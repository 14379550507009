import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Gauge from 'assets/images/UI/entypo_gauge.svg'
import { Icon } from "semantic-ui-react";
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";


import {
	COMPLETE_ANALYTICS,
	SET_SUB_NAV,
} from "constants/actions";

const Checklist = () =>{
	const dispatch = useDispatch();
	const [mini,setMini] = useState(false)
	const [steps,setSteps] = useState(false)
	const [currentStep,setCurrentStep] = useState(0)
	const [refresh,setRefresh] = useState(false)
	const [progress,setProgress] = useState(3)

	const { checklist } = useSelector(
    (state) => ({
      checklist: state.checklist.home
    }),
    shallowEqual
  );


	const setStep = (s) =>{
		setCurrentStep(s)
		setSteps(true)
	}

	const navigate_tabs = () =>{
		dispatch({
        type: SET_SUB_NAV,
        payload: 3,
      });
	}


	const changing = (nextStepIndex, nextElement) =>{
		if(currentStep==2 && nextStepIndex==1){
			navigate_tabs()
		}
		
	}

	const exit = () =>{
		if(currentStep==0){
			dispatch({
        type: COMPLETE_ANALYTICS,
        payload: 0,
      });
		}
	}


	const checkStep = (i) =>{
		if(i==1){
			setStep(0)
		}
	}


	return (
		<Container mini={mini}>

			
			<Title>Getting started</Title>
			<T2>Let's make you an innerlogic pro</T2>
			<T3>We'll guide you around the app to make sure that you know exactly what to do.</T3>

			<Title>Onboarding progress {progress*25}%</Title>
			<ProgressContainer>
			<ProgressBar>
				
				<Fill width={progress*25}/>
			</ProgressBar>
			<Circle left={progress*25}/>
			</ProgressContainer>
			{checklist?.steps.map((item,i)=>{
				return <Check done={checklist?.steps[i].completed} onClick={()=>checkStep(i)}>{i+1}. {item.title}</Check>
			})}


			<Steps
	          enabled={steps}
	            steps={step[currentStep]}
	            initialStep={0}
	            onBeforeChange={changing}
	            onComplete={exit}
          />
		</Container>
		)
}


export default Checklist



const Container = styled.div`
	padding:20px;
	background-color:white;;
	font-family: 'Poppins';
	font-size: 16px;
	line-height: 24px;
	width:300px;
	color: black;
	border-radius:5px;
	display:flex;
	flex-direction:column;

`

const Title = styled.div`
	font-weight:bold;
	display:flex;
	align-items:flex-end;
	font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;
text-transform:uppercase;
margin-bottom:5px;
color: #9E9FA1;
`

const Check = styled.div`
	font-size:12px;
	margin-top:10px;
	opacity:${props=>props.done?0.3:1};
	cursor:pointer;
	color:#476DFA;
	text-decoration:${props=>props.done?'line-through':'underline'};

`

const Image = styled.img`
		height:30px;
		margin-right:10px;
		margin-left:5px;
`

const Top = styled.div`
	position:absolute;
	top:5px;
	right:5px;
	font-size:12px;
`

const T2= styled.div`
	font-weight:500;
`

const T3 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 1.4;
display: flex;
align-items: center;
margin-bottom:20px;

color: #7E7E7E;
`
const ProgressBar = styled.div`
	height:12px;
	width:100%;
	background-color:lightgrey;
	border-radius:10px;
	display:flex;
	align-items:center;
	position:relative;
	overflow:hidden;

`

const Circle = styled.div`
	height:20px;
	width:20px;
	border-radius:10px;
	background-color:green;
	position:absolute;
	left:${props=>props.left+'%'};
	transition:all 1s;
`

const Fill = styled.div`
	height:100%;
	background-color:green;
	width:${props=>props.width+1+'%'};
	transition: width 2s;
`

const ProgressContainer = styled.div`
	position:relative;
	width:100%;
	display:flex;
	align-items:center;
	margin-bottom:10px;
`

const step = [[
  {
    element: '.help',
    title:'Help Section',
    intro: 'If you need help, or are looking for a tutorial or walkthrough, explore the help section to get upskilled on the innerlogic platform',
  }
  ]
];

