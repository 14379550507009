import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Chart from "react-apexcharts";
import { Progress, Dimmer, Loader } from 'semantic-ui-react'
import Avatar from './Avatar'
import EmotionCard from './Emotions'
import Empty from './Empty'


import format from "date-fns/format";

import {
  SG_GET_MOODS,
  // SG_GET_MOOD_ORG,
  // SG_GET_MOOD_TEAMS,
  LOAD_EMPLOYEES,
  // UPDATE_POSITION,
} from "constants/actions";


import ChartDonutData from "./ChartDonutData";
import ChartLineData from "./ChartLineData";


const NewEQ = ({ setMood, selectedTeamId }) => {
  const dispatch = useDispatch();

  const [moodData, setMoodData] = useState([]);
  const [reportDate, setReportDate] = useState([]);
  const [moodPrevData, setMoodPrevData] = useState([]);
  const [moodChartData, setMoodChartData] = useState([]);
  const [loading, setLoading] = useState(true)

  const [showTeamId, setShowTeamId] = useState(false)
  // const [position, setPosition] = useState(0);
  // const [positionId, setPositionId] = useState(0);
  // const [loading, setLoading] = useState(true);
  // const [cardData, setCardData] = useState(false);
  // const [onLoad, setOnLoad] = useState(false);

  const AmbassadorId = useSelector((state) => Number(state.auth?.ambassador_id), shallowEqual)
  const EmployeeId = useSelector((state) => Number(state.auth?.employee_id), shallowEqual)

  // useEffect(() => {
  //   // Run! Like go get some data from an API.
  //   dispatch({ type: SG_GET_MOODS }); //

  //   // dispatch({ type: SG_GET_MOOD_TEAMS, payload: "team-dash=true" });
  //   // dispatch({ type: SG_GET_MOOD_ORG }); // from server
  //   // dispatch({ type: SG_GET_MOOD_ORG, payload: "org=true" });

  // }, [dispatch]);
  // anything with mood is from server
  // anything with emotion is from local
  const { get_mood_data, get_position, get_selectedOrg,
    get_employee_data } = useSelector(
      (state) => ({
        // get_emotion_data: state.moods, // from server
        get_mood_data: state.moods.moods, // from server
        get_position: state.moods.position,
        get_selectedOrg: state.selectedOrg,
        get_employee_data: state.employees,

      }),
      shallowEqual
    );

  useEffect(() => {
    if (EmployeeId > 0) {
  
      setShowTeamId(selectedTeamId? selectedTeamId : get_employee_data?.[EmployeeId]?.team)
    }
    if (Number(AmbassadorId) > 0) {
      setShowTeamId(selectedTeamId)
    }

  }, [selectedTeamId, EmployeeId, AmbassadorId, get_employee_data]);

  // console.log(showTeamId)
  // console.log(get_employee_data?.[EmployeeId])

  useEffect(() => {
    // Run! Like go get some data from an API.
    let ShowTeams = ''
    if (Number(AmbassadorId) > 0) {
      if (showTeamId) {
        ShowTeams = `&team=${showTeamId}`
      }
      const OrgId = get_selectedOrg?.organization?.id
      if (OrgId) {
        dispatch({
          type: LOAD_EMPLOYEES,
          payload: `organization=${OrgId}${ShowTeams}`
        });

        dispatch({
          type: SG_GET_MOODS,
          payload: `organization=${OrgId}${ShowTeams}`
        });
      }
      return
    } else {

      if (showTeamId) {
        ShowTeams = `team=${showTeamId}`
      }
      dispatch({
        type: LOAD_EMPLOYEES,
        payload: `${ShowTeams}`
      });
      dispatch({
        type: SG_GET_MOODS,
        payload: `${ShowTeams}`
      }); //
    }


  }, [dispatch, get_selectedOrg, AmbassadorId, showTeamId]);

  useEffect(() => {
    const RevDateList = []
    const RevMoodList = []
    if (get_mood_data?.length > 0) {
      get_mood_data.map(p => {
        RevDateList.push(format(
          new Date(p?.on_date.toLocaleString()),
          "MMM do yyyy"
        ))
        RevMoodList.push(p.mood_score)

      })

      const Pos = get_mood_data?.length
      // console.log(Pos)

      setMoodData(get_mood_data[0])

      if (Pos > 1) {
        setMoodPrevData(get_mood_data?.[1])
      }

      const moodList = RevMoodList.reverse()
      const dateList = RevDateList.reverse()
      // console.log(RevMoodList)
      // console.log(RevDateList)
      // console.log(moodList)
      // console.log(dateList)

      setMoodChartData({ moodList, dateList })
    } else {
      setMoodChartData([])
      setMoodData([])
    }

  }, [get_mood_data]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });

  useEffect(() => {
    if (moodData?.length > 0) {
      setReportDate(moodData?.on_date)
    }

  }, [moodData]);

  // console.log(get_mood_data)
  // console.log(moodData?.spectrum_score)
  // console.log(moodPrevData?.spectrum_score)

  return (
    <Container>

      <Dimmer.Dimmable blurring dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        {moodData.length < 1 && !loading ?
          <Empty /> :
          <>
            <Card>
              <Left>
                <Title>Team Mood Report</Title>
                <DateText>Report date:

                  {moodData?.on_date &&
                    format(
                      new Date(moodData?.on_date.toLocaleString()),
                      "MMM do yyyy"
                    )
                  }
                </DateText>
                <ChartLineData moodChartData={moodChartData} />
              </Left>
              <Right>
                {moodData?.category_list &&
                  <ChartDonutData emotionData={moodData?.category_list} />
                }

              </Right>

            </Card>

            <Explainer>
              <SubTitle>Emotion System Breakdown</SubTitle>
              <Description>Emotion systems are the core categories that drive the emotional landscape of your team</Description>
            </Explainer>
            <EmotionCardContainer>
              <EmotionCard name="Enjoyment"
                setMood={setMood}
                thisWeek={moodData?.spectrum_score}
                lastWeek={moodPrevData?.spectrum_score} />
              <EmotionCard name="Motivation"
                setMood={setMood}
                thisWeek={moodData?.spectrum_score}
                lastWeek={moodPrevData?.spectrum_score} />
              <EmotionCard name="Curiosity"
                setMood={setMood}
                thisWeek={moodData?.spectrum_score}
                lastWeek={moodPrevData?.spectrum_score} />
              <EmotionCard name="Stress"
                setMood={setMood}
                thisWeek={moodData?.spectrum_score}
                lastWeek={moodPrevData?.spectrum_score} />
              <EmotionCard name="Discontent"
                setMood={setMood}
                thisWeek={moodData?.spectrum_score}
                lastWeek={moodPrevData?.spectrum_score} />
              <EmotionCard name="Anger"
                setMood={setMood}
                thisWeek={moodData?.spectrum_score}
                lastWeek={moodPrevData?.spectrum_score}
              />
            </EmotionCardContainer>
          </>}
      </Dimmer.Dimmable>
    </Container>
  )

}


export default NewEQ


const Container = styled.div`
  padding:20px;

  @media(max-width:768px){
    padding:0px;
  }
`

const Card = styled.div`
 	display:flex;
  margin-top:40px;

  @media(max-width:900px){
    flex-wrap:wrap;
    justify-content:center;
    padding:0px;
    margin-top:0px;
  }

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:25px;
	font-weight:bold;
`
const DateText = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:5px;
	margin-bottom:30px;
	margin-left:3px;
`

const Left = styled.div`
	width:calc(100% - 400px);

  @media(max-width:900px){
    width:100%;
  }
`

const Right = styled.div`
	width:400px;
	height:400px;
	display:flex;
	align-items:flex-end;

  @media(max-width:768px){
    height:350px;
  }
`

const People = styled.div`
	width:300px;
`

const EmotionCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items:center;
  }
`;

const Explainer = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:40px;
`

const SubTitle = styled.div`
  font-size:16px;
  font-weight:bold;
`

const Description = styled.div`
  
`