import styled from "styled-components";
import React from "react";
import {Icon} from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';


const Nav = () =>{
    const navigate = useNavigate();

    return (
        <Container>
            <IconContainer onClick={()=>navigate(-1)}>
            <Icon name="home" />
            </IconContainer>
            
            <Icon name="chevron right" style={{color:'lightgrey'}}/>
            <Text>Data Journey</Text>
        </Container>
    )
}


export default Nav


const Container = styled.div`
    width:100%;
    display:flex;
    font-family:'Raleway';

    margin-top:10px;
`

const Text = styled.div`
    margin-left:10px;
`

const IconContainer = styled.div`
    color:#2D70E2;
    margin-right:10px;
    cursor:pointer;
`