import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Icon, Confirm } from "semantic-ui-react";
import { Pagination, Dimmer, Loader, Popup } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import AddSchedule from "components/CultureAudit/MultipleScheduler";

import {
  SG_GET_DEBRIEF_SCHEDULES,
  ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  SG_DELETE_DEBRIEF_SCHEDULE,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES
} from "constants/actions";

import Garbage from "assets/images/UI/garbage.svg";
import Pencil from "assets/images/UI/pencil.svg";

import format from "date-fns/format";
import SurveyStatusPending from "components/CultureAudit/SurveyStatusPending";
import { SURVEY_TYPE, SURVEY_INFO, SURVEY_QUESTION_TYPE } from "constants/surveys";

const StatusPendingHistoryList = ({
  showErrors,
  showItems,
  pdfConfig,
  tab,
}) => {
  const dispatch = useDispatch();
  const surveyType = SURVEY_TYPE.AUDIT
  const [fetchAgain, setFetchAgain] = useState(false);
  const [briefId, setBriefId] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [debriefSchedules, setDebriefSchedules] = useState([]);
  const [deleteId, setDeleteId] = useState(false);
  const [deletedRecord, setDeletedRecord] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [organizationId, setOrganizationId] = useState(false);
  const [open, setOpen] = useState(false);
  const [secondaryCategory, setSecondaryCategory] = useState([]);
  const [subfactorFound, setSubfactorFound] = useState(false);
  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);
  const [userAdmin, setUserAdmin] = useState(false);
  const [userEmployee, setUserEmployee] = useState(false);
  const [groupList, setGroupList] = useState([]);

  const [alertMessage, setAlertMessage] = useState({
    no: 0,
    text: null,
  });

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [showSurveyStatus, setShowSurveyStatus] = useState(0);

  const [selectedPrimary, setSelectedPrimary] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState([]);

  const [primarySecondaryName, setPrimarySecondaryName] = useState({
    primary: "Type",
    secondary: "Role",
  });

  // this has been set in django if you use non basic pagination
  // this page using basic pagination
  const defaultPageSize = 12;

  const {
    get_auth,
    get_organizations,
    get_employee,
    get_survey_structure,
    get_employee_categories,
    get_survey_questions,
    get_selectedOrg,
    get_debrief_schedules,
    get_culture_audit_reports,
  } = useSelector(
    (state) => ({
      get_errors: state.errors,
      get_auth: state.auth,
      get_organizations: state.organizations,
      get_employee: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_categories: state.employee_category.employee_category,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_debrief_schedules: state.debrief_schedule,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
    }),
    shallowEqual
  );

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);


  useEffect(() => {
    setDebriefSchedules(get_debrief_schedules.debrief_schedule.results);
    if (get_debrief_schedules.activePage > 0) {
      if (get_debrief_schedules.activePage !== activePage) {
        setActivePage(get_debrief_schedules.activePage);
      }
    }
  }, [get_debrief_schedules, activePage, tab]);

  useEffect(() => {
    setTotalPages(
      Math.ceil(get_debrief_schedules.debrief_schedule?.count / defaultPageSize)
    );
  }, [activePage, get_debrief_schedules]);


  useEffect(() => {
    if (organizationId && activePage) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${activePage}&type_of=${surveyType}&organization=${organizationId}${`&curr-status=pending`}${selectedPrimary?.value ? `&name=${selectedPrimary?.value?.trim()}` : ""}`,
      });
      if (fetchAgain) {
        setFetchAgain(false);
      }
      if (deletedRecord) {
        setDeletedRecord(false);
      }
    }
  }, [
    dispatch,
    organizationId,
    activePage,
    fetchAgain,
    setFetchAgain,
    deletedRecord,
    surveyType,
    selectedPrimary,
  ]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${SURVEY_QUESTION_TYPE.AUDIT}&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setPrimarySecondaryName({
      secondary: get_survey_structure?.[0]?.categories?.find(
        (f) => f.priority === "secondary"
      )?.name,
      primary: get_survey_structure?.[0]?.categories?.find(
        (f) => f.priority === "primary"
      )?.name,
    });
  }, [get_survey_structure]);

  useEffect(() => {
    setUserAdmin(false);
    if (get_employee) {
      setUserEmployee(get_employee?.userEmp);
      // check for admin
      if (get_employee.userEmp?.account_type === 5) {
        setUserAdmin(true);
      }
    }
  }, [get_employee, get_auth]);

  useEffect(() => {
    if (!get_culture_audit_reports?.response && surveyType) {
      if (ambassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${surveyType}&organization=${organizationId}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${surveyType}`,
        });
      }
    }
  }, [dispatch, surveyType, ambassadorId, get_culture_audit_reports.response, organizationId]);

  useEffect(() => {
    const _primaryList = [];
    const _secondaryList = [];
    const _groupList = [];
    if (get_survey_structure?.[0]?.categories?.length > 0) {
      get_survey_structure?.[0]?.categories.map((st) => {
        if (st?.priority === "primary") {
          // no subfactors
          st.options.map((str) => {
            if (str?.subfactors?.length > 0) {
              // subfactors found
              setSubfactorFound(true);
              // console.log(str)
              _groupList.push(str?.name);
              str?.subfactors.map((opf) => {
                // check if user is not admin
                // for eligibility
                if (userAdmin) {
                  _primaryList.push({
                    value: opf.name,
                    name: opf?.name,
                    name_id: opf?.id,
                    label: opf?.name,
                    group: str?.name,
                    mainGroup: st?.name,
                    type_of: st?.priority,
                    subtitle: st?.subtitle,
                    priority: st?.priority,
                  });
                } else {
                  if (userEmployeeCategoryList?.includes(opf.name)) {
                    _primaryList.push({
                      value: opf.name,
                      name: opf?.name,
                      name_id: opf?.id,
                      label: opf?.name,
                      group: str?.name,
                      mainGroup: st?.name,
                      type_of: st?.priority,
                      subtitle: st?.subtitle,
                      priority: st?.priority,
                    });
                  }
                }
                return null;
              });
            } else {
              // check if user is not admin
              // for eligibility
              if (userAdmin) {
                _primaryList.push({
                  value: str?.name,
                  label: str?.name,
                  name: str?.name,
                  name_id: str?.id,
                  type_of: st?.priority,
                  subtitle: st?.subtitle,
                  priority: st?.priority,
                });
              } else {
                if (userEmployeeCategoryList?.includes(str.name)) {
                  _primaryList.push({
                    value: str?.name,
                    label: str?.name,
                    name: str?.name,
                    name_id: str?.id,
                    type_of: st?.priority,
                    subtitle: st?.subtitle,
                    priority: st?.priority,
                  });
                }
              }
            }
            return null;
          });
          return null;
        }

        if (st?.priority === "secondary") {
          st.options.map((str) => {
            if (userAdmin) {
              _secondaryList.push({
                value: str?.name,
                label: str?.name,
                name: str?.name,
                name_id: str?.id,
                type_of: st?.priority,
                subtitle: st?.subtitle,
                priority: st?.priority,
              });
            } else {
              if (userEmployeeCategoryList?.includes(str.name)) {
                _secondaryList.push({
                  value: str?.name,
                  label: str?.name,
                  name: str?.name,
                  name_id: str?.id,
                  type_of: st?.priority,
                  subtitle: st?.subtitle,
                  priority: st?.priority,
                });
              }
            }
            return null
          })
        }

      setPrimaryCategory(_primaryList);
      setSecondaryCategory(_secondaryList)
      setGroupList(_groupList)
      return null;
    })
  }

}, [get_survey_structure, userAdmin, userEmployeeCategoryList]);


  useEffect(() => {
      setOrganizationId(Number(get_auth.organization_id));
  }, [get_auth]);


  function ChangedPage(e, { activePage }) {
    setActivePage(activePage);
    dispatch({
      type: ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
      payload: activePage,
    });
  }

  useEffect(() => {
    setPageLoading(true);
    const timer1 = setTimeout(() => {
      setPageLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [dispatch, fetchAgain, deletedRecord, selectedPrimary]);

  const anchorWidth = pdfConfig?.[0].data.introTemplate === 2 ? "50" : "100";
  const hasAnchor = anchor || surveyType === 4;

  function Paginate() {
    return (
      <Pagination
        activePage={activePage}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        prevItem={debriefSchedules?.links?.previous ? true : null}
        nextItem={debriefSchedules?.links?.next ? true : null}
        totalPages={totalPages}
        onPageChange={ChangedPage}
      />
    );
  }

  function clearErrors() {
    setFetchAgain(false);
    setDeletedRecord(false);
  }

  function DeleteDebriefSchedule() {
    if (deleteId) {
      dispatch({
        type: SG_DELETE_DEBRIEF_SCHEDULE,
        payload: { id: deleteId },
      });
    }
    setDeletedRecord(deleteId);
    setConfirmDelete(false);
    setBriefId(false);
    setDeleteId(false);
  }

  function SelectDeleteRow(id) {
    setDeleteId(id);
    setConfirmDelete(true);
  }

  function EditSurvey(params) {
    setOpen(true);
    setBriefId(params);
  }

  function SurveyDetails(params) {
    setShowSurveyStatus(params);
    setBriefId(params);
    setShowMenu(false);
  }

  if (showSurveyStatus) {
    return (
        <Container>
            <Small>
            {get_organizations?.[organizationId]?.name} Culture Index
            </Small>
            <Title>
                Active surveys
            </Title>
            <SurveyStatusPending
                setShowSurveyStatus={setShowSurveyStatus}
                briefId={briefId}
                setAlertMessage={setAlertMessage}
                setShowMenu={setShowMenu}
                setBriefId={setBriefId}
                // nonHistory={true} // for historic responses
            />
        </Container>
    );
  }

  return (
    <Container>
        <Small>
        {get_organizations?.[organizationId]?.name} Culture Index
        </Small>
        <Title>
            Active surveys
        </Title>
        <ScheduleSection>
            <AddSchedule
                    setFetchAgain={setFetchAgain} // changes on new addition or editing
                    briefId={briefId}
                    organizationId={organizationId}
                    trigger={<StyledButton>Schedule</StyledButton>}
                    open={open ? open : false}
                    setOpen={setOpen}
                    primaryCategory={primaryCategory}
                    secondaryCategory={secondaryCategory}
                    surveyQuestions={get_survey_questions}
                    surveyStructure={get_survey_structure}
                    primarySecondaryName={primarySecondaryName}
                    surveyType={SURVEY_TYPE.AUDIT}
                    surveyInfo={SURVEY_INFO.audit}
                    setAlertMessage={setAlertMessage}
                    setBriefId={setBriefId}
                />
        </ScheduleSection>
       


      {!showErrors && fetchAgain > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Schedule Added!"
          content="You have successfully added a new schedule!"
        />
      )}

      {!showErrors && deletedRecord > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Schedule Removed!"
          content="You have successfully removed a schedule!"
        />
      )}
      {/* <Dimmer.Dimmable as={Segment} dimmed={pageLoading}> */}

      <TopSection>
        {/* <FormContainer>
          <div style={{ width: 300 }}>
            <Label>Filter {primarySecondaryName?.primary}</Label>
            <Select
              placeholder={`Select ${primarySecondaryName?.primary}`}
              onChange={(e) => setSelectedPrimary(e)}
              value={selectedPrimary}
              // isMulti
              isClearable
              options={primaryCategory}
              // getOptionLabel={(option) => option.name}
              // getOptionValue={(option) => option.role}
            />
          </div>
        </FormContainer> */}


          {/* <>
            <AnchorWrapper width={anchorWidth}>
              <Anchor anchorLabel="PDF Data Anchor" pdf={true} />
            </AnchorWrapper>
          </> */}

      </TopSection>
      <Dimmer.Dimmable dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Table>
          <THeader>
            <TH width={15}>Primary Factor</TH>
            <TH width={45}>Date &amp; Time</TH>
            <TH width={15}>Status</TH>
            <TH width={15}>Responses</TH>
            <TH width={10}>Actions</TH>
            {/* {pdfConfig?.length > 0 && <TH width={10}>PDF</TH>} */}
          </THeader>

          {debriefSchedules &&
            !pageLoading &&
            debriefSchedules.map((brief, i) => {
              return (
                <TR key={i}>
                  <TD width={15} onClick={() => SurveyDetails(brief?.id)}><Factor>{brief?.name}</Factor></TD>
                  <TD width={45} onClick={() => SurveyDetails(brief?.id)}>
                    <TableRows>
                        {`${format(
                          new Date(brief?.scheduled_for?.toLocaleString()),
                          "EEEE, MMM do h:mm a"
                        )} to ${format(
                          new Date(brief?.close_at?.toLocaleString()),
                          "EEEE, MMM do h:mm a"
                        )}`}
                    </TableRows>
                  </TD>
                  <TD width={15} onClick={() => SurveyDetails(brief?.id)}><Status text={brief?.status_text}/></TD>
                  <TD width={15} onClick={() => SurveyDetails(brief?.id)}>
                    {brief.status > 2 ? (
                      <Responses>
                        <strong>{brief?.total_responses}</strong> / {brief?.total_requests}
                        </Responses>
                    ) : (
                      "Pending"
                    )}
                  </TD>
                  <TD
                    width={10}
                    style={{zIndex: 1000}}
                  >
                    {brief?.status < 4 ? (
                      <GridAdjustment>
                       
                        {brief?.status < 3 && (
                          <>
                            <Popup
                              content="Delete Survey"
                              trigger={
                                <IconImg src={Garbage} onClick={() => SelectDeleteRow(brief?.id)}/>
                              }
                            />
                            <Confirm
                              open={confirmDelete}
                              onCancel={() => setConfirmDelete(false)}
                              onConfirm={() => DeleteDebriefSchedule(brief?.id)}
                            />
                            {/* <Popup
                              content="Edit"
                              trigger={
                                <IconImg src={Pencil}
                                  onClick={() => EditSurvey(brief?.id)}
                                />
                              }
                            /> */}
                          </>
                        )}
                      </GridAdjustment>
                    ) : (
                      ""
                    )}
                  </TD>

                  {/* {pdfConfig?.length > 0 && hasAnchor && surveyType !== 3 && (
                    <TD width={10} style={{ minWidth: "10%" }}>
                      <div
                        style={{
                          left: -15,
                          minWidth: "110px",
                        }}
                      />
                      {brief &&
                        organizationId &&
                        hasAnchor &&
                        surveyType !== 3 && (
                          <ErrorBoundary
                            componentFallback={
                              <ErrorWrapper>
                                An unknown error has occurred
                              </ErrorWrapper>
                            }
                          >
                            <DeiPDF
                              config={pdfConfig}
                              activePage={activePage}
                              reportId={brief.id}
                            />
                          </ErrorBoundary>
                        )}
                    </TD>
                  )} */}
                </TR>
              );
            })}
        </Table>

        <Pages>
          <Paginate />
        </Pages>
      </Dimmer.Dimmable>
    </Container>
  );
};

export default StatusPendingHistoryList;

const Status = ({text}) =>{

  const color1 = {
    'Demo':'#F2F2F2',
    'Completed':'#EAFBF7',
    'Processing':'#EBF1FD',
    'Pending':'#FFFAF1',
    'Live':'#FEF3F4'
  }

  const color2 = {
    'Demo':'#666D79',
    'Completed':'#1D9A7D',
    'Processing':'#2D70E2',
    'Pending':'#FEA711',
    'Live':'#F15062'
  }

  return (
    <StatusContainer color1={color1[text]} color2={color2[text]}>
      {text}
    </StatusContainer>
  )
}

/******** Styles ****************************************************/

const Container = styled.div`
    margin-top:30px;
    padding:20px;
    font-family:"Raleway", sans-serif;
    position:relative;
`

const GridAdjustment = styled.div`
  left: ${(props) => (props.pdfConfig?.length > 0 ? "-15px" : "0px")};
  position: ${(props) =>
    props.pdfConfig?.length > 0 ? "absolute" : "relative"};
`;

const Table = styled.div`
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
`;

const TR = styled.div`
  display: flex;
  padding:10px;
  width: 100%;

  &:hover{
    background-color:#F8FAFF;
    cursor:pointer;
  }
`;

const THeader = styled.div`
    display:flex;
    width:100%;
    padding:15px 10px;
`

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  font-size:14px;
  font-weight: 600;
  border-bottom: 1px solid #DFDFDF;;
  padding: 15px 10px;
  color:#666D79;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
`;

const ErrorWrapper = styled.div`
  width: 110px;
  position: absolute;
  left: 0;
  top: 0;
  color: red;
`;

const Pages = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const AnchorWrapper = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const FilterWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
`;

const TopSection = styled.div`
  flex-direction: row;
  display: flex;
`;

const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
  margin-top: -15px;
  margin-bottom: -3px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

const TableRows = styled.div`
  display: flex;
  flex-direction: row;
  color:#666D79;
   font-size:12px;
`;

const Factor = styled.div`
    font-weight:600;
    color:#2A3039;
   

`

const StatusContainer = styled.div`
    background-color:${props=>props.color1};
    color:${props=>props.color2};
    padding: 4px 8px;
    border-radius:8px;
    font-weight:600;

`

const Responses = styled.div`
      color:#2A3039;
`

const Title = styled.div`
    font-size: 24px;
    font-weight:700;
    color:#2A3039;
    margin-bottom:20px;
`
const Small = styled.div`
    font-size: 12px;
    color:#2D70E2;
    margin-bottom:10px;
    font-weight:600;
`

const StyledButton = styled.button`
  background-color: #e3b01f;
  color: black;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 19px;
  border: 1px solid #DFDFDF;
  outline: none;
  margin-top: 10px;
  margin-right: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  background-color:white;
  color: #7E7E7E;


  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;

const ScheduleSection = styled.div`
    position:absolute;
    right:10px;
    top:40px;
`


const IconImg = styled.img`
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
`