import styled from "styled-components";
import { Dimmer, Loader } from 'semantic-ui-react'

import { GridRowData } from "./Components"

const Subfactor = ({ surveySchedule, primaryCategory, monthList,
	currentMonth, pageLoading }) => {

	return (
		<>
			{currentMonth > 0 && <Current month={currentMonth} />}
			<Left>
				<Top>
					<div>Primary Factor</div>
					
				</Top>
				<Bottom>
					<LeftGrid>
						{primaryCategory &&
							primaryCategory.map((item, i) =>
								<Sub key={i}>
									<div>{item?.value}</div>
								</Sub>)
						}
					</LeftGrid>
				</Bottom>
			</Left>

			<Right>
				<Dimmer.Dimmable dimmed={pageLoading}>

					<Dimmer active={pageLoading} inverted>
						<Loader>Loading</Loader>
					</Dimmer>
					<TopR>
						{monthList.map((item, i) => <Dated key={i}>{item}</Dated>)}
					</TopR>

					<Grid>
						{primaryCategory &&
							primaryCategory.map((item, i) => {
								return (
									<GridRowData
										monthList={monthList}
										itemName={item?.value}
										surveySchedule={surveySchedule}
										key={i} />
								)
							})}
					</Grid>
				</Dimmer.Dimmable>
			</Right>
		</>
	)
}

/******** Styles ****************************************************/

const Left = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:300px;
`

const Top = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	border-bottom:1px solid #e9e9e9;
	height:60px;
	padding:30px 30px 10px 30px;
`

const Bottom = styled.div`
	padding:0px 20px 20px 20px;
`

const Sub = styled.div`
	width:100%;
	display:flex;
	justify-content:space-between;
	padding-left:20px;
	height:25px;
`

const Right = styled.div`
	width:calc(100% - 300px);
`

const TopR = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	border-bottom:1px solid;
	padding:30px 20px 10px 20px;
	height:60px;
`

const Dated = styled.div`
	width:calc(100% / 12);
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-weight:bold;
`

const Grid = styled.div`
	padding:0px 20px 0px 20px;
	margin-top:10px;
	margin-bottom:20px;
`
const LeftGrid = styled.div`
	margin-top:10px;
	margin-bottom:20px;
`

const Current = styled.div`
	position:absolute;
	height:calc(100% - 20px);
	width:calc((100% - 300px) / 12);
	border:3px solid #E3B01F;
	border-radius:20px;
	z-index:-1;
	left:${props => 'calc(((100% - 300px) / 12 - 3.3px) * ' + (props.month - 1) + ' + 318px)'};
	top:10px;
`

export default Subfactor
