import React,{useState} from 'react';
import styled, { keyframes } from "styled-components";


const Container = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  border-radius: 8px;
  box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
  overflow:hidden;
  height:300px;
  margin-bottom:36px;

    @media (min-width:901px){
     display:none;
    }
`

const Top = styled.div`
	height:75px;
	width:100%;
	background-color: #6a00ff;
	color:white;
	padding-left:20px;
	display:flex;
	justify-content:center;
	flex-direction:column;
`

const Title = styled.div`
	font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  font-weight:bold;
`

const SubTitle = styled.div`
	margin-top:5px;
`

const Body = styled.div`
	padding:20px;
	display:flex;
	flex-direction:column;
`	

const Row = styled.div`
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	width:100%;
	border-bottom:1px solid #e9e9e9;
	padding:20px 0 20px 0;
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;

	@media (max-width:1000px){
		font-size:12px;
	}
`
const LastRow = styled.div`
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	width:100%;
	padding:20px 0 20px 0;
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
`

const Item = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
`
const Price = styled.div`
	
`
const Multi = styled.span`
	font-size:10px;
	color:#9b9b9b;
	margin:0 4px 0 4px;
`

const Due = styled.div`
	font-weight:bold;
	font-size:20px;
`

const OrderBoxMobile = () =>{

	return (
		<Container>
			<Top>
				<Title>Innerlogic Premium</Title>
				<SubTitle>Billed monthly</SubTitle>

			</Top>
			<Body>
				<Row>
					<Item>$9.99USD <Multi> x </Multi> 6 members <Multi>x</Multi> 1 month</Item>
					<Price>$59.94 USD</Price>
				</Row>
				<Row>
					<Item>Sales tax</Item>
					<Price>$8.99 USD</Price>
				</Row>
				<LastRow>
					<Due>Due today</Due>
					<Price>$68.93 USD</Price>
				</LastRow>
			</Body>
		</Container>
		)
}

export default OrderBoxMobile