import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";


const insights = [
    {
      number: 1,
      title: 'Insight 1',
      description: 'This is a description of Insight 1.',
    },
    {
      number: 2,
      title: 'Insight 2',
      description: 'This is a description of Insight 2.',
    },
    {
      number: 3,
      title: 'Insight 3',
      description: 'This is a description of Insight 3.',
    },
  ];


const Insights = () =>{

    return  (
        <CardContainer>
          <CardTitle>Insights</CardTitle>
          {insights.map((insight, index) => (
            <Insight key={index}>
              <InsightNumber>{insight.title}</InsightNumber>
              <InsightDescription>{insight.description}</InsightDescription>
            </Insight>
          ))}
        </CardContainer>

    )
}


export default Insights;


const CardContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: 400px;
  margin-left:30px;
  font-family: 'Raleway';
  color:#2A3039;
`;

const CardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 16px;
`;

const Insight = styled.div`
  margin-bottom: 16px;
`;

const InsightNumber = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom:10px;
`;

const InsightTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-bottom:20px;
`;

const InsightDescription = styled.p`
  font-size: 16px;
  margin: 0;
`;
