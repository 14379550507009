import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// import Chart from "react-apexcharts";
// import { Progress, Icon } from 'semantic-ui-react'


const Feeling = ({ CommentsData, selected, employeeData,color,team_type}) => {

  // const [show, setShow] = React.useState(false)
  const [selectedComments, setSelectedComments] = useState([]);


  const {get_auth,get_employees} = useSelector(
      (state) => ({
        get_auth: state.auth,
         get_employees: state.employees,
      }),
      shallowEqual
    );


  const Feedback_Checker = () =>{
    let role = get_employees[get_auth.employee_id]?.account_type
    // if(team_type <12 && role>8){
    //    return false
    // }
    return true
   
  }

  const Anonymous_Checker = () =>{
    let role = get_employees[get_auth.employee_id]?.account_type
    if(team_type==10){
      return true
    }
    if(team_type == 12 && role>8){
       return true
    }
    return false
   
  }
  

  useEffect(() => {
    if (CommentsData) {
      setSelectedComments(
        CommentsData.filter(p => p.category === selected?.name)
      )
      // console.log(CommentsData.filter(p => p.category === selected.name))
    }

  }, [CommentsData, selected]);

  // console.log(selectedComments)

  function EmployeeComment({empId, feeling,anonymous}) {

    const [employee, setEmployee] = useState();


    useEffect(() => {
      if (employeeData) {
        setEmployee(employeeData.filter(p => p.id === empId)[0])
      }

    }, [empId]);

    function EmployeeName() {
      if (employee?.first_name) {
        return `${employee?.first_name} ${employee?.last_name}`
      } else {
        return employee?.email.split("@")[0]
      }
    }


    return (<div style={{display:'flex'}}>
      {anonymous?
        <Icon name="user circle" style={{fontSize:30,marginTop:5}}/>:
          employee?.picture?
      <IMG src={employee.picture} />
      :<Icon name="user circle" style={{fontSize:40,marginTop:5,marginRight:10}}/>}

      <NameContainer>
        <Name>{anonymous?"Team member": EmployeeName()} </Name>
          <Is>is feeling</Is>
          <Emotion color={color}>{feeling}</Emotion>
      </NameContainer>
    </div>
    );
  }

  return (
    <Container>

     {selectedComments.map((p, k) => {
        return ( 
        <div key={k} style={{width:'100%'}}>
        <EmployeeComment 
        empId={p.employee_id} 
        feeling={p.name}  
        // anonymous={Anonymous_Checker()}
        />

			  <CommentContainer>
       <Icon name="quote left" style={{color:'#d8d8d8',fontSize:16,marginRight:10}} />
        <Comment>
          {p.comment} 
        </Comment>
      </CommentContainer>
        </div>
        )


      })}

    </Container>


  )
}


export default Feeling




const CommentContainer = styled.div`
  display:flex;
  margin-left:50px;
  margin-top:-10px;

  @media(max-width:768px){
    margin-left:5px;
    margin-top:5px;
  }
`


const Container = styled.div`
	padding:20px 10px;
  width:100%;

`

const IMG = styled.img`
	height:40px;
	width:40px;
	border-radius:20px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-weight:500;
	color: #4a4a4a;
`

const Is = styled.div`
font-family: 'Red Hat Display', sans-serif;
color: #9b9b9b;
font-weight:500;
margin-left:5px;
`

const NameContainer = styled.div`
	width:100%;
  position:relative;
  display:flex;
`

const Comment = styled.div`
 font-family: 'Red Hat Display', sans-serif;
font-size:18px;
line-height:1.3;
font-weight:300;
padding:0px 20px 20px 10px;
margin-bottom:20px;
`

const Emotion = styled.div`
  border-radius: 9.5px;
  background-color:${props=>props.color};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: 'Red Hat Display', sans-serif;
  padding:0px 10px;
  position:absolute;
  top:5px;
  right:0;

`
const Hide = styled.div`
  max-height:${props => props.show ? '400px' : '0px'};
  overflow:hidden;
  transition:all 1s;
  background-color:white;
`

const See = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  cursor:pointer;

  &:hover{
    text-decoration:underline;
  }
`

const DistributionTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:20px;
  margin-bottom:0px;
  font-weight:500;
  color:#4a4a4a;
  margin-left:10px;
`
const data = {

  series: [{
    name: 'Enjoyment',
    data: [1.45, 5.42, 5.9, 0., 12.6, 18.1, 18.2, 14.16, 11.1, 6.09, 0.34, 3.88, 13.07,
      5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, 27.03, 54.4, 47.2, 43.3, 18.6,
      48.6, 41.1, 39.6, 37.6, 9.4, 21.4, 0
    ]
  }],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      sparkline: {
        enabled: true
      },
      toolbar: { show: false }
    },
    grid: {
      padding: {
        right: 150
      }
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: -1,
            to: 100,
            color: '#08c251'
          }]
        },
        columnWidth: '80%',
      }
    },
    annotations: {
      yaxis: [
        {
          y: 35,
          borderColor: '#e9e9e9',
          offsetX: 150,
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            borderWidth: 0,
            offsetX: 150,
            style: {
              color: '#4a4a4a',
              fontFamily: 'Red Hat Display, sans-serif',
              fontSize: 14
            },
            text: 'Average Enjoyment 35%'
          }
        }
      ]
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: 'Growth',
      },
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + "%";
        }
      }
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
        '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
        '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
        '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
        '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
        '2013-07-01', '2013-08-01', '2013-09-01'
      ],
      labels: {
        rotate: -90
      }
    }
  },


};