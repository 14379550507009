import React,{useState} from 'react';
import styled from 'styled-components';
import Selection from './Selection';
import MemberSelection from './MemberSelection';
import Review from './Review';

const Collect = () =>{
    const [step,setStep] = useState(1);

    const renderContent = () =>{
        switch(step){
            case 1:
                return <Selection setStep={(s)=>setStep(s)}/>
            case 2:
                return <MemberSelection setStep={(s)=>setStep(s)}/>
            case 3:
                return <Review setStep={(s)=>setStep(s)}/>
        }
    }

    return (
        <Container>
            <Center>
               {renderContent()}
            </Center>
        </Container>
    )
}

export default Collect;



const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
`

const Center = styled.div`
    width:800px;
    `