import { sv } from "date-fns/locale";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

function scaleNumber(n, min, max, newMin, newMax) {
  if (max === min) {
    return 15; // or handle this case as you see fit
  }
  return newMin + ((n - min) * (newMax - newMin)) / (max - min);
}

const Chart = ({
  onItemClick,
  data,
  selectedRadio,
  bottomLabel,
  radioOption,
  factorData,
  isOtp,
}) => {
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    svgWidth: 800,
  }); // Default size

  const [hoveredItem, setHoveredItem] = useState(null);
  const [yAxisValues, setYAxisValues] = useState({
    minValueY: 0,
    maxValueY: 0,
    yAxisTicksValues: [],
    maxAxisValueY: 0,
  });

  const { svgWidth } = dimensions;

  const svgHeight = 400;
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setDimensions({
          svgWidth: containerRef.current.offsetWidth,
          svgHeight: containerRef.current.offsetHeight,
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Calculate dynamic max and min for the Y axis
    let maxValueY;
    let minValueY;
    if (isOtp && Number(selectedRadio) !== 0) {
      maxValueY = Math.round(
        Math.max(
          ...factorData.map((item) => {
            if (item?.change === 0 || item?.change === undefined) {
              return 0;
            }
            return item?.change;
          })
        )
      );
      minValueY = Math.round(
        Math.min(
          ...factorData.map((item) => {
            if (item?.change === 0 || item?.change === undefined) {
              return 0;
            }
            return item?.change;
          })
        )
      );
    } else {
      maxValueY = Math.round(
        Math.max(
          ...data.map((item) => {
            if (Number(selectedRadio) === 0) {
              return item.y;
            } else {
              if (
                item?.outcomes?.find(
                  (o) => Number(o.id) === Number(selectedRadio)
                )?.outcomeChange === undefined
              ) {
                return 0;
              }
              return item?.outcomes?.find(
                (o) => Number(o.id) === Number(selectedRadio)
              )?.outcomeChange;
            }
          })
        )
      );
      minValueY = Math.round(
        Math.min(
          ...data.map((item) => {
            if (Number(selectedRadio) === 0) {
              return item.y;
            } else {
              if (
                item?.outcomes?.find(
                  (o) => Number(o.id) === Number(selectedRadio)
                )?.outcomeChange === undefined
              ) {
                return 0;
              }
              return item?.outcomes?.find(
                (o) => Number(o.id) === Number(selectedRadio)
              )?.outcomeChange;
            }
          })
        )
      );
    }

    // Check if both max and min values are 0 and set the default scale if so
    const defaultTicks = [-2, -1, 0, 1, 2];
    let yAxisTicksValues;
    let maxAxisValueY = 0;
    if (maxValueY === 0 && minValueY === 0) {
      yAxisTicksValues = defaultTicks;
      maxAxisValueY = 2;
    } else {
      maxAxisValueY = Math.max(Math.abs(maxValueY), Math.abs(minValueY));
      yAxisTicksValues = defaultTicks.map((tick) => tick * (maxAxisValueY / 2));
    }

    setYAxisValues({ minValueY, maxValueY, yAxisTicksValues, maxAxisValueY });
  }, [selectedRadio, data]);

  const { minValueY, maxValueY, yAxisTicksValues, maxAxisValueY } = yAxisValues;

  // Determine the min/max values for the size of the circles
  const margin = { top: 20, right: 20, bottom: 50, left: 50 };
  const maxCircleSize = 40;
  const minCircleSize = 10;
  const maxCircleValue =
    isOtp && Number(selectedRadio) !== 0
      ? 5
      : Math.max(...data.map((item) => item.size));
  const minCircleValue =
    isOtp && Number(selectedRadio) !== 0
      ? 5
      : Math.min(...data.map((item) => item.size));

  // Generate grid lines based on the SVG dimensions and margins
  const gridLinesX = [];
  const gridLinesY = [];
  const numGridLines = 10;

  // Ticks for the axes
  const xAxisTicks = [];
  const yAxisTicks = [];

  for (let i = 0; i <= numGridLines; i++) {
    const x =
      margin.left +
      (i * (svgWidth - margin.left - margin.right)) / numGridLines;
    const y =
      margin.top +
      (i * (svgHeight - margin.top - margin.bottom)) / numGridLines;
    gridLinesX.push(x);
    gridLinesY.push(y);
  }

  for (let i = 0; i <= numGridLines; i++) {
    const xValue = (i * (svgWidth - margin.left - margin.right)) / numGridLines;
    const yValue =
      (i * (svgHeight - margin.top - margin.bottom)) / numGridLines;
    xAxisTicks.push(margin.left + xValue);
    yAxisTicks.push(svgHeight - margin.bottom - yValue);
  }

  // Function to handle mouse over event on circles
  const handleMouseOver = (item) => {
    setHoveredItem(item);
  };

  // Function to handle mouse out event on circles
  const handleMouseOut = () => {
    setHoveredItem(null);
  };

  // Function to handle click event on circles
  const handleCircleClick = (item) => {
    // You can handle the modal opening logic here or pass the item to the parent component
    onItemClick(item);
  };

  // sort the data so the highest size is at the top of the list
  const sortedData = [...data]?.sort((a, b) => b.size - a.size);

  const sortedFactors =
    factorData && [...factorData]?.sort((a, b) => b.average - a.average);
  const hoveredInfo = (item, isFactor) => {
    if (isFactor) {
      const yCheck = item?.change || 0;
      const circleAdj =
        scaleNumber(
          5,
          minCircleValue,
          maxCircleValue,
          minCircleSize,
          maxCircleSize
        ) + 8;

      // Get the difference between 2 numbers within an array
      let topPosition;
      const adjustedPos = (yCheck / maxAxisValueY) * 170 * -1;

      topPosition = 435 + adjustedPos;

      return (
        <HoveredInfo
          style={{
            position: "absolute",
            display: hoveredItem?.id === item.id ? "flex" : "none",
            // display: "flex",
            // Convert SVG coordinates to the absolute position on the screen
            top: `${topPosition - circleAdj}px`, // Position above the circle
            left: `${
              margin.left +
              (item?.average / 100) * (svgWidth - margin.left - margin.right)
            }px`, // Align with the circle's X position
            pointerEvents: "none",
            padding: "5px",
            border: "1px solid black",
            borderRadius: "5px",
            transform: "translate(-50%)", // Center it above the circle
          }}
        >
          <HoveredItem key={3}>
            <ItemLabel>{item?.label}</ItemLabel>
          </HoveredItem>
          <HoveredItem key={3}>
            <HoveredItemTitle>
              Factor Score:&nbsp;
              <HoveredItemScore>{item?.average}%</HoveredItemScore>
            </HoveredItemTitle>
          </HoveredItem>
          {item?.outcomes?.map((outcome) => {
            if (isNaN(outcome?.average)) {
              return null;
            }
            return (
              <HoveredItem key={outcome?.id}>
                <HoveredItemTitle>
                  {outcome?.label}:&nbsp;
                  <HoveredItemScore>{outcome?.average}%</HoveredItemScore>
                </HoveredItemTitle>
              </HoveredItem>
            );
          })}
          <ChatDialogV />
        </HoveredInfo>
      );
    }
    const yCheck =
      Number(selectedRadio) === 0
        ? item?.y
        : item?.outcomes?.find((o) => Number(o.id) === Number(selectedRadio))
            ?.outcomeChange || 0;

    const circleAdj =
      scaleNumber(
        item.size,
        minCircleValue,
        maxCircleValue,
        minCircleSize,
        maxCircleSize
      ) + 8;

    // Get the difference between 2 numbers within an array
    let topPosition;
    const adjustedPos = (yCheck / maxAxisValueY) * 170 * -1;

    topPosition = 435 + adjustedPos;

    return (
      <HoveredInfo
        style={{
          position: "absolute",
          display: hoveredItem?.id === item.id ? "flex" : "none",
          // display: "flex",
          // Convert SVG coordinates to the absolute position on the screen
          top: `${topPosition - circleAdj}px`, // Position above the circle
          left: `${
            margin.left +
            ((Number(selectedRadio) === 0
              ? item.x
              : item?.outcomes?.find(
                  (o) => Number(o.id) === Number(selectedRadio)
                )?.average) /
              100) *
              (svgWidth - margin.left - margin.right)
          }px`, // Align with the circle's X position
          pointerEvents: "none",
          padding: "5px",
          border: "1px solid black",
          borderRadius: "5px",
          transform: "translate(-50%)", // Center it above the circle
        }}
      >
        <HoveredItem key={3}>
          <ItemLabel>{item?.category}</ItemLabel>
        </HoveredItem>
        <HoveredItem key={3}>
          <HoveredItemTitle>
            Culture Score:&nbsp;
            <HoveredItemScore>{item?.x}%</HoveredItemScore>
          </HoveredItemTitle>
        </HoveredItem>
        {item?.outcomes?.map((outcome) => {
          if (isNaN(outcome?.average)) {
            return null;
          }
          return (
            <HoveredItem key={outcome?.id}>
              <HoveredItemTitle>
                {outcome?.label}:&nbsp;
                <HoveredItemScore>{outcome?.average}%</HoveredItemScore>
              </HoveredItemTitle>
            </HoveredItem>
          );
        })}
        <ChatDialogV />
      </HoveredInfo>
    );
  };

  return (
    <div style={{ height: svgHeight + 60 }}>
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <svg width="100%" height="100%" style={{ overflow: "visible" }}>
          {/* Render grid lines */}
          {gridLinesX.map((x, index) => (
            <line
              key={index}
              x1={x}
              y1={margin.top}
              x2={x}
              y2={svgHeight - margin.bottom}
              stroke="#e0e0e0"
              strokeWidth="1"
            />
          ))}
          {gridLinesY.map((y, index) => (
            <line
              key={index}
              x1={margin.left}
              y1={y}
              x2={svgWidth - margin.right}
              y2={y}
              stroke="#e0e0e0"
              strokeWidth="1"
            />
          ))}

          {/* Render X axis without the outer border */}
          <path
            d={`M${margin.left},${svgHeight - margin.bottom} L${
              svgWidth - margin.right
            },${svgHeight - margin.bottom}`}
            stroke="black"
            strokeWidth="0"
          />

          {/* Render Y axis without the outer border */}
          <path
            d={`M${margin.left},${margin.top} L${margin.left},${
              svgHeight - margin.bottom
            }`}
            stroke="black"
            strokeWidth="0"
          />

          {/* Render X axis ticks and labels */}
          {xAxisTicks.map((x, index) => (
            <g key={index}>
              <line
                x1={x}
                y1={svgHeight - margin.bottom}
                x2={x}
                y2={svgHeight - margin.bottom + 5}
                stroke="black"
              />
              <text
                x={x}
                y={svgHeight - margin.bottom + 20}
                textAnchor="middle"
                fontSize="10"
              >{`${index * 10}`}</text>
            </g>
          ))}

          {/* Render Y axis ticks and labels */}
          {yAxisTicksValues.map((value, index) => {
            const y =
              svgHeight -
              margin.bottom -
              ((value + yAxisTicksValues[yAxisTicksValues.length - 1]) /
                (yAxisTicksValues[yAxisTicksValues.length - 1] * 2)) *
                (svgHeight - margin.top - margin.bottom);
            return (
              <g key={index}>
                <line
                  x1={margin.left - 5}
                  y1={y}
                  x2={margin.left}
                  y2={y}
                  stroke="black"
                />
                <text
                  x={margin.left - 10}
                  y={y + 4}
                  textAnchor="end"
                  fontSize="10"
                >
                  {value}
                </text>
              </g>
            );
          })}

          {/* Render Y axis centered at zero */}
          <line
            x1={margin.left}
            y1={
              svgHeight -
              margin.bottom -
              (maxAxisValueY / (2 * maxAxisValueY)) *
                (svgHeight - margin.top - margin.bottom)
            }
            x2={svgWidth - margin.right}
            y2={
              svgHeight -
              margin.bottom -
              (maxAxisValueY / (2 * maxAxisValueY)) *
                (svgHeight - margin.top - margin.bottom)
            }
            stroke="#000"
            strokeWidth="2"
          />

          {/* Render circles */}
          {((Number(selectedRadio) !== 0 && !isOtp) ||
            Number(selectedRadio) === 0) &&
            sortedData.map((item, i) => {
              let xValue =
                Number(selectedRadio) === 0
                  ? item.x
                  : item?.outcomes?.find(
                      (o) => Number(o.id) === Number(selectedRadio)
                    )?.average;
              let yValue =
                Number(selectedRadio) === 0
                  ? item.y
                  : item?.outcomes?.find(
                      (o) => Number(o.id) === Number(selectedRadio)
                    )?.outcomeChange || 0;

              if (!xValue) {
                return null;
              }
              return (
                <circle
                  style={{
                    cursor: "pointer",
                    transition: "all 0.3s",
                    opacity:
                      hoveredItem && hoveredItem.id !== item.id ? 0.2 : 1,
                  }}
                  key={item.id}
                  // Adjust Y position dynamically based on the new Y axis scale
                  cx={
                    margin.left +
                    (xValue / 100) * (svgWidth - margin.left - margin.right)
                  }
                  cy={
                    svgHeight -
                    margin.bottom -
                    ((yValue + maxAxisValueY) / (2 * maxAxisValueY)) *
                      (svgHeight - margin.top - margin.bottom) +
                    (yValue > 0 ? item.size / 8 : 0)
                  }
                  r={
                    hoveredItem && hoveredItem.id === item.id
                      ? scaleNumber(
                          item.size,
                          minCircleValue,
                          maxCircleValue,
                          minCircleSize,
                          maxCircleSize
                        ) + 2
                      : scaleNumber(
                          item.size,
                          minCircleValue,
                          maxCircleValue,
                          minCircleSize,
                          maxCircleSize
                        )
                  }
                  fill={item.color}
                  onMouseOver={() => handleMouseOver(item)}
                  onMouseOut={handleMouseOut}
                  onClick={() => {
                    handleCircleClick(item);
                  }}
                />
              );
            })}

          {Number(selectedRadio) !== 0 &&
            isOtp &&
            sortedFactors.map((item, i) => {
              let xValue = item?.average;
              let yValue = item?.change || 0;

              if (!xValue) {
                return null;
              }
              return (
                <circle
                  style={{
                    cursor: "pointer",
                    transition: "all 0.3s",
                    opacity:
                      hoveredItem && hoveredItem.id !== item.id ? 0.2 : 1,
                  }}
                  key={item.id}
                  // Adjust Y position dynamically based on the new Y axis scale
                  cx={
                    margin.left +
                    (xValue / 100) * (svgWidth - margin.left - margin.right)
                  }
                  cy={
                    svgHeight -
                    margin.bottom -
                    ((yValue + maxAxisValueY) / (2 * maxAxisValueY)) *
                      (svgHeight - margin.top - margin.bottom) +
                    (yValue > 0 ? 5 / 8 : 0)
                  }
                  r={
                    hoveredItem && hoveredItem.id === item.id
                      ? scaleNumber(
                          5,
                          minCircleValue,
                          maxCircleValue,
                          minCircleSize,
                          maxCircleSize
                        ) + 2
                      : scaleNumber(
                          5,
                          minCircleValue,
                          maxCircleValue,
                          minCircleSize,
                          maxCircleSize
                        )
                  }
                  fill={item.color}
                  onMouseOver={() => handleMouseOver(item)}
                  onMouseOut={handleMouseOut}
                  onClick={() => {
                    handleCircleClick(item);
                  }}
                />
              );
            })}

          {/* Render X axis label */}
          <text
            x={svgWidth / 2}
            y={svgHeight}
            textAnchor="middle"
            fontSize="14"
            fill="#000"
          >
            {radioOption} Score
          </text>

          {/* Render Y axis label */}
          <text
            transform={`translate(${margin.left / 3}, ${
              svgHeight / 2
            }) rotate(-90)`}
            textAnchor="middle"
            fontSize="14"
            fill="#000"
          >
            % Change
          </text>
        </svg>

        {/* Display information label on hover */}
        {isOtp && Number(selectedRadio) !== 0
          ? sortedFactors?.map((item) => hoveredInfo(item, true))
          : sortedData?.map((item) => hoveredInfo(item, false))}
      </div>

      {/* Render legend */}
      <LegendWrapper svgHeight={svgHeight}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            height: 50,
            flexWrap: "wrap",
            maxWidth: "100%",
          }}
        >
          {((Number(selectedRadio) !== 0 && !isOtp) ||
            Number(selectedRadio) === 0) &&
            data.map((cat, i) => (
              <div
                key={cat?.category}
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onMouseOver={() => handleMouseOver(cat)}
                onMouseOut={handleMouseOut}
                onClick={() => handleCircleClick(cat)}
              >
                <span
                  style={{
                    backgroundColor: cat?.color,
                    width: "15px",
                    height: "15px",
                    display: "block",
                    borderRadius: "50%",
                  }}
                ></span>
                <span style={{ marginLeft: "5px" }}>{cat?.category}</span>
              </div>
            ))}

          {Number(selectedRadio) !== 0 &&
            isOtp &&
            factorData.map((cat, i) => (
              <div
                key={cat?.category}
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onMouseOver={() => handleMouseOver(cat)}
                onMouseOut={handleMouseOut}
                onClick={() => handleCircleClick(cat)}
              >
                <span
                  style={{
                    backgroundColor: cat?.color,
                    width: "15px",
                    height: "15px",
                    display: "block",
                    borderRadius: "50%",
                  }}
                ></span>
                <span style={{ marginLeft: "5px" }}>{cat?.label}</span>
              </div>
            ))}
        </div>
      </LegendWrapper>
    </div>
  );
};

const HoveredItem = styled.div`
  display: flex;
`;

// ChatDialogV should give the container the appearance of a chat bubble by adding a v shape to the bottom
const ChatDialogV = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #666d79;
  top: 100%;
  left: 44%;
  rotate: 180deg;
`;

const ItemLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  align-items: center;
`;

const LegendWrapper = styled.div`
  position: absolute;
  bottom: 0 - ${(props) => props.svgHeight}px;
  left: 50%;
  transform: translateX(-50%) translateY(-80%);
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
`;

const HoveredItemTitle = styled.div`
  display: flex;
  font-size: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const HoveredItemScore = styled.div`
  font-weight: bold;
`;

const HoveredInfo = styled.div`
  background-color: #666d79;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  justify-content: flex-start;
  opacity: 0.9;
  zindex: 1;
  display: flex;
  flex-direction: column;
`;

export default Chart;
