import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import DatePick from "../DatePicker";
import Metric from "../Metric";
import People from "../People";
import PlanComponent from "../PlanComponent/index";
import Progress from "../Progress";
import Anchor from "./Anchor";
import Sport from "./Sport";

const Phase1 = ({ categories, questions, data, setData }) => {
  const setFactor = (d) => {
    const _data = Object.assign({}, data);
    _data.metric.factor = d;
    setData(_data);
  };

  const setQ = (q) => {
    const _data = Object.assign({}, data);
    _data.metric.q = q;
    console.log(q, _data);
    setData(_data);
  };

  const setPrimary = (d) => {
    const _data = Object.assign({}, data);
    _data.primary.id = d;
    setData(_data);
  };

  const setAnchor = (d) => {
    const _data = Object.assign({}, data);
    _data.anchor.id = d;
    setData(_data);
  };

  const setSubAnchor = (d) => {
    const _data = Object.assign({}, data);
    _data.anchor.selected = d;
    setData(_data);
  };

  const setPlan = (d) => {
    const _data = Object.assign({}, data);
    _data.plan.id = d;
    setData(_data);
  };

  return (
    <Container>
      <Title>Create an action plan</Title>

      {/*			<Label>Title</Label>
			<Input />

			<Label>Description</Label>
			<TextArea />

			<Label>Timeline</Label>
			<DatePick />*/}

      <Label>Plan components</Label>
      <Metric
        questions={questions}
        selected={data.metric.factor}
        selected2={data.metric.q}
        setSelected={(d) => setFactor(d)}
        setSelected2={(d) => setQ(d)}
      />
      <Sport
        categories={categories}
        selected={data.primary.id}
        setSelected={(d) => setPrimary(d)}
      />
      <Anchor
        categories={categories}
        selected={data.anchor.id}
        selected2={data.anchor.selected}
        setSelected={(d) => setAnchor(d)}
        setSelected2={(d) => setSubAnchor(d)}
      />

      <PlanComponent selected={data.plan.id} setSelected={(d) => setPlan(d)} />
      {/*
			<Progress />*/}
    </Container>
  );
};

export default Phase1;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  font-size: 30px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
`;

const Input = styled.input`
  border-radius: 5px;
  height: 30px;
  width: 300px;
  outline: none;
  border: ${(props) =>
    props.error ? "1px solid #ff2d55" : "1px solid #9b9b9b"};
  margin-top: 5px;
  font-family: "Barlow", sans-serif;
  padding-left: 15px;
  margin-bottom: 15px;
`;

const Label = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  font-weight: bold;
`;
const TextArea = styled.textarea`
  height: 150px;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
`;
