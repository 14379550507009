import Admin from "assets/images/nav/admin.svg";
import Culture from "assets/images/nav/culture.svg";
import Mood from "assets/images/nav/mood.svg";
import Leadership from "assets/images/nav/leadership.svg";
import Home from "assets/images/nav/home.svg";
import Personality from "assets/images/nav/personality.svg";
import Settings from "assets/images/nav/settings.svg";
import Survey from "assets/images/nav/survey.svg";
import Management from "assets/images/nav/management.svg";
import MyMood from "assets/images/group-12.svg"

const data = [
  {
    icon: "home",
    link: "/dashboard",
    title: "Home",
    image: Home,
    role: 11,
    team: false,
  },
  {
    icon: "heartbeat",
    image: Culture,
    link: "/culture",
    title: "Team health",
    role: 9,
    team: true,
    subNav: [
      {
        icon: "chart bar outline",
        link: "/cultureanalytics",
        title: "Team health analytics",
        role: 1,
      },
    ],
  },
  {
    icon: "discussions",
    link: "/eq",
    title: "Emotional health",
    image: Mood,
    role: 9,
    team: true,
    subNav: [
      {
        icon: "chart bar outline",
        link: "/systems",
        title: "Mood Analytics",
        role: 1,
      },
    ],
  },
   {
    icon: "discussions",
    link: "/personal",
    title: "My mood",
    icon:"id badge outline",
    role: 11,
    team: true,
  },
  {
    icon: "dna",
    link: "/personality-dash",
    title: "Personality",
    image: Personality,
    role: 9,
    team: false,
  },
  {
    icon: "location arrow",
    link: "/leadership",
    title: "Leadership",
    image: Leadership,
    role: 5,
    team: false,
  },
  {
    "icon": "code branch",
    "link": "/HRDash",
    "title": "Admin Dashboard",
    "image":Admin,
    "role": 5,
    "team": false,
     "subNav": [
      {
        "icon": "chart bar",
        "link": "/newanalytics",
        "title": "Analytics",
        "role": 5
      }
    ]
  },
  {
    icon: "address book",
    link: "/management",
    title: "Team Management",
    image: Management,
    role: 5,
    team: false,
  },
  {
    icon: "setting",
    link: "/survey_settings",
    title: "Survey Settings",
    image: Survey,
    role: 5,
    team: false,
  },
  {
    icon: "user",
    link: "/settings/member",
    title: "Profile",
    image: Settings,
    role: 11,
    team: false,
  },
  {
    icon: "payment",
    link: "/get-payment",
    title: "Billing",
    role: 5,
  },
  {
    icon: "log out",
    link: "/account/logout",
    title: "Logout",
    role: 11,
    team: false,
  },
];


export default data;

export const CultureMenu = [
  {
    id: 1,
    name: "Resilience",
    text: "Resilience",
 
    value: 1,
  },
  {
    id: 2,
    name: "Clarity",
    text: "Clarity",
    value: 2,
  },
  {
    id: 3,
    name: "Communication",
    text: "Communication",
    value: 3,
  },
  {
    id: 4,
    name: "Trust",
    text: "Trust",
    value: 4,
  },
  {
    id: 5,

    name: "Impact",
    text: "Impact",
    value: 5,
  },
  {
    id: 6,

    name: "Alignment",
    text: "Alignment",
    value: 6,
  },
  // {
  //   id: 7,
  //   name: "Overview",
  // },
]

export const CultureQuestions = [{
  id: 1,
  value: 1,
  area: "Resilience",
  category: "Resilience",
  question: "I\u2019m able to handle difficulties and cope with challenges effectively right now."
}, {
  id: 2,
  value: 2,
  area: "Clarity",
  category: "Clarity",
  question: "I have a clear understanding of my role and what\u2019s expected of me right now on our team and within our team initiatives."
}, {
  id: 3,
  value: 3,
  area: "Communication",
  category: "Communication",
  question: "Information and ideas are being expressed clearly, directly, and respectfully lately within our team."
}, {
  id: 4,
  value: 4,
  area: "Trust",
  category: "Trust",
  question: "Lately, i\u2019ve felt comfortable sharing my honest opinions and ideas, even if they differ from others."
}, {
  id: 5,
  value: 5,
  area: "Impact",
  category: "Impact",
  question: "I understand how what I\u2019m working on right now contributes to our team\u2019s bigger mission or goal."
}, {
  id: 6,
  value: 6,
  area: "Alignment",
  category: "Alignment",
  question: "Lately on our team I feel like we share a common set of core values that direct our actions and behaviors."
}]
