import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {Search} from '../reports/Audit/Insights/difference_search'
import {
	SET_SELECTED_QUESTION,
	SET_SUB_NAV,
	SET_SELECTED_FILTERS,
	SET_NAVIGATION,
} from "constants/actions";
import { useNavigate } from "react-router-dom";

import {
  Pagination,
  Checkbox,
  Dimmer, Loader, Icon
} from "semantic-ui-react";
import Light from 'assets/images/UI/lightbulb.svg'
import RedFlag from 'assets/images/UI/red_flag.svg'
import GreenFlag from 'assets/images/UI/green_flag.svg'


const Insights = ({questions}) =>{

	const dispatch = useDispatch();
	const selected = useSelector((state) => state.audit?.filters, shallowEqual);
	const [page,setPage] = useState(1)
	const [checked,setChecked] = useState(false)
	const [changes,setChanges] = useState(false)
	const [demographic,setDemographics] = useState(false)
	const [layer,setLayers] = useState(1)
	const [loading,setLoading] = useState(true)
	const [tab,setTab] = useState(0)

	const filter_data = useSelector(
		(state) => state.audit?.filtered_data,
		shallowEqual
	);

	const filter_last_data = useSelector(
		(state) => state.audit?.filtered_last_data,
		shallowEqual
	);



	const categories = useSelector(
		(state) => state.audit?.category_structure,
		shallowEqual
	);

	const [data,setData] = useState([])

	useEffect(()=>{
		if(filter_data.length>0){
			setLoading(true)

		setData(Search(filter_data,categories,questions,checked,demographic,layer,filter_last_data))
		setTimeout(()=>{
			setLoading(false)
		},[400])
		}
		
	},[])


	useEffect(()=>{
		if(filter_data.length>0 && Object.keys(categories).length>0){
			setLoading(true)
			let search_data = Search(filter_data,categories,questions,checked,demographic,layer,filter_last_data)
			if(changes){
				search_data = search_data.filter(f=>f.hasOwnProperty('change')).filter(f=>f.change).sort((a,b)=>a.change-b.change)
			}
			setData(search_data)
			setTimeout(()=>{
				setLoading(false)
			},[400])
		}
		
	},[checked,filter_data,layer,demographic,changes,categories])


	const getSlice = () =>{
		if(tab==0){
			return data.sort((a,b)=>a.diff-b.diff).slice(0,3)
		}
		if(tab==2){
			return data.sort((a,b)=>Math.abs(b.change)-Math.abs(a.change)).slice(0,3)
		}
		return data.sort((a,b)=>b.diff-a.diff).slice(0,3)
	}

	useEffect(()=>{
		if(tab==2){
			setChanges(true)
		}
		else{
			setChanges(false)
		}
	},[tab])
	
	return (
		<Container>

			<div style={{width:'100%',marginBottom:0}}>
			<Title><Image src={Light}/>Insights</Title>
			<T1>The highest priority areas within your organization. Click below to see a list of all high level insights</T1>

			</div>

			<Tabs>
				<Tab selected={tab==0} onClick={()=>setTab(0)}>Red Flags</Tab>
				<Tab selected={tab==1} onClick={()=>setTab(1)}>Green Flags</Tab>
				<Tab selected={tab==2} onClick={()=>setTab(2)}>Changes</Tab>
			</Tabs>

			<Dimmer.Dimmable blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
         	{getSlice().map((item,i)=>{
				let select = {factor:item.factor,id:item.d}
				let n1 = {option:item.option1.id,id:item.category1,name:item.option1,subfactor:null}
				let n2 = {option:item?.option2?.id,id:item?.category2,name:item?.option2,subfactor:null}
				return (
					<Row>
						<div style={{display:'flex',width:'50%',alignItems:'center'}}>
						<Flag src={tab==0?RedFlag:GreenFlag}/>
						{/*<Num>{i+1}</Num>*/}
						<div style={{display:'flex',justifyContent:'flex-start',flexDirection:'column'}}>
{/*							<Warning color={getColor(item.diff)}>
								<Icon name={'warning'} style={{margin:0,marginTop:-4}}/>
							</Warning>*/}
						<Sub>{categories.categories.find(f=>f.id==item.category1).name}</Sub>
							<Option>{item.option1.name}</Option>
							
						</div>
					</div>
					{item.option2?
						<div style={{display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
							<Sub>{categories.categories.find(f=>f.id==item.category2).name}</Sub>
							<Option>{item.option2.name}</Option>
							
						</div>
						:""}
						

						<Col width={20}>
							{item.factor!==null?<Pillar>{questions.dimensions.find(f=>f.id-1==item.d).factors.find(f=>f.id-1==item.factor).title}</Pillar>:""}
						</Col>
						
						<Col width={40}>
						{/*{getFlags(item).map((item=><Flag data={item}/>))}*/}
						<Score>{changes? (item.change>0?'+':'')+((item.change)*10).toFixed(1) : item.diff.toFixed(1)}% {changes? 'since last survey':item.diff<0?'below norm':'above norm'}</Score>
						</Col>
					</Row>
					)
			})}
        </Dimmer.Dimmable>

			<Button>See all insights -></Button>
		</Container>
		)
}

export default Insights


const Warning = styled.div`
	width:30px;
	height:30px;
	border-radius:5px;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color:${props=>props.color};
	margin-right:10px;
`


const Container = styled.div`
	width:100%;
	font-family: 'Poppins';
	margin-top:30px;
	margin-bottom:40px;
	position:relative;
	width:calc(100% - 380px);
	padding-bottom:50px;
	min-height:400px;
`

const Row = styled.div`
	border:1px solid lightgrey;
	border-radius: 10px;
	display:flex;
	flex-direction:row;
	padding:15px;
	justify-content:space-between;
	align-items:center;
	width:100%;
	margin-bottom:5px;
`

const Option = styled.div`
	font-size:14px;
`

const Sub  = styled.div`
	font-size:8px;
	margin-bottom:-5px;
`

const Score = styled.div`
	color:#414141;
	font-size:14px;
	font-weight:500;
	margin-top:10px;

`

const Pillar = styled.div`
	font-weight: 400;
font-size: 8px;
background-color:#476DFA;
color:white;
border-radius: 100px;
padding:0px 10px;
width:150px;
display:flex;
justify-content:center;


`

const Col = styled.div`
	display:flex;
`

const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom:5px;
  color: #414141;
  display:flex;
  align-items:center;
`;

const T1 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 1.4;
display: flex;
align-items: center;
max-width:400px;
color: #7E7E7E;
padding-left:20px;
`

const Image = styled.img`
	width:15px;
	margin-right:5px;
`

const Button = styled.div`
	border-radius:5px;
	padding:2px;
	color:white;
	background-color:#476DFA;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	width:125px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	position:absolute;
	bottom:10px;
	right:0px;
`

const Flag = styled.img`
	width:20px;
	margin-right:15px;
`

const Tabs = styled.div`
	display:flex;
	margin-bottom:20px;
	font-family: "Inter", sans-serif;
	border-bottom: 1px solid #DFDFDF;
`

const Tab = styled.div`
		display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100px;
	padding: 20px 0px 5px 0px;
	// color:{props=>props.selected?'#ab710a':'#9b9b9b'};
	color: #9E9FA1;
	border-bottom:${props=>props.selected?'2px solid #476DFA':""};
	margin-bottom:-1px;
	font-size:12px;
	cursor:pointer;
`

const Num = styled.div`
	height:20px;
	width:20px;
	min-width:20px;
	border-radius:10px;
	border:1px solid #66D686;
	display:flex;
	align-items:center;
	justify-content:center;
	font-weight:400;
	font-size:12px;
	color:#66D686;
	margin-right:10px;
`