import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
	Icon,
	Modal,
	Loader,
	Dimmer,
	Progress,
	Select
} from "semantic-ui-react";
import Chart from "react-apexcharts";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";


const create_option_list = (categories, questions, type) => {
  let options = [];

  categories.categories.map((item) => {
    if (type == 1 || (type == 2 && item.priority != "primary")) {
      options.push({ text: item.name, value: item });
    }
  });

  if (type == 2) {
    let dimensions = questions.dimensions.length;
    if (dimensions != 1) {
      questions.dimensions[1].priority = "questions";
      options.push({
        text: questions.dimensions[1].title,
        value: questions.dimensions[1],
      });
    }
    questions.dimensions[0].priority = "questions";
    options.push({
      text: questions.dimensions[0].title,
      value: questions.dimensions[0],
    });
  }

  return options;
};



const Modals = (props) => {
	 const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);


  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({ surveyType: 6, auditTypeId: 3 });

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  });

  const [horizontal, setHorizontal] = React.useState();
  const [vertical, setVertical] = React.useState();
  const [anchor_list, setAnchorList] = React.useState();
  const [anchor_list2, setAnchorList2] = React.useState();

  useEffect(() => {
    if (get_survey_structure[0] && questionStructure) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1
      );
      setAnchorList(anchor_list);
      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2
      );
      setAnchorList2(anchor_list2);
      if (anchor_list) {
        setHorizontal(
          anchor_list.find((f) => f.value.priority == "secondary").value
        );
        setVertical(
          anchor_list.find((f) => f.value.priority == "primary").value
        );
      }
    }
  }, [get_survey_structure[0], questionStructure]);




	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={props.trigger}
			closeIcon
			size={"small"}
		>
			<Modal.Header style={{ padding: 30 }}>
				<Role>{props.title}</Role>

				<Question>{props.text}</Question>
			</Modal.Header>


		<Content>
				   <OptionArea>
				        <div style={{ marginRight: 10, display: "flex", marginBottom:10}}>
				          <Label>Unit of Analysis</Label>
				          <StyledSelect
				            placeholder="Select Survey Type"
				            options={anchor_list}
				            value={vertical}
				            id="anchors"
				            onChange={(e, t) => setVertical(t.value)}
				          />
				        </div>
				        <div style={{display: "flex", alignItems: "center"}}>
				          <Label>Target</Label>
				          <StyledSelect
				            placeholder="Select Survey Type"
				            options={anchor_list2}
				            value={horizontal}
				            id="anchors"
				            onChange={(e, t) => setHorizontal(t.value)}
				          />
				        </div>

				      </OptionArea>


			    <Prediction>
			    Predicted improvement in turnovers
			    <Button>30% reduction</Button>
			    </Prediction>

				

				<Title1>Improvement Required</Title1>
				<Table>
					
					<Row>
						<Feature>Trust</Feature>
					

							<BarContainer>
								<Bar>
									<Color value={55}/>
								</Bar>
								<Num>55%</Num>
							</BarContainer>

					</Row>
					<Row>
						<Feature>Performance Review Scores</Feature>
						

							<BarContainer>
								<Bar>
									<Color value={40}/>
								</Bar>
								<Num>40%</Num>
							</BarContainer>

					</Row>
					<Row>
						<Feature>Individual Agreeableness</Feature>
						

							<BarContainer>
								<Bar>
									<Color value={20}/>
								</Bar>
								<Num>20%</Num>
							</BarContainer>

					</Row>
					<Row>
						<Feature>Payroll Raises</Feature>
						

							<BarContainer>
								<Bar>
									<Color value={15}/>
								</Bar>
								<Num>15%</Num>
							</BarContainer>

					</Row>
					
				</Table>

		</Content>

	</Modal>
	);
};

export default Modals;


const Content = styled.div`
	padding:30px;
`


const Role = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #414141;
`

const Question = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;

color: #7E7E7E;

`


const Table = styled.div`
	width:100%;
	margin-bottom:30px;
	position:relative;
	margin-top:40px;
`

const Row = styled.div`
	display:flex;
	width:100%;
	border-top: 1px solid #DFDFDF;
	align-items:center;
	justify-content:space-between;
	padding:11px 17px;
`

const Feature = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */

cursor:pointer;
color: #7E7E7E;
width:250px;

&:hover{
	text-decoration:underline;
}
`

const Bar = styled.div`
	width:100%;
	border-radius:10px;
	height:12px;
	background: #C0C0C0;
	overflow:hidden;
`

const Color  = styled.div`
	width:${props=>props.value+'%'};
	height:100%;
	background-color:#5167DD;
	border-radius:4px;
`

const Num = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: right;

color: #7E7E7E;
margin-left:5px;
`

const TableTitle = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
margin-bottom:5px;
/* identical to box height */
position:relative;

color: #414141;
`

const Prediction = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 24px;
margin-bottom:30px;
/* identical to box height */
position:relative;
color: #414141;
display:flex;
align-items:center;
`

const Sub = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
margin-bottom:20px;
color: #7E7E7E;
max-width:500px;
`

const Average = styled.div`
	position:absolute;
	height:calc(100% + 40px);
	top:-35px;
	display:flex;
	align-items:center;
	flex-direction:column;
	justify-content:center;
	font-family: 'Poppins';
	width:100px;
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 18px;
left:${props=>'calc((100% - 240px)*'+props.score/100+' + 160px)'};

color: #7E7E7E;
`

const Line = styled.div`
	height:100%;
	width:1px;
	border-left:1px dashed grey;
`

const BarContainer = styled.div`
	width:calc(100% - 250px);
	display:flex;
	align-items:center;
`


const Optimization = styled.div`
	width:100px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	display:flex;
	align-items:center;
	color:#6D6D6D;
	`

const Title1 = styled.div`
	margin-left:260px;
	margin-bottom:-36px;
	font-family: 'Poppins';
	font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 18px;
display:flex;
align-items:center;
color:#7E7E7E;
`


const OptionArea = styled.div`
  display: flex;
  flex-direction:column;
  margin-bottom: 40px;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  width:200px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const Button = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 12px;
padding:10px 24px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;
border-radius:20px;
color: white;
border:1px solid #9E9FA1;
position:absolute;
right:0px;
background-color: #476DFA;
`