import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";


const NetworkError = ({retry}) => {

    return (
        <Container>
                <Title>Network issue</Title>
                <Description>
                   We experienced an issue trying to edit this plan. Please try again.
                </Description>
                <Retry onClick={retry}>
                    Retry
                </Retry>
        </Container>
    )

}

export default NetworkError

const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
    flex-direction:column;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
`

const Description = styled.div`

`

const Retry = styled.div`
background-color: #2D70E2;
color: white;
padding: 10px 20px;
border-radius:8px;
border:none;
cursor:pointer;
width:auto;
margin-top:20px;
`
