import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import { getSecondaryLabel } from "components/CWFPDF/pdfUtils";

import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  barGraphContainer: {
    fontFamily: "Raleway",
    width: "100%",
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: "left",
    paddingBottom: 5,
  },
  top: {
    width: "100%",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#aecbff",
    backgroundColor: "#aecbff",
    display: "flex",
    flexDirection: "row",
  },
  line: {
    position: "absolute",
    height: "100%",
    width: 1,
    borderLeftStyle: "dashed",
    borderLeftWidth: 1,
    borderColor: "grey",
    alignItems: "center",
    justifyContent: "center",
  },
  spacer: {
    width: "54%",
  },
  numContainer: {
    width: "46%",
    display: "flex",
    flexDirection: "row",
  },
  numberItem: {
    width: "9%",
    fontSize: 8,
  },
  chartContainer: {
    position: "relative",
    width: "100%",
  },
  chartRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    borderBottomWidth: 1,
    height: 12,
  },
  tableData: {
    fontSize: 8,
    borderRightWidth: 1,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  bar: {
    height: 8,
    alignItems: "center",
    opacity: 0.9,
    display: "flex",
    position: "relative",
    paddingTop: 2,
    paddingBottom: 2,
  },
});

const BarGraph = ({ title, average, data, categories, isFrench }) => {
  return (
    <View style={styles.barGraphContainer}>
      <Text style={styles.title}>{title}</Text>
      {data?.factorResponses && (
        <>
          <View style={styles.top}>
            <View style={styles.spacer} />
            <View style={styles.numContainer}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                return <Text style={styles.numberItem}>{item}</Text>;
              })}
            </View>
          </View>

          <View style={styles.chartContainer}>
            <View style={[styles.line, { left: "58.69%" }]} />
            <View style={[styles.line, { left: "62.68%" }]} />
            <View style={[styles.line, { left: "66.77%" }]} />
            <View style={[styles.line, { left: "70.86%" }]} />
            <View style={[styles.line, { left: "74.95%" }]} />
            <View style={[styles.line, { left: "79.04%" }]} />
            <View style={[styles.line, { left: "83.13%" }]} />
            <View style={[styles.line, { left: "87.22%" }]} />
            <View style={[styles.line, { left: "91.31%" }]} />
            <View style={[styles.line, { left: "95.4%" }]} />

            <View style={styles.chartRow}>
              <Text
                style={[
                  styles.tableData,
                  {
                    width: "35%",
                    color: "blue",
                  },
                ]}
              >
                {isFrench ? "Moyenne" : "Average"}
              </Text>
              <Text
                style={[
                  styles.tableData,
                  {
                    width: "20%",
                  },
                ]}
              >
                {average}
              </Text>
              <View
                style={[
                  styles.bar,
                  {
                    width: `${40.9 * ((average * 10) / 100)}%`,
                    backgroundColor: "blue",
                  },
                ]}
              />
            </View>

            {data.factorResponses.map((item) => {
              if (!item.average) {
                return null;
              }

              const label = getSecondaryLabel(item.id, categories);
              return (
                <View style={styles.chartRow}>
                  <Text
                    style={[
                      styles.tableData,
                      {
                        width: "35%",
                      },
                    ]}
                  >
                    {isFrench ? label?.fr : label?.name}
                  </Text>
                  <Text
                    style={[
                      styles.tableData,
                      {
                        width: "20%",
                      },
                    ]}
                  >
                    {item.average.toFixed(1)}
                  </Text>
                  <View
                    style={[
                      styles.bar,
                      {
                        width: `${40.9 * ((item.average * 10) / 100)}%`,
                        backgroundColor: label?.color,
                      },
                    ]}
                  />
                </View>
              );
            })}
          </View>
        </>
      )}
    </View>
  );
};

export default BarGraph;
