import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import ReportType from './ReportType'
import ReportSelect from './ReportSelect'
import CatSelect from './CatSelect'
import Permissions from './Permissions';

const ReportCreation = () =>{
    const [stage,setStage] = useState(1)

    const renderContent = () =>{
        switch(stage){
            case 1:
                return (
                <>
                <T1>Report Creation</T1>
                <Description>
                    Please select a report template option
                </Description>

            <Options>
                <ReportType
                    title={'Culture Summary'}
                    id={1}
                    next={()=>setStage(2)}
                    description={'This report summarizes the information for each culture factor. The data for each culture factor is analyzed and summarized.'}
                    typeTitle={'Culture'}/>

                <ReportType
                    title={'Gap Analysis'}
                    id={2}
                    next={()=>setStage(2)}
                    description={'This report summarizes the information for each culture factor. The data for each culture factor is analyzed and summarized.'}
                    typeTitle={'Culture'}/>

                    <ReportType
                    title={'Retention Analysis'}
                    id={3}
                    next={()=>setStage(2)}
                    description={'This report summarizes the information for each culture factor. The data for each culture factor is analyzed and summarized.'}
                    typeTitle={'Retention'}/>
            </Options>
            </>)
            case 2:
                return <ReportSelect next={()=>setStage(3)}/>
            case 3:
                return <CatSelect next={()=>setStage(4)}/>
            case 4:
                return <Permissions/>
            default:
                return ""
        }
    }

    return (
        <Container>
            <Center>
            {renderContent()}
            </Center>
        </Container>
    )

}


export default ReportCreation

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height:100%;
    flex-direction:column;
    font-family:'Raleway';
`

const Center = styled.div`
    width:100%;
    max-width:800px;
    display:flex;
    flex-direction:column;
    margin-top:20px;
`
const T1 = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:5px;
    font-family:'Raleway';
    color:#2A3039;
    line-height:1.5;
    margin-top:20px;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    letter-spacing:-0.35px;
`

const Description = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 0.5s;
`

const Options = styled.div`
    width:100%;
    display:flex;

    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`