import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";

const Scatter = ({step}) =>{
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        },200)
    },[step])

    const renderContent = () =>{
        switch(step){
            case 0:
                return (
                    <><Title>The effect of organization culture on safety</Title> 

                    <Description>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam ultricies, nunc nisl ultrices odio, quis aliquam nunc nisl ut nisl. Sed euismod, diam quis aliquam ultricies, nunc nisl ultrices odio, quis aliquam nunc nisl ut nisl.
                    </Description>

                    <Chart
                    options={data2.options}
                    series={data2.series}
                    type="line"
                    height={500}
                    /></>
                )
            case 1:
                return (
                    <>
                    <T2>How each factor impacts safety</T2>
                    <Description>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam ultricies, nunc nisl ultrices odio, quis aliquam nunc nisl ut nisl. Sed euismod, diam quis aliquam ultricies, nunc nisl ultrices odio, quis aliquam nunc nisl ut nisl.
                    </Description>
    
                    <Chart
                      options={data3.options}
                      series={data3.series}
                      type="bar"
                      height={500}
                    />
                    </>
                )
            case 2:
                return (
                    <>
                    <T2>Predicting relative risks in departments</T2>
                    <Description>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam ultricies, nunc nisl ultrices odio, quis aliquam nunc nisl ut nisl. Sed euismod, diam quis aliquam ultricies, nunc nisl ultrices odio, quis aliquam nunc nisl ut nisl.
                    </Description>
                    </>
                )
        }
    }



    return (
        <Container>
             {!loading && renderContent()}

          
        </Container>
    )
}

export default Scatter


const Container = styled.div`
    margin-top:30px;
    font-family:'Raleway';
    padding-bottom:100px;
`
const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`

const T2 = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:10px;
    margin-top:20px;
`

const Description = styled.div`

`

const data2 = {
    series: [
      {
        name: "Points",
        type: "scatter",
  
        //2.14, 2.15, 3.61, 4.93, 2.4, 2.7, 4.2, 5.4, 6.1, 8.3
        data: [
          {
            x: 1,
            y: 2.14,
          },
          {
            x: 1.2,
            y: 2.19,
          },
          {
            x: 1.8,
            y: 2.43,
          },
          {
            x: 2.3,
            y: 3.8,
          },
          {
            x: 2.6,
            y: 4.14,
          },
          {
            x: 2.9,
            y: 5.4,
          },
          {
            x: 3.2,
            y: 5.8,
          },
          {
            x: 3.8,
            y: 6.04,
          },
          {
            x: 4.55,
            y: 6.77,
          },
          {
            x: 4.9,
            y: 8.1,
          },
          {
            x: 5.1,
            y: 9.4,
          },
          {
            x: 7.1,
            y: 7.14,
          },
          {
            x: 9.18,
            y: 8.4,
          },
        ],
      },
      {
        name: "Line",
        type: "line",
        data: [
          {
            x: 1,
            y: 2,
          },
          {
            x: 2,
            y: 3,
          },
          {
            x: 3,
            y: 4,
          },
          {
            x: 4,
            y: 5,
          },
          {
            x: 5,
            y: 6,
          },
          {
            x: 6,
            y: 7,
          },
          {
            x: 7,
            y: 8,
          },
          {
            x: 8,
            y: 9,
          },
          {
            x: 9,
            y: 10,
          },
          {
            x: 10,
            y: 11,
          },
        ],
      },
    ],
    options: {
      chart: {
        height: "100%",
        type: "line",
        fontFamily: "Raleway",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        borderColor: "#e7e7e7",
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        size: [5, 0],
        strokeWidth: 1,
        radius: 2,
        strokeColors: "#476DFA",
      },
      tooltip: {
        enabled: false,
      },
      colors: ["#66D686", "#476DFA", "#E66579", "#EE7C35"],
      xaxis: {
        type: "numeric",
        tickAmount: 10,
        decimalsInFloat: 0,
        title: {
          text: "Culture Score",
          style: {
            fontSize: "12px",
            fontWeight: 400,
            color: "#7E7E7E",
          },
        },
      },
      yaxis: {
        tickAmount: 10,
        decimalsInFloat: 0,
        title: {
          text: "Safety Score",
          style: {
            fontSize: "12px",
            fontWeight: 400,
            color: "#7E7E7E",
          },
        },
      },
      legend: {
        show: false,
      },
    },
  };


  
const data3 = {
	series: [
		{
			data: [8.8,8.5,8.5,8.4,8.1,7.6,7.5,7.3,6.4,6.1],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#27CDA7", "#2D70E2"],
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 100],
              colorStops: [
                {
                  offset: 0,
                  color: "#27CDA7",
                  opacity: 0.5,
                },
                {
                  offset: 100,
                  color: "#2D70E2",
                  opacity: 0.5,
                }
              ],
            },
          },
		colors: ["#2D70E2"],
		xaxis: {
			categories: [
				"Psychological Safety",
                "Empathy",
                "Belonging",
                "Learning",
                "Values",
                "Clarity",
                "Resilience",
                "Accountability",
                "Impact",
                "Vision"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 10,
			decimalsInFloat: 1,
			title: {
				text: "",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
