import styled from "styled-components";
import React, { useEffect, useState } from "react";
import BasicDropdown from "../PlanComponent/Dropdown";
import { SURVEY_INFO } from "constants/surveys";
import {Dropdown, Item} from 'semantic-ui-react'

const organize_questions = (q) => {
  if (q.survey_type === SURVEY_INFO.edi.question_type) {
    return q.questions.factors
      .map((i, id) => {
        return {
          key: i.title,
          text: i.title,
          value: { title: i.title, f: id },
        };
      })
      .flat();
  }

  return q.questions.dimensions
    .map((i, id) => {
      return i.factors.map((x, idx) => {
        return {
          key: x.title,
          text: x.title,
          value: { title: x.title, d: id, f: idx },
        };
      });
    })
    .flat();
};

const getFactorIndex = (factor, options) => {
  if (options && factor) {
    const found = options.findIndex(
      (option) => option.key.toLowerCase() === factor.title.toLowerCase()
    );
    return found;
  }

  return 0;
};

const organize_questions2 = (q, s, o) => {
    let questions = [{ key: -1, text: "All questions", value: -1 }];
    questions.push(
      ...q.questions.map((qx, idx) => {
        return {
          key: qx.q,
          text: qx.q,
          value: idx,
        };
      })
    );

  return questions;
};

const Metric = ({
  questions,
  selectedFactor,
  setSelected2,
  selected2,
  editMode,
}) => {
  const [open, setOpen] = useState(false);
  const [options2, setOptions2] = useState([]);

  useEffect(() => {
    if (selectedFactor) {
      setOptions2(organize_questions2(questions, selectedFactor));
    }
  }, [selectedFactor]);


  return (
    <Container>
      <ItemContainer>
          <ItemLabel>Dimension: </ItemLabel>
          <ItemValue>{selectedFactor.charAt(0).toUpperCase() + selectedFactor.slice(1)}</ItemValue>
        </ItemContainer>
        <ItemContainer>
          <ItemLabel>Focus Area: </ItemLabel>
          <ItemValue>{options2?.[selected2?.[0]]?.text}</ItemValue>
        </ItemContainer>
    </Container>
  );
};

export default Metric;

const ItemContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  font-family: "Raleway";
  font-style: normal;
  align-items: center;
`;

const ItemLabel = styled.div`
font-family: "Raleway";
font-style: normal;
font-weight: 600;
  font-size: 14px;
line-height: 21px;
/* identical to box height */

color: #6d6d6d;
margin-right: 10px;
  min-width: 90px;
`;

const ItemValue = styled.div`
color: #2d70e2;
font-kerning: none;
font-variant-numeric: lining-nums proportional-nums;
font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
  "ss01" on, "salt" on, "liga" off;
/* Caption/Semibold 14 */
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 120%;
`;


const Container = styled.div`
  width: 100%;
  border-radius: 3px;
  padding-top: 15px;
  min-width: calc(60vw - 100px);
`;

const Top = styled.div`
  display: flex;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-right: 15px;
`;

const Description = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #7e7e7e;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 10px;
`;

const SelectionContainer = styled.div`
  display: flex;
  margin-top: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #7e7e7e;
  width: 100%;
  flex-direction: column;
`;

const Selected = styled.div``;

const templateOptions = [
  {
    key: "Strategic Gap Analysis",
    text: "Strategic Gap Analysis",
    value: "Strategic Gap Analysis",
  },
];
