import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Dial from './Dial'
import Down from 'assets/images/UI/down2.svg'
import Up from 'assets/images/UI/up2.svg'

const ScoreContainer = ({
  title,
  change,
  score,
  color,
  bgColor,
  addMargin,
}) => {
  return (
    <Container bgColor={bgColor} addMargin={addMargin}>
      <Title>{title} Score</Title>

      <Bottom>
        <ChangeMetrics>
          <Change
            color={
              change > 0
                ? "#1D9A7D"
                : change && !isNaN(change)
                ? "#F15062"
                : "#000"
            }
          >
            {(!isNaN(change) && change && (
              <>
                <Pic src={change < 0 ? Down : Up} /> {change > 0 ? "+" : ""}
                {change} %
              </>
            )) || <>--</>}
          </Change>
          <Sub>vs last assessment</Sub>
        </ChangeMetrics>

        <DialContainer>
          <Dial borderPercentage={score} borderColor={color} />
        </DialContainer>
      </Bottom>
    </Container>
  );
};

export default ScoreContainer;

const Container = styled.div`
  padding: 24px;
  background-color: ${(props) => props.bgColor};
  width: calc(33% - 20px);
  margin: ${(props) => (props.addMargin ? "0 10px 10px 0" : "0")};
  max-height: 175px;
  min-height: 175px;
`;

const Title = styled.div`
font-family: Raleway, sans-serif;
font-style: normal;
font-weight: 600;
font-size: 20px;
color: #2A3039;
`

const Bottom = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    justify-content:space-between;
`

const Change = styled.div`
font-family: Raleway, sans-serif;
font-style: normal;
font-weight: 500;
font-size: 16px;
color:${props=>props.color};
`

const Sub = styled.div`
font-family: Raleway, sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #666D79;
`

const Pic = styled.img`
font-family: Raleway, sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
margin-right:10px;
`

const ChangeMetrics = styled.div`
    width:50%;
`

const DialContainer = styled.div`
   width:100px;
`