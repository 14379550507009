import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { useLocation } from "react-router-dom";
import List from "./datastory/List";
import Create from "./datastory/Create";
import Success from "./components/Success";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./components/DeleteModal";
import {
  SG_DELETE_SCHEDULE_TEMPLATE,
  SG_DELETE_STORY_RECOMMENDATION,
} from "constants/actions";

const DataStory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stage, setStage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deletePending, setDeletePending] = useState(null);

  const { createdStory } = useSelector(
    (state) => ({
      createdStory: state.schedule_reports,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (createdStory?.created) {
      setShowSuccess(true);
      setLoading(false);
    } else {
      setShowSuccess(false);
    }
  }, [showSuccess, createdStory]);

  const deletePendingConfirm = (id) => {
    dispatch({
      type: SG_DELETE_SCHEDULE_TEMPLATE,
      payload: id,
    });
    setDeletePending(null);
  };

  const deleteItemConfirm = (id) => {
    dispatch({
      type: SG_DELETE_STORY_RECOMMENDATION,
      payload: id,
    });
    setDeleteItem(null);
  };

  const deleteModal = (
    <DeleteModal
      open={deleteItem || deletePending}
      setOpen={() => {
        setDeleteItem(null);
        setDeletePending(null);
      }}
      handleDeletePDFSavedState={() => {
        if (deleteItem) {
          deleteItemConfirm(deleteItem);
        } else {
          deletePendingConfirm(deletePending);
        }
      }}
    />
  );

  useEffect(() => {
    let pathname = location.pathname.split("/");

    switch (pathname[pathname.length - 1]) {
      case "list":
        setStage(0);
        break;
      case "create":
        setStage(1);
        break;
      default:
        setStage(0);
    }
  }, [location.pathname]);

  const renderContent = (stage) => {
    switch (stage) {
      case 0:
        return (
          <List
            setDeleteItem={(item) => {
              setDeleteItem(item);
            }}
            setDeletePending={(id) => {
              setDeletePending(id);
            }}
            loading={loading}
          />
        );
      case 1:
        return <Create />;
      default:
        return <List />;
    }
  };

  return (
    <Container>
      {deleteModal}
      {showSuccess && (
        <Success
          open={showSuccess}
          navFunction={() => {
            // Update for the data story.
            navigate("/leader/audit/reports/stories/create");
          }}
          onClose={() => {
            dispatch({
              type: "CLEAR_SCHEDULE_TEMPLATE",
            });
            setShowSuccess(false);
          }}
        />
      )}
      {renderContent(stage)}
    </Container>
  );
};;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  font-family: "Raleway";
  transition: all 0.3s ease-in-out;
`;

export default DataStory;
