import React, { useEffect, useState } from 'react';
import styled,{keyframes} from 'styled-components';
import { Icon } from 'semantic-ui-react';

import factor_content from './factor_content.json';


const checkContent = (factor) => {
  if (factor_content[factor]) {
    return factor_content[factor];
  } 
    return "";
  
};

const ROIDiagnosticStep2 = ({
  selectedOutcomeID,
  selectedOutcomeName,
  roi_data,
  onFactorSelected,
  goBack,
}) => {
  const [sortedData, setSortedData] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState(null);

  useEffect(() => {
    // Prepare and sort data
    const formattedData = roi_data
      .slice(0, 3)
      .map((item) => ({
        name: item.name,
        impact: parseFloat((item.impact * 100).toFixed(1)), // Impact as a percentage
        score: parseFloat((item.score * 10).toFixed(1)), // Score as a percentage
        id: item.id,
      }))
      .sort((a, b) => b.impact - a.impact); // Sort largest to smallest

    setSortedData(formattedData);
  }, [roi_data]);

  const handleSelect = (factor) => {
    setSelectedFactor(factor);
  };

  const selectFactor = (factor) => {
    onFactorSelected(factor);
  };

  const maxImpact = Math.max(...sortedData.map((item) => item.impact));

  return (
    <Container>
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <Title>
        Select a culture factor based on the optimal ROI for{' '}
        <Label>{selectedOutcomeName}</Label>
      </Title>
      <Description>
        These factors are predicted to have the highest impact on {selectedOutcomeName} based on our predictive model of your organization.
        Choosing a factor with high ROI is most likely to lead to a larger impact on {selectedOutcomeName} for a given amount of effort.
      </Description>

      <CardArea>
        {sortedData.map((factor) => (
          <ROICard
            key={factor.name}
            factor={factor.name}
            roiScore={factor.impact}
            onClick={() => handleSelect(factor)}
            maxScore={maxImpact}
            selected={selectedFactor && selectedFactor.name === factor.name}
            description={checkContent(factor.name)?.description}
          />
        ))}
      </CardArea>

      {selectedFactor ? (
        <SelectedAreaComponent
          factor={selectedFactor}
          selectFactor={selectFactor}
        />
      ) : (
        <T3>Select a factor above to view more details and continue</T3>
      )}
    </Container>
  );
};

export default ROIDiagnosticStep2;


// Selected Area Component
const SelectedAreaComponent = ({ factor, selectFactor }) => {
  const factorContent = checkContent(factor.name);
  return (
    <SelectedArea>
      <SelectedTitle>Selected Factor: {factor.name}</SelectedTitle>
      <SelectedDescription>{factorContent?.description}</SelectedDescription>

      <T2>Key Impact Areas:</T2>
      {factorContent.keyImpactAreas.map((area, index) => <li>{area}</li>)}

      <SmallButton onClick={() => selectFactor(factor)}>
        Continue with {factor.name}
      </SmallButton>
    </SelectedArea>
  );
};

// ROI Card Component
const ROICard = ({
  factor,
  roiScore,
  description,
  onClick,
  selected,
  maxScore,
}) => {
  return (
    <Card onClick={onClick} selected={selected}>
      <CardTitle>{factor}</CardTitle>
      <CardDescription>Projected ROI Improvement</CardDescription>

      <Score>
        <Icon name="arrow up" style={{ color: '#27CDA7', marginRight: '10px' }} />
        {roiScore}%
      </Score>

      <Bar>
        <BarFill width={(roiScore / maxScore) * 100} />
      </Bar>

      <CardDescription>{description}</CardDescription>
    </Card>
  );
};



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


// Styled Components
const Container = styled.div`
  padding: 20px;
  width:100%;
  margin: auto;
  font-family: 'Raleway', sans-serif;
   opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`;

const BackButton = styled.div`
  color: #2D70E2;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
    max-width:700px;

`;

const Label = styled.span`
  color: #2D70E2;
  font-weight: bold;
`;

const CardArea = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const Card = styled.div`
  padding: 20px;
  border: ${(props) => (props.selected ? '2px solid #2A3039' : '1px solid #DFDFDF')};
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
`;

const CardTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #2A3039;
  margin-bottom: 5px;
  line-height: 1.2;
`;

const CardDescription = styled.div`
  font-size: 12px;
  color: #666D79;
`;

const Score = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Bar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #DFDFDF;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const BarFill = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: #2D70E2;
`;

const SelectedArea = styled.div`
  background-color: #F8FAFF;
  padding: 20px;
  border-radius: 8px;
  color: #2A3039;
  margin-top: 20px;
`;

const SelectedTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SelectedDescription = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
`;

const T2 = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SmallButton = styled.div`
  padding: 6px 12px;
  background-color: #2D70E2;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: background-color 0.3s;
  display: inline-block;
  margin-top: 20px;

  &:hover {
    background-color: #1c4db8;
  }
`;

const T3 = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2A3039;
  margin-top: 40px;
  width: 100%;
  text-align: center;
`;
