import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Message, Button, Icon } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
// import Select from "react-select";

import Upload from "./Upload";
import List from "./List";
import Details from "./Details";
import { BasicErrorMessage } from "utilities/ErrorMessages";
import RedirectLink from "./RedirectLink";
import { saveAs } from "file-saver";

import {
	LOAD_TEAMS,
	PULL_ORGANIZATION,
	SG_GET_FILE_UPLOADS,
	SG_GET_SURVEY_STRUCTURES,
	CLEAR_ERRORS,
} from "constants/actions";

const AllPageOptions = [
	{
		id: 1,
		name: "Member upload lists",
	},
	{
		id: 2,
		name: "View Details",
	},
	{
		id: 3,
		name: "Upload a new member list",
	},
	{
		id: 4,
		name: "Preview Upload",
	},
];

const FileUploads = () => {
	const dispatch = useDispatch();
	const [organizationId, setOrganizationId] = useState(false);
	const [employee, setEmployee] = React.useState(false);

	const [showReportId, setShowReportId] = useState(false);
	const [showErrors, setShowErrors] = React.useState(false);
	const [fetchAgain, setFetchAgain] = useState(0);
	const [deletedRecord, setDeletedRecord] = useState(0);
	const [createEdit, setCreateEdit] = useState(0);
	const [showPage, setShowPage] = useState(AllPageOptions[0]);

	const EmployeeId = useSelector(
		(state) => Number(state.auth?.employee_id),
		shallowEqual
	);
	const AmbassadorId = useSelector(
		(state) => Number(state.auth?.ambassador_id),
		shallowEqual
	);

	const {
		get_auth,
		get_selectedOrg,
		get_employee,
		get_survey_structure,
		get_errors,
	} = useSelector(
		(state) => ({
			get_errors: state.errors,
			get_auth: state.auth,
			get_employee: state.employees,
			get_selectedOrg: state.selectedOrg,
			get_survey_structure: state.surveystructure.survey_structure,
		}),
		shallowEqual
	);

	useEffect(() => {
		// Run! Like go get some data from an API.
		// dispatch({ type: SG_GET_MOODS });
		if (Number(AmbassadorId) > 0) {
			if (organizationId) {
				dispatch({
					type: LOAD_TEAMS,
					payload: `organization=${organizationId}`,
				});
				dispatch({
					type: PULL_ORGANIZATION,
					payload: { id: organizationId },
				});
				dispatch({
					type: SG_GET_FILE_UPLOADS,
					payload: `organization=${organizationId}`,
				});
				dispatch({
					type: SG_GET_SURVEY_STRUCTURES,
					payload: `organization=${organizationId}`,
				});
				return;
			}
		} else {
			dispatch({ type: LOAD_TEAMS });
			dispatch({
				type: SG_GET_SURVEY_STRUCTURES,
			});

			if (Number(EmployeeId) > 0) {
				dispatch({
					type: SG_GET_FILE_UPLOADS,
				});
			}
			return;
		}
	}, [dispatch, AmbassadorId, organizationId, EmployeeId]);

	useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setOrganizationId(get_selectedOrg?.organization?.id);
		} else {
			setOrganizationId(Number(get_auth.organization_id));
		}
	}, [get_selectedOrg, AmbassadorId, get_auth]);

	useEffect(() => {
		setEmployee(get_employee[EmployeeId]);
		if (get_employee[EmployeeId]?.account_type === 5) {
			setCreateEdit(true);
			return;
		}

		if (get_employee[EmployeeId]?.role < 7) {
			setCreateEdit(true);
			return;
		}
		setCreateEdit(false);
	}, [get_employee, EmployeeId]);

	useEffect(() => {
		if (get_errors?.status === 403) {
			setShowErrors(get_errors?.data?.detail);
		}
	}, [get_errors]);

	function clearErrors() {
		dispatch({
			type: CLEAR_ERRORS,
		});
		setShowErrors(false);
	}

	const DemoFile = () => {
		saveAs(get_survey_structure?.[0]?.csv_template);
	};


	return (
    <Container>
      {showErrors && (
        <Message
          negative
          onDismiss={clearErrors}
          header="There was an Error!"
          content={showErrors}
        />
      )}

      <>
        {showPage?.id !== 1 && (
          <Back onClick={() => setShowPage(AllPageOptions[0])}>
            <Icon name="chevron left" />
            Back
          </Back>
        )}
        {showPage?.id === 1 && (
          <RedirectLink to={"management"}>
            <Back>
              <Icon name="chevron left" />
              Back
            </Back>
          </RedirectLink>
        )}
        <Title>{showPage?.name}</Title>

        <BasicErrorMessage />

        {get_survey_structure?.[0]?.csv_template ? (
          <Container>
            {/* Add New Modals */}

            {showPage?.id === 3 && (
              <Upload
                setFetchAgain={setFetchAgain}
                setShowReportId={setShowReportId}
                // new
                organizationId={organizationId}
                setShowPage={setShowPage}
                AllPageOptions={AllPageOptions}
              />
            )}

            {/* Show details of uploaded rows */}
            {showPage?.id === 2 ? (
              showReportId ? (
                <Details
                  showReportId={showReportId}
                  setShowReportId={setShowReportId}
                  setFetchAgain={setFetchAgain}
                  setDeletedRecord={setDeletedRecord}
                  // new
                  organizationId={organizationId}
                  setShowPage={setShowPage}
                  AllPageOptions={AllPageOptions}
                  get_survey_structure={get_survey_structure}
                />
              ) : (
                <p>Not a Relevant Upload File Selected</p>
              )
            ) : (
              ""
            )}

            {showPage?.id === 1 && (
              <>
                <Buttons>
                  <StyledButtonGrey onClick={() => DemoFile()}>
                    <Icon name="download" />
                    Download Sample
                  </StyledButtonGrey>

                  <StyledButton onClick={() => setShowPage(AllPageOptions[2])}>
                    <Icon name="upload" />
                    Upload File
                  </StyledButton>
                </Buttons>
                <Container>
                  <List
                    fetchAgain={fetchAgain}
                    setFetchAgain={setFetchAgain}
                    // selectedTeam={selectedTeam}
                    organizationId={organizationId}
                    showErrors={showErrors}
                    deletedRecord={deletedRecord}
                    createEdit={createEdit}
                    setDeletedRecord={setDeletedRecord}
                    setShowReportId={setShowReportId}
                    setShowPage={setShowPage}
                    AllPageOptions={AllPageOptions}
                  />
                </Container>
              </>
            )}
          </Container>
        ) : (
          <Message
            color="orange"
            icon="warning sign"
            header="Do not Modify before you read this"
            content={`We were unable to find a matching survey structure. This
							information needs to be uploaded before we start the uploads
							`}
          />
        )}
      </>
    </Container>
  );
};

export default FileUploads;

const Container = styled.div`
	margin-top: 20px;
`;

const Headers = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 28px;
	margin-top: 40px;
	margin-bottom: 20px;
`;

const Title = styled.div`
	font-size: 26px;
	font-weight: bold;
	margin-right: 20px;
	display: block;
	font-family: "Barlow", sans-serif;
	margin-top: 30px;
`;
const WideSelectCont = styled.div`
	width: 200px;
`;
const DropContainer = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
`;

const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2d50e2;
	border: none;
	outline: none;
	margin-top: 30px;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;

const StyledButtonGrey = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #595959;
	border: none;
	outline: none;
	margin-top: 30px;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #595959;
		color: #595959;
	}
`;

const Buttons = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Back = styled.div`
	font-family: "Barlow", sans-serif;
	color: #595959;
	margin-top: 30px;
	font-size: 16px;
	cursor: pointer;
`;
