import React,{useState} from 'react';
import styled from 'styled-components';
import {Table} from 'semantic-ui-react'
import {useNavigate} from 'react-router-dom'
import Stat from './Stat'
import Report from './Reports'
import Accountability from 'ProtoType/Accountability'
import Active from '../Active'

const ActiveDash = () =>{
    const navigate = useNavigate();
    const [tab,setTab] = useState(3)

    const renderContent = () =>{
        if(tab==0){
            return <Report/>
        }
        if (tab==2){
            return <Active/>
        }
        if(tab==3){
            return <Accountability/>
        }
        else{
            return (
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Department</Table.HeaderCell>
                            <Table.HeaderCell>Factor</Table.HeaderCell>
                            <Table.HeaderCell>Novelty</Table.HeaderCell>
                            <Table.HeaderCell>Quality</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Department A</Table.Cell>
                            <Table.Cell>Customer Satisfaction</Table.Cell>
                            <Table.Cell>Low</Table.Cell>
                            <Table.Cell>Very high</Table.Cell>
                            <Table.Cell><Button onClick={()=>navigate('/simple/prototype/recon/reports/conversation')}>View</Button></Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Department B</Table.Cell>
                            <Table.Cell>Customer Satisfaction</Table.Cell>
                            <Table.Cell>Moderate</Table.Cell>
                            <Table.Cell>Very high</Table.Cell>
                            <Table.Cell><Button onClick={()=>navigate('/simple/prototype/recon/reports/conversation')}>View</Button></Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Department C</Table.Cell>
                            <Table.Cell>Customer Satisfaction</Table.Cell>
                            <Table.Cell>High</Table.Cell>
                            <Table.Cell>Moderate</Table.Cell>
                            <Table.Cell><Button onClick={()=>navigate('/simple/prototype/recon/reports/conversation')}>View</Button></Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Department D</Table.Cell>
                            <Table.Cell>Customer Satisfaction</Table.Cell>
                            <Table.Cell>Low</Table.Cell>
                            <Table.Cell>Low</Table.Cell>
                            <Table.Cell><Button onClick={()=>navigate('/simple/prototype/recon/reports/conversation')}>View</Button></Table.Cell>
                        </Table.Row>
                        </Table.Body>
                </Table>
            )
        }
    }

    return (
        <Container>
            <Center>
                <Title>Change Management Reporting</Title>
                <Row>
                    <Stat text={'Total Conversations'} value={132}/>

                    <Stat text={'Solution Provided'} value={55}/>

                    <Stat text={'Knowledge Transfered'} value={80}/>
                </Row>

                <Tabs>
                    <Tab onClick={()=>setTab(3)} active={tab==3}>Accountability</Tab>
                    {/* <Tab onClick={()=>setTab(0)} active={tab==0}>Reports</Tab> */}
                    <Tab onClick={()=>setTab(2)} active={tab==2}>Active Conversations</Tab>
                    <Tab onClick={()=>setTab(1)} active={tab==1}>History</Tab>
                </Tabs>

               {renderContent()}
            </Center>
        </Container>
    )
}

export default ActiveDash;



const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:800px;
    `

const Button = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    width:100px;
    height:30px;
    background-color:#2D70E2;
    color:white;
    border-radius:5px;
    cursor:pointer;
`
const Title = styled.div`
    font-size:20px;
    font-weight:700;
    margin-bottom:20px;
    display:flex;
    justify-content:space-between;
    align-items:center;
`

const Row = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    margin-bottom:20px;
`

const Tabs = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-start;
    margin-bottom:20px;
    margin-top:30px;
`
const Tab = styled.div`
    padding:10px 20px;
    color:${props=>props.active ? 'blue' : '#2A3039'};
    border-bottom:${props=>props.active ?  '2px solid blue':'none'};
    margin-right:20px;
    cursor:pointer;
`