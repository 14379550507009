import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import {Table} from 'semantic-ui-react'

const Scatter = ({step}) =>{
    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        setLoading(true)
        setTimeout(()=>{
            setLoading(false)
        },200)
    },[step])


    return (
        <Container>
            <Title>The impact of Organizational Culture on Customer Satisfaction - Q4 2024</Title> 

            <Description>
            Understanding the intricate relationship between organizational culture and customer satisfaction is crucial for businesses aiming to enhance their service delivery. Research indicates that a positive organizational culture, characterized by strong internal communication, psychological safety, and adaptability, directly impacts employee morale and performance. This, in turn, translates to higher levels of customer satisfaction and loyalty.
            </Description>

            <Description>
            In Q4 2024, we conducted a comprehensive study across multiple store locations to analyze how various aspects of organizational culture influence customer satisfaction. Our findings reveal that clear and transparent communication from leadership fosters trust and alignment among employees, leading to a more cohesive and motivated workforce. Psychological safety, where employees feel valued and heard, enhances their willingness to go the extra mile for customers, thereby improving service quality and customer satisfaction. Furthermore, adaptability to changes, such as the introduction of new technologies or shifts in strategic priorities, ensures that employees remain resilient and customer-focused, even in dynamic environments.
            </Description>

            <T2>How NPS scores from store locations relate to key financial metrics.</T2>

            <Description>
            Net Promoter Score (NPS) is a critical indicator of customer loyalty and overall satisfaction. Our analysis demonstrates a significant correlation between NPS scores from various store locations and key financial metrics, including revenue growth, customer retention rates, and average transaction value. Stores with higher NPS scores consistently reported better financial performance, underscoring the importance of maintaining a customer-centric culture.
            </Description>
            <Chart
                      options={data2.options}
                      series={data2.series}
                      type="bar"
                      height={500}
                    />

            <T2>How culture predicts NPS</T2>
              <Description>
                  According to the most recent culture data collected, culture factors <strong>explain 43% of the variability</strong> in NPS scores at each store location.
                  The top three culture factors that predict NPS scores are adaptability, communication, and psychological safety.
              </Description>

              <Chart
                      options={data3.options}
                      series={data3.series}
                      type="bar"
                      height={500}
                    />
              <Description>
              Our analysis indicates that stores with high scores in these culture factors consistently achieve higher NPS scores, demonstrating the critical role of a positive workplace culture in driving customer satisfaction. Investing in initiatives that enhance adaptability, communication, and psychological safety can significantly boost NPS scores and, consequently, customer loyalty and business performance.
              </Description>


              <T2>Current culture factor drivers of NPS</T2>
              <Table>
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Cause</Table.HeaderCell>
      <Table.HeaderCell>Effect</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    <Table.Row>
      <Table.Cell>Leadership communication on company vision changes</Table.Cell>
      <Table.Cell>Positive impact on NPS as clear communication increases trust and alignment with company goals</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Introduction of regular feedback loops</Table.Cell>
      <Table.Cell>Increased psychological safety leading to higher NPS due to employees feeling heard and valued</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Implementation of cross-functional team projects</Table.Cell>
      <Table.Cell>Mixed reactions; boosts adaptability for some, while others feel overwhelmed, slightly affecting NPS</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Transparent handling of layoffs</Table.Cell>
      <Table.Cell>Slight drop in NPS due to inherent stress, but transparency mitigates long-term negative impact</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Frequent shifts in strategic priorities</Table.Cell>
      <Table.Cell>Decreased NPS due to perceived instability and lack of clear direction</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Inconsistent application of performance reviews</Table.Cell>
      <Table.Cell>Negative impact on NPS due to perceived unfairness and lack of clarity</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Sudden changes in management</Table.Cell>
      <Table.Cell>Negative effect on NPS due to uncertainty and disruption in established relationships</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Changes in internal communication platforms</Table.Cell>
      <Table.Cell>Mixed feedback; initial drop in NPS due to adjustment period, eventual increase as efficiency improves</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Rapid scaling of the organization</Table.Cell>
      <Table.Cell>Challenges in adaptability lead to decreased NPS due to growing pains and integration issues</Table.Cell>
    </Table.Row>
  </Table.Body>
</Table>


              <T2>Actions to be taken</T2>
              <Description>
              To improve NPS scores, organizations should focus on enhancing the identified culture factors. Firstly, fostering an adaptable workforce can be achieved through continuous training and development programs that prepare employees for changes and new challenges. Encouraging open and transparent communication from leadership helps build trust and ensures that all employees are aligned with the company’s vision and goals. Additionally, creating a psychologically safe work environment where employees feel valued and heard can lead to increased engagement and proactive customer service.
              </Description>

              <Description>
              Specific actions include implementing regular feedback loops to gather employee insights, promoting cross-functional team projects to improve collaboration and adaptability, and ensuring consistent application of performance reviews to maintain fairness and clarity. Transparent handling of organizational changes, such as layoffs or strategic shifts, is crucial to maintaining employee morale and trust. By addressing these areas, organizations can enhance their overall culture, leading to improved NPS scores and better customer satisfaction.
              </Description>


          
        </Container>
    )
}

export default Scatter


const Container = styled.div`
    font-family:'Raleway';
    padding-bottom:100px;
    position:relative;
`
const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
    line-height:1.2;
`

const T2 = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:20px;
`

const Description = styled.div`
  margin-bottom: 20px;
  line-height:1.8;
`

const Statement = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
`

const data2 = {
    series:[{
          name: 'Net Profit',
          data: [60, 68, 66, 85]
        }, {
          name: 'Revenue',
          data: [55, 53, 58, 88]
        }, {
          name: 'Free Cash Flow',
          data: [35, 41, 36, 88]
        }],
    options: {
      chart: {
        height: "100%",
        type: "line",
        fontFamily: "Raleway",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      stroke: {
        curve: "straight",
      },
      grid: {
        borderColor: "#e7e7e7",
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        size: [5, 0],
        strokeWidth: 1,
        radius: 2,
        strokeColors: "#476DFA",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Lowest Quartile",
          "Middle Quartile",
          "Middle Quartile",
          "Highest Quartile"],
        fill: {
            opacity: 0.7
          },
        title: {
          text: "Quartiles of cusomter satisfaction scores",
          style: {
            fontSize: "12px",
            fontWeight: 400,
            color: "#7E7E7E",
          },
        },
      },
      yaxis: {
        tickAmount: 10,
        decimalsInFloat: 0,
        title: {
          text: "Relative outcomes",
          style: {
            fontSize: "12px",
            fontWeight: 400,
            color: "#7E7E7E",
          },
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'left',
      },
    },
  };


  
const data3 = {
	series: [
		{
			data: [22,11,10],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              horizontal: false,
              distributed: true,
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              type: "vertical",
              shadeIntensity: 0.5,
              gradientToColors: ["#27CDA7", "#2D70E2"],
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 100],
              colorStops: [
                {
                  offset: 0,
                  color: "#27CDA7",
                  opacity: 0.5,
                },
                {
                  offset: 100,
                  color: "#2D70E2",
                  opacity: 0.5,
                }
              ],
            },
          },
		colors: ["#2D70E2"],
		xaxis: {
			categories: [
				"Adaptability",
                "Communication",
                "Psychological Safety",
			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#2D70E2", "#2D70E2"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 30,
      forceNiceScale: true,
      tickAmount:8,
			decimalsInFloat: 0,
			title: {
				text: "% prediction of NPS",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
