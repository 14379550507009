import styled, { keyframes } from "styled-components";
import React from "react";
import SelectFrequency from "../../../components/common/SelectFrequency";
import { Icon } from "semantic-ui-react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const CheckinFrequency = (props) => {
  const { onFrequencySelect } = props;
  const [selected, setSelected] = React.useState(false);

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        Set your check-in frequency
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Our check-in process is designed to keep you on track. No fluff, or
        useless messages. Just a quick and simple check-in to keep you focused.
        <u> You can change your check-in frequency at any time. </u>
      </SubtextExtended>

      <LINK delay={0.7}>
        <a>
          See a sample check-in <Icon name="external alternate" />
        </a>
      </LINK>
      <SelectArea delay={1}>
        <SelectFrequency
          selected={selected == 1}
          setSelected={() => {
            onFrequencySelect(1);
            setSelected(1);
          }}
          title="Weekly"
          item="You'll receive a check in on a weekly basis."
        />
        <SelectFrequency
          selected={selected == 2}
          setSelected={() => {
            onFrequencySelect(2);
            setSelected(2);
          }}
          title="Bi-weekly"
          item="Receive a check-in every 2 weeks."
        />
        <SelectFrequency
          selected={selected == 3}
          setSelected={() => {
            onFrequencySelect(3);
            setSelected(3);
          }}
          title="Monthly"
          item="Recieve a check-in once a month."
        />
      </SelectArea>
    </ScreenContainer>
  );
};

export default CheckinFrequency;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SelectArea = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LINK = styled.div`
  margin-bottom: 20px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;
