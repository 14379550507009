import styled, { keyframes } from "styled-components";
import React from "react";

import AdminTheme from './AdminTheme'
import ClusterAnalysis from '../cluster/index'
import Cluster from '../cluster/Cluster'
import Grid from './Grid'



const AdminDash = () =>{
	const [selected, setSelected] = React.useState(1)

	return (
		<Container>
				<Tabs>
					<Tab selected={selected==1} onClick={()=>setSelected(1)}>Overview</Tab>
					<Tab selected={selected==2} onClick={()=>setSelected(2)}>Connections</Tab>
					<Tab selected={selected==3} onClick={()=>setSelected(3)}>Clusters</Tab>
				</Tabs>

				{selected==1?
					<Grid/>:
					selected==2?
					<ClusterAnalysis/>:
					<Cluster/>}
		</Container>
		)
}



export default AdminDash



const Container = styled.div`
	padding:20px;
`


const Tabs = styled.div`
	display:flex;
	margin-bottom:40px;
`

const Tab = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px;
	font-size:16px;
	font-weight:${props=>props.selected?'bold':400};
	border-bottom:${props=>props.selected?'2px solid #6a00ff':400};
	color:${props=>props.selected?'black':'#9b9b9b'};
	cursor:pointer;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:24px;
	font-weight:bold;
	margin-bottom:40px;
`