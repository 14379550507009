import React from "react";
import styled from "styled-components";
import { Icon, Popup } from "semantic-ui-react";

const StatusBox = ({ type, tokenStatus, email_status }) => {
  // console.log(email_status)
  // console.log(email_status === 4)
  const getColor = () => {

    if (email_status === 4) {
      return "#F15062";
    }
    if (email_status === 3) {
      return "#F15062";
    }
    if (type === "Pending") {
      return "#FEA711";
    }
    if (type === "Error" || type === "Removed") {
      return "#F15062";
    }
    if (type === "No Response") {
      return "#666D79";
    }

    return "#1D9A7D";
  };

  const getbgColor = () => {
    if (email_status === 4) {
      return "#FEF3F4";
    }
    if (email_status === 3) {
      return "#FEF3F4";
    }
    if (type === "Pending") {
      return "#FFFAF1";
    }
    if (type === "Error" || type === "Removed") {
      return "#FEF3F4";
    }
    if (type === "No Response") {
      return "#F2F2F2";
    }

    return "#EAFBF7";
  };

  const getContent = () => {
    if (email_status === 3) {
      return "Hard Bounce";
    }
    if (email_status === 4) {
      return "Marked Spam";
    }
    if (type?.toLowerCase() === "archived") {
      return "Archived";
    }
    if (type?.toLowerCase() === "pending") {
      return "Delivered";
    }

    if (type?.toLowerCase() === "archived") {
      return "Archived";
    }

    if (type?.toLowerCase() === "enabled") {
      return "Enabled";
    }

    if (type?.toLowerCase() === "completed") {
      return "Completed";
    }
    if (type?.toLowerCase() === "no response") {
      return "No Response";
    }

    if (type?.toLowerCase() === "removed") {
      return "Removed";
    }

    return "Bounce";
  };

  return (
    <Popup
      trigger={
        <Container color={getColor()} bgColor={getbgColor()}>
          {getContent()}
          {/* {tokenStatus?.id === 3 ? (
            <Check>
              <Icon name="check" style={{ margin: 0, marginTop: -7 }} />
            </Check>
          ) : (
            ""
          )} */}
        </Container>
      }
      content={getContent()}
      inverted
      position="top center"
    />
  );
};

/******** Styles ****************************************************/

const Container = styled.div`
	padding: 5px;
	font-family: "Raleway", sans-serif;
	border-radius: 8px;
	background-color: ${(props) => props.bgColor};
	color: ${(props) => props.color};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
  font-size:10px;
  font-weight:600;
  padding:2px 4px;
`;

const Check = styled.div`
	position: absolute;
	top: -7.5px;
	right: -7.5px;
	color: white;
	background-color: green;
	height: 20px;
	width: 20px;
	border-radius: 10px;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export default StatusBox;
