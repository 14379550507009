import styled, { keyframes } from "styled-components";
import React from "react";

import SummaryData from "./SummaryData";
import Performance from "./Performance";
import Connections from "./experiments/Connections";
import NewCulture from "./NewCulture";
import NewEQ from "./experiments/NewEQ";
import NewSystems from "./experiments/NewSystems";
import UserExplore from "./experiments/UserExplore";
import Comments from "./experiments/Comments";
import CommentsV2 from "./experiments/CommentsV2";
import Dashboard from "./experiments/Dashboard";
import ThemeAnalytics from "./experiments/ThemeAnalytics";
import AdminTheme from "./Admin/AdminTheme";
import Network from "./Admin/Network";
import Grid from "./Admin/Grid";
import Dash from "./Admin/Dash";
import TeamExplore from "./Team/TeamExplore";
import OnBoarding from "./OnBoarding";
import Users from "./Blink/User";
import Maps from "./Blink/Maps";
import Analytics from "./Blink/Analytics";
import Savings from "./Blink/Savings";
import Practice from "./Debriefs/Practice";
import History from "./Debriefs/History";
import Schedule from "./Debriefs/Schedule";
import Predictions from "./Debriefs/Predictions";
import Game from "./Debriefs/Game";
import Audit from "../../reports/Audit/index";
import AuditDash from "../../reports/Audit/Dashboard/History";
import Risk from "../../reports/Audit/Risk/index";
import OKR from "../../reports/Audit/OKR/index";
import ROI from "../../reports/Audit/ROI/index";
import Prediction from "../../reports/Audit/OKR/Dashboard";
import TeamTool from "./TeamTool/index";
import Action from "./Action/index";
import Overview from "../../reports/EDI/Payroll/index";
import HeatMap from "./Heatmap";
import Member from "./Member/index";
import WhitePaper from "./Whitepaper";
import DataScience from "./DataScience";
import TeamSummary from "./TeamSummary";
import Inclusivity from "./Inclusivity";

const _Culture = () => {
  return (
    <Container>
      <Inclusivity />
    </Container>
  );
};

export default _Culture;

const Container = styled.div`
  max-width: 1200px;
  display: flex;
`;
