import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";


import TeamTable from './TeamTable'


const Teams = () =>{

	return (
		<div>
			<TeamTable />
		</div>
		)
}

export default Teams