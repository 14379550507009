import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon, Label,Checkbox } from 'semantic-ui-react'
import ClickAwayListener from "react-click-away-listener";

const Filters = ({setOpen,open,categories}) =>{
	console.log(open)
	const handleClickAway = () => {
		if(open){
			 setOpen(false);
		}
   
  };

	return (
		 <ClickAwayListener onClickAway={handleClickAway}>
			<Container show={open}>
				{categories?.categories.map((item=>{
					return <Option>
						<Title>{item.name}</Title>
						{item.options.map(o=>{
							return <div><Checkbox label={o.name} /></div>
						})}
					</Option>
				}))}
			</Container>
		 </ClickAwayListener>
		)
}


export default Filters


const Container = styled.div`
	position:absolute;
	left:0px;
	height:${props=>props.show?'auto':0};
	overflow:${props=>props.show?'':'hidden'};
	transition:height 0.5s;
	z-index:10;
	background-color:white;
	padding:${props=>props.show?'20px':'0px'};
	display:flex;
	box-shadow:1px 2px 9px rgba(0,0,0,0.1);
	font-family: "Barlow", sans-serif;

`

const Option = styled.div`
	width:150px;
	margin-right:10px;
`

const Title = styled.div`
	font-weight:bold;
`