import {
  GET_CHAT_RECOMMENDATION,
  GET_DATA_STORIES,
  GET_DATA_JOURNEYS,
  // EDIT_DATA_JOURNEY,
  FETCH_CHAT_RECOMMENDATION,
  ADD_CHAT_RECOMMENDATION,
  EDIT_CHAT_RECOMMENDATION,
  DELETE_CHAT_RECOMMENDATION,
  CHAT_RECOMMENDATION_ERROR,
  CLEAR_CHAT_RECOMMENDATION,
  SET_ACTIVE_RECOMMENDATION,
  SET_ACTIVE_JOURNEY,
  CLEAR_DATA_STORY,
  CLEAR_ALL,
  DELETE_STORY_RECOMMENDATION,
} from "constants/actions";

const initialState = {
  chat_recommendations: [],
  chat_recommendations_error: null,
  data_stories: [],
  data_journeys: [],
  active_recommendation: null,
  active_journey: null,
  pending: false,
};

export default function Recommendations(state = initialState, action) {
  switch (action.type) {
    case GET_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case GET_DATA_STORIES:
      return {
        ...state,
        data_stories: action.payload,
        pending: false,
      };
    case GET_DATA_JOURNEYS:
      return {
        ...state,
        data_journeys: action.payload,
        pending: false,
      };
    // case EDIT_DATA_JOURNEY:
    // return {
    //   ...state,
    //   data_journeys: action.payload,
    //   pending: false,
    // };

    case SET_ACTIVE_JOURNEY:
      return {
        ...state,
        active_journey: action.payload,
        pending: false,
      };

    case FETCH_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case ADD_CHAT_RECOMMENDATION:
      const currentRecommendations = state.chat_recommendations;
      currentRecommendations?.results?.unshift(action.payload);
      return {
        ...state,
        chat_recommendations: currentRecommendations,
        pending: false,
      };
    case EDIT_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case SET_ACTIVE_RECOMMENDATION:
      return {
        ...state,
        active_recommendation: action.payload,
        pending: false,
      };
    case DELETE_CHAT_RECOMMENDATION:
      return {
        ...state,
        chat_recommendations: action.payload,
        pending: false,
      };
    case DELETE_STORY_RECOMMENDATION:
      const currentRecs = state?.data_stories?.data?.filter(
        (recommendation) => recommendation.id !== action.payload
      );

      return {
        ...state,
        data_stories: {
          ...state.data_stories,
          data: currentRecs,
        },
        pending: false,
      };
    case CHAT_RECOMMENDATION_ERROR:
      return {
        ...state,
        chat_recommendations_error: action.payload,
        pending: false,
      };
    case CLEAR_DATA_STORY:
      return {
        ...state,
        data_stories: null,
      };
    case CLEAR_CHAT_RECOMMENDATION:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
