import React,{useState} from 'react';
import styled from 'styled-components';
import Papa from 'papaparse';
import Mapping from './Mapping'
import LabelModal from './LabelModal'
import Upload from './Upload'
import TopProgress from './TopProgress'
import ImportData from './Import'

// create a react component that takes in a csv upload and returns a table
// with the data
//

function CsvUploader() {
    const [file, setFile] = useState(null);
    const [columns, setColumns] = useState([]);
    const [step,setStep] = useState(0)
    
    const handleFileUpload = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        setFile(selectedFile);
        parseCsv(selectedFile);
      }
    };
  
   
const parseCsv = (csvFile) => {
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (result) => {
        if (result.data.length > 0) {
          const firstRow = result.data[0];
          const csvColumns = Object.keys(firstRow);
          setColumns(csvColumns);
        }
      },
      error: (error) => {
        console.error('CSV parsing error:', error);
        // Handle parsing error, e.g., show an error message to the user
      },
    });
  };

  React.useEffect(() => {
    console.log(columns)
    }, [columns]);
  
    const handleColumnSelect = (index, selectedOption) => {
      // Update the selected label for the specific column header.
      // You can use this function to store the mapping of columns to labels.
    };

    const getContent = () =>{
        switch(step){
            case 0:
                return <Upload handleFileUpload={handleFileUpload}/>
            case 1:
                return (
                    <>
                        <LabelModal/>
                        <Mapping columns={columns} handleColumnSelect={handleColumnSelect}/>
                    </>
                
                )
            case 2:
                return <ImportData columns={columns} />
            default:
                return <Upload onChange={handleFileUpload}/>
        }
    }
  
    return (
      <Container>
        <Center>

            <TopProgress current_step={step}/>

            {getContent()}
          
    

        <ButtonContainer>
            <Button onClick={()=>setStep(step+1)}>Next</Button>
        </ButtonContainer>
        </Center>
       
            
           
      </Container>
    );
  }
  
  export default CsvUploader;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family: 'Raleway';
  `

const Center = styled.div`
 width:800px;
  `

const Button = styled.button`

`

const ButtonContainer = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`