import react from "react";

const getComponentMapping = (componentName) => {
  switch (componentName) {
    case "HighestFactor":
      return "FactorScore";
    case "LowestFactor":
      return "FactorScore";
    case "LowestFactorFeedbackAI":
      return "AIFeedback";
    case "HighestFactorFeedbackAI":
      return "AIFeedback";
    case "LowestFactorExplanation":
      return "FactorExplain";
    case "HighestFactorExplanation":
      return "FactorExplain";
    default:
      return componentName;
  }
};

// This component will be used to render components in the components folder, these components make up the page structure and design.
// Select the component based on the name and version passed to it.
const Component = (props) => {
  const { reactComponent, componentVersion, categoryName, tracked } = props;
  let version = "v1";
  if (componentVersion) {
    version = componentVersion;
  }

  let componentData = {};
  if (props?.manualInputs) {
    // Loop through manualInputs, create an object using the componentMap as the property name
    // and value as the property value
    props.manualInputs.forEach((input) => {
      componentData[input.componentMap] = input.value;
    });
  }

  if (props?.dataRequirements) {
    componentData = {
      ...componentData,
      calculatedData: props.dataRequirements,
    };
  }

  // Map some of the earlier component names to the new component names
  // This is a bandaid fix 🩹
  const componentName = getComponentMapping(reactComponent);

  // Import the component based on the name and version
  const Component = require(`./${componentName}/${version}`).default;
  // Render the component
  return (
    <Component
      id={props?.componentId}
      {...componentData}
      manualInputs={props.manualInputs}
      categoryName={categoryName}
      tracked={tracked}
    />
  );
};

export default Component;
