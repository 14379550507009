import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input, Message, Pagination, Icon } from 'semantic-ui-react'

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Create from './create'
import {
  SET_AM_MENU,
  LOAD_ORGANIZATIONS,
  SET_AUTH_ORGANIZATION,
  GET_AM_ORGANIZATION,
  PULL_ORGANIZATION
} from "constants/actions";

const Login = () => {
  const dispatch = useDispatch();
  const [organizations, setOrganizations] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, seTotalPages] = useState(1);

  const [showMessage, setShowMessage] = useState(false)

  const { get_organizations, get_selectOrg } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_organizations: state.organizations,
      get_selectOrg: state.selectedOrg
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizations(get_organizations.organizations.results)
    seTotalPages(
      Math.ceil(get_organizations.organizations.count / get_organizations.organizations.page_size))
  }, [get_organizations]);

  useEffect(() => {
    dispatch({
      type: LOAD_ORGANIZATIONS,
      payload: `page=${activePage}`
    });
  }, [dispatch, activePage]);

  // console.log(totalPages)

  // console.log(get_organizations)

  function ChangedPage(e, { activePage }) {
    setActivePage(activePage)
  }

  function Paginate() {
    return <Pagination
      activePage={activePage}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      prevItem={organizations?.links?.previous ? true : null}
      nextItem={organizations?.links?.next ? true : null}
      totalPages={totalPages}
      onPageChange={ChangedPage}
      size='mini'
    />
  }

  function SetOrganization(org) {
    // console.log(org)
    dispatch({
      type: GET_AM_ORGANIZATION,
      payload: org
    });
    dispatch({
      type: SET_AUTH_ORGANIZATION, payload: org.id
    });

    dispatch({
      type: PULL_ORGANIZATION,
      payload: { id: org.id }
    })
  }


  function UpdateList() {

    const timeout = setTimeout(() => {
      dispatch({
        type: LOAD_ORGANIZATIONS,
        payload: `page=${activePage}`
      });

    }, 5000)

    return () => clearTimeout(timeout)
  }



  return (
    <CenterContainer>

      <CenterInnerContainer>
        <TopMenu>
          <Title>My Organizations</Title>
          <Create UpdateList={UpdateList} />
        </TopMenu>
        {showMessage &&
          (
            <Message positive
              onDismiss={() => setShowMessage(false)}
              header='Successfully Switched'
              content={`Successfully switched to ${showMessage?.name}`}
            />
          )
        }

        <Input icon='search' placeholder='Search...' style={{ width: '100%', marginBottom: 30 }} />

        {organizations &&
          organizations.map((org, k) => {
            return (
              <Card key={k} onClick={() => {
                SetOrganization(org)
                setShowMessage(org)
              }}>
                <Org>{org.name}</Org>


                {Number(get_selectOrg?.organization.id) === Number(org.id)
                  ?
                  (<div style={{ display: 'flex' }}>
                    <Icon name="check" style={{ fontSize: 20, color: 'green' }} />
                  </div>) :
                  <Icon name="chevron right" style={{ fontSize: 20, color: 'grey' }} />}




              </Card>
            )
          })
        }
        <PaginateMenu>
          <Paginate />
        </PaginateMenu>
      </CenterInnerContainer>

    </CenterContainer>)
}


export default Login


const CenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CenterInnerContainer = styled.div`
margin-top:40px;
height:100vh;
width:100vw;
`
const TopMenu = styled.div`
display: flex;
justify-content: space-between;
`

const PaginateMenu = styled.div`
display: flex;
justify-content: flex-end;
margin-top:20px;
width:100%
`

const Container = styled.div`
	height:100vh;
	width:100vw;
	// margin-left:auto;
	margin-top:-40px;
	display:flex;
	align-items: center;
  justify-content: center;

	font-family: "Barlow Semi Condensed", sans-serif;
	padding:30px;

	flex-direction:column;
`


const Title = styled.div`
	font-size:30px;
	font-weight:bold;
	line-height:1;
	margin-bottom:30px;
`
const Card = styled.div`
	padding:10px 5px;

  margin-bottom:10px;
  position:relative;
  display:flex;
  justify-content:space-between;
  align-items:center;
  cursor:pointer;

  &:hover{
  	background-color:rgba(0,0,0,0.1);
  }

`

const Org = styled.div`
  font-size:22px;
`

const Num = styled.div`
	font-size:14px;
`

const Component = styled.div`
	width:600px;

	background-color:rgba(0,0,0,0.02);
	padding:20px;
	border-radius:10px;
	margin-top:30px;
`

