import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import {Table,Icon, Step} from 'semantic-ui-react'
import TopProgress from './TopProgress'
import Add from './Add'
import Progress from './Progress'
import Filters from './Filters'
import { useNavigate,useParams } from 'react-router-dom';

import data from './data'
import Table2 from './Table'
import Success from './Success'

const steps =['Access','Planned','Check-ins','Review']

const Accountability = () =>{
    const navigate = useNavigate()
    let { myParam } = useParams();
    const [users,setUsers] = useState([])
    const [sortDir,setSortDir] = useState('desc')
    const [selected,setSelected] = useState('')
    const [open,setOpen] = useState(myParam=='success'?true:false)
    

    // useEffect(()=>{
    //     fetch('https://randomuser.me/api/?results=10&nat=us,dk,fr,gb')
    //     .then(response => response.json())
    //     .then(data => {
    //         setUsers(data)
    //     })
    //     .catch(error => {
    //       console.error('Error fetching data', error);
    //     });

    // },[])

    useEffect(()=>{
        if(users.length == 0){
            setUsers(data.sort((a,b)=>b.progress - a.progress))
        }
    },[])

    useEffect(()=>{
        if(selected == ''){
            setUsers(data.sort((a,b)=>b.progress - a.progress))
        }else{
            setUsers(data.filter(user=>user.filters.includes(selected)).sort((a,b)=>b.progress - a.progress))
        }
    },[selected])

    const sort = () =>{
        console.log('sorting')
        if(sortDir == 'desc'){
            setUsers(users.sort((a,b)=>a.progress - b.progress))
            setSortDir('asc')
        }else{
            setUsers(users.sort((a,b)=>b.progress - a.progress))
            setSortDir('desc')
        }
    }

    return (
        <Container>
            <Center>
            {/* <Row>
                <Title></Title>
                <Options>
                    <Filters selected={selected} onSelect={setSelected}/>
                    <Add/>
                </Options>
            </Row> */}
           <Title>
            Report History
            <Button onClick={()=>navigate('/leader/prototype/reportcreation')}>Create Report</Button>
            </Title>
            <Table2/>
{/* 
            <TableTop>
                <T1 width={40}>Member</T1>
                <T1 width={60} >Progress <IconContainer onClick={()=>sort()}><Icon name="sort"/></IconContainer></T1>
            </TableTop>
            
            {users?.map((user,i)=>{
                return <User key={i} user={user}/>

            }
            )} */}
            </Center>

            <Success open={open} close={()=>setOpen(false)}/>
            
        </Container>
    )

}

const User = ({user}) =>{
    const navigate = useNavigate();
    return (
        <UserContainer onClick={()=>navigate(`/leader/prototype/userprofile/${user.id.name}`)}>
            <MemberArea> 
                <UserImage src={user.picture.large}/>
                <div>
                    <UserName>{user.name.first} {user.name.last} </UserName>
                    <div style={{display:'flex'}}>
                    <Dep>{user.filters[0]}</Dep>
                    </div>
                </div>
            </MemberArea>
            

            <ProgressArea>
                <Progress completed={user.progress} />
            </ProgressArea>

            <Icon name="chevron right" style={{fontSize:18,color:"#B3B3B3",position:'absolute',right:-40}}/>
            
        </UserContainer>
    )
} 

export default Accountability

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    margin-top:40px;
    font-family:'Raleway';
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
`

const Center = styled.div`
    width:100%;
    max-width:800px;
    display:flex;
    flex-direction:column;

    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;

`

const Tag = styled.div`
    background-color:#DFDFDF;
    color:#666D79;
    padding:5px;
    display:flex;
    font-size:12px;
    border-radius:5px;
    align-items:center;
    justify-content:center;
`

const Button = styled.div`
    color:white;
    background-color:#2D70E2;
    font-size:16px;
    padding:10px 20px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    font-family:'Raleway';
    margin-bottom:40px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
`

const UserContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom:20px;
    cursor:pointer;
    position:relative; 
`
const UserImage = styled.img`
    width:50px;
    height:50px;
    border-radius:10px;
    margin-right:20px;
`

const UserName = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-right:20px;
    display:flex;
    align-items:center;
`

const UserEmail = styled.div`
    font-size:14px;
    color:#666D79;
    margin-right:20px;
`
const UserProgress = styled(Progress)`
    width:200px;
    margin-right:40px !important;
    margin-bottom:0px !important;
    margin-top:0px !important;
    padding:0;
`
const UserStatus = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;

`


const TableTop = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    margin-bottom:20px;
`
const T1 = styled.div`
    font-size:14px;
    font-weight:bold;
    width:${props=>props.width}%;
    margin-right:20px;
    display:flex;
`

const Dep = styled.div`
    font-size:12px;
    color:#666D79;
    background-color:#DFDFDF;
    padding:0px 5px;
    display:flex;
    border-radius:5px;
    margin-top:5px;
`

const ProgressArea = styled.div`
    width:60%;
`

const MemberArea = styled.div`
    width:40%;
    display:flex;
`

const Options = styled.div`
    display:flex;
    align-items:center;
    width:400px;
    justify-content:flex-end;
`

const Row = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    margin-bottom:40px;
`

const IconContainer = styled.div`
   
    margin-left:10px;
    cursor:pointer;
`