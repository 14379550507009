import styled, { keyframes } from "styled-components";
import React from "react";




const Cluster = () =>{

	return (
		<Container>
			<Title>Team Clusters</Title>
			<Description>The team cluster analysis looks for trends in the team intelligence survey responses and identifies clusters of similarity within your team. These patterns are then analyzed for the most meaningful traits which make these team members similar in how they responded to their survey.</Description>
			<ClusterContainer>
				<ACluster>
					<Number color={'#6a00ff'}>8</Number>
					<Content>
						<Line width={100} color={'#6a00ff'}/>
						<ClusterTitle>Group 1</ClusterTitle>
						<AvatarContainer>
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
						</AvatarContainer>

						<ClusterDescription>Similarities:</ClusterDescription>
						<ul>
							<Li>Significantly more feelings of motivation</Li>
							<Li>Moderately less feelings of stress</Li>
							<Li>Moderately less feelings of discontent</Li>
						</ul>

						<ClusterDescription>Most common theme: Growth Mindset</ClusterDescription>
						<ClusterDescription>Keywords:</ClusterDescription>

						<ul>
							<Li>season trying win</Li>
							<Li>bit worn end</Li>
							<Li>great team performing</Li>
						</ul>

					</Content>
				</ACluster>
				<ACluster>
					<Number color={'#08c251'}>6</Number>
					<Content>
						<Line width={75} color={'#08c251'}/>
						<ClusterTitle>Group 2</ClusterTitle>
						<AvatarContainer>
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							
						</AvatarContainer>

						<ClusterDescription>Similarities:</ClusterDescription>
						<ul>
							<Li>Moderately less feelings of motivation</Li>
							<Li>Moderately more feelings of discontent</Li>
							<Li>Moderately lower team resilience</Li>
						</ul>

						<ClusterDescription>Most common theme: Growth Mindset</ClusterDescription>
						<ClusterDescription>Keywords:</ClusterDescription>

						<ul>
							<Li>season trying win</Li>
							<Li>bit worn end</Li>
							<Li>great team performing</Li>
						</ul>


					</Content>
				</ACluster>

				<ACluster>
					<Number color={'#3078ff'}>5</Number>
					<Content>
						<Line width={62} color={'#3078ff'}/>
						<ClusterTitle>Group 3</ClusterTitle>
						<AvatarContainer>
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />							
						</AvatarContainer>

						<ClusterDescription>Similarities:</ClusterDescription>
						<ul>
							<Li>Significantly more feelings of stress</Li>
							<Li>Significantly more feelings of anger</Li>
							<Li>Moderately less feelings of enjoyment</Li>
						</ul>

						<ClusterDescription>Most common theme: Growth Mindset</ClusterDescription>
						<ClusterDescription>Keywords:</ClusterDescription>

						<ul>
							<Li>season trying win</Li>
							<Li>bit worn end</Li>
							<Li>great team performing</Li>
						</ul>


					</Content>
				</ACluster>
			</ClusterContainer>
		</Container>
		)
}


export default Cluster


const Container = styled.div`
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:20px;
	width:35%;
	margin-right:20px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:16px;
	font-weight:bold;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:30px;
`

const ACluster = styled.div`
	display:flex;
	width:100%;
	margin-bottom:20px;
`

const Number = styled.div`
	height:50px;
	width:50px;
	border:${props=>'1px solid '+props.color};
	border-radius:25px;
	font-family: "Red Hat Display", sans-serif;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:24px;
	font-weight:bold;
	color:${props=>props.color};
	margin-right:15px;
	min-width:50px;
`

const Content = styled.div`
	width:100%;
`

const Line = styled.div`
	width:${props=>'calc('+props.width+'% - 30px)'};
	height:5px;
	background-color:${props=>props.color};
	margin-bottom:5px;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	border-radius:15px;
	margin-right:5px;
`

const AvatarContainer = styled.div`
	display:flex;
`

const ClusterContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
	width:100%;
`

const ClusterTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
	margin-bottom:5px;
`

const ClusterDescription = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top:15px;
	font-weight:500;
`

const Li = styled.li`
	font-family: "Red Hat Display", sans-serif;
`