import React from "react";
import { View, Text, Image, StyleSheet, Page } from "@react-pdf/renderer";
import format from "date-fns/format";

import coverImage from "../../assets/SL-white.png";

const SportLawIntro = (props) => {
  const { selectedOrg } = props;

  const styles = StyleSheet.create({
    page: {
      position: "relative",
      height: "100vh",
      backgroundColor: "#54297C",
    },
    textarea: {
      position: "absolute",
      top: "10%",
      left: 80,
      color: "white",
    },
    title: {
      fontWeight: 700,
      fontSize: 30,
      width: 350,
      lineHeight: 1.2,
    },
    subTitle: {
      fontSize: 13,
      marginTop: 20,
      width: 350,
      lineHeight: 1.2,
    },
    bottom: {
      width: "100vw",
      height: 150,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      position: "absolute",
      bottom: 0,
    },
    bar: {
      width: 7,
      borderRadius: 2.5,
    },
    sub1: {
      fontSize: 18,
      fontWeight: 500,
      marginTop: 15,
      marginBottom: 240,
    },
    img: {
      width: 150,
      marginLeft: 0,
      marginBottom: 75,
    },
  });

  const bars = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28,
  ];
  const colors = ["#3e1d5c", "#260940", "#696969"];

  const formatReportDate = (dateTime) => {
    return format(dateTime, "MMMM do, yyyy");
  };

  return (
    <Page>
      <View style={styles.page}>
        <View style={styles.textarea}>
          <Image src={coverImage} style={styles.img} />
          <Text style={styles.title}>Culture Index</Text>
          <Text style={styles.sub1}>Overview and Key Findings </Text>
          <Text style={styles.subTitle}>
            Generated by Sport Law for {selectedOrg} on&nbsp;
            {formatReportDate(new Date())}
          </Text>
        </View>

        <View style={styles.bottom}>
          {bars.map((item) => (
            <View
              style={[
                styles.bar,
                {
                  height: 50 + (100 * item) / 28 + Math.random() * 100,
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                },
              ]}
            />
          ))}
        </View>
      </View>
    </Page>
  );
};

export default SportLawIntro;
