import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { CalendarIcon } from "./icons";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#666d79",
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  date: {
    color: "#666d79",
    fontSize: 10,
    fontWeight: 600,
    marginLeft: 10,
  },
});

const DateSelector = (props) => {
  const { date, title } = props;

  return (
    <View style={styles.container}>
      {title && <Text style={styles.title}>{title}</Text>}
      <View style={styles.dateContainer}>
        <CalendarIcon />
        <Text style={styles.date}>{date}</Text>
      </View>
    </View>
  );
};

export default DateSelector;
