import {
    GOOGLE_AUTH_PENDING,
    LOGIN_GOOGLE_AUTH,
    REGISTER_GOOGLE_AUTH,
    REGISTER_GOOGLE_AUTH_TOKEN,
    CLEAR_GOOGLE_AUTH,
} from "constants/actions";;

const initialState = {
    token: false,
    access_token: false,
    refresh_token: false,
    employee_permission: {},
    key: false,
    is_authenticated: false,
    user_id: false,
    role_id: false,
    organization_id: false,
    employee_id: false,
    ambassador_id: false,
    expires_in: false,
    is_staff: false,
    message: false,
    error: false,
    next_steps: 0,
    access_token_expires_at: false,
    refresh_token_expires_at: false,
    session_id: false,
    url: false,
    isLoading: false,
    organization_list: [],
    user: [],
    employee: [],
    organization: [],
    registered: [],
    forgot_password: [],
    reset_password: [],
    changed_password: [],
    register_org: [],
    register_token: [],

};


export default function GoogleAuth(state = initialState, action) {
    switch (action.type) {
        case GOOGLE_AUTH_PENDING:
            return {
                ...state,
                isLoading: true
            };
        case LOGIN_GOOGLE_AUTH:
            return {
                ...state,
                ...action.payload,
                is_staff: action.payload.is_staff,
                isLoading: false
            };
        case REGISTER_GOOGLE_AUTH:
            return {
                ...state,
                ...action.payload,
                register_org: action.payload,
                is_staff: action.payload.is_staff,
                isLoading: false
            }
        case REGISTER_GOOGLE_AUTH_TOKEN:
            return {
                ...state,
                register_token: action.payload
            }
    
        case CLEAR_GOOGLE_AUTH:
            return {
                ...state,
                access_token: false,
                refresh_token: false,
                employee_permission: {},
                token: false,
                key: false,
                is_authenticated: false,
                user_id: false,
                role_id: false,
                organization_id: false,
                employee_id: false,
                ambassador_id: false,
                expires_in: false,
                is_staff: false,
                message: false,
                error: false,
                next_steps: 0,
                access_token_expires_at: false,
                refresh_token_expires_at: false,
                session_id: false,
                url: false,
                isLoading: false,
                organization_list: [],
                user: [],
                employee: [],
                organization: [],
                registered: [],
                forgot_password: [],
                reset_password: [],
                changed_password: [],
                register_org: [],
                register_token: [],
            };
        default:
            return state;
    }
}