import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";

const colors = ['#6200EE','#03DAC5','#0336FF','#FF0266','#41c300']

const Label = (props)=>{

	return (
		<div style={{display:'flex',alignItems:'center',marginBottom:20}}>
			<Dot color={props.color}/>
			<div>
				<LTitle>{props.text}</LTitle>
				<Val>{props.val}</Val>
			</div>
		</div>
		)
}


const Bardata = [{'color':'#6200EE','year':2019,'value':33,highlight:true},
					{'color':'#03DAC5','year':2020,'value':43},
					{'color':'#0336FF','year':'Future','value':15}]

const Projectdata = [{'color':'#6200EE','year':'Scheduled','value':15},
					{'color':'#03DAC5','year':'Completed','value':10,highlight:true},
					{'color':'white','year':'','value':5}]

const ProjectBar = (props) =>{

	return (
		<div style={{display:'flex',marginTop:10,alignItems:'center',marginBottom:60}}>
			{props.data.map((item,i)=>{
				return (<ColorBar color={item.color} highlight={item.highlight} width={item.value/props.data.map(item=>item.value).reduce((a,b)=>a+b,0)}> 
					{item.value}
					<Year show={item.year}>{item.year}</Year>
				</ColorBar>)
			})}
		</div>
		)
}

const ColorBar = styled.div`
	height:${props=>props.highlight?'50px':'30px'};
	background-color:${props=>props.color};
	width:${props=>props.width*100+'%'};
	color:white;
	font-weight:bold;
	display:flex;
	align-items:center;
	padding-left:10px;
	position:relative;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`
const Year = styled.div`
	position:absolute;
	height:20px;
	bottom:-20px;
	left:15px;
	border-left:1px solid black;
	color:#9b9b9b;
	padding-left:5px;
	display:${props=>props.show?'flex':'none'};
`


const Initiative = () =>{

	return (
		<Box>
			<Width width={40}>
				<H3>Initiative A</H3>
				<Description>Description of this initiative here</Description>
			</Width>

			<Width width={20}>
				<H4>March 5th,2020</H4>
			</Width>

			<Width width={20}>
				<H4>Halifax, Nova Scotia</H4>
			</Width>

			<Width width={20}>
				<H4>$20,000</H4>
			</Width>
		</Box>
		)
}

const Box = styled.div`
	width:100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
	margin-top:10px;
	display:flex;
	align-items:center;

`

const H3 = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-weight:500;
	font-size:16px;
`

const H4 = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
`

const Width = styled.div`
	width:${props=>props.width+'%'};

`

const Description = styled.div`

`


const Savings = () =>{


	return (
		<Container>
			<Title>Return on investment analysis</Title>
			<Top>
				<ChartArea>
					<div style={{width:400,positon:'relative'}}>
						<CenterText>
							<LTitle>Total Savings</LTitle>
							<Value>$356,300.67</Value>
						</CenterText>
						<Chart options={data.options} series={data.series} type="donut" height={400} />
					</div>
					<Legend>
						<Label text={'Q1 2020 Savings'} val={'$30,540'} color={colors[0]}/>
						<Label text={'Q2 2020 Savings'} val={'$55,345'} color={colors[1]}/>
						<Label text={'Q3 2020 Savings'} val={'$3,540'} color={colors[2]}/>
						<Label text={'Q4 2020 Savings'} val={'$6,800'} color={colors[3]}/>
					</Legend>
				</ChartArea>

				<Projects>
					<LTitle>Initiatives completed per year</LTitle>

					<ProjectBar data={Bardata}/>

					<LTitle>2021 Scheduled Initiatives</LTitle>

					<ProjectBar data={Projectdata}/>
				</Projects>
			</Top>

			<H2>Initiatives and Impact </H2>
			<Thead>
				<TH width={40}></TH>
				<TH width={20}>Start Date</TH>
				<TH width={20}>Location</TH>
				<TH width={20}>Cost</TH>
			</Thead>
			<Initiative />
			<Initiative />
			<Initiative />
			<Initiative />
			<Initiative />
			<Initiative />
		</Container>
		)
}



export default Savings


const Container = styled.div`
	width:100%;
	margin-top:30px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:20px;
	font-weight:bold;
`

const H2 = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:18px;
	font-weight:bold;
	margin-top:20px;
`

const ChartArea = styled.div`
	display:flex;
	margin-top:50px;
`

const CenterText = styled.div`
	position:absolute;
	top:42%;
	width:100%;
	justify-content:center;
	display:flex;
	flex-direction:column;
	align-items:center;

`
const Value = styled.div`
	font-size:34px;
	font-weight:bold;
	margin-top:5px;
`

const LTitle = styled.div`
	color:#9b9b9b;
`

const Val = styled.div`
	font-size:20px;
	font-weight:bold;
`

const Legend = styled.div`
	margin-top:75px;
	margin-left:20px;
`
const Dot = styled.div`
	height:10px;
	width:10px;
	border-radius:5px;
	background-color:${props=>props.color};
	margin-right:10px;
`

const Projects = styled.div`
	width:500px;
	margin-top:100px;
	margin-left:100px;
`

const Top = styled.div`
	display:flex;
`

const Thead = styled.div`
	display:flex
`

const TH = styled.div`
	width:${props=>props.width+'%'};
	margin-top:40px;
	color:#9b9b9b;

`

const data = {
          
            series: [44, 55, 41, 17],
            options: {
              chart: {
                type: 'donut',
              },
              plotOptions: {
				    pie: {
				      donut: {
				        size: '75%'
				      }
				    }
				  },
               dataLabels: {
    				enabled: false
    			},
    			 legend: {
                    show:false
                  },
    			colors:['#6200EE','#03DAC5','#0336FF','#FF0266','#41c300'],
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  }
                }
              }]
            },
          
          
          }