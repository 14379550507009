import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Input } from 'semantic-ui-react'
import Icon from 'assets/images/UI/material-symbols_full-stacked-bar-chart.svg'

const SideNavigation = () =>{

    return (
        <Container>
            <Input icon='search' placeholder='Search...' style={{width:'100%'}}/>
            <CardArea>
                <Card title={'Innovation Potential'}/>
                <Card title={'Culture'}/>
                <Card title={'Organizational Agility'}/>
                <Card title={'Process Efficiency'}/>
            </CardArea>
            
        </Container>
    )
}

export default SideNavigation;


const Card = ({title}) =>{

    return (
        <CardContainer>
            <Title>
                <IMG src={Icon} />
                {title} - <B> 66%</B>
            </Title>
            <Text>
            The innovation potential for Department A is 66% which ranks it 8th in the organization overall. 
            </Text>

            <More>See More</More>
        </CardContainer>
    )
}

const Container = styled.div`
    width:250px;
`

const B = styled.span`
    font-weight:bold;
    font-size:20px;
    margin-left:5px;
`

const CardContainer = styled.div`
    padding:10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin-bottom:10px;
`

const Title = styled.div`
    display:flex;
    align-items:center;
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;;
`

const IMG = styled.img`
    width:20px;
    margin-right:10px;
`

const CardArea = styled.div`
    margin-top:30px;
`

const Text = styled.div`
    margin-top:10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #666D79;
`

const More = styled.div`
    margin-top:10px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #2D70E2;
    cursor:pointer;
    width:100%;
    display:flex;
    justify-content:flex-end;
`