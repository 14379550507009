import React, { useEffect } from 'react'
import styled from "styled-components";
import { Button, Icon, Modal } from 'semantic-ui-react'

import AddSchedule from "debriefs/AddSchedule"

import Pic1 from 'assets/images/calendar.png'
import Pic2 from 'assets/images/google-docs.png'

const TypesOfBriefs = [
    {
        id: 1,
        name: 'Game',
        describe: "Short Description in JSON Learn more opne new window",
        learnMore: "link goes here",
        image: Pic1
    },
    {
        id: 2,
        name: 'Practice',
        describe: "Short Description in JSON Learn more opne new window",
        learnMore: "link goes here",
        image: Pic2
    }
]


const AddNew = ({ showReportId,
    debriefSchedule, setFetchAgain,
    setShowReportId, ...props}) => {
    const [briefType, setBriefType] = React.useState(false)
    const [firstOpen, setFirstOpen] = React.useState(false)

    function ResetFirst() {
        setFirstOpen(false)
        if (!showReportId) {
            setBriefType(false)
        }
    }

    function CloseAll() {
        setFirstOpen(false)
        // setFetchAgain(1)
    }

    useEffect(() => {
        setBriefType(TypesOfBriefs.find((tb) => tb.id === debriefSchedule?.type_of))
    }, [debriefSchedule]);


    return (
        <>
           {showReportId ?
                <Button color='blue' onClick={() => setFirstOpen(true)}>Edit</Button>
                :
                <View onClick={() => setFirstOpen(true)}>Schedule Debrief </View>

           }
            <Modal
                onClose={() => setFirstOpen(false)}
                onOpen={() => setFirstOpen(true)}
                open={firstOpen}
                size="tiny"
            >
                <Modal.Header>Schedule a {briefType?.name} Debrief

                    <Button 
                        color='grey' 
                        floated='right' 
                        onClick={() => ResetFirst()}
                    >
                        <Icon name='cancel' /> Cancel
                    </Button>
                </Modal.Header>

                <Modal.Content>
                    <AddSchedule
                        briefType={briefType}
                        debriefSchedule={debriefSchedule}
                        briefId={showReportId}
                        CloseAll={CloseAll}
                        setFetchAgain={setFetchAgain}
                        setShowReportId={setShowReportId}
                    />
                </Modal.Content>

            </Modal>
        </>
    )
}

export default AddNew;


const View = styled.div`
    padding:10px 20px;
    background-color:#6a00ff;
    color:white;
    border-radius:20px;
    font-weight:bold;
    position:absolute;
    right:30px;
    cursor:pointer;
`