import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Modal,Dropdown } from 'semantic-ui-react'


const ResearchModal = () =>{
    const [open, setOpen] = React.useState(false)

        return (
            <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="small"
            trigger={<Linked>
                Read research summary <Up/>
               </Linked>}>
                <Content>
                    <Title>Investigating the antecedents of work disengagement in the workplace
                    </Title>
                    <Authors>
                        Usman, A., Muqadas, F., Imran, M., & Rahman, U. (2018). Investigating the antecedents of work disengagement in the workplace. Journal of Management Development, 37(2), 149-164.
                    </Authors>

                    <T2>Tldr</T2>

                    <T3>
                    This research paper investigates the causes of work disengagement in public sector 
                    organizations. The study found that work disengagement increases due to managers' 
                    personal preferences, unfairness, above the rule practices, negative political 
                    influence, work overload, and a lack of accountability in the workplace.
                    </T3>

                    <T2>Key Takeaways</T2>

                    <UL>
                        <LI>
                        Managers play a significant role in work disengagement. It is important for managers to be fair, unbiased, and provide a supportive work environment to prevent disengagement.
                        </LI>
                        <LI>
                        Organizational culture, especially bureaucratic culture, can have a strengthening effect on the relationship between work disengagement and factors like organizational injustice and political influence. Organizations should work towards developing a supportive and inclusive culture.
                        </LI>
                        <LI>
                        Work overload is another factor that contributes to work disengagement. Employers should ensure that employees are not overwhelmed with excessive work and provide necessary support, resources, and training to manage workload effectively.
                        </LI>
                    </UL>

                    <T2>
                        In depth
                    </T2>

                    <T4>
                        <p>Managers have a direct impact on employee engagement. When managers show personal preferences, unfairness, or engage in negative political influence, employees become disengaged and lose motivation. To prevent this, managers should ensure fairness in decision-making, provide equal opportunities, and create a positive work environment.</p>

                    <p>Organizational culture, especially bureaucratic culture, can exacerbate work disengagement. Bureaucratic organizations with rigid rules and structures can lead to feelings of injustice and lack of control, contributing to disengagement. Organizations should work towards fostering a culture that values fairness, transparency, and open communication.</p>

                    <p>Work overload is a significant contributor to work disengagement. When employees are overwhelmed with excessive workload and lack the necessary resources or support, they become disengaged and their productivity suffers. Employers should assess workload, provide necessary resources, and implement strategies to manage workload effectively, such as task prioritization and time management techniques.</p>

                    </T4>
                </Content>
            </Modal>
            )

}

export default ResearchModal;




const Up = () =>{
    return (<svg width="8" height="8" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginLeft:4}}>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1465 9C11.4226 9 11.6465 8.77614 11.6465 8.5V2.38419e-07H3.14648C2.87034 2.38419e-07 2.64648 0.223858 2.64648 0.5C2.64648 0.776143 2.87034 1 3.14648 1H10.6465V8.5C10.6465 8.77614 10.8703 9 11.1465 9Z" fill="#2A3039"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.146485 11.5C0.341747 11.6953 0.65833 11.6953 0.853592 11.5L11.5 0.853554C11.6953 0.658291 11.6953 0.341709 11.5 0.146446C11.3048 -0.0488155 10.9882 -0.0488155 10.7929 0.146446L0.146485 10.7929C-0.048777 10.9882 -0.048777 11.3047 0.146485 11.5Z" fill="#2A3039"/>
    </svg>)
}

const Linked = styled.div`
    margin-top:16px;
    font-size:12px;
    font-family: 'Raleway';
font-style: normal;
font-weight: bold;
cursor:pointer;
`

const Content = styled.div`
    padding:40px;
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: bold;
font-size: 24px;
color: #2A3039;
margin-bottom:20px;
line-height:32px;

`

const Authors = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
margin-bottom:32px;
line-height:18px;
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #2A3039;
margin-bottom:8px;
`

const T3 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
line-height:22px;
margin-bottom:24px;
`

const T4 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
white-space: pre-line;
line-height:22px;
`
const UL = styled.ul`
    margin-bottom:24px;
`

const LI = styled.li`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
margin-top:8px;
margin-bottom:8px;
line-height:22px;
`

