import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

import Department from "../../../assets/images/department.png";
import Team from "../../../assets/images/team.png";
import { useNavigate } from "react-router-dom";
import { CSSTransitionGroup } from "react-transition-group";
import "./department.css";

import { useForm, Controller } from "react-hook-form";
import { Message } from "semantic-ui-react";
import {
  Container,
  Title,
  Img,
  StyledInput,
  Invite,
  Button,
  Text,
  SubText,
  Input,
  Input2,
  DevTitle,
  InviteBox,
  Oval,
  Inner,
  Hide,
  SentContainer,
  Sent,
  LeaderContainer,
  InviteButton,
  AddContainer,
  Add,
  TeamContainer,
  InvitedContainer,
  ScrollContainer,
  InviteFormButton,
} from "../styled-list";

import TeamCard from './TeamCard'



const AddTeam = (props) => {
  return (
    <AddContainer>
      <Add onClick={() => props.onClick()}>+ Add Team</Add>
    </AddContainer>
  );
};

const DepartmentTeamCard = (props) => {
  let navigate = useNavigate();
  const [teams, setTeams] = React.useState([]);
  const [position, setPosition] = React.useState(0);
  const [selected,setSelected] = React.useState(0)
  const [created, setCreated] = React.useState([]);


  const progress = () => {
  	if(created.length!=teams.length){
  		 if (created.length >= 2) {
	      setPosition(position + 1);
	    }
	    setTeams(teams=>[...teams,created.length])
  	}
  };

  const finish = (x) =>{
  	setSelected(selected+1)
  	setCreated(created=> [...created,x]);
  }

  const back = () =>{
  	setSelected(selected-1)
  }

  const next = () =>{
  	if(created.length>selected){
  		setSelected(selected+1)
  	}else{
  		navigate('/app/management')
  	}
  	
  }

  return (
    <Container>
      <div style={{ width: "100%", height: 800, position: "relative" }}>
        <div
          style={{
            width: "100%",
            position: "relative",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <DevTitle>{props.department.name} Department</DevTitle>

           {created.length>=selected && selected!=0?<div
            style={{ position: "absolute", right: 80 }}
            onClick={() => back()}
          >
            back
          </div>:"" }
          <Button
            style={{ position: "absolute", right: 20 }}
            onClick={() => next()}
          >
            {created.length>selected ? "Next": "Finish"}
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            flexWrap: "nowrap",
            position: "relative",
          }}
        >
          <ScrollContainer position={position}>
          	<TeamCard 
          				 position={position} 
          				 type={1} 
          				 department={props.department} 
          				 selected={selected==0}
          				 i={0}
          				 created={created}
          				 setCreated={(x)=>finish(x)}/>
          	{teams.map((item,i)=>{
          		return <TeamCard 
          				 position={position} 
          				 type={1} 
          				 department={props.department} 
          				 selected={selected==i+1}
          				 i={i+1}
          				 created={created}
          				 setCreated={(x)=>finish(x)}/>
          	})}
              <AddTeam onClick={() => progress()} />
              <AddTeam onClick={() => progress()} />
          </ScrollContainer>
        </div>
      </div>
    </Container>
  );
};

export default DepartmentTeamCard