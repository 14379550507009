import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import useGetUserSettings from "hooks/useGetUserSettings";
import {Dropdown} from 'semantic-ui-react'

const calculate_options = (categories) => {
  return categories.categories
    .find((f) => f.priority === "primary")
    .options.map((i) => {
      return {
        key: i.name,
        text: i.name,
        value: i.id,
      };
    });
};

const People = ({
  categories,
  selected,
  setSelected,
  hideLabels,
  editEnabled,
  filteredCategoriesProp,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(calculate_options(categories));
  const [primaryLabel, setPrimaryLabel] = useState();
  const { filteredCategories, isAdmin } = useGetUserSettings();

  useEffect(() => {
    if (categories) {
      const primary = categories?.categories?.find(
        (c) => c.priority === "primary"
      );

      const filteredPrimaryOptions =
        (!isAdmin &&
          primary.options.filter((po) => {
            if (filteredCategoriesProp) {
              return filteredCategoriesProp?.includes(po.value_id);
            } else {
              return filteredCategories?.includes(po.name);
            }
          })) ||
        primary.options;

      if (primary) {
        let options = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((cat) => {
            if (
              (!cat.subfactors && cat.name) ||
              (cat.subfactors.length === 0 && cat.name)
            ) {
              options.push({
                key: cat.name,
                text: cat.name,
                value: cat.id,
              });
            }
          });
        }

        let subfactors = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
              });
            }
          });
        } else {
          primary.options.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
              });
            }
          });

          subfactors = subfactors.filter((sf) => {
            return filteredCategoriesProp?.includes(sf.value);
          });
        }

        if (subfactors.length > 0) {
          options.push(...subfactors);
        }

        setOptions(options);
      }
    }
  }, [categories, filteredCategories, isAdmin]);


  useEffect(() => {
    if (categories && !primaryLabel) {
      const found = categories.categories.find((f) => f.priority === "primary");
      if (found) {
        setPrimaryLabel(found.name);
      }
    }
  }, [categories, primaryLabel]);

  return (
    <Container>
      <Top onClick={() => setOpen(!open)}>
        {!hideLabels && (
          <>
            <Title>{primaryLabel}</Title>
            <Description>
              Which {primaryLabel?.toLowerCase()} is this plan for?
            </Description>
          </>
        )}
      </Top>
      <SelectionContainer>
        <Dropdown
          options={options}
          fluid
          selection
          placeholder={"Select an option"}
          value={selected}
          disabled={!editEnabled}
          onChange={(t, { value }) => {
            setSelected(value);
          }}
        />
      </SelectionContainer>
    </Container>
  );
};

export default People;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  margin-top:20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction:column;
  position: relative;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color:#2A3039;
  line-height: 21px;
  margin-right: 15px;
  margin-bottom:10px;
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 25px;
  color: #2A3039;
`;


const IconContainer = styled.div`
  position: absolute;
  right: 10px;
`;

const SelectionContainer = styled.div`
  display: flex;
  margin-top: 10px;
  border-radius: 100px;
`;

const Selected = styled.div``;

const templateOptions = [
  {
    key: "Strategic Gap Analysis",
    text: "Strategic Gap Analysis",
    value: "Strategic Gap Analysis",
  },
];
