import React, { useState, useEffect } from 'react';
import { Table, Button, Portal, Segment, Confirm, Modal, Form, Icon } from 'semantic-ui-react'
import styled from "styled-components";

import { useDispatch } from "react-redux";

import { UPDATE_ORGANIZATION } from "constants/actions";
// import NewSubFactor from './NewSubFactor'
// import NewSubFactorPortal from './NewSubFactorPortal'

const Styling = ({ organization, setUpdated }) => {
    const dispatch = useDispatch();
    const [openSubFactor, setOpenSubFactor] = useState(false)
    const [orgStyle, setOrgStyle] = useState({})
    const [styleList, setStyleList] = useState([])

    const [confirmOpen, setConfirmOpen] = useState(false)
    const [editRow, setEditRow] = useState(false)

    const [selectRow, setSelectRow] = useState(false)

    const [updateCellValue, setUpdatedCellValue] = useState('')

    useEffect(() => {
        if (organization?.styling) {
            setOrgStyle(organization?.styling)
            const _style_list = []
            for (const [key, value] of Object.entries(organization?.styling)) {
              
                if (key !== "rules") {
                    _style_list.push({
                        name: key,
                        value
                    })
                }

            }
            setStyleList(_style_list)
        }
    }, [organization]);

    function SelectSubFactorRow() {
        // console.log(item.id)
        // setRowId(item?.id)

        // setSurveyStruc(item)
        setOpenSubFactor(true)
        // setSelectedItem(item)
    }

    function NewRow(updatedStyle) {
        dispatch({
            type: UPDATE_ORGANIZATION,
            payload: {
                id: organization.id,
                styling: updatedStyle,
            },
        });
    }


    function RemoveFromList() {
        const _org_style = orgStyle
        delete _org_style[selectRow?.name]
        NewRow(_org_style)
        // setOrgStyle(_org_style)
        // setUpdated(Math.random())
        setSelectRow(false)
        setConfirmOpen(false)

    }

    function EditDetails(name) {

        const _org_style = orgStyle
        _org_style[name] = updateCellValue
        setOrgStyle(_org_style)
        // console.log(_org_style)

        NewRow(_org_style)
        setUpdated(Math.random())
        setSelectRow(false)
        setEditRow(false)
    }

    // console.log(selectRow)
    // console.log(orgStyle)

    function SelectedRow(params) {
        setEditRow(params?.name)
        setSelectRow({ name: params.name, value: params.value })
        setUpdatedCellValue(params.value)
    }

    function AddRow() {
        const _org_style = orgStyle
        _org_style[selectRow.name] = selectRow.value
        NewRow(_org_style)
        setOrgStyle(_org_style)
        setUpdated(Math.random())
        setOpenSubFactor(false)
        setSelectRow(false)
    }

    function SelectDeleteRow(params) {
        setConfirmOpen(true)
        setSelectRow({ name: params.name, value: params.value })
    }




    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {styleList && styleList.map((item, i) =>
                        <Table.Row key={i}>
                            <Table.Cell>
                                {item?.name}
                            </Table.Cell>
                            <Table.Cell >
                                {editRow === item?.name &&
                                    <Icon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setEditRow(false)}
                                        name='window close outline' />
                                }
                                {editRow === item?.name ?
                                    <input placeholder='Name'
                                        defaultValue={item?.value}
                                        onChange={(e) => setUpdatedCellValue(e.target.value)}
                                    />
                                    :
                                    <span
                                        onClick={() => SelectedRow(item)}
                                    >
                                        {item?.value}
                                    </span>
                                }
                            </Table.Cell>
                            <Table.Cell>
                                {editRow === item?.name ?
                                    <Button
                                        content='Update'
                                        onClick={() => {
                                            EditDetails(item?.name)
                                        }} />
                                    :
                                    <Icon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => SelectDeleteRow(item)}
                                        name='trash' />
                                }
                                <Confirm
                                    open={confirmOpen}
                                    header={`Delete ${selectRow?.name} and ${selectRow?.value}`}
                                    onCancel={() => setConfirmOpen(false)}
                                    onConfirm={() => RemoveFromList(i)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <StyledButton
                disabled={openSubFactor}
                onClick={() => SelectSubFactorRow()}
            >
                + Add New Row
            </StyledButton>
            <Modal
                open={openSubFactor}
                onClose={() => setOpenSubFactor(false)}
                onOpen={() => setOpenSubFactor(true)}
            >
                <Modal.Header>Add New</Modal.Header>
                <Modal.Content>

                    <Form>
                        <Form.Field>
                            <label>Name</label>
                            <input placeholder='Name'
                                onChange={(e) => setSelectRow({
                                    ...selectRow,
                                    name: e.target.value
                                })}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Value</label>
                            <input placeholder='Value'
                                onChange={(e) => setSelectRow({
                                    ...selectRow,
                                    value: e.target.value
                                })}
                            />
                        </Form.Field>
                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={AddRow}
                        primary>
                        <Icon name='save' /> Save Details
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default Styling;

const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`
const StyledButton = styled.button`
  background-color: ${props => props.disabled ? '#dcdcdc' : '#6a00ff'};
  color: white;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 7px 7px 18px;
  border: none;
  outline: none;
//   margin-top:30px;
 
  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;
