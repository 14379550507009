import React from "react";
import styled from "styled-components";
import Edit from "./Edit";

import SignupToken from "components/SignupToken";

const MemberCard = (props) => {
  // team tool only
  // step 2
const { data, setOpenFullCard,
  setOpenTeamCard, openTeamCard,
  auditEnabled, teamToolEnabled,
  fullEmpData, setFullEmpData,
  userEmployee,setLoading } = props

  // console.log("step 2", fullEmpData)

  return (
    <MemberContainer>
      <MemberDetails>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "calc(100% - 56px)",
            wordWrap: "break-word",
          }}
        >
          <Name>
            {" "}
            {data?.first_name ? data?.first_name + " " + data?.last_name : ""}
          </Name>

          <Role valid={data?.email_status_text === "Validated"}>
            {data?.email_status_text}
          </Role>
          {data?.email_status_text !== "Validated" && (
            <SignupToken
              organization_id={data?.organization}
              employee_id={data?.id}
            />
          )}
         
        </div>
      </MemberDetails>

      <RowContainer width={20} mobile={false}>
        <Text leader={data.role === 5}>{data.role_text}</Text>
        
      </RowContainer>

      <RowContainer width={15} mobile={false}>
        <Text>{data.team_name ? data.team_name : data.department_name}</Text>
      </RowContainer>
     
      <RowContainer width={30} mobile={false}>
        <Email>{data ? data.email : ""}</Email>
      </RowContainer>
      <RowContainer width={10} mobile={true}>
      {/* // team tool only */}
      {/* // step 2 */}
        <Edit
          data={data}
          setOpenFullCard={setOpenFullCard}
          setOpenTeamCard={setOpenTeamCard}
          openTeamCard={openTeamCard}
          auditEnabled={auditEnabled}
          teamToolEnabled={teamToolEnabled}
          setFullEmpData={setFullEmpData}
          fullEmpData={fullEmpData}
          userEmployee={userEmployee}
          setLoading={setLoading}
        />
      </RowContainer>
    </MemberContainer>
  );
};

export default MemberCard;

const MemberContainer = styled.div`
  width: 100%;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
`;
const Avatar = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 17px;
  border: solid 1px #e9e9e9;
  margin-right: 10px;
  min-width: 34px;
`;

const BlankAvatar = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #4a4a4a;
  margin-right: 10px;
  min-width: 46px;
`;

const Name = styled.div`
  font-size: 16px;
`;
const Email = styled.div`
  font-size: 14px;
`;
const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const Status = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => (props.status < 3 ? "#9b9b9b" : "black")};
`;

const Text = styled.div`
  color: ${(props) => (props.leader ? "#009021 " : "black")};
  font-size: 14px;
  font-family: "Barlow", sans-serif;
`;

const RowContainer = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

const MemberDetails = styled.div`
  width: 25%;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    width: 90%;
    padding: 0;
  }
`;
