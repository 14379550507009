import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import Chart from 'assets/images/UI/chart.svg'
import Arrow from 'assets/images/UI/arrow_blue.svg'
import Star from 'assets/images/UI/star.svg'

import Flag from 'assets/images/UI/blue_flag.svg'
import Light from 'assets/images/UI/lightbulb.svg'

import ResearchCard from './ResearchCard'
import Recommendation from './Recommendation'
import Filters from './Filters'
import { get_most_recent,
        filter_data,
        get_overall_average,
        get_last_responses,
        get_hr_flags,
        get_scores_per_pillar } from "./calculations";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ComparisonTable from "./ComparisonTable"
import Summary from "./Summary";
import SurveyVersions from "reports/Audit/VersionControl/index";
import { SG_GET_CHAT_RECOMMENDATION } from "constants/actions";
import { CheckIcon, CloseIcon } from "./icons";

const TagAnalytics = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [latest_data, setLatestData] = useState();
  const [last_data, setLastData] = useState();
  const [filtered_data, setFilteredData] = useState();
  const [filtered_last_data, setFilteredLastData] = useState();
  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );
  const [hasPendingRec, setHasPendingRec] = useState(false);
  const [overall_score, setOverallScore] = useState();
  const [last_overall_score, setLastOverallScore] = useState();
  const [overall_score_org, setOverallScoreOrg] = useState(0);
  const [pendingRecs, setPendingRecs] = useState();
  const [aiRecs, setAiRecs] = useState();
  const [hr_flags, setHrFlags] = useState(0);
  const [feedback_quality, setFeedbackQuality] = useState();

  const [pillar_scores, setPillarScores] = useState();

  const [standards, setStandards] = useState();

  const {
    get_culture_audit_reports,
    survey_version,
    recommendations,
    recommendationsError,
    get_standards,
  } = useSelector(
    (state) => ({
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      survey_version: state.audit.survey_version,
      recommendations: state.recommendations.chat_recommendations,
      recommendationsError: state.recommendations.chat_recommendations_error,
      get_standards: state.survey_standard.survey_standards,
    }),
    shallowEqual
  );

  const checkForPending = (recs) => {
    if (recs && recs?.length > 0) {
      const pendingRec = recs.filter(
        (rec) => Object.keys(rec.recommendation).length === 0
      );

      if (pendingRec && pendingRec?.length > 0) {
        return pendingRec;
      }
    }
    return null;
  };

  useEffect(() => {
    dispatch({
      type: SG_GET_CHAT_RECOMMENDATION,
    });
  }, []);

  useEffect(() => {
    if (survey_version) {
      setStandards(
        get_standards.find((f) =>
          f.question_sort_order.includes(survey_version.sort_order)
        )
      );
    }
  }, [get_standards, survey_version]);

  useEffect(() => {
    if (recommendations?.results?.length > 0) {
      const pendingRecs = checkForPending(recommendations?.results);
      const aiRecs = recommendations?.results.filter(
        (rec) =>
          Object.keys(rec.recommendation).length > 0 &&
          !rec.recommendation?.error
      );
      if (aiRecs?.length > 0) {
        setAiRecs(aiRecs);
      }
      if (pendingRecs?.length > 0) {
        setPendingRecs(pendingRecs);
      }
    } else {
    }
  }, [recommendations]);

  useEffect(() => {
    if (get_culture_audit_reports?.response) {
      let sorted_responses = get_culture_audit_reports?.response.sort(
        (a, b) => b.id - a.id
      );
      let most_recent = get_most_recent(sorted_responses);
      let last_responses = get_last_responses(sorted_responses);
      setLatestData(most_recent.responses);
      setLastData(last_responses);
      setLastOverallScore(get_overall_average(last_responses));
      setOverallScore(get_overall_average(most_recent.responses));

      let conversation_analytics = get_hr_flags(most_recent.responses);

      setHrFlags(conversation_analytics.flags);
      setFeedbackQuality(conversation_analytics.feedback_quality);
    }
  }, [get_culture_audit_reports]);

  useEffect(() => {
    if (latest_data && selectedFilters.length === 0 && survey_version) {
      setOverallScoreOrg(0);
      setLastOverallScore(get_overall_average(last_data));
      let conversation_analytics = get_hr_flags(latest_data);

      setHrFlags(conversation_analytics.flags);
      setFeedbackQuality(conversation_analytics.feedback_quality);

      setPillarScores(
        get_scores_per_pillar(
          latest_data,
          survey_version,
          latest_data,
          last_data,
          standards
        )
      );
      return setOverallScore(get_overall_average(latest_data));
    }

    if (latest_data && selectedFilters.length > 0) {
      setFilteredLastData(filter_data(last_data, selectedFilters));
      return setFilteredData(filter_data(latest_data, selectedFilters));
    }
  }, [latest_data, selectedFilters, survey_version, standards]);

  useEffect(() => {
    if (filtered_data && survey_version) {
      setOverallScore(get_overall_average(filtered_data));
      setOverallScoreOrg(get_overall_average(latest_data));
      setLastOverallScore(get_overall_average(filtered_last_data));

      let conversation_analytics = get_hr_flags(filtered_data);

      setHrFlags(conversation_analytics.flags);
      setFeedbackQuality(conversation_analytics.feedback_quality);

      setPillarScores(
        get_scores_per_pillar(
          filtered_data,
          survey_version,
          latest_data,
          filtered_last_data,
          standards
        )
      );
    }
  }, [filtered_data, survey_version]);

  const get_difference_score = (score1, score2) => {
    let diff = score1 - score2;

    if (diff > 0) {
      return "+" + (diff * 10).toFixed(0);
    } else {
      return (diff * 10).toFixed(0);
    }
  };

  const PendingComponent = (recs, hasError) => {
    // Get last pending rec in array
    //   const pendingRec = recs?.filter(
    //     (rec) => Object.keys(rec.recommendation).length === 0
    //   );
    // Get last item in pendingRec array
    //   const lastPendingRec = pendingRec[0];
    // Use the lastPendingRec if needed?
    //   console.log("tetstst");
    return (
      <PendingContainer hasError={hasError}>
        {(hasError && (
          <ContainerContent>
            <IconContainer hasError={hasError}>
              {CloseIcon("#F15062")}
            </IconContainer>
            <TextContainer>
              An error has occurred with your recommendation. Please try again
              later.
            </TextContainer>
          </ContainerContent>
        )) || (
          <ContainerContent>
            <IconContainer>{CheckIcon("#1D9A7D")}</IconContainer>
            <TextContainer>
              Your recommendation is being processed. You will receive an email
              when it is ready.
            </TextContainer>
          </ContainerContent>
        )}
        <CloseIconContainer onClick={() => setHasPendingRec(false)}>
          {CloseIcon("#666D79")}
        </CloseIconContainer>
      </PendingContainer>
    );
  };

  // console.log("pillar_scores", pillar_scores)
  // Default close color #666D79
  // Error color #F15062
  //

  return (
    <Container>
      <Title>Tag Analytics</Title>
      <Filters />
      <SurveyVersions />
      <ChatButton>
        Chat with this data
        <SVGIMG>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 256 256"
          >
            <path
              fill="white"
              d="M216 52H40a12 12 0 0 0-12 12v160a11.89 11.89 0 0 0 6.93 10.88A12.17 12.17 0 0 0 40 236a11.89 11.89 0 0 0 7.69-2.83l.06-.06l32.14-28.17a4 4 0 0 1 2.61-.94H216a12 12 0 0 0 12-12V64a12 12 0 0 0-12-12Zm4 140a4 4 0 0 1-4 4H82.5a12.1 12.1 0 0 0-7.79 2.87l-32.16 28.2A4 4 0 0 1 36 224V64a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4Z"
            />
          </svg>
        </SVGIMG>
      </ChatButton>

      <Row>
        <Score>
          <Block color={"#EAFBF7"}>
            <IMG src={Star} />
          </Block>
          <T1>
            {(overall_score * 10).toFixed(0)}%<Sub>Overall Score</Sub>
          </T1>
        </Score>
        <Score>
          <Block color={"#FFFAF1"}>
            <IMG src={Chart} />
          </Block>
          <T1>
            {overall_score_org
              ? get_difference_score(overall_score, overall_score_org) + "%"
              : "--"}
            <Sub>Relative Score</Sub>
          </T1>
        </Score>
        <Score>
          <Block color={"#EBF1FD"}>
            <IMG src={Arrow} />
          </Block>
          <T1>
            {last_overall_score
              ? (overall_score * 10).toFixed(0) -
                (last_overall_score * 10).toFixed(0) +
                "%"
              : "--"}
            <Sub>Change</Sub>
          </T1>
        </Score>
      </Row>

      <Row>
        <Score>
          <Block color={"#EBF1FD"}>
            <IMG src={Flag} />
          </Block>
          <T1>
            {hr_flags ? hr_flags : "--"}
            <Sub>HR Flags</Sub>
          </T1>
        </Score>
        <Score>
          <Block color={"#EBF1FD"}>
            <IMG src={Light} />
          </Block>
          <T1>
            {feedback_quality ? (feedback_quality * 10).toFixed(0) + "%" : "--"}
            <Sub>Feedback Quality</Sub>
          </T1>
        </Score>
        <Score>
          <Block color={"#EBF1FD"}>
            <IMG src={Arrow} />
          </Block>
          <T1>
            {filtered_data?.length
              ? filtered_data?.length
              : latest_data?.length}
            <Sub>Participation</Sub>
          </T1>
        </Score>
      </Row>

      <Text>
        The scores in this tag have scored <strong>19% below</strong> the
        organization averages.
      </Text>

      {hasPendingRec && PendingComponent(recommendations, recommendationsError)}
      <Tabs>
        <Tab selected={selected == 0} onClick={() => setSelected(0)}>
          Summary
        </Tab>

        <Tab selected={selected == 1} onClick={() => setSelected(1)}>
          Recommendations
        </Tab>

        <Tab selected={selected == 2} onClick={() => setSelected(2)}>
          Your Research
        </Tab>
      </Tabs>

      {selected == 0 ? (
        <>
          {pillar_scores ? <ComparisonTable data={pillar_scores} /> : ""}
          {/* <Summary data={selectedFilters.length>0?filtered_data : latest_data}/>
           */}
        </>
      ) : selected == 1 ? (
        <Recommendation
          data={selectedFilters.length > 0 ? filtered_data : latest_data}
          pillar_scores={pillar_scores}
          setPending={() => setHasPendingRec(true)}
          pendingRecs={pendingRecs}
          recommendations={aiRecs}
        />
      ) : (
        <>
          <T2>Curated & Summarized Research</T2>

          <Text>
            This research has been curated by AI to match your current needs. It
            was summarized to offer you the best insights right when you need
            it.
          </Text>
          <BlogContainer>
            <ResearchCard id={1} />
            <ResearchCard id={2} />
          </BlogContainer>
        </>
      )}
    </Container>
  );
};;;;;;;;

export default TagAnalytics;

const Container = styled.div`
    padding-left:58px;
    margin-top:60px;
    position:relative;
   
`

const Title = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;

`

const Tag = styled.div`
background: #EAFBF7;
border-radius: 8px;
padding: 4px 8px;
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 12px;
color: #1D9A7D;
width:auto;
display:inline-block;

`

const Block = styled.div`
    width:40px;
    height:40px;
    background-color:${props=>props.color};
    border-radius: 8px;
    margin-right:12px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const Score = styled.div`
    display:flex;
    margin-top:20px;
    margin-right:40px;
    width:150px;
`

const T1 = styled.div`
    display:flex;
    flex-direction:column;
    font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 16px;
color: #2A3039;
height:100%;
justify-content:center;
`

const Sub = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #666D79;
`

const Row = styled.div`
    display:flex;
    width:100%;
    
`

const IMG = styled.img`
    max-height:20px;
`

const Text = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
margin-top:30px;
width:100%;
margin-bottom:40px;
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 20px;
color: #2A3039;
margin-top:8px;
`

const Text2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #2A3039;
margin-top:16px;
`

const UL = styled.ul`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 150%;
color: #2A3039;
`

const Tabs = styled.div`
    display:flex;
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #666D79;
margin-bottom:34px;
`

const Tab = styled.div`
    padding-bottom:8px;
    border-bottom:${props=>props.selected?'2px solid #2D70E2':''};
    font-weight:${props=>props.selected?'bold':400};
    color:${props=>props.selected?'#2D70E2':'#666D79'};
    margin-right:${props=>props.selected?'37px':'40px'};
    cursor:pointer;
`

const BlogContainer = styled.div`
    display:flex;
    width:100%;
    margin-top:20px;
`

const ChatButton = styled.div`
padding: 4px 12px;
align-items: center;
display:flex;
font-family: Raleway;
font-size: 12px;
font-weight:600;
background: #2D70E2;
border-radius: 8px;
color:white;
position:absolute;
right:30px;
top:0px;
cursor:pointer;
`

const SVGIMG = styled.div`
    width:20px;
    margin-top:5px;
    margin-left:5px;
`


const PendingContainer = styled.div`
    background: ${props => props.hasError ? "#FEF3F4" : "#EAFBF7"};
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    font-family: 'Raleway';
    max-width: 800px;
`;

const ContainerContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
`;

const IconContainer = styled.div`
    background: ${props => props.hasError ? "#FCDCE0" : "#D4F5ED"};
    border-radius: 8px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    margin-right: 16px;
`;

const TextContainer = styled.div``;

const CloseIconContainer = styled.div`
    margin-left: auto;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
`;
