import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Progress } from 'semantic-ui-react'

const Recommendation = () => {

    return (
      <Container>
        <Card width={60}>
          <Title>Recommendation</Title>
          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            auctor, nisl eget ultricies tincidunt, nunc nisl aliquam mauris,
            vitae ultricies nunc lorem eget massa. Nullam auctor, nisl eget
            ultricies tincidunt, nunc nisl aliquam mauris, vitae ultricies nunc
            lorem eget massa.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            auctor, nisl eget ultricies tincidunt, nunc nisl aliquam mauris,
            vitae ultricies nunc lorem eget massa. Nullam auctor, nisl eget
            ultricies tincidunt, nunc nisl aliquam mauris, vitae ultricies nunc
            lorem eget massa.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            auctor, nisl eget ultricies tincidunt, nunc nisl aliquam mauris,
            vitae ultricies nunc lorem eget massa. Nullam auctor, nisl eget
            ultricies tincidunt, nunc nisl aliquam mauris, vitae ultricies nunc
            lorem eget massa.
          </Description>
        </Card>

        <Card width={30}>
          <Title>Predicted Impact</Title>
          <Description>
            Predictions based on machine learning models and historical data.
          </Description>
          <Row>
            <T2>Retention</T2>
            <T3>+8%</T3>
          </Row>

          <Row>
            <T2>Well-being</T2>
            <T3>+5%</T3>
          </Row>

          <Row>
            <T2>Safety</T2>
            <T3>+3%</T3>
          </Row>

          <Row>
            <T2>Financial Growth</T2>
            <T3>+2%</T3>
          </Row>
        </Card>

        <Card width={60}>
          <Title>Tagged plan description</Title>

          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            auctor, nisl eget ultricies tincidunt, nunc nisl aliquam mauris,
            vitae ultricies nunc lorem eget massa. Nullam auctor, nisl eget
            ultricies tincidunt, nunc nisl aliquam mauris, vitae ultricies nunc
            lorem eget massa.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            auctor, nisl eget ultricies tincidunt, nunc nisl aliquam mauris,
            vitae ultricies nunc lorem eget massa. Nullam auctor, nisl eget
            ultricies tincidunt, nunc nisl aliquam mauris, vitae ultricies nunc
            lorem eget massa.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
            auctor, nisl eget ultricies tincidunt, nunc nisl aliquam mauris,
            vitae ultricies nunc lorem eget massa. Nullam auctor, nisl eget
            ultricies tincidunt, nunc nisl aliquam mauris, vitae ultricies nunc
            lorem eget massa.
          </Description>
        </Card>

        <Card width={30}>
          <Title>Plan resources</Title>
          <Description>Resources from our database.</Description>
          <Row>
            <T2>Resource 1</T2>
          </Row>

          <Row>
            <T2>Resource 2</T2>
          </Row>

          <Row>
            <T2>Resource 3</T2>
          </Row>

          <Row>
            <T2>Resource 4</T2>
          </Row>
        </Card>

        <Card width={60}>
          <Title>Factors that most effect outcomes</Title>
          <Factor
            title={"Appropriate delegation of tasks to key stakeholders"}
            outcome={"+20%"}
          />

          <Factor
            title={"Engagement and priority assigned to deliverying this plan"}
            outcome={"+15%"}
          />

          <Factor
            title={"Appropriate delegation of tasks to key stakeholders"}
            outcome={"+6%"}
          />

          <Factor title={"No resource allocation"} outcome={"-30%"} />

          <Factor title={"No engagement or time to commit"} outcome={"-50%"} />
        </Card>
      </Container>
    );

    }

export default Recommendation;


const Factor = ({title,outcome}) =>{

    return (
        <FactorContainer>
            <FactorRow>
                <FactorTitle>
                    {title}
                </FactorTitle>
                <FactorPrediction>
                    {outcome}
                </FactorPrediction>
            </FactorRow>
            
        </FactorContainer>


    )
}

const Container = styled.div`
    padding-left:58px;
    margin-top:20px;
    display:flex;
    flex-wrap:wrap;
`

const Card = styled.div`
    width:${props => props.width}%;
    padding:20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-right:20px;
    margin-bottom:20px;
`
const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    font-family: 'Poppins', sans-serif;
`

const Description = styled.div`
    font-size:14px;
    font-family: 'Poppins', sans-serif;
    margin-bottom:20px;
`

const Row = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    margin-bottom:10px;
    border-bottom:1px solid #E5E5E5;
`

const T2 = styled.div`
    font-size:14px;
    font-family: 'Poppins', sans-serif;
    font-weight:600;
`
const T3 = styled.div`

`

const FactorContainer = styled.div`
    width:100%;
    border-bottom:1px solid #E5E5E5;
    font-family: 'Poppins', sans-serif;
    padding:5px 10px;
`

const FactorRow = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    margin-bottom:10px;
   
`

const FactorTitle = styled.div`
    width:90%;
    font-size:12px;
`
const FactorPrediction = styled.div`
    width:10%;
`

const More = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    font-size:12px;
    color:#4F4F4F;
    padding-right:20px;
`