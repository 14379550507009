import styled from "styled-components";
import React from "react";
import LegendStyle from "./LegendStyle2";
import { useSelector, shallowEqual } from "react-redux";
import BasicLegend from "./BasicLegend";
import Empty from "./Empty";
import Heatmap from "./Heatmap";
import { Icon, Progress, Popup, Radio } from "semantic-ui-react";
import LinkedRow from "./LinkedRow2D";
import ChartLoading from "./ChartLoading";
import LegendIcon from "./LegendIcon";
import OverviewLegend from "./OverviewLegend";
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';
import PDFBasicLegend from "./PDFBasicLegend";


import {
  calculate_data,
  check_for_subfactors,
  getTitle,
} from "./data_navigation/calculate_data";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const colors2 = ["48, 120, 255", "8, 194, 81", "126, 36, 234", "255, 45, 85"];

const colors = [
  "#0387EF",
  "#FFC600",
  "#02AE5B",
  "#FF0000",
  "#fcba03",
  "#2b704d",
  "#472aa3",
  "#a3852a",
  "#a32a89",
  "#2a8ba3",
  "#a1a32a",
  "#2aa330",
];

const options = [
  { key: 1, text: "Winter Sports", value: 1 },
  { key: 2, text: "Summer Sports", value: 2 },
  { key: 3, text: "Para Sports", value: 3 },
];

const options2 = [
  { key: 1, text: "Overview", value: 1 },
  { key: 2, text: "Technical Leaders", value: 2 },
  { key: 3, text: "Sport Scientists", value: 3 },
  { key: 3, text: "Athletes", value: 3 },
  { key: 5, text: "Coaches", value: 5 },
];



const Grid = ({
  data,
  nav,
  refresh,
  structure,
  WhiteLabel,
  setSub,
  rule,
  questions,
  pdf,
  overviewReport = false,
  selected,
  standards,
  pdfAnchor,
}) => {
  const [highlight, setHighlight] = React.useState(null);
  const [select, setSelected] = React.useState(null);
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const [calculated, setCalculated] = React.useState([]);
  const [render, setRender] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [average, setAverage] = React.useState(null);
  const [checked, setChecked] = React.useState(false);
  const getAverage = () => {
    let ranking = [];
    let total = [0, 0];
    let N = 0;

    let last_total = [0, 0];
    let N_1 = 0;

    calculated
      .filter((f) => f.summary[0])
      .map((item) => {
        item.ranking.map((r) => {
          let resp = r.response;
          let n = item.n;
          ranking.push({ ...r, n });
        });

        N += item.n;
        total[0] += item.summary[0] * item.n;
        total[1] += item.summary[1] * item.n;

        N_1 += item.last_n;
        last_total[0] += item.summary_last[0] * item.last_n;
        last_total[1] += item.summary_last[1] * item.last_n;
      });

    let ranked = calculated
      .filter((f) => f.summary[0])[0]
      ?.ranking.map((rank, i) => {
        let _total = 0;
        let n = 0;
        let id = rank.id;
        let factor = rank.factor;
        let n_last = 0
        let _last_total = 0


        calculated.map((item) => {
          const last_resp = item?.last_ranking?.filter(
            (f) => f.id == id && f.factor == factor
          )[0];
          _last_total += last_resp?.response * item?.last_n;
          n_last += item?.last_n;
        });

        let last = calculated
          .filter((f) => f.summary[0])[0]
          .last_ranking.filter(
            (f) => f.id == id && f.factor == factor
          )[0]?.response;

        ranking
          .filter((f) => f.id == id && f.factor == factor)
          .map((item) => {
            _total += item?.response * item?.n;
            n += item?.n;
          });

        return { id, factor, response: _total / n, last:_last_total/n_last };
      });

    return {
      summary: [total[0] / N, total[1] / N],
      ranking: ranked,
      last: [last_total[0] / N_1, last_total[1] / N_1],
    };
  };

  const get_survey_structure = useSelector(
    (state) => state.surveystructure.survey_structure,
    shallowEqual
  );
  // const pdfAnchor = get_survey_structure[0].categories.find(
  //   (f) => f.priority.toLowerCase() === "secondary"
  // ).id;

  const checkSubFactors = (id, option) => {
    if (structure.categories[id].subfactors.length > 0) {
      let factor = structure.categories[id].subfactors.filter(
        (f) => f.parent == option
      );
      if (factor.length > 0) {
        return true;
      }
    }

    return false;
  };

  React.useEffect(() => {
    const useAnchor = pdf && pdfAnchor ? pdfAnchor : anchor;
    if (useAnchor) {
      setRender(!render);
      setCalculated(
        calculate_data(data, nav, structure, useAnchor, rule, questions).sort(
          (a, b) => a.summary[0] + a.summary[1] - b.summary[0] - b.summary[1]
        )
      );
      setSelected(null);
    }
  }, [nav, refresh, anchor, data, structure, pdfAnchor]);

  React.useEffect(() => {
    setLoading(true);
    if (calculated.length > 0) {
      setAverage(getAverage());
    }
    setTimeout(() => setLoading(false), 300);
  }, [calculated, select]);

  if (calculated && calculated.filter((f) => f.summary[0]).length == 0) {
    return <Empty />;
  }

  const get_total = () => {
    console.log(average)
    let current = (average.summary[0] + average.summary[1]) / 2;
    let last = (average.last[0] + average.last[1]) / 2;
    let diff = ((current - last) * 10).toFixed(0);


    if (last) {
      return current.toFixed(1) + " (" + (diff > 0 ? "+" : "") + diff + "%)";
    }
    return current.toFixed(1);
  };

  const get_select_total = () => {
    let selected = calculated.filter((f) => f.summary[0])[select];

    let current = (selected.summary[0] + selected.summary[1]) / 2;

    let last = (selected.summary_last[0] + selected.summary_last[1]) / 2;

    let diff = ((current - last) * 10).toFixed(1);

    if (last) {
      return current.toFixed(1) + " (" + (diff > 0 ? "+" : "") + diff + "%)";
    }

    return current.toFixed(1);
  };

  return (
    <BigC>
      <First className="title-bar">
        <Title>Culture Index Map</Title>
        {standards?.response?.pillars?.length > 0 ? (
          <Radio
            toggle
            label="Benchmark"
            checked={checked}
            onChange={(e, d) => setChecked(d.checked)}
            style={{ fontSize: 10, marginRight: 40 }}
          />
        ) : (
          ""
        )}
      </First>
      <Main>
        {selected == 1 ? (
          <ChartContainer pdf={pdf} id="culture_chart">
            <Container pdf={pdf}>
              {calculated
                .filter((f) => f.summary[0])
                .map((item, i) => (
                  <MasterDot
                    data={item}
                    i={i}
                    key={i}
                    highlight={highlight}
                    selected={select === i}
                    hide={select != null && select !== i}
                    rule={rule}
                    pdf={pdf}
                    onClick={(d) => setSelected(d)}
                  />
                ))}
              <Axis>
                <AxisText overviewReport={overviewReport}>
                  {questions.dimensions[1].title}
                </AxisText>
                <Values overviewReport={overviewReport}>
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
                    return <Num key={item}>{item}</Num>;
                  })}
                </Values>
              </Axis>
              <Axis2>
                <Values2>
                  {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((item) => {
                    return <Num key={item}>{item}</Num>;
                  })}
                </Values2>
                <AxisTextTop>{questions.dimensions[0].title}</AxisTextTop>
              </Axis2>

              <Gride>
                {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((item) => {
                  return <VLine key={item} />;
                })}
              </Gride>

              <Gride2>
                {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((item) => {
                  return <HLine key={item} />;
                })}
              </Gride2>
            </Container>
            {pdf ? (
              <PDFBasicLegend
                overviewReport={overviewReport}
                show={select != null || pdf}
                structure={structure}
                data={calculated}
                anchor={anchor}
                pdf={pdf}
                nonOTP={true}
              />
            ) : (
              <BasicLegend
                overviewReport={overviewReport}
                show={select != null || pdf}
                structure={structure}
                data={calculated}
                anchor={anchor}
                pdf={pdf}
                nonOTP={true}
              />
            )}
          </ChartContainer>
        ) : (
          <Heatmap
            questions={questions}
            categories={structure}
            nav={nav}
            rule={rule}
            data2={data}
            anchor={anchor}
            color={WhiteLabel.highlight}
          />
        )}

        {selected == 1 && !pdf ? (
          <Legend className="legend-container">
            <Card>
              <CardTop>
                <LTitle>
                  <LegendIcon />
                  Legend
                </LTitle>
                <TeamTitle>
                  <Circle
                    color={
                      select
                        ? calculated.filter((f) => f.summary[0])[select].color
                        : "#09E1C0"
                    }
                    style={{ marginRight: 5 }}
                  />
                  {select != null
                    ? calculated.filter((f) => f.summary[0])[select].title.name
                    : "Overview"}
                  {select != null ? (
                    <Icon
                      name="close"
                      onClick={() => setSelected(null)}
                      style={{
                        marginTop: -4,
                        cursor: "pointer",
                        marginLeft: 5,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </TeamTitle>
                <Score>
                  {select != null
                    ? get_select_total()
                    : average
                    ? get_total()
                    : ""}
                  <Sub>Overall Score</Sub>
                </Score>
              </CardTop>
              {/*<Title2>Culture Factor Ranking</Title2>*/}
              {loading && select ? (
                <div style={{ height: 250 }}>
                  <ChartLoading />
                </div>
              ) : (
                <LinkedRow
                  data={
                    select != null
                      ? calculated.filter((f) => f.summary[0])[select]
                      : average
                  }
                  nav={select}
                  color={WhiteLabel.highlight}
                  factors={2}
                  checked={checked}
                  standards={standards}
                />
              )}
            </Card>
          </Legend>
        ) : (
          ""
        )}
      </Main>

      {selected == 1 && !pdf ? (
        <OverviewLegend
          data={calculated}
          onClick={() => setSelected(null)}
          questions={questions}
        />
      ) : (
        ""
      )}

      {selected == 1 && !pdf
        ? calculated
            .filter((f) => f.summary[0])
            .map((item, i) => {
              return (
                <LegendStyle
                  title={item.title}
                  data={item}
                  i={i}
                  key={i}
                  hover={(d) => setHighlight(d)}
                  highlight={highlight}
                  selected={select}
                  pdf={pdf}
                  onClick={(d) => setSelected(d)}
                  color={WhiteLabel.highlight}
                  questions={questions}
                />
              );
            })
        : ""}
    </BigC>
  );
};

// <Legend className="legend-container">
//            <LTitle>Legend</LTitle>

//            {calculated
//              .filter((f) => f.raw.length > 0)
//              .map((item, i) => {
//                return (
//                  <LegendStyle
//                    title={item.title}
//                    data={item}
//                    i={i}
//                    key={i}
//                    hover={(d) => setHighlight(d)}
//                    highlight={highlight}
//                    selected={select}
//                    pdf={pdf}
//                    onClick={(d) => setSelected(d)}
//                    color={WhiteLabel.highlight}
//                    questions={questions}
//                  />
//                );
//              })}
//          </Legend>

export default Grid;

const Dot = (props) => {
  return (
    <DotContainer
      hover={
        props.highlight === null
          ? true
          : props.highlight === props.i
          ? true
          : false
      }
      onMouseEnter={() => props.hover(props.i)}
      onMouseLeave={() => props.hover(null)}
    >
      <Circle color={props.color} />
      <Text>{props.title}</Text>
    </DotContainer>
  );
};

const DotContainer = styled.div`
  display: flex;
  cursor: pointer;
  opacity: ${(props) => (props.hover || props.pdf ? 1 : 0.3)};
`;

const MasterDot = ({
  data,
  i,
  highlight,
  selected,
  hide,
  rule,
  pdf,
  onClick,
}) => {
  const [hover, setHover] = React.useState(false);
  return (
    <>
      <Popup
        position="top center"
        content={data.title.name}
        trigger={
          <Position
            x={data.summary[1]}
            y={data.summary[0]}
            hover={highlight === null ? true : highlight === i ? true : false}
            hide={hide}
            pdf={pdf}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => onClick(i)}
          >
            <Circle2
              color={data.color}
              hover={highlight === i || hover ? true : false}
            />
          </Position>
        }
      />
      <Deviation
        color={data.color}
        top={data.summary[0]}
        left={data.summary[1]}
        hover={highlight == i || hover ? true : false}
        hide={hide}
        x={data.stdev[1] * 1.96}
        y={data.stdev[0] * 1.96}
      />

      {data.raw.map((item, i) => {
        let count = data.raw.filter((f) => f.color === item.color).length;
        if (count > rule) {
          let dotColor = pdf ? item.color.replace("0.3", "1") : item.color;
          return (
            <SmallCircle
              key={i}
              color={dotColor}
              x={selected ? item.y : data.summary[1]}
              y={selected ? item.x : data.summary[0]}
              selected={selected}
            />
          );
        }
      })}
    </>
  );
};

const LTitle = styled.div`
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* identical to box height */

  color: #414141;
`;

const Positioned = styled.div`
  position: absolute;
`;

const AxisText = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #727272;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  position: ${(props) => (props.overviewReport ? "absolute" : "relative")};
  margin-bottom: ${(props) => (props.overviewReport ? "-65px" : "-65px")};
`;

const AxisTextTop = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #727272;
  display: flex;
  height: 50px;
  width: 200px;
  align-items: center;
  justify-content: center;
  transform-origin: 50% 50%;
  transform: rotate(270deg) translate(-200px, -135px);
`;

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 10px 5px;
  border-color: transparent transparent #000000 transparent;
  transform: translate(-90px, -10px);
`;

const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent #000000;
  margin-bottom: -5.5px;
  margin-right: -10px;
`;

const BigC = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding-left: 59px;
  font-family: "Inter", sans-serif;
`;

const Main = styled.div`
  display: flex;
  width: 100%;
`;

const Container = styled.div`
  min-height: ${(props) => (props.pdf ? "610px" : "0")};
  min-width: ${(props) => (props.pdf ? "610px" : "0%")};
  height: ${(props) => (props.pdf ? "500px" : "500px")};
  width: ${(props) => (props.pdf ? "500px" : "500px")};
  display: flex;
  flex-wrap: wrap;
  position: ${(props) => (props.pdf ? "absolute" : "relative")};
  left: ${(props) => (props.pdf ? "30%" : "0")};
  margin-top: ${(props) => (props.pdf ? "-30px" : "50px")};
  margin-left: 40px;
  left: ${(props) => (props.pdf ? "100px" : "0px")};
`;

const ChartContainer = styled.div`
  padding: 0px 30px 60px 30px;
  box-shadow: ${(props) =>
    props.pdf ? "none" : "0px 4px 15px rgba(0, 0, 0, 0.05);"};
  transition: all 0.5s;
`;

const Quad = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 50%;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;

  color: #414141;
`;

const H1 = styled.div`
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const H2 = styled.div`
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  color: grey;
  position: absolute;
  top: ${(props) => props.top + "%"};
  right: ${(props) => props.right + "%"};
  left: ${(props) => props.left + "%"};
  bottom: ${(props) => props.bottom + "%"};
`;

const H22 = styled.div`
  font-weight: 300;
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  color: grey;
`;

const Deviation = styled.div`
  opacity: ${(props) => (props.hide || !props.hover ? 0 : 0.1)};
  background-color: ${(props) => props.color};
  height: ${(props) => props.y * 10 + "%"};
  width: ${(props) => props.x * 10 + "%"};
  border-radius: 50%;
  left: ${(props) => "calc(" + (props.left - props.x / 2) * 10 + "% + 7.5px)"};
  bottom: ${(props) => "calc(" + (props.top - props.y / 2) * 10 + "% + 7.5px)"};
  position: absolute;
`;

const Excellence = styled.div`
  width: 40%;
  height: 40%;
  border-bottom: 1px dashed black;
  border-left: 1px dashed black;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Tick = styled.div`
  padding-left: 5px;
`;

const Axis = styled.div`
  width: calc(100% + 5px);
  height: 25px;
  border-bottom: 2px solid #c0c0c0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  top: calc(100% - 25px);
`;
const Axis2 = styled.div`
  height: calc(100% + 5px);
  width: 200px;
  border-left: 2px solid #c0c0c0;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0px;
  margin-top: -5px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 17px;
  width: calc(100% - 600px);
  transition: all 500ms;
  height: 616px;
`;

const Circle = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${(props) => props.color};
  border: ${(props) => "1px solid" + props.color};
`;
const Circle2 = styled.div`
  height: ${(props) => (props.hover ? "30px" : "15px")};
  width: ${(props) => (props.hover ? "30px" : "15px")};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  transition: all 0.5s;
  transform: ${(props) => (props.hover ? "translate(-7.5px,7.5px)" : "none")};
`;

const Position = styled.div`
  position: absolute;
  left: calc(${(props) => props.x * 10 + "%"} - 7.5px);
  bottom: calc(${(props) => props.y * 10 + "%"} - 8.5px);
  z-index: 10;
  opacity: ${(props) =>
    props.hide ? 0 : props.hover || props.pdf === true ? 1 : 0.3};
  cursor: pointer;
`;
const First = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 20px;
  color: #414141;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 30px;
  margin-top: 19px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  justify-content: space-between;
`;

const Position2 = styled.div`
  position: absolute;
  left: ${(props) => props.x * 10 + "%"};
  bottom: ${(props) => props.y * 10 + "%"};
  z-index: -1;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${(props) => (props.selected ? 1 : 0)};
`;

const SmallCircle = styled.svg`
  position: absolute;
  left: calc(${(props) => props.x * 10 + "%"} - 7.5px);
  bottom: calc(${(props) => props.y * 10 + "%"} - 8.5px);
  z-index: -1;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${(props) => (props.selected ? 1 : 0)};
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: ${(props) => "1px solid" + props.color};
`;

const Values = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${(props) => (props.overviewReport ? "-35px" : "-25px")};
`;
const Values2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: -20px;
`;

const Num = styled.div`
  font-size: 10px;
  color: #c0c0c0;
`;

const Gride = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
`;

const VLine = styled.div`
  height: 100%;
  width: ${(props) => (props.i == 0 ? "2px" : "1px")};
  background-color: ${(props) => (props.i == 0 ? "black" : "rgba(0,0,0,0.1)")};
`;

const Gride2 = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HLine = styled.div`
  width: 100%;
  height: ${(props) => (props.i == 10 ? "2px" : "1px")};
  background-color: ${(props) => (props.i == 10 ? "black" : "rgba(0,0,0,0.1)")};
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  padding: 5px;
  border-radius: 2px;
  margin-right: 10px;
  background-color: ${(props) => (props.selected ? "#2d50e2" : "white")};
  color: ${(props) => (props.selected ? "white" : "#2d50e2")};
  font-weight: ${(props) => (props.selected ? "bold" : 400)};
  cursor: pointer;
`;

const Card = styled.div`
  padding: 0px 30px;
  border-radius: 10px;
  height: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
`;

const CardTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-top: 40px;
`;

const Score = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #6d6d6d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;

  color: #7e7e7e;
`;

const Text = styled.div`
  font-size: 14px;
  margin-top: 5px;
  margin-left: 32px;
`;

const TeamTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  color: #7e7e7e;
  display: flex;
  align-items: center;
`;

const TeamTitleTop = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  color: #7e7e7e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: -30px;
  cursor: pointer;
`;

const ButtonComponent = styled.div`
  background-color: ${(props) => (props.selected ? "#476DFA" : "#515151")};
  border-radius: 20px;
  transition: background 0.1s ease;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 22px;
  font-size: 10px;
  color: white;
  width: 76px;
  font-family: "Inter", sans-serif;
  margin-left: 10px;

  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const Title2 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-transform: uppercase;

  color: #7e7e7e;
  margin-bottom: 30px;
`;

