import styled, { keyframes } from "styled-components";
import React from "react";
import Individual from './UserComment'
import { Dropdown,Icon } from 'semantic-ui-react'


const options =[
{
	text:'Clarity',
	'value':'Clarity'
},
{
	text:'Alignment',
	'value':'Alignment'
},
{
	text:'Resilience',
	'value':'Resilience'
},
{
	text:'Impact',
	'value':'Impact'
},
{
	text:'Trust',
	'value':'Trust'
},
{
	text:'Communication',
	'value':'Communication'
}
]

const sortOptions = [{text:'Date',value:'Date'},{text:'Change in score',value:'change'}]

const Comments = () =>{

	const [selected,setSelected] = React.useState(1)

	return(
		<Container>
			<Controller>
				<Dates>
						<Time first selected={selected==1} onClick={()=>setSelected(1)}>Team Health</Time>
						<Time selected={selected==2} onClick={()=>setSelected(2)}>Emotional Health</Time>
					</Dates>
					<div>
					<div style={{display:'flex',alignItems:'center'}}>
						 <Dropdown
						    placeholder='Select metric'
						    selection
						    options={options}
						  />
						  <SortContainer>
						  <div>
						  <Icon name="sort amount down" />
							  Sort by 
							</div>
							  <Dropdown
						        inline
						        header='Sort options'
						        options={sortOptions}
						        defaultValue={sortOptions[0].value}
						      />
							  
							</SortContainer>
						</div>
					 </div>
			</Controller>
			<Individual/>
			<Individual/>
			<Individual/>
			<Individual/>
			<Individual/>
			<Individual/>
			<Individual/>
			<Individual/>
			<Individual/>
		</Container>
		)
}

export default Comments


const Container = styled.div`
	width:100%;
 	padding:30px 40px;
 	margin-top:20px;
`

const Dates = styled.div`
	display:flex;


`

const Time = styled.div`
	color:${props=>props.selected?'white':'#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props=>props.first?'1px solid #6a00ff':'none'};
	background-color:${props=>props.selected?'#6a00ff':'none'};
	cursor:pointer;
`

const Controller = styled.div`
	display:flex;
		justify-content:space-between;
	width:100%;

`

const SortContainer = styled.div`
	display:flex;
	margin-left:10px;
	flex-direction:column;
	align-items:center;
	font-size:12px;
	font-family: 'Red Hat Display', sans-serif;
`