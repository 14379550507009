import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";

// Create styles
const styles = StyleSheet.create({
	page:{
		width:'100%',
		border:'0.5px solid grey',
		marginBottom:30,
		marginTop:20
	},
	TH:{
		fontSize:10,
		textTransform:'uppercase',
		color:'white',
		backgroundColor:'#000921',
		fontWeight:500,
		padding:3
	},
	H:{
		display:'flex',
		width:'100%',
		flexDirection:'row',
		padding:3
	},
	H1:{
		display:'flex',
		width:'100%',
		flexDirection:'row',
	},
	TD:{
		fontSize:10,
		padding:4
	}
})



const Table = ({data}) =>{



	return (
		<View style={styles.page} wrap={false}>
			<View style={styles.H1}>
				{data.rows.map((item)=><View style={[{width:item.width},styles.TH]}><Text>{item.title}</Text></View>)}
			</View>
			<View style={styles.body}>
				{data.data.map((item,x)=>{
					return (
						<View style={[{backgroundColor:x%2==0?'#F1F1F7':'white'},styles.H]}>
							{item.map((row,i)=><View style={[{width:data.rows[i].width},styles.TD]}><Text>{row}</Text></View>)}
						</View>
						)
				})}
			</View>
		</View>
		)
}

export default Table