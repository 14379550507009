import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";

import { Progress } from 'semantic-ui-react'

import VisProvider, { VisGraph } from "vis-network-hook";
const DefaultPic = 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7'

const SampleData = {
	nodes: [
		{ id: 1, value: 1, label: "Deepesh Krishnan", shape: "circularImage", color: '#6a00ff', image: "https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg" },
		{ id: 2, value: 1, label: "Michael Bawol", shape: "circularImage", color: '#6a00ff', image: "https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg" },
		{ id: 3, value: 1, label: "Camden Springer", shape: "circularImage", color: '#6a00ff', image: "https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg" },
		{ id: 4, value: 1, label: "Bryce Tully", shape: "circularImage", color: '#6a00ff', image: "https://images.squarespace-cdn.com/content/v1/53af1c83e4b0b3e1fc2000bd/1446397787787-SI1H06DNOL2QJSX13DCD/ke17ZwdGBToddI8pDm48kOyctPanBqSdf7WQMpY1FsRZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpwwQIrqN0bcqL_6-iJCOAA0qwytzcs0JTq1XS2aqVbyK6GtMIM7F0DGeOwCXa63_4k/Randy+Krum+Profile+Photo+square.jpg" },
		{ id: 5, value: 1, label: "Bryce Crosby", shape: "circularImage", color: '#6a00ff', image: "https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg" },
		{ id: 6, value: 1, label: "Bryce Crosby", shape: "circularImage", color: 'blue', image: "https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg" }
	],
	edges: [
		{ from: 2, to: 3, color: '#6a00ff', },
		{ from: 1, to: 2, color: '#6a00ff', },
		{ from: 2, to: 4, color: '#6a00ff', },
		{ from: 2, to: 5, color: '#6a00ff', },
		{ from: 2, to: 6, color: 'blue', },
	]
};



const options = {
	height: "400px",
	width: "600px",
	nodes: {
		borderWidth: 4
	}
}


const Teams = () => {

	return (
		<>
			<User>
				<TeamLogo>L</TeamLogo>
				<div style={{ width: '100%' }}>
					<Name>Team A</Name>
					<Progress percent={76} progress size='small' style={{ marginBottom: 5, marginTop: 0 }} color='blue' inverted />
				</div>
			</User>
		</>
	)
}


const Connection = ({ pastDates, culConnections, graphNetwork, selEmp, empList }) => {
	const [noGraph, setNoGraph] = React.useState(false)
	const [selectedDate, setSelectedDate] = React.useState(pastDates?.[pastDates?.length - 1])

	// console.log(graphNetwork)
	// console.log(culConnections.filter(cl => cl.date === selectedDate)[0]?.connections)

	const [graphData, setGraphData] = useState({ nodes: [], edges: [] })

	useEffect(() => {
		if (culConnections) {
			const _edges1 = []
			const _rawData = culConnections.filter(
				cl => cl.date === selectedDate)[0]?.connections?.edges?.filter(
					ed => ed.from === selEmp.id)

			if (!_rawData) {
				setGraphData({ nodes: [], edges: [] })
				setNoGraph(true)
				return
			}

			_rawData.map(mp => {
				_edges1.push({
					from: mp.from,
					to: mp.to,
					color: "#6a00ff",
					// id: `${mp.from}-${mp.to}`
				})

				return null
			})
			const _nodes1 = []

			// console.log(_rawData)

			_rawData.map(cn => {
				// console.log(empList)
				const EmpSet = empList.filter(em => em.employee_id === cn.to)[0]
				// console.log(empList)
				_nodes1.push({
					id: cn.to,
					value: 1,
					label: EmpSet?.employee?.first_name ? `${EmpSet?.employee?.first_name} ${EmpSet?.employee?.last_name}` : EmpSet?.employee?.email.split("@")[0],
					shape: "circularImage",
					color: '#6a00ff',
					image: EmpSet?.employee?.picture ? EmpSet?.employee?.picture : DefaultPic
				})

			})

			const EmpFromSet = empList.filter(em => em.employee_id === selEmp.id)[0]
			_nodes1.push({
				id: selEmp.id,
				value: 1,
				label: EmpFromSet?.employee?.first_name ? `${EmpFromSet?.employee?.first_name} ${EmpFromSet?.employee?.last_name}` : EmpFromSet?.employee?.email.split("@")[0],
				shape: "circularImage",
				color: '#6a00ff',
				image: EmpFromSet?.employee?.picture ? EmpFromSet?.employee?.picture : DefaultPic
			})
			// _nodes1.push({
			// 	id: selEmp.id,
			// 	value: 1,
			// 	label: selEmp?.first_name? `${selEmp?.first_name} ${selEmp?.last_name}` : selEmp.email.split("@")[0],
			// 	shape: "circularImage",
			// 	color: '#6a00ff',
			// 	image: selEmp.picture? selEmp.picture : DefaultPic
			// })

			// console.log(_nodes1)

			setNoGraph(false)
			setGraphData({
				nodes: _nodes1, edges: _edges1
			})
		}
		// console.log(_edges1)
		// console.log(_nodes1)

	}, [culConnections, selectedDate, selEmp, empList]);

	// console.log(graphNetwork[1])
	// console.log(selEmp)

	function ChangeFromEarlier() {
		if (Object.keys(graphNetwork?.[0])?.length === 0) {
			return null
		}


		const LastCon = (graphNetwork?.[0]?.connectivity[selEmp?.id])
		let PrevCon = 0

		if (graphNetwork?.[1]) {
			if (Object.keys(graphNetwork)) {
				PrevCon = (graphNetwork[1]?.connectivity[selEmp?.id])
			}

		}

		// console.log(SampleData)
		// console.log(graphData)

		if (LastCon === PrevCon) {

			return <strong>remained same as last week</strong>
		}
		if (LastCon > PrevCon) {

			return (
				<strong>increased {Math.round((LastCon - PrevCon) * 100)}% this week </strong>)
		}
		if (LastCon < PrevCon) {

			return <span>
				<strong>decreased {Math.round((LastCon - PrevCon) * 100)}% this week</strong>
			</span>
		}

		return null
	}




	return (
		<Container>
			<Title>Connection Metrics</Title>
			{/* <Dates>
				{pastDates.map((dt, k) => {
					return <Time
						first={k === 0}
						selected={dt === selectedDate}
						key={dt}
						onClick={() => setSelectedDate(dt)}>{dt}</Time>
				})}

			</Dates> */}
			<DataContainer>
				{noGraph && graphData && <h3>No Network Graph</h3>}
				<VisProvider graph={graphData} options={options} >
					<VisGraph />
				</VisProvider>

				{/* <Metrics>
					<Title>Top Connections</Title>
					<Options>
						<Option selected={option == 1} onClick={() => setOption(1)}>People</Option>
						<Option selected={option == 2} onClick={() => setOption(2)}>Teams</Option>
					</Options>
					{option == 1 ? <Users /> : <Teams />}
				</Metrics> */}
			</DataContainer>



			<CardContainer>
				{graphNetwork[0]?.connectivity?.[selEmp?.id] ?

					<Card>
						<AnalysisTitle>Connectivity</AnalysisTitle>
						<AnalysisScore>{Math.round(graphNetwork[0]?.connectivity?.[selEmp.id] * 100)}%</AnalysisScore>
						<Text>{selEmp?.first_name} {selEmp?.last_name} is directly
							connected to {Math.round(graphNetwork[0]?.connectivity?.[selEmp.id] * 100)}% of
							the team. {selEmp?.first_name}'s connectivity has
							{" "}{ChangeFromEarlier()}. Connectivity measures the degree to which a team member is connected to others in that team.</Text>
					</Card> : ""}
				{graphNetwork[0]?.average_maximum_distance ?
					<Card>
						<AnalysisTitle>Team Member Distance</AnalysisTitle>
						<AnalysisScore>{graphNetwork[0]?.average_maximum_distance ? Number(graphNetwork[0].average_maximum_distance).toFixed(2) : '--'}</AnalysisScore>
						<Text>{selEmp.first_name} {selEmp.last_name}'s
							furthest team member connection is {Number(graphNetwork[0].average_maximum_distance).toFixed(2)}{" "}
							team members away. The further team members are from each other the less likely it is that information flows as effectively from that team memebr to others.</Text>
					</Card>
					: ""}

			</CardContainer>
		</Container>
	)
}


export default Connection

const VisContainer = styled.div`
	width:800px;
	height:500px;
`

const Container = styled.div`
	width:100%;
 	padding:10px;
 	margin-top:20px;
 	position:relative;
`

const IMG = styled.img`
	width:300px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:500;
	margin-bottom:30px;
	color:#4a4a4a;
`

const Metrics = styled.div`
	width:calc(100% - 620px);
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background-color:white;
	padding:20px;
	margin-left:20px;

	@media(max-width:1050px){
		width:100%;
	}
`

const Avatar = styled.img`
	width:40px;
	height:40px;
	border-radius:20px;
	margin-right:10px;
`


const User = styled.div`
	display:flex;
	width:100%;
	margin-top:10px;

`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`

const DataContainer = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;

	@media (max-width:1050px){
		justify-content:center;
	}
`

const Dates = styled.div`
	display:flex;
	position:absolute;
	top:0px;
	right:40px;

`

const Time = styled.div`
	color:${props => props.selected ? 'white' : '#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props => props.first ? '1px solid #6a00ff' : 'none'};
	background-color:${props => props.selected ? '#6a00ff' : 'none'};
	cursor:pointer;
`

const Options = styled.div`
	display:flex;
	margin-bottom:30px;
	width:100%;
	justify-content:flex-end;
`

const Option = styled.div`
	font-size:12px;
	font-family: 'Red Hat Display', sans-serif;
	font-weight:${props => props.selected ? 'bold' : 400};
	padding:5px 10px;
	border-bottom:${props => props.selected ? '1px solid black' : 'none'};
	cursor:pointer;
`

const TeamLogo = styled.div`
	height:40px;
	width:40px;
	border-radius:20px;
	min-width:40px;
	background-color:pink;
	color:white;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:10px;
	font-size:20px;
	font-weight:bold;
`

const Card = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding:20px;
  display:flex;
  flex-direction:column;
  width:calc(50% - 10px);

  margin-bottom:20px;

  @media(max-width:900px){
    width:350px;
  }


   @media(max-width:768px){
    width:100%;
  }
`

const AnalysisTitle = styled.div`
  font-size:18px;
  font-weight:bold;
  margin-bottom:10px;
`

const Text = styled.div`


@media(max-width:768px){
    line-height:1.3;
    font-size:18px;
  }
`

const AnalysisScore = styled.div`
  font-size:22px;
  font-weight:bold;
  margin-bottom:10px;
`

const CardContainer = styled.div`
	display:flex;
	margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
	justify-content:space-between;
	flex-wrap:wrap;
`