import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "./Icons";
import { SET_DATA_ACNHOR } from "constants/actions";
import Anchor from "reports/Audit/Anchor";
import ClickAwayListener from "react-click-away-listener";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const build_state = (is_french, structure) => {
  let _state = structure?.[0]?.categories
    .filter((f) => f.priority.toLowerCase() !== "primary")
    .map((i) => {
      return {
        text: is_french ? (i.fr_name ? i.fr_name : i.name) : i.name,
        value: i.id,
      };
    });
  if (_state) {
    _state.unshift({
      value: structure[0].categories.find(
        (f) => f.priority.toLowerCase() === "primary"
      ).id,
      text: is_french ? "Aperçu" : "Overview",
    });
  }

  return _state;
};

const BasicAnchor = ({ customLabel }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();
  const [anchorOptions, setAnchorOptions] = useState();
  const [anchorList, setAnchorList] = useState();
  const [selectedOption, setSelectedOption] = useState("Overview");

  const { get_survey_structure } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_survey_structure && get_survey_structure.length > 0) {
      setAnchorList(build_state(false, get_survey_structure));
      setAnchorOptions(
        build_state(false, get_survey_structure).map((option) => {
          return option.text;
        })
      );
    }
  }, [get_survey_structure]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (anchorList && selectedOption) {
      const anchorValue = anchorList.find(
        (f) => f.text === selectedOption
      ).value;
      if (anchorValue !== -1) {
        dispatch({
          type: SET_DATA_ACNHOR,
          payload: anchorValue,
        });
      }
    }
  }, [selectedOption, anchorList]);

  return (
    <DropdownContainer>
      <DropdownLabel>{customLabel || "Anchor"}:</DropdownLabel>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <DropdownSelect
          value={selectedOption}
          onClick={() => setIsOpen(!isOpen)}
        >
          <SelectedOptionContainer>
            <SelectedOption>{selectedOption}</SelectedOption>
            {!isOpen ? ChevronDownIcon() : ChevronUpIcon()}
          </SelectedOptionContainer>
          {isOpen && (
            <DropdownSelectionContainer>
              {anchorOptions.map((option, index) => (
                <DropdownItem
                  isHovered={hoveredIndex === index}
                  onMouseOver={() => setHoveredIndex(index)}
                  key={index}
                  selected={selectedOption === option}
                  onClick={() => {
                    handleOptionChange(option);
                    setIsOpen(false);
                  }}
                >
                  {option}
                </DropdownItem>
              ))}
            </DropdownSelectionContainer>
          )}
        </DropdownSelect>
      </ClickAwayListener>
      <HiddenAnchor>
        <Anchor />
      </HiddenAnchor>
    </DropdownContainer>
  );
};

export default BasicAnchor;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DropdownSelectionContainer = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  border-radius: 8px;
  background: #fff;
  /* Shadow */
  box-shadow: 0px 8px 20px 0px rgba(63, 134, 254, 0.08);
`;

const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const DropdownItem = styled.div`
  padding: 10px;
  background: ${(props) => (props.isHovered ? "#F8FAFF" : "#fff")};
  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
`;

const DropdownLabel = styled.div`
  color: #666d79;
  min-width: fit-content;
  text-align: right;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-right: 8px;
  align-self: center;
`;

const SelectedOption = styled.div`
  cursor: pointer;
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const DropdownSelect = styled.div`
  width: 100%;
  border: 0px solid #ccc;
  border-radius: 4px;

  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const HiddenAnchor = styled.div`
  display: none;
`;
