import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import { injectDownloadOptions } from "constants/chartOptions";


const Predictions = ({data,options,series,loading,type})=>{


    const [outcomeName,setOutcomeName] = useState('')
    const [prediction,setPrediction] = useState(0)

    useEffect(()=>{
        if(data){
            setOutcomeName(data.outcome.name.toLowerCase())
            setPrediction((data.score.correlation*data.score.correlation*100).toFixed(0))
        }
    }
    ,[data,type])



    return (
      <Container>
        <Title>
          {type} predicts <strong>{prediction}%</strong> of {outcomeName}
        </Title>

        {/* <Title>
                        Understanding your culture's impact on {outcomeName}
                    </Title>

                    <Description>
                        Your culture predicts <strong>{prediction}%</strong> of {outcomeName}
                    </Description>

                    <Question>How each culture factor contributes</Question>
                    <Answer>Out of all the culture factors measured, here is a breakdown of the relative impact each one has on {outcomeName}.</Answer> */}
        {loading ? (
          ""
        ) : (
          <Chart
            options={injectDownloadOptions(options)}
            series={series}
            type="pie"
            height={400}
          />
        )}

        {/* 
                    <Question>What does it mean that culture predicts {prediction}% of {outcomeName}?</Question>
        
                    <Answer>
                    <p>
                    When we say that culture predicts a certain percentage of an outcome, such as {outcomeName}, we're referring to the extent to which the various aspects of your organization's culture are responsible for driving that outcome.
                    </p>
                    
                    <p>
                    In this context, the predictive model has analyzed your culture data and determined that {prediction}% of the variations in {outcomeName} can be attributed to the specific culture factors measured. This means that the way your organization’s culture is structured—encompassing elements like psychological safety, communication, and accountability—has a direct and measurable impact on {outcomeName}.

                    </p>

                    <p>
                    For example, if the model shows that culture predicts {prediction}% of {outcomeName}, it means that this percentage of your {outcomeName} can be explained by the strengths and weaknesses of your organizational culture. The remaining percentage might be influenced by other factors, such as external market conditions, technology, or individual employee skills, which are not captured in this specific model.
                    </p>

                    <p>
                    Understanding this relationship helps you identify which aspects of your culture are most influential on key outcomes, enabling you to focus on improving those areas to achieve better results.
                    </p>         
                    
                    </Answer> */}
      </Container>
    );

}


export default Predictions;

const Container = styled.div`
    padding:20px;
    font-family:"Raleway";
     width:calc(50% - 20px);
     background-color:#F8FAFF;
        border-radius:10px;
        margin-right:20px;

`


const Title = styled.div`
 font-size:20px;
    line-height: 1.4;
    font-weight:700;
    color:#2A3039;
    margin-bottom:10px;

`

const Description = styled.div`
    font-size:18px;
    line-height:1.6;
`

const T2 = styled.div`
    font-size:14px;
    font-weight:600;
    margin-bottom:5px;
`

const T3 = styled.div`

`


const Question = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`

const Answer = styled.div`
    font-size:14px;
    margin-bottom:40px;
    line-height:1.6;
`







