import styled, { keyframes } from "styled-components";

export const transitionStyles = {
  entering: { opacity: 0 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

export const defaultStyle = {
  opacity: 0,
  transition:'opacity 500ms ease-in-out'
}

export const appear = keyframes`
	0% {opacity:0;z-index:1}
  100% {opacity:1,z-index:2}
`;

export const disappear = keyframes`
	0% {opacity:1;z-index:2}
  100% {opacity:0;z-index:1}
`;

export const Title  = styled.div`
	font-size: 28px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:100px;
  margin-top:170px;
`
export const DevTitle  = styled.div`
	font-size: 28px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:20px;
  margin-top:20px;
`

export const Img = styled.img`
	width:400px;
	margin-right:36px;
	box-shadow: 0 10px 40px 0 rgba(211, 211, 211, 0.5);
	cursor:pointer;

	&:hover{
  	box-shadow: 0 10px 40px 0 rgba(211, 211, 211, 1);
  }
`


export const Container = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	flex-direction:column;
	height:100%;
  position:absolute;
  
`

export const Input = styled.input`
	font-size: 28px;
	box-sizing:border-box;
	display:flex;
  font-weight: bold;
  color: #9b9b9b;
  font-family: 'Red Hat Display', sans-serif;
  border:0;
  border-bottom: solid 2px #9b9b9b;
  

  &:focus{
   outline: none;
  }
`

export const Input2 = styled.input`
	font-size: 28px;
	box-sizing:border-box;
	display:flex;
  font-weight: bold;
  color: #9b9b9b;
  font-family: 'Red Hat Display', sans-serif;
  border:0;
  border-bottom: solid 2px #9b9b9b;
  width:100%;
  

  &:focus{
   outline: none;
  }
`

export const SubText = styled.div`
	font-size: 20px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-top:30px;
`

export const Text = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;

`

export const StyledButton = styled.div`
  padding:10px 30px 10px 30px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;

`

export const Invite = styled.div`
	width: 100%;
  height: 50px;
  color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  justify-content:flex-end;
  align-items:center;
  cursor:pointer;
  margin-top:10px;

`

export const InviteFormButton = styled.button`
width: 100%;
height: 50px;
color: #6a00ff;
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
display:flex;
justify-content:flex-end;
align-items:center;
cursor:pointer;
margin-top:10px;
border: none;
    background: white;
`;

export const StyledInput = styled.input`
  width:100%;
  padding:5px;
  background-color:transparent;
  border:none;
  font-family: 'Red Hat Display', sans-serif;
  &:focus {
        outline: none;
    }
`

export const InviteBox = styled.div`
	width:${props=>props.view? '350px' : '390px'};
	border-radius:20px;
	background-color:#ebebeb;
	color:grey;
	transition:width 1s;
	font-family: 'Red Hat Display', sans-serif;
	padding: 5px 15px 5px 15px;
	z-index:1
`

export const InviteButton = styled.button`
	background-color:rgba(106, 0, 255,0.8);
	color:white;
	padding:${props=>props.view? '2px 10px 2px 10px' : '0'};
	border-radius:10px;
	width:${props=>props.view? 'auto' : '0px'};
	overflow:hidden;
	display:${props=>props.view? 'block' : 'none'};
	opacity:${props=>props.view? 1 : 0};
	height:20px;
	font-size:12px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:5px;
	transition:width 300ms, opacity 0.1s,padding 1s,display 0.5s;
	font-family: 'Red Hat Display', sans-serif;
	cursor:pointer;
	z-index:3;
`


export const DeliveredButton = styled.div`
	background-color:#35c43c;
	color:white;
	padding:${props=>props.sent? '2px 10px 2px 10px' : '0'};
	border-radius:10px;
	width:${props=>props.sent? 'auto' : '0px'};
	opacity:${props=>props.sent? 1 : 0};
	height:20px;
	font-size:12px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-left:5px;
	transition:width 1s, opacity 1s,padding 1s;
	font-family: 'Red Hat Display', sans-serif;
`


export const Oval = styled.div`
	width: 24px;
  height: 24px;
  border-radius:12px;
  border-style: solid;
  border-width: 1px;
  border-color:${props=>props.color};
  background-color: #ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-right:14px;
  min-width:24px;
`

export const Inner = styled.div`
	width: 20px;
  height: 20px;
  border-radius:10px;
  background-image: ${props=>props.color? props.color:'white'};
  background-color: #ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
`

export const AddContainer = styled.div`
	border-radius: 8px;
  border: dashed 2px #979797;
  background-color: #f8f8f8;
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size: 23px;
  font-weight: bold;
  color: #6a00ff;
  font-family: 'Red Hat Display', sans-serif;
  width:calc(33% - 17px);
  flex: 0 0 auto;
  margin-right:25px;
`

export const TeamContainer = styled.div`
	border-radius: 8px;
	flex: 0 0 auto;
  box-shadow: ${props=>props.type? '0 10px 20px 0 rgba(193, 193, 193, 0.5)' : 'none'};
  border: ${props=>props.type? 'solid 1px #e9e9e9' : 'none'};
  background-color: #ffffff;
  height:${props=>props.type? '100%' : '90%'};
  width:${props=>props.type? 'calc(33% - 17px)' : '50%'};
   padding: 60px 25px 60px 24px;
   margin-right:25px;
   box-sizing:border-box;
`

export const Hide = styled.div`
	opacity:${props=>props.visible ? 1 : 0};
	transition:opacity 0.5s;
`

export const Add = styled.div`
	cursor:pointer;
`

export const ScrollContainer = styled.div`
height:100%;
position:absolute;
right:${props=>props.position==0? '0px':'calc('+(props.position*33)+'% + 10px)'};
transition:right 0.5s;
width:100%;
display:flex;
flexDirection:row;
justifyContent:space-between;
`

export const InvitedContainer = styled.div`
	 font-size: 16px;
  font-weight: bold;
  color: #545454;
  padding:20px 10px 20px 10px;
  display:flex;
  flex-direction:column;
  font-family: 'Red Hat Display', sans-serif;
`

export const Sent = styled.div`
	font-size: 12px;
	font-weight: bold;
  	color: #6a00ff;
`

export const SentContainer = styled.div`
margin-bottom:10px;
display:flex;
flex-direction:row;
justify-content:space-between;
position:absolute;
opacity:${props=>props.visible?1:0};
transition:opacity 700ms ease-in;
 font-size: 16px;
  font-weight: bold;
  color: #545454;
  width:100%;
  font-family: 'Red Hat Display', sans-serif;
  adding:20px 10px 20px 10px;

`

export const LeaderContainer = styled.div`
display:flex;
flex-direction:row;
align-items:center;
opacity:${props=>props.visible?1:0};
max-height:${props=>props.visible?'100px':0};
overflow:hidden;
transition:opacity 200ms ease-out;
`

export const Button = styled.div`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:0 10px 0 10px;
`