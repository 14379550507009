import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import SideMenu from "./SideMenu";
import Stories from "./Stories";
import {
  SG_GET_DATA_STORIES,
  SG_GET_TASK_TEMPLATES,
  SG_GET_TASK_SCHEDULES,
  SG_GET_USER_TASK_RESPONSE,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
  SG_GET_DATA_STORY_LOGS,
} from "constants/actions";
import AssignedTasks from "./AssignedTasks";

// Create a function that takes the above objects and creates a new array of objects, first we need to find the assigned tasks for the employee.
const getAssignedTasks = (empObj, assignedTasks, taskTemplates) => {
  // Find the assigned tasks for the employee
  const assignedTasksForEmployee = assignedTasks.results.filter((task) =>
    task.assigned_employees.includes(empObj.employee_id)
  );

  return assignedTasksForEmployee.map((task) => {
    return {
      taskId: task.id,
      taskRefId: task.task_ref_id,
      taskName: task.name,
      task_template_user_tasks: task.task_template_user_tasks,
      taskStatus: task.status_text,
      updated_at: task.updated_at,
    };
  });
};

const Tasks = () => {
  const [selected, setSelected] = useState(0);
  const [unreadStories, setUnreadStories] = useState(0);
  const [incompleteTasks, setIncompleteTasks] = useState(0);
  const dispatch = useDispatch();

  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    core_data,
    task_templates,
    scheduled_tasks,
    get_employee_user_category,
    get_auth,
    story_logs,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
      get_employee_permission: state.employee_permission,
      pending_stories: state.schedule_reports?.pending_schedule_reports,
      task_templates: state.task_templates?.task_templates,
      scheduled_tasks: state.task_templates?.scheduled_tasks,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      story_logs: state.story_logging,
    }),
    shallowEqual
  );

  const userTaskStatus = (task, userId) => {
    if (
      task?.tracked_user_tasks?.task_completions?.some(
        (obj) => Number(obj.employee_id) === Number(userId)
      )
    )
      return "Completed";
    if (!task?.tracked_user_tasks?.task_responses?.[userId]) return "New";
    return "In Progress";
  };

  const getUnreadTasks = (tasks) => {
    const taskStatuses = tasks?.results?.map((task) =>
      userTaskStatus(task, get_auth_employee?.employee_id)
    );
    const unreadTasks = taskStatuses?.filter(
      (status) => status === "New" || status === "In Progress"
    );
    return unreadTasks?.length;
  };

  useEffect(() => {
    if (get_auth?.employee_id) {
      const employeeId = Number(get_auth?.employee_id);

      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }
  }, [get_auth]);
  const unreadCount = (logs, storiesList) => {
    let unread = 0;
    logs?.data_story_logs?.results?.forEach((log) => {
      if (!log.user_interactions.Completed_count) {
        storiesList.forEach((story) => {
          if (log.data_story_id === story.storyId) {
            unread++;
          }
        });
      }
    });

    //  if no log exists for a particular story, add it to the unread list
    storiesList.forEach((story) => {
      let exists = false;
      logs?.data_story_logs?.results?.forEach((log) => {
        if (log.data_story_id === story.storyId) {
          exists = true;
        }
      });

      if (!exists) {
        unread++;
      }
    });
    return unread;
  };

  useEffect(() => {
    if (
      get_chat_gpt_recommendations &&
      get_chat_gpt_recommendations?.data_stories &&
      get_chat_gpt_recommendations?.data_stories?.results?.length > 0
    ) {
      const storyList = structureStories(
        get_chat_gpt_recommendations,
        core_data
      );
      const unread = unreadCount(story_logs, storyList);

      setUnreadStories(unread);
    }

    if (
      scheduled_tasks?.results?.length > 0 &&
      get_employee_user_category?.length > 0
    ) {
      let incompleteTasksNum = getAssignedTasks(
        get_employee_user_category[0],
        scheduled_tasks,
        task_templates
      );

      setIncompleteTasks(incompleteTasksNum?.length);
    }
  }, [
    get_chat_gpt_recommendations,
    scheduled_tasks,
    get_employee_user_category,
    story_logs,
  ]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DATA_STORIES,
      payload: `employee=${get_auth_employee?.employee_id}`,
    });

    dispatch({
      type: SG_GET_TASK_TEMPLATES,
      // payload: `employee=${get_auth_employee?.employee_id}`,
    });

    dispatch({
      type: SG_GET_TASK_SCHEDULES,
      // payload: `employee=${get_auth_employee?.employee_id}`,
    });

    dispatch({
      type: SG_GET_USER_TASK_RESPONSE,
    });
    dispatch({
      type: SG_GET_DATA_STORY_LOGS,
    });
  }, [dispatch]);

  function timeAgo(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;
    const secondsInMonth = 30 * secondsInDay;

    if (diffInSeconds < secondsInMinute) {
      return `${diffInSeconds} seconds ago`;
    } else if (diffInSeconds < secondsInHour) {
      const minutes = Math.floor(diffInSeconds / secondsInMinute);
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < secondsInDay) {
      const hours = Math.floor(diffInSeconds / secondsInHour);
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < secondsInWeek) {
      const days = Math.floor(diffInSeconds / secondsInDay);
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (diffInSeconds < secondsInMonth) {
      const weeks = Math.floor(diffInSeconds / secondsInWeek);
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else {
      const months = Math.floor(diffInSeconds / secondsInMonth);
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    }
  }

  const structureStories = (stories, core_data) => {
    const storyArray = stories?.data_stories?.results;
    // Create a function that does the following.
    // Loop through storyArray and map

    let storyList = [];
    storyArray.forEach((story) => {
      const parentId = story?.id;
      const createdDate = timeAgo(story?.created_at);

      story?.recommendation?.data_story?.forEach((item) => {
        const storyParent = core_data?.categories?.[0]?.categories?.find(
          (cat) => cat?.id === item?.parent_id
        );
        const storyPrimary = core_data?.categories
          ?.find((cat) => cat?.priority === "primary")
          ?.options?.find((op) => op?.id === item?.primary_id);
        const storyCategory = storyParent?.options?.find(
          (op) => op?.id === item?.category_option
        );

        storyList?.push({
          storyId: parentId,
          category_option: item?.category_option,
          dateCreated: createdDate,
          title: `${storyParent?.name} - ${storyCategory?.name}`,
          primary: storyPrimary,
        });
      });
    });

    return storyList;
  };

  const renderContent = () => {
    if (selected === 1) {
      const storiesObj = structureStories(
        get_chat_gpt_recommendations,
        core_data
      );
      return <Stories stories={storiesObj} />;
    } else if (selected === 2) {
      return (
        <AssignedTasks
          tasks={getAssignedTasks(
            get_employee_user_category[0],
            scheduled_tasks,
            task_templates
          )}
        />
      );
    }

    return (
      <>
        <Title>Tasks</Title>
        <Description>
          We've organized your tasks so that you know exactly what you need to
          do to build a great culture.
        </Description>

        <T2>Pending Tasks</T2>

        {Number(unreadStories) > 0 && (
          <TaskCard
            title="Understand your departments biggest themes"
            description="Your data story is a summary of your team's feedback."
            linkText="Click here to view your stories"
            link={`You have ${unreadStories} unread story`}
            view={true}
            onClick={() => setSelected(1)}
          />
        )}

        {Number(incompleteTasks) > 0 && (
          <TaskCard
            title="View your assigned surveys"
            description="Complete your survey."
            link={
              Number(incompleteTasks) > 0
                ? `You have ${incompleteTasks} open survey`
                : null
            }
            linkText="Click here to view"
            onClick={() => setSelected(2)}
            view={true}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      <Center>
        <SideMenu
          selected={selected}
          setSelected={setSelected}
          unreadCount={unreadStories}
          taskCount={incompleteTasks}
        />
        {renderContent()}
      </Center>
    </Container>
  );
};

export default Tasks;

const TaskCard = ({
  title,
  description,
  link,
  linkText,
  view,
  completed,
  url,
  onClick,
}) => {
  const navigate = useNavigate();

  return (
    <CardContainer view={view}>
      <Card link={link} completed={completed}>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </Card>

      {link && (
        <CardBottom>
          {link} - <Link onClick={() => onClick()}>{linkText}</Link>
        </CardBottom>
      )}
    </CardContainer>
  );
};


const Container = styled.div`
    display:flex;
    justify-content:center;
    font-family:'raleway';
    margin-top:30px;

`

const Center = styled.div`
   width:800px;
   position:relative;
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`

`

const T2 = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-top:30px;
    margin-bottom:20px;
`

const Card = styled.div`
    background-color:white;
    box-shadow:${props=>props.completed ? 'none' : '0px 0px 10px 0px rgba(0,0,0,0.1)'};
    border-radius:5px;
    padding:20px;
    position:relative;
    border:${props=>props.completed ? '2px solid #27CDA7' : 'none'};
    width:100%;

    
`

const CardTitle = styled.div`
    font-size:15px;
    font-weight:bold;
    margin-bottom:10px;
`
const CardDescription = styled.div`
    line-height:1.5;
    font-size:13px;
`

const CardContainer = styled.div`
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:${props=>!props.view ? 0.5 : 1};
`

const CardBottom = styled.div`
    height:40px;
    padding:10px;
    border-radius:0px 0px 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width:95%;
    background-color:#F8FAFF;
    font-size:12px;
    font-weight:bold;
`

const Link = styled.div`
    color:#2D70E2;
    cursor:pointer;
    font-weight:bold;
    font-size:12px;
    display:inline;
    margin-left:5px;
`