import React,{useState,useRef} from 'react';
import styled,{keyframes} from 'styled-components';


const Stat = ({text,value}) =>{

    return (
        <Container>
            <Top>{text}</Top>

            <Num>{value}</Num>
        </Container>
    )

}

export default Stat

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
 border-radius: 8px;
  border: 1px solid #e5e5e5;
  margin-bottom: 16px;
  line-height: 1.4;
  padding: 16px;
  width: 30%;
  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state

  // 3. Adjust delay for each sample
  animation-delay: ${({ delay }) => delay}s; 
`
const Top = styled.div`
    font-size:12px;
    font-weight:bold;
    margin-bottom:5px;
`

const Num = styled.div`
    font-size:30px;
    font-weight:bold;
    margin-bottom:5px;
`