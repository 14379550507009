import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import { Icon, Radio } from "semantic-ui-react";
import ClickAwayListener from "react-click-away-listener";
import { SET_NEW_NAVIGATION } from "constants/actions";
import Filters from "./Filters";

const Navigation = ({ outcomes, core_data }) => {
  const dispatch = useDispatch();
  const navigation = useSelector(
    (state) => state.audit?.new_navigation,
    shallowEqual
  );
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [options4, setOptions4] = useState([]);
  const [isOutcome, setIsOutcome] = useState(false);
  const [factorView, setFactorView] = useState(false);

  const { outcome, category, sub_category, factor, stage } = navigation;

  // Generate the dropdown options based on outcomes and categories
  const generate_outcome_options = () => {
    let _options = [
      { name: "All Feedback", id: 0 },
      { name: "Culture", id: 1 },
    ];
    outcomes?.questions.map((question) => {
      _options.push({ name: question.name, id: question.id, type: "outcome" });
    });
    return _options;
  };

  const generate_category_options = () => {
    let _options = [];
    core_data?.categories?.[0]?.categories?.map((category) => {
      _options.push({
        name: category.name,
        id: category.id,
        type: "category",
      });
    });
    return _options;
  };

  const generate_category_sub_options = () => {
    let _options = [{ name: "All Factors", id: null, type: "cat_option" }];
    core_data?.categories?.[0]?.categories
      .find((f) => f.id === category.id)
      ?.options.map((sub_category) => {
        _options.push({
          name: sub_category.name,
          id: sub_category.id,
          type: "cat_option",
        });
      });
    return _options;
  };

  const generate_factor_options = () => {
    let _options = [{ name: "All Factors", id: null, type: "factor" }];
    core_data?.questions?.dimensions.map((dimension, d) => {
      dimension.factors.map((factor) => {
        _options.push({
          name: factor.title,
          id: factor.id,
          type: "factor",
          dimension: d,
        });
      });
    });
    return _options;
  };

  useEffect(() => {
    setOptions1(generate_outcome_options());
    setOptions4(generate_factor_options());
  }, [outcomes]);

  useEffect(() => {
    setOptions2(generate_category_options());
  }, [core_data]);


  useEffect(() => {
    if (category) {
      setOptions3(generate_category_sub_options());
      updateSelection({ ...navigation, factor: null, sub_category: null });
    }
  }, [category]);

  useEffect(() => {
    if (!factor && options4.length === 0) {
      setOptions4(generate_factor_options());
    }
  }, [factor]);

  useEffect(() => {
    if (sub_category?.name == "Overview") {
      setOptions4([]);
      updateSelection({ ...navigation, factor: null });
      if (factorView) {
        setFactorView(false);
      }
    } else {
      if (!factorView) {
        setFactorView(true);
      }
    }
  }, [sub_category]);

  useEffect(() => {
    if (factor && options4.length === 0) {
      setOptions4(generate_factor_options());
    }

    if (factor?.name == "Overview") {
      setOptions4([]);
    }
  }, [factor]);

  useEffect(() => {
    if (outcome?.name === "Culture" && outcome?.id === 0) {
      setOptions4([]);
      updateSelection({
        ...navigation,
        outcome: { name: "All Feedback", id: 0 },
        factor: null,
        sub_category: null,
      });

      return;
    }
    if (outcome?.name !== "Culture") {
      setIsOutcome(true);
      updateSelection({ ...navigation, factor: null, sub_category: null });
    } else {
      setIsOutcome(false);
    }
  }, [outcome]);

  // Push out the selected dropdown options to the redux store

  // Action creators (assuming these are properly defined elsewhere)
  const updateSelection = (selection) =>
    dispatch({
      type: SET_NEW_NAVIGATION,
      payload: selection,
    });

  const selectFactor = (value) => {
    setFactorView(value);

    if (!value) {
      updateSelection({
        ...navigation,
        factor: null,
        sub_category: { name: "Overview", id: null, type: "cat_option" },
      });
    } else {
      updateSelection({
        ...navigation,
        sub_category: { name: "Factor overview", id: 1, type: "cat_option" },
      });
    }
  };

  const goBack = () => {
    if (navigation?.outcome?.id === 1 && !!navigation?.factor?.id) {
      return updateSelection({
        ...navigation,
        factor: { name: "All Factors", id: null, type: "factor" },
      });
    } else if (navigation?.outcome?.type === "outcome") {
      return updateSelection({
        ...navigation,
        outcome: {
          name: "All Feedback",
          id: 0,
          type: "outcome",
        },
        factor: null,
      });
    }
    if (options4.length > 0) {
      updateSelection({ ...navigation, factor: null });
      return setOptions4([]);
    }
    if (factorView) {
      selectFactor(false);
    } else if (sub_category?.name == "Factor overview") {
      updateSelection({
        ...navigation,
        sub_category: { name: "Overview", id: null, type: "cat_option" },
      });
    } else if (sub_category?.name == "Overview") {
      updateSelection({ ...navigation, category: null });
    }
  };

  useEffect(() => {
    updateSelection({
      outcome: {
        name: "All Feedback",
        id: 0,
      },
      factor: null,
      sub_category: null,
    });
  }, []);

  return (
    <>
      <Row>
        <Container>
          {outcome?.name !== "All Factors" && (
            <Dropdown
              options={options1}
              selected={outcome}
              label={"Data view"}
              setSelected={(option) => {
                if (option?.name === "Culture") {
                  updateSelection({
                    ...navigation,
                    outcome: option,
                    factor: {
                      name: "All Factors",
                      id: null,
                      type: "factor",
                    },
                  });
                } else {
                  updateSelection({ ...navigation, outcome: option });
                }
              }}
            />
          )}
          {options4.length > 0 && !isOutcome && (
            <>
              <Dropdown
                options={options4}
                selected={factor}
                label={"Selected Factor"}
                setSelected={(option) =>
                  updateSelection({ ...navigation, factor: option })
                }
              />
            </>
          )}
        </Container>
      </Row>
      <Row2 align={outcome?.name != "All Feedback"}>
        {outcome?.name != "All Feedback" && (
          <BackButton onClick={() => goBack()}>
            <Icon name="chevron left" style={{ marginTop: -7 }} />
            Back
          </BackButton>
        )}
        <Filters />
      </Row2>
    </>
  );
};

export default Navigation;

const Dropdown = ({ options, selected, setSelected, label }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!selected) {
      setSelected(options?.[0]);
    }
  }, [options]);

  const selectOption = (option) => {
    setOpen(false);
    setSelected(option);
  };

  return (
    <Box>
      <Label>{label}</Label>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <DropdownContainer
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Nav onClick={() => setOpen(!open)}>
            <Selection>{selected?.name}</Selection>
            <Icon
              name="triangle down"
              style={{ marginTop: -4, color: "#B3B3B3" }}
            />
          </Nav>
          {open && (
            <DropdownContent>
              {options.map((option, i) => (
                <Option key={i} onClick={() => selectOption(option)}>
                  {option.name}
                </Option>
              ))}
            </DropdownContent>
          )}
        </DropdownContainer>
      </ClickAwayListener>
    </Box>
  );
};

const Container = styled.div`
  display: flex;
  font-family: "raleway";
  margin-bottom: 20px;
`;

const DropdownContainer = styled.div`
  position: relative;
  border: 1px solid lightgrey;
  padding: 5px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Selection = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-right: 5px;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  width: 200px;
`;

const Option = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: #2a3039;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const Slash = styled.div`
  font-size: 20px;
  color: #2a3039;
  margin-right: 10px;
  color: #dfdfdf;
`;

const Text = styled.div`
  font-size: 12px;
  color: #2d70e2;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Radios = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Row2 = styled.div`
  display: flex;
  justify-content: ${(props) => (props.align ? "space-between" : "flex-end")};
  align-items: center;
`;

const Label = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-right: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;