import ApexCharts from "apexcharts";

var SentimentAnalysis = require("sentiment");
var sentiment = new SentimentAnalysis();

const scoring = {
  0: 10,
  1: 9,
  2: 8,
  3: 7,
  4: 6,
  5: 5,
  6: 4,
  7: 3,
  8: 2,
  9: 1,
  10: 0,
};

export const reverseScore = (s, r) => {
  if (r) {
    return scoring[s];
  }

  return s;
};

export const calculate_score = (s, structure) => {
  let score = 0;
  let total = 0;
  s.questions.map((q) => {
    let reverse =
      structure.dimensions[q.id].factors[q.factor].questions[q.q]?.reverse;
    score += reverseScore(q.response, reverse);
    total += 1;

    return null;
  });

  return score / total;
};

export const getFeedback = (
  currQuestion,
  categories,
  filter_data,
  structure
) => {
  let _data = [];
  filter_data?.[0]
    .filter((f) => "comments" in f)
    .filter((f) => f.comments.length > 0)
    .map((item) => {
      //calculate the overall score for this user
      let score = calculate_score(item, structure);
      let primary = categories.categories
        .find((f) => f.priority === "primary")
        .options.find(
          (f) =>
            f.id ===
            item.categories.find((x) => x.priority === "primary").response
        ).name;
      let secondary = categories.categories
        .find((f) => f.priority === "secondary")
        .options.find(
          (f) =>
            f.id ===
            item.categories.find((x) => x.priority === "secondary").response
        ).name;

      item.comments
        .filter((f, i) => i === currQuestion)
        .map((c) => {
          let sent = sentiment.analyze(c.response);
          let sent_score =
            sent.calculation.length > 0
              ? sent.score / sent.calculation.length
              : sent.score;
          let risk = 50 - score * 5 + (sent_score * -1 + 5) * 5;

          _data.push({
            text: c.response,
            primary,
            secondary,
            risk: Math.floor(risk),
            sentiment: sent_score,
          });
        });
    });
  return _data.sort((a, b) => b.risk - a.risk);
};

export const getFeedbackOTP = (filterData, structure, categories, question) => {
  let _data = [];
  let filterDataUse = filterData?.filter_data
    ? filterData?.filter_data
    : filterData;
  if (filterDataUse && filterDataUse.length > 0) {
    filterDataUse[0]
      .filter((f) => "comments" in f)
      .filter((f) => f?.comments?.length > 0)
      .map((item) => {
        //calculate the overall score for this user
        let score = calculate_score(item, structure);
        let primary = categories.categories
          .find((f) => f.priority === "primary")
          .options.find(
            (f) =>
              f.id ===
              item.categories.find((x) => x.priority === "primary").response
          ).name;
        let secondary = categories.categories
          .find((f) => f.priority === "secondary")
          .options.find(
            (f) =>
              f.id ===
              item.categories.find((x) => x.priority === "secondary").response
          )?.name;

        item.comments
          .filter((c) => c.ques_order === question)
          .map((c) => {
            let sent = sentiment.analyze(c.response);
            let sent_score =
              sent.calculation.length > 0
                ? sent.score / sent.calculation.length
                : sent.score;
            let risk = 50 - score * 5 + (sent_score * -1 + 5) * 5;

            _data.push({
              text: c.response,
              primary,
              secondary,
              risk: Math.floor(risk),
              sentiment: sent_score,
            });
          });
      });

    return _data.sort((a, b) => b.risk - a.risk);
  }
  return _data;
};

const _options = {
  series: [
    {
      name: "Series 1",
      data: [20, 100, 40, 30, 50, 60],
    },
  ],
  chart: {
    width: 500,
    type: "radar",
    id: "chart",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  plotOptions: {
    radar: {
      size: 300,
      polygons: {
        strokeColors: "#e9e9e9",
        fill: {
          colors: ["#f8f8f8", "#fff"],
        },
      },
    },
  },

  colors: ["#2d50e2"],
  markers: {
    size: 4,
    colors: ["#fff"],
    strokeColor: "#2d50e2",
    strokeWidth: 2,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
  xaxis: {
    categories: [
      "Safety",
      "Well-being",
      "Cohesion",
      "Empowerment",
      "Personalization",
      "Belonging",
    ],
    labels: {
      style: {
        fontSize: "20px",
        fontWeight: "bold",
        fontFamily: "Barlow Semi Condensed, sans-serif",
        colors: ["black", "black", "black", "black", "black", "black"],
      },
    },
  },
  yaxis: {
    tickAmount: 7,
    labels: {
      formatter: function (val, i) {
        if (i % 2 === 0) {
          return val;
        } else {
          return "";
        }
      },
    },
  },
};

const _data = [
  {
    average: 3,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "1",
    dimension: 0,
    stdev: 3,
    n: 2,
  },
  {
    average: 3,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "1",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 3.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "4",
    dimension: 0,
    stdev: 2.5,
    n: 2,
  },
  {
    average: 3.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "0",
    dimension: 1,
    stdev: 2.5,
    n: 2,
  },
  {
    average: 4,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "0",
    dimension: 0,
    stdev: 3,
    n: 2,
  },
  {
    average: 4,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "1",
    dimension: 0,
    stdev: 3,
    n: 2,
  },
  {
    average: 4,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "2",
    dimension: 1,
    stdev: 2,
    n: 2,
  },
  {
    average: 5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "1",
    dimension: 1,
    stdev: 2,
    n: 2,
  },
  {
    average: 5.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "3",
    dimension: 0,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 6,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "3",
    dimension: 0,
    stdev: 1,
    n: 2,
  },
  {
    average: 6,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "2",
    dimension: 1,
    stdev: 2,
    n: 2,
  },
  {
    average: 6.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "0",
    dimension: 0,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 6.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "0",
    dimension: 1,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 6.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "3",
    dimension: 1,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "1",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "1",
    dimension: 0,
    stdev: 0,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "2",
    dimension: 0,
    stdev: 0,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "3",
    dimension: 0,
    stdev: 1,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "3",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "4",
    dimension: 0,
    stdev: 0,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "1",
    dimension: 1,
    stdev: 0,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "1",
    dimension: 1,
    stdev: 1,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "2",
    dimension: 1,
    stdev: 0,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "3",
    dimension: 1,
    stdev: 0,
    n: 2,
  },
  {
    average: 7,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "4",
    dimension: 1,
    stdev: 2,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "2",
    dimension: 0,
    stdev: 2.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "2",
    dimension: 0,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "4",
    dimension: 0,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "0",
    dimension: 1,
    stdev: 2.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "1",
    dimension: 1,
    stdev: 2.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "1",
    dimension: 1,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "3",
    dimension: 1,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 7.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "3",
    dimension: 1,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "0",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "0",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "2",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "2",
    dimension: 0,
    stdev: 1,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "3",
    dimension: 0,
    stdev: 1,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "4",
    dimension: 0,
    stdev: 2,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "4",
    dimension: 0,
    stdev: 0,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "0",
    dimension: 1,
    stdev: 0,
    n: 2,
  },
  {
    average: 8,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "2",
    dimension: 1,
    stdev: 0,
    n: 2,
  },
  {
    average: 8.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "2",
    section: "0",
    dimension: 1,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 8.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "2",
    dimension: 1,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 8.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "0",
    section: "4",
    dimension: 1,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 8.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "1",
    section: "4",
    dimension: 1,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 8.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "3",
    section: "4",
    dimension: 1,
    stdev: 0.5,
    n: 2,
  },
  {
    average: 8.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "4",
    dimension: 1,
    stdev: 1.5,
    n: 2,
  },
  {
    average: 9,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "3",
    dimension: 1,
    stdev: 0,
    n: 2,
  },
  {
    average: 9.5,
    label: {
      id: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
      name: "Track and Field (W)",
      color: "#66cc70",
    },
    distribution: [0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1],
    role: "364733f4-e9d2-4d5d-b3e5-c9444551efe3",
    question: "4",
    section: "0",
    dimension: 0,
    stdev: 0.5,
    n: 2,
  },
];

const _questions = {
  title: "Culture of excellence audit tool",
  dimensions: [
    {
      id: 1,
      title: "Human Factors",
      factors: [
        {
          id: 1,
          title: "Safety",
          questions: [
            {
              q: "People feel safe sharing ideas, feedback and opinions on this team, even if they differ from others.",
              id: 1,
              role: null,
              reverse: false,
            },
            {
              q: "If I have concerns about harassment or safety on this team, I know what to do (i.e. where to go, who to talk to).",
              id: 11,
              role: null,
              reverse: false,
            },
            {
              q: "Harassment is tolerated on this team.",
              id: 21,
              role: null,
              reverse: true,
            },
            {
              q: "Physical safety is taken seriously on this team.",
              id: 31,
              role: null,
              reverse: false,
            },
            {
              q: "People in positions of power on this team use that power inappropriately.",
              id: 41,
              role: null,
              reverse: true,
            },
          ],
        },
        {
          id: 2,
          title: "Well-being",
          questions: [
            {
              q: "Our team demonstrates genuine care for mental health and well-being.",
              id: 2,
              role: null,
              reverse: false,
            },
            {
              q: "Sometimes I feel that my physical health is compromised in service of performance.",
              id: 12,
              role: null,
              reverse: true,
            },
            {
              q: "I feel unsupported or undermined in my spiritual beliefs on this team.",
              id: 22,
              role: null,
              reverse: true,
            },
            {
              q: "My involvement on this team helps me feel fulfilled.",
              id: 32,
              role: null,
              reverse: false,
            },
            {
              q: "My personal values often conflict with the values of this team.",
              id: 42,
              role: null,
              reverse: true,
            },
          ],
        },
        {
          id: 3,
          title: "Cohesion",
          questions: [
            {
              q: "I feel respected and cared for by the people on this team.",
              id: 3,
              role: null,
              reverse: null,
            },
            {
              q: "Even outside of the sport context, I feel a strong social connection to many people on this team.",
              id: 13,
              role: null,
              reverse: null,
            },
            {
              q: "People on this team spend time together outside of sport.",
              id: 23,
              role: null,
              reverse: null,
            },
            {
              q: "Our team works well together when pursuing a goal.",
              id: 33,
              role: null,
              reverse: null,
            },
            {
              q: "Our team cohesion is a strength in competitive situations.",
              id: 43,
              role: null,
              reverse: null,
            },
          ],
        },
        {
          id: 4,
          title: "Empowerment",
          questions: [
            {
              q: "I am empowered to take action in my role.",
              id: 4,
              role: null,
              reverse: null,
            },
            {
              q: "I am given the space to make decisions about my own development and performance.",
              id: 14,
              role: null,
              reverse: null,
            },
            {
              q: "On this team, people are regularly encouraged to make meaningful contributions.",
              id: 24,
              role: null,
              reverse: null,
            },
            {
              q: "I wish we focused more on people’s strengths on this team.",
              id: 34,
              role: null,
              reverse: true,
            },
            {
              q: "I have confidence in the abilities and attributes I bring to this team.",
              id: 44,
              role: null,
              reverse: null,
            },
          ],
        },
        {
          id: 5,
          title: "Personalization",
          questions: [
            {
              q: "This team cares about me as a person.",
              id: 5,
              role: null,
              reverse: null,
            },
            {
              q: "This team takes an individualized approach to growth and development.",
              id: 15,
              role: null,
              reverse: null,
            },
            {
              q: "This team understands everybody’s unique strengths and weaknesses.",
              id: 25,
              role: null,
              reverse: null,
            },
            {
              q: "Too often, a one-size-fits-all approach is used on this team.",
              id: 35,
              role: null,
              reverse: true,
            },
            {
              q: "This team values individual differences and diversity.",
              id: 45,
              role: null,
              reverse: null,
            },
          ],
        },
      ],
    },
    {
      id: 2,
      title: "Impact Factors",
      factors: [
        {
          id: 1,
          title: "Vision",
          questions: [
            {
              q: "Our team has a clear and compelling vision (i.e., ultimate goal or desired outcome).",
              id: 6,
              role: null,
              reverse: null,
            },
            {
              q: "Our team’s vision is supported by a great strategy to achieve it.",
              id: 16,
              role: null,
              reverse: null,
            },
            {
              q: "We take an intentional approach (i.e., thoughtful planning and preparation) to pursuing our vision.",
              id: 26,
              role: null,
              reverse: null,
            },
            {
              q: "We revisit our team vision often in our environment.",
              id: 36,
              role: null,
              reverse: null,
            },
            {
              q: "Our team lacks a clear and compelling vision of what ultimate success would look like for us.",
              id: 46,
              role: null,
              reverse: true,
            },
          ],
        },
        {
          id: 2,
          title: "Identiy",
          questions: [
            {
              q: "As a team, we’ve identified a shared set of core values.",
              id: 7,
              role: null,
              reverse: null,
            },
            {
              q: "People on this team are bought-in to the way we do things.",
              id: 17,
              role: null,
              reverse: null,
            },
            {
              q: "Our actions consistently align with our team’s core values.",
              id: 27,
              role: null,
              reverse: null,
            },
            {
              q: "I know what we stand for on this team.",
              id: 37,
              role: null,
              reverse: null,
            },
            {
              q: "Our team lacks a distinct identity.",
              id: 47,
              role: null,
              reverse: true,
            },
          ],
        },
        {
          id: 3,
          title: "Climate",
          questions: [
            {
              q: "I find our environment optimistic and growth-oriented.",
              id: 8,
              role: null,
              reverse: null,
            },
            {
              q: "I am challenged to get better in this environment.",
              id: 18,
              role: null,
              reverse: null,
            },
            {
              q: "I rarely get the feedback I need to improve on this team.",
              id: 28,
              role: null,
              reverse: true,
            },
            {
              q: "Our team environment ignites engagement and motivation.",
              id: 38,
              role: null,
              reverse: null,
            },
            {
              q: "Optimal learning conditions and approaches are a priority in our environment.",
              id: 48,
              role: null,
              reverse: null,
            },
          ],
        },
        {
          id: 4,
          title: "Accountability",
          questions: [
            {
              q: "Holding others accountable is a strength of our leadership team.",
              id: 9,
              role: null,
              reverse: null,
            },
            {
              q: "People hold each other accountable on this team",
              id: 19,
              role: null,
              reverse: null,
            },
            {
              q: "The expectations and standards on this team are unclear.",
              id: 29,
              role: null,
              reverse: true,
            },
            {
              q: "When expectations or standards aren’t met on this team, it is addressed.",
              id: 39,
              role: null,
              reverse: null,
            },
            {
              q: "I believe it’s important to hold myself accountable on this team.",
              id: 49,
              role: null,
              reverse: null,
            },
          ],
        },
        {
          id: 5,
          title: "Adaptability",
          questions: [
            {
              q: "Our team responds well to changing or unpredictable conditions.",
              id: 10,
              role: null,
              reverse: null,
            },
            {
              q: "When things change unexpectedly, our team adapts well.",
              id: 20,
              role: null,
              reverse: null,
            },
            {
              q: "Our team quickly pivots and changes course in the face of unexpected circumstances.",
              id: 30,
              role: null,
              reverse: null,
            },
            {
              q: "Our team misses out on important opportunities because we are too slow to react.",
              id: 40,
              role: null,
              reverse: true,
            },
            {
              q: "Our team strives to innovate and be ahead of the curve.",
              id: 50,
              role: null,
              reverse: null,
            },
          ],
        },
      ],
    },
  ],
  type: 2,
  type_name: "Innerlogic",
  comments: null,
};

const getAverage = (d) => {
  let scores = 0;
  let total = 0;
  d.map((item) => {
    scores += item.average * item.n;
    total += item.n;
  });
  return scores / total;
};

const getOptions = (dimension = 0) => {
  let factors = _questions.dimensions[dimension].factors.map(
    (item) => item.title
  );
  let __options = _options;
  __options.xaxis.categories = factors;

  let series = [
    {
      name: "Series 1",
      data: factors.map((item, i) => {
        return getAverage(
          _data.filter(
            (f) => f.dimension == dimension && Number(f.section) == i
          )
        );
      }),
    },
  ];

  __options.series = series;

  return __options;
};

export const getSpiderChartURI = async () => {
  return await ApexCharts.exec("chart", "dataURI").then(({ imgURI }) => {
    return imgURI;
  });
};

export const getGrade = (avgScore, reverse = false) => {
  const score = reverse ? 100 - avgScore : avgScore;

  if (score >= 90) {
    return "A+";
  }
  if (score >= 85) {
    return "A";
  }
  if (score >= 80) {
    return "A-";
  }
  if (score >= 77) {
    return "B+";
  }
  if (score >= 73) {
    return "B";
  }
  if (score >= 70) {
    return "B-";
  }
  if (score >= 67) {
    return "C+";
  }
  if (score >= 63) {
    return "C";
  }
  if (score >= 60) {
    return "C-";
  }
  if (score >= 50) {
    return "D";
  }

  return "F";
};

export const getColorGrade = (avgScore, reverse = false) => {
  const score = reverse ? 100 - avgScore : avgScore;
  if (score >= 73) {
    return "rgba(54, 224, 38, .85)";
  }
  if (score >= 50) {
    return "rgba(255, 191, 0, .85)";
  }

  return "rgba(224, 44, 38, 0.75)";
};
