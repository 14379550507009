import styled from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
import useAutoHeight from "../AI/useAutoHeight";
import Query from "./Query";

const RecommendationBuilder = () => {
    const { ref: inputRef, resize } = useAutoHeight(20);
    const [prompt, setPrompt] = React.useState("");

    return (
        <Container>
            <Title>Lets build a recommendation for you</Title>
            <Sub>We'll ask you a few questions to get the process started.</Sub>

          <ChatWindow>
            <Query query={"What is the name of the person you are writing this recommendation for?"} />
            <ChatInput>
              <Input
                ref={inputRef}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                style={{ width: 600, marginRight: 10 }}
                placeholder="Enter your response here"
                rows="1"
              />
              <SendButton>
                <Icon name={"send"} />
              </SendButton>
            </ChatInput>
            </ChatWindow>
        </Container>
    )

}

export default RecommendationBuilder;

const Container = styled.div`
    padding-left:58px;
    margin-top:40px;
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 24px;
color: #2A3039;
margin-bottom:10px;
`

const Sub = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
`

const Question = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size:20px;
padding:30px;
`

const ChatWindow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top:40px;
`;

const ChatInput = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  padding: 10px;

  position: absolute;
  bottom: 10px;
  max-width: 970px;
  // width: calc(60vw - 50px);
  width: 50vw;

  // @media screen and (min-width: 1390px) {
  //   width: 54%;
  // }
`;

const Input = styled.textarea`
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: black;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  max-height: 300px; /* Height for 4 lines */
  line-height: 20px; /* Adjust the line-height to match the desired initial height */
  font-size: 14px; /* Adjust the font-size according to your design */
  width: 100%;
  &::placeholder {
    color: #999;
  }
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  outline: none;
  padding: 5px;
  margin-left: 5px;
`;
