import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Icon from 'assets/images/UI/material-symbols_full-stacked-bar-chart.svg'
import DotNavigation from "./DotNavigation";
import SideNavigation from "./SideNavigation";

const Pillar = () =>{

    const [tab,setTab] = useState(0);

    return (
        <TopContainer>
            <SideNavigation />
        <Container>
            <Top>
                <IMG src={Icon} />
                <Title>Innovation Potential</Title>
                <Sub>The innovation potential for Department A is <B>66%</B> which ranks it <B>8th</B> in the organization overall. This score has improved by <B>6%</B> since the last assessment.</Sub>
                <Description>Innovation potential is an organization's ability to come up with and implement new and creative ideas that improve their products, services, processes, or business models.</Description>
            </Top>

            <Tabs>
                <Tab selected={tab==0} onClick={()=>setTab(0)}>Summary</Tab>
                <Tab selected={tab==3} onClick={()=>setTab(3)}>Charts</Tab>
                <Tab selected={tab==1} onClick={()=>setTab(1)}>Drivers</Tab>
                <Tab selected={tab==2} onClick={()=>setTab(2)}>Recommendations</Tab>
                <Tab selected={tab==3} onClick={()=>setTab(3)}>Feedback</Tab>
            </Tabs>

            <Content>
                <ContentTitle>
                    What does this mean?
                </ContentTitle>
                <Text>
                    <B2>Department A</B2> has a <B3>high innovation potential</B3> score, which means that they are able to come up with and implement new and creative ideas that improve their products, services, processes, or business models. This is a good thing, as it means that they are able to adapt to changing market conditions and customer needs.
                </Text>

                <ContentTitle>What are people saying?</ContentTitle>
                <Text>
                    The themes which most relate to the innovation potential of this department are:

                    <ul>
                        <li>People are encouraged to come up with new and better ways of doing things</li>
                        <li>People are encouraged to take risks</li>
                        <li>People are encouraged to think outside the box</li>
                        <li>People are encouraged to try new things</li>
                        <li>People are encouraged to be creative</li>
                    </ul>
                </Text>

            <KnowledgeArea>
                <KnowledgeTitle>Unlocking Innovation Potential</KnowledgeTitle>
                <KnowledgeText>To fully realize their innovation potential, 
                    organizations must create a culture that fosters experimentation 
                    and risk-taking. Encouraging cross-functional collaboration, rewarding 
                    experimentation, and creating a safe space for feedback can help build 
                    this culture. 
                </KnowledgeText>
                <DotNavigation currentStep={2} totalSteps={4} />

            </KnowledgeArea>

            </Content>
        </Container>

        </TopContainer>
    )
}

export default Pillar;


const TopContainer = styled.div`
    width:100%;
    padding-left:58px;
    margin-top:30px;
    display:flex;
`

const Container = styled.div`
    width:calc(100% - 250px);
    padding:50px 30px;
    color: #2A3039;
    display:flex;
    flex-direction:column;
    align-items:center;
`

const Sub = styled.div`
    font-size:20px;
    color: #666D79;
    line-height:1.5;
`

const Top = styled.div`
    width:100%;
    border-bottom:1px solid #E5E5E5;
    padding-bottom:20px;
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
margin-bottom:15px;
`

const B = styled.span`
    font-weight:bold;
    font-size:24px;
`

const B2 = styled.span`
    font-weight:bold;
`

const B3 = styled.span`
    font-weight:bold;
    color:#2D70E2;
`

const IMG = styled.img`
    height:80px;
    margin-bottom:20px;
    margin-left:-10px;
`
const Description = styled.div`
    font-size:12px;
    font-family: 'Raleway';
    margin-top:15px;
`

const Tabs = styled.div`
    display:flex;
    margin-top:20px;
`

const Tab = styled.div`
    font-size:14px;
    font-family: 'Raleway';
    font-weight: 700;
    margin-right:20px;
    cursor:pointer;
    color:${props=>props.selected?'#2A3039':'#666D79'};
    border-bottom:${props=>props.selected?'2px solid #2A3039':'2px solid transparent'};
`

const Content = styled.div`
    margin-top:50px;
    display:flex;
    flex-direction:column;
    max-width:800px;
`

const ContentTitle = styled.div`
    font-size:22px;
    font-family: 'Raleway';
    font-weight: 700;
    
`

const Text = styled.div`
    font-size:14px;
    font-family: 'Raleway';
    margin-top:20px;
    line-height:1.5;
    margin-bottom:30px;
`   

const KnowledgeArea = styled.div`
    width:400px;
`

const KnowledgeTitle = styled.div`
    font-size:22px;
    font-family: 'Raleway';
    font-weight: 700;
    line-height:1.3;

`
const KnowledgeText = styled.div`
    font-size:14px;
    font-family: 'Raleway';
    margin-top:20px;
    line-height:1.5;

`