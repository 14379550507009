import React,{useState,useRef} from 'react';
import styled,{keyframes} from 'styled-components';
import {Icon} from 'semantic-ui-react'


const Conversation = () =>{
    const inputRef = useRef(null);
    const [prompt,setPrompt] = useState("")

    const handleKeyDown = (e) =>{

        if(e.key === 'Enter'){
            e.preventDefault()
            if(prompt.length>0){
                inputRef.current.focus();
                setPrompt("")
            }
        }
    }

    return (
        <Container>
            <Center>
                <Title>Department A</Title>
                <Score>Customer Satisfaction: 90th percentile</Score>
                <T3>Conversation collection date: <strong>October 3rd, 2024</strong></T3>
                <Bubble>
                <T2>Information Summary</T2>

                <Description>
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.

                </Description>

                <Description>
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                   Lorum ipsum dolor sit amet, consectetur adipiscing elit.

                </Description>
                </Bubble>

                
                <T2>Interact with this data</T2>
                <Samples>
                    <Sample>
                        <SampleTitle>Application of learning</SampleTitle>
                        <SampleText>
                            Find out how to apply the information collected.
                        </SampleText>
                    </Sample>

                    <Sample end={true}>
                        <SampleTitle>Themes</SampleTitle>
                        <SampleText>
                            Generate the top themes from the data.
                        </SampleText>
                    </Sample>
                </Samples>

                <ChatInput>
                <Input
                  ref={inputRef}
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  style={{ width: "100%", marginRight: 10 }}
                  placeholder="Enter your question here..."
                  onKeyDown={handleKeyDown}
                  rows="1"
                //   disabled={feedback_length === 0}
                //   disabledStyle={feedback_length === 0}
                />
                <SendButton onClick={() => handleKeyDown({ key: "Enter" })}>
                  <Icon name={"send"} />
                </SendButton>
              </ChatInput>
            </Center>
         
        </Container>
    )
}

export default Conversation;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:600px;
    `
const Bubble = styled.div`
    background-color:#EBF1FD;
    border-radius:10px;
    padding:20px;
    margin-bottom:40px;
`

const Title = styled.div`
    font-size:12px;
    font-weight:600;
    color:grey;
    margin-bottom:5px;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`
    margin-top:20px;
    color:black;
`

const T3 = styled.div`
    font-size:14px;
    margin-bottom:20px;
`
const Score = styled.div`
    font-size:20px;
    margin-bottom:20px;
    font-weight:bold;
`

const Sample = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  margin-right: ${({ end }) => (end ? "0" : "16px")};
  margin-bottom: 16px;
  line-height: 1.4;
  padding: 16px;

  width: calc(50% - 8px);

  @media screen and (min-width: 1563px) {
    width: 40%;
  }

  @media screen and (min-width: 1630px) {
    width: 40%;
  }

  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
  cursor: pointer;

  // 3. Adjust delay for each sample
  animation-delay: ${({ delay }) => delay}s; 
`;

const SampleTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const SampleText = styled.div`
  font-size: 12px;
`

const Samples = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;


const ChatInput = styled.div`
display: flex;
align-items: center;
background-color: white;
border-radius: 8px;
border: 1px solid #dfdfdf;
padding: 16px;
width: 100%;
`;

const Input = styled.textarea`
flex-grow: 1;
background-color: transparent;
border: none;
color: #2a3039;
padding: 0 5px; /* Remove padding-top and padding-bottom */
outline: none;
resize: none;
overflow: auto;
max-height: 300px; /* Height for 4 lines */
line-height: 24px; /* Adjust the line-height to match the desired initial height */
width: 100%;

font-family: Raleway;
font-size: 14px;

opacity: ${({ disabledStyle }) => (disabledStyle ? "0.5" : "1")};
cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};

&::placeholder {
  color: #666d79;
  font-size: 14px;
}
`;

const SendButton = styled.button`
background-color: transparent;
border: none;
color: #e0e0e0;
cursor: pointer;
outline: none;
padding: 5px;
margin-left: 5px;
`;

