import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Icon, Button, Confirm, Grid, Header, Segment, TransitionablePortal } from "semantic-ui-react";

import {
  SG_EDIT_SUBSCRIPTION,
  SG_CANCEL_SUBSCRIPTION,
} from "constants/actions";

export default function Price({
  prices,
  priceId,
  selectedPaymentMethod,
  selectedPlan,
  organization,
  setPriceId,
  subscription,
  // setSubscriptionId,
  subscriptionSelected,
  setSubscriptionSelected,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  function SelectedPriceRow(e) {
    setPriceId(e);
    setSubscriptionSelected(e);
    // if (subscription?.id > 0) {
    //   dispatch({
    //     type: SG_EDIT_SUBSCRIPTION,
    //     payload: {
    //       id: subscription?.id,
    //       price: e,
    //       quantity: organization.no_of_licenses,
    //     },
    //   });
    // }
  }

  // console.log(subscriptionSelected)

  function DeleteSubscription() {
    if (subscription?.id > 0) {
      dispatch({
        type: SG_CANCEL_SUBSCRIPTION,
        payload: {
          id: subscription?.id,
        },
      });
      setOpen(false);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  return prices.map((p, i) => {
    return (
      <Container key={i}>
        <BoxContainer>
          <Title>{subscriptionSelected?.id ?
            p.stripe_id === subscriptionSelected?.stripe_id ? "Selected" : "Switch" : "Subscribe"} {p?.product_name}</Title>
          <TrialText>Total Licenses : {organization?.no_of_licenses} </TrialText>
          <TrialText>Price Per $ {p.amount_decimal} </TrialText>

        </BoxContainer>
        <SubContainer>
          <BoxContainer>
            {selectedPaymentMethod ?
            (p.stripe_id === subscriptionSelected?.stripe_id) ? (
              <StyleButton disabled>Selected</StyleButton>
            ) : (
              <StyleButton
                onClick={() => SelectedPriceRow(p)}
                selected={priceId === p.id ? true : false}
              >
                {priceId === p.id ? "Selected" : "Select"}
              </StyleButton>
            )
          : <Read>Add a new card or Select a Card from above to 
            select a plan
          </Read>
          }
          
          {/* {(p.stripe_id === subscriptionSelected?.stripe_id) ?
              <Icon
                name="trash alternate outline"
                onClick={() => setOpen(true)}
                color="red"
                style={{ cursor: "pointer" }}
                size="large"
              /> : ''}
            <Confirm
              header='Warning Irreversible'
              content='This would remove all your data and close your access to app'
              open={open}
              onCancel={() => setOpen(false)}
              onConfirm={() => DeleteSubscription()}
            /> */}
          </BoxContainer>
        </SubContainer>
      </Container>
    );
  });
}

// const Container = styled.div`
//   width: 500px;
//   display: flex;
//   flex-direction: column;
//   margin-top: 30px;
//   // margin-left: calc((100% - 500px) / 2);
//   border-radius: 8px;
//   box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
//   padding: 36px;

//   @media (max-width: 768px) {
//     width: 100%;
//     margin: 20px 0 0 0;
//     padding: 36px 20px 36px 20px;
//   }
// `;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(179, 179, 179, 0.5);
  padding: 36px;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 100px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;
const Text = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TrialText = styled.div`
  font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;
const Summary = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
`;
const Read = styled.div`
  width: 200px;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #6a00ff;
  margin-top: 30px;
  cursor: pointer;
`;
const Cancel = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #ff2d55;
  margin-top: 15px;
  // cursor: pointer;
`;
const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.disabled ? "#606060" : props.selected ? "#5e00e3" : "#6a00ff"};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 40px;
`;
