import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import Chart from "react-apexcharts";
import ComparisonTable from "./ComparisonTable";

const Factors = () =>{

    return (
        <Container>
            <Title>Factor Analytics</Title>
            <Description>Below you'll find an analysis of culture factors as it relates to the archived tags associated with individuals who have completed the survey prior to leaving the organization.</Description>
            <ComparisonTable data={test_data}/>

            <ChartArea>
              
            </ChartArea>
           
        </Container>
    )

}


export default Factors


const Container = styled.div`
    width: 100%;
    max-width:1000px;
`
const Title = styled.div`
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    margin-bottom:24px;
`
const Description = styled.div`
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
max-width:800px;
line-height:1.5;
`
const ChartArea = styled.div`
    width:100%;
    margin-top:40px;
`
const test_data = [
    {score:7.2,org_score:6.5,name:"Psychological Safety"},
    {score:6.2,org_score:6.5,name:"Adaptability"},
    {score:5.2,org_score:6.5,name:"Values"},
    {score:4.2,org_score:6.5,name:"Inclusivity"},
    {score:3.2,org_score:6.5,name:"Communication"},
    {score:2.2,org_score:6.5,name:"Impact"},
    {score:1.2,org_score:6.5,name:"Purpose"}
]