import React from "react";
import styled from "styled-components";

const LabeledToggle = (props) => {
  const { onSelect, selected, options } = props;

  const generatedLabels = options?.map((label) => {
    return (
      <LabelWrapper
        selected={selected === label}
        onClick={() => onSelect(label)}
      >
        <LabelText selected={selected === label}>{label}</LabelText>
      </LabelWrapper>
    );
  });
  return <SwitchContainer>{generatedLabels}</SwitchContainer>;
};

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #ebf1fd;
  border-radius: 8px;
  padding: 2px;
  align-self: stretch;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => (props.selected ? "#fff" : "#ebf1fd")};
  padding: 2px;
  min-width: 127px;
  border-radius: 8px;
  height: 36px;
  justify-content: center;
  cursor: pointer;
`;

const LabelText = styled.div`
  color: #2a3039;
  text-align: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  line-height: 120%;
`;

export default LabeledToggle;
