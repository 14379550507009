import React, { useEffect, useState } from "react";
import {
  TeamCard,
  Name,
  Avatar,
  TeamName,
  TeamLeader,
  Members,
  ColumnName,
  BlankAvatar,
  MembersMobile,
} from "settings/styled-list";

import styled from 'styled-components';



const Team = (props) => {
  const {data, members, setSelectedTeam} = props;
  
  const getLeader = () => {
    let leader = members.filter(
      (member) => member.team === data.id && member.role == 2
    )[0];
    return leader
      ? leader.first_name
        ? leader.first_name + " " + leader.last_name
        : leader.email
      : "";
  };

 
  return (
    <TeamCard onClick={() => setSelectedTeam(data)}>
      <Name>
        <BlankColor color={stringToHslColor(data?.name, 60, 50)}>{data?.name?.[0]}</BlankColor>
        <div style={{display:'flex',alignItems:'center'}}>
          <TeamName>{data.name}</TeamName>
          <TeamLeader>{members ? getLeader() : ""}</TeamLeader>
          <MembersMobile>
            {members
              ? members.filter((mm) => mm.team === data.id).length
              : 0}{" "}
            members
          </MembersMobile>
        </div>
      </Name>

      <Members>
        {members ? members.filter((mm) => mm.team === data.id).length : 0}{" "}
        members
        {/* <ClickableView >Edit</ClickableView> */}
      </Members>

      <ColumnName>
        <TeamName>{data.team_type_name}</TeamName>
        
      </ColumnName>

    </TeamCard>
  );
};

export default Team;

function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str?.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

const BlankColor = styled.div`
  background-color:${props=>props.color};
  color:white;
  height:35px;
  width:35px;
  border-radius:18px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:20px;
  font-weight:bold;
   margin-left:20px;
  margin-right:10px;
`