// src/AIEditModal.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_POST_ML_SERVER_V1,
  } from "constants/actions";

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  font-family: 'Raleway';
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    font-family: "Raleway";
    font-size: 14px;
    color: #2a3039;
    margin-top: 10px;
    resize:none;

`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #2D70E2;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

const T2 = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
`

const ButtonArea = styled.div`
    display:flex;
    justify-content:flex-end;
    width:100%;
`


const AIEditModal = ({ selectedText, onSave, onCancel }) => {
    const dispatch = useDispatch();
    const get_chat_gpt = useSelector((state) => state.chat_gpt,shallowEqual);
  const [editRequest, setEditRequest] = useState('');
  const [suggestedEdit, setSuggestedEdit] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    onSave(suggestedEdit);
  };

  const handleSubmit = async () => {
    const prompt = `
    Edit the following text:
        ${selectedText}
    Based on the following request:
        ${editRequest}
    ## END OF REQUEST
    Only return the edited text in markdown syntax:`
    dispatch({
                  type: SG_POST_ML_SERVER_V1,
                  payload: {
                    input_text: '',
                    prompt: prompt,
                  },
                });
    
  };

  useEffect(()=>{
    if(get_chat_gpt?.chat_gpt_ml?.response){
        let resp = get_chat_gpt?.chat_gpt_ml?.response
      

        setSuggestedEdit(resp)
    }

    if(get_chat_gpt.pending){
        setLoading(true)
    }
    else{
        setLoading(false)
    }
},[get_chat_gpt])

  return (
    <Modal>
      <h3>Ask AI for Edit</h3>
      <T2>Selected Text:</T2>
      <p>
      {selectedText}
      </p>
      <TextArea
        placeholder="Describe your edit request..."
        value={editRequest}
        onChange={(e) => setEditRequest(e.target.value)}
      />

{suggestedEdit && 
    (<>
      <T2>Suggested Edit:</T2>
     <TextArea readOnly value={suggestedEdit} />
    </>)
   }

      <ButtonArea>
     
      {suggestedEdit? (
        <>
         
          <Button onClick={onCancel}>Reject</Button>
          <Button onClick={handleSave}>Accept</Button>
        </>
      ):
        (
            <>
             <Button onClick={onCancel}>Cancel</Button>
             <Button onClick={handleSubmit} disabled={loading}>
                {loading ? 'Loading...' : 'Submit'}
            </Button>
            </>
        )
      }
     
      </ButtonArea>
     
    </Modal>
  );
};

export default AIEditModal;
