import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Spark from "assets/images/UI/ion_sparkles-outline.svg";
import { Icon,Dimmer } from "semantic-ui-react";
import Questions from './Questions'
import Information from './Information';


const RecV4 = () => {

    const [open, setOpen] = useState(false)
    const [info,setInfo] = useState(null)

    if(open){
        return <Questions/>
    }

    return (
        <Container>
            <T1>Recommendation Catalogue</T1>
            <T2>
                Explore our range of solutions to address specific areas of improvement in your organization's culture.
            </T2>

            <Options>
            <OptionItem 
                onClick={()=>setOpen(true)}
                openModal={()=>setInfo(1)}
                title={"Micro Action Plans"} 
                description={"Targeted steps to quickly address specific areas needing improvement in workplace culture for immediate impact."} />
            <OptionItem onClick={()=>setOpen(true)} title={"Employee Training"} description={"Programs designed to enhance skills and bridge gaps identified through culture feedback, tailored to organizational needs."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"Workshops"} description={"Interactive sessions that provide hands-on experience in specific skills, fostering teamwork and innovation among participants."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"Recognition"} description={"Schemes that acknowledge and reward employees' contributions, structured to boost morale and encourage excellence."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"Communication Strategies"} description={"Plans to improve organizational information flow, ensuring transparency and enhancing overall communication effectiveness."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"Team Building"} description={"Activities aimed at strengthening team bonds and improving collaboration through engaging and constructive exercises."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"Crisis Tactics"} description={"Predefined actions and protocols to effectively manage organizational crises and maintain stability."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"Engagement Programs"} description={"Initiatives to boost employee involvement and satisfaction through development opportunities, social events, and wellness programs."} coming_soon={true}/>
            <OptionItem onClick={()=>setOpen(true)} title={"OKR Strategies"} description={"Frameworks for setting, communicating, and monitoring clear, aligned organizational goals and results each quarter."} coming_soon={true}/>
            </Options>

            <Information open={info} setOpen={setInfo}/>
        </Container>
    )
} 

export default RecV4

const OptionItem = ({title,description,onClick,coming_soon,openModal}) =>{

    return (
        <Card>
             <Dimmer active={coming_soon}>
                Coming Soon
            </Dimmer>
            <Title><Icon2 src={Spark}/>{title}</Title>
            <Description>
                {description}
            </Description>
            {!coming_soon && (<ArrowContainer onClick={()=>onClick()}>
                <Icon name={'arrow right'} />
            </ArrowContainer>)}

            {!coming_soon && (<More onClick={()=>openModal()}>
                More information
            </More>)}
            
            {/* <Button>View More</Button> */}
        </Card>
    )
}

const Container = styled.div`
    margin-top:50px;
    padding-left:20px;
    font-family:'raleway';
`

const T1 = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:10px;
`

const T2 = styled.div`
    font-size:16px;
    margin-bottom:30px;
`

const Title = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:20px;
`

const Options = styled.div`
    display:flex;
    flex-wrap:wrap;
`

const More = styled.div`
    font-size:12px;
    color:#007bff;
    cursor:pointer;
    position:absolute;
    bottom:10px;
    left:20px;
`

const Card = styled.div`
    width:300px;
    height:200px;
    border:1px solid #ccc;
    border-radius:10px;
    margin-right:10px;
    padding:20px;
    margin-bottom:10px;
    position:relative;
    `

const Description = styled.div`
    line-height:1.5;
`
const Button = styled.div`
    background-color:#007bff;
    color:#fff;
    padding:10px;
    text-align:center;
    cursor:pointer;
    margin-top:20px;
`

const Icon2 = styled.img`
    width:20px;
    margin-right:10px;
`

const ArrowContainer = styled.div`
    position:absolute;
    right:10px;
    bottom:10px;
    cursor:pointer;
    color:#007bff;
    font-size:20px;
    `