import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Radio ,Dropdown,Input} from 'semantic-ui-react';
import {useNavigate} from 'react-router-dom'


const Permissions = () =>{
    const navigate = useNavigate()

    return (
        <Container>
            <T1>Report Options</T1>
            <Description>
                Please select the permissions that apply for the report you are requesting to be generated.
            </Description>

            <Card>

           

            <Option>
            <T2>Required review before sharing  <Radio toggle/></T2>
            <Text>This option will allow you to review the report prior to it being released to the members you have selected to share the report with.</Text>
           
            </Option>

            <Option>
            <T2>Allow PDF Download  <Radio toggle/></T2>
            <Text>This option allows anyone with access to download a PDF copy of the report</Text>
           
            </Option>
           

            <T2>Share options</T2>
            <Text>This option will allow the report to be shared with the members you have selected to share the report with.</Text>

            <Row>
            <Input placeholder='Add member...' style={{width:300,marginRight:20}}/>
            <Button>Add</Button>
            </Row>
           

            <T3>General Access</T3>
            <Dropdown
                inline
                options={friendOptions}
                defaultValue={friendOptions[0].value}
                style={{zIndex:10}}
                />

            </Card>

            <ButtonContainer>
                <Button2 onClick={()=>navigate('/leader/prototype/accountability/success')}>Generate Report</Button2>
            </ButtonContainer>
        </Container>
    )

}

export default Permissions


const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    font-family:'Raleway';
`
const T2 = styled.div`
    display:flex;
    width:100%;
    align-items:center;
    justify-content:space-between;
    font-size:16px;
    font-weight:bold;
    margin-bottom:5px;
`

const Text = styled.div`
    max-width:80%;
    line-height:1.5;
`

const Option = styled.div`
    margin-bottom:20px;
`


const T1 = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:5px;
    font-family:'Raleway';
    color:#2A3039;
    line-height:1.5;
    margin-top:20px;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    letter-spacing:-0.35px;
`

const Description = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 0.5s;
`

const Card = styled.div`
    width:100%;
    padding:20px;
    background-color:#EBF1FD;
    border-radius:10px;

    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;
`

const T3 = styled.div`
    font-size:14px;
    font-weight:bold;

    margin-top:20px;
`
const Button = styled.div`
    background-color:#2A3039;
    color:#fff;
    padding:10px;
    border-radius:5px;
    width:100px;
    text-align:center;
    cursor:pointer;
    transition:0.3s;
    &:hover{
        background-color:#1A2029;
    }

`

const Row = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    margin-top:20px;
`


const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;
    z-index:-1;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`

const Button2 = styled.div`
    color:#2D70E2;
    border:1px solid #2D70E2;
    font-size:16px;
    padding:10px 20px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`

const friendOptions = [
    {
      key: 'Anyone with access to this department',
      text: 'Anyone with access to this department',
      value: 'Anyone with access to this department',
     
    },
    {
      key: 'Only members who have been added',
      text: 'Only members who have been added',
      value: 'Only members who have been added',
      
    }
  ]