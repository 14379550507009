import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'


const Component1 = () =>{

    return (
        <Container>

            <T1>How data journeys work.</T1>
            <Text delay={0.3}>
                The journey to change starts with a single step. We'll guide you through the process of understanding your data, and how to use it to make better decisions.
                We'll send you a series of emails, each with a new insight, activity, or check-in to help you along the way. All of this takes no longer than 10 minutes a week.
            </Text>

            <Img 
            delay={0.7}
            src={"http://res.cloudinary.com/dljz0lko8/image/upload/fl_sanitize/v1605798323/svg/Group_427_qhrocn.svg"} />

           
        </Container>
    )
}

export default Component1

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;
    line-height:1.5;

    animation: ${fadeIn} 1s ease-out forwards;
`



const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const T2 = styled.div`
    font-size:12px;
    color:#2A3039;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;

`

const Img = styled.img`
  width:100%;
  opacity:0;
  animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
  margin-top:40px;
`