import React, { useState, useEffect } from 'react';
import ApexCharts from 'react-apexcharts';
import styled from 'styled-components';



// Styled Components for the Heatmap Container
const HeatmapContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Raleway', sans-serif;
    padding: 0px 20px;
`;

const HeatmapTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: 'Raleway', sans-serif;
`;

const Heatmap = () => {
  const [sortedHeatmapData, setSortedHeatmapData] = useState([]);

  // EQ Factors and Culture Factors
  const eqFactors = [
    "Experiential EQ", "Strategic EQ", "Self Awareness", "Self Management", "Empathy",
    "Social Awareness", "Relationship Management", "Self-Regard", "Self-Actualization", 
    "Reality Testing", "Impulse Control", "Optimism", "Social Responsibility", 
    "Emotional Expression", "Independence", "Emotional Self-Awareness", "Assertiveness", 
    "Emotional Empathy", "Cognitive Empathy", "Interpersonal Relationships", 
    "Stress Tolerance", "Flexibility"
  ];

  const cultureFactors = [
    "Psychological Safety", "Empathy", "Belonging", "Learning", "Values", "Communication", 
    "Clarity", "Resilience", "Accountability", "Impact", "Vision", "Adaptability"
  ];

  // Dummy Data for Heatmap (Replace this with actual correlation data)
  const generateRandomData = () => {
    return eqFactors.map(eq => ({
      name: eq,
      data: cultureFactors.map(() => Math.random() * 100) // Random correlation data
    }));
  };

  // Helper function to calculate the average for sorting
  const calculateAverages = (heatmapData) => {
    return heatmapData.map(item => {
      const avg = item.data.reduce((a, b) => a + b, 0) / item.data.length;
      return { ...item, avg };
    });
  };

  // Sort rows and columns based on average correlations
  const sortHeatmap = (heatmapData) => {
    let sortedData = calculateAverages(heatmapData);

    // Sort by row averages (EQ factors)
    sortedData.sort((a, b) => b.avg - a.avg);

    // Transpose and sort by column averages (Culture factors)
    let transposedData = cultureFactors.map((_, i) => ({
      name: cultureFactors[i],
      data: sortedData.map(item => item.data[i])
    }));

    // Calculate average for columns and sort by average
    let sortedColumns = calculateAverages(transposedData);
    sortedColumns.sort((a, b) => b.avg - a.avg);

    // Rebuild the sorted heatmap data structure
    const sortedHeatmapData = sortedData.map((row, rowIndex) => ({
      ...row,
      data: sortedColumns.map(col => col.data[rowIndex])
    }));

    return { sortedRows: sortedHeatmapData, sortedCols: sortedColumns.map(c => c.name) };
  };

  // Generate and sort heatmap data
  useEffect(() => {
    const heatmapData = generateRandomData();
    const { sortedRows, sortedCols } = sortHeatmap(heatmapData);
    setSortedHeatmapData({ rows: sortedRows, cols: sortedCols });
  }, []);

  // Heatmap Configuration
  const chartOptions = {
    chart: {
      type: 'heatmap',
      toolbar: { show: false },
      fontFamily: 'Raleway, sans-serif'
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.7,
        colorScale: {
          min: 0,
          max: 100,
          gradientToColors: undefined, // Ensure it's a single gradient
          ranges: [
            { from: 0, to: 21, color: '#f7fbff' },  // Lightest blue
            { from: 21, to: 41, color: '#deebf7' }, 
            { from: 41, to: 61, color: '#9ecae1' }, 
            { from: 61, to: 81, color: '#3182bd' }, 
            { from: 81, to: 100, color: '#08306b' }  // Darkest blue
          ]
        },
        distributed: false,
      }
    },
    dataLabels: { enabled: false },
    xaxis: { 
      categories: sortedHeatmapData.cols || cultureFactors,
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '16px',  // Increase the font size
          fontFamily: 'Raleway, sans-serif',
          padding: 10  // Add padding between rows and labels
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return Math.round(value);  // Round to 0 decimal places
        }
      }
    },
    grid: {
      padding: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 20  // Add padding on the left to give more space for Y-axis titles
      }
    },
    title: {
      show: false,  // Remove the title
    },
    legend: {
      show: false  // Remove the legend
    }
  };

  return (
    <HeatmapContainer>
      <Title>Correlation Heatmap</Title>
      <BigNumber>Correlations between EQ and Culture</BigNumber>
        <Description>
            This heatmap shows the correlation between Emotional Intelligence (EQ) factors and Culture factors. 
            The values range from 0 to 100, where 0 indicates no correlation and 100 indicates a strong correlation.
        </Description>
      {sortedHeatmapData.rows && (
        <ApexCharts 
          options={chartOptions}
          series={sortedHeatmapData.rows}
          type="heatmap"
          height={800} // Further increase height for higher row heights
        />
      )}
    </HeatmapContainer>
  );
};

export default Heatmap;



const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
  max-width:800px;
`;


const BigNumber = styled.div`
    font-size: 28px;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 10px;
`

const Text = styled.div`
  max-width:800px;
`
const Title = styled.div`
    color: #2d70e2;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;

`