// Install styled-components if you haven't already
// npm install styled-components
import React from 'react';
import styled, { keyframes } from 'styled-components';


const TimelineComponent = () => {


    return (
        <Container>
            <Title>
                Productivity trend timeline
            </Title>
            <Description>
                The key trends identified in your data at each survey collection time point.
            </Description>
             <Timeline>
            {timelineItems.map((item, index) => (
                <TimelineItem key={index} last={index+1==timelineItems.length}>
                <TimelineDot />
                <TimelineContent>
                    <T1>{item.title}</T1>
                    <p>{item.time}</p>
                    <p>{item.details}</p>
                </TimelineContent>
                </TimelineItem>
            ))}
            </Timeline>
        </Container>
       
    )

}


export default TimelineComponent;


// Sample data
const timelineItems = [
    {
        title: 'Positive Work Environment and Supportive Management',
        time: 'Q3 2024',
        details: "Employees express that when managers are supportive, transparent, and show genuine concern for their teams' well-being, it results in higher morale and a positive work environment. This includes flexible working conditions and open communication channels."
      },
    {
      title: 'Perceived Lack of Recognition and Growth Opportunities',
      time: 'Q1 2024',
      details: 'Comments reveal that many employees feel overwhelmed by heavy workloads and tight deadlines. This is exacerbated by a lack of clear guidance or support from management, leading to burnout and diminished job satisfaction.'
    }
   
  ];

const Container = styled.div`
    font-family:"Raleway";
    width:100%;
    margin-top:40px;
    `

    const T1 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
    `

    const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    line-height:1.2;
    margin-bottom:10px;
    `

    const Description = styled.div`
    font-size:14px;
    margin-bottom:40px;
    `

  // Styled components for the timeline
const Timeline = styled.div`
position: relative;
max-width: 600px;
`;

const TimelineItem = styled.div`
padding: 10px 20px;
position: relative;
background-color: inherit;
border-left: ${props=>props.last?'none':'2px solid #e9ecef'};
margin-bottom: 10px;
`;

const TimelineDot = styled.div`
position: absolute;
width: 10px;
height: 10px;
background-color: #007bff;
border: 2px solid #007bff;
border-radius: 50%;
top: -5px;
left: -5px;
`;

const TimelineContent = styled.div`
padding: 20px;
margin-top:-40px;


`;