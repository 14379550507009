import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Accordion, Icon,Button,Loader } from 'semantic-ui-react'
import make_call from './Request'
import Edit from './Edit'
import ScoreModal from './ScoreModal' 
import Start from './Start'

const Recommendation = () => {
    const [activeIndex,setActiveIndex] = React.useState(0)
    const [recommendation,setRecommendation] = React.useState('')
    const [original,setOriginal] = React.useState('')
    const [step,setStep] = React.useState(0)
    const [scores, setScores] = useState({
        accuracy: 1,
        relevance: 1,
        quality: 1,
        depth: 1,
        feedback:''
      });



    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
    
       setActiveIndex(newIndex)  
      }

    const generate_recommendation = async () =>{

        const prompt = `
        Write me a recommendation to improve the psychological safety for my team. 
        I need to reduce the amount of turn over I am experiencing.

        Use the following template when wiriting your recommendation:

        Overall summary of the recommendation, 3-4 sentences. 
        2-3 actions to take, each that build off the last action.
        For each recommended action, there should be two or three very tangigle and actionable steps which are 2-3 sentences each.
        The action steps should each be very actionable by the person receiving this plan. 

        Recommendation:
        `;
           
            async function someFunction(prompt) {
            const message = await make_call(prompt);
            setRecommendation(message);
            setOriginal(message)
            }

        someFunction(prompt)
    }

    const submit_score = () =>{
        // This is what gets submitted and stored in the database
        const data = {
            "scores":scores,
            "original":original,
            "edited":recommendation,
            "prompt":"" // This is the prompt used to build the original recommendation
        }
    }

    const evaluate = () =>{
        generate_recommendation()
        setStep(1)
    }

    const restart = () =>{
        setStep(0)
        setRecommendation('')
        setOriginal('')
    }

    if(step==0){
        return <Start evaluate={evaluate}/>
    }

    if(step==1 && !recommendation){
        return <Loader active inline='centered' />
    }
    
    if(step==1 && recommendation){
        return <ScoreModal 
                recommendation={recommendation} 
                setStep={()=>setStep(step+1)}
                scores={scores}
                setScores={setScores}/>
    }



    return (
        <Container>
            <Left>
                <Title>Improve the following recommendation</Title>
                <Edit  
                jsonString ={recommendation}/>
                <Button onClick={()=>restart()}>Save Edits</Button>
            </Left>

            <Right>
                <Title>Recommendation Prompts</Title>

                <Accordion>
                        <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Needs Analysis
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                        <P>
                            
                        </P>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Research Content
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 1}>
                        <P>
                           
                        </P>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Template
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 2}>
                        <P>
                           
                        </P>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 3}
                        index={3}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Context
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 3}>
                        <P>
                           
                        </P>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 4}
                        index={4}
                        onClick={handleClick}
                        >
                             <Icon name='dropdown' />
                        Prompt
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 4}>
                        <P>
                           
                        </P>
                        </Accordion.Content>

                    </Accordion>

                    
            </Right>
           
        </Container>
    )

}

export default Recommendation;


const Container = styled.div`
    display:flex;
`

const Left = styled.div`
    width: 50%;
    padding:20px;
`

const Right = styled.div`
    width:50%;
    padding-top:20px;
`

const TextArea = styled.textarea`
    height:700px;
    width:100%;
    padding:10px;
    font-family:'Raleway';
    line-height:1.3;
`

const Title = styled.div`
    margin-bottom:20px;
    font-size:20px;
    font-weight:600;
    color:#2A3039;
    font-family:'Raleway';
`

const P = styled.div`

`