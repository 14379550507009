import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import { useNavigate } from 'react-router-dom';
import {Icon} from 'semantic-ui-react'


const BackButton = ({url,location}) =>{
    const navigate = useNavigate();

    return <Back onClick={()=>navigate(url)}> <Icon name='arrow left'/> Back to {location}</Back>
}

export default BackButton


const Back = styled.div`
    font-size:14px;
    font-family:'Raleway';
    margin-bottom:20px;
    cursor:pointer;
`
