import { useDispatch, useSelector, shallowEqual } from "react-redux";

export function useLanguageSetting(){
    const { get_employee, get_employee_permission } = useSelector(
        (state) => ({
          get_employee: state.employees,
          get_employee_permission: state.employee_permission.employee_permission,
        }),
        shallowEqual
      );

    return get_employee_permission?.[0]?.extra_details?.language == "French" ? 'fr': 'en'
}