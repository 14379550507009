import React, { useState } from "react";
import {
    Table,
    Button,
    Portal,
    Segment,
    Header,
    Modal,
    Image,
    Icon,
} from "semantic-ui-react";
import styled from "styled-components";
// import NewSubFactor from './NewSubFactor'
import NewSubFactorPortal from "./NewSubFactorPortal";

const ListJson = ({
    surveyStrucRow,
    setRowId,
    setOpenNewCategory,
    setSurveyStruc,
    setSurveyStrucRow,
}) => {
    const [openSubFactor, setOpenSubFactor] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);

    function SelectRow(item) {
        setRowId(item?.id);
        setOpenNewCategory(true);
        setSurveyStruc(item);
    }

    function SelectSubFactorRow(item) {
        // console.log(item.id)
        setRowId(item?.id);

        setSurveyStruc(item);
        setOpenSubFactor(true);
        setSelectedItem(item);
    }

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Priority</Table.HeaderCell>
                        <Table.HeaderCell>Subtitle</Table.HeaderCell>
                       
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {surveyStrucRow && surveyStrucRow.map((item, i) =>
                        <Table.Row key={i}>
                            <Table.Cell>{item?.name}</Table.Cell>
                            <Table.Cell>{item?.priority}</Table.Cell>
                            <Table.Cell>{item?.subtitle}</Table.Cell>
                            
                            <Table.Cell>
                                {/* */}

                                    <StyledButton
                                        disabled={openSubFactor}
                                        onClick={() => SelectSubFactorRow(item)}
                                    >
                                        + Sub Factor
                                    </StyledButton>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        content="Edit"
                                        onClick={() => {
                                            SelectRow(item);
                                        }}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )}
                </Table.Body>
            </Table>

            <Modal
                open={openSubFactor}
                onClose={() => setOpenSubFactor(false)}
                onOpen={() => setOpenSubFactor(true)}
                // trigger={<Button>Scrolling Content Modal</Button>}
            >
                <Modal.Header>Sub Factors</Modal.Header>
                <Modal.Content scrolling>
                    <NewSubFactorPortal
                        surveyStruc={selectedItem}
                        setSurveyStruc={setSurveyStruc}
                        // redundant
                        setOpenSubFactor={setOpenSubFactor}
                        surveyStrucRow={surveyStrucRow}
                        setSurveyStrucRow={setSurveyStrucRow}
                        openSubFactor={openSubFactor}
                    />

                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenSubFactor(false)} primary>
                        Close <Icon name="close" />
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ListJson;

const ColorBlock = styled.div`
    width: 30px;
    height: 30px;
    background-color: ${(props) => props.color};
    cursor: pointer;
`;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: ${(props) => (props.disabled ? "#dcdcdc" : "#2d50e2")};
    border: none;
    outline: none;
    padding: 0px 30px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
        margin-right: 3px;
    }
`;
