import styled, { keyframes } from "styled-components";
import React from "react";
import { Progress } from 'semantic-ui-react'

const Comment = ({text,score,q}) =>{

	return (
		<Container collapse={q==2}>
			<Avatar src={"https://stg-app.innerlogic.com/static/media/user.1416ec52.svg"} />
			<Data>
				<Name>Michael Bawol</Name>
				{q==2?	
					<Progress percent={score} color='violet' progress size='small' style={{marginBottom:0}}/>:
					<Text>{text}</Text>
					}
			</Data>
		</Container>
		)
}

export default Comment

const Container = styled.div`
	padding:${props=>props.collapse?'15px 30px':'30px'};
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display:flex;
	margin-top:${props=>props.collapse?'5px':'20px'};
	transition:all 1s;
`

const Avatar = styled.img`
	width:50px;
	height:50px;
	border-radius:25px;
	margin-right:10px;
`

const Data = styled.div`
	width:100%;
`

const Name = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:10px;
`

const Text= styled.div`
	
`