import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Label } from "semantic-ui-react";

const Tabs = ({ onSelect, selected, options, totalComments,padding=true }) => {
  return (
    <Container padding={padding}>
      {options.map((item) => {
        return (
          <Tab selected={selected === item} onClick={() => onSelect(item)}>
            <TabText>{item}</TabText>

            {item === "Activity" && totalComments ? (
              <Label style={{ marginLeft: 5 }} size="tiny">
                {totalComments}
              </Label>
            ) : (
              ""
            )}
          </Tab>
        );
      })}
    </Container>
  );
};

export default Tabs;

const Container = styled.div`
  widht: 100%;
  display: flex;
  margin-bottom: 1px;
  padding-left:${props=>props.padding?'58px':'0px'};
`;

const Tab = styled.div`
  margin-right: 50px;
  border-bottom: ${(props) => (props.selected ? "2px solid blue" : "")};
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  flex-direction: row;
  color: #9e9fa1;
`;

const TabText = styled.div`
  margin-bottom: 5px;
`;
