import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Input,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import promptsData from "./prompts.json";
import starterQuestions from "./starterQuestions.json";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import ThematicSample from "./thematicSample";

const PromptLibrary = ({ onSelect, setCategoryMeta,goBack }) => {
  const [open, setOpen] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [starterQues, setStarterQues] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [finalSelectedPrompt, setFinalSelectedPrompt] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryDropdown, setCategoryDropdown] = useState([]);

  const { core_data } = useSelector(
    (state) => ({
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    setPrompts(promptsData);
    setFilteredPrompts(promptsData);
    setStarterQues(starterQuestions);
    setSelectedCategory(null);
    setCategoryMeta(null);
  }, []);

  useEffect(() => {
    if (core_data) {
      setCategoryDropdown(createCategoryDropdown(core_data));
    }
  }, [core_data]);

  const createCategoryDropdown = (core) => {
    const categories =
      core?.categories?.length > 0 && core?.categories?.[0]?.categories;
    // Loop through categories and create an array with the object structure:
    //  { id: category.id, name: category.name, value: category.name }

    if (!categories) return [];
    const categoryDropdown = categories?.map((category) => {
      return {
        id: category.id,
        text: category.name,
        value: category.id,
      };
    });
    return categoryDropdown;
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredPrompts(
      prompts.filter(
        (prompt) =>
          prompt.title.toLowerCase().includes(term) ||
          prompt.description.toLowerCase().includes(term) ||
          prompt.tags.some((tag) => tag.toLowerCase().includes(term))
      )
    );
  };

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt);
    setSelectedCategory(null); // Reset category selection when a new prompt is selected
    setCategoryMeta(null);
  };

  const confirmPromptSelect = () => {
    onSelect(selectedPrompt);
    setFinalSelectedPrompt(selectedPrompt);
    setOpen(false);
  };

  const handleCategoryChange = (e, { value }) => {
    setSelectedCategory(value);
    setCategoryMeta(value);
  };

  const handleRemovePrompt = () => {
    onSelect(null);
    setFinalSelectedPrompt(null);
  };

  return (
    <Container selected={finalSelectedPrompt}>
      <Back onClick={() => goBack()}><Icon name="chevron left"  /> Back</Back>
      <Title>Prompt Library</Title>
     
         
          <ContentWrapper>
            <SelectionPane>
              
              <SearchBar
                placeholder="Search prompts..."
                value={searchTerm}
                onChange={handleSearch}
                fluid
              />
              {/* Prompts with Filters */}
              <SectionTitle>Comprehensive analysis</SectionTitle>
              {filteredPrompts.map((prompt) => (
                <PromptItem
                  key={prompt.id}
                  onClick={() => handlePromptSelect(prompt)}
                  active={selectedPrompt && selectedPrompt.id === prompt.id}
                >
                  {prompt.title}
                </PromptItem>
              ))}

              {/* Starter Questions */}
              <SectionTitle>Starter Questions</SectionTitle>
              {starterQues.map((ques, index) => (
                <PromptItem
                  key={index}
                  onClick={() => handlePromptSelect(ques)}
                  active={selectedPrompt && selectedPrompt.title === ques.title}
                >
                  {ques.title}
                </PromptItem>
              ))}
            </SelectionPane>

            <DetailsPane>
              {selectedPrompt ? (
                <>
               
                  <PromptTitle>
                    {selectedPrompt.title}
                    <SemanticButton
                      primary
                      disabled={
                        !selectedPrompt || (selectedPrompt.category && !selectedCategory)
                      }
                      onClick={confirmPromptSelect}
                    >
                      Ask Prompt
                    </SemanticButton>
                  </PromptTitle>

                  <BlueTitle>Overview</BlueTitle>
                  <PromptDescription>
                    {selectedPrompt.description || selectedPrompt.ques}
                  </PromptDescription>

                  {selectedPrompt?.example?
                  <>
                  <BlueTitle>Example</BlueTitle>
                  <PromptDescription>
                    {selectedPrompt.example}
                  </PromptDescription>
                  </>
                  :''}

                 
                  {/* Display category dropdown if the prompt has a category flag */}
                  {selectedPrompt.category && (
                    <>
                      <CategoryLabel>Select a category</CategoryLabel>
                      <Dropdown
                        placeholder="Select Category"
                        fluid
                        selection
                        options={categoryDropdown}
                        onChange={handleCategoryChange}
                        value={selectedCategory}
                      />
                    </>
                  )}
                </>
              ) : (
                <Placeholder>
                  Select a prompt or question to see details
                </Placeholder>
              )}
            </DetailsPane>
          </ContentWrapper>
          
         
      
    </Container>
  );
};

export default PromptLibrary;

// Styled Components
const Container = styled.div`
  font-family: "Raleway", sans-serif;
  width:100%;
`;

const Back = styled.div`
  cursor: pointer;
  color: #666D79;
  font-size: 14px;
  margin-bottom: 20px;
   margin-left:20px;

`

const Title = styled.div`
  font-size: 24px;
  color:#2A3039;
  font-weight: 600;
  margin-left:20px;
  margin-bottom: 40px;
`

const SearchBar = styled(Input)`
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  max-height: 90%;
`;

const SelectionPane = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  padding:10px;
  overflow-y: auto;
`;

const BlueTitle = styled.div`
  color:#2D70E2;
  font-size: 16px;
  font-weight: 600;
  margin-bottom:10px;
`

const SectionTitle = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 11px;
  color: #2A3039;
  padding-left: 0px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;

`;

const PromptItem = styled.div`
  padding: 8px;
  cursor: pointer;
  color:${({ active }) => (active ? "#2D70E2" : "#2A3039")};
  font-size: 12px;
  background-color: ${({ active }) => (active ? "#F8FAFF" : "white")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};

  font-family: "Raleway", sans-serif;
  &:hover {
    background-color: #f0f8ff;
  }
`;

const DetailsPane = styled.div`
  width: 70%;
  padding: 20px;
  overflow-y: auto;
`;

const PromptTitle = styled.div`
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 30px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color:#2A3039;
  width:100%;
  display:flex;
  justify-content:space-between;
`;

const PromptDescription = styled.div`
  font-size: 14px;
  line-height: 1.6;
  font-family: "Raleway", sans-serif;
  color:#2A3039;
  margin-bottom: 30px;
`;

const Tags = styled.div`
  margin-top: 20px;
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #e0e1e2;
  color: #555;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  font-size: 12px;
`;

const Placeholder = styled.div`
  color: #999;
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
`;

const SelectedPromptCard = styled.div`
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-left: 5px solid #2185d0;
  background-color: #fafafa;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  position: relative;
`;

const PromptInfo = styled.div`
  flex: 1;
`;

const RemoveButton = styled.div`
  cursor: pointer;
  color: #999;
  &:hover {
    color: #333;
  }
`;

const CategoryLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;

const CategoryDisplay = styled.div`
  margin-top: 10px;
  font-weight: bold;
  color: #2185d0;
`;

const SemanticButton = styled.button`
  background-color: #2D70E2;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #1a71b8;
  }
`