import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import Footer from '../components/Footer'
import Side from '../components/Side'
import Chart from '../components/Chart'

// Create styles
const styles = StyleSheet.create({
	title:{
		fontSize:16,
		fontWeight:700,
		paddingBottom:5,
		borderBottom:'0.5px solid black',
		width:'100%',
		color:'#3076B8',
		marginBottom:15
	},
	page:{
		padding:60,
		paddingHorizontal:80
	},
	p:{
		fontSize:11,
		lineHeight:1.7,
		marginBottom:10
	},
	T2:{
		fontSize:20,
		fontWeight:700
	},
	line:{
		width:75,
		height:4,
		backgroundColor:'#3076B8',
		marginTop:5
	},
	description:{
		lineHeight:1.7,
		fontSize:11,
		marginTop:15
	},
	section:{
		paddingTop:10,
		paddingBottom:10,
		paddingLeft:20,
		paddingRight:20,
		backgroundColor:'#F1F1F7',
		display:'flex',
		flexDirection:'row',
		justifyContent:'space-between',
		alignItems:'center',
		width:'100%',
		marginTop:20,
		marginBottom:20
	},
	subSection:{
		display:'flex',
		flexDirection:'row',
		fontWeight:700,
		fontSize:16,
		height:'100%',
		alignItems:'center',
	},
	subTitle:{
		fontWeight:700,
		fontSize:16
	},
	S1:{
		fontSize:10,
		marginRight:15
	},
	img:{
		height:20
	},
	offset:{
		width:'100%'
	},
	summary:{
		border:'2px solid #2C6BAA',
		borderRadius:5,
		marginTop:20,
		marginBottom:20
	},
	top:{
		backgroundColor:'#2C6BAA',
		color:'white',
		fontSize:16,
		fontWeight:700,
		padding:5
	},
	bottom:{
		padding:10
	}
})


const Diversity = (props) =>{


	return (
		<Page style={styles.page}>
			<Footer/>
			<Side/>

			<Text style={styles.T2}>Demographic Analysis</Text>
            <View style={styles.line}/>
            <Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs. 
            Chicken jerky short ribs, pastrami pork fatback ground round spare ribs cow t-bone filet mignon hamburger 
            leberkas shank. Meatball burgdoggen pork ribeye.</Text>

			{props.categories.map((item,i)=>{
	           	return (
	           		<View wrap={false}>
			            <View style={styles.section}>
			            	<View>
			            		<Text style={styles.subTitle}>{item.name}</Text>
			            	</View>
			            	<View style={styles.subSection}>
			            	</View>
			            </View>

			            <View style={styles.offset}>
			            	<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
								information appears on all nuLogic reports and is not specific to this organization's individual results</Text>
						<Chart data={item.options}/>
			            </View>	
		         </View>
	           		)
	           })}


		</Page>
		)
}



export default Diversity