import React from "react";
import styled from "styled-components";
import { infoIcon, errorIcon } from "./Icons";

// Define the Toast component
const Toast = ({ level = "info", message, actionComponent, onClose }) => {
  return (
    <ToastContainer level={level}>
      <Message>{message}</Message>
      {actionComponent ? (
        <ActionContainer>{actionComponent}</ActionContainer>
      ) : (
        <CloseButton onClick={onClose}>X</CloseButton>
      )}
    </ToastContainer>
  );
};

const Indicator = ({ level }) => {
  return <IndicatorBox level={level}>{getIcon(level)}</IndicatorBox>;
};

// Toast container that takes up 100% of its container width
const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ level }) => getIndicatorColor(level)};
  box-shadow: 0px 8px 20px 0px ${({ level }) => getBackgroundColor(level)};
  justify-content: space-between; /* Ensures space between the message and the close/action button */
  color: #2a3039;
  position: relative;
`;

// Indicator box on the left
const IndicatorBox = styled.div`
  height: 34px;
  width: 34px;
  border-radius: 4px;
  background-color: ${({ level }) => getBackgroundColor(level)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px; /* Adds space between the indicator and the message */
`;

// The message part
const Message = styled.div`
  flex-grow: 1; /* Allows the message to take up remaining space */
  font-size: 12px;
  line-height: 1.4;
  text-align: left;
`;

// Close button on the right
const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  margin-left: 12px;
`;

// Action container for optional action component (e.g., retry button)
const ActionContainer = styled.div`
  margin-left: 12px;
`;

// Utility function to determine which icon to show based on level
const getIcon = (level) => {
  switch (level) {
    case "success":
      return;
    case "info":
      return infoIcon(getBackgroundColor(level));
    case "alert":
      return infoIcon(getBackgroundColor(level));
    case "error":
      return errorIcon();
    default:
      return errorIcon();
  }
};

// Utility function to determine background color based on level
const getBackgroundColor = (level) => {
  switch (level) {
    case "success":
      return "#EAFCF7";
    case "info":
      return "#EBF1FD";
    case "alert":
      return "#FFFAF1";
    case "error":
      return "#FEF3F4";
    default:
      return "#EBF1FD";
  }
};

// Utility function to determine indicator color based on level
const getIndicatorColor = (level) => {
  switch (level) {
    case "success":
      return "#D4F5ED";
    case "info":
      return "#D4E2F9";
    case "alert":
      return "#FFEDCF";
    case "error":
      return "#FCDCE0";
    default:
      return "#D4E2F9";
  }
};

export default Toast;
