import styled, { keyframes } from "styled-components";
import React from "react";
import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/Resilience.svg";
import Impact from "assets/images/Impact.svg";
import { Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";
import CultureDials from './MetricDial'

const Team = () =>{

	const [selected,setSelected] = React.useState(1)

	return (
		<Container>
			<Title>Team Health Metrics</Title>
			<DialContainer>
			<CultureDials title="Trust" selected={selected==1} setSelected={()=>setSelected(1)}/>
			<CultureDials title="Clarity" selected={selected==2} setSelected={()=>setSelected(2)}/>
			<CultureDials title="Communication" selected={selected==3} setSelected={()=>setSelected(3)}/>
			<CultureDials title="Alignment" selected={selected==4} setSelected={()=>setSelected(4)}/>
			<CultureDials title="Resilience" selected={selected==5} setSelected={()=>setSelected(5)}/>
			<CultureDials title="Impact" selected={selected==6} setSelected={()=>setSelected(6)}/>

			</DialContainer>
			{/*<Metrics>
					<Title>Team Health Factors</Title>
					<User selected>
						<Avatar src={Trust}/>
						<div style={{width:'100%'}}>
						<Name>Trust</Name>
						<Progress percent={76} progress size='small' style={{marginBottom:5,marginTop:0}} color='purple' inverted/>
						</div>
					</User>

					<User>
						<Avatar src={Clarity}/>
						<div style={{width:'100%'}}>
						<Name>Clarity</Name>
						<Progress percent={56} progress size='small' style={{marginBottom:5,marginTop:0}} color='purple' inverted/>
						</div>
					</User>

					<User>
						<Avatar src={Communication}/>
						<div style={{width:'100%'}}>
						<Name>Communication</Name>
						<Progress percent={46} progress size='small' style={{marginBottom:5,marginTop:0}} color='purple' inverted/>
						</div>
					</User>

					<User>
						<Avatar src={Alignment}/>
						<div style={{width:'100%'}}>
						<Name>Alignment</Name>
						<Progress percent={26} progress size='small' style={{marginBottom:5,marginTop:0}} color='purple' inverted/>
						</div>
					</User>

					<User>
						<Avatar src={Resilience}/>
						<div style={{width:'100%'}}>
						<Name>Resilience</Name>
						<Progress percent={16} progress size='small' style={{marginBottom:5,marginTop:0}} color='purple' inverted/>
						</div>
					</User>
					<User>
						<Avatar src={Impact}/>
						<div style={{width:'100%'}}>
						<Name>Impact</Name>
						<Progress percent={16} progress size='small' style={{marginBottom:5,marginTop:0}} color='purple' inverted/>
						</div>
					</User>
				</Metrics>*/}
			

			<Title>Trust Analytics</Title>
				<Comment>
					<SubTitle>Trust Comment</SubTitle>
						<View>View all trust comments</View>
					<Description>Tongue jowl spare ribs landjaeger picanha salami. Leberkas cupim sausage andouille, pork belly strip steak shankle kevin buffalo chuck.</Description>
				</Comment>
				<Metrics>
					<SubTitle>Trust Trends</SubTitle>
				
					<H2>This week</H2>
					<Progress  percent={85} progress size='small' style={{marginBottom:15,marginTop:2,marginBottom:0}} color='purple'/>
					<H2>Last week</H2>
					<Progress  percent={75} progress size='small' style={{marginBottom:15,marginTop:2,marginBottom:0}} color='purple'/>
					<H2>Average</H2>
					<Progress  percent={78} progress size='small' style={{marginBottom:15,marginTop:5}} color='purple'/>
					<div style={{marginTop:30}}>
					
					<Chart options={data.options} series={data.series} type="bar" height={250}/>
					</div>
				</Metrics>

				
				

		</Container>
		)
}


export default Team

const DialContainer = styled.div`
	display:flex;
	width:100%;
	justify-content:space-between;
`

const Container = styled.div`
	width:100%;
 	padding:30px 20px;
 	margin-top:20px;
 	position:relative;
 	margin-bottom:50px;
`

const IMG = styled.img`
	width:400px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:30px;
	color:#4a4a4a;
`
const SubTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
`

const Description = styled.div`
	font-family: 'Quicksand', sans-serif;
	font-size:20px;
	line-height:1.3;
	margin-bottom:20px;
	margin-top:10px;
	font-weight:500;
`

const Metrics = styled.div`
	width:100%;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	background-color:white;
	padding:20px;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	margin-right:10px;
`


const User = styled.div`
	display:flex;
	width:100%;
	margin-top:2px;
	box-shadow: ${props=>props.selected?'0 2px 40px 0 rgba(220, 220, 220, 0.7)':'none'};
	border-radius:8px;
	padding:4px 10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`

const DataContainer = styled.div`
	display:flex;
	width:100%;
	flex-direction:row;
	align-items:center;
	margin-bottom:30px;
`

const Dates = styled.div`
	display:flex;
	position:absolute;
	top:20px;
	right:40px;

`

const Comment = styled.div`
  width:100%;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  padding:30px 20px;
  margin-top:20px;
  margin-bottom:20px;
`

const Time = styled.div`
	color:${props=>props.selected?'white':'#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props=>props.first?'1px solid #6a00ff':'none'};
	background-color:${props=>props.selected?'#6a00ff':'none'};
	cursor:pointer;
`


const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
	margin-top:10px;
`
const Score = styled.div`

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:18px;
  color:#6a00ff;
  font-weight:bold;
`
const Top = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
`
const Impacted = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`

const Theme = styled.div`
		margin-top:20px;
	border-bottom:1px solid #e9e9e9;
`


const TopicTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	margin-bottom:10px;
`

const View = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
`


const data = {
          
            series: [{
              name: 'Trust',
              data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
                5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
                48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
              ]
            }],
            options: {
              chart: {
                type: 'bar',
                width: 350,
                sparkline: {
	            enabled: true
	          },
                toolbar:{show:false}
              },
              grid:{
              	padding:{
              		right:170
              	}
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [{
                      from: -1,
                      to: 100,
                      color: '#6a00ff'
                    },{
                      from: -100,
                      to: -1,
                      color: '#ff2d55'
                    }]
                  },
                  columnWidth: '80%',
                }
              },
              annotations: {
			  yaxis: [
			    {
			      y: 0,
			      borderColor: '#e9e9e9',
			      offsetX:170,
			       strokeDashArray: 0,
			      label: {
			        borderColor: '#00E396',
			        borderWidth:0,
			         offsetX:170,
			        style: {
			          color: '#4a4a4a',
			          fontFamily: 'Red Hat Display, sans-serif',
			          fontSize:14
			        },
			        text: 'Average Trust 73%'
			      }
			    }
			  ]
			},
              dataLabels: {
                enabled: false,
              },
              yaxis: {
                title: {
                  text: 'Growth',
                },
                labels: {
                  formatter: function (y) {
                    return y.toFixed(0) + "%";
                  }
                }
              },
              xaxis: {
                type: 'datetime',
                categories: [
                  '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
                  '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
                  '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
                  '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
                  '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
                  '2013-07-01', '2013-08-01', '2013-09-01'
                ],
                labels: {
                  rotate: -90
                }
              }
            },
          
          
          };


const data5 ={
          
            series: [
              {
                data: [
                  {
                    x: 'Personal objectives',
                    y: [
                      new Date('2019-02-27').getTime(),
                      new Date('2019-03-04').getTime()
                    ],
                    fillColor: '#008FFB'
                  },
                  {
                    x: 'Work environment',
                    y: [
                      new Date('2019-03-04').getTime(),
                      new Date('2019-03-17').getTime()
                    ],
                    fillColor: '#00E396'
                  },
                  {
                    x: 'Personal life',
                    y: [
                      new Date('2019-03-07').getTime(),
                      new Date('2019-03-10').getTime()
                    ],
                    fillColor: '#775DD0'
                  },
                  {
                    x: 'Team satisfaction',
                    y: [
                      new Date('2019-03-08').getTime(),
                      new Date('2019-03-12').getTime()
                    ],
                    fillColor: '#FEB019'
                  },
                  {
                    x: 'Project deadline',
                    y: [
                      new Date('2019-03-12').getTime(),
                      new Date('2019-03-17').getTime()
                    ],
                    fillColor: '#FF4560'
                  }
                ]
              }
            ],
            options: {
              chart: {
                height: 350,
                type: 'rangeBar',
                toolbar:{
                	show:false
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  distributed: true,
                  dataLabels: {
                    hideOverflowingLabels: false
                  }
                }
              },
              dataLabels: {
                enabled: true,
                formatter: function(val, opts) {
                  var label = opts.w.globals.labels[opts.dataPointIndex]
                  return label 
                },
                style: {
                  colors: ['#f3f4f5', '#fff']
                }
              },
              xaxis: {
                type: 'datetime'
              },
              yaxis: {
                show: false
              },
              grid: {
                row: {
                  colors: ['#f3f4f5', '#fff'],
                  opacity: 1
                }
              }
            },
          
          
          };
