import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { build_default_State } from "./data_navigation/calculate_data";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
	SET_SELECTED_QUESTION,
	SET_SUB_NAV,
	SET_SELECTED_FILTERS,
	SET_NAVIGATION,
} from "constants/actions";
import { erf } from "mathjs";

const configure = (structure) => {
  let ids = 0;
  let names = [{ title: "Overall Culture", level: 0, id: ids }];
  ids += 1;
  if (structure?.dimensions) {
    structure?.dimensions.map((item, i) => {
      names.push({ title: item.title, level: 1, id: ids, dimension: i });
      ids += 1;
      if (item.factors.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
    });
  }

  return names;
};

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};


const LinkedRow = ({data,color,dimensions,nav,checked,standards}) =>{
  const dispatch = useDispatch();
  const [structure, setStructure] = useState(null);
  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);

  const questions = useSelector(
    (state) => state.audit?.question_structure,
    shallowEqual
  );

  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual
  );

  useEffect(() => {

    if(data?.ranking){
      setFilteredData1(
      data.ranking
        .sort((a, b) => b.response - a.response)
    );

    setFilteredData2(
      data.ranking
        .filter((f) => f.id === 1)
        .sort((a, b) => b.response - a.response)
    );
    }
		
	}, [data]);

  	useEffect(() => {
		if (questions) {
			if (Object.keys(questions).length > 0) {
				// setQ(questions);
				setStructure(configure(questions));
			}
		}
	}, [questions]);

const selected2 = useSelector((state) => state.audit?.filters, shallowEqual);

  const navigate = (select) => {

    if (data) {
      let selected = structure.find(
        (f) => f.dimension == select.id && f.factor == select.factor
      );

      dispatch({
        type: SET_SELECTED_QUESTION,
        payload: selected,
      });
      dispatch({
        type: SET_SUB_NAV,
        payload: 3,
      });

      if(data.navigation){
        onSelect({
        id: data.navigation.id,
        option: data.navigation.option,
        subfactor: data.navigation.subfactor,
        name: { name: data.title.name },
      });
      }
      
    }
    else{
      let selected = structure.find(
        (f) => f.dimension == select.id && f.factor == select.factor
      );

        dispatch({
        type: SET_SELECTED_QUESTION,
        payload: selected,
      });
      dispatch({
        type: SET_SUB_NAV,
        payload: 3,
      });
      dispatch({
      type: SET_SELECTED_FILTERS,
      payload: selected2,
    });
       set_nav([])
    }
   


  };

    const selected = useSelector((state) => state.audit?.filters, shallowEqual);

  

  const onSelect = (s) => {

    let check = selected.filter(
      (f) =>
        f.id === s.id &&
        f.subfactor === s.subfactor &&
        f.option === s.option
    );
    let _selected;


    if (check.length > 0) {
      _selected = selected;
    } else {
      _selected = [...selected, s];
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected);
  };

  const set_nav = (new_selected) => {
    let _selected = build_default_State(categories);
    // console.log(_selected, new_selected);
    new_selected.map((item, i) => {
      if (_selected[item.id].selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s != "selected" && s != "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }

      if (item.subfactor != null) {
        _selected[item.id][item.option].subfactors[
          item.subfactor
        ].selected = true;
        _selected[item.id][item.option].sub = true;
      } else {
        _selected[item.id][item.option].selected = true;
      }
      return null;
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
  };

  if(!data?.ranking){
    return <div/>
  }


  const getPercentile = (bench,val) =>{
    let zscore = (val - bench.average) / bench.std
    return (zptile(zscore)*100).toFixed(0)
  }

  const get_last = (item) =>{
    let last 
    if(!item.last && data.last_ranking){
      last = data.last_ranking.find(f=>f.id==item.id&&f.factor==item.factor)?.response
    }
    else{
      last = item.last
    }

    if(last){
      return '('+((item.response - last)>0?'+':"")+(item.response - last).toFixed(1)*10 +'%)'
    }

    return ""
    
  }

  const checkBenchmark = (benchmark) =>{
    let bench = ((0.842*benchmark.std + benchmark.average)*10).toFixed(0)

    if(bench>100){
      return 100
    }

    return bench
  }

  const getColor = (score) =>{
    if(score < -10){
      return '#FF6576'
    }
    if(score < 0){
      return '#FEA711'
    }
    if(score > 0){
      return 'green'
    }
  }




  return (
  	<Container>
    <Column>
        <LegendRow>
          <ColorSquare color={1}/>
          <LegendText>{questions.dimensions[0].title}</LegendText>

          <ColorSquare style={{marginLeft:20}} color={2}/>
          <LegendText>{questions.dimensions[1].title}</LegendText>
{/* 
          {standards?(<>
                <BenchMark/>
              <LegendText>Benchmark</LegendText>

              <BenchMark2/>
              <LegendText>Excellence</LegendText>
              </>)
              :""} */}
        </LegendRow>
        {questions?.dimensions.length > 0 && filteredData1.length > 0
          ?filteredData1.map((item, i) => {
            let benchmark = standards?.response.pillars.find(f=>f.id==item.id&&f.factor==item.factor)
            let response = item.response.toFixed(1)
            let bench_diff

            
            if(benchmark){
              bench_diff = (response - benchmark.average).toFixed(1) *10
            }

            // let response = checked? getPercentile(benchmark,item.response.toFixed(1)):item.response.toFixed(1)
           

		    return <Row key={i} className={'culture_row'+i}>
		        <Linked onClick={() => navigate(item)}>
		          {/*<Num>{i + 1 + ". "}</Num>*/}
		          {
		            questions?.dimensions[item?.id]
		              ?.factors[item?.factor]?.title
		          }
		        </Linked>

            <ProgressArea>
  		        <StyledProgressBar
  		          value={
  		            response
  		          }
  		          total={10}
  		          inverted={false}
  		          color={item.id==1} // accepts only ["red","orange","yellow","olive","green","teal","blue","violet","purple","pink","brown","grey","black"].
  		          size="small"
  		          style={{
  		            width: "100%",
  		            margin: 0,
  		          }}
  		        />
              
              
              {/* {benchmark?<BM2 left={checked ?80:checkBenchmark(benchmark)}/>:""}
              {benchmark?<BM left={checked ?50:(benchmark.average*10).toFixed(0)}/>:""} */}
             </ProgressArea>
		        <Score>
		          {response}
               <Diff color={checked? getColor(bench_diff):null}>{checked? '('+bench_diff+'%)' :get_last(item)}</Diff>

		        </Score>
		     </Row>
		 } ):""}
     </Column>
    </Container>

    )
}

export default LinkedRow


const color1 = 'linear-gradient(to right, #0159DB, #00B0F0) !important'
const color2 = 'linear-gradient(to right, #0DC6AB, #7DFBD4) !important'

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-image: ${props=>props.color==1?color1:color2};
    border-radius:15px !important;
  }
   border-radius:15px !important;
`;

const ProgressArea = styled.div`
  position:relative;
  width:calc(100% - 180px);
  display:flex;
  align-items:center;
`

const BM = styled.div`
  height:20px;
  border-left:1.5px dashed black;
  width:2px;
  position:absolute;
  left:${props=>props.left+'%'};

`

const BM2 = styled.div`
  height:20px;
  border-left:1.5px solid #FA1E4E;
  width:2px;
  position:absolute;
  left:${props=>props.left+'%'};

`

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2.5px 0px 2.5px 2.5px;
  margin-bottom:7.5px;
   font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;

color: #7E7E7E;
`;

const Linked = styled.div`
  font-weight: 300;
  width: 180px;
  cursor: pointer;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

const Score = styled.div`
  width: 60px;
  margin-left: 25px;
 font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
text-align: right;
display:flex;
color: #7E7E7E;
`;

const Container = styled.div`
  padding: 5px 5px 20px 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Column = styled.div`
  width: 100%;
  display:flex;
  flex-direction:column;
 align-items:center;
`;

const Title = styled.div`
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #C0C0C0;
  margin-bottom: 20px;
`;

const LegendRow = styled.div`
  display:flex;
  margin-bottom:20px;
  align-items:center;
  flex-wrap:wrap;
  justify-content:space-evenly;
`

const ColorSquare = styled.div`
  width:30px;
  height:2px;
  background-image:${props=>props.color==1?color2:color1};
  margin-right:10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);

`

const LegendText = styled.div`
    font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  line-height: 13px;
  /* identical to box height */


  color: #7e7e7e;
`

const BenchMark = styled.div`
  height:2px;
  border-bottom:2px dashed black;
  width:30px;
  margin-right:10px;
  margin-left:10px;
`

const BenchMark2 = styled.div`
  height:2px;
  border-bottom:2px solid #FA1E4E;
  width:30px;
  margin-right:10px;
  margin-left:10px;
 `

const Diff = styled.span`
  font-size:10px;
  margin-left:8px;
  color:${props=>props.color};

`