import React, { useState } from "react";
import styled from "styled-components";

const ItemContainer = styled.div`
  margin: 10px;
  padding: 5px;
  border-left: 3px solid ${(props) => (props.isActive ? "blue" : "gray")};
  cursor: pointer;
`;

const SubItemList = styled.ul`
  padding-left: 20px;
  list-style-type: none;
`;

const SubItem = styled.li`
  padding: 2px 0;
  margin-top: 5px;
`;

const SubItemIndicator = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #007bff;
  margin-right: 5px;
  margin-top: 4px;
`;

const SubItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 5px;
`;

const Step = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-right: 10px;
`;

const FilledStep = styled(Step)`
  border-color: #007bff;
  background-color: #007bff;
  color: white;
`;

const Checkmark = styled.span`
  font-size: 10px;
`;

const ParentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => (props.isActive ? "#007bff" : "gray")};
`;

const NavigationItem = ({ title, subItems, isActive, isCompleted }) => {
  const [isExpanded, setIsExpanded] = useState(isActive);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <ItemContainer onClick={toggleExpand} isActive={isActive}>
      <ParentWrapper isActive={isActive}>
        {isCompleted ? (
          <FilledStep>
            <Checkmark>✓</Checkmark>
          </FilledStep>
        ) : (
          <Step />
        )}
        {title}
      </ParentWrapper>
      {isExpanded && (
        <SubItemList>
          {subItems.map((subItem, index) => (
            <SubItemContainer>
              <SubItemIndicator />
              <SubItem key={index}>{subItem}</SubItem>
            </SubItemContainer>
          ))}
        </SubItemList>
      )}
    </ItemContainer>
  );
};

export default NavigationItem;
