import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Popup } from 'semantic-ui-react'
import {
  fadeIn,
  ScreenContainer,
} from "reports/DataJourney/components/common/styles";

const steps = [
  {
    id: 0,
    title: "Weekly insight read",
  },
  {
    id: 1,
    title: "Check in completed",
  },
  {
    id: 2,
    title: "Copilot question asked",
  },
  {
    id: 3,
    title: "Copilot question asked",
  },
  {
    id: 4,
    title: "Copilot question asked",
  },
  {
    id: 5,
    title: "Copilot question asked",
  },
  {
    id: 6,
    title: "Copilot question asked",
  },
  {
    id: 7,
    title: "Copilot question asked",
  },
];

const interpolateColor = (startColor, endColor, factor) => {
  const hex = (color) => parseInt(color.slice(1), 16);
  const lerp = (a, b, amount) => (1 - amount) * a + amount * b;

  let color1 = hex(startColor);
  let color2 = hex(endColor);

  let r1 = (color1 >> 16) & 0xff;
  let g1 = (color1 >> 8) & 0xff;
  let b1 = color1 & 0xff;

  let r2 = (color2 >> 16) & 0xff;
  let g2 = (color2 >> 8) & 0xff;
  let b2 = color2 & 0xff;

  let r = Math.round(lerp(r1, r2, factor)).toString(16).padStart(2, "0");
  let g = Math.round(lerp(g1, g2, factor)).toString(16).padStart(2, "0");
  let b = Math.round(lerp(b1, b2, factor)).toString(16).padStart(2, "0");

  return `#${r}${g}${b}`;
};

const Progress = ({ completed }) => {
  return (
    <ScreenContainer>
      {steps.map((item) => {
        const startColor = interpolateColor(
          "#27CDA7",
          "#2D70E2",
          item.id / steps.length
        );
        const endColor = interpolateColor(
          "#27CDA7",
          "#2D70E2",
          (item.id + 1) / steps.length
        );
        const gradient = `linear-gradient(to right, ${startColor}, ${endColor})`;
        return (
          <Step
            key={item.id}
            delay={item.id * 0.1 + 0.3}
            length={steps.length}
            completed={item.id < completed}
            gradient={
              item.id < completed
                ? gradient
                : "linear-gradient(to right, #DADADA, #DADADA)"
            }
          />
        );
      })}
    </ScreenContainer>
  );
};

export default Progress;

const Container = styled.div`
    width:100%;
    display:flex;
`

const Step = styled.div`
    width: ${props => 100 / props.length}%;
    height: 10px;
    background: ${props => props.gradient};
    margin-left: 2px;
    border-radius:5px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

