export const HeadingList = [
    {id: 9, ind: 0, name:"Leadership", title: "Overall Leadership", alt_title: "Leadership"},
    {id: 1, ind: 1, name:"Compassion", title: "Compassion", alt_title: "Compassion"},
    {id: 2, ind: 2, name:"Candor", title: "Candor", alt_title: "Candor"},
    {id: 3, ind: 3, name:"Empowerment", title: "Empowerment", alt_title: "Empowerment"},
    {id: 5, ind: 4, name:"Recognition", title: "Inspiration", alt_title: "Inspiration"},
    {id: 4, ind: 5, name:"EQ", title: "EQ", alt_title: "EQ"},
    {id: 6, ind: 6, name:"Adaptability", title: "Adaptability", alt_title: "Adaptability"},
    {id: 8, ind: 7, name:"Vision", title: "Vision", alt_title: "Vision"},
    {id: 7, ind: 8, name:"Development", title: "Strategy", alt_title: "Strategy"}
]

export const tabs = [
    "Overall Leadership",
    "Compassion",
    "Candor",
    "Empowerment",
    "Inspiration",
    "EQ",
    "Adaptability",
    "Vision",
    "Strategy",
  ];
export const Leadershiptitle = [
    "Leadership",
    "Compassion",
    "Candor",
    "Empowerment",
    "Inspiration",
    "EQ",
    "Adaptability",
    "Vision",
    "Strategy",
  ];

export const CultureTitle = [
  "Trust",
  "Communication",
  "Alignment",
  "Resilience",
  "Clarity",
  "Impact",
];


export const headerList = [
  "Resilience",
  "Clarity",
  "Communication",
  "Trust",
  "Impact",
  "Alignment",
];