import React, { useState } from "react";
import styled from "styled-components";

const BasicInput = (props) => {
  const { placeholder, handleInputChange, value, inputAltBg, hideFocus } =
    props;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <InputContainer isFocused={isFocused}>
      <StyledInput
        placeholder={placeholder}
        onChange={handleInputChange}
        value={value}
        isFocused={isFocused && !hideFocus}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        type="text"
        altBg={inputAltBg}
        multiple
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
  margin-bottom: ${(props) => (props.isFocused ? "3px" : "5px")};
`;

const StyledInput = styled.input`
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  border-radius: 5px;
  max-height: 100px;
  color: #666d79;
  width: 100%;
  margin-right: 10px;
  outline: none;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  border-bottom: ${(props) =>
    props.isFocused ? "2px solid #2D70E2" : "0px solid #DFDFDF"};
  margin-top: 5px;
  font-family: "Raleway";
  font-size: 14px;
  margin-bottom: 15px;
`;

export default BasicInput;
