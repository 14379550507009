import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import Assignee from "./Assignee";

const statusOptions = [
	{
		key: "Planned",
		text: "Planned",
		value: 0,
	},
	{
		key: "In Progress",
		text: "In Progress",
		value: 1,
	},
	{
		key: "Action Required",
		text: "Action Required",
		value: 2,
	},
	{
		key: "On Hold",
		text: "On Hold",
		value: 3,
	},
	{
		key: "Completed",
		text: "Completed",
		value: 4,
	},
];

const Actions = ({ data, onClick }) => {
	const [hover, setHover] = useState(false);

	return (
		<Container
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={() => onClick()}
		>
			<Title> {data.metric.factor.title} Plan</Title>
			<Description>{data.planData[0]}</Description>
			<Status>{statusOptions[data.status].text}</Status>
			<Assigned>
				{data.member.map((i) => {
					return (
						<Assignee
							first={i.name.split(" ")[0]}
							last={i.name.split(" ")[1]}
						/>
					);
				})}
			</Assigned>
			<Action>
				<More show={hover}>
					<Icon
						name="ellipsis horizontal"
						style={{ margin: 0, marginTop: -3 }}
					/>
				</More>
			</Action>
		</Container>
	);
};

export default Actions;

const Container = styled.div`
	width: 100%;
	background: white;
	height: 40px;
	display: flex;
	align-items: center;
	border-left: 5px solid #2d50e3;
	cursor: pointer;
	margin-bottom: 1px;
	font-family: "Barlow Semi Condensed", sans-serif;

	&:hover {
		background-color: rgba(45, 80, 227, 0.05);
	}
`;

const Action = styled.div`
	display: flex;
	width: 50px;
`;

const Title = styled.div`
	width: 130px;
	border-right: 1px solid black;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	margin-left: 10px;
	font-weight: bold;
`;

const Description = styled.div`
	margin-left: 5px;
	margin-right: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: calc(100% - 525px);
`;

const More = styled.div`
	border-color: rgb(118, 118, 118);
	background-color: rgba(9, 30, 66, 0.08);
	padding: 6px 10px;
	font-weight: 500;
	cursor: pointer;
	transition: background 0.1s ease-out 0s,
		box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
	border-radius: 2px;
	display: ${(props) => (props.show ? "flex" : "none")};
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
`;

const Metric = styled.div`
	padding: 3px 10px;
	border-color: rgb(118, 118, 118);
	background-color: #2d50e3;
	color: white;
	font-weight: bold;
	font-size: 14px;
	border-radius: 2px;
	text-transform: uppercase;
	width: 100px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Status = styled.div`
	padding: 3px 10px;
	border-color: rgb(118, 118, 118);
	background-color: #459e00;
	color: white;
	font-weight: bold;
	font-size: 14px;
	border-radius: 2px;
	text-transform: uppercase;
	width: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
`;

const Assigned = styled.div`
	margin-right: 10px;
`;
