import { put, call, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_ALL_ACTION_PLANS,
  SG_GET_ALL_ACTION_PLANS,
  FETCH_ACTION_PLAN,
  CREATE_ACTION_PLAN,
  UPDATE_ACTION_PLAN,
  GET_ACTION_PLAN_ACTIVITY,
  ADD_ACTIVITY_ITEM,
  DELETE_ACTIVITY_ITEM,
  ALL_ERRORS,
  SG_FETCH_ACTION_PLAN,
  SG_CREATE_ACTION_PLAN,
  SG_UPDATE_ACTION_PLAN,
  SG_GET_ACTION_PLAN_ACTIVITY,
  SG_ADD_ACTIVITY_ITEM,
  SG_DELETE_ACTIVITY_ITEM,
  UPDATE_ACTIVITY_ITEM,
  SG_UPDATE_ACTIVITY_ITEM,
  DELETE_ACTION_PLAN,
  SG_DELETE_ACTION_PLAN,
  GET_ACTION_PLAN_ACTIVITIES,
  SG_GET_ACTION_PLAN_ACTIVITIES,
} from "constants/actions";

function* getActionPlans(action) {
  try {
    const json = yield call(api.GET_ALL_ACTION_PLANS, action.payload);
    yield put({ type: GET_ALL_ACTION_PLANS, payload: json.data.results });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getActionPlansLoad() {
  yield takeLatest(SG_GET_ALL_ACTION_PLANS, getActionPlans);
}

function* getActionPlanActivities(action) {
  try {
    const json = yield call(api.GET_ACTION_PLAN_ACTIVITIES, action.payload);
    yield put({ type: GET_ACTION_PLAN_ACTIVITIES, payload: json.data.results });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getActionPlanActivitiesLoad() {
  yield takeLatest(SG_GET_ACTION_PLAN_ACTIVITIES, getActionPlanActivities);
}

function* fetchActionPlan(action) {
  try {
    const json = yield call(api.FETCH_ACTION_PLAN, action.payload);
    yield put({ type: FETCH_ACTION_PLAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* fetchActionPlanLoad() {
  yield takeLatest(SG_FETCH_ACTION_PLAN, fetchActionPlan);
}

function* createActionPlan(action) {
  try {
    const json = yield call(api.CREATE_ACTION_PLAN, action.payload);
    yield put({ type: CREATE_ACTION_PLAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* createActionPlanLoad() {
  yield takeLatest(SG_CREATE_ACTION_PLAN, createActionPlan);
}

function* updateActionPlan(action) {
  try {
    const json = yield call(api.UPDATE_ACTION_PLAN, action.payload);
    yield put({ type: UPDATE_ACTION_PLAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* updateActionPlanLoad() {
  yield takeLatest(SG_UPDATE_ACTION_PLAN, updateActionPlan);
}

function* getActionPlanActivity(action) {
  try {
    const json = yield call(api.GET_ACTION_PLAN_ACTIVITY, action.payload);
    yield put({ type: GET_ACTION_PLAN_ACTIVITY, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* getActionPlanActivityLoad() {
  yield takeLatest(SG_GET_ACTION_PLAN_ACTIVITY, getActionPlanActivity);
}

function* deleteActionPlan(action) {
  try {
    const json = yield call(api.DELETE_ACTION_PLAN, action.payload);
    yield put({ type: DELETE_ACTION_PLAN, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* deleteActionPlanLoad() {
  yield takeLatest(SG_DELETE_ACTION_PLAN, deleteActionPlan);
}

function* addActivityItem(action) {
  try {
    const json = yield call(api.ADD_ACTIVITY_ITEM, action.payload);
    yield put({ type: ADD_ACTIVITY_ITEM, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* addActivityItemLoad() {
  yield takeLatest(SG_ADD_ACTIVITY_ITEM, addActivityItem);
}

function* deleteActivityItem(action) {
  try {
    const json = yield call(api.DELETE_ACTIVITY_ITEM, action.payload);
    yield put({ type: DELETE_ACTIVITY_ITEM, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* deleteActivityItemLoad() {
  yield takeLatest(SG_DELETE_ACTIVITY_ITEM, deleteActivityItem);
}

function* updateActivityItem(action) {
  try {
    const json = yield call(api.UPDATE_ACTIVITY_ITEM, action.payload);
    yield put({ type: UPDATE_ACTIVITY_ITEM, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* updateActivityItemLoad() {
  yield takeLatest(SG_UPDATE_ACTIVITY_ITEM, updateActivityItem);
}

export default function* index() {
  yield all([
    getActionPlansLoad(),
    fetchActionPlanLoad(),
    createActionPlanLoad(),
    updateActionPlanLoad(),
    getActionPlanActivityLoad(),
    addActivityItemLoad(),
    deleteActivityItemLoad(),
    updateActivityItemLoad(),
    deleteActionPlanLoad(),
    getActionPlanActivitiesLoad(),
  ]);
}
