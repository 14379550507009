import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";


import {
    ENGAGEMENT_DATA_PENDING,
    GET_ENGAGEMENT_DATA,
    SG_GET_ENGAGEMENT_DATA,
    ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
    yield put({ type: ENGAGEMENT_DATA_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_ENGAGEMENT_DATA, action.payload);
    yield put({ type: GET_ENGAGEMENT_DATA, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_ENGAGEMENT_DATA, loadmethods);
}


export default function* index() {
  yield all([
    methodLoad(),
  ]);
}
