import { useSelector, shallowEqual, useDispatch } from "react-redux";
import React,{ useEffect, useState } from "react";
import styled,{ createGlobalStyle } from 'styled-components';
import Anchor from "./Anchor";

const SelectAnchor = ({next}) =>{

    
    return (
        <Container>
            <Title>Report segmentation </Title>
            <Description>
                Select a group or category for your data to be segmented by. This will determine how your presentation will display the comparison data.
            </Description>

            <Anchor/>


            <ButtonArea>
                <Button onClick={()=>next()}>
                    Build My Presentation
                </Button>
            </ButtonArea>
           
        </Container>
    )
}

export default SelectAnchor;


const Container = styled.div`

`

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #333333;
    margin-bottom:10px;
`

const Description = styled.div`

`


const ButtonArea = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`

const Button = styled.button`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
    border:none;
    font-family: 'Raleway';
     background-color: ${({ disabled }) => (disabled ? "#ccc" : "#2d70e2")};
  color: ${({ disabled }) => (disabled ? "#666" : "white")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  

   &:hover {
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#1c4db8")};
  }

`;