import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Textbox from "./Textbox";
import SectionTitle from "./SectionTitle";
import PillarLabel from "./PillarLabel";
import GroupTitle from "./GroupTitle";
import MultiDropdown from "./MultiDropdown";
import TextInput from "./TextInput";
import DateSelector from "./DateSelector";
import InfoHeading from "./InfoHeading";
import MultiDataTable from "./MultiDataTable";
import WellnessTop from "./WellnessTop";
import ResourceLink from "./ResourceLink";
import BarGraph from "./BarGraph";
import DropdownTable from "./DropdownTable";
import ReflectionTable from "./ReflectionTable";
import WellnessTable from "./WellnessTable";
import EIAComponent from "./EIACompoment";
import DateRange from "./DateRange";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getPDFComponent = (componentData, data, index, categories, metric) => {
  let pdfComponent;
  switch (componentData.type) {
    case "textbox":
      pdfComponent = <Textbox key={index} {...componentData} data={data} />;
      break;

    case "textinput":
      pdfComponent = (
        <TextInput key={index} {...componentData} data={data?.planData} />
      );
      break;

    case "sectiontitle":
      pdfComponent = <SectionTitle key={index} {...componentData} />;
      break;

    case "culturepillar":
      pdfComponent = (
        <PillarLabel
          key={index}
          pillar={(metric?.title && metric) || capitalizeFirstLetter(metric)}
        />
      );
      break;

    case "grouptitle":
      pdfComponent = <GroupTitle key={index} {...componentData} />;
      break;

    case "multidropdown":
      pdfComponent = (
        <MultiDropdown
          key={index}
          {...componentData}
          selected={data.planData}
          comments={data.planData}
        />
      );
      break;

    case "dateselection":
      pdfComponent = (
        <DateSelector key={index} {...componentData} data={data} />
      );
      break;

    case "daterange":
      pdfComponent = <DateRange key={index} {...componentData} data={data} />;
      break;

    case "reflectiontable":
      pdfComponent = (
        <ReflectionTable key={index} {...componentData} data={data} />
      );
      break;

    case "dynamicinputtable":
      pdfComponent = (
        <ReflectionTable
          dynamic={true}
          key={index}
          {...componentData}
          data={data}
        />
      );
      break;

    case "resourcelinks":
      pdfComponent = <ResourceLink key={index} {...componentData} />;
      break;

    case "commenttable":
      pdfComponent = (
        <MultiDataTable key={index} {...componentData} data={data} />
      );
      break;

    case "dynamicdropdowntable":
      pdfComponent = (
        <DropdownTable key={index} {...componentData} data={data} />
      );
      break;

    case "eiacomponent":
      pdfComponent = (
        <EIAComponent key={index} {...componentData} data={data} />
      );
      break;

    case "wellnesstable":
      pdfComponent = (
        <WellnessTable
          key={index}
          {...componentData}
          data={data}
          primary={data?.primary}
        />
      );
      break;

    case "bargraph":
      pdfComponent = (
        <BarGraph
          average={
            (
              data?.planData?.[4] && data?.planData?.[4]
            )?.factorAverage?.toFixed(1) || 0
          }
          categories={categories}
          data={data?.planData?.[4] && data?.planData?.[4]}
          savedTemplate={true}
          isFrench={false}
          title={componentData?.title}
          key={index}
        />
      );
      break;

    case "wellnesstop":
      pdfComponent = <WellnessTop key={index} {...componentData} />;
      break;

    default:
      pdfComponent = null;
  }

  return pdfComponent;
};

const PDFLayout = (props) => {
  const {
    template,
    data,
    planTitle,
    primary,
    categories,
    status,
    assignees,
    startDate,
    endDate,
    metric,
    factors,
    question,
    questionsList,
    focusArea,
  } = props;
  const [primaryName, setPrimaryName] = useState();

  useEffect(() => {
    if (primary && categories) {
      const sportCat = categories?.categories.find(
        (cat) => cat.priority === "primary"
      );
      const primarySport = sportCat.options
        .find((opt) => opt.subfactors.find((sub) => sub.id === primary))
        .subfactors.find((sub) => sub.id === primary);
      if (primarySport?.name) {
        setPrimaryName(primarySport.name);
      }
    }
  }, [primary, categories]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View>
          <InfoHeading
            planTitle={planTitle}
            status={status}
            metric={metric}
            primary={primaryName}
            assignees={assignees}
            startDate={startDate}
            endDate={endDate}
            factors={factors}
            question={question}
            questionsList={questionsList}
            focusArea={focusArea}
          />
        </View>
        {template?.map((component, index) => {
          return (
            <View id={`${component.type}-${index}`}>
              {getPDFComponent(component, data, index, categories, metric)}
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: 40,
    position: "relative",
    fontFamily: "Raleway",
  },
});

export default PDFLayout;
