/**
 * @description Chart options
 *
 */

export const chartDownloadOptions = {
  toolbar: {
    show: true,
    offsetX: 0,
    offsetY: 0,
    tools: {
      download: true,
      selection: true,
      zoom: false,
      zoomin: false,
      zoomout: false,
      pan: false,
      reset: false | '<img src="/static/icons/reset.png" width="20">',
      //   customIcons: []
    },
    export: {
      csv: {
        filename: undefined,
        columnDelimiter: ",",
        headerCategory: "category",
        headerValue: "value",
        categoryFormatter(x) {
          return new Date(x).toDateString();
        },
        valueFormatter(y) {
          return y;
        },
      },
      svg: {
        filename: undefined,
      },
      png: {
        filename: undefined,
      },
    },
    autoSelected: "zoom",
  },
};

export function injectDownloadOptions(
  apexChartOptionsObject,
  useLabel = false
) {
  return {
    ...apexChartOptionsObject,
    chart: {
      ...apexChartOptionsObject.chart,
      toolbar: {
        ...apexChartOptionsObject.chart?.toolbar,
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
        },
        export: {
          csv: {
            columnDelimiter: ",",
            headerCategory: "Category",
            dateFormatter: (timestamp) =>
              new Date(timestamp).toLocaleDateString(),
          },
          svg: { filename: "chart_export" },
          png: { filename: "chart_export" },
        },
      },
    },
    tooltip: {
      ...apexChartOptionsObject.tooltip,
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w, options }) {
        const category = w.globals.seriesNames?.[0]?.[dataPointIndex]; // Get the x-axis label
        const label = w.globals.labels?.[dataPointIndex]; // Get the x-axis label
        if (category?.length > 20 && !useLabel) {
          return `
            <div style="z-index: 9999, padding: 8px; background-color: white; border: 1px solid #e0e0e0; border-radius: 5px; font-family: Poppins, sans-serif;">
              <div style="font-size: 14px; font-weight: 500; color: #333;">${category}</div>
            </div>
          `;
        }

        if (label?.length > 20 && useLabel) {
          return `
            <div style="z-index: 9999, padding: 8px; background-color: white; border: 1px solid #e0e0e0; border-radius: 5px; font-family: Poppins, sans-serif;">
              <div style="font-size: 14px; font-weight: 500; color: #333;">${label}</div>
            </div>
          `;
        }

        return `
          <div>
          </div>
        `;
      },
    },
    xaxis: {
      ...apexChartOptionsObject.xaxis,
      labels: {
        ...apexChartOptionsObject.xaxis?.labels,
        formatter: function (value) {
          // Ensure the value is a string and handle cases where it's not
          if (!value || typeof value !== "string") {
            return ""; // Return an empty string if the value is invalid
          }
          const maxLength = 20; // Adjust truncation length as needed
          return value.length > maxLength
            ? `${value.slice(0, maxLength)}...`
            : value;
        },
      },
    },
  };
}