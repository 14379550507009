import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";




const Explanation = () =>{

    return (
        <Container>
            <Title>
                Low employee engagement in the sales department
            </Title>

            <InsightText>
            Your employee engagement has been steadily decreasing over the last 6 months.
            </InsightText>
            <T2>Key Metrics</T2>
            <Stats>
                <Stat>
                    <StatTitle>
                        Score
                    </StatTitle>
                    <StatValue>
                        6.4
                    </StatValue>
                </Stat>
                <Stat>
                    <StatTitle>
                    Change
                    </StatTitle>
                    <StatValue>
                        -6.3%
                    </StatValue>
                </Stat>

                <Stat>
                    <StatTitle>
                        # of employees impacted
                    </StatTitle>
                    <StatValue>
                        36
                    </StatValue>
                </Stat>
                <Stat>
                    <StatTitle>
                    Cohort difference
                    </StatTitle>
                    <StatValue>
                        -35%
                    </StatValue>
                </Stat>

            </Stats>

            <T1>Reason</T1>
            <Text>These factors combined can result in employees being physically present but mentally disengaged from their sales tasks.</Text>
            <ol>
                <li><strong>Commission Fatigue:</strong> Sales personnel may become desensitized to monetary incentives, finding the constant pressure for targets less motivating over time.</li>
                <li><strong>Monotonous Work Routine:</strong> A lack of variety in sales strategies and customer interactions can lead to boredom and disengagement.</li>
                <li><strong>Competitive Environment:</strong> The high-stakes, competitive nature of sales can create a sense of isolation, reducing teamwork and lowering overall engagement.</li>
                <li><strong>Lack of Novelty:</strong> Continuous repetition of the same tasks without innovation or change can diminish interest and enthusiasm in the job.</li>

            </ol>


            <T1>Recommendation</T1>

            <Text>To address the issue of low employee engagement in the sales department, especially due to "commission fatigue," a multi-faceted approach should be employed:</Text>

            <ol>
                <li><strong>Revamp Incentive Structures:</strong> Shift from traditional commission models to a more holistic reward system that recognizes a range of achievements, such as customer satisfaction, teamwork, and innovative thinking. Introduce non-monetary incentives such as additional vacation days, flexible working hours, or opportunities for personal development and training.</li>
                <li><strong>Enhance Job Variety:</strong> Regularly rotate sales staff through different roles or market segments to keep the work interesting and challenging. This can prevent monotony and help employees develop a broader skill set, making their job more engaging and fulfilling.</li>
                <li><strong>Foster a Team-Oriented Culture:</strong> Promote a sense of camaraderie and collaboration within the sales team. Organize team-building activities and collaborative projects that encourage teamwork and build a supportive community within the department.</li>
                <li><strong>Implement Continuous Learning and Development:</strong> Offer ongoing training and professional development opportunities. Encourage employees to pursue certifications, attend workshops, and participate in industry conferences to enhance their skills and stay updated with the latest sales strategies and technologies.</li>
                <li><strong>Encourage Employee Feedback and Participation:</strong> Create a feedback-rich environment where employees feel valued and heard. Regularly solicit their ideas and suggestions on how to improve the sales process, workplace culture, and incentive programs.</li>
                <li><strong>Promote Work-Life Balance:</strong> Recognize the importance of personal time and mental health. Encourage employees to take breaks, disconnect after work hours, and utilize their vacation time to recharge.</li>

            </ol>

        </Container>
    )

}

export default Explanation;

const fadeIn = keyframes`
    
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    `;

const Container = styled.div`
    width:100%;
    
    animation: ${fadeIn} 0.5s;
    transition: all 0.3s ease;


`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`
const T1 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-top:30px;
    color:#666D79;
    margin-bottom:10px;
`

const Text = styled.div`
    margin-bottom:10px;
    line-height:1.6;
    font-size:14px;
    color:#666D79;
`

const Row = styled.div`
    display:flex;
    width:100%;
    flex-wrap:wrap;
    flex-direction:column;
`

const InsightCard = styled.div`
    width:100%;
    padding:20px;
    border:1px solid #E0E0E0;
    border-radius:5px;
    margin-bottom:20px;
    position:relative;
`

const InsightTitle = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`
const InsightText = styled.div`
    font-size:14px;
    color:#666D79;
    line-height:1.4;
`

const Stats = styled.div`
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
`

const Stat = styled.div`
    width:calc(50% - 10px);
    margin-top:10px;
`
const StatTitle = styled.div`
    font-size:12px;
    color:#666D79;
`
const StatValue = styled.div`
    font-size:16px;
    font-weight:bold;
    color:#666D79;
`

const T2 = styled.div`
    font-size:10px;
    font-weight:bold;
    margin-top:20px;
    color:#666D79;
    text-transform:uppercase;
`