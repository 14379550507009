import {
  GET_PROD_ORG_CONTRACT_PENDING,
  GET_PROD_ORG_CONTRACT,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  prod_org_contract: [],
  pending: false,
};

export default function Price(state = initialState, action) {
  switch (action.type) {
    case GET_PROD_ORG_CONTRACT_PENDING:
      return {
        ...state,
        pending: true,
      };

    case GET_PROD_ORG_CONTRACT:
      return {
        ...state,
        prod_org_contract: action.payload,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        initialState,
      };

    default:
      return state;
  }
}
