/// Libraries
import React from "react";
import { Page, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import BarlowBold from "assets/fonts/BarlowSemiCondensed-Bold.ttf";
import BarlowMedium from "assets/fonts/BarlowSemiCondensed-Medium.ttf";
import BarlowRegular from "assets/fonts/BarlowSemiCondensed-Regular.ttf";

// Pages
import Intro from "./Intro";
import Methods from "./Methods";
import Risks from "./Risks";
import Breakdown from "./Breakdown";
import Footer from "../components/Footer";
import Diversity from "./Diversity";
import SectionIntro from "./SectionIntro";
import { getTopRisks } from "../utils/calculations";
import TableOfContents from "./TableOfContents";
import Engagement from './Engagement'

Font.register({
  family: "Barlow",
  fonts: [
    { src: Barlow },
    { src: BarlowBold, fontWeight: 700 },
    { src: BarlowMedium, fontWeight: 500 },
    { src: BarlowRegular, fontWeight: 400 },
  ],
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
     padding: 60,
    paddingHorizontal: 80,
    fontFamily: "Barlow",
  },
  section: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  title: {
    fontSize: 40,
    textAlign: "center",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  table: {
    marginHorizontal: 10,
  },
  subHeading: {
    fontSize: 12,
    paddingBottom: 50,
  },
  spiderSubHeading: {
    fontSize: 12,
    paddingBottom: 30,
    display: "flex",
    textAlign: "center",
    zIndex: 999,
  },
  heading: {
    paddingBottom: "15px",
    left: 0,
    fontSize: 22,
    fontWeight: 500,
  },
  doc: {
    fontFamily: "Barlow",
    fontSize: 14,
      padding: 60,
    paddingHorizontal: 80,
  },
  spiderImage: {
    left: 55,
    top: -28,
    maxHeight: 300,
    maxWidth: 400,
    height: 300,
    width: 400,
    zIndex: -99,
  },
  spiderHeading: {
    fontSize: 18,
    paddingBottom: 10,
    fontWeight: 500,
  },
});

const Template = ({
  surveyStructure,
  surveyQuestions,
  overviewReport,
  ediResponses,
}) => {
  const keyPoints = getTopRisks(
    surveyStructure.categories,
    surveyQuestions.questions.factors,
    ediResponses,
    10
  );

  const generateBreakdownTOC = (questions, categories) => {
    const CATEGORIES_PER_PAGE = 3;
    let numCategories = 0;
    categories.forEach((cat) => {
      // If overview component is generating report, otherwise use categories.length.
      if ((!overviewReport && cat.priority !== "primary") || overviewReport) {
        numCategories += 1;
      }
    });

    if (questions.factors) {
      return questions.factors.map((f) => {
        const numPages = Math.ceil(numCategories / CATEGORIES_PER_PAGE);

        return { title: f.title, numPages: numPages };
      });
    }
  };

  // This will conditionally include/exclude primary from breakdown
  // report based on a param passed from the overview component.
  const breakdownCategories = overviewReport
    ? surveyStructure.categories
    : surveyStructure.categories.filter((f) => f.priority !== "primary");

  return (
    <Document style={styles.doc}>
      {/* INTRO PAGE */}
      <Page>
        <View>
          <Intro config={null} selectedReport={null} selectedOrg={null} />
        </View>

        <Footer />
      </Page>

      {/* TABLE OF CONTENTS */}
      <View>
        <TableOfContents
          sections={generateBreakdownTOC(
            surveyQuestions?.questions,
            surveyStructure.categories
          )}
        />
      </View>
      <Engagement />
      <Methods
        questions={surveyQuestions?.questions}
        responseData={ediResponses}
      />

      <Risks data={keyPoints} overviewReport={overviewReport} />

      {surveyQuestions?.questions.factors.map((item, i) => {
        return (
          <Breakdown
            data={item}
            surveyQuestions={surveyQuestions}
            surveyStructure={surveyStructure}
            responseData={ediResponses}
            categories={surveyStructure.categories}
          />
        );
      })}

      <SectionIntro title={"Understanding Demographics"} />
      <Diversity
        surveyQuestions={surveyQuestions}
        surveyStructure={surveyStructure}
        responseData={ediResponses}
        categories={surveyStructure.categories.filter(
          (f) => f.priority === "tertiary"
        )}
      />
    </Document>
  );
};

export default Template;
