import styled from "styled-components";
import React, { useEffect, useState } from "react";

const SportLabel = ({ primary, categories }) => {
    const [selected, setSelected] = useState("");
    
    useEffect(() => {
        if (primary && categories) {
            const sportCat = categories?.categories.find((cat) => cat.priority === "primary");
            const primarySport = sportCat.options.find((opt) => opt.subfactors.find((sub) => sub.id === primary)).subfactors.find((sub) => sub.id === primary);
            if (primarySport?.name) {
                setSelected(primarySport.name);
            }
        }
    }, [primary, categories]);
    
    return (
        <Container>
            <Label>Sport: </Label>
            <SportLabelText>{selected}</SportLabelText>
        </Container>
    );
}

export default SportLabel;


const Label = styled.div`
  color: #666d79;
  min-width: fit-content;
  text-align: right;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-right: 60px;
  margin-top: 3px;
`;

const SportLabelText = styled.div`
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 3px;

`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;