import styled from "styled-components";
import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Checkbox, Icon, Dimmer, Loader } from 'semantic-ui-react'
import Chart from "react-apexcharts";


const TableRow = ({ emp }) => {
	// console.log(emp)
	return (
		<TR>
			<TD width={25} borderLeft={false} borderRight={true}>
				{emp.picture ? <Avatar src={emp?.picture} /> :
					<Icon name="user circle" style={{ fontSize: 20, marginTop: 0, marginRight: 5, marginLeft: -2 }} />}
				<Name>{emp?.name}</Name>
			</TD>
			<TD2 width={12.5} borderRight={true}
				bold score={emp?.Average}>
				{emp?.Average}
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				{emp?.Consistency}
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				{emp?.Composure}
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				{emp?.Confidence}
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				{emp?.Compete}
			</TD2>
			<TD2 width={12.5} borderRight={false}>
				{emp?.Cohesion}
			</TD2>
		</TR>
	)
}

const Card = (props) => {

	return (
		<SumCard>
			<Area1>
				<TypeName>{props?.title}</TypeName>
				<Description>We sustained our best all the way through this performance, from start to finish.</Description>
			</Area1>
			<Score>{props?.score}%</Score>
		</SumCard>
	)
}


const factors = ['Consistency', 'Composure', 'Confidence', 'Compete', 'Cohesion']

const Game = ({ debriefResponses, questions,
	debriefSchedule, get_employees, teamId }) => {

	const [wordList, setWordList] = useState([])
	const [employeeList, setEmployeeList] = useState([])
	const [debriefSummary, setDebriefSummary] = useState([])
	const [seriesChartData, setSeriesChartData] = useState(false)

	const [dir, setDir] = useState(1)
	const [sorted, setSorted] = useState('Average')
	const [loading, setLoading] = useState(true)

	const [showMemberData, setShowMemberData] = useState(true)
	const [showLeaderData, setShowLeaderData] = useState(true)
	const [allowMemberData, setAllowMemberData] = useState(false)
	const [allowLeaderData, setAllowLeaderData] = useState(false)
	const [dispEmpList, setDispEmpList] = useState([])


	// console.log(questions)
	// console.log(debriefSchedule)
	// console.log(get_employees)

	useEffect(() => {
		const _WordListing = []
		if (questions) {
			questions.questions.map((item) => {
				return _WordListing.push(item.name)
			})
			setWordList(_WordListing)
		}
	}, [questions]);

	useEffect(() => {
		setTimeout(() => setLoading(false), 1000);
	}, [seriesChartData])

	const sort = (type) => {
		let _dir = dir
		if (type === sorted) {
			_dir = _dir * -1
			setDir(_dir)
		}
		setSorted(type)
		if (_dir > 0) {
			let _dispEmpList = dispEmpList.sort(function (a, b) {
				return a[type] - b[type]
			})
			return setDispEmpList(_dispEmpList)
		}
		let _dispEmpList = dispEmpList.sort(function (a, b) {
			return b[type] - a[type]
		})
		return setDispEmpList(_dispEmpList)
	}

	useEffect(() => {
		const _EmpListing = []

		get_employees.filter(em => em.team === teamId).map((item) => {
			let fullName = item.first_name ? `${item.first_name} ${item.last_name}` : `${item.email}`
			const _scores = {}
			let Average = 0
			let NofOf = 0
			// console.log(debriefResponses.filter(db => db.employee === item.id)[0]?.response)
			if (debriefResponses.filter(db => db.employee === item.id)[0]?.response) {

				const UserScore = (Object.values(debriefResponses.filter(db => db.employee === item.id)[0]?.response))

				wordList.map((wl, k) => {
					_scores[wl] = UserScore[k]
					Average += UserScore[k]
					NofOf += 1
					return null
				})

				_scores['Average'] = Average / NofOf
			} else {
				wordList.map((wl, k) => {
					return _scores[wl] = 0
				})
				_scores['Average'] = 0
			}
			if (debriefSchedule?.include_leaders) {
				if (item.role < 7) {
					_EmpListing.push({
						id: item.id,
						picture: item.picture,
						email: item.email,
						name: fullName,
						leader: item.role < 7 ? true : false,
						member: item.role > 7 ? true : false,
						..._scores
					})
				}
			}

			if (debriefSchedule?.include_members) {
				if (item.role > 7) {
					_EmpListing.push({
						id: item.id,
						picture: item.picture,
						email: item.email,
						name: fullName,
						leader: item.role < 7 ? true : false,
						member: item.role > 7 ? true : false,
						..._scores
					})
				}
			}
			

			return null
		})
		setEmployeeList(_EmpListing)

	}, [get_employees, teamId, debriefSchedule, wordList, debriefResponses]);

	useEffect(() => {
		// debriefSchedule?.include_members
		setShowMemberData(debriefSchedule?.include_members)
		setShowLeaderData(debriefSchedule?.include_leaders)

		// setAllowMemberData(debriefSchedule?.include_members)
		// setAllowLeaderData(debriefSchedule?.include_leaders)
		// const [allowMemberData, setAllowMemberData] = useState(false)
		// const [allowLeaderData, setAllowLeaderData] = useState(false)
	}, [debriefSchedule]);

	useEffect(() => {
		let _EmpDisList = []
		if (showMemberData) {
			_EmpDisList = employeeList.filter(el =>
				el.member === showMemberData
			).map((item) => {
				return item
			})
		}
		let _LeadDisList = []
		if (showLeaderData) {
			_LeadDisList = employeeList.filter(el =>
				el.leader === showLeaderData
			).map((item) => {
				return item
			})
		}

		const _CombinedList = [..._EmpDisList, ..._LeadDisList]
		setDispEmpList(_CombinedList.sort(function (a, b) {
			return b['Average'] - a['Average']
		}))
	}, [employeeList, wordList, showMemberData, showLeaderData]);

	useEffect(() => {
		const TotalScoreList = {}
		const TotalScoreNos = {}
		const TotalPrevScoreList = {}
		const TotalPrevScoreNos = {}

		if (showLeaderData && !showMemberData ) {
			// console.log("showLeaderData found")
			setShowMemberData(false)
			wordList.map(wl => {
				// console.log(debriefSchedule?.summary?.current_leaders?.[wl])
				TotalScoreList[wl] = debriefSchedule?.summary?.current_leaders?.[wl]
				TotalScoreNos[wl] = debriefSchedule?.summary?.current_leaders?.nos
				TotalPrevScoreList[wl] = debriefSchedule?.summary?.previous_leaders?.[wl]
				TotalPrevScoreNos[wl] = debriefSchedule?.summary?.previous_leaders?.nos
				return null
			})
		}

		if (!showLeaderData && showMemberData ) {
			// console.log("showMemberData found")
			setShowLeaderData(false)
			wordList.map(wl => {
				// console.log(debriefSchedule?.summary?.current_members?.[wl])
				TotalScoreList[wl] = debriefSchedule?.summary?.current_members?.[wl]
				TotalScoreNos[wl] = debriefSchedule?.summary?.current_members?.nos
				TotalPrevScoreList[wl] = debriefSchedule?.summary?.previous_members?.[wl]
				TotalPrevScoreNos[wl] = debriefSchedule?.summary?.previous_members?.nos
				return null
			})
		}

		if (showLeaderData && showMemberData) {
			// console.log("Both found")
			wordList.map(wl => {
				// console.log(debriefSchedule?.summary?.current_members?.[wl])
				TotalScoreList[wl] = debriefSchedule?.summary?.current_all?.[wl]
				TotalScoreNos[wl] = debriefSchedule?.summary?.current_all?.nos
				TotalPrevScoreList[wl] = debriefSchedule?.summary?.previous_all?.[wl]
				TotalPrevScoreNos[wl] = debriefSchedule?.summary?.previous_all?.nos
				return null
			})
		}

		const CurrentList = {}
		const PreviousList = {}

		Object.entries(TotalScoreList).map(([key, value]) => {
			// console.log(key, value)
			return (
				CurrentList[key] = Math.round(value / TotalScoreNos[key])
			);
		})

		Object.entries(TotalPrevScoreList).map(([key, value]) => {
			// console.log(key, value)
			return (
				PreviousList[key] = Math.round(value / TotalPrevScoreNos[key])
			);
		})

		// FinalList['Average'] = TotalScore > 0 ? Math.round(TotalScore / TotalNos) : 0
		// console.log(TotalScoreList)
		// console.log(TotalPrevScoreList)
		// console.log(CurrentList)
		// console.log(PreviousList)
		// console.log(debriefSchedule)

		if (CurrentList?.Cohesion) {
			let _series = wordList.map((item) => {
				return CurrentList[item]
			})

			let _series2 = wordList.map((item) => {
				return PreviousList[item] ? PreviousList[item] : 0
			})

			setSeriesChartData([{ "name": 'Scores', data: _series }, { "name": 'Average', data: _series2 }])
		} else {
			setSeriesChartData(null)
		}

	}, [wordList, debriefSchedule, showMemberData, showLeaderData]);

	// console.log(seriesChartData)
	// console.log(showLeaderData)
	// console.log(showMemberData)

	return (
		<Container>
			<Top>
				<Title>Performance Debrief</Title>
				<CheckContainer>
					<Checkbox label='Members'
						disabled={!debriefSchedule?.include_members}
						checked={!debriefSchedule?.include_members ? false : showMemberData}
						onChange={() => debriefSchedule?.include_members && setShowMemberData(!showMemberData)}
						style={{ marginRight: 10 }}
					/>
					<Checkbox label='Leaders'
						disabled={!debriefSchedule?.include_leaders}
						checked={!debriefSchedule?.include_leaders ? false : showLeaderData}
						onChange={() => 
							debriefSchedule?.include_leaders && setShowLeaderData(!showLeaderData)}
					/>
				</CheckContainer>
			</Top>
			<Dimmer.Dimmable dimmed={loading}>
				<Dimmer active={loading} inverted>
					<Loader>Loading</Loader>
				</Dimmer>
				{loading ? " " :
					<SummaryCards>
						{seriesChartData ?
							<Chart
								options={data3.options}
								series={seriesChartData}
								type="bar" height={400} /> :
							""}
						{/*<Card title={"Average"} score={debriefSummary['Average']} />
				<Card title={"Consistency"} score={debriefSummary['Consistency']} />
				<Card title={"Composure"} score={debriefSummary['Composure']} />
				<Card title={"Confidence"} score={debriefSummary['Confidence']} />
				<Card title={"Compete"} score={debriefSummary['Compete']} />
				<Card title={"Cohesion"} score={debriefSummary['Cohesion']} />*/}

					</SummaryCards>}
			</Dimmer.Dimmable>

			<Table>

				<TR>
					<TH width={25} borderLeft={false} borderRight={true}>
						Member
					</TH>
					<TH width={12.5} borderRight={true} center={true} click={true} onClick={() => sort('Average')}>
						Overall <SortArrow dir={dir} show={sorted === "Average"} />
					</TH>
					<TH width={12.5} borderRight={true} center={true} click={true} onClick={() => sort('Consistency')}>
						Consistency <SortArrow dir={dir} show={sorted === "Consistency"} />
					</TH>
					<TH width={12.5} borderRight={true} center={true} click={true} onClick={() => sort('Composure')}>
						Composure <SortArrow dir={dir} show={sorted === "Composure"} />
					</TH>
					<TH width={12.5} borderRight={true} center={true} click={true} onClick={() => sort('Confidence')}>
						Confidence <SortArrow dir={dir} show={sorted === "Confidence"} />
					</TH>
					<TH width={12.5} borderRight={true} center={true} click={true} onClick={() => sort('Compete')}>
						Compete <SortArrow dir={dir} show={sorted === "Compete"} />
					</TH>
					<TH width={12.5} borderRight={false} center={true} click={true} onClick={() => sort('Cohesion')}>
						Cohesion <SortArrow dir={dir} show={sorted === "Cohesion"} />
					</TH>
				</TR>
				{dispEmpList.map((emp, k) => {
					return <TableRow
						key={k}
						emp={emp}
					/>

				})}


			</Table>
		</Container>
	)
}



export default Game


const SortArrow = ({ dir, show }) => {

	return (
		<SortContainer show={show}>
			<Icon name={dir > 0 ? 'chevron down' : 'chevron up'} style={{ marginTop: -6 }} />
		</SortContainer>
	)
}

const Container = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:50px;
`

const SortContainer = styled.div`
		display:${props => props.show ? 'flex' : 'none'};
		margin-left:5px;
`

const Top = styled.div`
	height:70px;
	margin-top:10px;
	border-bottom:1px solid #ebebeb;
	width:65%;
	display:flex;
	align-items:center;
	padding:10px;
	position:relative;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-right:20px;
`
const CheckContainer = styled.div`
	position:absolute;
	right:0;
`

const Table = styled.div`
	width:100%;
	padding:0px 50px;
`
const TR = styled.div`
	width:100%;
	height:35px;
	display:flex;
	border-top:1px solid black;
	border-bottom:1px solid black;
	
`

const TH = styled.div`
	color:white;
	background-color:#3078ff;
	width:${props => props.width + '%'};
	display:flex;
	align-items:center;
	padding:3px 6px;
	font-weight:bold;
	border-right:${props => props.borderRight ? '1px solid black' : 'none'};
	border-left:${props => props.borderLeft ? '1px solid black' : 'none'};
	justify-content:${props => props.center ? 'center' : 'flex-start'};
	cursor:${props => props.click ? 'pointer' : 'auto'};
	 user-select: none;
`

const SummaryCards = styled.div`
	padding:50px;
	min-height:450px;
`

const Avatar = styled.img`
	width:20px;
	height:20px;
	border-radius:10px;
	margin-right:5px;
`

const Name = styled.div`
	
`

const TD = styled.div`
	width:${props => props.width + '%'};
	display:flex;
	align-items:center;
	padding:3px 6px;
	border-right:${props => props.borderRight ? '1px solid black' : 'none'};
	border-left:${props => props.borderLeft ? '1px solid black' : 'none'};
`

const TD2 = styled.div`
	width:${props => props.width + '%'};
	display:flex;
	align-items:center;
	padding:3px 6px;
	border-right:${props => props.borderRight ? '1px solid black' : 'none'};
	border-left:${props => props.borderLeft ? '1px solid black' : 'none'};
	justify-content:center;
	font-weight:${props => props.bold ? 'bold' : 400};
	background-color:${props => props.score ? 'rgba(48, 120, 255,' + props.score / 100 + ')' : 'white'};
`

const SumCard = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:350px;
	height:200px;
	display:flex;
	margin-bottom:10px;
	padding:30px;
`

const TypeName = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
`

const Description = styled.div`
	color:#757575;
`

const Area1 = styled.div`
	width:50%;

`

const Score = styled.div`
	width:50%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:40px;
	font-weight:bold;
`

const questions = ['We sustained our best all the way through this performance, from start to finish.',
	'We met the most difficult moments of this performance with calmness, clarity, and control.',
	'I expected us to be successful going into this performance - I believed in our ability to meet the task.',
	'We brought our full effort and energy to this performance - we played with purpose, passion, and never gave up.',
	'The interactions I had with my teammates during this performance energized and empowered me to be my best.']

const data3 = {

	options: {
		chart: {
			type: 'line',
			width: '100%',
			toolbar: {
				show: false
			},
			sparkline: {
				enabled: false,
			}
		},
		legend: {
			position: 'top',
			horizontalAlign: 'left'
		},
		colors: ['rgba(201, 124, 12,0.6)', '#b5b5b5'],
		fill: {
			type: ['solid', 'pattern'],
			opacity: 0.6,
			pattern: {
				style: ['verticalLines', 'slantedLines', 'verticalLines', 'horizontalLines'], // string or array of strings

			}
		},
		grid: {
			padding: {
				left: 10,
				right: 10,
				bottom: 20,
				top: 10
			}
		},
		dataLabels: {
			enabled: true,
			style: {
				colors: ['white', 'black']
			}

		},
		stroke: {
			curve: "smooth"
		},
		xaxis: {
			categories: factors,
		},
		yaxis: {
			title: {
				text: 'Score'
			},
			min: 0,
			max: 100,
			decimalsInFloat: 0,
		}
	}
}

