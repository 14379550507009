import React, { useState } from "react";
import styled from "styled-components";

const SliderContainer = styled.div`
  width: 100%;
 margin-top:20px;
  font-family: "Raleway";
`;

const StyledSlider = styled.input`
  width: 100%;
  appearance: none;
  height: 10px;
  margin-bottom:10px;
  background: linear-gradient(
    to right,
    lightgrey 0%,
    #1D9A7D 100%
  );
  outline: none;
  border-radius: 5px;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #4CAF50;
    border-radius: 50%;
    cursor: pointer;
  }
`;


const Title = styled.div`
    font-size: 16px;
    font-weight:600;
    margin-bottom:10px;
    color: #2A3039;

`

const Description = styled.div`
    margin-bottom:20px;
`

const SliderWithGradient = ({value,setValue}) => {
  

  return (
    <SliderContainer>

    <Title>
        Predictive Analytics
    </Title>
    <Description>
        We've built a predictive model that can help you understand how your team's self-awareness will help to support Psychological Safety over longer periods of time. Use the slider below to see how your team's self-awareness will impact psychological safety over the next 1 - 2 years.
    </Description>
      <StyledSlider
        type="range"
        min="0"
        max="25"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <p>Simulated increase in team self awareness: <strong>{value}%</strong></p>
    </SliderContainer>
  );
};

export default SliderWithGradient;
