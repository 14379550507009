import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  tableContainer: {
    width: "100%",
    display: "flex",
    marginBottom: 20,
  },
  tableTop: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    backgroundColor: "#031A4A",
    color: "white",
  },
  tableHeader: {
    fontSize: 10,
    height: 20,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRightWidth: 1,
    textAlign: "center",
    marginTop: 5,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
  },
  tableColumn: {
    fontSize: 10,
    padding: 5,
    fontWeight: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const RankingTable = ({ title, data, headers, isFrench }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableTop}>
        <Text style={[styles.tableHeader, { width: "40%" }]}>{title}</Text>
        <Text style={[styles.tableHeader, { width: "20%" }]}>DIMENSION</Text>
        {headers.map((item) => (
          <Text
            style={[styles.tableHeader, { width: `${40 / headers.length}%` }]}
          >
            {item}
          </Text>
        ))}
      </View>
      {data.map((item) => {
        return (
          <View style={styles.tableRow}>
            <Text style={[styles.tableColumn, { width: "40%" }]}>
              {item[0]}
            </Text>
            <Text
              style={[
                styles.tableColumn,
                {
                  width: "20%",
                },
              ]}
            >
              {item[1]}
            </Text>
            {headers.map((d, i) => {
              return (
                <Text
                  style={[
                    styles.tableColumn,
                    {
                      width: `${40 / headers.length}%`,

                      textAlign: "center",
                    },
                  ]}
                >
                  {((isNaN(item[i + 2] > 0 && item[i + 2]) ||
                    item[i + 2] === -1) &&
                    "--") ||
                    (item[i + 2] > 0 && item[i + 2]) ||
                    "0.0"}
                </Text>
              );
            })}
          </View>
        );
      })}
    </View>
  );
};

export default RankingTable;
