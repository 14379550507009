import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Loader } from "semantic-ui-react";
import {calculate_correlations,multiple_regression,calculate_likelihood,multiple_regression_with_outliers,calculate_effect_plot,multiple_regression2} from "../calculations";
import EmptyData from "./components/Empty";
import Model from "./Model";
import { useSelector, shallowEqual } from "react-redux";
import ROISummary from "./ROISummary";
import ROI from "reports/Audit/ROI";


function countUniqueDimensionFactorCombos(data) {
  if (!data || !data.questions) {
      console.error("Invalid data format");
      return 0;
  }



  const uniqueCombos = new Set();

  data.questions.forEach(question => {
      if (question.id !== undefined && question.factor !== undefined) {
          const combo = `${question.id || 0}-${question.factor}`;
          uniqueCombos.add(combo);
      }
  });

  return uniqueCombos.size;
}


const Relationships = ({ core_data, data, outcomeQ, outcomeStandards,selectedFilters }) => {
  // We need to convert the data structure if it is incorrect.
  // If data is just an array, we need to convert it to an array within an array.
  // if the data is an array within an array, keep as is.
  const [dataFormat, setDataFormat] = useState();
  const [loading, setLoading] = useState(true);

  const raw_data =  useSelector(
    (state) => state.audit?.raw_data,
    shallowEqual
  );


  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
    },1000)
    setDataFormat([raw_data[0].responses])
  },[])


  

  const get_correlation_table_data = () => {
     // count the total amount of factors in the core data
     const totalFactors = core_data?.questions?.dimensions.reduce(
      (acc, dimension) => acc + dimension.factors.length,
      0
    );
    
    let _data = [];
    outcomeQ?.questions.map((item, index) => {
      const correlation_data = calculate_correlations(dataFormat[0].filter(f=>countUniqueDimensionFactorCombos(f) === totalFactors), item.id);
      _data.push({
        title: item.name,
        data: correlation_data,
      });
    });

    return _data;
  };

  const [sortedData, setSortedData] = useState([]);
  const [correlations, setCorrelations] = useState([]);
  const [model, setModel] = useState([]);
  const [selectedTab, setSelectedTab] = useState(-1);
  const get_max = (info) => {
    return Math.max(
      ...info?.map((item) => item.data.map((i) => i.correlation)).flat()
    );
  };

  const get_min = (info) => {
    return Math.min(
      ...info?.map((item) => item.data.map((i) => i.correlation)).flat()
    );
  };

  const [max, setMax] = useState(get_max(correlations));
  const [min, setMin] = useState(get_min(correlations));

  const get_title = (item) => {
    if (core_data?.questions) {
      if (core_data?.questions?.dimensions) {
        return core_data?.questions.dimensions
          .find((d, i) => i == item.dimension)
          .factors.find((factor, i) => i === item.factor)?.title;
      }

      return core_data?.questions.factors.find((d, i) => i == item.factor)
        ?.title;
    }
  };

  const addElipses = (string) => {
    if (string?.length > 45) {
      return string?.slice(0, 45) + "...";
    }
    return string;
  };

  useEffect(() => {
    if (dataFormat && core_data?.questions) {
      setCorrelations(get_correlation_table_data());
    }
  }, [dataFormat, outcomeQ,core_data,data]);

  useEffect(() => {
    setMax(get_max(correlations));
    setMin(get_min(correlations));
  }, [correlations]);

  function getGradientColor(value) {
    // Ensure the value is within the range 0 to 1
    const clampedValue = Math.max(0, Math.min(1, value));

    // Define start and end colors in RGB
    const startColor = { r: 39, g: 205, b: 167 }; // #27CDA7
    const endColor = { r: 45, g: 112, b: 226 }; // #2D70E2

    // Interpolate between the start and end colors
    const r = Math.round(
      startColor.r + (endColor.r - startColor.r) * clampedValue
    );
    const g = Math.round(
      startColor.g + (endColor.g - startColor.g) * clampedValue
    );
    const b = Math.round(
      startColor.b + (endColor.b - startColor.b) * clampedValue
    );

    // Return the color in hexadecimal format
    return `rgb(${r}, ${g}, ${b})`;
  }

  function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) {
      factor = 0.5;
    }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - result[i]));
    }
    return result;
  }

  function hexToRgb(hex) {
    var bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  }

  function rgbToHex(rgb) {
    return (
      "#" +
      rgb
        .map(function (value) {
          return ("0" + value.toString(16)).slice(-2);
        })
        .join("")
    );
  }

  function getColorValue(min, max, value) {
    var color1 = hexToRgb("#2D6FE5");
    var color2 = hexToRgb("#A20DB7");

    if (value < min) value = min;
    if (value > max) value = max;

    var factor = (value - min) / (max - min);
    var interpolatedColor = interpolateColor(color1, color2, factor);
    return rgbToHex(interpolatedColor);
  }

  const sort_factors_by_correlation = () => {
    let factors = [];
    core_data?.questions?.dimensions?.map((dimension, index) => {
      dimension.factors.map((factor) => {
        factor["dimension"] = index;
        factor["score"] = correlations.map((item) => {
          return item.data.find(
            (i) => i.factor === factor.id - 1 && i.dimension == index
          )?.correlation;
        });
        factors.push(factor);
      });
    });

    return factors.sort((a, b) => {
      // Calculate the sum of scores for object 'a'
      const sumA = a.score.reduce((acc, curr) => acc + curr, 0);

      // Calculate the sum of scores for object 'b'
      const sumB = b.score.reduce((acc, curr) => acc + curr, 0);

      // Sort in descending order by the sum of scores
      return sumB - sumA;
    });
  };

  const generate_models = () => {
     // count the total amount of factors in the core data
     const totalFactors = core_data?.questions?.dimensions.reduce(
      (acc, dimension) => acc + dimension.factors.length,
      0
    );

    const modified_data_format = dataFormat[0].filter(f=>countUniqueDimensionFactorCombos(f) === totalFactors);

    if (outcomeQ?.questions) {
      let prediction_model = outcomeQ?.questions.map((item, index) => {
        // const likelihood = calculate_likelihood(
        //   dataFormat,
        //   item.id,
        //   core_data?.questions
        // );

        const effect_data = calculate_effect_plot(
          modified_data_format,
          item.id,
          core_data?.questions
        );

        return {
          score: multiple_regression2(modified_data_format, item.id, core_data?.questions),
          outcome: item,
          effect_data: effect_data,
        };
      });
      setModel(prediction_model);
    }
  };

  useEffect(() => {
    if (dataFormat) {
      setSortedData(sort_factors_by_correlation());
      generate_models();
    }
  }, [correlations, core_data, outcomeQ, dataFormat]);

  if (loading) {
    return <LoadingContainer>
      <Loader active inline/>
      <Description>
        Loading ROI insights...
      </Description>
      </LoadingContainer>
  }


  return (
    <Container>
      <T1>ROI insights</T1>
      <Description>
        We've analyzed the key relationships between your culture and the
        critical outcomes in your organization. The table below shows which
        culture factors are responsible for driving the biggest impact on the
        outcomes that were measured.
      </Description>
     
      {/* <Tabs>
        <Tab selected={selectedTab == -1}
              onClick={() => setSelectedTab(-1)}>
          Summary
        </Tab>
        {model.map((item, index) => {
          return (
            <Tab
              selected={selectedTab == index}
              onClick={() => setSelectedTab(index)}
            >
              {item.outcome.name}
            </Tab>
          );
        })}
      </Tabs>

      {selectedTab===-1?
      <ROISummary data={model} />:

          <Model
            data={model[selectedTab]}
            core_data={core_data}
            selected={selectedTab}
            responses={dataFormat}
            outcomeStandards={outcomeStandards}
          />
    } */}


    <Content>
          {model.map((item, index) => {

      return (
        <Model
          data={item}
          core_data={core_data}
          selected={selectedTab}
          responses={dataFormat}
          outcomeStandards={outcomeStandards}
        />
      );
      } )}
    </Content>
   
    </Container>
  );
};


export default Relationships;


const Container = styled.div`
  width: 100%;
  font-family:"Raleway";
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
  max-width:800px;
  font-size:14px;
`;
 

const Row = styled.div`
  display: flex;
  width:100%;
  margin-bottom:1px;
`;

const Title = styled.div`
  width: 150px;
  color: #666d79;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight:bold;
`;

const Empty = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    font-weight:bold;
`

const Block = styled.div`
    margin-right:1px;
    width:150px;
    background-color:${props=>props.color};
    color:white;
    padding:10px;
`
const Titles = styled.div`
    display:flex;
    flex-direction:row;
    margin-left:150px;
`

const Tilt = styled.div`
display:flex;
align-items:flex-start;
height:25px;
width:150px;
margin-right:2px;
font-weight:bold;
`

const Table = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
`

const Content = styled.div`
    display:flex;
    flex-wrap:wrap;
    flex-direction:row;
    width:100%;
    gap:20px;
`

const LoadingContainer = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height:500px;
    width:100%;
    flex-direction:column;
`

// For positive scores
const growRight = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${props => props.width / 2}%;
    }
`;


// For negative scores
const growLeft = keyframes`
    0% {
        width: 0%;
        left: 50%;
    }
    100% {
        width: ${props => props.width / 2}%;
        left: calc(50% - ${props => props.width / 2}% + ${props => props.offset / 2}%);
    }
`;



const Score = styled.div`
    width:${props=>props.width/2}%;
    position:absolute;
    left:calc(50% + ${props=>props.offset/2}%);
    height:15px;
    background-color:${props=>props.score > 0 ? "#27CDA7" : "#F15062"};
    opacity: 0.5;
    border-radius: ${props=>props.score > 0 ? "0px 8px 8px 0px" :"8px 0px 0px 8px"};
    display:flex;
    align-items:center;
    justify-content:center;
    animation: ${props => props.score > 0 ? growRight : growLeft} 0.5s forwards ease;
    top:0px;
`

const Line = styled.div`
    height:102%;
    width:2px;
    background-color:#DFDFDF;
    position:absolute;
    left:calc(175px + (100% - 175px) / 2 - 1px);
    top:-5px;
`

const LineTitle = styled.div`
    position:absolute;
    left:-100px;
    display:flex;
    justify-content:center;
    top:-20px;
    width:200px;
    font-size:10px;
    font-weight:bold;
`

const Number = styled.div`
    position:absolute;
    left:${props=>props.side?'':'-40px'};
    right:${props=>props.side?'-40px':''};
    font-size:12px;
`

const Tabs = styled.div`
    display:flex;
    flex-direction:row;
    margin-top:30px;
    margin-bottom:20px;
`

const Tab = styled.div`
    padding:10px 20px;
    background-color:${props=>props.selected?'#2D70E2':'#F8FAFF'};
    color:${props=>props.selected?'white':'#2D70E2'};
    border-radius:5px;
    margin-right:10px;
    cursor:pointer;
`