import styled from "styled-components";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

import PDF from "./PDF";

const RecommendationPDF = (props) => {
  const { data, orgName } = props;
  const organization = data?.params?.org_name || orgName;
  const template = data?.params?.template;
  const tags = data?.params?.tags?.join(", ") || null;
  const date = new Date(data?.created_at);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
  }).format(date);
  return (
    <PDFViewer width={800} height={900}>
      <PDF
        data={data?.recommendation?.response || data?.recommendation}
        orgName={organization}
        template={template}
        tags={tags}
        scores={data?.survey_data?.scores}
        date={formattedDate.toString()}
      />
    </PDFViewer>
  );
};

export default RecommendationPDF;

const Container = styled.div`
  padding-left: 58px;
  margin-top: 50px;
`;

const Button = styled.div``;
