const get_most_recent = (responses) => {
    let tracker = {};
    return {
      date: null,
      compliance: responses
        .filter((i) => {
          if (!(i.name in tracker)) {
            tracker[i.name] = i.date;
          }
  
          return i.date == tracker[i.name];
        })
        .map((i) => i.compliance)
        .flat(),
       request_list: responses
        .filter((i) => {
          if (!(i.name in tracker)) {
            tracker[i.name] = i.date;
          }
  
          return i.date == tracker[i.name];
        })
        .map((i) => i.request_list)
        .flat()
    };
  };

export const calculate_engagement_by_anchor = (engagement,anchor,categories,employee_categories) =>{

    const get_category_list = categories[0]?.categories.find(f=>f.id==anchor).options
    const priority = categories[0]?.categories.find(f=>f.id==anchor).priority

    const most_recent = get_most_recent(engagement)
    return get_completion_by_anchor(get_category_list,employee_categories,most_recent,anchor)
}

const get_completion_by_anchor = (category_list,employee_categories,engagement,anchor) =>{


    return category_list.map((item,i)=>{

        let employees_in_category = employee_categories.filter(f=>{
            return f.category.find(c=>c?.name_id==anchor)?.value_id == item.id
        }).map(item=>item.employee_id)

        let completed = countOverlap(engagement.compliance,employees_in_category)
        let total = countOverlap(engagement.request_list,employees_in_category) 
        
        return {data:[{date:engagement.date,completed,total}], name:item.name}


    })
}

function countOverlap(arr1, arr2) {
    let set1 = new Set(arr1);
    let set2 = new Set(arr2);

    let overlap = [...set1].filter(x => set2.has(x));


    return overlap.length;
}

