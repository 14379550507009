import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Dial from "reports/Dashboard/Dial";
import Icon from 'assets/images/UI/material-symbols_full-stacked-bar-chart.svg'


const TopBar = () =>{

    return (

        <Container>
            <Main>
               
                <Dial/>
                <Sub>Your overall culture score</Sub>
            </Main>

            <SubComponent title={'Innovation Potential'} score={'65%'} change={'+10%'}/>
            <SubComponent title={'Organizational Agility'} score={'65%'} change={'+10%'}/>
            <SubComponent title={'Operational Efficiency'} score={'65%'} change={'+10%'}/>
            <SubComponent title={'Engagement Score'} score={'65%'} change={'+10%'}/>
           
            
        </Container>
    )
}

export default TopBar;

const SubComponent = ({title,score,change}) =>{

    return (
        <Component>
        <IMG src={Icon} />
        <Title>{title}</Title>
        <Score>{score}</Score>
        <Change>{change}</Change>

        <Insight>
            <Val>88%</Val>
            <Description>Department A</Description>
        </Insight>

        <Insight>
            <Val>66%</Val>
            <Description>Department B</Description>
        </Insight>

    </Component>
    )
}


const Container = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    height:100%;
    padding:20px;
    color:#2A3039;
`

const Main = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    width:200px;
`

const Sub = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #666D79;
    margin-top:-20px;
`

const Component = styled.div`
    
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 10px;
color: #666D79;
`
const Score = styled.div`
    font-size:20px;
    font-weight:600;
`
const Change = styled.div`
    font-size:10px;
    color:#1D9A7D;
    margin-bottom:10px;
`
const Insight = styled.div`
    display:flex;
    font-size:10px;
`

const Val = styled.div`
    font-weight:bold;
`
const Description = styled.div`
    margin-left:10px;
    color: #666D79;
`


const IMG = styled.img`
    width:25px;
`