import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon } from 'semantic-ui-react'

function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return 'hsl('+h+', '+s+'%, '+l+'%)';
}

const TR = (props) =>{

	return (
		<TRow>
			<TD width={30}> 
				<Name color={stringToHslColor(props.first_name+' '+props.last_name, 60, 50)}>{props.first_name[0]+props.last_name[0]}</Name>
				<Sub>
					<NameText>{props.first_name+' '+props.last_name}</NameText>
					<Text>{props.email}</Text>
					<Validation color={'#08c251'}>Validated</Validation>
				</Sub>
			</TD>
			<TD width={10}>
				<Text>{props.position}</Text>
			</TD>
			<TD width={20}>
				<Text>{props.department}</Text>
			</TD>
			<TD width={20}>
				<Text>{props.location}</Text>
			</TD>
			<TD width={20}>
				40 days ago
			</TD>

			<Menu>
				<Icon name="ellipsis horizontal" style={{fontSize:20}}/>
			</Menu>
		</TRow>
		)
}


const Users = () =>{

	return (
		<Container>
			<Row>
				<Title>Members</Title>

			</Row>
			<Table>
				<Top>
					<Col width={30} >Name</Col>
					<Col width={10} >Position</Col>
					<Col width={20} >Department</Col>
					<Col width={20} >Region</Col>					
					<Col width={20} >Last updated</Col>
				</Top>

				<TR 
					first_name={"Emiliano"} 
					last_name={"Void"}
					email={"test@blinkequity.com"}
					location={"Halifax, Nova Scotia"}
					department={"Operations"}
					position={"CEO"}/>

					<TR 
					first_name={"Michael"} 
					last_name={"Void"}
					email={"test@blinkequity.com"}
					location={"Halifax, Nova Scotia"}
					department={"Operations"}
					position={"CEO"}/>

						<TR 
					first_name={"Bryce"} 
					last_name={"Tully"}
					email={"test@blinkequity.com"}
					location={"Halifax, Nova Scotia"}
					department={"Operations"}
					position={"CEO"}/>

						<TR 
					first_name={"Deepesh"} 
					last_name={"Krishnan"}
					email={"test@blinkequity.com"}
					location={"Halifax, Nova Scotia"}
					department={"Operations"}
					position={"CEO"}/>

						<TR 
					first_name={"Ellen"} 
					last_name={"Bawol"}
					email={"test@blinkequity.com"}
					location={"Halifax, Nova Scotia"}
					department={"Operations"}
					position={"CEO"}/>

						<TR 
					first_name={"Heather"} 
					last_name={"Bawol"}
					email={"test@blinkequity.com"}
					location={"Halifax, Nova Scotia"}
					department={"Operations"}
					position={"CEO"}/>

					

			</Table>
		</Container>
		)
}


export default Users


const Container = styled.div`
	margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
`

const Row = styled.div`
	display:flex;
`

const Title = styled.div`
	font-size:20px;
	font-weight:bold;
	
`

const Top = styled.div`
	padding:15px;
	display:flex;
`

const Table = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	margin-top:30px;
`

const Col = styled.div`
	width:${props=>props.width+'%'};
`

const TRow = styled.div`
	display:flex;
	background-color:#f8f8f8;
	padding:15px;
	position:relative;
	align-items:center;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	padding-left:10px;
	display:flex;
	align-items:center;

`

const Name = styled.div`
	background-color:${props=>props.color};
	color:white;
	height:40px;
	width:40px;
	border-radius:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:20px;
	font-weight:bold;
`

const Text = styled.div`
	font-size:14px;
`

const NameText = styled.div`
	font-size:16px;
	font-weight:bold;
`

const Sub = styled.div`
	margin-left:10px;
	display:flex;
	flex-direction:column;
	justify-content:center;
`

const Validation = styled.div`
	font-size:12px;
	padding:0px;
	color:${props=>props.color};
	margin-top:2px;
`

const Menu = styled.div`
	position:absolute;
	right:10px;
`