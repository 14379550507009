import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Individual from "./individualComment"
import SummaryData from './SummaryDataV2'

const CommentsV2 = () =>{


	return (
		<Container>
			
			<Card>
			
			<Line/>
				<Metrics>Trust</Metrics>
				 <Question>I’m comfortable sharing my opinions and ideas on this team, even if they differ from others.</Question>
				<Date>Report for March 30th,2021</Date>
				<Main>
					<Left>
						<SummaryData/>
					</Left>
					<Right>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
						<Individual/>
					</Right>
				</Main>
			</Card>
		</Container>
		)
}


export default CommentsV2


const Question = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	margin-top:15px;
	margin-bottom:0px;
  line-height:1;
`



const Card = styled.div`
	  box-shadow: 0 4px 20px 0 #ececec;
	  width:100%;
	  display:flex;
	  flex-direction:column;
	  padding:30px 40px;
	  margin-bottom:10px;
`

const CardTop = styled.div`
	  box-shadow: 0 4px 20px 0 #ececec;
	  width:800px;
	  display:flex;
	  flex-direction:column;
	  padding:30px 40px 0px 40px;
	  margin-bottom:10px;
`

const Container = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
`


const Metrics = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:26px;
	font-weight:bold;
	color:#4a4a4a;
`

const Line = styled.div`
	width:30px;
	height:5px;
	background-color:#6a00ff;
	margin-bottom:5px;
`

const Date = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:15px;
	border-bottom:1px solid #e9e9e9;
	margin-bottom:30px;
`


const Dates = styled.div`
	display:flex;

`

const Time = styled.div`
	color:${props=>props.selected?'white':'#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props=>props.first?'1px solid #6a00ff':'none'};
	background-color:${props=>props.selected?'#6a00ff':'none'};
	cursor:pointer;
`

const Left = styled.div`
	width:30%;
`

const Right = styled.div`
	width:calc(70% - 20px);
`

const Main = styled.div`
	display:flex;
	flex-direction:row;
	justify-content:space-between;
`