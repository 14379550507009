import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress,Icon } from 'semantic-ui-react'


const Feeling = (props) =>{

	const [show,setShow] = React.useState(false)

	return (
		<Container>
			<IMG src={props.pic}/>
			<div>
				<NameContainer>
					<Name>{props.name}</Name>
					<Is>is feeling</Is>
					<Emotion>{props.emotion}</Emotion>
				</NameContainer>
				<Comment>	
					{props.comment}
				</Comment>
			  <See onClick={()=>setShow(!show)}>Show metrics <Icon name={show?"chevron up":"chevron down"}/></See>
		      <Hide show={show}>
		       
		        <DistributionTitle>Enjoyment Feeling Distribution</DistributionTitle>
		         <Chart options={data.options} series={data.series} type="bar" height={200}/>
		      </Hide>
			</div>

			
		</Container>


		)
}


export default Feeling


const Container = styled.div`
	padding:20px 10px;
	display:flex;


`

const IMG = styled.img`
	height:40px;
	width:40px;
	border-radius:20px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-weight:500;
	color: #4a4a4a;
`

const Is = styled.div`
font-family: 'Red Hat Display', sans-serif;
color: #9b9b9b;
font-weight:500;
`

const NameContainer = styled.div`
	position:relative;
	width:100%;
`	

const Comment = styled.div`
font-family: 'Red Hat Display', sans-serif;
margin-top:10px;
font-size:18px;
line-height:1.3;
margin-bottom:20px;
`

const Emotion = styled.div`
  opacity: 0.8;
  border-radius: 9.5px;
  background-color: #08c251;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: 'Red Hat Display', sans-serif;
  position:absolute;
  right:0px;
  padding:0px 10px;
  top:5px;
`
const Hide = styled.div`
  max-height:${props=>props.show?'400px':'0px'};
  overflow:hidden;
  transition:all 1s;
  background-color:white;
`

const See = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  cursor:pointer;

  &:hover{
    text-decoration:underline;
  }
`

const DistributionTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:20px;
  margin-bottom:0px;
  font-weight:500;
  color:#4a4a4a;
  margin-left:10px;
`
const data = {
          
            series: [{
              name: 'Enjoyment',
              data: [1.45, 5.42, 5.9, 0., 12.6, 18.1, 18.2, 14.16, 11.1, 6.09, 0.34, 3.88, 13.07,
                5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, 27.03, 54.4, 47.2, 43.3, 18.6, 
                48.6, 41.1, 39.6, 37.6, 9.4, 21.4, 0
              ]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                sparkline: {
              enabled: true
            },
                toolbar:{show:false}
              },
              grid:{
                padding:{
                  right:150
                }
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [{
                      from: -1,
                      to: 100,
                      color: '#08c251'
                    }]
                  },
                  columnWidth: '80%',
                }
              },
              annotations: {
        yaxis: [
          {
            y: 35,
            borderColor: '#e9e9e9',
            offsetX:150,
             strokeDashArray: 0,
            label: {
              borderColor: '#00E396',
              borderWidth:0,
               offsetX:150,
              style: {
                color: '#4a4a4a',
                fontFamily: 'Red Hat Display, sans-serif',
                fontSize:14
              },
              text: 'Average Enjoyment 35%'
            }
          }
        ]
      },
              dataLabels: {
                enabled: false,
              },
              yaxis: {
                title: {
                  text: 'Growth',
                },
                labels: {
                  formatter: function (y) {
                    return y.toFixed(0) + "%";
                  }
                }
              },
              xaxis: {
                type: 'datetime',
                categories: [
                  '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
                  '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
                  '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
                  '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
                  '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
                  '2013-07-01', '2013-08-01', '2013-09-01'
                ],
                labels: {
                  rotate: -90
                }
              }
            },
          
          
          };