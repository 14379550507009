// FactorDetail.js
import React from 'react';
import styled from 'styled-components';
import Indicator from './Indicator'
import {Progress} from 'semantic-ui-react'

const Header = styled.h1`
  font-size: 24px;
  color: #2A3039;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 20px;
`;

const SubHeader = styled.h2`
  font-size: 18px;
  color: #2A3039;
  font-family: 'Raleway', sans-serif;
  margin-top: 40px;
  margin-bottom: 10px;
`;

const AnalysisText = styled.p`
  font-size: 14px;
  color: #2A3039;
  font-family: 'Raleway', sans-serif;
`;

const Score = styled.div`
    font-size:40px;
    font-weight:bold;
    margin-botom:5px;
`

const ChangeTag = styled.div`
font-size:24px;
margin-left:20px;
border-radius:20px;
background-color:#D4F5ED;
padding:10px;
`

const ScoreArea = styled.div`
    display:flex;
    align-items:center;
    font-size:16px;
    margin-bottom:30px;
`

const T3 = styled.div`
    font-size:24px;
    margin-bottom:40px;
`

const T2 = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #2A3039;
  margin-bottom: 10px;
`

const P = styled.p`
  line-height:1.7;
  margin-bottom:20px;
`
const LI = styled.li`
  margin-bottom:15px;
  padding-left:10px;
`

const OL = styled.ol`
  margin-bottom:15px;
  padding-left:0px;
`

const UL = styled.ul`
  margin-bottom: 15px;
  padding-left: 20px;
`;

const Container  = styled.div`
  font-family: 'Raleway', sans-serif;
  margin-bottom:50px;
  padding:20px;
`
function formatElements(rawString) {
  if (!rawString) return null;

  const lines = rawString.split('\n');
  const elements = [];
  let index = 0;
  let paragraphLines = [];
  let listItems = [];
  let inList = false;

  while (index < lines.length) {
    let line = lines[index];
    index++;

    if (line.trim() === '') {
      // Empty line, flush any pending paragraphs or lists
      if (paragraphLines.length > 0) {
        const paragraphText = paragraphLines.join(' ');
        elements.push(
          <P key={`p-${index}`} dangerouslySetInnerHTML={{
            __html: paragraphText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').trim(),
          }} />
        );
        paragraphLines = [];
      }
      if (inList) {
        elements.push(
          <UL key={`ul-${index}`}>
            {listItems}
          </UL>
        );
        listItems = [];
        inList = false;
      }
      continue;
    }

    if (line.startsWith('##')) {
      // Header
      if (paragraphLines.length > 0) {
        const paragraphText = paragraphLines.join(' ');
        elements.push(
          <P key={`p-${index}`} dangerouslySetInnerHTML={{
            __html: paragraphText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').trim(),
          }} />
        );
        paragraphLines = [];
      }
      if (inList) {
        elements.push(
          <UL key={`ul-${index}`}>
            {listItems}
          </UL>
        );
        listItems = [];
        inList = false;
      }
      const headerText = line.replace(/^##\s*/, '').trim();
      elements.push(<T2 key={`h2-${index}`}>{headerText}</T2>);
    } else if (/^\s*-\s/.test(line)) {
      // List item
      if (paragraphLines.length > 0) {
        const paragraphText = paragraphLines.join(' ');
        elements.push(
          <P key={`p-${index}`} dangerouslySetInnerHTML={{
            __html: paragraphText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').trim(),
          }} />
        );
        paragraphLines = [];
      }
      inList = true;
      const listItemText = line.replace(/^\s*-\s*/, '').trim();

      // Collect subsequent indented lines as part of the list item
      let listItemLines = [listItemText];
      while (index < lines.length && lines[index].startsWith('  ')) {
        listItemLines.push(lines[index].trim());
        index++;
      }
      const fullListItemText = listItemLines.join(' ');

      listItems.push(
        <LI key={`li-${index}`} dangerouslySetInnerHTML={{
          __html: fullListItemText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').trim(),
        }} />
      );
    } else {
      // Regular text line, collect into paragraph
      if (inList) {
        // We have exited the list, so flush the list
        elements.push(
          <UL key={`ul-${index}`}>
            {listItems}
          </UL>
        );
        listItems = [];
        inList = false;
      }
      paragraphLines.push(line);
    }
  }

  // Flush any pending paragraphs
  if (paragraphLines.length > 0) {
    const paragraphText = paragraphLines.join(' ');
    elements.push(
      <P key={`p-end`} dangerouslySetInnerHTML={{
        __html: paragraphText.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').trim(),
      }} />
    );
  }

  // Flush any pending lists
  if (inList) {
    elements.push(
      <UL key={`ul-end`}>
        {listItems}
      </UL>
    );
  }

  return elements;
}

function FactorDetail({ factor }) {
  const {
    factor_name,
    outcome_name,
    score_history,
    gpt_analysis,
  } = factor;

  const name = factor_name || outcome_name;
  const change_percentage = factor.latest_score - factor.last_score;
  const isPositive = change_percentage >= 0;
  

  
  return (
    <Container>
      <Header>{name}</Header>
      

    <ScoreArea>
      Your {name.toLowerCase()} score is currently {(factor.latest_score*10).toFixed(0)}% and <strong style={{marginLeft:3}}>{isPositive ? ' increased' : ' decreased'} by {(change_percentage*10).toFixed(0)}%.</strong>

    </ScoreArea>



    {/* <T2>How does this change compare?</T2>
    <Description>
        A {change_percentage.toFixed(2)}% change in {name} is in the 87th percentile of changes when compared to any change recorded in this area.
    </Description>
    <Progress percent={87} progress color="blue"/> */}
     

      <AnalysisText>{formatElements(gpt_analysis)}</AnalysisText>
    </Container>
  );
}

export default FactorDetail;
