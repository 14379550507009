import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  tableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    borderWidth: 1,
    borderColor: "#eaeaea",
  },
  tableTop: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea",
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 600,
    display: "flex",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    height: 20,
  },
  contentSection: {},
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    borderBottomWidth: 1,
    borderBottomColor: "#eaeaea",
  },
  tableColumn: {
    width: "50%",
    display: "flex",
    alignItems: "left",
    justifyContent: "center",
    height: 20,
  },
  tableData: {
    fontSize: 10,
    fontWeight: 400,
  },
});

const SummaryTable = ({ title, average, title2, data, headers, isFrench }) => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableTop}>
        <Text
          style={[
            styles.tableHeader,
            {
              width: "48%",
            },
          ]}
        >
          {title}
        </Text>
        <Text
          style={[
            styles.tableHeader,
            {
              width: "10%",
            },
          ]}
        >
          {average.toFixed(1)}
        </Text>
        <Text
          style={[
            styles.tableHeader,
            {
              width: "45%",
            },
          ]}
        >
          {title2}
        </Text>
      </View>
      <View style={styles.tableTop}>
        <Text
          style={[
            styles.tableHeader,
            {
              width: "48%",
            },
          ]}
        >
          {isFrench ? "Moyenne" : "Average"}
        </Text>
        <Text
          style={[
            styles.tableHeader,
            {
              width: "10%",
            },
          ]}
        >
          AVG
        </Text>
        {headers?.map((item) => (
          <Text
            style={[
              styles.tableHeader,
              {
                width: `${45 / headers.length}%`,
              },
            ]}
          >
            {item}
          </Text>
        ))}
      </View>
      <View style={styles.contentSection}>
        {data?.data?.map((row) => {
          return (
            <View style={styles.tableRow}>
              <View style={[styles.tableColumn, { width: "48%" }]}>
                <Text style={styles.tableData}>{row?.factor}</Text>
              </View>
              <View style={[styles.tableColumn, { width: "10%" }]}>
                <Text style={[styles.tableData]}>
                  {isNaN(row?.factorAverage)
                    ? "--"
                    : row?.factorAverage.toFixed(1)}
                </Text>
              </View>
              {row?.factorResponses?.map((col, i) => {
                return (
                  <View
                    style={[
                      styles.tableColumn,
                      { width: `${45 / headers.length}%` },
                    ]}
                  >
                    <Text
                      style={[
                        styles.tableData,
                        { justifyContent: "center", alignItems: "center" },
                      ]}
                    >
                      {isNaN(col?.average) ? "--" : col?.average.toFixed(1)}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default SummaryTable;
