import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress } from 'semantic-ui-react'

const data = {
          
            series: [{
              name: 'Not at all',
              data: [44]
            }, {
              name: 'Not much',
              data: [53]
            }, {
              name: 'Somewhat',
              data: [12]
            }, {
              name: 'Mostly',
              data: [9]
            }, {
              name: 'Absolutely',
              data: [25]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 50,
                stacked: true,
                stackType: '100%',
                  sparkline: {
                enabled: true
              },
              },
              grid:{
                padding:{
                  top:0
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              dataLabels:{
                enabled:true
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                categories: ['Trust scores'],
              },
              fill: {
                opacity: 1,
                colors:['#e0d2fe','#bfa2fd','#8047f9','#7d42f9','#4f00f7']
              
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " times"
                  }
                }
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: -30
              }
            },
          
          
          }


const Individual = () =>{

	return (
		<Container>
      <User>
        <UserContainer>
          <Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"}/>
           <div>
           <Name>Michael Bawol</Name>
           <Average>Average trust score 3.2/5</Average>
           <View>View Profile</View>
           </div>
         </UserContainer>
        <Score>4/5</Score>
      </User>
      <DistributionTitle>Trust Score Distribution</DistributionTitle>
        <Chart options={data.options} series={data.series} type="bar" height={50}/>
        <Title>Trust Engagement</Title>
	    <Progress percent={88} progress color='violet' inverted/>

      <CommentTitle>Trust Comment</CommentTitle>
      <Comment>Tongue tenderloin pork belly andouille pork meatball doner pancetta pastrami buffalo meatloaf prosciutto picanha. Jerky pastrami beef strip steak venison buffalo shoulder landjaeger short loin picanha short ribs hamburger sirloin cupim.</Comment>
     </Container>
		)
}


export default Individual



const Container = styled.div`
	width:100%;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  padding:30px 20px;
  margin-top:20px;
`

const Average = styled.div`
   font-family: 'Red Hat Display', sans-serif;
   font-size:12px;
   margin-left:10px;
`

const View = styled.div`
    font-family: 'Red Hat Display', sans-serif;
   font-size:12px;
   margin-left:10px;

`

const UserContainer = styled.div`
  display:flex;
`
const Title = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  margin-bottom:-10px;
  font-weight:500;
`
const DistributionTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:20px;
  margin-bottom:0px;
  font-weight:500;
`

const CommentTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:-15px;
  margin-bottom:0px;
  font-weight:500;
`


const Name = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
  margin-left:10px;
  font-size:18px;
`

const Question = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;

	margin-bottom:50px;
  line-height:1;
`

const Avatar = styled.img`
  height:50px;
  width:50px;
  border-radius:25px;
`

const User = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
`

const Score = styled.div`
  height:50px;
  width:50px;
  border-radius:25px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:18px;
  color:#c3a7fd;
  border:2px solid #c3a7fd;
  font-weight:bold;
`

const Comment = styled.div`
font-family: 'Red Hat Display', sans-serif;
`