import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";

import {
  SG_DELETE_SURVEY_COMPLIANCE,
  SG_ADD_SURVEY_COMPLIANCE,
} from "constants/actions";

import { format, formatDistance } from "date-fns";
import getISODay from "date-fns/getISODay";

const DayOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const SurveyTypes = [1, 2]; // Culture and Mood Survey type

const Intelligence = (props) => {
  const { intelligence, organizationId, globalSchedule } = props;
  const dispatch = useDispatch();
  // console.log(props);
  // console.log(intelligence);
  // console.log(organizationId);
  // console.log(globalSchedule);

  function stopSurveyCompliance() {
    intelligence.map((survey_compliance) =>
      dispatch({
        type: SG_DELETE_SURVEY_COMPLIANCE,
        payload: {
          id: survey_compliance.id,
        },
      })
    );
  }

  function startSurveyCompliance() {
    SurveyTypes.map((survey_type) =>
      dispatch({
        type: SG_ADD_SURVEY_COMPLIANCE,
        payload: {
          active: true,
          global_schedule: globalSchedule.id,
          survey_type: survey_type,
          frequency: 1,
          day: globalSchedule.day,
          hour: globalSchedule.hour,
          minute: 0,
          organization: organizationId,
        },
      })
    );
   
  }

  // List of teams in mood 2 and culture 1
  // console.log(globalSchedule)
  return (
    <div style={{ width: "100%", padding: 10 }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          zIndex: 3,
          justifyContent: "space-between",
        }}
      >
        {globalSchedule && (
          <>
            {intelligence.length > 0 && (
              <p style={{ fontSize: "large" }}>
                Your next survey scheduled at{" "}
                {` ${
                  intelligence[0]?.scheduled_at &&
                  format(new Date(intelligence[0]?.scheduled_at), "dd MMM yyyy")
                }
          ${DayOfWeek[getISODay(new Date(intelligence[0]?.scheduled_at)) - 1]}
             ${format(new Date(intelligence[0]?.scheduled_at), "h")}:00 
             ${format(new Date(intelligence[0]?.scheduled_at), "aaa")}
             ${formatDistance((new Date(intelligence[0]?.scheduled_at)), new Date(), { addSuffix: true })}`}
            </p>
            )}

            <div
              style={{
                width: "100%",
                justifyContent: "center",
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button.Group>
                {intelligence.length > 0 && (
                  <Button negative onClick={() => stopSurveyCompliance()}>
                    Turn Off Survey
                  </Button>
                )}
                {/* <Button.Or /> */}
                {intelligence.length === 0 && (
                  <Button positive onClick={() => startSurveyCompliance()}>
                   Turn on Survey
                  </Button>
                )}
              </Button.Group>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Intelligence;
