import styled from "styled-components";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback 
} from "react";
import { PDFDownloadLink,PDFViewer } from '@react-pdf/renderer';

import PDF from "./PDF";

const RecommendationPDF = () =>{





    return (
        <Container>

        <PDFViewer width={800} height={900}>
            <PDF />
        </PDFViewer>
{/* 
        <PDFDownloadLink document={<PDF />} fileName="somename.pdf">
            {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download now!'
            }
            </PDFDownloadLink> */}
        </Container>
    )

}

export default RecommendationPDF;


const Container = styled.div`
`

const Button = styled.div`

`