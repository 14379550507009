import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Questions from "./Questions"
import Chart from "react-apexcharts";
import {generate_chart_standards_data,generate_chart_factor_standards,generate_question_standards} from './calculations';
import { useDispatch } from "react-redux";
import {
    SET_NEW_NAVIGATION
  } from "constants/actions";



const Benchmark = ({data,standards,navigation,categories,questions,outcomeStandards}) =>{
    const dispatch = useDispatch();
    const [series,setSeries] = useState(null)
    const [options,setOptions] = useState(null)
    const [chartData,setChartData] = useState(null)
    const [factorData,setFactorData] = useState(null)
    const [loading,setLoading] = useState(true)

    // Data to be displayed when overview is selected and culture
    useEffect(()=>{
        if(navigation.outcome && !navigation.sub_category?.id){
            let data_type = navigation?.outcome
            setChartData(generate_chart_standards_data(data,standards,navigation,categories,data_type,outcomeStandards))
        }

        if(navigation.sub_category?.id){
            let data_type = navigation?.outcome
            setChartData(generate_chart_factor_standards(data,standards,navigation,categories,data_type,questions))
        }

        if(navigation.factor?.name && navigation.factor?.name!='Overview'){
            // Calculate the standards data for the question
            // Get each question themselves, the average for the selected group
            // and the global standard

            setFactorData(generate_question_standards(data,standards,navigation,categories,questions))
        }
       
    },[data,standards,navigation,categories])

    useEffect(()=>{
        if(chartData){
            setLoading(true)
            let sorted_data = chartData.sort((a, b) => (b.diff > a.diff) ? 1 : -1)
            let _series = [{data:sorted_data.map((item)=>{
                return {x:item.name,y:Math.round(item.diff),id:item.option_id}
            })}]

            let _options = data3.options
            _options.colors = sorted_data.map((item)=>item.diff > 0 ? "#27CDA7" : "#F15062")
            _options.xaxis.categories = sorted_data.map((item)=>item.name)

            let max = Math.max(...sorted_data.map((item)=>item.diff)) + 5 

            _options.yaxis.max = max

            let min = Math.min(...sorted_data.map((item)=>item.diff))

           

            _options.yaxis.min = min

            _options.chart.events = {
                dataPointSelection: (event, chartContext, config) => {
                    let nav = {...navigation}
                    if(navigation.sub_category.name!=='Overview'){
                        nav.factor = {name:sorted_data[config.dataPointIndex].name,
                            id:sorted_data[config.dataPointIndex].option_id,
                            dimension:sorted_data[config.dataPointIndex].dimension,
                            type:'factor'}
                    }
                    else{
                        nav.sub_category = {name:sorted_data[config.dataPointIndex].name,
                            id:sorted_data[config.dataPointIndex].option_id,
                            type:'cat_option'}
                    }

                    

                    dispatch({
                        type: SET_NEW_NAVIGATION,
                        payload: nav,
                      });
                  }
              }

            setSeries(_series)
            setOptions(_options)

            setTimeout(()=>{
                setLoading(false)
            },1000)
        }
        },[chartData])


    const renderChart = () =>{
        if(loading){
            return <div>Loading...</div>
        }

        if(navigation.factor?.name && navigation.factor?.name!='Overview'){
            return <Questions data={factorData} navigation={navigation}/>
        }

         return <Chart
            options={options}
            series={series}
            type="bar"
            width={800}
        />
    }

    return (
        <Container>
                 <Title>Differences in Global Benchmarks averages</Title>
                <Description>
                    According to your collected data, the average culture score for your organization is 7.6. 
                    The following chart shows the difference between the average score for each department and the global benchmark average.
                </Description>

               {renderChart()}
        </Container>
    )
}


export default Benchmark


function adjustMin(max, min) {
    // Calculate the initial difference
    let difference = max - min;
  
    // Find the nearest lower multiple of 10 for the difference, if necessary
    let adjustedDifference = Math.floor(difference / 10) * 10;
  
    // Adjust min only if it makes min smaller
    min = max - adjustedDifference;
  
    return min;
  }
const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    width:100%;
`


const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`

const Description = styled.div`
    margin-bottom:30px;
    line-height:1.4;
`


const Help = styled.div`
    display:inline;
    padding:5px 10px;
    border:1px solid #666D79;
    color:#666D79;
    font-size:12px;
    font-family:'Raleway';
    margin-bottom:10px;
    position:absolute;
    right:0px;
    top:10px;
    cursor:pointer;
    
`


const data3 = {
	series: [
		{
			data: [10,8,4.4,-3,-8,-20,-22],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800,
			height: 400
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        grid:{
            padding:{
                bottom:60
            }
        },
        plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
		colors: ["#27CDA7", "#27CDA7", "#27CDA7",'#F15062','#F15062','#F15062','#F15062'],
		xaxis: {
			categories: [
				"Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department G"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: "#2D70E2",
				},
                rotateAlways:true,
                maxHeight: 200,
			}
		},
		yaxis: {
			min: -30,
			max: 20,
			decimalsInFloat: 0,
            forceNiceScale: true,
			title: {
				text: "Difference from the benchmark average",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
