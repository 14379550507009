import React from "react";
import styled from "styled-components";

import { useDispatch } from "react-redux";
import { Icon, Button, Modal } from "semantic-ui-react";
import { SelectContainer } from "../styled_list";

import { SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY } from "constants/actions";

export const choice = [
  { id: 1, value: 1, text: "Weekly" },
  { id: 2, value: 2, text: "Bi-Weekly" },
  { id: 3, value: 4, text: "Monthly" },
  { id: 4, value: 12, text: "Quarterly" },
];

const SurveyFrequency = (props) => {
  const { intelligenceFreq, organizationId } = props
  const dispatch = useDispatch();
  const [selectedFreq, setSelectedFreq] = React.useState(1);

  React.useEffect(() => {
    if (intelligenceFreq[0]?.frequency) {
      setSelectedFreq(
        choice.filter((fl) => fl.value === intelligenceFreq[0].frequency)[0].id
      );
    }
  }, [intelligenceFreq]);

  function MoveUp() {
    // () => UpdateActiveStatus(selectedFreq + 1)
    let newFreq = selectedFreq + 1 > 4 ? 4 : selectedFreq + 1;
    setSelectedFreq(newFreq);
    UpdateActiveStatus(newFreq);
    // console.log(choice.filter((fl) => fl.id === newFreq)[0].value)
    // UpdateActiveStatus(choice.filter((fl) => fl.id === newFreq)[0].value);
  }
  function MoveDown() {
    let newFreq = selectedFreq - 1 < 1 ? 1 : selectedFreq - 1;
    setSelectedFreq(newFreq);
    UpdateActiveStatus(newFreq);
    // console.log(choice.filter((fl) => fl.id === newFreq)[0].value)
    // UpdateActiveStatus(choice.filter((fl) => fl.id === newFreq)[0].value);
  }
  
  // console.log(props)
  // console.log(intelligenceFreq)

  function UpdateActiveStatus(newFreq) {
    // console.log(newFreq)

    intelligenceFreq.map(freq => 
      dispatch({
        type: SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY,
        payload: {
          id: freq.id,
          frequency: choice.filter((fl) => fl.id === newFreq)[0].value,
          organization: organizationId,
        },
      })
    )
  }

  return (
    <>
      <SelectContainer>
        {selectedFreq
          ? choice.filter((fl) => fl.id === selectedFreq)[0].text
          : ""}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            right: 5,
            cursor: "pointer",
          }}
        >
          <Icon
            name="chevron up"
            size={"small"}
            style={{ color: "#4a4a4a" }}
            onClick={MoveUp}
            disabled={selectedFreq === 4}
          />
          <Icon
            name="chevron down"
            size={"small"}
            style={{ color: "#4a4a4a" }}
            disabled={selectedFreq === 1}
            onClick={MoveDown}
          />
        </div>
      </SelectContainer>
    </>
  );
};

export default SurveyFrequency;

export const ContainerCard = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
`;
