import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Explanation from "./Explanation"


const Insights = () =>{
    const [selected,setSelected] = useState(null)

    if(selected){    
        return <Explanation/>
    }

    return (
        <Container>
            <Title>
                The key insights driving your organization
            </Title>
            <Text>
                We've scanned all the metrics in your organization, and have sorted through all the key issues.
            </Text>

            <Row>   
                <InsightComponent onClick={()=>setSelected(true)}/>
                <InsightComponent onClick={()=>setSelected(true)}/>
                <InsightComponent onClick={()=>setSelected(true)}/>
            </Row>
        </Container>
    )
}


export default Insights;

const InsightComponent = ({onClick}) =>{

    return (
        <InsightCard>
        <InsightTitle>
            Low employee engagement in the sales department
        </InsightTitle>
        <InsightText>
            Your employee engagement has been steadily increasing over the last 6 months.
        </InsightText>
        <T2>Key Metrics</T2>
        <Stats>
            <Stat>
                <StatTitle>
                    Score
                </StatTitle>
                <StatValue>
                    6.4
                </StatValue>
            </Stat>
            <Stat>
                <StatTitle>
                   Change
                </StatTitle>
                <StatValue>
                    -6.3%
                </StatValue>
            </Stat>

            <Stat>
                <StatTitle>
                    # of employees impacted
                </StatTitle>
                <StatValue>
                    36
                </StatValue>
            </Stat>
            <Stat>
                <StatTitle>
                   Cohort difference
                </StatTitle>
                <StatValue>
                    -35%
                </StatValue>
            </Stat>

        </Stats>

        <Button onClick={()=>onClick()}>Generate Explanation</Button>
    </InsightCard>
    )
}


const Container = styled.div`
    width:100%;
    font-family:'Raleway', sans-serif;
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`

const Text = styled.div`
    margin-bottom:30px;
    line-height:1.4;
    font-size:16px;
    color:#666D79;
`

const Row = styled.div`
    display:flex;
    width:100%;
    flex-wrap:wrap;
    flex-direction:column;
`

const InsightCard = styled.div`
    width:100%;
    padding:20px;
    border:1px solid #E0E0E0;
    border-radius:5px;
    margin-bottom:20px;
    position:relative;
`

const InsightTitle = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`
const InsightText = styled.div`
    font-size:14px;
    color:#666D79;
    line-height:1.4;
`

const Stats = styled.div`
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
`

const Stat = styled.div`
    width:calc(50% - 10px);
    margin-top:10px;
`
const StatTitle = styled.div`
    font-size:12px;
    color:#666D79;
`
const StatValue = styled.div`
    font-size:16px;
    font-weight:bold;
    color:#666D79;
`

const T2 = styled.div`
    font-size:10px;
    font-weight:bold;
    margin-top:20px;
    color:#666D79;
    text-transform:uppercase;
`

const Button = styled.div`
    background:#666D79;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    font-size:14px;
    font-weight:bold;
    margin-top:20px;
    display:inline-block;
    cursor:pointer;
    transition:all 0.3s;
    &:hover{
        background:#333;
    }

    position:absolute;
    right:20px;
    bottom:20px;
`