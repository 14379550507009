import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";

const ThemeCard = (props) =>{

	return (
		<Theme>
			{/*<Number color={props.color}>{props.i}</Number>*/}
			  <Score>
		              {props.impact}
		       <Impact>Impact</Impact>
		        </Score>
			<div>
			<ThemeTitle>{props.theme}</ThemeTitle>
			 <Keywords>
         <KeyTitle>Keyword Analytics</KeyTitle>
				{props.keys.map(item=><Key color={props.color}>{item}</Key>)} 
			 </Keywords>
			<Description>Bacon ipsum dolor amet sausage jowl leberkas venison boudin. Alcatra pork belly pig pastrami, jerky meatball pork chop ham prosciutto filet mignon. Shoulder filet mignon shankle tail prosciutto doner andouille ball tip beef short ribs. Pork meatloaf porchetta, pancetta tail ribeye spare ribs shank t-bone venison brisket.</Description>
			 <ChartTitle>Theme sentiment</ChartTitle>
			 <Chart options={data2.options} series={series1} type="bar" height={30}/>
			
			</div>
		</Theme>
		)
}


const Overview = () =>{

	return (
		<CardContent>
					<Title>Key Themes</Title>
					<Dated>May 31st, 2021</Dated>
					<ThemeCard key="1" color={'#7e24ea'} i={1} theme={"Uncertainty"} impact={'High Positive'} keys={['season trying win','bit worn end','great team performing']}/>
					<ThemeCard key="2" color={'#7e24ea'} i={2} theme={"Future events/goals"} impact={'High Negative'} keys={['season eager goal','losing games team','drained stress injury']}/>
					<ThemeCard key="3" color={'#7e24ea'} i={3} theme={"Connection, belonging"} impact={'Moderate Positive'} keys={['impact ready battle','improved season eager','eager goal points']}/>
		</CardContent>
		)
}

export default Overview




const CardContent = styled.div`
	margin-top:50px;
`

const Dated = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	color:#4a4a4a;
	margin-top:5px;
`
const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:20px;
	font-weight:bold;
	width:100%;
`
const KeyTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  color:#9b9b9b;
  font-size:14px;
  margin-top:5px;
  margin-bottom:5px;

`

const Theme = styled.div`
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:20px;
	width:100%;
	display:flex;
	flex-direction:row;
	border-radius:8px;
	margin-top:10px;
	max-width:800px;
	position:relative;

`

const ThemeTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
	 color: #4a4a4a;
`	

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	color: #4a4a4a;
`

const Number = styled.div`
	font-size:24px;
	font-weight:bold;
	height:50px;
	width:50px;
	min-width:50px;
	margin-right:10px;
	border-radius:25px;
	border:${props=>'2px solid '+props.color};
	display:flex;
	align-items:center;
	justify-content:center;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  font-weight:bold;
  position:absolute;
  top:15px;
  right:20px;

`

const Impact = styled.div`
  font-size:9px;
  font-weight:900;
  text-transform:uppercase;
  margin-top:-3px;
`

const ChartTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  font-size:16px;
  color: #4a4a4a;
  margin-top:10px;
`

const Keywords = styled.div`
	font-size:16px;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:10px;
`

const Key = styled.span`
	background-color:${props=>props.color};
	color:white;
	padding:2px 10px;
	border-radius:15px;
	font-size:13px;
	margin-right:1px;
`

 const data2 = {
          
            options: {
              chart: {
                type: 'bar',
                height: 30,
                stacked: true,
                stackType: '100%',
                  sparkline: {
                enabled: true
              },
              },
              grid:{
                padding:{
                  top:0
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              dataLabels:{
                enabled:true
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                categories: ['Trust scores'],
              },
              fill: {
                opacity: 0.3,
                colors:['#ff2d55','#e4e4e4','#08c251']
              
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " times"
                  }
                }
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: -30
              }
            },
          
          
          }
const series1= [{name: 'Negative',data: [15]}, {
              name: 'Neutral',
              data: [10]
            }, {
              name: 'Positive',
              data: [75]
            }]


