import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown, Loader } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_GET_REPORT_TEMPLATE } from "constants/actions";

const TemplateSelection = (props) => {
  const dispatch = useDispatch();
  const { setSelectedTemplate, selectedTemplate } = props;
  const [selectedRecoTemplate, setSelectedRecoTemplate] =
    useState(selectedTemplate);
  const [loading, setLoading] = useState(false);
  const [templateOptions, setTemplateOptions] = useState([]);

  const get_report_template = useSelector(
    (state) => state.report_template,
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_REPORT_TEMPLATE,
    });
  }, [dispatch]);

  useEffect(() => {
    if (get_report_template?.report_template) {
      const formattedTemplates = get_report_template?.report_template?.map(
        (template, i) => {
          return {
            key: i,
            text: template?.name,
            value: template?.id,
          };
        }
      );

      setTemplateOptions(formattedTemplates);
      setLoading(false);
    }
  }, [get_report_template]);

  return (
    <StepContainer>
      {loading && <Loader />}
      {templateOptions && (
        <>
          <StepTitle>Template</StepTitle>
          <Label>
            Select a template for your recommendation, this will determine the
            format of your report.
          </Label>
          <Dropdown
            placeholder="Template selection"
            fluid
            selection
            value={selectedRecoTemplate}
            onChange={(e, { value }) => {
              setSelectedRecoTemplate(value);
              setSelectedTemplate(value);
            }}
            options={templateOptions}
          />
        </>
      )}
    </StepContainer>
  );
};

const StepContainer = styled.div``;

const StepTitle = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Label = styled.div``;

export default TemplateSelection;
