import React from 'react';
import styled from 'styled-components';
import {Loader} from 'semantic-ui-react';

const chartLoading = () =>{

    return(
        <Container>
            <Loader active inline='centered' />
        </Container>
    )
}

export default chartLoading;


const Container = styled.div`
    width:100%;
    height:350px;
    display:flex;
    align-items:center;
    justify-content:center;
`