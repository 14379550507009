import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import Select from "react-select";
import { useDispatch } from "react-redux";
import { SG_UPDATE_EMPLOYEE_STATUS } from "constants/actions";
import { Button } from "semantic-ui-react";

const ChangeStatusModal = ({
  EmployeeStatusList,
  selectedUserRow,
  setSelectedUserRow,
}) => {
  const dispatch = useDispatch();
  const [deactiveReason, setDeactiveReason] = useState("");
  const [selectedUserStatus, setSelectedUserStatus] = useState([]);

  const [showComponent, setShowComponent] = useState(false);

  function UpdateAllEmployees() {
    dispatch({
      type: SG_UPDATE_EMPLOYEE_STATUS,
      payload: {
        employee_ids: selectedUserRow,
        status: selectedUserStatus?.value,
        reason: deactiveReason,
      },
    });

    setSelectedUserRow([]);
  }

  useEffect(() => {
    if (selectedUserRow.length > 0) {
      setShowComponent(true);
    } else {
      const timer1 = setTimeout(() => {
        setShowComponent(false);
      }, 500);
      return () => clearTimeout(timer1);
    }
  }, [selectedUserRow]);

  return (
    <CSSTransition
      in={showComponent}
      timeout={500}
      classNames="fade"
      unmountOnExit
    >
      <ModalWrapper>
        <AnimatedContainer>
          <InputDescription>Please select a reason:</InputDescription>
          <InputContainer>
            <Select
              placeholder="Select Employee Status"
              onChange={(e) => setSelectedUserStatus(e)}
              value={selectedUserStatus}
              options={EmployeeStatusList}
            />
          </InputContainer>

          <InputDescription>Please enter a Description:</InputDescription>
          <InputContainer>
            <RowData
              value={deactiveReason}
              placeholder="Comments"
              onChange={(s, t) => {
                setDeactiveReason(s.target.value);
              }}
            />
          </InputContainer>

          <Button onClick={UpdateAllEmployees}>Update</Button>
        </AnimatedContainer>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default ChangeStatusModal;

const ModalWrapper = styled.div`
  position: relative;
`;

const AnimatedContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  background-color: #f5f5f5;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
`;

const InputDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  align-self: center;
  color: #414141;
`;

const InputContainer = styled.div`
  width: 35%;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
`;

const RowData = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 200px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-size: 12px;
  line-height: 24px;
  align-items: center;
  color: #9e9fa1;
  border: 1px solid #dfdfdf;
`;
