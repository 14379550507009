import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon,Loader} from 'semantic-ui-react'
import TopProgress from './TopProgress'
import Loading from './Loading'
import FullIntroduction from './FullIntro'

const Create = () =>{
    const [step,setStep] = useState(0)
    const steps = [
        'Introduction', 'Select a template','Build your story'
    ]

    const [loading,setLoading] = useState(false)
    const [full_loading,setFullLoading] = useState(false)

    const next = () =>{
        setLoading(true)
        setStep(step+1)
        setTimeout(()=>{
            setLoading(false)
        },500)
    }

    const start = () =>{
        setFullLoading(true)
        setStep(step+1)
        setTimeout(()=>{
            setFullLoading(false)
        },500)
    }


   

    const getComponent = () =>{

        if(loading){
            return <Loading/>
        }

        switch(step){

            default:
                return <div>Step {step}</div>
        }
    }

    if(step ==0){
        return <FullIntroduction next={start}/>
    }

    if(full_loading){
        return <div style={{width:'100%',height:'100vh'}}><Loader active /></div>
    }

    return (
        <Container>
        <Side>
            <Icon
            name="edit"
            style={{ fontSize: 30, marginBottom: 5, marginLeft: 0 }}
            />
            <Title>Lets build a story</Title>
            <Description>
             Choose your template and data points to build your story.
            </Description>
            <TopProgress step={step} steps={steps} />
        </Side>

            <Content>
                {getComponent()}
            <ButtonContainer>
                 <Button onClick={()=>next()}>{step==0?'Get Started':'Next'}</Button>
            </ButtonContainer>
            </Content>
        </Container>
        )
}

export default Create




const Container = styled.div`
width:100%;
display:flex;
flex-direction:row;
margin-top:20px;
max-width:1200px;
`

const Content = styled.div`
  width:calc(100% - 400px);
  font-family:'Raleway';
  padding:30px;
  padding-left:50px;
  margin-top:50px;
  border-left:1px solid #E0E0E0;
`



const Side = styled.div`
  width:450px;
  padding:20px;
  padding-top:30px;
  padding-left:40px;
`


const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 10px;
    color:#2A3039;
`

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    font-family: 'Raleway';
    margin-bottom:30px;
`

const Button = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`