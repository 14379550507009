import styled, { keyframes } from 'styled-components';
import React from 'react';
import emotions from '../../../data/emotions.json'
import { Icon } from 'semantic-ui-react'
import { CultureTitle } from "data/leadership";

const Card = styled.div`
	position:relative;
	width:100%;
	margin-bottom:5px;
	padding-right:10px;
	height:400px;
	background-color:rgba(106, 0, 255,0.02);
	display:flex;
	align-items:center;
	flex-direction:column;
	margin-top:13px;
	max-height:${props=>props.open? '800px':0};
	overflow:hidden;
	transition:all 600ms;
`

const MoodScore = styled.div`
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	padding:10px;
	position:absolute;
	top:-20px;
	left:calc(50% - 85px);
	width:250px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column
`
const Select = styled.div`
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	position:absolute;
	top:0px;
	margin-left:${props=>props.left};
	height:${props=>props.height+'px'};
	width:30px;
	transition:margin 500ms;
	background-color:white;
`

const Score = styled.div`
	font-size: 30px;
  font-weight: bold;
  color: #4a4a4a;
    font-family: 'Red Hat Display', sans-serif;
    margin-bottom:5px;
`
const Overall = styled.div`
	font-size: 11px;
  font-weight: 900;
    color: #4a4a4a;
    font-family: 'Red Hat Display', sans-serif;
    text-transform:uppercase;
`

const SubOverall = styled.div`
	font-size: 11px;
  font-weight: bold;
   font-family: 'Red Hat Display', sans-serif;
  color: #6a00ff;
`

const Trends = styled.div`
	font-size: 23px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
`

const DateCard = styled.div`
	height:43px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	font-size: 16px;
  line-height: 1.31;
  text-align: center;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  border-radius: 14.5px;
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  width:90%;
  margin-top:20px;
  margin-bottom:30px;
`
const DateAxis = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding:5px 5px 5px 20px;
`

const BottomDate = styled.div`
	  font-size: 16px;
  font-weight: ${props=>props.current? 'bold':'500'};
  line-height: 1.19;
  text-align: center;
  color: ${props=>props.current? 'black':'#9b9b9b'};
  
  font-family: 'Red Hat Display', sans-serif;
`

function catmullRom2bezier(points) {
    var result = [];
    for (var i = 0; i < points.length - 1; i++) {
        var p = [];

        p.push({
            x: points[Math.max(i - 1, 0)].x,
            y: points[Math.max(i - 1, 0)].y
        });
        p.push({
            x: points[i].x,
            y: points[i].y
        });
        p.push({
            x: points[i + 1].x,
            y: points[i + 1].y
        });
        p.push({
            x: points[Math.min(i + 2, points.length - 1)].x,
            y: points[Math.min(i + 2, points.length - 1)].y
        });

        // Catmull-Rom to Cubic Bezier conversion matrix
        //    0       1       0       0
        //  -1/6      1      1/6      0
        //    0      1/6      1     -1/6
        //    0       0       1       0

        var bp = [];
        bp.push({
            x: ((-p[0].x + 6 * p[1].x + p[2].x) / 6),
            y: ((-p[0].y + 6 * p[1].y + p[2].y) / 6)
        });
        bp.push({
            x: ((p[1].x + 6 * p[2].x - p[3].x) / 6),
            y: ((p[1].y + 6 * p[2].y - p[3].y) / 6)
        });
        bp.push({
            x: p[2].x,
            y: p[2].y
        });
        result.push(bp);
    }

    return result;
}

function makePath(points) {
	if (points.length === 0) {
    return null
  }
    var result = "M" + points[0].x + "," + points[0].y + " ";
    var catmull = catmullRom2bezier(points);
    for (var i = 0; i < catmull.length; i++) {
        result += "C" + catmull[i][0].x + "," + catmull[i][0].y + " " + catmull[i][1].x + "," + catmull[i][1].y + " " + catmull[i][2].x + "," + catmull[i][2].y + " ";
    }

    return result;
}

const height = 230
const months =['Jan', 'Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']


function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const MobileChart =(props) =>{
	const { culture_data } = props;

	const calculateCoordinates = () =>{
		return emotions.map((item,i)=>item.mood).slice(0,5)
	}

	const calculateDates = () =>{
		return emotions.map((item,i)=>[months[new Date(item.date).getMonth()],new Date(item.date).getDate()]).slice(0,5)
	}

	const [position, setPosition] = React.useState(emotions.length-1)
	const [width, setWidth] = React.useState(1000)
	const [coordinates,setCoordinates] = React.useState([])
	const [teamData,setTeamData] = React.useState([])
	const [dates,setDate] = React.useState(calculateDates())
	 const [newIndex, setNewIndex] = React.useState(0);

	 const [index, setIndex] = React.useState(CultureTitle.indexOf(props.type));
  		const [type, setType] = React.useState(CultureTitle[index]);


	React.useEffect(()=>{
	const _TrendRow = [];
	 CultureTitle.map((cl) => {
      const _titleRow = [];
      culture_data.map((cd) => {
        let team = cd.questions?.filter((lst) => lst.name === type)[0]?.score;
        if (cd) {
          _titleRow.push({
            id: cd.id,
            date: cd.on_date,
            team,
            title: cl
          });
        }
        // console.log(_titleRow);
        return _titleRow
      });
      // console.log(_titleRow);
      _TrendRow.push(_titleRow.reverse());
      return _TrendRow;
    });

	 setTeamData(_TrendRow)

	},[culture_data,type])

	  React.useEffect(() => {
    if (teamData.length > 0) {
      const calculateCoordinates = (index) => {
      return teamData[index].map((item, i) => item.team);
    };
    const calculateDates = (index) => {
      return teamData[index].map((item, i) => [
        months[new Date(item.date).getMonth()],
        new Date(item.date).getDate(),
      ]);
    };
    setCoordinates(calculateCoordinates(newIndex));
    setDate(calculateDates(newIndex))
    if (newIndex === 0) {
       setPosition(teamData[newIndex].length - 1)
    }
   

    }


   

  }, [teamData, newIndex]);


	 React.useEffect(()=>{
	 	let _index = CultureTitle.indexOf(props.type)
	 	setIndex(_index)
	 	setType(CultureTitle[_index])
	 },[props.type])


	const pathBuilder2 = (graph) =>{
    var points = [];
    for (var i = 0; i < graph.length; i++) {

        points.push({x: i*23.75+5, y: (100-graph[i])/100*height});
    }
    return points
	}

	const shapeBuilder2 = (graph) =>{
    var points = [];

    for (var i = 0; i < graph.length; i++) {
        points.push({x: i*23.75+5, y: (100-graph[i])/100*height});
    }

    let path = makePath(points)
    path = path + "V "+(height) +"H 5"



    return path
	}

	const load = (dir)=>{
		if(dir<0){
			setPosition(position==0? position: position-1)
			props.setPosition(position==0? position: position-1)
		}else{
			setPosition(position==coordinates.length-1? position: position+1)
			props.setPosition(position==coordinates.length-1? position: position+1)
		}
	}

	const move = (i) =>{
		setPosition(i)
		props.setPosition(i)
	}

	 const getDiff = () =>{
  		if(culture_data.length<2){
  			return ' no change '
  		}

  		let score1 = culture_data[0].questions.filter(f=>f.name==type)[0].score
  		let score2 = culture_data[1].questions.filter(f=>f.name==type)[0].score
  		let diff = score1 - score2

  		if(diff >0){
  			return ` a ${diff}% increase `
  		}
  		else if(diff<0){
  			return ` a ${Math.abs(diff)}% decrease `
  		}

  		return ' no change '

  	}


		return(
		<div style={{width:'100%'}}>

	      <Card open={props.open}>
				<DateCard>You had <strong>&nbsp;{getDiff()}&nbsp;</strong> in {type}</DateCard>
			<div style={{height:height,position:'relative',width:'100%'}} >

			 <svg width={'100%'} height={height} style={{position:"absolute",left:0,top:0}}>

	      	 <line x1="5.5%" y1={height} x2={'100%'} y2={height} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	      	<line x1="5.5%" y1={height-1*height/5} x2={'100%'} y2={height-1*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-2*height/5} x2={'100%'} y2={height-2*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-3*height/5} x2={'100%'} y2={height-3*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-4*height/5} x2={'100%'} y2={height-4*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-5*height/5} x2={'100%'} y2={height-5*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       </svg>

	       <svg width={'100%'} height={height} viewBox={"0 0 100 "+height} preserveAspectRatio="none" style={{position:"absolute",left:0,top:0}}>
	      		 <defs>
			    <linearGradient id="myGradient" gradientTransform="rotate(90)">
			      <stop offset="5%"  stopColor="#6a00ff" />
			      <stop offset="95%" stopColor="rgba(106, 0, 255,0.3)" />
			    </linearGradient>
			  </defs>
	     <path d={makePath(pathBuilder2(coordinates))} stroke="#6a00ff" fill="transparent" strokeWidth="4" vectorEffect="non-scaling-stroke" style={{ transition: "0.4s" }}/>
	      		<path d={shapeBuilder2(coordinates)}  fill="rgba(106, 0, 255,0.05)" style={{ transition: "0.4s" }}/>
	      </svg>

	        <svg width={'100%'} height={height} style={{position:"absolute",left:0,top:0}}>

	       	{coordinates.map((item,i)=>{
	       		console.log(item)
	       		if(i!=position){
	       			return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*23.75+5+'%'} cy={(100-item)+'%'} r="4" fill="rgb(186, 186, 186)" style={{transition:'.6s ease'}}/>
	       			<circle cx={i*23.75+5+'%'} cy={(100-item)+'%'} r="8" fill="rgba(186, 186, 186,0.3)" style={{transition:'.6s ease'}} />
	       			<circle cx={i*23.75+5+'%'} cy={(100-item)+'%'} r="12" fill="rgba(186, 186, 186,0.1)" onClick={()=>move(i)} style={{transition:'.6s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       		}
	       		return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*23.75+5+'%'} cy={(100-item)+'%'} r="4" fill="#6a00ff" style={{transition:'.6s ease'}}/>
	       			<circle cx={i*23.75+5+'%'} cy={(100-item)+'%'} r="8" fill="rgba(106, 0, 255,0.3)" style={{transition:'.6s ease'}}/>
	       			<circle cx={i*23.75+5+'%'} cy={(100-item)+'%'} r="12" fill="rgba(106, 0, 255,0.1)" onClick={()=>move(i)} style={{transition:'.6s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       	})}


	      	 </svg>
	      	 </div>
	      	 <DateAxis>
	      	 	{dates.map((item,i)=>{
	      	 		return <BottomDate current={dates.length==i+1}>{dates[i][1]}<br/> {dates[i][0]}</BottomDate>
	      	 	})}
	      	 </DateAxis>
	      </Card>
	    
	      </div>

			)

}

export default MobileChart