import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const SectionTitle = (props) => {
  const { title, subtitle } = props;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {subtitle && subtitle?.length > 0 && (
        <Text style={styles.subtitle}>{subtitle}</Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#2d70e2",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
    color: "#7e7e7e",
  },
});

export default SectionTitle;
