import styled, { keyframes } from "styled-components";
import React, { useEffect } from "react";
import { SG_GET_CULTURE_DATA, CLEAR_UPDATE_CULTURE_COMMENTS } from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import IL from "assets/images/top.png";
import culture_analytics from "data/culture_analytics.json";
import { Icon } from "semantic-ui-react";
import TrendsChart from "./Trends";

import AddComment from "./add_comment";

import CommentComp from "components/Comment"

// const CommentComp = ({employee_id, comment, ...props}) => {
//   console.log(props);
//   return (
//     <Container>
//       <EmployeeAvatar employee_id={employee_id} />
//       <div style={{ position: "relative", width: "100%",  }}>
//         <Name>
//           {props.name} <SubDate>{props.date}</SubDate>
//         </Name>
//         <Comment>{comment && comment}</Comment>
//       </div>
//     </Container>
//   );
// };

const Trends = (props) => {
  const {culture_data, type, employee_id} = props
  const [visibility, setVisibility] = React.useState(true);


  const getDiff = () =>{
  		if(culture_data.length<2){
  			return ' no change '
  		}

  		let score1 = culture_data[0].questions.filter(f=>f.name==type)[0].score
  		let score2 = culture_data[1].questions.filter(f=>f.name==type)[0].score
  		let diff = score1 - score2

  		if(diff >0){
  			return `a ${diff}% increase `
  		}
  		else if(diff<0){
  			return `a ${Math.abs(diff)}% decrease `
  		}

  		return 'no change'

  }

  return (
    <TrendContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Dated>
          <Icon name="signal" style={{ fontSize: 10 }} /> Trends
        </Dated>
        <Dated
          style={{ cursor: "pointer" }}
          onClick={() => setVisibility(!visibility.toString())}
        >
          {visibility ? "Hide" : "Show"}
        </Dated>
      </div>
      <ContentContainer visibility={visibility.toString()}>
        <Logo src={IL} />
        <div>
          <InnerLogic>innerlogic</InnerLogic>
          <Comment>
           Your team had {getDiff()} in {type} this week.
          </Comment>
          <TrendsChart 
          culture_data={culture_data}
          employee_id={employee_id}
          type={type}
          trigger={<View>View trends</View>} 
          // type={props.type}
          />
        </div>
      </ContentContainer>
    </TrendContainer>
  );
};

const Comments = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);
  const [cultureId, setCultureId] = React.useState(0)
  const [reportComments, setReportComments] = React.useState([]);
  const [newComments, setNewcomments] = React.useState(false);

    useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: SG_GET_CULTURE_DATA });
    dispatch({ type: CLEAR_UPDATE_CULTURE_COMMENTS })
  }, [dispatch, newComments]);

  const { culture_data, get_auth, get_report_comments,
  updated_comments } = useSelector(
    (state) => ({
      culture_data: state.culture.culture,
      get_report_comments: state.culture.reportComments,
      updated_comments: state.culture.updatedComments,
      get_auth: state.auth,
    }),
    shallowEqual
  );
// console.log(props)

  useEffect(() => {
	setData(culture_data[0]?.survey_comments.filter(
		co => co.name === props.type))
    
    setReportComments(culture_data[0]?.report_comments.filter(
      co => co.name === props.type))

    setCultureId(culture_data[0]?.id)

  },[culture_data, props.type])
  
  useEffect(() => {
    setNewcomments(updated_comments)
 
  },[updated_comments])

  // console.log(get_report_comments)

  // console.log(data)

  return (
    <Card>
      <Title>{props?.type} Comments</Title>
   <Dated></Dated>
            <Line />

            <Trends 
            type={props.type} 
            culture_data={culture_data}
            employee_id={get_auth.employee_id} />

            <AddComment 
            name={props.type}
            cultureId={cultureId}
            employee_id={get_auth.employee_id} /> 
      
            {data?.map((item, i) => {
                  return ( <CommentComp
                      employee_id={item.employee_id}
                      comment={item.comment}
                      showFace={true}
                      key={i}
                    />)
              })
              }    
            
            {reportComments?.map((item, i) => {
                  return ( <CommentComp
                      employee_id={Number(item.employee_id)}
                      comment={item.comment}
                      key={i}
                    />)
              })
              }   
        
      
    </Card>
  );
};

export default Comments;

const Card = styled.div`
  border-radius: 8px;

  width: 30%;
  padding: 12px;
  position: absolute;
  right: 0px;

  @media (min-width: 768px) {
    box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  }

  @media (max-width: 786px) {
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    right: 0;
  }
`;
const Title = styled.div`
  font-size: 23px;
  color: #4a4a4a;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Red Hat Display", sans-serif;
  margin: 54px 0 44px 0;

  @media (max-width: 768px) {
    margin: 10px 0 30px 0;
    font-size: 18px;
  }
`;
const Dated = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: #696969;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const Line = styled.div`
  height: 1px;
  background-color: #e9e9e9;
  width: 100%;
  margin-bottom: 14px;
`;

const Avatar = styled.img`
  height: 44px;
  width: 44px;
  border-radius: 22px;
  margin-bottom: 10px;
`;
const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
`;

const Comment = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.31;
  color: #9b9b9b;
  margin-top: 5px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  transition: max-height 1s ease-out, opacity 1s ease-out;
  position: relative;
  margin-bottom: 40px;
`;

const SubDate = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 28px;
  color: #9b9b9b;
  font-family: "Red Hat Display", sans-serif;
`;

const TrendContainer = styled.div`
  width: calc(100% - 8px);
  background-color: rgba(106, 0, 255, 0.1);
  padding: 16px;
  box-sizing: content-box;
  margin-left: -12px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 23.5px;
  margin-right: 12px;
`;

const View = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
  margin-top: 12px;
`;

const InnerLogic = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  color: #4a4a4a;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-height: ${(props) => (props.visibility ? "200px" : 0)};
  transition: max-height 0.5s;
  overflow: hidden;
  margin-top: ${(props) => (props.visibility ? "16px" : 0)};

  @media (max-width: 1000px) {
    height: ${(props) => (props.visibility ? "150px" : 0)};
  }
`;
