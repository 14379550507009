import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";

const Plan = ({ question }) => {
	return (
		<Container>
			<Title>Create a plan</Title>
			<Top>Culture Pillar </Top>
			<Question>{question}</Question>
			<T1>Creating Urgency:</T1>
			<Area>
				<Q>
					Why is this a meaningful measure to improve? What are the
					benefits of improving in this culture area?
				</Q>
				<Text />
			</Area>

			<Area>
				<Q>
					What do you predict is going to happen if this doesn’t
					change? What is the cost of not improving in this area?
				</Q>
				<Text />
			</Area>

			<T1>Possible causes:</T1>
			<Area>
				<Q>
					What do you believe may be leading to the current level of
					performance in this area within your culture?
				</Q>
				<Text />
			</Area>

			<T1>Action plan:</T1>
			<Area>
				<Q>
					List up to 3 strategies or behaviors that will be
					implemented, introduced, or changed in your environment to
					improve this culture area. Also identify who will be
					responsible for leading each item (person, people, or
					groups):{" "}
				</Q>
				<TextArea>
					<T2>Strategy #1</T2>
					<Text2 />
					<T2>Who is responsible for leading this strategy?</T2>
					<Text3 />

					<T2>Strategy #2</T2>
					<Text2 />
					<T2>Who is responsible for leading this strategy?</T2>
					<Text3 />

					<T2>Strategy #3</T2>
					<Text2 />
					<T2>Who is responsible for leading this strategy?</T2>
					<Text3 />
				</TextArea>
			</Area>
		</Container>
	);
};

export default Plan;

const Container = styled.div`
	width: 100%;
	padding: 20px;
	margin-top: 30px;
	font-family: "Barlow", sans-serif;
	margin-bottom: 60px;
`;

const Top = styled.div`
	font-size: 12px;
	color: lightgrey;
	text-transform: uppercase;
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 30px;
`;

const T1 = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
`;

const T2 = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 14px;
	margin-bottom: 5px;
	font-weight: 600;
`;

const Q = styled.div`
	font-size: 18px;
	line-height: 1.4;
	font-weight: 300;
	width: 400px;
	margin-right: 20px;
`;

const Text = styled.textarea`
	width: calc(100% - 420px);
	height: 150px;
	font-family: "Barlow", sans-serif;
	padding: 10px;
`;

const Text2 = styled.textarea`
	width: 100%;
	height: 150px;
	font-family: "Barlow", sans-serif;
	padding: 10px;
	margin-bottom: 10px;
`;

const Text3 = styled.textarea`
	width: 100%;
	height: 30px;
	font-family: "Barlow", sans-serif;
	padding: 5px;
	margin-bottom: 20px;
`;

const Area = styled.div`
	display: flex;
	width: 100%;
	margin-bottom: 20px;
`;

const Question = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	margin-bottom: 30px;
	font-size: 20px;
`;
const TextArea = styled.div`
	width: calc(100% - 420px);
`;
