import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_FILE_UPLOADS,
    SG_GET_FILE_UPLOADS,
    DELETE_FILE_UPLOAD,
    SG_DELETE_FILE_UPLOAD,
    FETCH_FILE_UPLOAD,
    SG_FETCH_FILE_UPLOAD,
    EDIT_FILE_UPLOAD,
    SG_EDIT_FILE_UPLOAD,
    ADD_FILE_UPLOAD,
    SG_ADD_FILE_UPLOAD,
    START_FILE_UPLOAD,
    ALL_ERRORS,
} from "constants/actions";


function* loadmethods(action) {
  //   yield put({ type: GET_methodS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    yield put({type: START_FILE_UPLOAD})
    const json = yield call(api.GET_FILE_UPLOADS, action.payload);
    // console.log(json.data)
    yield put({ type: GET_FILE_UPLOADS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeEvery(SG_GET_FILE_UPLOADS, loadmethods);
}

function* fetchmethods(action) {
  try {
    yield put({type: START_FILE_UPLOAD})
    const json = yield call(api.FETCH_FILE_UPLOAD, action.payload);
    yield put({ type: FETCH_FILE_UPLOAD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_FILE_UPLOAD, fetchmethods);
}

function* updatemethods(action) {
  try {
    yield put({type: START_FILE_UPLOAD})
    const json = yield call(api.EDIT_FILE_UPLOAD, action.payload);
    yield put({ type: EDIT_FILE_UPLOAD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodUpdate() {
  yield takeLatest(SG_EDIT_FILE_UPLOAD, updatemethods);
}

function* addmethods(action) {
  // RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE
  try {
    yield put({type: START_FILE_UPLOAD})
    const json = yield call(api.ADD_FILE_UPLOAD, action.payload);
    yield put({ type: ADD_FILE_UPLOAD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodAdd() {
  yield takeLatest(SG_ADD_FILE_UPLOAD, addmethods);
}

function* deletemethods(action) {
  try {
    yield call(api.DELETE_FILE_UPLOAD, action.payload);
    yield put({ type: DELETE_FILE_UPLOAD, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_FILE_UPLOAD, deletemethods);
}


export default function* index() {
  yield all([
    methodLoad(),
    methodFetch(),
    methodUpdate(),
    methodAdd(),
    methodDelete(),
  ]);
}
