import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/UI/people.svg'
import { useNavigate } from 'react-router-dom';

const NextSteps = ({next}) =>{
    const navigate = useNavigate();

    return (
        <Container>
            
            <T2>What are my next steps?</T2>

                <T1>Have more questions about your results?</T1>
                <Description>
                    Check out the culture copilot. This ai tool is connected to your data, and able to give you additional insights into what is going on in your team. 
                </Description>

                    <Button onClick={()=>navigate('/simple/audit/ai')}>Go to Culture Copilot</Button>


               


                <T1>Build an action plan</T1>
                <Description>
                    Use our action plan builder to create a plan specifically for you based on your data. 
                    The ai powered builder will guide you through each step of the process.
                </Description>


                <Button onClick={()=>navigate('/simple/prototype/draft/drafts')}>Build an action plan</Button>



          

           

          
        </Container>
    )

}

export default NextSteps

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
    align-items:flex-start;

`

const T1 = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:14px;
    font-weight:600;
    margin-bottom:30px;
    line-height:1.4;
`

const Description = styled.div`
    margin-bottom: 20px;
`


const Button = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-bottom:30px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`