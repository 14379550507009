import React, { useEffect, useState } from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { format } from "date-fns";
import { CalendarIcon } from "./icons";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  planTitle: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  itemRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  itemLabel: {
    color: "#666d79",
    fontWeight: 500,
    fontSize: 12,
    marginTop: 3,
    marginRight: 5,
  },
  itemValue: {
    color: "#2d70e2",
    fontWeight: 500,
    fontSize: 12,
    marginTop: 3,
  },
  colRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  dateValue: {
    color: "#666d79",
    fontWeight: 500,
    fontSize: 12,
    marginTop: 3,
    marginRight: 15,
    marginLeft: 5,
  },
});

const organize_questions2 = (q, s, o) => {
  let questions = [{ key: -1, text: "All questions", value: -1 }];
  questions.push(
    ...q.questions.map((qx, idx) => {
      return {
        key: qx.q,
        text: qx.q,
        value: idx,
      };
    })
  );

  return questions;
};

const InfoHeading = (props) => {
  const {
    planTitle,
    status,
    metric,
    primary,
    assignees,
    startDate,
    endDate,
    factors,
    question,
    planId,
    questionsList,
    focusArea,
  } = props;

  const [questionOptions, setQuestionOptions] = useState([]);

  useEffect(() => {
    if (questionsList && questionsList?.questions) {
      setQuestionOptions(organize_questions2(questionsList));
    }
  }, [questionsList]);

  return (
    <View style={styles.container}>
      <Text style={styles.planTitle}>{planTitle}</Text>

      {status && (
        <View style={styles.itemRow}>
          <Text style={styles.itemLabel}>Status:</Text>
          <Text style={styles.itemValue}>{status}</Text>
        </View>
      )}
      {planId !== 4 && (
        <View>
          {metric && (
            <View style={styles.itemRow}>
              <Text style={styles.itemLabel}>Dimension:</Text>
              <Text style={styles.itemValue}>{metric}</Text>
            </View>
          )}
          {focusArea && questionOptions && (
            <View style={styles.colRow}>
              <Text style={styles.itemLabel}>Focus Area:</Text>
              <Text style={styles.itemValue}>
                {questionOptions[focusArea?.[0]]?.text}
              </Text>
            </View>
          )}
        </View>
      )}
      {primary && (
        <View style={styles.itemRow}>
          <Text style={styles.itemLabel}>Sport:</Text>
          <Text style={styles.itemValue}>{primary}</Text>
        </View>
      )}

      <View style={styles.colRow}>
        <Text style={styles.itemLabel}>Assignees:</Text>
        <View style={styles.row}>
          {assignees.map((assignee, i) => (
            <Text style={styles.itemValue}>
              {assignee.name}
              {i < assignees?.length - 1 ? ", " : ""}
            </Text>
          ))}
        </View>
      </View>
      <View style={styles.colRow}>
        <Text style={styles.itemLabel}>Timelines:</Text>
        <View style={styles.row}>
          <CalendarIcon />
          <Text style={styles.dateValue}>
            {format(new Date(startDate), "yyyy-MM-dd")}
          </Text>
          <CalendarIcon />
          <Text style={styles.dateValue}>
            {format(new Date(endDate), "yyyy-MM-dd")}
          </Text>
        </View>
      </View>

      {planId === 4 && (
        <View>
          {metric && (
            <View style={styles.itemRow}>
              <Text style={styles.itemLabel}>Metric:</Text>
              <Text style={styles.itemValue}>{metric}</Text>
            </View>
          )}

          {factors && (
            <View style={styles.itemRow}>
              <Text style={styles.itemLabel}>Factor:</Text>
              <Text style={styles.itemValue}>{factors}</Text>
            </View>
          )}

          {question && (
            <View style={styles.itemRow}>
              <Text style={styles.itemLabel}>Question:</Text>
              <Text style={styles.itemValue}>{question}</Text>
            </View>
          )}
        </View>
      )}
    </View>
  );
};

export default InfoHeading;
