import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Checkbox,Icon } from 'semantic-ui-react';



const Table = () =>{

    return (
        <Container>
            <Header>
                <TH width={20}>Category</TH>
                <TH width={25}>Report Type</TH>
                <TH width={15}>Status</TH>
                <TH width={20}>Date</TH>
                <TH width={10}>Actions</TH>
            </Header>
            <Row>
                <TD width={20}><MultipleCategory name={"Development"}/></TD>
                <TD width={25}>Culture Factors</TD>
                <TD width={15}><Status type={"Review"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal' style={{marginTop:-5}}/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Accounting"}/></TD>
                <TD width={25}>Accountability</TD>
                <TD width={15}><Status type={"Shared"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal'/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Development"}/></TD>
                <TD width={25}>Accountability</TD>
                <TD width={15}><Status type={"Generating"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal'/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Development"}/></TD>
                <TD width={25}>Culture Factors</TD>
                <TD width={15}><Status type={"Review"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal' style={{marginTop:-5}}/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Accounting"}/></TD>
                <TD width={25}>Accountability</TD>
                <TD width={15}><Status type={"Shared"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal'/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Development"}/></TD>
                <TD width={25}>Accountability</TD>
                <TD width={15}><Status type={"Generating"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal'/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Development"}/></TD>
                <TD width={25}>Culture Factors</TD>
                <TD width={15}><Status type={"Review"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal' style={{marginTop:-5}}/>
                </TD>
            </Row>
            <Row>
                <TD width={20}><MultipleCategory name={"Accounting"}/></TD>
                <TD width={25}>Accountability</TD>
                <TD width={15}><Status type={"Shared"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal'/>
                </TD>
            </Row>
            <Row>

                <TD width={20}><MultipleCategory name={"Development"}/></TD>
                <TD width={25}>Accountability</TD>
                <TD width={15}><Status type={"Generating"}/></TD>
                <TD width={20}>10/10/2021</TD>
                <TD width={10}>
                    <Icon name='ellipsis horizontal'/>
                </TD>
            </Row>
        </Container>
    )

}

export default Table

const MultipleCategory = ({name}) =>{

    return (
        <Border>
            {name}
        
        </Border>
    )
}

const Status = ({type}) =>{
   
    return (
        <StatusContainer>
            {type}
        </StatusContainer>
    )
}

const Container = styled.div`
    width:100%;
    margin-bottom:50px;
`

const Border = styled.div`
    
   
`

const StatusContainer = styled.div`
    color:#2D70E2;
    display:flex;
    font-size:12px;
    font-weight:bold;
    align-items:center;
    justify-content:center;
`

const B1 = styled.div`
    z-index:2;
    width:95%,
    position:absolute;
    border:1px solid #2D70E2;
    background-color:#EBF1FD;
    border-radius:5px;
    top:-5px;
    left:5px;
    height:20px;

`

const B2 = styled.div`
    z-index:1;
    width:90%;
    position:absolute;
    border:1px solid #2D70E2;
    background-color:#EBF1FD;
    border-radius:5px;
    top:-10px;
    left:10px;
`

const Header = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    height:35px;
    background-color:#D5E2F9;
    border-radius:5px;
    font-family:'Raleway';
    margin-bottom:0px;

`

const TH = styled.div`
    width:${props=>props.width}%;
    font-size:12px;
    font-weight:400;
    color:#2A3039;
    display:flex;
    align-items:center;
    justify-content:${props=>props.centered ? 'center' : 'flex-start'};
    padding-left:10px;
`

const Row = styled.div`
    display:flex;
    height:45px;
    align-items:center;
    width:100%;

    border-bottom:1px solid #F5F5F5;

`

const TD = styled.div`
    width:${props=>props.width}%;
    font-size:12px;
    color:#2A3039;
    display:flex;
    align-items:center;
    justify-content:${props=>props.centered ? 'center' : 'flex-start'};
    padding-left:10px;
`


const UserImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    margin-right:20px;
`

const Image = styled.img`
    height:30px;
`