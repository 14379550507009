import {
  ADD_MANAGER_USER,
  GET_MANAGER_USERS,
  CLEAR_MANAGER_ERRORS,
  DEACTIVATE_MANAGER_USER,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  managerUsers: [],
  managerUser: {},
  results: null,
};

export default function UserManager(state = initialState, action) {
  switch (action.type) {
    case ADD_MANAGER_USER:
      // Create a copy of the existing managerUsers array
      const newManagerUsers = [...state.managerUsers?.results];

      // Check that the user isn't already in the array
      // new users are found in action?.payload?.newData
      action?.payload?.newData?.forEach((user) => {
        // Look through the action?.payload?.response?.permissions object,
        // if the user id is in the failed array, don't add the user to the list
        if (action?.payload?.response?.permissions?.failed?.includes(user.id)) {
          return;
        }

        const userIndex = newManagerUsers?.findIndex(
          (managerUser) => managerUser.id === user.id
        );

        if (userIndex === -1) {
          // add the user to the front of the list
          newManagerUsers?.unshift(user);
        }
      });

      return {
        ...state,
        results: action.payload?.response,
        managerUsers: {
          ...state.managerUsers,
          results: newManagerUsers,
        },
      };

    case DEACTIVATE_MANAGER_USER:
      // Create a copy of the existing managerUsers array
      const currentList = [...state.managerUsers?.results];

      // Check that the user isn't already in the array
      // new users are found in action?.payload?.newData
      const newList = currentList?.filter((user) => user.id !== action.payload);

      return {
        ...state,
        results: action.payload?.response,
        managerUsers: {
          ...state.managerUsers,
          results: newList,
        },
      };

    case GET_MANAGER_USERS:
      return {
        ...state,
        managerUsers: action.payload,
      };

    case CLEAR_MANAGER_ERRORS:
      return {
        ...state,
        results: null,
      };

    case CLEAR_ALL:
      return {
        ...state,
        managerUsers: [],
        managerUser: {},
        results: null,
      };

    default:
      return state;
  }
}
