import styled from "styled-components";
import React from "react";
import { Dimmer, Loader } from 'semantic-ui-react'

import { GridRowData } from "./Components"

const SubFactorGrid = ({
    subFactors,
    monthList,
    surveySchedule,
    userAdmin,
    userEmployeeCategoryList,
    isFrench }) => {

    return (
        <Grid>
            {subFactors.map((ff, i) => {
                if (userAdmin) {
                    return <GridRowData
                        monthList={monthList}
                        itemName={isFrench?ff.fr:ff?.name}
                        surveySchedule={surveySchedule}
                        key={i} />
                } else if (userEmployeeCategoryList?.includes(ff?.name_id)) {
                    return <GridRowData
                        monthList={monthList}
                        itemName={isFrench?ff.fr:ff?.name}
                        surveySchedule={surveySchedule}
                        key={i} />
                }
                return null
            })}
        </Grid>
    )
}

function SubFactorList({ 
        subFactors,
        userAdmin,
        userEmployeeCategoryList,
        isFrench }) {

    return (
        subFactors.map((ff, i) => {
            if (userAdmin) {
                return <Sub key={i}><div>{isFrench?ff.fr? ff.fr:ff?.name:ff?.name}</div> </Sub>
            } else if (userEmployeeCategoryList?.includes(ff?.name_id)) {
                return <Sub key={i}><div>{isFrench?ff.fr?ff.fr:ff?.name:ff?.name}</div> </Sub>
            }
            return null
        })
    )
}

const Subfactor = ({
    surveySchedule,
    groupList,
    primaryCategory,
    monthList,
    currentMonth,
    pageLoading,
    userAdmin,
    userEmployeeCategoryList,
    language_hook,
    survey_structure
}) => {


    const checkIncludes = (op)=>{
        return primaryCategory.filter(f=>f.group_id == op).length>0
    }
   
    return (
        <>
            {currentMonth > 0 && <Current month={currentMonth} />}
            <Left>
                <Top>
                    <div>{language_hook?language_hook.fetch_words('Primary Factor'):"Primary Factor"}</div>
                    {/* <div>Progress</div> */}
                </Top>
                <Bottom>
                    {groupList?.map((op, i) => {
                        // console.log(op)
                        if(checkIncludes(op?.id)){
                            let subs =primaryCategory.filter(pc => pc.group_id === op?.id)
                            let name = language_hook?.is_french() ? survey_structure.categories.find(s=>s.priority=="primary").options.find(f=>f.name==op?.name)?.fr : op?.name
                            return <div key={i}>
                            <Primary>{name}</Primary>
                            <SubFactorList
                                subFactors={subs}
                                option={op?.option}
                                userAdmin={userAdmin}
                                userEmployeeCategoryList={userEmployeeCategoryList}
                                isFrench={language_hook?.is_french()}
                            />
                        </div>
                        }
                        return null
                    })}
                </Bottom>
            </Left>

            <Right>
                <Dimmer.Dimmable dimmed={pageLoading}>
                    <Dimmer active={pageLoading} inverted>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <TopR>
                        {monthList.map((item, i) => <Dated key={i}>{item}</Dated>)}
                    </TopR>
                    {groupList?.map((op, i) => {
                        
                         if(checkIncludes(op?.id)){
 
                            return <div key={i}>
                                <Primary>{''}</Primary>
                                <SubFactorGrid
                                    subFactors={primaryCategory.filter(pc => pc.group_id === op?.id)}
                                    monthList={monthList}
                                    surveySchedule={surveySchedule}
                                    key={i}
                                    userAdmin={userAdmin}
                                    userEmployeeCategoryList={userEmployeeCategoryList}

                                />
                            </div>
                         }
                         return null
                    })}

                </Dimmer.Dimmable>
            </Right>



        </>
    )
}

/******** Styles ****************************************************/

const Left = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:300px;
`

const Top = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	border-bottom:1px solid #e9e9e9;
	height:60px;
	padding:30px 30px 10px 30px;
`

const Bottom = styled.div`
	padding:0px 20px 20px 20px;
`

const Primary = styled.div`
	font-size:14px;
	font-weight:bold;
	margin-bottom:10px;
	height:30px;
	margin-top:15px;
	font-family: 'Barlow', sans-serif;
`

const Sub = styled.div`
	width:100%;
	display:flex;
	justify-content:space-between;
	padding-left:20px;
	height:25px;
`

const Right = styled.div`
	width:calc(100% - 300px);
`

const TopR = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:flex-end;
	border-bottom:1px solid;
	padding:30px 20px 10px 20px;
	height:60px;
`

const Dated = styled.div`
	width:calc(100% / 12);
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-weight:bold;
`

const Grid = styled.div`
	padding:0px 20px 0px 20px;
`

const Current = styled.div`
	position:absolute;
	height:calc(100% - 20px);
	width:calc((100% - 300px) / 12);
	border:3px solid #E3B01F;
	border-radius:20px;
	z-index:-1;
	left:${props => 'calc(((100% - 300px) / 12 - 3.3px) * ' + (props.month - 1) + ' + 318px)'};
	top:10px;
`

export default Subfactor
