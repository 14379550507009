import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

const colors = ['#a9a8ad','#887fad','#7365ad','#513bad','#2e02eb']

const DistributionChart = ({data}) =>{

    return (
        <Container>
            {data.map((item,i)=>{
                if(item){
                    return <Color width={item} color={colors[i]}>{item}%</Color>
                }
        })}
        </Container>
    )
}

export default DistributionChart;

const Container = styled.div`
    width:100%;
    display:flex;
    
`

const Color = styled.div`
    width:${props=>props.width}%;
    background-color:${props=>props.color};
    height:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    color:white;
`