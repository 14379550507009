import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import ClickAwayListener from 'react-click-away-listener';

const AddSlideContainer = styled.div`
  position: relative;
  display: inline-block;
  font-family: 'Raleway';
  width:100%;
  margin-bottom:20px;
`;

const Button = styled.div`
  display: flex;
  align-items: space-between;
  cursor: pointer;
  border-radius: 8px;
  background-color: #ffffff;
  font-weight: 600;
  width:100%;
  justify-content: space-between;
`;

const Menu = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px 0;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const ItemTitle = styled.div`
  font-weight: bold;
  color: #333;
`;

const ItemDescription = styled.div`
  font-size: 0.9em;
  color: #777;
  margin-top: 2px;
`;

const AddSlideComponent = ({ onSelect }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleSelect = (option) => {
    onSelect(option);
    setMenuOpen(false);
  };

  const handleClickAway = () => {
    setMenuOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AddSlideContainer>
        <Button onClick={toggleMenu}>
          <span>Add Slide</span>
          <Icon name="chevron down" style={{fontSize:10}}/>
        </Button>
        {menuOpen && (
          <Menu>
            <MenuItem onClick={() => handleSelect('Participation')}>
              <ItemTitle>Participation</ItemTitle>
              <ItemDescription>Overview of participation metrics and engagement.</ItemDescription>
            </MenuItem>
            <MenuItem onClick={() => handleSelect('Thematic Analysis')}>
              <ItemTitle>Thematic Analysis</ItemTitle>
              <ItemDescription>In-depth analysis of common themes and patterns.</ItemDescription>
            </MenuItem>
            <MenuItem onClick={() => handleSelect('Factor Analysis')}>
              <ItemTitle>Factor Analysis</ItemTitle>
              <ItemDescription>Detailed breakdown of factors contributing to outcomes.</ItemDescription>
            </MenuItem>
            <MenuItem onClick={() => handleSelect('Score Overview')}>
              <ItemTitle>Score Overview</ItemTitle>
              <ItemDescription>Summary of scores across various metrics.</ItemDescription>
            </MenuItem>
          </Menu>
        )}
      </AddSlideContainer>
    </ClickAwayListener>
  );
};

export default AddSlideComponent;
