import React from 'react';
import { List, Button } from 'semantic-ui-react';
import styled, { keyframes } from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Icon } from 'semantic-ui-react'

const ImageList = [
    'long arrow alternate left',
    'long arrow alternate right',
    'arrows alternate horizontal',
    'arrows alternate horizontal']

const colors = [
    '#612A8A',
    '#00B0F0',
    '#F12DA2',
    '#08C251'
];

const icons = ['retweet', 'arrow right', 'angle double right', 'random']

const points = [['Only team leaders get access to the report data', 'Only team members participate in surveys', 'Team members login to create profiles only'],
['All team members get access to the report data', 'All team members participate in surveys', 'Team members login to access report data']]

const TeamType = ({ teamType, setTeamType, setLearn }) => {

    const { get_team_types } = useSelector(
        (state) => ({
            get_team_types: state.team_types.team_types.filter(
                p => p.sort_order < 100
            ),
        }),
        shallowEqual
    );

    function SelectTeam(e) {
        setTeamType(e)
    }

    console.log(get_team_types)

    return (
        <Container>
            {get_team_types.length > 0 && get_team_types.map((p, i) => {
                if (p.id === 10 || p.id === 12) {
                    return null
                }
                return <CardContainer key={i}>
                    <NewCard onClick={() => SelectTeam(p.id)} color={teamType === p.id ? 'rgba(0, 176, 240,0.3)' : 'white'}>
                        <Icon name={icons[i]} style={{ fontSize: 40 }} />
                        <Title>  {p.name}</Title>
                        <Description>
                            {p.description}
                        </Description>

                    </NewCard>
                    {/*                   <LearnMore onClick={()=>setLearn(i+1)}>Learn More</LearnMore>*/}
                </CardContainer>
            })}

        </Container>
    );

    // return (
    //   <Container>
    //        {get_team_types.length > 0 && get_team_types.map((p, i) => {
    //           return  <Card key={i}>
    //            <Top color={colors[i]}>
    //                {p.name}
    //            </Top>
    //            <Description>
    //                   {p.description}
    //            </Description>
    //            <Bottom>
    //                <Learn onClick={()=>setLearn(i+1)}>Explore</Learn>
    //                <Button 
    //                    color={teamType === p.id? 'violet': 'grey'}
    //                    disabled={teamType === p.id}
    //                    onClick={() => SelectTeam(p.id)}>
    //                     {teamType === p.id? 'Selected': 'Select'}   
    //                 </Button>
    //             </Bottom>
    //            </Card>
    //        })}
    //    </Container>

    //   )
};

export default TeamType;

const Container = styled.div`
    display:flex;
    width:100%;
    flex-direction:row;
    justify-content:space-evenly;

    @media (max-width:768px){
        flex-wrap:wrap;
        font-size:16px;
    }
`

const Card = styled.div`
    height:300px;
    width:400px;
    box-shadow: 0 2px 40px 0 rgba(213, 213, 213, 0.5);
    font-family: 'Red Hat Display', sans-serif;
    margin-right:20px;
    position:relative;

     @media (max-width:768px){
        width:100%;
        margin-right:0;
        margin-bottom:20px;
    }
`

const Top = styled.div`
    width:100%;
    height:100px;
    background-color:${props => props.color};
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    font-weight:bold;
    padding:20px;
`

const Description = styled.div`
`

const Bottom = styled.div`
    position:absolute;
    bottom:0;
    width:100%;
    display:flex;
   justify-content:space-between;
   align-items:center;
    padding:10px;
`

const Learn = styled.div`
   cursor:pointer;
   color:#6a00ff;
`

const CardContainer = styled.div`
     height:350px;
     width:calc(45% - 20px);  
     display:flex;
     flex-direction:column;
     margin-right:20px;
`


const NewCard = styled.div`
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius:3px;
    height:300px;
    width:100%;
    font-family: 'Red Hat Display', sans-serif;
    
    display:flex;
    flex-direction:column;
   align-items:center;
   padding:20px;
   cursor:pointer;
   background-color:${props => props.color};
   position:relative;

   &:hover{
       box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 4px;
   }
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    text-align:center;
    margin-top:30px;
    margin-bottom:20px;
    height:40px;
`

const LearnMore = styled.div`
  color:#6a00ff;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:7px 24px 7px 24px;
  border: none;
    outline:none;
    margin-left:10px;
`