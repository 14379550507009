import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import IMG from 'assets/images/stock/hero_test.jpg'


const Introduction = ({next}) => {

    return (
        <Container>
            <Center>
                <Title>
                    Action Plan Creation
                </Title>

                <Sub>For creating meaningful culture change</Sub>
        
                <Box>
                    <Half>
                        <T2>
                            Welcome!
                        </T2>
                        <Description>
                                We've analyzed your culture data and have created a walkthrough of the key elements of your culture.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam tincidunt,
                                enim lectus luctus elit, sit amet ullamcorper elit risus id nisl. Donec eget nunc eget liber.
                        </Description>

                        <Button onClick={()=>next()}>Build Draft</Button>
                    </Half>
                    <Half2>
                        <Image src={IMG}/>
                    </Half2>
                    
                </Box>
            </Center>
            
                   
        </Container>
    )

}

export default Introduction


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
    padding:20px;
    width:100%;
    margin-top:100px;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Center = styled.div`
width:100%;
max-width:1000px;
`


const Title = styled.div`
    font-size:30px;
    font-weight:bold;
    margin-bottom:30px;
`

const Description = styled.div`
    line-height:1.7;
    max-width:500px;
`

const Box = styled.div`
    width:100%;
    border:1px solid #E0E0E0;
    border-radius:10px;
    padding: 40px 30px;
    display:flex;
`

const Half = styled.div`
    width:50%;
    padding-right:30px;
`

const Half2 = styled.div`
    width:50%;
`

const Image = styled.img`
    width:100%;
    border-radius:10px;
`

const T2 = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:10px;
`

const Sub = styled.div`
    font-size:16px;
    font-weight:400;
    margin-bottom:20px;
`
const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-top:20px;
    font-size:14px;
    font-weight:500;
    transition: all 0.3s ease;
    

    &:hover{
        transform: scale(1.05); 
    }
`
