import styled, { keyframes } from 'styled-components';
import React, { useState, useEffect } from "react";
import { get_personality_percentile } from 'reports/Dashboard/calculations';
import SurveyVersions from "reports/Audit/VersionControl/index";
import { Loader } from "semantic-ui-react";
import Chart from "react-apexcharts";
import ChartLoading from "reports/Audit/ChartLoading";

const Correlations = ({ data, questions, loading,outcome_title }) => {
  const get_max = (info) => {
    return Math.max(...info?.map((item) => item.correlation));
  };

  const get_min = (info) => {
    return Math.min(...info?.map((item) => item.correlation));
  };

  const [max, setMax] = useState(get_max(data));
  const [min, setMin] = useState(get_min(data));
  const [selected,setSelected] = useState(null)
  const [series,setSeries] = useState([])
  const [options,setOptions] = useState(data2.options)
  const [loading2,setLoading] = useState(false)

  useEffect(() => {
    setMax(get_max(data));
    setMin(get_min(data));
  }, [data]);

  const get_title = (item) => {
    if (questions) {
      if (questions.questions?.dimensions) {
        return questions.questions.dimensions
          .find((d, i) => i == item.dimension)
          .factors.find((factor,i) => i === item.factor)?.title;
      }

      return questions.questions.factors.find((d, i) => i == item.factor)
        ?.title;
    }
  };

  const addElipses = (string) => {
    if (string?.length > 45) {
      return string?.slice(0, 45) + "...";
    }
    return string;
  };

  useEffect(()=>{
    
    if(selected){
      setLoading(true);
      let _series = [];
      _series.push({
        name: 'Test',
        type: "scatter",
        data: selected.data.factor_data.map((item,index)=>{
          return {
            x:selected.data.outcome_data[index],
            y:item
          }
        })
      });


      setSeries(_series);
      let _options = options
      _options.yaxis.title.text = selected.title + ' Score'
      _options.xaxis.title.text = outcome_title + ' Score'
      setOptions(_options);

      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
   
    

  },[selected])


  try{
    return (
      <Card>
        <CardTitle>
          <div>Correlation with culture factors</div>
  
          <SurveyVersions />
        </CardTitle>
  
        <Text>
          The table below shows the correlations between this outcome factor and
          each culture score.
        </Text>
        <Container>
          {data
            ?.sort((a, b) => b.correlation - a.correlation)
            .map((item, index) => {
              let score = item.correlation;
              let width = score > 0 ? (score / max) * 100 : (score / min) * 100;
              let offset = score > 0 ? 0 : -width;
              return (
                <Row onClick={()=>setSelected({data:item,title:get_title(item)})}>
                  <Title>{addElipses(get_title(item))}</Title>
                  <Block>
                    <Score score={score} width={width} offset={offset}>
                      <Number side={score > 0}>{score.toFixed(2)}</Number>
                    </Score>
                  </Block>
                </Row>
              );
            })}
          {data.length > 0 && (
            <Line>
              <LineTitle>0</LineTitle>
            </Line>
          )}
  
          {data.length == 0 && !loading && (
            <Empty>
              There are no correlations with this survey version to report.
            </Empty>
          )}
          {loading && (
            <div
              style={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Loader inline active>
                Loading
              </Loader>
            </div>
          )}
        </Container>
  
        {loading2? <ChartLoading/>:
          selected?
          <ChartArea>
             <Chart
                options={options}
                series={series}
                type="scatter"
                height={"100%"}
              />
          </ChartArea>
         :"" }
      </Card>
    );
  }catch(e){
    return <div></div>
  }

  
};

export default Correlations;

const Card = styled.div`
  width: 100%;
  font-family: Raleway;
  margin-top: 30px;
  padding-bottom: 80px;
  padding-right: 40px;
`;

const CardTitle = styled.div`
  font-size: 20px;
  color: #2a3039;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  position: relative;

  width: 100%;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
`;

const Title = styled.div`
  width: 175px;
  color: #666d79;
  height: 15px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const Empty = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    font-weight:bold;
`

const Block = styled.div`
    width:calc(100% - 175px);
    position:relative;
`

const ChartArea = styled.div`
  margin-top:30px;
  height:500px;

`

const growWidth = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${props => props.width / 2}%;
    }
`;


// For positive scores
const growRight = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${props => props.width / 2}%;
    }
`;


// For negative scores
const growLeft = keyframes`
    0% {
        width: 0%;
        left: 50%;
    }
    100% {
        width: ${props => props.width / 2}%;
        left: calc(50% - ${props => props.width / 2}% + ${props => props.offset / 2}%);
    }
`;



const Score = styled.div`
    width:${props=>props.width/2}%;
    position:absolute;
    left:calc(50% + ${props=>props.offset/2}%);
    height:15px;
    background-color:${props=>props.score > 0 ? "#27CDA7" : "#F15062"};
    opacity: 0.5;
    border-radius: ${props=>props.score > 0 ? "0px 8px 8px 0px" :"8px 0px 0px 8px"};
    display:flex;
    align-items:center;
    justify-content:center;
    animation: ${props => props.score > 0 ? growRight : growLeft} 0.5s forwards ease;
    top:0px;
`

const Line = styled.div`
    height:102%;
    width:2px;
    background-color:#DFDFDF;
    position:absolute;
    left:calc(175px + (100% - 175px) / 2 - 1px);
    top:-5px;
`

const LineTitle = styled.div`
    position:absolute;
    left:-100px;
    display:flex;
    justify-content:center;
    top:-20px;
    width:200px;
    font-size:10px;
    font-weight:bold;
`

const Number = styled.div`
    position:absolute;
    left:${props=>props.side?'':'-40px'};
    right:${props=>props.side?'-40px':''};
    font-size:12px;
`

const Text = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
margin-top:10px;
width:100%;
margin-bottom:60px;
`

const data2 = {
  series: [
    {
      name: "Points",
      type: "scatter",

      //2.14, 2.15, 3.61, 4.93, 2.4, 2.7, 4.2, 5.4, 6.1, 8.3
      data: [
        {
          x: 1,
          y: 2.14,
        },
        {
          x: 1.2,
          y: 2.19,
        },
        {
          x: 1.8,
          y: 2.43,
        },
        {
          x: 2.3,
          y: 3.8,
        },
        {
          x: 2.6,
          y: 4.14,
        },
        {
          x: 2.9,
          y: 5.4,
        },
        {
          x: 3.2,
          y: 5.8,
        },
        {
          x: 3.8,
          y: 6.04,
        },
        {
          x: 4.55,
          y: 6.77,
        },
        {
          x: 4.9,
          y: 8.1,
        },
        {
          x: 5.1,
          y: 9.4,
        },
        {
          x: 7.1,
          y: 7.14,
        },
        {
          x: 9.18,
          y: 8.4,
        },
      ],
    },
    {
      name: "Line",
      type: "line",
      data: [
        {
          x: 1,
          y: 2,
        },
        {
          x: 2,
          y: 3,
        },
        {
          x: 3,
          y: 4,
        },
        {
          x: 4,
          y: 5,
        },
        {
          x: 5,
          y: 6,
        },
        {
          x: 6,
          y: 7,
        },
        {
          x: 7,
          y: 8,
        },
        {
          x: 8,
          y: 9,
        },
        {
          x: 9,
          y: 10,
        },
        {
          x: 10,
          y: 11,
        },
      ],
    },
  ],
  options: {
    chart: {
      height: "100%",
      type: "scatter",
      fontFamily: "Poppins, sans-serif",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [5, 0],
      strokeWidth: 1,
      radius: 2,
      strokeColors: "#476DFA",
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#66D686", "#476DFA", "#E66579", "#EE7C35"],
    xaxis: {
      decimalsInFloat: 0,
      tickAmount: 10,
      min:0,
      max:10,
      title: {
        text: "Team Cohesion",
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#7E7E7E",
        },
      },
    },
    yaxis: {
      decimalsInFloat: 0,
      min:0,
      max:10,
      title: {
        text: "Personality",
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#7E7E7E",
        },
      },
    },
    legend: {
      show: false,
    },
  },
};