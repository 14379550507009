import {
  DEBRIEF_SCHEDULES_PENDING,
  CULTURE_AUDIT_REPORT_PENDING,

  GET_DEBRIEF_SCHEDULES,
  GET_CULTURE_AUDIT_REPORTS,
  DELETE_DEBRIEF_SCHEDULE,
  SELECTED_DEBRIEF_SCHEDULE,
  RE_SELECTED_DEBRIEF_SCHEDULE,
  FETCH_DEBRIEF_SCHEDULE,
  EDIT_DEBRIEF_SCHEDULE,
  ADD_NEW_DEBRIEF_SCHEDULE,
  GET_AUDIT_ELIGIBLE,
  ADD_USER_TO_SURVEY,
  EXTEND_SURVEY_TIME,
  CLEAR_DEBRIEF_SCHEDULE,
  CLEAR_CULTURE_AUDIT_REPORT,
  ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  CLEAR_ALL,
  FIND_DEBRIEF_SCHEDULE
} from "constants/actions";

const initialState = {
  debrief_schedule: [],
  latest_schedule: [],
  culture_audit_reports: [],
  audit_eligible: [],
  user_added: false,
  survey_extended: false,
  debrief_id: null,
  pending: false,
  created: null,
  updated_debrief: null,
  deleted: false,
  activePage: 1,
  reportsPending: false,
};

export default function DebriefSchedule(state = initialState, action) {
  switch (action.type) {
    case DEBRIEF_SCHEDULES_PENDING:
      return {
        ...state,
        pending: true,
        created: null,
        debrief_id: null,
        updated_debrief: null,
      };
    case CULTURE_AUDIT_REPORT_PENDING:
        return {
          ...state,
          reportsPending: true,
        };
    case GET_DEBRIEF_SCHEDULES:
      return {
        ...state,
        debrief_schedule: action.payload,
        pending: false,
        created: null,
      };
    case GET_CULTURE_AUDIT_REPORTS:
      return {
        ...state,
        culture_audit_reports: action.payload,
        pending: false,

        reportsPending: false,
      };
    case GET_AUDIT_ELIGIBLE:
      return {
        ...state,
        audit_eligible: action.payload,
        pending: false,
        created: null,
      };
    case ADD_USER_TO_SURVEY:
      return {
        ...state,
        user_added: action.payload,
        pending: false,
        created: null,
      };
    case EXTEND_SURVEY_TIME:
      return {
        ...state,
        survey_extended: action.payload,
        pending: false,
        created: null,
      };
    case GET_ACTIVE_PAGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        activePage: action.payload,
        pending: false,
      };
    case ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        activePage: action.payload,
        pending: false,
      };
    case SELECTED_DEBRIEF_SCHEDULE:
      return {
        ...state,
        debrief_id: action.payload,
        pending: false,
      };
    case RE_SELECTED_DEBRIEF_SCHEDULE:
      return {
        ...state,
        debrief_id: null,
        pending: false,
      };

    case FIND_DEBRIEF_SCHEDULE:
      return {
        ...state,
        latest_schedule: action.payload,
        pending: false,
      };
    case FETCH_DEBRIEF_SCHEDULE:
        // console.log(action.payload)
        return {
          ...state,
          [action.payload.id]: action.payload,
          // debrief_id: action.payload.id,
          pending: false,
        };

    case EDIT_DEBRIEF_SCHEDULE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        debrief_id: action.payload.id,
        updated_debrief: true,
        created: null,
        pending: false,
      };

    case DELETE_DEBRIEF_SCHEDULE:
      const new_result = state.debrief_schedule.results.filter(
        (row) => row.id !== action.payload.id
      );

      // console.log(new_result)

      return {
        ...state,
        debrief_schedule: {
          ...(state.debrief_schedule.results = new_result),
        },
        created: null,
        updated_debrief: true,
        pending: false,
      };
    case RESET_ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE:
      return {
        ...state,
        created: null,
      };
    case ADD_NEW_DEBRIEF_SCHEDULE:
      // console.log(action.payload)
      return {
        ...state,
        created: action.payload,
        debrief_id: action.payload.id,
        pending: false,
        updated_debrief: true,
        // [action.payload.id]: action.payload,
        // debrief_schedule: [...state.debrief_schedule, action.payload],
        // debrief_id: null,
      };

    case CLEAR_DEBRIEF_SCHEDULE:
      return {
        ...state,
        debrief_schedule: [],
        latest_schedule: [],

        audit_eligible: [],
        user_added: false,
        survey_extended: false,
        debrief_id: null,
        pending: false,
        created: null,
        deleted: false,
        updated_debrief: null,
        activePage: 1,

      };

    case CLEAR_CULTURE_AUDIT_REPORT:
        return {
          ...state,
          culture_audit_reports: [],
          reportsPending: false,
        };

    case CLEAR_ALL:
      return {
        ...state,
        debrief_schedule: [],
        culture_audit_reports: [],
        audit_eligible: [],
        user_added: false,
        survey_extended: false,
        updated_debrief: null,
        debrief_id: null,
        pending: false,
        created: null,
        deleted: false,
        activePage: 1,
      };

    default:
      return state;
  }
}
