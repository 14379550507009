import styled, { keyframes } from "styled-components";
import React from "react";


const ThemeEntry = (props) =>{

	return (
		<ThemeContainer>
			<SideContainer>
			<Dot/>
			<Line/>
			</SideContainer>
			<Content>
				<Dated>{props.date}</Dated>
				<ThemeTitle>{props.theme}</ThemeTitle>
				<Row>
					<Text>Impact</Text>
					<Impact color={props.impact=="Negative"?"255, 184, 18":"48, 120, 255"}>{props.magnitude} {props.impact}</Impact>
				</Row>
				<Row>
					<Text>Sentiment</Text>
					<Impact color={props.sentiment=="Positive"?"8, 194, 81":"255, 45, 85"}>{props.sentiment}</Impact>
				</Row>
				<Row>
					<Text>Keywords</Text>
					<Impact color={"106, 0, 255"}>{props.keywords}</Impact>
				</Row>
			</Content>
		</ThemeContainer>
		)
}


const RowEntry = (props) =>{

	return (
		<TR>
					<TD width={20}>Developpers</TD>
					<TD width={20}><Impact color={"48, 120, 255"}>1. {props.theme}</Impact></TD>
					<TD width={20}>{props.magnitude} {props.impact}</TD>
					<TD width={20}><Impact color={props.sentiment=="Positive"?"8, 194, 81":"255, 45, 85"}>{props.sentiment}</Impact></TD>
					<TD width={20}>{props.keywords}</TD>

					<TD width={20}></TD>
					<TD width={20}><Impact color={"48, 120, 255"}>2. {props.theme}</Impact></TD>
					<TD width={20}>{props.magnitude} {props.impact}</TD>
					<TD width={20}><Impact color={props.sentiment=="Positive"?"8, 194, 81":"255, 45, 85"}>{props.sentiment}</Impact></TD>
					<TD width={20}>{props.keywords}</TD>

					<TD width={20}></TD>
					<TD width={20}><Impact color={"48, 120, 255"}>3. {props.theme}</Impact></TD>
					<TD width={20}>{props.magnitude} {props.impact}</TD>
					<TD width={20}><Impact color={props.sentiment=="Positive"?"8, 194, 81":"255, 45, 85"}>{props.sentiment}</Impact></TD>
					<TD width={20}>{props.keywords}</TD>
		</TR>
		)
}


const AdminTheme = ()=>{

	return (
		<Container>
			<Title>Theme Summary</Title>
			<Table>
				<TH>
					<TD width={20}>Team</TD>
					<TD width={20}>Theme</TD>
					<TD width={20}>Impact</TD>
					<TD width={20}>Sentiment</TD>
					<TD width={20}>Keywords</TD>
				</TH>

			<RowEntry theme={"Growth Mindset"} date={"May 31st"} impact={"Positive"} magnitude={"Large"} sentiment={"Positive"} keywords={"season trying win"}/>
			<RowEntry theme={"Performance"} date={"May 24th"} impact={"Negative"} magnitude={"Moderate"} sentiment={"Negative"} keywords={"drained stress injury"}/>
			<RowEntry theme={"Hard work paying off"} date={"May 17th"} impact={"Positive"} magnitude={"Moderate"} sentiment={"Positive"} keywords={"improved season eager"}/>
			<RowEntry theme={"New experiences"} date={"May 10th"} impact={"Negative"} magnitude={"Moderate"} sentiment={"Negative"} keywords={"drained stress injury"}/>
				

			</Table>
			
		</Container>
		)
}


export default AdminTheme



const Container = styled.div`
	width:100%;
	margin-left:30px;
	padding-right:20px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;
	font-size:18px;
	width:100%;
	margin-top:50px;
`

const ThemeContainer = styled.div`
	width:100%;
	height:155px;
	display:flex;
`

const Dot = styled.div`
	height:10px;
	width:10px;
	border-radius:5px;
	background-color:white;
	border:3px solid #6a00ff;
`

const Line = styled.div`
	height:100%;
	width:1px;
	background-color:#6a00ff;
	margin-left:5px;
`

const SideContainer = styled.div`
	height:100%;
`

const Content = styled.div`
	margin-top:-3px;
	margin-left:10px;
	width:100%;
`

const Dated = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:12px;
	color:#a5a5a5;
`

const ThemeTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
`

const Row = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	margin-top:5px;
`

const Text= styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
`

const Impact = styled.div`
	background-color:${props=>'rgba('+props.color+',0.3)'};
	color:${props=>'rgba('+props.color+',1)'};
	border:${props=>'1px solid rgba('+props.color+',0.6)'};
	border-radius:4px;
	padding:3px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
	font-size:13px;

`

const Table = styled.div`
	display:flex;
	flex-direction:column;
	width:100%;
`

const TH = styled.div`
	display:flex;
	flex-direction:row;
	font-weight:bold;
	font-size:14px;
	color:#4a4a4a;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	font-family: "Red Hat Display", sans-serif;
	
	display:flex;
	padding:10px;

`

const TR = styled.div`
	display:flex;
	flex-direction:row;
	box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
	margin-top:20px;
	border-radius:8px;
	align-items:center;
	flex-wrap:wrap;
`

