import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Select,Dropdown } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { SET_DATA_ACNHOR } from "constants/actions";

const Anchor = (props) => {
  const dispatch = useDispatch();
  const core_data = useSelector((state) => state.audit.core_data, shallowEqual);

  const get_employee_permission = useSelector(
    (state) => state.employee_permission,
    shallowEqual
  );

  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

  const build_state = (is_french) => {
    let _state = core_data?.categories[0]?.categories
      ?.filter((f) => f.priority.toLowerCase() !== "primary")
      ?.map((i) => {
        return {
          text: is_french ? (i.fr_name ? i.fr_name : i.name) : i.name,
          value: i.id,
        };
      });
    if (!props.pdf && _state) {
      _state.unshift({
        value: core_data?.categories?.[0]?.categories?.find(
          (f) => f.priority.toLowerCase() === "primary"
        )?.id,
        text: core_data?.categories?.[0]?.categories?.find(
          (f) => f.priority.toLowerCase() === "primary"
        )?.name,
      });
    }

    // _state.push({
    //     value: -1,
    //     text: "BIPOC",
    //   });

    return _state;
  };

  const [anchor_list, set_anchor_list] = useState();


  useEffect(() => {
    if (!anchor_list) {
      set_anchor_list(build_state(props.is_french));
    }
  }, [props]);

  const selectAnchor = (value) =>{
    dispatch({
      type: SET_DATA_ACNHOR,
      payload: value,
    });
  }

  useEffect(() => {

    if(!anchor){
        dispatch({
          type: SET_DATA_ACNHOR,
          payload: core_data?.categories?.[0]?.categories?.find(
            (f) => f.priority === "primary"
          ).id,
        });
    }

  }, []);

  return (
    <div class="anchors">
      <Dropdown
        placeholder={anchor_list?.[0]?.text}
        options={anchor_list || []}
        // defaultValue={anchor_list[0].value}
        value={anchor}
        id="anchors"
        selection
        onChange={(e, t) => selectAnchor(t.value)}
      />
    </div>
  );
};

export default Anchor;

const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
  margin-top: -15px;
  margin-bottom: -3px;
`;
