import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";

import Representation from './Representation'
import Trends from './Trends'
import Correlation from './Correlation'

const titles = ['Representation Analytics', 'Relationship Analytics', 'Trend Analytics','Intervention Analytics']




const Inclusivity = () =>{

  const [sub,setSub] = useState(1)


	

	return (

	 <Container>

     <Top>
        <NavItem
          icon="home"
          title="Representation"
          selected={sub == 1}
          setSelected={() => setSub(1)}
        />
        <NavItem
          icon="chart bar"
          title="Relationships"
          selected={sub == 2}
          setSelected={() => setSub(2)}

        />
        <NavItem
          icon="table"
          title="Trends"
          selected={sub == 3}
          setSelected={() => setSub(3)}

        />
         <NavItem
          icon="table"
          title="Interventions"
          selected={sub == 4}
          setSelected={() => setSub(4)}

        />
      </Top>

      <Content>
          <Title>{titles[sub-1]}</Title>

          {sub==1?
              <Representation/>
               :

            sub==2 ?
              
            <Correlation/>

              :
              <Trends/>

            }
      </Content>


	      

       </Container>

		)
}


export default Inclusivity

const NavItem = (props) => {
  return (
    <NavContainer
      selected={props.selected}
      onClick={() => props.setSelected()}
      color={props.highlight}
      id={"nav_" + props.title}
    >
      <Title2>{props.title}</Title2>
    </NavContainer>
  );
};


const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top:30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
 font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
display: flex;
align-items: center;
text-transform: capitalize;

color: #414141;
  margin-bottom: 30px;
`;

const Card = styled.div`
  width: calc(100% - 360px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
`;

const Top = styled.div`
  display:flex;
  width:100%;
  border-bottom: 1px solid #DFDFDF;
  padding-left:58px;

`

const Title2 = styled.div`
  font-size: 12px;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 20px 0px 5px 0px;
  // color:{props=>props.selected?'#ab710a':'#9b9b9b'};
  color: #9E9FA1;
  border-bottom:${props => props.selected ? '2px solid #476DFA' : ""};
  margin-bottom:-1px;

  cursor: pointer;
`;

const Content = styled.div`
  padding-left:58px;
  margin-top:30px;
`



