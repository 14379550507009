import styled, { keyframes } from "styled-components";
import React from "react";
import { Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";
import Emotions from "data/emotion_profiles.json"
import Curiosity from "assets/images/curiosity.svg";
import Enjoyment from "assets/images/enjoyment.svg";
import Motivation from "assets/images/motivation.svg";
import Discontent from "assets/images/discontent.svg";
import Stress from "assets/images/stress.svg";
import Anger from "assets/images/anger.svg";
import { Link } from "react-router-dom"


const icons ={
  "Curiosity":Curiosity,
  "Enjoyment":Enjoyment,
  Motivation:Motivation,
  Discontent:Discontent,
  Stress:Stress,
  Anger:Anger
}


const EmotionCard = ({thisWeek, lastWeek, name,setMood, ...props}) =>{

	// console.log(thisWeek?.[name])

  const getChange = () =>{
    let change = Math.round(thisWeek?.[name]?.average - lastWeek?.[name]?.average)
    if(change){
      return change>0?'+'+change+'%':change+'%'
    }

    return 'N/A'
  }
	
	return (
     
		    <CardColor color={Emotions[name]?.color} onClick={()=>setMood(name.toLowerCase())}>

		      <Top>
		        <EmotionWord>{name}</EmotionWord>
		        	<Score>
							{getChange()}
							<Sub>Change this week</Sub>
					</Score>
      		</Top>

      		<Title>This week</Title>
      		<Progress percent={Math.round(thisWeek?.[name]?.average)} progress size='small' style={{marginBottom:15,marginTop:5}} color='grey'/>

      		<Title>Last Week</Title>
      		<Progress percent={Math.round(lastWeek?.[name]?.average)} progress size='small' style={{marginBottom:15,marginTop:5}} color='grey'/>
      		
      		<View>
      			View Analytics
      		</View>
      		</CardColor>
         
		)	
}

export default EmotionCard

const View = styled.div`
	width:100%;  
	font-family: "Red Hat Display", sans-serif;
	display:flex;
	margin-top:10px;
	 
`

const CardColor = styled.div`
 	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 	padding:30px 20px;
 	width: calc(33% - 24px);
 	margin-right:20px;
 	margin-top:20px;
 	background-image:${props=>'linear-gradient(to bottom right, rgba('+props.color+',0.4),rgba('+props.color+',0.3))'};
 	color:#4a4a4a;
 	position:relative;
 	cursor:pointer;

 	&:hover{
 		${View}{
 			text-decoration:underline;
 			font-weight:bold;
 		}
 		box-shadow: rgba(0, 0, 0, 0.7) 0px 1px 4px;
 	}

  @media (max-width:900px){
    width:300px;
  }

  @media(max-width:768px){
    width:100%;
  }

   

`




const Emotion = styled.div`
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  border-radius: 5px;
  width: calc(33% - 24px);
  padding: 15px 40px 5px 40px;
  position: relative;
  margin-bottom:20px;
  margin-top:10px;

  @media (max-width:768px){
    width:100%;
    padding:16px 10px 10px 25px;
  }
`;

const ColorStrip = styled.div`
  height: 100%;
  width: 8px;
  background-color: ${(props) => 'rgb('+props.color+')'};
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 10px 0 0 10px;
`;

const EmotionWord = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color: #4a4a4a;
  margin-right: 10px;
`;

const IMG = styled.img`
	height:30px;
`

const Change = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	font-family: "Red Hat Display", sans-serif;
  	font-size: 35px;
  	font-weight: bold;
  	padding:20px 0;
`

const Sub = styled.div`
	font-size:12px;
	font-weight:400;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:500;
	margin-bottom:5px;
    color::#4a4a4a;
`

const Score = styled.div`

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:18px;
  color:#4a4a4a;
  font-weight:bold;
`
const Top = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
`

const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`