import styled from "styled-components";
import React from "react";

import './loading.css'

const ChartLoading = () =>{

	return (
		<Container>
			<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
		</Container>
		)
}


export default ChartLoading


const Container = styled.div`
	height:100%;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
`