import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Icon,Progress,Dropdown } from 'semantic-ui-react'

const HumanLoop = () =>{

    const [selected,setSelect] = useState(null)
    return (
        <Container>
            <h1>Select the statement which you think is best</h1>

            <Row>
                <T1 selected={selected==1} onClick={()=>setSelect(1)}>
                <Title>Statement #1 {selected==1?<Icon name="check" style={{color:'green'}}/>:""}</Title>
                The first step that I would take in order to improve communication 
                within this organization is to focus on building a culture of 
                knowledge sharing. Every team should be encouraged to contribute 
                and empower open communications, so that all employees can be 
                informed as quickly as possible of any new developments or updates
                 in the business. To make the process easier, I would suggest setting
                  up a centralized system, such as an online forum or an internal 
                  social network, where company wide communication can be managed
                   in one place. This would also enable employees to more easily
                    track their communication, allowing them to keep up with any
                     new developments without wasting time. In addition to a
                      centralized system, I would also suggest celebrating open
                       and effective communication with rewards. Whether this
                        comes in the form of bonus points or something more
                         tangible, such as special discounts or free company
                          swag, celebrating open communication would encourage
                           employees to be more engaged and open with each other.
                            Finally, I would create a feedback loop process to
                             ensure employees can provide constructive comments
                              and critiques to further the development of communication
                               infrastructure. This would allow for improvement of communication
                                processes on a regular basis.
                </T1>

                <T1 selected={selected==2} onClick={()=>setSelect(2)}>
                <Title>Statement #2 {selected==2?<Icon name="check" style={{color:'green'}}/>:""}</Title>
                The first step that I would take in order to improve communication 
                within this organization is to focus on building a culture of 
                knowledge sharing. Every team should be encouraged to contribute 
                and empower open communications, so that all employees can be 
                informed as quickly as possible of any new developments or updates
                 in the business. To make the process easier, I would suggest setting
                  up a centralized system, such as an online forum or an internal 
                  social network, where company wide communication can be managed
                   in one place. This would also enable employees to more easily
                    track their communication, allowing them to keep up with any
                     new developments without wasting time. In addition to a
                      centralized system, I would also suggest celebrating open
                       and effective communication with rewards. Whether this
                        comes in the form of bonus points or something more
                         tangible, such as special discounts or free company
                          swag, celebrating open communication would encourage
                           employees to be more engaged and open with each other.
                            Finally, I would create a feedback loop process to
                             ensure employees can provide constructive comments
                              and critiques to further the development of communication
                               infrastructure. This would allow for improvement of communication
                                processes on a regular basis.
                </T1>
            </Row>

            <Row2>
                <Button>Next ></Button>
            </Row2>
            

        </Container>

    )
}


export default HumanLoop


const Container = styled.div`
    padding-left:58px;
    margin-top:30px;
`

const T1 = styled.div`
    width:50%;
    padding:20px;
    line-height:1.8;
    font-size:14px;
    cursor:pointer;
    border: 3px solid transparent;

    &:hover{
        border:3px solid green;
    }
`

const Row = styled.div`
    width:100%;
    display:flex;
`

const Row2 = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    padding-right:30px;
    margin-top:30px;
`

const Title = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:10px;
`

const Button = styled.div`
    color: #7033FF;
    cursor:pointer;
`