import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicInput from "./TextBoxColumn";
import { addIcon, trashIcon } from "components/Icons";
import MultiselectDropdown from "./MultiselectDropdown";
import DatePick from "./DatePicker";
import TableStatus from "./DropdownTable/TableStatus";
import { getColConfig, getNSOTableData } from "components/CWFPDF/pdfUtils";
// Actions
import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SET_QUESTIONS,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
} from "constants/actions";
import { calculate_otp_table_data } from "reports/Audit/data_navigation/calculate_data";
import SummaryTable from "./SummaryTable";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
} from "reports/Audit/data_navigation/calculate_data";

import translations from "components/CWFPDF/translations.json";

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const impactOptions = [
  {
    key: 1,
    value: "People",
    text: "People",
  },
  {
    key: 2,
    value: "System",
    text: "System",
  },
  {
    key: 3,
    value: "Culture",
    text: "Culture",
  },
];

const hpOutcomeOptions = [
  {
    key: 1,
    value: "Organizational Culture",
    text: "Organizational Culture",
  },
  {
    key: 2,
    value: "Clear Leadership",
    text: "Clear Leadership",
  },
  {
    key: 3,
    value: "Physical Safety",
    text: "Physical Safety",
  },
  {
    key: 4,
    value: "Recognition and Reward",
    text: "Recognition and Reward",
  },
  {
    key: 5,
    value: "Workload Management",
    text: "Workload Management",
  },
  {
    key: 6,
    value: "Growth and Development",
    text: "Growth and Development",
  },
  {
    key: 7,
    value: "Psychological support",
    text: "Psychological support",
  },
  {
    key: 8,
    value: "Promotion of Health",
    text: "Promotion of Health",
  },
];

const WellnessTable = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    onChange,
    id,
    editEnabled,
    dimensions,
    primary,
    handleWellnessData,
    savedTemplate,
  } = props;
  const [addSectionHovered, setAddSectionHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState();
  const [dynamicRows, setDynamicRows] = useState([[[]], [[]], [[]], [[]]]);
  const [calculatedData, setCalculatedData] = useState([]);
  const [reportData, setReportData] = useState();
  const [questionStructure, setQuestionStructure] = useState();
  const [colConfig, setColConfig] = useState();
  const [tableData, setTableData] = useState();

  // ******** Selectors ********
  const {
    survey_version,
    WhiteLabel,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    get_auth,
    get_survey_questions,
  } = useSelector(
    (state) => ({
      get_organizations: state.organizations,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_survey_structure: state.surveystructure.survey_structure,
      get_auth: state.auth,
      WhiteLabel: state.white_label?.white_label,
      survey_version: state.audit.survey_version,
      get_survey_questions: state.surveyquestions.survey_questions,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=8&status=2`,
    });
  }, []);

  useEffect(() => {
    if (!savedTemplate) {
      if (get_survey_structure && get_survey_structure[0]) {
        const DefState = build_default_State(get_survey_structure[0]);
        dispatch({
          type: SET_NAVIGATION,
          payload: DefState,
        });
        if (get_culture_audit_reports.response) {
          if (get_culture_audit_reports.response.length > 0) {
            let sorted_responses = get_culture_audit_reports.response.sort(
              (a, b) => b.id - a.id
            );

            if (survey_version) {
              sorted_responses = sorted_responses.filter(
                (f) => f.question.sort_order === survey_version.sort_order
              );
            }

            //   if (checkPersonality(get_organizations)) {
            //     //Modify the results to include personality as a categorical variable
            //     sorted_responses =
            //       convert_personality_to_categories(sorted_responses);
            //   }

            if (primary) {
              sorted_responses = sorted_responses.filter((responseGroup) => {
                return responseGroup?.name_uid === primary;
              });
            }

            let most_recent;
            most_recent = get_most_recent(sorted_responses);

            let total = {};
            most_recent.responses?.map((r) => {
              let n = r.categories.length.toString();
              if (!(n in total)) {
                total[n] = 1;
              } else {
                total[n] += 1;
              }
            });

            most_recent["last"] = get_last_responses(sorted_responses);

            if (sorted_responses.length > 0 && most_recent.responses) {
              let historical_data = sort_historical_data(
                sorted_responses.reverse(),
                0
              );

              setReportData([most_recent, ...historical_data]);

              // setComments(check_comments(most_recent, get_employees));

              dispatch({
                type: SET_RAW_DATA,
                payload: [most_recent, ...historical_data],
              });

              dispatch({
                type: SET_DATA_DATES,
                payload: get_dates(historical_data),
              });
            }
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    survey_version,
    primary,
  ]);

  useEffect(() => {
    if (!data?.planData[3]) {
      if (get_auth?.organization_id) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=5&organization=${get_auth?.organization_id}`,
        });
      }
    }
  }, [get_auth?.organization_id]);

  /** useEffect Hooks
   * 1. Calculate data for table
   * 2. Get column config for table
   * 3. Get table data
   * 4. Get URI
   * 5. Get Spider options
   */
  useEffect(() => {
    if (!savedTemplate) {
      if (reportData && get_survey_structure?.[0] && questionStructure) {
        const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : 2;
        const secondary = get_survey_structure[0]?.categories?.find(
          (category) => category.priority === "secondary"
        );

        setCalculatedData(
          calculate_otp_table_data(
            reportData,
            get_survey_structure[0],
            secondary?.id,
            orgRule,
            questionStructure,
            primary,
            true
          )
        );
      }
    }
  }, [reportData, get_survey_structure[0], questionStructure, primary]);

  // 2. Get column config for table
  useEffect(() => {
    if (get_survey_structure?.[0]) {
      setColConfig(getColConfig(get_survey_structure[0], "en"));
    }
  }, [get_survey_structure[0]]);

  //   // 3. Get summary table data
  useEffect(() => {
    if (!savedTemplate) {
      if (
        calculatedData &&
        calculatedData?.responses?.length > 0 &&
        get_survey_structure?.[0] &&
        colConfig
      ) {
        const tableDataArr = getNSOTableData(
          calculatedData,
          get_survey_structure[0],
          questionStructure,
          colConfig,
          "en",
          translations
        );
        setTableData(tableDataArr);
        handleWellnessData(tableDataArr);
      } else {
        setTableData();
        handleWellnessData();
      }
    }
  }, [
    calculatedData,
    colConfig,
    get_survey_structure[0],
    questionStructure,
    primary,
  ]);

  useEffect(() => {
    if (editEnabled) {
      onChange(dynamicRows, id);
    }
  }, [dynamicRows]);

  useEffect(() => {
    if (survey_version) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues;

        if (survey_version) {
          SurveyQues = {
            ...get_survey_questions.filter(
              (sq) => sq.sort_order === survey_version.sort_order
            )?.[0],
          };
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };
        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (survey_version) {
        SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === survey_version.sort_order
          )?.[0],
        };
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, survey_version]);

  useEffect(() => {
    if (data) {
      setDynamicRows(data?.planData[id]);
      if (data?.planData[3] && !tableData) {
        setTableData(data?.planData[3]);
      }
    }
  }, [data]);

  const addRow = (f) => {
    const _rows = [...dynamicRows];
    if (!_rows[f]) {
      _rows[f] = [];
    }
    _rows[f].push([]);
    setDynamicRows(_rows);
  };

  const removeRow = (i, f) => {
    const _rows = [...dynamicRows];
    _rows?.[f]?.splice(i, 1);
    setDynamicRows(_rows);
  };

  const handleInputChange = (d, i, f, idx) => {
    // set the inputText at the particular index of the array
    //   if the index doesn't exist, create it
    const _inputText = [...dynamicRows];
    if (!_inputText[f][i]) {
      _inputText[f][i] = [];
    }
    _inputText[f][i][idx] = d;

    setDynamicRows(_inputText);
  };

  const handleStatusChange = (d, i, f, idx) => {
    const tableData = [...dynamicRows];
    if (!tableData[f][i]) {
      tableData[f][i] = [];
    }
    tableData[f][i][idx] = d;
    setDynamicRows(tableData);
  };

  const handleDatesSelected = (date, id, f, idx) => {
    if (date) {
      const tableData = [...dynamicRows];

      if (!tableData[f][id]) {
        tableData[f][id] = [];
      }
      tableData[f][id][idx] = date;
      setDynamicRows(tableData);
    }
  };

  const getRows = () => {
    return dynamicRows;
  };

  const handleDropdownChange = (d, i, f, idx) => {
    const tableData = [...dynamicRows];
    if (!tableData[f][i]) {
      tableData[f][i] = [];
    }
    tableData[f][i][idx] = d;
    setDynamicRows(tableData);
  };

  return (
    <ComponentContainer>
      {tableData && (
        <DataTableContainer>
          <SummaryTable
            title={"Domain Overall Scores"}
            title2="By Roles"
            headers={["Athlete", "Coach", "SSSM", "NSO Staff", "Other"]}
            data={tableData[0]}
            isFrench={false}
            average={tableData[0]?.dimensionAvg}
          />
        </DataTableContainer>
      )}
      {dimensions?.map((wd, f) => (
        <FactorTable>
          <DescriptionContainer>
            <Factor>{wd?.title}</Factor>
            <Description>{wd?.first}</Description>
            <Description>{wd?.second}</Description>
          </DescriptionContainer>
          {getRows()?.[f]?.map((r, i) => {
            const altBackground = i % 2 === 0;
            const rowIsHovered = hoveredRow === `${i}-${f}`;
            return (
              <TableWrapper>
                <TableContainer>
                  <TableRow>
                    <RowHeader style={{ width: 160 }}>NSO Initiative</RowHeader>
                    <RowHeader style={{ width: 225 }}>
                      Description of Action
                    </RowHeader>
                    <RowHeader style={{ width: 175 }}>HP Outcome(s)</RowHeader>
                    <RowHeader style={{ width: 145 }}>HP Impact(s)</RowHeader>
                    <RowHeader style={{ width: 135 }}>Lead Personnel</RowHeader>
                  </TableRow>
                  <>
                    <TableRow
                      hideBorder={true}
                      altBg={true}
                      onMouseOver={() => setHoveredRow(`${i}-${f}`)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <TableCol
                        altBg={true}
                        style={{
                          width: 160,
                          maxHeight: "100%",
                        }}
                      >
                        {/* {r && !dynamic && <ColTitle>{r}</ColTitle>} */}
                        {(editEnabled && (
                          <BasicInput
                            altBg={true}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[0] || ""
                            }
                            onChange={(d) => handleInputChange(d, i, f, 0)}
                            placeholder="Enter details"
                            hideFocus={true}
                            editEnabled={editEnabled}
                            inTable={true}
                          />
                        )) || (
                          <TextWrapper altBg={altBackground}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[0] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      <TableCol
                        altBg={true}
                        style={{
                          width: 225,
                          maxHeight: "100%",
                        }}
                      >
                        {(editEnabled && (
                          <BasicInput
                            altBg={true}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[1] || ""
                            }
                            onChange={(d) => handleInputChange(d, i, f, 1)}
                            placeholder="Enter details"
                            hideFocus={true}
                            editEnabled={editEnabled}
                            inTable={true}
                          />
                        )) || (
                          <TextWrapper altBg={true}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[1] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      <TableCol
                        altBg={true}
                        style={{
                          width: 165,
                          maxHeight: "100%",
                        }}
                      >
                        <MultiselectDropdown
                          data={props.data?.planData?.[id]?.[f]?.[i]?.[2] || ""}
                          editEnabled={editEnabled}
                          options={hpOutcomeOptions}
                          inTable={true}
                          onSelect={(d) => handleDropdownChange(d, i, f, 2)}
                        />
                      </TableCol>
                      <TableCol
                        altBg={true}
                        style={{
                          width: 155,
                          paddingLeft: 10,
                          maxHeight: "100%",
                        }}
                      >
                        <MultiselectDropdown
                          data={props.data?.planData?.[id]?.[f]?.[i]?.[3] || ""}
                          editEnabled={editEnabled}
                          options={impactOptions}
                          inTable={true}
                          onSelect={(d) => handleDropdownChange(d, i, f, 3)}
                        />
                      </TableCol>
                      <TableCol
                        altBg={true}
                        style={{
                          width: 135,
                          maxHeight: "100%",
                        }}
                      >
                        {(editEnabled && (
                          <BasicInput
                            altBg={true}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[4] || ""
                            }
                            onChange={(d) => handleInputChange(d, i, f, 4)}
                            placeholder="Enter details"
                            hideFocus={true}
                            editEnabled={editEnabled}
                            inTable={true}
                          />
                        )) || (
                          <TextWrapper altBg={true}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[4] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      {editEnabled && (
                        <RemoveRowContainer
                          isRowFocused={rowIsHovered}
                          onClick={() => removeRow(i, f)}
                        >
                          {trashIcon()}
                        </RemoveRowContainer>
                      )}
                    </TableRow>
                    <TableRow hideBorder={true} altBg={false}>
                      <RowHeader style={{ minWidth: 160, maxWIdth: 160 }}>
                        Budget
                      </RowHeader>
                      <RowHeader style={{ minWidth: 160, maxWIdth: 160 }}>
                        Timeline
                      </RowHeader>
                      <RowHeader style={{ minWidth: 160, maxWIdth: 160 }}>
                        Status
                      </RowHeader>
                    </TableRow>
                    <TableRow hideBorder={true} altBg={false}>
                      <TableCol
                        altBg={false}
                        style={{
                          minWidth: 160,
                          maxWidth: 160,
                        }}
                      >
                        <div style={{ marginTop: -45, height: "100%" }}>
                          {(editEnabled && (
                            <BasicInput
                              altBg={false}
                              hideBorders={true}
                              value={
                                props.data?.planData?.[id]?.[f]?.[i]?.[5] || ""
                              }
                              onChange={(d) => handleInputChange(d, i, f, 5)}
                              placeholder="Enter budget"
                              hideFocus={true}
                              editEnabled={editEnabled}
                            />
                          )) || (
                            <TextWrapper altBg={false}>
                              {props.data?.planData?.[id]?.[f]?.[i]?.[5] || ""}
                            </TextWrapper>
                          )}
                        </div>
                      </TableCol>
                      <TableCol
                        altBg={false}
                        style={{
                          minWidth: 160,
                          maxWidth: 160,
                        }}
                      >
                        <div style={{ marginTop: -100, height: "100%" }}>
                          <DatePick
                            handleDateChange={(date) =>
                              handleDatesSelected(date, i, f, 6)
                            }
                            {...props}
                            date={
                              props.data?.planData?.[id]?.[f]?.[i]?.[6] || null
                            }
                          />
                        </div>
                      </TableCol>

                      <TableCol altBg={false} style={{ maxWidth: 100 }}>
                        <div style={{ marginTop: -10, height: "100%" }}>
                          <TableStatus
                            selectedOption={
                              planStatus?.find(
                                (s) =>
                                  s?.value ===
                                  props.data?.planData?.[id]?.[f]?.[i]?.[7]
                              )?.text || ""
                            }
                            setStatus={(d) => handleStatusChange(d, i, f, 7)}
                            editEnabled={editEnabled}
                          />
                        </div>
                      </TableCol>
                    </TableRow>
                  </>
                </TableContainer>
              </TableWrapper>
            );
          })}
          {editEnabled && (
            <AddRowContainer isHovered={true}>
              <AddContainer onClick={() => addRow(f)}>
                Add Row&nbsp;&nbsp;{addIcon()}
              </AddContainer>
            </AddRowContainer>
          )}
        </FactorTable>
      ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

// Position the title in the top corner of the column it resides in
const ColTitle = styled.div`
  position: absolute;
  top: -12px;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  padding: 5px;
  color: #2a3039;
`;

const DataTableContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  border-radius: 5px;
  padding: 10px;
  min-height: 80px;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  border-radius: 5px;
  width: 100%;
  border-collapse: collapse;
`;

const AddContainer = styled.div`
  cursor: pointer;
  color: #2d70e2;
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  position: relative;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #DFDFDF"};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  padding-bottom: 10px;
  padding-top: 10px;
  flex-direction: row;
  &:last-child {
    border-bottom: none;
  }
`;

const RemoveRowContainer = styled.div`
  opacity: ${(props) => (props.isRowFocused ? "1" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -30px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  z-index: 999;
  top: 40px;
`;

const RowHeader = styled.th`
  font-weight: bold;
  font-size: 12px;
  // margin: 10px;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

const TableCol = styled.td`
  font-size: 14px;
  position: relative;
  // margin: 10px;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
`;

const AddRowContainer = styled.div`
  opacity: ${(props) => (props.isHovered ? "1" : "0.7")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  margin-top: 0px;
`;

const FactorTable = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const QuestionContainer = styled.div`
  margin-bottom: 20px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Factor = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Raleway;
`;

const Description = styled.div`
  font-size: 14px;
  font-family: Raleway;
  margin-bottom: 10px;
`;

export default WellnessTable;
