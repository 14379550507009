import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon,Progress } from 'semantic-ui-react'

const TeamMember = ({completed}) =>{

	return (
		<Member>
			{completed?<Icon name='check' style={{color:'white'}}/>
			:<div style={{width:20}}/>}
			<Avatar src={"https://stg-app.innerlogic.com/static/media/user.1416ec52.svg"} />
			<Name>Michael Bawol</Name>
		</Member>
		)
}


const Completion = () =>{

	return (
		<Container>
			<Title>Team Completion </Title>
			 <Progress percent={66} inverted color='grey' progress size='small' />
			<TeamMember completed={true}/>
			<TeamMember completed={true}/>
			<TeamMember completed={true}/>
			<TeamMember completed={true}/>
			<TeamMember completed={true}/>
			<TeamMember completed={true}/>
			<TeamMember completed={false}/>
			<TeamMember completed={false}/>
			<TeamMember completed={false}/>
			<TeamMember completed={false}/>
			<TeamMember completed={false}/>
		</Container>
		)
}


export default Completion



const Container = styled.div`
	width:100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:30px;
	margin-top:20px;
	background-color:#15056b;
`

const Title = styled.div`
	color:white;
	font-weight:bold;
	font-size:18px;
`

const Average = styled.div`
	font-size:50px;
	font-weight:bold;
	width:100%;
	padding:20px 0px;
	display:flex;
	justify-content:center;
`

const Description = styled.div`
	width:100%;
	justify-content:center;
	display:flex;
`

const Member = styled.div`
	width:100%;
	display:flex;
	align-item:center;
	margin-top:10px;
`

const Avatar = styled.img`
	width:20px;
	height:20px;
	border-radius:10px;
	margin-right:5px;
`

const Name = styled.div`
	font-size:12px;
	color:white;
`