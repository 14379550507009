import styled, { keyframes } from 'styled-components';
import React from "react";
import LogoIMG from 'assets/images/logo_full.png'
import {Icon} from 'semantic-ui-react'


const Success = () =>{

    return (
        <Container>
            {/* <IMG src={LogoIMG}/> */}
            <Icon name='check circle' size='huge' style={{color:'#27CDA7'}}/>
            <T1>Congratulations!</T1>
            <Text>
                We're generating your plan as we speak. This process usually takes about 5 minutes, and once completed, we will email you to let you know.
            </Text>
        </Container>
    )

}

export default Success


const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    `;

const Container = styled.div`
    font-family:'Raleway';
    margin-top:50px;

    `

const IMG = styled.img`
    width:120px;
    margin-bottom:20px;
    `

const T1 = styled.div`
    font-size:24px;
    color:#2A3039;
    margin-bottom:20px;
    font-weight:600;
    margin-top:30px;
    `

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    line-height:1.5;
    `

