import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {Loader} from 'semantic-ui-react'

const QA = ({question,answer,setAnswer,loading}) =>{



    return (
        <Container>
            <Question>
                {loading? <Loader inline active/>:question}
            </Question>
            <TextArea
                placeholder="Type your answer here..."
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
            />
        </Container>
    )
}


export default QA


const Container = styled.div`
    height:200px;
`

const Question = styled.div`
    font-family:'Raleway';
    font-size:16px;
    font-weight:500;
    margin-bottom:20px;
    color:#2A3039;
    line-height:1.5;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:16px;
    font-weight:400;
    margin-bottom:60px;
`