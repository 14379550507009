import styled, { keyframes } from "styled-components";
import React from "react";
import { Dropdown } from 'semantic-ui-react'

import Pic1 from 'assets/images/calendar.png'
import Pic2 from 'assets/images/google-docs.png'

const Type = (props) =>{

	return (
		<TypeContainer>
			<div>
			<TypeName>{props.name}</TypeName>
			<Description> sdhf sdjfdhs skhfdsjkdfhs jshd fhf jks jhf ksfhd</Description>
			<See>Learn more</See>
			</div>
			<div>
				<Image src={props.name=='Game'?Pic1:Pic2} />
			</div>
		</TypeContainer>
		)
}

const History = () =>{


	return (
		<Container>
			<Title>Debriefs</Title>
			<DropContainer>
				<Dropdown
				    placeholder='Select Team'
				    fluid
				    selection
				    options={friendOptions}

				  />
			</DropContainer>

			<Types>
				<Type
					name={'Game'}/>
				<Type
					name={'Practice'}/>
			</Types>
			<Table>
				<TR>
					<TH width={20}>Type</TH>
					<TH width={30}>Date</TH>
					<TH width={20}>Status</TH>
					<TH width={30}>Completion</TH>
				</TR>

				{[0,1,2,3,4,5,6,7,8].map((item,i)=>{
					return (
						<TR>
							<TD width={20}>
								<SmallImg src={i<6?Pic1:Pic2}/>
								<T1>{i<6? 'Game': 'Practice'}</T1>
							</TD>
							<TD width={30}>October 14th, 2021</TD>
							<TD width={20}>Completed</TD>
							<TD width={30}>
							
								<div>10 / 12</div>
								<View>View</View>
							</TD>
						</TR>
						)
				})}

			</Table>

		</Container>
		)
}


export default History


const Container = styled.div`
	margin-top:50px;
	font-family: 'Red Hat Display', sans-serif;
`

const Title = styled.div`
		font-size:26px;
	font-weight:bold;
	margin-right:20px;
	
`
const DropContainer = styled.div`
	width:200px;
	margin-top:20px;
`
const Types = styled.div`
	width:100%;
	display:flex;
`

const TypeContainer = styled.div`
	width:400px;
	margin-right:30px;
	margin-top:20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display:flex;
	padding:30px;
`
const TypeName = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
`

const Description = styled.div`
	color:#757575;
`

const Image = styled.img`
	width:100px;
`

const Table = styled.div`
	margin-top:30px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
`

const TR = styled.div`
	display:flex;
	padding:15px 10px;
	width:100%;
`

const TH = styled.div`
	width:${props=>props.width+'%'};
	font-weight:bold;
	border-bottom:1px solid #9b9b9b;
	padding-bottom:5px;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	position:relative;
`

const T1 = styled.div`
	font-weight:500;
	font-size:18px;
`

const SmallImg = styled.img`
	width:30px;
	margin-right:10px;
`

const See = styled.div`
	margin-top:10px;
	color:#6a00ff;

`

const View = styled.div`
	padding:5px 35px;
	background-color:#6a00ff;
	color:white;
	border-radius:20px;
	font-weight:bold;
	position:absolute;
	right:0;
	cursor:pointer;
`

const friendOptions = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess'
  },
  {
    key: 'Elliot Fu',
    text: 'Elliot Fu',
    value: 'Elliot Fu'
  },
  {
    key: 'Stevie Feliciano',
    text: 'Stevie Feliciano',
    value: 'Stevie Feliciano'
  },
  {
    key: 'Christian',
    text: 'Christian',
    value: 'Christian'
  },
  {
    key: 'Matt',
    text: 'Matt',
    value: 'Matt'
  },
  {
    key: 'Justen Kitsune',
    text: 'Justen Kitsune',
    value: 'Justen Kitsune'
  },
]