import styled, { keyframes } from 'styled-components';
import React from "react";



const Component12 = () =>{


    return (
        <Container>

            <T1>Connecting culture to your departments objectives.</T1>
            <Text delay={0.5}>
          This plan is specifically designed to help you achieve your departments objectives. Here is how we will accomplish that:
            </Text>

            <Goal delay={0.5}><strong>Quarterly goal:</strong> Finishing the .NET integration for our largest customer.</Goal>

            <T2 delay={0.6}>How your plan to improve your values scores will help you achieve this goal.</T2>
            <UL delay={0.6}>
                <LI>
                <strong>Alignment with Organizational Values and Project Success Rate:</strong> Research shows that development projects in organizations where there is a strong alignment between team values and organizational values have a 33% higher success rate. This means that if the development department's values align well with those of the organization, particularly in terms of innovation, quality, and teamwork, there is a significantly higher chance of successfully completing the .NET integration within the next quarter.
                </LI>

                <LI>
                <strong>Cultural Cohesion and Development Speed:</strong> Studies indicate that development teams operating within a cohesive cultural environment, where members share common values and understand organizational goals, can accelerate project timelines by up to 25%. For the development department, fostering a culture that values collaboration, agility, and a shared sense of purpose could lead to faster completion of the .NET integration project, potentially beating the quarter deadline.
                </LI>
                <LI>
                    <strong>Employee Engagement and Productivity:</strong>Data from organizational behavior research suggests that departments with high employee engagement, driven by shared values and cultural alignment, are 21% more productive. In the context of the development department, engaging employees through a culture that emphasizes continuous learning, respect for individual contributions, and a shared commitment to excellence could increase productivity levels, thus facilitating the on-time or early completion of the .NET integration project.
                   
                </LI>
            </UL>
            

           
           

            
        </Container>
    )
}

export default Component12

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Goal = styled.div`
    font-size:16px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    background-color:#D4F5ED;
    padding:20px;
    border-radius:10px;
    margin-top:20px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    color:#2A3039;
    padding-bottom:10px;
    display:inline-block;
    margin-top:20px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const UL = styled.ol`
  margin-top:10px;
  opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const LI = styled.li`
  margin-bottom:20px;
  line-height:1.4;
`