import React, { useEffect, useState } from "react";

import {
  Sent
} from "../styled-list";


const InvitedUser = (props) =>{
	

	return (
			<div
              style={{
                marginBottom: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
                  {props.data.email}
             <Sent>Sent</Sent>
           </div>
                )
}

export default InvitedUser