import styled, { keyframes } from "styled-components";
import React from "react";
import { Popup, Dimmer, Loader, Select } from "semantic-ui-react";

import { calculateHeatData } from "./calculations";

const SquareComponent = ({ width, color, opacity, score }) => {
  if (score > 0) {
    return (
      <Popup
        content={"Score:" + Math.floor(score * 10) + "%"}
        position="top center"
        inverted
        trigger={<Square width={width} color={color} opacity={opacity} />}
      />
    );
  } else {
    return <EmptySquare width={width} color={color} opacity={opacity} />;
  }
};

const MAX_WIDTH = "10%";

const create_option_list = (categories, questions, type) => {
  let options = [];

  categories.categories.forEach((item) => {
    if (type === 1 || (type === 2 && item?.value?.priority !== "primary")) {
      options.push({ text: item.name, value: item });
    }
  });

  if (type === 2 && questions) {
    const factorsFormatted = questions.factors.map((factor, index) => {
      return {
        id: index + 1,
        name: factor.title,
      };
    });
    const updatedQues = {
      id: -1,
      name: "EDI Questions",
      options: factorsFormatted,
      ...{ priority: "questions" },
    };

    options.push({
      text: "EDI Questions",
      value: updatedQues,
    });
  }

  return options;
};

const HeatMap = ({
  data,
  categories,
  anchor,
  questions,
  nav,
  rule,
  data2,
  color,
}) => {
  const COLOR = "#3238a8";
  const [titles, setTitles] = React.useState([]);
  const [width, setWidth] = React.useState(3);
  const [loading, setLoading] = React.useState(true);
  const anchor_list = create_option_list(categories, questions, 1);
  const anchor_list2 = create_option_list(categories, questions, 2);

  const [horizontal, setHorizontal] = React.useState(
    anchor_list.find((f) => f.value.priority === "secondary").value
  );
  const [vertical, setVertical] = React.useState(
    anchor_list.find((f) => {
      return f.value.priority === "primary";
    }).value
  );

  const [calculated, setCalculated] = React.useState(
    calculateHeatData(data2, categories, rule, questions, horizontal, vertical)
  );

  React.useEffect(() => {
    let isQuestion = horizontal.priority === "questions";

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 600);

    if (isQuestion) {
      setWidth(questions.factors.length);
      setTitles(questions.factors);
    } else {
      setWidth(
        categories.categories.find((f) => {
          return f.id === horizontal.id;
        }).options.length
      );
      setTitles(
        categories.categories.find((f) => f.id === horizontal.id).options
      );
    }
    return setCalculated(
      calculateHeatData(
        data2,
        categories,
        rule,
        questions,
        horizontal,
        vertical
      )
    );
  }, [vertical, horizontal, nav]);

  const getOpacity = (score) => {
    if (score > 9) {
      return 0.1;
    }
    if (score > 7.5) {
      return 0.2;
    }
    if (score > 6) {
      return 0.6;
    }
    if (score > 5) {
      return 0.9;
    }
    if (score <= 5) {
      return 1;
    }
  };

  const handleVerticalChange = (e, t) => {
    setVertical(t.value);
  };

  if (!calculated) {
    return (
      <Dimmer active={loading} inverted>
        <Loader inverted />
      </Dimmer>
    );
  }

  if (calculated.length === 0) {
    return <div />;
  }

  return (
    <Container>
      <Dimmer active={loading} inverted>
        <Loader inverted />
      </Dimmer>
      <OptionArea>
        <div style={{ marginRight: 10 }}>
          <Label>Vertical Anchors</Label>
          <Select
            placeholder="Select Survey Type"
            options={anchor_list}
            value={vertical}
            id="anchors"
            onChange={(e, t) => handleVerticalChange(e, t)}
          />
        </div>

        <div>
          <Label>Horizontal Anchors</Label>
          <Select
            placeholder="Select Survey Type"
            options={anchor_list2}
            value={horizontal}
            id="anchors"
            onChange={(e, t) => setHorizontal(t.value)}
          />
        </div>
      </OptionArea>
      <TopRow>
        <div style={{ width: "100px" }} />
        {titles.map((item) => {
          return (
            <TH width={width}>
              {horizontal.priority === "questions" ? item.title : item.name}
            </TH>
          );
        })}
      </TopRow>
      {calculated.map((item) => {
        return (
          <Row>
            <Title>{item.title}</Title>
            {titles.map((t) => {
              let anchor_data = item.role_data.find((f) => {
                if (horizontal.priority === "questions") {
                  return Number(f.id) + 1 === t.id;
                }
                return f.id === t.id;
              });

              if (anchor_data) {
                return (
                  <SquareComponent
                    width={width}
                    color={color}
                    opacity={getOpacity(anchor_data.score)}
                    score={anchor_data.score}
                  />
                );
              }
              return (
                <SquareComponent
                  width={width}
                  color={color}
                  opacity={0}
                  score={0}
                />
              );
            })}
          </Row>
        );
      })}

      <Legend>
        <LI>
          under 50%
          <Color opacity={1} color={color} />
        </LI>
        <LI>
          50-60%
          <Color opacity={0.9} color={color} />
        </LI>
        <LI>
          60-75%
          <Color opacity={0.6} color={color} />
        </LI>
        <LI>
          75-90%
          <Color opacity={0.2} color={color} />
        </LI>
        <LI>
          90-100%
          <Color opacity={0.1} color={color} />
        </LI>
      </Legend>
    </Container>
  );
};

export default HeatMap;

const Container = styled.div`
  width: 700px;
  margin-top: 50px;
  font-family: "Barlow Semi Condensed", sans-serif;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: width 1s;
`;

const Title = styled.div`
  width: 100px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

const Square = styled.div`
  height: 50px;
  width: ${(props) => "calc((100% - 150px) / " + props.width + ")"};
  max-width: ${MAX_WIDTH};
  background-color: ${(props) => props.color};
  border-radius: 4px;
  opacity: ${(props) => props.opacity};
  cursor: pointer;
  margin-right: 10px;
`;

const EmptySquare = styled.div`
  height: 50px;
  width: ${(props) => "calc((100% - 150px) / " + props.width + ")"};
  max-width: ${MAX_WIDTH};
  background-color: ${(props) => props.color};
  border-radius: 4px;
  opacity: ${(props) => props.opacity};
  margin-right: 10px;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`;

const TH = styled.div`
  width: ${(props) => "calc((100% - 100px) / " + props.width + ")"};
  max-width: ${MAX_WIDTH};
  justify-content: center;
  display: flex;
  text-align: center;
  margin-right: 10px;
  font-size: 12px;
`;

const Legend = styled.div`
  display: flex;
  margin-top: 40px;
  padding-left: 100px;
`;

const LI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
`;

const Color = styled.div`
  background-color: ${(props) => props.color};
  height: 15px;
  width: 80px;
  border-radius: 5px;
  opacity: ${(props) => props.opacity};
`;

const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
  margin-top: -15px;
  margin-bottom: -3px;
`;

const OptionArea = styled.div`
  display: flex;
`;
