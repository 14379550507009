import React, { useState } from "react";
import styled from "styled-components";

const TextInput = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextboxContainer>
      <Title>{props.title}</Title>
      {(props.editEnabled && (
        <InputWrapper isFocused={isFocused}>
          <Input
            placeholder={props.placeholder}
            value={props.data?.[props.id]}
            onChange={(e) => props.onChange(e.target.value, props.id)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        </InputWrapper>
      )) || (
        <UneditableText>
          {props.data?.[props.id] || "None given"}
        </UneditableText>
      )}
    </TextboxContainer>
  );
};

const Title = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TextboxContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => (props.isFocused ? "#2D70E2" : "#e0e0e0")};
  padding: 16px;
  width: 300px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  width: 100%;
  font-family: Raleway;
  font-size: 14px;
  height: 10px;

  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;

const UneditableText = styled.div`
  color: #666d79;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-left: 10px;
`;

export default TextInput;
