import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import {Table} from 'semantic-ui-react'
import LabelModal from '../LabelModal'

const Labels = () =>{

    return (

        <Container>
            <ButtonContainer>
                <LabelModal/>
            </ButtonContainer>
            
             <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Label</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell># of data points</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Accidents</Table.Cell>
                        <Table.Cell>The number of reported accidents for a quarter per location</Table.Cell>
                        <Table.Cell>104</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Store customer satisfaction</Table.Cell>
                        <Table.Cell>Total score of customer satisfaction per store location</Table.Cell>
                        <Table.Cell>833</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Average drive through wait times</Table.Cell>
                        <Table.Cell>The average drive through wait time, per day for a location</Table.Cell>
                        <Table.Cell>10,456</Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
        </Container>

    )

}

export default Labels

const Container = styled.div`

`

const ButtonContainer = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-bottom:20px;
`