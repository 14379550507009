import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon, Button, Message, Modal, Header, Input, Divider, Grid, Segment } from 'semantic-ui-react'

// import CategoryCard from "./CategoryCard";
import CopyCategory from "./CopyCategory";
import CreateCategory from "./CreateCategory";
import NewEditCategory from "./NewEditCategory";
import Summary from "./Summary";
import ListJson from "./ListJson";

// import {
//   all_category,
//   category1,
//   category2,
//   category3,
//   category4,
//   category5,
// } from "data/sample/survey_structure";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_EDIT_SURVEY_STRUCTURE,
  SG_ADD_SURVEY_STRUCTURE,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_CATEGORIES,
} from "constants/actions";


const Categories = () => {
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState(false);

  const [surveyStruc, setSurveyStruc] = useState({})
  const [surveyStrucRow, setSurveyStrucRow] = useState([])
  const [rowId, setRowId] = useState(0)
  const [openNewCategory, setOpenNewCategory] = useState(false)
  const [errorList, setErrorList] = useState([])

  const EmployeeId = useSelector((state) => Number(state.auth?.employee_id), shallowEqual)
  const AmbassadorId = useSelector((state) => Number(state.auth?.ambassador_id), shallowEqual)
  const [surveyStructureId, setSurveyStructureId] = useState(false)

  const [openSummary, setOpenSummary] = useState(false)
  const [openNameEdit, setOpenNameEdit] = useState(false)
  const [openNameChanged, setOpenNameChanged] = useState(false)

  const { get_auth, get_selectedOrg, 
    get_survey_structure,
    get_survey_structure_copied } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_selectedOrg: state.selectedOrg,
      get_team_access: state.team_access.team_access,
      get_survey_structure: state.surveystructure.survey_structure,
      get_survey_structure_copied: state.surveystructure.survey_structure_copied,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );


  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
    } else {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
      });
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
      });
    }
  }, [dispatch, AmbassadorId, organizationId]);

  function SaveSurveyStructure() {
    // const survey_structure_id = ;
    if (surveyStructureId) {
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          categories: surveyStrucRow,
        },
      });
    }
  }
  
  useEffect(() => {
    if (get_survey_structure_copied?.new_survey_structure_id > 0){
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
      });
    }
  }, [get_survey_structure_copied, dispatch]);

    useEffect(() => {
    if (get_survey_structure?.[0]?.id) {
      // 
      setSurveyStructureId(get_survey_structure?.[0]?.id)
      setSurveyStrucRow(get_survey_structure?.[0]?.categories);
      setOpenNameChanged(get_survey_structure?.[0]?.name)
    }
  }, [get_survey_structure]);

  useEffect(() => {
    if (surveyStrucRow) {
      // check for the following
      // one primary exists
      const ErrorMessage = []
      let PrimaryFound = false
      let SecondaryFound = false
      surveyStrucRow.map(row => {
        if (row.priority === "primary" && !PrimaryFound) {
          PrimaryFound = true

        }
        if (row.priority === "secondary" && !SecondaryFound) {
          SecondaryFound = true

        }
        return null
      })

      if (!PrimaryFound) {
        ErrorMessage.push("No row with Priority Primary Found, atleast one row with Priority Primary is essenstial")
      }
      if (!SecondaryFound) {
        ErrorMessage.push("No row with Priority Secondary Found, atleast one row with Priority Secondary is essenstial")
      }
      // one secondary exists
      // console.log(ErrorMessage)
      setErrorList(ErrorMessage)
    }
  }, [surveyStrucRow]);

  // console.log(surveyStrucRow)
  function EditName() {
    // console.log(openNameChanged)
    if (surveyStructureId && openNameChanged.length > 3) {
      dispatch({
        type: SG_EDIT_SURVEY_STRUCTURE,
        payload: {
          id: surveyStructureId,
          name: openNameChanged,
        },
      });
      setOpenNameEdit(false)
    }

  }

  if (!surveyStructureId) {
    return (
      <Segment placeholder>
        <Grid columns={2} relaxed='very' stackable>
          <Grid.Column>
            <CenteredObject>
              <CopyCategory
                organizationId={organizationId}
                trigger={<StyledButton>+ Copy Another Category</StyledButton>}
              />

            </CenteredObject>

          </Grid.Column>

          <Grid.Column verticalAlign='middle'>
            <CenteredObject>
              <CreateCategory
                organizationId={organizationId}
                trigger={<StyledButton>+ Create New Category</StyledButton>}
              />

            </CenteredObject>

          </Grid.Column>
        </Grid>
        <Divider vertical>Or</Divider>
      </Segment>
    )
  }

  return (
    <Container>
      <Message
        color='orange'
        icon='warning sign'
        header='Do not Modify before you read this'
        content={
          `This data is crucial for the entire survey, please 
          donot change or add anything to this page without 
          consulting support team. Any errors would appear below this message. 
          Please note even if there are no errors things may break if you
          change this file without proper knowledge. To modify please contact
          support.
        `}
      />
      {errorList && errorList.length > 0 && <Message floating color='red'>
        <Message.Header>Errors</Message.Header>
        <Message.List items={errorList} />

      </Message>}

      {openNameEdit ?
        <CenterContent>
          <Input

            placeholder='Category Name'
            name="name"
            defaultValue={openNameChanged}
            // value={get_survey_structure?.[0]?.name}
            onChange={(v) => setOpenNameChanged(v.target.value)}
          />
          <Button circular color='green' icon='check'
            onClick={EditName} />
          <Button circular color='red' icon='close'
            onClick={() => setOpenNameEdit(false)} />

        </CenterContent> :

        <Header as='h3' icon textAlign='center'>
          <Icon name='file alternate outline' circular />
          <Header.Content onClick={() => setOpenNameEdit(true)}>
            {openNameChanged}
          </Header.Content>
        </Header>
      }
      <NewEditCategory
        surveyStruc={surveyStruc}
        setSurveyStruc={setSurveyStruc}
        rowId={rowId}
        surveyStrucRow={surveyStrucRow}
        setSurveyStrucRow={setSurveyStrucRow}
        openNewCategory={openNewCategory}
        organizationId={organizationId}
        surveyStructureId={surveyStructureId}
        setOpenNewCategory={setOpenNewCategory}
        trigger={<StyledButton>+ New Category</StyledButton>}
      />

      <ListJson
        surveyStrucRow={surveyStrucRow}
        setSurveyStrucRow={setSurveyStrucRow}
        setRowId={setRowId}
        setSurveyStruc={setSurveyStruc}
        setOpenNewCategory={setOpenNewCategory}
      />

      <Button
        style={{ margin: "20px 0px" }}
        icon
        labelPosition="left"
        floated="left"
        onClick={() => setOpenSummary(true)}
        size="small"
        color="green"
      >
        View Summary
        <Icon name="eye" />
      </Button>

      <Button
        style={{ margin: "20px 0px" }}
        icon
        labelPosition="right"
        floated="right"
        onClick={() => SaveSurveyStructure()}
        size="small"
        color="violet"
      >
        Save
        <Icon name="save" />
      </Button>

      <Modal
        open={openSummary}
        onClose={() => setOpenSummary(false)}>

        <Modal.Header>
          <Header as='h1'>
            Category Summary
            <Header.Subheader>
              Detailed Summary of JSON file, this is how the information gets processed
            </Header.Subheader>
          </Header>

        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Summary
              surveyStrucRow={surveyStrucRow}
              surveyStructure={get_survey_structure}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenSummary(false)} color="violet">
            Close <Icon name='close' />
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default Categories;

const Container = styled.div`
  margin-bottom: 30px;
`;

const CenteredObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
    margin-right: 3px;
  }
`;

const CenterContent = styled.div`
display: flex;
  align-items: center;
  justify-content: center;

`

const Table = styled.div`
  width: 100%;
  margin-top: 30px;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;
