import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import { Radio,Dropdown} from 'semantic-ui-react'


const Share = ({confirm}) =>{
    const [toggle, setToggle] = useState(false);

    return (
        <Container>
             <Content>
               
                <Description>
                    Leaders who share their plans with colleagues <strong>2.6x more likely</strong> to achieve their goals.
                </Description>

                <Title>Do you want to make your plan available to your colleagues?</Title>

                <ToggleArea>
                    <ToggleTitle>Share my plan</ToggleTitle>
                    <Radio 
                        toggle
                        value={toggle}
                        onChange={()=>setToggle(!toggle)}
                        />
                </ToggleArea>

                <Text>
                    This will make the details of your plan available to your colleagues. You will also be able to view the plans your colleagues share.
                    Sharing plans helps your organization learn and grow faster. 
                </Text>


            <ButtonContainer>
               
                <Button onClick={()=>confirm()}>{toggle?
                    'Share my plan':
                    'I do not want to share my plan'}</Button>

            </ButtonContainer>
            </Content>
        </Container>
    )
}

export default Share;


const Container = styled.div`
    width:100%;
font-family:'Raleway';
display:flex;
flex-direction:column;
align-items:center;
margin-top:40px;
`

const Content = styled.div`
    width:600px;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height:1.2;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`

const Description = styled.div`
    margin-bottom: 40px;
    font-size:20px;
    line-height:1.4;
    background-color:#D5E2F9;
    padding:30px;
    border-radius:8px;
    color:#2254AA;
`

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ToggleArea = styled.div`
    display:flex;
    justify-content:space-between;
`

const ToggleTitle = styled.div`
    font-size:18px;
    font-weight:600;
    margin-right:20px;
`

const Text = styled.div`
    font-size:16px;
    line-height:1.4;
    margin-top:20px;
    margin-bottom:40px;
    color:#666D79;
`

const friendOptions = [
    {
      key: 'Weekly',
      text: 'Weekly',
      value: 'Weekly',
    },
    {
      key: 'Bi-weekly',
      text: 'Bi-weekly',
      value: 'Bi-weekly',
    },
    {
      key: 'Monthly',
      text: 'Monthly',
      value: 'Monthly',
    },
  ]

  
const friendOptions2 = [
    {
      key: '9am',
      text: '9am',
      value: '9am',
    },
    {
      key: '10am',
      text: '10am',
      value: '10am',
    },
    {
      key: '11am',
      text: '11am',
      value: '11am',
    },
    {
        key: '12pm',
        text: '12pm',
        value: '12pm',
        },
        {
        key: '1pm',
        text: '1pm',
        value: '1pm',
        },
        {
        key: '2pm',
        text: '2pm',
        value: '2pm',
        },
        {
        key: '3pm',
        text: '3pm',
        value: '3pm',
        },
        {
        key: '4pm',
        text: '4pm',
        value: '4pm',
        },
        {
        key: '5pm',
        text: '5pm',
        value: '5pm',
        }
  ]

  const friendOptions3 = [
    {
      key: 'Monday',
      text: 'Monday',
      value: 'Monday',
    },
    {
      key: 'Tuesday',
      text: 'Tuesday',
      value: 'Tuesday',
    },
    {
      key: 'Wednesday',
      text: 'Wednesday',
      value: 'Wednesday',
    },
    {
        key: 'Thursday',
        text: 'Thursday',
        value: 'Thursday',
        },
        {
        key: 'Friday',
        text: 'Friday',
        value: 'Friday',
        },
        {
        key: 'Saturday',
        text: 'Saturday',
        value: 'Saturday',
        },
        {
        key: 'Sunday',
        text: 'Sunday',
        value: 'Sunday',
        }
  ]