import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress,Dropdown } from 'semantic-ui-react'
import Chart from "react-apexcharts";




const TO = () =>{

	return (
		<Container>

				<Title>Turn over Trends</Title>
				<Description>This location's turn over score is 55%. Turn over score is an analysis of the propencity for equitable turn
        over within this quarter. A high score is indicative of equality in turn over decisions. </Description>

        <Description2>This location's turn over equity is <strong>30% below your organization's average</strong>, and is 20% below the industry standard.</Description2>

        <div>
          <Sub>Filter trends by</Sub>
        <Filters>
          <Text>Parameter</Text>
           <Dropdown
            placeholder='Select Friend'
            fluid
            selection
            options={friendOptions}
            style={{width:200,marginRight:40}}
          />

          <Text>Department</Text>
           <Dropdown
            placeholder='Select Friend'
            fluid
            selection
            options={friendOptions}
            style={{width:200}}
          />
        </Filters>
        </div>
				<Chart options={data2.options} series={data2.series} type="bar" width={'100%'} />
		</Container>
		)
}

export default TO



const Container = styled.div`
	width:100%;
	height:100%;
	padding-right:20px;
`

const Title = styled.div`
	font-size:22px;
	font-weight:bold;
`

const Description = styled.div`
	margin-top:20px;
	margin-bottom:10px;
`


const Description2 = styled.div`

  margin-bottom:50px;
`

const Filters = styled.div`
  display:flex;
  align-items:center;
  margin-bottom:40px;
`
const Text = styled.div`
  margin-right:20px;
`

const Sub = styled.div`
  font-size:16px;
  font-weight:500;
  margin-bottom:10px;
`

const data2 = {
	series: [{
              name: 'Black',
              data: [44, 55, 41, 67, 22, 43]
            }, {
              name: 'White',
              data: [13, 23, 20, 8, 13, 27]
            }, {
              name: 'Indigeneous',
              data: [11, 17, 15, 15, 21, 14]
            }, {
              name: 'Hispanic',
              data: [21, 7, 25, 13, 22, 8]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                  show: false
                },
                zoom: {
                  enabled: true
                },
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                  }
                }
              }],
              plotOptions: {
                bar: {
                  horizontal: false,
                  borderRadius: 10
                },
              },
               title: {
                text: 'Turn over by race',
                align: 'left'
              },
               colors:['#6200EE','#03DAC5','#0336FF','#FF0266','#41c300'],
              xaxis: {
              categories: ['Q1 2020', 'Q2 2020', 'Q3 2020', 'Q4 2020',
                  'Q1 2021', 'Q2 2021'
                ],
              },
              legend: {
                position: 'right',
                offsetY: 40
              },
              fill: {
                opacity: 1
              }
            },
          
          
          }

const friendOptions = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess',
  },
  {
    key: 'Elliot Fu',
    text: 'Elliot Fu',
    value: 'Elliot Fu',
  },
  {
    key: 'Stevie Feliciano',
    text: 'Stevie Feliciano',
    value: 'Stevie Feliciano',
  },
  {
    key: 'Christian',
    text: 'Christian',
    value: 'Christian',
  },
  {
    key: 'Matt',
    text: 'Matt',
    value: 'Matt',
  },
  {
    key: 'Justen Kitsune',
    text: 'Justen Kitsune',
    value: 'Justen Kitsune',
  },
]