import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Page } from "@react-pdf/renderer";
import Table from "../components/Table";
import Chart from "../components/Chart";
import Footer from "../components/Footer";
import Side from "../components/Side";
import { getGrade } from "../utils/cultureUtils";

const themesArray = [
  {
    pointTitleOne: "Open communication",
    pointDescriptionOne:
      "Many participants mentioned feeling comfortable speaking up and addressing concerns with their coaches and teammates. They appreciate the open-mindedness and willingness to listen and evaluate their concerns in an effort to improve as a team.",
    pointTitleTwo: "Unequal value of opinions",
    pointDescriptionTwo:
      "Several participants expressed frustration that their ideas, suggestions, and opinions were not taken as seriously or valued as much as others. They felt ignored or even discouraged from speaking up, which impacted their overall satisfaction with the team environment.",
    pointTitleThree: "Fear of repercussions",
    pointDescriptionThree:
      "Some participants expressed a fear of speaking up or disagreeing with the coaching staff due to concerns about potential negative consequences in terms of playing time or perceived punishments. This fear may discourage open dialogue and limit the expression of different perspectives within the team.",
  },
  {
    pointTitleOne: "Lack of emotional discussions",
    pointDescriptionOne:
      "Participants mention that emotions are not commonly discussed or addressed in the hockey culture. There is a need for more open conversations about feelings and emotions, even when facing challenges or losses.",
    pointTitleTwo: "Support and camaraderie",
    pointDescriptionTwo:
      "The feedback highlights the positive aspect of having a supportive team environment where everyone is there for each other. It is seen as important to offer help and support to teammates during hardships.",
    pointTitleThree: "Difficulty in seeking help",
    pointDescriptionThree:
      "While there are resources available, participants express that it can be challenging to seek help and support due to the perception of being mentally strong. There is a need for more awareness and encouragement to utilize the available resources when going through tough times.",
  },
  {
    pointTitleOne: "Balancing Individuality and Team Dynamics",
    pointDescriptionOne:
      "Some respondents emphasized the importance of being authentic and true to oneself, but also acknowledged the need to adapt and prioritize the team's success over personal preferences or behaviors.",
    pointTitleTwo: "Open-mindedness and Growth",
    pointDescriptionTwo:
      "Several comments mentioned the importance of being open to new ideas, trying new things, and having a growth mindset. Respondents felt that having an open mind contributes to personal and team development.",
    pointTitleThree: "Equality and Inclusion",
    pointDescriptionThree:
      "A number of respondents expressed the importance of treating everyone equally, regardless of their background, nationality, language, or appearance. They emphasized the value of inclusivity and treating all team members with respect and fairness.",
  },
];

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
    color: "#2C3644",
  },
  p: {
    fontSize: 11,
    lineHeight: 1.7,
    marginBottom: 10,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#2C3644",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 15,
  },
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 16,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 13,
    marginBottom: 5,
  },
  question: {
    fontSize: 9.5,
    lineHeight: 1.7,
  },
  S1: {
    fontSize: 10,
    marginRight: 15,
  },
  img: {
    height: 20,
  },
  offset: {
    width: "100%",
  },
  summary: {
    border: "2px solid #2C6BAA",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C6BAA",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
  },
  legendText: {
    fontSize: 9,
    lineHeight: 1.7,
    marginTop: -6,
    marginLeft: 13,
  },
  legendWrapper: {
    flexDirection: "row",
    display: "flex",
  },
  legend: {
    width: 10,
    lineHeight: 1.9,
    borderBottomColor: "black",
    borderBottomWidth: 1,
    borderBottomStyle: "dotted",
  },
  themeSection: {
    // marginTop: 20,
    // marginBottom: 20,
  },
  themeTitle: {
    fontSize: 10,
    fontWeight: 700,
    color: "#2C3644",
    // marginBottom: 10,
  },
  themeWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    // lineHeight: 1.7,
  },
  themeText: {
    marginBottom: 5,
  },
  boldedTheme: {
    fontWeight: 700,
  },
});

const get_question_list = (q, dimension, questions) => {
  return questions.dimensions[dimension].factors.find((f) => {
    return f.id === q.id;
  }).questions;
};

/**
 * Returns the average, and reverse scoring as per new specs
 * @param {*} data
 * @param {*} item
 * @returns
 */
const get_average = (data, item) => {
  let n = 0;
  let sum = [];

  data.map((i) => {
    sum.push(i.average * i.n);
    n += i.n;
  });

  const score = Math.round((sum.reduce((pv, cv) => pv + cv, 0) / n) * 10);

  if (item && item.reverse) {
    return 100 - score;
  }

  return score;
};

const getAverageForChange = (data, item) => {
  let n = 0;
  let sum = [];

  data.map((i) => {
    sum.push(i.average * i.n);
    n += i.n;
  });

  const score = (sum.reduce((pv, cv) => pv + cv, 0) / n) * 10;

  if (item && item.reverse) {
    return 100 - score;
  }

  return score;
};

const structured = (data, q, dimension) => {
  return data.filter((f) => {
    return f.dimension === dimension && Number(f.section) === q.id - 1;
  });
};

const Breakdown = (props) => {
  const {
    question,
    info,
    refresh,
    questions,
    categories,
    anchor,
    dimension,
    config,
    navBar,
    filterData,
    previous,
    isSportLaw,
    index,
  } = props;

  const [q, setQ] = useState(get_question_list(question, dimension, questions));
  const [data, setData] = useState(structured(info, question, dimension));
  const anchorName = categories.find((f) => f.id === anchor);
  let subSelected = false;
  const navItems = Object.keys(navBar);
  const navOptions = [];
  let hasPrevious = false;
  navItems.forEach((item) => {
    let found = false;
    if (navBar[item].selected === false && anchorName.name === "Sport") {
      subSelected = true;
      found = true;
    }

    if (found) {
      let subNavItems = Object.keys(navBar[item]);
      subNavItems = subNavItems.filter(
        (key) => key !== "priority" && key !== "selected"
      );
      let selected = subNavItems.filter((subNav) => navBar[item]?.[subNav].sub);
      if (selected.length > 0) {
        selected.forEach((selectedNav) => {
          let subFactorsKeys = Object.keys(
            navBar[item]?.[selectedNav].subfactors
          );
          subFactorsKeys.forEach((subKey) => {
            if (navBar[item]?.[selectedNav].subfactors?.[subKey].selected) {
              navOptions.push(
                navBar[item]?.[selectedNav].subfactors?.[subKey].id
              );
            }
          });
        });
      }
    }
  });

  const tableTemplate = !isSportLaw
    ? [
        { title: "Question", width: "68%" },
        { title: "Score", width: "10%" },
        { title: "Norm", width: "10%" },
        { title: "Change", width: "12%" },
      ]
    : [
        { title: "Question", width: "70%" },
        { title: "Score", width: "10%" },
        { title: "Norm", width: "10%" },
        { title: "Change", width: "10%" },
      ];

  const average = (array) => {
    let total = 0;
    let n = 0;
    array.map((item) => {
      total += item != null ? item : 0;
      n += item != null ? 1 : 0;
    });

    if (!total) {
      return 0;
    }

    return total / n;
  };

  const get_last_question_total = (qId, dimension, factor) => {
    if (previous.length === 0) {
      return null;
    }

    const prevArray = previous?.map((item) => {
      const q = item.questions.find((f) => {
        return f.q === qId && f.id === dimension && f.factor === factor - 1;
      })?.response;

      return q;
    });

    return average(prevArray);
  };

  useEffect(() => {
    setData(structured(info, question, dimension));
    setQ(get_question_list(question, dimension, questions));
  }, [info, refresh]);

  const getFactor = () => {
    return questions.dimensions[dimension].factors.find(
      (f) => f.id === question.id
    ).title;
  };

  const getFactorObj = () => {
    return questions.dimensions[dimension].factors.find(
      (f) => f.id === question.id
    );
  };

  if (data.length === 0) {
    return null;
  }

  const overviewTableData = q.map((item, i) => {
    const questionData = data.filter((f) => Number(f.question) === i);
    const averageNormal = get_average(questionData, null, true);
    const averageForChange = getAverageForChange(questionData, null, true) / 10;
    const getPrefix = item.reverse ? "**" : "";
    let prevTotal = get_last_question_total(i, dimension, getFactorObj().id);

    prevTotal = prevTotal !== null ? prevTotal : null;

    let change;

    if (prevTotal === null) {
      change = null;
    } else {
      change = prevTotal ? ((averageForChange - prevTotal) * 10).toFixed(1) : 0;
      if (change.toString() === "0.0" || change.toString() === "-0.0") {
        change = null;
        prevTotal = null;
      }
    }

    if (change) {
      hasPrevious = true;
    }

    if (!isSportLaw) {
      return [
        `${item.q}`,
        `${getPrefix}${averageNormal}%`,
        "--",
        prevTotal && !isNaN(change) && isFinite(change) ? `${change}%` : "--",
      ];
    } else {
      return [
        `${item.q}`,
        `${getPrefix}${averageNormal}%`,
        "--",
        prevTotal && !isNaN(change) && isFinite(change) ? `${change}%` : "--",
      ];
    }
  });

  const overviewTable = {
    rows: tableTemplate,
    data: overviewTableData,
  };

  return (
    <Page style={styles.page}>
      <Text style={styles.T2}>{question.title} Analysis</Text>
      <View style={styles.line} />
      {/* <Text style={styles.description}>
        Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
        tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
        swine, pork chop porchetta picanha spare ribs. Chicken jerky short ribs,
        pastrami pork fatback ground round spare ribs cow t-bone filet mignon
        hamburger leberkas shank. Meatball burgdoggen pork ribeye.
      </Text> */}

      <Table
        data={overviewTable}
        headerColor="#2C3644"
        hideReverseText={false}
        isSportLaw={isSportLaw}
      />

      {/* <View style={styles.themeSection}>
        <Text style={styles.themeTitle}>Themes</Text>
        <View style={styles.themeWrapper}>
          <Text style={styles.themeText}>
            <Text style={styles.boldedTheme}>
              1. {themesArray[index].pointTitleOne}:
            </Text>
            &nbsp;{themesArray[index].pointDescriptionOne}
          </Text>
          <Text style={styles.themeText}>
            <Text style={styles.boldedTheme}>
              2. {themesArray[index].pointTitleTwo}:
            </Text>
            &nbsp;{themesArray[index].pointDescriptionTwo}
          </Text>
          <Text style={styles.themeText}>
            <Text style={styles.boldedTheme}>
              3. {themesArray[index].pointTitleThree}:
            </Text>
            &nbsp;{themesArray[index].pointDescriptionThree}
          </Text>
        </View>
      </View> */}

      <Text style={[styles.title, { color: config.titleColor }]}>
        Question Analysis
      </Text>

      {/* <Text style={styles.description}>
        Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
        tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
        swine, pork chop porchetta picanha spare ribs.
      </Text> */}

      {q.map((item, i) => {
        const questionData = data.filter((f) => Number(f.question) === i);
        // In some instances, 2 sets of charts are being displayed on the first
        // breakdown page, causing the table of contents to be off.
        const adjustPadding = i === 0;
        // const previousAvg = get_cat_average(i, dimension, item.id);
        return (
          <View wrap={false} style={[adjustPadding && { marginBottom: 150 }]}>
            <View
              style={[
                styles.section,
                { backgroundColor: isSportLaw ? "#F1F1F7" : "#F0F5FE" },
              ]}
            >
              <Text style={styles.question}>{getFactor()} Question</Text>
              <Text style={styles.subTitle}>
                {i + 1}. {item.q}
              </Text>
            </View>
            <View style={styles.offset}>
              {/* <Text style={styles.p}>
                This table contains overall performance metrics for the nuLogic
                Diversity, Equity, and Inclusion assessment. The following
                information appears on all nuLogic reports and is not specific
                to this organization's individual results
              </Text> */}

              <View style={styles.legendContainer}>
                <View style={styles.legend} />
                <Text style={styles.legendText}> Previous Score</Text>
              </View>
              <Chart
                question={item}
                subSelected={subSelected}
                options={subSelected ? navOptions : anchorName.options}
                data={questionData}
                barColor={config.barColor}
                navBar={navBar}
                previous={hasPrevious && previous}
                anchor={anchor}
                factor={getFactorObj().id}
                dimension={dimension}
                questionId={i}
              />
            </View>
          </View>
        );
      })}
      <Footer {...config} />
      <Side {...config} isSportLaw={isSportLaw} />
    </Page>
  );
};

export default Breakdown;
