import React from 'react'
import { Icon , Popup } from 'semantic-ui-react'
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import Content from './helpcontent.json'

const Help = ({open,setHelp}) =>{

	const [select,setSelected] = React.useState(null)

	return (
		<Container open={open} >
			<Top>
				<Text>
					{select!=null?<Icon name="angle double left" onClick={()=>setSelected(null)} style={{cursor:'pointer'}}/>:""}
					Help
				</Text>
				<Icon name="close" onClick={()=>setHelp()} style={{cursor:'pointer'}}/>
			</Top>


			{select==null?
				Content.audit.map((item,i)=>{
				return <VideoItem title={item.title} description={item.description} onClick={()=>setSelected(i)}/>
			}):""}



			{select!=null?
				<Video title={"Feature Overview"}/>:""
				}


{/*
			<Bottom>
				<T2>Additional Resources</T2>

				<Resource>
					<Icon name="chat" style={{marginRight:10}}/>
					<T3>Message support</T3>
				</Resource>

				<Resource>
					<Icon name="location arrow" style={{marginRight:10}}/>
					<T3>Welcome tutorial</T3>
				</Resource>

				<Resource>
					<Icon name="globe" style={{marginRight:10}}/>
					<T3>Blog</T3>
				</Resource>
			</Bottom>*/}
		</Container>
		
		)
}



export default Help


const VideoItem = ({title,description,onClick}) =>{

	return (
		<ItemContainer onClick={()=>onClick()}>
			<Title>{title}</Title>
			<Description>{description}</Description>
			<Icon name="chevron right" style={{position:'absolute',right:20}}/>
		</ItemContainer>
		)
}

const Video = ({title}) =>{

	return (
		<VideoContainer>
			<VTitle>{title}</VTitle>
			<iframe width="100%" height="200" src="https://www.youtube.com/embed/qP1ZskD2JEM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</VideoContainer>
		)
}



const Container = styled.div`
	position:absolute;
	height:calc(100vh - 60px);
	width:400px;
	right:${props=>props.open?'0px':'-400px'};
	top:60px;
	background-color:#476DFA;
	transition:all 0.5s;
	padding:20px;
	font-family: 'Poppins';
`

const Top = styled.div`
	display:flex;
	justify-content:space-between;
	color:white;
	margin-bottom:30px;
`

const Text = styled.div`
	font-size:12px;
	
	color:white;
`

const ItemContainer = styled.div`
	background-color:#213375;
	border-radius:10px;
	color:white;
	padding:15px;
	position:relative;
	display:flex;
	flex-direction:column;
	justify-content:center;
	margin-bottom:10px;
	cursor:pointer;

`

const Title = styled.div`
	font-weight:bold;
	margin-bottom:5px;
	font-size:16px;
`

const Description = styled.div`
	font-size:12px;
	max-width:300px;
`

const VideoContainer = styled.div`
	width:100%;
`

const VTitle = styled.div`
	font-weight:bold;
	margin-bottom:10px;
	font-size:14px;
	border-bottom:1px solid white;
	padding-bottom:5px;
	color:white;
`

const T2 = styled.div`
	font-weight:bold;
	margin-bottom:30px;
	font-size:14px;
	color:white;
`

const Bottom = styled.div`
	height:200px;
	width:100%;
	margin-top:30px;
	padding:15px;
`

const Resource = styled.div`
	display:flex;
	color:white;
	border-bottom:1px solid rgba(255, 255, 255,0.2);
	cursor:pointer;
	margin-top:20px;

	&:hover{
		opacity:0.7
	}
`

const T3 = styled.div`
	margin-bottom:5px;
	font-size:12px;
	font-weight:300;
	color:white;
`