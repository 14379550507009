import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Confirm, Header, Dimmer, Loader, Segment, Icon, Table, } from 'semantic-ui-react'

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_DELETE_TEAM_ACCESS,
  SG_GET_TEAM_ACCESSS
} from "constants/actions";

import AddTeamAccess from "settings/member_directory/TeamAccess/Edit"

// import BlankAvatar from "assets/images/empty/user.svg";

const TeamAccessList = ({ teamId }) => {
  const dispatch = useDispatch();
  const [teamAccess, setTeamAccess] = useState([]);

  const [openInvite, setOpenInvite] = React.useState(false)
  const [active, setActive] = React.useState(false)

  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(null)

  const { get_error, get_team_access,
    get_teams, get_employees } = useSelector(
      (state) => ({
        get_employees: state.employees.employees,
        get_teams: state.teams.teams,
        get_error: state.errors,
        get_team_access: state.team_access
      }),
      shallowEqual
    );

  useEffect(() => {
    const EmpListing = []
    get_team_access.team_access.map(ta =>
      EmpListing.push({
        id: ta.id,
        employee: get_employees.filter(em => em.id === ta.employee)?.[0],
        team: get_teams.filter(tm => tm.id === ta.team)[0]?.name

      })
    )
    setTeamAccess(EmpListing)
  }, [get_team_access, get_teams, get_employees]);

  useEffect(() => {
    // console.log("TEam Access List api on")
    dispatch({
      type: SG_GET_TEAM_ACCESSS,
      payload: `team=${teamId}`
    });
  }, [dispatch, teamId]);

  function deleteMember(id) {
    setDeleteId(null)
    dispatch({
      type: SG_DELETE_TEAM_ACCESS,
      payload: { id: id }
    });
    setOpenDelete(false)
    setActive(true)
  }
  // console.log(teamAccess)

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(false)
    }, 1000);
    return () => clearTimeout(timer);
  }, [active]);

  const getName = (emp) => {
    if (emp == null) {
      return ""
    }
    let employ = teamAccess.filter(f => f.id === emp)[0]

    if (employ.employee.first_name == null) {
      return employ.employee.email
    }

    return employ.employee.first_name + " " + employ.employee.last_name
  }

  const setRemove = (id) => {
    setOpenDelete(true)
    setDeleteId(id)
  }
// console.log("TEam Access List on")
  return (
    <>

      <Segment basic>
        <Dimmer active={active}>
          <Loader size='large'>Loading</Loader>
        </Dimmer>
        <Header>Team Spectators</Header>
        <Table>
          <Table.Body>
            {teamAccess &&
              teamAccess.map((amb, k) => {
                return (

                  <Table.Row key={k}>
                    <Table.Cell>
                      {amb?.employee?.first_name} {amb?.employee?.last_name}
                    </Table.Cell>
                    <Table.Cell>{amb?.employee?.email}</Table.Cell>
                    <Table.Cell>{amb?.team}</Table.Cell>
                    <Table.Cell>
                      <Confirm
                        open={openDelete}
                        onCancel={() => setOpenDelete(false)}
                        onConfirm={() => deleteMember(deleteId)}
                        content={`Are you sure you want to remove ${getName(deleteId)} as a spectator of this team?`}
                      />
                      <Remove onClick={() => setRemove(amb.id)}>Remove</Remove></Table.Cell>
                  </Table.Row>

                )
              })}
          </Table.Body>
        </Table>
      </Segment>
      <div>
        <div className="ui horizontal divider">Add Spectators
          <Linked>
            <Icon name={openInvite ? 'minus' : 'plus'}
              onClick={() => setOpenInvite(!openInvite)} />
          </Linked>
        </div>
        {openInvite &&
          <AddTeamAccess teamId={teamId}
            setActive={setActive}
            teamAccess={get_team_access.team_access} />}
      </div>
    </>
  );
};

export default TeamAccessList;

const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const Remove = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: #ff2d55;
  cursor:pointer;
  font-family: 'Red Hat Display', sans-serif;
`

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width:768px){
    display:none;
  }
`;
const Linked = styled.span`
  text-decoration: none;
  cursor: pointer;
`;

const Card = styled.div`
  width:600px;
  max-width:100%;
  padding:30px;
  margin-bottom:10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position:relative;
  display:flex;
  justify-content:space-between;
  align-items:center;

`

const Avatar = styled.img`
  height: 10px;
  width: 10px;
  border: solid 4px #ffffff;
  border-radius: 10px;
  transition: left 0.5s;
  margin-right: -10px;
  alt: ${(props) => props.alt};
`;
