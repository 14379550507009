import React, { useState } from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  width: 200px;
  position: relative;
`;

const DropdownHeader = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
`;

const DropdownList = styled.ul`
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const DropdownListItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #e0e0e0;
  `}
`;

const Dropdown = ({ items, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item) => {
    console.log(item);
    setSelectedItem(item);
    setIsOpen(false);
    if (onSelect) {
      onSelect(item);
    }
  };

  return (
    <DropdownContainer>
      <DropdownHeader onClick={toggleDropdown}>
        {selectedItem?.name|| 'Select an option'}
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {items.map((item) => (
            <DropdownListItem
              key={item.id}
              onClick={() => handleItemClick(item)}
              isSelected={selectedItem?.id === item?.id}
            >
              {item.name}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
