import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "components/Icons";
import ClickAwayListener from "react-click-away-listener";

const BasicDropdown = ({
  options,
  selectedOption,
  handleOptionChange,
  label,
  inModal,
  editEnabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState();

  return (
    <DropdownContainer>
      <DropdownLabel>{label}:</DropdownLabel>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <DropdownSelect
          value={selectedOption}
          onClick={() => editEnabled && setIsOpen(!isOpen)}
        >
          <SelectedOptionContainer>
            <SelectedOption editEnabled={editEnabled}>
              {selectedOption || "Select an option"}
            </SelectedOption>
            {editEnabled && (
              <>{!isOpen ? ChevronDownIcon() : ChevronUpIcon()}</>
            )}
          </SelectedOptionContainer>
          <DropdownSelection inModal={inModal}>
            {isOpen && editEnabled && (
              <DropdownSelectionContainer inModal={inModal}>
                {options.map((option, index) => (
                  <DropdownItem
                    isHovered={hoveredIndex === index}
                    onMouseOver={() => setHoveredIndex(index)}
                    key={index}
                    selected={selectedOption === option}
                    onClick={() => {
                      handleOptionChange(option);
                      setIsOpen(false);
                    }}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownSelectionContainer>
            )}
          </DropdownSelection>
        </DropdownSelect>
      </ClickAwayListener>
    </DropdownContainer>
  );
};

export default BasicDropdown;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-width:250px;
`;

const DropdownSelectionContainer = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  border-radius: 8px;
  background-color: #FFF;
  top: 0;
  /* Shadow */
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.4);
`;

const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const DropdownSelection = styled.div`
  position: relative;
  z-index: 1;

`;

const DropdownItem = styled.div`
  padding: 10px;
  background: ${(props) => (props.isHovered ? "#F8FAFF" : "#fff")};
  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
`;

const DropdownLabel = styled.div`
  color: #666d79;
  min-width: fit-content;
  text-align: left;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-right: 8px;
  margin-top: 5px;
`;

const SelectedOption = styled.div`
  cursor: ${(props) => (props.editEnabled ? "pointer" : "default")};
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const DropdownSelect = styled.div`
  width: 100%;
  border: 0px solid #ccc;
  border-radius: 4px;

  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
