import React from "react";
import styled from "styled-components";

const WellnessTop = () => {
  return (
    <TitleWrapper>
      <Title>Wellness Action Plan</Title>
      <FirstSection>
        Wellness encompasses physical, psychological/emotional, social, and
        intellectual dimensions, and is defined as the balanced amalgamation of
        these factors and purposeful direction within a sport environment. There
        are four dimensions of wellness that impact psychological and physical
        health and safety in high performance sport. These wellness dimensions
        are:{" "}
      </FirstSection>
      <BulletSection>
        <Bullet>• Physical</Bullet>
        <Bullet>• Social / Spiritual</Bullet>
        <Bullet>• Emotional</Bullet>
        <Bullet>• Cognitive</Bullet>
      </BulletSection>
      <FirstSection>
        Within the Wellness Index, there are 8 High Performance Outcomes that
        surround the four wellness dimensions. These represent how psychological
        and physical health and safety are upheld and monitored within a sport
        organization. NSO initiatives and related actions, should be developed
        and monitored through their High Performance Impact on People
        (Supporting Growth), Systems (Building Sustainability), and Culture
        (Establishing Values & Behaviour). Within this action plan you will be
        asked to indicate the High Performance Outcome(s) and High Performance
        Impact(s) per NSO initiative.
      </FirstSection>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-weight: 600;
  font-size: 18px;
  line-height: 128%;
  color: #2d70e2;
`;

const FirstSection = styled.div`
  font-family: "Raleway";
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #7e7e7e;
  margin-top: 15px;
`;

const BulletSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Bullet = styled.div`
  font-family: "Raleway";
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #7e7e7e;
  margin-top: 5px;
  margin-left: 10px;
`;

export default WellnessTop;
