import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import DistributionChart from "./DistributionChart";
import Distribution2 from "./Distribution2";

const colors = ['#a9a8ad','#887fad','#7365ad','#513bad','#2e02eb']
const titles = ['<50%','50-60%','60-70%','70-80%','>80%']
const data = [[10,20,30,40,50],[20,30,40,10,0],[30,40,50,60,70],[40,50,60,70,80],[50,60,70,80,90]]

const Distribution = () =>{

    return (
        <Container>
            <Title>Distribution</Title>

            <Legend>
                {colors.map((item,i)=>{
                return (
                    <LegendItem>
                    <Color color={item}/>
                    <T2>{titles[i]}</T2>
                    </LegendItem>
                    
                )
                })}
            </Legend>


            <Plot>
                <Row>
                    <Name>
                        Department A
                    </Name>
                    <Chart>
                        <DistributionChart data={data[0]}/>
                    </Chart>
                </Row>

                <Row>
                    <Name>
                        Department B
                    </Name>
                    <Chart>
                        <DistributionChart data={data[1]}/>
                    </Chart>
                </Row>
                
            </Plot>

            <Distribution2 width={4} start={5}/>

            <Distribution2 width={6} start={3}/>
        </Container>
    )

}

export default Distribution;

const Container = styled.div`
    margin-top:30px;
    padding-left:58px;
`

const Title = styled.h1`

`

const T2 = styled.div`

`

const LegendItem = styled.div`
    display:flex;
    align-items:center;
    margin-right:30px;
`

const Plot = styled.div`

`

const Name = styled.div`
    width:200px;
    font-weight:600;
    font-size:14px;
    font-family: 'Poppins', sans-serif;
`
const Row = styled.div`
    display:flex;
    border-bottom:1px solid #d9d9d9;
    padding:20px 0px;
`

const Chart = styled.div`
    width:calc(100% - 200px);
`

const Legend = styled.div`
    display:flex;
    margin-bottom:30px;
    width:100%;
    justify-content:flex-end;
`
const Color = styled.div`
    width:15px;
    height:15px;
    background-color:${props=>props.color};
    border-radius:5px;
    margin-right:5px;
`