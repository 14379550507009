import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  width: 100%;
`;
const TH = styled.th`
  font-size: 12px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
  color: #696969;
  width: ${(props) => props.width};
  text-transform: uppercase;
  padding: 0 0 10px 15px;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const TH1 = styled.div`
  font-size: 12px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
  color: #696969;
  width: 200px;
  text-transform: uppercase;
  padding: 0 0 10px 15px;

  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const Headers = styled.div`
  width: 100%;
  height: 100px;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const RowItem = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
`;

const Name = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  color: #696969;
  width: 200px;
  padding: 0 0 10px 15px;

  @media (max-width: 768px) {
    font-size: 9px;
  }
`;

const Data = styled.div`
  height: 100%;
  width: 81.32px;
`;
const TD = styled.td`
  width: ${(props) => props.width};
  text-align: center;
  padding: 10px 5px;
  border: 1px solid #ccc;
`;

const headers = [
  ["culture", 20],
  ["mood", 20],
  ["leadership", 20],
  ["culture", 20],
  ["mood", 20],
  ["leadership", 20],
  ["culture", 20],
  ["mood", 20],
  ["leadership", 20],
];

const data = [
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
  { team: "Team 123", scores: [74, 33, 89, 74, 33, 89, 74, 33, 89] },
];

const Row = (props) => {
  return (
    <RowItem>
      <Name>{props.data.team}</Name>
      {props.data.scores.map((item, i) => {
        return <Data>{item}</Data>;
      })}
    </RowItem>
  );
};

const TeamTable = () => {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th></th>
            {headers.map((item, i) => {
              return <TH width={100 / headers.length + "%"}>{item[0]}</TH>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => {
            return (
              <tr>
                <th>{item.team}</th>
                {item.scores.map((x) => {
                  return <TD width={100 / headers.length + "%"}>{x}</TD>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default TeamTable;
