import styled, { keyframes } from "styled-components";
import React from "react";


const height = 400
const width = 400


const AvatarGen = (props) =>{

	return (
			<AvatarContainer 
				top={props.y/100*height} 
				left={props.x/100*width} 
				onMouseEnter={()=>props.onHover()} 
				onMouseLeave={()=>props.offHover()}
				selected={props.selected}
				hide={props.hide}>
				<div style={{position:'relative'}}>
					<Anchor/>
					<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"}/>
					
				</div>
			</AvatarContainer>
		)
}

const Zscore = () =>{

	const [selected,setSelected] = React.useState(0)

	return (
		<Container>
				<Text top={-10} left={width/2 - 50}>Improving Team Health</Text>
				<Text top={height+10} left={width/2 - 50}>Decreasing Team Health</Text>
				<Text3 top={height/2} left={width-120}>Improving Emotional Health</Text3>
				<Text2 top={height/2} left={-90}>Decreasing Emotional Health</Text2>
				<svg height={height} width={width} viewBox="0 0 100 100">
					<defs>
					    <linearGradient id="myGradient" gradientTransform="rotate(135)">
					      <stop offset="0%"  stopColor="#01CFEC" />
					      <stop offset="100%" stopColor="#6B00D1" />
					    </linearGradient>
					  </defs>
					<path d="M 1 1 L 1 50 L 99 50 L 1 50 L 1 99 L 99 99 L 99 1 L 50 1 L 50 99 L 50 1 L 0.5 1" stroke={"url('#myGradient')"} strokeWidth="1" fill="transparent"/>

					
					
					<line x1={1.5} y1={10} x2={98.5} y2={10} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={20} x2={98.5} y2={20} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={30} x2={98.5} y2={30} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={40} x2={98.5} y2={40} stroke="grey" opacity={0.3} strokeWidth="0.5"/>

					<line x1={1.5} y1={60} x2={98.5} y2={60} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={70} x2={98.5} y2={70} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={80} x2={98.5} y2={80} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={90} x2={98.5} y2={90} stroke="grey" opacity={0.3} strokeWidth="0.5"/>


					<line x1={10} y1={1.5} x2={10} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={20} y1={1.5} x2={20} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={30} y1={1.5} x2={30} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={40} y1={1.5} x2={40} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					
					<line x1={60} y1={1.5} x2={60} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={70} y1={1.5} x2={70} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={80} y1={1.5} x2={80} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={90} y1={1.5} x2={90} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>

				</svg>
				<AvatarGen x={55} y={76} onHover={()=>setSelected(1)} offHover={()=>setSelected(0)} selected={selected==1} hide={selected!=0}/>
				<AvatarGen x={47} y={55} onHover={()=>setSelected(2)} offHover={()=>setSelected(0)} selected={selected==2} hide={selected!=0}/>
				<AvatarGen x={52} y={60} onHover={()=>setSelected(3)} offHover={()=>setSelected(0)} selected={selected==3} hide={selected!=0}/>
				<AvatarGen x={45} y={76} onHover={()=>setSelected(4)} offHover={()=>setSelected(0)} selected={selected==4} hide={selected!=0}/>
				<AvatarGen x={40} y={34} onHover={()=>setSelected(5)} offHover={()=>setSelected(0)} selected={selected==5} hide={selected!=0}/>
				<AvatarGen x={38} y={20} onHover={()=>setSelected(6)} offHover={()=>setSelected(0)} selected={selected==6} hide={selected!=0}/>
				<AvatarGen x={80} y={98} onHover={()=>setSelected(7)} offHover={()=>setSelected(0)} selected={selected==7} hide={selected!=0}/>
				<AvatarGen x={44} y={76} onHover={()=>setSelected(8)} offHover={()=>setSelected(0)} selected={selected==8} hide={selected!=0}/>

		</Container>
		)
}


export default Zscore


const Container = styled.div`
	padding:10px;
	magrin-bottom:20px;
	position:relative;
	display:flex;
`


const Text = styled.div`
	position:absolute;
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};
`

const Text2 = styled.div`
	transform: rotate(-90deg);
	position:absolute;
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};

`

const Text3 = styled.div`
	transform: rotate(90deg);
	position:absolute;
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};
	width:300px;
	display:flex;
	justify-content:center;
`

const Avatar = styled.img`
	height:40px;
	width:40px;
	cursor:pointer;

	&:hover{
		border:3px solid #6a00ff;
		height:43px;
		width:43px;
	}
`

const AvatarContainer = styled.div`
	position:absolute;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};
	opacity:${props=>props.hide && !props.selected?0.3:1};


`

const Anchor = styled.div`
	height:8px;
	width:8px;
	background-color:white;
	border-radius:4px;
	position:absolute;
	top:-3px;
	left:-3px;
	border:2px solid black;

`