import React, { useEffect, useState } from "react";
import IntroScreen from './introScreen'
import Team from './Team/index'
import Department from './Department/index'

const CreateTeam = () => {
  const [visible, setVisible] = React.useState(1);

  const change = (i) => {
    setVisible(i);
  };


  return (
    <div style={{ position: "relative", height: 900 }}>
      {visible === 1 ? <IntroScreen change={(i) => change(i)} /> : ""}
      {visible === 2 ? <Department change={(i) => change(i)}  back={()=>setVisible(1)}/> : ""}
       {visible === 3 ? <Team change={(i) => change(i)}  back={()=>setVisible(1)}/> : ""}
    </div>
  );
};

export default CreateTeam;