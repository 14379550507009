import React from 'react';
import { Button, Paragraph } from './styles/Common';

const SlackConnectView = ({ onConnected }) => {
  const handleAddToSlack = () => {
    // Normally: window.location.href = '/api/slack/oauth-initiate'
    // After OAuth callback, you'd invoke onConnected(token)
    onConnected('mock-slack-token');
  };

  return (
    <div>
      <Paragraph>Click the button below to initiate the Slack OAuth flow and connect your workspace.</Paragraph>
      <Button primary onClick={handleAddToSlack}>Add to Slack</Button>
    </div>
  );
};

export default SlackConnectView;
