import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chevron from 'assets/images/UI/chevron_right.svg'
import Impact from 'assets/images/UI/impact.svg'
import ROI from 'assets/images/UI/roi.svg'
import Icon1 from "assets/images/UI/accountability.svg";
import Icon2 from "assets/images/UI/communication.svg";
import Icon3 from "assets/images/UI/adaptability.svg";

const Recommendation = ({
  data,
  text,
  title,
  pillarData,
  setOpen,
  impactLabel,
}) => {
  const getIcon = (pillar) => {
    switch (pillar) {
      case "Communication":
        return Icon2;
      case "Adaptability":
        return Icon3;
      case "Accountability":
        return Icon1;
      default:
        return Icon1;
    }
  };

  return (
    <Container>
      <Half>
        <Title>
          <Dot />
          <TitleText>{title}</TitleText>
          <Factor>{data?.primary}</Factor>
        </Title>
        <Date>{data?.date}</Date>
        <Text>{text}</Text>

        <Link onClick={() => setOpen()}>
          See Full Recommendation <Pic src={Chevron} />
        </Link>
      </Half>

      <Half2>
        <Box>
          <div>
            <Icon>
              <Pic2 src={getIcon(pillarData?.factorLabel)} />
            </Icon>
            <Sub>Culture Pillar</Sub>
            <Pillar>{pillarData?.factorLabel}</Pillar>
          </div>
          <Score>{Math.round(pillarData?.primaryAvg * 10)}%</Score>
        </Box>

        <Box>
          <div style={{ display: "flex" }}>
            <Icon>
              <Pic2 src={Impact} />
            </Icon>
            <div style={{ marginTop: 10 }}>
              <Pillar>Biggest Impact</Pillar>
              <Score>
                {impactLabel} -{" "}
                {(pillarData?.lowestAverage?.average * 10).toFixed(1)}%
              </Score>
            </div>
          </div>

          <div style={{ display: "flex", marginTop: -30 }}>
            <Icon>
              <Pic2 src={ROI} />
            </Icon>
            <div style={{ marginTop: 10 }}>
              <Pillar>Predicted ROI</Pillar>
              <Score>{data.roi || "10%"}</Score>
            </div>
          </div>
        </Box>
      </Half2>
    </Container>
  );
};

export default Recommendation;

const Container = styled.div`
  display: flex;
  padding-bottom: 25px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 25px;
`;

const Half = styled.div`
  width: calc(50% - 12px);
  margin-right: 24px;
`;

const Half2 = styled.div`
  width: calc(50% - 12px);
  display: flex;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
`;
const TitleText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2a3039;
  line-height: 128%;
  width: 400px;
`;
const Factor = styled.div`
  background: #eafbf7;
  border-radius: 8px;
  color: #1d9a7d;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 8px;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #27cda7;
  margin-right: 12px;
`;

const Date = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666d79;
`;

const Text = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  margin-top: 16px;
  line-height: 150%;
`;

const Pic = styled.img`
  margin-left: 8px;
`;

const Pic2 = styled.img``;

const Link = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2d70e2;
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: #f8faff;
  justify-content: space-around;
`;
const Icon = styled.div`
background: #EBF1FD;
border-radius: 8px;
height:40px;
width:40px;
margin-bottom:16px;
margin-right:16px;
display:flex;
align-items:center;
justify-content:center;
`

const Sub = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 12px;
color: #666D79;
`

const Pillar = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #2D70E2;
margin-bottom:8px;
`
const Score = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #2A3039;
`