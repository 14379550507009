import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import MarkdownRenderer from "./markdown";
import sample from './sample.json'
import CreateStory from "./CreateStory";

const InsightsV2 = () => {

    // return <CreateStory />

    return (
        <Container>
            <Content>
                <Title>ROI Report</Title>
                
                <MarkdownRenderer 
                    markdown={sample.json_data.resp} 
                    tldr={sample.json_data.tldr}
                    quotes={sample.json_data.quotes}/>
            </Content>
            
        </Container>
    )

}

export default InsightsV2;

const Container = styled.div`
    padding:20px;
    font-family:"Raleway";
    width:100%;
    display:flex;
    justify-content:center;
`

const Content = styled.div`
    max-width:700px;
`

const Title = styled.div`
    font-size:12px;
    text-transform:uppercase;
    color:#2D70E2;
    margin-bottom:10px;
    font-weight:bold;
`