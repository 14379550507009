import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BasicDropdown from "components/BasicDropdown";
import Textbox from "./Textbox";
import { addIcon, trashIcon } from "components/Icons";

const MultiDataTable = ({
  data,
  rowsections: rowSections,
  headers,
  onChange,
  id,
  editEnabled,
}) => {
  const [rows, setRows] = useState([
    {
      selections: [],
      input: "",
    },
  ]);
  const [hoveredRow, setHoveredRow] = useState();
  const [middleIsHovered, setMiddleHoveredRow] = useState();

  useEffect(() => {
    onChange(rows, id);
  }, [rows]);

  useEffect(() => {
    if (data) {
      setRows((data?.planData[id]?.length > 0 && data?.planData[id]) || [{}]);
    }
  }, [data]);

  const handleOptionChange = (d, i, idx) => {
    const _rows = [...rows];
    if (!_rows[i]) {
      _rows[i] = {
        selections: [],
        input: "",
      };
    }

    const selectionData = _rows[i].selections;
    selectionData[idx] = d;
    _rows[i].selections = selectionData;

    setRows(_rows);
  };

  const handleInputChange = (d, i) => {
    const _rows = [...rows];
    if (!_rows[i]) {
      _rows[i] = {
        selections: [],
        input: "",
      };
    }
    _rows[i].input = d;
    setRows(_rows);
  };

  const dropdownOptions = ["Low", "Moderate", "High"];

  return (
    <TableContainer>
      <TableRow>
        {headers.map((h) => {
          return <RowHeader>{h}</RowHeader>;
        })}
      </TableRow>
      {rowSections.map((r, i) => {
        return (
          <>
            <TableRow altBg={true} hideBorder={true}>
              <TableCol>
                <FirstColumn altBg={true}>{r.firstcolumn}</FirstColumn>
              </TableCol>
              <TableCol style={{ marginTop: "-1px" }}>
                {r.middlecolumns.map((m, idx) => {
                  const middleRowAltBackground = idx % 2 === 0;
                  const rowIsHovered = hoveredRow === `${i}-${idx}`;

                  return (
                    <MiddleTableRow
                      hideBorder={true}
                      altBg={middleRowAltBackground || rowIsHovered}
                      onMouseOver={() => setMiddleHoveredRow(`${i}-${idx}`)}
                      onMouseLeave={() => setMiddleHoveredRow(null)}
                      style={{ marginRight: "-9px" }}
                    >
                      {m}
                    </MiddleTableRow>
                  );
                })}
              </TableCol>
              <TableCol>
                {r.middlecolumns.map((m, idx) => {
                  const middleRowAltBackground = idx % 2 === 0;
                  const rowIsHovered = hoveredRow === `${i}-${idx}`;

                  return (
                    <MiddleTableRow
                      hideBorder={true}
                      altBg={middleRowAltBackground || rowIsHovered}
                      onMouseOver={() => setMiddleHoveredRow(`${i}-${idx}`)}
                      onMouseLeave={() => setMiddleHoveredRow(null)}
                      style={{ marginRight: "-5px" }}
                    >
                      {(editEnabled && (
                        <BasicDropdown
                          selectedOption={
                            rows[i]?.selections?.[idx] || dropdownOptions[0]
                          }
                          handleOptionChange={(d) =>
                            handleOptionChange(d, i, idx)
                          }
                          key={i}
                          options={dropdownOptions}
                        />
                      )) || (
                        <SelectedOption editEnabled={editEnabled}>
                          {rows[i]?.selections?.[idx] || dropdownOptions[0]}
                        </SelectedOption>
                      )}
                    </MiddleTableRow>
                  );
                })}
              </TableCol>
            </TableRow>
            <CommentContainer>
              <Textbox
                hideBorders={true}
                altBg={true}
                value={rows[i]?.input || ""}
                onChange={(d) => handleInputChange(d, i)}
                placeholder="Enter comments here"
                hideFocus={true}
                editEnabled={editEnabled}
                isTable={true}
              />
            </CommentContainer>
          </>
        );
      })}
    </TableContainer>
  );
};

const FirstColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
`;

const SelectedOption = styled.div`
  cursor: ${(props) => (props.editEnabled ? "pointer" : "default")};
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f8faff;
`;

const MiddleColumns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const MiddleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  border-collapse: collapse;
`;

const AddContainer = styled.div`
  cursor: pointer;
  color: #2d70e2;
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #DFDFDF"};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  flex-direction: row;
  padding-horizontal: 15px;
  &:last-child {
    border-bottom: none;
  }
`;

const MiddleTableRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #DFDFDF"};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  flex-direction: row;
  &:last-child {
    border-bottom: none;
  }
  min-height: 120px;
  max-height: 120px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
`;

const RowHeader = styled.th`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 50%;
`;

const TableCol = styled.td`
  width: 50%;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const AddRowContainer = styled.div`
  opacity: ${(props) => (props.isHovered ? "1" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default MultiDataTable;
