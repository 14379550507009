import React from "react";
import { View, Text, Image, StyleSheet, Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import format from "date-fns/format";

import coverImage from "assets/images/logo_white.png";
import ilLogo from "assets/images/il-logo.png";
import statsContainer from "../assets/stats-container.png";
import pdfBg from "assets/images/pdf-bg.png";

const Intro = (props) => {
  let imgCover;
  let coverTitle;
  let subtitle;
  let reportType;
  let templateType = 1;
  let orgSuffix;

  if (props.config?.[0].data) {
    const { coverSubtitle, coverImage, coverHeading, type, introTemplate } =
      props.config?.[0].data;
    templateType = (introTemplate && introTemplate) || 1;
    imgCover = coverImage && coverImage !== "DEFAULT" ? coverImage : null;
    subtitle = (coverSubtitle && coverSubtitle) || "PDF summary report";
    coverTitle = (coverHeading && coverHeading) || "Sport Culture Index";
    reportType = (type && type) || "Culture Audit";
  }

  if (props.selectedReport) {
    orgSuffix = `/ ${props.selectedReport.name}`;
  }

  if (props.overview) {
    orgSuffix =
      props.anchor.name.toLowerCase() === "sport"
        ? "- All sports"
        : "- Average of all sports";
  }

  const styles = StyleSheet.create({
    page: {
      position: "relative",
      height: "100vh",
      backgroundColor: "#F0F5FE",
      color: "#2C3644",
    },
    textarea: {
      position: "absolute",
      top: "10%",
      left: 80,
      color: "#2C3644",
    },
    title: {
      fontWeight: 700,
      fontSize: 30,
      width: 350,
      lineHeight: 1.2,
      paddingTop: 100,
    },
    subSection: {
      position: "absolute",
      bottom: "10%",
      left: 80,
    },
    subTitle: {
      fontSize: 12,
      width: 350,
      lineHeight: 1.2,
    },
    sportName: {
      fontSize: 14,
      fontWeight: 500,
      marginTop: 15,
      marginBottom: 170,
    },
    bottom: {
      width: "100vw",
      height: 150,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "flex-end",
      position: "absolute",
      bottom: 0,
    },
    bar: {
      width: 7,
      borderRadius: 2.5,
    },
    sub1: {
      fontSize: 18,
      fontWeight: 500,
      marginTop: 15,
    },
    img: {
      width: 150,
      marginLeft: -10,
      marginBottom: 75,
    },
  });

  const bars = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28,
  ];
  const colors = ["#2C6CF5", "#2E34D0", "#3008B2"];

  const getReportDetails = (reportData, orgName) => {
    const detailsObj = [];

    // Organization Name Details Stat
    if (orgName) {
      detailsObj.push(
        <View style={styles.statsWrapper} key={"org-name"}>
          <View style={styles.textFormatter}>
            <Text style={styles.statText}>Organization name: </Text>
            <Text style={styles.valueText}>
              {orgName} {orgSuffix}
            </Text>
          </View>
        </View>
      );
    }

    // Report Type Details Stat
    detailsObj.push(
      <View style={styles.statsWrapper} key={"report-type"}>
        <View style={styles.textFormatter}>
          <Text style={styles.statText}>Report type: </Text>
          {/* Get value from prop when more audits are added. */}
          <Text style={styles.valueText}>
            {reportData?.type_of || "Culture Audit"}
          </Text>
        </View>
      </View>
    );

    // Responses Details Stat
    if (reportData?.responses) {
      detailsObj.push(
        <View style={styles.statsWrapper} key={"num-respondents"}>
          <View style={styles.textFormatter}>
            <Text style={styles.statText}># of respondents: </Text>
            <Text style={styles.valueText}>{props.totalResponses}</Text>
          </View>
        </View>
      );
    }

    // Date Details Stat
    if (reportData?.date) {
      detailsObj.push(
        <View style={styles.statsWrapper} key={"close-date"}>
          <View style={styles.textFormatter}>
            <Text style={styles.statText}>Survey close date: </Text>
            <Text style={styles.valueText}>
              {formatReportDate(reportData.date)}
            </Text>
          </View>
        </View>
      );
    }

    return detailsObj;
  };

  const formatReportDate = (dateTime) => {
    return format(dateTime, "MMMM do, yyyy");
  };

  const introImg = (imgSrc, header, subtitle, sportName) => {
    return (
      <View>
        <View>
          <Image
            src={(imgSrc && { uri: imgSrc }) || coverImage}
            cache={true}
            style={{ width: 760, objectFit: "cover" }}
          />
        </View>
        {header && (
          <View style={styles.textWrapper}>
            <Text style={styles.header}>{header.toUpperCase()}</Text>
            <Text style={styles.subTitle}>{subtitle}</Text>
          </View>
        )}
        <View style={styles.statsSection}>
          {(templateType === 1 && (
            <View style={styles.statsContainer}>
              <View>
                <Image source={statsContainer} fixed />
              </View>
              <View style={{ position: "absolute", left: 45, top: 25 }}>
                {getReportDetails(props.selectedReport, props.selectedOrg)}
              </View>
            </View>
          )) || (
            // Placeholder for stats container.
            <View
              style={[
                styles.statsContainer,
                {
                  backgroundColor: "rgb(65,65,65)",
                },
              ]}
            >
              {getReportDetails(props.selectedReport, props.selectedOrg)}
            </View>
          )}
        </View>
        <View>
          <Image source={pdfBg} />
        </View>
      </View>
    );
  };

  const introReport =
    templateType === 2 ? (
      <View style={styles.introWrapper}>
        {introImg(imgCover, coverTitle, subtitle, props.sportName)}
      </View>
    ) : (
      <View style={styles.page}>
        <View style={styles.textarea}>
          <Image src={ilLogo} style={styles.img} />
          <Text style={styles.title}>Culture Index</Text>
          <Text
            style={[
              styles.sub1,
              !props.sportName ||
                (props.overview && {
                  marginBottom: 170,
                }),
            ]}
          >
            Overview and Key Findings
          </Text>
          {props.sportName && !props.overview && (
            <Text style={styles.sportName}>{props.sportName}</Text>
          )}
        </View>
        <View style={styles.subSection}>
          <Text style={styles.subTitle}>
            Generated by innerlogic for {props.selectedOrg} on&nbsp;
            {formatReportDate(new Date())}
          </Text>
        </View>

        {/* <View style={styles.bottom}>
          {bars.map((item) => (
            <View
              style={[
                styles.bar,
                {
                  height: 50 + (100 * item) / 28 + Math.random() * 100,
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                },
              ]}
            />
          ))}
        </View> */}

        <View
          style={{
            display: "flex",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Image source={pdfBg} />
        </View>
      </View>
    );

  return (
    <Page>
      <View>{introReport}</View>
    </Page>
  );
};

Intro.propTypes = {
  companyLogo: PropTypes.string,
  introTemplate: PropTypes.number,
  coverImage: PropTypes.string,
  coverHeading: PropTypes.string,
  coverSubtitle: PropTypes.string,
  metadata: PropTypes.object,
};

export default Intro;
