import styled, { keyframes } from "styled-components";
import React from "react";
import SelectItem from "../../../components/common/SelectItem";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const Action = (props) => {
  const { onActionSelect } = props;
  const [selected, setSelected] = React.useState();
  // Extract the last item of the selected option history array to determine the next step.
  const lastSelected =
    props?.selectionHistory?.[props?.selectionHistory.length - 1];
  const availableActions =
    props?.activeJourney?.activeJourney?.response?.actions;

  // Get the list of actions associated with the selected need.
  const selectedAction = availableActions?.find(
    (action) => action?.need_id === lastSelected?.id
  );
  // create an array of objects which parses the selectedAction?.CTA string into object containing 2 properties: action and id, where id is the index of the action in the array.
  const ctaList = selectedAction?.CTA.split("\n")
    ?.filter((cta) => cta !== "")
    .map((cta, index) => ({ action: cta, id: index }));

  // create an array of objects which parses the selectedAction?.action string into object containing 3 properties: title, action and id, where id is the index of the action in the array, title is found between ** and **, and action is the text after \nAction:\n
  const actionList = selectedAction?.action
    .split("\n\n")
    ?.filter(
      (action) => action !== null && action !== "" && action !== "Action:"
    )
    ?.map((action, index) => {
      const title = action.match(/\*\*(.*?)\*\*/)?.[1];
      const actionText = action.split("\nAction:\n")[1];
      return { title, actionText, id: index };
    });

  // Using the ctaList and actionList, create a new array of objects which contains the properties of both ctaList and actionList
  const combinedList = ctaList?.map((cta, index) => {
    return {
      ...cta,
      context: selectedAction?.context,
      ...actionList[index],
      type: "action",
    };
  });

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        Which action would you like to focus on, to address your need?
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        This action will help guide you in achieving the outcome you've
        selected.
      </SubtextExtended>
      <SelectArea delay={1}>
        {combinedList?.map((item, index) => (
          <SelectItem
            key={index}
            selected={selected == item.id}
            setSelected={() => {
              onActionSelect(item);
              setSelected(item.id);
            }}
            title={item.title}
            item={item.actionText}
          />
        ))}
      </SelectArea>
    </ScreenContainer>
  );
};

export default Action;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SelectArea = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;
