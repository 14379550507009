// PresentationView.js
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Pagination, Popup } from "semantic-ui-react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import {
  SG_GET_POWERPOINT_PRESENTATION,
  SG_DELETE_POWERPOINT_PRESENTATION,
  SG_GENERATE_AWS_DOWNLOAD_URL,
  CLEAR_POWERPOINT_PRESENTATION,
  SG_GET_POWERPOINT_PRESENTATION_DOWNLOAD,
} from "constants/actions";
import { deleteIcon, downloadIcon } from "./Icons";
import Empty from "../Empty";
import DeleteModal from "./DeleteModal";

const statusColors = {
  new: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
  error: {
    container: "#FEF3F4",
    text: "#F15062",
  },
  failed: {
    container: "#FEF3F4",
    text: "#F15062",
  },
  completed: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  pending: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
};

const PresentationView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pagedData, setPagedData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [downloadingId, setDownloadingId] = useState(false);

  const itemsPerPage = 10;

  const { presentations, download_url } = useSelector(
    (state) => ({
      presentations: state.powerpoint_presentations.presentations,
      download_url: state.powerpoint_presentations.downloadLink,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({ type: SG_GET_POWERPOINT_PRESENTATION });
  }, [dispatch]);

  useEffect(() => {
    if (presentations?.items?.length > 0) {
      setTotalPages(Math.ceil(presentations.length / itemsPerPage));
      const start = (activePage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      setPagedData(presentations?.items?.slice(start, end));
    }
  }, [presentations, activePage]);

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  useEffect(() => {
    console.log(download_url);
    if (download_url) {
      setDownloadingId(null);
      window.open(download_url, "_blank");
      dispatch({ type: CLEAR_POWERPOINT_PRESENTATION });
    }
  }, [download_url, dispatch]);

  const handleDeleteConfirm = () => {
    dispatch({ type: SG_DELETE_POWERPOINT_PRESENTATION, payload: deleteId });
    setDeleteId(null);
  };

  const handleDownload = (fileName, id) => {
    dispatch({
      type: SG_GET_POWERPOINT_PRESENTATION_DOWNLOAD,
      payload: id,
    });
    setDownloadingId(id);
  };

  return (
    <Container>
      <Title>
        Presentation History
        <Button onClick={() => navigate("/leader/audit/presentation")}>
          Create Presentation
        </Button>
      </Title>
      <Header>
        <TH width={30}>Name</TH>
        <TH width={25}>Status</TH>
        <TH width={15}>Date</TH>
        <TH width={10}>Actions</TH>
      </Header>
      {pagedData?.length > 0 &&
        pagedData.map((item) => (
          <Row key={item.id}>
            <TD width={30}>{item.name}</TD>
            <TD width={25}>
              <Status type={item.status} />
            </TD>
            <TD width={15}>
              {format(new Date(item.created_at), "MMM d, yyyy")}
            </TD>
            <TD width={10}>
              <Popup
                trigger={
                  <ActionWrapper onClick={() => handleDelete(item.id)}>
                    {deleteIcon()}
                  </ActionWrapper>
                }
                content="Delete Presentation"
                position="top center"
              />
              <Popup
                trigger={
                  <ActionWrapper
                    onClick={() => handleDownload(item.file_name, item.id)}
                  >
                    {downloadingId === item.id ? (
                      <LoadingSpinner />
                    ) : (
                      downloadIcon()
                    )}
                  </ActionWrapper>
                }
                content="Download Presentation"
                position="top center"
              />
            </TD>
          </Row>
        ))}
      {presentations?.items?.length === 0 && <Empty type="Presentations" />}
      {presentations?.items?.length > itemsPerPage && (
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) => setActivePage(activePage)}
        />
      )}
      <DeleteModal
        open={!!deleteId}
        setOpen={() => setDeleteId(null)}
        handleDeletePDFSavedState={handleDeleteConfirm}
      />
    </Container>
  );
};

export default PresentationView;

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 20px;
  font-family: Raleway;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const LoadingSpinner = ({ loadingText }) => (
  <SpinnerContainer>
    <Loader active inline="centered" size="small">
      {loadingText}
    </Loader>
  </SpinnerContainer>
);

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

const Status = ({ type }) => {
  const typeCleaned =
    type.toLowerCase() === "queued for processing" ? "Pending" : type;
  return (
    <StatusContainer color={statusColors?.[typeCleaned]?.container}>
      <StatusText color={statusColors?.[typeCleaned]?.text}>
        {typeCleaned === "New" ? "Pending" : capitalizeFirstLetter(typeCleaned)}
      </StatusText>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;