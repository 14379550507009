import styled, { keyframes } from 'styled-components';
import React from "react";



const Component11 = () =>{


    return (
        <Container>

            <T1>Behavior's to practice</T1>
            <Text delay={0.5}>
           Based on your data, and the action you've chosen, here is a checklist of behaviors to practice.
            </Text>

            <T2 delay={0.4}>Behaviors</T2>
            <UL delay={0.6}>
                <LI>
                <strong>Communication and transparency:</strong> Respondents mentioned the importance of clear and open communication within the organization. They highlighted the need for regular updates, timely information sharing, and proactive communication from management.
                </LI>

                <LI>
                <strong>Role clarity and responsibility:</strong> Several respondents emphasized the need for clear role definitions and well-defined responsibilities. They mentioned the importance of understanding their role within the organization and having clarity on their tasks and objectives.
                </LI>
                <LI>
                    <strong>Supportive and inclusive culture: </strong> Many respondents praised the supportive and inclusive culture within the organization. They highlighted the importance of feeling valued, respected, and encouraged to be their authentic selves. They mentioned the need for a safe space to share ideas and concerns, as well as support during challenging times.
                   
                </LI>
            </UL>
            

           
           

            
        </Container>
    )
}

export default Component11

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    background-color:#D4F5ED;
    padding:20px;
    border-radius:10px;
    margin-top:20px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    color:#2A3039;
    border-bottom:1px solid #2A3039;
    padding-bottom:10px;
    display:inline-block;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const UL = styled.ul`
  margin-top:20px;
  opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const LI = styled.li`
  margin-bottom:20px;
  line-height:1.4;
`