import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  FETCH_PDF_TEMPLATE,
  ALL_ERRORS,
  SG_FETCH_PDF_TEMPLATE,
  GET_PDF_TEMPLATES,
  SG_GET_PDF_TEMPLATES,
  SG_CREATE_PDF_TEMPLATE,
  CREATE_PDF_TEMPLATE,
  CREATE_PDF_SAVED_COMMENTS,
  SG_CREATE_PDF_SAVED_COMMENTS,
  GET_PDF_SAVED_COMMENTS,
  SG_GET_PDF_SAVED_COMMENTS,
} from "constants/actions";

function* fetchPdfTemplate(action) {
  try {
    const json = yield call(api.FETCH_PDF_TEMPLATE, action.payload);
    yield put({ type: FETCH_PDF_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfFetchLoad() {
  yield takeLatest(SG_FETCH_PDF_TEMPLATE, fetchPdfTemplate);
}

function* getAllPdfTemplates(action) {
  try {
    const json = yield call(api.GET_PDF_TEMPLATES, action.payload);
    yield put({ type: GET_PDF_TEMPLATES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfGetLoad() {
  yield takeLatest(SG_GET_PDF_TEMPLATES, getAllPdfTemplates);
}

function* createPDFTemplate(action) {
  try {
    const json = yield call(api.CREATE_PDF_TEMPLATE, action.payload);
    yield put({ type: CREATE_PDF_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* pdfCreateLoad() {
  yield takeLatest(SG_CREATE_PDF_TEMPLATE, createPDFTemplate);
}

export default function* index() {
  yield all([pdfFetchLoad(), pdfGetLoad(), pdfCreateLoad()]);
}
