import DatePicker from "react-datepicker";
import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";

const DatePick = () => {
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	return (
		<Container>
			<DatePicker
				selected={startDate}
				onChange={(date) => setStartDate(date)}
				selectsStart
				startDate={startDate}
				endDate={endDate}
			/>
			<DatePicker
				selected={endDate}
				onChange={(date) => setEndDate(date)}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
			/>
		</Container>
	);
};

export default DatePick;

const Container = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 15px;
`;
