import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Header, Message, Modal, Input } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import {
  SAVE_TEAM,
  UPDATE_TEAM,
  LOAD_EMPLOYEES
} from "constants/actions";

import TeamTypeList from "settings/member_directory/Teams/TeamTypes/List"
import Learn from "./TeamTypes/Learn"

const CancelButton = styled.div`
  cursor:pointer;
  margin-left:10px;
  font-size:16px;
  color:#4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
`
const P = styled.p`
font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:2px;
`

const Button = styled.button`
background-color:${props => props.disabled ? '#4a4a4a' : '#6a00ff'};
  height: 45px;
  border-radius: 8px;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:7px 24px 7px 24px;
  border: none;
    outline:none;
    margin-left:10px;
`

const SubText = styled.div`
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
   color:${props=>props.error?'red':'#4a4a4a'};
   margin-bottom:20px;

   @media(max-width:768px){
    font-size:16px;
   }
`
const TextLabel = styled.p`
font-size: 18px;
font-weight: 500;
  font-family: 'Red Hat Display', sans-serif;
  margin:15px 0 5px 0;
   color:${props=>props.error?'red':'black'};
   font-weight:bold;
`
const Container=styled.div`
  padding:20px;

   @media (max-width:768px){
    padding:10px;
   
   }
`

const Error = styled.span`
  display:${props=>props.error?'inline':'none'};
`

const StyledHeader = styled(Modal.Header)({
    backgroundImage: 'linear-gradient(45deg,rgba(0, 176, 240,0.3),rgba(0, 187, 255,0.3))!important'
});

const AddTeam = ({ teamd, teamUpdate, setTeamUpdate, ...props }) => {
  const apiDispatch = useDispatch();

  const [open, setOpen] = useState(false)
  const [teamType, setTeamType] = useState(false)
  const [teamName, setTeamName] = useState(false)
  const [errorsFound, setErrorsFound] = useState(false)
  const [learn,setLearn] = useState(0)
  const [organizationId, setOrganizationId] = useState(0)

  const org_id = useSelector((state) => state.auth.organization_id)
  
  const AmbOrganization = useSelector((state) => state.selectedOrg?.organization?.id, shallowEqual)


// get_selectedOrg: 
useEffect(() => {
  setOrganizationId(AmbOrganization? AmbOrganization : org_id)
}, [AmbOrganization, org_id]);

  const save = () => {
    if (!teamName || !teamType) {
      setErrorsFound(true)
      return
    }
    if (teamd) {
      apiDispatch({
        type: UPDATE_TEAM,
        payload: {
          team_type: teamType, 
          name: teamName,
          id: teamd.id
        }
      });
      setTeamUpdate(teamd.id + Math.floor(Math.random() * teamd.id))
    } else {
      apiDispatch({
        type: SAVE_TEAM,
        payload: {
          name: teamName,
          team_type: teamType,
          organization: Number(organizationId)
        }
      });
    }
    setOpen(false)
  }

  // const {
  //   get_teams,
  // } = useSelector(
  //   (state) => ({
  //     get_teams: state.teams,
  //   }),
  //   shallowEqual
  // );

  React.useEffect(() => {
    if (teamd) {
      // let Team = get_teams?.filter((p) => p.id === teamdId)[0]
      setTeamName(teamd.name)
      setTeamType(teamd.team_type)
    }
  }, [teamd])

  function TeamNameChange(e) {
    setTeamName(e.target.value)
  }

  const close = () => {
    setOpen(false)
  }

  return (
    <Modal
      onClose={() => close()}
      onOpen={() => setOpen(true)}
      open={open}
      size={'large'}
      closeIcon
      trigger={props.trigger}
      style={{marginTop:80}}
    >

     {learn?
        <Learn back={()=>setLearn(false)} type={learn}/>
        :
        <>
         <StyledHeader>
        {teamd ? "Edit Team" :
          "Select a team type"}</StyledHeader>
      <Modal.Content>
        <Modal.Description>
          <Container>
        
          <div style={{marginTop:30}}/>
          <TextLabel error={errorsFound && !teamType}><Error error={errorsFound && !teamType}>* (Please select a team type)</Error></TextLabel>
          <TeamTypeList
            teamType={teamType}
            setTeamType={setTeamType} 
            setLearn={(val)=>setLearn(val)}/>

             {teamType?
              <>
               <TextLabel>Team name {errorsFound && !teamName ? '**':""}</TextLabel>
                <Input
                  size='huge'
                  placeholder='Please enter a team name'
                  value={teamName ? teamName : ''}
                  style={{ width: '100%',maxWidth:300 }}
                  onChange={TeamNameChange}
                  error={errorsFound && !teamName}
                />
              </>
              :""}
          <Modal.Actions>
            <div style={{
              display: 'flex', flexDirection: 'row',
              alignItems: 'center', justifyContent: 'flex-end', marginTop: 100
            }}>
              <Button onClick={save}>
                {teamd ? "Update" : "Create"}</Button>
            </div>

          </Modal.Actions>
          </Container>
        </Modal.Description>
      </Modal.Content> 
      </>}

    </Modal>
  )
}


export default AddTeam

