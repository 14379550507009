import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import styled from "styled-components";
import {
  Accordion,
  Icon,
  Table,
  Button,
  Confirm,
  Card,
} from "semantic-ui-react";
import {
  SG_GET_SURVEY_COMPLIANCES,
  SG_EDIT_SURVEY_COMPLIANCE,
  SG_GET_SURVEY_TOKENS,
  SG_GET_SURVEY_RESPONSES,
  LOAD_EMPLOYEES,
} from "constants/actions";
import format from "date-fns/format";
import EmployeeAvatar from "components/EmpAvatar";
import EmailDeliveryReport from "components/EmailDeliveryReport"


// const STATUS = [
//   { value: 1, text: "Scheduled" },
//   { value: 2, text: "Processing" },
//   { value: 3, text: "In Session" },
//   { value: 4, text: "Completed" },
// ];

// const SURVEY_TYPE = [
//   { value: 1, text: "Team Health" },
//   { value: 2, text: "Emotional Health" },
//   { value: 3, text: "Leadership" },
//   { value: 4, text: "Personality" },
// ];

const UserEmployeeCard = (props) => {
  // console.log(props)
  return (
    <EmployeeCard>
      <EmployeeAvatar
        showName
        nameRight
        nonMemberHide={false}
        employee_id={props.item.employee}
        picSize={"small"}
        overRide
      />
      <Icon
        name={props.type == "Pending" ? "question" : "check"}
        style={{ color: props.type == "Pending" ? "#ffb347" : "#04a543" }}
      />
      <EmailDeliveryReport employee_id={props?.item?.employee} />
    </EmployeeCard>
  );
};

const UserCompletion = () => {
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [surveyCompliances, setSurveyCompliances] = React.useState([]);
  const [surveyTokens, setSurveyTokens] = React.useState([]);
  const [surveyResponses, setSurveyResponses] = React.useState([]);
  const [selectedSurvey, setSelectedSurvey] = React.useState({});
  const [openBtn, setOpenBtn] = React.useState(false);
  const [scheduled, setScheduled] = React.useState(false);

  const [complianceId, setComplianceId] = useState(false)
  // const [incompleteMood, SetIncompleteMood] = useState(false)
  const [loopedData, SetLoopedData] = useState(false)


  const org_id = useSelector((state) => state.auth.organization_id);

  // const EmployeeId = useSelector((state) => state.auth?.employee_id, shallowEqual)
  // const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)
  const selectedOrg = useSelector((state) => state.selectedOrg, shallowEqual)

  const [organizationalId, setOrganizationalId] = React.useState(0)

  React.useEffect(() => {
    setOrganizationalId(selectedOrg?.organization?.id ?
      selectedOrg?.organization?.id :
      org_id)
  }, [selectedOrg, org_id]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  React.useEffect(() => {
    // if the response is ok 200 LOAD_EMPLOYEES
    if (organizationalId) {
      dispatch({
        type: LOAD_EMPLOYEES,
        payload: `organization=${organizationalId}`,
      });
      dispatch({
        type: SG_GET_SURVEY_COMPLIANCES,
        payload: `status=3&organization=${organizationalId}`,
      });
    }

  }, [dispatch, organizationalId]);

  // function GetIncompleteCulture(id) {
  //   // if the response is ok 200
  //   // console.log(id)
  //   dispatch({
  //     type: SG_GET_SURVEY_TOKENS,
  //     payload: `?culture_survey_compliance=${id}&organization=${organizationalId}`,
  //   });
  //   dispatch({
  //     type: SG_GET_SURVEY_RESPONSES,
  //     payload: `?compliance=${id}&organization=${organizationalId}`,
  //   });
  //   // /SG_GET_SURVEY_RESPONSES
  //   setSelectedSurvey({
  //     id: id,
  //     name: "Culture Survey",
  //   });
  // }


  // function GetIncompleteMood(id) {
  //   // if the response is ok 200
  //   // console.log(id)
  //   dispatch({
  //     type: SG_GET_SURVEY_TOKENS,
  //     payload: `?mood_survey_compliance=${id}&organization=${organizationalId}`,
  //   });
  //   dispatch({
  //     type: SG_GET_SURVEY_RESPONSES,
  //     payload: `?compliance=${id}&organization=${organizationalId}`,
  //   });
  //   setSelectedSurvey({
  //     id: id,
  //     name: "Mood Survey",
  //   });
  // }

  // function GetIncompleteLeadership(id) {
  //   // if the response is ok 200
  //   // console.log(id)
  //   dispatch({
  //     type: SG_GET_SURVEY_TOKENS,
  //     payload: `?leadership_survey_compliance=${id}&organization=${organizationalId}`,
  //   });
  //   dispatch({
  //     type: SG_GET_SURVEY_RESPONSES,
  //     payload: `?compliance=${id}&organization=${organizationalId}`,
  //   });
  //   setSelectedSurvey({
  //     id: id,
  //     name: "Leadership Survey",
  //   });
  // }

  const {
    get_survey_compliances,
    get_survey_tokens,
    get_survey_responses,
  } = useSelector(
    (state) => ({
      get_selected_teams: state.teamsinschedule.teams_in_schedules,
      get_survey_compliances: state.surveycompliance.survey_compliances,
      get_survey_tokens: state.surveytoken.survey_tokens,
      get_survey_responses: state.surveyresponse.survey_responses,
      get_auth: state.auth,
      get_selectedOrg: state.selectedOrg
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (loopedData && organizationalId) {
      if (complianceId > 0) {
        dispatch({
          type: SG_GET_SURVEY_TOKENS,
          payload: `?mood_survey_compliance=${complianceId}&organization=${organizationalId}`,
        });
        dispatch({
          type: SG_GET_SURVEY_RESPONSES,
          payload: `?compliance=${complianceId}&organization=${organizationalId}`,
        });
      }

    }

  }, [dispatch, complianceId, loopedData, organizationalId]);

  //   console.log(surveyTokens);
  function CloseSurvey() {
    if (get_survey_compliances[0]?.id) {
      dispatch({
        type: SG_EDIT_SURVEY_COMPLIANCE,
        payload: {
          id: get_survey_compliances[0]?.id,
          organization: organizationalId,
          close_at: new Date(),
          query: "close-all=true",
        },
      });
    }

    setOpenBtn(false);
  }

  // console.log(surveyTokens)
  // console.log(get_survey_compliances.filter(e => e.status_text === "In Session"))

  React.useEffect(() => {
    const _surveyList = []
    let data = get_survey_compliances.filter(e => e.status_text === "In Session")
    setSurveyCompliances(data);
    if (data.length > 0) {
      let mood = data.filter(e => e.survey_type === 2)
      if (mood.length > 0) {
        // GetIncompleteMood(mood[0].id)
        _surveyList.push(mood?.[0]?.id)
      }
    }
    SetLoopedData(true)
    setComplianceId(_surveyList)
    // console.log(_surveyList)

  }, [get_survey_compliances]);

  React.useEffect(() => {
    setSurveyTokens(get_survey_tokens);
  }, [get_survey_tokens]);

  React.useEffect(() => {
    setSurveyResponses(get_survey_responses);
  }, [get_survey_responses]);

  function DateDiff(surveyDate) {
    const Difference = [];
    const today = new Date();
    const DueDate = new Date(surveyDate);
    const diffMs = today - DueDate; // milliseconds between now & DueDate
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    if (diffDays === 0) {
      if (diffHrs === 0) {
        if (diffMins < 10) {
          if (!scheduled) {
            setScheduled(true);
          }
        }
      }
    }
    return Difference;
  }

  return (
    <Container>
      <div style={{ width: "100%", padding: 10, position: "relative" }}>
        <Title>Survey In Session</Title>
        <P>View Survey Responses</P>

        <>
          <ButtonContainer>
            <Button
              color="red"
              disabled={scheduled}
              onClick={() => setOpenBtn(true)}
            >
              {scheduled ? "Scheduled to Close" : "Close this survey"}
            </Button>
          </ButtonContainer>
          <Confirm
            open={openBtn}
            header={"Are you sure you want to close the survey?"}
            content={`Closing the survey means that any members who have not currently completed the survey will not be able to complete it. The report will be generated as soon as the survey closes.`}
            onCancel={() => setOpenBtn(false)}
            onConfirm={() => CloseSurvey(selectedSurvey.id)}
          />
        </>
      </div>
      {scheduled && (
        <Card fluid>
          <Card.Content header="Report" />
          <Card.Content description="Your report is queued and would be generated soon" />
        </Card>
      )}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Scheduled</Table.HeaderCell>
            <Table.HeaderCell>Close </Table.HeaderCell>
            <Table.HeaderCell> </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {surveyCompliances.map((item, i) => {
            // console.log(item)
            DateDiff(item.close_at);

            return (
              <Table.Row key={i} negative={scheduled}>
                <Table.Cell>{item.status_text}
                  hello
                </Table.Cell>
                <Table.Cell>{item.survey_type_text}</Table.Cell>
                <Table.Cell>
                  {format(
                    new Date(item.scheduled_at.toLocaleString()),
                    "EEEE, MMM do h:mm a"
                  )}
                </Table.Cell>
                <Table.Cell>
                  {format(
                    new Date(item.close_at.toLocaleString()),
                    "EEEE, MMM do h:mm a"
                  )}{" "}
                </Table.Cell>
                {/*<Table.Cell>
                  {item.survey_type === 1 && selectedSurvey?.id !== item.id && (
                    <Button onClick={() => GetIncompleteCulture(item.id)}>
                      View Status
                    </Button>
                  )}

                  {item.survey_type === 2 && selectedSurvey?.id !== item.id && (
                    <Button onClick={() => GetIncompleteMood(item.id)}>
                      View Status
                    </Button>
                  )}

                  {item.survey_type === 3 && selectedSurvey?.id !== item.id && (
                    <Button onClick={() => GetIncompleteLeadership(item.id)}>
                      View Status
                    </Button>
                  )}
                </Table.Cell>*/}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {/* <Title>Current Survey In Session</Title> */}
      <Accordion style={{ zIndex: -1 }}>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
          style={{ zIndex: -1 }}
        >
          <Icon name="dropdown" />
          Pending Survey Responses{" "}
          {surveyTokens.length > 0 ? "(" + surveyTokens.length + ")" : ""}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <AccordionContainer>
            {surveyTokens.map((item, i) => {
              // console.log(item)
              return <UserEmployeeCard
                item={item} type={"Pending"} key={i} />;
            })}
          </AccordionContainer>
        </Accordion.Content>
      </Accordion>

      <Accordion style={{ zIndex: -1 }}>
        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
          style={{ zIndex: -1 }}
        >
          <Icon name="dropdown" />
          Completed Surveys{" "}
          {surveyResponses.length > 0 ? "(" + surveyResponses.length + ")" : ""}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <AccordionContainer>
            {surveyResponses.map((item, i) => {
              // console.log(item)
              return (
                <UserEmployeeCard
                  item={item}
                  type={"Completed"}
                  date={format(
                    new Date(item.updated_at.toLocaleString()),
                    "EEEE, MMM do h:mm a"
                  )}
                />
              );
            })}
          </AccordionContainer>
        </Accordion.Content>
      </Accordion>
    </Container>
  );
};

export default UserCompletion;

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 50px;
  margin-bottom: 22px;

  @media (max-width: 768px) {
    margin-top: 0;
    font-size: 20px;
  }
`;

export const P = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.31;
`;

const Container = styled.div`
  z-index: -1;
`;

const Setup = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
  width: 100%;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 7px;
  font-family: "Red Hat Display", sans-serif;
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const EmployeeCard = styled.div`
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 #e0e0e0;
  width: 350px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
`;

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 70px;

  @media (max-width: 768px) {
    top: 40px;
  }
`;