import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown, Popup } from "semantic-ui-react";
import LegendStyle from "./LegendStyleOTP";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Empty from "./Empty";
import BasicLegend from "./BasicLegend";
import PDFBasicLegend from "./PDFBasicLegend";
import LegendIcon from "./LegendIcon";
import Heatmap from "./Heatmap";
import { calculate_data } from "./data_navigation/calculate_data";
import Languages from "language/index";
import { useLanguageSetting } from "language/redux_store";

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const colors2 = ["48, 120, 255", "8, 194, 81", "126, 36, 234", "255, 45, 85"];

const colors = [
  "#0387EF",
  "#FFC600",
  "#02AE5B",
  "#FF0000",
  "#fcba03",
  "#2b704d",
  "#472aa3",
  "#a3852a",
  "#a32a89",
  "#2a8ba3",
  "#a1a32a",
  "#2aa330",
];

const options = [
  { key: 1, text: "Winter Sports", value: 1 },
  { key: 2, text: "Summer Sports", value: 2 },
  { key: 3, text: "Para Sports", value: 3 },
];

const options2 = [
  { key: 1, text: "Overview", value: 1 },
  { key: 2, text: "Technical Leaders", value: 2 },
  { key: 3, text: "Sport Scientists", value: 3 },
  { key: 3, text: "Athletes", value: 3 },
  { key: 5, text: "Coaches", value: 5 },
];

const Grid = ({
  data,
  nav,
  refresh,
  structure,
  WhiteLabel,
  setSub,
  pdf,
  rule,
  questions,
  overviewReport = false,
  access,
  selected,
  otpPdf,
  otpView,
  language,
}) => {
  const [highlight, setHighlight] = React.useState(null);
  const [select, setSelected] = React.useState(null);
  const globalLang = useLanguageSetting();
  const configLang = language;
  const language_hook = new Languages(configLang || globalLang);
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

  const get_survey_structure = useSelector(
    (state) => state.surveystructure.survey_structure,
    shallowEqual
  );
  const pdfAnchor = get_survey_structure[0].categories.find(
    (f) => f.priority.toLowerCase() === "primary"
  ).id;

  const rolesAnchor = get_survey_structure[0].categories.find(
    (f) => f.priority.toLowerCase() === "secondary"
  ).id;

  const [calculated, setCalculated] = React.useState([]);
  const [render, setRender] = React.useState(false);

  const checkSubFactors = (id, option) => {
    if (structure.categories[id].subfactors.length > 0) {
      let factor = structure.categories[id].subfactors.filter(
        (f) => f.parent === option
      );
      if (factor.length > 0) {
        return true;
      }
    }

    return false;
  };

  React.useEffect(() => {
    if (anchor && !otpView && !otpPdf) {
      setRender(!render);
      const useAnchor = (pdf === true && pdfAnchor) || anchor;


      setCalculated(
        calculate_data(data, nav, structure, useAnchor, rule, questions, access)
      );
    }

    if (otpView && otpView) {
      setRender(!render);

      const useAnchor = pdfAnchor;
      setCalculated(
        calculate_data(data, nav, structure, useAnchor, rule, questions, access)
      );
    }

    if (otpPdf && !otpView) {
      setRender(!render);
      const useAnchor = rolesAnchor;
      if (useAnchor) {
        setCalculated(
          calculate_data(
            data,
            nav,
            structure,
            useAnchor,
            rule,
            questions,
            access
          )
        );
      }
    }
  }, [nav, refresh, anchor, otpView]);

  if (calculated.filter((f) => f.raw.length > 0).length === 0) {
    return <Empty />;
  }

  return (
    <BigC>
      <First color={WhiteLabel.highlight} className="title-bar">
        <Title>{language_hook.fetch_words("culture_title")}</Title>
      </First>
      <Main pdf={otpPdf}>
        {selected == 1 ? (
          <Container pdf={otpPdf}>
            {calculated
              .filter((f) => f.raw.length > 0)
              .map((item, i) => (
                <MasterDot
                  data={item}
                  i={i}
                  highlight={highlight}
                  selected={select == i}
                  hide={select != null && select != i}
                />
              ))}

            <AxisText overviewReport={overviewReport}>
              {language_hook.fetch_words("performance")}
            </AxisText>
            <AxisTextTop>{language_hook.fetch_words("person")}</AxisTextTop>
            <Axis>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i, k) => (
                <Tick key={k} otpPdf={otpPdf}>
                  {i}
                </Tick>
              ))}
            </Axis>
            <Axis2>
              {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0].map((i, k) => (
                <Tick hideTick={i === 5} key={k}>
                  {i}
                </Tick>
              ))}
            </Axis2>
            <Quad>
              <H2>{language_hook.fetch_words("culture_of")}</H2>
              <Title>{language_hook.fetch_words("culture_quadrant_1")}</Title>
            </Quad>
            <Quad>
              <H2>{language_hook.fetch_words("culture_of")}</H2>
              <Title>{language_hook.fetch_words("culture_quadrant_2")}</Title>
              <Excellence>
                <H2>{language_hook.fetch_words("culture_of2")}</H2>
                <H1 otpPdf={otpPdf}>
                  {language_hook.fetch_words("culture_quadrant_5")}
                </H1>
              </Excellence>
            </Quad>
            <Quad>
              <H2>{language_hook.fetch_words("culture_of2")}</H2>
              <Title>{language_hook.fetch_words("culture_quadrant_3")}</Title>
            </Quad>
            <Quad>
              <H2>{language_hook.fetch_words("culture_of2")}</H2>
              <Title>{language_hook.fetch_words("culture_quadrant_4")}</Title>
            </Quad>
            {(pdf && (
              <PDFBasicLegend
                overviewReport={overviewReport}
                show={select != null || pdf}
                structure={structure}
                anchor={anchor}
                pdf={otpPdf}
                isFrench={() => language_hook.is_french()}
              />
            )) || (
              <BasicLegend
                overviewReport={overviewReport}
                show={select != null || pdf}
                structure={structure}
                anchor={anchor}
                pdf={otpPdf}
                isFrench={() => language_hook.is_french()}
              />
            )}
          </Container>
        ) : (
          <Heatmap
            questions={questions}
            categories={structure}
            nav={nav}
            rule={rule}
            data2={data}
            anchor={anchor}
            color={WhiteLabel.highlight}
            language_hook={(w) => language_hook.fetch_words(w)}
            isFrench={() => language_hook.is_french()}
          />
        )}

        {selected == 1 ? (
          <Legend otpPdf={otpPdf}>
            <LTitle>
              <LegendIcon />
              {language_hook.fetch_words("legend")}
            </LTitle>
            {/*{chart.map((item,i)=>{
          return <Dot 
          color={item.color} 
          title={item.name}
           i={i} 
           hover={(d)=>setHighlight(d)} 
           highlight={highlight}/>
        })}
        */}
            {calculated
              .filter((f) => f.raw.length > 0)
              .map((item, i) => {
                return (
                  <LegendStyle
                    title={item.title}
                    data={item}
                    i={i}
                    otpPdf={otpPdf}
                    hover={(d) => setHighlight(d)}
                    highlight={highlight}
                    selected={select}
                    onClick={(d) => setSelected(d)}
                    progress_color={WhiteLabel.progress_color}
                    language_hook={(w) => language_hook.fetch_words(w)}
                    isFrench={language_hook.is_french()}
                    questions={questions}
                  />
                );
              })}
          </Legend>
        ) : (
          ""
        )}
      </Main>
    </BigC>
  );
};

export default Grid;

const Dot = (props) => {
  return (
    <DotContainer
      hover={
        props.highlight === null
          ? true
          : props.highlight == props.i
          ? true
          : false
      }
      onMouseEnter={() => props.hover(props.i)}
      onMouseLeave={() => props.hover(null)}
    >
      <Circle color={props.color} />
      <Text>{props.title}</Text>
    </DotContainer>
  );
};

const DotContainer = styled.div`
  display: flex;
  cursor: pointer;
  opacity: ${(props) => (props.hover ? 1 : 0.3)};
`;

const MasterDot = ({ data, i, highlight, selected, hide }) => {
  const [hover, setHover] = useState(false);

  return (
    <>
      <Popup
        position="top center"
        content={data.title.name}
        trigger={
          <Position
            x={data.summary[1]}
            y={data.summary[0]}
            hover={highlight === null ? true : highlight === i ? true : false}
            hide={hide}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Circle2
              color={data.color}
              hover={highlight === i || hover ? true : false}
            />
          </Position>
        }
      />

      <Deviation
        color={data.color}
        top={data.summary[0]}
        left={data.summary[1]}
        hover={highlight === i || hover ? true : false}
        hide={hide}
        x={data.stdev[0] * 1.96}
        y={data.stdev[1] * 1.96}
      />

      {data.raw.map((item, i) => (
        <SmallCircle
          color={item.color}
          x={selected ? item.y : data.summary[1]}
          y={selected ? item.x : data.summary[0]}
          selected={selected}
        />
      ))}
    </>
  );
};

const LTitle = styled.div`
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const AxisText = styled.div`
  font-size: 18px;
  line-height: 12px;
  color: #414141;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Inter";
  position: absolute;
  bottom: -25px;
  margin-left: 10px;
`;

const AxisTextTop = styled.div`
  position: absolute;
  left: 25px;
  font-size: 18px;
  line-height: 12px;
  font-weight: bold;
  color: #414141;
  display: flex;
  height: 100%;
  align-items: center;
  transform: rotate(270deg) translate(0px, -75px);
  margin-top: -3px;
`;

const BigC = styled.div`
  width: 100%;
  margin-bottom: 40px;
  padding-left: 59px;
  font-family: "Inter", sans-serif;
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.pdf ? "column" : "row")};
`;

const Container = styled.div`
  min-height: ${(props) => (props.pdf ? "650px" : "0")};
  min-width: ${(props) => (props.pdf ? "650px" : "0%")};
  height: ${(props) => (props.pdf ? "500px" : "500px")};
  width: ${(props) => (props.pdf ? "500px" : "50%")};
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  position: relative;
  left: ${(props) => (props.pdf ? "20%" : "0")};
`;

const Quad = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 50%;
  position: relative;
`;

const Title = styled.div`
  font-size: 20px;
  text-transform: uppercase;

  color: #414141;
`;

const H1 = styled.div`
  font-size: ${(props) => (props.otpPdf ? "13px" : "16px")};
  font-weight: bold;
  text-transform: uppercase;
`;

const H2 = styled.div``;

const Deviation = styled.div`
  opacity: ${(props) => (props.hide || !props.hover ? 0 : 0.1)};
  background-color: ${(props) => props.color};
  height: ${(props) => props.y * 10 + "%"};
  width: ${(props) => props.x * 10 + "%"};
  border-radius: 50%;
  left: ${(props) => "calc(" + (props.left - props.x / 2) * 10 + "% + 7.5px)"};
  bottom: ${(props) => "calc(" + (props.top - props.y / 2) * 10 + "% + 7.5px)"};
  position: absolute;
`;

const Excellence = styled.div`
  width: 40%;
  height: 40%;
  border-bottom: 1px dashed black;
  border-left: 1px dashed black;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Tick = styled.div`
  padding-left: ${(props) => (props.otpPdf ? "17px" : "5px")};
  line-height: ${(props) => (props.otpPdf ? "20px" : "25px")};
  opacity: ${(props) => (props.hideTick ? "0" : "1")};
`;

const Axis = styled.div`
  width: 100%;
  height: 25px;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 25px);
  left: 12px;
`;
const Axis2 = styled.div`
  height: 100%;
  width: 25px;
  border-left: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: calc(50% + 1px);
  top: -15px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: ${(props) => (props.otpPdf ? "100%" : "50%")};
  margin-left: ${(props) => (props.otpPdf ? "0" : "0")};
`;

const Circle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: ${(props) => props.color};
  border: ${(props) => "1px solid" + props.color};
`;
const Circle2 = styled.div`
  height: ${(props) => (props.hover ? "30px" : "15px")};
  width: ${(props) => (props.hover ? "30px" : "15px")};
  border-radius: ${(props) => (props.hover ? "15px" : "7.5px")};
  background-color: ${(props) => props.color};
  border: 2px solid black;
  transition: all 0.5s;
  transform: ${(props) => (props.hover ? "translate(-7.5px,7.5px)" : "none")};
`;

const Text = styled.div`
  margin-left: 10px;
`;

const Position = styled.div`
  position: absolute;
  left: ${(props) => props.x * 10 + "%"};
  bottom: ${(props) => props.y * 10 + "%"};
  z-index: 10;
  opacity: ${(props) => (props.hide ? 0 : props.hover ? 1 : 0.3)};
  cursor: pointer;
`;
const First = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 20px;
  color: #414141;
  align-items: center;
  padding-left: 5px;
  margin-bottom: 30px;
  margin-top: 19px;
  font-family: "Poppins", sans-serif;
`;

const Position2 = styled.div`
  position: absolute;
  left: ${(props) => props.x * 10 + "%"};
  bottom: ${(props) => props.y * 10 + "%"};
  z-index: -1;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${(props) => (props.selected ? 1 : 0)};
`;

const SmallCircle = styled.svg`
  position: absolute;
  left: ${(props) => props.x * 10 + "%"};
  bottom: ${(props) => props.y * 10 + "%"};
  z-index: -1;
  cursor: pointer;
  transition: all 0.5s;
  opacity: ${(props) => (props.selected ? 1 : 0)};
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: ${(props) => props.color};
  border: ${(props) => "1px solid" + props.color};
`;
