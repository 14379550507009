import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from 'semantic-ui-react'
import data from "data/onboarding.json";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  SG_GET_ONBOARDINGS,
  SG_EDIT_ONBOARDING,
} from "constants/actions";

const Tool = (props) =>{

	return (
		<Tools>
			<Link to={props.data.url}>
				<ColorBar color={props.color}/>
				<IconContainer color={props.color}>
					{props.completed? 
					<Icon name={"check"}/>:
						<Icon name={props.data.img}/>
					}
				</IconContainer>
				<ToolTitle>{props.data.title}</ToolTitle>
				<ToolDescription>{props.data.description}</ToolDescription>
				<Icon name="arrow right"  style={{position:'absolute',right:20,bottom:20}}/>
			</Link>
		</Tools>
		)
}

const EndScreen = (props) =>{

	return (
		<End>
			<ColorBar color={props.color}/>
				<IconContainer color={props.color}>
					<Icon name={"check"}/>
				</IconContainer>
				<ToolTitle>Account setup complete</ToolTitle>
				<ToolDescription>Your innerlogic account is completely setup. Now that you and your team are using innerlogic you can expect a survey to come your way on the day and time that has been setup. In the meantime, hang tight, and check back once your first report has been generated. </ToolDescription>
				<Button onClick={()=>props.CloseOnBoarding()}>Got it</Button>
		</End>
		)
}



const AccountSetup = () =>{
	const dispatch = useDispatch();
	const [onBoardingData, setOnBoardingData] = React.useState(false)
	const [totalNumber, settotalNumber] = React.useState(0);
	const [activeIndex, setActiveIndex] = React.useState(-1);

	const {get_onboarding,get_auth} = useSelector(
      (state) => ({
        get_onboarding: state.onboarding.onboarding,
          get_auth: state.auth,
      }),
      shallowEqual
    );

     React.useEffect(() => {
    // if the response is ok 200
    if (get_auth?.token) {
      dispatch({
        type: SG_GET_ONBOARDINGS,
      });
    }
  }, [dispatch, get_auth]);



	React.useEffect(() => {
      setOnBoardingData(get_onboarding)
      console.log(get_onboarding)
  }, [get_onboarding]);

	React.useEffect(() => {
    settotalNumber(
      Math.floor(parseFloat(onBoardingData?.completed_percent) * 100)
    );
    if (parseFloat(onBoardingData?.completed_percent) > 0.9) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(0);
    }
  }, [onBoardingData]);

	function CloseOnBoarding() {
    dispatch({
      type: SG_EDIT_ONBOARDING,
      payload: {
        id: onBoardingData.id,
        is_dismissed: true
      },
    });
    setOnBoardingData(false);
  }


	return (
		<>
		 {!onBoardingData?.is_dismissed && (
		<Container>
			<Title>Account Setup {onBoardingData ? totalNumber : ""}% </Title>
			<Line/>


			{
				onBoardingData && totalNumber < 99 ?
				<ToolContainer>
				{data.map((item,i)=>{
						if (onBoardingData[item.ref] > 0) {

			                return (
			                  <Tool
			                    data={item}
			                    key={i}
			                    i={i}
			                    onBoarding={onBoardingData}
			                    completed={onBoardingData[item.ref] === 2 ? true : false}
			                    color={'#6a00ff'}
			                  />
			                );
			              } else {
			                return null;
			              }
							})}
						</ToolContainer>
				: 
				<EndScreen color={'#6a00ff'} CloseOnBoarding={()=>CloseOnBoarding()}/>

			}
			
		</Container>)}
		 </>
		)
}


export default AccountSetup


const Container = styled.div`

`

const Title = styled.div`
	font-size:14px;
	color:#545454;
`

const Line = styled.div`
	width:100%;
	height:1px;
	background-color:#e9e9e9;
`


const Tools = styled.div`
	width:275px;
	height:300px;
	box-shadow: 0 4px 20px 0 #ececec;
	padding:20px;
	position:relative;
	margin-right:20px;
	margin-top:20px;

	a{
		color:black;
	}
`


const End = styled.div`
	width:500px;
	height:300px;
	box-shadow: 0 4px 20px 0 #ececec;
	padding:20px;
	position:relative;
	margin-right:20px;
	margin-top:20px;

	a{
		color:black;
	}
`


const ColorBar = styled.div`
	width:100px;
	background-color:${props=>props.color};
	height:5px;
	position:absolute;
	top:0;
	left:0;
`

const IconContainer = styled.div`
	height:50px;
	font-size:50px;
	color:${props=>props.color};
	margin-top:20px;
`

const ToolTitle = styled.div`
	font-size:18px;
	font-weight:bold;
	margin-bottom:20px;
	margin-top:10px;
`

const ToolDescription = styled.div`
	
`

const ToolContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
`

const Button = styled.div`
	position:absolute;
	right:20px;
	bottom:20px;
	padding:5px 10px;
	background-color:#6a00ff;
	color:white;
	cursor:pointer;
	font-weight:bold;
`