import React, { useState,useEffect } from 'react';

import {Popup} from 'semantic-ui-react'

import styled, { keyframes } from "styled-components";
import Box from './Box'
import {useNavigate} from 'react-router-dom'
import Labels from './Labels';
import Back from 'ProtoType/SimpleDash/Back'
import AnalysisDashboard from '../AnalysisDashboard';
import Create from '../AnalysisDashboard/Create'
import NoData from 'assets/images/UI/no_data.svg'

const History = () =>{
    const navigate = useNavigate();
    const [active,setActive] = useState(3)


    return (
        <Container>
            <Center>
                <Top>
                <Title>
                    Data Management
                   
                </Title>

                <Popup 
                    position='top center'
                    content='You do not have access to this feature.'
                    inverted
                     trigger={(<ButtonContainer><Button disabled={true}>Upload new data</Button></ButtonContainer>) }
                />
                
                     </Top>



                <Empty>
                    <img src={NoData} style={{width:200}}/>
                    <Text>No data yet.</Text>
                    <Description>
                        This is your hub for all data related to your organization. Upload data to get started.
                    </Description>
                </Empty>



                
                
                {/* <Tabs>
                   <Tab onClick={()=>setActive(3)} active={active==3}>History</Tab>
                   <Tab onClick={()=>setActive(2)} active={active==2}>Labels</Tab>

                </Tabs> 
                {active==1? 
                <AnalysisDashboard/>
                :active==2?
                <Box
                      title={'Regional franchise financial data'}
                      icon={'file'}
                      onClick={()=>navigate('/simple/prototype/groupdata/history/entry')}
                      description={'March 20th, 2024'}/>
                      : <Labels/>}   */}
              

            </Center>
        </Container>
    )

}

export default History


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:800px;
    `

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`


const Tabs = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-start;
    margin-bottom:20px;
    margin-top:30px;
`

const Tab = styled.div`
    padding:10px 20px;
    color:${props=>props.active ? 'blue' : '#2A3039'};
    border-bottom:${props=>props.active ?  '2px solid blue':'none'};
    margin-right:20px;
    cursor:pointer;
`

const Button = styled.button`
  background-color: #2d70e2;
  color: white;
  font-famiy: 'Raleway';
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
    border:none;

  &:disabled {
    opacity:0.6;
    cursor: not-allowed;
  }


`;

const ButtonContainer = styled.div`
    display:flex;
    align-items:center;
`

const Top = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
`

const Empty = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:50px;
    margin-bottom:50px;
    height:500px;
    width:100%;
    flex-direction:column;
    color:#2A3039;
    font-size:18px;
    font-weight:bold;
`

const Text = styled.div`
    color:#2A3039;
    font-size:20px;
    font-weight:600;
    margin-top:30px;
`

const Description = styled.div`
    color:#666D79;
    font-size:16px;
    font-weight:400;
    line-height:1.5;
    margin-top:20px;
    max-width:600px;
    text-align:center;
`