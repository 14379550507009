import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { AiIcon } from "./Icons";
import { Button, Progress } from 'semantic-ui-react'

const AwaitingQuery = ({context_length}) => {
  const [waitingText, setWaitingText] = useState("Gathering insights");
  const [progress, setProgress] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  function getRandomValueFromArray(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}

const get_time = () =>{

  if(context_length <100){
    return 20
  }
  if(context_length <200){
    return 80
  }
  return 150
}

useEffect(() => {
  let interval;

  if (currentIndex < chatbot_intro_lines.length - 1) {
    interval = setInterval(() => {
      setCurrentIndex(currentIndex + 1);
      setWaitingText(chatbot_intro_lines[currentIndex + 1]);
    }, 4000);
  }

  return () => {
    if (interval) {
      clearInterval(interval);
    }
  };
}, [currentIndex, chatbot_intro_lines]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, get_time());

    return () => clearInterval(timer); // This is the cleanup function to clear the interval
  }, []);


  return (
    <Container>
      <Circle>{AiIcon()}</Circle>
      <Message>
        <Description>
          <LoadingText>{waitingText}</LoadingText>
          <Progress percent={progress} indicating size='tiny'/>
        </Description>
      </Message>
    </Container>
  );
};

export default AwaitingQuery;

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
`;

const Message = styled.div`
  align-self: center;
  background: #F8FAFF
  padding: 16px;
  border-radius: 8px;
`;

const LoadingText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  align-content: center;
  font-size: 16px;
  color: #2a3039;
  margin-bottom:5px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.error ? "#F15062" : "#2A3039")};
  white-space: pre-wrap;
  width:350px;
`;

const Image = styled.img`
  height: 25px;
`;


const chatbot_intro_lines = [
"Gathering insights",
"Connecting to our internal knowledge base...",
"Processing your query...",
"Generating insights...",
"Summarizing your query...",
"Compiling your response...",
]
