import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import ReportType from "../components/ReportType";
import ReportOptions from "../components/ReportOptions";
import ReportCategories from "../components/ReportCategories";
import ReportPrimaries from "../components/ReportPrimaries";
import ReportPermissions from "../components/ReportPermissions";
import ReportSimpleRequest from "../components/ReportSimpleRequest";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
} from "constants/actions";
import { filter_data } from "reports/Tag/calculations";
import { Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import NewCreate from "./NewCreate";

const getCategoryOptions = (categories, parentId) => {
  let categoryList = [];
  const parentCategory = categories.find(
    (category) => category.id === parentId
  );

  if (parentCategory) {
    parentCategory.options.forEach((option) => {
      categoryList.push({
        id: option.id,
        name: option.name,
      });
    });
  }

  return categoryList;
};

const getEmptyFilters = (data, filters) => {
  let emptyFilters = [];
  filters?.forEach((filter) => {
    const hasData = data?.find((item) =>
      item?.categories?.find((cat) => cat?.response === filter?.id)
    );
    if (!hasData) {
      emptyFilters.push(filter.id);
    }
  });

  return emptyFilters;
};

const ReportCreation = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(2);
  const [reportType, setReportType] = useState(7);
  // parent_id
  const [reportOptions, setReportOptions] = useState();
  // parent_children
  const [reportCategories, setReportCategories] = useState([]);
  // primary_list
  const [reportPrimaries, setReportPrimaries] = useState([]);
  // secondary selected
  const [secondarySelected, setSecondarySelected] = useState(false);
  // Anchor Name
  const [anchorName, setAnchorName] = useState("");
  // Report settings
  const [reportSettings, setReportSettings] = useState();
  // Filtered category list
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [orgName, setOrgName] = useState("");

  const navigate = useNavigate();
  const {
    get_employee_categories,
    get_auth_employee,
    latestData,
    get_auth,
    coreData,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      coreData: state.audit?.core_data,
      pdfReports: state.pdfReports,
      get_auth: state.auth,
      latestData: state.audit?.filtered_data,
      get_employee_categories: state.employee_category,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
    });
  }, []);

  useEffect(() => {
    const employeeId = Number(get_auth_employee?.employee_id);
    const employeeCategories =
      get_employee_categories?.employee_user_category?.find(
        (category) => category.employee === employeeId
      );

    if (employeeCategories) {
      const orgName = employeeCategories?.category?.find(
        (cat) => cat?.priority === "primary"
      )?.value;
      if (orgName) {
        setOrgName(orgName);
      } else {
        setOrgName(coreData?.categories?.[0]?.name);
      }
    } else {
      const orgName = coreData?.categories?.[0]?.name;
      setOrgName(orgName);
    }
  }, [get_employee_categories]);

  useEffect(() => {
    if (reportOptions && coreData?.categories?.length > 0) {
      const secondaries = coreData?.categories?.[0]?.categories?.find(
        (category) => category.priority === "secondary"
      );

      const primary = coreData?.categories?.[0]?.categories?.find(
        (category) => category.priority === "primary"
      );
      // get the anchor name of the reportOptions

      const anchor = coreData?.categories?.[0]?.categories?.find(
        (category) => category.id === reportOptions
      );
      if (anchor?.priority === "primary") {
        setAnchorName("location");
      } else {
        setAnchorName(anchor?.name.toLowerCase());
      }

      if (secondaries?.id === reportOptions || primary?.id === reportOptions) {
        setSecondarySelected(true);
      } else {
        setSecondarySelected(false);
      }
    }
  }, [reportOptions, coreData?.categories]);

  useEffect(() => {
    let filteredLatestData;
    if (reportOptions && coreData?.categories?.length > 0) {
      const options = getCategoryOptions(
        coreData.categories?.[0].categories,
        reportOptions
      );

      const selectedFilters = options.map((option) => {
        return { id: option.id, parent: reportOptions };
      });

      if (latestData?.[0]?.[0]) {
        filteredLatestData = filter_data(latestData?.[0], selectedFilters);
      } else {
        filteredLatestData = filter_data(latestData, selectedFilters);
      }

      const emptyFilters = getEmptyFilters(filteredLatestData, selectedFilters);
      // remove any options with no data
      const filteredOptions = options.filter(
        (option) => !emptyFilters.includes(option.id)
      );

      setFilteredCategories(filteredOptions);
    }
  }, [reportOptions]);

  useEffect(() => {
    if (get_auth_employee?.employee_id) {
      const employeeId = Number(get_auth_employee?.employee_id);

      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }
  }, [get_auth_employee]);
  const renderContent = () => {
    switch (stage) {
      case 1:
        return (
          <>
            <BackButton
              onClick={() => {
                navigate("/leader/audit/reports/stories");
              }}
            >
              <Icon name="chevron left" style={{ marginTop: -7 }} />
              Back
            </BackButton>
            <T1>Story Creation</T1>
            <Description>Please select a report template option</Description>

            <Options>
              <ReportType
                title={"Culture Insights Report"}
                id={7}
                next={() => {
                  setReportType(7);
                  setStage(2);
                }}
                description={
                  "This report identifies the most impactful themes driving your culture."
                }
                pageLength={2}
                typeTitle={"Culture"}
              />
            </Options>
          </>
        );
      case 2:
        return (
          <ReportOptions
            next={(id) => {
              if (id === "all") {
                setReportOptions([]);
                setStage(5);
              } else {
                setReportOptions(id);
                setStage(3);
              }
            }}
            nomenclature="Story"
            reportType={reportType}
            goBack={() => navigate("/leader/audit/reports/stories")}
          />
        );
      case 3:
        return (
          <ReportCategories
            selectedParent={reportOptions}
            filteredOptions={filteredCategories}
            nomenclature="Story"
            next={(ids) => {
              setReportCategories(ids);
              if (secondarySelected) {
                setStage(5);
              } else {
                setStage(4);
              }
            }}
            goBack={() => setStage(2)}
          />
        );
      case 4:
        return (
          <ReportPrimaries
            next={(ids) => {
              setReportPrimaries(ids);
              setStage(5);
            }}
            nomenclature="Story"
            goBack={() => {
              setStage(3);
              setReportPrimaries([]);
            }}
          />
        );
      case 5:
        if (reportType === 4 || reportType === 7) {
          return (
            <ReportSimpleRequest
              primaries={reportPrimaries}
              categories={reportCategories}
              parentCategory={reportOptions}
              templateId={reportType}
              anchorName={anchorName}
              get_auth={get_auth}
              nomenclature="Story"
              next={() => setStage(6)}
              pdfOptions={reportSettings}
              orgName={orgName}
              setPdfOptions={(options) => setReportSettings(options)}
              goBack={() => {
                if (secondarySelected) {
                  setStage(3);
                } else {
                  setStage(4);
                }
              }}
            />
          );
        }

        return (
          <ReportPermissions
            primaries={reportPrimaries}
            categories={reportCategories}
            templateId={reportType}
            parentCategory={reportOptions}
            anchorName={anchorName}
            get_auth={get_auth}
            nomenclature="Story"
            next={() => setStage(6)}
            pdfOptions={reportSettings}
            orgName={orgName}
            goBack={() => {
              setStage(4);
            }}
            setPdfOptions={(options) => setReportSettings(options)}
          />
        );
      default:
        return "";
    }
  };

  return (
    <Container>
      <Center>
        <NewCreate
          filteredOptions={filteredCategories}
          setReportOptions={setReportOptions}
        />
        {/* {renderContent()} */}
      </Center>
    </Container>
  );
};

export default ReportCreation;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  font-family: "Raleway";
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;
const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const Options = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;
