import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import IMG from 'assets/images/stock/mountain.jpg'
import IMG2 from 'assets/images/stock/forest.jpg'
import { useNavigate } from 'react-router-dom';

const Introduction = ({next}) => {
    const navigate = useNavigate();
    
    return (
        <Container>
            <Center>
                <Title>
                    Deploy a new story or plan
                </Title>

                <Sub>Understand or build the culture you want</Sub>
        
                <Options>
                <Box>
                    <Half2>
                        <Image src={IMG}/>
                    </Half2>
                    <Half>
                        <T2>
                            New Data Story
                        </T2>
                        <Description>
                               Lets build a story with your data. This tool is designed to guide you through the highest level elements of your data. So that you spend less time analyzing and crunching numbers, and more time actioning change.
                        </Description>

                        <Button onClick={()=>navigate('/simple/prototype/story')}>Create Story</Button>
                    </Half>
                   
                    
                </Box>

                <Box>
                    <Half2>
                        <Image src={IMG2}/>
                    </Half2>
                    <Half>
                        <T2>
                            New Action Plan
                        </T2>
                        <Description>
                               Lets build a story with your data. This tool is designed to guide you through the highest level elements of your data. So that you spend less time analyzing and crunching numbers, and more time actioning change.
                        </Description>

                        <Button2  onClick={()=>navigate('/simple/prototype/plan')}>Create Plan</Button2>
                    </Half>
                    
                    
                </Box>
                </Options>
               
            </Center>
            
                   
        </Container>
    )

}

export default Introduction


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
    padding:20px;
    width:100%;
    margin-top:30px;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Center = styled.div`
width:100%;
max-width:1000px;
`


const Title = styled.div`
    font-size:30px;
    font-weight:bold;
    margin-bottom:30px;
`

const Description = styled.div`
    line-height:1.7;
    max-width:500px;
`

const Box = styled.div`
    border:1px solid #E0E0E0;
    border-radius:10px;
    padding: 40px 30px;
    display:flex;
    flex-direction:column;
    margin-bottom:10px;
    width:49%;
`

const Half = styled.div`
    width:100%;
    padding-right:30px;
`

const Half2 = styled.div`
    width:100%;
    max-height:200px;
    overflow:hidden;
    border-radius:10px;
    margin-bottom:20px;
`

const Image = styled.img`
    width:100%;
    
`

const T2 = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:10px;
`

const Sub = styled.div`
    font-size:16px;
    font-weight:400;
    margin-bottom:20px;
`
const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-top:20px;
    font-size:14px;
    font-weight:500;
    transition: all 0.3s ease;
    

    &:hover{
        transform: scale(1.05); 
    }
`

const Button2 = styled.button`
    background-color: #27CDA7;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-top:20px;
    font-size:14px;
    font-weight:500;
    transition: all 0.3s ease;
    

    &:hover{
        transform: scale(1.05); 
    }
`

const Options = styled.div`
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
`