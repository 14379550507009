import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual,useDispatch} from "react-redux";
import SplashScreen from "./SplashScreen";
import MethodSelection from "./MethodSelection";
import ROIDiagnostic from "./ROIDiagnostic";
import BenchmarkDiagnostic from "./BenchmarkDiagnostic";
import RawScoreDiagnostic from "./RawScoreDiagnostic";
import DiagnosticReport from "./DiagnosticReport";
import ROIDiagnosticStep2 from "./ROIDiagnosticStep2";
import ActionIntro from "./ActionIntro";
import ContextLibrary from "./ContextLibrary";
import ContextLibrary2 from "./ContextLibrary2"; // Import the new component
import ActionPlanReport from "./ActionPlanReport";
import IntroScreen from "./IntroScreen";
import { filter_data } from "reports/Tag/calculations";
import { Loader } from "semantic-ui-react";
import { SET_NEW_FILTERS } from "constants/actions";

const Diagnostic = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isIntro, setIsIntro] = useState(true);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedContext, setSelectedContext] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [selectedOutcomeID, setSelectedOutcomeID] = useState(null);
  const [selectedOutcomeName, setSelectedOutcomeName] = useState(null);
  const [roiData, setRoiData] = useState(null);
  const [dataWithFilters, setDataWithFilters] = useState(null);
  const [recommendationLevel, setRecommendationLevel] = useState(null);
  const [correctedData, setCorrectedData] = useState(null);
  const raw_data =  useSelector(
    (state) => state.audit?.raw_data,
    shallowEqual
  );

  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_NEW_FILTERS,
        payload: [],
      });
    }
  }, []);





  useEffect(() => {
    if(raw_data && raw_data[0]){
      setCorrectedData(raw_data[0].responses);
    }
  }, [raw_data]);

  useEffect(() => {
    if (selectedFilters.length > 0 && selectedFilters[0].parent != null && correctedData) {
      const filteredData = filter_data(correctedData, selectedFilters);
      setDataWithFilters(filteredData);
    } else {
      setDataWithFilters(null);
    }
  }, [selectedFilters]);

  const handleNextStep = (method = null, context = null, action = null) => {
    if (method) setSelectedMethod(method);
    if (context) setSelectedContext(context);
    setLoading(true);
    setStep((prevStep) => prevStep + 1);

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleOutcomeSelected = (outcomeID, outcomeName, roiData) => {
    setSelectedOutcomeID(outcomeID);
    setSelectedOutcomeName(outcomeName);
    setRoiData(roiData);
    setStep(3); // Move to step 3 for ROI, which is ROIDiagnosticStep2
  };

  const handlePreviousStep = () => {
    setLoading(true);
    // Define the step to go back to based on the current step and selected method
    if (step === 1) {
      setIsIntro(true); // Go back to Intro from MethodSelection
    } else if (step === 2) {
      setStep(1); // Go back to MethodSelection from any diagnostic selection
    } else if (step === 3 && selectedMethod === "ROI") {
      setStep(2); // Go back to ROIDiagnostic from ROIDiagnosticStep2
    } else if (step === 3) {
      setStep(2); // Go back to MethodSelection from BenchmarkDiagnostic or RawScoreDiagnostic
    } else if (step === 4) {
      if (selectedMethod === "ROI") {
        setStep(3); // Go back to ROIDiagnosticStep2 for ROI method
      } else {
        setStep(2); // Go back to BenchmarkDiagnostic or RawScoreDiagnostic
      }
    } else if (step === 5) {
      setStep(4); // Go back to ContextLibrary2 from ContextLibrary
    } else if (step === 6) {
      setStep(5); // Go back to ContextLibrary from ActionPlanReport
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleFactorSelected = (factor) => {
    setLoading(true)
    setSelectedFactor(factor);

    // If in ROIDiagnosticStep2, proceed directly to ContextLibrary2 (step 4)
    if (selectedMethod === "ROI" && step === 3) {
      setStep(4);
    } else if (
      selectedMethod === "Benchmark" ||
      selectedMethod === "RawScore"
    ) {
      // Go to ContextLibrary2 (step 4) for Benchmark and RawScore methods
      setStep(4);
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
    
  };


  if (!core_data?.categories?.[0]?.categories) {
    return null;
  }

  if (isIntro) {
    return <IntroScreen start={() => setIsIntro(false)} data_length={dataWithFilters? dataWithFilters?.length : 100} />;
  }

  if (loading) {
    return (
      <Container>
        <Loader inline active/>
      </Container>
    );
  }

  return (
    <Container>
      <Content>
        {step === 0 && (
          <SplashScreen
            onContinue={() => handleNextStep()}
            goBack={() => setIsIntro(true)}
          />
        )}
        {step === 1 && (
          <MethodSelection
            onSelectMethod={handleNextStep}
            goBack={handlePreviousStep}
            benchmarks={core_data?.standards?.response}
          />
        )}

        {/* Diagnostic methods based on selected method */}
        {step === 2 && selectedMethod === "ROI" && (
          <ROIDiagnostic
            data={dataWithFilters ? dataWithFilters : correctedData}
            rawData={correctedData}
            onOutcomeSelected={handleOutcomeSelected}
            goBack={handlePreviousStep}
          />
        )}
        {step === 2 && selectedMethod === "Benchmark" && (
          <BenchmarkDiagnostic
            onFactorSelected={handleFactorSelected}
            data={dataWithFilters ? dataWithFilters : correctedData}
            goBack={handlePreviousStep}
          />
        )}
        {step === 2 && selectedMethod === "RawScore" && (
          <RawScoreDiagnostic
            data={dataWithFilters ? dataWithFilters : correctedData}
            onFactorSelected={handleFactorSelected}
            goBack={handlePreviousStep}
          />
        )}

        {/* Step 3: ROIDiagnosticStep2 (only for ROI method) */}
        {step === 3 && selectedMethod === "ROI" && (
          <ROIDiagnosticStep2
            selectedOutcomeID={selectedOutcomeID}
            selectedOutcomeName={selectedOutcomeName}
            onFactorSelected={handleFactorSelected}
            goBack={handlePreviousStep}
            roi_data={roiData}
            data={dataWithFilters ? dataWithFilters : correctedData}
          />
        )}

        {/* Step 4: ContextLibrary2 for all methods */}
        {/* {step === 4 && (
          <ContextLibrary2
            onSelectLevel={(level) => {
              setRecommendationLevel(level);
              handleNextStep();
            }}
            goBack={handlePreviousStep}
          />
        )} */}

        {/* Step 5: ContextLibrary */}
        {/* {step === 5 && (
          <ContextLibrary
            onSelectContext={(context) => handleNextStep(null, context)}
            selectedFactor={selectedFactor}
            method={selectedMethod}
            goBack={handlePreviousStep}
            context={recommendationLevel}
          />
        )} */}

        {/* Step 6: ActionPlanReport */}
        {step === 4 && (
          <ActionPlanReport
            context={selectedContext}
            action={selectedAction}
            selectedFactor={selectedFactor}
            method={selectedMethod}
            selectedOutcomeID={selectedOutcomeID}
            selectedOutcomeName={selectedOutcomeName}
            goBack={handlePreviousStep}
            data={dataWithFilters ? dataWithFilters : correctedData}
            recommendationLevel={recommendationLevel}
            resetSteps={() => setStep(1)}
          />
        )}
      </Content>
    </Container>
  );
};

export default Diagnostic;

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  font-family: "Raleway";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height:500px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Raleway";
`;

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
`;
