import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dimmer, Loader, Pagination } from "semantic-ui-react";
import Insight from "./Insight";
import Filter from "./Filter";

import { combo_effect_size_calculation } from "../Analytics/calculations";

import { Data_Generator } from "../testing/generate_data";

const ES_levels = [0, 0.2, 0.5, 0.8, 1.2];

const Insights = (props) => {
  const [data, setData] = useState([]);
  const [dataRef, setRef] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTertiary = (name, id) => {
    return props.categories.filter((f) => f.name === name)[0].options[id]?.name;
  };

  const responseSet = props.surveyData.filter((set) => {
    return set.responses.length > 0;
  });

  useEffect(() => {
    if (data.length === 0) {
      let _data = combo_effect_size_calculation(
        props.surveyQuestions,
        props.categories,
        responseSet,
        0
      )
        .flat()
        .flat()
        .map((item) => {
          if (item && item.data) {
            return item.data.map((i) => {
              return i.ES.map((e, idx) => {
                return {
                  factor: item.factor,
                  primary: item.category,
                  secondary: item.category2,
                  risk: e.risk,
                  n: e.n,
                  tertiary: getTertiary(i.name, idx),
                  avg: e.avg,
                };
              });
            });
          }
        })
        .flat()
        .flat()
        .filter((i) => i && !isNaN(i.risk))
        .sort((a, b) => {
          return a.risk - b.risk;
        });

      setData(_data);
      setRef(_data);
      setTimeout(() => setLoading(false), 400);
    }
  }, []);

  return (
    <Container>
      <Title>Top 3 Insights</Title>
      <InsightContainer>
        {data.slice(0, 3).map((item) => {
          return (
            <Insight
              title={`${item.factor} in ${item.tertiary} ${
                item.secondary === "Overall" ? "employees" : item.secondary
              } ${item.primary === "Overall" ? "" : item.primary}`}
              risk={item.risk}
              n={item.n}
              link={item.factor}
              avg={item.avg}
              description={""}
            />
          );
        })}
      </InsightContainer>
    </Container>
  );
};

export default Insights;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
`;

const Filters = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Risk = styled.div`
  font-size: 20px;
`;

const Add = styled.div`
  cursor: pointer;
`;

const RiskContainer = styled.div``;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const InsightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
