import { partitionSelectDependencies } from 'mathjs';
import React from 'react';
import styled from 'styled-components';

// Styled components
const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; // This spreads out the steps evenly
  position: relative;
  height: ${props=>props.total*35+'px'};// subtract the height of the circles to start and end on the line
`;

const ProgressLine = styled.div`
  width: 2px;
  background-color: #e0e0e0;
  position: absolute;
  left: 7.5px; // align with the top of the first circle
  top:3px;
  height:calc(100% - 6px);
  z-index: 0;
`;

const Step = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const FilledStep = styled(Step)`
  border-color: #007bff;
  background-color: #007bff;
  color: white;
`;

const Checkmark = styled.span`
  font-size: 10px;
`;

const StepLabel = styled.div`
  z-index: 2;
  margin-left:10px;
  font-family:'Raleway';
  color:${props=>props.active?'#007bff':'#666D79'};
  font-weight:${props=>props.active?'bold':'normal'};
  max-width:250px;
`;

const Time = styled.div`
  position:absolute;
  bottom:-25px;
  left:20px;
  font-size:12px;
  font-family:'Raleway';
`
// ProgressT

// ProgressTracker component

// ProgressTracker component
const ProgressTracker = ({ steps, currentStep }) => {
  return (
    <ProgressBarContainer total={steps.length}>
      <ProgressLine />
      {steps.map((step, index) => {
        const isFilled = index < currentStep;
        const active = index === currentStep;
        return (
          <div key={index} style={{ position: 'relative',display:'flex',alignItems:'center'}}>
            {
              active?
              <FilledStep/>:
            isFilled ? (
              <FilledStep>
                <Checkmark>✓</Checkmark>
              </FilledStep>
            ) : (
              <Step />
            )}
            <StepLabel active={active||isFilled}>{step.name}</StepLabel>
          </div>
        );
      })}
    </ProgressBarContainer>
  );
};


const App = ({step,totalSteps,selectStep,parts}) => {
 let stepsData = [];

 parts.map((part,index)=>{
  stepsData.push({name:`Action ${index+1}`})
 })

    stepsData.push({name:'Review your plan',time:'~ 1 minutes'})

    stepsData.push({name:'Set Milestones',time:'~ 2 minutes'})

    stepsData.push({name:'Setup progress tracking',time:'~ 1 minutes'})
 
  return <ProgressTracker steps={stepsData} currentStep={step} selectStep={selectStep}/>;
};

export default App;

