import React, { useState, useEffect } from 'react'

import { add } from 'date-fns'

export const LongCountDownTimer = () => {

    const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(`${year}-12-31`) - +new Date();
        let timeLeft = {};

        // console.log(difference)

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });

    // console.log(timerComponents)
    return (
        <div>
            <h1>
                {timerComponents.length ? timerComponents : <span>Time's up!</span>}
            </h1>

        </div>
    );
}

export default LongCountDownTimer;

export const ShortCountDownTimer = ({ futureTime }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const difference = +new Date(futureTime) - +new Date();
        // console.log(difference)
        const Days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const Hours = Math.floor((difference / (1000 * 60 * 60)) % 24)

        if (difference > 0) {
            return `${Days && `${Days}d`} ${Hours && `${Hours}:`}${Math.floor((difference / 1000 / 60) % 60)}:${Math.floor((difference / 1000) % 60)}`
        };

        return false
    }


    useEffect(() => {
        const timer1 = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer1)
    });

    return timeLeft

}



export const AddToCountDownTimer = ({
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 1
}) => {

    const [futureTime] = useState(
        add(new Date(), {
            years,
            months,
            weeks,
            days,
            hours,
            minutes,
            seconds: 30,
        })
    )
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


    function calculateTimeLeft() {

        // console.log(futureTime)
        const difference = +new Date(futureTime) - +new Date();
        // console.log(difference)
        const Days = Math.floor(difference / (1000 * 60 * 60 * 24))
        const Hours = Math.floor((difference / (1000 * 60 * 60)) % 24)

        if (difference > 0) {
            return `${Days && `${Days}d`} ${Hours && `${Hours}:`}${Math.floor((difference / 1000 / 60) % 60)}:${Math.floor((difference / 1000) % 60)}`
        };

        return false
    }


    useEffect(() => {
        const timer1 = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer1)
    });

    return timeLeft

}

// export function SayHello({
//     years = 0,
//     months = 0,
//     weeks = 0,
//     days = 0,
//     hours = 0,
//     minutes = 1
// }) => {

//     const [futureTime] = useState(
//         add(new Date(), {
//             years,
//             months,
//             weeks,
//             days,
//             hours,
//             minutes,
//             seconds: 30,
//         })
//     )
//     const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());


//     function calculateTimeLeft() {

//         console.log(futureTime)
//         const difference = +new Date(futureTime) - +new Date();
//         console.log(difference)
//         const Days = Math.floor(difference / (1000 * 60 * 60 * 24))
//         const Hours = Math.floor((difference / (1000 * 60 * 60)) % 24)

//         if (difference > 0) {
//             return `${Days && `${Days}d`} ${Hours && `${Hours}:`}${Math.floor((difference / 1000 / 60) % 60)}:${Math.floor((difference / 1000) % 60)}`
//         };

//         return false
//     }


//     useEffect(() => {
//         setTimeout(() => {
//             setTimeLeft(calculateTimeLeft());
//         }, 1000);
//     });

//     return timeLeft

// }