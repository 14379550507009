import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import ColumnSorting from "assets/images/UI/ColumnSorting.svg"


const TagManagement = ({open,setOpen}) =>{

    return (
        <Table>
                        <TableHeader>
                            <TH width={25}>
                                Name
                                <IMG src={ColumnSorting} />
                            </TH>
                            <TH width={25}>
                                Team
                                <IMG src={ColumnSorting} />
                            </TH>
                            <TH width={25}>
                                Updated
                                <IMG src={ColumnSorting} />
                            </TH>
                            <TH width={25}>
                                Status
                                <IMG src={ColumnSorting} />
                            </TH>
                        </TableHeader>

                        <Content>

                            <TableRow selected={true} onClick={()=>setOpen(true)}>
                                <TD width={25}>
                                    <Name>Admin Account</Name>
                                </TD>
                                <TD width={25}>
                                <Team>Team A</Team>
                                </TD>
                                <TD width={25}>
                                    <Name>2022-12-16</Name>
                                </TD>
                                <TD width={25}>
                                    <Status>Tag</Status>
                                </TD>
                            </TableRow>
                        </Content>
                    </Table>

        
    )

}

export default TagManagement


const Table = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;

`

const TableHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 14px;
    border-bottom: 1px solid #DFDFDF;
    padding-left:20px;
`

const TH = styled.div`
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 600;
color:#666D79;
width:${props => props.width}%;
`

const IMG = styled.img`
    width:16px;
    margin-left:10px;
`

const TD = styled.div`
width:${props => props.width}%;
display:flex;
`
const Name = styled.div`
font-family: Raleway;
font-size: 14px;
font-weight: bold;
color:#2A3039;
`

const Team = styled.div`
padding: 4px 8px;
align-items: center;
border-radius: 8px;
background: var(--primary-50, #EBF1FD);
color: var(--primary-500, #2D70E2);
font-family: Raleway;
font-size: 12px;
font-style: normal;
font-weight: 600;
`

const Status = styled.div`
display: flex;
padding: 4px 8px;
border-radius: 8px;
font-family: Raleway;
font-size: 12px;
font-style: normal;
font-weight: 600;
color:#F15062;
background-color:#FEF3F4;
`

const Date = styled.div`

`


const Content = styled.div`
    width:100%;

`
const TableRow = styled.div`
    display: flex;
    width:100%;
    align-items: center;
    padding: 12px 0px;
    background-color:${props=>props.selected? '#F8FAFF':'#fff'};
    padding-left:20px;
    cursor:pointer;

`