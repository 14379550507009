
import styled, { keyframes } from "styled-components";
import React, {useState,useEffect} from "react";
import { Icon } from 'semantic-ui-react'
import data from "data/tools.json";
import AccountSetup from './AccountSetup'
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import EmployeeAvatar from "components/EmpAvatar";

import {
  SG_GET_MOODS,
  SG_GET_CULTURE_DATA,
  LOAD_EMPLOYEES,
  LOAD_TEAMS,
} from "constants/actions";




const Team = () =>{

	const dispatch = useDispatch();
  const [cultureData, setCultureData] = useState([]);

   useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: SG_GET_MOODS });
    dispatch({ type: LOAD_EMPLOYEES });
    dispatch({ type: LOAD_TEAMS });
    dispatch({ type: SG_GET_CULTURE_DATA });
  }, [dispatch]);

   const { mood_data, culture_data } = useSelector(
    (state) => ({
      mood_data: state.moods.moods,
      culture_data: state.culture.culture,
    }),
    shallowEqual
  );

    useEffect(() => {
    if (culture_data) {
      setCultureData(culture_data[0]);
    }
  }, [culture_data]);

	return (
		<Container>
		<Title>Your Team</Title>
			{cultureData?.compliance? (
				<div>
				{cultureData.compliance.map((item, i) => {
						return (
                      <EmployeeAvatar
                        employee_id={item.employee}
                        showFace
                        checked={item.completed}
                        nonMemberHide={false}
                        showEmotionalReport
                        overRide={true}
                        nameRight
                        picSize={'small'}
                      /> )
					})

			}
			</div>
				):""}
		</Container>
		)

}

export default Team

const Container = styled.div`
	width:200px;
	min-width:200px;
`

const Title = styled.div`
	font-size:14px;
	color:#545454;
	margin-bottom:20px;
`
