import styled, { keyframes } from "styled-components";
import React from "react";



const Summary = () =>{

	return (
		<Container>
			<Title>Average response to challenge</Title>
			<Average>86%</Average>
			<Description>Good</Description>
		</Container>
		)
}


export default Summary



const Container = styled.div`
	width:100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
`

const Title = styled.div`
	color:#4a4a4a;
	margin-bottom:10px;
`

const Average = styled.div`
	font-size:50px;
	font-weight:bold;
	width:100%;
	padding:20px 0px;
	display:flex;
	justify-content:center;
`

const Description = styled.div`
	width:100%;
	justify-content:center;
	display:flex;
`