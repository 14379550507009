import React from "react";
import styled from "styled-components";
import { Icon, Popup } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Blank from "assets/images/empty/user.svg";
import LanguageMenu from "./LanguageMenu";
import Settings from "assets/images/nav/cog.svg";
import Compass from "assets/images/nav/compass.svg";
import Logout from "assets/images/nav/logout.svg";
import Language from "assets/images/nav/language.svg";

import HAM from "assets/images/nav/hamburger.svg";

const TopBar = ({ open, setOpen,setHelp }) => {
  const [employee, setEmployee] = React.useState(false);
  const [paymentAlert, setPaymentAlert] = React.useState(false);
  const [organization, setOrganization] = React.useState(false);
  const [checkForStaff, setCheckForStaff] = React.useState(false);

  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );
  const IsStaff = useSelector((state) => state.auth?.is_staff, shallowEqual);
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );

  React.useEffect(() => {
    if (typeof IsStaff == "boolean") {
      // variable is a boolean
      if (IsStaff) {
        setCheckForStaff(true);
      }
      // console.log("IsStaff Boolean")
    }
    if (typeof IsStaff == "string") {
      // variable is a boolean
      if (IsStaff === "true") {
        setCheckForStaff(true);
      }
      // console.log("IsStaff Boolean")
    }
  }, [IsStaff]);

  const { get_employee, get_auth, get_organizations, get_SelectedOrg } =
    useSelector(
      (state) => ({
        get_employee: state.employees,
        get_auth: state.auth,
        get_organizations: state.organizations,
        get_SelectedOrg: state.selectedOrg,
      }),
      shallowEqual
    );

  // console.log(get_SelectedOrg.organization)

  React.useEffect(() => {
    if (get_employee) {
      setEmployee(get_employee[get_auth?.employee_id]);

      //   console.log(get_employee[get_auth?.employee_id])
    }

    if (get_auth?.token) {
      setOrganization(get_organizations[get_auth.organization_id]);
    }
  }, [get_employee, get_auth, get_organizations]);

  // const getLocation = () =>{
  // 	let url = location.pathname
  // 	return Navigation.filter(e => e.link==url)[0].title
  // }
  // console.log(employee)

  // 	React.useEffect(() => {
  //     if (organization?.payment_status < 2) {
  //       setPaymentAlert({
  //         header: <Header>
  //           {organization?.days_for_trial > 0 ? organization?.days_for_trial : 0 } days left in trial
  // 				<Link to="/get-payment">
  //             <Upgrade>Upgrade</Upgrade>
  //           </Link>
  //         </Header>,
  //         content: <div>
  //           Your free trial allows access to both the team health,
  //           and emotional health survey's. To continue your innerlogic
  //           experience, please upgrade your account in the billing section.
  //         </div>
  //       })
  //     }
  //     if (organization?.payment_status > 3) {
  //       setPaymentAlert({
  //         header: <Header>
  //           Payment Failed
  //                   <Link to="/get-payment">
  //             <Upgrade>Retry</Upgrade>
  //           </Link>
  //         </Header>,
  //         content: <div>
  //           You may not be able access to team health,
  //           and emotional health survey's any longer. To continue your innerlogic
  //           experience, please complete your payment in the billing section.
  //         </div>
  //       })
  //     }
  //   }, [organization])
  // account_type === 5 is admin
  // console.log(checkForStaff)
  // if (typeof IsStaff == "boolean") {
  // 	// variable is a boolean
  // 	console.log("IsStaff Boolean")
  // }
  // // console.log(Number(AmbassadorId))
  // console.log(typeof IsStaff);

  return (
    <Container>
      {/* <Ham src={HAM} onClick={() => setOpen(!open)} /> */}
      {/* {WhiteLabel?.logo ? <Logo src={WhiteLabel.logo} open={open} /> : ""} */}
      <div>{employee?.account_type === 5 && paymentAlert?.header}</div>
      <div>
        {get_SelectedOrg?.organization?.name &&
          get_SelectedOrg?.organization?.name}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          right: 10,
        }}
      >
        <Line />
        {Number(AmbassadorId) > 0 && (
          <Link to="/ambassador">
            <Popup
              content="Switch Organizations"
              trigger={<Image src={Compass} />}
            />
          </Link>
        )}
        {checkForStaff && (
          <Link to="/ambassador">
            <Popup
              content="List Organizations / Add Ambassadors (staff feature)"
              trigger={<Image src={Compass} />}
            />
          </Link>
        )}

        {/* <HelpContainer onClick={()=>setHelp()} className="help">
					<Icon name={'help circle'}/> Help
				</HelpContainer> */}

      
      </div>
    </Container>
  );
};

export default TopBar;


const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 0px;
  height: 50px;
  z-index: 998;
  background-color: white;
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-left: 70px;
  font-size: 20px;

  a {
    color: #a5a5a5;
  }
`;

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
`;

const Text = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin-right: 15px;
`;

const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: -5px;
`;

const Role = styled.div`
  font-size: 12px;
  color: #a5a5a5;
`;

const EmployeeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Line = styled.div`
  height: 100%;
  width: 1px;
  background-color: #a5a5a5;
  margin-right: 20px;
`;

const Image = styled.img`
  height: 19px;
  width: 19px;
  margin-right: 20px;
`;

const Title = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #a5a5a5;
`;

const Upgrade = styled.span`
  display: flex;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
  border-radius: 15px;
  font-weight: 900;
  margin-left: 30px;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 15px;
  margin-left: ${(props) => (props.open ? "120px" : 0)};
  transition: all 0.5s;
`;

const Ham = styled.img`
  margin-right:10px;
  cursor:pointer;
`

const HelpContainer = styled.div`
	font-family: "Poppins", sans-serif;
	font-size:12px;
	color:#9E9FA1;
	border-radius:10px;
	padding:0px 10px;
	cursor:pointer;
`


const Menu = styled.div`
  cursor: pointer;
`;

