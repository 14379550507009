import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import Insights from './Insights'
import Hover from './Hover'
import TopBar from './TopBar'
import Distribution from "./Distribution";
import Pillar from "./Pillar";
import ChartNavigation from "./ChartNavigation";


const Ideas = () =>{
    const [chartType,setChartType] = useState('Culture Map')
          return <Pillar/>


    const renderArea = () =>{

        if(chartType==='Culture Map'){
            return (
                <>
                    <ChartContainer>
                    <Chart
                        options={data.options}
                        series={series}
                        type="bubble"
                        width={"100%"}
                        />
                    </ChartContainer>
                        

                        <Hover/>
                </>
            )
        }
        else if(chartType==='Heat Map'){
            return <>Heat map here</>
        }
        else if(chartType==='Distribution'){

            return <Distribution/>
        }
    }


    return (
        <Container>
            <Menu>
                <T><Item>CliQ</Item> / REV</T>

            </Menu>
           <TopBar/>

            <ChartNavigation setChartType={setChartType} chartType={chartType}/>
            {renderArea()}
           
        </Container>
    )
}

export default Ideas;

const Container = styled.div`
    padding-left:58px;
    margin-top:40px;
    width:100%;
`

const Title = styled.h1`
    font-family: 'Raleway';
`
const Top = styled.div`
    display:flex;
    justify-content:space-between;
`
const ChartContainer = styled.div`
    padding:40px;
`
const T = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
margin-bottom:40px;
`

const Menu = styled.div`
    wisth:100%;
    display:flex;
    justify-content:space-between;
`

const Item = styled.span`
color: #2D70E2;
cursor:pointer;
`

const data = {
    options: {
      chart: {
          height: 350,
          type: 'bubble',
          fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
      },
      fill: {
          opacity: 0.7
      },
      legend:{
            show:false
      },
      dataLabels:{
        enabled:false
      },
      xaxis: {
          min:48,
          max:100,
          type: 'numeric',
          title: {
            text: 'Culture Score',
          },
      },
      yaxis: {
            min:-16,
            max:20,
            title: {
                text: '% Change',
              },
      },
      markers: {
        colors: ['#F44336', '#31b055', '#a3a3a2', '#a3a3a2', '#31b055','#31b055'], // Add colors as per your requirement
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: '#000000', // Dark black color
            borderWidth: 2, // Width of the line
            strokeDashArray: 0
          },
        ],
      },
    },
  
  
  };

  const series = [
    {
      name: 'Bubble 1',
      data: [
        { x: 66, y: -10, z: 3,label:"Bubble 1" },
      ],
    },
    {
      name: 'Bubble 2',
      data: [
        { x:75, y: 15, z: 2,label:"Bubble 1"  },
      ],
    },
    {
        name: 'Bubble 3',
        data: [
          { x:78, y: 0, z: 1,label:"Bubble 1"  },
        ],
      },
      {
        name: 'Bubble 4',
        data: [
          { x:85, y: 0, z: 4,label:"Bubble 1"  },
        ],
      },
      {
        name: 'Bubble 5',
        data: [
          { x:52, y: 8, z: 1.5,label:"Bubble 1"  },
        ],
      },
      {
        name: 'Bubble 6',
        data: [
          { x:90, y: 16, z: 3.5,label:"Bubble 1"  },
        ],
      },
      {
        name: 'Bubble 7',
        data: [
          { x:74, y: -5, z: 2.5,label:"Bubble 1"  },
        ],
      }
    // More single-bubble categories...
  ];