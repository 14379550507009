// Libraries
import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { PERSONAS, PERSONA_OPTIONS } from "members/audit/Personas.js";

import {
  SG_GET_EMPLOYEE_ORG_PERMISSIONS,
  SG_GET_SURVEY_STRUCTURES,
} from "constants/actions";

function useGetUserPersona(params) {
  const [persona, setPersona] = useState();
  const [pdfFeatureEnabled, setPDFEnabled] = useState();
  const [structuresDispatched, setStructuresDispatched] = useState();
  const dispatch = useDispatch();

  const { get_survey_structure, get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  const { employee_org_permission } = useSelector(
    (state) => ({
      employee_org_permission: state.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!get_survey_structure?.[0] && !structuresDispatched) {
      setStructuresDispatched(true);
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${get_auth?.organization_id}`,
      });
    }
  }, [get_survey_structure]);

  useEffect(() => {
    if (
      params.isOtp &&
      get_auth?.organization_id &&
      !employee_org_permission?.employee_org_permission?.length > 0
    ) {
      dispatch({
        type: SG_GET_EMPLOYEE_ORG_PERMISSIONS,
        payload: `organization=${Number(get_auth?.organization_id)}`,
      });
    }
  }, [dispatch, get_auth?.organization_id, params.isOtp]);

  useEffect(() => {
    const optionsArray = [];
    if (
      params.user &&
      params.isOtp &&
      params.filteredCategories &&
      get_survey_structure?.[0] &&
      employee_org_permission
    ) {
      params.user?.access_data_analytics === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_datalytics);
      params.user?.access_subfactor_data === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_subfactor);
      params.user?.survey_add_users === 1 &&
        optionsArray.push(PERSONA_OPTIONS.edit_users);
      params.user?.account_type === 5 &&
        optionsArray.push(PERSONA_OPTIONS.is_admin);
      params.user?.enable_login === true &&
        optionsArray.push(PERSONA_OPTIONS.enable_login);
      params.user?.read_feedback === 1 &&
        optionsArray.push(PERSONA_OPTIONS.read_feedback);
      params.user?.survey_schedule === 1 &&
        optionsArray.push(PERSONA_OPTIONS.schedule_survey);
      params.user?.survey_status === 1 &&
        optionsArray.push(PERSONA_OPTIONS.participate_survey);

      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const newTeamArr = primary?.options.map((o) => o.name);

      if (
        newTeamArr?.length > 0 &&
        newTeamArr.every((v) => params.filteredCategories.includes(v))
      ) {
        optionsArray.push(PERSONA_OPTIONS.otp_view);
        if (params.filteredCategories.length > newTeamArr.length) {
          optionsArray.push(PERSONA_OPTIONS.is_cwf);
        }
      }

      if (
        employee_org_permission &&
        employee_org_permission?.employee_org_permission.length > 0
      ) {
        const currentPermissions =
          employee_org_permission?.employee_org_permission.find(
            (p) => p.employee_id === params.user?.id
          );

        if (currentPermissions?.auth_details) {
          const exists = currentPermissions?.auth_details.find((p) => {
            if (p?.name === "pdf_enabled") {
              return true;
            }
            if (p.length > 0) {
              return p[0].name === "pdf_enabled";
            }
            return false;
          });

          if (exists) {
            setPDFEnabled(true);
            optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
          }
          if (
            currentPermissions?.extra_details?.persona &&
            currentPermissions?.extra_details?.persona === "CWF"
          ) {
            setPDFEnabled(true);
            optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
          }
        } else {
          setPDFEnabled(false);
        }
      }

      const persona = PERSONAS.find((p) => {
        return p.options.sort().join(",") === optionsArray.sort().join(",");
      });

      if (persona) {
        setPersona(persona);
      }
    }
  }, [
    params.user,
    params.isOtp,
    params.filteredCategories,
    get_survey_structure,
    params.pdfFeatureEnabled,
    employee_org_permission,
  ]);

  return {
    persona,
    pdfFeatureEnabled,
  };
}

export default useGetUserPersona;
