import styled, { keyframes } from "styled-components";
import React from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
	SG_GET_MOODS,
	SG_GET_CULTURE_DATA,
	LOAD_EMPLOYEES,
	LOAD_TEAMS,
	SG_GET_TEAM_ACCESSS,
} from "constants/actions";

import Team from './Team'
import Profile from './Profile'
import EmptyEmployee from './EmptyEmployee'

const TeamExplore = () => {
	const dispatch = useDispatch();
	const [hide, setHide] = React.useState(false)

	const [defaultTeam, setDefaultTeam] = React.useState(false)
	const [selectedTeam, setSelectedTeam] = React.useState(false)
	const [selectedTeamId, setSelectedTeamId] = React.useState(false)
	const [organizationalId, setOrganizationalId] = React.useState(false)
	// // // // //
	const [employee, setEmployee] = React.useState(false)
	const EmployeeId = useSelector((state) => Number(state.auth?.employee_id), shallowEqual)
	const AmbassadorId = useSelector((state) => Number(state.auth?.ambassador_id), shallowEqual)
	const [teamList, setTeamList] = React.useState([])

	const { get_team_access,
		get_employee, get_auth, get_teams,
		get_selectedOrg } = useSelector(
			(state) => ({
				get_auth: state.auth,
				get_employee: state.employees,
				get_teams: state.teams.teams,
				get_selectedOrg: state.selectedOrg,
				get_team_access: state.team_access.team_access
			}),
			shallowEqual
		);

	React.useEffect(() => {
		// Run! Like go get some data from an API.
		// dispatch({ type: SG_GET_MOODS });
		// console.log("getting data from API", "AmbassadorId",
		// AmbassadorId, "Team Id", selectedTeamId,)
		if (Number(AmbassadorId) > 0) {

			if (organizationalId) {
				dispatch({
					type: LOAD_EMPLOYEES,
					payload: `organization=${organizationalId}`
				});
				dispatch({
					type: LOAD_TEAMS,
					payload: `organization=${organizationalId}`
				});
				dispatch({
					type: SG_GET_CULTURE_DATA,
					payload: `organization=${organizationalId}${selectedTeamId ?
						(`&team=${selectedTeamId}`) :
						''}`
				});
				dispatch({
					type: SG_GET_MOODS,
					payload: `organization=${organizationalId}${selectedTeamId ?
						(`&team=${selectedTeamId}`) :
						''}`
				});
				dispatch({
					type: SG_GET_TEAM_ACCESSS,
					payload: `organization=${organizationalId}`
				});
				return
			}
		}
		// else {

		dispatch({ type: LOAD_EMPLOYEES });

		dispatch({ type: LOAD_TEAMS });

		dispatch({
			type: SG_GET_CULTURE_DATA,
			payload: `${selectedTeamId ?
				(`team=${selectedTeamId}`) :
				''}`
		});

		dispatch({
			type: SG_GET_MOODS,
			payload: `${selectedTeamId ?
				(`team=${selectedTeamId}`) :
				''}`
		});

		if (EmployeeId !== null) {
			dispatch({
				type: SG_GET_TEAM_ACCESSS,
				payload: `employee=${EmployeeId}`
			});
		}

	}, [dispatch, AmbassadorId, organizationalId, selectedTeamId, EmployeeId]);


	React.useEffect(() => {
		setOrganizationalId(get_selectedOrg?.organization?.id)
	}, [get_selectedOrg]);

	React.useEffect(() => {
		setEmployee(get_employee[EmployeeId])
	}, [get_employee, EmployeeId]);

	React.useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setTeamList(get_teams)
			return
		}
		if (employee) {
			if (employee?.account_type === 5) {
				setTeamList(get_teams)
				return
			}
			const _TeamList = []

			_TeamList.push(
				get_teams.filter(
					tm => tm.id === employee?.team)[0]

			)
			get_team_access.map(tm =>
				_TeamList.push(get_teams.filter(
					gt => gt.id === tm?.team)[0]
				)
			)

			setTeamList(_TeamList)

			return
		}

	}, [get_teams, employee, AmbassadorId, get_team_access]);


	function SelTeam(tm) {
		// console.log(tm)
		setSelectedTeam(tm)
		setSelectedTeamId(tm.id)
	}
	
	React.useEffect(() => {
		// console.log(!defaultTeam , get_employee , teamList?.length)
		if (!defaultTeam && get_employee && teamList?.length > 0) {
			
			if (Number(EmployeeId) > 0) {

				setSelectedTeam(teamList.filter(
					tm => tm?.id === get_employee?.[EmployeeId]?.team
				)[0])

				setSelectedTeamId(get_employee?.[EmployeeId]?.team)
				// console.log('defaultTeam', defaultTeam)
				// console.log(!defaultTeam , get_employee , teamList)

				setDefaultTeam(true)
			}
		}

	}, [EmployeeId, get_employee, defaultTeam, teamList]);

	// console.log(selectedTeam)
	// console.log(selectedTeamId)
	// console.log(get_employee)

	return (
		<Container>
			<Left hide={hide}>
				<Filter>
					<Hide onClick={() => setHide(!hide)}>{hide ? 'Show' : 'Hide'}</Hide>
					<Hidden hide={hide}>
						{/* <Dropdown
						    icon='sort amount down'
						    inline
						    scrolling
						    options={options}
						     defaultValue={options[0].value}
						      className='icon'
						  /> */}
					</Hidden>
				</Filter>
				{teamList.map((tm, k) => {
					return <Team
						selected={tm?.id === selectedTeamId}
						team={tm}
						name={tm?.name}
						hide={hide}
						SelTeam={SelTeam}
						key={k}
					/>
				})}

			</Left>
			{selectedTeamId ?
				<Profile
					hide={hide}
					selectedTeam={selectedTeam}
					selectedTeamId={selectedTeamId} />
				:
				<EmptyEmployee />
			}

		</Container>
	)
}


export default TeamExplore


const Left = styled.div`
	width:${props => props.hide ? '60px' : '250px'};
	transition:all 500ms;
	border-right:1px solid #e9e9e9;
	height:calc(100vh - 55px);
	padding-top:10px;
	padding-right:10px;

`

const Container = styled.div`
	display:flex;
	justify-content:space-between;
		margin-top:15px;
	width:100%;
`


const Filter = styled.div`
	display:flex;
	width:100%;
	justify-content:space-between;
	margin-bottom:10px;


`

const Hide = styled.div`
	text-transform:uppercase;
	font-family: 'Red Hat Display', sans-serif;
	color:#9b9b9b;
	 font-size: 12px;
  	font-weight: bold;
  	cursor:pointer;
;
`

const Hidden = styled.div`
	display:${props => props.hide ? 'none' : 'auto'};
`