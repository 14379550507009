import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Loader} from 'semantic-ui-react'
import LogAnimation from './LogAnimation';

const FullPageLoading = () => {

    return (
        <Container>
            <LogAnimation />
           
            <LoadingText>
                Improving your plan
            </LoadingText>
        </Container>
    )

}

export default FullPageLoading;

const Container = styled.div`
    width:100%;
    height:500px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
`

const LoadingText = styled.div`
    font-size:18px;
    font-family:'Raleway';
    margin-top:20px;
`
