import { compositionDependencies } from "mathjs";

var SentimentAnalysis = require("sentiment");


export const get_sentiment_by_anchor = (
  data,
  anchor,
  categories,
  selectedPrimary
) => {
  if (!anchor) {
    return;
  }
  if (data.length == 0) {
    return;
  }
  let categoryList = get_category_list(categories, anchor);

  return categoryList.map((item, i) => {

    let filtered_data = selectedPrimary
      ? data.responses.filter(
          (f) =>
            f.categories.find((f) => f.id === anchor)?.response === item.id &&
            selectedPrimary &&
            f.categories.find((f) => f.priority === "primary")?.response ===
              selectedPrimary
        )
      : data.responses.filter(
          (f) => f.categories.find((f) => f.id === anchor)?.response === item.id
        );

    let cat_sentiment = get_category_ml_sentiment(filtered_data);
    let cat_hr_flags = get_category_hr_flags(filtered_data);
    let cat_quality = get_category_quality(filtered_data);

    return {
      id: item.id,
      name: item.name,
      sentiment: average(cat_sentiment),
      min: Math.min(...cat_sentiment),
      max: Math.max(...cat_sentiment),
      count: filtered_data.length,
      hr_flags: sumArray(cat_hr_flags),
      quality: average(cat_quality),
    };
  });
};

// export const getConversationsByFactor = (anchor, data, selectedPrimaryId) => {
//   let filtered_data = data.responses.filter((f) => {
//     return f.categories.find((f) => f.id === anchor).response === id;
//   });

//   return filtered_data;
// };

function sumArray(numbers) {
  return numbers.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue;
  }, 0);
}

const get_category_list = (categories, anchor) => {
  return categories[0]?.categories.find((f) => f.id == anchor).options;
};

const get_category_quality = (data) => {
  return data
    .map((c, i) => {
      if (c.ml_feedback?.feedback?.length > 0) {
        let feedback = c.ml_feedback.feedback;
        let average_quality = [];
        if (feedback.length > 0) {
          feedback.map((f) => {
            average_quality.push(f.conversation_analysis.quality[0]);
          });
          return average(average_quality);
        }
      }
    })
    .filter((f) => !isNaN(f));
};

const get_factor_quality = (data) => {
  let quality = []
   data
    .map((c, i) => {
         quality.push(c.conversation_analysis.quality[0])
        
    })
    return quality
};

const get_category_ml_sentiment = (data) => {
  return data
    .map((c, i) => {
      if (c.ml_feedback?.feedback?.length > 0) {
        let feedback = c.ml_feedback.feedback;
        let average_sentiment = [];
        if (feedback.length > 0) {
          feedback.map((f) => {
            average_sentiment.push(
              f.conversation_analysis.sentiment[0].compound
            );
          });
          return average(average_sentiment);
        }
      }
    })
    .filter((f) => !isNaN(f));
};

const get_factor_ml_sentiment = (data) => {
  let average_sentiment = [];
  data
    .map((c, i) => {
          average_sentiment.push(c.conversation_analysis.sentiment[0].compound);
    })
   return average_sentiment
};

const get_category_hr_flags = (data) => {
  return data
    .map((c, i) => {
      if (c.ml_feedback?.feedback?.length > 0) {
        let feedback = c.ml_feedback.feedback;
        let flag_count = 0;
        if (feedback.length > 0) {
          feedback.map((f) => {
            flag_count += f.conversation_analysis.hr_flag[0];
          });
          return flag_count;
        }
      }
    })
    .filter((f) => !isNaN(f));
};

const get_factor_hr_flags = (data) => {
  let flags = 0 
  data.map((c, i) => {
      flags += c.conversation_analysis.hr_flag[0];
    })
    return flags
};

const get_category_sentiment = (data) => {
  var sentiment = new SentimentAnalysis();

  return data
    .map((c, i) => {
      let text = "";
      c.feedback.map((item) => {
        text += item.feedback + " ";
        text += item.follow_up;
      });
      let sent = sentiment.analyze(text);
      return sent.score / sent.calculation.length;
    })
    .filter((f) => !isNaN(f));
};

const average = (data) => {
  return data.reduce((a, b) => a + b, 0) / data.length;
};

const get_category_name = (categories, anchor, id) => {
  return categories[0]?.categories
    .find((f) => f.id == anchor)
    .options.find((f) => f.id == id).name;
};

export const get_culture_factor_sentiment = (
  data,
  questions,
  sort_order,
  selectedPrimary
) => {
  if (questions?.length > 0 && sort_order && data) {
    let question = questions.find((f) => f.sort_order == sort_order);

    const factors = get_factor_list(question);

    return factors.map((item, i) => {
      let filtered_feedback = get_filtered_feedback(
        item,
        data,
        selectedPrimary
      );

      let factor_sentiment = get_factor_ml_sentiment(filtered_feedback);
      let factor_hr_flags = get_factor_hr_flags(filtered_feedback);
     let factor_quality = get_factor_quality(filtered_feedback);

      return {
        name: item.factor,
        dimension: item.d,
        factor: item.factor_id,
        sentiment: average(factor_sentiment),
        min: Math.min(...factor_sentiment),
        max: Math.max(...factor_sentiment),
        count: filtered_feedback.length,
        hr_flags: factor_hr_flags,
        quality: average(factor_quality)
      };
    });
  }
};

const get_factor_list = (question) => {
  let factors = [];

  question.questions.dimensions.map((item, i) => {
    item.factors.map((factor) => {
      factors.push({ d: i, factor: factor.title, factor_id: factor.id - 1 });
    });
  });

  return factors;
};

const get_filtered_feedback = (factor, data, selectedPrimary) => {
  let filtered_feedback = [];
  let dataMapping = selectedPrimary
    ? data.responses.filter(
        (f) =>
          f.categories.find((f) => f.priority === "primary").response ===
          selectedPrimary
      )
    : data.responses;


 dataMapping.map((item, i) => {
    if (item.ml_feedback?.feedback?.length > 0) {
        let feedback = item.ml_feedback.feedback.filter(
        (f) => f.id === factor.d && f.factor === factor.factor_id
      );
      if (feedback.length > 0) {
        filtered_feedback.push(...feedback)
      }

      }
  });

  return filtered_feedback
};

const getSentiment = (text) => {
  var sentiment = new SentimentAnalysis();
  let sent = sentiment.analyze(text);
  return sent.score / sent.calculation.length;
};

export const getStructuredFeedback = (id, anchor, data, selectedPrimaryId) => {
  let filtered_data = data.responses.filter((f) => {
    if (selectedPrimaryId) {
      return (
        f.categories.find((f) => f.id === anchor).response === id &&
        f.categories.find((f) => f.priority === "primary").response ===
          selectedPrimaryId
      );
    } else {
      return f.categories.find((f) => f.id === anchor).response === id;
    }
  });

  let feedback = "The following is user feedback from a culture survey:\n\n";
  filtered_data.map((item) => {
    item.feedback.map((f) => {
      feedback += "Response: " + f.feedback + "\n";
      if (item.follow_up) {
        feedback += "Follow Up: " + item.follow_up + "\n\n";
      }
    });
  });

  return feedback;
};

export const getStructuredFeedbackFactor = (factor, data) => {
  let filtered_data = data.responses
    .map((item) => {
      let feedback = item.feedback.filter(
        (f) => f.id == factor.dimension && f.factor == factor.factor
      );
      return feedback;
    })
    .flat();

  let feedback = "The following is user feedback from a culture survey:\n\n";

  filtered_data
    .filter((f) => f.feedback != "")
    .map((item) => {
      feedback += "Question: " + item.question + "\n";
      feedback += "Response: " + item.feedback + "\n";
      if (item?.follow_up && item.follow_up !== "") {
        feedback += "Follow Up Question: " + item.follow_up_question + "\n\n";
        feedback += "Follow Up Response: " + item.follow_up + "\n\n";
      }
    });

  return feedback;
};

export const getConversations = (id, anchor, data, selectedPrimary) => {
  let filtered_data = selectedPrimary
    ? data.responses.filter((f) => {
        return (
          f.categories.find((f) => f.id === anchor).response === id &&
          f.categories.find((f) => f.priority === "primary").response ===
            selectedPrimary
        );
      })
    : data.responses.filter((f) => {
        return f.categories.find((f) => f.id === anchor).response === id;
      });

  return filtered_data;
};