import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Dropdown } from "semantic-ui-react";

const PlanComponent = ({ selected, setSelected }) => {
	const [open, setOpen] = useState(false);

	return (
		<Container>
			<Top onClick={() => setOpen(!open)}>
				<Title>Plan</Title>
				<Description>
					Writen plan from one of our planning templates
				</Description>
			</Top>

			<Dropdown
				placeholder="Select a template"
				fluid
				selection
				value={selected}
				onChange={(e, t) => setSelected(t.value)}
				options={templateOptions}
			/>
		</Container>
	);
};

export default PlanComponent;

const Container = styled.div`
	width: 100%;
	font-family: "Barlow Semi Condensed", sans-serif;
	border-radius: 3px;
	padding: 15px;
`;

const Top = styled.div`
	display: flex;
	margin-bottom: 10px;

	position: relative;
	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
	cursor: pointer;
`;

const Title = styled.div`
	font-weight: bold;
	margin-right: 5px;
	font-size: 16px;
`;

const Description = styled.div`
	font-size: 13px;
	color: grey;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 10px;
`;

const Selected = styled.div``;

const templateOptions = [
	{
		key: "Strategic Gap Analysis",
		text: "Strategic Gap Analysis",
		value: 0,
	},
];
