import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginBottom: 30,
    position: "relative",
    marginTop: 20,
  },
  bottom: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  title: {
    width: "22%",
    display: "flex",
    alignItems: "flex-start",
    textAlign: "left",
    paddingRight: 5,
    fontSize: 10,
    borderRight: "1px solid #2C3644",
    paddingTop: 2,
    paddingBottom: 2,
    color: "#2C3644",
  },
  bar: {
    height: 8,
    opacity: 0.9,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingTop: 2,
    paddingBottom: 2,
  },
  score: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 8,
    position: "absolute",
    right: -25,
    top: -2,
    opacity: 1,
    color: "#2C3644",
  },
  lines: {
    position: "absolute",
    left: "20%",
    height: "100%",
    width: "calc(80% + 20px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    height: "calc(100% + 10px)",
    width: 1,
    borderLeft: "0.5px dashed black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chart: {
    position: "relative",
  },
  text: {
    position: "absolute",
    top: -35,
    left: -25,
    fontSize: 7,
    color: "grey",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: 50,
    textAlign: "center",
  },
  norm: {
    position: "absolute",
    height: "100%",
    width: "100%",
    left: "20%",
  },
});

/**
 * Returns the average, and reverse scoring as per new specs
 * @param {number} avg current average
 * @param {*} item object containing data regarding question type
 */
const getTableAvg = (avg, item) => {
  // if (item && item.reverse) {
  //   return 100 - avg;
  // }

  return avg;
};

const Chart = (props) => {
  const { question, data, barColor, questionId } = props;

  const chartData = data?.anchorQuestionData?.map((item) => {
    return {
      label: item?.optionLabel,
      average: Number(item?.average) * 10,
    };
  });

  const sortedChartData = chartData?.sort((a, b) => {
    return b.average - a.average;
  });

  return (
    <View style={styles.page}>
      <View style={styles.chart}>
        {sortedChartData?.map((item) => {
          const average = item?.average;
          const label = item?.label;

          return (
            <View style={styles.row}>
              <Text style={styles.title}>{label}</Text>
              <View
                style={[
                  styles.bar,
                  {
                    width: 78 * (average / 100) + "%",
                    backgroundColor: barColor,
                  },
                ]}
              >
                <Text style={styles.score}>{Math.floor(average) + "%"}</Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Chart;
