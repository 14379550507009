import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import { get_ROI_params } from './paramCalculation';
import { Table, Icon, Select } from 'semantic-ui-react';
import factor_content from './factor_content.json';


const checkContent = (factor) => {
  if (factor_content[factor]) {
    return factor_content[factor];
  } 
    return "";
  
};

function countUniqueDimensionFactorCombos(data) {
  if (!data || !data.questions) {
      console.error("Invalid data format");
      return 0;
  }



  const uniqueCombos = new Set();

  data.questions.forEach(question => {
      if (question.id !== undefined && question.factor !== undefined) {
          const combo = `${question.id || 0}-${question.factor}`;
          uniqueCombos.add(combo);
      }
  });

  return uniqueCombos.size;
}

const ROIDiagnostic = ({ onOutcomeSelected, goBack, data,rawData }) => {
  const [sortedData, setSortedData] = useState(null);
  const core_data = useSelector((state) => state.audit?.core_data);
  const get_organizations = useSelector((state) => state.organizations);
  const get_auth = useSelector((state) => state.auth);
  const get_survey_questions = useSelector((state) => state.surveyquestions);
  const [selectCard, setSelectCard] = useState(null);

  useEffect(() => {
    if (core_data && data) {
      const organizationData =
        get_organizations[
          get_auth?.organization_id || get_organizations?.organization?.id
        ];
      if (organizationData) {
        const sortOrder = organizationData?.styling?.survey_sequence?.find(
          (item) => item.value === 'outcome_question'
        )?.question?.sort_order;

        if (sortOrder) {
          const outcomeQ = get_survey_questions?.outcome_questions.find(
            (item) => item.sort_order === sortOrder
          );

          if (outcomeQ) {

            // count the total amount of factors in the core data
            const totalFactors = core_data.questions.dimensions.reduce(
              (acc, dimension) => acc + dimension.factors.length,
              0
            );
         


            // Prepare and sort data
            const formattedData = outcomeQ.questions
              .map((item) => {
                const roi_data = get_ROI_params(
                  item.id,
                  item.name,
                  core_data,
                  rawData.filter(f=>countUniqueDimensionFactorCombos(f) === totalFactors),
                  true
                );
                const impact = roi_data[0]?.impact || 0;
                return {
                  name: item.name,
                  roiScore: parseFloat((impact * 100).toFixed(1)), // ROI Impact as a percentage
                  factorId: item.id,
                  best: roi_data[0]?.best || 'N/A',
                };
              })
              .sort((a, b) => b.roiScore - a.roiScore); // Sort smallest to largest

            setSortedData(formattedData);
          }
        }
      }
    }
  }, [core_data, data, get_organizations, get_auth, get_survey_questions]);

  const handleSelect = (factor) => {
     // count the total amount of factors in the core data
     const totalFactors = core_data.questions.dimensions.reduce(
      (acc, dimension) => acc + dimension.factors.length,
      0
    );

    const roi_data = get_ROI_params(
      factor.factorId,
      factor.name,
      core_data,
      rawData.filter(f=>countUniqueDimensionFactorCombos(f) === totalFactors),
      true
    );
    onOutcomeSelected(factor.factorId, factor.name, roi_data);
  };

  const getColorGrade = (score) => {
    const min = -10;
    const max = 10;
    let normalizedScore = (score - min) / (max - min);
    normalizedScore = Math.min(Math.max(normalizedScore, 0), 1);

    const redStart = { r: 241, g: 80, b: 98 }; // #F15062
    const greenEnd = { r: 39, g: 205, b: 167 }; // #27CDA7

    let r, g, b;
    if (normalizedScore <= 0.5) {
      const ratio = normalizedScore * 2;
      r = redStart.r + Math.floor((255 - redStart.r) * ratio);
      g = redStart.g + Math.floor((255 - redStart.g) * ratio);
      b = redStart.b + Math.floor((255 - redStart.b) * ratio);
    } else {
      const ratio = (normalizedScore - 0.5) * 2;
      r = 255 + Math.floor((greenEnd.r - 255) * ratio);
      g = 255 + Math.floor((greenEnd.g - 255) * ratio);
      b = 255 + Math.floor((greenEnd.b - 255) * ratio);
    }
    return `rgb(${r}, ${g}, ${b})`;
  };


 

  if (!sortedData) {
    return (
      <Container>
        <Title>ROI-Based Predictive Model Diagnostic</Title>
        <Description>Loading data...</Description>
      </Container>
    );
  }

  return (
    <Container>
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <Title>ROI-Based Predictive Model Diagnostic</Title>
      <Description>
        Based on our predictive model, we’ve identified key culture factors with
        the potential to yield the highest ROI. Select a factor to view details
        on expected ROI and recommended actions.
      </Description>

    <CardArea>
    {sortedData.map((factor) => (
        <ROICard
          key={factor.factorId}
          factor={factor.name}
          roiScore={factor.roiScore}
          onClick={() => setSelectCard(factor.factorId)}
          maxScore={Math.max(...sortedData.map((item) => item.roiScore))}
          selected={selectCard === factor.factorId}
          description={checkContent(factor.name)?.description}
        />
      ))}
    </CardArea>

    {selectCard ?
     <SelectedAreaComponent 
      factor={sortedData.find((item) => item.factorId === selectCard)} 
      selectFactor={handleSelect}
     />:
      <T3>Select a factor above to view more details and continue</T3>
     }      

    </Container>
  );
};

export default ROIDiagnostic;


// Selected Area Component
const SelectedAreaComponent = ({ factor, selectFactor }) => {
  const factorContent = checkContent(factor.name);
  return (
    <SelectedArea>
      <SelectedTitle>Selected Factor: {factor.name}</SelectedTitle>
      <SelectedDescription>{factorContent?.description}</SelectedDescription>

      <T2>Key Impact Areas:</T2>
      {factorContent.keyImpactAreas.map((area, index) => <li>{area}</li>)}

      <SmallButton onClick={() => selectFactor(factor)}>
        Continue with {factor.name}
      </SmallButton>
    </SelectedArea>
  );
};


const T2 = styled.div`
  font-size:18px;
  font-weight:600;
  margin-bottom:10px;
`

const SelectedDescription = styled.div`
  margin-bottom:20px;
  font-size:14px;

`

const SelectedTitle = styled.div`
  font-size:24px;
  font-weight:bold;
  margin-bottom:20px;
`

const SelectedArea = styled.div`
background-color:#F8FAFF;
padding:20px;
border-radius:8px;
color:#2A3039;
margin-top:20px;
`



const ROICard = ({ factor, roiScore, description,onClick,selected,maxScore }) => {
  console.log(maxScore)

  return (
    <Card onClick={onClick} selected={selected}>
      <CardTitle>
        {factor}
      </CardTitle>
      <CardDescription>Projected ROI Improvement</CardDescription>

      <Score>
        <Icon name="arrow up" style={{color:'#27CDA7',marginRight:'10px'}}/>
        {roiScore}%
      </Score>

      <Bar>
        <BarFill width={roiScore/maxScore*100}/>
      </Bar>

      <CardDescription>
        {description}
      </CardDescription>

    </Card>
  )

}


const Score = styled.div`
  font-size: 24px;
  font-weight: bold;
  display:flex;
  align-items:center;
  margin-top:20px;
  margin-bottom:20px;
`

const Card = styled.div`
  padding: 20px;
  border: ${props=>props.selected? '2px solid #2A3039':'1px solid #DFDFDF'};
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  cursor:pointer;

  &:hover{
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
`

const CardTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color:#2A3039;
  margin-bottom:5px;
`

const CardDescription = styled.div`
font-size:12px;
  color:#666D79;
`

const CardArea = styled.div`
  display:flex;
  gap:20px;
  margin-top:20px;
`

const Bar = styled.div`
  width:100%;
  height:8px;
  background-color:#DFDFDF;
  border-radius:10px;
  overflow:hidden;
  margin-top:10px;
  margin-bottom:20px;
`

const BarFill = styled.div`
  width:${props=>props.width}%;
  height:100%;
  background-color:#2D70E2;
  `
  const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const Container = styled.div`
  padding: 20px;
  width:100%;

  font-family: 'Raleway', sans-serif;
  opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`;

const BackButton = styled.div`
  color: #2D70E2;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  max-width:700px;
`;


const SmallButton = styled.div`
  padding: 6px 12px;
  background-color: #2D70E2;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: background-color 0.3s;
  display:inline-block;
  margin-top:20px;

  &:hover {
    background-color: #1c4db8;
  }
`;


const T3 = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2A3039;
  margin-top: 40px;
  width: 100%;
  text-align: center;
`;
