import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Progress, Icon } from 'semantic-ui-react'

// import Trust from "assets/images/Dependability.svg";
// import Clarity from "assets/images/Clarity.svg";
// import Communication from "assets/images/Communication.svg";
// import Alignment from "assets/images/Direction.svg";
// import Resilience from "assets/images/Resilience.svg";
// import Impact from "assets/images/Impact.svg";
// import Chart from "react-apexcharts";

import CultureDials from './MetricDial'

import { CultureMenu } from "data/live/navigation";
import format from "date-fns/format";
import Empty from '404/Empty'

const Team = ({ empCultureData, selEmp, culComments }) => {

	const [selected, setSelected] = React.useState(CultureMenu[0])
	// const FirstName = CultureMenu[0].name
	
	const [chartData, setChartData] = useState({
		"Resilience": [0, 0]
	});
	
	const [AverageResult, setAverageResult] = useState();
	const [surveyComments, setSurveyComments] = useState();

	// console.log(chartData)
	
	useEffect(() => {
		const _culMenu = {}

		CultureMenu.map(men => {
			// _culMenu.push(
			// 	empCultureData?.responses?.responses.filter(em => em.name === men.name)
			// )
			const _score = []
			// console.log(
			empCultureData.map(
				em => em.responses.map(
					re1 => re1.responses.filter(
						em => em.name === men.name).map(flt => {
							_score.push(
								flt.score
							)
							// console.log(flt)
						})
				)
			)
			// )
			_culMenu[men.name] = _score
		})
		// console.log(_culMenu)
		setChartData(_culMenu)
	}, [empCultureData]);

	function SelectMenu(id) {
		setSelected(CultureMenu.filter(cl => cl.id === id)[0])
	}

	// console.log(chartData?.[selected.name])
	// console.log(chartData)

	useEffect(() => {
		if (chartData?.[selected.name].length > 0) {
			const average = list => list.reduce((prev, curr) => prev + curr) / list.length;
			const list = chartData?.[selected.name]
			setAverageResult(average(list))
		}

	}, [chartData, selected]);

	useEffect(() => {
		
		setSurveyComments(culComments?.[0]?.survey_comments.filter(
			cm => cm.name === selected.name && cm.employee_id === selEmp?.id)[0])
	}, 
	[culComments, selected, selEmp]);

	// console.log(CultureMenu[0]?.name)
	// console.log(surveyComments)
	// console.log(culComments)

	const getChange = () => {
		let current = chartData?.[selected.name][0]
		let last = chartData?.[selected.name][1]
		if (last > current) {
			return 'decrease of ' + (current - last) + '% this week'
		} else if (current > last) {
			return 'increase of ' + (current - last) + '% this week'
		}

		return 'no change this week'
	}

	const getAverage = () => {
		let current = chartData?.[selected.name][0]
		let last = Math.floor(AverageResult)
		if (last > current) {
			return 'below their long term average of ' + last + ' by ' + Math.floor(current - last) + ' points.'
		} else if (current > last) {
			return 'above their long term average of ' + last + ' by ' + Math.floor(current - last) + ' points.'
		}

		return 'consistent with their long term average for this team member.'
	}

	if(empCultureData.length<1){
		return <Empty/>
	}

	return (
		<Container>
			<DateText>Report date: {empCultureData.length > 0 ? format(new Date(empCultureData[0].date), 'MMM do yyyy') : ""}</DateText>
			<DialContainer>
				{CultureMenu.map((cl, k) => {
					return <CultureDials
						title={cl.name} data={chartData?.[`${cl.name}`]}
						selected={selected.id === cl.id}
						setSelected={() => SelectMenu(cl.id)}
						key={k}
					/>
				})}

				{/* <CultureDials title="Clarity" data={chartData?.["Clarity"]} selected={selected.id === 2} setSelected={() => SelectMenu(2)} />
				<CultureDials title="Communication" data={chartData["Communication"]} selected={selected.id === 3} setSelected={() => SelectMenu(3)} />
				<CultureDials title="Trust" data={chartData["Trust"]} selected={selected.id === 4} setSelected={() => SelectMenu(4)} />
				<CultureDials title="Impact" data={chartData["Impact"]} selected={selected.id === 5} setSelected={() => SelectMenu(5)} />
				<CultureDials title="Alignment" data={chartData["Alignment"]} selected={selected.id === 6} setSelected={() => SelectMenu(6)} /> */}

			</DialContainer>



			<Title>{selected.name} Analytics</Title>
			<Comment>
				<SubTitle>{selected.name} Comment</SubTitle>
				<div style={{ display: 'flex', padding: '0 20px 20px 20px', marginTop: 10 }}>
					<Icon name="quote left" style={{ color: '#d8d8d8', fontSize: 20 }} />
					<Description>
						{surveyComments?.comment}
					</Description>
				</div>
			</Comment>
			<Metrics>
				<SubTitle>{selected.name} Trends</SubTitle>
				<TrendText>There was an <strong>{getChange()}</strong> in {selected.name} scores. This score is {getAverage()} </TrendText>
				<H2>This week</H2>
				<Progress percent={chartData?.[selected.name][0]} progress size='small' style={{ marginBottom: 15, marginTop: 2, marginBottom: 0 }} color='purple' />
				<H2>Last week</H2>
				<Progress percent={chartData?.[selected.name][1]} progress size='small' style={{ marginBottom: 15, marginTop: 2, marginBottom: 0 }} color='purple' />
				<H2>Average</H2>
				<Progress percent={Math.floor(AverageResult)} progress size='small' style={{ marginBottom: 15, marginTop: 5 }} color='purple' />

			</Metrics>




		</Container>
	)
}


export default Team

const DialContainer = styled.div`
	display:flex;
	width:100%;
  flex-wrap:wrap;
`

const Container = styled.div`
	width:100%;
  padding:10px;
 	margin-top:20px;
 	position:relative;
 	margin-bottom:50px;
`

const IMG = styled.img`
	width:400px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:30px;
	color:#4a4a4a;
`
const SubTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
`

const Description = styled.div`
	font-family: 'Quicksand', sans-serif;
	font-size:20px;
	line-height:1.3;
	margin-bottom:20px;
	margin-top:10px;
	font-weight:500;
`

const Metrics = styled.div`
	width:100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	background-color:white;
	padding:20px;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	margin-right:10px;
`
const DateText = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:10px;
`

const User = styled.div`
	display:flex;
	width:100%;
	margin-top:2px;
	box-shadow: ${props => props.selected ? '0 2px 40px 0 rgba(220, 220, 220, 0.7)' : 'none'};
	border-radius:8px;
	padding:4px 10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`

const DataContainer = styled.div`
	display:flex;
	width:100%;
	flex-direction:row;
	align-items:center;
	margin-bottom:30px;
`

const Dates = styled.div`
	display:flex;
	position:absolute;
	top:20px;
	right:40px;

`

const Comment = styled.div`
  width:100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding:30px 20px;
  margin-top:20px;
  margin-bottom:20px;
`

const Time = styled.div`
	color:${props => props.selected ? 'white' : '#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props => props.first ? '1px solid #6a00ff' : 'none'};
	background-color:${props => props.selected ? '#6a00ff' : 'none'};
	cursor:pointer;
`


const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
	margin-top:10px;
`
const Score = styled.div`

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:18px;
  color:#6a00ff;
  font-weight:bold;
`
const Top = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
`
const Impacted = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`

const Theme = styled.div`
		margin-top:20px;
	border-bottom:1px solid #e9e9e9;
`


const TopicTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	margin-bottom:10px;
`

const View = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
`

const TrendText = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	margin-top:10px;
	margin-bottom:20px;
`


const data = {

	series: [{
		name: 'Trust',
		data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
			5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
			48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
		]
	}],
	options: {
		chart: {
			type: 'bar',
			width: 350,
			sparkline: {
				enabled: true
			},
			toolbar: { show: false }
		},
		grid: {
			padding: {
				right: 170
			}
		},
		plotOptions: {
			bar: {
				colors: {
					ranges: [{
						from: -1,
						to: 100,
						color: '#6a00ff'
					}, {
						from: -100,
						to: -1,
						color: '#ff2d55'
					}]
				},
				columnWidth: '80%',
			}
		},
		annotations: {
			yaxis: [
				{
					y: 0,
					borderColor: '#e9e9e9',
					offsetX: 170,
					strokeDashArray: 0,
					label: {
						borderColor: '#00E396',
						borderWidth: 0,
						offsetX: 170,
						style: {
							color: '#4a4a4a',
							fontFamily: 'Red Hat Display, sans-serif',
							fontSize: 14
						},
						text: 'Average Trust 73%'
					}
				}
			]
		},
		dataLabels: {
			enabled: false,
		},
		yaxis: {
			title: {
				text: 'Growth',
			},
			labels: {
				formatter: function (y) {
					return y.toFixed(0) + "%";
				}
			}
		},
		xaxis: {
			type: 'datetime',
			categories: [
				'2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
				'2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
				'2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
				'2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
				'2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
				'2013-07-01', '2013-08-01', '2013-09-01'
			],
			labels: {
				rotate: -90
			}
		}
	},


};


const data5 = {

	series: [
		{
			data: [
				{
					x: 'Personal objectives',
					y: [
						new Date('2019-02-27').getTime(),
						new Date('2019-03-04').getTime()
					],
					fillColor: '#008FFB'
				},
				{
					x: 'Work environment',
					y: [
						new Date('2019-03-04').getTime(),
						new Date('2019-03-17').getTime()
					],
					fillColor: '#00E396'
				},
				{
					x: 'Personal life',
					y: [
						new Date('2019-03-07').getTime(),
						new Date('2019-03-10').getTime()
					],
					fillColor: '#775DD0'
				},
				{
					x: 'Team satisfaction',
					y: [
						new Date('2019-03-08').getTime(),
						new Date('2019-03-12').getTime()
					],
					fillColor: '#FEB019'
				},
				{
					x: 'Project deadline',
					y: [
						new Date('2019-03-12').getTime(),
						new Date('2019-03-17').getTime()
					],
					fillColor: '#FF4560'
				}
			]
		}
	],
	options: {
		chart: {
			height: 350,
			type: 'rangeBar',
			toolbar: {
				show: false
			}
		},
		plotOptions: {
			bar: {
				horizontal: true,
				distributed: true,
				dataLabels: {
					hideOverflowingLabels: false
				}
			}
		},
		dataLabels: {
			enabled: true,
			formatter: function (val, opts) {
				var label = opts.w.globals.labels[opts.dataPointIndex]
				return label
			},
			style: {
				colors: ['#f3f4f5', '#fff']
			}
		},
		xaxis: {
			type: 'datetime'
		},
		yaxis: {
			show: false
		},
		grid: {
			row: {
				colors: ['#f3f4f5', '#fff'],
				opacity: 1
			}
		}
	},


};
