import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Popup } from 'semantic-ui-react'
  
const steps = [{
    id:0,
    title:'Account created'
},
{
    id:1,
    title:'Data story viewed'
},
{
    id:2,
    title:'Data story completed'
},
{
    id:3,
    title:'Action plan milestones set'
},
{
    id:4,
    title:'Check in completed'
}]

const interpolateColor = (startColor, endColor, factor) => {
    const hex = color => parseInt(color.slice(1), 16);
    const lerp = (a, b, amount) => (1 - amount) * a + amount * b;

    let color1 = hex(startColor);
    let color2 = hex(endColor);

    let r1 = (color1 >> 16) & 0xff;
    let g1 = (color1 >> 8) & 0xff;
    let b1 = color1 & 0xff;

    let r2 = (color2 >> 16) & 0xff;
    let g2 = (color2 >> 8) & 0xff;
    let b2 = color2 & 0xff;

    let r = Math.round(lerp(r1, r2, factor)).toString(16).padStart(2, '0');
    let g = Math.round(lerp(g1, g2, factor)).toString(16).padStart(2, '0');
    let b = Math.round(lerp(b1, b2, factor)).toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
};

const Progress = ({completed}) =>{

    return (
        <Container>
          {
            steps.map((item)=>{
                const startColor = interpolateColor('#27CDA7', '#2D70E2', item.id / (steps.length - 1));
                const endColor = interpolateColor('#27CDA7', '#2D70E2', (item.id + 1) / (steps.length - 1));
                const gradient = `linear-gradient(to right, ${startColor}, ${endColor})`;
                return <Popup 
                    content={item.title}
                    position='top center'
                    inverted
                    trigger={ <Step 
                    key={item.id} 
                    length={steps.length}
                    completed={item.id<completed} 
                    gradient={item.id < completed ? gradient : 'linear-gradient(to right, #DADADA, #DADADA)'}/>} />
               
            })
          }
        </Container>
    )

}

export default Progress

const Container = styled.div`
    width:100%;
    display:flex;
`

const Step = styled.div`
    width: ${props => 100 / props.length}%;
    height: 10px;
    background: ${props => props.gradient};
    margin-left: 2px;
`;

