import React, { useState, useEffect, useRef } from "react";
import Dropzone from "react-dropzone";
import { Slider } from "react-semantic-ui-range";
import AvatarEditor from "react-avatar-editor";
import styled, { keyframes } from 'styled-components';
import { useDispatch } from "react-redux";

import { Dimmer, Loader, Image, Segment, Button } from 'semantic-ui-react'

import { UPDATE_ORGANIZATION_LOGO, PULL_ORGANIZATION } from "constants/actions";

var EXIF = require("exif-js");



const ImageUpload = ({ organization }) => {
  const dispatch = useDispatch();
  // fakeImage.image_url
  const [image, setImage] = useState(false);
  const [newImage, setNewImage] = useState(true);
  const [editing, setEditing] = useState(null);
  const setEditorRef = useRef(null);

  const [imageUpdated, setImageUpdated] = useState(false)

  useEffect(() => {
    if (organization) {
      // setEmployee(organization);
      if (organization?.logo) {

        setImage(organization.logo);
        setNewImage(false)

      }
    }
  }, [organization]);

  // console.log(organization.logo)
  // console.log(image)

  useEffect(() => {

    const timer1 = setTimeout(() => {
      if (imageUpdated) {
        dispatch({
          type: PULL_ORGANIZATION,
          payload: {
            id: organization.id
          },
        });
        setImageUpdated(false)
      }

    }, 1000)
    return () => clearTimeout(timer1)
  }, [dispatch, imageUpdated, organization]);

  function dataURLtoBlob(dataURL) {
    var array, binary, i;
    binary = atob(dataURL.split(",")[1]);
    array = [];
    i = 0;
    while (i < binary.length) {
      array.push(binary.charCodeAt(i));
      i++;
    }
    return new Blob([new Uint8Array(array)], {
      type: "image/png",
    });
  }

  const onClickSave = () => {
    if (setEditorRef.current) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = setEditorRef.current.getImageScaledToCanvas();

      let image_file = dataURLtoBlob(canvasScaled.toDataURL());
      var myFile = new File([image_file], "name.png");
      if (organization) {
        // // update employee
        dispatch({
          type: UPDATE_ORGANIZATION_LOGO,
          payload: {
            id: organization.id,
            logo: myFile,
          },
        });
        setImageUpdated(true)
      }
    }
  };

  const handleDrop = (dropped) => {
    // var that = this;
    setImage(dropped[0]);

  };

  return (
    <>
      <Segment>
        <Dimmer active={imageUpdated}>
          <Loader content='Loading' />
        </Dimmer>
        <div aria-labelledby="max-width-dialog-title" style={{ paddingLeft: 30 }}>
          {!newImage ? <Button floated="right" icon="remove" onClick={() => {
            setImage(false)
            setNewImage(true)
          }
          } /> : ''}
          <P id="max-width-dialog-title">Upload Company Logo</P>

          <div className="dialog">
            <P>Click the image or Drag &amp; Drop the Image here</P>

            <Dropzone
              onDrop={handleDrop}
              noKeyboard
              disabled={editing}
              style={{ width: "250px", height: "250px" }}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  {/* {console.log(getRootProps())} */}
                  <AvatarEditor
                    ref={setEditorRef}
                    image={image ? image : null}
                    width={150}
                    height={150}
                    border={50}
                    onImageChange={() => setEditing(!editing)}
                    color={[230, 230, 230, 0.7]} // RGBA
                    // scale={scale}
                    // rotate={rotate}
                    borderRadius={125}
                  />

                  {!image ? <input {...getInputProps()} /> : ""}
                </div>
              )}
            </Dropzone>
            {newImage ? (
              <div>
                <Butt onClick={() => onClickSave()} color="grey">
                  Save Picture
                </Butt>
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
      </Segment>
    </>
  );
};

export default ImageUpload;

const P = styled.p`
  font-size: 16px;
  font-family: 'Red Hat Display', sans-serif;
`

const Butt = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:7px 24px 7px 24px;
  border: none;
  outline:none;
`

const Info = styled.div`
    font-size: 14px;
  font-family: 'Red Hat Display', sans-serif;
`

const Box = styled.div`
  border-radius:10px;
  border:1px solid #e9e9e9;
  display:flex;
  align-items:center;
  padding:20px;
  width:340px;
  position:relative;
  margin-bottom:30px;
`

const Rotate = styled.div`
  position:absolute;
  bottom:5px;
  right:-30px;
  width:100px;
  cursor:pointer;
  color:#6a00ff;
`
