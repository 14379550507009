import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Input, Icon } from "semantic-ui-react";
import SelectionTable from "./SelectionTable";

const SourceSelection = ({goBack,setSelectedData,selectedData}) =>{

    const removeSource = (index) =>{
        const newData = selectedData.filter((data, i) => i !== index);
        setSelectedData(newData);
    }
   
    return (
        <Container>
           
            <Title>
                Select a data source
                <SemanticButton onClick={()=>goBack()}>Apply</SemanticButton>
            </Title>


            <SelectedAreaTitle>
                Data currently selected:
            </SelectedAreaTitle>
            <SelectedArea>
                {selectedData.length == 0 && <Placeholder>No data sources selected</Placeholder>}

                {selectedData.map((data, index) => {
                    return <Selected>{data.name} <Icon name="close"  onClick={()=>removeSource(index)} style={{cursor:'pointer'}}/></Selected>

                })}
            </SelectedArea>
                     
                
                <ContentWrapper>
                    <SelectionPane>
                    
                    <DataType selected={true}>Culture</DataType>

                    <DataType>Outcomes</DataType>

                    <DataType>Custom Comments</DataType>

                    <DataType>Pulse Surveys</DataType>

                    <DataType>Slack Channels</DataType>

                    <DataType>Files</DataType>



                    
                    </SelectionPane>

                    <DetailsPane>
                        <DetailsTitle>
                            Your culture data sources
                        </DetailsTitle>
                       
                      <SelectionTable setSelectedData={setSelectedData} selectedData={selectedData}/>


                    </DetailsPane>
                </ContentWrapper>
          
         
        </Container>
    )
}

export default SourceSelection;

const DetailSource = ({title, data_length}) =>{
    return (
        <Source>
            <SourceTitle>{title}</SourceTitle>
            <SourceData>{data_length} data points</SourceData>
        </Source>
    )
}

const Source = styled.div`
    padding:10px;
    border-bottom:1px solid #ddd;
    display:flex;
    justify-content:space-between;
    align-items:center;
`

const SourceTitle = styled.div`
    font-size:14px;
    color:#2A3039;
`

const SourceData = styled.div`
    font-size:12px;
    color:#666D79;
`

// Styled Components
const Container = styled.div`
  font-family: "Raleway", sans-serif;
  width:100%;
  padding:20px;
`;

const Back = styled.div`
  cursor: pointer;
  color: #666D79;
  font-size: 14px;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: 32px;
  color:#2A3039;
  font-weight: 700;
  margin-bottom: 15px;
  width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
`

const ContentWrapper = styled.div`
  display: flex;
  max-height: 90%;
`;

const SelectionPane = styled.div`
  width: 200px;
  border-right: 1px solid #ddd;
  padding:10px;
  overflow-y: auto;
`;

const DetailsPane = styled.div`
  width: calc(100% - 200px);
  padding: 0px 20px;
  position:relative;
`;


const Placeholder = styled.div`
  font-size: 12px;
`;

const DataType = styled.div`
    color:${props => props.selected ? "#2D70E2" : "#666D79"};
    font-size:14px;
    padding:10px 20px;
    cursor:pointer;
    border-radius:8px;
    font-weight:${props => props.selected ? "600" : "400"};

    background-color:${props => props.selected ? "#F7F8FA" : "white"};

    &:hover{
        background-color:#F7F8FA;
    }

`
const DetailsTitle = styled.div`
    font-size:20px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
`

const SemanticButton = styled.div`
    display:inline-block;
    background-color:#EBF1FD;
    color:#2D70E2;
    padding:10px 20px;
    margin-right:5px;
    margin-bottom:5px;
    border-radius:4px;
    font-size:14px;
    font-family: 'Raleway';
    font-weight:600;
    cursor:pointer;
`


const Selected = styled.div`
  border:1px solid #1D9A7D;
    color:#1D9A7D;
    padding:4px 8px;
    margin-right:5px;
    margin-bottom:5px;
    border-radius:8px;
    font-size:14px;
    display:inline-block;
    font-weight:600;
   
`

const SelectedArea = styled.div`
    display:flex;
    flex-wrap:wrap;
    min-height:35px;
    margin-bottom:30px;
`

const SelectedAreaTitle = styled.div`
    color:#666D79;
    font-size:14px;
    font-weight:600;
    margin-bottom:5px;
`