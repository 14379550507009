import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
import Chart from "react-apexcharts";
import {
  dataLabels,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  barChartOptions,
} from "reports/DataJourney/components/common/styles";

const SuccessProbability = () => {
  const options = {
    chart: barChartOptions,
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    colors: ["#2D70E2", "#C7CFDB", "#E6E6E6"],
    xaxis: {
      categories: ["Without data journey", "With data journey"],
      tickPlacement: "between",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    dataLabels: dataLabels,
    yaxis: {
      show: false,
      min: 0,
      max: 8.5,
      decimalsInFloat: 1,
      axisTicks: {
        show: false,
      },
      title: {
        text: "Psychological Safety Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  };

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        The probability of improving your culture goes up by{" "}
        <strong>over 8x.</strong>
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Based on the thousands of teams we've worked with. Teams that followed a
        data journey were significantly more successful in improving their
        culture.
      </SubtextExtended>

      <Row>
        <ChartContainer delay={1}>
          <Chart
            options={options}
            series={data3.series}
            type="bar"
            height={250}
          />
        </ChartContainer>
      </Row>

      <SmallText delay={1}>*Based on a study of 1000+ teams</SmallText>
      <SmallText delay={1}>
        *Improvements measured as % of overall score
      </SmallText>
    </ScreenContainer>
  );
};

export default SuccessProbability;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const ChartContainer = styled.div`
  width: 400px;

  margin-top: 40px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const SmallText = styled.div`
  font-size: 12px;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const data3 = {
  series: [
    {
      data: [0.75, 8],
    },
  ],
};
