import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import ActionCard from "./ActionCard";
import { Message } from "semantic-ui-react";

const ActionList = ({ setSelected, plans }) => {
	return (
		<Container>
			<Top>
				<Title>Action Plans (Demo Only)</Title>

				<StyledButton onClick={() => setSelected(1)}>
					Create Plan
				</StyledButton>
			</Top>
			<Soon>Coming soon</Soon>
			{plans.map((item, i) => (
				<ActionCard
					setSelected={() => setSelected(i + 2)}
					data={item}
				/>
			))}

			{plans.length === 0 && (
				<Message info>
					<Message.Header>Start an Action plan</Message.Header>
					<p>
						There are no action plans currently created. Please
						create a plan.
					</p>
				</Message>
			)}
		</Container>
	);
};

export default ActionList;

const Container = styled.div`
	width: 100%;
	font-family: "Barlow", sans-serif;
	margin-top: 30px;
`;
const Title = styled.div`
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 40px;
`;

const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2d50e2;
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;

const Top = styled.div`
	width: 900px;
	display: flex;
	justify-content: space-between;
`;

const Soon = styled.div``;
