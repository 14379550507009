import React from "react";
import { View, Text, StyleSheet, Page } from "@react-pdf/renderer";
import Chart from "../components/Chart2";
import Footer from "../components/Footer";
import { getFactorAverage, getGrade } from "../utils/calculations";
import Side from '../components/Side'

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#3076B8",
    marginBottom: 15,
  },
  page: {
     padding: 60,
  },
  p: {
    fontSize: 12,
    marginBottom: 10,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#3076B8",
    marginTop: 5,
  },
  description: {
    fontSize: 12,
    marginTop: 15,
    lineHeight:1.7
  },
  section: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#F1F1F7",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 16,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  S1: {
    fontSize: 10,
    marginRight: 15,
  },
  img: {
    height: 20,
  },
  offset: {
    width: "100%",
  },
  summary: {
    border: "2px solid #2C6BAA",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C6BAA",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
  }, 
  colorBlock: {
    width: "100%",
    color: "black",
    marginBottom: 10,
  },
  contentTitle: {
    fontWeight: 700,
    fontSize: 12,
  },
   p2: {
    marginTop: 5,
    fontSize: 11,
    lineHeight: 1.7,
    width:'70%'
  },
  row:{
    flexDirection:'row',
    width:'100%',
    alignItems:'center',
    justifyContent:'space-between'
  },
  result:{
    padding:5,
    fontWeight:700,
    fontSize:14,
    marginLeft:10
  },
  summary: {
    border: "2px solid #2C6BAA",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 10,
  },
  top: {
    backgroundColor: "#2C6BAA",
    color: "white",
    fontSize: 13,
    fontWeight:700,
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop:-1
  },
  bottom: {
    padding: 10,
    paddingBottom:0
  },
});

const Breakdown = (props) => {
  const { responseData, categories, data, surveyStructure, surveyQuestions } =
    props;
  return (
    <Page style={styles.page}>
      <Text style={styles.T2}>{data.title} Breakdown</Text>
      <View style={styles.line} />
      <Text style={styles.description}>
        Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
        tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
        swine, pork chop porchetta picanha spare ribs. Chicken jerky short ribs,
        pastrami pork fatback ground round spare ribs cow t-bone filet mignon
        hamburger leberkas shank. Meatball burgdoggen pork ribeye.
      </Text>
      
    <View style={styles.summary}>
       <View style={styles.top}>
          <Text>Overall Score: 76% (B+)</Text>
          <Text>Score Consensus: High </Text>
          
        </View>  
     <View style={styles.bottom}>   
      <View style={styles.colorBlock}>
          <Text style={styles.contentTitle}>
           Area of Strength
          </Text>
          <View style={styles.row}>
            <Text style={styles.p2}>
              This area is the highest scoring factor in this entire category. 
            </Text>
            <View style={styles.result}>
              <Text style={{fontSize:12}}>Montreal</Text>
            </View>
          </View>
       </View>

         <View style={styles.colorBlock}>
          <Text style={styles.contentTitle}>
           Area of Greatest Opportunity
          </Text>
          <View style={styles.row}>
            <Text style={styles.p2}>
              This area is the lowest scoring factor in this entire category. 
            </Text>
            <View style={styles.result}>
              <Text style={{fontSize:12}}>Non-Binary</Text>
            </View>
          </View>
        </View>


        <View style={styles.colorBlock}>
          <Text style={styles.contentTitle}>
           Area of Highest Consensus
          </Text>
          <View style={styles.row}>
            <Text style={styles.p2}>
              This area is has the lowest varirability in responses indicating that respondents tend to agree with how one another feels on this factor . 
            </Text>
            <View style={styles.result}>
              <Text style={{fontSize:12}}>Arab</Text>
            </View>
          </View>
        </View>

        <View style={styles.colorBlock}>
          <Text style={styles.contentTitle}>
           Area of Lowest Consensus
          </Text>
          <View style={styles.row}>
            <Text style={styles.p2}>
              This area is has the highest varirability in responses indicating that respondents tend to disagree with how one another feels on this factor . 
            </Text>
            <View style={styles.result}>
              <Text style={{fontSize:12}}>Athletes</Text>
            </View>
          </View>
        </View>
      </View>
     </View>







      {categories.map((item, i) => {
        const totalAvg = Math.floor(
          getFactorAverage(data.id - 1, responseData, surveyQuestions)
        );

        return (
          <View wrap={false}>
            <View style={styles.section}>
              <View>
                <Text style={styles.subTitle}>
                  {i + 1}. {item.name}
                </Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.S1}>Average score:</Text>
                <Text>
                  {totalAvg}% ({getGrade(totalAvg)})
                </Text>
              </View>
            </View>

            <View style={styles.offset}>
              <Text style={styles.p}>
                This table contains overall performance metrics for the nuLogic
                Diversity, Equity, and Inclusion assessment. The following
                information appears on all nuLogic reports and is not specific
                to this organization's individual results
              </Text>
              <Chart
                item={item}
                categoryId={item.id}
                options={item.options}
                breakdown={true}
                surveyStructure={surveyStructure}
                responseData={responseData}
                factorId={data.id}
              />
            </View>
          </View>
        );
      })}
      <Footer />
      <Side/>
    </Page>
  );
};

export default Breakdown;
