import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const Custom = styled.input`
	border-radius: 5px;
	height: 50px;
	outline: none;
	border: ${(props) =>
		props.error ? "1px solid #ff2d55" : "1px solid #9b9b9b"};
	margin-top: 10px;
	font-family: "Barlow", sans-serif;
	padding-left: 15px;
`;
const Container = styled.div`
	font-size: 16px;
	font-weight: 400;
	font-family: "Barlow", sans-serif;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
`;

const Error = styled.div`
	font-size: 14px;
	font-style: italic;
	color: #ff2d55;
`;

const Input = React.forwardRef((props, ref) => {
	return (
		<Container>
			<Custom
				id={props.id}
				error={props.error}
				type={props.type}
				placeholder={props.placeholder}
				onChange={(e) => props.onChange(e.target.value)}
				forwardedRef={ref}
			/>
			<Error>{props.error}</Error>
		</Container>
	);
});

export default Input;
