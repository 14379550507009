import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Icon, Checkbox } from "semantic-ui-react";
import { useSelector, shallowEqual } from "react-redux";

const getCategoryOptions = (categories, parentId) => {
  let categoryList = [];
  const parentCategory = categories.find(
    (category) => category.id === parentId
  );
  if (parentCategory) {
    parentCategory.options.forEach((option) => {
      categoryList.push({
        id: option.id,
        name: option.name,
      });
    });
  }

  return categoryList;
};

const ReportOptions = ({ next, selectedParent, lastStep, goBack }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [checkboxDisabled, setCheckboxDisabled] = useState(false);

  const [checkAll, setCheckAll] = useState(false);

  const { coreData } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
    }),
    shallowEqual
  );

  const handleCategorySelected = (id) => {
    if (selectedCategories.includes(id)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== id));
    } else {
      setSelectedCategories([...selectedCategories, id]);
    }
  };

  const handleCheckAll = () => {
    if (checkAll) {
      setSelectedCategories([]);
    } else {
      const allCategoryIds = categoryOptions.map((category) => category.id);
      setSelectedCategories(allCategoryIds);
    }
    setCheckAll(!checkAll);
  };

  const createReport = () => {};

  useEffect(() => {
    if (coreData?.categories && coreData?.categories?.length > 0) {
      setCategoryOptions(
        getCategoryOptions(coreData.categories?.[0].categories, selectedParent)
      );
    }
  }, [coreData]);

  useEffect(() => {
    if (
      selectedCategories.length === categoryOptions.length &&
      categoryOptions.length > 0
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [selectedCategories, categoryOptions]);

  return (
    <Container>
      <BackButton onClick={() => goBack()}>
        <Icon name="chevron left" style={{ marginTop: -7 }} />
        Back
      </BackButton>
      <T1>Select the report target</T1>
      <Description>
        Please select a target for your report. You'll have the opportunity to
        select additional options in the next step.
      </Description>
      {/* <Option title="Entire Organization" /> */}
      <SelectArea>
        <OptionContainer>
          <Checkbox
            checked={checkAll}
            onChange={handleCheckAll}
            style={{ marginRight: 10 }}
          />
          Check All
        </OptionContainer>
        {categoryOptions.map((category) => {
          return (
            <Option
              checked={selectedCategories.includes(category.id)}
              key={category.id}
              id={category.id}
              title={category.name}
              disabled={checkboxDisabled}
              onSelect={(id) => handleCategorySelected(id)}
            />
          );
        })}
      </SelectArea>
      <ButtonContainer>
        {(lastStep && (
          <Button2
            disabled={selectedCategories?.length === 0}
            onClick={() => createReport()}
          >
            Generate Report
          </Button2>
        )) || (
          <Button
            disabled={selectedCategories?.length === 0}
            onClick={() => {
              if (selectedCategories?.length === 0) {
                return;
              }
              next(selectedCategories);
            }}
          >
            Next
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

const Option = ({ title, onSelect, checked, id }) => {
  return (
    <OptionContainer active={checked}>
      <Checkbox
        checked={checked}
        onChange={() => onSelect(id)}
        style={{ marginRight: 10 }}
      />{" "}
      {title}
    </OptionContainer>
  );
};

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div``;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const SelectArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

const OptionContainer = styled.div`
  width: calc(33% - 5px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 2px;

  background-color: ${({ active }) => (active ? "#D5E2F9" : "#fff")};
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;

const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`;

const Button2 = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
`;

const Button = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-family: "Raleway";
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export default ReportOptions;
