import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  neutralColor,
  unreadColor,
  labelColor,
  inactiveColor,
  defaultText,
  filterColor,
  fontSizes,
  wordExceptionList,
} from "./styleConfig";
import Tabs from "./components/Tabs";
import IdeaBox from "./components/IdeaBox";
import image from "assets/images/stock/recommendations-image.png";
import ConfirmButton from "./components/ConfirmButton";
import { CloseIcon, DownloadIcon } from "./Icons";
import PDF from "./pdf/PDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Popup } from "semantic-ui-react";
import FeedbackSummary from "./feedback_summary";

const PlanModal = ({ open, setOpen, data, title, primary }) => {
  const [resultSentences, setResultSentences] = React.useState([]);
  const [solutionsSentences, setSolutionsSentences] = React.useState([]);
  const [solutionsOne, setSolutionsOne] = React.useState([]);
  //  const [solutionsTwo, setSolutionsTwo] = React.useState([]);
  const [imageText, setImageText] = React.useState();
  const [sentenceOne, setSentenceOne] = React.useState();
  const [sentenceTwo, setSentenceTwo] = React.useState();
  const [ideaOne, setIdeaOne] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [selectedTab, setSelectedTab] = React.useState("Feedback Summary");
  const [ideaTwo, setIdeaTwo] = React.useState();

  useEffect(() => {
    if (data) {
      // Regex to split sentences by periods, but not if the period is preceeded by a word in the wordExceptionList
      // Split sentences by regex
      const sentencesUnformatted = data.ai_result.split("####");

      setResultSentences(sentencesUnformatted);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setLoading(false);
      }, 30);
    }
  }, [open]);

  const getTitle = (item) => {
    let title = item.trim().split("\n")[0];
    return title;
  };

  const getPoints = () => {
    return (
      <ActionItemsContainer>
        <ActionItemsContent>
          <ActionItemsTitle>Action items</ActionItemsTitle>

          {[1, 2, 3].map((item) => {
            let n1 = data.points.indexOf(`${item}.`);
            let n2 = data.points.indexOf(`${item + 1}.`);
            const numberRegex = /\d\.\s/g;

            if (n1 == -1 && n2 == -1) {
              return;
            }
            if (n2 != -1) {
              return (
                <ActionItems>
                  {data.points.substring(n1, n2).replace(numberRegex, "")}
                </ActionItems>
              );
            }
            return (
              <ActionItems>
                {data.points.substring(n1).replace(numberRegex, "")}
              </ActionItems>
            );
          })}
        </ActionItemsContent>
      </ActionItemsContainer>
    );
  };

  return (
    <Container open={open}>
      {open ? (
        <>
          <Title>
            <RiskTitleContainer>
              <TitleContent>
                <TitleWrapper>
                  <ReadReceipt read={true} />
                  <RiskTitle>
                    {title[0]}
                    <LabelContainer>
                      <Tag>{data.primary}</Tag>
                    </LabelContainer>
                  </RiskTitle>
                </TitleWrapper>
              </TitleContent>
              <DateContent>Created {data.date}</DateContent>
            </RiskTitleContainer>
            <Options>
              <Close onClick={() => setOpen()}>{CloseIcon()}</Close>
            </Options>
          </Title>
          <Tabs
            onSelect={(s) => setSelectedTab(s)}
            options={["Feedback Summary", "Recommendations"]}
            selected={selectedTab}
          />
          <Content>
            {selectedTab === "Feedback Summary" ? (
              <FeedbackSummary data={data} />
            ) : (
              <>
                {getPoints()}
                {/* <T2>Recommendation</T2> */}
                <Description>
                  {resultSentences.map((item, i) => {
                    let title = getTitle(item);
                    return (
                      <REC>
                        <Num>
                          {i + 1}. {title}
                        </Num>
                        {item.replace(title, "").trim()}
                      </REC>
                    );
                  })}
                </Description>
              </>
            )}
          </Content>
        </>
      ) : (
        ""
      )}
    </Container>
  );
};

export default PlanModal;

const RiskTitle = styled.div`
  font-weight: 600;
  font-size: ${fontSizes.large};
  margin-bottom: 10px;
  line-height: 25.6px;
  font-family: "Raleway";
  font-style: normal;
`;

const LabelContainer = styled.div`
  padding-left: 10px;
  margin-top: -2px;
  float: right;
`;

const RiskTitleContainer = styled.div`
  margin-bottom: 15px;
`;

const TitleContent = styled.div`
  flex-direction: row;
  display: flex;
`;

const ReadReceipt = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: ${(props) => (props.read ? neutralColor : unreadColor)};
  margin-right: 10px;
  margin-top: 8px;
`;

const DateContent = styled.div`
  font-weight: 400;
  font-size: ${fontSizes.xsmall};
  color: ${inactiveColor};
`;

const Title = styled.div`
  font-family: "Poppins";
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: bold;
  font-size: ${fontSizes.large};
  margin-bottom: 20px;
`;

const TabsContainer = styled.div`
  margin-bottom: 34px;
  margin-top: 40px;
  justify-content: flex-start;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

const Description = styled.div`
  margin-bottom: 20px;
  //  font-family: "Poppins";
  line-height: 1.5;
  font-size: ${fontSizes.medium};
  white-space: pre-line;
  margin-bottom: 20px;
`;

const Container = styled.div`
  padding: 40px 50px;
  position: absolute;
  max-height: calc(100vh - 100px);
  overflow: hidden;
  top: 0;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: ${(props) => (props.open ? "1" : "0")};
  transform: ${(props) =>
    props.open
      ? "opacity 0.3s, visibility 0.3s"
      : "opacity 0.3s, visibility 0.3s"};
  background-color: white;
  padding-right: 100px;
  z-index: ${(props) => (props.open ? "99" : "-1")};
  flex-direction: column;
  transition: all 0.4s linear;
  padding-bottom: 70px;
  font-family: "Raleway";
  width: calc(100vw - 50px);

  @media screen and (min-width: 1420px) {
    width: 100%;
  }
`;

const Close = styled.div`
  font-size: ${fontSizes.small};
  font-weight: 400;
  cursor: pointer;
  margin-left: 20px;
`;

const IconContainer = styled.div`
  color: red;
`;

const Options = styled.div`
  display: flex;
`;

const Content = styled.div`
  max-width: 100%;
  padding-right: 35px;
  overflow-y: scroll;
  margin-bottom: 150px;
  height: calc(100vh - 300px);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const T2 = styled.div`
  font-weight: bold;
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.large};
  margin-bottom: 35px;
`;

const Tag = styled.div`
  justify-content: flex-start;
  background-color: ${labelColor.background};
  color: ${labelColor.text};
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.xsmall};
  border-radius: 5px;
  max-width: fit-content;
`;

const StyledImage = styled.img`
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  margin-bottom: 32px;
  margin-top: 28px;
`;

const ImageText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.small};
  margin-left: 16px;
  color: ${defaultText};
`;

const ImageTextContainer = styled.div`
  padding-right: 128px;
  margin-left: 46px;
  margin-top: 35px;
  border-left: 4px solid ${filterColor.activeBackground};
`;

const ActionItemsContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 40px;
`;

const ActionItemsContent = styled.ul`
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  padding: 32px 64px;
  align-items: flex-start;
  background-color: ${filterColor.background};
  color: ${defaultText};
  list-style-position: outside;
`;

const ActionItemsTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.large};
  margin-bottom: 30px;
`;

const ActionItems = styled.li`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.medium};
  margin-bottom: 16px;
  line-height: 1.6;
`;

const ButtonWrapper = styled.div`
  width: 158px;
  margin-top: 40px;
`;

const Num = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const REC = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
`;