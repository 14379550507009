import React, { useState } from "react";
import styled, { keyframes,css } from "styled-components";
import { Icon } from "semantic-ui-react";


const Container = styled.div`
 background-color: #f8f8f8;
 width:120%;
 margin-left:-10%;
 padding:15px 50px 15px 50px;
 margin-bottom:20px;

 @media (min-width:768px){
 	display:none;
 }

`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`
const H2 = styled.h2`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
`;
const P1 = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 15px;
  line-height:1.5em;
  overflow:hidden;
  max-height:${props=>props.show?'10em':'3em'};
  transition:max-height 0.5s;
`;

const TopBox = styled.div`
  border-radius: 17.5px;
  background-color: #ebebeb;
  padding: 30px 60px 20px 30px;
  width: 49%;

  @media (max-width:768px){
    width:100%;
    padding:20px;
    margin-top:20px;
  }
`;

const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  padding-left: 5px;
  cursor: pointer;

`;

const TraitInfo = (props) =>{

	const [low,setLow] = useState(false)
	const [high,setHigh] = useState(false)

	return (
		<Container>
			<Title>What is {props.trait} ?</Title>

			 <TopBox>
	          <H2>
	            Low {props.trait}{" "}
	            <Icon
	              name="arrow circle down"
	              style={{ color: "#4a4a4a",fontSize:20 }}
	            />
	          </H2>
	          <P1 show={low}>{props.data.low}</P1>
	          <Read onClick={()=>setLow(!low)}>Read more</Read>
	        </TopBox>

	         <TopBox>
	          <H2>
	            High {props.trait}{" "}
	            <Icon
	              name="arrow circle up"
	  
	              style={{ color: "#4a4a4a",fontSize:20}}
	            />
	          </H2>
	          <P1 show={high}>{props.data.high}</P1>
	          <Read onClick={()=>setHigh(!high)}>Read more</Read>
	        </TopBox>
		</Container>
		)
}

export default TraitInfo