import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

import { Progress, Icon,Button } from 'semantic-ui-react'


const MemberCard = (props) => {

  // console.log("i was here")
  return (
    <MemberContainer>
         <RowContainer width={20} mobile={false}>
          <Name> {props.data?.first_name
            ? props.data?.first_name + " " + props.data?.last_name
            : ""}</Name>
        
      </RowContainer>

      <RowContainer width={20} mobile={false}>
      <Text>{props.data?props.data.email:""}</Text>
      </RowContainer>
      <RowContainer width={15} mobile={false}>
      <Text>{props.data?props.data.primary:""}</Text>
      </RowContainer>
      <RowContainer width={15} mobile={false}>
      <Text>{props.data?props.data.secondary:""}</Text>
      </RowContainer>

      <RowContainer width={10} mobile={false}>
        <Text>
             {props.data?.email_status_text}
        </Text>
      </RowContainer>
      <RowContainer width={20} mobile={false}>
          <Button color="violet" size="mini">Remind</Button>
          <Button color="grey" size="mini">Cancel</Button>
      </RowContainer>
    </MemberContainer>
  );
};

export default MemberCard;

const MemberContainer = styled.div`
  width: 100%;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
`;
const Avatar = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 17px;
  border: solid 1px #e9e9e9;
  margin-right: 10px;
  min-width: 34px;
`;

const BlankAvatar = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #4a4a4a;
  margin-right: 10px;
  min-width: 46px;
`;

const Name = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #9b9b9b;
`;
const Email = styled.div`
  font-size: 12px;
`;
const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const Status = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => (props.status < 3 ? "#9b9b9b" : "black")};
`;

const Text = styled.div`
  font-size: 12px;
  font-family: "Red Hat Display", sans-serif;
`;

const RowContainer = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width:768px){
    display:${props=>props.mobile?'flex':'none'};
  }
`;

const MemberDetails = styled.div`
  width:25%;
  display:flex;
  height:100%;
  align-items:center;
  padding:0 0 0 15px;

  @media (max-width:768px){
    width:90%;
    padding:0;
  }

`