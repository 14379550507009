import styled, { keyframes } from 'styled-components';
import React from "react";
import SelectItem from "./SelectItem";


const Component5 = () =>{

    const [selected,setSelected] = React.useState(false)

    return (
        <Container>

            <T1>Which culture need would you like to focus on?</T1>
            <Text delay={0.5}>
            Based on your data, we have identified areas of your culture that can be improved. Select an area to begin setting a plan to address it.
            </Text>

        <SelectArea delay={1}>
            <SelectItem 
                selected={selected==1}
                setSelected={()=>setSelected(1)}
                title="Lack of psychological safety and trust in expressing concerns"
                item="The feedback indicates that some employees feel hesitant to bring up problems or provide candid feedback due to fear of negative consequences or avoidance by leadership. This suggests a need for creating a culture of psychological safety where employees feel comfortable and supported in identifying problems and expressing genuine concerns without fear of retribution or dismissal." />

            <SelectItem 
                selected={selected==2}
                setSelected={()=>setSelected(2)}
                title="Inconsistent communication and feedback channels" 
                item="The feedback also highlights inconsistencies in how feedback is received and addressed by different individuals within the organization. Some employees feel that sharing with colleagues is generally safe, but sharing with higher-level leaders is met with avoidance or anger. This indicates a need for establishing clear and consistent communication and feedback channels throughout the organization, ensuring that all employees, regardless of their position, feel heard and valued when expressing their opinions or concerns." />

           
        </SelectArea>
       

            
        </Container>
    )
}

export default Component5

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`



const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:30px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`


const SelectArea = styled.div`
opacity:0;
animation: ${fadeIn} 1s ease-out forwards;
animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`