import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Team from "./Team";
import Comparison from "./Comparison";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

// Constants
import { SG_GET_EMPLOYEE_RECORD_VALUE } from "constants/actions";

//Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";

import { Data_GeneratorV2 } from "reports/Audit/Testing/generate_data";

const TeamSummary = () => {
  const dispatch = useDispatch();
  const [sub, setSub] = useState(1);
  const [responseData, setData] = useState([]);

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({ surveyType: 6, auditTypeId: 3 });

  const { questionStructure, reportData, surveyComments } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  });

  const { employee_records } = useSelector((state) => ({
    employee_records: state.employee_record_value.employee_record_value,
  }));

  const generate_test_data = () => {
    const DataGenerated = Data_GeneratorV2(
      15,
      2,
      get_survey_structure[0],
      questionStructure,
      employee_records
    );

    setData(get_response_list(DataGenerated));
  };

  useEffect(() => {
    dispatch({
      type: SG_GET_EMPLOYEE_RECORD_VALUE,
      payload: "data_for=2&sort_order=1",
    });
  }, [dispatch]);

  const get_response_list = (data) => {
    return data
      .map((item) => {
        return item.responses.map((r) => r.response);
      })
      .flat();
  };

  useEffect(() => {
    if (reportData?.length > 0) {
      let responsesList = [];
      reportData.forEach((d) => {
        if (d.responses.length > 0) {
          responsesList.push(d.responses);
        }
      });

      if (responsesList.length > 0) {
        setData(...responsesList);
      }
    }
  }, [reportData]);

  return (
    <Container>
      <Top>
        <NavItem
          title="Analytics"
          selected={sub == 1}
          setSelected={() => setSub(1)}
        />
        <NavItem
          title="Comparisons"
          selected={sub == 2}
          setSelected={() => setSub(2)}
        />
      </Top>
      <div onClick={() => generate_test_data()}>Generate Data</div>
      <Content>
        {sub === 1 ? (
          <Team data={responseData} records={employee_records} />
        ) : (
          <Comparison data={responseData} records={employee_records} />
        )}
      </Content>
    </Container>
  );
};

export default TeamSummary;

const NavItem = (props) => {
  return (
    <NavContainer
      selected={props.selected}
      onClick={() => props.setSelected()}
      color={props.highlight}
      id={"nav_" + props.title}
    >
      <Title>{props.title}</Title>
    </NavContainer>
  );
};

const Container = styled.div`
  font-family: "Inter", sans-serif;
  width: 100%;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  padding: 20px 0px 5px 0px;
  // color:{props=>props.selected?'#ab710a':'#9b9b9b'};
  color: #9e9fa1;
  border-bottom: ${(props) => (props.selected ? "2px solid #476DFA" : "")};
  margin-bottom: -1px;

  cursor: pointer;
`;

const Title = styled.div`
  font-size: 12px;
`;

const IconContainer = styled.div`
  font-size: 20px;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  padding-left: 58px;
`;

const Content = styled.div`
  padding-left: 58px;
  width: 100%;
`;
