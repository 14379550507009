export function calculateFactorScores(data, core_data) {
    // Initialize data structures
    const factorResponses = {};
    const factorFeedback = {};
    const benchmarkData = {};
    const factorNames = {};
    const dimensionNames = {};
  
     // Use actual IDs without subtracting 1
  core_data.questions.dimensions.forEach(dimension => {
    const dimensionId = dimension.id -1;
    const dimensionName = dimension.title || `Dimension ${dimensionId}`;
    dimensionNames[dimensionId] = dimensionName;

    dimension.factors.forEach(factor => {
      const factorId = factor.id - 1;
      const factorName = factor.title || `Factor ${factorId}`;

      if (!factorNames[dimensionId]) {
        factorNames[dimensionId] = {};
      }
      factorNames[dimensionId][factorId] = factorName;
    });
  });

  // Process data to collect responses per factor
    data.forEach(response => {
      if (!response.questions) return;
  
      response.questions.forEach(question => {
        const dimensionId = question.id; // dimension id
        const factorIndex = question.factor; // factor index
        const responseValue = question.response;

  
        if (responseValue != null && !isNaN(responseValue)) {
          if (!factorResponses[dimensionId]) {
            factorResponses[dimensionId] = {};
          }
          if (!factorResponses[dimensionId][factorIndex]) {
            factorResponses[dimensionId][factorIndex] = [];
          }
          factorResponses[dimensionId][factorIndex].push(responseValue);
        }
      });

      response.feedback.forEach(feedback => {
        const dimensionId = feedback.dimension;
        const factorIndex = feedback.factor;
        const feedbackValue = feedback.feedback;

        console.log(feedback)

        if (feedbackValue) {
          if (!factorFeedback[dimensionId]) {
            factorFeedback[dimensionId] = {};
          }
          if (!factorFeedback[dimensionId][factorIndex]) {
            factorFeedback[dimensionId][factorIndex] = [];
          }
          factorFeedback[dimensionId][factorIndex].push(feedbackValue);
        }
      });
    });

  
    const pillars = core_data?.standards?.response?.pillars;
    if(pillars){
      pillars?.forEach(item => {
        const dimensionId = item.id;
        const factorIndex = item.factor;
        const benchmarkAverage = item.average;
    
        if (!benchmarkData[dimensionId]) {
          benchmarkData[dimensionId] = {};
        }
        benchmarkData[dimensionId][factorIndex] = benchmarkAverage;
      });
    }
    // Compute average scores and differences
    const outputData = [];
  
    for (const dimensionId in factorResponses) {
      for (const factorIndex in factorResponses[dimensionId]) {
        const responses = factorResponses[dimensionId][factorIndex];
        const sumResponses = responses.reduce((a, b) => a + b, 0);
        const averageScore = sumResponses / responses.length;
        const benchmarkAverage =
          benchmarkData[dimensionId] && benchmarkData[dimensionId][factorIndex];
        const difference = benchmarkAverage != null ? averageScore - benchmarkAverage : null;

        const factorName =
          (factorNames[dimensionId][factorIndex]) ||
          `Factor ${factorIndex}`;
        const dimensionName = dimensionNames[dimensionId] || `Dimension ${dimensionId}`;
  
        const label = `${factorName}`;

        const feedbackLength = factorFeedback?.[dimensionId]?.[factorIndex]?.length || 0;
  
        outputData.push({
          dimensionId: parseInt(dimensionId),
          factorIndex: parseInt(factorIndex),
          factorName: label,
          averageScore: averageScore,
          benchmarkAverage: benchmarkAverage,
          difference: difference,
          feedbackLength
        });
      }
    }
  
    // Prepare data for ApexChart
  const categories = [];
  const series = [
    { name: 'Average Score', data: [] },
    { name: 'Benchmark Average', data: [] },
    { name: 'Difference', data: [] }
  ];

  // Sort output data for consistent ordering
  outputData.sort((a, b) => a.dimensionId - b.dimensionId || a.factorId - b.factorId);

  for (const item of outputData) {
    categories.push(item.factorName);

    // Include IDs in data points
    series[0].data.push({
      x: item.factorName,
      y: item.averageScore,
      factorId: item.factorIndex,
      dimensionId: item.dimensionId,
      feedbackLength: item.feedbackLength
    });

    series[1].data.push({
      x: item.factorName,
      y: item.benchmarkAverage,
      factorId: item.factorIndex,
      dimensionId: item.dimensionId,
      feedbackLength: item.feedbackLength
    });

    series[2].data.push({
      x: item.factorName,
      y: item.difference,
      factorId: item.factorIndex,
      dimensionId: item.dimensionId
    });
  }

  return {
    categories: categories,
    series: series
  };
}