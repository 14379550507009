import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import StarImg from 'assets/images/UI/star.svg'
import Arrow from 'assets/images/UI/arrow_blue.svg'

import SummaryChart from "./SummaryChart";
import factor_content from './factor_content.json';


import Anchor from "../Anchor";
import { calculate_outcome_summary_chart ,calculate_longitudinal_outcome_chart} from './calculations';
import ItemModal from "./ItemModal";

const OutcomeContent = ({factor,core_data,outcomeQ,data,rule,selectedFactor,historical_data,lastData}) =>{
    const [selected,setSelected] = useState(null)
    const [chartData,setChartData] = useState(null)
    const [modalData,setModalData] = useState(null)
    const [open,setOpen] = useState(false)

    const anchor = useSelector(
        (state) => state.audit?.anchor,
        shallowEqual
      );

      useEffect(()=>{
        setChartData(calculate_outcome_summary_chart(data,lastData,anchor,selectedFactor,core_data,outcomeQ,rule))
        if(anchor){
            setModalData(calculate_longitudinal_outcome_chart(historical_data,anchor,selectedFactor,core_data,outcomeQ))

        }
    },[selectedFactor,anchor])

    useEffect(()=>{
        if(selected){
            setOpen(true)
        }
    },[selected])

    const close = () =>{
        setOpen(false)
        setSelected(null)
    }

        

    const getChange = () =>{
        if(chartData?.lastOverallStats.average){
            let change = chartData?.overallStats.average - chartData?.lastOverallStats.average
            return (change*10).toFixed(1)+'%'
        }
        return '--'
    }

    const findContent = () =>{
        let content = selectedFactor.name in factor_content?factor_content[selectedFactor.name]:null

        if(content){
            return (
                <>
                 <p>{content.content.p}</p>
                </>
            )
        }
        return ""

    }

    return (
        <Container>
            <Title>
                {factor?.name}
            </Title>

            <Content>
                <div style={{display:'flex'}}>
                    <ScoreArea>
                        <GreenBlock>
                        <ScoreImg src={StarImg}/>
                        </GreenBlock>

                        <div>
                            <Score>{(chartData?.overallStats.average*10).toFixed(0)}%</Score>
                            <ScoreTitle>Overall Score</ScoreTitle>
                        </div>                  
                    
                    </ScoreArea>


                    <ScoreArea>
                        <BlueBlock>
                        <ScoreImg2 src={Arrow}/>
                        </BlueBlock>

                        <div>
                            <Score>{getChange()}</Score>
                            <ScoreTitle>Change</ScoreTitle>
                        </div>                  
                    
                    </ScoreArea>
                </div>

                <Anchor anchorLabel=" " />
            </Content>

            <Description>
            {findContent()}
            </Description>


            {/* <Tabs>
                <NavContainer 
                    selected={selected==1} 
                    onClick={()=>setSelected(1)}>Summary</NavContainer>
                <NavContainer
                    selected={selected==2} 
                    onClick={()=>setSelected(2)}>Trends</NavContainer>
                <NavContainer
                    selected={selected==3} 
                    onClick={()=>setSelected(3)}>Questions</NavContainer>
            </Tabs> */}


             <SummaryChart 
                                chartData={chartData} 
                                data={data}
                                anchor={anchor}
                                core_data={core_data}
                                setSelected={setSelected}
                                selectedFactor={selectedFactor}/>

            {selected && <ItemModal open={open} setOpen={close} data={modalData} selected={selected}/> }

        </Container>
    )
}

export default OutcomeContent;


const Container = styled.div`

`

const Title = styled.div`
    color:#2A3039;
    font-weight:600;
    font-size:18px;
    margin-bottom:30px;
`

const Content = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`

const ScoreArea = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-right:50px;
`

const ScoreTitle = styled.div`
    color:#666D79;
    font-size:12px;

`

const Score = styled.div`
color:#2A3039;
font-size:14px;
font-weight:600;
`

const ScoreImg = styled.img`
    width:20px;
`

const ScoreImg2 = styled.img`
    width:30px;
`

const GreenBlock = styled.div`
    background-color:#EAFBF7;
    height:40px;
    width:40px;
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
`

const BlueBlock = styled.div`
     background-color:#EBF1FD;
    height:40px;
    width:40px;
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
`

const Description = styled.div`
    margin-top:20px;
    min-height:40px;
`



const NavContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100px;
	padding: 20px 0px 5px 0px;
	color: ${props=>props.selected?'#2D70E2':'#666D79'};
	border-bottom:${props=>props.selected?'2px solid #2D70E2':""};
    font-weight:${props=>props.selected?'600':'400'};
	margin-bottom:-1px;

	cursor: pointer;
`;



const Tabs = styled.div`
	display: flex;
`
