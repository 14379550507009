// Sidebar.js (Updated)
import { string } from 'mathjs';
import React from 'react';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  width: 300px;
  min-width:300px;
  background-color: #FFFFFF; /* Changed to white */
  color: #2A3039;
  padding: 20px;
  border-right: 1px solid #D5E2F9;
  overflow-y: auto;
`;

const SidebarHeader = styled.div`
  color: #2A3039;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const NavItem = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => (props.active ? '#2D70E2' : '#2A3039')};
  padding-left: 10px;
  &:hover {
    color: #2D70E2;
  }
`;

const SubNavItem = styled(NavItem)`
  padding-left: 20px; /* Indent sub-items */
`;

const ChangePercentage = styled.span`
  font-size: 12px;
  color: ${(props) => (props.isPositive ? '#2D70E2' : '#FF0000')};
  margin-left: 5px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #D5E2F9;
  margin: 15px 0;
`;

const getScoreChange = (factor1,factor2) => {
  let factor1Change = factor1.latest_score - factor1.last_score
  let factor2Change = factor2.latest_score - factor2.last_score

  if (typeof factor1.last_score == 'string' || typeof factor2.last_score == 'string') {
    return 0
  }
  
  return factor2Change - factor1Change
}

function Sidebar({ onSelectFactor, onViewChange, cultureFactors, outcomeFactors }) {
  return (
    <SidebarContainer>
      <SidebarHeader>Change Report</SidebarHeader>
      <NavItem onClick={() => onViewChange('summary')}>Summary</NavItem>
      <Divider />
      <SidebarHeader>Culture Factors</SidebarHeader>
      {cultureFactors.sort((a,b)=>getScoreChange(a,b)).map((factor) => {
        let changePercentage = ((factor.latest_score - factor.last_score)*10).toFixed(1)
        return (<SubNavItem 
          key={factor.factor_id} 
          onClick={() => onSelectFactor({ ...factor, type: 'culture' })}>
          {factor.factor_name}
          <ChangePercentage isPositive={changePercentage >= 0}>
            ({changePercentage >= 0 ? '+' : ''}{changePercentage}%)
          </ChangePercentage>
        </SubNavItem>)

})}
      <Divider />
      <SidebarHeader>Outcome Factors</SidebarHeader>
      {outcomeFactors.sort((a,b)=>getScoreChange(a,b)).map((factor) => {

        let changePercentage = typeof factor.last_score != 'string' ? ((factor.latest_score - factor.last_score)*10).toFixed(1) : "--"
        return (<SubNavItem key={factor.outcome_id} onClick={() => onSelectFactor({ ...factor, type: 'outcome' })}>
          {factor.outcome_name}
          <ChangePercentage isPositive={changePercentage >= 0}>
            ({changePercentage >= 0 ? '+' : ''}{changePercentage}%)
          </ChangePercentage>
        </SubNavItem>)
})}
    </SidebarContainer>
  );
}

export default Sidebar;
