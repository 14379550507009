import styled from "styled-components";
import React, { useEffect, useState } from "react";
// import Plot from 'react-plotly.js';

const Values = () => {
    let data_frame = data.map(item=>{
        return {x:item.x,
          y:item.y,
          z:item.z,
          mode: 'markers',
          type: 'scatter3d',
          marker: {color: item.color},
          text:item.text,
          textposition: 'right center',
          hovertemplate:  "<b>%{text}</b><br><br>" +
          "<extra></extra>",
          showlegend: false
        }})

    return (

        <Container>
            {/* <Plot
        data={data_frame}
        layout={ 
            {width: 500,
             height: 500,
             margin:
                {l: 0,
                r: 0,
                b: 0,
                t: 0},
            scene:{
            xaxis:{
                showticklabels:false,
                nticks:0,
                showaxeslabels:false
            }, yaxis:{
                showticklabels:false,
                nticks:0,
                showaxeslabels:false
            }, zaxis:{
                showticklabels:false,
                nticks:0,
                showaxeslabels:false
            }}} }
      /> */}
        </Container>
    )
}

export default Values;


const Container = styled.div`
    width:500px;
    height:500px;
    margin-top: 100px;
    padding-left:58px;
`

// const data =[
//     {'x': [-0.10030263386260271, -0.11229110913588773, -0.06748137876467454, -0.08776974051162097, -0.06679496039999819, -0.10148518731240767, -0.12346838811706276, -0.10023049448636771, -0.07450126038144175, -0.11254850842620989, -0.07618691312038192, -0.08851101904989157, -0.08268355889231602, -0.10620621656864006, -0.05408328317914775, -0.13704190674257857, -0.07320078661677012, -0.11676825488511211, -0.13701343517329567, -0.08969380089056725, -0.10882241272467964, -0.09839642931473105, -0.11652579686725882, -0.10620244886771504, -0.09592213309248787, -0.08767613369868113, -0.0880053981209164, -0.11889761455290111, -0.1006789335088083, -0.09789546619729736, -0.10638790555641808, -0.09105509538927281, -0.09832911037398609, -0.09578832727154084, -0.08481409819445968, -0.05865201361657652, -0.12658182705392124, -0.11863160791476472, -0.06245320474449461, -0.11858206175500384, -0.10153112939178667, -0.10100988631207482, -0.1006789335088083, -0.111887153295246, -0.09592213309248787, -0.11870155882081963, -0.08283422988880602, -0.09953622228350308, -0.08283422988880602, -0.10168529161543947, -0.07395498965458419],
//      'y': [-0.012200326299367374, 0.007988156934867055, -0.04694199030953736, 0.023013540577901573, -0.003238062344939268, -0.2582002407659132, -0.2420706049869197, 0.040573924157562974, -0.10261209367525777, 0.12067326515728848, -0.016096085058240898, 0.10434583606970133, 0.03656165658438809, -0.046403168083775266, -0.06634532178356842, -0.024812942574667388, 0.046790754007140875, 0.009829660321452573, -0.024816927854600053, -0.03970130600239298, -0.020027778220392558, -0.012082115274896877, -0.05810066906669444, -0.0463806893209014, -0.252420744604314, 0.001442342011431555, -0.054171879546781945, 0.017845626245265606, -0.034853023566926426, -0.2522746011151111, -0.1618013241320327, -0.04229598502981753, -0.06360744553722851, 0.09465578782986969, -0.22692933119015404, 0.0448641691673214, -0.008942126117777375, -0.0371908326718666, -0.011422491480665781, -0.03718791902167195, -0.2582538856074018, 0.07169654013013436, -0.034853023566926426, -0.0036954184592767714, -0.252420744604314, -0.17379535772059565, -0.052615147310482016, -0.1858070883270566, -0.052615147310482016, -0.020111988089848973, -0.06611129172539813], 
//     'z': [0.02509390338778358, -0.024126336824467267, 0.07390226792898327, 0.1410202664456738, 0.016301056273187897, 0.15861638047814522, 0.15210761586608784, 0.09062260224849919, 2.5940917191287905e-06, 0.06509689875613091, 0.009271987295524005, 0.05573733778772099, 0.0022947443632707603, 0.22654451319359756, 0.061281172554748624, 0.09456720795686936, 0.015132487092449878, 0.13789082261726726, 0.09458181978371295, 0.04765174783380681, 0.05438903450757935, 0.044577779251364025, 0.15534649491291252, 0.2267518934044499, 0.11788710505798626, 0.08017384854469745, -0.026312306722313546, 0.08476333061809638, 0.06163529792167626, 0.11148081713913341, 0.09026230447430264, 0.053954069331071934, 0.09676941177975679, 0.014203214204169628, 0.07498347290076397, 0.043550051141460024, 0.01284327244992341, 0.13345938601133836, 0.010189009670686483, 0.13356361290262556, 0.15870591291171254, 0.1025217360719454, 0.06163529792167626, 0.005887970021019008, 0.11788710505798626, 0.08441128596133415, 0.0506806522562732, 0.08884826188557039, 0.0506806522562732, 0.11182284000209838, 0.10392846911837064],
//      'text': ['equity', 'adaption', 'Friendly-open-minded', 'DIversity', 'gratitude', 'Collaboration', 'collaboration', 'transparency', 'performance and people doing incredible things together', 'complexity', 'caring', 'excitement', 'Aspiration', 'inclusivity', 'Empathy & excellence.', 'adaptability', 'Passion', 'diversity', 'adaptability', 'inviting', 'acceptance', 'Agile', 'togetherness', 'inclusivity', 'Collaborative', 'Optimism', 'get-it-done', 'ingenuity', 'Innovation', 'collaboratives', 'Teamwork', 'Engagement', 'comradery', 'intensity', 'collaboraters', 'self-indulgence', 'profitability', 'inclusion', 'Respect', 'inclusion', 'Collaboration', 'decisiveness', 'Innovation', 'progressiveness', 'Collaborative', 'teamwork', 'dedication', 'collaboration, with room to improve.', 'dedication', 'approachability', 'support and diversity'], 'color': '#7EEDCC'}, 
//      {'x': [-0.08749496160354207, -0.06282671411528334, -0.07983301192839905, -0.06402763217036535, -0.10193637071138843, -0.07181629895441158, -0.05021134634698405, -0.0839145803360803, -0.05967592144305401, -0.048935835785455975, -0.06941969804565763, -0.021367645197399483, -0.0661292512428348, -0.04140073353980889, -0.02482224361902666, -0.08123519351729667, -0.06402763217036535, -0.07983301192839905, -0.03808355685369139], 
//      'y': [0.13897311340483262, 0.26246785870211853, 0.3156800566710355, 0.3114197286221251, 0.22058495291808605, 0.1325944203372974, 0.1052198932001432, 0.17855122756146333, 0.14876357085177214, 0.025900444510816103, 0.07027622015842262, 0.2640830862321743, 0.28609404358768586, 0.046986776073183086, 0.09314755427684622, 0.0628322613264069, 0.3114197286221251, 0.3156800566710355, 0.1746621339686784], 
//      'z': [0.05063577112163323, 0.0686326995942362, 0.07206717078302319, 0.08837134359536944, 0.09709209857814227, 0.03381439248952126, 0.06161273939700423, 0.12906419311122758, 0.038617790476489, 0.014790400526403541, -0.06384545536025259, 0.07036942825850462, 0.07493375027380332, 0.00846249817669904, 0.02940685669948229, 0.08237156208656833, 0.08837134359536944, 0.07206717078302319, 0.08217356000560673], 
//      'text': ['nervousness', 'controlled chaos.', 'chaos', 'Chaos', 'instability', 'Inconsistent', 'No transparency', 'ambiguity', 'Anxiety', 'experimentation ', 'doing-too-much', 'Chaotic Mess', 'chaos (in both a good and bad way!)', 'silos', 'ambiguous ', 'miscommunication', 'Chaos', 'chaos', 'uncertainty '], 'color': '#2D70E2'}, 
//      {'x': [-0.08607197914360804, -0.09037605078819626, -0.08397821423610358, -0.06618356505692925, -0.0859521370356542, -0.057258977298817716, -0.10010672550769455, -0.0859521370356542, -0.06015560235428568, -0.07657815914952243, -0.070682277880505, -0.08004670248509983, -0.05664439255058185, -0.08607197914360804, -0.08406639841737623, -0.08701859328988314, -0.07813538463326793, -0.06226505605959725, -0.03456561784333466, -0.05691661062100155, -0.05427508243655951, -0.0632490122888705, -0.06791862600977268, -0.04985048776939457, -0.028090529395393367, -0.08966344207052515, -0.04782567995535711, -0.10063523342976806, -0.07709735987217932, -0.05590398236050175, -0.043429850370853375, -0.07985017797661408, -0.08843292916559269, -0.07627106882522518, -0.05602523888051257, -0.10562131303950424, -0.08787967876355933, -0.05691661062100155, -0.08312980479274833, -0.08755712419907354, -0.09157202848025961, -0.09900942896120346, -0.09381823552867917, -0.09820651839107042, -0.07985017797661408, 0.13090934836865525, -0.08701859328988314, -0.061546248945305485], 
//      'y': [0.03703543529582001, 0.0028258185868979026, -0.06171590237709094, -0.027945356261986482, -0.062148118450060914, -0.03527825606806075, -0.053823426522739276, -0.062148118450060914, 0.020671849678925148, -0.024058900346739208, -0.026990287676358426, -0.10051272704420404, 0.006934354074718922, 0.03703543529582001, -0.05255767581413164, 0.03639385912944463, -0.036552066757484764, 0.02561161510880973, 0.0025918225110781344, -0.011857642591372466, -0.06679782746888087, 0.011628760674664267, -0.014714902770857052, -0.04597543211737063, 0.03585564629652194, -0.019505062523083577, 0.08971547242737636, 0.004012554252414827, 0.0004895437558780494, -0.05479207273526868, 0.07654154042413505, -0.04943009449554991, 0.02735282748466739, 0.03775231278050532, 0.0456482821873176, -0.08828313110436725, -0.05564643998107564, -0.011857642591372466, -0.07066047443103513, -0.0318882139757915, -0.06640156757716156, -0.006474979601108046, -0.05026976589072377, 0.014148891385669613, -0.04943009449554991, 0.044285716028651986, 0.03639385912944463, 0.06586640920602407], 
//      'z': [-0.21785994543103054, -0.11526806544644318, -0.1125572776124135, -0.05706705168887662, -0.19297175609974462, -0.11528812218112516, -0.07374231959561403, -0.19297175609974462, -0.03852200119066132, -0.04901913614356927, -0.1649204166538589, -0.024532798221911126, -0.15331785330871447, -0.21785994543103046, -0.18555616660686955, -0.21907228579846996, -0.11348483043489889, -0.006880736082955812, -0.0663508987287854, -0.1436269994296771, -0.09781368111948148, -0.15008181103918966, -0.08963313254773772, -0.08921853660558454, -0.09328813900343934, -0.06353922118944734, -0.06062841927017514, -0.09750473059370945, -0.08105279172400601, -0.038752606833046044, -0.05281775726632394, -0.1712579250372926, -0.03919863736518611, -0.06131236093306616, -0.03500454354105549, -0.057498890584617365, -0.16422017323080648, -0.1436269994296771, -0.05316909828865669, -0.13895495676829805, -0.041018993032807274, -0.14455459150219038, -0.21921325748044404, -0.06585545035135429, -0.1712579250372926, -0.06077045694330851, -0.21907228579846996, -0.05931638054456918], 
//      'text': ['change', 'progression', 'work hard', 'friendly', 'performance', 'family', 'competition', 'performance', 'believers', 'revenue output', 'results', 'high performing, but generally supportive.', 'Better', 'change', 'Progress', 'change', 'Business', 'Nimble', 'Champions', 'people', 'support', 'values', 'respect', 'Work hard & play hard', '.', 'evolving', 'Not sure', 'growing', 'in progress.', 'great people', 'chill', 'learning', 'evolution.', 'drinking', 'various processes.', 'high-performing', 'Performance', 'people', 'professionals', 'progressing', 'competitors', 'growth', 'progress', 'above-average', 'learning', ' ', 'change', 'relaxed'], 'color': '#FEA711'}
//     ]

const data = [{'x': [0.027711165049168218, -0.05885365215181203, -0.049575694937170095, -0.053286066001089344, -0.008425544000314429, 0.027711165049168283], 'y': [0.11944294819844523, 0.050387201711099366, 0.07364500037341697, -0.07305532649447778, 0.04514027507069676, 0.11944294819844523], 'z': [-0.01263697253110529, -0.16578614177106327, -0.033896859638694384, -0.07446324208121687, -0.15602490871209287, -0.012636972531105248], 'text': ['performance', 'striving', 'growth', ' -', 'Aspiration', 'performance'], 'color': '#2D70E2'},
{'x': [0.33988018535342074, 0.3541570994878423, 0.05515324130609067, 0.37287982030448574, 0.28919538022110364], 'y': [-0.0536612114165099, 0.011146847271883633, -0.03101383734874354, -0.01037923501172842, -0.08887398261610163], 'z': [0.02570075236023081, 0.01493353639619436, -0.09405275074045281, 0.03384555239869985, -0.00032289044345138975], 'text': ['Excellence ', 'excellence', 'WINNING! ', 'Excellence', 'excllence '], 'color': '#27CDA7'},
{'x': [-0.058067705153362036, -0.11219537865387696, -0.10230269821717211, -0.07633227835496757, 0.03458263328124026, -0.17704031933707282, -0.0838065172558463, -0.004045625538794246, -0.10048593054958307, -0.15078122207395417, -0.08055445337222385], 'y': [-0.1985638667623686, -0.2511001758220016, -0.07336426565216575, -0.095017024134817, -0.0068200364882824525, 0.01216240313855227, -0.2575747383427094, 0.055940100489775664, -0.19215184012301925, -0.14720224493082829, 0.02265973060847389], 'z': [0.17435466176297237, 0.29235973099305507, -0.026125318142314523, 0.07134805880694506, -0.1151056904883385, -0.16904409326260086, 0.19348845401728296, -0.07500691717113339, -0.15490973936996535, -0.13892741164101338, -0.1172859599030812], 'text': ["I can't answer it with one word, because the are different cultures within this organization. One which is amazing, and one that is unclear. ", "I only recently joined the CSIA team and don't feel I have enough experience to comment on values yet.", ' uncertainty', 'Diverse personalities ', 'Perseverance   ', 'manipulative', 'CSIA terrific , my organization = toxic and incompetent ', 'agility', 'erratic ', 'haphazard ', 'caring'], 'color': '#FEA711'},
{'x': [0.03838359112917812, -0.12249277913236084, -0.05161981283419367, -0.09639052242354242, -0.05701008921710987, -0.09638799197725234], 'y': [0.05119013046879197, -0.023024096255388028, 0.16620069912952917, 0.2932630324837678, 0.1876469987431852, 0.29353356551307885], 'z': [0.011814298703916098, 0.035440327323486655, -0.04402436072986331, 0.19607306946086372, 0.14454788757139275, 0.19634389936245278], 'text': ['Harmony', 'individuals', 'encouragement', 'collaboration', 'teamwork', 'collaboration'], 'color': '#8f1ecc'}]