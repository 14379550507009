import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_ADD_DATA_STORY_LOG } from "constants/actions";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { Modal, Button as SemanticButton } from "semantic-ui-react";

const StoryFeedback = (props) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { id } = useParams();

  const [feedbackReceived, setFeedbackReceived] = useState(false);
  const [feedback, setFeedback] = useState();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const handleComprehensionClick = (showFeedbackModal, comprehension) => {
    if (showFeedbackModal) {
      setShowFeedbackModal(true);
    }

    setFeedbackReceived(true);
    // Handle Dispatch
    dispatch({
      type: SG_ADD_DATA_STORY_LOG,
      payload: {
        data_story_id: id,
        user_action: `Comprehension - ${comprehension}`,
      },
    });
    if (!showFeedbackModal) {
      addToast("Thank you for your feedback!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  const handleFeedbackModalClose = () => {
    setShowFeedbackModal(false);

    // Handle Dispatch
    dispatch({
      type: SG_ADD_DATA_STORY_LOG,
      payload: {
        data_story_id: id,
        user_action: `Feedback - ${feedback}`,
      },
    });

    addToast("Thank you for your feedback!", {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <>
      <FeedbackModal
        showFeedbackModal={showFeedbackModal}
        handleFeedbackModalClose={handleFeedbackModalClose}
        feedback={feedback}
        setFeedback={setFeedback}
      />
      <Container>
        <T1>How well did you understand your culture?</T1>
        <Text delay={0.5}>
          Please selection an option that is most representative of your
          understanding of the infomration presented.
        </Text>
        <ButtonArea>
          <StyledButton
            delay={1.5}
            enabled={!feedbackReceived}
            isSelected={selectedOption === 1}
            onClick={() => {
              if (!feedbackReceived) {
                handleComprehensionClick(false, "I understand my culture");
                setSelectedOption(1);
              }
            }}
          >
            I understand my culture
          </StyledButton>
          <StyledButton
            delay={1.5}
            enabled={!feedbackReceived}
            isSelected={selectedOption === 2}
            onClick={() => {
              if (!feedbackReceived) {
                handleComprehensionClick(true, "Information is missing");
                setSelectedOption(2);
              }
            }}
          >
            Information is missing
          </StyledButton>
          <StyledButton
            delay={1.5}
            enabled={!feedbackReceived}
            isSelected={selectedOption === 3}
            onClick={() => {
              if (!feedbackReceived) {
                handleComprehensionClick(true, "I did not understand");
                setSelectedOption(3);
              }
            }}
          >
            I did not understand
          </StyledButton>
        </ButtonArea>
      </Container>
    </>
  );
};

const FeedbackModal = ({
  showFeedbackModal,
  handleFeedbackModalClose,
  feedback,
  setFeedback,
}) => {
  return (
    <Modal open={showFeedbackModal} onClose={handleFeedbackModalClose}>
      <Modal.Header>
        Please help us understand better by providing some additional feedback
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <textarea
            style={{ width: "100%", height: "100px" }}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleFeedbackModalClose}>Submit</Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  font-family: "Raleway";
`;

const Text = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Button = styled.div`
  color: #2a3039;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 20px;
  font-family: "Raleway";
  margin-top: 20px;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
  border: 1px solid #2a3039;
  padding: 20px;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const StyledButton = styled.div`
  color: ${({ isSelected }) => (isSelected ? "white" : "#2a3039")};
  background-color: ${({ isSelected }) =>
    isSelected ? "#2a3039" : "transparent"};
  font-size: 14px;
  font-weight: 600;
  cursor: ${({ enabled }) => (enabled ? "pointer" : "not-allowed")};
  margin-right: 20px;
  font-family: "Raleway";
  margin-top: 20px;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
  border: 1px solid #2a3039;
  padding: 20px;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay

  &:hover {
    background-color: ${({ enabled }) => (enabled ? "#2a3039" : "transparent")};
    color: ${({ enabled }) => (enabled ? "white" : "#2a3039")};
  }
`;


const ButtonArea = styled.div`
  display: flex;
`;

export default StoryFeedback;
