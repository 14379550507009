import styled, { keyframes } from "styled-components";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import ClickAwayListener from "react-click-away-listener";

const Container = styled.div`
  width: 250px;
  padding: 10px 0 10px 0;
  color: #6a00ff;
  font-size: 20px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  z-index:100;

  @media (max-width:768px){
    width:130px;
    font-size:14px;
  }
`;

const List = styled.div`
  box-shadow: ${(props) =>
    props.visible ? "0 2px 20px 0 rgba(194, 194, 194, 0.5)" : ""};
  border-radius: 8px;
  width: 250px;
  padding: 5px;
  position: absolute;
  top: 40px;
  height: ${(props) => (props.visible ? "auto" : "0")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: height 500ms, opacity 500ms, box-shadow 500ms;
  overflow: hidden;
  background-color: white;
  z-index:10;

  @media (max-width:768px){
    width:150px;
  }
`;

const ListItem = styled.div`
  color: #4a4a4a;
  font-size: 16px;
  border-bottom: ${(props) => (props.last ? "solid 1px #e9e9e9" : "")};
  padding: 8px 8px 5px 5px;
  cursor: pointer;
  height: 25px;
  box-sizing: content-box;

  @media (max-width:768px){
    font-size:14px;
  }
`;

const Check = styled.div`
  position: absolute;
  right: 0px;
  top: ${(props) => props.position};
  left: 87%;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms, top 500ms;
  z-index: 11;
`;

const Expand = styled.div`
  position: absolute;
  right: 0;
  top: 7px;
  transform: ${(props) => (props.visible ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 300ms;

  @media(max-width:768px){
    right:0px;
  }
`;
const Cont = styled.div`
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  display: inline-block;

  @media (max-width:768px){
    padding:5px 5px 5px 5px;
  }
`;
const Text1 = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 0px;
`;

const DropDown = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [position, setPosition] = React.useState(53);
  const [keyPosition, setKeyPosition] = React.useState(0);
  const [selected, setSelect] = React.useState(false);

  const selectEmployee = (employee,i) => {
    let shift = props.type === 1 ? -40 : 0;
    setSelect(employee);
    if(!employee){
      setPosition(53);
    }else{
      setPosition(53 + (i+1) * 40 + shift);
    }
    
    props.selectedEmployee(employee);
  };

  const select2 = () => {
    setTimeout(() => {
      setVisible(!visible);
    }, 400);
  };

  const handleClickAway = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!selected && props.data) {
      if(props.data.some(e=>e.employee==props.default)){
         props.data.map((item,i)=>{
          if(item.employee==props.default){
            selectEmployee(item.employee,i)
          }
      })
       }else{
         setSelect(props.default)
       }
     
    }
  }, [props.default]);


  const getName = () =>{
    let _name =props.data.find(({ employee }) => employee === selected)
    if(_name.name!="None None"){
      return _name.name
    }
    return _name.email.split('@')[0]
  }

  return (
    <Cont>
      {props.data && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div style={{ position: "relative" }}>
            <Check position={position + "px"} visible={visible}>
              <Icon
                name="check circle"
                size={"large"}
                style={{ color: "#6a00ff" }}
              />
            </Check>
            <Container onClick={() => setVisible(visible ? false : true)}>
              {selected
                ? getName()
                : "Your Team"}

              <Expand visible={visible}>
                <Icon name="angle down" size="large" />
              </Expand>
            </Container>
            <List visible={visible} onClick={select2}>
              {props.type === 2 && (
                <ListItem onClick={() => selectEmployee(false)} last={false}>
                  Your Team
                </ListItem>
              )}

              {props.data &&
                props.data.map((p, i) => {
                  let _name = p.name!="None None"? p.name : p.email.split('@')[0]
                  return (
                    <ListItem
                      key={i}
                      onClick={() => {
                        selectEmployee(p.employee,i);
                      }}
                      last={
                        i === Object.keys(props.data).length - 1 ? false : true
                      }
                    >
                      {_name}
                    </ListItem>
                  );
                })}
            </List>
          </div>
        </ClickAwayListener>
      )}
    </Cont>
  );
};

export default DropDown;
