import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from 'semantic-ui-react'


const Archive = () =>{


	return (
		<Container>
			<Left>
				<Title>Culture Pillars</Title>

				<Pillar title={'Communication'} icon={'bullhorn'} number={4} selected={true}/>
				<Pillar title={'Accountability'} icon={'redo'} number={2}/>
				<Pillar title={'Leadership'} icon={'feed'} number={4}/>
				<Pillar title={'Problem Solving'} icon={'broken chain'} number={4}/>
				<Pillar title={'Adaptability'} icon={'dna'} number={4}/>
				<Pillar title={'Drive'} icon={'drivers license'} number={4}/>
				<Pillar title={'Clarity'} icon={'eye'} number={4}/>
			</Left>


			<Content>
				<Plans/>
				<Plans/>
				<Plans/>
			</Content>
		</Container>
		)
}


export default Archive

const Pillar = ({title,icon,number,selected}) =>{

	return (
		<PillarContainer selected={selected}> 
			<div style={{display:'flex',alignItems:'center'}}>
				<Square><Icon name={icon} style={{margin:0,marginTop:-6}}/></Square>
				<T1 selected={selected}>{title}</T1>
			</div>
			<Label color={selected?'#476DFA':'lightblue'}>{number}</Label>

		</PillarContainer>
		)
}

const Plans = () =>{

	return (
		<Plan>
					<LargeSqaure>
						<Icon name={'bullhorn'} style={{margin:0}}/>
						<Bottom>
							Communication
						</Bottom>
					</LargeSqaure>

					<Text>
						<T2>Improving communication in a work environment</T2>
						<Details>
							<T4>Group size:</T4>
							<T5>36</T5>

							<T4>Time frame:</T4>
							<T5>5 months</T5>

							<T4>Execution score:</T4>
							<T5>76%</T5>
						</Details>
						<T3>Bacon ipsum dolor amet shoulder pancetta strip steak ham brisket venison 
						pork jerky boudin shankle hamburger rump kielbasa bacon drumstick. Brisket 
						burgdoggen tongue frankfurter pork shankle pork loin pork chop short ribs.</T3>

						<Tags>
							<Tag>Low complexity</Tag>
							<Tag>High compliance</Tag>
							<Tag>Manager execution</Tag>
						</Tags>
					</Text>
					<Score>
						<Num>+10%</Num>
						<Sub>Effect of implementation</Sub>
					</Score>
				</Plan>
		)
}


const Container = styled.div`
	padding-left:38px;
	margin-top:40px;
	display:flex;
`

const Left = styled.div`
	width:250px;
`

const Title = styled.div`
	margin-bottom:30px;
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
display: flex;
align-items: center;
text-transform: capitalize;
padding-left:30px;
color: #414141;
`

const PillarContainer = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:space-between;
	cursor:pointer;
	background-color:${props=>props.selected?'rgba(71, 109, 250,0.2)':'white'};
	padding:7px 30px;
`

const Square = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
	height:25px;
	width:25px;
	border-radius:4px;
	background-color:#476DFA;
	color:white;
	margin-right:10px;
	font-size:12px;
`

const T1 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: ${props=>props.selected?500:400};
font-size: 14px;
line-height: 18px;

color: ${props=>props.selected?'#476DFA':"#7E7E7E"};
`

const Label = styled.div`
	padding:5px 10px;
	border-radius:3px;
	background-color:${props=>props.color};
	color:white;
`

const Content = styled.div`
width:calc(100% - 330px);
margin-left:30px;
`

const Plan = styled.div`
	width:100%;
	border-radius:10px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	padding:20px;
	display:flex;
	align-items:center;
	margin-bottom:20px;
`

const LargeSqaure = styled.div`
	height:150px;
	width:150px;
	border-radius:20px;
	background-color:#476DFA;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:50px;
	color:white;
	position:relative;
`

const Bottom = styled.div`
	position:absolute;
	bottom:0px;
	width:100%;
	height:30px;
	display:flex;
	justify-content:center;
	align-items:center;
	font-size:12px;
	font-family: 'Poppins';
`

const T2 = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 30px;
display: flex;
align-items: center;
text-transform: capitalize;

color: #414141;
`

const Text = styled.div`
	height:100%;
	width:calc(100% - 320px);
	margin-left:20px;
`

const Score = styled.div`
	width:100px;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	line-height:1;
	margin-left:20px;
`

const Num = styled.div`
	font-size:40px;
`

const Sub = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 10px;
line-height: 16px;
display: flex;
align-items: center;
text-align:center;
color: #7E7E7E;
`
const T3 = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 20px;
margin-top:10px;
color: #9E9FA1;
`

const Details = styled.div`
	display:flex;
	margin-top:5px;
`

const T4 = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 24px;
display: flex;
align-items: center;

color: #7E7E7E;
margin-right:4px;
`

const T5 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
display: flex;
align-items: center;

color: #7E7E7E;
margin-right:20px;
`

const Tags = styled.div`
	display:flex;
	margin-top:20px;
`

const Tag = styled.div`
	padding:3px 10px;
	background-color:#D7DCE6;
	border-radius:3px;
	margin-right:5px;
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #414141;
`