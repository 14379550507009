import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon,Popup} from 'semantic-ui-react'

const Box = ({title,description,buttonText,onClick,IMG,color,active,tooltip}) =>{

    return (
        <Container>
             <Half>
                        <T2>
                            {title} <Popup 
                                    content={tooltip} 
                                    inverted
                                    position='top center'
                                    trigger={<Icon name='info circle' style={{marginLeft:5,cursor:'pointer'}}/>}/>
                        </T2>
                        <Description>
                               {description}
                        </Description>

                        <Button onClick={()=>onClick()} color={color} disabled={active}>{buttonText}</Button>
                    </Half>
                    <Half2>
                        <Image src={IMG}/>
                    </Half2>
        </Container>
    )

}

export default Box

const Container = styled.div`
width:100%;
border:1px solid #E0E0E0;
border-radius:10px;
padding: 20px 20px;
display:flex;
margin-bottom:10px;
`

const TextContainer = styled.div`
    margin-left:20px;
`
const Title = styled.div`
    font-size:14px;
    font-weight:600;
    color#2A3039;
    margin-bottom:5px;
`

const Description = styled.div`
    line-height:1.7;
    max-width:500px;
`


const IconContainer = styled.div`
    position:absolute;
    right:10px;
    color:#E0E0E0;
`


const Half = styled.div`
    width:50%;
    padding-right:30px;
`

const Half2 = styled.div`
    width:50%;
    max-height:175px;
    overflow:hidden;
    border-radius:10px;
    display:flex;
    align-items:center; 
    justify-content:center;
`

const Image = styled.img`
    width:100%;
    
`

const T2 = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
`

const Button = styled.button`
    border:1px solid #2D70E2;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    cursor:pointer;
    width:auto;
    margin-top:20px;
    font-size:14px;
    font-weight:500;
    transition: all 0.3s ease;
    background-color:white;
    opacity:${(props)=>props.disabled?0.3:1};
    pointer-events:${(props)=>props.disabled?'none':null};

    

    &:hover{
        transform: scale(1.05); 
    }
`
