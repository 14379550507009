import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import Side from "./Side";
import Footer from "./Footer";
import { getFeedbackOTP } from "../../utils/cultureUtils";
import Table from "./Table";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

Font.register({
  family: "Barlow",
  src: Barlow,
  format: "truetype",
});

const Recommendations = (props) => {
  const { comments, isFrench } = props;
  const lang = isFrench ? "fr" : "en";

  return (
    <>
      <Page style={styles.page}>
        <Text style={[styles.T2, { color: props.titleColor }]}>
          {getTranslatedText("recommendations", lang, translations)}
        </Text>
        <View style={styles.line} />
        <View wrap={false}>
          <Text style={styles.p}>{comments}</Text>
        </View>
        <Side />
        <Footer isFrench={isFrench} />
      </Page>
    </>
  );
};

export default Recommendations;

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  tableStyle: {
    width: "100%",
    border: "0.5px solid grey",
    marginTop: 20,
  },
  TD: {
    fontSize: 10,
    padding: 4,
  },
  H: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: 3,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
  },
});
