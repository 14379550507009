import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";

const data = {
          
            series: [{
              name: 'Trust',
              data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
                5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
                48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
              ]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                sparkline: {
	            enabled: true
	          },
                toolbar:{show:false}
              },
              grid:{
              	padding:{
              		right:170
              	}
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [{
                      from: -1,
                      to: 100,
                      color: '#6a00ff'
                    },{
                      from: -100,
                      to: -1,
                      color: '#ff2d55'
                    }]
                  },
                  columnWidth: '80%',
                }
              },
              annotations: {
			  yaxis: [
			    {
			      y: 0,
			      borderColor: '#e9e9e9',
			      offsetX:170,
			       strokeDashArray: 0,
			      label: {
			        borderColor: '#00E396',
			        borderWidth:0,
			         offsetX:170,
			        style: {
			          color: '#4a4a4a',
			          fontFamily: 'Red Hat Display, sans-serif',
			          fontSize:14
			        },
			        text: 'Average Team Health 73%'
			      }
			    }
			  ]
			},
              dataLabels: {
                enabled: false,
              },
              yaxis: {
                title: {
                  text: 'Growth',
                },
                labels: {
                  formatter: function (y) {
                    return y.toFixed(0) + "%";
                  }
                }
              },
              xaxis: {
                type: 'datetime',
                categories: [
                  '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
                  '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
                  '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
                  '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
                  '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
                  '2013-07-01', '2013-08-01', '2013-09-01'
                ],
                labels: {
                  rotate: -90
                }
              }
            },
          
          
          };



const RelativeGraph = () =>{


	return (
		<ChartContainer>
				<Chart options={data.options} series={data.series} type="bar" height={200}/>
			</ChartContainer>
		)
}

export default RelativeGraph

const ChartContainer = styled.div`
	margin-top:30px;
`