import styled, { keyframes } from 'styled-components';
import React,{useState} from 'react';



const ScoreBubble = (props) => {

	const calculatePosition = (score) =>{
		if(score==0){
			return 0
		}
		return Math.floor(score/5*100-10)
	}
	
	return (
		<BubbleContainer position={calculatePosition(props.score)} x={props.overlap<0.4 ? props.type==="self score": ''}>
		      	<Bubble>
		      		<Score>{props.score}</Score>
		      		<SubScore>{props.type}</SubScore>
		      	</Bubble>
		      	<Triangle/>
		      	<Line x={props.overlap<0.4 ? props.type==="self score": ''}/>
		      	<Oval x={props.overlap<0.4 ? props.type==="self score": ''}/>
		</BubbleContainer>
	)
}


function catmullRom2bezier(points) {
    var result = [];
    for (var i = 0; i < points.length - 1; i++) {
        var p = [];

        p.push({
            x: points[Math.max(i - 1, 0)].x,
            y: points[Math.max(i - 1, 0)].y
        });
        p.push({
            x: points[i].x,
            y: points[i].y
        });
        p.push({
            x: points[i + 1].x,
            y: points[i + 1].y
        });
        p.push({
            x: points[Math.min(i + 2, points.length - 1)].x,
            y: points[Math.min(i + 2, points.length - 1)].y
        });

        // Catmull-Rom to Cubic Bezier conversion matrix
        //    0       1       0       0
        //  -1/6      1      1/6      0
        //    0      1/6      1     -1/6
        //    0       0       1       0

        var bp = [];
        bp.push({
            x: ((-p[0].x + 6 * p[1].x + p[2].x) / 6),
            y: ((-p[0].y + 6 * p[1].y + p[2].y) / 6)
        });
        bp.push({
            x: ((p[1].x + 6 * p[2].x - p[3].x) / 6),
            y: ((p[1].y + 6 * p[2].y - p[3].y) / 6)
        });
        bp.push({
            x: p[2].x,
            y: p[2].y
        });
        result.push(bp);
    }

    return result;
}

function makePath(points) {
    var result = "M" + points[0].x + "," + points[0].y + " ";
    var catmull = catmullRom2bezier(points);
    for (var i = 0; i < catmull.length; i++) {
        result += "C" + catmull[i][0].x + "," + catmull[i][0].y + " " + catmull[i][1].x + "," + catmull[i][1].y + " " + catmull[i][2].x + "," + catmull[i][2].y + " ";
    }
    return result;
}


const Distribution = (props) =>{
 const {ansDist, ansData} = props
	// # distribution of results
// const dist =[[3,2,6,4,2],[1,3,2,1,2],[0,0,8,5,0],[3,3,1,1,2],[6,6,6,6,6],[0,2,6,4,0],[4,2,5,4,2],[3,2,6,4,2],[0,0,8,5,0]]
// self v/s team 
// const data = [[2,4],[3,4.5],[4,3.4],[5,4.3],[4,4.2],[4,4.7],[3,4.5],[5,2.3],[4,3.1]]

// console.log(ansDist)
// console.log(dist)
// console.log(ansData)
// console.log(data)

	const calculatePosition = (score) =>{
		if(score==0){
			return 0.5
		}
		return Math.floor(score/5*100-10)
	}

	const calculateWidth = () =>{
		let score1 = ansData[props.selected][0]
		let score2 = ansData[props.selected][1]
	if(score1==0){
		score1=0.5
	}
	if(score2==0){
		score2=0.5
	}

		return Math.floor(Math.abs(score1-score2)/5*100)
	}

	const shapeBuilder2 = (graph) =>{
    var points = [];
    let max = Math.max(...graph)

    points.push({x: 0, y: 100});
    for (var i = 0; i < graph.length; i++) {
        points.push({x: (i+1)*20-10, y: 100-graph[i]/max*100});
    }
    points.push({x: 100, y: 100});
    // console.log(points)
    let path = makePath(points)
    path = path + "V "+(100) +"H 0"


    return path
	}

	return (
		<Container>
		 {props.empty?"":
		 <svg width={'100%'} height={'100%'} viewBox={"0 0 100 100"} preserveAspectRatio="none" style={{position:"absolute",left:0,top:0}}>
		 	<path d={shapeBuilder2(ansDist[props.selected])}  fill="rgba(255, 184, 18,0.2)" style={{transition:'0.4s'}}/>
		 </svg>}
			<Num>1</Num>
			<Num>2</Num>
			<Num>3</Num>
			<Num>4</Num>
			<Num>5</Num>

		{props.empty?"":
		<>
		<ScoreBubble score={ansData[props.selected][0]} type={'self score'} overlap={Math.abs(ansData[props.selected][0]-ansData[props.selected][1])}/>
		<ScoreBubble score={ansData[props.selected][1]} type={'team avg'} overlap={Math.abs(ansData[props.selected][0]-ansData[props.selected][1])}/>
		<DiffBar width={calculateWidth()} p1={calculatePosition(ansData[props.selected][0]>ansData[props.selected][1]? ansData[props.selected][1]: ansData[props.selected][0])}/>
		</>}
		
			
		</Container>

		)
}

export default Distribution

const Container = styled.div`
	width:100%;
	height:160px;
	display:flex;
	flex-direction:row;
	justify-content:space-around;
	align-items:flex-end;
	border-bottom:1px solid #979797;
	position:relative;
`

const Num = styled.div`
	font-size: 60px;
  font-weight: bold;
  color: #9b9b9b;
  height:75px;
`

const Oval = styled.div`
	border:2px solid #ffffff;
  background-color: #ffb812;
  width:25px;
  height:25px;
  border-radius:12.5px;
  position:absolute;
  bottom:-12.5px;
`
const BubbleContainer = styled.div`
	display:flex;
	align-items:center;
	flex-direction:column;
	position:absolute;
	top:${props=>props.x? '31px' : '-4px'};
	left:${props=>'calc('+props.position+'% - 33.5px)'};
	transition:left 500ms;
`

const Bubble = styled.div`
	background-color:black;
	height:35px;
	border-radius:20px;
	width:67px;
	color:white;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	z-index:10;
`


const Triangle = styled.div`
	border-left: 5px solid transparent;
  	border-right: 5px solid transparent;
  	border-top: 10px solid black;
  	z-index:3
 `

 const Score = styled.div`
 	font-size: 14px;
 	font-weight: bold;
 	font-family: 'Red Hat Display', sans-serif;
 	margin-bottom:-5px;
 `

const SubScore = styled.div`
	font-size: 8px;
  font-weight: bold;
  text-transform:uppercase;
  font-family: 'Red Hat Display', sans-serif;
`

const Line = styled.div`
	height:${props=>props.x? '85px' : '120px'};
	border-right:2px solid #979797;
	z-index:3;
`

const DiffBar = styled.div`
	height:5px;
	background-color:#ffb812;
	position:absolute;
	bottom:-4px;
	left:${props=>props.p1+'%'};
	width:${props=>props.width+'%'};
	transition:left 500ms, width 500ms;
`
