import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import Chart from "react-apexcharts";
import SideMenu from "./SideMenu";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

const Diversity = () => {
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );

  return (
    <Container>
      <First color={WhiteLabel.highlight}>Representation Analytics</First>
      <SquareContainer>
        <Square width={30}>
          <Title>Representation Score</Title>
          <Description>
            Scores calculated based on company norms in Race,Gender,Orientation
          </Description>
          <ProgressContainer>
            {["Leadership", "Middle Management", "Individual Contributors"].map(
              (item, i) => {
                return (
                  <ScoreComponent score={i} highlight={i == 1} name={item} />
                );
              }
            )}
          </ProgressContainer>
        </Square>

        <Square width={70}>
          <Title>Middle Management Representation Breakdown</Title>
          <Description>
            Audit scores from most recent survey. Aug 6th, 2020
          </Description>
          <Toggles>
            <Toggle>Gender</Toggle>
            <Toggle>Orientation</Toggle>
            <Toggle>Disability</Toggle>
            <Toggle selected={true}>Race</Toggle>
          </Toggles>
          <Value>Trends in Racial Representation</Value>
          <Change color={"green"}>
            <Icon name="arrow up" />
            6%
          </Change>
          <Chart
            options={data3.options}
            series={data3.series}
            type="line"
            height={100}
          />
          <div style={{ marginTop: 30 }} />
          <Value>Racial Distribution</Value>
          <Chart
            options={data2.options}
            series={series1}
            type="bar"
            height={150}
          />
        </Square>
      </SquareContainer>
    </Container>
  );
};

export default Diversity;

const ScoreComponent = (props) => {
  return (
    <ScoreContainer highlight={props.highlight}>
      <div style={{ width: 150 }}>{props.name}</div>
      <Progress
        percent={100 - props.score * 10}
        size="tiny"
        style={{ width: 250, margin: 0, marginLeft: 20 }}
      />

      <Score>{100 - props.score * 10}%</Score>
    </ScoreContainer>
  );
};

const ScoreContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
  background-color: ${(props) => (props.highlight ? "grey" : "white")};
  padding: 5px;
  color: ${(props) => (props.highlight ? "white" : "black")};
  cursor: pointer;

  &:hover {
    background-color: lightgrey;
  }
`;
const ProgressContainer = styled.div`
  margin-top: 30px;
`;

const Change = styled.div`
  display: flex;
  color: ${(props) => props.color};
`;

const Value = styled.div`
  width: 100%;
  display: flex;
  font-size: 18px;
  margin-bottom: 10px;
`;

const Toggles = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Toggle = styled.div`
  padding: 3px 7px;
  background-color: ${(props) => (props.selected ? "lightgrey" : "#f5f5f5")};
  border-radius: 20px;
  margin-right: 10px;
  font-weight: 300;
  cursor: pointer;
  font-size: 12px;
`;

const Score = styled.div`
  margin-left: 30px;
`;

const Container = styled.div`
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  font-family: "Barlow", sans-serif;
`;

const Left = styled.div`
  width: calc(100% - 420px);
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const Description = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Description2 = styled.div`
  margin-bottom: 50px;
`;

const Filters = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;
const Text = styled.div`
  margin-right: 20px;
`;

const Sub = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Data = styled.div`
  margin-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 30px;
`;

const DataTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const DataDescription = styled.div`
  margin-bottom: 30px;
`;

const First = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  color: white;
  border-top: 1px solid black;
  height: 30px;
  align-items: center;
  padding-left: 5px;
  width: 100%;
`;

const SquareContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const Square = styled.div`
  width: ${(props) => props.width + "%"};
  min-height: 350px;
  padding: 20px;
  border: 1px solid lightgrey;
`;

const Options = styled.div`
  width: 200px;
  height: 350px;
  margin-left: 20px;
  padding: 10px;
`;

const data2 = {
  options: {
    chart: {
      type: "bar",
      height: 150,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Racial diversity"],
    },
    fill: {
      opacity: 0.5,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " times";
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: -30,
    },
  },
};
const series1 = [
  { name: "Black", data: [15] },
  {
    name: "Indigenous/First Nation",
    data: [10],
  },
  {
    name: "Hispanic/Latinx",
    data: [15],
  },
  {
    name: "Asian",
    data: [10],
  },
  {
    name: "Multiple Ethinc Groups",
    data: [10],
  },
  {
    name: "Pacific Islander",
    data: [10],
  },
  {
    name: "Other",
    data: [30],
  },
];

const series2 = [
  { name: "Men", data: [15] },
  {
    name: "Women",
    data: [10],
  },
  {
    name: "Other",
    data: [1],
  },
];

const friendOptions = [
  {
    key: "Jenny Hess",
    text: "Jenny Hess",
    value: "Jenny Hess",
  },
  {
    key: "Elliot Fu",
    text: "Elliot Fu",
    value: "Elliot Fu",
  },
  {
    key: "Stevie Feliciano",
    text: "Stevie Feliciano",
    value: "Stevie Feliciano",
  },
  {
    key: "Christian",
    text: "Christian",
    value: "Christian",
  },
  {
    key: "Matt",
    text: "Matt",
    value: "Matt",
  },
  {
    key: "Justen Kitsune",
    text: "Justen Kitsune",
    value: "Justen Kitsune",
  },
];

const data3 = {
  series: [
    {
      name: "Revenue",
      data: [100, 110, 120, 130, 170, 250, 375],
    },
  ],
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#3260a8"],
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },
    stroke: {
      width: [5, 2],
      curve: "smooth",
      dashArray: [0, 8],
    },
    yaxis: {
      show: false,
      title: {
        text: "Culture",
      },
      decimalsInFloat: 0,
    },
  },
};
