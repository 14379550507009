import {
    GET_EMPLOYEE_RECORD,
    DELETE_EMPLOYEE_RECORD,
    FETCH_EMPLOYEE_RECORD,
    UPDATE_EMPLOYEE_RECORD,
    ADD_EMPLOYEE_RECORD,
    CLEAR_EMPLOYEE_RECORD,
    CLEAR_ALL,
} from "constants/actions";

const initialState = {
    employee_record: [],
    pending: false,
    created: false,
};

export default function EmployeeCategories(state = initialState, action) {
    switch (action.type) {
        case GET_EMPLOYEE_RECORD:
            return {
                ...state,
                employee_record: action.payload,
                pending: false,
                created: false,
            };

        case FETCH_EMPLOYEE_RECORD:
            return {
                ...state,
                [action.payload.id]: action.payload,
                pending: false,
                created: false,

            };

        case UPDATE_EMPLOYEE_RECORD:
            if (state.employee_record.filter((an) => an.id === action.payload.id)[0]) {
                state = {
                    ...state,
                    employee_record: state.employee_record.filter(
                        (rw) => rw.id !== action.payload.id
                    ),
                };
            }
            return {
                ...state,
                created: false,
                employee_record: [...state.employee_record, action.payload],
            };

        case DELETE_EMPLOYEE_RECORD:
            // console.log(action.payload)
            return {
                ...state,
                employee_record: state.employee_record.filter(
                    (row) => row.id !== action.payload.id
                ),
            };

        case ADD_EMPLOYEE_RECORD:
            // const newState = state.employee_record
            // newState.push(action.payload)
            return {
                ...state,
                created: action.payload,
                employee_record: [...state.employee_record, action.payload],
            };

        case CLEAR_EMPLOYEE_RECORD:
            return {
                ...state,
                employee_record: [],
                created: false,
            };

        case CLEAR_ALL:
            return {
                ...state,
                employee_record: [],
                created: false,
            };

        default:
            return state;
    }
}