// Libraries
import React from "react";
import {
  Text,
  View,
  StyleSheet,
  Font,
  Svg,
  Defs,
  LinearGradient,
  Stop,
  Page,
  Polygon,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
// import GradientNumbers from "../components/GradientNumbers";

// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";
import PoppinsBold from "assets/fonts/Poppins-Bold.ttf";
import PoppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import PoppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import PoppinsItalic from "assets/fonts/Poppins-LightItalic.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsItalic, fontWeight: 200 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsRegular, fontWeight: 400 },
  ],
  format: "truetype",
});

/**
 * Generates the table of contents for the PDF
 *
 * @returns a Table Of Contents Page
 */
const TableOfContents = (props) => {
  const {
    config,
    numberOfValidCategories,
    introTemplate,
    anchor,
    categories,
    overviewTitles,
  } = props;
  const anchorObject = categories?.categories.find((f) => f.id === anchor);

  // Page adjustments
  const pageAdjustments = {};
  if (introTemplate === 2) {
    pageAdjustments.factorsAdjustment = numberOfValidCategories > 1 ? 1 : 0;

    pageAdjustments.cultureMapAdjustment =
      anchorObject.priority !== "primary"
        ? pageAdjustments.factorsAdjustment - 1
        : pageAdjustments.factorsAdjustment;
  } else {
    pageAdjustments.factorsAdjustment = 0;

    pageAdjustments.cultureMapAdjustment =
      anchorObject.priority !== "primary" ? 0 : 1;
  }

  const getCurrentPage = (currentPage, isTitle) => {
    if (anchorObject.priority === "primary" && isTitle) {
      return currentPage - 1;
    }
    return introTemplate === 2 && numberOfValidCategories === 1
      ? currentPage + 1
      : currentPage;
  };

  // Denotes breakdown start page.
  let currentPage = (introTemplate === 1 && 11) || 10;

  return (
    <Page style={styles.page}>
      <View style={styles.shapes}>
        <Svg height="1000" width="220" style={{ zIndex: 1 }}>
          <Polygon
            points="0,100 150,0 220,0 220,350 0,500 0,100"
            fill={config.tocSideBlock[0]}
          />
          <Polygon
            points="0,510 220,360 220,850 0,1000 0,650"
            fill={config.tocSideBlock[1]}
          />
        </Svg>
        <Svg
          height="200"
          width="300"
          style={{ position: "absolute", bottom: 0 }}
        >
          <Polygon
            points="0,0 300,200 0,200 0,0"
            fill={config.tocSideBlock[2]}
          />
        </Svg>
      </View>

      <View style={styles.textarea}>
        <Text style={styles.title}>Table of Contents</Text>
        <View style={[styles.row, styles.bold]}>
          <Text>Report Methods</Text>
          <Text>3</Text>
        </View>
        <View style={[styles.row, styles.indent]}>
          <Text>Introduction</Text>
          <Text>3</Text>
        </View>
        <View style={[styles.row, styles.indent]}>
          <Text>Methodology</Text>
          <Text>3</Text>
        </View>
        <View style={[styles.row, styles.bold]}>
          <Text>Results & Key Findings</Text>
          <Text>{5 + pageAdjustments.factorsAdjustment}</Text>
        </View>

        <View style={[styles.row, styles.indent]}>
          <Text>{`${overviewTitles[0]}` || `Human Factor`} Overview</Text>
          <Text>{5 + pageAdjustments.factorsAdjustment}</Text>
        </View>
        <View style={[styles.row, styles.indent]}>
          <Text>{`${overviewTitles[1]}` || `System Factor`} Overview</Text>
          <Text>{6 + pageAdjustments.factorsAdjustment}</Text>
        </View>
        <View style={[styles.row, styles.indent]}>
          <Text>Demographic Analysis</Text>
          <Text>{7 + pageAdjustments.factorsAdjustment}</Text>
        </View>
        {introTemplate === 1 && (
          <>
            <View style={[styles.row, styles.indent]}>
              <Text>Key Areas of Growth</Text>
              <Text>{8 + pageAdjustments.factorsAdjustment}</Text>
            </View>

            <View style={[styles.row, styles.indent]}>
              <Text>Key Strengths</Text>
              <Text>{9 + pageAdjustments.factorsAdjustment}</Text>
            </View>
          </>
        )}
        <View style={[styles.row, styles.indent]}>
          <Text>Culture Index Map</Text>
          <Text>
            {(introTemplate === 1 &&
              10 + pageAdjustments.cultureMapAdjustment) ||
              9 + pageAdjustments.cultureMapAdjustment}
          </Text>
        </View>
        <View style={[styles.row, styles.bold]}>
          <Text>{`${overviewTitles[0]}` || `Human Factor`} Analysis</Text>
          <Text>
            {(introTemplate === 1 &&
              currentPage + pageAdjustments.cultureMapAdjustment) ||
              currentPage + pageAdjustments.cultureMapAdjustment}
          </Text>
        </View>
        {/* <View style={[styles.row, styles.indent]}>
          <Text>Introduction</Text>
          <Text>11</Text>
        </View> */}
        {props.subSections[0].map((section, i) => {
          let pageAdjustment = pageAdjustments.cultureMapAdjustment;
          if (introTemplate === 2 && numberOfValidCategories === 1 && i === 0) {
            currentPage += pageAdjustment;
          }

          if (anchorObject.priority === "primary" && i === 0) {
            currentPage += 1;
          }

          const row = (
            <View style={[styles.row, styles.indent]}>
              <Text>{section.title}</Text>
              <Text>{currentPage}</Text>
            </View>
          );

          currentPage = currentPage + section.pages;

          return row;
        })}
        <View style={[styles.row, styles.bold]}>
          <Text>{`${overviewTitles[1]}` || `System Factor`} Analysis</Text>
          <Text>
            {getCurrentPage(currentPage, true) +
              pageAdjustments.cultureMapAdjustment}
          </Text>
        </View>
        {props.subSections[1].map((section, i) => {
          let pageAdjustment = pageAdjustments.cultureMapAdjustment;
          if (introTemplate === 2 && numberOfValidCategories === 1 && i === 0) {
            currentPage += pageAdjustment;
          }
          const row = (
            <View style={[styles.row, styles.indent]}>
              <Text>{section.title}</Text>
              <Text>{getCurrentPage(currentPage)}</Text>
            </View>
          );

          currentPage = currentPage + section.pages;

          return row;
        })}
        {/* {props.showFeedbackSection && (
          <View style={[styles.row, styles.bold]}>
            <Text>Feedback</Text>
            <Text>{currentPage + 1}</Text>
          </View>
        )} */}
        <View style={styles.about}>
          <Text style={{ marginBottom: 5, marginTop: 20 }}>
            {config.aboutCompany}
          </Text>

          <Text>{config.aboutDescription}</Text>
        </View>
      </View>
    </Page>
  );
};

// Create styles
// Create styles
const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "row",
  },
  shapes: {
    height: "100vh",
    position: "relative",
  },
  textarea: {
    paddingHorizontal: 15,
    paddingTop: 80,
    width: 350,
    fontSize: 12,
    height: "100%",
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    marginBottom: 20,
    color: "#000921",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  bold: {
    fontWeight: 700,
    marginBottom: 2,
    marginTop: 5,
  },
  indent: {
    paddingLeft: 20,
    marginBottom: 2,
  },
  about: {
    fontSize: 10,
    color: "grey",
  },
});

TableOfContents.propTypes = {
  subSections: PropTypes.array,
};

export default TableOfContents;
