import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { ActionPlanEllipse, ActionPlanDottedLine } from "./icons";
import Footer from "./Footer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    height: 600,
    padding: 40,
    fontFamily: "Raleway",
    backgroundColor: "#F9F9F9",
  },
  pageTitle: {
    width: "55%",
  },
  pageTitleText: {
    color: "#2A3039",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "120%",
  },
  blueTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "120%",
    color: "#2D70E2",
  },
  planNumber: {
    fontSize: 100,
    fontWeight: 800,
    position: "absolute",
    left: -5,
    top: -38,
    zIndex: -1,
    color: "#EBF1FD",
    fontStyle: "normal",
    lineHeight: "128%",
  },
  planSection: {
    backgroundColor: "#FFF",
    width: "100%",
    marginTop: 40,
  },
  planTitleSection: {
    width: "100%",
    height: 50,
    justifyContent: "center",
    display: "flex",
    paddingLeft: 28,
  },
  planTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "128%",
    color: "#2A3039",
  },
  stepSection: {
    paddingLeft: 65,
    paddingRight: 40,
    marginTop: 20,
  },
  step: {
    marginBottom: 30,
  },
  stepIcon: {},
  stepTitle: {
    marginTop: 8,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "128%",
    color: "#2A3039",
  },
  stepPoints: {
    marginLeft: 5,
  },
  stepContainer: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
  },
  stepPoint: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: "128%",
    marginLeft: 6,
    color: "#2A3039",
  },
  stepBullet: {
    display: "flex",
    justifyContent: "start",
    marginTop: 0.5,
    fontSize: 10,
  },
  elipseTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  elipseContainer: {
    left: -37,
    marginTop: 8,
    zIndex: 2,
  },
  dottedBorder: {
    borderStyle: "dotted",
    borderLeftWidth: 1,
    borderColor: "#D5E2F9",
    borderRadius: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
});

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const ActionPlanTitle = ({ title, planNumber }) => {
  // Regex to make any text with "action plan" in it blue
  // This could probably be turned into a reusable function
  const blueRegex = /action plan/gi;
  const blueIndex = title.search(blueRegex);
  const blueMatch = title.match(blueRegex);
  const titleFirstSection = title.slice(0, blueIndex);
  const titleSecondSection = title.slice(blueIndex).replace(blueRegex, "");

  return (
    <View style={styles.pageTitle}>
      <Text style={styles.pageTitleText}>
        {titleFirstSection}
        <Text style={styles.blueTitle}>{blueMatch}</Text>
        {titleSecondSection}
      </Text>
    </View>
  );
};

// Create Document Component
const ActionPlanPage = (props) => {
  const { title, stepsData } = props;

  return (
    <Page style={styles.page}>
      {title && <ActionPlanTitle title={title} />}
      <View style={styles.planSection}>
        <View style={styles.planTitleSection}>
          <Text style={styles.planNumber}>{stepsData.planNumber}</Text>
          <Text style={styles.planTitle}>{stepsData.planTitle}</Text>
        </View>
        <View style={styles.stepSection}>
          {stepsData.steps.map((step, i) => {
            return (
              <View style={styles.step}>
                <View style={styles.stepIcon}>{step.icon}</View>
                <View style={[styles.elipseTitleContainer]}>
                  {(i < stepsData.steps.length - 1 && (
                    <View style={{ position: "absolute", left: -35, top: 14 }}>
                      <ActionPlanDottedLine
                        height={
                          (step.stepPoints.length > 1 &&
                            step.stepPoints.length * 200) ||
                          320
                        }
                        color="#D5E2F9"
                        width={1}
                      />
                    </View>
                  )) || (
                    <View
                      style={{
                        position: "absolute",
                        left: -35,
                        top: 16,
                        height: 100,
                        width: 5,
                        backgroundColor: "#fff",
                        zIndex: 5,
                      }}
                    >
                      {/* <ActionPlanDottedLine
                        height={step.stepPoints.length * 200}
                        color="#FFF"
                        width={2}
                      /> */}
                    </View>
                  )}
                  <View style={styles.elipseContainer}>
                    <ActionPlanEllipse />
                  </View>
                  <Text style={styles.stepTitle}>{step.stepTitle}</Text>
                </View>
                <View style={styles.stepPoints}>
                  {step.stepPoints.map((point) => {
                    return (
                      <View style={styles.stepContainer}>
                        <Text style={styles.stepBullet}>•</Text>
                        <Text style={styles.stepPoint}> {point}</Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          })}
        </View>
      </View>
      <Footer />
    </Page>
  );
};

export default ActionPlanPage;
