import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Dropdown } from "semantic-ui-react";

const organize_questions = (q) => {
	return q.questions.dimensions
		.map((i, id) => {
			return i.factors.map((x, idx) => {
				return {
					key: x.title,
					text: x.title,
					value: { title: x.title, d: id, f: idx },
				};
			});
		})
		.flat();
};

const organize_questions2 = (q, s) => {
	let questions = [{ key: -1, text: "All questions", value: -1 }];

	questions.push(
		...q.questions.dimensions[s.d].factors[s.f].questions.map((qx, idx) => {
			return {
				key: qx.q,
				text: qx.q,
				value: idx,
			};
		})
	);
	return questions;
};

const Metric = ({
	questions,
	selected,
	setSelected2,
	setSelected,
	selected2,
}) => {
	const [open, setOpen] = useState(false);
	const [options1, setOptions] = useState(organize_questions(questions));
	const [options2, setOptions2] = useState([]);

	useEffect(() => {
		if (selected) {
			setSelected2(null);
			setOptions2(organize_questions2(questions, selected));
		}
	}, [selected]);

	return (
		<Container>
			<Top onClick={() => setOpen(!open)}>
				<Title>Metrics</Title>
				<Description>
					Which metric gaps is this plan in relation to?
				</Description>
			</Top>

			<SelectionContainer>
				<Dropdown
					placeholder="Choose a factor"
					fluid
					selection
					options={options1}
					value={selected}
					onChange={(e, t) => setSelected(t.value)}
					style={{ marginRight: 20 }}
				/>
				<Dropdown
					placeholder="Choose a question"
					fluid
					selection
					value={selected2}
					onChange={(e, t) => setSelected2(t.value)}
					options={options2}
				/>
			</SelectionContainer>
		</Container>
	);
};

export default Metric;

const Container = styled.div`
	width: 100%;
	font-family: "Barlow Semi Condensed", sans-serif;
	border-radius: 3px;
	padding: 15px;
`;

const Top = styled.div`
	display: flex;
`;

const Title = styled.div`
	font-weight: bold;
	margin-right: 5px;
	font-size: 16px;
`;

const Description = styled.div`
	font-size: 13px;
	color: grey;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 10px;
`;

const SelectionContainer = styled.div`
	display: flex;
	margin-top: 10px;
`;

const Selected = styled.div``;

const templateOptions = [
	{
		key: "Strategic Gap Analysis",
		text: "Strategic Gap Analysis",
		value: "Strategic Gap Analysis",
	},
];
