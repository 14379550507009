import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

import Clipboard from "assets/images/clipboard.png";

const ActionCard = ({ setSelected, data }) => {
  return (
    <Container>
      <Box>
        <Img src={Clipboard} />
      </Box>
      <TextBox>
        <Title>
          <div>
            <Pillar>
              {data.metric.title}

              <Area>
                <Label
                  color={data.metric.type == "People" ? "#FF9961" : "#163174"}
                >
                  {data.metric.type}
                </Label>
              </Area>
            </Pillar>
            {data.question}
          </div>

          <Report onClick={() => setSelected()}>View Report ></Report>
        </Title>
      </TextBox>
    </Container>
  );
};

export default ActionCard;

const Container = styled.div`
  width: 900px;
  padding: 20px;
  box-shadow: 0 2px 10px 0 rgba(220, 220, 220, 0.5);
  border-radius: 5px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const Img = styled.img`
  height: 20px;
`;

const Box = styled.div`
  padding: 5px;
  background-color: rgba(45, 80, 226, 0.05);
  border-radius: 5px;
  margin-right: 20px;
`;

const Title = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 300;
  display: flex;
  position: relative;
  width: 100%;
`;

const Text = styled.div`
  font-size: 14px;
  font-color: #4a4a4a;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Area = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 5px;
`;

const Report = styled.div`
  color: #2d50e2;
  cursor: pointer;
  position: absolute;
  right: 10px;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const Label = styled.div`
  background-color: ${(props) => props.color};
  padding: 0px 20px;
  border-radius: 10px;
  font-size: 12px;
  color: white;
  margin-left: 5px;
`;

const Pillar = styled.div`
  display: flex;
  font-weight: bold;
`;
