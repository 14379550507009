import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress } from 'semantic-ui-react'
import Avatar from './Avatar'
import EmotionCard from './Emotions'

const data = {
          
            series: [{
              name: 'series1',
              data: [31, 40, 28, 51, 42, 109, 100]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                 toolbar:{
                	show:false
                }
              },
              grid:{
              	padding:{
              		right:50
              	}
              },
              dataLabels: {
                enabled: false
              },
              annotations:{
              	points: 
					[
					  {
					    x: new Date('2018-09-19T06:30:00.000Z').getTime(),
					    y: 100,
					    marker: {
					      size: 8,
					    },
					    label: {
					      text: '95%',
					      borderWidth:0,
					      style:{
					      	fontSize:24,
					      	fontWeight:'bold'
					      }
					    }
					  }
					]
              },
              stroke: {
                curve: 'smooth',
                colors:['rgba(106, 0, 255,0.3)']
              },
              fill:{
              	colors:['#6a00ff']
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };

const data2 = {
          
            series: [2,2,2,2,2,2],
         
            options: {
              chart: {
                width: 400,
                type: 'donut'
              },
              labels: ['Enjoyment', 'Motivation', 'Curiosity', 'Stress', 'Discontent','Anger'],
              colors:['rgba(8, 194, 81,0.5)','rgba(48, 120, 255,0.5)','rgba(213, 0, 178,0.5)','rgba(255, 184, 18,0.5)','rgba(126, 36, 234,0.5)','rgba(255, 45, 85,0.5)'],

              fill: {
                opacity: 1,
               
              },
              stroke: {
                width: 0,
                colors: undefined
              },
              yaxis: {
                show: false
              },
              legend: {
                position: 'bottom',
                   colors:['#08c251','#3078ff','#ffb812','#d500b2','#7e24ea','#ff2d55'],
              },
              plotOptions: {
                polarArea: {
                  rings: {
                    strokeWidth: 0
                  },
                  spokes: {
                    strokeWidth: 0
                  },
                }
              },
            },
          
          
          };

const NewEQ = () =>{


	return (
		<div>
			<Card>
				<Left>
					<Title>Emotional Health Report</Title>
					<DateText>Report date: March 30th,2021</DateText>
					<Chart options={data.options} series={data.series} type="area" height={350}/>
				</Left>
				<Right>
					<Chart options={data2.options} series={data2.series} type="donut" width={400}/>
				</Right>

			</Card>
			<EmotionCardContainer>
				<EmotionCard name="Curiosity"/>
				<EmotionCard name="Enjoyment"/>
				<EmotionCard name="Motivation"/>
				<EmotionCard name="Stress"/>
				<EmotionCard name="Discontent"/>
				<EmotionCard name="Anger"/>
			</EmotionCardContainer>
		</div>
		)
}


export default NewEQ


const Card = styled.div`
 	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
 	padding:30px 40px;
 	display:flex;

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:25px;
	font-weight:bold;
`
const DateText = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:5px;
	margin-bottom:30px;
	margin-left:3px;
`

const Left = styled.div`
	width:calc(100% - 400px);
`

const Right = styled.div`
	width:400px;
	height:400px;
	display:flex;
	align-items:flex-end;
`

const People = styled.div`
	width:300px;
`

const EmotionCardContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;