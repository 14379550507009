import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown, Label } from "semantic-ui-react";
import Scheduled from "./Schedule";

const ToolCard = ({ name, type, status, last_used }) => {
	const getColor = () => {
		if (status == "Recurring") {
			return "teal";
		}
		if (status == "Scheduled") {
			return "yellow";
		}
		if (status == "Completed") {
			return "green";
		}
		if (status == "New") {
			return "blue";
		}
	};

	const getIcon = () => {
		if (type == "Feedback") {
			return "chat";
		}
		if (type == "Performance") {
			return "clipboard";
		}
		if (type == "Psychology") {
			return "heartbeat";
		}
		if (type == "Physiology") {
			return "heartbeat";
		}
	};

	return (
		<Container>
			<TH width={25}>
				<Square color={getColor()}>
					<Icon
						name={getIcon()}
						style={{
							margin: 0,
						}}
					/>
				</Square>
				<Name>{name}</Name>
			</TH>
			<TH width={15}>
				<Label>{type}</Label>
			</TH>
			<TH width={15}>
				<Label color={getColor()}>{status}</Label>
			</TH>
			<TH width={15}>{last_used}</TH>
			<TH width={30}>
				<Scheduled trigger={<Schedule>Schedule</Schedule>} />
				<Schedule>Results</Schedule>
				<Schedule>Information</Schedule>
			</TH>
		</Container>
	);
};

export default ToolCard;

const Container = styled.div`
	width: 100%;
	border: 1px solid lightgrey;
	padding: 20px;
	display: flex;
	height: 75px;
	align-items: center;
	margin-bottom: 5px;
`;

const TH = styled.div`
	font-size: 14px;
	font-family: "Barlow", sans-serif;
	width: ${(props) => props.width + "%"};
	display: flex;
	align-items: center;
`;

const Name = styled.div`
	font-size: 16px;
	font-weight: 500;
`;

const Schedule = styled.div`
	border-radius: 5px;
	height: 30px;
	color: white;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	padding: 0px 20px;
	color: #2d50e2;
	font-family: "Barlow Semi Condensed", sans-serif;
	margin-right: 5px;
	border: 1px solid #2d50e2;

	@media (min-width: 991px) {
		display: flex;
	}
`;

const Square = styled.div`
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ff5168;
	color: white;
	font-size: 20px;
	margin-right: 10px;
`;
