import Image from 'assets/images/empty/graph.jpg'
import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom"

const Container = styled.div`
	height:100%;
	width:100%;
	display:flex;
	margin-top:100px;
	justify-content:center;


`

const Card = styled.div`
	width: 600px;
  height: 462px;
  border-radius: 8px;
  box-shadow: 0 4px 20px 0 #ececec;
  background-color: #ffffff;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-evenly;

  @media (max-width:768px){
    width:100%;
  }
`
const IMG = styled.img`
 height: 100px;

 @media (max-width:768px){
   height:auto;
   width:100%;
  }
`

const Title = styled.div`
	font-size: 23px;
  font-weight: bold;
  text-align: center;
   font-family: "Red Hat Display", sans-serif;
   margin-bottom:8px;
`

const Text = styled.div`
	font-size: 16px;
  line-height: 1.31;
  text-align: center;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  max-width:472px;
`

const Button = styled.div`
  width: 196px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;

`

const Empty = (props) =>{
	let navigate = useNavigate();
	return (
		<Container>
			<Card>
				<IMG src={Image}/>
				<div>
					<Title>No data yet...</Title>
					<Text>It looks like there isn't any data to display here.{props.cluster?' Cluster data will only be generated if your team is larger than 3 members.':""} Please check back once you've collected some data or added team members.</Text>
				</div>
				<Button onClick={() => navigate(-1)}>Go back</Button>
			</Card>
		</Container>
		)
}

export default Empty