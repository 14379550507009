import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Icon, Confirm } from "semantic-ui-react";

import {
  Button,
  Pagination,
  Dimmer,
  Image,
  Loader,
  Segment,
} from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { saveAs } from "file-saver";

import {
  SG_GET_FILE_UPLOADS,
  SG_DELETE_FILE_UPLOAD,
} from "constants/actions";

import format from "date-fns/format";


const UploadedList = ({
  organizationId,
  setShowReportId,
  fetchAgain,
  setFetchAgain,
  selectedTeam,
  showErrors,
  deletedRecord,
  setDeletedRecord,
  setShowPage,
  AllPageOptions,
}) => {
  const dispatch = useDispatch();
  // const [organizations, setOrganizations] = useState([]);

  const [pageLoading, setPageLoading] = useState(false);
  const [fileUploads, setFileUploads] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const { get_file_uploads } = useSelector(
    (state) => ({
      get_file_uploads: state.fileupload,
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log("updated data", get_file_uploads.results)
    setFileUploads(get_file_uploads?.file_uploads);

    //
  }, [get_file_uploads]);

  // console.log(get_debrief_schedules.activePage)
  // console.log(activePage)

  useEffect(() => {
    let teamquery = "";
    if (selectedTeam?.id) {
      teamquery = `&team=${selectedTeam.id}`;
    }
    if (organizationId) {
      dispatch({
        type: SG_GET_FILE_UPLOADS,
        payload: `file_type=1&organization=${organizationId}${teamquery}`,
      });
    }
  }, [dispatch, organizationId, selectedTeam]);

  // console.log(organizationId, fetchAgain)

  // useEffect(() => {
  //   setPageLoading(true)
  //   const timer1 = setTimeout(() => {
  //     setPageLoading(false)
  //   }, 2000)
  //   return () => clearTimeout(timer1)
  // }, [activePage]);

  useEffect(() => {
    if (fetchAgain || deletedRecord) {
      setPageLoading(true);
    } else {
      const timer1 = setTimeout(() => {
        // if (organizationId) {
        //   dispatch({
        //     type: SG_GET_DEBRIEF_SCHEDULES,
        //     payload: `page=${activePage}&organization=${organizationId}`

        //   });
        // }
        setPageLoading(false);
      }, 2000);
      // const timer2 = setTimeout(() => {
      //     setSecondOpen(true)
      // }, 20);
      return () => clearTimeout(timer1);
      // clearTimeout(timer2)
    }
  }, [fetchAgain, deletedRecord]);

  // console.log(selectedTeam)
  useEffect(() => {
    setPageLoading(true);
    const timer1 = setTimeout(() => {
      if (fetchAgain) {
        setFetchAgain(false);
      }
      if (deletedRecord) {
        setDeletedRecord(false);
      }
      dispatch({
        type: SG_GET_FILE_UPLOADS,
        payload: `file_type=1&organization=${organizationId}`,
      });
      setPageLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [
    dispatch,
    fetchAgain,
    setFetchAgain,
    deletedRecord,
    setDeletedRecord,
    organizationId,
  ]);

  function ViewRecord(id) {
    setShowPage(AllPageOptions[1]);
    setShowReportId(id);
  }

  function clearErrors() {
    if (organizationId) {
      dispatch({
        type: SG_GET_FILE_UPLOADS,
        payload: `file_type=1&organization=${organizationId}`,
      });
    }
    setFetchAgain(false);
    setDeletedRecord(false);
  }

  function DeleteFileUpload() {
    if (deleteId) {
      dispatch({
        type: SG_DELETE_FILE_UPLOAD,
        payload: { id: deleteId },
      });
    }
    setDeletedRecord(deleteId);
    setConfirmDelete(false);
    setShowReportId(false);
    setDeleteId(false);
  }

  function SelectDeleteRow(id) {
    // console.log(id)
    setDeleteId(id);
    setConfirmDelete(true);
  }

  // console.log(deleteId)

  return (
    <>
      {!showErrors && fetchAgain > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Upload File Added!"
          content="You have successfully added a new Upload File!"
        />
      )}

      {!showErrors && deletedRecord > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="Uploaded File Removed!"
          content="You have successfully removed a Uploaded File!"
        />
      )}
      {/* <Dimmer.Dimmable as={Segment} dimmed={pageLoading}> */}
      <Dimmer.Dimmable dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        <Table>
          <TR>
            <TH width={50}>Name</TH>
            <TH width={20}>File</TH>
            <TH width={20}>Status</TH>
            <TH width={20}></TH>
          </TR>

          {fileUploads &&
            fileUploads.map((brief, k) => {
              const saveFile = () => {
                saveAs(brief?.data_file, `${brief?.name}.csv`);
              };
              return (
                <TR key={k}>
                  <TD width={50}>
                    {brief?.name}
                  </TD>
              
                  <TD width={20}>
                    <StyledButtonGrey onClick={saveFile}>
                      <Icon name="download" /> Download
                    </StyledButtonGrey>
                  </TD>
                  <TD width={20}>{brief?.status_text}</TD>
                  <TD width={20}>
                    <>
                      <StyledButton onClick={() => ViewRecord(brief?.id)}>
                        View
                      </StyledButton>
                      <Button icon onClick={() => SelectDeleteRow(brief?.id)}>
                        <Icon name="trash alternate" />
                      </Button>
                      <Confirm
                        open={confirmDelete}
                        onCancel={() => setConfirmDelete(false)}
                        onConfirm={() => DeleteFileUpload(brief?.id)}
                      />
                    </>
                  </TD>
                </TR>
              );
            })}
        </Table>
      </Dimmer.Dimmable>
    </>
  );
};

export default UploadedList;

const Table = styled.div`
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  margin-bottom: 50px;
  font-family: 'Poppins';
`;

const TR = styled.div`
  display: flex;
  padding: 15px 10px;
  width: 100%;
`;
const TH = styled.div`
  width: ${(props) => props.width + "%"};
  border-bottom: 1px solid #9b9b9b;
  padding-bottom: 5px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #414141
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
/* identical to box height */


color: #414141
`;

const T1 = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const SmallImg = styled.img`
  width: 30px;
  margin-right: 10px;
`;

const View = styled.div`
  padding: 5px 35px;
  // background-color:#6a00ff;
  // color:white;
  // border-radius:20px;
  font-weight: bold;
  position: absolute;
  right: 0;
`;

const ViewButton = styled.button`
  padding: 5px 35px;
  background-color: #6a00ff;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  position: absolute;
  // right:0;
  cursor: pointer;
  border: 0;
  box-shadow: none;
`;

const Card = styled.div`
  width: 600px;
  max-width: 100%;
  padding: 30px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Org = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const Pages = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const StyledButtonGrey = styled.button`
  color: white;
  font-size: 14px;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #595959;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #595959;
    color: #595959;
  }
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  display: flex;
  height: 35px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-right: 5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;
