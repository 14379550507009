import React, { useContext } from "react";

import { Message } from "semantic-ui-react";


import { useSelector, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { DomainContext } from 'layouts/LayoutContext';

const Login = () => {
  const [errorMessage, setErrorMessage] = React.useState(false);

  const Domain = useContext(DomainContext);
  
  const { get_error } = useSelector(
    (state) => ({
      get_error: state.errors,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_error?.message) {
      setErrorMessage(get_error?.message);
    }
  }, [get_error]);

  return (
    <Container>
      <Box>
        <Banner>
          <IMG src={Domain.image} />
        </Banner>
        <Title>Check your Email</Title>
        {errorMessage?.header && (
          <Message
            error
            header={errorMessage.header}
            content={errorMessage.message}
          />
        )}
        <P>
          If the email you entered is found in our records we would shortly send
          you an email with password reset instructions
        </P>

        <Bottom>
          <div>
            <Link to="/account/login">Back to login</Link>
          </div>
        </Bottom>
      </Box>
    </Container>
  );
};

export default Login;

const Title = styled.span`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  font-family: "Red Hat Display", sans-serif;
`;

const IMG = styled.img`
  height: 75px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 50px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 50px;
  width: 100%;
`;

const P = styled.div`
  font-family: "Barlow", sans-serif;
  max-width: 500px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Box = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @media (max-width: 768px) {
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 100px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
  }
`;