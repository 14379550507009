import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import MarkdownRenderer from "./markdown";
import { Icon, Dropdown, Popup } from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  SG_FETCH_TASK_SCHEDULE,
  CLEAR_AWS_DOWNLOAD_URL,
  SG_GENERATE_AWS_DOWNLOAD_URL,
} from "constants/actions";
import { downloadIcon } from "../../../../reports/components/Icons";
import LoadingSpinner from "components/LoadingSpinner_v2";

const extractQuestions = (data) => {
  const extractedQuestions = [];

  data.forEach((item, index) => {
    const isAiEnhanced = item.aiEnhanced;
    extractedQuestions.push({
      key: item.id,
      text: isAiEnhanced
        ? `${item.question} **Includes AI Follow Up**`
        : item.question,
      value: index,
    });
  });

  return extractedQuestions;
};

const TaskReport = () => {
  const { id, reportGroupId } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  let navigate = useNavigate();

  const { fetched_scheduled_task, download_url } = useSelector(
    (state) => ({
      download_url: state.download_url,
      fetched_scheduled_task: state.task_templates?.fetched_scheduled_task,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_TASK_SCHEDULE,
        payload: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (download_url?.download_url?.download_link) {
      setDownloadLoading(false);
      window.open(download_url?.download_url?.download_link, "_blank");
    }
  }, [download_url]);

  useEffect(() => {
    if (fetched_scheduled_task) {
      const markdownData =
        fetched_scheduled_task?.task_reports?.[reportGroupId];
      setData(markdownData);
      if (fetched_scheduled_task?.task_template_user_tasks?.user_tasks) {
        const templateQuestions =
          fetched_scheduled_task?.task_template_user_tasks?.user_tasks?.filter(
            (task) => task?.task_type === "question"
          );
        const questions = extractQuestions(templateQuestions);
        setOptions(questions);
      }
    }
  }, [fetched_scheduled_task]);

  const handleSelect = (value) => {
    setSelected(value);
  };

  useEffect(() => {
    // If the data is available, select the first question by default
    if (data && data?.report_data && data?.report_data?.response?.length > 0) {
      setSelected
        ? handleSelect(0)
        : handleSelect(data?.report_data?.response?.[0]?.id);
    }
  }, [data, options]);

  const handleDownload = (fileName) => {
    dispatch({
      type: SG_GENERATE_AWS_DOWNLOAD_URL,
      payload: fileName,
    });

    setDownloadLoading(true);
  };

  return (
    <Container>
      <Content>
        <BackButton onClick={() => navigate(`/leader/audit/manage/task/${id}`)}>
          <Icon name="chevron left" style={{ marginTop: -7 }} />
          Back
        </BackButton>
        <TitleRow>
          <Title>Survey Report</Title>
          <Popup
            trigger={
              <ActionWrapper
                onClick={() => {
                  handleDownload({
                    file_name: data?.report_data?.download_files?.file_name,
                  });
                }}
              >
                {downloadLoading ? (
                  <LoadingSpinner loadingText="Generating your report..." />
                ) : (
                  <>Download PDF {downloadIcon()}</>
                )}
              </ActionWrapper>
            }
            content="Download"
            position="top center"
          />
        </TitleRow>
        <Description>
          Select a question from your custom survey to view the data.
        </Description>
        <Dropdown
          placeholder="Select Question"
          fluid
          search
          selection
          value={selected}
          options={options}
          // Pass the index of the selected question
          onChange={(e, { value }) => {
            handleSelect(value);
          }}
          style={{ marginBottom: 30, lineHeight: 1.6 }}
        />

        <T1>Question highlights</T1>
        {data && !isNaN(selected) && (
          <MarkdownRenderer
            markdown={`${data?.report_data?.response?.[selected]?.summary}\n\n${data?.report_data?.response?.[selected]?.table}`}
            tldr={null}
            quotes={data?.report_data?.response?.[selected]?.table_quotes}
          />
        )}
      </Content>
    </Container>
  );
};

export default TaskReport;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  color: #2d70e2;
  margin: 0 5px;
`;

const Container = styled.div`
  padding: 20px;
  font-family: "Raleway";
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 700px;
`;

const Title = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  color: #2d70e2;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
`;

const T1 = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const BackButton = styled.div`
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  marghin-bottom: 20px;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Description = styled.div``;