import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

const Bar = ({ score, title, companyHigh }) => {
  let width = score[0];
  let scoreEnd = `${score[0]}%`;
  let left = score[1];
  if (companyHigh) {
    width = (score[0] / companyHigh) * 100;
    left = (score[1] / companyHigh) * 100;
    scoreEnd = score[0];
  }

  return (
    <BarContainer>
      <Title>{title}</Title>
      <BarColor>
        <Bar2 left={left} />
        {/* <Bar2 left={score[2]}/> */}
        <Color width={width} />
      </BarColor>
      <Score>{scoreEnd}</Score>
    </BarContainer>
  );
};

export default Bar;

const BarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
`;

const BarColor = styled.div`
  height: 10px;
  background-color: #c0c0c0;
  width: 100%;
  border-radius: 20px;
  position: relative;
`;

const Color = styled.div`
  height: 100%;
  width: ${(props) => props.width + "%"};
  border-radius: 20px 0px 0px 20px;
  background-color: #e66579;
`;

const Title = styled.div`
  width: 200px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Score = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  margin-left: 10px;
  color: #7e7e7e;
`;

const Bar1 = styled.div`
  height: 20px;
  width: 2px;
  border-left: 2px solid #414141;
  position: absolute;
  left: ${(props) => props.left + "%"};
  top: -5px;
`;

const Bar2 = styled.div`
  height: 20px;
  width: 2px;
  border-left: 2px dashed #414141;
  position: absolute;
  left: ${(props) => props.left + "%"};
  top: -5px;
`;
