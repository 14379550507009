import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import ThemeTimeline from './ThemeTimeline'
import { Icon } from 'semantic-ui-react'
import Pic from 'assets/images/themes.png'
import ThemeData from 'data/themes.js'
import Empty from '404/Empty'

const ThemeCard = (props) => {

  const [chartData, setChartData] = useState(series1)

  const getZscore = () =>{
    if(props.z_score>1.5){
      return 'significantly positive'
    }
    if(props.z_score>0.5){
      return 'moderately positive'
    }
    if(props.z_score>0){
      return 'slightly positive'
    }
    if(props.z_score>-0.5){
      return 'slightly negative'
    }
    if(props.z_score>-1.5){
      return 'moderately negative'
    }
    if(props.z_score<=-1.5){
      return 'significantly negative'
    }
  }

 const getSentiment = () =>{
    let neg = props.neg
    let pos = props.pos
    let neu = props.neu

    if(neg>pos && neg>neu){
      return ' and the sentiment related to this theme is predominantly negative. Negative sentiments indicate that comments associated with this theme tend to be more unpleasant or pessimistic. '
    }
    else if(pos>neu && pos>neg){
      return ' and the sentiment related to this theme is predominantly positive. Positive sentiments indicate that comments associated with this theme tend to be more uplifting and optimistic. '
    }

   return ' and the sentiment related to this theme is predominantly neutral. Neutral sentiments indicate that comments associated with this theme tend to be more fact based or lacking in overall sentiment. '
 }

  useEffect(() => {
    setChartData(
      [
        { name: 'Negative', data: [props?.neg] },
        {
          name: 'Neutral',
          data: [props?.neu]
        }, {
          name: 'Positive',
          data: [props.pos]
        }]
    )
  }, [props]);
  return (
    <Theme>

      <div>
        <ThemeTitle>
          <Number>{props.i}</Number>
          {props.theme}</ThemeTitle>
        <div style={{ marginLeft: 55, marginTop: 10 }}>

          <Description>{ThemeData[props?.theme]?.description}.</Description>

          <Description>This theme has had a <strong>{getZscore()} impact</strong> on your scores this week, {getSentiment()}</Description>
          

          <ChartTitle>Theme sentiment</ChartTitle>
          <Chart options={data2.options} series={chartData} type="bar" height={30} />
          <Keywords>
            <KeyTitle>Keywords: </KeyTitle>
            <KeyContainer>
              {props.keys.map(item => <Key color={props.color}>{item}</Key>)}
            </KeyContainer>
          </Keywords>
        </div>
      </div>
    </Theme>
  )
}


const Overview = ({ themeList, themeHistory, ...props }) => {

  console.log(themeList)

  // const [combinedList, setCombinedList] = useState();

  if(!themeList || themeList.length<1){
    return <Empty/>
  }

  return (
    <CardContent>
      <Top>

        <ImageContainer>
          <Image src={ThemeData[themeList[0]?.name]?.icon}/>
        </ImageContainer>


        <div>
          <TopTitle>Top theme this week</TopTitle>
          <Title1>{themeList[0]?.name}</Title1>
          <Description>{ThemeData[themeList[0]?.name]?.description}</Description>
        </div>
      </Top>

      <ContentContainer>
        <Left>
          <Title><Icon name="gem" style={{ fontSize: 16, marginRight: 10 }} />Key Themes</Title>
          {themeList.slice(0,3).map((p, k) => {
            return <ThemeCard 
            key={k} i={k + 1} color={'#7e24ea'} 
            theme={p.name} 
            impact={'High Positive'} 
            z_score={p.z_score}
            keys={p.keywords}
            pos={p.sentiment_pos}
            neg={p.sentiment_neg}
            neu={p.sentiment_neu} />
          })
          }
          
        </Left>
        <ThemeTimeline themeHistory={themeHistory} />
      </ContentContainer>
    </CardContent>
  )
}

export default Overview


const ContentContainer = styled.div`
    display:flex;
  flex-direction:row;
  padding:40px 20px;
  width:100%;

  @media(max-width:768px){
    padding:0px;
  }
`

const Left = styled.div`
  width:60%;

  @media(max-width:768px){
    width:100%;
  }
`


const CardContent = styled.div`
	margin-top:20px;
  padding:20px;
`

const Dated = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	color:#4a4a4a;
	margin-top:5px;
`
const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:20px;
	font-weight:bold;
	width:100%;
  margin-bottom:40px;
`

const Title1 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:20px;
  font-size:26px;
  font-weight:bold;
  width:100%;
`

const KeyTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size:14px;
  margin-top:5px;
  margin-bottom:5px;

`

const Theme = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	border-radius:8px;
	margin-top:10px;
	max-width:800px;
	position:relative;
  margin-bottom:30px;

`

const ThemeTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:20px;
	font-weight:bold;
	display:flex;
  align-items:center;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
  max-width:600px;
  margin-bottom:10px;
`

const Number = styled.div`
	font-size:24px;
	font-weight:bold;
	height:40px;
	width:40px;
	min-width:40px;
	margin-right:10px;
	border-radius:20px;
  background-color:#7e24ea;
  color:white;
	display:flex;
	align-items:center;
	justify-content:center;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  font-weight:bold;
  position:absolute;
  top:15px;
  right:20px;

`

const Impact = styled.div`
  font-size:9px;
  font-weight:900;
  text-transform:uppercase;
  margin-top:-3px;
`

const ChartTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  font-size:16px;
  font-weight:bold;
  margin-top:20px;
  color:#4a4a4a;
  margin-bottom:10px;
`

const Keywords = styled.div`
	font-size:16px;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  margin-bottom:15px;
  margin-top:20px;
`

const Key = styled.div`
  font-size:14px;
  font-family: 'Red Hat Display', sans-serif;
  margin-right:5px;
  margin-left:5px;
  padding:5px;
  background-color:#e9e9e9;
`

const KeyContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
`

const Top = styled.div`
  display:flex;
  margin-bottom:20px;

  @media(max-width:768px){
    flex-direction:column;
  }
`

const ImageContainer = styled.div`
   background-color:#7e24ea;
   padding:20px;
   border-radius:10px;
   margin-right:30px;

   @media(max-width:768px){
    height:45px;
    width:45px;
    margin-bottom:10px;
    padding:0px;
    margin:0px;
    display:flex;
    align-items:center;
    justify-content:center;
   }
`

const Image = styled.img`
  height:100px;

    @media(max-width:768px){
    height:30px;
   }
  
`

const TopTitle = styled.div`
   color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:5px;
`

const data2 = {

  options: {
    chart: {
      type: 'bar',
      height: 30,
      stacked: true,
      stackType: '100%',
      sparkline: {
        enabled: true
      },
    },
    grid: {
      padding: {
        top: 0
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ['Trust scores'],
    },
    fill: {
      opacity: 0.5,
      colors: ['#ff2d55', '#00b0f0', '#08c251']

    },
    tooltip: {
      y: {
        formatter: function (val) {
          return Math.floor(val*100) + "%"
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: -30
    }
  },


}
const series1 = [
  { name: 'Negative', data: [15] },
  {
    name: 'Neutral',
    data: [10]
  }, {
    name: 'Positive',
    data: [75]
  }]


