import React, { useEffect, useState } from "react";
import Template from "../pages/Template";
import TemplateOTP from "../pages/TemplateOTP";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Loader, Icon } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const PDFInstance = (props) => {
  const {
    data,
    filteredData,
    organizationData,
    dummyData,
    reportData,
    nav_state,
    WhiteLabel,
    questionStructure,
    get_survey_structure,
    selectedReport,
    rule,
    URI,
    spiderOptions,
    spiderTwoOptions,
    calculated,
    setPdfGenerating,
    overview,
    isOTP,
    otpView,
    URI_OTP,
    accessSubfactor,
    previous,
    summarizedData,
    sportName,
    downloadIcon,
    reset,
    isFrench,
    structure,
    // dialOne,
  } = props;

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentBlob, setDocumentBlob] = useState();
  const [documentUrl, setDocumentUrl] = useState();
  /**** Helper Functions ****/
  const generatePDF = async () => {
    // This hides the loading spinner which displays *BEFORE* the pdf offially starts generating.
    setPdfGenerating(false);
    setLoading(true);
    if (isOTP) {
      const pdfDoc = await pdf(
        <TemplateOTP
          config={data}
          filterData={filteredData}
          selectedOrg={organizationData.name}
          nav={nav_state}
          WhiteLabel={WhiteLabel}
          selectedReport={selectedReport}
          rule={rule}
          chart={URI}
          chartTwo={URI_OTP}
          spiderOptions={spiderOptions}
          spiderTwoOptions={spiderTwoOptions}
          calculated={calculated}
          anchor={props.anchor}
          {...props}
          structure={questionStructure}
          categories={structure}
          otpView={otpView}
          reportData={dummyData || reportData}
          isFrench={isFrench}
        />
      ).toBlob();

      setDocumentBlob(pdfDoc);
      setDocumentUrl(window.URL.createObjectURL(pdfDoc));
      setLoading(false);
    } else {
      const pdfDoc = await pdf(
        <Template
          config={data}
          filterData={filteredData}
          selectedOrg={organizationData.name}
          nav={nav_state}
          WhiteLabel={WhiteLabel}
          categories={get_survey_structure}
          selectedReport={selectedReport}
          rule={rule}
          chart={URI}
          spiderOptions={spiderOptions}
          spiderTwoOptions={spiderTwoOptions}
          calculated={calculated}
          anchor={props.anchor}
          previous={previous}
          {...props}
          // dialOne={dialOne}
          structure={questionStructure}
          reportData={dummyData || reportData}
          summarizedData={summarizedData}
          sportName={sportName}
        />
      ).toBlob();

      setDocumentBlob(pdfDoc);
      setDocumentUrl(window.URL.createObjectURL(pdfDoc));
      setLoading(false);
    }
  };

  /**** useEffect Hooks ****/
  useEffect(() => {
    generatePDF();
  }, [
    data,
    filteredData,
    organizationData,
    dummyData,
    reportData,
    nav_state,
    WhiteLabel,
    questionStructure,
    get_survey_structure,
    selectedReport,
    rule,
    URI,
    spiderOptions,
    spiderTwoOptions,
    calculated,
  ]);

  useEffect(() => {
    setDocumentBlob(null);
    setDocumentUrl(null);
  }, [reset]);

  useEffect(() => {
    if (documentBlob && !saved && !loading) {
      setSaved(true);
      saveAs(documentBlob, `${data.coverSubtitle}.pdf`);
    }
  }, [documentBlob, loading]);

  /****** Render Functions *****/
  if (loading) {
    return (
      <div
        style={{
          marginLeft: overview ? (downloadIcon ? "-198px" : "-180px") : "-65px",
          marginTop: downloadIcon ? "5px" : "0px",
        }}
      >
        <Loader inline active size="small" />
      </div>
    );
  }

  if (documentUrl && !overview) {
    setTimeout(() => {
      window.close();
    }, 500);

    return (
      <div
        style={{
          height: "25px",
          width: "110px",
          cursor: "pointer",
          marginLeft: "-65px",
        }}
      >
        <a href={documentUrl} download={`${data.coverSubtitle}.pdf`}>
          <Icon
            name="file pdf"
            size="large"
            style={{
              color: "rgb(156,35,21)",
            }}
          />
        </a>
      </div>
    );
  }

  if (documentUrl && overview) {
    setTimeout(() => {
      window.close();
    }, 500);

    return (
      <div
        style={{
          height: "25px",
          width: downloadIcon ? "50px" : "110px",
          cursor: "pointer",
          marginLeft: "-200px",
        }}
      >
        <a href={documentUrl} download={`${data.coverSubtitle}.pdf`}>
          {(downloadIcon && <Button icon="download" />) || (
            <PDFDownloadWrapper>
              <Button>Download Again</Button>
            </PDFDownloadWrapper>
          )}
        </a>
      </div>
    );
  }

  return null;
};

const PDFDownloadWrapper = styled.div`
  width: 150px;
  left: 0;
  top: 0;
`;

export default PDFInstance;
