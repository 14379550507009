const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.
const promptText = "Translate the following English text to French: 'Hello World'";  // Replace with your own prompt.

export default async function make_call(prompt){

        return fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer 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`
                },
                body: JSON.stringify({
                    messages: [{"role":"system","content":"Your single job is to collect more feedback about editing a culture action plan. Your questions should help add additional insight into improving the draft of an existing plan."},{"role":"user","content":prompt}],
                    model:'gpt-3.5-turbo-16k'
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                return data.choices[0].message.content.trim()
            })
            .catch(error => {
                console.error("Error:", error);
                return null
            });
}




export async function make_modifications(prompt){

    return fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            },
            body: JSON.stringify({
                messages: [{"role":"system","content":"You are a machine that only returns and replies with valid, iterable RFC8259 compliant JSON in your responses. You must replace all text tag in the template with proper solution information. Your responses should always start with ["},{"role":"user","content":prompt}],
                model:'gpt-3.5-turbo-16k'
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.choices[0].message.content.trim()
        })
        .catch(error => {
            console.error("Error:", error);
            return null
        });
}

export async function generate_milestones(prompt){

    return fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            },
            body: JSON.stringify({
                messages: [{"role":"system","content":"You are a machine that only returns and replies with valid, iterable RFC8259 compliant JSON in your responses. You must replace all text tag in the template with proper solution information. Your responses should always start with ["},{"role":"user","content":prompt}],
                model:'gpt-3.5-turbo-16k'
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.choices[0].message.content.trim()
        })
        .catch(error => {
            console.error("Error:", error);
            return null
        });
}


export async function generate_behaviors(prompt){

    return fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            },
            body: JSON.stringify({
                messages: [{"role":"system","content":"You are a machine that only returns and replies with valid, iterable RFC8259 compliant JSON in your responses. You must replace all text tag in the template with proper solution information. Your responses should always start with ["},{"role":"user","content":prompt}],
                model:'gpt-3.5-turbo-16k'
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.choices[0].message.content.trim()
        })
        .catch(error => {
            console.error("Error:", error);
            return null
        });
}



export async function make_modifications2(prompt){

    return fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            },
            body: JSON.stringify({
                messages: [{"role":"system","content":"You are a machine that only returns and replies with valid, iterable RFC8259 compliant JSON in your responses. You must replace all text tag in the template with proper solution information. Your responses should always start with ["},{"role":"user","content":prompt}],
                model:'gpt-3.5-turbo'
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.choices[0].message.content.trim()
        })
        .catch(error => {
            console.error("Error:", error);
            return null
        });
}

export async function make_modifications3(history,prompt){

    let messages = [{"role":"system","content":"You are a machine that only returns and replies with valid, iterable RFC8259 compliant JSON in your responses. You must replace all text tag in the template with proper solution information. Your responses should always start with ["}]
    messages.push(...history)
    messages.push({"role":"user","content":prompt})

    return fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`
            },
            body: JSON.stringify({
                messages: messages,
                model:'gpt-3.5-turbo-16k'
            })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.choices[0].message.content.trim()
        })
        .catch(error => {
            console.error("Error:", error);
            return null
        });
}