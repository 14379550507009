import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Icon } from "semantic-ui-react";
import styled, { keyframes } from "styled-components";
import PercentileBubble from "./Bubble";
import TeamBreakDown from "./teamBreakdown";
import User from 'assets/images/empty/user.svg'
import TeamAvatar from 'assets/images/team.svg'
import { Link } from "react-router-dom";


const loading=keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`


const Trait = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: 42px;

  @media(max-width:768px){
   flex-direction:column;
   overflow:hidden;
  }
`;

const Definition = styled.div`
  width: 40%;
  padding: 40px 40px 40px 40px;

  @media(max-width:768px){
    width:100%;
    padding:20px;
  }
`;

const Percentile = styled.div`
  width: 60%;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin: 7px 8px 7px 0px;
  color: #4f00bf;
  padding: 50px 20px 80px 36px;

   @media(max-width:768px){
    width:100%;
    padding:20px;
    padding-bottom:80px;
  }
`;

const PercentileLoading = styled.div`
  width: 60%;
  border-radius: 8px;
   color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
  margin: 7px 8px 7px 0px;
  color: #4f00bf;
  padding: 50px 20px 80px 36px;
  height:350px;

   @media(max-width:768px){
    width:100%;
    padding:20px;
    padding-bottom:80px;
  }
`;

const Highlight = styled.span`
  color: #4f00bf;
`;

const Paragraph = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 30px;

  @media(max-width:768px){
    margin-bottom:10px;
  }
`;
const Paragraph2 = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #9b9b9b;
`;

const Bar = styled.div`
  height: 12px;
  border-radius: 8px;
  background-color: #e9e9e9;
  width: 100%;
  position: relative;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #545454;
  display: flex;
  align-items: center;
  margin-top: 100px;
`;

const Avatar = styled.img`
  height: 55px;
  width: 55px;
  border: solid 4px #ffffff;
  border-radius: 30px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 3;
  transition: left 0.5s;
`;

const Team = styled.div`
  height: 50px;
  width: 50px;
  background-color: #6a00ff;
  border-radius: 25px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 2;
`;

const Empty = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 30px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 3;
  transition: left 0.5s;
`;

const BlueBar = styled.div`
  width: ${(props) => props.width + "%"};
  height: 12px;
  background-color: #6a00ff;
  left: ${(props) => props.position + "%"};
  position: absolute;
  transition: width 0.5s;
`;
const Text7 = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
`;
const Text8 = styled.h1`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: black;
`;

const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  padding-left: 5px;
  cursor: pointer;

  @media (max-width:768px){
    display:none;
  }
`;

const MobileLink = styled.div`
  background-color: #6a00ff;
  height: 65px;
  display:flex;
  align-items:center;
  justify-content:center;

@media (min-width:768px){
  display:none;
}
`

const ReadMobile = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  padding-left: 5px;
  cursor: pointer;

 
`;

const DefPicture =
  "https://storage.googleapis.com/charactercode-a247a.appspot.com/logos/1/11/pic1596039683.png";

const RelativeTeam = (p1, p2, trait, trait_data) => {
  let diff = Math.abs(p1 - p2);
  let positive = p1 > p2;
  let word = "";

  if(diff < 1){
    return "the same in "+ trait_data[trait].less
  }

  if (diff < 20) {
    word += "slightly";
  } else if (diff < 40) {
    word += "moderately";
  } else {
    word += "significantly";
  }

  if (positive) {
    word += " higher in ";
  } else {
    word += " lower in ";
  }

  return word + " " + trait_data[trait].less;
};

const getPercentile = (s) => {
  let mod = s % 10;
  if (mod === 0 || mod > 3) {
    return s + "th";
  } else if (mod === 1) {
    return s + "st";
  } else if (mod === 2) {
    return s + "nd";
  } else if (mod === 3) {
    return s + "rd";
  }
};

const TraitCard = (props) => {
  const { personalityData } = props;
  const [traitData, setTraitData] = React.useState(false);
  const [employee1, setEmployee1] = React.useState([]);
  const [employee2, setEmployee2] = React.useState([]);
  const [score1, setScore1] = React.useState([]);
  const [score2, setScore2] = React.useState([]);
  const { id1, id2, profiles, keyword, average, employee } = props;


  useEffect(() => {
    if (id1) {
      setEmployee1(profiles.find(({ employee }) => employee === id1));
      // console.log(profiles.find(({ employee }) => employee === id1))
    }

    if (id2) {
      setEmployee2(profiles.find(({ employee }) => employee === id2));
    } else {
      setEmployee2({ name: "Team Avg", pic: null });
    }
  }, [profiles, id1, id2]);

  const { get_trait_data } = useSelector(
    (state) => ({
      get_trait_data: state.personality.trait_data,
    }),
    shallowEqual
  );
  useEffect(() => {
    setTraitData(get_trait_data);
  }, [get_trait_data]);

  useEffect(() => {
    if (employee1) {
      setScore1(Math.floor(employee1[keyword]));
    }
  }, [employee1, keyword]);

  useEffect(() => {
    if (employee2 && id2) {
      setScore2(Math.floor(employee2[keyword]));
    } else {
      setScore2(average);
    }
  }, [employee2, average, keyword,id2]);

  // console.log(employee1)
  // console.log(employee2)

  const getMagnitude=()=>{
     return 'big'
   }

   const getEmployeeName =(id) =>{
     if(personalityData){
        let _data = personalityData.filter(f => f.employee === id)[0]
      if(_data.name!=="None None"){
        return _data.name
      }
      return _data.email.split("@")[0]
     }
     return ""
   }

   const getName = (emp) =>{
    if(emp.name!="None None"){
      return emp.name
    }
    return emp.email.split("@")[0]
   }

  const getPosition = () => {
    let width = Math.abs(score1 - score2);
    let screen = window.innerWidth 
    let diff = screen>768? 8: 20


    if (width < diff) {
      return (
        <React.Fragment>
          {employee1.pic? 
            <Avatar src={employee1.pic} position={score1} />:
              <Empty src={User} position={score1} />
            }
          {employee2?.pic ? <Avatar src={employee2.pic} position={score2} />
          : id2?
             <Empty src={User} position={score2} />
            :<Empty src={TeamAvatar} position={score2} />}
          <PercentileBubble
            p1={score1}
            p2={score2}
            style={"double"}
            t1={getName(employee1)}
            t2={getName(employee2)}
            position={
              score1 > score2
                ? score2 + (score1 - score2 - 2) / 2
                : score1 + (score2 - score1 - 2) / 2
            }
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {employee1.pic? 
            <Avatar src={employee1.pic} position={score1} />:
               <Empty src={User} position={score1} />
            }
          
          {employee2?.pic ? (
            <Avatar src={employee2?.pic} position={score2} />
          ) : id2?
             <Empty src={User} position={score2} />
            :(
            <Empty src={TeamAvatar} position={score2} />
          )}
          <BlueBar
            width={width ? width : ""}
            position={score2 > score1 ? score1 : score2}
          />
          <PercentileBubble position={score1} text={getName(employee1)} />
          <PercentileBubble position={score2} text={getName(employee2)} />
        </React.Fragment>
      );
    }
  };

  if(props.loading){
    return (
       <Trait>
          <Definition>
          </Definition>
           <PercentileLoading>
            </PercentileLoading>

        </Trait>
      )
  }

  return (
    <Trait>
      <Definition>
        <Text7>{props.type}</Text7>
        {traitData[props.type] ? (
          <Paragraph>{traitData[props.type]["definition"]}</Paragraph>
        ) : (
          ""
        )}
        {props.link ? (
          <TeamBreakDown
            traitData={get_trait_data}
            userID={props.userID}
            personalityData={personalityData}
            type={props.type}
             keyword={keyword}
            trigger={
              <Read>
                See {props.type} {props.link ? "team breakdown" : "Report"} >
              </Read>
            }
          />
        ) : (
          <Read onClick={() => props.setSelectedTrait(props.trait)}>
            See {props.type} {props.link ? "team breakdown" : "Report"} <Icon name="chevron right"/>
          </Read>
        )}
      </Definition>

      <Percentile>
        <Text8>
          {employee==id1? 'You are':getEmployeeName(id1)+' is'} in the{" "}
          <Highlight>{getPercentile(score1)} Percentile</Highlight>
        </Text8>
        {traitData[props.type] ? (
          <Paragraph2>
            {employee==id1? 'You are':getEmployeeName(id1)+' is'} {traitData[props.type].verb} than&nbsp;
            {score1 - 1 > 0 ? score1 - 1 : 0} out of 100 people and {employee==id1? 'you are':getEmployeeName(id1)+' is'}
            &nbsp;{RelativeTeam(score1, score2, props.type, traitData)} than {id2? getEmployeeName(id2) : "the average person on your team" }
          </Paragraph2>
        ) : (
          ""
        )}

        <Bar>
          <span style={{ position: "absolute", left: 0, top: 30 }}>0</span>
          <span style={{ position: "absolute", left: 0, top: 50 }}>Low</span>
          <span style={{ position: "absolute", right: 0, top: 30 }}>100</span>
          <span style={{ position: "absolute", right: 0, top: 50 }}>High</span>
          {getPosition()}
        </Bar>
      </Percentile>

      <MobileLink>
        {props.link ? (
          <TeamBreakDown
            traitData={get_trait_data}
            userID={props.userID}
            personalityData={personalityData}
            type={props.type}
             keyword={keyword}
            trigger={
              <ReadMobile>
                See {props.type} {props.link ? "team breakdown" : "Report"} >
              </ReadMobile>
            }
          />
        ) : (
          <ReadMobile onClick={() => props.setSelectedTrait(props.trait)}>
            See {props.type} {props.link ? "team breakdown" : "Report"} <Icon name="chevron right"/>
          </ReadMobile>
        )}
      </MobileLink>
    </Trait>
  );
};

export default TraitCard;
