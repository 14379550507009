import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import InnerLogicLogo from "assets/images/logo_blue_main.png";
import Logo from "assets/images/UI/Ilogo.svg";
import { Modal, Icon, Popup } from "semantic-ui-react";
import { avatarIcon, AiIcon } from "../AI/Icons";

const Query = ({query}) => {
  const inputRef = useRef(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackActions, setShowFeedbackActions] = useState(false);
  const [positiveFeedbackGiven, setPositiveFeedbackGiven] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");

 

  return (
    <Container>
      <Container>
        <Circle>
          {AiIcon()}
        </Circle>
        <TextContainer>
          <MessageContainer>
            <Message>
              <Description>
                {query}
              </Description>
            </Message>
          </MessageContainer>
        </TextContainer>
      </Container>
    </Container>
  );
};

export default Query;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;

  max-width: 800px;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 80px;

  width: 50vw;
`;

const FeedbackActions = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: flex-end;
  padding-right: 10px;
`;

const FeedbackInput = styled.textarea`
  margin-top: 25px;
  flex-grow: 1;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #999;
  border-radius: 8px;
  color: black;
  padding: 10px;
  outline: none;
  resize: none;
  overflow: auto;
  max-height: 300px; /* Height for 4 lines */
  line-height: 20px; /* Adjust the line-height to match the desired initial height */
  font-size: 14px; /* Adjust the font-size according to your design */
  width: 100%;
  font-family: "Raleway";
  min-height: 150px;

  &::placeholder {
    color: #999;
  }
`;

const IconWrapper = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const QuestionContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  max-width: 66%;
  margin-left: auto;
  margin-right: 5px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const SubmitConfirm = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d70e2;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Message = styled.div`
  align-self: center;
`;

const ErrorCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f15062;
  position: absolute;
  bottom: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
`;

const MessageContainer = styled.div`
  background: #f8faff;
  border-radius: 8px;
  padding: 16px;
  margin-right: 10px;
`;

const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.error ? "#F15062" : "#2A3039")};
  white-space: pre-wrap;
`;

const Image = styled.img`
  height: 25px;
`;

const Header = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
  justify-content: center;
`;
