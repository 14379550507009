import { ErrorBoundary } from "react-error-boundary";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

function ButtonErrorWrapper({ error, resetErrorBoundary }) {
  const safety = () => {
    resetErrorBoundary();
  };

  return (
    <Container>
      <ErrorText onClick={() => safety()}>
        <IconWrapper>
          <Icon name="redo" size="small" color="white" />
        </IconWrapper>
        <div>Try again</div>
      </ErrorText>
    </Container>
  );
}

const ErrorWrapper = (props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ButtonErrorWrapper}
      resetErrorBoundary={() => props.retryFunction}
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default ErrorWrapper;

const Container = styled.div`
  position: absolute;
  left: -25px;
  top: 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 45px;
  justify-content: center;
`;

const IconWrapper = styled.div`
  padding-right: 14;
`;

const ErrorText = styled.div`
  width: 150px;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  color: #6a00ff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background-color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
