import styled, { keyframes } from "styled-components";
import React from "react";
import { Checkbox,Select,Label } from 'semantic-ui-react'

const Row = ({edit,categories,selection}) =>{

	const getCatOptions = (o) =>{
		return o.options.map(item=>{
			return {value:item,text:item.name}
		})
	}

	const getCategories = () =>{


			return <Select options={getCatOptions(selection)}/>

	}

	return (
		<Container>
			
			<TD width={edit?3:0}>
				<Checkbox />
			</TD>
			<TD width={15}>Michael Bawol</TD>


				<TD width={75}>
					<Label size={'mini'} as='a'>
				      Halifax
				    </Label>
				    <Label size={'mini'} as='a'>
				      Senior Executive
				    </Label>
					<Label size={'mini'} as='a'>
				      $70,000-90,000
				    </Label>

				    <Label size={'mini'} as='a'>
				       London
				    </Label>

				    <Label size={'mini'} as='a'>
				      1 day
				    </Label>

				</TD>
				<TD2 width={20}>{selection?getCategories():'...'}</TD2>
			



			
		</Container>
		)
}


export default Row


const Container = styled.div`
	width:100%;
	display:flex;
	  width: 100%;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #e9e9e9;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  box-shadow:${props=>props.selected?'1px 2px 9px rgba(0,0,0,0.1)':''};
  transition:box-shadow 0.5s;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	padding: 0 0 0 15px;
	transition:width 0.5s;
	overflow:hidden;
`

const TD2 = styled.div`
	width:${props=>props.width+'%'};
	padding: 0 0 0 15px;
	transition:width 0.5s;
`