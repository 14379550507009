import styled from "styled-components";
import React from "react";
import CompleteAvatar from "./Avatar";
import LeftAvatar from "components/LeftAvatar";

const Panel = ({ user, view, setView, userCount }) => {
// console.log(user)
  return (
    <div style={{position:'absolute',top:0,left:0,display:'flex',flexDirection:'row',overflow:'hidden',height:'100vh',width:'100vw'}}>
      <Container view={view} onClick={() => setView(false)} />
      <SidePanel view={view}>
        <View onClick={() => setView(false)}>X Close Panel</View>

        <Title>Your Leadership Panel</Title>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 17,
            borderBottom: "1px solid #e9e9e9",
            paddingBottom: 20,
          }}
        >
          <NumCircle>{userCount[0]}</NumCircle>
          <Q>✓</Q>

          <AnswerText>answered survey</AnswerText>

          <UnNumCircle>{userCount[1]}</UnNumCircle>
          <Q>?</Q>
          <AnswerText>unanswered survey</AnswerText>
        </div>

        {user ? (
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
          >
            {user.map((item, i) => {
              return (
                <LeftAvatar
                  key={i}
                  employee_id={item.employee}
				  checked={item.completed}
                  
                />
              );
            })}
          </div>
        ) : (
          ""
        )}
      </SidePanel>
    </div>
  );
};

export default Panel;

const Container = styled.div`
  height:100%;
  min-height:100%;
  width:100%;
  background-color:black;
  opacity:${props=>props.view ? 0.2 : 0};
  position:absolute;
  top:0;
  left:0;
  z-index:${props=>props.view ? 100 : 0};
  transition:opacity 1s;

`

const SidePanel = styled.div`
  position:absolute;
  right:${props=>props.view ? 0 : '-450px'};
  width:450px;
  height:100%;
  background-color:white;
  padding:20px;
  z-index:101;
  transition:right 1s;
  overflow-y:scroll;
  padding-bottom:100px;

  @media (max-width:768px){
  right:${props=>props.view ? 0 : '-100%'};
  width:100%;
  margin-top:70px;
  overflow-y:scroll;
  padding-bottom:100px;
  }
`
const View = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 35px;
`;

const TeamText = styled.div`
  font-size: 23px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-right: 20px;
`;

const NumCircle = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #1e2172;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

const UnNumCircle = styled.div`
  border: solid 1px #1e2172;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: #1e2172;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-left: 20px;
`;

const AnswerText = styled.div`
  font-size: 10px;
  font-weight: 900;
  color: #1e2172;
  font-family: "Red Hat Display", sans-serif;
  text-transform: uppercase;
`;

const Q = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-family: "Red Hat Display", sans-serif;
  color: #1e2172;
  margin: 0px 5px 0px 5px;
`;
