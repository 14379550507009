import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import structure from "../structure/survey_structure.json";
import {
  Icon,
  Progress,
  Label,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import Chart from "react-apexcharts";
import Filter from "../Analytics/Filter";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner";
import Empty from "../Empty";

import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
} from "constants/actions";

const Diversity = () => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState();
  const [sub, setSub] = useState();
  const [selected, setSelected] = useState(null);
  const chart = useRef(data3.options);
  const data = useRef(series1);
  const [loading, setLoading] = useState(true);
  const [recentResponses, setRecentResponses] = useState();
  const [organizationId, setOrganizationId] = useState();

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  const structureOptions = (structure) => {
    return structure[0].categories
      .filter((f) => f.priority.toLowerCase() === "tertiary")
      .map((item, i) => {
        return {
          key: i,
          text: item.name,
          value: item.id,
        };
      });
  };

  const {
    auth,
    surveyQuestions,
    errors,
    surveyStructure,
    selectedOrg,
    ediResponses,
  } = useSelector(
    (state) => ({
      errors: state.errors,
      auth: state.auth,
      surveyQuestions: state.surveyquestions.survey_questions,
      surveyStructure: state.surveystructure.survey_structure,
      selectedOrg: state.selectedOrg,
      ediResponses: state.debrief_schedule.culture_audit_reports,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (surveyStructure[0] && !options) {
      setOptions(structureOptions(surveyStructure));
    }
  }, [surveyStructure]);

  useEffect(() => {
    if (Number(ambassadorId) > 0 && selectedOrg) {
      setOrganizationId(selectedOrg.organization?.id);
    } else {
      setOrganizationId(Number(auth.organization_id));
    }
  }, [selectedOrg, ambassadorId, auth]);

  useEffect(() => {
    if (options && !sub) {
      setSub(options[0].value);
    }
  }, [options, sub]);

  useEffect(() => {
    if (
      !recentResponses &&
      ediResponses.response &&
      ediResponses.response.length > 0
    ) {
      let responseList = [];

      const dataSet = ediResponses.response
        .filter((responseList) => responseList.responses.length > 0)
        .sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.date) - new Date(a.date);
        });

      dataSet.forEach((responseSet) => {
        const exists = responseList.findIndex(
          (response) => response.name === responseSet.name
        );
        if (exists === -1) {
          responseList.push(responseSet);
        }
        if (responseList.length === 0) {
          responseList.push(responseSet);
        }
      });
      setRecentResponses(responseList);
    }
  }, [ediResponses, recentResponses]);

  useEffect(() => {
    if (organizationId && !recentResponses) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=7&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: "audit-type-id=4",
      });
    }
  }, [dispatch, organizationId]);

  const getGroupObj = (group) => {
    const tertiaryGroup = surveyStructure[0].categories.find(
      (questionGroup) => {
        return questionGroup.priority === "primary";
      }
    );

    const groupResponseId = tertiaryGroup.answers.find(
      (answer) => answer.name === group
    )?.id;
    return groupResponseId;
  };

  const getGroupStats = (group, secondary) => {
    const dataSet = recentResponses;
    const subValue = sub || surveyStructure[0].categories[0].id;
    const primaryFilter =
      selected !== null
        ? surveyStructure[0].categories
            .find((f) => f.priority === "secondary")
            .options.find((option) => option.name === group)
        : surveyStructure[0].categories
            .find((f) => f.priority === "primary")
            .options.find((option) => option.name === group);
    const secondaryFilter = surveyStructure[0].categories
      .find((category) => category.id === subValue)
      .options.find((option) => option.name === secondary.name);

    let groupCount = 0;

    if (dataSet.length > 0) {
      dataSet.forEach((surveySet) => {
        if (surveySet.responses.length > 0) {
          surveySet.responses.forEach((responseGroup) => {
            const filter = selected ? "secondary" : "primary";
            const isPrimaryGroup =
              responseGroup.response.categories.filter(
                (category) => category.priority.toLowerCase() === filter
              )[0].response === primaryFilter.id;

            const isSecondaryGroup =
              responseGroup.response.categories.filter(
                (category) => category.id === subValue
              )[0].response === secondaryFilter.id;
            let isSelectedGroup = true;

            if (selected) {
              isSelectedGroup =
                responseGroup.response.categories.filter(
                  (category) => category.priority.toLowerCase() === "primary"
                )[0].response === selected;
            }

            if (isSecondaryGroup && isPrimaryGroup && isSelectedGroup) {
              groupCount = groupCount + 1;
            }
          });
        }
      });

      return groupCount;
    }
    return groupCount;
  };

  useEffect(() => {
    setLoading(true);
    if (
      surveyStructure &&
      surveyStructure[0] &&
      recentResponses &&
      recentResponses[0]
    ) {
      if (selected === null) {
        chart.current.xaxis.categories = surveyStructure[0].categories
          .filter(
            (category) => category.priority.toLowerCase() === "primary"
          )[0]
          .options.map((item, i) => item.name);
      } else {
        chart.current.xaxis.categories = surveyStructure[0].categories
          .filter(
            (category) => category.priority.toLowerCase() === "secondary"
          )[0]
          .options.map((item, i) => item.name);
      }

      const subValue = sub || surveyStructure[0].categories[0].id;
      data.current = surveyStructure[0].categories
        .find((f) => f.id === subValue)
        .options.map((secondaryItem, i) => {
          return {
            name: secondaryItem.name,
            data: chart.current.xaxis.categories.map((item, i) => {
              return getGroupStats(item, secondaryItem, i);
            }),
          };
        });

      setTimeout(() => setLoading(false), 300);
    }
    setTimeout(function () {
      setLoading(false);
    }, 1000);
  }, [sub, selected, surveyStructure, recentResponses]);

  useEffect(() => {
    if (selected === "") {
      setSelected(null);
    }
  }, [selected]);

  if (
    (!loading && ediResponses.response.length === 0) ||
    ediResponses.response?.[0]?.responses.length === 0
  ) {
    return <Empty />;
  }

  if (loading || !options || !surveyStructure) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <ContentContainer>
        <Left>
          <Filters>
            <T3>
              Diversity Analysis by
              <Dropdown
                inline
                options={options}
                defaultValue={options[0].value}
                value={sub}
                onChange={(e, { value }) => setSub(value)}
                style={{ marginLeft: 5 }}
              />
            </T3>
            <div
              style={{
                width: 200,
                marginLeft: 30,
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <Filter
                structure={surveyStructure}
                selected={selected}
                ignoreString
                setSelected={(v) => setSelected(v)}
              />
            </div>
          </Filters>
          {loading ? (
            ""
          ) : (
            <Chart
              options={chart.current}
              series={data.current}
              type="bar"
              height={600}
            />
          )}
        </Left>
        {/* <Right>
          <Title>Diversity by role</Title>
          <Level>High</Level>
          <Description>
            Tri-tip ribeye sirloin andouille. Short ribs porchetta turkey
            turducken beef, drumstick frankfurter filet mignon. Shoulder pork
            chop picanha spare ribs sirloin salami chuck kielbasa tongue short
            loin. Short loin pork alcatra, venison kielbasa burgdoggen cow
            shankle.
          </Description>

          <Title>Area of Greatest Growth</Title>
          <Level>Middle Management</Level>
          <Description>
            Tri-tip ribeye sirloin andouille. Short ribs porchetta turkey
            turducken beef, drumstick frankfurter filet mignon. Shoulder pork
            chop picanha spare ribs sirloin salami chuck kielbasa tongue short
            loin. Short loin pork alcatra, venison kielbasa burgdoggen cow
            shankle.
          </Description>

          <Title>Opportunity for Improvement</Title>
          <Level>Black Representation in Leadership</Level>
          <Description>
            Tri-tip ribeye sirloin andouille. Short ribs porchetta turkey
            turducken beef, drumstick frankfurter filet mignon. Shoulder pork
            chop picanha spare ribs sirloin salami chuck kielbasa tongue short
            loin. Short loin pork alcatra, venison kielbasa burgdoggen cow
            shankle.
          </Description>
        </Right> */}
      </ContentContainer>
    </Container>
  );
};

export default Diversity;

const Container = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
`;

const T3 = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-weight: 300;
`;

const Left = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  padding: 40px;
`;

const Description = styled.div`
  margin-bottom: 20px;
  font-family: "Barlow", sans-serif;
`;

const Level = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  line-height: 1;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
  margin-left: 0px;
`;

const Right = styled.div`
  width: calc(30% - 20px);
  padding: 50px 20px;
  margin-top: 50px;
`;

const ChartContainer = styled.div`
  margin-top: -30px;
`;

const Filters = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 50px;
  margin-bottom: 30px;
`;

const H3 = styled.div`
  font-size: 22px;
  font-weight: bold;
`;

const Change = styled.span`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
  color: #ab710a;
  margin-left: 3px;
`;

const Card = styled.div`
  padding: 30px 10px;
  position: relative;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
`;

const Values = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Stat = styled.div`
  font-size: 36px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: bold;
`;

const TextArea = styled.div`
  width: 100%;
  padding: 10px;
`;
const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const data = {
  series: [86],
  options: {
    chart: {
      height: 250,
      type: "radialBar",
    },
    grid: {
      padding: {
        right: 0,
        top: 0,
        left: 0,
        bottom: 0,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "65%",
        },
        track: {
          background: "white",
        },
        dataLabels: {
          show: false,
          name: { show: false },
          value: { show: false },
        },
      },
    },
    fill: {
      colors: ["#ab710a"],
    },
  },
};

const data3 = {
  options: {
    chart: {
      type: "bar",
      height: 100,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Racial diversity and thoughts", "2nd"],
      labels: {
        show: false,
      },
    },
    fill: {
      opacity: 0.5,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetX: -30,
    },
  },
};
const series1 = [
  { name: "Black", data: [15, 10] },
  {
    name: "Indigenous/First Nation",
    data: [10, 15],
  },
  {
    name: "Hispanic/Latinx",
    data: [15, 20],
  },
  {
    name: "Asian",
    data: [10, 30],
  },
  {
    name: "Multiple Ethinc Groups",
    data: [10, 10],
  },
  {
    name: "Pacific Islander",
    data: [10, 10],
  },
  {
    name: "Other",
    data: [30, 20],
  },
];
