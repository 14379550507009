import React,{useState} from 'react';
import styled from 'styled-components';
import {Table} from 'semantic-ui-react'



const ImportData = ({columns}) =>{

    return (

        <Container>
            <Table>
                <Table.Header>
                    <Table.Row>
                    {columns.map((column, index) => (
                       <Table.HeaderCell>{column}</Table.HeaderCell>
                    ))}
                    
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                   
                </Table.Body>
            </Table>
        </Container>
    )

}

export default ImportData

const Container = styled.div`

`

