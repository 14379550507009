import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useToasts } from "react-toast-notifications";

import { Icon, Pagination, Progress } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "./Table";
import data from "./data";
import AddUser from "./AddUser";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_MANAGER_USERS, CLEAR_MANAGER_ERRORS } from "constants/actions";

const steps = ["Access", "Planned", "Check-ins", "Review"];

const Accountability = () => {
  const navigate = useNavigate();
  let { myParam } = useParams();
  const [users, setUsers] = useState([]);
  const [sortDir, setSortDir] = useState("desc");
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(myParam === "success" ? true : false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reloadPage, setReloadPage] = useState(false);
  const [managers, setManagers] = useState([]);
  const [displayedManagers, setDisplayedManagers] = useState([]);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const pageSize = 10;

  function changedPage(e, { activePage }) {
    setActivePage(activePage);
  }

  const { user_manager, get_employee_org_category, manager_categories } =
    useSelector(
      (state) => ({
        user_manager: state.user_manager,
        get_auth: state.auth,
        get_employee_org_category:
          state.employee_category?.employee_org_category,
        get_error: state.errors,
        get_employee_permission: state.employee_permission,
        addedResult: state.user_manager.results,
        manager_categories: state.employee_category?.manager_categories,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (users.length === 0) {
      setUsers(data.sort((a, b) => b.progress - a.progress));
    }
  }, []);

  useEffect(() => {
    if (user_manager?.managerUsers) {
      setManagers(user_manager?.managerUsers);
    }
  }, [user_manager]);

  useEffect(() => {
    if (managers?.results?.length > 0) {
      setDisplayedManagers(managers?.results);
      setTotalPages(Math.ceil(managers?.count / pageSize));
    }
  }, [managers, activePage]);

  const handleErrorMessaging = (addResult) => {
    const { categories, permissions, logins, errors } = addResult;

    // Determine messageLevel
    let messageLevel = "Success";
    if (
      categories.created.length === 0 &&
      permissions.created.length === 0 &&
      categories.updated.length === 0 &&
      permissions.updated.length === 0
    ) {
      messageLevel = "Error";
    } else if (
      categories.failed.length > 0 ||
      permissions.failed.length > 0 ||
      errors.length > 0
    ) {
      messageLevel = "Warning";
    }

    // Prepare errors array
    const parsedErrors = [];

    if (categories.failed.length > 0) {
      parsedErrors.push({
        errorType: "categories",
        failed: categories.failed,
        message: "Failed to add categories to user(s)",
      });
    }

    if (permissions.failed.length > 0) {
      parsedErrors.push({
        errorType: "permissions",
        failed: permissions.failed,
        message: "Failed to add permissions to user(s)",
      });
    }

    const loginErrors = [];
    for (const id in logins) {
      if (logins[id].error) {
        loginErrors.push(parseInt(id));
      }
    }
    if (loginErrors.length > 0) {
      parsedErrors.push({
        errorType: "Login",
        failed: loginErrors,
        message: "Failed to add login to user(s)",
      });
    }

    errors.forEach((error) => {
      parsedErrors.push({
        errorType: "API",
        message: error,
      });
    });

    // Return the structured error object
    return {
      messageLevel,
      errors: parsedErrors,
    };
  };

  useEffect(() => {
    if (user_manager?.results) {
      const { messageLevel, errors } = handleErrorMessaging(
        user_manager.results
      );
      if (messageLevel === "Success") {
        addToast("Users updated successfully", { appearance: "success" });
        dispatch({
          type: CLEAR_MANAGER_ERRORS,
        });
      } else if (messageLevel === "Error") {
        addToast("Failed to update users", { appearance: "error" });
        dispatch({
          type: CLEAR_MANAGER_ERRORS,
        });
      } else {
        addToast("Completed updates with some errors", {
          appearance: "warning",
        });
        dispatch({
          type: CLEAR_MANAGER_ERRORS,
        });
      }
    }
  }, [user_manager]);

  useEffect(() => {
    dispatch({
      type: SG_GET_MANAGER_USERS,
      payload: `page=${activePage}&page_size=${pageSize}`,
    });
  }, [activePage]);

  useEffect(() => {
    if (selected === "") {
      setUsers(data.sort((a, b) => b.progress - a.progress));
    } else {
      setUsers(
        data
          .filter((user) => user.filters.includes(selected))
          .sort((a, b) => b.progress - a.progress)
      );
    }
  }, [selected]);

  return (
    <Page>
      <Container>
        <Center>
          <Title>
            Data sharing and accountability <AddUser />
          </Title>
          <Description>
            Share key insights, data stories, and recommendations and keep track
            of important tasks.
          </Description>

          <Table
            users={displayedManagers}
            addedCategories={manager_categories}
            userCategories={get_employee_org_category}
          />
        </Center>
        {managers?.count > pageSize && (
          <Pagination
            activePage={activePage}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            prevItem={activePage > 1 ? true : null}
            nextItem={activePage < totalPages ? true : null}
            totalPages={totalPages}
            onPageChange={changedPage}
            size="mini"
          />
        )}
      </Container>
    </Page>
  );
};

const User = ({ user }) => {
  const navigate = useNavigate();
  return (
    <UserContainer
      onClick={() => navigate(`/leader/prototype/userprofile/${user.id}`)}
    >
      <MemberArea>
        <UserImage src={user.picture.large} />
        <div>
          <UserName>
            {user.name.first} {user.name.last}{" "}
          </UserName>
          <div style={{ display: "flex" }}>
            <Dep>{user.filters[0]}</Dep>
          </div>
        </div>
      </MemberArea>

      <Progress
        percent={user.progress}
        progress
        size="small"
        color="blue"
        style={{ width: "60%" }}
      />

      <Icon
        name="chevron right"
        style={{
          fontSize: 14,
          color: "#B3B3B3",
          position: "absolute",
          right: -40,
          top: 10,
        }}
      />
    </UserContainer>
  );
};

export default Accountability;

const PaginateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const Page = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway";
`;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  margin-top: 40px;
  font-family: "Raleway";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Description = styled.div`
  font-size: 14px;
  margin-bottom: 40px;
`;

const Tag = styled.div`
  background-color: #dfdfdf;
  color: #666d79;
  padding: 5px;
  display: flex;
  font-size: 12px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const UserContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;
const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 20px;
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const UserEmail = styled.div`
  font-size: 14px;
  color: #666d79;
  margin-right: 20px;
`;
const UserProgress = styled(Progress)`
  width: 200px;
  margin-right: 40px !important;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  padding: 0;
`;
const UserStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const T1 = styled.div`
  font-size: 14px;
  font-weight: bold;
  width: ${(props) => props.width}%;

  display: flex;
  padding: 0px 10px;
`;

const Dep = styled.div`
  font-size: 12px;
  color: #666d79;
  margin-top: 0px;
`;

const ProgressArea = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const MemberArea = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: flex-end;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
`;

const IconContainer = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const TR = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
`;
