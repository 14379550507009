import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Popup } from "semantic-ui-react";

function hslToHex(h, s, l) {
	l /= 100;
	const a = (s * Math.min(l, 1 - l)) / 100;
	const f = (n) => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color)
			.toString(16)
			.padStart(2, "0"); // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
}

function stringToHslColor(str, s, l) {
	var hash = 0;
	for (var i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	var h = hash % 360;
	return hslToHex(h, s, l);
}

const Assignee = ({ first, last, size = null }) => {
	if (first && last) {
		return (
			<Popup
				content={"Assignee: " + first + " " + last}
				position="bottom center"
				size="mini"
				inverted
				trigger={
					<Container
						size={size}
						color={stringToHslColor(
							first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase(),
							30,
							80
						)}
					>
						{first.charAt(0).toUpperCase() + last.charAt(0).toUpperCase()}
					</Container>
				}
			/>
		);
	}

	return <div />;
};

export default Assignee;

const Container = styled.div`
	height: ${(props) => (props.size == "large" ? "50px" : "25px")};
	width: ${(props) => (props.size == "large" ? "50px" : "25px")};
	border-radius: ${(props) => (props.size == "large" ? "25px" : "12.5px")};
	background-color: ${(props) => props.color};
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	cursor: pointer;
	font-size: ${(props) => (props.size == "large" ? "20px" : "14px")};
`;
