import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Query from './Query'
import {Icon} from 'semantic-ui-react';

const Copilot = ({open,setOpen}) =>{

    return (
        <Container open={open}>
            <Content open={open}>
                <Title>Culture copilot</Title>
                <IconContainer onClick={()=>setOpen(!open)}>
                    <Icon name="window minimize outline"/>
                </IconContainer>

                <Query query={'Hi, I am your culture copilot, how can I help you? \n\n I am currently focused on your Department A culture factor data.'} type="response"/>


                <T2>Here are some frequenty asked questions:</T2>
                <Suggestions>
                    <Suggest>
                        <T1>Describe what this means</T1>
                        What are the key themes that help to explain the differences between these groups?
                    </Suggest>

                    <Suggest>
                        <T1>Tell me why the groups are different</T1>
                        What are the key themes that help to explain the differences between these groups?
                    </Suggest>

                    <Suggest>
                        <T1>Explain the reasons for the top score</T1>
                        What are the key themes that help to explain the differences between these groups?
                    </Suggest>
                </Suggestions>
            </Content>
           

        </Container>
    )

}

export default Copilot

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;


const Container = styled.div`
    height:calc(100vh - 100px);
    position:fixed;
    right:20px;
    top:80px;
    border-radius: 15px;
    width:${props=>props.open?'400px':'0'};
    background-color:#EBF1FD;
    display:flex;
    transition:all 0.3s ease-in-out;
    overflow:hidden;
    z-index:100;
    padding:${props=>props.open?'20px':'0px'};
    opacity:${props=>props.open?1:0};
    flex-direction:column;
    
`
const Content = styled.div`
    width:100%;
    height:100%;
    display:${props=>props.open?'flex':'none'};
    flex-direction:column;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    position:relative;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 40px;
    color:#2A3039;
    width:200px;
`

const IconContainer = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
        transform: scale(1.1);
    }
`

const T2 = styled.div`
    font-size: 14px;
    font-family: 'Raleway';
    margin-bottom: 10px;
    font-weight: 600;
    color:#2A3039;
`

const Suggestions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Suggest = styled.div`
    font-size: 12px;
    font-family: 'Raleway';
    margin-bottom: 10px;
    color:#2A3039;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid #666D79;
    padding: 15px;
    border-radius: 10px;
    line-height: 20px;
    background-color: #F8FAFF;

    &:hover{
        color:#666D79;
    }
`

const T1 = styled.div`
    font-size: 14px;
    font-family: 'Raleway';
    margin-bottom: 5px;
    font-weight: 600;
    color:#2A3039;
`