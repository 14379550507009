import React from "react";
import { View, Text, Image, StyleSheet,Page,Svg,Polygon } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  pages:{
    position:'absolute',
    left:0,
    top:0,
    height:'100vh',
    width:20,
    zIndex:1
  },
  shape1:{
    height:100,
    width:20,
    backgroundColor:'#3B86EE',
  }
 })



const Side = () =>{


  return (
    <View style={styles.pages} fixed>
      <Svg height="200" width="25" >
        <Polygon
          points="0,0 25,0 25,190 0,200 0,0"
          fill="#FCD21D"
          stroke="#FCD21D"
          strokeWidth={1}
        />
      </Svg>
      <Svg height="1000" width="25" style={{marginTop:-10}}>
        <Polygon
          points="0,10 25,0 25,990 0,1000 0,10"
          fill="#353740"
          stroke="#353740"
          strokeWidth={1}
        />
      </Svg>
      <Svg height="40" width="25" style={{marginTop:-10}}>
        <Polygon
          points="0,10 25,0 25,30 0,40 0,0"
          fill="#FCD21D"
          stroke="#FCD21D"
          strokeWidth={1}
        />
      </Svg>
      <Svg height="200" width="25" style={{marginTop:-10}}>
        <Polygon
          points="0,10 25,0 25,190 0,200 0,10"
          fill="#353740"
          stroke="#353740"
          strokeWidth={1}
        />
      </Svg>
    </View>
    )
}

export default Side