import DatePicker from "react-datepicker";
import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";

const DatePick = (props) => {
  const { startDate, endDate, handleDateChange } = props;

  return (
    <Container>
      <DatePicker
        selected={startDate}
        onChange={(date) => handleDateChange(date, true)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => handleDateChange(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </Container>
  );
};

export default DatePick;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  padding-top: 25px;
`;
