import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { UPDATE_ORGANIZATION, SG_EDIT_GLOBAL_SCHEDULE } from "constants/actions";
import { Header, } from "semantic-ui-react";
import { timeZones, SortedTimezone } from "data/timezone";
import Select from "react-select";

function UpdateTimeZone({ organization, organizationId, globalSchedule }) {
  const dispatch = useDispatch();

  const [timeZone, setTimeZone] = useState({
    value: "America/Halifax",
    label: "America/Halifax",
    text: "America/Halifax",
  });

  React.useEffect(() => {
    if (organization) {
      setTimeZone({
        value: organization?.time_zone,
        label: organization?.time_zone,
        text: organization?.time_zone,
      });
    }
  }, [organization]);

  function UpdateTimezone(e) {
    // console.log(e)
    dispatch({
      type: UPDATE_ORGANIZATION,
      payload: {
        id: organizationId,
        time_zone: e.value,
      },
    });
    // console.log("updated", globalSchedule.id)
    dispatch({
      type: SG_EDIT_GLOBAL_SCHEDULE,
      payload: {
        id: globalSchedule.id,
        time_zone: e.value,
      },
    });
    setTimeZone({
      value: e.value,
      label: e.value,
      text: e.value,
    });
  }

  return (

        <div style={{width: '80%'}}>
          <Header>Update Time zone</Header>

          <Select
            placeholder="Select your timezone"
            onChange={(e) => UpdateTimezone(e)}
            value={timeZone}
            options={SortedTimezone()}
          />
        </div>

  );
}

export default UpdateTimeZone;
