import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";

import EmptyEmployee from '../EmptyEmployee'
import Overview from './Overview'
import Connection from './Connection'
import Team from './Team'
import EQ from './EQ'

import Comments from './Comments'
import History from './History'

import ThemeAnalytics from '../../theme/ThemeAnalysis'
import ThemeOverview from '../../theme/Overview'

import format from "date-fns/format";

import { Dropdown, Icon,Dimmer,Loader,Segment } from 'semantic-ui-react'

import { ThemesList } from "data/themes"

const NavOptions = [{ text: 'Overview', value: 1 }, { text: 'Connections', value: 2 }, { text: 'Team Health', value: 3 }, { text: 'Emotional Health', value: 4 },
{ text: 'Sentiment Overview', value: 5 }, { text: 'Sentiment History', value: 6 }]


const DefaultPic = 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7'

const Blank = () => {

	return (
		<User>
			<Icon name="user circle" style={{ marginTop: 10, marginLeft: 3 }} />
		</User>
	)
}

const Profile = ({ 
	selEmp, cultureData, moodData, 
	empList,
	selectedTeam,
	loading, ...props }) => {

	const [selected, setSelected] = React.useState(1)
	const [empCultureData, setEmpCultureData] = React.useState([])
	const [empMoodData, setEmpMoodData] = React.useState([])

	const [dateList, setDateList] = useState([]);

	const [pastDates, setPastDates] = useState();
	const [culOverview, setculOverview] = useState();
	const [moodOverview, setmoodOverview] = useState();

	const [themeList, setThemeList] = useState([]);
	const [themeHistory, setThemeHistory] = useState([]);
	const [themeAnalysis, setThemeAnalysis] = useState([]);

	const [graphNetwork, setGraphNetwork] = useState([]);

	const [culConnections, setculConnections] = useState();
	const [culComments, setculComments] = useState();

	function FullName() {
		// console.log(selEmp?.first_name)
		if (selEmp?.first_name) {
			return `${selEmp.first_name} ${selEmp.last_name}`
		}
		return selEmp?.email?.split('@')[0]
	}



	useEffect(() => {
		if (selEmp?.id) {
			const overView = []
			const empList = []
			const _dateList = []
			const connectList = []
			const commentList = []
			const _graph_network = []
			// console.log(cultureData)
			cultureData.filter(md => md.team === selectedTeam?.id).map(cd => {
				_dateList.push(format(
					new Date(cd?.on_date.toLocaleString()),
					"MMM do yyyy"
				))
				
				if (cd.responses.filter(
					ce => ce.employee_id === selEmp.id
				)[0]) {
						overView.push(
							cd.responses.filter(
								ce => ce.employee_id === selEmp.id
							)[0]?.emp_average)
				} else {
					overView.push(0)
				}

				empList.push({
					date: cd.on_date,
					responses: cd.responses.filter(ce => ce.employee_id === selEmp.id)
				})
				connectList.push({
					date: format(
						new Date(cd?.on_date.toLocaleString()),
						"MMM do yyyy"
					),
					connections: cd.connections
				}
				)
				// /survey_comments
				commentList.push({
					date: format(
						new Date(cd?.on_date.toLocaleString()),
						"MMM do yyyy"
					),
					survey_comments: cd.survey_comments
				}
				)
				_graph_network.push(cd.graph_network)
				return null
			})
			// console.log(overView)

			setGraphNetwork(_graph_network)
			setPastDates(_dateList.reverse())
			setDateList(_dateList)
			setEmpCultureData(empList)
			setculOverview(overView.reverse())
			setculConnections(connectList)
			setculComments(commentList)

		}
	}, [cultureData, selEmp, selectedTeam]);

	// console.log(culOverview)
	// console.log(cultureData)
	// console.log(selEmp)
	// console.log(selectedTeam)

	useEffect(() => {
		// employee_comments
		if (selEmp?.id) {
			const overView = []
			const empList = []
			moodData.filter(md => md.team === selectedTeam?.id).map(cd => {
				if (cd.employee_comments.filter(
					ce => ce.employee_id === selEmp.id)[0]) {
					overView.push(cd.employee_comments.filter(
						ce => ce.employee_id === selEmp.id)[0]?.mood_score
					)
				} else {
					overView.push(0)
				}

				empList.push({
					date: cd.on_date,
					responses: cd.employee_comments.filter(
						ce => ce.employee_id === selEmp.id)
				})
				return null
			})
			setEmpMoodData(empList)
			setmoodOverview(overView.reverse())
		}

	}, [moodData, selEmp, dateList, selectedTeam]);


	useEffect(() => {
		const FinalList = []
		if (moodData[0]?.employee_theme_score[selEmp?.id]) {
			const ThemeList1 = Object.values(moodData[0]?.employee_theme_score[selEmp?.id])
			// console.log(ThemeList1);

			ThemeList1.filter(
				th => th.category_name !== ''
			).map(p => {
				FinalList.push(
					{
						name: p.category_name,
						importance: p.total_importance / p.nos,
						sentiment_compound: p.total_sentiment_compound / p.nos,
						sentiment_neg: p.total_sentiment_neg / p.nos,
						sentiment_neu: p.total_sentiment_neu / p.nos,
						sentiment_pos: p.total_sentiment_pos / p.nos,
						z_score: p.total_z_score / p.nos,
						keywords: p.keywords,
						sentiment: ((p.total_sentiment_compound + 1) / 2)
					}
				)
			})
			// console.log(FinalList)
			setThemeList(
				FinalList.sort((a, b) => parseFloat(b.importance) - parseFloat(a.importance))
			)

		} else {
			setThemeList([])
		}
		if (moodData) {
			
			const ResponseList = []
			const ResponseObject = {}

			ThemesList.map(th => {
				// console.log(th.name)
				ResponseObject[th.name] = {
					'importance': [],
					'sentiment': []
				}
				ResponseObject['dateList'] = []

				moodData.map(md => {
					if (md.employee_theme_score[selEmp?.id]) {
						const ThemeList1 = Object.values(md.employee_theme_score[selEmp?.id])
						const ThemeFound = (ThemeList1.filter(fth => fth.category_name === th.name)[0])
						if (ThemeFound) {
							ResponseObject[th.name]['importance'].push(Math.round(ThemeFound.total_importance / ThemeFound.nos))
							ResponseObject[th.name]['sentiment'].push(Math.round((((ThemeFound.total_sentiment_compound / ThemeFound.nos) + 1) / 2) * 100))
							// Math.round(()+ 1) / 2) * 100))
							// console.log(Math.round((((ThemeFound.total_sentiment_compound / ThemeFound.nos)+1 ) / 2)))
							ResponseList.push({
								date:
									format(
										new Date(md?.on_date.toLocaleString()),
										"MMM do yyyy"
									),
								name: th.name,
								importance: Math.round(ThemeFound.total_importance / ThemeFound.nos),
								sentiment: Math.round(((ThemeFound.total_sentiment_compound + 1) / 2) * 100)
							})
						} else {
							ResponseObject[th.name]['importance'].push(0)
							ResponseObject[th.name]['sentiment'].push(0)
							ResponseList.push({
								date:
									format(
										new Date(md?.on_date.toLocaleString()),
										"MMM do yyyy"
									),
								name: th.name,
								importance: 0,
								sentiment: 0
							})

						}
					}
				})
			})

			const FinalList1 = []
			const FinalList2 = []
			moodData.map(md => {
				if (md.employee_theme_score[selEmp?.id]) {
					const ThemeList1 = Object.values(md.employee_theme_score[selEmp?.id])
					// console.log(ThemeList1);
					// 
					ThemeList1.filter(
						th => th.category_name !== ''
					).map(p => {
					
						FinalList2.push(
							{
								date:
									format(
										new Date(md?.on_date.toLocaleString()),
										"MMM do yyyy"
									),
								name: p.category_name,
								importance: p.total_importance / p.nos,
								sentiment: ((p.total_sentiment_compound + 1) / 2)
							}
						)
					})
					// console.log(
					// 	FinalList2.sort((a, b) => parseFloat(b.importance) - parseFloat(a.importance))
					// )
					FinalList1.push(
						FinalList2.sort((a, b) => parseFloat(b.importance) - parseFloat(a.importance))
					)
					setThemeHistory(FinalList1)
				} else  {
					setThemeHistory([])
				}
			})
			// console.log(ResponseObject)
			// console.log(ResponseList)
			
			setThemeAnalysis(ResponseObject)
		}

	}, [moodData, selEmp, dateList])

	// console.log(empCultureData)
	// console.log(culComments)
	// console.log(selEmp)
	// console.log(themeList)
	// console.log(themeHistory)


	return (
		
		<Container hide={props.hide}>
		<Dimmer.Dimmable  dimmed={loading} blurring>
					<Dimmer active={loading} inverted >
						<Loader>Loading</Loader>
					</Dimmer>

		{!selEmp?
			<EmptyEmployee/>:
			<>
			<Card>
				<UserContainer>
					{selEmp ?
						selEmp.picture ?
							<Avatar src={selEmp.picture} />
							: <Blank /> : <Blank />}


					<Name>{FullName()}</Name>

				</UserContainer>

				<Tabs>
					<Tab selected={selected === 1} onClick={() => setSelected(1)}>Overview</Tab>
					<Tab selected={selected === 2} onClick={() => setSelected(2)}>Connections</Tab>
					<Tab selected={selected === 3} onClick={() => setSelected(3)}>Team Health</Tab>
					<Tab selected={selected === 4} onClick={() => setSelected(4)}>Emotional Health</Tab>
					<Tab selected={selected === 5} onClick={() => setSelected(5)}>Key Sentiments</Tab>
					<Tab selected={selected === 6} onClick={() => setSelected(6)}>Sentiment History</Tab>
				</Tabs>

				<MobileNav>
					<Dropdown
						inline
						options={NavOptions}
						defaultValue={NavOptions[0].value}
						onChange={(e, v) => setSelected(v.value)}
					/>
				</MobileNav>
			</Card>
			<Bottom>
				{selected === 1 && <Overview
					culOverview={culOverview}
					moodOverview={moodOverview}
					pastDates={pastDates}
				/>}

				{selected === 2 && <Connection
					pastDates={pastDates}
					culConnections={culConnections}
					graphNetwork={graphNetwork}
					selEmp={selEmp}
					empList={empList}
				/>}

				{selected === 3 && <Team
					empCultureData={empCultureData}
					selEmp={selEmp}
					culComments={culComments} />}
				
				{selected === 4 && <EQ
					empMoodData={empMoodData}
					selEmp={selEmp}
					pastDates={pastDates}
				/>}
				{selected === 5 &&
					<ThemeOverview themeList={themeList}
						themeHistory={themeHistory} />}

				{selected === 6 && <ThemeAnalytics
					themeHistory={themeHistory}
					dateList={dateList}
					pastDates={pastDates}
					themeAnalysis={themeAnalysis}
				/>}


			</Bottom>
			</>}
			</Dimmer.Dimmable>
		</Container>
		
	)
}



export default Profile

const Container = styled.div`
	width:${props => props.hide ? 'calc(100% - 70px)' : 'calc(100% - 270px)'};
	border-radius: 15px;
  	overflow:hidden;
  	transition:all 500ms;
`

const MobileNav = styled.div`
	
	@media(min-width:768px){
		display:none;
	}
`

const Card = styled.div`
	width:100%;
	padding:30px 30px 0px 10px ;
	
  	background-color: #ffffff;

  	@media(max-width:768px){
  		padding:5px;
  	}
`


const UserContainer = styled.div`
	display:flex;
	align-items:center;
	position:relative;
	margin-bottom:40px;
`

const Avatar = styled.img`
	width:60px;
	height:60px;
	border-radius:30px;
	margin-right:10px;

	@media(max-width:768px){
		display:none;
	}
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:36px;
	font-weight:bold;
	color:#4a4a4a;
	line-height:1;
`

const Tabs = styled.div`
	display:flex;

	@media(max-width:768px){
		display:none;
	}
`

const Tab = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px;
	font-size:16px;
	font-weight:${props => props.selected ? 'bold' : 400};
	border-bottom:${props => props.selected ? '2px solid #6a00ff' : 400};
	color:${props => props.selected ? 'black' : '#9b9b9b'};
	cursor:pointer;
`

const Bottom = styled.div`
	width:100%;
	background-color:white;

`
const User = styled.div`
	width:50px;
	height:50px;
	border-radius:25px;
	margin-right:20px;
	display:flex;
	align-items:center;
	justify-content:center;
	color:black;
	font-size:50px;
	margin-top:10px;

	@media(max-width:768px){
		display:none;
	}
`
