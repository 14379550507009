import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Personality from "assets/images/nav/personality.svg";
import { Link } from "react-router-dom";
// import ModalLeader from "./ModalLeaderTeams";
// import LeaderList from "./LeaderList";

import {
  SG_GET_GLOBAL_SCHEDULES,
  PULL_ORGANIZATION,
  SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,

} from "constants/actions";

import {
  Warning,
  Title,
  Title2,
  Title3,
  P,
  P2,
  Line,
  Line2,
  SettingsCard,
  Img,
  View,
  Button,
  Container,
} from "./styled_list";



const SurveySettings = ({organizationalId, setProcessing }) => {
  const dispatch = useDispatch();
  const [globalSchedule, setGlobalSchedule] = useState([]);

  const organization_id = useSelector((state) => state.auth.organization_id);
  const [organization, setOrganization] = React.useState("");
  const [organizationId, setOrganizationId] = useState(null);

  React.useEffect(() => {
    // if the response is ok 200
    if (organization_id) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organizationalId? organizationalId : organization_id },
      });
    }
  }, [organization_id, organizationalId, dispatch]);

  const { get_organization } = useSelector(
    (state) => ({
      get_organization: state.organizations,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   // if the response is ok 200
  //   dispatch({
  //     type: SG_GET_GLOBAL_SCHEDULES,
  //   });

  // }, [dispatch]);
  
  useEffect(() => {
    dispatch({
      type: SG_GET_GLOBAL_SCHEDULES,
      payload: organizationalId ? `organization=${organizationalId}` : ''
    });
  }, [dispatch, organizationalId]);

  // useEffect(() => {
  //   if (globalSchedule?.id) {
  //     dispatch({
  //       type: SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
  //       payload: `global-schedule=${globalSchedule.id}`,
  //     });
  //   }
  // }, [dispatch, globalSchedule]);

  useEffect(() => {
    if (get_organization) {
      setOrganization(get_organization[organization_id]);
    }
  }, [get_organization, organization_id]);

  const {
    // get_selected_teams,
    get_global_schedules,
    // get_survey_compliances,
    // get_global_schedule_frequencies,
    // get_auth,
  } = useSelector(
    (state) => ({
      // get_selected_teams: state.teamsinschedule.teams_in_schedules,
      get_global_schedules: state.surveyschedule.global_schedules,
      // get_global_schedule_frequencies:
      //   state.surveyschedulefrequency.global_schedule_frequencies,
      get_survey_compliances: state.surveycompliance.survey_compliances,
      // get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    // setGlobalSettings(get_global_schedules);
    setOrganizationId(organizationalId? Number(organizationalId) : Number(organization_id));
    setGlobalSchedule(get_global_schedules);
    
  }, [
    get_global_schedules,
    // get_survey_compliances,
    // get_global_schedule_frequencies,
    // get_auth,
    organizationalId,
    organization_id
  ]);

  // List of teams in mood 2 and culture 1
  // console.log(globalSchedule)
  return (
    <div style={{ width: "100%", padding: 10 }}>
      <Title>Personality Survey Settings</Title>
      <P>Manage Personality Survey Users.</P>
      <Line />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          zIndex: 3,
          justifyContent: "space-between",
        }}
      >
        {globalSchedule && (
          <SettingsCard style={{ paddingBottom: 100 }}>
            <Container>
              <Img src={Personality} style={{ width: 70 }} />
              <div>
                <Title2>Personality</Title2>
                <P2>
                  Understand yourself, your teams, and your people at a much
                  deeper level using a valid and reliable personality
                  assessment.
                </P2>
              </div>
            </Container>

            <div
              style={{
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                position: "absolute",
                bottom: 15,
                right: 20,
              }}
            >
              <View>
                <Link to="/settings/personality">
                  <Button>Manage</Button>
                </Link>
              </View>
            </div>
          </SettingsCard>
        )}
      </div>
    </div>
  );
};

export default SurveySettings;
