import React from "react";
import { View, Text, Image, StyleSheet, Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import { getAverageForGroup, getDepScores } from "../utils/calculations";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginBottom: 30,
    position: "relative",
  },
  bottom: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignItems:'center',
    paddingTop: 2,
    paddingBottom: 2,
  },
  title: {
    width: "20%",
    fontSize: 10,
  },
  bar: {
    height: 8,
    width:'100%',
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  score: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 4,
    position: "absolute",
    right: -20,
    top: -2,
    opacity: 1,
  },
  lines: {
    position: "absolute",
    left: "20%",
    height: "100%",
    width: "calc(80% + 20px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    height: "100%",
    width: 1,
    borderLeft: "1px dashed grey",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chart: {
    position: "relative",
  },
  text: {
    position: "absolute",
    bottom: -5,
    left: -5,
    fontSize: 8,
    color: "grey",
  },
  circle:{
    height:8,
    width:8,
    borderRadius:4,
    backgroundColor:'#204c75',
    position:'absolute',
    marginLeft:-4
  },
  variance:{
    height:8,
    opacity:0.6,
    backgroundColor:'#327bbf',
    position:'absolute',
    zIndex:-1
  }
});

const Chart = ({
  responseData,
  surveyStructure,
  factorId,
  demographics,
  options,
  breakdown,
  categoryId,
  item,
}) => {
  const getDemographicsAverage = (factorId, optionId, chartData) => {
    let totalPool = 0;
    let demTotal = 0;
    chartData.forEach((d) => {
      if (d.categoryId === factorId) {
        d.data.forEach((r) => {
          totalPool += r.total;
          if (r.id === optionId) {
            demTotal += r.total;
          }
        });
      }
    });
    return (demTotal / totalPool) * 100;
  };

  const priority = item?.priority;

  const depScores = getDepScores(responseData, factorId);

  return (
    <View style={styles.page}>
      <View style={styles.chart}>
        {priority === "primary" &&
          breakdown &&
          depScores?.map((dep) => {
               let std = 10
            return (
              <View style={styles.row}>
                <Text style={styles.title}>{dep.group}</Text>

                <View
                    style={[styles.bar, ]}
                  >
                  <View style={[styles.variance,{width:std*2+'%',left:dep.score -std+'%'}]} />

                    <View style={[styles.circle,{ left: dep.score  + "%" }]}/>
             

                </View>
              </View>
            );
          })}
        {priority !== "primary" &&
          options?.map((item, i) => {
            let score = demographics
              ? getDemographicsAverage(categoryId, item.id, responseData)
              : getAverageForGroup(
                  responseData,
                  item.name,
                  categoryId,
                  surveyStructure,
                  factorId
                ) * 10;

            if (score > 0) {
              let std = 10
              return (
                <View style={styles.row}>
                  <Text style={styles.title}>{item.name}</Text>
                  <View
                    style={[styles.bar, ]}
                  >
                  <View style={[styles.variance,{width:std*2+'%',left:score-std+'%'}]} />

                    <View style={[styles.circle,{ left: score + "%" }]}/>
             
                  </View>
                </View>
              );
            }

            return null;
          })}

        <View style={styles.lines}>
          <View style={styles.line}>
            <Text style={styles.text}>0%</Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.text}>20%</Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.text}>40%</Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.text}>60%</Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.text}>80%</Text>
          </View>
          <View style={styles.line}>
            <Text style={styles.text}>100%</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default Chart;
