import { all, takeLatest, call, put, take, select, delay, race } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  PENDING_WEBSOCKET,
  SAGA_CONNECT_WEBSOCKET,
  CONNECT_WEBSOCKET,
  SAGA_MESSAGE_WEBSOCKET,
  MESSAGE_WEBSOCKET,
  SAGA_START_AND_MESSAGE_WEBSOCKET,
  DISCONNECT_WEBSOCKET,
  ERROR_WEBSOCKET,
  ROOM_LOADING,
  CREATE_CHAT_ROOM,
  FETCH_FAILURE,
  ADD_PENDING_MESSAGE,
  SG_ADD_PENDING_MESSAGE,
  DISPLAY_RETRY_MESSAGE,
} from "../constants/actions";
import * as api from "../constants/api";

import { WEBSOCKET_URL } from "../constants/api";

function* establishWebSocketSaga(action) {
  // const { token, name, web_chat_id } = action.payload;
  // const url = `ws://localhost:8000/ws-1/${token}/${name}?web_chat_id=${web_chat_id}`;
  const ws = new WebSocket(WEBSOCKET_URL(action.payload));

  yield new Promise((resolve) => {
    ws.onopen = resolve;
  });

  yield put({ type: CONNECT_WEBSOCKET, payload: ws });
}

function* establishWebSocket() {
  yield takeLatest(SAGA_CONNECT_WEBSOCKET, establishWebSocketSaga);
}

function createWebSocketChannel(ws) {
  return eventChannel((emit) => {
    const messageHandler = (event) => {
      // Assuming you receive the response message from the server
      emit({ type: MESSAGE_WEBSOCKET, payload: JSON.parse(event.data) });
    };

    const closeHandler = () => {
      // Handle WebSocket disconnection
      emit({ type: DISCONNECT_WEBSOCKET });
    };

    const errorHandler = (error) => {
      // Handle WebSocket errors
      emit({ type: ERROR_WEBSOCKET, payload: error });
    };

    // Add event listener for 'message' event
    ws.addEventListener("message", messageHandler);

    // Add event listener for 'close' event
    ws.addEventListener("close", closeHandler);

    // Add event listener for 'error' event
    ws.addEventListener("error", errorHandler);

    // Return the unsubscribe function
    return () => {
      // Clean up the event listeners when the channel is unsubscribed
      ws.removeEventListener("message", messageHandler);
      ws.removeEventListener("close", closeHandler);
      ws.removeEventListener("error", errorHandler);
    };
  });
}

function* handleSendMessage(action) {
  yield put({ type: MESSAGE_WEBSOCKET, payload: action.payload });
  yield put({ type: PENDING_WEBSOCKET });
  yield put({ type: DISPLAY_RETRY_MESSAGE, payload: false });

  const ws = yield select((state) => state.ai_websocket.ws);

  if (ws && ws.readyState === WebSocket.OPEN) {
    const channel = yield call(createWebSocketChannel, ws);

    // Send the message with a race to handle timeout
    try {
      const { response, timeout } = yield race({
        response: call([ws, ws.send], JSON.stringify(action.payload)),
        timeout: delay(5000), // Set the timeout duration as needed
      });

      if (timeout) {
        throw new Error("Message send timeout");
      }
    } catch (error) {
      console.error("Message failed to send:", error);
      return;
    }

    // Listen for responses on the WebSocket channel
    while (true) {
      const responseAction = yield take(channel);
      yield put(responseAction);
    }
  } else {
    console.log("WebSocket connection not open.");
    yield put({ type: DISCONNECT_WEBSOCKET, payload: false });
  }
}

// Watcher Saga
function* watchSendMessage() {
  yield takeLatest(SAGA_MESSAGE_WEBSOCKET, handleSendMessage);
}

// Saga to create a process and get the response ID
function* createProcess(action) {
  yield put({ type: ROOM_LOADING });
  try {
    const json = yield call(api.createChatRoom, action.payload);
    yield put({ type: CREATE_CHAT_ROOM, payload: json.data });
    // Return the response ID
    return json.data.id;
  } catch (e) {
    yield put({ type: FETCH_FAILURE, payload: e.message });
  }
}

function* waitForWebSocketOpen(ws) {
  yield new Promise((resolve, reject) => {
    const maxAttempts = 10; // Adjust the maximum number of attempts as needed
    let attempts = 0;

    const checkOpen = () => {
      if (ws.readyState === WebSocket.OPEN) {
        resolve();
      } else if (
        ws.readyState === WebSocket.CLOSED ||
        attempts >= maxAttempts
      ) {
        reject(new Error("WebSocket connection failed to open."));
      } else {
        attempts++;
        setTimeout(checkOpen, 100); // Adjust the delay time in milliseconds as needed
      }
    };

    checkOpen();
  });
}

function* handleStartandSendMessage(action) {
  const { name, message, token } = action.payload;
  yield put({ type: DISPLAY_RETRY_MESSAGE, payload: false });

  // Call the createProcess saga to get the response ID
  const web_chat_id = yield call(createProcess, action);

  // Initialize WebSocket with generated chat ID
  const ws = new WebSocket(WEBSOCKET_URL({ name, token, web_chat_id }));
  // const ws = new WebSocket("ws://invalid-url"); // Invalid URL for testing purposes

  yield put({ type: MESSAGE_WEBSOCKET, payload: message });

  const MAX_RETRIES = 3; // Maximum number of retries
  let retries = 0;

  // Start the 25-second global timeout timer
  const { timeoutReached } = yield race({
    connectedWithin25Sec: call(function* () {
      while (retries < MAX_RETRIES) {
        try {
          const result = yield race({
            connected: call(waitForWebSocketOpen, ws),
            retryTimeout: delay(10000), // 10-second retry delay
          });

          if (result.connected) {
            yield put({ type: CONNECT_WEBSOCKET, payload: ws });
            return true; // Exit the 25-second timer race if connected
          } else {
            retries += 1;
            console.log(`Retry attempt ${retries}...`);
          }
        } catch (error) {
          console.error("Error during WebSocket connection:", error);
          break;
        }
      }
      return false; // Return false if maximum retries were exhausted
    }),
    timeoutReached: delay(25000), // 25-second global timeout
  });

  // Check if the 25-second timer was reached
  if (timeoutReached) {
    console.warn("25-second timer reached without WebSocket connection");
    yield put({ type: DISPLAY_RETRY_MESSAGE, payload: action.payload });
    yield put({ type: DISCONNECT_WEBSOCKET, payload: false });
    return;
  }

  // WebSocket is connected and ready to send messages
  if (ws && ws.readyState === WebSocket.OPEN) {
    yield put({ type: PENDING_WEBSOCKET });
    const channel = yield call(createWebSocketChannel, ws);

    ws.send(JSON.stringify(message));

    while (true) {
      const responseAction = yield take(channel);
      yield put(responseAction);
    }
  } else {
    yield put({ type: DISPLAY_RETRY_MESSAGE, payload: action.payload });
    yield put({ type: DISCONNECT_WEBSOCKET, payload: false });
  }
}

// Watcher Saga
function* watchStartAndSendMessage() {
  yield takeLatest(SAGA_START_AND_MESSAGE_WEBSOCKET, handleStartandSendMessage);
}

function* addPendingMessage(action) {
  yield put({ type: SG_ADD_PENDING_MESSAGE, payload: action.payload });
}

// Watcher Saga
function* watchAddPendingMessage() {
  yield takeLatest(ADD_PENDING_MESSAGE, addPendingMessage);
}

export default function* rootSaga() {
  yield all([
    establishWebSocket(),
    watchSendMessage(),
    watchStartAndSendMessage(),
    // Other sagas
    watchAddPendingMessage(),
  ]);
}
