import React from "react";
import styled from "styled-components";

const GroupTitle = (props) => {
  const { title } = props;

  return (
    <TitleWrapper>
      <Title>{title}</Title>
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 128%;
  color: #2a3039;
`;

export default GroupTitle;
