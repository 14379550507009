import styled, { keyframes } from "styled-components";
import React from "react";

import { Icon } from "semantic-ui-react";
import { Link, useParams } from "react-router-dom";

import teamdash_data from "../../../data/teamdash_data.json";
import depdash_data from "../../../data/depdash_data.json";
import MobileCard from "./MobileCard";
import MobileChart from "./mobilechart";

import "./analytics.css";

const Profile = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #e9e9e9",
        width: "calc(100%)",
        padding: "9px 6.5px 9px 6.5px",
        marginBottom: 10,
      }}
    >
      <Avatar src={props.pic} />
      <div>
        <Name>{props.name}</Name>
        <Compliance compliance={props.compliance}>
          {props.compliance}% Compliance
        </Compliance>
      </div>
    </div>
  );
};

const categories = ["Culture", "Mood", "Leadership"];

const MobileTeamDash = () => {
  let { type } = useParams();
  const [position, setPosition] = React.useState(0);
  const data = type == "Team" ? teamdash_data : depdash_data;
  const [reverse, setReverse] = React.useState(false);
  const [direction, setDirection] = React.useState(1);
  const [selected, setSelected] = React.useState(0);
  const [trends, setTrends] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const next = () => {
    setDirection(1);

    if (selected == 2) {
      setSelected(0);
      setReverse(false);
    } else {
      setSelected(selected + 1);
      setReverse(false);
    }
  };

  const back = () => {
    setDirection(0);

    if (selected == 0) {
      setSelected(2);
      setReverse(true);
    } else {
      setSelected(selected - 1);
      setReverse(true);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          width: "100%",
        }}
      >
        <Link to="/HRDash">
          <Icon
            name="arrow left"
            size={"large"}
            style={{ color: "#6a00ff", marginRight: 24 }}
          />
        </Link>
        <div style={{ width: "100%" }}>
          <Title>
            {data.name} {data.type}
          </Title>
          <Row>
            <LeaderAvatar src={data.leader_pic} />
            <Leader>{data.leader}</Leader>
          </Row>
        </div>
      </div>

      <Card>
        <div>
          <CardTitle>Survey participation</CardTitle>
          <OverallCompliance compliance={data.overall_compliance}>
            team compliance {data.overall_compliance}%
          </OverallCompliance>
        </div>
        <View
          style={{ position: "absolute", right: "20px" }}
          onClick={() => setOpen(true)}
        >
          View &gt;
        </View>
      </Card>
      <MobileCard title={"Team Personality Profiles"} link={"/personality"} />

      <TopCard>
      
          <Content key={selected}>
            <ToggleTitle>{categories[selected]}</ToggleTitle>
          </Content>


        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          {[0, 1, 2].map((item, i) => {
            return <Dot selected={i == selected} />;
          })}
        </div>

        <Arrow2 position={"1%"} onClick={() => back()}>
          <Icon
            name="chevron left"
            style={{
              fontSize: 25,
              cursor: "pointer",
              zIndex: 2,
              marginRight: 0,
            }}
          />
        </Arrow2>
        <Arrow2 position={"90%"} onClick={() => next()}>
          {" "}
          <Icon
            name="chevron right"
            style={{
              fontSize: 25,
              cursor: "pointer",
              zIndex: 2,
              marginRight: 0,
            }}
          />
        </Arrow2>
      </TopCard>
      <MobileChart
        selected={selected}
        data={data.history}
        categories={categories}
      />

      <ComplianceContainer open={open}>
        <EmotionTop>
          Team Participation
          <Icon
            name="close"
            style={{ position: "absolute", right: 10 }}
            onClick={() => setOpen(false)}
          />
        </EmotionTop>
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            marginLeft: 20,
            marginBottom: 50,
            justifyContent: "center",
            overflowY: "scroll",
          }}
        >
          {data.members.map((item, i) => {
            return (
              <Profile
                compliance={item.compliance}
                name={item.name}
                pic={item.pic}
              />
            );
          })}
        </div>
      </ComplianceContainer>
    </Container>
  );
};

export default MobileTeamDash;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Title = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const Sub = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LeaderAvatar = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: solid 1px #ebebeb;
  margin-right: 10px;
`;

const Avatar = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 21px;
  margin-right: 12px;
`;

const Leader = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  text-transform: uppercase;
`;
const OverallCompliance = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: ${(props) =>
    props.compliance < 70
      ? "#ff2d55"
      : props.compliance > 90
      ? "#04a543"
      : "#9b9b9b"};
  font-family: "Red Hat Display", sans-serif;
  text-transform: uppercase;
`;

const View = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #6a00ff;
  display: flex;
  align-items: center;
`;
const Arrow = styled.span`
  font-size: 20px;
  margin-left: 15px;
`;

const Card = styled.div`
  border-radius: 15px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-left: 26px;
  width: 100%;
  margin-top: 20px;
  align-items: center;
  position: relative;
`;
const CardTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
`;

const TopCard = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 26px;
  position: relative;
  margin-bottom: 5px;
  margin-top: 20px;
`;
const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  flex-direction: column;
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0px;
  left: 0px;
`;

const ToggleTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 5px;
`;

const P = styled.p`
  font-size: 16px;
  line-height: 1.31;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  text-align: center;
  width: 75%;
  margin-bottom: 10px;
`;

const Arrow2 = styled.span`
  font-size: 50px;
  position: absolute;
  top: 10px;
  left: ${(props) => props.position};
  cursor: pointer;
  user-select: none;
`;

const Dot = styled.div`
  width: ${(props) => (props.selected ? "8px" : "6px")};
  height: ${(props) => (props.selected ? "8px" : "6px")};
  opacity: ${(props) => (props.selected ? 1 : 0.6)};
  background-color: #6a00ff;
  border-radius: ${(props) => (props.selected ? "4px" : "3px")};
  margin-right: 6px;
`;

const ComplianceContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99999;
  background-color: white;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
`;

const EmotionTop = styled.div`
  height: 100px;
  min-height: 100px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: black;
  font-family: "Red Hat Display", sans-serif;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
`;

const Compliance = styled.div`
  font-size: 12px;
  font-weight: 900;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) =>
    props.compliance < 70
      ? "#ff2d55"
      : props.compliance > 90
      ? "#04a543"
      : "#9b9b9b"};
`;
