// BenchmarkDiagnostic.js
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { calculateFactorScores } from './calculations';
import Chart from "react-apexcharts";
import styled,{createGlobalStyle} from 'styled-components';
import {Icon} from 'semantic-ui-react';


const BenchmarkDiagnostic = ({ onGenerateReport, onFactorSelected,goBack,data }) => {
  const [chartData, setChartData] = useState(null);

  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  useEffect(() => {
    if (core_data && data) {
      setChartData(calculateFactorScores(data, core_data));
    }
  }, [core_data, data]);

  if (!chartData) {
    return (
      <div className="benchmark-diagnostic">
        <h2>Global Benchmark Comparison Diagnostic</h2>
        <p>Loading data...</p>
      </div>
    );
  }
  console.log(chartData)
  // Extract the 'Difference' series
  const differenceSeries = chartData.series.find(
    (seriesItem) => seriesItem.name === 'Average Score'
  );

  if (!differenceSeries) {
    return (
      <div className="benchmark-diagnostic">
        <h2>Global Benchmark Comparison Diagnostic</h2>
        <p>No difference data available.</p>
      </div>
    );
  }

  // Prepare data by pairing differences with their categories and IDs
  const differenceData = differenceSeries.data.map((dataPoint) => ({
    category: dataPoint.x,
    value: dataPoint.y != null ? parseFloat((dataPoint.y * 10).toFixed(1)) : null, // Scale to base 100
    factorId: dataPoint.factorId,
    dimensionId: dataPoint.dimensionId
  }));

  // Filter out null values and sort from highest to lowest difference
  const sortedDifferenceData = differenceData
    .filter((item) => item.value != null)
    .sort((a, b) => b.value - a.value);

  // Prepare sorted categories and data points
  const sortedSeriesData = sortedDifferenceData.map((item) => ({
    x: item.category,
    y: item.value,
    factorId: item.factorId,
    dimensionId: item.dimensionId
  }));

  // Determine the y-axis min and max based on the data
  const yMin = Math.floor(Math.min(...sortedDifferenceData.map(item => item.value)));
  const yMax = Math.ceil(Math.max(...sortedDifferenceData.map(item => item.value)));

  // Event handler for data point selection
  const handleDataPointSelection = (event, chartContext, config) => {
    const { dataPointIndex, seriesIndex, w } = config;
    const dataPoint = w.config.series[seriesIndex].data[dataPointIndex];
    const { factorId, dimensionId,x } = dataPoint;
    // Call the onFactorSelected prop
    if (onFactorSelected) {
      onFactorSelected({ factorId, dimensionId,name:x });
    }
  };

  // Chart options
  const options = {
    chart: {
      height: 400,
      type: 'bar',
      id: 'my-chart',
      fontFamily: 'Raleway, sans-serif',
      foreColor: '#7E7E7E',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    colors: ['#2D70E2'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        barHeight: '70%',
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: 'solid',
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      type: 'category',
      title: {
        text: 'Factors',
      },
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
          fontSize: '12px',
        },
        rotate: -45,
        maxHeight: 100,
        formatter: function (val) {
          return val.length > 30 ? val.substring(0, 27) + '...' : val;
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
        formatter: function (value) {
          return value.toFixed(0);
        },
      },
      title: {
        text: 'Factor Score',
      },
      min: yMin - 5,
      max: yMax + 5,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return value.toFixed(1);
        },
      },
      style: {
        fontFamily: 'Raleway, sans-serif',
      },
    },
    title: {
      text: 'Select a Factor for your Action Plan',
      align: 'center',
      style: {
        fontFamily: 'Raleway, sans-serif',
      },
    },
  };

  const series = [
    {
      name: 'Factor Score',
      data: sortedSeriesData,
    },
  ];

  console.log(series)

  return (
    <div className="benchmark-diagnostic">
        <BackButton onClick={goBack}><Icon name="chevron left"/> Back</BackButton>
      <Title>Score Comparison Diagnostic</Title>
      <p>Based on your culture data please select a culture factor you'd like to create an action plan for.</p>
        <GlobalStyle />
      <Chart options={options} series={series} type="bar" height={400} />
      
    </div>
  );
};

export default BenchmarkDiagnostic;

const GlobalStyle = createGlobalStyle`
  .apexcharts-bar-area {
    cursor: pointer;
  }
`;

const Container = styled.div`
    width:100%;
`


const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
`

const Title = styled.div`
    font-size:22px;
    font-weight:bold;
    margin-bottom:20px;
    margin-top:10px;
`