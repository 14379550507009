import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";

import Distribution from "./distribution";
import Panel from "./panel";

import { tabs, Leadershiptitle, HeadingList } from "data/leadership";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  SG_GET_LEADERSHIP_DATA,
  LOAD_TEAMS,
  LOAD_EMPLOYEES,
} from "constants/actions";


import "./leadership.css";

import Analytics from "./analytics";
import teamdash_data from "../../data/teamdash_data.json";

import { Dimmer, Loader, Icon, Header } from "semantic-ui-react";

import EmptyCard from "./EmptyCard";
import Feedback from './Feedback'


const Leadership = () => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [view, setView] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = React.useState("Loading");

  const [empty, setEmpty] = useState(false);
  const [selectedName, setSelectedName] = useState({});

  const [ansDist, setAnsDist] = useState([]);
  const [ansData, setAnsData] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [user, setUser] = useState([]);

  const [employee, setEmployee] = React.useState(false);
  const [employeeId, setEmployeeId] = React.useState(null);

  const [leadershipData, setLeadershipData] = useState([]);
  const [userCount, setUserCount] = useState([0, 0]);

  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_EMPLOYEES }); // from server
    dispatch({ type: LOAD_TEAMS }); // from server
    dispatch({ type: SG_GET_LEADERSHIP_DATA }); // from server
  }, [dispatch]);

  useEffect(() => {
    setSelectedName(HeadingList.find(({ ind }) => ind === selected));
  }, [selected]);

  const { get_leadership_data, get_auth, get_employees } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_leadership_data: state.leadership.leadership,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_employees && get_auth) {
      setEmployee(get_employees[get_auth.employee_id]);
      setEmployeeId(get_auth.employee_id);
    }
  }, [get_employees, get_auth]);

  useEffect(() => {
    if (get_leadership_data?.length > 0) {
      setLeadershipData(
        get_leadership_data.filter(
          (d) => d.leader === Number([get_auth.employee_id])
        )[0]
      );
    }
  }, [get_leadership_data, get_auth]);

  useEffect(() => {
    let _lead_data = [];
    let _lead_dist = [];

    if (leadershipData?.question_list) {
      _lead_data.push([
        leadershipData.team_average.leader_total_avg,
        leadershipData.team_average.team_total_avg,
      ]);
      _lead_dist.push(Object.values(leadershipData.team_average.spread));
      Leadershiptitle.map((t) => {
        const ID = HeadingList.find(({ title }) => title === t)?.id;
        leadershipData.question_list
          .filter((fl) => fl.id === ID)
          .map((p) => {
            _lead_data.push([p.leader_score, p.team_avg]);
            _lead_dist.push(Object.values(p.spread));

            return null;
          });
        return null;
      });
      setAnsData(_lead_data);
      setAnsDist(_lead_dist);
    }

    if (leadershipData?.compliance) {
      let _yes = 0;
      let _no = 0;
      leadershipData.compliance.map((p) => {
        if (p.completed === true) {
          _yes += 1;
        } else {
          _no += 1;
        }

        return null;
      });

      setUserCount([_yes, _no]);
      setUser(leadershipData.compliance);
    }
  }, [leadershipData]);

  useEffect(() => {
    if (get_leadership_data) {
      // console.log("I was here 123");
      if (get_leadership_data?.length > 0) {
        // console.log("I was here 148", get_leadership_data?.length);
        if (leadershipData?.id) {
          setEmpty(false)
        } else {
          // console.log("I was here 123");

            setEmpty(true)
          //
        }
      } else {
        // console.log("I was here 163");
        setTimeout(
          () => setLoadingText("Unable to fetch data for this report"),
          2000
        );
        setEmpty(true)
      }
    } else {
      // console.log("I was here 169");
      setTimeout(
        () => setLoadingText("Unable to fetch data for this report"),
        2000
      );
      setEmpty(true)
    }

    setTimeout(() => {
            setLoading(false);
          }, 2000);

  }, [leadershipData, get_leadership_data]);
  // console.log(leadershipData);
  useEffect(() => {
    const _analytics = [];
    // setTimeout(() => setLoading(false), 2000);
    if (get_leadership_data.length > 0) {
      Leadershiptitle.map((t) => {
        const _titleRow = [];
        const ID = HeadingList.find(({ title }) => title === t)?.id;
        // console.log(t);
        get_leadership_data
          .filter((d) => d.leader === Number([get_auth.employee_id]))
          .map((ld) => {
            if (t === "Leadership") {
              // console.log(ld?.question_list);
              _titleRow.push({
                id: ld.id,
                date: ld.on_date,
                self: ld.leader_avg,
                team: ld.member_avg,
                title: "Leadership",
              });
            } else {
              if (ld.question_list.length > 0) {
                ld.question_list
                  .filter((fl) => fl.id === ID)
                  .map((p) => {
                    _titleRow.push({
                      id: ld.id,
                      date: ld.on_date,
                      self: p.leader_score,
                      team: p.team_avg,
                      title: t,
                    });
                    return null;
                  });
                // console.log(_titleRow);
              }
            }

            return _titleRow;
          });
        if (_titleRow.length > 0) {
          _analytics.push(_titleRow);
        }
        return _analytics;
      });
      setAnalytics(_analytics.reverse());
    }
  }, [get_leadership_data, get_auth]);

  return (
    <div
      style={{
        height: "90vh",
        marginTop: 50,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Panel
        view={view}
        user={user}
        userCount={userCount}
        setView={(x) => setView(x)}
      />
      <Title>Leadership</Title>

      {loading ? (
        ""
      ) : (
        <View
          style={{ position: "absolute", right: 40 }}
          onClick={() => setView(true)}
        >
          View Leadership Panel <Arrow>&#62;</Arrow>
        </View>
      )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 37,
            height: 30,
            zIndex: 1,
          }}
        >
          {tabs.map((item, i) => {
            return (
              <Tab
                selected={i == selected}
                key={i}
                onClick={() => setSelected(i)}
                //   loading={loading}
              >
                {item}
              </Tab>
            );
          })}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 48,
            zIndex: 1,
          }}
        >
          {loading ? (
            <LoadingCard />
          ) : (
            <>
              {empty ? (
                <EmptyCard selected={selected} title={Leadershiptitle} />
              ) : (
                <Card key={selected}>
                  {ansData.length > 0 && (
                    <ScoreCard>
                      <CardText>Your {Leadershiptitle[selected]}</CardText>
                      <CardP>
                        When team members trust each other to pull their weight
                        and follow through. Without dependability, trust
                        decreases, resentment rises, and conflict is likely.
                      </CardP>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          height: 50,
                          marginTop: 50,
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRight: "1px solid white",
                            flexDirection: "column",
                          }}
                        >
                          <Score>{ansData[selected][0]} </Score>
                          <SubScore>Self score</SubScore>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Score>{ansData[selected][1]}</Score>
                          <SubScore>team avg</SubScore>
                        </div>
                      </div>
                    </ScoreCard>
                  )}
                  <CardBottom>
                    <Analytics
                      leadership={analytics}
                      trigger={
                        <View>
                          View Analytics <Arrow>&#62;</Arrow>
                        </View>
                      }
                    />
                  </CardBottom>
                </Card>
              )}
            </>
          )}

          {loading ? (
            <EmptyDistributionContainer />
          ) : (
            <DistributionContainer>
              <TopDistribution>
                <div style={{ padding: 20 }}>
                  <DistributionTitle>
                    {Leadershiptitle[selected]} Response Distribution
                  </DistributionTitle>
                </div>
                {ansData.length > 0 && ansDist.length > 0 ? (
                  <Distribution
                    selected={selected}
                    selectedName={selectedName}
                    ansDist={ansDist}
                    ansData={ansData}
                    empty={empty}
                  />
                ) : (
                  ""
                )}
              </TopDistribution>
              <BottomDistribution>
                <DistributionText>Not at all</DistributionText>
                <DistributionText>Absolutely</DistributionText>
              </BottomDistribution>
            </DistributionContainer>
          )}
        </div>

        {selected>0 && !empty ?  <Title2>{Leadershiptitle[selected]} Feedback</Title2> :""}

        {empty ? (
          ""
        ) : (
            <Feedback 
                loading={loading} 
                data={leadershipData}
                selected={selected} />
        )}
        {/*<Dimmer active={loading}>
          {loadingText === "Loading" && <Loader />}{" "}
          <Header as="h2" icon inverted>
            {loadingText}
          </Header>
        </Dimmer> */}
    </div>
  );
};

export default Leadership;

const loading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 35px;
`;

const Title2 = styled.div`
  font-size: 23px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 16px;
`;

const Tab = styled.div`
  display: ${(props) => (props.loading ? "none" : "flex")};
  font-size: 20px;
  color: ${(props) => (props.selected ? "#ffb812" : "#9b9b9b")};
  font-family: "Red Hat Display", sans-serif;
  margin-right: 24px;
  cursor: pointer;
  border-bottom: ${(props) => (props.selected ? "3px solid #ffb743" : "none")};
  font-weight: ${(props) => (props.selected ? "bold" : "400")};
  transition: color 500ms, border-bottom 500ms;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

const ScoreCard = styled.div`
  height: 250px;
  width: 100%;
  padding: 20px;
  background-image: linear-gradient(94deg, #ffb347 2%, #ffcc33 109%);
`;

const CardText = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
`;

const Card = styled.div`
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  border-radius: 8px;
  display: inline-block;
  flex-direction: column;
  width: 360px;
  overflow: hidden;
  position: absolute;
`;

const LoadingCard = styled.div`
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  border-radius: 8px;
  display: inline-block;
  flex-direction: column;
  width: 360px;
  height: 300px;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
`;

const DistributionContainer = styled.div`
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  border-radius: 8px;
  width: calc(100% - 380px);
`;

const EmptyDistributionContainer = styled.div`
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  border-radius: 8px;
  width: calc(100% - 380px);
  height: 300px;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
`;

const CardP = styled.div`
  font-size: 16px;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 10px;
`;

const CardBottom = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const View = styled.div`
  color: #ffb812;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Score = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 15px;
`;

const SubScore = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
`;

const Arrow = styled.span`
  font-size: 25px;
  margin-left: 15px;
`;

const DistributionTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

const DistributionSub = styled.div`
  font-size: 16px;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 10px;
`;

const TopDistribution = styled.div`
  height: 250px;
`;

const BottomDistribution = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px;
`;

const DistributionText = styled.div`
  font-size: 11px;
  font-weight: 900;
  color: #4a4a4a;
  text-transform: uppercase;
  font-family: "Red Hat Display", sans-serif;
`;

const Update = styled.div`
  height: 150px;
  border-radius: 8px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  width: calc(33% - 36px);
  padding: 33px 20px 33px 20px;
  display: flex;
  flex-direction: row;
`;

const EmptyUpdate = styled.div`
  height: 150px;
  border-radius: 8px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  width: calc(33% - 36px);
  padding: 33px 20px 33px 20px;
  display: flex;
  flex-direction: row;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
`;
