import styled, { keyframes } from "styled-components";
import React from "react";
import emotions from "../../../data/emotions.json";
import { Icon, Modal } from "semantic-ui-react";

import { tabs, CultureTitle, HeadingList } from "data/leadership";

import culture_trends from "../../../data/culture_trends.json";

const Hover = (props) => {
  return (
    <Hovering left={props.left} top={props.top}>
      <HoverContainer>
        {props.score}%
        <Arrow />
      </HoverContainer>
    </Hovering>
  );
};

function catmullRom2bezier(points) {
  var result = [];
  for (var i = 0; i < points.length - 1; i++) {
    var p = [];

    p.push({
      x: points[Math.max(i - 1, 0)].x,
      y: points[Math.max(i - 1, 0)].y,
    });
    p.push({
      x: points[i].x,
      y: points[i].y,
    });
    p.push({
      x: points[i + 1].x,
      y: points[i + 1].y,
    });
    p.push({
      x: points[Math.min(i + 2, points.length - 1)].x,
      y: points[Math.min(i + 2, points.length - 1)].y,
    });

    // Catmull-Rom to Cubic Bezier conversion matrix
    //    0       1       0       0
    //  -1/6      1      1/6      0
    //    0      1/6      1     -1/6
    //    0       0       1       0

    var bp = [];
    bp.push({
      x: (-p[0].x + 6 * p[1].x + p[2].x) / 6,
      y: (-p[0].y + 6 * p[1].y + p[2].y) / 6,
    });
    bp.push({
      x: (p[1].x + 6 * p[2].x - p[3].x) / 6,
      y: (p[1].y + 6 * p[2].y - p[3].y) / 6,
    });
    bp.push({
      x: p[2].x,
      y: p[2].y,
    });
    result.push(bp);
  }

  return result;
}

function makePath(points) {
  if (points.length === 0) {
    return null
  }
  var result = "M" + points[0].x + "," + points[0].y + " ";
  var catmull = catmullRom2bezier(points);
  for (var i = 0; i < catmull.length; i++) {
    result +=
      "C" +
      catmull[i][0].x +
      "," +
      catmull[i][0].y +
      " " +
      catmull[i][1].x +
      "," +
      catmull[i][1].y +
      " " +
      catmull[i][2].x +
      "," +
      catmull[i][2].y +
      " ";
  }
  return result;
}

const height = 300;
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// const title = [
//   "Trust",
//   "Communication",
//   "Alignment",
//   "Resilience",
//   "Clarity",
//   "Impact",
// ];

const TrendsChart = (props) => {
  const { culture_data, employee_id } = props;
  const [teamData, setTeamData] = React.useState([]);
  const [index, setIndex] = React.useState(CultureTitle.indexOf(props.type));
  const [type, setType] = React.useState(CultureTitle[index]);

  const [open, setOpen] = React.useState(false);
  const [position, setPosition] = React.useState(0);
  const [width, setWidth] = React.useState(1000);

  React.useEffect(() => {
    const _TrendRow = [];

    CultureTitle.map((cl) => {
      const _titleRow = [];
      culture_data.map((cd) => {
        let team = cd.questions?.filter((lst) => lst.name === type)[0]?.score;
        // console.log(cd);
        if (cd) {
          let self = cd?.responses
            ?.filter((lst) => lst.employee_id == employee_id)[0]
            ?.responses.filter((lst) => lst.name === type)[0].score;
          _titleRow.push({
            id: cd.id,
            date: cd.on_date,
            team,
            self,
            title: cl,
            employee: employee_id,
          });
        }
        // console.log(_titleRow);
        return _titleRow
      });
      // console.log(_titleRow);
      _TrendRow.push(_titleRow.reverse());
      return _TrendRow;
    });

    // console.log(_TrendRow);
    setTeamData(_TrendRow);
  }, [culture_data, type, employee_id]);


  React.useEffect(() => {
    setType(CultureTitle[index]);
  }, [index]);

  // console.log(culture_trends);
  // console.log(teamData);

  const [coordinates, setCoordinates] = React.useState([])
  const [coordinates2, setCoordinates2] = React.useState([]);
  const [newIndex, setNewIndex] = React.useState(0);
  const [dates, setDate] = React.useState([]);

  React.useEffect(() => {
    if (teamData.length > 0) {
      const calculateCoordinates = (index) => {
      return teamData[index].map((item, i) => item.self);
    };
    const calculateCoordinates2 = (index) => {
      return teamData[index].map((item, i) => item.team);
    };
  
    const calculateDates = (index) => {
      return teamData[index].map((item, i) => [
        months[new Date(item.date).getMonth()],
        new Date(item.date).getDate(),
      ]);
    };
    setCoordinates(calculateCoordinates(newIndex));
    setCoordinates2(calculateCoordinates2(newIndex));
    setDate(calculateDates(newIndex))
    if (newIndex === 0) {
       setPosition(teamData[newIndex].length - 1)
    }
   

    }
    
    // setCoordinates(calculateCoordinates(newIndex < 3 ? newIndex : 2));
    // setCoordinates2(calculateCoordinates2(newIndex < 3 ? newIndex : 2));

  }, [teamData, newIndex]);

  // console.log(dates);

  const pathBuilder2 = (graph) => {
    var points = [];
    for (var i = 0; i < graph.length; i++) {
      if (graph[i]) {
        points.push({
          x: i * 12.9 + 8,
          y: ((graph[i] * height) / 100) * -1 - 40 + height + graph[i] * 0.8,
        });
      }
    }
    return points;
  };

  const load = (dir) => {
    // console.log("dir", position, coordinates.length)
    if (dir < 0) {
      setPosition(position == 0 ? position : position - 1);
    } else {
      setPosition(position == coordinates.length - 1 ? position : position + 1);
    }
  };

  const move = (i) => {
    setPosition(i);
  };

  const forward = () => {
  //  console.log("forward", newIndex, index)
    if (newIndex < 5){
      setIndex(newIndex+1);
      setNewIndex(newIndex+1)
    }
    // setIndex(newIndex);
    
  };

  const backward = () => {
    // let newIndex = index == 0 ? 5 : index - 1;
    // console.log("backward", newIndex, index)
    if (newIndex > 0){
      setIndex(newIndex-1);
      setNewIndex(newIndex-1)
    }
    
    // setNewIndex()
  };

  return (
    <Modal
      closeIcon
      open={open}
      trigger={props.trigger}
      centered={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="large"
    >
      <div style={{ width: "100%" }}>
        <Card>
          <div style={{ height: 60, position: "relative" }}>
            <Trends>{CultureTitle[index]} Scores</Trends>
            <Selects>
              <Selection />
              <SelectState>My Score</SelectState>
              <Score>{coordinates && coordinates[position]}%</Score>

              <SelectionTeam />
              <SelectState>Team Average</SelectState>
              <Score>{coordinates2 && coordinates2[position]}%</Score>
            </Selects>
          </div>

          <div style={{ position: "absolute", top: 40, right: 40 }}>
            <Icon
              name="chevron left"
              onClick={() => load(-1)}
              size={"small"}
              style={{ fontSize: 30, cursor: "pointer", zIndex: 2 }}
            />
            <Icon
              name="chevron right"
              onClick={() => load(1)}
              size={"small"}
              style={{ fontSize: 30, cursor: "pointer", zIndex: 2 }}
            />
          </div>
          <div style={{height:300,position:'relative'}} >
			 
	      	<svg width={'100%'} height={300} style={{position:"absolute",left:0,top:0}}>

	      	<line x1="5.5%" y1={height-35} x2={'100%'} y2={height-35} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	      	<line x1="5.5%" y1={height-40-1*(height-80)/5} x2={'100%'} y2={height-40-1*(height-80)/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-40-2*(height-80)/5} x2={'100%'} y2={height-40-2*(height-80)/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-40-3*(height-80)/5} x2={'100%'} y2={height-40-3*(height-80)/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-40-4*(height-80)/5} x2={'100%'} y2={height-40-4*(height-80)/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="5.5%" y1={height-40-5*(height-80)/5} x2={'100%'} y2={height-40-5*(height-80)/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>

	       	<text x="0" y={height-35} fill="grey">0%</text>
	       	<text x="0" y={height-35-1*(height-80)/5} fill="grey">20%</text>
	       	<text x="0" y={height-35-2*(height-80)/5} fill="grey">40%</text>
	       	<text x="0" y={height-35-3*(height-80)/5} fill="grey">60%</text>
	       	<text x="0" y={height-35-4*(height-80)/5} fill="grey">80%</text>
	       	<text x="0" y={height-35-5*(height-80)/5} fill="grey">100%</text>
	       </svg>

	       <Select left={position*12.9+5+'%'} height={height+20}/>

           <svg width={'100%'} height={300} viewBox={"0 0 100 "+height} preserveAspectRatio="none" style={{position:"absolute",left:0,top:0}}>
	      		 <defs>
			    <linearGradient id="myGradient" gradientTransform="rotate(90)">
			    			      <stop offset="5%"  stopColor="#ffb347" />
			      <stop offset="95%" stopColor="#ffcc33" />
			    </linearGradient>
			  </defs>
	      	<path d={makePath(pathBuilder2(coordinates))} stroke="url('#myGradient')" fill="transparent" strokeWidth="4" vectorEffect="non-scaling-stroke" style={{transition:'0.4s'}}/>
	      </svg> 

	       <svg width={'100%'} height={300} viewBox={"0 0 100 "+height} preserveAspectRatio="none" style={{position:"absolute",left:0,top:0}}>
	      		 <defs>
			    <linearGradient id="myGradient2" gradientTransform="rotate(90)">
			      <stop offset="5%"  stopColor="#ffb347" />
			      <stop offset="95%" stopColor="#ffcc33" />
			    </linearGradient>
			  </defs>
	      	<path d={makePath(pathBuilder2(coordinates2))} stroke="url('#myGradient2')" fill="transparent" strokeWidth="3" vectorEffect="non-scaling-stroke" strokeDasharray="12 6" style={{transition:'0.4s'}}/>
	      </svg> 


	        <svg width={'100%'} height={300} style={{position:"absolute",left:0,top:0}}>
	       	    	
	       	    	{dates.map((item,i)=>{
			       		return (
			       			<React.Fragment key={i}>
			       				<text x={i*12.9+6.5+'%'} y={height-20} fill={position==i?"black":"grey"} fontWeight="600" style={{transition:'fill .6s ease',fontSize:16}}>{dates[i][0]}</text>
			       				<text x={i*12.9+7+'%'} y={height} fill={position==i?"black":"grey"} fontWeight="600" style={{transition:'fill .6s ease',fontSize:16}}>{dates[i][1]}</text>
			       			</React.Fragment>
			       			)
			       	})}

	       	{coordinates.map((item,i)=>{
	       		if(!item){
	       				return 
	       			}

	       		if(i!=position){

	       			return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="4" fill="rgb(186, 186, 186)" style={{transition:'.4s ease'}}/>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="8" fill="rgba(186, 186, 186,0.3)" style={{transition:'.4s ease'}} />
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="12" fill="rgba(186, 186, 186,0.1)" onClick={()=>move(i)} style={{transition:'.4s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       		}
	       		return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="4" fill="#ffb247" style={{transition:'.4s ease'}}/>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="8" fill="rgba(255, 178, 71,0.3)" style={{transition:'.4s ease'}}/>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="12" fill="rgba(255, 178, 71,0.1)" onClick={()=>move(i)} style={{transition:'.4s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       	})}

	       		{coordinates2.map((item,i)=>{
	       			if(!item){
	       				return 
	       			}
	       		if(i!=position){
	       			return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="4" fill="rgb(186, 186, 186)" style={{transition:'.4s ease'}}/>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="8" fill="rgba(186, 186, 186,0.3)" style={{transition:'.4s ease'}} />
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="12" fill="rgba(186, 186, 186,0.1)" onClick={()=>move(i)} style={{transition:'.4s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       		}
	       		return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="4" fill="#ffb247" style={{transition:'.4s ease'}}/>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="8" fill="rgba(255, 178, 71,0.3)" style={{transition:'.4s ease'}}/>
	       			<circle cx={i*12.9+8+'%'} cy={(item * height/100 * -1-40 + height+item*0.8)/height*100+'%'} r="12" fill="rgba(255, 178, 71,0.1)" onClick={()=>move(i)} style={{transition:'.4s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       	})}

	    
	       		
	      	 </svg>

	      		
	      	 </div>

        
          <div
            style={{
              position: "absolute",
              bottom: -100,
              left: "calc(50% - 150px)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: 300,
              justifyContent: "space-between",
            }}
          >
            <IconArrow
            disabled={newIndex ===5? true :false}>
              <Icon
                name="chevron left"
                disabled={newIndex ===0? true :false}
                onClick={() => backward()}
                size={"small"}
                style={{ fontSize: 20, 
                  cursor: newIndex === 0? "not-allowed" :"pointer" ,
                  zIndex: 2 }}
              />
            </IconArrow>

            <BottomText>{CultureTitle[index]}</BottomText>
            <IconArrow
            disabled={newIndex ===5? true :false}>
              <Icon
                name="chevron right"
                onClick={() => forward()}
                size={"small"}
                disabled={newIndex ===5? true :false}
                style={{
                  fontSize: 20,
                  cursor: newIndex === 5? "not-allowed" :"pointer" ,
                  zIndex: 2,
                  marginLeft: 5,
                }}
              />
            </IconArrow>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default TrendsChart;

const appear = keyframes`
	0% {opacity:0;left:0}
  100% {opacity:0.7;height:40px;width:70px}
`;

const Card = styled.div`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  position: relative;
  width: 100%;
`;

const Selects = styled.div`
  padding: 10px;
  position: absolute;
  top: -15px;
  left: calc(50% - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
const Select = styled.div`
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0px;
  margin-left: ${(props) => props.left};
  height: ${(props) => props.height + "px"};
  width: 60px;
  transition: margin 500ms;
  background-color: white;
`;

const Overall = styled.div`
  font-size: 11px;
  font-weight: 900;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  text-transform: uppercase;
`;

const SubOverall = styled.div`
  font-size: 11px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #6a00ff;
`;

const Trends = styled.div`
  font-size: 23px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;
const HoverContainer = styled.div`
  width: 70px;
  height: 40px;
  opacity: 0.7;
  box-shadow: 0 3px 20px 0 #d3d3d3;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  font-family: "Red Hat Display", sans-serif;
`;

const Score = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: black;
  font-family: "Red Hat Display", sans-serif;
  margin-right: 40px;
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid black;
  position: absolute;
  bottom: -10px;
`;

const SelectState = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  margin-right: 5px;
`;

const Oval = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.color};
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 14px;
  min-width: 24px;
`;

const Inner = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-image: ${(props) => (props.color ? props.color : "white")};
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Hovering = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  height: 40px;
  animation: ${appear} 500ms linear 0s 1 forwards;
`;

const Selection = styled.div`
  width: 30px;
  border-bottom: 4px solid #2193b0;
  margin-right: 10px;
`;
const SelectionTeam = styled.div`
  width: 30px;
  border-bottom: 4px dashed #2193b0;
  margin-right: 10px;
`;

const IconArrow = styled.div`
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BottomText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: white;
  font-family: "Red Hat Display", sans-serif;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;
