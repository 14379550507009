import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon,Label} from 'semantic-ui-react'


const Box = ({title,description,icon,onClick,num}) =>{

    return (
        <Container onClick={()=>onClick()}>
            <IconContainer2>
                <Icon name={icon} style={{margin:0}}/>
            </IconContainer2>
            
            <TextContainer>
                <Title>{title} {num?<Label style={{marginLeft:20}} color={'grey'} size="tiny">{num}</Label>:""}</Title>
                <Description>{description}</Description>
            </TextContainer>

            <IconContainer>
                <Icon name={'chevron right'} />
            </IconContainer>
            
        </Container>
    )

}

export default Box

const Container = styled.div`
    width:100%;
    padding:20px 10px;
    border-radius:5px;
    border:1px solid #E0E0E0;
    display:flex;
    align-items:center;
    margin-bottom:10px;
    position:relative;

    cursor:pointer;

    &:hover{
        border:1px solid #2A3039;
        color:#2A3039;
    }
`

const TextContainer = styled.div`
    margin-left:20px;
`
const Title = styled.div`
    font-size:14px;
    font-weight:600;
    color#2A3039;
    margin-bottom:5px;
    display:flex;
    align-items:center;
`

const Description = styled.div`
    font-size:12px;
`

const IconContainer = styled.div`
    position:absolute;
    right:10px;
    color:#E0E0E0;
`

const IconContainer2 = styled.div`
    padding:10px;
    border-radius:5px;
    background-color:#2D70E2;
    color:white;

`