import React from "react";
import styled from "styled-components";
import Pic from "assets/images/empty/cloud-computing.png";

const Empty = () => {
	return (
		<Container>
			<Img src={Pic} />
			<Text>
				There's <strong>no data</strong> collected for this section yet. Please
				check back once more data has been populated.{" "}
			</Text>
		</Container>
	);
};

export default Empty;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 50px;
	margin-bottom: 50px;
`;

const Img = styled.img`
	width: 100px;
	max-width: 100%;
`;

const Text = styled.div`
	font-family: "Barlow", sans-serif;
	font-size: 18px;
	max-width: 500px;
	line-height: 1.5;
`;
