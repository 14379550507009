import React, { useState } from 'react';
import { Table, Label,Button  } from 'semantic-ui-react';
import styled from 'styled-components';

const PredictionSlider = ({ factors,outcomeName }) => {
    const initialScores = factors.reduce((acc, factor) => {
        acc[factor.index.title] = factor.average_score;
        return acc;
      }, {});
    
      const [scores, setScores] = useState(initialScores);
    
      const calculateUpperCI = (score, se) => score + 1.96 * se;
    
      const calculatePredictedImpact = () => {
        return factors.reduce((acc, factor) => {
          const originalScore = factor.average_score;
          const currentScore = scores[factor.index.title];

    
          if (currentScore !== originalScore) {
            const upperCI = calculateUpperCI(currentScore, factor.SE);
            const changeInScore = upperCI - originalScore;
            return acc + changeInScore * factor.coefficient;
          }
    
          return acc;
        }, 0);
      };

      const calculatePredictedLowerImpact = () => {
        return factors.reduce((acc, factor) => {
          const originalScore = factor.average_score;
          const currentScore = scores[factor.index.title];
    
          if (currentScore !== originalScore) {
            const lowerCI = currentScore - 1.96 * factor.SE;
            const changeInScore = lowerCI - originalScore;
            return acc + changeInScore * factor.coefficient;
          }
    
          return acc;
        }, 0);
      };
    
      const calculateScoreChangePercent = (original, current) => {
        return (current - original)  * 10;
      };


      const calculateOutcomeChangePercent = (original, current) => {
        console.log(original)
        if(original.average_score == current){
            return 0
        }

        const upperCI = calculateUpperCI(current, original.SE);
        const lowerCI = original.average_score - 1.96 * original.SE;
        const changeInScore = upperCI - original.average_score;
        const changeInScoreLower = lowerCI - original.average_score;

        const changeInScorePercent = (changeInScore * original.coefficient)  * 10;
       
        return changeInScorePercent
      };


      const calculateOutcomeChangePercentText = (original, current) => {

        if(original.average_score == current){
            return 0
        }

        const upperCI = calculateUpperCI(current, original.SE);
        const lowerCI = current - 1.96 * original.SE;
        const changeInScore = upperCI - original.average_score;
        const changeInScoreLower = lowerCI - original.average_score;

        const changeInScorePercent = ((changeInScore * original.coefficient)  * 10).toFixed(1);
        const changeInScorePercentLower = ((changeInScoreLower * original.coefficient)  * 10).toFixed(1);

        return `${changeInScorePercentLower>0?changeInScorePercentLower : 0} to ${changeInScorePercent}`
      };
    
      const handleSliderChange = (title, value) => {
        setScores({
          ...scores,
          [title]: value,
        });
      };
    
      const handleResetScores = () => {
        setScores(initialScores);
      };
    
      const handleAddPercentage = (percentage) => {
        const newScores = {};
        for (const key in scores) {
          newScores[key] = scores[key] + percentage / 10
        }
        setScores(newScores);
      };
    
      const predictedImpact = calculatePredictedImpact();
      const predictedLowerImpact = calculatePredictedLowerImpact()>0? calculatePredictedLowerImpact(): 0;
    
      // Calculate the minimum value for the sliders
      const minScore = Math.min(
        5,
        ...factors.map((factor) => factor.average_score)
      );

      const getColor = (predicted,change) => {
        let percentage = change / (predicted*10)
        if(percentage > 0.1){
            return 'green'
        }

        return 'grey'
      }

  return (
    <Container>
      <Title>Predict how your culture factors will impact {outcomeName}</Title>
      <Description>
        Adjust the sliders to see how changing the scores of each factor will impact the predicted {outcomeName} score.
      </Description>
      <div style={{ marginBottom: '20px',display:'flex',width:'100%', justifyContent:'flex-end' }}>
        <Button onClick={() => handleAddPercentage(5)} size="mini">Add 5% to All Factors</Button>
        <Button onClick={() => handleAddPercentage(10)} size="mini">Add 10% to All Factors</Button>
        <Button onClick={handleResetScores} size="mini">Reset Scores</Button>
      </div>
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Factor Name</Table.HeaderCell>
            <Table.HeaderCell>Adjust Score</Table.HeaderCell>
            <Table.HeaderCell>Current Score</Table.HeaderCell>
            <Table.HeaderCell>Factor Score Change</Table.HeaderCell>
            <Table.HeaderCell>Potential ROI</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {factors.sort((a,b)=>a.average_score - b.average_score).map((factor) => (
            <Table.Row key={factor.index.title}>
              <Table.Cell>{factor.index.title}</Table.Cell>
              <Table.Cell>
                <SliderContainer>
                  <SliderInput
                    type="range"
                    min={minScore}
                    max="10"
                    step="0.01"
                    value={scores[factor.index.title]}
                    onChange={(e) =>
                      handleSliderChange(factor.index.title, parseFloat(e.target.value))
                    }
                  />
                </SliderContainer>
              </Table.Cell>
              <Table.Cell>
                <Label>{(factor.average_score*10).toFixed(1)}%</Label>
              </Table.Cell>
              <Table.Cell>
                <Label>
                  {(scores[factor.index.title]*10).toFixed(1)}% ({calculateScoreChangePercent(factor.average_score, scores[factor.index.title]).toFixed(0)}%)
                </Label>
              </Table.Cell>

              <Table.Cell>
                <Label color={getColor(predictedImpact,calculateOutcomeChangePercent(factor, scores[factor.index.title]).toFixed(0))}>
                 {calculateOutcomeChangePercentText(factor, scores[factor.index.title])}%
                </Label>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4" textAlign="right">
              <h3>Predicted {outcomeName} score change: {(predictedLowerImpact*10).toFixed(2)}% to {(predictedImpact*10).toFixed(2)}%</h3>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </Container>
  );
};

export default PredictionSlider;

const Container = styled.div`
    padding:20px;
    font-family:"Raleway";
`;

const Title = styled.div`
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
`

const Description = styled.div`
    margin-bottom: 20px;
`

// Styled-components for the custom slider
const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const SliderInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2D70E2;
    cursor: pointer;
    transition: background 0.2s;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #2D70E2;
    cursor: pointer;
    transition: background 0.2s;
  }
`;