import React from "react";
import styled from "styled-components";
import Down from "assets/images/UI/down-small.svg";
import Up from "assets/images/UI/up-small.svg";

function hexToRGBA(hex, alpha = 1) {
  // Ensure the hex color code starts with a '#'
  if (hex.charAt(0) !== "#") {
    throw new Error("Invalid hex color code format");
  }

  // Remove the '#' from the hex color code
  const cleanHex = hex.slice(1);

  // Check if the hex color code has a valid length (either 3 or 6 characters)
  if (cleanHex.length !== 3 && cleanHex.length !== 6) {
    throw new Error("Invalid hex color code length");
  }

  // Expand the 3-character hex color code to a 6-character one if necessary
  const finalHex =
    cleanHex.length === 3
      ? cleanHex
          .split("")
          .map((char) => char + char)
          .join("")
      : cleanHex;

  // Extract the red, green, and blue values from the hex color code
  const r = parseInt(finalHex.slice(0, 2), 16);
  const g = parseInt(finalHex.slice(2, 4), 16);
  const b = parseInt(finalHex.slice(4, 6), 16);

  // Return the RGBA color value as a string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const Dial = ({
  strokeWidth = 3,
  borderPercentage = 25,
  borderColor = "#2D70E2",
}) => {
  const radius = 50;
  const innerRadius = radius * 0.8;
  const borderCircleRadius = radius + strokeWidth;
  const circleCircumference = 2 * Math.PI * borderCircleRadius;

  const borderLength =
    (borderPercentage / 100) * 270 * ((circleCircumference - 20) / 360);

  const getCirclePosition = (angle, radius) => {
    const x = 50 + radius * Math.cos((angle - 90) * (Math.PI / 180));
    const y = 50 + radius * Math.sin((angle - 90) * (Math.PI / 180));
    return { x, y };
  };
  const circleAngle = 225 + ((borderPercentage + 2) / 100) * 266;
  const circlePosition = getCirclePosition(
    circleAngle,
    borderCircleRadius - strokeWidth / 2
  );

  return (
    <svg width="80%" height="80%" viewBox="-10 -10 120 120" fill="none">
      <circle cx="50" cy="50" r={radius} fill={borderColor} opacity={0.1} />

      <path
        d="M50 50 L50 0 A50 50 0 0 1 101 50 Z"
        fill="#ffffff"
        transform="rotate(134.5, 50, 50)"
      />
      <circle
        cx="50"
        cy="50"
        r={borderCircleRadius - strokeWidth / 2}
        fill="none"
        stroke="#ffffff"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={`${borderLength} ${
          circleCircumference - borderLength
        }`}
        transform="rotate(135, 50, 50)"
      />
      <circle
        cx="50"
        cy="50"
        r={borderCircleRadius - strokeWidth / 2}
        fill="none"
        stroke={borderColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={`${borderLength} ${
          circleCircumference - borderLength
        }`}
        transform="rotate(135, 50, 50)"
      />
      <circle
        cx="50"
        cy="50"
        r={innerRadius}
        fill="#ffffff"
        filter={`drop-shadow(0px 8px 20px ${hexToRGBA(borderColor, 0.08)})`}
      />
      <text
        x="50"
        y="50"
        text-anchor="middle"
        alignment-baseline="central"
        font-size="24"
        font-weight="bold"
        fill="#000"
        font-family="Raleway, sans-serif"
      >
        {!isNaN(borderPercentage) ? `${borderPercentage}%` : "--"}
      </text>
      <circle
        cx={circlePosition.x}
        cy={circlePosition.y}
        r="4"
        fill="none"
        stroke={borderColor}
        strokeWidth="2"
      />
    </svg>
  );
};

const ScoreContainer = ({
  title,
  change,
  score,
  color,
  bgColor,
  addMargin,
}) => {
  return (
    <Container bgColor={bgColor} addMargin={addMargin}>
      <Title>Overall Culture Score</Title>
      <Bottom>
        <ChangeMetrics>
          <Change
            color={
              change > 0
                ? "#1D9A7D"
                : change && !isNaN(change)
                ? "#F15062"
                : "#000"
            }
          >
            {(change && (
              <>
                <Pic src={change < 0 ? Down : Up} /> {change > 0 ? "+" : ""}
                {change} %
              </>
            )) || <>--</>}
          </Change>
          <Sub>vs last assessment</Sub>
        </ChangeMetrics>

        <DialContainer>
          <Dial borderPercentage={score} borderColor={color} />
        </DialContainer>
      </Bottom>
    </Container>
  );
};

export default ScoreContainer;

const Container = styled.div`
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 24px;
  padding-right: 5px;
  background-color: #f8faff;
  min-width: 340px;
  margin: ${(props) => (props.addMargin ? "0 10px 10px 0" : "0")};
  max-height: 155px;
  min-height: 155px;
  border-radius: 8px;
`;

const Title = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #2a3039;
  margin-bottom: 25px;
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Change = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${(props) => props.color};
`;

const Sub = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #666d79;
`;

const Pic = styled.img`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
`;

const ChangeMetrics = styled.div`
  width: 50%;
  margin-right: 15px;
`;

const DialContainer = styled.div`
  width: 110px;
  height: 100px;
  bottom: 10px;
`;
