import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'
import Chart from "react-apexcharts";

const Component2 = () =>{

    return (
        <Container>

            <T1>What to focus on improving first?</T1>
            <Text delay={0.5}>
            Based on your last survey, your team's psychological safety score is 6.7. This is lower than your organization's average of 7.1.
            We predict that you can <strong>improve your team's psychological safety scores by over 10%</strong> following this data journey.
            </Text>

            <ChartContainer delay={1}>
            <Chart
						options={data3.options}
						series={data3.series}
						type="bar"
						height={350}
					/>
            </ChartContainer>
               
        </Container>
    )
}

export default Component2

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    max-width:500px;
    line-height:1.5;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const ChartContainer = styled.div`
    width:100%;
   
    margin-top:40px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;

    animation: ${fadeIn} 1s ease-out forwards;
`

const IconContainer = styled.div`
    width:30px;
    height:30px;
    background-color:#2A3039;
    color:white;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:20px;
    min-width:30px;
`

const data3 = {
	series: [
		{
			data: [6.7, 7.1, 7.7],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 600,
			height: 400,
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              columnWidth: '75px',
              distributed: true,
            }
          },
		colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
		xaxis: {
			categories: [
				"Your Team",
                "Your Organization",
                "Your team after data journey"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 10,
			decimalsInFloat: 1,
			title: {
				text: "Psychological Safety Scores",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
