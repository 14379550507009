import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import {Table,Icon, Step,Progress} from 'semantic-ui-react'
import { useNavigate,useParams } from 'react-router-dom';

import data from './data'
import AddUser from './AddUser'


const steps =['Access','Planned','Check-ins','Review']

const Accountability = () =>{
    const navigate = useNavigate()
    let { myParam } = useParams();
    const [users,setUsers] = useState([])
    const [sortDir,setSortDir] = useState('desc')
    const [selected,setSelected] = useState('')
    const [open,setOpen] = useState(myParam=='success'?true:false)
    


    useEffect(()=>{
        if(users.length == 0){
            setUsers(data.sort((a,b)=>b.progress - a.progress))
        }
    },[])

    useEffect(()=>{
        if(selected == ''){
            setUsers(data.sort((a,b)=>b.progress - a.progress))
        }else{
            setUsers(data.filter(user=>user.filters.includes(selected)).sort((a,b)=>b.progress - a.progress))
        }
    },[selected])

    const sort = () => {
      if (sortDir == "desc") {
        setUsers(users.sort((a, b) => a.progress - b.progress));
        setSortDir("asc");
      } else {
        setUsers(users.sort((a, b) => b.progress - a.progress));
        setSortDir("desc");
      }
    };

    return (
        <Container>
            <Center>
          
            <Title>Data sharing and accountability <AddUser/></Title>
            <Description>Share key insights, data stories, and recommendations and keep track of important tasks.</Description>
            
            <TableTop>
                <T1 width={30}>Member</T1>
                <T1 width={20}>Data access</T1>
                <T1 width={20}>Status</T1>
                <T1 width={20}>Last login</T1>
                <T1 width={10}></T1>
            </TableTop>

            <Table>

            {users?.map((user,i)=>{
                return (
                    <TR>
                    <TD width={30}>
                        {user.name.first} {user.name.last}
                    </TD>
                    <TD width={20}>
                        {user.filters[0]}
                    </TD>
                    <TD width={20}>
                        Registered
                    </TD>
                    <TD width={20}>
                        May 20th, 2024
                    </TD>

                    <TD width={10} onClick={()=>navigate(`/leader/prototype/userprofile/${user.id.name}`)}>
                       Actions
                    </TD>

                </TR>
                )
            })}
            </Table>


{/*             
            {users?.map((user,i)=>{
                return <User key={i} user={user}/>

            }
            )} */}
            </Center>

        
            
        </Container>
    )

}

const User = ({user}) =>{
    const navigate = useNavigate();
    return (
        <UserContainer onClick={()=>navigate(`/leader/prototype/userprofile/${user.id.name}`)}>
            <MemberArea> 
                <UserImage src={user.picture.large}/>
                <div>
                    <UserName>{user.name.first} {user.name.last} </UserName>
                    <div style={{display:'flex'}}>
                    <Dep>{user.filters[0]}</Dep>
                    </div>
                </div>
            </MemberArea>
            

            <Progress 
                percent={user.progress}  
                progress 
                size='small'
                color="blue"
                style={{width:'60%'}}/>


            <Icon name="chevron right" style={{fontSize:14,color:"#B3B3B3",position:'absolute',right:-40,top:10}}/>
            
        </UserContainer>
    )
} 

export default Accountability

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    margin-top:40px;
    font-family:'Raleway';
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
`

const Center = styled.div`
    width:100%;
    max-width:800px;
    display:flex;
    flex-direction:column;

    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;

`

const Description = styled.div`
    font-size:14px;
    margin-bottom:40px;
`

const Tag = styled.div`
    background-color:#DFDFDF;
    color:#666D79;
    padding:5px;
    display:flex;
    font-size:12px;
    border-radius:5px;
    align-items:center;
    justify-content:center;
`

const Button = styled.div`
    color:white;
    background-color:#2D70E2;
    font-size:16px;
    padding:10px 20px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    font-family:'Raleway';
    margin-bottom:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
`

const UserContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    position:relative; 
`
const UserImage = styled.img`
    width:40px;
    height:40px;
    border-radius:50%;
    margin-right:20px;
`

const UserName = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-right:20px;
    display:flex;
    align-items:center;
`

const UserEmail = styled.div`
    font-size:14px;
    color:#666D79;
    margin-right:20px;
`
const UserProgress = styled(Progress)`
    width:200px;
    margin-right:40px !important;
    margin-bottom:0px !important;
    margin-top:0px !important;
    padding:0;
`
const UserStatus = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;

`


const TableTop = styled.div`
    width:100%;
    display:flex;
    align-items:center;
`
const T1 = styled.div`
    font-size:14px;
    font-weight:bold;
    width:${props=>props.width}%;

    display:flex;
    padding:0px 10px;
`

const Dep = styled.div`
    font-size:12px;
    color:#666D79;
    margin-top:0px;
`

const ProgressArea = styled.div`
    width:60%;
    height:100%;
    display:flex;
    align-items:center;
`

const MemberArea = styled.div`
    width:40%;
    display:flex;
    align-items:center;
`

const Options = styled.div`
    display:flex;
    align-items:center;
    width:400px;
    justify-content:flex-end;
`

const Row = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width:100%;
    margin-bottom:40px;
`

const IconContainer = styled.div`
   
    margin-left:10px;
    cursor:pointer;
`

const TR = styled.div`
    display:flex;
    align-items:center;
    width:100%;
    padding:20px 0;
    border-bottom:1px solid #E0E0E0;
`

const TD = styled.div`
    width:${props=>props.width}%;
    font-size:14px;
    display:flex;
    align-items:center;
    padding:0px 10px;
`