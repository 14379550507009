import React from "react";
import DatePick from "./DatePicker";

const DateSelector = (props) => {
  const useDate =
    props?.date ||
    (!Array.isArray(props?.data?.planData?.[props.id]) &&
      props?.data?.planData?.[props.id]) ||
    new Date();

  return (
    <DatePick
      handleDateChange={(date) =>
        props.handleDatesSelected(date, props.id, props?.dateIndex)
      }
      {...props}
      date={useDate}
    />
  );
};

export default DateSelector;
