import React, { useEffect, useCallback, useState, useRef } from "react";
import { Icon, Modal, Dropdown } from "semantic-ui-react";
import styled, { keyframes } from "styled-components";
import Query from "./Query";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import AwaitingQuery from "./AwaitingQuery";
import useAutoHeight from "./useAutoHeight";
import {
  SAGA_START_AND_MESSAGE_WEBSOCKET,
  SAGA_MESSAGE_WEBSOCKET,
  SAGA_CONNECT_WEBSOCKET,
  DISCONNECT_WEBSOCKET,
  SET_WEB_CHAT_MESSAGES,
  ADD_PENDING_MESSAGE,
  SET_SELECTED_QUESTION,
  SET_NEW_FILTERS,
} from "constants/actions";
import { useToasts } from "react-toast-notifications";

import { closeIcon } from "components/Icons";

// To add some configurable Co-pilot prompts and options, we can define them here.
const PAGE_OPTIONS = [
  {
    id: 0,
    page: "/dashboard",
    options: [],
  },
  {
    id: 1,
    page: "/benchmarks",
    options: [
      {
        id: 0,
        prompt:
          "What are the key themes that help to explain the differences between these groups?",
        descriptionTitle: "Describe what this means",
        description:
          "What are the key themes that help to explain the differences between these groups?",
      },
    ],
  },
  {
    id: 2,
    page: "/change",
    options: [
      {
        id: 0,
        prompt:
          "What are the key themes that help to explain the differences between these groups?",
        descriptionTitle: "Describe what this means",
        description:
          "What are the key themes that help to explain the differences between these groups?",
      },
    ],
  },
  {
    id: 3,
    page: "/insights",
    options: [],
    initiateFromPage: true,
  },
  {
    id: 4,
    page: "/feedback",
    options: [
      {
        id: 0,
        prompt:
          "What are the key themes that help to explain the differences between these groups?",
        descriptionTitle: "Describe what this means",
        description:
          "What are the key themes that help to explain the differences between these groups?",
      },
    ],
  },
];

const AICopilot = ({
  open,
  setOpen,
  simplePrompt,
  navigation,
  feedbackData,
  focus,
  isQuestionaire,
}) => {
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const { ref: inputRef, resize } = useAutoHeight(20);
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [topicCreated, setTopicCreated] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [feedback_length, setFeedbackLength] = useState(0);
  const [tags, setTags] = useState([]);
  const [chatInitiated, setChatInitiated] = useState(false);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pageOptions, setPageOptions] = useState();
  const [currentFocus, setCurrentFocus] = useState("");
  const { addToast } = useToasts();

  const {
    get_chat_topics,
    core_data,
    selectedFactors,
    get_web_sock,
    chatToken,
    selectedFilters,
  } = useSelector(
    (state) => ({
      selectedFilters: state.audit?.new_filters,
      lastData: state.audit?.filtered_last_data,
      get_chat_topics: state.ai_chat?.chat_topics,
      get_web_sock: state.ai_websocket,
      chatToken: state.auth.chat_token,
      core_data: state.audit?.core_data,
      selectedFactors: state.audit?.selected,
    }),
    shallowEqual
  );

  useEffect(() => {
    const page = PAGE_OPTIONS.find((option) =>
      window.location.pathname.includes(option.page)
    );
    setPageOptions(page);
  }, [window.location.pathname]);

  useEffect(() => {
    if (!get_web_sock.ws && selectedChatRoom?.id && chatInitiated) {
      console.log("Connecting to websocket");
      dispatch({
        type: SAGA_CONNECT_WEBSOCKET,
        payload: {
          token: chatToken,
          // token,
          name: selectedChatRoom?.name,
          web_chat_id: selectedChatRoom?.id,
        },
      });
    }
    // eslint-disable-next-line
  }, [dispatch, get_web_sock, selectedChatRoom]);

  useEffect(() => {
    if ((feedbackData || core_data?.feedback) && simplePrompt) {
      let feed = "### Feedback\n\n";
      let feedback = feedbackData || core_data?.feedback;
      feedback?.map((item) => {
        if ("prompt_feedback" in item) {
          feed += "Follow up question: " + item?.prompt_question + "\n";
          feed += item?.prompt_feedback;
          return;
        }

        if ("feedback" in item) {
          feed += item?.feedback;
        }

        if (item.follow_up) {
          feed += "\nFollow up: " + item?.follow_up;
        }

        if (item?.response && item?.type === "outcome") {
          feed += item?.response;
        }
        feed += "\n";
      });
      feed += "### End of Feedback\n\n";
      call_API(simplePrompt, feed);
    }
  }, [core_data?.feedback, simplePrompt, feedbackData]);

  useEffect(() => {
    const firstMessage = getCurrentConvo(get_web_sock);

    if (
      firstMessage[
        Object.keys(firstMessage)?.[Object.keys(firstMessage)?.length - 1]
      ]?.server?.response?.length > 0
    ) {
      setSelectedChatRoom({
        id: get_web_sock?.data?.web_chat_id,
        name: get_web_sock?.data?.name,
        params: get_web_sock?.data?.params,
      });
    }
  }, [get_web_sock?.data?.web_chat_id]);

  useEffect(() => {
    // If the message is an update, dont add it to this list.
    if (get_web_sock?.data?.type === "update") {
      return;
    }

    if (
      chatInitiated &&
      (!get_web_sock?.data?.web_chat_id ||
        get_web_sock?.data?.web_chat_id === selectedChatRoom?.id ||
        (get_web_sock?.data?.web_chat_id &&
          isNaN(get_web_sock?.data?.web_chat_id)) ||
        get_web_sock?.channel?.id)
    ) {
      if (
        get_web_sock?.message &&
        getMostRecentMessage(get_web_sock)?.length > 0
      ) {
        setMessages((prev) => {
          return [
            ...prev,
            {
              type: "response",
              message: getMostRecentMessage(get_web_sock),
            },
          ];
        });
        setLoading(false);
      }

      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);
    }
  }, [get_web_sock?.message]);

  function getCurrentConvo(wsData) {
    for (const key in wsData) {
      if (key === "message") {
        return wsData[key];
      }
    }
  }

  useEffect(() => {
    let feedbackLen = feedbackData
      ? feedbackData.length
      : core_data?.feedback?.length;
    setFeedbackLength(feedbackLen);
  }, [core_data?.feedback, feedbackData]);

  function clearChat() {
    setPrompt("");
  }

  function processQuestionnaireFeedback(feedbackData) {
    if (feedbackData) {
      let feed = "### Feedback\n\n";

      feedbackData.map((item) => {
        feed += "Question: " + item.question + "\n";
        feed += "Feedback: " + item.feedback + "\n";

        if (item?.follow_up?.question) {
          feed += "\nFollow up question: " + item?.follow_up?.question + "\n";
          feed += "Follow up feedback: " + item?.follow_up?.feedback + "\n";
        } else if (item?.follow_up && item?.follow_up.length > 0) {
          item.follow_up.map((followUp) => {
            feed += "\nFollow up question: " + followUp.question + "\n";
            feed += "Follow up feedback: " + followUp.feedback + "\n";
          });
        }

        feed += "\n";
      });

      feed += "### End of Feedback\n\n";
      return feed;
    }
  }

  function handlePromptClick(prompt) {
    if (!loading) {
      let feed = "### Feedback\n\n";
      if (feedbackData && isQuestionaire) {
        feed = processQuestionnaireFeedback(feedbackData);
      } else {
        core_data?.feedback?.map((item) => {
          if ("prompt_feedback" in item) {
            feed += "Follow up question: " + item?.prompt_question;
            feed += item?.prompt_feedback;
          }

          if ("feedback" in item) {
            feed += item?.feedback;
          }

          if (item.follow_up) {
            feed += "\nFollow up: " + item?.follow_up;
          }

          if (item?.response && item?.type === "outcome") {
            feed += item?.response;
          }

          feed += "\n\n";
        });
      }
      call_API(prompt, feed);
      setTimeout(() => {
        setPrompt("");
        resize();
      }, 150);
    }
  }

  function handleKeyDown(e) {
    if (!loading) {
      if (e.key === "Enter" && prompt.trim().length !== 0) {
        // setQuery(prompt);
        // Call your function here
        let feed = "### Feedback\n\n";
        if (feedbackData && isQuestionaire) {
          feed = processQuestionnaireFeedback(feedbackData);
        } else {
          core_data?.feedback?.map((item) => {
            if ("prompt_feedback" in item) {
              feed += "Follow up question: " + item?.prompt_question;
              feed += item?.prompt_feedback;
            }

            if ("feedback" in item) {
              feed += item?.feedback;
            }

            if (item.follow_up) {
              feed += "\nFollow up: " + item?.follow_up;
            }

            if (item?.response && item?.type === "outcome") {
              feed += item?.response;
            }

            feed += "\n\n";
          });
        }
        call_API(prompt, feed);
        setTimeout(() => {
          if (e?.preventDefault) {
            e?.preventDefault();
          }
          setPrompt("");
          resize();
        }, 150);
      } else if (e.key === "Enter" && prompt.trim().length === 0) {
        if (e?.preventDefault) {
          e?.preventDefault();
        }
        resize();
      } else if (e.key === "Enter" && prompt.trim().length !== 0 && loading) {
        if (e?.preventDefault) {
          e?.preventDefault();
        }
        resize();
      }
    } else {
      setTimeout(() => {
        setPrompt("");
        resize();
      }, 15);
    }
  }

  // return the most recent message from the ws.messages object
  // the most recent message is the one at the bottom of the object
  function getMostRecentMessage(ws) {
    const keys = Object.keys(ws.message);
    return ws.message[keys[keys.length - 1]]?.server?.response;
  }

  const handleClose = () => {
    if (messages.length > 0) {
      addToast(
        "Your chat has been saved, you may continue this chat from the Co-Pilot page.",
        { appearance: "info" }
      );
    }
    setMessages([]);
    setOpen(false);
    selectedChatRoom?.id && setSelectedChatRoom(null);
    setChatInitiated(false);
    setLoading(false);
    clearChat();
    setTopicCreated(Math.random());
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
  };

  // UseEffect to trigger the call_API function in cases where the prompt is initiated from outside of the chat component.
  const call_API = async (promptQuery, feedbackData) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setChatInitiated(true);

    let params = {
      feedback_length,
      tags,
      factors: selectedFactors,
      date: "",
      //! NOTE: don't overwrite this, as it is an expected param for the API.
      context_data_length: 0,
    };

    // Update the current conversation to add the latest prompt
    setMessages((prev) => {
      return [
        ...prev,
        {
          type: "prompt",
          message: promptQuery,
        },
      ];
    });

    // setUpdatedMessage(false);
    clearChat();

    if (get_web_sock.ws && get_web_sock?.isConnected) {
      const msgId = uuidv4();
      dispatch({
        type: SAGA_MESSAGE_WEBSOCKET,
        payload: {
          name: selectedChatRoom?.name,
          web_chat_id: get_web_sock?.channel?.id,
          id: msgId,
          message: promptQuery,
        },
      });

      // Add to the pending messages.
      dispatch({
        type: ADD_PENDING_MESSAGE,
        payload: {
          id: msgId,
          web_chat_id: get_web_sock?.channel?.id,
        },
      });
    } else {
      const name = promptQuery
        .slice(0, 50)
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/[^a-zA-Z0-9_]/g, "");
      if (Object.keys(params).length) {
        dispatch({
          type: SAGA_START_AND_MESSAGE_WEBSOCKET,
          payload: {
            token: chatToken,
            // token,
            name,
            message: {
              id: uuidv4(),
              message: promptQuery,
            },
            // contextData: TEST_FEEDBACK,
            contextData: feedbackData,
            params,
            // params: {"topic":"random param"}
          },
        });
        setTopicCreated(Math.random());

        //Need to clear the selected filters + the selected culture factors
        //So that the conversation params are reset for the next conversation
        dispatch({
          type: SET_SELECTED_QUESTION,
          payload: { title: "Overall Culture", level: 0, id: 0 },
        });

        dispatch({
          type: SET_NEW_FILTERS,
          payload: [],
        });

        setTags([]);
      }
    }
  };
  // Should happen on page load,
  // ensures previous socket is disconnected
  useEffect(() => {
    // DISCONNECT_WEBSOCKET
    dispatch({
      type: DISCONNECT_WEBSOCKET,
    });
  }, []);

  useEffect(() => {
    setConversationHistory(get_chat_topics?.chat_topics);
  }, [get_chat_topics, topicCreated]);

  useEffect(() => {
    if (conversationHistory?.interaction_history?.length > 0) {
      dispatch({
        type: SET_WEB_CHAT_MESSAGES,
        payload: conversationHistory?.interaction_history,
      });
    }
  }, [dispatch, conversationHistory]);

  useEffect(() => {
    // First check if we are filtering at all.
    if (
      navigation?.outcome?.name === "Overall Culture" ||
      navigation?.outcome?.name === "All Feedback"
    ) {
      setCurrentFocus("Overall Culture factor data.");
    }

    if (navigation?.outcome?.type === "outcome") {
      setCurrentFocus(`${navigation?.outcome?.name} data.`);
    }

    if (navigation?.factor?.type === "factor") {
      setCurrentFocus(`${navigation?.factor?.name} data.`);
    }
  }, [navigation]);

  return (
    <Modal
      open={open}
      size="large"
      style={{
        minHeight: "600px",
        maxHeight: "720px",
        padding: 50,
      }}
      onClose={() => handleClose()}
      closeOnDimmerClick={true}
    >
      <Title>Culture copilot</Title>
      <IconContainer onClick={() => handleClose()}>
        {closeIcon("#2A3039")}
      </IconContainer>
      <Content open={open}>
        <Query
          query={`Hi, I am your culture copilot. I can answer any questions you have about your data. \n\nI am currently focused on your <strong>${
            focus?.toLowerCase() || "organization"
          }</strong> data I've preserved any filters you had on the page you were navigating.`}
          type="response"
        />
        {!chatInitiated && pageOptions?.options?.length > 0 && (
          <PresetWrapper>
            <T2>Here are some frequenty asked questions:</T2>
            <Suggestions>
              {pageOptions?.options?.map((option, i) => {
                return (
                  <Suggest
                    onClick={() => handlePromptClick(option.prompt)}
                    key={i}
                  >
                    <T1>{option.descriptionTitle}</T1>
                    {option.description}
                  </Suggest>
                );
              })}
            </Suggestions>
          </PresetWrapper>
        )}
        {messages.map((item, i) => {
          // If the first message is a prompt, and the simplePrompt is set, hide the first message
          if (i === 0 && simplePrompt) {
            return null;
          }
          return <Query key={i} query={item.message} type={item.type} />;
        })}
        {chatInitiated && loading && (
          <AwaitingQuery
            message={"Awaiting response..."}
            context_length={feedback_length}
          />
        )}

        <div style={{ marginBottom: chatInitiated ? 50 : 0 }} />

        <div
          style={{ float: "left", clear: "both", height: 5 }}
          ref={messagesEndRef}
        ></div>
      </Content>
      {/* Chat Input */}
      <Bottom>
        <ChatInput>
          <Input
            ref={inputRef}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            style={{ width: "100%" }}
            placeholder="Ask a question about your culture."
            onKeyDown={handleKeyDown}
            rows="1"
            disabled={feedback_length === 0}
            disabledStyle={feedback_length === 0}
          />
          <SendButton onClick={() => handleKeyDown({ key: "Enter" })}>
            <Icon name={"send"} />
          </SendButton>
        </ChatInput>
      </Bottom>
    </Modal>
  );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const PresetWrapper = styled.div`
  margin-top: -20px;
`;

const SendButton = styled.button`
  background-color: transparent;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  outline: none;
  padding: 5px;
  margin-left: 5px;
`;

const ChatInput = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  padding: 16px;
  width: 100%;
`;

const Input = styled.textarea`
  flex-grow: 1;
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  max-height: 200px; /* Adjusted max-height for scrolling */
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  width: 100%;

  font-family: Raleway;
  font-size: 14px;

  opacity: ${({ disabledStyle }) => (disabledStyle ? "0.5" : "1")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "auto")};

  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0px;
  justify-content: center;
  display: flex;
  width: calc(100% - 100px);
  padding-bottom: 40px;
`;

const Container = styled.div`
  height: calc(100vh - 100px);
  position: fixed;
  right: 20px;
  top: 80px;
  border-radius: 15px;
  width: ${(props) => (props.open ? "400px" : "0")};
  background-color: #ebf1fd;
  display: flex;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 100;
  padding: ${(props) => (props.open ? "20px" : "0px")};
  opacity: ${(props) => (props.open ? 1 : 0)};
  flex-direction: column;
`;

const Content = styled.div`
  width: 100%;
  height: 500px; /* Fixed height for the content */
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  position: relative;
  overflow-y: auto; /* Enable vertical scrolling */
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 40px;
  color: #2a3039;
  width: 200px;
`;

const IconContainer = styled.div`
  position: absolute;
  padding: 50px;
  right: 0px;
  top: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const T2 = styled.div`
  font-size: 14px;
  font-family: "Raleway";
  margin-bottom: 10px;
  font-weight: 600;
  color: #2a3039;
`;

const Suggestions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Suggest = styled.div`
  font-size: 12px;
  font-family: "Raleway";
  margin-bottom: 10px;
  color: #2a3039;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid #666d79;
  padding: 15px;
  border-radius: 10px;
  line-height: 20px;
  background-color: #f8faff;

  &:hover {
    color: #666d79;
  }
`;

const T1 = styled.div`
  font-size: 14px;
  font-family: "Raleway";
  margin-bottom: 5px;
  font-weight: 600;
  color: #2a3039;
`;

export default AICopilot;
