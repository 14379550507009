import styled, { keyframes } from "styled-components";
import React from "react";
import Curiosity from "assets/images/curiosity.svg";
import Enjoyment from "assets/images/enjoyment.svg";
import Motivation from "assets/images/motivation.svg";
import Discontent from "assets/images/discontent.svg";
import Stress from "assets/images/stress.svg";
import Anger from "assets/images/anger.svg";
import { Progress ,Loader,Icon} from 'semantic-ui-react'
import Chart from "react-apexcharts";
import EmotionComment from './EmotionComment'



 const Loading = () =>{

   return (
     <div style={{height:350,width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>

     </div>
     )
 }

const EQ = () =>{
      const data = {
          
            series: [{
              name: 'series1',
              data: [31, 40, 28, 51, 42, 109, 100]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                 toolbar:{
                  show:false
                },
                zoom:{
                  enabled:false
                },
                animations:{
                  enabled:false
                },
                 events: {
                  click: function(event, chartContext, config) {
                    // The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
                  setData(config.dataPointIndex)
                  }
                }
              },
              grid:{
                padding:{
                  right:50
                }
              },
              dataLabels: {
                enabled: false
              },
              annotations:{
                points: 
          [
            {
              x: new Date('2018-09-19T06:30:00.000Z').getTime(),
              y: 100,
              marker: {
                size: 8,
              },
              label: {
                text: '95%',
                borderWidth:0,
                style:{
                  fontSize:24,
                  fontWeight:'bold'
                }
              }
            }
          ]
              },
              stroke: {
                curve: 'smooth',
                colors:['#6a00ff']
              },
              fill:{
                colors:['#6a00ff']
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };
  const [options,setOptions] = React.useState(data.options)
  const [selected,setSelected] = React.useState(data.series[0].data.length-1)
  const [loading,setLoading] = React.useState(false)


  const move = (i) =>{
    if(i>0 && selected==data.series[0].data.length-1){
      return 
    }
    if(i<0 && selected==0){
      return 
    }
    setLoading(true)
    let _options = options
    let idx = selected+i
    setSelected(idx)
    let _data = data.series[0].data[idx]

    _options.annotations.points=  [
            {
              x: new Date(options.xaxis.categories[idx]).getTime(),
              y: _data,
              marker: {
                size: 8,
              },
              label: {
                text: _data+'%',
                borderWidth:0,
                style:{
                  fontSize:24,
                  fontWeight:'bold'
                }
              }
            }
          ]

          setOptions(_options)
              setTimeout(function () {
               setLoading(false)
            }, 1);
          
  }

  const setData = (idx) =>{
    setLoading(true)
    let _options = options
    setSelected(idx)
    let _data = data.series[0].data[idx]

    _options.annotations.points=  [
            {
              x: new Date(options.xaxis.categories[idx]).getTime(),
              y: _data,
              marker: {
                size: 8,
              },
              label: {
                text: _data+'%',
                borderWidth:0,
                style:{
                  fontSize:24,
                  fontWeight:'bold'
                }
              }
            }
          ]

          setOptions(_options)
              setTimeout(function () {
               setLoading(false)
            }, 20);
          
  }

	return (
		<Container>
			<Title>Emotional Health Metrics</Title>
        <DateContainer>
			  <DateText>Report date: March 30th,2021</DateText>
        <div style={{marginRight:40}}>
            <Icon
            name="chevron left"
            onClick={() => move(-1)}
            size={"small"}
            style={{ fontSize: 22, cursor: "pointer", zIndex: 2 }}
          />
           <Icon
            name="chevron right"
            onClick={() => move(1)}
            size={"small"}
            style={{ fontSize: 22, cursor: "pointer", zIndex: 2 }}
          />
        </div>
        </DateContainer>
          {!loading?<Chart options={options} series={data.series} type="area" height={350}/>:<Loading/>}
          <EmotionContainer>
            <EmotionComment mood={"Grateful"} comment={""} on_date={""}/>
            <EmotionComment mood={"Optimistic"} comment={""} on_date={""}/>
             <EmotionComment mood={"Concerned"} comment={""} on_date={""}/>
          </EmotionContainer>
		</Container>
		)
}


export default EQ


const DateContainer = styled.div`
  display:flex;
  justify-content:space-between;
`

const Container = styled.div`
	width:100%;
 	padding:30px 20px;
 	margin-top:20px;
 	position:relative;
 	margin-bottom:50px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:30px;
	color:#4a4a4a;
`

const DateText = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:5px;
  margin-bottom:30px;
  margin-left:3px;
`

const EmotionContainer = styled.div`
  display:flex;
`