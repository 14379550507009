import styled, { keyframes } from "styled-components";
import React from "react";
import { Dropdown,Icon } from 'semantic-ui-react'
import HistoryAnalysis from './HistoryAnalysis'




const History = () =>{

	const [selected,setSelected] = React.useState(1)

	return (
		<Container>
			<Dates>
				<Time first selected={selected==1} onClick={()=>setSelected(1)}>Recommendations</Time>
				<Time selected={selected==2} onClick={()=>setSelected(2)}>Analysis</Time>
			</Dates>

			<HistoryAnalysis/>


		</Container>
		)
}

export default History


const Container = styled.div`
	width:100%;
 	padding:30px 40px;
 	margin-top:20px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:500;
	margin-bottom:30px;
	color:#4a4a4a;
`

const Dates = styled.div`
	display:flex;


`

const Time = styled.div`
	color:${props=>props.selected?'white':'#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props=>props.first?'1px solid #6a00ff':'none'};
	background-color:${props=>props.selected?'#6a00ff':'none'};
	cursor:pointer;
`
