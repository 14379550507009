import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { SG_UPDATE_CULTURE_COMMENTS } from "constants/actions";

const Add_Comment = ({ cultureId, name, employee_id, ...props }) => {
  const dispatch = useDispatch();
  const [text, setText] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const addComment = (i) => {
    if (visible) {
      setText("");
      setVisible(false);
    } else {
      setVisible(true);
    }
	if (text.length > 3) {
	    dispatch({
      type: SG_UPDATE_CULTURE_COMMENTS,
      payload: {
        id: cultureId,
        new_comments: {
          name,
		  comment: text,
          employee_id,
        },
      },
    });	
	}

  };

  return (
    <div>
      <Contain visible={visible}>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 5,
            cursor: "pointer",
            color: "#4a4a4a",
            fontSize: 14,
          }}
          onClick={() => setVisible(false)}
        >
          <Icon name="close" />
        </div>
        <InputContainer
          value={text}
          onChange={(e) => setText(e.target.value)}
          visible={visible}
          placeholder={`What are your thoughts on ${name.toLowerCase()} this week?`}
        />
      </Contain>

      <Button 
	  disabled={text.length === 0}
	  onClick={() => addComment(name)}>
        {visible ? "Submit" : "Add"} comment
      </Button>
    </div>
  );
};

export default Add_Comment;

const InputContainer = styled.textarea`
  width: 100%;
  height: 100px;
  font-size: 16px;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  border: 0;
  background-color: rgba(155, 155, 155, 0.1);
  border-radius: 10px;
  padding: 10px;
  line-height: 1.31;

  &:focus {
    outline: none;
  }
`;
const Contain = styled.div`
  max-height: ${(props) => (props.visible ? "100px" : 0)};
  overflow: hidden;
  transition: max-height 500ms;
  position: relative;
  margin-bottom: 10px;
`;

const Button = styled.div`
  height: 40px;
  border-radius: 25px;
  background-color: #6a00ff;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
  cursor: pointer;
`;
