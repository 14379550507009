import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Input, Button as SemanticButton, Icon } from 'semantic-ui-react';
import promptsData from './prompts.json';

const PromptLibrary = () => {
  const [open, setOpen] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [finalSelectedPrompt, setFinalSelectedPrompt] = useState(null);

  useEffect(() => {
    setPrompts(promptsData);
    setFilteredPrompts(promptsData);
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filtered = prompts.filter((prompt) =>
      prompt.title.toLowerCase().includes(term.toLowerCase()) ||
      prompt.description.toLowerCase().includes(term.toLowerCase()) ||
      prompt.tags.some((tag) => tag.toLowerCase().includes(term.toLowerCase()))
    );
    setFilteredPrompts(filtered);
  };

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt);
  };

  const handleSelectPrompt = () => {
    // Update the final selected prompt to display in the main component
    setFinalSelectedPrompt(selectedPrompt);
    setOpen(false);
  };

  const handleRemovePrompt = () => {
    setFinalSelectedPrompt(null);
  };

  return (
    <Container>
      <SemanticButton primary onClick={() => setOpen(true)}>
        Open Prompt Library
      </SemanticButton>

      {finalSelectedPrompt && (
        <SelectedPromptCard>
          <PromptInfo>
            <PromptTitle>{finalSelectedPrompt.title}</PromptTitle>
            <PromptDescription>{finalSelectedPrompt.description}</PromptDescription>
            {/* <Tags>
              {finalSelectedPrompt.tags.map((tag, index) => (
                <Tag key={index}>{tag}</Tag>
              ))}
            </Tags> */}
          </PromptInfo>
          <RemoveButton onClick={handleRemovePrompt}>
            <Icon name="close" />
          </RemoveButton>
        </SelectedPromptCard>
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        size="large"
      >
        <Modal.Header>Prompt Library</Modal.Header>
        <Modal.Content>
          <SearchBar
            placeholder="Search prompts..."
            value={searchTerm}
            onChange={handleSearch}
            fluid
          />
          <ContentWrapper>
            <SelectionPane>
              {filteredPrompts.map((prompt) => (
                <PromptItem
                  key={prompt.id}
                  onClick={() => handlePromptSelect(prompt)}
                  active={selectedPrompt && selectedPrompt.id === prompt.id}
                >
                  {prompt.title}
                </PromptItem>
              ))}
            </SelectionPane>
            <DetailsPane>
              {selectedPrompt ? (
                <>
                  <PromptTitle>{selectedPrompt.title}</PromptTitle>
                  <PromptDescription>{selectedPrompt.description}</PromptDescription>
                  <Tags>
                    {selectedPrompt.tags.map((tag, index) => (
                      <Tag key={index}>{tag}</Tag>
                    ))}
                  </Tags>
                </>
              ) : (
                <Placeholder>Select a prompt to see details</Placeholder>
              )}
            </DetailsPane>
          </ContentWrapper>
        </Modal.Content>
        <Modal.Actions>
          <SemanticButton onClick={() => setOpen(false)}>Cancel</SemanticButton>
          <SemanticButton
            primary
            disabled={!selectedPrompt}
            onClick={handleSelectPrompt}
          >
            Select Prompt
          </SemanticButton>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default PromptLibrary;

const Container = styled.div`
  margin-top: 30px;
  font-family: 'Raleway', sans-serif;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const SearchBar = styled(Input)`
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 400px;
`;

const SelectionPane = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
`;

const PromptItem = styled.div`
  padding: 15px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#f0f8ff' : 'white')};
  &:hover {
    background-color: #f0f8ff;
  }
`;

const DetailsPane = styled.div`
  width: 70%;
  padding: 20px;
  overflow-y: auto;
`;

const PromptTitle = styled.h2`
  margin-top: 0;
  font-size:14px;
  margin-bottom:10px;
`;

const PromptDescription = styled.p`
  font-size: 12px;
  line-height: 1.5;
`;

const Tags = styled.div`
  margin-top: 20px;
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #e0e1e2;
  color: #555;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  font-size: 12px;
`;

const Placeholder = styled.div`
  color: #999;
  font-size: 18px;
  text-align: center;
  margin-top: 150px;
`;

/* New Styles for Selected Prompt Card */
const SelectedPromptCard = styled.div`
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-left: 5px solid #2185d0;
  background-color: #fafafa;
  position: relative;
  display: flex;
  align-items: flex-start;
  border-radius:8px;
`;

const PromptInfo = styled.div`
  flex: 1;
`;

const RemoveButton = styled.div`
  cursor: pointer;
  color: #999;
  &:hover {
    color: #333;
  }
`;
