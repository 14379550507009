import styled, { keyframes } from "styled-components";
import React from "react";
import VisProvider, { VisGraph } from "vis-network-hook";
import Clusters from './Cluster'

 const data = {
    nodes: [
      { id: 1,value:50, label: "Deepesh Krishnan",shape: "circularImage",color:'#08c251', image:"https://randomuser.me/api/portraits/men/20.jpg" },
      { id: 2, value:100,label: "Ellen Bawol",shape: "circularImage",color:'#08c251', image:"https://randomuser.me/api/portraits/women/29.jpg" },
      { id: 3, value:30,label: "Camden Springer",shape: "circularImage",color:'#08c251', image:"https://randomuser.me/api/portraits/men/21.jpg"  },
      { id: 5, value:200,label: "Ron Ball",shape: "circularImage",color:'#6a00ff', image:"https://randomuser.me/api/portraits/men/83.jpg"  },
      { id: 6, value:22,label: "Alice McDonough",shape: "circularImage",color:'#6a00ff', image:"https://randomuser.me/api/portraits/women/35.jpg"  },
      { id: 7, value:100,label: "Kim Woo",shape: "circularImage",color:'#6a00ff', image:"https://randomuser.me/api/portraits/women/60.jpg"  },
      { id: 8, value:22,label: "Alyssa Lunda",shape: "circularImage",color:'#08c251', image:"https://randomuser.me/api/portraits/women/16.jpg"  },
      { id: 9, value:22,label: "Bridget Crosby",shape: "circularImage",color:'#6a00ff', image:"https://randomuser.me/api/portraits/women/57.jpg"  },
      { id: 10, value:22,label: "Jennifer Davis",shape: "circularImage",color:'#3078ff', image:"https://randomuser.me/api/portraits/women/21.jpg"  },
      { id: 11, value:100,label: "Bryce Crosby",shape: "circularImage",color:'#6a00ff', image:"https://randomuser.me/api/portraits/men/22.jpg"  },
      { id: 12, value:22,label: "David Blaine",shape: "circularImage",color:'#3078ff', image:"https://randomuser.me/api/portraits/men/23.jpg"  },
      { id: 13, value:22,label: "Paige Tully",shape: "circularImage",color:'#3078ff', image:"https://randomuser.me/api/portraits/women/26.jpg"  },
    ],
    edges: [
      { from: 2, to: 3 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 3, to: 1 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 1, to: 4 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 2, to: 1 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 4, to: 3 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 4, to: 2 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 2, to: 5 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 7, to: 9 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 9, to: 6 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 6, to: 8 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 7, to: 6 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 8, to: 9 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 8, to: 7 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 7, to: 5 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 11, to: 13 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 13, to: 10 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 10, to: 12 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 11, to: 10 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 12, to: 13 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 12, to: 11 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 11, to: 5 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 2, to: 7 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 2, to: 11 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
      { from: 11, to: 7 ,color:'rgba(106, 0, 255,0.4)',dashes:true},
    ]
  };


  const options = {
  	height:"1000px",
  	width:"100%",
  	nodes:{
  		borderWidth:4,
  	},
  	edges:{
  		width:2
  	}
  }

const Network = () =>{


	return (
    <Container>
   
		<Card>
			<Title>Team Connections Map</Title>
			<Description>Your team's mapping of work connections based on who works most closely together.</Description>
			<Legend> 
        <Cluster><Circle color={'#6a00ff'}/> Cluster 1</Cluster>
         <Cluster><Circle color={'#08c251'}/> Cluster 2</Cluster>
          <Cluster><Circle color={'#3078ff'}/> Cluster 3</Cluster>
      </Legend>
      <VisProvider graph={data} options={options}>
				      <VisGraph />
				    </VisProvider>


		</Card>
     <Clusters/>
    </Container>
		)
}



export default Network



const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:bold;
	margin-bottom:5px;
`

const Card = styled.div`
 	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
 	padding:30px 40px;
 	width:65%;
 	margin-right:20px;
`
const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;

`

const Legend = styled.div`
  display:flex;
`

const Cluster = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  margin-right:10px;
`

const Circle = styled.div`
  height:16px;
  width:16px;
  border-radius:8px;
  background-color:${props=>props.color};
  margin-right:5px;
`

const Container = styled.div`
  display:flex;

`