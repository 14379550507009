import styled, { keyframes } from "styled-components";
import React from "react";

import { Modal } from "semantic-ui-react";

const Information = ({ open, setOpen, info }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Modal.Content>
        <T1>What are the micro action plans?</T1>
        <Description>
          The micro action plan recommendation is a targeted set of steps
          designed to quickly address specific areas needing improvement in
          workplace culture for immediate impact.
        </Description>
        <T1>When to use the micro action plans?</T1>
        <Description>
          The micro action plan recommendation is a targeted set of steps
          designed to quickly address specific areas needing improvement in
          workplace culture for immediate impact.
        </Description>

        <T1>Sample micro action plan</T1>
        <Description>
          The micro action plan recommendation is a targeted set of steps
          designed to quickly address specific areas needing improvement in
          workplace culture for immediate impact.
        </Description>
      </Modal.Content>
    </Modal>
  );
};

export default Information;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Raleway";
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: "Raleway";
`;
