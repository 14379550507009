import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// This should be updated, will do so when this component is updated.
import Box from "../../../../../ProtoType/DraftPlan/Dashboard/Box";
import CategoryPermission from "../CategoryPermission";

const UserPlans = ({ employeeCategory, currentUser }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Center>
        <CategoryPermission
          employeeCategory={employeeCategory}
          currentUser={currentUser}
        />
      </Center>
    </Container>
  );
};

export default UserPlans;

const Container = styled.div`
  padding-left: 58px;
  padding-right: 40px;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Center = styled.div`
  width: 800px;
  margin-top: 0px;
  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state

  // 3. Adjust delay for each sample
  animation-delay: 0.3s;
`;
