import React  from "react";
import styled, { keyframes } from "styled-components";
import MemberCard from "./memberCard";
import { Message } from "semantic-ui-react";


const MemberTable = (props) => {

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
      }}
    >
      {props.members.length > 0 ? (
        <Table>
          <Headers>
            <TH width={40}>Name</TH>
            <TH width={30}>Status</TH>
            <TH width={30}></TH>
          </Headers>
          {props.members.map((item, i) => {
           
              return <MemberCard 
              setLoading={props.setLoading}
              data={item} key={i} />;
        
          })}
        </Table>
      ) : (
        <Message
          icon="envelope outline"
          color="violet"
          size="big"
          header="Your organization does not have any members"
          style={{ zIndex: 0 }}
          content={
            <div>
              Invite individual members to fill out the personality assessment. Members invited here will receive a survey immediately.
            </div>
          }
        />
      )}
    </div>
  );
};

export default MemberTable;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
  @media (max-width:768px){
    display:none;
  }
`;
const Linked = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
