import {
  GET_REPORT_TEMPLATE_PENDING,
  GET_REPORT_TEMPLATE,
  CLEAR_REPORT_TEMPLATE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  report_template: [],
  created: false,
  pending: false,
  updated: false,
  pageNumber: 1,
};

export default function ReportTemplate(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_TEMPLATE_PENDING:
      return {
        ...state,
        pending: true,
        updated: false,
      };
    case GET_REPORT_TEMPLATE:
      return {
        ...state,
        report_template: action.payload,
        pending: false,
        updated: false,
      };
    case CLEAR_REPORT_TEMPLATE:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}
