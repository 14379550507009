export const MoodMainMenu = [
	{
        "id": 2,
        "index": 1,
		"name": "Enjoyment",
        "colors": ['rgba(8, 194, 81,1)','rgba(8, 194, 81,0.8)','rgba(8, 194, 81,0.6)','rgba(8, 194, 81,0.4)','rgba(8, 194, 81,0.2)']
	},
	{
        "id": 3,
        "index": 2,
		"name":"Motivation",
        "colors": ['rgb(0, 51, 128)','rgb(0, 71, 179)','rgb(0, 92, 230)','rgb(51, 133, 255)','rgb(128, 179, 255)']
	},
	{
		"id": 1,
        "index": 0,
        "name": "Curiosity",
        "colors": ['rgb(102, 0, 77)','rgb(153, 0, 115)','rgb(204, 0, 153)','rgb(255, 0, 191)','rgb(255, 102, 217)']
	},
	{
        "id": 4,
        "index": 3,
		"name":"Stress",
        "colors": ['rgb(128, 102, 0)','rgb(179, 143, 0)','rgb(230, 184, 0)','rgb(255, 209, 26)','rgb(255, 219, 77)']
	},
	{
        "id": 5,
        "index": 4,
		"name":"Discontent",
        "colors": ['rgb(51, 0, 102)','rgb(77, 0, 153)','rgb(102, 0, 204)','rgb(128, 0, 255)','rgb(153, 51, 255)']
	},
	{
        "id": 6,
        "index": 5,
		"name":"Anger",
        "colors": ['rgb(102, 0, 0)','rgb(153, 0, 0)','rgb(204, 0, 0)','rgb(255, 26, 26)','rgb(255, 102, 102)']
	},
]