export function UploadGroupIcon(fill) {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 21H2C1.73478 21 1.48043 20.8946 1.29289 20.7071C1.10536 20.5196 1 20.2652 1 20V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H16C16.2652 1 16.5196 1.10536 16.7071 1.29289C16.8946 1.48043 17 1.73478 17 2V11M14.75 21V14.5"
        stroke="#5167DD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.25L13.25 15.5L14.75 14L16.25 15.5L17 16.25"
        stroke="#5167DD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7H13M5 11H9"
        stroke="#5167DD"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function CategoryIcon(fill) {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9978 1.10001H2.49775C1.77978 1.10001 1.19775 1.68204 1.19775 2.40001V2.80001C1.19775 3.51798 1.77978 4.10001 2.49775 4.10001H19.9978C20.7157 4.10001 21.2978 3.51798 21.2978 2.80001V2.40001C21.2978 1.68204 20.7157 1.10001 19.9978 1.10001Z"
        stroke="#476DFA"
        strokeMiterlimit="10"
      />
      <path
        d="M19.9977 6.60001H5.89766C5.17969 6.60001 4.59766 7.18204 4.59766 7.90001V8.3C4.59766 9.01798 5.17969 9.60001 5.89766 9.60001H19.9977C20.7156 9.60001 21.2977 9.01798 21.2977 8.3V7.90001C21.2977 7.18204 20.7156 6.60001 19.9977 6.60001Z"
        stroke="#476DFA"
        strokeMiterlimit="10"
      />
      <path
        d="M19.9977 12.1H12.1977C11.4797 12.1 10.8977 12.682 10.8977 13.4V13.8C10.8977 14.518 11.4797 15.1 12.1977 15.1H19.9977C20.7157 15.1 21.2977 14.518 21.2977 13.8V13.4C21.2977 12.682 20.7157 12.1 19.9977 12.1Z"
        stroke="#476DFA"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export function PlusIcon(fill) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4248 0.70166L6.42487 11.1931"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M11.6707 5.94751L1.17922 5.94748"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function ElipsesButton(fill) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 30C23.732 30 30 23.732 30 16C30 8.268 23.732 2 16 2C8.268 2 2 8.268 2 16C2 23.732 8.268 30 16 30ZM10 18C9.46957 18 8.96086 17.7893 8.58579 17.4142C8.21071 17.0391 8 16.5304 8 16C8 15.4696 8.21071 14.9609 8.58579 14.5858C8.96086 14.2107 9.46957 14 10 14C10.5304 14 11.0391 14.2107 11.4142 14.5858C11.7893 14.9609 12 15.4696 12 16C12 16.5304 11.7893 17.0391 11.4142 17.4142C11.0391 17.7893 10.5304 18 10 18ZM18 16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18C15.4696 18 14.9609 17.7893 14.5858 17.4142C14.2107 17.0391 14 16.5304 14 16C14 15.4696 14.2107 14.9609 14.5858 14.5858C14.9609 14.2107 15.4696 14 16 14C16.5304 14 17.0391 14.2107 17.4142 14.5858C17.7893 14.9609 18 15.4696 18 16ZM22 18C21.4696 18 20.9609 17.7893 20.5858 17.4142C20.2107 17.0391 20 16.5304 20 16C20 15.4696 20.2107 14.9609 20.5858 14.5858C20.9609 14.2107 21.4696 14 22 14C22.5304 14 23.0391 14.2107 23.4142 14.5858C23.7893 14.9609 24 15.4696 24 16C24 16.5304 23.7893 17.0391 23.4142 17.4142C23.0391 17.7893 22.5304 18 22 18Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

export function XIcon(fill) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 3.25L3.25 9.75"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.25 3.25L9.75 9.75"
        stroke="#C0C0C0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SaveIcon(fill) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4583 15.375H3.54167C3.16594 15.375 2.80561 15.2257 2.53993 14.9601C2.27426 14.6944 2.125 14.3341 2.125 13.9583V4.04167C2.125 3.66594 2.27426 3.30561 2.53993 3.03993C2.80561 2.77426 3.16594 2.625 3.54167 2.625H11.3333L14.875 6.16667V13.9583C14.875 14.3341 14.7257 14.6944 14.4601 14.9601C14.1944 15.2257 13.8341 15.375 13.4583 15.375Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0418 15.375V9.70834H4.9585V15.375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9585 2.625V6.16667H10.6252"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TeamIcon(fill) {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7171 10.3764L10.7169 10.3762C10.5416 10.1595 10.5745 9.84114 10.792 9.66537M10.7171 10.3764L11.4276 10.451C12.0498 9.94744 12.8364 9.66987 13.6422 9.66987C15.5721 9.66987 17.1394 11.2212 17.1394 13.1246V14.2869C17.1394 14.317 17.1128 14.3489 17.0713 14.3489H12.6139C12.335 14.3489 12.1085 14.5753 12.1085 14.8542C12.1085 15.1333 12.335 15.3595 12.6139 15.3595H17.0712C17.6653 15.3595 18.1499 14.8793 18.1499 14.2869V13.1246C18.1499 10.6608 16.1265 8.6592 13.6422 8.6592C12.6059 8.6592 11.5939 9.01648 10.792 9.66537M10.7171 10.3764C10.8929 10.5931 11.2109 10.6266 11.4276 10.4511L10.7171 10.3764ZM10.792 9.66537L10.8863 9.78203M10.792 9.66537C10.792 9.66539 10.792 9.66541 10.7919 9.66543L10.8863 9.78203M10.8863 9.78203C10.7334 9.90557 10.7102 10.1294 10.8336 10.2819L10.8863 9.78203Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M10.9367 5.75981C10.9367 7.24012 12.1515 8.44191 13.6422 8.44191C15.1329 8.44191 16.3481 7.24033 16.3481 5.75981C16.3481 4.27931 15.1331 3.07754 13.6422 3.07754C12.1514 3.07754 10.9367 4.27951 10.9367 5.75981ZM11.9473 5.75981C11.9473 4.84003 12.706 4.08821 13.6422 4.08821C14.5786 4.08821 15.3374 4.84006 15.3374 5.75981C15.3374 6.67955 14.5788 7.43124 13.6422 7.43124C12.706 7.43124 11.9473 6.67957 11.9473 5.75981Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M2.16195 16.7849H11.8073C12.5299 16.7849 13.1193 16.2014 13.1193 15.4814V13.8467C13.1193 10.4954 10.3662 7.77175 6.98463 7.77175C3.60301 7.77175 0.85 10.4954 0.85 13.8467V15.4814C0.85 16.2014 1.4394 16.7849 2.16195 16.7849ZM1.86067 13.8469C1.86067 11.0562 4.15778 8.78259 6.98463 8.78259C9.81148 8.78259 12.1086 11.0562 12.1084 13.8469V15.4816C12.1084 15.6402 11.9758 15.7744 11.8071 15.7744H2.16195C1.99328 15.7744 1.86067 15.6402 1.86067 15.4816V13.8469Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M3.42715 4.253C3.42715 6.1312 4.96903 7.65635 6.86121 7.65635C8.75354 7.65635 10.2954 6.13138 10.2954 4.25317C10.2954 2.37515 8.75372 0.85 6.86121 0.85C4.96887 0.85 3.42715 2.37497 3.42715 4.253ZM4.43782 4.25317C4.43782 2.93582 5.52343 1.86067 6.86121 1.86067C8.19916 1.86067 9.28477 2.93583 9.28477 4.25317C9.28477 5.5707 8.19916 6.64586 6.86121 6.64586C5.52344 6.64586 4.43782 5.57071 4.43782 4.25317Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
    </svg>
  );
}

export function LockIcon(fill) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 11H5C3.89543 11 3 11.8954 3 13V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V13C21 11.8954 20.1046 11 19 11Z"
        stroke="#476DFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 11V7C7 5.67392 7.52678 4.40215 8.46447 3.46447C9.40215 2.52678 10.6739 2 12 2C13.3261 2 14.5979 2.52678 15.5355 3.46447C16.4732 4.40215 17 5.67392 17 7V11"
        stroke="#476DFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function RefreshIcon(fill) {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1998 0.279972C13.7298 0.279972 16.6298 2.88997 17.1198 6.27997H19.1998L15.6998 10.28L12.1998 6.27997H14.5198C14.2961 5.30108 13.7471 4.42695 12.9625 3.80034C12.1778 3.17373 11.2039 2.83165 10.1998 2.82997C8.7498 2.82997 7.4698 3.53997 6.6598 4.60997L4.9498 2.65997C5.60432 1.91153 6.41149 1.31189 7.31704 0.901372C8.22259 0.490854 9.20554 0.278972 10.1998 0.279972ZM9.7998 13.72C6.2798 13.72 3.3698 11.11 2.8798 7.71997H0.799805L4.2998 3.71997C5.4698 5.04997 6.6298 6.38997 7.7998 7.71997H5.4798C5.70352 8.69887 6.25252 9.57299 7.03715 10.1996C7.82178 10.8262 8.79567 11.1683 9.7998 11.17C11.2498 11.17 12.5298 10.46 13.3398 9.38997L15.0498 11.34C14.3959 12.0892 13.5889 12.6893 12.6832 13.0999C11.7775 13.5105 10.7942 13.7219 9.7998 13.72Z"
        fill="#5167DD"
      />
    </svg>
  );
}

export function TrashIcon(fill) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.125 4.25H3.54167H14.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4587 4.24999V14.1667C13.4587 14.5424 13.3094 14.9027 13.0437 15.1684C12.778 15.4341 12.4177 15.5833 12.042 15.5833H4.95866C4.58294 15.5833 4.2226 15.4341 3.95692 15.1684C3.69125 14.9027 3.54199 14.5424 3.54199 14.1667V4.24999M5.66699 4.24999V2.83332C5.66699 2.4576 5.81625 2.09726 6.08192 1.83159C6.3476 1.56591 6.70794 1.41666 7.08366 1.41666H9.91699C10.2927 1.41666 10.653 1.56591 10.9187 1.83159C11.1844 2.09726 11.3337 2.4576 11.3337 2.83332V4.24999"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08301 7.79166V12.0417"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.91699 7.79166V12.0417"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
