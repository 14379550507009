import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import { format } from "date-fns";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { viewIcon } from "../../reports/components/Icons";
import { useNavigate, useParams } from "react-router-dom";

const STATUS_CODES = {
  0: { status: "Pending", color: "#FFC107" },
  1: { status: "Active", color: "#28A745" },
};

const createManagersTable = (managers, employees, added) => {
  const managersList = [];

  managers.forEach((manager) => {
    const empObject = employees.find((emp) => emp.employee_id === manager.id);

    // if !manager?.permission?.details?.[0] then check added if a category exists for this user, if so, use that

    const dataAccess = manager?.permission?.details?.[0]
      ? `${manager?.permission?.details?.[0]?.name}: ${manager?.permission?.details?.[0]?.value}`
      : `${
          added?.filter((cat) => cat.employee_id === manager.id)?.[0]
            ?.employee_category?.name
        }: ${
          added?.filter((cat) => cat.employee_id === manager.id)?.[0]
            ?.employee_category?.value
        }` || null;

    let additionalCategories = "";
    let hasAdditionalCategories = false;
    // Create a list of additional data access categories, if any
    if (manager?.permission?.details?.length > 1) {
      hasAdditionalCategories = true;
      manager?.permission?.details?.slice(1).forEach((category) => {
        if (additionalCategories === "") {
          additionalCategories += `${category?.value}`;
        } else {
          additionalCategories += `, ${category?.value}`;
        }
      });
    }

    const managerObject = {
      id: manager.id,
      name: `${manager?.first_name} ${manager?.last_name}`,
      dataAccess:
        employees?.length > 0
          ? (dataAccess && dataAccess) || "No access categories..."
          : "Fetching data...",
      additionalCategories: additionalCategories,
      hasAdditionalCategories: hasAdditionalCategories,
      // empObject?.category?.find((cat) => cat.priority === "primary")?.
      // ?.map((cat) => `${cat.name}: ${cat.value}`),
      // .join(", "),
      status: manager?.email_status,
    };
    managersList.push(managerObject);
  });

  return managersList;
};

const Table = ({ loading, users, userCategories, addedCategories }) => {
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(null);
  const [managersList, setManagersList] = useState([]);
  useEffect(() => {
    if (users.length > 0) {
      setManagersList(
        createManagersTable(users, userCategories, addedCategories)
      );
    }
  }, [userCategories, users]);

  return (
    <Container>
      <Header>
        <TH width={20}>Member</TH>
        <TH width={30}>Data access</TH>
        <TH width={10}>Status</TH>
        <TH width={20}>Last login</TH>
        <TH width={20}>Actions</TH>
      </Header>
      {!loading &&
        managersList?.length > 0 &&
        managersList
          //   ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          ?.map((user) => {
            return (
              <React.Fragment key={user.id}>
                <Row
                  onMouseOver={() => {
                    setHovered(user.id);
                  }}
                  onMouseOut={() => setHovered(null)}
                  rowIsHovered={hovered === user.id}
                >
                  <TD width={20}>{user.name}</TD>
                  <TD width={30}>
                    {user.dataAccess}{" "}
                    {user?.hasAdditionalCategories ? (
                      <Popup
                        trigger={
                          <ActionWrapper>
                            +{user.additionalCategories.split(", ").length}
                          </ActionWrapper>
                        }
                        content={user.additionalCategories}
                        position="top center"
                        size="mini"
                        inverted
                      />
                    ) : null}
                  </TD>
                  <TD width={10}>
                    {STATUS_CODES[user.status]?.status || "Pending"}
                  </TD>
                  <TD width={20}>May 20th, 2024</TD>
                  <TD width={20}>
                    <Popup
                      trigger={
                        <ActionWrapper
                          onClick={() => {
                            navigate(
                              `/leader/audit/manage/userprofile/${user.id}`
                            );
                          }}
                        >
                          {viewIcon()}
                        </ActionWrapper>
                      }
                      content="View Activity"
                      position="top center"
                    />
                  </TD>
                </Row>
              </React.Fragment>
            );
          })}
      {users?.length === 0 && (
        <Row>
          <TD width={100} centered>
            Users list is empty, add a user to get started.
          </TD>
        </Row>
      )}
    </Container>
  );
};

export default Table;

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;
