import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/resilience2.svg";
import Impact from "assets/images/Impact.svg";



const data = {
          
            series: [86],
            options: {
              chart: {
                height: 150,
                type: 'radialBar',
              },
               grid:{
              	padding:{
              		right:0,
              		top:0,
              		left:0,
              		bottom:0
              	}
              },
              plotOptions: {
                radialBar: {
                	startAngle: -90,
                  endAngle: 90,
                  hollow: {
                    size: '60%',
                  },
                   track:{
                	background:'#c3a7fd'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 }
                },
                  
              },
              fill:{
              	colors:['#6a00ff']
              }
            },
          
          
          };

const Dial = (props) =>{

	return (
		<Card>
			<Chart options={data.options} series={data.series} type="radialBar" height={150} />
			<Values>
					<Stat>86%</Stat>
				</Values>
		</Card>
		)
}


export default Dial



const Card = styled.div`
	padding:10px;
	position:relative;
	width:200px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	margin-bottom:-75px;
`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 25px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:25px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const Change = styled.div`
	font-size:16px;
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:10px;
`

const Top = styled.div`
	width:100%;
	margin-bottom:-30px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:25px;
	color:#4a4a4a;
`

const Icon = styled.img`
	height:40px;
`

const View = styled.div`
	font-family: "Red Hat Display", sans-serif;
	cursor:pointer;
`

const Line = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
`

const Arrow = styled.span`
	width: 7px;
  height: 11px;
  margin-left:10px;
`