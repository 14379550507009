import React from 'react'
import { Button, Header, Image, Modal,Input,TextArea,Dropdown } from 'semantic-ui-react'
import styled, { keyframes } from "styled-components";


const Create_Posting = () => {
    const [open, setOpen] = React.useState(false)

    return (

        <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
      trigger={<Button>Create new job</Button>}
    >
      <Modal.Header>Create a new job posting</Modal.Header>
      <Content>
      <Input placeholder='Job Title...' />
      <Text placeholder='Job Description' style={{marginTop:20}}/>
       
       <TagArea>
        <h4>Select tags for this job</h4>
            <Dropdown
            placeholder='Select Friend'
            fluid
            selection
            options={friendOptions}
        />
       </TagArea>
      </Content>
      <Modal.Actions>
        <Button color='grey' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Save"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
    )

    }

export default Create_Posting

const Text = styled(TextArea)`
    padding:10px;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding:20px;
`

const TagArea = styled.div`
    margin-top:30px;
`

const friendOptions = [
    {
      key: 'Jenny Hess',
      text: 'Jenny Hess',
      value: 'Jenny Hess',
    },
    {
      key: 'Elliot Fu',
      text: 'Elliot Fu',
      value: 'Elliot Fu',
    },
    {
      key: 'Stevie Feliciano',
      text: 'Stevie Feliciano',
      value: 'Stevie Feliciano',
    },
    {
      key: 'Christian',
      text: 'Christian',
      value: 'Christian',
    },
    {
      key: 'Matt',
      text: 'Matt',
      value: 'Matt',
    },
    {
      key: 'Justen Kitsune',
      text: 'Justen Kitsune',
      value: 'Justen Kitsune',
    },
  ]