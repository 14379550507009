import React from "react";
import styled, { keyframes } from "styled-components";
import BlankAvatar from "assets/images/empty/user.svg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SG_GET_MOOD_PER } from "constants/actions";

const CommentComponent = ({
  employee_id,
  comment,
  tightLayout,
  showEmotionalReport = false,
  hideFace = false,
  showFace = false,
  clickable = false,
  ...props }) => {
  //   console.log(props);
  const dispatch = useDispatch();
  const [employee, setEmployee] = React.useState("");
  const [user, setUser] = React.useState("");
  const [employeeFullName, setEmployeeFullName] = React.useState("Member");

  let navigate = useNavigate();
  const get_auth = useSelector((state) => state.auth, shallowEqual);
  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );
  // const showFaces = !anonymous
  if (showFace) {
    showEmotionalReport = showFace
  } 
  if (user?.role > 9) {
    showEmotionalReport = false
    showFace = false
  }

  // console.log(showFace)
  // console.log(showEmotionalReport)

  React.useEffect(() => {
    if (get_employees) {

      setUser(get_employees.filter((em) => em.id == Number(get_auth?.employee_id))[0])
      if (get_employees.filter((em) => em.id === employee_id)) {
        setEmployee(get_employees.filter((em) => em.id === employee_id)[0]);
      }
      // check for rules to show name
      // if (showFace) {
      //   setEmployeeFullName(`
      //   ${get_employees.filter((em) => em.id === employee_id)[0]?.first_name} ${get_employees.filter((em) => em.id === employee_id)[0]?.last_name}
      //   `)
      // } else {
      //   if (get_employees.filter((em) => em.id === Number(get_auth?.employee_id))[0]?.role < 9) {
      //     // console.log("checked")
      //     if (get_employees.filter((em) => em.id === employee_id)[0]?.first_name) {
      //       setEmployeeFullName(`
      //   ${get_employees.filter((em) => em.id === employee_id)[0]?.first_name} ${get_employees.filter((em) => em.id === employee_id)[0]?.last_name}
      //   `)

      //     } else if (get_employees.filter((em) => em.id === employee_id)[0]?.email) {
      //       setEmployeeFullName(`${get_employees.filter((em) => em.id === employee_id)[0]?.email}`)
      //     }

      //   }
      //   if (Number(get_auth?.employee_id) === employee_id) {
      //     setEmployeeFullName(`
      //   ${get_employees.filter((em) => em.id === employee_id)[0]?.first_name} ${get_employees.filter((em) => em.id === employee_id)[0]?.last_name}
      //   `)
      //   }
      // }
    }
  }, [get_employees, employee_id, get_auth, showFace]);

  let employeePic = BlankAvatar;


  function EmployeePicture() {
    if (user?.role > 9) {
      return
    }
    if (user?.role === 6) {
      if (user?.id === employee?.id) {
        employeePic = employee?.picture ? employee?.picture : BlankAvatar;
        return;
      }
      return
    }
    if (hideFace) {
      employeePic = BlankAvatar;
      return
    }
    if (user?.id === employee?.id) {
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }
    
    if (showFace) {
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }

    // self can view
    if (user?.id === employee?.id) {
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }

    // only leader can view
    if (user?.role < 9) {
      // console.log("show picuser id", user?.id, user?.account_type, user?.role)
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }
  }

  EmployeePicture();

  let employeeName = "Team Member";
  function EmployeeNameSequence() {
    if (employee?.first_name) {
      return `${employee?.first_name} ${employee?.last_name}`;
    } else if (employee?.email) {
      return `${employee?.email}`;
    } else if (employee?.id) {
      return `${employee?.id}`;
    }

    return "Team Member";
  }
  function EmployeeName() {
    // self can view
    if (user?.id === employee?.id) {
      employeeName = EmployeeNameSequence();
      return;
    }
    if (user?.role === 6) {
      if (user?.id === employee?.id) {
        employeeName = EmployeeNameSequence();
        return;
      }
      return
    }
    if (showFace) {
      employeeName = EmployeeNameSequence();
      return;
    }
    // only leader can view
    if (user?.role < 9) {
      // console.log("show picuser id", user?.id, user?.account_type, user?.role)
      employeeName = EmployeeNameSequence();
      return;
    }
  }
  EmployeeName()
  // console.log(employeePic)

  function SelectedEmployee(id) {
    dispatch({
      type: SG_GET_MOOD_PER,
      payload: id,
    });

    navigate("/app/personal?show=yes");
  }

  const personal = () => {
    if (!showEmotionalReport) {
      return
    }
    if (user?.role === 6) {
      if (user?.id === employee?.id) {
        SelectedEmployee(employee?.id)
        return;
      }
      return
    }
    if (showEmotionalReport) {
      // console.log(employee.id)
      SelectedEmployee(employee?.id)
    } else if (user?.role < 9) {
      SelectedEmployee(employee?.id)
    }
  }

  if (tightLayout) {
    return (
      <CommentContainer>
        <Avatar src={employeePic} onClick={() => personal()} 
        clickable={showEmotionalReport} />
        <div style={{ position: "relative", width: "100%", marginLeft: 11 }}>
          {showFace ? <Name> {employeeName}{" "} </Name> : ""}
          <Comment>{comment && comment}</Comment>
        </div>
      </CommentContainer>
    )
  }


  return (
    <Container>
      <Avatar src={employeePic} onClick={() => personal()} 
      clickable={showEmotionalReport} />
      <div style={{ position: "relative", width: "100%", marginLeft: 12 }}>
        <Name>
          {showFace ? <Name> {employeeName} </Name> : ""}
          <SubDate>{props.date}</SubDate>
        </Name>
        <Comment>{comment && comment}</Comment>
      </div>
    </Container>
  );
};

export default CommentComponent;

const Card = styled.div`
  border-radius: 8px;

  width: 30%;
  padding: 12px;
  position: absolute;
  right: 0px;

  @media (min-width: 768px) {
    box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  }

  @media (max-width: 786px) {
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 0;
    right: 0;
  }
`;

const Title = styled.div`
  font-size: 23px;
  color: #4a4a4a;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Red Hat Display", sans-serif;
  margin: 54px 0 44px 0;

  @media (max-width: 768px) {
    margin: 10px 0 30px 0;
    font-size: 18px;
  }
`;
const Dated = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: #696969;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

const Line = styled.div`
  height: 1px;
  background-color: #e9e9e9;
  width: 100%;
  margin-bottom: 14px;
`;

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-bottom: 10px;
  cursor:${props => props.clickable ? 'pointer' : 'auto'};
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
`;

const Comment = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.31;
  color: #9b9b9b;
  margin-top: 5px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  transition: max-height 1s ease-out, opacity 1s ease-out;
  position: relative;
  margin-bottom: 40px;
`;

const CommentContainer = styled.div`
	 width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`

const SubDate = styled.span`
  font-size: 16px;
  font-weight: bold;
  margin-left: 28px;
  color: #9b9b9b;
  font-family: "Red Hat Display", sans-serif;
`;

const TrendContainer = styled.div`
  width: calc(100% - 8px);
  background-color: rgba(106, 0, 255, 0.1);
  padding: 16px;
  box-sizing: content-box;
  margin-left: -12px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 47px;
  height: 47px;
  border-radius: 23.5px;
  margin-right: 12px;
`;

const View = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
  margin-top: 12px;
`;

const InnerLogic = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  color: #4a4a4a;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: ${(props) => (props.visibility ? "140px" : 0)};
  transition: height 0.5s;
  overflow: hidden;
  margin-top: ${(props) => (props.visibility ? "16px" : 0)};

  @media (max-width: 1000px) {
    height: ${(props) => (props.visibility ? "150px" : 0)};
  }
`;
