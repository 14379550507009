import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { REMOVE_EMPLOYEE } from "constants/actions";
import { Confirm } from "semantic-ui-react";

const Remove = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #ff2d55;
	cursor: pointer;
	font-family: "Barlow", sans-serif;
`;

const Delete = (props) => {
	const {data, setFullEmpData, setLoading} = props
	const apiDispatch = useDispatch();
	const [open, setOpen] = useState(false);
	
	const deleteMember = () => {
		setFullEmpData({})
		setLoading(true)
		apiDispatch({
			type: REMOVE_EMPLOYEE,
			payload: data.id,
		});
		setOpen(false);
	};

	return (
		<>
			<Confirm
				open={open}
				onCancel={() => setOpen(false)}
				onConfirm={() => deleteMember()}
				content={
					"Are you sure you want to permantly delete " +
					(data.name ? data.name : data.email)
				}
			/>
			<Remove onClick={() => setOpen(true)}>Delete member</Remove>
		</>
	);
};

export default Delete;
