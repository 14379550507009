import styled, { keyframes } from "styled-components";
import React from "react";
import {  Popup } from 'semantic-ui-react'

const height = 600
const width = 600


const TeamColor = (props) =>{

	const [size,setSize] = React.useState(Math.random()*75)


	return (
			<Popup
		        content={<div>
		        	<Row><Title>Team Health: 86% <Change>(+10%)</Change></Title></Row>
		        	<Row><Title>Emnotional Health: 66% <Change>(-20%)</Change></Title></Row>
		        	</div>}
		        key={props.y}
		        header={'Customer Success'}
		        trigger={<AvatarContainer 
						top={props.y/100*height - size/2/height} 
						left={props.x/100*width - size/2/width}
						size={size} >
					</AvatarContainer>}
		      />
			
		)
}

const Grid = () =>{

	const [selected,setSelected] = React.useState(0)

	return (
		<Container>
				<Text top={-10} left={width/2 - 70}>Improving Team Health</Text>
				<Text top={height+10} left={width/2 - 70}>Decreasing Team Health</Text>
				<Text3 top={height/2} left={width-120}>Improving Emotional Health</Text3>
				<Text2 top={height/2} left={-110}>Decreasing Emotional Health</Text2>
				<svg height={height} width={width} viewBox="0 0 100 100">
					<defs>
					    <linearGradient id="myGradient" gradientTransform="rotate(135)">
					      <stop offset="0%"  stopColor="#01CFEC" />
					      <stop offset="100%" stopColor="#6B00D1" />
					    </linearGradient>
					  </defs>
					<path d="M 1 1 L 1 50 L 99 50 L 1 50 L 1 99 L 99 99 L 99 1 L 50 1 L 50 99 L 50 1 L 0.5 1" stroke={"url('#myGradient')"} strokeWidth="1" fill="transparent"/>

					
					
					<line x1={1.5} y1={10} x2={98.5} y2={10} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={20} x2={98.5} y2={20} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={30} x2={98.5} y2={30} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={40} x2={98.5} y2={40} stroke="grey" opacity={0.3} strokeWidth="0.5"/>

					<line x1={1.5} y1={60} x2={98.5} y2={60} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={70} x2={98.5} y2={70} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={80} x2={98.5} y2={80} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={1.5} y1={90} x2={98.5} y2={90} stroke="grey" opacity={0.3} strokeWidth="0.5"/>


					<line x1={10} y1={1.5} x2={10} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={20} y1={1.5} x2={20} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={30} y1={1.5} x2={30} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={40} y1={1.5} x2={40} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					
					<line x1={60} y1={1.5} x2={60} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={70} y1={1.5} x2={70} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={80} y1={1.5} x2={80} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>
					<line x1={90} y1={1.5} x2={90} y2={98.5} stroke="grey" opacity={0.3} strokeWidth="0.5"/>

				</svg>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={1}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={2}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={3}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={4}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={5}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={6}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={7}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={8}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={9}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={10}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={11}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={12}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={13}/>
				<TeamColor x={Math.random()*100} y={Math.random()*100} i={14}/>


		</Container>
		)
}


export default Grid


const Container = styled.div`
	padding:10px;
	magrin-bottom:20px;
	position:relative;
	display:flex;
	margin-left:50px;
`


const Text = styled.div`
	position:absolute;
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};
`

const Text2 = styled.div`
	transform: rotate(-90deg);
	position:absolute;
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};

`

const Text3 = styled.div`
	transform: rotate(90deg);
	position:absolute;
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};
	width:300px;
	display:flex;
	justify-content:center;
`

const Avatar = styled.img`
	height:40px;
	width:40px;
	cursor:pointer;

	&:hover{
		border:3px solid #6a00ff;
		height:43px;
		width:43px;
	}
`

const AvatarContainer = styled.div`
	position:absolute;
	top:${props=>props.top+'px'};
	left:${props=>props.left+'px'};
	opacity:${props=>props.hide && !props.selected?0.3:1};
	height:${props=>props.size+'px'};
	width:${props=>props.size+'px'};
	border-radius:${props=>props.size/2+'px'};
	background-color:rgba(107, 0, 209,0.4);
	border:1px solid rgba(107, 0, 209,1);
	overflow:hidden;
	cursor:pointer;

`


const Anchor = styled.div`
	height:8px;
	width:8px;
	background-color:white;
	border-radius:4px;
	position:absolute;
	top:-3px;
	left:-3px;
	border:2px solid black;

`

const Row = styled.div`
	display:flex;
	flex-direction:row;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	display:flex;
	flex-direction:row;

`

const Change = styled.span`
	font-family: 'Red Hat Display', sans-serif;
	margin-left:5px;
	color:#4a4a4a;
	font-size:12px;
`