import styled from "styled-components";
import React, { useState, useEffect } from "react";
import {Icon} from "semantic-ui-react";
import ClickAwayListener from "react-click-away-listener";


const CustomDropdown = ({select,showLink}) => {
    const [show, setShow] = useState(false);

    const handleClickAway = () => {
        setShow(false);
      };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
        <DropdownContainer>
        <DropdownButton onClick={() => setShow(!show)}>
            Survey Options <Icon name="chevron down" />
        </DropdownButton>
        <DropdownContent show={show}>
            <DropdownItem  onClick={()=>select(1)}><Icon name="check square outline" style={{marginRight:10}}/>Compliance report</DropdownItem>
            {showLink ?<DropdownItem  onClick={()=>select(7)}><Icon name="linkify" style={{marginRight:10}}/>Share public link</DropdownItem> :""}
            <DropdownItem onClick={()=>select(2)}><Icon name="clock outline" style={{marginRight:10}}/>Extend survey deadline</DropdownItem>
            <DropdownItem onClick={()=>select(8)}><Icon name="download" style={{marginRight:10}}/>Download email list</DropdownItem>
            <DropdownItem onClick={()=>select(3)}><Icon name="write" style={{marginRight:10}}/>Create a custom reminder</DropdownItem>
            <DropdownItem onClick={()=>select(4)}><Icon name="bell outline" style={{marginRight:10}}/>Send reminder</DropdownItem>
            <DropdownItem onClick={()=>select(5)}><Icon name="info" style={{marginRight:10}}/>Email reminder status</DropdownItem>
            <DropdownItem onClick={()=>select(6)}><Icon name="close" style={{marginRight:10}}/>Close the survey</DropdownItem>
        </DropdownContent>
        </DropdownContainer>
        </ClickAwayListener>
    )


}

export default CustomDropdown;


const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  font-family: 'Raleway', sans-serif;
`;

const DropdownButton = styled.div`
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Raleway', sans-serif;
  color:#666D79;
  font-weight:600;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  left:-25px;
  background-color: #ffffff;
  min-width: 200px;
  box-shadow: 0px 8px 20px 0px #3F86FE14;

  z-index: 1;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 4px;
`;

const DropdownItem = styled.a`
  color: #666D79;
  font-size:12px;
  padding: 6px 10px;
  text-decoration: none;
  display: block;
    cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;