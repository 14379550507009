export const buildRankingTables = (
  data,
  categories,
  question,
  selectedQuestion,
  isFrench,
  translations,
  titleText
) => {
  const lang = isFrench ? "fr" : "en";
  const anchorName = categories.categories?.find(
    (ao) => ao.priority === "secondary"
  );
  let colWidth;
  const title = titleText;

  const tableTemplate = categories.categories
    ?.find((ao) => ao.priority === "secondary")
    .options.map((anchor) => {
      colWidth = 39 / (anchorName.options.length + 1);
      return {
        title: isFrench
          ? anchor?.fr.charAt(0).toUpperCase()
          : anchor.name.charAt(0).toUpperCase(),
        id: anchor.id,
        width: `${colWidth}%`,
      };
    });

  tableTemplate.unshift({ title: "AV", width: `${colWidth}%` });
  tableTemplate.unshift({
    title: getTranslatedText("domain", lang, translations),
    width: "25%",
  });
  tableTemplate.unshift({ title, width: "46%" });

  const dimensionData = [];
  data.forEach((d) => {
    if (d.dimension === selectedQuestion?.dimension) {
      dimensionData.push(d);
    }
  });

  const dimensionQuestions = [];
  const dimensionTalley = [];

  dimensionData.forEach((dd) => {
    if (
      selectedQuestion?.dimension === Number(dd.dimension) &&
      selectedQuestion?.factor === Number(dd.section) &&
      selectedQuestion?.question === Number(dd.question) &&
      !dimensionTalley.find(
        (dt) => dt.question === dd.question && dt.section === dd.section
      )
    ) {
      const factorLabel = getFactorLabel(dd, question, isFrench);
      dimensionTalley.push(dd);
      dimensionQuestions.push({
        question: dd.question,
        dimension: dd.dimension,
        section: dd.section,
        factor: factorLabel,
      });
    }
  });

  const dimensionAverages = dimensionQuestions
    .map((dq) => {
      let reverse = false;
      const questionResponses = data.filter(
        (dd) =>
          dd.question === dq.question &&
          dd.dimension === dq.dimension &&
          dd.section === dq.section
      );

      questionResponses.forEach((r) => {
        if (fetchReverseOption(r, question)) {
          reverse = true;
        }
      });

      let n = 0;

      const questionAvg =
        questionResponses.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) / n || 0;

      return {
        question: dq,
        average: questionAvg,
        groups: questionResponses,
        reverse: reverse,
      };
    })
    .sort((a, b) => {
      let bAverage = b.average * 10;
      let aAverage = a.average * 10;

      if (a.reverse) {
        aAverage = 100 - aAverage;
      }

      if (b.reverse) {
        bAverage = 100 - bAverage;
      }

      return bAverage - aAverage;
    });

  const rankData = dimensionAverages
    .filter((item) => item?.groups?.length > 0)
    .filter((da) => {
      let n = 0;
      const avg =
        (da.groups.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) /
          n) *
          10 || 0;

      const average = da.reverse ? 100 - (avg * 10) / 10 : avg / 10;
      return average < 10; // Should be whitelabel config value.
    })
    .map((item) => {
      let n = 0;
      const avg =
        item.groups.reduce((total, b) => {
          n += b.n;

          return total + b.average * b.n;
        }, 0) / n || 0;
      const average = (avg * 10) / 10;
      const averagePrefix = item.reverse ? "**" : "";

      const tableData = tableTemplate.map((t) => {
        if (t.title === getTranslatedText("domain", lang, translations)) {
          return `${item.question.factor}`;
        }
        if (t.title === "AV") {
          return `${average.toFixed(1)}${averagePrefix}`;
        }
        if (!t.id) {
          return `${fetchQuestion(item.groups[0], question, isFrench)}`;
        }

        const groupData = item.groups.filter((g) => g.role === t.id);

        const itemAverage =
          (groupData?.length > 0 &&
            groupData.reduce((total, b) => {
              return total + b.average;
            }, 0)) ||
          null;

        const group =
          (groupData.length > 0 &&
            (!isNaN(itemAverage) && itemAverage / groupData.length) * 10) ||
          null;

        if (groupData?.length === 0) {
          return -1;
        }
        const groupAverage = group / 10;
        return `${groupAverage.toFixed(1)}`;
      });

      return tableData;
    });

  return rankData;
};

const getFactorLabel = (q, question, isFrench) => {
  return isFrench
    ? question.dimensions[q.dimension].factors[Number(q.section)]?.fr
    : question.dimensions[q.dimension].factors[Number(q.section)].title;
};

const fetchReverseOption = (q, question) => {
  return question.dimensions[q.dimension].factors[Number(q.section)].questions[
    Number(q.question)
  ]?.reverse;
};

const fetchQuestion = (q, question, isFrench) => {
  const questionFound =
    question?.dimensions[q.dimension].factors[Number(q.section)].questions[
      Number(q.question)
    ];

  return isFrench ? questionFound?.fr : questionFound?.q;
};

export const getTranslatedText = (
  textId,
  language,
  translationObj,
  subtext = false
) => {
  const text = translationObj[textId];
  if (subtext) {
    return text.subtext[language];
  }
  if (text) {
    return text[language];
  }
  return textId;
};
