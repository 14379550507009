import styled, { keyframes } from 'styled-components';
import React,{useState,useEffect} from 'react';
import Check from '../../assets/images/check-white.svg'

const Avatar = styled.img`
  height:50px;
  width:50px;
  border-radius:25px;
  margin-right:31px;

`
const Name = styled.div`
	font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-right:5px;
`

const Checked = styled.div`
  width: 22px;
  height: 22px;
  border: solid 2px #ffffff;
  background-color: #1e2172;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:11px;
  position:absolute;
  left:30px;
  top:30px;
`

const UnChecked = styled.div`
	width: 22px;
  height: 22px;
  border: solid 2px #1e2172;
  background-color: #ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:11px;
  font-size: 16px;
  font-weight: bold;
  color: #1e2172;
  font-family: 'Red Hat Display', sans-serif;
  position:absolute;
  left:30px;
  top:30px;
`

const CompleteAvatar = (props) =>{

	return (
		<div style={{display:'flex',position:'relative',alignItems:'center',flexDirection:'row',marginBottom:20}}>
			<Avatar src={props.pic} />
			{props.checked ? 
				<Checked><img src={Check}/></Checked>
				:<UnChecked>?</UnChecked>}
			<Name>{props.first}</Name>
			<Name>{props.last}</Name>
		</div>
		)


}

export default CompleteAvatar