import styled, { keyframes } from 'styled-components';
import React from "react";
import Progress from './Progress'

import Chart from "react-apexcharts";

const Component14 = () =>{
  const [selected,setSelected] = React.useState(false)


    return (
        <Container>

            <T1>Progress Tracker</T1>
            <Text delay={0.5}>
             <Blue>You're well on your way to improving your culture! </Blue>
             Your check-in path is optimized to get you to your goals. We'll make sure you're always making progress.
            </Text>

           
          <Progress completed={1} />

          <T2 delay={1.2}>12% of your goal achieved</T2>

          <ChartContainer delay={1.5}>
            <Chart
						options={data3.options}
						series={data3.series}
						type="line"
						height={350}
					/>
            </ChartContainer>
            

           

           
           

            
        </Container>
    )
}

export default Component14

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:40px;
    margin-top:10px;
    line-height:1.6;
    opacity:0;
    max-width:800px;


    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    background-color:#D4F5ED;
    padding:20px;
    border-radius:10px;
    margin-top:20px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    font-weight:bold;
    color:#2A3039;
    margin-top:20px;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const ChartContainer = styled.div`
    width:100%;
   
    margin-top:40px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`


const Blue = styled.span`
    color:#2D70E2;
    font-weight:500;
`


const data3 = {
  series: [{
    name: "Steps",
    data: [67, 68, 70, 71, 72, 74, 75, 77]
}],
	options: {
		chart: {
			type: "line",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 600,
			height: 400,
		},
    forecastDataPoints: {
      count: 6
    },
    markers: {
      size: 5,
      strokeColors: "#2D70E2",
    },
    grid:{
      padding:{
        right:100
      }
    },
    annotations:{
      points: [
        {
          x: 8,
          y: 77,
          marker: {
            size: 8,
          },
          label: {
            style:{
              background:'#2D70E2',
              color:'#fff',
              fontSize:'12px',
              fontWeight:600,
              padding:{
                left:10,
                right:10,
                top:5,
                bottom:5
              }
            },
            text: 'Your Goal',
            offsetY:-5
          }
        }
      ]
    },
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
		colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
		xaxis: {
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
				}
			}
		},
		yaxis: {
			min: 65,
			max: 80,
			decimalsInFloat: 1,
			title: {
				text: "Psychological Safety Scores",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
