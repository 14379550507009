import styled, { keyframes } from "styled-components";
import React from "react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  Subsubtext,
  barChartOptions,
} from "reports/DataJourney/components/common/styles";

const WhatNext = () => {
  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>What to do now?</ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Its time to put these actions and behaviors into practice. Heres how
        we'll accomplish that:
      </SubtextExtended>
      <UL delay={0.6}>
        <LI>
          <strong>Weekly check ins:</strong> We'll check in with you each week
          around your schedule to see how things are going. This will be brief,
          and designed only to help reinforce the actions and behaviors that
          you've commited to.
        </LI>
        <LI>
          <strong>Start practicing your action:</strong> Several respondents
          emphasized the need for clear role definitions and well-defined
          responsibilities. They mentioned the importance of understanding their
          role within the organization and having clarity on their tasks and
          objectives.
        </LI>
        <LI>
          <strong>Stay engaged: </strong>It won't be easy along the way, but
          trust the process, and the science backing your change journey.
          Consistent effort over long periods of time is the only way to
          success.
        </LI>
      </UL>
    </ScreenContainer>
  );
};

export default WhatNext;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const UL = styled.ol`
  margin-top: 40px;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const LI = styled.li`
  margin-bottom: 20px;
  line-height: 1.4;
`;
