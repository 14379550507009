export const feedbackAnalysisIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M51.5857 17.3684L50.6431 11.4106C50.5552 10.8465 50.0268 10.4635 49.4602 10.5505C48.8955 10.6398 48.5106 11.1697 48.6006 11.7339L48.9561 13.9824C44.2604 8.42949 37.3497 5.17285 29.9989 5.17285C21.0937 5.17285 12.8218 9.98457 8.41016 17.7311L10.2084 18.7545C14.2524 11.6534 21.8352 7.2418 29.9989 7.2418C36.7463 7.2418 43.0895 10.2355 47.3939 15.3389L45.2021 14.5807C44.6616 14.3953 44.0736 14.6803 43.8857 15.2206C43.6989 15.7606 43.9857 16.3495 44.5252 16.5365L50.2261 18.508C50.3372 18.5458 50.4513 18.5646 50.5645 18.5646C50.804 18.5646 51.0404 18.4811 51.2293 18.3232C51.5068 18.0897 51.6432 17.727 51.5857 17.3684Z" fill="#D5E2F9"/>
<path d="M48.7767 42.864C44.5236 49.06 37.5044 52.7589 29.9994 52.7589C24.6565 52.7589 19.6246 50.9316 15.5678 47.5861L18.1877 48.0295C18.7513 48.1275 19.2848 47.7457 19.3809 47.1824C19.4758 46.6192 19.097 46.0853 18.5333 45.9898L12.586 44.9831C12.2283 44.9201 11.8636 45.0539 11.6273 45.3302C11.3909 45.6061 11.3172 45.9859 11.4323 46.3309L13.3426 52.0523C13.487 52.4852 13.8901 52.7596 14.3235 52.7596C14.4316 52.7596 14.5427 52.7419 14.6508 52.706C15.1933 52.5247 15.4853 51.9387 15.3045 51.3968L14.6849 49.5408C19.0341 52.9672 24.3601 54.828 29.9995 54.828C38.1865 54.828 45.844 50.7932 50.482 44.0352L48.7767 42.864Z" fill="#D5E2F9"/>
<path d="M3.10348 15.5173H34.1379C35.8519 15.5173 37.2414 16.9068 37.2414 18.6208V35.1725C37.2414 36.8865 35.8519 38.276 34.1379 38.276H14.4827L8.70176 44.0569C7.78102 44.9777 6.20684 44.3255 6.20684 43.0235V38.276H3.10348C1.38949 38.276 0 36.8865 0 35.1725V18.6208C0 16.9067 1.38949 15.5173 3.10348 15.5173Z" fill="#D5E2F9"/>
<path d="M20.6895 38.276H34.1377C35.8516 38.276 37.2411 36.8865 37.2411 35.1725V19.6553H25.8619C23.0053 19.6553 20.6895 21.9711 20.6895 24.8277V38.276Z" fill="#C7CFE2"/>
<path d="M8.27668 21.7238H5.1732C4.60145 21.7238 4.13867 21.2606 4.13867 20.6893C4.13867 20.118 4.60133 19.6548 5.1732 19.6548H8.27668C8.84844 19.6548 9.31121 20.118 9.31121 20.6893C9.31121 21.2606 8.84844 21.7238 8.27668 21.7238Z" fill="#9FA3AA"/>
<path d="M32.0686 21.7238H12.4134C11.8417 21.7238 11.3789 21.2606 11.3789 20.6893C11.3789 20.118 11.8416 19.6548 12.4134 19.6548H32.0686C32.6404 19.6548 33.1031 20.118 33.1031 20.6893C33.1031 21.2606 32.6405 21.7238 32.0686 21.7238Z" fill="#B3B3B3"/>
<path d="M12.4126 25.862H5.17125C4.59949 25.862 4.13672 25.3988 4.13672 24.8275C4.13672 24.2562 4.59937 23.793 5.17125 23.793H12.4126C12.9844 23.793 13.4471 24.2562 13.4471 24.8275C13.4471 25.3988 12.9844 25.862 12.4126 25.862Z" fill="#B3B3B3"/>
<path d="M16.5502 23.7927C15.9784 23.7927 15.5156 24.256 15.5156 24.8273C15.5156 25.3985 15.9783 25.8618 16.5502 25.8618H24.8261V23.7928H16.5502V23.7927Z" fill="#9FA3AA"/>
<path d="M18.6196 30H5.17125C4.59949 30 4.13672 29.5367 4.13672 28.9654C4.13672 28.3942 4.59937 27.9309 5.17125 27.9309H18.6196C19.1913 27.9309 19.6541 28.3942 19.6541 28.9654C19.6541 29.5367 19.1913 30 18.6196 30Z" fill="#9FA3AA"/>
<path d="M8.27473 34.1374H5.17125C4.59949 34.1374 4.13672 33.6742 4.13672 33.1029C4.13672 32.5316 4.59937 32.0684 5.17125 32.0684H8.27473C8.84648 32.0684 9.30926 32.5316 9.30926 33.1029C9.30926 33.6742 8.84648 34.1374 8.27473 34.1374Z" fill="#9FA3AA"/>
<path d="M12.4134 32.0691C11.8417 32.0691 11.3789 32.5323 11.3789 33.1036C11.3789 33.6749 11.8416 34.1382 12.4134 34.1382H24.8272V32.0692H12.4134V32.0691Z" fill="#B3B3B3"/>
<path d="M21.75 21.7243H32.0714C32.6432 21.7243 33.1059 21.2611 33.1059 20.6898C33.1059 20.1185 32.6433 19.6553 32.0714 19.6553H25.8646C24.1778 19.6554 22.6942 20.475 21.75 21.7243Z" fill="#9FA3AA"/>
<path d="M24.8293 32.0691H20.6914V34.138H24.8293V32.0691Z" fill="#9FA3AA"/>
<path d="M56.8957 21.7239H25.8613C24.1473 21.7239 22.7578 23.1134 22.7578 24.8274V41.379C22.7578 43.093 24.1473 44.4825 25.8613 44.4825H45.5164L51.2974 50.2635C52.2182 51.1842 53.7923 50.5321 53.7923 49.23V44.4825H56.8958C58.6098 44.4825 59.9993 43.093 59.9993 41.379V24.8274C59.9992 23.1134 58.6097 21.7239 56.8957 21.7239Z" fill="#27CDA7"/>
<path d="M45.5602 29.3617L40.1917 35.6231L37.8621 32.5177C37.5186 32.059 36.871 31.9681 36.4144 32.3105C35.9568 32.6529 35.8639 33.3015 36.2073 33.7581L39.3107 37.896C39.4986 38.1475 39.7906 38.299 40.1048 38.3092C40.1159 38.3104 40.1271 38.3104 40.1382 38.3104C40.4392 38.3104 40.7272 38.179 40.9231 37.9487L47.1301 30.7074C47.5028 30.2739 47.4523 29.6214 47.0179 29.2497C46.5855 28.8778 45.9321 28.9263 45.5602 29.3617Z" fill="white"/>
</svg>

    )
}