function randn_bm(min, max, skew) {
	let u = 0,
		v = 0;
	while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
	while (v === 0) v = Math.random();
	let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

	num = num / 10.0 + 0.5; // Translate to 0 -> 1
	if (num > 1 || num < 0) num = randn_bm(min, max, skew);
	// resample between 0 and 1 if out of range
	else {
		num = Math.pow(num, skew); // Skew
		num *= max - min; // Stretch to fill range
		num += min; // offset to min
	}
	return num;
}

function randomIntFromInterval(min, max) {
	// min and max included
	return Math.floor(Math.random() * (max - min + 1) + min);
}

const dates = [
	"March 4,2022",
	"March 4,2021",
	"March 4,2020",
	"March 4,2019",
	"March 4,2018",
	"March 4,2017",
	"March 4,2016",
	"March 4,2015",
	"March 4,2014",
	"March 4,2013",
];

export const Data_Generator = (points, history, categories, questions) => {
	let responses = [];

	for (let i = 0; i < history; i++) {
		let _package = { id: i + 1, date: dates[i], type: "COC" };

		let response_list = generate_responses(points, categories, questions);
		_package["responses"] = response_list;

		responses.push(_package);
	}

	return responses;
};

const getminMax = () => {
	let min = randomIntFromInterval(0, 8);
	let max = randomIntFromInterval(min + 1, 10);

	return [min, max];
};

const generate_responses = (points, categories, questions) => {
	let responses = [];
	let min_max = categories.categories[1].options
		.map((item, i) => i)
		.map((item) => getminMax());
	let skew = (val) => randomIntFromInterval(0, 101) / 40;
	for (let i = 0; i < points; i++) {
		let resp = { id: i + 1, categories: [], questions: [] };
		categories.categories.map((item, idx) => {
			let selection = randomIntFromInterval(0, item.options.length - 1);
			//Check selection subfactor existance
			let subfactor = null;
			if (
				item.subfactors.filter((f) => f.parent == selection).length > 0
			) {
				let sub_id = item.subfactors.findIndex(
					(e) => e.parent == selection
				);
				subfactor = randomIntFromInterval(
					0,
					item.subfactors[sub_id].options.length - 1
				);
			}
			resp.categories.push({ id: idx, response: selection, subfactor });
		});

		let sport = resp.categories[0].subfactor;
		let secondary = resp.categories[1].response;

		questions.factors.map((item, idx) => {
			let factor = idx;
			let score = randn_bm(
				min_max[secondary][0],
				min_max[secondary][1],
				skew(idx)
			);
			item.questions.map((q, q_id) => {
				resp.questions.push({
					factor,
					q: q_id,
					response: score,
				});
			});
		});

		responses.push(resp);
	}

	return responses;
};
