import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress,Checkbox } from 'semantic-ui-react'


const TableRow = (props) =>{

	return (
		<TR>	
			<TD width={25} borderLeft={false} borderRight={true}>
				<Avatar src={'https://stg-app.innerlogic.com/static/media/user.1416ec52.svg'}/>
				<Name>Michael Bawol</Name>
			</TD>
			<TD2 width={12.5} borderRight={true} bold score={props.score}>
				{props.score}
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				66
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				88
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				100
			</TD2>
			<TD2 width={12.5} borderRight={true}>
				80
			</TD2>
			<TD2 width={12.5} borderRight={false}>
				65
			</TD2>
		</TR>
		)
}

const Avatar = styled.img`
	width:20px;
	height:20px;
	border-radius:10px;
	margin-right:5px;
`

const Name = styled.div`
	
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	padding:3px 6px;
	border-right:${props=>props.borderRight?'1px solid black':'none'};
	border-left:${props=>props.borderLeft?'1px solid black':'none'};
`

const TD2 = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	padding:3px 6px;
	border-right:${props=>props.borderRight?'1px solid black':'none'};
	border-left:${props=>props.borderLeft?'1px solid black':'none'};
	justify-content:center;
	font-weight:${props=>props.bold?'bold':400};
	background-color:${props=>props.score?'rgba(48, 120, 255,'+props.score/100+')':'white'};
`

const Card = (props) =>{

	return (
		<SumCard>
			<Area1>
				<TypeName>{props.title}</TypeName>
				<Description>We sustained our best all the way through this performance, from start to finish.</Description>
			</Area1>
			<Score>{props.score}%</Score>
		</SumCard>
		)
}

const SumCard = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:350px;
	height:200px;
	display:flex;
	margin-bottom:10px;
	padding:30px;
`

const TypeName = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
`

const Description = styled.div`
	color:#757575;
`

const Area1 = styled.div`
	width:50%;

`

const Score = styled.div`
	width:50%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:40px;
	font-weight:bold;
`

const Game = () => {

	return (
		<Container>
			<Top>
				<Title>Performance Debrief</Title>
				<CheckContainer>
					<Checkbox label='Members'  style={{marginRight:10}} checked={true}/>
					<Checkbox label='Leaders' />
				</CheckContainer>
			</Top>

			<SummaryCards>
				<Card title={"Average"} score={80} />
				<Card title={"Consistency"} score={80} />
				<Card title={"Composure"} score={80} />
				<Card title={"Confidence"} score={80} />
				<Card title={"Compete"} score={80} />
				<Card title={"Cohesion"} score={80} />
			</SummaryCards>

			<Table>

				<TR>
					<TH width={25} borderLeft={false} borderRight={true}>
						Member
					</TH>
					<TH width={12.5} borderRight={true} center={true}>
						Average
					</TH>
					<TH width={12.5} borderRight={true} center={true}>
						Consistency
					</TH>
					<TH width={12.5} borderRight={true} center={true}>
						Composure
					</TH>
					<TH width={12.5} borderRight={true} center={true}>
						Confidence
					</TH>
					<TH width={12.5} borderRight={true} center={true}>
						Compete
					</TH>
					<TH width={12.5} borderRight={false} center={true}>
						Cohesion
					</TH>
				</TR>

				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>
				<TableRow score={Math.floor(Math.random(0,1)*100)}/>

			</Table>
		</Container>
		)
}



export default Game 


const Container = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`


const Top = styled.div`
	height:70px;
	margin-top:10px;
	border-bottom:1px solid #ebebeb;
	width:65%;
	display:flex;
	align-items:center;
	padding:10px;
	position:relative;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-right:20px;
`
const CheckContainer = styled.div`
	position:absolute;
	right:0;
`

const Table = styled.div`
	width:100%;
`
const TR = styled.div`
	width:100%;
	height:35px;
	display:flex;
	border-top:1px solid black;
	border-bottom:1px solid black;
	
`

const TH = styled.div`
	color:white;
	background-color:#3078ff;
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	padding:3px 6px;
	font-weight:bold;
	border-right:${props=>props.borderRight?'1px solid black':'none'};
	border-left:${props=>props.borderLeft?'1px solid black':'none'};
	justify-content:${props=>props.center?'center':'flex-start'};
`

const SummaryCards = styled.div`
	display:flex;
	width:100%;
	justify-content:space-evenly;
	flex-wrap:wrap;
	margin-top:30px;
	margin-bottom:30px;
`