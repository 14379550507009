import styled from "styled-components";
import React, { useEffect, useState } from "react";
import {
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_OUTCOME_QUESTIONS,
  SG_GET_DEBRIEF_SCHEDULES,
  SET_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
} from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ScoreContainer from "./ScoreContainer";
import Recommendation from "./Recommendation";
import CustomDropdown from "./Dropdown";
import RecommendationsModal from "./RecommendationsModal";
import { format } from "date-fns";
import { Dimmer } from "semantic-ui-react";
import Empty from "./empty";
import {
  getDataByQuarter,
  getAnchorAveragesByQuestion,
} from "../Outcomes/calculations";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import LogAnimation from "./LogAnimation";
import Options from "./Options";

import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  get_by_id,
  change_data,
} from "reports/Audit/data_navigation/calculate_data";

// Calculations
import {
  calculate_table_data,
  getCategoryFromOption,
  retrievePillarData,
  // change_data,
  getPrimaryId,
  getSecondaryId,
  getOptionName,
  getLastFactorAverage,
  getSortOrders,
  restructure_Questions,
  getCultureAverages,
  convert_personality_to_categories,
  checkPersonality,
  convert_DEI,
  check_comments,
} from "./calculations";

import useGetOrgData from "hooks/useGetOrgData";
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetUserSettings from "hooks/useGetUserSettings";

const findSortOrderId = (report, debriefs) => {
  const primary = report?.name;
  const reportDate = format(new Date(report.date), "MMM d yyyy");
  const regex = /(\d*\-\d*\-\d*)/;
  const matchingDebrief = debriefs?.debrief_schedule?.results?.find(
    (schedule) => {
      const scheduleDate = regex.exec(schedule?.scheduled_for);
      const dateFormatted = format(new Date(scheduleDate[0]), "MMM d yyyy");
      return schedule?.name === primary && reportDate === dateFormatted;
    }
  );
  return matchingDebrief?.brief_question;
};

const get_latest_recommendations = (reports, debriefs, surveys) => {
  let latest_recommendations = [];
  reports?.response?.forEach((report) => {
    if (Array.isArray(report?.ai_results?.survey_summary) && report.ai_results.survey_summary.length > 0) {
      let sort_order_id = findSortOrderId(report, debriefs);
      const survey = surveys?.find((survey) => survey.id === sort_order_id);
      const summaryData = report.ai_results.survey_summary
        .filter((f) => f.version === "v1")
        .map((item) => {
          return {
            ...item,
            date: format(new Date(report.date), "MMM d yyyy"),
            survey: survey?.questions,
          };
        });
      latest_recommendations.push(...summaryData);
    }
  });
  return latest_recommendations;
};


const checkResponses = (f) => {
  if (f.ai_result && f.points && f.title && f.min_summary && f.max_summary) {
    return true;
  }

  return false;
};

const get_title = (text) => {
  let n = text?.toLowerCase().indexOf(`title:`);
  let n2 = text?.toLowerCase().indexOf(`synopsis:`);
  return [text.slice(n + 6, n2), text.slice(n2 + 9)];
};

const format_responses = (responses) => {
  let formatted_responses = [];
  responses.forEach((response) => {
    let ai_summary = response.ai_summary.response;

    let formatted_response = {
      ai_result: ai_summary.find((f) => f.name === "result1")?.content,
      //    ai_justification: response.result_list_1,
      //    obsctacle: response.result_list_2,
      //    solutions: response.result_list_3,
      points: ai_summary.find((f) => f.name === "result3")?.content,
      title: ai_summary.find((f) => f.name === "result6")?.content,
      min_summary: ai_summary.find((f) => f.name === "summary_clusters_min")
        ?.content,
      max_summary: ai_summary.find((f) => f.name === "summary_clusters_max")
        ?.content,
      factor: response.factor,
      dimension: response.dimension,
      primary: response.primary_factor,
      date: response.date,
      survey: response.survey,
    };
    formatted_responses.push(formatted_response);
  });

  return formatted_responses.filter((f) => checkResponses(f));
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const auditTypeId = 3;
  const surveyType = 10;
  const [loading, setLoading] = useState(true);
  const [rec, setRec] = useState();
  const [filteredRecs, setFilteredRecs] = useState();
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [questionsList, setQuestionsList] = useState([]);
  const [outcomesData, setOutcomesData] = useState();
  const [firstDialData, setFirstDialData] = useState();
  const [secondDialData, setSecondDialData] = useState();
  const [cultureScore, setCultureScore] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [dropdownOptions, setDropdownOptions] = useState();
  const [dropdownLabel, setDropdownLabel] = useState();
  const [quarterlyData, setQuarterlyData] = useState();
  const { organizationData } = useGetOrgData();
  const [selectedSecondary, setSelectedSecondary] = useState();
  const { orgSurveyQuestions, WhiteLabel, get_survey_questions } =
    useGetSurveyData({
      surveyType: 6,
      auditTypeId: 3,
    });
  const { filteredCategories, isAdmin } = useGetUserSettings();
  const [selectedPrimary, setSelectedPrimary] = useState();
  const [primaryFilters, setPrimaryFilters] = useState();
  const [calculated, setCalculated] = useState();
  const [calculatedSecondary, setCalculatedSecondary] = useState();
  const [refetch, setRefetch] = useState(false);
  const [filterArray, setFilterArray] = useState([]);
  const [lastData, setLastData] = useState([]);
  const [fakeLoad, setFakeLoad] = useState(false);
  const [firstDialLabel, setFirstDialLabel] = useState();
  const [secondDialLabel, setSecondDialLabel] = useState();
  const [sortOrderList, setSortOrderList] = useState();
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysList, setSurveysList] = useState();
  const [debriefsRefreshed, setDebriefsRefreshed] = useState(false);
  const [reportData, setReportData] = useState();
  const [updated, setUpdated] = useState(false);
  const [rule, setRule] = useState(2);

  const { get_debrief_schedules, get_survey_structure } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_debrief_schedules: state.debrief_schedule,
    }),
    shallowEqual
  );

  const { reports, reportsPending } = useSelector(
    (state) => ({
      reports: state.debrief_schedule.culture_audit_reports,
      reportsPending: state.debrief_schedule?.reportsPending,
    }),
    shallowEqual
  );

  const organization = useSelector((state) => {
    return state.organizations;
  });

  const surveyQuestions = useSelector((state) => {
    return state.surveyquestions.outcome_questions;
  });

  // const { reportData } = useGetSurveyUtils({
  //   get_culture_audit_reports: reports,
  //   get_survey_structure,
  //   get_organizations: organization,
  //   get_survey_questions,
  //   nav_state: nav_state,
  //   get_auth,
  //   sportsFiltered: (primaryFilters && [primaryFilters]) || null,
  //   get_employee,
  //   filteredCategories,
  //   isAdmin,
  //   refetch: true,
  // });

  // Setting params previously used in hook
  const sportsFiltered = (primaryFilters && [primaryFilters]) || null;

  useEffect(() => {
    const DefState = build_default_State(get_survey_structure[0]);
    dispatch({
      type: SET_NAVIGATION,
      payload: DefState,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(WhiteLabel).includes("rule")) {
      setRule(WhiteLabel.rule);
    }
  }, [WhiteLabel]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure[0] && reports.response) {
      if (reports.response.length > 0) {
        let sorted_responses = reports.response.sort((a, b) => b.id - a.id);

        if (checkPersonality(organization)) {
          //Modify the results to include personality as a categorical variable
          sorted_responses =
            convert_personality_to_categories(sorted_responses);
        }

        if (surveyType === 7) {
          sorted_responses = convert_DEI(sorted_responses);
        }

        change_data(sorted_responses[0], 10);

        if (sportsFiltered?.length > 0) {
          sorted_responses = sorted_responses.filter((responseGroup) => {
            return sportsFiltered.includes(responseGroup.name);
          });
        }

        let most_recent;
        most_recent = get_most_recent(sorted_responses);

        let total = {};
        most_recent.responses?.map((r) => {
          let n = r.categories.length.toString();
          if (!(n in total)) {
            total[n] = 1;
          } else {
            total[n] += 1;
          }
        });

        most_recent["last"] = get_last_responses(sorted_responses);

        if (sorted_responses.length > 0 && most_recent.responses) {
          let historical_data = sort_historical_data(
            sorted_responses.reverse(),
            0
          );

          setReportData([most_recent, ...historical_data]);

          dispatch({
            type: SET_RAW_DATA,
            payload: [most_recent, ...historical_data],
          });

          dispatch({
            type: SET_DATA_DATES,
            payload: get_dates(historical_data),
          });
        }
      }
    }
  }, [dispatch, get_survey_structure, reports, organization]);

  const generate_recommendations = () => {
    return format_responses(
      get_latest_recommendations(
        reports,
        get_debrief_schedules,
        get_survey_questions
      )
    );
  };

  useEffect(() => {
    setFakeLoad(true);
    setSelectedPrimary();
    setSelectedSecondary();
    setPrimaryFilters();
    setFilterArray();
    setCalculated();
    setCalculatedSecondary();
    setTimeout(() => {
      setRefetch(!refetch);
      setFakeLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (
      sortOrderList &&
      get_survey_questions &&
      get_survey_questions.length > 0
    ) {

      const surveyQuestionsCopy = JSON.parse(
        JSON.stringify(get_survey_questions)
      );

      const mappedList = sortOrderList.map((i) => {
        if(i==0){
          return
        }
        
        let survey = JSON.parse(
          JSON.stringify(
            surveyQuestionsCopy.filter((sq) => sq.sort_order === i)?.[0]
          )
        );

        if (surveyType === 7) {
          survey = restructure_Questions(survey);
        }

        const surveyStructure = {
          title: survey?.questions?.title,
          dimensions: survey?.questions?.dimensions,
          type: survey?.questions?.type,
          type_name: survey?.questions?.type_name,
          comments: survey?.questions?.comments,
          sort_order: survey?.sort_order,
        };

        return surveyStructure;
      });
      setSurveysList(mappedList.filter(f=>f));
    }
  }, [sortOrderList, get_survey_questions]);
  

  useEffect(() => {
    if (
      get_debrief_schedules?.debrief_schedule?.results?.length > 0 &&
      get_survey_questions?.length > 0
    ) {
      const sortOrders = getSortOrders(
        get_debrief_schedules,
        get_survey_questions
      );
     

      setSortOrderList(sortOrders);
    } else {
      if (organizationData?.styling) {
        const sortOrder = organizationData?.services_enabled?.find(
          (item) => item.name === "Audit"
        )?.question_sort_order;
        setSortOrderList([sortOrder]);
      }
    }
  }, [get_debrief_schedules, get_survey_questions, organizationData]);

  useEffect(() => {
    if (sortOrderList && surveysList && !selectedSurvey) {
      const selectedSortOrder = sortOrderList[0];

      const selected = surveysList?.find((survey) => {
        return selectedSortOrder === survey?.sort_order;
      });

      if (selected) {
        setSelectedSurvey(selected);
        dispatch({
          type: SET_QUESTIONS,
          payload: selected,
        });
      }
    }
  }, [sortOrderList, surveysList]);

  useEffect(() => {
    if (selectedPrimary && calculatedSecondary) {
      setFakeLoad(true);
      setTimeout(() => {
        setFakeLoad(false);
      }, 750);
    }
  }, [selectedPrimary]);

  useEffect(() => {
    if (selectedSecondary && calculatedSecondary) {
      setFakeLoad(true);
      setTimeout(() => {
        setFakeLoad(false);
      }, 750);
    }
  }, [selectedSecondary]);

  /** useEffect Hooks
   * 1. Calculate data for table
   * 2. Get column config for table
   * 3. Get table data
   * 4. Get URI
   * 5. Get Spider options
   */
  useEffect(() => {
    if (
      (!calculated || (!calculated?.responses?.length > 0 && !updated)) &&
      reportData &&
      reportData?.length > 0 &&
      get_survey_structure?.[0] &&
      selectedSurvey
    ) {
      const primaryAnchor = get_survey_structure[0].categories?.find(
        (cat) => cat.priority === "primary"
      )?.id;

      const hasSecondary = get_survey_structure?.[0]?.categories?.find(
        (cat) => cat?.priority === "secondary"
      );

      setUpdated(true);

      if (hasSecondary) {
        const secondaryAnchor = getSecondaryId(get_survey_structure[0]);

        const calculatedData = calculate_table_data(
          reportData,
          filterArray,
          get_survey_structure[0],
          primaryAnchor,
          rule,
          selectedSurvey
        );
        setCalculated(calculatedData);

        if (!calculatedSecondary && selectedPrimary) {
          setCalculatedSecondary(
            calculate_table_data(
              reportData,
              filterArray,
              get_survey_structure[0],
              secondaryAnchor,
              rule,
              selectedSurvey,
              true
            )
          );
        }
      }
    }
  }, [
    reportData,
    get_survey_structure[0],
    selectedSurvey,
    selectedPrimary,
    filterArray,
    calculated,
    rule,
  ]);

  useEffect(() => {
    let arrayTemp = [];
    if (selectedPrimary) {
      const primaryObj = getCategoryFromOption(
        selectedPrimary,
        get_survey_structure[0].categories
      );
      if (primaryObj) {
        arrayTemp.push(primaryObj);
      }
      if (selectedSecondary) {
        const secondaryObj = getCategoryFromOption(
          selectedSecondary,
          get_survey_structure[0].categories
        );
        arrayTemp.push(secondaryObj);
      }
    }
    setFilterArray(arrayTemp);
    setCalculated();
  }, [selectedPrimary, selectedSecondary, get_survey_structure]);

  useEffect(() => {
    dispatch({
      type: SG_GET_OUTCOME_QUESTIONS,
      payload: `survey_type=${surveyType}&status=2`,
    });
  }, []);

  useEffect(() => {
    if (organization?.organization?.id) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `${organization?.organization?.id}`,
      });
    }
  }, [organization]);

  useEffect(() => {
    if (
      filteredCategories?.length > 0 &&
      !isAdmin &&
      get_survey_structure.length > 0
    ) {
      if (filteredCategories[0]?.id) {
        setSelectedPrimary(filteredCategories[0]?.id);
      } else {
        const primaryId = getPrimaryId(
          filteredCategories[0],
          get_survey_structure
        );
        setSelectedPrimary(primaryId);
      }
    }

    if (isAdmin && get_survey_structure?.length > 0) {
      const primary = get_survey_structure[0].categories.find(
        (f) => f.priority.toLowerCase() === "primary"
      ).id;

      setSelectedPrimary(primary);
    }

    setUpdated(false);
  }, [filteredCategories, get_survey_structure, isAdmin]);

  useEffect(() => {
    if (!reportsPending) {
      if (reports?.response?.length > 0) {
        let data = getDataByQuarter(reports?.response);
        setQuarterlyData(data);
      } else {
        setQuarterlyData([]);
      }
    }
  }, [reports, selectedSecondary, selectedPrimary, reportsPending]);

  // Get the sort order for the outcome questions.
  useEffect(() => {
    if (organizationData?.styling) {
      const sortOrder = organizationData?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;
      setSortOrder(sortOrder);
    }
  }, [sortOrderList]);

  // Get the survey questions for the outcome survey.
  useEffect(() => {
    setLoading(true);
    if (surveyQuestions?.length > 0 && sortOrder) {
      const questions = surveyQuestions?.find((item) => {
        return item.sort_order === sortOrder;
      })?.questions;
      if (questions?.length > 0) {
        setQuestionsList(questions);
      }
    }
  }, [surveyQuestions, sortOrder]);

  // When we have quarterly data, calculate the averages for the selected question.
  useEffect(() => {
    if (
      quarterlyData?.length > 0 &&
      questionsList?.length > 0 &&
      get_survey_structure?.[0]
    ) {
      setLoading(true);
      const qOneId = questionsList?.[0]?.id;
      const qTwoId = questionsList?.[1]?.id;
      const primaryAnchor = get_survey_structure[0].categories?.find(
        (cat) => cat.priority === "primary"
      )?.id;

      let useAnchor = selectedSecondary
        ? getSecondaryId(get_survey_structure[0])
        : primaryAnchor;

      let firstDialData;
      let secondDialData;

      if (!isNaN(qOneId)) {
        firstDialData = getAnchorAveragesByQuestion(
          quarterlyData,
          useAnchor,
          qOneId,
          get_survey_structure?.[0],
          rule
        );

        const firstDialLabel = questionsList?.[0]?.name;
        setFirstDialLabel(firstDialLabel);

        firstDialData[0]?.anchorData
          .sort((a, b) => b.average - b.average)
          .reverse();
      } else {
        setFirstDialData([]);
      }

      if (!isNaN(qTwoId)) {
        const secondDialLabel = questionsList?.[1]?.name;
        setSecondDialLabel(secondDialLabel);
        secondDialData = getAnchorAveragesByQuestion(
          quarterlyData,
          useAnchor,
          qTwoId,
          get_survey_structure?.[0],
          rule
        );
        secondDialData[0]?.anchorData
          .sort((a, b) => b.average - a.average)
          .reverse();
      } else {
        setSecondDialData([]);
      }

      setFirstDialData(firstDialData);
      setSecondDialData(secondDialData);
      setLoading(false);
    }

    if (
      quarterlyData?.length === 0 &&
      get_survey_structure?.[0] &&
      !reportsPending
    ) {
      setFirstDialData([]);
      setSecondDialData([]);
      setLoading(false);
    }
  }, [
    quarterlyData,
    surveyQuestions,
    questionsList,
    get_survey_structure,
    reportsPending,
    rule,
  ]);

  useEffect(() => {
    if (organizationData?.services_enabled?.length > 0) {
      const serviceObject = organizationData?.services_enabled?.find(
        (service) => service?.name === "Audit"
      );
      const sortOrder = serviceObject.question_sort_order;

      if (orgSurveyQuestions.length > 0 && sortOrder) {
        const questions = orgSurveyQuestions.find(
          (question) => question.sort_order === sortOrder
        );

        if (questions?.questions?.outcomes) {
          setOutcomesData(questions?.questions?.outcomes);
        } else {
          setOutcomesData([]);
        }
      }
    }
  }, [orgSurveyQuestions, organizationData]);

  useEffect(() => {
    if (organization?.organization?.id) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page_size=1000&type_of=3&organization=${organization?.organization?.id}`,
      });
      setTimeout(() => {
        setDebriefsRefreshed(true);
      }, 500);
    }
  }, [organization]);

  useEffect(() => {
    if (!reports?.response) {
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: `audit-type-id=${auditTypeId}`,
      });
    }
  }, []);

  useEffect(() => {
    if (
      reports?.response?.length > 0 &&
      selectedPrimary &&
      get_debrief_schedules &&
      surveysList &&
      debriefsRefreshed
    ) {
      let recommendations = generate_recommendations();
      if (recommendations?.length > 0) {
        setRec(recommendations);
      } else {
        setRec([]);
        setFilteredRecs([]);
      }
    }
  }, [reports, get_debrief_schedules, surveysList]);

  useEffect(() => {
    if (
      rec?.length > 0 &&
      selectedPrimary &&
      get_survey_structure?.length > 0 &&
      calculatedSecondary &&
      calculatedSecondary?.length > 0 &&
      calculated &&
      calculated?.responses?.length > 0
    ) {
      const primaryOptions = get_survey_structure[0]?.categories.find(
        (category) => category.priority === "primary"
      ).options;

      const selectedPrimaryName = primaryOptions.find(
        (option) => option.id === selectedPrimary
      )?.name;

      const filteredData =
        (selectedPrimaryName &&
          rec.filter((item) => item.primary === selectedPrimaryName)) ||
        rec;

      if (filteredData?.length > 0) {
        const topRecs = filteredData.map((item) => {
          const primaryId = getPrimaryId(item?.primary, get_survey_structure);
          const secondaryData = calculatedSecondary?.find(
            (d) => d.primary === primaryId
          );

          const pillarData = retrievePillarData(
            item.factor,
            item?.survey,
            calculated,
            primaryId,
            secondaryData
          );

          return { ...item, pillarData };
        });

        setFilteredRecs(topRecs || []);
      } else {
        setFilteredRecs(filteredData || []);
      }
    }
  }, [
    rec,
    selectedPrimary,
    get_survey_structure,
    calculatedSecondary,
    selectedSurvey,
    calculated,
  ]);

  useEffect(() => {
    if (rec && outcomesData && !reportsPending) {
      setLoading(false);
    }
  }, [rec, outcomesData, reportsPending]);

  useEffect(() => {
    if (selectedPrimary) {
      const overviewSelected =
        selectedPrimary ===
        get_survey_structure[0].categories.find(
          (f) => f.priority.toLowerCase() === "primary"
        ).id;
      if (overviewSelected) {
        const foundCategory = get_survey_structure[0].categories.find(
          (category) => category?.priority.toLowerCase() === "primary"
        );
        const optionsList = foundCategory?.options?.map((option) => {
          return {
            key: option.id,
            text: option.name,
            value: option.id,
          };
        });
        setDropdownLabel(foundCategory?.name);
        setDropdownOptions(optionsList);
      } else {
        const foundCategory = get_survey_structure[0].categories.find(
          (category) => category?.priority.toLowerCase() === "secondary"
        );
        const optionsList = foundCategory?.options?.map((option) => {
          return {
            key: option.id,
            text: option.name,
            value: option.id,
          };
        });
        setDropdownLabel(foundCategory?.name);
        setDropdownOptions(optionsList);
      }
    }
  }, [selectedPrimary]);

  useEffect(() => {
    if (calculated && calculated?.responses?.length > 0) {
      const defaultPrimary =
        selectedPrimary ===
        get_survey_structure?.[0]?.categories.find(
          (f) => f.priority.toLowerCase() === "primary"
        ).id;

      if (!defaultPrimary) {
        const cultureAvg = getCultureAverages(calculated, selectedPrimary);
        setCultureScore(cultureAvg);
      } else {
        const cultureAvg = getCultureAverages(calculated);
        setCultureScore(cultureAvg);
      }
    } else {
      setCultureScore(0);
    }
  }, [calculated, selectedSecondary]);

  const getSecondaryBreadcrumb = (id) => {
    const foundCategory = get_survey_structure[0].categories.find(
      (category) => category?.priority.toLowerCase() === "secondary"
    );
    const foundOption = foundCategory?.options.find(
      (option) => option.id.toLowerCase() === id.toLowerCase()
    );
    return <>/ {foundOption?.name} </>;
  };

  const openModal = (data) => {
    setOpen(true);
    setSelectedData(data);
  };

  const createUnstructuredBreadcrumb = (id, secondary) => {
    if (get_survey_structure?.length === 0) return;

    const foundCategory = get_survey_structure[0].categories.find(
      (category) => category?.priority.toLowerCase() === "primary"
    );
    const foundOption = foundCategory?.options.find(
      (option) => option.id === id
    );

    if (id && foundOption) {
      if (secondary) {
        return (
          <>
            /{" "}
            <Item
              onClick={() => {
                setSelectedPrimary(id);
                setSelectedSecondary(undefined);
                setUpdated(false);
              }}
            >
              {foundOption?.name}{" "}
            </Item>
          </>
        );
      } else {
        return <>/ {foundOption?.name} </>;
      }
    }
  };

  const createBreadcrumb = (data) => {
    let selected = data.id === selectedPrimary;
    if (!data?.id) {
      selected = filteredCategories[0] === data;
    }
    if (selected) {
      if (!selectedSecondary) {
        return <>/ {data?.name || data} </>;
      } else {
        return (
          <>
            /{" "}
            <Item
              onClick={() => {
                setSelectedPrimary(
                  data?.id || getPrimaryId(data, get_survey_structure)
                );
                setSelectedSecondary(undefined);
                setUpdated(false);
              }}
            >
              {data.name || data}{" "}
            </Item>
          </>
        );
      }
    } else {
      return null;
    }
  };

  const defaultPrimary =
    selectedPrimary ===
    get_survey_structure?.[0]?.categories.find(
      (f) => f.priority.toLowerCase() === "primary"
    ).id;

  const retrieveScore = (id, scores, secondaryId) => {
    if (id && scores?.length > 0 && !secondaryId) {
      const score = !defaultPrimary
        ? Number(
            scores?.[scores?.length - 1]?.anchorData?.find(
              (f) => f.option === selectedPrimary
            )?.average
          ) * 10
        : Number(scores?.[scores?.length - 1]?.overallAverage) * 10;
      return score;
    }

    if (scores?.length > 0 && secondaryId) {
      const score =
        Number(
          scores?.[scores?.length - 1]?.anchorData?.find(
            (f) => f.option === secondaryId
          )?.average
        ) * 10;
      return score;
    }
  };

  const retrieveChange = (id, scores, secondaryId) => {
    let currentScore;
    let lastScore;
    //   Get the current score using a similar pattern to the above function
    if (id && scores?.length > 0 && !secondaryId) {
      const score = !defaultPrimary
        ? Number(
            scores?.[scores?.length - 1]?.anchorData?.find(
              (f) => f.option === selectedPrimary
            )?.average
          ) * 10
        : Number(scores?.[scores?.length - 1]?.overallAverage) * 10;
      currentScore = score;
    }

    if (scores?.length > 0 && secondaryId) {
      const score =
        Number(
          scores?.[scores?.length - 1]?.anchorData?.find(
            (f) => f.option === secondaryId
          )?.average
        ) * 10;
      currentScore = score;
    }

    // retrieve scores from last quarter (if any)
    if (scores?.[scores?.length - 2]) {
      if (id && !secondaryId) {
        const score = !defaultPrimary
          ? Number(
              scores?.[scores?.length - 2]?.anchorData?.find(
                (f) => f.option === selectedPrimary
              )?.average
            ) * 10
          : Number(scores?.[scores?.length - 2]?.overallAverage) * 10;
        lastScore = score;
      }

      if (secondaryId) {
        const score =
          Number(
            scores?.[scores?.length - 2]?.anchorData?.find(
              (f) => f.option === secondaryId
            )?.average
          ) * 10;
        lastScore = score;
      }
    }

    // return diff if lastScore exists.
    if (lastScore) {
      return currentScore - lastScore;
    } else {
      return null;
    }
  };

  const generateRecItems = (recs) => {
    const elements = recs
      ?.sort((a, b) => a.pillarData?.primaryAvg - b.pillarData?.primaryAvg)
      .slice(0, 3)
      .map((item, index) => {
        const text = get_title(item.title);

        try {
          const impactLabel = getOptionName(
            item.pillarData?.lowestAverage?.role,
            get_survey_structure
          );

          return (
            <Recommendation
              key={index}
              testData={item}
              data={item}
              onClick={() => setSelectedData(item)}
              setOpen={() => openModal(item)}
              text={text[1]}
              title={text[0]}
              impactLabel={impactLabel}
              pillarData={item.pillarData}
            />
          );
        } catch {
          return (
            <Recommendation
              key={index}
              testData={item}
              data={item}
              onClick={() => setSelectedData(item)}
              setOpen={() => openModal(item)}
              text={text[1]}
              title={text[0]}
              impactLabel={""}
              pillarData={item.pillarData}
            />
          );
        }
      });

    if (elements.includes(null)) {
      return <Empty />;
    } else {
      return elements;
    }
  };

  const getSurveyOptions = (list) => {
    const options = list.map((survey) => {
      return {
        key: survey.sort_order,
        value: survey.sort_order,
        text: `${survey.title} (${survey.sort_order})`,
      };
    });

    return options;
  };
  let dialsNum = 0;

  dialsNum += firstDialData && firstDialData.length > 0 ? 1 : 0;
  dialsNum += secondDialData && secondDialData.length > 0 ? 1 : 0;
  dialsNum += reportData && filterArray && !isNaN(cultureScore) ? 1 : 0;


  if(reportData &&
    filterArray &&
    !isNaN(cultureScore) &&
    selectedSurvey){
    console.log(cultureScore,getLastFactorAverage(
      reportData,
      filterArray,
      selectedSurvey
    ))
  }
  

  return (
    <Dimmer.Dimmable
      blurring
      dimmed={(!organizationData && !dropdownOptions) || fakeLoad}
    >
      <Dimmer active={loading} inverted>
        <LogAnimation />
      </Dimmer>
      <Container>
        <RecommendationsModal
          open={open}
          setOpen={() => setOpen(false)}
          data={selectedData}
          title={selectedData?.title ? get_title(selectedData.title) : "Plan"}
          closeOnDimmerClick
        />
        <Menu>
          <T>
            {organizationData && (
              <Item
                selected={defaultPrimary || !isAdmin}
                onClick={() => {
                  const primaryId = get_survey_structure[0].categories.find(
                    (f) => f.priority.toLowerCase() === "primary"
                  ).id;
                  setSelectedPrimary(primaryId);
                  setPrimaryFilters(undefined);
                  setRefetch(!refetch);
                  setSelectedSecondary(undefined);
                  setUpdated(false);
                }}
              >
                {organizationData?.name}{" "}
              </Item>
            )}
            {(filteredCategories?.length > 0 &&
              filteredCategories.map((f) => {
                return createBreadcrumb(f);
              })) ||
              createUnstructuredBreadcrumb(selectedPrimary, selectedSecondary)}
            {!defaultPrimary &&
              selectedSecondary &&
              getSecondaryBreadcrumb(selectedSecondary)}
          </T>
          {dropdownOptions && (
            <CustomDropdown
              options={dropdownOptions}
              defaultLabel={`Select a ${dropdownLabel.toLowerCase()}`}
              onDropdownSelect={(item) => {
                if (!defaultPrimary) {
                  setSelectedSecondary(item.value);
                } else {
                  setPrimaryFilters(item.text);
                  setSelectedPrimary(item.value);
                  setRefetch(!refetch);
                }
                setUpdated(false);
              }}
            />
          )}
        </Menu>

        <Top
          defaultSpacing={dialsNum === 3}
          leftAlign={dialsNum === 2}
          center={dialsNum === 1}
        >
          {(reportData &&
            filterArray &&
            !isNaN(cultureScore) &&
            selectedSurvey && (
              <ScoreContainer
                bgColor={"#F8FAFF"}
                color={"#2D70E2"}
                score={Math.round(cultureScore * 10)}
                addMargin={dialsNum === 2}
                change={
                  (reportData.length > 0 &&
                    (cultureScore -
                      getLastFactorAverage(
                        reportData,
                        filterArray,
                        selectedSurvey
                      ))*10).toFixed(0) ||
                  0
                }
                title={"Culture"}
              />
            )) || (
            <LoadWrapper>
              <LogAnimation />
              <LoadingText>Loading component</LoadingText>
            </LoadWrapper>
          )}
          {firstDialData && firstDialData.length > 0 && selectedPrimary && (
            <ScoreContainer
              bgColor={"#FFFAF1"}
              color={"#FEA711"}
              score={retrieveScore(
                selectedPrimary,
                firstDialData,
                selectedSecondary
              )}
              change={retrieveChange(
                selectedPrimary,
                firstDialData,
                selectedSecondary
              )}
              title={firstDialLabel}
            />
          )}
          {secondDialData && secondDialData.length > 0 && selectedPrimary && (
            <ScoreContainer
              bgColor={"#EAFBF7"}
              color={"#27CDA7"}
              score={retrieveScore(
                selectedPrimary,
                secondDialData,
                selectedSecondary
              )}
              change={retrieveChange(
                selectedPrimary,
                secondDialData,
                selectedSecondary
              )}
              title={secondDialLabel}
            />
          )}
        </Top>

        {/* <T1>Top Recommendations</T1> */}
        <Options />

        {/* {(calculated &&
          get_survey_structure &&
          get_survey_structure.length > 0 &&
          calculatedSecondary &&
          calculatedSecondary?.length > 0 &&
          !selectedSecondary &&
          calculated?.summary &&
          filteredRecs &&
          filteredRecs.length > 0 &&
          selectedSurvey &&
          !open &&
          generateRecItems(filteredRecs)) ||
          (((!loading && !fakeLoad && !filteredRecs) ||
            (!loading && !fakeLoad && filteredRecs?.length === 0) ||
            (!loading && !fakeLoad && selectedSecondary)) && <Empty />) || (
            <LoadWrapper>
              <LoadingText>Loading section</LoadingText>
            </LoadWrapper>
          )} */}
      </Container>
    </Dimmer.Dimmable>
  );
};

export default Dashboard;

const LoadingText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  padding-top: 20px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const LoadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Container = styled.div`
  padding: 20px;
  margin-top: 20px;
  padding-left: 58px;
`;

const Top = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.defaultSpacing
      ? "space-between"
      : props?.leftAlign
      ? "flex-start"
      : "center"};
`;

const T1 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  text-transform: uppercase;
  margin-top: 44px;
  margin-bottom: 40px;
`;

const T = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 40px;
`;

const Menu = styled.div`
  wisth: 100%;
  display: flex;
  justify-content: space-between;
`;

const Item = styled.span`
  color: ${(props) => (props.selected ? "black" : "#2D70E2")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
`;

const AnchorWrapper = styled.div`
  opacity: 0;
  position: absolute;
`;