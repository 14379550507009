import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";


const Box = ({category,score,onClick}) =>{

    return (
        <Container onClick={()=>onClick()}>
            
                        <T2>
                            {category}
                        </T2>

                        <Score>
                            {score}
                        </Score>
                       
        </Container>
    )

}

export default Box

const Container = styled.div`
width:100%;
border:1px solid #E0E0E0;
border-radius:10px;
padding: 20px 20px;
display:flex;
margin-bottom:10px;
align-items:center;
justify-content:space-between;
cursor:pointer;
`


const Score = styled.div`
    font-size:18px;
    font-weight:600;
    color#2A3039;
    margin-bottom:5px;
`

const T2 = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
`

