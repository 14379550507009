import styled from "styled-components";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./transitions.css"; // Import your CSS for transitions
import Sidebar from "./Sidebar";
// import Component from "./components";
import Navbar from "./Navbar";
import sample from "./sample_data/sample2.json";
import JourneyScreen from "./screens";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const DataJourney = () => {
  // TODO: Replace sampleData with the actual data
  const [introComplete, setIntroComplete] = React.useState(false);
  const [activeJourney, setActiveJourney] = React.useState();
  const [stage, setStage] = React.useState(0);
  const [introStage, setIntroStage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectionHistory, setSelectionHistory] = React.useState([]);
  const [currentSelection, setCurrentSelection] = React.useState();
  const [journeyPath, setJourneyPath] = React.useState("need");
  const [actionsComplete, setActionsComplete] = React.useState(false);
  const [checkInFrequency, setCheckInFrequency] = React.useState();
  const [nextStepsComplete, setNextStepsComplete] = React.useState(false);
  const [introPassed, setIntroPassed] = React.useState(false);
  const [nextStepsPassed, setNextStepsPassed] = React.useState(false);
  const [actionsPassed, setActionsPassed] = React.useState(false);

  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    get_survey_structure,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_chat_gpt_recommendations?.active_journey) {
      setActiveJourney(get_chat_gpt_recommendations?.active_journey);
    }
  }, [get_chat_gpt_recommendations]);

  const renderContent = (stage) => {
    //  from the stage, retrieve the component data
    const data = sample[stage];
    // return <Component {...data} />;
  };

  return (
    <Container>
      <Sidebar
        open={open}
        setOpen={setOpen}
        stage={stage}
        setStage={setStage}
        setIntroComplete={setIntroComplete}
        setActionsComplete={setActionsComplete}
        introComplete={introPassed}
        actionsComplete={actionsPassed}
        introStage={introStage}
      />
      <Center open={open}>
        <Navbar
          open={open}
          setOpen={setOpen}
          stage={stage}
          setStage={setStage}
        />
        <TransitionGroup component={null}>
          <CSSTransition
            key={stage} // Use the stage as a unique key to trigger transitions
            timeout={1000} // Duration of the transition
            classNames="fade" // Prefix for the CSS transition classes
          >
            <JourneyScreen
              activeJourney={activeJourney}
              step={stage}
              introStep={introStage}
              journeyPath={journeyPath}
              actionsComplete={actionsComplete}
              onIntroComplete={() => {
                setIntroComplete(true);
                setIntroPassed(true);
              }}
              introComplete={introComplete}
              selectionHistory={selectionHistory}
              onFrequencySelect={setCheckInFrequency}
              onActionSelect={(action) => {
                setCurrentSelection(action);
              }}
              onActionsComplete={() => {
                setActionsPassed(true);
                setActionsComplete(true);
              }}
            />
          </CSSTransition>
        </TransitionGroup>
      </Center>

      <Bottom>
        <Button2
          onClick={() => {
            // TODO
          }}
        >
          Back
        </Button2>
        <Button
          onClick={() => {
            if (!introComplete) {
              if (introStage < 5) {
                setIntroStage(introStage + 1);
              } else {
                setIntroComplete(true);
              }
            } else if (!actionsComplete) {
              // context and cta have no selection.
              if (
                currentSelection ||
                journeyPath === "context" ||
                journeyPath === "cta"
              ) {
                setSelectionHistory([...selectionHistory, currentSelection]);
                setCurrentSelection(null);
                if (journeyPath === "need") {
                  setJourneyPath("action");
                } else if (journeyPath === "action") {
                  setJourneyPath("context");
                } else if (journeyPath === "context") {
                  setJourneyPath("cta");
                } else if (journeyPath === "cta") {
                  setActionsComplete(true);
                }
              }
            } else if (!nextStepsComplete) {
              setStage(stage + 1);
            }
          }}
        >
          Continue Learning{" "}
          <Icon name="arrow right" style={{ marginLeft: 10, marginTop: -3 }} />
        </Button>
      </Bottom>
    </Container>
  );
};

export default DataJourney;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway";
  min-height: calc(100vh - 75px);
`;

const Center = styled.div`
  width: ${(props) => (props.open ? "calc(100% - 300px)" : "800px")};
  padding-top: 1%;
  padding-left: ${(props) => (props.open ? "200px" : "0px")};
  transition: all 0.3s ease-in-out;
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 75px;
  background-color: #2254aa;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid white;
`;

const Button2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
