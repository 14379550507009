import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress } from 'semantic-ui-react'
import GoogleMapReact from 'google-map-react';
import Chart from "react-apexcharts";


const AnyReactComponent = ({ text,open }) => <Marker onClick={()=>open()}>{text}</Marker>;

const Area = (props) =>{

	return (
		<div>
			<ATitle>{props.title}
			<Percent>{props.percent}%</Percent></ATitle>
			<Progress percent={props.percent}  color='grey' inverted size="tiny"/>
		</div>
		)
}

const Active = (props) =>{

	return (
		<AContainer>
			<H5>{props.title}</H5>
			<Dat>{props.date}</Dat>
		</AContainer>
		)
}

const Points = [{lat:43.651070,lng:-79.347015,text:'86%'},{lat:44.651070,lng:-63.582687,text:'96%'},{lat:45.508888,lng:-73.561668,text:'73%'}]

const Maps = () =>{

	const [center,setCenter] = useState({lat:Points[0].lat,lng:Points[0].lng})
	const [zoom, setZoom] = useState(5)
	const [open,setOpen] = useState(false)


	const selection = (id) =>{
		setOpen(true)
		setCenter({lat:Points[id].lat,lng:Points[id].lng})
	}

	return (
		<Container>	
			{/*<Top>
				<Title>Map Overview</Title>
			</Top>*/}

			<Dash>
				<MapContainer>
						<GoogleMapReact
					          bootstrapURLKeys={{ key:'AIzaSyAdZuA2OaEPdXJeetk4VXzcxBso-m6nz1w'}}
					          center={center}
					          defaultZoom={zoom}
					        >
			          {Points.map((item,i)=>{
			          	return <AnyReactComponent
			            lat={item.lat}
			            lng={item.lng}
			            text={item.text}
			            open={()=>selection(i)}
			          /> })}
			        </GoogleMapReact>
			    </MapContainer>
			 <SideMenu open={open}>
			 	<H2>Halifax, Nova Scotia
			 		<Icon name="close" onClick={()=>setOpen(false)} style={{cursor:'pointer'}}/></H2>

			 	<Card>
			 		<H3>Culture Index</H3>
			 		<div style={{position:'relative'}}>
						<Chart options={data.options} series={data.series} type="radialBar" height={150} />
						<Values>
								<Stat>86%</Stat>
							</Values>
					</div>
					<TextArea>
						<Change>Great</Change>
						<Text>This location's culture index has <strong>increased by +10%</strong> this assessment.</Text>
					</TextArea>
				</Card>

				<Content>
					<H4>Role Breakdown</H4>
					<Area title={'Technical Leaders'} percent={94}/>
					<Area title={'Athletes'} percent={88}/>
					<Area title={'Sport Science'} percent={78}/>
					<Area title={'Coaches'} percent={66}/>
				</Content>

				<Activity>
					<div style={{padding:'20px 20px 0px 20px'}}>
						<H4>Latest Activity</H4>
					</div>
					<Active title={"Bobsleigh & Luge assessment scheduled"} date={"March 31st, 2020"}/>
					<Active title={"Athletics assessment scheduled"} date={"March 31st, 2020"}/>
					<Active title={"Speed Skating assessment completed"} date={"March 31st, 2020"}/>
				</Activity>
			 </SideMenu>
		</Dash>
		</Container>
		)
}


export default Maps



const Container = styled.div`
	height:calc(100vh - 110px);
	width:calc(100% + 50px);
	margin-left:-20px;
	font-family: 'Red Hat Display', sans-serif;
	position:relative;
`

const MapContainer = styled.div`
	width:100%;
	height:100%;
`

const Top = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	height:60px;
	padding-left:30px;
	border-bottom:1px solid #4a4a4a;
	margin-top:10px;
`

const Title = styled.div`
	font-size:18px;
	font-weight:500;
`

const Dash = styled.div`
	height:100%;
	width:100%;
	display:flex;
`

const SideMenu = styled.div`
	width:400px;
	height:100%;
	background-color:#f8f8f8;
	position:absolute;
	right:${props=>props.open?0:'-640px'};
	transition:all 500ms;
`

const H2 = styled.div`
	font-size:18px;
	padding:20px;
	border-bottom:1px solid #e9e9e9;
	font-weight:bold;
	display:flex;
	justify-content:space-between;
	align-items:center;
`

const H3 = styled.div`
	font-size:12px;
`

const Change = styled.div`
	font-size:16px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
	color:#ab710a;
	margin-bottom:5px;
`


const Card = styled.div`
	padding:30px 10px;
	position:relative;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	background-color:white;
`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 25px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:25px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const TextArea = styled.div`
	width:100%;
	padding:10px;
`

const Text = styled.div`
	
`

const Content = styled.div`
	padding:20px;
	border-bottom:1px solid #e9e9e9;
`

const H4 = styled.div`
	font-size:16px;
	color:#ab710a;
	font-weight:bold;
	margin-bottom:20px;
`

const ATitle = styled.div`
	font-size:14px;
	font-weight:500;
	width:100%;
	display:flex;
	justify-content:space-between;
`

const Percent = styled.div`
	
`

const Activity = styled.div`

`

const AContainer = styled.div`
	padding:20px;
	border-bottom:1px solid #e9e9e9;
	
`

const H5 = styled.div`
	font-weight:500;
	font-size:16px;
`

const Dat = styled.div`
	margin-top:10px;
	color:#4a4a4a;
	font-size:12px;
`

const Marker = styled.div`
	height:60px;
	width:60px;
	border-radius:30px;
	border:3px solid white;
	background-color:rgba(0,176,240,1);
	display:flex;
	align-items:center;
	justify-content:center;
	font-family: "Red Hat Display", sans-serif;
	color:white;
	font-size:22px;
	font-weight:bold;
	cursor:pointer;

	&:hover{
		box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 40px;
	}

`

const data = {
          
            series: [86],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
               grid:{
              	padding:{
              		right:0,
              		top:0,
              		left:0,
              		bottom:0
              	}
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '65%',
                  },
                   track:{
                	background:'white'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#ab710a']
              }
            },
          
          
          };

