import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Icon } from "semantic-ui-react";

import { Participants, Oval, P3 } from "../styled_list";
import { Message } from "semantic-ui-react";
import {
  SG_ADD_TEAMS_IN_SCHEDULE,
  SG_DELETE_TEAMS_IN_SCHEDULE,
} from "constants/actions";

export const Selector = (props) => {
  return (
    <Oval selected={props.selected} onClick={() => props.onClick()}>
      <Icon
        name="check"
        size={"small"}
        style={{ margin: 0, color: props.selected ? "white" : "#d8d8d8" }}
      />
    </Oval>
  );
};

export const TeamSelection = ({ prop, selectedTeam, AddRemoveTeam }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "calc(50% - 45px)",
        // borderBottom: "1px solid #e9e9e9",
        padding: "10px 0px 10px 0px",
      }}
    >
      <Selector
        selected={selectedTeam}
        onClick={() => AddRemoveTeam(selectedTeam, prop.id)}
      />
      <P3>{prop.name} Team</P3>
    </div>
  );
};

const SurveyTypes = [1, 2]; // Culture and Mood Survey type

function Teams(props) {
  const { schedule_id, organizationId, teamsChanged, setTeamsChanged } = props;
  const dispatch = useDispatch();

  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [teamUpdated, setTeamUpdated] = useState(
    {type: "Added Team", color: "green", show: false})

  const { get_teams, get_selected_teams } = useSelector(
    (state) => ({
      get_teams: state.teams,
      get_selected_teams: state.teamsinschedule.teams_in_schedules,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSelectedTeams(get_selected_teams);
    setTeams(get_teams.teams);
  }, [get_selected_teams, get_teams]);

  // console.log(selectedTeams)

  function RemoveAllTeams(team) {
    // console.log(team.id);
    let TeamSel = selectedTeams.filter((sel) => sel.team === team?.team);
    // console.log(TeamSel);
    TeamSel.map((del) =>
     dispatch({
        type: SG_DELETE_TEAMS_IN_SCHEDULE,
        payload: {
          id: del.id,
        },
      })
    );
  //  setTeamsChanged(!teamsChanged)
    // setTeamUpdated({message: "Removed Team", color: "red", show: true})
  }

  function AddBothTeams(teamId) {
    SurveyTypes.map((surveyType) =>
      dispatch({
        type: SG_ADD_TEAMS_IN_SCHEDULE,
        payload: {
          global_schedule: schedule_id,
          team: teamId,
          survey_type: surveyType,
          organization: organizationId,
        },
      })
  
    );
    //  setTeamsChanged(!teamsChanged)
    // setTeamUpdated({message: "Added Team", color: "green", show: true})
  }

  function AddRemoveTeam(selectedTeam, teamId) {
    // console.log(selectedTeam);
    // console.log(teamId);
    if (selectedTeam) {
      RemoveAllTeams(selectedTeam);
      return;
    }

    // if (id) {
    if (teamId) {
      AddBothTeams(teamId);
    }
  }

  // console.log(teams)
  // console.log(selectedTeams)

  return (
    <>   {teamUpdated?.show && 
     <Message color={teamUpdated.color}>
       {teamUpdated.message}
     </Message>
     } 
      <Participants>
  
        {teams.length > 0 ? teams.map((p, k) => (
          <TeamSelection
            prop={p}
            selectedTeam={
              selectedTeams.filter(
                (tm) => tm.team === p.id && SurveyTypes.includes(tm.survey_type)
              )[0]
            }
            AddRemoveTeam={AddRemoveTeam}
            key={k}
          />
        )): <p>No Teams found!</p>}
      </Participants>
    </>
  );
}

export default Teams;
