import React from 'react';
import styled from "styled-components";

const MandatoryLinks = ({ Domain }) => {

  const today = new Date()
    return (<>
        {/* {Domain.allowRegister &&
            <Banner>
                To know more about us <a href={Domain.homePage} target="_blank" rel="noreferrer">click here</a>
            </Banner>} */}

        <P>Copyright © {today.getFullYear()} innerlogic.com</P>
        <LinkContainer>
           
            <a href={Domain.privacyPolicy} target="_blank" rel="noreferrer">Privacy Policy</a>
            <Line/> <a href=" https://innerlogic.com/terms/ " target="_blank" rel="noreferrer">Terms of Use</a>

        </LinkContainer>
    </>);
};

export default MandatoryLinks;

const Banner = styled.div`

  font-size: 14px;
  font-weight: 400;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
`;

const LinkContainer = styled.div`
width: 100%;
max-width: 600px;
height: 100%;
padding: 10px 0;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
// justify-content: center;

a {
  color: #2D70E2;
  font-weight: 600;
  font-size: 12px;

}
@media (max-width: 768px) {
  width: 100%;
  padding: 20px 20px 0 20px;
}


`;

const Line = styled.div`
  width: 1px;
  height: 12px;
  background-color: #2D70E2;
  margin-left: 12px;
  margin-right: 12px;
`;

const P = styled.p`
  font-size: 10px;
  font-weight: 400;
  font-family: "Raleway";
  margin-bottom:0px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  color: #666D79;
`;