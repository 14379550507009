import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import { useNavigate } from 'react-router-dom';

import Box from './Box'
import Back from '../Back'

const ExplainDash = () => {
    const navigate = useNavigate();

    return (
    <Container>

        <Center>

            {/* <Title>Create a new data story</Title>
            <Box 
                onClick={()=>navigate('/simple/prototype/explain/dashboard/create')}
                title={'New story'}
                icon={'certificate'}
                description={'Build a culture action plan based on your culture data. Our ai action planner will help you every step of the way.'}/> */}

            <div style={{marginBottom:30}}/>
            <Back url={'/simple/prototype/simpledash'} location={'dashboard'}/>
            <Title>Your data stories</Title>
            <Box 
                 onClick={()=>navigate('/simple/prototype/explain')}
                title={'Department A Story - Dec 5th, 2023'}
                icon={'book'}
                read={true}
                description={'Story build on Dec 5th, 2023 and based on the data collected on October 30th, 2023.'}/>


              <Box 
                 onClick={()=>navigate('/simple/prototype/explain')}
                title={'Department A Story - June 20th, 2023'}
                icon={'book'}
                read={false}
                description={'Story build on Dec 5th, 2023 and based on the data collected on October 30th, 2023.'}/>

        </Center>
    </Container>
    )


}


export default ExplainDash;



const Container = styled.div`
  padding-left: 58px;
  padding-right: 40px;
  font-family: "Raleway";
  display:flex;
    flex-direction:column;
    align-items:center;
`;

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Center = styled.div`
    width:800px;
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`
