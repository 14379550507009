import styled from "styled-components";
import React, { useState, useEffect } from "react";

import StatusCard from "./StatusCard";
import StatusCardEmp from "./StatusCardEmp";

import {
  Button,
  Header,
  Confirm,
  Icon,
  Modal,
  Dropdown,
} from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { ShortCountDownTimer } from "utilities/CountDownTimer";

import CustomEmail from "components/CustomEmail/index";

import Select from "react-select";

import {
  // SG_GET_DEBRIEF_RESPONSES,
  SG_GET_DEBRIEF_TOKENS,
  SG_FETCH_DEBRIEF_SCHEDULE,
  SG_EDIT_DEBRIEF_SCHEDULE,
  SG_FETCH_SURVEY_QUESTION,
  SG_GET_AUDIT_ELIGIBLE, // view eligble users
  SG_ADD_USER_TO_SURVEY, // add user to survey
  SG_EXTEND_SURVEY_TIME, // extend survey time
} from "constants/actions";

import format from "date-fns/format";
import { differenceInSeconds, sub } from "date-fns";

const HoursList = [
  { value: 30, label: "Half Hour (30 mins) " },
  { value: 60, label: "One Hour (60 mins)" },
  { value: 120, label: "Two Hour (120 mins)" },
  { value: 240, label: "Four Hour (240 mins)" },
  { value: 480, label: "Eight Hour (480 mins)" },
  { value: 1440, label: "One Day (24 hrs)" },
  { value: 4320, label: "Three Days" },
  { value: 10080, label: "1 Week" },
  { value: 20160, label: "2 Week" },
  { value: 30240, label: "3 Week" },
  { value: 40320, label: "4 Week" },
];

const SurveyStatus = ({
  setShowSurveyStatus,
  briefId,
  setAlertMessage,
  setShowMenu,
  setBriefId,
  nonHistory,
}) => {
  const dispatch = useDispatch();
  const [debriefTokens, setDebriefTokens] = useState([]);
  const [debriefSchedule, setDebriefSchedule] = useState([]);
  // const [debriefResponses, setDebriefResponses] = useState([]);
  const [debriefQuestionId, setDebriefQuestionId] = useState([]);
  const [rowList, setRowList] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [openExtendSurvey, setOpenExtendSurvey] = useState(false);
  const [selectedNoOfHours, setSelectedNoOfHours] = useState([]);
  const [deadlineExtended, setDeadlineExtended] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [auditEligibleList, setAuditEligibleList] = useState(false);

  const [surveyListEmpId, setSurveyListEmpId] = useState([]);
  const [fullHistoryList, setFullHistoryList] = useState([]);

  const [openEmail, setOpenEmail] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  const {
    // get_debrief_responses,
    get_debrief_schedules,
    get_debrief_tokens,
    // get_survey_question,
    get_employees,
  } = useSelector(
    (state) => ({
      // get_debrief_responses: state.debrief_response,
      get_debrief_schedules: state.debrief_schedule,
      get_debrief_tokens: state.debrief_token.debrief_token,
      get_survey_question: state.surveyquestions,
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (briefId) {
      // fetch eligible team members here
      dispatch({
        type: SG_FETCH_DEBRIEF_SCHEDULE,
        payload: { id: briefId },
      });
      dispatch({
        type: SG_GET_AUDIT_ELIGIBLE,
        payload: `audit-id=${briefId}`,
      });
    }
  }, [dispatch, briefId, deadlineExtended, userAdded]);

  useEffect(() => {
    if (briefId) {
      dispatch({
        type: SG_GET_DEBRIEF_TOKENS,
        payload: `debrief_schedule=${briefId}`,
      });
    }
  }, [dispatch, briefId, debriefSchedule]);

  useEffect(() => {
    if (debriefQuestionId > 0) {
      dispatch({
        type: SG_FETCH_SURVEY_QUESTION,
        payload: { id: debriefQuestionId },
      });
    }
  }, [dispatch, debriefQuestionId]);

  useEffect(() => {
    setDebriefTokens(get_debrief_tokens);
  }, [get_debrief_tokens]);

  useEffect(() => {
    setDebriefSchedule(get_debrief_schedules?.[briefId]);
    setDebriefQuestionId(get_debrief_schedules?.[briefId]?.brief_question);
  }, [briefId, get_debrief_schedules]);

  useEffect(() => {
    setDeadlineExtended(get_debrief_schedules?.survey_extended);
    setAuditEligibleList(get_debrief_schedules?.audit_eligible?.response);
    setUserAdded(get_debrief_schedules?.user_added);
  }, [get_debrief_schedules]);

  function ExtendSurvey() {
    if (briefId > 0) {
      dispatch({
        type: SG_EXTEND_SURVEY_TIME,
        payload: {
          id: briefId,
          extend_minutes: selectedNoOfHours.value,
        },
      });
    }
    setOpenExtendSurvey(false);
  }

  function CloseSurvey() {
    if (briefId) {
      dispatch({
        type: SG_EDIT_DEBRIEF_SCHEDULE,
        payload: {
          id: briefId,
          close_survey: briefId,
        },
      });
      setShowConfirm(false);
      setAlertMessage({
        no: 1,
        text: "Successfully Closed a Survey, this can take about 5 mins",
      });
    } else{
   
        setAlertMessage({
          no: 2,
          text: "There is no brief id",
        });
   
    }
  }

  function RemindAll() {
    if (briefId && !(timeLeft > 0)) {
      setTimeLeft(330);
      dispatch({
        type: SG_EDIT_DEBRIEF_SCHEDULE,
        payload: {
          id: briefId,
          remind_all: briefId,
        },
      });
      setAlertMessage({
        no: 1,
        text: "Successfully set up a reminder, this can take about 5 mins for emails to go",
      });
    } else {
      setAlertMessage({
        no: 2,
        text: "Please wait for about 5 mins for previously queued action to be completed",
      });
    }
  }

  useEffect(() => {
    const EmployeeList = [];
    debriefTokens.map((item) => {
      const EMP = get_employees.find(({ id }) => id === item.employee);
// auditEligibleList
      EmployeeList.push({
        id: item?.id,
        employee_id: item.employee,
        first_name: EMP?.first_name,
        last_name: EMP?.last_name,
        email: `${EMP?.email}`,
        // to_delete: true,
        debrief_response: auditEligibleList ? auditEligibleList.filter(ae => ae?.email === EMP?.email)?.[0]?.debrief_response : false,
        employee_status: EMP?.status,
        status: item?.delivery_status,
        failed: item?.failed,
        to_delete: auditEligibleList? auditEligibleList.filter(ae => ae?.email === EMP?.email)?.[0]?.debrief_response: false,
        deleted_on: item?.deleted_on,
        email_status: EMP?.email_status,
        email_status_text: EMP?.email_status_text,
        reminded: item?.reminded,
        updated_at: item?.updated_at,
        difference: differenceInSeconds(
          new Date(item?.updated_at),
          sub(new Date(), {
            minutes: 5,
            seconds: 30,
          })
        ),
      });
      return null;
    });

    setRowList(
      EmployeeList.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
    );
  }, [debriefTokens, get_employees, auditEligibleList]);

  useEffect(() => {
    const _request = debriefSchedule?.request_list;
    const _compliance = debriefSchedule?.compliance;

    const EmployeeList = [];
    // completed employees
    _compliance &&
      _compliance.map((item) => {
        const EMP = get_employees.find(({ id }) => id === item);
        const _found = EmployeeList.filter((el) => el.employee_id === item)?.[0]
          ?.employee_id;
        if (!_found) {
          EmployeeList.push({
            employee_id: item,
            name: `${EMP?.first_name ? EMP?.first_name : ""} ${EMP?.last_name ? EMP?.last_name : ""
              }`,
            email: `${EMP ? `${EMP?.email}` : `archived employee`}`,
            status: item?.delivery_status,
            failed: item?.failed,
            employee_status: EMP?.status,
            to_delete: item?.to_delete,
            deleted_on: item?.deleted_on,
            email_status: EMP?.email_status,
            email_status_text: EMP?.email_status_text,
            reminded: item?.reminded,
            updated_at: item?.updated_at,
            debrief_response: "completed",
            difference: differenceInSeconds(
              new Date(item?.updated_at),
              sub(new Date(), {
                minutes: 5,
                seconds: 30,
              })
            ),
          });
        }
        return null;
      });

    // not completed employees
    _request &&
      _request.map((item) => {
        const EMP = get_employees.find(({ id }) => id === item);
        const _found = EmployeeList.filter((el) => el.employee_id === item)?.[0]
          ?.employee_id;
        if (!_found) {
          EmployeeList.push({
            employee_id: item,
            name: `${EMP?.first_name ? EMP?.first_name : ""} ${EMP?.last_name ? EMP?.last_name : ""
              }`,
            email: `${EMP ? `${EMP?.email}` : "archived employee"}`,
            status: item?.delivery_status,
            failed: item?.failed,
            to_delete: item?.to_delete,
            deleted_on: item?.deleted_on,
            employee_status: EMP?.status,
            email_status: EMP?.email_status,
            email_status_text: EMP?.email_status_text,
            reminded: item?.reminded,
            debrief_response: false,
            updated_at: item?.updated_at,
            difference: differenceInSeconds(
              new Date(item?.updated_at),
              sub(new Date(), {
                minutes: 5,
                seconds: 30,
              })
            ),
          });
        }
        return null;
      });

    setFullHistoryList(
      EmployeeList.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
    );
  }, [debriefSchedule, get_employees]);


  useEffect(() => {
    if (auditEligibleList?.length > 0) {
      const ExtEmpList = rowList.map((el) => el.employee_id);
      
      const _FilteredList = auditEligibleList.filter(
        (al) => !ExtEmpList.includes(al.employee_id)
      );

      const _EligbleNotCincluded = [];
      _FilteredList?.length > 0 &&
        _FilteredList.map((ae) => {
          const EMP = get_employees.find(({ id }) => id === ae.employee_id);
          _EligbleNotCincluded.push({
            employee_id: ae.employee_id,
            name: `${EMP?.first_name ? EMP?.first_name : ""} ${EMP?.last_name ? EMP?.last_name : ""
              }`,
            email: `${EMP ? `${EMP?.email}` : "archived employee"}`,
            status: null,
            failed: null,
            to_delete: null,
            deleted_on: null,
            survey_status: ae?.survey_status,
            employee_status: EMP?.status,
            email_status: EMP?.email_status,
            email_status_text: EMP?.email_status_text,
            reminded: null,
            debrief_response: ae?.debrief_response || false,
            updated_at: null,
            difference: 0,
          });
          return null;
        });

      setSurveyListEmpId(_EligbleNotCincluded);
    }
  }, [rowList, auditEligibleList, get_employees]);

  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    setTimeLeft(
      differenceInSeconds(
        new Date(debriefSchedule?.updated_at),
        sub(new Date(), {
          minutes: 5,
          seconds: 30,
        })
      )
    );
  }, [debriefSchedule]);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer1 = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timer1);
    }
  });

  function BackToList() {
    setShowSurveyStatus(0);
    setShowMenu(true);
    setBriefId(false);
  }

  if (nonHistory) {
    return (
      <>
        {/* custom email process */}
        <CustomEmail
          briefId={briefId}
          openEmail={openEmail}
          setOpenEmail={setOpenEmail}
          showHistory={showHistory}
          setShowHistory={setShowHistory}
          RemindAll={RemindAll}
        />
        <Container>
          <BackSection>
            <div style={{ display: "flex" }}>
              {debriefSchedule?.status === 3 && (
                <>
                  <Confirm
                    open={showConfirm}
                    header="Close Survey"
                    content="This will close this survey. Any users with pending surveys will not be able to complete it. Are you sure you want to close it?"
                    onCancel={() => setShowConfirm(false)}
                    onConfirm={CloseSurvey}
                  />
                </>
              )}
            </div>
            {/*	
							{timeLeft > 0 ? (
								<WaitText>
									<Icon loading name="asterisk" />{" "}
									{`${Math.floor((timeLeft / 60) % 60)}:${Math.floor(
										timeLeft % 60
									)}s`}{" "}
								</WaitText>
							) : (
								debriefSchedule?.status === 3 && (
									<StyledButton color={"#2d50e2"} onClick={RemindAll}>
										Remind All
									</StyledButton>
								)
							)}

							{debriefSchedule?.status === 3 && (
								<>
									<StyledButton color={"#ff5168"} onClick={() => setShowConfirm(true)}>
										Close Survey
									</StyledButton>
									<Confirm
										open={showConfirm}
										header='Close Survey'
										content='This will close this survey. Any users with pending surveys will not be able to complete it. Are you sure you want to close it?'
										onCancel={() => setShowConfirm(false)}
										onConfirm={CloseSurvey}
									/>
								</>

							)}
						</div>*/}
          </BackSection>

          <HeaderText>
            <div>
              <div>
                <TopHeader>
                  <Linked onClick={BackToList}>Calendar</Linked>{" "}
                  <Icon name="chevron right" style={{ marginBottom: -15 }} />{" "}
                  {debriefSchedule?.name}
                </TopHeader>
                <Sub>Manage Individual Responses for Scheduled Survey</Sub>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 20,
                justifyContent: "space-between",
              }}
            >
              <TopSection>
                <Header sub>Scheduled For</Header>
                <span>
                  {debriefSchedule?.scheduled_for &&
                    format(
                      new Date(
                        debriefSchedule?.scheduled_for?.toLocaleString()
                      ),
                      "EEEE, do MMM yy - h:mm a"
                    )}
                  <br />
                  <ShortCountDownTimer
                    futureTime={debriefSchedule?.scheduled_for}
                  />
                </span>
              </TopSection>

              <TopSection>
                <Header sub>Close At</Header>

                <span>
                  {debriefSchedule?.close_at &&
                    format(
                      new Date(debriefSchedule?.close_at?.toLocaleString()),
                      "EEEE, do MMM yy - h:mm a"
                    )}
                  <br />
                  <ShortCountDownTimer futureTime={debriefSchedule?.close_at} />
                </span>
              </TopSection>
              <TopSection>
                <Header sub>Status</Header>
                <span>{debriefSchedule?.status_text}</span>
              </TopSection>

              <TopSection>
                <Header sub>Responses / Requests</Header>
                <span>
                  {debriefSchedule?.total_responses}/
                  {debriefSchedule?.total_requests}{" "}
                </span>
              </TopSection>
              {/*							<TopSection>
								<StyledSmallButton color={"#ff5168"}
									onClick={() => setOpenExtendSurvey(true)}>
									Extend Deadline
								</StyledSmallButton>
							</TopSection>*/}
              <TopSection>
                 <Dropdown
                  button
                  icon="setting"
                  text="Survey options"
                  floating
                  labeled
                  className="icon"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      icon="clock"
                      text="Extend survey deadline"
                      onClick={() => setOpenExtendSurvey(true)}
                    />
                    <Dropdown.Item
                      icon="write"
                      text="Creat a custom reminder"
                      onClick={() => setOpenEmail(true)}
                    />
                    <Dropdown.Item
                      icon="write"
                      text="Send Email"
                      onClick={() => setOpenEmail(true)}
                    />
                    <Dropdown.Item
                      icon="send"
                      text="Send reminder"
                      onClick={() => RemindAll()}
                    />
                    <Dropdown.Item
                      icon="star"
                      text="Email reminder status"
                      onClick={() => setShowHistory(true)}
                    />
                    {debriefSchedule?.status === 3 && (
                      <Dropdown.Item
                        icon="cut"
                        text="Close the survey"
                        onClick={() => setShowConfirm(true)}
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </TopSection>
            </div>
          </HeaderText>

          <Table>
            <Headers>
              <TH width={20}>Name</TH>
              <TH width={30}>Email</TH>
              {nonHistory && <TH width={10}>Reminder</TH>}
              <TH width={30}>Status</TH>
              {nonHistory && <TH width={20}>Action</TH>}
            </Headers>
            <StatusCard
              editSurvey={debriefSchedule?.status < 4 ? true : false}
            />
            {/* list of employees who are in the survey */}
            {rowList &&
              rowList.map((brief, i) => {
                
                return (
                  <StatusCard
                    data={brief}
                    key={i}
                    editSurvey={debriefSchedule?.status < 4 ? true : false}
                  />
                );
              })}
            {/* complete list of employees not all would have recd survey link or part of survey */}
            {surveyListEmpId &&
              surveyListEmpId.map((sl) => {
                return (
                  <StatusCardEmp
                    data={sl}
                    nonHistory={nonHistory}
                    debriefSchedule={debriefSchedule}
                    status={debriefSchedule?.status}
                    key={sl.employee_id}
                  />
                );
              })}
          </Table>
        </Container>
        <Modal
          closeIcon
          open={openExtendSurvey}
          onClose={() => setOpenExtendSurvey(false)}
        >
          <Header icon="clock" content="Extended Closing Time" />
          <Modal.Content>
            <p>
              Survey would close at{" "}
              {debriefSchedule?.close_at &&
                format(
                  new Date(debriefSchedule?.close_at?.toLocaleString()),
                  "EEEE, do MMM yy - h:mm a"
                )}
            </p>
            Extend it by{" "}
            <Select
              placeholder="Select No of Hours"
              onChange={(e) => setSelectedNoOfHours(e)}
              value={selectedNoOfHours}
              options={HoursList}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={() => setOpenExtendSurvey(false)}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" onClick={() => ExtendSurvey()}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }

  return (
    <Container>
      <ButtonContainer>
        <Button
          floated="left"
          color="grey"
          onClick={BackToList}
          labelPosition="left"
          icon="left chevron"
          content="Back"
          basic
        />
      </ButtonContainer>

      <HeaderText>
        <Header as="h2">
          {debriefSchedule?.name}
          <Header.Subheader>
            Manage Individual Responses for Scheduled Survey
          </Header.Subheader>
        </Header>

        <div>
          <Header sub>Scheduled For</Header>

          <span>
            {debriefSchedule?.scheduled_for &&
              format(
                new Date(debriefSchedule?.scheduled_for?.toLocaleString()),
                "EEEE, do MMM yy - h:mm a"
              )}
            <br />
            <ShortCountDownTimer futureTime={debriefSchedule?.scheduled_for} />
          </span>

          <Header sub>Closed At</Header>

          <span>
            {debriefSchedule?.close_at &&
              format(
                new Date(debriefSchedule?.close_at?.toLocaleString()),
                "EEEE, do MMM yy - h:mm a"
              )}
            <br />
            <ShortCountDownTimer futureTime={debriefSchedule?.close_at} />
          </span>
        </div>

        <div>
          <Header sub>Status</Header>
          <span>{debriefSchedule?.status_text}</span>
          <Header sub>Responses / Requests</Header>

          <span>
            {debriefSchedule?.total_responses}/{debriefSchedule?.total_requests}{" "}
          </span>
        </div>
      </HeaderText>

      <Table>
        <Headers>
          <TH width={20}>Name</TH>
          <TH width={30}>Email</TH>

          <TH width={30}>Status</TH>
        </Headers>

        {/* completed list of employees */}
        {fullHistoryList &&
          fullHistoryList.map((sl) => {
            return (
              <StatusCardEmp
                data={sl}
                nonHistory={nonHistory}
                debriefSchedule={debriefSchedule}
                status={debriefSchedule?.status}
                key={sl.employee_id}
              />
            );
          })}

        {/* {auditEligibleList &&
					auditEligibleList.map(el => {
						return (<TRow key={el.employee_id}>
							<THRow width={20}>{el?.name}</THRow>
							<THRow width={30}>{el?.email}</THRow>
							<THRow width={30}>{el?.debrief_response? "Completed" : "Not Completed"}</THRow>
						</TRow>
						)
					})} */}
      </Table>
    </Container>
  );
};

/******** Styles ****************************************************/

const Container = styled.div`
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const HeaderText = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 30px;
  font-family: "Barlow", sans-serif;
`;

const TH = styled.div`
  font-size: 14px;
  background-color: #fafafa;
  width: ${(props) => props.width + "%"};
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  padding: 10px 0 10px 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const THRow = styled.div`
  font-size: 14px;
  width: ${(props) => props.width + "%"};
  font-family: "Barlow", sans-serif;
  padding: 10px 0 10px 15px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px;
  color: black;
`;

const BackSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: grey;
`;

const WaitText = styled.div`
  font-size: 12px;
  width: 75px;
  color: red;
`;

const StyledButton = styled.button`
  font-size: 14px;

  padding: 0px 30px;
  height: 40px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  border: none;
  border: 1px solid white;
  outline: none;

  &:hover {
    background-color: white;
    color: ${(props) => props.color};
    border: ${(props) => "1px solid " + props.color};
  }
`;

const StyledSmallButton = styled.button`
  font-size: 12px;

  padding: 0px 10px;
  height: 40px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  border: none;
  border: 1px solid white;
  outline: none;

  &:hover {
    background-color: white;
    color: ${(props) => props.color};
    border: ${(props) => "1px solid " + props.color};
  }
`;

const TopSection = styled.div`
  margin-right: 20px;
`;

const Linked = styled.div`
  margin-right: 5px;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`;

const TopHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const TextWrap = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
  align-items: center;   
`

const TextInfo = styled.div`
width: 150px;
display: flex;
flex-wrap: wrap;
`

const Sub = styled.div``;

export default SurveyStatus;
