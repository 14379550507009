import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon} from 'semantic-ui-react'

const ReviewPlan = ({plan,finish,edit,back}) => {

    return (
        <Container>
            <Card>
            <BackButton onClick={()=>back()}><Icon name="chevron left" style={{marginTop:-4}}/>Back</BackButton>
                <FormatData dataArray={JSON.parse(plan)}/>
            </Card>

            <ButtonContainer>
                <Button onClick={()=>finish()}>Finalize plan & set goals</Button>

            </ButtonContainer>
            
        </Container>
    )

}

export default ReviewPlan;



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`

`


const Card = styled.div`
  padding:20px;

  opacity: 0; // Start with hidden state
animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state


// 3. Adjust delay for each sample
animation-delay: 0.3s; 
`


const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const Button2 = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`


const ContentContainer = styled.div`
  font-family: 'Raleway';
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    line-height:1.4;
`

const T3 = styled.div`
    font-size:14px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const P = styled.div`
    margin-bottom: 20px;
    line-height:1.4;
`

const LI = styled.li`
    margin-bottom:10px;
    line-height:1.4;
`
const BackButton = styled.div`
    font-size:12px;
    font-weight:400;
    margin-bottom:10px;
    cursor:pointer;
    display:flex;
    align-items:center;
    margin-bottom:10px;
    margin-left:-10px;
    margin-top:-10px;
    font-family:'Raleway';
`

const LINK = styled.div`
    margin-bottom: 20px;
    margin-top:30px;
`


function makeBoldText(inputText) {
  try{
      // Use a regular expression to find the text before the colon
    const match = inputText.match(/^(.*?):/);

    if (match && match[1]) {
      const boldText = `<strong>${match[1]}:</strong>`;
      const replacedText = inputText.replace(/^(.*?):/, boldText);
      return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
    } else {
      // If there's no match, return the original text
      return inputText;
    }
  }
  catch{
    return inputText
  }
  
}

const FormatData = ({ dataArray,setError }) => {
  
    const renderElements = (items) => {
      const elements = [];
      let keyCounter = 0;
  
      items.forEach((item) => {
        let text = makeBoldText(item.text)
        switch (item.type) {
          case 't1':
            elements.push(<T1 key={keyCounter++}>{item.text}</T1>);
            break;
          case 't2':
            elements.push(<T2 key={keyCounter++}>{item.text}</T2>);
            break;
          case 't3':
              elements.push(<T3 key={keyCounter++}>{item.text}</T3>);
              break;
          case 'p':
            elements.push(<P key={keyCounter++}>{text}</P>);
            break;
          case 'l':
              elements.push(<LINK key={keyCounter++}><strong>{item.text}</strong> <a target='_blank' href={item.url}>{item.link_text}</a></LINK>);
              break;
          case 'b':
            // If the previous element is not a list, start a new list
            if (!elements.length || elements[elements.length - 1].type !== 'ul') {
              elements.push(
                <ul key={keyCounter++}>{[
                  <LI key={keyCounter++}>{text}</LI>
                ]}</ul>
              );
            } else {
              // If the previous element is a list, add the bullet point to it
              let lastElement = elements.pop();
              lastElement = React.cloneElement(lastElement, {
                children: [...lastElement.props.children, <LI key={keyCounter++}>{text}</LI>]
              });
              elements.push(lastElement);
            }
            break;
          default:
            break;
        }
      });
  
      return elements;
    };
  
    try {
      return <ContentContainer>{renderElements(dataArray)}</ContentContainer>;
    } catch (error) {
        return setError(true)
    }
  };