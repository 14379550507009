import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";

const FactorScore = ({data}) =>{

	const [chart,setChart] = useState(data3)
	const [loading,setLoading] = useState(false)

	useEffect(()=>{
		setLoading(true)
		let _chart = {...chart}
		_chart.options.yaxis.title.text = data.content.factor
		setChart({
			..._chart,
			series:[{
				data: data.content.chart.scores
			}]
		})

		setTimeout(()=>{
			setLoading(false)
		},100)
	},[])

	console.log(data.type)
	return (
        <Container>
            <T1>
                Your overall <strong>{data.type == 'lowest_scoring_factor'?'lowest':'highest'} culture factor</strong> by benchmark is <Box>{data.content.factor}</Box>.
                 It scored a {data.content.score} which puts it in the {data.type == 'lowest_scoring_factor'?'bottom':'top'} {data.content.percentile} of scores globally.
            </T1>

           {!loading && <Chart
						options={chart.options}
						series={chart.series}
						type="bar"
						height={350}
					/> }

           
          
        </Container>
    )

}

export default FactorScore

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:30px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const Description = styled.div`
    margin-bottom: 40px;
`

const Box = styled.span`
    background-color:#EBF1FD;
    padding:5px 10px;
    border-radius:5px;
    margin-left:10px;
    font-weight:600;
    color:#2D70E2;
`


const data3 = {
	series: [
		{
			data: [6.7, 7.1, 7.5],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 600,
			height: 400,
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
		colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
		xaxis: {
			categories: [
				"Your Team",
                "Your Organization",
                "Global Average"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 10,
			decimalsInFloat: 1,
			title: {
				text: "Psychological Safety Scores",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
