const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.

export default async function make_call(prompt, onMessageChunk) {
   
    const response = await fetch(endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer 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`
        },
        body: JSON.stringify({
            messages: [
                { role: "system", content: "You are an assistant designed to help diagnose culture trends and findings, based on a large amount of qualitative feedback." },
                {role: "user", content: prompt}
            ],
            model: 'gpt-4o',
            stream: true // Enable streaming
        })
    });

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let assistantMessage = ""; // Collect assistant's response

    while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        const chunk = decoder.decode(value, { stream: true });

        // Split the stream by lines and parse each one
        const lines = chunk.split("\n").filter(line => line.trim());
        for (const line of lines) {
            if (line === "data: [DONE]") {
                done = true; // End the stream on [DONE]
                break;
            }
            if (line.startsWith("data: ")) {
                try {
                    const json = JSON.parse(line.replace("data: ", ""));
                    if (json.choices && json.choices[0].delta.content) {
                        const content = json.choices[0].delta.content;
                        onMessageChunk(content); // Stream to UI
                        assistantMessage += content; // Append to assistant's response
                    }
                } catch (error) {
                    console.error("Error parsing JSON:", error);
                }
            }
        }
    }


    return assistantMessage.trim();
}