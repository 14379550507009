import React from 'react';
import styled, { keyframes } from "styled-components";
import { Icon } from "semantic-ui-react";

import { Link } from "react-router-dom";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


const Container = styled.div`
  width:500px;
  display:flex;
  flex-direction:column;
  margin-top:70px;
  margin-left:calc((100% - 500px) / 2);
  border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
    padding:36px;

    @media (max-width:768px){
      width:100%;
      margin:20px 0 0 0 ;
      padding:36px 20px 36px 20px;
    }
`

const Title = styled.div`
	font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:20px;
  color:#08c251;
`
const Text = styled.div`
	font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:5px;
  font-weight:bold;
`


const Trial = styled.div`
	font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:20px;
`

const StartContainer = styled.div`
  border:1px solid #e9e9e9;
  border-radius:8px;
  width:100%;
  padding:20px;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  margin:10px 0 10px 0;
  cursor:pointer;
  position:relative;
`  
const Read = styled.div`
  
`;



export default function Success() {
  return (
    <Container>
      <Title>Success!</Title>
      <Trial>Your innerlogic journey has begun</Trial>
      <Text>Continue using innerlogic to build your team's culture, and check out these premium tools:</Text>
        <Link to="/settings/personality">
            <StartContainer>
            <Read>Activate Personality Assessments</Read>
            <Icon name="chevron right" style={{position:'absolute',right:10,top:20}}/>
          </StartContainer>
        </Link>
        <Link to="/HRDash">
          <StartContainer>
             <Read>Premium analytics</Read>
               <Icon name="chevron right" style={{position:'absolute',right:10,top:20}}/>
          </StartContainer>
        </Link>
        <Link to="/survey_settings">
          <StartContainer>
            <Read>Setup a leadership pulse</Read>
              <Icon name="chevron right" style={{position:'absolute',right:10,top:20}}/>
          </StartContainer>
        </Link>
     
  </Container>
  );
}