import { useSelector, shallowEqual, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Popup } from "semantic-ui-react";
import CloseImg from "assets/images/nav/close.svg";
import ClearImg from "assets/images/nav/clear.svg";
import CheckImg from "assets/images/nav/check.svg";
import { SET_NEW_FILTERS } from "constants/actions";

const Filters = ({ org_name, data_length }) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState();
  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  const { get_survey_structure } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  const handleSelect = (item, parent) => {
    item.parent = parent.id;
    if (selectedFilters.includes(item)) {
      let _selectedFilters = selectedFilters.filter((i) => i !== item);
      dispatch({
        type: SET_NEW_FILTERS,
        payload: _selectedFilters,
      });
    } else {
      let _selectedFilters = [...selectedFilters, item];
      dispatch({
        type: SET_NEW_FILTERS,
        payload: _selectedFilters,
      });
    }
  };

  const clearAll = () => {
    dispatch({
      type: SET_NEW_FILTERS,
      payload: [],
    });
  };

  useEffect(() => {
    setSelected(
      get_survey_structure[0]?.categories.sort((a, b) => {
        const priorities = { primary: 1, secondary: 2, tertiary: 3 };

        if (a.priority === "tertiary" && a.demographic) return 1;
        if (b.priority === "tertiary" && b.demographic) return -1;

        return priorities[a.priority] - priorities[b.priority];
      })[0]
    );
  }, [get_survey_structure]);

  return (
    <FilterComponent>
      <InfoBar>
        <SelectedSection>
          {selectedFilters?.length > 0 && (
            <Section>
              <BarLabel>Selected filters:</BarLabel>
              <SelectedOptions>
                {selectedFilters.map((item, index) => {
                  return (
                    <SelectedOption
                      onClick={() => handleSelect(item, selected)}
                    >
                      {item.name} <IconImg src={CloseImg} />
                    </SelectedOption>
                  );
                })}

                {selectedFilters.length > 0 && (
                  <Popup
                    trigger={
                      <Clear src={ClearImg} onClick={() => clearAll()} />
                    }
                    content="Clear All"
                    inverted
                    position="top center"
                    style={{
                      backgroundColor: "#666D79",
                      fontFamily: "Raleway",
                      fontSize: 12,
                    }}
                  />
                )}
              </SelectedOptions>
            </Section>
          )}
        </SelectedSection>

        <DataPointsSection>
          Feedback Length
          <Length>
            # of data points: <strong>{data_length}</strong>
          </Length>
        </DataPointsSection>
      </InfoBar>
      <Content>
        <SideMenu>
          {get_survey_structure[0]?.categories
            .sort((a, b) => {
              const priorities = { primary: 1, secondary: 2, tertiary: 3 };

              if (a.priority === "tertiary" && a.demographic) return 1;
              if (b.priority === "tertiary" && b.demographic) return -1;

              return priorities[a.priority] - priorities[b.priority];
            })
            .map((item, index) => {
              return (
                <Item
                  onClick={() => setSelected(item)}
                  selected={item == selected}
                >
                  {item.name}
                </Item>
              );
            })}
        </SideMenu>

        <MainMenu>
          <Title>{selected?.name}</Title>
          <Options>
            {selected?.options.map((item, index) => {
              return (
                <Option
                  onClick={() => handleSelect(item, selected)}
                  selected={selectedFilters.includes(item)}
                >
                  {selectedFilters.includes(item) && (
                    <IconImg2 src={CheckImg} />
                  )}{" "}
                  {item.name}
                </Option>
              );
            })}
          </Options>
        </MainMenu>
      </Content>
    </FilterComponent>
  );
};

export default Filters;

const Container = styled.div`
  color: #2d70e2;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  display: flex;
  width: 300px;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`;

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 230px;
  max-width: 230px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #666d79;
  margin-right: 20px;
`;

const DataPointsSection = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #666d79;
`;

const InfoBar = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  margin-left: 35px;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid #e5e5e5;
`;

const Item = styled.div`
  margin-bottom: 5px;
  height: 36px;
  padding: 4px 12px 4px 27px;
  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-family: Raleway;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#F8FAFF" : "#fff")};
  display: flex;
  align-items: center;
`;

const MainMenu = styled.div`
  padding: 20px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Options = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  font-family: Raleway;
  font-size: 14px;
`;

const Option = styled.div`
  padding: 8px 16px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 30px;
  font-family: Raleway;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.selected ? "#EBF1FD" : "#fff")};
  color: ${(props) => (props.selected ? "#2D70E2" : "#2A3039")};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 500px;
`;

const SelectedOption = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: var(--primary-500, #2d70e2);
  cursor: pointer;
`;

const DefaultOption = styled.div`
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: var(--primary-500, #2d70e2);
`;

const FilterComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const BarLabel = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #666d79;
  margin-right: 20px;
`;

const IconImg = styled.img`
  width: 20px;
  margin-left: 3px;
`;

const IconImg2 = styled.img`
  width: 20px;
  margin-right: 4px;
`;

const Clear = styled.img`
  width: 20px;
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 0px;
`;

const Button = styled.button`
  background: #2d70e2;
  border-radius: 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  cursor: pointer;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 20px;
`;

const Length = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: #666d79;
`;
