
import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";



const Insight = ({onClick,setCompleted}) =>{

    const complete = () =>{
        setCompleted()
        onClick()
    }

    return (
        <Container>
            <Center>
                <Title>Department A</Title>
                    <Score>Customer Satisfaction Insight</Score>
                    <T3>Conversation collection date: <strong>October 3rd, 2024</strong></T3>
                    <Bubble>
                    <T2>Building behaviors that support customer satisfaction</T2>

                    <Description>
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.

                    </Description>

                    <Description>
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.

                    </Description>

                    <Description>
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.

                    </Description>

                    <Description>
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorum ipsum dolor sit amet, consectetur adipiscing elit.

                    </Description>
                    </Bubble>

                    <ButtonContainer>
                        <Button onClick={()=>complete()}>Complete</Button>
                    </ButtonContainer>
                </Center>
        </Container>
    )
}

export default Insight;


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:600px;
    `


const Bubble = styled.div`
    background-color:#EBF1FD;
    border-radius:10px;
    padding:20px;
    margin-bottom:40px;
`

const Title = styled.div`
    font-size:12px;
    font-weight:600;
    color:grey;
    margin-bottom:5px;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`
    margin-top:20px;
    color:black;
`

const T3 = styled.div`
    font-size:14px;
    margin-bottom:20px;
`
const Score = styled.div`
    font-size:20px;
    margin-bottom:20px;
    font-weight:bold;
`
const ButtonContainer = styled.div`
    display:flex;
    justify-content:flex-end;
    width:100%;
`

const Button = styled.div`
    background:blue;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-left:20px;
`