import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import {
  grandientsAboveNorm,
  dataLabels,
  grandientsBelowNorm,
  orgColorGradient,
  legendOptions,
  barChartOptions,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subsubtext,
  Subtext,
} from "reports/DataStories/common/styles";

const sampleData = {
  description:
    "Your department's overall score is <strong>83%</strong>. That puts you in the <strong>80th percentile</strong> within your organization.",
};

const OverallScore = ({ calculatedData }) => {
  const [series, setSeries] = useState(data3.series);
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (calculatedData?.scores) {
      const updatedScores = calculatedData?.scores?.map((score) => {
        return score * 10;
      });
      const scoreData = {
        series: [
          {
            data: updatedScores,
          },
        ],
      };

      setSeries(scoreData.series);

      const colorsArray = calculatedData?.labels?.map((label, index) => {
        if (label === "") {
          return orgColorGradient;
        } else {
          if (calculatedData?.scores[index] > 0) {
            return grandientsAboveNorm;
          } else {
            return grandientsBelowNorm;
          }
        }
      });

      // Get the lowest value in the updatedScores array
      const lowestValue = Math.round(Math.min(...updatedScores));
      // Get the highest value in the updatedScores array
      const highestValue = Math.round(Math.max(...updatedScores));

      const factorLabel = calculatedData?.labels?.find((label) => label !== "");
      const options = {
        ...data3.options,
        xaxis: {
          ...data3.options.xaxis,
          categories: calculatedData.labels,
        },
        yaxis: {
          min: lowestValue,
          max: highestValue,
          decimalsInFloat: 0,
          title: {
            text: "% difference from global norm",
            style: {
              fontWeight: 400,
            },
          },
          forceNiceScale: true,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          show: true,
          markers: {
            fillColors: ["#468ADE", "#C7CFDB"],
          },
          customLegendItems: [factorLabel, "All other departments in your org"],
        },
        fill: {
          type: ["gradient", "solid"],
          gradient: {
            type: "vertical",
            shadeIntensity: 0.5,
            gradientToColors: ["#40B7C2", "#468ADE"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
            colorStops: colorsArray,
          },
        },
      };
      setOptions(options);
    }
  }, [calculatedData]);

  const textRegex = /(\d+%)/g;
  const result = calculatedData?.text.split(textRegex);

  return (
    <Container>
      <ScreenTitleExtended delay={1}>Overall culture score</ScreenTitleExtended>
      <SubTextWrapper>
        <SubtextExtended delay={1.3}>
          {result?.map((text, id) => {
            if (text.match(textRegex)) {
              return <strong>{text}</strong>;
            } else {
              return text;
            }
          })}
          &nbsp; The overall culture score represents the aggregate score of all
          culture factors within the assessment. An analysis on these factors
          will be provided on the next screen.
        </SubtextExtended>
      </SubTextWrapper>

      <SubsubtextExtended delay={1.5}></SubsubtextExtended>
      <ChartWrapper style={{ marginBottom: 30 }} delay={1.7}>
        <Chart options={options} series={series} type="bar" height={450} />
      </ChartWrapper>
    </Container>
  );
};

export default OverallScore;

const SubTextWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
`;

const ScreenTitleExtended = styled(ScreenTitle)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SubtextExtended = styled(Subtext)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SubsubtextExtended = styled(Subsubtext)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const BoldedText = styled.div`
  padding-top: 20px;
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  opacity: 0;
  line-height: 1.4;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const ChartWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const data3 = {
  series: [
    {
      data: [
        9.07191496958851, 9.028902529765867, 8.637639297975701,
        8.579321706766255, 8.46192786722845, 8.421206955557551,
        8.27093006510129, 8.254794271867654, 8.221697977457534,
        8.208445706294807, 8.201215585363835, 8.166001946655774,
        8.065011081207938, 8.023233689572915, 8.020796247720615,
        8.001270988671994, 7.999767639769582, 7.917709668981536,
        7.9109999529785044, 7.8909709059581425, 7.829226685399363,
        7.81673588380098, 7.805303441896007, 7.7592000183324386,
        7.744351541473576, 7.736835483554641, 7.729060863820424,
        7.728245678448761, 7.697514210574307, 7.694394839036695,
        7.6755404546353265, 7.654175121993225, 7.650795132568388,
        7.641555815705638, 7.588455930406092, 7.554771912973915,
        7.528901072108942, 7.480826239859022, 7.430119479560203,
        7.327993441023147, 7.15194359524052, 7.115125156082723,
        7.083864717117156, 6.95506412723199, 6.942412377619459,
        6.912098226322278, 6.819152796598606, 6.733064379653344,
        6.567938878165246, 6.559467350012666,
      ],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      events: {
        mounted: (chart) => {
          chart.windowResizeHandler();
        },
      },
      width: 800,
    },

    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#2D70E2",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
      "#C7CFDB",
    ],
    xaxis: {
      categories: [
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
      ],
      tickPlacement: "on",
      labels: {
        show: false,
        style: {
          fontSize: "9px",
          colors: [
            "#C7CFDB",
            "#C7CFDB",
            "#2D70E2",
            "#C7CFDB",
            "#C7CFDB",
            "#C7CFDB",
          ],
        },
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      decimalsInFloat: 1,
      title: {
        text: "",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
