import React, { useState } from "react";
import styled from "styled-components";

const TableTextbox = (props) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextboxContainer altBg={props.hideBorders}>
      <InputWrapper
        altBg={props.hideBorders}
        isFocused={!props.hideBorders && isFocused}
      >
        {(props.editEnabled && (
          <Input
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
        )) || <InputText>{props.value}</InputText>}
      </InputWrapper>
    </TextboxContainer>
  );
};

const Title = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TextboxContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  width: 100%;
`;

const InputText = styled.div`
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  width: 100%;
  font-family: Raleway;
  font-size: 14px;

  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;

const Input = styled.textarea`
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  width: 100%;
  font-family: Raleway;
  font-size: 14px;

  height: 150px;
  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;

export default TableTextbox;
