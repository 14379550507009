import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Icon from 'assets/images/UI/material-symbols_full-stacked-bar-chart.svg'

const Hover = () =>{

    return (
        <Container>
            <Tags>
            <Tag>Department A</Tag>
            </Tags>
            
            <Top>
                <Circle></Circle>
                <div>
                    <Title>Department A</Title>
                    <People>20 people</People>
                    <Description>Lorem 
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </Description>
                </div>
               
            </Top>

            <ScoreArea>
                <ScoreComponent score={40} title={'Culture'} rank={6}/>
                <ScoreComponent score={100} title={'Innovation Potential'} rank={1}/>
                <ScoreComponent score={25} title={'Organizational Agility'} rank={3}/>

                <ScoreComponent score={40} title={'Well-Being'} rank={7}/>
                <ScoreComponent score={66} title={'Retention'} rank={9}/>
                <ScoreComponent score={87} title={'Operational Efficiency'} rank={4}/>

            </ScoreArea>
        </Container>
    )
}


export default Hover;

const ScoreComponent = ({score,title,rank}) =>{

   return (
   <ScoreContainer>
        <IMG src={Icon} />
        <Title2>{title}</Title2>
        <Score>{score}%</Score>
        <Rank>{rank} out of 10</Rank>
        
    </ScoreContainer>)
}


const Container = styled.div`
    padding:20px;
    box-shadow: 0px 8px 20px rgba(63, 134, 254, 0.2);
   border-radius: 8px;
    width:500px;
    color:#2A3039;
`

const Tag = styled.div`
    padding:5px;
    background-color:#EBF1FD;
    border-radius:5px;
    font-family: "Poppins";
    font-size:10px;
    font-weight:600;
`

const Tags = styled.div`
    display:flex;
    margin-bottom:10px;
`

const Circle = styled.div`
    width:50px;
    height:50px;
    border-radius:50%;
    background-color:#EBF1FD;
    margin-right:10px;
    min-width:50px;
    `
const Top = styled.div`
    display:flex;
    border-bottom:1px solid #DFDFDF;
    padding-bottom:20px;

`

const Title = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

`

const Description = styled.div`
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    color: #2A3039;
`

const ScoreContainer = styled.div`
    padding:10px;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:150px;
    color:#2A3039;
`

const Score = styled.div`
    font-family: "Poppins";
    font-weight:600;
    font-size:16px;
    line-height:1;
    margin-bottom:-3px;

`
const Title2 = styled.div`
color: #666D79;
    font-family: 'Raleway';
font-style: normal;
font-weight: 400;
margin-bottom:-3px;
font-size: 10px;

`

const ScoreArea = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin-top:20px;
`

const Change = styled.div`
    font-family: "Poppins";
    font-weight:bold;
    font-size:10px;
    color:#27CDA7;
`

const People = styled.div`
font-family: "Poppins";
font-weight:400;
font-size:10px;
color:#2D70E2;
`

const Rank = styled.div`
font-family: "Poppins";
font-weight:400;
font-size:10px;
color:#2D70E2;
`

const IMG = styled.img`
    width:25px;
`