import styled from "styled-components";
// import React from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import TeamIcon from 'assets/images/nav/team.svg'
// import { overall, average_cat } from "./data_navigation/calculate_data";

// const old_color = "rgba(227, 176, 31,0.2)";

const LegendStyle = (props) => {



  const getDifference = (d) =>{


    let last = props.data.summary_last[d]
    let current =  props.data.summary[d]
    let diff = (current - last).toFixed(1)*10

    if(diff){
      return '('+(diff>0?'+':"")+ diff +'%)'
    }
    return ""
    
  }


  return (
    <Container>
      <Row
        onClick={() =>
          props.onClick(props.selected == props.i ? null : props.i)
        }
        hover={
          props.highlight === null
            ? true
            : props.highlight == props.i
            ? true
            : false
        }
        selected={props.selected == props.i}
        onMouseEnter={() => props.hover(props.i)}
        onMouseLeave={() => props.hover(null)}
      >
        
        <Title>
	        <Circle color={props.data.color} />
          <Image src={TeamIcon}/>
	        {props.title.name}
        </Title>
        <Score>
          <div>{props.data.summary[0].toFixed(1)} <Diff>{getDifference(0)}</Diff></div>
          <Sub>{props.dimensions!=1?props.questions.dimensions[0].title : ''}</Sub>
        </Score>
        {props.dimensions!=1?
        	<Score>
	          <div>{props.data.summary[1].toFixed(1)} <Diff>{getDifference(1)}</Diff></div>
	          <Sub>{props.questions.dimensions[1].title}</Sub>
	        </Score>
	        :""}
      </Row>

     
    </Container>
  );
};

export default LegendStyle;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${(props) => props.color || "green"} !important;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 5px 130px 5px 35px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  margin-top:10px;
  cursor:pointer;
`;

const Title = styled.div`
  width: 300px;
  display:flex;
  flex-direction:row;
  align-items:center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;

color: #7E7E7E;
`;

const SubTitle = styled.div`
  width: 200px;
`;

const Title2 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const Title3 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #696969;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Row2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
/* identical to box height */


color: #6D6D6D;
  height:40px;
`;

const Culture = styled.div``;

const Sub = styled.div`
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
text-align: center;

color: #7E7E7E;
`;

const Circle = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${(props) => props.color};
  margin-right:63px;
`;

const Details = styled.div`
  max-height: ${(props) => (props.selected ? "1000px" : "0")};
  transition: all 0.5s;
  overflow: hidden;
  padding-top: ${(props) => (props.selected ? "10px" : "0")};
`;

const Dated = styled.div`
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Button = styled.div`
  background-color: #3078ff;
  padding: 5px 10px;
  color: white;
`;

const Image = styled.img`
  margin-right:20px;
`

const Diff = styled.span`
  font-size:10px;
`