import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Checkbox, Popup } from "semantic-ui-react";
import Sports from "../survey_structure_alt.json";

const data = [
	"Bobsleigh",
	"Speed skating",
	"Curling",
	"Biathlon",
	"Figure Skating",
	"Freestyle Skiing",
	"Hockey (F)",
	"Hockey (M)",
	"Luge",
	"Skeleton",
	"Snowboard",
];

const months = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

const Calendar = () => {
	return (
		<Container>
			<Current month={6} />
			<Left>
				<Top>
					<div>Primary Factor</div>
					<div>Progress</div>
				</Top>
				<Bottom>
					<Primary>Winter Sports</Primary>
					{Sports.categories[0].subfactors[0].options.map((item) => (
						<Sub>
							<div>{item}</div> <div>100%</div>
						</Sub>
					))}

					<Primary>Summer Sports</Primary>
					{Sports.categories[0].subfactors[1].options.map((item) => (
						<Sub>
							<div>{item}</div> <div>100%</div>
						</Sub>
					))}

					<Primary>Para Summer Sports</Primary>
					{Sports.categories[0].subfactors[2].options.map((item) => (
						<Sub>
							<div>{item}</div> <div>100%</div>
						</Sub>
					))}

					<Primary>Para Winter Sports</Primary>
					{Sports.categories[0].subfactors[3].options.map((item) => (
						<Sub>
							<div>{item}</div> <div>100%</div>
						</Sub>
					))}
				</Bottom>
			</Left>

			<Right>
				<TopR>
					{months.map((item) => (
						<Dated>{item}</Dated>
					))}
				</TopR>
				<Grid>
					{Sports.categories[0].subfactors[0].options.map((item) => {
						return (
							<Row>
								{months.map((m, i) => {
									let selected = Math.random(0, 1) < 0.05;
									if (selected) {
										return (
											<Popup
												content={<OverviewCard name={item} i={i} />}
												trigger={<DateCircle selected={selected} i={i} />}
											/>
										);
									}
									return <DateCircle selected={selected} />;
								})}
							</Row>
						);
					})}
				</Grid>

				<Grid>
					{Sports.categories[0].subfactors[1].options.map((item) => {
						return (
							<Row>
								{months.map((m, i) => {
									let selected = Math.random(0, 1) < 0.05;
									if (selected) {
										return (
											<Popup
												content={<OverviewCard name={item} i={i} />}
												trigger={<DateCircle selected={selected} i={i} />}
											/>
										);
									}
									return <DateCircle selected={selected} />;
								})}
							</Row>
						);
					})}
				</Grid>

				<Grid>
					{Sports.categories[0].subfactors[2].options.map((item) => {
						return (
							<Row>
								{months.map((m, i) => {
									let selected = Math.random(0, 1) < 0.05;
									if (selected) {
										return (
											<Popup
												content={<OverviewCard name={item} i={i} />}
												trigger={<DateCircle selected={selected} i={i} />}
											/>
										);
									}
									return <DateCircle selected={selected} i={i} />;
								})}
							</Row>
						);
					})}
				</Grid>

				<Grid>
					{Sports.categories[0].subfactors[3].options.map((item) => {
						return (
							<Row>
								{months.map((m, i) => {
									let selected = Math.random(0, 1) < 0.05;
									if (selected) {
										return (
											<Popup
												content={<OverviewCard name={item} i={i} />}
												trigger={<DateCircle selected={selected} i={i} />}
											/>
										);
									}
									return <DateCircle selected={selected} i={i} />;
								})}
							</Row>
						);
					})}
				</Grid>
			</Right>
		</Container>
	);
};

export default Calendar;

const Container = styled.div`
	width: 100%;
	border: 1px solid #e9e9e9;
	border-radius: 4px;
	margin-top: 50px;
	font-family: "Red Hat Display", sans-serif;
	display: flex;
	position: relative;
`;

const Left = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width: 300px;
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: 1px solid #e9e9e9;
	height: 60px;
	padding: 30px 30px 10px 30px;
`;

const Bottom = styled.div`
	padding: 0px 20px 20px 20px;
`;

const Primary = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
	height: 30px;
	margin-top: 15px;
	font-family: "Barlow", sans-serif;
`;

const Sub = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-left: 20px;
	height: 25px;
`;

const Right = styled.div`
	width: calc(100% - 300px);
`;

const TopR = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	border-bottom: 1px solid;
	padding: 30px 20px 10px 20px;
	height: 60px;
`;

const Dated = styled.div`
	width: calc(100% / 12);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
`;

const Grid = styled.div`
	padding: 15px 20px 0px 20px;
	margin-top: 40px;
`;

const Row = styled.div`
	display: flex;
	height: 25px;
`;

const DateCircle = styled.div`
	width: calc(100% / 12);
	background-color: ${(props) => (props.selected ? "#E3B01F" : "#ebebeb")};
	border-radius: 10px;
	margin: 5px;
	cursor: pointer;

	background-image: ${(props) =>
		props.i > 5 && props.selected
			? "linear-gradient(45deg, #818580 25%, #a6a6a6 25%, #a6a6a6 50%, #818580 50%, #818580 75%, #a6a6a6 75%, #a6a6a6 100%)"
			: "none"};
	background-size: 28.28px 28.28px;
	&:hover {
		background-color: ${(props) => (props.selected ? "#E3B01F" : "#808080")};
	}
`;

const Current = styled.div`
	position: absolute;
	height: calc(100% - 20px);
	width: calc((100% - 300px) / 12);
	border: 3px solid #e3b01f;
	border-radius: 20px;
	z-index: -1;
	left: ${(props) =>
		"calc(((100% - 300px) / 12 - 3.3px) * " + (props.month - 1) + " + 318px)"};
	top: 10px;
`;

const full_months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const OverviewCard = ({ name, i }) => {
	if (i > 5) {
		return (
			<Card>
				<OT>{name}</OT>
				<Description>
					Culture assessment scheduled for {months[i]}, 2021
				</Description>
			</Card>
		);
	}

	return (
		<Card>
			<OT>{name}</OT>
			<Description>Response rate from assessment</Description>
			<FT>Sport Scientists</FT>
			<Progress
				progress="ratio"
				value={10}
				total={10}
				size="small"
				inverted
				color="black"
				style={{ margin: 0 }}
			/>
			<FT>Coaches</FT>
			<Progress
				progress="ratio"
				value={10}
				total={12}
				size="small"
				inverted
				color="black"
				style={{ margin: 0 }}
			/>
			<FT>Athletes</FT>
			<Progress
				progress="ratio"
				value={35}
				total={50}
				size="small"
				inverted
				color="black"
				style={{ margin: 0 }}
			/>
			<FT>Technical Leaders</FT>
			<Progress
				progress="ratio"
				value={8}
				total={12}
				size="small"
				inverted
				color="black"
				style={{ margin: 0 }}
			/>
		</Card>
	);
};

const StyledProgressBar = styled(Progress)`
	& > .bar {
		background-color: ${(props) => props.color || "green"} !important;
	}
`;

const Card = styled.div`
	width: 300px;
	padding: 10px;
`;

const OT = styled.div`
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 20px;
	font-family: "Barlow", sans-serif;
`;

const FT = styled.div`
	margin-top: 10px;
`;

const Description = styled.div`
	font-size: 12px;
`;
