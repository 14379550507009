import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  SG_GET_MANAGER_USERS,
  GET_MANAGER_USERS,
  ADD_MANAGER_USER,
  SG_ADD_MANAGER_USER,
  SG_DEACTIVATE_MANAGER_USER,
  DEACTIVATE_MANAGER_USER,
  ALL_ERRORS,
  ADD_EDIT_MANAGER_USER,
} from "constants/actions";

function* loadManagerUsers(action) {
  try {
    const json = yield call(api.GET_MANAGER_USERS, action.payload);
    yield put({ type: GET_MANAGER_USERS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* managerUserLoad() {
  yield takeLatest(SG_GET_MANAGER_USERS, loadManagerUsers);
}

function* addManagerUser(action) {
  try {
    const json = yield call(api.ADD_MANAGER_USER, action.payload);
    yield put({
      type: ADD_MANAGER_USER,
      payload: {
        response: json.data,
        newData: action?.newAdditions,
      },
    });

    yield put({ type: ADD_EDIT_MANAGER_USER, payload: action?.payload?.employee_category_list });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* managerUserAdd() {
  yield takeLatest(SG_ADD_MANAGER_USER, addManagerUser);
}

function* deactivateManagerUser(action) {
  try {
    const json = yield call(api.ADD_MANAGER_USER, action.payload);
    yield put({ type: DEACTIVATE_MANAGER_USER, payload: action?.userId });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* managerUserDeactivate() {
  yield takeLatest(SG_DEACTIVATE_MANAGER_USER, deactivateManagerUser);
}

export default function* index() {
  yield all([managerUserLoad(), managerUserAdd(), managerUserDeactivate()]);
}
