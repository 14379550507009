import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import Spark from "assets/images/UI/ion_sparkles-outline.svg";
import { Icon, Dimmer } from "semantic-ui-react";
import Questions from "./Questions";
import Information from "./Information";

const ReportType = (props) => {
  const { templates, next, setTemplate, isSport, goBack } = props;
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(null);

  if (open) {
    return <Questions />;
  }

  return (
    <Container>
      <BackButton onClick={() => goBack()}>
        <Icon name="chevron left" style={{ marginTop: -7 }} />
        Back
      </BackButton>
      <T1>Recommendation Catalogue</T1>
      <T2>
        Explore our range of solutions to address specific areas of improvement
        in your organization's culture.
      </T2>
      <Options>
        {templates
          ?.filter((t) => {
            if (isSport) {
              return t;
            } else {
              return !t?.sport_only;
            }
          })
          .map((template, index) => {
            return (
              <OptionItem
                key={index}
                onClick={() => {
                  setTemplate(template);
                  next();
                }}
                openModal={() => setInfo(template)}
                selectTemplate={() => setTemplate(template)}
                title={template.name}
                description={template.description}
              />
            );
          })}
      </Options>
      <Information open={info} setOpen={setInfo} />
    </Container>
  );
};

const OptionItem = ({
  title,
  description,
  onClick,
  coming_soon,
  openModal,
  selectTemplate,
}) => {
  return (
    <Card>
      <Dimmer active={coming_soon}>Coming Soon</Dimmer>
      <Title>
        <Icon2 src={Spark} />
        {title}
      </Title>
      <Description>{description}</Description>
      {!coming_soon && (
        <ArrowContainer onClick={() => onClick()}>
          <Icon name={"arrow right"} />
        </ArrowContainer>
      )}

      {!coming_soon && (
        <More onClick={() => openModal()}>More information</More>
      )}

      {/* <Button>View More</Button> */}
    </Card>
  );
};

const Container = styled.div`
  font-family: "raleway";
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const T2 = styled.div`
  font-size: 16px;
  margin-bottom: 30px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const More = styled.div`
  font-size: 12px;
  color: #007bff;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 20px;
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-right: 10px;
  padding: 20px;
  margin-bottom: 10px;
  position: relative;
`;

const Description = styled.div`
  line-height: 1.5;
`;

const Icon2 = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const ArrowContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  color: #007bff;
  font-size: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
`;

export default ReportType;
