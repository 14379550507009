import { erf } from "mathjs";

/**
 * @module calcUtils
 * @description Utility functions for calculating data
 *
 */

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const personality_names = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Neuroticism",
  "Openess",
];

/**
 * @function getSortOrders
 * @description This function takes in a debrief object and a list of surveys,
 * and returns an array of sort orders for the debrief object.
 */
export const getSortOrders = (debriefObj, surveys) => {
  // Using debriefs.debrief_schedule.results create an array of unique brief_question
  const briefQuestions = debriefObj.debrief_schedule.results.map((debrief) => {
    return debrief.brief_question;
  });

  // Remove the duplicates from the array
  const uniqueBriefQuestions = [...new Set(briefQuestions)];

  // Map the unique brief_question ids to the survey ids, and return the sort_order
  const sortOrders = uniqueBriefQuestions.map((briefQuestion) => {
    return surveys.find((survey) => {
      return survey.id === briefQuestion;
    }).sort_order;
  });

  return sortOrders;
};

/**
 * @function restructureQuestions
 *
 * @param {*} questions
 * @returns restructured questions object
 */
export const restructureQuestions = (questions) => {
  const questionsCopy = JSON.parse(JSON.stringify(questions));
  let d1 = {
    id: 1,
    title: "DEI Factors",
    factors: questionsCopy?.questions?.factors,
  };
  questionsCopy.questions.dimensions = [d1];

  return questionsCopy;
};

// go through reportData?.response and filter out the responses that do not meet the prioritiesToFilterStructure
// the responses represent a survey respondant and the goal is to filter out any respondants that do not meet the prioritiesToFilterStructure
export const filterReportResponses = (
  reportData,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return reportData;
  }

  const filteredSurveys = reportData?.response?.map((survey) => {
    const filteredResponses = survey?.responses?.filter((response) => {
      return prioritiesToFilterStructure?.every((priority) => {
        const priorityId = priority?.priority;
        const priorityValues = priority?.values;
        const matchingResponse = response?.response?.categories?.find(
          (category) => {
            return (
              category?.id === priorityId &&
              priorityValues?.includes(category?.response)
            );
          }
        );
        return matchingResponse;
      });
    });
    const updatedSurvey = { ...survey };
    updatedSurvey.responses = filteredResponses;
    return updatedSurvey;
  });
  reportData.response = filteredSurveys;
  return reportData;
};

export const checkPersonality = (org) => {
  return org.organization.services_enabled.find((f) => f.id === 21)?.enabled;
};

export const check_comments = (resp, emp) => {
  let comm = false;
  if (emp) {
    let user_info = emp?.userEmp;

    if (
      !user_info ||
      (!user_info.read_feedback && user_info.account_type_text != "Admin")
    ) {
      return false;
    }

    // if(questionStructure){
    //   if(!("comments" in questionStructure)){
    //     return false
    //   }
    //   else if(questionStructure.comments.length<1){
    //     return false
    //   }
    // }

    for (var i = 0; i < resp.responses.length; i++) {
      if ("comments" in resp.responses[i] || "feedback" in resp.responses[i]) {
        comm = true;
        break;
      }
    }
  }

  return comm;
};

export const convert_personality_to_categories = (resp) => {
  resp.map((item) => {
    item.responses.map((r) => {
      if (Object.keys(r.response.survey_personality).length > 0) {
        let personality = calculate_personality_categories(
          r.response.survey_personality
        );
        r.response.categories.push(...personality);
      }

      return r;
    });
  });

  return resp;
};

const calculate_personality_categories = (data) => {
  let pers = personality_norms.map((item, i) => calculate_personality(data, i));
  return pers.map((item, i) => {
    let response = item < 33 ? "0" : item < 67 ? "1" : "2";
    return { id: personality_names[i], priority: "personality", response };
  });
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};
