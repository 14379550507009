import Mood from "assets/images/nav/mood_alt.svg";
import Report from "assets/images/nav/report_alt.svg";

const ToolData = [
	{
		title: "Team Health",
		icon: "heartbeat",
		color: "#6a00ff",
		description:
			"Your home for exploring aggregated feedback, data, and insights on the health and functioning of your team’s culture.",
		role: 10,
		link: "/app/team/team",
	},
	{
		title: "Team Mood",
		icon: "gem",
		img: Mood,
		color: "#F12DA2",
		description:
			"Your home for exploring aggregated feedback, data, and insights on the trending mood and morale of your team.",
		role: 10,
		link: "/app/team/eq",
	},
	{
		title: "My Data",
		icon: "line graph",
		img: Report,
		color: "#6a00ff",
		description:
			"Your home for exploring individual feedback, data, and insights collected from each member of your team.",
		role: 11,
		link: "/app/team/personal",
	},
	{
		title: "Connections",
		icon: "fork",
		color: "#F12DA2",
		description:
			"Your home for exploring the intricacies of how your team and its members are connected to one another.",
		role: 8,
		link: "/app/team/teams?name=Connections",
	},
	{
		title: "Clusters",
		icon: "group",
		color: "#F12DA2",
		description:
			"Your home for discovering cultural and emotional trends that are impacting specific groups or pods within your team.",
		role: 8,
		link: "/app/team/teams?name=Clusters",
	},
	{
		title: "Team Sentiments",
		icon: "zoom",
		color: "#6a00ff",
		description:
			"Your home for digging into the underlying sentiments and themes that are emerging from your team’s feedback.",
		role: 8,
		link: "/app/team/teams?name=Sentiments",
	},
	// {
	// 	"title":"Organization Overview",
	// 	"icon":"cogs",
	// 	"color":"#F12DA2",
	// 	"description":"Your home for sharing feedback on the health of your team. Healthy teams are safe and inclusive teams that help everyone progress towards a common goal.",
	// 	"role":5,
	// 	"link":"/HRDash"
	// }
];

export default ToolData;
