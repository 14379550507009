import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";


const Stories = () =>{

    return (
        <Container>
            <Title>Data Stories</Title>
            <Description>
                These are the data stories that you have been granted access to.
            </Description>
            <StoryItem/>
            <StoryItem/>
            <StoryItem/>

        </Container>
    )
}

const StoryItem = () =>{

    return (
        <StoryContainer>
            <StoryTitle>
                Understand your departments biggest themes
            </StoryTitle>
           <StoryDate>
                2 days ago
            </StoryDate>

            <StoryStatus>
                Pending
            </StoryStatus>

            <Button>View</Button>
           
        </StoryContainer>
    )
}

export default Stories;

const Container = styled.div`
    width:100%;`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: Raleway;
`

const Description = styled.div`
    margin-bottom: 40px;
`

const StoryContainer = styled.div`

    display:flex;
    align-items:center;
    border-radius:10px;
    padding:20px;
    margin-top:20px;
    background-color:#fff;
    box-shadow: 0px 8px 20px 0px #3F86FE14;
    justify-content:space-between;
    width:100%;
`

const StoryTitle = styled.div`
    font-size:14px;
    font-weight:600;
`

const StoryDate = styled.div`
    font-size:14px;
    color:#666D79;
`

const StoryStatus = styled.div`
    font-size:14px;
    background-color:#FFFAF1;
    color:#FEA711;
    padding:4px 8px;
    border-radius:5px;
    font-weight:600;
`
const Button = styled.button`
    color:#2D70E2;
    font-size:12px;
    padding:6px 10px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';
    border:none;
    border-radius:5px;
    margin-left:20px;
`