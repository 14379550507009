import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dropdown } from 'semantic-ui-react'
import styled, { keyframes } from "styled-components";
import TraitCard from "./TraitCard";
import SubTraitCard from "./SubTraitCard";

import Keys from "./keys";
import ConBox from "./ConBox";
import TraitReport from "./traitReport/index";
import Empty from "./Empty";
import {
  LOAD_PERSONALITY,
  LOAD_TRAIT_DATA,
  SG_GET_SURVEY_TOKENS,
} from "constants/actions";
import MobileKeys from './mobilekeys'




const Card = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  width:100%;
  padding:20px;
  margin-bottom:10px;
  position:relative;
`

const ColorBar = styled.div`
  height:5px;
  width:80px;
  position:absolute;
  background-color:${props=>props.color};
  top:0px;
  left:0px;
`


const Text1 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin-top: 75px;

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size:16px;
  }
`;

const Text2 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin-bottom: 5px;

  @media (max-width:768px){
    font-size:20px;
  }
`;

const Text3 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969696;
  margin-bottom: 40px;

  @media (max-width:768px){
    color: #4a4a4a;
    line-height: 1.31;
  }
`;

const Text7 = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
`;
const Text8 = styled.h1`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: black;
`;

const Compared = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin: 0 20px 0 20px;
  font-size: 20px;
  color: #969696;

  @media (max-width:768px){
    display:none;
  }
`;

const MobileCompared = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin: 0 20px 0 20px;
  font-size: 16px;
  color: #545454;
  font-weight:bold;

  @media (min-width:768px){
    display:none;
  }
`;

const orgPersonalityTraitList = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Emotionality",
  "Openess to Experience",
];

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction:column;
    height:100px;
    justify-content:space-between;
  }
`;

const MobileTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width:100%;
  margin-bottom:15px;

  @media (min-width:768px){
    display:none;
  }
`

const PersonalityTraitList = [
  {
    id: 1,
    name: "Extraversion",
    key: "extraversion",
    color:'#c73d88'
  },
  {
    id: 2,
    name: "Conscientiousness",
    key: "conscientiousness",
    color:'#ffb812'
  },
  {
    id: 3,
    name: "Agreeableness",
    key: "agreeableness",
     color:'#3f448c',
  },
  {
    id: 4,
    name: "Emotionality",
    key: "er",
    color:'#6a00ff'
  },
  {
    id: 5,
    name: "Openess to Experience",
    key: "openess",
     color:'#d9008a'
  },
];

const orgsubTraits = [
  { id: 1, name: "Assertiveness", key: "assertiveness" },
  { id: 2, name: "Enthusiasm", key: "enthusiasm" },
  { id: 3, name: "Politeness", key: "politeness" },
  { id: 4, name: "Compassion", key: "compassion" },
  { id: 5, name: "Orderliness", key: "orderliness" },
  { id: 6, name: "Industriousness", key: "industriousness" },
  { id: 7, name: "Volatility", key: "volatility" },
  { id: 8, name: "Withdrawl", key: "withdrawl" },
  { id: 9, name: "Abstract Interest", key: "ai" },
  { id: 10, name: "Creativity", key: "creativity" },
];

const subTraits = {
  Extraversion: ["Assertiveness", "Enthusiasm"],
  Agreeableness: ["Politeness", "Compassion"],
  Conscientiousness: ["Orderliness", "Industriousness"],
  Emotionality: ["Volatility", "Withdrawl"],
  "Openess to Experience": ["Abstract Interest", "Creativity"],
};

const subTraitskey = {
  Extraversion: ["assertiveness", "enthusiasm"],
  Agreeableness: ["politeness", "compassion"],
  Conscientiousness: ["orderliness", "industriousness"],
  Emotionality: ["volatility", "withdrawl"],
  "Openess to Experience": ["ai", "creativity"],
};

const allTraits = [
  "assertiveness",
  "enthusiasm",
  "politeness",
  "compassion",
  "orderliness",
  "industriousness",
  "volatility",
  "withdrawl",
  "ai",
  "creativity",
  "agreeableness",
  "conscientiousness",
  "extraversion",
  "openess",
  "er",
];

const subTraitList = [
  {
    id: 1,
    name: "Extraversion",
    key: "extraversion",
    subTrait: [
      { id: 1, name: "Assertiveness", key: "assertiveness" },
      { id: 2, name: "Enthusiasm", key: "enthusiasm" },
    ],
  },
  {
    id: 2,
    name: "Conscientiousness",
    key: "conscientiousness",
    subTrait: [
      { id: 5, name: "Orderliness", key: "orderliness" },
      { id: 6, name: "Industriousness", key: "industriousness" },
    ],
  },
  {
    id: 3,
    name: "Agreeableness",
    key: "agreeableness",
    subTrait: [
      { id: 3, name: "Politeness", key: "politeness" },
      { id: 4, name: "Compassion", key: "compassion" },
    ],
  },
  {
    id: 4,
    name: "Emotionality",
    key: "er",
    subTrait: [
      { id: 7, name: "Volatility", key: "volatility" },
      { id: 8, name: "Withdrawl", key: "withdrawl" },
    ],
  },
  {
    id: 5,
    name: "Openess to Experience",
    key: "openess",
    subTrait: [
      { id: 9, name: "Abstract Interest", key: "ai" },
      { id: 10, name: "Creativity", key: "creativity" },
    ],
  },
];

const PersonalityDash = (props) => {
  const [userID, setUser] = React.useState("1");
  const [compareID, setCompare] = React.useState(false);
  const [compareID2, setCompare2] = React.useState(false);
  const [personalityData, setPersonalityData] = React.useState(false);
  const [dropData, setDrop] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(false);
  const [selectedTrait, setSelectedTrait] = React.useState(false);
  const [compatible, setCompatible] = React.useState(false);
  const [teamAvg, setTeamAvg] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const [personalityToken, setPersonalityToken] = React.useState(false);

  const [employee, setEmployee] = React.useState(false);
  const [employeeId, setEmployeeId] = React.useState(null);
  const [myPersonality, setMyPersonality] = React.useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_PERSONALITY });
    dispatch({ type: LOAD_TRAIT_DATA });
    dispatch({ type: SG_GET_SURVEY_TOKENS });
  }, [dispatch]);



  const {
    get_personality_data,
    get_trait_data,
    get_auth,
    get_employees,
    get_survey_tokens,
  } = useSelector(
    (state) => ({
      get_personality_data: state.personality.personality,
      get_trait_data: state.personality.trait_data,
      get_auth: state.auth,
      get_employees: state.employees,
      get_survey_tokens: state.surveytoken.survey_tokens,
    }),
    shallowEqual
  );

  // console.log("get_personality_data", get_personality_data);

  // console.log('personalityData', personalityData)
  // console.log("myPersonality", myPersonality);

  React.useEffect(() => {
    if (get_employees && get_auth) {
      setEmployee(get_employees[get_auth.employee_id]);
      setEmployeeId(get_auth.employee_id);
    }
  }, [get_employees, get_auth]);

  React.useEffect(() => {
    if (get_survey_tokens) {
      setPersonalityToken(
        get_survey_tokens.filter((st) => st.participants_in_personality > 0)[0]
      );
    }
  }, [get_survey_tokens]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  });


  React.useEffect(() => {
    if (get_personality_data) {
      if (get_personality_data.filter((st) => (st.employee)==employeeId)[0]) {
        setMyPersonality(
          get_personality_data.filter((st) => (st.employee)==employeeId)
        );
      } else {
        setMyPersonality(false);
      }
    }
  }, [get_personality_data, get_auth, employeeId]);

  // console.log(myPersonality);
  // console.log(
  //   get_personality_data.filter(
  //     (st) => st.employee == employeeId)
  // );
  // console.log("get_auth.employee_id", employeeId);
  // personality_survey_type

  const average = (data, trait) => {
    // return Math.floor(
    let _int =
      Object.keys(data)
        .map((id, i) => {
          // console.log(id)
          return parseFloat(data[id][trait]);
        })
        .reduce((a, b) => a + b) / Object.keys(data).length;
    // );
    return Math.floor(_int);
  };

  const calculateAverage = (data) => {
    let avg = {};
    allTraits.map((item, i) => {
      avg[item] = average(data, item);
    });
    //  console.log(avg)
    return avg;
  };

 

  useEffect(() => {
    if (get_personality_data.length >0) {
      setPersonalityData(get_personality_data);

      let similar = {
            key: 1,
            text: 'Most similar',
            value: 'Similar',
            image: { avatar: true, src: '' },
      }

       let least = {
            key: 1,
            text: 'Least similar',
            value: 'Least',
            image: { avatar: true, src: '' },
      }

      let dropped = get_personality_data.map((item,i)=>{
        return {
          key: item.employee,
            text: item.name!="None None"? item.name : item.email.split('@')[0],
            value: item.employee,
            image: { avatar: true, src: item.pic },
        }
      })

      if(get_personality_data.length>1){
        dropped.unshift(least,similar)
      }
      



      setDrop(dropped)
      
      if(get_personality_data.some(e=>e.employee==get_auth.employee_id)){
        setCompare(parseInt(get_auth.employee_id));
      }else{
        setCompare(get_personality_data[0].employee);
      }

      setTeamAvg(calculateAverage(get_personality_data));

    }
  }, [get_personality_data,get_auth]);



  const getMagnitude = () => {
    if (compatible[2] > 80 || compatible[2] < 20) {
      return "significantly";
    } else if (compatible[2] > 65 || compatible[2] < 35) {
      return "moderately";
    }
    return "slightly";
  };

  const getEmployeeName = (id) => {
    if (personalityData) {
      return personalityData.filter((f) => f.employee === id)[0].name;
    }
    return "";
  };

  const getText = () => {
    return (
      (get_auth.employee_id == compareID
        ? "Your"
        : (getEmployeeName(compareID))+"'s") +
      " personality traits are " +
      getMagnitude() +
      " more " +
      (compatible[2] > 50 ? "varied" : "consistent") +
      " with " +
      (compareID2 ? getEmployeeName(compareID2) : "your team's averages") +
      " than they are " +
      (compatible[2] > 50 ? "consistent" : "varied") +
      ". Higher " +
      (compatible[2] > 50 ? "variability" : "consistency") +
      " scores indicate more natural personality " +
      (compatible[2] > 50 ? "differences" : "similarities") +
      ", suggesting " +
      (get_auth.employee_id == compareID
        ? "you are "
        : getEmployeeName(compareID) + " is ") +
      getMagnitude() +
      " more " +
      (compatible[2] > 50 ? "dissimilar " : "alike ") +
      (compareID2 ? getEmployeeName(compareID2) : "your team members") +
      " than " +
      (compatible[2] > 50 ? "alike" : "different") +
      "."
    );
  };

  if (selectedTrait) {
    window.scrollTo(0, 0)
    props.pageRef.current.scrollTo(0,0)
    return (
      <TraitReport
        employee={get_auth.employee_id}
        selectedTrait={selectedTrait}
        personalityData={personalityData}
        traitData={get_trait_data}
        reportName={selectedTrait.name}
        average={teamAvg}
        pageRef={props.pageRef}
        id1={compareID}
        id2={compareID2}
        setID1={(i) => setCompare(i)}
        setID2={(i) => setCompare2(i)}
        back={() => setSelectedTrait(false)}
      />
    );
  }

  // console.log(personalityData)
  //Empty state includes a link to the survey. This nav item is only visible if personality is turned on for that person
  //

  
  if (!personalityData && !loading) {
    return <Empty employee={employee} personalityToken={personalityToken} />;
  }

  if (!myPersonality && !loading) {
    return <Empty employee={employee} personalityToken={personalityToken} />;
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom:100
      }}
    >
       

      <div style={{ width: "95%" }}>
        {employee ? (
          <Text1>Hi {employee?.first_name} {employee?.last_name}, welcome to</Text1>
        ) : (
          ""
        )}

        <Text2>{myPersonality[0]?.team}'s Personality Suite</Text2>
        <Text3>
          The Big Five Personality Assessment is the most comprehensive and
          reliable personality assessment in the world
        </Text3>

        <MobileTitle>Current comparison</MobileTitle>
        <DropdownContainer>

         <Dropdown
            options={dropData}
            value={compareID}
            selection
            search
            onChange={(d,{name,value}) => setCompare(value)}
          />
            <Compared>relative to</Compared>

             <Dropdown
            options={dropData}
            defaultValue={compareID2}
            selection
            search
            onChange={(d,{name,value}) => setCompare2(value)}
          />
          {/*<DropDown
            data={personalityData}
            default={compareID}
            type={1}
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={(id) => setCompare(id)}
          />
          <Compared>relative to</Compared>
          <MobileCompared>&</MobileCompared>
          <DropDown
            data={personalityData}
            type={2}
            default={compareID2}
            setSelectedEmployee={setSelectedEmployee}
            selectedEmployee={(id) => setCompare2(id)}
          />*/}
        </DropdownContainer>
      </div>

      {personalityData?.length > 0 &&
        PersonalityTraitList.map((item, i) => {
          return (
          <Card>
            <ColorBar color={item.color}/>
            <TraitCard
              key={i}
              type={item.name}
              trait={item}
              keyword={item.key}
              id1={compareID}
              personalityData={personalityData}
              id2={compareID2}
              setSelectedTrait={setSelectedTrait}
              // showReport={setSelectedEmployee}
              average={teamAvg[item.key]}
              profiles={personalityData}
              employee={get_auth.employee_id}
              loading={loading}
              color={item.color}
            />
            <SubTraitCard
              type={item.name && subTraits[item.name][0]}
              id1={compareID}
              id2={compareID2}   
              personalityData={personalityData}     
              average={teamAvg[subTraitskey[item.name][0]]}
              profiles={personalityData}
               keyword={subTraitskey[item.name][0]}
                color={item.color}
            />
            <SubTraitCard
              type={item.name && subTraits[item.name][1]}
              id1={compareID}
              id2={compareID2}   
              personalityData={personalityData}     
               average={teamAvg[subTraitskey[item.name][1]]}
              profiles={personalityData}
               keyword={subTraitskey[item.name][1]}
                color={item.color}
            />
        </Card>
          );
        })}
    </div>
  );
};

export default PersonalityDash;
