import styled, { keyframes } from "styled-components";
import React from "react";
import Zscore from './Zscore'
import Cluster from './Cluster'
import Connections from './Connections'
import ThemeTimeline from './ThemeTimeline'

const Dashboard = () =>{

	return (
		<Container>
			<Connections/>
			<Card>
				<Title>Relative changes in team metrics</Title>
				<Description>Porchetta pork ribeye prosciutto, tenderloin ground round shankle kevin alcatra brisket short ribs filet mignon tongue.</Description>
				<Zscore/>
			</Card>

			<Cluster/>
			<ThemeTimeline/>

		</Container>
		)
}


export default Dashboard

const Container = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
`

const Card = styled.div`
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:40px;
	width:calc(50% - 20px);
	display:flex;
	align-items:center;
	flex-direction:column;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:16px;
	font-weight:bold;
	width:100%;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;
	width:100%;
`