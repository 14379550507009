import styled, { keyframes } from "styled-components";
import React from "react";
import EmptyImage from "assets/images/UI/no_data.svg";

const Empty = ({ addMargin = true }) => {
  return (
    <Container addMargin={addMargin}>
      <IMG src={EmptyImage} height={250} width={250} />
      <Text>No data available to view</Text>
      <P>
        Please check back once your first survey has been completed, or try
        changing your filters.
      </P>
    </Container>
  );
};

export default Empty;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${({ addMargin }) => (addMargin ? "100px" : "0")};
`;

const IMG = styled.img`
  width: 300px;
`;

const Text = styled.div`
  font-family: "Poppins";
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
`;

const P = styled.div`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  max-width: 600px;
  margin-top: 10px;
  line-height: 1.5;
  text-align: center;
`;
