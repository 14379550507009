import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import Assignee from "./Assignee";
import { useSelector } from "react-redux";
import { TeamIcon, CheckIcon } from "./Icons";

var dateFormat = require("dateformat");

const planStatus = {
  Planned: {
    id: 1,
    statusText: "Planned",
    color: "71, 108, 250",
  },
  InProgress: {
    id: 2,
    statusText: "In Progress",
    color: "243, 171, 60",
  },
  ActionRequired: {
    id: 3,
    statusText: "Action Required",
    color: "230, 101, 121",
  },
  // OnHold: {
  //   id: 4,
  //   statusText: "On Hold",
  //   color: "209, 199, 2",
  // },
  Completed: {
    id: 5,
    statusText: "Completed",
    color: "102, 214, 134",
  },
  Archived: {
    id: 11,
    statusText: "Archived",
    color: "138, 138, 138",
  },
  Delete: {
    id: 99,
    statusText: "For Deletion",
    color: "138, 138, 138",
  },
};

const getPlanStatus = (id) => {
  const keys = Object.keys(planStatus);

  let status = null;
  keys.forEach((key) => {
    if (planStatus[key].id === id) {
      status = planStatus[key];
    }
  });

  return status;
};

const Actions = ({ data, onClick, categories, commentTotals }) => {
  const [hover, setHover] = useState(false);
  const [comments, setComments] = useState();
  const { planActivity } = useSelector((state) => ({
    planActivity: state.actionPlan.planActivity,
  }));

  const getPrimary = (primary) => {
    let sportName = categories?.categories
      ?.find((f) => f.priority === "primary")
      .options.find((f) => f.id === primary)?.name;
    if (!sportName) {
      const sportPrimary = categories?.categories
        ?.find((f) => f.priority === "primary")
        .options.find((o) => {
          return o?.subfactors.find((sf) => sf.id === primary);
        });
      if (sportPrimary) {
        sportName = sportPrimary.subfactors.find(
          (sf) => sf.id === primary
        ).name;
      }
    }
    return sportName;
  };

  return (
    <Container onClick={() => onClick()}>
      <TopContainer>
        <TitleWrapper>
          {CheckIcon()}
          <Title>{data.metric.title || data?.metric}</Title>
        </TitleWrapper>
        <div style={{ display: "flex" }}>
          <Primary>
            <IconWrapper>{TeamIcon("#9E9FA1")}</IconWrapper>
            <PrimaryText>{getPrimary(data.primary)}</PrimaryText>
          </Primary>
          <More>
            <Icon
              name="ellipsis horizontal"
              style={{ margin: 0, marginTop: -3, opacity: 0.5 }}
              color="grey"
            />
          </More>
        </div>
      </TopContainer>
      <Status color={getPlanStatus(data.status).color}>
        <StatusText>{getPlanStatus(data.status).statusText}</StatusText>
      </Status>
      <Bottom>
        <Left>
          <Assigned>
            <Assignee assignees={data.member} stacked={true} />
          </Assigned>
          <Dated>{dateFormat(data.startDate, "mmmm d, yyyy")}</Dated>
        </Left>
      </Bottom>
    </Container>
  );
};

export default Actions;

const Container = styled.div`
  width: "100%";
  background: white;
  padding: 9px;
  display: flex;
  flex-direction: column;
  height: 170px;
  cursor: pointer;
  margin-bottom: 1px;
  font-family: "Raleway"
  margin-bottom: 10px;
  border-radius: 3px;
  border: lightgrey 1px solid;
  position:relative;
`;

const Action = styled.div`
  display: flex;
  width: 50px;
  left: 150;
`;

const IconWrapper = styled.div`
  padding-left: 5px;
`;

const PrimaryText = styled.div`
  padding-left: 5px;
  white-space: nowrap;
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  max-width: 75px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  margin-left: 5px;
`;

const Description = styled.div`
  margin-top: 10px;
  display: -webkit-box;
  max-width: 100%;
  min-height: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 9px;
  line-height: 14px;
  color: #9e9fa1;
`;

const More = styled.div`
  border-radius: 100px;
  border: lightgrey 1px solid;
  background-color: white;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 33px;
  height: 22px;
  &:hover {
    background-color: rgba(9, 30, 66, 0.2);
  }
`;

const Metric = styled.div`
  padding: 3px 10px;
  border-color: rgb(118, 118, 118);
  background-color: #2d50e3;
  color: white;
  font-weight: bold;
  font-size: 14px;
  border-radius: 2px;
  text-transform: uppercase;
  width: 100px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Status = styled.div`
  margin-top: 10px;
  background-color: ${(props) => "rgba(" + props.color + ")"};
  border-radius: 30px;
  display: flex;
  height: 15px;
  margin-right: 10px;
  width: fit-content;
  align-items: center;
  justify-content: center;
`;

const StatusText = styled.div`
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 8px;
  line-height: 12px;
  display: flex;
  text-align: center;
`;

const Assigned = styled.div`
  position: relative;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 9px;
`;
const Dated = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  color: #6d6d6d;
`;

const Comments = styled.div``;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Primary = styled.div`
  height: 22px;
  border-color: rgb(118, 118, 118);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 1px solid lightgrey;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 30px;
  width: fit-content;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 14px;
  color: #6d6d6d;
`;

const TitleWrapper = styled.div`
  flex-direction: row;
  display: flex;
  padding-top: 2px;
`;
