import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";

const Participation = ({score}) =>{

    return (
        <Container>
            <T1>
                Your team's survey participation rate was <strong>67%</strong>. 
                16 out of 24 team members contributed to these results.
            </T1>

            <Chart
						options={data3.options}
						series={data3.series}
						type="bar"
						height={350}
					/>

           
          
        </Container>
    )

}

export default Participation

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:30px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const Description = styled.div`
    margin-bottom: 40px;
`

const Box = styled.span`
    background-color:#EBF1FD;
    padding:5px 10px;
    border-radius:5px;
    margin-left:10px;
    font-weight:600;
    color:#2D70E2;
`


const data3 = {
	series: [
		{
			data: [67, 88],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 600,
			height: 400,
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              distributed: true,
              columnWidth: '45%',
            }
          },
		colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
		xaxis: {
			categories: [
				"Your Team",
                "Your Organization",
			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 100,
			decimalsInFloat: 0,
			title: {
				text: "Participation rate",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
