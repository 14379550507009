import React from 'react'
import styled from "styled-components";
import { Link } from 'react-router-dom';

function MemberHomePage({ Menulist }) {
  return (
    <ColumnHolder>
      <LeftColumn>
        <Title>Member Management Menu</Title>

        {Menulist.filter(m => m.visible === true).map((m,i) => {
          return <NavContainer key={i}>
            <Link to={m.path}>
              <MenuTitle>{m.title}</MenuTitle>
              <MenuText> {m.subtitle}</MenuText>
            </Link>
          </NavContainer>
        })}

      </LeftColumn>

      <RightColumn>
        <p>Recently Changed</p>
        <p> Column 3: Put any relevant content here  </p>
      </RightColumn>
    </ColumnHolder>
  )

}

export default MemberHomePage


const ColumnHolder = styled.div`
margin-top: 50px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
width: 100%;
`;

const LeftColumn = styled.div`
flex: 60%;
`

const RightColumn = styled.div`
flex: 35%;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
margin-bottom: 30px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;


const MenuTitle = styled.div`
  font-size: 20px;
  flex-basis: 100%;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;


const MenuText = styled.div`
  font-size: 12px;
  flex-basis: 100%;
  font-family: "Red Hat Display", sans-serif;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  width: 90%;
  align-items: center;
  border: 1px solid #d3dbdb; 
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

/* Use a collapsed row to break to a new row */
const BreakRow = styled.div`
    flex-basis: 100%;
    height: 0;
`

/* Use a collapsed column to break to a new column */
const BreakColumn = styled.div`
    flex-basis: 100%;
    width: 0;
  `