import styled, { keyframes } from 'styled-components';
import React,{useState} from 'react';


const Card = styled.div`
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	border-radius: 8px;
	display:inline-block;
	flex-direction:column;
	width:360px;
	overflow:hidden;
	position:absolute;
`


const CardP = styled.div`
	 font-size: 16px;
	 color: #ffffff;
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;

`

const CardBottom = styled.div`
	width:100%;
	height:50px;
	display:flex;
	align-items:center;
	justify-content:center;
`
const ScoreCard = styled.div`
	height:300px;
	width:100%;
	padding:20px;
	 background-image: linear-gradient(94deg, #ffb347 2%, #ffcc33 109%);
	 display:flex;
	 justify-content:center;
	 flex-direction:column;
`
const CardText = styled.div`
	font-size: 23px;
  font-weight: bold;
  color: #ffffff;
  font-family: 'Red Hat Display', sans-serif;
`

const EmptyCard = ({selected,title}) =>{

	return (
		<Card key={selected}>
			<ScoreCard>
				<CardText>Your {title[selected]}</CardText>
				<CardP>When team members trust each other to pull their weight and follow through. Without dependability, trust decreases, resentment rises, and conflict is likely.</CardP>
		

			</ScoreCard>
		</Card>
		)
}


export default EmptyCard