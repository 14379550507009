import React from 'react'
import { Button, Header, Image, Modal,Dropdown } from 'semantic-ui-react'
import styled from "styled-components";

function ModalExampleModal({open,setOpen}) {


  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Modal.Header>Select a tag</Modal.Header>
      <Container>
       <Description>
            Select the appropriate tag reason for archiving this employee. This information will be used to analyze archived employee data.
       </Description>
            <Dropdown
            placeholder='Select reason for archiving'
            fluid
            selection
            options={friendOptions}
        />
      </Container>

      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Save"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ModalExampleModal



const Description = styled.div`
font-family: Raleway;
font-size: 14px;
color:#666D79;
line-height:1.5;
margin-bottom:20px;
`

const Container = styled.div`
    padding:20px;
    padding-bottom:60px;

`

const friendOptions = [
    {
      key: 'Resignation',
      text: 'Resignation',
      value: 'Resignation',
    },
    {
      key: 'Termination',
      text: 'Termination',
      value: 'Termination',
    },
    {
      key: 'Contract End',
      text: 'Contract End',
      value: 'Contract End',
    },
    {
        key: 'Reorganization',
        text: 'Reorganization',
        value: 'Reorganization',
    },
    {
      key: 'Retirement',
      text: 'Retirement',
      value: 'Retirement',
    },
    {
      key: 'Transfer',
      text: 'Transfer',
      value: 'Transfer',
    },
    {
      key: 'Extended Leave',
      text: 'Extended Leave',
      value: 'Extended Leave',
    },
    {
        key: 'System Error',
        text: 'System Error',
        value: 'System Error',
      },
        {
        key: 'Other',
        text: 'Other',
        value: 'Other',
        }
  ]