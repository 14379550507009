import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { getTranslatedText } from "./pdfUtils";
import translations from "./translations.json";



const Ranking = ({ title, headers, data, isFrench }) => {
  return (
    <Container>
      <Top>
        <TH width={40}>{title}</TH>
        <TH width={20}>
          {getTranslatedText("DIMENSIONS", isFrench, translations)}
        </TH>
        {headers.map((item) => (
          <TH width={40 / headers.length}>{item}</TH>
        ))}
      </Top>

      {data.map((item) => {
        return (
          <Row>
            <TD width={40} left={true}>
              {item[0]}
            </TD>
            <TD width={20}>{item[1]}</TD>
            {headers.map((d, i) => {
              return (
                <TD width={40 / headers.length}>
                  {((isNaN(item[i + 2] > 0 && item[i + 2]) ||
                    item[i + 2] === -1) &&
                    "--") ||
                    (item[i + 2] > 0 && item[i + 2]) ||
                    "0.0"}
                </TD>
              );
            })}
          </Row>
        );
      })}
    </Container>
  );
};


export default Ranking


const Container = styled.div`
	width:100%;
	margin-bottom:30px;
`

const Top = styled.div`
	display:flex;
	width:100%;
	border-bottom:1px solid black;
	background-color:#031A4A;
	color:white;
`

const TH = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	font-weight:bold;
	justify-content:center;
	height:30px;
	font-size:12px;
	border-right:1px solid black;

`


const Row = styled.div`
	display:flex;
	border-bottom:1px solid black;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	justify-content:center;
	align-items:center;
	font-weight:300;
	font-size:12px;
	padding:10px;

	border-right:1px solid black;
	
`