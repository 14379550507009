import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";


const ReviewPlan = ({plan,nextStep,edit}) => {

    return (
        <Container>
            <Card>
                <FormatData dataArray={plan}/>
            </Card>

            <ButtonContainer>
                <Button2 onClick={()=>edit()}>Edit plan</Button2>
                <Button onClick={()=>nextStep()}>Keep changes</Button>

            </ButtonContainer>
            
        </Container>
    )

}

export default ReviewPlan;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`

`


const Card = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding:20px;

  opacity: 0; // Start with hidden state
animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state


// 3. Adjust delay for each sample
animation-delay: 0.3s; 
`

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
`

const Button2 = styled.button`
    background-color: #E0E0E0;
    color: black;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-right:10px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`


const FormatData = ({ dataArray }) => {
  
    const renderElements = (items) => {
      const elements = [];
      let keyCounter = 0;
  
      items.forEach((item) => {
        switch (item.type) {
          case 't1':
            elements.push(<h3 key={keyCounter++}>{item.text}</h3>);
            break;
          case 't2':
            elements.push(<h4 key={keyCounter++}>{item.text}</h4>);
            break;
          case 'p':
            elements.push(<p key={keyCounter++}>{item.text}</p>);
            break;
          case 'b':
            // If the previous element is not a list, start a new list
            if (!elements.length || elements[elements.length - 1].type !== 'ul') {
              elements.push(
                <ul key={keyCounter++}>{[
                  <li key={keyCounter++}>{item.text}</li>
                ]}</ul>
              );
            } else {
              // If the previous element is a list, add the bullet point to it
              let lastElement = elements.pop();
              lastElement = React.cloneElement(lastElement, {
                children: [...lastElement.props.children, <li key={keyCounter++}>{item.text}</li>]
              });
              elements.push(lastElement);
            }
            break;
          default:
            break;
        }
      });
  
      return elements;
    };
  
    return <div>{renderElements(dataArray)}</div>;
  };
