import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Modal, Dropdown } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_ADD_CHAT_RECOMMENDATION } from "constants/actions";
import Filters from "./Filters";
import SurveyVersions from "reports/Audit/VersionControl/index";
import { getStructuredFeedback } from "reports/Tag/calculations";
import Context from "./Context";
import { templates } from "./templates";

const RecommendationBuilder = ({
  data,
  pillar_scores,
  setPending,
  trigger = false,
  setOpen,
}) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(
    "Culture Factor Report"
  );
  const [selectedBenchmark, setSelectedBenchmark] = useState(
    "Organization Averages"
  );
  const [originalOptionsList, setOriginalOptionsList] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextData, setContextData] = useState();
  const [triggerRecommendation, setTriggerRecommendation] = useState(false);
  const [templateOptions, setTemplateOptions] = useState();
  const [selectedRecTemplate, setSelectedRecTemplate] = useState();

  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );
  const surveyVersion = useSelector(
    (state) => state.audit?.survey_version,
    shallowEqual
  );

  const organization = useSelector(
    (state) => state.organizations,
    shallowEqual
  );

  const get_report_template = useSelector(
    (state) => state.report_template,
    shallowEqual
  );

  // TODO: Use get_report_template object when available.
  useEffect(() => {
    if (get_report_template?.report_template) {
      const formattedTemplates = get_report_template?.report_template?.map(
        (template, i) => {
          return {
            key: i,
            text: template?.name,
            value: template?.id,
          };
        }
      );

      setTemplateOptions(formattedTemplates);
    }
  }, [get_report_template]);

  const [dropdownOptions, setDropdownOptions] = useState(originalOptionsList);

  useEffect(() => {
    if (surveyVersion) {
      let _options = [
        ...surveyVersion?.questions.dimensions.flatMap((item, index) =>
          item.factors.map((factor, i) => ({
            key:
              index *
                (surveyVersion?.questions.dimensions[index - 1]
                  ? surveyVersion?.questions.dimensions[index - 1].factors
                      .length
                  : 0) +
              i,
            text: factor.title,
            value: {
              title: factor.title,
              type: "pillar",
              factor,
              dimension: index,
            },
          }))
        ),
      ];
      setDropdownOptions(_options);
    }
  }, [surveyVersion]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      let _tags = selectedFilters.map((item) => item.name);
      setTags(_tags);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (selectedTemplate === "Outcome Analysis") {
      setDropdownOptions(
        originalOptionsList.filter((f) => f.value.type === "outcomes")
      );
    }
    if (selectedTemplate === "Culture Factor Report") {
      setDropdownOptions(
        originalOptionsList.filter((f) => f.value.type === "pillar")
      );
    }
  }, [selectedTemplate]);

  const findFactorFeedback = (f, selectedItems) => {
    let factor = f.factor;
    let dimension = f.id;
    let _selectedItems = selectedItems.filter(
      (f) => f?.factor.id - 1 === factor && f?.dimension === dimension
    );

    if (_selectedItems.length > 0) {
      return true;
    }

    return false;
  };

  const findFactorScores = (f, selectedItems) => {
    let factor = f.factor;
    let dimension = f.dimension;
    let _selectedItems = selectedItems.filter(
      (f) => f?.factor.id - 1 === factor && f?.dimension === dimension
    );
    if (_selectedItems.length > 0) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (contextData && contextData?.length > 0 && triggerRecommendation) {
      requestRecommendation();
    }
  }, [contextData, triggerRecommendation]);

  const requestRecommendation = () => {
    //Filter appropriate scores based on the selected items and templates
    // Outcomes data should have outcome data associated with it

    //For the factor data we will need to calculate scores based on each question in the factor
    //We will need to calculate the average score for each question in the factor
    const feedback = getStructuredFeedback(data);
    //If the data is for the entire organization, we need to pass the org name here
    let reqData;
    // If we want to pass the template
    let selectedRecTemp = get_report_template?.report_template?.find(
      (t) => t?.id === selectedRecTemplate
    )?.template;

    const reqParams = {
      tags: tags,
      template: selectedTemplate,
      org_name: organization?.organization?.name,
      selectedBenchmark: selectedBenchmark,
      contextData: contextData,
      recommendation_template: selectedRecTemplate,
    };

    if (
      selectedTemplate === "Thematic Analysis" ||
      selectedTemplate === "Culture Factor Report"
    ) {
      let _filtered_feedback = feedback?.filter((f) =>
        findFactorFeedback(f, selectedItems)
      );
      let _filtered_scores = pillar_scores?.filter((f) =>
        findFactorScores(f, selectedItems)
      );

      reqData = {
        selectedItems: selectedItems,
        feedback: _filtered_feedback,
        scores: _filtered_scores,
      };
    } else {
      reqData = {
        selectedItems: selectedItems,
        feedback: feedback,
        scores: pillar_scores,
      };
    }

    const recReq = {
      survey_data: reqData,
      params: reqParams,
      chat_token: "mgngxkfkot",
      recommendation_template: selectedRecTemplate,
      api_version: 3,
    };

    //Call the api to generate the recommendation
    //The api will return a recommendation object
    //The recommendation object will be added to the table
    dispatch({
      type: SG_ADD_CHAT_RECOMMENDATION,
      payload: recReq,
    });
    setOpen(false);
  };

  useEffect(() => {
    const feedback = getStructuredFeedback(data);
    if (selectedItems?.length > 0) {
      let _filtered_feedback = feedback.filter((f) =>
        findFactorFeedback(f, selectedItems)
      );
      setDataLength(_filtered_feedback.length);
    } else {
      setDataLength(feedback.length, selectedItems);
    }
  }, [selectedItems, data]);

  return (
    <Content>
      <Title>Generate a recommendation</Title>
      <Context
        isOpen={showContextMenu}
        selectedFactor={selectedItems?.[0]}
        setIsOpen={(val) => setShowContextMenu(val)}
        setContextData={(data) => setContextData(data)}
        triggerRecommendation={(v) => setTriggerRecommendation(v)}
      />
      <Description>
        Customize your AI generated recommendation by selecting the area of
        focus that will help to build a plan customized to your specific needs.
      </Description>

      <SmallLabel>This recommendation is for:</SmallLabel>
      <Filters
        org_name={organization?.organization?.name}
        data_length={dataLength}
      />

      {templateOptions && (
        <ActionList>
          <T2>Template</T2>
          <Label>
            Select a template for your recommendation, this will determine the
            format of your report.
          </Label>

          <Dropdown
            placeholder="Template selection"
            fluid
            selection
            value={selectedRecTemplate}
            onChange={(e, { value }) => {
              setSelectedRecTemplate(value);
            }}
            options={templateOptions}
          />
        </ActionList>
      )}

      <ActionList>
        <T2>Benchmark</T2>
        <Label>
          Select a benchmark to compare the culture scores to in the
          recommendations.
        </Label>
        <Dropdown
          placeholder="Benchmark selection"
          fluid
          selection
          value={selectedBenchmark}
          onChange={(e, { value }) => {
            setSelectedBenchmark(value);
          }}
          options={benchmarkOptions}
        />
      </ActionList>
      <ActionList>
        <T2>Culture Factor</T2>
        <Label>
          Choose one or more culture factors to create a recommendation for. The
          report will contain recommendations and summaries for each of the
          culture factors you choose.
        </Label>
        <Dropdown
          placeholder="Areas of focus"
          fluid
          selection
          value={selectedItems[0]}
          onChange={(e, { value }) => {
            setSelectedItems([value]);
          }}
          options={dropdownOptions}
        />
      </ActionList>

      <T2 style={{ marginTop: 20 }}>Data points</T2>
      <Description>
        <strong>{dataLength}</strong> data points have been identified for this
        recommendation.
      </Description>

      <SurveyVersions hidden={true} />

      <Bottom>
        <Button
          // onClick={() => requestRecommendation()}
          onClick={() => setShowContextMenu(true)}
          disabled={dataLength === 0 || selectedItems.length === 0}
        >
          Build recommendation
        </Button>
      </Bottom>
    </Content>
  );
};

export default RecommendationBuilder;

const Button = styled.button`
  background: ${(props) => (props.disabled ? "#E5E5E5" : "#2254AA")};
  border-radius: 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #2a3039;
  margin-bottom: 10px;
`;

const Content = styled.div`
  padding-right: 20px;
`;

const Circle = styled.div`
  background: #fea711;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const Tag = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const T1 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2a3039;
`;

const T2 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 8px;
`;

const Description = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666d79;
  margin-bottom: 20px;
  line-height: 1.6;
`;

const ActionList = styled.div`
  margin-top: 32px;
`;

const Label = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: #666d79;
  margin-bottom: 8px;
`;

const SmallLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  color: #666d79;
  margin-bottom: 12px;
`;

const StyledTextArea = styled.textarea`
  padding: 8px 12px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  margin-top: 16px;
  resize: none; /* Disable textarea resizing */
  height: 100px; /* Set fixed height */
  width: 100%; /* Set fixed width */
  /* Additional styles can be added here */

  &:focus {
    border-color: #2d70e2; /* Set border color when textarea is highlighted */
    outline: none; /* Remove default outline */
  }
`;

const DropTitle = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2a3039;
`;

const DropDescription = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #666d79;
  margin-top: 8px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`;
const Bottom2 = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top: 1px solid #e5e5e5;
`;

const reportTemplates = [
  { key: 0, text: "Thematic Analysis", value: "Thematic Analysis" },
  { key: 1, text: "Outcome Analysis", value: "Outcome Analysis" },
  { key: 2, text: "Culture Factor Report", value: "Culture Factor Report" },
];

const benchmarkOptions = [
  { key: 0, text: "Organization Averages", value: "Organization Averages" },
  { key: 1, text: "Global Benchmarks", value: "Global Benchmarks" },
];
