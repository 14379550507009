import styled from "styled-components";
import React from "react";

import OverviewGridOTP from "./OverviewGrid";
import OverviewGrid from "./OverviewGrid2";
import OverviewGrid1D from "./OverviewGrid1D";

const Overview = ({
  nav,
  data,
  refresh,
  structure,
  WhiteLabel,
  rule,
  questions,
  pdf = false,
  overview = false,
  otpPdf = false,
  selected,
  standards,
  otpView = false,
  language,
  pdfAnchor,
}) => {
  const isOneDimensional = () => {
    return questions.dimensions.length === 1;
  };

  const selectOverview = () => {
    if (Number(WhiteLabel?.overview) === 1) {
      return (
        <OverviewGridOTP
          nav={nav}
          data={data}
          refresh={refresh}
          structure={structure}
          WhiteLabel={WhiteLabel}
          rule={rule}
          questions={questions}
          pdf={false}
          otpPdf={otpPdf}
          overviewReport={overview}
          selected={selected}
          otpView={otpView}
          language={language}
        />
      );
    }

    if (isOneDimensional()) {
      return (
        <OverviewGrid1D
          nav={nav}
          data={data}
          refresh={refresh}
          structure={structure}
          WhiteLabel={WhiteLabel}
          rule={rule}
          questions={questions}
          pdf={pdf}
          overviewReport={overview}
          selected={selected}
        />
      );
    }

    return (
      <OverviewGrid
        nav={nav}
        data={data}
        refresh={refresh}
        structure={structure}
        WhiteLabel={WhiteLabel}
        rule={rule}
        questions={questions}
        pdf={pdf}
        overviewReport={overview}
        selected={selected}
        standards={standards}
        pdfAnchor={pdfAnchor}
      />
    );
  };

  return <Container>{selectOverview()}</Container>;
};

export default Overview;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
