import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Dropdown from "../Dropdown";

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const TableStatus = ({ selectedOption, setStatus, editEnabled }) => {
  const [selected, setSelected] = useState(selectedOption);

  const handleSelected = (t) => {
    const matchedStatus = planStatus.find((status) => status.text === t);
    setStatus(matchedStatus.value);
    setSelected(t);
  };

  const statusOptions = planStatus.map((status) => {
    return status.text;
  });

  return (
    <StatusContainer>
      {(editEnabled && (
        <Dropdown
          handleOptionChange={(t) => handleSelected(t)}
          options={statusOptions}
          selectedOption={selected}
          inModal={true}
        />
      )) || (
        <SelectedOption>{selectedOption || statusOptions[0]}</SelectedOption>
      )}
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectedOption = styled.div`
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

export default TableStatus;
