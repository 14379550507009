import styled, { keyframes } from 'styled-components';
import React from "react";
import SelectItem from './SelectItem'


const Component8 = () =>{
    const [selected,setSelected] = React.useState(false)
    return (
        <Container>

            <T1>Based on that need, which objective would you like to focus on?</T1>
            <Text delay={0.5}>
            Objectives are the specific outcomes you would like to target as a result of addressing a particular need.
            </Text>


            <SelectArea delay={1}>
            <SelectItem 
                selected={selected==1}
                setSelected={()=>setSelected(1)}
                title="Encourage Constructive Feedback and Dissent"
                item="Foster an environment where employees are comfortable providing feedback and engaging in healthy dissent, both agreeing and disagreeing with your ideas." />

            <SelectItem 
                selected={selected==2}
                setSelected={()=>setSelected(2)}
                title="Create a Safe Space for Mistakes and Growth" 
                item="Cultivate an environment where employees feel safe admitting mistakes and view them as opportunities for learning and improvement." />

           
        </SelectArea>
           

            
        </Container>
    )
}

export default Component8

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`



const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:30px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`


const SelectArea = styled.div`
opacity:0;
animation: ${fadeIn} 1s ease-out forwards;
animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`