import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';

const levels = [
  { label: "Organizational wide", description: "For strategies that impact the entire organization" },
  { label: "Department/Team", description: "For strategies that managers can implement with their teams" }
];

const ContextLibrary2 = ({ onSelectLevel, goBack }) => {
  const [selectedLevel, setSelectedLevel] = useState(null);

  const handleLevelClick = (level) => {
    setSelectedLevel(level);
  };

  const handleNextClick = () => {
    if (selectedLevel) {
      onSelectLevel(selectedLevel);
    }
  };

  return (
    <Container>
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <BlueTitle>Level of Influence</BlueTitle>
      <Title>Select the level of influence</Title>
      <Description>Choose the level at which you want to apply this recommendation:</Description>

      <LevelContainer>
        {levels.map((level, index) => (
          <LevelCard
            key={index}
            onClick={() => handleLevelClick(level.label)}
            isSelected={selectedLevel === level.label}
          >
            <LevelTitle>{level.label}</LevelTitle>
            <LevelDescription>{level.description}</LevelDescription>
          </LevelCard>
        ))}
      </LevelContainer>

      <NextButton onClick={handleNextClick} disabled={!selectedLevel}>
        Next
      </NextButton>
    </Container>
  );
};

export default ContextLibrary2;

// Styled Components
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.8s ease-out forwards;
  padding: 20px;
  max-width: 600px;
  margin: auto;
  font-family: 'Raleway', sans-serif;
`;

const BlueTitle = styled.div`
  color: #2D70E2;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 20px;
`;

const LevelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LevelCard = styled.div`
  border: 2px solid ${({ isSelected }) => (isSelected ? '#2D70E2' : '#ddd')};
  background-color: ${({ isSelected }) => (isSelected ? '#e6f0ff' : '#fff')};
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  box-shadow: ${({ isSelected }) => (isSelected ? '0 4px 12px rgba(0, 0, 0, 0.15)' : 'none')};
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#d0e4ff' : '#f8f8f8')};
    border-color: #2D70E2;
  }
`;

const LevelTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

const LevelDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin-top: 5px;
`;

const NextButton = styled.button`
  margin-top: 30px;
  padding: 12px 24px;
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#2D70E2')};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;
  
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#1a4ea1')};
  }
`;

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
    margin-bottom:10px;
`