import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { calculateFactorScores } from './calculations';
import { Icon } from 'semantic-ui-react';
import styled,{keyframes} from 'styled-components';
import factor_content from './factor_content.json';


const checkContent = (factor) => {
  if (factor_content[factor]) {
    return factor_content[factor];
  } 
    return "";
  
};

const BenchmarkDiagnostic = ({ onGenerateReport, onFactorSelected, goBack, data }) => {
  const [sortedDifferenceData, setSortedDifferenceData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFactor, setSelectedFactor] = useState(null);


  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  useEffect(() => {
    if (core_data && data) {
      console.log(data)
      const chartData = calculateFactorScores(data, core_data);

      const differenceSeries = chartData.series.find(
        (seriesItem) => seriesItem.name === 'Average Score'
      );


      if (differenceSeries) {
        const differenceData = differenceSeries.data.map((dataPoint, index) => ({
          name: dataPoint.x,
          value: dataPoint.y != null ? parseFloat((dataPoint.y * 10).toFixed(1)) : null,
          factorId: dataPoint.factorId,
          dimensionId: dataPoint.dimensionId,
          feedbackLength: dataPoint.feedbackLength
        }));

        const sortedData = differenceData
          .filter((item) => item.value != null)
          .sort((a, b) => a.value - b.value); // Sort smallest to largest
        setSortedDifferenceData(sortedData);
      }
    }
  }, [core_data, data]);

  const factorsPerPage = 3;
  const totalPages = sortedDifferenceData ? Math.ceil(sortedDifferenceData.length / factorsPerPage) : 0;

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    setSelectedFactor(null);  // Reset selected factor when changing pages
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    setSelectedFactor(null);  // Reset selected factor when changing pages
  };

  const handleSelect = (factor) => {
    setSelectedFactor(factor);
  };

  const selectFactor = (factor) => {
    if (onFactorSelected) {
      onFactorSelected(factor);
    }
  };

  if (!sortedDifferenceData) {
    return (
      <Container>
        <Title>Global Benchmark Comparison Diagnostic</Title>
        <Description>Loading data...</Description>
      </Container>
    );
  }

  const indexOfLastFactor = currentPage * factorsPerPage;
  const indexOfFirstFactor = indexOfLastFactor - factorsPerPage;
  const currentFactors = sortedDifferenceData.slice(indexOfFirstFactor, indexOfLastFactor);

  const maxScore = Math.max(...sortedDifferenceData.map((item) => item.value));
  const minScore = Math.min(...sortedDifferenceData.map((item) => item.value));

 

  return (
    <Container>
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <Title>Culture Factor Score Comparison</Title>
      <Description>
        Based on your culture data, please select a culture factor you'd like to create an action plan for.
      </Description>

      <CardArea key={currentPage}>
        {currentFactors.map((factor) => (
          <FactorCard
            key={`${factor.factorId}-${currentPage}`}  // Ensure unique key
            factor={factor}
            onClick={() => handleSelect(factor)}
            selected={selectedFactor && selectedFactor.factorId === factor.factorId && selectedFactor.dimensionId === factor.dimensionId}
            maxScore={maxScore}
            minScore={minScore}
            description={checkContent(factor.name)?.description}
          />
        ))}
      </CardArea>

      <Pagination>
        <Icon
          name="chevron left"
          onClick={prevPage}
          style={{ cursor: currentPage === 1 ? 'default' : 'pointer', opacity: currentPage === 1 ? 0.5 : 1 }}
        />
        <PageInfo>Page {currentPage} of {totalPages}</PageInfo>
        <Icon
          name="chevron right"
          onClick={nextPage}
          style={{ cursor: currentPage === totalPages ? 'default' : 'pointer', opacity: currentPage === totalPages ? 0.5 : 1 }}
        />
      </Pagination>

      {selectedFactor ? (
        <SelectedAreaComponent
          factor={selectedFactor}
          selectFactor={selectFactor}
        />
      ) : (
        <T3>Select a factor above to view more details and continue</T3>
      )}

    </Container>
  );
};

export default BenchmarkDiagnostic;

// FactorCard Component
const FactorCard = ({ factor, onClick, selected, description, minScore }) => {
  return (
    <Card onClick={onClick} selected={selected}>
      <CardTitle>{factor.name}</CardTitle>

      <Score>
        {factor.value}%
      </Score>

      <Bar>
        <BarFill width={factor.value} color={'#2D70E2'} />
      </Bar>

      <CardDescription>
        {description}
      </CardDescription>

    </Card>
  );
};

// SelectedAreaComponent
const SelectedAreaComponent = ({ factor, selectFactor }) => {
  const factorContent = checkContent(factor.name);


  return (
    <SelectedArea>
      <SelectedTitle>Selected Factor: {factor.name}</SelectedTitle>
      <SelectedDescription>{factorContent?.description}</SelectedDescription>

      <T2>
        Score Overview
        <CurrentScore>{factor.value}%</CurrentScore>  
      </T2>
      <ScoreOverview>
        
      
        <ProgressBar>
          <ProgressFill width={factor.value} />
        </ProgressBar>

        <OverviewDescription>
          This score indicates your organization's current performance in <strong>{factor.name}</strong>.
        </OverviewDescription>
      </ScoreOverview>

      <T2>Key Impact Areas:</T2>
      {factorContent.keyImpactAreas.map((area, index) => <li>{area}</li>)}

      {factor.feedbackLength >0 ?(<SmallButton onClick={() => selectFactor(factor)}>
        Continue with {factor.name}
      </SmallButton>):
        <div>Not enough actionable data to generate a plan</div>
      }
    </SelectedArea>
  );
};


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


// Styled Components
const Container = styled.div`
  padding: 20px;
  font-family: 'Raleway', sans-serif;
  width: 100%;
   opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`;

const BackButton = styled.div`
  color: #2D70E2;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
`;

const CardArea = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;  // Ensure cards wrap correctly
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const PageInfo = styled.span`
  margin: 0 10px;
  margin-top: 5px;
  font-size: 14px;
`;

const Card = styled.div`
  padding: 20px;
  border: ${(props) => (props.selected ? '2px solid #2A3039' : '1px solid #DFDFDF')};
  font-family: 'Raleway', sans-serif;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  min-width: 200px; 
  max-width: 300px;

  &:hover {
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
`;

const CardTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #2A3039;
  margin-bottom: 5px;
  line-height: 1.2;
`;

const CardDescription = styled.div`
  font-size: 12px;
  color: #666D79;
`;

const Score = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Bar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #DFDFDF;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const BarFill = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: ${(props) => props.color};
`;

const SelectedArea = styled.div`
  background-color: #F8FAFF;
  padding: 20px;
  border-radius: 8px;
  color: #2A3039;
  margin-top: 20px;
`;

const SelectedTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const SelectedDescription = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
`;

const T2 = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:10px;
`;

const ScoreOverview = styled.div`
  margin-bottom: 20px;
`;

const CurrentScore = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const OverviewDescription = styled.div`
  font-size: 14px;
  margin-top: 10px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #DFDFDF;
  border-radius: 10px;
  overflow: hidden;
`;

const ProgressFill = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  background-color: #2D70E2;
`;

const SmallButton = styled.div`
  padding: 6px 12px;
  background-color: #2D70E2;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  transition: background-color 0.3s;
  display: inline-block;
  margin-top: 20px;
  border: none;

  &:hover {
    background-color: #1c4db8;
  }
`;

const T3 = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #2A3039;
  margin-top: 40px;
  width: 100%;
  text-align: center;
`;
