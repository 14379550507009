import React, { useEffect, useState } from 'react';
import styled,{keyframes,createGlobalStyle} from 'styled-components';
import { Icon } from 'semantic-ui-react';
import Chart from 'react-apexcharts';

const ROIDiagnosticStep2 = ({
  selectedOutcomeID,
  selectedOutcomeName,
  roi_data,
  onFactorSelected,
  goBack,
}) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Prepare chart data from roi_data
    const formattedChartData = roi_data.slice(0,3).map((item) => ({
      x: item.name,
      y: parseFloat((item.impact * 100).toFixed(1)), // Impact as a percentage
      score: parseFloat((item.score * 10).toFixed(1)), // Score as a percentage for tooltip
    }));

    setChartData(formattedChartData);
  }, [roi_data]);

  // Event handler for bar chart click
  const handleDataPointSelection = (event, chartContext, config) => {
    const { dataPointIndex } = config;
    const selectedFactor = roi_data[dataPointIndex];
    if (selectedFactor) {
      onFactorSelected(selectedFactor);
    }
  };

  // Chart options
  const options = {
    chart: {
      height: 400,
      type: 'bar',
      fontFamily: 'Raleway, sans-serif',
      toolbar: { show: false },
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    colors: ['#2D70E2'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
      },
    },
    xaxis: {
      type: 'category',
      title: { text: 'Culture Factors' },
      labels: {
        rotate: -45,
        formatter: (val) => (val.length > 30 ? `${val.substring(0, 27)}...` : val),
      },
    },
    yaxis: {
      title: { text: 'Predicted Improvement (%)' },
    },
    dataLabels: { enabled: false },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const impact = series[seriesIndex][dataPointIndex];
        const score = chartData[dataPointIndex]?.score || 0;
        const name = chartData[dataPointIndex]?.x || '';
        
        // Use styled component within tooltip for custom design
        return `
          <div class="custom-tooltip">
            <div class="tooltip-header">How will ${name} improve ${selectedOutcomeName}?</div>
            <div class="tooltip-content">
              <span><strong>Predicted improvement:</strong> ${impact}%</span>
              <span><strong>${name} current score:</strong> ${score}%</span>
            </div>
          </div>
        `;
      },
    },
    title: {
      text: `Impact on ${selectedOutcomeName} by Culture Factor`,
      align: 'center',
    },
  };

  const series = [
    {
      name: 'Impact',
      data: chartData,
    },
  ];

  return (
    <Container>
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <Title>
        Select a culture factor based on the optimal ROI for{' '}
        <Label>{selectedOutcomeName}</Label>
      </Title>
      <Description>
        These factors are predicted to have the highest impact on {selectedOutcomeName} based on our predictive model of your organization.
        Choosing a factor with high ROI is most likely to lead to a larger impact on {selectedOutcomeName} for a given amount of effort.
      </Description>
      <Chart options={options} series={series} type="bar" height={400} />
      <GlobalStyle />

      <style jsx global>{`
        .custom-tooltip {
          background-color: white;
          color: #333;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 8px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
          font-family: Raleway, sans-serif;
          font-size: 14px;
          text-align: left;
        }

        .tooltip-header {
          font-weight: bold;
          color: #2D70E2;
          margin-bottom: 5px;
        }

        .tooltip-content span {
          display: block;
          margin-bottom: 2px;
        }
      `}</style>
    </Container>
  );
};

export default ROIDiagnosticStep2;


const FactorCard = ({title,impact,outcome,score,onClick}) =>{

    return( <Card onClick={()=>onClick()}>
        <CardTitle>{title}</CardTitle>
        <CardSub>Current factor score:{score}</CardSub>
        <CardDescription>Based on the predictive model, this factor has a <strong>predicted impact of {impact}</strong> on {outcome} for a 10% increase in score.</CardDescription>
    </Card>)
}


const GlobalStyle = createGlobalStyle`
  .apexcharts-bar-area {
    cursor: pointer;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;



const Container = styled.div`
  padding: 20px;
  opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.div`
    margin-bottom:30px;
`


const Label = styled.span`
    color:#2D70E2;
    font-weight:bold;
`
const Card = styled.div`
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 20px;
    font-family: Raleway, sans-serif;
    cursor: pointer;
    width:100%;
    margin-bottom:10px;
    position:relative;

    &:hover {
        background-color: #f9f9f9;
    }
`

const CardTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`

const CardDescription = styled.div`
    font-size: 14px;
    margin-bottom: 10px;

`   

const CardSub = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
    color:grey;
`

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
`