import React from "react";
import styled from "styled-components";

const Tabs = (props) => {
  const { onSelect, selected, options, isOtp, showTabs } = props;
  const tabOptions =
    isOtp && !showTabs ? ["Profile", "Category Options"] : options;
  return (
    <Container>
      {tabOptions.map((item, i) => {
        return (
          <Tab key={i} selected={selected === i} onClick={() => onSelect(i)}>
            <TabText>{item}</TabText>
          </Tab>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1px;
  padding-left: 58px;
`;

const Tab = styled.div`
  margin-right: 50px;
  border-bottom: ${(props) => (props.selected ? "2px solid blue" : "")};
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  flex-direction: row;
  color: #9e9fa1;
`;

const TabText = styled.div`
  margin-bottom: 15px;
`;

export default Tabs;
