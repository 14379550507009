import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";

const SelectItem = ({ title, item, selected, setSelected }) => {
  return (
    <Container selected={selected} onClick={() => setSelected()}>
      <Title>{title}</Title>

      <ItemContainer>{item}</ItemContainer>

      {selected && (
        <IconContainer>
          <Icon name="check" />
        </IconContainer>
      )}
    </Container>
  );
};

export default SelectItem;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  cursor: pointer;
  margin-right: 20px;
  font-family: "Raleway";
  margin-top: 20px;
  border: 1px solid #2a3039;
  padding: 20px;
  position: relative;

  border: ${(props) => (props.selected ? "2px solid #27CDA7" : "")};
  background-color: ${(props) => (props.selected ? "#EAFBF7" : "")};

  &:hover {
    background-color: #eafbf7;
  }
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #2a3039;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const ItemContainer = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-top: 5px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  color: #27cda7;
  font-size: 20px;
`;
