import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Checkbox } from 'semantic-ui-react';

const ReportSelect = ({next}) =>{

    const [option1,setOption1] = useState(false)
    const [option2,setOption2] = useState(false)

    useEffect(()=>{
        if(option1){
            setOption2(false)
        }
    },[option1])

    useEffect(()=>{
        if(option2){
            setOption1(false)
        }
    },[option2])

    return (
        <Container>
            <T1>How would you like the recommendation to be built?</T1>
            <Description>
               Please select one option to further define the target for your report.
            </Description>

            <SelectArea>
            <Option 
                option={option1}
                setOption={setOption1}
                title="Use the data to drive the recommendation" 
                description={"We will base the recommendation off of the lowest scoring factor for the selected targets."}/>
            <Option 
                option={option2}
                setOption={setOption2}
                title="Provide information manually" 
                description={"Manually enter a theme to base the recommendation on"}/>
            </SelectArea>       

            <ButtonContainer>
                   <Button onClick={()=>next()}>Build recommendation</Button>
               </ButtonContainer>
        </Container>
    )

}

export default ReportSelect

const Option = ({title,description,option,setOption}) =>{

    return (
        <OptionContainer active={option}>
            <Checkbox 
            checked={option}
            onChange={()=>setOption(!option)}
            style={{marginRight:10}}/> 
            <div>
                <Title>
                    {title}
                </Title>
                <D2>
                    {description}
                </D2>
            </div>
           
           
        </OptionContainer>
    )


}


const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`

`

const T1 = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:5px;
    font-family:'Raleway';
    color:#2A3039;
    line-height:1.5;
    margin-top:20px;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    letter-spacing:-0.35px;
`

const Title = styled.div`
    font-size:16px;
    font-weight:bold;
`

const Description = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 0.5s;
`

const D2 = styled.div`
    font-weight:400;
`

const SelectArea = styled.div`
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    margin-top:20px;
    width:100%;
    
`

const OptionContainer = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    font-family:'Raleway';
    font-size:14px;
    font-weight:600;
    border:1px solid #E0E0E0;
    border-radius:5px;
    padding:10px;
    margin-bottom:5px;
    margin-right:5px;

    background-color:${({active})=>active?'#D5E2F9':'#fff'};
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;
`


const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`

const Button = styled.div`
    color:#2D70E2;
    border:1px solid #2D70E2;
    font-size:16px;
    padding:10px 20px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`