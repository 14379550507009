import styled, { keyframes } from "styled-components";
import React from "react";

import ToolBox from './ToolBox'
import Logo from  'assets/images/creative-process.png'
import { Transition } from 'semantic-ui-react'


const OnBoarding = () =>{

	const [visible,setVisible] = React.useState(false)

	React.useEffect(()=>{
		setTimeout(function(){ setVisible(true) }, 10);
	})


	return (
		<Container>
			<TopBanner>
				<Left>
					<SubTitle>Welcome, Michael!</SubTitle>
					<Title>Your Innerlogic ToolBox</Title>
					<Description>Michael, we're excited to have you join the platform designed to help you and your team thrive and work better together</Description>
				</Left>
				<Right>
				 <Transition.Group animation={'swing right'} duration={500}>
					{visible && (<Image src={Logo} />)}
				</Transition.Group>
				</Right>
			</TopBanner>


		{/*<Row>
			<Block>
				<Text>Getting Started</Text>
			</Block>
			<Block2>
				<BlockTitle>Add a profile picture</BlockTitle>
			</Block2>
		</Row>*/}

		<ToolBox />
		</Container>
		)
}


export default OnBoarding


const Container = styled.div`
	width:100%;
`

const TopBanner = styled.div`
	width:100%;
	height:350px;
	display:flex;
	justify-content:center;
	flex-direction:row;
	font-family: 'Red Hat Display', sans-serif;
	position:relative;
	background-color:rgba(0, 176, 240,0.1);

`

const Left = styled.div`
	width:50%;
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding:40px;
`

const Title = styled.div`
	font-size:44px;
	font-weight:bold;
`

const Description = styled.div`
	margin-top:30px;
	max-width:800px;
	font-size:18px;
	line-height:1.3;
`

const Row = styled.div`
	display:flex;
	margin-top:30px;
`

const Block = styled.div`
	background-color:#00B0F0;
	height:300px;
	width:200px;
	display:flex;
	align-items:center;
	justify-content:center;
`

const Text = styled.div`
	color:white;
	font-size:40px;
	line-height:1;
	text-align:center;

`

const Block2 = styled.div`
	height:300px;
	width:200px;

`

const BlockTitle = styled.div`
	font-size:20px;
	font-weight:bold;
`

const SubTitle = styled.div`
	color:#545454;
	margin-bottom:20px;
	font-size:16px;
`

const Image = styled.img`
	height:200px;
`

const Right = styled.div`
	width:50%;
	position:relative;
	display:flex;
	align-items:center;
	justify-content:center;
`