import React, { useEffect } from "react";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import PaymentMethod from "./PaymentMethod";
import { StripeKey } from "_config";
import "./payment.css";
import Success from "./Success";
import Trial from "./Trial";

import SubscriptionBox from "./SubscriptionBox"
import OrderBox from "./OrderBox"

import Price from "./Price";
import NewPrice from "./NewPrice";
import Checkout from "./Checkout";
import {
  PULL_ORGANIZATION,
  SG_GET_PRICES, SG_GET_SUBSCRIPTIONS,
  SG_NEXT_INVOICE,
  SG_GET_STRIPE_PAYMENT_METHODS,
  SG_DELETE_PAYMENT_METHOD,
  SG_GET_PAYMENT_METHODS,
  SG_GET_STRIPE_TAX_RATES,
  SG_ADD_SUBSCRIPTION,
  SG_UPDATE_SUBSCRIPTION,
  SG_CANCEL_SUBSCRIPTION,
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  Icon, Button, Grid, Header, 
  Segment, TransitionablePortal
} from "semantic-ui-react";


import PaymentMethods from "./PaymentMethods"
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const promise = loadStripe(StripeKey);

function DisplayInvoice({ invoiceInfo }) {
  if (invoiceInfo) {
    return <p>{JSON.stringify(invoiceInfo)}</p>
  } else {
    return <p>Nothing Found</p>
  }

}

export default function Payment() {
  const dispatch = useDispatch();
  const organization_id = useSelector((state) => Number(state.auth.organization_id));
  const employeeId = useSelector((state) => Number(state.auth.employee_id));
  const [organization, setOrganization] = React.useState("");
  const [employee, setEmployee] = React.useState("");
  const [prices, setPrices] = React.useState([]);
  const [priceId, setPriceId] = React.useState(false);
  const [subscription, setSubscription] = React.useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = React.useState(false)
  const [stripeTaxRates, setStripeTaxRates] = React.useState([]);
  const [showNextInvoice, setShowNextInvoice] = React.useState(false)

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(false)

  React.useEffect(() => {
    // if the response is ok 200
    if (organization_id) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organization_id },
      });
    }
  }, [organization_id, dispatch, showNextInvoice]);

  React.useEffect(() => {
        dispatch({
      type: SG_GET_PRICES,
    });

    dispatch({
      type: SG_GET_STRIPE_TAX_RATES
    })
    // /
    dispatch({
      type: SG_GET_PAYMENT_METHODS,
    });

    dispatch({
      type: SG_GET_SUBSCRIPTIONS,
    });
  }, [dispatch]);

  const {
    get_organization,
    get_employees,
    get_prices,
    get_next_invoice,
    get_payment_methods,
    get_stripe_payment_methods,
    get_stripe_tax_rates,
    get_auth, get_subscription
  } = useSelector(
    (state) => ({
      get_organization: state.organizations,
      get_employees: state.employees,
      get_subscription: state.subscription,
      get_next_invoice: state.subscription.invoice.response,
      get_stripe_tax_rates: state.subscription.taxRates,
      get_payment_methods: state.payments.payment_methods,
      get_stripe_payment_methods: state.subscription.payment_methods.response,
      get_prices: state.price,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_employees) {
      setEmployee(get_employees[employeeId])
    }
  }, [get_employees, employeeId]);

  React.useEffect(() => {
    if (get_prices?.price) {
      setPrices(get_prices.price);
    }
  }, [get_prices]);

  React.useEffect(() => {
    if (get_subscription?.subscriptions) {
      setSubscription(get_subscription?.subscriptions[0]);
      if (get_subscription?.subscriptions[0]?.payment_method)
      {
        setSelectedPaymentMethod(get_payment_methods.find(({id}) => id === get_subscription?.subscriptions[0]?.payment_method))
      }
      if (get_subscription?.subscriptions[0]?.price)
      {
        setSubscriptionSelected(get_prices?.price.find(({id}) => id === get_subscription?.subscriptions[0]?.price))
      }
      
      // setSubscriptionSelected(get_subscription.subscriptions[0]?.items?.plan)
    }
  }, [get_subscription, get_payment_methods, get_prices]);

  React.useEffect(() => {
    if (get_organization) {
      setOrganization(get_organization[organization_id]);
    }
  }, [get_organization, organization_id]);

  React.useEffect(() => {
    if (get_stripe_tax_rates) {
      setStripeTaxRates(get_stripe_tax_rates);
    }
  }, [get_stripe_tax_rates]);


  // // console.log(get_organization[organization_id]);
  // // console.log(organization_id)
  // console.log(employee)
  // console.log(new Date(subscription[0]?.current_period_end))
  // {format(
  //   new Date(subscription[0].current_period_end.toLocaleString()),
  //   "EEEE, MMM do h:mm a"
  // )}
  useEffect(() => {
    if (showNextInvoice) {
      dispatch({
        type: SG_NEXT_INVOICE,
      });
    }
  }, [dispatch, showNextInvoice])

  function FetchStripePayment(payment_method) {
    dispatch({
      type: SG_GET_STRIPE_PAYMENT_METHODS,
      payload: { employee: employeeId }
      // payload: { payment_method: payment_method }
    });
  }

  function DeletePaymentMethod(id) {
    // console.log(id)
    dispatch({
      type: SG_DELETE_PAYMENT_METHOD,
      payload: { id: id }
    });
  }

  function CreateSubscription(data) {
		console.log(data)
		dispatch({
		  type: SG_ADD_SUBSCRIPTION,
		  payload: { ...data }
		});
	}

  function UpdateSubscription(data) {
		console.log(data)
		dispatch({
		  type: SG_UPDATE_SUBSCRIPTION,
		  payload: { ...data }
		});
	}

  function CancelSubscription(id) {
    // console.log(id)
    dispatch({
      type: SG_CANCEL_SUBSCRIPTION,
      payload: { id: id }
    });
  }

  // console.log(get_prices)
  console.log(subscription);
  return (
    <Container>
      <Grid stackable>

        <Grid.Row>
          <Grid.Column floated='left' width={10}>
            <Checkout
              organizationId={organization_id}
              organization={organization} />

            <PaymentMethods
              paymentData={get_payment_methods}
              defaultPayment={subscription?.default_payment_method}
              FetchStripePayment={FetchStripePayment}
              DeletePaymentMethod={DeletePaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              employee={employee}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />

            <SubContainer>
              <Header>Add Credit Card</Header>
              <Elements stripe={promise}>
                <PaymentMethod employeeId={employeeId} />
              </Elements>
            </SubContainer>


            <NewPrice
              prices={prices}
              selectedPaymentMethod={selectedPaymentMethod}
              subscription={subscription}
              organization={organization}
              priceId={priceId}
              setPriceId={setPriceId}
              subscriptionSelected={subscriptionSelected}
              setSubscriptionSelected={setSubscriptionSelected}
            />

            {subscription?.id &&
              <SubscriptionBox
                subscription={subscription}
                CancelSubscription={CancelSubscription}
              />
            }
              <OrderBox 
                organization={organization}
                subscription={subscription}
                subscriptionSelected={subscriptionSelected}
                selectedPaymentMethod={selectedPaymentMethod}
                stripeTaxRates={stripeTaxRates}
                employee={employee}
                UpdateSubscription={UpdateSubscription}
                CreateSubscription={CreateSubscription}
              />
          </Grid.Column>

          <Grid.Column floated='right' width={5}>
            {/* <OrderBox  organization={organization}
            subscription={subscription}
            subscriptionSelected={subscriptionSelected}
                selectedPaymentMethod={selectedPaymentMethod}
                selectedPlan={selectedPlan}
                stripeTaxRates={stripeTaxRates}
                /> */}
          </Grid.Column>

        </Grid.Row>

      </Grid>

      <p>
        Current Period Start Date: {(subscription?.current_period_start)}
      </p>

      <p>
        {/* {format(
    new Date(subscription[0]?.current_period_end.toLocaleString()),
    "EEEE, MMM do h:mm a"
  )} */}
Next Renewal Date: {(subscription?.current_period_end)}
      </p>
      <p>
        Licenses Billed:  {(subscription?.quantity)} / current users: {organization?.no_of_licenses}
      </p>
      <p>
        Status:  {(subscription?.status)}
      </p>
      <p>
        Cancel at End of Period: {subscription?.cancel_at_period_end ? "Yes" : "No"}

      </p>

      <Segment>
        <div>
          <Button
            content={showNextInvoice ? 'Hide Invoice' : 'Next Invoice'}
            negative={showNextInvoice}
            positive={!showNextInvoice}
            onClick={() => FetchStripePayment(!showNextInvoice)}
          />
          {/* <Button
            icon={showNextInvoice ? 'minus' : 'add'}
            negative={showNextInvoice}
            positive={!showNextInvoice}
            onClick={() => setShowNextInvoice(!showNextInvoice)}
            /> */}

          <TransitionablePortal
            onClose={() => setShowNextInvoice(!showNextInvoice)}
            open={showNextInvoice}>
            <Segment
              style={{
                left: '20%',
                position: 'fixed',
                top: '20%',
                zIndex: 1000,
                overflow: "auto",
              }}
            >
              <Header>Your Next Invoice</Header>
              <DisplayInvoice invoiceInfo={get_next_invoice} />
            </Segment>
          </TransitionablePortal>
        </div>
      </Segment>




    </Container>
  );

}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  margin-bottom: 70px;
  max-width: 1200px;
  // justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 100px;
  }
`;

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  // margin-left: calc((100% - 500px) / 2);
  border-radius: 8px;
  box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
  padding: 36px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 36px 20px 36px 20px;
  }
`;
