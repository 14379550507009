import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

import "../Team/team.css";
import styled, { keyframes } from "styled-components";

import { useForm, Controller } from "react-hook-form";
import { Message } from "semantic-ui-react";
import Selection from '../Team/Selection'
import CreateEdit from '../Team/CreateEdit'
import Invite from '../Team/Invite'
import UserList from '../Team/userList'

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  UPDATE_EMPLOYEE,
  LOAD_EMPLOYEES,
} from "constants/actions";

import Check from '../../../assets/images/fill-22@3x.png'

import {
  SubText,
  Hide,
  InvitedContainer,
  Button
} from "../styled-list";

const TeamContainer = styled.div`
	border-radius: 8px;
	flex: 0 0 auto;
  box-shadow: ${props=>props.type? '0 10px 20px 0 rgba(193, 193, 193, 0.5)' : 'none'};
  border: ${props=>props.type? 'solid 1px #e9e9e9' : 'none'};
  background-color: ${props=>props.selected? '#3e0095' : 'white'};
  height:${props=>props.type? '100%' : '90%'};
  width:${props=>props.type? 'calc(33% - 17px)' : '50%'};
   padding: 60px 25px 60px 24px;
   margin-right:25px;
   box-sizing:border-box;
   color:${props=>props.selected? 'white' : 'black'};
   position:relative;
   opacity:${props=>props.faded? 0.2 : 1};
   z-index:${props=>props.faded? -1 : 2};
`
const IMG = styled.img`
	position:absolute;
	top:20px;
	right:20px;
	height:46px;
	width:46px;
`

export const Text = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: ${props=>props.selected? '#4a4a4a' : 'white'};
  font-family: 'Red Hat Display', sans-serif;

`


const TeamCard = (props) => {
const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [leaderSent, setLeaderSent] = React.useState(false);
  const [team, setTeam] = React.useState(false);
  const [invite,setInvite] = React.useState(false)

  const [invited, setInvited] = React.useState([]);


  React.useEffect(() => {
  	if(team?.id){
  		dispatch({
      type: LOAD_EMPLOYEES,
      payload: {params: team? `&team=${team.id}` : null}
    });
  	}
   
  }, [dispatch, team]);

  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_employees?.employees) {
      setInvited(get_employees.employees);      
    }
  }, [get_employees]);

  const save = () =>{
  	if(!props.created.includes(props.i)){
  		props.setCreated(props.i)
  	}
  }

  const toggleInvite = () =>{
  	if(props.selected){
  	 setInvite(!invite)
  	}
  }


  return (
    <TeamContainer type={props.type} selected={!props.selected && team} faded={!props.selected && !team}>
    	{!props.selected && team ? <IMG src={Check} /> :""}
     	<CreateEdit visible={visible} setVisible={(val)=>setVisible(val)} setTeam={(id)=>setTeam(id)} team={team} department={props.department} i={props.i}/>

      <Hide visible={visible}>
        <SubText>{leaderSent ? "Team Leader" : "Add Team Leader"}</SubText>
        {props.selected ?<Invite role=" leader" role_id={2} team_id={team?team.id:false} send={()=>setLeaderSent(true)} department={team?team.department:false}/> :""}
        <UserList invited={invited.filter(i=>i.role==2&&i.team==team.id)}/>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <Selection
            color={"#6a00ff"}
            selected={
              invite ? "linear-gradient(260deg, #6a00ff, #6a00ff)" : false
            }
            onClick={() => toggleInvite()}
          />
          <Text selected={props.selected}>Have leader invite team members?</Text>
        </div>

        {!invite ? <SubText>Add Team Member(s)</SubText> :""}
       {props.selected && !invite?
       	<Invite role=" member" role_id={1} team_id={team?team.id:false} department={team?team.department:false}/>
       	:""}
        <InvitedContainer>
         
            <UserList invited={invited.filter(i=>i.role==1&&i.team==team.id)} />
        </InvitedContainer>
        {props.selected?<Button onClick={()=>save()}>Finish</Button>:""}
      </Hide>
    </TeamContainer>
  );
};


export default TeamCard