import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginTop: 20,
  },
  TH: {
    fontSize: 10,
    textTransform: "uppercase",
    color: "white",
    fontWeight: "bold",
    paddingVertical: 3,
    borderTop: "2px solid black",
    borderBottom: "2px solid black",
  },
  H: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderBottom: "1px solid black",
  },
  H1: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  TD: {
    fontSize: 10,
    padding: 4,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
    marginBottom: 30,
  },
  text: {
    lineHeight: 1.4,
  },
});

const Table = ({
  data,
  headerColor,
  wrapRow = true,
  hideReverseText = true,
  pillarColors,
  isFrench,
}) => {
  const lang = isFrench ? "fr" : "en";

  const getRowValue = (row) => {
    if (Number(row) === -1) {
      return "--";
    } else {
      return row;
    }
  };

  return (
    <View>
      <View
        style={[styles.page, { marginBottom: (hideReverseText && 30) || 10 }]}
      >
        <View style={styles.H1}>
          {data.rows.map((item, i) => (
            <View
              style={[
                { width: item.width, backgroundColor: headerColor },
                styles.TH,
                i != 0
                  ? {
                      display: "flex",
                      alignItems: "center",
                      borderLeft: 0.5,
                      borderLeftColor: "white",
                    }
                  : { paddingLeft: 3 },
              ]}
            >
              <Text>{item.title}</Text>
            </View>
          ))}
        </View>
        <View style={styles.body}>
          {data.data.map((item, x) => {
            return (
              <View style={[styles.H]} wrap={false}>
                {item.map((row, i) => (
                  <View
                    style={[
                      { width: data.rows[i].width },
                      styles.TD,
                      i !== 0
                        ? {
                            borderLeft: "1px solid black",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }
                        : "",
                    ]}
                  >
                    <Text
                      style={[
                        i === 1 ? { fontWeight: "bold" } : "",
                        i === 1 && pillarColors?.length > 0
                          ? { color: pillarColors[x] }
                          : "",
                        styles.text,
                      ]}
                    >
                      {getRowValue(row)}
                    </Text>
                  </View>
                ))}
              </View>
            );
          })}
        </View>
      </View>
      {!hideReverseText && (
        <Text style={styles.tableSubText}>
          {getTranslatedText("reverseTextMsg", lang, translations)}
        </Text>
      )}
    </View>
  );
};

export default Table;
