import React, { useState,useRef } from 'react';
import styled from 'styled-components';
import ImprovementModal from './ImprovementModal';
import { Dimmer, Loader } from 'semantic-ui-react'
import make_call from './Request'

const Container = styled.div`
  position: relative;
  // You may need to adjust these to match your design
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  height:400px;
  padding-bottom: 50px; // Adjust this based on the toolbar height
  box-sizing: border-box;
  padding:20px;
  font-family:"Raleway";
  font-size:14px;
  line-height:20px;
  // Other styling as needed
`;

const Toolbar = styled.div`
  flex-wrap:wrap;
  align-items: center;
  display:flex;
  padding:10px;
  // Additional styling for the toolbar
`;

function TextAreaWithToolbar({setOpen}) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [prompt,setPrompt] = useState("");


    const make_gpt_call = () =>{
        setLoading(true)
        async function someFunction() {
            const _message = await make_call('draft');

            setMessage(_message);
            setLoading(false)
          }

          someFunction()
    }

    const make_gpt_call2 = () =>{

        setLoading(true)
        async function someFunction() {
            const _message = await make_call('improvement',prompt,message);

            setMessage(_message);
            setLoading(false)
            setPrompt("")
          }

          someFunction()
    }



  return (
    <Container>
        <Dimmer active={loading}>
            <Loader />
         </Dimmer>
      <StyledTextArea
        // Other textarea properties
        placeholder="Build your plan here..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />

      <ToolTitle>
        Your Planning Tools
      </ToolTitle>
      <Toolbar>
            <Button onClick={()=>make_gpt_call()}>
                <Title>Generate a draft</Title>
                <Description>
                    Our AI will use your data, and the resources it has available to build you a first draft.
                </Description>

            </Button>

            <ImprovementModal
                prompt={prompt}
                setPrompt={setPrompt}
                make_gpt_call2={make_gpt_call2}
                message={message}
                trigger={ 
                    (<Button>
                    <Title>Improve your plan</Title>
                    <Description>
                        Give our AI more context or instructions and we'll re-write this section for you.
                    </Description>
    
                  
    
                </Button>)}/>

          

            <Button>
                <Title>Access resources</Title>
                <Description>
                    We'll access our library of resources to help you build this section.
                </Description>

            </Button>

            <Button onClick={()=>setOpen()}>
                <Title>Coach me through this</Title>
                <Description>
                    Not sure where to start? We can guide you through this section.
                </Description>

            </Button>

            <Button>
                <Title>Crowdsource your solution</Title>
                <Description>
                    Check out what your colleagues have had success with.
                </Description>

            </Button>

       
      </Toolbar>
    </Container>
  );
}

export default TextAreaWithToolbar;


const Button = styled.div`
    padding:15px;
    border:1px solid #EDEDED;
    font-family:"Raleway";
    border-radius:5px;
    width:300px;
    margin-right:5px;
    margin-bottom:5px;
    cursor:pointer;

    &:hover{
        background-color:#EDEDED;
    
    }

`

const Title = styled.div`
    font-size:14px;
    font-weight:bold;
    color#2A3039;
`

const Description = styled.div`
    font-size:12px;
    color:#2A3039;
    margin-top:5px;
`

const ToolTitle = styled.div`
    font-size:20px;
    font-weight:bold;
    font-family:"Raleway";
    margin-top:20px;
`