import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_REPORT_TEMPLATE_PENDING,
  GET_REPORT_TEMPLATE,
  SG_GET_REPORT_TEMPLATE,
  ALL_ERRORS,
} from "constants/actions";

function* loadmethods(action) {
  yield put({ type: GET_REPORT_TEMPLATE_PENDING });

  try {
    const json = yield call(api.GET_REPORT_TEMPLATE, action.payload);
    yield put({ type: GET_REPORT_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodLoad() {
  yield takeLatest(SG_GET_REPORT_TEMPLATE, loadmethods);
}

export default function* index() {
  yield all([methodLoad()]);
}
