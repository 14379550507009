import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Popup, Icon } from "semantic-ui-react";
import { format } from "date-fns";
import { caretDown, caretUp } from "../../../reports/components/Icons";
import AccountSetupActivity from "./components/AccountSetupActivity";

const statusColors = {
  Unopened: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
  Error: {
    container: "#FEF3F4",
    text: "#F15062",
  },
  Completed: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  Pending: {
    container: "#F3F7FF",
    text: "#2D70E2",
  },
  InProgress: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
};

const activities = [
  {
    icon: "eye",
    text: "Email reminder viewed",
    event: "Email reminder viewed",
  },
  {
    icon: "mail outline",
    text: "Email reminder sent",
    event: "Email reminder sent",
  },
  {
    icon: "pin",
    text: "Set action plan milestones",
    event: "Set action plan milestones",
  },
  {
    icon: "eye",
    text: "Viewed action plan draft",
    event: "Viewed action plan draft",
  },
  {
    icon: "flag checkered",
    text: "Completed data story",
    event: "Completed",
  },
  {
    icon: "eye",
    text: "Viewed data story",
    event: "Viewed",
  },
  {
    icon: "check",
    text: "Created account.",
    event: "Created account",
  },
  {
    event: "Feedback",
    icon: "comment",
    text: "Feedback given for data story",
  },
  {
    event: "Comprehension",
    icon: "question",
    text: "Comprehension feedback given for data story",
  },
];

const ACCOUNT_SETUP = [
  {
    icon: "user plus",
    text: "Account created",
    event: "Account created",
    type: "account_created",
  },
  {
    icon: "mail outline",
    text: "Confirmation email sent",
    event: "Confirmation email sent",
    type: "confirmation_email_sent",
  },
  {
    icon: "eye",
    text: "Email validated",
    event: "Email validated",
    type: "email_validated",
  },
  {
    icon: "check",
    text: "Onboarding complete",
    event: "Onboarding complete",
    type: "onboarding_complete",
  },
];

const getTaskName = (type) => {
  switch (type) {
    case "DataStory":
      return "Data Story";
    case "ActionPlan":
      return "Action Plan";
    case "AccountSetup":
      return "Account Setup";
    default:
      return "Unknown";
  }
};

function updatedAt(userPerm, accountDetails) {
  return userPerm.updated_at > accountDetails.updated_at
    ? userPerm.updated_at
    : accountDetails.updated_at;
}

const accountSetupStatus = (userPerm, accountDetails) => {
  let status;
  let lastUpdated = updatedAt(userPerm, accountDetails);
  let history = [];

  if (accountDetails?.enable_login) {
    status = "Email validation pending";
    history.push(ACCOUNT_SETUP[0]);
    history.push(ACCOUNT_SETUP[1]);
  }

  if (accountDetails?.email_status_text === "Validated") {
    status = "Email validated";
    history.push(ACCOUNT_SETUP[2]);
  }

  if (userPerm?.extra_details?.onboarding_complete) {
    status = "Onboarding complete";
    history.push(ACCOUNT_SETUP[3]);
  }

  return {
    type: "Account Setup",
    status,
    lastUpdated,
    history,
  };
};

const groupUserInteractions = (logs) => {
  return logs.map((log) => {
    const { data_story_id, user_interactions } = log;
    const activity = Object.keys(user_interactions).reduce((acc, key) => {
      if (key.includes("_history")) {
        const [type, additional_info] = key.split("_history")[0].split(" - ");
        if (additional_info) {
          acc.push({
            type,
            count: user_interactions[`${type} - ${additional_info}_count`],
            history: user_interactions[key],
            additional_info,
          });
        } else {
          acc.push({
            type,
            count: user_interactions[`${type}_count`],
            history: user_interactions[key],
            additional_info: null,
          });
        }
      }
      return acc;
    }, []);
    return { data_story_id, activity, type: "DataStory" };
  });
};

const mergeInteractionsWithInfo = (groupedInteractions, activitiesInfo) => {
  return groupedInteractions
    .flatMap((interaction) => {
      return interaction.activity.map((activity) => {
        const info = activitiesInfo.find(
          (infoItem) => infoItem.event === activity.type
        );
        return {
          text: info ? info.text : "",
          count: activity.count,
          history: activity.history?.[activity.history?.length - 1],
          icon: info ? info.icon : "",
          additional_info: activity.additional_info,
          type: activity.type,
        };
      });
    })
    .sort((a, b) => new Date(a.history) - new Date(b.history));
};

const getStatus = (type, item, recent) => {
  if (type === "DataStory") {
    if (recent?.type === "Completed") {
      return "Completed";
    } else if (recent?.type && recent?.type !== "Completed") {
      return "InProgress";
    } else {
      return "Pending";
    }
  } else {
    return "Pending";
  }
};

const getMostRecentActivity = (data) => {
  let mostRecentActivity = null;
  let mostRecentDate = null;

  data.activity.forEach((activity) => {
    const history = activity.history;
    if (history.length > 0) {
      const date = new Date(history[history.length - 1]);
      if (!mostRecentDate || date > mostRecentDate) {
        mostRecentDate = date;
        mostRecentActivity = activity;
      }
    }
  });

  return mostRecentActivity;
};

const removeEmptyDataStory = (data) => {
  const filteredStories = data?.filter((recommendation) => {
    if (recommendation?.recommendation?.data_story?.length > 0) {
      recommendation.recommendation.data_story =
        recommendation?.recommendation?.data_story?.filter((dataStory) => {
          return dataStory?.response?.length > 0;
        });
      return recommendation;
    }
  });

  // Remove any recommendation objects that have an empty data_story array
  return filteredStories?.filter((recommendation) => {
    return recommendation.recommendation.data_story.length > 0;
  });
};

const PendingStories = (pendingStories, data) => {
  const [hovered, setHovered] = useState(null);
  // Filter out any pendingStories which have logs already associated.
  const filteredPendingStories = pendingStories?.filter(
    (pendingStory) =>
      !data?.some((log) => log?.data_story_id === pendingStory.id)
  );

  return removeEmptyDataStory(filteredPendingStories)?.map(
    (pendingStory, i) => {
      const updatedAt = pendingStory?.updated_at;
      const formattedDate = format(new Date(updatedAt), "MMM d, yyyy h:mm a");

      return (
        <Row
          key={i}
          onMouseOver={() => setHovered(pendingStory.id)}
          onMouseLeave={() => setHovered(null)}
          rowIsHovered={hovered === pendingStory.id}
        >
          <TD width={25}>Data Story</TD>
          <TD width={25}>
            <Status type="Unopened" />
          </TD>
          <TD width={30}>{formattedDate}</TD>
          <TD width={20}></TD>
        </Row>
      );
    }
  );
};

const Table = ({ data, loading, userData, employee, pendingStories }) => {
  const [hovered, setHovered] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [accountDetails, setAccountDetails] = useState({});

  useEffect(() => {
    if (userData && employee) {
      const detailsHistory = accountSetupStatus(userData, employee);
      setAccountDetails(detailsHistory);
    }
  }, [userData, employee]);

  const toggleRow = (id) => {
    const isRowCurrentlyExpanded = expandedRows.includes(id);
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter((rowId) => rowId !== id)
      : [...expandedRows, id];
    setExpandedRows(newExpandedRows);
  };

  return (
    <Container>
      <Header>
        <TH width={25}>Task</TH>
        <TH width={25}>Status</TH>
        {/* <TH width={25}>Date Assigned</TH> */}
        <TH width={30}>Last Updated</TH>
        <TH width={20}>View Activity</TH>
      </Header>
      {!loading && accountDetails && (
        <AccountSetupActivity
          accountHistory={accountDetails}
          canExpand={expandedRows?.length === 0}
          expandCb={() => {
            setExpandedRows([]);
          }}
        />
      )}
      {!loading &&
        data?.length > 0 &&
        groupUserInteractions(data)?.map((item, i) => {
          const isExpanded = expandedRows.includes(item.data_story_id);
          const mostRecent = getMostRecentActivity(item);
          // Extract the date from the history array
          const recentDate =
            mostRecent?.history?.[mostRecent?.history?.length - 1];

          return (
            <React.Fragment key={item.id}>
              <Row
                onMouseOver={() => {
                  setHovered(i);
                }}
                onMouseOut={() => setHovered(null)}
                rowIsHovered={hovered === i}
              >
                <TD width={25}>{getTaskName(item.type)}</TD>
                <TD width={25}>
                  <Status type={getStatus(item?.type, item, mostRecent)} />
                </TD>
                {/* <TD width={25}>
                            {mappedData.assignedDate}
                            </TD> */}
                {/* Format the date as Jan 2, 2024 4:00PM */}
                <TD width={30}>
                  {format(new Date(recentDate), "MMM d, yyyy h:mm a")}
                  {/* {recentDate} */}
                </TD>
                <TD width={20}>
                  {item?.activity && item?.activity?.length > 0 && (
                    <Popup
                      trigger={
                        <ActionWrapper
                          onClick={() => {
                            toggleRow(item.data_story_id);
                          }}
                        >
                          {isExpanded
                            ? caretUp("#2d70e2")
                            : caretDown("#2d70e2")}
                        </ActionWrapper>
                      }
                      content="Expand"
                      position="top center"
                    />
                  )}
                </TD>
              </Row>
              {isExpanded &&
                item?.activity &&
                mergeInteractionsWithInfo([item], activities)?.map(
                  (activity, index) => {
                    const activityDate = new Date(activity?.history);

                    const formattedDate = format(
                      activityDate,
                      "MMM do, yyyy - hh:mma"
                    );

                    return (
                      <ExpandedRow key={index}>
                        <ExpandedContent>
                          <ActivityItem key={index}>
                            <Icon
                              name={activity.icon}
                              style={{ fontSize: 18, color: "#B3B3B3" }}
                            />
                            <Text>{activity.text}</Text>
                            {/* Show a tooltip if there's additional info, and add an onhover  */}
                            {activity.additional_info ? (
                              <Popup
                                content={`User provided additional Information: ${activity.additional_info}`}
                                trigger={<Icon name="info circle" />}
                              />
                            ) : (
                              ""
                            )}
                            <DateStyle>{formattedDate}</DateStyle>
                            {index + 1 != activities.length ? <Line /> : ""}
                          </ActivityItem>
                        </ExpandedContent>
                      </ExpandedRow>
                    );
                  }
                )}
            </React.Fragment>
          );
        })}
      {/* Unread/pending list */}
      {PendingStories(pendingStories, data)}
      {data?.length === 0 && !accountDetails && (
        <Row>
          <TD width={100} centered>
            No Activity history found for this user.
          </TD>
        </Row>
      )}
    </Container>
  );
};

export default Table;

const MultipleCategory = ({ name }) => {
  return <Border>{name}</Border>;
};

const Status = ({ type }) => {
  const typeCleaned =
    type.toLowerCase() === "queued for processing" ? "Pending" : type;
  return (
    <StatusContainer color={statusColors?.[typeCleaned]?.container}>
      <StatusText color={statusColors?.[typeCleaned]?.text}>
        {typeCleaned}
      </StatusText>
    </StatusContainer>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;

const Border = styled.div``;

const DateStyle = styled.div`
  margin-left: 20px;
  color: #ccc;
  font-size: 12px;
`;

const Line = styled.div`
  height: 40px;
  width: 1px;
  background-color: #ccc;
  position: absolute;
  left: 10px;
  bottom: -46px;
`;

const ActivityItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  font-size: 12px;
  font-family: "Raleway";
  position: relative;
`;

const Text = styled.div`
  margin-left: 20px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const ExpandedRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: -10px;
`;

const ExpandedContent = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #2a3039;
`;

const ExpandedFileTitle = styled.div`
  font-size: 12px;
  width: 82.5%;
  color: #2a3039;
  line-height: 1.5;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;

const ExpandedFileDownload = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 17.5%;
  justify-content: flex-start;
`;
