import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import EmpForm from "./EmpForm";
// import { useNavigate } from "react-router-dom";
import {
  SG_ADD_EMPLOYEE_CATEGORY,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_STRUCTURES,
  SG_FETCH_USER_EMPLOYEE,
  REGISTER_EMPLOYEE,
  SG_VALIDATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  PULL_EMPLOYEE,
} from "constants/actions";

const EditEmployee = () => {
  const dispatch = useDispatch();
  // const history = useNavigate();
  const { id } = useParams();

  const [userEmployee, setUserEmployee] = useState(false);
  const [employee, setEmployee] = useState(false);

  const [categoryUpload, setCategoryUpload] = useState([]);
  const [categoryUpdated, setCategoryUpdated] = useState(false);
  // const [employeeCategory, setEmployeeCategory] = useState([]);
  const [runOnce, setRunOnce] = useState(false);

  const {
    get_auth,
    get_employees,
    get_employee_category,
    get_employee_created,
    get_org_id,
    get_selectedOrg,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_employee_created: state.employees.created,
      get_employee_category: state.employee_category.employee_category,
      get_selectedOrg: state.selectedOrg,
    }),
    shallowEqual
  );

  // const {
  //   get_teams,
  //   get_team_types,
  //   get_employee_roles,
  //   get_error,
  //   get_validated_email,
  // } = useSelector(
  //   (state) => ({
  //     get_teams: state.teams.teams,
  //     get_team_types: state.team_types.team_types,
  //     get_employee_roles: state.employee_roles.employee_roles,
  //     get_error: state.errors,
  //     get_validated_email: state.employees.validateEmail,
  //   }),
  //   shallowEqual
  // );

  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );

  useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch({ type: SG_GET_MOODS });
    if (Number(AmbassadorId) > 0) {
      // console.log("Ambassador Lead teams", AmbassadorId)
      const OrgId = get_selectedOrg?.organization?.id;
      if (OrgId) {
        dispatch({
          type: SG_GET_SURVEY_STRUCTURES,
          payload: `organization=${OrgId}`,
        });
        dispatch({
          type: SG_GET_EMPLOYEE_CATEGORIES,
          payload: `organization=${OrgId}`,
        });
      }
    } else {
      // console.log("Lead teams")
      dispatch({ type: SG_GET_SURVEY_STRUCTURES });
      dispatch({ type: SG_GET_EMPLOYEE_CATEGORIES });
    }
  }, [dispatch, AmbassadorId, get_selectedOrg]);

  useEffect(() => {
    dispatch({
      type: SG_FETCH_USER_EMPLOYEE,
      payload: {
        id: Number(get_auth?.employee_id),
      },
    });
  }, [dispatch, id, get_auth]);

  useEffect(() => {
    if (id) {
      setEmployee(get_employees?.[id]);
    }
    setUserEmployee(get_employees?.userEmp);
  }, [id, get_employees]);

  // console.log(get_employees);

  useEffect(() => {
    // console.log(get_employee_created)
    if (get_employee_created?.id && runOnce) {
      if (
        !get_employee_category.filter(
          (ec) => ec.employee === get_employee_created?.id
        )?.[0]
      ) {
        dispatch({
          type: SG_ADD_EMPLOYEE_CATEGORY,
          payload: {
            category: categoryUpload,
            employee: get_employee_created?.id,
          },
        });
      }
      // setOpen(false)
      setRunOnce(false);
    }
  }, [
    get_employee_created,
    get_employee_category,
    categoryUpload,
    runOnce,
    dispatch,
  ]);

  return (
    <>
      <EmpForm
        employee={employee}
        userEmployee={userEmployee}
        setCategoryUpload={setCategoryUpload}
        setCategoryUpdated={setCategoryUpdated}
      />
    </>
  );
};

export default EditEmployee;

const Container = styled.div`
  padding-left: 58px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  margin-left: 30px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
`;

const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Barlow", sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0px 200px 0px;
    width: 100%;
  }
`;

const FormSection1 = styled.div`
  margin-right: 60px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const FormSection = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
