import { call, put, all, takeEvery, takeLatest, throttle } from "redux-saga/effects";
import * as api from "constants/api";

import {
  SURVEY_STANDARD_PENDING,
    GET_SURVEY_STANDARD,
    SG_GET_SURVEY_STANDARD,
    ADD_SURVEY_STANDARD,
    SG_ADD_SURVEY_STANDARD,
    DELETE_SURVEY_STANDARD,
    SG_DELETE_SURVEY_STANDARD,
    UPDATE_SURVEY_STANDARD,
    SG_UPDATE_SURVEY_STANDARD,
    FETCH_SURVEY_STANDARD,
    SG_FETCH_SURVEY_STANDARD,

    ALL_ERRORS,
} from "constants/actions";


function* loadTemplate(action) {
  try {
    // SURVEY_STANDARD_PENDING
    yield put({ type: SURVEY_STANDARD_PENDING});
    const json = yield call(api.GET_SURVEY_STANDARD, action.payload);
    yield put({ type: GET_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* templateLoad() {
  yield takeLatest(SG_GET_SURVEY_STANDARD, loadTemplate);
}

function* fetchtemplates(action) {
  try {
    yield put({ type: SURVEY_STANDARD_PENDING});
    const json = yield call(api.FETCH_SURVEY_STANDARD, action.payload);
    yield put({ type: FETCH_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* templateFetch() {
  yield takeLatest(SG_FETCH_SURVEY_STANDARD, fetchtemplates);
}

function* updatetemplates(action) {
  try {
    yield put({ type: SURVEY_STANDARD_PENDING});
    const json = yield call(api.EDIT_SURVEY_STANDARD, action.payload);
    yield put({ type: UPDATE_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* templateUpdate() {
  yield takeEvery(SG_UPDATE_SURVEY_STANDARD, updatetemplates);
}

function* addtemplates(action) {
  try {
    yield put({ type: SURVEY_STANDARD_PENDING});
    const json = yield call(api.ADD_SURVEY_STANDARD, action.payload);
    yield put({ type: ADD_SURVEY_STANDARD, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* templateAdd() {
  yield takeEvery(SG_ADD_SURVEY_STANDARD, addtemplates);
}

function* deletetemplates(action) {
  try {
    yield put({ type: SURVEY_STANDARD_PENDING});
    yield call(api.DELETE_SURVEY_STANDARD, action.payload);
    yield put({ type: DELETE_SURVEY_STANDARD, payload: action.payload });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* templateDelete() {
  yield takeEvery(SG_DELETE_SURVEY_STANDARD, deletetemplates);
}


export default function* index() {
  yield all([
    templateLoad(),
    templateFetch(),
    templateUpdate(),
    templateAdd(),
    templateDelete(),
  ]);
}
