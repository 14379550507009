import styled from "styled-components";
import React, {  useState } from "react";
import { Icon } from "semantic-ui-react";


const Insight = ({ risk, n, title, description, link }) => {
	const [score, setScore] = useState(Math.floor(Math.random() * 20));

	const getRisk = () => {
		if (risk > 1.2 || risk < -1.2) {
			return "Very High";
		}
		if (risk > 0.8 || risk < -0.8) {
			return "High";
		}
		if (risk > 0.5 || risk < -0.5) {
			return "Medium";
		}
		if (risk > 0.2 || risk < -0.2) {
			return "Modest";
		}
		return "Low";
	};

	return (
		<Container>
			<Title>{title}</Title>
			<Score>
				<Top>Avg. Score</Top>
				<Row>
					{Math.floor(Math.random() * 70) / 10}
					<IconContainer>
						<Icon name="arrow alternate circle down" />
						{score}%
					</IconContainer>
				</Row>
			</Score>
		</Container>
	);
};

export default Insight;

const Container = styled.div`
	padding: 20px;
	width: calc(33% - 5px);
	font-family: "Barlow", sans-serif;
	margin-top: 10px;
	margin-right: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Title = styled.div`
	font-size: 14px;
	margin-bottom: 20px;
	line-height: 1.2;
	height: 35px;
`;

const Description = styled.div`
	font-weight: 300;
`;

const Risk = styled.div`
	margin-bottom: 5px;
	margin-top: 10px;
`;

const Score = styled.div`
	font-size: 50px;
	font-family: "Barlow Semi Condensed", sans-serif;
	line-height: 1;
	margin-bottom: 20px;
`;

const Top = styled.div`
	font-size: 12px;
	color: grey;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const IconContainer = styled.div`
	font-size: 22px;
	margin-top: 5px;
	margin-left: 10px;
	color: red;
`;

const Link = styled.div`
	margin-top: 20px;
	color: #2d50e2;
	cursor: pointer;
`;
