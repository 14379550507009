import React from "react";

const JourneyScreen = (props) => {
  const { journeyVersion, introComplete, actionsComplete } = props;
  let version = "v1";

  if (journeyVersion) {
    version = journeyVersion;
  }

  let screen = "Introduction";
  //  If the introduction is complete, render the journey screen
  if (introComplete) {
    screen = "JourneyPath";
  }

  // If the actions are complete, render the next steps
  if (actionsComplete) {
    screen = "NextSteps";
  }

  const Screen = require(`./${screen}/${version}`).default;

  return <Screen {...props} />;
};

export default JourneyScreen;
