import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'
import LogoIMG from 'assets/images/logo_full.png'


const questions =[
    {
        question:"What best describes your role?",
        answers:[
            "I manage a team",
            "I'm an individual contributor",
            "I'm an executive"
        ]
    },
    {
        question:"What best describes your level of comfort with data?",
        answers:[
            "I'm a beginner",
            "I'm comfortable",
            "I'm an expert"
        ]
    },
    {
        question:"How important is culture to you and your team?",
        answers:[
            "Not important",
            "Somewhat important",
            "Very important"
        ]
    }
]

const Component3 = ({question,setQuestion}) =>{
    const [selected,setSelected] = React.useState(null)

    React.useEffect(()=>{
       setSelected(null)
    }
    ,[question])

    return (
        <Container>
            <IMG src={LogoIMG}/>
            <T1>Let's get to know you.</T1>
            <Text delay={0.5}>
              Answer some questions for a more tailored experience.
            </Text>

           
            <Card delay={question==0?1:0.2} key={`card-${question}`}>
            <Progress>
                {question+1} of 3 questions
            </Progress>
                <Question>
                    {questions[question].question}
                </Question>
                <T2>Your role has a significant impact on the nature of the actions we recommend.</T2>

                <QuestionArea>
                    {questions[question].answers.map((item,index)=>{
                        return(
                            <Q selected={selected==index} onClick={()=>setSelected(index)}>
                                {item} {selected==index && <Icon name="check" style={{marginTop:-3,color:'white'}}/>}
                            </Q>
                        )
                    })}
                </QuestionArea>
            </Card>
            
        </Container>
    )
}

export default Component3

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn2 = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px) translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
`;

const IMG = styled.img`
    width:120px;
    margin-bottom:20px;
`

const Container = styled.div`
    font-family:'Raleway';
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:40px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`


const Card = styled.div`
    width:100%;
    display:flex;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-right:20px;
    border-radius:10px;
    overflow:hidden;
    margin-top:20px;
    padding:30px;
    flex-direction:column;
    position:relative;

    opacity:0;

    animation: ${fadeIn2} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    color:#2A3039;
`

const T1 = styled.div`
    font-size:28px;
    font-weight:bold;
    margin-bottom:10px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`
const Question = styled.div`

    font-size:18px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:5px;
`
const QuestionArea = styled.div`
    display:flex;
    flex-direction:row;
    margin-top:20px; 
    flex-wrap:wrap;
    width:100%;
`

const Q = styled.div`
    font-size:16px;
    font-weight:400;
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:${props=>props.selected?'white':'#2A3039'};
    padding:10px 20px;
    margin-right:10px;
    margin-bottom:10px;
    border-radius:10px;
    background-color:${props=>props.selected?'#2254AA':'#EBF1FD'};
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    width:48%;

    &:hover{
        background-color:#2254AA;
        color:white;
    }
`
const Progress = styled.div`
    font-size:12px;
    color:#2A3039;
    margin-bottom:5px;

    position:absolute;
    top:30px;
    right:20px;

`