import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  Title2,
  P2,
  SettingsCard,
  View,
  Container,
} from "../styled_list";

import { SG_ADD_SURVEY_COMPLIANCE } from "constants/actions";
import styled, { keyframes } from "styled-components";

const SurveyTypes = [1, 2]; // Culture and Mood Survey type

const StartIntelligence = (props) => {
  const { globalSchedule, setProcessing, setFirstTime,
    OpenPortal } = props;
  const dispatch = useDispatch();


  function startSurveyCompliance() {
    SurveyTypes.map((survey_type) =>
      dispatch({
        type: SG_ADD_SURVEY_COMPLIANCE,
        payload: {
          active: true,
          global_schedule: globalSchedule.id,
          survey_type: survey_type,
          frequency: 1,
          day: globalSchedule.day,
          hour: globalSchedule.hour,
          minute: 0,
          organization: globalSchedule.organization,
        },
      })
    );

    setProcessing(true);
    setFirstTime(true)
    OpenPortal(2)
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          marginTop: 20,
          zIndex: 3,
          justifyContent: "space-between",
        }}
      >
        <SettingsCard style={{ paddingBottom: 100 }}>
          <Container>
            <div>
              <Title2>Activate team intelligence survey </Title2>
              <P2>
                Track and support your team(s) using
                evidence-based team &amp; emotional health metrics.
              </P2>
            </div>
          </Container>

          <div
            style={{
              width: "100%",
              justifyContent: "flex-end",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              position: "absolute",
              bottom: 15,
              right: 20,
            }}
          >
            <View>
              <StyledButton onClick={() => startSurveyCompliance()}>
               Setup Survey
              </StyledButton>
            </View>
          </div>
        </SettingsCard>
      </div>
    </>
  );
};

export default StartIntelligence;


const StyledButton = styled.div`
  padding: 15px;
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
