import React from 'react'
import { Button, Label, Menu, Table,Icon} from 'semantic-ui-react'
import styled, { keyframes } from "styled-components";



const Applicant = () => {
    const [tab,setTab] = React.useState(1)
    return (
        <Container>
            <Profile>
            <Circle>
                MB
            </Circle>
            <div>
            <h2 style={{marginTop:0,marginBottom:5}}>Michael Bawol</h2>
            <Sub>
                Job Application 1
                <Button color={'green'} size='mini' inverted style={{marginLeft:5,marginRight:5}}>
                    Completed
                </Button>
                <Button color={'violet'} size='mini' inverted>
                    Application Stage
                </Button>
            </Sub>
            </div>
            
            </Profile>
           
            <h4>email@email.com</h4>
            <h4>Download resume</h4>


            <Metrics>
                <Metric>
                Retention Score
                <Score>
                77%
                    </Score>
                </Metric>

                <Metric>
                Culture Fit
                <Score>
                77%
                    </Score>
                </Metric>

                <Metric>
                Performance Potential
                <Score>
                High
                    </Score>
                </Metric>

            </Metrics>            

            <SurveyResponses>
                <h3>Survey Responses</h3>
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Header</Table.HeaderCell>
                    <Table.HeaderCell>Header</Table.HeaderCell>
                    <Table.HeaderCell>Header</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>
                    <Label ribbon>First</Label>
                    </Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                    <Table.Cell>Cell</Table.Cell>
                </Table.Row>
                </Table.Body>

            </Table>
            </SurveyResponses>

           
        </Container>
    )
}


export default Applicant


const Container = styled.div`
    margin-top:30px;
    padding-left:58px;
`

const TabContainer = styled.div`
    display:flex;
    width:100%:
`

const Tab = styled.div`
    padding:20px 10px 3px 10px;
    min-width:150px;
    border-bottom:${props=>props.selected?'1px solid blue':'1px solid #ccc'};
    cursor:pointer;
    color:${props=>props.selected?'blue':'#ccc'};
`

const Profile = styled.div`
    display:flex;
    align-items:center;
`

const Circle = styled.div`
    width:60px;
    height:60px;
    border-radius:50%;
    background-color:#ccc;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:30px;
    color:white;
    margin-right:20px;

`

const Sub = styled.div`
    font-size:14px;
    color:#ccc;
    display:flex;
    align-items:center;
`

const Metric = styled.div`
    font-size:12px;
    margin-right:50px;
`

const Score = styled.div`
    font-size:40px;
    margin-top:10px;
    font-weight:600;
`

const Metrics = styled.div`
    display:flex;
    margin-top:20px;
    margin-bottom:20px;
    max-width:800px;
    justify-content:space-between;
`

const SurveyResponses = styled.div`
    margin-top:50px;
`