import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";



const SideMenu = ({selected,setSelected}) => {

    return (
        <Container>
            <Menu>Menu</Menu>
            <Title selected={selected==0} onClick={()=>setSelected(0)}>
                Pending
                <Num>3</Num>
            </Title>

            <Title selected={selected==1} onClick={()=>setSelected(1)}>
                Data Story
            </Title>

            <Title selected={selected==2} onClick={()=>setSelected(2)}>
                Action Plans
            </Title>

            <Title selected={selected==3} onClick={()=>setSelected(3)}>
                Check-ins
            </Title>

            <Logout>
                Logout
            </Logout>
        </Container>
    )


}

export default SideMenu;


const Container = styled.div`
    width:200px;
    position:absolute;
    left:-220px;
    top:50px;
    box-shadow: 0px 8px 20px 0px #3F86FE14;
    padding:8px 12px 8px 12px;
`

const Menu = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left:10px;
    font-family: Raleway;
    color:#666D79;
`

const Title = styled.div`
    font-size: 12px;
    font-weight: 400;
    cursor:pointer;
    font-family: Raleway;
    padding:6px 10px 6px 10px;
    background-color:${props=>props.selected?"#F8FAFF":"none"};
    color:${props=>props.selected?"#2D70E2":"#666D79"};
    border-radius:5px;
    display:flex;
    width:100%;
    justify-content:space-between;
`

const Num = styled.div`
    font-family: Raleway;
`

const Logout = styled.div`
    border-top:1px solid #E0E0E0;
    font-size: 12px;
    font-weight: 400;
    cursor:pointer;
    font-family: Raleway;
    padding:6px 10px 6px 10px;
`