import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";

import {
  StyledInput,
  InviteBox,
  SentContainer,
  Sent,
  LeaderContainer,
  InviteButton,
} from "../styled-list";


import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  REGISTER_EMPLOYEE
} from "constants/actions";

const Invite = (props) =>{
	const apiDispatch = useDispatch();
	const [sent,setSent] = useState(false)
	const [sendMember,setSendMember] = useState(false)
	const [member,setMember] = useState("")
	const org_id = useSelector((state) => state.auth.organization_id)


	const invite = () =>{
			apiDispatch({
		      type: REGISTER_EMPLOYEE,
			   payload:{
			   	email:member,
			    organization: org_id,
		        role: props.role_id,
		        picture: null,
		        department: props.department_id},
			    });		
	}


	const inviteMember = () => {
		invite()
	    setMember("");
	    setSendMember(false)
	    if(props.role_id==1){
	    	setSent(false);
	    }else{
	    	setSent(true);
	    	props.send()
	    }
	    
	    
	  };

	const settingMember = (e) => {
	    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value)) {
	      setSendMember(true);
	      setMember(e.target.value);
	    } else {
	      setMember(e.target.value);
	    }
	  };

	return (
		<div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            position: "relative",
          }}
        >
 	
          <LeaderContainer visible={!sent}>
            <InviteBox>
              <StyledInput
                placeholder={"Type email of Department Lead"}
                value={member}
                onChange={(e) => settingMember(e)}
              />
            </InviteBox>
            <InviteButton view={sendMember} onClick={() => inviteMember()}>
              {" "}
              Invite
            </InviteButton>
          </LeaderContainer>
        </div>
		)
}


export default Invite