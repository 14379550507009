import styled from "styled-components";
import React from "react";
import IMG from "assets/images/UI/Chat2.svg";
import IMG2 from "assets/images/UI/List.svg";
import CultureSummary from "assets/images/pdf/CultureSummary.svg";
import ChangeReport from "assets/images/pdf/ChangeReport.svg";

const IMAGE_MAP = {
  1: IMG,
  4: IMG2,
  8: CultureSummary,
  9: ChangeReport,
};

const ReportType = ({ title, description, id, next, pageLength }) => {
  return (
    <Container onClick={next}>
      <TopContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Title>{title}</Title>
        </div>
        <Icon src={IMAGE_MAP[id] || IMG} />
      </TopContainer>
      <Description>{description}</Description>
      <Length>Report length: {pageLength} pages</Length>
    </Container>
  );
};

export default ReportType;

const Container = styled.div`
  background-color: #f9fbfd;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 0px;
  cursor: pointer;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  max-width: 100%;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
  }
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatusDot = styled.div`
  height: 8px;
  width: 8px;
  background-color: #6fcf97;
  border-radius: 50%;
  margin-right: 10px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #2a3039;
`;

const Description = styled.div`
  font-size: 14px;
  color: #6e6e6e;
  margin: 10px 0 20px;
  line-height: 1.5;
`;

const Length = styled.div`
  font-size: 12px;
  color: #b3b3b3;
`;

const Icon = styled.img`
  height: 40px;
  margin: 0;
  align-self: flex-start;
`;
