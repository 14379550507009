// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getTranslatedText } from "./pdfUtils";
import translations from "./translations.json";

const Navigation = (props) => {
  const { currentPage, handlePageChange, lang } = props;

  return (
    <Container>
      <Title>{getTranslatedText("tableOfContents", lang, translations)}</Title>
      <SectionClickable
        onClick={() => handlePageChange(-1)}
        selected={currentPage === -1}
      >
        {getTranslatedText("reportTitleLabel", lang, translations)}
      </SectionClickable>

      <SectionTtitle>
        {getTranslatedText("personDomain", lang, translations)}
      </SectionTtitle>
      <List>
        <Item onClick={() => handlePageChange(0)} selected={currentPage === 0}>
          {getTranslatedText("personDomainScores", lang, translations)}
        </Item>
        <Item onClick={() => handlePageChange(1)} selected={currentPage === 1}>
          {getTranslatedText(
            "Physical health and well-being",
            lang,
            translations
          )}
        </Item>
        <Item onClick={() => handlePageChange(2)} selected={currentPage === 2}>
          {getTranslatedText(
            "Physical safety and safe sport",
            lang,
            translations
          )}
        </Item>
        <Item onClick={() => handlePageChange(3)} selected={currentPage === 3}>
          {getTranslatedText("Self-determination", lang, translations)}
        </Item>
        <Item onClick={() => handlePageChange(4)} selected={currentPage === 4}>
          {getTranslatedText(
            "Mental health and well-being",
            lang,
            translations
          )}
        </Item>
        <Item onClick={() => handlePageChange(5)} selected={currentPage === 5}>
          {getTranslatedText("Psychological safety", lang, translations)}
        </Item>
        <Item onClick={() => handlePageChange(6)} selected={currentPage === 6}>
          {getTranslatedText("personDomainHighItems", lang, translations)}
        </Item>
        <Item onClick={() => handlePageChange(7)} selected={currentPage === 7}>
          {getTranslatedText("personDomainLowItems", lang, translations)}
        </Item>
        <Item onClick={() => handlePageChange(8)} selected={currentPage === 8}>
          {getTranslatedText("personSubjectiveResponses", lang, translations)}
        </Item>
        <Item onClick={() => handlePageChange(9)} selected={currentPage === 9}>
          {getTranslatedText("personDomainSummaryComments", lang, translations)}
        </Item>
      </List>

      <SectionTtitle>
        {getTranslatedText("performanceDomain", lang, translations)}
      </SectionTtitle>
      <List>
        <Item
          onClick={() => handlePageChange(10)}
          selected={currentPage === 10}
        >
          {getTranslatedText("performanceDomainScores", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(11)}
          selected={currentPage === 11}
        >
          {getTranslatedText("dailyTrainingEnv", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(12)}
          selected={currentPage === 12}
        >
          {getTranslatedText("coaching", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(13)}
          selected={currentPage === 13}
        >
          {getTranslatedText("leadership", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(14)}
          selected={currentPage === 14}
        >
          {getTranslatedText("pathwaysAndProfiles", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(15)}
          selected={currentPage === 15}
        >
          {getTranslatedText("ssm", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(16)}
          selected={currentPage === 16}
        >
          {getTranslatedText("athletesAndIntResults", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(17)}
          selected={currentPage === 17}
        >
          {getTranslatedText("performanceDomainHighItems", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(18)}
          selected={currentPage === 18}
        >
          {getTranslatedText("performanceDomainLowItems", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(19)}
          selected={currentPage === 19}
        >
          {getTranslatedText(
            "performanceSubjectiveResponses",
            lang,
            translations
          )}
        </Item>
        <Item
          onClick={() => handlePageChange(20)}
          selected={currentPage === 20}
        >
          {getTranslatedText(
            "performanceDomainSummaryComments",
            lang,
            translations
          )}
        </Item>
      </List>

      <SectionTtitle>
        {getTranslatedText("overallSummary", lang, translations)}
      </SectionTtitle>
      <List removeBottomPadding={true}>
        <Item
          onClick={() => handlePageChange(21)}
          selected={currentPage === 21}
        >
          {getTranslatedText("overallSummaryHighItems", lang, translations)}
        </Item>
        <Item
          onClick={() => handlePageChange(22)}
          selected={currentPage === 22}
        >
          {getTranslatedText("overallSummaryLowItems", lang, translations)}
        </Item>
      </List>

      <SectionClickable
        onClick={() => handlePageChange(23)}
        selected={currentPage === 23}
      >
        {getTranslatedText("recommendations", lang, translations)}
      </SectionClickable>
    </Container>
  );
};

export default Navigation;

const Container = styled.div`
    width:320px;
    color:black;
    padding-right:20px;
    margin-right:20px;
`

const List = styled.div`
    margin-top:10px;
    display:flex;
    flex-direction:column;
    width:100%;
    margin-bottom: ${props=>props.removeBottomPadding?'10px':'30px'};
`

const Item = styled.div`
    cursor: ${props=>props.selected?'default':'pointer'};
    color:${props=>props.selected?'#2D70E2':'#666D79'};
    font-family: Raleway;
font-size: 14px;
padding:4px 0px;
padding-left:10px;
font-weight:${props=>props.selected?'600':'400'};
`

const Title = styled.div`
    color:#2A3039;
    font-family: Raleway;
font-size: 20px;
font-style: normal;
font-weight: 600;
margin-bottom:30px;
`

const SectionTtitle = styled.div`
    color:#666D79;
    font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 600;
margin-bottom:10px;
`

const SectionClickable = styled.div`
    cursor: ${props => props.selected ? 'default' : 'pointer'};
    color: ${props => props.selected ? '#2D70E2' : '#666D79'};
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin-bottom:10px;
`;