import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import TopProgress from './TopProgress'
import Loading from './Loading'
import FullPageLoading from './FullPageLoading';
import ReviewPlan from './ReviewPlan';
import FinalReview from './FinalReview';
import {make_modifications2,make_modifications3} from '../Request'
import {Icon} from 'semantic-ui-react'
import Milestones from "./Milestones";
import Behaviors from "./Behaviors";
import Progress from "./Progress";
import Share from "./Share";
import { useNavigate } from 'react-router-dom';
import NetworkError from './NetworkError'
import Back from 'ProtoType/SimpleDash/Back'
import FeedbackModal from './FeedbackModal'
import Copilot from './Copilot'

import {text} from './plan'

function isolateT2Sections(array) {
    const t2Sections = [];
    let currentSection = [];

    array.forEach(item => {
        if (item.type === 't2') {
            if (currentSection.length > 0) {
                t2Sections.push(currentSection);
            }
            currentSection = [item];
        } else if (currentSection.length > 0) {
            currentSection.push(item);
        }
    });

    // Add the last section if it exists
    if (currentSection.length > 0) {
        t2Sections.push(currentSection);
    }

    return t2Sections;
}


const Collab = ({ selected }) => {
const navigate = useNavigate();
  const [currentResponse, setCurrentResponse] = useState("");

  const [parts, setParts] = useState(isolateT2Sections(JSON.parse(text)));
  const [step, setStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(0);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [promptPathway, setPromptPathway] = useState([]);
  const [error, setError] = useState(false);
  const [selectedFeedback,setSelectedFeedback] = useState([])

  // Selected recommendation

  const handleResponseChange = (e) => {
    setCurrentResponse(e.target.value);
  };

  const handleNext = () => {
    setLoading(2);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setStep(step + 1);
    setStage(0);
  };

  const handleStage = (direction) => {
    setSelectedFeedback([])
    setCurrentResponse("");
    setLoading(direction > 0 ? 1 : 2);

    if (direction == 1) {
      ask_question();
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

   

    if(!error){
        if (direction == -1) {
            setViewFeedback(true);
          }
        setStage(stage + direction);
    }

    
  };

  const update_part = (part) => {
    let _parts = [...parts];
    _parts[step] = part;
    setParts(_parts);
  };

  const back = () =>{
    if(step>0){
      setStep(step-1)
    }
  }
   


  const ask_question = async () => {
    const prompt = `
          We've created a recommendation to improve culture in an organization. 
          That plan is represented in JSON format below:

            ${JSON.stringify(parts[step])}

          Ignore the JSON structure for now, and focus on the contents of the plan.
          
          We've asked the user who created this plan to provide feedback to the following question 
          about making this plan more actionable for them:

          ${currentResponse?`How would you improve this action plan to be more actionable for you?

          Here is their answer to this question:`:""}
          ${currentResponse}

          ${selectedFeedback.length>0? `Here are options that they selected to describe their feedback:`:""}
          ${selectedFeedback.map(item=>item)}

          Task:
            Please modify the contents on this template without changing the json structure.
            You should feel free to make changes to any part of the plan, not simply add to the plan,
            but you can edit any part of the plan to make it better for the user. 
            Make the plan better based on the feedback provided by the user. Don't number any bullet points.
          `;

    async function someFunction(prompt) {
      let message;
      if (promptPathway.length > 0) {
        message = await make_modifications3(promptPathway, prompt);
      } else {
        message = await make_modifications2(prompt);
      }


      if(message===null){
        setError(true);
        return;
      }

      let _promptPathway = [...promptPathway];
      _promptPathway.push(
        { role: "user", content: prompt },
        { role: "assistant", content: message }
      );
      setPromptPathway(_promptPathway);

      //handle the new template revisions, and replace the old ones
      update_part(JSON.parse(message));
      // Take the old ones and store them along with the newest ones for fine tuning.
      setCurrentResponse("");
      setViewFeedback(false);
      setLoading(false);
    }

    someFunction(prompt);
  };



  useEffect(()=>{
    if(error){
        setLoading(false)
        setStage(0)
        setViewFeedback(false)
    }
  },[error])

  const retry = () =>{
        setLoading(1)
        setError(false)
        setTimeout(() => {
            setLoading(false);
          }, 1000);
        ask_question()
  }

  const loadComponent = () => {
    if (loading == 1) {
      return <FullPageLoading message={"Modifying your plan"} />;
    }
    if (loading == 2) {
      return <Loading />;
    }
    if (step == parts.length) {
      return (
        <FinalReview
          plan={text}
          edit={() => handleStage(-1)}
          finish={() => handleNext()}
          back={back}
        />
      );
    }
    if (step == parts.length + 1) {
      return <Milestones text={text} confirm={() => handleNext()} />;
    }

    // if (step == parts.length + 2) {
    //   return <Behaviors text={text} confirm={() => handleNext()} />;
    // }

    if (step == parts.length + 2) {
      return <Progress confirm={() => navigate("/simple/prototype/draft/drafts")} />;
    }

    if (step == parts.length + 3) {
      return <Share confirm={() => navigate("/simple/prototype/draft/drafts")} />;
    }


      return (
        <>
          <Card>
           {step!=0?
           <BackButton onClick={()=>back()}><Icon name="chevron left" style={{marginTop:-4}}/>Back</BackButton>
           :""}
            {!error ? <FormatData dataArray={parts[step]} setError={setError} />:
            <NetworkError retry={retry}/>}
          </Card>

          {!viewFeedback && !error &&(
            <ButtonContainer>
             
              <FeedbackModal 
                handleStage={handleStage} 
                currentResponse={currentResponse} 
                selectedFeedback={selectedFeedback}
                setSelectedFeedback={setSelectedFeedback}
                handleResponseChange={handleResponseChange}/>
              {/* <Button2 onClick={() => setViewFeedback(true)}>
                Give feedback
              </Button2> */}
              <Button onClick={() => handleNext()}>Looks great!</Button>
            </ButtonContainer>
          )}

          {/* {viewFeedback && (
            <QuestionContainer>
              <Qualifier>Draft Improvement Question</Qualifier>
              <QuestionText>
                How would you improve this action plan to be more actionable for
                you?
              </QuestionText>
              <TextArea
                value={currentResponse}
                onChange={handleResponseChange}
              />

              <ButtonContainer>
                <Button onClick={() => handleStage(1)}>Next</Button>
              </ButtonContainer>
            </QuestionContainer>
          )} */}
        </>
      );

  };

  return (
    <Container>
      <Side>
        <Back url={-1} location={"plan drafts"}/>
        <Title>Lets collaborate on a plan</Title>
        <Description>
          Lets review the draft plan that we created for you. We'll be able to
          incorporate your feedback in order to make the plan more actionable
          for you. 
        </Description>
        <TopProgress step={step} totalSteps={parts.length} parts={parts} selectStep={(s)=>setStep(s)}/>
      </Side>

      <Content>
        {/* <TopText>
          <Circle>{AiIcon()}</Circle>

          <MessageContainer>
            {!loading &&
              planMessages[
                step == parts.length
                  ? 3
                  : stage == 1
                  ? 1
                  : stage == 0 && step > 0
                  ? 2
                  : 0
              ]}
          </MessageContainer>
        </TopText> */}
        {loadComponent()}
      </Content>
      <Copilot/>
    </Container>
  );
};

export default Collab;


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
`

const Side = styled.div`
width:450px;
padding:30px;
padding-bottom:100px;
padding-left:40px;
background-color:#F8FAFF;
`

const Content = styled.div`
  width:calc(100% - 400px);
  font-family:'Raleway';
  padding:30px;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:14px;
    font-weight:400;
    line-height:1.5;
    margin-bottom:20px;

`

const QuestionText = styled.div`
font-family:'Raleway';
font-size: 14px;
font-weight: 400;
line-height:1.4;
`

const QuestionContainer = styled.div`
min-height:250px;
margin-top:30px;
opacity: 0; // Start with hidden state
animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state


// 3. Adjust delay for each sample
animation-delay: 0.3s; 
`

const Qualifier = styled.div`
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #2D70E2;
`


const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const Button2 = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:10px;
`

const Card = styled.div`
  padding:20px;

  opacity: 0; // Start with hidden state
animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state


// 3. Adjust delay for each sample
animation-delay: 0.3s; 
`

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
`;

const MessageContainer = styled.div`
  background: #f8faff;
  border-radius: 8px;
  padding: 16px;
  margin-right: 10px;
`;

const TopText = styled.div`
    display: flex;
    margin-bottom: 40px;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 10px;
    color:#2A3039;
`

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    font-family: 'Raleway';
    margin-bottom:30px;
`

const ContentContainer = styled.div`
  font-family: 'Raleway';
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    line-height:1.4;
`

const T3 = styled.div`
    font-size:14px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const P = styled.div`
    margin-bottom: 20px;
    line-height:1.4;
`

const LI = styled.li`
    margin-bottom:10px;
    line-height:1.4;
`

const BackButton = styled.div`
    font-size:12px;
    font-weight:400;
    margin-bottom:10px;
    cursor:pointer;
    display:flex;
    align-items:center;
    margin-bottom:10px;
    margin-left:-10px;
    margin-top:-10px;
    font-family:'Raleway';
`

const LINK = styled.div`
    margin-bottom: 20px;
    margin-top:30px;
`

function makeBoldText(inputText) {
  try{
      // Use a regular expression to find the text before the colon
    const match = inputText.match(/^(.*?):/);

    if (match && match[1]) {
      const boldText = `<strong>${match[1]}:</strong>`;
      const replacedText = inputText.replace(/^(.*?):/, boldText);
      return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
    } else {
      // If there's no match, return the original text
      return inputText;
    }
  }
  catch{
    return inputText
  }
  
}

const FormatData = ({ dataArray,setError }) => {
  
    const renderElements = (items) => {
      const elements = [];
      let keyCounter = 0;
  
      items.forEach((item) => {
        let text = makeBoldText(item.text)
        switch (item.type) {
          case 't1':
            elements.push(<T1 key={keyCounter++}>{item.text}</T1>);
            break;
          case 't2':
            elements.push(<T2 key={keyCounter++}>{item.text}</T2>);
            break;
          case 't3':
              elements.push(<T3 key={keyCounter++}>{item.text}</T3>);
              break;
          case 'p':
            elements.push(<P key={keyCounter++}>{text}</P>);
            break;
          case 'l':
              elements.push(<LINK key={keyCounter++}><strong>{item.text}</strong> <a target='_blank' href={item.url}>{item.link_text}</a></LINK>);
              break;
          case 'b':
            // If the previous element is not a list, start a new list
            if (!elements.length || elements[elements.length - 1].type !== 'ul') {
              elements.push(
                <ul key={keyCounter++}>{[
                  <LI key={keyCounter++}>{text}</LI>
                ]}</ul>
              );
            } else {
              // If the previous element is a list, add the bullet point to it
              let lastElement = elements.pop();
              lastElement = React.cloneElement(lastElement, {
                children: [...lastElement.props.children, <LI key={keyCounter++}>{text}</LI>]
              });
              elements.push(lastElement);
            }
            break;
          default:
            break;
        }
      });
  
      return elements;
    };
  
    try {
      return <ContentContainer>{renderElements(dataArray)}</ContentContainer>;
    } catch (error) {
        return setError(true)
    }
  };

const planMessages = [
    "We've drafted an action plan for you based on your goals and your current situation. Please review the plan and let us know how we can improve it.",
    "We've incorporated your feedback into the plan. How does this version look?",
    "Heres the next section of your plan. Please review and let us know how we can improve it.",
    "We've finalized your plan based on your feedback. Please review the overall plan. You can always go back to any previous step to make additional improvements."
]
  
function AiIcon(fill) {
    return (
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1389 18.7668C18.9017 18.7668 18.7021 18.6829 18.5398 18.5151C18.3776 18.3473 18.2965 18.1409 18.2965 17.8956V9.59098C18.2965 9.34577 18.3776 9.13929 18.5398 8.97152C18.7021 8.79084 18.9017 8.7005 19.1389 8.7005C19.3885 8.7005 19.5944 8.78439 19.7566 8.95216C19.9189 9.11993 20 9.33287 20 9.59098V17.8956C20 18.1409 19.9126 18.3473 19.7379 18.5151C19.5757 18.6829 19.376 18.7668 19.1389 18.7668Z"
          fill="white"
        />
        <path
          d="M18.5331 6.71753C18.6908 6.88064 18.8801 6.9622 19.1009 6.9622H19.1956C19.4164 6.9622 19.6057 6.88064 19.7634 6.71753C19.9211 6.55442 20 6.35868 20 6.13033V6.06508C20 5.83672 19.9211 5.64099 19.7634 5.47787C19.6057 5.31476 19.4164 5.23321 19.1956 5.23321H19.1009C18.8801 5.23321 18.6908 5.31476 18.5331 5.47787C18.3753 5.64099 18.2965 5.83672 18.2965 6.06508V6.13033C18.2965 6.35868 18.3753 6.55442 18.5331 6.71753Z"
          fill="white"
        />
        <path
          d="M0.861115 5.2374C1.09823 5.2374 1.29791 5.32122 1.46015 5.48888C1.62239 5.65653 1.70351 5.86287 1.70351 6.1079V14.4067C1.70351 14.6517 1.62239 14.8581 1.46015 15.0257C1.29791 15.2063 1.09823 15.2965 0.861115 15.2965C0.611516 15.2965 0.405596 15.2127 0.243357 15.0451C0.0811174 14.8774 0 14.6646 0 14.4067V6.1079C0 5.86287 0.0873585 5.65653 0.262078 5.48888C0.424317 5.32122 0.623996 5.2374 0.861115 5.2374Z"
          fill="white"
        />
        <path
          d="M1.46693 17.2792C1.3092 17.1162 1.11992 17.0347 0.89909 17.0347H0.804451C0.583623 17.0347 0.394341 17.1162 0.236607 17.2792C0.0788722 17.4422 5.03191e-06 17.6378 5.03191e-06 17.866V17.9312C5.03191e-06 18.1594 0.0788722 18.355 0.236607 18.518C0.394341 18.681 0.583623 18.7625 0.804451 18.7625H0.89909C1.11992 18.7625 1.3092 18.681 1.46693 18.518C1.62467 18.355 1.70354 18.1594 1.70354 17.9312V17.866C1.70354 17.6378 1.62467 17.4422 1.46693 17.2792Z"
          fill="white"
        />
        <path
          d="M13.7232 2.62093C13.7232 2.13448 14.1046 1.74013 14.575 1.74013C15.0454 1.74013 15.4267 2.13448 15.4267 2.62093V21.379C15.4267 21.8655 15.0454 22.2598 14.575 22.2598C14.1046 22.2598 13.7232 21.8655 13.7232 21.379V2.62093Z"
          fill="white"
        />
        <path
          d="M4.57663 2.62093C4.57663 2.13448 4.95798 1.74013 5.42839 1.74013C5.8988 1.74013 6.28015 2.13448 6.28015 2.62093V21.379C6.28015 21.8655 5.8988 22.2598 5.42839 22.2598C4.95798 22.2598 4.57663 21.8655 4.57663 21.379V2.62093Z"
          fill="white"
        />
        <path
          d="M9.1499 0.880798C9.1499 0.394347 9.53125 0 10.0017 0C10.4721 0 10.8534 0.394347 10.8534 0.880799V10.2502C10.8534 10.7366 10.4721 11.131 10.0017 11.131C9.53125 11.131 9.1499 10.7366 9.1499 10.2502V0.880798Z"
          fill="white"
        />
        <path
          d="M9.1499 13.7498C9.1499 13.2634 9.53125 12.869 10.0017 12.869C10.4721 12.869 10.8534 13.2634 10.8534 13.7498V23.1192C10.8534 23.6056 10.4721 24 10.0017 24C9.53125 24 9.1499 23.6056 9.1499 23.1192V13.7498Z"
          fill="white"
        />
      </svg>
    );
  }
  
  
