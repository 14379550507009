import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  textWrapper: {
    backgroundColor: "#f8faff",
    borderRadius: 5,
    minHeight: 80,
    maxHeight: 100,
    padding: 10,
  },
  title: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 10,
  },
  container: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  text: {
    color: "#666d79",
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
});

const TextInput = (props) => {
  const { title } = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.textWrapper}>
        <Text style={styles.text}>
          {props.data?.[props.id] || "None given"}
        </Text>
      </View>
    </View>
  );
};

export default TextInput;
