import styled, { keyframes } from "styled-components";
import React from "react";
import {
  dataLabels,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  barChartOptions,
  Subsubtext,
} from "reports/DataJourney/components/common/styles";

const listData = [
  {
    id: 0,
    title: "Behavior's to practice",
    subTitle:
      "Based on your data, and the action you've chosen, here is a checklist of behaviors to practice.",
    descriptionTitle: "Behaviors",
    listItems: [
      "Communication and transparency: Respondents mentioned the importance of clear and open communication within the organization. They highlighted the need for regular updates, timely information sharing, and proactive communication from management. Several respondents emphasized the need for clear role definitions and well-defined responsibilities. They mentioned the importance of understanding their role within the organization and having clarity on their tasks and objectives. Many respondents praised the supportive and inclusive culture within the organization. They highlighted the importance of feeling valued, respected, and encouraged to be their authentic selves. They mentioned the need for a safe space to share ideas and concerns, as well as support during challenging times.",
      "Role clarity and responsibility: Several respondents emphasized the need for clear role definitions and well-defined responsibilities. They mentioned the importance of understanding their role within the organization and having clarity on their tasks and objectives.",
      "Supportive and inclusive culture: Many respondents praised the supportive and inclusive culture within the organization. They highlighted the importance of feeling valued, respected, and encouraged to be their authentic selves. They mentioned the need for a safe space to share ideas and concerns, as well as support during challenging times.",
    ],
  },
];

const ContextList = (props) => {
  const { listData } = props;
  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>{listData.title}</ScreenTitleExtended>
      <SubtextExtended delay={0.5}>{listData.subTitle}</SubtextExtended>
      {listData.description && (
        <SubsubtextExtended delay={0.4}>
          {listData.descriptionTitle}
        </SubsubtextExtended>
      )}
      <UL delay={0.6}>
        {listData.listItems.map((item, index) => {
          const getTitle = item.split(":")[0];
          const getDescription = item.split(":")[1];
          return (
            <LI key={index}>
              <strong>{getTitle}</strong>
              {getDescription}
            </LI>
          );
        })}
      </UL>
    </ScreenContainer>
  );
};

export default ContextList;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubsubtextExtended = styled(Subsubtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const UL = styled.ul`
  margin-top: 20px;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const LI = styled.li`
  margin-bottom: 20px;
  line-height: 1.4;
`;
