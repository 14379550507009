import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_DEBRIEF_SCHEDULES,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
} from "constants/actions";

import { SURVEY_TYPE } from "constants/surveys";

import { calculate_engagement_by_anchor } from "./caluclate_engagement";
import EngagamentChart from "./Chart";
import BasicDropdown from "components/BasicDropdown";
import ChangeChart from "./ChangeChart";
import BasicAnchor from "components/BasicAnchor";
import { Modal } from "semantic-ui-react";
import {
  CloseIcon,
  PieChartIcon,
  StarIcon,
  TrendsIcon,
} from "components/Icons";
import Chart from "react-apexcharts";
import LogAnimation from "components/LogAnimation";

// get the year based on the timestamp
const getYear = (timestamp) => {
  return new Date(timestamp).getFullYear();
};

// Get the range of the dates by year in the data array and map it to a new array
// the new array should show a range of dates from the earliest to the latest date
// it should also include the name of the project and the completed percentage based on the completed and total values
const getFormattedData = (data) => {
  let formattedData = [];
  data.forEach((parentElement) => {
    parentElement.data.forEach((element) => {
      formattedData.push({
        name: parentElement.name,
        date: element.date,
        completed: ((element.completed / element.total) * 100).toFixed(2),
      });
    });
  });

  // organize the formatted data into arrays based on the name
  let organizedData = {};
  formattedData.forEach((element) => {
    if (organizedData[element.name]) {
      organizedData[element.name].push(element);
    } else {
      organizedData[element.name] = [element];
    }
  });

  return organizedData;
};

const Engagement = () => {
  const dispatch = useDispatch();
  const [debriefSchedules, setDebriefSchedules] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const surveyType = SURVEY_TYPE.AUDIT;
  const showItems = "history";
  const [organizationId, setOrganizationId] = useState(false);
  const selectedPrimary = [];
  const [selectedOption, setSelectedOption] = useState("Overall");
  const [showModal, setShowModal] = useState(false);
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const [trends, setTrends] = useState();
  const [selectedDataPoint, setSelectedDataPoint] = useState();
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
  const [selectedName, setSelectedName] = useState();
  const [selectedChartData, setSelectedChartData] = useState();
  const [loading, setLoading] = useState(false);

  const {
    get_debrief_schedules,
    get_auth,
    get_selectedOrg,
    get_employees,
    get_employee_org_category,
    get_survey_structure,
  } = useSelector(
    (state) => ({
      get_debrief_schedules: state.debrief_schedule,
      get_auth: state.auth,
      get_selectedOrg: state.selectedOrg,
      get_employees: state.employees,
      get_employee_org_category: state.employee_category?.employee_org_category,
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  function getUniqueValues(array) {
    return ["Overall", ...new Set(array)];
  }

  const handleSelectedOptionChange = (val) => {
    setSelectedDataPoint();
    setSelectedOption(val);
  };

  useEffect(() => {
    setSelectedDataPoint();
  }, [anchor]);

  useEffect(() => {
    if (!organizationId) {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, get_auth]);

  useEffect(() => {
      setDebriefSchedules(get_debrief_schedules.debrief_schedule.results);

  }, [get_debrief_schedules]);

  useEffect(() => {
    if (organizationId && activePage) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${activePage}&type_of=${surveyType}&organization=${organizationId}${
          showItems ? `&curr-status=${showItems}` : ""
        }${selectedPrimary?.value ? `&name=${selectedPrimary?.value}` : ""}`,
      });

      if (!get_employee_org_category?.[0]) {
        dispatch({
          type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
          payload: `organization=${organizationId}`,
        });
      }
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (
      debriefSchedules?.length > 0 &&
      get_employee_org_category?.length > 0 &&
      anchor
    ) {
      if (selectedOption === "Overall") {

        setData(
          calculate_engagement_by_anchor(
            debriefSchedules,
            anchor,
            get_survey_structure,
            get_employee_org_category
          )
        );
      } else {
        const selected = debriefSchedules?.filter(
          (f) => f.name == selectedOption
        );

        console.log(selected)
        setData(
          calculate_engagement_by_anchor(
            selected,
            anchor,
            get_survey_structure,
            get_employee_org_category
          )
        );
      }
    }
  }, [
    get_survey_structure,
    anchor,
    get_employee_org_category,
    debriefSchedules,
    selectedOption,
  ]);

  useEffect(() => {
    if (data?.length > 0) {
      const formattedData = getFormattedData(data);
      setTrends(formattedData);
    }
  }, [data]);

  useEffect(() => {
    if (trends && selectedDataPoint?.length > 0) {
      const chartData = trends?.[selectedDataPoint]?.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      data2.series[0].data = chartData?.map((item) => item.completed);
      data2.options.xaxis.categories = chartData?.map((item) =>
        getYear(item.date)
      );
      setSeries(data2.series);
      setOptions(data2.options);
      setSelectedName(chartData[0]?.name);
      setSelectedChartData(chartData);
      setShowModal(true);
    }
  }, [trends, selectedDataPoint]);

  const getEngagementWord = (rate) =>{
    if(rate>80){
      return "Great"
    }
    if(rate>70){
      return "Good"
    }
    if(rate>60){
      return "Average"
    }
    if(rate>50){
      return "Below Average"
    }
    return "Low"
    
  }

  return (
    <Container>
      <Modal
        onClose={() => {
          setShowModal(false);
          setSelectedDataPoint();
        }}
        onOpen={() => setShowModal(true)}
        open={showModal}
        size="small"
      >
        <ModalContainer>
          <ModalHeader>
            <HeaderLabel>
              {selectedName && selectedName.toUpperCase()}
            </HeaderLabel>
            <CloseIconContainer
              onClick={() => {
                setShowModal(false);
              }}
            >
              {CloseIcon()}
            </CloseIconContainer>
          </ModalHeader>
          <StatsSection>
            <IconStat>
              <IconContainer backgroundColor="#eafbf7">
                {StarIcon()}
              </IconContainer>
              <StatsText>
                <ScoreLabel>{getEngagementWord( selectedChartData?.[selectedChartData?.length - 1]
                      ?.completed)}</ScoreLabel>
                <ScoreDescription>Overall engagement</ScoreDescription>
              </StatsText>
            </IconStat>
            <IconStat>
              <IconContainer backgroundColor="#FFFAF1">
                {PieChartIcon()}
              </IconContainer>
              <StatsText>
                <ScoreLabel>
                  {selectedChartData &&
                    selectedChartData?.[selectedChartData?.length - 1]
                      ?.completed}
                  %
                </ScoreLabel>
                <ScoreDescription>Feedback participation</ScoreDescription>
              </StatsText>
            </IconStat>
            {/* <IconStat>
              <IconContainer backgroundColor="#EBF1FD">
                {TrendsIcon()}
              </IconContainer>
              <StatsText>
                <ScoreLabel>High</ScoreLabel>
                <ScoreDescription>Feedback quality</ScoreDescription>
              </StatsText>
            </IconStat> */}
          </StatsSection>
          {/* <ModalDescription>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </ModalDescription> */}
          <ModalChartSection>
            <Chart options={options} series={series} type="line" height={400} />
          </ModalChartSection>
        </ModalContainer>
      </Modal>
      <SubTitle>Survey Trends</SubTitle>
      <PageTitle>Survey Engagement Analytics</PageTitle>
      <Description>
        Your hub for analyzing all survey engagement data by any category tag.
      </Description>
      <DropdownSections>
        <BasicAnchor customLabel="Engagement by" />
        <BasicDropdown
          label="Location"
          options={getUniqueValues(debriefSchedules?.map((item) => item.name))}
          selectedOption={selectedOption}
          handleOptionChange={handleSelectedOptionChange}
        />
      </DropdownSections>
      {(data && data?.length > 0 && !loading && (
        <>
          <EngagamentChart
            data={data}
            setSelectedDataPoint={setSelectedDataPoint}
          />

          <ChangeChart
            data={data}
            setSelectedDataPoint={setSelectedDataPoint}
          />
        </>
      )) || (
        <LoadWrapper>
          <LogAnimation />
          <LoadingText>Loading component</LoadingText>
        </LoadWrapper>
      )}
    </Container>
  );
};

export default Engagement;

const PageTitle = styled.div`
  color: #2a3039;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss11" on, "ss09" on, "ss08" on, "ss07" on, "ss06" on,
    "ss05" on, "ss04" on, "ss02" on, "ss01" on, "ss10" on, "salt" on, "ss03" on,
    "liga" off;
  /* Head/Bold 32 */
  font-family: Raleway;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 40.96px */
  text-transform: capitalize;
`;

const LoadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const LoadingText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  padding-top: 20px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const DropdownSections = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content:flex-end;
  align-items: center;
  margin-right: 20px;
  margin-bottom:-50px;
  margin-top:30px;
`;

const Container = styled.div`
  disxplay: flex;
  padding-left: 58px;
  margin-top: 20px;
  padding-right:30px;
`;

const SubTitle = styled.div`
  color:#2D70E2;
  font-weight:600;
  font-family: Raleway;
  margin-bottom:4px;

`

const Description = styled.div`
color: var(--text-2, #666D79);
font-variant-numeric: lining-nums proportional-nums;
font-feature-settings: 'ss08' on, 'ss07' on, 'ss05' on, 'ss09' on, 'ss02' on, 'ss01' on, 'salt' on, 'liga' off;
font-family: Raleway;
font-size: 12px;
margin-top:4px;
`

const ChartHeader = styled.div`
  color: #2a3039;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss02" on, "ss01" on, "ss11" on, "salt" on, "ss10" on,
    "ss09" on, "ss04" on, "ss03" on, "ss08" on, "ss07" on, "ss05" on, "liga" off;
  /* Head/SemiBold 20 */
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  align-items: center;
`;

const HeaderLabel = styled.div`
  color: #2a3039;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss09" on, "ss06" on, "ss02" on, "ss11" on, "ss04" on,
    "salt" on, "ss03" on, "ss08" on, "ss07" on, "ss05" on, "ss01" on, "ss10" on,
    "liga" off;
  /* Head/Bold 20 */
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%; /* 25.6px */
  text-transform: uppercase;
`;

const CloseIconContainer = styled.div`
  cursor: pointer;
`;

const StatsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

const IconStat = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 40px;
`;

const IconContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => props.backgroundColor};
`;

const StatsText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const ScoreLabel = styled.div`
  color: #2a3039;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss09" on, "ss01" on, "salt" on, "ss08" on, "ss02" on,
    "ss07" on, "ss05" on, "liga" off;

  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const ScoreDescription = styled.div`
  color: #666d79;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Regular 12 */
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

const ModalDescription = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  color: #666d79;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;

  /* Caption/Regular 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
`;

const ModalChartSection = styled.div``;

const data2 = {
  series: [
    {
      name: "Completion rate",
      data: [80.2, 80.5, 90, 70.6],
    },
  ],
  options: {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    markers: {
      size: 8,
      radius: 100,
    },
    grid: {
      padding: {
        left: 10,
        right: 25,
        bottom: 20,
        top: 10,
      },
    },
    background: {
      enabled: true,
      foreColor: "#fff",
      padding: 15,
      borderRadius: 10,
      opacity: 0.9,
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -10,
      style: {
        fontSize: "12px",
        fontWeight: 400,
        colors: ["#666D79", "#fff"],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 15,
        borderRadius: 10,
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    stroke: {
      width: [5, 2],
      curve: "smooth",
      dashArray: [0, 8],
    },
    xaxis: {
      categories: Array.from(
        new Array(4),
        (val, index) => new Date().getFullYear() - index
      ).reverse(),
    },
    yaxis: {
      title: {
        text: "Completion rate",
      },
      min: 0,
      max: 100,
      decimalsInFloat: 0,
      tickAmount: 4,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [100, 100],
        colorStops: [],
        gradientToColors: ["rgba(63, 134, 254, 0.06)", "rgba(112, 51, 255, 0)"], // optional, if not defined - uses the shades of same color in series
      },
    },
  },
};