import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';

const SplashScreen = ({ onContinue,goBack }) => (
  <Container>
    <BackButton onClick={()=>goBack()}><Icon name="chevron left"/> Back</BackButton>
    <BlueTitle>AI toolkit</BlueTitle>
    <Title>Culture Action Plan</Title>
    
    <Description>
      This tool is designed to give you deep insights into your organization’s culture. 
      Whether you’re looking to enhance employee engagement, improve retention, or understand where you stand compared to industry benchmarks, this action planning tool can help you make informed, impactful changes.
    </Description>
    
    <h2>How It Works</h2>
    <Description>
      You’ll start by selecting one of three diagnostic methods:
      <ul>
        <li><strong>ROI-Based Predictive Model</strong> - Focus on factors with the highest potential ROI based on predicted impact.</li>
        <li><strong>Global Benchmark Comparison</strong> - See how your organization compares to industry or global standards.</li>
        <li><strong>Raw Score Analysis</strong> - Get an unfiltered view of your culture factor scores.</li>
      </ul>
    </Description>
    
    <Description>
      Each method offers a unique perspective, empowering you to prioritize the areas most important to your goals. After selecting a method, we’ll guide you step-by-step through your results, providing explanations along the way.
    </Description>

    <h2>Ready to Begin?</h2>
    <Description>
      Click below to get started on your journey toward a more engaged and impactful workplace.
    </Description>
    <Button onClick={onContinue}>Get Started</Button>
  </Container>
);

export default SplashScreen;


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;



const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
`

const Container = styled.div`
     opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`

const Button = styled.button`
    background-color: #2D70E2;
    border: none;
    color: white;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    margin-top:20px;
`

const Title = styled.div`
    font-size:28px;
    font-weight:bold;
    margin-bottom:20px;
    font-family: 'Raleway';
`

const BlueTitle = styled.div`
    color:#2D70E2;
    font-size:14px;
    font-weight:600;
    margin-bottom:15px;
    margin-top:10px;
`

const Description = styled.div`
    margin-top:20px;
`
