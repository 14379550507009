import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import Actions from "./Actions";

const Plan = ({ title, description, create }) => {
	const [open, setOpen] = useState(false);

	return (
		<Container>
			<TopContainer>
				<IconContainer onClick={() => setOpen(!open)}>
					<Icon name={open ? "chevron down" : "chevron right"} />
				</IconContainer>
				<Title onClick={() => setOpen(!open)}>{title}</Title>
				<Description>{description}</Description>
				<Action>
					<Complete>Complete action</Complete>
					<More>
						<Icon name="ellipsis horizontal" />
					</More>
				</Action>
			</TopContainer>
			<ContentContainer open={open}>
				<Actions
					title={"Establish a communication plan"}
					description={
						"Bacon ipsum dolor amet kielbasa pork chop beef ribs tongue, cow picanha andouille venison porchetta ribeye drumstick capicola."
					}
					status={"Planned"}
					metric={"Cohesion"}
					first={"Mike"}
					last={"Bawol"}
				/>
				<Actions
					title={"Establish a communication plan"}
					description={
						"Bacon ipsum dolor amet kielbasa pork chop beef ribs tongue, cow picanha andouille venison porchetta ribeye drumstick capicola."
					}
					status={"Planned"}
					metric={"Cohesion"}
					first={"Mike"}
					last={"Bawol"}
				/>
				<Actions
					title={"Establish a communication plan"}
					description={
						"Bacon ipsum dolor amet kielbasa pork chop beef ribs tongue, cow picanha andouille venison porchetta ribeye drumstick capicola."
					}
					status={"Planned"}
					metric={"Cohesion"}
					first={"Mike"}
					last={"Bawol"}
				/>
				<Actions
					title={"Establish a communication plan"}
					description={
						"Bacon ipsum dolor amet kielbasa pork chop beef ribs tongue, cow picanha andouille venison porchetta ribeye drumstick capicola."
					}
					status={"Planned"}
					metric={"Cohesion"}
					first={"Mike"}
					last={"Bawol"}
				/>
				<Actions
					title={"Establish a communication plan"}
					description={
						"Bacon ipsum dolor amet kielbasa pork chop beef ribs tongue, cow picanha andouille venison porchetta ribeye drumstick capicola."
					}
					status={"Planned"}
					metric={"Cohesion"}
					first={"Mike"}
					last={"Bawol"}
				/>
				<Actions
					title={"Establish a communication plan"}
					description={
						"Bacon ipsum dolor amet kielbasa pork chop beef ribs tongue, cow picanha andouille venison porchetta ribeye drumstick capicola."
					}
					status={"Planned"}
					metric={"Cohesion"}
					first={"Mike"}
					last={"Bawol"}
				/>

				<Create onClick={() => create()}>
					<Icon name="plus" /> Create plan
				</Create>
			</ContentContainer>
		</Container>
	);
};

export default Plan;

const Container = styled.div`
	width: 100%;
	padding: 10px;
	font-family: "Barlow Semi Condensed", sans-serif;
	background-color: #f4f5f7;
	border-radius: 6px;
	margin-bottom: 5px;
`;

const TopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

const ContentContainer = styled.div`
	margin-top: ${(props) => (props.open ? "10px" : "0px")};
	margin-right: 20px;

	max-height: ${(props) => (props.open ? "10000px" : "0px")};
	transition: 0.4s all;
	overflow: hidden;
`;

const IconContainer = styled.div`
	cursor: pointer;
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 16px;
	width: 250px;
	border-right: 1px solid black;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
`;

const Description = styled.div`
	margin-left: 5px;
	width: calc(100% - 400px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Action = styled.div`
	display: flex;
	width: 180px;
`;

const Complete = styled.div`
	border-color: rgb(118, 118, 118);
	background-color: rgba(9, 30, 66, 0.08);
	padding: 5px 10px;
	font-weight: 500;
	cursor: pointer;
	transition: background 0.1s ease-out 0s,
		box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
	border-radius: 2px;
	margin-right: 10px;

	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
`;

const More = styled.div`
	border-color: rgb(118, 118, 118);
	background-color: rgba(9, 30, 66, 0.08);
	padding: 5px 10px;
	font-weight: 500;
	cursor: pointer;
	transition: background 0.1s ease-out 0s,
		box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
	border-radius: 2px;

	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
`;

const Create = styled.div`
	wisth: 100%;
	padding: 10px;
	cursor: pointer;
	border-radius: 3px;
	margin-left: 20px;

	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
`;
