import styled, { keyframes } from "styled-components";
import React, { useRef } from "react";
import { Icon, Label } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { getArrayAverage } from "../Analytics/calculations";

  const getGrade = (score) =>{
    if(score>96){
      return 'A+'
    }
    if(score>92){
      return 'A'
    }
    if(score>89){
      return 'A-'
    }
    if(score>86){
      return 'B+'
    }
    if(score>82){
      return 'B'
    }
    if(score>79){
      return 'B-'
    }
    if(score>76){
      return 'C+'
    }
    if(score>72){
      return 'C'
    }
    if(score>69){
      return 'C-'
    }
    if(score>66){
      return 'D+'
    }
    if(score>60){
      return 'D'
    }

    return 'F'
  }

const Insight = ({ risk, n, title, description, link, avg }) => {
  const risks = ["Modest", "Moderate", "High", "Very High"];
  let navigate = useNavigate();

  const navigateTo = (l) => {
    navigate(`/app/edi/report?name=${l}`);
  };

  const getRisk = () => {
    if (risk > 1.2 || risk < -1.2) {
      return "Very High";
    }
    if (risk > 0.8 || risk < -0.8) {
      return "High";
    }
    if (risk > 0.5 || risk < -0.5) {
      return "Medium";
    }
    if (risk > 0.2 || risk < -0.2) {
      return "Modest";
    }
    return "Low";
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Score>
        <Top>Avg. Grade</Top>
        <Row>
          {getGrade(avg)}
          {/* <IconContainer>
						<Icon name="arrow alternate circle down" />
						{score}%
					</IconContainer> */}
        </Row>
      </Score>
      <Risk>
        {risk > 0 ? "Strength" : "Risk"} Level :{" "}
        <Label
          color={risk < -0.5 ? "orange" : risk > 0 ? "green" : "yellow"}
          size={"tiny"}
        >
          {getRisk()}
        </Label>
      </Risk>
      <Risk>Sample size: {n}</Risk>
      <Description>{description}</Description>
      <Link onClick={() => navigateTo(link)}>Checkout Raw Data > </Link>
    </Container>
  );
};

export default Insight;

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  width: calc(33% - 5px);
  font-family: "Barlow", sans-serif;
  margin-top: 10px;
  margin-right: 5px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1;
  height: 25px;
`;

const Description = styled.div`
  font-weight: 300;
`;

const Risk = styled.div`
  margin-bottom: 5px;
  margin-top: 10px;
`;

const Score = styled.div`
  font-size: 50px;
  font-family: "Barlow Semi Condensed", sans-serif;
  line-height: 1;
  margin-bottom: 20px;
`;

const Top = styled.div`
  font-size: 12px;
  color: grey;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const IconContainer = styled.div`
  font-size: 22px;
  margin-top: 5px;
  margin-left: 10px;
  color: red;
`;

const Link = styled.div`
  margin-top: 20px;
  color: #2d50e2;
  cursor: pointer;
`;
