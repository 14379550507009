import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../../transitions.css"; // Import your CSS for transitions
import Intro from "./JourneyIntro";
import HowTheyWork from "./HowTheyWork";
import SuccessProbability from "./SuccessProbability";
import FocusSuggestion from "./FocusSuggestion";
import JourneyPath from "../../JourneyPath/v1";
import AreYouReady from "./AreYouReady";
import WhatPeopleSay from "./WhatPeopleSay";

const JourneyIntro = (props) => {
  const { introStep, onIntroComplete } = props;

  const renderContent = (currentStep) => {
    // Use a case statement to determine which component to render
    switch (currentStep) {
      case 0:
        return <Intro {...props} />;
      case 1:
        return <HowTheyWork {...props} />;
      case 2:
        return <SuccessProbability {...props} />;
      case 3:
        return <FocusSuggestion {...props} />;
      case 4:
        return <AreYouReady {...props} />;
      case 5:
        return <WhatPeopleSay {...props} />;
      default:
        return <Intro {...props} />;
    }
  };

  return renderContent(introStep);
};

export default JourneyIntro;
