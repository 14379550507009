import styled, { keyframes } from "styled-components";
import React from "react";
import Overview from './Overview'
import Connection from './Connection'
import Team from './Team'
import EQ from './EQ'
import Comments from './Comments'
import Themes from './Themes'

const Profile = () =>{

	const [selected,setSelected] = React.useState(6)
	return (
		<Container>
			<Card>
				<UserContainer>
						<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"}/>

						<Name>Michael Bawol</Name>
					
				</UserContainer>

				<Tabs>
					<Tab selected={selected==1} onClick={()=>setSelected(1)}>Overview</Tab>
					<Tab selected={selected==2} onClick={()=>setSelected(2)}>Connections</Tab>
					<Tab selected={selected==3} onClick={()=>setSelected(3)}>Team Health</Tab>
					<Tab selected={selected==4} onClick={()=>setSelected(4)}>Emotional Health</Tab>
					<Tab selected={selected==5} onClick={()=>setSelected(5)}>Comments</Tab>
					<Tab selected={selected==6} onClick={()=>setSelected(6)}>Themes</Tab>
				</Tabs>
			</Card>
			<Bottom>
				{selected==1?
					<Overview/>:
					selected==2?
				<Connection/>:
				selected==3?
				<Team/>:
				selected==4?<EQ/>:
				selected==5?<Comments/>:<Themes/>}
			</Bottom>
		</Container>
		)
}



export default Profile

const Container = styled.div`
	width:calc(100% - 270px);
	border-radius: 15px;
  	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  	overflow:hidden;
`

const Card = styled.div`
	width:100%;
	padding:30px 30px 0px 30px ;
	
  	background-color: #ffffff;
`


const UserContainer = styled.div`
	display:flex;
	align-items:center;
	position:relative;
	margin-bottom:40px;
`

const Avatar = styled.img`
	width:60px;
	height:60px;
	border-radius:30px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:36px;
	font-weight:bold;
	color:#4a4a4a;
`

const Tabs = styled.div`
	display:flex;
`

const Tab = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px;
	font-size:16px;
	font-weight:${props=>props.selected?'bold':400};
	border-bottom:${props=>props.selected?'2px solid #6a00ff':400};
	color:${props=>props.selected?'black':'#9b9b9b'};
	cursor:pointer;
`

const Bottom = styled.div`
	width:100%;
	background-color:white;

`
