import styled, { keyframes } from "styled-components";
import React from "react";


const ThemeEntry = (props) =>{

	return (
		<ThemeContainer >
			<SideContainer>
			<Dot/>
			<Line last={props.last}/>
			</SideContainer>
			<Content>
				<Dated>{props.date}</Dated>
				<ThemeTitle>{props.theme}</ThemeTitle>
				{/*<Row>
					<Text>Impact</Text>
					<Impact color={props.impact=="Negative"?"255, 184, 18":"48, 120, 255"}>{props.magnitude} {props.impact}</Impact>
				</Row>
				<Row>
					<Text>Sentiment</Text>
					<Impact color={props.sentiment=="Positive"?"8, 194, 81":"255, 45, 85"}>{props.sentiment}</Impact>
				</Row>
				<Row>
					<Text>Keywords</Text>
					<Impact color={"106, 0, 255"}>{props.keywords}</Impact>
				</Row>*/}
			</Content>
		</ThemeContainer>
		)
}


const ThemeTimeline = ()=>{

	return (
		<Container>
			<Title>Theme Timeline</Title>
			<ThemeEntry theme={"Growth Mindset"} date={"May 31st"} impact={"Positive"} magnitude={"Large"} sentiment={"Positive"} keywords={"season trying win"}/>
			<ThemeEntry theme={"Performance"} date={"May 24th"} impact={"Negative"} magnitude={"Moderate"} sentiment={"Negative"} keywords={"drained stress injury"}/>
			<ThemeEntry theme={"Hard work paying off"} date={"May 17th"} impact={"Positive"} magnitude={"Moderate"} sentiment={"Positive"} keywords={"improved season eager"}/>
			<ThemeEntry theme={"New experiences"} date={"May 10th"} impact={"Negative"} magnitude={"Moderate"} sentiment={"Negative"} keywords={"drained stress injury"}/>
			<ThemeEntry theme={"Growth Mindset"} date={"May 3rd"} impact={"Positive"} magnitude={"Large"} sentiment={"Positive"} keywords={"season trying win"}/>
			<ThemeEntry theme={"Performance"} date={"April 24th"} impact={"Negative"} magnitude={"Moderate"} sentiment={"Negative"} keywords={"drained stress injury"}/>
			<ThemeEntry theme={"Hard work paying off"} date={"April 17th"} impact={"Positive"} magnitude={"Moderate"} sentiment={"Positive"} keywords={"improved season eager"}/>
			<ThemeEntry theme={"New experiences"} date={"April 10th"} impact={"Negative"} magnitude={"Moderate"} sentiment={"Negative"} keywords={"drained stress injury"} last={true}/>
		</Container>
		)
}


export default ThemeTimeline



const Container = styled.div`
	width:calc(40% - 30px);
	margin-left:30px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;
	font-size:18px;
	width:100%;
	margin-top:50px;
`

const ThemeContainer = styled.div`
	width:100%;
	height:75px;
	display:flex;
`

const Dot = styled.div`
	height:10px;
	width:10px;
	border-radius:5px;
	background-color:white;
	border:3px solid #6a00ff;
`

const Line = styled.div`
	height:${props=>props.last?'0%':'100%'};
	width:1px;
	background-color:#6a00ff;
	margin-left:5px;
`

const SideContainer = styled.div`
	height:100%;
`

const Content = styled.div`
	margin-top:-3px;
	margin-left:10px;
	width:100%;
`

const Dated = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:12px;
	color:#a5a5a5;
`

const ThemeTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
`

const Row = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	margin-top:5px;
`

const Text= styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
`

const Impact = styled.div`
	background-color:${props=>'rgba('+props.color+',0.3)'};
	color:${props=>'rgba('+props.color+',1)'};
	border:${props=>'1px solid rgba('+props.color+',0.6)'};
	border-radius:4px;
	padding:3px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
	font-size:13px;
`