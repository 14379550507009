import React from 'react'
import styled from 'styled-components'
import { avatarIcon, AiIcon } from "../../reports/AI/Icons";

const SideChat = ({open,setOpen}) => {


    return (
        <Container open={open}>
            <Title>Culture Coach</Title>
            <Query>
                <Circle>
                {AiIcon()}
                </Circle>
                <TextContainer>
                <MessageContainer>
                    <Message>
                    <Description >
                        Hi, I see you're working on the plan for what culture issues to improve. Lets take a look at your data to start.
                    </Description>
                    </Message>
                </MessageContainer>
                </TextContainer>
            </Query>
           
            <Button onClick={(()=>setOpen())}>Close</Button>
        </Container>
    )

}

export default SideChat;

const Container = styled.div`
   width:500px;
   position:absolute;
   right:${props=>props.open ? "0px" : "-700px"};
   top:0px;
    height:100vh;
    background-color:white;
    z-index:1000;
    transition:all 0.5s ease-in-out;
    padding:20px;
`

const Title = styled.div`
    font-size:20px;
    margin-bottom:20px;
    font-family:"Raleway";
    font-weight:bold;
`

const Button = styled.button`

`



const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;

  max-width: 800px;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
`;

const MessageContainer = styled.div`
  background: #f8faff;
  border-radius: 8px;
  padding: 16px;
  margin-right: 10px;
`;

const Message = styled.div`
  align-self: center;
`;
const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.error ? "#F15062" : "#2A3039")};
  white-space: pre-wrap;
`;

const Query = styled.div`
  display: flex;
  margin-bottom: 20px;

  width:100%;

  max-width:1400px;
`;
