import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from 'semantic-ui-react'

import ToolData from "./tools";
import AccountSetup from './AccountSetup'
import { Link, useLocation } from "react-router-dom";
import Team from './Team'

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SG_GET_ONBOARDINGS,
  SG_EDIT_ONBOARDING,
} from "constants/actions";

const Member = (props) =>{

	return (
		<MemberContainer>
			<Avatar src={props.img} />
			<Name>{props.name}</Name>
		</MemberContainer>
		)
}

const Tool = (props) =>{
	return (
		<Tools>
			<Link to={props.data.link}>
				<ColorBar color={props.data.color}/>
				<IconContainer color={props.data.color}>
					{props.data.img?<Image src={props.data.img}/>:<Icon name={props.data.icon}/>}
				</IconContainer>
				<ToolTitle>{props.name?props.name:props.data.title}</ToolTitle>
				<ToolDescription>{props.data.description}</ToolDescription>
				<Icon name="arrow right"  style={{position:'absolute',right:20,bottom:20}}/>
			</Link>
		</Tools>
		)
}


const ToolBox = (props) =>{
	const dispatch = useDispatch();
	const [onBoardingData, setOnBoardingData] = React.useState(false)


	const {get_onboarding,get_auth} = useSelector(
      (state) => ({
        get_onboarding: state.onboarding.onboarding,
          get_auth: state.auth,
      }),
      shallowEqual
    );

     React.useEffect(() => {
    // if the response is ok 200
    if (get_auth?.token) {
      dispatch({
        type: SG_GET_ONBOARDINGS,
      });
    }
  }, [dispatch, get_auth]);



	React.useEffect(() => {
      setOnBoardingData(get_onboarding)
  }, [get_onboarding]);


	return (
		<Container>
			<Team/>
			<div>
				<AccountSetup loading={props.loading} 
				setOnBoardingData={(d)=>setOnBoardingData(d)}/>
				
				{onBoardingData?.is_dismissed &&
					(<ToolContainer>
					<Title2>Your Tools</Title2>
					<Line/>
					{ToolData.map((item,i)=>{
						if(props.role<=item.role){
							if(item.title=="My Data" && props.role<9){
								return <Tool data={item} name="Member Analytics" key={i} />
							}

							return <Tool data={item} key={i} />
						}
						
					})}
				
				</ToolContainer>)}
			</div>
		</Container>
		)
}



export default ToolBox

const Line = styled.div`
	width:100%;
	height:1px;
	background-color:#e9e9e9;
`


const Container = styled.div`
	width:100%;
	font-family: 'Red Hat Display', sans-serif;
	margin-top:30px;
	display:flex;

	@media(max-width:768px){
		flex-direction:column;
	}
`

const MemberContainer = styled.div`
	display:flex;
	align-items:center;
	margin-top:10px;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	border-radius:15px;
	margin-right:10px;
`

const Name = styled.div`
	font-size:14px;
	font-weight:bold;
`

const Title = styled.div`
	font-size:14px;
	color:#545454;
	margin-bottom:20px;
`

const Title2 = styled.div`
	font-size:14px;
	color:#545454;
	margin-top:20px;

	@media(max-width:768px){
		font-size:18px;
	}
`

const ToolContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
`

const Tools = styled.div`
	width:275px;
	height:300px;
	box-shadow: 0 4px 20px 0 #ececec;
	padding:20px;
	position:relative;
	margin-right:20px;
	margin-top:20px;

	a{
		color:black;
	}

	@media(max-width:768px){
		width:100%;
		margin-right:0;
	}
`

const ColorBar = styled.div`
	width:100px;
	background-color:${props=>props.color};
	height:5px;
	position:absolute;
	top:0;
	left:0;
`

const IconContainer = styled.div`
	height:50px;
	font-size:50px;
	color:${props=>props.color};
	margin-top:20px;
`

const ToolTitle = styled.div`
	font-size:18px;
	font-weight:bold;
	margin-bottom:20px;
	margin-top:10px;
`

const ToolDescription = styled.div`
	
`

const Image = styled.img`
	height:40px;
	margin-top:-10px;
`