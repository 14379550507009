import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon,Progress } from 'semantic-ui-react'

const TeamMember = ({employee}) =>{

	return (
		<Member>
			{employee?.answered?<Icon name='check' style={{color:'white'}}/>
			:<div style={{width:20}}/>}
			{employee.picture?<Avatar src={employee?.picture} />:
				<Icon name="user circle" style={{fontSize:20,marginTop:0,marginRight:5,marginLeft:-2,color:'white'}}/>}
			<Name>{employee?.name}</Name>
		</Member>
		)
}


const Completion = ({employeeList, averageResp}) =>{
	// console.log(employeeList)

	return (
		<Container>
			<Title>Team Completion </Title>
			 <Progress percent={averageResp === Infinity? 0 : averageResp } inverted color='grey' progress size='small' />
			 {employeeList.map((em, i) => {
				return <TeamMember employee={em} key={i} />
			 })}
			
		</Container>
		)
}


export default Completion



const Container = styled.div`
	width:100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:30px;
	margin-top:20px;
	background-color:#15056b;
`

const Title = styled.div`
	color:white;
	font-weight:bold;
	font-size:18px;
`

const Average = styled.div`
	font-size:50px;
	font-weight:bold;
	width:100%;
	padding:20px 0px;
	display:flex;
	justify-content:center;
`

const Description = styled.div`
	width:100%;
	justify-content:center;
	display:flex;
`

const Member = styled.div`
	width:100%;
	display:flex;
	align-item:center;
	margin-top:10px;
`

const Avatar = styled.img`
	width:20px;
	height:20px;
	border-radius:10px;
	margin-right:5px;
`

const Name = styled.div`
	font-size:12px;
	color:white;
`