import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";

const People = () => {
	const [open, setOpen] = useState(false);

	return (
		<Container>
			<Top onClick={() => setOpen(!open)}>
				<Title>People</Title>
				<Description>
					Who is responsible for leading this strategy?
				</Description>
				<IconContainer>
					<Icon name={open ? "chevron up" : "chevron down"} />
				</IconContainer>
			</Top>

			<Selected>Selected: none</Selected>
		</Container>
	);
};

export default People;

const Container = styled.div`
	width: 100%;
	font-family: "Barlow Semi Condensed", sans-serif;
	border-radius: 3px;
	border: 1px solid lightgrey;
	padding: 10px;
`;

const Top = styled.div`
	display: flex;

	position: relative;
	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
	cursor: pointer;
`;

const Title = styled.div`
	font-weight: bold;
	margin-right: 5px;
	font-size: 16px;
`;

const Description = styled.div`
	font-size: 13px;
	color: grey;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 10px;
`;

const Selected = styled.div``;
