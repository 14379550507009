import React from 'react';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LoadingLine = styled.div`
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 5px;

  // Shimmer effect
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${shimmer};
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(
    to right,
    #f6f7f8 0%,
    #e0e0e0 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 800px 104px;
  position: relative;
`;

const TextLoading = () => {
  return (
    <LoadingWrapper>
      <LoadingLine style={{ width: '90%' }} />
      <LoadingLine style={{ width: '60%' }} />
      <LoadingLine style={{ width: '80%' }} />
      <LoadingLine style={{ width: '85%' }} />
      <LoadingLine style={{ width: '75%' }} />
      <LoadingLine style={{ width: '95%' }} />
    </LoadingWrapper>
  );
};

export default TextLoading;
