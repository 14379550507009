import React, {useState,useEffect} from 'react';
import styled, { keyframes } from 'styled-components';
import Input from 'semantic-ui-react'

const Custom = styled.input`
	border-radius:5px;
	height:45px;
	outline:none;
	border:${props=>props.error? '1px solid #ff2d55':'1px solid #9b9b9b'};
	margin-top:10px;
	padding:0px;
`
const Container = styled.div`
  font-size: 16px;
  font-weight:400;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  flex-direction:column;
  margin-bottom:30px;
  margin-right:30px;
  width:${props=>props.half?'calc(50% - 15px)':'100%'};

  @media (max-width:768px){
  	margin-bottom:${props=>props.dob?'10px':'30px'};
  }
`

const Error = styled.div`
	font-size:14px;
	font-style: italic;
	color:#ff2d55;
`
const CusInput = React.forwardRef((props, ref) => {
	return (
	  <Container half={props.half} dob={props.dob}>
		{props.placeholder}
		<Custom
		  type={props.type}
		  disabled={props.disabled}
		  value={props.value}
		  onChange={(e) => props.onChange(e.target.value)}
		  defaultValue={props.defaultValue}
		  forwardedRef={ref}
		/>
		<Error>{props.error}</Error>
	  </Container>
	);
  });
  
  export default CusInput;