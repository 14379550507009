import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

//
import OnBoarding from "dashboard/TeamToolHome";
//
// New Reports
import NewEQ from "reports/emotional_health/index";
import TeamHealth from "reports/team_health/index";
import CultureAnalytics from "reports/culture/analytics";
import AdminDash from "reports/admin_dash/index";

import TeamExplore from "reports/team_explore/index";
import UserExplore from "reports/user_explore/index";
import NewSystems from "reports/emotional_health/NewSystems";
// report
import PersonalityDash from "reports/personality_new";
import TraitReport from "reports/personality/traitReport";
//
import MobileTeamDash from "reports/hr/TeamDash/MobileTeamDash";
// import NewAnalytics from "reports/hr/new_analytics/index";
import TeamAnalytics from "reports/hr/new_analytics/Team/index";
// import TeamChanges from "reports/changes/teams"

// import MobileEmotions from "reports/eq/MobileEmotions";
import MobileCultureAnalytics from "reports/culture/analytics/MobileCultureAnalytics";
import Leadership from "reports/leadership";
// import MobileLeadership from "reports/leadership/MobileLeadership";
// debriefs
import Debriefs from "debriefs/index";
// settings
import SurveySettings from "settings/survey_settings/index";
import TeamSurveyStatus from "surveyHistory/UserCompletion";
import LeaderList from "settings/survey_settings/LeaderList";
//
import Lost from "404";
//
function RoutesTeamTool() {
  let navigate = useNavigate();
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoute, setValidRoute] = useState(false);
  const [employee, setEmployee] = useState(false);

  const { get_auth, get_organizations, get_selectedOrg,
    get_employees } = useSelector(
      (state) => ({
        get_auth: state.auth,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
        get_employees: state.employees,
      }),
      shallowEqual
    );

  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      if (Number(EmployeeId)) {
        setEmployee(get_employees[get_auth.employee_id]);
      }
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth,
    EmployeeId, get_employees]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId]);
    } else {
      setOrganization(false);
    }
  }, [organizationId, get_organizations]);

  useEffect(() => {
    const Found = organization?.services_enabled?.filter(
      srv => srv?.enabled === true
    ).map(sr => sr.name)

    setValidRoute(Found)
  }, [organization]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!validRoute?.includes("Teamtool")) {
        return navigate("/app/unsubscribed")
      }
    }, 2000)
    return () => clearTimeout(timer1)

  }, [organization, validRoute, navigate]);


        return (
          <>
            {validRoute && (
              <Routes>
                <Route path="/" element={<OnBoarding />} />
                <Route path={"dashboard"} element={<OnBoarding />} />
                <Route path={"eq"} element={<NewEQ />} />
                <Route path={"team"} element={<TeamHealth />} />
                <Route path={"culture"} element={<CultureAnalytics />} />
                <Route
                  path={"mobilecultureanalytics/:type"}
                  element={<MobileCultureAnalytics />}
                />
                <Route path={"admin"} element={<AdminDash />} />
                <Route path={"newanalytics/teams"} element={<TeamAnalytics />} />
                <Route path={"mobileteamdash/:type"} element={<MobileTeamDash />} />
                <Route path={"teams"} element={<TeamExplore />} />
                <Route path={"leadership"} element={<Leadership />} />
                <Route path={"team-survey-status"} element={<TeamSurveyStatus />} />
                <Route path={"personality-dash"} element={<PersonalityDash />} />
                <Route
                  path={"personality-dashboard/:report_name"}
                  element={<TraitReport />}
                />
                <Route path={"personal"} element={<UserExplore />} />
                <Route path={"systems"} element={<NewSystems />} />
                <Route path={"debriefs"} element={<Debriefs />} />
                <Route path={"survey_settings"} element={<SurveySettings />} />
                <Route path={"leader_list"} element={<LeaderList />} />
                <Route path={"*"} element={<Lost />} />
              </Routes>
            )}
          </>
        );
      }

      export default RoutesTeamTool;
