import React from 'react';
import styled, { keyframes } from "styled-components";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.


const Container = styled.div`
  width:500px;
  display:flex;
  flex-direction:column;
  margin-top:70px;
  margin-left:calc((100% - 500px) / 2);
  border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
    padding:36px;

    @media (max-width:768px){
      width:100%;
      margin:20px 0 0 0 ;
      padding:36px 20px 36px 20px;
    }
`

const Title = styled.div`
	font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:20px;
`
const Text = styled.div`
	font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:5px;
  font-weight:bold;
`

const Text2 = styled.div`
	font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  font-weight:500;
`
const Text3 = styled.div`
	font-size: 18px;
  font-family: "Red Hat Display", sans-serif;
  font-weight:400;
`

const TrialText = styled.div`
	font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:20px;
`

const Button = styled.div`
  width: 196px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-bottom:20px;
`
const Button2 = styled.div`
  width: 100px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
`

const Check = styled.div`
  width:15px;
  height:15px;
  min-width:15px;
  border-radius:7.5px;
  color:${props=>props.color};
  border:2px solid ${props=>props.color};
  display:flex;
  align-items:center;
  justify-content:center;
  font-weight:900;
  font-size:10px;
  margin-right:10px;
`
const Feature = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  font-size: 1rem;
  color: #4a4a4a;
  margin-bottom:5px;

`

const More = styled.div`
	width:100%;
	border-top:1px solid #e9e9e9;
	border-bottom:1px solid #e9e9e9;
	padding:20px;
	margin-top:20px;
	margin-bottom:20px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-between;
`

export default function Trial() {
  return (
    <Container>
      <Title>Billing</Title>
      <TrialText>Your free trial expires in 14 days.</TrialText>
      <Button>Upgrade to premium</Button>
      <Text>With innerlogic premium, your organization gets:</Text>
          <Feature>
                  <Check color={"#c73d88"}>✓</Check>
                  Unlimited Culture &amp; Mood Tracking
              </Feature>
              <Feature>
                  <Check color={"#c73d88"}>✓</Check>
                  Unlimited Teams, Departments & Members
              </Feature>
               <Feature>
                  <Check color={"#c73d88"}>✓</Check>
                  360 Leadership Pulses for your Leaders
              </Feature>
               <Feature>
                  <Check color={"#c73d88"}>✓</Check>
                  Dynamic Personality Analytics
              </Feature>
               <Feature>
                  <Check color={"#c73d88"}>✓</Check>
                   Premium Organization Analytics
              </Feature>

              <More>
                <div>
                  <Text2>The Innerlogic Enterprise Plan</Text2>
                  <Text3>Culture Mastery. Expert Delivery.</Text3>
                </div>
                <Button2>Contact Us</Button2>
              </More>
     
  </Container>
  );
}