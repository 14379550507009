import React from 'react';
import { Card, Heading, Paragraph, Loader } from './styles/Common';

const UserSyncLoading = () => {
  return (
    <Card style={{textAlign: 'center'}}>
      <Heading>Syncing Users...</Heading>
      <Loader />
      <Paragraph>Please wait while we synchronize your users with Slack.</Paragraph>
    </Card>
  );
};

export default UserSyncLoading;
