import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  LOGIN_REQUEST,

  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_TOKEN,
  SG_REGISTER_USER_TOKEN,
  REGISTER_AMBASSADOR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  FORGOT_PASSWORD,
  SG_FORGOT_PASSWORD,
  RESET_PASSWORD,
  SG_RESET_PASSWORD,
  CLEAR_ALL,
  SG_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
  ALL_ERRORS,
} from "constants/actions";
import * as api from "constants/api";


// LOGIN USER
function* loginUser(action) {
  try {
    const login = yield call(api.LOGIN_USER, action.payload);
    yield put({ type: LOGIN_SUCCESS, payload: login.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
    yield put({ type: LOGIN_FAIL, payload: e });
  }
}

export function* websiteLoginUser() {
  yield takeLatest(LOGIN_REQUEST, loginUser);
}



// LOGOUT USER
function* logoutUser() {
  try {

    yield call(api.LOGOUT_USER);
    yield put({ type: CLEAR_ALL });
    yield put({ type: LOGOUT_SUCCESS });

  } catch (e) {
    yield put({ type: CLEAR_ALL });
    yield put({ type: LOGOUT_SUCCESS });

    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteLogoutUser() {
  yield takeLatest(LOGOUT_REQUEST, logoutUser);
}

function* forgotPassword(action) {
  try {
    yield put({ type: CLEAR_ALL });
    const json = yield call(api.FORGOT_PASSWORD, action.payload);
    yield put({ type: FORGOT_PASSWORD, payload: json });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteForgotPassword() {
  yield takeLatest(SG_FORGOT_PASSWORD, forgotPassword);
}


function* resetPassword(action) {
  try {

    yield put({ type: CLEAR_ALL });
    const json = yield call(api.RESET_PASSWORD, action.payload);
    yield put({ type: RESET_PASSWORD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });

  }
}

export function* websiteResetPassword() {
  yield takeLatest(SG_RESET_PASSWORD, resetPassword);
}

function* registerUser(action) {
  try {
    const json = yield call(api.REGISTER_USER, action.payload);
    yield put({ type: REGISTER_SUCCESS, payload: json.data });
    yield put({ type: LOGIN_SUCCESS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });

    yield put({ type: REGISTER_FAIL, payload: e.message });
  }
}

export function* websiteRegisterUser() {
  yield takeLatest(REGISTER_USER, registerUser);
}

function* registerUserToken(action) {
  try {
    const json = yield call(api.REGISTER_USER_TOKEN, action.payload);
    yield put({ type: REGISTER_USER_TOKEN, payload: json.data });
    yield put({ type: LOGIN_SUCCESS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteRegisterUserToken() {
  yield takeLatest(SG_REGISTER_USER_TOKEN, registerUserToken);
}

function* registerAmbassador(action) {
  try {
    const json = yield call(api.REGISTER_AMBASSADOR, action.payload);
    yield put({ type: REGISTER_SUCCESS, payload: json.data });
    yield put({ type: LOGIN_SUCCESS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
    yield put({ type: REGISTER_FAIL, payload: e.message });
  }
}

export function* websiteRegisterAmbassador() {
  yield takeLatest(REGISTER_AMBASSADOR, registerAmbassador);
}

function* changePassword(action) {
  console.log(action.payload)
  try {
    const json = yield call(api.CHANGE_PASSWORD, action.payload);
    yield put({ type: CHANGE_PASSWORD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* websiteChangePassword() {
  yield takeLatest(SG_CHANGE_PASSWORD, changePassword);
}

export default function* index() {
  yield all([
    websiteLoginUser(),
    websiteLogoutUser(),
    websiteForgotPassword(),
    websiteRegisterUser(),
    websiteRegisterUserToken(),
    websiteRegisterAmbassador(),
    websiteResetPassword(),
    websiteChangePassword(),
  ]);
}
