import {
  GET_MEMBER_FILTERS,
  SET_MEMBER_FILTERS,
  SET_MEMBER_SEARCH_FILTERS,
} from "constants/actions";

const initialState = {
  filteredCategories: [],
  filteredPermissions: [],
  missingCategoriesFilter: false,
  filteredCategoriesId: [],
  search: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MEMBER_FILTERS:
      return {
        ...state,
        filteredCategories: action.payload.filteredCategories,
        filteredPermissions: action.payload.filteredPermissions,
        missingCategoriesFilter: action.payload.missingCategoriesFilter,
        filteredCategoriesId: action.payload.filteredCategoriesId,
        search: action.payload.search,
      };
    case SET_MEMBER_FILTERS:
      return {
        ...state,
        filteredCategories: action.payload.filteredCategories,
        filteredPermissions: action.payload.filteredPermissions,
        missingCategoriesFilter: action.payload.missingCategoriesFilter,
        filteredCategoriesId: action.payload.filteredCategoriesId,
      };
    case SET_MEMBER_SEARCH_FILTERS:
      return {
        ...state,
        search: action.payload.search,
      };
    default:
      return state;
  }
}
