import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import emotion_definitions from "data/emotion_definitions.json";

var dateFormat = require("dateformat");

const Top = styled.div`
  width: 100%;
  height: 150px;
  background-color: ${(props) =>props.color};
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
`;
const Emotion = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
`;

const Definition = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.31;
  color: #ffffff;
`;

const TextContainer = styled.div`
  width: 100%;
  min-height: 180px;
  padding: 12px;
  margin-bottom:15px;
`;

const Container = styled.div`
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 #ececec;
  width: calc(100%- 30px);
  max-width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  margin-right: 24px;
  position:relative;
  margin-top:10px;
 
  @media only screen and (min-width: 1100px) {
    width: 300px;
  }
`;


const Total = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: #696969;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 12px;
  padding-bottom: 12px;
  font-family: "Red Hat Display", sans-serif;
`;

const Date = styled.div`
	position:absolute;
	bottom:10px;
	left:10px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
	color: #9b9b9b;
`

const EmotionComment = (props) =>{

	return (
		<Container>
	      <Top color={emotion_definitions[props.mood].color}>
	        <Emotion>{props.mood}</Emotion>
	        <Definition>{emotion_definitions[props.mood].definition}</Definition>
	      </Top>
	      <TextContainer>
	        {props.comment}
	      </TextContainer>
	      <Date>{props.on_date}</Date>
	    </Container>
		)
}


export default EmotionComment