import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";


const Screen3 = () => {
    const [selected, setSelected] = useState(false);

    return (
        <Container>
            <Question>
                Which outcome factor would you like to optimize?
            </Question>

            <Responses>
                <Response selected={selected==1} onClick={()=>setSelected(1)}>
                    <Name>Productivity</Name>
                    <Description>The productivity score for this group is <strong>66%</strong>, which is <strong>10%</strong> below the global norm. This score has decreased by 4% since the last assessment.</Description>
                </Response>
                <Response selected={selected==2} onClick={()=>setSelected(2)}>
                    <Name>Innovation</Name>
                    <Description>The innovation score for this group is <strong>76%</strong> which is <strong>5%</strong> above the global norm. This score has increased by 2% since the last assessment.</Description>
                </Response>

                <Response selected={selected==3} onClick={()=>setSelected(3)}>
                    <Name>Engagement</Name>
                    <Description>The engagement score for this group is <strong>78%</strong> which is <strong>8%</strong> above the global norm. This score has increased by 1% since the last assessment.</Description>
                </Response>
            </Responses>
        </Container>
    )

}

export default Screen3;

const Container = styled.div`
    width:100%;
    font-family:"Raleway";
`

const Question = styled.div`
    font-size:28px;
    margin-bottom:40px;
    font-weight:600;
    line-height:1.2
`

const Responses = styled.div`
    display:flex;
    flex-direction:column;
`

const Response = styled.div`
    padding:20px;
    border:1px solid #ccc;
    margin-bottom:10px;
    cursor:pointer;

    background-color:${props=>props.selected ? "#f0f0f0" : "white"};
    color:${props=>props.selected ? "#333" : "#666"};
    transition:0.3s;
`

const Name = styled.div`
    font-size:16px;
    font-weight:bold;
`
const Description = styled.div`
    margin-top:10px;
`