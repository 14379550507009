import React,{useState} from 'react';
import styled from 'styled-components';


const Pending = ({title}) =>{

    return (
        <Container>
            <Title>{title} <Status>In Progress</Status></Title>
            <Description>This change agent was deployed on April 23rd.</Description>
            <Row>
                <Button>Send reminder</Button>
                <Progress>2 of 4 responses</Progress>
            </Row>
        </Container>
    )
}

export default Pending;

const Container = styled.div`
width:100%;
border:1px solid #E0E0E0;
border-radius:10px;
padding: 20px 20px;
display:flex;
margin-bottom:10px;
flex-direction:column;
font-family:'Raleway';
cursor:pointer;
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    display:flex;
    width:100%;
    align-items:center;
    justify-content:space-between;
`

const Status = styled.div`
    font-size:14px;
    padding:5px 10px;
    border-radius:5px;
    background-color:#F3F3F3;
    color:#2A3039;
`

const Progress = styled.div`
    font-size:14px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:5px;
`

const Row = styled.div`
display:flex;
margin-top:20px;
align-items:center;
justify-content:space-between;
`

const Button = styled.div`
    background:blue;
    color:white;
    padding:5px 10px;
    border-radius:5px;
    font-size:14px;
    font-weight:600;
`

const Description = styled.div`
    font-size:14px;
    color:#2A3039;
    margin-top:5px;
`