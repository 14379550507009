import styled, { keyframes } from 'styled-components';

import React, { useEffect, useCallback, useState, useRef } from "react";


const ComparisonTable = ({data}) =>{

    const get_max=(info) => {
        return Math.max(...info?.map((item)=>item.score - item.org_score))
    }

    const get_min=(info) => {
        return Math.min(...info?.map((item)=>item.score - item.org_score))
    }


    const [max,setMax] = useState(get_max(data))
    const [min,setMin] = useState(get_min(data))


    useEffect(()=>{
        setMax(get_max(data))
        setMin(get_min(data))
    },[data])
    
    

    return (
        <Card>
            <CardTitle>
                Relative differences in pillar scores
            </CardTitle>

            <Text>
            The table below shows the relative differences in pillar scores between your organization and the selected tag.
            </Text>
            <Container>
            {data?.sort((a,b)=>(b.score - b.org_score) - (a.score - a.org_score)).map((item,index)=>{
               let score = item.score - item.org_score
               let width = score > 0 ? (score/max)*100 : (score/min)*100
               let offset = score > 0 ? 0 : -width
               return (
                     <Row>
                        <Title>{item.name}</Title>
                        <Block>
                            <Score score={score} width={width} offset={offset}>
                                <Number side={score>0}>{(score*10).toFixed(1)}%</Number>
                            </Score>
                        </Block>
                    </Row>
               )
            })}
            <Line>
                <LineTitle>Org average</LineTitle>
            </Line>
        </Container>
        </Card>
        
    )
}

export default ComparisonTable

const Card = styled.div`
    width:60%;
    font-family: Raleway;
    margin-top:30px;
    
`

const CardTitle = styled.div`
    font-size:20px;
    color:#2A3039;
    font-weight:600;
    margin-bottom:10px;
`

const Container = styled.div`
    position:relative;
   
    width:100%;
`

const Row = styled.div`
    display:flex;
    width:100%;
    margin-top:10px;

`

const Title = styled.div`
    width:175px;
    color:#666D79;
    height:15px;
    display:flex;
    align-items:center;
`

const Block = styled.div`
    width:calc(100% - 175px);
    position:relative;
`

const growWidth = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${props => props.width / 2}%;
    }
`;


// For positive scores
const growRight = keyframes`
    0% {
        width: 0%;
    }
    100% {
        width: ${props => props.width / 2}%;
    }
`;


// For negative scores
const growLeft = keyframes`
    0% {
        width: 0%;
        left: 50%;
    }
    100% {
        width: ${props => props.width / 2}%;
        left: calc(50% - ${props => props.width / 2}% + ${props => props.offset / 2}%);
    }
`;



const Score = styled.div`
    width:${props=>props.width/2}%;
    position:absolute;
    left:calc(50% + ${props=>props.offset/2}%);
    height:15px;
    background-color:${props=>props.score > 0 ? "#27CDA7" : "#F15062"};
    opacity: 0.5;
    border-radius: ${props=>props.score > 0 ? "0px 8px 8px 0px" :"8px 0px 0px 8px"};
    display:flex;
    align-items:center;
    justify-content:center;
    animation: ${props => props.score > 0 ? growRight : growLeft} 0.5s forwards ease;
    top:0px;
`

const Line = styled.div`
    height:102%;
    width:2px;
    background-color:#DFDFDF;
    position:absolute;
    left:calc(175px + (100% - 175px) / 2 - 1px);
    top:-5px;
`

const LineTitle = styled.div`
    position:absolute;
    left:-100px;
    display:flex;
    justify-content:center;
    top:-20px;
    width:200px;
    font-size:10px;
    font-weight:bold;
`

const Number = styled.div`
    position:absolute;
    left:${props=>props.side?'':'-40px'};
    right:${props=>props.side?'-40px':''};
    font-size:12px;
`

const Text = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
margin-top:10px;
width:100%;
margin-bottom:60px;
`