import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import DatePick from "../DatePicker";
import Metric from "../Metric";
import People from "../People";
import PlanComponent from "../PlanComponent/index";
import Progress from "../Progress";
import Anchor from "./Anchor";
import Sport from "./Sport";
import planningTemplates from "../templates/planningTemplates.json";
import { Dropdown } from "semantic-ui-react";

const factorsList = [
  {
    key: 1,
    text: "Equity",
    value: "Equity",
  },
  {
    key: 2,
    text: "Inclusion",
    value: "Inclusion",
  },
  {
    key: 3,
    text: "Accessibility",
    value: "Accessibility",
  },
];

const PlanSelection = ({
  categories,
  questions,
  data,
  setData,
  setSelectedPlan,
  selectedPlanId,
  disabledDropdown,
  alternateFocus,
  selectedFactors,
  selectedQuestion,
  filteredCategories,
}) => {
  const [questionsList, setQuestionsList] = useState();

  useEffect(() => {
    if (questions) {
      let questionsTemp = [];
      questions?.questions?.dimensions?.forEach((dimension, dimensionIndex) => {
        // Loop through factors.
        dimension?.factors?.forEach((factor, factorIndex) => {
          // Loop through questions.
          factor?.questions?.forEach((question, questionIndex) => {
            // Add question to questionsList.
            questionsTemp.push({
              text: question?.q,
              key: `${dimensionIndex}-${factorIndex}-${questionIndex}`,
              value: {
                dimension: dimensionIndex,
                factor: factorIndex,
                question: questionIndex,
              },
            });
          });
        });
      });
      setQuestionsList(questionsTemp);
    }
  }, []);

  const setFactor = (d) => {
    const _data = Object.assign({}, data);
    _data.metric = d;
    setData(_data);
  };

  const setQ = (q) => {
    const _data = Object.assign({}, data);
    _data.question = q === -1 ? [-1] : [q];
    setData(_data);
  };

  const setPrimary = (d) => {
    const _data = Object.assign({}, data);
    _data.primary = d;
    setData(_data);
  };

  const setPlan = (d) => {
    const _data = Object.assign({}, data);
    _data.plan = { id: d };
    setData(_data);
  };

  // Hardcode id since this is the only plan that has this config.
  const onSelectFactors = (factors) => {
    const _data = Object.assign({}, data);
    _data.planData[1] = factors;
    setData(_data);
  };

  const onSelectQuestion = (question) => {
    const _data = Object.assign({}, data);
    _data.planData[2] = question;
    setData(_data);
  };

  return (
    <Container>
      <PlanComponent
        plans={planningTemplates}
        selected={data?.plan?.id}
        setSelected={(d) => {
          setPlan(d);
          setSelectedPlan(d);
        }}
      />

      <Sport
        categories={categories}
        selected={data.primary}
        filteredCategoriesProp={filteredCategories}
        setSelected={(d) => setPrimary(d)}
        editEnabled={true}
      />
      {!alternateFocus && (
        <Metric
          questions={questions}
          selectedFactor={data.metric}
          selected2={data.question || [data?.planData[2]?.value]}
          setSelected={(d) => setFactor(d)}
          setSelected2={(d) => setQ(d)}
          onSelectQuestion={(d) => {
            if (selectedPlanId === 4) {
              onSelectQuestion(d);
            }
          }}
          disableDropdown={disabledDropdown}
          altPrompt={selectedPlanId === 4}
          removeAllQuestions={selectedPlanId === 4}
        />
      )}
      {selectedPlanId === 4 && (
        <AltSection>
          <Title>Focus</Title>
          <Description>What is the focus of this plan?</Description>
          <Dropdown
            placeholder="Select focus"
            fluid
            selection
            search
            multiple
            options={factorsList}
            value={selectedFactors || data?.planData[1]}
            onChange={(e, t) => {
              onSelectFactors(t.value);
            }}
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: "8px",
              border: "1px solid",
              borderColor: "#e0e0e0",
              minWidth: "250px",
              maxWidth: "400px",
            }}
          />
        </AltSection>
      )}
    </Container>
  );
};

export default PlanSelection;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding-bottom: 150px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #2a3039;
  line-height: 21px;
  margin-right: 15px;
  margin-top: 20px;
`;

const Description = styled.div`
  font-size: 13px;
  line-height: 25px;
  color: #2a3039;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #414141;
`;
const TextArea = styled.textarea`
  height: 150px;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
`;

const AltSection = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;