import React, { useState } from "react";
import { Header, Message, Modal } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled, { keyframes } from "styled-components";


import {
  UPDATE_EMPLOYEE,
  SG_ADD_PERSONALITY_TEAM,
} from "constants/actions";

const CancelButton = styled.div`
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
`;
const P = styled.p`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;

const Button = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-left: 10px;
`;

const DisabledButton = styled(Button)`
  height: 45px;
  border-radius: 8px;
  background-color: #d0d0d0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-left: 10px;
`;

const SubText = styled.div`
  font-size: 13px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  margin-bottom: 30px;
`;

function AddTeam(props) {
  const apiDispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [team, setTeam] = useState(false);
  const teamList = useSelector((state) => state.teams.teams).map((item, i) => ({
    key: item.id,
    text: item.name,
    value: item.id,
  }), shallowEqual);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const { get_personality_teams } = useSelector(
    (state) => ({
      get_personality_teams: state.personalityteams.personality_teams,
    }),
    shallowEqual
  );

  const _ParticipantList = [];
  get_personality_teams.map((p) => _ParticipantList.push(p.team));

  const _ParticipantNonTeamList = [];
  if (get_personality_teams) {
    teamList
      .filter((o1) => !_ParticipantList.includes(o1.key))
      .map((p) => _ParticipantNonTeamList.push(p));
  }

  const save = () => {
    selectedTeams.map((team) =>
      apiDispatch({
        type: SG_ADD_PERSONALITY_TEAM,
        payload: {
          organization: props.organization,
          team: team,
        },
      })
    );
      setOpen(false)
      setTeam(false)
    // setOpen(true);
    setSelectedTeams([]);
  };

  const close = () => {
    setOpen(false);
    setTeam(false);
  };

  const onChange = (e, data) => {
    setSelectedTeams(data.value);
  };

 
  return (
    <Modal
      onClose={() => close()}
      onOpen={() => setOpen(true)}
      open={open}
      size={"tiny"}
      closeIcon
      trigger={props.trigger}
    >
      <Modal.Header>
        Invite Team's to the Personality Assessment Tool
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <P>
            Select one or multiple teams to use our personality analytics tool,
            where people learn not only about their own personality, but also
            how their personality interacts with others based on the most
            reliable personality metrics available.
          </P>

          <Dropdown
            placeholder="Select Team(s)"
            fluid
            multiple
            search
            selection
            defaultValue={selectedTeams}
            onChange={onChange}
            options={_ParticipantNonTeamList}
            style={{ marginBottom: 30 }}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CancelButton onClick={() => setOpen(false)}>Cancel</CancelButton>
          {selectedTeams.length > 0 ? (
            <Button onClick={save}>Invite</Button>
          ) : (
            <DisabledButton>Invite</DisabledButton>
          )}
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default AddTeam;
