import React from 'react'
import { Routes, Route } from 'react-router-dom';
import QueryChatGpt from './QueryChatGpt';

function ChatGptPages() {

  return (
    <div>
      {/* <nav>
        <Link to="create-blog">My Profile</Link>
      </nav> */}

      <Routes>
        <Route path="/" element={<QueryChatGpt />} />
       
      </Routes>
    </div>
  );
}

export default ChatGptPages;