import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import { Dimmer, Message, Header, Segment, Portal } from "semantic-ui-react";

import { format, formatDistance, subDays } from "date-fns";
import getISODay from "date-fns/getISODay";

import {
  SG_GET_SURVEY_SETTING_LIST,
  SG_GET_GLOBAL_SCHEDULES,
  SG_ADD_GLOBAL_SCHEDULE,
  SG_EDIT_GLOBAL_SCHEDULE,
  PULL_ORGANIZATION,
  SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
  SG_GET_SURVEY_COMPLIANCES,
  LOAD_TEAMS,
  LOAD_EMPLOYEES,
  SG_GET_LEADERS_IN_SCHEDULES,
  SG_GET_TEAMS_IN_SCHEDULES
} from "constants/actions";

import StartIntelligence from "./Components/StartIntelligence";

import Intelligence from "./Components/Intelligence";
import SurveyFrequency from "./Components/SurveyFrequency";
import SurveyDate from "./Components/SurveyDate";
import DateOfSurvey from "./Components/DateOfSurvey";
import TimeOfSurvey from "./Components/TimeOfSurvey";
import UpdateTimeZone from "./Components/UpdateTimeZone";
import CloseEvery from "./Components/CloseEvery";
import Teams from "./Components/Teams";

const options = [
  {
    id: 1,
    title: "Team Intelligence Survey",
    description:
      "Choose whether the team intelligence survey is on or off. When on is selected, the team intelligence survey will be automatically delivered according to the schedule you have set. When it is set to off, the team intelligence survey will not be scheduled.",
    Component: Intelligence,
  },
  {
    id: 2,
    title: "Frequency",
    description:
      "Choose how frequently you would like the team intelligence survey to be delivered",
    Component: SurveyFrequency,
  },
  {
    id: 3,
    title: "Day of the Week",
    description:
      "Choose which day of the week you would like the team intelligence survey to be set for",
    Component: DateOfSurvey,
  },
  {
    id: 4,
    title: "Time of Day",
    description:
      "Choose what time of the day you would like the team intelligence survey to be sent",
    Component: TimeOfSurvey,
  },
  {
    id: 5,
    title: "Time Zone",
    description:
      "Choose which time zone you would like the survey time to be set for",
    Component: UpdateTimeZone,
  },
  {
    id: 6,
    title: "When to close the survey",
    description:
      "Choose when you would like the survey to end. Ending the survey generates the a report and survey recipients can no longer complete the survey",
    Component: CloseEvery,
  },
  {
    id: 7,
    title: "Date of next survey",
    description:
      "This is the next scheduled date for the team intelligence survey. You may choose a new date if the current date is not appropriate",
    Component: SurveyDate,
  },
  {
    id: 8,
    title: "Teams to include in this survey",
    description:
      "By default all new teams are added to this survey. You may edit which teams are selected to receive the team intelligence survey. Simply unselect any teams you wish to exclude from this survey.",
    Component: Teams,
  },
];

const DayOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const Row = (props) => {
  const { tableValues } = props;
  return (
    <RowContainer last={props.i + 1 === options.length}>
      <TextContainer>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </TextContainer>
      <StyledButton onClick={props.OpenPortal}>
        {tableValues[props.i]} {props.i == 5 ? 'hrs' : ''}
      </StyledButton>
    </RowContainer>
  );
};

const SurveyTypes = [1, 2]; // Culture and Mood Survey type

const SettingsTable = ({ AmbassadorId }) => {
  const dispatch = useDispatch();
  const organization_id = useSelector((state) => state.auth.organization_id);

  // const [orgId, setOrgId] = useState(false)
  const [organizationalId, setOrganizationalId] = React.useState(0)

  const [organizationId, setOrganizationId] = useState(null);
  const [organization, setOrganization] = React.useState("");

  const [globalSchedule, setGlobalSchedule] = useState([]);

  const [intelligence, setIntelligence] = useState([]);
  // const [leadership, setLeadership] = useState([]);
  const [intelligenceFreq, setIntelligenceFreq] = useState([]);
  const [tableValues, setTableValues] = useState([]);
  const [teamsIncluded, setTeamsIncluded] = useState(0);

  const [changeDateofWeek, setChangeDateOfWeek] = useState(new Date());
  const [teamsChanged, setTeamsChanged] = useState(false);
  const [firstTime, setFirstTime] = useState(false)

  const [dateChanged, setDateChanged] = useState(false)

  const [surveyDateList, setSurveyDateList] = useState([])

  const org_id = useSelector((state) => state.auth.organization_id);

  // const EmployeeId = useSelector((state) => state.auth?.employee_id, shallowEqual)
  // const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)
  const selectedOrg = useSelector((state) => state.selectedOrg, shallowEqual)

  React.useEffect(() => {
    setOrganizationalId(selectedOrg?.organization?.id ?
      selectedOrg?.organization?.id :
      org_id)
  }, [selectedOrg, org_id]);

  // useEffect(() => {
  //   setOrgId(organizationalId ? organizationalId : organization_id)
  // }, [organizationalId, organization_id]);

  React.useEffect(() => {
    // if the response is ok 200
    if (organizationalId) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organizationalId },
      });
    }
  }, [organizationalId, dispatch]);

  const { get_organization } = useSelector(
    (state) => ({
      get_organization: state.organizations,
    }),
    shallowEqual
  );

  const {
    get_teams,
    get_selected_teams,
    get_global_schedules,
    // get_global_schedule_updated,
    get_survey_compliances,
    get_global_schedule_frequencies,
    get_settings_list,
    get_auth,
    get_selectedOrg,
  } = useSelector(
    (state) => ({
      get_teams: state.teams.teams,
      get_selected_teams: state.teamsinschedule.teams_in_schedules,
      get_global_schedules: state.surveyschedule.global_schedules,
      // get_global_schedule_updated: state.surveyschedule.updated,
      // get_global_schedule_frequencies_updated:
      //   state.surveyschedulefrequency.global_schedule_frequencies.updated,
      get_global_schedule_frequencies:
        state.surveyschedulefrequency.global_schedule_frequencies,
      get_survey_compliances: state.surveycompliance.survey_compliances,
      get_auth: state.auth,
      get_settings_list: state.surveysettinglist.survey_setting_list,
      get_selectedOrg: state.selectedOrg
    }),
    shallowEqual
  );
  React.useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      if (Number(organizationalId) > 0) {
        // console.log("organizationalId", organizationalId)
        dispatch({
          type: SG_GET_GLOBAL_SCHEDULES,
          payload: `organization=${organizationalId}`
        });
      }
    } else {
      dispatch({
        type: SG_GET_GLOBAL_SCHEDULES,
      });
    }

  }, [dispatch, AmbassadorId, organizationalId]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      if (Number(organizationalId) > 0) {
        // console.log("organizationalId", organizationalId)
        dispatch({
          type: SG_GET_SURVEY_SETTING_LIST,
          payload: `organization=${organizationalId}`
        });
      }
    } else {
      dispatch({
        type: SG_GET_SURVEY_SETTING_LIST,
      });
    }

  }, [
    dispatch, AmbassadorId, intelligence,
    organizationalId,
    // organization,
    // organizationId,
    // globalSchedule,
    // intelligenceFreq,
    changeDateofWeek,
    // setChangeDateOfWeek,
    // setTeamsChanged,
    // teamsChanged,
    dateChanged,
    // setDateChanged,
    // surveyDateList
  ]);

  useEffect(() => {
    // if the response is ok 200
    // dispatch({
    //   type: SG_GET_GLOBAL_SCHEDULES,
    // });
    // dispatch({
    //   type: SG_GET_SURVEY_COMPLIANCES,
    //   payload: "limit=30",
    // });
    dispatch({
      type: LOAD_TEAMS,
      payload: organizationalId ? `organization=${organizationalId}` : ''
    });
    dispatch({
      type: LOAD_EMPLOYEES,
      payload: organizationalId ? `organization=${organizationalId}` : ''
    })

    dispatch({
      type: SG_GET_LEADERS_IN_SCHEDULES,
      payload: organizationalId ? `organization=${organizationalId}` : ''
    });

    dispatch({
      type: SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
      payload: organizationalId ? `organization=${organizationalId}` : ''
    });
  }, [dispatch, organizationalId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_COMPLIANCES,
      payload: `limit=30${organizationalId ?
        `&organization=${organizationalId}` : ''}`
    });
  }, [dispatch, organizationalId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_TEAMS_IN_SCHEDULES,
      payload: organizationalId ? `organization=${organizationalId}` : ''
    });
  }, [dispatch, organizationalId]);

  // useEffect(() => {
  //   if (globalSchedule?.id) {
  //     dispatch({
  //       type: SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
  //       payload: `global-schedule=${globalSchedule.id}`,
  //     });
  //   }
  // }, [dispatch, globalSchedule]);
  // console.log("teamsChanged", teamsChanged? "true" : "false")

  // console.log(dateChanged)

  useEffect(() => {
    if (get_organization) {
      setOrganization(get_organization[organizationalId]);
    }
  }, [get_organization, organizationalId]);

  useEffect(() => {
    // setGlobalSettings(get_global_schedules);

    setOrganizationId(Number(get_selectedOrg?.organization?.id) > 0 ?
      Number(get_selectedOrg?.organization?.id) :
      Number(get_auth?.organization_id)
    );

    setGlobalSchedule(get_global_schedules);
    setChangeDateOfWeek(get_global_schedules?.day);

    if (get_global_schedule_frequencies) {
      setIntelligenceFreq(
        get_global_schedule_frequencies.filter((gs) =>
          SurveyTypes.includes(gs.survey_type)
        )
      );
    }
    if (get_survey_compliances) {
      // console.log(get_survey_compliances);
      setIntelligence(
        get_survey_compliances.filter(
          (gs) => SurveyTypes.includes(gs.survey_type) && gs.status === 1
        )
      );
      // setDateChanged(
      //   get_survey_compliances.filter(
      //     (gs) => SurveyTypes.includes
      //     (gs.survey_type) && gs.status === 1
      //   )[0]?.scheduled_at
      // )
    }
  }, [
    get_global_schedules,
    get_survey_compliances,
    get_global_schedule_frequencies,
    get_auth,
    get_selectedOrg,
  ]);

  // console.log(organizationalId)
  // console.log(get_selected_teams)
  // console.log(get_auth)
  // console.log(orgId)

  useEffect(() => {
    // console.log("Ran this")
    setTeamsIncluded(
      `${get_selected_teams.filter((p) => p.survey_type === 1).length} of ${get_teams.length}`
    );
  }, [get_teams,
    get_selected_teams,
    teamsChanged,])

  const [processing, setProcessing] = React.useState(false);
  const [openPortal, setOpenPortal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(false);

  function ClosePortal() {
    // console.log("portal closed");
    setOpenPortal(false);
    setProcessing(false);
    setTeamsChanged(!teamsChanged)
    setChangeDateOfWeek(!changeDateofWeek)
    // setStartSettings(false);
  }

  function OpenPortal(e) {
    setOpenPortal(e);
    setProcessing(true);
  }

  function PrevComponent(e) {
    // console.log(e);
    // console.log(openPortal);
    if (e - 1 <= 0) {
      return;
    }
    if (openPortal) {
      setSelectedRow(options.find(({ id }) => id === e - 1));
    }
  }
  function NextComponent(e) {
    // console.log(e);
    // console.log(openPortal);
    if (intelligence.length === 0) {
      return;
    }

    if (e + 1 > 8) {
      if (firstTime) {
        // start global schedule
        // active
        dispatch({
          type: SG_EDIT_GLOBAL_SCHEDULE,
          payload: {
            id: globalSchedule.id,
            active: 2
          },
        });
        setFirstTime(false)
        setOpenPortal(false);
        setProcessing(false);
      } else {
        return;
      }
    }
    if (openPortal) {
      setSelectedRow(options.find(({ id }) => id === e + 1));
    }

    setTeamsChanged(!teamsChanged)
    setChangeDateOfWeek(!changeDateofWeek)
  }

  useEffect(() => {
    if (openPortal) {
      setSelectedRow(options.find(({ id }) => id === openPortal));
    }
  }, [openPortal]);

  React.useEffect(() => {
    const _rowValues = [];
    if (get_settings_list?.active === 2) {
      _rowValues.push(get_settings_list?.active === 2 ? "On" : "Off");
      _rowValues.push(get_settings_list?.frequency_text);
      _rowValues.push(DayOfWeek[get_settings_list?.day_of_week]);
      _rowValues.push(`${get_settings_list?.time_of_day}:00`);
      _rowValues.push(get_settings_list?.time_zone);
      _rowValues.push(get_settings_list?.close_in_hrs);
      _rowValues.push(
        `${get_settings_list?.date_of_next_survey &&
        format(new Date(get_settings_list?.date_of_next_survey), "dd MMM yyyy EEEE")
        }`
        // ${DayOfWeek[getISODay(new Date(get_settings_list?.date_of_next_survey))-1]}`
      );
      _rowValues.push(`${teamsIncluded} Teams`);
    }

    setTableValues(_rowValues);
    setSurveyDateList(get_settings_list?.survey_dates)

  }, [get_settings_list,
    intelligence,
    globalSchedule,
    intelligenceFreq,
    organization,
    teamsIncluded,
    teamsChanged
  ]);

  const [once, setOnce] = React.useState(false);

  // 

  // console.log(tableValues)
  // console.log(get_settings_list?.date_of_next_survey)
  // console.log(globalSchedule?.active === 2)

  // console.log(format(new Date(intelligence[0]?.scheduled_at), "dd MMM yyyy (HH) EEEE"))
  // console.log(getISODay(new Date(intelligence[0]?.scheduled_at.toLocaleString())))

  React.useEffect(() => {
    if (!get_global_schedules) {
      if (!once) {
        const todayDate = new Date()
        if (organizationalId) {
          dispatch({
            type: SG_ADD_GLOBAL_SCHEDULE,
            payload: {
              active: 1,
              starting_from: todayDate,
              day: todayDate.getDay(),
              hour: todayDate.getHours(),
              minute: 0,
              organization: Number(organizationalId),
            },
          });
          setOnce(true)
        }

      }
    }
  }, [dispatch, get_global_schedules, organizationalId, once])
  // console.log(intelligence)
  if (globalSchedule && globalSchedule?.active === 2 && get_settings_list?.date_of_next_survey === null) {
    return <StartIntelligence globalSchedule={globalSchedule}
      setProcessing={setProcessing}
      setFirstTime={setFirstTime}
      OpenPortal={OpenPortal}
      setSelectedRow={setSelectedRow}
    />
  }

  return (
    <StyledContainer>
      <Dimmer.Dimmable blurring dimmed={processing}>
        {/* <Dimmer active={processing} inverted>
					<Loader>Updating please wait ...</Loader>
				</Dimmer> */}
        {globalSchedule && globalSchedule?.active !== 2 ? (
          <StartIntelligence globalSchedule={globalSchedule}
            setProcessing={setProcessing}
            setFirstTime={setFirstTime}
            OpenPortal={OpenPortal}
            setSelectedRow={setSelectedRow}
          />
        ) : (
          options.map((item, i) => {
            return (
              <Row
                key={i}
                title={item.title}
                description={item.description}
                // component={item.component}
                i={i}
                OpenPortal={() => OpenPortal(item.id)}
                tableValues={tableValues}
              />
            );
          })
        )}
      </Dimmer.Dimmable>
      {/* <Portal 
			// closeOnPortalMouseLeave={false}
			// closeOnTriggerMouseLeave={false}
			// onClose={ClosePortal}
			 open={openPortal}> */}
      <Modal
        closeIcon
        centered={false}
        open={openPortal ? true : false}
        // trigger={props.trigger}
        onClose={() => ClosePortal(false)}
        onOpen={() => OpenPortal(true)}
        size="small"
        style={{ top: 100 }}
      >
        <Header as="h2">{selectedRow?.title}</Header>
        {/* <AllErrors /> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: 25,
            flexDirection: "column",
            position: "relative",
            minHeight: 150,
          }}
        >

          <AreaDescription>{selectedRow?.description}</AreaDescription>

          {/* {selectedRow?.Component} */}
          {ComponentFactory(selectedRow?.Component, {
            intelligence,
            organization,
            organizationId,
            globalSchedule,
            intelligenceFreq,
            changeDateofWeek,
            setChangeDateOfWeek,
            setTeamsChanged,
            teamsChanged,
            dateChanged,
            setDateChanged,
            surveyDateList
          })}

        </div>


        {firstTime ?
          <div
            style={{
              display: "flex",
              padding: 10,
              // alignItems: "center",
              justifyContent: "space-between",
              // flexDirection: "column",
              // minHeight: 50,
            }}
          >
            <Button
              disabled={selectedRow?.id === 1}
              onClick={() => PrevComponent(selectedRow?.id)}
            >
              Prev Step
            </Button>
            <StyledButton
              positive
              onClick={() => NextComponent(selectedRow?.id)}
            >
              {selectedRow?.id === 8 ? "Complete" : "Next Step"}
            </StyledButton>
          </div>
          :
          <div
            style={{
              display: "flex",
              padding: 10,
              // alignItems: "center",
              justifyContent: "flex-end",
              // flexDirection: "column",
              // minHeight: 50,
            }}
          >
            <StyledButton
              positive
              // disabled={selectedRow?.id === 8}
              onClick={() => ClosePortal()}
            >
              Update
            </StyledButton>
          </div>
        }



      </Modal>
    </StyledContainer>
  );
};

export default SettingsTable;

function ComponentFactory(Component, props) {
  // let FinalComponent = Component
  // console.log(FinalComponent)
  // console.log(props);
  if (Component) {
    return <Component {...props} />;
  }
}

const StyledContainer = styled.div`
  width: 100%;
`;

const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-bottom: ${(props) => (props.last ? "1px solid #e9e9e9" : "")};
  padding: 20px;
`;

const Title = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Description = styled.div`
  font-family: "Red Hat Display", sans-serif;
`;

const TextContainer = styled.div`
  max-width: 800px;
`;

export const StyledButton = styled.div`
  padding: 15px;
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const AreaDescription = styled.div`
   font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  line-height:1;
  margin-bottom:10px;
`
