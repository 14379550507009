import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import {process_feedback_data} from './calculations'
import DataTypeDropdown from './DataTypeDropdown';
import IMG from 'assets/images/UI/Conversation.svg'


const Feedback = ({data,core_data,outcomeQ}) =>{

    const [feedback,setFeedback] = useState(process_feedback_data(data))

    const getFactorName = (factor_id,dimension) =>{
        return core_data?.questions.dimensions[dimension].factors.find(f=>f.id==factor_id+1)?.title
    }

    return (
        <Container>

           

           


                <Title>Feedback</Title>
                <Description>
                   Access all the feedback from the culture survey. Sort and filter according to the feedback your are the most interested in, and ask the copilot for help in summarizing.
                </Description>

                <DataTypeDropdown outcomes={outcomeQ} core_data={core_data}/>
            {feedback.map((item,i)=>{
                return (
                    <Row key={i}>
                          <Tags>
                                <Tag>{item.type}</Tag>
                                <Tag>{getFactorName(item.factor,item.dimension)}</Tag>
                                
                            </Tags>
                            <Q>{item.follow_up_question? item.follow_up_question :item.question}</Q>
                            <Text>{item.feedback}</Text>
                            <Action>See full conversation</Action>

                          

                    </Row>
                )
            })}
        </Container>
    )
}

export default Feedback;


const Container = styled.div`
    width:100%;
    position:relative;
`

const Row = styled.div`
    width:100%;
    max-width:800px;
    display:flex;
    flex-direction:column;
    border:1px solid #f0f0f0;
    position:relative;
    padding:20px;
`

const TR = styled.div`
    width:${props=>props.width}%;
    display:flex;
    flex-direction:column;
    padding:20px;
`
const Q = styled.div`
    font-weight:bold;
    margin-bottom:10px;
    max-width:calc(100% - 200px);
`

const Text = styled.div`
    font-size:14px;
    line-height:1.4;
    color:#666;
    margin-bottom:10px;
    max-width:calc(100% - 200px);
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`

const Description = styled.div`
    margin-bottom:30px;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:12px;
    font-weight:bold;
    margin-bottom:10px;
`

const Score = styled.div`
    position:absolute;
    right:0;
    
`

const Tag = styled.div`
    color:#524C71;
    font-weight:600;
    font-size:10px;
    background-color:#F8FAFF;
    padding:4px 8px;
    border-radius:5px;
    display:inline-block;
    margin-right:5px;
`

const Image = styled.img`
    width:30px;
    margin-right:40px;
`

const Tags = styled.div`
    position:absolute;
    top:20px;
    right:20px;
`

const Action = styled.div`
    color:#2D70E2;
    font-size:12px;
    cursor:pointer;
    margin-top:-10px;
    width:100%;
    display:flex;
    justify-content:flex-end;
`

const TableTitle = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
    margin-top:20px;
`