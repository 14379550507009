import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Header, Message, Modal, Input, List, Button,Icon } from 'semantic-ui-react'
import RoleTable from './RoleTable'

const TeamTypes = [
	{'title':'Two-directional',
	  'roles':[{
	  	'name':'Team Member',
	  	access:[1,1,1,1,0,1,0]
	  },
	  {
	  	'name':'Team Leader',
	  	access:[1,1,1,1,0,1,1]
	  }]},
	  {'title':'One-directional',
	  'roles':[{
	  	'name':'Team Member',
	  	access:[1,0,0,0,0,0,0]
	  },
	  {
	  	'name':'Team Leader',
	  		access:[0,1,1,1,0,1,1]
	  }]},
	  {'title':'One-directional Anonymous',
	  'roles':[{
	  	'name':'Team Member',
	  	access:[1,0,0,0,0,0,0]
	  },
	  {
	  	'name':'Team Leader',
	  	access:[0,1,1,1,1,0,1]	  	
	  }]},
	  
	  {'title':'Two-directional Limited',
	  'roles':[{
	  	'name':'Team Member',
	  	access:[1,1,1,1,1,0,0]
	  },
	  {
	  	'name':'Team Leader',
	  	access:[1,1,1,1,0,1,1]
	  }]},
	  
]


const Learn = (props) => {

	return (
		<>
		<StyledHeader><Icon name="chevron left" onClick={()=>props.back()} style={{cursor:'pointer'}}/>{TeamTypes[props.type-1].title} Team Type</StyledHeader>

	      <Modal.Content>
	        <Modal.Description>
	        <div style={{width:'100%',display:'flex',alignItems:'center',flexDirection:'column'}}>
	        <div style={{maxWidth:800}}>
		        <Title>What you need to know about the roles for this team type</Title>
		        <Description>Roles help you define what access a member should have when you invite them to innerlogic in this particular team. This team type will have a default role, which all members invited to this team will be given by default.</Description>
		        <Title>Changing team types and roles</Title>
		        <Description>At any time, you can change a team's type. Simple go to that team under team management and select edit in the top right corner menu. Each team member in that team will be automatically converted to the new team type roles, including team leaders. 
		        You can also change any individual team member roles by select manage and edit in the team member management tab, or in the employee manegement tab. </Description>
		        <RoleTable roles={TeamTypes[props.type-1].roles}/>
		    </div>
		    </div>
	        </Modal.Description>
	      </Modal.Content>
	    </>
		)
}

export default Learn


const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:30px;
	max-width:800px;
	font-size:16px;
	line-height:1.4;

	@media (max-width:768px){
		font-size:16px;
	}
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:500;
	margin-bottom:10px;
`

const StyledHeader = styled(Modal.Header)({
    backgroundImage: 'linear-gradient(45deg,rgba(0, 176, 240,0.3),rgba(0, 187, 255,0.3))!important'
});