import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import ActionList from "./ActionList";
import Plan from "./Plan";
import Report from "./Report/index";
import Overview from "./Overview/index";

const Action = () => {
	const [selected, setSelected] = useState(null);
	const [plans, setPlans] = useState([]);

	const addPlan = (p) => {
		setPlans([...plans, p]);
	};

	return (
		<div>
			<Overview />
			{/*{selected == 1 ? (
				<Plan
					save={() => setSelected(null)}
					addPlan={(p) => addPlan(p)}
				/>
			) : selected >= 2 ? (
				<Report
					back={() => setSelected(null)}
					data={plans[selected - 2]}
				/>
			) : (
				<ActionList setSelected={(s) => setSelected(s)} plans={plans} />
			)}*/}
		</div>
	);
};

export default Action;
