import styled, { keyframes } from "styled-components";
import React from "react";
import { Popup } from 'semantic-ui-react'

const SquareComponent = ({width,color,opacity}) =>{

	return (
		<Popup 
			content={'Score:' + Math.floor(opacity*100)+'%'}
			position='top center'
			inverted
			trigger={<Square width={width} color={color} opacity={opacity}/>} />
		
		)
}

const HeatMap = () =>{
	const NUM_ROLES = 5
	const COLOR = '#4287f5'


	return (
		<Container>

			

			<TopRow>
				<div style={{width:'100px'}}/>
				<TH width={NUM_ROLES}>Role 1</TH>
				<TH width={NUM_ROLES}>Role 2</TH>
				<TH width={NUM_ROLES}>Role 3</TH>
				<TH width={NUM_ROLES}>Role 4</TH>
				<TH width={NUM_ROLES}>Role 5</TH>
			</TopRow>
			<Row>
				<Title>Team A</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team B</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team C</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team D</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team E</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team F</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team G</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>

			<Row>
				<Title>Team H</Title>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
				<SquareComponent width={NUM_ROLES} color={COLOR} opacity={Math.random()}/>
			</Row>


			<Legend>
				<LI>
					under 50%
					<Color opacity={0.2} color={COLOR}/>
				</LI>
				<LI>
					50-60%
					<Color opacity={0.4} color={COLOR}/>
				</LI>
				<LI>
					60-75%
					<Color opacity={0.6} color={COLOR}/>
				</LI>
				<LI>
					75-90%
					<Color opacity={0.8} color={COLOR}/>
				</LI>
				<LI>
					90-100%
					<Color opacity={1} color={COLOR}/>
				</LI>
			</Legend>
		</Container>
		)
}


export default HeatMap


const Container = styled.div`
	width:100%;
	max-width:600px;
	margin-top:50px;
	font-family: "Barlow Semi Condensed", sans-serif;
	align-items:center;
	display:flex;
	flex-direction:column;
`

const Title = styled.div`
	width:100px;
`

const Row = styled.div`
	display:flex;
	width:100%;
	justify-content:space-between;
	margin-top:10px;
	align-items:center;
`

const Square = styled.div`
	height:50px;
	width:${props=>'calc((100% - 150px) / '+ props.width+')'};
	background-color:${props=>props.color};
	border-radius:4px;
	opacity:${props=>props.opacity};
	cursor:pointer;
`
const TopRow = styled.div`
	display:flex;
	width:100%;
	margin-top:10px;
	align-items:center;
`

const TH = styled.div`
	width:${props=>'calc((100% - 100px) / '+ props.width+')'};
	justify-content:center;
	display:flex;
`

const Legend = styled.div`
	display:flex;
	margin-top:40px;
	padding-left:100px;
`

const LI = styled.div`
	display:flex;
	flex-direction:column;
	align-items:center;
	margin-right:5px;
`

const Color = styled.div`
	background-color:${props=>props.color};
	height:15px;
	width:80px;
	border-radius:5px;
	opacity:${props=>props.opacity};
`