import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown, Loader } from "semantic-ui-react";

const calculate_options = (categories) => {
  return categories.categories
    .find((f) => f.priority == "primary")
    .options.map((i) => {
      return {
        key: i.name,
        text: i.name,
        value: i.id,
      };
    });
};

const People = ({
  categories,
  selected,
  setSelected,
  setSportOptions,
  isAdmin,
  filteredCategories,
  empCategoryList,
  otpDualView,
  filteredCategoriesProp,
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(calculate_options(categories));
  const [primaryLabel, setPrimaryLabel] = useState();
  // const { filteredCategories, isAdmin, empCategoryList, otpDualView } =
  //   useGetUserSettings();

  useEffect(() => {
    if (categories) {
      const primary = categories?.categories?.find(
        (c) => c.priority === "primary"
      );

      const filteredPrimaryOptions =
        (!isAdmin &&
          primary.options.filter((po) => {
            if (filteredCategoriesProp) {
              return filteredCategoriesProp?.includes(po.value_id);
            } else {
              return filteredCategories?.includes(po.name);
            }
          })) ||
        primary.options;

      if (primary) {
        let options = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((cat) => {
            if (
              (!cat.subfactors && cat.name) ||
              (cat.subfactors.length === 0 && cat.name)
            ) {
              options.push({
                key: cat.name,
                text: cat.name,
                value: cat.id,
              });
            }
          });
        }

        let subfactors = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
              });
            }
          });
        } else {
          primary.options.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
              });
            }
          });

          subfactors = subfactors.filter((sf) => {
            return filteredCategoriesProp?.includes(sf.value);
          });
        }

        if (subfactors.length > 0) {
          options.push(...subfactors);
        }

        setOptions(options);
      }
    }
  }, [
    categories,
    isAdmin,
    empCategoryList,
    isAdmin,
    filteredCategories,
    otpDualView,
  ]);

  useEffect(() => {
    if (categories && !primaryLabel) {
      const found = categories.categories.find((f) => f.priority === "primary");
      if (found) {
        setPrimaryLabel(found.name);
      }
    }
  }, [categories, primaryLabel]);

  return (
    <Container>
      <Top>
        <Title>Select a {primaryLabel}</Title>
        <Description>
          Please select a sport you would like to target in this report.
        </Description>
      </Top>
      {(options && options.length > 0 && (
        <SelectionContainer>
          <Dropdown
            placeholder="Select a Sport"
            fluid
            selection
            options={options}
            value={selected}
            style={{ fontFamily: "Raleway" }}
            onChange={(e, t) => {
              setSelected(t.value);
              setSportOptions(options);
            }}
          />
        </SelectionContainer>
      )) || (
        <SelectionContainer>
          <Loader inline active size="small" />
        </SelectionContainer>
      )}
    </Container>
  );
};

export default People;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  border-radius: 3px;
`;

const Top = styled.div`
  display: flex;
  flex-direction:column;
  position: relative;
`;

const Description = styled.div`
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
color:#2A3039;

`

const Title = styled.div`
font-size: 18px;
font-weight: bold;
color: #4a4a4a;
margin-bottom: 5px;
fonmt-family:"Raleway";
margin-top:20px;
`;


const IconContainer = styled.div`
  position: absolute;
  right: 10px;
`;

const SelectionContainer = styled.div`
  display: flex;
  margin-top: 10px;
  border-radius: 100px;
  max-width: 400px;
`;

const Selected = styled.div``;

const templateOptions = [
  {
    key: "Strategic Gap Analysis",
    text: "Strategic Gap Analysis",
    value: "Strategic Gap Analysis",
  },
];
