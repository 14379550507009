import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

const get_total = (d) => {
    let total = 0
    if(d.min_summary.length>0){
        total+=d.min_summary[0][3]
    }

    if(d.max_summary.length>0){
        total+=d.max_summary[0][3]
    }

    return total

}

function FormattedText({text }) {
    const lines = text.split('\n');
  
    return (
      <div>
        {lines.map((line, i) => {
          // Check for introductory parts
          if (line.match(/^\d+\..+:/)) {
            const [intro, rest] = line.split(':');
            return (
              <p key={i}>
                <strong>{intro}:</strong>{rest}
              </p>
            );
          }
          // Check for list items
          else if (line.match(/^- /)) {
            return (
              <ul key={i}>
                <li>{line.replace(/^- /, '')}</li>
              </ul>
            );
          }
          // Just return a paragraph for other lines
          else {
            return <p key={i}>{line}</p>;
          }
        })}
      </div>
    );
  }

const Feedback_summary = ({data}) =>{

    const [total,setTotal] = React.useState(get_total(data))
    const [tldr,setTldr] = React.useState(data?.ai_result_text_pieces.find(f=>f.name=="result8"))
    const [action,setAction] = React.useState(data?.ai_result_text_pieces.find(f=>f.name=="result7"))
    
    return (
        <Container>
            {tldr?
                <TLDR data={tldr} title={'Summary'}/>:""}

            {action?
                <TLDR data={action} title={"Actions"}/>:""}

            {/* <Title>Cluster summary of feedback</Title>
            {[...data.min_summary,...data.max_summary].sort((a,b)=>b[2]-a[2]).map((item, i)=>{
                return (<Summary item={item} total={total} key={i} />)
            })} */}
        </Container>
    )
}

export default Feedback_summary;


const Summary = ({item,total}) =>{

    return (
        <SummaryContainer>
            <SummaryTitle>
                {item[1].replace(/['"]+/g,'')}
            </SummaryTitle>
            <Total>{item[2]} out of {total} responses</Total>
            <SummaryBody>{item[0]}</SummaryBody>
        </SummaryContainer>
    )
}

const TLDR = ({data,title}) =>{
    return (
        <SummaryContainer>
            <SummaryTitle>
                {title}
            </SummaryTitle>
            <SummaryBody><FormattedText text={data.content} /></SummaryBody>
        </SummaryContainer>
    )
}

const Container = styled.div`

`

const Title = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:10px;
`

const SummaryContainer = styled.div`
    margin-bottom:30px;
`

const SummaryTitle = styled.div`
    font-size:14px;
    font-weight:600;
    display:flex;
    width:100%;
    justify-content:space-between;
    line-height:1.5;
`

const SummaryBody = styled.div`
white-space: pre-line;
font-size:14px;
line-height:1.5;
`

const Percentage = styled.div`
    font-size:16px;
`

const Total = styled.div`
    font-size:12px;
    color:#666D79;
    margin-top:5px;
    margin-bottom:5px;

`