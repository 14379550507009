import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Icon} from 'semantic-ui-react';
import Layers from 'assets/images/UI/layers.svg';
import Chart from 'assets/images/UI/Name=Сhart.svg';
import Outcomes from 'assets/images/UI/Name=Outcomes.svg';

const MethodSelection = ({ onSelectMethod,goBack,benchmarks }) => (
  <Container>
    <BackButton onClick={goBack}><Icon name="chevron left"/> Back</BackButton>
    <Title>Choose the diagnostic method you want to use to build your action plan.</Title>
    <Description>
    Each method below offers a different lens on your culture data, allowing you to choose the perspective that best suits the way you want to take action.

    </Description>


    <Content>
        <DiagnosticCard
            onClick={() => onSelectMethod('ROI')}
            recommended={true}
            img={Outcomes}
            title="ROI based predictions"
            description="This approach leverages predictive modelling to identify the culture factors that will have the highest return on investment (ROI) based on your organization’s unique data."
        />

        {benchmarks && <DiagnosticCard
            img={Layers}
            onClick={() => onSelectMethod('Benchmark')}
            title="Global benchmark comparison"
            description="This approach compares your organization’s culture scores to global or industry benchmarks, offering a context for where you stand relative to others."
        />}

        <DiagnosticCard
            img={Chart}
            onClick={() => onSelectMethod('RawScore')}
            title="Raw scores"
            description="This approach offers a straightforward view of your culture scores, allowing you to see each factor’s current state without additional weighting or comparison."
        />
    </Content>

   


    
  </Container>
);


const DiagnosticCard = ({title,description,onClick,img}) =>{
    return (
        <Card onClick={onClick}>
               <IMG src={img}/>
          
            <CardTitle>
                {title}
            </CardTitle>

            <CardDescription>
                {description}
            </CardDescription>

            <Action>
                <Button>Choose <Icon name="arrow right"/></Button>
            </Action>

        </Card>
    )
}

export default MethodSelection;



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
`

const Container = styled.div`
     opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`


const Content = styled.div`
    display:flex;
    gap:20px;
`

const Title = styled.div`
    font-size:22px;
    font-weight:bold;
    margin-bottom:20px;
    margin-top:10px;
    line-height:1.3;
`


const Card = styled.div`
    background-color:#F8FAFF;
    padding: 20px;
    border-radius: 10px;
    width:calc(33% - 10px);
    position:relative;
    padding-bottom:60px;
`

const CardTitle = styled.div`
     color:#2A3039;
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
`

const CardDescription = styled.div`
    font-size: 12px;
    color:#2A3039;
`

const Description = styled.div`
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
`


const Action = styled.div`
    position:absolute;
    bottom:20px;
    right:20px;
    cursor:pointer;
`

const Button = styled.div`
    color:#2D70E2;
    font-weight:bold;
`


const IMG = styled.img`
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
`