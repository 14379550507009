import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import Phase1 from "./Planning/Phase1";
import Plan from "./Plan/index";
import Phase3 from "./Planning/Phase3";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Assignee from "./Assignee";
import Tabs from "./Tabs";
import Update from "./Update";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";

const Edit = ({ open, close, plans, data }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const options = ["Details", "Activity"];
  const [tab, setTab] = useState(options[0]);

  const { get_survey_structure, get_survey_questions } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_survey_questions: state.questions.survey_questions,
    }),
    shallowEqual
  );

  //Pull survey questions and categories from redux
  useEffect(() => {
    dispatch({ type: SG_GET_SURVEY_STRUCTURES });
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=6&status=2`,
    });
  }, [dispatch]);

  const getQ = () => {
    let dimension = data.metric.factor.d;
    let factor = data.metric.factor.f;
    let question = data.metric.q;
    if (question) {
      return (
        get_survey_questions[0].questions.dimensions[dimension].factors[factor]
          .questions[question]?.q || "All questions"
      );
    }

    return "";
  };

  const getSportTitle = () => {
    return get_survey_structure[0].categories.find(
      (f) => f.priority == "primary"
    ).name;
  };

  const getSport = () => {
    console.log(
      get_survey_structure[0].categories.find((f) => f.priority == "primary"),
      data.primary.id
    );
    return get_survey_structure[0].categories
      .find((f) => f.priority == "primary")
      .options.find((f) => f.id == data.primary.id).name;
  };

  const getAnchor = () => {
    if (data.anchor.selected) {
      return get_survey_structure[0].categories
        .find((f) => f.id == data.anchor.id)
        .options.find((f) => f.id == data.anchor.selected).name;
    }

    return "";
  };

  const getAnchorTitle = () => {
    if (data.anchor.id) {
      return get_survey_structure[0].categories.find(
        (f) => f.id == data.anchor.id
      ).name;
    }

    return "";
  };

  return (
    <Container open={open}>
      <InnerContainer>
        <TopContainer>
          <Close onClick={() => close()}>
            <Icon name="close" style={{ margin: 0, marginTop: -3 }} />
          </Close>
        </TopContainer>

        <ScrollContainer>
          <Tabs options={options} selected={tab} onSelect={(s) => setTab(s)} />

          {tab == "Details" ? (
            <>
              <Title>{data ? data.metric.factor.title : ""} Plan</Title>
              <Top>Culture Pillar</Top>
              <T2>{data ? getQ() : ""}</T2>

              <Row>
                <T3>{data ? getSportTitle() + ": " : ""}</T3>
                <T2>{data ? getSport() : ""}</T2>
              </Row>

              <Row>
                <T3>{data ? getAnchorTitle() + ": " : ""}</T3>
                <T2>{data ? getAnchor() : ""}</T2>
              </Row>

              <T2>Tagged team members</T2>
              {data
                ? data.member.map((i) => (
                    <Assignee
                      first={i.name.split(" ")[0]}
                      last={i.name.split(" ")[1]}
                      size="large"
                    />
                  ))
                : ""}

              <Top>Plan Details</Top>
              <T1>(Creating Urgency)</T1>
              <Area>
                <Q>
                  Why is this a meaningful measure to improve? What are the
                  benefits of improving in this culture area?
                </Q>
                <R>{data ? data.planData[0] : ""}</R>
              </Area>

              <Area>
                <Q>
                  What do you predict is going to happen if this doesn’t change?
                  What is the cost of not improving in this area?
                </Q>
                <R>{data ? data.planData[1] : ""}</R>
              </Area>

              <T1>Possible causes:</T1>
              <Area>
                <Q>
                  What do you believe may be leading to the current level of
                  performance in this area within your culture?
                </Q>
                <R>{data ? data.planData[2] : ""}</R>
              </Area>

              <T1>Action plan:</T1>
              <Area>
                <Q>
                  List up to 3 strategies or behaviors that will be implemented,
                  introduced, or changed in your environment to improve this
                  culture area.
                </Q>
                <TextArea>
                  <T2>Strategy #1</T2>
                  <R>{data ? data.planData[3] : ""}</R>
                  <T2>Strategy #2</T2>
                  <R>{data ? data.planData[4] : ""}</R>
                  <T2>Strategy #3</T2>
                  <R>{data ? data.planData[5] : ""}</R>
                </TextArea>
              </Area>
            </>
          ) : (
            <Update />
          )}
        </ScrollContainer>
      </InnerContainer>
    </Container>
  );
};

export default Edit;

const Container = styled.div`
  position: absolute;
  min-width: 300px;
  width: 60vw;
  right: ${(props) => (props.open ? "-25px" : "-70vw")};
  top: -20px;
  height: calc(100vh - 50px);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-family: "Barlow Semi Condensed", sans-serif;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  padding: 10px;
  overflow: hidden; ;
`;

const Top = styled.div`
  font-size: 12px;
  color: lightgrey;
  text-transform: uppercase;
  margin-top: 20px;
`;
const T2 = styled.div``;

const Row = styled.div`
  display: flex;
  align-itesm: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const T3 = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
`;

const ScrollContainer = styled.div`
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;

  &:hover {
    background-color: rgba(9, 30, 66, 0.2);
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
`;
const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div`
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: lightgrey;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
`;
const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Back = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #7f7f7f;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const T1 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #476dfa;
`;

const Q = styled.div`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 300;
  width: 100%;
  margin-right: 20px;
`;

const Area = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextArea = styled.div`
  width: 100%;
`;

const R = styled.div``;
