import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";




const LegendIcon = () =>{


	return (
		<Container>
			<Shape width={'100%'}/>
			<Shape width={'70%'}/>
			<Shape width={'50%'}/>
		</Container>
		)
}


export default LegendIcon



const Container = styled.div`
	width:20px;
	height:14px;
	display:flex;
	flex-direction:column;
	align-items:flex-end;
	margin-right:5px;
`

const Shape = styled.div`
	width:${props=>props.width};
	height:4px;
	border:1.3px solid #476DFA;
	border-radius:20px;
	margin-bottom:1px;
`