import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for modal opening animation
const modalOpenAnimation = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
`;

// Styled modal backdrop
const ModalBackdrop = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

// Styled modal
const StyledModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  z-index: 100;
  transition: width 0.3s ease, height 0.3s ease;
  width: ${({ width }) => width || '300px'};
  animation: ${modalOpenAnimation} 0.3s ease-out;
  min-width:800px;
  min-height:300px;
  display:flex;
  flex-direction:column;
  padding-bottom:120px;
`;

// Modal component
const Modal = ({ isOpen, children }) => {
  return (
    <ModalBackdrop isOpen={isOpen}>
      <StyledModal>
        {children}
      </StyledModal>
    </ModalBackdrop>
  );
};

export default Modal;