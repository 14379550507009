import React from 'react';
import styled from 'styled-components';
import Clarity from 'assets/images/UI/clarity.png';
import factor_content from './factor_content.json'
import {Popup} from 'semantic-ui-react'; 





const Tooltip = ({ text,factor,onClick }) => {

if(!factor_content[factor]) return <span>{text}</span>

  return (
    <Popup 
    position='top center'
    inverted
    content='Click to learn more' 
    trigger={ <span onClick={()=>onClick()} >{text}</span>} />
)

};


export default Tooltip;
