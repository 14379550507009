import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Dropdown } from "semantic-ui-react";

const Filter = ({ structure, selected, setSelected }) => {
  const structureOptions = () => {
    let _options = [];

    structure
      .filter((category) => category.priority.toLowerCase() === "primary")[0]
      .options.map((item, i) => {
        _options.push({
          key: i + 1,
          text: item.name,
          value: item.name,
        });
      });

    return _options;
  };

  const [options, setOptions] = useState(structureOptions());

  return (
    <div style={{ width: 200 }}>
      <Dropdown
        placeholder="Select Location"
        fluid
        selection
        options={options}
        value={selected}
        onChange={(e, { value }) => setSelected(value)}
        clearable
      />
    </div>
  );
};

export default Filter;
