import React from "react";
import DateSelector from "./DateSelector";
import styled from "styled-components";

const DateRange = (props) => {
  const fromDate = props.data?.planData?.[props.id]?.[0];
  const toDate = props.data?.planData?.[props.id]?.[1];

  return (
    <DateRangeContainer>
      <DateSelectionLabel>Timeline:</DateSelectionLabel>
      <Control>
        <SelectionWrapper>
          <Label>From:</Label>
          <DateSelector
            {...props}
            handleDatesSelected={(date, id) =>
              props.handleDatesSelected(date, props?.id, 0)
            }
            date={fromDate !== "" ? fromDate : null}
          />
        </SelectionWrapper>

        <SelectionWrapper>
          <Label>To:</Label>
          <DateSelector
            {...props}
            handleDatesSelected={(date, id) =>
              props.handleDatesSelected(date, props?.id, 1)
            }
            date={toDate !== "" ? toDate : null}
          />
        </SelectionWrapper>
      </Control>
    </DateRangeContainer>
  );
};

export default DateRange;

const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DateSelectionLabel = styled.div`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 10px;
`;

const Control = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  max-height: 40px;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 5px;
`;

const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Label = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 600;
  margin-top: 12px;
  margin-right: 5px;
`;
