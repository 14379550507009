import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
	Icon,
	Progress,
	Dropdown,
	Modal,
	Select,
	Radio,
} from "semantic-ui-react";
import DateTimePicker from "react-datetime-picker";

const Schedule = ({ trigger }) => {
	const [open, setOpen] = useState(false);
	const [freq, setFreq] = useState(null);
	const [dateValue, setDateValue] = useState(new Date());

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={trigger}
			size="tiny"
			closeIcon
		>
			<Modal.Header>
				<Title>Create survey schedule</Title>
			</Modal.Header>
			<Modal.Content>
				<div>
					<FormContainer>
						<Label>Survey Type</Label>
						<Select
							placeholder="Select Survey Type"
							options={SurveyList}
						/>
					</FormContainer>

					<FormContainer>
						<Label>Frequency</Label>
						<Radio
							label="One Time"
							name="radioGroup"
							value="this"
							checked={freq === "one"}
							onChange={() => setFreq("one")}
							style={{ marginRight: 10 }}
						/>
						<Radio
							label="Recurring"
							name="radioGroup"
							value="that"
							checked={freq === "rec"}
							onChange={() => setFreq("rec")}
						/>
					</FormContainer>
					<FormContainer>
						<Label>Select a start date and time</Label>
						<DateTimePicker
							onChange={setDateValue}
							value={dateValue}
						/>
					</FormContainer>
					<FormContainer>
						<Label>
							After how many hours should the survey close?
						</Label>
						{/* <Input placeholder='# of hours' /> */}

						<Select
							placeholder="Select No of Hours"
							options={HoursList}
						/>
					</FormContainer>
					<FormContainer>
						<Label>Select Survey Question</Label>
						{/* <Input placeholder='# of hours' /> */}
					</FormContainer>
					<FormContainer>
						<Label>Select Category</Label>
						{/* <Input placeholder='# of hours' /> */}
					</FormContainer>
				</div>
			</Modal.Content>
			<Modal.Actions>
				<StyledButton color={"#2d50e2"}>Save</StyledButton>
			</Modal.Actions>
		</Modal>
	);
};

export default Schedule;

const HoursList = [
	{ value: 1440, text: "One Day (24 hrs)" },
	{ value: 4320, text: "Three Days" },
	{ value: 10080, text: "1 Week" },
	{ value: 60, text: "One Hour (60 mins) - testing only" },
	{ value: 30, text: "Half Hour (30 mins) - testing only" },
];

const SurveyList = [
	{ value: 1440, text: "Mood Check" },
	{ value: 4320, text: "Culture Pulse" },
	{ value: 10080, text: "Game Debrief" },
	{ value: 60, text: "Practice Debriefs" },
	{ value: 30, text: "Hooper McKinnon" },
];

const Container = styled.div`
	// margin-top:30px;
	font-family: "Barlow", sans-serif;
	// padding:30px;
	// box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width: 400px;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const Title = styled.div`
	font-size: 26px;
	font-weight: bold;
	font-family: "Barlow", sans-serif;
`;

const Label = styled.div`
	margin-bottom: 10px;
	font-family: "Barlow", sans-serif;
	font-weight: bold;
`;

const FormContainer = styled.div`
	max-width: 300px;
	margin-bottom: 20px;
`;

const FormContainerTop = styled.div`
	max-width: 300px;
	margin-top: 0px;
`;

const Submit = styled.button`
	border-radius: 5px;
	background-color: ${(props) => (props.disable ? "#dcdcdc" : "#6a00ff")};
	font-size: 14px;
	font-weight: bold;
	color: white;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	margin-top: 30px;
	cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
	border: none;
`;

const Types = styled.div`
	width: 100%;
	display: flex;
`;

const TypeContainer = styled.div`
	width: 400px;
	margin-right: 30px;
	margin-top: 20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display: flex;
	padding: 30px;
	cursor: pointer;
	background-color: ${(props) => (props.selected ? "red" : "gray")};
`;
const TypeName = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 15px;
`;

const Description = styled.div`
	color: #757575;
`;

const Image = styled.img`
	width: 100px;
`;

const See = styled.div`
	margin-top: 10px;
	color: #6a00ff;
`;

const StyledButton = styled.button`
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	padding: 0px 30px;
	height: 40px;
	color: white;
	background-color: ${(props) => props.color};
	border-radius: 20px;
	margin-right: 10px;
	cursor: pointer;
	user-select: none;
	border: none;
	border: 1px solid white;
	outline: none;

	&:hover {
		background-color: white;
		color: ${(props) => props.color};
		border: ${(props) => "1px solid " + props.color};
	}
`;
