import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Department from "../../assets/images/department.png";
import Team from "../../assets/images/team.png";

const Title  = styled.div`
	font-size: 28px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:100px;
  margin-top:170px;
`

const Img = styled.img`
	width:400px;
	margin-right:36px;
	box-shadow: 0 10px 40px 0 rgba(211, 211, 211, 0.5);
	cursor:pointer;

	&:hover{
  	box-shadow: 0 10px 40px 0 rgba(211, 211, 211, 1);
  }
`

 const Container = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	flex-direction:column;
	height:100%;
  position:absolute;
  
`


const IntroScreen = (props) => {
  return (
    <Container>
      <Title>What kind of team would you like to build?</Title>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <Img src={Team} onClick={() => props.change(3)} />
        <Img src={Department} onClick={() => props.change(2)} />
      </div>
    </Container>
  );
};

export default IntroScreen