import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Assignee from "../Assignee";
import { Input } from "semantic-ui-react";

const Update = () => {
	return (
		<Container>
			<Row>
				<Assignee first="Michael" last="Bawol" />

				<Input
					placeholder="Add comment"
					style={{ width: 400, marginLeft: 10 }}
				/>
			</Row>
		</Container>
	);
};

export default Update;

const Container = styled.div``;

const Row = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
`;
