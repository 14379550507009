import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress } from "semantic-ui-react";
import Chart from "react-apexcharts";
import Diversity from "./Diversity";
import TO from "./TO";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Overview from "./Overview";

const Area = (props) => {
	return (
		<div>
			<ATitle>
				{props.title}
				<Percent>{props.percent}%</Percent>
			</ATitle>
			<Progress percent={props.percent} color="violet" size="tiny" />
		</div>
	);
};

const Active = (props) => {
	return (
		<AContainer>
			<H5>{props.title}</H5>
			<Dat>{props.date}</Dat>
		</AContainer>
	);
};

const Analytics = () => {
	const [selected, setSelected] = React.useState(0);
	const WhiteLabel = useSelector(
		(state) => state.white_label?.white_label,
		shallowEqual
	);

	return (
		<Container>
			<Dash>
				<AnalysisArea>
					<Nav>
						<NavItem
							color={WhiteLabel.highlight}
							selected={0 === selected}
							onClick={() => setSelected(0)}
						>
							Diversity
						</NavItem>
						<NavItem
							color={WhiteLabel.highlight}
							selected={5 === selected}
							onClick={() => setSelected(5)}
						>
							Compensation
						</NavItem>
						<NavItem
							color={WhiteLabel.highlight}
							selected={1 === selected}
							onClick={() => setSelected(1)}
						>
							Inclusion
						</NavItem>
						<NavItem
							color={WhiteLabel.highlight}
							selected={2 === selected}
							onClick={() => setSelected(2)}
						>
							Turn over
						</NavItem>
						<NavItem
							color={WhiteLabel.highlight}
							selected={3 === selected}
							onClick={() => setSelected(3)}
						>
							Promotions
						</NavItem>
						<NavItem
							color={WhiteLabel.highlight}
							selected={4 === selected}
							onClick={() => setSelected(4)}
						>
							New hires
						</NavItem>
					</Nav>

					{selected === 0 ? (
						<Diversity />
					) : selected == 1 ? (
						<>
							<Title>Inclusion Trends</Title>
							<Description>
								Pork loin porchetta shoulder tri-tip. Capicola shank kevin,
								biltong porchetta buffalo picanha hamburger rump. Pork chop
								landjaeger pork belly meatball ribeye shank t-bone cupim sausage
								frankfurter rump.{" "}
							</Description>

							<Chart
								options={data2.options}
								series={data2.series}
								type="line"
								width={"100%"}
							/>
						</>
					) : (
						<TO />
					)}
				</AnalysisArea>
				{/*<SideMenu>
			 	<H2>Halifax, Nova Scotia</H2>

			 	<Card>
			 		<H3>Diversity Meta Score</H3>
			 		<div style={{position:'relative'}}>
						<Chart options={data.options} series={data.series} type="radialBar" height={150} />
						<Values>
								<Stat>86%</Stat>
							</Values>
					</div>
					<TextArea>
						<Change>Great</Change>
						<Text>This location's diversity meta score has <strong>increased by +10%</strong> this quarter.</Text>
					</TextArea>
				</Card>

				<Content>
					<H4>Score Breakdown</H4>
					<Area title={'New Hires'} percent={94}/>
					<Area title={'Turn Over'} percent={88}/>
					<Area title={'Inclusion'} percent={78}/>
					<Area title={'Emotional Health'} percent={66}/>
					<Area title={'Promotions'} percent={62}/>
				</Content>

				<Activity>
					<div style={{padding:'20px 20px 0px 20px'}}>
						<H4>Latest Activity</H4>
					</div>
					<Active title={"Implemented a workplace initiative, which saw a 15% increase in diversity scores"} date={"March 31st, 2020"}/>
					<Active title={"Implemented a workplace initiative, which saw a 15% increase in diversity scores"} date={"March 31st, 2020"}/>
					<Active title={"Implemented a workplace initiative, which saw a 15% increase in diversity scores"} date={"March 31st, 2020"}/>
				</Activity>
			 </SideMenu>*/}
			</Dash>
		</Container>
	);
};

export default Analytics;

const Container = styled.div`
	height: calc(100vh - 110px);
	width: 100%;
	font-family: "Red Hat Display", sans-serif;
	position: relative;
`;

const Top = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	height: 60px;
	padding-left: 30px;
	margin-top: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const AnalysisArea = styled.div`
	width: 100%;
	height: 100%;
	padding-right: 20px;
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: bold;
`;

const Dash = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
`;

const SideMenu = styled.div`
	width: 400px;
	height: 100%;
	background-color: #f8f8f8;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const H2 = styled.div`
	font-size: 18px;
	padding: 30px 20px;
	border-bottom: 1px solid #e9e9e9;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const H3 = styled.div`
	font-size: 12px;
`;

const Change = styled.div`
	font-size: 16px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
	color: rgba(0, 176, 240, 1);
	margin-bottom: 5px;
`;

const Card = styled.div`
	padding: 30px 10px;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: white;
`;

const Values = styled.div`
	position: absolute;
	width: 50px;
	height: 50px;
	left: calc(50% - 25px);
	top: calc(50% - 25px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Stat = styled.div`
	font-size: 25px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
`;

const TextArea = styled.div`
	width: 100%;
	padding: 10px;
`;

const Text = styled.div``;

const Content = styled.div`
	padding: 20px;
	border-bottom: 1px solid #e9e9e9;
`;

const H4 = styled.div`
	font-size: 16px;
	color: #6a00ff;
	font-weight: bold;
	margin-bottom: 20px;
`;

const ATitle = styled.div`
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Percent = styled.div``;

const Activity = styled.div``;

const AContainer = styled.div`
	padding: 20px;
	border-bottom: 1px solid #e9e9e9;
`;

const H5 = styled.div`
	font-weight: 500;
	font-size: 16px;
`;

const Dat = styled.div`
	margin-top: 10px;
	color: #4a4a4a;
	font-size: 12px;
`;

const Marker = styled.div`
	height: 60px;
	width: 60px;
	border-radius: 30px;
	border: 3px solid white;
	background-color: rgba(0, 176, 240, 1);
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Red Hat Display", sans-serif;
	color: white;
	font-size: 22px;
	font-weight: bold;
	cursor: pointer;

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 40px;
	}
`;

const Description = styled.div`
	margin-top: 20px;
	margin-bottom: 50px;
`;

const Nav = styled.div`
	width: 100%;
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const NavItem = styled.div`
	font-family: "Red Hat Display", sans-serif;
	padding: 10px 20px;
	height: 50px;
	display: flex;
	align-items: center;
	color: ${(props) => (props.selected ? props.color : "#9b9b9b")};
	font-weight: ${(props) => (props.selected ? "bold" : "400")};
	border-bottom: ${(props) =>
		props.selected ? "3px solid " + props.color : "none"};
	cursor: pointer;
	font-size: 16px;
	position: relative;
`;

const data = {
	series: [86],
	options: {
		chart: {
			height: 250,
			type: "radialBar",
		},
		grid: {
			padding: {
				right: 0,
				top: 0,
				left: 0,
				bottom: 0,
			},
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: "65%",
				},
				track: {
					background: "#c3a7fd",
				},
				dataLabels: {
					show: false,
					name: { show: false },
					value: { show: false },
				},
			},
		},
		fill: {
			colors: ["#6a00ff"],
		},
	},
};

const data2 = {
	series: [
		{
			name: "Gender Inclusion",
			data: [28, 29, 33, 36, 32, 32, 33],
		},
		{
			name: "Race Inclusion",
			data: [12, 11, 14, 18, 17, 13, 13],
		},
	],
	options: {
		chart: {
			height: 350,
			type: "line",
			dropShadow: {
				enabled: true,
				color: "#000",
				top: 18,
				left: 7,
				blur: 10,
				opacity: 0.2,
			},
			toolbar: {
				show: false,
			},
		},
		dataLabels: {
			enabled: true,
		},
		stroke: {
			curve: "smooth",
		},
		title: {
			text: "Inclusion score trends",
			align: "left",
		},
		grid: {
			borderColor: "#e7e7e7",
			row: {
				colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
				opacity: 0.5,
			},
		},
		markers: {
			size: 1,
		},
		xaxis: {
			categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
			title: {
				text: "Month",
			},
		},
		yaxis: {
			title: {
				text: "Score",
			},
			min: 5,
			max: 100,
		},
		legend: {
			position: "top",
			horizontalAlign: "right",
			floating: true,
			offsetY: -25,
			offsetX: -5,
		},
	},
};
