import DatePicker from "react-datepicker";
import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, forwardRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { calendarIcon } from "components/Icons";

const DatePick = (props) => {
  const { handleDateChange, date } = props;
  const [startDate, setStartDate] = useState(
    (date && new Date(date)) || new Date()
  );
  const TextInput = forwardRef(({ value, onClick }, ref) => (
    <TextboxContainer onClick={onClick}>
      {calendarIcon()}
      <DateText>{value || "Check in date"}</DateText>
    </TextboxContainer>
  ));

  useEffect(() => {
    if (props?.editEnabled) {
      handleDateChange(startDate);
    }
  }, [startDate]);

  return (
    <Container>
      {props.title && <Title>{props.title}</Title>}
      <DatePicker
        selected={startDate}
        onChange={(date) => props?.editEnabled && setStartDate(date)}
        selectsStart
        startDate={startDate}
        customInput={<TextInput />}
      />
    </Container>
  );
};

export default DatePick;

const DateText = styled.div`
  color: #666d79;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-left: 10px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  padding-top: 25px;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
`;

const TextboxContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid;
  border-color: ${(props) => (props.isFocused ? "#2D70E2" : "#e0e0e0")};
  padding: 16px;
  width: 300px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  width: 100%;
  font-family: Raleway;
  font-size: 14px;
  height: 10px;

  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;
