import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Table from "../components/Table";

const List = ({ setDeleteId, loading, setDeleteItem, pdfList }) => {
  const navigate = useNavigate();
  const { coreData, pdfReports } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  return (
    <Container>
      <Center>
        <Title>
          Report History
          <Button
            onClick={() => navigate("/leader/audit/reports/workshop/create")}
          >
            Create Report
          </Button>
        </Title>
        {coreData?.categories &&
          pdfReports?.templates &&
          pdfList?.length > 0 && (
            <Table
              data={pdfList}
              categories={coreData?.categories}
              templates={pdfReports?.templates}
              setDeleteId={setDeleteId}
              setDeleteItem={setDeleteItem}
              loading={loading}
            />
          )}
      </Center>
    </Container>
  );
};

export default List;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  margin-top: 40px;
  font-family: "Raleway";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
