import React, { useState, useEffect } from "react";
import {
    Button,
    Message,
    Modal,
    Icon,
    Input,
    Checkbox,
    Segment,
} from "semantic-ui-react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";

import { useToasts } from "react-toast-notifications";

import Select from "react-select";

import {
    UPDATE_EMPLOYEE,
    REGISTER_EMPLOYEE,
    SG_VALIDATE_EMPLOYEE,
} from "constants/actions";

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// function ShowEmployeeFound({ empFound, setEmployee, setError, setEmailId }) {
//     function OnSelect() {
//         setEmployee(empFound);
//         setError({ emailFound: true });
//     }

//     function OnClear() {
//         setEmployee(false);
//         setError({ emailFound: false });
//         setEmailId("");
//     }
//     const [employeeDet, setEmployeeDet] = React.useState(false);
//     React.useEffect(() => {
//         if (empFound?.first_name) {
//             setEmployeeDet(`${empFound?.first_name} ${empFound?.last_name}
//             ${empFound?.email}`);
//         } else {
//             setEmployeeDet(empFound?.email);
//         }
//     }, [empFound]);

//     return (
//         <Segment>
//             <Message
//                 header="We found this member in your organization"
//                 style={{ zIndex: 0 }}
//                 size="small"
//                 success
//                 content={`${employeeDet} and is in team ${empFound.team_name}, would you like to update this members settings?`}
//             />

//             <Button onClick={OnSelect}>Edit member settings</Button>

//             <Button onClick={OnClear}>Clear</Button>
//         </Segment>
//     );
// }

function EditMember(props) {
    const apiDispatch = useDispatch();
    const { addToast } = useToasts();
    const {
        fullEmpData,
        userEmployee, data,
        openTeamCard,
        setOpenTeamCard,
        teamId,
        newInvite,
        // setLoading,
    } = props

    // console.log(fullEmpData)
    // console.log(userEmployee)

    const [loadingTeam, setLoadingTeam] = useState(false);
    const [loadingRoles, setLoadingRoles] = useState(false);
    const [teamList, setTeamList] = useState([]);
    const [employeeRole, setEmployeeRole] = useState([]);

    // const [selectedAccount, setSelectedAccount] = useState([])
    // const [userAuth, setUserAuth] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(false);
    const [selectedRole, setSelectedRole] = useState(false);

    const [saveEnabled, setSaveEnabled] = useState(1);

    const [selectedTeamTypeId, setSelectedTeamTypeId] = useState(false);

    const [employeeFound, setEmployeeFound] = useState(false);
    // const [employee, setEmployee] = useState(false);

    const [employeeFullname, setEmployeeFullname] = useState(false);
    const [emailId, setEmailId] = useState("");
    const [showAdmin, setShowAdmin] = useState(false);

    const [error, setError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);

    const [inviteText, setInviteText] = useState("Enter Email");

    const org_id = useSelector((state) => state.auth.organization_id);
    const AmbassadorId = useSelector(
        (state) => state.auth?.ambassador_id,
        shallowEqual
    );
    const [organizationId, setOrganizationId] = React.useState();
    const {
        get_teams,
        get_team_types,
        get_employee_roles,
        get_error,
        get_employees,
        get_validated_email,
        get_auth,
        get_selectedOrg,
    } = useSelector(
        (state) => ({
            get_teams: state.teams.teams,
            get_team_types: state.team_types.team_types,
            get_employee_roles: state.employee_roles.employee_roles,
            get_error: state.errors,
            get_auth: state.auth,
            get_employees: state.employees.employees,
            get_validated_email: state.employees.validateEmail,
            get_selectedOrg: state.selectedOrg,
        }),
        shallowEqual
    );


    useEffect(() => {
        setSelectedTeamTypeId(
            get_team_types.filter((p) => p?.name === "No Team")[0]?.id
        );
    }, [get_team_types]);
    // console.log(openTeamCard)
    // console.log("employee", employee)
    // console.log("userAuth", userEmployee)

    React.useEffect(() => {
        setOrganizationId(
            Number(get_selectedOrg?.organization?.id) > 0
                ? get_selectedOrg?.organization?.id
                : Number(org_id)
        );
    }, [get_selectedOrg, org_id]);

    React.useEffect(() => {
        if (userEmployee?.account_type === 5) {
            setShowAdmin(true);
        }
    }, [userEmployee]);



    useEffect(() => {
        if (Number(get_validated_email?.code) === 400) {
            setErrorMessage(
                `The email ${get_validated_email?.email} has been used`
            );
            setInviteText("Already Invited");
            // setSaveEnabled(2)
        } else if (Number(get_validated_email?.code) === 500) {
            setErrorMessage(get_validated_email?.message);
            setInviteText("Invalid Email");
            // setSaveEnabled(2)
        } else if (Number(get_validated_email?.code) === 200) {
            setInviteText("Invite");

            setErrorMessage(false);
            // setSaveEnabled(3)
        }
    }, [get_validated_email]);

    // console.log(userEmployee?.account_type)
    // console.log(fullEmpData?.account_type)
    // console.log(saveEnabled)

    useEffect(() => {
        setSaveEnabled(1);
        if (errorMessage) {
            setSaveEnabled(2);
            return;
        }
        if (employeeRole.length === 0) {
            setSaveEnabled(3);
            return;
        }
        if (!selectedRole?.role_name) {
            setSaveEnabled(3);
            return;
        }
        if (userEmployee?.account_type !== 5 && fullEmpData?.account_type === 5) {
            setSaveEnabled(4);
            return
        }

    }, [errorMessage, selectedRole, employeeRole, userEmployee, fullEmpData]);

    useEffect(() => {
        let employeeName = fullEmpData?.email ? fullEmpData?.email : "None";
        if (fullEmpData?.first_name) {
            employeeName = fullEmpData?.first_name + " " + fullEmpData?.last_name;
        }
        setEmployeeFullname(employeeName)
    }, [fullEmpData]);

    const SaveForm = () => {
        // console.log(emailId)
        // setLoading(true)
        if (!selectedRole) {
            setError({
                // selectedTeam: 'Please select a team.',
                employeeRole: "Please select a Valid Role.",
            });
            return;
        }
        // console.log("this should trigger",
        // Number(get_auth.employee_id), employee?.id, selectedRole.account_type)

        if (Number(get_auth.employee_id) === fullEmpData?.id) {
            if (fullEmpData.account_type < 7) {
                if (selectedRole.account_type > 5) {
                    setError({
                        employeeRole:
                            "Selecting this role could lock you out, please select another role. Since you are an admin, you cannot choose non-admin role for yourself",
                    });
                    return;
                }
            }
        }

        if (fullEmpData?.id) {
            apiDispatch({
                type: UPDATE_EMPLOYEE,
                payload: {
                    id: fullEmpData.id,
                    team: selectedTeam?.id ? selectedTeam.id : null,
                    account_type: selectedRole.account_type,
                    role: selectedRole.role_name,
                    employee_role: selectedRole.id,
                    organization: org_id,
                },
            });
        } else {
            if (!emailId) {
                setError({
                    emailId: "Enter a email id",
                });
                return;
            }
            if (emailId) {
                // const ValidEmail1 = checkEmail(emailId)
                const ValidEmail = validateEmail(emailId.toLowerCase());

                if (!ValidEmail) {
                    setError({ emailId: "Enter a valid email id" });
                    return;
                }

                // /get_employees
                const email_found = get_employees.filter(
                    (p) => p.email === emailId.toLowerCase()
                )?.[0];

                // console.log('email_found', email_found)

                if (email_found?.id) {
                    setError({ emailFound: true });
                    setEmployeeFound(email_found);
                    return;
                }
            }

            apiDispatch({
                type: REGISTER_EMPLOYEE,
                payload: {
                    email: emailId,
                    organization: org_id,
                    team: selectedTeam?.id ? selectedTeam.id : null,
                    account_type: selectedRole.account_type,
                    role: selectedRole.role_name,
                    employee_role: selectedRole.id,
                },
            });
        }

        let employeeName = fullEmpData?.email ? fullEmpData?.email : emailId;
        if (fullEmpData?.first_name) {
            employeeName = fullEmpData?.first_name + " " + fullEmpData?.last_name;
        }

        addToast(
            `${employeeName} has been successfully added to ${selectedTeam?.name} team`,
            {
                appearance: "success",
                autoDismiss: true,
            }
        );

        setOpenTeamCard(false);
        setEmailId("");
        setError(false);

    };

    const close = () => {
        setOpenTeamCard(false);
        setEmailId("");
        setError(false);
        setErrorMessage(false);
    };

    useEffect(() => {
        setTeamList(get_teams);
    }, [get_teams]);

    useEffect(() => {
        
        if (fullEmpData?.team) {
            setSelectedTeam(get_teams.filter((p) => p.id === fullEmpData.team)[0]);

            setSelectedTeamTypeId(
                get_teams.filter((p) => p.id === fullEmpData.team)[0]?.team_type
            );

        } else if (teamId) {
            setSelectedTeam(get_teams.filter((p) => p.id === teamId)[0]);
            setSelectedTeamTypeId(
                get_teams.filter((p) => p.id === teamId)[0]?.team_type
            );
        } else {
            setSelectedTeam(get_teams.filter((p) => p?.id === userEmployee?.id)[0]);
            setSelectedTeamTypeId(
                get_teams.filter((p) => p.id === userEmployee?.id)[0]?.team_type
            );
        }

    }, [
        fullEmpData,
        teamId,
        get_employee_roles,
        userEmployee,
        get_teams,
        get_auth,
        get_employees,
    ]);

    useEffect(() => {
        setSelectedRole(get_employee_roles.filter(
            (p) => p.id === fullEmpData?.employee_role
        )[0])
    }, [get_employee_roles, fullEmpData]);

    function ChangeTeam(e) {
        setSelectedTeam(e);
        
        if (e?.team_type) {
            setSelectedTeamTypeId(e.team_type);
        } else {
            setSelectedTeamTypeId(
                get_team_types.filter((p) => p.name === "No Team")[0].id
            );  
        }

        setLoadingRoles(true);
        let _role_list = [];
        if (e?.team_type) {
            _role_list = get_employee_roles.filter(
                (p) =>
                    p.is_active === true &&
                    p.is_visible === true &&
                    p.team_type === e.team_type &&
                    p.sort_order < 100
            );
        } else {
            _role_list = get_employee_roles.filter(
                (p) =>
                    p.is_active === true &&
                    p.is_visible === true &&
                    p.team_type === null &&
                    p.sort_order > 100
            );
        }

        setEmployeeRole(_role_list);
        setLoadingRoles(false);
    }

    function ChangeEmployeeRole(e) {
        setSelectedRole(e);
    }

    function CheckEmailId(e) {
        // console.log(e.target.value)
        setErrorMessage(false);
        const emailId = e.target.value;
        setEmailId(emailId);
        // const ValidEmail = validateEmail(emailId)
        setError({
            emailId: false,
        });
        if (emailId) {
            // const ValidEmail1 = checkEmail(emailId)
            const ValidEmail = validateEmail(emailId.toLowerCase());

            if (!ValidEmail) {
                setError({ emailId: "Enter a valid email id" });
                return;
            }

            // /get_employees
            const email_found = get_employees.filter(
                (p) => p.email === emailId.toLowerCase()
            )?.[0];

            // console.log('email_found', email_found)

            if (email_found?.id) {
                setError({ emailFound: true });
                // setEmployeeFound(email_found);
                return;
            }
            setInviteText(false);

            setErrorMessage(false);

            setEmailId(e.target.value);
        }
    }

    const checkEmail = () => {
        if (emailId && emailId.length > 3) {
            apiDispatch({
                type: SG_VALIDATE_EMPLOYEE,
                payload: `email=${emailId}`,
            });
        }
    };

// console.log(get_employee_roles)
// console.log(selectedTeamTypeId)

    useEffect(() => {
        if (userEmployee?.account_type === 5 && fullEmpData?.id) {
            setEmployeeRole(
                get_employee_roles.filter(
                    (p) =>
                        p.active === true &&
                        p.is_visible === true &&
                        p?.team_type === selectedTeamTypeId
                )
            );
        } else {
            setEmployeeRole(
                get_employee_roles.filter(
                    (p) =>
                        p.active === true &&
                        p.is_visible === true &&
                        p?.team_type === selectedTeamTypeId &&
                        p?.is_admin === false
                )
            );
        }
    }, [
        selectedTeam,
        showAdmin,
        get_employee_roles,
        fullEmpData,
        selectedTeamTypeId,
        userEmployee,
    ]);

    // console.log(employeeRole)
    // console.log(selectedTeam?.team_type)
    // console.log(get_team_types.filter(p => p.name === "No Team")[0])

    const getTeam = () => {
        if (props.noTeam) {
            return true;
        }
        if (fullEmpData) {
            if (fullEmpData.id) {
                return true;
            }
        }
        if (newInvite) {
            return true
        }
        return false;
    };

    const getSelected = (p) => {
        if (selectedRole) {
            if (selectedRole?.id === p.id) {
                // setInviteDisabled(true)
                return true;
            }
        }
        return false;
    };

    // console.log(props.trigger)
    // console.log(openTeamCard)

    return (
        <Modal
            onClose={() => close()}
            onOpen={() => setOpenTeamCard(true)}
            open={openTeamCard}
            size={"tiny"}
            closeIcon
            trigger={props.trigger}
        >
            <Modal.Header>
                {fullEmpData?.id
                    ? `Edit${" "} ${employeeFullname}'s details`
                    : "Invite a new member"}
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>

                    {/* <SubText>**Moving a department leader to a team will automatically change their role to team member.</SubText> */}
                    {error?.selectedTeam && (
                        <Message
                            header="Team"
                            style={{ zIndex: 0 }}
                            size="small"
                            error
                            content={error?.selectedTeam}
                        />
                    )}
                    {error?.employeeRole && (
                        <Message
                            header="Employee Role"
                            style={{ zIndex: 0 }}
                            size="small"
                            error
                            content={error?.employeeRole}
                        />
                    )}
                    {/* {error?.emailFound && (
                        <ShowEmployeeFound
                            empFound={employeeFound}
                            setEmployee={setFullEmpData}
                            setError={setError}
                            setEmailId={setEmailId}
                        />
                    )} */}
                    {!fullEmpData?.id && (
                        <>
                            <TextLabel>Team member email address</TextLabel>
                            <Input
                                iconPosition="left"
                                onChange={CheckEmailId}
                                // onfocusout={CheckEmailId}
                                onBlur={() => checkEmail()}
                                onFocus={() => setError(false)}
                                type="email"
                                value={emailId}
                                fluid
                                placeholder="Email"
                            >
                                <Icon name="at" />
                                <input type="email" value={emailId} />
                            </Input>
                        </>
                    )}

                    <MessageHolder>
                        {errorMessage && (
                            <Message
                                header="Email Error"
                                style={{ zIndex: 0 }}
                                size="small"
                                error
                                content={errorMessage}
                            />
                        )}
                    </MessageHolder>

                    {!error?.emailFound && getTeam() ? (
                        <>
                            {employeeFullname ? (
                                <TextLabel>
                                    Choose a team for {employeeFullname} to
                                    switch to.
                                </TextLabel>
                            ) : (
                                <TextLabel>Choose a team</TextLabel>
                            )}

                            <Select
                                placeholder="Select Team"
                                onChange={ChangeTeam}
                                value={selectedTeam}
                                isClearable
                                options={teamList}
                                isLoading={loadingTeam}
                                getOptionLabel={(option) =>
                                    `${option.name} - ${option.team_type_name}`
                                }
                                getOptionValue={(option) => option.id}
                            />
                        </>
                    ) : (
                        ""
                    )}
                    

                    {/* {selectedTeam?.id ? */}
                    {(userEmployee?.id === fullEmpData?.id) ?
                        (
                            <Message
                                header="Cannot Change for self"
                                style={{ zIndex: 0 }}
                                size="small"
                                error
                                content={
                                    "You cannot change roles for yourself"
                                }
                            />
                        )

                        :
                        <>

                            {(userEmployee?.id !== fullEmpData?.id) && 
                            !error?.emailFound && employeeRole.length > 0 ? (
                                <>
                                    <P>
                                        Choose what account type this user will have
                                        in{" "}
                                        {selectedTeam
                                            ? selectedTeam?.name
                                            : "this team "}
                                        .{" "}
                                    </P>

                                    {employeeRole.map((p, i) => (
                                        <Selection key={i}>
                                            <Checkbox
                                                checked={getSelected(p)}
                                                onChange={() =>
                                                    ChangeEmployeeRole(p)
                                                }
                                            />

                                            <Text>
                                                {p?.name}{" "}
                                                {p?.is_admin && " and Admin"}
                                            </Text>
                                        </Selection>
                                    ))}

                                </>
                            ) : (
                                <Message
                                    header="No Valid Roles "
                                    style={{ zIndex: 0 }}
                                    size="small"
                                    error
                                    content={
                                        `No Valid Roles of this Type or no Type Selected, 
                                        please select a different type from above.
                                        Please set team type by visiting the team tab and selecting 
                                        team type there`
                                    }
                                />
                            )
                            } </>}


                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                {!error?.emailFound && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                        }}
                    >
                        <CancelButton
                            onClick={() => setOpenTeamCard(false)}>
                            Cancel
                        </CancelButton>


                        {saveEnabled === 2 && (
                            <StyledButton disabled>Email Id Error</StyledButton>
                        )}
                        {saveEnabled === 3 && (
                            <StyledButton disabled>
                                Select a Valid Role
                            </StyledButton>
                        )}
                        {saveEnabled === 4 && (
                            <StyledButton disabled>
                                Only Admins can change this!
                            </StyledButton>
                        )}
                        {saveEnabled === 1 && (
                            <StyledButton onClick={SaveForm}>Save</StyledButton>
                        )}
                    </div>
                )}
            </Modal.Actions>
        </Modal>
    );
}

export default EditMember;

const ImageList = [
    ["user", "black"],
    ["users", "violet"],
    ["user outline", "orange"],
];

const CancelButton = styled.div`
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    color: #4a4a4a;
    font-family: "Red Hat Display", sans-serif;
`;
const P = styled.p`
    font-size: 16px;
    font-family: "Red Hat Display", sans-serif;
    margin-bottom: 2px;
`;
const TextLabel = styled.p`
    font-size: 16px;
    font-family: "Red Hat Display", sans-serif;
    margin: 15px 0 5px 0;
`;
const StyledButton = styled.button`
    height: 45px;
    border-radius: 8px;
    background-color: #6a00ff;
    background-color: ${(props) => (props.disabled ? "#ff1100" : "#6a00ff")};
    cursor: ${(props) => (props.disabled ? "#9b9b9b" : "#pointer")};
    color: white;
    font-size: 16px;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 7px 24px 7px 24px;
    border: none;
    outline: none;
    margin-left: 10px;
`;

const SubText = styled.div`
    font-size: 13px;
    font-family: "Red Hat Display", sans-serif;
    color: #4a4a4a;
    margin-bottom: 20px;
`;

const MessageHolder = styled.div`
    height: "30px";
`;

const Selection = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const Text = styled.div`
    font-family: "Red Hat Display", sans-serif;
    font-size: 14px;
    margin-left: 10px;
`;
