import React from "react";
import styled from "styled-components";

const PillarLabel = (props) => {
  const { pillar } = props;

  return (
    <PillarWrapper>
      <Title>CULTURE PILLAR</Title>
      <Pillar>{pillar?.title || pillar}</Pillar>
    </PillarWrapper>
  );
};

const PillarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: #666d79;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 16px;
  line-height: 128%;
`;

const Pillar = styled.div`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #2a3039;
`;

export default PillarLabel;
