import React from "react";
import styled, { keyframes } from "styled-components";

const COLOR_OPTIONS = {
  0: "#2C6BAA",
  1: "#27CDA7",
  2: "#EC6986",
  3: "#CC89E2",
  4: "#F5A623",
  5: "#2C6BAA",
  6: "#27CDA7",
  7: "#EC6986",
  8: "#CC89E2",
};

const AIChart = ({ data, delay, categoryName }) => {
  const sortedData = data.sort((a, b) => b.average - a.average);

  return (
    <ChartContainer delay={delay}>
      {/* Legend Section */}
      <ChartLegend>
        <LegendItem>
          <GlobalBenchmarkLegendItem></GlobalBenchmarkLegendItem>
          Global Norm
        </LegendItem>
        <LegendItem>
          <CurrentGroupLegendItem></CurrentGroupLegendItem>
          {categoryName || "Your Department"}
        </LegendItem>
      </ChartLegend>
      {sortedData.map((item, index) => (
        <ChartRow key={index}>
          <ChartQuestion>
            {index + 1}. {item.question}
          </ChartQuestion>
          <ChartBars>
            <BarWrapper>
              <CurrentGroup
                style={{
                  width: `${item.average * 10}%`,
                  backgroundImage: `linear-gradient(to right, #40B7C2, #468ADE`,
                }}
              ></CurrentGroup>
              {(item.average * 10).toFixed(1)}%
            </BarWrapper>
            <BarWrapper>
              <GlobalBenchmark
                style={{
                  width: `${item.global_average * 10}%`,
                  backgroundColor: "#C7CFDB",
                }}
              ></GlobalBenchmark>
              {(item.global_average * 10).toFixed(1)}%
            </BarWrapper>
          </ChartBars>
        </ChartRow>
      ))}
    </ChartContainer>
  );
};

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const ChartContainer = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "Raleway";
  margin-top: 20px;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const ChartRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 20px;
  width: 100%;
`;

const ChartQuestion = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;

  /* Style your question text */
  max-width: 40%;
  min-width: 40%;

  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
`;

const ChartBars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const GlobalBenchmark = styled.div`
  height: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  position: relative;
  // background-image: repeating-linear-gradient(
  //   45deg,
  //   transparent,
  //   transparent 10px,
  //   rgba(255, 255, 255, 0.5) 10px,
  //   rgba(255, 255, 255, 0.5) 20px
  // );
  border-radius: 6px;
  margin-right: 10px;
`;

const CurrentGroup = styled.div`
  height: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
  position: relative;
  border-radius: 6px;
  margin-right: 10px;
`;

const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  width: 100%;
`;

const ChartLegend = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  width: 100%;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
`;

const GlobalBenchmarkLegendItem = styled.span`
  background-color: #c7cfdb; /* Same color as your global benchmark bar */
  height: 5px;
  width: 20px;
  border-radius: 5px;
  // background-image: repeating-linear-gradient(
  //   45deg,
  //   transparent,
  //   transparent 3px,
  //   rgba(255, 255, 255, 0.5) 3px,
  //   rgba(255, 255, 255, 0.5) 6px
  // );
`;

const CurrentGroupLegendItem = styled.span`
  background-color: #468ADE;
  height: 5px;
  width: 20px;
  border-radius: 5px;
`;

export default AIChart;
