import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Input, Button, Loader, Dimmer, Form, TextArea } from "semantic-ui-react";
import styled from "styled-components";

import {
    SG_POST_CHAT_GPT,
} from "constants/actions";

const QueryChatGpt = () => {
    const dispatch = useDispatch();

    const [formErrors, setFormErrors] = useState([]);
    const [pristine, setPristine] = useState(true);


    const [text, setText] = useState("");
    const [prompt, setPrompt] = useState("");

    const [loading, setLoading] = useState(false);
    const [chatGptResp, setChatGptResp] = useState({})

    function FetchChatGpt() {
        if (formErrors?.errors > 0) {
            setPristine(false);
            return;
        }

        setLoading(true)
        dispatch({
            type: SG_POST_CHAT_GPT,
            payload: {
                request: {
                    gpt_text: text,
                    prompt: prompt
                }
            },
        });
    }

    function ClearChatGpt() {
        setFormErrors([])
        setPristine(true);
        setText('')
        setPrompt('')
        setLoading(false)
    }

    const {
        get_chat_gpt_resp,
    } = useSelector(
        (state) => ({
            get_chat_gpt_resp: state.chat_gpt.chat_gpt,
        }),
        shallowEqual
    );

    useEffect(() => {
        setLoading(get_chat_gpt_resp.pending)
        setChatGptResp(get_chat_gpt_resp)
    }, [get_chat_gpt_resp]);


    useEffect(() => {
        const ErrorList = [];
        let totalErrors = 0
        if (text?.length === 0) {
            ErrorList["text"] = {
                error: true,
                message: "Please enter the content"
            };
            totalErrors += 1
        }
        if (prompt?.length === 0) {
            ErrorList["prompt"] = {
                error: true,
                message: "Please enter the prompt"
            };
            totalErrors += 1
        }
        ErrorList['errors'] = totalErrors
        setFormErrors(ErrorList);
    }, [text, prompt, pristine]);

    return (
        <Container>
            <Header>Get OPEN AI Summary</Header>
            <Form>
                <Header>Text Content</Header>
                <TextArea rows={2} placeholder='Text'
                    onChange={(e) => setText(e.target.value)} >
                    {text}
                </TextArea>
                {formErrors.text?.error && !pristine && (
                    <ErrorMessage>
                        {formErrors.text?.message}
                    </ErrorMessage>

                )}
                <Header>Write your prompt here</Header>
                <Input
                    value={prompt}
                    fluid
                    onChange={(e) => setPrompt(e.target.value)}
                    style={{ width: 600, marginRight: 10 }}
                />
                {formErrors.prompt?.error && !pristine && (
                    <ErrorMessage>
                        {formErrors.prompt?.message}
                    </ErrorMessage>

                )}
                <SpacedDiv>

                    <Button color="red" onClick={FetchChatGpt}>
                        Submit
                    </Button>

                    <Button secondary onClick={ClearChatGpt}>
                        Clear
                    </Button>
                </SpacedDiv>




                <Dimmer active={loading}>
                    <Loader />

                </Dimmer>
                

                    <Summary>
                      <Response>
                         Message:
                        </Response> <pre>
                            {JSON.stringify(chatGptResp?.message)}
                        </pre>

                    </Summary>
                    <Summary>
                        <Response>
                            AI Summary
                        </Response>
                        <pre>
                            {JSON.stringify(chatGptResp?.response?.ai_result)}
                        </pre>

                    </Summary>

                    <Response>Full Response</Response>
                    <pre>
                        {JSON.stringify(chatGptResp)}
                    </pre>
               
            </Form>
        </Container>
    );
};

export default QueryChatGpt;

const Container = styled.div`
  width: 100%;
  padding-left: 58px;
  margin-top: 30px;
`;

const Header = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #7e7e7e;
`;

const Response = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  max-width: 800px;
  color: #7e7e7e;
//   margin-top: 30px;
`;

const Summary = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #7e7e7e;
`;

const SpacedDiv = styled.div`
  padding: 20px 0px;
`;

const ErrorMessage = styled.div`
    color: red;

`