import Chart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  grandientsAboveNorm,
  dataLabels,
  orgColorGradient,
  grandientsBelowNorm,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subsubtext,
  Subtext,
} from "reports/DataStories/common/styles";

const OverallFactorScores = (props) => {
  // TODO: Replace with the name of the department
  const departmentName =
    props?.calculatedData?.department_name || "Your department";
  // Create a new array of objects containing the data for the chart and the associated labels at the same index of the array
  const mappedData = props?.calculatedData?.factor_scores?.map(
    (value, index) => {
      return {
        label: value.name,
        value: Math.round(value.score * 10),
      };
    }
  );

  // Sort the array of objects by the value in descending order
  const sortedData = mappedData.sort((a, b) => b.value - a.value);

  // Get the lowest value in the mappedData array
  const lowestValue = Math.round(
    Math.min(...mappedData.map((item) => item.value))
  );
  // Get the highest value in the mappedData array
  const highestValue = Math.round(
    Math.max(...mappedData.map((item) => item.value))
  );

  // Create a new array of labels and data from the sorted array of objects
  const sortedLabels = sortedData.map((item) => item.label);
  const sortedValues = sortedData.map((item) => item.value.toFixed(2));

  const gradientColors = sortedValues.map((value) => {
    if (value === 0) {
      return orgColorGradient;
    } else if (value > 0) {
      return grandientsAboveNorm;
    } else {
      return grandientsBelowNorm;
    }
  });

  const optionsConfig = {
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 800,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#40B7C2", "#468ADE"],
        inverseColors: true,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 100],
        colorStops: gradientColors,
      },
    },
    xaxis: {
      categories: sortedLabels,
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
      title: {
        text: "% difference from the global norm",
        style: {
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      min: lowestValue,
      max: highestValue,
      decimalsInFloat: 1,
      forceNiceScale: true,
      title: {
        style: {
          fontWeight: 400,
        },
      },
    },
    annotations: {
      yaxis: [
        {
          y: 0,
        },
      ],
      xaxis: [
        {
          x: 0,
        },
      ],
    },
    dataLabels: dataLabels,
  };

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={1}>
        Culture factor performance
      </ScreenTitleExtended>
      <SubTextExtended delay={1.3}>
        Below is a breakdown of all culture factors in the {departmentName}{" "}
        department, represented by the percentage they each differ from the
        global norms. These are the factors that contribute to your overall
        culture score. Each culture factor was measured using various (typically
        3) items within the assessment. These items are then aggregated to
        generate a score for each factor, which are then compared against known
        benchmarks on the same items.
      </SubTextExtended>
      <SubsubtextExtended delay={1.5}></SubsubtextExtended>
      <ChartWrapper style={{ marginBottom: 30 }} delay={1.7}>
        <Chart
          options={optionsConfig}
          series={[{ data: sortedValues }]}
          type="bar"
          height={450}
        />
      </ChartWrapper>
    </ScreenContainer>
  );
};

export default OverallFactorScores;

const ChartWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const ScreenTitleExtended = styled(ScreenTitle)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SubTextExtended = styled(Subtext)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SubsubtextExtended = styled(Subsubtext)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;