import styled from "styled-components";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import PDFLayout from "./PDFLayout";

const ActionPlanPDF = (props) => {
  return (
    <PDFViewer width={800} height={900}>
      <PDFLayout {...props} />
    </PDFViewer>
  );
};

export default ActionPlanPDF;

const Container = styled.div`
  padding-left: 58px;
  margin-top: 50px;
`;

const Button = styled.div``;
