import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

const Insight = (props) => {
	return (
		<Container>
			<Title>{props.title}</Title>
			<Description>{props.description}</Description>
		</Container>
	);
};

export default Insight;

const Container = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding: 20px;
	width: calc(33% - 5px);
	font-family: "Barlow", sans-serif;
	margin-top: 10px;
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
`;

const Description = styled.div`
	font-weight: 300;
`;
