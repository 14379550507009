import React,{useState} from 'react';
import styled from 'styled-components';
import Box from './Box';
import { Dropdown } from 'semantic-ui-react'

const Selection = ({setStep}) =>{

    return (
        <Container>
            <Text>Select the focus for the change agent.</Text>


            <Row>
            <SelectContainer>
            <T2>Data Factor</T2>

                <Dropdown 
                        placeholder='Select a factor'
                        fluid
                        selection
                        options={friendOptions}/>
            </SelectContainer>    
           
            <SelectContainer>
            <T2>Data Category</T2>
                <Dropdown 
                        placeholder='Select a category'
                        fluid
                        selection
                        options={friendOptions2}/>
             </SelectContainer> 
            </Row>
            

            <Box category={"Department A"} score={'95th'}  onClick={()=>setStep(2)}/>
            <Box category={"Department B"} score={'93rd'}  onClick={()=>setStep(2)}/>
            <Box category={"Department C"} score={'90th'}  onClick={()=>setStep(2)}/>
            <Box category={"Department D"} score={'89th'}  onClick={()=>setStep(2)}/>


        </Container>
    )
}

export default Selection;

const Container = styled.div`
    font-family:'Raleway';
`

const T2 = styled.div`
    font-size:14px;
`

const Text = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:40px;
`
const Row = styled.div`
    display:flex;
    margin-bottom:40px;
`

const SelectContainer = styled.div`
    width:300px;
    margin-right:30px;
`


const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`

const Button = styled.div`
    background:blue;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-left:20px;
`


const friendOptions = [
    {
      key: 'Culture',
      text: 'Culture',
      value: 'Culture',
    },
    {
      key: 'Retention',
      text: 'Retention',
      value: 'Retention',
    },
    {
      key: 'Well-Being',
      text: 'Well-Being',
      value: 'Well-Being',
    },
    {
        key: 'Engagament',
        text: 'Engagament',
        value: 'Engagament',
      },
      {
        key: 'Customer Satisfaction',
        text: 'Customer Satisfaction',
        value: 'Customer Satisfaction',
      },
      {
        key: 'Store Size',
        text: 'Store Size',
        value: 'Store Size',
      }
  ]


  const friendOptions2 = [
    {
      key: 'Store Locations',
      text: 'Store Locations',
      value: 'Store Locations',
    },
    {
      key: 'Departments',
      text: 'Departments',
      value: 'Departments',
    },
    {
      key: 'Franchises',
      text: 'Franchises',
      value: 'Franchises',
    }
  ]