import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    border: "0.5px solid grey",
    marginTop: 20,
  },
  TH: {
    fontSize: 10,
    textTransform: "uppercase",
    color: "white",
    fontWeight: 500,
    padding: 3,
  },
  H: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: 3,
  },
  H1: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  TD: {
    fontSize: 10,
    padding: 4,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
    marginBottom: 30,
  },
});

const Table = ({
  data,
  headerColor,
  wrapRow = true,
  hideReverseText = true,
  isSportLaw,
}) => {
  return (
    <View>
      <View
        style={[styles.page, { marginBottom: (hideReverseText && 30) || 10 }]}
        wrap={true}
      >
        <View style={styles.H1}>
          {data.rows.map((item) => (
            <View
              style={[
                {
                  width: item.width,
                  backgroundColor: isSportLaw ? headerColor : "#2B6EDF",
                },
                styles.TH,
              ]}
            >
              <Text>{item.title}</Text>
            </View>
          ))}
        </View>
        <View style={styles.body}>
          {data.data.map((item, x) => {
            return (
              <View
                style={[
                  {
                    backgroundColor:
                      x % 2 == 0
                        ? !isSportLaw
                          ? "#F0F5FE"
                          : "#F1F1F7"
                        : "white",
                  },
                  styles.H,
                ]}
                wrap={true}
              >
                {item.map((row, i) => (
                  <View
                    style={[
                      {
                        width: data.rows[i].width,
                      },
                      styles.TD,
                    ]}
                  >
                    <Text>{row}</Text>
                  </View>
                ))}
              </View>
            );
          })}
        </View>
      </View>
      {/* {!hideReverseText && (
        <Text style={styles.tableSubText}>
          **These scores are reversed scored but are presented as they were
          answered
        </Text>
      )} */}
    </View>
  );
};

export default Table;
