import React from 'react';
import styled, { keyframes } from "styled-components";
import {Modal} from 'semantic-ui-react';

const QuoteModal = ({quote,close,quoteData}) => {

    
// Utility to parse inline Markdown (bold)
const parseInlineMarkdown = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = [];
    let lastIndex = 0;
  
    text.replace(boldRegex, (match, p1, offset) => {
      if (offset > lastIndex) {
        parts.push(text.slice(lastIndex, offset));
      }
      parts.push(<strong key={offset}>{p1}</strong>);
      lastIndex = offset + match.length;
    });
  
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }
  
    return parts;
};

  
// Utility to parse Markdown with nested lists
const parseMarkdown = (markdown) => {
    if (!markdown) {
      return null;
    }
  
    const lines = markdown.split('\n');
    const elements = [];
    let currentList = null;
    let sublist = null;
    let sublist2 = null
    let sublist3 = null
  
    lines.forEach((line, index) => {
      if (line.startsWith('# ')) {
        elements.push(<H1 key={index}>{line.slice(2)}</H1>);
      } else if (line.startsWith('## ')) {
        elements.push(<H2 key={index}>{line.slice(3)}</H2>);
      } else if (line.startsWith('### ')) {
        elements.push(<TextComponent text={line.slice(4)} />);
      } else if (line.startsWith('##### ')) {
        elements.push(<h3 key={index}>{line.slice(6)}</h3>);
      } else if (line.startsWith('- ')) {
        // Parent list
        if (!currentList) {
          currentList = [];
          elements.push(<ul key={`ul-${index}`}>{currentList}</ul>);
        }
        if (sublist) {
          sublist = null; // End the sublist if previously active
        }
        currentList.push(<LI key={index}>{parseInlineMarkdown(line.slice(2))}</LI>);
      } else if (line.startsWith('  - ')) {
        // Sublist detection: nested list
        if (!sublist) {
          sublist = [];
          if (currentList) {
            currentList.push(<ul key={`sub-ul-${index}`}>{sublist}</ul>);
          }
        }
        sublist.push(<LI key={index}>{parseInlineMarkdown(line.slice(4))}</LI>);
      } 
      else if (line.startsWith('   - ')) {
        // Sublist detection: nested list
        if (!sublist2) {
            sublist2 = [];
        }
       
        sublist2.push(<LI key={index}>{parseInlineMarkdown(line.slice(5))}</LI>);
      } 
      else if (line.startsWith('     - ')) {
        // Sublist detection: nested list
        if (!sublist3) {
          sublist3 = [];
          if(sublist2){
            sublist2.push(<ul key={`sub-ul-${index}`}>{sublist3}</ul>);
          }
          
        }
        sublist3.push(<LI key={index}>{parseInlineMarkdown(line.slice(6))}</LI>);
      } 
      
      else {
        if (currentList || sublist || sublist2) {
            if(sublist2){
                elements.push(<ul key={`sub-ul-${index}`}>{sublist2}</ul>);
            }
          currentList = null; // End the current list
          sublist = null; // End the sublist
          sublist2 = null;
          sublist3 = null;
        }
        elements.push(<p key={index}>{parseInlineMarkdown(line)}</p>);
      }
    });
  
    return elements;
  };

    return (
        <Modal open={quote!==null} onClose={close}>
            <Modal.Content style={{padding:30}}>
                <Small>
                    Supporting Evidence
                </Small>
                <Title>
                    {quote?.effect}
                </Title>

                <Description>We've summarized the information presented from the feedback most related to this theme.</Description>
                
                <Summary>
                    {parseMarkdown(quoteData?.[quote?.row]?.summary)}
                </Summary>
                
              
            </Modal.Content>
        </Modal>
    )
}

export default QuoteModal;

const Title = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-bottom:5px;
    font-family:"Raleway";
`

const Quote = styled.li`
    margin-bottom:10px;
    font-family:"Raleway";
`

const QuoteText = styled.div`
    font-size:14px;
    font-weight:bold;
    color:#2A3039;
    line-height:1.6;
`

const Description = styled.div`
    font-size:14px;
    color:#2A3039;
    margin-bottom:20px;
    font-family:"Raleway";
`

const Small = styled.div`
    text-transform:uppercase;
    font-size:12px;
    font-weight:bold;
    color:#2D70E2;
`

const Summary = styled.div` 
    white-space: pre-wrap;
        font-family:"Raleway";

`

const H1 = styled.h1`

`

const H2 = styled.h2`

`

const TextComponent = styled.div`

`

const LI = styled.li`

`

