import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/stock/Placeholder.jpg'

const FactorExplain = () =>{

    return (
        <Container>
            

            <T2>Why culture matters here...</T2>

            <IMG src={Image}/>
            {/* <iframe
            width="100%"
            height="400"
            src={'https://www.youtube.com/embed/RcGkHrPSzDc'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            /> */}

          
        </Container>
    )

}

export default FactorExplain

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    line-height:1.4;
`

const Description = styled.div`
    margin-bottom: 40px;
`
const D2 = styled.div`
    margin-bottom:20px;
`

const IMG = styled.img`
    width:100%;
    margin-bottom:10px;
`