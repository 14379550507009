import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import AICopilot from "app/audit/simple/components/AICopilot";
import Spark from "assets/images/UI/mdi_sparkles-outline.svg";
import Filters from "app/audit/simple/Filters";
import { useSelector, shallowEqual } from "react-redux";

const removeFeedback = (question) => {
  return question.replace(/\*\*Feedback:\*\*.*$/, "").trim();
};

const compileFeedback = (feedback) => {
  const compiledFeedback = [];

  feedback.forEach((item) => {
    const { question, response, follow_up } = item.response;
    const feedbackObj = {
      type: "Questionnaire",
      question,
      feedback: response,
      follow_up: follow_up
        ? {
            question: removeFeedback(follow_up?.question),
            feedback: follow_up?.response,
          }
        : null,
    };
    compiledFeedback.push(feedbackObj);
  });

  return compiledFeedback;
};

const extractQuestions = (data) => {
  const extractedQuestions = [];

  data.forEach((item, index) => {
    const isAiEnhanced = item.aiEnhanced;
    extractedQuestions.push({
      key: item.id,
      text: isAiEnhanced
        ? `${item.question} **Includes AI Follow Up**`
        : item.question,
      value: index,
    });
  });

  return extractedQuestions;
};

const Question = ({ responses, employees, template, selectEmpCb }) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [filteredResponses, setFilteredResponses] = useState([]);
  const [copilot, setCopilot] = useState(false);
  const [copilotData, setCopilotData] = useState(null);
  const { selectedFilters, core_data } = useSelector(
    (state) => ({
      selectedFilters: state.audit?.new_filters,
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (filteredResponses) {
      const feedback = compileFeedback(filteredResponses);
      setCopilotData(feedback);
    }
  }, [filteredResponses]);

  const filterUsers = (filters, users) => {
    return users
      .filter((user) => {
        return filters.some((filter) => {
          return user.category.some((category) => {
            return (
              category.name_id === filter.parent &&
              category.value_id === filter.id
            );
          });
        });
      })
      .map((user) => user.employee_id);
  };

  useEffect(() => {
    if (selectedId !== null && responses?.length > 0) {
      let filteredResponses = responses.map((response) => {
        return {
          employee: employees?.employees?.find(
            (employee) => employee.id === response.employee
          ),
          response: response.task_response?.task_list?.[selectedId],
        };
      });
      if (selectedFilters?.length > 0) {
        const validEmployeeIds = filterUsers(
          selectedFilters,
          core_data?.orgCategories
        );

        filteredResponses = filteredResponses.filter((response) =>
          validEmployeeIds.includes(response.employee?.id)
        );
      }
      setFilteredResponses(filteredResponses);
    }
  }, [selectedId, selectedFilters]);

  useEffect(() => {
    if (template?.task_template_user_tasks?.user_tasks) {
      const templateQuestions =
        template?.task_template_user_tasks?.user_tasks?.filter(
          (task) => task?.task_type === "question"
        );
      const questions = extractQuestions(templateQuestions);
      setOptions(questions);

      // Set the first question as selected by default if questions are available
      if (questions.length > 0) {
        setSelected(questions[0].value);
        setSelectedId(questions[0].value);
      }
    }
  }, [template]);

  const handleSelect = (key) => {
    setSelected(key);
    setSelectedId(key);
  };

  const viewEmployeeResponses = (employee) => {
    selectEmpCb(employee);
  };

  const copilotButton = (
    <AIButton onClick={() => setCopilot(true)}>
      Use the culture copilot <IMG2 src={Spark} />
    </AIButton>
  );

  return (
    <Container>
      <TopBar>
        <Filters />
        {copilot && (
          <AICopilot
            isQuestionaire={true}
            feedbackData={copilotData}
            open={copilot}
            setOpen={setCopilot}
          />
        )}
      </TopBar>
      <Title>Questions</Title>
      <Description>
        Select a question from your custom survey to see all the responses.
      </Description>
      <Dropdown
        placeholder="Select Question"
        fluid
        search
        selection
        value={selected}
        options={options}
        onChange={(e, { value }) => {
          handleSelect(value);
        }}
        style={{ marginBottom: 30, lineHeight: 1.6 }}
      />

      <TitleSection>
        <Title>Responses</Title>
        {copilotButton}
      </TitleSection>
      {filteredResponses.length === 0 && (
        <div>Select a question to view responses</div>
      )}
      {(filteredResponses?.length > 0 &&
        filteredResponses.map((response, i) => {
          const { employee, response: taskResponse } = response;

          if(!employee?.first_name || !employee?.last_name) return null;


          return (
            <Response key={i}>
              <Name
                onClick={() => viewEmployeeResponses(response?.employee?.id)}
              >
                <InitialsCircle
                  firstName={employee.first_name}
                  lastName={employee.last_name}
                />
                <NameText>
                  {employee.first_name} {employee.last_name}
                </NameText>
              </Name>
              {/* <QuestionField>{taskResponse?.question}</QuestionField> */}
              <Answer>{taskResponse?.response}</Answer>
              {taskResponse?.follow_up && (
                <React.Fragment key={i}>
                  <AITag>AI Follow up</AITag>
                  <Follow>
                    {removeFeedback(taskResponse?.follow_up?.question)}
                  </Follow>
                  <Answer>{taskResponse?.follow_up?.response}</Answer>
                </React.Fragment>
              )}
            </Response>
          );
        })) || <div>No responses found</div>}
    </Container>
  );
};

export default Question;

// Function to get initials from the name
const getInitials = (firstName, lastName) => {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#e74c3c",
  "#e67e22",
  "#f1c40f",
  "#e84393",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
];

// Hash function to consistently map initials to a color
const hashStringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return colors[hash % colors.length];
};

const InitialsCircle = ({ firstName, lastName }) => {
  const initials = getInitials(firstName, lastName);
  const color = hashStringToColor(initials);

  return <Circle bgColor={color}>{initials}</Circle>;
};

const Container = styled.div`
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  max-width: 800px;
`;

const Description = styled.div``;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Response = styled.div`
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const NameText = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const Answer = styled.div`
  margin-bottom: 10px;
  line-height: 1.6;
  white-space: pre-wrap;
`;

const IMG2 = styled.img`
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
`;

const Follow = styled.div`
  font-weight: bold;
  font-style: italic;
  margin-bottom: 10px;
`;

// Styled component for the circle
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-right: 10px;
`;

const QuestionField = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const AIButton = styled.div`
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
  min-width: 200px;
  max-height: 45px;
  margin-left: 20px;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

// Define the styled component for the Chip
const AITag = styled.div`
  color: blue;
  text-transform: uppercase;
  font-size: 10px;
  font-weight:bold;
  margin-top:20px;
`;