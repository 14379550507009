import styled, { keyframes } from 'styled-components';
import React from "react";
import SelectItem from './SelectItem'


const Component9 = () =>{
    const [selected,setSelected] = React.useState(false)

    return (
        <Container>

            <T1>Which action would you like to focus on, to address your need?</T1>
            <Text delay={0.5}>
            This action will help guide you in achieving the outcome you've selected.
            </Text>

            <SelectArea delay={1}>
            <SelectItem 
                selected={selected==1}
                setSelected={()=>setSelected(1)}
                title="Ask for Feedback"
                item="Encourage employees to provide feedback on your leadership style and departmental processes. You can ask, 'What do you think we can do better as a team, and how can I support you more effectively?'" />

            <SelectItem 
                selected={selected==2}
                setSelected={()=>setSelected(2)}
                title="Promote Disagreement" 
                item="Make it clear that differing opinions are not only accepted but valued. Ask employees for their perspectives on specific issues and genuinely consider their input. For instance, you could say, 'I'd like to hear your thoughts on this project. Do you see any potential issues or improvements we should consider?'" />

            <SelectItem 
                selected={selected==3}
                setSelected={()=>setSelected(3)}
                title="Normalize Disagreement" 
                item="Share stories of successful outcomes that resulted from healthy disagreements within the team. Highlight that constructive dissent can lead to better decision-making and innovation." />

           
        </SelectArea>
           

            
        </Container>
    )
}

export default Component9

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`



const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:30px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`


const SelectArea = styled.div`
opacity:0;
animation: ${fadeIn} 1s ease-out forwards;
animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`