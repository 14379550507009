import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { Icon, Message, Breadcrumb, Dimmer, Loader, Segment, Button } from "semantic-ui-react";
// import { Link, useParams } from "react-router-dom";


import EditMember from "settings/member_directory/Members/EditMember";
import MemberCard from "./memberCard";
import Edit from "./Edit";
import TeamAccessList from "settings/member_directory/TeamAccess/List"



import "settings/setting.css";


const TeamSettings = (props) => {
  const { teamLeader, administrator, userEmployee,
    setOpenTeamCard, openTeamCard,
    employee, setFullEmpData,
    setLoading,
    BackFromTeam,
    } = props

  // const [addMember, setAddMember] = React.useState(false);
  // const [invitedMember, setInviteMember] = React.useState(false);
  // const [role, setRole] = React.useState(11);
  const [active, setActive] = React.useState(false)
  const [inverted, setInverted] = React.useState(false)

  const selectDept = (dept) => {
    BackFromTeam()
    props.selectDepartment(dept);
  };

  // const inviteLeader = () => {
  //   setRole(5);
  //   setAddMember(true);
  // };

  // const inviteMember = () => {
  //   setRole(9);
  //   setAddMember(false);
  //   setInviteMember(true);
  // };

  // const addMembers = () => {
  //   setRole(9);
  //   setInviteMember(false);
  //   setAddMember(true);
  // };

  React.useEffect(() => {
    // if the response is ok 200
    setActive(true)
    setInverted(true)
    setTimeout(
      () => {
        // console.log('Hello after 5 seconds');
        setActive(false)
        setInverted(false)
      },
      2 * 1000
    );

    // 
  }, [props.teamUpdate]);

  // console.log(props)

return (
    <Container>
      {/* team and department */}
      {!props?.teamLeader && props.team?.department ? (
        <Breadcrumb style={{ marginBottom: 20, maxWidth: "80%" }}>
          <Breadcrumb.Section
            link
            onClick={() => selectDept(props.team.department)}
          >
            {props.team.department_name} Department
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>{props.team.name} Team</Breadcrumb.Section>
        </Breadcrumb>
      ) : (
        ""
      )}

      <Title>
        {" "}
        
        {(administrator) &&
          <Icon
            name="arrow left"
            size={"small"}
            style={{ color: "#4a4a4a", cursor: "pointer" }}
            onClick={BackFromTeam}
          />
        }

        {/* } */}
        {" "}
        {props?.team?.name} Settings
      </Title>

      {teamLeader && <EditContainer>
        <Edit
          teamUpdate={props.teamUpdate}
          setTeamUpdate={props.setTeamUpdate}
          data={props.team}
          selectDept={(d) => selectDept(d)}
          back={BackFromTeam}
        />
      </EditContainer>}
      {teamLeader && props.team?.leader_can_invite}

      <Segment basic>
        <Dimmer active={active} inverted={inverted}>
          <Loader size='large'>Loading</Loader>
        </Dimmer>

        {administrator &&
          <TeamAccessList
            teamId={props?.team?.id} />
        }

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 60,
          }}
        >

          <Table>
            <Headers>
              <TH width={30} mobile={false}>
                Member
              </TH>
              <TH width={40} mobile={false}>
                Access
              </TH>
              <TH width={30} mobile={true}>
                <EditMember
                  teamId={props?.team?.id}
                  setOpenTeamCard={setOpenTeamCard}
                  openTeamCard={openTeamCard}
                  employee={employee} 
                  setFullEmpData={setFullEmpData}
                  userEmployee={userEmployee}
                  setLoading={setLoading}
                  trigger={<StyledButton>Invite Member</StyledButton>} />

              </TH>
            </Headers>



           
              {props.members.map((item, i) => {
                return <MemberCard
                  key={i}
                  data={item}
                  id={item.email + i}
                  teamLeader={props?.teamLeader}
                  userEmployee={userEmployee}

                  setOpenTeamCard={setOpenTeamCard}
                  openTeamCard={openTeamCard}
                  employee={employee} 
                  setFullEmpData={setFullEmpData}
                  setLoading={setLoading}
                  
                />;
              })}

          </Table>
        </div>
      </Segment>

    </Container>
  );
};

export default TeamSettings;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const StyledButton = styled.div`

  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px 10px;
`;

const Remove = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ff2d55;
  cursor: pointer;
  margin-left: 24px;
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    width: 100%;
  }
`;
const View = styled.span`
  font-size: 13px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-color: #7500ff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  padding: 4px 8px 4px 8px;
  border-radius: 10px;
`;

const AlertText = styled.span`
  font-size: 13px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-color: red;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  padding: 4px 8px 4px 8px;
  border-radius: 10px;
`;

const Linked = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Container = styled.div`
  width: 100%;
  padding: 71px 86px 0 50px;
  height: 90vh;
  position: relative;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const EditContainer = styled.div`
  position: absolute;
  top: 71px;
  right: 86px;

  @media (max-width: 768px) {
    right: 10px;
    top: 0;
  }
`;
