import {
  CLEAR_ALL,
  GET_DATA_STORY_LOGS,
  ADD_DATA_STORY_LOG,
  FETCH_DATA_STORY_LOG,
  DELETE_DATA_STORY_LOG,
} from "../constants/actions";

const initialState = {
  data_story_logs: [],
  data_story_log: {},
  pending: false,
  created: false,
};

export default function StoryLogging(state = initialState, action) {
  switch (action.type) {
    case GET_DATA_STORY_LOGS:
      return {
        ...state,
        data_story_logs: action.payload,
        pending: false,
      };
    case ADD_DATA_STORY_LOG:
      return {
        ...state,
        data_story_logs: [action.payload, ...state.data_story_logs],
        pending: false,
        created: true,
      };
    case FETCH_DATA_STORY_LOG:
      return {
        ...state,
        data_story_log: action.payload,
        pending: false,
      };
    case DELETE_DATA_STORY_LOG:
      const filteredDataStoryLogs = state.data_story_logs.filter(
        (data_story_log) => data_story_log.id !== action.payload
      );
      return {
        ...state,
        data_story_logs: filteredDataStoryLogs,
        pending: false,
      };
    case CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
