import styled, { keyframes } from "styled-components";
import React from "react";

const Introduction = (props) => {
  const { title, description } = props;
  return (
    <Container>
      <Title>{title}</Title>
      <Text delay={0.3}>{description}</Text>
    </Container>
  );
};

export default Introduction;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  font-family: "Raleway";
  padding-top: 20px;
`;

const Text = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  line-height: 1.5;

  animation: ${fadeIn} 1s ease-out forwards;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;

  animation: ${fadeIn} 1s ease-out forwards;
`;

const T2 = styled.div`
  font-size: 12px;
  color: #2a3039;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
`;
