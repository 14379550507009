import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: 12,
    textAlign: "left",
    paddingBottom: 5,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 400,
    paddingBottom: 5,
    textAlign: "left",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    paddingBottom: 5,
    minHeight: 80,
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 600,
    width: "50%",
    alignItems: "center",
    display: "flex",
  },
  tableCol: {
    width: "50%",
    display: "flex",
    position: "relative",
    fontSize: 10,
    flexDirection: "column",
  },
  colTitle: {
    fontSize: 8,
    position: "absolute",
    top: 3,
    left: 0,
    fontWeight: 600,
    padding: 3,
    color: "#2a3039",
  },
  tableData: {
    marginTop: 15,
    marginLeft: 5,
  },
});

const ReflectionTable = (props) => {
  const { headers, title, subtitle, rows, data, id, dynamic } = props;

  const rowLoop = dynamic ? data?.planData[id] : rows;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.subtitle}>{subtitle}</Text>
      <View style={styles.tableContainer}>
        <View style={styles.topRow}>
          {headers.map((header) => (
            <Text style={styles.tableHeader}>{header}</Text>
          ))}
        </View>
        {rowLoop?.map((r, i) => {
          const altBackground = i % 2 === 0;

          return (
            <View
              style={[
                styles.tableRow,
                {
                  backgroundColor: altBackground ? "#F8FAFF" : "#fff",
                },
              ]}
            >
              <View style={styles.tableCol}>
                {!dynamic && <Text style={styles.colTitle}>{rows[i]}</Text>}
                <Text style={styles.tableData}>
                  {data?.planData[id]?.[i]?.[0] || ""}
                </Text>
              </View>
              <View style={styles.tableCol}>
                {!dynamic && <Text style={styles.colTitle}>{rows[i]}</Text>}
                <Text style={styles.tableData}>
                  {data?.planData[id]?.[i]?.[1] || ""}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default ReflectionTable;
