import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import Table from "./Table";
import Side from "./Side";
import Footer from "./Footer";
import { getGrade } from "../../utils/cultureUtils";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

Font.register({
  family: "Barlow",
  src: Barlow,
  format: "truetype",
});

const factorTitle = [
  [
    {
      id: 1,
      abbv: "Mental",
      fr_abbr: "Mentale",
      color: "#8EC7D2",
    },
    {
      id: 2,
      abbv: "Physical",
      fr_abbr: "Physique",
      color: "#DBA507",
    },
    {
      id: 3,
      abbv: "Psychological Safety",
      fr_abbr: "Sécurité psychologique",
      color: "#33691e",
    },
    {
      id: 4,
      abbv: "Safe Sport",
      fr_abbr: "Sport sûr",
      color: "#8c1c03",
    },
    {
      id: 5,
      abbv: "Self-Determination",
      fr_abbr: "Autodétermination",
      color: "#ba34eb",
    },
  ],
  [
    {
      id: 1,
      abbv: "Leadership",
      fr_abbr: "Leadership",
      color: "#002253",
    },
    {
      id: 2,
      abbv: "Coaching",
      fr_abbr: "Encadrement",
      color: "#ff5722",
    },
    {
      id: 3,
      abbv: "DTE",
      fr_abbr: "DTE",
      color: "#4D2C29",
    },
    {
      id: 4,
      abbv: "SSSM",
      fr_abbr: "SSSM",
      color: "#00a388",
    },
    {
      id: 5,
      abbv: "Pathways",
      fr_abbr: "Parcours",
      color: "#374140",
    },
    {
      id: 6,
      abbv: "Results",
      fr_abbr: "Résultats",
      color: "#f2b705",
    },
  ],
];

const getHeaderLetter = (name) => {
  switch (name) {
    case "Athlete":
      return "A";
    case "Coach":
      return "C";
    case "Entraîneur(euse)":
      return "E";
    case "Sport Science":
      return "SSM";
    case "Support Staff":
      return "NSO";
    case "Sport Science/Medicine":
      return "SSM";
    case "NSO Staff":
      return "NSO";
    case "Personnel de l'ONS":
      return "ONS";
    case "Science du sport/Médecine":
      return "SSM";
    case "Autre":
      return "Au";
    case "Other":
      return "O";
    default:
      return name.charAt(0);
  }
};

const Risks = ({
  data,
  question,
  categories,
  anchor,
  titleColor,
  tableBorderColor,
  otpView,
  comments,
  isFrench,
}) => {
  const lang = isFrench ? "fr" : "en";
  const anchorName = categories.categories?.find(
    (ao) => ao.priority === "secondary"
  );
  let colWidth;

  const tableTemplateHigh = categories.categories
    ?.find((ao) => ao.priority === "secondary")
    .options.map((anchor) => {
      colWidth = 39 / (anchorName.options.length + 1);
      return {
        title: isFrench
          ? getHeaderLetter(anchor?.fr).toUpperCase()
          : getHeaderLetter(anchor.name).toUpperCase(),
        id: anchor.id,
        width: `${colWidth}%`,
      };
    });

  tableTemplateHigh.unshift({ title: "AV", width: `${colWidth}%` });
  tableTemplateHigh.unshift({
    title: getTranslatedText("dimensions", lang, translations),
    width: "25%",
  });
  tableTemplateHigh.unshift({
    title: getTranslatedText("highestRatedStatements", lang, translations),
    width: "46%",
  });

  const tableTemplateLow = categories.categories
    ?.find((ao) => ao.priority === "secondary")
    .options.map((anchor) => {
      colWidth = 39 / (anchorName.options.length + 1);
      return {
        title: isFrench
          ? getHeaderLetter(anchor?.fr).toUpperCase()
          : getHeaderLetter(anchor.name).toUpperCase(),
        id: anchor.id,
        width: `${colWidth}%`,
      };
    });
  tableTemplateLow.unshift({ title: "AV", width: `${colWidth}%` });
  tableTemplateLow.unshift({
    title: getTranslatedText("dimensions", lang, translations),
    width: "25%",
  });
  tableTemplateLow.unshift({
    title: getTranslatedText("lowestRatedStatements", lang, translations),
    width: "46%",
  });

  const fetchFactor = (q) => {
    const title = factorTitle[q.dimension].find((fa) => {
      return fa.id === Number(q.section) + 1;
    });
    return title;
  };

  const chartColors = [];
  const dimensionOne = [];
  const dimensionTwo = [];
  data.responses.forEach((response) => {
    if (response.dimension === 0) {
      dimensionOne.push(response);
    } else {
      dimensionTwo.push(response);
    }
  });

  const dimensionOneQuestions = [];
  const dimOneTally = [];
  const dimensionTwoQuestions = [];
  const dimTwoTally = [];
  dimensionOne.forEach((q) => {
    if (
      !dimOneTally.find(
        (qk) => qk.question === q.question && qk.section === q.section
      )
    ) {
      const label = fetchFactor(q);
      dimOneTally.push(q);

      dimensionOneQuestions.push({
        question: q.question,
        dimension: q.dimension,
        section: q.section,
        factorLabel: isFrench ? label?.fr_abbr : label?.abbv,
        factorColor: label?.color,
      });
    }
  });

  dimensionTwo.forEach((q) => {
    if (
      !dimTwoTally.find(
        (qk) => qk.question === q.question && qk.section === q.section
      )
    ) {
      const label = fetchFactor(q);

      dimTwoTally.push(q);
      dimensionTwoQuestions.push({
        question: q.question,
        dimension: q.dimension,
        section: q.section,
        factorLabel: isFrench ? label?.fr_abbr : label?.abbv,
        factorColor: label?.color,
      });
    }
  });

  const fetchQuestion = (q) => {
    const ques = isFrench
      ? question.dimensions[q.dimension].factors[Number(q.section)].questions[
          Number(q.question)
        ]?.fr
      : question.dimensions[q.dimension].factors[Number(q.section)].questions[
          Number(q.question)
        ]?.q;
    return ques;
  };

  const fetchReverseOption = (q) => {
    return question.dimensions[q.dimension].factors[Number(q.section)]
      .questions[Number(q.question)]?.reverse;
  };

  const isQuestionBinary = (q, question) => {
    return question.dimensions[q.dimension].factors[Number(q.section)]
      .questions[Number(q.question)]?.binary;
  };

  const dimensionOneAverages = dimensionOneQuestions.map((q) => {
    let reverse = false;
    const questionResponses = data.responses
      .filter(
        (r, i) =>
          r.question === q.question &&
          r.dimension === q.dimension &&
          r.section === q.section
      )
      .filter((dd) => !isQuestionBinary(dd, question));
    questionResponses.forEach((r) => {
      if (fetchReverseOption(r)) {
        reverse = true;
      }
    });
    let n = 0;
    const questionAverage =
      questionResponses.reduce((total, b) => {
        n += b.n;
        return total + b.average * b.n;
      }, 0) / n || 0;

    return {
      question: q,
      average: questionAverage,
      groups: questionResponses,
      reverse: reverse,
    };
  });

  const dimensionTwoAverages = dimensionTwoQuestions.map((q) => {
    let reverse = false;
    const questionResponses = data.responses
      .filter(
        (r, i) =>
          r.question === q.question &&
          r.dimension === q.dimension &&
          r.section === q.section
      )
      .filter((dd) => !isQuestionBinary(dd, question));
    questionResponses.forEach((r) => {
      if (fetchReverseOption(r)) {
        reverse = true;
      }
    });

    let n = 0;
    const questionAverage =
      questionResponses.reduce((total, b) => {
        n += b.n;
        return total + b.average * b.n;
      }, 0) / n || 0;

    return {
      question: q,
      average: questionAverage,
      groups: questionResponses,
      reverse: reverse,
    };
  });

  const dimensionAverages = [
    ...dimensionOneAverages,
    ...dimensionTwoAverages,
  ].sort((a, b) => {
    let bAverage = b.average * 10;
    let aAverage = a.average * 10;

    if (a.reverse) {
      aAverage = 100 - aAverage;
    }

    if (b.reverse) {
      bAverage = 100 - bAverage;
    }

    return bAverage - aAverage;
  });

  const dOneData = dimensionAverages
    .filter((item) => {
      let n = 0;
      const avg =
        (item.groups.reduce((total, b) => {
          n += b.n;
          return total + b.average * b.n;
        }, 0) /
          n) *
          10 || 0;
      const average = item.reverse ? 100 - (avg * 10) / 10 : avg / 10;
      return average < 10; // Should be whitelabel config value.
    })
    .map((item) => {
      let n = 0;
      const avg =
        item.groups.reduce((total, b) => {
          n += b.n;

          return total + b.average * b.n;
        }, 0) / n || 0;
      const average = (avg * 10) / 10;
      const averagePrefix = item.reverse ? "**" : "";
      chartColors.push(item.question.factorColor);
      const tableData = tableTemplateLow.map((v) => {
        if (v.title === getTranslatedText("dimensions", lang, translations)) {
          return `${item.question.factorLabel}`;
        }

        if (v.title === "AV") {
          return `${average.toFixed(1)}${averagePrefix}`;
        }

        if (!v.id) {
          return `${fetchQuestion(item.question)}`;
        }
        const groupData = item.groups.filter((g) => g.role === v.id);

        const group =
          (groupData.reduce((total, b) => {
            return total + b.average;
          }, 0) /
            groupData.length) *
            10 || 0;
        const groupAverage = group / 10;

        if (groupData?.length === 0) {
          return -1;
        }

        return `${groupAverage.toFixed(1)}`;
      });

      return tableData;
    });

  const topNum = otpView ? 15 : 5;

  const chartOneColors = chartColors.splice(0, topNum);
  const dimensionStrengths = dOneData.slice(0, topNum);
  const chartTwoColors = chartColors.reverse().slice(0, topNum);

  const dimensionRisks = dOneData.reverse().slice(0, topNum);

  const dOneRiskTableData = {
    rows: tableTemplateLow,
    data: dimensionRisks,
  };

  const dOneStrengthTableData = {
    rows: tableTemplateHigh,
    data: dimensionStrengths,
  };

  return (
    <>
      <Page style={styles.page}>
        <Text style={[styles.T2, { color: titleColor }]}>
          {getTranslatedText("summary", lang, translations)}
        </Text>
        <View style={styles.line} />
        <Side />
        <Footer isFrench={isFrench} />
        {/* <Text style={styles.p}>
          The tables below display the ten lowest (areas of growth) at a
          threshold of 75% and lower, and ten highest (strengths) at a threshold
          of 85% or higher, scoring questions from the assessment, as well as
          the factor each question belongs to.
        </Text> */}
        <View>
          <Text style={[styles.title, { color: titleColor }]}>
            {getTranslatedText("highestRankedItems", lang, translations)} (
            {topNum})
          </Text>
          {(dOneStrengthTableData?.data.length > 0 && (
            <Table
              pillarColors={chartOneColors}
              data={dOneStrengthTableData}
              headerColor={tableBorderColor}
              hideReverseText={false}
              isFrench={lang === "fr"}
            />
          )) || (
            <Text style={styles.description}>
              {getTranslatedText("noStrengths", lang, translations)}
            </Text>
          )}

          {comments && (
            <View wrap={false}>
              <Text style={styles.p}>{comments?.[21]}</Text>
            </View>
          )}
        </View>
      </Page>
      <Page style={styles.page}>
        <Side />
        <Footer isFrench={isFrench} />
        <View>
          <Text style={[styles.title, { color: titleColor }]}>
            {getTranslatedText("lowestRankedItems", lang, translations)} (
            {topNum})
          </Text>
          {(dOneRiskTableData?.data.length > 0 && (
            <Table
              pillarColors={chartTwoColors}
              data={dOneRiskTableData}
              headerColor={tableBorderColor}
              hideReverseText={false}
              isFrench={lang === "fr"}
            />
          )) || (
            <Text style={styles.description}>
              {getTranslatedText("noGrowth", lang, translations)}
            </Text>
          )}

          {comments && (
            <View wrap={false}>
              <Text style={styles.p}>{comments?.[22]}</Text>
            </View>
          )}
        </View>
      </Page>
    </>
  );
};

export default Risks;

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
  },
});
