import React from "react";
import styled from "styled-components";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const JourneyContext = (props) => {
  const { contextData } = props;
  return (
    <ScreenContainer>
      <ScreenTitleExtended>{contextData.title}</ScreenTitleExtended>
      <SubtextExtended delay={0.5}>{contextData.subTitle}</SubtextExtended>

      <Content delay={1}>
        <Description>
          <T2>{contextData.descriptionTitle}</T2>
          {contextData.description}
        </Description>
      </Content>
    </ScreenContainer>
  );
};

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const Description = styled.div`
  line-height: 1.6;
  background-color: #d4f5ed;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 600px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const T2 = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 10px;
  font-weight: bold;
`;

export default JourneyContext;
