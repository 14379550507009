import styled, { keyframes } from "styled-components";
import React from "react";




const ResultCard = (props) =>{


	const getScore = () =>{

		if(!props.score){
			return 'No Score'
		}


		let diff = props.score - props.last

		if(!diff){
			return 'No change'
		}
		if(diff<0){
			return diff+'% decrease'
		}else{
			return '+'+diff+'% increase'
		}
	}

	const getAverage = () =>{

		if(!props.score){
			return 'You do not have a score in this area for this week'
		}
		// console.log(props.selectedTeam)
		let team = props.selectedTeam? 'This ':'Your '

		let diff = props.score - props.last
		let average = props.score - props.average
		let text = ''
		if(props.title.slice(0,4)=='Team'){
			text+=team
			text+="team's scores have "
		}else{
			text+="Your scores have "
		}

		if(diff>0){
				text+="increased from last week "
			}else if(diff<0){
				text+="decreased from last week "
			}else{
				text+="stayed the same from last week "
			}

		if(average>0){
				text+=" and are above the overall historical average."
			}else if(diff<0){
				text+="and are below the overall historical average."
			}

			return text

	}

	const getAverageWords = () =>{

		if(!props.score){
			return 'N/A'
		}
		let average = props.score - props.average

		if(average>20){
			return 'Significantly above '
		}
		if(average>10){
			return 'Moderately above '
		}
		if(average>0){
			return 'Slightly above '
		}
		if(average<-20){
			return 'Significantly below '
		}
		if(average<-10){
			return 'Moderately below '
		}
		if(average<0){
			return 'Slightly below '
		}

		return 'Excatly '
	}



	return (
		<Half>
			<Top>
				<Title>{props.title}</Title>
				<Increase>{getScore()}</Increase>
			</Top>
			<div style={{width:'100%',display:'flex',justifyContent:'center'}}>
				<BarArea>
					<Value score={props.score}>{props.score?props.score:"N/A"}</Value>
					<Bar>
						<Color score={props.score?props.score:0}/>
					</Bar>
					{props.average?
					<Average score={props.average}>
							
								<Arrow/>
						{props.title.slice(0,4)=="Team"?'Team avg':'Average'}: {props.average}
					</Average>
					:<Average/>}
				</BarArea>
			</div>

			<Result>{getAverageWords()} average</Result>
			<Description>{getAverage()}</Description>
		</Half>
		)
}

export default ResultCard

const Half = styled.div`
	width:calc(50% - 10px);
	padding:20px;
	font-family: 'Red Hat Display', sans-serif;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

	@media(max-width:768px){
		width:100%;
		margin-bottom:10px;
	}
`


const Title = styled.div`
	font-size:18px;
	font-weight:bold;
`

const Increase = styled.div`
	color:#4a4a4a;
`

const Top = styled.div`
	display:flex;
	justify-content:space-between;
`

const BarArea = styled.div`
	width:90%;
	margin-top:30px;
`

const Bar = styled.div`
	width:100%;
	height:25px;
	background-color:#9b9b9b;
	border-radius:10px;
	overflow:hidden;
	position:relative;
`

const Color = styled.div`
	height:100%;
	background-color:#6a00ff;
	width:${props=>props.score+'%'};

`

const Value = styled.div`
	padding-left:${props=>'calc('+props.score+'% - 15px)'};
	width:100%;
	font-size:16px;
	font-weight:bold;
	color:#6a00ff;
	margin-bottom:4px;
`

const Result = styled.div`
	color:#6a00ff;
	font-weight:bold;
	margin-top:30px;
	margin-bottom:5px;
`

const Description = styled.div`
	
`	

const Average = styled.div`
	margin-left:${props=>'calc('+props.score+'% - 50px)'};
	color:#6a00ff;
	font-size:12px;
	font-weight:bold;
	display:flex;
	flex-direction:column;
	width:100px;
	align-items:center;
	margin-top:5px;
	min-height:50px;
`

const Arrow = styled.div`
	border: solid #6a00ff;
  border-width: 0 3px 3px 0;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
`