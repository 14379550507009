import {
  GET_POWERPOINT_PRESENTATION,
  DELETE_POWERPOINT_PRESENTATION,
  CREATE_POWERPOINT_PRESENTATION,
  GET_POWERPOINT_PRESENTATION_STATUS,
  CLEAR_POWERPOINT_PRESENTATION,
  POWERPOINT_PRESENTATIONS_PENDING,
  GET_POWERPOINT_PRESENTATION_DOWNLOAD,
} from "constants/actions";

const initialState = {
  presentations: [],
  presentationStatus: {},
  downloadLink: null,
  created: null,
  pending: false,
};

export default function PowerpointPresentations(state = initialState, action) {
  switch (action.type) {
    case GET_POWERPOINT_PRESENTATION:
      return {
        ...state,
        presentations: action.payload,
        created: null,
        pending: false,
      };

    case CREATE_POWERPOINT_PRESENTATION:
      return {
        ...state,
        presentations: [...state.presentations, action.payload],
        created: action.payload,
        pending: true,
      };

    case DELETE_POWERPOINT_PRESENTATION:
      return {
        ...state,
        presentations: state.presentations.filter(
          (presentation) => presentation.id !== action.payload
        ),
      };

    case GET_POWERPOINT_PRESENTATION_STATUS:
      return {
        ...state,
        presentationStatus: {
          ...state.presentationStatus,
          [action.payload.id]: action.payload.status,
        },
      };

    case CLEAR_POWERPOINT_PRESENTATION:
      return {
        ...state,
        presentations: [],
        presentationStatus: {},
        pending: false,
        created: null,
        downloadLink: null,
      };

    case POWERPOINT_PRESENTATIONS_PENDING:
      return {
        ...state,
        pending: true,
      };

    case GET_POWERPOINT_PRESENTATION_DOWNLOAD:
      return {
        ...state,
        downloadLink: action.payload,
      };

    default:
      return state;
  }
}