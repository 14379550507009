import React,{useState,useRef} from 'react';
import styled,{keyframes} from 'styled-components';
import ReportModal from './ReportModal'
import Box from './Box'
import { useNavigate } from 'react-router-dom';

const Reports = () =>{
    const navigate = useNavigate();

    return (
        <Container>
            <ReportModal/>
            <Box 
              onClick={()=>navigate('/simple/prototype/recon/reports/analysis')}
              title={'Best practices for customer satisfaction - Q4 2023'}
              icon={'book'}
              read={true}
              description={'Story built on Dec 5th, 2023 and based on the agent data collected on October 30th, 2023.'}/>
        </Container>
    )

}

export default Reports

const Container = styled.div`

`