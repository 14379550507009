/// Libraries
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import Intro from "./Intro";
import Methods from "./methods";
import Risks from "./Risks";
import Breakdown from "./Breakdown";
import Footer from "../components/Footer";
import TOC from "../components/TOC";
import SectionIntro from "./SectionIntro";
import Diversity from "./Diversity";
import Overview from "./Overview";
import ContentBlock from "./ContentBlockSL";

// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";
import PoppinsBold from "assets/fonts/Poppins-Bold.ttf";
import PoppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import PoppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import PoppinsItalic from "assets/fonts/Poppins-LightItalic.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsItalic, fontWeight: 200 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsRegular, fontWeight: 400 },
  ],
  format: "truetype",
});


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    marginBottom: 30,
    paddingHorizontal: 50,
    fontFamily: "Poppins",
  },
  section: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  title: {
    fontSize: 40,
    textAlign: "center",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  table: {
    marginHorizontal: 10,
  },
  subHeading: {
    fontSize: 12,
    paddingBottom: 50,
  },
  spiderSubHeading: {
    fontSize: 12,
    paddingBottom: 30,
    display: "flex",
    textAlign: "center",
    zIndex: 999,
  },
  heading: {
    paddingBottom: "15px",
    left: 0,
    fontSize: 22,
    fontWeight: 500,
  },
  doc: {
    fontFamily: "Poppins",
    fontSize: 14,
  },
  spiderImage: {
    left: 55,
    top: -28,
    maxHeight: 300,
    maxWidth: 400,
    height: 300,
    width: 400,
    zIndex: -99,
  },
  spiderHeading: {
    fontSize: 18,
    paddingBottom: 10,
    fontWeight: 500,
  },
});


const Template = ({surveyStructure,surveyQuestions}) =>{

  return (
    <Document style={styles.doc}>
      {/* INTRO PAGE */}
      <Page>
        <View>
          <Intro
            config={null}
            selectedReport={null}
            selectedOrg={null}
          />
        </View>


      </Page>
      <TOC/>

      <Methods/>

        {/*Adding a section intro here for additional content prior to the dimension overview*/}
       <SectionIntro title={'Introduction to Culture Factors'}/>

      {/*
        Add factor overview here
        Each dimension will have its own page, with its own title, and intro text as in the template below
      */}
      <Overview/>

    {/* Add culture index map as is*/}


    {/* Risks are presented in a similar way, we will extend this out to 10 risks and 10 strengths*/}
      <Risks/>
     
      <ContentBlock title={'Getting Culture Right Starts Here'}/>
     {/*The factor breakdown will be presented using these charts, however, the main overview of the factor will
         include a table with the columns question, score, grade , norm, change. Norm and change will be -- for now. */}
      {surveyQuestions?.questions.factors.map((item)=>{
        return <Breakdown data={item} categories={surveyStructure.categories}/>
      })}



     </Document>
    )
}

export default Template