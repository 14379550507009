import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Input } from "semantic-ui-react";

import Assignee from "../Assignee";
import useGetOrgData from "hooks/useGetOrgData";
import { useDispatch } from "react-redux";
import {
  SG_DELETE_ACTIVITY_ITEM,
  SG_UPDATE_ACTIVITY_ITEM,
  SG_GET_ACTION_PLAN_ACTIVITIES,
} from "constants/actions";

const ActivityHistory = ({ employees, data }) => {
  const dispatch = useDispatch();
  const [allowEdits, setAllowEdits] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(data.description);
  const { get_auth } = useGetOrgData();

  const personId = data.employee;

  const person = employees
    .filter((e) => e.id === personId)
    .map((found) => {
      return {
        id: found.id,
        name: `${found.first_name} ${found.last_name}`,
        member: `${found.first_name} ${found.last_name}`,
      };
    });

  useEffect(() => {
    if (personId === Number(get_auth.employee_id) && !editMode) {
      setAllowEdits(true);
    } else {
      setAllowEdits(false);
    }
  }, [personId, get_auth, editMode]);

  const handleEditSave = () => {
    const copy = { ...data };
    copy.description = editData;

    dispatch({
      type: SG_UPDATE_ACTIVITY_ITEM,
      payload: copy,
    });

    setEditMode(false);
  };

  const handleDelete = () => {
    dispatch({
      type: SG_DELETE_ACTIVITY_ITEM,
      payload: data.id,
    });

    dispatch({
      type: SG_GET_ACTION_PLAN_ACTIVITIES,
    });
    setEditMode(false);
  };

  const rowDisplay = editMode ? (
    <InputWrapper>
      <Input
        placeholder="Add comment"
        value={editData}
        onChange={(e, d) => setEditData(d.value)}
        style={{ width: 400, marginLeft: 10 }}
      />
    </InputWrapper>
  ) : (
    <CommentSection>
      {data.description}

      {!editMode && allowEdits && (
        <ControlRow>
          <TextButton onClick={() => setEditMode(!editMode)}>Edit</TextButton>

          <TextButton onClick={() => handleDelete(data.id)}>Delete</TextButton>
        </ControlRow>
      )}
      <DateDisplay>{format(new Date(data.created_at), "PPPpp")}</DateDisplay>
    </CommentSection>
  );

  const controlSectionEdit = editMode && (
    <ControlSection>
      <StyledButton
        disabled={editData?.length === 0}
        onClick={() => handleEditSave()}
      >
        Save
      </StyledButton>
      <StyledButton
        disabled={editData?.length === 0}
        onClick={() => handleEditSave()}
      >
        Cancel
      </StyledButton>
    </ControlSection>
  );
  return (
    <ActivityContainer>
      <ActivityRow>
        <Assignee assignees={person} stacked={false} />
        {/*        <NameDisplay>{person[0].name}</NameDisplay>*/}
        {/* <DateDisplay>{format(new Date(data.created_at), "PPPpp")}</DateDisplay>*/}

        {rowDisplay}
        {controlSectionEdit}
      </ActivityRow>
    </ActivityContainer>
  );
};

const ActivityContainer = styled.div`
  margin-top: 18px;
`;

const ActivityRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TextButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const ControlSection = styled.div`
  display: flex;
  padding-left: 10px;
  padding-top: 7px;
  flex-direction: row;
`;

const InputWrapper = styled.div`
  margin-top: 7px;
`;

const CommentSection = styled.div`
  font-family: "Poppins";
  background-color: #edebeb;
  padding: 20px;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  max-width: 70%;
  position: relative;
  padding-bottom: 40px;
  min-width: 275px;
  overflow-wrap: break-word;
`;

const NameDisplay = styled.label`
  font-family: "Poppins";
  font-weight: bold;
  padding-left: 10px;
  font-size: 16px;
`;

const DateDisplay = styled.label`
  font-family: "Poppins";
  padding-left: 25px;
  font-size: 9px;
  position: absolute;
  bottom: 10px;
  right: 20px;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const ControlRow = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-size: 9px;
  font-family: "Poppins";
`;

export default ActivityHistory;
