import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import RecommendationBuilder from './RecommendationBuilder'
import { SG_GET_CHAT_RECOMMENDATION } from "constants/actions";
import {
  get_most_recent,
  filter_data,
  get_overall_average,
  get_last_responses,
  get_hr_flags,
  get_scores_per_pillar,
} from "../Tag/calculations";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { addIcon } from "components/Icons";
import MainContent from './MainContent'

const formatDescription = (tags, date) => {
  if (tags?.length === 0) {
    return date;
  }
  return `${tags.join(", ")} - ${date}`;
};

const timeAgo = (updatedAt) => {
  const now = new Date();
  const updated = new Date(updatedAt);
  const diff = now - updated;
  const hours = diff / 1000 / 60 / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = days / 30;
  const years = days / 365;

  switch (true) {
    case years > 10:
      return "a long time ago";
    case years > 1:
      return `${Math.floor(years)} years ago`;
    case months > 1:
      return `${Math.floor(months)} months ago`;
    case weeks > 1:
      return `${Math.floor(weeks)} weeks ago`;
    case days > 1:
      return `${Math.floor(days)} days ago`;
    case hours > 1:
      return `${Math.floor(hours)} hours ago`;
    default:
      return "just now";
  }
};

const Recommendation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selected, setSelected] = useState(0);
  const [latest_data, setLatestData] = useState();
  const [last_data, setLastData] = useState();
  const [filtered_data, setFilteredData] = useState();
  const [filtered_last_data, setFilteredLastData] = useState();
  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );
  const organization = useSelector(
    (state) => state.organizations,
    shallowEqual
  );
  const [hasPendingRec, setHasPendingRec] = useState(false);
  const [overall_score, setOverallScore] = useState();
  const [last_overall_score, setLastOverallScore] = useState();
  const [overall_score_org, setOverallScoreOrg] = useState(0);
  const [pendingRecs, setPendingRecs] = useState();
  const [hr_flags, setHrFlags] = useState(0);
  const [feedback_quality, setFeedbackQuality] = useState();
  const [aiRecs, setAiRecs] = useState();
  const [selectedRec, setSelectedRec] = useState();
  const [pillar_scores, setPillarScores] = useState();
  // If a rec has just been created, pre-select it after the component mounts
  const [createdRec, setCreatedRec] = useState(false);
  const [pendingRecsList, setPendingRecsList] = useState();
  const [standards, setStandards] = useState();
  const [forcedLoading, setForcedLoading] = useState(false);
  const [content, setContent] = useState();
  const { addToast } = useToasts();

  const {
    get_culture_audit_reports,
    survey_version,
    recommendations,
    recommendationsError,
    get_standards,
  } = useSelector(
    (state) => ({
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      survey_version: state.audit.survey_version,
      recommendations: state.recommendations.chat_recommendations,
      recommendationsError: state.recommendations.chat_recommendations_error,
      get_standards: state.survey_standard.survey_standards,
    }),
    shallowEqual
  );

  const checkForPending = (recs) => {
    if (recs && recs?.length > 0) {
      const pendingRec = recs.filter(
        (rec) => Object.keys(rec.recommendation).length === 0
      );

      if (pendingRec && pendingRec?.length > 0) {
        return pendingRec;
      }
    }
    return null;
  };

  useEffect(() => {
    dispatch({
      type: SG_GET_CHAT_RECOMMENDATION,
    });
  }, []);

  useEffect(() => {
    if (survey_version) {
      setStandards(
        get_standards.find((f) =>
          f.question_sort_order.includes(survey_version.sort_order)
        )
      );
    }
  }, [get_standards, survey_version]);

  useEffect(() => {
    if (recommendations?.results?.length > 0) {
      const pendingRecs = checkForPending(recommendations?.results);
      const aiRecs = recommendations?.results
        .filter(
          (rec) =>
            Object.keys(rec.recommendation).length > 0 &&
            !rec.recommendation?.error
        )
        .sort((a, b) => {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
      if (aiRecs?.length > 0) {
        setAiRecs(aiRecs);
        const selectedRecById = id
          ? aiRecs.find((rec) => rec.tracking_code === id)
          : undefined;
        if (selectedRecById && !selectedRec) {
          setForcedLoading(true);
          setTimeout(() => {
            setForcedLoading(false);
          }, 1000);
          setSelectedRec(selectedRecById);
        }
      }
      if (pendingRecs?.length > 0) {
        setPendingRecs(pendingRecs);
        if (hasPendingRec) {
          setTimeout(() => {
            setSelectedRec(pendingRecs[0]);
            window.history.replaceState(
              {},
              "",
              `/app/audit/rec/${pendingRecs[0].tracking_code}`
            );
            addToast("Your recommendation has been created!", {
              appearance: "success",
              autoDismiss: true,
            });

            setHasPendingRec(false);
            setForcedLoading(false);
          }, 500);
        }
      }
    }
  }, [recommendations, hasPendingRec, id]);

  useEffect(() => {
    if (selectedRec) {
      if (Object.values(selectedRec?.recommendation)?.length > 0) {
        setForcedLoading(true);
        setTimeout(() => {
          setForcedLoading(false);
        }, 1000);
      }

      window.history.replaceState(
        {},
        "",
        `/app/audit/rec/${selectedRec.tracking_code}`
      );
    }
  }, [selectedRec]);

  useEffect(() => {
    if (get_culture_audit_reports?.response) {
      let sorted_responses = get_culture_audit_reports?.response.sort(
        (a, b) => b.id - a.id
      )

      if(survey_version?.sort_order){
        sorted_responses = sorted_responses.filter(f=>f.question_sort_order === survey_version.sort_order)
        console.log(sorted_responses,survey_version.sort_order)
      }

      let most_recent = get_most_recent(sorted_responses);
      let last_responses = get_last_responses(sorted_responses);
      setLatestData(most_recent.responses);
      setLastData(last_responses);
      setLastOverallScore(get_overall_average(last_responses));
      setOverallScore(get_overall_average(most_recent.responses));

      let conversation_analytics = get_hr_flags(most_recent.responses);

      setHrFlags(conversation_analytics.flags);
      setFeedbackQuality(conversation_analytics.feedback_quality);
    }
  }, [get_culture_audit_reports,survey_version]);

  useEffect(() => {
    if (latest_data && selectedFilters.length === 0 && survey_version) {
      setOverallScoreOrg(0);
      setLastOverallScore(get_overall_average(last_data));
      let conversation_analytics = get_hr_flags(latest_data);

      setHrFlags(conversation_analytics.flags);
      setFeedbackQuality(conversation_analytics.feedback_quality);

      setPillarScores(
        get_scores_per_pillar(
          latest_data,
          survey_version,
          latest_data,
          last_data,
          standards
        )
      );
      return setOverallScore(get_overall_average(latest_data));
    }

    if (latest_data && selectedFilters.length > 0) {
      setFilteredLastData(filter_data(last_data, selectedFilters));
      return setFilteredData(filter_data(latest_data, selectedFilters));
    }
  }, [latest_data, selectedFilters, standards]);

  useEffect(() => {
    if (filtered_data && survey_version) {
      setOverallScore(get_overall_average(filtered_data));
      setOverallScoreOrg(get_overall_average(latest_data));
      setLastOverallScore(get_overall_average(filtered_last_data));

      let conversation_analytics = get_hr_flags(filtered_data);

      setHrFlags(conversation_analytics.flags);
      setFeedbackQuality(conversation_analytics.feedback_quality);

      setPillarScores(
        get_scores_per_pillar(
          filtered_data,
          survey_version,
          latest_data,
          filtered_last_data,
          standards
        )
      );
    }
  }, [filtered_data]);

  const checkEmpty = () => {
    if (aiRecs && aiRecs?.length > 0) {
      return false;
    }
    if (pendingRecs && pendingRecs?.length > 0) {
      return false;
    }

    return true;
  };

  //Need some form of loading state so that this does not flash when the component loads.
  // if(checkEmpty()){
  //   return <Empty
  //     data={selectedFilters.length > 0 ? filtered_data : latest_data}
  //     pillar_scores={pillar_scores}
  //     setPending={() => setHasPendingRec(true)}
  //     pendingRecs={pendingRecs}/>
  // }

  useEffect(() => {
    if (pendingRecs && pendingRecs?.length > 0) {
      const pendingRecsList = pendingRecs.map((rec, i) => {
        const recSelected = selectedRec?.tracking_code === rec.tracking_code;
        const formattedDate = rec?.created_at && timeAgo(rec.created_at);

        const formattedDescription = formatDescription(
          rec.params.tags,
          formattedDate
        );

        return (
          <Tab
            onClick={() => setSelectedRec(rec)}
            selected={recSelected}
            delay={i * 0.1}
          >
            <Title selected={recSelected}>
              {rec.params.template} <PendingTag>Pending</PendingTag>
            </Title>
            <FactorContainer>
              {rec.survey_data.selectedItems.map((item) => {
                return <Factor>{item.title}</Factor>;
              })}

              <Sub>{formattedDescription}</Sub>
            </FactorContainer>
          </Tab>
        );
      });
      setPendingRecsList(pendingRecsList);
    }
  }, [pendingRecs, aiRecs, selectedRec]);


  
  // useEffect(() => {
  //   let content =   <CenteredLoading><LogAnimation /></CenteredLoading>;
  //   if (!forcedLoading) {
  //     content =
  //       (forcedLoading && (
  //         <CenteredLoading>
  //           <LogAnimation />
  //         </CenteredLoading>
  //       )) ||
  //       selectedRec ? (
  //         <>
  //           {!Object.keys(selectedRec.recommendation).length > 0 ? (
  //             <Pending />
  //           ) : (
  //             ((!selectedRec?.survey_data?.scores ||
  //               selectedRec?.survey_data?.scores?.length === 0 ||
  //               selectedRec?.recommendation?.error) && (
  //               <>Recommendation has encountered an error</>
  //             )) || (
  //               <RecommendationPDF
  //                 data={selectedRec}
  //                 orgName={organization?.organization?.name}
  //               />
  //             )
  //           )}
  //         </>
  //       ) : (
  //         <NewRecommendation
  //           data={latest_data}
  //           pillar_scores={pillar_scores}
  //           setPending={() => {
  //             setForcedLoading(true);
  //             setTimeout(() => {
  //               setHasPendingRec(true);
  //             }, 500);
  //           }}
  //           pendingRecs={pendingRecs}
  //         />
  //       );
  //   }
  //   setContent(content);
  // }, [selectedRec, forcedLoading, pendingRecs, aiRecs, selectedFilters,latest_data]);

  return (
    <Container>
      <History>
        <NewRecTop
          onClick={() => {
            setSelectedRec();
            window.history.replaceState({}, "", `/app/audit/rec`);
          }}
        >
          <AddIconWrapper>{addIcon()}</AddIconWrapper>
          New recommendation
        </NewRecTop>
        <RecommendationsContainer>
          <HistoryTitle>Report History</HistoryTitle>
          {pendingRecsList}
          <>
            {aiRecs &&
              aiRecs?.length > 0 &&
              aiRecs?.map((rec, i) => {
                const formattedDate =
                  rec?.updated_at && timeAgo(rec.updated_at);

                const formattedDescription = formatDescription(
                  rec.params.tags,
                  formattedDate
                );

                const recSelected =
                  selectedRec &&
                  selectedRec?.tracking_code === rec.tracking_code;
                return (
                  <NavLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={`/app/audit/rec/${rec.tracking_code}`}
                  >
                    <Tab
                      onClick={() => {
                        setSelectedRec(rec);
                      }}
                      selected={recSelected}
                      delay={i * 0.1}
                    >
                      <Title selected={recSelected}>
                        {rec.params.template}{" "}
                      </Title>
                      <FactorContainer>
                        {rec.survey_data.selectedItems.map((item) => {
                          return <Factor>{item.title}</Factor>;
                        })}
                        <Sub>{formattedDescription}</Sub>
                      </FactorContainer>
                    </Tab>
                  </NavLink>
                );
              })}
          </>
        </RecommendationsContainer>

        {/* <RecommendationBuilder
          data={selectedFilters.length > 0 ? filtered_data : latest_data}
          pillar_scores={pillar_scores}
          setPending={() => setHasPendingRec(true)}
          pendingRecs={pendingRecs}
        /> */}
      </History>
      <MainContent
        forcedLoading={forcedLoading}
        selectedRec={selectedRec}
        organization={organization}
        latest_data={selectedFilters.length > 0 ? filtered_data : latest_data}
        pillar_scores={pillar_scores}
        setForcedLoading={setForcedLoading}
        setHasPendingRec={setHasPendingRec}
        pendingRecs={pendingRecs}

        />
    </Container>
  );
};

export default Recommendation;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CenteredLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
`;

const AddIconWrapper = styled.div`
  margin-right: 10px;
`;

const Container = styled.div`
  padding-top: 30px;
  display: flex;
`;

const History = styled.div`
  height: calc(100vh - 70px);
  width: 300px;
  border-right: 1px solid #e5e5e5;
  position: fixed;
`;

const RecommendationsContainer = styled.div`
  margin-top: 40px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  padding: 20px;
  background: ${(props) => (props.selected ? "#EBF1FD" : "white")};
  border-right: ${(props) =>
    props.selected ? "3px solid #2D70E2" : "3px solid white"};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e5e5e5;

  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state

  // 3. Adjust delay for each sample
  animation-delay: ${({ delay }) => delay}s;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: ${({ selected }) => (selected ? "#2D70E2" : "#666D79")};
`;

const Sub = styled.div`
  font-family: Raleway;
  font-size: 12px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

const SurveyVersionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  padding-top: 30px;
  z-index: 100;
`;


const Description = styled.div`
  font-family: Raleway;
  font-size: 16px;
  line-height: 24px;
`;
const PendingTag = styled.div`
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  background: #fffaf1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  color: #fea711;
  border-radius: 8px;
`;

const Factor = styled.div`
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  color: #b3b3b3;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const FactorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const NewRecTop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  margin-bottom: 20px;
  cursor: pointer;
  color: #2d70e2;
`;

const HistoryTitle = styled.div`
    font-size:18px;
    margin-left:20px;
    margin-top:20px;
    margin-bottom:20px;
    font-family: Raleway;
    font-weight:bold;
    color:#2A3039;
`