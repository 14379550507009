import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";

import ToolCard from "./ToolCard";

const TeamTool = () => {
	return (
		<Container>
			<Top>
				<Title>teamLogic</Title>
				<Schedule>Schedule</Schedule>
			</Top>
			<Table>
				<Headers>
					<TH width={25}>Tool</TH>
					<TH width={15}>Type</TH>
					<TH width={15}>Status</TH>
					<TH width={15}>Last Used</TH>
					<TH width={30}>Actions</TH>
				</Headers>

				{data.map((item) => (
					<ToolCard
						name={item.name}
						type={item.type}
						status={item.status}
						last_used={item.last_used}
					/>
				))}
			</Table>
		</Container>
	);
};

export default TeamTool;

const Container = styled.div`
	margin-top: 60px;
	font-family: "Barlow", sans-serif;
`;

const Table = styled.div`
	width: 100%;
`;
const Headers = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	padding: 0px 20px;
`;

const TH = styled.div`
	font-size: 12px;
	font-family: "Barlow Semi Condensed", sans-serif;
	color: #595959;
	font-weight: bold;
	text-transform: uppercase;
	width: ${(props) => props.width + "%"};
	padding: 0 0 10px 0px;

	@media (max-width: 768px) {
		display: none;
	}
`;

const Title = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 50px;
`;

const Schedule = styled.div`
	border-radius: 5px;
	background-color: #2d50e2;
	height: 40px;
	color: white;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	user-select: none;
	padding: 0px 30px;
	font-family: "Barlow Semi Condensed", sans-serif;
	margin-right: 5px;

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
		margin-right: 3px;
	}

	@media (min-width: 991px) {
		display: flex;
	}
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
`;

const data = [
	{
		name: "Mood Check",
		type: "Feedback",
		status: "Recurring",
		last_used: "10 days ago",
	},
	{
		name: "Culture Pulse",
		type: "Feedback",
		status: "New",
		last_used: "10 days ago",
	},
	{
		name: "Practice Debriefs",
		type: "Performance",
		status: "Scheduled",
		last_used: "10 days ago",
	},
	{
		name: "Game Debriefs",
		type: "Performance",
		status: "Completed",
		last_used: "10 days ago",
	},
	{
		name: "Hooper Mckinnon",
		type: "Physiology",
		status: "New",
		last_used: "10 days ago",
	},

	{
		name: "Personality Assessment",
		type: "Psychology",
		status: "New",
		last_used: "10 days ago",
	},
	{
		name: "Mental Performance Intake",
		type: "Psychology",
		status: "New",
		last_used: "10 days ago",
	},
];
