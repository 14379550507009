import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/Resilience.svg";
import Impact from "assets/images/Impact.svg";

import culture_analytics from "../../../data/culture_analytics.json";
import { SG_GET_CULTURE_DATA } from "constants/actions";
import { useNavigate } from "react-router-dom";

import EmployeeAvatar from "components/EmpAvatar";

//Table Dimensions
const numRows = culture_analytics.table.length + 2;
const numCols = 6;
const cellWidth = 15;
const cellHeight = 50;

const CultureCell = ({ position, data, search, visible }) => {

  const scores = data.responses.find(({name}) => name === search).answer
  return (
    <Box background={colors[scores - 1]}>
      <Score visible={visible}>{scores}</Score>
    </Box>
  );
};

const CultureTable = (props) => {
  // const dispatch = useDispatch();
  const [cultureData, setCultureData] = useState([]);
  const [loading,setLoading] = useState(true)
   let navigate = useNavigate();

  //  const [employee, setEmployee] = useState('')

  // useEffect(() => {
  //   // Run! Like go get some data from an API.
  //   dispatch({ type: SG_GET_CULTURE_DATA });
  // }, [dispatch]);

  const { culture_data, get_auth } = useSelector(
    (state) => ({
      culture_data: state.culture.culture,
      get_auth: state.auth,
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );

  // React.useEffect(() => {
  //   if (get_employees && get_auth) {
  //     if (get_employees.filter((em => em.id == get_auth?.employee_id))) 
  //     {
  //       setEmployee(get_employees.filter((em => em.id == get_auth?.employee_id))[0])
  //     }
  //   }
    
  // }, [get_employees, get_auth])



  useEffect(() => {
    if (culture_data[0]?.responses) {
      const _EmpResponses = []
      if (culture_data[0]?.responses.filter(re => re.employee_id == get_auth?.employee_id).length > 0)
      {
        _EmpResponses.push(culture_data[0]?.responses.filter(re => re.employee_id == get_auth?.employee_id)[0])
      }
      _EmpResponses.push(...culture_data[0]?.responses.filter(re => re.employee_id != get_auth?.employee_id))

      // console.log(_EmpResponses)
      
      setCultureData(_EmpResponses);
      // console.log(_EmpResponses)
    }
    else if(!loading){
      navigate("/app/culture");
    }
    setLoading(false)
  }, [culture_data, loading, navigate, get_auth]);


  const onCellClick = (id) => {
    let direction = id - props.position;
    if (direction > 0) {
      props.setPosition(id);
      props.setReverse(false);
    } else {
      props.setPosition(id);
      props.setReverse(true);
    }
  };

  const getPositionEnd = () => {
    if (props.position === 0) {
      return "0%";
    } else if (props.position === 1) {
      return "calc(" + cellWidth + "% + 14px)";
    } else {
      // console.log(
      //   "calc(" +
      //     cellWidth * props.position +
      //     "% + " +
      //     (14 + (props.position - 1) * 2) +
      //     "px)"
      // );
      return (
        "calc(" +
        cellWidth * props.position +
        "% + " +
        (14 + (props.position - 1) * 2) +
        "px)"
      );
    }
  };

  const getPosition = (dir) => {
    if (props.position === 0) {
      if (dir === "start") {
        if (props.reverse) {
          return cellWidth + "%";
        } else {
          return -cellWidth + "%";
        }
      }
      return "0%";
    } else if (props.position === 1) {
      if (dir === "start") {
        if (props.reverse) {
          return "calc(" + cellWidth * 2 + "% + " + "14px)";
        } else {
          return "0";
        }
      }
    } else {
      if (dir === "start") {
        if (props.reverse) {
          return (
            "calc(" +
            (cellWidth * props.position + cellWidth) +
            "% + " +
            (14 + (props.position - 1) * 2) +
            "px)"
          );
        } else {
          return (
            "calc(" +
            (cellWidth * props.position - cellWidth) +
            "% + " +
            (14 + (props.position - 1) * 2) +
            "px)"
          );
        }
      }
    }
  };

  return (
	cultureData.length > 0 ?
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 30,
        width: props.width,
        padding: 20,
      }}
    >

        <Column num={cultureData.length}>
          <Empty />
          {cultureData?.map((item, i) => {
            return (
              <AvatarContainer key={i}>
                <EmployeeAvatar 
                employee_id={item.employee_id}
                picSize='small'
                nonMemberHide={true}
                nameHover={true}
                showEmotionalReport  />
              </AvatarContainer>
            );
          })}
        </Column>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Hover end={getPositionEnd()} start={getPosition("start")} num={cultureData.length}/>

        <Column
          highlight={props.position === 0}
          margin={["0px", "10px"]}
          onClick={() => onCellClick(0)}
          num={cultureData.length}
        >
          <Icon src={Trust} />
          {cultureData?.map((item, i) => {
            return <CultureCell 
			position={props.position} 
			visible={props.position === 0}
			data={item} 
			search={'Trust'} key={i} />;
          })}
        </Column>
        <Column
          highlight={props.position === 1}
          i={1}
          margin={["10px", "10px"]}
          onClick={() => onCellClick(1)}
          num={cultureData.length}
        >
          <Icon src={Communication} />
		  {cultureData?.map((item, i) => {
            return <CultureCell 
			position={props.position} 
			visible={props.position === 1}
			data={item} 
			search={'Communication'} key={i} />;
          })}
        </Column>
        <Column
          highlight={props.position === 2}
          i={2}
          margin={["10px", "10px"]}
          onClick={() => onCellClick(2)}
          num={cultureData.length}
        >
          <Icon src={Alignment} />
          {cultureData?.map((item, i) => {
            return <CultureCell 
			position={props.position} 
			visible={props.position === 2}
			data={item} 
			search={'Alignment'} key={i} />;
          })}
        </Column>
        <Column
          highlight={props.position === 3}
          i={3}
          margin={["10px", "10px"]}
          onClick={() => onCellClick(3)}
          num={cultureData.length}
        >
          <Icon src={Resilience} />
          {cultureData?.map((item, i) => {
            return <CultureCell 
			position={props.position} 
			visible={props.position === 3}
			data={item} 
			search={'Resilience'} key={i} />;
          })}
        </Column>
        <Column
          highlight={props.position === 4}
          i={4}
          margin={["10px", "10px"]}
          onClick={() => onCellClick(4)}
          num={cultureData.length}
        >
          <Icon src={Clarity} />
          {cultureData?.map((item, i) => {
            return <CultureCell 
			position={props.position} 
			visible={props.position === 4}
			data={item} 
			search={'Clarity'} key={i} />;
          })}
        </Column>
        <Column
          highlight={props.position === 5}
          i={5}
          margin={["10px", "0px"]}
          onClick={() => onCellClick(5)}
          num={cultureData.length}
        >
          <Icon src={Impact} />
          {cultureData?.map((item, i) => {
            return <CultureCell 
			position={props.position} 
			visible={props.position === 5}
			data={item} 
			search={'Impact'} key={i} />;
          })}
        </Column>
      </div>
    </div>
	: ''
  );
};

export default CultureTable;


const slide = (start, end) => keyframes`
	0% {left:${start}}
  100% {left:${end}}
`;

const expand = (margin) => keyframes`
	0% {margin-right:1px;margin-left:1px}
  100% {margin-right:${margin[1]};margin-left:${margin[0]}}
`;

const collapse = keyframes`
	0% {margin-right:10px;margin-left:10px}
  100% {margin-right:1px;margin-left:1px}
`;

const Column = styled.div`
  height: ${props=>(props.num+2) * cellHeight + "px"};
  width: ${cellWidth + "%"};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: content-box;
  padding-left: ${(props) => (props.highlight ? "10px" : "0px")};
  padding-right: ${(props) => (props.highlight ? "10px" : "0px")};
  margin-right: ${(props) => (props.highlight ? "10px" : "1px")};
  margin-left: ${(props) => (props.highlight ? "0px" : "1px")};
  animation: ${(props) => (props.highlight ? expand(props.margin) : collapse)}
    300ms linear 0s 1 forwards;
  cursor: pointer;
`;

const Box = styled.div`
  height: ${cellHeight + "px"};
  width: 100%;
  z-index: 2;
  margin-bottom: 1px;
  background-color: ${(props) => props.background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Hover = styled.div`
  height: ${props=>(props.num+2) * cellHeight + 20 + "px"};
  width: ${"calc(" + cellWidth + "% + 18px)"};
  box-shadow: 0 4px 40px 0 rgba(52, 52, 52, 0.5);
  z-index: 1;
  position: absolute;
  left: 0px;
  background-color: white;
  animation: ${(props) => slide(props.start, props.end)} 300ms linear 0s 1
    forwards;
  box-sizing: content-box;
`;

const Score = styled.div`
  color: white;
  font-size: 23px;
  font-weight: bold;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  margin-right: ${(props) => (props.visible ? "0px" : "50px")};
  transition: opacity 500ms, margin 300ms;
  font-family: "Red Hat Display", sans-serif;
`;

const Icon = styled.img`
  width: 49px;
  height: 46px;
  z-index: 2;
  margin-bottom: 20px;
`;
const Empty = styled.div`
  width: 49px;
  height: 46px;
  z-index: 2;
  margin-bottom: 20px;
`;

const Avatar = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
`;

const AvatarContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom:1px;
  width:100%;
`;

const colors = ["#e0d2fe", "#9f73fb", "#8d59fa", "#7e44fa", "#4f00f7"];
