import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import ReportType from "./components/ReportType";
import ReportOptions from "../components/ReportOptions";
import ReportCategories from "./components/ReportCategories";
import ReportPrimaries from "./components/ReportPrimaries";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_GET_REPORT_TEMPLATE } from "constants/actions";
import { selectlist } from "./dummy_data";
import { useNavigate } from "react-router-dom";

const ReportCreation = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(1);
  // Report template
  const [reportType, setReportType] = useState();
  // category_head
  const [reportOptions, setReportOptions] = useState();
  // category_option
  const [reportCategories, setReportCategories] = useState([]);
  // primary_uid
  const [reportPrimaries, setReportPrimaries] = useState([]);
  // secondary selected
  const [secondarySelected, setSecondarySelected] = useState(false);
  // Anchor Name
  const [anchorName, setAnchorName] = useState("");
  // Report settings
  const [reportSettings, setReportSettings] = useState();
  // List of available story templates.
  const [templateList, setTemplatelist] = useState([]);

  const [storyTemplates, setStoryTemplates] = useState([]);

  const navigate = useNavigate();

  const { get_auth, coreData, reportTemplates } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      reportTemplates: state.report_template,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  // TODO: replace this with storyTemplates data from redux
  useEffect(() => {
    if (reportTemplates?.report_template?.length > 0) {
      const filteredTemplates = reportTemplates?.report_template?.filter(
        (template) => template.type_of === 2
      );
      setStoryTemplates(filteredTemplates);
    }
  }, [reportTemplates]);

  useEffect(() => {
    dispatch({
      type: SG_GET_REPORT_TEMPLATE,
    });
  }, []);

  useEffect(() => {
    // Data story templates are defined by the type_of value of 2
    if (storyTemplates?.length > 0) {
      setTemplatelist(
        storyTemplates.filter(
          (template) =>
            template.type_of === 2 &&
            (template?.id === 17 || template?.id === 35)
        )
      );
    }
  }, [storyTemplates]);

  useEffect(() => {
    if (reportOptions && coreData?.categories?.length > 0) {
      const secondaries = coreData?.categories?.[0]?.categories?.find(
        (category) =>
          category.priority === "secondary" || category.priority === "primary"
      );
      // get the anchor name of the reportOptions

      const anchor = coreData?.categories?.[0]?.categories?.find(
        (category) => category.id === reportOptions
      );

      if (anchor?.priority === "primary") {
        setAnchorName("location");
      } else {
        setAnchorName(anchor?.name.toLowerCase());
      }

      if (secondaries?.id === reportOptions) {
        setSecondarySelected(true);
      } else {
        setSecondarySelected(false);
      }
    }
  }, [reportOptions, coreData?.categories]);

  const renderContent = () => {
    switch (stage) {
      case 1:
        return (
          <ReportType
            id={1}
            setTemplate={(id) => setReportType(id)}
            next={() => setStage(2)}
            templates={templateList}
            goBack={() => navigate("/leader/audit/reports/stories")}
          />
        );
      case 2:
        return (
          <ReportOptions
            next={(id) => {
              setReportOptions(id);
              if (!id) {
                setStage(4);
              } else {
                setStage(3);
              }
            }}
            goBack={() => setStage(1)}
          />
        );
      case 3:
        return (
          <ReportCategories
            selectedParent={reportOptions}
            next={(ids) => {
              setReportCategories(ids);
              if (secondarySelected) {
                setStage(5);
              } else {
                setStage(4);
              }
            }}
            lastStep={secondarySelected}
            categories={reportCategories}
            parentCategory={reportOptions}
            anchorName={anchorName}
            get_auth={get_auth}
            pdfOptions={reportSettings}
            reportType={reportType}
            goBack={() => {
              setStage(2);
              setReportCategories([]);
            }}
          />
        );
      case 4:
        return (
          <ReportPrimaries
            next={(ids) => {
              setReportPrimaries(ids);
              setStage(5);
            }}
            category_option={reportCategories}
            category_head={reportOptions}
            primary_uid={reportPrimaries}
            anchorName={anchorName}
            get_auth={get_auth}
            pdfOptions={reportSettings}
            reportType={reportType}
            goBack={() => {
              setStage(3);
              setReportPrimaries([]);
            }}
          />
        );
      default:
        return "";
    }
  };

  return (
    <Container>
      <Center>{renderContent()}</Center>
    </Container>
  );
};

export default ReportCreation;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  font-family: "Raleway";
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const Options = styled.div`
  width: 100%;
  display: flex;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;
