/**
 * This file contains the utility functions for the bar chart
 * Its main purpose is to take Pre-existing datasets and convert them
 * into the format that the HorizontalBarChart component expects.
 *
 * All utils in this folder should document the page they are implemented in.
 */

// This utility function is used in the FeedbackTag component
// app/audit/simple/FeedbackTag.js
export const convertFeedbackData = (data, mapping, actionablePercentages) => {
  const total = data.reduce((sum, item) => sum + item.data[0], 0);
  const getPercentage = (value) => ((value / total) * 100).toFixed(0);

  // the data format for this is an array with a name, and a data array with exactly 1 object
  // the data[0] is the value of the bar for that name.
  return data.map((item, index) => {
    return {
      name: item.name,
      percent: getPercentage(item.data[0]),
      value: item.data[0],
      color: mapping.find((i) => i.name === item.name).color,
      actionablePercentage: actionablePercentages[index],
    };
  });
};
