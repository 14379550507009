import React from "react";
import { Dropdown } from "semantic-ui-react";

const Filter = ({
  selected,
  setSelected,
  structure,
  options,
  ignoreString = false,
}) => {
  const optionsList =
    options ||
    structure[0].categories
      .filter(
        (categorySet) => categorySet.priority.toLowerCase() === "primary"
      )[0]
      .options.map((optionGroup) => {
        return {
          text: optionGroup.name,
          value: optionGroup.id,
        };
      });

  if (optionsList) {
    return (
      <div>
        <Dropdown
          placeholder="Select Location"
          fluid
          selection
          options={optionsList}
          value={selected}
          onChange={(e, { value }) => {
            if (!ignoreString) {
              if (typeof value === "string") {
                // On clear seems to give value = " " which breaks.
                setSelected(null);
              } else {
                setSelected(value);
              }
            } else {
              setSelected(value);
            }
          }}
          clearable
        />
      </div>
    );
  }

  return <div></div>;
};

export default Filter;
