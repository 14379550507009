import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Dropdown } from "semantic-ui-react";
import DatePick from "../DatePicker";

const Phase3 = ({ categories, questions, data, setData }) => {
  const setMember = (d) => {
    const _data = Object.assign({}, data);
    _data.member = d;
    setData(_data);
  };

  const setStatus = (d) => {
    const _data = Object.assign({}, data);
    _data.status = d;
    setData(_data);
  };

  return (
    <Container>
      <Title>Plan details</Title>

      <Label>Timeline</Label>
      <Description>
        Select a timeline for the implementation of this plan? ie. is it a long
        term or short term plan?
      </Description>
      <DatePick />

      <Label>Who is accountable for this plan?</Label>
      <Description>Tag team members to include them on the plan</Description>
      <Dropdown
        placeholder="Tag team members"
        fluid
        selection
        multiple
        options={templateOptions}
        value={data.member}
        onChange={(e, t) => setMember(t.value)}
        style={{ marginRight: 20 }}
      />

      <Label>Current plan status</Label>
      <Description>What is the status of this plan as of today?</Description>
      <Dropdown
        placeholder="Set a status"
        fluid
        selection
        options={statusOptions}
        value={data.status}
        onChange={(e, t) => setStatus(t.value)}
        style={{ marginRight: 20 }}
      />
    </Container>
  );
};

export default Phase3;

const Container = styled.div`
  width: 100%;
  height: 100%;
  font-family: "Barlow Semi Condensed", sans-serif;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-size: 13px;
  color: grey;
`;

const templateOptions = [
  {
    key: "Michael Bawol",
    text: "Michael Bawol",
    value: { id: 0, name: "Michael Bawol" },
  },
];

const statusOptions = [
  {
    key: "Planned",
    text: "Planned",
    value: 0,
  },
  {
    key: "In Progress",
    text: "In Progress",
    value: 1,
  },
  {
    key: "Action Required",
    text: "Action Required",
    value: 2,
  },
  // {
  // 	key: "On Hold",
  // 	text: "On Hold",
  // 	value: 3,
  // },
  {
    key: "Completed",
    text: "Completed",
    value: 4,
  },
];
