import {
  GET_SURVEY_QUESTIONS,
  FETCH_SURVEY_QUESTION,
  EDIT_SURVEY_QUESTION,
  ADD_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
  CLEAR_SURVEY_QUESTION,
  CLEAR_ALL,
  GET_OUTCOME_QUESTIONS,
} from "constants/actions";
  
  const initialState = {
    survey_questions: [],
    outcome_questions: [],
    pending: false,
  };
  
  export default function SurveyQuestion (state = initialState, action) {
    switch (action.type) {
      case GET_SURVEY_QUESTIONS:
        return {
          ...state,
          survey_questions: action.payload,
          pending: false,
        };
      case GET_OUTCOME_QUESTIONS:
        return {
          ...state,
          outcome_questions: action.payload,
          pending: false,
        };
      case FETCH_SURVEY_QUESTION:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
        };
      case EDIT_SURVEY_QUESTION:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
        };
      case ADD_SURVEY_QUESTION:
        return {
          ...state,
          created: action.payload,
        };
      case DELETE_SURVEY_QUESTION:
        return {
          ...state,
          survey_questions: state.survey_questions.filter(
            (row) => row.id !== action.payload.id
          ),
        };
      case CLEAR_SURVEY_QUESTION:
        return {
          ...state,
          survey_questions: [],
          pending: false,
        };
      case CLEAR_ALL:
        return {
          ...state,
          survey_questions: [],
          pending: false,
        };
      default:
        return state;
    }
  }
  