import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import BenchmarkChart from './BenchmarkChart';
import Slider from "./Slider";
import IconImg from "assets/images/UI/person_green.svg"


const Factor = () =>{
    const [value, setValue] = useState(10);

    return (
        <Container>
            <Title>Culture Factor Analysis</Title>
            <BigNumber>How EQ supports Psychological Safety</BigNumber>
                <Description>
                    Emotional intelligence is a key factor in creating a psychologically safe environment. When employees feel safe to express their thoughts and feelings, they are more likely to collaborate, innovate, and take risks. This leads to higher engagement, productivity, and overall well-being.
                </Description>


                <BenchmarkChart score={7.5} standard={8.2} title={'Psychological Safety'}/>


        
            <Content>
            
            <EQ>
                <IMG src={IconImg}/>
                Self Awareness
              
                
            </EQ>

            <Text>
            Higher team self awareness scores predicts <strong>4.3x higher likelihood</strong> to improve Psychological Safety.
            </Text>

            <Slider value={value} setValue={setValue}/>

            <T1>Predicted Impact</T1>
           
            <Large>
                +{(value*0.43).toFixed(1)} to {(value*0.66).toFixed(1)}% gain 
            </Large>
            <Small>
                * Based on the 95% confidence interval by improving self awareness by {value}%.
            </Small>

            {/* <div style={{display:'flex',marginTop:20}}>
            <Button>Predictive Model</Button>
               

            </div> */}

           

            </Content>





        </Container>
    )
}

export default Factor;



const Container = styled.div`
    max-width:800px;
    width:100%;
`

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
  max-width:800px;
`;


const BigNumber = styled.div`
    font-size: 28px;
    line-height: 1.4;
    font-weight: bold;
    margin-bottom: 10px;
`

const Text = styled.div`
  max-width:800px;
`
const Title = styled.div`
    color: #2d70e2;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;

`

const T2 = styled.div`
    color: #2A3039;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;

`

const Content = styled.div`
   
`
const T1 = styled.div`
    font-weight:bold;
    margin-top:30px;
    font-size:14px;
     color:#1D9A7D;
     text-transform:uppercase;
`

const Button = styled.div`
    background-color:#1D9A7D;
    color:#EAFBF7;
    padding:15px 20px;
    display:flex;
    justify-content:center;
    font-weight:600;
    border-radius:10px;
    width:calc(50% - 10px);
    margin-right:20px;
    cursor:pointer;

    a{
        color:#EAFBF7;
    }

`

const IMG = styled.img`
    width:40px;
    margin-right:10px;
`

const Large = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-top:5px;
    margin-bottom:10px;
    color:#2A3039;
`
const Small = styled.div`
    font-size:10px;
    margin-top:15px;
    color:#666D79;
    font-weight:600;
`

const D2 = styled.div`
    font-size:20px;
    line-height: 1.4;
    font-weight:700;
    color:#2A3039;
    margin-bottom:10px;
    `;

const EQ = styled.div`
    font-size:20px;
    font-weight:700;
    color:#1D9A7D;
    display:flex;
    align-items:center;
    margin-bottom:20px;
`