export const roles = [
  {
    role: 21,
    name: "Survey Access",
    description:
      "The user will receive an email to verify their contact information. Users with this access will receive surveys but will have no access to a login or account information.",
  },
  {
    role: 11,
    name: "Profile Access",
    description:
      "In addition to survey access, profile users can create a profile which includes their name, and a profile picture. Users with this access will not have access to further dashboards.",
  },
  {
    role: 10,
    name: "Anonymous Access",
    description:
      "In addition to profile access, anonymous will have access to dashboards anonymously.",
  },
  {
    role: 9,
    name: "Team Access",
    description:
      "In addition to profile access, team users can access team dashboards with aggregate information and data from their team surveys.",
  },
  {
    role: 5,
    name: "Team Leader Access",
    description:
      "In addition to team access, team leaders can invite additional team members, as well as view individual responses from the team surveys.",
  },
]; // old 2

export const accountType = [
  // {'role':1,'name':'Super Admin'}, // should not allow this change from this app
  // {'role':2,'name':'Ambassador'}, // should not allow this change from this app
  //   { role: 3, name: "Owner" }, // no real use in app right now
  { role: 3, name: "Coach" }, // no survey tokens or email
  { role: 5, name: "Team Admin" }, // old 2
  { role: 9, name: "Team Member" }, // old 1
  { role: 11, name: "Team Player" },
];

// EMAIL_STATUS = [
//   (9, 'Not Validated'),
//   (1, 'Validated'),
//   (2, 'Soft Bounce'),
//   (3, 'Hard Bounce'),
// ]

export const memberKey = { 9: "Sent Invite", 1: "Team Member" };
