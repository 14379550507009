import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import InvitedUser from './InvitedUser'
import {
  UPDATE_EMPLOYEE,
  LOAD_EMPLOYEES,
} from "constants/actions";

const UserList = (props) =>{
 

	return (
		<div>
			{
				props.invited.map((item,i)=>{
					return <InvitedUser data={item} />
				})
			}
		</div>
	)
}


export default UserList