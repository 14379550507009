import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top:20px;
`;

const ProgressBar = styled.div`
  height: 25px;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    #f15062,
    #ffedcf,
    #2d70e2,
    #27cda7
  );
  position: relative;
`;

const IndicatorContainer = styled.div`
  position: absolute;
  left: ${({ percentage }) => `${percentage}%`};
  transform: translateX(-50%);
  top: 0;
  height: 100%;
  width: 10px;
`;

const IndicatorBar = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  width: 4px;
  background-color: black;
`;

const Triangle = styled.div`
  position: absolute;
  top: -5px;
  left: 5px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
`;

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const Label = styled.span`
  font-size: 14px;
`;

const IndicatorBarComponent = ({ percentage }) => (
  <ProgressBarContainer>
    <ProgressBar>
      <IndicatorContainer percentage={percentage}>
        <Triangle />
        <IndicatorBar />
      </IndicatorContainer>
    </ProgressBar>
    <LabelsContainer>
      <Label>Low</Label>
      <Label>High</Label>
    </LabelsContainer>
  </ProgressBarContainer>
);

export default IndicatorBarComponent;
