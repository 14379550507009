// src/Toolbar.js
import React from 'react';
import styled from 'styled-components';
import { RichUtils } from 'draft-js';
import { Icon } from 'semantic-ui-react';

const ToolbarContainer = styled.div`
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  font-family:'Raleway';
`;

const Button = styled.button`
  padding: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonContainer = styled.div`
    display:flex;
    align-items:center;
    margin-right:5px;
    justify-content:center;
    align-items:center;
    padding:5px;
    cursor:pointer;

    &:hover {
        background-color: #f1f1f1;
    }
`

const Text = styled.div`
    font-size:16px;
    font-weight:bold;

`
const Toolbar = ({ editorState, setEditorState, preserveSelection }) => {
    const applyInlineStyle = (style) => {
        preserveSelection(() => {
          const newState = RichUtils.toggleInlineStyle(editorState, style);
          if (newState) {
            setEditorState(newState);
          }
        });
      };
    
      const applyBlockType = (blockType) => {
        preserveSelection(() => {
          const newState = RichUtils.toggleBlockType(editorState, blockType);
          if (newState) {
            setEditorState(newState);
          }
        });
      };

  return (
    <ToolbarContainer>
        <ButtonContainer onClick={() => applyInlineStyle('BOLD')}>
        <Icon name="bold" style={{marginTop:-3}}/>
        </ButtonContainer>

        <ButtonContainer onClick={() => applyInlineStyle('ITALIC')}>
        <Icon  name="italic" style={{marginTop:-3}}/>
        </ButtonContainer>

        <ButtonContainer onClick={() => applyInlineStyle('UNDERLINE')}>
        <Icon  name="underline"/>
        </ButtonContainer>
      
        <ButtonContainer onClick={() => applyBlockType('header-one')}>
            <Text>H1</Text>
        </ButtonContainer>

        <ButtonContainer onClick={() => applyBlockType('header-two')}>
            <Text>H2</Text>
        </ButtonContainer>

        <ButtonContainer onClick={() => applyBlockType('header-three')}>
            <Text>H3</Text>
        </ButtonContainer>

        <ButtonContainer onClick={() => applyBlockType('unordered-list-item')}>
            <Icon name="list ul" style={{marginTop:-3}}/>
        </ButtonContainer>

        <ButtonContainer onClick={() => applyBlockType('ordered-list-item')}>
            <Icon name="list ol" style={{marginTop:-3}}/>
        </ButtonContainer>
    </ToolbarContainer>
  );
};

export default Toolbar;
