let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const retrieveLinearCalc = (data, factor, questions) => {
  let vQuestions = [];

  data.questions.forEach((fd) => {
    if (fd.factor === factor) {
      vQuestions.push(fd);
    }
  });

  let vValues = [];
  vQuestions.forEach((q) => {
    let val;
    if (q.reverse) {
      val = (q.reverse && reverse[Math.floor(q.response)]) || q.response;
    } else {
      val = check_reverse_score(q, questions);
    }
    vValues.push(val);
  });

  let sum = 0;
  vValues.forEach((v) => {
    if (v) {
      sum += v;
    }
  });
  const avg = sum / vValues.length;

  return avg;
};

const retrieveLinearCalcAggregate = (data, factorsObj, questions) => {
  let vQuestions = [];

  factorsObj?.id?.factors?.forEach((f) => {
    data.questions.forEach((fd) => {
      if (fd.factor === f.id - 1 && fd.id === factorsObj?.id?.id - 1) {
        vQuestions.push(fd);
      }
    });
  });

  let vValues = [];
  vQuestions.forEach((q) => {
    let val;
    if (q.reverse) {
      val = (q.reverse && reverse[Math.floor(q.response)]) || q.response;
    } else {
      val = check_reverse_score(q, questions);
    }
    vValues.push(val);
  });

  let sum = 0;
  vValues.forEach((v) => {
    if (v) {
      sum += v;
    }
  });
  const avg = sum / vValues.length;

  return avg;
};

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions?.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? reverse[Math.floor(resp.response)] : resp.response;
  return response;
};

export const fetch_data = (data, x, y, questionStructure) => {
  return data.map((item) => {
    let _x = get_item(item, x, questionStructure);
    let _y = get_item(item, y, questionStructure);
    return [_x, _y];
  });
};

const get_item = (data, value, questionStructure) => {
  let type = value.type;

  if (type === "personality") {
    let id = value.id;
    return calculate_personality(data.survey_personality, id);
  }
  if (type === "records") {
    return data.employee_records.find((f) => f.id === value.id).value;
  }
  if (type === "culture") {
    if (value?.factor === null) {
      return retrieveLinearCalcAggregate(data, value, questionStructure);
    } else {
      return retrieveLinearCalc(data, value.factor, questionStructure);
    }
  }
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return Math.round(((score1 + score2) / 2) * 100) / 100;
};
