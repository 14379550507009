import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Dropdown } from "semantic-ui-react";

const calculate_options = (categories) => {
	return categories.categories.map((i) => {
		return {
			key: i.name,
			text: i.name,
			value: i.id,
		};
	});
};

const calculate_options2 = (categories, selected) => {
	return categories.categories
		.find((f) => f.id == selected)
		.options.map((i) => {
			return {
				key: i.name,
				text: i.name,
				value: i.id,
			};
		});
};

const People = ({
	categories,
	selected,
	setSelected2,
	setSelected,
	selected2,
}) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState(calculate_options(categories));
	const [options2, setOptions2] = useState([]);

	useEffect(() => {
		setSelected2(null);
		if (selected) {
			setOptions2(calculate_options2(categories, selected));
		}
	}, [selected]);

	return (
		<Container>
			<Top onClick={() => setOpen(!open)}>
				<Title>Anchor</Title>
				<Description>
					Is there a data anchor you'd like to target? (Optional)
				</Description>
			</Top>
			<SelectionContainer>
				<Dropdown
					placeholder="Select an anchor"
					fluid
					selection
					options={options}
					value={selected}
					onChange={(e, t) => setSelected(t.value)}
					style={{ marginRight: 20 }}
					clearable
				/>
				<Dropdown
					placeholder="Select an anchor option"
					fluid
					selection
					options={options2}
					value={selected2}
					onChange={(e, t) => setSelected2(t.value)}
				/>
			</SelectionContainer>
		</Container>
	);
};

export default People;

const Container = styled.div`
	width: 100%;
	font-family: "Barlow Semi Condensed", sans-serif;
	border-radius: 3px;
	padding: 15px;
`;

const Top = styled.div`
	display: flex;

	position: relative;
	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
	cursor: pointer;
`;

const Title = styled.div`
	font-weight: bold;
	margin-right: 5px;
	font-size: 16px;
`;

const Description = styled.div`
	font-size: 13px;
	color: grey;
`;

const IconContainer = styled.div`
	position: absolute;
	right: 10px;
`;

const SelectionContainer = styled.div`
	display: flex;
	margin-top: 10px;
`;

const Selected = styled.div``;

const templateOptions = [
	{
		key: "Strategic Gap Analysis",
		text: "Strategic Gap Analysis",
		value: "Strategic Gap Analysis",
	},
];
