import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import Footer from '../components/Footer'
import Side from '../components/Side'


const styles = StyleSheet.create({
	title:{
		fontSize:16,
		fontWeight:700,
		paddingBottom:5,
		borderBottom:'0.5px solid black',
		width:'100%',
		color:'#3076B8',
		marginBottom:15
	},
	page:{
		width:'100%',
		height:'100vh',
		position:'relative',
		padding:60,
		paddingHorizontal:50,
		display:'flex',
		flexDirection:'row'
	},
	quote:{
		width:'30%',
		fontSize:13,
		lineHeight:1.7,
		textAlign:'right',
		display:'flex',
		alignItems:'flex-end',
		marginTop:5
	},
	textarea:{
		width:'70%',
		paddingLeft:30,
		
	},
	title:{
		fontSize:20,
		fontWeight:700
	},
	p:{
		marginTop:20,
		fontSize:11,
		lineHeight:1.7
	},
	author:{
		width:70,
		fontSize:10,
		lineHeight:1.2,
		marginTop:5
	}
})


const SectionIntro = ({title}) =>{


	return (
		<Page style={styles.page}>
			<Side/>
			<Footer/>

			<View style={styles.quote}>
				<Text>“Tableau allows us to
					rapidly iterate and do this
					rapid hypothesis testing
					to look for new things, and
					that’s important when
					you’re dealing with these
					really large data sets. You
					need to be able to change
					your views and the way
					you’re analyzing it pretty
					quickly. Tableau allows us
					to do that.”</Text>

					<Text style={styles.author}>- Emeliano Void, CEO and founder on nuLogic</Text>
			</View>

			<View style={styles.textarea}>
				<Text style={styles.title}>{title}</Text>

				<Text style={styles.p}>While it’s important to stay on top of evolving market definitions, you
					have to remember the reason why we’ve united around analytics in the
					first place: More data is available to us every day; the decisions we make
					on behalf of our businesses are increasingly important; and we look to
					technology to help us organize and analyze that data. While the technologies that support us in this endeavor have changed over the years, our
					ultimate goal remains the same: to find answers to the questions that
					matter—the ones that identify opportunities to push our businesses in the
					right direction.</Text>

				<Text style={styles.p}>An early paper on visual analytics reads, “Let’s start with what visual
analytics is not: A graphical depiction of data. Virtually any software application can produce a chart, gauge or dashboard. Visual analytics offers
something much more profound. Visual analytics is the process of analytical reasoning facilitated by interactive visual interfaces.”
				</Text>

				<Text style={styles.p}>While it’s important to stay on top of evolving market definitions, you
					have to remember the reason why we’ve united around analytics in the
					first place: More data is available to us every day; the decisions we make
					on behalf of our businesses are increasingly important; and we look to
					technology to help us organize and analyze that data. While the technologies that support us in this endeavor have changed over the years, our
					ultimate goal remains the same: to find answers to the questions that
					matter—the ones that identify opportunities to push our businesses in the
					right direction.</Text>

			</View>


		</Page>
		)
}

export default SectionIntro