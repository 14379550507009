import styled, { keyframes } from 'styled-components';

export const MemberContainer = styled.div`
width:100%;
padding:18px 0px 18px 0px;
border-bottom:1px solid #e9e9e9;
display:flex;
flex-direction:row;
align-items:center;
font-size: 16px;
font-weight: 500;
font-family: 'Red Hat Display', sans-serif;
color: #4a4a4a;


`
export const Avatar = styled.img`
height:46px;
width:46px;
border-radius:23px;
border: solid 1px #e9e9e9;
margin-right:10px;
min-width:46px;
`

export const BlankAvatar = styled.div`
 height:46px;
width:46px;
border-radius:23px;
background-color:#4a4a4a;
margin-right:10px;
min-width:46px;
`

export const Name = styled.div`
font-size: 14px;
font-family: 'Red Hat Display', sans-serif;
color:#9b9b9b;
width:80%;
`
export const Email = styled.div`
font-size: 12px;
font-family: 'Red Hat Display', sans-serif;
color:#9b9b9b;
`

export const Status = styled.div`
font-size: 16px;
font-weight: bold;
font-family: 'Red Hat Display', sans-serif;
color:${props=>props.status<3? '#9b9b9b' : 'black'}
`

export const Text = styled.div`
font-size: 14px;
font-family: 'Red Hat Display', sans-serif;
`

export const MobileStatus = styled.div`
	font-size: 13px;
font-family: 'Red Hat Display', sans-serif;

@media (min-width:768px){
	display:none;
}
`

export const RowContainer = styled.div`
width:${props=>props.width+'%'};
display:flex;
height:100%;
align-items:center;
padding:0 0 0 15px;

@media (max-width:768px){
	display:${props=>props.mobile?'flex':'none'};
}
`
export const Member = styled.div`
	width:40%;
	display:flex;
	height:100%;
	align-items:center;
	padding:0 0 0 15px;

	@media (max-width:768px){
		width:100%;
		position:relative;
		word-break:break-word;

	}
`

export const MobileView = styled.div`
	position:absolute;
	right:20px;
	color: #7500ff;

	@media (min-width:768px){
		display:none;
	}
`

export const View = styled.span`
	font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  color: #7500ff;
  display:flex;
  justify-content:center;
  align-items:center;

  @media (max-width:768px){
  	display:none;
  }
`