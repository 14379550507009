import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import {  CultureQuestions } from "data/live/navigation"

const data = {

  series: [{
    name: 'Last week',
    data: [0, 0, 0, 0, 0, 1, 4, 5, 2, 4]
  }, {
    name: 'This week',
    data: [0, 0, 0, 0, 0, 2, 3, 6, 3, 3]
  },],
  options: {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: '50%',
      }
    },
    colors: ['#c3a7fd', '#6a00ff'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 2
    },

    grid: {
      row: {
        colors: ['#fff', '#f2f2f2']
      }
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: ['0-10%', '10-20%', '20-30%', '30-40%', '40-50%', '50-60%', '60-70%', '70-80%', '80-90%', '90-100%'
      ],
      tickPlacement: 'on'
    },
    yaxis: {
      title: {
        text: '# of responses',
      },
    },
    fill: {
      colors: ['#c3a7fd', '#6a00ff'],
    }
  },

};

const Responses = ({scoreData, scoreLastData, selectedName, cultureQuestion, ...props}) => {
  const [seriesData, setSeriesData] = React.useState(data.series)
  // console.log(data.series)
  // console.log(scoreData)

  React.useEffect(() => {
    setSeriesData(
      [{
        name: 'Last week',
        data: scoreLastData
      }, {
        name: 'This week',
        data: scoreData
      },]
    )
  },[scoreData, scoreLastData])


  return (
    <Container>
      {props.none ? <Title>{selectedName} Metrics</Title> :
        <><Title>{selectedName} question</Title>
          <Question>{cultureQuestion}</Question>
        </>}
      <Chart 
      options={data.options} 
      series={seriesData} 
      type="bar" height={350} />
    </Container>
  )
}


export default Responses



const Container = styled.div`
	width:100%;
  margin-top:30px;

`

const Title = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:30px;
`

const Question = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;

	margin-bottom:50px;
  line-height:1;
`