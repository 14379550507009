import React from 'react';
import styled from 'styled-components';

// Styled components
const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between; // This spreads out the steps evenly
  position: relative;
  width: 100%;
  padding: 0 15px; // This accounts for the width of the circles on the ends
`;

const ProgressLine = styled.div`
  height: 4px;
  background-color: #e0e0e0;
  position: absolute;
  left: 15px; // Same as padding on ProgressBarContainer
  right: 15px; // Same as padding on ProgressBarContainer
  top: 50%; // Centers the line vertically
  transform: translateY(-50%);
  z-index: 0;
`;

const Step = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; // Needed for absolute positioning of the checkmark and label
  z-index: 1;
`;

const FilledStep = styled(Step)`
  border-color: #007bff;
  background-color: #007bff;
  color: white;
`;

const Checkmark = styled.span`
  font-size: 24px;
`;

const StepLabel = styled.div`
  position: absolute;
  top: 35px; // Adjust this value as needed to position the label below the step
  width: 200%; // Allows text-align center to actually center the text
  left:-50%;
  text-align: center;
  font-size: 12px;
  color: #333;
`;

// ProgressTracker component
const ProgressTracker = ({ steps, currentStep }) => {
  return (
    <ProgressBarContainer>
      <ProgressLine />
      {steps.map((step, index) => {
        const isFilled = index < currentStep;
        return (
          <div key={index} style={{ position: 'relative' }}>
            {isFilled ? (
              <FilledStep>
                <Checkmark>✓</Checkmark>
              </FilledStep>
            ) : (
              <Step />
            )}
            <StepLabel>{step}</StepLabel>
          </div>
        );
      })}
    </ProgressBarContainer>
  );
};


const App = ({step,currentStep}) => {

  return <ProgressTracker steps={step} currentStep={currentStep} />;
};

export default App;

