import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Defs,
  LinearGradient,
  Stop,
} from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { ActionPlanEllipse, ActionPlanDottedLine } from "./icons";
import Footer from "./Footer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    height: 600,
    paddingHorizontal: 40,
    paddingTop: 40,
    paddingBottom: 80,
    fontFamily: "Raleway",
    backgroundColor: "#F9F9F9",
  },
  pageTitle: {
    width: "55%",
  },
  pageTitleText: {
    color: "#2A3039",
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "120%",
  },
  blueTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "120%",
    color: "#2D70E2",
  },
  planNumber: {
    fontSize: 100,
    fontWeight: 800,
    position: "absolute",
    left: -5,
    top: -38,
    zIndex: -1,
    color: "#EBF1FD",
    fontStyle: "normal",
    lineHeight: "128%",
  },
  planSection: {
    backgroundColor: "#FFF",
    width: "100%",
    paddingTop: 40,
    borderRadius: 10,
  },
  planTitleSection: {
    width: "100%",
    height: 50,
    justifyContent: "center",
    display: "flex",
    paddingLeft: 28,
    paddingRight: 28,
  },
  planTitle: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "128%",
    color: "#2A3039",
  },
  stepSection: {
    paddingLeft: 65,
    paddingRight: 40,
    paddingTop: 20,
  },
  step: {
    paddingBottom: 30,
  },
  stepIcon: {},
  stepTitle: {
    paddingTop: 8,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: "128%",
    color: "#2A3039",
  },
  stepPoints: {
    marginLeft: 5,
  },
  stepContainer: {
    paddingTop: 15,
    display: "flex",
    flexDirection: "row",
  },
  stepPoint: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: 1.7,
    marginLeft: 6,
    color: "#2A3039",
  },
  stepBullet: {
    display: "flex",
    justifyContent: "start",
    paddingTop: 0.5,
    lineHeight: 1.7,
    fontSize: 10,
  },
  elipseTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  stepData: {},
  elipseContainer: {
    left: -37,
    paddingTop: 8,
    zIndex: 2,
  },
  dottedBorder: {
    borderStyle: "dotted",
    borderLeftWidth: 1,
    borderColor: "#D5E2F9",
    borderRadius: 1,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
});

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const ActionPlanTitle = ({ title, planNumber }) => {
  // Regex to make any text with "action plan" in it blue
  // This could probably be turned into a reusable function
  const blueRegex = /action plan/gi;
  const blueIndex = title.search(blueRegex);
  const blueMatch = title.match(blueRegex);
  const titleFirstSection = title.slice(0, blueIndex);
  const titleSecondSection = title.slice(blueIndex).replace(blueRegex, "");

  return (
    <View style={styles.pageTitle}>
      <Text style={styles.pageTitleText}>
        {titleFirstSection}
        <Text style={styles.blueTitle}>{blueMatch}</Text>
        {titleSecondSection}
      </Text>
    </View>
  );
};

// Create Document Component
const ActionPlanPage = (props) => {
  const { title, stepsData } = props;

  return (
    <Page style={styles.page} size="A4">
      {title && <ActionPlanTitle title={title} />}

      <View style={styles.planSection}>
        <View style={styles.planTitleSection}>
          <Text style={styles.planNumber}>{stepsData.planNumber}</Text>
          <Text style={styles.planTitle}>{stepsData.planTitle}</Text>
        </View>
        <View style={styles.stepSection}>
          {stepsData.steps.map((step, i) => {
            let lineLength = 320;
            step.stepPoints.forEach((point) => {
              if (point.length > lineLength) {
                lineLength += point.length;
              }
            });

            return (
              <View style={styles.step} wrap={false}>
                <View>
                  <View style={styles.stepIcon}>
                    <Text
                      render={({ subPageNumber }) => subPageNumber > 1 && ` `}
                    />
                    {step.icon}
                  </View>
                  <View style={styles.stepData}>
                    <View style={[styles.elipseTitleContainer]}>
                      <View style={styles.elipseContainer}>
                        <ActionPlanEllipse />
                      </View>
                      <Text style={styles.stepTitle}>{step.stepTitle}</Text>
                    </View>
                    <View style={styles.stepPoints}>
                      {step.stepPoints.map((point) => {
                        return (
                          <View
                            style={styles.stepContainer}
                            break={true}
                            wrap={false}
                          >
                            <Text style={styles.stepBullet}>•</Text>
                            <Text style={styles.stepPoint}> {point}</Text>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </View>
      <Footer overlapBackground={true} />
    </Page>
  );
};

export default ActionPlanPage;
