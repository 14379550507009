import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";


const ActionAnalysis = () =>{


	return (
		<Container>
			<Title>Culture Intervention ROI</Title>
			<T1>Discover which action plans had the biggest impact in your organization, and learn how to best manage culture</T1>
			<Chart
							options={data2.options}
							series={data2.series}
							type="scatter"
							height={600}
							style={{marginTop:30}}
						/>

		</Container>
		)
}


export default ActionAnalysis



const Container = styled.div`
	padding-left:58px;
`

const Title = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
display: flex;
align-items: center;
text-transform: capitalize;

color: #414141;
margin-top:40px;
`

const T1 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 1.4;
display: flex;
align-items: center;

color: #7E7E7E;
`

const data2 = {
	series: [{
              name: "High Impact",
              data: [
              [16,20], [54,33], [9,12], [18,17]]
            },{
              name: "Neutral",
              data: [
              [16,0.2], [40,2], [33,-1], [24,-0.4]]
            },{
              name: "Negative Impact",
              data: [
              [30,-10], [15,-23.6], [16,-5], [37,-16]]
            }],
	options: {
		chart: {
			type: "scatter",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			sparkline: {
				enabled: false,
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#66D686", "#F3AB3C","#E5657A"],
		grid: {
			padding: {
				left: 10,
				right: 10,
				bottom: 20,
				top: 10,
			},
		},
		stroke: {
			width: [5, 2],
			curve: "smooth",
			dashArray: [0, 8],
		},
		xaxis: {
			title:{
				text:"Group Size Impacted",
				style:{
					fontWeight:400
				}
			}
		},
		yaxis: {
			title: {
				text: "Culture ROI",
				style:{
					fontWeight:400
				}
			},
			decimalsInFloat: 1,
		},
	},
};