import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicInput from "./TextBoxColumn";
import { addIcon, trashIcon } from "components/Icons";
import DatePick from "./DatePicker";
import TableStatus from "./DropdownTable/TableStatus";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
} from "reports/Audit/data_navigation/calculate_data";

import { buildRankingTables } from "./utils";
import translations from "components/CWFPDF/translations.json";

import {
  SET_SURVEY_VERSION,
  SG_FETCH_USER_EMPLOYEE,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_STANDARD,
  SET_QUESTIONS,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SG_FETCH_DEBRIEF_SCHEDULE,
} from "constants/actions";
import { calculate_otp_table_data } from "reports/Audit/data_navigation/calculate_data";
import Ranking from "components/CWFPDF/Ranking";

const factorsList = [
  {
    key: 1,
    text: "Equity",
    value: "Equity",
  },
  {
    key: 2,
    text: "Inclusion",
    value: "Inclusion",
  },
  {
    key: 3,
    text: "Accessibility",
    value: "Accessibility",
  },
];

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const factorDescriptions = [
  {
    key: 1,
    text: "Equity",
    description:
      "An equitable organization allocates resources, programs and decision-making in ways that result in similar opportunities and benefits participants. An equity lens recognizes that decisions need to account for unequal barriers to sport and leadership, to ensure that everyone has access to the full range of opportunities to achieve the benefits that come from participating in sport.",
    second:
      "In the table below, indicate a Culture Pillar to target, and describe your team / organizations desired state of equity within your sport context. Please indicate the action and resources needed to reach each desired state. Multiple line items may be submitted.",
  },
  {
    key: 2,
    text: "Inclusion",
    description:
      "is where people have both the feeling and reality of belonging, where they can participate in ways that are meaningful to them, and where the activity contributes to them reaching their full potential.",
    second:
      "In the table below, use the dropdown to select a Culture Pillar to target, and describe your team / organizations desired state of inclusion within your sport context. Please indicate the action and resources needed to reach each desired state. Multiple line items may be submitted.",
  },
  {
    key: 3,
    text: "Accessibility",
    description:
      "in sport can refer to an environment outcome such as, physical space, information, or service that is flexible to accommodate a person’s preferences or needs without adaption.",
    second:
      "In the table below, indicate your target Culture Pillar and describe your team / organizations desired state of accessibility within your sport context. Please indicate the action and resources needed to reach your desired state. Multiple line items may be submitted.",
  },
];

const rankHeaders = ["AV", "A", "C", "SSM", "NSO"];

const EIAComponent = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    onChange,
    id,
    editEnabled,
    questions,
    primary,
    handleTableData,
    handleEIAQuestionSelect,
    handleEIAFactorSelect,
    editLayout,
    savedTemplate,
  } = props;
  const [addSectionHovered, setAddSectionHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState();
  const [dynamicRows, setDynamicRows] = useState([[[]]]);
  const [selectedFactors, setSelectedFactors] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [questionsList, setQuestionsList] = useState([]);
  const [calculatedData, setCalculatedData] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [canEdit, setCanEdit] = useState();
  const [canAccessData, setCanAccessData] = useState();

  const [currentUser, setCurrentUser] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [sportsFiltered, setSportsFiltered] = useState();
  const [filteredCategories, setFilteredCategories] = useState();
  const [reportData, setReportData] = useState();
  const [organizationData, setOrganizationData] = useState(null);
  const [tableData, setTableData] = useState();
  const [renderedDataTable, setRenderedDataTable] = useState();

  // ******** Selectors ********
  const {
    get_employee_user_category,
    survey_version,
    get_employees,
    WhiteLabel,
    get_survey_structure,
    get_culture_audit_reports,
    get_survey_questions,
    get_organizations,
    get_auth,
    org,
  } = useSelector(
    (state) => ({
      get_survey_questions: state.surveyquestions.survey_questions,
      get_organizations: state.organizations,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_survey_structure: state.surveystructure.survey_structure,
      get_auth: state.auth,
      WhiteLabel: state.white_label?.white_label,
      survey_version: state.audit.survey_version,
      get_employees: state.employees,
      org: state.organizations,
    }),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationData(
      org[Number(get_auth?.organization_id) || org?.organization?.id]
    );
  }, [org, get_auth?.organization_id]);

  useEffect(() => {
    if (!currentUser && !get_employee_user_category?.length > 0) {
      dispatch({
        type: SG_FETCH_USER_EMPLOYEE,
        payload: {
          id: Number(get_auth?.employee_id),
        },
      });

      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${get_auth?.employee_id}`,
      });
    }
  }, [dispatch, get_auth]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=8&status=2`,
    });
  }, []);

  useEffect(() => {
    if (!data?.planData[3]) {
      if (get_auth?.organization_id) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=5&organization=${get_auth?.organization_id}`,
        });
      }
    }
  }, [get_auth?.organization_id]);

  /** useEffect Hooks
   * 1. Calculate data for table
   * 2. Get column config for table
   * 3. Get table data
   * 4. Get URI
   * 5. Get Spider options
   */
  useEffect(() => {
    if (
      !savedTemplate &&
      reportData &&
      get_survey_structure?.[0] &&
      questions?.questions &&
      primary
    ) {
      const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : 2;
      const secondary = get_survey_structure[0]?.categories?.find(
        (category) => category.priority === "secondary"
      );

      setCalculatedData(
        calculate_otp_table_data(
          reportData,
          get_survey_structure[0],
          secondary?.id,
          orgRule,
          questions?.questions,
          primary,
          true
        )
      );
    }
  }, [reportData, get_survey_structure[0], questions?.questions, primary]);

  useEffect(() => {
    if (
      calculatedData?.responses &&
      questions?.questions &&
      get_survey_structure[0]
    ) {
      const ranking = buildRankingTables(
        calculatedData?.responses,
        get_survey_structure[0],
        questions?.questions,
        selectedQuestion,
        false,
        translations,
        "QUESTION"
      );

      setTimeout(() => {
        setTableData(ranking);
      }, 300);
    }
  }, [
    calculatedData,
    questions?.questions,
    get_survey_structure,
    selectedQuestion,
    primary,
  ]);

  useEffect(() => {
    if (!savedTemplate && tableData && handleTableData) {
      handleTableData(tableData);
    }
  }, [tableData, primary]);

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    if (get_employees) {
      // setUserEmployee(get_employees?.userEmp);
      setCurrentUser(get_employees?.userEmp);
      // check for admin
      if (get_employees.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employees.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employees.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employees]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure[0]) {
      const DefState = build_default_State(get_survey_structure[0]);
      dispatch({
        type: SET_NAVIGATION,
        payload: DefState,
      });
      if (get_culture_audit_reports.response) {
        if (get_culture_audit_reports.response.length > 0) {
          let sorted_responses = get_culture_audit_reports.response.sort(
            (a, b) => b.id - a.id
          );

          if (survey_version) {
            sorted_responses = sorted_responses.filter(
              (f) => f.question.sort_order === survey_version.sort_order
            );
          }

          if (sportsFiltered?.length > 0) {
            sorted_responses = sorted_responses.filter((responseGroup) => {
              return sportsFiltered.includes(
                responseGroup.name.replace(/[ \t]+(\r?$)/, "")
              );
            });
          }

          let most_recent;

          most_recent = get_most_recent(sorted_responses);

          let total = {};
          most_recent.responses?.map((r) => {
            let n = r.categories.length.toString();
            if (!(n in total)) {
              total[n] = 1;
            } else {
              total[n] += 1;
            }
          });

          most_recent["last"] = get_last_responses(sorted_responses);

          if (sorted_responses.length > 0 && most_recent.responses) {
            let historical_data = sort_historical_data(
              sorted_responses.reverse(),
              0
            );

            setReportData([most_recent, ...historical_data]);

            // setComments(check_comments(most_recent, get_employees));

            dispatch({
              type: SET_RAW_DATA,
              payload: [most_recent, ...historical_data],
            });

            dispatch({
              type: SET_DATA_DATES,
              payload: get_dates(historical_data),
            });
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    survey_version,
    primary,
  ]);

  useEffect(() => {
    if (get_auth?.employee_id) {
      if (get_employee_user_category?.length > 0) {
        const currentCategories = get_employee_user_category.find(
          (f) => f.employee_id === Number(get_auth?.employee_id)
        );
        setEmployeeCategories(currentCategories);
      }
    }
  }, [get_auth?.employee_id, get_employee_user_category]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);

        return;
      }

      if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value);

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
  ]);

  useEffect(() => {
    if (editEnabled) {
      onChange(dynamicRows, id);
    }
  }, [dynamicRows]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (data && !dynamicRows?.length > 0) {
      setDynamicRows(JSON.parse(JSON.stringify(data?.planData[id])));
    }
  }, [data]);

  const addRow = (f) => {
    const _rows = [...dynamicRows];
    if (!_rows[f]) {
      _rows[f] = [];
    }
    _rows[f].push([]);
    setDynamicRows(_rows);
  };

  const removeRow = (i, f) => {
    const _rows = [...dynamicRows];
    _rows?.[f]?.splice(i, 1);
    setDynamicRows(_rows);
  };

  const handleInputChange = (d, i, f, idx) => {
    // set the inputText at the particular index of the array
    //   if the index doesn't exist, create it
    const _inputText = [...dynamicRows];
    if (!_inputText[f][i]) {
      _inputText[f][i] = [];
    }
    _inputText[f][i][idx] = d;

    setDynamicRows(_inputText);
  };

  const handleStatusChange = (d, i, f, idx) => {
    const tableData = [...dynamicRows];
    if (!tableData[f][i]) {
      tableData[f][i] = [];
    }
    tableData[f][i][idx] = d;
    setDynamicRows(tableData);
  };

  const handleDatesSelected = (date, id, f, idx) => {
    if (date) {
      const tableData = [...dynamicRows];

      if (!tableData[f][id]) {
        tableData[f][id] = [];
      }
      tableData[f][id][idx] = date;
      setDynamicRows(tableData);
    }
  };

  const getRows = () => {
    return dynamicRows;
  };

  const onSelectFactors = (factors) => {
    setSelectedFactors(factors);
    handleEIAFactorSelect(factors);
    let _rows = [...dynamicRows];
    factors.forEach((f, i) => {
      if (!_rows[i]) {
        _rows.push([[]]);
      }
    });
    setDynamicRows(_rows);
  };

  useEffect(() => {
    if (!selectedFactors?.length > 0 && data?.planData[1]) {
      setSelectedFactors(data?.planData[1]);
      let _rows = JSON.parse(JSON.stringify(data?.planData[id]));
      data?.planData[1].forEach((f, i) => {
        if (!_rows[i]) {
          _rows.push([[]]);
        }
      });
      setDynamicRows(_rows);
    }

    if (!selectedQuestion && data?.planData[2]) {
      setSelectedQuestion(data?.planData[2]);
    }

    if (savedTemplate && selectedQuestion && data?.planData[3]) {
      setTableData(data?.planData[3]);
    }
  }, [data, selectedQuestion]);

  const onSelectQuestion = (questions) => {
    setSelectedQuestion(questions);
    handleEIAQuestionSelect(questions);
  };

  return (
    <ComponentContainer>
      {tableData && (
        <DataTableContainer>
          <DataTableTitle>Dimension Data:</DataTableTitle>
          <Ranking
            title={"QUESTION"}
            headers={rankHeaders}
            data={tableData}
            isFrench={false}
          />
        </DataTableContainer>
      )}
      {selectedFactors
        ?.filter((fa) => factorDescriptions?.find((ft) => ft.text === fa))
        .map((fa, f) => (
          <FactorTable>
            <DescriptionContainer>
              <Factor>
                {factorDescriptions?.find((ft) => ft.text === fa)?.text}
                :&nbsp;
              </Factor>
              <Description>
                {factorDescriptions?.find((ft) => ft.text === fa)?.description}
              </Description>
              <Description>
                {factorDescriptions?.find((ft) => ft.text === fa)?.second}
              </Description>
            </DescriptionContainer>
            <TableWrapper>
              <TableContainer>
                <TableRow>
                  <RowHeader style={{ width: 150 }}>
                    NSO Desired State
                  </RowHeader>
                  <RowHeader style={{ width: 250 }}>
                    Description of Action
                  </RowHeader>
                  <RowHeader style={{ width: 145 }}>Resource Needed</RowHeader>
                  <RowHeader style={{ width: 140 }}>Lead Personnel</RowHeader>
                  <RowHeader style={{ width: 130 }}>Timeline</RowHeader>
                  <RowHeader style={{ width: 120 }}>Status</RowHeader>
                </TableRow>
                {getRows()?.[f]?.map((r, i) => {
                  const altBackground = i % 2 === 0;
                  const rowIsHovered = hoveredRow === `${i}-${f}`;
                  return (
                    <TableRow
                      hideBorder={true}
                      altBg={altBackground}
                      onMouseOver={() => setHoveredRow(`${i}-${f}`)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      <TableCol
                        altBg={altBackground}
                        style={{
                          minWidth: 140,
                          maxWidth: 140,
                          maxHeight: "100%",
                        }}
                      >
                        {(editEnabled && (
                          <BasicInput
                            altBg={altBackground}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[0] || ""
                            }
                            onChange={(d) => {
                              handleInputChange(d, i, f, 0);
                            }}
                            placeholder="Enter details"
                            hideFocus={true}
                            editEnabled={editEnabled}
                            inTable={true}
                          />
                        )) || (
                          <TextWrapper altBg={altBackground}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[0] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      <TableCol
                        altBg={altBackground}
                        style={{
                          minWidth: 235,
                          maxWidth: 235,
                          maxHeight: "100%",
                        }}
                      >
                        {(editEnabled && (
                          <BasicInput
                            altBg={altBackground}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[1] || ""
                            }
                            onChange={(d) => handleInputChange(d, i, f, 1)}
                            placeholder="Enter details"
                            hideFocus={true}
                            editEnabled={editEnabled}
                            inTable={true}
                          />
                        )) || (
                          <TextWrapper altBg={altBackground}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[1] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      <TableCol
                        altBg={altBackground}
                        style={{
                          minWidth: 135,
                          maxWidth: 135,
                          maxHeight: "100%",
                        }}
                      >
                        {(editEnabled && (
                          <BasicInput
                            altBg={altBackground}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[2] || ""
                            }
                            onChange={(d) => handleInputChange(d, i, f, 2)}
                            placeholder="Enter details"
                            hideFocus={true}
                            inTable={true}
                            editEnabled={editEnabled}
                          />
                        )) || (
                          <TextWrapper altBg={altBackground}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[2] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      <TableCol
                        altBg={altBackground}
                        style={{
                          minWidth: 135,
                          maxWidth: 135,
                          maxHeight: "100%",
                        }}
                      >
                        {(editEnabled && (
                          <BasicInput
                            altBg={altBackground}
                            hideBorders={true}
                            value={
                              props.data?.planData?.[id]?.[f]?.[i]?.[3] || ""
                            }
                            onChange={(d) => handleInputChange(d, i, f, 3)}
                            placeholder="Enter details"
                            hideFocus={true}
                            inTable={true}
                            editEnabled={editEnabled}
                          />
                        )) || (
                          <TextWrapper altBg={altBackground}>
                            {props.data?.planData?.[id]?.[f]?.[i]?.[3] || ""}
                          </TextWrapper>
                        )}
                      </TableCol>
                      <TableCol
                        altBg={altBackground}
                        style={{
                          minWidth: 135,
                          maxWidth: 135,
                        }}
                      >
                        <div style={{ marginTop: -55, height: "100%" }}>
                          <DatePick
                            handleDateChange={(date) =>
                              handleDatesSelected(date, i, f, 4)
                            }
                            {...props}
                            date={
                              props.data?.planData?.[id]?.[f]?.[i]?.[4] || null
                            }
                          />
                        </div>
                      </TableCol>

                      <TableCol altBg={altBackground} style={{ maxWidth: 100 }}>
                        <div style={{ marginTop: 35, height: "100%" }}>
                          <TableStatus
                            selectedOption={
                              planStatus?.find(
                                (s) =>
                                  s?.value ===
                                  props.data?.planData?.[id]?.[f]?.[i]?.[5]
                              )?.text || ""
                            }
                            setStatus={(d) => handleStatusChange(d, i, f, 5)}
                            editEnabled={editEnabled}
                          />
                        </div>
                      </TableCol>
                      {editEnabled && (
                        <RemoveRowContainer
                          isRowFocused={rowIsHovered}
                          onClick={() => removeRow(i, f)}
                        >
                          {trashIcon()}
                        </RemoveRowContainer>
                      )}
                    </TableRow>
                  );
                })}
                {editEnabled && (
                  <AddRowContainer
                    onMouseOver={() => setAddSectionHovered(true)}
                    onMouseLeave={() => setAddSectionHovered(false)}
                    isHovered={addSectionHovered}
                  >
                    <AddContainer onClick={() => addRow(f)}>
                      Add Row&nbsp;&nbsp;{addIcon()}
                    </AddContainer>
                  </AddRowContainer>
                )}
              </TableContainer>
            </TableWrapper>
          </FactorTable>
        ))}
    </ComponentContainer>
  );
};

const ComponentContainer = styled.div``;

// Position the title in the top corner of the column it resides in
const ColTitle = styled.div`
  position: absolute;
  top: -12px;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  padding: 5px;
  color: #2a3039;
`;

const NonEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  min-width: 300px;
`;

const DataInfo = styled.div`
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  margin-top: 3px;
`;

const Label = styled.div`
  color: #666d79;
  min-width: 150px;
  text-align: left;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 3px;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextWrapper = styled.div`
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  border-radius: 5px;
  padding: 10px;
  min-height: 80px;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  border-radius: 5px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  border-collapse: collapse;
`;

const DataTableContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const AddContainer = styled.div`
  cursor: pointer;
  color: #2d70e2;
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  position: relative;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #DFDFDF"};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  padding-bottom: 10px;
  padding-top: 10px;
  flex-direction: row;
  &:last-child {
    border-bottom: none;
  }
`;

const RemoveRowContainer = styled.div`
  opacity: ${(props) => (props.isRowFocused ? "1" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -30px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 40px;
`;

const RowHeader = styled.th`
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const TableCol = styled.td`
  font-size: 14px;
  position: relative;
  // margin: 10px;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 10px;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
`;

const AddRowContainer = styled.div`
  opacity: ${(props) => (props.isHovered ? "1" : "0.7")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
`;

const FactorTable = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Factor = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Raleway;
`;

const DataTableTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-family: Raleway;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  font-family: Raleway;
  margin-bottom: 10px;
`;

export default EIAComponent;
