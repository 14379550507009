import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  getColConfig,
  getNSOTableData,
  getTranslatedText,
} from "components/CWFPDF/pdfUtils";
import translations from "components/CWFPDF/translations.json";
import BarGraph from "./BarGraph";
// Actions
import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SET_QUESTIONS,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
} from "constants/actions";
import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  calculate_otp_table_data,
} from "reports/Audit/data_navigation/calculate_data";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const Textbox = (props) => {
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const [textContent, setTextContent] = useState();
  const [calculatedData, setCalculatedData] = useState([]);
  const [reportData, setReportData] = useState();
  const [questionStructure, setQuestionStructure] = useState();
  const [colConfig, setColConfig] = useState();
  const [tableData, setTableData] = useState();
  const [barData, setBarData] = useState();

  // ******** Selectors ********
  const {
    survey_version,
    WhiteLabel,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    get_auth,
    get_survey_questions,
  } = useSelector(
    (state) => ({
      get_organizations: state.organizations,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_survey_structure: state.surveystructure.survey_structure,
      get_auth: state.auth,
      WhiteLabel: state.white_label?.white_label,
      survey_version: state.audit.survey_version,
      get_survey_questions: state.surveyquestions.survey_questions,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (tableData) {
    }
  }, [tableData]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=8&status=2`,
    });
  }, []);

  useEffect(() => {
    if (!props?.savedTemplate) {
      if (get_survey_structure && get_survey_structure[0]) {
        const DefState = build_default_State(get_survey_structure[0]);
        dispatch({
          type: SET_NAVIGATION,
          payload: DefState,
        });
        if (get_culture_audit_reports.response) {
          if (get_culture_audit_reports.response.length > 0) {
            let sorted_responses = get_culture_audit_reports.response.sort(
              (a, b) => b.id - a.id
            );

            if (survey_version) {
              sorted_responses = sorted_responses.filter(
                (f) => f.question.sort_order === survey_version.sort_order
              );
            }

            //   if (checkPersonality(get_organizations)) {
            //     //Modify the results to include personality as a categorical variable
            //     sorted_responses =
            //       convert_personality_to_categories(sorted_responses);
            //   }

            if (props?.primary) {
              sorted_responses = sorted_responses.filter((responseGroup) => {
                return responseGroup?.name_uid === props?.primary;
              });
            }

            let most_recent;
            most_recent = get_most_recent(sorted_responses);

            let total = {};
            most_recent.responses?.map((r) => {
              let n = r.categories.length.toString();
              if (!(n in total)) {
                total[n] = 1;
              } else {
                total[n] += 1;
              }
            });

            most_recent["last"] = get_last_responses(sorted_responses);

            if (sorted_responses.length > 0 && most_recent.responses) {
              let historical_data = sort_historical_data(
                sorted_responses.reverse(),
                0
              );

              setReportData([most_recent, ...historical_data]);

              // setComments(check_comments(most_recent, get_employees));

              dispatch({
                type: SET_RAW_DATA,
                payload: [most_recent, ...historical_data],
              });

              dispatch({
                type: SET_DATA_DATES,
                payload: get_dates(historical_data),
              });
            }
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    survey_version,
    props?.primary,
  ]);

  useEffect(() => {
    if (!props?.data?.planData[3]) {
      if (get_auth?.organization_id) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=5&organization=${get_auth?.organization_id}`,
        });
      }
    }
  }, [get_auth?.organization_id]);

  /** useEffect Hooks
   * 1. Calculate data for table
   * 2. Get column config for table
   * 3. Get table data
   * 4. Get URI
   * 5. Get Spider options
   */
  useEffect(() => {
    if (!props?.savedTemplate) {
      if (reportData && get_survey_structure?.[0] && questionStructure) {
        const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : 2;
        const secondary = get_survey_structure[0]?.categories?.find(
          (category) => category.priority === "secondary"
        );

        setCalculatedData(
          calculate_otp_table_data(
            reportData,
            get_survey_structure[0],
            secondary?.id,
            orgRule,
            questionStructure,
            props?.primary,
            true
          )
        );
      }
    }
  }, [reportData, get_survey_structure[0], questionStructure, props?.primary]);

  // 2. Get column config for table
  useEffect(() => {
    if (get_survey_structure?.[0]) {
      setColConfig(getColConfig(get_survey_structure[0], "en"));
    }
  }, [get_survey_structure[0]]);

  useEffect(() => {
    if (!props?.savedTemplate) {
      if (
        calculatedData &&
        calculatedData?.responses?.length > 0 &&
        get_survey_structure?.[0] &&
        colConfig
      ) {
        const tableDataArr = getNSOTableData(
          calculatedData,
          get_survey_structure[0],
          questionStructure,
          colConfig,
          "en",
          translations
        );
        setTableData(tableDataArr);
      } else {
        setTableData();
      }
    }
  }, [
    calculatedData,
    colConfig,
    get_survey_structure[0],
    questionStructure,
    props?.primary,
  ]);

  useEffect(() => {
    if (survey_version) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues;

        if (survey_version) {
          SurveyQues = {
            ...get_survey_questions.filter(
              (sq) => sq.sort_order === survey_version.sort_order
            )?.[0],
          };
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };
        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (survey_version) {
        SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === survey_version.sort_order
          )?.[0],
        };
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, survey_version]);

  return (
    <TextboxContainer altBg={props.hideBorders || props.altBg}>
      {props.title && <Title>{props.title}</Title>}
      {props.mhTable && barData && (
        <BarGraph
          // TODO: Add french
          title={getTranslatedText(barData.factor, "en", translations)}
          average={barData?.factorAverage?.toFixed(1)}
          categories={get_survey_structure[0]}
          data={barData}
        />
      )}
      {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
      {(props?.editEnabled && (
        <InputWrapper
          altBg={props.hideBorders}
          isFocused={!props.hideBorders && isFocused}
        >
          <Input
            placeholder={props.placeholder}
            value={props.value || props.data?.planData?.[props.id]}
            onChange={(e) => props.onChange(e.target.value, props.id)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            editEnabled={props.editEnabled}
          />
        </InputWrapper>
      )) ||
        (props?.isTable && (
          <UneditableTextContainer>
            <CommentsTitle>Comments</CommentsTitle>
            <TextWrapper large={props?.large}>{props.value}</TextWrapper>
          </UneditableTextContainer>
        )) || (
          <TextWrapper large={props?.large}>
            {props.value || props.data?.planData?.[props.id]}
          </TextWrapper>
        )}
    </TextboxContainer>
  );
};

const Title = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const UneditableTextContainer = styled.div`
  border-radius: 8px;
  border: 1px solid;
  border-color: #e0e0e0;
`;

const CommentsTitle = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  padding: 10px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TextWrapper = styled.div`
  background-color: #f8faff;
  border-radius: 5px;
  min-height: ${(props) => (props.large ? "140px" : "80px")};
  max-height: ${(props) => (props.large ? "250px" : "100px")};
  padding: 10px;
`;

const TextboxContainer = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.altBg ? "0px" : "20px")};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  margin-bottom: ${(props) => (props.hideBorders ? "0px" : "40px")};
  height: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: ${(props) => (props.altBg ? "0px" : "8px")};
  border: ${(props) => (props.hideBorders ? "0px" : "1px solid")};
  border-color: ${(props) =>
    props.isFocused && !props.altBg ? "#2D70E2" : "#e0e0e0"};
  padding: ${(props) => (props.inTable ? "0px" : "16px")};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  width: 100%;
`;

const Input = styled.textarea`
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  font-family: Raleway;
  font-size: 14px;
  width: 100%;
  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }
`;

export default Textbox;
