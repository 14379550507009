import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import { Modal, Dropdown } from "semantic-ui-react";


const Add = () =>{
    const [open, setOpen] = useState(false)

    return (
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size="medium"
        trigger={<Button>Add member</Button>}
        >
            <Content>
                <Title>Add a member to the accountability dashboard</Title>
                <Text>
                    Keep track of your team's progress and access to culture plan building and check in tools.
                </Text>
                <StyledButton>Add</StyledButton>
            </Content>
        </Modal>
    )
}

export default Add;


const Content = styled.div`
  padding: 40px;
  position:relative;
`;


const Title = styled.div`
    font-family:'Raleway';
    font-size:24px;
    font-weight:bold;
    margin-bottom:30px;

`

const Text = styled.div`
    font-family:'Raleway';
    font-size:16px;
    margin-bottom:40px;
    `

const Button = styled.div`
    background-color:#476DFA;
    color:white;
    padding:5px 10px;
    border-radius:5px;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    width:100px;
    font-size:12px;
    font-weight:bold;
`


const StyledButton = styled.div`
    font-family:'Raleway';
    font-size:16px;
    font-weight:bold;
    padding:10px 20px;
    border-radius:5px;
    background-color:#476DFA;
    color:white;
    border:none;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:50px;
    position:absolute;
    bottom:30px;
    right:30px;
`