import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import {Table,Button} from 'semantic-ui-react'

const Start = ({evaluate}) =>{

    return (
        <Container>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Organization
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Primary
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Factor
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Action
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            Test
                        </Table.Cell>
                        <Table.Cell>
                            Resulta
                        </Table.Cell>
                        <Table.Cell>
                            Psychological Safety
                        </Table.Cell>
                        <Table.Cell>
                            <Button onClick={()=>evaluate()}>Evaluate</Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Container>
    )
}

export default Start

const Container = styled.div`
    width:100%;
    padding-left:60px;
    margin-top:40px;
`