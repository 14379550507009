import styled, { keyframes } from "styled-components";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import ClickAwayListener from "react-click-away-listener";

const Container = styled.div`
  color: #4a4a4a;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  margin-left: 24px;
  width: 100%;
`;

const List = styled.div`
  box-shadow: ${(props) =>
    props.visible ? "0 2px 20px 0 rgba(194, 194, 194, 0.5)" : ""};
  border-radius: 8px;
  width: 100%;
  padding: 5px;
  position: absolute;
  top: 40px;
  height: ${(props) => (props.visible ? "auto" : "0")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: height 500ms, opacity 500ms, box-shadow 500ms;
  overflow: hidden;
  background-color: white;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 2;
`;

const ListItem = styled.div`
  color: #4a4a4a;
  font-size: 16px;

  border-bottom: ${(props) => (props.last ? "solid 1px #e9e9e9" : "")};
  // font-weight: ${(props) => (props.selected? 900 : 500)};
  padding: 8px 8px 5px 5px;
  cursor: pointer;
  box-sizing: content-box;

  &:hover {
    background-color: #e9e9e9;
  }
`;

const Check = styled.div`
  position: absolute;
  right: 0px;
  top: ${(props) => props.position};
  left: 87%;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms, top 500ms;
  z-index: 3;
`;

const Expand = styled.div`
  position: absolute;
  right: 40px;
  top: -5px;
  transform: ${(props) => (props.visible ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 300ms;
  color: #6a00ff;
  margin-top: 3px;
  font-size: 30px;
`;
const Cont = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  width: 350px;
  height: 60px;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
const Text1 = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
  margin-bottom: 0px;
`;

const Description = styled.div`
   color: #9b9b9b;
   font-family: 'Red Hat Display', sans-serif;
   font-size:13px;
`
const Title = styled.div`
   font-family: 'Red Hat Display', sans-serif;
   font-size:16px;
   font-weight:bold;
   margin-bottom:5px;
`

const DropDown = (props) => {
  const [visible, setVisible] = React.useState(false);

  const select = (id) => {
    props.onChange(id);
    if (props.name == "role") {
      props.setRole(id);
    }
  };

  const handleClickAway = () => {
    setVisible(false);
  };

  useEffect(() => {
    props.onChange(props.defaultValue);
  }, [props]);




  return (
    <Cont>
      <div style={{ position: "relative", width: "100%" }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Container 
          onClick={() => setVisible(visible ? false : true)}>
            {props.value !== null &&
            props.value != undefined &&
            props.value !== false
              ? props.options.find(({role}) => role === props.value)?.name
              : props.placeholder}
            <Expand visible={visible}>
              <Icon name="angle down" />
            </Expand>
          </Container>
          <List visible={visible} 
          onClick={() => setVisible(!visible)}>
            {props.options.map((item, i) => {
              return (
                <ListItem
                  key={item.role}
                  // selected={item.role == props.value}
                  onClick={() => select(item.role)}
                  last={i == props.options.length - 1 ? false : true}
                >
                  <Title>{item.name}</Title>
                  <Description>
                  {item.description}
                  </Description>
                </ListItem>
              );
            })}
          </List>
        </ClickAwayListener>
      </div>
    </Cont>
  );
};

export default DropDown;
