import { useSelector, shallowEqual, useDispatch } from "react-redux";
import translation from './translations.json'


class Languages {

	constructor(language){
		//Get the language preference for this user here
		this.language = language ? language : 'en'
	}

	fetch_words(word){
		try{
			return translation[word][this.language]
		}
		catch{
			return word
		}
		
	}

	is_french(){
		return this.language === 'fr'
	}

}

export default Languages