import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import IMG from '../assets/evaluation.png'
import Chart from '../components/Chart'
import Footer from '../components/Footer'
import Side from '../components/Side'

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2C6CF5",
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 9,
    lineHeight: 1.7,
    marginBottom: 10,
  },
  T2: {
    fontSize: 18,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 9,
    marginTop: 15,
  },
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#F1F1F7",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 14,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 11,
    marginBottom: 5,
  },
  question: {
    fontSize: 9,
    lineHeight: 1.7,
  },
  S1: {
    fontSize: 8,
    marginRight: 15,
  },
  img: {
    height: 20,
  },
  offset: {
    width: "100%",
  },
  summary: {
    border: "2px solid #2C6BAA",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C6BAA",
    color: "white",
    fontSize: 14,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
  },
});

const TableTemplate = {
	rows:[
			{'title':'Question',width:'60%'},
			{'title':'Score',width:'10%'},
			{'title':'Grade',width:'10%'},
			{'title':'Norm',width:'10%'},
			{'title':'Change',width:'10%'},
		],
	data:[
		['People feel safe sharing ideas,feedback, and opinions, even if they differ from others.','50%','D','--','--'],
		['If I have concerns about maltreatment (bullying, harassment, abuse, discrimination), I know what to do (where to go, who to talk to).','50%','D','--','--'],
		['Maltreatment (bullying, harassment, abuse, discrimination) is tolerated.','50%','D','--','--'],
		['Physical health and safety is taken seriously.','50%','D','--','--'],
		['People in positions of power use that power inappropriately.','50%','D','--','--'],
	]
}



const Breakdown = (props) =>{

	return (
		<Page style={styles.page}>
		<Text style={styles.T2}>{props.data.title} Breakdown</Text>
            <View style={styles.line}/>
            <Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs. 
            Chicken jerky short ribs, pastrami pork fatback ground round spare ribs cow t-bone filet mignon hamburger 
            leberkas shank. Meatball burgdoggen pork ribeye.</Text>

            <Table data={TableTemplate}/>

            <Text style={styles.title}>Question Breakdown</Text>

              <Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs.</Text>

           {props.categories.map((item,i)=>{
           	return (
           		<View wrap={false}>
		            <View style={styles.section}>

		            		<Text style={styles.subTitle}>{i+1}. Safety Question </Text>
		            		<Text style={styles.question}>People feel safe sharing ideas, feedback, and opinions, even if they differ from others.</Text>

		            </View>

		            <View style={styles.offset}>
		            	<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
							information appears on all nuLogic reports and is not specific to this organization's individual results</Text>
					<Chart data={item.options}/>
		            </View>	
	         </View>
           		)
           })}
           <Footer/>
           <Side/>

		</Page>
		)

}


export default Breakdown