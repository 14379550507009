import styled, { keyframes } from "styled-components";
import React from "react";
import { Input,Progress } from 'semantic-ui-react'


const User = (props) =>{


	return (
		<Card selected={props.selected}>
			<UserContainer>
					<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"}/>

					<Name>Michael Bawol</Name>
					<Score>
							6
							<Impact>Comments</Impact>
						</Score>
			</UserContainer>

		</Card>
		)
}


export default User



const Card = styled.div`
	border-radius: 15px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  background-color: #ffffff;
  padding:25px;
  margin-top:10px;
  border:${props=>props.selected?'2px solid #6a00ff':""};
  cursor:pointer;
`

const UserContainer = styled.div`
	display:flex;
	align-items:center;
	position:relative;
`

const Avatar = styled.img`
	width:40px;
	height:40px;
	border-radius:20px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
`

const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  color:#4a4a4a;
  font-weight:bold;
  position:absolute;
  top:0px;
  right:0px;
`

const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`