import React from "react";
import Dropdown from "./Dropdown";
import styled from "styled-components";
import Textbox from "./Textbox";

const MultiDropdown = (props) => {
  const {
    dropdowns,
    handleDropdownChange,
    id,
    selected,
    handleTextChange,
    comments,
    editEnabled,
  } = props;
  return (
    <DropdownContainer>
      {dropdowns?.map((dropdown, index) => (
        <DropdownWrapper key={index}>
          {(editEnabled && (
            <Dropdown
              label={dropdown.title}
              options={dropdown.options}
              selectedOption={selected?.[id]?.[index]?.value}
              inModal={true}
              handleOptionChange={(value) => {
                handleDropdownChange(value, id, index);
              }}
            />
          )) || (
            <UneditableWrapper>
              <DropdownLabel>{dropdown.title}:</DropdownLabel>
              <SelectedOption>
                {selected?.[id]?.[index]?.value || dropdown.options[0]}
              </SelectedOption>
            </UneditableWrapper>
          )}
          <Textbox
            placeholder={dropdown.placeholder}
            value={comments?.[id]?.[index]?.comment}
            onChange={(value) => handleTextChange(value, id, index)}
            editEnabled={editEnabled}
          />
        </DropdownWrapper>
      ))}
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DropdownWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;

const UneditableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const DropdownLabel = styled.div`
  color: #666d79;
  min-width: fit-content;
  text-align: right;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-right: 8px;
  margin-top: 3px;
`;

const SelectedOption = styled.div`
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 3px;
`;


export default MultiDropdown;
