// useAutoHeight.js

import { useEffect, useRef } from "react";

const useAutoHeight = (initialHeight) => {
  const ref = useRef(null);

  const resize = () => {
    ref.current.style.height = `${initialHeight}px`;
    ref.current.style.height = `${ref?.current?.scrollHeight}px`;
  };

  useEffect(() => {
    ref?.current?.addEventListener("input", resize);
    resize();

    return () => {
      ref?.current?.removeEventListener("input", resize);
    };
  }, [initialHeight]);

  return { ref, resize };
};

export default useAutoHeight;
