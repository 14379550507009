import {
  PENDING_EMPLOYEE_PERMISSION,
  GET_EMPLOYEE_PERMISSIONS,
  GET_EMPLOYEE_ORG_PERMISSIONS,
  DELETE_EMPLOYEE_PERMISSION,
  FETCH_EMPLOYEE_PERMISSION,
  EDIT_EMPLOYEE_PERMISSION,
  ADD_EMPLOYEE_PERMISSION,
  CLEAR_EMPLOYEE_PERMISSION,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  employee_permission: [],
  employee_org_permission: [],
  pending: false,
  created: false,
  updated: false,
};

export default function EmployeeCategories(state = initialState, action) {
  switch (action.type) {
    case PENDING_EMPLOYEE_PERMISSION:
      return {
        ...state,
        pending: true,
        created: false,
        updated: false,
      };
    case GET_EMPLOYEE_PERMISSIONS:
      return {
        ...state,
        employee_permission: action.payload,
        pending: false,
        created: false,
        updated: false,
      };
    case GET_EMPLOYEE_ORG_PERMISSIONS:
      return {
        ...state,
        employee_org_permission: action.payload,
        pending: false,
        created: false,
        updated: false,
      };
    case FETCH_EMPLOYEE_PERMISSION:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false,
        created: false,
        updated: false,
      };

    case EDIT_EMPLOYEE_PERMISSION:
      return {
        ...state,
        pending: false,
        created: false,
        updated: action.payload,
        employee_permission: [action.payload],
      };

    case DELETE_EMPLOYEE_PERMISSION:
      return {
        ...state,
        updated: true,
        created: false,
        pending: false,
      };

    case ADD_EMPLOYEE_PERMISSION:
      return {
        ...state,
        pending: false,
        updated: false,
        created: action.payload,
        employee_permission: [...state.employee_permission, action.payload],
      };

    case CLEAR_EMPLOYEE_PERMISSION:
      return {
        ...state,
        employee_permission: [],
        employee_org_permission: [],
        created: false,
        updated: false,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        employee_permission: [],
        employee_org_permission: [],
        created: false,
        updated: false,
        pending: false,
      };

    default:
      return state;
  }
}
