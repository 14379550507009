import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Button, Header, Image, Modal,Icon } from 'semantic-ui-react'
import Add_Candidate from "./Add_candidate";
import History from "./history";


const Recruiting = () => {
    const [open,setOpen] = useState(false)

    return (
        <Container>
            <Top>
            <h1>Job Location 1</h1>
            <Tools>
                <Linked>
                    <Icon name="linkify" onClick={()=>setOpen(!open)}/>
                    <LinkArea open={open}>
                        <Link>
                            https://www.innerlogic.com
                        </Link>
                        <Icon name="copy" style={{marginLeft:10}}/>
                        Copy
                    </LinkArea>
                </Linked>
                <Add_Candidate />
                
            </Tools>
            </Top>

            
            <History />
            
        
        </Container>
    );
    }

export default Recruiting;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-left:58px;
    margin-top:40px;
`;

const Top = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    padding-right:40px;
    align-items:center;
`
const Linked = styled.div`
    font-size:16px;
    margin-right:10px;
    padding:7px;
    background-color:#f2f2f2;
    border-radius:5px;
    cursor:pointer;
    position:relative;
`

const Tools = styled.div`
    display:flex;
    align-items:center;

`

const LinkArea = styled.div`
    position:absolute;
    bottom:-38px;
    right:0px;
    background-color:#f2f2f2;
    padding:10px;
    align-items:center;
    display:${props => props.open ? "flex" : "none"};

`

const Link = styled.div`
    background-color:white;
    padding:3px;
`