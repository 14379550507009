import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

const OverviewOptions = (props) => {
  const {
    btnAction,
    showControl,
    setShowControl,
    btnText,
    setPdfGenerating,
    btnEnabled,
    downloadIcon,
    otpReport,
    isFrench,
    triggeredGenerate,
    pdfKey,
  } = props;

  const handleBtnPress = () => {
    setPdfGenerating(true);
    btnAction();
    setShowControl(false);
  };

  useEffect(() => {
    if (triggeredGenerate) {
      setPdfGenerating(true);
      if (btnEnabled) {
        btnAction();
      }
      setShowControl(false);
    }
  }, [triggeredGenerate, btnEnabled, pdfKey]);

  const generateBtn = downloadIcon ? (
    !btnEnabled || triggeredGenerate ? (
      <Loader active inline size="tiny" style={{ marginRight: 10 }} />
    ) : (
      <Button
        disabled={!btnEnabled}
        onClick={() => handleBtnPress()}
        size="tiny"
        icon="download"
      />
    )
  ) : (
    <Button
      style={{
        minHeight: "40px",
      }}
      disabled={!btnEnabled}
      onClick={() => handleBtnPress()}
    >
      {btnText}
    </Button>
  );

  const btnSection = !showControl ? null : generateBtn;

  if (!showControl) {
    return (
      <OptionsContainer>
        <ButtonContainer></ButtonContainer>
      </OptionsContainer>
    );
  }

  return (
    <OptionsContainer>
      <ButtonContainer otpReport={otpReport} downloadIcon={downloadIcon}>
        {btnSection}
      </ButtonContainer>
      {!btnEnabled && !downloadIcon && (
        <ErrorLabel otpReport={otpReport}>
          Not enough data for the selected options or range.
        </ErrorLabel>
      )}
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: ${(props) => (props.downloadIcon ? "35px" : "210px")};
  height: 24px;
  margin-left: ${(props) => (props.otpReport ? "-8px" : "0px")};
  padding-left: ${(props) => (props.downloadIcon ? "5px" : "8px")};
`;

const ErrorLabel = styled.label`
  color: red;
  margin-left: ${(props) => (props.otpReport ? "-8px" : "8px")};
  font-size: 10px;
`;

OverviewOptions.propTypes = {
  selectOptions: PropTypes.array,
  pdfLink: PropTypes.element,
  btnAction: PropTypes.func,
  pdfLoading: PropTypes.bool,
  showControl: PropTypes.bool,
  btnText: PropTypes.string,
};

export default OverviewOptions;
