import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Icon } from 'semantic-ui-react';
import { theme } from './theme';
import { Card, Heading, Paragraph, Button, Table, Spacer, ModalOverlay, ModalContent } from './styles/Common';
import { SG_GET_EMPLOYEE_FILE_UPLOAD, SG_ADD_EMPLOYEE_FILE_UPLOAD, SG_FETCH_EMPLOYEE_FILE_UPLOAD, SG_DELETE_EMPLOYEE_FILE_UPLOAD, CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD } from 'constants/actions';
import { useLocation } from 'react-router-dom';
import { BasicErrorMessage } from "utilities/ErrorMessages";

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing(4)};
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusBadge = styled.span`
  background: ${({status, theme}) => {
    if (status === 'Completed') return theme.colors.statusCompleted;
    if (status === 'Processing') return theme.colors.statusProcessing;
    if (status === 'Failed') return theme.colors.statusFailed;
    return theme.colors.border;
  }};
  color: ${({status}) => (status === 'Completed' ? '#088B00' : status === 'Processing' ? '#B45309' : '#9B2C2C')};
  padding: 4px 8px;
  border-radius: 4px;
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: 600;
`;

const ActionsCell = styled.td`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing(4)};
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FadeInDiv = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const FileManagement = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const demoMode = queryParams.get('demo') === 'true';

  const dispatch = useDispatch();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [pending, setPending] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { get_employee_fileupload, get_auth } = useSelector(
    (state) => ({
      get_employee_fileupload: state.employee_fileupload,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    setPending(get_employee_fileupload?.pending);
    if (!demoMode) {
      setFiles(get_employee_fileupload?.employee_fileuploads?.results);
      const totalResults = get_employee_fileupload?.employee_fileuploads?.count;
      const totalPerPage = get_employee_fileupload?.employee_fileuploads?.page_size;
      setTotalPages(Math.ceil(totalResults / totalPerPage) || 1);
    }
  }, [get_employee_fileupload, demoMode]);

  useEffect(() => {
    if (demoMode) {
      // Demo mode mock data
      const mockFiles = [
        { id: 1, name: 'report_q4.pdf', status_text: 'completed', created_at: 'Dec 1, 2024' },
        { id: 2, name: 'data_export.csv', status_text: 'completed', created_at: 'Nov 28, 2024' },
        { id: 3, name: 'team_list.xlsx', status_text: 'processing', created_at: 'Nov 30, 2024' }
      ];
      setFiles(mockFiles);
    } else {
      // Fetch files from the API
      dispatch({ type: SG_GET_EMPLOYEE_FILE_UPLOAD, payload: `page=${currentPage}` });
    }
  }, [demoMode, dispatch, currentPage]);

  useEffect(() => {
    if (get_employee_fileupload.updated) {
      // Fetch files from the API
      setTimeout(() => {
        handleCloseModal();
      }, 1000);
      dispatch({ type: SG_GET_EMPLOYEE_FILE_UPLOAD, payload: { page: currentPage } });
    }
  }, [get_employee_fileupload.updated, dispatch, currentPage]);

  useEffect(() => {
    if (get_employee_fileupload.selectedFile) {
      setSelectedFile(get_employee_fileupload.selectedFile);
      setShowDetailModal(true);
    }
  }, [get_employee_fileupload.selectedFile]);

  const handleOpenModal = () => setShowUploadModal(true);
  const handleCloseModal = () => {
    setShowUploadModal(false)
    setUploading(false);
  };
  const handleCloseDetailModal = () => {
    setShowDetailModal(false);
    setSelectedFile(null);
    dispatch({ type: CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD });
  };

  const handleFileUpload = (selectedFiles) => {
    // Simulate upload
    setUploading(true);

    const mimeToFileType = {
      'application/pdf': 'pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/msword': 'doc'
    };

    // Dispatch action to add files
    const formData = new FormData();
    formData.append('file', selectedFiles[0]);
    formData.append('employee', get_auth?.employee_id);
    formData.append('name', selectedFiles[0].name);
    formData.append('file_type', mimeToFileType[selectedFiles[0].type] || 'unknown');
    formData.append('file_size', selectedFiles[0].size);

    dispatch({ type: SG_ADD_EMPLOYEE_FILE_UPLOAD, payload: formData });
  };

  const handleRemoveFile = (id) => {
    setFiles((prev) => prev.filter((f) => f.id !== id));
    // Dispatch action to delete file
    dispatch({ type: SG_DELETE_EMPLOYEE_FILE_UPLOAD, payload: { id } });
  };

  const viewMoreDetails = (id) => {
    // Dispatch action to fetch detailed data
    dispatch({ type: SG_FETCH_EMPLOYEE_FILE_UPLOAD, payload: { id } });
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return `${date.toLocaleDateString(undefined, options)} ${date.toLocaleTimeString(undefined, timeOptions)}`;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <TopBar>
          <Heading>File Management</Heading>
          <Button primary onClick={handleOpenModal}>Upload File</Button>
        </TopBar>
        <BasicErrorMessage />
        <Card>
          {pending ? (
            <Paragraph>Loading...</Paragraph>
          ) : (
            <FadeInDiv>
              <Table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Status</th>
                    <th>Uploaded On</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {files && files.map(file => (
                    <tr key={file?.id}>
                      <td>{file?.name}</td>
                      <td><StatusBadge status={file?.status_text}>{file?.status_text}</StatusBadge></td>
                      <td>{formatDateTime(file?.created_at)}</td>
                      <ActionsCell>
                        <Button 
                          style={{background:'#FFF', borderColor:'#DC3545', color:'#DC3545'}} 
                          onClick={() => handleRemoveFile(file.id)}
                        >
                          <Icon name='trash' />
                        </Button>
                        <Button style={{background:'#FFF', borderColor:'#666', color:'#666'}}
                          onClick={() => viewMoreDetails(file.id)}
                        >
                          <Icon name='eye' />
                        </Button>
                      </ActionsCell>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </FadeInDiv>
          )}
        </Card>

        <Pagination>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button key={index + 1} onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </Button>
          ))}
        </Pagination>

        {showUploadModal && (
          <ModalOverlay>
            <ModalContent>
              <Heading>Upload Files</Heading>
              <Paragraph>Select one or more files to upload. They will be processed automatically.</Paragraph>
              <Spacer height="20px" />
              {!uploading ? (
                <input type="file" multiple onChange={(e) => handleFileUpload(e.target.files)} />
              ) : (
                <Paragraph>Uploading and processing...</Paragraph>
              )}
              <Spacer height="20px" />
              <Button onClick={handleCloseModal} style={{marginRight:'10px'}}>Cancel</Button>
            </ModalContent>
          </ModalOverlay>
        )}

        {showDetailModal && selectedFile && (
          <ModalOverlay>
            <ModalContent>
              <Heading>File Details</Heading>
              {pending ? (
                <Paragraph>Loading...</Paragraph>
              ) : (
                <FadeInDiv>
                  <Paragraph><strong>Name:</strong> {selectedFile.name}</Paragraph>
                  <Paragraph><strong>Status:</strong> {selectedFile.status_text}</Paragraph>
                  <Paragraph><strong>File Type:</strong> {selectedFile.file_type}</Paragraph>
                  <Paragraph><strong>File Size:</strong> {selectedFile.file_size} bytes</Paragraph>
                  <Paragraph><strong>Title:</strong> {selectedFile.title}</Paragraph>
                  <Paragraph><strong>Description:</strong> {selectedFile.description}</Paragraph>
                  <Paragraph><strong>Created At:</strong> {formatDateTime(selectedFile.created_at)}</Paragraph>
                  <Paragraph><strong>Updated At:</strong> {formatDateTime(selectedFile.updated_at)}</Paragraph>
                  <Paragraph><strong>File URL:</strong> <a href={selectedFile.file} target="_blank" rel="noopener noreferrer">Download</a></Paragraph>
                  <Spacer height="20px" />
                  <Button onClick={handleCloseDetailModal} style={{marginRight:'10px'}}>Close</Button>
                </FadeInDiv>
              )}
            </ModalContent>
          </ModalOverlay>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default FileManagement;
