import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";




const CustomNav = ({options,selected,setSelected,language_hook}) =>{

	return (
		<Container>
			{options.map((item,i)=>{
				return <ButtonComponent selected={selected==i+1} onClick={()=>setSelected(i+1)}>{language_hook(item)}</ButtonComponent>
			})}
		</Container>
		)
}

export default CustomNav



const Container = styled.div`
	display:flex;
`

const ButtonComponent = styled.div`
  background-color: ${props=>props.selected?'#476DFA':'#515151'};
  border-radius: 20px;
  transition: background 0.1s ease;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 22px;
  font-size:10px;
  color:white;
  padding:0px 10px;
   font-family: "Inter", sans-serif;
   margin-left:10px;



  &:hover {
    background-color: #b5b5b5;
    color: rgba(0, 0, 0, 0.87);
  }
`;