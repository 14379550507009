import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BasicInput from "components/BasicInput";
import { addIcon, trashIcon } from "components/Icons";

const ReflectionTable = (props) => {
  const {
    title,
    subtitle,
    headers,
    rows,
    dynamic,
    data,
    onChange,
    id,
    editEnabled,
  } = props;
  const [inputText, setInputText] = useState([]);
  const [addSectionHovered, setAddSectionHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState();
  const [dynamicRows, setDynamicRows] = useState([[]]);

  useEffect(() => {
    if (editEnabled) {
      onChange(dynamicRows, id);
    }
  }, [dynamicRows]);

  useEffect(() => {
    if (data) {
      setDynamicRows(data?.planData[id]);
    }
  }, [data]);

  const addRow = () => {
    setDynamicRows([...dynamicRows, []]);
  };

  const removeRow = (i) => {
    const _rows = [...dynamicRows];
    _rows.splice(i, 1);
    setDynamicRows(_rows);
  };

  const handleInputChange = (d, i, idx) => {
    // set the inputText at the particular index of the array
    //   if the index doesn't exist, create it
    const _inputText = [...dynamicRows];
    if (!_inputText[i]) {
      _inputText[i] = [];
    }
    _inputText[i][idx] = d;

    setDynamicRows(_inputText);
  };

  const getRows = () => {
    if (dynamic) {
      return dynamicRows;
    } else {
      return rows;
    }
  };
  return (
    <TableWrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <TableContainer>
        <TableRow>
          {headers.map((h) => {
            return <RowHeader>{h}</RowHeader>;
          })}
        </TableRow>
        {getRows()?.map((r, i) => {
          const altBackground = i % 2 === 0;
          const rowIsHovered = hoveredRow === i;
          return (
            <TableRow
              hideBorder={true}
              altBg={altBackground || (rowIsHovered && editEnabled)}
              onMouseOver={() => setHoveredRow(i)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <TableCol>
                {r && !dynamic && <ColTitle>{r}</ColTitle>}
                {(editEnabled && (
                  <BasicInput
                    inputAltBg={altBackground || rowIsHovered}
                    value={dynamicRows?.[i]?.[0] || ""}
                    handleInputChange={(d) =>
                      handleInputChange(d.target.value, i, 0)
                    }
                    placeholder="Enter comment here"
                    hideFocus={true}
                  />
                )) || (
                  <TextWrapper altBg={altBackground}>
                    {dynamicRows?.[i]?.[0] || ""}
                  </TextWrapper>
                )}
              </TableCol>
              <TableCol>
                {(editEnabled && (
                  <BasicInput
                    inputAltBg={altBackground || rowIsHovered}
                    value={dynamicRows?.[i]?.[1] || ""}
                    handleInputChange={(d) =>
                      handleInputChange(d.target.value, i, 1)
                    }
                    placeholder="Enter comment here"
                    hideFocus={true}
                  />
                )) || (
                  <TextWrapper altBg={altBackground}>
                    {dynamicRows?.[i]?.[1] || ""}
                  </TextWrapper>
                )}
              </TableCol>
              {dynamic && editEnabled && (
                <RemoveRowContainer
                  isRowFocused={rowIsHovered}
                  onClick={() => removeRow(i)}
                >
                  {trashIcon()}
                </RemoveRowContainer>
              )}
            </TableRow>
          );
        })}
        {dynamic && editEnabled && (
          <AddRowContainer
            onMouseOver={() => setAddSectionHovered(true)}
            onMouseLeave={() => setAddSectionHovered(false)}
            isHovered={addSectionHovered}
          >
            <AddContainer onClick={addRow}>
              Add Row&nbsp;&nbsp;{addIcon()}
            </AddContainer>
          </AddRowContainer>
        )}
      </TableContainer>
    </TableWrapper>
  );
};

// Position the title in the top corner of the column it resides in
const ColTitle = styled.div`
  position: absolute;
  top: -12px;
  left: 0;
  font-weight: bold;
  font-size: 10px;
  padding: 5px;
  color: #2a3039;
`;

const TableWrapper = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom:20px;
`;

const TextWrapper = styled.div`
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  border-radius: 5px;
  padding: 10px;
  min-height: 80px;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  border-radius: 5px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  border-collapse: collapse;
`;

const AddContainer = styled.div`
  cursor: pointer;
  color: #2d70e2;
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  position: relative;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #DFDFDF"};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  padding-bottom: 10px;
  padding-top: 10px;
  flex-direction: row;
  &:last-child {
    border-bottom: none;
  }
`;

const RemoveRowContainer = styled.div`
  opacity: ${(props) => (props.isRowFocused ? "1" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  top: 25px;
`;

const RowHeader = styled.th`
  font-weight: bold;
  font-size: 14px;
  // margin: 10px;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 10px;
  display: flex;
  align-items: center;
  width: 50%;
`;

const TableCol = styled.td`
  width: 50%;
  font-size: 14px;
  position: relative;
  // margin: 10px;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

const AddRowContainer = styled.div`
  opacity: ${(props) => (props.isHovered ? "1" : "0.7")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px;
`;

export default ReflectionTable;
