import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import ClickAwayListener from "react-click-away-listener";

import Delete from "./Delete";



const Edit = (props) => {
  const {setOpenFullCard, setOpenTeamCard,
     setFullEmpData, teamToolEnabled,
    data,
    auditEnabled,
    setLoading} = props

    // console.log(props)
    // console.log("step 3", props)
// // step 3
  const [visible, setVisible] = React.useState(false);

  const handleClickAway = () => {
    setVisible(false);
  };

  function ViewFullProfile() {
    setOpenFullCard(true)
    setFullEmpData(props.data)
  }
  function ViewTeamOptions() {
    setOpenTeamCard(true);
    setFullEmpData(data);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Oval onClick={() => setVisible(!visible)}>
          <Dots />
          <Dots />
          <Dots />
          <List visible={visible}>
         {/* // step 3 */}
            {auditEnabled &&
            <ListItem onClick={ViewFullProfile}>Full Profile</ListItem>
            }

            {teamToolEnabled && (
              <ListItem onClick={ViewTeamOptions}>Team Options</ListItem>
            )}
           
            <ListItem last={false}>
              <Delete 
              setLoading={setLoading}
              setFullEmpData={setFullEmpData}
              data={props.data} />
            </ListItem>
          </List>
        </Oval>
      </div>
    </ClickAwayListener>
  );
};

export default Edit;

const Oval = styled.div`
  width: 34px;
  height: 34px;

  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #ebebeb;
  }
`;
const Dots = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: #4a4a4a;
  margin-right: 1.5px;
  margin-left: 1.5px;
`;

const List = styled.div`
  box-shadow: ${(props) =>
    props.visible ? "0 2px 20px 0 rgba(194, 194, 194, 0.5)" : ""};
  border-radius: 8px;
  width: 250px;
  padding: 5px;
  position: absolute;
  top: 25px;
  right: -30px;
  height: ${(props) => (props.visible ? "auto" : "0")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: height 500ms, opacity 500ms, box-shadow 500ms;
  overflow: hidden;
  background-color: white;
  z-index: 3;
`;

const Red = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ff2d55;
  font-family: "Red Hat Display", sans-serif;
`;

const ListItem = styled.div`
  color: #4a4a4a;
  font-size: 16px;
  border-bottom: ${(props) => (props.last ? "solid 1px #e9e9e9" : "")};
  padding: 8px 8px 5px 5px;
  cursor: pointer;
  height: 25px;
  box-sizing: content-box;
  font-family: "Red Hat Display", sans-serif;
  color: #545454;

  &:hover {
    background-color: #e9e9e9;
  }
`;
