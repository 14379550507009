import React from "react";
import styled from "styled-components";

import ManageEmpDetail from "./manageEmpDetail";
import EmailDeliveryReport from "components/EmailDeliveryReport";

const MemberCard = (props) => {
  const {userEmployee, data} = props
 
  return (
    <MemberContainer>
      <MemberDetails>
        {props.data.picture ? (
          <Avatar src={props.data.picture} />
        ) : (
          <BlankAvatar />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "calc(100% - 56px)",
            wordWrap: "break-word",
          }}
        >
          {props.data?.first_name
            ? props.data?.first_name + " " + props.data?.last_name
            : ""}
          <Email>{props.data ? props.data.email : ""}</Email>
        </div>
      </MemberDetails>

      <RowContainer mobile={false} width={40}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "calc(100% - 56px)",
            wordWrap: "break-word",
          }}
        >
          <Status status={props.data.status}>
          {props.data?.employee_role_name}
          </Status>
          <Role valid={props.data?.email_status_text == "Validated"}>
            {props.data?.email_status_text}
          </Role>
          {/* <EmailDeliveryReport email={props?.data?.email} /> */}
          {/* employee_id */}
          <EmailDeliveryReport employee_id={props?.data?.id} />
        </div>
      </RowContainer>

      <RowContainer mobile={true} width={30}>
        <ManageEmpDetail 
        userEmployee={userEmployee}
        data={data}
        
         />
      </RowContainer>
    </MemberContainer>
  );
};

export default MemberCard;

const MemberContainer = styled.div`
  width: 100%;
  padding: 26px 0px 26px 0px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;

  @media (max-width: 768px) {
    padding: 10px 0 10px 0;
  }
`;
const Avatar = styled.img`
  height: 46px;
  width: 46px;
  border-radius: 23px;
  border: solid 1px #e9e9e9;
  margin-right: 10px;
  min-width: 46px;
`;

const BlankAvatar = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #4a4a4a;
  margin-right: 10px;
  min-width: 46px;
`;

const Status = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => (props.status < 3 ? "#9b9b9b" : "black")};
`;

const RowContainer = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    width: 20%;
  }
`;
const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const MemberDetails = styled.div`
  width: 30%;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const Email = styled.div`
  font-size: 12px;
`;
const View = styled.div`
  font-size: 10px;
  // font-weight: bold;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
  // margin-top: 12px;
`;
