import React, { useState, useEffect } from 'react'

import Chart from "react-apexcharts";
import ErrorWrapper from "error/component_error"
function Bomb() {
  throw new Error('💥 CABOOM 💥')
}


const options = {

  chart: {
    height: 350,
    type: 'area',
    toolbar: {
      show: false
    }
  },

  dataLabels: {
    enabled: false
  },
  annotations: {
    points:
      [
        {
          x: new Date('2018-09-19T04:30:00.000Z').getTime(),
          y: 9,
          marker: {
            size: 8,
          },
          label: {
            text: '9',
            borderWidth: 0,
            style: {
              fontSize: 20,
              fontWeight: 'bold'
            }
          }
        }
      ]
  },
  stroke: {
    curve: 'smooth',
    colors: ['#08c251']
    // colors:  chartLineColors,
  },
  fill: {
    // colors:  chartBgColors,
    colors: ['#08c251']
  },
  xaxis: {
    type: 'datetime',
    categories: ["2018-07-19", "2018-08-19", "2018-09-19"]
  },
  yaxis:{
    min:0,
    max:100
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy'
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        chart: {
          width: '100%'
        }
      }
    }]
};


export default function ChartLineData({ moodChartData, colors, name, ...props }) {

  // console.log(moodChartData)

  const [chartData, setChartData] = useState([{
    name: 'series1',
    data: [5, 2, 1, 7, 9]
  }]);
  const [chartOptions, setChartOptions] = useState(options);
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    const DataLen = moodChartData?.dateList?.length
    if (DataLen > 0) {

      setChartData(
        [{
          name: `${name} data`,
          data: moodChartData.moodList.map(e=>parseInt(e)).reverse()
        }]
      )


      setChartOptions({
        ...options, xaxis: {
          type: 'date',
          categories: moodChartData?.dateList.reverse()
        },
        annotations: {
            points:
                [
                    {
                        x: moodChartData?.dateList[DataLen - 1],
                        y: moodChartData?.moodList.reverse()[DataLen - 1],
                        marker: {
                            size: 8,
                        },
                        label: {
                            text: `${moodChartData?.moodList[DataLen - 1]}%`,
                            borderWidth: 0,
                            style: {
                                fontSize: 24,
                                fontWeight: 'bold'
                            }
                        }
                    }
                ]
        },
        stroke: {
          curve: 'smooth',
          colors: [colors?.[2]]
        },
        fill: {
          colors: [colors?.[4]]
        },
      })
      // if (colors) {
      //   setChartLineColors([colors?.[2]])
      //   setChartBgColors([colors?.[4]])
      // }
    }
   



  }, [colors, moodChartData]);

  useEffect(() => {

  }, [chartOptions,chartData]);



  return (
    <>
      <ErrorWrapper>
      <Chart
        options={chartOptions}
        series={chartData}
        type="area"
        height={350}
      />
      </ErrorWrapper>
    </>
  )
}
