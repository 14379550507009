import React from 'react';
import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';

// import RemoveFromDepartment from './removeFromDepartment'
// import AddToDepartment from './addToDepartment'

import RemoveTeam from './removeTeam'

import AddTeam from "settings/member_directory/Teams/addTeam";

const Oval = styled.div`
	width: 34px;
  height: 34px;
  background-color: #ebebeb;
  border-radius:17px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:row;
  cursor:pointer;
  position:relative;
`
const Dots = styled.div`
	height:4px;
	width:4px;
	border-radius:2px;
	background-color: #4a4a4a;
	margin-right:1.5px;
	margin-left:1.5px;
`

const List = styled.div`
	box-shadow: ${props=>props.visible? '0 2px 20px 0 rgba(194, 194, 194, 0.5)' :''};
	border-radius:8px;	
	width:250px;
	padding:5px;
	position:absolute;
	top:40px;
	right:0px;
	height:${props=>props.visible? 'auto' :'0'};
	opacity:${props=>props.visible? 1 :0};
	transition:height 500ms,opacity 500ms,box-shadow 500ms;
	overflow: hidden;
	background-color:white;
	z-index:3;
`

const Red = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: #ff2d55;
  font-family: 'Red Hat Display', sans-serif;
`

const BlueName = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: #6a00ff;
  font-family: 'Red Hat Display', sans-serif;
`

const ListItem = styled.div`
	color:#4a4a4a;
	font-size:16px;
	border-bottom: ${props=>props.last? 'solid 1px #e9e9e9' : "" };
	padding:8px 8px 5px 5px;
	cursor:pointer;
	height:25px;
	box-sizing:content-box;
	font-family: 'Red Hat Display', sans-serif;
	color: #545454;
	&:hover{
		background-color:#e9e9e9;
	}

`


const Edit = (props) =>{

	const [visible, setVisible] = React.useState(false)
	const handleClickAway = () => {
        setVisible(false)
    }

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
		<div>
			<Oval onClick={()=>setVisible(!visible)}>
				<Dots /><Dots /><Dots />
				<List visible={visible}>
					<ListItem  
					last={true}>
						<AddTeam teamd={props?.data} 
						 teamUpdate={props.teamUpdate}
						 setTeamUpdate={props.setTeamUpdate}
						trigger={<BlueName>Edit Team</BlueName>} />
						
					</ListItem>
					{/*props?.data?.department ?
						<RemoveFromDepartment 
						trigger={<ListItem  last={true}><Red>Remove from Department</Red></ListItem>}
						data={props.data}
						back={()=>props.back()}/>
					:
						<AddToDepartment
							trigger={<ListItem  last={true}>Add to Department</ListItem>}
							data={props.data}
							selectDept={(d)=>props.selectDept(d)}/>*/}

					<RemoveTeam 
						data={props.data}
						back={()=>props.back()}/>
				</List>
			</Oval>
		</div>
		</ClickAwayListener>
		)
}

export default Edit