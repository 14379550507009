import React from 'react';
import styled from 'styled-components';

// Styled components
const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between; // This spreads out the steps evenly
  position: relative;
  height: ${props=>props.total*40+'px'};// subtract the height of the circles to start and end on the line
`;

const ProgressLine = styled.div`
  width: 2px;
  background-color: #e0e0e0;
  position: absolute;
  left: 7.5px; // align with the top of the first circle
  top:3px;
  height:calc(100% - 6px);
  z-index: 0;
`;

const Step = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const FilledStep = styled(Step)`
  border-color: #007bff;
  background-color: #007bff;
  color: white;
`;

const Checkmark = styled.span`
  font-size: 10px;
`;

const StepLabel = styled.div`
  white-space: nowrap; // prevent text wrapping
  z-index: 2;
  margin-left:10px;
  font-family:'Raleway';
  font-weight:600;
  font-size:14px;
  text-transform:uppercase;
`;

const Option = styled.div`
  font-size: 13px;
  margin-left: 25px;
  margin-top: 5px;
  cursor:pointer;
  color: #666D79;
`;



const App = ({step,setStep}) => {

  return steps.map((s, i) => {
    return (
      <div key={i} style={{marginBottom:15}}>
        <StepLabel>{s.title}</StepLabel>
        {s.options.map((o, j) => {
          return <Option onClick={()=>setStep(j+1)}>{o}</Option>
        })}
      </div>
    );
  }
  );

};

export default App;


const steps = [
    {
      title:'Analytics',
      options:['Benchmarks','Change','Heatmap','Long term trends','Category Analysis']
    },
    {
      title:'Methodology',
      options:['Factor analysis','Item analysis']
    },
    {
      title:'Feedback',
      options:['Conversational analysis','Custom questions']
    }
  ]

