import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";


const Screen7 = () => {
    const [selected, setSelected] = useState(false);

    return (
        <Container>
            <Question>
                Are there any other considerations you'd like included in this action plan?
            </Question>

            <Textarea placeholder="Please enter your response here"></Textarea>

           
        </Container>
    )

}

export default Screen7;

const Container = styled.div`
    width:100%;
    font-family:"Raleway";
`

const Question = styled.div`
    font-size:28px;
    margin-bottom:40px;
    font-weight:600;
    line-height:1.2;
`
const Textarea = styled.textarea`
    width:100%;
    height:200px;
    padding:10px;
    border:1px solid #ccc;
    font-size:16px;
    margin-bottom:20px;

    font-family:"Raleway";
    resize:none;
    box-sizing:border-box;
`