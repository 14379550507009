import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  userIcon,
  scheduleIcon,
  editIcon,
  deletePulseIcon,
} from "../../../reports/components/Icons";
import { Popup } from "semantic-ui-react";
import { Pagination } from "semantic-ui-react";

const statusCodes = [
  { code: 1, label: "Draft", container: "#FEF3F4", text: "#F15062" },
  { code: 2, label: "In Progress", container: "#F3F7FF", text: "#2D70E2" },
  { code: 3, label: "Sent", container: "#F1FAF9", text: "#00B67A" },
];

const Status = ({ id }) => {
  const status = statusCodes?.find((status) => status?.code === id);

  return (
    <StatusContainer color={status?.container}>
      <StatusText color={status?.text}>{status?.label}</StatusText>
    </StatusContainer>
  );
};

const Table = ({ data, onDeleteClick }) => {
  const [activePage, setActivePage] = React.useState(1);
  const [pagedData, setPagedData] = React.useState([]);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = React.useState(0);

  useEffect(() => {
    if (data?.length > 0) {
      const indexOfLastItem = activePage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentPagedData = data.slice(indexOfFirstItem, indexOfLastItem);

      // Reset pagedData to ensure old data is cleared
      setPagedData(currentPagedData);
      setTotalPages(Math.ceil(data.length / itemsPerPage));
    } else {
      setPagedData([]); // Reset pagedData if there's no data
    }
  }, [activePage, data]);

  const statusCodes = [
    { code: 1, label: "Draft", container: "#FEF3F4", text: "#F15062" },
    { code: 2, label: "In Progress", container: "#F3F7FF", text: "#2D70E2" },
    { code: 3, label: "Sent", container: "#F1FAF9", text: "#00B67A" },
  ];

  const mapStatusText = (status) => {
    switch (status) {
      case 1:
        return "Draft";
      case 3:
        return "Sent";
      default:
        return "Unknown";
    }
  };

  const handlePaginationChange = (e, { activePage }) => {
    setPagedData([]); // Clear the previous pagedData before changing the page
    setActivePage(activePage);
  };

  return (
    <Container>
      {pagedData?.length > 0 &&
        pagedData.map((item, i) => (
          <RowItem
            item={item}
            index={i}
            key={item.templateId}
            onDeleteClick={() => onDeleteClick(item.templateId)}
          />
        ))}
      {pagedData?.length === 0 && (
        <Row>
          <TD width={100} centered>
            No surveys found.
          </TD>
        </Row>
      )}
      {totalPages > 1 && (
        <Pagination
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={handlePaginationChange}
        />
      )}
    </Container>
  );
};

export default Table;

const RowItem = ({ item, index, onDeleteClick }) => {
  const navigate = useNavigate();

  const handleRowClick = (item) => {
    if (item.status === 3) {
      // Navigate to the survey details page
      navigate(`/leader/audit/manage/task/${item.surveyId}`);
    }
  };

  const handleEditClick = (item) => {
    if (item.status === 1) {
      // Navigate to the survey details page
      navigate(`/leader/audit/manage/tasks/edit/${item.templateId}`);
    }
  };

  const handleSendClick = (item) => {
    if (item.status === 1) {
      // Navigate to the survey details page
      navigate(`/leader/audit/manage/tasks/schedule/${item.templateId}`);
    }
  };

  const [hovered, setHovered] = useState(null);

  return (
    <Row
      key={item.templateId}
      onClick={() => handleRowClick(item)}
      onMouseOver={() => setHovered(index)}
      onMouseOut={() => setHovered(null)}
      rowIsHovered={hovered === index}
      clickable={item.status === 3}
    >
      <SurveyName width={30}>{item.name}</SurveyName>
      <TD width={20}>
        Updated: {format(new Date(item.updated_date), "yyyy-MM-dd")}
      </TD>
      <TD width={20}>
        <Status id={item.status} />
      </TD>
      <TD width={10}>
        {userIcon()}
        {(Number(item.users_count) > 0 && item.users_count) || "--"}
      </TD>
      <TD width={10}>
        {item.status === 1 ? (
          <Popup
            trigger={
              <ActionWrapper onClick={() => handleEditClick(item)}>
                {editIcon()}
              </ActionWrapper>
            }
            content="Edit"
            position="top center"
            inverted
          />
        ) : item.status === 3 ? (
          <ActionWrapper></ActionWrapper>
        ) : (
          ""
        )}
        {item.status === 1 ? (
          <Popup
            trigger={
              <ActionWrapper onClick={() => handleSendClick(item)}>
                {scheduleIcon()}
              </ActionWrapper>
            }
            content="Send Survey"
            position="top center"
            inverted
          />
        ) : item.status === 3 ? (
          <ActionWrapper></ActionWrapper>
        ) : (
          ""
        )}

        {
          // Only show delete icon if the survey is in draft status
          item.status === 1 && (
            <Popup
              trigger={
                <ActionWrapper onClick={() => onDeleteClick(item.templateId)}>
                  {deletePulseIcon()}
                </ActionWrapper>
              }
              content="Delete"
              position="top center"
              inverted
            />
          )
        }
      </TD>
    </Row>
  );
};

const Container = styled.div`
  width: 100%;
  font-family: Raleway;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  padding-left: 10px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 14px;
  color: #666d79;
`;

const ActionWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
`;

const SurveyName = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
  max-width: 80px;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;
