import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import DepartmentCard from './departmentCard'
import { Link } from "react-router-dom";
import DepartmentTeamCard from './DepartmentTeamCard'

 const Container = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	flex-direction:column;
	height:100%;
  position:absolute;
`

const Button = styled.div`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:0 10px 0 10px;
`

const Department = (props) => {

  const [complete,setComplete] = useState(false)
  const [screen, setScreen] = useState(0)
  const [department,setDepartment] = useState(false)
  
  return (
    <Container>
      {screen==0 ?
        <DepartmentCard  
          back={()=>props.back()} 
          complete={()=>setComplete(true)} 
          change={()=>setScreen(1)}
          department={department}
          setDepartment={(dep)=>setDepartment(dep)}
          /> :""}

       {screen ==1 ?
         <DepartmentTeamCard
           department={department}
         />:""}

    </Container>
  );
};

export default Department