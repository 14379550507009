import styled, { keyframes } from "styled-components";
import React from "react";
import { Checkbox, Form,Dropdown,Input,Modal} from 'semantic-ui-react'
import DateTimePicker from 'react-datetime-picker';

const Schedule = (props) =>{
	const [open, setOpen] = React.useState(false)
	const [type,setType] = React.useState(null)
	const [value, onChange] = React.useState(new Date());

	return (
		<Modal
		      onClose={() => setOpen(false)}
		      onOpen={() => setOpen(true)}
		      open={open}
		      trigger={props.trigger}
		      size="tiny"
		    >
		    <Modal.Header><Title>Schedule a Culture Assessment</Title></Modal.Header>
		 <Modal.Content>
		
			<Form>
				 <Form.Group inline>
		          <label>Type</label>
		          <Form.Radio
		            label='Professional Sport'
		            value='Game'
		            checked={type === 'Game'}
		            onChange={(e,{value})=>setType(value)}
		          />
		          <Form.Radio
		            label='Collegiate Sport'
		            value='Practice'
		            checked={type === 'Practice'}
		            onChange={(e,{value})=>setType(value)}
		          />
		          <Form.Radio
		            label='Amateur Canadian Sport'
		            value='Practice'
		            checked={type === 'Practice'}
		            onChange={(e,{value})=>setType(value)}
		          />
		        </Form.Group>


		        	
		
			    
			 </Form>
			
			 <FormContainer>

			 <Label>Select a date and time</Label>
			 <DateTimePicker
				        onChange={onChange}
				        value={value}
				      />
			</FormContainer>

			 <FormContainer>
			<Label>Select time zone</Label>
				<Dropdown
			    placeholder='Select time zone'
			    fluid
			    selection
			    options={friendOptions}
			  />
			  </FormContainer>


			 <FormContainer>
			 	<Label>Add to the survey</Label>
			 	 <Checkbox label='Race' style={{marginRight:10}}/>
			 	 <Checkbox label='Gender' style={{marginRight:10}}/>
			 	 <Checkbox label='Age' />
			 </FormContainer>
			 
			</Modal.Content>

			 <Modal.Actions>
			 <div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
			  	<Submit>Schedule</Submit>
			  </div>
			  </Modal.Actions>
		</Modal>
		)
}


export default Schedule


const Container = styled.div`
	margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
	padding:30px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:400px;
	display:flex;
	align-items:center;
	flex-direction:column;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	 font-family: 'Barlow', sans-serif;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
	max-width:300px;
	margin-top:20px;
`

const Submit = styled.div`
	border-radius: 25px;
  font-size: 14px;
  font-weight:300;
  background-color: #E3B01F;
  color: black;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:150px;
  cursor:pointer;
 font-family: 'Barlow', sans-serif;
`



const friendOptions = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess',
    image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
  },
  {
    key: 'Elliot Fu',
    text: 'Elliot Fu',
    value: 'Elliot Fu',
    image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
  },
  {
    key: 'Stevie Feliciano',
    text: 'Stevie Feliciano',
    value: 'Stevie Feliciano',
    image: { avatar: true, src: '/images/avatar/small/stevie.jpg' },
  },
  {
    key: 'Christian',
    text: 'Christian',
    value: 'Christian',
    image: { avatar: true, src: '/images/avatar/small/christian.jpg' },
  },
  {
    key: 'Matt',
    text: 'Matt',
    value: 'Matt',
    image: { avatar: true, src: '/images/avatar/small/matt.jpg' },
  },
  {
    key: 'Justen Kitsune',
    text: 'Justen Kitsune',
    value: 'Justen Kitsune',
    image: { avatar: true, src: '/images/avatar/small/justen.jpg' },
  },
]