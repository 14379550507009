import React, { useState } from "react";
import {Icon} from 'semantic-ui-react'
import { MemberContainer, RowContainer, Text ,MobileView,View} from "./styled-list";
import MemberInfo from "./MemberInfo";

const MemberCard = ({ data }) => {

  return data.id > 0 ? (
    <MemberContainer>

      <MemberInfo emp_id={data.employee} />

      <RowContainer width={30} mobile={false}>
        <Text>Completed</Text>
      </RowContainer>
      <RowContainer width={30} mobile={true}>
        <View>View</View>
        <MobileView><Icon name="chevron right"/></MobileView>
      </RowContainer>
    </MemberContainer>
  ) : (
    ""
  );
};

export default MemberCard;
