import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress,Dropdown,Menu,Checkbox } from 'semantic-ui-react'
import ClickAwayListener from "react-click-away-listener";






const MenuItem = ({title,selected,setSelected}) =>{




	return (
		<MenuLine onClick={()=>setSelected()}>
			<Line>
				{title}
				<CheckContainer selected={selected}>
								<Icon name="check" />
							</CheckContainer>

			</Line>
		</MenuLine>
		)
}



const options = ['Culture','Performance','Risk','ROI','Diversity']

const Filter = () =>{

	const [open,setOpen] = useState(false)
	const [selected,setSelected] = useState(0)

	const select = (i) =>{
		setSelected(i)
		setOpen(false)
	}

	return (
		 <ClickAwayListener onClickAway={()=>setOpen(false)}>
			<Filtered onClick={()=>setOpen(!open)}>
				<ButtonComponent>
							<IconContainer>
								<Icon name='add' style={{margin:0,marginTop:-3}}/>
							</IconContainer>
								<TextContainer>
								Filters
							</TextContainer>
						</ButtonComponent>

						<MenuContainer open={open}>
							{options.map((item,i)=>{
								return <MenuItem title={item} selected={selected==i} setSelected={()=>select(i)} />
							})}

						</MenuContainer>
			</Filtered>
		</ClickAwayListener>
		)
}

export default Filter



const MenuLine = styled.div`
	width:100%;
	padding:10px 10px;
	position:relative;

	&:hover{
		background-color: #b5b5b5;
    	color: rgba(0,0,0,.87);
	}
`

const CheckContainer = styled.div`
	display:${props=>props.selected?'flex':'none'};
`

const Line = styled.div`
	display:flex;
	width:100%;
	justify-content:space-between;
	cursor:pointer;
`


const IconContainer = styled.div`
	width:30px;
	display:flex;
	align-items:center;
	justify-content:center;
	background-color:rgba(0,0,0,.05);
	height:100%;
`

const ButtonComponent = styled.div`
	background-color:#e8e8e8;
	border-radius: 0.28571429rem;
	transition:background .1s ease;
	color:rgba(0,0,0,.6);
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	height:35px;



	&:hover{
		background-color: #b5b5b5;
    	color: rgba(0,0,0,.87);
	}
`

const TextContainer = styled.div`
	width:70px;
	display:flex;
	align-items:center;
	justify-content:center;
`

const Filtered = styled.div`
	font-family: 'Barlow', sans-serif;
	
	margin-right:5px;
	font-weight:700;
	font-size:14px;
	position:relative;
	

`

const MenuContainer = styled.div`
	position:absolute;
	top:41px;
	left:-100px;
	width:200px;
	background-color:white;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
	z-index:99;
	display:${props=>props.open?'flex':'none'};
	flex-direction:column;
`