import React from "react";
import { View, Text, Image, StyleSheet,Page,Svg,Polygon } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page:{
    display:'flex',
    flexDirection:'row'
  },
  shapes:{
    height:'100vh',
    position:'relative'
  },
  textarea:{
    paddingHorizontal:15,
    paddingTop:80,
    width:350,
    fontSize:12,
    height:'100%'
  },
  title:{
    fontSize:26,
    fontWeight:700,
    marginBottom:20
  },
  row:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    width:'100%'
  },
  bold:{
    fontWeight:700,
    marginBottom:2,
    marginTop:5
  },
  indent:{
    paddingLeft:20,
     marginBottom:2
  },
  about:{
    fontSize:10,
    color:'grey'
  }
 })



const TOC = () =>{


  return (
    <Page style={styles.page}>
      <View style={styles.shapes}>
      <Svg height="1000" width="220" style={{zIndex:1}}>
        <Polygon
          points="0,100 150,0 220,0 220,350 0,500 0,100"
          fill="#2E34D0"
        />

        <Polygon
          points="0,510 220,360 220,850 0,1000 0,650"
          fill="#2E34D0"
        />
      </Svg>

      <Svg height="200" width="300" style={{position:'absolute',bottom:0}}>
        <Polygon
          points="0,0 300,200 0,200 0,0"
          fill="#000921"
        />
      </Svg>
      </View>

      <View style={styles.textarea}>
        <Text style={styles.title}>Table of Contents</Text>

        <View style={[styles.row,styles.bold]}>
            <Text>Report Methods</Text>
            <Text>1</Text>
        </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Introduction</Text>
            <Text>1</Text>
          </View>

           <View style={[styles.row,styles.indent]}>
            <Text>Methodology</Text>
            <Text>3</Text>
          </View>

          <View style={[styles.row,styles.bold]}>
            <Text>Results & Key Findings</Text>
            <Text>5</Text>
        </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Human Factor Overview</Text>
            <Text>5</Text>
          </View>

           <View style={[styles.row,styles.indent]}>
            <Text>System Factor Overview</Text>
            <Text>8</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Culture Index Map</Text>
            <Text>10</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Key Risks</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Key Strengths</Text>
            <Text>20</Text>
          </View>

           <View style={[styles.row,styles.bold]}>
            <Text>Human Factor Breakdown</Text>
            <Text>5</Text>
          </View>

           <View style={[styles.row,styles.indent]}>
            <Text>Introduction</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Safety</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Well-being</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Cohesion</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Empowerment</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Personalization</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Belonging</Text>
            <Text>20</Text>
          </View>

           <View style={[styles.row,styles.bold]}>
            <Text>System Factor Breakdown</Text>
            <Text>5</Text>
          </View>


           <View style={[styles.row,styles.indent]}>
            <Text>Introduction</Text>
            <Text>20</Text>
          </View>


          <View style={[styles.row,styles.indent]}>
            <Text>Vision</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Values</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Environment</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Accountability</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Adaptability</Text>
            <Text>20</Text>
          </View>

          <View style={[styles.row,styles.indent]}>
            <Text>Communication</Text>
            <Text>20</Text>
          </View>

           <View style={[styles.row,styles.bold]}>
            <Text>Segment Analysis</Text>
            <Text>5</Text>
          </View>

           <View style={[styles.row,styles.bold]}>
            <Text>Feedback</Text>
            <Text>5</Text>
          </View>


          <View style={styles.about}>
            <Text style={{marginBottom:5,marginTop:20}}>ABOUT NULOGIC</Text>

            <Text>Kielbasa ground round sirloin swine, jerky meatloaf shoulder strip steak leberkas 
            doner porchetta. Tongue ham hock pork chop picanha pork loin shank flank leberkas capicola 
            doner tenderloin. Corned beef turducken drumstick, salami tenderloin burgdoggen shankle picanha 
            strip steak landjaeger cow. Frankfurter kielbasa ham chislic drumstick tenderloin cupim jowl 
            chicken hamburger. Short ribs ball tip shank, capicola biltong alcatra meatball kielbasa bresaola 
            ham chicken pastrami beef ribs. Drumstick beef ribs spare ribs jowl t-bone, capicola ground round 
            brisket short loin pork loin turducken meatloaf picanha ham hock. Turkey leberkas porchetta filet 
            mignon, pork loin buffalo pig chuck tail cow meatball.</Text>

          </View>

      </View>
    </Page>
    )
}

export default TOC