import React from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { useRoutes, useLocation } from "react-router-dom";
import { Provider } from "react-redux";

import { Environment, SentryDSN_PROD, SentryDSN_STG } from "./_config";

import "semantic-ui-css/semantic.min.css";
import 'assets/fonts/fonts.css';
import {
  ToastProvider,
  DefaultToastContainer,
} from "react-toast-notifications";

import routes from "routes";

import store from "store";

import ErrorBoundary from "ErrorBoundary";

if (Environment !== "Development") {
  const Sentr_DSN =
    Environment === "Production" ? SentryDSN_PROD : SentryDSN_STG;
  Sentry.init({
    dsn: `${Sentr_DSN}`,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const ToastContainer = (props) => (
  <DefaultToastContainer style={{ marginTop: 70 }} {...props} />
);

const OurFallbackComponent = ({
  error,
  componentStack,
  resetErrorBoundary,
}) => {
  return (
    <div>
      <p>An error occurred: {error.message}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

function App() {
  const routing = useRoutes(routes);
  return (
    <ErrorBoundary FallbackComponent={OurFallbackComponent}>
      <Provider store={store}>
        <ToastProvider autoDismiss={1} components={{ ToastContainer }}>
          <ScrollToTop />
          {routing}
        </ToastProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
