import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import {Table} from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';

const Review = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Center>
                <Title>Plan details</Title>
                
                <T2>
                    Selected Plan Template
                </T2>

                <div style={{marginBottom:20}}>
                    <Block>
                        Culture Factor Plan
                    </Block>
                </div>

                <T2>
                    For users in the following areas
                </T2>

                <div style={{marginBottom:20}}>
                    <Block>
                        Departments
                    </Block>
                </div>

                <T2>
                    For factors that are
                </T2>

                <div style={{marginBottom:40}}>
                    <Block>
                       Lowest Benchmark Scores
                    </Block>
                </div>
               

                <T2>
                    Plan History
                </T2>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Member</Table.HeaderCell>
                            <Table.HeaderCell>Department</Table.HeaderCell>
                            <Table.HeaderCell>Date Generated</Table.HeaderCell>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Michael Bawol</Table.Cell>
                            <Table.Cell>Development</Table.Cell>
                            <Table.Cell>Oct 6th, 2023</Table.Cell>
                            <Table.Cell><Button2>View</Button2></Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>

                

            <ButtonContainer>
                <Button onClick={() => navigate('/simple/prototype/rules')}>Save</Button>
            </ButtonContainer>
            </Center>
        </Container>
    )
}

export default Review

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    min-height:100vh;
`


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Center = styled.div`
    width:800px;
    margin-top:20px;
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:30px;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const Description = styled.div`
    font-size:14px;
    font-weight:400;
    margin-bottom:20px;
`


const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-top:20px;
    font-size:14px;
    font-weight:500;
    transition: all 0.3s ease;
    

    &:hover{
        transform: scale(1.05); 
    }
`

const Button2 = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 5px 10px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    font-size:12px;
    font-weight:500;
    transition: all 0.3s ease;
    

    &:hover{
        transform: scale(1.05); 
    }
`
const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`

const Block = styled.span`
background-color:#EBF1FD;
padding:5px 10px;
border-radius:5px;  
font-weight:600;
color:#2D70E2;
`


const friendOptions = [
    {
      key: 'Jenny Hess',
      text: 'Jenny Hess',
      value: 'Jenny Hess',
    },
    {
      key: 'Elliot Fu',
      text: 'Elliot Fu',
      value: 'Elliot Fu',
    },
    {
      key: 'Stevie Feliciano',
      text: 'Stevie Feliciano',
      value: 'Stevie Feliciano',
    },
    {
      key: 'Christian',
      text: 'Christian',
      value: 'Christian',
    },
    {
      key: 'Matt',
      text: 'Matt',
      value: 'Matt',
    },
    {
      key: 'Justen Kitsune',
      text: 'Justen Kitsune',
      value: 'Justen Kitsune',
    },
  ]
  

  const factorOptions = [
    {
      key: 0,
      text: 'Lowest Factor Average Scores',
      value: 0,
    },
    {
        key: 1,
        text: 'Lowest Factor Benchmark Scores',
        value: 1,
      },
      {
        key: 2,
        text: 'Lowest Organization Average Scores',
        value: 2,
      },
      {
        key: 3,
        text: 'Lowest Organization Benchmark Scores',
        value: 3,
      },
      {
        key: 4,
        text: 'Custom',
        value: 4,
      },
  ]
  