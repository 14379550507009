import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Progress from './Progress'
import Activity from './Activity'
import Insight from './Insight'
import CheckIn from './CheckIn'
import {Icon,Popup} from 'semantic-ui-react'

const Nudge = () =>{
    const [stage,setStage] = useState(0)
    const [completed1,setCompleted1] = useState(false)
    const [completed2,setCompleted2] = useState(false)
    const [completed3,setCompleted3] = useState(false)
    const [completed,setCompleted] = useState(0)

    const countCompleted = () =>{
        let count = 0
        if(completed1){
            count++
        }
        if(completed2){
            count++
        }
        if(completed3){
            count++
        }

        setCompleted(count)

    }

    useEffect(()=>{
        countCompleted()
    },[completed1,completed2,completed3])


    if(stage==1){
        return <Insight onClick={()=>setStage(0)} setCompleted={()=>setCompleted1(true)}/>
    }

    if(stage==2){
        return <CheckIn onClick={()=>setStage(0)} setCompleted={()=>setCompleted2(true)}/>
    }

    return (
        <Container>
            <Center>
               

                <ProgressTitle>Progress</ProgressTitle>
                <Progress completed={completed}/>
                <TopRow>
                    <Title>Habit Hub</Title>
                    <Popup 
                    content='3 others out of 20 in your organization have completed their tasks.' 
                    position='top center'
                    inverted
                    trigger={<Social>3 <Icon name="users" style={{marginLeft:5}}/></Social>} />
                    
                </TopRow>
                
                <Description>Welcome to your habit builder. 
                    Please complete your tasks to build towards your department goals.
                    Leaders who complete their tasks are <strong>3x more likely to achieve their goals.</strong>
                </Description>

                <Activity
                    activity={'Action insight'}
                    icon="bell outline"
                    onClick={()=>setStage(1)}
                    completed={completed1}
                    description={'Based on your action plan, and your check ins, we have generated an insight for you.'}/>

                <Activity
                      activity={'Check in'}
                      icon="calendar check outline"
                      onClick={()=>setStage(2)}
                      completed={completed2}
                      description={'Lets checkin on your milestone progress to date. This quick process helps to keep you on track to meet your goals.'}/>

                <Activity
                      activity={'Copilot question'}
                      icon={"question"}
                      completed={completed3}
                      onClick={()=>setCompleted3(true)}
                      description={'Ask the copilot a question to help you achieve your goals and improve your learning.'}/>
            
            </Center>
           
        </Container>
    )

}

export default Nudge

const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:600px;
    `

const Title = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:15px;
    margin-top:30px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`

const Description = styled.div`
    margin-bottom:30px;
`

const ProgressTitle = styled.div`
    font-size:12px;
    font-weight:600;
    margin-bottom:10px;
`

const TopRow = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
`

const Social = styled.div`
    display:flex;
    color:grey;
    border:1px solid grey;
    border-radius:30px;
    padding:3px 20px;
`