import React, { useState,useEffect } from 'react';
import styled from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_POST_ML_SERVER_V1,
  } from "constants/actions";

  const demo_data =[`| Event                                                                        | Cause                                                                                                                                                                                                                                                                                     |
|------------------------------------------------------------------------------|-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| Unclear Strategic Changes and Lack of Communication                          | Feedback indicates that frequent changes are made without adequate stakeholder consultation, leading to confusion and a sense of being undervalued. This includes issues like unannounced changes in processes and restructuring without explaining the reasons or involving those affected. |
| High Workload and Unrealistic Deadlines                                       | Comments reveal that many employees feel overwhelmed by heavy workloads and tight deadlines. This is exacerbated by a lack of clear guidance or support from management, leading to burnout and diminished job satisfaction.                                                                                                      |
| Perceived Lack of Recognition and Growth Opportunities                       | Numerous responses highlight a sense of stagnation and lack of career development, with promotions and recognition being either delayed or non-existent. This feeling of being underappreciated impacts overall motivation and emotional well-being.                                                                               |
| Positive Work Environment and Supportive Management                          | Employees express that when managers are supportive, transparent, and show genuine concern for their teams' well-being, it results in higher morale and a positive work environment. This includes flexible working conditions and open communication channels.                                                                      |`,
`| Event                                                                            | Cause                                                                                                                                                                                                                                                                                     |
|----------------------------------------------------------------------------------|-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| Lack of Psychological Safety in Team Environments                                | Feedback indicates that employees fear negative consequences for taking risks or suggesting new ideas, leading to a culture of silence and conformity. This lack of psychological safety stifles creativity and innovation.                                                                |
| Misalignment of Organizational Values and Innovation Goals                       | Comments reveal that there is a disconnect between the company's stated values and its innovation goals. Employees feel that the organization does not truly support innovative thinking, resulting in decreased motivation to pursue new ideas.                                           |
| Insufficient Resources and Time for Innovation                                   | Numerous responses highlight that employees are not given adequate time or resources to explore innovative solutions. High workloads and tight deadlines leave little room for experimentation and creative thinking.                                                                      |
| Recognition and Reward Systems that Do Not Value Innovation                      | Employees express frustration that the current recognition and reward systems do not adequately value or incentivize innovation. This leads to a lack of motivation to engage in innovative activities and a focus on short-term, routine tasks instead.                                    |
`,
`| Event                                                                             | Cause                                                                                                                                                                                                                                                                                     |
|-----------------------------------------------------------------------------------|-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------|
| Lack of Accountability Across Teams                                               | Feedback indicates that there is a lack of clear accountability within teams, leading to frustration and disengagement. Employees feel that responsibilities are often unclear, and there is no follow-through on commitments, which negatively impacts their motivation and engagement.      |
| Limited Opportunities for Professional Growth and Learning                        | Comments reveal that employees are not provided with sufficient opportunities for professional growth and learning. This lack of development prospects leads to feelings of stagnation and disengagement, as employees do not see a clear path for career advancement or skill enhancement.    |
| Inconsistent Performance Feedback and Recognition                                 | Numerous responses highlight the inconsistency in performance feedback and recognition. Employees feel that feedback is either infrequent or non-constructive, and recognition for their efforts is rare, leading to a decline in engagement and job satisfaction.                          |
| Positive Impact of Transparent and Fair Accountability Practices                  | Employees express that when accountability practices are transparent and fair, it enhances their sense of responsibility and engagement. Clear expectations and consistent follow-up on commitments help in maintaining high levels of motivation and accountability.                           |
`]

const FeedbackTable = ({feedback,name,importance,selected}) =>{
    const dispatch = useDispatch();
    const get_chat_gpt = useSelector((state) => state.chat_gpt,shallowEqual);
    const [loading,setLoading] = useState(false)
    const [GPTResponse,setGPTResponse] = useState('')

//     useEffect(()=>{
//         if(feedback.length>0){
//           console.log(feedback)
//           const prompt =`We have run an analysis on culture predictors of ${name}, and have determined that 
//           the culture factors of ${importance[0].name}, ${importance[1].name},
//           have predictive power over ${name}.

// We have aggregated the feedback from the survey in each of these areas:
// ${feedback.map(item=>`
// ${item.name} feedback:
// ${item?.data?.map(f=>`Employee ID:${f.employee} - ${f.feedback}\n`)}
// `)}

// ## TASK:
// Using markdown syntax, generate a cause and effect table that shows events and situations that have effected ${name} based on the feedback from all the culture factors.
// The table should give someone the understanding as to exactly what is driving ${name} in the organization based on the feedback above.
// Base the events on documented events in the feedback data. Provide enough detail in the causation column to give a clear understanding of the cause.
// There should only be 2 columns here, event and cause. Each cause doesn't have to only relate to a single factor if feedback is consistent accross other factors.
// You should also never mention the culture factors by name. There should be a maximum of 4 rows in the table.
// Focus on the most impactful events that were mentioned in multiple comments, don't focus on one-off events.
// Only return a table in markdown syntax, and don't begin with the word markdown. 
// `;
//     console.log(prompt)
//             dispatch({
//                 type: SG_POST_ML_SERVER_V1,
//                 payload: {
//                   input_text: '',
//                   prompt: prompt,
//                 },
//               });
//               setGPTResponse('')
//         }

//     },[feedback])

// useEffect(()=>{
//   if(feedback.length>0 && feedback[0]?.data){
//       const prompt =`We have run an analysis on culture predictors of ${name}, and have determined that 
//       the culture factors of ${importance[0].name}, ${importance[1].name},
//       have predictive power over ${name}.

// We have aggregated the feedback from the survey in each of these areas:
// ${feedback.map(item=>`
// ${item.name} feedback:
// ${item?.data?.map(f=>`${f.feedback}`)}
// `)}

// ## TASK:
//   Generate insights about ${name} considering the feedback from the survey and the predictive factors of each of the culture factors.
//   Explain how each culture factor is impacting ${name} based on the feedback data.

//   In addition, provide clear business impacts that have been documented and studied in research. For example,
//   what is the business impact of lower ${name} in relation to the culture factors and the feedback data?
// `;
//       console.log(prompt)
//       dispatch({
//           type: SG_POST_ML_SERVER_V1,
//           payload: {
//             input_text: '',
//             prompt: prompt,
//           },
//         });
//         setGPTResponse('')
//   }

// },[feedback])

    useEffect(()=>{
        if(get_chat_gpt?.chat_gpt_ml?.response){
            let resp = get_chat_gpt?.chat_gpt_ml?.response
          

            setGPTResponse(resp)
        }

        if(get_chat_gpt.pending){
            setLoading(true)
        }
        else{
            setLoading(false)
        }
    },[get_chat_gpt])


    return (
        <Container>
            <Title>Key Events and Causation</Title>
            {/* <Content>
              {GPTResponse}
            </Content> */}
            <MarkdownTable markdown={demo_data[selected]} />
        </Container>
    )
}

export default FeedbackTable


const parseMarkdownTable = (markdown) => {
    const rows = markdown.trim().split('\n')
      .map(row => row.trim().split('|').slice(1, -1).map(cell => cell.trim().replace(/\*\*/g, '')));
  
    return {
      headers: rows[0],
      data: rows.slice(2)
    };
  };
  
  const MarkdownTable = ({ markdown }) => {
    const { headers, data } = parseMarkdownTable(markdown);
  
    return (
      <Table>
        <thead>
          <Tr>
            {headers.map((header, index) => (
              <Th key={index}>{header}</Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <Td key={cellIndex}>{cell}</Td>
              ))}
            </Tr>
          ))}
        </tbody>
      </Table>
    );
    }

const Container = styled.div`

`


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Title = styled.div`
    color: #2d70e2;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    `;

const Content = styled.div`
    white-space: pre-line;
`