import styled ,{keyframes} from "styled-components";
import React from "react";
import {Icon} from 'semantic-ui-react'
import Component1 from './Component1'
import Component2 from './Component2'
import Component3 from './Component3'
import Component4 from './Component4'
import Success from "./Success";
import { useNavigate } from 'react-router-dom';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './transitions.css'; // Import your CSS for transitions



const DataJourney = () =>{

    const [stage,setStage] = React.useState(0)
    const [open,setOpen] = React.useState(false)
    const [question,setQuestion] = React.useState(0)
    const navigate = useNavigate()

    const renderContent = () =>{

        switch(stage){
            case 0:
                return <Component1/>
            case 1:
                return <Component3 question={question} setQuestion={setQuestion}/>
            case 2:
                return <Component4/>
            case 3:
                return <Success/>

            default:
                return <Component1/>
                }
    }

    const get_next = () =>{
       
        if(stage==1 && question<4){
            return setQuestion(question+1)
        }
        if(stage==3){
            return navigate('/app/prototype/tasks')
        }

        return setStage(stage+1)

    }

    const getButtonText = () =>{
        if(stage<2){
            return 'Next'
        }
        if(stage == 2){
            return 'Generate my plan'
        }
        return 'Back to tasks'
    }

    return (
        <Container>
           
        <Center open={open}>
            <TransitionGroup component={null}>
                <CSSTransition
                    key={stage} // Use the stage as a unique key to trigger transitions
                    timeout={1000} // Duration of the transition
                    classNames="fade" // Prefix for the CSS transition classes
                >
                    {renderContent()}
          
            </CSSTransition>
                </TransitionGroup>
            <ButtonContainer>
            <Button onClick={()=>get_next()} key={`button-${question+stage}`} delay={1.3}>
                {getButtonText()} <Icon name="arrow right" style={{marginLeft:10,marginTop:-3}}/>
            </Button>
            </ButtonContainer>
               
        </Center>
        

       
        </Container>
    )

}

export default DataJourney


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    min-height:calc(100vh - 75px);
    padding-bottom:100px;
`

const Center = styled.div`
    width:${props=>props.open?'calc(100% - 300px)':'800px'};
    padding-top:1%;
    padding-left:${props=>props.open?'200px':'0px'};
    transition:all 0.3s ease-in-out;
    
`

const Bottom = styled.div`
    position:fixed;
    bottom:0px;
    left:0px;
    width:100%;
    height:75px;
    background-color:#2254AA;
    z-index:100;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-right:20px;

`

const Button = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px 20px;
    color:#2254AA;
    font-size:16px;
    font-weight:600;
    cursor:pointer;
    border:1px solid #2254AA;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Button2 = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px 20px;
    color:white;
    font-size:16px;
    font-weight:600;
    cursor:pointer;
`
const ButtonContainer = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-end;
    align-items:center;
    margin-top:60px;
    animation:fadeIn 1s ease-out forwards;
`
