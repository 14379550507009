import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { calculateFactorScores } from './calculations';
import styled, { createGlobalStyle } from 'styled-components';
import { Icon } from 'semantic-ui-react';

const BenchmarkDiagnostic = ({ onGenerateReport, onFactorSelected, goBack, data }) => {
  const [sortedDifferenceData, setSortedDifferenceData] = useState(null);

  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  useEffect(() => {
    if (core_data && data) {
      const chartData = calculateFactorScores(data, core_data);

      const differenceSeries = chartData.series.find(
        (seriesItem) => seriesItem.name === 'Difference'
      );

      if (differenceSeries) {
        const differenceData = differenceSeries.data.map((dataPoint) => ({
          category: dataPoint.x,
          value: dataPoint.y != null ? parseFloat((dataPoint.y * 10).toFixed(1)) : null, // Scale to base 100
          factorId: dataPoint.factorId,
          dimensionId: dataPoint.dimensionId,
        }));

        const sortedData = differenceData
          .filter((item) => item.value != null)
          .sort((a, b) => b.value - a.value);

        setSortedDifferenceData(sortedData);
      }
    }
  }, [core_data, data]);

  if (!sortedDifferenceData) {
    return (
      <div className="benchmark-diagnostic">
        <h2>Global Benchmark Comparison Diagnostic</h2>
        <p>Loading data...</p>
      </div>
    );
  }

  const handleCardClick = ({ factorId, dimensionId, name }) => {
    if (onFactorSelected) {
      onFactorSelected({ factorId, dimensionId, name });
    }
  };

  return (
    <Container>
      <BackButton onClick={goBack}><Icon name="chevron left" /> Back</BackButton>
      <Title>Global Benchmark Comparison Diagnostic</Title>
      <Description>
        Based on your culture data and the global benchmarks, please select a culture factor you'd like to create an action plan for.
      </Description>
      <CardContainer>
        {sortedDifferenceData.map((item) => (
          <Card key={item.factorId} onClick={() => handleCardClick({
            factorId: item.factorId,
            dimensionId: item.dimensionId,
            name: item.category
          })}>
            <FactorName>{item.category}</FactorName>
            <FactorValue>{item.value}</FactorValue>
          </Card>
        ))}
      </CardContainer>
    </Container>
  );
};

export default BenchmarkDiagnostic;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Raleway', sans-serif;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

const BackButton = styled.div`
  color: #2D70E2;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin-bottom: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Card = styled.div`
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #2D70E2;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const FactorName = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const FactorValue = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
