import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Table,Button } from 'semantic-ui-react'
import { Link } from "react-router-dom";


const History = () => {

    return (
        <Container>
            <h3>Job History</h3>
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Title</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Created on</Table.HeaderCell>
                    <Table.HeaderCell>Applicants</Table.HeaderCell>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>Job Location 1</Table.Cell>
                    <Table.Cell>Active</Table.Cell>
                    <Table.Cell>March 4th, 2023</Table.Cell>
                    <Table.Cell>8</Table.Cell>
                    <Table.Cell><Link to='/app/prototype/posting'><Button>View</Button></Link></Table.Cell>
                </Table.Row>
                <Table.Row>
                <Table.Cell>Job Location 2</Table.Cell>
                    <Table.Cell>Active</Table.Cell>
                    <Table.Cell>March 4th, 2023</Table.Cell>
                    <Table.Cell>12</Table.Cell>
                    <Table.Cell><Link to='/app/prototype/posting'><Button>View</Button></Link></Table.Cell>
                </Table.Row>
                <Table.Row>
                <Table.Cell>Job Location 3</Table.Cell>
                    <Table.Cell>Closed</Table.Cell>
                    <Table.Cell>March 4th, 2023</Table.Cell>
                    <Table.Cell>20</Table.Cell>
                    <Table.Cell><Link to='/app/prototype/posting'><Button>View</Button></Link></Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>
        </Container>
    )

}

export default History



const Container = styled.div`
    margin-top:20px;
`