import styled from "styled-components";
import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Modal, Dimmer, Loader, Popup } from "semantic-ui-react";
import Empty from "./empty";
import ConfirmButton from "./components/ConfirmButton";

import PlanModal from "./Modal2";

import {

  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_DEBRIEF_SCHEDULES,
} from "constants/actions";


import {
  neutralColor,
  unreadColor,
  labelColor,
  primaryColor,
  inactiveColor,
  filterColor,
  fontSizes,
} from "./styleConfig";

import { ChevronIcon, Checkmark, CloseIcon, FilterReactIcon } from "./Icons";
import { format } from "date-fns";
import CircularProgress from "./components/CircularProgress";
import VerticalProgress from "./components/VerticalProgress";
import Tabs from "./components/Tabs";

import { useLocation } from "react-router-dom";




const calculateImpact = (data, primary, factor) => {
  const recentData = JSON.parse(JSON.stringify(data?.response));
  const mostRecentResponse = recentData.sort((a, b) => {
    return new Date(b?.date) - new Date(a?.date);
  });

  const primaryResponse = mostRecentResponse.filter(
    (response) => response.name === primary
  );

  const factorData = primaryResponse?.[0]?.ai_results?.survey_summary?.find(
    (summary) => summary.factor === factor
  );
  const score =
    factorData?.no_of_feedbacks / primaryResponse?.[0]?.responses?.length;

  return score * 100;
};

const get_title = (text) => {
  let n = text?.toLowerCase().indexOf(`title:`);
  let n2 = text?.toLowerCase().indexOf(`synopsis:`);
  return [text.slice(n + 6, n2), text.slice(n2 + 9)];
};

const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

// using orgCategoryStructure, find the dimension id & the factor id matching the secondaryFactor
const getSecondaryFactorMapping = (orgCategoryStructure, secondaryFactor) => {
  let dimensionId;
  let factorId;

  orgCategoryStructure?.dimensions?.forEach((dimension) => {
    const factor = dimension.factors.forEach((factor) => {
      if (factor.title === secondaryFactor) {
        dimensionId = dimension.id;
        factorId = factor.id;
      }
    });
  });
  return { dimensionId, factorId };
};

// using the secondaryFactorMapping, find the questions that match the dimensionId & factorId
const retrieveFactorResponses = (reportData, factorMapping) => {
  const { dimensionId, factorId } = factorMapping;
  const factorResponses = [];
  reportData?.forEach((responseGroup) => {
    responseGroup?.response?.questions?.forEach((questionResponse) => {
      if (
        questionResponse?.id === dimensionId - 1 &&
        questionResponse?.factor === factorId - 1
      ) {
        factorResponses.push(questionResponse);
      }
    });
  });

  // find the average of the factorResponses.response
  const average =
    factorResponses.reduce((acc, curr) => {
      const value = curr?.reverse ? rev_order[curr.response] : curr.response;
      return acc + value;
    }, 0) / factorResponses.length;

  return average * 10;
};

const retrieveFactorAverage = (
  reportData,
  primaryFactor,
  secondaryFactor,
  orgStructure
) => {
  const secondaryFactorMapping = getSecondaryFactorMapping(
    orgStructure,
    secondaryFactor
  );
  const factorAverage = retrieveFactorResponses(
    reportData,
    secondaryFactorMapping
  );
  return factorAverage;
};


const Recommendations = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const loader = useRef(null);
  const loaderRef = useRef(null);

  const isDEI = location.pathname.includes("edi");
  const auditTypeId = isDEI ? 4 : 3;
  const surveyType = isDEI ? 7 : 6;
  const [recommendationUnfilteredList, setRecommendationfilteredList] =
    useState([]);
  const [recommendationList, setRecommendationList] = useState([]);
  const [filtered, setFiltered] = useState();
  const [filterTemp, setFilterTemp] = useState([]);
  const [secondaryFiltered, setSecondaryFiltered] = useState();
  const [secondaryFilterTemp, setSecondaryFilterTemp] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [cats, setCats] = useState([]);
  const [selected, setSelected] = useState(0);
  const [filters, setFilters] = useState([]);
  const [secondaryFilters, setSecondaryFilters] = useState([]);

  const [cultureAuditReports, setCultureAuditReports] = useState([]);

  const [extraLoading, setExtraLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);

  const [selectedTab, setSelectedTab] = React.useState("All");
  const [surveysList, setSurveysList] = useState();
  const [filteredRec, setFilteredRec] = useState(false);

  // const [activePlan, setActivePlan] = useState(null);
  // const { get_survey_questions } = useGetSurveyData({
  //   surveyType: surveyType,
  //   auditTypeId: auditTypeId,
  // });
  // const [sortOrderList, setSortOrderList] = useState();

  const { get_debrief_schedules, get_survey_questions,
    // get_culture_audit_reports, 
    get_organizations
    // get_survey_structure 
  } = useSelector(
    (state) => ({
      // get_survey_structure: state.surveystructure.survey_structure,
      get_debrief_schedules: state.debrief_schedule,
      get_survey_questions: state.surveyquestions.survey_questions,
      // get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_organizations: state.organizations,
    }),
    shallowEqual
  );

  useEffect(() => {

    dispatch({
      type: SG_GET_CULTURE_AUDIT_REPORTS,
      payload: `audit-type-id=${auditTypeId}`,
    });
    dispatch({
      type: SG_GET_DEBRIEF_SCHEDULES,
      payload: `page_size=1000&type_of=3&organization=${get_organizations?.organization?.id}`,
    });
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=${surveyType}&status=2`,
    });
  }, [dispatch, auditTypeId, get_organizations, surveyType]);

  useEffect(() => {
    setCultureAuditReports(get_debrief_schedules?.culture_audit_reports)
    const timer1 = setTimeout(() => {
      setLoading(get_debrief_schedules?.reportsPending || get_debrief_schedules?.pending);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_debrief_schedules, get_survey_questions]);

  // this is the main fuction
  useEffect(() => {
    let latest_recommendations = [];
    cultureAuditReports?.response?.forEach((report) => {
      if (report?.ai_results?.survey_summary?.length > 0) {
        const _briefQuesId =
          get_debrief_schedules?.debrief_schedule?.results?.[0]?.brief_question;
        const survey = get_survey_questions?.find(
          (survey) => survey.id === _briefQuesId
        );

        if (Array.isArray(report.ai_results?.survey_summary)) {
          // Add this check
          const summaryData = report.ai_results.survey_summary
            .filter((f) => f.version === "v1")
            .map((item) => {
              return {
                ...item,
                date: format(new Date(report.date), "MMM d yyyy"),
                responses: report.responses,
                survey: survey?.questions,
              };
            });

          latest_recommendations.push(...summaryData);
        }
      }
    });

    const checkResponses = (f) => {
      if (
        f.ai_result &&
        f.points &&
        f.title &&
        f.min_summary &&
        f.max_summary
      ) {
        return true;
      }
      return false;
    };

    let formatted_responses = [];
    latest_recommendations.forEach((response) => {
      let ai_summary = response.ai_summary.response;

      let formatted_response = {
        ai_result: ai_summary.find((f) => f.name === "result1")?.content,
        //    ai_justification: response.result_list_1,
        //    obsctacle: response.result_list_2,
        //    solutions: response.result_list_3,
        points: ai_summary.find((f) => f.name === "result3")?.content,
        title: ai_summary.find((f) => f.name === "result6")?.content,
        min_summary: ai_summary.find((f) => f.name === "summary_clusters_min")
          ?.content,
        max_summary: ai_summary.find((f) => f.name === "summary_clusters_max")
          ?.content,
        factor: response.factor,
        dimension: response.dimension,
        primary: response.primary_factor,
        date: response.date,
        responses: response.responses,
        survey: response.survey,
        ai_result_text_pieces: ai_summary,
      };

      formatted_responses.push(formatted_response);
    });

    const _GeneratedRecommendations = formatted_responses.filter((f) =>
      checkResponses(f)
    );

    const _finalFilter = _GeneratedRecommendations.map((item, i) => {
      const isLast =
        i > 1 &&
        i ===
        _GeneratedRecommendations.filter((f) => f.factor === cats[selected]).length - 1;
      const impact = calculateImpact(
        cultureAuditReports,
        item.primary,
        item.factor
      );

        const factorAverage = Math.round(
          retrieveFactorAverage(
            item?.responses,
            item.primary,
            item.factor,
            item?.survey
          )
        );

        return {
          ...item,
          isLast,
          impact,
          factorAverage,
        };
      })
      ?.sort((a, b) => {
        if (a?.factorAverage < b?.factorAverage) {
          return -1;
        }
        if (a?.factorAverage > b?.factorAverage) {
          return 1;
        }
        return 0;
      });
    let filt = [];
    let secondaryFilt = [];

    _finalFilter.map((item) => {
      if (!filt.includes(item.primary)) {
        filt.push(item.primary);
      }
      if (!secondaryFilt.includes(item.factor)) {
        secondaryFilt.push(item.factor);
      }
      return null;
    });

    setFilters(filt);
    setSecondaryFilters(secondaryFilt);

    setRecommendationfilteredList(_finalFilter);


  }, [cultureAuditReports,
    get_debrief_schedules,
    get_survey_questions,
    cats,
    selected,
  ]);

useEffect(() => {
  if (recommendationUnfilteredList) {
   
    let filtered_rec = recommendationUnfilteredList;

      if (!filtered && !secondaryFiltered) {
        setFilteredRec(false);
        // setRecommendationList(filtered_rec?.slice(0, 4 + page));
        // return;
      } else {
        setFilteredRec(true);
      }

      if (filtered && filtered?.length > 0) {
        filtered_rec = filtered_rec.filter((f) => filtered.includes(f.primary));
      }

    if (secondaryFiltered && secondaryFiltered?.length > 0) {
      filtered_rec = filtered_rec.filter((f) =>
        secondaryFiltered.includes(f.factor)
      );
    }
    
    setRecommendationList(filtered_rec?.slice(0, 4 + page));
    // console.log("+++++++++++++++++filtered_rec", filtered_rec)
    // setLoading(false);
  }
},[recommendationUnfilteredList, filtered, secondaryFiltered, page])

  const onFilterSelect = (f) => {
    if (filterTemp.includes(f)) {
      setFilterTemp(filterTemp.filter((item) => item !== f));
    } else {
      setFilterTemp([...filterTemp, f]);
    }
  };
  // pagination old start
  // const handleObserver = useCallback(
  //   (entries) => {
  //     const target = entries[0];

  //     if (
  //       target.isIntersecting &&
  //       recommendationList &&
  //       page < Math.ceil(recommendationList.length / 4) &&
  //       !extraLoading
  //     ) {
  //       setLoadMore(true);
  //     }
  //   },
  //   [recommendationList, extraLoading, page]
  // );
  // useEffect(() => {
  //   const option = {
  //     root: null,
  //     rootMargin: "10px",
  //     threshold: 0,
  //   };
  //   const observer = new IntersectionObserver(handleObserver, option);
  //   if (loader.current) observer.observe(loader.current);
  // }, [handleObserver]);

  // const handleScroll = () => { };

  // pagination old end

  // pagination new start
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0, // 100% of the target element is visible
    };

    const handleObserver = (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        // Trigger the page load function here
        // console.log("page load more data here ")
        loadMoreData();
      }
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    // Clean up the observer when the component is unmounted
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, []);

  const loadMoreData = () => {
    // Implement your logic to load more data here
    setPage((page) => page + 1);
  };
  // pagination new end

console.log("loading", loading)
console.log(recommendationList)

  const onSecondaryFilterSelect = (f) => {
    if (secondaryFilterTemp.includes(f)) {
      setSecondaryFilterTemp(secondaryFilterTemp.filter((item) => item !== f));
    } else {
      setSecondaryFilterTemp([...secondaryFilterTemp, f]);
    }
  };

  const openModal = (data) => {
    setOpen(true);
    setSelectedData(data);
  };

  // if (
  //   get_culture_audit_reports &&
  //   get_culture_audit_reports?.length === 0 &&
  //   !loading &&
  //   !extraLoading &&
  //   hasFetched &&
  //   orgCategoryStructure &&
  //   get_culture_audit_reports &&
  //   surveysList &&
  //   paginatedData?.length === 0 &&
  //   ((filtered && filtered?.length > 0) ||
  //     (secondaryFiltered && secondaryFiltered?.length > 0))
  // ) {
  //   return <Empty />;
  // }

  const disableScroll = open
    ? {
        overflow: "hidden",
      }
    : {};

  return (
    <>
    
    <Dimmer.Dimmable blurring dimmed={loading}>
      <Dimmer active={loading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <Container>
       
        <PlanModal
          open={open}
          setOpen={() => setOpen(false)}
          title={selectedData?.title ? get_title(selectedData.title) : "Plan"}
          data={selectedData}
        />
        <Right>
          <Top>
            <TopHeading>
              <Title>Recommendations</Title>
              <IconWrapper onClick={() => setFilterModalOpen(true)}>
                {<FilterReactIcon filled={filteredRec? true: false} />}
              </IconWrapper>
            </TopHeading>
            <Tabs
              onSelect={(s) => setSelectedTab(s)}
              selected={selectedTab}
              options={["All", "Saved"]}
            />
          </Top>
          {(
            recommendationList.map((recItem, i) => {
              return (
                <RecItem
                  key={i}
                  data={recItem}
                  factor={recItem.factor}
                  factorAverage={recItem?.factorAverage}
                  setOpen={() => openModal(recItem)}
                  primary={recItem.primary}
                  isLast={recItem?.isLast}
                  impact={recItem?.impact}
                />
              );
            })) }
            
          {extraLoading && !open && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 10,
              }}
            >
              <Loader active inline="centered" />
            </div>
          )}
          <div style={{ height: 10 }} ref={loader} />
          <div ref={loaderRef} />
        </Right>
  

   
        </Container>
    </Dimmer.Dimmable>

       <Modal
          dimmer="blurring"
          open={filterModalOpen}
          closeOnDimmerClick
          onClose={() => setFilterModalOpen(false)}
          style={{ width: 350, maxWidth: 450 }}
        >
          <FilterContainer>
            <TopSection>
              <FiltersLabel>Filters</FiltersLabel>
              <CloseButton onClick={() => setFilterModalOpen(false)}>
                {CloseIcon()}
              </CloseButton>
            </TopSection>
            <FilterSectionLabel>Primary</FilterSectionLabel>
            <ButtonSection>
              <CheckContainer
                selected={filterTemp.length === 0}
                onClick={() => {
                  setFilterTemp([]);
                  setPaginatedData([]);
                }}
              >
                <InnerButtonContainer>
                  {filterTemp.length === 0 && (
                    <CheckWrapper>{Checkmark()}</CheckWrapper>
                  )}
                  <FilterButtonText selected={filterTemp.length === 0}>
                    All
                  </FilterButtonText>
                </InnerButtonContainer>
              </CheckContainer>
              {filters.map((item, i) => {
                return (
                  <CheckContainer
                    key={i}
                    selected={filterTemp.includes(item)}
                    onClick={() => onFilterSelect(item)}
                  >
                    <InnerButtonContainer>
                      {filterTemp.includes(item) && (
                        <CheckWrapper>{Checkmark()}</CheckWrapper>
                      )}
                      <FilterButtonText selected={filterTemp.includes(item)}>
                        {item}
                      </FilterButtonText>
                    </InnerButtonContainer>
                  </CheckContainer>
                );
              })}
            </ButtonSection>
            <FilterSectionLabel>Factor</FilterSectionLabel>
            <ButtonSection>
              <CheckContainer
                selected={secondaryFilterTemp.length === 0}
                onClick={() => {
                  setSecondaryFilterTemp([]);
                  setPaginatedData([]);
                }}
              >
                <InnerButtonContainer>
                  {secondaryFilterTemp.length === 0 && (
                    <CheckWrapper>{Checkmark()}</CheckWrapper>
                  )}
                  <FilterButtonText selected={secondaryFilterTemp.length === 0}>
                    All
                  </FilterButtonText>
                </InnerButtonContainer>
              </CheckContainer>
              {secondaryFilters.map((item, i) => {
                return (
                  <CheckContainer
                    selected={secondaryFilterTemp.includes(item)}
                    onClick={() => onSecondaryFilterSelect(item)}
                    key={i}
                  >
                    <InnerButtonContainer>
                      {secondaryFilterTemp.includes(item) && (
                        <CheckWrapper>{Checkmark()}</CheckWrapper>
                      )}
                      <FilterButtonText
                        selected={secondaryFilterTemp.includes(item)}
                      >
                        {item}
                      </FilterButtonText>
                    </InnerButtonContainer>
                  </CheckContainer>
                );
              })}
            </ButtonSection>
            <ConfirmSection>
              <ButtonWrapper>
                <ConfirmButton
                  buttonText="Clear"
                  btnAlt
                  onClick={() => {
                    setFilterModalOpen(false);
                    setFilterTemp([]);
                    setFiltered([]);
                    setSecondaryFiltered([]);
                    setSecondaryFilterTemp([]);
                    setPaginatedData([]);
                  }}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <ConfirmButton
                  buttonText="Apply"
                  onClick={() => {
                    setFiltered(filterTemp);
                    setSecondaryFiltered(secondaryFilterTemp);
                    setFilterModalOpen(false);
                  }}
                />
              </ButtonWrapper>
            </ConfirmSection>
          </FilterContainer>
        </Modal>
        {/* <PlanModal
          open={open}
          setOpen={() => setOpen(false)}
          title={selectedData?.title ? get_title(selectedData.title) : "Plan"}
          data={selectedData}
        />
        <Right modalOpen={open} style={disableScroll}>
          <Top>
            <TopHeading>
              <Title>Recommendations</Title>
              <IconWrapper onClick={() => setFilterModalOpen(true)}>
                {<FilterReactIcon filled={filteredRec ? true : false} />}
              </IconWrapper>
            </TopHeading>
            <Tabs
              onSelect={(s) => setSelectedTab(s)}
              selected={selectedTab}
              options={["All", "Saved"]}
            />
          </Top>
          {recommendationList.map((recItem, i) => {
            return (
              <RecItem
                key={i}
                data={recItem}
                factor={recItem.factor}
                factorAverage={recItem?.factorAverage}
                setOpen={() => openModal(recItem)}
                primary={recItem.primary}
                isLast={recItem?.isLast}
                impact={recItem?.impact}
              />
            );
          })}

          {extraLoading && !open && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: 10,
              }}
            >
              <Loader active inline="centered" />
            </div>
          )}
          <div style={{ height: 10 }} ref={loader} />
          <div ref={loaderRef} />
        </Right> */}
      </>

  );
};

export default Recommendations;

const RecItem = ({
  data,
  factor,
  primary,
  setOpen,
  isLast,
  impact,
  factorAverage,
}) => {
  const text = get_title(data.title);
  return (
    <RiskContainer
    onClick={() => setOpen()}
      style={{ borderBottom: !isLast ? `1px solid #DFDFDF` : "0px" }}
    >
      <InfoSection>
        <RiskTitleContainer>
          <TitleContent>
            <TitleWrapper>
              <ReadReceipt read={true} />
              <RiskTitle>{text[0]}&nbsp;&nbsp;</RiskTitle>
            </TitleWrapper>
          </TitleContent>
        </RiskTitleContainer>

        <Description>{text[1].split(".")[0]}.</Description>
        <LabelSection>
          <PrimaryLabel>{primary}</PrimaryLabel>
          <FactorLabel>{factor}</FactorLabel>
        </LabelSection>
      </InfoSection>
      <RecommendationStats>
        <CircularProgress
          progress={factorAverage}
          factor={factor}
          size={55}
          trackWidth={4}
          indicatorWidth={4}
        />
        <Popup
          position="bottom center"
          inverted
          content=""
          trigger={
            <div style={{ display: "inline-block" }}>
              <VerticalProgress completed={40} label="Research" />
            </div>
          }
        />
        <Popup
          position="bottom center"
          inverted
          content=""
          trigger={
            <div style={{ display: "inline-block" }}>
              <VerticalProgress
                completed={impact}
                label="Impact"
                isImpact={true}
              />
            </div>
          }
        />

        <ChevronWrapper>{ChevronIcon()}</ChevronWrapper>
      </RecommendationStats>
    </RiskContainer>
  );
};

const TitleWrapper = styled.div`
  display: flex;
`;

const Container = styled.div`
  width: 1400px;
  padding-left: 58px;
  margin-top: 30px;
  display: flex;
  height: 90vh;
  font-family: "Raleway";
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;

  width: calc(100vw);

  @media screen and (min-width: 1260px) {
    width: 1400px;
  }
`;

const RecommendationStats = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 65px;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
`;

const Top = styled.div`
  margin-left: -25px;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 45px;
  right: 215px;
  cursor: pointer;
  z-index: 1;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: ${fontSizes.xlarge};
  font-weight: 700;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const FilterSectionLabel = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 16px;
`;

const FiltersLabel = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.large};
`;

const Divider = styled.div`
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 0;
`;

const RiskTitleContainer = styled.div``;

const CheckWrapper = styled.div`
  margin-right: 10px;
`;

const ReadReceipt = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: ${(props) => (props.read ? neutralColor : unreadColor)};
  margin-right: 10px;
  margin-top: 10px;
`;

const RiskContainer = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;
  // font-family: "Barlow", sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-horizontal: 20px;
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 10px;
  background-color: ${(props) => (props.selected ? "lightgrey" : "white")};

  // &:hover {
  // 	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
  // }
`;

const RecNumber = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 10px;
`;

const FilterButtonText = styled.div`
  color: ${(props) =>
    props.selected ? filterColor.activeText : filterColor.text};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0em;
  text-align: left;
`;

const RiskTitle = styled.div`
  font-weight: 600;
  font-size: ${fontSizes.large};
  margin-bottom: 10px;
  line-height: 28.6px;
  font-family: "Raleway";
  font-style: normal;
  margin-right: 10px;
  white-space: normal;
  max-width: 370px;
`;

const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: ${primaryColor};
  cursor: pointer;
  font-weight: 600;
  font-size: ${fontSizes.medium};
  line-height: 24px;
  font-style: normal;
`;

const Description = styled.div`
  margin-bottom: 20px;
  font-size: ${fontSizes.medium};
  line-height: 24px;
  font-weight: 400;
  padding-left: 16px;
  max-width: 400px;
`;

const Left = styled.div`
  width: 265px;
  padding-top: 45px;
  border-right: 1px solid lightgrey;
`;

const CategoryContainer = styled.div`
  // font-family: "Poppins", sans-serif;
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  // background-color:${(props) =>
    props.selected ? "rgba(71, 109, 250,0.1)" : ""};
  color: ${(props) => (props.selected ? primaryColor : inactiveColor)};
  padding: 5px;
  display: flex;
  font-size: ${fontSizes.small};
  justify-content: space-between;
  border-radius: 5px;
  padding-right: 40px;
  padding-bottom: 6px;
`;

const Right = styled.div`
  margin-left: 50px;
  width: calc(100% - 265px);
  padding-top: 40px;
`;

const Tag = styled.span`
  background-color: ${labelColor.background};
  color: ${labelColor.text};
  padding: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.xsmall};
  border-radius: 5px;
  max-width: fit-content;
  white-space: nowrap;
  vertical-align: middle;
`;

const InnerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
`;

const T2 = styled.div`
  font-size: ${fontSizes.xlarge};
  // font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 44px;
`;

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.selected ? filterColor.activeBackground : filterColor.background};
  border-radius: 30px;
  margin-right: 12px;
  margin-bottom: 10px;
  height: 30px;
  cursor: pointer;
`;

const Beta = styled.div`
  color: #2d70e2;
  background-color: rgba(71, 109, 250, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
  margin-left: 20px;
  font-size: ${fontSizes.xsmall};
`;

const LabelSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const PrimaryLabel = styled.div`
  color: #1d9a7d;
  background-color: #eafbf7;
  border-radius: 10px;
  padding: 5px 10px;
  margin-left: 12px;
  font-size: ${fontSizes.xsmall};
`;

const FactorLabel = styled.div`
  color: #2d70e2;
  background-color: rgba(71, 109, 250, 0.1);
  border-radius: 10px;
  padding: 5px 10px;
  margin-left: 20px;
  font-size: ${fontSizes.xsmall};
`;

const ChevronWrapper = styled.div`
  margin-left: 14px;
  margin-bottom: 10px;
`;

const TitleContent = styled.div`
  flex-direction: row;
  display: flex;
`;

const DateContent = styled.div`
  font-weight: 400;
  font-size: ${fontSizes.xsmall};
  color: ${inactiveColor};
`;

const FilterContainer = styled.div`
  padding-bottom: 35px;
  padding-right: 25px;
  padding-top: 35px;
  padding-left: 35px;
`;

const TopSection = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const TopHeading = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

const CloseButton = styled.div`
  font-size: ${fontSizes.large};
  font-weight: 200;
  font-family: "Barlow", sans-serif;
  font-style: normal;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ConfirmSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  bottom: 0;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;
`;