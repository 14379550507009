import styled, { keyframes } from 'styled-components';
import React from 'react';
import emotions from '../../../data/emotions.json'
import { Icon } from 'semantic-ui-react'

const Card = styled.div`
	position:relative;
	width:100%;
	margin-bottom:5px;
	height:450px;
	display:flex;
	align-items:center;
	flex-direction:column;
	margin-top:13px;

	@media (min-width:768px){
		display:none;
	}
`

const MoodScore = styled.div`
	padding:10px;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	margin-top:-10px;
`
const Select = styled.div`
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	position:absolute;
	top:0px;
	margin-left:${props=>props.left};
	height:${props=>props.height+'px'};
	width:30px;
	transition:margin 500ms;
	background-color:white;
`

const Score = styled.div`
	font-size: 30px;
  font-weight: bold;
  color: #4a4a4a;
    font-family: 'Red Hat Display', sans-serif;
    margin-bottom:5px;
`

const Trends = styled.div`
	font-size: 23px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
`

const DateCard = styled.div`
	height:43px;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	font-size: 16px;
  line-height: 1.31;
  text-align: center;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  border-radius: 14.5px;
  border: solid 1px #e9e9e9;
  background-color: #ffffff;
  width:100%;

`
const DateAxis = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	padding:5px 0px 5px 0px;
`

const BottomDate = styled.div`
	  font-size: 16px;
  font-weight: ${props=>props.current? 'bold':'500'};
  line-height: 1.19;
  text-align: center;
  color: ${props=>props.current? 'white':'#9b9b9b'};
  background-color:${props=>props.current? '#6a00ff':'#none'};
  border-radius: 10px;
  padding:10px;
  font-family: 'Red Hat Display', sans-serif;
`
const TopScore = styled.div`
	font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
    font-family: 'Red Hat Display', sans-serif;
`
const Overall = styled.div`
	font-size: 11px;
  font-weight: 900;
    color: #4a4a4a;
    font-family: 'Red Hat Display', sans-serif;
    text-transform:uppercase;
    margin-bottom:-5px;
`

const SubOverall = styled.div`
	font-size: 11px;
  font-weight: bold;
   font-family: 'Red Hat Display', sans-serif;
  color: #6a00ff;
  margin-bottom:10px;
`




function catmullRom2bezier(points) {
    var result = [];
    for (var i = 0; i < points.length - 1; i++) {
        var p = [];

        p.push({
            x: points[Math.max(i - 1, 0)].x,
            y: points[Math.max(i - 1, 0)].y
        });
        p.push({
            x: points[i].x,
            y: points[i].y
        });
        p.push({
            x: points[i + 1].x,
            y: points[i + 1].y
        });
        p.push({
            x: points[Math.min(i + 2, points.length - 1)].x,
            y: points[Math.min(i + 2, points.length - 1)].y
        });

        // Catmull-Rom to Cubic Bezier conversion matrix
        //    0       1       0       0
        //  -1/6      1      1/6      0
        //    0      1/6      1     -1/6
        //    0       0       1       0

        var bp = [];
        bp.push({
            x: ((-p[0].x + 6 * p[1].x + p[2].x) / 6),
            y: ((-p[0].y + 6 * p[1].y + p[2].y) / 6)
        });
        bp.push({
            x: ((p[1].x + 6 * p[2].x - p[3].x) / 6),
            y: ((p[1].y + 6 * p[2].y - p[3].y) / 6)
        });
        bp.push({
            x: p[2].x,
            y: p[2].y
        });
        result.push(bp);
    }

    return result;
}

function makePath(points) {
    var result = "M" + points[0].x + "," + points[0].y + " ";
    var catmull = catmullRom2bezier(points);
    for (var i = 0; i < catmull.length; i++) {
        result += "C" + catmull[i][0].x + "," + catmull[i][0].y + " " + catmull[i][1].x + "," + catmull[i][1].y + " " + catmull[i][2].x + "," + catmull[i][2].y + " ";
    }

    return result;
}

const height = 230
const months =['Jan', 'Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']


function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const MobileChart =(props) =>{

	const calculateCoordinates = () =>{
		return props.data.map((item,i)=>{
			if(props.selected==0){
				return item.culture
			}
			else if(props.selected==1){
				return item.mood
			}
			else if(props.selected==2){
				return item.leadership
			}
		}).slice(0,5)
	}

	const calculateDates = () =>{
		return emotions.map((item,i)=>[months[new Date(item.date).getMonth()],new Date(item.date).getDate()]).slice(0,5)
	}

	const [position, setPosition] = React.useState(props.data.slice(0,5).length-1)
	const [width, setWidth] = React.useState(1000)
	const [coordinates,setCoordinates] = React.useState(calculateCoordinates())
	const [dates,setDate] = React.useState(calculateDates())


	const pathBuilder2 = (graph) =>{
    var points = [];
    for (var i = 0; i < graph.length; i++) {
    	if(graph[i]){
        points.push({x: i*24.5+2, y: (100-graph[i])/100*height});
    	}
    }
    return points
	}

	const shapeBuilder2 = (graph) =>{
	if(props.selected==2){
		return 
	}	
    var points = [];

    for (var i = 0; i < graph.length; i++) {
    	if(graph[i]){
        points.push({x: i*24.5+2, y: (100-graph[i])/100*height});
    	}
    }

    let path = makePath(points)
    path = path + "V "+(height) +"H 2"



    return path
	}

	const load = (dir)=>{
		if(dir<0){
			setPosition(position==0? position: position-1)
			props.setPosition(position==0? position: position-1)
		}else{
			setPosition(position==coordinates.length-1? position: position+1)
			props.setPosition(position==coordinates.length-1? position: position+1)
		}
	}

	const move = (i) =>{
		setPosition(i)
	}

	React.useEffect(()=>{
		setCoordinates(calculateCoordinates())
		setPosition(props.data.slice(0,5).length-1)
	},[props.selected])

		return(
		<div style={{width:'100%'}}>

	      <Card>
				<div style={{height:120,position:'relative',width:'100%'}}>
					<MoodScore>
						<TopScore>{coordinates[position]}%</TopScore>
						<Overall>{props.categories[props.selected]} Score</Overall>
						<SubOverall>Moderate</SubOverall>
						<DateCard>This team had a<strong>&nbsp; 19% increase&nbsp; </strong> in {props.categories[props.selected]}</DateCard>
					</MoodScore>
				</div>
				
			<div style={{height:height,position:'relative',width:'100%'}} >

			 <svg width={'100%'} height={height} style={{position:"absolute",left:0,top:0}}>

	      	 <line x1="2%" y1={height} x2={'100%'} y2={height} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	      	<line x1="2%" y1={height-1*height/5} x2={'100%'} y2={height-1*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="2%" y1={height-2*height/5} x2={'100%'} y2={height-2*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="2%" y1={height-3*height/5} x2={'100%'} y2={height-3*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="2%" y1={height-4*height/5} x2={'100%'} y2={height-4*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       	<line x1="2%" y1={height-5*height/5} x2={'100%'} y2={height-5*height/5} stroke="lightgrey" strokeDasharray="2,4" strokeWidth="2"/>
	       </svg>

	       <svg width={'100%'} height={height} viewBox={"0 0 100 "+height} preserveAspectRatio="none" style={{position:"absolute",left:0,top:0}}>
	      		 <defs>
			    <linearGradient id="myGradient" gradientTransform="rotate(90)">
			      <stop offset="5%"  stopColor="#6a00ff" />
			      <stop offset="95%" stopColor="rgba(106, 0, 255,0.3)" />
			    </linearGradient>
			  </defs>
	     <path d={makePath(pathBuilder2(coordinates))} stroke="#6a00ff" fill="transparent" strokeWidth="4" vectorEffect="non-scaling-stroke" style={{transition:'0.4s'}}/>
	      		<path d={shapeBuilder2(coordinates)}  fill="rgba(106, 0, 255,0.05)"/>
	      </svg>

	        <svg width={'100%'} height={height} style={{position:"absolute",left:0,top:0}}>

	       	{coordinates.map((item,i)=>{
	       		if(!item){
	       				return 
	       			}
	       		if(i!=position){
	       			return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*24.5+2+'%'} cy={(100-item)+'%'} r="4" fill="rgb(186, 186, 186)" style={{transition:'.6s ease'}}/>
	       			<circle cx={i*24.5+2+'%'} cy={(100-item)+'%'} r="8" fill="rgba(186, 186, 186,0.3)" style={{transition:'.6s ease'}} />
	       			<circle cx={i*24.5+2+'%'} cy={(100-item)+'%'} r="12" fill="rgba(186, 186, 186,0.1)" onClick={()=>move(i)} style={{transition:'.6s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       		}
	       		return (
	       			<React.Fragment key={i}>
	       			<circle cx={i*24.5+2+'%'} cy={(100-item)+'%'} r="4" fill="#6a00ff" style={{transition:'.6s ease'}}/>
	       			<circle cx={i*24.5+2+'%'} cy={(100-item)+'%'} r="8" fill="rgba(106, 0, 255,0.3)" style={{transition:'.6s ease'}}/>
	       			<circle cx={i*24.5+2+'%'} cy={(100-item)+'%'} r="12" fill="rgba(106, 0, 255,0.1)" onClick={()=>move(i)} style={{transition:'.6s ease',cursor:'pointer'}}/>
	       			</React.Fragment>
	       			)
	       	})}


	      	 </svg>
	      	 </div>
	      	 <DateAxis>
	      	 	{dates.map((item,i)=>{
	      	 		return <BottomDate current={i==position} onClick={()=>move(i)}>{dates[i][1]}<br/> {dates[i][0]}</BottomDate>
	      	 	})}
	      	 </DateAxis>
	      </Card>
	    
	      </div>

			)

}

export default MobileChart