import React from 'react';
import styled from 'styled-components';


const Circle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #3498db;
  position: relative;
  opacity: 0.5;
  position:relative;
`;

const PieSlice = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(50% 50%, 100% 50%, 100% 0, 50% 0);
  transform-origin: 50% 50%;
  transform: rotate(135deg);
`;


const InnerCircle = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 50%;
  background-color:white;
  position: absolute;
  top: 10%;
  left: 10%;
`

const Border = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #000;
  border-radius: 50%;
  clip-path: polygon(50% 50%, 100% 50%, 100% 0, 0 0, 0 100%);
  transform-origin: 50% 50%;
  transform: rotate(calc(135deg - 40% * 360deg));
`;


function hexToRGBA(hex, alpha = 1) {
  // Ensure the hex color code starts with a '#'
  if (hex.charAt(0) !== "#") {
    throw new Error("Invalid hex color code format");
  }

  // Remove the '#' from the hex color code
  const cleanHex = hex.slice(1);

  // Check if the hex color code has a valid length (either 3 or 6 characters)
  if (cleanHex.length !== 3 && cleanHex.length !== 6) {
    throw new Error("Invalid hex color code length");
  }

  // Expand the 3-character hex color code to a 6-character one if necessary
  const finalHex = cleanHex.length === 3
    ? cleanHex.split("").map(char => char + char).join("")
    : cleanHex;

  // Extract the red, green, and blue values from the hex color code
  const r = parseInt(finalHex.slice(0, 2), 16);
  const g = parseInt(finalHex.slice(2, 4), 16);
  const b = parseInt(finalHex.slice(4, 6), 16);

  // Return the RGBA color value as a string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


const Dial = ({ strokeWidth=3, borderPercentage=25,borderColor='#2D70E2' }) => {
  const radius = 50;
  const innerRadius = radius * 0.8;
  const borderCircleRadius = radius + strokeWidth;
  const circleCircumference = 2 * Math.PI * (borderCircleRadius);

  const borderLength = ((borderPercentage / 100) * 270) * ((circleCircumference-20) / 360);

  const getCirclePosition = (angle, radius) => {
    const x = 50 + radius * Math.cos((angle - 90) * (Math.PI / 180));
    const y = 50 + radius * Math.sin((angle - 90) * (Math.PI / 180));
    return { x, y };
  };
  const circleAngle = 225 + (((borderPercentage+2) / 100) * 266);
  const circlePosition = getCirclePosition(circleAngle, borderCircleRadius - strokeWidth / 2);

  return (
    <svg width="100%" height="100%" viewBox="-10 -10 120 120" fill="none">
    <circle cx="50" cy="50" r={radius} fill={borderColor} opacity={0.1} />
    
    <path
      d="M50 50 L50 0 A50 50 0 0 1 101 50 Z"
      fill="#ffffff"
      transform="rotate(134.5, 50, 50)"
    />
    <circle
      cx="50"
      cy="50"
      r={borderCircleRadius - strokeWidth / 2 }
      fill="none"
      stroke="#ffffff"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeDasharray={`${borderLength} ${circleCircumference - borderLength}`}
      transform="rotate(135, 50, 50)"
    />
    <circle
      cx="50"
      cy="50"
      r={borderCircleRadius - strokeWidth / 2}
      fill="none"
      stroke={borderColor}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeDasharray={`${borderLength} ${circleCircumference - borderLength}`}
      transform="rotate(135, 50, 50)"
    />
    <circle cx="50" cy="50" r={innerRadius} fill="#ffffff" filter={`drop-shadow(0px 8px 20px ${hexToRGBA(borderColor,0.08)})`}/>
    <text x="50" y="50" text-anchor="middle" alignment-baseline="central" font-size="20" font-weight="bold" fill="#000" font-family="Raleway, sans-serif">{borderPercentage}%</text>
    <circle
      cx={circlePosition.x}
      cy={circlePosition.y}
      r="4"
      fill="none"
      stroke={borderColor}
      strokeWidth="2"
    />
  </svg>
  );
};


  
  export default Dial;
