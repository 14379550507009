import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import styled, { keyframes } from "styled-components";
import PercentileBubble from "./Bubble";
import User from 'assets/images/empty/user.svg'
import TeamAvatar from 'assets/images/team.svg'




const AvatarScore = ({type,employee1,employee2,score1,score2,User,id2,width,color}) =>{

		const [hover,setHover] = React.useState(false)

	   const getName = (emp) =>{
      if(!emp){
        return ""
      }

	    if(emp.name!="None None"){
	      return emp.name
	    }
	    return emp.email.split("@")[0]
	   }

	if(type==1 && id2){
		return ( 
			<React.Fragment>
          {employee1.pic? 
            <Avatar src={employee1.pic} position={score1} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>:
              <Empty src={User} position={score1} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
            }
          {employee2?.pic ? <Avatar src={employee2.pic} position={score2} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
          : id2?
             <Empty src={User} position={score2} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
            :""}

           {width>8? <BlueBar
            width={width ? width : ""}
            position={score2 > score1 ? score1 : score2}
            color={color}
          />:""}
          <PercentileBubble
            p1={score1}
            p2={score2}
            style={"double"}
            t1={getName(employee1)}
            t2={getName(employee2)}
            hover={hover}
            position={
              score1 > score2
                ? score2 + (score1 - score2 - 2) / 2
                : score1 + (score2 - score1 - 2) / 2
            }
          />
        </React.Fragment>)
	}else {
      return (
        <React.Fragment>
          {employee1.pic? 
            <Avatar src={employee1.pic} position={score1} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>:
               <Empty src={User} position={score1} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
            }
          
          {employee2?.pic ? (
            <Avatar src={employee2?.pic} position={score2} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
          ) : id2?
             <Empty src={User} position={score2} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}/>
            :""}
         {id2? <BlueBar
            width={width ? width : ""}
            position={score2 > score1 ? score1 : score2}
            color={color}
          />:""}
          <PercentileBubble position={score1} text={getName(employee1)} hover={hover} onMouseLeave={()=>setHover(false)}/>
          {id2?<PercentileBubble position={score2} text={getName(employee2)} hover={hover} onMouseLeave={()=>setHover(false)}/>:""}
        </React.Fragment>
      )
    }
}



export default AvatarScore


const Avatar = styled.img`
  height: 55px;
  width: 55px;
  border: solid 4px #ffffff;
  border-radius: 30px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 3;
  transition: left 0.5s;
`;

const Empty = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 30px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 3;
  transition: left 0.5s;
`;

const BlueBar = styled.div`
  width: ${(props) => props.width + "%"};
  height: 12px;
  background-color: ${props=>props.color};
  left: ${(props) => props.position + "%"};
  position: absolute;
  transition: width 0.5s;
  opacity:0.5;
`;