import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";


const Factor = (props) =>{

	return (
		<FactorContainer>
			<F1>{props.title}</F1>
			<Signal>Signal strength</Signal>
			<Progress percent={props.signal} size='small' progress style={{margin:0}}/>

			<Description>Feelings of discontent have grown +20% in the last few surveys. When feelings of discontent grow 
			and aren't properly addressed, this predicts changes in team health</Description>
		</FactorContainer>
		)
}


const FactorContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
	width:calc(50% - 20px);
	margin-top:20px;
	margin-right:20px;
`

const F1 = styled.div`
	font-size:18px;
	font-weight:bold;
	color:#4a4a4a;
`

const Signal = styled.div`
	margin-top:20px;
	margin-bottom:5px;
`


const Predictions = () =>{

	return (
		<Container>
			<div style={{display:'flex'}}>
				<Left>
					<Title>Opportunities<br/> this week</Title>
					<Description>There is a predicted change of -9% in team health scores for the next survey. Predictions are
					generated through AI. </Description>
					<Learn>Find out more <Icon name="chevron right" style={{marginBottom:2,marginLeft:5}}/></Learn>
				</Left>
				<Right>
						<T2>Predicted change in team health</T2>
					 <Chart options={data2.options} series={data2.series} type="line" height={300} />
				</Right>
			</div>

			<Factors>
				<Title>How do we know?</Title>
				<F>
					<Factor title={'Trend in feelings of discontent'} signal={80}/>
					<Factor title={'Growth in Hardship'} signal={60}/>
					<Factor title={'Significant cluster of lower trust scores'} signal={55}/>
					<Factor title={'Lower levels of engagement'} signal={50}/>
				</F>
			</Factors>
		</Container>
		)
}


export default Predictions


const Container = styled.div`
	margin-top:70px;
	font-family: 'Red Hat Display', sans-serif;
`

const Description = styled.div`
	margin-top:20px;
`

const Left = styled.div`
	width:30%;
	padding:30px;
	padding-right:5%;
`

const Title = styled.div`
	font-size:20px;
	font-weight:500;
`

const T2 = styled.div`
	font-size:18px;
	font-weight:bold;
`

const Right = styled.div`
	width:70%;
	padding:30px;
`

const Factors = styled.div`
	padding:30px;
`
const Learn = styled.div`
	margin-top:20px;
	color:#4a4a4a;
	font-weight:500;
	display:flex;
	align-items:center;
`

const F = styled.div`
	display:flex;
	flex-wrap:wrap;
`

const data2 = {
	series: [
               {
                name: "Predicted",
                data: [null,null,33,30]
              },
              {
                name: "Current",
                data: [28, 29, 33,null]
              }
            ],
            options: {
            		annotations:{
            				points: [
							      {
							        x: 4,
							        y: 30,
							        marker: {
							          size: 12,
							          fillColor: "#fff",
							          strokeColor: "#c3a7fd",
							          radius: 1,
							          strokeWidth:9,
							        },
							        label: {
							          borderColor: "#6a00ff",
							          offsetY: 0,
							          style: {
							            color: "#fff",
							            background: "#6a00ff",
							            fontSize:30,

							          },

							          text:'-9%'
							        }
							       }
							      ]
            		},
              chart: {
                height: 200,
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: true,
			    }
              },
              colors:['#6a00ff','#6a00ff'],
              stroke: {
                curve: 'smooth',
                width: [5, 7],
	          dashArray: [12, 0]
              },
              markers:{
              	 strokeColors:'#c3a7fd',
              	size:8,
              	strokeWidth:8,
              },
              grid:{
              	padding:{
              		left:30,
              		right:80,
              		bottom:30,
              		top:30
              	}
              },
              xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              },
              yaxis: {
                title: {
                  text: 'Score'
                },
                min: 20,
                max: 40
              },
            }
}