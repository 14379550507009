

export const calculateHeatmap = (core_data,filtered_data,anchor,outcomeQ,outcomeStandards) => {



    // Gather the anchor options
    const anchor_options = core_data?.categories[0]?.categories.find(f=>f.id == anchor).options
    const data = anchor_options?.map((option)=>{
        let culture_scores = get_culture_scores(option,filtered_data,anchor,core_data.questions)
        let outcome_scores = get_outcome_scores(option,filtered_data,anchor,outcomeQ)
        return {
            option:option.name,
            culture_scores:culture_scores,
            outcome_scores:outcome_scores,
            responses:filtered_data[0].filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id).length
        }
    }) 
    
    return data
}


const get_culture_scores = (option,filtered_data,anchor,questions) => {

    let anchor_data = filtered_data[0].filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id)
    let overall_scores = []

    // Get the overall culture scores
    anchor_data.forEach((data)=>{
       overall_scores.push(average_array(data.questions.map((question)=>question.response)))
    })


    // Get the factor scores
    let factor_scores = {}
    let item_scores = {}


    questions?.dimensions.forEach((dimension)=>{
        dimension.factors.forEach((factor)=>{
            let scores = []
                const dimension_id = dimension.id -1
                const factor_id = factor.id -1
                anchor_data.forEach((data)=>{
                    scores.push(data.questions.filter(i=>i.id==dimension_id && i.factor == factor_id).map(r=>r.response))
                })
                const key = JSON.stringify([dimension_id,factor_id]);
                factor_scores[key]= {scores:average_array(scores.flat()),factor:factor.title}

            factor.questions.forEach((question,q_id)=>{
                let item_scores_raw =  anchor_data.map((data)=>{
                    return data.questions.find(i=>i.id==dimension_id && i.factor == factor_id && i.q == q_id)?.response
                })
                const item_key = JSON.stringify([dimension_id,factor_id,q_id])
                item_scores[item_key] = {scores:average_array(item_scores_raw),question:question.q,factor_name:factor.title,dimension_name:dimension.title}

            })
        })
    })

    return {
        overall_scores:average_array(overall_scores),
        factor_scores:factor_scores,
        item_scores:item_scores
    }
}

const average_array = (arr) => {
    return arr.reduce((a,b)=>a+b,0)/arr.length
}


const get_outcome_scores = (option,filtered_data,anchor,outcomeQ) => {
    let outcome_scores = {}
    let question_scores = {}
    let anchor_data = filtered_data[0].filter(f=>f.categories.find(i=>i.id==anchor)?.response==option.id)

    outcomeQ?.questions.forEach((question)=>{
        let name = question.name

        let filtered_responses = anchor_data.map((data)=>data?.employee_outcomes?.responses?.filter(i=>i.q == question.id).map((i)=>i?.response)).flat()
        let scores = average_array(filtered_responses)
        outcome_scores[name] = scores

        
        question.questions.forEach((q)=>{
            let filtered_responses = anchor_data.map((data)=>data?.employee_outcomes?.responses?.filter(i=>i.q == question.id && i.s == q.s).map((i)=>i?.response)).flat()
            let scores = average_array(filtered_responses)
            const item_key = JSON.stringify([q.q,q.s])
            question_scores[item_key] = {scores,question:q.question,outcome:name}
        })

    })

    return {
        overall_scores:outcome_scores,
        question_scores:question_scores
    }

}

const aggregate_vertical_scores = (data) => {
    let outcomes = Object.values(data.outcome_scores.overall_scores)
    if(outcomes.includes(NaN)){
        return data.culture_scores.overall_scores
    }
     return data.culture_scores.overall_scores + outcomes.reduce((a,b)=>a+b,0)
}

export const vertical_sorted_names = (data) => {
    const sorted_names = data.sort((a,b)=>aggregate_vertical_scores(a) - aggregate_vertical_scores(b) ).map((item)=>item.option)
    return sorted_names
}


export const horizontal_sorted_names = (data) => {
    const total_culture_scores = data.map((item)=>item.culture_scores.overall_scores*item.responses).reduce((a,b)=>a+b,0)
    const total_responses = data.reduce((a,b)=>a+b.responses,0)


    const outcome_names = Object.keys(data[0].outcome_scores.overall_scores)
    
    const overall_outcome_scores = outcome_names.map((name)=>{
        let score = data.map((item)=>item.outcome_scores.overall_scores[name] * item.responses)
        let average_score = score ? score.reduce((a,b)=>b? a+b : a,0)/total_responses : null
        return {name,score:average_score}
    })


    const sorted_names = [...overall_outcome_scores,{name:"Culture",score:total_culture_scores/total_responses}]
    return sorted_names.sort((a,b)=>b.score-a.score)
}

export const factorTitleSorted = (data) => {
    let factor_scores = []
    const factor_titles = Object.values(data[0].culture_scores.factor_scores).map((item)=>item.factor)
    factor_titles.forEach((title)=>{
        factor_scores.push({scores:average_array(data.map((item)=>Object.values(item.culture_scores.factor_scores).find(i=>i.factor == title).scores)),name:title})
    })

    return factor_scores.sort((a,b)=>a.scores-b.scores)

}