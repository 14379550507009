import React from "react";
import styled from "styled-components";

const ComponentDescription = (props) => {
  const { title, description } = props;

  return (
    <DescriptionContainer>
      <Title>{title}</Title>
      <Subtitle>{description}</Subtitle>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

export default ComponentDescription;
