import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'
import Pic1 from 'assets/images/stock/article1.png'
import Pic2 from 'assets/images/stock/article2.png'

const Component4 = () =>{

    return (
        <Container>

            <T1>Great cultures are built deliberately.</T1>
            <Text delay={0.5}>
               Research shows that culture is changeable, and there are many ways to improve it. Here are some suggestions for how to get started. We've curated a few resources for you to take a deeper dive into each topic.
            </Text>

            <CardContainer>
                <Card delay={1}>
                    <ImageContainer>
                        <Img src={Pic1}/>
                    </ImageContainer>
                    
                    
                    <T2>
                       How to build a culture of psychological safety.
                    </T2>
                </Card>

                <Card delay={1.5}>
                    <ImageContainer>
                        <Img src={Pic2}/>
                    </ImageContainer>
                    
                    <T2>
                       How psychological safety can improve your bottom line.
                    </T2>
                </Card>

              
               
            </CardContainer>
        </Container>
    )
}

export default Component4

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const CardContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:40px;
`

const Card = styled.div`
    width:400px;
    display:flex;
    flex-direction:column;
    align-items:center;
    background-color:#EBF1FD;
    margin-right:20px;
    border-radius:10px;
    overflow:hidden;
    margin-top:20px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    padding:20px;
    height:75px;
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const IconContainer = styled.div`
    width:30px;
    height:30px;
    background-color:#2A3039;
    color:white;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:20px;
    min-width:30px;
`

const Img = styled.img`
  width:100%;
`

const ImageContainer = styled.div`
    width:100%;
    height:200px;
    overflow:hidden;
`