import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import styled from "styled-components";
import {
  TeamCard,
  Name,
  Avatar,
  TeamName,
  TeamLeader,
  Members,
  View,
  BlankAvatar,
} from "settings/styled-list";

import { Grid, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";

import MemberInfo from "./MemberInfo";

const Team = (props) => {
  const { members, data } = props;
  const [employees, setEmployees] = useState([]);
  const [teams, setTeams] = useState([]);

  const { memberInfo, teamInfo } = useSelector(
    (state) => ({
      memberInfo: state.employees.employees,
      teamInfo: state.teams.teams,
    }),
    shallowEqual
  );

  useEffect(() => {
    setEmployees(memberInfo);
    setTeams(teamInfo);
  }, [memberInfo, teamInfo]);

  const getLeader = (team) => {
    let leader = employees.filter(
      (member) => member.team === team && member.role === 2
    )[0];
    return leader?.id ? leader.id : null;
  };

  const getTeamSize = (team) => {
    let leader = employees.filter((member) => member.team === team);

    return leader?.length ? leader.length : 0;
  };

  const getTeam = (team_id) => {
    let _Team = teams.filter((team) => team.id === team_id)[0];
    return _Team;
  };

  //
  //
  //

  return (
    <TeamCard onClick={()=>props.onClick()} style={{paddingLeft:10}}>
        <Name>
          <div>
            <TeamName>{getTeam(data.team)?.name}</TeamName>
            <TeamLeader>{getLeader(data.team) ? getLeader() :"No leader"}</TeamLeader>

          </div>
        </Name>

        <Members> {getTeamSize(data.team) ? getTeamSize(data.team) : "0"} members</Members>
        <View>View</View>
        
      </TeamCard>
  );
};

export default Team;
