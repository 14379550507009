import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { shallowEqual, useSelector } from "react-redux";
import { Dimmer, Label, Loader, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { getPercentile, effect_size_calculation } from "./calculations";
import Empty from "../Empty";

const IndividualModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const distribution = useRef();

  const [loading, setLoading] = useState(true);
  const [options1, setOptions1] = useState(null);
  const [options2, setOptions2] = useState(null);
  const [series2, setSeries2] = useState([]);
  const [effect, setEffect] = useState([]);
  const [percentile, setPercentile] = useState([0]);
  const [diffFromPrevious, setDiffFromPrevious] = useState(0);
  const [loaded, setLoaded] = useState(null);
  const [empty, setEmpty] = useState(false);

  const selected = useSelector((state) => state.audit?.selected, shallowEqual);

  const getDistribution = (distribution) => {
    const counts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
    };

    for (const num of distribution) {
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }

    let distributionArray = [];

    for (let i = 1; i < 11; i++) {
      distributionArray.push(counts[i]);
    }

    return distributionArray;
  };

  const formatDataToChart = (targetGroup, entireGroup) => {
    let chartData = [];

    entireGroup.forEach((groupResponses) => {
      let respObj = {
        x: groupResponses.date,
        y: groupResponses.avg,
      };
      chartData.push(respObj);
    });

    return [{ data: chartData }];
  };

  distribution.current = [
    { name: "data", data: getDistribution(props.targetGroupResponses) },
  ];

  useEffect(() => {
    const data = props.groupData(props.item, props.label);

    if (props.groupData(props.item, props.label).length > 0) {
      let _options = data3.options;
      _options.colors = [props.color, "#b5b5b5"];
      setOptions1(_options);

      let _options2 = data2.options;
      _options2.colors = [props.color];
      setOptions2(_options2);
      let chart_data = formatDataToChart(
        props.targetGroupResponses,
        props.groupData(props.item, props.label)
      );
      setSeries2(chart_data);

      let effectSize = effect_size_calculation(
        props.categories,
        props.recentResponses,
        props.factor,
        props.idx,
        props.role,
        props.i
      );

      setEffect(effectSize);
      if (
        chart_data.length > 0 &&
        props.groupData(props.item, props.label).length > 0
      ) {
        const percentile = getPercentile(
          props.targetGroupResponses,
          props.groupData(props.item)[0]
        );

        const previousRespPool = props
          .groupData(props.item)
          .sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.date) - new Date(a.date);
          });

        // Get sample of previous, first we need to "pop" this group.
        previousRespPool.pop();

        const previousPercentile = previousRespPool.length
          ? getPercentile(previousRespPool[0].distribution, previousRespPool[0])
          : 0;

        setPercentile(percentile);
        setDiffFromPrevious(percentile - previousPercentile);
      }

      setLoaded({ selected, q: props.idx, role: props.i });
      setEmpty(false);
      setTimeout(() => setLoading(false), 300);
    } else {
      if (open) {
        setEmpty(true);
        setTimeout(() => setLoading(false), 600);
      }
    }
  }, [open]);

  const getSize = (ES) => {
    if (ES < 0.2) {
      return "None";
    }
    if (ES < 0.5) {
      return "Small";
    }
    if (ES < 0.8) {
      return "Moderate";
    }
    if (ES < 1.3) {
      return "Large";
    }
    return "Very Large";
  };

  const getPrevChangeLabel = () => {
    if (diffFromPrevious > 0) {
      return (
        <Label style={{ marginLeft: 10 }} color={"green"}>
          +{diffFromPrevious}%
        </Label>
      );
    } else {
      return (
        <Label style={{ marginLeft: 10 }} color={"red"}>
          {diffFromPrevious}%
        </Label>
      );
    }
  };

  return (
    <Modal
      onClose={() => {
        setOpen(false);
        setEmpty(false);
        setLoading(true);
      }}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
      closeIcon
      size={"large"}
    >
      {!loading && empty ? (
        <Empty />
      ) : (
        <>
          <Modal.Header style={{ padding: 30 }}>
            <Role>{props.title}</Role>

            <Question>{props.question}</Question>
          </Modal.Header>
          <SquareContainer>
            <Square width={75}>
              <Title>Long term trends</Title>
              <Description>
                Index scores from most recent survey. {props.date}
              </Description>
              {loading ? (
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              ) : (
                <Chart
                  options={options1}
                  series={series2}
                  type="line"
                  height={"100%"}
                />
              )}
            </Square>

            <Square width={25}>
              <Title>Role Norms</Title>
              <Description>
                Index scores from most recent survey. {props.date}
              </Description>
              <Chart
                options={data.options}
                series={[percentile]}
                type="radialBar"
                height={"100%"}
                style={{ marginTop: -20 }}
              />
              <Change>Since last assessment {getPrevChangeLabel()}</Change>
            </Square>

            <Square width={75}>
              <Title>Score distribution</Title>
              <Description>
                Index scores from most recent survey. {props.date}
              </Description>
              {loading ? (
                <Dimmer active inverted>
                  <Loader inverted>Loading</Loader>
                </Dimmer>
              ) : (
                <Chart
                  options={options2}
                  series={distribution.current}
                  type="bar"
                  height={"100%"}
                />
              )}
            </Square>

            <Square width={25}>
              <Title>Effect size insights</Title>
              <Description>
                Critical factors that are most impacted and in need of attention
              </Description>
              <Labels>
                {effect &&
                  effect.map((item) => {
                    return (
                      <LabelContainer>
                        <TitleES>{item.name}</TitleES>
                        <Value>
                          Effect Size:{" "}
                          {Math.floor(
                            Math.max(...item.ES.map((i) => Math.abs(i) * 100))
                          ) / 100}{" "}
                          (
                          {getSize(
                            Math.floor(
                              Math.max(...item.ES.map((i) => Math.abs(i) * 100))
                            ) / 100
                          )}
                          )
                        </Value>
                      </LabelContainer>
                    );
                  })}
              </Labels>
            </Square>
          </SquareContainer>
        </>
      )}
    </Modal>
  );
};

export default IndividualModal;

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
`;

const LabelContainer = styled.div`
  width: 100%;
`;

const Value = styled.div`
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const TitleES = styled.div`
  font-size: 12px;
  color: black;
  font-weight: bold;
  margin-bottom: 3px;
`;

const Square = styled.div`
  width: ${(props) => props.width + "%"};
  height: 350px;
  padding: 20px;
  border: 1px solid lightgrey;
`;

const SquareContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Title = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 3px;
`;
const Description = styled.div`
  margin-top: 0px;
  font-size: 12px;
`;

const Role = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 30px;
  margin-bottom: 10px;
`;

const Question = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: 300;
`;
const Change = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: -20px;
`;

const data3 = {
  series: [
    {
      name: "Average Score",
      data: [8.2, 8.5, 9, 7.6],
    },
    {
      name: "Category Norms",
      data: [8.6, 8.0, 7.3, 8.5],
    },
  ],
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#E3B01F", "#b5b5b5"],
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },
    stroke: {
      width: [5, 2],
      curve: "straight",
      dashArray: [0, 8],
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "Score",
      },
      min: 0,
      max: 10,
      decimalsInFloat: 1,
    },
  },
};

const data2 = {
  series: [
    {
      name: "Scores",
      data: [0, 0, 0, 0, 1, 2, 8, 3, 2, 0, 1],
    },
  ],
  options: {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    colors: ["#0387EF", "#FFC600", "#FF0000", "#02AE5B"],
    fill: {
      opacity: 0.3,
    },
    grid: {
      padding: {
        left: 10,
        right: 10,
        bottom: 20,
        top: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
    },
    yaxis: {
      title: {
        text: "Score frequency",
      },
      categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      decimalsInFloat: 0,
    },
  },
};

const data = {
  series: [80],
  options: {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "50%",
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "black",
            fontSize: "36px",
            show: true,
          },
        },
      },
    },

    labels: ["Percentile"],
    fill: {
      colors: ["#b5b5b5"],
    },
  },
};
