import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";

const metrics = [
	{ title: "Safety", type: "People", id: 1 },
	{ title: "Well-being", type: "People", id: 2 },
	{ title: "Cohesion", type: "People", id: 3 },
	{ title: "Empowerment", type: "People", id: 4 },
	{ title: "Personalization", type: "People", id: 5 },
	{ title: "Vision", type: "Performance", id: 6 },
	{ title: "Identity", type: "Performance", id: 7 },
	{ title: "Climate", type: "Performance", id: 8 },
	{ title: "Accountability", type: "Performance", id: 9 },
	{ title: "Adaptability", type: "Performance", id: 10 },
];

const Metrics = ({ setMetric }) => {
	const [selected, setSelected] = useState(null);

	const update = (i) => {
		setSelected(i);
		setMetric(i);
	};

	return (
		<Container>
			<Title>Select a targeted culture metric</Title>
			<div style={{ display: "flex" }}>
				<MetricArea>
					{metrics
						.filter((f) => f.type == "People")
						.map((i) => (
							<Target
								data={i}
								selected={i.id == selected}
								onClick={() => update(i.id)}
							/>
						))}
				</MetricArea>
				<MetricArea>
					{metrics
						.filter((f) => f.type == "Performance")
						.map((i) => (
							<Target
								data={i}
								selected={i.id == selected}
								onClick={() => update(i.id)}
							/>
						))}
				</MetricArea>
			</div>
		</Container>
	);
};

const Target = ({ data, onClick, selected }) => {
	const [score, setScore] = useState(Math.floor(Math.random() * 20));
	const [direction, setDirection] = useState(Math.random());
	return (
		<TargetContainer onClick={() => onClick()} selected={selected}>
			<TextArea>
				<Area>
					{data.title}
					<Label
						color={data.type == "People" ? "#FF9961" : "#163174"}
					>
						{data.type}
					</Label>
				</Area>
				<Description>
					{data.title} scores have decreased by 11%, and remain the
					lowest scores
				</Description>
			</TextArea>
			<Score>
				<Top>Avg. Score</Top>
				<Row>
					{Math.floor(Math.random() * 70) / 10}
					<IconContainer color={direction > 0.5 ? "red" : "green"}>
						<Icon
							name={
								direction > 0.5
									? "arrow alternate circle down"
									: "arrow alternate circle up"
							}
						/>
						{score}%
					</IconContainer>
				</Row>
			</Score>
		</TargetContainer>
	);
};

export default Metrics;

const Container = styled.div`
	width: 100%;
	padding: 20px;
	margin-top: 30px;
	font-family: "Barlow", sans-serif;
	margin-bottom: 40px;
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 30px;
`;

const TargetContainer = styled.div`
	width: 500px;
	padding: 20px 10px;
	display: flex;
	border: 1px solid #e9e9e9;
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-bottom: 10px;
	background-color: ${(props) =>
		props.selected ? "rgba(0, 0, 0, 0.1)" : "none"};

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
`;

const Area = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: bold;
	font-size: 20px;
	display: flex;
	align-items: center;
`;

const Score = styled.div`
	font-size: 28px;
	font-family: "Barlow Semi Condensed", sans-serif;
	line-height: 1;
	width: 150px;
`;

const Top = styled.div`
	font-size: 12px;
	color: grey;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const IconContainer = styled.div`
	font-size: 22px;
	margin-top: 5px;
	margin-left: 10px;
	color: ${(props) => props.color};
`;

const TextArea = styled.div``;

const Description = styled.div`
	font-weight: 300;
	margin-top: 10px;
`;

const Label = styled.div`
	background-color: ${(props) => props.color};
	padding: 0px 20px;
	border-radius: 10px;
	font-size: 12px;
	color: white;
	margin-left: 5px;
`;

const MetricArea = styled.div`
	width: 50%;
`;
