import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Icon, Table } from "semantic-ui-react";

import { Link } from "react-router-dom";
import format from "date-fns/format";
import { ElipsesButton, TeamIcon } from "./Icons";
import { Popup } from "semantic-ui-react";
import RedirectLink from "../RedirectLink";

const TableList = ({ members, categories, filterCategories, structure }) => {
  const [mappedMembers, setMappedMembers] = useState([]);

  useEffect(() => {
    if (members && members?.length > 0) {
      const mappedList = members
        .filter((item) => item.status !== 99 && item.status !== 98)
        .map((item, i) => {
          let missingFields = [];
          let missingFieldsString = "";
          filterCategories.forEach((cat) => {
            const userCategory = categories.find((c) => c.employee === item.id);
            if (userCategory) {
              if (
                cat?.priority === "primary" ||
                cat?.priority === "secondary"
              ) {
                const hasField = userCategory?.category.find(
                  (uc) => uc?.priority === cat?.priority
                );

                if (
                  (!hasField && !missingFields.includes(cat?.category)) ||
                  (hasField &&
                    !cat?.options.find((op) => op.text === hasField?.value))
                ) {
                  missingFields.push(cat?.category);
                }
              } else {
                const hasField = userCategory?.category.find((uc) => {
                  return uc?.name === cat?.category;
                });
                if (!hasField && !missingFields.includes(cat?.category)) {
                  missingFields.push(cat?.category);
                }
              }
            }
          });

          if (!item.email) {
            missingFields.push("Email");
          }

          if (!item.first_name) {
            missingFields.push("First Name");
          }

          if (!item.last_name) {
            missingFields.push("Last Name");
          }

          missingFields = missingFields.filter((f) => !!f);
          if (missingFields.length > 0) {
            missingFields.forEach((field, i) => {
              if (missingFieldsString.length > 0) {
                missingFieldsString = missingFieldsString + ", " + field;
              } else {
                missingFieldsString = field;
              }
            });
          }
          if (item.email) {
            const dateUpdated = format(
              new Date(item?.updated_at),
              "yyyy-MM-dd"
            );
            let teamString = "N/A";
            let popupString = "";
            const empCategories = categories.find(
              (cat) => cat.employee === item.id
            );
            if (empCategories?.category.length > 0) {
              const primaryCategories = empCategories.category.filter((cat) => {
                return cat?.priority === "primary";
              });
              if (primaryCategories) {
                const safePrimaryCategories = getPrimaryCategoriesSafe(
                  primaryCategories,
                  structure
                );

                teamString =
                  safePrimaryCategories.length > 1
                    ? `${safePrimaryCategories[0]} +${
                        safePrimaryCategories.length - 1
                      }`
                    : safePrimaryCategories[0];

                safePrimaryCategories.forEach((cat, i) => {
                  if (i > 0) {
                    if (popupString.length > 0) {
                      popupString = popupString + ", " + cat;
                    } else {
                      popupString = cat;
                    }
                  }
                });
              }
            }
            return (
              <TableRow key={i}>
                <TableContentName>
                  {item?.first_name
                    ? item?.first_name + " " + item?.last_name
                    : "N/A"}
                </TableContentName>
                <TableContent>
                  <TeamCol>
                    <TeamIconWrapper>{TeamIcon("#9E9FA1")}</TeamIconWrapper>
                    <Popup
                      content={popupString}
                      disabled={popupString.length === 0}
                      position="bottom center"
                      size="mini"
                      inverted
                      trigger={<TeamText>{teamString || "N/A"}</TeamText>}
                    />
                  </TeamCol>
                </TableContent>
                <TableContent>{dateUpdated}</TableContent>
                <TableContent>
                  <RedirectLink to={`members/edit/${item.id}`}>
                    <ElipsesIcon>{ElipsesButton()}</ElipsesIcon>
                  </RedirectLink>
                </TableContent>
                {missingFields.length > 0 && (
                  <WarningIconWrapper>
                    <Popup
                      content={`Missing Fields: ${missingFieldsString}`}
                      position="bottom center"
                      size="mini"
                      inverted
                      trigger={<Icon name="warning" color="red" />}
                    />
                  </WarningIconWrapper>
                )}
              </TableRow>
            );
          } else {
            return (
              <TableRow key={i}>
                <TableContent>NA</TableContent>
                <TableContent>NA</TableContent>
                <TableContent>NA</TableContent>
                <TableContent></TableContent>
              </TableRow>
            );
          }
        });
      setMappedMembers(mappedList);
    }
  }, [members, categories, filterCategories, structure]);

  const getPrimaryCategoriesSafe = (userCategories, structure) => {
    // Get primary category list from the data
    let primaryCategoryList = structure?.categories?.filter(
      (category) => category?.priority === "primary"
    );
    let userPrimaryNamesList = [];
    userCategories?.forEach((userCat) => {
      // check primaryCategoryList?.[0]?.options for a match using the value_id of userCat
      primaryCategoryList?.[0]?.options?.forEach((category) => {
        if (category?.id === userCat?.value_id) {
          userPrimaryNamesList.push(category?.name);
        } else {
          // Check subfactors
          if (category?.subfactors?.length > 0) {
            category?.subfactors?.forEach((subfactor) => {
              if (subfactor?.id === userCat?.value_id) {
                userPrimaryNamesList.push(subfactor?.name);
              }
            });
          }
        }
      });
    });

    return userPrimaryNamesList;
  };

  return (
    <>
      <div
        style={{
          marginTop: 30,
        }}
      >
        {mappedMembers && mappedMembers.length > 0 && (
          <TableContainer>
            <TopTableRow>
              <TableHeading>Name</TableHeading>
              <TableHeading>Team</TableHeading>
              <TableHeading>Update</TableHeading>
              <TableHeading>Actions</TableHeading>
            </TopTableRow>
            {mappedMembers}
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default TableList;

const Linked = styled.span`
  text-decoration: none;
  cursor: pointer;
`;

const ElipsesIcon = styled.div`
  line-height: 21.86px;
  margin-right: 5px;
  cursor: pointer;
`;

const TableContainer = styled.div`
  width: 100%;
`;

const WarningIconWrapper = styled.div`
  position: absolute;
  right: 0;
  padding-right: 125px;
  padding-top: 30px;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableHeading = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TableRow = styled.div`
  height: 82px;
  display: flex;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.87);
  }
`;

const TeamCol = styled.div`
  flex-direction: row;
  display: flex;
`;

const TeamIconWrapper = styled.div`
  line-height: 21.86px;
  margin-right: 5px;
`;

const TeamText = styled.div`
  line-height: 21.86px;
  flex-direction: column;
`;

const TableContent = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const TableContentName = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;
