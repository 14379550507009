import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import Chart from "react-apexcharts";


const Trends = () =>{

    return (
        <Container>
                 <Title>Long term trends</Title>
                <Description>
                    According to your collected data, the average culture score for your organization is 7.6. 
                    The most recent data shows an improvement of <strong>12%</strong>, which is in the <strong>90th percentile </strong>
                    of change improvements globally.
                </Description>

                <Chart
                            options={data3.options}
                            series={data3.series}
                            type="line"
                            width={800}
                        />
        </Container>
    )
}


export default Trends


const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
    width:100%;
`


const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
`

const Description = styled.div`
    margin-bottom:5px;
    line-height:1.4;
`


const Help = styled.div`
    display:inline;
    padding:5px 10px;
    border:1px solid #666D79;
    color:#666D79;
    font-size:12px;
    font-family:'Raleway';
    margin-bottom:10px;
    position:absolute;
    right:0px;
    top:10px;
    cursor:pointer;
    
`


const data3 = {
	series: [
		{
			data: [72,66,78],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800,
			height: 300,
            sparkline: {
                enabled: true,
            }
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        stroke: {
            curve: 'smooth'
          },
          dataLabels: {
            enabled: true,
          },
          grid:{
            padding:{
                left:20,
                right:20
            }
          },
		colors: ["#27CDA7", "#27CDA7", "#27CDA7",'#F15062','#F15062','#F15062','#F15062'],
		xaxis: {
			categories: [
				"Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department G"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: "#2D70E2",
				}
			}
		},
		yaxis: {
			min: 60,
			max: 80,
			decimalsInFloat: 0,
			title: {
				text: "Difference from the benchmark average",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
