import React from "react";
import styled from "styled-components";
import {
  QuestionButtonIcon,
  CommunicationIcon,
  EducationExamplesIcon,
  SchedulingIcon,
  QuestionsIcon,
  StructuredSectionsIcon,
  FollowUpIcon,
  IdeaIcon,
  TeamsIcon,
  ContextSessionsIcon,
  KeyChangesIcon,
  ComprehensiveIcon,
  OpenDiscussionIcon,
  DateIcon,
  ActionIdeaIcon,
} from "./icons";
import TextBlock from "./TextBlock";

const getPlanTitle = (plan) => {
  return plan.split("\n")[0].split(":")[1].trim();
};

const createReportFromArray = (data, scores, orgName, tags) => {
  const reportPlan = data.map((item, i) => {
    const reportPageData = {
      pageTitle: `GROWTH AREA ${i + 1}`,
      barTitle: scores[i].name,
      barPercentage: Math.floor(scores[i].score * 10),
      teamName: orgName,
      tags: tags,
      orgName: orgName,
      orgAverage: Math.floor(scores[i].org_score * 10),
    };

    const bulletItems = scores[i]?.item_scores?.map((score) => {
      return `${score?.question} (score: ${Math.floor(score?.score * 10)}%)`;
    });

    const textBlockOne = {
      title: `What is ${scores[i].name}?`,
      description: item?.factor_definition,
      bulletTitle: `Items related to ${scores[i].name} in the survey:`,
      bullets: bulletItems,
      bgColor: "#F8FAFF",
      icon: IdeaIcon(),
    };

    const textBlockTwo = {
      title: `What are people saying about ${scores[i].name}?`,
      bgColor: "#fff",
      numberedItems: item?.summary
        ?.split(/\n\n?/)
        .map((item) => item.replace(/^\d+\./, "").trim()),
      icon: TeamsIcon(),
    };

    const actionPlanTitle = `${orgName} action plan to improve ${scores[
      i
    ].name.toLowerCase()}`;

    const planSteps = item?.recommendations?.map((plan, i) => {
      const planArray = plan.split("\n\nStep ");
      const planArray2 = planArray
        .map((step, i) => {
          const stepArray = step.split(/\n\n|\n/);
          const stepTitle = stepArray[0]?.replace(/\d+:/g, `Step ${i}.`);
          // Create an array of step points separated by \n\n
          const stepPoints = stepArray.slice(1).map((stepPoint, i) => {
            return stepPoint;
          });
          const icon = ActionIdeaIcon();

          return { stepTitle, stepPoints, icon };
        })
        .filter((plan) => plan.stepPoints?.length > 0);

      return {
        planNumber: i + 1,
        planTitle: getPlanTitle(plan),
        // randomize the icon based on iconsArray
        steps: planArray2,
      };
    });

    // Regex to make any text with "action plan" in it blue
    // This could probably be turned into a reusable function
    const blueRegex = /action plan/gi;
    const blueIndex = actionPlanTitle.search(blueRegex);
    const blueMatch = actionPlanTitle.match(blueRegex);
    const titleFirstSection = actionPlanTitle.slice(0, blueIndex);
    const titleSecondSection = actionPlanTitle
      .slice(blueIndex)
      .replace(blueRegex, "");

    return (
      <>
        <PageContainer>
          <TitleSection>
            <TitleText>{reportPageData?.pageTitle}</TitleText>
          </TitleSection>
          <BarChartSection>
            <ChartTitleSection>
              <ChartTitleText>{reportPageData?.barTitle}</ChartTitleText>
            </ChartTitleSection>

            <ChartContainer>
              {(reportPageData?.tags && (
                <TagsSection>
                  {reportPageData?.tags?.split(", ").map((tag) => (
                    <TagsText>{tag}</TagsText>
                  ))}
                </TagsSection>
              )) || <TeamNameText>{reportPageData?.teamName}</TeamNameText>}
              <ChartArea>
                <StartingLine>
                  <StartingLineText>0</StartingLineText>
                </StartingLine>
                <LineTwo>
                  <LineText>20</LineText>
                </LineTwo>
                <LineFour>
                  <LineText>40</LineText>
                </LineFour>
                <LineSix>
                  <LineText>60</LineText>
                </LineSix>
                <LineEight>
                  <LineText>80</LineText>
                </LineEight>
                <LineTen>
                  <LineText>100</LineText>
                </LineTen>
                <ProgressBarContainer>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="650"
                    height="50"
                    viewBox="0 0 100 30"
                    preserveAspectRatio="none"
                  >
                    <defs>
                      <linearGradient id="progressGradient">
                        <stop offset="8%" stopColor="#F8A8B0" />
                        <stop offset="50%" stopColor="#FECD8D" />
                        <stop offset="92%" stopColor="#95E6D2" />
                      </linearGradient>
                    </defs>
                    <path
                      d={`M 0 0 H ${reportPageData?.barPercentage - 2} Q ${
                        reportPageData?.barPercentage
                      } 0 ${reportPageData?.barPercentage} 2 V 25 Q ${
                        reportPageData?.barPercentage
                      } 30 ${reportPageData?.barPercentage - 2} 30 H 0 V 0`}
                      fill="url(#progressGradient)"
                      style={{ opacity: 0.5 }}
                    />
                  </svg>
                </ProgressBarContainer>
                <AverageLine style={{ left: `${reportPageData?.orgAverage}%` }}>
                  <AverageLineText>
                    {reportPageData?.orgName} average
                  </AverageLineText>
                </AverageLine>
              </ChartArea>
            </ChartContainer>
          </BarChartSection>
          <TextBlock
            title={textBlockOne?.title}
            description={textBlockOne.description}
            bulletTitle={textBlockOne.bulletTitle}
            bullets={textBlockOne.bullets}
            bgColor={textBlockOne.bgColor}
            icon={textBlockOne.icon}
          />
          <TextBlock
            title={textBlockTwo.title}
            bgColor={textBlockTwo.bgColor}
            numberedItems={textBlockTwo.numberedItems}
            icon={textBlockTwo.icon}
          />
        </PageContainer>
        <PageContainer>
          {planSteps?.map((plan, pId) => {
            return (
              <>
                {pId === 0 && actionPlanTitle && (
                  <ActionPlanTitleSection>
                    <ActionPlanTitle>
                      {titleFirstSection}
                      <ActionPlanBlueText>
                        &nbsp;{blueMatch}&nbsp;
                      </ActionPlanBlueText>
                      {titleSecondSection}
                    </ActionPlanTitle>
                  </ActionPlanTitleSection>
                )}
                <PlanSection>
                  <PlanTitleSection>
                    <PlanNumberText>{plan?.planNumber}</PlanNumberText>
                    <PlanTitle>{plan?.planTitle}</PlanTitle>
                  </PlanTitleSection>
                  <StepSection>
                    {plan?.steps?.map((step, i) => {
                      // let lineLength = 320;
                      return (
                        <StepContainer>
                          <StepIcon>{step?.icon}</StepIcon>
                          <StepData>
                            <ElipseContainer>
                              <StepTitle>{step?.stepTitle}</StepTitle>
                            </ElipseContainer>
                            <StepPoints>
                              {step?.stepPoints?.map((point) => {
                                return (
                                  <PointContainer>
                                    <StepBullet>•</StepBullet>
                                    <StepPoint> {point}</StepPoint>
                                  </PointContainer>
                                );
                              })}
                            </StepPoints>
                          </StepData>
                        </StepContainer>
                      );
                    })}
                  </StepSection>
                </PlanSection>
              </>
            );
          })}
        </PageContainer>
      </>
    );
  });

  return reportPlan;
};

const RecommendationPlan = (props) => {
  const { data, orgName, template, tags, scores, date } = props;
  const report = createReportFromArray(data, scores, orgName, tags);

  return <RecommendationTemplate>{report}</RecommendationTemplate>;
};

const RecommendationTemplate = styled.div``;

const PageContainer = styled.div``;

const TitleSection = styled.div``;

const TitleText = styled.div`
  color: #2a3039;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  text-transform: uppercase;
  font-family: Raleway;
  font-variant: lining-nums proportional-nums;
  font-feature-settings: "'ss09' on, 'ss06' on, 'ss02' on, 'ss11' on, 'ss04' on, 'salt' on, 'ss03' on, 'ss08' on, 'ss07' on, 'ss05' on, 'ss01' on, 'ss10' on, 'liga' off";
`;

const BarChartSection = styled.div``;

const ChartTitleSection = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;

const ChartTitleText = styled.div`
  color: #2a3039;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "'ss11' on, 'ss09' on, 'ss08' on, 'ss07' on, 'ss06' on, 'ss05' on, 'ss04' on, 'ss02' on, 'ss01' on, 'ss10' on, 'salt' on, 'ss03' on, 'liga' off";
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  text-transform: capitalize;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 50px;
  flex-direction: row;
  align-items: center;
  display: flex;
  align-content: center;
  margin-bottom: 20px;
`;

const TagsSection = styled.div``;

const TagsText = styled.div`
  fontsize: 8px;
  margin-right: 10px;
  padding-right: 5px;
  color: #666d79;
  font-family: Raleway;
`;

const TeamNameText = styled.div`
  font-size: 8px;
  margin-right: 10px;
  padding-right: 5px;
  color: #666d79;
  font-family: Raleway;
`;

const ChartArea = styled.div`
  width: 500;
  height: 100%;
  border-bottom: 1px solid #dfdfdf;
  border-top: 1px solid #dfdfdf;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const StartingLine = styled.div`
  position: absolute;
  height: 120%;
  width: 1;
  backgroundcolor: #dfdfdf;
  left: 0;
`;

const StartingLineText = styled.div`
  margin-top: 50px;
  font-size: 8px;
  color: #666d79;
  margin-left: -2px;
  width: 2px;
`;

const LineTwo = styled.div`
  position: absolute;
  height: 120%;
  width: 2px;
  background-color: #dfdfdf;
  left: 20%;
`;

const LineText = styled.div`
  margin-top: 50px;
  font-size: 8px;
  color: #666d79;
  margin-left: -4px;
  position: absolute;
`;

const LineFour = styled.div`
  position: absolute;
  height: 120%;
  width: 2px;
  background-color: #dfdfdf;
  left: 40%;
`;

const LineSix = styled.div`
  position: absolute;
  height: 120%;
  width: 2px;
  background-color: #dfdfdf;
  left: 60%;
`;

const LineEight = styled.div`
  position: absolute;
  height: 120%;
  width: 2px;
  background-color: #dfdfdf;
  left: 80%;
`;

const LineTen = styled.div`
  position: absolute;
  height: 120%;
  width: 2px;
  background-color: #dfdfdf;
  left: 100%;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 25;
  opacity: 0.5;
`;

const ProgressBar = styled.div``;

const AverageLine = styled.div`
  position: absolute;
  height: 170%;
  width: 4px;
  background-color: rgba(39, 205, 167, 0.5);
  border-radius: 3px;
  align-items: center;
  color: #666d79;
  font-size: 8px;
  margin-top: -70px;
`;

const AverageLineText = styled.div`
  width: 200px;
  margin-top: -25px;
  font-size: 12px;
  margin-left: -70px;
`;

const ActionPlanTitle = styled.div`
  color: #2a3039;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  flex-direction: row;
`;

const ActionPlanTitleSection = styled.div`
  padding-top: 25px;
`;

const ActionPlanBlueText = styled.div`
  fontsize: 18px;
  fontweight: 700;
  lineheight: 120%;
  color: #2d70e2;
`;

const PlanSection = styled.div`
  background-color: #fff;
  width: 100%;
  padding-top: 40px;
  border-radius: 10px;
`;

const PlanTitleSection = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  position: relative;
`;

const PlanNumberText = styled.div`
  font-size: 100px;
  font-weight: 800;
  position: absolute;
  left: -5px;
  top: -38px;
  z-index: 1;
  color: #ebf1fd;
  font-style: normal;
  line-height: 128%;
`;

const PlanTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 128%;
  color: #2a3039;
  position: absolute;
  left: 35px;
  z-index: 2;
`;

const StepSection = styled.div`
  padding-left: 65px;
  padding-right: 40px;
  padding-top: 65px;
`;

const StepContainer = styled.div`
  padding-bottom: 30px;
`;

const StepIcon = styled.div``;

const StepData = styled.div``;

const ElipseContainer = styled.div`
  display: flex;
  flexdirection: row;
  alignitems: center;
`;

const StepTitle = styled.div`
  padding-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 128%;
  color: #2a3039;
`;

const StepPoints = styled.div`
  margin-left: 5px;
`;

const PointContainer = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: row;
`;

const StepBullet = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 8px;
  line-height: 1.7px;
  font-size: 10px;
  margin-right: 5px;
`;

const StepPoint = styled.div``;

export default RecommendationPlan;
