import React, { useState, useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import { Input } from 'semantic-ui-react'

const OrderBox = ({ selectedPaymentMethod,
	stripeTaxRates, selectedPlan,
	subscription,
	subscriptionSelected,
	organization, 
	CreateSubscription,
	UpdateSubscription,
	employee,
	...props }) => {

	// console.log(subscriptionSelected)
	// console.log(selectedPlan)

	const [quantity, setQuantity] = useState(0)
	const [amountDecimal, setAmountDecimal] = useState(0)
	const [tax, setTax] = useState('')
	const [taxPercent, setTaxPercent] = useState(0)
	const [taxAmount, setTaxAmount] = useState(0)
	const [promoCode, setPromoCode] = useState('')
	// customer_stripe_id
	// payment_method_id
	// stripe_price_id
	// tax_rates
	// quantity

	useEffect(() => {
		setQuantity(Number(organization?.no_of_employees))
		setTax(stripeTaxRates.filter(
				({ display_name }) => display_name === organization?.province
			)[0])
		setTaxPercent(parseFloat(
			stripeTaxRates.filter(
				({ display_name }) => display_name === organization?.province
			)[0]?.percentage))

	}, [organization, stripeTaxRates])

	useEffect(() => {
		setAmountDecimal(
			parseFloat(subscriptionSelected?.amount_decimal).toFixed(2)
		)
	}, [subscriptionSelected])

	useEffect(() => {
		if (taxPercent) {
					setTaxAmount(
			parseFloat((amountDecimal * quantity)* (taxPercent/100))
		)
		}

	}, [amountDecimal, quantity, taxPercent ])

	function CreateNewSubscription() {
		CreateSubscription({
			customer_stripe_id: employee.stripe_customer_id,
			payment_method: selectedPaymentMethod.id,
			tax_rates: tax?.id,
			promotion_code_text: promoCode,
			price: subscriptionSelected.id,
          	quantity: organization.no_of_licenses,
			employee: employee.id,
			organization: organization.id,
		})
	}

	function UpdatePromoCode(e) {
		setPromoCode(e.target.value)
	}

	function UpdateCurSubscription() {
		UpdateSubscription({
			id: subscription.id,
			payment_method: selectedPaymentMethod.id,
			tax_rates: tax?.id,
			price: subscriptionSelected.id,
          	quantity: organization.no_of_licenses,
		})
	}

	function CancelCurSubscription() {
		UpdateSubscription({
			id: subscription.id,
			cancel_at_period_end: true,
		})
	}

	return (
		<Container>
			<Top>
				<Title>Innerlogic {subscriptionSelected.product_name? subscriptionSelected.product_name : "Nothing Selected"} </Title>
				<SubTitle>Billed monthly</SubTitle>

			</Top>
			<Body>
				<Row>
					<Item>${amountDecimal}USD <Multi> x </Multi> {quantity} members <Multi>x</Multi> 1 month
					</Item>
					<Price>${(quantity * amountDecimal).toFixed(2)} USD</Price>
				</Row>
				<Row>
					<Item>Sales tax - {taxPercent? taxPercent : 0}%</Item>
					<Price>{taxAmount.toFixed(2)}</Price>
				</Row>
				{!subscription?.id  && 
				<>
				<Row>
					<Item>Promo Code</Item>
					<StyledInput 
					onChange={UpdatePromoCode}
					value={promoCode}
					placeholder='Enter Promo Code'/>
					
				</Row>
					<p>Promo Code Message once the right promo code has been entered</p>
				
				</>}

				<Row>
					<Due>Monthly Charge</Due>
					<Price>${((amountDecimal * quantity) + taxAmount).toFixed(2)} USD</Price>
				</Row>

				<LastRow>
				{subscription?.id?

				<StyledCancelButton 
				color="red"
				// disabled
					onClick={ CancelCurSubscription }>
					Cancel 
				</StyledCancelButton>
	: ''}
				<StyleButton 
				// disabled
					onClick={subscription?.id? 
						UpdateCurSubscription : 
						CreateNewSubscription}>
					{subscription?.id? "Change" : "Subscribe"}
				</StyleButton>
				</LastRow>
			</Body>
		</Container>
	)
}

export default OrderBox

const Container = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;
  border-radius: 8px;
    box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
    overflow:hidden;
    // height:300px;

    // @media (max-width:1000px){
    // 	// margin-left:30px;
    // 	// width:350px;
    // }

    // @media (max-width:900px){
    //  display:none;
    // }
`

const Top = styled.div`
	height:100px;
	width:100%;
	background-color: #6a00ff;
	color:white;
	padding-left:20px;
	display:flex;
	justify-content:center;
	flex-direction:column;
`

const Title = styled.div`
	font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  font-weight:bold;
`

const SubTitle = styled.div`
	margin-top:5px;
`

const StyledInput = styled(Input)`
	margin:5px;
`

const Body = styled.div`
	padding:20px;
	display:flex;
	flex-direction:column;
`

const Row = styled.div`
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	width:100%;
	border-bottom:1px solid #e9e9e9;
	padding:20px 0 20px 0;
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;

	@media (max-width:1000px){
		font-size:12px;
	}
`
const MultiRow = styled.div`
	display:flex;
	flex-direction:column;
	width:100%;
	border-bottom:1px solid #e9e9e9;
	padding:0px 0 20px 0;
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;

	@media (max-width:1000px){
		font-size:12px;
	}
`

const LastRow = styled.div`
	display:flex;
	flex-direction:row;
	justify-content:space-between;
	width:100%;
	padding:20px 0 20px 0;
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
`

const Item = styled.div`
	display:flex;
	align-items:center;
	justify-content:center;
`
const Price = styled.div`
	
`
const Multi = styled.span`
	font-size:10px;
	color:#9b9b9b;
	margin:0 4px 0 4px;
`

const Due = styled.div`
	font-weight:bold;
	font-size:20px;
`
const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.disabled ? "#606060" : props.selected ? "#5e00e3" : "#6a00ff"};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 40px;
`;

const StyledCancelButton = styled.button`
height: 45px;
border-radius: 8px;
color: white;
background-color: ${(props) =>
  props.disabled ? "#606060" : "#FF0000"};
disabled: ${(props) => (props.disabled ? true : false)};
cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
font-size: 16px;
font-weight: bold;
font-family: "Red Hat Display", sans-serif;
display: flex;
align-items: center;
justify-content: center;
padding: 7px 24px 7px 24px;
border: none;
outline: none;
margin-top: 40px;
`;