import React from "react";
import styled from "styled-components";
import Pic from "assets/images/empty/mail.jpg";

const Validate_Email = () => {
	return (
		<Container>
			<Text>
				We've sent you an email to validate your account.{" "}
				<strong>Please check your inbox</strong> for the link to validate your
				account. Once you've validated your account you can begin by logging in{" "}
			</Text>
			<Img src={Pic} />
		</Container>
	);
};

export default Validate_Email;

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 50px;
`;

const Img = styled.img`
	width: 500px;
	max-width: 100%;
`;

const Text = styled.div`
	font-family: "Barlow", sans-serif;
	font-size: 18px;
	max-width: 500px;
	line-height: 1.5;
`;
