import React from 'react';
import styled from "styled-components";

const TopMenu = ({selected, setSelected, CultureMenu}) => {
	return (
		<>
			<CardTop>
				<Nav>
					{CultureMenu.map((p,i) => {
						return <NavItem 
						selected={p.id === selected}
						onClick={() => setSelected(p.id)}>{p.name}</NavItem>
					})}
					
					{/* <NavItem>Clarity</NavItem>
					<NavItem>Communication</NavItem>
					<NavItem>Alignment </NavItem>
					<NavItem>Resilience </NavItem>
					<NavItem>Impact </NavItem>
					<NavItem>Overview</NavItem> */}
				</Nav>


			</CardTop>
		</>
	);
};

export default TopMenu;

const Nav = styled.div`
	width:100%;
	display:flex;
	margin-top:20px;


`

const NavItem = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px 20px;
	height:50px;
	display:flex;
	align-items:center;
	color:${props => props.selected ? '#6a00ff' : '#9b9b9b'};
	font-weight:${props => props.selected ? 'bold' : '400'};
	border-bottom:${props => props.selected ? '3px solid #6a00ff' : 'none'};
	cursor:pointer;
	font-size:16px;
	position:relative;
`

const CardTop = styled.div`
	  width:100%;
	  display:flex;
	  flex-direction:column;

	   @media(max-width:1000px){
	    display:none;
	  }
`