import React, { useState } from "react";
import styled from "styled-components";
import NavigationItem from "./NavigationItem";

const LegendContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
`;

const NavigationLegend = () => {
  const [steps, setSteps] = useState([
    {
      title: "Journey Introduction",
      isCompleted: true,
      subItems: ["How they work?", "Probability of success"],
    },
    {
      title: "Needs",
      isCompleted: false,
      subItems: ["Select a focus", "Select an action", "Outcome"],
      isActive: true,
    },
    {
      title: "Whats next?",
      isCompleted: false,
      subItems: ["Set weekly check-ins", "Action plan"],
      isActive: false,
    },
  ]);

  return (
    <LegendContainer>
      {steps.map((step, index) => (
        <NavigationItem
          key={index}
          title={step.title}
          subItems={step.subItems}
          isCompleted={step.isCompleted}
          isActive={step.isActive}
          setActiveStep={(index) => {
            const newSteps = steps.map((step, i) => {
              if (i === index) {
                return {
                  ...step,
                  isActive: true,
                };
              } else {
                return {
                  ...step,
                  isActive: false,
                };
              }
            });
            setSteps(newSteps);
          }}
        />
      ))}
    </LegendContainer>
  );
};

export default NavigationLegend;
