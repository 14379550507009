import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import { Table } from 'semantic-ui-react';
import { chartDownloadOptions } from "constants/chartOptions";

const EffectPlot = ({data})=>{

    const [series,setSeries] = useState([])
    const [options,setOptions] = useState(options2)

    useEffect(()=>{
        if(data){
            const series = [
                {
                    data: data.effect_data.map(result => ({
                        x: `${result.factor}`,
                        y: [result.lowerBound, result.upperBound],
                        cohenD: result.cohenD // Optional, for potential further use
                    }))
                }
            ];
        
            const min = Math.min(...data.effect_data.map(result => result.lowerBound));
            const max = Math.max(...data.effect_data.map(result => result.upperBound));
        
            setSeries(series)
            setOptions({
                ...options,
                xaxis: {
                    title: {
                        text: 'Effect size'
                    },
                    min: min-0.1,
                    max: max+0.1,
                }
            })
        }
       
    },[data])



    return (
        <Container>
              <Title>Effect size analysis</Title>
              <Description>
                    The effect size analysis shows the range of potential impact of each factor on the outcome. The lower bound represents the minimum effect size, while the upper bound represents the maximum effect size.
              </Description>
             <Chart options={options} series={series} type="rangeBar" height={600} />


             <Question>
                How to interpret the effect size analysis?
                </Question>
                <Answer>
                        <p>
                        Each line on the plot represents the effect size (Cohen's D) of a specific factor relative to an outcome. The center point of the dumbbell indicates the estimated effect size.
                        </p>

                        <p>
                        The horizontal lines extending from each point represent the confidence interval for the effect size. A wider interval suggests more uncertainty in the estimate, while a narrower interval indicates greater precision.
                        </p>

                        <p>In relation to {data?.outcome.name.toLowerCase()}, {data?.effect_data[0].factor.toLowerCase()} has a very large effect. Which means that the difference between groups scoring {data?.effect_data[0].factor.toLowerCase()} in the top 25% vs the bottom 25% has the largest difference in {data?.outcome.name.toLowerCase()} scores. </p>
                </Answer>

                <Table celled style={{marginBottom:30}}>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Effect size</Table.HeaderCell>
                    <Table.HeaderCell>Interpretation</Table.HeaderCell>
                    <Table.HeaderCell>Significance</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>0.2</Table.Cell>
                    <Table.Cell>Small</Table.Cell>
                    <Table.Cell>This indicates a small effect size, meaning the difference between groups is small and may not be practically significant.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>0.5</Table.Cell>
                    <Table.Cell>Medium</Table.Cell>
                    <Table.Cell>This indicates a medium effect size, suggesting a noticeable difference between groups that is likely to be meaningful.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>0.8</Table.Cell>
                    <Table.Cell>Large</Table.Cell>
                    <Table.Cell>This indicates a large effect size, meaning there is a substantial difference between groups, likely to be of practical significance.</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>&gt; 1.0</Table.Cell>
                    <Table.Cell>Very Large</Table.Cell>
                    <Table.Cell>This indicates a very large effect size, showing an extreme difference between groups, which is highly significant.</Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>

                <Question>
                How is this analysis different than predictive modeling?
                </Question>
                <Answer>
                        <p>
                        Effect size measures the strength of the difference between two groups (e.g., top 25% vs. bottom 25%) based on a specific factor, providing insight into how much one group differs from another. In contrast, a predictive model indicate how much a factor contributes to a combined explanation of the outcome.
                        </p>

                        <p>
                        Use effect size when you want to understand group differences and their impact on an outcome. The factor with the largest effect size will help to identify which culture factors are impacting the extreme scores of the outcome.
                        </p>

                        <p>
                        Use predictions to identify which factors are most influential in predicting that outcome across all data points. Predictive modelling takes into consideration the impact of all factors combined, while effect size analysis focuses on the impact of individual factors.
                        </p>
                </Answer>
        </Container>
    )

}

export default EffectPlot;

const Container = styled.div`
    font-family:"Raleway";
    padding-bottom:50px;
`

const Title = styled.div`
    color: #2d70e2;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;

`

const Description = styled.div`
    margin-bottom:40px;
`

const Question = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:10px;
`

const Answer = styled.div`
    font-size:14px;
    margin-bottom:40px;
    line-height:1.5;
`

const options2 = {
  chart: {
    height: 600,
    type: "rangeBar",
    fontFamily: "Raleway, sans-serif",
    foreColor: "#7E7E7E",
    zoom: {
      enabled: false,
    },
    ...chartDownloadOptions,
  },
  colors: ["#EC7D31", "#36BDCB"],
  plotOptions: {
    bar: {
      horizontal: true,
      isDumbbell: true,
      dumbbellColors: [["#EC7D31", "#36BDCB"]],
    },
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    position: "top",
    horizontalAlign: "left",
    customLegendItems: ["Lower Bound", "Upper Bound"],
  },
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#36BDCB"],
      inverseColors: false,
      stops: [0, 100],
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  xaxis: {
    title: {
      text: "Effect size",
    },
  },
  dataLabels: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
};
