import { GET_STORY_TEMPLATES, CLEAR_ALL } from "../constants/actions";

const initialState = {
  story_templates: [],
  data_stories_pending: [],
  story_templates_error: null,
  pending: false,
};

export default function DataStories(state = initialState, action) {
  switch (action.type) {
    case GET_STORY_TEMPLATES:
      return {
        ...state,
        story_templates: action.payload,
        pending: false,
      };

    case CLEAR_ALL:
      return {
        ...state,
        story_templates: [],
        pending: false,
      };

    default:
      return state;
  }
}
