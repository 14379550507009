import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Leadership from "assets/images/teamwork.svg";

import { Link } from "react-router-dom";
// import ModalLeader from "./ModalLeaderTeams";
// import LeaderList from "./LeaderList";
import SurveyFrequency from "./SurveyFrequency";
import SurveyDate from "./UnUsed/SurveyDate";

import moment from "moment";

import {
  SG_GET_GLOBAL_SCHEDULES,
  PULL_ORGANIZATION,
  SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
  SG_GET_SURVEY_COMPLIANCES,
  SG_DELETE_SURVEY_COMPLIANCE,
  SG_ADD_SURVEY_COMPLIANCE,
  LOAD_TEAMS,
  LOAD_EMPLOYEES,
  SG_GET_LEADERS_IN_SCHEDULES,
  SG_GET_TEAMS_IN_SCHEDULES,
} from "constants/actions";

import {
  Warning,
  Title,
  Title2,
  Title3,
  P,
  P2,
  Line,
  Line2,
  SettingsCard,
  Img,
  View,
  Button,
  Container,
} from "./styled_list";

import { Toggle, SelectFreq } from "./Settings";

const SurveySettings = ({ setProcessing }) => {
  const dispatch = useDispatch();
  const [globalSchedule, setGlobalSchedule] = useState([]);
  const [cultureFrequency, setCultureFrequency] = useState([]);
  const [culture, setCulture] = useState([]);
  const [moodFrequency, setMoodFrequency] = useState([]);
  const [mood, setMood] = useState([]);
  const [leadershipFrequency, setLeadershipFrequency] = useState([]);
  const [leadership, setLeadership] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);

  const [cultureToggle, setCultureToogle] = useState(false);
  const [moodToggle, setMoodToogle] = useState(false);
  const [leadershipToggle, setLeadershipToogle] = useState(false);

  const organization_id = useSelector((state) => state.auth.organization_id);
  const [organization, setOrganization] = React.useState("");

  const [hide, setHide] = React.useState(true);
  const [moodTeam, setMoodTeam] = React.useState([]);

  React.useEffect(() => {
    // if the response is ok 200
    if (organization_id) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organization_id },
      });
    }
  }, [organization_id, dispatch]);

  const { get_organization } = useSelector(
    (state) => ({
      get_organization: state.organizations,
    }),
    shallowEqual
  );

  useEffect(() => {
    // if the response is ok 200
    dispatch({
      type: SG_GET_GLOBAL_SCHEDULES,
    });
    dispatch({
      type: SG_GET_SURVEY_COMPLIANCES,
      payload: "limit=30",
    });
    dispatch({
      type: LOAD_TEAMS,
    });
    dispatch({ type: LOAD_EMPLOYEES, payload: {} });
    dispatch({
      type: SG_GET_LEADERS_IN_SCHEDULES,
    });
    dispatch({
      type: SG_GET_TEAMS_IN_SCHEDULES,
    });
    dispatch({
      type: SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
    });
  }, [dispatch]);

  useEffect(() => {
    if (globalSchedule?.id) {
      dispatch({
        type: SG_GET_GLOBAL_SCHEDULE_FREQUENCIES,
        payload: `global-schedule=${globalSchedule.id}`,
      });
    }
  }, [dispatch, globalSchedule]);

  useEffect(() => {
    if (get_organization) {
      setOrganization(get_organization[organization_id]);
    }
  }, [get_organization, organization_id]);

  const {
    get_selected_teams,
    get_global_schedules,
    get_global_schedule_updated,
    get_survey_compliances,
    get_global_schedule_frequencies,
    get_auth,
  } = useSelector(
    (state) => ({
      get_selected_teams: state.teamsinschedule.teams_in_schedules,
      get_global_schedules: state.surveyschedule.global_schedules,
      get_global_schedule_updated: state.surveyschedule.updated,
      get_global_schedule_frequencies_updated:
        state.surveyschedulefrequency.global_schedule_frequencies.updated,
      get_global_schedule_frequencies:
        state.surveyschedulefrequency.global_schedule_frequencies,
      get_survey_compliances: state.surveycompliance.survey_compliances,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    // setGlobalSettings(get_global_schedules);
    setOrganizationId(Number(get_auth?.organization_id));
    setGlobalSchedule(get_global_schedules);
    if (get_global_schedule_frequencies) {
      setCultureFrequency(
        get_global_schedule_frequencies.filter((gs) => gs.survey_type === 1)[0]
      );
      setMoodFrequency(
        get_global_schedule_frequencies.filter((gs) => gs.survey_type === 2)[0]
      );
      setLeadershipFrequency(
        get_global_schedule_frequencies.filter((gs) => gs.survey_type === 3)[0]
      );
    }

    if (get_survey_compliances) {
      // console.log(get_survey_compliances);
      setCulture(
        get_survey_compliances.filter(
          (gs) => gs.survey_type === 1 && gs.status === 1
        )[0]
      );
      setMood(
        get_survey_compliances.filter(
          (gs) => gs.survey_type === 2 && gs.status === 1
        )[0]
      );
      setLeadership(
        get_survey_compliances.filter(
          (gs) => gs.survey_type === 3 && gs.status === 1
        )[0]
      );
    }
  }, [
    get_global_schedules,
    get_survey_compliances,
    get_global_schedule_frequencies,
    get_auth,
  ]);

  function createUpdateSurveyCompliance(survey_compliance, survey_type_id) {
    if (survey_compliance) {
      dispatch({
        type: SG_DELETE_SURVEY_COMPLIANCE,
        payload: {
          id: survey_compliance.id,
        },
      });
    } else {
      dispatch({
        type: SG_ADD_SURVEY_COMPLIANCE,
        payload: {
          active: true,
          global_schedule: globalSchedule.id,
          survey_type: survey_type_id,
          frequency: 1,
          day: 1,
          hour: 9,
          minute: 0,
          organization: organizationId,
        },
      });
    }
  }

  // List of teams in mood 2 and culture 1
  // console.log(globalSchedule)
  return (
    <div style={{ width: "100%", padding: 10 }}>
      <Title>Leadership Pulse Settings</Title>
      <P>Here is your team survey control centre, comming soon!</P>

      {/* <Dimmer active={processing} /> */}

      {/* need to stlize this one */}
      <Line />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          zIndex: 3,
          justifyContent: "space-between",
        }}
      >
        {hide && (
          <>
            <SettingsCard>
              <Container>
                <Img src={Leadership} style={{ width: 70 }} />
                <div>
                  <Title2>Leadership</Title2>
                  <P2>
                    Track and support your leaders using an evidence-based 360
                    leadership pulse.
                  </P2>

                  <P2>
                    {globalSchedule
                      ? globalSchedule?.last_sent
                        ? `Last Sent date ${moment(
                            globalSchedule.last_sent
                          ).format("DD MMM YYYY - (ddd)")}`
                        : globalSchedule?.starting_from
                        ? `Starting From ${moment(
                            globalSchedule.starting_from
                          ).format("DD MMM YYYY - (ddd)")}`
                        : ""
                      : "Not active"}

                    {leadership && (
                      <div style={{ marginTop: 10 }}>
                        <Title3>
                          <SurveyDate
                            scheduledAt={leadership.scheduled_at}
                            onDay={globalSchedule.day}
                            id={leadership.id}
                          />

                          {/* Next Survey:{" "}
                            {leadership.scheduled_at &&
                              moment(leadership.scheduled_at).format(
                                "DD MMM YYYY - (ddd)"
                              )} */}
                        </Title3>

                        <small>
                          {moment(leadership.scheduled_at).fromNow()}
                        </small>
                      </div>
                    )}
                  </P2>
                  {/*<SurveyCompliances 
                  survey_type={3}
                  trigger={<View>
                    View Past Surveys<Arrow>&#62;</Arrow>
                  </View>}
                  />*/}
                </div>
                <Toggle
                  on={leadershipToggle}
                  onClick={() => createUpdateSurveyCompliance(leadership, 3)}
                />
              </Container>
              <Line2 />
              {leadership && (
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <SelectFreq
                      selected={leadershipFrequency}
                      setProcessing={setProcessing}
                    /> */}
                  <SurveyFrequency
                    selected={leadershipFrequency}
                    setProcessing={setProcessing}
                    scheduledAt={leadership.scheduled_at}
                  />
                  {/* <LeadershipSettings /> */}
                  {/* <ModalLeader
                schedule={leadership}
                organizationId={organizationId}
                trigger={<Button>Manage</Button>}
              /> */}
                  <Link to="/app/team/leader_list">
                    <Button>Manage</Button>
                  </Link>
                </div>
              )}
            </SettingsCard>
          </>
        )}
      </div>
    </div>
  );
};

export default SurveySettings;
