import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Loader, Select } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import CategorizedDropdown from "../DataScience/CategorizedDropdown";
// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import Model from "assets/images/nav/fa6-solid_layer-group.svg";
import Bar from "./Bar";
import Chart from "react-apexcharts";

const create_option_list = (categories, questions, type) => {
  let options = [];

  categories.categories.forEach((item, i) => {
    if (type === 1 || type === 2) {
      options.push({
        text: item.name,
        value: { text: item, type: "categories", id: i },
      });
    }
  });

  if (type === 2) {
    options.push({
      text: "Personality",
      value: { type: "personality", id: null, text: "Personality" },
    });
    options.push({
      text: "Records",
      value: { type: "records", id: null, text: "Records" },
    });
  }

  return options;
};

let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return Math.round(((score1 + score2) / 2) * 100) / 100;
};

const filterInitialCategories = (horizontal, data) => {
  const selectedCategory = horizontal?.type?.id;
  const categoryResponse = horizontal?.value;
  const filteredResponses = data.filter((dataSet) => {
    return dataSet.categories.find(
      (catResponses) =>
        catResponses.id === selectedCategory &&
        catResponses.response === categoryResponse
    );
  });

  return filteredResponses;
};

const retrieveCategoryAverages = (
  filteredResponses,
  unitOfAnalysis,
  records,
  data
) => {
  let values = [];
  if (unitOfAnalysis.type === "categories") {
    unitOfAnalysis.text.options.forEach((op) => {
      const matchingResponses = filteredResponses.filter((fr) => {
        return fr.categories.find(
          (r) => r.id === unitOfAnalysis.text.id && r.response === op.id
        );
      });

      const normResponses = data.filter((fr) => {
        return fr.categories.find(
          (r) => r.id === unitOfAnalysis.text.id && r.response === op.id
        );
      });

      values.push({
        name: op.name,
        id: op.id,
        norm:
          (normResponses.length > 0 &&
            Math.round((normResponses.length / data.length) * 100)) ||
          0,
        percent:
          (matchingResponses.length > 0 &&
            Math.round(
              (matchingResponses.length / filteredResponses.length) * 100
            )) ||
          0,
      });
    });
  } else if (unitOfAnalysis.type === "personality") {
    let personality_factors = [
      "Extraversion",
      "Conscientiousness",
      "Agreeableness",
      "Neuroticism",
      "Openess",
    ];

    personality_factors.forEach((p, i) => {
      const dataArr = filteredResponses.map((item) => {
        return calculate_personality(item?.survey_personality, i);
      });
      const normArr = data.map((item) => {
        return calculate_personality(item?.survey_personality, i);
      });

      let filteredSum = 0;
      dataArr.forEach((v) => {
        filteredSum += v;
      });

      let normSum = 0;
      normArr.forEach((v) => {
        normSum += v;
      });

      values.push({
        name: p,
        id: i,
        norm:
          (normArr.length > 0 && Math.round((normSum / normArr.length) * 10)) ||
          0,
        percent:
          (dataArr.length > 0 &&
            Math.round((filteredSum / dataArr.length) * 10)) ||
          0,
      });
    });
  } else if (unitOfAnalysis.type === "records") {
    const numericalRegex = /[$|€|£]?(\d+\s?[\,?\d{3,}]*\.?\d+)/;

    records.forEach((r) => {
      const dataArr = filteredResponses.map((item) => {
        const val = item.employee_records.find((f) => f.id === r.id)?.value;
        let cleanedVal = val.toString().match(numericalRegex);
        cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
        cleanedVal = cleanedVal.replace(/,/g, "");
        return Number(cleanedVal);
      });

      const normArr = data.map((item) => {
        const val = item.employee_records.find((f) => f.id === r.id)?.value;
        let cleanedVal = val.toString().match(numericalRegex);
        cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
        cleanedVal = cleanedVal.replace(/,/g, "");
        return Number(cleanedVal);
      });

      dataArr.sort((a, b) => a > b && -1);
      normArr.sort((a, b) => a > b && -1);
      const companyHigh = dataArr[0];
      const normHigh = normArr[0];
      const relativePerc = dataArr.map((d) => {
        return (d / companyHigh) * 10;
      });

      const normRelativePerc = normArr.map((d) => {
        return (d / normHigh) * 10;
      });

      let filteredSum = 0;
      relativePerc.forEach((v) => {
        filteredSum += v;
      });
      let normSum = 0;
      normRelativePerc.forEach((v) => {
        normSum += v;
      });

      values.push({
        name: r.name,
        id: r.id,
        norm:
          (normArr.length > 0 && Math.round((normSum / normArr.length) * 10)) ||
          0,
        percent:
          (dataArr.length > 0 &&
            Math.round((filteredSum / dataArr.length) * 10)) ||
          0,
      });
    });
  }

  values.sort((a, b) => a.percent > b.percent && -1);
  return values;
};

const TeamSummary = ({ data, records }) => {
  const dispatch = useDispatch();
  const [horizontal, setHorizontal] = useState();
  const [vertical, setVertical] = useState();
  const [anchor_list, setAnchorList] = useState();
  const [anchor_list2, setAnchorList2] = useState();
  const [variableList, setVariableList] = useState();
  const [analysisVariable, setAnalysisVariable] = useState();
  const [calculated, setCalculated] = useState();
  const [loading, setLoading] = useState();

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({ surveyType: 6, auditTypeId: 3 });

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  });

  useEffect(() => {
    if (vertical?.text?.options) {
      const options = vertical?.text?.options.map((op) => {
        return {
          text: op.name,
          value: { text: op.name, value: op.id, type: vertical?.text },
        };
      });
      setVariableList(options);
      setHorizontal(options[0].value);
    }
  }, [vertical]);

  useEffect(() => {
    if (get_survey_structure[0] && questionStructure) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1
      );
      setAnchorList(anchor_list);
      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2
      );
      setAnchorList2(anchor_list2);
    }
  }, [get_survey_structure[0], questionStructure]);

  useEffect(() => {
    if (
      analysisVariable &&
      horizontal &&
      vertical &&
      records &&
      data &&
      data.length > 0
    ) {
      setLoading(true);
      const filteredResponses = filterInitialCategories(horizontal, data);
      const calculatedData = retrieveCategoryAverages(
        filteredResponses,
        analysisVariable,
        records,
        data
      );

      if (calculatedData.length > 0) {
        setLoading(false);
        setCalculated(calculatedData);
      }
    }
  }, [analysisVariable, horizontal, vertical, records, data]);

  if (!get_survey_structure[0] || !data) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>Summary Analysis</Title>
      <OptionArea>
        <div
          style={{
            marginRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label>Category</Label>
          <div
            style={{
              width: 275,
            }}
          >
            <CategorizedDropdown
              placeholder="Select a Category"
              filterOptions={anchor_list}
              hideLabel
              hideFilter
              value={vertical}
              id="anchors"
              onSelectLabel={(t) => setVertical(t.value)}
            />
          </div>
        </div>

        <div
          style={{
            marginLeft: 30,
            display: "flex",
            alignItems: "center",
            width: 275,
          }}
        >
          <Label>Variable</Label>

          <div
            style={{
              width: 275,
            }}
          >
            <CategorizedDropdown
              placeholder="Select a Variable"
              filterOptions={variableList}
              hideLabel
              hideFilter
              value={horizontal}
              id="anchors"
              onSelectLabel={(t) => setHorizontal(t.value)}
            />
          </div>
        </div>
      </OptionArea>

      <Cards>
        <Card>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Factor Breakdown
          </TableTitle>
          <Sub>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus vitae
            faucibus nisl venenatis, ut etiam proin neque.
          </Sub>
          <Content>
            <Top>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Label>Unit of Analysis</Label>
                <div style={{ width: 275 }}>
                  <CategorizedDropdown
                    placeholder="Select Survey Type"
                    filterOptions={anchor_list2}
                    hideLabel
                    hideFilter
                    value={analysisVariable}
                    id="anchors"
                    onSelectLabel={(t) => setAnalysisVariable(t.value)}
                  />
                </div>
              </div>
              <Legend>
                {calculated && (
                  <>
                    {/* <LegendItem>
                      <Bar1 /> <LText>Team norms</LText>
                    </LegendItem> */}
                    <LegendItem>
                      <Bar2 /> <LText>Industry norms</LText>
                    </LegendItem>
                  </>
                )}
              </Legend>
            </Top>
            <BarArea>
              {calculated && (
                <>
                  {calculated.map((d) => {
                    return <Bar score={[d.percent, d.norm]} title={d.name} />;
                  })}
                </>
              )}
            </BarArea>
          </Content>
        </Card>

        <Card2 width={"380px"}>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Culture Score
          </TableTitle>
          <Sub>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus vitae
            faucibus nisl venenatis, ut etiam proin neque.
          </Sub>
          <Chart
            options={data2.options}
            series={data2.series}
            type="radialBar"
            height={"100%"}
            style={{ marginTop: 0 }}
          />
        </Card2>
      </Cards>
    </Container>
  );
};

export default TeamSummary;

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
  margin-bottom: 30px;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const Card = styled.div`
  width: calc(100% - 400px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 40px 50px 20px;
  margin-right: 20px;
`;

const Card2 = styled.div`
  width: ${(props) => props.width};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 40px 50px 20px;
`;

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TableTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-left: 33px;
`;

const Image = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const Content = styled.div`
  padding-left: 25px;
`;

const BarArea = styled.div`
  margin-top: 40px;
`;

const Bar1 = styled.div`
  width: 20px;
  height: 2px;
  border-bottom: 2px dashed #414141;
`;

const Bar2 = styled.div`
  width: 20px;
  height: 2px;
  border-bottom: 2px solid #414141;
`;

const Legend = styled.div`
  display: flex;
  align-items: center;
`;

const LText = styled.div`
  margin-left: 5px;
  margin-right: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
`;

const data2 = {
  series: [80],
  options: {
    chart: {
      height: 400,
      type: "radialBar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "35%",
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#888",
            fontSize: "17px",
            fontFamily: "Poppins, sans-serif",
          },
          value: {
            formatter: function (val) {
              return parseInt(val);
            },
            color: "#414141",
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
            show: true,
          },
        },
      },
    },
    fill: {
      colors: ["#476DFA"],
    },
    stroke: {
      lineCap: "round",
    },
  },
};
