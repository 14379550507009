import React,{useState} from 'react';
import styled from 'styled-components';
import {Modal,Dropdown} from 'semantic-ui-react'



const LabelModal = () =>{
    const [open,setOpen] = useState(false)

    return (
        <Modal
        open={open}
        onClose={()=>setOpen(false)}
        trigger={<Add onClick={()=>setOpen(true)}>Create a new report</Add>}
        size='small'
        >
            <Modal.Content>
                <Title>
                    Create a new report
                </Title>

            <Description>
                Select the factors you'd like to generate a best practices report on.
            </Description>

            <Row>
            <SelectContainer>
            <T2>Data Factor</T2>

                <Dropdown 
                        placeholder='Select a factor'
                        fluid
                        selection
                        options={friendOptions}/>
            </SelectContainer>    
           
            <SelectContainer>
            <T2>Data Category</T2>
                <Dropdown 
                        placeholder='Select a category'
                        fluid
                        selection
                        options={friendOptions2}/>
             </SelectContainer> 
            </Row>


                <ButtonContainer>
                    <Button primary onClick={()=>setOpen(false)}>Create</Button>
                </ButtonContainer>
            </Modal.Content>
        </Modal>
    )
}

export default LabelModal

const LabelInput = styled.input`
    width:100%;
    height:40px;
    border-radius:5px;
    border:1px solid #ccc;
    padding:10px;
    margin-bottom:20px;
    font-family:'Raleway';
`
const ButtonContainer = styled.div`
    width:100%;
    margin-top:30px;
    display:flex;
    justify-content:flex-end;
`
const Button = styled.div`
    background:blue;
    color:white;
    padding:5px 10px;
    border-radius:5px;
    cursor:pointer;
    font-family:'Raleway';
    font-size:14px;
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:20px;
    font-family:'Raleway';

`

const T2 = styled.div`
    font-size:14px;
    font-weight:bold;
    font-family:'Raleway';
    margin-bottom:5px;
`

const Row = styled.div`
    display:flex;
    margin-bottom:40px;
`


const Description = styled.div`
font-family:'Raleway';
margin-bottom:5px;
`

const SelectContainer = styled.div`
    width:300px;
    margin-right:30px;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #ccc;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    margin-bottom:20px;
`

const Add = styled.div`
    color:blue;
    cursor:pointer;
    margin-bottom:20px;
`

const friendOptions = [
    {
      key: 'Culture',
      text: 'Culture',
      value: 'Culture',
    },
    {
      key: 'Retention',
      text: 'Retention',
      value: 'Retention',
    },
    {
      key: 'Well-Being',
      text: 'Well-Being',
      value: 'Well-Being',
    },
    {
        key: 'Engagament',
        text: 'Engagament',
        value: 'Engagament',
      },
      {
        key: 'Customer Satisfaction',
        text: 'Customer Satisfaction',
        value: 'Customer Satisfaction',
      },
      {
        key: 'Store Size',
        text: 'Store Size',
        value: 'Store Size',
      }
  ]


  const friendOptions2 = [
    {
      key: 'Store Locations',
      text: 'Store Locations',
      value: 'Store Locations',
    },
    {
      key: 'Departments',
      text: 'Departments',
      value: 'Departments',
    },
    {
      key: 'Franchises',
      text: 'Franchises',
      value: 'Franchises',
    }
  ]