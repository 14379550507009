import styled from "styled-components";
import React from "react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const AreYouReady = (props) => {
  const slideData = props?.activeJourney?.activeJourney?.response?.data_slide;

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        Are you ready to start your path to improving {slideData?.factor_name}?
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Research shows that culture is changeable, and there are many ways to
        improve it. Here are some suggestions for how to get started. We've
        curated a few resources for you to take a deeper dive into each topic.
      </SubtextExtended>
    </ScreenContainer>
  );
};

export default AreYouReady;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;
