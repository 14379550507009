import React, { useState, useCallback, useRef,useEffect } from 'react';
import { Editor, EditorState, RichUtils, Modifier, convertFromRaw,convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from 'styled-components';
import AIEditModal from './CommentModal';
import Toolbar from './Toolbar';
import { markdownToDraft,draftToMarkdown } from 'markdown-draft-js';

const EditorContainer = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 200px;
  margin-bottom: 10px;
  font-family:'Raleway';
`;


const RichTextEditor = () => {
  
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [showAIEditModal, setShowAIEditModal] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [selectionState, setSelectionState] = useState(null);
    const editorRef = useRef(null);
  
    const handleKeyCommand = (command) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      }
      return 'not-handled';
    };
  
    const getSelectedText = () => {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const startKey = selection.getStartKey();
        const endKey = selection.getEndKey();
        const startOffset = selection.getStartOffset();
        const endOffset = selection.getEndOffset();
        const blockMap = contentState.getBlockMap();
      
        // Filter the selected blocks and create a new ContentState with only those blocks
        const selectedBlocks = blockMap
          .skipUntil((_, key) => key === startKey)
          .takeUntil((_, key) => key === endKey)
          .concat([[endKey, blockMap.get(endKey)]])
          .map((block) => {
            const key = block.getKey();
            const text = block.getText();
      
            if (key === startKey && key === endKey) {
              // Single block selection
              return block.set('text', text.slice(startOffset, endOffset));
            } else if (key === startKey) {
              // Start block
              return block.set('text', text.slice(startOffset));
            } else if (key === endKey) {
              // End block
              return block.set('text', text.slice(0, endOffset));
            } else {
              // Middle blocks
              return block;
            }
          });
      
        // Create a new ContentState with only the selected blocks
        const selectedContentState = contentState.set('blockMap', selectedBlocks);
      
        // Convert the selected content state to raw JS object
        const rawContentState = convertToRaw(selectedContentState);
      
        // Convert the raw content state to markdown
        const markdown = draftToMarkdown(rawContentState);
      
        console.log(markdown);
        return markdown;
      };
      
  
    const handleAskAI = () => {
      const text = getSelectedText();
      setSelectedText(text);
      if (text) {
        setShowAIEditModal(true);
      }
    };

    useEffect(()=>{
        const markdownString =`Culture Improvement Plan Draft:

1. **Assessment Phase:**
   - Conduct employee surveys and focus groups to identify current cultural strengths and areas needing improvement.
   - Analyze HR metrics such as turnover rates, employee engagement scores, and absenteeism.

2. **Vision and Values Alignment:**
   - Revisit and communicate the company’s vision and core values.
   - Ensure all team members understand and align with these values through workshops and training sessions.

3. **Leadership Development:**
   - Implement leadership training programs focused on fostering a positive culture.
   - Encourage leaders to model desired behaviors and provide them with tools to mentor others.

4. **Employee Involvement:**
   - Create platforms for employees to voice their opinions, ideas, and concerns.
   - Form committees or task forces to address cultural issues and develop initiatives.

5. **Recognition and Reward Systems:**
   - Develop systems to regularly acknowledge and reward behaviors that promote the desired culture.
   - Celebrate cultural milestones and achievements with company-wide events.

6. **Communication Strategies:**
   - Establish transparent and consistent channels of communication between all levels of the organization.
   - Use regular updates, newsletters, and town hall meetings to keep employees informed and engaged.

7. **Performance Management:**
   - Incorporate cultural objectives into performance reviews.
   - Set clear expectations and provide constructive feedback to enhance cultural alignment.

8. **Training and Development:**
   - Offer ongoing training programs related to cultural competence, diversity, and inclusion.
   - Encourage continuous learning to adapt to and embrace cultural changes.

9. **Monitoring and Evaluation:**
   - Set measurable goals and track progress through regular assessments.
   - Adjust strategies as necessary based on feedback and data analysis.

10. **Sustainability Plan:**
    - Ensure the culture improvement initiatives are sustainable by embedding them into the company’s policies and practices.
    - Regularly revisit and refine the culture improvement plan to adapt to changing organizational needs.`
        const rawData = markdownToDraft(markdownString);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
    },[])

  // Function to replace highlighted section with pre-determined markdown string
function replaceHighlightedSectionWithMarkdown(editorState, markdownString) {
    // Get the current content and selection state
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
  
    // Convert markdown string to draft raw content
    const rawContent = markdownToDraft(markdownString);
    const markdownContentState = convertFromRaw(rawContent);
  
    // Get the block map from markdown content
    const blockMap = markdownContentState.getBlockMap();
  
    // Replace selected text with new content
    const newContentState = Modifier.replaceWithFragment(
      contentState,
      selectionState,
      blockMap
    );
  
    // Update the editor state with the new content
    const newEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');
  
    return newEditorState;
  }
  
    const saveSuggestedEdit = (suggestedEditMarkdown) => {

        const newEditorState = replaceHighlightedSectionWithMarkdown(editorState, suggestedEditMarkdown);
        setEditorState(newEditorState);
      
        setShowAIEditModal(false);
        // focusEditor();
      };

      const preserveSelection = useCallback(
        (callback) => {
          const currentSelection = editorState.getSelection();
          callback();
          setSelectionState(currentSelection);
          setEditorState((prevState) => {
            const restoredEditorState = EditorState.forceSelection(prevState, currentSelection);
            return restoredEditorState;
          });
        },
        [editorState]
      );
    
  
    const focusEditor = () => {
      if (editorRef.current) {
        editorRef.current.focus();
      }
    };
  
    const styleMap = {
      HIGHLIGHT: {
        backgroundColor: 'yellow',
      },
    };
  
  
    return (
      <Container>
        <ButtonArea>
        <Button onClick={handleAskAI}>Edit with AI</Button>
        <Button onClick={handleAskAI}>Spark some ideas</Button>
        <Button onClick={handleAskAI}>Give me suggestions</Button>
        <Button onClick={handleAskAI}>Evaluate my plan</Button>
        </ButtonArea>
         
       <Toolbar
        editorState={editorState}
        setEditorState={setEditorState}
        preserveSelection={preserveSelection}
      />
      <EditorContainer onClick={focusEditor}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          customStyleMap={styleMap}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
        />
      </EditorContainer>
     
      {showAIEditModal && (
        <AIEditModal
          selectedText={selectedText}
          onSave={saveSuggestedEdit}
          onCancel={() => {
            setShowAIEditModal(false);
            focusEditor();
          }}
        />
      )}

      </Container>
    );
  };
  
  export default RichTextEditor;


const Container = styled.div`
    margin-top:50px;
    padding:20px;
    font-family:"Raleway";
`

const ButtonArea = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-bottom:20px;
`

const Button = styled.button`
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 10px;
    font-family:"Raleway";
    font-size:14px;

    &:hover {
        background-color: #0056b3;
    }
`;