import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../../transitions.css"; // Import your CSS for transitions
import Action from "./Action";
import Need from "./Need";
// import Objective from "./Objective";
import Context from "./Context";
import CTA from "./CTA";

const JourneyPath = (props) => {
  const { journeyPath } = props;

  const renderContent = (path) => {
    // Use a case statement to determine which component to render
    switch (path.toLowerCase()) {
      case "need":
        return <Need {...props} />;
      case "action":
        return <Action {...props} />;
      case "context":
        return <Context {...props} />;
      case "cta":
        return <CTA {...props} />;
      default:
        return <Need {...props} />;
    }
  };

  return renderContent(journeyPath);
};

export default JourneyPath;
