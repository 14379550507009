import styled from "styled-components";
// import React from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import DataRanking from "./DataRanking";
// import { overall, average_cat } from "./data_navigation/calculate_data";

// const old_color = "rgba(227, 176, 31,0.2)";

const LegendStyle = (props) => {

  return (
    <Container>
      <Row
        onClick={() =>
          props.onClick(props.selected == props.i ? null : props.i)
        }
        hover={
          props.highlight === null
            ? true
            : props.highlight == props.i
            ? true
            : false
        }
        selected={props.selected == props.i}
        onMouseEnter={() => props.hover(props.i)}
        onMouseLeave={() => props.hover(null)}
      >
        
        <Title>
	        <Circle color={props.data.color} />
	        {props.title.name}
        </Title>
        <Score>
          <div>{props.data.summary[0].toFixed(1)}</div>
          <Sub>{props.dimensions!=1?props.questions.dimensions[0].title : ''}</Sub>
        </Score>
        {props.dimensions!=1?
        	<Score>
	          <div>{props.data.summary[1].toFixed(1)}</div>
	          <Sub>{props.questions.dimensions[1].title}</Sub>
	        </Score>
	        :""}
      </Row>

      <Details selected={props.selected == props.i}>
        {/*				<Title2>Survey completion</Title2>
				<Dated>March 4th, 2020</Dated>*/}
        <Title2>Culture Factor Ranking</Title2>
        <DataRanking data={props.data} color={props.color} dimensions={props.dimensions}/>
      </Details>
    </Container>
  );
};

export default LegendStyle;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${(props) => props.color || "green"} !important;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 5px 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const Title = styled.div`
  width: 200px;
  display:flex;
  flex-direction:row;
  align-items:center;
`;

const SubTitle = styled.div`
  width: 200px;
`;

const Title2 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const Title3 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: #696969;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Row2 = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Score = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  height:40px;
`;

const Culture = styled.div``;

const Sub = styled.div`
  font-size: 10px;
  font-weight: 400;
`;

const Circle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: ${(props) => props.color};
  margin-right:10px;
`;

const Details = styled.div`
  max-height: ${(props) => (props.selected ? "1000px" : "0")};
  transition: all 0.5s;
  overflow: hidden;
  padding-top: ${(props) => (props.selected ? "10px" : "0")};
`;

const Dated = styled.div`
  margin-bottom: 20px;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Button = styled.div`
  background-color: #3078ff;
  padding: 5px 10px;
  color: white;
`;
