import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
	Modal,
    Dropdown,
} from "semantic-ui-react";
import Chart from "react-apexcharts";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { injectDownloadOptions } from "constants/chartOptions";



const IndividualModal = ({open,setOpen,data,selected}) => {

    const selectedData = data.questionSeries[selected.item][selected.question]

    // Helper function to convert quarter strings to dates for sorting
  const quarterToDate = (q) => {
    const [quarter, year] = q.split(' ');
    const qNum = Number(quarter.substring(1));
    return new Date(Number(year), (qNum - 1) * 3);
  };

  // Prepare data for the longitudinal chart
  const averageScoreData = selectedData['Average Score']
    .filter(item => item.y !== null)
    .sort((a, b) => quarterToDate(a.x) - quarterToDate(b.x));

  const longitudinalCategories = averageScoreData.map(item => item.x);
  const longitudinalSeries = [
    {
      name: 'Average Score',
      data: averageScoreData.map(item => Math.round(item.y * 10)), // Convert to percentage and remove decimals
    },
  ];

  // Prepare data for the distribution chart
  const distributionData = selectedData['Distribution']
    .filter(item => item.y !== null)
    .sort((a, b) => quarterToDate(a.x) - quarterToDate(b.x));

  const distributionDates = distributionData.map(item => item.x);
  const [selectedDate, setSelectedDate] = useState(distributionDates[distributionDates.length - 1]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const selectedDistributionData = distributionData.find(item => item.x === selectedDate);

  const distributionSeries = [
    {
      name: 'Number of Respondents',
      data: selectedDistributionData.y,
    },
  ];

  const distributionCategories = [...Array(11).keys()].map(String); // '0' to '10'

  // Chart options for the longitudinal chart
  const longitudinalOptions = {
    chart: {
      type: 'line',
      fontFamily: 'Raleway, sans-serif',
      toolbar: {
        show: false, // Disable chart interactions
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    colors: ['#2D70E2'],
    xaxis: {
      categories: longitudinalCategories,
      title: {
        text: 'Date',
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      tooltip: {
        enabled: false, // Disable tooltip on x-axis
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        formatter: function (val) {
          return val + '%'; // Add percentage sign and remove decimals
        },
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      title: {
        text: 'Average Score (%)',
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%'; // Add percentage sign
      },
      style: {
        fontFamily: 'Raleway, sans-serif',
      },
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
    stroke: {
      curve: 'straight',
    },
    markers: {
      hover: {
        size: 0,
      },
    },
    grid: {
      show: false,
    },
  };

  // Chart options for the distribution chart
  const distributionOptions = {
    chart: {
      type: 'bar',
      fontFamily: 'Raleway, sans-serif',
      toolbar: {
        show: false, // Disable chart interactions
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    colors: ['#27CDA7'],
    xaxis: {
      categories: distributionCategories,
      title: {
        text: 'Score',
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
      title: {
        text: 'Number of Respondents',
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels on bars
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
    plotOptions: {
      bar: {
        distributed: false,
      },
    },
    grid: {
      show: false,
    },
  };

  const dateOptions = distributionDates.map(date => ({
    key: date,
    text: date,
    value: date,
  }));
	

	return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      size={"large"}
    >
      <Modal.Header style={{ padding: 30 }}>
        <Blue>{selected.item}</Blue>
        {selected.question}
      </Modal.Header>

      <Content>
        <div style={{ fontFamily: "Raleway, sans-serif" }}>
          <T1>Average Scores Over Time</T1>
          <Description>
            This chart shows the average scores for the selected question over
            time.
          </Description>
          <Chart
            options={injectDownloadOptions(longitudinalOptions)}
            series={longitudinalSeries}
            type="line"
            height={350}
          />

          <T1>Distribution of Scores</T1>
          <Description>
            This chart shows the distribution of scores for the selected
            question on the selected date.
          </Description>
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <label
              htmlFor="dateSelect"
              style={{ marginRight: "10px", fontWeight: "bold" }}
            >
              Select Date:
            </label>
            <Dropdown
              id="dateSelect"
              selection
              options={dateOptions}
              value={selectedDate}
              onChange={(e, { value }) => setSelectedDate(value)}
              style={{ minWidth: "200px" }}
            />
          </div>

          <Chart
            options={distributionOptions}
            series={distributionSeries}
            type="bar"
            height={350}
          />
        </div>
      </Content>
    </Modal>
  );
};

export default IndividualModal;

const Content = styled.div`
    padding:30px;
    padding-top:10px;
    font-family: 'Raleway', sans-serif;
`

const T1 = styled.div`
    font-size:20px;
    margin:20px 0;
    font-weight:bold;
`

const Description = styled.div`
    font-size:14px;
    margin-bottom:20px;
`

const Blue = styled.div`
    color:#2D70E2;
    font-weight:bold;
    font-size:14px;
    margin-bottom:5px;
    margin-top:-10px;

    `