import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Loading from './Loading'
import OverallScore from './Components/overall_score';
import FactorScore from './Components/factor_score';
import FactorExplain from './Components/factor_explain';
import FactorExplainAccountability from './Components/factor_explain_accountability';
import Summary from './Components/summary';
import Outcomes from './Components/outcomes';
import NextSteps from './Components/next_steps';
import Participation from './Components/participation';
import Video from './Components/video';
import MultipleFactors from './Components/multiple_factor_scores';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'
import Image from 'assets/images/stock/intro.jpg'

const Content = ({step,setStep,data,back,total_steps}) =>{
    const navigate = useNavigate();
    const [loading,setLoading] = useState(false)
    const { addToast } = useToasts()

    const next = () =>{

        if(step+1 == total_steps){
            return back()
        }
        setLoading(true)
        setStep(step+1)
        setTimeout(()=>{
            setLoading(false)
        },500)
    }

    const stepBack = () =>{
        setLoading(true)
        setStep(step-1)
        setTimeout(()=>{
            setLoading(false)
        },500)
    }

    const finish = () =>{
        addToast("You've successfully read your data story", {
            appearance: 'success',
            autoDismiss: true,
          })
        navigate('/simple/prototype/simpledash')
    }

    if(loading){
        return <Loading/>
    }



    const get_content = () =>{
        switch(data.type){
            case 'overall_score':
                return <OverallScore data={data}/>
            case 'lowest_scoring_factor':
                return <FactorScore data={data}/>
            case 'highest_scoring_factor':
                return <FactorScore data={data}/>
            case 'factor_explain':
                return <FactorExplain data={data}/>
            case 'factor_explain_accountability':
                return <FactorExplainAccountability data={data}/>
            case 'summary':
                return <Summary data={data}/>
            case 'outcomes':
                return <Outcomes data={data}/>
            case 'next':
                return <NextSteps data={data} next={next}/>
            case 'participation':
                return <Participation data={data}/>
            case 'video':
                return <Video data={data}/>
            case 'multiple_factor_scores':
                return <MultipleFactors data={data}/>
            default:
                return (
                    <>
                        <Title>
                       Introduction
                    </Title>
                    <IMG src={Image}/>
                    <Description>
                    <strong>Welcome to your innerlogic portal!</strong> Use this section to navigate your department's people data with ease.	
                    As a leader, <strong>you can drive meaningful change</strong> with the following insights guiding you toward purposeful action.
                    Let's get started on the next steps, delving deeper into your department's data story.
                    </Description>

                    <Description>
                    

                    </Description>

                    <Description>

                    </Description>
                   </>
                )
        }

    }



    return (

        <Container>
           {get_content()}

            <ButtonContainer>
                    {step>0 && <Button onClick={()=>stepBack()}>Back</Button>}
                {step+1<total_steps ? <Button onClick={()=>next()}>{step==0?'Get Started':'Next'}</Button>:
                <Button onClick={()=>finish()}>Complete</Button>}
            </ButtonContainer>


        </Container>
    )

}

export default Content



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';

    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
    
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:20px;
`

const Description = styled.div`
    line-height:1.6;
    font-weight:400;
    font-size:18px;
    margin-bottom:20px;
    color:#2A3039;
`


const Button = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    margin-top:30px;
`

const IMG = styled.img`
    width:100%;
    margin-bottom:20px;
`