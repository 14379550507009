import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

const Loading = () => {


    return (
        <Container>
            <Loader active inline='centered' />
            <T2>Please wait</T2>
            <T1>Generating Pulse Survey</T1>
        </Container>

    )

}


export default Loading;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
    font-family: 'Raleway';
`

const T1 = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    color: #2D70E2;
`

const T2 = styled.div`
    font-size: 18px;
    font-weight: 500;
    color:#2A3039;
    margin-top:30px;
`