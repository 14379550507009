import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

const Plan = ({ data, setData }) => {
  const setValue = (id, text) => {
    const _data = Object.assign({}, data);
    _data.planData[id] = text;
    setData(_data);
  };

  return (
    <Container>
      <Top>Culture Pillar </Top>
      <Question>{data.metric.factor?.id}</Question>
      <T1>Creating Urgency:</T1>
      <Area>
        <Q>
          Why is this a meaningful measure to improve? What are the benefits of
          improving in this culture area?
        </Q>
        <Text
          placeholder="Write plan here..."
          value={data.planData[0]}
          onChange={(e) => setValue(0, e.target.value)}
        />
      </Area>

      <Area>
        <Q>
          What do you predict is going to happen if this doesn’t change? What is
          the cost of not improving in this area?
        </Q>
        <Text
          placeholder="Write plan here..."
          value={data.planData[1]}
          onChange={(e) => setValue(1, e.target.value)}
        />
      </Area>

      <T1>Possible causes:</T1>
      <Area>
        <Q>
          What do you believe may be leading to the current level of performance
          in this area within your culture?
        </Q>
        <Text
          placeholder="Write plan here..."
          value={data.planData[2]}
          onChange={(e) => setValue(2, e.target.value)}
        />
      </Area>

      <T1>Action plan:</T1>
      <Area>
        <Q>
          List up to 3 strategies or behaviors that will be implemented,
          introduced, or changed in your environment to improve this culture
          area.
        </Q>
        <TextArea>
          <T2>Strategy #1</T2>
          <Text2
            placeholder="Write strategy here..."
            value={data.planData[3]}
            onChange={(e) => setValue(3, e.target.value)}
          />

          <T2>Strategy #2</T2>
          <Text2
            placeholder="Write strategy here..."
            value={data.planData[4]}
            onChange={(e) => setValue(4, e.target.value)}
          />

          <T2>Strategy #3</T2>
          <Text2
            placeholder="Write strategy here..."
            value={data.planData[5]}
            onChange={(e) => setValue(5, e.target.value)}
          />
        </TextArea>
      </Area>
    </Container>
  );
};

export default Plan;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  font-family: "Barlow", sans-serif;
  margin-bottom: 60px;
`;

const Top = styled.div`
  font-size: 12px;
  color: lightgrey;
  text-transform: uppercase;
`;

const Title = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;
`;

const T1 = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const T2 = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  margin-top: 10px;
`;

const Q = styled.div`
  font-size: 18px;
  line-height: 1.4;
  font-weight: 300;
  width: 100%;
  margin-right: 20px;
`;

const Text = styled.textarea`
  width: 100%;
  height: 150px;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  resize: none;
  border-radius: 3px;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
  margin-top: 10px;
`;

const Text2 = styled.textarea`
  width: 100%;
  height: 150px;
  font-family: "Barlow", sans-serif;
  padding: 10px;
  margin-bottom: 10px;
  resize: none;
  border-radius: 3px;
  border: 2px solid #ccc;
  background-color: #f8f8f8;
`;

const Text3 = styled.textarea`
  width: 100%;
  height: 30px;
  font-family: "Barlow", sans-serif;
  padding: 5px;
  margin-bottom: 20px;
`;

const Area = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Question = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  margin-bottom: 30px;
  font-size: 20px;
`;
const TextArea = styled.div`
  width: 100%;
`;
