import React, { useState } from "react";
import styled from "styled-components";

const Textbox = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [textContent, setTextContent] = useState();
  return (
    <TextboxContainer
      hideBorders={props.hideBorders}
      altBg={props.hideBorders || props.altBg}
    >
      {props.title && <Title>{props.title}</Title>}
      {props.subtitle && <Subtitle>{props.subtitle}</Subtitle>}
      {(props?.editEnabled && (
        <InputWrapper
          altBg={props.altBg}
          hideBorders={props.hideBorders}
          isFocused={!props.hideBorders && isFocused}
          inTable={props.inTable}
        >
          <Input
            placeholder={props.placeholder}
            onInput={(e) => {
              setTextContent(e.target.textContent);
            }}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              props.onChange(textContent, props.id);
              setIsFocused(false);
            }}
            editEnabled={props.editEnabled}
            contentEditable={true}
          >
            {props.value || props.data?.planData?.[props.id]}
          </Input>
        </InputWrapper>
      )) ||
        (props?.isTable && (
          <UneditableTextContainer>
            <CommentsTitle>Comments</CommentsTitle>
            <TextWrapper>{props.value}</TextWrapper>
          </UneditableTextContainer>
        )) || (
          <TextWrapper>
            {props.value || props.data?.planData?.[props.id]}
          </TextWrapper>
        )}
    </TextboxContainer>
  );
};

const Title = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const UneditableTextContainer = styled.div`
  border-radius: 8px;
  border: 1px solid;
  border-color: #e0e0e0;
`;

const CommentsTitle = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  padding: 10px;
`;

const Subtitle = styled.div`
  font-family: Raleway;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const TextWrapper = styled.div`
  background-color: #f8faff;
  border-radius: 5px;
  min-height: 80px;
  max-height: 100px;
  padding: 10px;
`;

const TextboxContainer = styled.div`
  width: 100%;
  padding-top: ${(props) => (props.altBg ? "0px" : "20px")};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  margin-bottom: ${(props) => (props.hideBorders ? "0px" : "40px")};
  height: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: ${(props) => (props.altBg ? "0px" : "8px")};
  border: ${(props) => (props.hideBorders ? "0px" : "1px solid")};
  border-color: ${(props) =>
    props.isFocused && !props.altBg ? "#2D70E2" : "#e0e0e0"};
  padding: ${(props) => (props.inTable ? "0px" : "16px")};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  width: 100%;
`;

const Input = styled.span`
  background-color: transparent;
  border: none;
  color: #2a3039;
  padding: 0 5px; /* Remove padding-top and padding-bottom */
  outline: none;
  resize: none;
  overflow: auto;
  line-height: 24px; /* Adjust the line-height to match the desired initial height */
  font-family: Raleway;
  font-size: 14px;
  &::placeholder {
    color: #666d79;
    font-size: 14px;
  }

  display: block;
  width: 100%;
  overflow: hidden;
  min-height: 90px;
  line-height: 20px;

  &:empty::before {
    content: "Enter details";
    color: gray;
  }
`;

export default Textbox;
