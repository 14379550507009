import styled, { keyframes } from "styled-components";
import React from "react";
import SelectItem from "../../../components/common/SelectItem";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const stepData = [
  {
    id: 1,
    title: "Lack of psychological safety and trust in expressing concerns",
    item: "The feedback indicates that some employees feel hesitant to bring up problems or provide candid feedback due to fear of negative consequences or avoidance by leadership. This suggests a need for creating a culture of psychological safety where employees feel comfortable and supported in identifying problems and expressing genuine concerns without fear of retribution or dismissal.",
    nextPath: "action",
  },
  {
    id: 2,
    title: "Inconsistent communication and feedback channels",
    item: "The feedback also highlights inconsistencies in how feedback is received and addressed by different individuals within the organization. Some employees feel that sharing with colleagues is generally safe, but sharing with higher-level leaders is met with avoidance or anger. This indicates a need for establishing clear and consistent communication and feedback channels throughout the organization, ensuring that all employees, regardless of their position, feel heard and valued when expressing their opinions or concerns.",
    nextPath: "action",
  },
];

const Need = (props) => {
  const { onActionSelect } = props;
  const [selected, setSelected] = React.useState();
  const needsArr = props?.activeJourney?.activeJourney?.response?.needs;

  // Parse the needs array and create a new array of objects
  // each object should have the following structure:
  // { id: 1, title: "Clarification of Organizational Direction", description: "Employees are indicating uncertainty about the company's strategic objectives and their role in achieving them. There is a need for a more transparent and well-communicated vision that connects individual contributions to the organization's success." }
  const parsedNeeds = needsArr.map((need, index) => {
    return {
      id: index,
      title: need.split(":")[0],
      description: need.split(":")[1].trim(),
      type: "need",
    };
  });

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        Which culture need would you like to focus on?
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Based on your data, we have identified areas of your culture that can be
        improved. Select an area to begin setting a plan to address it.
      </SubtextExtended>
      <SelectArea delay={1}>
        {parsedNeeds.map((item, index) => (
          <SelectItem
            key={index}
            selected={selected == item.id}
            setSelected={() => {
              onActionSelect(item);
              setSelected(item.id);
            }}
            title={item.title}
            item={item.description}
          />
        ))}
      </SelectArea>
    </ScreenContainer>
  );
};

export default Need;


const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SelectArea = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;
