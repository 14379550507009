import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Button, Header, Image, Modal,Dropdown } from 'semantic-ui-react'


const Create = ({trigger}) => {
    const [open, setOpen] = React.useState(false)
    return (
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={trigger}
      >
        <Modal.Header>Select your model variable</Modal.Header>
        <Modal.Content image>
          <Image size='medium' src='https://react.semantic-ui.com/images/avatar/large/rachel.png' wrapped />
          <Modal.Description>
            <Header>Select which model parameters would you like to include.</Header>
            
            <Text>Available outcome measures</Text>
            <Dropdown
                placeholder='Select outcome'
                fluid
                selection
                options={friendOptions}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='grey' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            content="Build model"
            labelPosition='right'
            icon='checkmark'
            onClick={() => setOpen(false)}
            positive
          />
        </Modal.Actions>
      </Modal>
    )
}

export default Create

const Text = styled.div`
    font-size:14px;
    margin-bottom:10px;
    font-family: "Poppins", sans-serif;
`

const friendOptions = [
    {
      key: 'Retention',
      text: 'Retention',
      value: 'Retention',
    },
    {
      key: 'Well-being',
      text: 'Well-being',
      value: 'Well-being',
    },
    {
      key: 'Safety Incident Rate',
      text: 'Safety Incident Rate',
      value: 'Safety Incident Rate',
    }
  ]
  