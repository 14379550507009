import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

const dates =  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const Timeline = () => {

    return (
        <Container>
            <TopTitle>Periodization of culture optimization</TopTitle>
            <Top>
               {dates.map((date, index) => {
                     return (
                          <Month key={index}>
                            {date}
                            <Line/>
                          </Month>
                     )
               })}

               <Current left={2.2*8.33}>
                    <Ball/>
               </Current>
            </Top> 

            <Items>
               <Item title={'Culture Diagnostics'} width={3} start={0} i={0}/>
               <Item title={'Culture Design'} width={2} start={3} i={1}/>
               <Item title={'Culture Implementation'} width={4} start={4.5} i={2}/>
               <Item title={'Debriefs'} width={3} start={8} i={3}/>
            </Items>
           
        </Container>
    )

    }

export default Timeline;


const Item = ({title,width,start,i}) =>{
    return (
        <ItemContainer width={width*8.33} left={start*8.33} i={i}>
                <Title>{title}</Title>
                <Description>Jan 12 - Mar 30th</Description>
        </ItemContainer>
    )
}

const Container = styled.div`
    width:900px;

`

const TopTitle = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:40px;
    font-family: 'Poppins', sans-serif;

`

const Month = styled.div`
    width:8.33%;
    font-size:12px;
    font-weight:bold;
    display:flex;
    justify-content:center;
    position:relative;
`

const Top = styled.div`
    display:flex;
    position:relative;
`

const Items = styled.div`
    width:100%;
    position:relative;

`

const ItemContainer = styled.div`
    width:${props => props.width}%;
    position:absolute;
    left:${props => props.left}%;
    top:${props => props.i*75}px;
    background-color:rgba(81, 103, 221,0.3);
    padding:10px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    font-family: 'Poppins', sans-serif;
    border-radius:5px;
    margin-top:10px;
    color:#3a4aa1;
`

const Title = styled.div`
    font-size:12px;
    font-weight:600;
`

const Description = styled.div`
    font-size:12px;
`

const Line = styled.div`
    height:300px;
    width:1px;
    border-left:1px dashed lightgrey;
    position:absolute;
    right:0px;
    top:15px;
`

const Current = styled.div`
    position:absolute;
    left:${props => props.left}%;
    height:330px;
    width:1px;
    border-left:2px solid #5157DD;
    top:-15px;

`

const Ball = styled.div`
    height:15px;
    width:15px;
    border-radius:50%;
    background-color:#5157DD;
    margin-left:-8px;
    margin-top:-5px;

`