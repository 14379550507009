import { erf } from "mathjs";

/**
 * Calculations
 */
export const getStandardDeviation = (groupHistory) => {
  const historyAverages = [];
  groupHistory.forEach((group) => {
    historyAverages.push(...group.distribution);
  });

  const n = historyAverages.length;
  if (n === 0) {
    return 0;
  }
  const mean = historyAverages.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    historyAverages.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
      n
  );
};

// Gets the standard deviation of a single set
export const getStandardDeviationSingle = (group) => {
  const n = group.distribution.length;
  if (n === 0) {
    return 0;
  }

  const mean = group.distribution.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    group.distribution
      .map((x) => Math.pow(x - mean, 2))
      .reduce((a, b) => a + b) / n
  );
};

export const getArrayAverage = (array) => {
  const n = array.length;
  if (n === 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;

  return mean;
};

export const zptile = (zScore) => {
  return 0.5 * (erf(zScore / 2 ** 0.5) + 1);
};

// ? Curious about my interpretation here.
export const getPercentile = (targetGroup, entireGroup) => {
  let zScore =
    (getArrayAverage(targetGroup) - Number(entireGroup.avg)) /
    getStandardDeviationSingle(entireGroup);
  return Math.floor(zptile(zScore) * 100) || 0;
};

export const getGroupAverage = (groupResponses) => {
  let groupAvg = [];
  groupResponses.forEach((group) => {
    groupAvg.push(Number(group.avg));
  });

  return groupAvg.reduce((a, b) => a + b) / groupAvg.length;
};

function getStandardDeviationES(array) {
  const n = array.length;
  if (n === 0) {
    return 0;
  }
  const mean = array
    .flat()
    .filter((set) => set.length > 0)
    .map((set) => set.reduce((a, b) => a + b) / set.length);

  return Math.sqrt(
    array
      .flat()
      .map((x) => Math.pow(x - mean, 2))
      .reduce((a, b) => a + b) / n
  );
}

/**
 *
 * @param {*} categories surveyStructure[0].categories
 * @param {*} data recentResponses[0]
 * @param {*} factor factor
 * @param {*} question q
 * @param {*} role selected tab from sidebar
 * @returns
 */
export const effect_size_calculation = (
  categories,
  data,
  factor,
  question,
  role,
  value
) => {
  //Determine tertiary category structure
  if (!categories.length) return;
  let tertiary = categories.filter((f) => f.priority === "tertiary");
  let tertiary_ids = tertiary.map((item) => item.id);
  if (tertiary.length > 0) {
    let responses = data.responses
      .filter(
        (f) =>
          f.response.categories.find((f) => f.id == role).response === value
      )
      .map((i) => {
        return {
          response: i.response.questions.filter(
            (f) => f.factor === factor && f.q === question
          )[0].response,
          categories: i.response.categories.filter((f) =>
            tertiary_ids.includes(f.id)
          ),
        };
      });

    if (responses.length > 0) {
      let ES = tertiary.map((category) => {
        let idx = category.id;
        let standard_deviation = getStandardDeviationES(
          responses.map((i) => i.response)
        );

        return {
          name: category.name,
          ES: category.options.map((option, i) => {
            let M1 = responses
              .filter(
                (f) =>
                  f.categories.filter((d) => d.id === idx)[0].response ===
                  option.id
              )
              .map((item) => {
                return item.response;
              });

            let M2 = responses
              .filter(
                (f) =>
                  f.categories.filter((d) => d.id === idx)[0].response !==
                  option.id
              )
              .map((item) => {
                return getAggregatedScore(item.response, question.reverse);
              });

            let S1 = getStandardDeviationES(M1);

            let S2 = getStandardDeviationES(M2);

            let r1 = average(M1);

            let r2 = average(M2);

            let SD_POOLED = Math.sqrt((Math.pow(S1, 2) + Math.pow(S2, 2)) / 2);

            console.log(r1, r2, SD_POOLED, M1, M2);

            if (r1 == 0 || r2 == 0 || SD_POOLED == 0) {
              return 0;
            }

            return (r1 - r2) / SD_POOLED;
          }),
        };
      });

      return ES;
    }
  }
};

// TODO: Replace individual implementations with this
const reverseScoring = {
  0: 10,
  1: 9,
  2: 8,
  3: 7,
  4: 6,
  5: 5,
  6: 4,
  7: 3,
  8: 2,
  9: 1,
  10: 0,
};

// TODO: Replace individual implementations with this
/**
 * Returns the Reverse value if condition is correct.
 *
 * @param {*} responseObj entire response object (single response)
 * @param {*} questions set of all questions
 * @param {*} factorIndex current factor index
 * @returns
 */
export const getAggregatedScore = (response, reverse) => {
  return reverse ? reverseScoring[response] : response;
};

export const calculate_ES = (factor, c, option, data, questions) => {
  let M1 = data[0].responses
    .filter((f) => {
      return (
        f.response.categories.find((f) => f.id === c).response === option.id
      );
    })
    .map((resp) =>
      resp.response.questions
        .filter((f) => f.factor === factor)
        .reduce((a, b) => {
          let reverse = questions.factors[factor].questions[b.q].reverse;
          return a + getAggregatedScore(b.response, reverse);
        }, 0)
    );

  let M2 = data[0].responses
    .filter((f) => {
      return (
        f.response.categories.find((f) => f.id === c).response !== option.id
      );
    })
    .map((resp) =>
      resp.response.questions
        .filter((f) => f.factor === factor)
        .reduce((a, b) => {
          let reverse = questions.factors[factor].questions[b.q].reverse;
          return a + getAggregatedScore(b.response, reverse);
        }, 0)
    );

  let S1 = getStandardDeviationES(M1);

  let S2 = getStandardDeviationES(M2);

  let r1 = average(M1);

  let r2 = average(M2);

  let distribution = [];

  data[0]?.surveyData.forEach((resp) => {
    resp.responses.forEach((responseSet) => {
      if (
        responseSet.response.categories.find((f) => f.id === c).response ===
        option.id
      ) {
        responseSet.response.questions
          .filter((f) => {
            return f.factor === factor;
          })
          .map((i) => {
            let reverse = questions.factors[factor].questions[i.q].reverse;
            return distribution.push(getAggregatedScore(i.response, reverse));
          });
      }
    });
  });

  let SD_POOLED = Math.sqrt((Math.pow(S1, 2) + Math.pow(S2, 2)) / 2);

  if (r1 == 0 || r2 == 0 || SD_POOLED == 0) {
    return {
      risk: 0,
      n: M1.length,
      avg: average(distribution),
    };
  }

  return {
    risk: (r1 - r2) / SD_POOLED,
    n: M1.length,
    avg: average(distribution),
  };
};

const average = (array) => {
  if (array.length == 0) {
    return 0;
  }

  let total = 0;
  let n = 0;
  array.forEach((item) => {
    total += item;
    n += 1;
  });
  return Math.floor((total / n) * 10) / 10;
};

export const combo_effect_size_calculation = (questions, categories, data) => {
  return questions.factors.map((f, x) => {
    return categories
      .filter((f) => f.priority.toLowerCase() === "primary")[0]
      .options.map((p, p_id) => {
        return categories
          .filter((f) => f.priority.toLowerCase() === "secondary")[0]
          .options.map((p2, p_id2) => {
            const responseSet = data.filter((matchingSet) => {
              return matchingSet.name === p.name;
            })[0];

            if (responseSet && responseSet.responses.length > 0) {
              let _data = [
                {
                  surveyData: [...data],
                  responses: data
                    .filter((matchingSet) => matchingSet.name === p.name)[0]
                    .responses.filter((f) => {
                      const primaryIndex = f.response.categories.find(
                        (f) => f.priority == "primary"
                      ).response;
                      const secondaryIndex = f.response.categories.find(
                        (f) => f.priority == "secondary"
                      ).response;
                      return primaryIndex === p.id && secondaryIndex == p2.id;
                    }),
                },
              ];

              return {
                factor: f.title,
                category: p.name,
                category2: p2.name,
                data: categories
                  .filter((f) => f.priority.toLowerCase() === "tertiary")
                  .map((c, c_id) => {
                    return {
                      name: c.name,
                      ES: c.options.map((o, o_id) => {
                        return calculate_ES(x, c.id, o, _data, questions);
                      }),
                    };
                  }),
              };
            }
          });
      });
  });
};



export const all_effect_size_calculations = (questions, categories, data) => {
   let nuanced = combo_effect_size_calculation(questions,categories,data)
   let primary = primary_effect_size(questions,categories,data)
   let secondary = secondary_effect_size(questions,categories,data)

   return [...primary,...secondary]

};

const primary_effect_size = (questions, categories, data) => {
  return questions.factors.map((f, x) => {
    return categories
      .filter((f) => f.priority.toLowerCase() === "primary")[0]
      .options.map((p, p_id) => {

         const responseSet = data.filter((matchingSet) => {
              return matchingSet.name === p.name;
            })[0];

      if (responseSet && responseSet.responses.length > 0) {

        
        let r1 = data.filter(f=>f.name == p.name)[0].responses.filter(f=>f.response.categories.find(
                        (f) => f.priority == "primary"
                      ).response == p.id)

        let r2 = data.filter(f=>f.name == p.name)[0].responses.filter(f=>f.response.categories.find(
                        (f) => f.priority == "primary"
                      ).response != p.id)

        return {factor:f.title,
                category:p.name,
                data:categories
                .filter((f) => f.priority.toLowerCase() === "tertiary")
                .map((c, c_id) => {

                  return {
                    name:c.name,
                    ES: c.options.map((o, o_id) => {
                      return calculate_es(x,c.id,o,r1,r2,questions)
                    })
                   }

                })
          }
        }
        
            });
    })

};

const secondary_effect_size = (questions, categories, data) => {
  let pooled_data = []
  let names = []
   data.map((item)=>{
     if(!names.includes(item.name)){
       pooled_data.push(...item.responses)
       names.push(item.name)
     }
  })


  return questions.factors.map((f, x) => {
    return categories
      .filter((f) => f.priority.toLowerCase() === "secondary")[0]
      .options.map((p, p_id) => {


        
        let r1 = pooled_data.filter(f=>f.response.categories.find(
                        (f) => f.priority == "secondary"
                      ).response == p.id)

        let r2 = pooled_data.filter(f=>f.response.categories.find(
                        (f) => f.priority == "secondary"
                      ).response != p.id)

        return {factor:f.title,
                category:p.name,
                data:categories
                .filter((f) => f.priority.toLowerCase() === "tertiary")
                .map((c, c_id) => {

                  return {
                    name:c.name,
                    ES: c.options.map((o, o_id) => {
                      return calculate_es(x,c.id,o,r1,r2,questions)
                    })
                   }

                })
          }
        });
    })

};


const calculate_es = (factor,c,option,r_1,r_2,questions) =>{
    let M1 = r_1
    .filter((f) => {
      return (
        f.response.categories.find((f) => f.id === c).response === option.id
      );
    })
    .map((resp) =>
      resp.response.questions
        .filter((f) => f.factor === factor)
        .reduce((a, b) => {
          let reverse = questions.factors[factor].questions[b.q].reverse;
          return a + getAggregatedScore(b.response, reverse);
        }, 0)
    );

  let M2 = r_2
    .filter((f) => {
      return (
        f.response.categories.find((f) => f.id === c).response !== option.id
      );
    })
    .map((resp) =>
      resp.response.questions
        .filter((f) => f.factor === factor)
        .reduce((a, b) => {
          let reverse = questions.factors[factor].questions[b.q].reverse;
          return a + getAggregatedScore(b.response, reverse);
        }, 0)
    );

  let S1 = getStandardDeviationES(M1);

  let S2 = getStandardDeviationES(M2);

  let r1 = average(M1);

  let r2 = average(M2);


  let factor_q_length = questions.factors[factor].questions.length
  

  let SD_POOLED = Math.sqrt((Math.pow(S1, 2) + Math.pow(S2, 2)) / 2);

  if (r1 == 0 || r2 == 0 || SD_POOLED == 0) {
    return {
      risk: 0,
      n: M1.length,
      avg: r1/factor_q_length,
    };
  }

  return {
    risk: (r1 - r2) / SD_POOLED,
    n: M1.length,
    avg: r1/factor_q_length,
  };
}


function randn_bm(min, max, skew) {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) num = randn_bm(min, max, skew);
  // resample between 0 and 1 if out of range
  else {
    num = Math.pow(num, skew); // Skew
    num *= max - min; // Stretch to fill range
    num += min; // offset to min
  }
  return num;
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function randomDate(date1, date2) {
  function randomValueBetween(min, max) {
    return Math.random() * (max - min) + min;
  }
  var date1 = date1 || "01-01-1970";
  var date2 = date2 || new Date().toLocaleDateString();
  date1 = new Date(date1).getTime();
  date2 = new Date(date2).getTime();
  if (date1 > date2) {
    return new Date(randomValueBetween(date2, date1)).toLocaleDateString();
  } else {
    return new Date(randomValueBetween(date1, date2)).toLocaleDateString();
  }
}

const getminMax = () => {
  let min = randomIntFromInterval(0, 8);
  let max = randomIntFromInterval(min + 1, 10);

  return [min, max];
};

export const Data_Generator = (
  points,
  response_numbers,
  categories,
  questions
) => {
  let responses = [];
  let sport_list = categories.categories.find(
    (f) => f.priority === "primary"
  ).options;
  let number_of_teams = sport_list.length;

  for (let i = 0; i < number_of_teams; i++) {
    for (let r = 0; r < response_numbers; r++) {
      let date = randomDate("02/13/2021", "03/01/2022");
      date = date.replaceAll("/", "-");
      let _package = {
        id: r + i + 1,
        date: date,
        type: "COC",
        name: sport_list[i].name,
      };

      let response_list = generate_responses(points, categories, questions);

      _package["responses"] = response_list;

      responses.push(_package);
    }
  }

  return responses.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
};

const generate_responses = (points, cats, questions) => {
  let responses = [];
  let min_max = cats.categories
    .find((f) => f.priority === "secondary")
    .options.map((item) => getminMax());
  let skew = (val) => randomIntFromInterval(0, 101) / 40;
  for (let i = 0; i < points; i++) {
    let resp = { id: i + 1, categories: [], questions: [] };
    cats.categories.map((item, idx) => {
      let selection = randomIntFromInterval(0, item.options.length - 1);
      //Check selection subfactor existance
      let subfactor = null;
      if (item?.subfactors?.filter((f) => f.parent === selection).length > 0) {
        let sub_id = item.subfactors.findIndex((e) => e.parent === selection);
        subfactor = randomIntFromInterval(
          0,
          item.subfactors[sub_id].options.length - 1
        );
      }

      resp.categories.push({
        id: item.id,
        response: item.options[selection].id,
        subfactor,
        priority: item.priority,
      });
    });

    let secondary = cats.categories
      .find((f) => f.priority === "secondary")
      .options.findIndex(
        (f) =>
          f.id ===
          resp.categories.find((f) => f.priority === "secondary").response
      );

    questions.factors.map((item, idx) => {
      let factor = idx;
      let score = randn_bm(
        min_max[secondary][0],
        min_max[secondary][1],
        skew(idx)
      );
      item.questions.map((q, q_id) => {
        resp.questions.push({
          factor,
          q: q_id,
          ques_order: q.id,
          response: Math.floor(score),
        });
      });
    });

    responses.push({ employee_id: 0, response: resp });
  }

  return responses;
};
