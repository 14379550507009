// ChartComponent.jsx
import React from 'react';
import Chart from 'react-apexcharts';

const ChartComponent = ({ data }) => {
  const options = {
    chart: {
      type: data.type || 'bar',
      toolbar: {
        show: false, // Disable interactions
      },
      fontFamily: 'Raleway, sans-serif',
    },
    xaxis: {
      categories: data.labels || [],
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
    },
    title: {
      text: data.title || '',
      align: 'center',
      style: {
        fontFamily: 'Raleway, sans-serif',
        fontSize: '18px',
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4, // Add border radius to the bars
      },
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    colors: ['#2D70E2'], // Set the bar color to #2D70E2
  };

  const series = [
    {
      name: data.seriesName || '',
      data: data.data || [],
    },
  ];

  return (
    <div style={{ maxWidth: '600px', margin: '20px 0' }}>
      <Chart options={options} series={series} type={options.chart.type} height={350} />
    </div>
  );
};

export default ChartComponent;

