import styled from "styled-components";
import React, { useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import PDFReport from "./PDFReport";
import DataStory from "./DataStory";
import Recommendations from "./Recommendations";
import Workshop from "./Workshop";
import {
  SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  SG_GET_DEBRIEF_PDF_REPORT,
} from "constants/actions";
import Insights from "./Insights";

const Reports = ({ page }) => {
  const dispatch = useDispatch();

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
    });

    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT,
    });
  }, []);

  const renderPage = (page) => {
    switch (page) {
      case 0:
        return <PDFReport />;
      case 1:
        return <Insights />;
      case 2:
        return <Recommendations />;
      case 3:
        return <Workshop />;
      default:
        return <PDFReport />;
    }
  };

  return (
    <Container>
      <Center>{renderPage(page)}</Center>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-horizontal: 20px;
  font-family: "Raleway";

  transition: all 0.3s ease-in-out;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default Reports;
