import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import Footer from '../components/Footer'
import Side from '../components/Side'

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2E34D0",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  summary: {
    border: "2px solid #2C3644",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C3644",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
    marginTop: 20,
  },
});

const TableTemplate = {
	rows:[
			{'title':'Factor',width:'60%'},
			{'title':'Score',width:'10%'},
			{'title':'Grade',width:'10%'},
			{'title':'Norm',width:'10%'},
			{'title':'Change',width:'10%'},
		],
	data:[
		['Safety','96%','A+','--','--'],
		['Well-being','96%','A+','--','--'],
		['Cohesion','96%','A+','--','--'],
		['Empowerment','96%','A+','--','--'],
		['Personalization','96%','A+','--','--'],
		['Belonging','96%','A+','--','--']
	]
}


const Overview = () =>{

	return (
		<Page style={styles.page}>
			<Text style={styles.T2}>Factors Overview</Text>
			<View style={styles.line}/>

			<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
						information appears on all nuLogic reports and is not specific to this organization's individual results</Text>

			<View wrap={false}>

			<Text style={styles.title}>Human Factors Overview</Text>
			<Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs. 
            Chicken jerky short ribs, pastrami pork fatback ground round spare ribs cow t-bone filet mignon hamburger 
            leberkas shank. Meatball burgdoggen pork ribeye.</Text>


           <View style={styles.summary}>
            	<View style={styles.top}>
            		<Text>Human Factors Score: 78% (B+)</Text>
            	</View>
            	<View style={styles.bottom}>
            		<Text style={{fontSize:'10',fontWeight:700,marginBottom:-15}}>Factor Summary</Text>
            		<Table data={TableTemplate}/>
            	</View>
            </View>


            </View>

            <View wrap={false}>

			<Text style={styles.title}>System Factors Overview</Text>
			<Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs. 
            Chicken jerky short ribs, pastrami pork fatback ground round spare ribs cow t-bone filet mignon hamburger 
            leberkas shank. Meatball burgdoggen pork ribeye.</Text>


           <View style={styles.summary}>
            	<View style={styles.top}>
            		<Text>System Factors Score: 78% (B+)</Text>
            	</View>
            	<View style={styles.bottom}>
            		<Text style={{fontSize:'10',fontWeight:700,marginBottom:-15}}>Factor Summary</Text>
            		<Table data={TableTemplate}/>
            	</View>
            </View>


            </View>

            <Side/>
            <Footer/>
		</Page>
		)
}


export default Overview