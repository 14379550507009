import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {Input,Icon,} from "semantic-ui-react";
import templateData from "./templates.json";
import Questions from "./Questions";

import { useNavigate } from "react-router";


const Library = () => {
  const navigate = useNavigate();  
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredPrompts(templateData);
    setSelectedPrompt(templateData[0]);
  }, []);



  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredPrompts(
        templateData.filter(
        (prompt) =>
          prompt.title.toLowerCase().includes(term) ||
          prompt.description.toLowerCase().includes(term))
      )
  };

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt);
  };

  const confirmPromptSelect = () => {
    setSelectedPrompt(selectedPrompt);
  };


  return (
    <Container>
      <Back onClick={()=>navigate(-1)}><Icon name="chevron left"  /> Back</Back>
      <Title>Pulse AI Library</Title>
     
         
          <ContentWrapper>
            <SelectionPane>
              
              <SearchBar
                placeholder="Search prompts..."
                value={searchTerm}
                onChange={handleSearch}
                fluid
              />
              {/* Prompts with Filters */}
              <SectionTitle>Culture factor pulses</SectionTitle>
              {filteredPrompts.map((prompt) => (
                <PromptItem
                  key={prompt.id}
                  onClick={() => handlePromptSelect(prompt)}
                  active={selectedPrompt && selectedPrompt.id === prompt.id}
                >
                  {prompt.title}
                </PromptItem>
              ))}

            
            </SelectionPane>

            <DetailsPane>

                <SemanticButton>Use this survey</SemanticButton>
              {selectedPrompt ? 
                <Questions jsonData={selectedPrompt} />
              : (
                <Placeholder>
                  Select a pulse survey template
                </Placeholder>
              )}


            </DetailsPane>
          </ContentWrapper>
          
         
      
    </Container>
  );
};

export default Library;



// Styled Components
const Container = styled.div`
  font-family: "Raleway", sans-serif;
  width:100%;
  padding:20px;
`;

const Back = styled.div`
  cursor: pointer;
  color: #666D79;
  font-size: 14px;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: 24px;
  color:#2A3039;
  font-weight: 600;
  margin-left:20px;
  margin-bottom: 40px;
`

const SearchBar = styled(Input)`
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  max-height: 90%;
`;

const SelectionPane = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  padding:10px;
  overflow-y: auto;
`;


const SectionTitle = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 11px;
  color: #2A3039;
  padding-left: 0px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;

`;

const PromptItem = styled.div`
  padding: 8px;
  cursor: pointer;
  color:${({ active }) => (active ? "#2D70E2" : "#2A3039")};
  font-size: 12px;
  background-color: ${({ active }) => (active ? "#F8FAFF" : "white")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};

  font-family: "Raleway", sans-serif;
  &:hover {
    background-color: #f0f8ff;
  }
`;

const DetailsPane = styled.div`
  width: 70%;
  padding: 0px 20px;
  position:relative;
`;


const Placeholder = styled.div`
  color: #999;
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
`;




const SemanticButton = styled.button`
  background-color: #2D70E2;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position:absolute;
    right:20px;
    top:-60px;
  &:hover {
    background-color: #1a71b8;
  }

`