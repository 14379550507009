import styled, { keyframes } from "styled-components";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "semantic-ui-react";
import PlusImg from "assets/images/nav/plus.svg";
import QuestionDrop from "./QuestionDrop2";
import Filters from "./Filters";
import { StarterQuestions } from "data/live/StarterQuestions";
import PromptLibrary from "components/PromptLibrary";
import {Loader} from 'semantic-ui-react'
import { set } from "date-fns";

const IntroScreen = ({
  raw_feedback,
  outcomeQ,
  setSelectedPrompt,
  setCategoryMeta,
  setPromptUsed,
  setDefaultListCb,
  open,
  setOpen,
  emptyData,
  feedback_length,
  initialLoad
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(
    "Culture Factor Report"
  );

  const [addSpacing, setAddSpacing] = useState(false);
    console.log(initialLoad,emptyData,raw_feedback.length)
  if(!raw_feedback.length>0 && emptyData && !initialLoad){
    return (
    <LoaderContainer> 
      <Loader active inline='centered' /> 
      <Description>Initiating copilot data sources...</Description>
      </LoaderContainer>
    )
  }

  if(open){
    return <PromptLibrary
      setCategoryMeta={setCategoryMeta}
      onSelect={(prompt) => {
        // If there's a promt selected, add spacing
        // otherwise spacing is false.
        setSelectedPrompt(null, prompt);
        setPromptUsed(true);
        setOpen(false);
      }}
      goBack={()=>setOpen(false)}
    />
  }


  return (
    <Page>
      <Container spacing={addSpacing}>
        <Title>Your culture copilot</Title>
        <Description>
          Ask questions and generate insights about your culture and engagement.
          Start by selecting which areas of your culture you'd like to address,
          and then simply engage with the copilot.
        </Description>

        <SmallLabel>This chat topic is focused on:</SmallLabel>
        <FactorContainer>
          <QuestionDrop
            raw_feedback={raw_feedback}
            outcomeQ={outcomeQ}
            setDefaultListCb={setDefaultListCb}
          />
        </FactorContainer>

        <SmallLabel>And on the following categories:</SmallLabel>
        <FactorContainer>
          <Filters />
        </FactorContainer>

        <Section>
          <SectionTitle>Select from our prompt library</SectionTitle>
          <SectionDescription>
            Select a prompt from the library below to guide how the AI
            interprets your question and analyzes the data. Your selected prompt
            will influence the insights generated.
          </SectionDescription>


          <Description red={feedback_length==0}>
            {feedback_length > 0 ? `${feedback_length} responses have been loaded into the copilot.` : "No data available for these filters."}
          </Description>



          {feedback_length>0 && <Button onClick={()=>setOpen(true)}>Open Prompt Library</Button>}
          
        </Section>
      </Container>
    </Page>
  );
};

export default IntroScreen;

// Styled Components

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

const Container = styled.div`
  margin-bottom: ${({ spacing }) => (spacing ? "100px" : "70px")};
`;

const Title = styled.div`
  font-family: "Raleway";
  font-weight: 700;
  font-size: 24px;
  color: #2a3039;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 14px;
  color: ${({ red }) => (red ? "#ff6f61" : "#666d79")};
  margin-bottom: 20px;
  line-height: 1.6;
`;

const SmallLabel = styled.div`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 12px;
  color: #666d79;
  margin-bottom: 12px;
`;

const FactorContainer = styled.div`
  display: flex;
  align-items: center;
  color: #2d70e2;
  font-family: "Raleway";
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-family: "Raleway";
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  margin-bottom: 8px;
`;

const SectionDescription = styled.div`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 14px;
  color: #666d79;
  margin-bottom: 16px;
  line-height: 1.6;
`;

const QuestionSuggestions = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  margin-bottom: 24px;
`;

const ChatHeading = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #2a3039;
  line-height: 26px;
`;

const LoaderContainer = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  height:100%;
  width:100%;
  flex-direction:column;
`

const Sample = styled.div`
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  margin-right: 16px;
  margin-bottom: 16px;
  padding: 16px;
  cursor: ${({ enabled }) => (enabled ? "pointer" : "not-allowed")};
  width: 40%;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SampleTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const SampleText = styled.div`
  font-size: 12px;
`;

const Samples = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  opacity: ${({ enabled }) => (enabled ? 1 : 0.5)};
`;

const Button = styled.div`
  font-family: "Raleway";
  font-weight: 600;
  font-size: 14px;
  color: white;
  background-color: #2d70e2;
  padding: 12px 24px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
`