import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";


const Copilot = () =>{

    const [open,setOpen] = useState(false)

    return (
        <Container open={open}>
            <ButtonContainer onClick={()=>setOpen(!open)}>
                Culture copilot help
            </ButtonContainer>
        </Container>
    )

}

export default Copilot

const Container = styled.div`
    position:fixed;
    bottom:20px;
    right:20px;
    z-index:1000;
    font-family:'Raleway';

    height:${props=>props.open?'500px':'45px'};
    width:${props=>props.open?'500px':'200px'};

    transition:all 0.3s ease-in-out;
    border:1px solid #E0E0E0;

    border-radius:${props=>props.open?'10px':'20px'};
    box-shadow:${props=>props.open?'0px 0px 10px 0px rgba(0,0,0,0.2)':'none'};
    padding:${props=>props.open?'10px':'0px'};
`

const ButtonContainer = styled.div`
    border-radius:5px;
    display:flex;
    align-items:center;
    font-size:18px;
    padding:5px 10px;
    background-color:white;
    cursor:pointer;
    font-weight:bold;
    width:100%;
    height:45px;
`