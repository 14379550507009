import styled, { keyframes } from "styled-components";
import React from "react";
import Progress from "./Progress";

import Chart from "react-apexcharts";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  Subsubtext,
  barChartOptions,
} from "reports/DataJourney/components/common/styles";

const ProgressTracker = () => {
  return (
    <ScreenContainer>
      <ScreenTitleExtended>Progress Tracker</ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        <Blue>You're well on your way to improving your culture! </Blue>
        Your check-in path is optimized to get you to your goals. We'll make
        sure you're always making progress.
      </SubtextExtended>

      <Progress completed={1} />

      <SubsubtextExtended delay={1.2}>
        12% of your goal achieved
      </SubsubtextExtended>

      <ChartContainer delay={1.5}>
        <Chart
          options={data3.options}
          series={data3.series}
          type="line"
          height={350}
        />
      </ChartContainer>
    </ScreenContainer>
  );
};

export default ProgressTracker;


const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubsubtextExtended = styled(Subsubtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const ChartContainer = styled.div`
  width: 100%;

  margin-top: 40px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Blue = styled.span`
  color: #2d70e2;
  font-weight: 500;
`;

const data3 = {
  series: [
    {
      name: "Steps",
      data: [67, 68, 70, 71, 72, 74, 75, 77],
    },
  ],
  options: {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    forecastDataPoints: {
      count: 6,
    },
    markers: {
      size: 5,
      strokeColors: "#2D70E2",
    },
    grid: {
      padding: {
        right: 100,
      },
    },
    annotations: {
      points: [
        {
          x: 8,
          y: 77,
          marker: {
            size: 8,
          },
          label: {
            style: {
              background: "#2D70E2",
              color: "#fff",
              fontSize: "12px",
              fontWeight: 600,
              padding: {
                left: 10,
                right: 10,
                top: 5,
                bottom: 5,
              },
            },
            text: "Your Goal",
            offsetY: -5,
          },
        },
      ],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
    xaxis: {
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      min: 65,
      max: 80,
      decimalsInFloat: 1,
      title: {
        text: "Psychological Safety Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
