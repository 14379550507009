import React from "react";
import { View, Text, StyleSheet, Page, Image } from "@react-pdf/renderer";
import Footer from "../components/Footer";
import Side from "../components/Side";

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
    color: "#2C3644",
  },
  page: {
    padding: 60,
    paddingHorizontal: 10,
    color: "#2C3644",
  },
  textPadding: {
    paddingHorizontal: 70,
  },
  p: {
    fontSize: 10,
    marginBottom: 10,
    lineHeight: 1.7,
    color: "#2C3644",
  },
  chartWrapper: {
    paddingTop: 20,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#2C3644",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  summary: {
    border: "2px solid #2C3644",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C3644",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
    marginTop: 20,
  },
});

const CultureMap = (props) => {
  const { chart, config, isSportLaw } = props;

  return (
    <Page style={styles.page} wrap={false} minPresenceAhead={5}>
      <View style={styles.textPadding}>
        <Text style={styles.T2}>Culture Index Map</Text>
        <View style={styles.line} />

        <Text style={styles.p}>
          The Culture Index Map below provides a snapshot of where each segment
          within the report indexed on the people and performance dimensions
          overall. Each axis represents an average of all factors within each
          dimension. Lastly, the data is visualized by segment, reflected in the
          legend at the bottom of map.
        </Text>
      </View>

      <View style={styles.chartWrapper} fixed>
        <Image source={chart} fixed style={{ width: "100%", height: "100%" }} />
      </View>
      <Side {...config} isSportLaw={isSportLaw} />
      <Footer {...config} />
    </Page>
  );
};

export default CultureMap;
