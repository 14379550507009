// Libraries
import { useEffect, useState } from "react";

// Constants
import { SURVEY_INFO } from "constants/surveys";

/**
 * ? This hook is specific to EDI types
 * @param {get_organizations: array, surveyType: int } params
 * @returns
 */
function useGetEDISurveyUtils(params) {
  const {
    get_organizations,
    ediResponses,
    surveyStructure,
    surveyQuestions,
    organizationId,
    selectedReport,
    pdfLoading = false,
  } = params;
  const [EDIQues, setEDIQues] = useState();
  const [filteredResponses, setFilteredResponses] = useState();
  const [selectedResponses, setSelectedResponses] = useState();
  const [questions, setQuestions] = useState();
  const [ediCategories, setEdiCategories] = useState();

  useEffect(() => {
    if (pdfLoading) {
      setEDIQues(null);
      setFilteredResponses(null);
      setQuestions(null);
      setEdiCategories(null);
    }
  }, [pdfLoading]);

  useEffect(() => {
    //Determine which survey is being used in the org data
    if (get_organizations && organizationId) {
      const organization = get_organizations[organizationId];
      const ediQues =
        (organization?.services_enabled &&
          organization?.services_enabled.filter((sc) => {
            return sc?.name === SURVEY_INFO.edi?.name;
          })?.[0]?.question_sort_order) ||
        0;
      if (ediQues) {
        setEDIQues(ediQues);
      }
    }
  }, [get_organizations, organizationId]);

  useEffect(() => {
    if (selectedReport?.length > 0) {
      let responseList = [];

      const dataSet = selectedReport.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });

      dataSet.forEach((responseSet) => {
        if (responseSet.responses.length > 0) {
          responseList.push(responseSet);
        }
      });
      setFilteredResponses(responseList);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (selectedReport?.responses?.length > 0 && !selectedResponses) {
      setSelectedResponses([selectedReport]);
    }
  }, [selectedReport, selectedResponses]);

  useEffect(() => {
    if (
      EDIQues > 0 &&
      surveyStructure?.[0] &&
      surveyStructure?.[0] &&
      !ediCategories &&
      surveyQuestions
    ) {
      const SurveyQues = surveyQuestions.find((sq) => {
        return sq.sort_order === EDIQues;
      });

      setQuestions(SurveyQues.questions);
      setEdiCategories(surveyStructure[0]?.categories);
    } else {
      setQuestions(surveyQuestions[0]?.questions);
      setEdiCategories(surveyStructure[0]?.categories);
    }
  }, [surveyStructure, surveyQuestions, EDIQues, pdfLoading]);

  return {
    EDIQues,
    filteredResponses,
    questions,
    ediCategories,
    ediResponses,
    selectedResponses,
  };
}

export default useGetEDISurveyUtils;
