import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Header, Image, Modal } from 'semantic-ui-react'
import Pic from 'assets/images/walkthrough/UI.webp'
import Tree from 'assets/images/walkthrough/tree.webp'

const Welcome = () =>{

	const [open, setOpen] = React.useState(true)
	const [selected,setSelected] = React.useState(0)

	const next = () =>{
		if(selected<2){
			setSelected(selected+1)
		}
		else{
			setOpen(false)
		}
	}

	const back = () =>{
		if(selected!=0){
			setSelected(selected-1)
		}
	}

	const close = () =>{
		setSelected(0)
		setOpen(false)
	}

	return (
		 <Modal
	      onClose={() => close()}
	      onOpen={() => setOpen(true)}
	      open={open}
	      // trigger={<Button>Show Modal</Button>}
	      style={{paddingBottom:20}}
	      size="small"
	      closeOnEscape={false}
          closeOnDimmerClick={false}
	    >

	    <Content>


	    {selected==0?

	    <>
	    	<ImageComp src={Pic}/>
		    <Title>Welcome to culture analytics</Title>
		    <T1>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
		    et dolore magna aliqua.</T1>

	    </>

	    :
	    selected==1?
	    <iframe width="560" height="315" src="https://www.youtube.com/embed/qP1ZskD2JEM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	
	    :
	    <>
	    	<ImageComp src={Tree}/>
	    	 <Title>Start exploring your culture data</Title>
		    <T1>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
		    et dolore magna aliqua.</T1>
	    </>
	}
	    
	    </Content>
	    <Dots>
	    	<Dot selected={selected==0}/>
	    	<Dot selected={selected==1}/>
	    	<Dot selected={selected==2}/>
	    </Dots>

	    <Buttons>
	    	<Button onClick={()=>back()}>Back</Button>
	    	<Button2 onClick={()=>next()}>{selected==2?'Finish':'Next'}</Button2>
	    </Buttons>
    	</Modal>
		)
}


export default Welcome



const Title = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
display: flex;
align-items: center;
color: #414141;
`


const Content = styled.div`
	height:400px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const ImageComp = styled.img`
		height:250px;
`

const Dots = styled.div`
	display:flex;
	width:100%;
	align-items:center;
	justify-content:center;
`

const Dot = styled.div`
	height:10px;
	width:10px;
	border-radius:50%;
	background-color:${props=>props.selected?'#476DFA':'#7E7E7E'};
	margin-right:5px;
`

const T1 = styled.div`
	max-width:600px;
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
margin-top:10px;
color: #7E7E7E;
`

const Buttons = styled.div`
	display:flex;
	width:100%;
	align-items:center;
	justify-content:center;
	margin-top:30px;
`

const Button = styled.div`
	width:200px;
	padding:10px;
	border:1px solid #7E7E7E;
	border-radius:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:20px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	cursor:pointer;
`

const Button2 = styled.div`
	width:200px;
	padding:10px;
	border:1px solid #7E7E7E;
	border-radius:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:20px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	cursor:pointer;
	background-color:#476DFA;
	color:white;
`