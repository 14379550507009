import styled from "styled-components";
import React, { useState } from "react";
import { Popup, Icon } from "semantic-ui-react";
import { PlusCircleIcon } from "./Icons";

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return hslToHex(h, s, l);
}

const Assignee = ({
  assignees,
  size = null,
  stacked = true,
  editMode = false,
  setAddAssignee = () => {},
}) => {
  const assigneesList = assignees?.map((p, idx) => {
    let fName = p?.name?.split(" ")[0] || p?.first_name;
    let lName = p?.name?.split(" ")[1] || p?.last_name;
    if (fName?.length === 0 && lName?.length === 0) {
      fName = "N";
      lName = "A";
    }
    const name = `${fName} ${lName}`;
    const zIndex = assignees.length - idx;
    const posAdjustment = idx > 0 ? 11 - idx * 1.2 : 11;

    const numberedElem = (
      <Popup
        content={"Assignee: " + name}
        position="bottom center"
        size="mini"
        inverted
        trigger={
          <Container size={size} color="#D9D9D9">
            +{assignees.length - 3}
          </Container>
        }
      />
    );

    const assigneeElem = (
      <Popup
        content={"Assignee: " + name}
        position="bottom center"
        size="mini"
        inverted
        trigger={
          <Container
            stacked={stacked}
            size={size}
            color={stringToHslColor(
              fName?.charAt(0).toUpperCase() + lName?.charAt(0).toUpperCase(),
              30,
              80
            )}
          >
            {fName?.charAt(0).toUpperCase() + lName?.charAt(0).toUpperCase()}
          </Container>
        }
      />
    );
    if (idx < 3 || !stacked) {
      return assigneeElem;
    } else {
      if (idx === assignees.length - 1) {
        return numberedElem;
      }
    }
  });

  if (editMode) {
    const addElem = (
      <Popup
        content={"Add an assignee"}
        position="bottom center"
        size="mini"
        inverted
        trigger={
          <AddAssigneeWrapper>
            <Container
              onClick={() => setAddAssignee(true)}
              addElem={true}
              size={size}
              color={"white"}
            >
              {PlusCircleIcon()}
            </Container>
          </AddAssigneeWrapper>
        }
      />
    );

    assigneesList.push(addElem);
  }

  return (
    <AssigneesContainer addWidth={stacked}>{assigneesList}</AssigneesContainer>
  );
};

export default Assignee;

const Container = styled.div`
  min-height: ${(props) => (props.size === "large" ? "50px" : "30px")};
  min-width: ${(props) => (props.size === "large" ? "50px" : "30px")};
  border-radius: ${(props) => (props.size === "large" ? "25px" : "15px")};
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  font-weight: bold;
  cursor: pointer;
  opacity: ${(props) => (props.addElem ? 0.3 : 1)};
  :hover {
    opacity: 1;
  }
  font-size: ${(props) => (props.size === "large" ? "20px" : "14px")};
`;

const AssigneeWrapper = styled.div`
  z-index: ${(props) => props.zIndex};
  display: flex;
  justify-content: flex-end;
  margin-left: ${(props) => (props.moveOver > 0 ? 10 : 0)};
`;

const AddAssigneeWrapper = styled.div`
  padding-left: 20px;
`;

const AssigneesContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-right: 10px;
`;
