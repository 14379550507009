import React from 'react';
import styled, { keyframes } from "styled-components";
import {Modal} from 'semantic-ui-react';

const QuoteModal = ({quote,close,quoteData}) => {
    return (
        <Modal open={quote!==null} onClose={close}>
            <Modal.Content style={{padding:30}}>
                <Small>
                    Supporting Evidence
                </Small>
                <Title>
                    {quote?.effect}
                </Title>

                <Description>We've pulled quotes from the feedback that most relate to this topic.</Description>
                <ol>
                {quoteData?.[quote?.row]?.map((quote,index)=>{
                    return (
                        <Quote key={index}>
                            <QuoteText>
                                {quote}
                            </QuoteText>
                        </Quote>
                    )
                })}
                </ol>
            </Modal.Content>
        </Modal>
    )
}

export default QuoteModal;

const Title = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-bottom:5px;
    font-family:"Raleway";
`

const Quote = styled.li`
    margin-bottom:10px;
    font-family:"Raleway";
`

const QuoteText = styled.div`
    font-size:14px;
    font-weight:bold;
    color:#2A3039;
    line-height:1.6;
`

const Description = styled.div`
    font-size:14px;
    color:#2A3039;
    margin-bottom:20px;
    font-family:"Raleway";
`

const Small = styled.div`
    text-transform:uppercase;
    font-size:12px;
    font-weight:bold;
    color:#2D70E2;
`