import React, { useState, useEffect, useContext } from "react";
import { Loader, Message, } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
// import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Environment } from "_config"

import { CLEAR_ALL, LOGOUT_REQUEST } from "constants/actions";

import { DomainContext } from 'layouts/LayoutContext';

const Logout = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const Domain = useContext(DomainContext);

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual
  );

  const [success] = useState("Logged Out Sucessfully, taking you to website");

  useEffect(() => {
    // console.log(get_auth?.token);
    if (get_auth?.token) {
      // console.log("Logged out")
      dispatch({ type: LOGOUT_REQUEST });
    }
    dispatch({ type: CLEAR_ALL });

  }, [dispatch, get_auth]);

  useEffect(() => {
    if (Environment !== "Production") {
      // use when testing to make sure you dont have to 
      // navigate back each time
      setTimeout(
        () => navigate("/account/login"),
        500
      );
    } else {
      // let location = Domain.name === "Innerlogic" ? 'https://innerlogic.com/' : 'https://nulogic.co'
      setTimeout(
        () => (window.location = Domain.homePage),
        3000
      );
    }
  })

  return (
    <div style={{ height: '100%', paddingTop: '25vh' }}>
      {success && (<Loader active inline='centered' />)}

      <div style={{
        height: '50%', width: '50%',
        marginRight: 'auto',
        marginLeft: 'auto'
      }}>
        {success && (
          <>
            <Loader />
            <Message
              success
              // header='Action Forbidden'
              content={success}
            />
          </>
        )}

      </div>


      {/* <LoginForm message={"Logged Out Sucessfully"}  /> */}

    </div>
  );
};

export default Logout;
