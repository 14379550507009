export const theme = {
    colors: {
      primary: '#2A6FE8',
      primaryLight: '#2A6FE833',
      background: '#F7F9FC',
      surface: '#FFFFFF',
      text: '#333333',
      textLight: '#666666',
      border: '#E1E4E8',
      success: '#28A745',
      danger: '#DC3545',
      info: '#17A2B8'
    },
    spacing: (factor) => `${factor * 8}px`,
    fontSizes: {
      small: '0.875rem',
      medium: '1rem',
      large: '1.25rem'
    },
    radii: {
      small: '4px',
      medium: '8px',
      large: '12px'
    }
  };
  