import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/stock/article1.jpg'

const FactorExplain = () =>{

    return (
        <Container>
            

            <T2>What exactly is accountability?</T2>

            <T3>Accountability is not only having responsibility for a set of tasks…</T3>
            <Description>
            It involves having a culture of responsibility and  <strong>shared ownership</strong> in achieving desired <strong>outcomes</strong>
            It also encompasses the commitment to hold each other accountable for the goals set, while ensuring <strong>performance expectations</strong> are met.

            </Description>

            <Description>
            Accountable teams are more likely to have:
            </Description>

            <ul>
                <LI>
                    <strong>Higher responsibility:</strong> Accountability in the workplace promotes a culture where individuals take ownership of their tasks and actions.

                </LI>
                <LI>
                    <strong>Greater transparency:</strong> It encourages open communication about progress, challenges, and outcomes, fostering an environment where everyone is clear about their roles and expectations.

                </LI>
                <LI>
                    <strong>Increased trust:</strong> Accountability builds trust among team members as they rely on each other to deliver and fulfill commitments, ultimately enhancing team cohesion and reliability.



                </LI>
            </ul>
            <IMG src={Image}/>
           

          
        </Container>
    )

}

export default FactorExplain

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    line-height:1.4;
`

const T3 = styled.div`
    font-size:14px;
    font-weight:bold;
`

const Description = styled.div`
margin-top:20px;
`
const D2 = styled.div`
    margin-bottom:20px;
`

const IMG = styled.img`
    width:100%;
    margin-bottom:40px;
`

const LI = styled.li`
    margin-bottom:10px;
`