import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress } from 'semantic-ui-react'
import Feeling from './Feeling'
import Theme from './Theme'

const data = {
          
            series: [1, 0, 3, 0, 5],
            options: {
              chart: {
                width: 400,
                type: 'donut'
              },
              labels: ['Connected', 'Grateful', 'Balanced', 'Proud', 'Joyful'],
              fill: {
                opacity: 1,
               
              },
              stroke: {
                width: 0,
                colors: undefined
              },
              yaxis: {
                show: false
              },
              legend: {
                position: 'bottom',
              },
              colors:['rgba(8, 194, 81,0.2)','rgba(8, 194, 81,0.4)','rgba(8, 194, 81,0.6)','rgba(8, 194, 81,0.8)','rgba(8, 194, 81,1)']
              ,
              plotOptions: {
                polarArea: {
                  rings: {
                    strokeWidth: 0
                  },
                  spokes: {
                    strokeWidth: 0
                  },
                }
              },
            },
          
          
          };

const data2 = {
          
            series: [{
              name: 'series1',
              data: [5,2,1,7,9]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                 toolbar:{
                	show:false
                }
              },
              grid:{
              	padding:{
              		right:50
              	}
              },
              dataLabels: {
                enabled: false
              },
              annotations:{
              	 yaxis: [
				    {
				      y: 6,
				      borderColor: 'black',
				      label: {
				        style: {
				          color: 'black',
				        },
				        text: 'Average Enjoyment'
				      }
				    }
				  ],
              	points: 
					[
					  {
					    x: new Date('2018-09-19T04:30:00.000Z').getTime(),
					    y: 9,
					    marker: {
					      size: 8,
					    },
					    label: {
					      text: '9',
					      borderWidth:0,
					      style:{
					      	fontSize:20,
					      	fontWeight:'bold'
					      }
					    }
					  }
					]
              },
              stroke: {
                curve: 'smooth',
                colors:['#08c251']
              },
              fill:{
              	colors:['#08c251']
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };

const data3 = {
          
            series: [
              {
                data: [
                  {
                    x: 'Work Environment',
                    y: 218
                  },
                  {
                    x: 'Personal Accomplishment',
                    y: 149
                  },
                  {
                    x: 'Milestone Met',
                    y: 184
                  }
                ]
              }
            ],
            options: {
              legend: {
                show: false
              },
              chart: {
                height: 350,
                type: 'treemap',
                toolbar:{
                	show:false
                }
              },
               colors:['#08c251']
            },
          
          
          };


const colors =['rgba(8, 194, 81,1)','rgba(48, 120, 255,1)','rgba(213, 0, 178,1)','rgba(255, 184, 18,1)','rgba(126, 36, 234,0.5)','rgba(255, 45, 85,1)']          

const NewSystems = ()=>{

	const [selected,setSelected] = React.useState(1)

	return (
		<Container>
			<CardTop>
				<Nav>
				<NavItem selected={selected==1} onClick={()=>setSelected(1)} color={colors[selected-1]}>Enjoyment (11)</NavItem>
				<NavItem selected={selected==2} onClick={()=>setSelected(2)} color={colors[selected-1]}>Motivation (5)</NavItem>
				<NavItem selected={selected==3} onClick={()=>setSelected(3)} color={colors[selected-1]}>Curiosity</NavItem>
				<NavItem selected={selected==4} onClick={()=>setSelected(4)} color={colors[selected-1]}>Stress (3)</NavItem>
				<NavItem selected={selected==5} onClick={()=>setSelected(5)} color={colors[selected-1]}>Discontent (2)</NavItem>
				<NavItem selected={selected==6} onClick={()=>setSelected(6)} color={colors[selected-1]}>Anger</NavItem>
			</Nav>
			</CardTop>
			<Card width={60}>
				<Title>Enjoyment System Trends</Title>
				<Chart options={data2.options} series={data2.series} type="area" height={350}/>
			</Card>
			<Card width={40}>
				<Title>Enjoyment System Breakdown</Title>
				<Chart options={data.options} series={data.series} type="donut" width={400}/>
			</Card>

			<Card width={60}>
				<Title>Enjoyment Comments</Title>
				<Feeling name={'Michael Bawol'} pic={'https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg'} emotion={'Joyful'} comment={'I was incredibly happy when our team met this week. Im feeling very confident in our plans moving forward'}/>
				  <Feeling name={'Bryce Tully'} pic={'https://randomuser.me/api/portraits/men/55.jpg'}  emotion={'Joyful'} comment={'I have a renewed energy this week after my vacation last week. Im ready to start this project'}/>
            <Feeling name={'Deepesh Krishnan'} pic={'https://randomuser.me/api/portraits/men/48.jpg'}  emotion={'Balanced'} comment={'Last week was a challenge, but this week I was able to balance my work load with more down time and a greater focus on taking breaks.'}/>
              <Feeling name={'Paige Springer'} pic={'https://randomuser.me/api/portraits/women/1.jpg'}  emotion={'Connected'} comment={'Our team meeting this week helped me gain a better understanding of how my role fits with the work Deepesh has been doing. I am able to focus on delivering a clearer objective plan.'}/>
			</Card>

			<Card width={40}>
				<Title>Enjoyment Themes</Title>
				<Theme score={86}/>
				<Theme score={14}/>

			</Card>

			






		</Container>
		)
}


export default NewSystems


const Card = styled.div`
 	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
 	padding:30px 20px;
 	width:${props=>'calc('+props.width+'% - 20px)'};
 	margin-right:20px;
 	margin-top:20px;
`

const CardColor = styled.div`
 	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
 	padding:30px 20px;
 	width:${props=>'calc('+props.width+'% - 20px)'};
 	margin-right:20px;
 	margin-top:20px;
 	background-image:linear-gradient(to bottom right, rgba(8, 194, 81,1),rgba(8, 194, 81,0.5));
 	color:white;
 	position:relative;

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:20px;
`

const Container = styled.div`
	display:flex;
	flex-wrap:wrap;
`



const TopicTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
	margin-bottom:10px;
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`	

const Elements = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
	margin-top:10px;
	margin-bottom:10px;
`
const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
	margin-top:10px;
`
const Score = styled.div`

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:18px;
  color:white;
  font-weight:bold;
`
const Top = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
`
const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`

const Li = styled.li`
	font-family: 'Red Hat Display', sans-serif;
`

const CardTop = styled.div`
	  box-shadow: 0 4px 20px 0 #ececec;
	  width:100%;
	  display:flex;
	  flex-direction:column;
	  padding:30px 40px 0px 40px;
	  margin-bottom:10px;
`

const Nav = styled.div`
	width:100%;
	display:flex;
`

const NavItem = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px 20px;
	height:50px;
	display:flex;
	align-items:center;
	color:${props=>props.selected?props.color:'#4a4a4a'};
	font-weight:bold;
	border-bottom:${props=>props.selected?'3px solid '+props.color:'none'};
	cursor:pointer;
	font-size:16px;
`
