import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import Chart from "react-apexcharts";
import Insights from "../Insights/Top3_payroll";
import structure from "../structure/survey_structure.json";
import Filter from "./Filter";

const Overview = ({ responses, categories, questions }) => {


	const structureOptions = () => {
		return structure.categories
			.filter((f) => f.priority == "Tertiary")
			.map((item, i) => {
				return {
					key: i,
					text: item.name,
					value: item.id,
				};
			});
	};

	const [options, setOptions] = useState(structureOptions());
	const [sub, setSub] = useState(options[0].value);
	const [selected, setSelected] = useState(null);

	
	return (
		<Container>
			<Top>
				<H3>Payroll Equity Audit</H3>
				<Updated>
					Last updated: Aug 2022
					<StyledButton>Connect API</StyledButton>
				</Updated>
			</Top>
			<Card>
				<Insights />
				<Right>
					<div style={{ position: "relative" }}>
						<Chart
							options={data.options}
							series={data.series}
							type="radialBar"
							height={200}
						/>
						<Values>
							<Stat>86%</Stat>
						</Values>
					</div>
					<TextArea>
						<Text>
							Your payroll equity score is <Change> Great</Change>
						</Text>
					</TextArea>
				</Right>
			</Card>
			<ChartArea>
				<Selection>
					<CTitle>Payroll breakdown by location and ethnicity</CTitle>
					<div style={{ display: "flex" }}>
						<Filter
							structure={structure}
							selected={selected}
							setSelected={(v) => setSelected(v)}
						/>
						<Dropdown
							options={options}
							defaultValue={options[0].value}
							value={sub}
							onChange={(e, { value }) => setSub(value)}
							style={{ marginLeft: 5 }}
							selection
						/>
					</div>
				</Selection>
				<Chart
					options={data3.options}
					series={data3.series}
					type="bar"
					height={400}
				/>
			</ChartArea>
		</Container>
	);
};

export default Overview;

const Container = styled.div`
	font-family: "Barlow", sans-serif;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

const Top = styled.div`
	width: 100%;
	padding: 10px 10px;
	margin-top: 20px;
	border-bottom: 1px solid lightgrey;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Updated = styled.div`
	font-size: 14px;
	color: lightgrey;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CTitle = styled.div`
	font-size: 18px;
	font-family: "Barlow Semi Condensed", sans-serif;
	font-weight: 600;
`;

const Grid = styled.div`
	width: calc(100% - 300px);
	display: flex;
	flex-wrap: wrap;
	height: 200px;
`;

const Area = styled.div`
	width: 33%;
	height: 50px;
	display: flex;
`;

const Selection = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Right = styled.div`
	width: 30%;
`;

const Score = styled.div``;

const ChartDiv = styled.div`
	width: 600px;
	height: 550px;
	padding: 20px;
	border: 1px solid lightgrey;
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
`;

const Description = styled.div`
	margin-top: 20px;
	margin-bottom: 10px;
`;

const H3 = styled.div`
	font-size: 28px;
	font-weight: bold;
	font-family: "Barlow Semi Condensed", sans-serif;
`;

const Change = styled.span`
	font-size: 16px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
	color: #ab710a;
	margin-left: 3px;
`;

const Card = styled.div`
	padding: 10px;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	background-color: white;
`;

const Values = styled.div`
	position: absolute;
	width: 50px;
	height: 50px;
	left: calc(50% - 25px);
	top: calc(50% - 25px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Stat = styled.div`
	font-size: 36px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
`;

const TextArea = styled.div`
	width: 100%;
	padding: 10px;
`;
const Text = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const Linked = styled.div`
	font-size: 12px;
	text-transform: uppercase;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
`;

const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2d50e2;
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;

const ChartArea = styled.div`
	width: 100%;
	padding: 30px;
`;

const data = {
	series: [86],
	options: {
		chart: {
			height: 250,
			type: "radialBar",
		},
		grid: {
			padding: {
				right: 0,
				top: 0,
				left: 0,
				bottom: 0,
			},
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: "65%",
				},
				track: {
					background: "white",
				},
				dataLabels: {
					show: false,
					name: { show: false },
					value: { show: false },
				},
			},
		},
		fill: {
			colors: ["#2d50e2"],
		},
	},
};

const data3 = {
	series: [
		{
			name: "Avg Salary",
			data: [45000, 53000, 65000, 28000, 26000, 13000],
		},
	],
	options: {
		chart: {
			type: "bar",
			toolbar: {
				show: false,
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#00E396"],
		xaxis: {
			labels: {
				rotate: -45,
			},
			categories: [
				"Apples",
				"Oranges",
				"Strawberries",
				"Pineapples",
				"Mangoes",
				"Bananas",
				"Blackberries",
				"Pears",
				"Watermelons",
				"Cherries",
				"Pomegranates",
				"Tangerines",
				"Papayas",
			],
			tickPlacement: "on",
		},
		yaxis: {
			min: 0,
			max: 100000,
			decimalsInFloat: 0,
			labels: {
				formatter: function (value) {
					return value + "$";
				},
			},
			title: {
				text: "Avg Salary",
			},
		},
	},
};
