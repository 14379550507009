import React from "react";
import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";

const MultiselectUser = (props) => {
  const { employeesList, onSelect, member, hideLabels, newPlanMembers } = props;
  const [selected, setSelected] = React.useState();

  React.useEffect(() => {
    if (member) {
      const filtered = member?.filter((m) => m !== undefined);
      if (filtered.length > 0) {
        setSelected([...filtered]);
      } else {
        if (newPlanMembers) {
          setSelected([...newPlanMembers]);
        }
      }
    }
  }, [member, newPlanMembers]);

  return (
    <Container>
      {!hideLabels && (
        <>
          <Title>Assignees</Title>
          <Label>Who is accountable for this plan?</Label>
          <Description>
            Tag team members to include them on the plan
          </Description>
        </>
      )}
      {employeesList && (
        <Dropdown
          placeholder="Tag team members"
          fluid
          selection
          multiple
          options={employeesList}
          value={selected}
          onChange={(e, t) => {
            setSelected(t.value);
            onSelect(t.value);
          }}
          clearable
          style={{
            marginTop: "10px",
            backgroundColor: "white",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "#e0e0e0",
            maxWidth: "350px",
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;

const Label = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;

`;

const Description = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  color: #7e7e7e;
`;

const Title = styled.div`
font-family: "Raleway";
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 21px;
/* identical to box height */

color: #6d6d6d;

margin-top: 20px;
`;

export default MultiselectUser;
