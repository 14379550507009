import React, { useState, useEffect } from "react";
import styled from "styled-components";
import EditAudit from "./EditAudit";
import { Label } from 'semantic-ui-react'

// import SignupToken from "components/SignupToken";

// import { roles, memberKey } from "data/employeeAuth";
// import { Progress, Icon } from "semantic-ui-react";
// import EmailDeliveryReport from "components/EmailDeliveryReport";

const MemberCard = (props) => {
  const {
    auditEnabled,
    data,
    setOpenFullCard,
    setOpenTeamCard,
    setFullEmpData,
    employeeCategory,
    teamToolEnabled,
    setLoading
  } = props;

  const [primaryList, setPrimaryList] = useState([]);
  const [secondaryList, setSecondaryList] = useState([]);

  useEffect(() => {
    setPrimaryList([]);
    // setSecondaryList([]);
    if (employeeCategory?.id) {
      if (employeeCategory?.category?.length > 0) {
        setPrimaryList(
          employeeCategory?.category
            .filter((ec) => ec.priority === "primary")
            .map((fl) => fl.value)
        );
        // setSecondaryList(
        //   employeeCategory?.category
        //     .filter((ec) => ec.priority === "secondary")
        //     .map((fl) => fl.value)
        // );
      }
    }
  }, [employeeCategory]);

  // console.log(employeeCategory)
  // console.log(primaryList)
  // console.log(secondaryList)

  function getShortList(data){
    return (
      <div style={{display:'flex',alignItems:'center'}}>
        {data.slice(0,1).map((pl,i)=>{
        return <Text key={i}>{pl}</Text>
      })}
        <div style={{marginLeft:3}}><Label size="mini">+{data.length-1} others</Label></div>
      </div>
      )
  }

  return (
    <MemberContainer>
      <MemberDetails>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "calc(100% - 56px)",
            wordWrap: "break-word",
          }}
        >
          <Name>
            {" "}
            {data?.first_name
              ? data?.first_name + " " + data?.last_name
              : data.email}
          </Name>
        </div>
      </MemberDetails>

      <RowContainer width={25} mobile={false}>
        <ListOfNames>
          {primaryList.length>1? getShortList(primaryList) : primaryList.map((pl, i) => {
            return <Text key={i}>{pl}</Text>;
          })}
        </ListOfNames>
      </RowContainer>


      <RowContainer width={25} mobile={false}>
        <Email>{data.updated_at.split("T")[0]}</Email>
      </RowContainer>
      <RowContainer width={25} mobile={true}>
        {/* the three dots are from below */}
        <EditAudit
          data={data}
          setOpenFullCard={setOpenFullCard}
          setOpenTeamCard={setOpenTeamCard}
          setFullEmpData={setFullEmpData}
          auditEnabled={auditEnabled}
          teamToolEnabled={teamToolEnabled}
          setLoading={setLoading}
          
        />
      </RowContainer>
    </MemberContainer>
  );
};

export default MemberCard;

const MemberContainer = styled.div`
  width: 100%;
  padding: 5px 0px 5px 0px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  font-family: "Barlow", sans-serif;
`;
const Avatar = styled.img`
  height: 34px;
  width: 34px;
  border-radius: 17px;
  border: solid 1px #e9e9e9;
  margin-right: 10px;
  min-width: 34px;
`;

const BlankAvatar = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 23px;
  background-color: #4a4a4a;
  margin-right: 10px;
  min-width: 46px;
`;

const Name = styled.div`
  font-size: 14px;
`;
const Email = styled.div`
  font-size: 14px;
`;
const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const ListOfNames = styled.ul`
  // Remove bullets
  list-style-type: none;
  // Remove padding
  padding: 0;
  // Remove margins
  margin: 0;
`;

const Status = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => (props.status < 3 ? "#9b9b9b" : "black")};
`;

const Text = styled.div`
  color: ${(props) => (props.leader ? "#009021 " : "black")};
  font-size: 14px;
  font-family: "Barlow", sans-serif;
`;

const RowContainer = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

const MemberDetails = styled.div`
  width: 25%;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 15px;

  @media (max-width: 768px) {
    width: 90%;
    padding: 0;
  }
`;
