import styled, { keyframes } from 'styled-components';
import React from "react";
import Progress from './Progress'

import Chart from "react-apexcharts";

const Summary = () =>{
  const [selected,setSelected] = React.useState(false)


    return (
        <Container>

            <T1>Your action plan</T1>
            <Text delay={0.5}>

              <T3>Lack of psychological safety and trust in expressing concerns</T3>
              <Description>
              The feedback indicates that some employees feel hesitant to bring up problems or provide candid feedback due to fear of negative consequences or avoidance by leadership. This suggests a need for creating a culture of psychological safety where employees feel comfortable and supported in identifying problems and expressing genuine concerns without fear of retribution or dismissal.
              </Description>
            </Text>

            <Text delay={0.5}>


              <T3>Ask for Feedback</T3>
              <Description>
              Encourage employees to provide feedback on your leadership style and departmental processes. You can ask, 'What do you think we can do better as a team, and how can I support you more effectively?'
              </Description>
            </Text>

            
        </Container>
    )
}

export default Summary

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.6;
    opacity:0;
    max-width:800px;


    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    border-radius:10px;
    font-size:14px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    color:#2D70E2;
    margin-top:20px;
    margin-bottom:5px;
    padding:5px 10px;
    background-color:#EBF1FD;
    font-weight:600;
    border-radius:5px;
    display:inline-block;
`


const T3 = styled.div`
    font-size:14px;
    font-weight:bold;
    color:#2A3039;
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const ChartContainer = styled.div`
    width:100%;
   
    margin-top:40px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`


const Blue = styled.span`
    color:#2D70E2;
    font-weight:500;
`


const data3 = {
  series: [{
    name: "Steps",
    data: [67, 68, 70, 71, 72, 74, 75, 77]
}],
	options: {
		chart: {
			type: "line",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 600,
			height: 400,
		},
    forecastDataPoints: {
      count: 6
    },
    markers: {
      size: 5,
      strokeColors: "#2D70E2",
    },
    grid:{
      padding:{
        right:100
      }
    },
    annotations:{
      points: [
        {
          x: 8,
          y: 77,
          marker: {
            size: 8,
          },
          label: {
            style:{
              background:'#2D70E2',
              color:'#fff',
              fontSize:'12px',
              fontWeight:600,
              padding:{
                left:10,
                right:10,
                top:5,
                bottom:5
              }
            },
            text: 'Your Goal',
            offsetY:-5
          }
        }
      ]
    },
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
		colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
		xaxis: {
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
				}
			}
		},
		yaxis: {
			min: 65,
			max: 80,
			decimalsInFloat: 1,
			title: {
				text: "Psychological Safety Scores",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
