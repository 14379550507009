import styled, { keyframes } from 'styled-components';
import React,{ useEffect, useState,useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Anchor from '../Anchor'
import { calculate_engagement_by_anchor } from "./caluclate_engagement";
import { SURVEY_TYPE } from "constants/surveys";
import ParticipationChart from "./ParticipationChart";
import Loading from './Loading';


import {
    SG_GET_DEBRIEF_SCHEDULES,
    SG_GET_EMPLOYEE_ORG_CATEGORIES,
    SET_DATA_ACNHOR
  } from "constants/actions";

const Participation = ({slideData, updateSlideData,loading}) =>{
    const dispatch = useDispatch();
    const [chartData,setData] = useState(null);
    const [chartURI, setChartURI] = useState(() => slideData?.chartURI || null);
    const [debriefSchedules, setDebriefSchedules] = useState([]);
    const [organizationId, setOrganizationId] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const surveyType = SURVEY_TYPE.AUDIT;
    const showItems = "history";

     // Ref to prevent cyclical updates
     const skipAnchorEffect = useRef(false);


    const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);

    const {
        get_debrief_schedules,
        get_auth,
        get_selectedOrg,
        get_employees,
        get_employee_org_category,
        get_survey_structure,
      } = useSelector(
        (state) => ({
          get_debrief_schedules: state.debrief_schedule,
          get_auth: state.auth,
          get_selectedOrg: state.selectedOrg,
          get_employees: state.employees,
          get_employee_org_category: state.employee_category?.employee_org_category,
          get_survey_structure: state.surveystructure.survey_structure,
        }),
        shallowEqual
      );

      useEffect(() => {
        if (!organizationId) {
          setOrganizationId(Number(get_auth.organization_id));
        }
      }, [get_selectedOrg, get_auth]);

    useEffect(() => {
        if(get_debrief_schedules){
            setDebriefSchedules(get_debrief_schedules.debrief_schedule.results);
        }
        
    }, [get_debrief_schedules]);

    useEffect(() => {
        if (organizationId && activePage) {
          dispatch({
            type: SG_GET_DEBRIEF_SCHEDULES,
            payload: `page=${activePage}&type_of=${surveyType}&organization=${organizationId}${
              showItems ? `&curr-status=${showItems}` : ""
            }`,
          });
    
          if (!get_employee_org_category?.[0]) {
            dispatch({
              type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
              payload: `organization=${organizationId}`,
            });
          }
        }
      }, [dispatch, organizationId]);

    

      useEffect(() => {
        if (debriefSchedules?.length > 0 && get_survey_structure && anchor) {
          
            setData(
                calculate_engagement_by_anchor(
                debriefSchedules,
                anchor,
                get_survey_structure,
                get_employee_org_category
                )
            );
        }
        }, [debriefSchedules, anchor,get_survey_structure,get_employee_org_category]);

          // Load data when a slide with existing data is selected
     useEffect(() => {

        if (slideData && slideData.anchor && slideData.anchor !== anchor) {
          skipAnchorEffect.current = true; // Set the flag
          dispatch({
            type: SET_DATA_ACNHOR, // Replace with your actual action type
            payload: slideData.anchor,
          });
        }
      }, [slideData]);


  
     // Handle anchor changes from user input
     useEffect(() => {
        if (skipAnchorEffect.current) {
          skipAnchorEffect.current = false; // Reset the flag
          return; // Skip updating slideData
        }
        if (anchor && (!slideData || slideData.anchor !== anchor || slideData.chartURI !== chartURI)) {
          const newSlideData = {
            ...slideData,
            chartURI,
            title:"Participation Rate",
            slide_template_id: 3,
            anchor
          };
          if (JSON.stringify(newSlideData) !== JSON.stringify(slideData)) {
            updateSlideData(newSlideData);
           
          }
        }
      }, [anchor,chartURI]);
    

    const handleSetChartURI = (uri) => {
            setChartURI(uri);
          };

    const check_chart_data = () =>{
      let total = 0
      chartData?.forEach((item)=>{
          total += item.data[0].total
      })

      return total > 0

    }

    if(loading){
        return <Loading/>
    }

    if(!check_chart_data()){
      return <div>No data</div>
    }
      

    return (
        <Container>
        <T1>Participation Rate Slide</T1>
        <SubTitle>Slide Options</SubTitle>
        <OptionArea>

          <div style={{ marginRight: 20 }}>
            <OptionTitle>Category</OptionTitle>
            <Anchor />
          </div>

        </OptionArea>
        <SlidePreview>
          <SlideTitle>Participation Rate</SlideTitle>

          <ParticipationChart setChartURI={handleSetChartURI} data={chartData}/>
          <SlideFooter>innerlogic</SlideFooter>
        </SlidePreview>
      </Container>
    )
}

export default Participation;

const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;


const Container = styled.div`
 opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`
const SlidePreview = styled.div`
    width:100%;
    height:550px;
    padding:20px;
    border:1px solid #e0e0e0;
    border-radius:10px;
    margin-top:20px;
    position:relative;
`

const Title = styled.div`
    margin-top:20px;
    font-size: 16px;
`

const SlideTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom:40px;
    font-family: 'Raleway';
    color:#333333;
`

const SlideFooter = styled.div`
    position:absolute;
    bottom:0;
    font-size: 12px;
    width:calc(100% - 40px);
    padding:10px 0px;
    border-top:1px solid #e0e0e0;
    display:flex;
`



const OptionArea = styled.div`
    margin-bottom:20px;
    display:flex;
    padding:20px;
    background-color:#f9fbfd;
    width:100%;
`

const OptionTitle = styled.div`
    font-size:12px;
    font-weight:500;
    margin-bottom:5px;
`
const SubTitle = styled.div`
    font-size: 14px;
    margin-top:20px;
    margin-bottom:5px;
    font-weight:600;
`

const T1 = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom:10px;
    color:#2A3039;
`