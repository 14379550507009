import React from 'react';
import styled, { keyframes } from 'styled-components';

const Loading = () => {
  return (
    <Container>
       <LoadingContainer>
        <Dot1 />
        <Dot2 />
        <Dot3 />
      </LoadingContainer>
    </Container>
   
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${pulse} 1s ease-in-out infinite;
`;

const Dot1 = styled(Dot)`
  background-color: #2D70E2;
`;

const Dot2 = styled(Dot)`
  background-color: #2D70E2;
  animation-delay: 0.2s;
`;

const Dot3 = styled(Dot)`
  background-color: #2D70E2;
  animation-delay: 0.4s;
`;

export default Loading;
