import styled, { keyframes } from "styled-components";
import React, { useState } from "react";
import { Radio } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SG_CREATE_DEBRIEF_PDF_REPORT } from "constants/actions";
import { v4 as uuidv4 } from "uuid";
import { getReportLink } from "../utils";

const Permissions = ({
  primaries,
  categories,
  parentCategory,
  get_auth,
  anchorName,
  setPdfOptions,
  pdfOptions,
  templateId,
  orgName,
  nomenclature,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    showWarning: false,
  });

  const createReport = () => {
    const uuid = uuidv4();
    const report = {
      template_id: templateId,
      organization: Number(get_auth?.organization_id),
      employee: Number(get_auth?.employee_id),
      tracking_code: uuid,
      params: {
        parent_id: parentCategory,
        parent_children: categories,
        primary_list: primaries,
        pdfOptions: options,
        orgName: orgName,
        report_link: getReportLink(templateId),
      },
    };

    dispatch({
      type: SG_CREATE_DEBRIEF_PDF_REPORT,
      payload: report,
    });

    navigate("/leader/audit/reports");
  };

  return (
    <Container>
      <T1>Generate your {nomenclature === "Story" ? "story" : "report"}</T1>
      <Description>
        You're just about set! Click the button below to generate your{" "}
        {nomenclature === "Story" ? "story" : "report"}.
      </Description>

      <ButtonContainer>
        <Button2 onClick={() => createReport()}>
          Generate {nomenclature || "Report"}
        </Button2>
      </ButtonContainer>
    </Container>
  );
};

export default Permissions;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  font-family: "Raleway";
`;
const T2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Text = styled.div`
  max-width: 80%;
  line-height: 1.5;
`;

const Option = styled.div`
  margin-bottom: 20px;
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const Card = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ebf1fd;
  border-radius: 10px;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;

const T3 = styled.div`
  font-size: 14px;
  font-weight: bold;

  margin-top: 20px;
`;
const Button = styled.div`
  background-color: #2a3039;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #1a2029;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;
    z-index:-1;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`;

const Button2 = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
`;

const friendOptions = [
  {
    key: "Anyone with access to this department",
    text: "Anyone with access to this department",
    value: "Anyone with access to this department",
  },
  {
    key: "Only members who have been added",
    text: "Only members who have been added",
    value: "Only members who have been added",
  },
];
