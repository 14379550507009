export const primaryColor = "#2D70E2";
export const inactiveColor = "#666D79";
export const labelColor = {
 text: "#27CDA7",
 background: "#F3FDFA",
};
export const unreadColor = "#FE3E85";
export const neutralColor = "#DFDFDF";
export const filterColor = {
 text: inactiveColor,
 activeText: primaryColor,
 activeBackground: "#E3EEFF",
 background: "#F8FAFF",
};
export const defaultText = "#2A3039";

export const fontSizes = {
 xsmall: "10px",
 small: "12px",
 medium: "14px",
 large: "18px",
 xlarge: "20px",
};

// Word exception list
// this list is used to filter out words that are not relevant to the search
export const wordExceptionList = ["et al", "St"];
