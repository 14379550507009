import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Loader, Select } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Chart from "react-apexcharts";
// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import CategorizedDropdown from "../DataScience/CategorizedDropdown";

import Model from "assets/images/nav/fa6-solid_layer-group.svg";

const create_option_list = (categories, questions, type, records) => {
  let options = [];

  categories.categories.map((item) => {
    if (type === 1) {
      options.push({ text: item.name, value: item });
    }
    if (type === 2) {
      item.options.forEach((o) => {
        options.push({
          text: o.name,
          value: { text: o.name, type: item.name, id: o.id, qId: item.id },
        });
      });
    }
  });

  let personality_factors = [
    "Extraversion",
    "Conscientiousness",
    "Agreeableness",
    "Neuroticism",
    "Openess",
  ];
  if (type === 2) {
    personality_factors.forEach((f, i) => {
      options.push({
        text: f,
        value: { type: "personality", id: i, text: f },
      });
    });

    records.forEach((item, i) => {
      options.push({
        text: item.name,
        value: { type: "records", id: item.id, text: item.name },
      });
    });
  }

  return options;
};

let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return Math.round(((score1 + score2) / 2) * 100) / 100;
};

const retrieveCategoryAverages = (responseData, vertical, variable) => {
  let values = [];
  vertical.options.forEach((op) => {
    const optionResponses = responseData.filter((fr) => {
      return fr.categories.find((r) => {
        return r.response === op.id;
      });
    });

    if (variable.type === "personality") {
      const matchingResponses = optionResponses.map((or) => {
        return calculate_personality(or.survey_personality, variable.id);
      });

      let sum = 0;
      matchingResponses.forEach((r) => {
        sum += r;
      });

      values.push({
        name: op.name,
        id: op.id,
        percent:
          (matchingResponses.length > 0 &&
            Math.round((sum / matchingResponses.length) * 10)) ||
          0,
      });
    } else if (variable.type === "records") {
      const numericalRegex = /[$|€|£]?(\d+\s?[\,?\d{3,}]*\.?\d+)/;

      const matchingResponses = optionResponses.map((or) => {
        const val = or.employee_records.find(
          (f) => f.id === variable.id
        )?.value;
        let cleanedVal = val.toString().match(numericalRegex);
        cleanedVal = cleanedVal.input.replace(/[$|€|£]?/g, "");
        cleanedVal = cleanedVal.replace(/,/g, "");
        return Number(cleanedVal);
      });

      let sum = 0;
      matchingResponses.forEach((r) => {
        sum += r;
      });

      values.push({
        name: op.name,
        id: op.id,
        percent:
          (matchingResponses.length > 0 &&
            Math.round(sum / matchingResponses.length)) ||
          0,
      });
    } else {
      const matchingResponses = optionResponses.filter((or) => {
        return or.categories.find((cr) => cr.response === variable.id);
      });

      values.push({
        name: op.name,
        id: op.id,
        percent:
          (matchingResponses.length > 0 &&
            Math.round(
              (matchingResponses.length / optionResponses.length) * 100
            )) ||
          0,
      });
    }
  });

  values.sort((a, b) => a.percent > b.percent && -1);
  return values;
};

const Comparison = ({ data, records }) => {
  const dispatch = useDispatch();
  const [horizontal, setHorizontal] = React.useState();
  const [vertical, setVertical] = React.useState();
  const [seriesData, setSeriesData] = React.useState();
  const [anchor_list, setAnchorList] = React.useState();
  const [anchor_list2, setAnchorList2] = React.useState();

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({ surveyType: 6, auditTypeId: 3 });

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  });

  useEffect(() => {
    if (data.length > 0 && horizontal && vertical) {
      const calculated = retrieveCategoryAverages(data, vertical, horizontal);

      if (calculated.length > 0) {
        const series = {
          series: [
            {
              name: "Scores",
              data: calculated.map((group) => group.percent),
            },
          ],
          options: {
            chart: {
              type: "bar",
              fontFamily: "Poppins, sans-serif",
              foreColor: "#7E7E7E",
              toolbar: {
                show: false,
              },
              width: "100%",
              height: 400,
            },
            legend: {
              position: "top",
              horizontalAlign: "left",
            },
            plotOptions: {
              bar: {
                columnWidth: "50%",
                colors: {
                  backgroundBarOpacity: 1,
                },
              },
            },
            colors: ["#476DFA"],
            xaxis: {
              labels: {
                rotate: -45,
                rotateAlways: true,
                offsetY: 5,
                minHeight: 110,
              },
              categories: calculated.map((calc) => calc.name),
              tickPlacement: "on",
            },
            yaxis: {
              decimalsInFloat: 0,
              title: {
                text: horizontal.text,
                style: {
                  fontWeight: 400,
                },
              },
            },
          },
        };
        setSeriesData(series);
      }
    }
  }, [data, horizontal, vertical]);

  useEffect(() => {
    if (get_survey_structure[0] && questionStructure) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1
      );

      setAnchorList(anchor_list);
      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2,
        records
      );

      setAnchorList2(anchor_list2);
      if (anchor_list) {
        setHorizontal(
          anchor_list.find((f) => f.value.priority == "secondary").value
        );
        setVertical(
          anchor_list.find((f) => f.value.priority == "primary").value
        );
      }
    }
  }, [get_survey_structure[0], questionStructure]);

  if (!get_survey_structure[0]) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>Group Comparison</Title>
      <OptionArea>
        <div style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
          <Label>Category</Label>
          <StyledSelect
            placeholder="Select Survey Type"
            options={anchor_list}
            value={vertical}
            id="anchors"
            onChange={(e, t) => setVertical(t.value)}
          />
        </div>

        <div style={{ marginLeft: 30, display: "flex", alignItems: "center" }}>
          <Label>Variable</Label>
          <CategorizedDropdown
            placeholder="Select a Variable"
            filterOptions={anchor_list2}
            value={horizontal}
            id="anchors"
            onSelectLabel={(t) => setHorizontal(t.value)}
          />
        </div>
      </OptionArea>
      {seriesData && (
        <Card>
          <TableTitle>
            {" "}
            <Image src={Model} />
            Team comparison by {vertical?.name} ({horizontal?.text})
          </TableTitle>
          <Sub>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus vitae
            faucibus nisl venenatis, ut etiam proin neque.
          </Sub>

          <Chart
            options={seriesData.options}
            series={seriesData.series}
            type="bar"
            height={400}
          />
        </Card>
      )}
    </Container>
  );
};

export default Comparison;

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
  margin-bottom: 30px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const Card = styled.div`
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 40px 20px 20px;
  margin-right: 20px;
`;

const TableTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #414141;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 20px;
  color: #7e7e7e;
  padding-left: 33px;
`;

const Image = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [19.5, 18.3, 16.4, 16.3, 16.2, 13, 12],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: "100%",
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        colors: {
          backgroundBarOpacity: 1,
        },
      },
    },
    colors: ["#476DFA"],
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: true,
        offsetY: 5,
        minHeight: 110,
      },
      categories: [
        "Team A",
        "Team B",
        "Team C",
        "Team D",
        "Team E",
        "Team F",
        "Team G",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      decimalsInFloat: 0,
      title: {
        text: "Performance Review Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
