import React, { useState } from "react";
import styled, { keyframes,css } from "styled-components";
import { Icon } from "semantic-ui-react";


const Container = styled.div`
 width:100%%;
 
 margin-bottom:20px;



`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom:10px;
`
const H2 = styled.h2`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
`;
const P1 = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 15px;
  line-height:1.5em;
  transition:max-height 0.5s;
`;



const TopBox = styled.div`
  border-radius: 17.5px;
  background-color: #ebebeb;
  padding: 30px 60px 20px 30px;
  width: 49%;

  @media (max-width:768px){
    width:100%;
    padding:20px;
    margin-top:20px;
  }
`;

const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  padding-left: 5px;
  cursor: pointer;

`;

const BoxContainer = styled.div`
	display:flex;
	justify-content:space-between;

	@media(max-width:768px){
		flex-direction:column;
	}
`

const TraitInfo = (props) =>{


	return (
		<Container>
			<Title>What is {props.trait} ?</Title>
			<P1>{props.data?.definition}</P1>
			{!props.sub?(
			<BoxContainer>
				 <TopBox>
		          <H2>
		            Low {props.trait}{" "}
		            <Icon
		              name="arrow circle down"
		              style={{ color: "#4a4a4a",fontSize:20 }}
		            />
		          </H2>
		          <P1 show>{props.data?.low}</P1>
		        </TopBox>

		         <TopBox>
		          <H2>
		            High {props?.trait}{" "}
		            <Icon
		              name="arrow circle up"
		  
		              style={{ color: "#4a4a4a",fontSize:20}}
		            />
		          </H2>
		          <P1 show>{props.data?.high}</P1>

		        </TopBox>
		     </BoxContainer>):"" }
		</Container>
		)
}

export default TraitInfo