import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

import Metrics from "./Phases/Metrics";
import Target from "./Phases/Target";
import Action from "./Phases/Plan";
import Track from "./Phases/Track";

const phases = [
	{ id: 0, title: "Assess" },
	{ id: 1, title: "Target" },
	{ id: 2, title: "Plan" },
	{ id: 3, title: "Track" },
];

const metrics = [
	{ title: "Safety", type: "People", id: 1 },
	{ title: "Well-being", type: "People", id: 2 },
	{ title: "Cohesion", type: "People", id: 3 },
	{ title: "Empowerment", type: "People", id: 4 },
	{ title: "Personalization", type: "People", id: 5 },
	{ title: "Vision", type: "Performance", id: 6 },
	{ title: "Identity", type: "Performance", id: 7 },
	{ title: "Climate", type: "Performance", id: 8 },
	{ title: "Accountability", type: "Performance", id: 9 },
	{ title: "Adaptability", type: "Performance", id: 10 },
];

const Plan = ({ save, addPlan }) => {
	const [selected, setSelected] = useState(0);
	const [metric, setMetric] = useState(null);
	const [question, setQuestion] = useState("");
	const [time, setTime] = useState(null);

	const continued = () => {
		if (selected === 0 && metric) {
			return setSelected(selected + 1);
		}
		if (selected === 1 && question) {
			return setSelected(selected + 1);
		}
		if (selected === 2) {
			return setSelected(selected + 1);
		}
		if (selected == 3 && time) {
			addPlan({
				question,
				metric: metrics.find((f) => f.id == metric),
				time,
			});
			save();
		}
	};

	const back = () => {
		if (selected > 0) {
			setSelected(selected - 1);
		}
	};
	return (
		<Container>
			<Steps>
				{phases.map((i) => (
					<Step
						selected={selected == i.id}
						title={i.title}
						last={i.id == phases.length - 1}
					/>
				))}
			</Steps>
			{selected == 0 ? (
				<Metrics setMetric={(m) => setMetric(m)} />
			) : selected == 1 ? (
				<Target
					metric={metrics.find((f) => f.id == metric)}
					setQuestion={(q) => setQuestion(q)}
				/>
			) : selected == 2 ? (
				<Action question={question} />
			) : (
				<Track setTime={(t) => setTime(t)} />
			)}
			<Actions>
				<Back onClick={() => back()}>Back</Back>
				<StyledButton onClick={() => continued()}>
					{selected == phases.length - 1 ? "Save" : "Continue"}
				</StyledButton>
			</Actions>
		</Container>
	);
};

const Step = ({ last, title, selected }) => {
	return (
		<StepContainer>
			<Circle selected={selected} />
			<Title>{title}</Title>
			{!last && <Line />}
		</StepContainer>
	);
};

export default Plan;

const Container = styled.div`
	width: 100%;
	margin-top: 30px;
`;

const Steps = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 30px;
`;

const StepContainer = styled.div`
	width: 100px;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: relative;
`;

const Circle = styled.div`
	height: 10px;
	width: 10px;
	border-radius: 5px;
	background-color: ${(props) => (props.selected ? "lightgrey" : "grey")};
	border: ${(props) =>
		props.selected ? "2px solid red" : "2px solid black"};
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: 300;
`;

const Line = styled.div`
	height: 2px;
	width: 90px;
	position: absolute;
	background-color: grey;
	top: 5px;
	left: calc(50% + 5px);
`;

const Actions = styled.div`
	height: 60px;
	width: 100%;
	position: fixed;
	bottom: 0;
	background-color: lightgrey;
	margin-left: -20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 100px;
`;
const StyledButton = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #2d50e2;
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;

const Back = styled.button`
	color: white;
	font-size: 14px;
	font-family: "Barlow Semi Condensed", sans-serif;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 5px;
	background-color: #7f7f7f;
	border: none;
	outline: none;
	padding: 0px 30px;
	border: 1px solid white;

	@media (max-width: 768px) {
		height: 30px;
		font-size: 12px;
		padding: 0 10px 0 10px;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0);
		border: 1px solid #2d50e2;
		color: #2d50e2;
	}
`;
