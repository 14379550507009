import React from "react";
import styled from "styled-components";

const IconContainer = (props) => {
  const { icon, backgroundColor = "#EBF1FD" } = props;
  return (
    <IconBox
      style={{
        background: backgroundColor,
      }}
    >
      {icon}
    </IconBox>
  );
};

const IconBox = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 8px;
`;

export default IconContainer;
