import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";

import Pic1 from 'assets/images/performance.png'
import Pic2 from 'assets/images/google-docs.png'
import { Button, Icon, Modal } from 'semantic-ui-react'

const TypesOfBriefs = [
	{
		id: 1,
		name: 'Performance',
		describe: "Your home for tracking performance debrief data",
		learnMore: "link goes here",
		image: Pic1,
		'description': "Participants are asked to score the team's efforts in the 5 C's (Consistency, Composure,Confidence,Compete,Cohesion)",
		questions: ['We sustained our best all the way through this performance, from start to finish.',
			'We met the most difficult moments of this performance with calmness, clarity, and control.',
			'I expected us to be successful going into this performance - I believed in our ability to meet the task.',
			'We brought our full effort and energy to this performance - we played with purpose, passion, and never gave up.',
			'The interactions I had with my teammates during this performance energized and empowered me to be my best.']
	},
	{
		id: 2,
		name: 'Training',
		describe: "Your home for tracking training debrief data",
		learnMore: "link goes here",
		image: Pic2,
		description: "Participants are asked to reflect on takeaways from the most recent training session, as well as rate their response to a difficult challenge",
		questions: ['The three biggest takeaways or sticking points for me today were…', 'The most difficult challenge or moment I faced today was', 'I would rate my response to this challenge as.', 'Based on today’s session, one thing I would like to improve is..']
	}
]



const DebriefTypes = () => {

	return (
		<Types>
			{TypesOfBriefs.map((brief, i) => {
				return (
					<TypeContainer key={i}>
						<div>
							<TypeName>{brief?.name}</TypeName>
							<Description>{brief?.describe}</Description>
							<DebriefInformation trigger={<See>Learn more</See>} data={brief} />
						</div>
						<div>
							<Image src={brief?.image} />
						</div>
					</TypeContainer>
				)
			})}
		</Types>

	)
}


export default DebriefTypes


function DebriefInformation(props) {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
       closeIcon
      trigger={props.trigger}
    >
      <Modal.Header>{props.data.name} Questions</Modal.Header>
      <ModalContainer >
      	
      	<Description>{props.data.description}</Description>

      	<Title>Questions Asked</Title>
      	<ul>
      	{props.data.questions.map((item, i)=><Li key={i}>{item}</Li>)}
      	</ul>
      </ModalContainer>

    </Modal>
  )
}


const Types = styled.div`
	width:100%;
	display:flex;
`

const TypeContainer = styled.div`
	width:400px;
	margin-right:30px;
	margin-top:20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display:flex;
	padding:30px;
    cursor: pointer;
`
const TypeName = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
`

const Description = styled.div`
	color:#757575;
`

const Image = styled.img`
	width:100px;
`

const See = styled.div`
	margin-top:10px;
	color:#6a00ff;
`
const Title = styled.div`
	font-weight:bold;
	font-size:16px;
	margin-bottom:20px;
	margin-top:30px;
`

const ModalContainer = styled.div`
	padding:20px;
`

const Li = styled.li`
	font-size:14px;
	margin-bottom:10px;
`