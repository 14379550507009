import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import Chart from "react-apexcharts";
import {Icon} from 'semantic-ui-react';


const Change = () =>{

    const [options, setOptions] = useState(null);
    const [series,setSeries] = useState(data3.series)
    const [selected,setSelected] = useState(null);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let _options = data3.options
        _options.chart.events = {
            dataPointSelection: (event, chartContext, config) => {
                console.log(chartContext, config);
                setSelected(true)
              }
          }
          setOptions(_options)

          setTimeout(()=>{
                setLoading(false)
            },500)

    }, [])

    useEffect(()=>{
        if(selected){
            setLoading(true)
            setSeries(data4.series)
            setOptions(data4.options)

            setTimeout(()=>{
                setLoading(false)
            },500)
        }
    },[selected])

    if(loading){
        return ""
    }

    return (
        <Container>
                 <Title>{selected? 'Department A culture factor':'Overall culture'} score changes</Title>
                <Description>
                    According to your collected data, the average culture score for your organization is 7.6. 
                    The following chart shows the difference between the average score for each department and the global benchmark average.
                </Description>

                {options && <Chart
                            options={options}
                            series={series}
                            type="bar"
                            width={800}
                        />}
        </Container>
    )
}


export default Change


const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
opacity:0;
animation: ${fadeIn} 0.5s ease forwards;
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;

   

`

const Description = styled.div`
    margin-bottom:30px;
    line-height:1.4;

   
`


const Help = styled.div`
    display:inline;
    padding:5px 10px;
    border:1px solid #666D79;
    color:#666D79;
    font-size:12px;
    font-family:'Raleway';
    margin-bottom:10px;
    position:absolute;
    right:0px;
    top:10px;
    cursor:pointer;
    
`


const data3 = {
	series: [
		{
			data: [10,8,4.4,-3,-8,-20,-22],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800,
			height: 400,
            
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
		colors: ["#27CDA7", "#27CDA7", "#27CDA7",'#F15062','#F15062','#F15062','#F15062'],
		xaxis: {
			categories: [
				"Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department G"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: "#2D70E2",
				}
			}
		},
		yaxis: {
			min: -30,
			max: 20,
			decimalsInFloat: 0,
			title: {
				text: "Overall culture score changes",
				style:{
					fontWeight:400
				}
			},
		},
	},
};



const data4= {
	series: [
		{
			data: [17,15,5,-3,-8,-9,-10],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800,
			height: 400,
            
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
		colors: ["#27CDA7", "#27CDA7", "#27CDA7",'#F15062','#F15062','#F15062','#F15062'],
		xaxis: {
			categories: [
				"Psychological Safety",
                "Adaptability",
                "Empathy",
                "Belonging",
                "Learning",
                "Values",
                "Clarity"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: "#2D70E2",
				}
			}
		},
		yaxis: {
			min: -30,
			max: 20,
			decimalsInFloat: 0,
			title: {
				text: "Overall culture score changes",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
