import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState } from "react";




const ItemTable = (
    {chartData,
    setSelected
    }
) =>{


    const selectItem = (item,question)=>{
        setSelected({question,item})
    }


    if(!chartData) return <div>Loading...</div>


    return (
        <TableContainer>
            <Scale color={"181, 181, 181"}>
                <TH width={52.7} />
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                    <Num>{i}</Num>
                ))}
                <Num2 />
            </Scale>
                {Object.keys(chartData?.questionSeries).map((question, i) =>{
                    return(
                        <TableRow
                        item={question}
                        idx={i}
                        data={chartData?.questionSeries[question]}
                        lastData={chartData?.questionLastSeries[question]}
                        overall={chartData?.questionOverallStats[question]}
                        labels={chartData?.categories}
                        last={chartData?.questionLastOverallStats[question]}
                        onSelect={(item)=>selectItem(item,question)}
                        color={"rgba(181, 181, 181,1)"}
                    />
                    )
                }
                   )}
           
        </TableContainer>
    )
}

export default ItemTable;


const getWidth = (average,stdev) =>{
	if(average-stdev <0){
		return average + stdev
	}
	else if(average + stdev > 10){
		return 10-average + stdev
	}

	return stdev*2
}

const getOffset = (average,stdev) =>{
	if(average-stdev <0){
		return 0
	}

	return average - stdev
}



const TableRow = ({item, idx, data, color,labels,overall,last,lastData,onSelect}) =>{

    const [open, setOpen] = useState(false);

    const sortedData = data['Average Score'].data.map((item,idx)=>{
        return {name:labels[idx],value:item,last:lastData['Last Average Score'].data[idx]}
    }).sort((a,b)=>b.value-a.value)

	if(!overall?.average) return null

    const average = overall?.average.toFixed(1)
    const stdev = overall?.standardDeviation



    const change = last.average? ((overall.average - last.average)*10).toFixed(1) : '--'


    const getChange = () =>{

        if(change){
            return <span style={{color:change>0?'green':'red',marginLeft:5}}>{`(${change>0?'+':""} ${change}%)`}</span>
        }

        return ""
    }


    const getChangeValue = (changed) =>{

        if(change){
            return <span style={{color:changed>0?'green':'red',marginLeft:5}}>{`(${changed>0?'+':""} ${(changed*10).toFixed(1)}%)`}</span>
        }

        return ""
    }

    return (
        <>
        <TR onClick={() => setOpen(!open)}>


                <TD
					width={40}
					borderRight={true}
					top={idx===0}
				>
					{item}
				</TD>

                <TD width={15} center={true} bold={item == "Average"} top={idx===0}>
					{average} {getChange()}
				</TD>

                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
					<TD width={4.09} dash={true} noBottom={i == 10} top={idx===0}></TD>
				))}

                <BarArea>
					<div style={{width:'100%',position:'relative',height:'100%',alignItems:'center',display:'flex'}}>
						<STDBAR width={getWidth(average,stdev)} left={getOffset(average,stdev)}/>
						<Bar left={average} color={color} show={stdev==0}/>
					</div>
				</BarArea>
        </TR>

            <Details open={open}>
            <TD width={40} borderRight={true}></TD>
            <TD width={15} center={true}>
                <div
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column",
                        width:'100%'
                    }}
                >
                    {sortedData.map((x, i) => {
                         if(!x.value) return null
                        return (
                            <div style={{width:'100%'}}>
                                        <LabelContainer onClick={()=>onSelect(x.name)}>
                                            {x.name}
                                        </LabelContainer>
                            </div>
                        );
                    })}
                </div>
            </TD>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <TD width={4.09} dash={true} noBottom={i == 10}></TD>
            ))}

            <BarContainer>
                {sortedData.map((c, i) => {
                    if(!c.value) return null
                    return (
                        <div style={{height:15,display:'flex',alignItems:'center'}}>
                        <Bar2
                            width={c.value*4.09}
                            color={'#C0C0C0'}
                        >
                            <Score>{c.value.toFixed(1)} {c.last && getChangeValue(c.value-c.last)}</Score>
                        </Bar2>
                        </div>
                    );
                })}
            </BarContainer>
            </Details>
        </>


    )
}


const TableContainer = styled.div`
	width: 100%;
	margin-bottom: 30px;
`;


const Scale = styled.div`
	margin-bottom:5px;
	display: flex;
`;



const Highlight = styled.div`
	margin-top: 20px;
	font-style: italic;
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;

color: #7E7E7E;
`;


const Num = styled.div`
	width: 4.09%;
	font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
text-align: center;

color: #7E7E7E;
	position: relative;
	display: flex;
	justify-content: center;
`;

const Num2 = styled.div`
	width: 2%;

	position: relative;
	display: flex;
	justify-content: center;
`;


const TH = styled.div`
	width: ${(props) => props.width + "%"};
`;


const TR = styled.div`
	width: 100%;
	display: flex;
	position: relative;
	cursor: pointer;
	transition: all 0.5s;
	background-color: ${(props) =>
		props.highlight ? "#F5F5F5" : "white"};

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
	}
`;


const BarContainer = styled.div`
	position: absolute;
	top: 0;
	left: calc(55% - 2px);
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: space-between;
	padding:10px 0px;
    padding-bottom:20px;
`;

const BarArea = styled.div`
	width:42%;
	position:absolute;
	left:54.5%;
	height:100%;
	align-items:center;
	display:flex;
`

const Bar = styled.div`
	width: 12px;
	height: 12px;
	border-radius:6px;
	position: absolute;
	left:${props=>'calc('+props.left*10+'% - 6px)'};
	top: calc(50% - 6px);
	background-color:${props=>props.show?'#E66579':'#2D70E2'};
`;

const STDBAR = styled.div`
	height:12px;
	width:${props=>props.width*10+'%'};
	background-color:lightgrey;
	opacity:1;
	left:${props=>'calc('+props.left*10+'%)'};
	position:absolute;
	border-radius:10px;
`

const Bar2 = styled.div`
	width: ${(props) => props.width + "%"};
	height: 12px;
	background-color: ${(props) => props.color};
	position: relative;
	border-radius: 10px;
`;


const TD = styled.div`
	width: ${(props) => props.width + "%"};
	display: flex;
	flex-direction: row;
	align-items: ${(props) => (props.center ? "center" : "flex-start")};
	justify-content: ${(props) => (props.center ? "center" : "flex-start")};
	padding: ${(props) => (props.dash ? "0px " : "10px 5px")};
	border-right: ${(props) =>
		props.borderRight ? "1px solid #DFDFDF" : "none"};
	font-weight: ${(props) => (props.bold ? "bold" : 400)};
	border-left: ${(props) => (props.dash ? "1px dashed #DFDFDF " : "none")};
	border-bottom: ${(props) => (!props.noBottom ? "1px solid #DFDFDF" : "none")};
	border-top:${(props) => (props.top ? "1px solid #DFDFDF" : "none")};
	box-sizing: ${(props) => (props.dash ? "content-box " : "border-box")};
	position: relative;
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;

color: #7E7E7E;

`;

const Details = styled.div`
	width: 100%;
	max-height: ${(props) => (props.open ? "1000px" : 0)};
	transition: all 1s;
	display: flex;
	overflow: ${props=>props.open?'visible':'hidden'};
	position: relative;
`;


const Score = styled.div`
	position: absolute;
	left: calc(100% + 10px);
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
display:flex;
color: #7E7E7E;
width:100px;
`;


const LabelContainer = styled.div`
	cursor: pointer;
	height:20px;
	display:flex;
	align-items:center;
	font-size:12px;
	overflow:hidden;
	margin-bottom:5px;
     overflow: hidden;          /* Hide the overflowed text */
  text-overflow: ellipsis;   /* Display ellipsis (...) */
  white-space: nowrap;       /* Prevent text from wrapping to the next line */
  width:100%;

	&:hover {
		font-weight: bold;
		text-decoration: underline;
	}
`;