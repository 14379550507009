import React, {useEffect,useState} from 'react';
import styled, { keyframes } from 'styled-components';
import {
  UPDATE_EMPLOYEE
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Dropdown2 from './dropdown_role'
import { useForm, Controller } from "react-hook-form";
import { Message,Dropdown } from 'semantic-ui-react'

import { useToasts } from 'react-toast-notifications'

import {roles} from "data/employeeAuth"

const email_validation = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const AddMember = (props) =>{
	const apiDispatch = useDispatch();
	const { addToast } = useToasts()
	 const methods = useForm();
	const [email,setEmail] = useState("")
	const[memberList,setMemberList] = useState([])
	const [role,setRole] = useState(null)
	const members = useSelector((state) => state.employees.employees)

	const { register, handleSubmit, control, reset, errors } = methods;

	useEffect(()=>{
		setRole(props.role)
	},[props.role])

	useEffect(()=>{
		if(members){
			setMemberList(members.filter(m=>m.team==null).map(item=>({key:item.id,value:item.id,text:item.first_name?item.first_name+' '+item.last_name:item.email})))
		}
	},[members])

	const invite = (data) =>{
			let selected = memberList.filter(m=>m.key==data.id)[0]
			apiDispatch({
		      type: UPDATE_EMPLOYEE,
			   payload:{
			   	id:data.id,
		        role: roles.find(({role}) => role === data.role).role,
		        team: props.team},
			    });
			addToast(selected.text+' has been successfully invited to this team', 
		        { appearance: 'success',
		        autoDismiss: true, })
		    props.cancel()
		
	}


	return (

		<MemberContainer visible={props.visible}>
			<DropdownContainer1>
				<Controller
					as={(props)=><Dropdown
					    fluid
					    search
					    selection
					    onChange={(e,{value})=>props.onChange(value)}
					    value={props.value}
					    options={memberList}
					     placeholder='Select member'
					  />}
				    name="id"
					control={control}
					rules={{
	                required: "Please select member",
	              }}
					/>
				{errors.id && <Error>{errors.id.message}</Error>}

			</DropdownContainer1>

			<DropdownContainer2>
				
				<Controller
		            as={<Dropdown2
		            	 defaultValue={role}/>}
		            name="role"
		            placeholder="Choose role"
		            options={roles}
		            setRole={(r)=>setRole(r)}
		            rules={{
	                required: "Please choose a role",
	              }}
		            control={control}/>
		            {errors.role && <Error>{errors.role.message}</Error>}
			</DropdownContainer2>

			<div style={{width:'30%',display:'flex',height:'100%',alignItems:'center',padding:'0 0 0 15px'}}>
				<form onSubmit={handleSubmit(invite)}>
					<Button type="submit">Add</Button>
				</form>
				<CancelButton onClick={()=>props.cancel()}>Cancel</CancelButton>
			</div>
		</MemberContainer>
		)

}

export default AddMember

const MemberContainer = styled.div`
	width:100%;
	padding:${props=>props.visible? '26px 0px 26px 0px' : 0};
	border-bottom:${props=>props.visible? '1px solid #e9e9e9' : 'none'};
	display:flex;
	flex-direction:row;
	align-items:center;
	font-size: 16px;
  font-weight: 500;
  font-family: 'Red Hat Display', sans-serif;
  color: #4a4a4a;
  max-height:${props=>props.visible? '100px' : 0};
  overflow:${props=>props.visible? 'visible' : 'hidden'};;
  transition:max-height 500ms,padding 500ms,overflow 500ms;


  @media (max-width:768px){
  	flex-direction:column;
	  max-height:${props=>props.visible? '200px' : 0};
	  align-items:flex-start;
  }
`
const Button = styled.button`
	width: 102px;
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-left:64px;
   border: none;
    outline:none;
`

const CancelButton = styled.div`
	width: 99px;
	min-width:99px;
  height: 45px;
  border-radius: 8px;
  background-color: #e9e9e9;
  color:#4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  margin-left:16px;
`
const StyledInput = styled.input`
  width:100%;
  padding:5px;
  background-color:transparent;
  border:none;
  font-family: 'Red Hat Display', sans-serif;
  &:focus {
        outline: none;
    }
`

const InviteBox = styled.div`
	width:350px;
	border-radius:20px;
	background-color:#ebebeb;
	color:grey;
	transition:width 1s;
	font-family: 'Red Hat Display', sans-serif;
	padding: 5px 15px 5px 15px;
	z-index:1
`

const Error = styled.div`
	font-size:14px;
	font-style: italic;
	color:#ff2d55;
	margin-left:20px;
	margin-top:5px;
`

const DropdownContainer1 = styled.div`
	width:40%;
	display:flex;
	height:100%;
	padding:0 0 0 15px;
	flex-direction:column;

	@media (max-width:768px){
		width:100%;
		margin-bottom:10px;
	}
`
const DropdownContainer2 = styled.div`
	width:30%;
	display:flex;
	height:100%;
	padding:0 0 0 15px;
	flex-direction:column;

	@media (max-width:768px){
		width:100%;
		margin-bottom:10px;
	}
`