import styled from "styled-components";
import React, { useState, useEffect } from "react";
import IntroScreen from "../presentation/IntroScreen";
import Templates from "../presentation/Templates";
import SelectAnchor from "../presentation/SelectAnchor";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import {
  SG_GET_POWERPOINT_PRESENTATION,
  SG_CREATE_POWERPOINT_PRESENTATION,
  SET_NEW_FILTERS
} from "constants/actions";
import { filter_data } from "reports/Tag/calculations";


const Presentation = () => {
  const [step, setStep] = useState(0);
  const [slides, setSlides] = useState([]); // Each slide is { template: '...', data: { ... } }
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [dataWithFilters, setDataWithFilters] = useState(null);
  const dispatch = useDispatch();

  const raw_data =  useSelector(
    (state) => state.audit?.raw_data,
    shallowEqual
  );

  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  // Dispatch functions
  const fetchPresentations = () => {
    dispatch({ type: SG_GET_POWERPOINT_PRESENTATION });
  };

  const createPresentation = (payload) => {
    dispatch({ type: SG_CREATE_POWERPOINT_PRESENTATION, payload });
  };

  // Fetch presentations when component mounts
  useEffect(() => {
    fetchPresentations();
    return () => {
      dispatch({
        type: SET_NEW_FILTERS,
        payload: [],
      });
    }
  }, []);

  useEffect(() => {
    if (selectedFilters.length > 0 && selectedFilters[0].parent != null && raw_data[0]?.responses) {
      const filteredData = filter_data(raw_data[0].responses, selectedFilters);
      setDataWithFilters(filteredData);
    } else {
      setDataWithFilters(null);
    }
  }, [selectedFilters]);

  const renderStep = () => {
    switch (step) {
      case 0:
        return <IntroScreen start={() => setStep(1)} data_length={dataWithFilters? dataWithFilters?.length : 100}/>;
      case 1:
        return (
          <>
            <Templates
              next={() => setStep(2)}
              selected={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              updateSlides={setSlides}
              slideData={slides}
              generatePresentation={createPresentation}
            />
          </>
        );

      case 2:
        return <SelectAnchor next={() => setStep(3)} />;

      default:
        return <IntroScreen start={() => setStep(1)} data_length={dataWithFilters? dataWithFilters?.length : 100} />;
    }
  };

  return (
    <Container>
      <Content>{renderStep()}</Content>
    </Container>
  );
};

export default Presentation;

const Container = styled.div`
  padding: 20px 10px;
  font-family: "Raleway";
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
`;

const NextButton = styled.button`
  background-color: #2d70e2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  justify-content: flex-end;
  &:hover {
    background-color: #1a4a8e;
  }
`;
