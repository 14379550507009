import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  LOGIN_REQUEST,
  CLEAR_ERRORS,
} from "constants/actions";

import GoogleAuth from "./GoogleAuth";

import { DomainContext } from 'layouts/LayoutContext';

import Input from "./InputLogin";
import UpgradePassword from "./UpgradePassword";
import { ValidateEmail } from "utilities/Validate";

import MandatoryLinks from "./MandatoryLinks";

import UserErrorMessages from "./UserErrorMessages";

import { Message, Divider } from "semantic-ui-react";
import { set } from "date-fns";

const LoginForm = (props) => {
  const dispatch = useDispatch();


  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [orgList, setOrgList] = useState([])
  const [loading, setLoading] = useState(false)
  // const [selectedOrg, setSelectedOrg] = useState(false)

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);

  const [userAuth, setUserAuth] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)

  const Domain = useContext(DomainContext);

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth
    }),
    shallowEqual
  );

  useEffect(() => {
    setUserAuth(get_auth)
    if (get_auth?.next_steps === 1) {
      setOrgList(get_auth?.organization_list)
    }
  }, [get_auth]);

  useEffect(() => {
    setPasswordReset(get_auth?.reset_password)
  }, [get_auth?.reset_password]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0
    if (password?.length === 0) {
      ErrorList["password"] = {
        error: true,
        message: "Please enter a password"
      };
      totalErrors += 1
    }
    if (email?.length === 0) {
      ErrorList["email"] = {
        error: true,
        message: "Please enter an email"
      };
      totalErrors += 1
    } else {
      if (!ValidateEmail(email)) {
        ErrorList["email"] = {
          error: true,
          message: "Please enter a valid email"
        };
        totalErrors += 1
      }
    }
    ErrorList['errors'] = totalErrors
    setFormErrors(ErrorList);
  }, [password, email, orgList, pristine]);

  let { from } = {
    // from: { pathname: "/app/dashboard" },
    from: { pathname: "/settings/select-organization" },
  }; // new



  // if (userAuth?.token) {
  //   return <Navigate to={from} />;
  // }
  if (userAuth?.token) {
    return <Navigate to={from} />;
  }

  function emailValue(e) {
    if (e) {
      setEmail(e)
    }
  }

  function passwordValue(e) {
    if (e) {
      setPassword(e)
    }

  }


  const onSubmit = () => {
    setLoading(true)
    if (formErrors?.errors > 0) {
      setPristine(false);
      setLoading(false)
      return;
    }
    // dispatch({
    //   type: CLEAR_ALL,
    // });
    dispatch({
      type: CLEAR_ERRORS,
    });

    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        email: email,
        password: password,
      },
    });
    setLoading(false)
    // setPristine(true);
  };

  const getErrors = () => {
    if (formErrors.email?.error && !pristine) {
      return  <Message
      error
      content={formErrors.email?.message}
    />
    }
    if (formErrors.password?.error && !pristine) {
      return  <Message
      error
      content={formErrors.password?.message}
    />
    }

    return null

  }

  if (userAuth?.next_steps === 2) {
    return <UpgradePassword />;
  }


  // console.log(passwordReset)
  return (
    <Container>


        <Box>
          <IMG src={Domain.fullImage} />
          <Title>
            {Domain.loginText}
          </Title>

          {/* registerError */}
          <UserErrorMessages />
          {formErrors.length > 0 && !pristine && (
            <Message
              negative
              //   onDismiss={clearErrors}
              header="We need these details!"
              list={formErrors}
            />
          )}

        {getErrors()}




          {passwordReset?.message && (
            <Message
              success
              // header={props.message}
              content={passwordReset?.message}
            />
          )}
          <Input placeholder="Email"
            name="email"
            type="email"
            id="email"
            value={email}
            onChange={emailValue}
          />
          


          <Input placeholder="Password"
            name="password"
            type="password"
            id="password"
            value={password}
            // defaultValue={password}
            onChange={passwordValue}
          />
         

          <Button onClick={onSubmit} id="sign-in">
            Sign In
          </Button>

          <Bottom>
            <Forgot>
              <Link to="/account/forgot-password">Forgot Password </Link>
            </Forgot>
          </Bottom>
          {Domain.showGoogleSso && 
            <>
              <Divider horizontal>Or</Divider>
              <Bottom>
                <GoogleAuth login={true} />
              </Bottom>
            </>
          }

          <MandatoryLinks Domain={Domain} />
        </Box>

    </Container >
  );
};

export default LoginForm;


const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2D70E2;
  color: white;
  font-size: 14px;
  font-family: "Raleway";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2D70E2;
    color: #2D70E2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  width: 100px;
  margin-bottom: 25px;
`;



const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;

  background-color: #2D70E2;
`;

const Box = styled.div`
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  max-width: 500px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;


const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding: 5px;
  font-family: "Barlow", sans-serif;
  justify-content: space-between;

  a {
    color: #2d50e2;
    font-weight: bold;
  }
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Raleway";
  line-height: 1;
  color: #2A3039;
`;



const FormContainer = styled.div`
  width: 100%;
  max-width: 600px;
  border-radius:10px;
  padding: 50px 30px 0 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 20px 0 20px;
  }

  @media (max-height: 1000px) {
    padding-top: 50px;
  }
`;

const Forgot = styled.div`
  font-size: 12px;
  font-family: "Raleway";
  margin-top:0px;
  a {
    color: #2D70E2;
    font-weight: 600;
  }
`;