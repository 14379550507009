import React,{useState} from 'react';
import styled from 'styled-components';
import Pending from './pending';
import {useNavigate} from 'react-router-dom';

const ActiveDash = () =>{
    const navigate = useNavigate();

    return (
        <Container>
            <Center>
               <Add onClick={()=>navigate('/simple/prototype/recon/collect')}>Add new</Add>
               <Pending title={'Department A - Customer Satisfaction'}/>
               <Pending title={'Department B - Customer Satisfaction'}/>
               <Pending title={'Department C - Customer Satisfaction'}/>
               <Pending title={'Department D - Customer Satisfaction'}/>
            </Center>
        </Container>
    )
}

export default ActiveDash;



const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
`

const Center = styled.div`
    width:800px;
    `

const Add = styled.div`
    color:blue;
    cursor:pointer;
    margin-bottom:20px;
`