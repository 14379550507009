import React from 'react';
import styled, { keyframes } from "styled-components";
import {Modal} from 'semantic-ui-react';
import factor_content from './factor_content.json'

const FactorModal = ({factor,close}) => {

    if(factor===null){
        return null;
    }

    return (
        <Modal open={factor!==null} onClose={close} size="small">
            <Modal.Content style={{padding:30,fontFamily:'Raleway'}}>
            <Small>
                Culture Content
            </Small>
            <T1>{factor_content[factor]?.title}</T1>
                <T2> {factor_content[factor].content.t2}</T2>
                <P>{factor_content[factor].content.p}</P>
                <T3>{factor_content[factor].content.t1}</T3>
                <ul>
                {factor_content[factor].content.li.map((item, index) => (
                    <LI key={index}>{item}</LI>   
                ))}
                </ul>
            
            </Modal.Content>
        </Modal>
    )
}

export default FactorModal;


const T1 = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom:20px;
    font-family:"Raleway";
`

const T2 = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-family:"Raleway";
`
const T3 = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-top:20px;
    font-family:"Raleway";
`

const P = styled.div`
    font-size: 14px;
    font-family:"Raleway";
    margin-top:10px;
    margin-bottom:10px;
    line-height:1.6;
`

const LI = styled.li`
    font-size: 14px;
    font-family:"Raleway";
    margin-bottom:10px;
    line-height:1.6;
`

const Small = styled.div`
    text-transform:uppercase;
    font-size:12px;
    font-weight:bold;
    color:#2D70E2;
`