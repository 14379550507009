import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SG_SIGNUP_TOKEN,
} from "constants/actions";

const SignUpToken = ({ employee_id, organization_id }) => {
  const dispatch = useDispatch();
  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees.token,
    }),
    shallowEqual
  );

  // console.log(get_employees)
  // console.log(deliveryInfo)

  function GenerateToken() {
    dispatch({
      type: SG_SIGNUP_TOKEN,
      payload: {
        employee_id: employee_id,
        organization_id: organization_id,
      },
    });
  }

  return (
    get_employees?.employee_id === employee_id ?
      <Status>{get_employees?.message} </Status> :
      <View onClick={GenerateToken}>Resend Sign Up Email &#xbb;</View>
  );
};

export default SignUpToken;

const Status = styled.div`
  font-size: 12px;
  // font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) => (props.status < 3 ? "#9b9b9b" : "black")};
`;

const View = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: #FF6833;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
  // margin-top: 12px;
`;
