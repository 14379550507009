import styled, { keyframes } from "styled-components";
import React from "react";
import User from './User/index'
import User2 from './User/User2'
import Profile from './User/Profile'
import { Dropdown,Icon } from 'semantic-ui-react'


const options =[
{
	text:'Overall',
	'value':'Overall'
},
{
	text:'Team Health',
	'value':'Team Health'
},
{
	text:'Emotional Health',
	'value':'Emotional Health'
},
{
	text:'Clarity',
	'value':'Clarity'
},
{
	text:'Alignment',
	'value':'Alignment'
},
{
	text:'Resilience',
	'value':'Resilience'
},
{
	text:'Impact',
	'value':'Impact'
},
{
	text:'Trust',
	'value':'Trust'
},
{
	text:'Communication',
	'value':'Communication'
},
{
	text:'Enjoyment',
	'value':'Enjoyment'
},
{
	text:'Motivation',
	'value':'Motivation'
},
{
	text:'Curiosity',
	'value':'Curiosity'
},
{
	text:'Stress',
	'value':'Stress'
},
{
	text:'Discontent',
	'value':'Discontent'
},
{
	text:'Anger',
	'value':'Anger'
}
]


const UserExplore = () =>{


	return (
		<Container>
			<Left>
				<Filter>
					<Dropdown
						    icon='sort amount down'
						    inline
						    scrolling
						    options={options}
						     defaultValue={options[0].value}
						      className='icon'
						  />
				</Filter>
				<User2 selected={true}/>
				<User2/>
				<User2/>
				<User2/>
			</Left>
			<Profile/>
		</Container>
		)
}


export default UserExplore


const Left = styled.div`
	width:250px;

`

const Container = styled.div`
	display:flex;
	justify-content:space-between;
`

const Filter = styled.div`
	display:flex;
	width:100%;
	justify-content:flex-end;

`