import React from "react";
import styled from "styled-components";

import moment from "moment";

import { useDispatch } from "react-redux";
import { Icon, Button, Modal } from "semantic-ui-react";
import { SelectContainer } from "./styled_list";

import { SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY } from "constants/actions";

export const choice = [
  { id: 1, value: 1, text: "Weekly" },
  { id: 2, value: 2, text: "Bi-Weekly" },
  { id: 3, value: 4, text: "Monthly" },
  { id: 4, value: 12, text: "Quarterly" },
];

const SurveyFrequency = ({ selected, setProcessing, scheduledAt }) => {
  const dispatch = useDispatch();
  const [selectedFreq, setSelectedFreq] = React.useState(1);

//   console.log(selectedFreq);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (selected?.frequency) {
      setSelectedFreq(
        choice.filter((fl) => fl.value === selected.frequency)[0].id
      );
    }
  }, [selected]);

  function MoveUp() {
    // () => UpdateActiveStatus(selectedFreq + 1)
    let newFreq = selectedFreq + 1 > 4 ? 4 : selectedFreq + 1;
    setSelectedFreq(newFreq);
    // console.log(choice.filter((fl) => fl.id === newFreq)[0].value)
    // UpdateActiveStatus(choice.filter((fl) => fl.id === newFreq)[0].value);
  }
  function MoveDown() {
    let newFreq = selectedFreq - 1 < 1 ? 1 : selectedFreq - 1;
    setSelectedFreq(newFreq);

    // console.log(choice.filter((fl) => fl.id === newFreq)[0].value)
    // UpdateActiveStatus(choice.filter((fl) => fl.id === newFreq)[0].value);
  }

  function UpdateActiveStatus() {
    if (selected?.id) {
      setProcessing(true);
      dispatch({
        type: SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY,
        payload: {
          id: selected.id,
          frequency: choice.filter((fl) => fl.id === selectedFreq)[0].value,
        },
      });
    }
  }

  return (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button>{selected?.frequency_text? selected?.frequency_text: "No Survey Set"}</Button>}
      >
        <Modal.Header>
          Set the Frequency of the Survey After{" "}
          {scheduledAt && moment(scheduledAt).format("DD MMM YYYY - (ddd)")}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {/* <Header>Default Profile Image</Header> */}
            <ContainerCard>
              <SelectContainer>
                {selectedFreq
                  ? choice.filter((fl) => fl.id === selectedFreq)[0].text
                  : ""}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "absolute",
                    right: 5,
                    cursor: "pointer",
                  }}
                >
                  <Icon
                    name="chevron up"
                    size={"small"}
                    style={{ color: "#4a4a4a" }}
                    onClick={MoveUp}
                    disabled={selectedFreq===4}
                  />
                  <Icon
                    name="chevron down"
                    size={"small"}
                    style={{ color: "#4a4a4a" }}
                    disabled={selectedFreq===1}
                    onClick={MoveDown}
                  />
                </div>
              </SelectContainer>
              <Button
                content={`Update to ${choice.filter((fl) => fl.id === selectedFreq)[0].text}`}
                labelPosition="right"
                icon="checkmark"
                onClick={() => {
                    UpdateActiveStatus()
                    setOpen(false)}}
                positive
              />
            </ContainerCard>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default SurveyFrequency;

export const ContainerCard = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
`;
