import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableDropdown from "./TableDropdown";
// import BasicInput from "./BasicInput";
import { addIcon, trashIcon } from "components/Icons";
// import Textbox from "../Textbox";
import Timelines from "../Timelines";
import TableStatus from "./TableStatus";
import TableTextbox from "./TableTextBox";

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const DropdownTable = ({ data, onChange, id, editEnabled }) => {
  const [rows, setRows] = useState([{}]);
  const [hoveredRow, setHoveredRow] = useState();

  useEffect(() => {
    onChange(rows, id);
  }, [rows]);

  useEffect(() => {
    if (data) {
      setRows((data?.planData[id]?.length > 0 && data?.planData[id]) || [{}]);
    }
  }, [data]);

  const handleFirstOptionChange = (i, d) => {
    // set the dropdownSelection at the particular index of the array
    const _dropdownSelection = [...rows];
    const target = dropdownData?.find((da) => d === da?.firstDropdown)
      ?.options?.[0];
    _dropdownSelection[i] = { focusArea: d, target };
    setRows(_dropdownSelection);
  };

  const handleSecondOptionChange = (i, d) => {
    const _dropdownSelection = [...rows];
    let rowData = _dropdownSelection[i];
    _dropdownSelection[i] = {
      ...rowData,
      target: d,
    };

    setRows(_dropdownSelection);
  };

  const handleTextChange = (value, index, i) => {
    const _inputText = [...rows];
    let rowData = _inputText[i];
    let inputData = _inputText[i]?.inputData;
    _inputText[i] = {
      ...rowData,
      inputData: {
        ...inputData,
        [index]: value,
      },
    };
    setRows(_inputText);
  };

  const addRow = () => {
    setRows([...rows, {}]);
  };

  const removeRow = (i) => {
    const _rows = [...rows];
    _rows.splice(i, 1);
    setRows(_rows);
  };

  const setStartDate = (d, i) => {
    const _inputText = [...rows];
    let rowData = _inputText[i];
    _inputText[i] = {
      ...rowData,
      startDate: d,
    };
    setRows(_inputText);
  };

  const setEndDate = (d, i) => {
    const _inputText = [...rows];
    let rowData = _inputText[i];
    _inputText[i] = {
      ...rowData,
      endDate: d,
    };
    setRows(_inputText);
  };

  const setStatus = (d, i) => {
    const _inputText = [...rows];
    let rowData = _inputText[i];
    _inputText[i] = {
      ...rowData,
      status: d,
    };
    setRows(_inputText);
  };

  const dropdownData = [
    {
      id: 0,
      firstDropdown: "Assessment, Diagnoses, Treatment & Recovery",
      options: [
        "Athletes, coaches, and support staff are aware of the mental health supports available to them at no cost.",
        "Stay-in-play protocols are developed and / or implemented for athletes experiencing mental health challenges or mental illness.",
        "Return-to-play protocols for athletes experiencing mental health challenges or mental illness are developed and / or implemented.",
        "Screening protocols to detect signs and symptoms of mental health challenges / illnesses are developed and / or implemented.",
        "Other",
      ],
    },
    {
      id: 1,
      firstDropdown:
        "Prevention of Mental Health Challenges and Mental Illness",
      options: [
        "Athletes, coaches, and support staff are aware of the Game Plan (GP) resources available.",
        "Sport specific factors that can compromise mental health and exacerbate or trigger mental illness have been identified.",
        "Known periods of vulnerability have been identified in YTPs.",
        "Athletes, coaches, and support staff are aware of the Canadian Sport Helpline, its purpose and how to access it.",
        "Athletes, coaches, and support staff are aware of the crisis resources available.",
        "A plan / resources / supports have been implemented to address known periods of vulnerability.",
        "Athletes, coaches, and support staff have received crisis intervention training.",
        "A plan / resources / supports have been implemented to address sport-specific factors that can compromise mental health, and exacerbate or trigger mental illness.",
        "A protocol to support athletes, coaches and support staff involved in instances of / alleged instances of maltreatment has been developed / communicated.",
        "Your NSO's Safe Sport Officer is identified, and contact information is distributed.",
        "Other",
      ],
    },
    {
      id: 2,
      firstDropdown: "Promotion of Mental Health",
      options: [
        "Athletes, coaches, and support staff have received mental health literacy training.",
        "Coaches reflect on their coaching practice to ensure their approach doesn't inadvertently harm athletes.",
        "An MPC is hired / contracted to implement mental skills training with athletes, coaches, and support staff.",
        "Other",
      ],
    },
  ];

  return (
    <ComponentContainer>
      {rows?.map((r, i) => {
        return (
          <>
            <TopDropdownSection>
              <FirstDropdown>
                <TableDropdown
                  selectedOption={r?.focusArea}
                  handleOptionChange={(d) => handleFirstOptionChange(i, d)}
                  key={i}
                  options={dropdownData.map((d) => d.firstDropdown)}
                  inModal={true}
                  label="Focus Area"
                  editEnabled={editEnabled}
                />
                {editEnabled && (
                  <RemoveRowContainer
                    isRowFocused={true}
                    onClick={() => removeRow(i)}
                  >
                    {trashIcon()}
                  </RemoveRowContainer>
                )}
              </FirstDropdown>

              {r?.focusArea && (
                <TableDropdown
                  selectedOption={r?.target}
                  handleOptionChange={(d) => handleSecondOptionChange(i, d)}
                  key={i + 1}
                  inModal={true}
                  options={
                    dropdownData?.find(
                      (da) => r?.focusArea === da?.firstDropdown
                    )?.options || []
                  }
                  label="Target"
                  editEnabled={editEnabled}
                />
              )}
            </TopDropdownSection>
            {r?.focusArea && r?.target && (
              <TableContainer>
                <TableRow>
                  <RowHeader>Description of Action</RowHeader>
                  <RowHeader>Resource Needed</RowHeader>
                  <RowHeader>Lead Personnel</RowHeader>
                  <RowHeader>Timeline</RowHeader>
                  <RowHeader>Status</RowHeader>
                </TableRow>
                <TableRow
                  hideBorder={true}
                  altBg={false}
                  onMouseOver={() => setHoveredRow(i)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCol>
                    <TableTextbox
                      placeholder="Description..."
                      editEnabled={editEnabled}
                      value={r?.inputData?.[0] || ""} // TODO
                      onChange={(value) => handleTextChange(value, 0, i)}
                    />
                  </TableCol>
                  <TableCol>
                    <TableTextbox
                      editEnabled={editEnabled}
                      placeholder="Resource..."
                      value={r?.inputData?.[1] || ""} // TODO
                      onChange={(value) => handleTextChange(value, 1, i)}
                    />
                  </TableCol>
                  <TableCol>
                    <TableTextbox
                      editEnabled={editEnabled}
                      placeholder="Enter name, or names of personnel..."
                      value={r?.inputData?.[2] || ""} // TODO
                      onChange={(value) => handleTextChange(value, 2, i)}
                    />
                  </TableCol>
                  <TableCol>
                    <Timelines
                      dateStart={r?.startDate || new Date()}
                      dateEnd={r?.endDate || new Date()}
                      setStartDate={(d) => setStartDate(d, i)}
                      setEndDate={(d) => setEndDate(d, i)}
                      showLabels={true}
                      editEnabled={editEnabled}
                      isTable={true}
                    />
                  </TableCol>
                  <TableCol>
                    <TableStatus
                      editEnabled={editEnabled}
                      selectedOption={
                        planStatus.find((s) => s.value === r?.status)?.text
                      }
                      setStatus={(a) => setStatus(a, i)}
                    />
                  </TableCol>
                </TableRow>
              </TableContainer>
            )}
          </>
        );
      })}
      {editEnabled && (
        <AddRowContainer isHovered={true}>
          <AddContainer onClick={addRow}>
            Add Row&nbsp;&nbsp;{addIcon()}
          </AddContainer>
        </AddRowContainer>
      )}
    </ComponentContainer>
  );
};

const TableContainer = styled.table`
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const AddContainer = styled.div`
  cursor: pointer;
  color: #2d70e2;
`;

const TopDropdownSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FirstDropdown = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const TableRow = styled.tr`
  width: 100%;
  display: flex;
  border-bottom: ${(props) =>
    props.hideBorder ? "none" : "1px solid #DFDFDF"};
  background-color: ${(props) => (props.altBg ? "#F8FAFF" : "#fff")};
  padding-bottom: 10px;
  padding-top: 10px;
  flex-direction: row;
  &:last-child {
    border-bottom: none;
  }
`;

const RemoveRowContainer = styled.div`
  opacity: ${(props) => (props.isRowFocused ? "1" : "0")};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  top: 30px;
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;

const RowHeader = styled.th`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  width: 50%;
`;

const TableCol = styled.td`
  width: 50%;
  font-size: 14px;
  display: flex;
`;

const AddRowContainer = styled.div`
  opacity: ${(props) => (props.isHovered ? "1" : "0")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default DropdownTable;
