import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_EMPLOYEE_FILE_UPLOAD,
    SG_GET_EMPLOYEE_FILE_UPLOAD,
    DELETE_EMPLOYEE_FILE_UPLOAD,
    SG_DELETE_EMPLOYEE_FILE_UPLOAD,
    FETCH_EMPLOYEE_FILE_UPLOAD,
    SG_FETCH_EMPLOYEE_FILE_UPLOAD,
    EDIT_EMPLOYEE_FILE_UPLOAD,
    SG_EDIT_EMPLOYEE_FILE_UPLOAD,
    ADD_EMPLOYEE_FILE_UPLOAD,
    SG_ADD_EMPLOYEE_FILE_UPLOAD,
    PENDING_EMPLOYEE_FILE_UPLOAD,
    ALL_ERRORS,
} from "constants/actions";

function* loadEmployeeFiles(action) {
  try {
    yield put({ type: PENDING_EMPLOYEE_FILE_UPLOAD });
    const json = yield call(api.GET_EMPLOYEE_FILE_UPLOAD, action.payload);
    yield put({ type: GET_EMPLOYEE_FILE_UPLOAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFileLoad() {
  yield takeEvery(SG_GET_EMPLOYEE_FILE_UPLOAD, loadEmployeeFiles);
}

function* fetchEmployeeFiles(action) {
  try {
    yield put({ type: PENDING_EMPLOYEE_FILE_UPLOAD });
    const json = yield call(api.FETCH_EMPLOYEE_FILE_UPLOAD, action.payload);
    yield put({ type: FETCH_EMPLOYEE_FILE_UPLOAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFileFetch() {
  yield takeLatest(SG_FETCH_EMPLOYEE_FILE_UPLOAD, fetchEmployeeFiles);
}

function* updateEmployeeFiles(action) {
  try {
    yield put({ type: PENDING_EMPLOYEE_FILE_UPLOAD });
    const json = yield call(api.EDIT_EMPLOYEE_FILE_UPLOAD, action.payload);
    yield put({ type: EDIT_EMPLOYEE_FILE_UPLOAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFileUpdate() {
  yield takeLatest(SG_EDIT_EMPLOYEE_FILE_UPLOAD, updateEmployeeFiles);
}

function* addEmployeeFiles(action) {
  try {
    yield put({ type: PENDING_EMPLOYEE_FILE_UPLOAD });
    const json = yield call(api.ADD_EMPLOYEE_FILE_UPLOAD, action.payload);
    yield put({ type: ADD_EMPLOYEE_FILE_UPLOAD, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFileAdd() {
  yield takeLatest(SG_ADD_EMPLOYEE_FILE_UPLOAD, addEmployeeFiles);
}

function* deleteEmployeeFiles(action) {
  try {
    yield put({ type: PENDING_EMPLOYEE_FILE_UPLOAD });
    yield call(api.DELETE_EMPLOYEE_FILE_UPLOAD, action.payload);
    yield put({ type: DELETE_EMPLOYEE_FILE_UPLOAD, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* employeeFileDelete() {
  yield takeLatest(SG_DELETE_EMPLOYEE_FILE_UPLOAD, deleteEmployeeFiles);
}

export default function* index() {
  yield all([
    employeeFileLoad(),
    employeeFileFetch(),
    employeeFileUpdate(),
    employeeFileAdd(),
    employeeFileDelete(),
  ]);
}