export function ideaIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9692 5.34982C13.953 3.41673 10.6841 3.41673 8.66795 5.34982C6.67778 7.25797 6.86844 10.1881 8.89571 12.1318C9.4691 12.6816 9.72538 13.4698 9.84814 14.176C9.97188 14.8878 9.97187 15.5932 9.97186 16.0411V16.05C9.97186 16.1963 10.0087 16.276 10.0397 16.3207C10.0727 16.3682 10.1201 16.4054 10.1818 16.435C10.2453 16.4654 10.3144 16.4829 10.3717 16.4921C10.3992 16.4965 10.4211 16.4985 10.4343 16.4994C10.4398 16.4998 10.4436 16.4999 10.4454 16.5H14.1917C14.1936 16.4999 14.1974 16.4998 14.2028 16.4994C14.216 16.4985 14.2379 16.4965 14.2655 16.4921C14.3227 16.4829 14.3919 16.4654 14.4554 16.435C14.517 16.4054 14.5644 16.3682 14.5974 16.3207C14.6285 16.276 14.6653 16.1963 14.6653 16.05V16.0411C14.6653 15.5932 14.6653 14.8878 14.789 14.176C14.9118 13.4698 15.168 12.6816 15.7414 12.1318C17.8403 10.1194 17.9633 7.26176 15.9692 5.34982ZM8.0042 4.71343C10.387 2.42886 14.2502 2.42886 16.633 4.71343C19.0378 7.01915 18.8249 10.4482 16.4052 12.7682C16.0399 13.1184 15.8268 13.6802 15.7149 14.324C15.6048 14.9574 15.604 15.5974 15.604 16.05C15.604 16.3537 15.5234 16.6115 15.3785 16.8199C15.2355 17.0256 15.0482 17.1571 14.8752 17.24C14.704 17.3221 14.5385 17.3608 14.4198 17.3798C14.3593 17.3895 14.3079 17.3945 14.2697 17.3971C14.2506 17.3984 14.2345 17.3991 14.2221 17.3995L14.2062 17.3999L14.2003 17.4L14.198 17.4L14.1969 17.4C14.1967 17.4 14.1959 17.4 14.1959 16.95V17.4H10.4412V16.95C10.4412 17.4 10.4414 17.4 10.4412 17.4L10.4402 17.4L10.4392 17.4L10.4368 17.4L10.4309 17.3999L10.415 17.3995C10.4026 17.3991 10.3866 17.3984 10.3674 17.3971C10.3293 17.3945 10.2779 17.3895 10.2174 17.3798C10.0986 17.3608 9.93314 17.3221 9.76197 17.24C9.58895 17.1571 9.40166 17.0256 9.25868 16.8199C9.11373 16.6115 9.03318 16.3537 9.03318 16.05C9.03318 15.5974 9.03234 14.9574 8.92223 14.324C8.81032 13.6802 8.59726 13.1184 8.23196 12.7682C5.8924 10.5251 5.59542 7.02294 8.0042 4.71343Z"
        fill="#2D70E2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.98532 20.55C9.98532 20.3015 10.1955 20.1 10.4547 20.1H14.2094C14.4686 20.1 14.6787 20.3015 14.6787 20.55C14.6787 20.7985 14.4686 21 14.2094 21H10.4547C10.1955 21 9.98532 20.7985 9.98532 20.55Z"
        fill="#2D70E2"
      />
    </svg>
  );
}

export function heartIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.332 21L10.882 19.7052C5.73203 15.1243 2.33203 12.103 2.33203 8.3951C2.33203 5.37384 4.75203 3 7.83203 3C9.57203 3 11.242 3.79455 12.332 5.05014C13.422 3.79455 15.092 3 16.832 3C19.912 3 22.332 5.37384 22.332 8.3951C22.332 12.103 18.932 15.1243 13.782 19.715L12.332 21Z"
        stroke="#2D70E2"
      />
    </svg>
  );
}

export function starIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.6695 0.5C10.8598 0.5 11.0337 0.608049 11.1179 0.778717L13.9099 6.43601L20.1531 7.3432C20.3415 7.37056 20.4979 7.50248 20.5568 7.68349C20.6156 7.8645 20.5665 8.0632 20.4302 8.19604L15.9126 12.5996L16.9791 18.8176C17.0112 19.0052 16.9341 19.1948 16.7802 19.3066C16.6262 19.4185 16.4221 19.4332 16.2536 19.3447L10.6695 16.409L5.08545 19.3447C4.91699 19.4332 4.71286 19.4185 4.55888 19.3066C4.40491 19.1948 4.3278 19.0052 4.35997 18.8176L5.42644 12.5996L0.908821 8.19604C0.772535 8.0632 0.723486 7.8645 0.782299 7.68349C0.841111 7.50248 0.997585 7.37056 1.18593 7.3432L7.42912 6.43601L10.2212 0.778717C10.3054 0.608049 10.4792 0.5 10.6695 0.5ZM10.6695 2.12978L8.20952 7.11429C8.13669 7.26187 7.9959 7.36415 7.83305 7.38781L2.33231 8.18712L6.31268 12.067C6.43052 12.1819 6.4843 12.3474 6.45648 12.5096L5.51684 17.9881L10.4369 15.4015C10.5825 15.3249 10.7565 15.3249 10.9022 15.4015L15.8222 17.9881L14.8826 12.5096C14.8548 12.3474 14.9085 12.1819 15.0264 12.067L19.0067 8.18712L13.506 7.38781C13.3431 7.36415 13.2024 7.26187 13.1295 7.11429L10.6695 2.12978Z"
        fill="#2D70E2"
      />
    </svg>
  );
}

export function flagIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.66797 5V20" stroke="#2D70E2" stroke-linecap="round" />
      <path
        d="M5.66797 6C5.66797 6 7.41797 4 10.043 4C12.668 4 14.668 6 16.668 6C18.668 6 19.668 5 19.668 5"
        stroke="#2D70E2"
        stroke-linecap="round"
      />
      <path
        d="M5.66797 15C5.66797 15 7.41797 13 10.043 13C12.668 13 14.668 15 16.668 15C18.668 15 19.668 14 19.668 14V5"
        stroke="#2D70E2"
        stroke-linecap="round"
      />
    </svg>
  );
}

export function closeIcon(fill) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <g id="Vector">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.14272 6.14272C6.333 5.95243 6.64152 5.95243 6.83181 6.14272L17.8573 17.1682C18.0476 17.3585 18.0476 17.667 17.8573 17.8573C17.667 18.0476 17.3585 18.0476 17.1682 17.8573L6.14272 6.83181C5.95243 6.64152 5.95243 6.333 6.14272 6.14272Z"
            fill="#2A3039"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.8573 6.14272C18.0476 6.333 18.0476 6.64152 17.8573 6.83181L6.83181 17.8573C6.64152 18.0476 6.333 18.0476 6.14272 17.8573C5.95243 17.667 5.95243 17.3585 6.14272 17.1682L17.1682 6.14272C17.3585 5.95243 17.667 5.95243 17.8573 6.14272Z"
            fill="#2A3039"
          />
        </g>
      </g>
    </svg>
  );
}
