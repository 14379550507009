import React, { useRef, useState, useEffect } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
} from "constants/actions";

import Template from './pages/template'

const DEIPDF = (props) =>{
	const dispatch = useDispatch();
	const [organizationId, setOrganizationId] = useState();

	const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

	//Get redux data
   const {
    auth,
    surveyStructure,
    selectedOrg,
    ediResponses,
    surveyQuestions,
    get_organizations,
  } = useSelector(
    (state) => ({
      errors: state.errors,
      auth: state.auth,
      surveyQuestions: state.surveyquestions.survey_questions,
      surveyStructure: state.surveystructure.survey_structure,
      selectedOrg: state.selectedOrg,
      ediResponses: state.debrief_schedule.culture_audit_reports,
      get_organizations: state.organizations,
    }),
    shallowEqual
  );


  //Check if EDI responses have been fetched
  useEffect(() => {
    if (organizationId && !ediResponses[0]) {

      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=7&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      dispatch({
        type: SG_GET_CULTURE_AUDIT_REPORTS,
        payload: "audit-type-id=4",
      });
    }
  }, [dispatch, organizationId]);


  	//Check if this is an ambassador account or regular, and set org id
   useEffect(() => {
    if (Number(ambassadorId) > 0 && selectedOrg) {
      setOrganizationId(selectedOrg.organization?.id);
    } else {
      setOrganizationId(Number(auth.organization_id));
    }
  }, [selectedOrg, ambassadorId, auth]);

   console.log(surveyQuestions,surveyStructure)

	return (
		<div style={{ marginTop:50 }}>
      {surveyStructure.length>0?<PDFDownloadLink
        document={
          <Template surveyStructure={surveyStructure[0]} surveyQuestions={surveyQuestions[0]}/>
        }
        fileName={`test.pdf`}
      >
 				pdfReady

      </PDFDownloadLink>:""}
    </div>
		)
}


export default DEIPDF