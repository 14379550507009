import React, { useState } from "react";
import styled from "styled-components";
import { caretDown } from "./Icons";

const LabeledDropdown = (props) => {
  const { options, selectedItem, dropdownLabel, onChange } = props;
  const [hoveredItem, setHoveredItem] = useState();
  //   const dropDownMenu = (
  //     <DropdownOpenDrawer>
  //       {options?.map((op) => {
  //         const isSelected = op.id === selectedItem;
  //         return (
  //           <OptionContainer>
  //             <OptionText>{op.name}</OptionText>
  //             {/* Add checkmark if selected */}
  //           </OptionContainer>
  //         );
  //       })}
  //     </DropdownOpenDrawer>
  //   );

  return (
    <DropdownContainer>
      <Prefix>{dropdownLabel}</Prefix>
      <SelectedOption>{selectedItem}</SelectedOption>
      <DropdownIcon>{caretDown("#666D79")}</DropdownIcon>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const SelectedOption = styled.div`
  color: #2d70e2;
  text-align: right;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const DropdownOpenDrawer = styled.div``;

const OptionContainer = styled.div``;

const OptionText = styled.div``;

const DropdownIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3px;
`;

const Prefix = styled.div`
  color: #666d79;
  text-align: right;

  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-right: 8px;
`;

export default LabeledDropdown;
