import styled from "styled-components";
import React from "react";
import {Icon} from 'semantic-ui-react'
import Component1 from './Component1'

import Component2 from './Component2'
import Component3 from './Component3'
import Component4 from './Component4'
import Component5 from './Component5'
import Component6 from './Component6'
import Component7 from './Component7'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './transitions.css'; // Import your CSS for transitions

import Side from './Side'


const DataJourney = () =>{

    const [stage,setStage] = React.useState(0)
    const [open,setOpen] = React.useState(false)

    const renderContent = () =>{

        switch(stage){
            case 0:
                return <Component7/>
            case 1:
                return <Component1/>
            case 2:
                return <Component2/>
            case 3:
                return <Component3/>
            case 4:
                return <Component4/>
            case 5:
                return <Component5/>
            case 6:
                return <Component6/>
            default:
                return <Component1/>
                }
    }

    return (
        <Container>
            <Side 
                open={open} 
                setOpen={setOpen}
                stage={stage}
                setStage={setStage}/>
        <Center open={open}>
            <TransitionGroup component={null}>
                <CSSTransition
                    key={stage} // Use the stage as a unique key to trigger transitions
                    timeout={1000} // Duration of the transition
                    classNames="fade" // Prefix for the CSS transition classes
                >
                    {renderContent()}
          
            </CSSTransition>
                </TransitionGroup>
        </Center>
        

        <Bottom>
            <Button2 onClick={()=>setStage(stage-1)}>
                Back
            </Button2>
            <Button onClick={()=>setStage(stage+1)}>
                Continue Learning <Icon name="arrow right" style={{marginLeft:10,marginTop:-3}}/>
            </Button>
        </Bottom>
        </Container>
    )

}

export default DataJourney


const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    min-height:calc(100vh - 75px);
`

const Center = styled.div`
    width:${props=>props.open?'calc(100% - 300px)':'800px'};
    padding-top:1%;
    padding-left:${props=>props.open?'200px':'0px'};
    transition:all 0.3s ease-in-out;
    
`

const Bottom = styled.div`
    position:fixed;
    bottom:0px;
    left:0px;
    width:100%;
    height:75px;
    background-color:#2254AA;
    z-index:100;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-right:20px;

`

const Button = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px 20px;
    color:white;
    font-size:16px;
    font-weight:600;
    cursor:pointer;
    border:1px solid white;
`

const Button2 = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    padding:10px 20px;
    color:white;
    font-size:16px;
    font-weight:600;
    cursor:pointer;
`

