import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import IMG from 'assets/images/stock/hero_test.jpg'

const Pillar = () => {

    return (

        <Container>
            <Title>Interpreting Psychological Safety</Title>
            <Sub>
                Why psychological safety matters and what your scores mean?
            </Sub>

            <Image src={IMG} />


            <GradientDiv percentage={66}>
                <TriangleIndicator>
                    <TriangleText>Department A</TriangleText>
                </TriangleIndicator>
                    <LowText>Low</LowText>
                    <AverageText>Average</AverageText>
                    <HighText>High</HighText>
            </GradientDiv>
        </Container>

    );
};

export default Pillar;


const Container = styled.div`
    padding-left:58px;
    margin-top:70px;
    width:100%;
    display:flex;
    flex-direction:column;

    color:#666D79;
font-size: 12px;
font-family: Raleway;
font-style: normal;
font-weight: 400;
line-height: 125%;
`

const Title = styled.div`
    font-size: 24px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    margin-bottom:5px;
    color:#2A3039;

`

const Sub = styled.div`
    font-size: 20px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom:20px;
    color:#666D79;

`

const Image = styled.img`
    width:50%
`

const TriangleIndicator = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #DFDFDF;  // Make triangle point downwards
  position: absolute;
  top: -20px;  // Position it below the GradientDiv
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const TriangleText = styled.div`
  text-align: center;
  position: absolute;
  bottom: 25px;  // Position it above the triangle
  width: 150px;   // Make sure the text can span the full width of the TriangleIndicator
  color:#2A3039;
  font-weight:600;
`;


const GradientDiv = styled.div`
    height: 20px;
    width: 300px;
    background: linear-gradient(90deg, #F8A8B0 0%, #FECD8D 52.32%, #95E6D2 100%);
    position:relative;
    margin-top:90px;

    ${TriangleIndicator} {
        left: calc(${props => props.percentage}% - 10px);  // Position the triangle in the middle of the GradientDiv
      }
`;

const LowText = styled.div`
  position: absolute;
  left: 0;
  top: 30px;
`;

const AverageText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
`;

const HighText = styled.div`
  position: absolute;
  right: 0;
  top: 30px;
`;