import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { getGrade } from "../../utils/cultureUtils";
import Table from "../../components/Table";

const Segmentation = (props) => {
  const {
    categories,
    calculated,
    hideText = false,
    dummyData,
    config,
    rule = 2,
    isSportLaw,
  } = props;

  const getRoleName = (anchor, id) => {
    return categories.categories
      .find((f) => f.id === anchor)
      .options.find((f) => f.id === id)?.name;
  };

  return calculated.map((item, i) => {
    const tableData = Object.keys(item.data).map((k) => {
      if (item.data[k].n1 > rule) {
        const roleName = getRoleName(item.id, k);
        if (!roleName) {
          return null;
        }
        return [`${roleName}`, `${item.data[k].n1}`];
      }
      return null;
    });

    // Filter out any empty results and show table data only if
    // data is available.
    if (tableData.filter((item) => item !== null).length === 0) {
      return null;
    }

    const tableTemplate = [
      { title: `${item.name}`, width: "50%" },
      { title: "Sample Size", width: "50%" },
    ];

    let tableContent = {
      rows: tableTemplate,
      data: tableData,
    };

    // sort table data
    function sortFunction(a, b) {
      if (Number(a[1]) === Number(b[1])) {
        return 0;
      } else {
        return Number(a[1]) > Number(b[1]) ? -1 : 1;
      }
    }

    // update the table content with sorted data
    tableContent.data = tableContent?.data?.sort(sortFunction);

    return (
      <View style={styles.container} wrap={false}>
        {!hideText && (
          <View>
            <Text style={[styles.title, { color: config.titleColor }]}>
              Table {i + 1}. {item.name}
            </Text>
            {/* <Text style={styles.description}>
              These grades are calculated based on traditional school grading (B
              is equal to 83-86%) scales. They are designed to give an intuitive
              sense of the overall scoring.
            </Text> */}
          </View>
        )}
        <Table
          data={tableContent}
          headerColor={config.tableBorderColor}
          isSportLaw={isSportLaw}
        />
      </View>
    );
  });
};

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 15,
  },
});

export default Segmentation;
