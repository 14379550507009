import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Image from "assets/images/stock/Placeholder.jpg";

const sampleData = {
  image: Image,
  title: "Why culture matters here...",
};

const FactorExplain = (props) => {
  return (
    <Container>
      <T2 delay={0.3}>{sampleData.title}</T2>

      <IMG src={sampleData.image} />
    </Container>
  );
};

export default FactorExplain;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
`;

const T2 = styled.div`
  padding-top: 20px;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.4;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const IMG = styled.img`
  width: 100%;
  margin-bottom: 10px;
`;
