import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import components from './components.json'
import items from './items.json'
import TopProgress from './TopProgress'
import { Dropdown,Button,Input } from 'semantic-ui-react';
import AddComponent from './Modal'
import { set } from 'date-fns';
import { render } from '@testing-library/react';

const StoryTemplate = () =>{

    const [template,setTemplate] = useState([])
    const [selected,setSelected] = useState(null)
    const [componentItem,setComponentItem] = useState(null)
    const [editing,setEditing] = useState(false)
    const[selectedStep,setSelectedStep] = useState(0)

    const [componentList,setComponentList] = useState(components.map((item)=>{
        return {value:item.componentId,text:item.title,data:item} 
    }))

    const add_component = () =>{

        let new_template = [...template]
        new_template.push(components.find(f=>f.componentId==selected))
        setTemplate(new_template)
    }

    const editData = (e,idx) =>{
        console.log(e,idx)
        let new_template = [...template]
        new_template[selectedStep].manualInputs[idx].value = e.target.value
        console.log(new_template)
        setTemplate(new_template)
    }

    const deleteStep = (idx) =>{
        let new_template = [...template]
        new_template.splice(idx,1)
        setTemplate(new_template)
    }

    const MoveUp = (idx) =>{
        
        if(idx!==0){
            let new_template = [...template]
            let temp = new_template[idx]
            new_template[idx] = new_template[idx-1]
            new_template[idx-1] = temp
            setTemplate(new_template)
            setSelectedStep(idx-1)
        }
    }

    const MoveDown = (idx) =>{

        if(idx==template.length-1){
            return
        }

        let new_template = [...template]
        let temp = new_template[idx]
        new_template[idx] = new_template[idx+1]
        new_template[idx+1] = temp
        setTemplate(new_template)

        setSelectedStep(idx+1)
    }

    const renderComponent = () =>{

        return template[selectedStep].manualInputs.map((input,idx)=>{
                        if(input.component=='dropdown'){
                            return (
                                <div style={{marginTop:20}}  id={idx}>
                                    <Label  id={idx}>{input.name}</Label>
                                    <Dropdown 
                                     id={idx}
                                    placeholder={input.placeholder}
                                    fluid selection 
                                    options={input.options}
                                    value={input.value?input.value:''}
                                    onChange={(e,{value})=>{
                                        editData({target:{value}},idx)
                                    }}
                                    />
                                </div>
                            )
                        }


                        return (
                            <div style={{marginTop:20}}>
                                <Label>{input.name}</Label>
                                {input.component=='textarea'?
                                <textarea 
                                value={input.value?input.value:''}
                                onChange={(e)=>editData(e,idx)}
                                id={idx}
                                placeholder={input.placeholder}
                                style={{width:'100%',height:100}}/>:
                                <Input 
                                value={input.value?input.value:''}
                                id={idx}
                                onChange={(e)=>editData(e,idx)}
                                placeholder={input.placeholder} />}

                            </div>
                        )
                    })}

   
    return (
        <Container>

            <Half>
            <Title2>Build a data story template</Title2>
            <Dropdown 
                placeholder='Select a component'
                fluid selection 
                options={componentList}
                value={selected}
                style={{marginBottom:20}}
                onChange={(e,{value})=>{
                    setSelected(value)
                } }
                 />
            <Button onClick={()=>add_component()}>
                Add to Story
            </Button>
             <TopProgress step={selectedStep} steps={template} onClick={(value)=>setSelectedStep(value)}/>
            
            <Button>
                Save
            </Button>
            </Half>

            <Half2>


            {template.length==0?
                <Empty>Please add a component</Empty>:
                <>
                    <TopContainer>
                    <Title>Edit Component</Title>
                    <Button onClick={()=>MoveUp(selectedStep)}>Move up</Button>
                    <Button onClick={()=>MoveDown(selectedStep)}>Move down</Button>
                    <Button onClick={()=>deleteStep(selectedStep)}>Delete</Button>
                    </TopContainer>
                    

                   {renderComponent()}
                </>
            }
            
           

            </Half2>
           

           
        </Container>
    )
}


export default StoryTemplate

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    padding:40px;
    font-family:'Raleway';
`

const Title = styled.div`
    font-size:20px;
    font-weight:600;
    
`

const Title2 = styled.div`
    font-size:20px;
    font-weight:600;
    margin-bottom:20px;
`

const Half = styled.div`
    width:30%;
    padding:0px 20px;
`

const Half2 = styled.div`
    width:70%;
    padding:0px 20px;
`

const Label = styled.div`
    margin-bottom:5px;
`

const TopContainer = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;
`

const Empty = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:16px;
    color:#2A3039;
    margin-top:20px;
    margin-bottom:20px;
`