import styled, { keyframes } from "styled-components";
import React from "react";
import { Input,Progress,Icon } from 'semantic-ui-react'

const DefaultPic = 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7'

const Blank = () =>{

	return (
		<User>
			<Icon name="user circle" style={{marginTop:10,marginLeft:3}}/>
		</User>
		)
}


const User2 = ({employee, selected, hide, OnEmpSelected, data, empSortOn }) =>{

	function FullName() {
		if (employee.first_name) {
		  return `${employee.first_name} ${employee.last_name}`
		}
		return employee.email.split('@')[0]

	  }



	return (
		<Card selected={selected} hide={hide} onClick={() => OnEmpSelected(employee)}>
			<UserContainer>
						{employee.picture?
							<Avatar src={employee.picture} hide={hide}/>:
							<Blank/>
					}
					

					<Name hide={hide}>{FullName()}</Name>
					<Score hide={hide}>
							{Number.isNaN(data[empSortOn])? 0 : Number(data[empSortOn])}
							
						</Score>
			</UserContainer>


		</Card>
		)
}


export default User2

const User = styled.div`
	width:30px;
	height:30px;
	border-radius:15px;
	margin-right:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	color:black;
	font-size:30px;
`


const Card = styled.div`
  border-radius: 8px;
  background-color: ${props=>props.selected?'#6a00ff':"none"};
  color:${props=>props.selected?'white':"black"};
  padding:10px;
  cursor:pointer;
  overflow:hidden;
  width: ${props=>props.hide?'50px':"auto"};

  @media(max-width:768px){
  	padding:4px;
  	width: ${props=>props.hide?'39px':"auto"};
  }
`

const UserContainer = styled.div`
	display:flex;
	align-items:center;
	position:relative;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	border-radius:15px;
	margin-right:10px;

	@media(max-width:768px){
		margin-right:${props=>props.hide?0:'10px'};
	}


`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
	display:${props=>props.hide?'none':'auto'};
`

const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  font-weight:bold;
  position:absolute;
  top:5px;
  right:0px;
  display:${props=>props.hide?'none':'auto'};

`

const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`