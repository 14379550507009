import React from "react";
import styled from "styled-components";

const createBulletPoints = (bulletTitle, bullets) => {
  return (
    <ActionItemsContainer>
      <ActionItemsContent>
        <BulletTitle>{bulletTitle}</BulletTitle>
        {bullets?.map((item, i) => {
          const scoreRegex = /([\-\w\:\s]*)%/g;
          const score = scoreRegex.exec(item)[0];
          const text = item.replace(`(${score})`, "");
          return (
            <BulletItem>
              <Dot>•</Dot>
              <BulletText key={i + "-bullet"}>
                {text}
                <BulletScore key={i + "-score"}>&nbsp;({score})</BulletScore>
              </BulletText>
            </BulletItem>
          );
        })}
      </ActionItemsContent>
    </ActionItemsContainer>
  );
};

const createNumberedItems = (numberedItems) => {
  return (
    <ActionItemsContainer>
      <ActionItemsContent>
        {numberedItems?.map((item, i) => {
          const boldedRegex = /[\-\w*\s*]*\w:/g;
          const boldedText = boldedRegex.exec(item);
          const text = item.replace(boldedText, "");
          return (
            <NumberedItem>
              <BoldedNumberText>
                {i + 1}. {boldedText}
                <NumberedText key={i + "-numberedText"}>
                  &nbsp;{text}
                </NumberedText>
              </BoldedNumberText>
            </NumberedItem>
          );
        })}
      </ActionItemsContent>
    </ActionItemsContainer>
  );
};

const TextBlock = (props) => {
  const {
    title,
    description,
    bullets,
    bulletTitle,
    icon,
    bgColor,
    numberedItems,
  } = props;
  return (
    <TextBlockContainer style={{ backgroundColor: bgColor ? bgColor : "#fff" }}>
      <IconContainer>
        {icon}
        <TextBlockTitle>{title}</TextBlockTitle>
      </IconContainer>
      {bullets && (
        <BulletItemsContainer>
          <BulletDescription>{description}</BulletDescription>
          {createBulletPoints(bulletTitle, bullets)}
        </BulletItemsContainer>
      )}
      {numberedItems && (
        <NumberedItemsContainer>
          {createNumberedItems(numberedItems)}
        </NumberedItemsContainer>
      )}
    </TextBlockContainer>
  );
};

const ActionItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 14px;
`;

const ActionItemsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
`;

const BulletTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  // lineHeight: 1.7,
  font-family: Raleway;
  margin-bottom: 12px;
`;

const BulletItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
`;

const Dot = styled.div`
  margin-right: 8px;
  font-size: 10px;
  // lineHeight: 1.4;
`;

const BulletText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  // lineHeight: 1.4;
`;

const BulletScore = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  // lineHeight: 1.4;
`;

const TextBlockContainer = styled.div`
  margin-top: 0px;
  color: #2a3039;
  padding-horizontal: 16px;
  padding-top: 8px;
  padding: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  border-radius: 10px;
`;

const TextBlockTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 128%;
  font-family: Raleway;
  margin-left: 12px;
`;

const BulletItemsContainer = styled.div`
  margin-top: 12px;
  margin-left: 40px;
  margin-right: 16px;
`;

const BulletDescription = styled.div`
  font-size: 14px;
  margin-top: 12px;
  font-weight: 400;
  font-style: normal;
  // lineHeight: 1.7;
  font-family: Raleway;
`;

const NumberedItemsContainer = styled.div`
  margin-top: 12px;
  margin-left: 40px;
  margin-right: 16px;
`;

const NumberedItem = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
`;

const BoldedNumberText = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  // lineHeight: 1.7;
`;

const NumberedText = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  // lineHeight: 1.7;
`;

export default TextBlock;
