export function TeamIcon(fill) {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7171 10.3764L10.7169 10.3762C10.5416 10.1595 10.5745 9.84114 10.792 9.66537M10.7171 10.3764L11.4276 10.451C12.0498 9.94744 12.8364 9.66987 13.6422 9.66987C15.5721 9.66987 17.1394 11.2212 17.1394 13.1246V14.2869C17.1394 14.317 17.1128 14.3489 17.0713 14.3489H12.6139C12.335 14.3489 12.1085 14.5753 12.1085 14.8542C12.1085 15.1333 12.335 15.3595 12.6139 15.3595H17.0712C17.6653 15.3595 18.1499 14.8793 18.1499 14.2869V13.1246C18.1499 10.6608 16.1265 8.6592 13.6422 8.6592C12.6059 8.6592 11.5939 9.01648 10.792 9.66537M10.7171 10.3764C10.8929 10.5931 11.2109 10.6266 11.4276 10.4511L10.7171 10.3764ZM10.792 9.66537L10.8863 9.78203M10.792 9.66537C10.792 9.66539 10.792 9.66541 10.7919 9.66543L10.8863 9.78203M10.8863 9.78203C10.7334 9.90557 10.7102 10.1294 10.8336 10.2819L10.8863 9.78203Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M10.9367 5.75981C10.9367 7.24012 12.1515 8.44191 13.6422 8.44191C15.1329 8.44191 16.3481 7.24033 16.3481 5.75981C16.3481 4.27931 15.1331 3.07754 13.6422 3.07754C12.1514 3.07754 10.9367 4.27951 10.9367 5.75981ZM11.9473 5.75981C11.9473 4.84003 12.706 4.08821 13.6422 4.08821C14.5786 4.08821 15.3374 4.84006 15.3374 5.75981C15.3374 6.67955 14.5788 7.43124 13.6422 7.43124C12.706 7.43124 11.9473 6.67957 11.9473 5.75981Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M2.16195 16.7849H11.8073C12.5299 16.7849 13.1193 16.2014 13.1193 15.4814V13.8467C13.1193 10.4954 10.3662 7.77175 6.98463 7.77175C3.60301 7.77175 0.85 10.4954 0.85 13.8467V15.4814C0.85 16.2014 1.4394 16.7849 2.16195 16.7849ZM1.86067 13.8469C1.86067 11.0562 4.15778 8.78259 6.98463 8.78259C9.81148 8.78259 12.1086 11.0562 12.1084 13.8469V15.4816C12.1084 15.6402 11.9758 15.7744 11.8071 15.7744H2.16195C1.99328 15.7744 1.86067 15.6402 1.86067 15.4816V13.8469Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
      <path
        d="M3.42715 4.253C3.42715 6.1312 4.96903 7.65635 6.86121 7.65635C8.75354 7.65635 10.2954 6.13138 10.2954 4.25317C10.2954 2.37515 8.75372 0.85 6.86121 0.85C4.96887 0.85 3.42715 2.37497 3.42715 4.253ZM4.43782 4.25317C4.43782 2.93582 5.52343 1.86067 6.86121 1.86067C8.19916 1.86067 9.28477 2.93583 9.28477 4.25317C9.28477 5.5707 8.19916 6.64586 6.86121 6.64586C5.52344 6.64586 4.43782 5.57071 4.43782 4.25317Z"
        fill={fill}
        stroke={fill}
        strokeWidth="0.3"
      />
    </svg>
  );
}

export function CheckIcon(fill) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C7.51664 1.5 6.0666 1.93987 4.83323 2.76398C3.59986 3.58809 2.63856 4.75943 2.07091 6.12987C1.50325 7.50032 1.35472 9.00832 1.64411 10.4632C1.9335 11.918 2.64781 13.2544 3.6967 14.3033C4.7456 15.3522 6.08197 16.0665 7.53683 16.3559C8.99168 16.6453 10.4997 16.4968 11.8701 15.9291C13.2406 15.3614 14.4119 14.4001 15.236 13.1668C16.0601 11.9334 16.5 10.4834 16.5 9C16.4978 7.01157 15.7069 5.10522 14.3008 3.69918C12.8948 2.29315 10.9884 1.50225 9 1.5ZM9 15.5C7.71442 15.5 6.45772 15.1188 5.3888 14.4046C4.31988 13.6903 3.48676 12.6752 2.99479 11.4874C2.50282 10.2997 2.37409 8.99279 2.6249 7.73191C2.8757 6.47104 3.49477 5.31285 4.40381 4.40381C5.31285 3.49476 6.47104 2.8757 7.73192 2.6249C8.99279 2.37409 10.2997 2.50281 11.4874 2.99478C12.6752 3.48675 13.6903 4.31987 14.4046 5.38879C15.1188 6.45771 15.5 7.71442 15.5 9C15.498 10.7233 14.8126 12.3754 13.594 13.594C12.3754 14.8126 10.7233 15.498 9 15.5Z"
        fill="#9E9FA1"
      />
      <path
        d="M12.1465 6.64651L7.99996 10.793L5.85346 8.6465C5.75916 8.55543 5.63286 8.50503 5.50176 8.50617C5.37066 8.50731 5.24525 8.55989 5.15255 8.6526C5.05985 8.7453 5.00726 8.87071 5.00612 9.0018C5.00498 9.1329 5.05538 9.2592 5.14646 9.35351L7.64646 11.8535C7.74022 11.9472 7.86738 11.9999 7.99996 11.9999C8.13254 11.9999 8.2597 11.9472 8.35346 11.8535L12.8535 7.3535C12.9445 7.2592 12.9949 7.1329 12.9938 7.0018C12.9927 6.87071 12.9401 6.7453 12.8474 6.6526C12.7547 6.55989 12.6293 6.50731 12.4982 6.50617C12.3671 6.50503 12.2408 6.55543 12.1465 6.64651Z"
        fill="#9E9FA1"
      />
    </svg>
  );
}

export function PlusIcon(fill) {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.4248 0.70166L6.42487 11.1931"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M11.6707 5.94751L1.17922 5.94748"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function EditIcon(fill) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8948 5.36842C13.6505 4.61266 14.8758 4.61266 15.6316 5.36842V5.36842C16.3874 6.12418 16.3874 7.3495 15.6316 8.10526L8.69107 15.0458C8.316 15.4209 7.80729 15.6316 7.27686 15.6316L5.36845 15.6316L5.36845 13.7232C5.36845 13.1927 5.57916 12.684 5.95423 12.309L12.8948 5.36842Z"
        stroke="#666D79"
      />
      <path d="M12 6L15 9" stroke="#666D79" />
    </svg>
  );
}

export function TrashIcon(fill) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.82422 6H5.82422H21.8242"
        stroke="#476DFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8242 6V20C19.8242 20.5304 19.6135 21.0391 19.2384 21.4142C18.8634 21.7893 18.3547 22 17.8242 22H7.82422C7.29379 22 6.78508 21.7893 6.41001 21.4142C6.03493 21.0391 5.82422 20.5304 5.82422 20V6M8.82422 6V4C8.82422 3.46957 9.03493 2.96086 9.41001 2.58579C9.78508 2.21071 10.2938 2 10.8242 2H14.8242C15.3547 2 15.8634 2.21071 16.2384 2.58579C16.6135 2.96086 16.8242 3.46957 16.8242 4V6"
        stroke="#476DFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8242 11V17"
        stroke="#476DFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8242 11V17"
        stroke="#476DFA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlusCircleIcon(fill) {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8V16"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 12H16"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CalendarIcon(fill) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_2507)">
        <path
          d="M3.02198 20H16.9775C18.4405 20 19.6294 18.8111 19.6294 17.3481V4.13331C19.6294 2.67035 18.4405 1.48145 16.9775 1.48145H15.9257V0.740727C15.9257 0.333315 15.5923 0 15.1849 0C14.7775 0 14.4442 0.333315 14.4442 0.740727V1.48145H5.55533V0.740727C5.55533 0.333315 5.22195 0 4.81454 0C4.40713 0 4.07381 0.333315 4.07381 0.740727V1.48145H3.02198C1.55902 1.48145 0.370117 2.67035 0.370117 4.13331V17.3481C0.370117 18.8111 1.55902 20 3.02198 20ZM1.85163 4.13331C1.85163 3.48889 2.37755 2.96297 3.02198 2.96297H4.07381V3.7037C4.07381 4.11111 4.40713 4.44442 4.81454 4.44442C5.22195 4.44442 5.55527 4.11111 5.55527 3.7037V2.96297H14.4442V3.7037C14.4442 4.11111 14.7775 4.44442 15.1849 4.44442C15.5923 4.44442 15.9256 4.11111 15.9256 3.7037V2.96297H16.9775C17.6219 2.96297 18.1478 3.48889 18.1478 4.13331V17.3481C18.1478 17.9926 17.6219 18.5185 16.9775 18.5185H3.02198C2.37755 18.5185 1.85163 17.9926 1.85163 17.3481V4.13331Z"
          fill="#9E9FA1"
        />
        <path
          d="M5.74036 8.51849C6.25173 8.51849 6.66627 8.10395 6.66627 7.59258C6.66627 7.08121 6.25173 6.66667 5.74036 6.66667C5.229 6.66667 4.81445 7.08121 4.81445 7.59258C4.81445 8.10395 5.229 8.51849 5.74036 8.51849Z"
          fill="#9E9FA1"
        />
        <path
          d="M9.81458 8.51849C10.3259 8.51849 10.7405 8.10395 10.7405 7.59258C10.7405 7.08121 10.3259 6.66667 9.81458 6.66667C9.30322 6.66667 8.88867 7.08121 8.88867 7.59258C8.88867 8.10395 9.30322 8.51849 9.81458 8.51849Z"
          fill="#9E9FA1"
        />
        <path
          d="M13.8888 8.51849C14.4002 8.51849 14.8147 8.10395 14.8147 7.59258C14.8147 7.08121 14.4002 6.66667 13.8888 6.66667C13.3774 6.66667 12.9629 7.08121 12.9629 7.59258C12.9629 8.10395 13.3774 8.51849 13.8888 8.51849Z"
          fill="#9E9FA1"
        />
        <path
          d="M5.74036 12.2222C6.25173 12.2222 6.66627 11.8076 6.66627 11.2963C6.66627 10.7849 6.25173 10.3704 5.74036 10.3704C5.229 10.3704 4.81445 10.7849 4.81445 11.2963C4.81445 11.8076 5.229 12.2222 5.74036 12.2222Z"
          fill="#9E9FA1"
        />
        <path
          d="M9.81458 12.2222C10.3259 12.2222 10.7405 11.8076 10.7405 11.2963C10.7405 10.7849 10.3259 10.3704 9.81458 10.3704C9.30322 10.3704 8.88867 10.7849 8.88867 11.2963C8.88867 11.8076 9.30322 12.2222 9.81458 12.2222Z"
          fill="#9E9FA1"
        />
        <path
          d="M13.8888 12.2222C14.4002 12.2222 14.8147 11.8076 14.8147 11.2963C14.8147 10.7849 14.4002 10.3704 13.8888 10.3704C13.3774 10.3704 12.9629 10.7849 12.9629 11.2963C12.9629 11.8076 13.3774 12.2222 13.8888 12.2222Z"
          fill="#9E9FA1"
        />
        <path
          d="M5.74036 15.9259C6.25173 15.9259 6.66627 15.5113 6.66627 15C6.66627 14.4886 6.25173 14.0741 5.74036 14.0741C5.229 14.0741 4.81445 14.4886 4.81445 15C4.81445 15.5113 5.229 15.9259 5.74036 15.9259Z"
          fill="#9E9FA1"
        />
        <path
          d="M9.81458 15.9259C10.3259 15.9259 10.7405 15.5113 10.7405 15C10.7405 14.4886 10.3259 14.0741 9.81458 14.0741C9.30322 14.0741 8.88867 14.4886 8.88867 15C8.88867 15.5113 9.30322 15.9259 9.81458 15.9259Z"
          fill="#9E9FA1"
        />
        <path
          d="M13.8888 15.9259C14.4002 15.9259 14.8147 15.5113 14.8147 15C14.8147 14.4886 14.4002 14.0741 13.8888 14.0741C13.3774 14.0741 12.9629 14.4886 12.9629 15C12.9629 15.5113 13.3774 15.9259 13.8888 15.9259Z"
          fill="#9E9FA1"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2507">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function CloseIcon(fill) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon">
        <g id="Vector">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.14272 6.14272C6.333 5.95243 6.64152 5.95243 6.83181 6.14272L17.8573 17.1682C18.0476 17.3585 18.0476 17.667 17.8573 17.8573C17.667 18.0476 17.3585 18.0476 17.1682 17.8573L6.14272 6.83181C5.95243 6.64152 5.95243 6.333 6.14272 6.14272Z"
            fill="#2A3039"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.8573 6.14272C18.0476 6.333 18.0476 6.64152 17.8573 6.83181L6.83181 17.8573C6.64152 18.0476 6.333 18.0476 6.14272 17.8573C5.95243 17.667 5.95243 17.3585 6.14272 17.1682L17.1682 6.14272C17.3585 5.95243 17.667 5.95243 17.8573 6.14272Z"
            fill="#2A3039"
          />
        </g>
      </g>
    </svg>
  );
}

export function SaveIcon(fill) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7156 5.23921C16.9694 5.49305 16.9694 5.90461 16.7156 6.15845L7.65381 15.2202L3.28413 10.8505C3.03029 10.5967 3.03029 10.1851 3.28413 9.93129C3.53797 9.67745 3.94953 9.67745 4.20337 9.93129L7.65381 13.3817L15.7963 5.23921C16.0502 4.98537 16.4617 4.98537 16.7156 5.23921Z"
        fill="#666D79"
      />
    </svg>
  );
}