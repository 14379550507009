import React from "react";
import styled from "styled-components";

const ProgressBar = (props) => {
  const { completed, label, isImpact } = props;

  const getBgColor = (completed, isImpact) => {
    if (isImpact) {
      if (completed >= 20) {
        return "#27CDA7";
      }
      if (completed >= 16) {
        return "#2D70E2";
      }
      if (completed >= 6) {
        return "#FEA711";
      }
      if (completed >= 0) {
        return "#F15062";
      }
    } else {
      if (completed >= 90) {
        return "#27CDA7";
      }
      if (completed >= 75) {
        return "#2D70E2";
      }
      if (completed >= 40) {
        return "#FEA711";
      }
      if (completed >= 0) {
        return "#F15062";
      }
    }
  };

  const getRating = (completed, isImpact) => {
    if (isImpact) {
      if (completed >= 20) {
        return "Very Strong";
      }
      if (completed >= 16) {
        return "Strong";
      }
      if (completed >= 6) {
        return "Moderate";
      }
      if (completed >= 0) {
        return "Low";
      }
    } else {
      if (completed >= 90) {
        return "Very Strong";
      }
      if (completed >= 75) {
        return "Strong";
      }
      if (completed >= 40) {
        return "Moderate";
      }
      if (completed >= 0) {
        return "Low";
      }
    }
  };

  const background = getBgColor(completed, isImpact);
  const total = isImpact ? completed * 3 : completed;
  return (
    <Container>
      <ProgressContainer>
        <Progress bgColor={background} completed={total} />
      </ProgressContainer>
      <InfoSection>
        <Rating color={background}>{getRating(completed, isImpact)}</Rating>
        <InfoText>{label}</InfoText>
      </InfoSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-right: 15px;
  min-width: 172px;
  justify-content: flex-start;
  z-index: 999;
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
  height: 7px;
  min-width: 50px;
  background-color: #e0e0de;
  border-radius: 50px;
  transform: rotate(-90deg);
`;

const Rating = styled.div`
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => `${props.color}`};
`;

const Progress = styled.div`
  height: 100%;
  max-width: 50px;
  width: ${(props) => `${props.completed}%`};
  background-color: ${(props) => `${props.bgColor}`};
  border-radius: inherit;
`;

const InfoText = styled.div`
  font-family: Raleway;
  color: #666d79;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const InfoSection = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: -20px;
  position: relative;
`;

export default ProgressBar;
