import styled, { keyframes } from "styled-components";
import React, { useState } from "react";
import QChart from "./QChart";
import Spark from "assets/images/UI/mdi_sparkles-outline.svg";
import { Pagination } from "semantic-ui-react";

const Questions = ({ data, navigation, isChange, setAIChatOpen,read_feedback }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  const get_feedback_section = () => {
    if (data && data[0]?.feedback?.length > 0 && read_feedback) {
      const feedbackItems = data[0].feedback
        .filter((f) => f)
        .sort((a, b) => b.length - a.length);
      const totalPages = Math.ceil(feedbackItems.length / itemsPerPage);
      const displayedFeedback = feedbackItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );

      return (
        <Feedback>
          <Title>Feedback</Title>
          {displayedFeedback.map((item, index) => (
            <Row key={index}>{item}</Row>
          ))}
          {totalPages > 1 && (
            <PaginationWrapper>
              <Pagination
                activePage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
              />
            </PaginationWrapper>
          )}
        </Feedback>
      );
    }
    return "";
  };
  return (
    <Container>
      {data && (
        <QChart
          data={data}
          delay={0.5}
          categoryName={
            navigation?.sub_category?.name || navigation?.factor?.name
          }
          isChange={isChange}
        />
      )}

      {get_feedback_section()}
    </Container>
  );
};

export default Questions;

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Feedback = styled.div`
  width: 100%;
`;

const Row = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  position: relative;
  padding: 20px;
  min-height: 100px;
`;

const IMG2 = styled.img`
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
`;

const AIButton = styled.div`
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;