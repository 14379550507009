import React, { useState,useEffect } from 'react';
import styled from 'styled-components';


const EditJsonComponent = ({ jsonString  }) => {
  // Parse JSON and store it in the state
  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      const parsedData = JSON.parse(jsonString);
      setData(parsedData);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      // Handle error or set default state
    }
  }, [jsonString]);
  // Function to handle text change
  const handleTextChange = (text, index) => {
    const newData = [...data];
    newData[index].text = text;
    setData(newData);
  };

  return (
    <Container>
      {data.map((item, index) => (
        <EditItem key={index}>
          <Label>{item.type}</Label>
          <TextArea 
            value={item.text} 
            onChange={(e) => handleTextChange(e.target.value, index)}
          />
        </EditItem>
      ))}
    </Container>
  );
};

export default EditJsonComponent;


const Container = styled.div`
    width:100%;
`

const EditItem = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:30px;
`

const Label = styled.div`
    font-family:'Raleway';
    font-size:16px;
    font-weight:500;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:14px;
    font-weight:400;
    line-height:1.5;
`