import styled, { keyframes } from "styled-components";
import React from "react";
import Team from './Team'
import { Dropdown,Icon } from 'semantic-ui-react'
import Profile from './Profile'

const options =[
{
	text:'Overall',
	'value':'Overall'
},
{
	text:'Team Health',
	'value':'Team Health'
},
{
	text:'Emotional Health',
	'value':'Emotional Health'
},
{
	text:'Clarity',
	'value':'Clarity'
},
{
	text:'Alignment',
	'value':'Alignment'
},
{
	text:'Resilience',
	'value':'Resilience'
},
{
	text:'Impact',
	'value':'Impact'
},
{
	text:'Trust',
	'value':'Trust'
},
{
	text:'Communication',
	'value':'Communication'
},
{
	text:'Enjoyment',
	'value':'Enjoyment'
},
{
	text:'Motivation',
	'value':'Motivation'
},
{
	text:'Curiosity',
	'value':'Curiosity'
},
{
	text:'Stress',
	'value':'Stress'
},
{
	text:'Discontent',
	'value':'Discontent'
},
{
	text:'Anger',
	'value':'Anger'
}
]


const TeamExplore = () =>{


	return (
		<Container>
			<Left>
				<Filter>
					<Dropdown
						    icon='sort amount down'
						    inline
						    scrolling
						    options={options}
						     defaultValue={options[0].value}
						      className='icon'
						  />
				</Filter>
				<Team selected={true} name={'Team A'}/>
				<Team name={'Team B'}/>
				<Team name={'Team C'}/>
				<Team name={'Team D'}/>
			</Left>
			<Profile/>
		</Container>
		)
}


export default TeamExplore


const Left = styled.div`
	width:250px;

`

const Container = styled.div`
	display:flex;
	justify-content:space-between;
`

const Filter = styled.div`
	display:flex;
	width:100%;
	justify-content:flex-end;

`