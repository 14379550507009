import {
  CREATE_PDF_SAVED_COMMENTS,
  GET_PDF_SAVED_COMMENTS,
  UPDATE_PDF_SAVED_COMMENTS,
  DELETE_PDF_SAVED_COMMENTS,
} from "constants/actions";

const initialState = {
  pdfComments: [],
  createdNew: false,
  pending: false,
};

export default function PDFComments(state = initialState, action) {
  switch (action.type) {
    case CREATE_PDF_SAVED_COMMENTS:
      return {
        ...state,
        pdfComments: action.payload,
      };

    case GET_PDF_SAVED_COMMENTS:
      return {
        ...state,
        pdfComments: action.payload,
      };

    case UPDATE_PDF_SAVED_COMMENTS:
      const updateIndex = state.pdfComments.findIndex((comment) => {
        return comment.id === action.payload.id;
      });

      const updateState = [...state.pdfComments];
      updateState[updateIndex] = action.payload;

      return {
        ...state,
        pdfComments: [...updateState],
      };

    case DELETE_PDF_SAVED_COMMENTS:
      const newComments = state.pdfComments.filter(
        (comment) => comment.id !== action.payload
      );
      return {
        ...state,
        pdfComments: newComments,
      };

    default:
      return state;
  }
}
