import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Message, Modal, Input, Button, List, Icon } from 'semantic-ui-react'

import Select from "react-select";
import ColorPicker from './ColorPicker'

import { useDispatch } from "react-redux";

import {
    SG_EDIT_SURVEY_STRUCTURE
  } from "constants/actions";

const PriorityList = [
    { value: 'primary', label: "Primary" },
    { value: 'secondary', label: "Secondary" },
    { value: 'tertiary', label: "Tertiary" },
]

function stringToHexColor(str, s, l) {
    var hash = 0;
    for (var i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return hslToHex(h, s, l);
}

function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
}

const NewCategory = (props) => {

    const { rowId,
        trigger,
        surveyStruc, setSurveyStruc,
        surveyStrucRow,
        setSurveyStrucRow,
        openNewCategory,
        setOpenNewCategory,
        organizationId,
        surveyStructureId } = props

    const dispatch = useDispatch();

    const [pristine, setPristine] = useState(true)
    const [listName, setListName] = useState('')
    // const [open, setOpen] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [color, setColor] = useState('#BCBCBC')
    const [formErrors, setFormErrors] = useState([]);

    const onSubmit = () => {
        if (formErrors.length > 0) {
            setPristine(false)
            return
        }
        setPristine(true)
        setOpenNewCategory(false)
        // const _strucRows = []
        let NewRow = []
        const _strucRow = []
        if (rowId) {
            surveyStrucRow.filter(st => st?.id !== rowId).map(sl => {
                return _strucRow.push({id: uuidv4(), ...sl})
            })
            _strucRow.push(surveyStruc)
            NewRow = _strucRow
            setSurveyStrucRow(_strucRow)
        } else {
            if (surveyStrucRow?.length > 0) {
                surveyStrucRow.map(sl => {
                    return _strucRow.push({id: uuidv4(), ...sl})
                })
                // console.log("Add Rows")
                _strucRow.push({
                    "id": uuidv4(),
                    "subfactors": [],
                    ...surveyStruc
                })
                NewRow = _strucRow
                setSurveyStrucRow(_strucRow)
            } else {
                // console.log("New Rows")
                NewRow = [
                    {
                        "id": uuidv4(),
                        "subfactors": [],
                        ...surveyStruc
                    }]
                setSurveyStrucRow(NewRow)
            }
        }

        dispatch({
			type: SG_EDIT_SURVEY_STRUCTURE,
			payload: {
                id: surveyStructureId,
			  organization: organizationId,
			  categories: NewRow,
			},
		  });
        setSurveyStruc({})

    }

    // console.log(rowId)
    // console.log(surveyStruc)

    useEffect(() => {
        const ErrorList = []
        if (!surveyStruc?.priority) {
            ErrorList.push("Select a Priority")
        }
        if (!surveyStruc?.name) {
            ErrorList.push("Enter a Name")
        }
        if (!surveyStruc?.subtitle) {
            ErrorList.push("Enter a Subtitle")
        }
        // if (!surveyStruc?.category) {
        //     ErrorList.push("Enter a category")
        // }
        // if (!surveyStruc?.color) {
        //     ErrorList.push("Select a Color")
        // }
        if (!surveyStruc?.options || surveyStruc?.options?.length === 0) {
            ErrorList.push("Add atleast one option")
        }
        setFormErrors(ErrorList)
    }, [surveyStruc, updated]);

    function reset() {
        setOpenNewCategory(false)
    }
    function AddToList() {
        const _survey = surveyStruc
        const _options = _survey?.options ? _survey?.options : []

        if (listName.length > 0) {
            if (!_options.find(({ name }) => name === listName)) {
                _options.push({
                    id: uuidv4(),
                    name: listName,
                    color: color
                })
            }
        }
        _survey.options = _options
        setSurveyStruc(_survey)
        setListName('')
        setUpdated(Math.random())
    }

    function RemoveFromList(key) {
// "Winter Sports" "Summer Sports"
        const RemoveName = surveyStruc?.options[key]?.name
        let ChangedSubFactor = false
        let SubFactors = surveyStruc?.subfactors
        if (SubFactors?.length > 0) {
            const SubFactorFound = SubFactors.filter(ss => ss.option === RemoveName)
            if (SubFactorFound?.length > 0) {
                // remove subfactors
                SubFactors = SubFactors.filter(ss => ss.option !== RemoveName)
                ChangedSubFactor = true
            }
        }
        // console.log(SubFactors)
        const _survey = surveyStruc
        const _options = surveyStruc?.options

        _options.splice(key, 1)
        _survey["options"] = _options
        if (ChangedSubFactor) {
            _survey['subfactors'] = SubFactors
        }
        // console.log(_survey)

        setSurveyStruc(_survey)
        const ErrorList = formErrors
        if (!_survey?.options && _survey?.options?.length === 0) {
            ErrorList.push("Add atleast one option")
        }
        setFormErrors(ErrorList)
        setUpdated(Math.random())
    }

    function AddDetails(name, value) {
        const _survey = surveyStruc
        _survey[name] = value
        setSurveyStruc(_survey)
        setUpdated(Math.random())
    }

    function UpdatedOptionList(e) {
        setListName(e.target.value)
        // const color = stringToHexColor(e.target.value, 50, 60)
        // console.log(color)
        setColor(stringToHexColor(e.target.value, 50, 60))
    }

    return (
        <Modal
            onClose={() => reset()}
            onOpen={() => setOpenNewCategory(true)}
            open={openNewCategory}
            trigger={trigger}
            size={"small"}
        >
            <Modal.Header>
                Create a category
            </Modal.Header>
            <div style={{ display: 'flex' }}>
                <Half>
                    {formErrors.length > 0 && !pristine &&
                        <Message negative
                            //   onDismiss={clearErrors}
                            header='We need these details!'
                            list={formErrors}
                        />
                    }
                    {/* {error ? <ErrorMessage>*{error}</ErrorMessage> : ""} */}
                    <FormContainerTop>


                    </FormContainerTop>

                    <FormContainerTop>
                        <Label>Priority</Label>
                        <Select
                            placeholder="Select Priority"
                            onChange={(e) => AddDetails("priority", e.value)}
                            value={PriorityList.find(({ value }) => value === surveyStruc?.priority)}
                            options={PriorityList}
                        />
                    </FormContainerTop>

                    <FormContainer>
                        <Label>Category Name</Label>
                        <Input
                            fluid
                            placeholder='Category Name'
                            name="name"
                            // defaultValue={surveyStruc?.name}
                            value={surveyStruc?.name? surveyStruc?.name : ''}
                            onChange={(v) => AddDetails("name", v.target.value)}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Subtitle</Label>
                        <Input
                            fluid
                            placeholder='subtitle'
                            name="subtitle"
                            onChange={(v) => AddDetails("subtitle", v.target.value)}
                            value={surveyStruc?.subtitle}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Question</Label>
                        <Input
                            fluid
                            placeholder='Question'
                            type="question"
                            onChange={(v) => AddDetails("question", v.target.value)}
                            value={surveyStruc?.question || ''}
                        />
                    </FormContainer>

                    <FormContainer>
                        <Label>Option Headings</Label>
                        <List divided verticalAlign='middle'>
                            {surveyStruc?.options?.length > 0 &&
                                surveyStruc?.options.map((item, key) => {
                                    return (
                                        <List.Item key={key}>
                                            <RowList>
                                                <Icon
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => RemoveFromList(key)}
                                                    name='minus circle' />

                                                <ColorBlock color={item?.color} />
                                                <List.Content>{item?.name}</List.Content>
                                            </RowList>

                                        </List.Item>
                                    )
                                })}
                        </List>

                        <Input fluid type='text' placeholder='Option Heading' action>
                            <input name="options" value={listName}
                                onChange={(e) => UpdatedOptionList(e)} />

                            <ColorPicker
                                color={color}
                                setColor={(c) => setColor(c.hex)}
                            />
                            <Button onClick={AddToList}>Add + </Button>
                        </Input>
                    </FormContainer>

                    <Submit
                        floated='right'
                        disable={formErrors.length > 0 && !pristine}
                        onClick={onSubmit}>{rowId ? "update" : "Create"}</Submit>
                </Half>
            </div>
        </Modal >
    )
}

export default NewCategory


const Container = styled.div`
	// margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
	// padding:30px;
	// box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:400px;
	display:flex;
	align-items:center;
	flex-direction:column;
`
const Half = styled.div`
	width:100%;
	padding:30px 30px;
`

const RowList = styled.div`
    display: flex;
    align-items: stretch; 
    justify-content: flex-start;
`

const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-bottom:30px;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
    width: 100%;
	margin-top:20px;
`

const FormContainerTop = styled.div`
    width: 100%;
	margin-top:0px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;

`
