import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Modal } from "semantic-ui-react";
import { XIcon } from "../audit/Icons";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { UPDATE_EMPLOYEE, SG_ADD_EMPLOYEE_LOG } from "constants/actions";



const ChangeStatusModal = ({
    EmployeeStatusList,
  changeUserStatus,
  setChangeUserStatus,
}) => {
    const dispatch = useDispatch();
    const [deactiveReason, setDeactiveReason] = useState("");
    const [selectedUserStatus, setSelectedUserStatus] = useState(EmployeeStatusList.filter((item) => item.value === changeUserStatus?.status)[0]);

    useEffect(() => {
        setSelectedUserStatus(EmployeeStatusList.filter((item) => item.value === changeUserStatus?.status)[0]);
    },[changeUserStatus])

  function UpdateEmployee() {

    dispatch({
        type: UPDATE_EMPLOYEE,
        payload: {
          id: changeUserStatus.id,
          status: selectedUserStatus?.value,
        },
      });
      // SG_ADD_EMPLOYEE_LOG
      dispatch({
        type: SG_ADD_EMPLOYEE_LOG,
        payload: {
          employee: changeUserStatus.id,
          type_of_log: 2,
          description: {
            status: selectedUserStatus?.value,
            status_label: selectedUserStatus?.label,
            reason: deactiveReason,
          },
        },
      });
      setChangeUserStatus("");
  }
  return (
    <Modal
      onClose={() => {
        // setSelectedUser();
        setChangeUserStatus("");
      }}
      size="tiny"
      open={changeUserStatus?.id > 0}
    >
      <ModalContents>
        <ModalHeader>Change Employee Status from {changeUserStatus?.status_text}</ModalHeader>
        <ModalBody>
          <InputDescription>Please select a reason:</InputDescription>
          <InputContainer>
            <Select
              placeholder="Select Employee Status"
              onChange={(e) => setSelectedUserStatus(e)}
              value={selectedUserStatus}
              options={EmployeeStatusList}
            />
          </InputContainer>

          <InputDescription>Please enter a Description:</InputDescription>
          <InputContainer>
            <RowData
              value={deactiveReason}
              placeholder="Comments"
              onChange={(s, t) => {
                setDeactiveReason(s.target.value);
              }}
            />
          </InputContainer>
          <ModalButtons>
            <ArchiveCancelButton
              onClick={() => {
                // setSelectedUser();
                setChangeUserStatus(false);
              }}
            >
              <CancelButtonText>Cancel</CancelButtonText>
              <CancelButtonIcon>{XIcon()}</CancelButtonIcon>
            </ArchiveCancelButton>
            <ArchiveConfirmButton onClick={UpdateEmployee}>
              <SaveText>Update Status</SaveText>
            </ArchiveConfirmButton>
          </ModalButtons>
        </ModalBody>
      </ModalContents>
    </Modal>
  );
};

export default ChangeStatusModal;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
`;

const ArchiveButton = styled.div`
  width: 173px;
  height: 44px;
  cursor: pointer;
  background-color: #476dfa;

  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
`;

const ModalContents = styled.div`
  background-color: white;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
`;

const ModalHeader = styled.div`
  font-family: "Poppins";
  padding-bottom: 45px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;

  color: #414141;
`;

const ModalBody = styled.div`
  padding-top: 5px;
`;

const InputDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const InputContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowData = styled.input`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px 12px 32px;
  gap: 10px;
  width: 376px;
  height: 48px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;

  color: #9e9fa1;

  border: 1px solid #dfdfdf;
  border-radius: 99px;
`;

const ArchiveCancelButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 45px;
  cursor: pointer;
`;

const CancelButtonText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #c0c0c0;
  cursor: pointer;
`;

const CancelButtonIcon = styled.div`
  line-height: 15px;
  padding-top: 1px;
  padding-left: 5px;
  cursor: pointer;
`;

const ArchiveConfirmButton = styled.div`
  width: 173px;
  height: 44px;
  cursor: pointer;
  background-color: #476dfa;

  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 100px;
`;

const SaveText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;
