import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Constants
import {
  SG_GET_ACTION_PLAN_ACTIVITIES,
  SG_GET_ACTION_PLAN_ACTIVITY,
  SG_GET_ALL_ACTION_PLANS,
} from "constants/actions";

/**
 * Sets the actionPlans, and fetches the activity for
 * the currently active plan
 *
 * @param {orgId: number, planId: number }
 * @returns
 */
function useGetActionPlan(params) {
  const dispatch = useDispatch();

  const { plans, planActivity, planActivities } = useSelector((state) => ({
    plans: state.actionPlan.plans,
    planActivity: state.actionPlan.planActivity,
    planActivities: state.actionPlan.planActivities,
  }));

  useEffect(() => {
    if (params.orgId || params.employeeId) {
      dispatch({
        type: SG_GET_ALL_ACTION_PLANS,
        payload: params.employeeId
          ? `page_size=1000&employee=${params.employeeId}`
          : `page_size=1000&organization=${params.orgId}`,
      });
    }
  }, [dispatch, params.orgId, params.employeeId]);

  useEffect(() => {
    if (params.planId) {
      dispatch({
        type: SG_GET_ACTION_PLAN_ACTIVITY,
        payload: `action_plan=${params.planId}`,
      });
    }
  }, [dispatch, params.planId]);

  useEffect(() => {
    dispatch({
      type: SG_GET_ACTION_PLAN_ACTIVITIES,
    });
  }, [dispatch, params.orgId]);

  return {
    actionPlans: plans,
    planActivity: planActivity,
    planActivities: planActivities,
  };
}

export default useGetActionPlan;
