import React from 'react'
import { Routes, Route } from "react-router-dom";
import Standards from "standards/index";
import Lost from "404";
//
function RoutesStandard() {
  return (
    <Routes>
        <Route path="/" element={<Standards />} />
        <Route path={"*"} element={<Lost /> }/>
    </Routes>
  );
}

export default RoutesStandard;
