import styled from "styled-components";
import React, { useState, useEffect } from 'react'
// import { Icon } from 'semantic-ui-react'
// import Chart from "react-apexcharts";
// import { Progress, Icon } from 'semantic-ui-react'
import Theme from './Theme'


const SystemThemes = ({ CommentsData, selected, employeeData, color }) => {

    // const [show, setShow] = React.useState(false)

    const [percentTheme, setPercentTheme] = useState([])

    // console.log(CommentsData)

    useEffect(() => {
        const ListOfTheme = {}
        const _finalList = []
        let total = 0
        if (CommentsData) {
         
            CommentsData.filter(cd => cd.comments !== '').filter(p => p.category === selected?.name).map(cd =>
                {if (cd.category_name in ListOfTheme) {
                    ListOfTheme[cd.category_name]['score'] += cd.z_score[0]?.importance
                    ListOfTheme[cd.category_name]['zscore'] += cd.z_score[0]?.z_score
                    ListOfTheme[cd.category_name]['total'] += 1
                } else {
                    ListOfTheme[cd.category_name] = {
                        'score':cd.z_score[0]?.importance,
                        'total':1,
                        'zscore':cd.z_score[0]?.z_score}
                }

                total+=1
             
            }
               
                
            )
            // console.log(CommentsData.filter(p => p.category === selected.name))
        }

        for (const property in ListOfTheme) {
                _finalList.push({
                    name: property,
                    percent: Math.round((ListOfTheme[property].score / ListOfTheme[property]?.total) ),
                    zscore:ListOfTheme[property].zscore / ListOfTheme[property]?.total,
                    users:Math.round(ListOfTheme[property].total/total*100)
                })
            // console.log(`${key}: ${value}`);
          }

// console.log(_finalList)

        setPercentTheme(_finalList.filter(cd => cd.name !== '').sort((a, b) => {
            return b.percent - a.percent;
        }))
    }, [CommentsData, selected]);

    // console.log(selectedComments)
     

    return (
        
        percentTheme.slice(0,3).map((p, k) => {
                return <Theme score={p.percent} name={p.name} key={k} system={selected.name} z_score={p.zscore} percentage={p.users} color={selected}/>
           
        })
            
            
        
    )
}


export default SystemThemes

const Card = styled.div`
 	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 	padding:30px 20px;
 	width:${props => 'calc(' + props.width + '% - 20px)'};
 	margin-right:20px;
 	margin-top:20px;

  @media (max-width:900px){
    width:100%;
    padding:20px;
  }

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:20px;
`

const SubText = styled.div`
  font-family: 'Red Hat Display', sans-serif;

`