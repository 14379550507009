import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Textbox from "../PlanComponent/Textbox";
import SectionTitle from "../PlanComponent/SectionTitle";
import PillarLabel from "../PlanComponent/PillarLabel";
import GroupTitle from "../PlanComponent/GroupTitle";
import MultiDropdown from "../PlanComponent/MultiDropdown";
import TextInput from "../PlanComponent/TextInput";
import DateSelector from "../PlanComponent/DateSelector";
import MultiselectUser from "../PlanComponent/MultiselectUser";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_GET_EMPLOYEE_CATEGORIES } from "constants/actions";
import Timelines from "../PlanComponent/Timelines";
import StatusDropdown from "../PlanComponent/StatusDropdown";
import ResourceLink from "../PlanComponent/ResourceLink";
import ReflectionTable from "../PlanComponent/ReflectionTable";
import MultiDataTable from "../PlanComponent/MultiDataTable";
import DropdownTable from "../PlanComponent/DropdownTable/DropdownTable";
import { Icon } from "semantic-ui-react";
import planningTemplates from "../templates/planningTemplates.json";
import { EditIcon, SaveIcon, CloseIcon } from "../Icons";
import {
  SG_UPDATE_ACTION_PLAN,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";
import Tabs from "../Tabs";
import Update from "../Update";
import QuestionsDropdown from "./QuestionsDropdown";
import SportLabel from "../PlanComponent/SportLabel";
import Assignee from "../Assignee";
import EIAComponent from "../PlanComponent/EIAComponent";
import ComponentDescription from "../PlanComponent/ComponentDescription";
import WellnessTable from "../PlanComponent/WellnessTable";
import WellnessTop from "../PlanComponent/WellnessTop";
import BarGraph from "../PlanComponent/BarGraph";
import PDF from "../../PDF";
import DateRange from "../PlanComponent/DateRange";

const planStatus = {
  Planned: {
    id: 1,
    statusText: "Planned",
    color: "71, 108, 250",
  },
  InProgress: {
    id: 2,
    statusText: "In Progress",
    color: "243, 171, 60",
  },
  ActionRequired: {
    id: 3,
    statusText: "Action Required",
    color: "230, 101, 121",
  },
  // OnHold: {
  //   id: 4,
  //   statusText: "On Hold",
  //   color: "209, 199, 2",
  // },
  Completed: {
    id: 5,
    statusText: "Completed",
    color: "102, 214, 134",
  },
  Archived: {
    id: 11,
    statusText: "Archived",
    color: "138, 138, 138",
  },
  Delete: {
    id: 99,
    statusText: "For Deletion",
    color: "138, 138, 138",
  },
};

const EditLayout = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    pillar,
    open,
    close,
    updatePlans,
    categories,
    questions,
    updateStatus,
    planMembers,
    updatePlanMembers,
  } = props;
  const [employeesList, setEmployeesList] = React.useState();
  const [editEnabled, setEditEnabled] = React.useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [tab, setTab] = useState("Details");
  // TODO: Figure out a way to nav through pages if multiple pages
  const [planPage, setPlanPage] = useState(0);
  const [editData, setEditData] = useState();
  const [selectedMetric, setSelectedMetric] = useState();
  const [addAssignee, setAddAssignee] = useState(false);
  const [editedMembers, setEditedMembers] = useState();
  const [employeesListSet, setEmployeesListSet] = React.useState(false);
  const { get_employees, get_auth, get_employee_categories } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employee_categories: state.employee_category.employee_category,
      get_employees: state.employees,
    }),
    shallowEqual
  );
  const options = ["Details", "Activity", "Export as PDF"];

  useEffect(() => {
    // Create a copy of the data object and set it to editData
    const _data = Object.assign({}, data);
    const formattedObject = {
      planData: _data?.plan?.[0]?.planData,
      primary: _data?.primary,
      member: _data?.assignees,
      status: _data?.status,
      startDate: _data?.start_Date,
      endDate: _data?.end_Date,
      planId: _data?.plan?.[0]?.planId,
      metric: _data?.metric,
      question: _data?.question,
    };
    setEditData(formattedObject);
  }, [data]);

  useEffect(() => {
    if (data && questions) {
      const questionsGroup = questions?.questions?.dimensions
        ?.find((dimension) =>
          dimension?.factors?.find(
            (f) => f.title.toLocaleLowerCase() === data?.metric
          )
        )
        ?.factors.find((f) => f.title.toLocaleLowerCase() === data?.metric);
      setSelectedMetric(questionsGroup);
    }
  }, [questions, data]);

  const updatePrimary = (d) => {
    setEditData((prevState) => ({
      ...prevState,
      primary: d,
    }));
  };

  useEffect(() => {
    if (editData?.planId && planningTemplates && !selectedPlan) {
      const selectedPlan = planningTemplates?.find(
        (p) => p.id === editData?.planId?.id
      );
      setSelectedPlan(selectedPlan);
    }
  }, [editData]);

  useEffect(() => {
    setEmployeesListSet(false);
  }, [editData?.primary]);

  const handleStartDateChange = (d) => {
    setEditData((prevState) => ({
      ...prevState,
      startDate: d,
    }));
    setStartDate(d);
  };

  const handleEndDateChange = (d) => {
    setEditData((prevState) => ({
      ...prevState,
      endDate: d,
    }));
    setEndDate(d);
  };

  const updateAssignees = (assignees) => {
    if (assignees?.length > 0) {
      const membersList = assignees
        ?.filter((p) => p?.id)
        ?.map((p) => {
          return p.id;
        });
      const _data = Object.assign({}, editData);
      const formattedAssignees = assignees
        ?.filter((p) => p?.id)
        ?.map((p) => {
          return {
            id: p.id,
            first_name: p.name.split(" ")[0],
            last_name: p.name.split(" ")[1],
          };
        });

      _data.member = formattedAssignees;
      setEditData(_data);

      const updateData = {
        assignees: membersList,
      };

      dispatch({
        type: SG_UPDATE_ACTION_PLAN,
        payload: { data: updateData, id: data.id },
      });
    }
  };

  useEffect(() => {
    if (get_auth && get_auth?.organization) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `organization=${get_auth?.organization?.id}`,
      });
    }
  }, [get_auth]);

  const handleSave = (d) => {
    const updateData = {
      metric: editData?.metric.toLowerCase(),
      action_plan: data?.plan,
      plan: [
        {
          planId: data?.plan?.[0]?.planId,
          planData: editData?.planData,
        },
      ],
      status: editData?.status,
      primary: editData?.primary,
      start_date: editData?.startDate,
      end_date: editData?.endDate,
    };

    dispatch({
      type: SG_UPDATE_ACTION_PLAN,
      payload: { data: updateData, id: data.id },
    });
    // updatePlans(editData);
    close();
  };

  useEffect(() => {
    if (get_employees && get_employees?.employees.length > 0 && editData) {
      let employeeOptions = get_employees?.employees.map((employee) => {
        if (employee.id) {
          const displayText = employee.first_name
            ? `${employee.first_name} ${employee.last_name}`
            : employee.email;

          return {
            key: employee.id,
            text: displayText,
            value: {
              id: employee.id,
              name: displayText,
              member: displayText,
            },
          };
        }
      });
      if (employeeOptions) {
        if (get_employee_categories?.length > 1) {
          const filteredEmps = employeeOptions.filter((f) => {
            const empCats = get_employee_categories.find(
              (c) => c.employee === f.key
            );
            const validEmployee = empCats?.category?.find(
              (cat) =>
                cat?.name_id === editData.primary ||
                cat?.value_id === editData.primary
            );
            return !!validEmployee;
          });

          setEmployeesList(filteredEmps);
        }
      } else {
        setEmployeesList(employeeOptions);
      }
      setEmployeesListSet(true);
    }
  }, [employeesListSet, get_employees, get_employee_categories, editData]);

  // Handle date changes
  //   Plan start date.
  useEffect(() => {
    if (startDate) {
      const _data = Object.assign({}, editData);
      _data.startDate = startDate;
      setEditData(_data);
    }
  }, [startDate]);

  const getPlanStatus = (id) => {
    const keys = Object.keys(planStatus);

    let status = null;
    keys.forEach((key) => {
      if (planStatus[key].id === id) {
        status = planStatus[key];
      }
    });

    return status;
  };

  //   //  Plan end date.
  useEffect(() => {
    if (endDate) {
      const _data = Object.assign({}, editData);
      _data.endDate = endDate;
      setEditData(_data);
    }
  }, [endDate]);

  const onTextboxChange = (value, id) => {
    setEditData((prevState) => ({
      ...prevState,
      planData: {
        ...prevState.planData,
        [id]: value,
      },
    }));
  };

  const onDropdownChange = (value, id, index) => {
    const _data = Object.assign({}, editData);

    _data.planData[id][index].value = value;
    // setEditData(_data);
    setEditData((prevState) => ({
      ...prevState,
      planData: {
        ...prevState.planData,
        [id]: _data.planData[id],
      },
    }));
  };

  const onDropdownCommentChange = (value, id, index) => {
    const _data = Object.assign({}, editData);
    _data.planData[id][index].comment = value;
    setEditData((prevState) => ({
      ...prevState,
      planData: {
        ...prevState.planData,
        [id]: _data.planData[id],
      },
    }));
  };

  const setMember = (d) => {
    const _data = Object.assign({}, editData);
    _data.member = d;
    setEditData(_data);
  };

  const handleDateStartChange = (d, id, index) => {
    const _data = Object.assign({}, editData);
    _data.planData[id][index].startDate = d;
    setEditData(_data);
  };

  const handleDateEndChange = (d, id, index) => {
    const _data = Object.assign({}, editData);
    _data.planData[id][index].endDate = d;
    setEditData(_data);
  };

  // For reflection/dynamic tables
  const handleTableChange = (d, id) => {
    if (editEnabled) {
      setEditData((prevState) => ({
        ...prevState,
        planData: {
          ...prevState.planData,
          [id]: d,
        },
      }));
    }
  };

  const setQuestion = (d) => {
    const _data = Object.assign({}, editData);
    _data.question = [d];
    setEditData(_data);
  };

  const handleDateRangeSelected = (date, id, index) => {
    const _data = Object.assign({}, editData);
    if (Array.isArray(_data.planData[id])) {
      if (_data.planData?.[id]?.[index]) {
        _data.planData[id][index] = date;
      } else {
        _data.planData[id].push(date);
      }
    } else {
      _data.planData[id] = [date];
    }
    setEditData(_data);
  };

  const handleCheckinDatesSelected = (date, id, index) => {
    const _data = Object.assign({}, editData);
    _data.planData[id] = date;
    setEditData(_data);
  };

  const setStatus = (d) => {
    const _data = Object.assign({}, editData);
    _data.status = d;
    setEditData(_data);
    updateStatus(d, data.id);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getComponent = (componentData, pillar, index) => {
    let component;
    switch (componentData.type) {
      case "textbox":
        component = (
          <Textbox
            key={index}
            onChange={(value, id) => onTextboxChange(value, id)}
            {...componentData}
            data={editData}
            editEnabled={editEnabled}
          />
        );
        break;

      case "textinput":
        component = (
          <TextInput
            key={index}
            onChange={(value, id) => onTextboxChange(value, id)}
            {...componentData}
            data={editData?.planData}
            editEnabled={editEnabled}
          />
        );
        break;

      case "sectiontitle":
        component = <SectionTitle key={index} {...componentData} />;
        break;

      case "culturepillar":
        component = (
          <PillarLabel
            key={index}
            pillar={
              (editData?.metric?.title && editData?.metric) ||
              capitalizeFirstLetter(editData?.metric)
            }
          />
        );
        break;

      case "grouptitle":
        component = <GroupTitle key={index} {...componentData} />;
        break;

      case "multidropdown":
        component = (
          <MultiDropdown
            key={index}
            {...componentData}
            selected={editData.planData}
            handleDropdownChange={(value, id, index) =>
              onDropdownChange(value, id, index)
            }
            handleTextChange={(value, id, index) =>
              onDropdownCommentChange(value, id, index)
            }
            editEnabled={editEnabled}
            comments={editData.planData}
          />
        );
        break;

      case "dateselection":
        component = (
          <DateSelector
            key={index}
            {...componentData}
            data={editData}
            editEnabled={editEnabled}
            handleDatesSelected={(date, id) => {
              handleCheckinDatesSelected(date, id);
            }}
          />
        );
        break;

      case "daterange":
        component = (
          <DateRange
            key={index}
            {...componentData}
            data={editData}
            editEnabled={editEnabled}
            handleDatesSelected={(date, id, idx) => {
              handleDateRangeSelected(date, id, idx);
            }}
          />
        );
        break;

      case "multiselectuser":
        component = (
          <MultiselectUser
            key={index}
            employeesList={employeesList}
            onSelect={setMember}
            editEnabled={editEnabled}
            {...componentData}
            member={editData?.member}
          />
        );
        break;

      case "timeline":
        component = (
          <Timelines
            dateStart={startDate}
            dateEnd={endDate}
            editEnabled={editEnabled}
            setStartDate={(d, id, index) => {
              if (componentData?.planData) {
                handleDateStartChange(d, id, index);
              } else {
                setStartDate(d);
              }
            }}
            setEndDate={(d, id, index) => {
              if (componentData?.planData) {
                handleDateEndChange(d, id, index);
              } else {
                setEndDate(d);
              }
            }}
            {...componentData}
            data={editData}
          />
        );
        break;

      case "planstatus":
        component = (
          <StatusDropdown
            key={index}
            {...componentData}
            editEnabled={editEnabled}
            setStatus={setStatus}
            data={editData}
          />
        );

        break;

      case "reflectiontable":
        component = (
          <ReflectionTable
            key={index}
            {...componentData}
            editEnabled={editEnabled}
            onChange={(d, id) => handleTableChange(d, id)}
            data={editData}
          />
        );
        break;

      case "dynamicinputtable":
        component = (
          <ReflectionTable
            key={index}
            {...componentData}
            dynamic={true}
            editEnabled={editEnabled}
            onChange={(d, id) => handleTableChange(d, id)}
            data={editData}
          />
        );

        break;
      case "resourcelinks":
        component = <ResourceLink key={index} {...componentData} />;
        break;

      case "commenttable":
        component = (
          <MultiDataTable
            key={index}
            {...componentData}
            data={editData}
            editEnabled={editEnabled}
            onChange={(d, id) => handleTableChange(d, id)}
          />
        );
        break;

      case "dynamicdropdowntable":
        component = (
          <DropdownTable
            key={index}
            {...componentData}
            editEnabled={editEnabled}
            onChange={(d, id) => handleTableChange(d, id)}
            data={editData}
          />
        );
        break;

      case "eiacomponent":
        component = (
          <EIAComponent
            key={index}
            {...componentData}
            editEnabled={editEnabled}
            onChange={(d, id) => handleTableChange(d, id)}
            data={editData}
            questions={questions}
            editLayout={true}
            savedTemplate={true}
          />
        );
        break;

      case "wellnesstable":
        component = (
          <WellnessTable
            key={index}
            {...componentData}
            editEnabled={editEnabled}
            onChange={(d, id) => handleTableChange(d, id)}
            data={editData}
            primary={editData?.primary}
            savedTemplate={true}
            // handleWellnessData={(d) => handleWellnessData(d)}
          />
        );
        break;

      case "bargraph":
        component = (
          <BarGraph
            average={
              (
                editData?.planData?.[4] && editData?.planData?.[4]
              )?.factorAverage?.toFixed(1) || 0
            }
            categories={categories}
            data={editData?.planData?.[4] && editData?.planData?.[4]}
            savedTemplate={true}
            isFrench={false}
            title={componentData?.title}
            key={index}
          />
        );
        break;

      case "wellnesstop":
        component = <WellnessTop />;
        break;

      default:
        component = null;
    }
    return component;
  };

  return (
    <FullScreenOverlay>
      <TopBar>
        <InfoSection>
          <BackButtonContainer onClick={() => close()}>
            <Icon name="long arrow alternate left" color="#9FA0A2" />
            Back to action plans
          </BackButtonContainer>
          <PlanLabel>
            {selectedPlan?.title}{" "}
            {editData && editData?.status && (
              <Status color={getPlanStatus(editData.status).color}>
                <StatusText>
                  {getPlanStatus(editData.status).statusText}
                </StatusText>
              </Status>
            )}
          </PlanLabel>
          <TabsWrapper>
            <Tabs
              options={options}
              selected={tab}
              onSelect={(s) => setTab(s)}
              padding={false}
            />
          </TabsWrapper>
          {editData && categories && tab !== "PDF Export" && (
            <DropdownSection>
              <StatusDropdown
                setStatus={setStatus}
                data={editData}
                removePadding={true}
                removeLabels={true}
              />
              {selectedMetric && data?.metric && editData?.question && (
                <QuestionsDropdown
                  questions={selectedMetric}
                  selectedFactor={data.metric}
                  selected2={editData.question}
                />
              )}
              <SportLabel categories={categories} primary={editData?.primary} />
              {(!addAssignee && !editEnabled && editData?.member && (
                <AssigneeWrapper>
                  <AssigneeText>Assignees:</AssigneeText>
                  <Assignee
                    setAddAssignee={(val) => setAddAssignee(val)}
                    editMode={true}
                    assignees={planMembers || editData.member}
                    stacked={false}
                  />
                </AssigneeWrapper>
              )) || (
                <MultiselectUser
                  key={1}
                  hideLabels={true}
                  employeesList={employeesList}
                  onSelect={(m) => {
                    updateAssignees(m);
                  }}
                  editEnabled={true}
                  member={editData?.member?.map((m) => {
                    return employeesList?.find((e) => e?.key === m?.id)?.value;
                  })}
                  newPlanMembers={
                    planMembers &&
                    planMembers?.map((m) => {
                      return employeesList?.find((e) => e?.key === m?.id)
                        ?.value;
                    })
                  }
                />
              )}
              <TimelineSection>
                <Timelines
                  dateStart={startDate || data?.start_date}
                  dateEnd={endDate || data?.end_date}
                  editEnabled={editEnabled}
                  setStartDate={(d, id, index) => {
                    handleStartDateChange(d);
                  }}
                  setEndDate={(d, id, index) => {
                    handleEndDateChange(d);
                  }}
                  data={editData}
                />
              </TimelineSection>
              {selectedPlan?.id === 4 && (
                <AltSection>
                  {selectedMetric && (
                    <SelectedOptionContainer>
                      <Title>Metric</Title>
                      <ItemsSelected>{selectedMetric?.title}</ItemsSelected>
                    </SelectedOptionContainer>
                  )}
                  <SelectedOptionContainer>
                    <Title>Factors</Title>
                    <ItemsSelected>
                      {editData?.planData?.[1]?.join(", ")}
                    </ItemsSelected>
                  </SelectedOptionContainer>

                  <SelectedOptionContainer>
                    <Title>Question</Title>
                    <ItemsSelected>
                      {
                        questions?.questions?.dimensions[
                          editData?.planData?.[2]?.dimension
                        ]?.factors[editData?.planData?.[2]?.factor]?.questions[
                          editData?.planData?.[2]?.question
                        ]?.q
                      }
                    </ItemsSelected>
                  </SelectedOptionContainer>
                </AltSection>
              )}
            </DropdownSection>
          )}
        </InfoSection>
      </TopBar>
      {tab === "Details" && (
        <LayoutContainer>
          {selectedPlan?.template?.[planPage].screenComponents?.map(
            (componentData, index) => {
              return getComponent(componentData, pillar, index);
            }
          )}
        </LayoutContainer>
      )}
      {tab === "Activity" && <Update planId={data.id} />}
      {tab === "Export as PDF" && (
        <PDF
          planTitle={selectedPlan?.title}
          status={getPlanStatus(editData.status).statusText}
          categories={categories}
          primary={editData?.primary}
          template={selectedPlan?.template?.[planPage].screenComponents}
          data={editData}
          assignees={planMembers || editData.member}
          startDate={startDate || data?.start_date}
          endDate={endDate || data?.end_date}
          metric={selectedMetric?.title}
          factors={
            selectedPlan?.id === 4 && editData?.planData?.[1]?.join(", ")
          }
          questionsList={selectedMetric}
          focusArea={editData?.question}
          planId={selectedPlan?.id}
          question={
            questions?.questions?.dimensions[editData?.planData?.[2]?.dimension]
              ?.factors[editData?.planData?.[2]?.factor]?.questions[
              editData?.planData?.[2]?.question
            ]?.q
          }
        />
      )}
      {/* {selectedPlan?.template?.length > 1 && <div>Next page</div>} */}

      {tab === "Details" && (
        <Actions>
          <div>
            <CloseButton
              onClick={() => {
                close();
              }}
            >
              {CloseIcon()}
              Close
            </CloseButton>
          </div>
          <ActionButtons>
            <EditButton2
              onClick={() => {
                setEditEnabled(!editEnabled);
              }}
            >
              {editEnabled ? "Cancel" : "Edit"}
            </EditButton2>
            {editEnabled && (
              <SaveButton2
                onClick={() => {
                  handleSave(editData);
                }}
              >
                Save & Close
              </SaveButton2>
            )}
          </ActionButtons>
        </Actions>
      )}
    </FullScreenOverlay>
  );
};

const TabsWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DropdownSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const Label = styled.div``;

const PlanLabel = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
  min-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AltSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Title = styled.div`
  color: #666d79;
  min-width: fit-content;
  text-align: left;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  min-width: 100px;
  margin-top: 3px;
`;

const ItemsSelected = styled.div`
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 3px;
  min-width: 550px;
`;

const LayoutContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding-bottom: 150px;
`;

const FullScreenOverlay = styled.div`
  background: #fff;
  z-index: 1000;
  left: 0;
  overflow: hidden;
  padding: 20px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  padding-bottom: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  max-width: 900px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
`;

const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: #9fa0a2;
  margin-bottom: 20px;
  cursor: pointer;
`;

const SaveButton = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const EditButton = styled.div`
display: flex;
align-items: center;
margin-right: 10px;
cursor: pointer;
color:#666D79;
font-size:14px;
margin-right:30px;
`;


const CloseButton = styled.div`
display: flex;
align-items: center;
margin-right: 10px;
cursor: pointer;
color:#666D79;
font-size:14px;
margin-right:30px;
`;


const Status = styled.div`
  margin-top: 10px;
  padding: 1px;
  background-color: ${(props) => "rgba(" + props.color + ")"};
  border-radius: 30px;
  display: flex;
  width: 90px;
  height: 20px;
  width: fit-content;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-top: 2px;
`

const StatusText = styled.div`
color: white;
font-family: "Raleway";
font-style: normal;
font-weight: 300;
font-size: 8px;
line-height: 15px;
padding-left: 15px;
padding-right: 15px;
display: flex;
text-align: center;
`

const TimelineSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const AssigneeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const AssigneeText = styled.div`
color: #666d79;
min-width: fit-content;
font-kerning: none;
font-variant-numeric: lining-nums proportional-nums;
font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
  "ss01" on, "salt" on, "liga" off;
/* Caption/Semibold 14 */
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 120%;
margin-right: 20px;
margin-top: 3px;
margin-bottom: 10px;
`;


const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-radius:10px;
  padding:10px 30px;
  background-color:#DFDFDF;
  position:fixed;
  bottom:20px;
`;

const ActionText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2A3039;
  display:flex;
  align-items:center;
`
const EditButton2 = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #7f7f7f;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-right:5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;


const SaveButton2 = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2D70E2;
  border: none;
  outline: none;
  padding: 0px 30px;
  margin-right:5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;


export default EditLayout;
