import styled, { keyframes } from "styled-components";
import React from "react";
import SelectItem from "../../../components/common/SelectItem";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  Subsubtext,
} from "reports/DataJourney/components/common/styles";

const stepData = [
  {
    id: 1,
    title: "Lack of psychological safety and trust in expressing concerns",
    item: "The feedback indicates that some employees feel hesitant to bring up problems or provide candid feedback due to fear of negative consequences or avoidance by leadership. This suggests a need for creating a culture of psychological safety where employees feel comfortable and supported in identifying problems and expressing genuine concerns without fear of retribution or dismissal.",
  },
  {
    id: 2,
    title: "Inconsistent communication and feedback channels",
    item: "The feedback also highlights inconsistencies in how feedback is received and addressed by different individuals within the organization. Some employees feel that sharing with colleagues is generally safe, but sharing with higher-level leaders is met with avoidance or anger. This indicates a need for establishing clear and consistent communication and feedback channels throughout the organization, ensuring that all employees, regardless of their position, feel heard and valued when expressing their opinions or concerns.",
  },
];

const Context = (props) => {
  const { selectionHistory } = props;

  // Find the most recent selected item in the selectionHistory array which is of the type "action"
  const lastSelected = selectionHistory?.find((item) => item.type === "action");
  // Remove the period at the end of lastSelected.title
  const title = lastSelected?.title.replace(/\.$/, "");
  const context = lastSelected?.context;

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>{title} in action</ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        A sample of a scenario based learning content, based on your own data.
      </SubtextExtended>
      <Content delay={1}>
        <Description>
          <SubsubtextExtended>Scenario 1</SubsubtextExtended>
          {context}
        </Description>
      </Content>
    </ScreenContainer>
  );
};

export default Context;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubsubtextExtended = styled(Subsubtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Description = styled.div`
  line-height: 1.6;
  background-color: #d4f5ed;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 600px;
`;
