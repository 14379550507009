import React from "react";
import styled from "styled-components";
import { Dropdown } from "semantic-ui-react";

const MultiselectDropdown = (props) => {
  const { title, options, editEnabled, data, id, onSelect, label, inTable } =
    props;
  return (
    <Container>
      {(editEnabled && (
        <>
          <Title>{title}</Title>

          {options && (
            <Dropdown
              placeholder="Select your options"
              fluid
              selection
              multiple
              disabled={!editEnabled}
              options={options}
              value={data}
              onChange={(e, t) => {
                onSelect(t.value, id);
              }}
              style={{
                backgroundColor: "white",
                borderRadius: "8px",
                border: "1px solid",
                borderColor: "#e0e0e0",
              }}
            />
          )}
        </>
      )) || (
        <NonEditContainer>
          {(data?.length > 1 && (
            <>
              {!inTable && <Label>{label}: </Label>}
              <DataInfo>{data?.join(", ")}</DataInfo>
            </>
          )) || (
            <>
              {!inTable && <Label>{label}: </Label>}
              <DataInfo>{data}</DataInfo>
            </>
          )}
        </NonEditContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
`;

const NonEditContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
`;

const DataInfo = styled.div`
  color: #2d70e2;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  margin-top: 3px;
`;

const Label = styled.div`
  color: #666d79;
  min-width: fit-content;
  text-align: left;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin-top: 3px;
  min-width: 100px;
`;

const Description = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  color: #7e7e7e;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
`;

export default MultiselectDropdown;
