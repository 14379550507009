import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import Select from "react-select";
// import { Modal, Message } from "semantic-ui-react";
import { Grid, Icon, Modal, Button, Image, Header } from "semantic-ui-react";

import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import {AllErrors, DismissableSuccess, DismissableError, ToastMessage} from "utilities/Messages"

import {
  SG_ADD_LEADER_SURVEY_PARTICIPANT,
  SG_DELETE_LEADER_SURVEY_PARTICIPANT,
} from "constants/actions";

  
import {TeamEmployeeSelection} from "./Settings"

import {
  TopBar,
  Title2,
  Title4,
  P,
  LeaderAvatar,
  LeaderName,
  Line,
  Participants,
  OtherContainer
} from "./styled_list";


import PersonImage from "assets/images/person_image.png";

  function ModalLeaderTeams(props) {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(0);
    const [selectedEmployee, setSelectedEmployee] = useState(0);
    const [teamList, setTeamList] = useState([]);
    const [nonTeamList, setNonTeamList] = useState([]);

    const [nonParticipantList, setNonParticipantList] = useState([]);
  
    const { employee, leaderParticipants } = props;

    const { get_employees, } = useSelector(
      (state) => ({
        get_employees: state.employees.employees,
  
      }),
      shallowEqual
    );

    
    useEffect(() => {
      if (props?.team) {
        setTeamList(get_employees.filter((fl) => fl.team === props.team));
          // not sure why this is not working
          // get_employees.filter(function (o1) {
          //   // filter out (!) items in result2
          //   return !leaderParticipants.some(function (o2) {
          //     return o1.id === o2.employee;
          //   });
          // })
        
        const _TeamList =[]
        get_employees.filter((fl) => fl.team === props.team).map(
          em => _TeamList.push(em.id)
        );

        const _ParticipantList =[]
        if (leaderParticipants.length > 0) {
               leaderParticipants.map(p =>
          _ParticipantList.push(p.employee)
          )
        }
   

          // list of team members who are not part 
          // of the team the leader is in for display
          const _ParticipantNonTeamList =[]
          
          get_employees.filter((o1) => _ParticipantList.includes(o1.id))
          .filter((o1) => !_TeamList.includes(o1.id)).map(
            p => _ParticipantNonTeamList.push(p)
          )
          setNonTeamList(_ParticipantNonTeamList);

          // list of employees who are not selected
          // for drop down list
          // console.log(_ParticipantList)
          const _NonParticipantList =[]
          get_employees.filter((o1) => !_ParticipantList.includes(o1.id))
          .filter((o1) => !_TeamList.includes(o1.id)).map(
            p => _NonParticipantList.push(p)
          )
          
          setNonParticipantList(_NonParticipantList);
      } else {
        setTeamList([])
        setNonTeamList([])
        setNonParticipantList([])
      }
     
    }, [get_employees, props, leaderParticipants]);
    
    // console.log(leaderParticipants)
    // console.log(get_employees)
    // console.log(nonParticipantList)

  
    const [addLeader, setAddLeader] = React.useState(false);
    const [removeLeader, setRemoveLeader] = React.useState(false);
  
    function AddRemoveEmployee(id, employeeId) {
      if (id) {
        dispatch({
          type: SG_DELETE_LEADER_SURVEY_PARTICIPANT,
          payload: {
            id: id,
          },
        });
        setRemoveLeader(true);
        setAddLeader(false);
      } else {
        dispatch({
          type: SG_ADD_LEADER_SURVEY_PARTICIPANT,
          payload: {
            leaders_in_global_schedule: props.selectedLeader.id,
            employee: employeeId,
            organization: props.organizationId,
          },
        });
        setAddLeader(true);
        setRemoveLeader(false);
      }
    }

    function SelectedEmployee(e) {
      setSelectedEmployee(e)
    }
  
    return (
      <Modal
        closeIcon
        centered={false}
        trigger={props.trigger}
        size="small"
        style={{top:100}}
      >
        <TopBar>
          <Title4>Leadership 360 Panel Participants</Title4>
        </TopBar>
  
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            padding: 25,
            flexDirection: "column",
            position: "relative",
            minHeight: 400,
          }}
        >
          {/* <Icon
            name="chevron left"
            size={"huge"}
            style={{
              color: "#6a00ff",
              position: "absolute",
              left: 10,
              top: 90,
              cursor: "pointer",
            }}
            onClick={() =>
              setSelected(selected !== leader_data.length - 1 ? selected + 1 : 0)
            }
          />
          <Icon
            name="chevron right"
            size={"huge"}
            style={{
              color: "#6a00ff",
              position: "absolute",
              right: 10,
              top: 90,
              cursor: "pointer",
            }}
            onClick={() =>
              setSelected(selected != 0 ? selected - 1 : leader_data.length - 1)
            }
          /> */}
  
          <LeaderAvatar src={employee.picture? employee.picture : PersonImage} />
          <LeaderName>{employee.first_name}{" "} {employee.last_name}</LeaderName>
          <LeaderName>{employee.email}</LeaderName>
  
          <Line />
          <div style={{ width: "100%", position: "relative", padding: 10 }}>
            <Title2>360 survey participants</Title2>
            {addLeader ? (
          <DismissableSuccess
            header={"Success"}
            color={"green"}
            visible={addLeader}
            message={"Successfuly added Employee to survey"}
          />
        ) : (
          ""
        )}
        {removeLeader ? (
          <DismissableSuccess
            header={"Success"}
            visible={removeLeader}
            color={"red"}
            message={"Successfuly removed Employee from survey"}
          />
        ) : (
          ""
        )}
            <Participants>
              {teamList.map((p, k) => {
                return (
                  <TeamEmployeeSelection
                    emp={p}
                    addLeader={addLeader}
                    removeLeader={removeLeader}
                    AddRemoveEmployee={AddRemoveEmployee}
                    selectedLeader={props.selectedLeader}
                    participants={leaderParticipants.filter(
                      lp => lp.employee === p.id)[0]}
                    schedule_id={props?.schedule_id}
                    organizationId={props?.organizationId}
                    key={k}
                  />
                );
              })}
            </Participants>
            <P style={{marginTop:20,marginBottom:5}}>Other Team Participants</P>

            <OtherContainer>
              <Select
              name="survey_preference"
              options={nonParticipantList}
              getOptionLabel={(option) => 
                `${option.first_name? option.first_name: ''} 
                ${option.last_name? option.last_name: ''} 
                ${option.email}`}
              getOptionValue={(option) => `${option.id}`}
              defaultValue={selectedEmployee}
              onChange={(e) => SelectedEmployee(e)}
            />
             <Button 
             onClick={() =>{
               AddRemoveEmployee(null, selectedEmployee.id)
               SelectedEmployee(null)
              }}
              style={{marginTop:10,width:100}}
            >Add</Button> 
            </OtherContainer>
            <Participants>
              {nonTeamList.map((p, k) => {
                return (
                  <TeamEmployeeSelection
                    emp={p}
                    addLeader={addLeader}
                    removeLeader={removeLeader}
                    AddRemoveEmployee={AddRemoveEmployee}
                    selectedLeader={props.selectedLeader}
                    participants={leaderParticipants.filter(
                      lp => lp.employee === p.id)[0]}
                    schedule_id={props?.schedule_id}
                    organizationId={props?.organizationId}
                    key={k}
                  />
                );
              })}
            </Participants>
          </div>
        </div>
 
      </Modal>
    );
  }
  

 export default ModalLeaderTeams