import React from 'react';
import styled, { keyframes } from "styled-components";
import { Table } from 'semantic-ui-react';
import TextComponent from './TextComponent';
import QuoteModal from './QuoteModal';
import FactorModal from './FactorModal';

// Utility to parse Markdown
const parseMarkdown = (markdown,setSelectedQuote,setSelectedFactor) => {
  const lines = markdown.split('\n');
  const elements = [];
  let currentTable = [];
  let currentList = null;

  lines.forEach((line, index) => {
    if (line.startsWith('# ')) {
      elements.push(<H1 key={index}>{line.slice(2)}</H1>);
    } else if (line.startsWith('## ')) {
      elements.push(<H2 key={index}>{line.slice(3)}</H2>);
    } else if (line.startsWith('### ')) {
      elements.push(<TextComponent text={line.slice(4)} setSelectedFactor={setSelectedFactor}/>);
    } else if (line.startsWith('##### ')) {
      elements.push(<h3 key={index}>{line.slice(6)}</h3>);
    } else if (line.startsWith('- ')) {
      if (!currentList) {
        currentList = [];
        elements.push(<ul key={`ul-${index}`}>{currentList}</ul>);
      }
      currentList.push(<LI key={index}>{parseInlineMarkdown(line.slice(2))}</LI>);
    } else if (line.startsWith('|')) {
      currentTable.push(line);
      if (index === lines.length - 1 || !lines[index + 1].startsWith('|')) {
        elements.push(parseTable(currentTable.join('\n'), index,setSelectedQuote));
        currentTable = [];
      }
    } else {
      if (currentList) {
        currentList = null; // End the current list
      }
      elements.push(<p key={index}>{parseInlineMarkdown(line)}</p>);
    }
  });

  return elements;
}

// Utility to parse inline Markdown (bold)
const parseInlineMarkdown = (text) => {
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = [];
    let lastIndex = 0;
  
    text.replace(boldRegex, (match, p1, offset) => {
      if (offset > lastIndex) {
        parts.push(text.slice(lastIndex, offset));
      }
      parts.push(<strong key={offset}>{p1}</strong>);
      lastIndex = offset + match.length;
    });
  
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }
  
    return parts;
};

// Utility to parse tables
const parseTable = (markdown, key,setSelectedQuote) => {
    const lines = markdown.split('\n').filter(line => line.startsWith('|'));
    const headers = lines[0].split('|').filter(header => header.trim());
    const rows = lines.slice(1).filter(line => !/^[-\s|]+$/.test(line)).map(line => line.split('|').filter(cell => cell.trim()));
  
    return (
      <Table key={key} celled>
        <Table.Header>
          <Table.Row>
            {headers.map((header, index) => (
              <Table.HeaderCell key={index}>{header.trim()}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map((row, rowIndex) => (
            <Table.Row key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <Table.Cell key={cellIndex}>{cell.trim()} {cellIndex == 1 && <Ref onClick={()=>setSelectedQuote({row:rowIndex,effect:row[0].trim()})}>[{rowIndex+1}]</Ref>}</Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
};

// Parse TLDR markdown
const parseTldr = (tldr) => {
    if (!tldr) return null;
    const tldrElements = parseMarkdown(tldr);
    return (
        <>
            <H2>TLDR</H2>
            <div style={{marginBottom:40}}>{tldrElements}</div>
        </>
    );
};

const MarkdownRenderer = ({ markdown, tldr,quotes }) => {
    const [selectedQuote, setSelectedQuote] = React.useState(null);
    const [selectedFactor, setSelectedFactor] = React.useState(null);
    const elements = parseMarkdown(markdown,setSelectedQuote,setSelectedFactor);
    const tldrElements = parseTldr(tldr);
    const finalElements = [];
    


    elements.forEach((element, index) => {
        finalElements.push(element);
        if (element?.type?.target === 'h1' && tldrElements) {
            finalElements.push(<Area>Report produced for the Finance Department</Area>)
            finalElements.push(<div key={`tldr-${index}`}>{tldrElements}</div>);
        }
    });

    return (<div>
            {finalElements} 
            <QuoteModal quoteData={quotes} quote={selectedQuote} close={()=>setSelectedQuote(null)}/>
            <FactorModal factor={selectedFactor} close={()=>setSelectedFactor(null)}/>

            </div>)
};
  
export default MarkdownRenderer;



  const H1 = styled.h1`
    font-size:20px;
    font-weight:bold;
    margin-bottom:5px;
  `
  const Area = styled.div`
    font-size:14px;
    font-weight:400;
    margin-bottom:40px;
    `

  const P = styled.div`
    margin-bottom:10px;
    color:#2A3039;
  `

  const H2 = styled.div`
  color:#2A3039;
  font-weight:600;
  margin-bottom:10px;
  font-size:16px;
  `

  const LI = styled.li`
  margin-bottom:10px;
  `

  const Ref = styled.span`
    font-weight:500;
    color:#2D70E2;
    cursor:pointer;
  `