import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Pagination, Input, Message } from "semantic-ui-react";

// import { useNavigate } from "react-router-dom";

import AddTeam from "./Teams/addTeam";

import TeamTable from "./Teams/TeamTable";
import TeamSettings from "./Teams/TeamSettings/index";

import "../setting.css";

import {
  RESET_CREATED_TEAM,
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

const HomeTeamTable = (props) => {
  const {userAdmin, userEmployee } = props
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [members, setMembers] = useState([]);
  const [teams, setTeams] = useState([]);

  const [teamLeader, setTeamLeader] = useState(false);
  const [administrator, setAdministrator] = useState(false);

  const [pool, setPool] = useState([]);
  // const [filterTeamList, setFilterTeamList] = useState(false);

  const [teamUpdate, setTeamUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  // const [departments, setDepartments] = useState([]);
  // const [tab, setTab] = useState(0);
  const [selectTeam, setSelectTeam] = useState(false);

  // const [organization, setOrganization] = useState({});

  const [openTeamCard, setOpenTeamCard] = useState(false);
  const [fullEmpData, setFullEmpData] = useState({});

  // const [selectDepartment, setSelectDepartment] = useState(false);
  const createdTeam = useSelector((state) => state.teams.created, shallowEqual);

  // const [employee, setEmployee] = React.useState(null);
  const [loading, setLoading] = useState(true);
  // console.log(userAdmin)

  // // // // //
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );

  const {
    // employees_data,
    get_organizations,
    get_employees,
    get_auth,
    get_teams,
    // get_departments,
    get_selectedOrg,
    // get_error,
    // get_survey_structure,
    // get_employee_categories,
  } = useSelector(
    (state) => ({
      get_error: state.errors,
      // employees_data: state.employees.employees,
      get_auth: state.auth,
      get_employees: state.employees,
      get_organizations: state.organizations,
      get_teams: state.teams,
      // get_departments: state.departments,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      // get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (Number(AmbassadorId) > 0) {
  //     setOrganization(get_organizations[get_selectedOrg?.organization?.id]);
  //   } else {
  //     setOrganization(get_organizations[get_auth?.organization_id]);
  //   }
  // }, [get_organizations, get_selectedOrg, AmbassadorId, get_auth]);


  useEffect(() => {
    let selectedTeam = selectTeam ? selectTeam : false;
   
    if (Number(AmbassadorId) > 0) {
      // set role and account
      setTeamLeader(true);
      setAdministrator(true);
      setSelectTeam(selectedTeam);
      // console.log("I am here")
      return
    }
    if (Number(userEmployee?.id) > 0) {
      // admin
      if (userEmployee?.account_type < 7) {
        setTeamLeader(true);
        setAdministrator(true);
        // setSelectTeam(selectedTeam);
        return
      }
      // team leader
      if (userEmployee?.role < 7) {
        setTeamLeader(true);
        // console.log("I was here set as team leader")
        if (userEmployee?.team) {
          setSelectTeam(userEmployee?.team);

        }
        return
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);

    // team leader sets the path for what else would
    // a person see
   
 

  }, [

    userEmployee,
    // tab,
    AmbassadorId,
    selectTeam,
  ]);

  // console.log('loading', loading)

  useEffect(() => {
    setMembers(get_employees?.employees)
  }, [get_employees]);

  useEffect(() => {
    setTeams(get_teams?.teams?.sort((a, b) => (a.id > b.id ? 1 : -1)));
    setPool(get_teams?.teams);
  }, [get_teams]);

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };

  useEffect(() => {
    if (get_employees?.updated) {
      setTeamUpdate(get_employees?.update?.id);
      setLoading(true);
    }
  }, [get_employees]);


  useEffect(() => {
    if (createdTeam?.id) {
      setSelectTeam(createdTeam.id);
      dispatch({
        type: RESET_CREATED_TEAM,
      });
    }
  }, [createdTeam, dispatch]);

  function BackFromTeam() {
    setSelectTeam(false)
    // setTab(1)
  }

  // console.log(get_teams?.teams)
  // console.log(members)
  // console.log(get_employees?.employees)

  const mountedRef = useRef(true)
  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])

  // !administrator &&
  if (teamLeader && selectTeam) {
    let selected = teams.filter((t) => t.id === selectTeam)[0];
    if (!mountedRef.current) return null
    return (
      <TeamSettings
        id={selectTeam}
        teamLeader={teamLeader}
        team={selected}
        members={members.filter((t) => t.team === selectTeam)}
        // selectDepartment={(i) => setSelectDepartment(i)}
        BackFromTeam={BackFromTeam}
        teamUpdate={teamUpdate}
        setTeamUpdate={setTeamUpdate}
        administrator={administrator}
        userEmployee={userEmployee}
        setLoading={setLoading}
        // setOpenFullCard={setOpenFullCard}
        openTeamCard={openTeamCard}
        setOpenTeamCard={setOpenTeamCard}
        setFullEmpData={setFullEmpData}
      />
    );
  }


  return (
    <>
      <TopButtons>
        {administrator &&
          <AddTeam
            teamUpdate={teamUpdate}
            setTeamUpdate={setTeamUpdate}
            trigger={<StyledButton>+ Create team</StyledButton>}
          />
        }
      </TopButtons>

      <SearchContainer>
        <Input
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          size="small"
          style={{ width: "70%" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <Pagination
          activePage={page}
          onPageChange={handlePaginationChange}
          boundaryRange={1}
          size="mini"
          firstItem={null}
          lastItem={null}
          siblingRange={0}
          totalPages={Math.ceil(pool?.length / 10)}
        />
      </SearchContainer>
    
      <TeamTable
        data={pool?.slice((page - 1) * 10, page * 10)}
        members={get_employees?.employees}
        search={search}
        setSelectTeam={setSelectTeam}
        back={BackFromTeam}
        teamUpdate={teamUpdate}
        setTeamUpdate={setTeamUpdate}
        teamLeader={teamLeader}
        administrator={administrator}
        userEmployee={userEmployee}
        openTeamCard={openTeamCard}
        // setOpenFullCard={setOpenFullCard}
        setOpenTeamCard={setOpenTeamCard}
        setFullEmpData={setFullEmpData}
      />

    </>
  );
};

export default HomeTeamTable;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
`;

const NavContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #d3dbdb;
`;

const NavTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const P = styled.p`
  font-size: 15px;
  line-height: 1.4;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 20px;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Tab = styled.div`
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 6px 16px 6px 16px;
  color: ${(props) => (props.selected ? "white" : "#7f7f7f")};
  border: ${(props) =>
    props.selected ? "1px solid #2d50e2" : "1px solid #7f7f7f"};
  background-color: ${(props) => (props.selected ? " #2d50e2" : "white")};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.selected ? "#2d50e2" : "#7f7f7f")};
    color: white;
  }
`;

const Container = styled.div`
  min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TopButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
