import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/resilience2.svg";
import Impact from "assets/images/Impact.svg";



const data = {
          
            series: [70],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '45%',
                  },
                   track:{
                	background:'#c3a7fd'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#6a00ff']
              }
            },
          
          
          };

const CultureDials = (props) =>{

	return (
			<Card>
				<Top>
					<Line>
						<Title>{props.title}</Title>
					</Line>
				</Top>
				<Chart options={data.options} series={data.series} type="radialBar" height={225} />
				<Values>
					<Change>+10%</Change>
					<Stat>86%</Stat>
				</Values>
			</Card>
		)
}


export default CultureDials



const Card = styled.div`
	padding:10px;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	position:relative;
	width:calc(50% - 10px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 25px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:20px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const Change = styled.div`
	font-size:14px;
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:0px;
`

const Top = styled.div`
	width:100%;
	margin-bottom:-10px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
	font-weight:500;
	color:#4a4a4a;
`

const Icon = styled.img`
	height:40px;
`

const View = styled.div`
	font-family: "Red Hat Display", sans-serif;
	cursor:pointer;
`

const Line = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
`

const Arrow = styled.span`
	width: 7px;
  height: 11px;
  margin-left:10px;
`