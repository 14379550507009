import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Activity from './Activity'
import UserPlans from './UserPlans'
import data from '../data'
import { useLocation, useNavigate } from 'react-router-dom';
import Back from "ProtoType/SimpleDash/Back";

import Chart from "react-apexcharts";

const UserProfile = () =>{
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState(0)

    const urlParts = location.pathname.split('/');
    const requiredPart = urlParts[urlParts.length - 1];

    const [user,setUser] = useState(data.find(user=>user.id.name == requiredPart))


    const getContent = () =>{
        if(selectedTab == 0){
            return <Activity/>
        }
        if(selectedTab == 1){
            return <UserPlans/>
        }

        return (<>
        <ChartTitle>Engagement Milestone Score</ChartTitle>
        <Description>
            This chart shows the engagement milestone score for the last 3 weeks. The milestone is calculated based on the 100th percentile of leadership engagement.
        </Description>
        <ChartContainer>
        <Chart
                    options={data3.options}
                    series={data3.series}
                    type="line"
                    height={'100%'}
                />
        </ChartContainer>
        </>)

    }
 
    return (
        <Container>
            <Center>
                <Back url={-1} location={'permission home'}/>
                <Profile>
                    <UserInfo>
                        <Title>{user.name.first} {user.name.last}</Title>
                        <Categories>
                            {user.filters.map((filter,i)=>{
                                return <Category key={i}>
                                    {filter}
                                </Category>
                            }
                            )}
                        </Categories>
                    </UserInfo>
                   
                </Profile>
 
                <Tabs>
                    <Tab onClick={()=>setSelectedTab(0)} selected={selectedTab === 0}>
                        Activity
                    </Tab>
                    <Tab onClick={()=>setSelectedTab(2)} selected={selectedTab === 2}>
                        Permissions
                    </Tab>
                    <Tab onClick={()=>setSelectedTab(1)} selected={selectedTab === 1}>
                        Data access
                    </Tab>
                </Tabs>

                <Content>
                    {getContent()}
                </Content>
            </Center>
        </Container> 
    )

}

export default UserProfile



const Container = styled.div`
    padding-left:58px;
    margin-top:40px;
    font-family:'Raleway';
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
`
const Content = styled.div`
    margin-top:20px;
`

const Center = styled.div`
    width:100%;
    max-width:800px;
    display:flex;
    flex-direction:column;
`

const Profile = styled.div`
    display:flex;
`

const Img = styled.img`
    width:150px;
    height:150px;
    border-radius:10px;
    margin-right:20px;
`
const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    font-family:'Raleway';
`

const Categories = styled.div`
    display:flex;
    margin-top:20px;
`

const Category = styled.div`
font-size:14px;
color:#666D79;
background-color:#DFDFDF;
padding:0px 10px;
margin-right:10px;
border-radius:5px;
`

const UserInfo = styled.div`
    display:flex;
    flex-direction:column;
`

const Tabs = styled.div`
    display:flex;
    margin-top:50px;
`
const Tab = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-right:20px;
    cursor:pointer;
    color:${props=>props.selected ? '#666D79' : '#DFDFDF'};
    border-bottom:${props=>props.selected ? '2px solid #666D79' : 'none'};
    padding-bottom:${props=>props.selected ? '10px' : '0px'};
`

const ChartContainer = styled.div`
    height:300px;
`

const ChartTitle = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-top:20px;
`
const Description = styled.div`
    margin-top:10px;
    margin-bottom:30px;
`


const data3 = {
	series: [
		{
			data: [72,66,78],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800,
			height: '100%',
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        stroke: {
            curve: 'smooth'
          },
		colors: ["#27CDA7", "#27CDA7", "#27CDA7",'#F15062','#F15062','#F15062','#F15062'],
		xaxis: {
			categories: [
				"Week 1",
                "Week 2",
                "Week 3"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: "#2D70E2",
				}
			}
		},
		yaxis: {
			min: 60,
			max: 80,
			decimalsInFloat: 0,
			title: {
				text: "Engagement Milestone Score",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
