import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";


const CreateStory = () => {
    const [step,setStep] = useState(1);

    return (
        <Container>
            <Content>
                <Title>What is a data story?</Title>
                <Description>
                    A data story is a narrative that uses data to communicate insights.
                    Innerlogic uses artifical intelligence to generate stories about the key themes driving your culture.
                    These stories are based on the feedback you've collected and from quantitative data.
                </Description>

                <Title>Steps to building a data story</Title>

                <Description>
                    Building a data story is simple. You must first select a target group. This could be a department, a team, or a specific role within your organization.
                    Once you've selected a target group, the process takes about 5 minutes to generate your data story. You'll receive an email notifying you when your story is ready.
                </Description>

                <ButtonArea>
                    <Button onClick={()=>setStep(step+1)}>
                        Build a Data Story
                    </Button>
                </ButtonArea>
                
            </Content>
            
        </Container>
    )

}

export default CreateStory;


const Container = styled.div`
    padding:20px;
    font-family:"Raleway";
    width:100%;
    display:flex;
    justify-content:center;
`

const Content = styled.div`
    max-width:700px;
`

const Title = styled.div`
    font-size:20px;
    text-transform:uppercase;
    color:#2D70E2;
    margin-bottom:10px;
    font-weight:bold;
`

const Description = styled.div`
    line-height:1.6;
    margin-bottom:30px;
`

const ButtonArea = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`

const Button = styled.div`
    padding:10px 20px;
    background-color:#2d70e2;
    color:white;
    cursor:pointer;
    transition:0.3s;
    border-radius:5px;
    display:inline-block;
    font-size:14px;

    &:hover {
        background-color:#1c4db8;
    }
`