import React from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { calculate_table_data } from "../../../../reports/Audit/data_navigation/calculate_data";
import Segmentation from "./Segmentation";
import Footer from "../../components/Footer";
import Side from "../../components/Side";

const Overview = (props) => {
  const {
    nav,
    categories,
    rule,
    structure,
    reportData,
    anchor,
    config,
    isSportLaw,
  } = props;
  const generate_table_data = () => {
    const anchors = categories.categories.filter(
      (f) =>
        f.priority !== "primary" &&
        f.id !== anchor &&
        f.priority !== "personality"
    );

    return anchors.map((item) => {
      let _data = calculate_table_data(
        reportData,
        nav,
        categories,
        item.id,
        rule,
        structure
      );

      return {
        name: item.name,
        id: item.id,
        data: _data.overall,
      };
    });
  };

  return (
    <Page break style={styles.page}>
      <Text style={styles.T2}>Demographic Analysis</Text>
      <View style={styles.line} />
      {/* <Text style={styles.description}>
        Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck
        tri-tip tail spare ribs brisket pork loin bresaola. Beef chislic doner
        swine, pork chop porchetta picanha spare ribs. Chicken jerky short ribs,
        pastrami pork fatback ground round spare ribs cow t-bone filet mignon
        hamburger leberkas shank. Meatball burgdoggen pork ribeye.
      </Text> */}
      <View style={styles.container}>
        <Segmentation
          categories={categories}
          structure={structure}
          calculated={[...generate_table_data()]}
          config={config}
          rule={rule}
          isSportLaw={isSportLaw}
        />
      </View>
      <Footer {...config} isSportLaw={isSportLaw} />
      <Side {...config} isSportLaw={isSportLaw} />
    </Page>
  );
};

export default Overview;

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 15,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2C6CF5",
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  container: {
    paddingTop: 20,
  },
});
