import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";



const Introduction = () => {

    return (
        <>
              <Title>
                        How to get the most from your data 
                    </Title>
    
                    <Description>
                        We've analyzed your culture data and have created a walkthrough of the key elements of your culture.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam tincidunt,
                        enim lectus luctus elit, sit amet ullamcorper elit risus id nisl. Donec eget nunc eget libero
                        ultricies aliquet. Nullam id eros quis purus ultricies aliquam. Sed euismod, diam quis aliquam.
                    </Description>
        </>
    )

}

export default Introduction



const Title = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
`

const Description = styled.div`
    line-height:1.6;
`