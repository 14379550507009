import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Logo from "assets/images/pdf/Logo.png";
import CoverImg from "assets/images/pdf/rec.png";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import ReportPage from "./reportPage";
import ActionPlanPage from "./ActionPlanPage";
import {
  QuestionButtonIcon,
  CommunicationIcon,
  EducationExamplesIcon,
  SchedulingIcon,
  QuestionsIcon,
  StructuredSectionsIcon,
  FollowUpIcon,
  IdeaIcon,
  TeamsIcon,
  ContextSessionsIcon,
  KeyChangesIcon,
  ComprehensiveIcon,
  OpenDiscussionIcon,
  DateIcon,
  ActionIdeaIcon,
} from "./icons";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#2A3039",
    width: "100%",
    padding: 40,
    position: "relative",
    fontFamily: "Raleway",
  },
  logo: {
    width: 80,
  },
  rec1: {
    width: 350,
    position: "absolute",
    left: -60,
    top: 50,
  },
  rec2: {
    width: 350,
    position: "absolute",
    left: 80,
    top: -50,
  },
  rec3: {
    width: 350,
    position: "absolute",
    left: 215,
    top: 275,
  },
  rec4: {
    width: 350,
    position: "absolute",
    left: 350,
    top: -100,
  },
  bottom: {
    position: "absolute",
    bottom: 100,
    width: "100%",
    color: "white",
    fontSize: 45,
    lineHeight: "120%",
    fontWeight: "bold",
    padding: 40,
    textTransform: "uppercase",
  },
  green: {
    color: "#27CDA7",
    fontWeight: "bold",
  },
  team: {
    width: "100%",
    marginTop: 40,
    textTransform: "uppercase",
    fontSize: 20,
    flexDirection: "row",
    fontWeight: 400,
  },
  // position dateSection to top right
  dateSection: {
    position: "absolute",
    top: 60,
    right: -18,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 100,
  },
  dateString: {
    color: "white",
    fontSize: 8,
    marginLeft: 2,
    fontFamily: "Raleway",
    fontWeight: 600,
  },
});

const iconsArray = [
  <QuestionButtonIcon />,
  <CommunicationIcon />,
  <EducationExamplesIcon />,
  <SchedulingIcon />,
  <QuestionsIcon />,
  <StructuredSectionsIcon />,
  <FollowUpIcon />,
];

const actionPlanStepsOne = {
  planNumber: 1,
  planTitle: "Set Transparent Expectations",
  steps: [
    {
      stepTitle: "Step 1: Open-Door Policy",
      icon: <QuestionButtonIcon />,
      stepPoints: [
        "Hold a leadership roundtable discussion where managers openly share their past professional setbacks and the lessons they learned.",
        "Encourage leaders to emphasize that growth comes from learning and embracing challenges.",
      ],
    },
    {
      stepTitle: "Step 2: Communication Strategy",
      icon: <CommunicationIcon />,
      stepPoints: [
        "Develop a brief presentation highlighting well-known companies (e.g., Google, Pixar) that emphasize psychological safety and have achieved innovative success.",
        "Share articles or case studies showcasing how open communication fosters team creativity and problem-solving.",
      ],
    },
    {
      stepTitle: "Step 3: Educational Examples",
      icon: <EducationExamplesIcon />,
      stepPoints: [
        `Highlight how Google's "Project Aristotle" found that psychological safety was crucial for effective teams and better performance.`,
        "Discuss how Pixar's creative process encourages candid feedback to improve their films.",
      ],
    },
  ],
};

const actionPlanStepsTwo = {
  planNumber: 2,
  planTitle: "Prioritize Active Listening",
  steps: [
    {
      stepTitle: "Step 1. Scheduling",
      icon: <SchedulingIcon />,
      stepPoints: [
        "Set recurring, bi-weekly or monthly one-on-one sessions with each team member during dedicated time slots to ensure consistency.",
      ],
    },
    {
      stepTitle: "Step 2. Question Bank",
      icon: <QuestionsIcon />,
      stepPoints: [
        "Prepare a list of open-ended questions that encourage participants to share their concerns, ideas, and suggestions openly.",
        `E.g., "What challenges have you encountered lately that you'd like to discuss?" "Is there an aspect of your role where you'd like more support?" "Can you share an idea you've been thinking about to improve our processes?`,
      ],
    },
    {
      stepTitle: "Step 3. Structured Sessions",
      icon: <StructuredSectionsIcon />,
      stepPoints: [
        `Highlight how Google's "Project Aristotle" found that psychological safety was crucial for effective teams and better performance.`,
        "Discuss how Pixar's creative process encourages candid feedback to improve their films.",
      ],
    },
    {
      stepTitle: "Step 4. Follow-Up Actions",
      icon: <FollowUpIcon />,
      stepPoints: [
        `Share a brief summary of key takeaways from the session with the participant, acknowledging their input.`,
        "If applicable, communicate the steps you'll take based on their suggestions or concerns.",
      ],
    },
  ],
};

const pageOneData = {
  pageTitle: "GROWTH AREA 1",
  barTitle: "Psychological Safety",
  barPercentage: 70,
  teamName: "QA team",
  orgName: "DIRECTV",
};

const textBlockOne = {
  title: "What is psychological safety?",
  description:
    "Refers to an environment where team members feel safe to voice concerns, share diverse perspectives, and ask questions without fear of humiliation or negative criticism. Psychological safety assures that when people identify problems, offer honest feedback, and make mistakes, they are met with understanding and confidence from other members of the team.",
  bulletTitle: "Items related to psychological safety in the survey:",
  bullets: [
    "It’s safe to identify problems and express genuine concerns (score: 62%)",
    "Candid feedback, opinions, and ideas are welcomed, even if they differ from others (score: 58%)",
    "There are no negative consequences for speaking up or asking questions (score: 64%)",
  ],
  bgColor: "#F8FAFF",
  icon: <IdeaIcon />,
};

const textBlockTwo = {
  title: "What is the QA team saying about resilience?",
  bgColor: "rgba(234, 251, 247, 0.50)",
  numberedItems: [
    "Lack of open communication: There are a few instances where feedback or ideas are not well received, and employees feel discouraged from bringing new suggestions to the table. This indicates a need for a more open and inclusive communication culture within the organization.",
    "Resistance to feedback: Some employees mentioned that feedback given to their supervisors is met with resistance and not followed up with noticeable improvements. This suggests a lack of willingness to address concerns and make necessary changes.",
    "Micromanagement and lack of trust: There is a mention of micromanagement and a need for leaders to trust their employees' talents and abilities more. Micromanagement can stifle creativity and discourage employees from sharing their ideas.",
  ],
  icon: <TeamsIcon />,
};

const pageTwoData = {
  pageTitle: "GROWTH AREA 2",
  barTitle: "Resilience",
  barPercentage: 78,
  teamName: "QA team",
  orgName: "DIRECTV",
};

const pageTwoTextBlockOne = {
  title: "What is resilience?",
  description:
    "Refers to the ability of an organization and/or team to thrive amid change and disruption. It encompasses a culture that fosters perseverance during challenges, allocates resources for rebounding from unexpected hurdles, and provides support systems to manage setbacks and move forward.",
  bulletTitle: "Items related to resilience in the survey:",
  bullets: [
    "It's encouraged to persevere in times of change or disruption (score: 72%)",
    "There are resources to help us rebound from diverse or unexpected challenges (score: 70%)",
    "When setbacks or stressful situations occur, there is support to cope and continue (score: 77%)",
  ],
  bgColor: "#F8FAFF",
  icon: <IdeaIcon />,
};

const pageTwoTextBlockTwo = {
  title: "What is the QA team saying about resilience?",
  bgColor: "rgba(234, 251, 247, 0.50)",
  numberedItems: [
    "Challenges in navigating change and lack of guidance or processes: The feedback suggests that there are instances where guidance and processes could be improved to help employees navigate through challenges and change more effectively. This highlights the importance of providing clear guidance and establishing consistent processes to support employees during periods of change. Taking action to address this trend would involve providing training, support, and clear communication to equip employees with the necessary skills and knowledge to navigate change successfully.",
    "Lack of resources and unrealistic expectations: The feedback expresses concerns about a lack of resources and unrealistic expectations placed on employees. This implies that employees may be overwhelmed or unsupported in their roles, which can negatively impact their morale and productivity. Addressing this trend would require management to assess resource allocation, set realistic expectations, and consider implementing changes to ensure that employees have the necessary support and resources to meet expectations without feeling overwhelmed.",
  ],
  icon: <TeamsIcon />,
};

const resiliencePlanStepsOne = {
  planNumber: 1,
  planTitle: "Provide Clear Context for Changes",
  steps: [
    {
      stepTitle: "Step 1. Announce Context Sessions",
      icon: <ContextSessionsIcon />,
      stepPoints: [
        "Introduce the monthly context sessions through official communication channels.",
        "Emphasize the importance of understanding the reasons behind changes for better alignment.",
      ],
    },
    {
      stepTitle: "Step 2. Select Key Changes for Context",
      icon: <KeyChangesIcon />,
      stepPoints: [
        "Identify significant recent changes that require clearer explanations.",
        "Prioritize changes that have caused confusion or concern among team members.",
      ],
    },
    {
      stepTitle: "Step 3. Prepare Comprehensive Explanations",
      icon: <ComprehensiveIcon />,
      stepPoints: [
        "Research and gather detailed information about the chosen changes.",
        "Develop clear, concise explanations that link the changes to Company X's overarching goals.",
      ],
    },
    {
      stepTitle: "Step 4. Engage with Open Discussion",
      icon: <OpenDiscussionIcon />,
      stepPoints: [
        "Encourage participants to ask questions and share their thoughts during the context sessions.",
        "Address any misconceptions and ensure that everyone gains a thorough understanding.",
      ],
    },
  ],
};

const getPlanTitle = (plan) => {
  return plan.split("\n")[0].split(":")[1].trim();
};

const createReportFromArray = (data, scores, orgName, tags) => {
  const reportPlan = data.map((item, i) => {
    const reportPageData = {
      pageTitle: `GROWTH AREA ${i + 1}`,
      barTitle: scores[i].name,
      barPercentage: Math.floor(scores[i].score * 10),
      teamName: orgName,
      tags: tags,
      orgName: orgName,
      orgAverage: Math.floor(scores[i].org_score * 10),
    };

    const bulletItems = scores[i]?.item_scores?.map((score) => {
      return `${score?.question} (score: ${Math.floor(score?.score * 10)}%)`;
    });

    const textBlockOne = {
      title: `What is ${scores[i].name}?`,
      description: item?.factor_definition,
      bulletTitle: `Items related to ${scores[i].name} in the survey:`,
      bullets: bulletItems,
      bgColor: "#F8FAFF",
      icon: <IdeaIcon />,
    };

    const textBlockTwo = {
      title: `What are people saying about ${scores[i].name}?`,
      bgColor: "#fff",
      numberedItems: item?.summary?.split(/\n\n?/).map(item => item.replace(/^\d+\./, '').trim()),
      icon: <TeamsIcon />,
    };

    const actionPlanTitle = `${orgName} action plan to improve ${scores[
      i
    ].name.toLowerCase()}`;

    const planSteps = item?.recommendations?.map((plan, i) => {
      // Accounting for an unusal format of the plan
      let format = 1;
      let planArray = plan.split("\n\nStep ");
      // if the format is different
      if (planArray.length === 1) {
        format = 2;
        planArray = plan.replace("Steps:", "").split("\n\n");
      }
      const planArray2 = planArray
        .map((step, i) => {
          const stepArray = step.split(/\n\n|\n/);
          let stepTitle;
          if (format === 1) {
            stepTitle = stepArray[0]?.replace(/\d+:/g, `Step ${i}.`);
          } else {
            let stepNumber = stepArray[0]?.match(/\d+/g);
            stepTitle = stepArray[0]?.replace(/\d+./g, `Step ${stepNumber}.`);
          }
          // Create an array of step points separated by \n\n
          const stepPoints = stepArray.slice(1).map((stepPoint, i) => {
            return stepPoint;
          });
          const icon = <ActionIdeaIcon />;

          return { stepTitle, stepPoints, icon };
        })
        .filter((plan) => plan.stepPoints?.length > 0);
      return {
        planNumber: i + 1,
        planTitle: getPlanTitle(plan),
        // randomize the icon based on iconsArray
        steps: planArray2,
      };
    });

    return (
      <>
        <ReportPage
          data={reportPageData}
          textBlockOne={textBlockOne}
          textBlockTwo={textBlockTwo}
        />
        {planSteps.map((plan, pId) => {
          return (
            <ActionPlanPage
              title={pId === 0 && actionPlanTitle}
              stepsData={plan}
            />
          );
        })}
      </>
    );
  });
  return reportPlan;
};

const createSinglePageReport = (data, scores) => {
  return (
    <>
      <ReportPage
        data={pageOneData}
        textBlockOne={textBlockOne}
        textBlockTwo={textBlockTwo}
      />
      <ActionPlanPage
        title="QA team action plan to improve psychological safety"
        stepsData={actionPlanStepsOne}
      />
      <ActionPlanPage stepsData={actionPlanStepsTwo} />
    </>
  );
};

// Create Document Component
const MyDocument = (props) => {
  const { data, orgName, template, tags, scores, date } = props;
  const report = Array.isArray(data)
    ? createReportFromArray(data, scores, orgName, tags)
    : createSinglePageReport(data);

  const title =
    `${template}` +
    `${(tags && tags?.length > 0 && ` - ${tags}`) || " "}` +
    `${date}`;
  return (
    <Document title={title}>
      <Page size="A4" style={styles.page}>
        <View>
          <Image src={Logo} style={styles.logo} />
          <Image src={CoverImg} style={styles.rec1} />
          <Image src={CoverImg} style={styles.rec2} />
          <Image src={CoverImg} style={styles.rec3} />
          <Image src={CoverImg} style={styles.rec4} />
        </View>
        <View style={styles.dateSection}>
          <DateIcon />
          <Text style={styles.dateString}>{date}</Text>
        </View>

        <View style={styles.bottom}>
          <Text style={styles.green}>Innerlogic</Text>
          <Text>Culture Report And Action Plans</Text>

          <View style={styles.team}>
            <Text style={{ marginRight: 5 }}>
              {orgName}
              {tags && ":"}
              <View>
                <Text style={[styles.green]}>{tags && ` ${tags}`}</Text>
              </View>
            </Text>
          </View>
        </View>
      </Page>
      {report}
      {/* <ActionPlanPage stepsData={actionPlanStepsTwo} /> */}
      {/* <ReportPage
        data={pageOneData}
        textBlockOne={textBlockOne}
        textBlockTwo={textBlockTwo}
      />
      <ActionPlanPage
        title="QA team action plan to improve psychological safety"
        stepsData={actionPlanStepsOne}
      />
      <ReportPage
        data={pageTwoData}
        textBlockOne={pageTwoTextBlockOne}
        textBlockTwo={pageTwoTextBlockTwo}
      />
      <ActionPlanPage
        title="QA team action plan to improve resilience"
        stepsData={resiliencePlanStepsOne}
      /> */}
    </Document>
  );
};

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

export default MyDocument;
