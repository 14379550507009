import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import Comment from './Comment'
import Summary from './Summary'
import Completion from './Completion'
import { Checkbox } from 'semantic-ui-react'


const Practice = ({ debriefResponses, questions,
	debriefSchedule, get_employees, teamId }) => {

	const [questionTab, setQuestionTab] = useState(0)
	const [practiceQues, setPracticeQues] = useState([])
	const [employeeList, setEmployeeList] = useState([])
	const [dispEmpList, setDispEmpList] = useState([])
	const [averageResp, setAverageResp] = useState(0)
	const [showMemberData, setShowMemberData] = useState(true)
	const [showLeaderData, setShowLeaderData] = useState(true)

	useEffect(() => {
		if (questions?.questions) {
			setPracticeQues(questions?.questions)
			setQuestionTab(questions?.questions[0])
		}
	}, [questions]);

	useEffect(() => {
		setShowMemberData(debriefSchedule?.include_members)
		setShowLeaderData(debriefSchedule?.include_leaders)
	}, [debriefSchedule]);

	useEffect(() => {
		const _EmpListing = []
		get_employees.filter(em => em.team === teamId).map((item) => {
			let fullName = item.first_name ? `${item.first_name} ${item.last_name}` : `${item.email}`
			let _scores = {}
			let answered = false
			if (debriefResponses.filter(db => db.employee === item.id)[0]?.response) {
				_scores = debriefResponses.filter(db => db.employee === item.id)[0]?.response
				answered = true
			}
			if (debriefSchedule?.include_leaders) {
				if (item.role < 7) {
					_EmpListing.push({
						id: item.id,
						picture: item.picture,
						email: item.email,
						name: fullName,
						leader: item.role < 7 ? true : false,
						member: item.role > 7 ? true : false,
						answered: answered,
						..._scores
					})
				}
			}

			if (debriefSchedule?.include_members) {
				if (item.role > 7) {
					_EmpListing.push({
						id: item.id,
						picture: item.picture,
						email: item.email,
						name: fullName,
						leader: item.role < 7 ? true : false,
						member: item.role > 7 ? true : false,
						answered: answered,
						..._scores				
					})
				}
			}
			return null
		})
		setEmployeeList(_EmpListing)

	}, [get_employees, teamId,debriefSchedule, debriefResponses]);
// console.log(employeeList)

	useEffect(() => {
		let AnsweredEm = 0
		let EmpScoreTotal = 0
		let _EmpDisList = []
		if (showMemberData) {
			_EmpDisList = employeeList.filter(el =>
				el.member === showMemberData
				&& el.answered === true
			).map((item) => {
				AnsweredEm += 1
				EmpScoreTotal += item?.['3']
				return item
			})
		}
			let _LeadDisList = []
			if (showLeaderData) {
				_LeadDisList = employeeList.filter(el =>
					el.leader === showLeaderData
					&& el.answered === true
				).map((item) => {
					AnsweredEm += 1
					EmpScoreTotal += item?.['3']
					return item
				})


			}
			
			setDispEmpList([..._EmpDisList, ..._LeadDisList])
			setAverageResp(AnsweredEm > 0 ? Math.round((EmpScoreTotal /AnsweredEm)) : 0)
		}, [employeeList, debriefResponses, showMemberData, showLeaderData]);

	return (
		<Container>
			<Top>
				<Title>Practice Debrief</Title>
				{practiceQues.filter(pq => pq.visible === true).map((item, i) => {
					return <Tabi
						key={i}
						selected={questionTab?.id === item.id}
						onClick={() => setQuestionTab(item)}>Q{item.id}</Tabi>
				})}
				<CheckContainer>

					<Checkbox label='Members'
						disabled={!debriefSchedule?.include_members}
						checked={!debriefSchedule?.include_members ? false : showMemberData}
						onChange={() => setShowMemberData(!showMemberData)}
						style={{ marginRight: 10 }}
					/>
					<Checkbox label='Leaders'
						disabled={!debriefSchedule?.include_leaders}
						checked={!debriefSchedule?.include_leaders ? false : showLeaderData}
						onChange={() => setShowLeaderData(!showLeaderData)}
					/>
				</CheckContainer>
			</Top>
			<Bottom>
				<CommentContainer>
					<Question>{questionTab?.question}</Question>

					{dispEmpList.filter(el => el.answered === true).map((item, i) => {
						// if (questionTab?.id in item) {
						return <Comment
							key={i}
							response={item}
							questionTab={questionTab}
							allQues={practiceQues}
						/>
						// }
						// return null
					})}
				</CommentContainer>

				<MetaContainer>

					<Summary averageResp={averageResp} />

					<Completion
						employeeList={employeeList}
						averageResp={averageResp}
					/>

				</MetaContainer>
			</Bottom>
		</Container>
	)
}


export default Practice



const Container = styled.div`
 font-family: 'Red Hat Display', sans-serif;
`

const Top = styled.div`
	height:70px;
	margin-top:10px;
	border-bottom:1px solid #ebebeb;
	width:65%;
	display:flex;
	align-items:center;
	padding:10px;
	position:relative;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-right:20px;
`

const Bottom = styled.div`
	width:100%;
	display:flex;
	justify-content:space-between;
`

const CommentContainer = styled.div`
	width:65%;

`

const MetaContainer = styled.div`
	width:30%;
	margin-top:60px;
`

const Tabi = styled.div`
	padding:8px 12px;
	background-color:${props => props.selected ? '#15056b' : '#ebebeb'};
	border-radius:15px;
	margin-right:10px;
	color:white;
	font-weight:bold;
	cursor:pointer;
`

const Question = styled.div`
	font-size:20px;
	font-weight:500;
	margin-top:20px;
`

const CheckContainer = styled.div`
	position:absolute;
	right:0;
`