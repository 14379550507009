import Categories from "../survey_structure_blades.json";
import Questions from "../question_structure_2.json";
import { feedback, feedback2, feedback3 } from "./feedback";

function randn_bm(min, max, skew) {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) num = randn_bm(min, max, skew);
  // resample between 0 and 1 if out of range
  else {
    num = Math.pow(num, skew); // Skew
    num *= max - min; // Stretch to fill range
    num += min; // offset to min
  }
  return num;
}

function randomIntFromInterval(min, max) {
  if (max == 0) {
    return 0;
  }
  // min and max included

  return Math.floor(Math.random() * (max - min + 1) + min);
}

const dates = [
  "March 4,2022",
  "March 4,2021",
  "March 4,2020",
  "March 4,2019",
  "March 4,2018",
  "March 4,2017",
  "March 4,2016",
  "March 4,2015",
  "March 4,2014",
  "March 4,2013",
];

export const Data_Generator = (points, history, categories, questions) => {
  let responses = [];

  for (let i = 0; i < history; i++) {
    let _package = { id: i + 1, date: dates[i], type: "COC" };

    let response_list = generate_responses(points, categories, questions);

    _package["responses"] = response_list;

    responses.push(_package);
  }

  return responses;
};

const getminMax = () => {
  let min = randomIntFromInterval(0, 8);
  let max = randomIntFromInterval(min + 1, 10);

  return [min, max];
};

const generate_responses = (points, cats, questions, team, records) => {
  let responses = [];
  let num_feedback = 0;
  let secondary_length = cats.categories.find((f) => f.priority == "secondary")
    .options.length;
  let min_max = Array.from({ length: secondary_length }, (_, i) => i + 1).map(
    (item) => getminMax()
  );
  let skew = (val) => randomIntFromInterval(0, 101) / 40;
  for (let i = 0; i < points; i++) {
    let resp = { id: i + 1, categories: [], questions: [] };
    cats.categories.map((item, idx) => {
      let selection = randomIntFromInterval(0, item.options.length - 1);
      //Check selection subfactor existance
      let subfactor = null;
      if ("subfactors" in item.options[selection]) {
        if (item.options[selection].subfactors.length > 0) {
          subfactor = randomIntFromInterval(
            0,
            item.options[selection].subfactors.length - 1
          );
        }
      }

      resp.categories.push({
        id: item.id,
        response: item.options[selection].id,
        subfactor:
          subfactor !== null
            ? item.options[selection].subfactors[subfactor].id
            : subfactor,
        priority: item.priority,
      });
    });

    let secondary = cats.categories
      .find((f) => f.priority == "secondary")
      .options.findIndex(
        (f) =>
          f.id ==
          resp.categories.find((f) => f.priority == "secondary").response
      );

    questions.dimensions.map((item, idx) => {
      let dimension = idx;
      let score = randn_bm(
        min_max[secondary][0],
        min_max[secondary][1],
        skew(idx)
      );
      item.factors.map((f, f_id) => {
        let factor = f_id;
        f.questions.map((q, q_id) => {
          resp.questions.push({
            id: dimension,
            factor,
            q: q_id,
            response: Math.floor(score),
            ques_order: resp.questions.length + 1,
          });
        });
      });
    });

    if (num_feedback < 14 && team == 0 && questions.type == 2) {
      let pos = num_feedback % 2;
      let fee = feedback[Math.floor(num_feedback / 2)];
      let val = pos === 0 ? 8 : 2;
      resp.feedback = [
        {
          q: fee.q,
          factor: fee.factor,
          question: fee.question,
          id: fee.id,
          feedback: fee.feedback[pos],
          response: val,
        },
      ];
      num_feedback += 1;
    }
    if (num_feedback < 14 && team == 0 && questions.type == 3) {
      let pos = num_feedback % 2;
      let fee = feedback2[Math.floor(num_feedback / 2)];
      let val = pos === 0 ? 8 : 2;
      resp.feedback = [
        {
          q: fee.q,
          factor: fee.factor,
          question: fee.question,
          id: fee.id,
          feedback: fee.feedback[pos],
          response: val,
        },
      ];
      num_feedback += 1;
    }

    if (num_feedback < 14 && team == 0 && questions.type == 4) {
      let pos = num_feedback % 2;
      let fee = feedback3[Math.floor(num_feedback / 2)];
      let val = pos === 0 ? 8 : 2;
      resp.feedback = [
        {
          q: fee.q,
          factor: fee.factor,
          question: fee.question,
          id: fee.id,
          feedback: fee.feedback[pos],
          response: val,
        },
      ];
      num_feedback += 1;
    }

    resp["survey_personality"] = generate_personality();
    resp["employee_records"] = generate_records(records);

    responses.push({ employee_id: 0, response: resp });
  }

  return responses;
};

function randomValueBetween(min, max) {
  return Math.random() * (max - min) + min;
}

function randomDate(date1, date2) {
  var date1 = date1 || "01-01-1970";
  var date2 = date2 || new Date().toLocaleDateString();
  date1 = new Date(date1).getTime();
  date2 = new Date(date2).getTime();
  if (date1 > date2) {
    return new Date(randomValueBetween(date2, date1)).toLocaleDateString();
  } else {
    return new Date(randomValueBetween(date1, date2)).toLocaleDateString();
  }
}

export const Data_GeneratorV2 = (
  points,
  response_numbers,
  categories,
  questions,
  records = []
) => {
  let responses = [];

  let sport_list = categories.categories.find(
    (f) => f.priority == "primary"
  ).options;
  let number_of_teams = sport_list.length;

  for (let i = 0; i < number_of_teams; i++) {
    for (let r = 0; r < response_numbers; r++) {
      let date = randomDate("02/13/2021", "05/01/2022");
      let _package = {
        id: i + 1,
        date: date,
        type: "COC",
        name: sport_list[i].name,
      };

      let response_list = generate_responses(
        points,
        categories,
        questions,
        i,
        records
      );

      _package["responses"] = response_list;

      responses.push(_package);
    }
  }

  return responses.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );
};

const generate_personality = () => {
  let personality = {};
  let values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  values.map((item) => {
    personality[item] = { question: item, response: randomValueBetween(1, 7) };
  });

  return personality;
};

const generate_records = (records) => {
  return records.map((item) => {
    let value;
    if (item.name == "Salary") {
      value = randomValueBetween(30000, 200000);
    } else {
      value = randomValueBetween(1, 20);
    }
    return { name: item.name, id: item.id, value };
  });
};
