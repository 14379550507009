import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Create from "./Create";
import Table from "../../reports/components/InsightStoryTable";

const List = ({ setDeleteId, loading, setDeleteItem, pdfList }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const { coreData, pdfReports } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (coreData && pdfReports?.fetched && pdfReports?.templates_fetched) {
      setIsLoading(false);
    }
  }, [coreData, pdfReports]);

  const getName = () => {
    if (coreData?.permissions?.first_name) {
      return coreData?.permissions?.first_name + "'s ";
    }
    return "Your ";
  };

  if (isLoading) {
    return <LoadingContainer>Loading...</LoadingContainer>;
  }

  return (
    <Container>
      <Center>
        <SmallTitle>Data Stories</SmallTitle>
        {coreData?.categories && pdfReports?.templates && pdfList?.length > 0 && (
          <>
            <Title>
              {getName()} data stories
              <Button
                onClick={() => navigate("/leader/audit/AI/insights/create")}
              >
                Create Story
              </Button>
            </Title>
            <Table
              data={pdfList}
              categories={coreData?.categories}
              templates={pdfReports?.templates}
              setDeleteId={setDeleteId}
              setDeleteItem={setDeleteItem}
              loading={loading}
            />
          </>
        )}
        {(pdfReports?.debrief_pdf_report?.length === 0 ||
          (pdfReports?.debrief_pdf_report?.length > 0 &&
            pdfList?.length === 0)) && <Create />}
      </Center>
    </Container>
  );
};

export default List;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  margin-top: 40px;
  font-family: "Raleway";
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: "Raleway";
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #2a3039;
`;

const SmallTitle = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2d70e2;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #2d70e2;
  font-family: "Raleway";
`;