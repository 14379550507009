import styled, { keyframes } from "styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";

const EmptyInsights = () => {
  const navigate = useNavigate();

  return (
    <EmptyContainer>
      <EmptyTitle>Data Stories</EmptyTitle>
      <EmptyDescription>
        <p>
          <b>Data Stories</b> are comprehensive reports generated from your
          audit assessments. These stories provide insights and analysis to help
          you make informed decisions.
        </p>
        <p>
          <b>How to Use:</b> Use these data stories to track performance,
          identify trends, and develop strategies for improvement.
        </p>
        <p>
          To start creating a new data story, click the <b>Create</b> button
          below.
        </p>
      </EmptyDescription>
      <CreateButton
        onClick={() => {
          // Update for the data story.
          navigate("/leader/audit/reports/stories/create");
        }}
      >
        Create Story
      </CreateButton>
    </EmptyContainer>
  );
};

export default EmptyInsights;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 40px;
  text-align: flex-start;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const EmptyTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 20px;
`;

const EmptyDescription = styled.div`
  font-size: 16px;
  font-family: "Raleway";
  margin-bottom: 30px;
  max-width: 600px;
  line-height: 1.5;

  p {
    margin-bottom: 15px;
  }
`;

const CreateButton = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 16px;
  padding: 12px 24px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
  display: inline-block;
`;
