import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Modal, Dropdown } from "semantic-ui-react";
import { ILIcon, UserAvatar as UserIcon } from "./Icons";
import { closeIcon } from "components/Icons";
import IconContainer from "./IconContainer";

const Question = (props) => {
  const { content, hasFlag } = props;

  return (
    <QuestionContainer>
      <QuestionBox>{content}</QuestionBox>
      {hasFlag && <FlagIndicator>!</FlagIndicator>}
      <UserAvatar>
        <IconContainer icon={UserIcon()} />
      </UserAvatar>
    </QuestionContainer>
  );
};

const Response = (props) => {
  const { content } = props;

  return (
    <ResponseContainer>
      <AIAvatar>
        <IconContainer backgroundColor="#2D70E2" icon={ILIcon("#FFF")} />
      </AIAvatar>
      <ResponseBox>{content}</ResponseBox>
    </ResponseContainer>
  );
};

const ChatModal = ({ open, setOpen, data }) => {
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Content>
        <TopSection>
          {/* <Title>{extractedData?.title}</Title> */}
          <T2>Conversation</T2>

          <CloseButton onClick={() => setOpen(false)}>
            {closeIcon("#2A3039")}
          </CloseButton>
        </TopSection>

        <ConversationContainer>
          <Thread>
            {data?.map((item, i) => {
              const question = item?.follow_up_question ? (
                <Response content={item.follow_up_question} />
              ) : (
                <Response content={item.question} />
              );

              const feedback = <Question content={item.feedback} />;

              return (
                <>
                  {question}
                  {feedback}
                </>
              );
            })}
          </Thread>
        </ConversationContainer>
      </Content>
    </Modal>
  );
};

export default ChatModal;

const Content = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  height: 90vh;
`;

const T2 = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
  color: #2a3039;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Thread = styled.div``;

const ConversationContainer = styled.div`
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    margin-top: 10px;
    margin-bottom: 35px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.03);
    padding-left: 16px;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-right: 0;
  margin-left: auto;
  max-width: 550px;
`;

const QuestionBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f8faff;
  line-height: 18px;
  margin-right: 0;
  margin-left: auto;
`;

const UserAvatar = styled.div`
  margin-left: 8px;
`;

const ResponseContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  max-width: 400px;
`;

const AIAvatar = styled.div`
  margin-right: 8px;
`;

const ResponseBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f8faff;
  line-height: 18px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const FlagIndicator = styled.div`
  display: flex;
  align-items: center;
  position: absolte;
  justify-content: center;
  background: #f15062;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  right: -35px;
  bottom: 6px;
  z-index: 99;
  margin-right: -20px;
  position: relative;
`;
