import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Search } from "./difference_search";
import {
  SET_SELECTED_QUESTION,
  SET_SUB_NAV,
  SET_SELECTED_FILTERS,
  SET_NAVIGATION,
  SG_GET_OUTCOME_QUESTIONS
} from "constants/actions";
import { build_default_State } from "../data_navigation/calculate_data";
import { Pagination, Checkbox, Dimmer, Loader, Modal } from "semantic-ui-react";
import {
  primaryColor,
  neutralColor,
  fontSizes,
  inactiveColor,
  primaryBg,
  secondaryColor,
  poorColor,
  hoverBg,
  titleColor,
} from "../styleConfig";
import { TrendDown, TrendUp, CloseIcon, FilterIcon } from "../Icons";
import ConfirmButton from "./ConfirmButton";
import Card from "./Card"
import useGetOrgData from "hooks/useGetOrgData";

const configure = (structure) => {
  let ids = 0;
  let names = [{ title: "Overall Culture", level: 0, id: ids }];
  ids += 1;
  if (structure?.dimensions) {
    structure?.dimensions.map((item, i) => {
      names.push({ title: item.title, level: 1, id: ids, dimension: i });
      ids += 1;
      if (item.factors.length > 0) {
        item.factors.map((x, idx) => {
          names.push({
            title: x.title,
            level: 2,
            id: ids,
            dimension: i,
            factor: idx,
          });
          ids += 1;
        });
      }
    });
  }

  return names;
};

const Insights = ({ questions }) => {
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.audit?.filters, shallowEqual);
  const [page, setPage] = useState(1);
  const [checked, setChecked] = useState(false);
  const [demographic, setDemographics] = useState(false);
  const [outcomes, setOutcomes] = useState(false);
  const [layer, setLayers] = useState(1);
  const [loading, setLoading] = useState(true);
  const [extraLoading, setExtraLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sortOrder, setSortOrder] = useState();
  const loader = useRef(null);
  const { organizationData } = useGetOrgData();
  const [questionsList, setQuestionsList] = useState([]);
  const surveyType = 10; // Survey type is 10 for outcomes.

  const filter_data = useSelector(
    (state) => state.audit,
    shallowEqual
  );

  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual
  );

  const surveyQuestions = useSelector((state) => {
    return state.surveyquestions.outcome_questions;
  });

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && page < Math.ceil(data.length / 10)) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useEffect(() => {
    setLoading(true);
    setData(
      Search(filter_data, categories, questions, checked, demographic, layer,outcomes,
        questionsList)
    );
    setTimeout(() => {
      setLoading(false);
    }, [400]);
  }, []);

    // Get the sort order for the outcome questions.
    useEffect(() => {
      if (organizationData?.styling) {
        const sortOrder = organizationData?.styling?.survey_sequence?.find(
          (item) => item.value === "outcome_question"
        )?.question?.sort_order;
        setSortOrder(sortOrder);
      }
    }, [organizationData]);

  useEffect(() => {
    if (surveyQuestions?.length === 0) {
      dispatch({
        type: SG_GET_OUTCOME_QUESTIONS,
        payload: `survey_type=${surveyType}&status=2`,
      });
    }
  }, [surveyQuestions]);

  useEffect(() => {
    if (surveyQuestions?.length > 0 && sortOrder) {
      const questions = surveyQuestions?.find(
        (item) => item.sort_order === sortOrder
      )?.questions;
      if (questions?.length > 0) {
        setQuestionsList(questions);
      }
    }

  }, [surveyQuestions, sortOrder]);

  // useEffect to handle pagination for infinite scroll
  // useEffect(() => {
  //   if (data.length > 0) {
  //     if (paginatedData.length === 0) {
  //       setPaginatedData(data.slice((page - 1) * 10, 10 * page));
  //     } else {
  //       setExtraLoading(true);
  //       // Set an artificial timeout.
  //       setTimeout(() => {
  //         setPaginatedData([
  //           ...paginatedData,
  //           ...data.slice((page - 1) * 10, 10 * page),
  //         ]);
  //         setExtraLoading(false);
  //       }, [1000]);
  //     }
  //   }
  // }, [page, data]);

  useEffect(() => {
    setLoading(true);
    const newData = Search(
      filter_data,
      categories,
      questions,
      checked,
      demographic,
      layer,
      outcomes,
      questionsList
    );
    setData(newData);
    setPaginatedData(newData.slice(0, 10 * page));
    setTimeout(() => {
      setLoading(false);
    }, [400]);
  }, [checked, filter_data, layer, demographic,outcomes]);

  const handle_navigation = (n1, n2, select) => {
    let structure = configure(questions);
    let _selected = structure.find(
      (f) => f.dimension == select.id && f.factor == select.factor
    );

    dispatch({
      type: SET_SELECTED_QUESTION,
      payload: _selected,
    });

    dispatch({
      type: SET_SUB_NAV,
      payload: 3,
    });

    onSelect(n1, n2);
  };

  const getLabel = (item) => {};

  const onSelect = (s1, s2) => {
    let check = selected.filter(
      (f) =>
        (f.id === s1.id &&
          f.subfactor === s1?.subfactor &&
          f.option === s1.option) ||
        (f.id === s2.id &&
          f.subfactor === s2?.subfactor &&
          f.option === s2.option)
    );
    let _selected;

    if (check.length > 0) {
      _selected = selected;
    } else {
      if (s2.option) {
        _selected = [...selected, s1, s2];
      } else {
        _selected = [...selected, s1];
      }
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected);
  };

  const set_nav = (new_selected) => {
    let _selected = build_default_State(categories);

    new_selected.map((item, i) => {
      if (_selected[item.id]?.selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s != "selected" && s != "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }

      if (item.subfactor != null) {
        _selected[item.id][item.option].subfactors[
          item.subfactor
        ].selected = true;
        _selected[item.id][item.option].sub = true;
      } else {
        _selected[item.id][item.option].selected = true;
      }
      return null;
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
  };

  const getFlags = (item) => {
    let diff = item.diff;
    let threshold = item.threshold;
    let score = item.score;

    let flags = [];
    if (diff < -10) {
      flags.append({ type: "Diff", value: diff, severity: "high" });
    } else if (diff < -5) {
      flags.append({ type: "Diff", value: diff, severity: "moderate" });
    } else if (diff < 0) {
      flags.append({ type: "Diff", value: diff, severity: "low" });
    } else if (diff < 5) {
      flags.append({ type: "Diff", value: diff, severity: "positive" });
    } else {
      flags.append({ type: "Diff", value: diff, severity: "shinning" });
    }

    if (threshold > 5) {
      flags.append({ type: "Diff", value: threshold, severity: "high" });
    } else if (threshold > 3) {
      flags.append({ type: "Diff", value: threshold, severity: "moderate" });
    } else if (threshold > 2) {
      flags.append({ type: "Diff", value: threshold, severity: "low" });
    } else {
      flags.append({ type: "Diff", value: threshold, severity: "positive" });
    }

    if (score < 5) {
      flags.append({ type: "Score", value: score, severity: "high" });
    } else if (score < 6) {
      flags.append({ type: "Score", value: score, severity: "moderate" });
    } else if (score < 7) {
      flags.append({ type: "Score", value: score, severity: "low" });
    } else if (score < 8) {
      flags.append({ type: "Score", value: score, severity: "positive" });
    } else {
      flags.append({ type: "Score", value: score, severity: "shinning" });
    }

    return flags;
  };

  const getColor = (score) => {
    if (score < -10) {
      return "#E66579";
    }
    if (score < -5) {
      return "#EE7C35";
    }
    if (score < 0) {
      return "#96ACFF";
    }
    return "#66D686";
  };

  const getScoreCol = (score) => {
    let scoreText = score < 0 ? "below norm" : "above norm";
    return (
      <ScoreText style={{ color: score < 0 ? poorColor : secondaryColor }}>
        {score}% {scoreText}
      </ScoreText>
    );
  };

  const getTableData = (data) => {
    return data.map((item) => {
      let select = { factor: item.factor, id: item.d };
      let n1 = {
        option: item.option1.id,
        id: item.category1,
        name: item.option1,
        subfactor: null,
      };
      let n2 = {
        option: item?.option2?.id,
        id: item?.category2,
        name: item?.option2,
        subfactor: null,
      };
      let scoreIcon =
        item.diff < 0 ? TrendDown(poorColor) : TrendUp(secondaryColor);

      // return <Card name={item.option1.name} category={ categories.categories.find((f) => f.id === item.category1)
      //               .name} score={item.diff.toFixed(1)} n={item.n} />

      return (
        <TableDataRow onClick={() => handle_navigation(n1, n2, select)}>
          <NameCol>
            {item.option1.name}
            {item?.option2 ? ` / ${item?.option2?.name}` : ""}
          </NameCol>
          <CategoryCol>
            <CategoryItem>
              <CategoryText>
                {
                  categories.categories.find((f) => f.id === item.category1)
                    .name
                }
                {item?.option2
                  ? ` / ${
                      categories.categories.find((f) => f.id === item.category2)
                        .name
                    }`
                  : ""}
              </CategoryText>
            </CategoryItem>

            {outcomes && (<CategoryItem>
              <CategoryText>
                    {item.name}
              </CategoryText>
            </CategoryItem>) }


          </CategoryCol>
          <ScoreCol>
            {scoreIcon}&nbsp;&nbsp;&nbsp;{getScoreCol(item.diff.toFixed(1))}
          </ScoreCol>
          <PeopleCol>
            {item?.n}
            {item.factor !== null ? (
              <Pillar>
                {
                  questions.dimensions
                    .find((f) => f.id - 1 == item.d)
                    .factors.find((f) => f.id - 1 == item.factor).title
                }
              </Pillar>
            ) : (
              ""
            )}
          </PeopleCol>
        </TableDataRow>
      );
    });
  };

  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 30,
          marginBottom: 30,
        }}
      >
        <Title>Insights Scan</Title>
        <div style={{ display: "flex" }}>
          <IconWrapper onClick={() => setShowModal(true)}>
            {FilterIcon()}
          </IconWrapper>
        </div>
      </div>

      <Dimmer.Dimmable blurring dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Modal
          dimmer="blurring"
          open={showModal}
          closeOnDimmerClick
          onClose={() => setShowModal(false)}
          style={{ width: "auto", height: 298 }}
        >
          <FilterContainer>
            <TopSection>
              <FiltersLabel>Filters</FiltersLabel>
              <CloseButton onClick={() => setShowModal(false)}>
                {CloseIcon()}
              </CloseButton>
            </TopSection>
            <ButtonSection>
              <Checkbox
                toggle
                checked={checked}
                onChange={(e, d) => setChecked(d.checked)}
                label="Pillar data"
                style={{ marginRight: 30 }}
              />
              <Checkbox
                toggle
                checked={layer === 2}
                onChange={(e, d) => setLayers(d.checked ? 2 : 1)}
                label="2 Layers"
                style={{ marginRight: 30 }}
              />
              <Checkbox
                toggle
                checked={demographic}
                onChange={(e, d) => setDemographics(d.checked)}
                label="Demographics"
                style={{ marginRight: 30 }}
              />
              <Checkbox
                toggle
                checked={outcomes}
                onChange={(e, d) => setOutcomes(d.checked)}
                label="Outcomes"
              />
            </ButtonSection>
            <ConfirmSection>
              <ButtonWrapper>
                <ConfirmButton
                  buttonText="Clear"
                  btnAlt
                  onClick={() => {
                    setShowModal(false);
                    setLayers(1);
                    setChecked(false);
                    setDemographics(false);
                  }}
                />
              </ButtonWrapper>
              <ButtonWrapper>
                <ConfirmButton
                  buttonText="Apply"
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </ButtonWrapper>
            </ConfirmSection>
          </FilterContainer>
        </Modal>
        <TableContainer>
          <HeadersSection>
            <NameCell>Name</NameCell>
            <CategoryCell>Category</CategoryCell>
            <ScoreCell>Score</ScoreCell>
            <PeopleCell>People</PeopleCell>
          </HeadersSection>
          <TableDivider />
        </TableContainer>
        {data.length > 0 &&
          getTableData(data.slice((page - 1) * 10, 10 * page))}
        {/* {extraLoading && page < Math.ceil(data.length / 10) && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 10,
            }}
          >
            <Loader active inline="centered" />
          </div>
        )}
        <div ref={loader} /> */}
        <div
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: 10,
          }}
        >
          <Pagination
            activePage={page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            totalPages={Math.ceil(data.length / 10)}
            onPageChange={(e, { activePage }) => setPage(activePage)}
            size="mini"
          />
        </div>
      </Dimmer.Dimmable>
    </Container>
  );
};

export default Insights;

const Warning = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const Container = styled.div`
  width: 100%;
  font-family: "Poppins";
  padding-left: 58px;
  margin-top: 30px;
`;

const Row = styled.div`
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const Option = styled.div`
  font-size: 14px;
`;

const Sub = styled.div`
  font-size: 8px;
`;

const Score = styled.div`
  color: #6d6d6d;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.medium};
  line-height: 140%;
`;

const HeadersSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  color: ${inactiveColor};
`;

const NameCell = styled.div`
  width: 20%;
`;

const CategoryCell = styled.div`
  width: 30%;
`;

const ScoreCell = styled.div`
  width: 30%;
`;

const PeopleCell = styled.div`
  width: 10%;
`;

const TableDataRow = styled.div`
  display: flex;
  height: 50px;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-right: 20px;
  padding-left:20px;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${hoverBg};
  }
`;

const NameCol = styled.div`
  width: 20%;
`;

const CategoryCol = styled.div`
  width: 30%;
  display:flex;
`;

const ScoreCol = styled.div`
  width: 30%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PeopleCol = styled.div`
  display: flex;
  flex-direction: row;
  width: 10%;
`;

const CategoryItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 4px 8px;
  gap: 10px;
  border-radius: 8px;

  width: fit-content;
  background-color: ${primaryBg};
  margin-right:5px;
`;

const CategoryText = styled.div`
  color: ${primaryColor};
  font-size: ${fontSizes.xsmall};
  font-weight: 600;
  line-height: 140%;
`;

const TableDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${neutralColor};
  margin-bottom: 20px;
`;

const Pillar = styled.div`
  font-weight: 400;
  font-size: 8px;
  background-color: #476dfa;
  color: white;
  border-radius: 10px;
  padding: 3px;
  width: 200px;
  display: flex;
  justify-content: center;
  text-align:center;
  margin-left: 20px;
  line-height:1.5;
`;

const ScoreText = styled.div`
  width: 100%;
`;

const Col = styled.div`
  width: ${(props) => props.width + "%"};
  padding-left: 5px;
  display: flex;
  justify-content: center;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 128%;

  color: ${titleColor};
`;

const FilterContainer = styled.div`
  padding: 40px;
`;

const TopSection = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

const FiltersLabel = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.large};
`;

const CloseButton = styled.div`
  font-size: ${fontSizes.large};
  font-weight: 200;
  font-family: "Barlow", sans-serif;
  font-style: normal;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ConfirmSection = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 32px;
  bottom: 0;
  padding-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  width: 82px;
  margin-right: 16px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  z-index: 1;
  margin-right: 20px;
`;
