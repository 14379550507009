import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
import Chart from "react-apexcharts";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  barChartOptions,
  dataLabels,
} from "reports/DataJourney/components/common/styles";

const FocusSuggestion = (props) => {
  // Parse data from props
  const slideData = props?.activeJourney?.activeJourney?.response?.data_slide;

  // Create a new array of values, rounding each to 1 decimal place.
  const roundedValues = slideData?.values.map((value) => value.toFixed(1));

  const chartOptions = {
    chart: barChartOptions,
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    colors: ["#2D70E2", "#C7CFDB", "#E6E6E6"],
    xaxis: {
      categories: slideData?.labels,
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: Math.round(Math.max(...slideData?.values) + 1),
      decimalsInFloat: 1,
      title: {
        text: `${slideData?.factor_name} scores`,
        style: {
          fontWeight: 400,
        },
      },
    },
    dataLabels: dataLabels,
  };

  const lowOrHigh =
    slideData?.values[0] < slideData?.values[1] ? "lower" : "higher";

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        What to focus on improving first?
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Based on your last survey, your team's{" "}
        {slideData.factor_name.toLowerCase()} score is{" "}
        {(slideData?.values[0] / 10).toFixed(1)}. This is {lowOrHigh} than your
        organization's average of {(slideData?.values[1] / 10).toFixed(1)}. We
        predict that you can{" "}
        <strong>
          improve your team's {slideData.factor_name.toLowerCase()} scores by
          over 10%
        </strong>{" "}
        following this data journey.
      </SubtextExtended>

      <ChartContainer delay={1}>
        <Chart
          options={chartOptions}
          series={[{ data: roundedValues }]}
          type="bar"
          height={350}
        />
      </ChartContainer>
    </ScreenContainer>
  );
};

export default FocusSuggestion;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const ChartContainer = styled.div`
  width: 100%;

  margin-top: 40px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const data3 = {
  series: [
    {
      data: [6.7, 7.1, 7.7],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "75px",
        distributed: true,
      },
    },
    colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
    xaxis: {
      categories: [
        "Your Team",
        "Your Organization",
        "Your team after data journey",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
          colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
        },
      },
    },
    yaxis: {
      min: 0,
      max: 10,
      decimalsInFloat: 1,
      title: {
        text: "Psychological Safety Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
