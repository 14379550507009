import React from 'react';
import {
 Page,
 Text,
 View,
 Document,
 StyleSheet,
 Image,
 Font,
} from "@react-pdf/renderer";
import {
 defaultText,
 filterColor,
 labelColor,
 inactiveColor,
} from "../styleConfig";
import lightbulbImage from "assets/images/lightbulb.jpg";
import pdfImage from "assets/images/stock/recommendations-image.png";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import RalewayBold from "assets/fonts/Raleway-Bold.ttf";
import RalewaySemiBold from "assets/fonts/Raleway-SemiBold.ttf";

Font.register({
 family: "Raleway",
 fonts: [
  { src: Raleway, fontWeight: 400 },
  { src: RalewayBold, fontWeight: 700 },
  { src: RalewaySemiBold, fontWeight: 600 },
 ],
 format: "truetype",
});

const fontSizes = {
 xsmall: 10,
 small: 12,
 medium: 14,
 large: 16,
 xlarge: 18,
};

const styles = StyleSheet.create({
 page: {
  padding: 60,
  zIndex: -9,
  display: "flex",
  fontFamily: "Raleway",
 },
 description: {
  marginBottom: 20,
  fontSize: fontSizes.small,
  //  lineHeight: 1.5,
 },
 T2: {
  fontSize: fontSizes.large,
  fontStyle: "normal",
  fontWeight: 600,
  marginBottom: 20,
 },
 actionItemsContainer: {
  marginTop: 40,
  marginBottom: 40,
 },
 titleContent: {
  flexDirection: "row",
 },
 riskTitle: {
  fontWeight: 700,
  fontSize: fontSizes.xlarge,
  marginBottom: 10,
  // lineHeight: 25,
  // fontStyle: "normal",
 },
 tag: {
  justifyContent: "flex-start",
  backgroundColor: labelColor.background,
  color: labelColor.text,
  padding: 5,
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: fontSizes.xsmall,
  borderRadius: 5,
  float: "right",
 },
 dateContent: {
  fontWeight: 400,
  fontSize: fontSizes.xsmall,
  color: inactiveColor,
 },
 content: {},
 imageContainer: {
  marginBottom: "32px",
  marginTop: "28px",
 },
 image: {
  width: "100%",
  borderRadius: 10,
  // height: 324,
 },
 imageTextContainer: {
  paddingRight: 128,
  marginLeft: 36,
  marginTop: 35,
  width: "100%",
  borderLeft: "4px solid " + filterColor.activeBackground,
 },
 imageText: {
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: fontSizes.xsmall,
  marginLeft: 16,
  color: defaultText,
 },
 ideaBoxContainer: {
  display: "flex",
  marginTop: 22,
  marginBottom: 32,
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
  height: 66,
  backgroundColor: labelColor.background,
  justifyContent: "center",
  borderRadius: 8,
  paddingRight: 30,
 },
 lightbulbImage: {
  paddingRight: 14,
  paddingLeft: 12,
  width: 52,
  height: 22,
  marginTop: -8,
 },
 ideaBoxText: {
  color: defaultText,
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: fontSizes.xsmall,
  paddingRight: 42,
  justifyContent: "flex-start",
  lineHeight: 1.2,
 },
 labelContainer: {},
 actionItemsTitle: {
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: fontSizes.large,
  marginBottom: 20,
 },
 actionItemsContent: {
  display: "flex",
  flexDirection: "column",
  paddingHorizontal: 42,
  paddingVertical: 18,
  alignItems: "flex-start",
  backgroundColor: filterColor.background,
  color: defaultText,
  borderRadius: 8,
 },
 actionItems: {
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: fontSizes.xsmall,
  marginBottom: 10,
  color: defaultText,
 },
 tagContainer: {
  justifyContent: "center",
  alignItems: "center",
  marginTop: -5,
  marginLeft: 10,
 },
});

const getPoints = (data) => {
 return (
  <View style={styles.actionItemsContainer}>
   <View style={styles.actionItemsContent}>
    <Text style={styles.actionItemsTitle}>Action items</Text>
    {[1, 2, 3, 4, 5].map((item, i) => {
     let n1 = data.points.indexOf(`${item}.`);
     let n2 = data.points.indexOf(`${item + 1}.`);
     const numberRegex = /\d\.\s/g;
     if (item !== 5) {
      return (
       <View style={{ flexDirection: "row" }}>
        <Text style={{ marginRight: 8, marginTop: -4 }}>•</Text>
        <Text key={i + "-action"} style={styles.actionItems}>
         {data.points.substring(n1, n2).replace(numberRegex, "")}
        </Text>
       </View>
      );
     }
     return (
      <View style={{ flexDirection: "row" }}>
       <Text style={{ marginRight: 8 }}>•</Text>
       <Text key={i + "-action"} style={styles.actionItems}>
        {data.points.substring(n1).replace(numberRegex, "")}
       </Text>
      </View>
     );
    })}
   </View>
  </View>
 );
};

const createIdeaBox = (idea) => {
 return (
  <View style={styles.ideaBoxContainer} wrap={false}>
   {lightbulbImage && (
    <Image
     src={{ uri: lightbulbImage }}
     cache={true}
     style={styles.lightbulbImage}
    />
   )}
   <Text style={styles.ideaBoxText}>{idea.trim()}</Text>
  </View>
 );
};

const PDF = ({
 data,
 riskTitle,
 resultSentences,
 sentenceOne,
 image,
 imageText,
 ideaOne,
 ideaTwo,
 solutionsSentences,
 solutionsOne,
 sentenceTwo,
 title,
}) => {
 return (
  <Document>
   <Page style={styles.page} wrap={true}>
    <>
     <View style={styles.titleContent}>
      <Text key="1" style={styles.riskTitle}>
       {title[0].toString().trim()}
      </Text>
      <View style={styles.tagContainer}>
       <Text key="2" style={styles.tag}>
        {data.primary.trim()}
       </Text>
      </View>
     </View>
     <Text style={styles.dateContent}>Created {data.date}</Text>
    </>

    <View style={styles.content}>
     {getPoints(data)}
     <Text style={styles.T2}>Recommendation</Text>
     <Text style={styles.description}>
      {resultSentences.map((item) => {
       return item + ". ";
      })}
     </Text>
     <Text style={styles.description}>{sentenceOne.trim()}.</Text>
     <View style={styles.imageContainer}>
      <Image style={styles.image} src={pdfImage} />
      <View style={styles.imageTextContainer}>
       <Text style={styles.imageText}>{imageText.trim()}.</Text>
      </View>
     </View>
     <Text style={styles.description}>{sentenceTwo.trim()}.</Text>
     {createIdeaBox(ideaOne)}
     <Text style={styles.description}>{data.ai_justification}</Text>

     <Text style={styles.T2}>Common obstacles and potential solutions</Text>
     <Text style={styles.description}>{data.obsctacle}</Text>
     <Text style={styles.description}>
      {solutionsSentences.map((t) => t + ". ")}
     </Text>
     {createIdeaBox(ideaTwo)}
     <Text style={styles.description}>{solutionsOne.trim()}.</Text>
    </View>
   </Page>
  </Document>
 );
};

export default PDF;