
import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import User from 'assets/images/empty/user.svg'
import tabs from 'data/leadership'


const UpdateCard = ({ loading,text }) => {
  if (loading) {
    return <EmptyUpdate />;
  }

  return (
    <Update>
     <Empty src={User} />
      <DistributionSub>
        {text}
      </DistributionSub>
    </Update>
  );
};

const Feedback = (props) =>{

  // console.log(props.data)
	return (
		<div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
          {props.selected>0?
          	props.data.question_list[props.selected-1].comments.map((item,i)=>{
          		return <UpdateCard loading={props.loading} text={item}/>
          	}):""}
            
           
          </div>
		)
}


export default Feedback

const loading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`;

const Empty = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 30px;
  margin-right:10px;
`;

const Update = styled.div`
  min-height: 150px;
  border-radius: 8px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  width: calc(33% - 36px);
  padding: 33px 20px 33px 20px;
  display: flex;
  flex-direction: row;
  margin-left:10px;
`;

const EmptyUpdate = styled.div`
  height: 150px;
  border-radius: 8px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  width: calc(33% - 36px);
  padding: 33px 20px 33px 20px;
  display: flex;
  flex-direction: row;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
`;



const DistributionSub = styled.div`
  font-size: 16px;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 10px;
`;
