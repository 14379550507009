import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";



const Representation = () =>{


	return  (<Card>
                 <Chart
                      options={data4.options}
                      series={data4.series}
                      type="line"
                      height={400}
                    />
                </Card>)
}



export default Representation

const Card = styled.div`
  width: calc(100% - 360px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
`;


const data4 = {
  series: [
    {
      name: "Black",
      data: [4.8,5,5.4,6,6,8,11,12]
    },
  ],
  options: {
    chart: {
      height: '100%',
      type: "line",
      fontFamily: "Poppins, sans-serif",
      toolbar: {
        show: false,
      },
      animations:{
        enabled:true
      }
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      borderColor: "#e7e7e7",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [5, 5],
      strokeWidth:2,
      radius:2,
       strokeColors: '#476DFA',
    },
    dataLabels:{
    	enabled:true,
    	 formatter: function (val, opts) {
      return val+ '%'
  }
    },
    tooltip:{
      enabled:true
    },
    colors:['#476DFA', '#66D686', '#9C27B0'],
    xaxis: {
      categories:['Year 1', 'Year 2' , 'Year 3', 'Year 4', 'Year 5', 'Year 6', 'Year 7' , 'Year 8', 'Year 9'],
      title: {
        text: "Time",
        style:{
          fontSize:'12px',
          fontWeight:400,
          color:'#7E7E7E'
        }
      },
    },
    yaxis: {
      decimalsInFloat:0,
      title: {
        text: "Representation of Black athletes (%)",
        style:{
          fontSize:'12px',
          fontWeight:400,
          color:'#7E7E7E'
        }
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  },
};
