import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon,Table } from 'semantic-ui-react'



const RoleTable = (props) =>{

	return (
		<Container>
		<TableContainer>
			<TableTitle>Permissions for the roles on this team type</TableTitle>
			<Table celled>
			    <Table.Header>
			      <Table.Row>
			        <Table.HeaderCell textAlign='center' style={{backgroundColor:'#612A8A',color:'white',fontWeight:'bold'}}>Permissions</Table.HeaderCell>
			        {props.roles.map((item,i)=><Table.HeaderCell textAlign='center' style={{backgroundColor:'#612A8A',color:'white',fontWeight:'bold'}}>{item.name+(i==0?' (default)':" (leader)")}</Table.HeaderCell>)}
			      </Table.Row>
			    </Table.Header>

			    <Table.Body>
			    	{['Team surveys','Login Credentials','Profile Creation','Team Dashboard Access','Anonymous Results Access','Open results access','Team management'].map((item,i)=>{
			    		return (
			    			 <Table.Row>
						        <Table.Cell textAlign='center' style={{backgroundColor:'#F2F2F2'}}>
						        	<Title>{item}</Title>
						        	
						        </Table.Cell>
						        <Table.Cell textAlign='center' style={{backgroundColor:'#F2F2F2'}}> {props.roles[0].access[i]?<Icon style={{color:'#612A8A'}} name='checkmark' size='large' />:<Icon color='red' name='close' size='large'/>}</Table.Cell>
						        <Table.Cell textAlign='center' style={{backgroundColor:'#F2F2F2'}}> {props.roles[1].access[i]?<Icon style={{color:'#612A8A'}} name='checkmark' size='large' />:<Icon color='red' name='close' size='large'/>}</Table.Cell>
						      </Table.Row>
			    			)
			    	})}
			     
			     
			    </Table.Body>

	  		</Table>
	  		</TableContainer>

  		</Container>
		)
}

export default RoleTable

const TableTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:20px;
	font-weight:500;
`

const Title = styled.div`
		font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
`

const Container = styled.div`
	width:100%;
	display:flex;
	justify-content:center;
`

const Button = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:7px 24px 7px 24px;
  border: none;
    outline:none;
    margin-left:10px;
`

const TableContainer = styled.div`
	max-width:600px;
	width:100%;
`