import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import QChart from './QChart';



const Questions = ({data}) =>{
    console.log(data)

    return (
        <Container>
           {data && <QChart data={data} delay={0.5}/> }
        </Container>
    )
}

export default Questions;

const Container = styled.div`
    width:100%;
`

const Row = styled.div`
    width:100%;
    display:flex;
    border-bottom:1px solid #f0f0f0;
`

const TR = styled.div`
    width:${props=>props.width}%;
    display:flex;
    flex-direction:column;
    padding:20px;
`
const Q = styled.div`
    font-weight:bold;
    margin-bottom:10px;
`

