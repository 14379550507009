import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import LogAnimation from "components/LogAnimation";

const EngagamentChart = ({ data, setSelectedDataPoint }) => {
  const [chart, setChart] = useState(data3.options);
  const [series, setSeries] = useState(data3.series);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selected) {
      setSelectedDataPoint(chart.xaxis.categories[selected[0]]);
    }
  }, [selected]);

  useEffect(() => {
    if (data?.length > 0) {
      setLoading(true);
      let sorted_data = data
        .filter((f) => f.data?.length > 0)
        .sort(
          (a, b) =>
            a.data[0].completed / a.data[0].total -
            b.data[0].completed / b.data[0].total
        );
      let _series = [];
      sorted_data.map((item) => {
        if (item.data[0].total !== 0) {
          const _percent = (item.data[0].completed / item.data[0].total) * 100;
          _series.push(_percent);
        } else {
          _series.push(0);
        }
      });

      let _chart = chart;
      _chart.xaxis.categories = sorted_data.map((item) => item.name);

      _chart.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          setSelected(config.selectedDataPoints[0]);
        },
      };

      setChart(_chart);
      setSeries([{ name: "Scores", data: _series }]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [data]);

  return (
    <div>
      {loading ? (
        <LoadWrapper>
          <LogAnimation />
          <LoadingText>Loading component</LoadingText>
        </LoadWrapper>
      ) : (
        <>
          <ChartHeader>Survey completion engagement</ChartHeader>
          <Chart options={chart} series={series} type="bar" height={500} />
        </>
      )}
    </div>
  );
};

export default EngagamentChart;

const ChartHeader = styled.div`
  color: #2a3039;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss02" on, "ss01" on, "ss11" on, "salt" on, "ss10" on,
    "ss09" on, "ss04" on, "ss03" on, "ss08" on, "ss07" on, "ss05" on, "liga" off;
  /* Head/SemiBold 20 */
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
`;

const LoadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const LoadingText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  padding-top: 20px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [9, 10, 8],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    noData: {
      text: "Loading chart...",
      align: "center",
      verticalAlign: "middle",
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#F8A8B0", "#FECD8D", "#95E6D2"],
        inverseColors: true,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 61, 100],
        colorStops: [
          {
            offset: 0,
            color: "#F8A8B0",
            opacity: 0.5,
          },
          {
            offset: 60,
            color: "#FECD8D",
            opacity: 0.5,
          },
          {
            offset: 100,
            color: "#95E6D2",
            opacity: 0.5,
          },
        ],
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [
        "Apples",
        "Oranges",
        "Strawberries",
        "Pineapples",
        "Mangoes",
        "Bananas",
        "Blackberries",
        "Pears",
        "Watermelons",
        "Cherries",
        "Pomegranates",
        "Tangerines",
        "Papayas",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    xaxis: {
      title: {
        text: "Engagement %",
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      decimalsInFloat: 0,
      tickAmount: 5,
      title: {
        text: "",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
