import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import User from './User/_index'
import User2 from './User/User2'
import Profile from './User/Profile'
import { Dropdown, Icon, Dimmer, Loader, Segment } from 'semantic-ui-react'
import Empty from '404/Empty'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
	SG_GET_MOODS,
	SG_GET_CULTURE_DATA,
	LOAD_EMPLOYEES,
	LOAD_TEAMS,
	SG_GET_TEAM_ACCESSS,
} from "constants/actions";
// import { number } from "mathjs";
import Select from "react-select";



const options = [
	{
		text: 'Overall',
		'value': 'Overall'
	},
	{
		text: 'Team Health',
		'value': 'Team Health'
	},
	{
		text: 'Emotional Health',
		'value': 'Emotional Health'
	},
	{
		text: 'Clarity',
		'value': 'Clarity'
	},
	{
		text: 'Alignment',
		'value': 'Alignment'
	},
	{
		text: 'Resilience',
		'value': 'Resilience'
	},
	{
		text: 'Impact',
		'value': 'Impact'
	},
	{
		text: 'Trust',
		'value': 'Trust'
	},
	{
		text: 'Communication',
		'value': 'Communication'
	},
	{
		text: 'Enjoyment',
		'value': 'Enjoyment'
	},
	{
		text: 'Motivation',
		'value': 'Motivation'
	},
	{
		text: 'Curiosity',
		'value': 'Curiosity'
	},
	{
		text: 'Stress',
		'value': 'Stress'
	},
	{
		text: 'Discontent',
		'value': 'Discontent'
	},
	{
		text: 'Anger',
		'value': 'Anger'
	}
]

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
}


const UserExplore = () => {
	const dispatch = useDispatch();
	// const [scoreData, setScoreData] = useState([]);
	const { height, width } = useWindowDimensions();
	const [hide, setHide] = useState(width < 768 ? true : false)
	const [selEmp, setSelEmp] = useState(false)

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const showEmployee = params.get('show-employee') ? params.get('show-employee') : false

	const [updateData, setUpdateData] = useState();
	const [empList, setEmpList] = useState([])
	const [empSortOn, setEmpSortOn] = useState('Overall')
	const [employeesFiltered, setEmployeesFiltered] = useState([]);
	const [employee, setEmployee] = useState(false)
	const [employeeRole, setEmployeeRole] = useState(0);
	const [employeeId, setEmployeeId] = useState(0);
	const [organizationId, setOrganizationId] = useState();
	const [loading, setLoading] = useState(true)

	const [teamList, setTeamList] = useState([])
	const [selectedTeam, setSelectedTeam] = useState(false)
	const [selectedTeamId, setSelectedTeamId] = useState(false)
	// // // // //
	const EmployeeId = useSelector((state) => state.auth?.employee_id, shallowEqual)
	const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)
	// const OrgId = useSelector((state) => state.get_selectedOrg?.organization?.id, shallowEqual)
	const [cultureData, setCultureData] = useState([])
	const [moodData, setMoodData] = useState([])

	const { culture_data, mood_data, employees_data,
		get_employee, get_auth,
		get_teams,
		get_team_access,
		get_selectedOrg } = useSelector(
			(state) => ({
				culture_data: state.culture.culture,
				mood_data: state.moods.moods,
				employees_data: state.employees.employees,
				get_auth: state.auth,
				get_employee: state.employees,
				get_teams: state.teams.teams,
				get_selectedOrg: state.selectedOrg,
				get_team_access: state.team_access.team_access
			}),
			shallowEqual
		);

	useEffect(() => {
		setOrganizationId(get_selectedOrg?.organization?.id)
	}, [get_selectedOrg]);
	// console.log(employees_data)

	useEffect(() => {
		// Run! Like go get some data from an API.
		// dispatch({ type: SG_GET_MOODS });
		let teamQuery = ''

		if (Number(AmbassadorId) > 0) {

			if (organizationId) {
				if (selectedTeamId) {
					teamQuery = `&team=${selectedTeamId}`
				}
				dispatch({
					type: LOAD_EMPLOYEES,
					payload: `organization=${organizationId}${teamQuery}`
				});
				dispatch({
					type: LOAD_TEAMS,
					payload: `organization=${organizationId}`
				});
				dispatch({
					type: SG_GET_CULTURE_DATA,
					payload: `organization=${organizationId}`
				});
				dispatch({
					type: SG_GET_MOODS,
					payload: `organization=${organizationId}`
				});
				return
			}
		}
		else {
			if (selectedTeamId) {
				teamQuery = `team=${selectedTeamId}`
			}
			dispatch({
				type: LOAD_EMPLOYEES,
				payload: teamQuery
			});
			dispatch({ type: LOAD_TEAMS });
			dispatch({
				type: SG_GET_CULTURE_DATA,
				payload: teamQuery
			});
			dispatch({
				type: SG_GET_MOODS,
				payload: teamQuery
			});
			dispatch({
				type: SG_GET_TEAM_ACCESSS
			});
		}

	}, [dispatch, AmbassadorId, get_selectedOrg,
		organizationId, selectedTeamId]);

	// // // // 
	// console.log(get_employee)
	// console.log(get_auth?.employee_id)

	useEffect(() => {
		if (get_employee) {
			setEmployee(get_employee[get_auth?.employee_id]);
			setEmployeeRole(get_employee[get_auth?.employee_id]?.role)
			setEmployeeId(Number(get_auth?.employee_id))
		}
	}, [get_employee, get_auth]);


	useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setTeamList(get_teams)
			return
		}
		if (employee?.account_type === 5) {
			setTeamList(get_teams)
			return
		}
		// setTeamList(get_teams.filter(tm => tm.id === employee?.team))
		if (employee?.role === 5) {
			const _TeamList = []
			_TeamList.push(
				get_teams.filter(
					tm => tm.id === employee?.team)[0]

			)
			get_team_access.filter(
				ta => ta.employee === employee?.id
			).map(tm =>
				_TeamList.push(get_teams.filter(
					gt => gt.id === tm?.team)[0]
				)
			)
			// console.log(_TeamList)
			setTeamList(_TeamList)
			return
		}

	}, [get_teams, employee, AmbassadorId, get_team_access]);

	// console.log(get_team_access)



	useEffect(() => {
		if (EmployeeId && !selectedTeamId) {
			setSelectedTeam(get_teams.filter(et => et.id === employee?.team)[0])
		}
	}, [get_teams, EmployeeId, employee, selectedTeamId]);

	useEffect(() => {
		const EmployeeList = []
		// console.log(culture_data[1])
		// console.log(employees_data)	
		// if (!employees_data) {
		// 	return
		// }

		// if (mood_data.length === 0) {
		// 	return
		// }

		employees_data.map(p => {
			const MoodData = mood_data[0]?.category_detailed.filter(
				mo => mo.employee_id === p.id)

			const CultureData = culture_data[0]?.responses.filter(
				cl => cl.employee_id === p.id)[0]

			const MoodScoreData = mood_data[0]?.employee_comments.filter(
				mo => mo.employee_id === p.id)[0]

			// console.log(MoodScoreData)
			// if (!MoodData) {
			// 	return null
			// }
			// if (!CultureData) {
			// 	return null
			// }
			// console.log(p)
			return EmployeeList.push(
				{
					team: p.team,
					employee_id: p.id,
					employee: p,
					'Overall': ((CultureData?.emp_average + MoodScoreData?.mood_score) / 2) ? (CultureData?.emp_average + MoodScoreData?.mood_score) / 2 : 0,
					'Team Health': CultureData?.emp_average ? CultureData?.emp_average : 0,
					'Emotional Health': MoodScoreData?.mood_score ? MoodScoreData?.mood_score : 0,
					'Clarity': CultureData?.responses.filter(cl => cl.name === 'Clarity')[0]?.score ? CultureData?.responses.filter(cl => cl.name === 'Clarity')[0]?.score : 0,
					'Alignment': CultureData?.responses.filter(cl => cl.name === 'Alignment')[0]?.score ? CultureData?.responses.filter(cl => cl.name === 'Alignment')[0]?.score : 0,
					'Resilience': CultureData?.responses.filter(cl => cl.name === 'Resilience')[0]?.score ? CultureData?.responses.filter(cl => cl.name === 'Resilience')[0]?.score : 0,
					'Impact': CultureData?.responses.filter(cl => cl.name === 'Impact')[0]?.score ? CultureData?.responses.filter(cl => cl.name === 'Impact')[0]?.score : 0,
					'Trust': CultureData?.responses.filter(cl => cl.name === 'Trust')[0]?.score ? CultureData?.responses.filter(cl => cl.name === 'Trust')[0]?.score : 0,
					'Communication': CultureData?.responses.filter(cl => cl.name === 'Communication')[0]?.score ? CultureData?.responses.filter(cl => cl.name === 'Communication')[0]?.score : 0,
					'Enjoyment': MoodData?.filter(md => md.category === 'Enjoyment')[0]?.score ? MoodData.filter(md => md.category === 'Enjoyment')[0]?.score : 0,
					'Motivation': MoodData?.filter(md => md.category === 'Motivation')[0]?.score ? MoodData.filter(md => md.category === 'Motivation')[0]?.score : 0,
					'Curiosity': MoodData?.filter(md => md.category === 'Curiosity')[0]?.score ? MoodData.filter(md => md.category === 'Curiosity')[0]?.score : 0,
					'Stress': MoodData?.filter(md => md.category === 'Stress')[0]?.score ? MoodData.filter(md => md.category === 'Stress')[0]?.score : 0,
					'Discontent': MoodData?.filter(md => md.category === 'Discontent')[0]?.score ? MoodData.filter(md => md.category === 'Discontent')[0]?.score : 0,
					'Anger': MoodData?.filter(md => md.category === 'Anger')[0]?.score ? MoodData.filter(md => md.category === 'Anger')[0]?.score : 0
				})
		}
		)

		EmployeeList.sort((a, b) => parseFloat(a['Team Health']) - parseFloat(b['Team Health']));

		const timer2 = setTimeout(() => setLoading(false), 1000)
		const timer1 = setTimeout(() => {
			setUpdateData(false)
		}, 2000)
		
		if (Number(AmbassadorId) > 0) {
			setEmpList(EmployeeList.filter(tm => tm.team === selectedTeamId))
			// setSelEmp(EmployeeList[0]?.employee)
			setSelEmp(false)
			setEmployeeId(0)
			setCultureData([])
			setMoodData([])
			return
		}
		if (employeeRole < 9) {
			// console.log("No EmployeeList employeeRole < 9")
			setEmpList(EmployeeList.filter(tm => tm.team === (selectedTeamId > 0 ? selectedTeamId : employee?.team)))
			setSelEmp(EmployeeList.filter(tm => tm.team === (selectedTeamId > 0 ? selectedTeamId : employee?.team))[0]?.employee)
			return
		}
		if (employeeRole > 8) {
			// console.log("No EmployeeList employeeRole > 8")
			setEmpList(EmployeeList.filter(tm => tm.team === employee?.team))

			setSelEmp(EmployeeList[0]?.employee)
			return
		}

		return () => clearTimeout(timer1, timer2)

	}, [culture_data, mood_data, employees_data,
		AmbassadorId, employee, teamList,
		selectedTeamId, employeeRole])

	useEffect(() => {
		if (!selectedTeamId) {
			setUpdateData(true)
			setSelectedTeamId(teamList[0]?.id)
			setSelectedTeam(teamList[0])
			const timer1 = setTimeout(() => {
				setUpdateData(false)
			}, 2000)
			return () => clearTimeout(timer1)
		}

	}, [teamList, selectedTeamId]);
	// console.log(employeeRole)
	// console.log(empList)
	// console.log(employee)
	// console.log(selectedTeamId)

	function OnEmpSelected(e) {
		setSelEmp(e)
		setCultureData(culture_data)
		setMoodData(mood_data)
	}

	function ChangeOrder(e, data) {
		// console.log(data.value);
		setEmpSortOn(data.value)
		let EmployeeList = empList.sort((a, b) => parseFloat(b[data.value]) - parseFloat(a[data.value]));
		setEmpList(EmployeeList)
	}
	// console.log(empList)
	// console.log(employees_data)

	useEffect(() => {
		if (Number(AmbassadorId)) {
			setEmployeesFiltered(empList)
			return
		}
		if (employeeRole < 9) {
			setEmployeesFiltered(empList)
			return
		}
		setEmployeesFiltered(empList.filter(em => em.employee_id === employeeId))
		setSelEmp(get_employee[get_auth?.employee_id])

	}, [employeeId, employeeRole, empList, get_employee, AmbassadorId, get_auth]);

	function UpdateSelectedTeam(e) {
		// console.log(e)
		setLoading(true)
		setSelectedTeamId(e.id)
		setSelectedTeam(e)
		setCultureData([])
		setMoodData([])
		setSelEmp(false)
	}
	// console.log(selEmp)
	// console.log(employeesFiltered)
	useEffect(() => {
		setCultureData(culture_data)
		setMoodData(mood_data)
	}, [selEmp, culture_data, mood_data]);

	// console.log(moodData)
	// console.log(cultureData)
	// console.log(selEmp)
	return (

		<Container>

			<>
				<Left hide={hide}>

					<Select
						placeholder="Select Team"
						onChange={UpdateSelectedTeam}
						value={selectedTeam}
						options={teamList}
						// isLoading={loading}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
					/>

					<Filter>
						<Hide onClick={() => setHide(!hide)}>{hide ? 'Show' : 'Hide'}</Hide>
						<Hidden hide={hide}>
							<Dropdown
								icon='sort amount down'
								inline
								scrolling
								options={options}
								defaultValue={options[0].value}
								className='icon'
								onChange={ChangeOrder}
								direction='left'
							/>
						</Hidden>
					</Filter>
					<Segment>
						<Dimmer active={updateData}>
							<Loader size='mini'>Loading</Loader>
						</Dimmer>
						{employeesFiltered &&
							employeesFiltered.map((p, k) => {
								return <User2
									employee={p.employee}
									selected={selEmp?.id === p?.employee?.id}
									hide={hide}
									data={p}
									key={k}
									empSortOn={empSortOn}
									OnEmpSelected={OnEmpSelected} />
							})
						}
					</Segment>


				</Left>



				<Profile
					hide={hide}
					selEmp={selEmp}
					cultureData={cultureData}
					moodData={moodData}
					empList={empList}
					employeeId={employeeId}
					employeeRole={employeeRole}
					loading={loading}
					selectedTeam={selectedTeam}
				/>

			</>
		</Container>
	)
}


export default UserExplore


const Left = styled.div`
	width:${props => props.hide ? '60px' : '250px'};
	transition:all 500ms;
	border-right:1px solid #e9e9e9;
	height:calc(100vh - 55px);
	padding-top:10px;
	padding-right:10px;
	overflow-y:scroll;

	::-webkit-scrollbar {
		  width: 5px;
		}

	::-webkit-scrollbar-track {
  background: white;        /* color of the tracking area */
}


`

const Container = styled.div`
	display:flex;
	justify-content:space-between;
	margin-top:15px;
	width:100%;
`

const Filter = styled.div`
	display:flex;
	width:100%;
	justify-content:space-between;
	margin-bottom:20px;
	margin-top:10px;


`

const Hide = styled.div`
	text-transform:uppercase;
	font-family: 'Red Hat Display', sans-serif;
	color:#9b9b9b;
	 font-size: 12px;
  	font-weight: bold;
  	cursor:pointer;
;
`

const Hidden = styled.div`
	display:${props => props.hide ? 'none' : 'auto'};

`