import {
  PENDING_EMPLOYEE_FILE_UPLOAD,
  GET_EMPLOYEE_FILE_UPLOAD,
  DELETE_EMPLOYEE_FILE_UPLOAD,
  FETCH_EMPLOYEE_FILE_UPLOAD,
  EDIT_EMPLOYEE_FILE_UPLOAD,
  ADD_EMPLOYEE_FILE_UPLOAD,
  CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD,
  CLEAR_EMPLOYEE_FILE_UPLOAD,
  CLEAR_CREATED_FILE_UPLOAD,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  employee_fileuploads: [],
  selectedFile: false,
  updated: false,
  pending: false,
};

export default function EmployeeFileUpload(state = initialState, action) {
  switch (action.type) {
    case PENDING_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        selectedFile: false,
        updated: false,
        pending: true,
      };
    case GET_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        employee_fileuploads: action.payload,
        pending: false,
      };

    case FETCH_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        selectedFile: action.payload,
        pending: false,
      };

    case CLEAR_CREATED_FILE_UPLOAD:
      return {
        ...state,
        pending: false,
      };

    case EDIT_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        [action.payload.id]: action.payload,
        updated: Math.random(),
        pending: false,
      };

    case DELETE_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        updated: Math.random(),
        pending: false,
      };

    case ADD_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        updated: `added-${Math.random()}-${action.payload}`, // Use Math.random() to ensure a new value
        pending: false,
      };
    case CLEAR_SELECTED_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        selectedFile: false,
      };
    case CLEAR_EMPLOYEE_FILE_UPLOAD:
      return {
        ...state,
        ...initialState,
      };

    case CLEAR_ALL:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
}