import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SG_CREATE_PAYMENT_METHOD, UPDATE_EMPLOYEE } from "constants/actions";

// function createPaymentMethod(cardElement, customerId, priceId) {
//   return stripe
//     .createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//     })
//     .then((result) => {
//       if (result.error) {
//         displayError(error);
//       } else {
//         createSubscription({
//           customerId: customerId,
//           paymentMethodId: result.paymentMethod.id,
//           priceId: priceId,
//         });
//       }
//     });
// }

// function createSubscription(customerId, paymentMethodId, priceId) {
//   return (
//     fetch('/create-subscription', {
//       method: 'post',
//       headers: {
//         'Content-type': 'application/json',
//       },
//       body: JSON.stringify({
//         customerId: customerId,
//         paymentMethodId: paymentMethodId,
//         priceId: priceId,
//       }),
//     })
//       .then((response) => {
//         return response.json();
//       })
//       // If the card is declined, display an error to the user.
//       .then((result) => {
//         if (result.error) {
//           // The card had an error when trying to attach it to a customer.
//           throw result;
//         }
//         return result;
//       })
//       // Normalize the result to contain the object returned by Stripe.
//       // Add the additional details we need.
//       .then((result) => {
//         return {
//           paymentMethodId: paymentMethodId,
//           priceId: priceId,
//           subscription: result,
//         };
//       })
//       // Some payment methods require a customer to be on session
//       // to complete the payment process. Check the status of the
//       // payment intent to handle these actions.
//       .then(handlePaymentThatRequiresCustomerAction)
//       // No more actions required. Provision your service for the user.
//       .then(onSubscriptionComplete)
//       .catch((error) => {
//         // An error has happened. Display the failure to the user here.
//         // We utilize the HTML element we created.
//         showCardError(error);
//       })
//   );
// }

export default function CheckoutForm({ employeeId }) {
  const dispatch = useDispatch();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);

  const stripe = useStripe();
  const elements = useElements();



  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    return stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then((result) => {
        if (result.error) {
          setError(error);
          setProcessing(false);
          setSucceeded(false);
        } else {
        
        //  dispatch({
        //     type: UPDATE_EMPLOYEE,
        //     payload: {
        //       id: employeeId,
        //       payment_method_id: result.paymentMethod.id,
        //     },
        //   });

          dispatch({
            type: SG_CREATE_PAYMENT_METHOD,
            payload: {
              employee: employeeId,
              payment_method: result.paymentMethod.id,
            },
          });

          setProcessing(false);
          setSucceeded(true);
        }
      });
  };

  // console.log(result);

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={handleChange}
        />
        <button
          className="stripe-button"
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          <span id="button-text">
            {processing ? <div className="spinner" id="spinner"></div> : "Pay"}
          </span>
        </button>
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
        <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded, see the result in your
          <a href={`https://dashboard.stripe.com/test/payments`}>
            {" "}
            Stripe dashboard.
          </a>{" "}
          Refresh the page to pay again.
        </p>
      </form>
    </>
  );
}
