import React from 'react';
import styled from 'styled-components';
import {Icon} from 'semantic-ui-react'

// Styled components
const TrackerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:60px;
justify-content:space-between;
width:100%;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width:33%;
`;

const StepNumber = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid ${props => props.completed ? 'green' : 'gray'};
  background: ${props => props.completed ? 'green' : 'white'};
  color: ${props => props.completed ? 'white' : 'black'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StepDescription = styled.div`
    margin-top:5px;
`;

const StepLine = styled.div`
  height: 2px;
  background: ${props => props.completed ? 'green' : 'gray'};
  position: absolute;
  top: 14px;
  width:100%;
  right:-50%;
  z-index: 0;
`;

// The React component
const ProgressTracker = ({current_step}) => {
  return (
    <TrackerContainer>
      {steps.map((step, index) => (
        <Step key={index}>
            {index<2 && <StepLine completed={index<current_step} />}
          <StepNumber completed={index<=current_step}>{index<current_step? <Icon name="check" style={{marginRight:-1,marginTop:-3}}/>:index + 1}</StepNumber>
          {step.description && <StepDescription>{step.description}</StepDescription>}
        </Step>
      ))}
    </TrackerContainer>
  );
};

export default ProgressTracker;
// Usage example with props
const steps = [
  { completed: true, description: 'Import file' },
  { completed: false, description: 'Map columns' },
  { completed: false, description: 'Import' },
];