import React, { useEffect, useState } from 'react';
import styled,{keyframes,createGlobalStyle} from 'styled-components';
import { useSelector } from 'react-redux';
import { get_ROI_params } from './paramCalculation';
import { Icon } from 'semantic-ui-react';
import Chart from 'react-apexcharts';
import { G } from '@react-pdf/renderer';

const ROIDiagnostic = ({ onOutcomeSelected, goBack, data }) => {
  const [chartData, setChartData] = useState(null);

  const core_data = useSelector((state) => state.audit?.core_data);
  const get_organizations = useSelector((state) => state.organizations);
  const get_auth = useSelector((state) => state.auth);
  const get_survey_questions = useSelector((state) => state.surveyquestions);

  useEffect(() => {
    if (core_data && data) {
      const organizationData = get_organizations[
        get_auth?.organization_id || get_organizations?.organization?.id
      ];
      if (organizationData) {
        const sortOrder = organizationData?.styling?.survey_sequence?.find(
          (item) => item.value === 'outcome_question'
        )?.question?.sort_order;

        if (sortOrder) {
          const outcomeQ = get_survey_questions?.outcome_questions.find(
            (item) => item.sort_order === sortOrder
          );

          if (outcomeQ) {
            // Prepare and sort chart data
            const formattedChartData = outcomeQ.questions
              .map((item) => {
                const roi_data = get_ROI_params(item.id, item.name, core_data, data);
                const impact = roi_data[0]?.impact || 0;
                const averageScore = roi_data[0]?.average || 0;
                return {
                  x: item.name,
                  y: parseFloat((impact * 100).toFixed(1)), // ROI Impact as a percentage
                  factorId: item.id,
                  roi_data, // Store the original ROI data to pass on click
                };
              })
              .sort((a, b) => b.y - a.y); // Sort from high to low by ROI impact
              
            setChartData(formattedChartData);
          }
        }
      }
    }
  }, [core_data, data, get_organizations, get_auth, get_survey_questions]);

  if (!chartData) {
    return (
      <div className="roi-diagnostic">
        <h2>ROI-Based Predictive Model Diagnostic</h2>
        <p>Loading data...</p>
      </div>
    );
  }

  // Event handler for data point selection
  const handleDataPointSelection = (event, chartContext, config) => {
    const { dataPointIndex } = config;
    const dataPoint = chartData[dataPointIndex];
    if (dataPoint) {
      // Recalculate roi_data based on factorId to ensure the exact data is passed
      const roi_data = get_ROI_params(dataPoint.factorId, dataPoint.x, core_data, data);
      onOutcomeSelected(dataPoint.factorId, dataPoint.x, roi_data);
    }
  };

  // Chart options with updated tooltip to include average score
  const options = {
    chart: {
      height: 400,
      type: 'bar',
      id: 'roi-chart',
      fontFamily: 'Raleway, sans-serif',
      foreColor: '#7E7E7E',
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: handleDataPointSelection,
      },
    },
    colors: ['#2D70E2'],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        barHeight: '70%',
      },
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
        maxHeight: 100,
        formatter: (val) => (val.length > 30 ? `${val.substring(0, 27)}...` : val),
      },
      title: {
        text: 'Outcome Factors',
      },
    },
    yaxis: {
      title: {
        text: 'Predicted best ROI improvement (%)',
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'ROI Impact by Outcome Factor',
      align: 'center',
    },
  };

  const series = [
    {
      name: 'ROI Impact',
      data: chartData,
    },
  ];

  return (
    <div className="roi-diagnostic">
      <BackButton onClick={goBack}>
        <Icon name="chevron left" /> Back
      </BackButton>
      <Title>ROI-Based Predictive Model Diagnostic</Title>
      <Description>
        Based on our predictive model, we’ve identified key culture factors with the potential to yield the highest ROI.
        Select a factor to view details on expected ROI and recommended actions.
      </Description>
      <Chart options={options} series={series} type="bar" height={400} />
      <GlobalStyle />
    </div>
  );
};

export default ROIDiagnostic;




const DiagnosticCard = ({title,description,onClick,best,recommended,roiScore }) =>{
    return (
        <Card onClick={onClick}>
      {recommended && (
        <Recommended>
          Innerlogic recommends
        </Recommended>
      )}
      <CardTitle>{title}</CardTitle>

      <CardDescription>{description}</CardDescription>

      <CardDescription>
        <strong>Projected ROI Improvement:</strong> {roiScore}
      </CardDescription>

      <CardDescription>
        <strong>Best For:</strong> {best}
      </CardDescription>
    </Card>
    )
}

const GlobalStyle = createGlobalStyle`
  .apexcharts-bar-area {
    cursor: pointer;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;



const Container = styled.div`
  padding: 20px;
  opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


const Card = styled.div`
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 20px;
    font-family: Raleway, sans-serif;
    cursor: pointer;
    width:100%;
    margin-bottom:10px;
    position:relative;

    &:hover {
        background-color: #f9f9f9;
    }
`

const CardTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`

const CardDescription = styled.div`
    font-size: 14px;
    margin-bottom: 10px;
`

const Recommended = styled.div`
    position:absolute;
    top:20px;
    right:10px;
    font-size: 12px;
    background-color: #2D70E2;
    padding:3px 10px;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    font-family: Raleway, sans-serif;

`

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
`