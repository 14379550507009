import styled, { keyframes } from "styled-components";
import React from "react";
import { Checkbox, Form,Dropdown,Input} from 'semantic-ui-react'
import DateTimePicker from 'react-datetime-picker';

const Schedule = () =>{

	const [type,setType] = React.useState(null)
	const [value, onChange] = React.useState(new Date());

	return (
		<Container>
			<div>
			<Title>Schedule a Debrief</Title>
			
			<Form>
				 <Form.Group inline>
		          <label>Type</label>
		          <Form.Radio
		            label='Game'
		            value='Game'
		            checked={type === 'Game'}
		            onChange={(e,{value})=>setType(value)}
		          />
		          <Form.Radio
		            label='Practice'
		            value='Practice'
		            checked={type === 'Practice'}
		            onChange={(e,{value})=>setType(value)}
		          />
		        </Form.Group>


		        	
		
			    
			 </Form>
			 <FormContainer>
				 <Label>Team</Label>
					<Dropdown
				    placeholder='Select a team'
				    fluid
				    selection
				    options={friendOptions}
				  />
			</FormContainer>


			 <FormContainer>
			 	<Label>Included team members</Label>
					<Checkbox label='Members'  style={{marginRight:10}} checked={true}/>
					<Checkbox label='Leaders' />
			</FormContainer>

			 <FormContainer>

			 <Label>Select a date and time</Label>
			 <DateTimePicker
				        onChange={onChange}
				        value={value}
				      />
			</FormContainer>

			 <FormContainer>
			<Label>Select time zone</Label>
				<Dropdown
			    placeholder='Select time zone'
			    fluid
			    selection
			    options={friendOptions}
			  />
			  </FormContainer>

			  <FormContainer>

			  <Label>After how many hours should the survey close?</Label>
			  <Input placeholder='# of hours' />
			  </FormContainer>

			  <Submit>Schedule</Submit>
			  </div>
		</Container>
		)
}


export default Schedule


const Container = styled.div`
	margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
	padding:30px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:400px;
	display:flex;
	align-items:center;
	flex-direction:column;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-bottom:30px;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
	max-width:300px;
	margin-top:20px;
`

const Submit = styled.div`
	border-radius: 25px;
  background-color: #6a00ff;
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:pointer;

`

const friendOptions = [
  {
    key: 'Jenny Hess',
    text: 'Jenny Hess',
    value: 'Jenny Hess',
    image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
  },
  {
    key: 'Elliot Fu',
    text: 'Elliot Fu',
    value: 'Elliot Fu',
    image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
  },
  {
    key: 'Stevie Feliciano',
    text: 'Stevie Feliciano',
    value: 'Stevie Feliciano',
    image: { avatar: true, src: '/images/avatar/small/stevie.jpg' },
  },
  {
    key: 'Christian',
    text: 'Christian',
    value: 'Christian',
    image: { avatar: true, src: '/images/avatar/small/christian.jpg' },
  },
  {
    key: 'Matt',
    text: 'Matt',
    value: 'Matt',
    image: { avatar: true, src: '/images/avatar/small/matt.jpg' },
  },
  {
    key: 'Justen Kitsune',
    text: 'Justen Kitsune',
    value: 'Justen Kitsune',
    image: { avatar: true, src: '/images/avatar/small/justen.jpg' },
  },
]