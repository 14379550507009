import DatePicker from "react-datepicker";
import styled, { keyframes } from "styled-components";
import React, { forwardRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { calendarIcon } from "components/Icons";
import { format } from "date-fns";

const Timelines = (props) => {
  const {
    dateStart,
    dateEnd,
    setStartDate,
    setEndDate,
    showLabels,
    editEnabled,
    showStartDateError,
    showEndDateError,
    isTable,
  } = props;
  const TextInputStart = forwardRef(({ value, onClick }, ref) => (
    <TextboxContainer first={true} onClick={onClick} editEnabled={editEnabled}>
      {calendarIcon()}
      <DateText>{value || "Start date"}</DateText>
    </TextboxContainer>
  ));

  const TextInputEnd = forwardRef(({ value, onClick }, ref) => (
    <TextboxContainer onClick={onClick} editEnabled={editEnabled}>
      {calendarIcon()}
      <DateText>{value || "End date"}</DateText>
    </TextboxContainer>
  ));

  const startDate = (dateStart && new Date(dateStart)) || null;
  const endDate = (dateEnd && new Date(dateEnd)) || null;

  return (
    <Container>
      {!isTable && <TimelinesText>Timelines</TimelinesText>}
      <DateContainer isTable={isTable}>
      {showLabels && <DateLabel>Start date:</DateLabel>}
      {(editEnabled && (
        <ComponentWrapper>
        <DatePicker
          showLabels={showLabels}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          minDate={new Date()}
          customInput={<TextInputStart />}
          />
          {showStartDateError && <ErrorLabel>This field is required</ErrorLabel>}
          </ComponentWrapper>
      )) || (
        <TextboxContainer editEnabled={editEnabled}>
          {calendarIcon()}
          <DateText>{format(new Date(dateStart), "yyyy-MM-dd")}</DateText>
        </TextboxContainer>
      )}

      {showLabels && <DateLabel>End date:</DateLabel>}
      {(editEnabled && (
        <ComponentWrapper>
        <DatePicker
          showLabels={showLabels}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          minDate={new Date()}
          customInput={<TextInputEnd />}
          />
          {showEndDateError && <ErrorLabel>This field is required</ErrorLabel>}
          </ComponentWrapper>
      )) || (
        <TextboxContainer editEnabled={editEnabled}>
          {calendarIcon()}
          <DateText>{format(new Date(dateEnd), "yyyy-MM-dd")}</DateText>
        </TextboxContainer>
          )}
        </DateContainer>
    </Container>
  );
};

export default Timelines;

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
`;

const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.isTable ? "column" : "row")};
  align-items: ${(props) => (props.isTable ? "flex-start" : "center")};
`;

const TextboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.editEnabled ? "pointer" : "default")};
  min-width: 150px;
  padding-top: 5px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
`;

const DateText = styled.div`
  color: #666d79;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-left: 10px;
`;

const DateLabel = styled.div`
  color: #666d79;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-bottom: -10px;
  margin-top: 5px;
`;

const ErrorLabel = styled.div`
  color: #ff0000;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
  /* Caption/Semibold 14 */
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  margin-bottom: -10px;
  margin-top: 5px;
`;

const TimelinesText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;

  margin-top: 20px;
`;