import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import { useNavigate } from 'react-router-dom';
import {Icon} from 'semantic-ui-react'
import Box from './Box2'
import IMG from 'assets/images/stock/plan.jpg'
import IMG2 from 'assets/images/stock/draft_plan.jpg'
import IMG3 from 'assets/images/stock/active_plan.jpg'


const Dashboard = ({ create, plans, setSelectedPlan }) => {
  const [tab, setTab] = useState(0);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Container>

        <Center>
            <Back onClick={()=>navigate('/simple/prototype/simpleDash')}> <Icon name='arrow left'/> Back to dashboard</Back>
            <Title>Planing</Title>

            {/* <Box 
                onClick={()=>navigate('/simple/prototype/draft/create')}
                title={'Start a new plan'}
                icon={'sync'}
                description={'Build a culture action plan based on your culture data. Our ai action planner will help you every step of the way.'}/> */}

            <Box 
                title={'Draft action plans'}
                num={1}
                onClick={()=>navigate('/simple/prototype/draft/drafts')}
                IMG={IMG}
                color={'#2254AA'}
                buttonText={'Drafts'}
                description={'Build an action plan based on your people data. Our ai action planner will help you every step of the way.'}/>


            <Box 
                title={'Active plans'}
                buttonText={'Active'}
                IMG={IMG2}
                color={"#2D70E2"}
                num={2}
                onClick={()=>navigate('/simple/prototype/draft/drafts')}
                description={"Access the plans you've already built to track your progress, and accomplish your milestones."}/>

            <Box 
                title={'Completed plans'}
                buttonText={'Completed'}

                IMG={IMG3}
                color={"#27CDA7"}
                num={8}
                onClick={()=>navigate('/simple/prototype/draft/drafts')}
                description={'View previously completed plans for reflection and future inspiration.'}/>


        </Center>

      {/* <Title>
        Plan Dashboard
        <Button onClick={() => setOpen(true)}>Generate Draft</Button>
      </Title> */}

     
      {/* <Tabs>
        <Tab selected={tab == 0} onClick={() => setTab(0)}>
          My Plans
        </Tab>
        <Tab selected={tab == 1} onClick={() => setTab(1)}>
          Peer Plans
        </Tab>
        <Tab selected={tab == 2} onClick={() => setTab(2)}>
          Notifications
        </Tab>
      </Tabs> */}

      {/* <Content>
        {tab == 0 ? (
          <>
            <Title>Planning</Title>
            <PlanArea>
              {plans?.map((p) => {
                return (
                  <Plan
                    onClick={() => {
                      setSelectedPlan(p.recommendation);
                      create("Collab");
                    }}
                  >
                    <Factor>{p?.factor}</Factor>
                    <PlanTitle>{p?.title}</PlanTitle>

                    <BottomComponents>
                      <Progress
                        percent={50}
                        size="tiny"
                        style={{ marginBottom: 10 }}
                        color="blue"
                      />
                      <Bottom>
                        <BottomText>Progress</BottomText>
                        <ProgressText>50%</ProgressText>
                      </Bottom>
                    </BottomComponents>
                  </Plan>
                );
              })}
            </PlanArea>

            <Title>Active</Title>

            <PlanArea>
              <Plan onClick={() => create("EditPlan")}>
                <Factor>Psychological Safety</Factor>
                <PlanTitle>
                  Improving the outcomes from the team's retrospectives
                </PlanTitle>

                <BottomComponents>
                  <Progress
                    percent={20}
                    size="tiny"
                    style={{ marginBottom: 10 }}
                    color="blue"
                  />
                  <Bottom>
                    <BottomText>Progress</BottomText>
                    <ProgressText>20%</ProgressText>
                  </Bottom>
                </BottomComponents>
              </Plan>
            </PlanArea>

            <Title>Completed</Title>

            <PlanArea>
              <Plan>
                <Factor>Psychological Safety</Factor>
                <PlanTitle>
                  Improving the outcomes from the team's retrospectives
                </PlanTitle>

                <BottomComponents>
                  <Bottom>
                    <BottomText>Plan Impact</BottomText>
                    <ProgressText>High</ProgressText>
                  </Bottom>

                  <Bottom>
                    <BottomText>Total Effect</BottomText>
                    <ProgressText>+4%</ProgressText>
                  </Bottom>
                </BottomComponents>
              </Plan>
            </PlanArea>
          </>
        ) : (
          <Peer />
        )}
      </Content> */}

     
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  padding-left: 58px;
  padding-right: 40px;
  margin-top: 0px;
  font-family: "Raleway";
  display:flex;
    flex-direction:column;
    align-items:center;
`;

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`

const SubTitle = styled.div`
    font-size:14px;
    color:#666D79;
    margin-bottom:15px;
`

const Bubble = styled.div`
  width: 300px;
  border-radius: 10px;
  padding: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const BubbleText = styled.div`
  font-size: 14px;
  font-family: "Raleway";
  font-weight: bold;
`;

const Num = styled.div`
  font-size: 40px;
  font-family: "Raleway";
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 40px;
`;
const Tab = styled.div`
  font-size: 14px;
  font-family: "Raleway";
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  margin-right: 20px;
  padding-bottom: 10px;
  border-bottom: ${(props) => (props.selected ? "2px solid #2D70E2" : "none")};
  cursor: pointer;
  color: ${(props) => (props.selected ? "#2D70E2" : "#A3A3A3")};
`;

const Content = styled.div`
  margin-top: 40px;
`;

const PlanArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss09" on, "ss01" on, "salt" on, "ss08" on, "ss02" on,
    "ss07" on, "ss05" on, "liga" off;
`;

const Plan = styled.div`
  width: 300px;
  height: 240px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
  margin-right: 5px;
`;

const Factor = styled.div`
    font-size:12px;
    font-family:'Raleway';
    color:grey;
    margin-bottom:15px;

`

const PlanTitle = styled.div`
    font-size:20px;
    font-family:'Raleway';
    font-weight:500;
    line-height:1.2;

`

const Bottom = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:5px;

`

const BottomText = styled.div`
    font-size:14px;
    font-family:'Raleway';
    color:grey;
`
const ProgressText = styled.div`
    font-size:14px;
    font-family:'Raleway';
    color:grey;
    font-weight:bold;
`

const BottomComponents = styled.div`
    position:absolute;
    bottom:20px;
    left:20px;
    width:calc(100% - 40px);
`

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
    font-size:14px;
`


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Center = styled.div`
    width:700px;
    margin-top:50px;
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Back = styled.div`
    font-size:14px;
    font-family:'Raleway';
    margin-bottom:20px;
    cursor:pointer;
`
