import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import Timeline from "./Timeline";

const Calendar = () => {


    return (

        <Container>
            <ChartContainer>
                <Title>Predicted Culture Optimizaiton</Title>
            <Chart
					options={data3.options}
					series={data3.series}
					type="area"
					height={300}
				/>
            </ChartContainer>
        <Timeline/>
           
        </Container>
    )

    }

export default Calendar;

const Container = styled.div`
    padding-left:58px;
    margin-top:20px;
`


const ChartContainer = styled.div`
    width:600px;
    padding:20px;

`
const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    font-family: 'Poppins', sans-serif;
`




const data3 = {
	series: [
		{
			name: "Predicted",
			type: "area",
			data: [0, 5, 10, 15],
		},
		{
			name: "Trend",
			type: "area",
			data: [0,-2, -5, -7],
		},
	],
	options: {
		chart: {
			height: 350,
			type: "line",
			toolbar: {
				show: false,
			},
		},
		annotations: {
			points: [
				{
					x: "Q2 2021",
					y: 15,
					marker: {
						size: 8,
						fillColor: "#3260a8",
					},
					label: {
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
						text: "+15%",
					},
				},
			],
		},

		dataLabels: {
			enabled: false,
		},
		colors: ["#3260a8", "#a84832"],
		stroke: {
			dashArray: [0, 0, 4],
			curve: "smooth",
			width: [0, 0, 3],
		},
		fill: {
			type: ["solid", "solid"],
			opacity: [0.3, 0.3, 0.2],
		},
		yaxis: {
            show:false,
			min: -20,
			max: 20,
            axisTicks:{
                show:false
            },
			labels: {
				formatter: function (value) {
					return value;
				},
			},
		},
		grid: {
            padding:{
                left:30
            },
            xaxis:{
                lines:{
                    show:true
                }
            },
            yaxis:{
                lines:{
                    show:false
                }
            }
		},
		xaxis: {
			categories: [
				"Q3 2020",
				"Q4 2020",
				"Q1 2021",
				"Q2 2021",
				"Q3 2021",
				"Q4 2022",
			],
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
        legend:{
            show:false
        }
	},
};