import React, { useState, useEffect } from "react";
import "../../../transitions.css"; // Import your CSS for transitions
import ContextIntro from "./ContextIntro";
import ContextList from "./ContextList";

const JourneyContext = (props) => {
  const { context, currentStep } = props;

  const renderContent = (path) => {
    // Use a case statement to determine which component to render
    switch (path.contextType.toLowerCase()) {
      case "intro":
        return <ContextIntro {...props} />;
      case "list":
        return <ContextList {...props} />;
      default:
        return <ContextIntro {...props} />;
    }
  };

  return renderContent(context[currentStep]);
};

export default JourneyContext;
