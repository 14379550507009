import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Table,Button,Label,Dropdown } from 'semantic-ui-react'
import Description from "./description";
import { Link } from "react-router-dom";

const History = () => {

    const [tab,setTab] = useState(1)
    return (
        <Container>
            <Tabs tab={tab} setTab={setTab}/>

            {tab==0?
            <Description/>:
            <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Applicant</Table.HeaderCell>
                    <Table.HeaderCell>Survey Status</Table.HeaderCell>
                    <Table.HeaderCell>Invited on</Table.HeaderCell>
                    <Table.HeaderCell>Candidate Score</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>Applicant 1</Table.Cell>
                    <Table.Cell>Pending</Table.Cell>
                    <Table.Cell>March 4th, 2023</Table.Cell>
                    <Table.Cell>77%</Table.Cell>
                    <Table.Cell><DropdownMenu/></Table.Cell>
                </Table.Row>
                <Table.Row>
                <Table.Cell>Applicant 2</Table.Cell>
                    <Table.Cell>Pending</Table.Cell>
                    <Table.Cell>March 4th, 2023</Table.Cell>
                    <Table.Cell>66%</Table.Cell>
                    <Table.Cell><DropdownMenu/></Table.Cell>
                </Table.Row>
                <Table.Row>
                <Table.Cell>Applicant 3</Table.Cell>
                    <Table.Cell>Completed</Table.Cell>
                    <Table.Cell>March 4th, 2023</Table.Cell>
                    <Table.Cell>33%</Table.Cell>
                    <Table.Cell><DropdownMenu/></Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>}
        </Container>
    )

}

export default History

const Tabs = ({tab,setTab}) =>{
    return (

        <TabContainer>
            <Tab selected={tab==0} onClick={()=>setTab(0)}>
                <h4>Description</h4>
            </Tab>
            <Tab selected={tab==1} onClick={()=>setTab(1)}>
                <h4>Application</h4>
                <Label>5</Label>
            </Tab>
            <Tab selected={tab==2} onClick={()=>setTab(2)}>
                <h4>Personal Interview</h4>
                <Label>5</Label>
            </Tab>
            <Tab selected={tab==3} onClick={()=>setTab(3)}>
                <h4>Hired</h4>
                <Label>5</Label>
            </Tab>
        </TabContainer>
    )
}

const DropdownMenu = () =>{
    return (
        <Dropdown text='Actions'>
            <Dropdown.Menu>
            <Dropdown.Item icon='thumbs up' text='Next Stage' />
            <Dropdown.Item icon='ban' text='Reject' />
            <Link to='/app/prototype/applicant'>
                <Dropdown.Item icon='eye' text='View'/>
            </Link>
            <Dropdown.Item icon='send' text='Send Reminder'/>
            <Dropdown.Item icon='trash' text='Delete' />
            </Dropdown.Menu>
        </Dropdown>
    )
}

const Container = styled.div`
    margin-top:20px;
    font-family: 'Poppins', sans-serif;
`

const TabContainer = styled.div`
    display:flex;
    width:100%:
`

const Tab = styled.div`
    padding:20px 10px;
    min-width:200px;
    border:${props=>props.selected?'1px solid blue':'1px solid #ccc'};
    cursor:pointer;
    color:${props=>props.selected?'blue':'#ccc'};
`