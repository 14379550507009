import React from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message, Button } from "semantic-ui-react";

import { useToasts } from 'react-toast-notifications'

import {
    CLEAR_ERRORS
  } from "constants/actions";


  export const ToastDemo = ({ content }) => {
    const { addToast } = useToasts()
    return (
      <Button onClick={() => addToast(content, {
        appearance: 'success',
        autoDismiss: true,
      })}>
        Add Toast
      </Button>
    )
  }

export function AllErrors() {
    const dispatch = useDispatch();
  const [visible, SetVisible] = React.useState(false);
  const [errorList, SetErrorList] = React.useState(false);

  const { get_errors } = useSelector(
    (state) => ({
      get_errors: state.errors.errors,
    }),
    shallowEqual
  );


  React.useEffect(() => {
    if (get_errors?.data) {
      SetErrorList(get_errors.data);
      SetVisible(true);
    }
  }, [get_errors]);

  function DismissError() {
    SetVisible(false);
    dispatch({
        type: CLEAR_ERRORS
      });
  }

  if (visible) {
    return (
      <div style={{ margin: "10px", padding: "10px" }}>
        <Message 
        color='red'
          onDismiss={DismissError}
          header={"There were some errors"}
          content={JSON.stringify(errorList)}
        />
      </div>
    );
  } else {
    return null;
  }
}
export function DismissableError(props) {
    const dispatch = useDispatch();
    const { addToast } = useToasts()
  const [visible, SetVisible] = React.useState(true);

  function DismissError() {
    SetVisible(false);
    dispatch({
        type: CLEAR_ERRORS
      });
  }

  function ShowToast() {
    addToast(props.message
      ? props.message
      : "There was an error, please try again", {
      appearance: 'success',
      autoDismiss: true,
    })
  }
  if (visible) {
    return (
      <div style={{ margin: "10px", padding: "10px" }}>
        <Message color='red'
          onDismiss={DismissError}
          header={props.header ? props.header : "Oops Error"}
          content={
            props.message
              ? props.message
              : "There was an error, please try again"
          }
        />
      </div>
    );
  } else {
    return null;
  }
}
export function ToastMessage(props) {
  const { addToast } = useToasts()
  return (
    
      addToast(props.message
        ? props.message
        : "There was an error, please try again", {
        appearance:props.appearance? props.appearance :'success',
        autoDismiss: true,
      })
    
  )
}

export function DismissableSuccess(props) {
  // const dispatch = useDispatch();
const [visible, SetVisible] = React.useState(props.visible);
const { addToast } = useToasts()
function DismissError() {
  SetVisible(false);
  
}


React.useEffect(() => {
  
  SetVisible(props.visible);

}, [props,addToast]);

if (visible) {
  return (
    <div style={{ margin: "10px", padding: "10px" }}>
      <Message color={props.color? props.color: 'green'}
        onDismiss={DismissError}
        header={props.header ? props.header : "Success"}
        content={
          props.message
            ? props.message
            : "Successfully Done"
        }
      />
    </div>
  );
} else {
  return null;
}
}
