import styled, { keyframes } from "styled-components";
import React from "react";
import Row from './Row'
import { Checkbox,Dropdown, Menu,Icon } from 'semantic-ui-react'
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_SURVEY_STRUCTURES
} from "constants/actions";
import OptionsMenu from './Menu'
import Filters from './Filters'

import UserTag from './UserTag'

const rows = [0,1,2,3,4,5,6,7,8,9]

const Member = () =>{
	const dispatch = useDispatch();
	const [edit,setEdit] = React.useState(false)	
	const [open,setOpen] = React.useState(false)	
	const [openFilter,setFilter] = React.useState(false)
	const [selected,setSelected] = React.useState(null)

	const {
		     get_survey_structure,
		     organizationId
		  } = useSelector((state) => ({
		  	organizationId: Number(state.auth?.organization_id),
		  	get_survey_structure: state.surveystructure.survey_structure,}),
		    shallowEqual
		  );

	if (organizationId) {
	      if (!get_survey_structure[0]) {
			  dispatch({
	          type: SG_GET_SURVEY_STRUCTURES,
	          payload: `organization=${organizationId}`,
	        });
		}
	}

	const getCatTitles = () =>{
		const length = get_survey_structure[0].categories.length
		return get_survey_structure[0].categories.map(item=>{
			return <TH width={75/length}>{item.name}</TH>
		})
	}

	const reset = () =>{
		setSelected(false)
		setEdit(false)
	}


 


	return (
		<Container>
			<TopArea>
				<div style={{display:'flex'}}>
					<Selection onClick={()=>setEdit(!edit)}>
							Select
						
					</Selection>
					<div style={{position:'relative'}}>
						<StyledButton onClick={()=>setOpen(!open)}>
							Menu
							<Icon name="chevron down" style={{marginLeft:5}}/>
						</StyledButton>
					<OptionsMenu categories={get_survey_structure[0]} edit={open} select={(s)=>setSelected(s)} setOpen={(f)=>setOpen(f)}/>
					</div>


				<div style={{position:'relative'}}>
					<StyledButton onClick={()=>setFilter(!openFilter)}>
							Filters
							<Icon name="filter" style={{marginLeft:5,fontSize:12}}/>
					
					</StyledButton>
					<Filters categories={get_survey_structure[0]} open={openFilter} setOpen={(f)=>setFilter(f)} />
				</div>

				<Reset onClick={()=>reset()}>
					reset
				</Reset>
			</div>

			<Save>Save all</Save>
			</TopArea>
			
			 
			<Table>
				<Headers>
					<TH width={edit?3:0}><Checkbox/></TH>
	                <TH width={15}>Name</TH>

	                	<TH width={65}>Tags</TH>
	                	<TH width={20}>Actions</TH>

	                

	             </Headers>	

	             {rows.map(item=><Row edit={edit} selection={selected} categories={get_survey_structure[0]}/>)}
	         
	        </Table>

	        <UserTag categories={get_survey_structure[0]}/>
	    </Container>
		)
}




export default Member

const Container = styled.div`
	width:100%;
`


const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 10px;
  background-color: #fafafa;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
  font-weight: bold;
  transition:width 0.5s;
  overflow:hidden;

  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  color: black;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.1);
  border: none;
  outline: none;;
  padding: 0 10px;
  border: 1px solid white;
  position:relative;
  font-weight:bold;
  height:30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;



const Selection = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: rgba(0,0,0,0.1);
  border: none;
  outline: none;;
  padding: 0 10px;
  border: 1px solid white;
  position:relative;
  font-weight:bold;
  height:30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`

const TopArea = styled.div`
	display:flex;
	align-items:flex-end;
	margin-top:30px;
	justify-content:space-between;
	margin-bottom:5px;
`

const Reset = styled.div`
	height:30px;
	align-items:center;
	padding:0px 10px;
	font-family: "Barlow Semi Condensed", sans-serif;
	cursor:pointer;
`

const Save = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;;
  padding: 0 10px;
  border: 1px solid white;
  position:relative;
  font-weight:bold;
  height:30px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;
