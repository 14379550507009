import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Popup } from "semantic-ui-react";
import { format } from "date-fns";
import { deleteIcon, downloadIcon, caretDown, caretUp } from "./Icons";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_GET_DATA_STORIES,
  SET_ACTIVE_RECOMMENDATION,
  SG_GET_PENDING_SHEDULE_REPORTS,
} from "constants/actions";
import { pendingList } from "../datastory/dummy_data";
import { viewIcon } from "./Icons";
import { useNavigate } from "react-router-dom";
import Empty from "../Empty";

const statusCodes = [
  { code: 1, label: "New/Retry", container: "#FFFAF1", text: "#FEA711" },
  { code: 2, label: "In Progress", container: "#F3F7FF", text: "#2D70E2" },
  { code: 3, label: "Timed Out", container: "#FEF3F4", text: "#F15062" },
  { code: 4, label: "Error", container: "#FEF3F4", text: "#F15062" },
  { code: 5, label: "Partial Response", container: "#F3F7FF", text: "#2D70E2" },
  { code: 7, label: "Completed", container: "#F1FAF9", text: "#00B67A" },
  { code: 9, label: "Email Sent", container: "#F1FAF9", text: "#00B67A" },
  { code: 11, label: "Archived", container: "#F3F7FF", text: "#2D70E2" },
  { code: 99, label: "For Deletion", container: "#FEF3F4", text: "#F15062" },
];

function findOrganizationId(data) {
  const organizationId = data?.message?.find((string) =>
    string?.includes("organization id")
  );
  return parseInt(organizationId?.split(" - ")?.[1]);
}

// Create a function that generates a title based on the message and categoryData
function generateTitle(message, categoryData) {
  const categoryHead = message?.message
    ?.find((m) => m?.includes("category_head"))
    ?.split(" - ")?.[1];
  const primaryUid = message?.message
    ?.find((m) => m?.includes("primary_uid"))
    ?.split(" - ")?.[1];
  const category = categoryData?.[0]?.categories?.find(
    (c) => c?.id === categoryHead
  );
  const primaryCategory = categoryData?.[0]?.categories?.find(
    (c) => c?.priority === "primary"
  );
  const option = primaryCategory?.options?.find((o) => o?.id === primaryUid);

  if (!option?.name) {
    return `${category?.name}`;
  }

  return `${option?.name} - ${category?.name}`;
}

// Create a function that generates a title based on the sampleId and sampleChild and categoryData
function generateTitleById(sampleId, sampleChild, categoryData) {
  const category = categoryData?.[0]?.categories?.find(
    (c) => c?.id === sampleId
  );
  const option = category?.options?.find((o) => o?.id === sampleChild);
  return `${category?.name} - ${option?.name}`;
}

function generatePendingTitle(primary, detailedInfo, categoryData) {
  const primaryCategory = categoryData?.[0]?.categories?.find(
    (c) => c?.priority === "primary"
  );
  const primaryOption = primaryCategory?.options?.find(
    (o) => o?.id === primary
  );

  const category = categoryData?.[0]?.categories?.find(
    (c) => c?.id === detailedInfo?.category_head
  );

  if (!primaryOption?.name) {
    return `${category?.name}`;
  }
  const title = `${primaryOption?.name} - ${category?.name}`;

  return title;
}

const Table = ({ setDeleteItem, data, setDeletePending }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataStories, setDataStories] = useState();
  const [pendingStories, setPendingStories] = useState();
  const [filteredDataStories, setFilteredDataStories] = useState();
  const [hovered, setHovered] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [employeePermissions, setEmployeePermissions] = useState();
  const [orgId, setOrgId] = useState();
  const [loading, setLoading] = useState(true);

  const toggleRow = (id) => {
    const isRowCurrentlyExpanded = expandedRows.includes(id);
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter((rowId) => rowId !== id)
      : [...expandedRows, id];
    setExpandedRows(newExpandedRows);
  };

  // const getLabelNames = ()

  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    get_survey_structure,
    core_data,
    get_employee_permission,
    pending_stories,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
      get_employee_permission: state.employee_permission,
      pending_stories: state.schedule_reports?.pending_schedule_reports,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_auth_employee?.organization_id) {
      setOrgId(Number(get_auth_employee?.organization_id));
    }
  }, [get_auth_employee]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DATA_STORIES,
      payload: `organization=${get_auth_employee?.organization_id}`,
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_PENDING_SHEDULE_REPORTS,
      payload: `organization=${get_auth_employee?.organization_id}`,
    });
  }, [dispatch]);

  useEffect(() => {
    const employeeId = Number(get_auth_employee?.employee_id);
    const employeePerms =
      get_employee_permission?.employee_org_permission?.find(
        (emp) => Number(emp?.employee_id) === Number(employeeId)
      );

    if (employeePerms && employeePerms?.details?.length > 0) {
      // Filter the details so that all we have is the ones that work for this component.
      const filteredDetails = employeePerms?.details?.filter(
        (detail) => detail?.name_id && detail?.value_id
      );

      // If the filtered details are not empty, set the employee permissions to the filtered details.
      // This means the data stories will be filtered based on the permissions and not the selected group.
      if (filteredDetails?.length > 0) {
        setEmployeePermissions(filteredDetails);
      }
    }
  }, [get_employee_permission]);

  useEffect(() => {
    if (get_chat_gpt_recommendations?.data_stories) {
      setDataStories(get_chat_gpt_recommendations?.data_stories?.results);
      setLoading(false);
    }
  }, [get_chat_gpt_recommendations]);

  useEffect(() => {
    if (pending_stories) {
      const pendingDataStories = pending_stories?.results;

      const mappedStories = pendingDataStories
        ?.filter((item) => item?.status_text !== "Completed")
        ?.map((item) => {
          const dateObject = new Date(item.created_at);
          const groupDate = `${format(dateObject, "MMMM d, yyyy")}`;
          const groupTitle = generatePendingTitle(
            item?.primary_uid,
            item?.detailed_info,
            core_data?.categories
          );
          return {
            id: item?.id,
            created_at: groupDate,
            title: groupTitle,
            status: 2,
            storyId: item?.detailed_info?.chat_recommendation_id,
          };
        });
      setPendingStories(mappedStories);
    }
  }, [pending_stories]);

  useEffect(() => {
    let filteredDataStories;
    let pendingDataStories;
    if (employeePermissions && dataStories) {
      filteredDataStories = dataStories?.map((item) => {
        const dateObject = new Date(item.created_at);
        const groupDate = `${format(dateObject, "MMMM d, yyyy")}`;
        const groupTitle = generateTitle(
          item?.recommendation?.rule,
          core_data?.categories
        );

        return {
          id: item.id,
          created_at: groupDate,
          title: groupTitle,
          status: item?.status,
          stories: item?.recommendation?.data_story?.filter((story) => {
            return employeePermissions.some((permission) => {
              return (
                permission.name_id === story.parent_id &&
                permission.value_id === story.category_option
              );
            });
          }),
          organizationId: findOrganizationId(item?.recommendation?.rule),
        };
      });

      pendingDataStories = pendingList?.results
        ?.filter((item) =>
          employeePermissions.some((permission) => {
            return permission.name_id === item?.detailed_info?.category_head;
          })
        )
        .filter((item) => item?.type_of === 2)
        ?.map((item) => {
          const dateObject = new Date(item.created_at);
          const groupDate = `${format(dateObject, "MMMM d, yyyy")}`;
          const groupTitle = generateTitle(
            item?.detailed_info?.rules,
            core_data?.categories
          );

          return {
            id: item?.detailed_info?.chat_recommendation_id,
            created_at: groupDate,
            title: groupTitle,
            status: item?.status,
            organizationId: findOrganizationId(item?.detailed_info?.rules),
          };
        });

      // Filter any pending stories that have a status of 9
      pendingDataStories = pendingDataStories?.filter(
        (item) => item?.status !== 9
      );
    }

    if (!employeePermissions && dataStories) {
      filteredDataStories = dataStories?.map((item) => {
        const groupTitle = generateTitle(
          item?.recommendation?.rule,
          core_data?.categories
        );

        return {
          id: item.id,
          title: `${groupTitle}`,
          stories: item?.recommendation?.data_story,
          status: item?.status,
          created_at: item?.created_at,
          organizationId: findOrganizationId(item?.recommendation?.rule),
        };
      });

      pendingDataStories = pendingList?.results
        ?.filter((item) => item?.type_of === 2)
        ?.map((item) => {
          const dateObject = new Date(item.created_at);
          const groupDate = `${format(dateObject, "MMMM d, yyyy")}`;
          const groupTitle = generateTitle(
            item?.detailed_info?.rules,
            core_data?.categories
          );

          return {
            id: item?.detailed_info?.chat_recommendation_id,
            created_at: groupDate,
            title: groupTitle,
            status: item?.status,
            organizationId: findOrganizationId(item?.detailed_info?.rules),
          };
        });

      // Filter any pending stories that have a status of 9
      pendingDataStories = pendingDataStories?.filter(
        (item) => item?.status !== 9
      );
    }

    if (filteredDataStories?.length > 0) {
      setFilteredDataStories([...filteredDataStories, ...pendingDataStories]);
    }
  }, [employeePermissions, dataStories, pendingStories]);

  useEffect(() => {
    if (get_auth_employee?.employee_id) {
      const employeeId = Number(get_auth_employee?.employee_id);

      dispatch({
        type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }
  }, [get_auth_employee]);

  return (
    <Container>
      {pendingStories?.length === 0 && filteredDataStories?.length === 0 ? (
        <Empty type="Data Stories" />
      ) : (
        <>
          <Header>
            <TH width={45}>Title</TH>
            {/* <TH width={25}>Template</TH> */}
            <TH width={15}>Status</TH>
            <TH width={20}>Date</TH>
            <TH width={10}>Actions</TH>
          </Header>
          {pendingStories?.length > 0 &&
            pendingStories
              ?.filter((item) => {
                // if the item.id already exists in filteredDataStories
                // remove it from this list.
                return !filteredDataStories?.some(
                  (filteredItem) => filteredItem.id === item.id
                );
              })
              ?.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <Row
                      onMouseOver={() => {
                        setHovered(item.id);
                      }}
                      onMouseOut={() => setHovered(null)}
                      rowIsHovered={hovered === item.id}
                    >
                      <TD width={45}>
                        <MultipleCategory name={item.title} />
                      </TD>
                      <TD width={15}>
                        <Status id={item.status} />
                      </TD>
                      <TD width={20}>{item.created_at}</TD>
                      <TD width={10}>
                        <Popup
                          trigger={
                            <ActionWrapper
                              onClick={() => {
                                setDeletePending(item.id);
                              }}
                            >
                              {deleteIcon()}
                            </ActionWrapper>
                          }
                          content="Delete Group"
                          position="top center"
                        />
                      </TD>
                    </Row>
                  </React.Fragment>
                );
              })}
          {!loading &&
            filteredDataStories?.length > 0 &&
            filteredDataStories
              ?.filter((item) => item.organizationId === orgId)
              ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              ?.map((item) => {
                let mappedData = item;
                const isExpanded = expandedRows.includes(item.id);
                return (
                  <React.Fragment key={item.id}>
                    <Row
                      onMouseOver={() => {
                        setHovered(item.id);
                      }}
                      onMouseOut={() => setHovered(null)}
                      rowIsHovered={hovered === item.id}
                    >
                      <TD width={45}>
                        <MultipleCategory name={mappedData.title} />
                      </TD>
                      {/* <TD width={25}>{}</TD> */}
                      <TD width={15}>
                        <Status id={mappedData.status} />
                      </TD>
                      <TD width={20}>
                        {format(
                          new Date(mappedData.created_at),
                          "EEEE, MMM d, yyyy"
                        )}
                      </TD>
                      <TD width={10}>
                        {mappedData?.stories &&
                          mappedData?.stories?.length > 0 && (
                            <Popup
                              trigger={
                                <ActionWrapper
                                  onClick={() => {
                                    toggleRow(item.id);
                                  }}
                                >
                                  {isExpanded
                                    ? caretUp("#2d70e2")
                                    : caretDown("#2d70e2")}
                                </ActionWrapper>
                              }
                              content="Expand"
                              position="top center"
                            />
                          )}
                        <Popup
                          trigger={
                            <ActionWrapper
                              onClick={() => {
                                setDeleteItem(item.id);
                              }}
                            >
                              {deleteIcon()}
                            </ActionWrapper>
                          }
                          content="Delete Group"
                          position="top center"
                        />
                      </TD>
                    </Row>
                    {isExpanded &&
                      mappedData?.stories &&
                      mappedData?.stories?.map((story, key) => {
                        const title = generateTitleById(
                          story?.parent_id,
                          story?.category_option,
                          core_data?.categories
                        );

                        return (
                          <ExpandedRow key={`${item?.id}-${key}`}>
                            <ExpandedContent>
                              <ExpandedFileTitle>{title}</ExpandedFileTitle>
                              <ExpandedFileDownload>
                                {(story?.response?.length > 0 && (
                                  <Popup
                                    trigger={
                                      <ActionWrapper
                                        onClick={() => {
                                          dispatch({
                                            type: SET_ACTIVE_RECOMMENDATION,
                                            payload: story,
                                          });
                                          navigate(
                                            `/leader/audit/reports/stories/${item.id}/${story?.category_option}`
                                          );
                                        }}
                                      >
                                        {viewIcon()}
                                      </ActionWrapper>
                                    }
                                    content="View Story"
                                    position="top center"
                                  />
                                )) || <>No data</>}
                              </ExpandedFileDownload>
                            </ExpandedContent>
                          </ExpandedRow>
                        );
                      })}
                  </React.Fragment>
                );
              })}
          {data?.length === 0 && (
            <Row>
              <TD width={100} centered>
                No reports found, create one to get started!
              </TD>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default Table;

const MultipleCategory = ({ name }) => {
  return <Border>{name}</Border>;
};

const Status = ({ id }) => {
  const status = statusCodes?.find((status) => status?.code === id);

  return (
    <StatusContainer color={status?.container}>
      <StatusText color={status?.text}>{status?.label}</StatusText>
    </StatusContainer>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;

const Border = styled.div``;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const ExpandedRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: -10px;
`;

const ExpandedContent = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #2a3039;
`;

const ExpandedFileTitle = styled.div`
  font-size: 12px;
  width: 82.5%;
  color: #2a3039;
  line-height: 1.5;
  justify-content: flex-start;
  align-items: center;
  display: flex;
`;

const ExpandedFileDownload = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  width: 17.5%;
  justify-content: flex-start;
`;
