import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import RecommendationBuilder from './RecommendationBuilder'

import RecommendationCard from './RecommendationCard'
import {getStructuredFeedback} from './calculations'

const formatTitle = (tags, template) => {
  if (tags?.length === 0) {
    return template;
  }
  return `${tags.join(", ")} - ${template}`;
};

const timeAgo = (updatedAt) => {
  const now = new Date();
  const updated = new Date(updatedAt);
  const diff = now - updated;
  const hours = diff / 1000 / 60 / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = days / 30;
  const years = days / 365;

  switch (true) {
    case years > 10:
      return "a long time ago";
    case years > 1:
      return `${Math.floor(years)} years ago`;
    case months > 1:
      return `${Math.floor(months)} months ago`;
    case weeks > 1:
      return `${Math.floor(weeks)} weeks ago`;
    case days > 1:
      return `${Math.floor(days)} days ago`;
    case hours > 1:
      return `${Math.floor(hours)} hours ago`;
    default:
      return "just now";
  }
};

const Recommendation = ({
  data,
  pillar_scores,
  setPending,
  pendingRecs,
  recommendations,
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedRec, setSelectedRec] = useState();

  return (
    <Container>
      <Top>
        <Title>Your Recommendations</Title>
        <RecommendationBuilder
          feedback={getStructuredFeedback(data)}
          pillar_scores={pillar_scores}
          setPending={setPending}
        />
      </Top>
      {(recommendations?.length > 0 &&
        recommendations?.map((item, i) => {
          const selectedItems = item.survey_data.selectedItems.map((it) => {
            return it.title;
          });
          const formattedTitle = formatTitle(
            item.params.tags,
            item.params.template
          );

          const formattedDate = item?.updated_at && timeAgo(item.updated_at);

          return (
            <RecommendationCard
              title={formattedTitle}
              tags={selectedItems}
              text={formattedDate}
            />
          );
        })) ||
        (pendingRecs?.length === 0 && (
          <NoRecsText>No recommendations have been generated yet</NoRecsText>
        ))}
      {pendingRecs?.map((item, i) => {
        const formattedTitle = formatTitle(
          item.params.tags,
          item.params.template
        );

        const formattedDate = item?.created_at && timeAgo(item.created_at);
        const selectedItems = item.survey_data.selectedItems.map((it) => {
          return it.title;
        });
        return (
          <RecommendationCard
            title={formattedTitle}
            tags={selectedItems}
            text={formattedDate}
            pending={true}
          />
        );
      })}
    </Container>
  );
};

export default Recommendation;

const Container = styled.div`
  max-width: 1000px;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2a3039;
  margin-top: 8px;
`;

const NoRecsText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  margin-bottom: 16px;
`;

const PendingTitle = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2a3039;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Top = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items:center;
    margin-bottom:40px;
`