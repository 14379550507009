import styled, { keyframes } from 'styled-components';
import React from "react";
import SelectFrequency from './SelectFrequency'
import {Icon} from 'semantic-ui-react'

const Component13 = () =>{
  const [selected,setSelected] = React.useState(false)


    return (
        <Container>

            <T1>Set your check-in frequency</T1>
            <Text delay={0.5}>
              Our check-in process is designed to keep you on track. No fluff, or useless messages. Just a quick and simple check-in to keep you focused.
              <u> You can change your check-in frequency at any time. </u>
            </Text>

            <LINK delay={0.7}>
            <a>See a sample check-in <Icon name="external alternate"/></a>
            </LINK>

            <SelectArea delay={1}>
            <SelectFrequency 
                selected={selected==1}
                setSelected={()=>setSelected(1)}
                title="Weekly"
                item="You'll receive a check in on a weekly basis." />

            <SelectFrequency 
                selected={selected==2}
                setSelected={()=>setSelected(2)}
                title="Bi-weekly" 
                item="Receive a check-in every 2 weeks." />


            <SelectFrequency 
                selected={selected==3}
                setSelected={()=>setSelected(3)}
                title="Monthly" 
                item="Recieve a check-in once a month." />

           
        </SelectArea>

           

           
           

            
        </Container>
    )
}

export default Component13

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:10px;
    line-height:1.6;
   
    opacity:0;
    max-width:800px;


    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    background-color:#D4F5ED;
    padding:20px;
    border-radius:10px;
    margin-top:20px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    color:#2A3039;
    border-bottom:1px solid #2A3039;
    padding-bottom:10px;
    display:inline-block;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const SelectArea = styled.div`
opacity:0;
animation: ${fadeIn} 1s ease-out forwards;
animation-delay: ${({ delay }) => delay}s; // Use props to set delay
width:100%;
display:flex;
flex-direction:column;
align-items:center;
`

const LINK = styled.div`
  margin-bottom:20px;

  opacity:0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`