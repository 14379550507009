import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Pagination, Input } from "semantic-ui-react";
import AddTeam from "./addTeam";
import TeamTable from "./TeamTable";
import MemberTable from "./MemberTable";
import AddMember from "./addMember";

import "../setting.css";
import { CSSTransition, CSSTransitionGroup } from "react-transition-group";

import { Message } from "semantic-ui-react";
import { Dimmer } from "semantic-ui-react";
import { Link } from "react-router-dom";

import {
  LOAD_TEAMS,
  LOAD_EMPLOYEES,
  SG_GET_PERSONALITY_PARTICIPANTS,
  SG_GET_PERSONALITY_TEAMS,
  LOAD_DEPARTMENTS,
  RESET_CREATED_TEAM,
  PULL_ORGANIZATION
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


const PersonalitySettings = () => {
  const apiDispatch = useDispatch();
  const [pool, setPool] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(0);
  const [teams, setTeams] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectTeam, setSelectTeam] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [blurred, setBlurred] = useState(false);
  React.useEffect(() => {
    // if the response is ok 200
    apiDispatch({
      type: LOAD_TEAMS,
    });
    apiDispatch({
      type: LOAD_EMPLOYEES,
      payload: {},
    });
    apiDispatch({
      type: LOAD_TEAMS,
    });
    apiDispatch({
      type: SG_GET_PERSONALITY_PARTICIPANTS,
      payload: {},
    });
    apiDispatch({
      type: SG_GET_PERSONALITY_TEAMS,
      payload: {},
    });
  }, [apiDispatch]);

  const organization_id = useSelector((state) => state.auth.organization_id);

  React.useEffect(() => {
    // if the response is ok 200
    if (organization_id) {
      apiDispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organization_id },
      });
    }
  }, [organization_id, apiDispatch]);

  const { get_personality_teams, 
    get_personality_participants, get_auth,
  get_organizations } = useSelector(
    (state) => ({
      get_personality_teams: state.personalityteams.personality_teams,
      get_personality_participants: state.personalityparticipants.personality_participants,
      get_auth: state.auth,
      get_organizations:  state.organizations
    }),
    shallowEqual
  );

  // console.log(get_organizations[get_auth.organization_id])

  useEffect(() => {
    setTeams(get_personality_teams);
    setMembers(get_personality_participants);
    setOrganization(get_auth.organization_id)
    if (get_organizations[get_auth.organization_id]?.payment_status < 2 || get_organizations[get_auth.organization_id]?.payment_status > 3)
    {
      setBlurred(true)
    }
    setPool(tab === 0 ? get_personality_teams : get_personality_participants);
  }, [get_personality_teams, 
    get_organizations, get_personality_participants, tab, get_auth]);

  const handlePaginationChange = (e, { activePage }) => {
    setPage(activePage);
  };


  const checkMember = (member, val) => {
    if (member.first_name) {
      if (member.first_name.includes(val)) {
        return true;
      }
    }
    if (member.last_name) {
      if (member.last_name.includes(val)) {
        return true;
      }
    }
    if (member.email) {
      if (member.email.includes(val)) {
        return true;
      }
    }
    return false;
  };

  const checked = (l, val) => {
    return l.name.includes(val);
  };

  const memberSearch = () => {
    if (search) {
      const mem = members.filter((member) => checkMember(member, search));
      setPool(mem);
      setPage(1);
    } else {
      setPool(members);
      setPage(1);
    }
  };

  const TeamSearch = () => {
    if (search) {
      const t = teams.filter((team) => checked(team, search));
      setPool(t);
      setPage(1);
    } else {
      setPool(teams);
      setPage(1);
    }
  };

  useEffect(() => {
    if (tab === 0) {
      TeamSearch();
    } else if (tab === 1) {
      memberSearch();
    }
  }, [tab]);

  useEffect(() => {
    //Tab has changed, change the pool data here
    setSearch("");
    if (tab === 1) {
      setPool(members);
    } else if (tab === 0) {
      setPool(teams);
    }
  }, [tab]);


  return (
    

 
    <Container>

      <div style={{ width: "100%", position: "relative" }}>
        <Title>Personality Management</Title>
        <P>
          Your hub to manage and utilize the personality analytics feature.
          Invite teams or members to take the assessment and upgrade their
          ability to collaborate.
        </P>


        {tab === 0 ? (
          <AddTeam 
          organization={organization_id}
          get_personality_teams={get_personality_teams}
          trigger={<Button disabled={blurred}>Add Team Personality</Button>} />
        ) : (
          <AddMember 
          organization={organization_id}
          get_personality_participants={get_personality_participants}
          trigger={<Button disabled={blurred}>Add Member Personality</Button>} />
        )}
      </div>

      <Dimmer.Dimmable blurring dimmed={blurred}>


      <Tabs>
        {["Teams", "Members"].map((item, i) => {
          return (
            <Tab 
            onClick={() => setTab(i)} selected={i === tab}
            key={i}>
              {item}
            </Tab>
          );
        })}
      </Tabs>

      <SearchContainer>
        <Input
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          size="huge"
          style={{ width: "80%" }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SearchContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 30,
        }}
      >
        <Pagination
          activePage={page}
          onPageChange={handlePaginationChange}
          boundaryRange={1}
          size="mini"
          firstItem={null}
          lastItem={null}
          siblingRange={0}
          totalPages={Math.ceil(pool.length / 10)}
        />
      </div>

      {tab === 0 ? (
        <TeamTable
          data={pool.slice((page - 1) * 10, page * 10)}
          members={members}
          search={search}
          selectTeam={(i) => setSelectTeam(i)}
        />
      ) : (
        <MemberTable
          search={search}
          members={pool.slice((page - 1) * 10, page * 10)}
        />
      )}
      </Dimmer.Dimmable>
    </Container>
    
  );
};

export default PersonalitySettings;


const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 60px;

   @media (max-width:768px){
  margin-top:0px;
  font-size:20px;
  width:70%;
}
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-left: 10px;
  position: absolute;
  right: 10px;
  top: 0px;

    @media (max-width:768px){
   height:30px;
   font-size:12px;
   padding:0 10px 0 10px;
   top:-5px;
   right:-20px;
}

`;

const P = styled.p`
  font-size: 15px;
  line-height: 1.4;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 20px;
  width: 70%;

  @media (max-width:768px){
  width:100%;
}
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e4e4e4;
  margin-top: 40px;
`;

const Tab = styled.div`
  font-size: 13px;
  font-weight: 700;
  font-family: "Red Hat Display", sans-serif;
  padding: 6px 16px 6px 16px;
  border-bottom: ${(props) => (props.selected ? "3px solid blue" : "none")};
  color: ${(props) => (props.selected ? "black" : "#9b9b9b")};
  cursor: pointer;
`;

const Container = styled.div`
min-height:100vh;
padding:30px;

@media (max-width:768px){
  padding:20px;
}
`
const Upgrade = styled.span`
  display: flex;
  padding: 5px 10px 5px 10px;
  font-family: "Red Hat Display", sans-serif;
  color: white;
  background-image: linear-gradient(308deg, #6d00aa, #6a00ff);
  border-radius: 10px;
  font-weight: 900;
  margin-left: 30px;
  cursor: pointer;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  font-size: 1.14em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  justify-content: space-between;
`;
const Linked = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;