import styled from "styled-components";
import React, { useEffect, useState } from "react";
import VideoCard from './VideoCard'
import Checklist from './checklist'
import Welcome from './welcome'

import Insights from './insights'
import { useSearchParams, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import useGetUserSettings from "hooks/useGetUserSettings";

import {
  filter_data
} from "../reports/Audit/data_navigation/calculate_data";

import {
  SET_FILTERED_DATA,
  SET_FILTERED_LAST_DATA,
  SET_DATA_DATES,
  SET_CATEGORY_DATA,
  SET_RAW_DATA,
} from "constants/actions";

const Home = () =>{
	const location = useLocation();
	const dispatch = useDispatch();
    const isDEI = location.pathname.includes("edi");

    const { filteredCategories, isAdmin } = useGetUserSettings();

    const { sub_state, nav_state } = useSelector(
    (state) => ({
      sub_state: state.audit.sub,
      nav_state: state.audit.nav,
    }),
    shallowEqual
  );



    const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
    standards
  } = useGetSurveyData({
    surveyType: isDEI ? 7 : 6,
    auditTypeId: isDEI ? 4 : 3,
    filteredCategories,
    isAdmin,
  });


  const { questionStructure, reportData, surveyComments } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
    surveyType: isDEI ? 7 : 6,
    filteredCategories,
    isAdmin,
  });

    useEffect(() => {
    if (reportData && nav_state) {
    	if(reportData.length>0){
    	let filtered_data = filter_data(reportData, nav_state,get_survey_structure[0])
          dispatch({
            type: SET_FILTERED_DATA,
            payload: filtered_data.filter_data,
          });

          dispatch({
            type: SET_FILTERED_LAST_DATA,
            payload: filtered_data.filter_data_last,
          });
    	}
         
    }
  }, [dispatch, reportData]);

      useEffect(() => {
    if (get_survey_structure) {
      dispatch({
        type: SET_CATEGORY_DATA,
        payload: get_survey_structure[0],
      });
    }
  }, [dispatch, get_survey_structure]);


	return (
		<Container>
			<Welcome/>
			<Row>
				{questionStructure?<Insights questions={questionStructure}/>:""}
			</Row>

		

		{/*	<T1>Featured content</T1>

			<Row>
				<VideoCard color={'#66D686'} title={'Understanding your culture'} sub={'Culture Analytics'}/>
				<VideoCard color={'#FA1E4E'} title={'Creating an action plan'} sub={'Action Planning'}/>
				<VideoCard color={'#615BF0'} title={'Following up on plans'} sub={'Action Planning'}/>
				<VideoCard color={'#6D6D6D'} title={'Culture analysis for managers'} sub={'Culture Analytics'}/>
			</Row>*/}
		</Container>
		)
}


export default Home


const Container = styled.div`
	padding-left:58px;
	margin-top:30px;
`

const T1 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-size: 18px;
color: #414141;
margin-bottom:20px;
`

const Row = styled.div`
	display:flex;
	margin-bottom:40px;
	justify-content:space-between;
	margin-right:50px;

`