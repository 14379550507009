import React,{useState} from 'react';
import styled from 'styled-components';
import {Table,Icon} from 'semantic-ui-react'


const Mapping = ({columns,handleColumnSelect}) =>{

    return (
        <Container>
            <Title>
                Map column heads to labels
            </Title>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Matched</Table.HeaderCell>
                        <Table.HeaderCell>Column label from file</Table.HeaderCell>
                        <Table.HeaderCell>Prospective attritube</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {columns.map((column, index) => (
                        <Table.Row>
                            <Table.Cell><Icon name="check" style={{color:'green',marginLeft:10}}/></Table.Cell>
                            <Table.Cell>{column}</Table.Cell>
                            <Table.Cell>
                                <select onChange={(e) => handleColumnSelect(index, e.target.value)}>
                                    <option value="">Select Label</option>
                                    {/* Dropdown options for labeling */}
                                    <option value="Option1">Option1</option>
                                    <option value="Option2">Option2</option>
                                    {/* Add more options as needed */}
                                </select>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>


            </Table>
          
        </Container>
    )

}

export default Mapping

const Container = styled.div`
    margin-top:20px;
`

const Header = styled.div`
font-family:'Raleway';
margin-bottom:20px;
`

const Title = styled.div`
font-size:24px;
font-weight:bold;
margin-bottom:20px;
`