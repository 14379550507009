import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { getGrade } from "../utils/cultureUtils";
import Table from "./Table";

const Segmentation = (props) => {
  const {
    categories,
    calculated,
    hideText = false,
    dummyData,
    config,
    rule,
    subSelected,
    wrap = false,
    previous,
    isSportLaw,
  } = props;

  const average = (array) => {
    let total = 0;
    let n = 0;
    array.map((item) => {
      total += item != null ? item : 0;
      n += item != null ? 1 : 0;
    });

    if (!total) {
      return 0;
    }

    return total / n;
  };

  const get_question_total = (data) => {
    if (data.length > 0) {
      return average(
        data
          .map((item) => {
            return item.questions.map((question) => question.response);
          })
          .flat()
      );
    }
    return 0;
  };

  const get_last_total = (data) => {
    if (data.length === 0) {
      return 0;
    }

    return average(
      data?.map((item) => {
        const q = item.questions.find((f) => {
          return true;
        })?.response;

        return q;
      })
    );
  };

  const get_cat_average = (cat, data, anchor) => {
    return get_question_total(
      data.filter((f) => {
        return f.categories.find((f) => f.id === anchor)?.response === cat;
      })
    );
  };

  const getRoleName = (anchor, id) => {
    if (!subSelected) {
      return categories.categories
        .find((f) => f.id === anchor)
        .options.find((f) => f.id === id).name;
    } else {
      const found = calculated[0].lookup.find((match) => {
        return match.id === id;
      });
      return found.label;
    }
  };

  return calculated.map((item, i) => {
    if (i > 3) {
      return null;
    }
    const tableData = Object.keys(item.data).map((k) => {
      const useRule = isNaN(rule) ? 2 : rule;
      if (item.data[k].n1 > useRule) {
        // const change = previous?.overall
        //   ? get_cat_average(k, previous, item.id) * 10
        //   : 0;

        const change = previous?.overall ? average(previous?.overall) * 10 : 0;

        try {
          return [
            `${getRoleName(item.id, k)}`,
            `${item.data[k].n1}`,
            `${((item.data[k].total / item.data[k].n) * 10).toFixed(0)}%`,
            `${
              change
                ? `${Math.floor(
                    (item.data[k].total / item.data[k].n) * 10 - change
                  )}%`
                : "--"
            }`,
          ];
        } catch {
          return null;
        }
      }
      return null;
    });

    // Filter out any empty results and show table data only if
    // data is available.
    if (tableData.filter((item) => item !== null).length === 0) {
      return null;
    }

    // get_cat_average
    const tableTemplate = [
      { title: `${item.name}`, width: "33.3%" },
      { title: "Sample Size", width: "33.3%" },
      { title: "Score", width: "16.65%" },
      { title: "Change", width: "16.65%" },
    ];

    const tableContent = {
      rows: tableTemplate,
      data: tableData,
    };

    // sort table data
    function sortFunction(a, b) {
      if (a[0] === b[0]) {
        return 0;
      } else {
        return a[0] < b[0] ? -1 : 1;
      }
    }

    // update the table content with sorted data
    tableContent.data = tableContent?.data
      ?.filter((arr) => arr && arr?.length > 0)
      ?.sort(sortFunction);

    return (
      <View style={styles.container} wrap={wrap}>
        {!hideText && (
          <View>
            <Text style={[styles.title, { color: config.titleColor }]}>
              Table {i + 1}. {item.name}
            </Text>
            {/* <Text style={styles.description}>
              These grades are calculated based on traditional school grading (B
              is equal to 83-86%) scales. They are designed to give an intuitive
              sense of the overall scoring.
            </Text> */}
          </View>
        )}
        <Table
          data={tableContent}
          headerColor={config.tableBorderColor}
          isSportLaw={isSportLaw}
        />
      </View>
    );
  });
};

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2C3644",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 15,
    color: "#2C3644",
  },
  container: {
    paddingBottom: 5,
  },
});

export default Segmentation;
