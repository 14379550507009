import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display:flex;
  flex-direction:row;

  @media (max-width:768px){
    flex-direction:column;
    position:relative;
  }
`

export const Toggled = styled.div`
  @media (max-width:768px){
    position:absolute;
    top:0;
    right:0;
  }
`

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 50px;
  margin-bottom: 22px;

  @media (max-width:768px){
    margin-top:0;
    font-size:20px;
  }
`;

export const OtherContainer = styled.div`
  width:100%;
  display:flex;
  flex-direction:column;

`

export const Title2 = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

export const Title3 = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
`;

export const Warning = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #ff2d55;
`;

export const Title4 = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: white;

  @media (max-width:768px){
    font-size:18px;
  }
`;

// export const Title = styled.div`
// 	font-size: 28px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
//   margin-top:40px;
//   margin-bottom:22px;
// `

// export const Title2 = styled.div`
// 	font-size: 20px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
// `


// export const Title3 = styled.div`
// 	font-size: 25px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
// `

export const P = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  line-height: 1.31;
`;

export const P2 = styled.div`
  font-size: 14px;
  color: #9b9b9b;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 0px;
  margin-top:10px;

  @media (max-width:768px){
    margin-bottom:0;
  }
`;
export const P3 = styled.div`
  font-size: 16px;
  color: #9b9b9b;
  font-family: "Red Hat Display", sans-serif;
`;

// export const P = styled.div`
// 	 font-size: 16px;
//   font-weight: 500;
//   color: #9b9b9b;;
//   font-family: 'Red Hat Display', sans-serif;
//   line-height: 1.31;
//   margin-bottom:40px;
// `

// export const P2 = styled.div`
// 	 font-size: 16px;
//   color: #4a4a4a;
//   font-family: 'Red Hat Display', sans-serif;
//   line-height: 1.31;
//`

export const Line = styled.div`
  width: 100%;
  border-bottom: solid 1px #e9e9e9;
  margin: 36px 0px 36px 0px;

  @media (max-width:768px){
    margin:10px 0 10px 0;
  }
`;


export const Line2 = styled.div`
  width: 100%;
  border-bottom: solid 1px #e9e9e9;
  margin: 5px 0px 10px 0px;

   @media (max-width:768px){
    margin:5px 0 10px 0;
  }
`;

export const SettingsCard = styled.div`
  padding: 36px 22px 7px 24px;
  border-radius: 20px;
  z-index: 1;
  box-shadow: 0 2px 40px 0 rgba(218, 218, 218, 0.5);
  width: calc(50% - 36px);
  margin-bottom: 36px;
  position:relative;

  @media (max-width:768px){
    width:100%;
  }
`;

export const LeaderContainer = styled.div`
  padding:0 10px 0 10px;
  max-width:700px;

  @media (max-width:768px){
    padding:0 10px 0 10px;
  }

`

export const PersonalityCard = styled.div`
  padding: 36px 22px 7px 24px;
  border-radius: 20px;
  box-shadow: 0 2px 40px 0 rgba(218, 218, 218, 0.5);
  width: calc(50% - 36px);
  margin-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;

   @media (max-width:768px){
    width:100%;
    height:150px;
    
  }
`;

export const Img = styled.img`
  width: 50px;
  height: 45px;
  margin-right: 22px;

  @media (max-width:768px){
    margin-bottom:20px;
  }
`;

export const View = styled.span`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: #6a00ff;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Arrow = styled.span`
  font-size: 18px;
  margin-left: 15px;
`;

export const Button = styled.div`
  padding:15px;
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ToggleContainer = styled.div`
  width: 31px;
  height: 12px;
  border-radius: 6px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  position: relative;
`;

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  border-radius: 10px;
  position: absolute;
  left: ${(props) => props.position};
  top: -4px;
  transition: left 200ms ease-in;
`;

export const TopBar = styled.div`
  background-color: #5800d4;
  color: white;
  padding: 36px 0px 29px 44px;

  @media (max-width:768px){
    padding: 36px 0px 29px 20px;
  }
`;

export const Oval = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${(props) => (props.selected ? "#6a00ff" : "#d8d8d8")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 14px;
  min-width: 24px;
`;

// export const Oval = styled.div`
// 	width: 24px;
//   height: 24px;
//   border-radius:12px;
//   background-color: ${props=>props.selected ? '#6a00ff':'#d8d8d8'};
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   cursor:pointer;
//   margin-right:14px;
//   min-width:24px;
// `

export const Highlight = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #7f00ff;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
`;


export const Participants = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
`;

export const BottomBar = styled.div`
  background-color: #5800d4;
  color: white;
  padding: 9px 24px 10px 44px;
  display: flex;
  justify-content: flex-end;
`;

export const CoreSettingBar = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  flex-direction: "row";
  width:600px;
  z-index: 999;
  

  @media (max-width:768px){
    width:100%;
    margin-bottom:40px;
    position:relative;
  }
`;

export const CoreTimeZoneBar = styled.div`
  color: black;
  display: flex;
  justify-content: space-between;
  flex-direction: "row";
  width:600px;
  z-index: 999;
  

  @media (max-width:768px){
    width:100%;
    position:relative;
  }
`;

export const Save = styled.div`
  width: 150px;
  height: 50px;
  border-radius: 9px;
  background-color: #ffffff;
  color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;  
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${props => props.margin ? props.margin : 0};

  @media (max-width:768px){
    position:absolute;
    right:0;
    bottom:-40px;
    height:auto;
    width:auto;
  }
`;

export const SelectContainer = styled.div`
  width: 188px;
  height: 50px;
  border-radius: 8px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
  font-family: "Red Hat Display", sans-serif;
  position: relative;
  z-index: 1;
  margin-right: ${props => props.margin ? props.margin : 0};
  margin-top:10px;

  @media (max-width:768px){
    padding-left:10px;
    width:150px;
    font-size:13px;
  }
`;

// export const SelectContainer = styled.div`
// 	width: 188px;
//   height: 50px;
//   border-radius: 8px;
//   background-color: #e9e9e9;
//   display:flex;
//   align-items:center;
//   padding-left:24px;
//   font-size: 16px;
//   font-weight: bold;
//   color: #4a4a4a;
//   font-family: 'Red Hat Display', sans-serif;
//   position:relative;
//   z-index:1;
// `

export const show = keyframes`
	0% {margin-right:10px;opacity:0}
  100% {margin-right:0px;opacity:1}
`;

export const EmployeeSelect = styled.div`
      display:flex;
      flex-direction:row;
      width:calc(50% - 45px);
      border-bottom:1px solid #e9e9e9;
      padding:10px 0px 10px 0px;

      @media (max-width:768px){
        width:100%;
        padding:10px 0 10px 0;
      }
`


export const LeaderAvatar = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 75px;
  margin-top:12px;
  margin-bottom:20px;
  opcacity:0;
  animation: ${props=>show} 300ms linear 0s 1 forwards;

`;
export const LeaderAvatarIcon = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 5px;
  margin-bottom:15px;
  opcacity:0;
  animation: ${props=>show} 300ms linear 0s 1 forwards;

`;

export const LeaderName = styled.div`
	font-size: 23px;
	font-weight: bold;
	font-family: 'Red Hat Display', sans-serif;

  @media (max-width:768px){
    font-size:14px;
  }

`



export const Selection = styled.div`
	background-color:white;
	width:100%;
	position:absolute;
	display:flex;
	flex-direction:column;
	opacity:${props=>props.open? 1: 0};
	transition:opacity 300ms ease-in;
`

export const OptionCard = styled.div`
  padding: 28px 28px 30px 28px;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin-top:20px;
`
export const MobileInvite = styled.div`
  
  @media(min-width:768px){
    display:none;
  }
`

export const InviteContainer = styled.div`
  display:flex;
  flex-direction:row;
  @media (max-width:768px){
    display:none;
  }
`

export const Card = styled.div`
	 height: 75px;
	 width:100%;
	 border-radius: 10px;
	  box-shadow: 0 4px 20px 0 #ececec;
	  background-color: #ffffff;
	  display:flex;
	  flex-direction:row;
	   justify-content:space-between;
	  align-items:center;
	  padding:24px;
	  margin-bottom:24px;

    @media (max-width:768px){
      height:auto;
    }
`

export const Name = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: #4a4a4a;
	font-family: 'Red Hat Display', sans-serif;
`
export const Email = styled.div`
  font-size: 14px;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
`


export const Avatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin-right:16px;
`;

export const IconContainer = styled.div`
	color:white;
	width:32px;
	height:32px;
	border-radius:16px;
	background-color:${props=>props.color};
	display:flex;
	align-items:center;
	justify-content:center;
	margin-right:5px;
	cursor:pointer;

`


// export const LeaderAvatar = styled.img`
//   height: 150px;
//   width: 150px;
//   border-radius: 75px;
//   margin-top:12px;
//   margin-bottom:20px;
//   opcacity:0;
//   animation: ${props=>show} 300ms linear 0s 1 forwards;

// `;

// export const LeaderName = styled.div`
// 	font-size: 23px;
// 	font-weight: bold;
// 	font-family: 'Red Hat Display', sans-serif;

// `
// export const Participants = styled.div`
// 	display:flex;
// 	flex-wrap:wrap;
// 	width:100%;
// 	justify-content:space-between;
// 	margin-top:28px;

// `

// export const BottomBar = styled.div`
// 	 background-color:#5800d4;
//   color:white;
//   padding:9px 24px 10px 44px;
//   display:flex;
//   justify-content:flex-end;
// `

// export const Save = styled.div`
// 	width: 150px;
//   height: 50px;
//   border-radius: 9px;
//   background-color: #ffffff;
//   color:#6a00ff;
//   font-size: 16px;
//   font-weight: bold;
//   font-family: 'Red Hat Display', sans-serif;
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   cursor:pointer;
// `
