import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import format from "date-fns/format";

const DefaultPic = 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7'

const Individual = ({
  employeesData, selectedComment, selectedName, team_type,selectedTeam }) => {

  const { get_auth, get_employees } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
    }),
    shallowEqual
  );


  const [show, setShow] = React.useState(false)

  const Feedback_Checker = () => {
    if (Number(get_auth?.employee_id)) {
      let role = get_employees[get_auth.employee_id]?.account_type
      // console.log(team_type)
      if (team_type < 12 && role > 8) {
        return false
      }
      return true
    }
    return false

  }

  const Anonymous_Checker = () => {
    if (Number(get_auth?.employee_id)) {
      let role = get_employees[get_auth.employee_id]?.account_type
      if (team_type == 10) {
        return true
      }
      if (team_type == 12 && role > 8) {
        return true
      }
    }

    return false

  }


// console.log(selectedComment)

  return (


    <div style={{ marginBottom: 50, padding: 20 }}>
      <Title>Feedback</Title>
      <Description>What people are saying about {selectedTeam?'this ':'your '} team's {selectedName}. </Description>
      <CommentContainer>

        {Feedback_Checker() && selectedComment ? selectedComment.map((p, i) => {
          if (p.comment) {

            return <Container key={i}>
              <EmployeeComment
                employeesData={employeesData}
                employeeId={p.employee_id}
                comment={p}
                anonymous={Anonymous_Checker()}
              />
              <div style={{ display: 'flex', padding: '0 20px 20px 20px' }}>
                <Icon name="quote left" style={{ color: '#d8d8d8', fontSize: 20 }} />
                <Comment>
                  {p.comment}
                </Comment>
              </div>

              {/* <See onClick={()=>setShow(!show)}>Show metrics <Icon name={show?"chevron up":"chevron down"}/></See>
      <Hide show={show}>
       
        <DistributionTitle>Trust Score Distribution</DistributionTitle>
         <Chart options={data.options} series={data.series} type="bar" height={200}/>
      </Hide> */}


            </Container>
          }

        }) : ""}


      </CommentContainer>
    </div>
  )
}

export default Individual

function EmployeeComment({ employeesData, employeeId, comment, anonymous }) {

  const [employeeInfo, setEmployeeInfo] = React.useState([])

  React.useEffect(() => {
    setEmployeeInfo(
      employeesData?.filter(e => e.id === employeeId)[0]
    )
  }, [employeesData, employeeId])

  // console.log(employeeInfo)
  // console.log(comment)

  return (
    <User>
      <UserContainer>

        {anonymous ? <Icon name="user circle" style={{ fontSize: 30, marginTop: 5 }} />
          :
          employeeInfo?.picture ?
            <Avatar src={employeeInfo.picture} />
            : <Icon name="user circle" style={{ fontSize: 40, marginTop: 10, marginRight: 0 }} />}

        <div>
          <Name>{anonymous ? '' : employeeInfo?.first_name + ' ' + employeeInfo?.last_name}</Name>
        </div>
      </UserContainer>
      <Score>
        {Math.floor(comment?.importance_zscore?.culture_score_importance.importance)}%
        <Impact>Importance</Impact>
      </Score>
    </User>
  )

}

const Container = styled.div`
	width:350px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin-right:10px;
  margin-top:20px;

  
`

const Average = styled.div`
   font-family: 'Red Hat Display', sans-serif;
   font-size:12px;
   margin-left:10px;
`

const View = styled.div`
    font-family: 'Red Hat Display', sans-serif;
   font-size:12px;
   margin-left:10px;
`

const UserContainer = styled.div`
  display:flex;
`
const Title = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  margin-bottom:10px;
  font-weight:bold;
  font-size:20px;
  
`
const DistributionTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:20px;
  margin-bottom:0px;
  font-weight:500;
  color:#4a4a4a;
  margin-left:10px;
`

const CommentTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  margin-bottom:0px;
  font-weight:500;
`


const Name = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
  margin-left:10px;
  font-size:18px;
`


const Description = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:10px;
`

const Question = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;

	margin-bottom:50px;
  line-height:1;
`

const Avatar = styled.img`
  height:50px;
  width:50px;
  border-radius:25px;
`



const User = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
    padding:15px 20px;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  font-weight:bold;
  position:absolute;
  top:15px;
  right:20px;

`

const Impact = styled.div`
  font-size:9px;
  font-weight:900;
  text-transform:uppercase;
  margin-top:-3px;
`
const Comment = styled.div`
 font-family: 'Red Hat Display', sans-serif;
font-size:18px;
line-height:1.3;
font-weight:300;
padding:0px 20px 20px 10px;
`

const Hide = styled.div`
  max-height:${props => props.show ? '400px' : '0px'};
  overflow:hidden;
  transition:all 1s;
  padding:${props => props.show ? '0px 20px 20px 0' : '0px'};
`

const See = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  cursor:pointer;
  padding:20px;

  &:hover{
    text-decoration:underline;
  }
`


const CommentContainer = styled.div`
  display:flex;
  flex-wrap:wrap;
`

const data = {

  series: [{
    name: 'Trust',
    data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
      5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
      48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
    ]
  }],
  options: {
    chart: {
      type: 'bar',
      height: 350,
      sparkline: {
        enabled: true
      },
      toolbar: { show: false }
    },
    grid: {
      padding: {
        right: 125
      }
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: -1,
            to: 100,
            color: '#6a00ff'
          }, {
            from: -100,
            to: -1,
            color: '#ff2d55'
          }]
        },
        columnWidth: '80%',
      }
    },
    annotations: {
      yaxis: [
        {
          y: 0,
          borderColor: '#e9e9e9',
          offsetX: 125,
          strokeDashArray: 0,
          label: {
            borderColor: '#00E396',
            borderWidth: 0,
            offsetX: 125,
            style: {
              color: '#4a4a4a',
              fontFamily: 'Red Hat Display, sans-serif',
              fontSize: 14
            },
            text: 'Average Trust 73%'
          }
        }
      ]
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: 'Growth',
      },
      labels: {
        formatter: function (y) {
          return y.toFixed(0) + "%";
        }
      }
    },
    xaxis: {
      type: 'datetime',
      categories: [
        '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
        '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
        '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
        '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
        '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
        '2013-07-01', '2013-08-01', '2013-09-01'
      ],
      labels: {
        rotate: -90
      }
    }
  },


};