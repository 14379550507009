import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

import {
  Oval,
  Inner
} from "../styled-list";

const Selection = (props) => {
  return (
    <Oval color={props.color} onClick={() => props.onClick()}>
      <Inner color={props.selected}>
        <Icon
          name="check"
          size={"small"}
          style={{ margin: 0, color: "white" }}
        />
      </Inner>
    </Oval>
  );
};

export default Selection