import styled, { keyframes } from "styled-components";

import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import VisProvider, { VisGraph, useVis } from "vis-network-hook";
import { Icon, Progress, Dimmer, Loader } from 'semantic-ui-react'
import { Network } from 'vis-network';
import Empty from '404/Empty'

// import {
//   SG_GET_MOODS,
//   LOAD_EMPLOYEES,
//   LOAD_TEAMS,
//   SG_GET_CULTURE_DATA
// } from "constants/actions";




const DemoConnectionData = {
  nodes: [
    { id: 1, value: 50, label: "Deepesh Krishnan", shape: "circularImage", color: '#08c251', image: "https://randomuser.me/api/portraits/men/20.jpg" },
    { id: 2, value: 100, label: "Ellen Bawol", shape: "circularImage", color: '#08c251', image: "https://randomuser.me/api/portraits/women/29.jpg" },
    { id: 3, value: 30, label: "Camden Springer", shape: "circularImage", color: '#08c251', image: "https://randomuser.me/api/portraits/men/21.jpg" },
    { id: 5, value: 200, label: "Ron Ball", shape: "circularImage", color: '#6a00ff', image: "https://randomuser.me/api/portraits/men/83.jpg" },
    { id: 6, value: 22, label: "Alice McDonough", shape: "circularImage", color: '#6a00ff', image: "https://randomuser.me/api/portraits/women/35.jpg" },
    { id: 7, value: 100, label: "Kim Woo", shape: "circularImage", color: '#6a00ff', image: "https://randomuser.me/api/portraits/women/60.jpg" },
    { id: 8, value: 22, label: "Alyssa Lunda", shape: "circularImage", color: '#08c251', image: "https://randomuser.me/api/portraits/women/16.jpg" },
    { id: 9, value: 22, label: "Bridget Crosby", shape: "circularImage", color: '#6a00ff', image: "https://randomuser.me/api/portraits/women/57.jpg" },
    { id: 10, value: 22, label: "Jennifer Davis", shape: "circularImage", color: '#3078ff', image: "https://randomuser.me/api/portraits/women/21.jpg" },
    { id: 11, value: 100, label: "Bryce Crosby", shape: "circularImage", color: '#6a00ff', image: "https://randomuser.me/api/portraits/men/22.jpg" },
    { id: 12, value: 22, label: "David Blaine", shape: "circularImage", color: '#3078ff', image: "https://randomuser.me/api/portraits/men/23.jpg" },
    { id: 13, value: 22, label: "Paige Tully", shape: "circularImage", color: '#3078ff', image: "https://randomuser.me/api/portraits/women/26.jpg" },
  ],
  edges: [
    { from: 2, to: 3, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 3, to: 1, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 1, to: 4, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 2, to: 1, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 4, to: 3, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 4, to: 2, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 2, to: 5, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 7, to: 9, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 9, to: 6, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 6, to: 8, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 7, to: 6, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 8, to: 9, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 8, to: 7, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 7, to: 5, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 11, to: 13, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 13, to: 10, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 10, to: 12, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 11, to: 10, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 12, to: 13, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 12, to: 11, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 11, to: 5, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 2, to: 7, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 2, to: 11, color: 'rgba(106, 0, 255,0.4)', dashes: true },
    { from: 11, to: 7, color: 'rgba(106, 0, 255,0.4)', dashes: true },
  ]
};

const noImg = 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7'

var changeChosenEdgeSize = function (values, id, selected, hovering) {
  values.width = 10;
  values.opacity = 1;


};

var changeChosenLabelSize = function (values, id, selected, hovering) {
  values.font = {
    size: 50,
    background: '#343434'
  }
};

const options = {
  height: "600px",
  width: "100%",
  nodes: {
    borderWidth: 4,
    borderWidthSelected: 8,
    brokenImage: 'https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/avatar_blank.png?alt=media&token=42465057-e35c-4e1e-8145-56cc3e879eb7',
    labelHighlightBold: true,
    font: '30px arial black',
  },
  edges: {
    width: 2,
    chosen: { edge: changeChosenEdgeSize },
    labelHighlightBold: true,
  },
  physics: {
    forceAtlas2Based: {
      damping: 0.90
    },
    minVelocity: 0.75,
    solver: 'forceAtlas2Based'
  }
}





const ClusterAnalysis = ({ selEmp, culture_data, employees_data }) => {
  const container = useRef();
  // const dispatch = useDispatch();
  // const { edges, nodes, network } = useVis();

  // const [cultureData, setCultureData] = useState([]);
  const [loading, setLoading] = useState(true)
  const [empty, setEmpty] = useState(false)
  // const [stop, setStop] = useState(false)
  const [progress, setProgress] = useState(0)
  const [graphNetwork, setGraphNetwork] = useState(false)

  const [graphNetworkList, setGraphNetworkList] = useState(false)
  const [teamConnect, setTeamConnect] = useState(false);
  const [teamConnectMessage, setTeamConnectMessage] = useState(false);
  const [teamCommunityMessage, setTeamCommunityMessage] = useState(false);

  const [noGraph, setNoGraph] = useState(true)
  const [graphData, setGraphData] = useState({ nodes: [], edges: [] })

  // useEffect(() => {
  //   // Run! Like go get some data from an API.
  //   dispatch({ type: SG_GET_MOODS });
  //   dispatch({ type: LOAD_EMPLOYEES });
  //   dispatch({ type: LOAD_TEAMS });
  //   dispatch({ type: SG_GET_CULTURE_DATA });
  // }, [dispatch]);

  // const { culture_data, employees_data } = useSelector(
  //   (state) => ({
  //     culture_data: state.culture.culture,
  //     employees_data: state.employees.employees,

  //   }),
  //   shallowEqual
  // );
  useEffect(() => {
    const _list = []
    if (culture_data && culture_data.length > 0) {
      culture_data.map(cd =>
        _list.push(cd.graph_network)
      )
    }

    setGraphNetworkList(_list)
  }, [culture_data]);

  // console.log(culture_data[0]?.graph_network)
  // console.log(selEmp)

  useEffect(() => {
    if (culture_data && culture_data.length > 0) {
      // console.log(culture_data)
      setGraphNetwork(culture_data[0]?.graph_network)
      if (employees_data.length > 0) {
        // setStop(true)
        // setCultureData(culture_data[0]?.connections);
        // console.log(culture_data)

        const newNodes = []
        const newEdges = []

        if (culture_data[0]?.connections?.edges.length > 0) {

          const myEmployeeListSet = new Set([]);

          culture_data[0].connections.edges.map(en => {
            newEdges.push(
              {
                from: en.from,
                to: en.to,
                color: 'rgba(106, 0, 255,0.4)',
                dashes: true
              }
            )
            myEmployeeListSet.add(en.from)
            myEmployeeListSet.add(en.to)
          })

          // console.log(newEdges)
          // function logSetElements(value1) {
          //   console.log(` ${value1}`);
          // }
          // console.log(employees_data)

          if (employees_data) {
            myEmployeeListSet.forEach(key => {
              const Employee = employees_data.filter(em => em.id === key)[0]
              // console.log(key)
              // console.log(Employee)
              if (Employee) {
                newNodes.push({
                  id: Employee.id,
                  shape: "circularImage", color: '#08c251',
                  label: Employee.first_name ? `${Employee.first_name} ${Employee.last_name}` : Employee.email.split('@')[0],
                  image: Employee.picture ? Employee.picture : noImg,
                  size: 50
                })
              }

            });
            // }


            // Object.keys(culture_data[0].connections.nodes).map((key, k) => {
            //   //  console.log(key))
            //   // myEmployeeListSet.map((key) => {
            //   const Employee = employees_data.filter(em => em.id === culture_data[0].connections.nodes[key].employee_id)[0]

            //   // const Employee = employees_data.filter(em => em.id === key)
            //     newNodes.push({
            //       id: Employee.id,
            //       shape: "circularImage", color: '#08c251',
            //       label: Employee.first_name ? `${Employee.first_name} ${Employee.last_name}` : Employee.email.split('@')[0],
            //       image: Employee.picture ? Employee.picture : noImg,
            //       size: 50
            //     })
            // newNodes.push({
            //   id: Employee?.id,
            //   shape: "circularImage", color: '#08c251',
            //   label: Employee?.first_name ? `${Employee?.first_name} ${Employee?.last_name}` : Employee?.email?.split('@')[0],
            //   image: Employee?.picture ? Employee?.picture : noImg,
            //   size: 50
            // })


            //     return null


            // })
            // })

            // setCultureData({ nodes: newNodes, edges: newEdges })  
            // console.log(newEdges,newNodes)
            const network = container.current &&
              new Network(container.current, { nodes: newNodes, edges: newEdges }, options);



            network.on("stabilizationProgress", function (params) {
              setProgress(Math.floor(params.iterations / 10))
            })


            network.once("stabilizationIterationsDone", function () {
              setLoading(false)

            });
            setNoGraph(false)
            setGraphData({
              nodes: newNodes, edges: newEdges
            })
          }
          else {
            setLoading(false)
            setEmpty(true)

          }

        }
      }
    }
  }, [culture_data, employees_data]);

  // console.log(graphData)
  // console.log(DemoConnectionData)

  // if (graphNetworkList[0]?.connectivity) {
  //   console.log(Object.values(graphNetworkList[0]?.connectivity))
  // }

  function FullName() {
    // console.log(selEmp?.first_name)
    if (selEmp?.first_name) {
      return `${selEmp.first_name} ${selEmp.last_name}`
    }
    return selEmp?.email?.split('@')[0]
  }

  useEffect(() => {
    let LastCon = 0
    let LastAverage = 0
    if (graphNetworkList[0]?.connectivity) {
      // console.log(Object.values(graphNetworkList[0]?.connectivity))
      LastCon = (Object.values(graphNetworkList[0]?.connectivity).reduce((a, b) => a + b, 0))
      LastAverage = Math.round((LastCon / Object.values(graphNetworkList[0]?.connectivity).length) * 100)
    }

    let PrevCon = 0
    let PrevAverage = 0

    if (graphNetworkList?.length > 1) {
      if (graphNetworkList[1]?.connectivity) {
        // console.log(Object.values(graphNetworkList[1]?.connectivity))
        PrevCon = (Object.values(graphNetworkList[1]?.connectivity).reduce((a, b) => a + b, 0))
        PrevAverage = Math.round((PrevCon / Object.values(graphNetworkList[1]?.connectivity).length) * 100)
      }
    }

    // console.log(LastCon)
    // console.log(LastAverage)
    // console.log(PrevCon)
    // console.log(PrevAverage)
    setTeamConnect(LastAverage)
    // // increased <strong>+10% this week.</strong>
    if (LastAverage === PrevCon) {

      setTeamConnectMessage(<strong>remained same as last week</strong>)
    }
    if (LastAverage > PrevAverage) {

      setTeamConnectMessage(
        <strong>increased {Math.round((LastAverage - PrevAverage))}% this week </strong>)
    }
    if (LastAverage < PrevAverage) {

      setTeamConnectMessage(
        <strong>decreased {Math.round((LastAverage - PrevAverage))}% this week</strong>
      )
    }
    if (graphNetworkList[0]?.community_index) {
      const LastComInd = graphNetworkList[0]?.community_index
      let PrevComInd = 0

      if (graphNetworkList.length > 1) {
        PrevComInd = graphNetworkList[1].community_index

      }
      if (LastComInd === PrevComInd) {
        setTeamCommunityMessage(<strong>remained same this week.</strong>)
      }
      if (LastComInd < PrevComInd) {
        setTeamCommunityMessage(<strong>decreased {Math.round((LastComInd - PrevComInd) * 100)}% this week.</strong>)
      }
      if (LastComInd > PrevComInd) {
        setTeamCommunityMessage(<strong>increased {Math.round((LastComInd - PrevComInd) * 100)}% this week.</strong>)
      }

    }
  }, [graphNetworkList]);


  return (
    <Container>
      {empty ?
        <Empty /> :

        <Card>
          <Title>Team Connections Map</Title>
          <Description>Your team's mapping of work connections based on who works most closely together.</Description>

          <Dimmer.Dimmable blurring dimmed={loading}>
            <Dimmer active={loading} inverted>
              <Progress percent={progress} indicating progress style={{ width: 500 }} size="small" />
            </Dimmer>
            <div ref={container} style={{ height: '600px', width: '100%' }} />

          </Dimmer.Dimmable>

          {/* <VisProvider graph={DemoConnectionData} options={options} >
          <VisGraph />
        </VisProvider> */}

          {/* {noGraph && graphData && <h3>No Network Graph</h3>}
			<VisProvider graph={graphData} options={options} >
				<VisGraph />
			</VisProvider> */}

          <CardContainer>
            <InfoCard>

              <IconContainer color={'#F12DA2'}>
                <Icon name={'fork'} />
              </IconContainer>
              <AnalysisTitle>Team Connectivity</AnalysisTitle>
              <AnalysisScore>{teamConnect}%</AnalysisScore>
              <Text>Your team's connectivity has {teamConnectMessage} Connectivity
                measures the average connection each team member has to the entirety of this team.</Text>
            </InfoCard>

            {graphNetwork?.community_index ?
              <InfoCard>
                <IconContainer color={'#F12DA2'}>
                  <Icon name={'sitemap'} />
                </IconContainer>
                <AnalysisTitle>Community Index</AnalysisTitle>
                <AnalysisScore>{Math.round(graphNetwork?.community_index * 100)}%</AnalysisScore>
                <Text>Your team's community index score has
                  {" "} {teamCommunityMessage} Community index is a measure of the amount of smaller communities contained within your team. A higher community index score represents the prescence of many micro communities.</Text>
              </InfoCard> : ""}

            {/*<InfoCard>
              <IconContainer color={'#F12DA2'}>
                <Icon name={'comments outline'} />
              </IconContainer>
              <AnalysisTitle>Communication Pathways</AnalysisTitle>
              <Text>Your team's communication pathways describe how each person is connected within the network. Each value is represented by the number of connections required to navigate from one individual to another.</Text>

              <Keyword>
                Furthest Connection
                <StyledProgressBar value={graphNetwork?.furthest_connection} total={3} progress="value" size={"small"} color="rgba(241, 45, 162,0.6)" />
              </Keyword>
              <Keyword>
                Average Maximum Distance
                <StyledProgressBar value={graphNetwork?.average_maximum_distance} total={3} progress="value" size={"small"} color="rgba(241, 45, 162,0.6)" />
              </Keyword>
              <Keyword>
                Average Shortest Pathway
                <StyledProgressBar value={graphNetwork?.average_shortest_pathway} total={3} progress="value" size={"small"} color="rgba(241, 45, 162,0.6)" />
              </Keyword>
            </InfoCard>

            <InfoCard>
              <IconContainer color={'#F12DA2'}>
                <Icon name={'connectdevelop'} />
              </IconContainer>
              <AnalysisTitle>Network Centrality</AnalysisTitle>
              <Keyword>
                Centralization (Moderate)
                {graphNetwork?.degree_centrality}
                <StyledProgressBar percent={86} progress size={"small"} color="rgba(241, 45, 162,0.6)" />
                <Text>Higher levels of centralization in a team represent more uniform flows of information</Text>

              </Keyword>
              <Keyword>
                Central/Peripheral Ratio

                <StyledProgressBar
                  value={graphNetwork?.centralization?.length / graphNetwork?.peripheral_ratio?.length}
                  total={6} progress="ratio" size={"small"} color="rgba(241, 45, 162,0.6)" />
                <Text>A representation of the # of individuals located at the center of your network versus those located on the periphery. Central team members are more connected and represent higher levels of importance within the network.</Text>
              </Keyword>
            </InfoCard>*/}
          </CardContainer>

        </Card>
      }
    </Container>
  )
}



export default ClusterAnalysis



const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:bold;
	margin-bottom:5px;
`

const Card = styled.div`
 	width:100%;


  @media(max-width:1000px){
    width:100%;
  }
`
const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;

`

const Legend = styled.div`
  display:flex;
`

const Cluster = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  margin-right:10px;
`

const Circle = styled.div`
  height:16px;
  width:16px;
  border-radius:8px;
  background-color:${props => props.color};
  margin-right:5px;
`

const Container = styled.div`
  display:flex;
  flex-wrap:wrap;

`

const InfoCard = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding:20px;
  display:flex;
  flex-direction:column;
  width:calc(50% - 10px);
  position:relative;

  margin-bottom:20px;

  @media(max-width:900px){
    width:350px;
  }


   @media(max-width:768px){
    width:100%;
  }
`

const AnalysisTitle = styled.div`
  font-size:18px;
  font-weight:bold;
  margin-bottom:10px;
`

const Text = styled.div`
  font-weight:400;


@media(max-width:768px){
    line-height:1.3;
    font-size:18px;
  }
`

const AnalysisScore = styled.div`
  font-size:22px;
  font-weight:bold;
  margin-bottom:10px;
`

const CardContainer = styled.div`
  display:flex;
  margin-top:30px;
  font-family: 'Red Hat Display', sans-serif;
  justify-content:space-between;
  flex-wrap:wrap;
`

const ColorBar = styled.div`
  width:100px;
  background-color:${props => props.color};
  height:5px;
  position:absolute;
  top:0;
  left:0;
`

const IconContainer = styled.div`
  height:50px;
  font-size:50px;
  color:${props => props.color};
  margin-top:20px;
`
const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || 'green'} !important;
  }
   margin-bottom:5px !important;
`;

const Keyword = styled.div`
  margin-top:10px;
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
`