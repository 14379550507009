import React from "react";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import styled from "styled-components";

const LoadingSpinner = (showBg) => {
  return (
    <LoadingWrapper>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  height: 500px;
  left: 50%;
  position: absolute;
  align-self: center;
`;

export default LoadingSpinner;
