import React, {useEffect , useLayoutEffect,useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from "react-router-dom"

const Container = styled.div`
  border-radius: 15px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  display:flex;
  flex-direction:row;
  padding:10px;
  width:100%;
  margin-top:20px;
  align-items:center;
  position:relative;

  @media (min-width:768px){
  	display:none;
  }
`

const IMG = styled.img`
	height:35px;
	width:35px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size: 16px;
  font-weight: bold;
  margin-left:16px;
`

const View = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  font-family: 'Red Hat Display', sans-serif;

`

const MobileCard = (props) =>{

	return (
		<Container>
			<Title>{props.title}</Title>
		    <Link to={props.link} style={{position:'absolute',right:'20px'}}><View>View &gt;</View></Link>
		</Container>
		)
}

export default MobileCard