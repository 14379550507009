import {
  GET_USER_TASK_RESPONSE,
  ADD_USER_TASK_RESPONSE,
  UPDATE_USER_TASK_RESPONSE,
} from "constants/actions";

const initialState = {
  task_responses: [],
  pending: false,
  response: null,
  created: [],
};

export default function TaskResponses(state = initialState, action) {
  switch (action.type) {
    case GET_USER_TASK_RESPONSE:
      return {
        ...state,
        task_responses: action.payload,
        pending: false,
      };

    case UPDATE_USER_TASK_RESPONSE:
      const updatedTaskResponses = {
        ...state.task_responses,
        results: state.task_responses.results.map((row) =>
          row.id === action.payload.id
            ? {
                ...row,
                ...action.payload,
              }
            : row
        ),
      };
      return {
        ...state,
        task_responses: updatedTaskResponses,
      };

    case ADD_USER_TASK_RESPONSE:
      const newTaskResponses = {
        ...state.task_responses,
        results: [...state.task_responses.results, action.payload],
      };
      return {
        ...state,
        created: action.payload,
        task_responses: newTaskResponses,
        response: action.payload.response,
      };
    default:
      return state;
  }
}
