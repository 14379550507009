import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";


import { Dropdown, Icon } from 'semantic-ui-react'
import Feeling from './Feeling'
// import Theme from './Theme'
import SystemThemes from './SystemThemes'

import { MoodMainMenu } from "data/live/moodMenu";
import format from "date-fns/format";
import Empty from "./Empty"

import {
  SG_GET_MOODS,
  LOAD_EMPLOYEES,
} from "constants/actions";

import ChartDonutSystems from "./ChartDonutSystems"
import ChartLineSystems from "./ChartLineSystems"

// const NavOptions = [{text:'Enjoyment',value:1},{text:'Motivation',value:2},{text:'Curiosity',value:3},{text:'Stress',value:4},
//             {text:'Discontent',value:5},{text:'Anger',value:6}]

const colors = ['rgba(48, 120, 255,1)', 'rgba(8, 194, 81,1)', 'rgba(213, 0, 178,1)', 'rgba(255, 184, 18,1)', 'rgba(126, 36, 234,0.5)', 'rgba(255, 45, 85,1)']

const NewSystems = (props) => {
  const { selectedMood, showBack, back, selectedTeamId } = props
  const dispatch = useDispatch();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const name = params.get('name') ? params.get('name') : "Curiosity";
  const [emotionName, setEmotionName] = useState([]);
  const [emotionScore, setEmotionScore] = useState([]);
  const [emotionColors, setEmotionColors] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([])

  const [moodData, setMoodData] = useState([]);
  // const [moodName, setMoodName] = useState("Curiosity");
  const [moodChartData, setMoodChartData] = useState([]);
  const [showTeamId, setShowTeamId] = useState(false)

  const [selectedMenu, setSelectedMenu] = React.useState({
    "id": 1,
    "index": 0,
    "name": "Curiosity",
    "colors": ['rgba(8, 194, 81,0.6)', 'rgba(8, 194, 81,0.2)', 'rgba(8, 194, 81,0.4)', 'rgba(8, 194, 81,0.8)', 'rgba(8, 194, 81,1)']
  })

  const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)
  const EmployeeId = useSelector((state) => Number(state.auth?.employee_id), shallowEqual)

  const { get_mood_data,  get_selectedOrg,
    get_employee_data  } = useSelector(
      (state) => ({
        // get_emotion_data: state.moods, // from server
        get_mood_data: state.moods.moods, // from server
        get_employee_data: state.employees,
        get_position: state.moods.position,
        get_selectedOrg: state.selectedOrg
      }),
      shallowEqual
    );

    useEffect(() => {
      if (EmployeeId > 0) {
        setShowTeamId(selectedTeamId? selectedTeamId : get_employee_data?.[EmployeeId]?.team)
      }
      if (Number(AmbassadorId) > 0) {
        setShowTeamId(selectedTeamId)
      }
  
    }, [selectedTeamId, EmployeeId, AmbassadorId, get_employee_data]);
  

    // console.log("selectedTeamId", selectedTeamId)

  useEffect(() => {
    // Run! Like go get some data from an API.
    let ShowTeams = ''
    if (Number(AmbassadorId) > 0) {
      if (showTeamId) {
        ShowTeams = `&team=${showTeamId}`
      }
      const OrgId = get_selectedOrg?.organization?.id
      if (OrgId) {
        dispatch({
          type: LOAD_EMPLOYEES,
          payload: `organization=${OrgId}${ShowTeams}`
        });

        dispatch({
          type: SG_GET_MOODS,
          payload: `organization=${OrgId}${ShowTeams}`
        });
      }

    } else {
      if (showTeamId) {
        ShowTeams = `team=${showTeamId}`
      }
      dispatch({
        type: LOAD_EMPLOYEES,
        payload: `${ShowTeams}`
      });
      dispatch({
        type: SG_GET_MOODS,
        payload: `${ShowTeams}`
      }); //
    }

    // dispatch({ type: SG_GET_MOOD_TEAMS, payload: "team-dash=true" });
    // dispatch({ type: SG_GET_MOOD_ORG }); // from server
    // dispatch({ type: SG_GET_MOOD_ORG, payload: "org=true" });

  }, [dispatch, get_selectedOrg, AmbassadorId, showTeamId]);
  // anything with mood is from server
  // anything with emotion is from local


  // const get_mood_data = []
  // console.log(get_mood_data)
  useEffect(() => {
    // if (get_mood_data?.length > 0) {
    //   const Pos = get_mood_data?.length
    // console.log(Pos)
    setMoodData(get_mood_data[0])
    // setMoodData([])
    // }
    // console.log(moodList)
    // console.log(dateList)
  }, [get_mood_data]);

  useEffect(() => {
    const dateList = []
    const moodList = []
    if (name) {
      let mood = selectedMood? selectedMood: name
      const SelectedMenu = (MoodMainMenu.filter(p => p.name.toLowerCase() === mood.toLowerCase())[0])
      setSelectedMenu(SelectedMenu)
      setEmotionColors(SelectedMenu.colors)
      if (get_mood_data?.length > 0) {
        get_mood_data.map(p => {
          dateList.push(format(
            new Date(p?.on_date.toLocaleString()),
            "MMM do yyyy"
          ))
          moodList.push(Math.round(p.spectrum_score[toTitleCase(name)]?.average))
          // console.log(p.spectrum_score[toTitleCase(name)]?.score)
        })
      }
      // console.log(moodList)
      setMoodChartData({ moodList, dateList })
      // setMoodName(name)
    }

  }, [get_mood_data, name,selectedMood]);

  function ChangeSelection(p) {
    // console.log(p)
    
    const SelectedMenu = (MoodMainMenu.filter(s => s.id === p.id)[0])
    setSelectedMenu(p)
    // setMoodName(p)
    setEmotionColors(SelectedMenu.colors)

    const dateList = []
    const moodList = []
    // console.log(p)
    if (p.name) {
      if (get_mood_data?.length > 0) {
        get_mood_data.map(md => {
          dateList.push(format(
            new Date(md?.on_date.toLocaleString()),
            "MMM do yyyy"
          ))
          moodList.push(Math.round(md.spectrum_score[p.name]?.average))
        })
      }

      // console.log(moodList)

      setMoodChartData({ moodList, dateList })
    }
    // console.log(SelectedMenu)
  }

  useEffect(() => {
    setDropDownOptions(MoodMainMenu.map(mn => ({
      key: mn.id,
      text: mn.name,
      value: mn.name,
    })))
  }, []);
  // const dropDownOptions = 

  function DropDownChanged(p) {
    const SelectedDropMenu = (MoodMainMenu.filter(s => s.name === p)[0])
    ChangeSelection(SelectedDropMenu)
  }

  //   "id": 2,
  //   "index": 1,
  // "name": "Enjoyment",
  //   "colors"
  // console.log(moodData?.category_list)

  useEffect(() => {
    if (moodData?.category_list) {
      const CategoryList = moodData?.category_list.filter(
        md => md.name === selectedMenu?.name)[0]?.emotions
      const Name = []
      const Score = []

      if (CategoryList) {
        CategoryList.map(p => {
          Name.push(p.name)
          Score.push(p.count)
        })
      }
      setEmotionName(Name)
      setEmotionScore(Score)
    }

  }, [selectedMenu, moodData]);

  // console.log(emotionName)
  // console.log(moodChartData)
  // console.log(moodData?.category_detailed)

  return (
    <Container>
      {get_mood_data.length === 0 ?
        <Empty /> :
        <>  <CardTop>
          {
            showBack ?
              <Back onClick={() => back()}><Icon name="chevron left" /> Back</Back>
              : ""
          }
          <Nav>
            {MoodMainMenu.map((p, k) => {
              return <NavItem key={k} 
              selected={p.id === selectedMenu.id}
                onClick={() => ChangeSelection(p)} color={emotionColors[2]}>
                {p.name} ({moodData?.category_list?.filter(c => c.name === p.name)[0]?.count})

              </NavItem>
            })}


          </Nav>

          <MobileNav>
            <Dropdown
              inline
              options={dropDownOptions}
              defaultValue={dropDownOptions[0]?.value}
              onChange={(e, v) => DropDownChanged(v.value)}
            />
          </MobileNav>
        </CardTop>

          <Card width={60}>
            <Title>{selectedMenu?.name} System Trends</Title>
            <ChartLineSystems colors={emotionColors}
              moodChartData={moodChartData}
              name={selectedMenu?.name} />

          </Card>
          <Card width={40}>
            <Title>{selectedMenu?.name} System Breakdown</Title>
            <ChartDonutSystems
              names={emotionName}
              score={emotionScore}
              colors={emotionColors}
            />

          </Card>

          <Card width={60}>
            <Title>{selectedMenu?.name} Comments</Title>
            <Feeling CommentsData={moodData?.category_detailed}
              selected={selectedMenu}
              color={emotionColors[2]}
              team_type={moodData?.team_type}
              employeeData={get_employee_data?.employees} />

          </Card>

          <Card width={40}>
            <Title>{selectedMenu?.name} Themes</Title>
            <SubText>Themes help you see the deeper trends that are present in this emotion category. Themes with a higher impact are contributing to larger overall changes in the emotions being felt on your team.</SubText>
            <SystemThemes
              CommentsData={moodData?.category_detailed}
              selected={selectedMenu}
            />

          </Card>
        </>}


    </Container>
  )
}


export default NewSystems

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const Card = styled.div`
 	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 	padding:30px 20px;
 	width:${props => 'calc(' + props.width + '% - 20px)'};
 	margin-right:20px;
 	margin-top:20px;


  @media (max-width:900px){
    width:100%;
    padding:20px;
    margin-right:0px;
  }

`

const SubText = styled.div`
  font-family: 'Red Hat Display', sans-serif;

`

const CardColor = styled.div`
 	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
 	padding:30px 20px;
 	width:${props => 'calc(' + props.width + '% - 20px)'};
 	margin-right:20px;
 	margin-top:20px;
 	background-image:linear-gradient(to bottom right, rgba(8, 194, 81,1),rgba(8, 194, 81,0.5));
 	color:white;
 	position:relative;

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:bold;
	margin-bottom:20px;
`

const Container = styled.div`
	display:flex;
	flex-wrap:wrap;
`



const TopicTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
	margin-bottom:10px;
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`

const Elements = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
	margin-top:10px;
	margin-bottom:10px;
`
const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
	margin-top:10px;
`
const Score = styled.div`

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:18px;
  color:white;
  font-weight:bold;
`
const Top = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
`
const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:-3px;
`

const Li = styled.li`
	font-family: 'Red Hat Display', sans-serif;
`

const CardTop = styled.div`
	  width:100%;
	  display:flex;
	  flex-direction:column;
    margin-top:20px;
	  margin-bottom:10px;
`

const Nav = styled.div`
	width:100%;
	display:flex;

   @media(max-width:900px){
    display:none;
  }
`

const NavItem = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px 20px;
	height:50px;
	display:flex;
	align-items:center;
	color:${props => props.selected ? props.color : '#9b9b9b'};
	font-weight:${props => props.selected ? 'bold' : 400};
	border-bottom:${props => props.selected ? '3px solid ' + props.color : 'none'};
	cursor:pointer;
	font-size:16px;
`
const MobileNav = styled.div`
  font-size:24px;
  padding-bottom:10px;
  @media(min-width:900px){
    display:none;
  }
`

const Back = styled.div`
  cursor:pointer;
  font-size:18px;
  font-weight:bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:10px;
`