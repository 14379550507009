import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import People from 'assets/images/UI/people.svg'
import Chat from 'assets/images/UI/chat.svg'
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {getStructuredFeedback} from './calculations'
import TextLoading from './TextLoading'

import {
    SG_POST_ML_SERVER_V1,
  } from "constants/actions";

const Summary = ({data}) =>{
    const dispatch = useDispatch();

    const [feedback,setFeedback] = useState('')
    const [summary,setSummary] = useState('')
    const [takeaways,setTakeaways] = useState('')
    const get_chat_gpt_resp = useSelector((state) => state.chat_gpt.chat_gpt,shallowEqual);
    const get_chat_gpt = useSelector((state) => state.chat_gpt,shallowEqual);
    const [loading,setLoading] = React.useState(true)
    const [activate,setActivate] = React.useState(false)


    useEffect(()=>{
        if(data){
            setFeedback(getStructuredFeedback(data))
            setActivate(false)
            setSummary('')
        }
        
    },[data])

    useEffect(()=>{
        if(feedback && activate){
            const prompt =`Based on the feedback provided above, please explain how this organization culture scores turned out the way they did.
             Explain this in one summary parapgrah, about 4-5 sentences long, and then highlight immediate actions that can be taken based on the feedback in key takeaways. 
            Title the paragraph Summary: and the point form Key Takeaways:. Don't mention that this feedback is from a culture survey."`
          
            dispatch({
                type: SG_POST_ML_SERVER_V1,
                payload: {
                  input_text: feedback,
                  prompt: prompt,
                },
              });
        }

    },[feedback,activate])

    useEffect(()=>{
        console.log(get_chat_gpt)
        if(get_chat_gpt?.chat_gpt_ml?.response){
            let resp = get_chat_gpt?.chat_gpt_ml?.response
            const summaryRegex = /Summary:\s([\s\S]*?)(?=#|$)/;
            const takeawaysRegex = /Key Takeaways:\s([\s\S]*?)(?=#|$)/;

            const summaryMatch = resp.match(summaryRegex);
            const takeawaysMatch = resp.match(takeawaysRegex);

            const summary = summaryMatch ? summaryMatch[1].trim() : "";
            const keyTakeaways = takeawaysMatch ? takeawaysMatch[1].trim() : "";

            setSummary(summary)
            setTakeaways(keyTakeaways)

        }

        if(get_chat_gpt.pending){
            setLoading(true)
        }
        else{
            setLoading(false)
        }
    },[get_chat_gpt])

    function formatPointsToLi(pointsString) {
        const pointsArray = pointsString.split('\n').map(point => point.slice(2));
        
        return pointsArray.map((point, index) => <li key={index}>{point}</li>);
    }

    if(!loading && !summary){
 
        return (<Square  onClick={()=>setActivate(true)}>
                <Button>Generate Summary</Button>
            </Square>)
    }


    return (
        <Container>
                        <Block color={'#EBF1FD'}><IMG src={Chat}/></Block>
                        <T2>
                            What lead to this score?
                        </T2>

                        <Text>
                       {loading? <TextLoading/> :summary}
                        </Text>

                        <Block color={'#EBF1FD'}><IMG src={People}/></Block>
                        <T2>
                        What should I focus on?
                        </T2>

                        <Text2>
                            Based on the scores and feedback from this tag, here is a summary of some immediate actions that can be taken:
                        </Text2>
                        <UL>
                           {loading? <TextLoading/>:formatPointsToLi(takeaways)}
                        </UL>

        </Container>
    )

}


export default Summary


const Container = styled.div`
    width:60%;
    margin-bottom:40px;
    margin-top:40px;
`

const Block = styled.div`
    width:40px;
    height:40px;
    background-color:${props=>props.color};
    border-radius: 8px;
    margin-right:12px;
    display:flex;
    align-items:center;
    justify-content:center;
`


const IMG = styled.img`
    max-height:20px;
`

const Text = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #2A3039;
margin-top:30px;
width:100%;
margin-bottom:40px;
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 20px;
color: #2A3039;
margin-top:8px;
`


const UL = styled.ul`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 150%;
color: #2A3039;
`

const Text2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 24px;
color: #2A3039;
margin-top:16px;
`

const Button = styled.div`
padding: 12px 24px;
border-radius: 8px;
background: #EBF1FD;
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
color:#2D70E2;
cursor:pointer;
`

const Square = styled.div`
    width:calc(40% - 40px);
    margin-left:40px;
    height:400px;
    display:flex;
    align-items:center;
    justify-content:center;

`
