import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import IMG from '../assets/evaluation.png'
import Chart from '../components/Chart'
import Footer from '../components/Footer'
import Side from '../components/Side'

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2E34D0",
    marginBottom: 15,
  },
  page: {
    width: "100%",
    height: "100vh",
    position: "relative",
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 15,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 15,
  },
  section: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#F1F1F7",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 16,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  S1: {
    fontSize: 10,
    marginRight: 15,
  },
  img: {
    height: 20,
  },
  offset: {
    width: "100%",
  },
  summary: {
    border: "2px solid #2C3644",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#2C3644",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
    marginTop: 20,
  },
  steps: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  square: {
    height: 6,
    width: 6,
    backgroundColor: "#2C3644",
    marginLeft: 20,
    marginRight: 10,
    marginTop: 5,
  },
});


const TableTemplate = {
	rows:[
			{'title':'Factors',width:'30%'},
			{'title':'N',width:'10%'},
			{'title':'Norm',width:'20%'},
			{'title':'Effect size',width:'20%'},
			{'title':'p-value',width:'20%'},
		],
	data:[
		['Inclusion','305','18th percentile','Very Large','0.001'],
		['Recruitment','305','18th percentile','Very Large','0.001'],
		['Representation','305','18th percentile','Very Large','0.001'],
		['Advancement','305','18th percentile','Very Large','0.001'],
		['Compensation','305','18th percentile','Very Large','0.001'],
		['Retention','305','18th percentile','Very Large','0.001']
	]
}


const Methods = () =>{


	return (
		<Page style={styles.page}>
			<Side/>
			<Footer/>
			<View>
			<Text style={styles.T2}>Report Methods</Text>
			<View style={styles.line}/>
{/*			 <View style={styles.summary}>
            	<View style={styles.top}>
            		<Text>Overall Score: 78% (B+)</Text>
            	</View>
            	<View style={styles.bottom}>
            		<Text style={{fontSize:'10',fontWeight:700,marginBottom:5}}>Factor Summary</Text>
            		<Table data={TableTemplate}/>
            		<Text style={{fontSize:'10',fontWeight:700}}>About this assessment</Text>
            		<Text style={{fontSize:10}}>Beef drumstick capicola, pork chop frankfurter tail rump picanha fatback pork. 
            		Tongue tenderloin bresaola beef ribs, ground round frankfurter chuck short loin kielbasa. 
            		Fatback bresaola drumstick rump tenderloin turkey, picanha beef shoulder jowl sirloin cow 
            		flank swine buffalo. Pork chop pastrami tenderloin kielbasa. Cow doner landjaeger short 
            		ribs, corned beef tenderloin kielbasa sirloin pancetta pig bresaola beef ground round meatball. 
            		Filet mignon flank t-bone picanha tri-tip.</Text>
            	</View>
            </View>*/}
            <Text style={styles.title}>Introduction</Text>

			<Text style={styles.p}> The methodology of the Sport Culture Index (SCI) is grounded in
            evidence supporting the notion that most meaningful sport culture
            variables fall into two primary categories: human factors (i.e.,
            dynamics, sentiments, and relationships) and system factors (i.e.,
            processes, practices, and structures). The SCI allows sport
            organizations to understand where they are indexing on the primary
            categories of human and system factors, helping them gain an
            understanding of their overall organizational approach.
            Further, the SCI provides detailed analytics and reporting on all
            supporting factors and sub-factors within each category, which can
            be further be analyzed by any number of custom demographics This
            report shows the aggregate scores of all culture pillars and
            questions in the system, organized by the segments selected on this
            report (i.e., role, gender, experience, etc.).The goal of this report is to provide reliable, 
            evidence-based information to guide cultural action steps and transformation
            strategies.</Text>


			<View wrap={false}>
			<Text style={styles.title}>Assessment Methodology</Text>

			<Text style={styles.p}> The methodology of the Sport Culture Index (SCI) is grounded in
            evidence supporting the notion that most meaningful sport culture
            variables fall into two primary categories: human factors (i.e.,
            dynamics, sentiments, and relationships) and system factors (i.e.,
            processes, practices, and structures). The SCI allows sport
            organizations to understand where they are indexing on the primary
            categories of human and system factors, helping them gain an
            understanding of their overall organizational approach.
            Further, the SCI provides detailed analytics and reporting on all
            supporting factors and sub-factors within each category, which can
            be further be analyzed by any number of custom demographics This
            report shows the aggregate scores of all culture pillars and
            questions in the system, organized by the segments selected on this
            report (i.e., role, gender, experience, etc.).The goal of this report is to provide reliable, 
            evidence-based information to guide cultural action steps and transformation
            strategies.</Text>
            </View>

            <Text>****Add in report segmentation table, use title tag for title</Text>

            <Text style={styles.title}>Next Steps</Text>

            <Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
					information appears on all nuLogic reports and is not specific to this organization's individual results</Text>

			<View style={styles.steps}>
				<View style={styles.square}/>
				<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
					information appears on all nuLogic reports and is not specific to this organization's individual results</Text>
			</View>

			<View style={styles.steps}>
				<View style={styles.square}/>
				<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
					information appears on all nuLogic reports and is not specific to this organization's individual results</Text>
			</View>

			<View style={styles.steps}>
				<View style={styles.square}/>
				<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
					information appears on all nuLogic reports and is not specific to this organization's individual results</Text>
			</View>

			<View style={styles.steps}>
				<View style={styles.square}/>
				<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
					information appears on all nuLogic reports and is not specific to this organization's individual results</Text>
			</View>
			
			</View>
		</Page>
		)
}


export default Methods