import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon, Loader } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// import questions from "./QuestionTemplate.json";

import {
  SG_POST_ML_SERVER_V1,
  SG_GET_TASK_TEMPLATES,
  SG_GET_TASK_SCHEDULES,
  SG_GET_USER_TASK_RESPONSE,
  SG_ADD_USER_TASK_RESPONSE,
  SG_UPDATE_USER_TASK_RESPONSE,
  SG_FETCH_TASK_TEMPLATE,
} from "constants/actions";

const GOAL =
  "We are trying to figure out where the red tape is in our company, and get feedback from each manager on how they can best remove that red tape.";
const NOTES = true;

const TaskQuestion = () => {
  const { id } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState();
  const [question, setQuestion] = useState(0);
  const [currentResponse, setCurrentResponse] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const get_chat_gpt = useSelector((state) => state.chat_gpt, shallowEqual);
  const task_templates = useSelector(
    (state) => state.task_templates?.task_templates,
    shallowEqual
  );
  const fetched_template = useSelector(
    (state) => state.task_templates?.fetched_template
  );
  const get_auth = useSelector((state) => state.auth, shallowEqual);
  const scheduled_tasks = useSelector(
    (state) => state.task_templates?.scheduled_tasks,
    shallowEqual
  );
  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  const get_employees = useSelector(
    (state) => state.audit?.employees,
    shallowEqual
  );

  const [loading, setLoading] = React.useState(false);
  const [activate, setActivate] = React.useState(false);
  const [currentAIQuestion, setCurrentAIQuestion] = React.useState(false);
  const [savedResponses, setSavedResponses] = React.useState([]);
  const [surveyStructure, setSurveyStructure] = React.useState([]);
  const [showComplete, setShowComplete] = React.useState(false);
  const [currentSurvey, setCurrentSurvey] = React.useState(0);
  const [scheduleId, setScheduleId] = React.useState();
  const [enableNotes, setEnableNotes] = React.useState(false);
  const isManager =
    core_data?.permissions?.account_type !== 5 &&
    get_employees?.userEmp?.id === Number(get_auth?.employee_id) &&
    get_employees?.userEmp?.employee_employee_permission?.access_level?.manager
      ?.active;

  const calculate_total_questions = () => {
    let total = 0;
    questions?.map((q) => {
      total += 1;
      if (q?.ai_follow_up || q?.aiEnhanced) {
        total += 1;
      }
    });
    return total;
  };

  const buildConversationThread = () => {
    let conversation = "";
    savedResponses.map((r) => {
      conversation += `Question: ${r.question.question}\nResponse: ${r.response}\n`;
    });
    return conversation;
  };

  const isUUID = (id) => {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(id);
  };

  useEffect(() => {
    if (isUUID(id)) {
      dispatch({
        type: SG_GET_TASK_SCHEDULES,
      });
    } else {
      dispatch({
        type: SG_GET_TASK_SCHEDULES,
        payload: id,
      });
    }
  }, [id]);

  useEffect(() => {
    if (isUUID(id) && scheduled_tasks?.results?.length) {
      const matchedTemplate = scheduled_tasks?.results?.find(
        (template) => template.task_ref_id === id
      );
      if (matchedTemplate) {
        dispatch({
          type: SG_GET_TASK_SCHEDULES,
          payload: matchedTemplate.id,
        });
      }
    }
  }, [scheduled_tasks]);

  useEffect(() => {
    if (scheduled_tasks?.id) {
      const questionSet =
        scheduled_tasks.task_template_user_tasks?.user_tasks?.filter(
          (t) => t.task_type == "question"
        );
      // Add feedback notes setting
      const notesEnabled =
        scheduled_tasks?.task_template_user_tasks?.user_tasks?.find(
          (task) => task.task_type === "feedback_notes"
        )?.selection;

      setEnableNotes(notesEnabled);
      const scheduledTaskId = scheduled_tasks?.task_template_user_tasks?.id;
      setScheduleId(scheduledTaskId);
      setQuestions(questionSet);
    }
  }, [scheduled_tasks, task_templates, fetched_template]);

  useEffect(() => {
    let _surveyStructure = [];
    if (questions?.length > 0) {
      questions?.map((q) => {
        _surveyStructure.push({
          question: q.question,
          ai_follow_up: false,
          id: q.id,
        });
        if (q?.ai_follow_up || q?.aiEnhanced) {
          _surveyStructure.push({ ai_follow_up: true, question: "", id: q.id });
        }
      });
      setSurveyStructure(_surveyStructure);

      setCurrentQuestion(_surveyStructure[question].question);
    }
  }, [questions]);

  useEffect(() => {
    if (currentResponse && activate) {
      setActivate(false);
      const history = buildConversationThread();
      const prompt = `Your goal is to come up with a really great follow up question in order to get the most out of the conversation. 
            The ultimate goal of this conversation as stated by the sender is: ${GOAL}

            Here is the conversation so far:
            ${history}
            
            Ask a great follow up question to get the best information possible in order to create action from this feedback and accomplish the stated goal.
            Make sure there is a logical flow from the previous question to this one. And make it as conversational as possible.
            ${
              enableNotes
                ? `Following your question, please provide feedback to the person answering the question on their responses to help them give me useful or actionable feedback.
                Any feedback should start with **Feedback:** and then your feedback. Always Capitalize Feedback in the formatting and always include the ** before and after the word Feedback: .Only return the follow up question and feedback. 
                Do not return the original conversation or any other information.`
                : "Only return the follow up question. Do not return the original conversation or any other information."
            }`;

      dispatch({
        type: SG_POST_ML_SERVER_V1,
        payload: {
          input_text: "",
          prompt: prompt,
        },
      });
      setCurrentResponse("");
    }
  }, [currentResponse, activate]);

  useEffect(() => {
    if (get_chat_gpt?.chat_gpt_ml?.response) {
      let resp = get_chat_gpt?.chat_gpt_ml?.response;
      let _surveyStructure = surveyStructure;
      _surveyStructure[question].question = resp;
      setSurveyStructure(_surveyStructure);
      setCurrentQuestion(resp);
    }

    if (get_chat_gpt.pending) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [get_chat_gpt]);

  const createResponseObject = (arr) => {
    return arr.reduce((acc, curr) => {
      // If the current id already exists in the accumulator, add the response to the follow_up property
      if (acc?.[curr.question.id]) {
        if (acc[curr.question.id].follow_up) {
          acc[curr.question.id].follow_up.push({
            response: curr.response,
            question: curr.question.question,
          });
        } else {
          acc[curr.question.id].follow_up = [
            {
              response: curr.response,
              question: curr.question.question,
            },
          ];
        }
        return acc;
      }
      acc[curr.question.id] = {
        response: curr.response,
        question: curr.question.question,
        ai_follow_up: curr.question.ai,
      };
      return acc;
    }, {});
  };

  const handleComplete = (responses) => {
    // Complete the survey
    let taskTemplateId = id;
    if (isUUID(id)) {
      taskTemplateId = scheduled_tasks.id;
    }
    const payloadStructure = {
      task_template: taskTemplateId,
      status: 11,
      employee: get_auth.employee_id,
      task_response: createResponseObject(responses),
    };

    dispatch({
      type: SG_ADD_USER_TASK_RESPONSE,
      payload: payloadStructure,
    });
  };

  const getNextQuestion = () => {
    if (!currentResponse) {
      return;
    }

    let _saved = savedResponses;
    if (savedResponses.length >= question) {
      // If the question already exists in the saved responses, add the new response under a new property (array)
      // Called follow_up.
      if (_saved[question]?.response) {
        _saved[question].follow_up = {
          response: currentResponse,
          question: surveyStructure[question],
        };
      } else {
        _saved[question] = {
          response: currentResponse,
          question: surveyStructure[question],
        };
      }
    } else {
      _saved.push({
        response: currentResponse,
        question: surveyStructure[question],
      });
    }

    if (question === surveyStructure.length - 1) {
      handleComplete(_saved);
      setShowComplete(true);
      return;
    }

    if (_saved[question + 1]?.response) {
      setCurrentResponse(savedResponses[question + 1].response);
      setActivate(false);
      setCurrentQuestion(surveyStructure[question + 1].question);
    }
    // Check the current question to see if there is an AI follow up
    else if (
      surveyStructure[question + 1]?.ai_follow_up &&
      !surveyStructure[question + 1].question
    ) {
      setActivate(true);
    } else {
      setActivate(false);
      setCurrentQuestion(surveyStructure[question + 1]?.question);
      setCurrentResponse("");
    }

    setQuestion(question + 1);
    setSavedResponses(_saved);
    // updateCurrentResponses(_saved);
  };

  const goBack = () => {
    if (question === 0) {
      if (isManager) {
        navigation("/simple/audit/manage/tasks");
      } else {
        navigation("/leader/audit/manage/tasks");
      }
      return;
    }
    setQuestion(question - 1);
    setCurrentQuestion(surveyStructure[question - 1].question);
    setCurrentResponse(savedResponses[question - 1].response);
  };

  function parseFeedback(inputString) {
    if (!inputString) {
      return "";
    }

    // Split the input string at '**feedback**:'
    const parts = inputString.split("**Feedback:**");

    if (parts.length === 1) {
      return (
        <div>
          <Question>{parts[0]}</Question>
        </div>
      );
    }

    // Trim the parts to remove any extra whitespace
    const part1 = parts[0].trim();
    const part2 = parts[1].trim();

    // Return the formatted result
    return (
      <div>
        <Question>{part1}</Question>

        <T4>Feedback Tips</T4>
        <Description>{part2}</Description>
      </div>
    );
  }

  return (
    <Container>
      {!showComplete ? (
        <>
          <Card delay={0.3} key={`card-${question}`}>
            <Progress>
              {question + 1} of {calculate_total_questions()} questions
            </Progress>

            {loading ? (
              <Loader active inline />
            ) : (
              parseFeedback(currentQuestion)
            )}

            <TextArea
              placeholder="Type your response here"
              value={currentResponse}
              onChange={(e) => setCurrentResponse(e.target.value)}
            />
            <ButtonContainer>
              {(question > 0 && (
                <Button onClick={() => goBack()} delay={1.3}>
                  <Icon
                    name="arrow left"
                    style={{ marginLeft: 10, marginTop: -3 }}
                  />{" "}
                  Back
                </Button>
              )) || <EmptyBackButtonSpace />}
              <Button onClick={() => getNextQuestion()} delay={1.3}>
                {surveyStructure.length == question + 1 ? "Submit" : "Next"}{" "}
                <Icon
                  name="arrow right"
                  style={{ marginLeft: 10, marginTop: -3 }}
                />
              </Button>
            </ButtonContainer>
          </Card>
        </>
      ) : (
        //  Create a completion screen with a green checkmark and a message
        //   and a button to return to the task list
        <Card delay={0.3}>
          <CenteredContainer>
            <Icon name="check circle" size="massive" color="green" />
            <T1>Survey Complete</T1>
            <Description>
              You have successfully completed this survey. Your responses have
              been registered. You may now close this window.
            </Description>
            {/* <Button
              onClick={() => {
                // Javascript to close window
                window.open("", "_self").close();
              }}
            >
              Close window
            </Button> */}
          </CenteredContainer>
        </Card>
      )}
    </Container>
  );
};

export default TaskQuestion;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn2 = keyframes`
  from {
    opacity: 0;
    transform: translateY(-40px) translateX(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(0);
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway";
`;

const Card = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  padding: 30px;
  flex-direction: column;
  position: relative;

  opacity: 0;

  animation: ${fadeIn2} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const T2 = styled.div`
  font-size: 14px;
  color: #2a3039;
`;

const T4 = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: #2d70e2;
  margin-top: 10px;
`;

const T1 = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-out forwards;
`;
const Question = styled.div`
  max-width: 80%;
  line-height: 1.3;
  font-size: 16px;
  font-weight: bold;
  color: #2a3039;
  margin-bottom: 15px;
`;

const Description = styled.div`
  max-width: 80%;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 400;
  color: #2a3039;
  margin-top: 3px;
  margin-bottom: 30px;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #ebf1fd;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-family: "Raleway";
  font-weight: 400;
  color: #2a3039;
  resize: none;
`;

const Q = styled.div`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => (props.selected ? "white" : "#2A3039")};
  padding: 10px 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: ${(props) => (props.selected ? "#2254AA" : "#EBF1FD")};
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 48%;

  &:hover {
    background-color: #2254aa;
    color: white;
  }
`;
const Progress = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-bottom: 5px;

  position: absolute;
  top: 30px;
  right: 20px;
`;

const EmptyBackButtonSpace = styled.div`
  width: 100px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #2254aa;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #2254aa;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  animation: fadeIn 1s ease-out forwards;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
