import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

const get_total = (d) => {
    let total = 0
    if(d.min_summary.length>0){
        total+=d.min_summary[0][3]
    }

    if(d.max_summary.length>0){
        total+=d.max_summary[0][3]
    }

    return total

}

const Feedback_summary = ({ data }) => {
  const [total, setTotal] = React.useState(get_total(data));

  return (
    <Container>
      {[...data.min_summary, ...data.max_summary]
        .sort((a, b) => b[2] - a[2])
        .map((item) => {
          return <Summary item={item} total={total} />;
        })}
    </Container>
  );
};

export default Feedback_summary;


const Summary = ({item,total}) =>{

    return (
        <SummaryContainer>
            <SummaryTitle>
                {item[1].replace(/['"]+/g,'')}
            </SummaryTitle>
            <Total>{item[2]} out of {total} responses</Total>
            <SummaryBody>{item[0]}</SummaryBody>
        </SummaryContainer>
    )
}

const Container = styled.div`

`

const SummaryContainer = styled.div`
    margin-bottom:30px;
`

const SummaryTitle = styled.div`
    font-size:16px;
    font-weight:600;
    display:flex;
    width:100%;
    justify-content:space-between;
    line-height:1.5;
`

const SummaryBody = styled.div`

`

const Percentage = styled.div`
    font-size:16px;
`

const Total = styled.div`
    font-size:12px;
    color:#666D79;
    margin-top:5px;
    margin-bottom:5px;

`