import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress,Icon } from 'semantic-ui-react'
import Themes from 'data/themes.js'

const Theme = (props) =>{



	const getZscore = () =>{
		if(props.z_score>1.5){
			return 'significantly positive'
		}
		if(props.z_score>0.5){
			return 'moderately positive'
		}
		if(props.z_score>0){
			return 'slightly positive'
		}
		if(props.z_score>-0.5){
			return 'slightly negative'
		}
		if(props.z_score>-1.5){
			return 'moderately negative'
		}
		if(props.z_score<=-1.5){
			return 'significantly negative'
		}
	}

	return (
		<Container>
			<IconContainer color={props.color.colors[2]}>
				<Image src={Themes[props.name]?.icon} style={{color:'white',fontSize:20,margin:0,marginTop:-10}}/>
			</IconContainer>
			<ContentContainer>
			<Title>{props.name}</Title>
			<Impact>Theme impact on {props?.system}</Impact>
			<StyledProgressBar percent={props?.score} progress size='small' style={{marginBottom:10,marginTop:2}} color={props.color.colors[2]} />
			<Description>{Themes[props.name]?.description}. {props?.name} has had a <strong>{getZscore()}</strong> impact on feelings of {props.system}. {props.percentage}% of feelings of {props.system} are associated with this theme.</Description>
			</ContentContainer>
			
		</Container>


		)
}


export default Theme


const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || 'green'} !important;
  }
`;

const Container = styled.div`
	display:flex;
	margin-bottom:20px;
	margin-top:40px;
`

const IconContainer = styled.div`
	background-color:${props=>props.color};
	border-radius:6px;
	display:flex;
	align-items:center;
	justify-content:center;
	height:50px;
	width:50px;
	padding-top:10px;
	margin-right:10px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:bold;
`

const ContentContainer = styled.div`
	width:100%;
`

const Impact = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:2px;
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`

const Image = styled.img`

`