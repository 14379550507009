import React from "react";
import styled, { keyframes } from 'styled-components';
import Culture from 'assets/images/empty/culture.svg'


const Container = styled.div`
	border-radius: 15px;
  background-color: #ffffff;
  width:100%;
  height:562px;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-evenly;

  @media (max-width:768px){
  	padding:20px;
  }
`

const Text = styled.div`
	font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #4a4a4a;
  width:400px;
  font-family: 'Red Hat Display', sans-serif;
  line-height:1.3;

  @media (max-width:768px){
  	width:100%;
  }
`

const IMG = styled.img`
	width: 276px;
  height: 321px;
`

const Empty = () =>{

	return (
		<Container>
			<Text>There is nothing to report just yet but as your team answers the survey you’ll see results here!</Text>
			<IMG src={Culture} />
		</Container>
		)
}

export default Empty