import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown, Icon, TextArea } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_CREATE_DEBRIEF_PDF_REPORT } from "constants/actions";
import { v4 as uuidv4 } from "uuid";

const Permissions = ({
  primaries,
  categories,
  parentCategory,
  get_auth,
  anchorName,
  setPdfOptions,
  pdfOptions,
  templateId,
  orgName,
  goBack,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    showWarning: false,
  });
  const [employeePermissions, setEmployeePermissions] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [cultureFactor, setCultureFactor] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const { get_auth_employee, core_data } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  const surveyVersion = useSelector(
    (state) => state.audit?.survey_version,
    shallowEqual
  );

  const createReport = () => {
    const uuid = uuidv4();
    const report = {
      // TODO: Pass this value as a prop.
      template_id: templateId,
      organization: Number(get_auth?.organization_id),
      employee: Number(get_auth?.employee_id),
      tracking_code: uuid,
      params: {
        parent_id: parentCategory,
        parent_children: categories,
        primary_list: primaries,
        pdfOptions: options,
        audienceInfo: additionalInfo,
        factor: cultureFactor,
        orgName: orgName,
      },
    };

    dispatch({
      type: SG_CREATE_DEBRIEF_PDF_REPORT,
      payload: report,
    });

    navigate("/leader/audit/reports/workshop");
  };

  useEffect(() => {
    if (surveyVersion) {
      let _options = [
        ...surveyVersion?.questions.dimensions.flatMap((item, index) =>
          item.factors.map((factor, i) => ({
            key:
              index *
                (surveyVersion?.questions.dimensions[index - 1]
                  ? surveyVersion?.questions.dimensions[index - 1].factors
                      .length
                  : 0) +
              i,
            text: factor.title,
            value: {
              title: factor.title,
              type: "pillar",
              factor,
              dimension: index,
            },
          }))
        ),
      ];
      setDropdownOptions(_options);
    }
  }, [surveyVersion]);

  return (
    <Container>
      <BackButton onClick={() => goBack()}>
        <Icon name="chevron left" style={{ marginTop: -7 }} />
        Back
      </BackButton>
      <T1>Additional Information</T1>
      <Description>
        We're almost there! Please select any additional options you'd like to
        include in your report.
      </Description>
      <Card>
        <Option>
          <T2>Select a culture factor</T2>
          <Dropdown
            placeholder="Factor selection"
            fluid
            selection
            value={cultureFactor}
            onChange={(e, { value }) => {
              setCultureFactor(value);
            }}
            options={dropdownOptions}
            style={{ marginBottom: 20, width: 250 }}
          />
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          </Text>
        </Option>

        <T2>Audience information</T2>
        <Text>
          Add some additional information regarding the audience of this report.
        </Text>

        <Row>
          <TextArea
            placeholder="Add additional comments..."
            style={{ minWidth: "100%", marginRight: 20 }}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            value={additionalInfo}
          />
        </Row>
      </Card>

      <ButtonContainer>
        <Button2 onClick={() => createReport()}>Generate Report</Button2>
      </ButtonContainer>
    </Container>
  );
};

export default Permissions;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  font-family: "Raleway";
`;
const T2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Text = styled.div`
  max-width: 80%;
  line-height: 1.5;
`;

const Option = styled.div`
  margin-bottom: 20px;
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const Card = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #ebf1fd;
  border-radius: 10px;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;

const T3 = styled.div`
  font-size: 14px;
  font-weight: bold;

  margin-top: 20px;
`;
const Button = styled.div`
  background-color: #2a3039;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #1a2029;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;
    z-index:-1;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const Button2 = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
`;

const friendOptions = [
  {
    key: "Anyone with access to this department",
    text: "Anyone with access to this department",
    value: "Anyone with access to this department",
  },
  {
    key: "Only members who have been added",
    text: "Only members who have been added",
    value: "Only members who have been added",
  },
];
