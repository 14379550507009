import React from "react";
import { useSelector } from "react-redux";

import {

  Avatar,
  BlankAvatar,
  Name,
  Member,
  View,
  Email,
  MobileStatus
} from "./styled-list";

import EmployeeAvatar from "components/EmpAvatar";

const MemberInfo = ({ emp_id }) => {
  const memberInfo = useSelector((state) => state.employees.employees).filter(
    (mm) => mm.id === emp_id
  )[0];
  return (
    <>
    {memberInfo?
    
      <Member>
        <EmployeeAvatar
            employee_id={memberInfo.id}
            nonMemberHide={false} />


        <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flexStart",
          padding: "0 0 0 15px",
          width:'100%'
        }}
      >
        <Name>{memberInfo.first_name ? memberInfo.first_name : ""} {' '}
        {memberInfo.last_name ? memberInfo.last_name : ""}</Name>
        <Email> {memberInfo.email}</Email>
        <MobileStatus>Completed</MobileStatus>
      </div>
        
      </Member>
      : ''}
    </>
  );
};

export default MemberInfo;
