import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Popup, Modal } from "semantic-ui-react";
import CloseImg from "assets/images/nav/close.svg";
import PlusImg from "assets/images/nav/plus.svg";
import ClearImg from "assets/images/nav/clear.svg";
import CheckImg from "assets/images/nav/check.svg";
import SurveyVersions from "reports/Audit/VersionControl";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_GENERATE_TASK_REPORT } from "constants/actions";

const getDataPoints = (filters, responses, orgList) => {
  if (filters.length === 0) return responses;
  return responses.filter((response) => {
    // Find the matching employee in the orgList based on the employee_id
    const employee = orgList.find(
      (org) => org.employee_id === response.employee
    );
    // If the employee is not found, skip this response
    if (!employee) return false;

    // Check if the employee's categories match any of the filters
    return filters.some((filter) => {
      return employee.category.some((category) => {
        return (
          category.value_id === filter.id && category.name_id === filter.parent
        );
      });
    });
  });
};

const Filters = ({ org_name, id, responses, employees, assignees }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selected, setSelected] = useState();
  const [data_length, setDataLength] = useState(0);
  const { coreData, get_employee_org_category } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      get_employee_org_category: state.employee_category?.employee_org_category,
    }),
    shallowEqual
  );

  const handleSelect = (item, parent) => {
    item.parent = parent.id;
    const isSelected = selectedFilters.some((i) => i.id === item.id);

    if (isSelected) {
      setSelectedFilters(selectedFilters.filter((i) => i.id !== item.id));
    } else {
      setSelectedFilters([...selectedFilters, item]);
    }
  };

  useEffect(() => {
    if (selectedFilters) {
      const dataPoints = getDataPoints(
        selectedFilters,
        responses,
        get_employee_org_category
      );
      setDataLength(dataPoints.length);
    }
  }, [selectedFilters]);

  const handleGenerate = () => {
    if (data_length > 0) {
      dispatch({
        type: SG_GENERATE_TASK_REPORT,
        payload: {
          filters: selectedFilters,
          task_id: id,
        },
      });
    }
    setSelectedFilters([]);
    setOpen(false);
  };

  const clearAll = () => {
    setSelectedFilters([]);
  };

  useEffect(() => {
    setSelected(
      coreData?.categories[0]?.categories.sort((a, b) => {
        const priorities = { primary: 1, secondary: 2, tertiary: 3 };

        if (a.priority === "tertiary" && a.demographic) return 1;
        if (b.priority === "tertiary" && b.demographic) return -1;

        return priorities[a.priority] - priorities[b.priority];
      })[0]
    );
  }, [coreData?.categories]);

  return (
    <FilterComponent>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        trigger={
          <Container>
            <Button onClick={() => setOpen(false)}>Request a Report</Button>
          </Container>
        }
      >
        <Modal.Header style={{ display: "flex" }}>
          <div>
            <div style={{ width: 150 }}>
              Filters
              <Length>
                # of data points: <strong>{data_length}</strong>
              </Length>
            </div>
            {selectedFilters?.length === 0 && (
              <Note>
                No filters selected. All data points will be included in the
                report.
              </Note>
            )}
          </div>

          <div style={{ position: "absolute", right: 20, top: 10 }}>
            <SurveyVersions label={true} />
          </div>

          <SelectedOptions style={{ marginLeft: 45 }}>
            {selectedFilters.length === 0 ? (
              <DefaultOption>{org_name}</DefaultOption>
            ) : (
              selectedFilters.map((item) => (
                <SelectedOption
                  key={item.id}
                  onClick={() => handleSelect(item, selected)}
                >
                  {item.name} <IconImg src={CloseImg} />
                </SelectedOption>
              ))
            )}

            {selectedFilters.length > 0 && (
              <Popup
                trigger={<Clear src={ClearImg} onClick={clearAll} />}
                content="Clear All"
                inverted
                position="top center"
                style={{
                  backgroundColor: "#666D79",
                  fontFamily: "Raleway",
                  fontSize: 12,
                }}
              />
            )}
          </SelectedOptions>
        </Modal.Header>

        <Content>
          <SideMenu>
            {coreData?.categories[0]?.categories
              ?.sort((a, b) => {
                const priorities = { primary: 1, secondary: 2, tertiary: 3 };

                if (a.priority === "tertiary" && a.demographic) return 1;
                if (b.priority === "tertiary" && b.demographic) return -1;

                return priorities[a.priority] - priorities[b.priority];
              })
              ?.map((item) => (
                <Item
                  key={item.id}
                  onClick={() => setSelected(item)}
                  selected={item === selected}
                >
                  {item.name}
                </Item>
              ))}
          </SideMenu>

          <MainMenu>
            <Title>{selected?.name}</Title>
            <Options>
              {selected?.options.map((item) => (
                <Option
                  key={item.id}
                  onClick={() => handleSelect(item, selected)}
                  selected={selectedFilters.some((i) => i.id === item.id)}
                >
                  {selectedFilters.some((i) => i.id === item.id) && (
                    <IconImg2 src={CheckImg} />
                  )}
                  {item.name}
                </Option>
              ))}
            </Options>
          </MainMenu>
        </Content>

        <Bottom>
          <Button disabled={data_length === 0} onClick={() => handleGenerate()}>
            Generate Report
          </Button>
        </Bottom>
      </Modal>
    </FilterComponent>
  );
};

export default Filters;

const Container = styled.div`
  color: #2d70e2;
  font-family: Raleway;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 200px;
`;

const Item = styled.div`
  margin-bottom: 5px;
  height: 36px;
  padding: 4px 12px 4px 27px;
  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-family: Raleway;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#F8FAFF" : "#fff")};
  display: flex;
  align-items: center;
`;

const MainMenu = styled.div`
  padding: 20px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-family: Raleway;
  font-size: 14px;
`;

const Option = styled.div`
  padding: 8px 16px;
  border-radius: 30px;
  background-color: ${(props) => (props.selected ? "#EBF1FD" : "#fff")};
  color: ${(props) => (props.selected ? "#2D70E2" : "#2A3039")};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SelectedOption = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-family: Raleway;
  font-size: 14px;
  font-weight: 600;
  color: #2d70e2;
  cursor: pointer;
`;

const DefaultOption = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  color: #2d70e2;
`;

const FilterComponent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const IconImg = styled.img`
  width: 20px;
  margin-left: 3px;
`;

const IconImg2 = styled.img`
  width: 20px;
  margin-right: 4px;
`;

const Clear = styled.img`
  width: 20px;
  margin-left: 8px;
  cursor: pointer;
`;

const Button = styled.button`
  background: #2d70e2;
  border-radius: 8px;
  font-family: "Raleway";
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 20px;
`;

const Length = styled.div`
  font-family: "Raleway";
  font-weight: 400;
  font-size: 12px;
  color: #666d79;
`;

const Note = styled.div`
  font-family: "Raleway";
  font-size: 12px;
  color: #666d79;
  margin-top: 10px;
`;