// Libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dimmer, Loader, Button, Dropdown, Icon } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { erf } from "mathjs";

// Components
import Anchor from "reports/Audit/Anchor";
import NavBar2 from "reports/Audit/NavBar2";
import SurveyVersions from "reports/Audit/VersionControl/index";

// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetUserSettings from "hooks/useGetUserSettings";
import { format } from "date-fns";
// Actions
import {
  SG_GET_PDF_TEMPLATES,
  SG_CREATE_PDF_TEMPLATE,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SET_NAVIGATION,
  SG_GET_DEBRIEF_SCHEDULES,
  SG_GET_OUTCOME_QUESTIONS,
} from "constants/actions";

// Calculations
import {
  calculate_table_data,
  get_most_recent,
  sort_historical_data,
  get_dates,
  build_default_State,
  get_by_id,
  get_last_responses,
} from "../../reports/Audit/data_navigation/calculate_data";

import {
  getDataByQuarter,
  getAnchorAveragesByQuestion,
} from "reports/Outcomes/calculations";

// Helper functions
const checkPersonality = (org) => {
  return org.organization.services_enabled.find((f) => f.id === 21)?.enabled;
};

const convert_personality_to_categories = (resp) => {
  resp.map((item) => {
    item.responses.map((r) => {
      if (Object.keys(r.response.survey_personality).length > 0) {
        let personality = calculate_personality_categories(
          r.response.survey_personality
        );
        r.response.categories.push(...personality);
      }

      return r;
    });
  });

  return resp;
};

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const personality_names = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Neuroticism",
  "Openess",
];

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};

const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

const calculate_personality_categories = (data) => {
  let pers = personality_norms.map((item, i) => calculate_personality(data, i));
  return pers.map((item, i) => {
    let response = item < 33 ? "0" : item < 67 ? "1" : "2";
    return { id: personality_names[i], priority: "personality", response };
  });
};

const PDFReport = () => {
  /********* Declarations **********/
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [pdfTemplate, setPdfTemplate] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [startDate, setStartDate] = useState(new Date("2022/02/08"));
  const [endDate, setEndDate] = useState(new Date());
  const [options, setOptions] = useState({
    startDate: startDate,
    endDate: endDate,
    sports: [],
  });
  const [showFeedback, setShowFeedback] = useState(false);
  const [category_access, setAccess] = useState([]);
  const [defaultsSet, setDefaultsSet] = useState(false);
  const [selectedSport, setSelectedSport] = useState();
  const [preservedNav, setPreservedNav] = useState();
  const [calculated, setCalculated] = useState();
  const [reportData, setReportData] = useState();
  const [debriefSchedules, setDebriefSchedules] = useState();
  const [debriefOptions, setDebriefOptions] = useState();
  const [selectedDebrief, setSelectedDebrief] = useState();
  const [outcomesSortOrder, setOutcomesSortOrder] = useState();
  const [filteredReports, setFilteredReports] = useState();
  const [quarterlyData, setQuarterlyData] = useState();
  const [outcomesQuestionsList, setOutcomesQuestionsList] = useState();
  const [calculatedOutcomes, setCalculatedOutcomes] = useState();
  const { currentUser } = useGetUserSettings();
  const rule = 2;

  /********* Selectors **********/
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const pdfConfig = useSelector(
    (state) => state.pdfGenerator.pdfConfig,
    shallowEqual
  );

  const outcomeQuestions = useSelector((state) => {
    return state.surveyquestions.outcome_questions;
  });

  const {
    whiteLabel,
    get_organizations,
    get_culture_audit_reports,
    survey_version,
    get_debrief_schedules,
  } = useSelector(
    (state) => ({
      survey_version: state.audit?.survey_version,
      get_organizations: state.organizations,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      whiteLabel: state.white_label?.white_label,
      get_debrief_schedules: state.debrief_schedule,
    }),
    shallowEqual
  );

  const orgRule = !!whiteLabel?.rule ? Number(whiteLabel?.rule) : rule;

  /********* Hooks **********/
  const {
    get_survey_structure,
    nav_state,
    get_employee,
    get_employee_categories,
    get_auth,
  } = useGetSurveyData({
    surveyType: 6,
    auditTypeId: 3,
  });

  useEffect(() => {
    if (
      nav_state &&
      get_culture_audit_reports &&
      get_survey_structure?.length > 0
    ) {
      let primary = [];
      let prime =
        nav_state[
          Object.keys(nav_state).find(
            (f) => nav_state[f].priority === "primary"
          )
        ];

      if (prime && Object.keys(prime)?.length > 0) {
        Object.keys(prime)
          .filter((f) => f != "selected" || f != "priority")
          .map((item, i) => {
            let items = prime[item];

            if (items.selected) {
              primary.push(item);
            }
          });
        const primaryOptions = get_survey_structure[0]?.categories?.filter(
          (cat) => cat.priority === "primary"
        );

        if (primary?.length > 0) {
          const selectedPrimaries = primary?.map(
            (p) => primaryOptions[0]?.options?.find((po) => po.id === p)?.name
          );

          const filteredReports = get_culture_audit_reports?.response?.filter(
            (report) => selectedPrimaries?.includes(report?.name)
          );

          setFilteredReports({ response: filteredReports });
        }
      }
    }
  }, [get_culture_audit_reports, nav_state, get_survey_structure]);

  // Get the survey questions for the outcome survey.
  useEffect(() => {
    if (outcomeQuestions?.length > 0 && outcomesSortOrder) {
      const questions = outcomeQuestions?.find(
        (item) => item.sort_order === outcomesSortOrder
      )?.questions;

      if (questions?.length > 0) {
        setOutcomesQuestionsList(questions);
      }
    }
  }, [outcomeQuestions, outcomesSortOrder]);

  // Organize data once we have reports & a selected anchor.
  // Update the data when the anchor changes.
  useEffect(() => {
    if (filteredReports?.response?.length > 0) {
      let data = getDataByQuarter(filteredReports?.response);
      setQuarterlyData(data);
    }
  }, [filteredReports]);

  useEffect(() => {
    dispatch({
      type: SG_GET_OUTCOME_QUESTIONS,
      payload: `survey_type=10&status=2`,
    });
  }, []);

  useEffect(() => {
    if (get_organizations?.organization?.id) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page_size=1000&type_of=3&organization=${get_organizations?.organization?.id}`,
      });
    }
  }, [get_organizations]);

  useEffect(() => {
    if (get_debrief_schedules?.debrief_schedule?.results?.length > 0) {
      setDebriefSchedules(get_debrief_schedules.debrief_schedule.results);
    }
  }, [get_debrief_schedules]);

  // Get the sort order for the outcome questions.
  useEffect(() => {
    if (get_organizations?.[get_organizations?.organization?.id]?.styling) {
      const sortOrder = get_organizations?.[
        get_organizations?.organization?.id
      ]?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;
      setOutcomesSortOrder(sortOrder);
    }
  }, [get_organizations?.[get_organizations?.organization?.id]]);

  useEffect(() => {
    if (debriefSchedules) {
      let options = debriefSchedules.map((item) => {
        return {
          key: item.id,
          text: `${item.name} - ${format(
            new Date(item?.scheduled_for),
            "yyyy-MM-dd"
          )}`,
          value: item.id,
        };
      });
      setDebriefOptions(options);
    }
  }, [debriefSchedules]);

  const onDebriefSelect = (e, { value }) => {
    const debriefObj = debriefSchedules.find((item) => item.id === value);

    let sort_order;
    let pdfObj;
    if (get_culture_audit_reports?.response?.length > 0) {
      sort_order = get_culture_audit_reports?.response?.find(
        (item) => item?.id === value
      )?.question_sort_order;
      pdfObj = {
        coverSubtitle: debriefObj?.type_of_text,
        options: {
          reportId: debriefObj?.id,
          sports: [debriefObj?.name],
        },
        sort_order: sort_order,
        sportName: debriefObj?.name,
      };
      setOptions({
        ...options,
        sports: [debriefObj?.name],
      });
    } else {
      pdfObj = {
        coverSubtitle: debriefObj?.type_of_text,
        options: {
          reportId: debriefObj?.id,
        },
      };
    }

    setSelectedDebrief(pdfObj);
  };

  useEffect(() => {
    if (get_employee_categories?.length > 0) {
      setAccess(
        get_employee_categories.find(
          (f) => f.employee === Number(get_auth.employee_id)
        )?.category
      );
    }
  }, [get_auth, get_employee_categories]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure[0]) {
      const DefState = build_default_State(get_survey_structure[0]);
      dispatch({
        type: SET_NAVIGATION,
        payload: DefState,
      });
      if (get_culture_audit_reports.response) {
        if (get_culture_audit_reports.response.length > 0) {
          let sorted_responses = get_culture_audit_reports.response.sort(
            (a, b) => b.id - a.id
          );

          if (survey_version) {
            sorted_responses = sorted_responses.filter(
              (f) => f.question.sort_order === survey_version.sort_order
            );
          }
          if (options.startDate) {
            setReportData([]);
          }

          if (checkPersonality(get_organizations)) {
            //Modify the results to include personality as a categorical variable
            sorted_responses =
              convert_personality_to_categories(sorted_responses);
          }

          if (options?.sports?.length > 0) {
            sorted_responses = sorted_responses.filter((responseGroup) => {
              return options?.sports.includes(
                responseGroup.name.replace(/[ \t]+(\r?$)/, "")
              );
            });
          }

          // change_data(sorted_responses[0],10)
          let most_recent;
          if (
            selectedDebrief &&
            selectedDebrief?.options?.reportId &&
            sorted_responses
          ) {
            most_recent = get_by_id(
              sorted_responses,
              selectedDebrief?.options?.reportId
            );
            sorted_responses
              .filter(
                (responseGroup) =>
                  responseGroup.id === selectedDebrief?.options?.reportId
              )
              ?.map((i) => i.responses)
              .flat()
              .map((i) => i.response)
              .filter((f) => !Array.isArray(f));
          } else if (options.startDate && options.endDate && sorted_responses) {
            sorted_responses = sorted_responses.filter(
              (responseGroup) => responseGroup.responses.length > 0
            );

            sorted_responses = sorted_responses.filter(
              (responseGroup) =>
                new Date(responseGroup.date) <= new Date(options.endDate) &&
                new Date(responseGroup.date) >= new Date(options.startDate)
            );

            // If not admin, filter categories which user does not have access to.
            most_recent = get_most_recent(sorted_responses);
          } else {
            most_recent = get_most_recent(sorted_responses);
          }

          let total = {};
          most_recent.responses?.map((r) => {
            let n = r.categories.length.toString();
            if (!(n in total)) {
              total[n] = 1;
            } else {
              total[n] += 1;
            }
          });

          most_recent["last"] = get_last_responses(sorted_responses);

          if (sorted_responses.length > 0 && most_recent.responses) {
            let historical_data = sort_historical_data(
              sorted_responses.reverse(),
              0
            );

            setReportData([most_recent, ...historical_data]);

            dispatch({
              type: SET_RAW_DATA,
              payload: [most_recent, ...historical_data],
            });

            dispatch({
              type: SET_DATA_DATES,
              payload: get_dates(historical_data),
            });
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    options?.endDate,
    options?.startDate,
    options?.sports,
    get_organizations,
    survey_version,
    selectedDebrief?.options?.reportId,
  ]);

  useEffect(() => {
    if (
      reportData &&
      nav_state &&
      get_survey_structure &&
      get_survey_structure[0] &&
      survey_version?.questions &&
      anchor
    ) {
      setCalculated(
        calculate_table_data(
          reportData,
          nav_state,
          get_survey_structure[0],
          anchor,
          orgRule,
          survey_version?.questions
        )
      );
    }
  }, [reportData, nav_state, options, anchor, get_survey_structure, orgRule]);

  /********* Functions & Routines **********/
  const resetDefaultsBtnClicked = () => {
    setStartDate(new Date("2022/02/08"));
    setEndDate(new Date());
    setOptions({
      startDate: new Date("2022/02/08"),
      endDate: new Date(),
      sports: [],
    });
    setDefaultsSet(!defaultsSet);
    setSelectedDebrief();
    setShowFeedback(false);
  };

  const handleStartDateSelect = (date) => {
    setStartDate(date);
    setOptions({ ...options, startDate: date });
  };

  const handleEndDateSelect = (date) => {
    setEndDate(date);
    setOptions({ ...options, endDate: date });
  };

  /********* Dispatch Calls **********/
  // Retrieve PDF Templates (if they exist).
  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_TEMPLATES,
    });
  }, [dispatch]);

  // Create a PDF Template (if one does not exist).
  useEffect(() => {
    if (pdfTemplate.length === 0) {
      dispatch({
        type: SG_CREATE_PDF_TEMPLATE,
        payload: { name: "ver 1.0" },
      });
    }
  }, [pdfTemplate, dispatch]);

  useEffect(() => {
    if (
      quarterlyData?.length > 0 &&
      outcomesQuestionsList?.length > 0 &&
      anchor
    ) {
      let outcomesDataArr = [];

      outcomesQuestionsList?.map((ques) => {
        let outcomesData = getAnchorAveragesByQuestion(
          quarterlyData,
          anchor,
          ques.id,
          get_survey_structure[0],
          orgRule,
          ques
        );

        outcomesData?.[0]?.anchorData
          .sort((a, b) => a.average - b.average)
          .reverse();
        outcomesDataArr.push(outcomesData);
      });

      setCalculatedOutcomes(outcomesDataArr);
    }
  }, [
    quarterlyData,
    anchor,
    outcomeQuestions,
    outcomesQuestionsList,
    get_survey_structure,
    orgRule,
  ]);

  /********* useEffect Hooks **********/
  useEffect(() => {
    setPdfTemplate(pdfConfig);
  }, [pdfConfig]);

  // Create array of select options based on primary categories
  useEffect(() => {
    if (!selectOptions && get_survey_structure?.[0]) {
      const options = get_survey_structure?.[0]?.categories
        .find(
          (category) => category.name.toLowerCase() === "sport".toLowerCase()
        )
        ?.options.map((catOption) => {
          return {
            key: catOption.name,
            text: catOption.name,
            value: catOption.name,
          };
        });
      setSelectOptions(options);
      setLoading(false);
    }
  }, [get_survey_structure, selectOptions]);

  /********* Render Functions **********/
  const SelectDate = React.forwardRef((props, ref) => (
    <SelectContainer
      ref={ref}
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
    >
      <Icon
        name="calendar alternate"
        size={"small"}
        style={{ color: "#6a00ff", marginRight: 8 }}
      />{" "}
      {props.value}
    </SelectContainer>
  ));

  const feedbackToggle =
    (currentUser?.read_feedback === 1 &&
      pdfConfig?.[0]?.data?.introTemplate === 2 && (
        <ToggleWrapper>
          <FilterLabel>Include feedback: </FilterLabel>
          <ToggleLabel>
            <ToggleInput
              type="checkbox"
              onChange={() => setShowFeedback(!showFeedback)}
              value={showFeedback}
              checked={showFeedback}
            />
            <ToggleSlider isChecked={showFeedback} />
          </ToggleLabel>
        </ToggleWrapper>
      )) ||
    null;

  if (loading || !pdfConfig[0] || !get_employee) {
    return (
      <LoadingWrapper>
        <Dimmer active={loading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
      </LoadingWrapper>
    );
  }

  const filter = (
    <FilterWrapper>
      <FilterLabel>Select your filters: </FilterLabel>
      <NavBar2
        structure={get_survey_structure[0]}
        mployee_categories={category_access}
        isAdmin={
          get_employee[get_auth.employee_id]?.account_type_text === "Admin"
        }
        sportSelectCb={(sports) => {
          setSelectedSport(sports);
        }}
        setPreservedState={(navState) => {
          setPreservedNav(navState);
        }}
      />
    </FilterWrapper>
  );

  const generatePDF = () => {
    // Are we generating from a debrief?
    let optionsObj;
    let anchorSelection;
    if (selectedDebrief) {
      optionsObj = {
        ...options,
        ...selectedDebrief?.options,
      };
      anchorSelection = get_survey_structure[0]?.categories.find(
        (f) => f.priority === "secondary"
      )?.id;
    } else {
      optionsObj = options;
      anchorSelection = anchor;
    }

    // Object to be sent to the new window
    const transferObject = {
      coverSubtitle: selectedDebrief?.coverSubtitle || "Generate PDF Report",
      anchor: anchor,
      config: pdfConfig,
      options: optionsObj,
      overview: selectedDebrief ? false : true,
      buttonEnabled: true,
      showFeedback: showFeedback,
      nav_state: preservedNav || nav_state,
      reset: defaultsSet,
      selectedSport: selectedDebrief
        ? selectedDebrief?.options?.sports?.[0]
        : selectedSport,
      sportName: selectedDebrief?.name || null,
      survey_version: survey_version,
      structure: get_survey_structure[0],
      outcomesData: calculatedOutcomes,
      outcomesQuestions: outcomesQuestionsList,
    };

    // Open a new window
    const newTab = window.open(
      `${window.location.origin}/app/audit/generate-pdf`,
      "_blank"
    );

    // Create a broadcast channel
    const bc = new BroadcastChannel("pdf_channel");

    // Once the window is open it will send a message to us to let us know it is ready.
    bc.onmessage = (ev) => {
      if (ev?.data?.windowOpen) {
        bc.postMessage(transferObject);
      }
      // newTab.postMessage(
      //   transferObject,
      //   `${window.location.origin}/app/otp/generate-pdf`
      // );
      // bc.postMessage(transferObject);
    };
  };

  return (
    <Page>
      <PageHeader>PDF Report</PageHeader>
      <PageSubtitle>Customize your PDF report options</PageSubtitle>
      <OptionsSection>
        <SelectionSection>
          <SelectorLabel>Choose your Date Range: </SelectorLabel>
          <DateRangeWrapper>
            <DatePicker
              selected={startDate}
              onChange={(date) => handleStartDateSelect(date)}
              selectsStart
              showYearDropdown
              startDate={startDate}
              endDate={endDate}
              customInput={<SelectDate />}
            />
          </DateRangeWrapper>
          <DateRangeToLabel>to</DateRangeToLabel>
          <DateRangeWrapper>
            <DatePicker
              selected={endDate}
              onChange={(date) => handleEndDateSelect(date)}
              selectsEnd
              showYearDropdown
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={<SelectDate />}
            />
          </DateRangeWrapper>
        </SelectionSection>
        {filter}
        <SelectionSection>
          <SelectorLabel>Choose your anchor: </SelectorLabel>
          <AnchorWrapper>
            <Anchor anchorLabel=" " />
          </AnchorWrapper>
        </SelectionSection>
        <SelectionSection>
          <SelectorLabel>Select your survey version: </SelectorLabel>
          <SurveyVersions selectedDebrief={selectedDebrief} />
        </SelectionSection>
        <SelectionSection>
          <SelectorLabel>Generate a specific report (optional): </SelectorLabel>
          <DropdownWrapper>
            {debriefOptions && (
              <Dropdown
                placeholder="Select a report"
                fluid
                selection
                options={debriefOptions}
                value={selectedDebrief?.options?.reportId || null}
                onChange={onDebriefSelect}
                style={{
                  marginRight: 20,
                  borderRadius: 20,
                  fontSize: 12,
                }}
                clearable
              />
            )}
          </DropdownWrapper>
        </SelectionSection>
        {feedbackToggle}
      </OptionsSection>
      <ButtonsSection>
        <ResetButtonWrapper>
          <Button onClick={() => resetDefaultsBtnClicked()}>Reset</Button>
        </ResetButtonWrapper>
        <GenerateButtonWrapper>
          {get_survey_structure?.[0] &&
            pdfConfig?.length > 0 &&
            anchor &&
            options && (
              <ButtonContainer>
                <Button
                  style={{ maxWidth: 150, minWidth: 150 }}
                  disabled={!calculated || calculated?.responses?.length === 0}
                  onClick={() => generatePDF()}
                >
                  Generate PDF
                </Button>
                {(!calculated || calculated?.responses?.length === 0) && (
                  <NoData>
                    Not enough data for the selected options or range.
                  </NoData>
                )}
              </ButtonContainer>
            )}
        </GenerateButtonWrapper>
      </ButtonsSection>
    </Page>
  );
};

/********** Styles ***********************/
const LoadingWrapper = styled.div`
  padding: 300px;
`;

const NoData = styled.div`
  color: red;
  margin-left: 8px;
  font-size: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GenerateButton = styled.div`
  cursor: pointer;
  background-color: #6a00ff;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 150px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  width: 150px;
  height: 30px;
  border-radius: 8px;
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #4a4a4a;
  position: relative;
  z-index: 1;
  margin-right: ${(props) => (props.margin ? props.margin : 0)};
  margin-right: 10px;

  @media (max-width: 768px) {
    padding-left: 10px;
    width: 150px;
    font-size: 13px;
  }
`;

const Page = styled.div`
  padding: 10px 0px;
  padding-left: 58px;
  font-family: "Raleway";

  @media (max-width: 600px) {
    display: none;
  }
`;

const PageHeader = styled.div`
  padding: 35px 0px;
  font-size: 32px;
  font-weight: 700;
`;

const PageSubtitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 60px;
`;

const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ResetButtonWrapper = styled.div`
  padding-right: 15px;
`;

const GenerateButtonWrapper = styled.div`
  display: flex;
  text-align: center;
  line-height: 35px;
`;

const SelectionSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
  line-height: 35px;
`;

const DropdownWrapper = styled.div`
  width: 350px;
  z-index: 9;
`;

const AnchorWrapper = styled.div`
  width: 150px;
  position: relative;
`;

const SelectorLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding-right: 8px;
`;

const DateRangeWrapper = styled.div`
  z-index: 99;
`;

const DateRangeToLabel = styled.div`
  padding-right: 8px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  margin-bottom: 35px;
  align-items: center;
`;

const FilterLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding-right: 8px;
  line-height: 35px;
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const ToggleSlider = styled.div`
  position: relative;
  width: 40px;
  height: 16px;
  background: #e7e7e7;
  border-radius: 26.6667px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 15px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${(props) => (props.isChecked ? "#66d686" : "#c0c0c0")};
    transform: translate(0, -50%);
  }
`;

const ToggleInput = styled.input`
  display: none;

  &:checked + ${ToggleSlider} {
    background: #e7e7e7;

    &:before {
      transform: translate(20px, -50%);
    }
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
`;

export default PDFReport;
