import React, { useEffect, useState } from "react";
import styled from "styled-components";
import IndividualModal from "./individualModal";
import { format } from "date-fns";

const Table = ({
  q,
  structure,
  title,
  getDataset,
  getGroupData,
  date,
  factor,
  recentResponses,
  categories,
}) => {
  const getFormattedDate = () => {
    const dateArr = date.split("-");

    const newDateObj = format(
      new Date(dateArr[0], dateArr[1] - 1, dateArr[2] - 1),
      "MMM do, yyyy"
    );
    return newDateObj;
  };

  const structured = (question) => {
    return question
      .map((item, i) => {
        return structure.options.map((s) => {
          const dataset = getDataset(item, i);
          return {
            question: i,
            average: dataset.avg,
            label: s,
          };
        });
      })
      .flat();
  };

  const [data, setData] = useState(structured(q));

  useEffect(() => {
    setData(structured(q));
  }, [structure, q]);

  return (
    <TableContainer>
      <Scale color={"181, 181, 181"}>
        <TH width={47.73} />
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <Num>{i}</Num>
        ))}
        <Num2 />
      </Scale>
      {q.map((item, i) => (
        <TableRow
          item={item}
          date={getFormattedDate()}
          idx={i}
          title={title}
          data={data.filter((f) => Number(f.question === i))}
          color={"rgba(181, 181, 181,1)"}
          average={(item, i) => getDataset(item, i).avg}
          getAvg={getDataset}
          groupData={getGroupData}
          factor={factor}
          recentResponses={recentResponses}
          categories={categories}
          structure={structure}
        />
      ))}

      <Highlight>
        **Items highlighted above are reverse scored in all aggregate
        calculations. For highlighted questions presented in this table, high
        scores are bad, low scores are good
      </Highlight>
    </TableContainer>
  );
};

export default Table;

const TableRow = ({
  item,
  data,
  color,
  idx,
  title,
  average,
  getAvg,
  date,
  groupData,
  factor,
  recentResponses,
  categories,
  structure,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TR onClick={() => setOpen(!open)} highlight={item.reverse}>
        <TD
          width={35}
          borderRight={true}
          bold={item.q === "Average"}
          noBottom={open}
        >
          {item.q}
        </TD>
        <TD width={15} center={true} bold={item.q === "Average"}>
          {average(item)}
        </TD>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <TD width={4.54} dash={true} noBottom={i === 10}></TD>
        ))}

        <Bar width={average(item) * 4.54} color={color} />
      </TR>

      <Details open={open}>
        <TD2 width={50} center={true}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "column",
            }}
          >
            {data.map((x, i) => {
              const groupDataArr = groupData(item);
              return (
                <div>
                  <IndividualModal
                    title={x.label.name + " " + title}
                    date={date}
                    question={item.q}
                    targetGroupResponses={
                      getAvg(item, x.label.name).distribution
                    }
                    factor={factor}
                    recentResponses={recentResponses}
                    categories={categories}
                    entireGroupResponses={groupDataArr}
                    groupData={groupData}
                    item={item}
                    label={x.label.name}
                    data={x}
                    i={x.label.id}
                    role={structure.id}
                    idx={idx}
                    color={x.label.color}
                    trigger={<LabelContainer>{x.label.name}</LabelContainer>}
                  />
                </div>
              );
            })}
          </div>
        </TD2>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
          <TD width={4.54} dash={true} noBottom={i === 10}></TD>
        ))}

        <BarContainer>
          {data.map((c, i) => {
            return (
              <Bar2
                width={getAvg(item, c.label.name).avg * 4.54}
                color={c.label.color}
              >
                <Score>{average}</Score>
              </Bar2>
            );
          })}
        </BarContainer>
      </Details>
    </>
  );
};

const TableContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const LabelContainer = styled.div`
  cursor: pointer;

  &:hover {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const Scale = styled.div`
  background-color: ${(props) => "rgba(" + props.color + ",0.4)"};
  display: flex;
`;

const TR = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  cursor: pointer;
  transition: all 0.5s;
  background-color: ${(props) =>
    props.highlight ? "rgba(1,1,1,0.1)" : "white"};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 4px;
  }
`;

const BarContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 2px);
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;
const Bar = styled.div`
  width: ${(props) => props.width + "%"};
  height: 15px;
  position: absolute;
  left: calc(50% - 2px);
  top: calc(50% - 7.5px);
  background-color: ${(props) => props.color};
`;

const Bar2 = styled.div`
  width: ${(props) => props.width + "%"};
  height: 15px;
  background-color: ${(props) => props.color};
  position: relative;
  border-radius: 10px;
`;

const Num = styled.div`
  width: 4.54%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  display: flex;
  justify-content: center;
`;

const Num2 = styled.div`
  width: 3.18%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  position: relative;
  display: flex;
  justify-content: center;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: ${(props) => (props.dash ? "0px " : "10px 5px")};
  border-right: ${(props) => (props.borderRight ? "1px solid black" : "none")};
  font-weight: ${(props) => (props.bold ? "bold" : 400)};
  border-left: ${(props) => (props.dash ? "1px dashed black " : "none")};
  border-bottom: ${(props) => (!props.noBottom ? "1px solid black" : "none")};
  box-sizing: ${(props) => (props.dash ? "content-box " : "border-box")};
  position: relative;
`;

const TD2 = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? "flex-end" : "flex-start")};
  justify-content: ${(props) => (props.center ? "center" : "flex-start")};
  padding: ${(props) => (props.dash ? "0px " : "10px 5px")};
  border-right: ${(props) => (props.borderRight ? "1px solid black" : "none")};
  font-weight: ${(props) => (props.bold ? "bold" : 400)};
  border-left: ${(props) => (props.dash ? "1px dashed black " : "none")};
  border-bottom: ${(props) => (!props.noBottom ? "1px solid black" : "none")};
  box-sizing: ${(props) => (props.dash ? "content-box " : "border-box")};
  position: relative;
  border-top: 1px solid black;
`;

const Details = styled.div`
  height: 200px;
  width: 100%;
  max-height: ${(props) => (props.open ? "1000px" : 0)};
  transition: all 1s;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const Score = styled.div`
  position: absolute;
  left: calc(100% + 10px);
  font-weight: bold;
`;

const Highlight = styled.div`
  margin-top: 20px;
  font-style: italic;
`;
