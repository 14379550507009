import styled, { keyframes } from "styled-components";
import React from "react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  Subsubtext,
  barChartOptions,
} from "reports/DataJourney/components/common/styles";

const PlanSummary = (props) => {
  const history = props?.selectionHistory;
  const need = history.find((item) => item.type === "need");
  const action = history.find((item) => item.type === "action");

  return (
    <ScreenContainer>
      <ScreenTitleExtended>Your action plan</ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        {need && <T3>{need?.title}</T3>}
        <Description>{need && need?.description}</Description>
      </SubtextExtended>

      <Text delay={0.5}>
        <SubsubtextExtended>{action?.title}</SubsubtextExtended>
        <Description>{action?.action}</Description>
      </Text>
    </ScreenContainer>
  );
};

export default PlanSummary;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubsubtextExtended = styled(Subsubtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const Text = styled.div`
  color: #2a3039;
  margin-bottom: 20px;
  max-width: 800px;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Description = styled.div`
  line-height: 1.6;
  border-radius: 10px;
  font-size: 14px;
  max-width: 600px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const T2 = styled.div`
  font-size: 14px;
  color: #2d70e2;
  margin-top: 20px;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: #ebf1fd;
  font-weight: 600;
  border-radius: 5px;
  display: inline-block;
`;

const T3 = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #2a3039;
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const ChartContainer = styled.div`
  width: 100%;

  margin-top: 40px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Blue = styled.span`
  color: #2d70e2;
  font-weight: 500;
`;

const data3 = {
  series: [
    {
      name: "Steps",
      data: [67, 68, 70, 71, 72, 74, 75, 77],
    },
  ],
  options: {
    chart: {
      type: "line",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    forecastDataPoints: {
      count: 6,
    },
    markers: {
      size: 5,
      strokeColors: "#2D70E2",
    },
    grid: {
      padding: {
        right: 100,
      },
    },
    annotations: {
      points: [
        {
          x: 8,
          y: 77,
          marker: {
            size: 8,
          },
          label: {
            style: {
              background: "#2D70E2",
              color: "#fff",
              fontSize: "12px",
              fontWeight: 600,
              padding: {
                left: 10,
                right: 10,
                top: 5,
                bottom: 5,
              },
            },
            text: "Your Goal",
            offsetY: -5,
          },
        },
      ],
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    colors: ["#2D70E2", "#B3B3B3", "#27CDA7"],
    xaxis: {
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      min: 65,
      max: 80,
      decimalsInFloat: 1,
      title: {
        text: "Psychological Safety Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
