import React from "react";

import { Modal,Icon } from "semantic-ui-react";
import styled from "styled-components";

const TopBar = styled.div`
  background-color: #6a00ff;
  color: white;
  padding: 50px;

  @media (max-width:768px){
    padding:25px;
  }
`;

const Title = styled.h1`
  font-family: "Red Hat Display", sans-serif;
  font-size: 28px;
  font-weight: bold;
`;

const TitleText = styled.h2`
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: 400;

  @media (max-width:768px){
    display:none;
  }
`;

const SubTitle = styled.h3`
  font-family: "Red Hat Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: grey;
`;

const UserCard = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(178, 178, 178, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px 10px 30px;
  margin-bottom: 20px;
`;
const YouCard = styled.div`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 30px 10px 30px;
  margin: 30px 0px 30px 0px;
`;

const Percentile = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  margin-right: 20px;
  width: 80px;
  min-width:80px;

  @media(max-width:768px){
    margin-right:5px;
  }
`;

const Name = styled.h2`
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #4a4a4a;
  margin: 0;

  @media (max-width:768px){
    font-size:16px;
  }
`;

const Avatar = styled.img`
  height: 55px;
  width: 55px;
  border: solid 4px #ffffff;
  border-radius: 30px;
  transition: left 0.5s;
  margin-right: 20px;
`;

const MainContainer = styled.div`
  padding: 40px;

  @media (max-width:768px){
    padding:10px;
  }
`;

function TeamBreakDown(props) {
  const { traitData, personalityData, selectedTrait,keyword } = props;
  // const dispatch = useDispatch();
  /*const [personalityData, setPersonalityData] = React.useState(false);
  const [traitData, setTraitData] = React.useState(false);
  useEffect(() => {
		// Run! Like go get some data from an API.
    dispatch({ type: LOAD_PERSONALITY });
    dispatch({ type: LOAD_TRAIT_DATA });
	  }, [dispatch]);

	const { get_personality_data, get_trait_data} = useSelector(
		(state) => ({
      get_personality_data: state.personality.personality,
      get_trait_data: state.trait_data.trait_data,
		}),
		shallowEqual
  );

  useEffect(() => {
    setPersonalityData(get_personality_data)
  },[get_personality_data])

  useEffect(() => {
    setTraitData(get_trait_data)
  },[get_trait_data]) */

  const [open, setOpen] = React.useState(false);

  const sortedData = (type) => {
    let data = Object.keys(personalityData).map((id, i) => {
      return [id, personalityData[id][type]];
    });
    return data.sort(function (first, second) {
      return second[1] - first[1];
    });
  };

  const getPercentile = (s) => {
    let mod = s % 10;
    if (mod === 0 || mod > 3) {
      return s + "th";
    } else if (mod === 1) {
      return s + "st";
    } else if (mod === 2) {
      return s + "nd";
    } else if (mod === 3) {
      return s + "rd";
    }
  };


  return (
    <Modal
      closeIcon
      open={open}
      trigger={props.trigger}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size="small"
      style={{marginTop:70}}
    >
      {/* add this soon */}
      <TopBar>
        <Title>{props.type}</Title>
        <TitleText>{props.type && traitData[props.type].definition}</TitleText>
      </TopBar>
      <MainContainer>
        <SubTitle>Team {props.type} Scores</SubTitle>
        {sortedData(props.keyword).map((id, i) => {

          let percentile = getPercentile(Math.floor(personalityData[id[0]][keyword]));
          let pic = personalityData[id[0]].pic;
          let name = personalityData[id[0]].name=="None None"? personalityData[id[0]].email.split("@")[0]:personalityData[id[0]].name
         /* if (id[0] === props.userID) {
            return (
              <YouCard key={i}>
                <Percentile>{percentile}</Percentile>
                <Avatar src={pic} />
                <Name>{name}</Name>
              </YouCard>
            );
          } */

          return (
            <UserCard key={i}>
              <Percentile>{percentile}</Percentile>
              {pic? 
                <Avatar src={pic} />:
                <Icon name="user" style={{fontSize:30}}/>}
              <Name>{name}</Name>
            </UserCard>
          );
        })}
      </MainContainer>
    </Modal>
  );
}

export default TeamBreakDown;
