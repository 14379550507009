import {
    GET_SURVEY_STRUCTURES,
    COPY_SURVEY_STRUCTURE,
    SURVEY_STRUCTURE_SELECT,
    DELETE_SURVEY_STRUCTURE,
    FETCH_SURVEY_STRUCTURE,
    EDIT_SURVEY_STRUCTURE,
    ADD_SURVEY_STRUCTURE,
    CLEAR_SURVEY_STRUCTURE,
    CLEAR_ALL,
} from "constants/actions";

  const initialState = {
    survey_structure: [],
    survey_struture_select: [],
    survey_structure_copied: false,
    pending: false,
  };
  
  export default function FileUpload(state = initialState, action) {
    switch (action.type) {
  
      case GET_SURVEY_STRUCTURES:
        return {
          ...state,
          survey_structure: action.payload,
          pending: false,
          survey_structure_copied: false
        };
      
      case COPY_SURVEY_STRUCTURE:
        return {
          ...state,
          survey_structure_copied: action.payload,
          pending: false,
        };
      case SURVEY_STRUCTURE_SELECT:
          return {
            ...state,
            survey_struture_select: action.payload,
            pending: false,
          };
      case FETCH_SURVEY_STRUCTURE:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false
        };
      

      case EDIT_SURVEY_STRUCTURE:
        return {
          ...state,
          survey_structure: [action.payload],
          survey_structure_copied: false
          // survey_structure: [...state.survey_structure, action.payload],
        };
  
      case DELETE_SURVEY_STRUCTURE:
        return {
          ...state,
          survey_structure: state.survey_structure.filter(
            (row) => row.id !== action.payload.id
          ),
        };
  
      case ADD_SURVEY_STRUCTURE:
        return {
          ...state,
          created: action.payload,
          survey_structure: [action.payload],
          survey_structure_copied: false
          // survey_structure: [...state.survey_structure, action.payload],
        };
  
      case CLEAR_SURVEY_STRUCTURE:
        return {
          ...state,
            survey_structure: [],
            survey_struture_select: [],
            survey_structure_copied: false,
            pending: false,
        };
  
      case CLEAR_ALL:
        return {
          ...state,
            survey_structure: [],
            survey_struture_select: [],
            survey_structure_copied: false,
            pending: false,
        };
  
      default:
        return state;
    }
  }