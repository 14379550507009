import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import PercentileBubble from "./Bubble";
import User from 'assets/images/empty/user.svg'
import {serverUrl} from "_config";

const Container = styled.div`
   border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 150px;
  max-height:323px;

  @media(max-width:768px){
   flex-direction:column;
   margin-top:20px;
   max-height:none;
  }
`

const Button = styled.div`
  width: 233px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  display:flex;
  align-items:center;
  justify-content:center;
  color:white;
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-bottom:37px;
  cursor:pointer;
`

const Definition = styled.div`
  width: 40%;
  padding: 40px 40px 40px 40px;

  @media(max-width:768px){
    width:100%;
    padding:20px;
  }
`;
const Percentile = styled.div`
  width: 60%;
  border-radius: 8px;
  background-color: #f8f8f8;
  margin: 7px 8px 7px 0px;
  color: #4f00bf;
  padding: 50px 20px 80px 36px;

   @media(max-width:768px){
    width:100%;
    padding:20px;
    padding-bottom:80px;
  }
`;

const Bar = styled.div`
  height: 12px;
  border-radius: 8px;
  background-color: #e9e9e9;
  width: 100%;
  position: relative;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #545454;
  display: flex;
  align-items: center;
  margin-top: 100px;
`;

const EmptyAvatar = styled.div`
  height: 55px;
  width: 55px;
  background-color:lightgrey;
  border: solid 4px #6a00ff;
  border-radius: 30px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 2;
`;

const Avatar = styled.img`
  height: 55px;
  width: 55px;
  border-radius: 22.5px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 3;
  transition: left 0.5s;
`;

const Team = styled.div`
  height: 50px;
  width: 50px;
  background-color: #6a00ff;
  border-radius: 25px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 2;
`;

const BlueBar = styled.div`
  width: ${(props) => props.width + "%"};
  height: 12px;
  background-color: #6a00ff;
  left: ${(props) => props.position + "%"};
  position: absolute;
  transition: width 0.5s;
`;

const Text1 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom:5px;
`;

const Text2 = styled.div`
  font-family: "Red Hat Display", sans-serif;
 font-size: 23px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
`;

const Text3 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969696;
  margin-bottom: 40px;
`;

const Empty = ({employee, personalityToken}) =>{

  const [score1,setScore] = React.useState(30)
  const [score2,setScore2] = React.useState(60)

  const getPosition = () => {
    let width = Math.abs(score1 - score2)      
      return (
        <React.Fragment>
            <Avatar position={score1} src={User} /> 
            <Team position={score2} />
          <BlueBar
            width={width ? width : ""}
            position={score2 > score1 ? score1 : score2}
          />
          <PercentileBubble position={score1} text={'You'} />
          <PercentileBubble position={score2} text={'Team avg'} />
        </React.Fragment>
      )
  };
  // console.log(employee)
	return (
		<Container>
			<Definition>
          <Text1>Hi {employee?.first_name
          ? `${employee?.first_name} ${employee?.last_name}` 
          : employee?.email }, welcome to</Text1>

          <Text2>{employee.team_name?employee.team_name:""} Personality Suite</Text2>
          <Text3>
            This is the place where you’ll see how your personality compares to others on your team, but first we need to learn a little more about you!
          </Text3>
          {personalityToken && 
           <div style={{display:'flex',justifyContent:'center'}}>
             <a 
             target="_blank" rel="noopener noreferrer"
             href={`${serverUrl}/personality-survey/${personalityToken.token}/`}>

            <Button>Complete Survey</Button>
            </a>
          </div>
          }
         

      </Definition>

      <Percentile>
        

        <Bar>
          <span style={{ position: "absolute", left: 0, top: 30 }}>0</span>
          <span style={{ position: "absolute", left: 0, top: 50 }}>Low</span>
          <span style={{ position: "absolute", right: 0, top: 30 }}>100</span>
          <span style={{ position: "absolute", right: 0, top: 50 }}>High</span>
          {getPosition()}
        </Bar>
      </Percentile>
		</Container>
		)
}


export default Empty