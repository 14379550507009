import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import Side from "./Side";
import Footer from "./Footer";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

Font.register({
  family: "Barlow",
  src: Barlow,
  format: "truetype",
});

const OTPDimensions = (props) => {
  const { categories, structure, calculated, titleColor, isFrench } = props;
  const lang = isFrench ? "fr" : "en";

  const anchorTwo = categories.categories.find(
    (f) => f.priority === "secondary"
  );

  let tableData;

  const getColConfig = () => {
    const primaryOptions = categories.categories.find(
      (f) => f.priority === "primary"
    );
    return primaryOptions.options.map((po) => {
      return {
        name: po.name,
        color: po.color,
        subfactors: po.subfactors,
        id: po.id,
      };
    });
  };

  const config = getColConfig();

  const getOTPTableData = (data) => {
    const anchorTwo = categories.categories.find(
      (f) => f.priority === "primary"
    );
    const tableData = structure.dimensions.map((d) => {
      const dimensionResponses = data?.filter(
        (r) => Number(r.dimension) === d.id - 1
      );

      let nationalAverage = {};
      let nationalN = 0;
      dimensionResponses.forEach((dr) => {
        nationalN += dr?.n || 0;
        if (dr?.role) {
          if (!nationalAverage[dr.role]?.n) {
            nationalAverage[dr.role] = {
              n: dr?.n || 0,
              sum: dr?.average ? dr?.average * dr?.n : 0,
            };
          } else {
            nationalAverage[dr.role].n += dr?.n || 0;
            nationalAverage[dr.role].sum += dr?.average
              ? dr?.average * dr?.n
              : 0;
          }
        }
      });

      const tableGroups = categories.categories
        ?.find((ao) => ao.priority === "secondary")
        .options?.map((ao) => {
          return {
            title: isFrench ? ao?.fr.charAt(0).toUpperCase() : ao.name.charAt(0).toUpperCase(),
            id: ao.id,
          };
        });
      // loop through config to get categorized data

      const dimensionInfo = config.map((ci) => {
        const primaryLabel = ci.name;
        let primaryResponses = [];
        let sportAverage = {};
        dimensionResponses.forEach((dr) => {
          const matchingResponses = dr.primaryData.filter(
            (fpd) => ci.id === fpd.group
          );
          primaryResponses.push({
            responses: matchingResponses,
            role: dr.role,
            primary: primaryLabel,
          });
        });

        return {
          primary: primaryLabel,
          responses: primaryResponses,
          id: ci.id,
        };
      });

      let nationalSum = 0;
      let nationalNValue = 0;
      const nationalAvgRows = tableGroups.map((tg) => {
        const currentGroup = nationalAverage[tg.id];
        const roleAvg = currentGroup?.sum
          ? currentGroup.sum / currentGroup.n
          : 0;
        nationalSum += nationalAverage[tg.id]?.sum || 0;
        nationalNValue += nationalAverage[tg.id]?.n || 0;
        return {
          id: tg.id,
          average: Math.floor(roleAvg * 10) / 10,
        };
      });

      const nationalAvg = nationalSum / nationalNValue;

      const nationalAverageRow = {
        factor: getTranslatedText("nationalAverage", lang, translations),
        factorAverage: Math.floor(nationalAvg * 10) / 10,
        factorResponses: nationalAvgRows,
        rowId: 0,
      };

      const dimensionData = config.map((ci) => {
        // get list of all responses related to current category.
        const ciResponses = dimensionInfo
          .filter((di) => di.id === ci.id)
          .map((di) => {
            const diResponses = di.responses.map((dr) => dr.responses);
            return diResponses.flat();
          })
          .flat();
        const ciAvg =
          ciResponses.reduce((total, b) => {
            return (total += b.response ? Number(b.response) : 0);
          }, 0) / ciResponses.length;

        const rowCols = tableGroups.map((tg) => {
          const ciResponses = dimensionInfo
            .filter((di) => di.id === ci.id)
            .map((di) => {
              const diResponses = di.responses
                .filter((dir) => dir.role === tg.id)
                .map((dr) => dr.responses);
              return diResponses.flat();
            })
            .flat();
          const roleAvg =
            ciResponses.reduce((total, b) => {
              return (total += b.response ? Number(b.response) : 0);
            }, 0) / ciResponses.length;

          return {
            id: tg.id,
            average: Math.floor(roleAvg * 10) / 10,
          };
        });

        return {
          factor: ci.name,
          factorAverage: Math.floor(ciAvg * 10) / 10,
          factorResponses: rowCols,
          rowId: ci.id,
        };
      });

      dimensionData.unshift(nationalAverageRow);

      return {
        dimension: d.title,
        dimensionId: d.id,
        dimensionAvg: null,
        data: dimensionData,
      };
    });

    return tableData;
  };

  tableData = getOTPTableData(calculated.responses);

  const createTables = (
    dimension,
    headerColor,
    wrapRow = true,
    hideReverseText = true
  ) => {
    return (
      <View>
        <View
          style={[
            styles.tableStyle,
            { marginBottom: (hideReverseText && 30) || 10 },
          ]}
          wrap={!wrapRow}
        >
          <View style={styles.body}>
            <View style={[{ backgroundColor: "white" }, styles.H]}>
              <View style={[{ width: "42%" }, styles.TH]} wrap={wrapRow}>
                <Text>{dimension.dimension} Domain Overall</Text>
              </View>
              <View style={[{ width: "10.5%" }, styles.TH]} wrap={wrapRow}>
                <Text>{dimension.dimensionAvg}</Text>
              </View>
              <View style={[{ width: "51.5%" }, styles.TH]} wrap={wrapRow}>
                <Text>
                  {getTranslatedText("by", lang, translations)}{" "}
                  {isFrench ? anchorTwo?.fr_name : anchorTwo.name}
                </Text>
              </View>
            </View>
            <View style={[{ backgroundColor: "white" }, styles.H]}>
              <View style={[{ width: "45%" }, styles.TH]} wrap={wrapRow}>
                <Text></Text>
              </View>
              <View style={[{ width: "11%" }, styles.TH]} wrap={wrapRow}>
                <Text>{getTranslatedText("avg", lang, translations)}</Text>
              </View>
              <View style={[{ width: "11%" }, styles.TH]} wrap={wrapRow}>
                <Text>{getTranslatedText("Athlete", lang, translations)}</Text>
              </View>
              <View style={[{ width: "11%" }, styles.TH]} wrap={wrapRow}>
                <Text>{getTranslatedText("Coach", lang, translations)}</Text>
              </View>
              <View style={[{ width: "11%" }, styles.TH]} wrap={wrapRow}>
                <Text>SSSM</Text>
              </View>
              <View style={[{ width: "11%" }, styles.TH]} wrap={wrapRow}>
                <Text>{getTranslatedText("NSOStaff", lang, translations)}</Text>
              </View>
            </View>
            {dimension.data.map((row) => {
              return (
                <View style={[{ backgroundColor: "white" }, styles.H]}>
                  <View style={[{ width: "45%" }, styles.TD]} wrap={wrapRow}>
                    <Text>{row.factor}</Text>
                  </View>
                  <View style={[{ width: "11%" }, styles.TD2]} wrap={wrapRow}>
                    <Text>{row.factorAverage || "--"}</Text>
                  </View>
                  {row.factorResponses.map((col) => {
                    return (
                      <View
                        style={[{ width: "11%" }, styles.TD2]}
                        wrap={wrapRow}
                      >
                        <Text>{col.average || "--"}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        {!hideReverseText && (
          <Text style={styles.tableSubText}>
            {getTranslatedText("reverseTextMsg", lang, translations)}
          </Text>
        )}
      </View>
    );
  };

  return (
    <>
      <Page style={styles.page}>
        <Text style={[styles.T2, { color: titleColor }]}>
          {getTranslatedText("comparativeData", lang, translations)}
        </Text>
        <View style={styles.line} />
        {tableData.length > 0 && (
          <View>{createTables(tableData[0], "blue")}</View>
        )}
        {tableData.length > 0 && (
          <View>{createTables(tableData[1], "blue")}</View>
        )}
        <Side />
        <Footer isFrench={isFrench} />
      </Page>
    </>
  );
};

export default OTPDimensions;

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  tableStyle: {
    width: "100%",
    border: "0.5px solid grey",
    marginTop: 20,
  },
  TD: {
    fontSize: 10,
    padding: 4,
    borderBottom: "0.5px solid grey",
    borderRight: "0.5px solid grey",
  },
  TD2: {
    fontSize: 10,
    padding: 4,
    borderBottom: "0.5px solid grey",
    borderRight: "0.5px solid grey",
    display: "flex",
    alignItems: "center",
  },
  TH: {
    fontSize: 10,
    padding: 4,
    borderBottom: "0.5px solid grey",
    borderRight: "0.5px solid grey",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  H: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
  },
});
