import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Modal from "./Modal";
import QA from "./QA";
import ProgressBar from "./Progress";
import make_call from './Request'

const Context = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const totalSteps = 3;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [history, setHistory] = useState([]);
  const [answer, setAnswer] = useState("");

  const toggleModal = () => setIsOpen(!isOpen);

  const make_gpt_call = () => {
    setLoading(true);
    async function someFunction() {
      let _history = [...history];

      if (answer) {
        _history = [..._history, { role: "user", content: answer }];
        setAnswer("");
      }

      const resp = await make_call(_history);

      setMessage(resp);

      setHistory([..._history, { role: "assistant", content: resp }]);

      setLoading(false);
    }

    someFunction();
  };

  const change_step = () => {
    setStep(step < totalSteps ? step + 1 : step);

    setMessage("");

    make_gpt_call();
  };

  return (
    <Container>
      <button onClick={toggleModal}>Toggle Modal</button>
      <Modal isOpen={isOpen}>
        <Title>Create a culture plan</Title>
        {step === 1 ? (
          <Step1 />
        ) : (
          <QA
            loading={loading}
            answer={answer}
            setAnswer={setAnswer}
            question={message}
          />
        )}
        <ProgressContainer>
          <ProgressBar step={step} totalSteps={totalSteps} />
        </ProgressContainer>

        <StyledButton onClick={() => change_step()}>
          {step === 1 ? "Get Started" : "Next"}
        </StyledButton>
        {/* <button onClick={toggleModal} style={{marginTop:40}}>Close</button> */}
      </Modal>
    </Container>
  );
};

export default Context;


const Container = styled.div`
    margin-top:40px;
    padding-left:60px;
`

const Title = styled.div`
    font-family:'Raleway';
    font-size:24px;
    font-weight:bold;
    margin-bottom:30px;

`

const Text = styled.div`
    font-family:'Raleway';
    font-size:16px;
    margin-bottom:40px;
    `

const StyledButton = styled.div`
    font-family:'Raleway';
    font-size:16px;
    font-weight:bold;
    padding:10px 20px;
    border-radius:5px;
    background-color:#476DFA;
    color:white;
    border:none;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:50px;
    position:absolute;
    bottom:30px;
    right:30px;
`

const ProgressContainer = styled.div`
    width:60%;
    position:absolute;
    bottom:40px;
    left:30px;

`

const Step1 = () =>{
    return (
        <>
        
        <Text>We're going to ask you a few questions to help create the most personalized plan for you.</Text>
        </>
    )
}