import styled, { keyframes } from "styled-components";
import React from "react";
import { Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";


const Cluster = () =>{

	return (
		<Container>
			<Title>Team Clusters</Title>
			<Description>The team cluster analysis looks for trends in the team intelligence survey responses and identifies clusters of similarity within your team. These patterns are then analyzed for the most meaningful traits which make these team members similar in how they responded to their survey.</Description>
			<ClusterContainer>
				<ACluster>
					<Number>8</Number>
					<Content>
						<Line width={100}/>
						<ClusterTitle>Cluster 1</ClusterTitle>
						<AvatarContainer>
							<Avatar src={"https://randomuser.me/api/portraits/men/20.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/men/54.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/women/85.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/men/85.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/women/45.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/women/35.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/men/75.jpg"} />
							<Avatar src={"https://randomuser.me/api/portraits/women/65.jpg"} />
						</AvatarContainer>
						<ClusterDescription>Score Impact:</ClusterDescription>
						<Progress percent={56} progress size='small' style={{marginBottom:15,marginTop:5,width:'80%'}} color='violet'/>
						<ClusterDescription>Interconnectedness:</ClusterDescription>
						<Progress percent={88} progress size='small' style={{marginBottom:15,marginTop:5,width:'80%'}} color='violet'/>

						<ClusterDescription>Sentiment:</ClusterDescription>
						 <Chart options={data2.options} series={series1} type="bar" height={30}/>

						<ClusterDescription>Similarities:</ClusterDescription>
						<ul>
							<Li>Significantly more feelings of motivation</Li>
							<Li>Moderately less feelings of stress</Li>
							<Li>Moderately less feelings of discontent</Li>
						</ul>
					</Content>
				</ACluster>
				<ACluster>
					<Number>6</Number>
					<Content>
						<Line width={75}/>
						<ClusterTitle>Group 2</ClusterTitle>
						<AvatarContainer>
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							
						</AvatarContainer>

						<ClusterDescription>Similarities:</ClusterDescription>
						<ul>
							<Li>Moderately less feelings of motivation</Li>
							<Li>Moderately more feelings of discontent</Li>
							<Li>Moderately lower team resilience</Li>
						</ul>

					</Content>
				</ACluster>

				<ACluster>
					<Number>5</Number>
					<Content>
						<Line width={62}/>
						<ClusterTitle>Group 3</ClusterTitle>
						<AvatarContainer>
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />
							<Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"} />							
						</AvatarContainer>

						<ClusterDescription>Similarities:</ClusterDescription>
						<ul>
							<Li>Significantly more feelings of stress</Li>
							<Li>Significantly more feelings of anger</Li>
							<Li>Moderately less feelings of enjoyment</Li>
						</ul>

					</Content>
				</ACluster>
			</ClusterContainer>
		</Container>
		)
}


export default Cluster


const Container = styled.div`
	margin-top:20px;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:40px;
	width:50%;
	margin-right:20px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:16px;
	font-weight:bold;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:30px;
`

const ACluster = styled.div`
	display:flex;
	width:100%;
	margin-bottom:20px;
`

const Number = styled.div`
	height:50px;
	width:50px;
	border:1px solid #6a00ff;
	border-radius:25px;
	font-family: "Red Hat Display", sans-serif;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:24px;
	font-weight:bold;
	color:#6a00ff;
	margin-right:15px;
	min-width:50px;
`

const Content = styled.div`
	width:100%;
`

const Line = styled.div`
	width:${props=>'calc('+props.width+'% - 30px)'};
	height:5px;
	background-color:#6a00ff;
	margin-bottom:5px;
`

const Avatar = styled.img`
	width:30px;
	height:30px;
	border-radius:15px;
	margin-right:5px;
`

const AvatarContainer = styled.div`
	display:flex;
`

const ClusterContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
	width:100%;
`

const ClusterTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
	margin-bottom:5px;
`

const ClusterDescription = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top:15px;
	font-weight:500;
`

const Li = styled.li`
	font-family: "Red Hat Display", sans-serif;
`

 const data2 = {
          
            options: {
              chart: {
                type: 'bar',
                height: 30,
                stacked: true,
                stackType: '100%',
                  sparkline: {
                enabled: true
              },
              },
              grid:{
                padding:{
                  top:0
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              dataLabels:{
                enabled:true
              },
              stroke: {
                width: 1,
                colors: ['#fff']
              },
              xaxis: {
                categories: ['Trust scores'],
              },
              fill: {
                opacity: 0.5,
                colors:['#ff2d55','#ffb812','#08c251']
              
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return val + " times"
                  }
                }
              },
              legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: -30
              }
            },
          
          
          }
const series1= [{name: 'Negative',data: [15]}, {
              name: 'Neutral',
              data: [10]
            }, {
              name: 'Positive',
              data: [75]
            }]


