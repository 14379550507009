import React from 'react';
import { Page, Text, View, Document, StyleSheet,Image,Font,Svg, Path,Defs,LinearGradient,Stop  } from '@react-pdf/renderer';
import Logo from "assets/images/pdf/Logo.png";
import CoverImg from "assets/images/pdf/rec.png";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import TextBlock from './components/TextBlock';
import {IdeaIcon, TeamsIcon} from "./icons";
import Footer from "./Footer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    width: "100%",
    height: 600,
    padding: 40,
    fontFamily: "Raleway",
  },
  chart: {
    width: "100%",
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    alignContent: "center",
    marginBottom: 20,
  },
  teamName: {
    fontSize: 8,
    marginRight: 10,
    color: "#666D79",
  },
  chartArea: {
    width: 500,
    height: "100%",
    borderBottom: "1px solid #DFDFDF",
    borderTop: "1px solid #DFDFDF",
    flexDirection: "row",
    alignItems: "center",
  },
  line0: {
    position: "absolute",
    height: "120%",
    width: 1,
    backgroundColor: "#DFDFDF",
    left: 0,
  },
  line20: {
    position: "absolute",
    height: "120%",
    width: 1,
    backgroundColor: "#DFDFDF",
    left: "20%",
  },
  line40: {
    position: "absolute",
    height: "120%",
    width: 1,
    backgroundColor: "#DFDFDF",
    left: "40%",
  },
  line60: {
    position: "absolute",
    height: "120%",
    width: 1,
    backgroundColor: "#DFDFDF",
    left: "60%",
  },
  line80: {
    position: "absolute",
    height: "120%",
    width: 1,
    backgroundColor: "#DFDFDF",
    left: "80%",
  },
  line100: {
    position: "absolute",
    height: "120%",
    width: 1,
    backgroundColor: "#DFDFDF",
    left: "100%",
  },
  number: {
    marginTop: 50,
    fontSize: 8,
    color: "#666D79",
    marginLeft: -2,
  },
  number2: {
    marginTop: 50,
    fontSize: 8,
    color: "#666D79",
    marginLeft: -4,
  },
  greenLine: {
    position: "absolute",
    height: "170%",
    width: 4,
    backgroundColor: "rgba(39, 205, 167, 0.50)",
    borderRadius: 3,
    alignItems: "center",
    color: "#666D79",
    fontSize: 8,
  },
});

// Create Document Component
const ReportPage = (props) => {
  const { data, textBlockOne, textBlockTwo } = props;
  return (
    <Page style={styles.page}>
      <View>
        <Text>{data.pageTitle}</Text>
      </View>

      <View style={styles.chart}>
        <Text style={styles.teamName}>{data.teamName}</Text>
        <View style={styles.chartArea}>
          <View style={styles.line0}>
            <Text style={styles.number}>0</Text>
          </View>

          <View style={styles.line20}>
            <Text style={styles.number2}>20</Text>
          </View>

          <View style={styles.line40}>
            <Text style={styles.number2}>40</Text>
          </View>

          <View style={styles.line60}>
            <Text style={styles.number2}>60</Text>
          </View>

          <View style={styles.line80}>
            <Text style={styles.number2}>80</Text>
          </View>

          <View style={styles.line100}>
            <Text style={styles.number2}>100</Text>
          </View>
          <View style={{ width: "100%", height: 25, opacity: 0.5 }}>
            <ProgressBar width={data.barPercentage} />
          </View>

          <View style={[styles.greenLine, { left: "75%" }]}>
            <View style={{ width: 200, marginTop: -15, alignItems: "center" }}>
              <Text>{data.orgName} average</Text>
            </View>
          </View>
        </View>
      </View>
      <TextBlock
        title={textBlockOne.title}
        description={textBlockOne.description}
        bulletTitle={textBlockOne.bulletTitle}
        bullets={textBlockOne.bullets}
        bgColor={textBlockOne.bgColor}
        icon={textBlockOne.icon}
      />
      <TextBlock
        title={textBlockTwo.title}
        bgColor={textBlockTwo.bgColor}
        numberedItems={textBlockTwo.numberedItems}
        icon={textBlockTwo.icon}
      />
      <Footer />
    </Page>
  );
};
  
  Font.register({
      family: "Raleway",
      fonts: [{ src: Raleway }],
    });
    
  
  export default ReportPage;


  const ProgressBar = ({ width }) => {
    const percentageWidth = `${width}%`;

    return (
        <Svg
            width={'100%'}
            height="25"
            viewBox={`0 0 ${100} 30`}
            preserveAspectRatio="none"
           
        >
            {/* Define the linear gradient */}
            <Defs>
                <LinearGradient
                    id="progressGradient">
                    <Stop offset="8%" stopColor="#F8A8B0" />
                    <Stop offset="50%" stopColor="#FECD8D" />
                    <Stop offset="92%" stopColor="#95E6D2" />
                </LinearGradient>
            </Defs>

            {/* Draw the rectangle with the gradient */}
            <Path
                d={`M 0 0 H ${width-2} Q ${width} 0 ${width} 2 V 25 Q ${width} 30 ${width-2} 30 H 0 V 0`}
                fill="url(#progressGradient)"
                style={{opacity:0.5}}
            />
        </Svg>
    );
};
