import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "./images/Logo.svg";
import ILROILogo from "./images/IL-ROI-Logo.svg";
import ROIStats from "./images/roi-stats.png";
import Quote from "./images/quote-image.png";
import VerticalProgress from "./VerticalProgress";
import styled from "styled-components";
import axios from "axios";
import urlconfig from "../_config";
const VERSION = "v1";

export async function FETCH_ROI_DATA(payload) {
  const { token } = payload;
  const url = `/api/organization/${VERSION}/marketing-roi/?token=${token}`;
  // /api/organization/v1/marketing-roi/?token=4053dba9-457d-4f76-830d-3c569456801f
  const response = await axios.get(`https://api.innerlogic.com${url}`);
  return response.data;
}

function MarketingReport() {
  let { token } = useParams();
  const [roiData, setRoiData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const data = await FETCH_ROI_DATA({ token });
      if (data && data.length > 0) {
        return data;
      }
    }
    const roiData = fetchData();

    roiData.then((data) => {
      const dataResponses = Object.keys(data[0]?.response);
      const sortedData = dataResponses.map((item) => {
        return {
          name: item,
          value: data[0]?.response[item],
        };
      });
      setRoiData(sortedData);
    });
  }, [token]);

  const getStatBar = (value, label, customLabel) => {
    return (
      <StatBarContainer>
        <VerticalProgress
          label={label}
          completed={value}
          customLabel={customLabel}
        />
      </StatBarContainer>
    );
  };

  const getQuestionThreeMapping = (value) => {
    if (value?.includes(4)) {
      return { value: 2, label: "Conversational AI" };
    }

    if (value?.length === 0) {
      return { value: 5, label: "N/A" };
    } else {
      let label = "";
      if (value?.includes(0)) {
        label += "No formal process;";
      }
      if (value?.includes(1)) {
        label += "Informal conversations;";
      }
      if (value?.includes(2)) {
        label += "Structured Interviews/check-ins;";
      }
      if (value?.includes(3)) {
        label += "Static surveys or pulses;";
      }

      return {
        value: 10,
        label: label,
      };
    }
  };

  const getQuestionFiveMapping = (value) => {
    if (value === 0) {
      return { value: 1, label: "About a week" };
    }
    if (value === 1) {
      return { value: 5, label: "About a month" };
    }
    if (value === 2) {
      return { value: 6, label: "2-3 months" };
    }
    if (value === 3) {
      return { value: 8, label: "More than 3 months" };
    }
    if (value === 4) {
      return { value: 10, label: "Never happens" };
    }
  };

  const getQuestionSevenMapping = (value) => {
    if (value === 0) {
      return { value: 10, label: "None" };
    }
    if (value === 1) {
      return { value: 8, label: "A few times a year" };
    }
    if (value === 2) {
      return { value: 6, label: "About half the year" };
    }
    if (value === 3) {
      return { value: 4, label: "Most of the time" };
    }
    if (value === 4) {
      return { value: 0, label: "Unlimited / full time access" };
    }
  };

  return (
    <ScreenContainer>
      <LogoSection>
        <LogoImage src={Logo} alt="Innerlogic Logo" />
      </LogoSection>
      <TopBlock>
        <HeadingText>
          Innerlogic&nbsp;
          <BlockImageWrapper>
            <ImageItem src={ILROILogo} alt="Innerlogic Logo Without Text" />
          </BlockImageWrapper>
          Readiness
        </HeadingText>
        <HeadingText>
          And
          <BlockImageWrapper>
            <BorderedImage src={ROIStats} alt="ROI Stats image" />
          </BlockImageWrapper>
          ROI Report
        </HeadingText>
        {/* <HeadingSubText>
          Building a&nbsp;
          <StrongText>healthy, high-performing culture</StrongText>
          accelerates your people’s growth and positively impacts your bottom
          line - companies that don’t are more than 70 percent likely to fail.
        </HeadingSubText> */}
      </TopBlock>
      <QuoteBlock>
        <QuoteAndImage>
          <QuoteImage src={Quote} alt="Quote" />
          <QuoteTextWrapper>
            <QuoteText>If you don't manage culture, it manages you.</QuoteText>
            <AuthorText>Dr. Eric MacIntosh</AuthorText>
          </QuoteTextWrapper>
        </QuoteAndImage>
        <QuoteDescription>
          Building a healthy, high-performing culture accelerates your people’s
          growth and positively impacts your bottom line - companies that don’t
          are more than <StrongText>70 percent</StrongText> likely to fail.
          <StrongText>*</StrongText>
          <br />
          <br />
          When it comes to culture feedback, an average of{" "}
          <StrongText>78 percent</StrongText> of companies fail to get tangible
          results, and of those who do, <StrongText>80 percent</StrongText> say
          they produce no meaningful change. One of the key contributing factors
          to this staggering lack of impact from people analytics is time. Our
          research suggests it takes HR leaders{" "}
          <StrongText>3-6 months</StrongText> to interpret and implement
          evidence-based solutions from employee feedback, leading to a lack of
          follow-through, and allowing cultural issues to persist and intensify.
          <br />
          <br />
          This cycle is unsustainable and costs organizations{" "}
          <StrongText>millions of dollars</StrongText> in lost productivity and
          turnover.
          <br />
          <br />
          With innerlogic, our AI culture copilot links your people data to{" "}
          <StrongText>
            instant, evidence-based recommendations and action plans.
          </StrongText>{" "}
          Moreover, our copilot technology gives your organization unlimited
          access to dynamic consulting based on unique cultural challenges,
          allowing your leaders to interact with it like they would a culture
          consultant for a fraction of the cost and time.
          <br />
          <br />
          <QuoteReference>
            <StrongTextAstr>*</StrongTextAstr>
            <QuoteReferenceText>
              Dewar, C. & Doucette, R. (2018, March 27). Culture: 4 keys to why
              it matter. McKinsey & Company,&nbsp;
              <UrlWrapper>
                https://www.mckinsey.com/capabilities/people-and-organizational-performance/our-insights/the-organization-blog/culture-4-keys-to-why-it-matters
              </UrlWrapper>
            </QuoteReferenceText>
          </QuoteReference>
        </QuoteDescription>
      </QuoteBlock>
      {roiData && roiData?.length > 0 && (
        <BreakdownSection>
          <Breakdown>
            <BreakdownInfo>
              <BreakdownHeading>
                <BreakdownNumber>1</BreakdownNumber>
                <BreakdownHeadingText>
                  Breakdown of your organization’s readiness for innerlogic
                </BreakdownHeadingText>
              </BreakdownHeading>
              <BreakdownDescription>
                Your readiness score is based on how important prioritizing
                healthy, high performance organizational culture is to you. It
                considers the value of unique employee perspectives in
                identifying culture gaps / growth areas in a timely manner.
                Lastly, the score reveals how ready you are to adopt AI
                technology.
              </BreakdownDescription>
            </BreakdownInfo>
            <BreakdownStats>
              <MobileLegendSection>
                <MobileLegendBox>
                  <MobileLegendText>Legend</MobileLegendText>
                  <MobileLegendItemsContainer>
                    <MobileLegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>Low Readiness</LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>Moderate Readiness</LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>High Readiness</LegendItemText>
                    </MobileLegendItem>
                  </MobileLegendItemsContainer>
                </MobileLegendBox>
              </MobileLegendSection>
              <StatsSection>
                <BreakdownStat>
                  <BreakdownStatQuestion>
                    How much of a priority is it for your organization to scale
                    a healthy, high-performing culture?
                  </BreakdownStatQuestion>
                  {getStatBar(roiData[0]?.value, "readiness")}
                </BreakdownStat>
                <BreakdownStat>
                  <BreakdownStatQuestion>
                    How much do you value the unique experiences and
                    perspectives of your people when it comes to building /
                    growing your culture?
                  </BreakdownStatQuestion>
                  {getStatBar(roiData[1]?.value, "readiness")}
                </BreakdownStat>
                <BreakdownStat>
                  <BreakdownStatQuestion>
                    How important is it for you to enact timely change once
                    culture gaps / growth areas are identified?
                  </BreakdownStatQuestion>
                  {getStatBar(roiData[3]?.value, "readiness")}
                </BreakdownStat>
                <BreakdownStat>
                  <BreakdownStatQuestion>
                  How valuable would it be for the leaders in your organization
                   to have instant access to resources and solutions matched to
                    them based on their people data?
                  </BreakdownStatQuestion>
                  {getStatBar(roiData[5]?.value, "readiness")}
                </BreakdownStat>
              </StatsSection>
              <LegendSection>
                <LegendBox>
                  <LegendText>Legend</LegendText>
                  <LegendItemsContainer>
                    <LegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>Low Readiness</LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>Moderate Readiness</LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>High Readiness</LegendItemText>
                    </LegendItem>
                  </LegendItemsContainer>
                </LegendBox>
              </LegendSection>
            </BreakdownStats>
          </Breakdown>
          <Breakdown>
            <BreakdownInfo>
              <BreakdownHeading>
                <BreakdownNumber>2</BreakdownNumber>
                <BreakdownHeadingText>
                  Breakdown of innerlogic’s projected ROI for your organization
                </BreakdownHeadingText>
              </BreakdownHeading>
              <BreakdownDescription>
                Your ROI score considers your current practices on collecting
                culture data, as well as the amount of time, and access to
                expertise you have in implementing meaningful culture changes
                based on it. Lastly, it determines how beneficial integrating
                our end-to-end AI culture copilot is for your organization.
              </BreakdownDescription>
            </BreakdownInfo>
            <BreakdownStats>
              <MobileLegendSection>
                <MobileLegendBox>
                  <MobileLegendText>Legend</MobileLegendText>
                  <MobileLegendItemsContainer>
                    <MobileLegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>
                        Low ROI (Conversational AI)
                      </LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>Moderate ROI (N/A)</LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>
                        High ROI (No process; informal conversations; static
                        surveys)
                      </LegendItemText>
                    </MobileLegendItem>
                  </MobileLegendItemsContainer>
                </MobileLegendBox>
              </MobileLegendSection>
              <StatsSection>
                <BreakdownStat single={true}>
                  <SingleBreakdownStatQuestion>
                    What is your current process for gathering data on the
                    unique experiences and perspectives within your culture?
                  </SingleBreakdownStatQuestion>
                  {getStatBar(
                    getQuestionThreeMapping(roiData[2]?.value)?.value,
                    "ROI",
                    getQuestionThreeMapping(roiData[2]?.value)?.label
                  )}
                </BreakdownStat>
              </StatsSection>
              <LegendSection>
                <LegendBox>
                  <LegendText>Legend</LegendText>
                  <LegendItemsContainer>
                    <LegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>
                        Low ROI (Conversational AI)
                      </LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>Moderate ROI (N/A)</LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>
                        High ROI (No process; informal conversations; static
                        surveys)
                      </LegendItemText>
                    </LegendItem>
                  </LegendItemsContainer>
                </LegendBox>
              </LegendSection>
            </BreakdownStats>
            <BreakdownStats>
              <MobileLegendSection>
                <MobileLegendBox>
                  <MobileLegendText>Legend</MobileLegendText>
                  <MobileLegendItemsContainer>
                    <MobileLegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>Low ROI (0-5 days)</LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>Moderate ROI (20-60 days)</LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>
                        High ROI (More than 60 days)
                      </LegendItemText>
                    </MobileLegendItem>
                  </MobileLegendItemsContainer>
                </MobileLegendBox>
              </MobileLegendSection>
              <StatsSection>
                <BreakdownStat>
                  <SingleBreakdownStatQuestion>
                  How long does it typically take for your organization to implement effective, 
                  evidence-based changes using culture feedback?
                  </SingleBreakdownStatQuestion>
                  {getStatBar(
                    getQuestionFiveMapping(roiData[4]?.value)?.value,
                    "ROI",
                    getQuestionFiveMapping(roiData[4]?.value)?.label
                  )}
                </BreakdownStat>
              </StatsSection>
              <LegendSection>
                <LegendBox>
                  <LegendText>Legend</LegendText>
                  <LegendItemsContainer>
                    <LegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>Low ROI (0-5 days)</LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>Moderate ROI (20-60 days)</LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>
                        High ROI (More than 60 days)
                      </LegendItemText>
                    </LegendItem>
                  </LegendItemsContainer>
                </LegendBox>
              </LegendSection>
            </BreakdownStats>
            <BreakdownStats>
              <MobileLegendSection>
                <MobileLegendBox>
                  <MobileLegendText>Legend</MobileLegendText>
                  <MobileLegendItemsContainer>
                    <MobileLegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>
                        Low ROI (Most of the time to unlimited)
                      </LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>
                        Moderate ROI (About half of the year)
                      </LegendItemText>
                    </MobileLegendItem>
                    <MobileLegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>
                        High ROI (None to a few times per year)
                      </LegendItemText>
                    </MobileLegendItem>
                  </MobileLegendItemsContainer>
                </MobileLegendBox>
              </MobileLegendSection>
              <StatsSection>
                <BreakdownStat>
                  <SingleBreakdownStatQuestion>
                  How much access do the leaders in your organization currently have to
                   curated resources and solutions based on their people data?
                  </SingleBreakdownStatQuestion>
                  {getStatBar(
                    getQuestionSevenMapping(roiData[6]?.value)?.value,
                    "ROI",
                    getQuestionSevenMapping(roiData[6]?.value)?.label
                  )}
                </BreakdownStat>
              </StatsSection>
              <LegendSection>
                <LegendBox>
                  <LegendText>Legend</LegendText>
                  <LegendItemsContainer>
                    <LegendItem>
                      <LegendBar color="#F15062" />
                      <LegendItemText>
                        Low ROI (Most of the time to unlimited)
                      </LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#FEA711" />
                      <LegendItemText>
                        Moderate ROI (About half of the year)
                      </LegendItemText>
                    </LegendItem>
                    <LegendItem>
                      <LegendBar color="#27CDA7" />
                      <LegendItemText>
                        High ROI (None to a few times per year)
                      </LegendItemText>
                    </LegendItem>
                  </LegendItemsContainer>
                </LegendBox>
              </LegendSection>
            </BreakdownStats>
          </Breakdown>
          {/* <Breakdown>
            <BreakdownInfo>
              <BreakdownHeading>
                <BreakdownNumber>3</BreakdownNumber>
                <BreakdownHeadingText>
                  Total spent Time results
                </BreakdownHeadingText>
              </BreakdownHeading>
              <BreakdownDescription></BreakdownDescription>
            </BreakdownInfo>
            <TimeBreakdownContainer>
              <TimeSpentSection>
                <TimeSpentBar>
                  <TimeSpentTextSection>
                    <TimeSpentText>1h 40min</TimeSpentText>
                    <TimeSpentSubText>Current time spent*</TimeSpentSubText>
                  </TimeSpentTextSection>
                </TimeSpentBar>
                <TimeSavedBar>
                  <TimeSavedTextSection>
                    <TimeSavedText>1h 40min</TimeSavedText>
                    <TimeSavedSubText>Current time spent*</TimeSavedSubText>
                  </TimeSavedTextSection>
                  <TimeSavedProgress progress={35 + 8}>
                    <TimeSpentTextSection>
                      <TimeSpentText>35min</TimeSpentText>
                      <TimeSpentSubText>
                        Time spent using&nbsp;<ILTag>innerlogic</ILTag>
                      </TimeSpentSubText>
                    </TimeSpentTextSection>
                  </TimeSavedProgress>
                </TimeSavedBar>
              </TimeSpentSection>
              <HoursSavedBlock>
                *&nbsp;
                <HoursSavedDescription>
                  Hours Saved equation:
                  <br />
                  <br /># of hours currently spent = [Question 4 how often] x
                  [Question 6 how long] x 8 hours per day based on 40 hour work
                  week] - # hours spent using innerlogic (90% time saved) = #
                  hours saved
                </HoursSavedDescription>
              </HoursSavedBlock>
            </TimeBreakdownContainer>
          </Breakdown> */}
        </BreakdownSection>
      )}
      <NextStepsSection>
        <NextStepsHeading>Ready To Take The Next Step?</NextStepsHeading>
        <NextStepButton
          href="https://meetings.hubspot.com/kevin-duffie/innerlogic-demo"
          target="_blank"
        >
          Book a demo
        </NextStepButton>
      </NextStepsSection>
    </ScreenContainer>
  );
}

const LogoImage = styled.img``;

const StrongText = styled.b`
  font-weight: 600;
  color: #2a3039;
`;

const UrlWrapper = styled.a`
  cursor: pointer;
  color: #2c70e3;
`;

const StrongTextAstr = styled.b`
  font-weight: 600;
  color: #2a3039;
  margin-top: -3px;
  margin-right: 2px;
`;
const QuoteAndImage = styled.div`
  margin-left: 0;
  margin-right: auto;
  height: 100px;
  width: 150px;
  position: relative;

  @media screen and (min-width: 1025px) {
    height: 200px;
    width: 250px;
  }
`;

const QuoteTextWrapper = styled.div`
  position: absolute;
  max-width: 100%;
  bottom: 20px;
  margin-left: 60px;

  @media screen and (min-width: 1025px) {
    margin-left: 120px;
    bottom: 100px;
  }
`;

const QuoteText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #2a3039;
  width: 230px;

  @media screen and (min-width: 1025px) {
    width: 330px;
    font-size: 26px;
  }
`;

const AuthorText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666d79;
  line-height: 100%;
  margin-top: 15px;
`;

const MobileLegendText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  /* identical to box height, or 26px */

  font-variant: all-small-caps;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss05" on,
    "ss07" on, "ss08" on, "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on,
    "liga" off, "kern" off;

  /* Primary/500 */

  color: #2d70e2;
`;

const MobileLegendItemsContainer = styled.div`
  margin-top: 16px;
`;

const MobileLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

const TimeSpentBar = styled.div`
  background-color: #f15062;
  border-radius: 20px;
  position: relative;

  margin-bottom: 24px;
  height: 240px;
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-right: 24px;
    width: 360px;
  }
`;

const ImageItem = styled.img`
  width: 0px;
  height: 0px;
  margin-top: 0px;
  display: none;
  @media screen and (min-width: 768px) {
    width: 75px;
    height: 75px;
    display: block;
  }
`;

const QuoteImage = styled.img`
  height: 100px;
  width: 150px;

  @media screen and (min-width: 1025px) {
    height: 200px;
    width: 250px;
  }
`;

const QuoteDescription = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-top: 16px;

  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;

  @media screen and (min-width: 1025px) {
    width: 40%;
  }
`;

const QuoteReference = styled.div`
  display: flex;
  flex-direction: row;
`;

const QuoteReferenceText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const BorderedImage = styled.img`
  width: 145px;
  height: 50px;
  border-radius: 20px;
  margin-left: 50px;
  margin-right: 50px;

  @media screen and (min-width: 768px) {
    width: 150px;
    height: 55px;
    border-radius: 40px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const NextStepsSection = styled.div`
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f8faff;
  border-radius: 20px;
  margin-bottom: 60px;
  padding: 50px;
  width: 90vw;
  @media screen and (min-width: 768px) {
    padding: 60px;
  }
`;

const NextStepsHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 128%;
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  color: #2a3039;

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
`;

const NextStepButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  margin-top: 24px;
  width: 136px;
  height: 44px;
  color: #ffffff;
  background: #2d70e2;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: #ffffff;
  }
`;

const HoursSavedBlock = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 20px;
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

const LegendItemsContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: 10px;
  @media screen and (min-width: 768px) {
    flex-direction: column;
    margin-left: 0px;
  }
`;

const TimeSavedBar = styled.div`
  height: 240px;
  background-color: #eafbf7;
  border-radius: 20px;
  position: relative;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 360px;
  }
`;

const TimeSpentSection = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const TimeBreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  @media screen and (min-width: 1125px) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`;

const TimeSpentText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 128%;
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  color: #ffffff;
`;

const TimeSavedProgress = styled.div`
  height: ${(props) => props.progress}%;
  background-color: #27cda7;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 360px;
  }
`;

const TimeSpentTextSection = styled.div`
  position: absolute;
  bottom: 24px;
  left: 24px;
`;

const TimeSavedTextSection = styled.div`
  padding: 24px;
`;

const TimeSavedText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 128%;
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  color: #2a3039;
`;

const ILTag = styled.div`
  font-weight: 600;
`;

const TimeSavedSubText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #2a3039;
`;

const TimeSpentSubText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #ffffff;
  display: flex;
  flex-direction: row;
`;

const ScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 999;
  @media screen and (min-width: 768px) {
    // margin-left: 60px;
    margin-right: 40px;
    // margin-top: 0px;
  }
`;

const LogoSection = styled.div`
  display: none;
  flex-direction: row;
  height: 5vh;
  background-color: #fff;
  margin-left: 0;
  margin-right: auto;
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const TopBlock = styled.div`
  font-family: "Raleway";
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  width: 90vw;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 52px;
  padding-bottom: 52px;
  @media screen and (min-width: 768px) {
    padding: 60px;
    width: 90vw;
    align-items: center;
  }
`;

const QuoteBlock = styled.div`
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  display: flex;
  flex-direction: column;
  background: #f8faff;
  border-radius: 20px;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  width: 90vw;
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (min-width: 768px) {
    padding: 60px;
    width: 90vw;
    flex-direction: column;
  }

  @media screen and (min-width: 1025px) {
    padding: 60px;
    width: 90vw;
    flex-direction: row;
  }
`;

const HeadingText = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  line-height: 128%;
  font-size: 38px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  color: #2a3039;
  flex-wrap: wrap;

  @media screen and (min-width: 1125px) {
    font-size: 65px;
  }
`;

const BlockImageWrapper = styled.div`
  @media screen and (min-width: 768px) {
    font-size: 65px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 40px;
  }
`;

const BreakdownHeadingText = styled.div`
  font-style: normal;
  font-weight: 700;
  line-height: 128%;
  text-transform: capitalize;
  font-feature-settings: "pnum" on, "lnum" on, "ss03" on, "salt" on, "ss01" on,
    "ss02" on, "ss04" on, "ss05" on, "ss06" on, "ss07" on, "ss08" on, "ss09" on,
    "ss10" on, "ss11" on, "kern" off, "liga" off;
  color: #2d70e2;
  font-size: 20px;
  margin-bottom: 16px;

  @media screen and (min-width: 1125px) {
    font-size: 30px;
    margin-bottom: 16px;
    width: 100%;
  }
`;

const HeadingSubText = styled.div`
  display: none;
  flex-direction: row;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
  margin-top: 32px;
  flex-wrap: wrap;
  width: 300px;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    width: 460px;
    font-size: 14px;
    margin-top: 0px;
    text-align: center;
  }
`;

const BreakdownSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  @media screen and (min-width: 768px) {
    margin-top: 120px;
  }
`;

const Breakdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 50px;
  @media screen and (min-width: 768px) {
    padding-bottom: 120px;
    margin-left: 60px;
    margin-right: 60px;
  }
`;

const BreakdownInfo = styled.div`
  display: flex;
  flex-direction: column;

  width: 90vw;
  margin-bottom: 32px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 60px;
  }
`;

const BreakdownHeading = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 520px;
    flex-direction: row;
    margin-right: auto;
    margin-left: 0px;
  }
`;

const BreakdownNumber = styled.div`
  font-style: normal;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 100%;
  margin-right: 16px;
  font-variant: all-small-caps;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss05" on,
    "ss07" on, "ss08" on, "ss03" on, "ss04" on, "ss10" on, "ss11" on, "ss02" on,
    "ss06" on, "ss09" on, "liga" off;
  color: #2d70e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #ebf1fd;
  border-radius: 30px;
  font-size: 14px;
  padding: 6px 20px;
  width: 47px;
  height: 29px;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    padding: 8px 24px;
    width: 60px;
    height: 47px;
  }
`;

const BreakdownDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;
  color: #666d79;

  @media screen and (min-width: 768px) {
    width: 500px;
  }
`;

const HoursSavedDescription = styled.div`
  width: 340px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const BreakdownStats = styled.div`
  box-sizing: border-box;
  width: 90vw;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const StatBarContainer = styled.div`
  width: 100%;
  margin-left: -20px;
  margin-top: 25px;
`;

const BreakdownStat = styled.div`
  padding: 32px;

  width: ${(props) => (props.single ? "100%" : "100%")};

  @media screen and (min-width: 1368px) {
    padding: 60px;
    width: ${(props) => (props.single ? "100%" : "50%")};
  }
`;

const BreakdownStatQuestion = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
  color: #2a3039;

  @media screen and (min-width: 1368px) {
    width: 400px;
  }
`;

const SingleBreakdownStatQuestion = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
  color: #2a3039;

  @media screen and (min-width: 1368px) {
    width: 500px;
  }
`;

const StatsSection = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
    width: 75%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const LegendBox = styled.div`
  display: none;
  @media screen and (min-width: 768px) {
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
    margin-bottom: 60px;
    margin-top: 60px;
    margin-left: 40px;
  }

  @media screen and (min-width: 876px) {
    width: 100%;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: flex-start;
    margin-left: 60px;
    margin-bottom: 60px;
    margin-top: 60px;
  }
`;

const MobileLegendBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 0px;
  margin-bottom: 0px;

  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 20px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const LegendText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 128%;
  font-variant: all-small-caps;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss05" on,
    "ss07" on, "ss08" on, "ss03" on, "ss04" on, "ss10" on, "ss11" on, "ss02" on,
    "ss06" on, "ss09" on, "liga" off;

  color: #2d70e2;
  margin-bottom: 20px;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  width: 120px;

  @media screen and (min-width: 950px) {
    width: 150px;
  }

  @media screen and (min-width: 1200px) {
    width: 200px;
  }
`;

const LegendBar = styled.div`
  width: 6px;
  height: 20px;
  background-color: ${(props) => props.color};
  margin-right: 10px;
  border-radius: 20px;
`;

const LegendItemText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
`;

const LegendSection = styled.div`
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;

  @media screen and (min-width: 768px) {
    border-top: 1px solid #dfdfdf;
    width: 0;
    padding: 0px;
    border-left: 1px solid #dfdfdf;
    border-top: 0px solid #dfdfdf;
  }
`;

const MobileLegendSection = styled.div`
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export default MarketingReport;
