import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Select } from "semantic-ui-react";

const Plan = () => {
	return (
		<Container>
			<T1>Creating Urgency:</T1>
			<Area>
				<Q>
					Why is this a meaningful measure to improve? What are the
					benefits of improving in this culture area?
				</Q>
				<Response>
					Bacon ipsum dolor amet biltong boudin ground round pork
					belly strip steak tail rump kevin chicken porchetta. Jerky
					ribeye landjaeger, swine cupim bresaola beef ribs filet
					mignon t-bone sausage shoulder chuck venison. Venison corned
					beef tenderloin jowl bresaola chicken bacon biltong
					meatball. Brisket chuck swine leberkas shankle.
				</Response>
			</Area>

			<Area>
				<Q>
					What do you predict is going to happen if this doesn’t
					change? What is the cost of not improving in this area?
				</Q>
				<Response>
					Bacon ipsum dolor amet biltong boudin ground round pork
					belly strip steak tail rump kevin chicken porchetta. Jerky
					ribeye landjaeger, swine cupim bresaola beef ribs filet
					mignon t-bone sausage shoulder chuck venison. Venison corned
					beef tenderloin jowl bresaola chicken bacon biltong
					meatball. Brisket chuck swine leberkas shankle.
				</Response>
			</Area>

			<T1>Possible causes:</T1>
			<Area>
				<Q>
					What do you believe may be leading to the current level of
					performance in this area within your culture?
				</Q>
				<Response>
					Bacon ipsum dolor amet biltong boudin ground round pork
					belly strip steak tail rump kevin chicken porchetta. Jerky
					ribeye landjaeger, swine cupim bresaola beef ribs filet
					mignon t-bone sausage shoulder chuck venison. Venison corned
					beef tenderloin jowl bresaola chicken bacon biltong
					meatball. Brisket chuck swine leberkas shankle.
				</Response>
			</Area>

			<T1>Action plan:</T1>
			<Area>
				<Q>
					List up to 3 strategies or behaviors that will be
					implemented, introduced, or changed in your environment to
					improve this culture area. Also identify who will be
					responsible for leading each item (person, people, or
					groups):{" "}
				</Q>
				<Response>
					Bacon ipsum dolor amet biltong boudin ground round pork
					belly strip steak tail rump kevin chicken porchetta. Jerky
					ribeye landjaeger, swine cupim bresaola beef ribs filet
					mignon t-bone sausage shoulder chuck venison. Venison corned
					beef tenderloin jowl bresaola chicken bacon biltong
					meatball. Brisket chuck swine leberkas shankle.
				</Response>
			</Area>
		</Container>
	);
};

export default Plan;

const Container = styled.div`
	width: 50%;
	padding: 0px 40px;
`;

const T1 = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 20px;
`;

const Q = styled.div`
	font-size: 18px;
	line-height: 1.4;
	font-weight: 300;
	margin-right: 20px;
`;

const Text = styled.textarea`
	width: calc(100% - 420px);
	height: 150px;
	font-family: "Barlow", sans-serif;
	padding: 10px;
`;

const Area = styled.div`
	width: 100%;
	margin-bottom: 20px;
`;

const Question = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	margin-bottom: 30px;
	font-size: 20px;
`;

const Response = styled.div`
	padding: 20px;
	border: 1px solid lightgrey;
	border-radius: 10px;
	font-family: "Barlow", sans-serif;
	margin-top: 10px;
	font-weight: bold;
`;
