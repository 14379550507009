import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { Loader, Select } from "semantic-ui-react";

const anchor_list = [{text:'Locations',value:1}]
const anchor_list2 = [{text:'Overall',value:1},{text:'Ontario',value:2},{text:'Nova Scotia',value:2},{text:'Quebec',value:2}]

const Representation = () =>{


	return  (

        <Container>
           <OptionArea>
            <div style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
                <Label>Unit of Analysis</Label>
                <StyledSelect
                  placeholder="Select Survey Type"
                  options={anchor_list}
                  value={anchor_list[0].value}
                  id="anchors"
                />
              </div>
              <div style={{ marginLeft: 30, display: "flex", alignItems: "center" }}>
                <Label>Target</Label>
                <StyledSelect
                  placeholder="Select Survey Type"
                  options={anchor_list2}
                    value={anchor_list[0].value}
                  id="anchors"
                />
              </div>
            </OptionArea>
            <Card>
                 <Chart
                      options={data2.options}
                      series={data2.series}
                      type="line"
                      height={400}
                    />
                </Card>

        </Container>)
}



export default Representation

const Card = styled.div`
  width: calc(100% - 360px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
`;

const Container = styled.div`
  width:100%;
`

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const data2 = {
  series: [
    {
      name: "Black",
      data: [
        {
          x: 12,
          y: 12,
        },
        {
          x: 13,
          y: 11,
        },
        {
          x: 14,
          y: 8,
        },
        {
          x: 15,
          y: 6,
        },
        {
          x: 16,
          y: 6,
        },
        {
          x: 17,
          y: 5.4,
        },
        {
          x: 18,
          y: 5,
        },
        {
          x: 19,
          y: 4.8,
        }
      ],
    },
    {
      name: "White",
      data: [
        {
          x: 12,
          y: 88,
        },
        {
          x: 13,
          y: 89,
        },
        {
          x: 14,
          y: 92,
        },
        {
          x: 15,
          y: 94,
        },
        {
          x: 16,
          y: 94,
        },
        {
          x: 17,
          y: 94.6,
        },
        {
          x: 18,
          y: 95,
        },
        {
          x: 19,
          y: 96.2,
        }
      ],
    }
  ],
  options: {
    chart: {
      height: '100%',
      type: "line",
      fontFamily: "Poppins, sans-serif",
      toolbar: {
        show: false,
      },
      animations:{
        enabled:true
      }
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      borderColor: "#e7e7e7",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [5, 5],
      strokeWidth:2,
      radius:2,
       strokeColors: '#476DFA',
    },
    tooltip:{
      enabled:true
    },
    colors:['#476DFA', '#66D686', '#9C27B0'],
    xaxis: {
      type: "numeric",
      decimalsInFloat:0,
      title: {
        text: "Age",
        style:{
          fontSize:'12px',
          fontWeight:400,
          color:'#7E7E7E'
        }
      },
    },
    yaxis: {
      decimalsInFloat:0,
      title: {
        text: "Representation (%)",
        style:{
          fontSize:'12px',
          fontWeight:400,
          color:'#7E7E7E'
        }
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  },
};
