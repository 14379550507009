import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import QA from "./QA";
import ProgressBar from "./Progress";
import make_call from "./Request";

const Context = (props) => {
  const {
    setContextData,
    selectedFactor,
    step,
    handleNext,
    contextMessage,
    setContextMessage,
  } = props;
  const totalSteps = 4;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(contextMessage);
  const [history, setHistory] = useState([]);
  const [answer, setAnswer] = useState("");
  const [stepsComplete, setStepsComplete] = useState(false);

  const make_gpt_call = () => {
    setLoading(true);
    async function someFunction() {
      let _history = [...history];

      if (answer) {
        _history = [..._history, { role: "user", content: answer }];
        setAnswer("");
      }

      const resp = await make_call(_history);

      setMessage(resp);
      setContextMessage(resp);

      setHistory([..._history, { role: "assistant", content: resp }]);

      setLoading(false);
      handleNext();
    }

    someFunction();
  };

  useEffect(() => {
    if (contextMessage) {
      setMessage(contextMessage);
    }
  }, [contextMessage]);

  useEffect(() => {
    if (!message && selectedFactor?.title) {
      setMessage(
        `What unique challenges is your team or department currently experiencing right now that we should be aware of when recommending you a ${selectedFactor?.title.toLowerCase()} plan?`
      );
    }
  }, [selectedFactor]);

  const change_step = () => {
    if (step < totalSteps) {
      if (step === 1) {
        setMessage(
          `What unique challenges is your team or department currently experiencing right now that we should be aware of when recommending you a ${selectedFactor?.title.toLowerCase()} plan?`
        );
        handleNext();
      } else {
        setMessage("");
        make_gpt_call();
      }
    } else {
      setStepsComplete(true);
      handleNext();
    }
  };

  return (
    <Container>
      <Title>Create a culture plan</Title>
      {step === 1 ? (
        <Step1 />
      ) : (
        <QA
          step={step}
          loading={loading}
          answer={answer}
          setAnswer={setAnswer}
          question={message && message}
          stepsComplete={stepsComplete}
        />
      )}
      {(!stepsComplete && step < totalSteps && (
        <ButtonContainer>
          <StyledButton onClick={() => change_step()}>
            {step === 1 ? "Get Started" : "Next"}
          </StyledButton>
        </ButtonContainer>
      )) || (
        <ButtonContainer>
          <StyledButton
            onClick={() => {
              let _history = [...history];
              if (answer) {
                _history = [..._history, { role: "user", content: answer }];
                setAnswer("");
              }
              setContextData(_history);
              setStepsComplete(true);
              handleNext();
            }}
          >
            Next
          </StyledButton>
        </ButtonContainer>
      )}
      {/* <button onClick={toggleModal} style={{marginTop:40}}>Close</button> */}
    </Container>
  );
};

export default Context;

const Container = styled.div`
  margin-top: 40px;
  padding-left: 60px;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
`;

const Text = styled.div`
  font-family: "Raleway";
  font-size: 16px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const StyledButton = styled.button`
  background-color: #ebf1fd;
  color: #2d70e2;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  width: auto;
  margin-left: 10px;
`;

const ProgressContainer = styled.div`
  width: 60%;
  position: absolute;
  bottom: 40px;
  left: 30px;
`;

const Step1 = () => {
  return (
    <>
      <Text>
        We're going to ask you a few questions to help create the most
        personalized plan for you.
      </Text>
    </>
  );
};
