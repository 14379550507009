import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_ORGANIZATIONS,
    LOAD_ORGANIZATIONS,
    DELETE_ORGANIZATION,
    REMOVE_ORGANIZATION,
    PULL_ORGANIZATION,
    FETCH_ORGANIZATION,
    UPDATE_ORGANIZATION,
    EDIT_ORGANIZATION,
    SAVE_ORGANIZATION,
    ADD_ORGANIZATION,
    UPDATE_ORGANIZATION_LOGO,
    EDIT_ORGANIZATION_LOGO,
    CLEAR_ORGANIZATION,
    VALIDATE_ORGANIZATION,
    SG_VALIDATE_ORGANIZATION,
    ALL_ERRORS,
} from "constants/actions";



function* loadorganizations(action) {
//   yield put({ type: GET_organizationS_PENDING });
  // console.log('action.payload', action.payload)
  try {
    const json = yield call(api.GET_ORGANIZATIONS, action.payload);
    yield put({ type: GET_ORGANIZATIONS, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationLoad() {
  yield takeLatest(LOAD_ORGANIZATIONS, loadorganizations);
}

function* fetchorganizations(action) {
  try {
    const json = yield call(api.FETCH_ORGANIZATION, action.payload);
    yield put({ type: FETCH_ORGANIZATION, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationFetch() {
  yield takeLatest(PULL_ORGANIZATION, fetchorganizations);
}


function* updateorganizations(action) {
  try {
    const json = yield call(api.EDIT_ORGANIZATION, action.payload);
    yield put({ type: EDIT_ORGANIZATION, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationUpdate() {
  yield takeLatest(UPDATE_ORGANIZATION, updateorganizations);
}


function* updateorganizationlogo(action) {
  try {
    const json = yield call(api.ADD_ORGANIZATION_LOGO, action.payload);
    yield put({ type: EDIT_ORGANIZATION_LOGO, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationlogoUpdate() {
  yield takeLatest(UPDATE_ORGANIZATION_LOGO, updateorganizationlogo);
}

function* addorganizations(action) {
  try {
    const json = yield call(api.ADD_ORGANIZATION, action.payload);
    yield put({ type: ADD_ORGANIZATION, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationAdd() {
  yield takeLatest(SAVE_ORGANIZATION, addorganizations);
}

function* deleteorganizations(action) {
  try {
    const json = yield call(api.DELETE_ORGANIZATION, action.payload);
    yield put({ type: DELETE_ORGANIZATION, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* organizationDelete() {
  yield takeLatest(REMOVE_ORGANIZATION, deleteorganizations);
}

function* validateorganizations(action) {
    try {
      const json = yield call(api.VALIDATE_ORGANIZATION, action.payload);
      yield put({ type: VALIDATE_ORGANIZATION, payload: json.data });
  
    } catch (e) {
      yield put({ type: ALL_ERRORS, payload: e.response });
    }
  }
  
  export function* organizationValidate() {
    yield takeLatest(SG_VALIDATE_ORGANIZATION, validateorganizations);
  }

export default function* index() {
  yield all([
    organizationLoad(),
    organizationFetch(),
    organizationUpdate(),
    organizationlogoUpdate(),
    organizationAdd(),
    organizationDelete(),
    organizationValidate()
  ]);
}
