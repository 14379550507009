import React from "react";
import styled, { keyframes } from "styled-components";

const FactorExplanation = (props) => {
  const { calculatedData } = props;
  return (
    <Container>
      <T2>{calculatedData.title}</T2>

      <T3 delay={0.3}>{calculatedData.content.t2}</T3>
      <Description delay={1}>{calculatedData.content.p}</Description>

      <SecondDescription delay={1}>
        {calculatedData.content.t1}
      </SecondDescription>

      <ul>
        {calculatedData.content.li.map((item, index) => {
          // split the item into 2 new variables, the first will be the preceeding title ex: Flexibility:
          // the second will be the description of the title
          // then return the title and description in the LI component
          const title = item.split(":")[0];
          const description = item.split(":")[1];
          return (
            <LI key={index} delay={1}>
              <strong>{title}:&nbsp;</strong> {description}
            </LI>
          );
        })}
      </ul>
    </Container>
  );
};

const fadeIn = keyframes`
      from {
          opacity: 0;
          transform: translateY(-20px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  `;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
`;

const T1 = styled.div`
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 70px;
  text-align: center;
  line-height: 1.4;
`;

const T2 = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.4;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const T3 = styled.div`
  font-size: 14px;
  font-weight: bold;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Description = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SecondDescription = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const D2 = styled.div`
  margin-bottom: 20px;
`;

const IMG = styled.img`
  width: 100%;
  margin-bottom: 40px;
`;

const LI = styled.li`
  margin-bottom: 10px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

export default FactorExplanation;
