import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Checkbox } from 'semantic-ui-react';

const ReportSelect = ({next}) =>{

    return (
        <Container>
            <T1>Select the report target</T1>
            <Description>
                Please select a target for your report. You'll have the opportunity to select additional options in the next step.
            </Description>


            <Option title="Entire Organization"/>
            <SelectArea>
            <Option title="Location"/>
            <Option title="Department"/>
            <Option title="Team"/>
            <Option title="Role"/>

            </SelectArea>       

            <ButtonContainer>
                   
                   <Button onClick={()=>next()}>Next</Button>
               </ButtonContainer>
        </Container>
    )

}

export default ReportSelect

const Option = ({title}) =>{
    const [active,setActive] = useState(false)

    return (
        <OptionContainer active={active}>
            <Checkbox 
            checked={active}
            onChange={()=>setActive(!active)}
            style={{marginRight:10}}/> {title}
        </OptionContainer>
    )


}


const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`

`

const T1 = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:5px;
    font-family:'Raleway';
    color:#2A3039;
    line-height:1.5;
    margin-top:20px;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    letter-spacing:-0.35px;
`

const Description = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:20px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 0.5s;
`

const SelectArea = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin-top:20px;
    width:100%;
    justify-content:space-between;
`

const OptionContainer = styled.div`
    width:calc(33% - 5px);
    display:flex;
    align-items:center;
    justify-content:flex-start;
    font-family:'Raleway';
    font-size:14px;
    font-weight:600;
    border:1px solid #E0E0E0;
    border-radius:5px;
    padding:10px;
    margin-bottom:5px;

    background-color:${({active})=>active?'#D5E2F9':'#fff'};
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;
`


const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`

const Button = styled.div`
    color:#2D70E2;
    border:1px solid #2D70E2;
    font-size:16px;
    padding:10px 20px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`