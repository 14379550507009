import React from "react";
import { View, Text, Image, StyleSheet, Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import format from "date-fns/format";

import coverImage from "assets/images/otp/Logo.png";
import statsContainer from "../../assets/stats-container.png";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

function formatDate(dateString) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const event = new Date(dateString);
  return event.toLocaleDateString('en-US', options);
}

const Intro = (props) => {
  const { completionRate, surveyDate } = props;
  let imgCover;
  let coverTitle;
  let subtitle;
  let templateType = 1;
  let orgSuffix;
  const lang = props.isFrench ? "fr" : "en";

  templateType = 1;
  imgCover = null;
  subtitle = getTranslatedText("coverSubTitle", lang, translations);
  coverTitle =
   props?.coverTitle || getTranslatedText("coverTitle", lang, translations);
  if (props.selectedReport) {
   orgSuffix = `/ ${props.selectedReport.name}`;
  }
  

  if (props.overview) {
   orgSuffix =
    props.anchor.name.toLowerCase() === "sport"
     ? getTranslatedText("allSports", lang, translations)
     : getTranslatedText("avgAllSports", lang, translations);
  }

  const styles = StyleSheet.create({
   page: {
    position: "relative",
    height: "100vh",
    backgroundColor: "white",
   },
   textarea: {
    position: "absolute",
    top: "6%",
    left: 80,
    color: "black",
   },
   title: {
    fontWeight: 700,
    fontSize: 30,
    width: 350,
    lineHeight: 1.2,
    paddingTop: 40,
   },
   subTitle: {
    fontSize: 13,
    marginTop: 10,
    width: 350,
    lineHeight: 1.2,
   },
   sportName: {
    fontSize: 15,
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 150,
   },
   bottom: {
    width: "100vw",
    height: 150,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "flex-end",
    position: "absolute",
    bottom: 0,
   },
   bar: {
    width: 7,
    borderRadius: 2.5,
   },
   sub1: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 15,
    marginBottom: 170,
   },
   img: {
    width: 150,
    marginLeft: -10,
    marginBottom: 55,
   },
  });

  const bars = [
   0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
   22, 23, 24, 25, 26, 27, 28,
  ];
  const colors = ["#E6B53C", "#B37814", "#B7BBC4"];

  const getReportDetails = (reportData, orgName) => {
   const detailsObj = [];

   // Organization Name Details Stat
   if (orgName) {
    detailsObj.push(
     <View style={styles.statsWrapper} key={"org-name"}>
      <View style={styles.textFormatter}>
       <Text style={styles.statText}>
        {getTranslatedText("organizationName", lang, translations)}:{" "}
       </Text>
       <Text style={styles.valueText}>
        {orgName} {orgSuffix}
       </Text>
      </View>
     </View>
    );
   }

   // Report Type Details Stat
   detailsObj.push(
    <View style={styles.statsWrapper} key={"report-type"}>
     <View style={styles.textFormatter}>
      <Text style={styles.statText}>
       {getTranslatedText("reportType", lang, translations)}:{" "}
      </Text>
      {/* Get value from prop when more audits are added. */}
      <Text style={styles.valueText}>
       {reportData?.type_of ||
        getTranslatedText("cultureAudit", lang, translations)}
      </Text>
     </View>
    </View>
   );

   // Responses Details Stat
   if (reportData?.responses) {
    detailsObj.push(
     <View style={styles.statsWrapper} key={"num-respondents"}>
      <View style={styles.textFormatter}>
       <Text style={styles.statText}>
        {getTranslatedText("numRespondants", lang, translations)}:{" "}
       </Text>
       <Text style={styles.valueText}>{props.totalResponses}</Text>
      </View>
     </View>
    );
   }

   // Date Details Stat
   if (reportData?.date) {
    detailsObj.push(
     <View style={styles.statsWrapper} key={"close-date"}>
      <View style={styles.textFormatter}>
       <Text style={styles.statText}>
        {getTranslatedText("surveyClose", lang, translations)}:{" "}
       </Text>
       <Text style={styles.valueText}>{formatReportDate(reportData.date)}</Text>
      </View>
     </View>
    );
   }

   return detailsObj;
  };

  const formatReportDate = (dateTime) => {
   return format(dateTime, "MMMM do, yyyy");
  };

  const introImg = (imgSrc, header, subtitle) => {
   return (
    <View>
     <View>
      <Image
       src={(imgSrc && { uri: imgSrc }) || coverImage}
       cache={true}
       style={{ width: 760, objectFit: "cover" }}
      />
     </View>
     {header && (
      <View style={styles.textWrapper}>
       <Text style={[styles.header, { color: props.titleColor }]}>
        {props?.coverTitle ||
         getTranslatedText("introHeader", lang, translations)}
       </Text>
       <Text style={styles.subTitle}>{subtitle}</Text>
      </View>
     )}
     <View style={styles.statsSection}>
      {(templateType === 1 && (
       <View style={styles.statsContainer}>
        <View>
         <Image source={statsContainer} fixed />
        </View>
        <View style={{ position: "absolute", left: 45, top: 25 }}>
         {getReportDetails(props.selectedReport, props.selectedOrg)}
        </View>
       </View>
      )) || (
       // Placeholder for stats container.
       <View
        style={[
         styles.statsContainer,
         {
          backgroundColor: "rgb(65,65,65)",
         },
        ]}
       >
        {getReportDetails(props.selectedReport, props.selectedOrg)}
       </View>
      )}
     </View>
    </View>
   );
  };

  const introReport =
    templateType === 2 ? (
      <View style={styles.introWrapper}>
        {introImg(imgCover, coverTitle, subtitle)}
      </View>
    ) : (
      <View style={styles.page}>
        <View style={styles.textarea}>
          <Image src={coverImage} style={styles.img} />
          <Text style={styles.title}>
            {props?.coverTitle ||
              getTranslatedText("introHeader", lang, translations)}
          </Text>
          <Text
            style={[styles.sub1, { marginBottom: props.sportName ? 10 : 170 }]}
          >
            {getTranslatedText("overviewKeyFindings", lang, translations)}
          </Text>
          {props.sportName && (
            <Text style={styles.sportName}>
              {(props.selectedSport && props.sportName) || "Culture Audit"}
            </Text>
          )}
          <Text style={styles.subTitle}>
            {getTranslatedText("sentMsg", lang, translations)} {formatDate(surveyDate)}
          </Text>

          <Text style={styles.subTitle}>
          {getTranslatedText("responseRate", lang, translations)} {completionRate.totalResponses} / {completionRate.totalRequests}
          </Text>
          <Text style={styles.subTitle}>
            
            {getTranslatedText("generatedMsg", lang, translations)}{" "}
            {(props.selectedSport && props.isOTPView && props.sportName) ||
              "OTP"}{" "}
            {getTranslatedText("on", lang, translations)}&nbsp;
            {formatReportDate(new Date())}
          </Text>

          
        </View>

        <View style={styles.bottom}>
          {bars.map((item) => (
            <View
              style={[
                styles.bar,
                {
                  height: 50 + (100 * item) / 28 + Math.random() * 100,
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                },
              ]}
            />
          ))}
        </View>
      </View>
    );

  return (
    <Page>
      <View>{introReport}</View>
    </Page>
  );
};

Intro.propTypes = {
  companyLogo: PropTypes.string,
  introTemplate: PropTypes.number,
  coverImage: PropTypes.string,
  coverHeading: PropTypes.string,
  coverSubtitle: PropTypes.string,
  metadata: PropTypes.object,
};

export default Intro;
