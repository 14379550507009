import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 8,
    paddingBottom: 8,
  },
  link: {
    fontFamily: "Raleway",
    fontWeight: 400,
    fontSize: 12,
    color: "#2d70e2",
    textAlign: "left",
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 400,
    fontSize: 12,
    textAlign: "left",
  },
});

const ResourceLink = (props) => {
  const { links } = props;
  return (
    <View style={styles.titleWrapper}>
      <Text style={styles.title}>Resources:</Text>
      {links?.map((link) => (
        <Text style={styles.link}>{link}</Text>
      ))}
    </View>
  );
};

export default ResourceLink;
