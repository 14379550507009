import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Heading, Paragraph, Button, FlexRow, Spacer } from './styles/Common';
import SlackConnectView from './SlackConnectView';
import UserSyncPreview from './UserSyncPreview';
import UserSyncLoading from './UserSyncLoading';
import UserSyncComplete from './UserSyncComplete';
import SlackAppSettings from './SlackAppSettings';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

// States remain the same
const STATES = {
  NOT_CONNECTED: 'NOT_CONNECTED',
  CONNECTED: 'CONNECTED',
  PREVIEW: 'PREVIEW',
  SYNCING: 'SYNCING',
  COMPLETE: 'COMPLETE',
  SETTINGS: 'SETTINGS',
};

const SlackIntegrationManager = ({ demoMode = false }) => {
  const [state, setState] = useState(STATES.NOT_CONNECTED);
  const [slackToken, setSlackToken] = useState(null);
  const [slackUsers, setSlackUsers] = useState([]);
  const [internalUsers, setInternalUsers] = useState([]);
  const [mappedUsers, setMappedUsers] = useState([]);
  const [selectedMappings, setSelectedMappings] = useState([]);
  const [syncedUsers, setSyncedUsers] = useState([]); // This will store synced user accounts

  useEffect(() => {
    if (!demoMode) {
      // Real API: Check connection status
      async function checkConnection() {
        const { connected, token } = await fetch('/api/slack/connection-status')
          .then(res => res.json());
        if (connected && token) {
          setSlackToken(token);
          setState(STATES.CONNECTED);
        }
      }
      checkConnection();
    } 
    // In demo mode, do nothing.
  }, [demoMode]);

  useEffect(() => {
    if (state === STATES.CONNECTED) {
      if (!demoMode) {
        // Real fetch
        Promise.all([
          fetch('/api/slack/users').then(r => r.json()),
          fetch('/api/internal/users').then(r => r.json())
        ]).then(([slackData, internalData]) => {
          setSlackUsers(slackData);
          setInternalUsers(internalData);
          return fetch('/api/slack/auto-map', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ slackUsers: slackData, internalUsers: internalData })
          })
          .then(r => r.json());
        }).then(autoMapped => {
          setMappedUsers(autoMapped);
          setSelectedMappings(autoMapped);
          setState(STATES.PREVIEW);
        });
      } else {
        // Demo Mode: Mock data
        const mockSlackUsers = [
          {id: 'S1', name: 'slack_user_1'},
          {id: 'S2', name: 'slack_user_2'},
          {id: 'S3', name: 'slack_user_3'}
        ];
        const mockInternalUsers = [
          {id: 'I1', name: 'internal_user_1'},
          {id: 'I2', name: 'internal_user_2'},
          {id: 'I3', name: 'internal_user_3'}
        ];
        setSlackUsers(mockSlackUsers);
        setInternalUsers(mockInternalUsers);
        
        const mockMappings = mockSlackUsers.map((su, i) => ({
          slackUser: su,
          internalUser: mockInternalUsers[i] || null
        }));
        
        setTimeout(() => {
          setMappedUsers(mockMappings);
          setSelectedMappings(mockMappings);
          setState(STATES.PREVIEW);
        }, 1000);
      }
    }
  }, [state, demoMode]);

  const handleSlackConnect = (token) => {
    setSlackToken(token);
    setState(STATES.CONNECTED);
  };

  const handleSync = async (finalMappings) => {
    setState(STATES.SYNCING);
    if (!demoMode) {
      await fetch('/api/slack/sync', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ mappings: finalMappings })
      }).then(() => {
        // After sync, we can fetch the synced users list
        return fetch('/api/slack/synced-users').then(r => r.json());
      }).then(synced => {
        setSyncedUsers(synced);
        setState(STATES.COMPLETE);
      });
    } else {
      // Demo mock delay and mock synced users
      setTimeout(() => {
        const mockSynced = selectedMappings.map(m => ({
          id: m.internalUser?.id || m.slackUser.id,
          slackName: m.slackUser.name,
          internalName: m.internalUser?.name || 'Unmapped Internal User'
        }));
        setSyncedUsers(mockSynced);
        setState(STATES.COMPLETE);
      }, 1500);
    }
  };

  const handleGoToSettings = () => {
    setState(STATES.SETTINGS);
  };

  const handleRemoveSlackApp = async () => {
    if (!demoMode) {
      await fetch('/api/slack/remove-app', { method: 'POST' });
    }
    setSlackToken(null);
    setSlackUsers([]);
    setInternalUsers([]);
    setMappedUsers([]);
    setSelectedMappings([]);
    setSyncedUsers([]);
    setState(STATES.NOT_CONNECTED);
  };

  const handleReSync = () => {
    // Potentially re-fetch and re-run preview steps.
    setState(STATES.CONNECTED);
  };

  // Handle removing a synced user from the list
  const handleRemoveSyncedUser = async (userId) => {
    if (!demoMode) {
      await fetch('/api/slack/remove-synced-user', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ userId })
      });
    }
    setSyncedUsers(prev => prev.filter(u => u.id !== userId));
  };

  return (
    <Container>
      {state === STATES.NOT_CONNECTED && (
        <Card>
          <Heading>Slack Integration</Heading>
          <Paragraph>Connect your organization’s Slack workspace to seamlessly sync and manage users.</Paragraph>
          {demoMode ? (
            <Button primary onClick={() => handleSlackConnect('demo-slack-token')}>Fake Connect to Slack (Demo)</Button>
          ) : (
            <SlackConnectView onConnected={handleSlackConnect} />
          )}
        </Card>
      )}
      {state === STATES.PREVIEW && (
        <UserSyncPreview
          slackUsers={slackUsers}
          internalUsers={internalUsers}
          mappings={selectedMappings}
          onChangeMappings={setSelectedMappings}
          onConfirm={() => handleSync(selectedMappings)}
        />
      )}
      {state === STATES.SYNCING && (
        <UserSyncLoading />
      )}
      {state === STATES.COMPLETE && (
        <UserSyncComplete onManage={() => handleGoToSettings()} />
      )}
      {state === STATES.SETTINGS && (
        <SlackAppSettings
          onRemove={handleRemoveSlackApp}
          onReSync={handleReSync}
          demoMode={demoMode}
          syncedUsers={syncedUsers}
          onRemoveSyncedUser={handleRemoveSyncedUser}
        />
      )}
    </Container>
  );
};

export default SlackIntegrationManager;

