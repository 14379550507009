import {
  GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  GET_DEBRIEF_PDF_REPORT,
  FETCH_DEBRIEF_PDF_REPORT,
  CREATE_DEBRIEF_PDF_REPORT,
  DELETE_DEBRIEF_PDF_REPORT,
  // UPDATE_DEBRIEF_PDF_REPORT,
  CLEAR_DEBRIEF_PDF_REPORT,
  GET_DEBRIEF_PDF_REPORT_URL,
  DELETE_DEBRIEF_PDF_REPORT_ITEM,
  CLEAR_DEBRIEF_PDF_REPORT_URL,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  debrief_pdf_report: [],
  fetched: false,
  templates: [],
  created: false,
  pending: false,
  templates_fetched: false,
  download_url: false,
};

export default function ReactPdfReport(state = initialState, action) {
  switch (action.type) {
    case GET_DEBRIEF_PDF_REPORT_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
        templates_fetched: true,
      };

    case GET_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        fetched: true,
        debrief_pdf_report: action.payload,
      };

    case FETCH_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    // case UPDATE_DEBRIEF_PDF_REPORT:
    //   return {
    //     ...state,
    //     [action.payload.id]: action.payload,
    //   };

    case CREATE_DEBRIEF_PDF_REPORT:
      return {
        ...state,
        created: action.payload,
        debrief_pdf_report: [...state.debrief_pdf_report, action.payload],
        download_url: false,
      };

    case CLEAR_DEBRIEF_PDF_REPORT:
      // Sets the created to false
      return {
        ...state,
        created: false,
      };

    case CLEAR_DEBRIEF_PDF_REPORT_URL:
      return {
        ...state,
        download_url: false,
      };

    case DELETE_DEBRIEF_PDF_REPORT:
      const newResponse = state.debrief_pdf_report.filter(
        (response) => response.id !== action.payload
      );
      return {
        ...state,
        debrief_pdf_report: newResponse,
      };

    case DELETE_DEBRIEF_PDF_REPORT_ITEM:
      // first find the report group using the id
      const report_group = state.debrief_pdf_report.find(
        (item) => item.id === action.payload?.id
      );

      // then remove the report from the groups download_list using the delete_share_id
      const newDownloadList = report_group.download_list.filter(
        (item) => item.id !== action.payload?.delete_share_id
      );

      // then update the report group with the new download_list
      const updatedReportGroup = {
        ...report_group,
        download_list: newDownloadList,
      };

      // then update the state with the updated report group
      return {
        ...state,
        debrief_pdf_report: state.debrief_pdf_report.map((item) =>
          item.id === action.payload?.id ? updatedReportGroup : item
        ),
      };

    case GET_DEBRIEF_PDF_REPORT_URL:
      return {
        ...state,
        download_url: action.payload,
      };

    case CLEAR_ALL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
