import React, { useState } from 'react';

import TeamMembers from "./Member/index"
import Teams from "./team/index"

const TeamHome = () => {
    // this value is set in TeamCard
    // team/index => list => card
    const [selectedTeam, setSelectedTeam] = useState(false)
    console.log(selectedTeam)
    return (
        <>
        {selectedTeam ? 
        <TeamMembers selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam} />
        :
        <Teams  setSelectedTeam={setSelectedTeam} />
        }
            

            
        </>
    );
};

export default TeamHome;