import React from "react";
import styled from "styled-components";

const ResourceLink = (props) => {
  const { links } = props;

  return (
    <LinkWrapper>
      <Title>Resources:</Title>
      {links.map((link) => (
        <Link href={link.url}>{link.title}</Link>
      ))}
    </LinkWrapper>
  );
};

export default ResourceLink;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Link = styled.a`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;

const Title = styled.div`
  font-family: Raleway;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
  padding-bottom: 10px;
`;
