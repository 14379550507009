import styled, { keyframes } from "styled-components";
import React from "react";
import Theme from './Theme'



const ThemeTimeline = () =>{

	return (
		<Container>
			<Title>Key Themes</Title>
				<Description>The team cluster analysis looks for trends in the team intelligence survey responses and identifies clusters of similarity within your team. These patterns are then analyzed for the most meaningful traits which make these team members similar in how they responded to their survey.</Description>
			<Theme score={86}/>
		    <Theme score={14}/>
		    <Theme score={14}/>


		</Container>
		)
}


export default ThemeTimeline


const Container = styled.div`
	margin-top:20px;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:40px;
	width:calc(50% - 20px);
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:16px;
	font-weight:bold;
`
const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
`