import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_DATA_STORY_LOGS,
  SG_GET_DATA_STORY_LOGS,
  ADD_DATA_STORY_LOG,
  SG_ADD_DATA_STORY_LOG,
  DELETE_DATA_STORY_LOG,
  SG_DELETE_DATA_STORY_LOG,
  FETCH_DATA_STORY_LOG,
  SG_FETCH_DATA_STORY_LOG,
  CLEAR_DATA_STORY_LOG,
} from "constants/actions";

function* getDataStoryLogs(action) {
  try {
    const json = yield call(api.GET_DATA_STORY_LOGS, action.payload);
    yield put({ type: GET_DATA_STORY_LOGS, payload: json.data });
  } catch (e) {
    yield put({ type: CLEAR_DATA_STORY_LOG });
  }
}

export function* loadDataStoryLogs() {
  yield takeLatest(SG_GET_DATA_STORY_LOGS, getDataStoryLogs);
}

function* addDataStoryLog(action) {
  try {
    const json = yield call(api.ADD_DATA_STORY_LOG, action.payload);
    yield put({ type: ADD_DATA_STORY_LOG, payload: json.data });
  } catch (e) {
    yield put({ type: CLEAR_DATA_STORY_LOG });
  }
}

export function* loadAddDataStoryLog() {
  yield takeLatest(SG_ADD_DATA_STORY_LOG, addDataStoryLog);
}

function* fetchDataStoryLog(action) {
  try {
    const json = yield call(api.GET_DATA_STORY_LOGS, action.payload);
    yield put({ type: FETCH_DATA_STORY_LOG, payload: json.data });
  } catch (e) {
    yield put({ type: CLEAR_DATA_STORY_LOG });
  }
}

export function* loadFetchDataStoryLog() {
  yield takeLatest(SG_FETCH_DATA_STORY_LOG, fetchDataStoryLog);
}

export default function* index() {
  yield all([
    loadDataStoryLogs(),
    loadAddDataStoryLog(),
    loadFetchDataStoryLog(),
  ]);
}
