import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import {Button} from 'semantic-ui-react'


const ScoreModal = ({recommendation,setStep,scores,setScores}) => {

    
    
      const handleScoreChange = (category, score) => {
        setScores({ ...scores, [category]: score });
      };

      console.log(recommendation)

    return (
        <Container>
            <Left>
                <FormatData jsonString={recommendation}/>

            </Left>

            <Right>
                <Title>Scoring Parameters for the following recommendation</Title>

                <Label>
                    Give some feedback on the quality of this recommendation. Why is it good or bad? How would you improve it?
                </Label>
                <TextArea
                    value={scores.feedback}
                    onChange={(e) => handleScoreChange('feedback', e.target.value)}
                    />

                <Question>
                    Please score the following recommendation on a scale of 1-5. 1 being the lowest and 5 being the highest.
                </Question>

                <Label>
                    Accuracy : {scores.accuracy}
                </Label>
                <Input 
                    type="range" 
                    min="1" 
                    max="5" 
                    value={scores.accuracy} 
                    onChange={(e) => handleScoreChange('accuracy', e.target.value)} 
                    />

                <Label>
                    Relevance : {scores.relevance}
                </Label>
                <Input 
                    type="range" 
                    min="1" 
                    max="5" 
                    value={scores.relevance} 
                    onChange={(e) => handleScoreChange('relevance', e.target.value)}
                    />

                <Label>
                    Quality : {scores.quality}
                </Label>
                <Input 
                    type="range" 
                    min="1" 
                    max="5" 
                    value={scores.quality} 
                    onChange={(e) => handleScoreChange('quality', e.target.value)}
                    />

                <Label>
                Depth: {scores.depth}
                </Label>
                <Input 
                    type="range" 
                    min="1" 
                    max="5" 
                    value={scores.depth} 
                    onChange={(e) => handleScoreChange('depth', e.target.value)}
                    />

                    <Button onClick={()=>setStep()}>Save</Button>
            </Right>
            
        </Container>
    )

}


export default ScoreModal;

const Container = styled.div`
    display:flex;
    width:100%;
    padding-left:60px;
    margin-top:30px;
`

const Left = styled.div`
    width: 50%;
    padding:0px 30px;
`

const Right = styled.div`
    width: 50%;
    padding-left:30px;
    padding-right:30px;
`

const Title = styled.div`
    font-family:'Raleway';
    font-size:18px;
    font-weight:bold;
    margin-bottom:20px;
`

const Label = styled.div`

`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:14px;
    font-weight:400;
    line-height:1.5;
    margin-bottom:40px;
`

const Input = styled.input`
    width:100%;
    margin-bottom:20px;
`

const Question = styled.div`
    font-family:'Raleway';
    font-size:14px;
    margin-bottom:20px;
`

const FormatData = ({ jsonString }) => {
    let dataArray;
    try {
      dataArray = JSON.parse(jsonString);
    } catch (error) {
      return <p>Error parsing JSON data: {error.message}</p>;
    }
  
    const renderElements = (items) => {
      const elements = [];
      let keyCounter = 0;
  
      items.forEach((item) => {
        switch (item.type) {
          case 't1':
            elements.push(<h1 key={keyCounter++}>{item.text}</h1>);
            break;
          case 't2':
            elements.push(<h2 key={keyCounter++}>{item.text}</h2>);
            break;
          case 'p':
            elements.push(<p key={keyCounter++}>{item.text}</p>);
            break;
          case 'b':
            // If the previous element is not a list, start a new list
            if (!elements.length || elements[elements.length - 1].type !== 'ul') {
              elements.push(
                <ul key={keyCounter++}>{[
                  <li key={keyCounter++}>{item.text}</li>
                ]}</ul>
              );
            } else {
              // If the previous element is a list, add the bullet point to it
              let lastElement = elements.pop();
              lastElement = React.cloneElement(lastElement, {
                children: [...lastElement.props.children, <li key={keyCounter++}>{item.text}</li>]
              });
              elements.push(lastElement);
            }
            break;
          default:
            break;
        }
      });
  
      return elements;
    };
  
    return <div>{renderElements(dataArray)}</div>;
  };