import React, { useState, useEffect } from 'react';
import { Card, Heading, Paragraph, Button, Spacer, Table } from './styles/Common';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`;

const Label = styled.label`
  margin-left: ${(props) => props.theme.spacing(1)};
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) => props.theme.colors.text};
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  input {
    opacity: 0; 
    width: 0; 
    height: 0;
  }
  
  span {
    position: absolute;
    cursor: pointer;
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: ${(props) => props.theme.colors.primary};
  }

  input:checked + span:before {
    transform: translateX(20px);
  }
`;

const ChannelToggle = styled.input.attrs({ type: 'checkbox' })``;

const SlackAppSettings = ({ onRemove, onReSync, demoMode = false, syncedUsers = [], onRemoveSyncedUser }) => {
  const [useChannels, setUseChannels] = useState(false);
  const [channels, setChannels] = useState([]); 
  const [selectedChannelIds, setSelectedChannelIds] = useState([]);

  // Fetch channel list when useChannels is turned on
  useEffect(() => {
    if (useChannels) {
      if (!demoMode) {
        fetch('/api/slack/channels')
          .then(res => res.json())
          .then(data => {
            setChannels(data);
            const initiallySelected = data.filter(c => c.selected).map(c => c.id);
            setSelectedChannelIds(initiallySelected);
          });
      } else {
        // Demo data
        const mockChannels = [
          { id: 'C1', name: '#general', selected: true },
          { id: 'C2', name: '#random', selected: false },
          { id: 'C3', name: '#engineering', selected: false },
          { id: 'C4', name: '#marketing', selected: false },
        ];
        setTimeout(() => {
          setChannels(mockChannels);
          setSelectedChannelIds(mockChannels.filter(c => c.selected).map(c => c.id));
        }, 500);
      }
    } else {
      // reset if turned off
      setChannels([]);
      setSelectedChannelIds([]);
    }
  }, [useChannels, demoMode]);

  const handleChannelToggle = (channelId) => {
    setSelectedChannelIds((prev) => 
      prev.includes(channelId) 
        ? prev.filter(id => id !== channelId) 
        : [...prev, channelId]
    );
  };

  return (
    <Card>
      <Heading>Slack App Settings</Heading>
      <Paragraph>Manage your Slack integration from here. You can re-run the user sync if your user lists have changed, or remove the Slack application entirely.</Paragraph>
      <Spacer height="20px" />

      <Button onClick={onReSync} style={{marginRight: '10px'}}>Re-run User Sync</Button>
      <Button style={{background: '#FFF', color: '#DC3545', borderColor: '#DC3545'}} onClick={onRemove}>
        Remove Slack App
      </Button>

      <Spacer height="40px" />
      <Heading>Data Source Settings</Heading>
      <Paragraph>Include Slack channels as data sources for your analytics.</Paragraph>

      <ToggleContainer>
        <Switch>
          <input
            type="checkbox"
            checked={useChannels}
            onChange={() => setUseChannels(!useChannels)}
          />
          <span></span>
        </Switch>
        <Label>Use Slack channels as data source</Label>
      </ToggleContainer>

      {useChannels && (
        <>
          <Paragraph>Select which channels to use as data sources:</Paragraph>
          <Table>
            <thead>
              <tr>
                <th>Channel</th>
                <th>Use as Data Source</th>
              </tr>
            </thead>
            <tbody>
              {channels.map(channel => (
                <tr key={channel.id}>
                  <td>{channel.name}</td>
                  <td>
                    <ChannelToggle
                      checked={selectedChannelIds.includes(channel.id)}
                      onChange={() => handleChannelToggle(channel.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <Spacer height="40px" />
      <Heading>Synced User Accounts</Heading>
      {syncedUsers.length === 0 ? (
        <Paragraph>No user accounts are currently synced with Slack.</Paragraph>
      ) : (
        <>
          <Paragraph>These users are currently synchronized between Slack and your internal system. You can remove synced users if needed.</Paragraph>
          <Table>
            <thead>
              <tr>
                <th>Slack Name</th>
                <th>Internal Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {syncedUsers.map(user => (
                <tr key={user.id}>
                  <td>{user.slackName}</td>
                  <td>{user.internalName}</td>
                  <td>
                    <Button 
                      style={{background: '#FFF', color: '#DC3545', borderColor: '#DC3545'}}
                      onClick={() => onRemoveSyncedUser(user.id)}>
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Card>
  );
};

export default SlackAppSettings;
