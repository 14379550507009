import styled from "styled-components";
import React, { useEffect, useState } from "react";

import Screen from "assets/images/empty/empty.png";

const Empty = ({language_hook}) => {
	return (
		<Container>
			<Image src={Screen} />
			<Text>{language_hook?.fetch_words('No data yet')}</Text>
		</Container>
	);
};

export default Empty;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Image = styled.img`
	height: 200px;
	margin-top: 50px;
`;

const Text = styled.div`
	font-size: 34px;
	font-weight: bold;
	font-family: "Barlow Semi Condensed", sans-serif;
	margin-top: 20px;
`;
