import { FETCH_FAILURE, ERROR, CLEAR_ERROR } from "../constants/actions";

const initialState = {
  error: null,
  fetch_error: null,
};

export default function error(state = initialState, action) {
  switch (action.type) {
    case ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        fetch_error: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        fetch_error: null,
      };
    default:
      return state;
  }
}
