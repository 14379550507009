import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  ADD_SCHEDULE_TEMPLATE,
  SG_ADD_SCHEDULE_TEMPLATE,
  DELETE_SCHEDULE_TEMPLATE,
  SG_DELETE_SCHEDULE_TEMPLATE,
  UPDATE_SCHEDULE_TEMPLATE,
  SG_UPDATE_SCHEDULE_TEMPLATE,
  SG_GET_PENDING_SHEDULE_REPORTS,
  GET_PENDING_SHEDULE_REPORTS,
  ALL_ERRORS,
} from "constants/actions";

function* getPendingScheduleReports(action) {
  try {
    const json = yield call(api.GET_PENDING_SHEDULE_REPORTS, action.payload);
    yield put({ type: GET_PENDING_SHEDULE_REPORTS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadGetPendingScheduleReports() {
  yield takeLatest(SG_GET_PENDING_SHEDULE_REPORTS, getPendingScheduleReports);
}

function* addScheduleTemplate(action) {
  try {
    const json = yield call(api.ADD_SCHEDULE_TEMPLATE, action.payload);
    yield put({ type: ADD_SCHEDULE_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadAddScheduleTemplate() {
  yield takeLatest(SG_ADD_SCHEDULE_TEMPLATE, addScheduleTemplate);
}

function* deleteScheduleTemplate(action) {
  try {
    const json = yield call(api.DELETE_SCHEDULE_TEMPLATE, action.payload);
    yield put({ type: DELETE_SCHEDULE_TEMPLATE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadDeleteScheduleTemplate() {
  yield takeLatest(SG_DELETE_SCHEDULE_TEMPLATE, deleteScheduleTemplate);
}

function* updateScheduleTemplate(action) {
  try {
    const json = yield call(api.UPDATE_SCHEDULE_TEMPLATE, action.payload);
    yield put({ type: UPDATE_SCHEDULE_TEMPLATE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadUpdateScheduleTemplate() {
  yield takeLatest(SG_UPDATE_SCHEDULE_TEMPLATE, updateScheduleTemplate);
}

export default function* rootSaga() {
  yield all([
    loadAddScheduleTemplate(),
    loadDeleteScheduleTemplate(),
    loadUpdateScheduleTemplate(),
    loadGetPendingScheduleReports(),
  ]);
}
