import styled from "styled-components";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./transitions.css"; // Import your CSS for transitions
import Sidebar from "./Sidebar";
import Component from "./components";
// import sample from "./sample_data/sample4.json";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import demo from "./sample_data/demo.json";
import { SG_GET_DATA_STORIES, SG_GET_DATA_STORY_LOGS } from "constants/actions";
import { useParams } from "react-router";

const DataStory = ({ tracked }) => {
  const dispatch = useDispatch();
  const { id, category } = useParams();

  const navigate = useNavigate();
  const [introComplete, setIntroComplete] = React.useState(false);
  const [stage, setStage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [currentStory, setCurrentStory] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeRecommendation, setActiveRecommendation] = React.useState();
  let isDemo = searchParams.get("demo");

  const { get_auth_employee, get_chat_gpt_recommendations, story_logs } =
    useSelector(
      (state) => ({
        get_auth_employee: state.auth,
        get_chat_gpt_recommendations: state.recommendations,
        active_recommendation: state.recommendations.active_recommendation,
        story_logs: state.story_logging,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch({
      type: SG_GET_DATA_STORY_LOGS,
    });
  }, [dispatch]);

  const isStoryCompleted = (storyId, logs) => {
    let completed = false;
    logs?.data_story_logs?.results?.forEach((log) => {
      if (log.data_story_id === Number(storyId)) {
        completed = !!log.user_interactions.Completed_count;
      }
    });

    return Boolean(completed);
  };

  const renderContent = (stage) => {
    //  from the stage, retrieve the component data
    // const data = sample[stage];

    // TODO: Once API includes properties required for this component, replace sample[stage] with data
    let data = currentStory[stage];
    let isTracked = tracked ? !isStoryCompleted(id, story_logs) : false;

    if (!isTracked && data?.reactComponent === "StoryFeedback") {
      data = currentStory[stage + 1];
      navigate("/leader/audit/reports/stories");
    }

    return <Component {...data} tracked={isTracked} />;
  };

  useEffect(() => {
    if (get_chat_gpt_recommendations?.data_stories?.results?.length > 0) {
      const storiesGroup =
        get_chat_gpt_recommendations?.data_stories?.results?.find(
          (group) => group.id === Number(id)
        );

      if (storiesGroup?.recommendation?.data_story?.length > 0) {
        const storyRecs = storiesGroup?.recommendation?.data_story;

        const rec = storyRecs.find((rec) => rec.category_option === category);
        if (rec) {
          setActiveRecommendation(rec);
          return;
        }
      }
    }
  }, [get_chat_gpt_recommendations, id, category]);

  useEffect(() => {
    dispatch({
      type: SG_GET_DATA_STORIES,
      payload: `organization=${get_auth_employee?.organization_id}`,
    });
  }, [dispatch]);

  useEffect(() => {
    if (activeRecommendation || isDemo) {
      const currentStory = isDemo
        ? demo?.response
        : activeRecommendation?.response;
      const nextScreens = [
        {
          title: "How well did you understand your data?",
          componentId: "feedback",
          componentVersion: "v1",
          reactComponent: "StoryFeedback",
          description: "A brief description of what this component does",
          manualInputs: [
            {
              name: "Legend Title",
              type: "string",
              value: "Story Feedback",
              component: "text",
              placeholder: "Title for the the legend",
            },
          ],
          pythonFunction: "module.functionName",
          dataRequirements: "",
          additionalSettings: {},
        },
        {
          title: "How well did you understand your data?",
          componentId: "feedback",
          componentVersion: "v1",
          reactComponent: "NextSteps",
          description: "A brief description of what this component does",
          manualInputs: [
            {
              name: "Legend Title",
              type: "string",
              value: "Next Steps",
              component: "text",
              placeholder: "Title for the the legend",
            },
          ],
          pythonFunction: "module.functionName",
          dataRequirements: "",
          additionalSettings: {},
        },
      ];

      currentStory.push(...nextScreens);
      setCurrentStory(currentStory);
    }
  }, [activeRecommendation, demo]);

  return (
    <Container>
      {currentStory && (
        <>
          {/* <Sidebar
            open={open}
            setOpen={setOpen}
            stage={stage}
            setStage={setStage}
            data={currentStory}
          /> */}
          <Center open={open}>
            <TransitionGroup component={null}>
              <CSSTransition
                key={stage} // Use the stage as a unique key to trigger transitions
                timeout={1000} // Duration of the transition
                classNames="fade" // Prefix for the CSS transition classes
              >
                {renderContent(stage)}
              </CSSTransition>
            </TransitionGroup>
          </Center>
        </>
      )}

      <Bottom showBack={stage > 0}>
        {stage > 0 && (
          <Button2
            onClick={() => {
              if (stage === 0) {
                navigate(-1);
              } else {
                setStage(stage - 1);
              }
            }}
          >
            Back
          </Button2>
        )}
        <Button
          onClick={() => {
            if (stage < currentStory.length - 1) {
              setStage(stage + 1);
            } else {
              setIntroComplete(true);
            }
          }}
        >
          Continue{" "}
          <Icon name="arrow right" style={{ marginLeft: 10, marginTop: -3 }} />
        </Button>
      </Bottom>
    </Container>
  );
};

export default DataStory;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Raleway";
  min-height: calc(100vh - 75px);
`;

const Center = styled.div`
  width: ${(props) => (props.open ? "calc(100% - 300px)" : "800px")};
  padding-top: 1%;
  padding-left: ${(props) => (props.open ? "200px" : "0px")};
  transition: all 0.3s ease-in-out;
  margin-bottom: 100px;
`;

const Bottom = styled.div`
  position: fixed;
  bottom: 0px;
  left: 200px;
  width: calc(100% - 200px);
  height: 75px;
  z-index: 100;
  display: flex;
  justify-content: ${(props) =>
    props.showBack ? "space-between" : "flex-end"};
  align-items: center;
  padding-right: 20px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #2254aa;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #2254aa;
`;

const Button2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: #2254aa;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
