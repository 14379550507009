import React from "react";
import styled from "styled-components";
import { Dropdown } from 'semantic-ui-react'

const Custom = styled.input`
	border-radius:5px;
	height:45px;
	outline:none;
	border:${props=>props.error? '1px solid #ff2d55':'1px solid #9b9b9b'};
	margin-top:3px;
	padding-left:15px;
`
const Container = styled.div`
  font-size: 16px;
  font-weight:400;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  flex-direction:column;
  margin-bottom:15px;
  width:${props=>props.half?'calc(50% - 15px)':'100%'};
`

const Error = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #ff2d55;
`;

const Label = styled.div`
   font-weight:bold;
`

const CustomDropdown = React.forwardRef((props, ref) => {

  console.log(props.defaultValue)
  // React.useEffect(()=>{
  //   // console.log(props)
  // },[props])
  return (
    <Container half={props.half}>
      <Label>{props.title}</Label>
      <Dropdown
        fluid
        search
        selection
        onChange={(e,{value}) => props.onChange(value)}
        value={props.defaultValue}
        options={props.options}
        forwardedRef={ref}
        error={props.error}
        placeholder={props.placeholder}
        style={{marginTop:5}}
      />
      <Error>{props.error}</Error>
    </Container>
  );
});

export default CustomDropdown;
