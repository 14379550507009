import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import {
  Table,
  Header,
  Radio,
  Form,
  Icon,
} from "semantic-ui-react";

import SignupToken from "components/SignupToken";
import EmailDeliveryReport from "components/EmailDeliveryReport";

import Select from "react-select";

// import { useNavigate } from "react-router-dom";
import {
  SG_VALIDATE_EMPLOYEE,
} from "constants/actions";


const EmployeeProfile = ({
  employee,
  valueUpdated,
  setValueUpdated,
  selectRow,
  setSelectRow,
  categoryUpload,
  setCategoryUpload,
  setCategoryUpdated,
  employeeCategory,
  setEmployeeCategory,
  userEmployee,
  inviteNew,
  errorMessage,
  setErrorMessage
}) => {
  const dispatch = useDispatch();

  // const [errorMessage, setErrorMessage] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [selectedPrimary, setSelectedPrimary] = useState([]);

  const [secondaryCategory, setSecondaryCategory] = useState([]);
  const [selectedSecondary, setSelectedSecondary] = useState({});
  const [disableEdit, setDisableEdit] = useState(false)
  const [isSelfEdit, setIsSelfEdit] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [notEnoughPriv, setNotEnoughPriv] = useState(false)
  const [editUsers, setEditUsers] = useState(false)
  const [canLockYouOut, setCanLockYouOut] = useState(false)
  const [selectAll, setSelectAll] = useState(false)


  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);

  const [disableCategory, setDisableCategory] = useState(false)
  const [orgCategory, setOrgCategory] = useState([])

  const [categoryTempLoad, setCategoryTempLoad] = useState([]);
  const [show, setShow] = useState(null)

  const {
    // get_teams, 
    get_auth,
    get_employee_category, get_survey_structure } =
    useSelector(
      (state) => ({
        get_auth: state.auth,
        get_teams: state.teams.teams,
        get_employee_category: state.employee_category.employee_category,
        get_survey_structure: state.surveystructure.survey_structure,
      }),
      shallowEqual
    );

  useEffect(() => {
    setEmployeeCategory(get_employee_category.filter(ec => ec.employee === employee?.id)?.[0]);
    setCategoryTempLoad(get_employee_category.filter(
      ec => ec.employee === employee?.id)?.[0]?.category.filter(
        ct => ct.priority !== 'primary'))
  }, [get_employee_category, setEmployeeCategory, employee]);


  // console.log(get_employee_category)
  // console.log(!(userEmployee?.account_type === 5 || userEmployee?.survey_add_users === 0))

  useEffect(() => {
    if (get_survey_structure.length > 0) {

      const _primaryList = get_survey_structure?.[0]?.field_map?.field_name.find(
        (f) => f.priority === "primary"
      );
      const _primarySelectList = []
      get_survey_structure?.[0]?.field_map?.field_name.find(
        (f) => f.priority === "primary"
      )?.answers?.map((lst, i) => {
        if (isAdmin) {
          _primarySelectList.push(
            {
              value: lst.name,
              index: i,
              label: lst.name,
              name: _primaryList?.original,
              priority: "primary",
            }
          );
        } else {
          if (userEmployeeCategoryList?.includes(lst.name)) {
            _primarySelectList.push(
              {
                value: lst.name,
                index: i,
                label: lst.name,
                name: _primaryList?.original,
                priority: "primary",
              }
            );
          }
        }
        return null
      });


      setPrimaryCategory({
        original: _primaryList?.original,
        answers: _primarySelectList
      });

      const _secondaryList = get_survey_structure?.[0]?.field_map?.field_name.find(
        (f) => f.priority === "secondary"
      );

      const _secondarySelectList = get_survey_structure?.[0]?.field_map?.field_name.find(
        (f) => f.priority === "secondary"
      )?.answers?.map((lst, i) => {
        return {
          index: i,
          value: lst.name,
          label: lst.name,
          name: _secondaryList?.original,
          priority: "secondary",
        }
      });

      setSecondaryCategory({
        original: _secondaryList?.original,
        answers: _secondarySelectList
      });

      (get_survey_structure?.[0]?.field_map?.field_name.find(
        (f) => f.priority === "secondary"
      ));
        
      const _orgCategory = []
      get_survey_structure?.[0]?.categories.filter(
        (f) => f.priority !== "primary" && f?.visibility
      ).map(og => {
        
        if (og?.visibility === "public") {
          _orgCategory.push(og)
        }
        return null
      })

      setOrgCategory(_orgCategory)
    }
  }, [get_survey_structure, isAdmin,
    userEmployeeCategoryList,]);


  useEffect(() => {
    let notFound = 0
    setDisableCategory(false)
    if (userEmployee?.account_type !== 5) {
      employeeCategory?.category?.filter(em => em.priority === "primary")
        .map(item => {
          // console.log(item)
          if (!userEmployeeCategoryList.includes(item?.value)) {
            notFound += 1
          }
          return null
        })
      if (notFound > 0) {
        setDisableCategory(true)
      }
    }
  }, [userEmployeeCategoryList, employeeCategory, userEmployee]);

  // console.log(employeeCategory)

  useEffect(() => {
    const _selectedPrimaryList = [];
    let _selectedRoleList = {};
    if (employeeCategory?.category) {
      employeeCategory?.category.map((cat) => {
        if (primaryCategory?.answers) {
          const PC = primaryCategory?.answers?.find(({ value }) => value === cat?.value);
          if (PC) {
            _selectedPrimaryList.push(PC);
          }
        }
        if (secondaryCategory?.answers) {
          const RC = secondaryCategory?.answers?.find(({ value }) => value === cat?.value);
          if (RC) {
            _selectedRoleList = RC;
          }
        }
        return null;
      });
    }
    // console.log(_selectedRoleList)
    setSelectedSecondary(_selectedRoleList);
    setSelectedPrimary(_selectedPrimaryList);
  }, [primaryCategory, secondaryCategory, employeeCategory, userEmployee]);

  useEffect(() => {
    const _Category = (get_employee_category.filter(
      ec => ec.employee === Number(get_auth?.employee_id))?.[0]?.category)

    setUserEmployeeCategoryList(_Category?.filter(ct => ct.priority === 'primary').map(
      ct => ct.value
    ))
  }, [get_employee_category, get_auth]);

  useEffect(() => {
    const UploadFile = [];
    if (selectedPrimary?.length > 0) {
      selectedPrimary.map((item) => {
        return UploadFile.push({
          name: item.name,
          value: item.value,
          priority: item.priority,
        });
      });
    }
    // if (selectedSecondary?.value) {
    //   // selectedSecondary.map((item) => {
    //   UploadFile.push({
    //     name: selectedSecondary.name,
    //     value: selectedSecondary.value,
    //     priority: selectedSecondary.priority,
    //   });
    // }
    if (categoryTempLoad?.length > 0) {
      categoryTempLoad.map((item) => {
        return UploadFile.push({
          name: item.name,
          value: item.value,
          priority: item.priority,
        });
      });
    }

    if (UploadFile.length > 0) {
      setCategoryUpdated(true)
    }

    setCategoryUpload(UploadFile);
  }, [selectedPrimary,
    // selectedSecondary,
    categoryTempLoad,
    setCategoryUpload,
    setCategoryUpdated]);


  function ChangePrimaryCategory(e) {
    setValueUpdated(true);
    if (!e) {
      setSelectedPrimary([]);
    } else {
      setSelectedPrimary(e);
    }
    setCategoryUpdated(true);
  }

  useEffect(()=>{
    if(selectAll){
      setSelectedPrimary(primaryCategory?.answers);
    }else{
      setSelectedPrimary([]);
    }

     setCategoryUpdated(true);
  },[selectAll])

  // function ChangeSecondaryCategory(e) {
  //   setValueUpdated(true);
  //   if (!e) {
  //     setSelectedSecondary({});
  //   } else {
  //     setSelectedSecondary(e);
  //   }
  //   setCategoryUpdated(true);
  // }

  function ResetRecieveSurvey() {
    setValueUpdated(true);
    if (selectRow?.survey_status === 1) {
      setSelectRow({
        ...selectRow,
        survey_status: 2,
      });
    } else {
      setSelectRow({
        ...selectRow,
        survey_status: 1,
      });
    }
  }

  function ResetAccessSubfactorData() {
    setValueUpdated(true);
    if (selectRow?.access_subfactor_data === 1) {
      setSelectRow({
        ...selectRow,
        access_subfactor_data: 0,
      });
    } else {
      setSelectRow({
        ...selectRow,
        access_subfactor_data: 1,
      });
    }
  }

  function ResetAccessDataAnalytics() {
    setValueUpdated(true);
    if (selectRow?.access_data_analytics === 1) {
      setSelectRow({
        ...selectRow,
        access_data_analytics: 0,
      });
    } else {
      setSelectRow({
        ...selectRow,
        access_data_analytics: 1,
      });
    }
  }


  function ChangeCategoryTag(e, org) {
    setValueUpdated(true);
    setCategoryUpdated(true);
    const UploadFile = categoryTempLoad.filter(cl => cl.name !== org.name)
    if (e) {
      UploadFile.push({
        name: org.name,
        value: e.name,
        priority: org.priority,
      });
      // categoryTempLoad
    }
    setCategoryTempLoad(UploadFile)
    // setCategoryUpload(UploadFile)
  }

  function ResetLoginEnabled() {
    setValueUpdated(true);
    if (selectRow?.enable_login === true) {
      setSelectRow({
        ...selectRow,
        enable_login: false,
      });
    } else {
      setSelectRow({
        ...selectRow,
        enable_login: true,
      });
    }
  }

  function ResetSurveySchedule() {
    setValueUpdated(true);
    if (selectRow?.survey_schedule !== 1) {
      setSelectRow({
        ...selectRow,
        survey_schedule: 1,
      });
    } else {
      setSelectRow({
        ...selectRow,
        survey_schedule: 0,
      });
    }
  }

  function ResetCanReadFeedback() {
    setValueUpdated(true);
    if (selectRow?.read_feedback === 1) {
      setSelectRow({
        ...selectRow,
        read_feedback: 0,
      });
    } else {
      setSelectRow({
        ...selectRow,
        read_feedback: 1,
      });
    }
  }
  function ResetSurveyUser() {
    setValueUpdated(true);
    if (selectRow?.survey_add_users !== 1) {
      setSelectRow({
        ...selectRow,
        survey_add_users: 1,
      });
    } else {
      setSelectRow({
        ...selectRow,
        survey_add_users: 0,
      });
    }
  }

  function ResetAdmin() {
    setValueUpdated(true);
    if (selectRow?.account_type === 5) {
      setSelectRow({
        ...selectRow,
        account_type: 9,
      });
    } else {
      setSelectRow({
        ...selectRow,
        account_type: 5,
      });
    }
  }

  const searchEmail = (e) => {
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setValueUpdated(true);

    setSelectRow({
      ...selectRow,
      email: e.target.value,
    })
    if (re.test(e.target.value)) {
      setErrorMessage(false);
      dispatch({
        type: SG_VALIDATE_EMPLOYEE,
        payload: `email=${e.target.value}`,
      });
    } else {
      // invalid email, maybe show an error to the user.
      setErrorMessage("Not valid email id");
    }
  };

  useEffect(() => {
    if (employee?.id) {
      setSelectRow({
        first_name: employee?.first_name,
        last_name: employee?.last_name,
        email: employee?.email,
        enable_login: employee?.enable_login,
        survey_status: employee?.survey_status,
        survey_schedule: employee?.survey_schedule,
        survey_add_users: employee?.survey_add_users,
        access_subfactor_data: employee?.access_subfactor_data,
        access_data_analytics: employee?.access_data_analytics,
        account_type: employee?.account_type,
        read_feedback: employee?.read_feedback
        // role: employee?.role,
      });
    } else {
      setSelectRow({
        first_name: "",
        last_name: "",
        email: "",
        enable_login: false,
        email_status: 1,
        survey_status: 9,
        survey_schedule: 0,
        survey_add_users: 0,
        read_feedback: 0,
        access_subfactor_data: 0,
        access_data_analytics: 0,
        account_type: 9,
        role: 11,
      });
    }
  }, [employee, setSelectRow]);
  // console.log(userEmployee?.account_type === 5)
  // console.log(selectRow)
  function ChangeFirstNameValue(value) {
    setValueUpdated(true);
    setSelectRow({
      ...selectRow,
      first_name: value,
    })
  }

  function ChangeLastNameValue(value) {
    setValueUpdated(true);
    setSelectRow({
      ...selectRow,
      last_name: value,
    })
  }

  useEffect(() => {
    setDisableEdit(false)
    setEditUsers(true)
    setIsAdmin(false)
    setIsSelfEdit(false)
    setCanLockYouOut(false)
    // when inviting new member override edit option
    if (inviteNew) {
      setDisableEdit(false)
    }
    if (userEmployee?.survey_add_users === 0) {
      setEditUsers(false)
    }
    if (userEmployee?.id === employee?.id) {
      setIsSelfEdit(true)
      setCanLockYouOut(true)
    }
    // if admin enble self edit
    if (userEmployee?.account_type === 5) {
      setIsAdmin(true)
      setIsSelfEdit(false)
      setEditUsers(true)
    }

  }, [inviteNew, userEmployee, employee]);

  // console.log(userEmployee?.account_type)
  // console.log(employee?.account_type)

  useEffect(() => {
    setNotEnoughPriv(false)
    if (userEmployee?.account_type > employee?.account_type) {
      setNotEnoughPriv(true)
    }
  }, [userEmployee, employee]);

  // console.log("isAdmin", isAdmin)

  // console.log(isAdmin !== true || disableEdit || isSelfEdit !== true)

  return (
    <Container>
      <FormContainer>
        <FormSection>
          <Table basic="very" celled>
            <Table.Body>
              <Table.Row>
                <Table.Cell>First Name</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        value={selectRow?.first_name || ""}
                        onChange={(e) =>
                          ChangeFirstNameValue(e.target.value)
                        }
                        placeholder="First name"
                      />
                    </Form.Group>
                  </Header.Content>
                </Table.Cell>
                {/* <Table.Cell onClick={() => setNameOpen(true)}><Icon name='edit' />Edit</Table.Cell> */}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Last Name</Table.Cell>
                <Table.Cell>
                  <Header.Content>
                    <Form.Group widths="equal">
                      <Form.Input
                        fluid
                        value={selectRow?.last_name || ""}
                        onChange={(e) =>
                          ChangeLastNameValue(e.target.value)
                        }
                        placeholder="Last name"
                      />
                    </Form.Group>
                  </Header.Content>
                </Table.Cell>
                {/* <Table.Cell onClick={() => setNameOpen(true)}><Icon name='edit' />Edit</Table.Cell> */}
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>

                <Table.Cell>
                  {employee?.email ? (
                    <Header.Content>{employee?.email}</Header.Content>
                  ) : (
                    <Header.Content>
                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          value={selectRow?.email || ""}
                          onChange={(e) => searchEmail(e)}
                          placeholder="Email Id"
                        />
                        <p className="error">{errorMessage}</p>
                      </Form.Group>
                    </Header.Content>
                  )}
                </Table.Cell>
              </Table.Row>
              {editUsers &&
                employee?.email && (
                  <Table.Row>
                    <Table.Cell>Delivery Information</Table.Cell>
                    <Table.Cell>
                      <Role valid={employee?.email_status_text === "Validated"}>
                        {employee?.data?.email_status_text}
                      </Role>
                      {employee?.email_status_text !== "Validated" && (
                        <SignupToken
                          organization_id={employee?.organization}
                          employee_id={employee?.id}
                        />
                      )}
                      <Role valid={employee?.email_status_text === "Validated"}>
                        {employee?.email_status_text}
                      </Role>
                      <EmailDeliveryReport employee_id={employee?.id} />
                    </Table.Cell>
                  </Table.Row>
                )}
              {editUsers && disableCategory ?

                <Table.Row>
                  <Table.Cell>
                    <Label inValid={true}>
                      Unable to Edit {primaryCategory
                        ? primaryCategory.original
                        : ""} and {secondaryCategory
                          ? secondaryCategory?.original
                          : ""}
                    </Label>
                  </Table.Cell>

                  <Table.Cell>
                    <Label inValid={true}>

                      This employee has more {primaryCategory
                        ? primaryCategory.original
                        : ""}
                      than your permissions!
                    </Label>

                  </Table.Cell>
                </Table.Row> :
                <>
                  {/* Type */}
                  {editUsers &&
                    <Table.Row>
                      {/* <Table.Cell Header Primary Category Only*/}
                      <Table.Cell>
                        {primaryCategory
                          ? primaryCategory.original
                          : ""}
                      </Table.Cell>
                      <Table.Cell>
                        {(isAdmin !== true || disableEdit || isSelfEdit === true) ?
                          <>
                            <Warning>
                              <Icon name='warning circle' size='huge' color="red" />
                            </Warning>
                            <Label inValid={isAdmin !== true || disableEdit || isSelfEdit === true}>
                              {" "}
                              <div>Cannot Edit {primaryCategory
                                ? primaryCategory.original
                                : ""}</div>
                              <Description>
                                You cannot edit your own details,
                                this option has to be enabled by admin.
                              </Description>
                            </Label>
                          </>
                          :
                          <>
                          <Select
                            placeholder={`Select ${primaryCategory?.original}`}
                            onChange={ChangePrimaryCategory}
                            value={selectedPrimary}
                            isMulti
                            options={primaryCategory?.answers}
                            style={{zIndex:5}}
                          />
                           <Radio
                            toggle
                            label='Select all'
                            onChange={()=>setSelectAll(!selectAll)}
                            checked={selectAll}
                            style={{marginTop:5,zIndex:-0}}
                          />
                          </>
                        }

                      </Table.Cell>
                    </Table.Row>
                  }
                </>
              }

            </Table.Body>
          </Table>
          {editUsers && orgCategory.length>0 && <>
            <Title onClick={() => setShow(show === 1 ? null : 1)}>
              Category Tag

              <Icon name={show === 1 ? "chevron up" : "chevron down"} />
            </Title>

            <ShowArea show={show === 1}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Selection
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>

                  {orgCategory && orgCategory.map((org, i) => {
                    let _selectedCategory = null
                    // console.log(categoryTempLoad)
                    if (categoryTempLoad && categoryTempLoad?.filter(cl => cl.name === org.name)?.[0]) {
                      const _selectedValue = categoryTempLoad.filter(cl => cl.name === org.name)?.[0]?.value
                      _selectedCategory = org?.options.filter(op => op.name === _selectedValue)
                    }

                    return (
                      <Table.Row key={i}>
                        <Table.Cell>
                          {org?.name}
                        </Table.Cell>
                        <Table.Cell>
                          <Select
                            placeholder={`Select ${org?.name}`}
                            onChange={(e) => ChangeCategoryTag(e, org)}
                            value={_selectedCategory ? _selectedCategory : null}
                            isClearable={true}
                            // isMulti
                            options={org?.options}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                          />
                        </Table.Cell>
                      </Table.Row>

                    )
                  })}


                </Table.Body>
              </Table>

            </ShowArea>
          </>}
          {editUsers && <>
          <Title onClick={() => setShow(show === 2 ? null : 2)}>
            Permission Controls
            <Icon name={show === 2 ? "chevron up" : "chevron down"} />
          </Title>
          <ShowArea show={show === 2}>

            <Row>
              <Radio
                toggle
                onClick={ResetRecieveSurvey}
                checked={selectRow?.survey_status === 1}
              />
              <Label>
                <div>Participate in Survey</div>
                <Description>
                  Member will participate in all audit survey's associated with
                  their given categories
                </Description>
              </Label>
            </Row>

            <Row>
              <Radio
                toggle
                onClick={ResetAccessDataAnalytics}
                checked={selectRow?.access_data_analytics === 1}
              />
              <Label>
                <div>Access Data Analytics</div>
                <Description>
                  Member will get access to data analytics
                </Description>
              </Label>
            </Row>
            <Row>
              <Radio
                toggle
                onClick={ResetAccessSubfactorData}
                checked={selectRow?.access_subfactor_data === 1}
              />
              <Label>
                <div>Access Subfactor Data</div>
                <Description>
                  Member will get acces to subfactor data
                </Description>
              </Label>
            </Row>

           

            <Row>
              <Radio
                toggle
                disabled={isAdmin !== true || disableEdit || isSelfEdit}
                onChange={ResetCanReadFeedback}
                checked={selectRow?.read_feedback === 1}
              />
              {(isAdmin !== true || disableEdit || isSelfEdit === true) &&
                <Warning>
                  <Icon name='warning circle' size='huge' color="red" />
                </Warning>

              }
              <Label inValid={isAdmin !== true || disableEdit || isSelfEdit === true}>
                {" "}
                <div>Can Read Feedback</div>
                <Description>
                  Member can read feedback.
                </Description>
              </Label>


            </Row>
            <Row>
              <Radio
                toggle
                disabled={isAdmin !== true || disableEdit || isSelfEdit === true}
                onChange={ResetSurveySchedule}
                checked={selectRow?.survey_schedule === 1}
              />
              {(isAdmin !== true || disableEdit || isSelfEdit === true) &&
                <Warning>
                  <Icon name='warning circle' size='huge' color="red" />
                </Warning>

              }
              <Label inValid={isAdmin !== true || disableEdit || isSelfEdit === true}>
                {" "}
                <div>Can Schedule Surveys</div>
                <Description>
                  The ability to schedule and manage survey's for the specified
                  granted permissioned groups
                </Description>
              </Label>
            </Row>

            <Row>
              <Radio
                toggle
                disabled={isAdmin !== true || disableEdit || isSelfEdit === true}
                onChange={ResetSurveyUser}
                checked={selectRow?.survey_add_users === 1}
              />
              {(isAdmin !== true || disableEdit || isSelfEdit === true) &&
                <Warning>
                  <Icon name='warning circle' size='huge' color="red" />
                </Warning>

              }
              <Label inValid={isAdmin !== true || disableEdit || isSelfEdit === true}>
                {" "}
                <div>Can Edit Users</div>
                <Description>
                  The ability to manage users for the specificed permissioned
                  groups (inivite,edit,remove).
                </Description>
              </Label>
            </Row>
            <Row>
              {/* if you are admin you cannot make yourself admin or remove yourself from admin*/}

              <Radio
                toggle
                disabled={notEnoughPriv || disableEdit || isSelfEdit === true|| canLockYouOut}
                onChange={ResetLoginEnabled}
                checked={selectRow?.enable_login}
              />

              {(notEnoughPriv || disableEdit || isSelfEdit === true || canLockYouOut) &&
                <Warning>
                  <Icon name='warning circle' size='huge' color="red" />
                </Warning>

              }
              <Label inValid={notEnoughPriv || disableEdit || isSelfEdit === true || canLockYouOut}>
                {" "}
                <div>Has a Login Account</div>
                <Description>
                  Login access grants this user access to the results and
                  analytics dashboards associated with the specific permissions
                  granted
                </Description>
              </Label>
            </Row>
            {/* new invites cannot be made default before they login */}
            {!inviteNew &&
              <Row>
                <Radio
                  toggle
                  onChange={ResetAdmin}
                  disabled={(isAdmin !== true || isSelfEdit === true || canLockYouOut)}
                  checked={selectRow?.account_type === 5}
                />
                {(isAdmin !== true || isSelfEdit === true || canLockYouOut) &&
                  <Warning>
                    <Icon name='warning circle' size='huge' color="red" />
                  </Warning>

                }
                <Label inValid={((isAdmin !== true || isSelfEdit === true || canLockYouOut))}>
                  <div>Is an Account Administrator</div>
                  <Description>
                    Has access to all reports, results, settings, user management
                    and team management for the entire organization
                  </Description>
                </Label>
              </Row>
            }
          </ShowArea>
        </>
          }
        </FormSection>
      </FormContainer>
    </Container>
  );
};

export default EmployeeProfile;

const Container = styled.div`
  margin-left: 10px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  margin-bottom: 30px;
  display:flex;
  justify-content:space-between;
  width:100%;
  cursor:pointer;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  margin-left: ${(props) => (props.inValid ? "10px" : "30px")}; 
  color: ${(props) => (props.inValid ? "#ff2d55" : "#484545")};
`;

const Warning = styled.div`
  margin-left: 30px;
  // padding-left: 20px;
`;

const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Barlow", sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0px 200px 0px;
    width: 100%;
  }
`;

const FormSection1 = styled.div`
  margin-right: 60px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const FormSection = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

const ShowArea = styled.div`

  max-height:${props => props.show ? '1000px' : 0};
  transition:all 1s;
  overflow:${props => props.show ? 'visible' : 'hidden'};
  padding-bottom:${props => props.show ? '30px' : 0};
`