export function StarIcon(fill) {
 return (
  <svg
   width="20"
   height="20"
   viewBox="0 0 20 20"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 0.5C10.1903 0.5 10.3641 0.608049 10.4484 0.778717L13.2404 6.43601L19.4836 7.3432C19.6719 7.37056 19.8284 7.50248 19.8872 7.68349C19.946 7.8645 19.897 8.0632 19.7607 8.19604L15.2431 12.5996L16.3096 18.8176C16.3417 19.0052 16.2646 19.1948 16.1106 19.3066C15.9567 19.4185 15.7525 19.4332 15.5841 19.3447L10 16.409L4.41592 19.3447C4.24746 19.4332 4.04333 19.4185 3.88936 19.3066C3.73538 19.1948 3.65827 19.0052 3.69045 18.8176L4.75691 12.5996L0.239296 8.19604C0.10301 8.0632 0.0539612 7.8645 0.112774 7.68349C0.171586 7.50248 0.32806 7.37056 0.516403 7.3432L6.75959 6.43601L9.55163 0.778717C9.63586 0.608049 9.80968 0.5 10 0.5ZM10 2.12978L7.53999 7.11429C7.46716 7.26187 7.32638 7.36415 7.16352 7.38781L1.66278 8.18712L5.64316 12.067C5.761 12.1819 5.81477 12.3474 5.78695 12.5096L4.84732 17.9881L9.76733 15.4015C9.91299 15.3249 10.087 15.3249 10.2327 15.4015L15.1527 17.9881L14.213 12.5096C14.1852 12.3474 14.239 12.1819 14.3568 12.067L18.3372 8.18712L12.8365 7.38781C12.6736 7.36415 12.5328 7.26187 12.46 7.11429L10 2.12978Z"
    fill={fill}
   />
  </svg>
 );
}

export function BoltIcon(fill) {
 return (
  <svg
   width="13"
   height="20"
   viewBox="0 0 13 20"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.9469 0.883034C7.94691 0.88307 7.94634 0.883837 7.94518 0.885223C7.94631 0.88369 7.94689 0.882997 7.9469 0.883034ZM7.21789 0.679482C7.2179 0.679466 7.21798 0.680104 7.21808 0.681445L7.21789 0.679482ZM7.21847 1.9891L1.28593 11.0037H4.86694C5.37794 11.0037 5.78153 11.4227 5.78153 11.912V17.0122L11.7141 7.9976H8.13306C7.62206 7.9976 7.21847 7.57856 7.21847 7.08925V1.9891ZM5.0531 18.1182C5.05311 18.1182 5.05367 18.1174 5.05483 18.116C5.05369 18.1176 5.0531 18.1183 5.0531 18.1182ZM5.78192 18.3198C5.78208 18.3211 5.78213 18.3217 5.78212 18.3218L5.78192 18.3198ZM8.19752 0.399429C8.23403 0.504183 8.24219 0.610128 8.24219 0.695047V6.9976H12.2489C12.4607 6.9976 12.7647 7.07279 12.9214 7.36186C13.0758 7.64681 12.974 7.93245 12.8613 8.10368L5.92117 18.6492C5.87503 18.7193 5.80864 18.8053 5.71665 18.8744C5.6335 18.9369 5.4318 19.0543 5.1775 18.9743C4.92971 18.8963 4.83437 18.6934 4.80248 18.6018C4.76597 18.4971 4.75781 18.3911 4.75781 18.3062V12.0037H0.751099C0.539275 12.0037 0.235326 11.9285 0.0786258 11.6394C-0.0758433 11.3544 0.0260419 11.0688 0.138728 10.8976L7.07883 0.35202C7.12497 0.281916 7.19136 0.195941 7.28335 0.126833C7.3665 0.0643668 7.5682 -0.0530124 7.8225 0.0269721C8.07029 0.104909 8.16563 0.307898 8.19752 0.399429Z"
    fill={fill}
   />
  </svg>
 );
}

export function ChevronIcon(fill) {
 return (
  <svg
   width="8"
   height="14"
   viewBox="0 0 8 14"
   fill="none"
   xmlns="http://www.w3.org/2000/svg"
  >
   <path d="M1 13L7 7L1 1" stroke={fill} strokeLinecap="round" />
  </svg>
 );
}

export function CloseIcon(fill) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6569 17.6569L6.34319 6.34315"
        stroke="#666D79"
        strokeLinecap="round"
      />
      <path
        d="M17.6568 6.34315L6.34311 17.6569"
        stroke="#666D79"
        strokeLinecap="round"
      />
    </svg>
  );
}
   