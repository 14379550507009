import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import KeyPic from 'assets/images/key.png'

const loading=keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`


const Text4 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  width: 100%;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
`;
const Key = styled.div`
  background-color: #e9e9e9;
  width: 120%;
  margin-left:-10%;
  padding: 33px 20px 20px 33px;
  margin-bottom:20px;
  

  @media (min-width:768px){
   display:none;
  }
`;

const Num = styled.div`
  height: 24px;
  width: 24px;
  min-width:24px;
  background-color: #4a4a4a;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const NumText = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
`;

const NumContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 0px 15px 0px;
`;

const Text5 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  color: #4a4a4a;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 20px;
`;

const KeyLoading = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  color: transparent;
	background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
	background-size: 400%;
	animation: ${loading} 1.2s ease-in-out infinite;
  width: 40%;
  padding: 33px 20px 20px 20px;

  @media (max-width:768px){
    width:100%;
    padding:10px;
  }
`;

const IMG = styled.img`
  height:30px;
  width:30px;
  transform:translate(10px,7px);
`


const MobileKeys = (props)=>{


	if(props.loading){
		return <KeyLoading/>
	}


	return (

		<Key>
          <Text4>3 Keys to Keep in Mind  <IMG src={KeyPic} /></Text4>
         
          <NumContainer>
            <Num>
              <NumText>1</NumText>
            </Num>
            <Text5>
              Everyone has each trait, it's simply the magnitue that differs
            </Text5>
          </NumContainer>

          <NumContainer>
            <Num>
              <NumText>2</NumText>
            </Num>
            <Text5>
              Each continuum has benefits and challenges on each end
            </Text5>
          </NumContainer>

          <NumContainer>
            <Num>
              <NumText>3</NumText>
            </Num>
            <Text5>
              The traits are extremely reliable predictors of behavior
            </Text5>
          </NumContainer>
        </Key>
        )
}

export default MobileKeys