import styled, { keyframes } from "styled-components";
import React from "react";
import ThemeTimeline from './ThemeTimeline'
import ThemeAnalysis from '../Themes/ThemeAnalysis'

const ThemeCard = (props) =>{

	return (
		<Theme>
			<Number color={props.color}>{props.i}</Number>
			  <Score>
		              {props.impact}
		       <Impact>Impact</Impact>
		        </Score>
			<div>
			<ThemeTitle>{props.theme}</ThemeTitle>
			 <Keywords>
				{props.keys.map(item=><Key color={props.color}>{item}</Key>)} 
			 </Keywords>
			<Description>Bacon ipsum dolor amet sausage jowl leberkas venison boudin. Alcatra pork belly pig pastrami, jerky meatball pork chop ham prosciutto filet mignon. Shoulder filet mignon shankle tail prosciutto doner andouille ball tip beef short ribs. Pork meatloaf porchetta, pancetta tail ribeye spare ribs shank t-bone venison brisket.</Description>

			
			</div>
		</Theme>
		)
}




const Themes = () =>{

	const [selected,setSelected] = React.useState(1)

	return (
		<CardContent>
			<Dates>
						<Time first selected={selected==1} onClick={()=>setSelected(1)}>Overview</Time>
						<Time selected={selected==2} onClick={()=>setSelected(2)}>Theme Analysis</Time>
					</Dates>
			{selected==1?
				<ContentContainer>
				<Left>
						<Title>Key Themes</Title>
						<Dated>May 31st, 2021</Dated>
						<ThemeCard key={1} color={'#7e24ea'} i={1} theme={"Growth Mindset"} impact={'High Positive'} keys={['season trying win']}/>
						<ThemeCard key={2} color={'#7e24ea'} i={2} theme={"Future events/goals"} impact={'High Negative'} keys={['season eager goal']}/>
						<ThemeCard key={3} color={'#7e24ea'} i={3} theme={"Connection, belonging"} impact={'Moderate Positive'} keys={['impact ready battle']}/>
				</Left>
				<ThemeTimeline/>
			</ContentContainer>
			:<ThemeAnalysis/>}
		</CardContent>
		)
}

export default Themes




const CardContent = styled.div`
	padding:10px 40px;

`

const ContentContainer = styled.div`
		display:flex;
	flex-direction:row;
`

const Left = styled.div`
	width:60%;
`

const Dated = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	color:#4a4a4a;
	margin-top:5px;
`
const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:20px;
	font-weight:bold;
	width:100%;
`

const Theme = styled.div`
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:20px;
	width:100%;
	display:flex;
	flex-direction:row;
	border-radius:8px;
	margin-top:10px;
	max-width:800px;
	position:relative;

`

const ThemeTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:16px;
	font-weight:bold;
	 color: #4a4a4a;
`	

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	color: #4a4a4a;
`

const Number = styled.div`
	font-size:24px;
	font-weight:bold;
	height:50px;
	width:50px;
	min-width:50px;
	margin-right:10px;
	border-radius:25px;
	border:${props=>'2px solid '+props.color};
	display:flex;
	align-items:center;
	justify-content:center;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  font-weight:bold;
  position:absolute;
  top:15px;
  right:20px;

`

const Impact = styled.div`
  font-size:9px;
  font-weight:900;
  text-transform:uppercase;
  margin-top:-3px;
`

const ChartTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  font-size:16px;
  color: #4a4a4a;
  margin-top:10px;
`

const Keywords = styled.div`
	font-size:16px;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:10px;
  margin-top:5px;
`

const Key = styled.span`
	background-color:${props=>props.color};
	color:white;
	padding:2px 10px;
	border-radius:15px;
	font-size:13px;
	margin-right:1px;
`

const Dates = styled.div`
	display:flex;
	margin-bottom:20px;

`

const Time = styled.div`
	color:${props=>props.selected?'white':'#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props=>props.first?'1px solid #6a00ff':'none'};
	background-color:${props=>props.selected?'#6a00ff':'none'};
	cursor:pointer;
`