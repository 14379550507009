import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ThumbsUp from "assets/images/UI/green_thumb.svg";
import ThumbsDown from "assets/images/UI/red_thumb.svg";
import NeutralList from "assets/images/UI/list2.svg";


const ThematicSample = () => {


    return (

        <Container>
            <Column>
                <Card background={'#F3FDFA'}>
                    <Icon src={ThumbsUp} />
                    <Title color={'#1D9A7D'}>Positive Themes</Title>
                    <Content>
                    <strong>Team Collaboration:</strong> 85% of comments highlight a positive sense of teamwork and support among peers.
                    </Content>

                    <Content>
                    <strong>Innovation and Growth:</strong> : 60% mention opportunities for skill growth and the company’s encouragement of innovative ideas.
                    </Content>
                </Card>

                <Card background={'#FEF3F4'}>
                <Icon src={ThumbsDown} />
                <Title color={'#F15062'}>Negative Themes</Title>

                <Content>
                    <strong>Team Collaboration:</strong> 85% of comments highlight a positive sense of teamwork and support among peers.
                    </Content>

                    <Content>
                    <strong>Innovation and Growth:</strong> : 60% mention opportunities for skill growth and the company’s encouragement of innovative ideas.
                    </Content>
                </Card>

                <Card background={'#F2F2F2'}>
                <Icon src={NeutralList} />
                <Title color={'#666D79'}>Neutral Themes</Title>

                <Content>
                    <strong>Team Collaboration:</strong> 85% of comments highlight a positive sense of teamwork and support among peers.
                    </Content>
                </Card>
            </Column>

            <Column>
                <FullCard background={'#F8FAFF'}>
                    <Title2>Actionable Insights</Title2>
                    <OL>
                        <LI><strong>Reinforce Team Collaboration</strong><br/>Highlight collaborative initiatives in company updates.</LI>
                        <LI><strong>Reinforce Team Collaboration</strong><br/>Highlight collaborative initiatives in company updates.</LI>
                        <LI><strong>Reinforce Team Collaboration</strong><br/>Highlight collaborative initiatives in company updates.</LI>
                    </OL>

                </FullCard>
            </Column>
        </Container>

    )

}

export default ThematicSample;

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-family: "Raleway";
`

const Column = styled.div`
    width:calc(50% - 10px);

    `

const Card = styled.div`
    width:100%;
    background-color: ${({ background }) => background};
    margin-bottom: 5px;
    padding:20px;
    border-radius: 5px;
`

const FullCard = styled.div`
    width:100%;
    height: 100%;
    background-color: ${({ background }) => background};
    padding:20px;
    display:flex;
    flex-direction:column;
    border-radius: 5px;
    justify-content:center;
`

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: ${({ color }) => color};
`

const Content = styled.div`
    font-size: 12px;
    margin-top: 10px;
    line-height: 1.4;
    color:#2A3039;
`

const Icon = styled.img`
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
`

const Title2 = styled.div`
    color:#2254AA;
    font-weight:600;
    font-size:20px;
    margin-bottom:20px;
`

const LI = styled.li`
    color:#2A3039;
    font-size:16px;
    margin-bottom:20px;
    line-height:1.6;
`

const OL = styled.ol`
    margin-left:-20px;
`