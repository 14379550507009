import styled, { keyframes } from 'styled-components';
import React from "react";



const Component10 = () =>{


    return (
        <Container>

            <T1>Promoting disagreement in action</T1>
            <Text delay={0.5}>
            A sample of a scenario based learning content, based on your own data.
            </Text>


            <Content delay={1}>
                
                <Description>
                <T2>Scenario 1</T2>
                In a workplace committed to improving psychological safety, promoting disagreement becomes a cornerstone of its culture. A perfect illustration of this principle in action occurred during our recent team meeting. When discussing a critical project strategy, Sarah, one of our team members, raised a dissenting opinion. She eloquently articulated her concerns and offered an alternative approach. Instead of shutting her down or dismissing her ideas, our team leader, Mark, encouraged her to elaborate further. He thanked her for her valuable perspective and encouraged others to weigh in as well. This open and respectful exchange of differing viewpoints not only led to a more comprehensive project plan but also reinforced the idea that dissenting voices are not only welcome but actively sought after. By embracing disagreement in this way, we foster an environment where everyone feels heard, valued, and empowered to contribute their best ideas, ultimately leading to more innovative and successful outcomes.
                </Description>
            </Content>
            

           
           

            
        </Container>
    )
}

export default Component10

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    background-color:#D4F5ED;
    padding:20px;
    border-radius:10px;
    margin-top:20px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    font-weight:bold;
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`


const SelectArea = styled.div`
opacity:0;
animation: ${fadeIn} 1s ease-out forwards;
animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`