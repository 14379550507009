import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Modal, Icon } from "semantic-ui-react";

import format from "date-fns/format";
import { TeamIcon, XIcon, TrashIcon } from "../audit/Icons";
import { Popup } from "semantic-ui-react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useNavigate } from "react-router";
import { UPDATE_EMPLOYEE, SG_ADD_EMPLOYEE_LOG } from "constants/actions";
import ChangeAllSelected from "./ChangeAllSelected";
import ChangeStatusModal from "./ChangeStatusModal";
import { useLocation } from "react-router-dom";

const TableList = ({ EmployeeStatusList, members, categories }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [deactiveReason, setDeactiveReason] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const [selectedUserRow, setSelectedUserRow] = useState([]);

  const [changeUserStatus, setChangeUserStatus] = useState();

  const { get_employee, get_auth } = useSelector(
    (state) => ({
      get_error: state.errors,
      get_auth: state.auth,
      get_employee: state.employees,
    }),
    shallowEqual
  );

  const activateEmployee = () => {
    // Active status = 1
    dispatch({
      type: UPDATE_EMPLOYEE,
      payload: {
        id: selectedUser.id,
        status: 1,
      },
    });
    // SG_ADD_EMPLOYEE_LOG
    dispatch({
      type: SG_ADD_EMPLOYEE_LOG,
      payload: {
        employee: selectedUser.id,
        type_of_log: 2,
        description: {
          Archived: deactiveReason,
        },
      },
    });
    setSelectedUser();
    setShowArchiveModal(false);
    const basePath = location.pathname.split("/").slice(0, -2).join("/");

    navigate(`${basePath}/members/inactive`);
  };

  useEffect(() => {
    if (get_employee && get_auth) {
      const found = get_employee?.userEmp?.id === Number(get_auth?.employee_id);
      if (found) {
        setCurrentUser(get_employee?.userEmp);
      }
    }
  }, [get_employee, get_auth]);

  function FnSelectUser(item) {
    setChangeUserStatus(item);
  }

  function AddToSelected(item) {
    if (selectedUserRow.includes(item)) {
      setSelectedUserRow(selectedUserRow.filter((i) => i !== item));
    } else {
      setSelectedUserRow([...selectedUserRow, item]);
    }
  }
  return (
    <>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <ChangeAllSelected
          EmployeeStatusList={EmployeeStatusList}
          selectedUserRow={selectedUserRow}
          setSelectedUserRow={setSelectedUserRow}
        />
        {members && members.length > 0 && (
          <TableContainer>
            <TopTableRow>
              <TableContentSmall></TableContentSmall>
              <TableHeading>Name</TableHeading>
              <TableHeading>Status</TableHeading>
              <TableHeading>Updated On</TableHeading>
              <TableHeading>Actions</TableHeading>
            </TopTableRow>
            {members &&
              members.map((item, i) => {
                if (item.email) {
                  const dateUpdated = format(
                    new Date(item?.updated_at),
                    "yyyy-MM-dd"
                  );
                  let teamString = "N/A";
                  let popupString = "";
                  const empCategories = categories?.find(
                    (cat) => cat.employee === item.id
                  );
                  if (empCategories?.category.length > 0) {
                    const primaryCategories = empCategories.category.filter(
                      (cat) => cat.priority === "primary"
                    );
                    if (primaryCategories) {
                      teamString =
                        primaryCategories.length > 1
                          ? `${primaryCategories[0]?.value} +${
                              primaryCategories.length - 1
                            }`
                          : primaryCategories[0]?.value;

                      primaryCategories.forEach((cat, i) => {
                        if (i > 0) {
                          if (popupString.length > 0) {
                            popupString = popupString + ", " + cat.value;
                          } else {
                            popupString = cat.value;
                          }
                        }
                      });
                    }
                  }
                  return (
                    <TableRow key={i}>
                      <TableContentSmall>
                        <input
                          // onClick={() => AddToSelected(item?.id)}
                          onChange={() => AddToSelected(item?.id)}
                          type="checkbox"
                          checked={selectedUserRow.includes(item?.id)}
                        />
                      </TableContentSmall>
                      <TableContentName>
                        {item?.first_name
                          ? item?.first_name + " " + item?.last_name
                          : "N/A"}
                      </TableContentName>

                      <TableContentClickable onClick={() => FnSelectUser(item)}>
                        <Icon name="edit" /> {item?.status_text}
                      </TableContentClickable>
                      <TableContent>{dateUpdated}</TableContent>
                      <TableContent>
                        {currentUser?.account_type === 5 && (
                          <Modal
                            onClose={() => {
                              setSelectedUser();
                              setShowArchiveModal(false);
                            }}
                            onOpen={() => {
                              setSelectedUser(item);
                              setShowArchiveModal(true);
                            }}
                            size="tiny"
                            open={showArchiveModal}
                            trigger={
                              <ArchiveButton>Re-activate Profile</ArchiveButton>
                            }
                          >
                            <ModalContents>
                              <ModalHeader>
                                Are you sure you want to re-activate this
                                employee?
                              </ModalHeader>
                              <ModalBody>
                                <InputDescription>
                                  Please enter a reason for re-activating this
                                  user:
                                </InputDescription>
                                <InputContainer>
                                  <RowData
                                    value={deactiveReason}
                                    placeholder="Re-activate reason"
                                    onChange={(s, t) => {
                                      setDeactiveReason(s.target.value);
                                    }}
                                  />
                                </InputContainer>
                                <ModalButtons>
                                  <ArchiveCancelButton
                                    onClick={() => {
                                      setSelectedUser();
                                      setShowArchiveModal(false);
                                    }}
                                  >
                                    <CancelButtonText>Cancel</CancelButtonText>
                                    <CancelButtonIcon>
                                      {XIcon()}
                                    </CancelButtonIcon>
                                  </ArchiveCancelButton>
                                  <ArchiveConfirmButton
                                    onClick={() => activateEmployee(item.id)}
                                  >
                                    Re-activate Profile
                                  </ArchiveConfirmButton>
                                </ModalButtons>
                              </ModalBody>
                            </ModalContents>
                          </Modal>
                        )}
                      </TableContent>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={i}>
                      <TableContentSmall>NA</TableContentSmall>
                      <TableContent>NA</TableContent>
                      <TableContent>NA</TableContent>
                      <TableContent>NA</TableContent>
                      <TableContent></TableContent>
                    </TableRow>
                  );
                }
              })}
          </TableContainer>
        )}
      </div>
      <ChangeStatusModal
        EmployeeStatusList={EmployeeStatusList}
        item={changeUserStatus}
        changeUserStatus={changeUserStatus}
        setChangeUserStatus={setChangeUserStatus}
      />
    </>
  );
};

export default TableList;

const Linked = styled.span`
  text-decoration: none;
  cursor: pointer;
`;

const ElipsesIcon = styled.div`
  line-height: 21.86px;
  margin-right: 5px;
  cursor: pointer;
`;

const TableContainer = styled.div`
  width: 100%;
`;

const WarningIconWrapper = styled.div`
  position: absolute;
  right: 0;
  padding-right: 125px;
  padding-top: 30px;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableHeading = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TableRow = styled.div`
  display: flex;
  background: #ffffff;
  height:30px;
  /* drop */
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
`;

const TeamCol = styled.div`
  flex-direction: row;
  display: flex;
`;

const TeamIconWrapper = styled.div`
  line-height: 21.86px;
  margin-right: 5px;
`;

const TeamText = styled.div`
  line-height: 21.86px;
  flex-direction: column;
`;

const TableContent = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const TableContentSmall = styled.div`
  width: 2%;
  padding-left: 10px;
  text-align: inherit;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const TableContentClickable = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: blue;
  /* Add cursor pointer style to indicate clickability */
  cursor: pointer;

  /* Add hover effect to indicate interactivity */
  &:hover {
    /* Example: Change background color on hover */
    background-color: #f0f0f0;
    /* Change text color on hover */
    color: red; /* You can adjust the color as needed */
  }
`;

const TableContentName = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
`;

const ArchiveButton = styled.div`
  cursor: pointer;
  color: blue;

`;

const SaveIconWrapper = styled.div`
  padding-right: 12px;
  cursor: pointer;
`;

const ModalContents = styled.div`
  background-color: white;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
`;

const ModalHeader = styled.div`
font-family: "Raleway", sans-serif;
padding-bottom: 45px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;

  color: #414141;
`;

const ModalBody = styled.div`
  padding-top: 5px;
`;

const InputDescription = styled.div`
font-family: "Raleway", sans-serif;
font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const InputContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowData = styled.input`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px 12px 32px;
  gap: 10px;
  width: 376px;
  height: 48px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;

  color: #9e9fa1;

  border: 1px solid #dfdfdf;
  border-radius: 99px;
`;

const ArchiveCancelButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 45px;
  cursor: pointer;
`;

const CancelButtonText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #c0c0c0;
  cursor: pointer;
`;

const CancelButtonIcon = styled.div`
  line-height: 15px;
  padding-top: 1px;
  padding-left: 5px;
  cursor: pointer;
`;

const ArchiveConfirmButton = styled.div`
  cursor: pointer;
  color: blue;
`;

const SaveText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;
