import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_POWERPOINT_PRESENTATION,
  DELETE_POWERPOINT_PRESENTATION,
  CREATE_POWERPOINT_PRESENTATION,
  GET_POWERPOINT_PRESENTATION_STATUS,
  SG_GET_POWERPOINT_PRESENTATION,
  SG_DELETE_POWERPOINT_PRESENTATION,
  SG_CREATE_POWERPOINT_PRESENTATION,
  SG_GET_POWERPOINT_PRESENTATION_STATUS,
  CLEAR_POWERPOINT_PRESENTATION,
  POWERPOINT_PRESENTATIONS_PENDING,
  GET_POWERPOINT_PRESENTATION_DOWNLOAD,
  SG_GET_POWERPOINT_PRESENTATION_DOWNLOAD,
  ALL_ERRORS,
} from "constants/actions";

function* loadPresentations(action) {
  yield put({ type: POWERPOINT_PRESENTATIONS_PENDING });
  try {
    const response = yield call(
      api.GET_POWERPOINT_PRESENTATION,
      action.payload
    );
    yield put({ type: GET_POWERPOINT_PRESENTATION, payload: response.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* presentationsLoad() {
  yield takeLatest(SG_GET_POWERPOINT_PRESENTATION, loadPresentations);
}

function* createPresentation(action) {
  yield put({ type: POWERPOINT_PRESENTATIONS_PENDING });
  try {
    const response = yield call(
      api.GENERATE_POWERPOINT_PRESENTATION,
      action.payload
    );
    yield put({ type: CREATE_POWERPOINT_PRESENTATION, payload: response.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* presentationCreate() {
  yield takeLatest(SG_CREATE_POWERPOINT_PRESENTATION, createPresentation);
}

function* deletePresentation(action) {
  yield put({ type: POWERPOINT_PRESENTATIONS_PENDING });
  try {
    yield call(api.DELETE_POWERPOINT_PRESENTATION, action.payload);
    yield put({
      type: DELETE_POWERPOINT_PRESENTATION,
      payload: action.payload,
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* presentationDelete() {
  yield takeLatest(SG_DELETE_POWERPOINT_PRESENTATION, deletePresentation);
}

function* loadPresentationStatus(action) {
  yield put({ type: POWERPOINT_PRESENTATIONS_PENDING });
  try {
    const response = yield call(
      api.GET_POWERPOINT_PRESENTATION_STATUS,
      action.payload
    );
    yield put({
      type: GET_POWERPOINT_PRESENTATION_STATUS,
      payload: response.data,
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* presentationStatusLoad() {
  yield takeLatest(
    SG_GET_POWERPOINT_PRESENTATION_STATUS,
    loadPresentationStatus
  );
}

export function* downloadPresentation(action) {
  try {
    const response = yield call(
      api.GET_POWERPOINT_PRESENTATION_DOWNLOAD,
      action.payload
    );
    yield put({
      type: GET_POWERPOINT_PRESENTATION_DOWNLOAD,
      payload: response?.data?.download_url,
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* presentationDownload() {
  yield takeLatest(
    SG_GET_POWERPOINT_PRESENTATION_DOWNLOAD,
    downloadPresentation
  );
}

export default function* index() {
  yield all([
    presentationsLoad(),
    presentationCreate(),
    presentationDelete(),
    presentationStatusLoad(),
    presentationDownload(),
  ]);
}
