import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/nav/factor.svg'
import Chart from "react-apexcharts";

const Outcomes = () =>{

    return (
        <Container>
            
            <T2>How is the culture impacting my team?</T2>

            <Description>
                Here is a table with the retention, well-being, and engagement scores for your team.
            </Description>


            <div style={{width:'100%',marginBottom:30}}>
            <Chart
						options={data3.options}
						series={data3.series}
						type="bar"
						height={350}
					/>
            </div>

                    

          
        </Container>
    )

}

export default Outcomes

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
    align-items:flex-start;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
    width:100%;
`

const Description = styled.div`
    margin-bottom: 20px;
`
const D2 = styled.div`
    margin-bottom:20px;
`

const IMG = styled.img`
    height: 30px;
    margin-bottom:20px;
`

const LI = styled.li`
    margin-bottom:10px;
`


const data3 = {
	series: [
		{
			data: [6.5, 8.1, 8.7],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800,
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            }
          },
		colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
		xaxis: {
			categories: [
				"Retention",
                "Well-being",
                "Engagement"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 10,
			decimalsInFloat: 1,
			title: {
				text: "",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
