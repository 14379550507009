import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";

const data = {
          
            series: [{
              name: 'Last week',
              data: [1,4,5,2]
            }],
            options: {
              chart: {
                height: 350,
                type: 'bar',
                toolbar:{
                	show:false
                }
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                }
              },
              colors:['#c3a7fd','#6a00ff'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                width: 2
              },
              
              grid: {
                row: {
                  colors: ['#fff', '#f2f2f2']
                }
              },
              xaxis: {
                labels: {
                  rotate: -45
                },
                categories: ['Deliverables','Personal Ownership','Team Accountability','Performance'
                ],
                tickPlacement: 'on'
              },
              yaxis: {
                title: {
                  text: 'Sentiment',
                },
              },
              fill: {
              	colors:['#c3a7fd','#6a00ff'],
                type: 'gradient',
                gradient: {
                  shade: 'light',
                  type: "horizontal",
                  shadeIntensity: 0.25,
                  gradientToColors: undefined,
                  inverseColors: true,
                  opacityFrom: 0.85,
                  opacityTo: 0.85,
                  stops: [50, 0, 100]
                },
              }
            },
            
          };


const Performance = () =>{

	return (
		<Container>
			<Chart options={data.options} series={data.series} type="bar" height={350}/>
		</Container>
		)
}


export default Performance



const Container = styled.div`
	max-width:800px;
	height:600px;
`
