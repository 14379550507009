import styled ,{keyframes} from "styled-components";
import React from "react";
import {Checkbox} from 'semantic-ui-react';
import Data from './Data';

const Questions = () =>{

    const [step,setStep] = React.useState(0)

    const next = () =>{
        setStep(step+1)
    }


    const generate_steps = () =>{

        if(step==1){
            return <Data next={next}/>
        }



        return (<>
            <Title>Who is this recommendation for?</Title>
            <Description>
                Select the groups that you want to apply this recommendation to.
            </Description>

            <Option title="Entire Organization"/>
            <SelectArea>
            <Option title="Location"/>
            <Option title="Department"/>
            <Option title="Team"/>
            <Option title="Role"/>
            </SelectArea>     

            
                <ButtonContainer>
                   
                   <Button onClick={()=>setStep(1)}>Select</Button>
               </ButtonContainer>
               </>
        )
    }

    return (
        <Container>
           
        <Center>
        {generate_steps()}
        </Center>

        </Container>
    )

}

export default Questions;


const Option = ({title}) =>{
    const [active,setActive] = React.useState(false)

    return (
        <OptionContainer active={active}>
            <Checkbox 
            checked={active}
            onChange={()=>setActive(!active)}
            style={{marginRight:10}}/> {title}
        </OptionContainer>
    )


}



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    min-height:calc(100vh - 75px);
    padding-bottom:100px;
`

const Center = styled.div`
    width:800px;
    opacity:0;
    margin-top:50px;

    animation: ${fadeIn} 0.5s ease-in-out forwards;
    
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`
    font-size:14px;
    color:#666;
    line-height:1.4;
    margin-bottom:20px;
`


const SelectArea = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin-top:20px;
    width:100%;
    justify-content:space-between;
`

const OptionContainer = styled.div`
    width:calc(33% - 5px);
    display:flex;
    align-items:center;
    justify-content:flex-start;
    font-family:'Raleway';
    font-size:14px;
    font-weight:600;
    border:1px solid #E0E0E0;
    border-radius:5px;
    padding:10px;
    margin-bottom:5px;

    background-color:${({active})=>active?'#D5E2F9':'#fff'};
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;
`


const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:flex-end;
    align-items:center
    width:100%;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: 1s;

`

const Button = styled.div`
    color:#2D70E2;
    border:1px solid #2D70E2;
    font-size:16px;
    padding:10px 20px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';

`