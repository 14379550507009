import { call, put, takeEvery, all } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_USER_TASK_RESPONSE,
  SG_GET_USER_TASK_RESPONSE,
  ADD_USER_TASK_RESPONSE,
  SG_ADD_USER_TASK_RESPONSE,
  UPDATE_USER_TASK_RESPONSE,
  SG_UPDATE_USER_TASK_RESPONSE,
  ALL_ERRORS,
} from "constants/actions";

function* getUserTaskResponse(action) {
  try {
    const json = yield call(api.GET_USER_TASK_RESPONSE, action.payload);
    yield put({ type: GET_USER_TASK_RESPONSE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userTaskResponse() {
  yield takeEvery(SG_GET_USER_TASK_RESPONSE, getUserTaskResponse);
}

function* addUserTaskResponse(action) {
  try {
    const json = yield call(api.ADD_USER_TASK_RESPONSE, action.payload);
    yield put({ type: ADD_USER_TASK_RESPONSE, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userTaskResponseCreate() {
  yield takeEvery(SG_ADD_USER_TASK_RESPONSE, addUserTaskResponse);
}

function* updateUserTaskResponse(action) {
  try {
    const json = yield call(api.UPDATE_USER_TASK_RESPONSE, action.payload);
    yield put({ type: UPDATE_USER_TASK_RESPONSE, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* userTaskResponseUpdate() {
  yield takeEvery(SG_UPDATE_USER_TASK_RESPONSE, updateUserTaskResponse);
}

export default function* rootSaga() {
  yield all([
    userTaskResponse(),
    userTaskResponseCreate(),
    userTaskResponseUpdate(),
  ]);
}
