import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SG_CREATE_DEBRIEF_PDF_REPORT } from "constants/actions";
import { Icon as SemanticIcon } from "semantic-ui-react";

const getCategories = (categories) => {
  return categories.map(({ id, name, priority }) => ({
    id,
    name,
    priority,
  }));
};

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const createPrimaryOptions = (categories) => {
  const primaryList = categories?.[0]?.categories?.find(
    (category) => category.priority === "primary"
  );

  // Format the options so they work for the dropdown.
  const primaryOptions = primaryList?.options?.map((primary) => ({
    category_text: null,
    category_value: null,
    category_name: null,
    category_name_id: primaryList?.id,
    category_value_id: null,
    primary_name: primary.name,
    primary_value: primaryList.name,
    primary_name_id: primary.id,
    primary_value_id: primaryList.id,
    display_text: primary.name,
  }));

  return primaryOptions;
};

const createOptions = (perms) => {
  const primaryCategories = perms?.employee_employee_category?.category?.filter(
    (item) => item.priority === "primary"
  );

  const permissionDetails = perms?.employee_employee_permission?.details;
  const output = [];

  permissionDetails?.forEach((permission) => {
    primaryCategories?.forEach((primary) => {
      output.push({
        category_text: permission.text,
        category_value: permission.value,
        category_name: permission.name,
        category_name_id: permission.name_id,
        category_value_id: permission.value_id,
        primary_name: primary.name,
        primary_value: primary.value,
        primary_name_id: primary.name_id,
        primary_value_id: primary.value_id,
        display_text: `${primary.value} - ${permission.text}`,
      });
    });

    if (permissionDetails?.length > 1) {
      output.push({
        category_text: permission.text,
        category_value: permission.value,
        category_name: permission.name,
        category_name_id: permission.name_id,
        category_value_id: permission.value_id,
        primary_name: null,
        primary_value: null,
        primary_name_id: null,
        primary_value_id: null,
        display_text: null,
      });
    }
  });

  if (permissionDetails?.length === 0 || !permissionDetails) {
    primaryCategories?.forEach((primary) => {
      output.push({
        category_text: null,
        category_value: null,
        category_name: null,
        category_name_id: null,
        category_value_id: null,
        primary_name: primary.name,
        primary_value: primary.value,
        primary_name_id: primary.name_id,
        primary_value_id: primary.value_id,
        display_text: primary.value,
      });
    });
  }

  return output;
};

const NewCreate = ({ filteredOptions, setReportOptions }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [visible, setVisible] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const [showAdditional, setShowAdditional] = useState(false);
  const [options, setOptions] = useState([]);
  const [payload, setPayload] = useState({});

  const { coreData, get_organizations, get_auth, get_employee } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      get_organizations: state.organizations,
      get_auth: state.auth,
      get_employee: state.employees,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (coreData?.permissions?.account_type !== 5) {
      setOptions(createOptions(coreData?.permissions));
    } else {
      setOptions(createPrimaryOptions(coreData?.categories));
    }
  }, [coreData?.permissions]);

  useEffect(() => {
    // When showAdditional is toggled, clear the selected options and breadcrumbs and payload
    setSelectedOption(null);
    setSelectedCategory(null);
    setSelectedCategoryOptions([]);
    setBreadcrumbs([]);
    setPayload({});
  }, [showAdditional]);

  useEffect(() => {
    if (
      coreData?.categories?.length > 0 &&
      coreData.categories?.[0].categories
    ) {
      setCategoryOptions(getCategories(coreData.categories?.[0].categories));
    }
  }, [coreData]);

  const handleBreadcrumbClick = (index) => {
    if (index === 0) {
      setCurrentStep(0);
      setSelectedOption(null);
      setSelectedCategory(null);
      setSelectedCategoryOptions([]);
      setBreadcrumbs([]);
      return;
    }
    setCurrentStep(index);
    setBreadcrumbs(breadcrumbs.slice(0, index));
  };

  const handleOptionSelect = (index) => {
    const selected = options[index];
    // If the option is already selected, deselect it
    if (selectedOption?.display_text === selected?.display_text) {
      setSelectedOption(null);
      // Also update the payload and breadcrumbs (if needed)
      setPayload({});
      setBreadcrumbs([]);
      setCurrentStep(0);

      return;
    }

    // Set payload based on first option if detailed_perms exist
    const uuid = uuidv4();
    const report = {
      template_id: 7,
      organization: Number(get_organizations?.organization?.id),
      tracking_code: uuid,
      employee: Number(get_auth?.employee_id),
      params: {
        primary_list: [],
        parent_id: selected?.category_name_id,
        parent_children: selected?.primary_name_id
          ? [selected?.primary_name_id]
          : [],
        pdfOptions: {},
        includeWarning: false,
        orgName: get_organizations?.organization?.name,
      },
    };

    setPayload(report);
    setSelectedOption(selected);
    // Dont re-add the same breadcrumb
    if (breadcrumbs.length > 0) {
      if (breadcrumbs[breadcrumbs.length - 1].name === selected.display_text) {
        return;
      }
    }

    // Only add the breadcrumb to the first step.
    // if there is already a selectedOption, replace it
    // if there is no selectedOption, add it to the beginning of the breadcrumbs
    if (selectedOption) {
      // Replace the first breadcrumb
      const newBreadcrumbs = [...breadcrumbs];
      newBreadcrumbs[0] = {
        name: selected.display_text || selected.category_text,
      };

      setBreadcrumbs(newBreadcrumbs);
    } else {
      setBreadcrumbs([
        { name: selected.display_text || selected.category_text },
        ...breadcrumbs,
      ]);
    }
  };

  const handleCategorySelect = (id, name) => {
    setSelectedCategory(id);
    setReportOptions(id);
    setVisible(false);
    setBreadcrumbs([...breadcrumbs, { name }]);

    setTimeout(() => {
      setVisible(true);
      setCurrentStep(2); // Move to the final step
    }, 1000);
  };

  const handleCategoryOptionsSelect = (id) => {
    if (selectedCategoryOptions.includes(id)) {
      setSelectedCategoryOptions(
        selectedCategoryOptions.filter((option) => option !== id)
      );
    } else {
      setSelectedCategoryOptions([...selectedCategoryOptions, id]);
    }
  };

  useEffect(() => {
    if (
      coreData?.permissions?.employee_employee_permission?.details?.length ===
        0 ||
      coreData?.permissions?.account_type === 5
    ) {
      const report = {
        template_id: 7,
        organization: Number(coreData?.permissions?.organization),
        employee: Number(coreData?.permissions?.id),
        tracking_code: uuidv4(),
      };

      if (selectedCategory) {
        setPayload({
          ...report,
          params: {
            ...payload.params,
            parent_id: selectedCategory,
            primary_list: [],
          },
        });
      }

      if (selectedCategoryOptions.length > 0) {
        setPayload({
          ...report,
          params: {
            ...payload.params,
            parent_children: selectedCategoryOptions,
            primary_list: [],
          },
        });
      }
    }
  }, [selectedCategoryOptions, selectedCategory]);

  const handleCreate = () => {
    if (selectedCategoryOptions.length > 0 || selectedOption) {
      const report = {
        ...payload,
        params: {
          ...payload.params,
          pdfOptions: {},
          includeWarning: false,
          orgName: get_organizations?.organization?.name,
        },
      };

      dispatch({
        type: SG_CREATE_DEBRIEF_PDF_REPORT,
        payload: report,
      });

      navigate("/leader/audit/AI/insights");
    }
  };

  const renderGroupArea = (showAdditionalOptions) => {
    if (showAdditionalOptions) {
      if (currentStep < 2) {
        return (
          <SecondGroupContainer visible={visible} animation={slideInFromRight}>
            <GroupTitle>Select a target category</GroupTitle>
            <GroupArea animation={slideInFromRight}>
              {categoryOptions?.map((category, index) => (
                <Group
                  key={category.id}
                  selected={selectedCategory === category.id}
                  onClick={() =>
                    handleCategorySelect(category.id, category.name)
                  }
                >
                  {category.name}
                </Group>
              ))}
            </GroupArea>
          </SecondGroupContainer>
        );
      } else {
        return (
          <GroupContainer visible={visible} animation={slideInFromRight}>
            <GroupTitle>Specify a category option</GroupTitle>
            <GroupArea animation={slideInFromRight}>
              {filteredOptions?.map((option) => (
                <Group
                  key={option.id}
                  selected={selectedCategoryOptions.includes(option.id)}
                  onClick={() =>
                    handleCategoryOptionsSelect(option.id, option.name)
                  }
                >
                  {option.name}
                </Group>
              ))}
            </GroupArea>
          </GroupContainer>
        );
      }
    } else {
      return (
        <FirstGroupContainer visible={visible} animation={fadeIn}>
          <SelectionWrapper>
            <SelectionGroup>
              <GroupArea animation={slideInFromRight}>
                {options?.map((option, index) => (
                  <Group
                    key={index}
                    onClick={() => handleOptionSelect(index)}
                    selected={
                      selectedOption?.display_text === option?.display_text
                    }
                  >
                    {option?.display_text || option?.category_text}
                  </Group>
                ))}
              </GroupArea>
            </SelectionGroup>
          </SelectionWrapper>
        </FirstGroupContainer>
      );
    }
  };

  return (
    <Container>
      <Powered>
        <Icon>{aiIcon("#2D70E2")} </Icon>Powered by generative AI and culture
        experts
      </Powered>
      <Title>
        Generate a <Blue>Story</Blue>
      </Title>
      <Description>
        Select a group to focus your data story on. Innerlogic will use AI to
        generate the key themes, insights, and trends based on all the data
        available.
        <Blue2> Learn more about what a data story is here</Blue2>.
      </Description>
      {showAdditional && (
        <Breadcrumb>
          {breadcrumbs.map((crumb, index) => (
            <BreadcrumbItem
              key={index}
              onClick={() => handleBreadcrumbClick(index)}
            >
              {crumb.name} {index < breadcrumbs.length - 1 && "->"}
            </BreadcrumbItem>
          ))}
          {breadcrumbs.length > 0 && currentStep !== 0 && (
            <BreadCrumbNextItem>
              {breadcrumbs?.length > 0 &&
                breadcrumbs?.length < 3 &&
                "-> Select a target"}
            </BreadCrumbNextItem>
          )}
        </Breadcrumb>
      )}
      {renderGroupArea(showAdditional)}
      {(coreData?.permissions?.employee_employee_permission?.details?.length ===
        0 ||
        coreData?.permissions?.account_type === 5) && (
        <ShowAdditional onClick={() => setShowAdditional(!showAdditional)}>
          Show additional options{" "}
          {showAdditional ? (
            <SemanticIcon name="chevron up" />
          ) : (
            <SemanticIcon name="chevron down" />
          )}
        </ShowAdditional>
      )}
      <ButtonArea>
        <Button
          onClick={handleCreate}
          active={selectedCategoryOptions.length > 0 || selectedOption}
        >
          Build story for selection
        </Button>
      </ButtonArea>
    </Container>
  );
};;

export default NewCreate;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const Title = styled.div`
  font-size: 70px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.2;
`;

const ShowAdditional = styled.div`
  font-size: 14px;
  color: #2d70e2;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.3s;
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  flex-wrap: wrap;
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
`;

const SeparatorText = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #666;
`;

const SelectionWrapper = styled.div`
  display: flex;
  align-items: flex-start; /* Aligns the selection groups at the top */
`;

const Blue = styled.span`
  color: #2d70e2;
  font-weight: 800;
`;

const Blue2 = styled.span`
  color: #666d79;
  font-weight: 600;
  cursor: pointer;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.4;
  max-width: 600px;
  color: #666;
  text-align: center;
  line-height: 1.6;
`;

const Powered = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 20px;
  font-weight: 600;
  padding: 5px 30px;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  display: flex;
  align-items: center;
`;

const GroupArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 0.3s;
  animation: ${(props) => (props.animation ? props.animation : "")} 1s ease-in;
`;
const Group = styled.div`
  padding: 4px 8px;
  background-color: #ebf1fd;
  border: ${(props) =>
    props.selected ? "2px solid #2D70E2" : "2px solid #EBF1FD"};
  font-weight: 600;
  color: #2d70e2;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const GroupRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

const ButtonArea = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Breadcrumb = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const BreadcrumbItem = styled.div`
  margin-right: 5px;
  color: #2d70e2;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
`;

const BreadCrumbNextItem = styled.div`
  margin-right: 5px;
  color: #666;
  font-weight: 600;
`;

const CategoryTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Button = styled.div`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: white;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    background-color: #1c4db8;
  }

  opacity: ${(props) => (!props.active ? "0.5" : "1")};
  cursor: ${(props) => (!props.active ? "not-allowed" : "pointer")};
`;

const SeeMore = styled.div`
  font-size: 12px;
  color: #2d70e2;
  font-weight: 600;
  margin-top: 0px;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 20px;
  &:hover {
    color: #1c4db8;
  }
`;

const Icon = styled.span`
  margin-right: 6px;
  margin-bottom: -4px;
`;

const GroupTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
`;

const GroupContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  animation: ${(props) => (props.animation ? props.animation : "")} 1s ease-out;
`;

const FirstGroupContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  animation: ${(props) => (props.animation ? props.animation : "")} 1s ease-in;
`;

const SelectionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const SecondGroupContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  animation: ${(props) => (props.animation ? props.animation : "")} 1s ease-out;
`;

function aiIcon(fill) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3111 14.0751C15.1214 14.0751 14.9617 14.0122 14.8319 13.8863C14.7021 13.7605 14.6372 13.6056 14.6372 13.4217V7.19323C14.6372 7.00933 14.7021 6.85447 14.8319 6.72864C14.9617 6.59313 15.1214 6.52538 15.3111 6.52538C15.5108 6.52538 15.6755 6.58829 15.8053 6.71412C15.9351 6.83995 16 6.99965 16 7.19323V13.4217C16 13.6056 15.9301 13.7605 15.7903 13.8863C15.6605 14.0122 15.5008 14.0751 15.3111 14.0751Z"
        fill={fill}
      />
      <path
        d="M14.8265 5.03815C14.9526 5.16048 15.1041 5.22165 15.2807 5.22165H15.3564C15.5331 5.22165 15.6845 5.16048 15.8107 5.03815C15.9369 4.91581 16 4.76901 16 4.59774V4.54881C16 4.37754 15.9369 4.23074 15.8107 4.10841C15.6845 3.98607 15.5331 3.9249 15.3564 3.9249H15.2807C15.1041 3.9249 14.9526 3.98607 14.8265 4.10841C14.7003 4.23074 14.6372 4.37754 14.6372 4.54881V4.59774C14.6372 4.76901 14.7003 4.91581 14.8265 5.03815Z"
        fill={fill}
      />
      <path
        d="M0.688892 3.92805C0.878587 3.92805 1.03833 3.99092 1.16812 4.11666C1.29791 4.2424 1.36281 4.39715 1.36281 4.58093V10.805C1.36281 10.9888 1.29791 11.1435 1.16812 11.2693C1.03833 11.4047 0.878587 11.4724 0.688892 11.4724C0.489213 11.4724 0.324477 11.4095 0.194685 11.2838C0.0648939 11.1581 0 10.9985 0 10.805V4.58093C0 4.39715 0.0698868 4.2424 0.209662 4.11666C0.339454 3.99092 0.499197 3.92805 0.688892 3.92805Z"
        fill={fill}
      />
      <path
        d="M1.17355 12.9594C1.04736 12.8372 0.895935 12.7761 0.719272 12.7761H0.643561C0.466899 12.7761 0.315473 12.8372 0.189285 12.9594C0.0630978 13.0817 4.02553e-06 13.2284 4.02553e-06 13.3995V13.4484C4.02553e-06 13.6196 0.0630978 13.7663 0.189285 13.8885C0.315473 14.0108 0.466899 14.0719 0.643561 14.0719H0.719272C0.895935 14.0719 1.04736 14.0108 1.17355 13.8885C1.29974 13.7663 1.36283 13.6196 1.36283 13.4484V13.3995C1.36283 13.2284 1.29974 13.0817 1.17355 12.9594Z"
        fill={fill}
      />
      <path
        d="M10.9786 1.9657C10.9786 1.60086 11.2836 1.3051 11.66 1.3051C12.0363 1.3051 12.3414 1.60086 12.3414 1.9657V16.0343C12.3414 16.3991 12.0363 16.6949 11.66 16.6949C11.2836 16.6949 10.9786 16.3991 10.9786 16.0343V1.9657Z"
        fill={fill}
      />
      <path
        d="M3.66131 1.9657C3.66131 1.60086 3.96638 1.3051 4.34271 1.3051C4.71904 1.3051 5.02412 1.60086 5.02412 1.9657V16.0343C5.02412 16.3991 4.71904 16.6949 4.34271 16.6949C3.96638 16.6949 3.66131 16.3991 3.66131 16.0343V1.9657Z"
        fill={fill}
      />
      <path
        d="M7.31992 0.660599C7.31992 0.29576 7.625 0 8.00133 0C8.37766 0 8.68273 0.29576 8.68273 0.660599V7.68763C8.68273 8.05247 8.37766 8.34823 8.00133 8.34823C7.625 8.34823 7.31992 8.05247 7.31992 7.68763V0.660599Z"
        fill={fill}
      />
      <path
        d="M7.31992 10.3124C7.31992 9.94753 7.625 9.65177 8.00133 9.65177C8.37766 9.65177 8.68273 9.94753 8.68273 10.3124V17.3394C8.68273 17.7042 8.37766 18 8.00133 18C7.625 18 7.31992 17.7042 7.31992 17.3394V10.3124Z"
        fill={fill}
      />
    </svg>
  );
}

const FilteredOptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: ${slideIn} 0.5s ease-out;
`;

const BackButton = styled.button`
  margin-bottom: 20px;
  background-color: #2d70e2;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
`;