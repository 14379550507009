import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";


function formatForApexChart(data) {
    // Check if data is empty
    if(data.length === 0) {
        return { categories: [], data: [], scoreName: '' };
    }

    // Obtain the keys
    const keys = Object.keys(data[0]);
    const categoryKey = keys[0];
    const dataKey = keys[1];

    // Sort the data from highest to lowest based on the data value
    data.sort((a, b) => b[dataKey] - a[dataKey]);

    // Extract the categories, data, and score name
    const categories = data.map(item => item[categoryKey]);
    const dataValues = data.map(item => item[dataKey]);
    const scoreName = dataKey;

    return {
        categories: categories,
        data: dataValues,
        scoreName: scoreName
    };
}

const ChatChart = ({ data }) => {
    const [chart, setChart] = useState(Sample.options);
    const [series, setData] = useState(Sample.series);

    useEffect(() => {
        if (data) {
            const formattedData = formatForApexChart(data);
            setChart({
                ...chart,
                xaxis: {
                    ...chart.xaxis,
                    categories: formattedData.categories
                },
                yaxis:{
                    ...chart.yaxis,
                    title:{
                        ...chart.yaxis.title,
                        text: formattedData.scoreName + ' Scores'
                    }
                }
            });
            setData([
                {
                    data: formattedData.data,
                    name: formattedData.scoreName
                }
            ]);
        }
    }, [data]);


    return (
        <ChartContainer>
            <Title>Figure 1: Departmental Trust Scores in a Contemporary Organization.</Title>
            <Description>
                This table encapsulates the trust scores attributed to various departments and functional units within a modern enterprise. Scores are presumably derived on a scale, with higher values indicating increased levels of trust. Insights gleaned from this data provide a nuanced understanding of inter-departmental trust perceptions, potentially highlighting areas for organizational improvement and fostering collaboration. Notably, variations in scores can serve as a basis for further investigation into departmental dynamics and their implications on overall organizational health.
            </Description>

             <Chart
            options={chart}
            series={series}
            type="bar"
            height={"300px"}
            />

        </ChartContainer>
       
    )
}

export default ChatChart;

const ChartContainer = styled.div`
    width:800px;
    height:400px;
`

const Title = styled.div`
color:#2A3039;
font-family: Raleway;
font-size: 20px;
font-weight:600;
margin-bottom:10px;
`

const Description = styled.div`
font-family: Raleway;
font-size: 14px;
color: #666D79;
`

const Sample = {
	series: [
		{
			name: "Average Score",
			data: [8.2, 8.5, 9, 7.6],
		},
	],
	options: {
		chart: {
			type: "line",
			toolbar: {
				show: false,
			},
		},
		colors: ["#6a00ff", "#3078ff"],
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			categories: [
				"2017",
				"2018",
				"2019",
				"2020",
				"2021",
				"2022",
				"2023",
			],
		},
		yaxis: {
			show: true,
			title: {
				text: "Score",
				style:{
					fontWeight:400
				}
			},
			min: 0,
			max: 10,
			decimalsInFloat: 1,
		},
	},
};