export function TrendDown(fill) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 6C15.2761 6 15.5 6.22386 15.5 6.5V15H7C6.72386 15 6.5 14.7761 6.5 14.5C6.5 14.2239 6.72386 14 7 14H14.5V6.5C14.5 6.22386 14.7239 6 15 6Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L15.3536 14.1464C15.5488 14.3417 15.5488 14.6583 15.3536 14.8536C15.1583 15.0488 14.8417 15.0488 14.6464 14.8536L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z"
        fill={fill}
      />
    </svg>
  );
}

export function TrendUp(fill) {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9C15.2761 9 15.5 8.77614 15.5 8.5V2.38419e-07H7C6.72386 2.38419e-07 6.5 0.223858 6.5 0.5C6.5 0.776143 6.72386 1 7 1H14.5V8.5C14.5 8.77614 14.7239 9 15 9Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.646447 14.8536C0.841709 15.0488 1.15829 15.0488 1.35355 14.8536L15.3536 0.853554C15.5488 0.658291 15.5488 0.341709 15.3536 0.146446C15.1583 -0.0488155 14.8417 -0.0488155 14.6464 0.146446L0.646447 14.1464C0.451184 14.3417 0.451184 14.6583 0.646447 14.8536Z"
        fill={fill}
      />
    </svg>
  );
}

export function CloseIcon(fill) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6569 17.6569L6.34319 6.34315"
        stroke="#666D79"
        strokeLinecap="round"
      />
      <path
        d="M17.6568 6.34315L6.34311 17.6569"
        stroke="#666D79"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function FilterIcon(fill) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99975 9.26137H7.49975C7.49975 9.14363 7.4582 9.02966 7.38241 8.93955L6.99975 9.26137ZM1.41042 2.61548L1.79308 2.29366L1.41042 2.61548ZM16.6457 2.61548L16.2631 2.29366L16.6457 2.61548ZM10.9998 9.32874L10.6171 9.00691C10.5413 9.09702 10.4998 9.21099 10.4998 9.32874H10.9998ZM10.9998 14L11.2998 14.4C11.4257 14.3056 11.4998 14.1574 11.4998 14H10.9998ZM6.99975 17H6.49975C6.49975 17.1894 6.60675 17.3625 6.77615 17.4472C6.94554 17.5319 7.14824 17.5136 7.29975 17.4L6.99975 17ZM7.38241 8.93955L1.79308 2.29366L1.02776 2.93731L6.61709 9.5832L7.38241 8.93955ZM1.79308 2.29366C1.51955 1.96841 1.75077 1.47183 2.17574 1.47183V0.471832C0.900811 0.471832 0.207153 1.96158 1.02776 2.93731L1.79308 2.29366ZM2.17574 1.47183H15.8804V0.471832H2.17574V1.47183ZM15.8804 1.47183C16.3054 1.47183 16.5366 1.96841 16.2631 2.29366L17.0284 2.93731C17.849 1.96158 17.1553 0.471832 15.8804 0.471832V1.47183ZM16.2631 2.29366L10.6171 9.00691L11.3824 9.65056L17.0284 2.93731L16.2631 2.29366ZM10.4998 9.32874V14H11.4998V9.32874H10.4998ZM10.6998 13.6L6.69975 16.6L7.29975 17.4L11.2998 14.4L10.6998 13.6ZM7.49975 17V9.26137H6.49975V17H7.49975Z"
        fill="#666D79"
      />
    </svg>
  );
}