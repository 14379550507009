import React, { useState, useEffect } from 'react'
import { Menu } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom';



function MemberMenu({Menulist}) {
    const location = useLocation()
    let navigate = useNavigate();

    function handleItemClick(e) {
        setActiveItem(e)
        navigate(e)

    }

    const [activeItem, setActiveItem] = useState(location.pathname)

    useEffect(() => {
        setActiveItem(location.pathname)
    }, [location.pathname]);

    // console.log(location.pathname)
    if (location.pathname === '/core/members') {
        return null
    }

    return (
        <Menu secondary>
            <Menu.Item
                    name="home"
                    active={activeItem === '/core/members'}
                    onClick={() => handleItemClick('/core/members')}
                />
          {Menulist.filter(m => m.visible === true).map(m => {
            return <Menu.Item
                    name={m.name}
                    active={activeItem === m.url}
                    onClick={() => handleItemClick(m.url)}
                    key={m.url}
                />
          })}

                
        </Menu>
    )

}

export default MemberMenu