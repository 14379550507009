import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import {Modal,Button,Icon} from 'semantic-ui-react'

const PermissionsModal = ({core_data}) => {
    const [open, setOpen] = React.useState(false)


    return (
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        trigger={ <Container>
           See data permissions <Icon name='lock' style={{marginTop:-7,marginLeft:3}}/>
        </Container>}
      >
        <Modal.Content>
        <Title>Your data permissions</Title>
        <Description>The following data access permissions have been assigned to your account:</Description>

        {core_data?.permissions?.employee_employee_permission?.details?.map((item,index)=>{
            
            return <Permission>{item.name} / {item.value}</Permission> })}
        </Modal.Content>

        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
                Close
            </Button>
        </Modal.Actions>

        </Modal>
            
    )

}

export default PermissionsModal;


const Container = styled.div`
    color:#2D70E2;
    font-family: Raleway;
    position:absolute;
    right:0;
    top:0;
    cursor:pointer;
    display:flex;
    align-items:center;
`

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: Raleway;
`

const Permission = styled.div`
    font-size: 14px;
    margin-bottom: 5px;
    font-family: Raleway;
`

const Description = styled.div`
    font-size: 14px;
    margin-bottom: 20px;
    font-family: Raleway;
`