import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {Icon} from "semantic-ui-react";
import Logo from "assets/images/il-logo.png";
import SourceSelection from "./SourceSelection";
import { set } from "date-fns";

// Array of inspirational messages
const messages = [
    "Every question sparks insight. Let’s shape your culture together.",
    "Great cultures start with great conversations – what’s on your mind?",
    "Your journey to transformation begins here. Let’s dive in.",
    "Empowering your team starts with understanding. Ask away!",
    "One conversation can ignite lasting change. What will yours be?",
    "Curiosity fuels progress – let’s uncover your next big breakthrough.",
    "Culture evolves one insight at a time. What do you need clarity on?",
    "Your culture’s potential is limitless. Let’s explore the possibilities.",
    "Ask with purpose, act with impact. Your culture starts here.",
    "Turn questions into actionable insights – let’s shape the future together."
];

// Function to randomly select a message
function getRandomMessage() {
    const randomIndex = Math.floor(Math.random() * messages.length);
    return messages[randomIndex];
}


const DataSource = () =>{
    const [title, setTitle] = useState("");
    const [selectData, setSelectData] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(!title){
            setTitle(getRandomMessage());
            setTimeout(()=>{
                setLoading(false);
            }, 5000);
        }
    }, []);

    const removeSource = (index) =>{
        const newData = selectedData.filter((data, i) => i !== index);
        setSelectedData(newData);
    }

    if(selectData){
        return <SourceSelection goBack={()=>setSelectData(false)} setSelectedData={setSelectedData} selectedData={selectedData}/>
    }

    return (
        <Container>
            <Content>
                <LogoIMG src={Logo} />
                <Title><TypingEffect message={title} loading={loading}/></Title>

                {selectedData.map((data, index) => {

                    return <Source>{data.name} <Icon name="close"  onClick={()=>removeSource(index)} style={{cursor:'pointer'}}/></Source>
                })}


                <TextInput
                    placeholder="Message your copilot"
                />

                <ActionArea>
                <TextTag onClick={()=>setSelectData(true)}>
                    <Icon name="plus"/> 
                Add data sources </TextTag>

                <Tag>Select a prompt <Icon name="chevron right"/></Tag>
                </ActionArea>
                
            </Content>
        </Container>
    )
}

export default DataSource;

// React component
const TypingEffect = ({ message,loading }) => {
    const [displayedText, setDisplayedText] = useState("");
    const [showCursor, setShowCursor] = useState(false);
  
    useEffect(() => {

        if(!loading){
            return setDisplayedText(message);
        }

      let index = 0;
  
      const startTyping = () => {
        const typeMessage = () => {
          if (index < message.length) {
            setDisplayedText(message.slice(0, index + 1));
            index++;
            setTimeout(typeMessage, 30); // Adjust typing speed (in ms)
          } else {
            setShowCursor(false); // Remove the cursor when typing is done
          }
        };
  
        setShowCursor(true); // Show cursor when typing starts
        typeMessage();
      };
  
      const delayTimeout = setTimeout(startTyping, 2000); // 2-second delay
      return () => clearTimeout(delayTimeout); // Cleanup timeout on unmount
    }, [message]);
  
    return (
      <TypingMessage>
        {displayedText}
        {showCursor && <Cursor />}
      </TypingMessage>
    );
  };
const Container = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height:100vh;
    width:100%;
`

const Content = styled.div`
    max-width:700px;
    width:100%;
`

const Title = styled.div`
    font-family: 'Raleway';
    font-size:30px;
    font-weight:bold;
    margin-bottom:20px;
    color:#2A3039;
    width:100%;

    line-height:1.2;
`

const TextInput = styled.input`
    width:100%;
    height:60px;
    border-radius:8px;
    border:1px solid #DFDFDF;
    padding:10px;
    font-family: 'Raleway';
    font-size:14px;
    color:#2A3039;
    margin-top:10px;
    width:100%;

`

const Tag = styled.div`
    display:inline-block;
    background-color:#EBF1FD;
    color:#2D70E2;
    padding:10px 20px;
    margin-right:5px;
    margin-bottom:5px;
    border-radius:4px;
    font-size:14px;
    font-family: 'Raleway';
    font-weight:600;
    cursor:pointer;
`

const TextTag = styled.div`
    display:inline-block;
    color:#2D70E2;
    margin-right:5px;
    margin-bottom:5px;
    font-size:14px;
    font-family: 'Raleway';
    font-weight:600;
    cursor:pointer;
`

const ActionArea = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-top:20px;
    width:100%;
`

// Blinking cursor animation
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

// Styled components
const TypingMessage = styled.div`
  white-space: pre-wrap;
`;

const Cursor = styled.span`
  display: inline-block;
  width: 4px; /* Adjust for better visibility */
  height: 22px;
  background-color: black; /* Black cursor for visibility */
  margin-left: 2px; /* Small gap between text and cursor */
  animation: ${blink} 0.5s step-end infinite;
`;

const LogoIMG = styled.img`
    width:100px;
    margin-bottom:20px;
`

const Source = styled.div`
  border:1px solid #1D9A7D;
    color:#1D9A7D;
    padding:4px 8px;
    margin-right:5px;
    margin-bottom:5px;
    border-radius:8px;
    font-size:14px;
    display:inline-block;
    font-weight:600;
   
`