import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'
import LogoIMG from 'assets/images/logo_full.png'


const Component2 = () =>{

    return (
        <Container>
            <IMG src={LogoIMG}/>
            <T1>Time to generate your plan.</T1>
            <Text>
                Staying engaged with your actions is the key to creating change. Here are some of the benefits of staying engaged.
            </Text>

            <CardContainer>
                <Card delay={0.5}>
                    <IconContainer>
                        <Icon name="download" style={{margin:0,marginTop:-4}}/>
                    </IconContainer>
                    
                    <T2>
                       <strong>30% more</strong> likely to achieve your quarterly objectives.
                    </T2>
                </Card>

                <Card delay={1}>
                    <IconContainer>
                        <Icon name="level up alternate" style={{margin:0,marginTop:-4}}/>
                    </IconContainer>
                    
                    <T2>
                        An average <strong>reduction of 20% </strong>wasted meetings.
                    </T2>
                </Card>

                <Card delay={1.5}>
                    <IconContainer>
                        <Icon name="sort amount down" style={{margin:0,marginTop:-4}}/>
                    </IconContainer>
                    
                    <T2>
                       <strong>5 hours</strong> less time spent on conflict resolution per week.
                    </T2>
                </Card>
               
            </CardContainer>
        </Container>
    )
}

export default Component2

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
`

const IMG = styled.img`
    width:120px;
    margin-bottom:20px;
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    line-height:1.5;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`

const CardContainer = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    margin-top:20px;
`

const Card = styled.div`
    width:100%;
    padding:25px;
    display:flex;
    flex-direction:row;
    align-items:center;
    background-color:#EBF1FD;
    margin-right:20px;
    border-radius:10px;
    margin-top:20px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
`

const IconContainer = styled.div`
    width:30px;
    height:30px;
    background-color:#2A3039;
    color:white;
    border-radius:50%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:20px;
    min-width:30px;
`