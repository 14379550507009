import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import Footer from '../components/Footer'
import Side from '../components/Side'

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2E34D0",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
});

const TableTemplate = {
	rows:[
			{'title':'Question',width:'46%'},
			{'title':'Factor',width:'15%'},
			{'title':'Anchor',width:'15%'},
			{'title':'Norm',width:'8%'},
			{'title':'Change',width:'8%'},
			{'title':'Score',width:'8%'},
		],
	data:[
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','96%'],
		['Maltreatment (bullying, harassment,abuse, discrimination) is tolerated.','Advancement','Orientation (Trans)','--','--','85%'],
		['We are not kept informed on important issues.','Compensation','Ethnicity (Asian)','--','--','84%'],
		['People feel safe sharing ideas, feedback, and opinions, even if they differ from others.','Inclusion','Ethnicity (Black)','--','--','80%'],
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','79%'],
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','75%'],
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','74%'],
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','74%'],
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','72%'],
		['We lack a clear and compelling vision of what ultimate success looks like.','Inclusion','Ethnicity (Black)','--','--','70%']
	]
}


const Risks = () =>{

	return (
		<Page style={styles.page}>
			<Text style={styles.T2}>Key Risks & Strengths</Text>
			<View style={styles.line}/>

			<Text style={styles.p}>This table contains overall performance metrics for the nuLogic Diversity, Equity, and Inclusion assessment. The following 
						information appears on all nuLogic reports and is not specific to this organization's individual results</Text>

			<View wrap={false}>

			<Text style={styles.title}>Top Risks</Text>
			<Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs. 
            Chicken jerky short ribs, pastrami pork fatback ground round spare ribs cow t-bone filet mignon hamburger 
            leberkas shank. Meatball burgdoggen pork ribeye.</Text>

            <Table data={TableTemplate}/>

            </View>

            <View wrap={false}>
            <Text style={styles.title}>Top Strengths</Text>
			<Text style={styles.description}>Bacon ipsum dolor amet boudin pig tongue hamburger sausage cupim chuck tri-tip tail spare 
            ribs brisket pork loin bresaola. Beef chislic doner swine, pork chop porchetta picanha spare ribs. 
            Chicken jerky short ribs, pastrami pork fatback ground round spare ribs cow t-bone filet mignon hamburger 
            leberkas shank. Meatball burgdoggen pork ribeye.</Text>

            <Table data={TableTemplate}/>
            </View>

            <Side/>
            <Footer/>
		</Page>
		)
}


export default Risks