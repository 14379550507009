import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/resilience2.svg";
import Impact from "assets/images/Impact.svg";

const data2 = {
          
            series: [{
              name: 'series1',
              data: [31, 40, 28, 51, 42, 109, 100]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                 toolbar:{
                	show:false
                },
                sparkline: {
                enabled: true
              	},
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth',
                colors:['#6a00ff']
              },
              fill:{
              	colors:['#6a00ff']
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };


const data = {
          
            series: [70],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '70%',
                  },
                   track:{
                	background:'#c3a7fd'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#6a00ff']
              }
            },
          
          
          };

const CultureDials = (props) =>{

	return (
			<Card>
				<Top>
					<Line>
						<Title>{props.title}</Title>
						<View>View Analytics<Arrow>&#62;</Arrow></View>
					</Line>
					<Icon src={Resilience} />
				</Top>
				<Chart options={data.options} series={data.series} type="radialBar" height={250} />
				<Values>
					<Change>+10%</Change>
					<Stat>86%</Stat>
				</Values>

				<Chart options={data2.options} series={data2.series} type="area" height={125} />
			</Card>
		)
}


export default CultureDials



const Card = styled.div`
	padding:10px;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	position:relative;
	width:calc(33% - 20px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	margin-top:20px;
`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 75px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:30px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const Change = styled.div`
	font-size:16px;
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:10px;
`

const Top = styled.div`
	width:100%;
	margin-bottom:-30px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:20px;
	color:#4a4a4a;
`

const Icon = styled.img`
	height:40px;
`

const View = styled.div`
	font-family: "Red Hat Display", sans-serif;
	cursor:pointer;
`

const Line = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
`

const Arrow = styled.span`
	width: 7px;
  height: 11px;
  margin-left:10px;
`