import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import { Form } from "semantic-ui-react";
// import Input from "settings/user_settings/Input";
// import { Input } from 'semantic-ui-react'

import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { UPDATE_ORGANIZATION, PULL_ORGANIZATION } from "constants/actions";

import ImageAvtar from "./ImageAvtar";
import Styling from "./Styling"


const Organization = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState("");
  const [updated, setUpdated] = useState(0)

  const {  handleSubmit, setValue } = useForm();
  const organization_id = useSelector((state) => state.auth.organization_id);

  useEffect(() => {
    // if the response is ok 200
    if (organization_id) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: organization_id },
      });
    }
  }, [organization_id, dispatch, updated]);

  const { get_organization } = useSelector(
    (state) => ({
      get_organization: state.organizations,
      get_auth: state.auth,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_organization) {
      setOrganization(get_organization[organization_id]);
    }
  }, [get_organization, organization_id]);

  const onSubmit = (data) => {
    if (organization_id) {
      // update organization
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          ...data,
          id: organization.id,
        },
      });
    }

    if (props.return) {
      navigate(`/${props.return}`);
    }
  };

  useEffect(() => {
    if (organization) {
      setValue("name", organization?.name);
      setValue("address", organization?.address);
      setValue("city", organization?.city);
      setValue("postalcode", organization?.postalcode);
      setValue("province", organization?.province);
      setValue("country", organization?.country);
      setValue("time_zone", organization?.time_zone);
    }
  }, [organization, setValue]);

  //     address2 = models.CharField(max_length=250, blank=True, null=True)
  //     province_code = models.CharField(max_length=2, blank=True, null=True)
  //     phone = models.CharField(max_length=20, blank=True, null=True)
  //     fax = models.CharField(max_length=20, blank=True, null=True)
  //     tollfree = models.CharField(max_length=20, blank=True, null=True)
  //     email = models.EmailField(blank=True, null=True)
  //     from_email = models.EmailField(blank=True, null=True)
  //     no_of_employees = models.PositiveSmallIntegerField(choices=STATUS, default=1)
  //     no_of_licenses = models.PositiveSmallIntegerField(choices=STATUS, default=1)
  //     logo = models.ImageField(upload_to=company_image_file_path, blank=True, null=True)
  //     payment_status = models.PositiveSmallIntegerField(choices=STA

  return (<>
    <div style={{ width: "100%", padding: 10 }}>
      <Title>Organization</Title>

      <Form loading={false} onSubmit={handleSubmit(onSubmit)} error>
        <FormContainer>
          <FormSection>
            <ImageAvtar organization={organization} />
          </FormSection>

          <FormSection>
            <Styling
              organization={organization}
              setUpdated={setUpdated} />
          </FormSection>

        </FormContainer>
      </Form>
    </div>
  </>
  );
};

export default Organization;


const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 60px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
`;

const Button = styled.button`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 80px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const FormSection = styled.div`
  width: 40%;
  margin-right: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;