import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top:30px;
  width:150px;
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFFFFF;
  cursor: pointer;
  margin-right:5px;
`;

const Chevron = styled(Icon)`
  &&& {
    margin: 0;
    font-size: 16px;
    cursor: pointer;
  }
`;

const DotNavigation = ({ totalSteps, onNext, onPrev }) => {
  const [currentStep, setCurrentStep] = React.useState(1);
  const canGoNext = currentStep < totalSteps;
  const canGoPrev = currentStep > 1;

  const handleNext = () => {
    if (canGoNext) {
        setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (canGoPrev) {
        setCurrentStep(currentStep - 1);
    }
  };

  return (
    <NavigationWrapper>
      <Chevron name='chevron left' onClick={handlePrev} />
      <DotWrapper>
        {[...Array(totalSteps)].map((_, index) => (
          <Circle
            key={index}
            style={{
              backgroundColor: index + 1 === currentStep ? '#2D70E2' : '#C8DCFF',
            }}
            onClick={() => {
              if (index + 1 !== currentStep) {
                onNext(index + 1);
              }
            }}
          />
        ))}
      </DotWrapper>
      <Chevron name='chevron right' onClick={handleNext} />
    </NavigationWrapper>
  );
};

export default DotNavigation;
