import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import Screen1 from "./components/screen1";
import Screen2 from "./components/screen2";
import Screen3 from "./components/screen3";
import Screen4 from "./components/screen4";
import Screen5 from "./components/screen5";
import Screen6 from "./components/screen6";
import Screen7 from "./components/screen7";

const PlanCopilot = () => {
    const [step,setStep] = useState(1);

    const renderContent = () => {
        switch(step){
            case 1:
                return <Screen1 />
            case 2:
                return <Screen2 />
            case 3:
                return <Screen3 />
            case 4:
                return <Screen4 />
            case 5:
                return <Screen5 />
            case 6:
                return <Screen6 />
            case 7:
                return <Screen7 />
            default:
                return ""
        }
    }

    return (
        <Container>
            <Content>
                <T1>Action Plan Builder</T1>

                {renderContent()}

                <ButtonArea>
                    <Button onClick={()=>setStep(step+1)}>
                        Continue
                    </Button>
                </ButtonArea>
            </Content>
           
        </Container>
    )

}

export default PlanCopilot;



const Container = styled.div`
    margin-top:30px;
    display:flex;
    justify-content:center;
`

const Content = styled.div`
    width:800px;
`

const ButtonArea = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`

const Button = styled.div`
    padding:10px 20px;
    background-color:#2d70e2;
    color:white;
    cursor:pointer;
    transition:0.3s;
    border-radius:5px;
    display:inline-block;
    font-size:14px;

    &:hover {
        background-color:#1c4db8;
    }
`

const T1 = styled.div`
    color: #2d70e2;
text-transform: uppercase;
font-size: 14px;
font-weight: 600;
margin-bottom: 20px;
display: flex;
margin-top:20px;
`
