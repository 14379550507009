// Install styled-components if you haven't already
// npm install styled-components
import React from 'react';
import styled, { keyframes } from 'styled-components';


// Container for the entire chart
const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-family: 'Raleway';
  margin-bottom:40px;
  width:100%;
`;

// Container for the bars and text
const BarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 10px;
  position:relative;
`;

// Individual bar
const Bar = styled.div`
  height: 35px;
  margin-right: 10px;
  position: relative;
  background-color: ${props => (props.type === 'next' ? '#2D70E2' : '#B3B3B3')};
  width: ${props => props.width+'%'};
  margin-bottom:5px;
`;

// Text inside the bars
const BarText = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
`;

// Container for the percentages
const PercentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

// Percentage text
const PercentText = styled.div`
  font-size: 20px;
  font-weight: bold;
  display:flex;
    align-items:center;
    justify-content:flex-end;
  height:35px;
  margin-bottom:5px;
`;

// Source text
const SourceText = styled.div`
  font-size: 12px;
  color: #888;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
    width:100%;
`

const Line = styled.div`
  height: calc(100% + 20px);
  width: 3px;
  background-color: #FEA711;
  position: absolute;
  top: -10px;
  left: 57%;
`;

const TextArea = styled.div`
    font-size: 14px;
    width:100%;
    margin-bottom:30px;
    line-height:1.5;
    color:#666D79;
`

const Title = styled.div`
    color: #2A3039;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;

`

const Chart = ({score,title,standards,selected}) =>{

  if(!standards){
    return (
      <ChartContainer>
      <Title>{title} Scores</Title>
      <ContentContainer>
      <BarsContainer>
          <Bar type="next" width={score*10}>
            <BarText>Your organization</BarText>
          </Bar>

        </BarsContainer>
        <PercentContainer>
          <PercentText>{(score*10).toFixed(0)}%</PercentText>
        </PercentContainer>
      </ContentContainer>
      
      
      <SourceText>Source: {title} results from the most recent survey</SourceText>
    </ChartContainer>
    )
  }

  return  (
    <ChartContainer>
      <Title>{title} Scores</Title>
       <TextArea>
              Your {title} scores are <strong>32% higher</strong> than the typical company.
          </TextArea>
      <ContentContainer>
      <BarsContainer>
          <Bar type="next" width={score*10}>
            <BarText>Your organization</BarText>
          </Bar>
          <Bar type="typical" width={57}>
            <BarText>Typical Company</BarText>
          </Bar>
          
          <Line/>
        </BarsContainer>
        <PercentContainer>
          <PercentText>{(score*10).toFixed(0)}%</PercentText>
          <PercentText>57%</PercentText>
        </PercentContainer>
      </ContentContainer>
      
      
      <SourceText>Source: {title}  results from the survey collected Jan 25th, 2024.</SourceText>
    </ChartContainer>
  );
  
}
export default Chart;
