import styled, { keyframes } from "styled-components";
import React from "react";

import Dial from "./Dial";
import Responses from "./responses";
import Individual from "./individual";

import format from "date-fns/format";
import ResultCard from "./ResultCard";
import Pic from "assets/images/score.png";
import Benchmark from "data/benchmarks.json";
import { erf } from "mathjs";

const zptile = (z_score) => {
	return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};

const definitions = {
	Trust: "Trust is a wide-ranging concept that affects all forms of teams, groups, families, partnerships and more. In the context of this report, trust is synonymous with psychological safety in that it is the belief that risks can be taken, mistakes can be made, and divergent ideas can be shared without fear of reprisal or punishment from our peers. It is the social anchor that allows us to bring our full, authentic self into conversations and collaborative environments, and is proven to foster a greater sense of belonging and innovation in teams.",
	Resilience:
		"By no means does high resilience suggest a person or team is free of unpleasant or uncomfortable experiences, but rather, that they are capable (i.e., have the mental and emotional resources) of coping with them or enduring through them. Low resilience signals an inability to cope with or manage mental, emotional, or psychological demands. A staggering 70% of people and teams have experienced lower resilience related to work since the pandemic began.",
	Clarity:
		"To perform with maximum precision and purpose, team members need clarity. That is, a high degree of understanding of their role and what’s expected of them at each stage of a project or mission. High clarity not only reduces errors and increases efficiency, it also empowers team members to attack their role with confidence and certainty that it’s where their attention is needed most. Without clarity, projects are more likely to go off track and individual team members are more likely to make mistakes, waste time, and become demotivated.",
	Communication:
		"If trust is the social anchor that allows people to bring their real self into their work, communication is the bridge for exchanging information and ideas within a team. And like any bridge, there must be lanes going in both directions, allowing traffic to flow smoothly and equally from both sides. Communication, in all its many forms in today’s world, is a measure of how information is exchanged. In this case, communication is a measure of how clearly, directly, and respectfully information is exchanged - three critical elements of great communication that are expressed both verbally and non-verbally in all teams.",
	Alignment:
		"The science is crystal clear: teams that are aligned in their performance-oriented core values outperform those that aren’t. In fact, value alignment has been shown to account for up to 47% of performance within teams. And beyond simply sharing a common set of values lies the willingness and accountability to act and behave in ways that uphold them. This is the true definition of alignment – shared values that direct real behaviors.",
	Impact: "Performers need to know their work matters and directly impacts the bigger mission of the organization. Impact is a measure of exactly that. When teams and individuals believe their work matters and contributes to a larger whole, they become more committed to it. Without a high sense of impact and connectivity to the greater mission, people are left feeling confused, undervalued, and disconnected.",
};

const SummaryData = ({
	scoreData,
	scoreLastData,
	selected,
	questions,
	employeeScore,
	selectedEmployee,
	selectedName,
	employeeLast,
	teamLast,
	employeeAvg,
	employeeResponse,
	culture_data,
	industryBenchmark,
	teamAvg,
	selectedTeam,
}) => {
	const getScore = () => {
		if (questions?.score) {
			let diff = questions.score - teamLast;
			if (diff < 0) {
				return diff + "%";
			} else {
				return "+" + diff + "%";
			}
		}
	};

	const getChange = () => {
		if (questions?.score) {
			let diff = questions.score - teamLast;
			if (diff < 0) {
				return "decreased";
			} else if (diff > 0) {
				return "increased";
			}
		}

		return "remained consistent";
	};

	const getAverage = () => {
		if (teamAvg && questions?.score) {
			let diff = Math.floor(questions.score - teamAvg);
			if (diff < 0) {
				return (
					Math.abs(diff) +
					" points below your team's long term average of " +
					teamAvg +
					"%."
				);
			} else if (diff > 0) {
				return (
					diff +
					" points above your team's long term average of " +
					teamAvg +
					"%."
				);
			}
		}

		return (
			"consistent with your team's long term average of " + teamAvg + "%."
		);
	};

	const getPercentile = () => {
		if (questions?.score) {
			let score = Math.round(
				zptile(
					(questions.score - Benchmark[selectedName][0]) /
						Benchmark[selectedName][1]
				) * 100
			);
			if (score < 50) {
				return "bottom " + score + "%";
			}
			return "top " + (100 - score) + "%";
		}
	};

	const getBenchmark = () => {
		if (questions?.score) {
			let diff = Math.round(questions.score - Benchmark[selectedName][0]);
			if (diff < 0) {
				return Math.abs(diff) + " points below ";
			} else if (diff > 0) {
				return diff + " points above ";
			}
			return "consistent with ";
		}
	};

	const checkTeam = () => {
		if (selectedTeam) {
			return "This ";
		}

		return "Your ";
	};

	return (
		<Dark>
			<Factors>
				<Top>
					<Block1>
						<Title>{selectedName} Score</Title>
						<StyledDate>
							Report for{" "}
							{culture_data?.on_date &&
								format(
									new Date(
										culture_data?.on_date.toLocaleString()
									),
									"MMM do yyyy"
								)}
						</StyledDate>
						<Description>{definitions[selectedName]}</Description>

						<Data>
							<Change>{getScore()} change</Change>

							<Description>
								{checkTeam()} team's{" "}
								{selectedName.toLowerCase()} score has{" "}
								{getChange()} this week. {checkTeam()} team's
								score is in the{" "}
								<strong>
									{getPercentile()} of organizations
								</strong>
								, and is {getBenchmark()} the benchmark.{" "}
								{checkTeam()} team's{" "}
								{selectedName.toLowerCase()} scores are{" "}
								{getAverage()}
							</Description>
						</Data>
					</Block1>

					<Block2>
						<Circle>
							<Image src={Pic} />
							<CircleScore>{questions?.score}</CircleScore>
							<SubText>{selectedName} score </SubText>
						</Circle>
					</Block2>
				</Top>

				<Area>
					<ResultCard
						title={`Team ${selectedName}`}
						score={questions?.score}
						last={teamLast}
						average={teamAvg}
						selectedTeam={selectedTeam}
					/>

					{selectedTeam ? (
						""
					) : (
						<ResultCard
							title={`Your ${selectedName}`}
							score={employeeScore}
							last={employeeLast}
							average={employeeAvg}
						/>
					)}
				</Area>

				<Responses
					scoreData={scoreData}
					selectedName={selectedName}
					scoreLastData={scoreLastData}
					selectedTeam={selectedTeam}
				/>
			</Factors>

			{/*  */}
		</Dark>
	);
};

export default SummaryData;

const Card = styled.div`
	box-shadow: 0 4px 20px 0 #ececec;
	width: 800px;
	display: flex;
	flex-direction: column;
	padding: 30px 40px;
`;

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size: 26px;
	font-weight: bold;
	margin-bottom: 10px;
`;

const Dark = styled.div`
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom: 10px;
	margin-top: 20px;
	font-size: 16px;
	line-height: 1.5;
`;

const Metrics = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size: 26px;
	font-weight: bold;
	color: #4a4a4a;
`;

const Line = styled.div`
	width: 30px;
	height: 5px;
	background-color: #6a00ff;
	margin-bottom: 5px;
`;

const Factor = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	align-items: center;
	border-bottom: ${(props) => (props.last ? "none" : "1px solid #e9e9e9")};
	padding-bottom: 10px;
`;

const Factors = styled.div`
	width: 100%;
	padding: 0px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 0px;

	@media (min-width: 1200px) {
		width: 100%;
	}
`;

const FactorTitle = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size: 16px;
`;

const Score = styled.div`
	font-size: 20px;
	font-weight: bold;
	font-family: "Red Hat Display", sans-serif;
`;

const Sub = styled.div`
	font-size: 10px;
	font-family: "Red Hat Display", sans-serif;
	position: absolute;
	top: -30px;
`;

const ScoreContainer = styled.div`
	margin-right: 20px;
	position: relative;
	width: 50%;
`;

const TwoScores = styled.div`
	display: flex;
	min-width: 145px;
	align-items: center;
`;

const Block1 = styled.div`
	width: calc(70% - 30px);
	margin-right: 30px;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const Block2 = styled.div`
	width: 30%;
	display: flex;
	justify-content: center;
	font-family: "Red Hat Display", sans-serif;
	align-items: center;

	@media (max-width: 768px) {
		width: 100%;
		margin-top: 75px;
	}
`;

const Top = styled.div`
	display: flex;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-bottom: 10px;
	margin-left: -38px;

	padding-left: 38px;
	width: calc(100% + 72px);
	background-color: rgba(0, 176, 240, 0.05);
	flex-wrap: wrap;
`;

const Circle = styled.div`
	height: 125px;
	width: 125px;
	min-width: 125px;
	border-radius: 63px;
	color: black;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-right: 20px;
	position: relative;
	color: #6a00ff;
`;

const CircleScore = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size: 60px;
	line-height: 1;
	font-weight: bold;
	margin-top: 10px;
`;

const Image = styled.img`
	height: 250px;
	position: absolute;
`;

const SubText = styled.div`
	font-size: 12px;
	margin-top: 5px;
`;

const Change = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
`;

const Data = styled.div`
	margin-top: 20px;
`;

const Area = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 30px;

	@media (max-width: 768px) {
		flex-wrap: wrap;
	}
`;

const StyledDate = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-top: 0px;
	margin-bottom: 10px;
`;
