import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import ReportType from "./components/ReportType";
import ReportOptions from "../components/ReportOptions";
import ReportCategories from "./components/ReportCategories";
import ReportPrimaries from "./components/ReportPrimaries";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES } from "constants/actions";
import { filter_data } from "reports/Tag/calculations";
import { useNavigate } from "react-router-dom";

const getCategoryOptions = (categories, parentId) => {
  let categoryList = [];
  const parentCategory = categories.find(
    (category) => category.id === parentId
  );
  if (parentCategory) {
    parentCategory.options.forEach((option) => {
      categoryList.push({
        id: option.id,
        name: option.name,
      });
    });
  }

  return categoryList;
};

const getEmptyFilters = (data, filters) => {
  let emptyFilters = [];
  filters?.forEach((filter) => {
    const hasData = data?.find((item) =>
      item?.categories?.find((cat) => cat?.response === filter?.id)
    );
    if (!hasData) {
      emptyFilters.push(filter.id);
    }
  });

  return emptyFilters;
};

const ReportCreation = () => {
  const dispatch = useDispatch();
  const [stage, setStage] = useState(1);
  // Report template
  const [reportType, setReportType] = useState();
  // parent_id
  const [reportOptions, setReportOptions] = useState();
  // parent_children
  const [reportCategories, setReportCategories] = useState([]);
  // primary_list
  const [reportPrimaries, setReportPrimaries] = useState([]);
  // secondary selected
  const [secondarySelected, setSecondarySelected] = useState(false);
  // Anchor Name
  const [anchorName, setAnchorName] = useState("");
  // Report settings
  const [reportSettings, setReportSettings] = useState();
  // Filtered category list
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [orgName, setOrgName] = useState("");
  const navigate = useNavigate();

  const {
    get_employee_categories,
    latestData,
    get_auth,
    coreData,
    reportTemplates,
  } = useSelector(
    (state) => ({
      get_employee_categories: state.employee_category,
      coreData: state.audit?.core_data,
      reportTemplates: state.debrief_pdf_report?.templates,
      get_auth: state.auth,
      latestData: state.audit?.filtered_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
    });
  }, []);

  useEffect(() => {
    const employeeId = Number(get_auth?.employee_id);
    const employeeCategories =
      get_employee_categories?.employee_org_category?.find(
        (category) => category.employee === employeeId
      );

    if (employeeCategories) {
      const orgName = employeeCategories?.category?.find(
        (cat) => cat?.priority === "primary"
      )?.value;
      setOrgName(orgName);
    } else {
      const orgName = coreData?.categories?.[0]?.name;
      setOrgName(orgName);
    }
  }, [get_employee_categories]);

  useEffect(() => {
    if (reportOptions && coreData?.categories?.length > 0) {
      const secondaries = coreData?.categories?.[0]?.categories?.find(
        (category) => category.priority === "secondary"
      );

      const primary = coreData?.categories?.[0]?.categories?.find(
        (category) => category.priority === "primary"
      );
      // get the anchor name of the reportOptions

      const anchor = coreData?.categories?.[0]?.categories?.find(
        (category) => category.id === reportOptions
      );
      if (anchor?.priority === "primary") {
        setAnchorName("location");
      } else {
        setAnchorName(anchor?.name.toLowerCase());
      }

      if (secondaries?.id === reportOptions || primary?.id === reportOptions) {
        setSecondarySelected(true);
      } else {
        setSecondarySelected(false);
      }
    }
  }, [reportOptions, coreData?.categories]);

  useEffect(() => {
    let filteredLatestData;
    if (reportOptions && coreData?.categories?.length > 0) {
      const options = getCategoryOptions(
        coreData.categories?.[0].categories,
        reportOptions
      );
      const selectedFilters = options.map((option) => {
        return { id: option.id, parent: reportOptions };
      });
      if (latestData?.[0]?.[0]) {
        filteredLatestData = filter_data(latestData?.[0], selectedFilters);
      } else {
        filteredLatestData = filter_data(latestData, selectedFilters);
      }

      const emptyFilters = getEmptyFilters(filteredLatestData, selectedFilters);
      // remove any options with no data
      const filteredOptions = options.filter(
        (option) => !emptyFilters.includes(option.id)
      );

      setFilteredCategories(filteredOptions);
    }
  }, [reportOptions]);

  const renderContent = () => {
    switch (stage) {
      case 1:
        return (
          <ReportType
            id={1}
            setTemplate={(id) => setReportType(id)}
            next={() => setStage(2)}
            templates={reportTemplates?.response?.filter(
              (template) => template.type === "recommendation"
            )}
            isSport={coreData?.Whitelabel?.sport_only}
            goBack={() => navigate("/leader/audit/reports/recV4")}
          />
        );
      case 2:
        return (
          <ReportOptions
            next={(id) => {
              setReportOptions(id);
              setStage(3);
            }}
            goBack={() => {
              setStage(1);
              setReportOptions();
            }}
          />
        );
      case 3:
        return (
          <ReportCategories
            primaries={reportPrimaries}
            selectedParent={reportOptions}
            filteredOptions={filteredCategories}
            pdfOptions={reportSettings}
            reportType={reportType}
            get_auth={get_auth}
            orgName={orgName}
            lastScreen={secondarySelected}
            goBack={() => {
              setStage(2);
              setReportCategories([]);
            }}
            next={(ids) => {
              setReportCategories(ids);
              if (secondarySelected) {
                setStage(5);
              } else {
                setStage(4);
              }
            }}
          />
        );
      case 4:
        return (
          <ReportPrimaries
            categories={reportCategories}
            parentCategory={reportOptions}
            pdfOptions={reportSettings}
            reportType={reportType}
            get_auth={get_auth}
            orgName={orgName}
            goBack={() => {
              setStage(3);
              setReportPrimaries([]);
            }}
            next={(ids) => {
              setReportPrimaries(ids);
              setStage(5);
            }}
          />
        );
      default:
        return "";
    }
  };

  return (
    <Container>
      <Center>{renderContent()}</Center>
    </Container>
  );
};

export default ReportCreation;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  font-family: "Raleway";
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const Options = styled.div`
  width: 100%;
  display: flex;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;
`;
