import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Modal, Dropdown } from "semantic-ui-react";
import { ILIcon, UserAvatar as UserIcon } from "./Icons";
import { closeIcon } from "components/Icons";
import IconContainer from "./IconContainer";

const getHRScoreColor = (score) => {
  if (score > 0) {
    return ["#FEF3F4", "#F15062"];
  }

  return ["#EAFBF7", "#1D9A7D"];
};

const getFeedbackScoreColor = (score) => {
  if (score > 8) {
    return ["#EAFBF7", "#1D9A7D"];
  }
  if (score > 6) {
    return ["#EBF1FD", "#2D70E2"];
  }
  if (score > 2) {
    return ["#FFFAF1", "#FEA711"];
  }
  return ["#FEF3F4", "#F15062"];
};

const hasHrFlag = (curItem, mlObject) => {
  const mlData = mlObject?.ml_feedback?.feedback?.find((v) => {
    if (v?.factor === curItem?.factor && v?.feedback === curItem?.feedback) {
      return true;
    }
  });

  if (mlData?.conversation_analysis?.hr_flag?.[0] === 1) {
    return true;
  } else {
    return false;
  }
};

const get_sentiment_words = (score) => {
  if (score > 0.7) {
    return "Very Positive";
  }
  if (score > 0.5) {
    return "Moderately Positive";
  }
  if (score > 0.2) {
    return "Slightly Positive";
  }
  if (score > -0.2) {
    return "Neutral";
  }
  if (score > -0.5) {
    return "Slightly Negative";
  }
  if (score > -0.7) {
    return "Moderately Negative";
  }

  return "Very Negative";
};

const getSentimentScoreColor = (sentiment) => {
  switch (sentiment) {
    case "Very Positive":
      return ["#EAFBF7", "#1D9A7D"];
    case "Moderately Positive":
      return ["#EAFBF7", "#1D9A7D"];
    case "Slightly Positive":
      return ["#EAFBF7", "#1D9A7D"];
    case "Neutral":
      return ["#EBF1FD", "#2D70E2"];
    case "Slightly Negative":
      return ["#FEF3F4", "#F15062"];
    case "Moderately Negative":
      return ["#FEF3F4", "#F15062"];
    default:
      return ["#FEF3F4", "#F15062"];
  }
};

const average = (data) => {
  return data.reduce((a, b) => a + b, 0) / data.length;
};

const get_average_sentiment = (data) => {
  let average_sentiment = [];
  if (data.length > 0) {
    data.map((f) => {
      average_sentiment.push(f.conversation_analysis.sentiment[0].compound);
    });
    return get_sentiment_words(average(average_sentiment));
  }
};

const get_total_flags = (data) => {
  let flag_count = 0;
  if (data.length > 0) {
    data.map((f) => {
      flag_count += f.conversation_analysis.hr_flag[0];
    });
    return flag_count;
  }
};

const get_average_quality = (data) => {
  let average_quality = [];
  if (data.length > 0) {
    data.map((f) => {
      average_quality.push(f.conversation_analysis.quality[0]);
    });
    return average(average_quality);
  }
};

function extractTitleAndSummary(str) {
  const titleRegex = /##\s*Title:\s*(.+)/i;
  const summaryRegex = /##\s*(?:TL;DR|tldr):\s*(.+)/i;

  const titleMatch = str.match(titleRegex);
  const summaryMatch = str.match(summaryRegex);

  const title = titleMatch ? titleMatch[1] : null;
  const summary = summaryMatch ? summaryMatch[1] : null;

  return { title, summary };
}

const sentiments = ["Negative", "Moderate", "Positive", "Very Positive"];

const Question = (props) => {
  const { content, hasFlag } = props;

  return (
    <QuestionContainer>
      <QuestionBox>{content}</QuestionBox>
      {hasFlag && <FlagIndicator>!</FlagIndicator>}
      <UserAvatar>
        <IconContainer icon={UserIcon()} />
      </UserAvatar>
    </QuestionContainer>
  );
};

const Response = (props) => {
  const { content } = props;

  return (
    <ResponseContainer>
      <AIAvatar>
        <IconContainer backgroundColor="#2D70E2" icon={ILIcon("#FFF")} />
      </AIAvatar>
      <ResponseBox>{content}</ResponseBox>
    </ResponseContainer>
  );
};

const ChatModal = ({ open, setOpen, data }) => {
  let extractedData;
  let hrScore;
  let hrColors;
  let sentiment;
  let sentimentColors;
  let feedbackScore;
  let feedbackColors;

  if (data) {
    extractedData = extractTitleAndSummary(data?.ml_feedback?.feedback_summary);
    hrScore = Math.round(get_total_flags(data.ml_feedback.feedback));
    hrColors = getHRScoreColor(hrScore);
    sentiment = get_average_sentiment(data.ml_feedback.feedback);
    sentimentColors = getSentimentScoreColor(sentiment);
    feedbackScore = Math.round(get_average_quality(data.ml_feedback.feedback));
    feedbackColors = getFeedbackScoreColor(feedbackScore);
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      <Content>
        <TopSection>
          <Title>{extractedData?.title}</Title>
          <CloseButton onClick={() => setOpen(false)}>
            {closeIcon("#2A3039")}
          </CloseButton>
        </TopSection>

        <ScoresSection>
          <Score
            style={{
              background: hrColors?.[0],
              color: hrColors?.[1],
            }}
          >
            <ScoreTitle>HR flag:&nbsp;</ScoreTitle>
            <ScoreValue>{hrScore}</ScoreValue>
          </Score>
          <Score
            style={{
              background: sentimentColors?.[0],
              color: sentimentColors?.[1],
            }}
          >
            <ScoreTitle>Sentiment:&nbsp;</ScoreTitle>
            <ScoreValue>{sentiment}</ScoreValue>
          </Score>
          <Score
            style={{
              background: feedbackColors?.[0],
              color: feedbackColors?.[1],
            }}
          >
            <ScoreTitle>Feedback Quality:&nbsp;</ScoreTitle>
            <ScoreValue>{feedbackScore}/10</ScoreValue>
          </Score>
        </ScoresSection>

        <ConversationContainer>
          <T2>Conversation</T2>
          {data?.feedback.map((item) => {
            const hasFlag = hasHrFlag(item, data);
            return (
              <Thread>
                {item?.question?.length > 0 && (
                  <Response content={item.question} />
                )}

                {item?.feedback?.length > 0 && (
                  <Question content={item.feedback} hasFlag={hasFlag} />
                )}

                {item?.follow_up_question?.length > 0 && (
                  <Response content={item.follow_up_question} />
                )}

                {item?.follow_up?.length > 0 && (
                  <Question content={item.follow_up} />
                )}
              </Thread>
            );
          })}
        </ConversationContainer>
      </Content>
    </Modal>
  );
};

export default ChatModal;

const Content = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  height: 90vh;
`;

const ScoresSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
  border-bottom: 1px solid #f2f2f2;
`;

const Title = styled.div`
  color: #2a3039;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  margin-bottom: 32px;
`;

const Authors = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  margin-bottom: 32px;
  line-height: 18px;
`;

const Score = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;

  border-radius: 8px;
  margin-right: 3px;
`;

const ScoreTitle = styled.div`
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
`;

const ScoreValue = styled.div`
  font-family: Raleway;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

const T2 = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
  color: #2a3039;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const T3 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  line-height: 22px;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 400px;
  font-weight: 600;
`;

const T4 = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  white-space: pre-line;
  line-height: 22px;
  padding-left: 30%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

const Thread = styled.div``;

const ConversationContainer = styled.div`
  height: 75%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
    margin-top: 10px;
    margin-bottom: 35px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    left: 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.03);
    padding-left: 16px;
  }
`;

const Circle = styled.div`
  background: #fea711;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 8px;
`;

const Tag = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
`;

const T1 = styled.div`
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
  color: #2a3039;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  margin-right: 0;
  margin-left: auto;
  max-width: 400px;
`;

const QuestionBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f8faff;
  line-height: 18px;
  margin-right: 0;
  margin-left: auto;
`;

const UserAvatar = styled.div`
  margin-left: 8px;
`;

const ResponseContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  max-width: 400px;
`;

const AIAvatar = styled.div`
  margin-right: 8px;
`;

const ResponseBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f8faff;
  line-height: 18px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled.div`
  cursor: pointer;
`;

const FlagIndicator = styled.div`
  display: flex;
  align-items: center;
  position: absolte;
  justify-content: center;
  background: #f15062;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  right: -35px;
  bottom: 6px;
  z-index: 99;
  margin-right: -20px;
  position: relative;
`;
