import React, { useState } from "react";

import { Checkbox, Message } from "semantic-ui-react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Link } from "react-router-dom";
import { SG_VALIDATE_EMAIL } from "constants/actions";

import styled from "styled-components";

import Logo from "assets/images/logo.jpg";

const Register = () => {
  const dispatch = useDispatch();


  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const [checked, setChecked] = useState(false);

  const [validationToken, setValidationToken] = React.useState(false);

  const { validate_email, get_error } = useSelector(
    (state) => ({
      validate_email: state.validateEmail.validateEmail,
      get_error: state.errors.msg,
    }),
    shallowEqual
  );

  function ValidateEmail() {
    if (token) {
      dispatch({
        type: SG_VALIDATE_EMAIL,
        payload: { token: token },
      });
    }
  };


  React.useEffect(() => {
    if (validate_email?.code === "200") {
      setValidationToken(true);
    }
  }, [validate_email]);

  // console.log(validate_email)

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Banner>
        <IMG src={Logo} />
      </Banner>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {!checked ?
          <div>
            <Title>Please validate your email</Title>
            <P>Please click the checkbox to accept the terms and validate your email address</P>
          </div>
          : ""}

        {validationToken ? (
          <P color={validate_email.message === "Successfully Validated" ? '#08c251' : '#ff2d55'}>{validate_email.message}</P>
        ) : (
          <Error>{validate_email.message}</Error>
        )}
        {!token &&
          <Error>No Token found</Error>}
        <Error>{get_error?.data?.token && "Invalid Token"}
        </Error>

        {!checked ? <Checkbox
          onChange={() => setChecked(!checked)}
          checked={checked}
          onClick={() => ValidateEmail()}
          name="terms"
          label={{
            children: (
              <a target="_blank"
                rel="noopener noreferrer"
                href={"https://innerlogic.com/terms"}>
                I agree to the terms and conditions
              </a>
            ),
          }}
          style={{ borderColor: "black" }}
        /> : ""}
        <Button disabled={!checked ? true : false}
          onClick={() => ValidateEmail()}>
          Get Started Now
        </Button>
      </div>
    </div>
  );
};

export default Register;

const Title = styled.div`
  font-size: 28px;
  font-weight: 900;
  margin-bottom: 40px;
  font-family: "Red Hat Display", sans-serif;
`;
const Button = styled.button`
  width: 200px;
  height: 55px;
  border-radius: 8px;
  background-color: #6a00ff;
  color: white;
  font-size: 18px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const IMG = styled.img`
  width: 150px;
`;
const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 50px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 50px;
  margin-top: 100px;
`;

const P = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.4;
  color:${props => props.color};
`;

const Error = styled.div`
  font-family: "Red Hat Display", sans-serif;
  max-width: 500px;
  font-size: 24px;
  color: red;
  margin-bottom: 20px;
  line-height: 1.4;
`;
