import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress } from 'semantic-ui-react'


const ThemeAnalysis = () =>{

	return (
		<CardContent>
			<Intro>
			<Title>Uncertainty</Title>
			<Sub>Theme Analytics</Sub>
			<P>One of the most basic beliefs we carry about ourselves, Dweck found in her research,
			has to do with how we view and inhabit what we consider to be our personality. </P>
				<ChartTitle>Historical Trends</ChartTitle>
				<Chart options={data.options} series={data.series} type="area" height={350}/>
				<MiniTitle>Date Range</MiniTitle>
				<Chart options={data2.options} series={data2.series} type="area" height={130}/>

			</Intro>

			<Card>
				<CardTitle>Most Impactful Keywords</CardTitle>
				<Description>These keywords best describe the most impactful comments on growth mindset</Description>
				<KeyWord> 
					<Word>season eager goal</Word>
					 <Progress percent={88}  color='violet'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>mentally losing games</Word>
					 <Progress percent={66}  color='violet'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>frustrated person team</Word>
					 <Progress percent={55}  color='violet'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word> season trying win</Word>
					 <Progress percent={45}  color='violet'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>great team performing</Word>
					 <Progress percent={33}  color='violet'  size="small"/>
				</KeyWord>
				
			</Card>

			<Intro>
			
			</Intro>


			{/*<Card>
				<CardTitle>Most Impactful Specific Themes</CardTitle>
					<Description>These themes are connected to the comments associated with growth mindset</Description>
				<KeyWord> 
					<Word>Bouncing back</Word>
					 <Progress percent={88} progress color='purple'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>Finishing strong</Word>
					 <Progress percent={66} progress color='purple'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>Hard work paying off</Word>
					 <Progress percent={55} progress color='purple'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>Overloaded, burning out</Word>
					 <Progress percent={45} progress color='purple'  size="small"/>
				</KeyWord>
				<KeyWord> 
					<Word>Future directions, opportunity</Word>
					 <Progress percent={33} progress color='purple'  size="small"/>
				</KeyWord>
				
			</Card>*/}

		</CardContent>
		)
}


export default ThemeAnalysis




const Card = styled.div`
	margin-bottom:20px;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:30px;
		width:calc(40% - 20px);
	margin-left:20px;
`

const ChartCard = styled.div`
	width:60%;
`

const CardTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:20px;
	font-weight:500;
	
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:15px;
	margin-top:5px;
`

const KeyWord = styled.div`
	display:flex;
	flex-direction:column;
	margin-bottom:-10px;
`
const ChartTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	margin-top:20px;
	font-weight:500;
`

const Word = styled.div`
	font-size:16px;
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:3px;
`

const Impact = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
`

const MiniTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
`

const Intro = styled.div`
	width:60%;
	display:flex;
	flex-direction:column;
`

const CardContent = styled.div`
	margin-top:50px;
	display:flex;
	flex-wrap:wrap;
`

const Dated = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	color:#4a4a4a;
	margin-top:5px;
`
const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:36px;
	font-weight:bold;
	width:100%;
`

const P = styled.p`
	font-family: "Red Hat Display", sans-serif;
	max-width:800px;
	margin-top:20px;
	font-size:16px;
`

const Sub = styled.div`
	font-family: "Red Hat Display", sans-serif;
	color:#9b9b9b;
	margin-top:5px;
`


const data = {
          
            series: [{
              name: 'Impact',
              data: [31, 40, 28, 51, 42, 66, 95]
            }, {
              name: 'Sentiment',
              data: [11, 32, 88, 32, 34, 52, 41]
            }],
            options: {
              chart: {
              	 id: 'chart2',
                height: 350,
                type: 'area',
                 toolbar:{
                 	 autoSelected: 'pan',
                	show:false
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-12T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-26T02:30:00.000Z", "2018-10-03T03:30:00.000Z", "2018-10-10T04:30:00.000Z", "2018-10-17T05:30:00.000Z", "2018-10-24T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };

 const data2 = {
 		series: [{
              data: [31, 40, 28, 51, 42, 66, 95]
            }],
            options: {
              chart: {
                id: 'chart1',
                height: 130,
                type: 'area',
                brush:{
                  target: 'chart2',
                  enabled: true
                },
                events: {
			      brushScrolled: function(chartContext, { xaxis, yaxis }) {
			      console.log(xaxis)
			      }
			    },
                selection: {
                  enabled: true,
                 xaxis: {
                    min: new Date('2018-09-12T00:00:00.000Z').getTime(),
                    max: new Date('2018-10-24T06:30:00.000Z').getTime()
                  }
                },
              },
              colors: ['#008FFB'],
              fill: {
                type: 'gradient',
                gradient: {
                  opacityFrom: 0.91,
                  opacityTo: 0.1,
                }
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-12T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-26T02:30:00.000Z", "2018-10-03T03:30:00.000Z", "2018-10-10T04:30:00.000Z", "2018-10-17T05:30:00.000Z", "2018-10-24T06:30:00.000Z"],
                tooltip: {
                  enabled: false
                }
              },
              yaxis: {
                tickAmount: 2,
                max:100,
                min:0
              }
            },
          
          }