import React from "react";
import styled from "styled-components";
import { labelColor, defaultText, fontSizes } from "../styleConfig";
import { IdeasIcon } from "../Icons";

export default function IdeaBox(props) {
 const { idea } = props;
 return (
  <BoxContainer>
   <LightbulbImage>{IdeasIcon()}</LightbulbImage>
   <BoxText>{idea}.</BoxText>
  </BoxContainer>
 );
}

const BoxContainer = styled.div`
 display: flex;
 margin-top: 48px;
 margin-bottom: 32px;
 flex-direction: row;
 align-items: center;
 width: 100%;
 height: 72px;
 background: ${labelColor.background};
 justify-content: center;
 border-radius: 8px;
`;

const LightbulbImage = styled.div`
 padding-right: 24px;
 padding-left: 12px;
`;

const BoxText = styled.div`
 color: ${defaultText};
 font-style: normal;
 font-weight: 600;
 font-size: ${fontSizes.small};
 padding-right: 12px;
`;
