
import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon,Loader} from 'semantic-ui-react'
import TopProgress from './TopProgress'
import Introduction from './Intro'
import Loading from './Loading'
import FullIntroduction from "./FullIntro";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Filters from "./Filters";
import {
  getStructuredFeedback,
  filter_data,
  get_most_recent,
  get_last_responses,
  get_scores_per_pillar,
} from "reports/Tag/calculations";
import TemplateSelection from "./TemplateSelection";
import FactorSelection from "./FactorSelection";
import Context from "../Context";
import { SG_ADD_CHAT_RECOMMENDATION } from "constants/actions";

const findFactorScores = (f, selectedItems) => {
  let factor = f.factor;
  let dimension = f.dimension;
  let _selectedItems = selectedItems.filter(
    (f) => f?.factor.id - 1 === factor && f?.dimension === dimension
  );
  if (_selectedItems.length > 0) {
    return true;
  }

  return false;
};

const findFactorFeedback = (f, selectedItems) => {
  let factor = f.factor;
  let dimension = f.id;
  let _selectedItems = selectedItems.filter(
    (f) => f?.factor.id - 1 === factor && f?.dimension === dimension
  );

  if (_selectedItems.length > 0) {
    return true;
  }

  return false;
};

const Create = () => {
  const dispatch = useDispatch();
  const [lastData, setLastData] = useState();
  const [step, setStep] = useState(0);
  const [data, setData] = useState();
  const [tags, setTags] = useState([]);
  const [pillarScores, setPillarScores] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [dataLength, setDataLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [full_loading, setFullLoading] = useState(false);
  const [latestData, setLatestData] = useState();
  const [filteredData, setFilteredData] = useState();
  const [filteredLastData, setFilteredLastData] = useState();
  const [standards, setStandards] = useState();
  const [factor, setFactor] = useState();
  const [benchmark, setBenchmark] = useState();
  const [contextData, setContextData] = useState();
  const [contextMessage, setContextMessage] = useState();

  const steps = [
    "Introduction",
    "Filter selection",
    "Template selection",
    "Factor selection",
    "Context Question",
    "Context follow up #1",
    "Context follow up #2",
    "Review",
  ];

  const {
    get_culture_audit_reports,
    survey_version,
    selectedFilters,
    organization,
    get_standards,
  } = useSelector(
    (state) => ({
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      survey_version: state.audit.survey_version,
      recommendations: state.recommendations.chat_recommendations,
      recommendationsError: state.recommendations.chat_recommendations_error,
      get_standards: state.survey_standard.survey_standards,
      selectedFilters: state.audit?.new_filters,
      organization: state.organizations,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (survey_version) {
      setStandards(
        get_standards.find((f) =>
          f.question_sort_order.includes(survey_version.sort_order)
        )
      );
    }
  }, [get_standards, survey_version]);



  useEffect(() => {
    if (filteredData && survey_version) {
      setPillarScores(
        get_scores_per_pillar(
          filteredData,
          survey_version,
          latestData,
          filteredLastData,
          standards
        )
      );
    }
  }, [filteredData]);

  useEffect(() => {
    if (selectedFilters.length > 0) {
      let _tags = selectedFilters.map((item) => item.name);
      setTags(_tags);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (latestData && selectedFilters.length === 0 && survey_version) {
      setPillarScores(
        get_scores_per_pillar(
          latestData,
          survey_version,
          latestData,
          lastData,
          standards
        )
      );
        }

    if (latestData && selectedFilters.length > 0) {
      setFilteredLastData(filter_data(lastData, selectedFilters));
      setFilteredData(filter_data(latestData, selectedFilters));
    }
  }, [latestData, selectedFilters, standards]);

  useEffect(() => {
    if (get_culture_audit_reports?.response) {
      let sorted_responses = get_culture_audit_reports?.response.sort(
        (a, b) => b.id - a.id
      );

      if (survey_version?.sort_order) {
        sorted_responses = sorted_responses.filter(
          (f) => f.question_sort_order === survey_version.sort_order
        );
      }

      let most_recent = get_most_recent(sorted_responses);
      let last_responses = get_last_responses(sorted_responses);
      setLatestData(most_recent.responses);
      setLastData(last_responses);
    }
  }, [get_culture_audit_reports, survey_version]);

  useEffect(() => {
    if (selectedFilters?.length > 0) {
      setData(filteredData);
    } else {
      setData(latestData);
    }
  }, [selectedFilters, latestData, filteredData]);

  useEffect(() => {
    const feedback = getStructuredFeedback(data);
    if (factor?.length > 0) {
      let _filtered_feedback = feedback.filter((f) =>
        findFactorFeedback(f, factor)
      );
      setDataLength(_filtered_feedback.length);
    } else {
      setDataLength(feedback.length, factor);
    }
  }, [factor, data, step]);

  const next = () => {
    if (step === 7) {
      setLoading(true);

      //For the factor data we will need to calculate scores based on each question in the factor
      //We will need to calculate the average score for each question in the factor
      const feedback = getStructuredFeedback(data);
      //If the data is for the entire organization, we need to pass the org name here
      let reqData;

      const reqParams = {
        tags: tags,
        template: selectedTemplate,
        org_name: organization?.organization?.name,
        selectedBenchmark: benchmark,
        contextData: contextData,
        recommendation_template: selectedTemplate,
      };

      if (
        selectedTemplate === "Thematic Analysis" ||
        selectedTemplate === "Culture Factor Report"
      ) {
        let _filtered_feedback = feedback?.filter((f) =>
          findFactorFeedback(f, factor)
        );
        let _filtered_scores = pillarScores?.filter((f) =>
          findFactorScores(f, factor)
        );

        reqData = {
          selectedItems: factor,
          feedback: _filtered_feedback,
          scores: _filtered_scores,
        };
      } else {
        reqData = {
          selectedItems: factor,
          feedback: feedback,
          scores: pillarScores,
        };
      }

      const recReq = {
        survey_data: reqData,
        params: reqParams,
        chat_token: "mgngxkfkot",
        recommendation_template: selectedTemplate,
        api_version: 3,
      };

      dispatch({
        type: SG_ADD_CHAT_RECOMMENDATION,
        payload: recReq,
      });

      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setLoading(true);
      setStep(step + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const start = () => {
    setFullLoading(true);
    setStep(step + 1);
    setTimeout(() => {
      setFullLoading(false);
    }, 500);
  };

  const getComponent = () => {
    if (loading) {
      return <Loading />;
    }

    switch (step) {
      case 0:
        return <Introduction />;
      case 1:
        return (
          <Filters
            org_name={organization?.organization?.name}
            data_length={dataLength}
          />
        );
      case 2:
        return (
          <TemplateSelection
            setSelectedTemplate={(t) => setSelectedTemplate(t)}
            selectedTemplate={selectedTemplate}
          />
        );

      case 3:
        return (
          <FactorSelection
            setFactor={(f) => setFactor(f)}
            factor={factor}
            setBenchmark={(b) => setBenchmark(b)}
            benchmark={benchmark}
          />
        );

      case 4:
        return (
          <Context
            step={2}
            handleNext={() => next()}
            selectedFactor={factor?.[0]}
            setContextData={(data) => setContextData(data)}
            setContextMessage={(v) => setContextMessage(v)}
            // triggerRecommendation={(v) => setTriggerRecommendation(v)}
          />
        );

      case 5:
        return (
          <Context
            step={3}
            handleNext={() => next()}
            setContextData={(data) => setContextData(data)}
            contextMessage={contextMessage}
            setContextMessage={(v) => setContextMessage(v)}
            // triggerRecommendation={(v) => setTriggerRecommendation(v)}
          />
        );

      case 6:
        return (
          <Context
            step={4}
            handleNext={() => next()}
            setContextData={(data) => setContextData(data)}
            contextMessage={contextMessage}
            setContextMessage={(v) => setContextMessage(v)}
            // triggerRecommendation={(v) => setTriggerRecommendation(v)}
          />
        );

      default:
        return (
          <div>
            Thank you, click the generate button below to create your
            recommendation
          </div>
        );
    }
  };

  if (step === 0) {
    return <FullIntroduction next={start} />;
  }

  if (full_loading) {
    return (
      <div style={{ width: "100%", height: "100vh" }}>
        <Loader active />
      </div>
    );
  }

  return (
    <Container>
      <Side>
        <Icon
          name="pen square"
          style={{ fontSize: 50, marginBottom: -5, marginLeft: -10 }}
        />
        <Title>Lets build an action plan</Title>
        <Description>
          Lets review the draft plan that we created for you. We'll be able to
          incorporate your feedback in order to make the plan more actionable
          for you. There's just a few simple steps to refine your plan and then
          we'll work on putting this plan into action.
        </Description>
        <TopProgress step={step} steps={steps} />
      </Side>

      <Content>
        {getComponent()}
        {(step < 4 || step > 6) && (
          <ButtonContainer>
            <Button onClick={() => next()}>
              {step === 0
                ? "Get Started"
                : step === 7
                ? "Generate Report"
                : "Next"}
            </Button>
          </ButtonContainer>
        )}
      </Content>
    </Container>
  );
};

export default Create




const Container = styled.div`
width:100%;
display:flex;
flex-direction:row;
margin-top:20px;
max-width:1200px;
`

const Content = styled.div`
  width:calc(100% - 400px);
  font-family:'Raleway';
  padding:30px;
  padding-left:50px;
  margin-top:50px;
  border-left:1px solid #E0E0E0;
`



const Side = styled.div`
  width:450px;
  padding:20px;
  padding-top:30px;
  padding-left:40px;
`


const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 10px;
    color:#2A3039;
`

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    font-family: 'Raleway';
    margin-bottom:30px;
`

const Button = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    margin-top:30px;
`