import React, { useState,useEffect } from 'react';
import Chart from "react-apexcharts";
import styled, { keyframes } from "styled-components";
import Likelihood from '../Likelihoodv2';
import Correlations from './Correlations';
import Factor from './Factor';


const Kindred = ({core_data}) =>{
    console.log(core_data)
    const [selectedNav,setSelectedNav] = useState(0)

    return (
        <Container>
        <T1>Kindred Insights</T1>
        <Description>
          We've connected to your Kindred data via api to provide you with insights on your team's emotional intelligence and how it connects to culture change efforts within your organization.
        </Description>


        <Content>
        <SideNav>
                <NavItem selected={selectedNav == 0} onClick={()=>setSelectedNav(0)}>
                    Overview
                </NavItem>
                {
                    core_data?.questions.dimensions.map((item,index)=>{

                        return item.factors.map((factor)=>{
                            return <NavItem selected={selectedNav == factor.title} onClick={()=>setSelectedNav(factor.title)}>
                                {factor.title}
                            </NavItem>
                        })
                    })
                }
            </SideNav> 


            <MainContent>
                {selectedNav== 0 ?<Correlations/> : <Factor /> }
                {/* <Likelihood/> */}
            </MainContent>

        </Content>
        

        </Container>
    )
}

export default Kindred;




const Container = styled.div`
  width: 100%;
  font-family:"Raleway";
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
  max-width:800px;
`;

const Content = styled.div`
    width:100%;
    display:flex;
    flex-wrap:wrap;
`

const MainContent = styled.div`
    width:calc(100% - 175px);
    padding:0px 20px;
`


const SideNav = styled.div`
    width:175px;
    border-right:1px solid #DFDFDF;
`


const NavItem = styled.div`
    padding:10px;
    cursor:pointer;
    width:150px;
    color:${props=>props.selected?'#2D70E2':'#666D79'};
    background-color:${props=>props.selected?'#F8FAFF':'#fff'};
    font-weight:${props=>props.selected?'bold':'normal'};
    font-size:12px;
    border-radius:5px;
    &:hover{
        background:#f7f7f7;
    }
`