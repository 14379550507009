import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import Side from "./Side";
import Footer from "./Footer";
import { getFeedbackOTP } from "../../utils/cultureUtils";
import Table from "./Table";

Font.register({
  family: "Barlow",
  src: Barlow,
  format: "truetype",
});

const Comments = (props) => {
  const { structure, list, categories, filterData, isFrench } = props;

  const tableTemplate = [{ title: "Feedback", width: "100%" }];

  const getFeedbackTable = (question, index) => {
    const feedbackData = getFeedbackOTP(
      filterData,
      structure,
      categories,
      question
    );

    const questionText = structure.comments.find((c) => {
      return c.order === question;
    })?.question;

    const getSentiment = (sent) => {
      if (sent > 2) {
        return "Positive";
      }
      if (sent < -2) {
        return "Negative";
      }
      return "Neutral";
    };

    const getRisk = (risk) => {
      if (risk > 70) {
        return `High`;
      }
      if (risk > 20) {
        return `Moderate`;
      }
      return `Low`;
    };

    if (!feedbackData || feedbackData.length === 0) {
      return null;
    }

    const tableData = feedbackData
      .filter((f) => f.text !== "")
      .map((feedback) => {
        return [`${feedback.text}`];
      });

    const tableContent = {
      rows: tableTemplate,
      data: tableData,
    };

    return (
      <View>
        <View style={styles.section} wrap={false}>
          <Text style={styles.question}>{questionText}</Text>
        </View>
        <View>
          <Table
            data={tableContent}
            headerColor={props.tableBorderColor}
            wrapRow={false}
          />
        </View>
      </View>
    );
  };

  return (
    <>
      <Page style={styles.page}>
        <Text style={[styles.T2, { color: props.titleColor }]}>
          Qualitative Comments pertaining to Leadership and Vision
        </Text>
        {list.map((q, i) => {
          return getFeedbackTable(q, i);
        })}
        <Side />
        <Footer isFrench={isFrench} />
      </Page>
    </>
  );
};

export default Comments;

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  tableStyle: {
    width: "100%",
    border: "0.5px solid grey",
    marginTop: 20,
  },
  TD: {
    fontSize: 10,
    padding: 4,
  },
  H: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: 3,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
  },
});
