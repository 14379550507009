import React,{useState} from 'react';
import styled from 'styled-components';
import {Icon} from 'semantic-ui-react'

const Upload = ({handleFileUpload}) =>{

    return (
        <Container>
            <Title>Upload Group Data</Title>

            <Info>
                <T2>Preparing a CSV</T2>
                <Description>
                    <ul>
                        <li>Use a spreadsheet program like Excel or Google Sheets to create a CSV file.</li>
                        <li>Make sure the first row of your CSV contains column headings.</li>
                        <li>Save your CSV file.</li>
                    </ul>
                </Description>
                <Row>
                    <Blue>Download Guide</Blue>
                    <Blue>Download Template</Blue>
                </Row>
                
            </Info>

            <UploadArea>
                <Icon name="cloud upload" size="huge" style={{color:'grey'}}/>
                <T3>Drag a file here or <input type="file" accept=".csv" onChange={handleFileUpload} /></T3>
                <T4>Supports CSV</T4>
            </UploadArea>
        </Container>
    )

}

export default Upload

const Container = styled.div`
    font-family:'Raleway';
    margin-bottom:40px;
`

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:50px;
`
const Info = styled.div`
    margin-bottom:40px;
`
const T2 = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`
    line-height:1.7;
    margin-bottom:20px;
`

const Row = styled.div`
    display:flex;
    margin-left:30px;
`

const Blue = styled.div`
    color:#2D70E2;
    margin-right:20px;
    cursor:pointer;
`

const UploadArea = styled.div`
    border:1px dashed #E0E0E0;
    border-radius:10px;
    padding:70px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    cursor:pointer;
`

const T3 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:0px;
    margin-top:20px;
`

const T4 = styled.div`
    font-size:12px;
    color:#666D79;
`