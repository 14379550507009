import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import Chart from "react-apexcharts";
import { Icon,Progress,Label,Tab } from 'semantic-ui-react'



const Predictions = () =>{


	return (
		<Container>
			 <SquareContainer>
			 	<Square width={75} right={true}>
			 		<T3>Performance and People Analysis</T3>
					<D1>Comparison of OKR and Culture or Leadership Analytics</D1>
			 		<Chart options={data2.options} series={data2.series} type="line" height={400} />
			 	</Square>


			 	<Square width={25} >
					
					<T3>Predicted Objective Performance</T3>
					<D1>Likelihood of meeting quarterly revenue targets</D1>
					<ChartContainer>
					<Chart options={data.options} series={data.series} type="radialBar" height={300} />
					</ChartContainer>



		      	</Square>
		      	<Square  width={60} top={true}>
		      		<T3>Performance Optimization Potential</T3>
					<D1>Factors impacting performance prediction</D1>

					<Chart options={data3.options} series={data3.series} type="area" height={400} />

		      	</Square>
		      	<Square  width={40} right={true} top={true}>
		      		<T3>Contributing Factors</T3>
					<D1>Factors impacting performance prediction</D1>

					<Factor>
						<LabelContainer><Label size="tiny">Value : <strong>21,000$</strong></Label></LabelContainer>
						<FTitle>Psychological Safety</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={70} size="small" progress/>
					</Factor>

					<Factor>
						<LabelContainer><Label size="tiny">Value : <strong>4,500$</strong></Label></LabelContainer>
						<FTitle>Mental Health</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={15} size="small" progress/>
					</Factor>

					<Factor>
						<LabelContainer><Label size="tiny">Value : <strong>3,000$</strong></Label></LabelContainer>
						<FTitle>Leadership</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={10} size="small" progress/>
					</Factor>

					<Factor>
						<LabelContainer><Label size="tiny">Value : <strong>1,500$</strong></Label></LabelContainer>
						<FTitle>Accountability</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={5} size="small" progress/>
					</Factor>

		      	</Square>

		      

		      	
		      </SquareContainer>
		</Container>
		)
}


export default Predictions


const Container = styled.div`
	
`

const SquareContainer = styled.div`
	display:flex;
	flex-wrap:wrap;

`



const ChartContainer = styled.div`
	margin-top:-20px;
	margin-bottom:-30px;
`


const Description = styled.div`

`

const D1 = styled.div`
	font-family: 'Barlow', sans-serif;
`
const T3 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:20px;
	font-weight:300;
	margin-bottom:10px;
`

const Right = styled.div`
	width:calc(100% - 400px);
	padding:30px 20px;
`

const Line = styled.div`
	width:100%;
	height:1px;
	background-color:lightgrey;
	margin-top:20px;
	margin-bottom:20px;
`

const Square = styled.div`
	border-right:${props=>props.right?'1px solid lightgrey':'none'};
	width:${props=>props.width+'%'};
	padding:20px 10px;
	border-top:${props=>props.top?'1px solid lightgrey':'none'};
`

const Score = styled.div`
	font-size:80px;
	line-height:1;
	font-family: 'Barlow', sans-serif;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:20px;
`

const Sub = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-family: 'Barlow', sans-serif;
`

const Factor = styled.div`
	width:100%;
	margin-top:20px;
	padding-right:20px;
	position:relative;

`

const FTitle = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:16px;
	font-weight:600;
	margin-bottom:5px;	
`

const FDescription = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:12px;
	margin-bottom:10px;	
`

const LabelContainer = styled.div`
	position:absolute;
	right:20px;
	top:0px;
`

const data = {
          
            series: [80],
            options: {
              chart: {
                height: 350,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '50%',
                  },
                  dataLabels: {
                    show: true,
                    name: {
                      offsetY: -10,
                      show: true,
                      color: '#888',
                      fontSize: '17px'
                    },
                    value: {
                      formatter: function(val) {
                        return parseInt(val);
                      },
                      color: 'black',
                      fontSize: '36px',
                      show: true,
                    }
                  },
                },
              },
              
              labels: ['Percentile'],
              fill: {
                colors:['#b5b5b5']
              },

            },
          
          
          }

const data2 = {
	series: [

              {
                name: "Culture Score",
                data: [8.2, 8.5, 9,7.6,8.2, 8.5, 6,5.6]
              },
               {
                name: "OKR Percentage",
                data: [80,110,90,50,60,70,90,130]
              }
            ],
            options: {
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: false,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left'
              },
              colors:['#E3B01F','#b5b5b5'],
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               stroke: {
          width: [5, 2],
          curve: 'smooth',
          dashArray: [0, 8]
        },  xaxis: {
                categories: ['Q1 2020', 'Q2 2020', 'Q3 2020', 'Q4 2020', 'Q1 2021', 'Q2 2021', 'Q3 2021','Q4 2021'],
              },
              yaxis: [{
                title: {
                  text: 'Culture'
                },
                min: 0,
                max: 10,
                 decimalsInFloat: 0,
              },
              {
				    opposite: true,
				    title: {
				      text: "OKR Percentage"
				    }
				  }
              ]
            }
}


const data3 ={
          
            series: [{
              name: 'Target',
               type:'area',
              data: [10000,20000,30000,40000,50000,60000,80000]
            }, {
              name: 'Progress',
               type:'area',
              data: [7000,13000,25000,null]
            },
            {
              name: 'Predicted',
              type:'line',
              data: [null,null,25000,34750,44500,54250,64000]
            },
            {
              name: 'Optimized',
              type:'line',
              data: [null,null,25000,41250,57500,73750,90000]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                toolbar: {
                  show: false
                },
              },
               annotations: {
				  xaxis: [
				    {
				      x:new Date("2018-09-19T02:30:00.000Z").getTime(),
				      borderColor: '#000',
				      label: {
				        borderColor: '#000',
				        orientation: 'horizontal',
				        text: '31%'
				      }
				    }
				  ],
				  points: 
					[
					  {
					    x: new Date("2018-09-19T06:30:00.000Z").getTime(),
					    y: 64000,
					    marker: {
					      size: 8,
					      fillColor:'#3260a8'
					    },
					    label: {
					      text: 'Predicted',
					      borderWidth:0,
					      style:{
					      	fontWeight:'bold'
					      }
					    }
					  },
					  {
					    x: new Date("2018-09-19T06:30:00.000Z").getTime(),
					    y: 90000,
					    marker: {
					      size: 8,
					      fillColor:'#32a852'
					    },
					    label: {
					      borderWidth:0,
					      style:{
					      	fontWeight:'bold'
					      },
					      text: 'Optimized'
					    }
					  }
					]
				},
             
              dataLabels: {
                enabled: false
              },
              colors:['#b5b5b5','#b5b5b5','#3260a8','#32a852'],
              stroke: {
              	dashArray: [0,0,4,4],
                curve: 'smooth',
                width:[0,5,3,3]
              },fill:{
              	type:['gradient','solid','dahsed'],
              	opacity:[0.3,1,1]
              },
              yaxis: {
			  labels: {
			    formatter: function (value) {
			      return value + "$";
			    }
			  },
			},
			grid:{
				padding:{
					right:30
				}
			},
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          }