import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Dropdown } from "semantic-ui-react";

const Filter = ({ structure, selected, setSelected }) => {
	const structureOptions = () => {
		let _options = [];
		_options.push({
			key: 0,
			text: "Overview",
			value: null,
		});

		structure.categories[0].options.map((item, i) => {
			_options.push({
				key: i + 1,
				text: item.name,
				value: i,
			});
		});

		return _options;
	};

	const [options, setOptions] = useState(structureOptions());

	return (
		<div>
			<Dropdown
				placeholder="Select Location"
				selection
				options={options}
				value={selected}
				onChange={(e, { value }) => setSelected(value)}
			/>
		</div>
	);
};

export default Filter;
