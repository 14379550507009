import styled from "styled-components";

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const H2 = styled.h2`
  color: #2a3039;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  margin-top: 20px;
`;

const H3 = styled.h3`
  color: #2a3039;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
  margin-top: 18px;
`;

const H4 = styled.h4`
  color: #2a3039;
  font-weight: bold;
  margin-bottom: 6px;
  font-size: 12px;
  margin-top: 16px;
`;

const H5 = styled.h5`
  color: #2a3039;
  font-weight: bold;
  margin-bottom: 4px;
  font-size: 10px;
  margin-top: 14px;
`;

const P = styled.p`
  margin-bottom: 10px;
  color: #2a3039;
  font-size:12px;
`;

const LI = styled.li`
  margin-bottom: 5px;
  font-size:12px;
`;

const UL = styled.ul`
  margin-bottom: 10px;
  padding-left: 20px;
  font-size:12px;
`;

// Utility to parse Markdown
export const parseMarkdown = (markdown) => {
  const lines = markdown.split('\n');
  const elements = [];
  let currentListItems = null;

  lines.forEach((line, index) => {
    if (line.trim() === '') {
      // Empty line, do nothing or handle as per requirement
      if (currentListItems) {
        elements.push(<UL key={`ul-${index}`}>{currentListItems}</UL>);
        currentListItems = null;
      }
      return;
    }

    if (line.startsWith('# ')) {
      elements.push(<H1 key={index}>{parseInlineMarkdown(line.slice(2))}</H1>);
    } else if (line.startsWith('## ')) {
      elements.push(<H2 key={index}>{parseInlineMarkdown(line.slice(3))}</H2>);
    } else if (line.startsWith('### ')) {
      elements.push(<H3 key={index}>{parseInlineMarkdown(line.slice(4))}</H3>);
    } else if (line.startsWith('#### ')) {
      elements.push(<H4 key={index}>{parseInlineMarkdown(line.slice(5))}</H4>);
    } else if (line.startsWith('##### ')) {
      elements.push(<H5 key={index}>{parseInlineMarkdown(line.slice(6))}</H5>);
    } else if (line.startsWith('- ')) {
      if (!currentListItems) {
        currentListItems = [];
      }
      currentListItems.push(
        <LI key={index}>{parseInlineMarkdown(line.slice(2))}</LI>
      );
    } else {
      if (currentListItems) {
        elements.push(<UL key={`ul-${index}`}>{currentListItems}</UL>);
        currentListItems = null;
      }
      elements.push(<P key={index}>{parseInlineMarkdown(line)}</P>);
    }
  });

  // Handle any remaining list items
  if (currentListItems) {
    elements.push(<UL key={`ul-end`}>{currentListItems}</UL>);
    currentListItems = null;
  }

  return elements;
};

// Utility to parse inline Markdown (bold and italic)
const parseInlineMarkdown = (text) => {
  const regex = /(\*\*\*(.*?)\*\*\*|\*\*(.*?)\*\*|\*(.*?)\*)/g;
  const tokens = [];
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    if (match.index > lastIndex) {
      tokens.push(text.slice(lastIndex, match.index));
    }
    if (match[2]) {
      // bold and italic: ***text***
      tokens.push(
        <strong key={match.index}>
          <em>{match[2]}</em>
        </strong>
      );
    } else if (match[3]) {
      // bold: **text**
      tokens.push(<strong key={match.index}>{match[3]}</strong>);
    } else if (match[4]) {
      // italic: *text*
      tokens.push(<em key={match.index}>{match[4]}</em>);
    }
    lastIndex = regex.lastIndex;
  }
  if (lastIndex < text.length) {
    tokens.push(text.slice(lastIndex));
  }
  return tokens;
};
