export function CheckIcon(fill) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.7156 5.2393C16.9694 5.49314 16.9694 5.9047 16.7156 6.15854L7.65381 15.2203L3.28413 10.8506C3.03029 10.5968 3.03029 10.1852 3.28413 9.93138C3.53797 9.67754 3.94953 9.67754 4.20337 9.93138L7.65381 13.3818L15.7963 5.2393C16.0502 4.98546 16.4617 4.98546 16.7156 5.2393Z" fill="#1D9A7D"/>
        </svg>
    )
}

export function CloseIcon(fill) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.14272 6.14272C6.333 5.95243 6.64152 5.95243 6.83181 6.14272L17.8573 17.1682C18.0476 17.3585 18.0476 17.667 17.8573 17.8573C17.667 18.0476 17.3585 18.0476 17.1682 17.8573L6.14272 6.83181C5.95243 6.64152 5.95243 6.333 6.14272 6.14272Z" fill={fill}/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8573 6.14272C18.0476 6.333 18.0476 6.64152 17.8573 6.83181L6.83181 17.8573C6.64152 18.0476 6.333 18.0476 6.14272 17.8573C5.95243 17.667 5.95243 17.3585 6.14272 17.1682L17.1682 6.14272C17.3585 5.95243 17.667 5.95243 17.8573 6.14272Z" fill={fill}/>
        </svg>
    )
}
