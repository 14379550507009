import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Modal,
  Form,
  Icon,
  Dropdown,
  Header as SemanticHeader,
} from "semantic-ui-react";
import { viewIcon, deleteIcon } from "../../../reports/components/Icons";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_ADD_EMPLOYEE_REPORT_ACCESS,
  SG_REMOVE_EMPLOYEE_REPORT_ACCESS,
} from "constants/actions";

const userMap = (usersList, employeeList, selectedList, addedList) => {
  const empList = employeeList?.employees;
  const inactiveList = empList?.filter(
    (emp) => emp.status === 99 || emp.status === 98 || emp.status === 108
  );

  const enabledLogins = empList?.filter((emp) => emp.enable_login === true);

  let filteredList = enabledLogins.filter(
    (emp) => !inactiveList.find((inactive) => inactive.id === emp.key)
  );

  // remove users that are already added
  if (selectedList?.length > 0) {
    filteredList = filteredList.filter(
      (emp) => !selectedList.find((selected) => selected.employee === emp.id)
    );
  }

  if (addedList?.length > 0) {
    filteredList = filteredList.filter(
      (emp) => !addedList.find((added) => added.employee === emp.id)
    );
  }

  const list = filteredList?.map((user) => {
    const employeeObj = usersList?.find((emp) => user.id === emp?.employee_id);

    return {
      key: employeeObj?.employee_id,
      value: employeeObj?.employee_id,
      text: `${employeeObj?.employee_full_name}`,
      id: user?.id,
    };
  });

  return list;
};

const AddUserAccessModal = ({ add, modalOpen, closeModal, addedUsers }) => {
  const [accessUsersList, setAccessUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [optionsList, setOptionsList] = useState([]);

  const { get_employee_org_category, get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_employee_org_category: state.employee_category?.employee_org_category,
    }),
    shallowEqual
  );

  const handleUserAdd = (value) => {
    if (!accessUsersList.includes(Number(value))) {
      setAccessUsersList([...accessUsersList, Number(value)]);
      const user = get_employee_org_category.find(
        (u) => u.employee_id === value
      );
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  useEffect(() => {
    if (get_employee_org_category?.length > 0 && get_employees?.employees) {
      const usersList = userMap(
        get_employee_org_category,
        get_employees,
        selectedUsers,
        addedUsers
      );
      setOptionsList(usersList);
    }
  }, [
    get_employee_org_category,
    get_employees,
    selectedUsers,
    accessUsersList,
  ]);

  return (
    <Modal open={modalOpen} onClose={closeModal} size="small">
      <Modal.Header>Add user access</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalTitle>Add user access to this report</ModalTitle>
          <ModalDescription>
            Add users to this report to give them access to view the report.
          </ModalDescription>
          <ModalRow>
            <Dropdown
              placeholder="Select a user"
              fluid
              search
              value={selectedUsers}
              selection
              onChange={(e, { value }) => handleUserAdd(value)}
              options={optionsList}
            />
          </ModalRow>
          <AssigneeTable onMouseOut={() => setHoveredRow(null)}>
            <AssigneeHeader>
              <AssigneeHeaderCell width={50}>Name</AssigneeHeaderCell>
              <AssigneeHeaderCell width={50}>Actions</AssigneeHeaderCell>
            </AssigneeHeader>
            {selectedUsers?.map((user, i) => {
              return (
                <AssigneeRow
                  key={user.employee_id}
                  onMouseOver={() => setHoveredRow(i)}
                  style={{
                    backgroundColor:
                      hoveredRow === i ? "#f0f0f0" : "transparent",
                  }}
                >
                  <AssigneeCell width={50}>
                    {user.employee_full_name}
                  </AssigneeCell>
                  <AssigneeCell
                    width={50}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        setSelectedUsers(
                          selectedUsers.filter(
                            (u) => u.employee_id !== user.employee_id
                          )
                        );
                        setAccessUsersList(
                          accessUsersList.filter(
                            (id) => id !== user.employee_id
                          )
                        );
                      }}
                    >
                      <Icon name="trash" />
                    </DeleteButton>
                  </AssigneeCell>
                </AssigneeRow>
              );
            })}
          </AssigneeTable>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModal()}>Cancel</Button>
        <Button
          content="Add"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            add(accessUsersList);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const RemoveUserAccessModal = ({ unassign, modalOpen, closeModal }) => {
  const [text, setText] = useState("");

  return (
    <Modal open={modalOpen} onClose={closeModal} size="small">
      <Modal.Header>Remove user access from this report</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalTitle>
            Are you sure you want to remove this users access to the report?
          </ModalTitle>
          <ModalDescription>
            When you remove a user, they will no longer have access to this
            report.
          </ModalDescription>
          <ModalDescription>
            Please type the word <strong>DELETE</strong> in the box below to
            confirm the removal of access for this user.
          </ModalDescription>
        </Modal.Description>
        <Input value={text} onChange={(e) => setText(e.target.value)} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => closeModal()}>Cancel</Button>
        <Button
          content="Remove Access"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            unassign();
          }}
          negative
          disabled={text.toLocaleLowerCase() != "delete"}
        />
      </Modal.Actions>
    </Modal>
  );
};

const ReportsTab = ({ task, id, employees }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userList, setUserList] = useState([]);

  const { get_employee_org_category } = useSelector(
    (state) => ({
      get_employee_org_category: state.employee_category?.employee_org_category,
    }),
    shallowEqual
  );

  const openAccessModal = () => setIsModalOpen(true);
  const closeAccessModal = () => setIsModalOpen(false);

  const handleAddUserAccess = (list) => {
    if (list.length > 0) {
      dispatch({
        type: SG_ADD_EMPLOYEE_REPORT_ACCESS,
        payload: {
          task_id: Number(id),
          employee_id_list: list,
        },
      });
    }
    closeAccessModal();
  };

  const handleRemoveUserAccess = (list) => {
    if (selectedUser) {
      dispatch({
        type: SG_REMOVE_EMPLOYEE_REPORT_ACCESS,
        payload: {
          task_id: Number(id),
          employee_id_list: [selectedUser],
        },
      });
    }

    setSelectedUser(null);
  };

  useEffect(() => {
    if (task?.employee_access && get_employee_org_category?.length > 0) {
      const accessList = task?.employee_access;
      const userList = get_employee_org_category?.filter((emp) =>
        accessList.includes(emp.employee_id)
      );
      setUserList(userList);
    }
  }, [task, get_employee_org_category]);

  const closeConfirmModal = () => {
    setSelectedUser(null);
  };

  return (
    <Container>
      <Header>
        <Title>Access</Title>
        <Button primary onClick={openAccessModal}>
          Add User Access
        </Button>
      </Header>
      <Table>
        <TableHeader>
          <TH width={80}>User</TH>
          {/* <TH width={60}>Access</TH> */}
          <TH width={20}>Actions</TH>
        </TableHeader>
        {userList?.map((user) => {
          return (
            <TableRow key={user.employee_id}>
              <TD width={80}>{user.employee_full_name}</TD>
              <TD width={20}>
                <ViewButton
                  onClick={() => {
                    setSelectedUser(user.employee_id);
                  }}
                >
                  {deleteIcon()}
                </ViewButton>
              </TD>
            </TableRow>
          );
        })}
      </Table>
      {isModalOpen && (
        <AddUserAccessModal
          add={handleAddUserAccess}
          modalOpen={isModalOpen}
          addedUsers={userList}
          closeModal={closeAccessModal}
        />
      )}
      {selectedUser && (
        <RemoveUserAccessModal
          unassign={handleRemoveUserAccess}
          modalOpen={selectedUser}
          closeModal={closeConfirmModal}
        />
      )}
    </Container>
  );
};

export default ReportsTab;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Table = styled.div`
  width: 100%;
  border-collapse: collapse;
`;

const ViewButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TableRow = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const ModalRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
`;

const ModalDescription = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
`;

const Input = styled.input`
  margin-top: 20px;
  height: 40px;
  padding: 10px;
  border: 3px solid #2d70e2;
  color: #666d79;
  font-family: "Raleway";
  border-radius: 5px;
`;

const AssigneeTable = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  font-family: "Raleway", sans-serif;
  margin-top: 40px;
`;

const AssigneeHeader = styled.div`
  display: flex;
  width: 100%;
`;

const AssigneeHeaderCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
`;

const AssigneeRow = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
`;

const AssigneeCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;
