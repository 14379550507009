import styled, { keyframes } from "styled-components";
import React from "react";
import DataChart from "./chart";
import {
  grandientsAboveNorm,
  dataLabels,
  defaultColorGradient,
  grandientsBelowNorm,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subsubtext,
  Subtext,
} from "reports/DataStories/common/styles";
import { feedbackAnalysisIcon } from "reports/DataStories/common/icons";

const AIFeedback = (props) => {
  const type = props?.id === "lowest_factor_feedback1" ? "Lowest" : "Highest";
  // remove any \n\n from the text

  const aiText = props.calculatedData?.text?.replace(/\n\n/g, "");

  // Create an array from the text property, splitting it by the number and period ex: 1. 2. 3.
  const textArray = aiText?.split(/\d+\.\s/);

  textArray?.shift();
  return (
    <Container>
      <ScreenTitleExtended delay={1}>
        {props.calculatedData.factor_name} Analysis
      </ScreenTitleExtended>
      <ChartWrapper delay={1.3}>
        <DataChart
          data={props.calculatedData.data}
          categoryName={props?.categoryName}
        />
      </ChartWrapper>
      <SubtitleWrapper delay={1.5}>
        <IconWrapper>{feedbackAnalysisIcon()}</IconWrapper>
        <Subtitle>
          Themes from comments related to {props.calculatedData.factor_name}:
        </Subtitle>
      </SubtitleWrapper>
      <SubtitleDesc delay={1.6}>
        Our internal AI system has summarised the key themes from all comments
        provided in this area.
      </SubtitleDesc>
      <DescriptionWrapper delay={1.9}>
        {textArray &&
          textArray?.length > 0 &&
          textArray.slice(0, 3).map((text, index) => {
            const splitText = text.split(":");

            if (splitText.length === 1)
              return (
                <DescriptionBox>
                  <DescriptionNumber>{index + 1}</DescriptionNumber>
                  <Description key={index}>{text}</Description>
                </DescriptionBox>
              );

            return (
              <DescriptionBox>
                <DescriptionNumber>{index + 1}</DescriptionNumber>

                <Description key={index}>
                  <strong>{splitText[0]}:</strong> {splitText[1]}
                </Description>
              </DescriptionBox>
            );
          })}
      </DescriptionWrapper>
    </Container>
  );
};

export default AIFeedback;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
`;

const DescriptionBox = styled.div`
  background-color: #f8faff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const DescriptionNumber = styled.div`
  font-weight: 700;
  color: #1f54aa;
  margin-right: 20px;
  font-size: 20px;
`;

const DescriptionWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SubtitleDesc = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const ScreenTitleExtended = styled(ScreenTitle)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 5px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  line-height: 1.4;
`;

const Description = styled.div`
  font-size: 14px;
`;

const ChartWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;
