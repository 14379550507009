import React, { useEffect, useState, useRef } from "react";
import {Loader} from "semantic-ui-react";
import styled from "styled-components";

const Loading = () => {

    return (
        <Container>
            <Loader active inline/>
        </Container>
    );
}

export default Loading;

const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    height:500px;
`