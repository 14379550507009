import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

//
import EdiSurvey from "ediSurvey";
//
import EDIReport from "reports/EDI/index";
import Diversity from "reports/EDI/Diversity/index";
import Insights from "reports/EDI/Insights/index";
import Payroll from "reports/EDI/Payroll/index";
import Maps from "reports/Audit/Map/Maps";
import Categories from "settings/Categories/index";
import PDFReport from "reports/DEIPDFReport/index";
import Action from "reports/Action";
import AuditReport from "reports/Audit/index";
import DataScience from "reports/Audit/DataScience/index";
import TeamSummary from "reports/Audit/TeamSummary/index";
import Recommendations from "reports/Recommendations/index"
import AI from "reports/AI/index"
import Outcomes from "reports/Outcomes/index"
import Feedback from "reports/Feedback/index"
import GeneratePDF from "reports/PDFReport/GenerateWindow";
//
import Lost from "404";
//
function RoutesTeamTool() {
  const navigate = useNavigate();
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoute, setValidRoute] = useState(false);
  const [employee, setEmployee] = useState(false);

  const { get_auth, get_organizations, get_selectedOrg, get_employees } =
    useSelector(
      (state) => ({
        get_auth: state.auth,
        get_error: state.errors,
        get_organizations: state.organizations,
        get_selectedOrg: state.selectedOrg,
        get_employees: state.employees,
      }),
      shallowEqual
    );

  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      if (Number(EmployeeId)) {
        setEmployee(get_employees[get_auth.employee_id]);
      }
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth, EmployeeId, get_employees]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId]);
    } else {
      setOrganization(false);
    }
  }, [organizationId, get_organizations]);

  useEffect(() => {
    const Found = organization?.services_enabled
      ?.filter((srv) => srv?.enabled === true)
      .map((sr) => sr.name);

    setValidRoute(Found);
  }, [organization]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!validRoute?.includes("EDI")) {
        return navigate("/app/unsubscribed");
      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization, validRoute, navigate]);

  useEffect(() => {
    // role < 7
    // account_type < 9
    if (validRoute) {
      if (validRoute?.includes("Teamtool")) {
        if (employee?.account_type > 7) {
          if (employee?.role > 7) {
            return navigate("/app/access-denied");
          }
        }
      }
    }
  }, [employee, validRoute, navigate]);

  return (
    <>
      {validRoute && (
        <Routes>
          <Route path="/" element={<EdiSurvey />} />
          <Route path="report" element={<AuditReport />} />
          <Route path="diversity" element={<Diversity />} />
          <Route path="insights" element={<Insights />} />
          <Route path="map" element={<Maps />} />
          <Route path="api" element={<Payroll />} />
          <Route path="pdf" element={<PDFReport />} />
          <Route path={"action"} element={<Action />} />
          <Route path={"category"} element={<Categories />} />
          <Route path={"data_science"} element={<DataScience />} />
          <Route path={"group"} element={<TeamSummary />} />
          <Route path={"recommendations"} element={<Recommendations />} />
          <Route path={"outcomes"} element={<Outcomes />} />
          <Route path={"feedback"} element={<Feedback />} />
          <Route path={"AI"} element={<AI />} />
          <Route path={"generate-pdf"} element={<GeneratePDF />} />
          <Route path={"*"} element={<Lost />} />
        </Routes>
      )}
    </>
  );
}

export default RoutesTeamTool;
