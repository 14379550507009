import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import ModelCard from './ModelCard'

import Chart from "react-apexcharts";
import Modal from './Modal'
import Prediction from './Prediction'

const Model = () =>{
	const [selected,setSelected]= useState(false)


	if(!selected){

	return (
		<Container>
			
			 <CardArea>
			 	<div style={{position:'relative',width:'100%',display:'flex',alignItems:'center'}}>
			 	 <Title>Prediction Models</Title>
			 	 <Button>+ Model</Button>
			 	 </div>

			 	 <Top>
			 	 	<TH width={60}>Model</TH>
			 	 	<TH width={15}>Outcome</TH>
			 	 	<TH width={15}>Accuracy</TH>
			 	 	<TH width={10}>Actions</TH>
			 	 </Top>

				 <ModelCard color={'#476DFA'} type={'Turnover'} title={'Global turnover'} onClick={()=>setSelected(true)}/>
				 <ModelCard color={'#66D686'} type={'Performance Review'} title={'Regional PR'} onClick={()=>setSelected(true)}/>
				 <ModelCard color={'#615BF0'} type={'Sales'} title={'Unit sales per location'} onClick={()=>setSelected(true)}/>
				 <ModelCard color={'#E5657A'} type={'Churn'} title={'Quartlery churn by department'} onClick={()=>setSelected(true)}/>
				 <ModelCard color={'#EE7C35'} type={'Satisfaction'} title={'Job Satisfaction'} onClick={()=>setSelected(true)}/>
				 <ModelCard color={'#09E1C0'} type={'Productivity'} title={'Developer productivity'} onClick={()=>setSelected(true)}/>
				 <ModelCard color={'#476DFA'} type={'Health'} title={'Sick days'} onClick={()=>setSelected(true)}/>
			</CardArea>


		</Container>
		)
	}

	return (
		<Prediction/>
		)
}


export default Model




const Container = styled.div`
  font-family: "Poppins", sans-serif;
  padding: 30px 20px 0px 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px;
  font-weight:500;
  color: #414141;

`;


const T2 = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
margin-bottom:30px;
/* identical to box height */


color: #414141
`



const CardArea = styled.div`
	width:100%;

	padding-right:10px;
	height:100vh;
`



const Feature = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */

color: #7E7E7E;
width:200px;



`

const Bar = styled.div`
	width:calc(100% - 200px);
	border-radius:10px;
	height:12px;
	background: #C0C0C0;
	overflow:hidden;
`

const Color  = styled.div`
	width:${props=>props.value+'%'};
	height:100%;
	background-color:#5167DD;
	border-radius:4px;
`

const Num = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-align: right;

color: #7E7E7E;
margin-left:5px;
`

const Sub = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;
margin-bottom:20px;
color: #7E7E7E;
max-width:500px;
`

const Button = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size:10px;
line-height: 12px;
padding:5px 16px;
display: flex;
align-items: center;
justify-content:center;
text-align: center;
border-radius:20px;
color: #476DFA;
border:1px solid #476DFA;
position:absolute;
right:0px;
top:5px;
cursor:pointer;
`

const Top =styled.div`
	width:100%;
	display:flex;
	margin-bottom:10px;
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #414141;
`

const TH = styled.div`
	width:${props=>props.width+'%'};
	padding-left:20px;

`