import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import DatePicker from "react-datepicker";
import { Icon } from "semantic-ui-react";
import { SG_EDIT_GLOBAL_SCHEDULE } from "constants/actions";
// import { SelectDate, SelectWeekDay, SelectTime } from "../Settings";

import { SelectContainer, Save } from "../styled_list";
import "../style.css";
export const time = [
  { value: 8, text: "8:00am" },
  { value: 9, text: "9:00am" },
  { value: 10, text: "10:00am" },
  { value: 11, text: "11:00am" },
  { value: 12, text: "12:00pm" },
  { value: 13, text: "01:00pm" },
  { value: 14, text: "02:00pm" },
  { value: 15, text: "03:00pm" },
  { value: 16, text: "04:00pm" },
  { value: 17, text: "05:00pm" },
];

export const SelectTime = ({ selectedTime, MoveUp, MoveDown }) => {
  return (
    <SelectContainer margin={"10px"}>
      {selectedTime
        ? time.filter((fl) => fl.value === selectedTime)[0]?.text
        : ""}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 5,
          cursor: "pointer",
        }}
      >
        <Icon
          name="chevron up"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveUp}
        />
        <Icon
          name="chevron down"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveDown}
        />
      </div>
    </SelectContainer>
  );
};
const TimeOfSurvey = ({ organizationId, globalSchedule, setProcessing }) => {
  const dispatch = useDispatch();
  // const ref = React.createRef();
  const [selectedTime, setSelectedTime] = useState(globalSchedule.hour);

  // React.useEffect(() => {
  //   if (globalSchedule?.hour) {
  //     setSelectedTime(globalSchedule.hour);
  //   }
  // }, [globalSchedule]);
  // console.log(globalSchedule)

  function MoveUp() {
    // console.log('up')
    let newTime = selectedTime < 17 && selectedTime > 8 ? selectedTime + 1 : 17
    setSelectedTime(newTime)
    CreateUpdateSchedule(newTime)
  }

  function MoveDown() {
    // console.log('down')
    let newTime = selectedTime < 18 && selectedTime > 8 ? selectedTime - 1 : 8
    setSelectedTime(newTime)
    CreateUpdateSchedule(newTime)
  }

  function CreateUpdateSchedule(nwTime) {
    dispatch({
      type: SG_EDIT_GLOBAL_SCHEDULE,
      payload: {
        id: globalSchedule.id,
        hour: nwTime,
        organization: organizationId,
      },
    });
  }

  return (
    <>
      <SelectTime
        selectedTime={selectedTime}
        MoveUp={MoveUp}
        MoveDown={MoveDown}
        setSelectedTime={setSelectedTime}
        onClick={CreateUpdateSchedule}
      />
    </>
  );
};

export default TimeOfSurvey;
