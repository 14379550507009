import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, shallowEqual } from "react-redux";
import { Dropdown,Icon } from "semantic-ui-react";
import make_call from './Request'
import Questions from './Questions'
import Loading from './Loading'
import { useNavigate } from 'react-router-dom';
import Filters from './Filters'
import { filter_data } from "reports/Tag/calculations";

const PulseBuilder = () => {
    const navigate = useNavigate();
    //Local state
    const [factorDropdown, setFactorDropdown] = useState([]);
    const [selectedFactor, setSelectedFactor] = useState(null);
    const [outcomeQ, setOutcomeQ] = useState(null);
    const [organizationData, setOrganizationData] = useState(null);
    const [feedbackData, setFeedbackData] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState(5);
    const [parsedJSON, setParsedJSON] = useState(null);
    const [correctedData, setCorrectedData] = useState(null);
    const [jsonError, setJSONError] = useState(false);


    // bring in redux data for building pulse
    const filtered_data = useSelector((state) => state.audit?.filtered_data,shallowEqual);
    const core_data = useSelector((state) => state.audit?.core_data,shallowEqual);
    const get_organizations = useSelector((state) => state.organizations);
    const get_auth = useSelector((state) => state.auth);
    const get_survey_questions = useSelector((state) => state.surveyquestions);
    const selectedFilters = useSelector((state) => state.audit?.new_filters,shallowEqual);
    const [dataWithFilters, setDataWithFilters] = useState(null);

    // Get organization data for the outcomeQ beneath
    useEffect(() => {
        if (get_organizations) {
          setOrganizationData(
            get_organizations[
              get_auth?.organization_id || get_organizations?.organization?.id
            ]
          );
        }
      }, [get_organizations]);

    useEffect(()=>{
        if(filtered_data.length>0){
          if(filtered_data[0][0]){
            return setCorrectedData(filtered_data)
        }
        
        setCorrectedData([filtered_data])
        }
       
    },[filtered_data])

    useEffect(() => {
        if (selectedFilters.length > 0) {
          const filteredData = filter_data(correctedData[0], selectedFilters);
          setDataWithFilters(filteredData);
        } else {
          setDataWithFilters(null);
        }
      }, [selectedFilters]);

    // use Effect to get the outcomeQ set -- this should be in core data
    useEffect(() => {
        if (organizationData) {
          // We need to get the outcome question id
          // Then parse the outcome question list for the proper version
          const sortOrder = organizationData?.styling?.survey_sequence?.find(
            (item) => item.value === "outcome_question"
          )?.question?.sort_order;
    
          if (sortOrder) {
            const outcomeQ = get_survey_questions?.outcome_questions.find(
              (item) => item.sort_order === sortOrder
            );
            setOutcomeQ(outcomeQ);
          }
        }
      }, [core_data, organizationData]);


     // Update factor dropdown when coreData changes
     useEffect(() => {
        if (core_data) {
          let options = [{key:0, text:"All Factors", value:'All Factors'}] 
          core_data.questions.dimensions.flatMap((dimension, i) =>
          dimension.factors.map((factor) =>{
              options.push({
                  key: factor.id+factor.title,
                  text: factor.title,
                  value: { ...factor, dimension: i, type: 'factor' }
              })
          })
        );
  
      //Add the outcome factors here
        outcomeQ?.questions.map((q) => {
          options.push({
              key: q.id+q.title,
              text: q.name,
              value: { ...q, type: 'outcome' }
          })
          })
    
          setFactorDropdown(options);

        }
      }, [core_data,outcomeQ]);

      function parseResponse(responseString) {
       
        try {
            // Find the start and end of the JSON object
            const jsonStartIndex = responseString.indexOf("{");
            const jsonEndIndex = responseString.lastIndexOf("}");
    
            if (jsonStartIndex === -1 || jsonEndIndex === -1) {
                throw new Error("No valid JSON object found in the response.");
            }
    
            // Extract the JSON string
            const jsonString = responseString.slice(jsonStartIndex, jsonEndIndex + 1);
    
            // Parse the JSON string
            const parsedData = JSON.parse(jsonString);
            console.log(parsedData)
            return parsedData;
        } catch (error) {
            console.error("Error parsing response:", error.message);
            setJSONError(true);
            return null; // Return null if parsing fails
        }
    }

      


      useEffect(()=>{
        if(!message){
            setParsedJSON(null)
            return;
        } 
        setParsedJSON(parseResponse(message))
      },[message])

      useEffect(()=>{
        if(!correctedData) return;

        const data = dataWithFilters ? dataWithFilters : correctedData[0]
        if(data.length>0){

                   
            let _data = [];
            let _feedback = ""
            if(selectedFactor?.type == 'outcome'){
                data
                .filter(f=> "feedback_builder" in f)
                .filter(f=> "responses" in f.feedback_builder)
                .map((item) => {
                  item.feedback_builder.responses.filter(f=>f.q == parseInt(selectedFactor.id)).map((f) => {
                      _data.push({ feedback: f, categories: item.categories });

                  });
                });

                _data.map((item) => {
                  if(item.feedback.response){
                      _feedback+= `## Question:\n${item.feedback.question}\n`
                      _feedback+= `## Feedback:\n${item.feedback.response}\n\n`
                  }
              })
                  return setFeedbackData(_feedback);

            }

            data
             ?.filter((f) => "feedback" in f)
             ?.filter((f) => f?.feedback?.length > 0)
             ?.map((item) => {
                 item?.feedback?.map((f) => {
                        if(selectedFactor == 'All Factors'){
                         _data.push({ feedback: f, categories: item.categories });
                        }
                        else{
                            if(f.id == selectedFactor?.dimension && f.factor == selectedFactor?.id-1){
                                _data.push({ feedback: f, categories: item.categories });
                            }
                        }
                 });
             });


             _data.map((item) => {
                const isFollowUp = item.feedback?.prompt_feedback
                if(isFollowUp){
                    _feedback+= `## Question:\n${item.feedback.prompt_question}\n`
                    _feedback+= `## Follow-up Feedback:\n${isFollowUp}\n\n`
                }
                if(item.feedback.feedback){
                    _feedback+= `## Question:\n${item.feedback.question}\n`
                    _feedback+= `## Feedback:\n${item.feedback.feedback}\n\n`
                }
            });

            setFeedbackData(_feedback);


        }
       
      },[selectedFactor,correctedData,dataWithFilters])



      const make_gpt_call = async (userFeedback = "") => {
        setLoading(true);
        setJSONError(false);
        setMessage(""); // Clear any existing message
    
        let prompt = `Your job here is to create a pulse survey to dig into themes associated with feedback from a culture survey on ${selectedFactor?.title}.
    
        The pulse questions are intended to be used for quick data gathering beyond the larger survey, and are intended to be used to gather more specific feedback on the themes that emerged from the larger survey.
        On top of deeper details and context, we are looking to build actionable solutions based on the feedback provided. 
    
        Here is the survey feedback:
        ${feedbackData}
    
        Generate ${questions} questions for the pulse survey.
        Do not ever ask scale, or quantitative questions. Only ask open-ended questions.
    
        Use the following format, make sure you return only json data, and you must start your response with {
        
        {
            "questions": [ list of questions ],
            "title": "Short and descriptive title",
            "description": "The description should detail the mission of the survey, and what the outcome that we're looking for from a respondent."
        }
        `;
    
        try {
            const assistantResponse = await make_call(prompt); // No streaming, receive full response
            setMessage(assistantResponse); // Set the full response
        } catch (error) {
            console.error("Error making GPT call:", error);
            setMessage("An error occurred while generating the survey.");
        } finally {
            setLoading(false);
        }
    };
    


    const handleFactorChange = (e, { value }) => {
        setSelectedFactor(value);
      };

    return (
        <Container>
           
           

            <Content>
                <Back onClick={()=>navigate('/leader/audit/survey-selection')}>
                    <Icon name="chevron left"  /> Back
                </Back>
            <Title>AI Pulse Builder</Title>
            <Description>
                Select the number of questions you want to include, and the data you want incorporated into your pulse survey.
            </Description>
            <Filters/>
                <Options>
                <div style={{display:'flex'}}>
                    <div style={{ marginRight: 20 }}>
                        <OptionTitle>Number of questions</OptionTitle>
                        <Dropdown
                        placeholder='Select Factor'
                        selection
                        options={[1,3,5,7,10].map((i)=>({key:i,text:i,value:i}))}
                        onChange={(e,{value})=>setQuestions(value)}
                        value={questions}
                        />
                    </div>
                


                    <div style={{ marginRight: 20 }}>
                    <OptionTitle>Select a Factor</OptionTitle>
                    <Dropdown
                    placeholder='Select Factor'
                    selection
                    options={factorDropdown}
                    onChange={handleFactorChange}
                    value={selectedFactor}
                    />
                    </div>
                </div>
                

                <Button onClick={()=>make_gpt_call()}>
                    Generate Pulse Survey
                </Button>

            </Options>

            {loading && <Loading/>}
            {parsedJSON!=null && !jsonError && <Questions jsonData={parsedJSON}/>}
            {jsonError && 
            (<ErrorState>
                        <div>There was an error generating the survey. Please try again.</div>
                        <Button2 onClick={()=>make_gpt_call()}>
                            Try again
                        </Button2>
            </ErrorState>)}
            </Content>
            

        </Container>
    )

}


export default PulseBuilder;

const Container = styled.div`
    margin-top: 20px;
    font-family: "Raleway";
    padding:20px;
    display:flex;
    flex-direction:column;
    align-items:center;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #2A3039;
    margin-bottom: 15px;
`

const OptionTitle = styled.div`
    font-size:12px;
    font-weight:500;
    margin-bottom:5px;
`

const Button = styled.div`
    background-color:#2D70E2;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    cursor:pointer;
    margin-top:10px;
    font-weight:bold;
    display:inline-block;
    height:40px;
`

const Button2 = styled.div`
    background-color:#FEF3F4;
    color:#2A3039;
    padding:10px 20px;
    border-radius:5px;
    cursor:pointer;
    margin-top:10px;
    font-weight:bold;
    display:inline-block;
    height:40px;
`


const Content = styled.div`
    margin-top:20px;
    max-width:800px;
`

const Options = styled.div`
    width:100%;
    min-width:800px;
    display:flex;
    align-items:flex-end;
    justify-content:space-between;
    margin-top:20px;
`
const Back = styled.div`
    cursor: pointer;
    color: #666D79;
    font-size: 14px;
    margin-bottom: 20px;
`

const Description = styled.div`
    font-size: 14px;
    color: #666D79;
    margin-bottom: 30px;
`

const ErrorState = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
    height:400px;
`