import React,{useState} from 'react';

import styled, { keyframes } from "styled-components";
import Scatter from './Scatter'
import template from './template.json'
import Back from 'ProtoType/SimpleDash/Back'
import TopProgress from './TopProgress'

const Analysis = () =>{
    const [step,setStep] = useState(0)

    return (
        <Container>

           
            <Content>
                <Scatter step={step}/>

            </Content>
        </Container>
    )

}

export default Analysis;


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
flex-direction:row;
max-width:100%;
`

const Content = styled.div`
  width:700px;
  font-family:'Raleway';
  padding:30px;
  padding-left:50px;
  padding-top:65px;

`



const Side = styled.div`
  width:450px;
  padding:30px;
  padding-left:40px;
  background-color:#F8FAFF;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 10px;
    color:#2A3039;
`

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    font-family: 'Raleway';
    margin-bottom:30px;
`
