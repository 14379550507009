import React from 'react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import styled from 'styled-components'

function ImprovementModal({trigger,prompt,setPrompt,make_gpt_call2,message}) {
  const [open, setOpen] = React.useState(false)

  const make_gpt_call = () =>{
    make_gpt_call2()
    setOpen(false)
  }

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
        <Container>
            <Title>
                How would you like to improve this section?
            </Title>

            <Text>
                {message}
            </Text>

            <Input 
                value={prompt}
                onChange={(e)=>setPrompt(e.target.value)}
                placeholder='Enter your message here'/>

                <Actions>
                    <Button onClick={()=>make_gpt_call()}>Apply suggestion</Button>
                </Actions>
        </Container>
       
    </Modal>
  )
}

export default ImprovementModal



const Title = styled.div`
    font-size:20px;
    margin-top:20px;
    margin-bottom:30px;
    font-family:"Raleway";
    font-weight:bold;
`

const Input = styled.input`
    width:100%;
    height:50px;
    border-radius:5px;
    border:1px solid #EDEDED;
    padding-left:10px;
    font-family:"Raleway";
    font-size:14px;
    margin-bottom:10px;
`

const Container = styled.div`
    padding:20px;
`

const Actions = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`

const Text = styled.div`
    font-size:16px;
    margin-bottom:20px;
    font-family:"Raleway";
    line-height:1.5;
`