import styled, { keyframes } from "styled-components";
import React from "react";

import Overview from './Themes/Overview'
import ThemeAnalysis from './Themes/ThemeAnalysis'




const ThemeAnalytics = () =>{

	const [selected,setSelected] = React.useState(1)

	return (
		<Container>
			<Card>
				<Title>Theme Analytics</Title>
				<Tabs>
					<Tab selected={selected==1} onClick={()=>setSelected(1)}>Overview</Tab>
					<Tab selected={selected==2} onClick={()=>setSelected(2)}>Theme Analysis</Tab>
				</Tabs>

				{selected==1?<Overview/>:<ThemeAnalysis/>}
			</Card>

			
		</Container>
		)
}

export default ThemeAnalytics







const Container = styled.div`
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
`

const Card = styled.div`
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
	padding:40px;
	width:100%;
	display:flex;
	flex-direction:column;
	border-radius:8px;
	margin-bottom:10px;
`

const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:5px;
	font-size:26px;
	font-weight:bold;
	width:100%;
`

const Description = styled.div`
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:20px;
	width:100%;
`


const Tabs = styled.div`
	display:flex;
	width:100%;
	margin-top:20px;
`

const Tab = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px;
	font-size:16px;
	font-weight:${props=>props.selected?'bold':400};
	border-bottom:${props=>props.selected?'2px solid #6a00ff':400};
	color:${props=>props.selected?'black':'#9b9b9b'};
	cursor:pointer;
`

