import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import {
  grandientsAboveNorm,
  dataLabels,
  defaultColorGradient,
  grandientsBelowNorm,
  orgColorGradient,
  legendOptions,
  barChartOptions,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subsubtext,
  Subtext,
} from "reports/DataStories/common/styles";

const FactorScore = ({ data, calculatedData, manualInputs, id }) => {
  const regex =
    /The (.+?) factor in the (.+?) department by benchmark is (.+?)\. It scored a (\d+)% which puts it (-*[\d\.]+)% (.+?) the global norm\./;

  // Screen Text.
  const match = calculatedData?.text?.match(regex);
  const rankSelection = manualInputs?.manualInputs?.find(
    (input) => input.name === "Rank Value"
  );

  const strongText =
    id === "lowest_factor1" ? "Biggest gap" : "Biggest strength";
  const departmentName = match?.[2];
  const factor = match?.[3];
  const score = match?.[4];
  const percentile = match?.[5];
  const bottomOrTop = strongText === "Biggest gap" ? "below" : "above";
  const screenTitle =
    strongText === "Biggest gap" ? "Biggest gap" : "Biggest strength";

  // Chart Data/options
  let _chart = { ...data3 };
  // Create a new array of objects, containing the data values and labels at the same index.
  const dataMapping = calculatedData.data.values.map((val, index) => ({
    label: calculatedData.data.labels[index],
    value: val,
  }));
  // Sort the array of objects by value.
  // This will sort the data in descending order.
  // dataMapping.sort((a, b) => b.value - a.value);
  // Re-arrange the dataMapping so the last 2 values are swapped
  // This is to ensure the global average is always last.
  dataMapping.sort((a, b) => {
    if (a.label === "Global Average") return 1;
    if (b.label === "Global Average") return -1;
    return 0;
  });

  // Create a new array of values from the sorted array of objects.
  const sortedValues = dataMapping.map((item) => item.value);
  // Create a new array of labels from the sorted array of objects.
  const sortedLabels = dataMapping.map((item) => item.label);
  _chart.options.xaxis.categories = sortedLabels;
  _chart.options.yaxis.title.text = factor;
  _chart.options.dataLabels = dataLabels;

  if (!calculatedData?.text) {
    return null;
  }

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={1}>{screenTitle}</ScreenTitleExtended>
      <SubtextExtended delay={1.3}>
        The <strong>{strongText.toLowerCase()}</strong> in the {departmentName}{" "}
        department is
        <Box> {factor}</Box>. It scored a {score}% which puts it{" "}
        {Number(percentile)?.toFixed(1)}% {bottomOrTop} the global norm.
      </SubtextExtended>

      <ChartWrapper delay={1.3}>
        <Chart
          options={_chart.options}
          series={[
            {
              data: sortedValues.map((val) => Math.floor(val)),
            },
          ]}
          type="bar"
          height={400}
        />
      </ChartWrapper>
    </ScreenContainer>
  );
};

export default FactorScore;

const ChartWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const ScreenTitleExtended = styled(ScreenTitle)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubtextExtended = styled(Subtext)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const Box = styled.span`
  border-radius: 5px;
  font-weight: 600;
  color: #2d70e2;
`;

const data3 = {
  series: [
    {
      data: [6.7, 7.1, 7.5],
    },
  ],
  options: {
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 0.5,
        gradientToColors: ["#40B7C2", "#468ADE"],
        inverseColors: true,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 100],
        colorStops: [
          grandientsAboveNorm,
          orgColorGradient,
          defaultColorGradient,
        ],
      },
    },

    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
        distributed: true,
      },
    },
    colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
    xaxis: {
      categories: ["Your Team", "Your Organization", "Global Average"],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      decimalsInFloat: 1,
      title: {
        text: "Psychological Safety Scores",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
