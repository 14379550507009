import React,{useState} from 'react';
import styled from 'styled-components';
import IMG from 'assets/images/stock/ballon.jpg'
import IMG2 from 'assets/images/stock/desk.jpg'
import IMG3 from 'assets/images/stock/copilot.jpg'
import Box from './Box'
import { useNavigate } from 'react-router-dom';


const Dashboard = () =>{
    const navigate = useNavigate();

    const activateLoading = (route) =>{
        if(route){
            navigate(route)
        }
    }

    return (
        <Container>
            <Center>
                <Center>
                
                <Title>Group Data Portal</Title>

                <Box 
                    onClick={()=>activateLoading('/simple/prototype/groupdata/history')}
                    title={'Manage group data'} 
                    IMG={IMG}
                    color={'#2254AA'}
                    buttonText={'Manage'}
                    description={'Explore your people data and get insights into the most critical areas of development'}/>
                <Box 
                onClick={()=>activateLoading('/simple/prototype/groupdata/analysis/dashboard')}
                title={'Group data analytics'} 

                buttonText={'Analyze'}
                IMG={IMG2}
                color={"#2D70E2"}
                description={'Collaborate with our people data ai to build an actionable plan. Set milestones and goals to make sure youre seeing progress.'}/>
               
            </Center>
            </Center>
        </Container>
    )
}

export default Dashboard;


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
`

const Center = styled.div`
    width:800px;
    `
const Title = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:20px;
`