import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import Box from '../History/Box'
import {useNavigate} from 'react-router-dom'



const AnalysisDashboard = () =>{
    const navigate = useNavigate();
    const [active,setActive] = useState(1)

    return (
        <Container>
            <Center>
                <Title>Business outcome analytics</Title>
                <Description>
                    In depth reports connecting business outcomes to organizational culture.
                </Description>
                <Box
                      title={'The impact of Organizational Culture on Absenteeism and Turnover - Q4 2024'}
                      icon={'file'}
                      onClick={()=>navigate('/leader/prototype/groupdata/analysisdashboard/scatter')}
                      description={'March 20th, 2024'}/>

                <Box 
                    onClick={()=>navigate('/leader/prototype/groupdata/reports')}
                    title={'The impact of Organizational Culture on Customer Satisfaction - Q4 2024'}
                    icon={'book'}
                    read={true}
                    description={'Analysis built on Jan 5th, 2024 and based on the agent data collected on October 30th, 2024.'}/>
                    

            </Center>
        </Container>
    )

}

export default AnalysisDashboard


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:800px;
    `

const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`


const Description = styled.div`
    margin-bottom:30px;
`