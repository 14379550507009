import React from "react";
import styled, { keyframes } from "styled-components";

const ProgressBar = (props) => {
  let {
    size = 150,
    progress = 0,
    trackWidth = 10,
    trackColor = `#ddd`,
    indicatorWidth = 10,
    indicatorColor = "#2D70E2",
    indicatorCap = `round`,
    spinnerSpeed = 1,
    factor,
  } = props;

  // Check if progress is NaN and assign 0 in that case
if (isNaN(progress)) {
  progress = 0;
}

  const center = size / 2,
    radius =
      center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <ProgressContainer>
      <SvgWrapper style={{ width: size, height: size }}>
        <SvgPi style={{ width: size, height: size }}>
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
          <CircleIndicator
            style={{ animationDuration: spinnerSpeed * 1000 }}
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </SvgPi>
      </SvgWrapper>
      <InfoSection>
        <Percentage>{progress}%</Percentage>
        <InfoText>{factor}</InfoText>
      </InfoSection>
    </ProgressContainer>
  );
};

const InfoSection = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InfoText = styled.div`
  font-family: Raleway;
  color: #666d79;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  line-height: 20px;
`;

const Percentage = styled.div`
  font-family: Raleway;
  color: #2d70e2;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  min-width: 180px;
  max-width: 200px;
`;

const SvgWrapper = styled.div`
  position: relative;
`;

const SvgPi = styled.svg`
  transform: rotate(-90deg);
`;

const CircleIndicator = styled.circle`
  transform-origin: center;
`;

export default ProgressBar;
