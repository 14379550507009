import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import {
  Icon,
  Popup,
  Modal,
  Dropdown,
  Button as ModalButton,
} from "semantic-ui-react";
import { SG_ADD_USER_TASK, SG_DELETE_USER_TASK } from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import Garbage from "assets/images/UI/garbage.svg";

const getEmployeeName = (employeeId, employees) => {
  const employee = employees?.find((emp) => emp.id === employeeId);
  return {
    firstName: employee?.first_name,
    lastName: employee?.last_name,
    email: employee?.email,
  };
};

const userMap = (usersList, employeeList, managers) => {
  const list = usersList?.map((user) => {
    const employeeObj = employeeList?.employees?.find(
      (emp) => emp.id === user?.employee_id
    );
    return {
      key: user?.employee_id,
      value: user?.employee_id,
      text: `${employeeObj?.first_name} ${employeeObj?.last_name}`,
      id: user?.id,
    };
  });

  const empList = employeeList?.employees;
  const inactiveList = empList?.filter(
    (emp) => emp.status === 99 || emp.status === 98 || emp.status === 108
  );

  const enabledLogins = empList?.filter((emp) => emp.enable_login === true);

  const filteredList = list.filter(
    (emp) => !inactiveList.find((inactive) => inactive.id === emp.key)
  );

  return filteredList;
};

const AssignUserModal = ({
  onClose,
  modalOpen,
  setModalOpen,
  assign,
  template,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [assignedUsersList, setAssignedUsersList] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const { get_employee_org_category, get_employees, user_manager } =
    useSelector(
      (state) => ({
        get_employees: state.employees,
        get_employee_org_category:
          state.employee_category?.employee_org_category,
        user_manager: state.user_manager,
      }),
      shallowEqual
    );

  const handleUserAdd = (value) => {
    if (!assignedUsersList.includes(Number(value))) {
      setAssignedUsersList([...assignedUsersList, Number(value)]);
      const user = get_employee_org_category.find(
        (u) => u.employee_id === value
      );
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  return (
    <Modal open={modalOpen} onClose={onClose} size="small">
      <Modal.Header>Assign additional users to this task</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalTitle>
            Select members to add to this task's assignee list
          </ModalTitle>
          <ModalDescription>
            When you select a member, they will receive an email notifying them
            that they have a task available.
          </ModalDescription>
          <ModalRow>
            <Dropdown
              placeholder="Select a user"
              fluid
              search
              value={selectedUsers}
              selection
              onChange={(e, { value }) => handleUserAdd(value)}
              options={userMap(
                get_employee_org_category,
                get_employees,
                user_manager?.managerUsers?.results
              )}
            />
          </ModalRow>
          <AssigneeTable onMouseOut={() => setHoveredRow(null)}>
            <AssigneeHeader>
              <AssigneeHeaderCell width={50}>Name</AssigneeHeaderCell>
              <AssigneeHeaderCell width={50}>Actions</AssigneeHeaderCell>
            </AssigneeHeader>
            {selectedUsers?.map((user, i) => {
              return (
                <AssigneeRow
                  key={user.employee_id}
                  onMouseOver={() => setHoveredRow(i)}
                  style={{
                    backgroundColor:
                      hoveredRow === i ? "#f0f0f0" : "transparent",
                  }}
                >
                  <AssigneeCell width={50}>
                    {user.employee_full_name}
                  </AssigneeCell>
                  <AssigneeCell
                    width={50}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <DeleteButton
                      onClick={() => {
                        setSelectedUsers(
                          selectedUsers.filter(
                            (u) => u.employee_id !== user.employee_id
                          )
                        );
                        setAssignedUsersList(
                          assignedUsersList.filter(
                            (id) => id !== user.employee_id
                          )
                        );
                      }}
                    >
                      <Icon name="trash" />
                    </DeleteButton>
                  </AssigneeCell>
                </AssigneeRow>
              );
            })}
          </AssigneeTable>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <ModalButton onClick={() => setModalOpen(false)}>Cancel</ModalButton>
        <ModalButton
          content="Save"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            assign(assignedUsersList);
            setModalOpen(false);
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const UnassignUserModal = ({ unassign, onClose, modalOpen, setModalOpen }) => {
  const [text, setText] = useState("");

  return (
    <Modal open={modalOpen} onClose={onClose} size="small">
      <Modal.Header>Unassign user from this task</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <ModalTitle>
            Are you sure you want to unassign this user from the task?
          </ModalTitle>
          <ModalDescription>
            When you unassign a user, they will no longer have access to this
            task.
          </ModalDescription>
          <ModalDescription>
            Please type the word <strong>DELETE</strong> in the box below to
            confirm the unassignment of this user.
          </ModalDescription>
        </Modal.Description>
        <Input value={text} onChange={(e) => setText(e.target.value)} />
      </Modal.Content>
      <Modal.Actions>
        <ModalButton onClick={() => setModalOpen(false)}>Cancel</ModalButton>
        <ModalButton
          content="Unassign"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            unassign();
            setModalOpen(false);
          }}
          negative
          disabled={text.toLocaleLowerCase() != "delete"}
        />
      </Modal.Actions>
    </Modal>
  );
};

const Summary = ({ assignees, responses, employees, id }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  // create a new array from assignees, filter out any assignees that are alreaedy in the responses
  const filteredAssignees = assignees?.filter((assignee) => {
    return !responses.some((response) => response.employee === assignee);
  });

  const assignTask = (template, employees) => {
    const structuredPayload = {
      task_id: template,
      employee_id_list: employees,
    };

    dispatch({
      type: SG_ADD_USER_TASK,
      payload: structuredPayload,
    });

    // TODO: Make this smarter.
    addToast("Task assigned successfully", { appearance: "success" });
  };

  const unassignTask = (template, employee) => {
    const structuredPayload = {
      task_id: Number(template),
      employee_id_list: [employee],
    };

    dispatch({ type: SG_DELETE_USER_TASK, payload: structuredPayload });
    addToast("Assignees removed successfully", {
      appearance: "success",
    });
  };


  return (
    <Container>
      {showAssignModal && (
        <AssignUserModal
          modalOpen={showAssignModal}
          onClose={() => setShowAssignModal(false)}
          setModalOpen={setShowAssignModal}
          template={id}
          assign={(users) => assignTask(id, users)}
        />
      )}
      {showUnassignModal && (
        <UnassignUserModal
          modalOpen={showUnassignModal}
          onClose={() => setShowUnassignModal(false)}
          setModalOpen={setShowUnassignModal}
          unassign={() => unassignTask(id, selectedEmployee)}
        />
      )}

      <AddAssignee onClick={() => setShowAssignModal(true)}>
        + Add survey participant
      </AddAssignee>

      <Table>
        <Headers>
          <TH width={40}>Member</TH>
          <TH width={40}>Status</TH>
          {/* <TH>Sentiment</TH>
          <TH>Action Orientation</TH> */}
          <TH width={20}>Actions</TH>
        </Headers>

        {employees?.employees?.length > 0 &&
          responses?.map((response) => {
            const { firstName, lastName } = getEmployeeName(
              response.employee,
              employees?.employees
            );

            if (!firstName && !lastName) {
              return null;
            }

            return (
              <Row>
                <TD width={40}>
                  <InitialsCircle firstName={firstName} lastName={lastName} />
                  <Name>
                    {firstName} {lastName}
                  </Name>
                </TD>
                <TD width={40}>
                  <Status status={"Completed"} />
                </TD>
                {/* <TD>
                  <Sentiment sentiment={"Positive"} />
                </TD>
                <TD>
                  <ActionOrientation action={"50% Actionable"} />
                </TD> */}
                <TD width={20}>
                  {/* Add Unassign button */}
                  <Popup
                    trigger={
                      <IconImg
                        onClick={() => {
                          setSelectedEmployee(response.employee);
                          setShowUnassignModal(true);
                        }}
                        src={Garbage}
                      />
                    }
                    content="Remove user"
                    position="top center"
                    size="mini"
                    inverted
                  />
                </TD>
              </Row>
            );
          })}
        {employees?.employees?.length > 0 &&
          filteredAssignees?.map((assignee) => {
            const { firstName, lastName, email } = getEmployeeName(
              assignee,
              employees?.employees
            );

            if (!firstName && !lastName) {
              return null;
            }
            return (
              <Row>
                <TD width={40}>
                  <InitialsCircle firstName={firstName} lastName={lastName} />
                  <Name>{email}</Name>
                </TD>
                <TD width={40}>
                  <Status status={"Pending"} />
                </TD>
                {/* <TD>
                  <Sentiment sentiment={"Neutral"} />
                </TD>
                <TD>
                  <ActionOrientation action={"0% Actionable"} />
                </TD> */}
                <TD width={20}>
                  {/* Add Unassign button */}
                  <Popup
                    trigger={
                      <IconImg
                        onClick={() => {
                          setSelectedEmployee(assignee);
                          setShowUnassignModal(true);
                        }}
                        src={Garbage}
                      />
                    }
                    content="Remove user"
                    position="top center"
                    size="mini"
                    inverted
                  />
                </TD>
              </Row>
            );
          })}
      </Table>
    </Container>
  );
};

export default Summary;

// Function to get initials from the name
const getInitials = (firstName, lastName) => {
  if(firstName && lastName) {
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }
  return ""
};
const colors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#e74c3c",
  "#e67e22",
  "#f1c40f",
  "#e84393",
  "#00cec9",
  "#0984e3",
  "#6c5ce7",
];

// Hash function to consistently map initials to a color
const hashStringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return colors[hash % colors.length];
};

const InitialsCircle = ({ firstName, lastName }) => {
  const initials = getInitials(firstName, lastName);
  const color = hashStringToColor(initials);

  return <Circle bgColor={color}>{initials}</Circle>;
};

const Input = styled.input`
  margin-top: 20px;
  height: 40px;
  padding: 10px;
  border: 3px solid #2d70e2;
  color: #666d79;
  font-family: "Raleway";
  border-radius: 5px;
`;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const Table = styled.div`
  width:100%;

`;

const Headers = styled.div`
  display: flex;
  width:100%;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom:10px;
  margin-bottom:10px;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  width:100%;
`;

const TD = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.width}%;
`;

const Name = styled.div`
  font-size: 12px;
  font-weight: 600;
  color:#2A3039;
`

const TH = styled.div`
  width: ${(props) => props.width}%;
  font-weight: bold;
  font-size: 14px;
  color:#666D79;
`;
const Description = styled.div`
  margin-bottom: 30px;
`;

// Styled component for the circle
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-right: 10px;
`;

const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway", sans-serif;
`;

const ModalDescription = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
`;

const ModalRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 30px;
`;

const DeleteButton = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const AssigneeTable = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  font-family: "Raleway", sans-serif;
  margin-top: 40px;
`;

const AssigneeHeader = styled.div`
  display: flex;
  width: 100%;
`;

const AssigneeHeaderCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  font-weight: bold;
  border-right: 1px solid #e0e0e0;
`;

const AssigneeRow = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
`;

const AssigneeCell = styled.div`
  width: ${(props) => props.width}%;
  padding: 10px;
  border-right: 1px solid #e0e0e0;
`;

const AddAssignee = styled.div`
  color: #2d70e2;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
  font-family: "Raleway";
  margin-bottom: 10px;
  display: inline-block;
`;

const IconImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Status = ({ status }) => {
  const statusMap = {
    Completed: ["#1D9A7D","#EAFBF7"],
    Pending: ["#FEA711","#FFFAF1"],
  };

  return <Text color1={statusMap[status][0]} color2={statusMap[status][1]}>{status}</Text>;
};

const Sentiment = ({ sentiment }) => {
  return <Text>{sentiment}</Text>;
};

const ActionOrientation = ({ action }) => {
  return <Text>{action}</Text>;
};

const Text = styled.div`
  font-size:10px;
  background-color: ${(props) => props.color2 || "#fff"};
  color: ${(props) => props.color1 || "#000"};
  padding: 2px 4px;
  font-weight: 600;
  border-radius: 8px;
`;
