import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import {Icon} from 'semantic-ui-react'
import Pic from 'assets/images/UI/blue_doc.svg'
import { Link, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";


const Options = () =>{

    const {
        get_employee_permission,
        WhiteLabel,
        get_employees
      } = useSelector(
        (state) => ({
          get_employee_permission: state.employee_permission,
          WhiteLabel:state.white_label?.white_label,
          get_employees: state.employees
        }),
        shallowEqual
      );

      const check_employee_permissions = (permissions) => {
        if(!permissions?.employee_permission?.length > 0){
          return false
        }
        if(!permissions?.employee_permission[0]?.details?.length>0){
          return false
        }
        return true
      }

      const check_employee_feedback = () =>{
        if(get_employees?.userEmp?.read_feedback){
            return true
          }

          return false

      }

    return (
           <Container>
            <Title>
                Your culture toolkit
            </Title>
            <Description>
                Here are your culture tools. You can use these to help you understand your culture and how it is impacting your organization.
            </Description>

            <SubTitle>Analysis</SubTitle>
            <SelectionContainer>
                
                    <Option>
                    <Link to={'/app/audit/report'} style={{display:'flex'}}>
                        <Circle><IMG src={Pic}/></Circle>
                        <div>
                            <OptionTitle>Culture Analytics</OptionTitle>
                            <OptionDescription>Detailed charts and visuals to help you understand the cultures that exist within your organization.</OptionDescription>

                        </div>
                        <div style={{height:'100%',display:'flex',alignItems:'center',marginLeft:20}}>
                            <Icon name="chevron right"/>
                        </div>
                        
                    </Link>
                    </Option>
               

                <Option>
                <Link to={'/app/audit/outcomes'} style={{display:'flex'}}>
                    <Circle><IMG src={Pic}/></Circle>
                    <div>
                        <OptionTitle>Outcome Analytics</OptionTitle>
                        <OptionDescription>How is culture influencing your top KPI's? Outcome analytics helps to add context and urgency to your culture data.</OptionDescription>

                    </div>
                    <div style={{height:'100%',display:'flex',alignItems:'center',marginLeft:20}}>
                        <Icon name="chevron right"/>
                    </div>
                    
                </Link>
                </Option>

                {check_employee_feedback() && (<Option>
                    <Link to={'/app/audit/AI'} style={{display:'flex'}}>
                        <Circle><IMG src={Pic}/></Circle>
                        <div>
                            <OptionTitle>Culture Copilot</OptionTitle>
                            <OptionDescription>Use the copilot to understand your data at a deeper level. With the help of AI, you can navigate your culture with greater ease.</OptionDescription>

                        </div>
                        <div style={{height:'100%',display:'flex',alignItems:'center',marginLeft:20}}>
                            <Icon name="chevron right"/>
                        </div>
                    </Link>
                    
                   
                </Option>)}

                {!check_employee_permissions(get_employee_permission) && (<Option>
                 <Link to={'/app/audit/engagement'} style={{display:'flex'}}>
                    <Circle><IMG src={Pic}/></Circle>
                    <div>
                        <OptionTitle>Engagement Analysis</OptionTitle>
                        <OptionDescription>Understand areas of highest engagement within your organization.</OptionDescription>

                    </div>
                    <div style={{height:'100%',display:'flex',alignItems:'center',marginLeft:20}}>
                        <Icon name="chevron right"/>
                    </div>
                    
                   </Link>
                </Option>)}

                {!check_employee_permissions(get_employee_permission) && check_employee_feedback() && (<Option>
                <Link to={'/app/audit/rec'} style={{display:'flex'}}>
                    <Circle><IMG src={Pic}/></Circle>
                    <div>
                        <OptionTitle>Recommendation Reports</OptionTitle>
                        <OptionDescription>Generate a report that contains AI generated recommendations. Share these reports to improve the impact of your culture change efforts.</OptionDescription>

                    </div>
                    <div style={{height:'100%',display:'flex',alignItems:'center',marginLeft:20}}>
                        <Icon name="chevron right"/>
                    </div>
                    
                   </Link>
                </Option>)}
            </SelectionContainer>
        </Container>
    )
}

export default Options

const Container = styled.div`
    padding:20px;
    max-width:1200px;
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
color: #2A3039;
margin-bottom:10px;
`

const Description = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: normal;
font-size: 14px;
color: #2A3039;
line-height:1.6;
margin-bottom:30px;
max-width:800px;
`

const SubTitle = styled.div`
font-family: 'Raleway';
font-size:16px;
font-weight:600;
margin-bottom:10px;
color: #2A3039;
`

const SelectionContainer = styled.div`
    display:flex;
    flex-wrap:wrap;
    justfiy-content:space-between;
    width:100%;
`

const Option = styled.div`
    border:1px solid #E5E5E5;
    padding:10px;
    display:flex;
    width:calc(50% - 20px);
    margin-right:10px;
    margin-bottom:10px;
    cursor:pointer;
`

const Circle = styled.div`
    width:40px;
    height:40px;
    border-radius: 8px;
    background: var(--primary-50, #EBF1FD);
    margin-right:10px;
    min-width:40px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const OptionTitle = styled.div`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2A3039;
    margin-bottom:5px;
`

const OptionDescription = styled.div`
    font-family: 'Raleway';

    font-size: 12px;
    color: #2A3039;
    margin-bottom:5px;
    line-height:1.4;
`

const IMG = styled.img`
    width:20px;

`