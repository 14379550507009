import React,{useState} from 'react';
import styled from 'styled-components';
import {Modal,Dropdown} from 'semantic-ui-react'



const AddComponent = () =>{
    const [open,setOpen] = useState(false)

    return (
        <Modal
        open={open}
        onClose={()=>setOpen(false)}
        trigger={<Button onClick={()=>setOpen(true)}>Create a new label</Button>}
        size='small'
        >
            <Modal.Content>
                <Title>
                    Add a new column label
                </Title>

                <T2>Label name</T2>
                <Description>Enter a unique label name with no spaces</Description>
                <LabelInput placeholder='Enter a label name'/>

                <T2>Label description</T2>
                <Description>Enter a unique label name with no spaces</Description>
                <TextArea placeholder='Enter a label name'/>


                <T2>Label type</T2>
                <Description>Please select a label type</Description>
                <Dropdown 
                     placeholder='Select a label type'
                    fluid
                    selection
                    options={friendOptions}/>


                <ButtonContainer>
                    <Button>Cancel</Button>
                    <Button primary>Create</Button>
                </ButtonContainer>
            </Modal.Content>
        </Modal>
    )
}

export default AddComponent

const LabelInput = styled.input`
    width:100%;
    height:40px;
    border-radius:5px;
    border:1px solid #ccc;
    padding:10px;
    margin-bottom:20px;
    font-family:'Raleway';
`
const ButtonContainer = styled.div`
    width:100%;
    margin-top:30px;
    display:flex;
    justify-content:flex-end;
`
const Button = styled.button`
font-family:'Raleway';
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:20px;
    font-family:'Raleway';

`

const T2 = styled.div`
    font-size:14px;
    font-weight:bold;
    font-family:'Raleway';
    margin-bottom:5px;
`

const Description = styled.div`
font-family:'Raleway';
margin-bottom:5px;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #ccc;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    margin-bottom:20px;
`

const friendOptions = [
    {
      key: 'Categorical',
      text: 'Categorical',
      value: 'Categorical',
    },
    {
      key: 'Numerical',
      text: 'Numerical',
      value: 'Numerical',
    },
    {
      key: 'Date',
      text: 'Date',
      value: 'Date',
    }
  ]