import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from 'semantic-ui-react'


const Help = () =>{
	const [selected,setSelected] = useState(0)

	return (
		<Container>
			<Top>
			Video Tutorials
			<Icon name={"close"} />
			</Top>
			<Content>
				<iframe width="100%" height="200" src="https://www.youtube.com/embed/qP1ZskD2JEM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				<VideoItem title={"Culture analytics overview"} selected={selected==0}/>
				<VideoItem title={"Interpreting your culture data"}/>
				<VideoItem title={"Using the heatmap feature"}/>
				<VideoItem title={"Exploring culture anchors"}/>
				<VideoItem title={"Long term culture metrics"}/>
				<VideoItem title={"Understanding the insights tab"}/>
				<VideoItem title={"Utilizing the tables tab"}/>
				<VideoItem title={"Next steps after digesting culture data"}/>
				<VideoItem title={"Getting the most out of your results"}/>
			</Content>
		</Container>
		)
}


export default Help


const VideoItem = ({title,selected}) =>{

	return (
		<Item selected={selected}>
			<Circle selected={selected}>
				<Icon name="play" style={{margin:0,marginTop:-14,marginLeft:1}}/>
			</Circle>
			<Text selected={selected}>{title}</Text>
		</Item>
		)
}

const Container = styled.div`
	position:absolute;
	top:100px;
	left:100px;
	width:350px;
	max-height:500px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
`

const Top = styled.div`
	padding:20px;
	font-weight:500;
	font-size:18px;
	color:white;
	background-color:#476DFA;	
	display:flex;
	justify-content:space-between;
`

const Content = styled.div`
	padding:20px;
	background-color:white;
`

const Item = styled.div`
	padding:5px 10px;
	display:flex;
	margin-bottom:7px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	cursor:pointer;
	background-color:${props=>props.selected?'#476DFA':'white'};
	border-radius:5px;
`

const Circle = styled.div`
	height:20px;
	width:20px;
	border-radius:10px;
	background-color:${props=>props.selected?'white':'#476DFA'};
	color:${props=>props.selected?'#476DFA':'white'};;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:6px;
`

const Text = styled.div`
	margin-left:5px;
	font-size:10px;
	font-family: 'Poppins';
	font-weight:bold;
	color:${props=>props.selected?'white':'black'};
`