import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Icon, Confirm } from 'semantic-ui-react'

import { Button, Pagination, Dimmer, Image, Loader, Segment } from 'semantic-ui-react'

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_DEBRIEF_SCHEDULES,
  ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  SG_DELETE_DEBRIEF_SCHEDULE,
} from "constants/actions";

import format from "date-fns/format";

import Pic1 from 'assets/images/performance.png'
import Pic2 from 'assets/images/google-docs.png'

import AddNew from "debriefs/AddNew"

const DebriefScheduleList = ({
  organizationId, setShowReportId,
  fetchAgain, setFetchAgain,
  selectedTeam,
  showErrors,
  deletedRecord,
  setDeletedRecord,
  createEdit
}) => {
  const dispatch = useDispatch();
  // const [organizations, setOrganizations] = useState([]);

  const [totalPages, setTotalPages] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [debriefSchedules, setDebriefSchedules] = useState([]);

  const [deleteId, setDeleteId] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false);

  // this has been set in django if you use non basic pagination
  // this page using basic pagination 
  const defaultPageSize = 12

  const { get_debrief_schedules } = useSelector(
    (state) => ({
      get_debrief_schedules: state.debrief_schedule
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log("updated data", get_debrief_schedules.results)
    setDebriefSchedules(get_debrief_schedules.debrief_schedule.results)
    setTotalPages(
      Math.ceil(get_debrief_schedules.debrief_schedule.count / defaultPageSize))
    if (get_debrief_schedules.activePage > 0) {
      if (get_debrief_schedules.activePage !== activePage) {
        // console.log(activePage)
        // console.log(get_debrief_schedules.activePage)
        setActivePage(get_debrief_schedules.activePage)
      }
    }
    // 
  }, [get_debrief_schedules, activePage]);

  // console.log(get_debrief_schedules.activePage)
  // console.log(activePage)

  useEffect(() => {
    let teamquery = ''
    if (selectedTeam?.id) {
      teamquery = `&team=${selectedTeam.id}`
    }
    if (organizationId && activePage) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${activePage}&type_list=1&type_list=2&organization=${organizationId}${teamquery}`
      });
    }
  }, [dispatch, organizationId, activePage, selectedTeam,
    fetchAgain, setFetchAgain, deletedRecord,
    setDeletedRecord,]);

  // console.log(organizationId, fetchAgain)

  function ChangedPage(e, { activePage }) {
    // console.log(activePage)
    setActivePage(activePage)
    dispatch({
      type: ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
      payload: activePage
    })
  }

  useEffect(() => {
    setPageLoading(true)
    const timer1 = setTimeout(() => {
      setPageLoading(false)
    }, 2000)
    return () => clearTimeout(timer1)
  }, [activePage]);

  useEffect(() => {
    if (fetchAgain || deletedRecord) {
      setPageLoading(true)
    }
    else {
      const timer1 = setTimeout(() => {
        setPageLoading(false)
      }, 2000)
      // const timer2 = setTimeout(() => {
      //     setSecondOpen(true) 
      // }, 20);
      return () => clearTimeout(timer1)
      // clearTimeout(timer2)
    }

  }, [fetchAgain, deletedRecord]);

  // console.log(selectedTeam)
  useEffect(() => {
    setPageLoading(true)
    const timer1 = setTimeout(() => {
      if (fetchAgain) {
        setFetchAgain(false)
      }
      if (deletedRecord) {
        setDeletedRecord(false)
      }
      setPageLoading(false)
    }, 2000)
    return () => clearTimeout(timer1)
  }, [dispatch, fetchAgain, setFetchAgain, deletedRecord,
    setDeletedRecord, organizationId]);

  function Paginate() {
    return <Pagination
      activePage={activePage}
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      prevItem={debriefSchedules?.links?.previous ? true : null}
      nextItem={debriefSchedules?.links?.next ? true : null}
      totalPages={totalPages}
      onPageChange={ChangedPage}
    />
  }

  function clearErrors() {
    if (organizationId && activePage) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${activePage}&organization=${organizationId}`
      });
    }
    setFetchAgain(false)
    setDeletedRecord(false)

  }

  function DeleteDebriefSchedule() {
    if (deleteId) {
      dispatch({
        type: SG_DELETE_DEBRIEF_SCHEDULE,
        payload: { id: deleteId }
      })
    }
    setDeletedRecord(deleteId)
    setConfirmDelete(false)
    setShowReportId(false)
    setDeleteId(false)
  }

  function SelectDeleteRow(id) {
    setDeleteId(id)
    setConfirmDelete(true)
  }

  // console.log(deletedRecord)

  return (
    <>

      {!showErrors && fetchAgain > 0 &&
        <Message
          positive
          onDismiss={clearErrors}
          header='New Schedule Added!'
          content='You have successfully added a new schedule!'
        />
      }

      {!showErrors && deletedRecord > 0 &&
        <Message
          positive
          onDismiss={clearErrors}
          header='New Schedule Removed!'
          content='You have successfully removed a schedule!'
        />
      }
      {/* <Dimmer.Dimmable as={Segment} dimmed={pageLoading}> */}
      <Dimmer.Dimmable dimmed={pageLoading}>

        <Dimmer active={pageLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>


        <Table>
          <TR>
            <TH width={20}>Type</TH>
            <TH width={25}>Date &amp; Time(Local)</TH>
            <TH width={18}>Closing Time</TH>
            <TH width={15}>Status</TH>
            <TH width={15}>Completion</TH>
            <TH width={20}></TH>

          </TR>

          {debriefSchedules &&
            debriefSchedules.map((brief, k) => {
              return (
                <TR key={k}>
                  <TD width={10}>
                    <SmallImg src={brief?.type_of === 1 ? Pic1 : Pic2} />
                    {/*<T1>{brief?.type_of_text}
                      {brief?.id}
                    </T1>*/}
                  </TD>
                  <TD width={10}>
                    {brief?.team_name}
                  </TD>
                  <TD width={30}>
                    {format(
                      new Date(brief?.scheduled_for?.toLocaleString()),
                      "EEEE, MMM do h:mm a"
                    )}
                    <br />
                    {brief?.time_zone}
                  </TD>
                  <TD width={18}>

                    {brief?.close_at &&
                      format(
                        new Date(brief?.close_at?.toLocaleString()),
                        "h:mm a"
                      )}
                  </TD>
                  <TD width={15}>
                    {brief?.status_text}
                  </TD>
                  <TD width={15}>
                    {brief?.total_responses} / {brief?.total_requests}
                  </TD>
                  <TD width={20}>
                    {brief?.status === 1 ?
                      <>
                        {createEdit &&
                          <>
                            <AddNew showReportId={brief.id}
                              debriefSchedule={brief}
                              setFetchAgain={setFetchAgain}
                              onClick={() => setShowReportId(brief?.id)}
                            />
                            <Button icon
                              onClick={() => SelectDeleteRow(brief?.id)}>
                              <Icon name='trash alternate' />
                            </Button>
                            <Confirm
                              open={confirmDelete}
                              onCancel={() => setConfirmDelete(false)}
                              onConfirm={() => DeleteDebriefSchedule(brief?.id)}
                            />
                          </>
                        }

                      </>
                      :
                      <ViewButton onClick={() => setShowReportId(brief?.id)}>
                        View
                      </ViewButton>
                    }
                  </TD>

                </TR>
              )
            })}

        </Table>
        <Pages>
          <Paginate />
        </Pages>
      </Dimmer.Dimmable>
    </>
  );
};

export default DebriefScheduleList;

const Headers = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
  font-size:28px;
  margin-top:40px;
  margin-bottom: 20px;
`;

const Table = styled.div`
	margin-top:30px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
`

const TR = styled.div`
	display:flex;
	padding:15px 10px;
	width:100%;
`

const TH = styled.div`
	width:${props => props.width + '%'};
	font-weight:bold;
	border-bottom:1px solid #9b9b9b;
	padding-bottom:5px;
`

const TD = styled.div`
	width:${props => props.width + '%'};
	display:flex;
	align-items:center;
	position:relative;
`

const T1 = styled.div`
	font-weight:500;
	font-size:18px;
`

const SmallImg = styled.img`
	width:30px;
	margin-right:10px;
`



const View = styled.div`
	padding:5px 35px;
	// background-color:#6a00ff;
	// color:white;
	// border-radius:20px;
	font-weight:bold;
	position:absolute;
	right:0;
	
`

const ViewButton = styled.button`
	padding:5px 35px;
	background-color:#6a00ff;
	color:white;
	border-radius:5px;
	font-weight:bold;
	position:absolute;
	// right:0;
  cursor:pointer;
  border: 0;
  box-shadow: none;
	
`

const Card = styled.div`
  width:600px;
  max-width:100%;
  padding:30px;
  margin-bottom:10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  position:relative;
  display:flex;
  justify-content:space-between;
  align-items:center;

`

const Org = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-size:22px;
  font-weight:bold;
`

const Pages = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  margin-top:5px;
`