import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Box from './Box'
import { useNavigate } from 'react-router-dom';

const Rules = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Center>
                <T1>Data Access Rules</T1>
                <Description>
                    Your organizations data access rules determine which data stories get automatically generated. And which culture plan gets delivered to each user.
                </Description>
                <Title>Add new rule</Title>
                <Box
                     title={'Add a new data story or custom plan'} 
                     icon={'plus'}
                     color={"#B3B3B3"}
                     onClick={()=>navigate('/simple/prototype/create')}
                     description={'Explore your culture data and get insights into the most critical areas of development'}/>

                <div style={{marginBottom:30}}/>

                <Title>Active Data Stories</Title>
                <Box
                     title={'Department Factor Explanation'} 
                     icon={'file outline'}
                     color={"#2D70E2"}
                     onClick={()=>navigate('/simple/prototype/review')}
                     description={'Explore your culture data and get insights into the most critical areas of development'}/>

                <div style={{marginBottom:30}}/>
                <Title>Active Action Plans</Title>
                <Box
                     title={'Culture Factor Plan'} 
                     icon={'file alternate outline'}
                     color={"#27CDA7"}
                     onClick={()=>navigate('/simple/prototype/review')}
                     description={'Explore your culture data and get insights into the most critical areas of development'}/>
           
          
           
            </Center>
        </Container>
    )
}

export default Rules

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    min-height:100vh;

`


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Center = styled.div`
    width:800px;
    margin-top:40px;
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Title = styled.div`
    font-size:18px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`

const T1 = styled.div`
    font-size:28px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`

const Description = styled.div`
    font-size:14px;
    font-weight:400;
    margin-bottom:20px;
`
