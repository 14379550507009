import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    GET_PROD_ORG_CONTRACT_PENDING,
    GET_PROD_ORG_CONTRACT,
    SG_GET_PROD_ORG_CONTRACT,
    ALL_ERRORS,
} from "constants/actions";

function* loadProcess(action) {
    yield put({ type: GET_PROD_ORG_CONTRACT_PENDING });
  try {
    const json = yield call(api.GET_PROD_ORG_CONTRACT, action.payload);
    yield put({ type: GET_PROD_ORG_CONTRACT, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* processLoad() {
  yield takeLatest(SG_GET_PROD_ORG_CONTRACT, loadProcess);
}


export default function* index() {
  yield all([
    processLoad(),
  ]);
}
