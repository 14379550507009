import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_ADD_DATA_STORY_LOG } from "constants/actions";
import { useParams } from "react-router";

const NextSteps = ({ next, tracked }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (tracked) {
      dispatch({
        type: SG_ADD_DATA_STORY_LOG,
        payload: {
          data_story_id: id,
          user_action: `Completed`,
        },
      });
    }
  }, []);

  return (
    <Container>
      <T2 delay={0.3}>What are my next steps?</T2>
      <TopTitle delay={0.7}>Have more questions about your results?</TopTitle>
      <TopDescription delay={0.7}>
        Check out the culture copilot. This ai tool is connected to your data,
        and able to give you additional insights into what is going on in your
        team.
      </TopDescription>
      <TopButton delay={0.7} onClick={() => navigate("/simple/audit/ai")}>
        Go to Culture Copilot
      </TopButton>
      <BottomTitle delay={1.1}>Build an action plan</BottomTitle>
      <BottomDescription delay={1.1}>
        Use our action plan builder to create a plan specifically for you based
        on your data. The ai powered builder will guide you through each step of
        the process.
      </BottomDescription>
      <BottomButton
        delay={1.1}
        onClick={() => navigate("/simple/audit/draft/drafts")}
      >
        Build an action plan
      </BottomButton>
    </Container>
  );
};

export default NextSteps;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TopTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.4;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const BottomTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.4;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const T2 = styled.div`
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 1.4;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const TopDescription = styled.div`
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-out forwards;
  opacity: 0;
  animation-delay: ${({ delay }) => delay}s;
`;

const BottomDescription = styled.div`
  margin-bottom: 20px;
  animation: ${fadeIn} 1s ease-out forwards;
  opacity: 0;
  animation-delay: ${({ delay }) => delay}s;
`;

const TopButton = styled.button`
  background-color: #ebf1fd;
  color: #2d70e2;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  width: auto;
  margin-bottom: 30px;
  animation: ${fadeIn} 1s ease-out forwards;
  opacity: 0;
  animation-delay: ${({ delay }) => delay}s;
`;

const BottomButton = styled.button`
  background-color: #ebf1fd;
  color: #2d70e2;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  width: auto;
  margin-bottom: 30px;
  animation: ${fadeIn} 1s ease-out forwards;
  opacity: 0;
  animation-delay: ${({ delay }) => delay}s;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
