
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { DomainContext } from 'layouts/LayoutContext';

import styled from "styled-components";

import {
  SG_CHANGE_PASSWORD,
  CLEAR_PASSWORD_DATA,
} from "constants/actions";

import Input from "./InputLogin";

// import Background from "assets/images/login.jpg";

import UserErrorMessages from "./UserErrorMessages";

import { Message } from "semantic-ui-react";

const ChangePassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const Domain = useContext(DomainContext);


  const [password0, setPassword0] = useState('')
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [errorResp, setErrorResp] = useState(false)
  const [msgResp, setMsgResp] = useState(false)
  const [dataResp, setDataResp] = useState(false)

  const { get_auth, get_error } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_error: state.errors,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (!get_auth?.token) {
      return navigate("/account/login");
    }
  }, [get_auth, navigate]);

  
  useEffect(() => {
    setMsgResp(false)
    setErrorResp(get_auth?.changed_password?.error)
    if (get_auth.changed_password?.message?.length > 0) {
      setMsgResp(get_auth.changed_password?.message)
    }
    if (get_auth.changed_password?.data?.length > 0) {
      setDataResp(get_auth.changed_password?.data)
    }


  }, [get_auth]);

  useEffect(() => {
    if (dataResp === "reset_credentials") {
      const timer1 = setTimeout(() => {
        setDataResp(false);
        dispatch({
          type: CLEAR_PASSWORD_DATA,
        });
      }, 5000);
      return () => clearTimeout(timer1);
    }
  }, [dataResp, dispatch]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0
    if (password0?.length === 0) {
      ErrorList["password0"] = {
        error: true,
        message: "Please enter existing password"
      };
      totalErrors += 1
    }

    if (password1?.length === 0) {
      ErrorList["password1"] = {
        error: true,
        message: "Please enter a password"
      };
      totalErrors += 1
    }

    if (password1 !== password2) {
      ErrorList["password2"] = {
        error: true,
        message: "Passwords dont match"
      };
      totalErrors += 1
    }

    if (password2?.length === 0) {
      ErrorList["password2"] = {
        error: true,
        message: "Please enter password again"
      };
      totalErrors += 1
    }

    ErrorList['errors'] = totalErrors

    setFormErrors(ErrorList);

  }, [password0,
    password1, password2, pristine
  ]);



  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }
    // dispatch({
    //   type: CLEAR_ERRORS,
    // });

    dispatch({
      type: SG_CHANGE_PASSWORD,
      payload: {
        new_password: password1,
        old_password: password0,
      },
    });
    setPristine(true);
  };


  return (
    <Container>
      <Box>

        <Banner>
        <IMG src={Domain.image} />
        </Banner>
        <Title>Password Change</Title>
        <Description>
          Please enter a new password for your account
        </Description>
        {get_error?.errors?.data?.status && (
          <Label basic color="red" pointing="below">
            {get_error?.errors?.statusText}
          </Label>
        )}

        {formErrors.token?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.token?.message}
          />
        )}

        {msgResp && (
          <Message
            error={errorResp}
            // header={props.message}
            content={msgResp}
          />
        )
        }

        {/* registerError */}
        <UserErrorMessages />

        <Input placeholder="Old Password"
          name="old_password"
          type="password"
          id="password0"
          value={password0}
          onChange={setPassword0}
        />
        {formErrors.password0?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.password0?.message}
          />
        )}

        <Input placeholder="Password"
          name="new_password"
          type="password"
          id="password1"
          value={password1}
          onChange={setPassword1}
        />
        {formErrors.password1?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.password1?.message}
          />
        )}
        <Input placeholder="Same Password Again"
          name="password2"
          type="password"
          id="password2"
          value={password2}
          onChange={setPassword2}
        />
        {formErrors.password2?.error && !pristine && (
          <Message
            error
            // header={props.message}
            content={formErrors.password2?.message}
          />
        )}


        <Button onClick={onSubmit} id="change">
          Change Password
        </Button>


      </Box>

    </Container >
  );
};

export default ChangePassword;

const Title = styled.span`
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
  font-family: "Barlow", sans-serif;
  line-height: 1;
`;

const Description = styled.div`
  font-family: "Barlow", sans-serif;
  margin-bottom: 20px;
`;

const Button = styled.button`
    width: 100%;
    height: 40px;
    min-height: 55px;
    border-radius: 8px;
    background-color: #2d50e2;
    color: white;
    font-size: 18px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    outline: none;
    margin-top: 30px;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0);
      border: 1px solid #2d50e2;
      color: #2d50e2;
      margin-right: 3px;
    }
  `;

const IMG = styled.img`
    width: 160px;
  `;
const Banner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
    margin-bottom: 10px;
    margin-top: 50px;
    width: 100%;
  `;

const P = styled.div`
    font-family: "Red Hat Display", sans-serif;
    max-width: 500px;
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.4;
  `;

const Container = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  `;

const Box = styled.div`
    height: 100%;
    background-color: #ffffff;
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 50%;
  
    @media (max-width: 768px) {
      padding: 0;
      width: 90%;
    }
  `;

const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    padding: 5px;
    font-family: "Barlow", sans-serif;
    justify-content: space-between;
  
    a {
      color: #2d50e2;
      font-weight: bold;
    }
  `;

const Label = styled.div`
    margin-bottom:10px;
    font-weight:bold;
  `


const SideImg = styled.img`
    min-height: 100%;
  `;

const ImgContainer = styled.div`
    width: calc(100% - 500px);
    max-width: 900px;
    overflow: hidden;
  
    @media (max-width: 768px) {
      display: none;
    }
  `;

const FormContainer = styled.div`
    width: 100%;
    max-width: 600px;
    height: 100%;
    padding: 100px 30px 0 30px;
    display: flex;
  
    @media (max-width: 768px) {
      width: 100%;
      padding: 20px 20px 0 20px;
    }
  
    @media (max-height: 1000px) {
      padding-top: 100px;
    }
  `;