import React,{useState} from 'react';
import styled from 'styled-components';
import {Table,Checkbox} from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';

const Review = () =>{
    const navigate = useNavigate();

    return (
        <Container>
            <Title>Please review the following details</Title>

            <T2>The following memebers will receive communication from the change agent:</T2>

            <ul>
                <li>John Smith</li>
                <li>John Smith</li>
                <li>John Smith</li>
                <li>John Smith</li>
                <li>John Smith</li>
            </ul>

            <T2>The change agent will investigate best practices in the following area: <Blue>Customer Retention</Blue></T2>

            <T1>Custom instructions</T1>
            <T2>
                Are there additional instructions you'd like to provide to the change agent?
            </T2>

            <TextArea/>

            <ButtonContainer>
                <Button primary onClick={()=>navigate('/simple/prototype/recon')}>Deploy agent</Button>
            </ButtonContainer>
        </Container>
    )
}

export default Review

const Container = styled.div`
    font-family:'Raleway';
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:20px;
`

const T1 = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
    margin-top:20px;
`

const T2 = styled.div`
    font-size:14px;
`

const Blue = styled.span`
    color:#2A3039;
    font-weight:bold;
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #ccc;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    margin-bottom:20px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`

const Button = styled.div`
    background:blue;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-left:20px;
`
