import React, { useState, useEffect } from 'react'

import Chart from "react-apexcharts";

import ErrorWrapper from "error/component_error"
function Bomb() {
  throw new Error('💥 CABOOM 💥')
}


export default function ChartDonutData(props) {

    const [chartData, setChartData] = useState([1, 4, 2, 2, 2, 2]);
    const [chartOptions, setChartOptions] = useState(
      {
        chart: {
          width: 400,
          type: 'donut'
        },
        labels: ['Enjoyment', 'Motivation', 'Curiosity', 'Stress', 'Discontent', 'Anger'],
        colors: ['rgba(8, 194, 81,0.5)', 'rgba(48, 120, 255,0.5)', 'rgba(213, 0, 178,0.5)', 'rgba(255, 184, 18,0.5)', 'rgba(126, 36, 234,0.5)', 'rgba(255, 45, 85,0.5)'],
    
        fill: {
          opacity: 1,
    
        },
        stroke: {
          width: 0,
          colors: undefined
        },
        yaxis: {
          show: false
        },
        legend: {
          position: 'bottom',
          colors: ['#08c251', '#3078ff', '#ffb812', '#d500b2', '#7e24ea', '#ff2d55'],
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0
            },
            spokes: {
              strokeWidth: 0
            },
          }
        },
         responsive: [
            {
              breakpoint: 768,
              options: {
                chart: {
                  width: '100%'
                }
              }
            }]
      }
    );

    useEffect(() => {
        setChartData(props.score)
        setChartOptions({
          chart: {
            width: 400,
            type: 'donut'
          },
          labels: props.names,
          colors: props.colors,
      
          fill: {
            opacity: 1,
      
          },
          stroke: {
            width: 0,
            colors: undefined
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'bottom',
            colors: ['#08c251', '#3078ff', '#ffb812', '#d500b2', '#7e24ea', '#ff2d55'],
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0
              },
              spokes: {
                strokeWidth: 0
              },
            }
          },
        }

        )
    }, [props]);

    const data = {

        series: [1, 4, 2, 2, 2, 2],
      
        options: {
          chart: {
            width: 400,
            type: 'donut'
          },
          labels: ['Enjoyment', 'Motivation', 'Curiosity', 'Stress', 'Discontent', 'Anger'],
          colors: ['rgba(8, 194, 81,0.5)', 'rgba(48, 120, 255,0.5)', 'rgba(213, 0, 178,0.5)', 'rgba(255, 184, 18,0.5)', 'rgba(126, 36, 234,0.5)', 'rgba(255, 45, 85,0.5)'],
      
          fill: {
            opacity: 1,
      
          },
          stroke: {
            width: 0,
            colors: undefined
          },
          yaxis: {
            show: false
          },
          legend: {
            position: 'bottom',
            colors: ['#08c251', '#3078ff', '#ffb812', '#d500b2', '#7e24ea', '#ff2d55'],
          },
          plotOptions: {
            polarArea: {
              rings: {
                strokeWidth: 0
              },
              spokes: {
                strokeWidth: 0
              },
            }
          },
        },
      };
      
      // console.log(chartOptions)
      // console.log(chartData)

    return (
        <>
          <ErrorWrapper>
            <Chart 
            options={chartOptions} 
            series={chartData} 
            type="donut" 
            width={'100%'} />
           </ErrorWrapper>
        </>
    )
}
