import styled, { keyframes } from "styled-components";
import React from "react";
import CultureDials from './cultureDials'
import NewAnalytics from './newAnalytics'
import Chart from "react-apexcharts";
import RelativeGraph from "./RelativeGraph"

const data = {
          
            series: [{
              name: 'series1',
              data: [31, 40, 28, 51, 42, 109, 100]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                 toolbar:{
                	show:false
                }
              },
              grid:{
              	padding:{
              		right:50
              	}
              },
              dataLabels: {
                enabled: false
              },
              annotations:{
              	points: 
					[
					  {
					    x: new Date('2018-09-19T06:30:00.000Z').getTime(),
					    y: 100,
					    marker: {
					      size: 8,
					    },
					    label: {
					      text: '95%',
					      borderWidth:0,
					      style:{
					      	fontSize:24,
					      	fontWeight:'bold'
					      }
					    }
					  }
					]
              },
              stroke: {
                curve: 'smooth',
                colors:['#6a00ff']
              },
              fill:{
              	colors:['#6a00ff']
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };

const NewCulture = () =>{

	return (
		<Container>
			<Card>
				<Title>Team Health Report</Title>
				<DateText>Report date: March 30th,2021</DateText>
				<Chart options={data.options} series={data.series} type="area" height={350}/>
				<DataContainer>
				
					<DialContainer>
						<CultureDials title={"Resilience"}/>
						<CultureDials title={"Clarity"}/>
						<CultureDials title={"Communication"}/>
						<CultureDials title={"Trust"}/>
						<CultureDials title={"Impact"}/>
						<CultureDials title={"Alignment"}/>
					</DialContainer>
				</DataContainer>
			</Card>
			
		</Container>
		)
}


export default NewCulture


const DialContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;

`

const Container = styled.div`
	max-width:1200px;
`

const Card = styled.div`
	width:100%;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
 	padding:30px 40px;
  margin-top:20px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:25px;
	font-weight:bold;
`
const DateText = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:5px;
	margin-bottom:30px;
	margin-left:3px;
`

const Factor = styled.div`
	width:100%;
	display:flex;
	justify-content:space-between;
	margin-top:25px;
	align-items:center;
`

const FactorTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
`	

const Score = styled.div`
	font-size:25px;
	font-weight:bold;
	font-family: 'Red Hat Display', sans-serif;
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	max-width:80%;
`
const ChartContainer = styled.div`
	margin-top:30px;
`

const Sub = styled.div`
	font-size:10px;
	font-weight:300;
	font-family: 'Red Hat Display', sans-serif;
`

const ScoreContainer = styled.div`
	margin-right:20px;
	display:flex;
	flex-direction:column;
	align-items:center;
`

const TwoScores = styled.div`
	display:flex;
	min-width:125px;
	align-items:center;
`

const DataContainer = styled.div`
	padding:0 30px;
`
const GraphTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
	margin-top:20px;
`