import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import { Progress } from 'semantic-ui-react'

const QuestionResponse = ({ questionTab, response }) => {
	return (
		<>
			{questionTab?.dataType === 'float' &&
				<Progress percent={response?.[questionTab?.id]} color='violet' progress size='small' style={{ marginBottom: 0 }} />}
			{questionTab?.dataType === 'text' &&
				<Text>{response?.[questionTab?.id]}</Text>
			}
			{questionTab?.dataType === 'array' &&
				response?.[questionTab?.id].map((res, i) => {
					return <Text key={i}>{res}</Text>
				})

			}
		</>
	);
}

const Comment = ({ response, questionTab, allQues }) => {
	const [combinedQues, setCombinedQues] = useState(false)

	useEffect(() => {
		if (questionTab) {
			setCombinedQues(questionTab?.combine_with.map((qt, i) => {
				return allQues.filter(aq => aq.id === qt)[0]
			})[0])
		}

	}, [questionTab, allQues]);

	// console.log(questionTab)
	// console.log(response)
	// console.log(questionTab?.combine_with.map((qt, i) => {
	// 	return allQues.filter(aq => aq.id === qt)[0]
	// 	})[0])
	return (
		<Container collapse={questionTab?.dataType === 'float'}>
			<Avatar src={response?.picture} />
			<Data>
				<Name>{response?.name}</Name>
				<QuestionResponse
					questionTab={questionTab}
					response={response}
				/>

				<QuestionResponse
					questionTab={combinedQues}
					response={response}
				/>

			</Data>
		</Container>
	)
}

export default Comment

const Container = styled.div`
	padding:${props => props.collapse ? '15px 30px' : '30px'};
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display:flex;
	margin-top:${props => props.collapse ? '5px' : '20px'};
	transition:all 1s;
`

const Avatar = styled.img`
	width:50px;
	height:50px;
	border-radius:25px;
	margin-right:10px;
`

const Data = styled.div`
	width:100%;
`

const Name = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:10px;
`

const Text = styled.div`
	
`