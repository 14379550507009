import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginBottom: 30,
    position: "relative",
    marginTop: 20,
  },
  bottom: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    width: "22%",
    display: "flex",
    alignItems: "flex-end",
    textAlign: "right",
    paddingRight: 5,
    fontSize: 10,
    borderRight: "1px solid #2C3644",
    paddingTop: 2,
    paddingBottom: 2,
    color: "#2C3644",
  },
  bar: {
    height: 8,
    opacity: 0.9,
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingTop: 2,
    paddingBottom: 2,
  },
  score: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 8,
    position: "absolute",
    right: -25,
    top: -2,
    opacity: 1,
    color: "#2C3644",
  },
  lines: {
    position: "absolute",
    left: "20%",
    height: "100%",
    width: "calc(80% + 20px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    height: "calc(100% + 10px)",
    width: 1,
    borderLeft: "0.5px dashed black",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chart: {
    position: "relative",
  },
  text: {
    position: "absolute",
    top: -35,
    left: -25,
    fontSize: 7,
    color: "grey",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: 50,
    textAlign: "center",
  },
  norm: {
    position: "absolute",
    height: "100%",
    width: "100%",
    left: "20%",
  },
});

/**
 * Returns the average, and reverse scoring as per new specs
 * @param {number} avg current average
 * @param {*} item object containing data regarding question type
 */
const getTableAvg = (avg, item) => {
  // if (item && item.reverse) {
  //   return 100 - avg;
  // }

  return avg;
};

const Chart = (props) => {
  const {
    options,
    data,
    question,
    barColor,
    subSelected,
    previous,
    anchor,
    questionId,
    dimension,
    factor,
  } = props;

  const average = (array) => {
    let total = 0;
    let n = 0;
    array.map((item) => {
      total += item != null ? item : 0;
      n += item != null ? 1 : 0;
    });

    if (!total) {
      return 0;
    }

    return total / n;
  };

  const get_last_question_total = (data, qId, dimension, factor) => {
    if (data.length === 0) {
      return 0;
    }

    return average(
      data?.map((item) => {
        const q = item.questions.find((f) => {
          return f.q === qId && f.id === dimension && f.factor === factor - 1;
        })?.response;

        return q;
      })
    );
  };

  const get_cat_average = (cat, data, qId, dimension, factor) => {
    return get_last_question_total(
      data.filter((f) => {
        return f.categories.find((f) => f.id === anchor)?.response === cat;
      }),
      qId,
      dimension,
      factor
    );
  };

  const optionsSorted = options
    .map((option) => {
      let responseGroup = !subSelected
        ? data.find((group) => group.role === option.id)
        : data.find((group) => group.role === option);

      return {
        average:
          (responseGroup &&
            getTableAvg(Math.round(responseGroup?.average * 10), question)) ||
          0,
        name: !subSelected ? option?.name : responseGroup?.label?.name,
        showZero: responseGroup?.distribution?.length > 0,
        id: !subSelected ? option.id : option,
      };
    })
    .sort((a, b) => a.average - b.average)
    .reverse();

  let avg = 0;

  optionsSorted.forEach((option) => {
    avg = avg + option.average;
  });

  avg = avg / optionsSorted.length;
  return (
    <View style={styles.page}>
      <View style={styles.chart}>
        {optionsSorted.map((item) => {
          if (!item.showZero) {
            return null;
          }

          const catAvg = previous
            ? get_cat_average(
                item.id,
                previous,
                questionId,
                dimension,
                factor
              ) * 10
            : 0;

          return (
            <View style={styles.row}>
              <Text style={styles.title}>{item.name}</Text>
              <View
                style={[
                  styles.bar,
                  {
                    width: 78 * (item.average / 100) + "%",
                    backgroundColor: barColor,
                  },
                ]}
              >
                <Text style={styles.score}>
                  {Math.floor(item.average) + "%"}
                </Text>
              </View>
              {catAvg > 0 && (
                <View style={styles.norm}>
                  <View
                    style={[styles.line, { left: (catAvg / 100) * 78 + "%" }]}
                  ></View>
                </View>
              )}
            </View>
          );
        })}
      </View>
    </View>
  );
};

export default Chart;
