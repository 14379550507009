import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import SummaryData from './SummaryData'
import Individual from './individual'

const data = {
          
            series: [{
              name: 'Last week',
              data: [1,4,5,2,4]
            },{
              name: 'This week',
              data: [2,3,6,3,3]
            },],
            options: {
              chart: {
                height: 350,
                type: 'bar',
                toolbar:{
                	show:false
                }
              },
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                }
              },
              colors:['#c3a7fd','#6a00ff'],
              dataLabels: {
                enabled: false
              },
              stroke: {
                width: 2
              },
              
              grid: {
                row: {
                  colors: ['#fff', '#f2f2f2']
                }
              },
              xaxis: {
                labels: {
                  rotate: -45
                },
                categories: ['Not at all','Not much','Somewhat','Mostly','Absolutely'
                ],
                tickPlacement: 'on'
              },
              yaxis: {
                title: {
                  text: 'Responses',
                },
              },
              fill: {
              	colors:['#c3a7fd','#6a00ff'],
                type: 'gradient',
                gradient: {
                  shade: 'light',
                  type: "horizontal",
                  shadeIntensity: 0.25,
                  gradientToColors: undefined,
                  inverseColors: true,
                  opacityFrom: 0.85,
                  opacityTo: 0.85,
                  stops: [50, 0, 100]
                },
              }
            },
            
          };

const NewAnalytics = () =>{

	return (
		<Container>
			<SummaryData/>
		</Container>
		)
}


export default NewAnalytics



const Container = styled.div`
	width:100%;
`

const Question = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:25px;
	margin-top:30px;
	margin-bottom:50px;
`