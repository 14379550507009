import React from 'react';
import styled, { keyframes } from 'styled-components';
import TextArea from './TextArea';
import SideChat from './SideChat';

const ActionPlan = () => {
    const [open,setOpen] = React.useState(false)

    return (
        <Container open={open}>
            <PlanContainer open={open}>
                <Title>What culture issues are you aiming to improve in this plan?</Title>

                <TextArea
                    setOpen={()=>setOpen(true)}/>
            </PlanContainer>

            <SideChat 
                open={open} 
                setOpen={()=>setOpen(false)}/>
           
        </Container>
    )

}


export default ActionPlan;



const Container = styled.div`
    padding-left:60px;
    margin-top:40px;
    font-family:"Raleway";
    width:100%;
    display:flex;
    align-items:center;
    justify-content:flex-start;
    position:relative;
    overflow:hidden;
    transition:all 0.5s ease-in-out;
`



const Title = styled.div`
    font-size:20px;
    margin-top:20px;
    margin-bottom:10px;
`

const PlanContainer = styled.div`
    width:${props=>props.open? 'calc(100% - 500px)':'800px'};
    display:flex;
    flex-direction:column;
    transition:all 0.5s ease-in-out;
`
