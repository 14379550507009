import styled from "styled-components";
import React, { useEffect } from "react";
import { Icon } from "semantic-ui-react";
import TopProgress from "./TopProgress";
import { SG_GET_SURVEY_STRUCTURES } from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const Side = ({ open, setOpen, setStage, stage }) => {
  const dispatch = useDispatch();
  const { get_auth, get_survey_structure, core_data } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  if (!core_data) return null;

  return (
    <Container open={open}>
      <IconContainer onClick={() => setOpen(!open)}>
        <Icon
          name={open ? "chevron left" : "chevron right"}
          style={{ margin: 0, marginTop: -3 }}
        />
      </IconContainer>
      {open && (
        <SideContent>
          <Title>Culture Data Analytics</Title>
          <Description>
            Your table of contents for the tools you can use to dynamically
            understand every part of your culture.
          </Description>
          <TopProgress step={stage} setStep={(s) => setStage(s)} />
        </SideContent>
      )}
    </Container>
  );
};

export default Side;

const Container = styled.div`
  height: calc(100vh - 200px);
  position: fixed;
  left: 0px;
  top: 100px;
  border-radius: ${(props) =>
    props.open ? "0px 15px 15px 0px" : "0px 5px 5px 0px"};
  width: ${(props) => (props.open ? "300px" : "30px")};
  background-color: #ebf1fd;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 100;
`;

const IconContainer = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 10px;
  color: #2a3039;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Raleway";
  margin-bottom: 30px;
`;
const SideContent = styled.div`
  width: 300px;
  padding: 30px;
  padding-top: 40px;
  padding-left: 40px;
  background-color: #ebf1fd;
`;
