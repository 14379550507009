import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Checkbox } from 'semantic-ui-react';


const Screen6 = () => {
    const [selected, setSelected] = useState(false);

    return (
        <Container>
            <Question>
               What items would you like included in your plan?
            </Question>


            
              <Checkbox label='Micro actions' style={{marginBottom:10}}/>
              <Checkbox label='Weekly checklist' style={{marginBottom:10}}/>
              <Checkbox label='Monthly focus areas' style={{marginBottom:10}}/>
              <Checkbox label='Quarterly goals' style={{marginBottom:10}}/>
              <Checkbox label='Action steps with deadlines' style={{marginBottom:10}}/>
              <Checkbox label='Behavioural examples' style={{marginBottom:10}}/>
              <Checkbox label='Scenario rundowns' style={{marginBottom:10}}/>



           
        </Container>
    )

}

export default Screen6;

const Container = styled.div`
    width:100%;
    font-family:"Raleway";
    display:flex;
    flex-direction:column;
`

const Question = styled.div`
    font-size:28px;
    margin-bottom:40px;
    font-weight:600;
    line-height:1.2;
`
const Textarea = styled.textarea`
    width:100%;
    height:200px;
    padding:10px;
    border:1px solid #ccc;
    font-size:16px;
    margin-bottom:20px;

    font-family:"Raleway";
    resize:none;
    box-sizing:border-box;
`