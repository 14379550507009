import React from "react";
import { useLocation, Link } from "react-router-dom";

const RelativeLink = ({ to, children, style }) => {
  const location = useLocation();
  const basePath = location.pathname.split("/").slice(0, -2).join("/");

  return (
    <Link style={style} to={`${basePath}/${to}`}>
      {children}
    </Link>
  );
};

export default RelativeLink;
