const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.


export default async function make_call(type,text,section){

    let prompt
    if(type==='draft'){
        prompt = `
        You are an assistant helping a user build a culture action plan. The user is trying to start a draft.
        I will provide you with some information to best start a draft of this action plan section.

        1. The culture issue identified (ISSUE).
        2. The section in which this user is looking to build a plan for. (SECTION)
        3. The main task we want you to solve in the recommendation and solution output. (TASK).

        ISSUE: 
        Lack of clarity on the organization's vision and values: Many employees expressed 
        confusion or uncertainty about the organization's vision and values. This lack of 
        clarity can lead to a disconnect between employees' daily work and the organization's
        overall goals. The organization should communicate its vision and values clearly and
        consistently to create a sense of shared purpose and direction.

        SECTION:
        The question being asked in this section is: What culture issue are you aiming to improve in this plan?
        
        TASK:
        Build a draft paragraph which helps to address the issue and is specifically for the identified SECTION of the plan.
        Your output should be a draft for the identified SECTION of the plan. 
            
        OUTPUT:
        `;
    }else if(type==='improvement'){

        prompt = `
         Your goal is to edit the paragraph we provide below based on the instructions provided.

         PARAGRAPH TO EDIT:
         ${section}

         INSTRUCTIONS:
         ${text}

         EDITED PARAGRAPH:
         `;

    }




        return fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer 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`
                },
                body: JSON.stringify({
                    messages: [{"role":"system","content":"You are an assisant designed to help build drafts of culture plans, and your outputs are suggestions for different sections of an action plan. Don't title your output"},{"role":"user","content":prompt}],
                    model:'gpt-3.5-turbo-1106'
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                return data.choices[0].message.content.trim()
            })
            .catch(error => {
                console.error("Error:", error);
            });
}

