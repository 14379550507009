import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";

const Tabs = ({ onSelect, selected, options }) => {
	return (
		<Container>
			{options.map((item) => {
				return (
					<Tab
						selected={selected == item}
						onClick={() => onSelect(item)}
					>
						{item}
					</Tab>
				);
			})}
		</Container>
	);
};

export default Tabs;

const Container = styled.div`
	widht: 100%;
	display: flex;
	margin-bottom: 10px;
`;

const Tab = styled.div`
	padding: 10px 20px;
	border-bottom: ${(props) =>
		props.selected ? "2px solid blue" : "1px solid lightgrey"};
	font-family: "Barlow Semi Condensed", sans-serif;
	cursor: pointer;
`;
