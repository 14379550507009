import React, { useState,useEffect } from 'react';
import {generate_milestones} from "../Request"
import styled, { keyframes } from "styled-components";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Icon} from 'semantic-ui-react'
import Loading from './Loading'


const parse_json_list = (json) =>{
    try{
        return JSON.parse(json).map((item,index)=>{
            return {id:index,value:item}    
        })
    }
    catch{
        return []
    }
}

const Milestones = ({text,confirm}) => {
    const [response,setResponse] = useState([])
    const [loading,setLoading] = useState(true)
    const [error,setError] = useState(false)

    const ask_question = async () =>{
       

        
        const prompt = `
          We've created a recommendation to improve culture in an organization. 
          That plan is represented in JSON format below:

            ${text}

          Ignore the JSON structure for now, and focus on the contents of the plan.
          
          Please create 2-3 distinct milestones for this plan. Each milestone should build on the last. 
          And it should ultimately achieve the goal of improving the targeted outcome of this plan.
          Please return your response in a list of strings only. Don't label each milestone either, simply
          add the milestone information in each string. 
          
          Milestones must be actionable and measurable. They should be distinct achievements in the execution of the plan
          described above. 

          As the following example shows:

          ['Communicate your priorities to your team','Host a team building session','Debrief and review the outcomes of the team building session']
        
            Never generate more than 3 milestones, and never generate less than 2 milestones. Never lebel the milestones.
          `;

         
           
            async function someFunction(prompt) {
                let tries = 0
                let listed = []
                while(tries<3){
                    const message = await generate_milestones(prompt);
                    listed = parse_json_list(message)
                    if(listed.length>0){
                        break
                    }else{
                        tries +=1
                    }
                }
            
            if(tries==3){
                setError(true)
            }
            
            setResponse(listed);
            setLoading(false)
        }

        someFunction(prompt)

        
    }

    useEffect(()=>{
        if(text){
            ask_question()
        }
        
    },[text])

    return (
        <Container>
            <Content>
                <Title>Set goals for your plan</Title>
                <Description>
                    Now that you have a plan, let's set some goals around it. 
                    This will help you track your progress and stay on track.
                </Description>

            {loading && <LoadingText>Building your milestones...</LoadingText>}

            {loading? <Loading/>:
            <>
                <Title>
                    Your plan milestones
                </Title>

                <ResponseContainer>
                {/* <ul>
                {response.map((item,index)=>{
                    return <li>{item.value}</li>
                    })}
                </ul> */}

                <MyListComponent items={response} setItems={setResponse} confirm={confirm}/>
            
                </ResponseContainer>
                </>}
            </Content>
           
        </Container>
    )

}

export default Milestones;

const MyListComponent = ({items,setItems,confirm}) => {
   
    const handleAddItem = () => {
        const newItem = {
            id: items.length + 1,
            value: `Item ${items.length + 1}`
        };
        setItems([...items, newItem]);
    };

    const handleEditItem = (id, newValue) => {
        const newItems = items.map(item => {
            if (item.id === id) {
                return { ...item, value: newValue };
            }
            return item;
        });
        setItems(newItems);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const reorderedItems = Array.from(items);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);

        setItems(reorderedItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {items.map((item, index) => (
                            <ListItem 
                                key={item.id} 
                                item={item} 
                                index={index}
                                onSave={handleEditItem}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            <ButtonContainer>
                <Button2 onClick={handleAddItem}>Add Milestone</Button2>
                <Button onClick={confirm}>Confirm Milestones</Button>
            </ButtonContainer>
            
        </DragDropContext>
    );
};

const ListItem = ({ item, index, onEdit, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(item.value);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        onSave(item.id, editedValue);
        setIsEditing(false);
    };

    return (
        <Draggable draggableId={String(item.id)} index={index}>
            {(provided) => (
                <Item 
                    ref={provided.innerRef} 
                    {...provided.draggableProps} 
                    {...provided.dragHandleProps}
                    style={{ ...provided.draggableProps.style }}
                >
                    {isEditing ? (
                        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                            <TextArea value={editedValue} onChange={(e) => setEditedValue(e.target.value)} />
                            <button onClick={handleSaveClick}>Save</button>
                        </div>
                    ) : (
                        <div  style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                            <Text>{item.value}</Text>
                            <Clickable>
                                <Icon name="pencil" onClick={handleEditClick}/>
                            </Clickable>
                        </div>
                    )}
                </Item>
            )}
        </Draggable>
    );
};


const Container = styled.div`
    width:100%;
font-family:'Raleway';
display:flex;
flex-direction:column;
align-items:center;
margin-top:40px;
`

const Content = styled.div`
    width:100%;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height:1.2;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
`

const Description = styled.div`
    margin-bottom: 40px;
`


const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const Button2 = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ResponseContainer = styled.div`
    white-space: pre-wrap;
`

const Item = styled.div`
    padding:20px;
    margin-bottom:10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`

const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:14px;
    font-weight:400;
    line-height:1.5;
    margin-bottom:20px;
`

const Clickable = styled.div`
    cursor:pointer;
    font-size:12px;
    color:#2D70E2;
`

const Text = styled.div`
    width:100%;
`
const LoadingText = styled.div`
    margin-bottom:20px;
    font-size:14px;
    font-weight:bold;

`