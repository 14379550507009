import styled, { keyframes } from 'styled-components';
import React from "react";
import {Icon} from 'semantic-ui-react'


const Component1 = () =>{

    return (
        <Container>

            <T1>We've taken the time to get to know your data.</T1>
            <Text delay={0.3}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquam nisl nunc vitae nisl.
            </Text>

            <CardContainer>
                <Card delay={0.5}>
                    <Title>Step 1</Title>
                    <T2>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquam nisl nunc vitae nisl. 
                    </T2>
                </Card>

                <Card delay={1}>
                    <Title>Step 2</Title>
                    <T2>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquam nisl nunc vitae nisl. 
                    </T2>
                </Card>

                <Card delay={1.5}>
                    <Title>Step 3</Title>
                    <T2>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget ultricies ultrices, nunc nisl aliquam nunc, quis aliquam nisl nunc vitae nisl.
                    </T2>
                </Card>
            </CardContainer>
        </Container>
    )
}

export default Component1

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`

const CardContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:40px;
`

const Card = styled.div`
    width:300px;
    padding:25px;
    display:flex;
    flex-direction:column;
    background-color:#EBF1FD;
    margin-right:20px;
    border-radius:10px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const T2 = styled.div`
    font-size:12px;
    color:#2A3039;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;

`