import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const DownloadButton = (props) => {
  const { btnAction, showControl, setPdfGenerating } = props;
  const [generateClicked, setGenerateClicked] = useState(false);

  const handleGenerateButtonClicked = () => {
    setPdfGenerating(true);
    btnAction();
    setGenerateClicked(true);
  };

  const generateButton = (
    <PDFGenerateWrapper>
      <Button onClick={() => handleGenerateButtonClicked()}>Build PDF</Button>
    </PDFGenerateWrapper>
  );

  if (!showControl) {
    return <NoDataDiv>Not Enough Data</NoDataDiv>;
  }

  return generateClicked ? null : generateButton;
};

const NoDataDiv = styled.div`
  width: 110px;
  position: absolute;
  left: 0;
  line-height: 0px;
`;

const PDFGenerateWrapper = styled.div`
  width: 110px;
  position: absolute;
  left: 0;
  top: 0;
`;

DownloadButton.propTypes = {
  btnReady: PropTypes.bool,
  btnAction: PropTypes.func,
};

export default DownloadButton;
