import React, { useEffect, useState } from "react";
import TemplateOTP from "../pages/TemplateOTP";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import { Loader, Icon } from "semantic-ui-react";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

const OTPInstance = (props) => {
  const {
    filteredData,
    organizationData,
    reportData,
    nav_state,
    WhiteLabel,
    questionStructure,
    get_survey_structure,
    selectedReport,
    rule,
    URI,
    spiderOptions,
    spiderTwoOptions,
    calculated,
    setPdfGenerating,
    overview,
    otpView,
    URI_OTP,
    selectedSport,
    accessSubfactor,
    reportComments,
    downloadIcon,
    aiData,
    isFrench
  } = props;

  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentBlob, setDocumentBlob] = useState();
  const [documentUrl, setDocumentUrl] = useState();

  /**** Helper Functions ****/
  const generatePDF = async () => {
    // This hides the loading spinner which displays *BEFORE* the pdf offially starts generating.
    setPdfGenerating(false);
    setLoading(true);
    const pdfDoc = await pdf(
      <TemplateOTP
        filterData={filteredData}
        selectedOrg={organizationData.name}
        nav={nav_state}
        WhiteLabel={WhiteLabel}
        structure={questionStructure}
        categories={get_survey_structure[0]}
        selectedReport={selectedReport}
        rule={rule}
        chart={URI}
        chartTwo={URI_OTP}
        spiderOptions={spiderOptions}
        spiderTwoOptions={spiderTwoOptions}
        calculated={calculated}
        anchor={props.anchor}
        {...props}
        otpView={otpView}
        reportData={reportData} // This does not appear to be used in TemplateOTP.js
        reportComments={reportComments}
        aiData={aiData}
      />
    ).toBlob();

    setDocumentBlob(pdfDoc);
    setDocumentUrl(window.URL.createObjectURL(pdfDoc));
    setLoading(false);
  };

  /**** useEffect Hooks ****/
  useEffect(() => {
    generatePDF();
  }, [
    filteredData,
    organizationData,
    reportData,
    nav_state,
    WhiteLabel,
    questionStructure,
    get_survey_structure,
    selectedReport,
    rule,
    URI,
    spiderOptions,
    spiderTwoOptions,
    calculated,
  ]);

  useEffect(() => {
    if (documentBlob && !saved && !loading) {
      setSaved(true);
      saveAs(
        documentBlob,
        `OTP-${
          !props.otpView && props.sportName
            ? `${props.sportName} Report`
            : "Overall Report"
        }.pdf`
      );
    }
  }, [documentBlob, loading]);

  /****** Render Functions *****/
  if (loading) {
   return (
    <div
     style={{
      minWidth: 125,
      justifyContent: "center",
      alignItems: "center",
     }}
    >
     <Loader inline active size="small" />
    </div>
   );
  }

  if (documentUrl && !overview) {
    return (
      <div
        style={{
          height: "25px",
          width: "110px",
          cursor: "pointer",
          marginLeft: "-65px",
        }}
      >
        <a
          href={documentUrl}
          download={`OTP-${
            selectedSport ? `${selectedSport} Report` : "Overall Report"
          }.pdf`}
        >
          <Icon
            name="file pdf"
            size="large"
            style={{
              color: "rgb(156,35,21)",
            }}
          />
        </a>
      </div>
    );
  }

  if (documentUrl && overview) {
    return (
      <div
        style={{
          height: "25px",
          width: "110px",
          cursor: "pointer",
          marginLeft: "-200px",
        }}
      >
        <a
          href={documentUrl}
          download={`OTP-${
            selectedSport ? `${selectedSport} Report` : "Overall Report"
          }.pdf`}
        >
          {(downloadIcon && <Button icon="download" />) || (
            <PDFDownloadWrapper>
              <Button>Download Again</Button>
            </PDFDownloadWrapper>
          )}
        </a>
      </div>
    );
  }

  return null;
};

const PDFDownloadWrapper = styled.div`
  width: 150px;
  left: 0;
  top: 0;
`;

export default OTPInstance;
