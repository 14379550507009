import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dimmer, Loader, Pagination } from "semantic-ui-react";
import Insight from "./Insight_payroll";
import Filter from "./Filter";

import {
	effect_size_calculation,
	primary_effect_size_calculation,
	combo_effect_size_calculation,
} from "../testing/effect_size";

import categories from "../structure/survey_structure";
import questions from "../structure/question_structure";
import { Data_Generator } from "../testing/generate_data";

const getTertiary = (name, id) => {
	return categories.categories.filter((f) => f.name == name)[0].options[id]
		.name;
};

const ES_levels = [0, 0.2, 0.5, 0.8, 1.2];

const Insights = () => {
	const [risk, setRisk] = useState(0);
	const [selected, setSelected] = useState("Overall");
	const risks = ["None", "Modest", "Moderate", "High", "Very High"];
	const colors = ["grey", "yellow", "orange", "red", "red"];
	const [data, setData] = useState([]);
	const [dataRef, setRef] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (data.length === 0) {
			let _data = combo_effect_size_calculation(
				questions,
				categories,
				Data_Generator(5000, 1, categories, questions),
				0
			)
				.flat()
				.flat()
				.map((item) => {
					return item.data.map((i) => {
						return i.ES.map((e, idx) => {
							return {
								factor: item.factor,
								primary: item.category,
								secondary: item.category2,
								risk: e.risk,
								n: e.n,
								tertiary: getTertiary(i.name, idx),
							};
						});
					});
				})
				.flat()
				.flat()
				.sort((a, b) => {
					return a.risk - b.risk;
				});
			setData(_data);
			setRef(_data);

			setTimeout(() => setLoading(false), 400);
		}
	}, []);

	return (
		<Container>
			<InsightContainer>
				{data.slice(0, 3).map((item) => {
					return (
						<Insight
							title={`${item.factor} in ${item.tertiary} ${
								item.secondary == "Overall"
									? "employees"
									: item.secondary
							} ${item.primary == "Overall" ? "" : item.primary}`}
							risk={item.risk}
							n={item.n}
							link={item.factor}
							description={
								"Inclusion scores have increased on average by +5% this quarter in the Halifax location"
							}
						/>
					);
				})}
			</InsightContainer>
		</Container>
	);
};

export default Insights;

const Container = styled.div`
	display: flex;
	width: 70%;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 0px;
`;

const Filters = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

const Risk = styled.div`
	font-size: 20px;
`;

const Add = styled.div`
	cursor: pointer;
`;

const RiskContainer = styled.div``;

const Title = styled.div`
	font-size: 22px;
	color: grey;
	margin-bottom: 10px;
`;

const InsightContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;
