import styled from "styled-components";
import React from "react";
import { fontSizes, primaryColor, inactiveColor } from "../styleConfig";

const Tabs = ({ onSelect, selected, options }) => {
 return (
  <Container>
   {options.map((item) => {
    return (
     <Tab key={item} selected={selected === item} onClick={() => onSelect(item)}>
      <TabText>{item}</TabText>
     </Tab>
    );
   })}
  </Container>
 );
};

export default Tabs;

const Container = styled.div`
 width: 100%;
 display: flex;
 margin-bottom: 1px;
 padding-bottom: 20px;
`;

const Tab = styled.div`
 margin-right: 50px;
 border-bottom: ${(props) =>
  props.selected ? `2px solid ${primaryColor}` : ""};
 font-style: normal;
 font-weight: 300;
 font-size: ${fontSizes.medium};
 line-height: 15px;
 cursor: pointer;
 flex-direction: row;
 color: ${(props) => (props.selected ? `${primaryColor}` : `${inactiveColor}`)};
`;

const TabText = styled.div`
  margin-bottom: 5px;
`;
