import { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {get_most_recent} from "reports/Audit/data_navigation/calculate_data";

import {
    SG_GET_SURVEY_STRUCTURES,
    SG_GET_EMPLOYEE_CATEGORIES,
    SG_GET_SURVEY_QUESTIONS,
    SG_GET_CULTURE_AUDIT_REPORTS,
    SG_GET_SURVEY_STANDARD,
  } from "constants/actions";
import { sort } from "mathjs";

function Fetch_data({dei}){
    const dispatch = useDispatch();
    const surveyType = dei?7:6
    const auditTypeId = dei?4:3

    const [data,setData] = useState([])
    const [sort_order, setSortOrder] = useState(null)

    // Get the auth state from the redux store
    const get_auth = useSelector(state => state.auth, shallowEqual);

    // get the culture audit reports only when the auth state has been generated
    const get_culture_audit_reports = useSelector(state => state.debrief_schedule.culture_audit_reports, shallowEqual);
    const employeeId = useSelector(state => Number(state.auth?.employee_id), shallowEqual);

    const get_survey_structure = useSelector(state => state.surveystructure.survey_structure, shallowEqual);

    const get_survey_questions = useSelector(state => state.surveyquestions.survey_questions, shallowEqual);

    const get_organizations = useSelector(state => state.organizations, shallowEqual);
    
    

    // With the auto token, fetch the culture audit reports
    useEffect(() => {
        if(Number(get_auth.organization_id) && get_culture_audit_reports?.length === 0){
            dispatch({ 
                type: SG_GET_CULTURE_AUDIT_REPORTS, 
                payload: `audit-type-id=${auditTypeId}&organization=${Number(get_auth.organization_id)}`
             });
        }

        if(Number(get_auth.organization_id) && get_survey_structure?.length === 0){
            dispatch({
                type: SG_GET_SURVEY_STRUCTURES,
                payload: `organization=${Number(get_auth.organization_id)}`,
            });
        }
        console.log(Number(get_auth?.organization_id))
        if(!sort_order && Number(get_auth?.organization_id)){
            const organization = get_organizations?.[Number(get_auth?.organization_id)];
            setSortOrder(organization?.services_enabled?.find(f=>f.name=="Audit").question_sort_order)
        }

        
    }, [get_auth]);

    useEffect(() => {
        if(get_survey_questions?.length === 0){
            dispatch({
                type: SG_GET_SURVEY_QUESTIONS,
                payload: `survey_type=${surveyType}&status=2`,
            });
        }
    }, [])

    


    //Sort the reponses in the culture audit reports
    useEffect(() => {

        if(!get_culture_audit_reports.response){
            return 
        }
        if(get_culture_audit_reports.response.length==0){
            return 
        }

        let sorted_responses = get_culture_audit_reports.response.sort(
                (a, b) => b.id - a.id);

        let most_recent = get_most_recent(sorted_responses);

        setData(most_recent)

        }, [get_culture_audit_reports]);


   return {
         data,
         sort_order,
         get_survey_structure,
        get_survey_questions,
   }

}

export default Fetch_data;