import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dimmer, Loader, Modal } from "semantic-ui-react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";


const statusColors = {
  Scheduled: {
    container: "#FFFAF1",
    text: "#FEA711",
  },
  Error: {
    container: "#FEF3F4",
    text: "#F15062",
  },
  Completed: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  Pending: {
    container: "#F3F7FF",
    text: "#2D70E2",
  },
  New: {
    container: "#F1FAF9",
    text: "#00B67A",
  },
  "In Progress": {
    container: "#F3F7FF",
    text: "#2D70E2",
  },
};

const getAssignedTasks = (empObj, assignedTasks, taskTemplates) => {
  // Find the assigned tasks for the employee
  const assignedTasksForEmployee = assignedTasks.results.filter((task) =>
    task.assigned_employees.includes(empObj.employee_id)
  );
  const taskTemplatesForEmployee = taskTemplates.results.filter((template) =>
    assignedTasksForEmployee.some(
      (task) => task.task_template_user_tasks === template.id
    )
  );
  // Create a new array of objects with the required information
  return taskTemplatesForEmployee.map((template) => {
    return {
      taskId: assignedTasksForEmployee.find(
        (task) => task.task_template_user_tasks === template.id
      ).id,
      taskRefId: assignedTasksForEmployee.find(
        (task) => task.task_template_user_tasks === template.id
      ).task_ref_id,
      taskName: template.name,
      task_template_user_tasks: assignedTasksForEmployee.find(
        (task) => task.task_template_user_tasks === template.id
      ).task_template_user_tasks,
      taskStatus: assignedTasksForEmployee.find(
        (task) => task.task_template_user_tasks === template.id
      ).status_text,
      taskQuestions: template.user_tasks
        .filter((task) => task.task_type === "question")
        .map((question) => {
          return {
            question: question.question,
            id: question.id,
            aiEnhanced: question.aiEnhanced,
          };
        }),
    };
  });
};

const userTaskStatus = (task, userId) => {
  if (
    task?.tracked_user_tasks?.task_completions?.some(
      (obj) => Number(obj.employee_id) === Number(userId)
    )
  )
    return "Completed";
  if (!task?.tracked_user_tasks?.task_responses?.[userId]) return "New";
  return "In Progress";
};

const AssignedTasks = ({ tasks }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const get_auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (tasks) {
      setLoading(false);
    }
  }, [tasks]);

  return (
    <PageContainer>
      <Title>Open Surveys</Title>
      <Description>
        Below are the surveys that have been assigned to you. Click on the
        "View" button to start answering the questions.
      </Description>
      <Description>
        You may update your responses at anytime while the survey is in the
        "Open" status.
      </Description>
      {loading ? (
        <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer>
      ) : (
        tasks.map((task) => {
          return (
            <TaskItem
              task={task}
              // status={userTaskStatus(task, get_auth?.employee_id)}
              status="New"
              onClick={() => {
                navigate(`/simple/audit/manage/questionnaire/${task.taskId}`);
              }}
            />
          );
        })
      )}
    </PageContainer>
  );
};

const TaskItem = ({ task, onClick, status }) => {
  return (
    <TaskContainer>
      <TaskTitle>{task.name || task?.taskName}</TaskTitle>
      <TaskDate>
        Updated: {format(new Date(task?.updated_at), "MM/dd/yyyy")}
      </TaskDate>

      <TaskStatus>
        <StatusContainer
          style={{
            backgroundColor: statusColors[status].container,
            color: statusColors[status].text,
          }}
        >
          {status}
        </StatusContainer>
      </TaskStatus>
      <TaskCompletion></TaskCompletion>
      <ViewButton onClick={() => onClick()}>View</ViewButton>
    </TaskContainer>
  );
};

const TaskContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 8px 20px 0px #3f86fe14;
  justify-content: space-between;
  width: 100%;
`;

const TaskTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const TaskDate = styled.div`
  font-size: 14px;
  color: #666d79;
`;

const TaskStatus = styled.div`
  font-size: 14px;
`;

const TaskCompletion = styled.div`
  font-size: 14px;
  color: #666d79;
`;

const ViewButton = styled.div`
  color: #2d70e2;
  font-size: 12px;
  padding: 6px 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border: none;
  border-radius: 5px;
  margin-left: 20px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
  font-family: "Raleway";
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  color: #666d79;
  margin-top: 10px;
`;

export default AssignedTasks;
