import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import QuestionMark from 'assets/images/UI/question_mark.svg';
import Bin from 'assets/images/UI/delete_red.svg';


const Questions = ({jsonData}) => {

    return (
        <Container>
            <Top>
                <Blue>Name</Blue>
                <Title>{jsonData.title}</Title>

                <Blue>Description</Blue>
                <Description>{jsonData.description}</Description>
            </Top>



            <QuestionArea>

            
            <T2>
                <BlueSquare>
                <Icon src={QuestionMark}/>
                </BlueSquare>
                Questions
            </T2>
            {jsonData.questions.map((question, index) => {
                return (
                    <Question>
                        <Grey>Question #{index+1}</Grey>
                        <Q>{question}</Q>

                        <div style={{display:'flex', justifyContent:'space-between', marginBottom:30}}>
                            <Toggle><CustomToggle checked={true}/> AI Enchanced</Toggle>
                            <Remove><Icon2 src={Bin}/>Remove question</Remove>
                        </div>
                    </Question>    
                )
        })}

            </QuestionArea>
            
            
        </Container>
    )

}

export default Questions;

const CustomToggle = ({ checked, onChange }) => (
    <ToggleSwitch>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="slider"></span>
    </ToggleSwitch>
  );


const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;



const Container = styled.div`
    font-family: "Raleway";
    margin-top: 20px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`

const Top = styled.div`
    background-color:#F8FAFF;
    padding:20px;
    border-radius:10px;
    margin-bottom:30px;
`

const Blue = styled.div`
    color:#2D70E2;
    font-weight:600;
    margin-bottom:5px;
`

const Title = styled.div`
    color:#2A3039;
    font-size:18px;
    font-weight:600;
    margin-bottom:30px;
`

const Description = styled.div`
    color:#2A3039;
    font-size:12px;
`

const Question = styled.div`

`

const Grey = styled.div`
    color:#666D79;
    font-size:10px;
    margin-bottom:5px;
`

const Q = styled.div`
    color:#2A3039;
    font-size:16px;
    margin-bottom:10px;
    border-bottom:1px solid #DFDFDF;
    line-height:1.6;
    padding-bottom:5px;
`

const Toggle = styled.div`
    color:#2A3039;
    font-size:12px;
    font-weight:600;
    display:flex;
    align-items:center;

   
`

const T2 = styled.div`
    color:#2A3039;
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
    display:flex;
    align-items:center;

`

const QuestionArea = styled.div`
    padding:40px;
    border:1px solid #DFDFDF;
    border-radius:10px;
`

const Icon = styled.img`
    width:40px;
`

const Icon2 = styled.img`
  width:20px;
  margin-right:10px;
`
const Remove = styled.div`
  color:#F15062;
    font-size:12px;
    font-weight:600;
    display:flex;
    align-items:center;
    cursor:pointer;
`

const BlueSquare = styled.div`
    width:40px;
    height:40px;
    background-color:#EBF1FD;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-right:10px;
    border-radius:5px;
`

const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 24px; /* Adjust width as needed */
  height: 12px;
  margin-right:10px;

  /* Hide the default checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider (track) */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc; /* Gray when unchecked */
    transition: 0.4s;
    border-radius: 12px;
  }

  /* The knob (circle) */
  .slider::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  /* Checked state styles */
  input:checked + .slider {
    background-color: #27cda7; /* Green when checked */
  }

  input:checked + .slider::before {
    transform: translateX(12px); /* Move the knob to the right */
  }
`;
