function getStandardDeviation(array) {
	const n = array.length;
	if (n == 0) {
		return 0;
	}
	const mean = array.reduce((a, b) => a + b) / n;
	return Math.sqrt(
		array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
	);
}

const average = (array) => {
	let total = 0;
	let n = 0;
	array.map((item) => {
		total += item;
		n += 1;
	});
	return total / n;
};

function calculate_ES(factor, c, option, data) {
	let core = data[0].responses.filter(
		(f) => f.categories[c].response == option
	);
	let standard_deviation = getStandardDeviation(
		data[0].responses.map((resp) =>
			resp.questions
				.filter((f) => f.factor == factor)
				.reduce((a, b) => {
					return a + b.response;
				}, 0)
		)
	);

	let r1 = average(
		core.map((resp) =>
			resp.questions
				.filter((f) => f.factor == factor)
				.reduce((a, b) => {
					return a + b.response;
				}, 0)
		)
	);

	let r2 = average(
		data[0].responses
			.filter((f) => f.categories[c].response != option)
			.map((resp) =>
				resp.questions
					.filter((f) => f.factor == factor)
					.reduce((a, b) => {
						return a + b.response;
					}, 0)
			)
	);

	return { risk: (r1 - r2) / standard_deviation, n: core.length };
}

export const effect_size_calculation = (questions, categories, data) => {
	//Loop through each question
	return questions.factors.map((f, x) => {
		return {
			factor: f.title,
			data: categories.categories.map((c, c_id) => {
				return {
					name: c.name,
					ES: c.options.map((o, o_id) => {
						return calculate_ES(x, c_id, o_id, data);
					}),
				};
			}),
		};
	});
};

export const primary_effect_size_calculation = (
	questions,
	categories,
	data,
	priority
) => {
	let priority_name = priority == 0 ? "Primary" : "Secondary";
	return questions.factors.map((f, x) => {
		return categories.categories
			.filter((f) => f.priority === priority_name)[0]
			.options.map((p, p_id) => {
				let _data = [
					{
						...data[0],
						responses: data[0].responses.filter(
							(f) => f.categories[priority].response === p_id
						),
					},
				];
				return {
					factor: f.title,
					category: p.name,
					data: categories.categories
						.filter((f) => f.priority === "Tertiary")
						.map((c, c_id) => {
							let category_id = categories.categories.findIndex(
								(f) => f.name === c.name
							);
							return {
								name: c.name,
								ES: c.options.map((o, o_id) => {
									return calculate_ES(x, category_id, o_id, _data);
								}),
							};
						}),
				};
			});
	});
};

export const combo_effect_size_calculation = (questions, categories, data) => {
	return questions.factors.map((f, x) => {
		return categories.categories
			.filter((f) => f.priority === "Primary")[0]
			.options.map((p, p_id) => {
				return categories.categories
					.filter((f) => f.priority === "Secondary")[0]
					.options.map((p2, p_id2) => {
						let _data = [
							{
								...data[0],
								responses: data[0].responses.filter(
									(f) =>
										f.categories[0].response === p_id &&
										f.categories[1].response == p_id2
								),
							},
						];

						return {
							factor: f.title,
							category: p.name,
							category2: p2.name,
							data: categories.categories
								.filter((f) => f.priority === "Tertiary")
								.map((c, c_id) => {
									let category_id = categories.categories.findIndex(
										(f) => f.name === c.name
									);
									return {
										name: c.name,
										ES: c.options.map((o, o_id) => {
											return calculate_ES(x, category_id, o_id, _data);
										}),
									};
								}),
						};
					});
			});
	});
};
