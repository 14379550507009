import React, {useEffect,useState} from 'react';
import styled, { keyframes } from 'styled-components';
import {
  REMOVE_TEAM
} from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Button, Confirm } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications'

const Remove = styled.div`
	font-size: 16px;
  font-weight: 500;
  color: #ff2d55;
  cursor:pointer;
  font-family: 'Red Hat Display', sans-serif;
`


const ListItem = styled.div`
	color:#4a4a4a;
	font-size:16px;
	border-bottom: ${props=>props.last? 'solid 1px #e9e9e9' : "" };
	padding:8px 8px 5px 5px;
	cursor:pointer;
	height:25px;
	box-sizing:content-box;
	font-family: 'Red Hat Display', sans-serif;
	color: #545454;
	&:hover{
		background-color:#e9e9e9;
	}

`

const RemoveTeam = (props) =>{
	const apiDispatch = useDispatch();
	const [open,setOpen] = useState(false)
	const { addToast } = useToasts()

	const confirm = () =>{
		apiDispatch({
	      type: REMOVE_TEAM,
	      payload:{id:props.data.id}
	    });
	    addToast(props?.data?.name+' has been successfully removed ', 
            { appearance: 'success',
            autoDismiss: true, })
	    setOpen(false)
	    props.back()
	}
	 

	return (
		<div>
		<Confirm
          open={open}
          onCancel={()=>setOpen(false)}
          onConfirm={()=>confirm()}
          content={'Are you sure you want to remove the '+props?.data?.name}
        />
		<ListItem  last={true}><Remove onClick={()=>setOpen(true)}>Remove Team</Remove></ListItem>
		</div>
		)
}

export default RemoveTeam