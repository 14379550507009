import styled, { keyframes } from "styled-components";
import React from "react";
import QuestionDrop from '../QuestionDrop'
import QuestionSelect from './QuestionSelect'
import {
  calculate_table_data,
  get_labels,
} from "../data_navigation/calculate_data";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Summary from './Summary'
import Distribution from '../DistributionV2'
import Historical from './Historical'
import Context from './Context'
import Insights from './Insights'
import QuestionInsights from './QuestionInsight'

const Pillar = ({ data, nav, WhiteLabel, structure, categories, rule,selected,standards,language_hook }) =>{

	const question = useSelector((state) => state.audit?.selected, shallowEqual);	
	const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
	const filter_last_data = useSelector(
		(state) => state.audit?.filtered_last_data,
		shallowEqual
	);

	const filter_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual
  );

	const [tab,setTab] = React.useState(1)
	const [selectedQ,setSelectedQ] = React.useState(false)



	const [calculated, setCalculated] = React.useState(
	    calculate_table_data(data, nav, categories, anchor, rule, structure)
	  );

	const [labels, setLabels] = React.useState(
    get_labels(nav, categories, anchor)
  );

  React.useEffect(() => {
    // setRender(!render)
    setCalculated(
      calculate_table_data(data, nav, categories, anchor, rule, structure)
    );
    setLabels(get_labels(nav, categories, anchor));
  }, [nav, anchor]);




	return (
		<Container>
			<QuestionDrop
		        color={WhiteLabel.highlight}
		        structure={structure}
		        selected={question}
		        pillarOnly={true}
		      />


		   <Summary 
		   	data={calculated}
		   	last_data={filter_last_data}
		   	structure={structure}
		   	filter_data={filter_data}
		   	standards={standards}
		   	selectedQ={selectedQ}/>

           {question?.level>1?<QuestionSelect
		     	info={calculated.responses}
		     	question={question}
          		questions={structure}
          		q={question.id}
          		raw={data}
          		last_data={filter_last_data}
          		selectedQ={selectedQ}
          		setSelected={(q)=>setSelectedQ(q)}
          		color={WhiteLabel.highlight}/>:""}

        <Tabs>
        	<NavContainer
        		selected={tab==1}
				onClick={() => setTab(1)}
				color={WhiteLabel.highlight}>
        		<Title>Chart</Title>
        	</NavContainer>
        	<NavContainer
        		selected={tab==2}
				onClick={() => setTab(2)}
				color={WhiteLabel.highlight}>
        		<Title>Long Term</Title>
        	</NavContainer>
        	<NavContainer
        		selected={tab==3}
				onClick={() => setTab(3)}
				color={WhiteLabel.highlight}>
        		<Title>Feedback</Title>
        	</NavContainer>
{/*        	<NavContainer
        		selected={tab==4}
				onClick={() => setTab(4)}
				color={WhiteLabel.highlight}>
        		<Title>Changes</Title>
        	</NavContainer>
        	<NavContainer
        		selected={tab==5}
				onClick={() => setTab(5)}
				color={WhiteLabel.highlight}>
        		<Title>Actions</Title>
        	</NavContainer>
        	<NavContainer
        		selected={tab==7}
				onClick={() => setTab(7)}
				color={WhiteLabel.highlight}>
        		<Title>Insights</Title>
        	</NavContainer>*/}
        </Tabs>

        <ContentContainer>
        {tab==1?
      		<Distribution data={calculated} labels={labels} question={question} language_hook={language_hook}/>:
      		tab==2?<Historical data={data} nav={nav} categories={categories} anchor={anchor} rule={rule} structure={structure}/>:
      		tab==3?<Context data={data} categories={categories} structure={structure} language_hook={language_hook}/>
      		:
      		tab==4?<Insights questions={structure} change={true}/>:
      		tab==7?<Insights questions={structure}/>:""}

      	</ContentContainer>

      	<QuestionInsights 
      		open={selectedQ} 
      		setClose={()=>setSelectedQ(false)}
      		filter_data={filter_data}
      		filter_last_data={filter_last_data}
      		anchor={anchor}
      		questions={structure}
      		/>


		</Container>
		)
}

export default Pillar


const Container = styled.div`
	padding-left:58px;
	margin-top:30px;
	position:relative;
`

const NavContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100px;
	padding: 20px 0px 5px 0px;
	// color:{props=>props.selected?'#ab710a':'#9b9b9b'};
	color: #9E9FA1;
	border-bottom:${props=>props.selected?'2px solid #476DFA':""};
	margin-bottom:-1px;

	cursor: pointer;
`;

const Title = styled.div`
	font-size: 12px;
`;

const Tabs = styled.div`
font-family: "Inter", sans-serif;
	border-bottom: 1px solid #DFDFDF;
	display: flex;
`

const ContentContainer = styled.div`
	min-height:500px;
`