/// Libraries
import React from "react";
import { Document, StyleSheet, Font } from "@react-pdf/renderer";

// Components
import Intro from "./OTP/Intro";
import PersonRisks from "./OTP/PersonRisks";
import PerformanceRisks from "./OTP/PerformanceRisks";
import OverallRanking from "./OTP/OverallRanking";

import Comments from "./OTP/Comments";
import PersonDimension from "./OTP/PersonDimension";
import PerformanceDimension from "./OTP/PerformanceDimension";

import CultureMap from "./OTP/CultureMap";

// Templates
import defaultTemplate from "../templates/default.json";
import Methodology from "./OTP/Methodology";

// Utils
import { getFeedbackOTP } from "../utils/cultureUtils";
import AreasOfImprovement from "./OTP/AreasOfImprovement";
import OTPDimensions from "./OTP/OTPDimensions";
import Recommendations from "./OTP/Recommendations";
import PerformanceOverallComment from "./OTP/PerformanceOverallComment";
import PersonOverallComment from "./OTP/PersonOverallComment";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./OTP/translations";
// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";
import PoppinsBold from "assets/fonts/Poppins-Bold.ttf";
import PoppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import PoppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import PoppinsItalic from "assets/fonts/Poppins-LightItalic.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsItalic, fontWeight: 200 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsRegular, fontWeight: 400 },
  ],
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 60,
    paddingHorizontal: 80,
    fontFamily: "Poppins",
  },
  section: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  title: {
    fontSize: 40,
    textAlign: "center",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  table: {
    marginHorizontal: 10,
  },
  subHeading: {
    fontSize: 12,
    paddingBottom: 50,
  },
  spiderSubHeading: {
    fontSize: 12,
    paddingBottom: 30,
    display: "flex",
    textAlign: "center",
    zIndex: 999,
  },
  heading: {
    paddingBottom: "15px",
    left: 0,
    fontSize: 22,
    fontWeight: 500,
  },
  doc: {
    fontFamily: "Poppins",
    fontSize: 14,
  },
  spiderImage: {
    left: 55,
    top: -28,
    maxHeight: 300,
    maxWidth: 400,
    height: 300,
    width: 400,
    zIndex: -99,
  },
  spiderHeading: {
    fontSize: 18,
    paddingBottom: 10,
    fontWeight: 500,
  },
});

const Template = (props) => {
  const {
    chart,
    chartTwo,
    nav,
    categories,
    structure,
    calculated,
    anchor,
    config,
    selectedReport,
    selectedOrg,
    overview,
    reportData,
    rule,
    filterData,
    otpView,
    comments,
    selectedSport,
    reportComments,
    aiData,
    isFrench,

    completionRate,
    surveyDate,
  } = props;

  const lang = isFrench ? "fr" : "en";

  const orgTemplate = defaultTemplate;

  const anchorGroup = categories.categories.find((cat) => cat.id === anchor);
  let totalResponses = 0;

  const titleColor = "#031A4A";
  const tableBorderColor = "#031A4A";

  Object.keys(calculated.overall).forEach((k) => {
    totalResponses = totalResponses + calculated.overall[k].n1;
  });

  // Comments to display can be configured here.
  const healthAndWellbeingQuestions = [102, 104];
  const leadershipQuestions = [107];
  // const hasLeadershipComments = getFeedbackOTP(
  //   filterData,
  //   structure,
  //   categories,
  //   leadershipQuestions[0]
  // )?.filter((comment) => comment?.text !== "");

  return (
    <Document style={styles.doc}>
      {/* INTRO PAGE */}
      <Intro
        selectedReport={selectedReport}
        coverTitle={props?.coverTitle}
        selectedOrg={selectedOrg}
        overview={overview}
        anchor={anchorGroup}
        totalResponses={totalResponses}
        sportName={props?.sportName}
        selectedSport={selectedSport}
        titleColor={titleColor}
        isOTPView={otpView}
        isFrench={isFrench}
        completionRate={completionRate}
        surveyDate={surveyDate}
      />
      <Methodology
        anchor={anchor}
        structure={structure}
        categories={categories}
        data={reportData}
        nav={nav}
        rule={rule}
        config={orgTemplate}
        selected={selectedSport}
        titleColor={titleColor}
        isFrench={isFrench}
      />
      {/* CULTURE INDEX MAP */}
      <CultureMap
        chart={chart}
        config={orgTemplate}
        titleColor={titleColor}
        isFrench={isFrench}
      />

      {/* PERSON DIMENSION */}
      <PersonDimension
        nav={nav}
        categories={categories}
        rule={rule}
        structure={structure}
        reportData={reportData}
        anchor={anchor}
        config={orgTemplate}
        calculated={calculated}
        titleColor={titleColor}
        comments={reportComments}
        isFrench={isFrench}
      />
      {/* RISK SECTION */}
      <PersonRisks
        data={calculated.responses}
        calculated={calculated}
        question={structure}
        categories={categories}
        anchor={anchor}
        config={orgTemplate}
        nav={nav}
        titleColor={titleColor}
        tableBorderColor={tableBorderColor}
        comments={reportComments}
        isFrench={isFrench}
      />
      {/* COMMENTS SECTION */}
      {!otpView &&
        (reportComments?.[8]?.length > 0 ||
          (aiData?.length > 0 && aiData?.[0])) && (
          <PersonOverallComment
            comments={reportComments?.[8]}
            aiData={aiData?.[0]}
            aiTitle={getTranslatedText(
              "mentalHealthAndWellBeing",
              lang,
              translations
            )}
            titleColor={titleColor}
            isFrench={isFrench}
          />
        )}
      {/*   {!accessSubfactor && (
        <Comments
          nav={nav}
          filterData={filterData}
          config={orgTemplate}
          anchor={anchor}
          calculated={calculated}
          structure={structure}
          categories={categories}
          list={healthAndWellbeingQuestions}
          titleColor={titleColor}
          tableBorderColor={tableBorderColor}
        />
      )}*/}
      {/* COMPARITIVE SECTION */}
      <PerformanceDimension
        nav={nav}
        categories={categories}
        rule={rule}
        structure={structure}
        reportData={reportData}
        anchor={anchor}
        config={orgTemplate}
        calculated={calculated}
        titleColor={titleColor}
        comments={reportComments}
        isFrench={isFrench}
      />

      {/* RISK SECTION */}
      <PerformanceRisks
        data={calculated.responses}
        calculated={calculated}
        question={structure}
        categories={categories}
        anchor={anchor}
        config={orgTemplate}
        nav={nav}
        titleColor={titleColor}
        tableBorderColor={tableBorderColor}
        comments={reportComments}
        isFrench={isFrench}
      />
      {/* {!otpView &&
        leadershipQuestions?.length > 0 &&
        hasLeadershipComments?.length > 0 && (
          <Comments
            nav={nav}
            filterData={filterData}
            config={orgTemplate}
            anchor={anchor}
            calculated={calculated}
            structure={structure}
            categories={categories}
            list={leadershipQuestions}
            titleColor={titleColor}
            tableBorderColor={tableBorderColor}
          />
        )} */}

      {!otpView &&
        (reportComments?.[20]?.length > 0 ||
          (aiData?.length > 0 && aiData?.[1])) && (
          <PerformanceOverallComment
            comments={reportComments?.[20]}
            aiData={aiData?.[1]}
            aiTitle={getTranslatedText(
              "leadershipAndVision",
              lang,
              translations
            )}
            titleColor={titleColor}
            isFrench={isFrench}
          />
        )}

      {otpView && (
        <CultureMap
          chart={chartTwo}
          config={orgTemplate}
          titleColor={titleColor}
          isFrench={isFrench}
        />
      )}
      <OverallRanking
        data={calculated}
        calculated={calculated}
        question={structure}
        categories={categories}
        anchor={anchor}
        config={orgTemplate}
        nav={nav}
        tableBorderColor={tableBorderColor}
        titleColor={titleColor}
        otpView={otpView}
        comments={reportComments}
        isFrench={isFrench}
      />

      {/* <ContentBlock title={"Getting Culture Right Starts Here"} /> */}
      {!otpView && (
        <AreasOfImprovement
          nav={nav}
          comments={comments}
          categories={categories}
          rule={rule}
          structure={structure}
          reportData={reportData}
          anchor={anchor}
          config={orgTemplate}
          calculated={calculated}
          titleColor={titleColor}
          tableBorderColor={tableBorderColor}
          isFrench={isFrench}
        />
      )}
      {otpView && (
        <OTPDimensions
          nav={nav}
          categories={categories}
          rule={rule}
          structure={structure}
          reportData={reportData}
          anchor={anchor}
          config={orgTemplate}
          calculated={calculated}
          titleColor={titleColor}
          isFrench={isFrench}
        />
      )}
      {/* FEEDBACK SECTION */}
      {!otpView && reportComments?.[23]?.length > 0 && (
        <Recommendations comments={reportComments?.[23]} isFrench={isFrench} />
      )}
    </Document>
  );
};

export default Template;
