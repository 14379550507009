import React from "react";
import styled from "styled-components";

const SectionTitle = (props) => {
  const { title, subtitle } = props;

  return (
    <TitleWrapper>
      <Title>{title}</Title>
      {subtitle && subtitle?.length > 0 && <Subtitle>{subtitle}</Subtitle>}
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-weight: 600;
  font-size: 18px;
  line-height: 128%;
  color: #2d70e2;
`;

const Subtitle = styled.div`
  font-family: "Raleway";
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #7e7e7e;
  margin-top: 5px;
`;

export default SectionTitle;
