// Sidebar.js (Updated)
import { string } from "mathjs";
import React from "react";
import styled from "styled-components";

const SidebarContainer = styled.div`
  width: 300px;
  min-width: 300px;
  background-color: #ffffff; /* Changed to white */
  color: #2a3039;
  padding: 20px;
  border-right: 1px solid #d5e2f9;
  overflow-y: auto;
  font-family: "Raleway", sans-serif;
`;

const SidebarHeader = styled.div`
  color: #2a3039;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
`;

const NavItem = styled.div`
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => (props.active ? "#2D70E2" : "#666D79")};
  font-weight: ${(props) => (props.active ? "600" : "400")};
  background-color: ${(props) => (props.active ? "#F8FAFF" : "white")};
  padding: 5px;
    font-family: "Raleway", sans-serif;

  &:hover {
    color: #2d70e2;
  }
`;

const SubNavItem = styled(NavItem)`
  padding-left: 20px; /* Indent sub-items */
    font-family: "Raleway", sans-serif;
`;

const ChangePercentage = styled.span`
  font-size: 12px;
  color: ${(props) =>
    props.isPositive && !props.isNeutral ? "#2D70E2" : "#FF0000"};
  margin-left: 5px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #d5e2f9;
  margin: 15px 0;
`;

const getScoreChange = (factor1, factor2) => {
  let factor1Change = factor1.latest_score - factor1.last_score;
  let factor2Change = factor2.latest_score - factor2.last_score;

  if (
    typeof factor1.last_score == "string" ||
    typeof factor2.last_score == "string"
  ) {
    return 0;
  }

  return factor2Change - factor1Change;
};

function Sidebar({
  onSelectFactor,
  onViewChange,
  cultureFactors,
  outcomeFactors,
  selected
}) {
  return (
    <SidebarContainer>
      <SidebarHeader>Change Report</SidebarHeader>
      <NavItem onClick={() => onViewChange("summary")} active={selected == null }>Summary</NavItem>
      <Divider />
      <SidebarHeader>Culture Factors</SidebarHeader>
      {cultureFactors
        .sort((a, b) => getScoreChange(a, b))
        .map((factor) => {
          let changePercentage = (
            (factor.latest_score - factor.last_score) *
            10
          ).toFixed(1);
          let changeColor =
            changePercentage >= 0
              ? "#2D70E2"
              : !isNaN(changePercentage)
              ? "#FF0000"
              : "#2A3039";
          return (
            <SubNavItem
              key={factor.factor_id}
              active={selected?.factor_id === factor.factor_id}
              onClick={() => onSelectFactor({ ...factor, type: "culture" })}
            >
              {factor.factor_name}
              <ChangePercentage
                style={{
                  color: changeColor,
                }}
              >
                ({changePercentage >= 0 ? "+" : ""}
                {changePercentage}
                {!isNaN(changePercentage) && "%"})
              </ChangePercentage>
            </SubNavItem>
          );
        })}
      <Divider />
      {outcomeFactors.length > 0 && <SidebarHeader>Outcome Factors</SidebarHeader>}
      {outcomeFactors
        .sort((a, b) => getScoreChange(a, b))
        .map((factor) => {
          let changePercentage =
            typeof factor.last_score != "string"
              ? ((factor.latest_score - factor.last_score) * 10).toFixed(1)
              : "--";
          let changeColor =
            changePercentage >= 0
              ? "#2D70E2"
              : !isNaN(changePercentage)
              ? "#FF0000"
              : "#2A3039";
          return (
            <SubNavItem
              key={factor.outcome_id}
              onClick={() => onSelectFactor({ ...factor, type: "outcome" })}
            >
              {factor.outcome_name}
              <ChangePercentage
                style={{
                  color: changeColor,
                }}
              >
                ({changePercentage >= 0 ? "+" : ""}
                {changePercentage}
                {!isNaN(changePercentage) && "%"})
              </ChangePercentage>
            </SubNavItem>
          );
        })}
    </SidebarContainer>
  );
}

export default Sidebar;
