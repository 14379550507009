import React from 'react';
import styled from 'styled-components';

const ActionIntro = ({ onContinue,selectedFactor,method }) => {

  
  return (<Container>
    <BlueTitle>AI toolkit</BlueTitle>
    <Title>Take action on {selectedFactor.name}</Title>

    <Description>
     Now that you've chosen a method of diagnosing your organization's culture, it's time to take action.
     In the next step we're going to select context for your action plan.
    </Description>
    
    <Button onClick={onContinue}>Continue to select action context</Button>
  </Container>)
}

export default ActionIntro;

// Reuse styles from SplashScreen
const Container = styled.div`
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
`;

const BlueTitle = styled.div`
  color: #2D70E2;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: -20px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Raleway';
`;

const Description = styled.div`
  margin-top: 20px;
  font-family: 'Raleway';
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

const Button = styled.button`
  background-color: #2D70E2;
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 20px;
  font-family: 'Raleway';

  &:hover {
    background-color: #1a4ea1;
  }
`;
