import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import Check from "assets/images/check-white.svg";
import BlankAvatar from "assets/images/empty/user.svg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Popup } from "semantic-ui-react";

import { useNavigate } from "react-router-dom";

import { SG_GET_MOOD_PER } from "constants/actions";

const EmployeeAvatar = ({
  employee_id,
  showName,
  showChecked,
  nonMemberHide,
  panel,
  nameHover,
  nameRight,
  overRide,
  onlyLeader = false,
  picSize = "regular",
  showEmotionalReport,
  hideFace = false,
  showFace = false, // use this to override all options pic, name and click
  ...props
}) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [user, setUser] = useState("");
  const [employee, setEmployee] = useState("");

  const { get_auth, get_employees } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );

  // console.log(props)

  React.useEffect(() => {
    setUser(get_employees.filter((em) => em.id == Number(get_auth?.employee_id))[0])
    if (get_employees.filter((em) => em.id === employee_id)) {
      setEmployee(get_employees.filter((em) => em.id === employee_id)[0]);
    }
  }, [get_employees, get_auth, employee_id, overRide]);

  if (showFace) {
    showEmotionalReport = showFace;
    showName = showFace;
  }

  let employeePic = BlankAvatar;
  function EmployeePicture() {
    if (user?.role > 9 ) {
      showEmotionalReport = false
      if (overRide) {
        employeePic = employee?.picture ? employee?.picture : BlankAvatar;
        return;
      }
      return
    }

    if (hideFace) {
      employeePic = BlankAvatar;
      return
    }

    if (user?.id !== employee?.id) {
      if (user?.role === 6) {
        showEmotionalReport = false
        // employeePic = BlankAvatar;
        if (overRide) {
          employeePic = employee?.picture ? employee?.picture : BlankAvatar;
          return;
        }
        
      return
      }
    }

    
    if (onlyLeader) {
      if (user?.id === employee?.id) {
        employeePic = employee?.picture ? employee?.picture : BlankAvatar;
        return;
      }
    }
    if (showFace) {
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }

    // self can view
    if (user?.id === employee?.id) {
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }

    // only leader can view
    if (user?.role < 9) {
      // console.log("show picuser id", user?.id, user?.account_type, user?.role)
      employeePic = employee?.picture ? employee?.picture : BlankAvatar;
      return;
    }
  }

  EmployeePicture();

  let employeeName = "Team Member";
  function EmployeeNameSequence() {
    if (employee?.first_name) {
      return `${employee?.first_name} ${employee?.last_name}`;
    } else if (employee?.email) {
      return `${employee?.email.split("@")[0]}`;
    } else if (employee?.id) {
      return `${employee?.id}`;
    }

    return "Team Member";
  }
  function EmployeeName() {
    if (user?.role > 9) {
      showEmotionalReport = false
      if (overRide) {
        employeeName = EmployeeNameSequence();
        return;
      }

      return
    }
    if (onlyLeader) {
      if (user?.id === employee?.id) {
        employeeName = EmployeeNameSequence();
        return;
      }
    }
    if (showFace) {
      employeeName = EmployeeNameSequence();
      return;
    }

    // self can view
    if (user?.id === employee?.id) {
      employeeName = EmployeeNameSequence();
      return;
    }

    // only leader can view
    if (user?.role < 9) {
      // console.log("show picuser id", user?.id, user?.account_type, user?.role)
      employeeName = EmployeeNameSequence();
      return;
    }
  }

  EmployeeName();




  // hide pictures from non members if not self enforcement
  // console.log("Need to fix this", get_employees)
  // console.log("Need to fix this 2 user id", user?.id, 'employee id', employee?.id)
  // console.log(employee, employee_id)

  // else {
  //   console.log('show pic', employee?.id, employee?.picture)
  //     employeePic = employee?.picture? employee?.picture : BlankAvatar

  // }
  // employeePic =
  // console.log(employeeName);
  // console.log(employee);
  // console.log(showEmotionalReport);

  const personal = () => {
    if (!showEmotionalReport) {
      return;
    }
    // console.log("I am here");
    if (showEmotionalReport) {
      // block all non anonymous users
      if (user?.role < 10) {
        SelectedEmployee(employee?.id);
      }
    }
    if (user?.role < 9) {
      SelectedEmployee(employee?.id);
    }
    // console.log("I am here", user?.role);
  };

  function SelectedEmployee(id) {
    // console.log(id)
    dispatch({
      type: SG_GET_MOOD_PER,
      payload: id,
    });

    navigate(`/app/personal?show-employee=${id}`);
  }

  if (props.loading) {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "column",
          marginRight: 30,
        }}
      >
        <GreyCircle />
      </div>
    );
  }

  if (panel) {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "row",
          marginBottom: 20,
        }}
      >
        {picSize === "regular" && (
          <Avatar
            src={employeePic}
            onClick={() => personal()}
            clickable={showEmotionalReport}
          />
        )}
        {picSize === "small" && (
          <AvatarSmall
            src={employeePic}
            onClick={() => personal()}
            clickable={showEmotionalReport}
          />
        )}
        {showChecked &&
          (props.checked ? (
            <CheckedPanel>
              <img src={Check} alt={"none"} />
            </CheckedPanel>
          ) : (
            <UnCheckedPanel>?</UnCheckedPanel>
          ))}

        <>
          <Name style={{ marginLeft: 10 }}>{employeeName}</Name>
        </>
      </div>
    );
  }

  if (nameHover && user?.role < 6) {
    return (
      <Popup
        header={employeeName}
        trigger={
          <div
            style={{
              display: "flex",
              position: "relative",
              alignItems: "center",
              flexDirection: "column",
              marginRight: 30,
            }}
          >
            {picSize === "regular" && (
              <Avatar
                src={employeePic}
                onClick={() => personal()}
                clickable={showEmotionalReport}
              />
            )}
            {picSize === "small" && (
              <AvatarSmall
                src={employeePic}
                onClick={() => personal()}
                clickable={showEmotionalReport}
              />
            )}
            {showChecked &&
              (props.checked ? (
                <Checked>
                  <img src={Check} alt={"none"} />
                </Checked>
              ) : (
                <UnChecked>?</UnChecked>
              ))}
          </div>
        }
      />
    );
  }

  if (nameRight) {
    return (
      <div
        style={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "row",
          marginRight: 30,
        }}
      >
        {picSize === "regular" && (
          <Avatar
            src={employeePic}
            onClick={() => personal()}
            clickable={showEmotionalReport}
          />
        )}
        {picSize === "small" && (
          <AvatarSmall
            src={employeePic}
            onClick={() => personal()}
            clickable={showEmotionalReport}
          />
        )}
        {showChecked &&
          (props.checked ? (
            <Checked>
              <img src={Check} alt={"none"} />
            </Checked>
          ) : (
            <UnChecked>?</UnChecked>
          ))}
        {showName && (
          <>
            {employee?.first_name ? (
              <>
                <Name style={{ marginLeft: 10 }}>
                  {employee?.first_name} &nbsp;
                </Name>
                <Name>{employee?.last_name}</Name>
              </>
            ) : employee?.email ? (
              <Name style={{ marginLeft: 10 }}>
                {employee?.email.split("@")[0]}
              </Name>
            ) : (
              <Name>{employee_id}</Name>
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        flexDirection: "column",
        marginRight: 30,
      }}
    >
      {picSize === "regular" && (
        <Avatar
          src={employeePic}
          onClick={() => personal()}
          clickable={showEmotionalReport}
        />
      )}
      {picSize === "small" && (
        <AvatarSmall
          src={employeePic}
          onClick={() => personal()}
          clickable={showEmotionalReport}
        />
      )}
      {showChecked &&
        (props.checked ? (
          <Checked>
            <img src={Check} alt={"none"} />
          </Checked>
        ) : (
          <UnChecked>?</UnChecked>
        ))}
      {showName && (
        <>
          {employee?.first_name ? (
            <>
              <Name>{employee?.first_name}</Name>
              <Name>{employee?.last_name}</Name>
            </>
          ) : employee?.email ? (
            <Name>{employee?.email.split("@")[0]}</Name>
          ) : (
            <Name>{employee_id}</Name>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeAvatar;

const loading = keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`;

const Avatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  margin-bottom: 10px;
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
`;

const Hover = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 11px;
  background-color: white;
`;

const AvatarSmall = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 22px;
  margin-bottom: 10px;
  cursor: ${(props) => (props.clickable ? "pointer" : "auto")};
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
`;

export const Checked = styled.div`
  width: 22px;
  height: 22px;
  border: solid 2px #ffffff;
  background-color: #1e2172;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  position: absolute;
  right: -5px;
  top: 40px;
`;

export const UnChecked = styled.div`
  width: 22px;
  height: 22px;
  border: solid 2px #1e2172;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  font-size: 16px;
  font-weight: bold;
  color: #1e2172;
  font-family: "Red Hat Display", sans-serif;
  position: absolute;
  right: -5px;
  top: 40px;
`;

const GreyCircle = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 30px;
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
`;
const CheckedPanel = styled.div`
  width: 22px;
  height: 22px;
  border: solid 2px #ffffff;
  background-color: #1e2172;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  position: absolute;
  left: 40px;
  top: 40px;
`;

const UnCheckedPanel = styled.div`
  width: 22px;
  height: 22px;
  border: solid 2px #1e2172;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  font-size: 16px;
  font-weight: bold;
  color: #1e2172;
  font-family: "Red Hat Display", sans-serif;
  position: absolute;
  left: 40px;
  top: 40px;
`;

const NamePanel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-right: 5px;
`;

const View = styled.div`
  font-size: 10px;
  // font-weight: bold;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
  cursor: pointer;
  // margin-top: 12px;
`;
