import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Modal, Button } from "semantic-ui-react";

const DeleteModal = ({ open, setOpen, handleDelete }) => {
  const [text, setText] = useState("");

  return (
    <Modal
      onClose={() => {
        setOpen(false);
        setText("");
      }}
      open={open}
      size="medium"
      closeIcon
    >
      <Content>
        <Title>Are you sure you want to delete this AI Pulse?</Title>

        <Description>
          Please type the word <strong>DELETE</strong> in the box below to
          confirm the deletion of this report. This will permanantly delete this
          report and all its contents.
        </Description>

        <Input value={text} onChange={(e) => setText(e.target.value)} />

        <Bottom>
          <Button
            onClick={() => {
              handleDelete();
              setText("");
            }}
            style={{ backgroundColor: "#F15062", color: "white" }}
            disabled={text.toLocaleLowerCase() != "delete"}
          >
            Delete
          </Button>
        </Bottom>
      </Content>
    </Modal>
  );
};

export default DeleteModal;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #2a3039;
  margin-bottom: 30px;
`;

const Content = styled.div`
  padding: 40px 40px 20px 40px;
`;

const Description = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666d79;
  line-height: 1.6;
  margin-bottom: 5px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
`;

const Input = styled.input`
  margin-top: 20px;
  height: 40px;
  padding: 10px;
  border: 3px solid #2d70e2;
  color: #666d79;
  font-family: "Raleway";
  border-radius: 5px;
`;
