//Semantic modal for the OTP report, which brings up the list of sports to choose from
import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Modal,Dropdown } from 'semantic-ui-react'
import SurveyVersions from "../../../reports/Audit/VersionControl/index";
import { get } from "react-hook-form";

const CreateReport = ({ open,
                        setOpen, 
                        create_button,
                        sportSelect,
                        customTitle,
                        setCustomTitle,
                        get_culture_audit_reports,
                        isFrench,
                        setIsFrench,
                        ToggleComponent}) => {

    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="medium"
            closeIcon
            trigger={<Button>Create report</Button>}
          >
              <Content>
                <Title>Create a Report</Title>

                <Description>
                    Customize your AI generated recommendation by selecting the area
                    of focus that will help to build a plan customized to your
                    specific needs.
                </Description>

                    <CustomTitleContainer>
                    <T2>Title</T2>
                    <Description>Give this report a title so that you can differentiate the report from others.</Description>
                    <CustomTitleInput
                        value={customTitle}
                        onChange={(e) => setCustomTitle(e.target.value)}
                    />
                    </CustomTitleContainer>


                    {sportSelect}

                    <T2>Language</T2>
                    <Description>Please select the language you would like to use in this report.</Description>
                    <ToggleContainer>
                    <ToggleComponent
                        toggle
                        checked={isFrench}
                        handleCheck={(s) => setIsFrench(s)}
                    />           
                    <ToggleText disabled={false}>{isFrench? 'French' : 'English'}</ToggleText>
                    </ToggleContainer>
                    
                    <DropdownWrapper>
                    <T2>Survey Version</T2>
                    <Description>Select a version of the CAAT survey to use for your report. </Description>
                    {get_culture_audit_reports && <SurveyVersions />}
                    </DropdownWrapper>



                    

                <Bottom>
                    {create_button}
                </Bottom>
            </Content>

          </Modal>
    )

}


export default CreateReport;


const Button = styled.button`
background: #2D70E2;
border-radius: 8px;
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 14px;
color: #FFFFFF;
border:none;
display:flex;
align-items:center;
justify-content:center;
padding: 12px 24px;
cursor:pointer;
`


const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 24px;
color: #2A3039;
margin-bottom:10px;
`

const Content = styled.div`
    padding:40px;
`

const Description = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #666D79;
line-height:1.6;
margin-bottom:5px;
`

const Bottom = styled.div`
    display:flex;
    justify-content:flex-end; 
    width:100%;
    margin-top:40px;
`
const T2 = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 5px;
  fonmt-family:"Raleway";
  margin-top:30px;
`;

const ToggleText = styled.div`
 font-size: 14px;
 font-family: "Raleway";
 font-weight: 400;
 color: #2A3039;
 display: flex;
 align-items: center;
 padding-left: 10px;
`;

const ToggleContainer = styled.div`
 display: flex;
 flex-direction: row;
 height: 35px;
 vertical-align: middle;
 padding-top: 0px;
`;


const CustomTitleContainer = styled.div`
 padding-bottom: 15px;
`;

const CustomTitleInput = styled.input`
 width: 400px;
 height: 35px;
 border: 1px solid #ccc;
 padding: 0px 10px;
 font-size: 14px;
 border-radius:5px;
 font-weight: 500;
 color: #4a4a4a;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;