import React from "react";
import { View, Text, StyleSheet, Page, Image } from "@react-pdf/renderer";
import Footer from "./Footer";
import Side from "./Side";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    paddingHorizontal: 10,
  },
  textPadding: {
    paddingHorizontal: 70,
  },
  p: {
    fontSize: 10,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  chartWrapper: {
    paddingTop: 20,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  summary: {
    border: "2px solid #000921",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#000921",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
  },
  bottom: {
    padding: 10,
    marginTop: 20,
  },
});

const CultureMap = (props) => {
  const { chart, config, titleColor, isFrench } = props;
  const lang = isFrench ? "fr" : "en";

  return (
    <Page style={styles.page} wrap={false}>
      <Side {...config} />
      <Footer {...config} isFrench={isFrench} />
      <View style={styles.textPadding}>
        <Text style={[styles.T2, { color: titleColor }]}>
          {getTranslatedText("cultureMap", lang, translations)}
        </Text>
        <View style={styles.line} />

        <Text style={styles.p}>
          {getTranslatedText("cultureMapDesc", lang, translations)}
        </Text>
      </View>

      <View style={styles.chartWrapper}>
        <Image source={chart} fixed style={{ width: "100%", height: "100%" }} />
      </View>
    </Page>
  );
};

export default CultureMap;
