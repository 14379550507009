import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Popup, Icon } from "semantic-ui-react";
import { caretDown, caretUp } from "../../../../reports/components/Icons";

const Status = ({ status }) => {
  const isComplete = status === "Onboarding complete";

  return (
    <StatusContainer color={isComplete ? "#F1FAF9" : "#F3F7FF"}>
      <StatusText color={isComplete ? "#00B67A" : "#2D70E2"}>
        {status}
      </StatusText>
    </StatusContainer>
  );
};

const AccountSetupActivity = ({ accountHistory, canExpand, expandCb }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const toggleRow = () => {
    setIsExpanded(!isExpanded);
    expandCb(!isExpanded);
  };

  useEffect(() => {
    if (!canExpand) {
      setIsExpanded(false);
    }
  }, [canExpand]);

  return (
    <React.Fragment>
      <RowContainer
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        rowIsHovered={isHovered}
      >
        <TD width={25}>{accountHistory?.type}</TD>
        <TD width={25}>
          <Status status={accountHistory?.status} />
        </TD>
        <TD width={30}>
          {accountHistory?.lastUpdated &&
            format(new Date(accountHistory?.lastUpdated), "MMM d, yyyy h:mm a")}
        </TD>
        <TD width={20}>
          {accountHistory?.history && accountHistory?.history?.length > 0 && (
            <Popup
              trigger={
                <ActionWrapper
                  onClick={() => {
                    toggleRow();
                  }}
                >
                  {isExpanded ? caretUp("#2d70e2") : caretDown("#2d70e2")}
                </ActionWrapper>
              }
              content="Expand"
              position="top center"
            />
          )}
        </TD>
      </RowContainer>
      {isExpanded &&
        accountHistory?.history?.map((activity, index) => {
          return (
            <ExpandedRow key={index}>
              <ExpandedContent>
                <ActivityItem>
                  <Icon
                    name={activity.icon}
                    style={{ fontSize: 18, color: "#B3B3B3" }}
                  />
                  <Text width={25}>{activity?.text}</Text>
                  {index + 1 != accountHistory?.history?.length ? <Line /> : ""}
                </ActivityItem>
              </ExpandedContent>
            </ExpandedRow>
          );
        })}
    </React.Fragment>
  );
};

const Line = styled.div`
  height: 40px;
  width: 1px;
  background-color: #ccc;
  position: absolute;
  left: 10px;
  bottom: -46px;
`;

const ActivityItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  font-size: 12px;
  font-family: "Raleway";
  position: relative;
`;

const RowContainer = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const ExpandedRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: -10px;
`;

const ExpandedContent = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  font-size: 12px;
  color: #2a3039;
`;

const Text = styled.div`
  margin-left: 20px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;

export default AccountSetupActivity;
