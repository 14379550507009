import React, { useState, useEffect } from 'react'

import Chart from "react-apexcharts";
import ErrorWrapper from "error/component_error"

function Bomb() {
  throw new Error('💥 CABOOM 💥')
}


const options = {
    chart: {
        height: 350,
        type: 'area',
        toolbar: {
            show: false
        }
    },
    grid: {
        padding: {
            right: 50
        }
    },
    dataLabels: {
        enabled: false
    },
    annotations: {
        points:
            [
                {
                    x: '2021-07-16T16:28:00Z',
                    y: 52,
                    marker: {
                        size: 8,
                    },
                    label: {
                        text: '28%',
                        borderWidth: 0,
                        style: {
                            fontSize: 24,
                            fontWeight: 'bold'
                        }
                    }
                }
            ]
    },
    stroke: {
        curve: 'smooth',
        colors: ['rgba(106, 0, 255,0.3)']
    },
    fill: {
        colors: ['#6a00ff']
    },
    xaxis: {
        type: 'date',
        categories: ["2018-07-19", "2018-08-19", "2018-09-19"]
    },
    tooltip: {
        x: {
            format: 'dd/MM/yy'
        },
    },
};


export default function ChartLineData({ moodChartData, ...props }) {

    // console.log(moodChartData)

    const [chartData, setChartData] = useState([{
        name: 'Emotional Health Score',
        data: [31, 40, 28]
    }]);

    const [chartOptions, setChartOptions] = useState();


    useEffect(() => {
        const DataLen = moodChartData?.dateList?.length
        // console.log(DataLen)
        setChartData(
            [{
                name: 'Emotional Health Score',
                data: moodChartData?.moodList
            }]
        )
        if (DataLen > 0) {
            setChartOptions({
                ...options, xaxis: {
                    type: 'date',
                    categories: moodChartData?.dateList
                },
                annotations: {
                    points:
                        [
                            {
                                x: moodChartData?.dateList[DataLen - 1],
                                y: moodChartData?.moodList[DataLen - 1],
                                marker: {
                                    size: 8,
                                },
                                label: {
                                    text: `${moodChartData?.moodList[DataLen - 1]}%`,
                                    borderWidth: 0,
                                    style: {
                                        fontSize: 24,
                                        fontWeight: 'bold'
                                    }
                                }
                            }
                        ]
                }
            })
        }

    }, [moodChartData]);

    // console.log(chartOptions)

    return (
        <>
            <ErrorWrapper>
            <Chart
                options={chartOptions ? chartOptions : options}
                series={chartData}
                type="area"
                height={350}
            />
            </ErrorWrapper>
        </>
    )
}
