import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { TrendDown, TrendUp, CloseIcon, FilterIcon } from "../Icons";

import {
    primaryColor,
    neutralColor,
    fontSizes,
    inactiveColor,
    primaryBg,
    secondaryColor,
    poorColor,
    hoverBg,
    titleColor,
  } from "../styleConfig";

const Card = ({name,category,score,n}) =>{


    const getScoreCol = (score) => {
        let scoreText = score < 0 ? "below norm" : "above norm";
        return (
          <ScoreText style={{ color: score < 0 ? poorColor : secondaryColor }}>
             {scoreIcon} {score}% {scoreText}
          </ScoreText>
        );
      };

      let scoreIcon =
        score < 0 ? TrendDown(poorColor) : TrendUp(secondaryColor);
    

    return (
        <Container>
            <Row>
            <Title>{name}</Title>
            {getScoreCol(score)}
            </Row>
            

            <Text>
                The {name} {category} scored <strong>{score}% below</strong> the organization average. This group had a total of <strong>{n} participants</strong>.
            </Text>

            <Actions>
               
                <Action border={true}>Culture copilot</Action>
                <Action border={true}>Generate recommendation</Action>
                <Action border={false}>Explore data</Action>
            </Actions>
        </Container>
    )
}


export default Card 


const Container = styled.div`
    padding:20px;
    font-family: Raleway;
    width:800px;
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const Text = styled.div`

`

const Actions = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-end;
    margin-top:30px;
`

const Action = styled.div`
    margin-right:10px;
    color:#2D70E2;
    font-size:12px;
    cursor:pointer;
    border-right:${props=>props.border?'1px solid #2D70E2':""};
    border-color:#2D70E2;
    padding-right:10px;
`

const ScoreText = styled.div`

`;

const Row = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items:space-between;
    margin-bottom:20px;
`