import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { Message, Modal, Input, Button, List, Icon } from 'semantic-ui-react'

import { useDispatch } from "react-redux";

import {
    SG_ADD_SURVEY_STRUCTURE,
} from "constants/actions";



const NewCategory = ({ organizationId, trigger }) => {
    const dispatch = useDispatch();
    const [pristine, setPristine] = useState(true)
    const [createCategory, setCreateCategory] = useState(false)

    const [formErrors, setFormErrors] = useState([]);
    const [categoryName, setCategoryName] = useState('');

    const onSubmit = () => {
        if (formErrors.length > 0) {
            setPristine(false)
            return
        }
        setPristine(true)
        dispatch({
            type: SG_ADD_SURVEY_STRUCTURE,
            payload: {
                organization: organizationId,
                name: categoryName,
            },
        });
        setCreateCategory(false)
    }

    // console.log(rowId)
    // console.log(surveyStruc)

    useEffect(() => {
        const ErrorList = []

        if (!categoryName) {
            ErrorList.push("Enter a Name")
        }
        if (!organizationId) {
            ErrorList.push("No Organization Found")
        }

        setFormErrors(ErrorList)
    }, [categoryName, pristine, organizationId]);


    return (
        <Modal
            onClose={() => setCreateCategory(false)}
            onOpen={() => setCreateCategory(true)}
            open={createCategory}
            trigger={trigger}
            size={"small"}
        >
            <Modal.Header>
                Create a category file with a Name
            </Modal.Header>
            <div style={{ display: 'flex' }}>
                <Half>
                    {formErrors.length > 0 && !pristine &&
                        <Message negative
                            //   onDismiss={clearErrors}
                            header='We need these details!'
                            list={formErrors}
                        />
                    }
                    {/* {error ? <ErrorMessage>*{error}</ErrorMessage> : ""} */}


                    <FormContainer>
                        <Label>Category Name</Label>
                        <Input
                            fluid
                            placeholder='Category Name'
                            name="name"
                            // defaultValue={surveyStruc?.name}
                            value={categoryName}
                            onChange={(v) => setCategoryName(v.target.value)}
                        />
                    </FormContainer>


                    <Submit
                        floated='right'
                        disable={formErrors.length > 0 && !pristine}
                        onClick={onSubmit}>Create</Submit>
                </Half>
            </div>
        </Modal >
    )
}

export default NewCategory


const Container = styled.div`
	// margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
	// padding:30px;
	// box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:400px;
	display:flex;
	align-items:center;
	flex-direction:column;
`
const Half = styled.div`
	width:100%;
	padding:30px 30px;
`

const RowList = styled.div`
    display: flex;
    align-items: stretch; 
    justify-content: flex-start;
`

const ColorBlock = styled.div`
	width:30px;
	height:30px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-bottom:30px;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
    width: 100%;
	margin-top:20px;
`

const FormContainerTop = styled.div`
    width: 100%;
	margin-top:0px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;

`
