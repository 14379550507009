import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  font-family: 'Raleway';
  border: 2px dashed #f5f5f5;
  margin-top:40px;
`;

const SlideIcon = styled(Icon)`
  color: #4a90e2;
  font-size: 3em !important;
  margin-bottom: 15px;
`;

const SlideTitle = styled.div`
  font-size: 1.5em;
  color: #333;
  margin: 10px 0 5px;
  font-weight: 600;
`;

const SlideSubtitle = styled.div`
  font-size: 1em;
  color: #777;
  margin: 0;
`;

const EmptySlide = () => {
  return (
    <SlideContainer>
      <SlideIcon name="file outline" />
      <SlideTitle>Add slides to get started</SlideTitle>
      <SlideSubtitle>Click the Add slide button to choose a slide template to build.</SlideSubtitle>
    </SlideContainer>
  );
};

export default EmptySlide;
