import React from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import BlankAvatar from "assets/images/empty/user.svg";
import Check from 'assets/images/check-white.svg'

const EmployeePicture = (props) => {
  const { employee_id, type, data, checked } = props;
  const [employee, setEmployee] = React.useState("");
  const [user, setUser] = React.useState("");
  const [employeeFullName, setEmployeeFullName] = React.useState("Member");

  const get_auth = useSelector((state) => state.auth, shallowEqual);

  const { get_employees } = useSelector(
    (state) => ({
      get_employees: state.employees.employees,
    }),
    shallowEqual
  );

  //   console.log(get_employees)
  React.useEffect(() => {
    if (get_employees) {
      if (get_employees.filter((em) => em.id == employee_id)) {
        setEmployee(get_employees.filter((em) => em.id == employee_id)[0]);
        setUser(get_employees.filter((em) => em.id === Number(get_auth?.employee_id))[0])
        if (get_employees.filter((em) => em.id === Number(get_auth?.employee_id))[0]?.role < 10) {
          setEmployeeFullName(`
          ${get_employees.filter((em) => em.id === employee_id)[0]?.first_name} ${get_employees.filter((em) => em.id === employee_id)[0]?.last_name}
          `)
        }
      }
    }
  }, [get_employees, employee_id, get_auth]);

  let employeePic = BlankAvatar;
  if (user?.role < 9) {
    employeePic = employee?.picture ? employee?.picture : BlankAvatar;
  }

  //   return <Avatar src={employeePic} />;
  return (<div style={{ display: 'flex', position: 'relative', alignItems: 'center', flexDirection: 'row', marginBottom: 20 }}>
    <Avatar src={employeePic} />
    {checked ?
      <Checked><img src={Check} alt="" /></Checked>
      : <UnChecked>?</UnChecked>}
    <Name>{employeeFullName}</Name>
    {/* <Name>{employee?.last_name}</Name> */}
  </div>)
};

export default EmployeePicture;

const Avatar = styled.img`
  height:50px;
  width:50px;
  border-radius:25px;
  margin-right:31px;

`
const Name = styled.div`
	font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  margin-right:5px;
`

const Checked = styled.div`
  width: 22px;
  height: 22px;
  border: solid 2px #ffffff;
  background-color: #1e2172;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:11px;
  position:absolute;
  left:30px;
  top:30px;
`

const UnChecked = styled.div`
	width: 22px;
  height: 22px;
  border: solid 2px #1e2172;
  background-color: #ffffff;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius:11px;
  font-size: 16px;
  font-weight: bold;
  color: #1e2172;
  font-family: 'Red Hat Display', sans-serif;
  position:absolute;
  left:30px;
  top:30px;
`