import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Segment } from "semantic-ui-react";

import Select from "react-select";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { SG_ADD_TEAM_ACCESS } from "constants/actions";

const Invite = ({ teamAccess, teamId, setActive }) => {

    const apiDispatch = useDispatch();
    const [employeeList, setEmployeeList] = useState([]);
    const [selectedEmp, setSelectedEmp] = useState(false)
    const [organizationalId, setOrganizationalId] = React.useState();

    const org_id = useSelector((state) => state.auth.organization_id);

    // const EmployeeId = useSelector((state) => state.auth?.employee_id, shallowEqual)
    const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)
    const selectedOrg = useSelector((state) => state.selectedOrg, shallowEqual)

    React.useEffect(() => {
        setOrganizationalId(selectedOrg?.organization?.id ?
            selectedOrg?.organization?.id :
            org_id)
    }, [AmbassadorId, selectedOrg, org_id]);

    const { get_employees, get_error, get_ambassador } = useSelector(
        (state) => ({
            get_employees: state.employees,
            get_error: state.errors,
            get_ambassador: state.ambassadors
        }),
        shallowEqual
    );
    
  

    React.useEffect(() => {
        const EmployeeIDs =  teamAccess.map(ta => ta.employee)
        setEmployeeList(get_employees?.employees.filter(
            em => em.team !== teamId
            && !EmployeeIDs.includes(em.id)
            && em.account_type > 7
            && em.role === 5

        ))
    }, [get_employees, teamId, teamAccess]);
    

    const onSubmit = () => {
        // console.log(data);
        apiDispatch({
            type: SG_ADD_TEAM_ACCESS,
            payload: {
                team: teamId,
                organization: organizationalId,
                employee: selectedEmp?.id
            },
        });
        setSelectedEmp(false);
        setActive(true)
    };
    // console.log(get_employees)
    function ChangeTeam(e) {
        // console.log(e)
        setSelectedEmp(e)
    }
    
    const getName = (option) =>{
        if(option.first_name==null){
            return ""
        }
        return option.first_name +" "+option.last_name
    }


    return (
        <Segment>

            <div className="one field">
               
                    <label>Select Spectators</label>
                    <Select
                        placeholder="Select Employee"
                        onChange={ChangeTeam}
                        value={selectedEmp}
                        isClearable
                        options={employeeList}
                        // isLoading={loadingTeam}
                        getOptionLabel={(option) => `${getName(option)} ${option.email} (${option.team_name})`}
                        getOptionValue={(option) => option.id}
                    />
                    <button
                        disabled={!selectedEmp?.id}
                        className="ui primary submit button"
                        onClick={onSubmit}>Submit
                    </button>
               
            </div>
            {/* <div className="ui horizontal divider">Add New by inviting</div>
                <div className="one field">
                    <div className={`field ${errors.email ? 'error' : ''}`}>
                        <label>Access Level</label>
                        
                        {errors.email && <Message negative>
                            <p>Email is required</p>
                            <p>{errors.email?.message}</p>
                        </Message>}
                    </div>
                </div>
                <div className="one field">
                    <label>Access Level</label>
                    <Input iconPosition='left'
                        onChange={CheckEmailId}
                        type="email"
                        value={emailId}
                        fluid placeholder='Email'>
                        <Icon name='at' />
                        <input type="email" value={emailId} />
                    </Input>
                </div>
              
                <button
                    onClick={() => setEmailId('')}
                    className="ui secondary submit button">Cancel
                </button>

                <button
                    disabled={Number(validEmail?.code) !== 200}
                    className="ui primary submit button" type="submit">Submit
                </button>
            </form> */}
            {/* */}


        </Segment>
    );
};

export default Invite;