import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const loading=keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`
const CompatLoading = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
  width: 50%;
  height:350px;

  @media (max-width:768px){
    width:100%;
    margin-top:10px;
  }
`;

const Compat = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  background-color: #ffffff;
  width: 50%;

  @media (max-width:768px){
    width:100%;
    margin-top:10px;
  }
`;

const CompatBox = styled.div`
  border-top-left-radius: 12px;
  background-color: #6a00ff;
  width: ${(props) => (props.width== 10 ? '100px' : props.width + "%")};
  max-width:calc(100% - 100px);
  min-width:100px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: width 0.5s;
`;
const BalanceBox = styled.div`
  border-top-right-radius: 12px;
  background-color: #1e2172;
  width: ${(props) => (props.width == 10 ? '100px' : props.width + "%")};
  max-width:calc(100% - 100px);
   min-width:100px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: width 0.5s;
`;
const Percent = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

const PercentSub = styled.div`
  font-size: 12px;
  font-weight: 900;
  margin-top: 5px;
  font-family: "Red Hat Display", sans-serif;
`;
const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  padding-left: 16px;
`;

const Text6 = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  color: #545454;
  padding: 24px 64px 10px 16px;

  @media (max-width:768px){
    padding-right:10px;
  }
`;



const TraitText = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-weight: 900;
  color: #696969;
  font-size: 12px;
`;

const TraitButton = styled.div`
  width: 216px;
  height: 39px;
  border-radius: 19.5px;
  background-color: ${(props) => props.color};
  font-size: 16px;
  font-weight: 900;
  color: white;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width:768px){
    width:200px;
  }
`;

const TraitCont = styled.div`
display:flex;
flex-direction:row;
padding:38px 0 38px 0;

@media (max-width:768px){
  flex-direction:column;
}
`

const Trait = styled.div`
  width:50%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;

  @media (max-width:768px){
    width:100%;
    margin-bottom:10px;
  }
`


const orgsubTraits = [
  { id: 1, name: "Assertiveness", key: "assertiveness" },
  { id: 2, name: "Enthusiasm", key: "enthusiasm" },
  { id: 3, name: "Politeness", key: "politeness" },
  { id: 4, name: "Compassion", key: "compassion" },
  { id: 5, name: "Orderliness", key: "orderliness" },
  { id: 6, name: "Industriousness", key: "industriousness" },
  { id: 7, name: "Volatility", key: "volatility" },
  { id: 8, name: "Withdrawl", key: "withdrawl" },
  { id: 9, name: "Abstract Interest", key: "ai" },
  { id: 10, name: "Creativity", key: "creativity" },
];


const ConBox = ({compatible,text,loading}) =>{

	if(loading){
		return <CompatLoading/>
	}

	return (
		 <Compat>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CompatBox width={100 - compatible[2]>90?90:100 - compatible[2]<10?10:100 - compatible[2]}>
              <Percent>
                {compatible ? Math.floor(100 - compatible[2]) : '50'}%
              </Percent>
              <PercentSub>CONSISTENT</PercentSub>
            </CompatBox>
            <BalanceBox width={compatible[2]>90?90:compatible[2]<10?10:compatible[2]}>
              <Percent>
                {compatible
                  ? compatible[2] === 0
                    ? Math.floor(compatible[2])
                    : Math.floor(compatible[2]) + 1
                  : 50}
                %
              </Percent>
              <PercentSub>VARIED</PercentSub>
            </BalanceBox>
          </div>
          <Text6>
            {text}
          </Text6>
        

          <TraitCont>
            <Trait>
              <TraitText>MOST CONSISTENT TRAIT</TraitText>
              <TraitButton color={"#6a00ff"}>
                {compatible[0] ? 
                orgsubTraits.find(({key}) => key === compatible[0]).name
                : "---"}
              </TraitButton>
            </Trait>
            <Trait>
              <TraitText>MOST VARIED TRAIT</TraitText>
              <TraitButton color={"#1e2172"}>
                {compatible[1] ? 
                orgsubTraits.find(({key}) => key === compatible[1]).name
                : "---"}
              </TraitButton>
            </Trait>
          </TraitCont>
        </Compat>
		)
}

export default ConBox