import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import TagModal from "./TagModal";
import Analytics from "./Analytics";
import TagManagement from "./TagManagement";
import Factors from "./Factors";
import Correlations from "./Correlations";
import Predictions from "./Predictions";

const Retention = () =>{

    const [open, setOpen] = useState(false)
    const [selected,setSelected] = useState(0)


    const select_render = () =>{

        switch(selected){
            case 0:
                return <TagManagement open={open} setOpen={(o)=>setOpen(o)}/>
            case 1:
                return <Factors/>
            case 2:
                return <Analytics/>
            case 3:
                return <Correlations/>
            case 4:
                return <Predictions/>
            default:
                return <TagManagement open={open} setOpen={(o)=>setOpen(o)}/>
        }
    }

    return (
        <>
         
        
        <Container>
            <Title>Retention Insights</Title>

            <ContentArea>
                <Left>
                    <MenuItem selected={selected==0} onClick={()=>setSelected(0)}>
                        Tag Management
                    </MenuItem>
                    <MenuItem selected={selected==1} onClick={()=>setSelected(1)}>
                        Factor Analysis
                    </MenuItem>
                    <MenuItem selected={selected==2} onClick={()=>setSelected(2)}>
                        Longitudinal Analysis
                    </MenuItem>
                    <MenuItem selected={selected==3} onClick={()=>setSelected(3)}>
                        Correlations
                    </MenuItem>
                    <MenuItem selected={selected==4} onClick={()=>setSelected(4)}>
                        Predictions
                    </MenuItem>
                </Left>

                <Right>
                    {select_render()}
                </Right>
            </ContentArea>
           
            <TagModal open={open} setOpen={(o)=>setOpen(o)}/>
        </Container>
        </>
    )
}


export default Retention;


const Container = styled.div`
    width: 100%;
    padding-left:48px;
    margin-top:40px;
    max-width:1100px;
`

const Title = styled.div`
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    margin-bottom:50px;
    color:#2A3039;
    font-variant-numeric: lining-nums proportional-nums;
font-feature-settings: 'ss11' on, 'ss09' on, 'ss08' on, 'ss07' on, 'ss06' on, 'ss05' on, 'ss04' on, 'ss02' on, 'ss01' on, 'ss10' on, 'salt' on, 'ss03' on, 'liga' off;

`



const ContentArea = styled.div`
    width:100%;
    display:flex;
    height:100%;
`

const Left = styled.div`
    width:200px;
    padding:20px;
    height:100vh;
    border-right:1px solid #DFDFDF;
`

const Right = styled.div`
    width: calc(100% - 200px);
    padding:20px;
`

const MenuItem = styled.div`
    color:${props=>props.selected? '#2D70E2':'#666D79'};
    height:36px;
    display:flex;
    align-items:center;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: ${props=>props.selected? 600:400};
    cursor:pointer;
    background-color:${props=>props.selected? '#F8FAFF':'#fff'};
`