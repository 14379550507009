import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  textboxContainer: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  textWrapper: {
    backgroundColor: "#f8faff",
    borderRadius: 5,
    padding: 10,
  },
  title: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 500,
    marginBottom: 10,
  },
  text: {
    fontSize: 10,
    fontWeight: 400,
    marginBottom: 10,
  },
});

const Textbox = ({ title, subtitle, data, id, large, value }) => {
  return (
    <View style={styles.textboxContainer} wrap={false} break={true}>
      {title && <Text style={styles.title}>{title}</Text>}
      {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
      <View
        style={[
          styles.textWrapper,
          {
            minHeight: large ? 140 : 80,
            maxHeight: large ? 250 : 100,
          },
        ]}
      >
        <Text style={styles.text}>{value || data?.planData?.[id]}</Text>
      </View>
    </View>
  );
};

export default Textbox;
