import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ClickAwayListener from "react-click-away-listener";

import EditMember from "settings/member_directory/Members/EditMember";

const ManageEmployeeInfo = (props) => {
	
	const {data, userEmployee} = props
	const [visible, setVisible] = useState(false);
	const [openTeamCard, setOpenTeamCard] = useState(false);
	const [fullEmpData, setFullEmpData] = useState(data);
	
	const handleClickAway = () => {
		setVisible(false);
	};

	function OpenModalClick() {
		setOpenTeamCard(true)
		setVisible(true)
	}

	useEffect(() => {
		setFullEmpData(data)
	}, [data]);

	// console.log(props.data)
	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<div style={{ position: "relative" }}>
				<View onClick={OpenModalClick}>Manage</View>

				<List visible={visible}>
					<EditMember
						data={data}
						setOpenTeamCard={setOpenTeamCard}
						openTeamCard={openTeamCard}
						setFullEmpData={setFullEmpData}
						employee={fullEmpData}
						userEmployee={userEmployee}
						setLoading={props.setLoading}
						fullEmpData={fullEmpData}
						trigger={<ListItem last={false}>Edit</ListItem>}
					/>
				</List>
			</div>
		</ClickAwayListener>
	);
};

export default ManageEmployeeInfo;

const View = styled.span`
	font-size: 16px;
	font-weight: bold;
	font-family: "Red Hat Display", sans-serif;
	color: #7500ff;
	cursor: pointer;
	width: 100%;
	justify-content: center;

	@media (max-width: 768px) {
		font-size: 13px;
	}
`;

const List = styled.div`
	box-shadow: ${(props) =>
		props.visible ? "0 2px 20px 0 rgba(194, 194, 194, 0.5)" : ""};
	border-radius: 8px;
	width: 250px;
	padding: 5px;
	position: absolute;
	top: 25px;
	right: -30px;
	height: ${(props) => (props.visible ? "auto" : "0")};
	opacity: ${(props) => (props.visible ? 1 : 0)};
	transition: height 500ms, opacity 500ms, box-shadow 500ms;
	overflow: hidden;
	background-color: white;
	z-index: 3;
`;

const Red = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: #ff2d55;
	font-family: "Red Hat Display", sans-serif;
`;

const ListItem = styled.div`
	color: #4a4a4a;
	font-size: 16px;
	border-bottom: ${(props) => (props.last ? "solid 1px #e9e9e9" : "")};
	padding: 8px 8px 5px 15px;
	cursor: pointer;
	height: 25px;
	box-sizing: content-box;
	font-family: "Red Hat Display", sans-serif;
	color: #545454;

	&:hover {
		background-color: #e9e9e9;
	}
`;

const Remove = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: #ff2d55;
	cursor: pointer;
	font-family: "Red Hat Display", sans-serif;
`;
