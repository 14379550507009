import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import MarkdownRenderer from "./markdown";
import { Icon, Popup } from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  SG_FETCH_DEBRIEF_PDF_REPORT,
  SG_GET_DEBRIEF_PDF_REPORT_URL,
  CLEAR_DEBRIEF_PDF_REPORT_URL,
} from "constants/actions";
import { downloadIcon } from "../../../reports/components/Icons";

const InsightsV2 = () => {
  const { id, share_id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [report, setReport] = useState(null);
  let navigate = useNavigate();

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_DEBRIEF_PDF_REPORT,
        payload: {
          id,
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (pdfReports?.download_url) {
      window.open(pdfReports?.download_url?.url, "_blank");
      dispatch({
        type: CLEAR_DEBRIEF_PDF_REPORT_URL,
      });
    }
  }, [pdfReports?.download_url]);

  const handleDownload = (fileName) => {
    dispatch({
      type: SG_GET_DEBRIEF_PDF_REPORT_URL,
      payload: fileName,
    });
  };

  useEffect(() => {
    if (pdfReports?.[id]) {
      const markdownData = pdfReports?.[id]?.additional_info?.find(
        (item) => item.share_id === share_id
      );
      setData(markdownData?.json_data);
      setReport(pdfReports?.[id]);
    }
  }, [pdfReports]);

  return (
    <Container>
      <Content>
        <BackButton onClick={() => navigate("/leader/audit/AI/insights")}>
          <Icon name="chevron left" style={{ marginTop: -7 }} />
          Back
        </BackButton>
        <TitleRow>
          <Title>Data Story</Title>
          <Popup
            trigger={
              <ActionWrapper
                onClick={() => {
                  handleDownload({
                    id: report?.id,
                    share_id: report?.download_files?.[0]?.share_id,
                  });
                }}
              >
                Download PDF {downloadIcon()}
              </ActionWrapper>
            }
            content="Download"
            position="top center"
          />
        </TitleRow>
        {data && (
          <MarkdownRenderer
            markdown={data?.resp}
            tldr={data?.tldr}
            quotes={data?.quotes}
          />
        )}
      </Content>
    </Container>
  );
};

export default InsightsV2;

const Container = styled.div`
  padding: 20px;
  font-family: "Raleway";
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 700px;
`;

const Title = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #2d70e2;
  margin-bottom: 10px;
  font-weight: bold;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  color: #2d70e2;
  margin: 0 5px;
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;
