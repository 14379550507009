import React, { useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

const ParticipationChart = ({ data, setChartURI }) => {
  const hiddenChartRef = useRef(null);

  useEffect(() => {
    let timeoutId;

    if (data && hiddenChartRef.current && hiddenChartRef.current.chart) {
      // Delay to ensure the chart has rendered
      timeoutId = setTimeout(() => {
        hiddenChartRef.current.chart
          .dataURI({ width: 2400, height: 1600, scale: 1 })
          .then(({ imgURI }) => setChartURI(imgURI));
      }, 500);
    }

    // Clean up on component unmount
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [data, setChartURI]);

  if (!data) return null;

  // Process and sort the data
  const processedData = data
    .map((item) => {
      const { name, data: itemData } = item;
      const { completed, total } = itemData[0]; // Assuming each item has at least one data point
      const value = total !== 0 ? (completed / total) * 100 : 0; // Calculate percentage
      return {
        category: name,
        value,
      };
    })
    .filter((item) => item.value !== 0) // Filter out entries where 'completed' is zero
    .sort((a, b) => b.value - a.value);

  const categories = processedData.map((item) => item.category);
  const dataValues = processedData.map((item) => item.value);

  const yaxisTitle = "Percentage Completed";

  const series = [{ name: yaxisTitle, data: dataValues }];

  const options = {
    chart: {
      type: "bar",
      height: 400,
      fontFamily: "Raleway, sans-serif",
      foreColor: "#7E7E7E",
      zoom: { enabled: false },
      toolbar: { show: false },
      background: "transparent",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        barHeight: "70%",
        distributed: true,
        dataLabels: {
          position: "center", // Position data labels in the center
        },
      },
    },
    colors: processedData.map(() => "#27CDA7"), // Use a consistent color
    xaxis: {
      categories,
      labels: { style: { fontFamily: "Raleway, sans-serif" } },
    },
    yaxis: {
      title: { text: yaxisTitle },
      labels: {
        style: { fontFamily: "Raleway, sans-serif" },
        formatter: (value) => value.toFixed(0), // Display integer values
      },
    },
    legend: { show: false },
    fill: { type: "solid" },
    grid: {
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`, // Display percentage without decimals
      style: {
        colors: ["#fff"], // White text
        fontSize: "14px",
        fontFamily: "Raleway, sans-serif",
      },
    },
    tooltip: {
      enabled: true,
      style: { fontFamily: "Raleway, sans-serif" },
      y: { formatter: (val) => `${val.toFixed(0)}%` },
    },
  };

  const hiddenChartOptions = {
    ...options,
    chart: {
      ...options.chart,
      id: "report-chart",
      width: 2400,
      height: 1600,
      animations: { enabled: false },
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions.bar,
        borderRadius: 10,
      },
    },
    grid: {
      ...options.grid,
      padding: { top: -1, right: 100, bottom: 300, left: 100 },
    },
    dataLabels: {
      ...options.dataLabels,
      style: {
        fontSize: 30,
      },
    },
    xaxis: {
      ...options.xaxis,
      title: {
        text: "Survey Participants",
        offsetY: 200,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
      },
      labels: {
        ...options.xaxis.labels,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
        offsetY: 20,
        rotate: -45,
        maxHeight: 100,
        formatter: (val) =>
          val.length > 30 ? `${val.substring(0, 27)}...` : val,
      },
    },
    yaxis: {
      ...options.yaxis,
      labels: {
        offsetX: 80,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
        formatter: (value) => value.toFixed(0),
      },
      title: {
        text: yaxisTitle,
        offsetX: -30,
        style: { fontFamily: "Raleway, sans-serif", fontSize: 30 },
      },
      tickAmount: 5,
    },
    dataLabels: {
      ...options.dataLabels,
      style: {
        ...options.dataLabels.style,
        fontSize: "20px", // Increase font size for high-resolution chart
      },
    },
  };

  return (
    <Container>
      {dataValues.length > 0 && (
        <>
          <Chart options={options} series={series} type="bar" height={400} />
          <HiddenChartContainer>
            <Chart
              ref={hiddenChartRef}
              options={hiddenChartOptions}
              series={series}
              type="bar"
              height={1600}
              width={2400}
            />
          </HiddenChartContainer>
        </>
      )}
    </Container>
  );
};

export default ParticipationChart;

const Container = styled.div`
  margin-top: 20px;
  position: relative;
`;

const HiddenChartContainer = styled.div`
  position: absolute;
  top: -10000px; /* Position it off-screen */
  left: -10000px;
  width: 2400px; /* Desired high-resolution width */
  height: 1600px; /* Desired high-resolution height */
  overflow: hidden; /* Prevent scrollbars */
`;
