import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import TeamCard from './TeamCard'
import { Link } from "react-router-dom";
 const Container = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	flex-direction:column;
	height:100%;
  position:absolute;
`

const Button = styled.div`
  height: 45px;
  border-radius: 8px;
  background-color: #6a00ff;
  color:white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;
  padding:0 10px 0 10px;
`

const Team = (props) => {

  const [complete,setComplete] = useState(false)
  
  return (
    <Container>
      <TeamCard type={0} back={()=>props.back()} complete={()=>setComplete(true)}/>

      {complete ?<Link to={"/management"}><Button>Complete Setup</Button></Link>:""}
    </Container>
  );
};

export default Team