import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_PERSONALITY_TEAMS,
  SG_GET_PERSONALITY_TEAMS,
  DELETE_PERSONALITY_TEAM,
  SG_DELETE_PERSONALITY_TEAM,
  SG_FETCH_PERSONALITY_TEAM,
  FETCH_PERSONALITY_TEAM,
  SG_EDIT_PERSONALITY_TEAM,
  EDIT_PERSONALITY_TEAM,
  SG_ADD_PERSONALITY_TEAM,
  ADD_PERSONALITY_TEAM,
  CLEAR_PERSONALITY_TEAM,
  SG_CLEAR_PERSONALITY_TEAM,
  ALL_ERRORS,
} from "constants/actions";

function* loadReducer(action) {
  try {
    const json = yield call(api.GET_PERSONALITY_TEAMS, action.payload);
    yield put({ type: GET_PERSONALITY_TEAMS, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaLoad() {
  yield takeLatest(SG_GET_PERSONALITY_TEAMS, loadReducer);
}

function* fetchReducer(action) {
  try {
    const json = yield call(api.FETCH_PERSONALITY_TEAM, action.payload);
    yield put({ type: FETCH_PERSONALITY_TEAM, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaFetch() {
  yield takeLatest(SG_FETCH_PERSONALITY_TEAM, fetchReducer);
}

function* updateReducer(action) {
  try {
    const json = yield call(api.EDIT_PERSONALITY_TEAM, action.payload);
    yield put({ type: EDIT_PERSONALITY_TEAM, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaUpdate() {
  yield takeLatest(SG_EDIT_PERSONALITY_TEAM, updateReducer);
}

function* addReducer(action) {
  try {
    const json = yield call(api.ADD_PERSONALITY_TEAM, action.payload);
    yield put({ type: ADD_PERSONALITY_TEAM, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaAdd() {
  yield takeLatest(SG_ADD_PERSONALITY_TEAM, addReducer);
}

function* deleteReducer(action) {
  try {
    yield call(api.DELETE_PERSONALITY_TEAM, action.payload);
    yield put({
      type: DELETE_PERSONALITY_TEAM,
      payload: action.payload,
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* sagaDelete() {
  yield takeLatest(SG_DELETE_PERSONALITY_TEAM, deleteReducer);
}

export default function* index() {
  yield all([sagaLoad(), sagaFetch(), sagaUpdate(), sagaAdd(), sagaDelete()]);
}
