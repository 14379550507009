import styled, { keyframes } from "styled-components";
import React from "react";
import Comment from './Comment'
import Summary from './Summary'
import Completion from './Completion'
import { Checkbox } from 'semantic-ui-react'


const tabs =[1,2,3,4]

const questions = ['The three biggest takeaways or sticking points for me today were…',
					'The most difficult challenge or moment I faced today was',
					'I would rate my response to this challenge as',
					'Based on today’s session, one thing I would like to improve is..']

const mock =[0,1,2,3,4,5,6,7,8]
const text = 'Filet mignon biltong pork loin shoulder beef. Porchetta capicola bresaola chislic t-bone ham hock strip steak burgdoggen chuck sirloin shank sausage doner. Ground round pork loin shank shankle tail ball tip frankfurter, pork pork belly chislic kielbasa tri-tip picanha. Tri-tip tongue pork ham hock ball tip corned beef beef pork loin. Pastrami t-bone brisket, picanha chicken turducken short loin doner.'

const Practice = () =>{

	const [tab,setTab] = React.useState(0)

	return (
		<Container>
			<Top>
				<Title>Practice Debrief</Title>
				{tabs.map((item,i)=>{
					return <Tabi selected={tab==i} onClick={()=>setTab(i)}>Q{item}</Tabi>
				})}
				<CheckContainer>
					<Checkbox label='Members'  style={{marginRight:10}} checked={true}/>
					<Checkbox label='Leaders' />
				</CheckContainer>
			</Top>
			<Bottom>
				<CommentContainer>
				<Question>{questions[tab]}</Question>
					{mock.map((item,i)=>{
						return <Comment text={text.slice(0,Math.floor(Math.random() * text.length))} 
						q={tab}
						score={Math.floor(Math.random() * 100)}/>
					})}
				</CommentContainer>

				<MetaContainer>
					<Summary/>
					<Completion/>
				</MetaContainer>
			</Bottom>
		</Container>
		)
}


export default Practice



const Container = styled.div`
 font-family: 'Red Hat Display', sans-serif;
`

const Top = styled.div`
	height:70px;
	margin-top:10px;
	border-bottom:1px solid #ebebeb;
	width:65%;
	display:flex;
	align-items:center;
	padding:10px;
	position:relative;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-right:20px;
`

const Bottom = styled.div`
	width:100%;
	display:flex;
	justify-content:space-between;
`

const CommentContainer = styled.div`
	width:65%;

`

const MetaContainer = styled.div`
	width:30%;
	margin-top:60px;
`

const Tabi = styled.div`
	padding:8px 12px;
	background-color:${props=>props.selected?'#15056b':'#ebebeb'};
	border-radius:15px;
	margin-right:10px;
	color:white;
	font-weight:bold;
	cursor:pointer;
`

const Question = styled.div`
	font-size:20px;
	font-weight:500;
	margin-top:20px;
`

const CheckContainer = styled.div`
	position:absolute;
	right:0;
`