import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Table from "../components/Table";
import Side from "../components/Side";
import Footer from "../components/Footer";

// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";
import PoppinsBold from "assets/fonts/Poppins-Bold.ttf";
import PoppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import PoppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import PoppinsItalic from "assets/fonts/Poppins-LightItalic.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsItalic, fontWeight: 200 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsRegular, fontWeight: 400 },
  ],
  format: "truetype",
});

const Risks = ({ data, question, categories, anchor, config }) => {
  const tableTemplate = [
    { title: "Question", width: "50%" },
    { title: "Factor", width: "15%" },
    { title: "Anchor", width: "15%" },
    { title: "Norm", width: "10%" },
    { title: "Score", width: "10%" },
  ];

  const anchorName = categories?.categories.find((f) => f.id === anchor);
  const fetchQuestion = (q) => {
    return question.dimensions[q.dimension].factors[Number(q.section)]
      .questions[Number(q.question)]?.q;
  };

  const fetchFactor = (q) => {
    return question.dimensions[q.dimension].factors[Number(q.section)].title;
  };

  const fetchRole = (q) => {
    return q?.label?.name;
  };

  const fetchReverseOption = (q) => {
    return question.dimensions[q.dimension].factors[Number(q.section)]
      .questions[Number(q.question)]?.reverse;
  };

  let dataSorted = data.sort((a, b) => {
    let bAverage = b.average * 10;
    let aAverage = a.average * 10;

    if (fetchReverseOption(a)) {
      aAverage = 100 - aAverage;
    }

    if (fetchReverseOption(b)) {
      bAverage = 100 - bAverage;
    }

    return aAverage - bAverage;
  });

  const riskDataSorted = dataSorted.slice(0, 10);
  const riskData = riskDataSorted
    .filter((item) => {
      const isReverse = fetchReverseOption(item);
      const average = isReverse
        ? 100 - Math.floor(item.average * 10)
        : Math.floor(item.average * 10);
      return average < config.riskThreshold;
    })
    .map((item) => {
      const average = Math.floor(item.average * 10);
      const isReverse = fetchReverseOption(item);
      const averagePrefix = isReverse ? "**" : "";

      return [
        `${fetchQuestion(item)}`,
        `${fetchFactor(item)}`,
        `${anchorName.name} (${fetchRole(item)})`,
        "--",
        `${averagePrefix}${average}%`,
      ];
    });

  const riskTableData = {
    rows: tableTemplate,
    data: riskData,
  };

  dataSorted = data.sort((a, b) => {
    let bAverage = b.average * 10;
    let aAverage = a.average * 10;

    if (fetchReverseOption(a)) {
      aAverage = 100 - aAverage;
    }

    if (fetchReverseOption(b)) {
      bAverage = 100 - bAverage;
    }

    return bAverage - aAverage;
  });

  const strengthData = dataSorted
    .filter((item) => {
      const isReverse = fetchReverseOption(item);
      const average = isReverse
        ? 100 - Math.floor(item.average * 10)
        : Math.floor(item.average * 10);
      return average > config.strThreshold;
    })
    .slice(0, 10)
    .map((item) => {
      const average = Math.floor(item.average * 10);
      const isReverse = fetchReverseOption(item);
      const averagePrefix = isReverse ? "**" : "";
      return [
        `${fetchQuestion(item)}`,
        `${fetchFactor(item)}`,
        `${anchorName.name} (${fetchRole(item)})`,
        "--",
        `${averagePrefix}${average}%`,
      ];
    });

  const strengthTableData = {
    rows: tableTemplate,
    data: strengthData,
  };

  return (
    <>
      <Page style={styles.page}>
        <Text style={styles.T2}>Key Areas of Growth & Strengths</Text>
        <View style={styles.line} />
        <Text style={styles.p}>
          The tables below display the ten lowest (areas of growth) at a
          threshold of 75% and lower, and ten highest (strengths) at a threshold
          of 85% or higher, scoring questions from the assessment, as well as
          the factor each question belongs to.
        </Text>

        <View wrap={false}>
          <Text style={[styles.title, { color: config.titleColor }]}>
            Top Areas of Growth
          </Text>
          {(riskData.length > 0 && (
            <Table
              data={riskTableData}
              headerColor={config.tableBorderColor}
              hideReverseText={false}
            />
          )) || (
            <Text style={styles.description}>No areas of growth detected</Text>
          )}
        </View>
        <Side {...config} />
        <Footer {...config} />
      </Page>
      <Page style={styles.page}>
        <View wrap={false}>
          <Text style={[styles.title, { color: config.titleColor }]}>
            Top Strengths
          </Text>
          {(strengthData.length > 0 && (
            <Table
              data={strengthTableData}
              headerColor={config.tableBorderColor}
              hideReverseText={false}
            />
          )) || <Text style={styles.description}>No Strengths Detected</Text>}
        </View>
        <Side {...config} />
        <Footer {...config} />
      </Page>
    </>
  );
};

export default Risks;

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 11,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#000921",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    marginTop: 0,
  },
  tableSubText: {
    fontSize: 12,
    fontWeight: 200,
  },
});
