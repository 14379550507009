import React from "react";
import { Page, Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import Side from "./Side";
import Footer from "./Footer";
import OTPChart from "./OTPChart";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

Font.register({
  family: "Barlow",
  src: Barlow,
  format: "truetype",
});

const AreasOfImprovement = (props) => {
  const {
    nav,
    categories,
    rule,
    structure,
    reportData,
    config,
    accessSubfactor,
    calculated,
    isFrench,
  } = props;

  const lang = isFrench ? "fr" : "en";

  const colorsArr = ["#d9d9d9", "#e5b63d", "#b9bdc5", "#ac710f", "#cd2c38"];
  const anchorTwo = categories.categories.find(
    (f) => f.priority === "secondary"
  );

  const tableData = structure.dimensions.map((d) => {
    const anchorTwo = categories.categories.find(
      (f) => f.priority === "secondary"
    );

    const dimensionResponses = calculated?.summary?.filter(
      (r) => Number(r.dimension) === d.id - 1
    );

    const dimensionResponsesForOverall = calculated?.raw?.filter(
      (r) => Number(r.dimension) === d.id - 1
    );
    const tableGroups = categories.categories
      ?.find((ao) => ao.priority === "secondary")
      .options?.map((ao) => {
        return {
          title: isFrench
            ? ao?.fr.charAt(0).toUpperCase()
            : ao.name.charAt(0).toUpperCase(),
          id: ao.id,
        };
      });

    const dimensionData = d.factors.map((f) => {
      const factorResponses = dimensionResponses.filter(
        (r) => Number(r.factor) === f.id - 1
      );

      const factorResponsesForOverall = dimensionResponsesForOverall.filter(
        (r) => Number(r.factor) === f.id - 1
      );

      let n = 0;
      const factorAvg =
        factorResponsesForOverall.reduce((total, b) => {
          n += b.raw?.length;

          return total + b.raw?.reduce((acc, curr) => acc + curr, 0);
        }, 0) / n || null;

      const factorColumns = tableGroups.map((g) => {
        return {
          average: factorResponses.find((fr) => fr.secondary === g.id)?.average,
          id: g.id,
        };
      });

      return {
        factor: isFrench ? f?.fr : f.title,
        factorAverage: factorAvg,
        factorResponses: factorColumns,
      };
    });

    let n = 0;
    let overallDimAverage = 0;

    dimensionData.forEach((dd) => {
      if (dd.factorAverage) {
        n++;
        overallDimAverage += dd.factorAverage;
      }
    });

    if (n > 0 && overallDimAverage > 0) {
      overallDimAverage = overallDimAverage / n;
    }

    return {
      dimension: isFrench ? d?.fr : d.title,
      dimensionId: d.id,
      dimensionAvg: overallDimAverage,
      data: dimensionData,
    };
    // }
  });

  const getSortedTableData = (data) => {
    const compiledData = data.map((dim) => dim.data);
    const sortedDimData = compiledData.flat().sort((a, b) => {
      if (a.factorAverage > b.factorAverage) return 1;
      if (a.factorAverage < b.factorAverage) return -1;
      return 0;
    });
    return sortedDimData;
  };

  const createTables = (
    data,
    headerColor,
    wrapRow = true,
    hideReverseText = true
  ) => {
    const domainsText = isFrench ? `Par ${anchorTwo?.fr_name}`  : `By ${anchorTwo?.name}`;
    return (
      <View>
        <View
          style={[
            styles.tableStyle,
            { marginBottom: (hideReverseText && 30) || 10 },
          ]}
          wrap={!wrapRow}
        >
          <View style={styles.body}>
            <View style={[{ backgroundColor: "white" }, styles.H]}>
              <View style={[{ width: "42%" }, styles.TH]} wrap={wrapRow}>
                <Text>{getTranslatedText("Domains", lang, translations)}</Text>
              </View>
              <View
                style={[{ width: "10.5%" }, styles.TH]}
                wrap={wrapRow}
              ></View>
              <View style={[{ width: "51.5%" }, styles.TH]} wrap={wrapRow}>
                <Text>{domainsText}</Text>
              </View>
            </View>
            <View style={[{ backgroundColor: "white" }, styles.H]}>
              <View style={[{ width: "45%" }, styles.TH]} wrap={wrapRow}>
                <Text></Text>
              </View>
              <View
                style={[
                  { width: "11%", backgroundColor: "#d9d9d9" },
                  styles.TH,
                ]}
                wrap={wrapRow}
              >
                <Text>{getTranslatedText("avg", lang, translations)}</Text>
              </View>
              <View
                style={[
                  { width: "11%", backgroundColor: "#e5b63d" },
                  styles.TH,
                ]}
                wrap={wrapRow}
              >
                <Text>{getTranslatedText("Athlete", lang, translations)}</Text>
              </View>
              <View
                style={[
                  { width: "11%", backgroundColor: "#b9bdc5" },
                  styles.TH,
                ]}
                wrap={wrapRow}
              >
                <Text>{getTranslatedText("Coach", lang, translations)}</Text>
              </View>
              <View
                style={[
                  { width: "11%", backgroundColor: "#ac710f" },
                  styles.TH,
                ]}
                wrap={wrapRow}
              >
                <Text>SSSM</Text>
              </View>
              <View
                style={[
                  { width: "11%", backgroundColor: "#cd2c38" },
                  styles.TH,
                ]}
                wrap={wrapRow}
              >
                <Text>NSO</Text>
              </View>
              <View
                style={[{ width: "11%", backgroundColor: "white" }, styles.TH]}
                wrap={wrapRow}
              >
                <Text>{getTranslatedText("Other", lang, translations)}</Text>
              </View>
            </View>
            {data.map((row) => {
              return (
                <View style={[{ backgroundColor: "white" }, styles.H]}>
                  <View style={[{ width: "45%" }, styles.TD]} wrap={wrapRow}>
                    <Text>{row.factor}</Text>
                  </View>
                  <View
                    style={[
                      { width: "11%", backgroundColor: colorsArr[0] },
                      styles.TD2,
                    ]}
                    wrap={wrapRow}
                  >
                    <Text>{row.factorAverage?.toFixed(1)}</Text>
                  </View>
                  {row.factorResponses.map((col, i) => {
                    return (
                      <View
                        style={[
                          { width: "11%", backgroundColor: colorsArr[i + 1] },
                          styles.TD2,
                        ]}
                        wrap={wrapRow}
                      >
                        <Text>{col.average?.toFixed(1) || "--"}</Text>
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        {!hideReverseText && (
          <Text style={styles.tableSubText}>
            {getTranslatedText("reverseTextMsg", lang, translations)}
          </Text>
        )}
      </View>
    );
  };

  return (
    <>
      <Page style={styles.page}>
        <Text style={[styles.T2, { color: props.titleColor }]}>
          {getTranslatedText("summary", lang, translations)}
        </Text>
        <View style={styles.line} />
        {/* <View style={styles.section} wrap={false}>
          <Text style={styles.question}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Text>
        </View> */}

        {tableData.length > 0 && (
          <View>{createTables(getSortedTableData(tableData), "blue")}</View>
        )}
        {/* {tableData.length > 0 &&
          getSortedTableData(tableData)?.map((da) => {
            return (
              <View>
                <OTPChart categories={categories} data={da} />
              </View>
            );
          })} */}
        <Side />
        <Footer isFrench={isFrench} />
      </Page>
    </>
  );
};

export default AreasOfImprovement;

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    marginBottom: 15,
  },
  tableStyle: {
    width: "100%",
    border: "0.5px solid grey",
    marginTop: 20,
  },
  page: {
    padding: 60,
    paddingHorizontal: 80,
  },
  p: {
    fontSize: 9,
    marginBottom: 10,
    lineHeight: 1.7,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#000921",
    marginTop: 5,
    marginBottom: 20,
  },
  description: {
    lineHeight: 1.7,
    fontSize: 9,
    marginTop: 0,
  },
  tableSubText: {
    fontSize: 10,
    fontWeight: 200,
  },
  textPadding: {
    paddingHorizontal: 70,
  },
  TD2: {
    fontSize: 8,
    padding: 4,
    borderBottom: "0.5px solid grey",
    borderRight: "0.5px solid grey",
    display: "flex",
    alignItems: "center",
  },
  TH: {
    fontSize: 8,
    padding: 4,
    borderBottom: "0.5px solid grey",
    borderRight: "0.5px solid grey",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  H: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  },
  T2: {
    fontSize: 18,
    fontWeight: 700,
    color: "#000921",
  },
  TD: {
    fontSize: 8,
    padding: 4,
    borderBottom: "0.5px solid grey",
    borderRight: "0.5px solid grey",
  },
});
