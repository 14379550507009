import React, { useEffect } from "react";
import Thematic from "./Thematics";
import Participation from "./Participation";
import Factor from "./Factor";
import Score from "./Score";
import { filter_data } from "reports/Tag/calculations";
import { SET_FILTERED_DATA } from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

const TemplateBuilder = ({
  selected,
  slideData,
  updateSlideData,
  loading,
  outcomeQ,
  outcomeStandards,
}) => {
  const dispatch = useDispatch();

  const unfiltered = useSelector(
    (state) => state.audit?.raw_data,
    shallowEqual
  );
  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  useEffect(() => {
    if (selectedFilters.length > 0 && selectedFilters[0].parent != null){
      const filtered = filter_data(unfiltered[0].responses, selectedFilters);
      dispatch({
        type: SET_FILTERED_DATA,
        payload: filtered,
      });
    }
    else{
      dispatch({
        type: SET_FILTERED_DATA,
        payload: unfiltered[0].responses,
      });
    }
    
  }, [selectedFilters, unfiltered]);

  switch (selected) {
    case "Thematic Analysis":
      return (
        <Thematic
          slideData={slideData}
          updateSlideData={updateSlideData}
          componentLoading={loading}
          outcomeQ={outcomeQ}
        />
      );
    case "Participation":
      return (
        <Participation
          slideData={slideData}
          updateSlideData={updateSlideData}
          loading={loading}
        />
      );
    case "Factor Analysis":
      return (
        <Factor
          slideData={slideData}
          updateSlideData={updateSlideData}
          loading={loading}
        />
      );
    case "Score Overview":
      return (
        <Score
          slideData={slideData}
          updateSlideData={updateSlideData}
          loading={loading}
          outcomeQ={outcomeQ}
          outcomeStandards={outcomeStandards}
        />
      );
    default:
      return null;
  }
};

export default TemplateBuilder;
