import React from "react";
import styled from "styled-components";
import { searchIcon } from "./Icons";

const SearchBar = (props) => {
  const { onChange, text, placeholderText } = props;

  return (
    <SearchContainer>
      <SearchBox
        placeholder={placeholderText}
        value={text}
        onChange={(e) => onChange(e.target.value)}
      />
      {searchIcon()}
    </SearchContainer>
  );
};

const SearchBox = styled.input`
  display: flex;
  width: 100%;
  border: none;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background: #f8faff;
  &:focus {
    outline: none;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #f8faff;
  border-radius: 8px;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 12px;
  margin-top: 16px;
`;

export default SearchBar;
