import styled, { keyframes } from "styled-components";
import React from "react";
import { Popup,Icon } from 'semantic-ui-react'
import { Link, useLocation } from "react-router-dom";
import Pic1 from 'assets/images/calendar.png'
import Pic2 from 'assets/images/google-docs.png'
import Schedule from './Schedule'
import Status from './Status'
import Calendar from './Calendar'
import Audit from '../index'

const Type = (props) =>{

	return (
		<TypeContainer>
			<div>
			<TypeName>{props.name}</TypeName>
			<Description> sdhf sdjfdhs skhfdsjkdfhs jshd fhf jks jhf ksfhd</Description>
			<See>Learn more</See>
			</div>
			<div>
				<Image src={props.name === 'Game'?Pic1:Pic2} />
			</div>
		</TypeContainer>
		)
}

const History = () => {

	const [select,setSelect] = React.useState(0)

	if(select === 1){
		return <Status />
	}
	if(select === 2){
		return <Audit/>
	}

	return (
		<Container>
			<Title>Own the Podium Culture Index</Title>
			<Buttons>
				<Schedule trigger={<StyledButton>Schedule</StyledButton>} />
			<Link to={'/culture_index'}><StyledButton>View Report</StyledButton></Link>
			</Buttons>
			<Calendar/>
			<Table>
				<TR>
					<TH width={30}>Date</TH>
					<TH width={30}>Status</TH>
					<TH width={30}>Responses</TH>
					<TH width={10}>Actions</TH>
				</TR>

				{[0,1,2,3,4,5,6,7,8].map((item,i)=>{
					return (
						<TR>
							<TD width={30}>October 14th, 2021</TD>
							<TD width={30}>Pending</TD>
							<TD width={30}>
							
								<div>10 / 12</div>
								
							</TD>
							<TD width={10}>
								<Popup content='Status' trigger={<Icon name="eye" style={{color:'#9b9b9b',cursor:'pointer',fontSize:20,marginRight:10}} onClick={()=>setSelect(1)}/>} />
								<Popup content='Report' trigger={<Icon name="paperclip" style={{color:'#9b9b9b',cursor:'pointer',fontSize:20,marginRight:10}} />} />

								<Popup content='Edit' trigger={<Icon name="edit"  style={{color:'#9b9b9b',cursor:'pointer',fontSize:20}}/>} />
								
							</TD>
						</TR>
						)
				})}

			</Table>

		</Container>
		)
}


export default History


const Container = styled.div`
	margin-top:50px;
	font-family: 'Red Hat Display', sans-serif;
`

const Title = styled.div`
		font-size:26px;
	font-weight:bold;
	margin-right:20px;
	font-family: 'Barlow', sans-serif;
	
`
const DropContainer = styled.div`
	width:200px;
	margin-top:20px;
`
const Types = styled.div`
	width:100%;
	display:flex;
`

const TypeContainer = styled.div`
	width:400px;
	margin-right:30px;
	margin-top:20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display:flex;
	padding:30px;
`
const TypeName = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
`

const Description = styled.div`
	color:#757575;
`

const Image = styled.img`
	width:100px;
`

const Table = styled.div`
	margin-top:30px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
`

const TR = styled.div`
	display:flex;
	padding:15px 10px;
	width:100%;
`

const TH = styled.div`
	width:${props=>props.width+'%'};
	font-weight:bold;
	border-bottom:1px solid #9b9b9b;
	padding-bottom:5px;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	position:relative;
`

const T1 = styled.div`
	font-weight:500;
	font-size:18px;
`

const SmallImg = styled.img`
	width:30px;
	margin-right:10px;
`

const See = styled.div`
	margin-top:10px;
	color:#6a00ff;

`

const View = styled.div`
	padding:5px 35px;
	background-color:#6a00ff;
	color:white;
	border-radius:20px;
	font-weight:bold;
	cursor:pointer;
`
const StyledButton = styled.button`
  background-color: #E3B01F;
  color: black;
  font-size: 16px;
  border-radius:20px;
  font-weight:300;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  outline: none;
  margin-top:30px;
  margin-right:10px;
  font-family: 'Barlow', sans-serif;
 
  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;

const Buttons = styled.div`
	display:flex;
`