import styled, { keyframes } from 'styled-components';
import React from "react";



const Component5 = () =>{

    return (
        <Container>

            <T1>How well did you understand your culture?</T1>
            <Text delay={0.5}>
            Please selection an option that is most representative of your understanding of the infomration presented.
            </Text>


        <ButtonArea>
            <Button delay={1.5}>
                I understand my culture
            </Button>

            <Button  delay={1.5}> 
                Information is missing
            </Button>

            <Button  delay={1.5}>
               I did not understand
            </Button>
               
        </ButtonArea>
           

            
        </Container>
    )
}

export default Component5

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`



const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:30px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const Button = styled.div`
    color:#2A3039;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-right:20px;
    font-family:'Raleway';
    margin-top:20px;
    opacity:0;
    border:1px solid #2A3039;
    padding:20px;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const ButtonArea = styled.div`
  display:flex;

`