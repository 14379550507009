import {
  Svg,
  Rect,
  Path,
  G,
  Defs,
  ClipPath,
  Circle,
  Line,
} from "@react-pdf/renderer";

export function CalendarIcon(fill) {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G id="Icon">
        <Path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2.5C7.27614 2.5 7.5 2.72386 7.5 3V4.5H16.5V3C16.5 2.72386 16.7239 2.5 17 2.5C17.2761 2.5 17.5 2.72386 17.5 3V4.5H19C20.3807 4.5 21.5 5.61929 21.5 7V19C21.5 20.3807 20.3807 21.5 19 21.5H5C3.61929 21.5 2.5 20.3807 2.5 19V7C2.5 5.61929 3.61929 4.5 5 4.5H6.5V3C6.5 2.72386 6.72386 2.5 7 2.5ZM5 5.5C4.17157 5.5 3.5 6.17157 3.5 7V8.5H20.5V7C20.5 6.17157 19.8284 5.5 19 5.5H5ZM20.5 9.5H3.5V19C3.5 19.8284 4.17157 20.5 5 20.5H19C19.8284 20.5 20.5 19.8284 20.5 19V9.5Z"
          fill="#666D79"
        />
      </G>
    </Svg>
  );
}

export function CalendarIconSmall(fill) {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <G id="Icon">
        <Path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7 2.5C7.27614 2.5 7.5 2.72386 7.5 3V4.5H16.5V3C16.5 2.72386 16.7239 2.5 17 2.5C17.2761 2.5 17.5 2.72386 17.5 3V4.5H19C20.3807 4.5 21.5 5.61929 21.5 7V19C21.5 20.3807 20.3807 21.5 19 21.5H5C3.61929 21.5 2.5 20.3807 2.5 19V7C2.5 5.61929 3.61929 4.5 5 4.5H6.5V3C6.5 2.72386 6.72386 2.5 7 2.5ZM5 5.5C4.17157 5.5 3.5 6.17157 3.5 7V8.5H20.5V7C20.5 6.17157 19.8284 5.5 19 5.5H5ZM20.5 9.5H3.5V19C3.5 19.8284 4.17157 20.5 5 20.5H19C19.8284 20.5 20.5 19.8284 20.5 19V9.5Z"
          fill="#666D79"
        />
      </G>
    </Svg>
  );
}