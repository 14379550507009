import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Dropdown from 'reports/Dashboard/Dropdown'

const ChartNavigation = ({setChartType,chartType}) =>{


    return (
        <Container>
            <C1>
                <Text>
                    Chart Type
                </Text>

                <Option onClick={()=>setChartType('Culture Map')} selected={chartType==='Culture Map'}>
                    Culture Map
                </Option>
                <Option onClick={()=>setChartType('Heat Map')} selected={chartType==='Heat Map'}>
                    Heat Map
                </Option>
                <Option onClick={()=>setChartType('Distribution')} selected={chartType==='Distribution'}>
                    Distribution
                </Option>
            </C1>
            
            <C1>
               
            </C1>
            
        </Container>
    )

}


export default ChartNavigation;


const Container = styled.div`
    display:flex;
    align-items:center;
    margin-top:30px;
    width:100%;
    justify-content:space-between;
    padding-right:40px;
    padding-left:40px;
`

const Text = styled.div`
    margin-right:10px;
    font-family: 'Raleway';
font-style: normal;
font-weight: bold;
font-size: 12px;
color: #666D79;
`

const Option = styled.div`
    padding:5px;
    cursor:pointer;
    color:${props=>props.selected?'white':'#2D70E2'};
    font-weight:${props=>props.selected?'bold':'normal'};
    font-family: 'Raleway';
    font-size:14px;
    background-color:${props=>props.selected?'#2D70E2':'white'};
    border-radius:5px;
    border:${props=>props.selected?'none':'1px solid #2D70E2'};
    margin-right:10px;
`

const C1 = styled.div`
    display:flex;
    align-items:center;
`