import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Message, Icon, Confirm } from "semantic-ui-react";
import { Pagination, Dimmer, Loader, Popup } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import Select from "react-select";

import {
  SG_GET_DEBRIEF_SCHEDULES,
  ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
  SG_DELETE_DEBRIEF_SCHEDULE,
  SG_GET_CULTURE_AUDIT_REPORTS,
} from "constants/actions";

import format from "date-fns/format";
import SurveyStatusHistory from "components/CultureAudit/SurveyStatusHistory";
import { SURVEY_TYPE, SURVEY_INFO, SURVEY_QUESTION_TYPE } from "constants/surveys";
import CulturePDF from "components/PDF";
import DeiPDF from "components/DEI-PDF";
import Anchor from "reports/Audit/Anchor";
import ErrorBoundary from "ErrorBoundary";

const StatusPendingHistoryList = ({
  fetchAgain,
  setFetchAgain,
  showErrors,
  setOpen,
  setAlertMessage,
  pdfConfig,
  primaryCategory,
  primarySecondaryName,
  tab,
}) => {
  const dispatch = useDispatch();
  const surveyType = SURVEY_TYPE.AUDIT
  const [briefId, setBriefId] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [pageLoading, setPageLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [debriefSchedules, setDebriefSchedules] = useState([]);
  const [organizationId, setOrganizationId] = useState(false);
  const showItems = 'history'
  const [showMenu, setShowMenu] = useState(false);

  const [deletedRecord, setDeletedRecord] = useState(false);


  const [showSurveyStatus, setShowSurveyStatus] = useState(0);

  const [selectedPrimary, setSelectedPrimary] = useState([]);

  const [nonHistory, setNonHistory] = useState(true);

  const [currStatusPage, setCurrStatusPage] = useState("");

  // this has been set in django if you use non basic pagination
  // this page using basic pagination
  const defaultPageSize = 12;

  const {
    get_auth,
    get_culture_audit_reports,
    get_debrief_schedules,
    get_organizations,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_debrief_schedules: state.debrief_schedule,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_organizations: state.organizations,
    }),
    shallowEqual
  );

  const ambassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );

  useEffect(() => {
    setOrganizationId(Number(get_auth.organization_id));
}, [get_auth]);
 
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  useEffect(() => {
    // console.log(tab)
    setDebriefSchedules(get_debrief_schedules.debrief_schedule.results);

    if (get_debrief_schedules.activePage > 0) {
      if (get_debrief_schedules.activePage !== activePage) {
        setActivePage(get_debrief_schedules.activePage);
      }
    }
  }, [get_debrief_schedules, activePage, tab]);

  useEffect(() => {
    setTotalPages(
      Math.ceil(get_debrief_schedules.debrief_schedule?.count / defaultPageSize)
    );
  }, [activePage, get_debrief_schedules]);


  useEffect(() => {
    if (organizationId && activePage) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page=${activePage}&type_of=${surveyType}&organization=${organizationId}${
          showItems ? `&curr-status=${showItems}` : ""
        }${selectedPrimary?.value ? `&name=${selectedPrimary?.value}` : ""}`,
      });

      if (fetchAgain) {
        setFetchAgain(false);
      }
      if (deletedRecord) {
        setDeletedRecord(false);
      }
    }
  }, [
    dispatch,
    organizationId,
    activePage,
    fetchAgain,
    setFetchAgain,
    deletedRecord,
    surveyType,
    showItems,
    selectedPrimary,
  ]);

  useEffect(() => {
    if (!get_culture_audit_reports?.response && surveyType) {
      if (ambassadorId) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${surveyType}&organization=${organizationId}`,
        });
      } else {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=${surveyType}`,
        });
      }
    }
  }, [dispatch, surveyType, ambassadorId, get_culture_audit_reports.response, organizationId]);

  useEffect(() => {
    if (showItems === "history") {
      setNonHistory(false);
    }
  }, [showItems]);

  useEffect(() => {
    if (showItems !== currStatusPage) {
      // console.log('reset page no')
      setCurrStatusPage(showItems);
      setActivePage(1);
      dispatch({
        type: ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
        payload: 1,
      });
    }
  }, [showItems, dispatch, currStatusPage]);

  function ChangedPage(e, { activePage }) {
    setActivePage(activePage);
    dispatch({
      type: ADD_ACTIVE_PAGE_DEBRIEF_SCHEDULE,
      payload: activePage,
    });
  }

  useEffect(() => {
    setPageLoading(true);
    const timer1 = setTimeout(() => {
      setPageLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [dispatch, fetchAgain, deletedRecord, selectedPrimary]);


  const anchorWidth = pdfConfig?.[0].data.introTemplate === 2 ? "50" : "100";
  const hasAnchor = anchor || surveyType === 4;

  function Paginate() {
    return (
      <Pagination
        activePage={activePage}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        prevItem={debriefSchedules?.links?.previous ? true : null}
        nextItem={debriefSchedules?.links?.next ? true : null}
        totalPages={totalPages}
        onPageChange={ChangedPage}
      />
    );
  }

  function clearErrors() {
    setFetchAgain(false);
    setDeletedRecord(false);
  }


  function SurveyDetails(params) {
    setShowSurveyStatus(params);
    setBriefId(params);
    setShowMenu(false);
  }

  if (showSurveyStatus) {
    return (
      <Container>
      <Small>
      {get_organizations?.[organizationId]?.name} Culture Index
      </Small>
      <Title>
          Active surveys
      </Title>
      <SurveyStatusHistory
          setShowSurveyStatus={setShowSurveyStatus}
          briefId={briefId}
          setAlertMessage={setAlertMessage}
          setShowMenu={setShowMenu}
          setBriefId={setBriefId}
          // nonHistory={true} // for historic responses
      />
    </Container>
    );
  }

  return (
    <Container>
        <Small>
        {get_organizations?.[organizationId]?.name} Culture Index
        </Small>
        <Title>
            Survey History
        </Title>
      {!showErrors && fetchAgain > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Schedule Added!"
          content="You have successfully added a new schedule!"
        />
      )}

      {!showErrors && deletedRecord > 0 && (
        <Message
          positive
          onDismiss={clearErrors}
          header="New Schedule Removed!"
          content="You have successfully removed a schedule!"
        />
      )}
      {/* <Dimmer.Dimmable as={Segment} dimmed={pageLoading}> */}

      <Dimmer.Dimmable dimmed={pageLoading}>
        <Dimmer active={pageLoading} inverted>
          <Loader>Loading</Loader>
        </Dimmer>
        <Table>
          <THeader>
            <TH width={20}>Primary Factor</TH>
            <TH width={50}>Date &amp; Time</TH>
            <TH width={15}>Status</TH>
            <TH width={15}>Responses</TH>
            {/* <TH width={10}>Actions</TH> */}
            {/* {pdfConfig?.length > 0 && <TH width={10}>PDF</TH>} */}
          </THeader>
            {/* {pdfConfig?.length > 0 && <TH width={10}>PDF</TH>} */}

          {debriefSchedules &&
            !pageLoading &&
            debriefSchedules.map((brief, i) => {
              return (
                <TR key={i} onClick={() => SurveyDetails(brief?.id)}>
                   <TD width={20}><Factor>{brief?.name}</Factor></TD>
                   <TD width={50}>
                    <TableRows>
                        {`${format(
                          new Date(brief?.scheduled_for?.toLocaleString()),
                          "EEEE, MMM do h:mm a"
                        )} to ${format(
                          new Date(brief?.close_at?.toLocaleString()),
                          "EEEE, MMM do h:mm a"
                        )}`}
                    </TableRows>
                  </TD>
                  <TD width={15}><Status text={brief?.status_text}/></TD>
                  <TD width={15}>
                    {brief.status > 2 ? (
                      <Responses>
                        <strong>{brief?.total_responses}</strong> / {brief?.total_requests}
                        </Responses>
                    ) : (
                      "Pending"
                    )}
                  </TD>
                  {/* <TD width={10}>
                      <Popup
                        content="Status"
                        trigger={
                          <Icon
                            name="history"
                            style={{
                              color: "#9b9b9b",
                              cursor: "pointer",
                              fontSize: 20,
                              marginRight: 10,
                            }}
                            onClick={() => SurveyDetails(brief?.id)}
                          />
                        }
                      />

                  </TD> */}
                  {/* 
                  {pdfConfig?.length > 0 && hasAnchor && (
                    <TD width={10} style={{ minWidth: "10%" }}>
                      <div
                        style={{
                          left: -15,
                          minWidth: "110px",
                        }}
                      />
                      {brief &&
                      organizationId &&
                      hasAnchor &&
                        surveyType === 3 ? (
                          <ErrorBoundary componentFallback={<ErrorWrapper>An unknown error has occurred</ErrorWrapper>} >
                            <CulturePDF
                              coverSubtitle={brief?.type_of_text}
                              options={{ reportId: brief.id, dontRefetch: true }}
                              anchor={anchor}
                              config={pdfConfig}
                              activePage={activePage}
                            />
                          </ErrorBoundary>
                      ) : (
                        <ErrorBoundary componentFallback={<ErrorWrapper>An unknown error has occurred</ErrorWrapper>}>
                          <DeiPDF
                            config={pdfConfig}
                            activePage={activePage}
                            reportId={brief.id}
                          />
                        </ErrorBoundary>
                      )}
                    </TD>
                  )} */}
                </TR>
              );
            })}
        </Table>

        <Pages>
          <Paginate />
        </Pages>
      </Dimmer.Dimmable>
    </Container>
  );
};

export default StatusPendingHistoryList;


const Status = ({text}) =>{

  const [statusText, setStatusText] = useState('')

  const color1 = {
    'Demo':'#F2F2F2',
    'Completed':'#EAFBF7',
    'Processing':'#EBF1FD',
    'Pending':'#FFFAF1',
    'Live':'#FEF3F4'
  }

  const color2 = {
    'Demo':'#666D79',
    'Completed':'#1D9A7D',
    'Processing':'#2D70E2',
    'Pending':'#FEA711',
    'Live':'#F15062'
  }

  useEffect(() => {
    if(text === 'Demo'){
      setStatusText('Demo')
    }else if(text === 'Completed'){
      setStatusText('Completed')
    }else if(text === 'Processing'){
      setStatusText('Processing')
    }else if(text === 'Pending'){
      setStatusText('Pending')
    }else if(text === 'Live'){
      setStatusText('Live')
    }

    setStatusText('Completed')
  }, [text])



  return (
    <StatusContainer color1={color1[statusText]} color2={color2[statusText]}>
      {statusText}
    </StatusContainer>
  )
}


/******** Styles ****************************************************/
const Container = styled.div`
    margin-top:30px;
    padding:20px;
    font-family:"Raleway", sans-serif;
    position:relative;
`


const GridAdjustment = styled.div`
  left: ${(props) => (props.pdfConfig?.length > 0 ? "-15px" : "0px")};
  position: ${(props) =>
    props.pdfConfig?.length > 0 ? "absolute" : "relative"};
`;

const Table = styled.div`
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
`;

const TR = styled.div`
  display: flex;
  padding:10px;
  width: 100%;

  &:hover{
    background-color:#F8FAFF;
    cursor:pointer;
  }
`;

const THeader = styled.div`
    display:flex;
    width:100%;
    padding:15px 10px;
`

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  font-size:14px;
  font-weight: 600;
  border-bottom: 1px solid #DFDFDF;;
  padding: 15px 10px;
  color:#666D79;
`;



const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
`;

const ErrorWrapper = styled.div`
  width: 110px;
  position: absolute;
  left: 0;
  top: 0;
  color: red;
`;

const Pages = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const AnchorWrapper = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

const FilterWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
`;

const TopSection = styled.div`
  flex-direction: row;
  display: flex;
`;

const Label = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: grey;
  margin-top: -15px;
  margin-bottom: -3px;
`;

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;


const TableRows = styled.div`
  display: flex;
  flex-direction: row;
  color:#666D79;
   font-size:12px;
`;

const Factor = styled.div`
    font-weight:600;
    color:#2A3039;
   

`

const StatusContainer = styled.div`
    background-color:${props=>props.color1};
    color:${props=>props.color2};
    padding: 4px 8px;
    border-radius:8px;
    font-weight:600;

`

const Responses = styled.div`
      color:#2A3039;
`

const Title = styled.div`
    font-size: 24px;
    font-weight:700;
    color:#2A3039;
    margin-bottom:20px;
`
const Small = styled.div`
    font-size: 12px;
    color:#2D70E2;
    margin-bottom:10px;
    font-weight:600;
`