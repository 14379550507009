import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";

const ChangeChart = ({ data, setSelectedDataPoint }) => {
  const [chart, setChart] = useState(data3.options);
  const [series, setSeries] = useState(data3.series);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);

  const getCompletionChange = (item) => {
    if (item.data.length < 2) {
      return 0;
    }

    let t1 = (item.data[0].completed / item.data[0].total) * 100;
    let t2 = (item.data[1].completed / item.data[1].total) * 100;
    return t1 - t2;
  };

  useEffect(() => {
    if (selected) {
      setSelectedDataPoint(chart.xaxis.categories[selected[0]]);
    }
  }, [selected]);

  useEffect(() => {
    if (data?.length > 0) {
      setLoading(true);
      let sorted_data = data
        .filter((f) => f.data?.length > 0)
        .sort((a, b) => getCompletionChange(a) - getCompletionChange(b));
      let _series = [];
      sorted_data.map((item) => {
        _series.push(getCompletionChange(item));
      });

      let _chart = chart;
      _chart.xaxis.categories = sorted_data.map((item) => item.name);
      _chart.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          setSelected(config.selectedDataPoints[0]);
        },
      };
      setChart(_chart);
      setSeries([{ name: "Scores", data: _series }]);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [data]);

  return (
    <div>
      {loading ? (
        ""
      ) : (
        <>
          <ChartHeader>The difference since the last survey</ChartHeader>
          <Chart options={chart} series={series} type="bar" height={500} />
        </>
      )}
    </div>
  );
};

export default ChangeChart;

const LoadingCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: #7e7e7e;
  min-width: 600px;
  min-height: 400px;
  border-radius: 8px;
`;

const ChartHeader = styled.div`
  color: #2a3039;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss02" on, "ss01" on, "ss11" on, "salt" on, "ss10" on,
    "ss09" on, "ss04" on, "ss03" on, "ss08" on, "ss07" on, "ss05" on, "liga" off;
  /* Head/SemiBold 20 */
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 128%;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [9, 10, 8],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 600,
      height: 400,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        horizontal: true,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    fill: {
      colors: [
        function ({ value, seriesIndex, w }) {
          if (value < 0) {
            return "#F15062";
          } else {
            return "#27CDA7";
          }
        },
      ],
      opacity: 0.5,
    },
    dataLabels: {
      enabled: false,
    },
    noData: {
      text: "Loading chart...",
      align: "center",
      verticalAlign: "middle",
    },
    xaxis: {
      categories: [
        "Apples",
        "Oranges",
        "Strawberries",
        "Pineapples",
        "Mangoes",
        "Bananas",
        "Blackberries",
        "Pears",
        "Watermelons",
        "Cherries",
        "Pomegranates",
        "Tangerines",
        "Papayas",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
        },
      },
    },
    xaxis: {
      title: {
        text: "Change in Engagement %",
      },
    },
    yaxis: {
      min: -50,
      max: 50,
      decimalsInFloat: 0,
      tickAmount: 5,
      title: {
        text: "",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};
