import React from 'react';
import styled from 'styled-components';


const LoadingCard = () => {

    return (
        <Card>
            <T2>Summary</T2>
                <FakeLine width={100}/>
                <FakeLine width={100}/>
                <FakeLine width={80}/>
                <FakeLine width={70}/>
                <FakeLine width={70}/>


            <T2>Key Takeaways</T2>
            <FakeLI ><FakeLine width={40}/></FakeLI>
            <FakeLI  ><FakeLine  width={40}/></FakeLI>
            <FakeLI  ><FakeLine  width={40}/></FakeLI>
            <FakeLI  ><FakeLine  width={40}/></FakeLI>
            <FakeLI  ><FakeLine  width={40}/></FakeLI>

        </Card>
    )
}

export default LoadingCard;

const Card = styled.div`
    max-width:900px;
`

const T2 = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
color: #2A3039;
margin-bottom:10px;
margin-top:20px;
`

const FakeLine = styled.div`
    width:${props => props.width ? props.width : 100}%;
    height:10px;
    background-color:#E5E5E5;
    margin-top:5px;
    border-radius:5px;
`


const FakeLI = styled.li`
  width:100%;
  display:flex;
`