import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import { BasicErrorMessage } from "utilities/ErrorMessages";

import "../setting.css";
import TeamTable from "./TeamTable";
import MemberTable from "./MemberTable";

import {
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_EMPLOYEE_ROLES,
  SG_GET_TEAM_TYPES,
  LOAD_EMPLOYEES,
  LOAD_TEAMS,
} from "constants/actions";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

const MemberDirectory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [organization, setOrganization] = useState({});
  const [teamToolEnabled, setTeamToolEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = React.useState({
    id: 1,
    name: "Member",
  });

  const [userAdmin, setUserAdmin] = useState(false);
  const [userEmployee, setUserEmployee] = useState(false);
  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);
  const [validRoute, setValidRoute] = useState([])
  const [teamUpdate, setTeamUpdate] = useState(true);

  const [changesMade, setChangesMade] = useState(false)

  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );

  const EmployeeId = useSelector(
    (state) => Number(state.auth?.employee_id),
    shallowEqual
  );

  const {
    get_organizations,
    get_auth,
    get_employee,
    get_selectedOrg,
    get_employee_categories,
    get_errors
  } = useSelector(
    (state) => ({
      get_errors: state.errors,
      get_auth: state.auth,
      get_employee: state.employees,
      get_organizations: state.organizations,
      get_selectedOrg: state.selectedOrg,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  // console.log(get_errors)
  // console.log(get_errors?.errors?.request?.response)

  useEffect(() => {
    // Run! Like go get some data from an API.
    // dispatch({ type: SG_GET_MOODS });
    // if (teamUpdate) {
      if (Number(AmbassadorId) > 0) {
        // console.log("Ambassador Lead teams", AmbassadorId)
        const OrgId = get_selectedOrg?.organization?.id;
        if (OrgId) {
          dispatch({
            type: LOAD_EMPLOYEES,
            payload: `organization=${OrgId}`,
          });

          dispatch({
            type: LOAD_TEAMS,
            payload: `organization=${OrgId}`,
          });

        }
      } else {
        // console.log("Lead teams")
        dispatch({ type: LOAD_EMPLOYEES });
        dispatch({ type: LOAD_TEAMS });
      }
    // }

    // setTeamUpdate(false);
  }, [dispatch, AmbassadorId, get_selectedOrg]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      // console.log("Ambassador Lead teams", AmbassadorId)
      const OrgId = get_selectedOrg?.organization?.id;
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `organization=${OrgId}`,
      });

    }
    else if (EmployeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        // payload: `employee=${EmployeeId}`,
      });

    }
    dispatch({
      type: SG_GET_EMPLOYEE_ROLES,
    });
    dispatch({
      type: SG_GET_TEAM_TYPES,
    });
  }, [dispatch, EmployeeId, AmbassadorId, get_selectedOrg]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganization(get_organizations[get_selectedOrg?.organization?.id]);
    } else {
      setOrganization(get_organizations[get_auth?.organization_id]);
    }
  }, [get_organizations, get_selectedOrg, AmbassadorId, get_auth]);

  useEffect(() => {
    if (organization?.services_enabled) {
      setTeamToolEnabled(
        organization?.services_enabled.filter(
          (se) => se?.name.toLowerCase() === "teamtool"
        )?.[0]?.enabled
      );
    }
  }, [organization]);

  function ChangeTab(e) {
    setSelectedTab(e);
    // setLoading(true)
  }
  useEffect(() => {
    setUserAdmin(false);
    if (get_employee) {
      setUserEmployee(get_employee[get_auth?.employee_id]);
      // check for admin
      if (get_employee[get_auth?.employee_id]?.account_type === 5) {
        setUserAdmin(true);
      }
    }
  }, [get_employee, get_auth]);

  useEffect(() => {
    const _Category = get_employee_categories.filter(
      (ec) => ec.employee === Number(get_auth?.employee_id)
    )?.[0]?.category;

    setUserEmployeeCategoryList(
      _Category?.filter((ct) => ct.priority === "primary").map((ct) => ct.value)
    );
  }, [get_employee_categories, get_auth]);

  // console.log(userEmployee)
  useEffect(() => {
    const Found = organization?.services_enabled?.filter(
      srv => srv?.enabled === true
    ).map(sr => sr.name)
    setValidRoute(Found)
  }, [organization]);

  useEffect(() => {
    if (validRoute?.includes("Teamtool")) {
      if (userEmployee?.account_type > 7) {
        if (userEmployee?.role > 7) {
          return navigate("/app/access-denied")
        }
      }
    }
  }, [userEmployee, validRoute, navigate]);





  return (
    <Container>
      <NavContainer>
        <NavTitle>{selectedTab?.name} Management</NavTitle>
        <TabButtons
          onClick={() =>
            ChangeTab({
              id: 1,
              name: "Member",
            })
          }
          selected={selectedTab?.id === 1}
        >
          Member
        </TabButtons>
        {teamToolEnabled && (
          <TabButtons
            selected={selectedTab?.id === 2}
            onClick={() =>
              ChangeTab({
                id: 2,
                name: "Team",
              })
            }
          >
            Team
          </TabButtons>
        )}

      </NavContainer>
      <BasicErrorMessage />
      {selectedTab?.id === 1 ? (
        <MemberTable
          userAdmin={userAdmin}
          userEmployee={userEmployee}
          userEmployeeCategoryList={userEmployeeCategoryList}
          setChangesMade={setChangesMade}
          changesMade={changesMade}
          teamUpdate={teamUpdate}
          setTeamUpdate={setTeamUpdate}
        />
      ) : (
        <TeamTable
          userAdmin={userAdmin}
          userEmployee={userEmployee}

        />
      )}
    </Container>
  );
};

export default MemberDirectory;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
`;

const NavContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #d3dbdb;
`;

const NavTitle = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
`;

const SearchContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  margin-top: 30px;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const P = styled.p`
  font-size: 15px;
  line-height: 1.4;
  font-family: "Red Hat Display", sans-serif;
  margin-top: 20px;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TabButtons = styled.div`
  color: white;
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 6px 16px 6px 16px;
  background-color: ${(props) => (props.selected ? "#2d50e2" : "white")};
  color: ${(props) => (props.selected ? "white" : "#2d50e2")};
  border: 1px solid #2d50e2;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background-color: #2d50e2;
    color: white;
  }
`;

const Container = styled.div`
  min-height: 90vh;
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const TopButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
