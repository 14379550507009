import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown, Loader } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SG_GET_REPORT_TEMPLATE } from "constants/actions";
import SurveyVersions from "reports/Audit/VersionControl/index";

const benchmarkOptions = [
  { key: 0, text: "Organization Averages", value: "Organization Averages" },
  { key: 1, text: "Global Benchmarks", value: "Global Benchmarks" },
];

const FactorSelection = (props) => {
  const dispatch = useDispatch();
  const { setFactor, factor, setBenchmark, benchmark } = props;
  const [selectedBenchmark, setSelectedBenchmark] = useState(
    benchmark?.value || "Organization Averages"
  );
  const [originalOptionsList, setOriginalOptionsList] = useState([]);

  const [selectedFactor, setSelectedFactor] = useState(factor || []);
  const [loading, setLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const surveyVersion = useSelector(
    (state) => state.audit?.survey_version,
    shallowEqual
  );

    useEffect(() => {
      setBenchmark("Organization Averages");
    }, []);
    
  useEffect(() => {
    if (surveyVersion) {
      let _options = [
        ...surveyVersion?.questions.dimensions.flatMap((item, index) =>
          item.factors.map((factor, i) => ({
            key:
              index *
                (surveyVersion?.questions.dimensions[index - 1]
                  ? surveyVersion?.questions.dimensions[index - 1].factors
                      .length
                  : 0) +
              i,
            text: factor.title,
            value: {
              title: factor.title,
              type: "pillar",
              factor,
              dimension: index,
            },
          }))
        ),
      ];
      setDropdownOptions(_options);
      setLoading(false);
    }
  }, [surveyVersion]);

  useEffect(() => {
    if (selectedBenchmark === "Outcome Analysis") {
      setDropdownOptions(
        originalOptionsList.filter((f) => f.value.type === "outcomes")
      );
    }
    if (selectedBenchmark === "Culture Factor Report") {
      setDropdownOptions(
        originalOptionsList.filter((f) => f.value.type === "pillar")
      );
    }
  }, [selectedBenchmark]);

  useEffect(() => {
    dispatch({
      type: SG_GET_REPORT_TEMPLATE,
    });
  }, [dispatch]);

  return (
    <StepContainer>
      <SurveyVersions hidden={true} />

      {loading && <Loader />}

      <StepTitle>Benchmark</StepTitle>
      <Label>
        Select a benchmark to compare the culture scores to in the
        recommendations.
      </Label>
      <Dropdown
        placeholder="Benchmark selection"
        fluid
        selection
        value={selectedBenchmark}
        onChange={(e, { value }) => {
            setBenchmark(value);
            setSelectedBenchmark(value);
        }}
        options={benchmarkOptions}
      />
      {dropdownOptions && (
        <FactorSection>
          <StepTitle>Culture Factor</StepTitle>
          <Label>
            Choose one or more culture factors to create a recommendation for.
            The report will contain recommendations and summaries for each of
            the culture factors you choose.
          </Label>
          <Dropdown
            placeholder="Areas of focus"
            fluid
            selection
            value={selectedFactor?.[0]}
            onChange={(e, { value }) => {
              setSelectedFactor([value]);
              setFactor([value]);
            }}
            options={dropdownOptions}
          />
        </FactorSection>
      )}
    </StepContainer>
  );
};

const StepContainer = styled.div``;

const StepTitle = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const FactorSection = styled.div`
  margin-top: 20px;
`;

const Label = styled.div``;

export default FactorSelection;
