import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Icon } from "semantic-ui-react";
import styled, { keyframes } from "styled-components";
import PercentileBubble from "./Bubble";
import TeamBreakDown from "./teamBreakdown";
import User from 'assets/images/empty/user.svg'
import TeamAvatar from 'assets/images/team.svg'
import { Link } from "react-router-dom";
import AvatarScore from './AvatarScore'
import TraitInfo from './TraitInfo'

const loading=keyframes`
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }`


const Trait = styled.div`
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 95%;
  position:relative;

  @media(max-width:768px){
   flex-direction:column;
  }
`;

const Definition = styled.div`
  width: 280px;
  display:flex;
  height:60px;
  align-items:center;
  padding-left:20px;

  @media(max-width:768px){
    width:100%;
    padding:20px;
  }
`;

const Percentile = styled.div`
  width: calc(100% -  280px);
  border-radius: 8px;
  background-color: #f8f8f8;
  margin: 7px 8px 7px 0px;
  color: #4f00bf;
  height:50px;
  padding:0px 20px;


   @media(max-width:768px){
    width:100%;
    padding:20px;
    padding-bottom:80px;
  }
`;

const PercentileLoading = styled.div`
  width: 60%;
  border-radius: 8px;
   color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: ${loading} 1.2s ease-in-out infinite;
  margin: 7px 8px 7px 0px;
  color: #4f00bf;
  padding: 50px 20px 80px 36px;
  height:350px;

   @media(max-width:768px){
    width:100%;
    padding:20px;
    padding-bottom:80px;
  }
`;

const Highlight = styled.span`
  color: #4f00bf;
`;

const Paragraph = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 30px;

  @media(max-width:768px){
    margin-bottom:10px;
  }
`;
const Paragraph2 = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #9b9b9b;
`;

const Bar = styled.div`
  height: 12px;
  border-radius: 8px;
  background-color: #e9e9e9;
  width: 100%;
  position: relative;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #545454;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;



const Team = styled.div`
  height: 50px;
  width: 50px;
  background-color: #6a00ff;
  border-radius: 25px;
  position: absolute;
  left: ${(props) => "calc(" + props.position + "% - 25px)"};
  z-index: 2;
`;




const Text7 = styled.div`
  font-size: 18px;
  font-weight:500;
  font-family: "Red Hat Display", sans-serif;

`;
const Text8 = styled.h1`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  color: black;
`;

const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #6a00ff;
  padding-left: 5px;
  cursor: pointer;

  @media (max-width:768px){
    display:none;
  }
`;

const MobileLink = styled.div`
  color: #6a00ff;
  display:flex;
  width:100%;
  justify-content:flex-end;
@media (min-width:768px){
  display:none;
}
`

const ReadMobile = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 16px;
  padding-left: 5px;
  cursor: pointer;

  &:hover{
    text-decoration:underline;
  }
`;

const More = styled.div`
  height:60px;
  position:absolute;
  right:-60px;
  display:flex;
  align-items:center;
  justify-content:center;
  cursor:pointer;

  @media(max-width:768px){
    display:none;

  }
`

const Expanded = styled.div`
  flex-direction:column;
  padding:${props=>props.expanded?'30px 50px 30px 0px':0};
  transition: all 0.5s;
  max-height:${props=>props.expanded?'1000px':'0px'};
  width:calc(100% - 280px);
  margin-left:280px;
  overflow:hidden;

  @media(max-width:768px){
    width:100%;
    margin-left:0px;
    padding:${props=>props.expanded?'30px 0px 30px 0px':0};
  }
`

const Anchor = styled.span`
position:absolute;
left:-60px;

@media(max-width:768px){
  display:none;
}
`

const Anchor2 = styled.span`
position:absolute;
right:-60px;
@media(max-width:768px){
  display:none;
}
`

const DefPicture =
  "https://storage.googleapis.com/charactercode-a247a.appspot.com/logos/1/11/pic1596039683.png";

const RelativeTeam = (p1, p2, trait, trait_data) => {
  let diff = Math.abs(p1 - p2);
  let positive = p1 > p2;
  let word = "";

  if(diff < 1){
    return "the same in "+ trait_data[trait].less
  }

  if (diff < 20) {
    word += "slightly";
  } else if (diff < 40) {
    word += "moderately";
  } else {
    word += "significantly";
  }

  if (positive) {
    word += " higher in ";
  } else {
    word += " lower in ";
  }

  return word + " " + trait_data[trait].less;
};

const getPercentile = (s) => {
  let mod = s % 10;
  if (mod === 0 || mod > 3 || s==12) {
    return s + "th";
  } else if (mod === 1) {
    return s + "st";
  } else if (mod === 2) {
    return s + "nd";
  } else if (mod === 3) {
    return s + "rd";
  }
};

const PersonalityTraitList = [
  {
    id: 1,
    name: "Extraversion",
    key: "extraversion",
    color:'#c73d88'
  },
  {
    id: 2,
    name: "Conscientiousness",
    key: "conscientiousness",
    color:'#ffb812'
  },
  {
    id: 3,
    name: "Agreeableness",
    key: "agreeableness",
     color:'#3f448c',
  },
  {
    id: 4,
    name: "Emotionality",
    key: "er",
    color:'#6a00ff'
  },
  {
    id: 5,
    name: "Openess to Experience",
    key: "openess",
     color:'#d9008a'
  },
];

const TraitCard = (props) => {
  const { personalityData } = props;
  const [traitData, setTraitData] = React.useState(false);
  const [employee1, setEmployee1] = React.useState([]);
  const [employee2, setEmployee2] = React.useState([]);
  const [score1, setScore1] = React.useState([]);
  const [score2, setScore2] = React.useState([]);
  const { id1, id2, profiles, keyword, average, employee } = props;
  const [expand,setExpand] = React.useState(false)


  useEffect(() => {
    if (id1) {
      setEmployee1(profiles.find(({ employee }) => employee === id1));
      // console.log(profiles.find(({ employee }) => employee === id1))
    }

    if (id2) {
      setEmployee2(profiles.find(({ employee }) => employee === id2));
    } else {
      setEmployee2({ name: "Team Avg", pic: null });
    }
  }, [profiles, id1, id2]);

  const { get_trait_data } = useSelector(
    (state) => ({
      get_trait_data: state.personality.trait_data,
    }),
    shallowEqual
  );
  useEffect(() => {
    setTraitData(get_trait_data);

  }, [get_trait_data]);

  useEffect(() => {
    if (employee1) {
      setScore1(Math.floor(employee1[keyword]));
    }
  }, [employee1, keyword]);

  useEffect(() => {
    if (employee2 && id2) {
      setScore2(Math.floor(employee2[keyword]));
    } else {
      setScore2(average);
    }
  }, [employee2, average, keyword,id2]);

  // console.log(employee1)
  // console.log(employee2)

  const getMagnitude=()=>{
     return 'big'
   }

   const getEmployeeName =(id) =>{
     if(personalityData){
        let _data = personalityData.filter(f => f.employee === id)[0]
      if(_data.name!=="None None"){
        return _data.name
      }
      return _data.email.split("@")[0]
     }
     return ""
   }


   const getSimilar = (trait) =>{
      // Find the employee in this category with the most similar score
      let data1 = parseFloat(personalityData.filter((p) => p.employee == props.id1)[0][trait])
      let data2 = personalityData.filter((p) => p.employee != props.id1).map(d=>{
          let data = d[trait]
          return {'id':d.employee,'diff':Math.abs(data1 - data)}

      })

      let _id2 = data2.reduce(function(prev,curr){
        return prev.diff < curr.diff ? prev : curr
      }).id

      let current_employee = personalityData.filter(p=>p.employee == _id2)[0]
      let _employee2 = {'name':current_employee.name,'pic':current_employee.pic}
      let _score2 = Math.floor(parseFloat(current_employee[trait]))

       let width = Math.abs(score1 - _score2);
      let screen = window.innerWidth 
      let diff = screen>768? 13: 20


        if (width < diff) {
          return (
              <AvatarScore
                type={1}
                employee1={employee1}
                employee2={_employee2}
                score1={score1}
                score2={_score2}
                User={User}
                width={width}
                color={props.color}
                id2={_id2}/> )
        } else {
          return (
           <AvatarScore
                type={2}
                employee1={employee1}
                employee2={_employee2}
                score1={score1}
                score2={_score2}
                User={User}
                width={width}
                id2={_id2}
                color={props.color}/> 
          );
        }

   }

    const getLeast = (trait) =>{
      // Find the employee in this category with the most similar score
      let data1 = parseFloat(personalityData.filter((p) => p.employee == props.id1)[0][trait])
      let data2 = personalityData.filter((p) => p.employee != props.id1).map(d=>{
          let data = d[trait]
          return {'id':d.employee,'diff':Math.abs(data1 - data)}

      })

      let _id2 = data2.reduce(function(prev,curr){
        return prev.diff > curr.diff ? prev : curr
      }).id

      let current_employee = personalityData.filter(p=>p.employee == _id2)[0]
      let _employee2 = {'name':current_employee.name,'pic':current_employee.pic}
      let _score2 = Math.floor(parseFloat(current_employee[trait]))

       let width = Math.abs(score1 - _score2);
      let screen = window.innerWidth 
      let diff = screen>768? 13: 20


        if (width < diff) {
          return (
              <AvatarScore
                type={1}
                employee1={employee1}
                employee2={_employee2}
                score1={score1}
                score2={_score2}
                User={User}
                width={width}
                color={props.color}
                id2={_id2}/> )
        } else {
          return (
           <AvatarScore
                type={2}
                employee1={employee1}
                employee2={_employee2}
                score1={score1}
                score2={_score2}
                User={User}
                width={width}
                id2={_id2}
                color={props.color}/> 
          );
        }



   }




  const getPosition = () => {

  if(props.id2=="Similar" && personalityData.length>1){
    let trait = PersonalityTraitList.filter(f=>f.name==props.type)[0].key
    return getSimilar(trait)
  }
  if(props.id2=="Least" && personalityData.length>1){
    let trait = PersonalityTraitList.filter(f=>f.name==props.type)[0].key
    return getLeast(trait)
  }

    let width = Math.abs(score1 - score2);
    let screen = window.innerWidth 
   let diff = screen>768? 13: 20

    if (width < diff) {
      return (
          <AvatarScore
            type={1}
            employee1={employee1}
            employee2={employee2}
            score1={score1}
            score2={score2}
            User={User}
            width={width}
            color={props.color}
            id2={id2}/> )
    } else {
      return (
       <AvatarScore
            type={2}
            employee1={employee1}
            employee2={employee2}
            score1={score1}
            score2={score2}
            User={User}
            width={width}
            id2={id2}
            color={props.color}/> 
      );
    }
  };

  if(props.loading){
    return (
       <Trait>
          <Definition/>
          <Percentile/>

        </Trait>
      )
  }

  return (
    <>
    <Trait>
      <Definition>
        <Text7>{props.type}</Text7>

      </Definition>

      <Percentile>
        {/*<Text8>
          {employee==id1? 'You are':getEmployeeName(id1)+' is'} in the{" "}
          <Highlight>{getPercentile(score1)} Percentile</Highlight>
        </Text8>*/}
        {/*{traitData[props.type] ? (
          <Paragraph2>
            {employee==id1? 'You are':getEmployeeName(id1)+' is'} {traitData[props.type].verb} than&nbsp;
            {score1 - 1 > 0 ? score1 - 1 : 0} out of 100 people and {employee==id1? 'you are':getEmployeeName(id1)+' is'}
            &nbsp;{RelativeTeam(score1, score2, props.type, traitData)} than {id2? getEmployeeName(id2) : "the average person on your team" }
          </Paragraph2>
        ) : (
          ""
        )}*/}

        <Bar>
         <Anchor>Low</Anchor>
         <Anchor2>High</Anchor2>
         {/* <span style={{ position: "absolute", left: 0, top: 30 }}>0</span>
          <span style={{ position: "absolute", left: 0, top: 50 }}>Low</span>
          <span style={{ position: "absolute", right: 0, top: 30 }}>100</span>
          <span style={{ position: "absolute", right: 0, top: 50 }}>High</span>*/}
          {getPosition()}
        </Bar>
      </Percentile>

      <More onClick={()=>setExpand(!expand)}>
        <Icon name={expand?'chevron up':'chevron down'} />
      </More>

     

      <MobileLink onClick={()=>setExpand(!expand)}>
        {expand?'Less':'More'} details<Icon name={expand?'chevron up':'chevron down'} />
      </MobileLink>
    </Trait>

     <Expanded expanded={expand}>
        <TraitInfo data={traitData[props.type]} trait={props.type} sub={false}/>

        <Text8>
          {employee==id1? 'You are':getEmployeeName(id1)+' is'} in the{" "}
          <Highlight>{getPercentile(score1)} Percentile</Highlight>
        </Text8>

        <TeamBreakDown
            traitData={get_trait_data}
            userID={id1}
            personalityData={personalityData}
            type={props.type}
             keyword={keyword}
            trigger={
              <ReadMobile>
                See {props.type} team breakdown >
              </ReadMobile>
            }
          />


        
      </Expanded>

    </>
  );
};

export default TraitCard;
