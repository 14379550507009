import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress } from "semantic-ui-react";
import Chart from "react-apexcharts";
import Insight from "./Insight";

const Overview = () => {
	return (
		<Container>
			<Card>
				<H3>DEI Index</H3>
				<div style={{ position: "relative" }}>
					<Chart
						options={data.options}
						series={data.series}
						type="radialBar"
						height={250}
					/>
					<Values>
						<Stat>86%</Stat>
					</Values>
				</div>
				<TextArea>
					<Text>
						Your DEI score is <Change> Great</Change>
					</Text>
				</TextArea>
			</Card>

			<Grid>
				<Area>
					<Icon
						name="assistive listening systems"
						style={{ marginRight: 10 }}
					/>
					<div>
						<Title>Inclusion</Title>
						<Score>88%</Score>
					</div>
				</Area>
				<Area>
					<Icon name="id badge" style={{ marginRight: 10 }} />
					<div>
						<Title>Representation</Title>
						<Score>88%</Score>
					</div>
				</Area>
				<Area>
					<Icon name="dollar" style={{ marginRight: 10 }} />
					<div>
						<Title>Compensation</Title>
						<Score>88%</Score>
					</div>
				</Area>
				<Area>
					<Icon name="signal" style={{ marginRight: 10 }} />
					<div>
						<Title>Advancement</Title>
						<Score>88%</Score>
					</div>
				</Area>
				<Area>
					<Icon name="heart" style={{ marginRight: 10 }} />
					<div>
						<Title>Retention</Title>
						<Score>88%</Score>
					</div>
				</Area>
				<Area>
					<Icon name="thumbs up" style={{ marginRight: 10 }} />
					<div>
						<Title>Recruitment</Title>
						<Score>88%</Score>
					</div>
				</Area>
			</Grid>

			<Insights>
				<Insight
					title={"Insight #1"}
					description={
						"Inclusion scores have increased on average by +5% this quarter in the Halifax location"
					}
				/>
				<Insight
					title={"Insight #2"}
					description={
						"Decrease in Rentention scores in Black Females"
					}
				/>
				<Insight
					title={"Insight #3"}
					description={
						"Representation scores have trended up for the last 4 quarters"
					}
				/>
				<Insight
					title={"Insight #4"}
					description={
						"Inclusion scores have increased on average by +5% this quarter in the Halifax location"
					}
				/>
				<Insight
					title={"Insight #5"}
					description={
						"Decrease in Rentention scores in Black Females"
					}
				/>
				<Insight
					title={"Insight #6"}
					description={
						"Representation scores have trended up for the last 4 quarters"
					}
				/>
			</Insights>
		</Container>
	);
};

export default Overview;

const Container = styled.div`
	font-family: "Barlow", sans-serif;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;

const Grid = styled.div`
	width: calc(100% - 300px);
	display: flex;
	flex-wrap: wrap;
	height: 200px;
`;

const Insights = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
`;

const Area = styled.div`
	width: 33%;
	height: 50px;
	display: flex;
`;

const Score = styled.div``;

const ChartDiv = styled.div`
	width: 600px;
	height: 550px;
	padding: 20px;
	border: 1px solid lightgrey;
`;

const Title = styled.div`
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 10px;
`;

const Description = styled.div`
	margin-top: 20px;
	margin-bottom: 10px;
`;

const H3 = styled.div`
	font-size: 22px;
	font-weight: bold;
`;

const Change = styled.span`
	font-size: 16px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
	color: #ab710a;
	margin-left: 3px;
`;

const Card = styled.div`
	padding: 30px 10px;
	position: relative;
	width: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: white;
`;

const Values = styled.div`
	position: absolute;
	width: 50px;
	height: 50px;
	left: calc(50% - 25px);
	top: calc(50% - 25px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const Stat = styled.div`
	font-size: 36px;
	font-family: "Red Hat Display", sans-serif;
	font-weight: bold;
`;

const TextArea = styled.div`
	width: 100%;
	padding: 10px;
`;
const Text = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`;

const data = {
	series: [86],
	options: {
		chart: {
			height: 250,
			type: "radialBar",
		},
		grid: {
			padding: {
				right: 0,
				top: 0,
				left: 0,
				bottom: 0,
			},
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: "65%",
				},
				track: {
					background: "white",
				},
				dataLabels: {
					show: false,
					name: { show: false },
					value: { show: false },
				},
			},
		},
		fill: {
			colors: ["#ab710a"],
		},
	},
};
