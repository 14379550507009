import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import {generate_milestones,generate_behaviors} from "../Request"
import Loading from './Loading'

const Behaviors = ({text,confirm}) => {
    const [response,setResponse] = useState([])
    const [loading,setLoading] = useState(true)

    const ask_question = async () =>{

    
          const prompt = `
          We've created a recommendation to improve culture in an organization. 
          That plan is represented in JSON format below:

            ${text}

          Ignore the JSON structure for now, and focus on the contents of the plan.
          
         What are the behaviors that would be associated with acheiving success with this plan? Please list 2-4 behaviors that would be associated with this plan.
          
         Behaviors are the actions or situations in which people chose to behave associated with successful improvements in the underlying issue that this plan addresses.
         They are the actions that would be taken by the people involved in this plan.
        
            Never generate more than 4 behaviors, and never generate less than 2 behaviors. Never lebel the behaviors.

            You should return a list of strings, each string should be a behavior associated with this plan. Here's an example:
            ['Team members acknowledge each other in team meetings and spend time building meaningful connections.','You engage with employees when they dont uphold the values of your organization.','Your team members spend a small portion of their time in conversation with each other.']
          `;
           
            async function someFunction(prompt) {
            const message = await generate_behaviors(prompt);

            setResponse(JSON.parse(message));
            setLoading(false)
            }

        someFunction(prompt)
    }


    useEffect(()=>{
        if(text){
            ask_question()
        }
        
    },[text])

    return (
        <Container>
           <Content>
                <Title>What does success look like?</Title>
                <Description>
                    How do you know your plan is working and on track?
                    Here is a behavior checklist that will help you track your progress.
                </Description>

                {loading && <LoadingText>Generating your plans behavior checklist...</LoadingText>}

                {loading? <Loading/>:
                <>
                    <Title>
                        Behavior Checklist
                    </Title>

                    <ul>
                    {response.map((item,index)=>{
                        return <li>{item}</li>
                        })}
                    </ul>
                </>}

            <ButtonContainer>
               
              

                <Button onClick={()=>confirm()}>Setup Progress Tracking</Button>

            </ButtonContainer>
            </Content>
        </Container>
        )

}

export default Behaviors;


const Container = styled.div`
    width:100%;
font-family:'Raleway';
display:flex;
flex-direction:column;
align-items:center;
margin-top:40px;
`

const Content = styled.div`
    width:600px;
`

const Title = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height:1.2;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`

const Description = styled.div`
    margin-bottom: 40px;
`

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const Button2 = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const LoadingText = styled.div`
    margin-bottom:20px;
    font-size:14px;
    font-weight:bold;
    
`