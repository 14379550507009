import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import { SG_GET_STORY_TEMPLATES, GET_STORY_TEMPLATES } from "constants/actions";

function* loadTemplates(action) {
  try {
    console.log("loadTemplates", action.payload);
    const json = yield call(api.GET_STORY_TEMPLATES, action.payload);
    yield put({ type: GET_STORY_TEMPLATES, payload: json.data });
  } catch (e) {
    console.log(e);
  }
}

export function* templateLoad() {
  yield takeLatest(SG_GET_STORY_TEMPLATES, loadTemplates);
}

export default function* index() {
  yield all([templateLoad()]);
}
