// Summary.jsx
import React, { useState, useEffect } from 'react';
import { parseMarkdown } from './MarkdownParser';
import styled from 'styled-components';
import Logo from 'assets/images/logo_full.png';
import {Loader} from 'semantic-ui-react'

const SummaryContainer = styled.div`
  /* Container for the entire summary */
  font-family: Raleway, sans-serif;
`;

const PagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Page = styled.div`
  flex: 1;
  margin: 10px;
  padding: 60px 40px;
  border: 1px solid #ccc;
  width:600px;
  height: 800px; /* Set a fixed height for the page */
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 10px;
    position:relative;
`;

const SlideFooter = styled.div`
    position:absolute;
    bottom:0;
    font-size: 12px;
    width:calc(100% - 60px);
    padding:10px 0px;
    border-top:1px solid #e0e0e0;
    display:flex;
`

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Chevron = styled.span`
  font-size: 24px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  margin: 0 20px;
`;

const PaginationText = styled.span`
  font-size: 16px;
`;

/* Styled components for the title and subtitle */
const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: -30px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color#B3B3B3;
  margin-bottom: 30px;
`;

const FooterImg = styled.img`
    width: 75px;
`

const OrgLogo = styled.img`
    width: 200px;
    margin-bottom: 10px;
     margin-top: -30px;
`

const CustomPaginationControls = ({
  currentPageIndex,
  totalPages,
  onPrev,
  onNext,
}) => {
  const pageNumber = currentPageIndex + 1;

  return (
    <PaginationContainer>
      <Chevron
        disabled={currentPageIndex === 0}
        onClick={() => {
          if (currentPageIndex > 0) {
            onPrev();
          }
        }}
      >
        &#x25C0; {/* Left chevron symbol */}
      </Chevron>
      <PaginationText>
        Page {pageNumber} of {totalPages}
      </PaginationText>
      <Chevron
        disabled={currentPageIndex >= totalPages - 1}
        onClick={() => {
          if (currentPageIndex < totalPages - 1) {
            onNext();
          }
        }}
      >
        &#x25B6; {/* Right chevron symbol */}
      </Chevron>
    </PaginationContainer>
  );
};

const Summary = ({ message, planFor, orgName,language }) => {
  const MAX_CHARS_PER_PAGE = 1400; // Adjust this value as needed
  const [pages, setPages] = useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const currentDate = new Date().toLocaleDateString();

  useEffect(() => {
    // Split the message into blocks
    const blocks = splitMarkdownIntoBlocks(message);

    // Group blocks into pages
    const newPages = groupBlocksIntoPages(blocks, MAX_CHARS_PER_PAGE);

    setPages(newPages);
  }, [message]);

  useEffect(() => {
    setCurrentPageIndex(0);
    }, [language]);

  const handlePrev = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentPageIndex < pages.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  // Get the current page content
  const currentPageBlocks = pages[currentPageIndex] || [];
  const currentPageContent = currentPageBlocks.join('\n\n');

  const getTitle = () =>{
    if(orgName === 'Trendyol'){
        return <OrgLogo src="https://firebasestorage.googleapis.com/v0/b/charactercode-a247a.appspot.com/o/email%2FTrendyol_online.png?alt=media&token=6bd6c708-ea22-4a8c-b619-8ab0b64e8b85" />
    }
    return <Title>{orgName}</Title>

  }

  return (
    <SummaryContainer>
      <PagesWrapper>
        <Page>

     
          {currentPageIndex === 0 && (
            <>
              {getTitle()}
              <Subtitle>
                {LanguageTranslations[language]} {planFor} - {currentDate}
              </Subtitle>
            </>
          )}

          {message.length == 0 && <Loader active inline='centered' />}
          {parseMarkdown(currentPageContent)}

          <SlideFooter><FooterImg src={Logo} /></SlideFooter>
        </Page>
      </PagesWrapper>

      {pages.length > 1 && (
        <CustomPaginationControls
          currentPageIndex={currentPageIndex}
          totalPages={pages.length}
          onPrev={handlePrev}
          onNext={handleNext}
        />
      )}
    </SummaryContainer>
  );
};

export default Summary;

// Include the updated utility functions
const splitMarkdownIntoBlocks = (markdown) => {
    const lines = markdown.split('\n');
    return lines;
  };
  
  const groupBlocksIntoPages = (blocks, maxCharsPerPage) => {
    const pages = [];
    let currentPage = [];
    let currentCharCount = 0;
  
    blocks.forEach((block) => {
      const blockLength = block.length;
  
      if (currentCharCount + blockLength <= maxCharsPerPage) {
        currentPage.push(block);
        currentCharCount += blockLength + 1; // +1 for the newline character
      } else {
        // Start a new page
        pages.push(currentPage);
        currentPage = [block];
        currentCharCount = blockLength + 1; // +1 for the newline character
      }
    });
  
    // Add the last page if any
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }
  
    return pages;
  };



  const LanguageTranslations = {
    'English':'Plan generated for',
    'Turkish':'Plan oluşturuldu',
  }