import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import {Table} from 'semantic-ui-react'
import Back from 'ProtoType/SimpleDash/Back'

const Entry = () =>{

    return (
        <Container>
            <Center>
                <Back url={-1} location={"data management"}/>
                <Title>Data entry 1</Title>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Location</Table.HeaderCell>
                            <Table.HeaderCell>Customer satisfaction</Table.HeaderCell>
                            <Table.HeaderCell>Customer rating</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>1/1/2020</Table.Cell>
                            <Table.Cell>Store 1</Table.Cell>
                            <Table.Cell>4.5</Table.Cell>
                            <Table.Cell>4.5</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>1/1/2020</Table.Cell>
                            <Table.Cell>Store 2</Table.Cell>
                            <Table.Cell>4.5</Table.Cell>
                            <Table.Cell>4.5</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>1/1/2020</Table.Cell>
                            <Table.Cell>Store 3</Table.Cell>
                            <Table.Cell>4.5</Table.Cell>
                            <Table.Cell>4.5</Table.Cell>
                        </Table.Row>
                        </Table.Body>
                </Table>
            </Center>
           
        </Container>
    )
}


export default Entry


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:800px;
    `

const Title = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:20px;
`