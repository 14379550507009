import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import {Dropdown} from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom';

const PlanDetails = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <Center>
                <Title>Culture Factor Plan</Title>
                
                <T2>
                    Plan Template
                </T2>

                <Description>
                    Your selected data story template will be applied to all users with access to this area.
                </Description>

                <Dropdown
                    placeholder='Select a template'
                    fluid
                    selection
                    options={friendOptions}
                />
                
                <div style={{marginBottom:30}}/>
                <T2>
                    For all users with access to 
                </T2>

                <Description>
                    Your selected data story template will be applied to all users with access to this area.
                </Description>

                <Dropdown
                    placeholder='Select a category'
                    fluid
                    selection
                    options={friendOptions}
                />

                <div style={{marginBottom:30}}/>
                <T2>
                    Number of plans
                </T2>

                <Description>
                    How many plans would you like to generate for each user with access to this area?
                </Description>

                <Dropdown
                    placeholder='Select number of plans'
                    fluid
                    selection
                    options={friendOptions}
                />

                <div style={{marginBottom:30}}/>
                <T2>
                    Factor Selection
                </T2>

                <Description>
                    How would you like to select the culture factors for each plan?
                </Description>

                <Dropdown
                    placeholder='Select a selection method'
                    fluid
                    selection
                    options={factorOptions}
                />

            <ButtonContainer>
                <Button onClick={() => navigate('/simple/prototype/rules')}>Save</Button>
            </ButtonContainer>
            </Center>
        </Container>
    )
}

export default PlanDetails

const Container = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    font-family:'Raleway';
    min-height:100vh;
`


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Center = styled.div`
    width:800px;
    margin-top:20px;
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:30px;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
`

const Description = styled.div`
    font-size:14px;
    font-weight:400;
    margin-bottom:20px;
`


const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-top:20px;
    font-size:14px;
    font-weight:500;
    transition: all 0.3s ease;
    

    &:hover{
        transform: scale(1.05); 
    }
`
const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`


const friendOptions = [
    {
      key: 'Jenny Hess',
      text: 'Jenny Hess',
      value: 'Jenny Hess',
    },
    {
      key: 'Elliot Fu',
      text: 'Elliot Fu',
      value: 'Elliot Fu',
    },
    {
      key: 'Stevie Feliciano',
      text: 'Stevie Feliciano',
      value: 'Stevie Feliciano',
    },
    {
      key: 'Christian',
      text: 'Christian',
      value: 'Christian',
    },
    {
      key: 'Matt',
      text: 'Matt',
      value: 'Matt',
    },
    {
      key: 'Justen Kitsune',
      text: 'Justen Kitsune',
      value: 'Justen Kitsune',
    },
  ]
  

  const factorOptions = [
    {
      key: 0,
      text: 'Lowest Factor Average Scores',
      value: 0,
    },
    {
        key: 1,
        text: 'Lowest Factor Benchmark Scores',
        value: 1,
      },
      {
        key: 2,
        text: 'Lowest Organization Average Scores',
        value: 2,
      },
      {
        key: 3,
        text: 'Lowest Organization Benchmark Scores',
        value: 3,
      },
      {
        key: 4,
        text: 'Custom',
        value: 4,
      },
  ]
  