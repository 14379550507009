
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Grid, Icon, Modal, Button, Image, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  AllErrors,
  DismissableSuccess,
  DismissableError,
  ToastMessage,
} from "utilities/Messages";

import {
  SG_DELETE_LEADERS_IN_SCHEDULE,
  SG_ADD_LEADERS_IN_SCHEDULE,
  LOAD_EMPLOYEES,
  SG_GET_GLOBAL_SCHEDULES,
  SG_GET_LEADERS_IN_SCHEDULES,
  SG_GET_LEADER_SURVEY_PARTICIPANTS,
} from "constants/actions";

import {
  P2,
  Title,
  Line,
  OptionCard,
  LeaderContainer
} from "./styled_list";

import {Selector, LeaderCard} from "./Settings"

const LeaderList = () => {
    const dispatch = useDispatch();
    const [addLeader, setAddLeader] = useState(false);
    const [removeLeader, setRemoveLeader] = useState(false);
    const [option1, setOption1] = React.useState(true);
    const [option2, setOption2] = React.useState(true);
    const [leaders, setLeaders] = React.useState([])
    const [leaderInSchedule, setLeaderInSchedule] = React.useState([])
    const [organizationId, setOrganizationId] = useState(null);
    const [leaderParticipants, setLeaderParticipants] = useState(null);
    const [leadership, setLeadership] = useState([]);
  
    useEffect(() => {
        // if the response is ok 200
        dispatch({ type: LOAD_EMPLOYEES, payload: {} });
        dispatch({ type: SG_GET_LEADER_SURVEY_PARTICIPANTS, payload: {} });
        dispatch({
            type: SG_GET_GLOBAL_SCHEDULES,
          });
          dispatch({
            type: SG_GET_LEADERS_IN_SCHEDULES,
          });
      }, [dispatch]);
    
      const { get_employees, 
              get_global_schedules, 
              get_auth,
              get_leaders_in_schedule,
            get_leader_survey_participants } = useSelector(
        (state) => ({
            get_employees: state.employees.employees,
            get_global_schedules: state.surveyschedule.global_schedules,
            get_auth: state.auth,
            get_leaders_in_schedule: state.leadersinschedule.leaders_in_schedules,
            get_leader_survey_participants: state.leadersurveyparticipants.leader_survey_participants,
        }),
        shallowEqual
      );

      useEffect(() => {
        setLeaderInSchedule(get_leaders_in_schedule)
        setOrganizationId(Number(get_auth?.organization_id));
        setLeadership(get_global_schedules);
        setLeaderParticipants(get_leader_survey_participants)
    }, [get_global_schedules, get_auth, get_leaders_in_schedule,
        get_leader_survey_participants]);

    function AddRemoveLeader(id, leaderId) {
      if (id) {
        dispatch({
          type: SG_DELETE_LEADERS_IN_SCHEDULE,
          payload: {
            id: id,
          },
        });
        setRemoveLeader(true);
        setAddLeader(false);
      } else {
        dispatch({
          type: SG_ADD_LEADERS_IN_SCHEDULE,
          payload: {
            global_schedule: leadership.id,
            employee: leaderId,
            organization: organizationId,
          },
        });
        setAddLeader(true);
        setRemoveLeader(false);
      }
    }

      useEffect(() => {
          if (get_employees){
              setLeaders(get_employees.filter(fl => fl.role > 1))
          }
		
    },[get_employees])
    
    return (
      <LeaderContainer>
        <Title> <Link  to="/survey_settings">
                    <Icon  name="arrow left" size={"small"} 
                    style={{ color: "#4a4a4a", marginLeft: "-20px" }} />
                  </Link>
                  Leadership Tracker Settings</Title>
        <OptionCard>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Selector selected={option1} onClick={() => setOption1(!option1)} />
            <P2>
              Answers to reports will come in 48 hours after the report has been
              sent out to promote and protect anonymity among your team.
            </P2>
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
            <Selector selected={option2} onClick={() => setOption2(!option2)} />
            <P2>
              Answers to reports will come in after 100% of your team has
              responded .
            </P2>
          </div>
        </OptionCard>
        {addLeader ? (
          <DismissableSuccess
            header={"Success"}
            color={"green"}
            visible={addLeader}
            message={"Successfuly added leader to survey"}
          />
        ) : (
          ""
        )}
        {removeLeader ? (
          <DismissableSuccess
            header={"Success"}
            visible={removeLeader}
            color={"red"}
            message={"Successfuly removed leader from survey"}
          />
        ) : (
          ""
        )}
        <Line />
        {leaders? leaders.map((p, k) => {
          return (
            <LeaderCard
              employee={p}
              schedule_id={leadership?.id}
              selectedLeader={
                leaderInSchedule.filter((tm) => tm.employee === p.id)[0]
              }
              leaderParticipants={leaderParticipants}
              AddRemoveLeader={AddRemoveLeader}
              organizationId={organizationId}
              key={k}
            />
          );
        }): ''}
        
      </LeaderContainer>
    );
  };
  
  export default LeaderList;