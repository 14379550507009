import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  SubText,
  Input2,
  InviteFormButton,
} from "../styled-list";
import { Message } from "semantic-ui-react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {
  SAVE_DEPARTMENT
} from "constants/actions";

const CreateEdit = ({visible,setVisible,department,setDepartment}) =>{
	const apiDispatch = useDispatch();
	const methods = useForm();
	const { register, handleSubmit, control, reset, errors } = methods;

	const org_id = useSelector((state) => state.auth.organization_id)

	const onSubmit = (data) => {
		setDepartment(data.department_name)
	    setVisible(true);
	    apiDispatch({
		      type: SAVE_DEPARTMENT,
		      payload:{name:data.department_name,organization:org_id}
		    });
	  };

	const createdDepartment = useSelector((state) => state.departments.created,shallowEqual)

  React.useEffect(() => {
  	setDepartment(createdDepartment)
  }, [createdDepartment]);

return (
	<div>
	{visible ? <SubText>{department.name} {department.id} Department</SubText>
	      : 
	        <div>
	          <form onSubmit={handleSubmit(onSubmit)}>
	            <Input2
	              // value={team}
	              placeholder={"Department Name"}
	              control={control}
	              name="department_name"
	              ref={register({
	                required: "Please enter Department Name",
	              })}
	            />
	            {errors.department_name && (
	              <Message
	                error
	                // header='Action Forbidden'
	                content={errors.department_name.message}
	              />
	            )}
	            <InviteFormButton type="submit">Create Department</InviteFormButton>
	            {/* onClick={() =>}  */}
	          </form>
	        </div>
	    }
	 </div>
	    )

}

export default CreateEdit