import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Progress,Dropdown } from 'semantic-ui-react'
import Filter from './Analytics/Filter'

const data =[{'name':'Black',n:30,score:30},{'name':'Asian',n:30,score:60},{'name':'Mixed/Multiple Ethnicities',n:30,score:80},
{'name':'First Nations/Aboriginal',n:30,score:70},{'name':'Hispanic/LatinX',n:30,score:50},{'name':'Other',n:30,score:67},
,{'name':'White/Caucasian',n:30,score:60}]

const reverseScoring = {
    0: 10,
    1: 9,
    2: 8,
    3: 7,
    4: 6,
    5: 5,
    6: 4,
    7: 3,
    8: 2,
    9: 1,
    10: 0,
  };


const Grades = ({surveyStructure,recentResponses,questions}) =>{

	const [options, setOptions] = useState();
	const [sub, setSub] = useState();
	const [data,setData] = useState([])
	const [scores,setScores] = useState([])

	  useEffect(() => {
    if (surveyStructure.length > 0 && !options) {
      setOptions(structureOptions(surveyStructure));
    }
  }, [options, surveyStructure]);

	    const structureOptions = (structure) => {
    return structure[0].categories
      .filter((f) => f.priority.toLowerCase() === "tertiary")
      .map((item, i) => {
        return {
          key: i,
          text: item.name,
          value: item.id,
        };
      });
  };


  const getAggregatedScore = (responseObj) => {

  	const factor = responseObj.factor

    const questionObj = questions?.factors[factor]?.questions.find(
      (question) => question.id === responseObj.ques_order
    );

    return questionObj?.reverse
      ? reverseScoring[responseObj.response]
      : responseObj.response;
  };

  const getAverageForGroup = (group, isRecent = false) => {
    const dataSet = recentResponses

    const secondaryFilter =
      surveyStructure[0].categories.find((f) => f.id === sub)

     let scores = []
       
     const table_data = secondaryFilter.options.map((item,i)=>{
     	let sum = 0
     	let cummulative =0
     	let total = 0
     		const secondaryDataSet = dataSet.forEach(responseGroup=>{
     			 responseGroup.responses?.filter(f=>f.response.categories.find(c=>c.id==sub).response ==item.id)
     			 	.forEach((responseSet) => {
     			 		let sum_ind = 0
     			 		let total_ind = 0
     			 		 responseSet.response.questions.forEach((questionResponse) => {
     			 		 	 const score = getAggregatedScore(questionResponse);
     			 		 	 sum+=score
     			 		 	 sum_ind+=score
     			 		 	 cummulative+=1
     			 		 	 total_ind+=1
     			 		 	})
     			 		 total+=1

     			 		 scores.push(sum_ind/total_ind)
     			 		})
     				})

     		let average = cummulative==0? 0 : sum/cummulative
     		return {name:item.name,score:average,n:total}
     	})

     return {table_data:table_data.filter(f=>f.n!=0) , scores}
  };

	const getGrade = (score) =>{
		if(score>96){
			return 'A+'
		}
		if(score>92){
			return 'A'
		}
		if(score>89){
			return 'A-'
		}
		if(score>86){
			return 'B+'
		}
		if(score>82){
			return 'B'
		}
		if(score>79){
			return 'B-'
		}
		if(score>76){
			return 'C+'
		}
		if(score>72){
			return 'C'
		}
		if(score>69){
			return 'C-'
		}
		if(score>66){
			return 'D+'
		}
		if(score>59){
			return 'D'
		}

		return 'F'
	}


	useEffect(()=>{
		if(sub){
			let {table_data, scores} = getAverageForGroup(sub)
			setData(table_data)
			setScores(scores)
		}
	},[sub])

	useEffect(()=>{
		if(options){
			setSub(options[0].value)
		}
		
	},[options])

	const range =[[100,89],[89,79],[79,69],[69,60],[60,0]]
	const score_key ={'A':0,'B':1,'C':2,'D':3,'F':4}

	const getScore = (grade) =>{
		let total = scores.length
		let range_val = range[score_key[grade]]

		return scores.filter(f=>(f*10<range_val[0] && f*10>range_val[1])).length / total *100 
	}



	return(
		<Container>
			<Table>
				<div>
					<Header>Table 1. Grade Scores by

					{options?<Dropdown
			            inline
			            options={options}
			            defaultValue={options[0].value}
			            value={sub}
			            onChange={(e, { value }) => setSub(value)}
			            style={{ marginLeft: 5 }}
			          />:""}
          			</Header>
					
					<Description>These grades are calculated based on traditional school grading (B is equal to 83-86%) scales. 
								They are designed to give an intuitive sense of the overall scoring of the DEI factors.</Description>
				</div>
				<TH>
					<TD >Factor</TD>
					<TD >Sample Size</TD>
					<TD >Overall Grade</TD>
				</TH>

				{data.map((item)=>{
					return (
						<TR>
							<TD >{item.name}</TD>
							<TD>{item.n}</TD>
							<TD><G>{getGrade(item.score*10)}</G></TD>
						</TR>
						)
				})}

				
			</Table>

			<Scores>
				<div>
					<Header>Table 2. Overall Grade Distribution</Header>
					<Description>The distribution of individual grades for all individuals who participated in the most recent assessments.</Description>
				</div>
				<Row>
					<Grade>A</Grade>
					<ProgressContainer>
						<Progress percent={getScore('A')} size="small" color='grey'/>
					</ProgressContainer>
				</Row>
				<Row>
					<Grade>B</Grade>
					<ProgressContainer>
						<Progress percent={getScore('B')} size="small" color='grey'/>
					</ProgressContainer>
				</Row>
				<Row>
					<Grade>C</Grade>
					<ProgressContainer>
						<Progress percent={getScore('C')} size="small" color='grey'/>
					</ProgressContainer>
				</Row>
				<Row>
					<Grade>D</Grade>
					<ProgressContainer>
						<Progress percent={getScore('D')} size="small" color='grey'/>
					</ProgressContainer>
				</Row>
				<Row>
					<Grade>F</Grade>
					<ProgressContainer>
						<Progress percent={getScore('F')} size="small" color='grey'/>
					</ProgressContainer>
				</Row>
				<Row>
					<Grade/>
					<Nums>
						{[0,1,2,3,4,5,6,7,8,9,10].map(item=><div>{item*10}</div>)}
					</Nums>
				</Row>
			</Scores>
		</Container>
		)
}


export default Grades


const Container = styled.div`
	width:100%;
	display:flex;
`

const TH = styled.div`
	width:100%;
	border-top:1px solid black;
	background-color:rgba(181, 181, 181,0.4);
	display:flex;
	font-weight:bold;

`

const TD = styled.div`
	width:33%;
	border-bottom:1px solid black;
	padding:5px;

`

const Table = styled.div`
	width:50%;
	padding:20px;
`

const TR = styled.div`
	width:100%;
	display:flex;
`

const G = styled.div`
	font-size:25px;
`

const Scores = styled.div`
	width:50%;
	padding:20px 30px;

`

const Grade = styled.div`
	width:30px;

`

const Row = styled.div`
	width:100%;
	display:flex;
`

const ProgressContainer = styled.div`
	width:calc(100% - 30px);
`

const Nums = styled.div`
	display:flex;
	justify-content:space-between;
	width:calc(100% - 30px);
	color:grey;
`

const Title = styled.div`
	font-size:20px;
	margin-bottom:20px;
	font-weight:bold;
`

const Header = styled.div`
	font-size:16px;
	font-weight:bold;
	margin-bottom:5px;
	display:flex;
`

const Description = styled.div`
	margin-bottom:10px;
`