import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import Chart from "react-apexcharts";
import { Icon,Progress,Label } from 'semantic-ui-react'
import SummarySquare from './SummarySquare'
import Property from './Property'

const Risk = () =>{

	const [open,setOpen] = useState(false)



	if(open){
		return <Property />
	}

	return (
		<Container>
		
				<Title>Property Analytics</Title>
				<Top>
					<SummarySquare change={50} value={'$ 30,638,000'} title='Quarterly Revenue'/>
					<SummarySquare change={-5} value={'78%'} title='Team Performance'/>
					<SummarySquare change={25} value={'8.4'} title='Average Search Ranking'/>
				</Top>
				<Container2>
					<Toggles>
						<Toggle>Location</Toggle>
						<Toggle>Team</Toggle>
						<Toggle selected={true}>Property</Toggle>
					</Toggles>
					<Table>
							<TH width={30}>Property</TH>
							<TH width={20}>90d Revenue</TH>
							<TH width={20}>% Objective</TH>
							<TH width={30}>Team Performance</TH>
					</Table>
					<TableElement 
					onClick={()=>setOpen(true)}
						top={true}
						title={'Crypto Vantage'}
						description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
						risk={96}/>
					<TableElement 
					onClick={()=>setOpen(true)}
						title={'Odd Shark'}
						description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
						risk={96}/>
				<TableElement 
					onClick={()=>setOpen(true)}
						title={'CDD Nerds'}
						description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
						risk={96}/>
					<TableElement 
					onClick={()=>setOpen(true)}
						title={'AddictiveTips.com'}
						description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
						risk={96}/>
				</Container2>

		</Container>
		)
}


export default Risk

const Container = styled.div`
	display:flex;
	flex-direction:column;
	margin-top:30px;
`

const Top = styled.div`
	width:100%;
	display:flex;
	justify-content:space-evenly;
	margin-bottom:40px;
`


const Left = styled.div`
	width:400px;
	height:calc(100vh - 80px);
	border-right:1px solid lightgrey;
	padding:30px 20px;
`
const Container2 = styled.div`
	padding:20px;
`
const Toggles = styled.div`
	width:100%;
	display:flex;
	justify-content:flex-end;
	margin-bottom:20px;
`

const Toggle = styled.div`
	padding:5px 15px;
	background-color:${props=>props.selected?'lightgrey':'#f5f5f5'};
	border-radius:20px;
	margin-right:10px;
	font-weight:200;
	cursor:pointer;
`

const Title = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:20px;
	font-weight:300;
	margin-bottom:20px;
	margin-left:0px;
	margin-left:20px;
`

const T2 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:30px;
	font-weight:700;
	margin-bottom:40px;
`
const T3 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:20px;
	font-weight:300;
	margin-bottom:10px;
`

const Right = styled.div`
	width:calc(100% - 400px);
	padding:30px 20px;
`

const Line = styled.div`
	width:100%;
	height:1px;
	background-color:lightgrey;
	margin-top:20px;
	margin-bottom:20px;
`

const Table = styled.div`
	width:100%;
	display:flex;
`

const TH = styled.div`
	width:${props=>props.width+'%'};
	font-family: 'Barlow', sans-serif;
	padding:10px;
	font-size:13px;
`

const TableElement = ({title,description,risk,top,onClick}) =>{

	return (
		<TableContainer top={top} onClick={()=>onClick()}>
							<TH width={30}><TableTitle>{title}</TableTitle></TH>
						<TH width={20}>$93,000</TH>
						<TH width={20}><Progress size="small" percent={60} progress style={{margin:0,marginTop:5}}/></TH>
						<TH width={30}>	<Label>Performance : <strong>{risk}%</strong></Label></TH>
			
		
		</TableContainer>
		)
}


const TableContainer = styled.div`
	width:100%;
	height:auto;
	cursor:pointer;
	font-family: 'Barlow', sans-serif;
	padding:10px;
	display:flex;
	justify-content:center;
	border-bottom:1px solid lightgrey;
	border-left:1px solid lightgrey;
	border-right:1px solid lightgrey;
	border-top:${props=>props.top?'1px solid lightgrey':'none'};

	&:hover{
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
	}
`

const TableTitle = styled.div`
	font-weight:700;
	font-size:16px;
	margin-bottom:10px;

`


const Description = styled.div`

`

const RiskElement = ({title,description,risk}) =>{

	return (
		<RiskContainer>
			<RiskTitle>{title}</RiskTitle>
			<Description>{description}</Description>
			<LabelContainer>
				<Label>ROI : <strong>{risk}$</strong></Label>
			</LabelContainer>
		</RiskContainer>
		)
}


const RiskContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
	width:100%;
	height:auto;
	border-radius:20px;
	cursor:pointer;
	font-family: 'Barlow', sans-serif;
	padding:20px;
	display:flex;
	position:relative;
	flex-direction:column;
	margin-top:10px;

	&:hover{
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
	}
`

const RiskTitle = styled.div`
	font-weight:700;
	font-size:16px;
	margin-bottom:10px;

`

const LabelContainer = styled.div`
	position:absolute;
	top:16px;
	right:20px;
`