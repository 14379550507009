import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Icon, Label, Menu, Table } from 'semantic-ui-react'


const Factors = () =>{

    return (
        <Container>
            <Title>Correlation Analysis</Title>
            <Description>Below you'll find an analysis of culture factors as it relates to the archived tags associated with individuals who have completed the survey prior to leaving the organization.</Description>
            
        <TableContainer>
        <Table celled>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Factor</Table.HeaderCell>
                    <Table.HeaderCell>6 months</Table.HeaderCell>
                    <Table.HeaderCell>1 year</Table.HeaderCell>
                    <Table.HeaderCell>2 year</Table.HeaderCell>
                    <Table.HeaderCell>3+ year</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>
                <Table.Row>
                    <Table.Cell>Culture</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>
                <Table.Row>
                <Table.Cell>Engagement</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Well-being</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Retention</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>HR Flags</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Feedback Quality</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>

                <Table.Row>
                    <Table.Cell>Survey Engagement</Table.Cell>
                    <Table.Cell>0.6</Table.Cell>
                    <Table.Cell>0.75</Table.Cell>
                    <Table.Cell>0.74</Table.Cell>
                    <Table.Cell>0.76</Table.Cell>
                </Table.Row>


                </Table.Body>

            </Table>

        </TableContainer>
            
        </Container>
    )

}


export default Factors


const Container = styled.div`
    width: 100%;
    max-width:1000px;
`
const Title = styled.div`
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    margin-bottom:24px;
`
const Description = styled.div`
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
max-width:800px;
line-height:1.5;
`
const ChartArea = styled.div`
    width:100%;
    margin-top:40px;
`
const TableContainer = styled.div`
    width:100%;
    font-family: Raleway;
    margin-top:20px;
`

const test_data = [
    {score:7.2,org_score:6.5,name:"Psychological Safety"},
    {score:6.2,org_score:6.5,name:"Adaptability"},
    {score:5.2,org_score:6.5,name:"Values"},
    {score:4.2,org_score:6.5,name:"Inclusivity"},
    {score:3.2,org_score:6.5,name:"Communication"},
    {score:2.2,org_score:6.5,name:"Impact"},
    {score:1.2,org_score:6.5,name:"Purpose"}
]