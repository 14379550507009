import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontFamily: "Raleway",
    fontWeight: 600,
    fontSize: 12,
    color: "#2d70e2",
  },
  firstSection: {
    fontFamily: "Raleway",
    fontWeight: 300,
    fontSize: 10,
    color: "#7e7e7e",
    marginBottom: 10,
    marginTop: 10,
  },
  bulletSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 5,
  },
  bullet: {
    fontFamily: "Raleway",
    fontWeight: 300,
    fontSize: 10,
    color: "#7e7e7e",
    marginBottom: 3,
    marginLeft: 10,
  },
});

const WellnessTop = () => {
  return (
    <View style={styles.titleWrapper}>
      <Text style={styles.title}>Wellness Action Plan</Text>
      <View style={styles.firstSection}>
        <Text style={styles.firstSectionText}>
          Wellness encompasses physical, psychological/emotional, social, and
          intellectual dimensions, and is defined as the balanced amalgamation
          of these factors and purposeful direction within a sport environment.
          There are four dimensions of wellness that impact psychological and
          physical health and safety in high performance sport. These wellness
          dimensions are:
        </Text>
      </View>
      <View style={styles.bulletSection}>
        <Text style={styles.bullet}>• Physical</Text>
        <Text style={styles.bullet}>• Social / Spiritual</Text>
        <Text style={styles.bullet}>• Emotional</Text>
        <Text style={styles.bullet}>• Cognitive</Text>
      </View>
      <View style={styles.firstSection}>
        <Text style={styles.firstSectionText}>
          Within the Wellness Index, there are 8 High Performance Outcomes that
          surround the four wellness dimensions. These represent how
          psychological and physical health and safety are upheld and monitored
          within a sport organization. NSO initiatives and related actions,
          should be developed and monitored through their High Performance
          Impact on People, Systems, and Culture.
        </Text>
      </View>
    </View>
  );
};

export default WellnessTop;
