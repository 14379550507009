import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import {
	Icon,
	Modal,
	Label,
	Loader,
	Dimmer,
	Progress,
} from "semantic-ui-react";
import Chart from "react-apexcharts";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { get_historical } from "../data_navigation/get_historical";
import { effect_size_calculation } from "../data_navigation/effect_size";
import { calculate_norms } from "../data_navigation/z_score";
import LegendIcon from '../LegendIcon'

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const frenchMonthNames = ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin",
  "Juil", "Août", "Sep", "Oct", "Nov", "Déc"]


const IndividualModal = (props) => {
	const [open, setOpen] = React.useState(false);
	const {is_french,language_hook} = props
	const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
	const [series, setSeries] = useState([
		{ name: "data", data: props.data.distribution },
	]);

	const [loading, setLoading] = useState(true);
	const [options1, setOptions1] = useState(null);
	const [options2, setOptions2] = useState(null);
	const [series2, setSeries2] = useState([]);
	const [effect, setEffect] = useState([]);
	const [percentile, setPercentile] = useState([0]);
	const [loaded, setLoaded] = useState(null);

	const filter_data = useSelector(
		(state) => state.audit?.filtered_data,
		shallowEqual
	);
	const selected = useSelector(
		(state) => state.audit?.selected,
		shallowEqual
	);
	const dates = useSelector((state) => state.audit?.dates, shallowEqual);
	const categories = useSelector(
		(state) => state.audit?.category_structure,
		shallowEqual
	);
	const raw = useSelector((state) => state.audit?.raw_data, shallowEqual);

	const getMonth = (month) =>{

		let offset = month.getTimezoneOffset()
		let new_date = new Date(month.getTime() + offset*60*1000 + 24*60*60*1000).getMonth()
		
		return new_date
	}

	useEffect(() => {
		let check = false;
		if (options1) {
			check = options1.colors[0] != props.color;
		}
		if (!check && loaded) {
			let dimension = selected.dimension != loaded.selected.dimension;
			let factor = selected.factor != loaded.selected.factor;
			let q = props.idx != loaded.q;
			let role = props.i != loaded.role;
			check = dimension || factor || q || role;
		}
		if (check) {
			setLoading(true);
		}
		setSeries([{ name: "data", data: props.data.distribution }]);

		if (!options1 || check) {

			let _options2 = data2.options;
			_options2.colors = ['#66D686'];
			setOptions2(_options2);
			let chart_data = get_historical(
				filter_data,
				selected,
				props.idx,
				props.data.role,
				dates,
				anchor,
				categories
			);

			let filtered_chart_data = [{data:chart_data[0].data.filter(f=>f.y)}]
			let months = filtered_chart_data[0].data.map(item =>is_french?frenchMonthNames[getMonth(item.x)] :monthNames[getMonth(item.x)])
			filtered_chart_data[0].data = filtered_chart_data[0].data.map(item=>item.y)


			let _options = data3.options;
			_options.colors = ['#476DFA', "#b5b5b5"];
			_options.xaxis.categories = months

			if(is_french){
				_options.yaxis.title.text = "Moyenne"
				_options2.yaxis.title.text= "Moyenne"
				_options.xaxis.title.text = "Date de l'audit"
			}
			setOptions1(_options);


			setSeries2(filtered_chart_data);

			if(Number(props.WhiteLabel?.overview) === 1){
				setEffect(
					effect_size_calculation(
						categories,
						filter_data,
						selected,
						props.idx,
						props.data.role,
						anchor
					)
				);
			}

			if (chart_data.length > 0) {
				setPercentile([
					calculate_norms(
						raw,
						props.data.role,
						chart_data[0].data[chart_data[0].data.length - 1].y,
						selected,
						props.idx,
						anchor,
						categories
					),
				]);
			}

			setLoaded({ selected, q: props.idx, role: props.i });
			setTimeout(() => setLoading(false), 300);
		}
	}, [open]);

	const getSize = (ES) => {
		if (ES < 0.2) {
			return "None";
		}
		if (ES < 0.5) {
			return "Small";
		}
		if (ES < 0.8) {
			return "Moderate";
		}
		if (ES < 1.3) {
			return "Large";
		}
		return "Very Large";
	};

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={props.trigger}
			closeIcon
			size={"large"}
		>
			<Modal.Header style={{ padding: 30 }}>
				<Role>{props.title}</Role>

				<Question>{props.question}</Question>
			</Modal.Header>
			<SquareContainer>
				<Square width={70}>
					<Title><LegendIcon/>{language_hook('Trends')}</Title>
					<Description>
					{language_hook('Trends2')}
					</Description>
					{loading ? (
						<Dimmer active inverted>
							<Loader inverted>Loading</Loader>
						</Dimmer>
					) : (
						<Chart
							options={options1}
							series={series2}
							type="line"
							height={"100%"}
						/>
					)}
				</Square>

				<Square width={30}>
					<Title><LegendIcon/>{language_hook('Role Norms')}</Title>
					<Description>
					{language_hook('Chart1')}
					</Description>
					{percentile[0] ? (
						<Chart
							options={data.options}
							series={percentile}
							type="radialBar"
							height={"100%"}
							style={{ marginTop: -20 }}
						/>
					) : (
						""
					)}
					{/*	<Change>
						Since last assessment{" "}
						<Label style={{ marginLeft: 10 }} color={"green"}>
							+5%
						</Label>
					</Change>*/}
				</Square>

				<Square width={effect?.length>0?70:100}>
					<Title><LegendIcon/>{language_hook('Score distribution')}</Title>
					<Description>
						{language_hook('Chart1')}
					</Description>
					{loading ? (
						<Dimmer active inverted>
							<Loader inverted>Loading</Loader>
						</Dimmer>
					) : (
						<Chart
							options={options2}
							series={series}
							type="bar"
							height={"100%"}
						/>
					)}
				</Square>

				<Square width={effect?.length>0?30:0} scroll={true} >
					<Title><LegendIcon/>Effect size insights</Title>
					<Description>
						Critical factors that are most impacted and in need of
						attention
					</Description>
					<Labels>
						{effect
							? effect.map((item) => {
									return (
										<LabelContainer>
											<LabelTitle>{item.name}</LabelTitle>
											<LabelText>
												Effect Size:{" "}
												{Math.floor(
													Math.max(
														...item.ES.map(
															(i) =>
																Math.abs(i) *
																100
														)
													)
												) / 100}{" "}
												(
												{getSize(
													Math.floor(
														Math.max(
															...item.ES.map(
																(i) =>
																	Math.abs(
																		i
																	) * 100
															)
														)
													) / 100
												)}
												)
											</LabelText>
										</LabelContainer>
									);
							  })
							: ""}
					</Labels>
				</Square>
			</SquareContainer>
		</Modal>
	);
};

export default IndividualModal;


const Labels = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
`;

const LabelContainer = styled.div`
	display: flex;
	flex-direction:column;
	justify-content: center;
	margin-top: 4px;
	border-radius: 2px;
	width: 100%;
	border-top:1px solid #D7DCE6;
	border-bottom:${props=>props.last?'1px solid #D7DCE6':""};
	padding:13px 0px 13px 24px;
`;

const LabelTitle = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	/* identical to box height */


	color: #A5A5A5;
`;

const LabelText = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 15px;

color: #7E7E7E;
`;

const Square = styled.div`
	width: ${(props) => 'calc('+props.width + "% - 10px)"};
	display:${props=>props.width==0?'none':'inline'};
	height: 375px;
	padding:${props=>props.padding===0?0:'24px'};
	margin-bottom:10px;
	margin-right:10px;

	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
	border-radius: 10px;

	overflow-y:${props=>props.scroll?'scroll':'none'};
	 &::-webkit-scrollbar {

	width:3px;
}
`;

const SquareContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding:20px;
`;
const Title = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	/* identical to box height */
	display:flex;
	align-items:center;


	color: #414141;
`;
const Description = styled.div`
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 300;
	font-size: 10px;
	line-height: 15px;
	/* identical to box height */
	margin-left:25px;


color: #C0C0C0;
`;

const Role = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
display: flex;
align-items: center;

color: #414141;
	margin-bottom: 10px;
`;

const Question = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 24px;
display: flex;
align-items: center;
margin-bottom:-10px;
color: #7E7E7E;
`;
const Change = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	margin-top: -20px;
`;

const data3 = {
	series: [
		{
			name: "Average Score",
			data: [8.2, 8.5, 9, 7.6],
		},
		{
			name: "Category Norms",
			data: [8.6, 8.0, 7.3, 8.5],
		},
	],
	options: {
		chart: {
			type: "line",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			sparkline: {
				enabled: false,
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#E3B01F", "#b5b5b5"],
		grid: {
			padding: {
				left: 10,
				right: 10,
				bottom: 20,
				top: 10,
			},
		},
		stroke: {
			width: [5, 2],
			curve: "straight",
			dashArray: [0, 8],
		},
		xaxis: {
			type: 'category',
			title: {
				text: "Survey Date",
				style:{
					fontWeight:400
				}
			},
		},
		yaxis: {
			title: {
				text: "Score",
				style:{
					fontWeight:400
				}
			},
			min: 0,
			max: 10,
			decimalsInFloat: 1,
		},
	},
};

const data2 = {
	series: [
		{
			name: "Scores",
			data: [0, 0, 0, 0, 1, 2, 8, 3, 2, 0, 1],
		},
	],
	options: {
		chart: {
			type: "line",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			sparkline: {
				enabled: false,
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
			show: false,
		},
		colors: ["#66D686", "#FFC600", "#FF0000", "#02AE5B"],
		fill: {
			opacity: 1,
		},
		grid: {
			padding: {
				left: 10,
				right: 10,
				bottom: 20,
				top: 10,
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			categories: [
				"0",
				"1",
				"2",
				"3",
				"4",
				"5",
				"6",
				"7",
				"8",
				"9",
				"10",
			],
		},
		yaxis: {
			title: {
				text: "Score frequency",
				style:{
					fontWeight:400
				}
			},
			decimalsInFloat: 0,
		},
	},
};

const data = {
	series: [80],
	options: {
		chart: {
			height: 400,
			type: "radialBar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: "35%",
				},
				dataLabels: {
					show: true,
					name: {
						offsetY: -10,
						show: false,
						color: "#888",
						fontSize: "17px",
						fontFamily:"Poppins, sans-serif",
					},
					value: {
						formatter: function (val) {
							return parseInt(val);
						},
						color: "#414141",
						fontSize: "30px",
						fontFamily:"Poppins, sans-serif",
						show: true,
					},
				},
			},
		},
		fill: {
			colors: ["#476DFA"],
		},
		 stroke: {
          lineCap: 'round'
        },
	},
};
