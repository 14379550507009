import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import Chart from "react-apexcharts";
import { Icon,Progress,Label } from 'semantic-ui-react'

const Risk = () =>{

	return (
		<Container>
			<Left>
				<Title>Risk Analytics</Title>
				<RiskElement 
					title={'Managers in Halifax'}
					description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
					risk={96}
					selected={true}/>
				<RiskElement 
					title={'Odd Shark Team'}
					description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
					risk={85}/>
				<RiskElement 
					title={'Female Employees'}
					description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
					risk={76}/>
				<RiskElement 
					title={'New Hires in South Africa'}
					description={'Significantly lower levels of psychological safety found in managers in Halifax, making less than 85,000$'}
					risk={75}/>

			</Left>
			<Right>
				<T2>Managers in Halifax</T2>

				<T3>Long term trends</T3>
				<D1>Audit scores from most recent survey. Aug 6th, 2020</D1>
		      <Chart options={data2.options} series={data2.series} type="line" height={400} />

		      <SquareContainer>
		      	<Square right={true}>
		      		<T3>Factor Insights</T3>
					<D1>Factors impacting risk scores for this cohort</D1>

					<Factor>
						<FTitle>Psychological Safety</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={70} size="small" progress/>
					</Factor>

					<Factor>
						<FTitle>Mental Health</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={50} size="small" progress/>
					</Factor>

					<Factor>
						<FTitle>Leadership</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={40} size="small" progress/>
					</Factor>

					<Factor>
						<FTitle>Accountability</FTitle>
						<FDescription>Scores in this category have a significant effect on this cohorts risk assessment</FDescription>
						<Progress percent={20} size="small" progress/>
					</Factor>

		      	</Square>

		      	<Square>
		      		

					
					<T3>Risk Analysis</T3>
					<D1>Likelihood of employee turn over in this cohort</D1>
					<ChartContainer>
					<Chart options={data.options} series={data.series} type="radialBar" height={300} />
					</ChartContainer>
					<Line/>


					<T3>Turn Over Prediction</T3>
					<D1>Likelihood of employee turn over in this cohort</D1>
					<Score>1.5x</Score>
					<Sub>Greater risk of turning over</Sub>


		      	</Square>
		      </SquareContainer>
			</Right>
		</Container>
		)
}


export default Risk

const Container = styled.div`
	display:flex;
`


const Left = styled.div`
	width:400px;
	height:calc(100vh - 80px);
	border-right:1px solid lightgrey;
	padding:30px 20px;
`

const Title = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:20px;
	font-weight:300;
	margin-bottom:20px;
	margin-left:0px;
`

const T2 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:30px;
	font-weight:700;
	margin-bottom:40px;
`
const T3 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:20px;
	font-weight:300;
	margin-bottom:10px;
`

const Right = styled.div`
	width:calc(100% - 400px);
	padding:30px 20px;
`

const Line = styled.div`
	width:100%;
	height:1px;
	background-color:lightgrey;
	margin-top:20px;
	margin-bottom:20px;
`

const RiskElement = ({title,description,risk,selected}) =>{

	return (
		<RiskContainer selected={selected}> 
			<RiskTitle>{title}</RiskTitle>
			<Description>{description}</Description>
			<LabelContainer>
				<Label>Risk : <strong>{risk}%</strong></Label>
			</LabelContainer>
		</RiskContainer>
		)
}


const RiskContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
	width:100%;
	height:auto;
	border-radius:20px;
	cursor:pointer;
	font-family: 'Barlow', sans-serif;
	padding:20px;
	display:flex;
	position:relative;
	flex-direction:column;
	margin-top:10px;
	background-color:${props=>props.selected?'lightgrey':'white'};

	&:hover{
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
	}
`

const RiskTitle = styled.div`
	font-weight:700;
	font-size:16px;
	margin-bottom:10px;

`

const LabelContainer = styled.div`
	position:absolute;
	top:16px;
	right:20px;
`

const ChartContainer = styled.div`
	margin-top:-20px;
	margin-bottom:-30px;
`


const Description = styled.div`

`

const D1 = styled.div`
	font-family: 'Barlow', sans-serif;
`

const SquareContainer = styled.div`
	display:flex;
	border-top:1px solid lightgrey;
`

const Square = styled.div`
	border-right:${props=>props.right?'1px solid lightgrey':'none'};
	width:50%;
	height:600px;
	padding:20px 10px;
`

const Score = styled.div`
	font-size:80px;
	line-height:1;
	font-family: 'Barlow', sans-serif;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:20px;
`

const Sub = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-family: 'Barlow', sans-serif;
`

const Factor = styled.div`
	width:100%;
	margin-top:20px;
	padding-right:20px;

`

const FTitle = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:16px;
	font-weight:600;
	margin-bottom:5px;	
`

const FDescription = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:12px;
	margin-bottom:10px;	
`

const data = {
          
            series: [80],
            options: {
              chart: {
                height: 350,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '50%',
                  },
                  dataLabels: {
                    show: true,
                    name: {
                      offsetY: -10,
                      show: true,
                      color: '#888',
                      fontSize: '17px'
                    },
                    value: {
                      formatter: function(val) {
                        return parseInt(val);
                      },
                      color: 'black',
                      fontSize: '36px',
                      show: true,
                    }
                  },
                },
              },
              
              labels: ['Percentile'],
              fill: {
                colors:['#b5b5b5']
              },

            },
          
          
          }

const data2 = {
	series: [

              {
                name: "Average Score",
                data: [8.2, 8.5, 9,7.6,8.2, 8.5, 6,5.6]
              }
            ],
            options: {
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: false,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left'
              },
              colors:['#E3B01F','#b5b5b5'],
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               stroke: {
          width: [5, 2],
          curve: 'smooth',
          dashArray: [0, 8]
        },  xaxis: {
                categories: ['Q1 2020', 'Q2 2020', 'Q3 2020', 'Q4 2020', 'Q1 2021', 'Q2 2021', 'Q3 2021','Q4 2021'],
              },
              yaxis: {
                title: {
                  text: 'Score'
                },
                min: 0,
                max: 10,
                 decimalsInFloat: 0,
              },
            }
}