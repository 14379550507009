import {
    NEW_EVENT_NOTIFICATION,
    CLEAR_EVENT_NOTIFICATION,
    CLEAR_ALL_EVENT_NOTIFICATIONS,
      CLEAR_ALL,
    } from "constants/actions";
    
    const initialState = {
      event_notifications: [],
      pending: false,
    };
    
    export default function EventNotification(state = initialState, action) {
      switch (action.type) {
        case NEW_EVENT_NOTIFICATION:
          return {
            ...state,
            event_notifications: action.payload,
            pending: true,
          };
        case CLEAR_EVENT_NOTIFICATION:
          return {
            ...state,
            event_notifications: state.event_notifications.filter(
                (row) => row.id !== action.payload.id
            ),
            pending: false,
          };
  
        case CLEAR_ALL_EVENT_NOTIFICATIONS:
          return {
            ...state,
            ...initialState,
          };
    
        case CLEAR_ALL:
          return {
            ...state,
            ...initialState,
          };
    
        default:
          return state;
      }
    }