import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";

const data = {
          
            series: [70],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '60%',

                  },
                   track:{
                	background:'#c3a7fd',
                	  background: '#fff',
                   dropShadow: {
                      enabled: true,
                      top: -3,
                      left: 0,
                      blur: 4,
                      opacity: 0.35
                    }
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#6a00ff']
              },
              stroke: {
                lineCap: 'round'
              },
               grid: {
                padding: {
                  top: -15,
                  bottom:-20
                }
              },
            },
          
          
          };

const Avatar = ()=>{
		return (
			<Dial>
				<Chart options={data.options} series={data.series} type="radialBar" height={175} />
				<Values src="https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg" />
			</Dial>
		)
}
	

export default Avatar


const Dial = styled.div`
	padding:10px;
	position:relative;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`


const Values = styled.img`
	position:absolute;
	width:75px;
	height:75px;
	left:calc(50% - 37.5px);
	top:calc(50% - 37.5px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	border-radius:37.5px;
`

const Stat = styled.div`
	font-size:30px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const Change = styled.div`
	font-size:16px;
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:10px;
`