import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import Phase1 from "./Planning/Phase1";
import Plan from "./Plan/index";
import Phase3 from "./Planning/Phase3";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";
import { SURVEY_INFO } from "constants/surveys";
import { useLocation } from "react-router";
import PlanSelection from "./Planning/PlanSelection";
import planningTemplates from "./templates/planningTemplates.json";
import PlanLayout from "./Planning/PlanLayout";
import { CloseIcon } from "./Icons";

const empty_selections = {
  metric: "",
  primary: "",
  plan: { id: null },
  planData: ["", "", "", "", "", ""],
  member: [],
  status: null,
  startDate: new Date(),
  endDate: new Date(),
};

const Planning = ({
  open,
  close,
  plans,
  handlePlanCreation,
  questions,
  filteredCategories,
}) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState(empty_selections);
  const location = useLocation();
  const isOtp = location.pathname.includes("otp/action");
  const [selectedPlan, setSelectedPlan] = useState(0); // 0 is the default plan
  const [planningPage, setPlanningPage] = useState(0);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [showStartDateError, setShowStartDateError] = useState(false);
  const [showEndDateError, setShowEndDateError] = useState(false);
  const [filteredStructure, setFilteredStructure] = useState();

  useEffect(() => {
    setData({ ...empty_selections, planData: ["", "", "", "", "", ""] });
  }, []);

  // if we're using a different template
  useEffect(() => {
    if (selectedPlan !== undefined) {
      setPlanningPage(0);
      const { metric, primary, question } = data;
      setData({
        ...planningTemplates[selectedPlan].dataTemplate,
        plan: { id: selectedPlan },
        metric: planningTemplates[selectedPlan].dataTemplate.metric || metric,
        primary,
        question: planningTemplates[selectedPlan].dataTemplate.metric
          ? null
          : question,
      });
    }
  }, [selectedPlan]);

  const surveyInfo = location.pathname.includes("edi/action")
    ? {
        type: SURVEY_INFO.edi.question_type,
        auditType: SURVEY_INFO.edi.type,
      }
    : {
        type: SURVEY_INFO.audit.question_type,
        auditType: SURVEY_INFO.audit.type,
      };
  const { get_survey_structure, get_survey_questions } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_survey_questions: state.surveyquestions.survey_questions,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      !isNaN(selectedPlan) &&
      planningPage < planningTemplates[selectedPlan].template.length - 1
    ) {
      setNextEnabled(true);
    } else {
      if (selected === 2) {
        setNextEnabled(true);
      }

      if (
        selected === 1 &&
        planningPage >= planningTemplates[selectedPlan].template.length - 1
      ) {
        if (data?.startDate && data?.endDate) {
          setNextEnabled(true);
        }
      }
    }
  }, [selectedPlan, planningPage, data]);

  //Pull survey questions and categories from redux
  useEffect(() => {
    dispatch({ type: SG_GET_SURVEY_STRUCTURES });
  }, [dispatch]);

  const next = () => {
    if ((!data?.startDate || !data?.endDate) && selected === 2) {
      if (!data?.startDate) {
        setShowStartDateError(true);
      }
      if (!data?.endDate) {
        setShowEndDateError(true);
      }

      if (data?.startDate) {
        setShowStartDateError(false);
      }

      if (data?.endDate) {
        setShowEndDateError(false);
      }

      if (!data?.startDate || !data?.endDate) {
        return;
      }
    }

    if (selected === 0) {
      let check = false;

      if ((selectedPlan === 4 || selectedPlan === 5) && data.primary) {
        check = true;
      }

      if (
        data.metric &&
        data.question !== null &&
        data.primary &&
        data.plan?.id !== null
      ) {
        check = true;
      }

      if (check) {
        setSelected(selected + 1);
      }
    }
    if (selected === 1) {
      setSelected(selected + 1);
    }
    if (selected === 2) {
      console.log(data);
      handlePlanCreation(data);
      setData({ ...empty_selections, planData: ["", "", "", "", "", ""] });
      setSelected(0);
      close();
    }
  };

  const defaultPlanBuilder =
    get_survey_questions.length > 0 ? (
      selected === 0 ? (
        <Phase1
          categories={get_survey_structure[0]}
          questions={
            questions || get_survey_questions?.[1] || get_survey_questions?.[0]
          }
          data={data}
          setData={(d) => setData(d)}
        />
      ) : selected === 1 ? (
        <Plan
          data={data}
          setData={(d) => setData(d)}
          questions={
            questions || get_survey_questions?.[1] || get_survey_questions?.[0]
          }
        />
      ) : (
        <Phase3 data={data} setData={(d) => setData(d)} />
      )
    ) : (
      ""
    );

  const otpPlanBuilder =
    get_survey_questions.length > 0 && selected === 0 ? (
      <PlanSelection
        categories={get_survey_structure[0]}
        questions={questions || get_survey_questions[0]}
        data={data}
        setData={(d) => setData(d)}
        selectedPlanId={selectedPlan}
        setSelectedPlan={(id) => {
          setSelectedPlan(id);
        }}
        alternateFocus={selectedPlan === 5}
        disabledDropdown={selectedPlan === 3}
        filteredCategories={filteredCategories}
      />
    ) : (
      <PlanLayout
        planTemplate={
          !isNaN(selectedPlan) &&
          planningTemplates[selectedPlan].template[planningPage]
        }
        questions={questions || get_survey_questions[0]}
        setPlanningPage={setPlanningPage}
        planningPage={planningPage}
        pillar={data.metric}
        data={data}
        setData={(d) => setData(d)}
        showStartDateError={showStartDateError}
        showEndDateError={showEndDateError}
      />
    );

  return (
    <LayoutContainer>
      {/* {defaultPlanBuilder} */}

      <TopBar>
        <ActionButtons></ActionButtons>
      </TopBar>
      {isOtp ? otpPlanBuilder : defaultPlanBuilder}
      <Actions>
        <ActionText>
          <CloseButton
            onClick={() => {
              close();
            }}
          >
            {CloseIcon()}
            Discard
          </CloseButton>
        </ActionText>

        <div style={{ display: "flex" }}>
          <Back
            onClick={() => {
              if (selected > 0) {
                if (planningPage > 0 && selected === 1) {
                  setPlanningPage(planningPage - 1);
                } else {
                  setSelected(selected - 1);
                }
              }
            }}
          >
            Back
          </Back>
          <StyledButton
            showDisabled={isNaN(selectedPlan)}
            disabled={!nextEnabled}
            onClick={() => next()}
          >
            {(!isNaN(selectedPlan) &&
              planningPage >=
                planningTemplates[selectedPlan].template?.length - 1) ||
            selected === 2
              ? "Save"
              : "Continue"}
          </StyledButton>
        </div>
      </Actions>
    </LayoutContainer>
  );
};

export default Planning;

const Container = styled.div`
  /* position: absolute; */
  min-width: 300px;
  width: 60vw;
  /* right: ${(props) => (props.open ? "-25px" : "-70vw")}; */
  top: -20px;
  height: calc(100vh - 50px);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 99;
  font-family: "Raleway";
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  padding: 10px;
  overflow: hidden; ;
`;

const TopBar = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;

  box-sizing: border-box;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
`;

const PlanLabel = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
  min-width: 1000px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
`;

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  color: #666d79;
  font-size: 14px;
  margin-right: 30px;
`;

const LayoutContainer = styled.div`
  padding-left: 30px;
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScrollContainer = styled.div`
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;

  &:hover {
    background-color: rgba(9, 30, 66, 0.2);
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #2a3039;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;

  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.showDisabled ? "default" : "pointer")};
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  opacity: ${(props) => (props.showDisabled ? "0.6" : "1")};

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: ${(props) =>
      props.showDisabled ? "#2d50e2" : "rgba(0, 0, 0, 0)"};
    border: ${(props) =>
      props.showDisabled ? "1px solid white" : "1px solid #2d50e2"};
    color: ${(props) => (props.showDisabled ? "white" : "#2d50e2")};
  }
`;

const Back = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #7f7f7f;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-right: 5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  border-radius: 10px;
  padding: 10px 30px;
  background-color: #dfdfdf;
  position: fixed;
  bottom: 20px;
`;

const ActionText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #2a3039;
  display: flex;
  align-items: center;
`;
