import styled from "styled-components";



const H1 = styled.h1`
font-size:20px;
font-weight:bold;
margin-bottom:5px;
color:#2A3039;`

const P = styled.div`
margin-bottom:10px;
color:#2A3039;
`

const H2 = styled.div`
color:#2254AA;
font-weight:bold;
margin-bottom:10px;
font-size:16px;
margin-top:20px;
`

const LI = styled.li`
margin-bottom:10px;
`


// Utility to parse Markdown
export const parseMarkdown = (markdown) => {
    const lines = markdown.split('\n');
    const elements = [];
    let currentTable = [];
    let currentList = null;
  
    lines.forEach((line, index) => {
      if (line.startsWith('# ')) {
        elements.push(<H1 key={index}>{line.slice(2)}</H1>);
      } else if (line.startsWith('## ')) {
        elements.push(<H2 key={index}>{line.slice(3)}</H2>);
      } else if (line.startsWith('### ')) {
        elements.push(<H2>{line.slice(4)}</H2>);
      } else if (line.startsWith('##### ')) {
        elements.push(<h3 key={index}>{line.slice(6)}</h3>);
      } else if (line.startsWith('- ')) {
        if (!currentList) {
          currentList = [];
          elements.push(<ul key={`ul-${index}`}>{currentList}</ul>);
        }
        currentList.push(<LI key={index}>{parseInlineMarkdown(line.slice(2))}</LI>);
      } else {
        if (currentList) {
          currentList = null; // End the current list
        }
        elements.push(<p key={index}>{parseInlineMarkdown(line)}</p>);
      }
    });
  
    return elements;
  }
  
  // Utility to parse inline Markdown (bold)
  const parseInlineMarkdown = (text) => {
      const boldRegex = /\*\*(.*?)\*\*/g;
      const parts = [];
      let lastIndex = 0;
    
      text.replace(boldRegex, (match, p1, offset) => {
        if (offset > lastIndex) {
          parts.push(text.slice(lastIndex, offset));
        }
        parts.push(<strong key={offset}>{p1}</strong>);
        lastIndex = offset + match.length;
      });
    
      if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
      }
    
      return parts;
  };
  