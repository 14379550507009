import styled, { keyframes } from "styled-components";
import React from "react";
import Pic from 'assets/images/4151017.jpg'


const EmptyEmployee = () =>{


	return (
		<Container>
			<Title>Please select a profile </Title>
			<Img src={Pic}/>
		</Container>
		)
}


export default EmptyEmployee



const Container = styled.div`
	width:100%;
	min-height:900px;
	padding-top:40px;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;

`

const Title = styled.div`
	font-size:30px;
	margin-bottom:20px;
	font-family: 'Red Hat Display', sans-serif;
	font-weight:bold;

`

const Img = styled.img`
	width:100%;
	max-width:600px;
`