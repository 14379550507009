import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Individual from "./individualComment"
import SummaryData from './SummaryData'

const Comments = () =>{

	const [selected,setSelected] = React.useState(1)

	return (
		<Container>
			<CardTop>
				<Nav>
				<NavItem selected>Trust</NavItem>
				<NavItem>Clarity</NavItem>
				<NavItem>Communication</NavItem>
				<NavItem>Alignment</NavItem>
				<NavItem>Resilience</NavItem>
				<NavItem>Impact</NavItem>
				<NavItem>Overview</NavItem>
			</Nav>
			</CardTop>
			<Card>
			
			<Line/>
				<Metrics>Trust</Metrics>
				<Date>Report for March 30th,2021</Date>

				<Dates>
					<Time first selected={selected==1} onClick={()=>setSelected(1)}>Team Metrics</Time>
					<Time selected={selected==2} onClick={()=>setSelected(2)}>Comments (9)</Time>
				</Dates>
				
				{

				selected==2?
				<>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/>
				<Individual/></>:
				<SummaryData/>}
			</Card>
		</Container>
		)
}


export default Comments

const Nav = styled.div`
	width:100%;
	display:flex;
`

const NavItem = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	padding:10px 20px;
	height:50px;
	display:flex;
	align-items:center;
	color:${props=>props.selected?'#6a00ff':'#4a4a4a'};
	font-weight:bold;
	border-bottom:${props=>props.selected?'3px solid #6a00ff':'none'};
	cursor:pointer;
	font-size:16px;
`

const Card = styled.div`
	  box-shadow: 0 4px 20px 0 #ececec;
	  width:800px;
	  display:flex;
	  flex-direction:column;
	  padding:30px 40px;
	  margin-bottom:10px;
`

const CardTop = styled.div`
	  box-shadow: 0 4px 20px 0 #ececec;
	  width:800px;
	  display:flex;
	  flex-direction:column;
	  padding:30px 40px 0px 40px;
	  margin-bottom:10px;
`

const Container = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
`


const Metrics = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:26px;
	font-weight:bold;
	color:#4a4a4a;
`

const Line = styled.div`
	width:30px;
	height:5px;
	background-color:#6a00ff;
	margin-bottom:5px;
`

const Date = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:15px;
	border-bottom:1px solid #e9e9e9;
	margin-bottom:30px;
`


const Dates = styled.div`
	display:flex;

`

const Time = styled.div`
	color:${props=>props.selected?'white':'#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props=>props.first?'1px solid #6a00ff':'none'};
	background-color:${props=>props.selected?'#6a00ff':'none'};
	cursor:pointer;
`