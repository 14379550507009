import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { Icon } from 'semantic-ui-react';

import Layers from 'assets/images/UI/layers.svg';
import Logo_grey from 'assets/images/UI/logo_grey.svg';
import User_grey from 'assets/images/UI/user_grey.svg';
import { useNavigate } from 'react-router-dom';


const SelectionScreen = () =>{
    const navigate = useNavigate();

    return (
        <Container>
            <Blue1>AI Toolkit</Blue1>
            <T1>
                Pulse AI Surveys
            </T1>
            <Content>
                <Card>
                    <IMG src={Layers}/>
                    <Title>Library</Title>

                    <Description>
                        Choose from a variety of pre-built AI pulse surveys, built by experts in organizational psychology and culture.
                    </Description>

                    <Action onClick={()=>navigate('/leader/audit/survey-library')}>
                        <Button>Next <Icon name="arrow right"/></Button>
                    </Action>

                </Card>

                <Card>
                    <IMG src={Logo_grey}/>
                    <Title>AI Builder</Title>
                    <Description>
                        Use our AI builder to create your own pulse survey based on your data and insights.
                    </Description>

                    <Action onClick={()=>navigate('/leader/audit/pulsebuilder')}>
                        <Button>Next <Icon name="arrow right"/></Button>
                    </Action>


                </Card>

                <Card>
                    <IMG src={User_grey}/>
                    <Title>Self Guided</Title>

                    <Description>
                        Use our self guided builder to create your own pulse survey based on the questions you think are best.
                    </Description>

                    <Action onClick={()=>navigate('/leader/audit/manage/tasktemplates/create')}>
                        <Button>Next <Icon name="arrow right"/></Button>
                    </Action>


                </Card>
            </Content>
        </Container>
    )
}

export default SelectionScreen;




const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;



const Container = styled.div`
    font-family: 'Raleway';
    padding: 20px;
    margin-top: 20px;

     opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`

const Content = styled.div`
    max-width: 1200px;
    display:flex;
    justify-content: space-between;

`

const Card = styled.div`
    background-color:#F8FAFF;
    padding: 20px;
    border-radius: 10px;
    width:calc(33% - 10px);
    position:relative;
    padding-bottom:60px;
`

const BlueTitle = styled.div`
    color:#2D70E2;
    font-weight:600;
    margin-bottom:5px;
    font-size:12px;
`

const Title = styled.div`
    color:#2A3039;
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
`

const Description = styled.div`
    color:#2A3039;
    font-size:12px;
`

const T1 = styled.div`
    color:#2A3039;
    font-size:32px;
    margin-bottom:30px;
    font-weight:600;
`

const Blue1 = styled.div`
    font-size: 14px;
    color: #2D70E2;
    font-weight: 600;
    margin-bottom: 10px;
`

const Action = styled.div`
    position:absolute;
    bottom:20px;
    right:20px;
    cursor:pointer;
`

const Button = styled.div`
    color:#2D70E2;
    font-weight:bold;
`

const IMG = styled.img`
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
`