import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { SketchPicker } from 'react-color';
import ClickAwayListener from "react-click-away-listener";


const ColorPicker = ({ color, setColor }) => {

	const [open, setOpen] = useState(false)


	return (
		<div style={{ position: 'relative' }}>
			<ClickAwayListener onClickAway={() => setOpen(false)}>
				<ColorBlock color={color} onClick={() => setOpen(!open)}>

				</ColorBlock>

				<Container open={open}>
					<SketchPicker
						color={color}
						onChangeComplete={(c) => setColor(c)}
					/>
				</Container>
			</ClickAwayListener>
		</div>
	)
}


export default ColorPicker

const ColorBlock = styled.div`
	width:40px;
	height:40px;
	background-color:${props => props.color};
	cursor:pointer;
`

const Container = styled.div`
	position:absolute;
	left:0;
	bottom:10px;
	display:${props => props.open ? 'flex' : 'none'};
`