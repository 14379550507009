import styled, { keyframes } from 'styled-components';
import React from "react";



const Component5 = () =>{

    return (
        <Container>

            <T1>Psychological Safety Explained.</T1>
            <Text delay={0.5}>
               Research shows that culture is changeable, and there are many ways to improve it. Here are some suggestions for how to get started. We've curated a few resources for you to take a deeper dive into each topic.
            </Text>


                <Card delay={1}>
                <iframe
                    width="700"
                    height="300"
                    src={'https://www.youtube.com/embed/RcGkHrPSzDc'}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    /> 
                    
                    <T2>
                      Psychological safety is one of the most important factors in a team's success. It's the belief that you can take risks and be vulnerable in front of your team without fear of judgement or punishment.
                    </T2>
                </Card>

            
        </Container>
    )
}

export default Component5

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    line-height:1.4;
    display:flex;
    align-items:center;
    justify-content:space-between;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`


const Card = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    background-color:#EBF1FD;
    margin-right:20px;
    border-radius:10px;
    overflow:hidden;
    margin-top:20px;
    padding:20px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-left:20px;
    width:40%;
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

