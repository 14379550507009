import styled, { keyframes } from 'styled-components';
import React from "react";
import Progress from './Progress'

import Chart from "react-apexcharts";

const Component15 = () =>{
  const [selected,setSelected] = React.useState(false)


    return (
        <Container>

            <T1>Choose how you would like to stay engaged</T1>
            <Text delay={0.5}>
             <Blue>Implementing your plan is critical! </Blue>
             We'd love to support you in your journey in a way that works best for you. How would you like to be kept engaged in your plan?
            </Text>

            <ButtonArea>
            <Button delay={1.5}>
               Email me actions weekly
            </Button>

            <Button  delay={1.5}>
               Actively coach me through my plan
            </Button>

            <Button  delay={1.5}>
               Connect with a culture expert every 2 weeks for 30 minutes
            </Button>

            <Button  delay={1.5}> 
                I'd like an accountability partner
            </Button>

            <Button  delay={1.5}> 
                I'll take care of my plan on my own
            </Button>

           
               
        </ButtonArea>

           
        
            

           

           
           

            
        </Container>
    )
}

export default Component15

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
    padding-top:20px;
    padding-bottom:150px;

`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:40px;
    margin-top:10px;
    line-height:1.6;
    opacity:0;
    max-width:800px;


    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Description = styled.div`
    line-height:1.6;
    background-color:#D4F5ED;
    padding:20px;
    border-radius:10px;
    margin-top:20px;
    max-width:600px;
`

const Content = styled.div`
  width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T2 = styled.div`
    font-size:14px;
    font-weight:bold;
    color:#2A3039;
    margin-top:20px;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;
    animation: ${fadeIn} 1s ease-out forwards;
`

const ChartContainer = styled.div`
    width:100%;
   
    margin-top:40px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`


const Blue = styled.span`
    color:#2D70E2;
    font-weight:500;
`


const Button = styled.div`
    color:#2A3039;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-right:20px;
    font-family:'Raleway';
    margin-top:20px;
    opacity:0;
    border:1px solid #2A3039;
    padding:20px;

    animation: ${fadeIn} 1s ease-out forwards;
    animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const ButtonArea = styled.div`
  display:flex;
  flex-direction:column;

`