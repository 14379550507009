import React from "react";
import styled from "styled-components";

// import moment from "moment";
import { format, formatDistance, subDays } from "date-fns";
// import getISODay from "date-fns/getISODay";
import formatISO from "date-fns/formatISO";

import { useDispatch } from "react-redux";
// import { Icon, Button, Modal, Dropdown } from "semantic-ui-react";
import Select from "react-select";

import { SG_EDIT_SURVEY_COMPLIANCE } from "constants/actions";

// const DayOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const SurveyDate = (props) => {
  const dispatch = useDispatch();
  const [weekDays, setWeekDays] = React.useState([]);
  const [loading, setLoading] = React.useState(false)
  const { 
    // globalSchedule, 
    organizationId,
    surveyDateList,
    intelligence, changeDateofWeek,
    dateChanged,
    setDateChanged 
  } = props;
  // const onWeekDay = changeDateofWeek;
  const [selectedDate, setSelectedDate] = React.useState(false);

  // console.log(changeDateofWeek)
  // let RevisedDayOfWeek = 0
  // if (changeDateofWeek < 6) {
  //   RevisedDayOfWeek = changeDateofWeek + 1
  // }

// console.log(props.globalSchedule)

// console.log(`${props.globalSchedule?.hour}:00`);
// console.log(props.organization?.time_zone);

// console.log(surveyDateList)

React.useEffect(() => {
  let _weekdays = [];
  surveyDateList.map((newDate) => {
    // console.log(newDate)
    // console.log(new Date(newDate))
    // console.log(format(new Date(newDate), "yyyy-MM-dd"))
     return  _weekdays.push({
      key: new Date(newDate),
      value: format(new Date(newDate), "yyyy-MM-dd"),
      text: `${format(new Date(newDate), "yyyy-MM-dd EEEE")} ${formatDistance(
        subDays(new Date(new Date(newDate)), 0),
        new Date(),
        { addSuffix: true }
      )}`,
    });
  }
  )

  setWeekDays(_weekdays);
}, [surveyDateList]);
 
// React.useEffect(() => {
//     let _weekdays = [];

//     let numberOfDaysToAdd = 90;
//     processArray(numberOfDaysToAdd);

//     async function processArray(items) {
//       for (let i = 0; i < items; i++) {
//         await delayedLog(i);
//       }
//     }

//     async function delayedLog(item) {
//       // notice that we can await a function
//       // that returns a promise
//       let newDate = await delay(item);
//       // let someDate = new Date(newDate);
//       // console.log(newDate.getDay())
//       // console.log(format(newDate, "yyyy-MM-dd EEEE"))

//       if (newDate.getDay() === RevisedDayOfWeek) {
//         _weekdays.push({
//           key: format(newDate, "yyyy-MM-dd"),
//           value: format(newDate, "yyyy-MM-dd"),
//           text: `${format(newDate, "yyyy-MM-dd EEEE")} ${formatDistance(
//             subDays(new Date(newDate), 0),
//             new Date(),
//             { addSuffix: true }
//           )}`,
//         });
//       }
//     }
//     function delay(i) {
      
//       let today = new Date();
//       let year = today.getFullYear();
//       let month = today.getMonth() + 1;
//       // let dayOfWeek = today.getDay();
//       let date =today.getDate();
//       // let hour = today.getHours();
      
//       // console.log(today, today.getMonth())
//       // console.log(today.getUTCDate(), today.getUTCHours())
//       // console.log("year", year, "month", month, "day", dayOfWeek, "date", date, "hour", hour)
      
//       // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

//       let someDate = new Date(`${year}-${month}-${date} ${props.globalSchedule?.hour}:00`);
//       // console.log(someDate.setDate(someDate.getDate() + i))
//       let NewDate = new Date(someDate.setDate(someDate.getDate() + i));
//       // console.log(NewDate.getDay())
//       return NewDate
//     }
//     setWeekDays(_weekdays);

//   }, [RevisedDayOfWeek, props.globalSchedule]);

  // console.log(props.globalSchedule)
  // console.log(`${props.globalSchedule.hour}:00`)

  // console.log(dateChanged)
  function UpdateActiveStatus(e) {
    // console.log(e.key)
    // console.log(intelligence)

    setLoading(true)
    // setDateChanged(e)
    setLoading(true)
    setDateChanged(e.key)

    setSelectedDate(
      weekDays.find(
        ({ key }) =>
          key ===
          format(new Date(e.key), "yyyy-MM-dd")
      )
    );

    intelligence.map((int) =>
      dispatch({
        type: SG_EDIT_SURVEY_COMPLIANCE,
        payload: {
          id: int.id,
          scheduled_at: e.key,
          organization: organizationId,
        },
      })
    );

    setLoading(false)

  }

  // React.useEffect(() => {
  //   // setSelectedDate(
  //   //   weekDays.find(
  //   //     ({ key }) =>
  //   //       key ===
  //   //       format(new Date(e.key), "yyyy-MM-dd")
  //   //   )
  //   // );
  // }, [weekDays]);
  // React.useEffect(() => {
  //   if (weekDays.length > 0) {
  //     if (dateChanged) {
  //       setSelectedDate(
  //         weekDays.find(
  //           ({ key }) =>
  //             key ===
  //             format(new Date(dateChanged.key), "yyyy-MM-dd")
  //         )
  //       );
  //     } else {
  //       setSelectedDate(
  //         weekDays[1]
  //       );

  //       // intelligence.map((int) =>
  //         // dispatch({
  //         //   type: SG_EDIT_SURVEY_COMPLIANCE,
  //         //   payload: {
  //         //     id: int.id,
  //         //     scheduled_at: formatISO(new Date(weekDays[1].key)),
  //         //   },
  //         // }
  //         // )
  //       // );

  //     }

  //     setLoading(false)
  //   }
  // }, [dispatch, intelligence, weekDays, dateChanged]);

  // console.log(intelligence)
  // intelligence.map((int) => {
  //   if (int) {
  //     console.log(format(new Date(int?.scheduled_at), "yyyy-MM-dd"));
  //   }
  //   return null;
  // });
  // console.log("Date Changed")
  // console.log(dateChanged)
  // console.log(weekDays)


  return (
    <div style={{ width: "80%" }}>
      <Select
        placeholder="Select next Date"
        onChange={UpdateActiveStatus}
        value={selectedDate}
        options={weekDays}
        isLoading={loading}
        getOptionLabel={(option) => option.text}
        getOptionValue={(option) => option.key}
      />
    </div>
  );
};

export default SurveyDate;

export const ContainerCard = styled.div`
  height: 75px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
`;
