import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const isStoryCompleted = (storyId, logs) => {
  let completed = false;
  logs?.data_story_logs?.results?.forEach((log) => {
    if (log.data_story_id === storyId) {
      completed = !!log.user_interactions.Completed_count;
    }
  });
  return Boolean(completed);
};

const Stories = ({ stories }) => {
  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    core_data,
    story_logs,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
      get_employee_permission: state.employee_permission,
      pending_stories: state.schedule_reports?.pending_schedule_reports,
      story_logs: state.story_logging,
    }),
    shallowEqual
  );

  return (
    <Container>
      <Title>Data Stories</Title>
      <Description>
        These are the data stories that you have been granted access to.
      </Description>
      {stories?.map((story) => {
        return (
          <StoryItem
            {...story}
            isComplete={isStoryCompleted(story.storyId, story_logs)}
          />
        );
      })}
    </Container>
  );
};

const StoryItem = ({
  title,
  dateCreated,
  status,
  storyId,
  category_option,
  isComplete,
}) => {
  const navigate = useNavigate();
  return (
    <StoryContainer>
      <StoryTitle>{title}</StoryTitle>
      <StoryDate>{dateCreated}</StoryDate>
      <StoryStatus
        style={{
          backgroundColor: isComplete ? "#F1F8FF" : "#FFFAF1",
          color: isComplete ? "#2D70E2" : "#FEA711",
        }}
      >
        {isComplete ? "Completed" : "Unread"}
      </StoryStatus>

      <Button
        onClick={() =>
          navigate(
            `/simple/audit/reports/stories/${storyId}/${category_option}`
          )
        }
      >
        View
      </Button>
    </StoryContainer>
  );
};

export default Stories;

const Container = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Raleway;
`;

const Description = styled.div`
  margin-bottom: 40px;
`;

const StoryContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 8px 20px 0px #3f86fe14;
  justify-content: space-between;
  width: 100%;
`;

const StoryTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  width: 40%;
`;

const StoryDate = styled.div`
    font-size:14px;
    color:#666D79;
`

const StoryStatus = styled.div`
    font-size:14px;
    background-color:#FFFAF1;
    color:#FEA711;
    padding:4px 8px;
    border-radius:5px;
    font-weight:600;
`
const Button = styled.button`
    color:#2D70E2;
    font-size:12px;
    padding:6px 10px;
    font-weight:600;
    cursor:pointer;
    font-family:'Raleway';
    border:none;
    border-radius:5px;
    margin-left:20px;
`