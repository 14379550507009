import styled, { keyframes } from 'styled-components';
import React from "react";
import LogoIMG from 'assets/images/logo_full.png'


const Component1 = () =>{

    return (
        <Container>
            <IMG src={LogoIMG}/>
            <T1 delay={0.3}>Welcome to your innerlogic account</T1>
            <Text delay={0.3}>
                Innerlogic is a powerful tool that helps you understand your data and make better decisions. We're here to help you manage and improve your culture.
            </Text>

            <Text delay={0.3}> 
                Before you access your account, we're going to walk you through a few things:
            </Text>

            <CardContainer>
                <Card delay={0.5}>
                    <Num>1</Num>
                    <Title>Discover innerlogic</Title>
                    <T2>
                        Learn how innerlogic hleps organizations scale their culture, and provide tools for leaders to make better decisions.
                    </T2>
                </Card>

                <Card delay={1}>
                    <Num>2</Num>
                    <Title>Answer a few questions</Title>
                    <T2>
                        We'll ask a few questions to understand your role and comfort with data. This will help us provide a more tailored experience.
                    </T2>
                </Card>

                <Card delay={1.5}>
                    <Num>3</Num>
                    <Title>Get started</Title>
                    <T2>
                        Jump into your data stories, dashboards, and culture roadmaps and be on your way to achieving your culture goals. 
                    </T2>
                </Card>
            </CardContainer>
        </Container>
    )
}

export default Component1

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    font-family:'Raleway';
`
const IMG = styled.img`
    width:120px;
    margin-bottom:20px;
`

const Text = styled.div`
    font-size:16px;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    
    line-height:1.5;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const CardContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:40px;
`

const Card = styled.div`
    width:300px;
    padding:25px;
    display:flex;
    flex-direction:column;
    margin-right:20px;
    border-radius:10px;
    height:250px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const Title = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:5px;
`

const T2 = styled.div`
    font-size:12px;
    color:#2A3039;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:10px;

    opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
   animation-delay: ${({ delay }) => delay}s; // Use props to set delay

`

const Num = styled.div`
    font-size:40px;
    font-weight:600;
    margin-bottom:40px;
    color:#2254AA;
`