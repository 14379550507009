import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import {Modal,Button,Dropdown} from 'semantic-ui-react'



const AddUser = () =>{
    const [open, setOpen] = React.useState(false)
    const [users,setUsers] = useState([])


    const add = () =>{
        setUsers([...users,{name:'John Doe',data_access:'Department A'}])
    }



    return (
        <Modal 
            trigger={<Button>Add member</Button>}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            size="small"
            closeIcon
            open={open}>
            <Modal.Header>Add members to share data</Modal.Header>
            <Modal.Content>
                <Modal.Description>

                    <Title>Select members and data access</Title>
                    <Description>
                       Select one or more members you'd like to give data access to. The category you select will be the only data this user can access.
                    </Description>
                    <Row>

                    <DropdownContainer>
                        <Label>Member</Label>
                        <Dropdown
                            placeholder='Select Country'
                            fluid
                            search
                            selection
                            options={countryOptions}
                        />
                    </DropdownContainer>
                   
                    <DropdownContainer>
                        <Label>Data access</Label>
                        <Dropdown
                        placeholder='Select Country'
                        fluid
                        search
                        selection
                        options={countryOptions}
                    />
                    </DropdownContainer>

                    <Button onClick={()=>add()} style={{height:35}}>+ Add</Button>
                    
                    </Row>

                    <UserTable>
                        <Header>
                            <T1 width={40}>Member</T1>
                            <T1 width={60}>Data Access</T1>
                        </Header>

                        {users.map((user,i)=>{
                            return <Row2 key={i}>
                                <T2 width={40}>{user.name}</T2>
                                <T2 width={60}>{user.data_access}</T2>
                            </Row2>
                        } )}
 
                    </UserTable>
                    
                  
                    <Bottom/>
                </Modal.Description>
            </Modal.Content>

            <Modal.Actions>

                <Button onClick={() => setOpen(false)}>Cancel</Button>

                <Button
                    content="Save"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() => setOpen(false)}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default AddUser


const Title = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:5px;
    font-family: 'Raleway', sans-serif;
`

const Description = styled.div`
    font-size:14px;
    margin-bottom:10px;
    font-family: 'Raleway', sans-serif;
`
const SelectArea = styled.div`
    margin-top:20px;
`

const Row = styled.div`
    display:flex;
    align-items:flex-end;
    margin-top:30px;
`

const Row2 = styled.div`
    display:flex;
    border-top:1px solid #E0E0E0;
`

const ButtonRow = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:10px;
`

const DropdownContainer = styled.div`
    margin-right:20px;
`

const Label = styled.div`
    font-size:12px;
    font-family: 'Raleway', sans-serif;
`

const UserTable = styled.div`
    width:100%;
    border:1px solid #E0E0E0;
    font-family: 'Raleway', sans-serif;
    margin-top:40px;
`

const Header = styled.div`
    display:flex;
    width:100%;
`

const T1 = styled.div`
    width:${props => props.width}%;
    padding:10px;
    font-weight:bold;
    border-right:1px solid #E0E0E0;
`

const T2 = styled.div`
    width:${props => props.width}%;
    padding:10px;
    border-right:1px solid #E0E0E0;
`

const Bottom = styled.div`
    margin-top:50px;
`

const countryOptions = [
    { key: 'af', value: 'af', flag: 'af', text: 'Afghanistan' },
    { key: 'ax', value: 'ax', flag: 'ax', text: 'Aland Islands' },
    { key: 'al', value: 'al', flag: 'al', text: 'Albania' },
    { key: 'dz', value: 'dz', flag: 'dz', text: 'Algeria' },
    { key: 'as', value: 'as', flag: 'as', text: 'American Samoa' },
    { key: 'ad', value: 'ad', flag: 'ad', text: 'Andorra' },
    { key: 'ao', value: 'ao', flag: 'ao', text: 'Angola' },
    { key: 'ai', value: 'ai', flag: 'ai', text: 'Anguilla' },
    { key: 'ag', value: 'ag', flag: 'ag', text: 'Antigua' },
    { key: 'ar', value: 'ar', flag: 'ar', text: 'Argentina' },
    { key: 'am', value: 'am', flag: 'am', text: 'Armenia' },
    { key: 'aw', value: 'aw', flag: 'aw', text: 'Aruba' },
    { key: 'au', value: 'au', flag: 'au', text: 'Australia' },
    { key: 'at', value: 'at', flag: 'at', text: 'Austria' },
    { key: 'az', value: 'az', flag: 'az', text: 'Azerbaijan' },
    { key: 'bs', value: 'bs', flag: 'bs', text: 'Bahamas' },
    { key: 'bh', value: 'bh', flag: 'bh', text: 'Bahrain' },
    { key: 'bd', value: 'bd', flag: 'bd', text: 'Bangladesh' },
    { key: 'bb', value: 'bb', flag: 'bb', text: 'Barbados' },
    { key: 'by', value: 'by', flag: 'by', text: 'Belarus' },
    { key: 'be', value: 'be', flag: 'be', text: 'Belgium' },
    { key: 'bz', value: 'bz', flag: 'bz', text: 'Belize' },
    { key: 'bj', value: 'bj', flag: 'bj', text: 'Benin' },
  ]