import React from "react";
import { View, Text, StyleSheet, Page } from "@react-pdf/renderer";
import Table from "../components/Table";
import Footer from "../components/Footer";
import {
  getDeiAvg,
  getFactorAverage,
  getFactorIndex,
  getGrade,
  getFactorNValue,
} from "../utils/calculations";
import Side from "../components/Side";

// Create styles
const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#353740",
    marginBottom: 15,
  },
  page: {
    padding: 60,
    width: "100%",
    position: "relative",
  },
  p: {
    fontSize: 12,
    marginBottom: 15,
  },
  T2: {
    fontSize: 20,
    fontWeight: 700,
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#3076B8",
    marginTop: 5,
  },
  description: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 15,
  },
  section: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: "#F1F1F7",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 16,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 16,
  },
  S1: {
    fontSize: 10,
    marginRight: 15,
  },
  img: {
    height: 20,
  },
  offset: {
    width: "100%",
  },
  summary: {
    border: "2px solid #353740",
    borderRadius: 5,
    marginTop: 20,
    marginBottom: 20,
  },
  top: {
    backgroundColor: "#353740",
    color: "white",
    fontSize: 16,
    fontWeight: 700,
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  bottom: {
    padding: 10,
    marginTop: 20,
  },
  steps: {
    width: "90%",
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  square: {
    height: 6,
    width: 6,
    backgroundColor: "#353740",
    marginLeft: 20,
    marginRight: 10,
    marginTop: 5,
  },
});

const tableTemplate = {
  rows: [
    { title: "Factors", width: "30%" },
    { title: "Norm", width: "20%" },
    { title: "Effect size", width: "20%" },
    { title: "Score", width: "20%" },
    { title: "Grade", width: "10%" },
  ],
};

// Pass param to chart to hide/show norm
const Methods = (props) => {
  const { questions, responseData } = props;
  const sections = questions.factors.map((f) => {
    return {
      title: f.title,
    };
  }, []);

  const overallAverage = getDeiAvg(sections, responseData, questions);
  const tableData = sections.map((section) => {
    const score = getFactorAverage(
      getFactorIndex(section.title, questions),
      responseData,
      questions
    );

    return [
      `${section.title}`,
      `--`,
      `--`,
      `${Math.floor(score)}`,
      `${getGrade(score)}`,
    ];
  });

  tableTemplate.data = tableData;

  return (
    <Page style={styles.page}>
      <Footer />
      <Side />
      <Text style={styles.T2}>Diversity, Equity & Inclusion Summary</Text>
      <View style={styles.line} />

      <View style={styles.summary}>
        <View style={styles.top}>
          <Text>
            Overall Score: {Math.floor(overallAverage)}% (
            {getGrade(overallAverage)})
          </Text>
          <Text>
            N:
            {getFactorNValue(0, responseData, questions)}
          </Text>
        </View>
        <View style={styles.bottom}>
          <Text style={{ fontSize: "10", fontWeight: 700, marginBottom: 5 }}>
            Factor Summary
          </Text>
          <Table data={tableTemplate} />
          <Text style={{ fontSize: "10", fontWeight: 700 }}>
            About this assessment
          </Text>
          <Text style={{ fontSize: 10 }}>
            Beef drumstick capicola, pork chop frankfurter tail rump picanha
            fatback pork. Tongue tenderloin bresaola beef ribs, ground round
            frankfurter chuck short loin kielbasa. Fatback bresaola drumstick
            rump tenderloin turkey, picanha beef shoulder jowl sirloin cow flank
            swine buffalo. Pork chop pastrami tenderloin kielbasa. Cow doner
            landjaeger short ribs, corned beef tenderloin kielbasa sirloin
            pancetta pig bresaola beef ground round meatball. Filet mignon flank
            t-bone picanha tri-tip.
          </Text>
        </View>
      </View>

      <Text style={styles.title}>Performance metrics</Text>

      <Text style={styles.p}>
        This table contains overall performance metrics for the nuLogic
        Diversity, Equity, and Inclusion assessment. The following information
        appears on all nuLogic reports and is not specific to this
        organization's individual results
      </Text>
      <Table data={tableTemplate} />

      <View wrap={false}>
        <Text style={styles.title}>Methods</Text>

        <Text style={styles.p}>
          The methodology of the Sport Culture Index (SCI) is grounded in
          evidence supporting the notion that most meaningful sport culture
          variables fall into two primary categories: people factors (i.e.,
          dynamics, sentiments, and relationships) and performance factors
          (i.e., processes, practices, and structures). The SCI allows sport
          organizations to understand where they are indexing on the primary
          categories of people and performance factors, helping them gain an
          understanding of their overall organizational approach. Further, the
          SCI provides detailed analytics and reporting on all supporting
          factors and sub-factors within each category, which can be further be
          analyzed by any number of custom demographics This report shows the
          aggregate scores of all culture pillars and questions in the system,
          organized by the segments selected on this report (i.e., role, gender,
          experience, etc.).The goal of this report is to provide reliable,
          evidence-based information to guide cultural action steps and
          transformation strategies.
        </Text>
      </View>

      <Text style={styles.title}>Next Steps</Text>

      <Text style={styles.p}>
        This table contains overall performance metrics for the nuLogic
        Diversity, Equity, and Inclusion assessment. The following information
        appears on all nuLogic reports and is not specific to this
        organization's individual results
      </Text>

      <View style={styles.steps}>
        <View style={styles.square} />
        <Text style={styles.p}>
          This table contains overall performance metrics for the nuLogic
          Diversity, Equity, and Inclusion assessment. The following information
          appears on all nuLogic reports and is not specific to this
          organization's individual results
        </Text>
      </View>

      <View style={styles.steps}>
        <View style={styles.square} />
        <Text style={styles.p}>
          This table contains overall performance metrics for the nuLogic
          Diversity, Equity, and Inclusion assessment. The following information
          appears on all nuLogic reports and is not specific to this
          organization's individual results
        </Text>
      </View>

      <View style={styles.steps}>
        <View style={styles.square} />
        <Text style={styles.p}>
          This table contains overall performance metrics for the nuLogic
          Diversity, Equity, and Inclusion assessment. The following information
          appears on all nuLogic reports and is not specific to this
          organization's individual results
        </Text>
      </View>

      <View style={styles.steps}>
        <View style={styles.square} />
        <Text style={styles.p}>
          This table contains overall performance metrics for the nuLogic
          Diversity, Equity, and Inclusion assessment. The following information
          appears on all nuLogic reports and is not specific to this
          organization's individual results
        </Text>
      </View>
    </Page>
  );
};

export default Methods;
