import styled from "styled-components";
import React from "react";
import {Icon} from 'semantic-ui-react'
import Back from './Back'
import TopProgress from './TopProgress'
import template from './template_demo.json'

const Side = ({open,setOpen,setStage,stage}) =>{

    const [section,setSection] = React.useState(2)

    return (
        <Container open={open}>
            <IconContainer onClick={()=>setOpen(!open)}>
                <Icon name={open?"chevron left":"chevron right"} style={{margin:0,marginTop:-3}}/>
            </IconContainer>

        {open &&
                <SideContent>
                    
                    <Title onClick={()=>setSection(1)}>Data story - Jan 5th, 2024</Title>
                  
                    <TopProgress 
                        step={stage} 
                        steps={template}
                        setStep={(s)=>setStage(s)}
                        open={section==1} />


                    <Title onClick={()=>setSection(2)}>Introduction to data journey</Title>
                  
                    <TopProgress 
                        step={stage} 
                        steps={template}
                        setStep={(s)=>setStage(s)}
                        open={section==2} />
                </SideContent>
            }
        </Container>
    )

}

export default Side

const Container = styled.div`
    height:calc(100vh - 200px);
    position:fixed;
    left:0px;
    top:100px;
    border-radius: ${props=>props.open? '0px 15px 15px 0px' :'0px 5px 5px 0px'};
    width:${props=>props.open?'300px':'30px'};
    background-color:#EBF1FD;
    display:flex;
    justify-content:center;
    transition:all 0.3s ease-in-out;
    overflow:hidden;
    
`

const IconContainer = styled.div`
    cursor:pointer;
    height:30px;
    width:30px;
    position:absolute;
    right:0px;
    top:0px;
    display:flex;
    justify-content:center;
    align-items:center;

    &:hover{
        background-color:#E0E0E0;
    }
`

const Title = styled.div`
    font-size: 15px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 20px;
    color:#2A3039;
    cursor:pointer;
`

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    font-family: 'Raleway';
    margin-bottom:30px;
`
const SideContent = styled.div`
    width:300px;
    padding:30px;
    padding-left:40px;
    background-color:#EBF1FD;
`