import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";


const ReportType = ({title,description,typeTitle}) =>{

        return (
            <Container>
                <Title>{title}</Title>
                <Description>{description}</Description>

                <Length>4 pages</Length>

                <ButtonContainer>
                    <Tags>
                        <TypeTitle>{typeTitle}</TypeTitle>
                    </Tags>
                    <Button>Create Report</Button>
                </ButtonContainer>

            </Container>
        )

}


export default ReportType

const Container = styled.div`
    width:350px;
    border:1px solid #E0E0E0;
    border-radius:10px;
    padding:20px;
    margin-bottom:20px;
    margin-right:10px;
`

const TypeTitle = styled.div`
    font-size:10px;
    font-weight:bold;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    border:1px solid #E0E0E0;
    border-radius:5px;
    padding:5px 10px;
`

const Title = styled.div`
    font-size:20px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`

const Description = styled.div`

`

const ButtonContainer = styled.div`
    display:flex;
    margin-top:20px;
    justify-content:space-between;
    align-items:center
`

const Button = styled.div`
    color:blue;
    font-size:14px;
    font-weight:600;
    margin-right:20px;
    cursor:pointer;
    font-family:'Raleway';
`

const Tags = styled.div`
    display:flex;
    justify-content:flex-start;
    align-items:center;
`

const Length = styled.div`
    font-size:12px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:10px;
    margin-top:10px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`