import styled, { keyframes } from "styled-components";
import React from "react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const WhatPeopleSay = (props) => {
  const themes = props?.activeJourney?.activeJourney?.response?.themes;

  // Parse the text from the themes object
  // and create an array of objects, each object should be parsed from the numbers within the text, and contain 2 properties: boldText and normalText
  // The boldText should start from the number and end at the first colon (:) in the text, remove the number.
  const parsedThemes = themes?.text?.split("\n").map((text) => {
    const boldText = text.match(/^\d+\.\s(.+):/)?.[1];
    const normalText = text.replace(/^\d+\.\s(.+):/, "").trim();
    return { boldText, normalText };
  });

  // Remove any objects of the array that contain undefined or null values
  parsedThemes.forEach((theme, index) => {
    if (!theme.boldText || !theme.normalText) {
      parsedThemes.splice(index, 1);
    }
  });

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        Heres what people were saying about your team's{" "}
        {themes?.factor_name?.toLowerCase()}.
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Theme summary from your data
      </SubtextExtended>

      <UL delay={0.8}>
        {parsedThemes.map((theme, index) => (
          <LI key={index}>
            <strong>{theme.boldText}:</strong> {theme.normalText}
          </LI>
        ))}
      </UL>
    </ScreenContainer>
  );
};

export default WhatPeopleSay;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const UL = styled.ul`
  margin-top: 20px;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const LI = styled.li`
  margin-bottom: 20px;
  line-height: 1.4;
`;
