import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "components/CWFPDF/translations";

const SummaryTable = ({ title, average, title2, data, headers, isFrench }) => {
  const lang = isFrench ? "fr" : "en";

  return (
    <Container>
      <Top>
        <TH width={48}>{title}</TH>
        <TH width={10}>{average.toFixed(1)}</TH>
        <TH width={45}>{title2}</TH>
      </Top>

      <Top>
        <TH width={48}></TH>
        <TH width={10}>AVG</TH>
        {headers.map((item) => (
          <TH width={45 / headers.length}>{item}</TH>
        ))}
      </Top>

      <Content>
        {data.data.map((row) => {
          return (
            <Row>
              <TD width={48} left={true}>
                {getTranslatedText(row.factor, lang, translations)}
              </TD>
              <TD width={10}>
                {isNaN(row.factorAverage) ? "--" : row.factorAverage.toFixed(1)}
              </TD>
              {row.factorResponses.map((col, i) => {
                return (
                  <TD width={45 / headers.length}>
                    {isNaN(col.average) ? "--" : col.average.toFixed(1)}
                  </TD>
                );
              })}
            </Row>
          );
        })}
      </Content>
    </Container>
  );
};

export default SummaryTable;

const Container = styled.div`
  width: 100%;
  border: 1px solid lightgrey;
  margin-bottom: 30px;

  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid lightgrey;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  height: 30px;
  font-size: 12px;
  border-right: 1px solid lightgrey;
`;

const Content = styled.div``;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid lightgrey;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  justify-content: ${(props) => (props.left ? "flex-start" : "center")};
  align-items: center;
  height: 30px;
  font-weight: 300;
  font-size: 12px;
  padding-left: ${(props) => (props.left ? "10px" : 0)};
  border-right: 1px solid lightgrey;
`;
