import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
} from "reports/DataJourney/components/common/styles";

const HowTheyWork = () => {
  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        How data journeys work.
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        The journey to change starts with a single step. We'll guide you through
        the process of understanding your data, and how to use it to make better
        decisions. We'll send you a series of emails, each with a new insight,
        activity, or check-in to help you along the way. All of this takes no
        longer than 10 minutes a week.
      </SubtextExtended>

      {/* <Img
        delay={0.7}
        src={
          "http://res.cloudinary.com/dljz0lko8/image/upload/fl_sanitize/v1605798323/svg/Group_427_qhrocn.svg"
        }
      /> */}
    </ScreenContainer>
  );
};

export default HowTheyWork;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;