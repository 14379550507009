import React from "react";
import styled from "styled-components";

const Suggestions = ({ suggestions, onSuggestionClick }) => {
  //  Suggestions needs to be parsed, as it is a json string.

  if (!suggestions || suggestions.length === undefined) return null;
  let parsedSuggestions = JSON.parse(suggestions);

  if (!parsedSuggestions || parsedSuggestions.length === 0) return null;

  return (
    <SuggestionsWrapper>
      <Title>Suggested follow up</Title>
      <SuggestionsContainer>
        {parsedSuggestions.map((suggestion, index) => (
          <Suggestion
            key={index}
            onClick={() => onSuggestionClick(suggestion?.prompt)}
          >
            {/* <IconPlaceholder /> */}
            <SuggestionContent>
              <TitleText>{suggestion.title}</TitleText>
              <PromptText>{suggestion.prompt}</PromptText>
            </SuggestionContent>
          </Suggestion>
        ))}
      </SuggestionsContainer>
    </SuggestionsWrapper>
  );
};

// Styled components for the Suggestions

const SuggestionsWrapper = styled.div`
  width: 100%;
  max-width: 800px;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SuggestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0 auto;
`;

const Suggestion = styled.div`
  // background: linear-gradient(135deg, #2c70e1 0%, #27cda7 100%);
  color: #2A3039;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: flex-start; // Align content to the top
  width: calc(50% - 15px); // Two per row, accounting for gap
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  border: 1px solid #e5e5e5;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SuggestionContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden; // Ensures content doesn't overflow container
`;

const IconPlaceholder = styled.div`
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  margin-right: 10px;
`;

const TitleText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom:5px;
`;

const PromptText = styled.div`
  font-size: 14px;
  word-wrap: break-word; // Ensures text wraps within the container
  overflow: hidden;
  text-overflow: ellipsis; // Add ellipsis if it overflows
  display: -webkit-box;
  -webkit-line-clamp: 3; // Limits the text to 3 lines
  -webkit-box-orient: vertical;
`;

export default Suggestions;
