import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/stock/article2.jpg'
import Image2 from 'assets/images/stock/article2.jpg'

const FactorExplain = () =>{

    return (
        <Container>
            

            <T2>What exactly is psychological safety?</T2>

            <T3>Psychological safety is more than just being nice and comfortable…</T3>
            <Description>
            It is the <strong>shared belief</strong> within a team, department, or organization that <strong>it is safe</strong> to 
            take interpersonal risks, such as speaking up, sharing ideas, asking questions, 
            and expressing concerns, <strong>without fear </strong>of negative consequences or judgement.

            </Description>

            <Description>
            Psychologically safe teams are more likely to have:
            </Description>

            <ul>
                <LI>
                    <strong>Enhanced innovation:</strong> Psychological safety encourages fearless idea-sharing, boosting creativity and unconventional thinking in teams.

                </LI>
                <LI>
                    <strong>Increased collaboration:</strong> Trust and openness in psychologically safe teams foster seamless cooperation and information sharing among members.

                </LI>
                <LI>
                    <strong>Higher performance:</strong> A culture where risks and mistakes are accepted leads to higher performance as individuals feel empowered to give their best without fear of judgement.


                </LI>
            </ul>
            <IMG src={Image}/>
           

          
        </Container>
    )

}

export default FactorExplain

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:20px;
    line-height:1.4;
`

const T3 = styled.div`
    font-size:14px;
    font-weight:bold;
`

const Description = styled.div`
margin-top:20px;
`
const D2 = styled.div`
    margin-bottom:20px;
`

const IMG = styled.img`
    width:100%;
    margin-bottom:40px;
`

const LI = styled.li`
    margin-bottom:10px;
`