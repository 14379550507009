import React from "react";
import styled from "styled-components";
import RecommendationPlan from "./RecommendationPlan";

const RecommendationViewer = (props) => {
  const { data, orgName } = props;
  const organization = data?.params?.org_name || orgName;
  const template = data?.params?.template;
  const tags = data?.params?.tags?.join(", ") || null;
  const date = new Date(data?.created_at);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
  }).format(date);

  return (
    <Container>
      <RecommendationPlan
        data={data?.recommendation?.response || data?.recommendation}
        orgName={organization}
        template={template}
        tags={tags}
        scores={data?.survey_data?.scores}
        date={formattedDate.toString()}
      />
    </Container>
  );
};

const Container = styled.div``;

export default RecommendationViewer;
