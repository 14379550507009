import styled, { keyframes } from "styled-components";
import React from "react";
import { Input,Progress } from 'semantic-ui-react'


const Team = (props) =>{


	return (
		<Card selected={props.selected}>
			<UserContainer>
				
					<Name>{props.name}</Name>
					<Impact>Overall</Impact>
					<Progress percent={86} progress size='small' style={{marginBottom:15,marginTop:0}} color='grey'/>
      		
			</UserContainer>


		</Card>
		)
}


export default Team



const Card = styled.div`
	border-radius: 15px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  background-color: #ffffff;
  padding:10px;
  margin-top:10px;
  border:${props=>props.selected?'2px solid #6a00ff':""};
  cursor:pointer;
`

const UserContainer = styled.div`
	display:flex;
	flex-direction:column;
	position:relative;
`

const Avatar = styled.img`
	width:40px;
	height:40px;
	border-radius:20px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
`

const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  color:#4a4a4a;
  font-weight:bold;

`

const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:5px;
	color:#9b9b9b;
`