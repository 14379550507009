import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin-bottom: ${(props) => (props.open ? "20px" : "0px")};
  max-height: ${(props) => (props.open ? "100%" : "0px")};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  height: ${(props) => (!props.isParent ? "auto" : props.total * 40 + "px")};
`;

const ProgressLine = styled.div`
  width: 2px;
  background-color: #e0e0e0;
  position: absolute;
  left: 7.5px; // align with the top of the first circle
  top: 3px;
  height: calc(100% - 6px);
  z-index: 0;
`;

const Step = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const FilledStep = styled(Step)`
  border-color: #007bff;
  background-color: #007bff;
  color: white;
`;

const Checkmark = styled.span`
  font-size: 10px;
`;

const ChildSteps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 20px;
  top: 20px;
  z-index: 1;
  height: auto;
  width: 100%;
`;

const StepLabel = styled.div`
  white-space: nowrap; // prevent text wrapping
  z-index: 2;
  margin-left: 10px;
  font-family: "Raleway";
  color: ${(props) => (props.active ? "#007bff" : "#666D79")};
`;

const ChildStepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const ParentStep = styled.div`
  display: flex;
  align-items: center;
  height: ${(props) => (props.blockHeight ? props.blockHeight : "auto")};
`;

// Helper function to check if the current step is a parent step
const isParentStep = (steps, currentStep) => {
  return steps?.some((step) => step?.step === currentStep);
};

// ProgressTracker component
const ProgressTracker = ({
  steps,
  currentStep,
  setStep,
  open,
  introComplete,
  actionsComplete,
  setIntroComplete,
  setActionsComplete,
  introStage,
}) => {
  const [childOpen, setChildOpen] = useState(false);
  const parentStep = isParentStep(steps, currentStep);
  let currentChildLength;
  let parent;

  if (parentStep) {
    currentChildLength = steps?.length;
  } else {
    // Find the parent step and get the length of the children.
    parent = steps?.find((step) =>
      step?.children?.some((child) => child?.step === currentStep)
    );
    currentChildLength = parent?.children?.length;
  }

  return (
    // If the currentstep is not a parentStep, let the progressBarcontainer grow to fit the children.
    <ProgressBarContainer
      isParent={parentStep}
      total={currentChildLength}
      open={open}
    >
      <ProgressLine />
      {steps.map((step, index) => {
        let isFilled;
        let active = false;
        const childSectionVisible = !introComplete
          ? step.children?.some((child) => child.step === introStage)
          : step.children?.some((child) => child.step === currentStep);

        if (!introComplete) {
          isFilled = step?.screen === "Introduction" && step?.step < introStage;
          active = step?.screen === "Introduction" && step?.step === introStage;
        } else {
          isFilled = step?.step < currentStep;
          active = step?.step === currentStep;
        }

        return (
          <div
            key={index}
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ParentStep>
              {active ? (
                <FilledStep />
              ) : isFilled ? (
                <FilledStep>
                  <Checkmark>✓</Checkmark>
                </FilledStep>
              ) : (
                <Step />
              )}
              <StepLabel
                active={active || isFilled}
                onClick={() => setStep(index)}
              >
                {step.name}
              </StepLabel>
            </ParentStep>
          </div>
        );
      })}
    </ProgressBarContainer>
  );
};

const App = ({
  step,
  steps,
  setStep,
  open,
  introComplete,
  actionsComplete,
  setIntroComplete,
  setActionsComplete,
  introStage,
}) => {
  return (
    <ProgressTracker
      open={open}
      steps={steps}
      currentStep={step}
      setStep={setStep}
      introStage={introStage}
      introComplete={introComplete}
      actionsComplete={actionsComplete}
      setIntroComplete={setIntroComplete}
      setActionsComplete={setActionsComplete}
    />
  );
};

export default App;
