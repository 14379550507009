import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";

import {Modal,Icon} from 'semantic-ui-react';


const Success = ({open,close}) =>{
    return (
        <Modal open={open} onClose={close} size={'tiny'}>
            <Modal.Content>
                <Container>
                    <Icon name='check circle' size='huge' style={{color:'#27CDA7'}}/>
                    <T1>You are all set!</T1>
                    <Description>
                        <strong>Your report has been successfully created.</strong> Our servers are now generating your report. You will receive an email notification once your report is ready.
                        This process typically takes a few minutes. 
                    </Description>
                    <ButtonArea>
                        <Button2>Create another</Button2>
                        <Button onClick={close}>Done</Button>
                    </ButtonArea>
                </Container>
            </Modal.Content>
        </Modal>
    )
}

export default Success

const Container = styled.div`
    font-family:'Raleway';
`

const T1 = styled.div`
    font-size:24px;
    font-weight:600;
    margin-top:20px;
    margin-bottom:10px;
    color:#2A3039;
`

const Description = styled.div`
    line-height:1.5;
`
const ButtonArea = styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:20px;
`

const Button = styled.div`
    padding:10px 20px;
    border-radius:5px;
    background-color:#27CDA7;
    color:white;
    cursor:pointer;
    margin-left:10px;
`

const Button2 = styled.div`
    padding:10px 20px;
    border-radius:5px;
    color:#666D79;
    cursor:pointer;
    margin-left:10px;
`