import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { Icon, Progress, Label } from "semantic-ui-react";
import Create from './Create'

const Models = () => {
	return (
		<Container>
			<Left>
				<Title>Predictive models <Create trigger={<Trigger>Create</Trigger>}/></Title>
				<RiskElement
					title={"Safety Incidents"}
					description={"Random forest model for predicting safety incidents in air traffic controllers."}
					risk={"0.8"}
				/>
				<RiskElement
					title={"Drive through performance"}
					selected={true}
					description={
						" Gradient boost model for predicting drive through performance in fast food restaurants."
					}
					risk={"0.7"}
				/>
                <RiskElement
					title={"Employee retention"}
					selected={false}
					description={
						"Logistic regression for predicting employee retention."
					}
					risk={"0.66"}
				/>
                <RiskElement
					title={"Employee well-being"}
					selected={false}
					description={
						" Random forest model for predicting employee well-being."
					}
					risk={"0.93"}
				/>
                 <RiskElement
					title={"Sports team conference ranking"}
					selected={false}
					description={
						" Naive Bayes model for predicting team conference ranking change."
					}
					risk={"0.77"}
				/>
			</Left>
			<Right>
                <TopSection>
                <LeftPart>
                <T2>Fast food drive through performance model</T2>
                <D1>
					Machine learning generated performance predictions based on culture
					analytics.
				</D1>
                </LeftPart>
                
                <RightPart>
                            <Score>+32%</Score>

                    <T3>Predicted impact on outcomes</T3>
                </RightPart>

                </TopSection>
				
				
				<Chart
					options={data3.options}
					series={data3.series}
					type="area"
					height={400}
				/>

				<SquareContainer>
					<Square right={true}>
						<T3>Factor Insights</T3>
						<D1>Explanatory power of each factor</D1>

						<Factor>
							<FTitle>Empathy & Psychological Safety</FTitle>
							<FDescription>
								Scores in this category have a significant effect on this
								cohorts risk assessment
							</FDescription>
							<Progress percent={45} size="small" progress />
						</Factor>

						<Factor>
							<FTitle>Culture of clarity</FTitle>
							<FDescription>
								Scores in this category have a significant effect on this
								cohorts risk assessment
							</FDescription>
							<Progress percent={25} size="small" progress />
						</Factor>

						<Factor>
							<FTitle>Autonomy</FTitle>
							<FDescription>
								Scores in this category have a significant effect on this
								cohorts risk assessment
							</FDescription>
							<Progress percent={20} size="small" progress />
						</Factor>

						<Factor>
							<FTitle>Employee Impact</FTitle>
							<FDescription>
								Scores in this category have a significant effect on this
								cohorts risk assessment
							</FDescription>
							<Progress percent={10} size="small" progress />
						</Factor>
					</Square>

					<Square>
						<T3>AI Recommendations</T3>
                        <RecommendationElement
                            title={"Empathy"}
                            description={"A plan for improving empathy in the workplace. "}
                            risk={"+10%"}
                        />

                        <RecommendationElement
                            title={"Well-being"}
                            description={"A 5 step plan for improving well-being in the workplace."}
                            risk={"+8%"}
                        />

                    <RecommendationElement
                            title={"Autonomy"}
                            description={"A plan for improving employee autonomy."}
                            risk={"+6%"}
                        />

					</Square>
				</SquareContainer>
			</Right>
		</Container>
	);
};

export default Models;

const Container = styled.div`
	display: flex;
    font-family: "Poppins", sans-serif;
`;

const Left = styled.div`
	width: 400px;
	height: calc(100vh - 80px);
	border-right: 1px solid lightgrey;
	padding: 30px 20px;
`;

const Title = styled.div`
	
	font-size: 20px;
	font-weight: 300;
	margin-bottom: 20px;
	margin-left: 0px;
    display:flex;
    width:100%;
    justify-content:space-between;
`;

const T2 = styled.div`

	font-size: 30px;
	font-weight: 700;
	margin-bottom: 10px;
    line-height: 1.2;
`;
const T3 = styled.div`

	font-size: 20px;
	font-weight: 300;
	margin-bottom: 10px;
`;

const Right = styled.div`
	width: calc(100% - 400px);
	padding: 30px 20px;
`;

const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: lightgrey;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const Trigger= styled.div`
    color:blue;
    cursor:pointer;
    font-size:14px;
`

const RiskElement = ({ title, description, risk, selected }) => {
	return (
		<RiskContainer selected={selected}>
			<RiskTitle>{title}</RiskTitle>
			<Description>{description}</Description>
			<LabelContainer>
				<Label>
					Accuracy: <strong>{risk}</strong>
				</Label>
			</LabelContainer>
		</RiskContainer>
	);
};

const RecommendationElement = ({ title, description, risk, selected }) => {
	return (
		<RiskContainer selected={selected}>
			<RiskTitle>{title}</RiskTitle>
			<Description>{description}</Description>
			<LabelContainer>
				<Label color="violet">
					Predicted impact: <strong>{risk}</strong>
				</Label>
			</LabelContainer>

            <Action>
                Deploy plan <Icon name="pushed"/>
            </Action>
		</RiskContainer>
	);
};

const RiskContainer = styled.div`
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
	width: 100%;
	height: auto;
	border-radius: 20px;
	cursor: pointer;
	font-family: "Barlow", sans-serif;
	padding: 20px;
	display: flex;
	position: relative;
	flex-direction: column;
	margin-top: 10px;
	background-color: ${(props) => (props.selected ? "lightgrey" : "white")};

	&:hover {
		box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
	}
`;

const RiskTitle = styled.div`
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 10px;
    max-width:200px;
    line-height: 1.4;
`;

const LabelContainer = styled.div`
	position: absolute;
	top: 16px;
	right: 20px;
`;
const LabelContainer2 = styled.div`
	position: absolute;
	top: 0px;
	right: 20px;
`;

const ChartContainer = styled.div`
	margin-top: -20px;
	margin-bottom: -30px;
`;

const Description = styled.div``;

const D1 = styled.div`
	font-family: "Barlow", sans-serif;
`;

const SquareContainer = styled.div`
	display: flex;
	border-top: 1px solid lightgrey;
`;

const Square = styled.div`
	border-right: ${(props) => (props.right ? "1px solid lightgrey" : "none")};
	width: 50%;
	height: 600px;
	padding: 20px 10px;
`;

const Score = styled.div`
	font-size: 50px;
	line-height: 1;
	font-family: "Barlow", sans-serif;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
    margin-bottom: 10px;
`;

const Sub = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Barlow", sans-serif;
`;

const Factor = styled.div`
	width: 100%;
	margin-top: 20px;
	padding-right: 20px;
	position: relative;
`;

const FTitle = styled.div`
	font-family: "Barlow", sans-serif;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
`;

const FDescription = styled.div`
	font-family: "Barlow", sans-serif;
	font-size: 12px;
	margin-bottom: 10px;
`;

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const RightPart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LeftPart = styled.div`
    display: flex;
    flex-direction: column;
    max-width:80%;
`

const Action = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color:darkblue;
    cursor:pointer;
`;

const data = {
	series: [80],
	options: {
		chart: {
			height: 350,
			type: "radialBar",
		},
		plotOptions: {
			radialBar: {
				hollow: {
					size: "50%",
				},
				dataLabels: {
					show: true,
					name: {
						offsetY: -10,
						show: true,
						color: "#888",
						fontSize: "17px",
					},
					value: {
						formatter: function (val) {
							return parseInt(val);
						},
						color: "black",
						fontSize: "36px",
						show: true,
					},
				},
			},
		},

		labels: ["Percentile"],
		fill: {
			colors: ["#b5b5b5"],
		},
	},
};

const data2 = {
	series: [
		{
			name: "Average Score",
			data: [8.2, 8.5, 9, 7.6, 8.2, 8.5, 6, 5.6],
		},
	],
	options: {
		chart: {
			type: "line",
			toolbar: {
				show: false,
			},
			sparkline: {
				enabled: false,
			},
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#E3B01F", "#b5b5b5"],
		grid: {
			padding: {
				left: 10,
				right: 10,
				bottom: 20,
				top: 10,
			},
		},
		stroke: {
			width: [5, 2],
			curve: "smooth",
			dashArray: [0, 8],
		},
		xaxis: {
			categories: [
				"Q1 2020",
				"Q2 2020",
				"Q3 2020",
				"Q4 2020",
				"Q1 2021",
				"Q2 2021",
				"Q3 2021",
				"Q4 2021",
			],
		},
		yaxis: {
			title: {
				text: "Score",
			},
			min: 0,
			max: 10,
			decimalsInFloat: 0,
		},
	},
};

const data3 = {
	series: [
		{
			name: "Outcomes",
			type: "area",
			data: [80, 83, 75, 75, null, null],
		},
		{
			name: "Predicted",
			type: "area",
			data: [null, null, null, 75, 70, 65],
		},
		{
			name: "Optimized",
			type: "area",
			data: [null, null, null, 75, 85, 95],
		},
	],
	options: {
		chart: {
			height: 350,
			type: "line",
			toolbar: {
				show: false,
			},
		},
		annotations: {
			points: [
				{
					x: "Q4 2022",
					y: 65,
					marker: {
						size: 8,
						fillColor: "#3260a8",
					},
					label: {
						text: "Predicted",
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
					},
				},
				{
					x: "Q4 2022",
					y: 95,
					marker: {
						size: 8,
						fillColor: "#32a852",
					},
					label: {
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
						text: "Optimized",
					},
				},
			],
		},

		dataLabels: {
			enabled: false,
		},
		colors: ["#b5b5b5", "#3260a8", "#32a852"],
		stroke: {
			dashArray: [0, 4, 4],
			curve: "smooth",
			width: [0, 3, 3],
		},
		fill: {
			type: ["solid", "dashed", "dashed"],
			opacity: [0.3, 0.4, 0.2],
		},
		yaxis: {
			min: 0,
			max: 100,
			labels: {
				formatter: function (value) {
					return value;
				},
			},
		},
		grid: {
			padding: {
				right: 50,
			},
		},
		xaxis: {
			categories: [
				"Q3 2020",
				"Q4 2020",
				"Q1 2021",
				"Q2 2021",
				"Q3 2021",
				"Q4 2022",
			],
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	},
};

const data4 = {
	series: [
		{
			name: "Player Performance",
			data: [80, 83, 75, 75, null, null, null],
		},
		{
			name: "Predicted",
			data: [null, null, null, 75, 70, 65, null],
		},
		{
			name: "Optimized",
			data: [null, null, null, 75, 85, 95, null],
		},
	],
	options: {
		chart: {
			height: 350,
			type: "line",
			toolbar: {
				show: false,
			},
		},
		annotations: {
			points: [
				{
					x: new Date("2018-09-24T05:30:00.000Z").getTime(),
					y: 65,
					marker: {
						size: 8,
						fillColor: "#3260a8",
					},
					label: {
						text: "Predicted",
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
					},
				},
				{
					x: new Date("2018-09-24T05:30:00.000Z").getTime(),
					y: 95,
					marker: {
						size: 8,
						fillColor: "#32a852",
					},
					label: {
						borderWidth: 0,
						style: {
							fontWeight: "bold",
						},
						text: "Optimized",
					},
				},
			],
		},

		dataLabels: {
			enabled: false,
		},
		colors: ["#b5b5b5", "#3260a8", "#32a852"],
		stroke: {
			dashArray: [0, 4, 4],
			curve: "straight",
			width: [5, 3, 3],
		},
		fill: {
			type: ["solid", "dashed", "dashed"],
			opacity: [1, 1, 1],
		},
		yaxis: {
			min: 0,
			max: 100,
			labels: {
				formatter: function (value) {
					return value;
				},
			},
		},
		grid: {
			padding: {
				right: 50,
			},
		},
		xaxis: {
			type: "datetime",
			categories: [
				"2018-09-19T00:00:00.000Z",
				"2018-09-20T01:30:00.000Z",
				"2018-09-21T02:30:00.000Z",
				"2018-09-22T03:30:00.000Z",
				"2018-09-23T04:30:00.000Z",
				"2018-09-24T05:30:00.000Z",
			],
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	},
};
