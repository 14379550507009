// useAutoHeight.js

import { useEffect, useRef } from 'react';

const useAutoHeight = (initialHeight) => {
  const ref = useRef(null);

  const resize = () => {
    if (ref.current) {
      // Check if ref.current is not null
      ref.current.style.height = `${initialHeight}px`;
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (ref.current) {
      // Ensure ref.current exists before adding event listener
      ref.current.addEventListener("input", resize);
      resize();
    }

    return () => {
      if (ref.current) {
        // Ensure ref.current exists before removing event listener
        ref.current.removeEventListener("input", resize);
      }
    };
  }, [initialHeight]);

  return { ref, resize };
};

export default useAutoHeight;