import { erf } from "mathjs";

const zptile = (z_score) => {
	return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};

function getStandardDeviation(array) {
	const n = array.length;
	if (n == 0) {
		return 0;
	}
	const mean = array.reduce((a, b) => a + b) / n;
	return Math.sqrt(
		array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
	);
}

const checkSubfactor = (anchor,categories) =>{
	let primary = categories.categories.find(f=>f.priority=='primary')
	if('subfactors' in primary.options[0]){
		let subfactors = []
		primary.options.map(item=>{
			item.subfactors.map(s=>subfactors.push(s.id))
		})
		return subfactors.includes(anchor)
	}

	return false
}

export const calculate_norms = (data, role, val, factor, question, anchor,categories) => {
	if (Object.keys(factor).length < 4) {
		return [];
	}

	let responses = data
		.filter((f, i) => i != 0)
		.map((i) => i.responses)
		.flat()
		.filter((f) => {
			try {
					if(checkSubfactor(role,categories)){
						return f.categories.find((f) => f.id == anchor)?.subfactor == role
					}
				return (
					f.categories.find((f) => f.id == anchor)?.response == role
				);
			} catch (err) {
				return false;
			}
		})
		.filter((f) => f.questions.length > 0)
		.filter((f) =>f.questions.find((f) =>
							f.id == factor.dimension &&
							f.factor == factor.factor &&
							f.q == question) !=undefined)
		.map((i) => {
			return i.questions.filter(
				(f) =>
					f.id == factor.dimension &&
					f.factor == factor.factor &&
					f.q == question
			)[0].response;
		});

	const reducer = (previousValue, currentValue) =>
		previousValue + currentValue;

	let mean =
		responses.length > 0 ? responses.reduce(reducer) / responses.length : 0;
	let stdev = getStandardDeviation(responses);

	let z_score = (val - mean) / stdev;

	return Math.floor(zptile(z_score) * 100);
};
