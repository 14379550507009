import { call, put, all, takeEvery } from "redux-saga/effects";
import * as api from "constants/api";

import {
    LOG_ERRORS,
    SG_LOG_ERRORS,
    ALL_ERRORS,
} from "constants/actions";


function* postData(action) {
    try {
      const json = yield call(api.LOG_ERROR, action.payload);
      yield put({ type: LOG_ERRORS, payload: json.data });
    } catch (e) {
      yield put({ type: ALL_ERRORS, payload: e.response });
    }
  }
  
  export function* sgPostData() {
    yield takeEvery(SG_LOG_ERRORS, postData);
  }

  export default function* index() {
    yield all([
     sgPostData(),

    ]);
  }