import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "./SideBar";
import FactorDetail from "./FactorDetail";
import { SG_FETCH_DEBRIEF_PDF_REPORT } from "constants/actions";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import SummaryChart from "./SummaryChart";
import Summary from "settings/Categories/Summary";

const AppContainer = styled.div`
  display: flex;
  font-family: "Raleway", sans-serif;
`;

const ContentArea = styled.div`
  padding: 20px 30px;

`;

const T2 = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #2a3039;
  margin-bottom: 10px;
`;

const P = styled.p`
  line-height: 1.7;
  margin-bottom: 20px;
`;

const LI = styled.li`
  margin-bottom: 15px;
  padding-left: 10px;
`;

const UL = styled.ul`
  margin-bottom: 15px;
  padding-left: 20px;
`;

const Container = styled.div`
  font-family: "Raleway", sans-serif;
  margin-bottom: 50px;
  padding: 20px;
`;

const Title = styled.div`
  color: #2d70e2;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const SubTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #2a3039;
  margin-bottom: 30px;
`;

function formatElements(rawString) {
  if (!rawString) return null;

  const lines = rawString.split("\n");
  const elements = [];
  let index = 0;
  let paragraphLines = [];
  let listItems = [];
  let inList = false;

  while (index < lines.length) {
    let line = lines[index];
    index++;

    if (line.trim() === "") {
      // Empty line, flush any pending paragraphs or lists
      if (paragraphLines.length > 0) {
        const paragraphText = paragraphLines.join(" ");
        elements.push(
          <P
            key={`p-${index}`}
            dangerouslySetInnerHTML={{
              __html: paragraphText
                .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                .trim(),
            }}
          />
        );
        paragraphLines = [];
      }
      if (inList) {
        elements.push(<UL key={`ul-${index}`}>{listItems}</UL>);
        listItems = [];
        inList = false;
      }
      continue;
    }

    if (line.startsWith("##")) {
      // Header
      if (paragraphLines.length > 0) {
        const paragraphText = paragraphLines.join(" ");
        elements.push(
          <P
            key={`p-${index}`}
            dangerouslySetInnerHTML={{
              __html: paragraphText
                .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                .trim(),
            }}
          />
        );
        paragraphLines = [];
      }
      if (inList) {
        elements.push(<UL key={`ul-${index}`}>{listItems}</UL>);
        listItems = [];
        inList = false;
      }
      const headerText = line.replace(/^##\s*/, "").trim();
      elements.push(<T2 key={`h2-${index}`}>{headerText}</T2>);
    } else if (/^\s*-\s/.test(line)) {
      // List item
      if (paragraphLines.length > 0) {
        const paragraphText = paragraphLines.join(" ");
        elements.push(
          <P
            key={`p-${index}`}
            dangerouslySetInnerHTML={{
              __html: paragraphText
                .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                .trim(),
            }}
          />
        );
        paragraphLines = [];
      }
      inList = true;
      const listItemText = line.replace(/^\s*-\s*/, "").trim();

      // Collect subsequent indented lines as part of the list item
      let listItemLines = [listItemText];
      while (index < lines.length && lines[index].startsWith("  ")) {
        listItemLines.push(lines[index].trim());
        index++;
      }
      const fullListItemText = listItemLines.join(" ");

      listItems.push(
        <LI
          key={`li-${index}`}
          dangerouslySetInnerHTML={{
            __html: fullListItemText
              .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
              .trim(),
          }}
        />
      );
    } else {
      // Regular text line, collect into paragraph
      if (inList) {
        // We have exited the list, so flush the list
        elements.push(<UL key={`ul-${index}`}>{listItems}</UL>);
        listItems = [];
        inList = false;
      }
      paragraphLines.push(line);
    }
  }

  // Flush any pending paragraphs
  if (paragraphLines.length > 0) {
    const paragraphText = paragraphLines.join(" ");
    elements.push(
      <P
        key={`p-end`}
        dangerouslySetInnerHTML={{
          __html: paragraphText.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>").trim(),
        }}
      />
    );
  }

  // Flush any pending lists
  if (inList) {
    elements.push(<UL key={`ul-end`}>{listItems}</UL>);
  }

  return elements;
}

function App() {
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [view, setView] = useState("summary"); // 'summary', 'culture', 'outcome'
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { pdfReports } = useSelector(
    (state) => ({
      pdfReports: state.debrief_pdf_report,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_DEBRIEF_PDF_REPORT,
        payload: {
          id,
        },
      });
    }
  }, [id]);

  useEffect(() => {
    if (pdfReports?.[id]) {
      const markdownData = pdfReports?.[id]?.additional_info?.[0];
      setData(markdownData?.json_data);
      setLoading(false); // Data fetched, set loading to false
    }
  }, [pdfReports]);

  const sortedCultureFactors =
    data?.culture_factors?.sort(
      (a, b) => Math.abs(b.change_percentage) - Math.abs(a.change_percentage)
    ) || [];
  const sortedOutcomeFactors =
    data?.outcome_factors?.sort(
      (a, b) => Math.abs(b.change_percentage) - Math.abs(a.change_percentage)
    ) || [];

  const handleSelectFactor = (factor) => {
    setSelectedFactor(factor);
    setView("detail");
  };

  const handleViewChange = (newView) => {
    setView(newView);
    setSelectedFactor(null);
  };

  if (loading) {
    return <p>Loading...</p>; // Display loading message while fetching data
  }

  if (!data) {
    return <p>No data available</p>; // Handle case where there's no data
  }

  return (
    <AppContainer>
      <Sidebar
        onSelectFactor={handleSelectFactor}
        onViewChange={handleViewChange}
        selected={selectedFactor}
        cultureFactors={sortedCultureFactors}
        outcomeFactors={sortedOutcomeFactors}
      />
      <ContentArea>
        {view === "summary" && (
          <>
            <Title>Survey Summary</Title>
            <SubTitle>Change Overview</SubTitle>
            <SummaryChart cultureFactors={data?.culture_factors} outcomeFactors={data?.outcome_factors}/>
            <SubTitle>Culture Factors</SubTitle>
            <p>{formatElements(data?.tldr_culture || "")}</p>
            <SubTitle>Outcome Factors</SubTitle>
            <p>{formatElements(data?.tldr_outcome || "")}</p>
          </>
        )}
        {view === "detail" && selectedFactor && (
          <FactorDetail factor={selectedFactor} />
        )}
      </ContentArea>
    </AppContainer>
  );
}

export default App;
