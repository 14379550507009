import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { SG_EDIT_GLOBAL_SCHEDULE } from "constants/actions";
import { Button, Header } from "semantic-ui-react";

import Select from "react-select";

function UpdatingCloseHrs({ organizationId, globalSchedule }) {
  const dispatch = useDispatch();
  const [totalHours, setTotalHours] = useState([]);
  const [CloseHrs, setCloseHrs] = useState({
    value: 24,
    label: "24 hrs / 1 day(s)",
    text: "24 hrs / 1 day(s)",
  });

  const [savedCloseHrs, setSavedCloseHrs] = useState({
    value: 24,
    label: "24 hrs / 1 day(s)",
    text: "24 hrs / 1 day(s)",
  });

  React.useEffect(() => {
    const _totalHours = [];
    for (let step = 24; step < 168; ) {
      // Runs 5 times, with values of step 0 through 4.
      _totalHours.push({
        value: step,
        label: `${step} hrs / ${step / 24} day(s)`,
        text: `${step} hrs / ${step / 24} day(s)`,
      });
      // console.log('Walking east one step', step);
      step += 24;
    }
    setTotalHours(_totalHours);
  }, []);

  // console.log(CloseHrs)
  // console.log(savedCloseHrs)
  // console.log(totalHours)

  React.useEffect(() => {
    if (globalSchedule) {
      setCloseHrs({
        value: globalSchedule?.close_by_hrs,
        label:  `${globalSchedule?.close_by_hrs} hrs / ${globalSchedule?.close_by_hrs / 24} day(s)`,
        text:  `${globalSchedule?.close_by_hrs} hrs / ${globalSchedule?.close_by_hrs / 24} day(s)`,
      });
    }
  }, [globalSchedule]);

  function UpdateCloseHrs(e) {
    dispatch({
      type: SG_EDIT_GLOBAL_SCHEDULE,
      payload: {
        id: globalSchedule.id,
        close_by_hrs: e.value,
        organization: organizationId,
      },
    });
    setCloseHrs({
      value: e.value,
      label: `${e.value} hrs / ${e.value / 24} day(s)`,
      text:  `${e.value} hrs / ${e.value / 24} day(s)`,
    });
  }

  return (
    <div style={{width: '80%'}}>
      <Header>Close in Hrs</Header>
      
      <Select
        placeholder="Close in Hrs"
        onChange={(e) => UpdateCloseHrs(e)}
        value={CloseHrs}
        options={totalHours}
      />
    </div>
  );
}

export default UpdatingCloseHrs;
