import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icon, Popup, Checkbox } from "semantic-ui-react";
import Language from "assets/images/nav/language.svg";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ClickAwayListener from "react-click-away-listener";
import {
  SG_GET_EMPLOYEE_PERMISSIONS,
  SG_ADD_EMPLOYEE_PERMISSION,
  SG_EDIT_EMPLOYEE_PERMISSION,
  SG_GET_PROD_ORG_CONTRACT
} from "constants/actions";

const LanguageMenu = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("English");
  const [authEmployee, setAuthEmployee] = useState(false);
  const [empPermission, setEmpPermission] = useState([]);

  const { get_employee, get_employee_permission } = useSelector(
    (state) => ({
      get_employee: state.employees,
      get_employee_permission: state.employee_permission.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_PROD_ORG_CONTRACT,
    });

    dispatch({
      type: SG_GET_EMPLOYEE_PERMISSIONS,
    });
  }, [dispatch]);

  useEffect(() => {
    if (empPermission?.extra_details?.language) {
      setSelected(empPermission?.extra_details?.language);
    }
  }, [empPermission]);

  function AddUpdateLanguage(value) {
    if (empPermission?.id) {
      // Update language
      let _ExtExtrDet = Object.keys(empPermission?.extra_details)
        //Filter Object with key contanis "language"
        .filter((key) => key !== "language")
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: empPermission?.extra_details[key],
          });
        }, {});

      _ExtExtrDet["language"] = value;

      dispatch({
        type: SG_EDIT_EMPLOYEE_PERMISSION,
        payload: {
          body: {
            id: empPermission?.id,
            extra_details: _ExtExtrDet,
          },
          query: `organization=${get_employee?.userEmp?.organization}`,
        },
      });
    } else {
      // add language
      dispatch({
        type: SG_ADD_EMPLOYEE_PERMISSION,
        payload: {
          body: {
            employee: get_employee?.userEmp?.id,
            extra_details: { language: value },
          },
          query: `organization=${get_employee?.userEmp?.organization}`,
        },
      });
    }
  }

  useEffect(() => {
    setAuthEmployee(get_employee.userEmp);
  }, [get_employee]);

  useEffect(() => {
    setEmpPermission(
      get_employee_permission?.find(
        (p) => p.employee_id === Number(authEmployee?.id)
      )
    );
  }, [get_employee_permission, authEmployee]);

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <Container>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Popup
          content="Language settings"
          disabled={open}
          trigger={<Image src={Language} onClick={() => setOpen(!open)} />}
        />

        <Menu open={open}>
          <Title>Selected language</Title>
          <Checkbox
            label="English"
            style={{ marginRight: 10 }}
            checked={selected === "English"}
            onChange={(e, data) => AddUpdateLanguage(data.label)}
          />
          <Checkbox
            label="French"
            checked={selected === "French"}
            onChange={(e, data) => AddUpdateLanguage(data.label)}
          />
        </Menu>
      </ClickAwayListener>
    </Container>
  );
};

export default LanguageMenu;

const Container = styled.div`
  position: relative;
  font-family: "Poppins", sans-serif;
`;

const Image = styled.img`
  height: 19px;
  width: 19px;
  margin-right: 20px;
  cursor: pointer;
`;

const Menu = styled.div`
  position: absolute;
  top: 30px;
  right: 0px;
  background-color: white;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.open ? "block" : "none")};
  z-index: 999;
  width: 300px;
`;

const Title = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: bold;
`;
