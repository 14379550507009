import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Table from '../components/Table'
import Footer from '../components/Footer'
import Side from '../components/Side'


const styles = StyleSheet.create({
	title:{
		fontSize:16,
		fontWeight:700,
		paddingBottom:5,
		borderBottom:'0.5px solid black',
		width:'100%',
		color:'#2C6CF5',
		marginBottom:15
	},
	page:{
		width:'100%',
		height:'100vh',
		position:'relative',
		padding:60,
		paddingHorizontal:50,
		display:'flex',
		flexDirection:'row'
	},
	quote:{
		width:'30%',
		fontSize:13,
		lineHeight:1.7,
		textAlign:'right',
		display:'flex',
		alignItems:'flex-end',
		marginTop:5
	},
	textarea:{
		width:'100%',
		paddingLeft:30,
		
	},
	title:{
		fontSize:20,
		fontWeight:700
	},
	p:{
		marginTop:20,
		fontSize:11,
		lineHeight:1.7
	},
	author:{
		width:70,
		fontSize:10,
		lineHeight:1.2,
		marginTop:5
	},
	colorBlock:{
		width:'100%',
		padding:20,
		backgroundColor:'#ff8259',
		color:'white',
		marginTop:15
	},
	contentTitle:{
		fontWeight:700,
		fontSize:12,
		marginBottom:-10
	}
})


const ContentBlock = ({title}) =>{


	return (
		<Page style={styles.page}>
			<Side/>
			<Footer/>

		
			<View style={styles.textarea}>
				<Text style={styles.title}>At Sport Law our dream is to elevate sport</Text>

				<Text style={styles.p}>Since 2010, Sport Law has been advocating for a Management by Values
					leadership ethos to be promoted and rewarded in sport. To accelerate this work, Sport Law has been looking for a
					caring company to collaborate with to bring our vision of fostering a values-based culture to life. Even the healthiest
					of workplaces, opportunities to provide meaningful feedback are often hard to find. Worse yet, people are concerned
					about providing truthful responses for fear of retribution. While sport organizations currently measure success in a limited fashion
					, there is a growing appetite to understand the health and effectiveness of their culture.</Text>

				<Text style={styles.p}>
					Sport Law's comprehensive process is supported by a Culture Coach to guide the implementation of strategies and to address some of the 
				</Text>

					<View style={styles.colorBlock}>
						<Text style={styles.contentTitle}>Shift to value-based care puts data in the spotlight</Text>
						<Text style={styles.p}>Historically, the health care industry has relied on a standard, fee-for-service structure. Providers
received a single payment for services rendered over a fixed period of time, and high profit margins
were the barometer of success. This volume-based approach does little to improve quality, or patient
satisfaction. It’s no surprise that value-based care, or accountable care, has emerged as a new way
to reduce cost, and improve patient experience. With value-based care, providers can manage more
patients, create incentives, and increase market share by offering more options.</Text>
					</View>

				<Text style={styles.p}>An early paper on visual analytics reads, “Let’s start with what visual
analytics is not: A graphical depiction of data. Virtually any software application can produce a chart, gauge or dashboard. Visual analytics offers
something much more profound. Visual analytics is the process of analytical reasoning facilitated by interactive visual interfaces.”
				</Text>

				<Text style={styles.p}>While it’s important to stay on top of evolving market definitions, you
					have to remember the reason why we’ve united around analytics in the
					first place: More data is available to us every day; the decisions we make
					on behalf of our businesses are increasingly important; and we look to
					technology to help us organize and analyze that data. While the technologies that support us in this endeavor have changed over the years, our
					ultimate goal remains the same: to find answers to the questions that
					matter—the ones that identify opportunities to push our businesses in the
					right direction.</Text>

			</View>


		</Page>
		)
}

export default ContentBlock