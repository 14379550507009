import React from "react";
import { View, Text, StyleSheet, Font, Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import Barlow from "assets/fonts/BarlowSemiCondensed-Light.ttf";
import Side from "./Side";
import Footer from "./Footer";
import { getTranslatedText } from "components/CWFPDF/pdfUtils";
import translations from "./translations";

/**
 *
 * @param {description: string, factors: string} props
 *
 * @returns
 */
const Methodology = (props) => {
  const { nav, categories, rule, structure, data, anchor, titleColor, isFrench } = props;
  const lang = isFrench ? "fr" : "en";

  // Create styles
  const styles = StyleSheet.create({
    title: {
      fontSize: 16,
      fontWeight: 700,
      paddingBottom: 5,
      borderBottom: "0.5px solid black",
      width: "100%",
      marginBottom: 15,
    },
    page: {
      width: "100%",
      height: "100vh",
      position: "relative",
      padding: 60,
      paddingHorizontal: 80,
    },
    p: {
      fontSize: 11,
      marginBottom: 10,
      lineHeight: 1.7,
    },
    p2: {
      fontSize: 9,
      marginBottom: 10,
    },
    T2: {
      fontSize: 20,
      fontWeight: 700,
      color: "#000921",
    },
    line: {
      width: 75,
      height: 4,
      backgroundColor: "#000921",
      marginTop: 5,
      marginBottom: 20,
    },
    description: {
      fontWeight: 700,
      fontSize: 14,
      marginTop: 15,
    },
    section: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: "#F1F1F7",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
      marginBottom: 20,
    },
    subSection: {
      display: "flex",
      flexDirection: "row",
      fontWeight: 700,
      fontSize: 16,
      height: "100%",
      alignItems: "center",
    },
    subTitle: {
      fontWeight: 700,
      fontSize: 16,
    },
    S1: {
      fontSize: 10,
      marginRight: 15,
    },
    img: {
      height: 20,
    },
    offset: {
      width: "100%",
    },
    summary: {
      border: "2px solid #000921",
      borderRadius: 5,
      marginTop: 20,
      marginBottom: 20,
    },
    top: {
      backgroundColor: "#000921",
      color: "white",
      fontSize: 16,
      fontWeight: 700,
      padding: 5,
    },
    bottom: {
      padding: 10,
      marginTop: 20,
    },
    steps: {
      width: "90%",
      display: "flex",
      flexDirection: "row",
      marginBottom: 3,
    },
    steps2: {
      width: "90%",
      display: "flex",
      flexDirection: "column",
    },
    square: {
      height: 6,
      width: 6,
      backgroundColor: "#000921",
      marginLeft: 20,
      marginRight: 10,
      marginTop: 5,
    },
    wrappingTitle: {
      fontSize: 16,
      fontWeight: 700,
      paddingBottom: 5,
      borderBottom: "0.5px solid black",
      width: "100%",
      color: "#2E34D0",
      marginBottom: 15,
    },
    quote: {
      fontSize: 13,
      lineHeight: 1.7,
      textAlign: "center",
      alignItems: "flex-end",
    },
    author: {
      paddingTop: 10,
      width: "100%",
      fontSize: 10,
      lineHeight: 1.2,
      justifyContent: "flex-start",
    },
    quoteText: {
      fontWeight: 500,
    },
    quoteSection: {
      paddingTop: 35,
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      backgroundColor: "#F1F1F7",
      display: "flex",
      flexDirection: "column",
      marginTop: 25,
      marginBottom: 20,
      marginLeft: 5,
      width: "45%",
    },
    definition: {
      display: "flex",
      flexDirection: "row",
      fontSize: 11,
      marginBottom: 8,
    },
    definition2: {
      display: "flex",
      flexDirection: "column",
      fontSize: 11,
      marginBottom: 8,
      lineHeight: 1.7,
    },
    d1: {
      fontWeight: 700,
      color: "#44546A",
    },
    italic: {
      fontWeight: 500,
    },
    numbered: { fontWeight: 500, marginRight: 10, fontSize: 9 },
  });

  let subSelected = false;
  const navItems = Object.keys(nav);
  const navOptions = [];
  navItems.forEach((item) => {
    let found = false;
    const anchorName = categories.categories.find((f) => f.id === anchor);
    if (nav[item].selected === false) {
      subSelected = true;
      found = true;
    }

    if (found) {
      let subNavItems = Object.keys(nav[item]);
      subNavItems = subNavItems.filter(
        (key) => key !== "priority" && key !== "selected"
      );
      let selected = subNavItems.filter((subNav) => nav[item]?.[subNav].sub);
      if (selected.length > 0) {
        selected.forEach((selectedNav) => {
          let subFactorsKeys = Object.keys(nav[item]?.[selectedNav].subfactors);
          subFactorsKeys.forEach((subKey) => {
            if (nav[item]?.[selectedNav].subfactors?.[subKey].selected) {
              navOptions.push(nav[item]?.[selectedNav].subfactors?.[subKey].id);
            }
          });
        });
      }
    }
  });

  const anchorGroup = categories.categories.find((cat) => cat.id === anchor);

  const introduction = (
    <View style={{ flexDirection: "row" }}>
      <View style={{ width: "100%" }}>
        <Text style={[styles.wrappingTitle, { color: titleColor }]}>
          {getTranslatedText('methodologyHeader', lang, translations)}
        </Text>
        <Text style={styles.p}>
          {getTranslatedText('methodologyDescription', lang, translations)}
        </Text>
      </View>
      {/*      <View style={styles.quoteSection}>
        <View style={styles.quote}>
          <Text style={styles.quoteText}>
            “A nation's culture resides in the hearts and in the soul of its
            people.”
          </Text>
          <Text style={styles.author}>- Mahatma Gandhi</Text>
        </View>
      </View>*/}
    </View>
  );

  const sportIndex = (
    <View wrap={false}>
      <Text style={styles.p}>
        {getTranslatedText("sportIndexDescription", lang, translations)}
      </Text>

      <View styles={styles.steps2}>
        <View style={styles.definition}>
          <Text>
            <Text style={styles.italic}>
              {getTranslatedText("cultureOfCare", lang, translations)} –{" "}
            </Text>
            {getTranslatedText("cultureOfCare", lang, translations, true)}
          </Text>
        </View>

        <View style={styles.definition}>
          <Text>
            <Text style={styles.italic}>
              {getTranslatedText("cultureOfApathy", lang, translations)} -{" "}
            </Text>
            {getTranslatedText("cultureOfApathy", lang, translations, true)}
          </Text>
        </View>

        <View style={styles.definition}>
          <Text>
            <Text style={styles.italic}>
              {getTranslatedText("cultureOfHarassment", lang, translations)} -{" "}
            </Text>
            {getTranslatedText("cultureOfHarassment", lang, translations, true)}
          </Text>
        </View>

        <View style={styles.definition}>
          <Text>
            <Text style={styles.italic}>
              {getTranslatedText("cultureOfQuality", lang, translations)} -{" "}
            </Text>
            {getTranslatedText("cultureOfQuality", lang, translations, true)}
          </Text>
        </View>

        <View style={styles.definition}>
          <Text>
            <Text style={styles.italic}>
              {getTranslatedText("cultureOfExcellence", lang, translations)} -{" "}
            </Text>
            {getTranslatedText("cultureOfExcellence", lang, translations, true)}
          </Text>
        </View>
      </View>
    </View>
  );

  const methodology = (
    <View wrap={false} style={{ marginTop: 10 }}>
      <Text style={styles.p}>
      {getTranslatedText('methodologyParagraph', lang, translations)}
      </Text>

      <View style={styles.definition2}>
        <Text style={styles.d1}>{getTranslatedText('personDomain', lang, translations)}:</Text>
        <Text>
        {getTranslatedText('personDomain', lang, translations, true)}
        </Text>
      </View>
      <View style={styles.definition2}>
        <Text style={styles.d1}>{getTranslatedText('performanceDomain', lang, translations)}:</Text>
        <Text>
        {getTranslatedText('performanceDomain', lang, translations, true)}
        </Text>
      </View>

      <Text style={styles.p}>
        {getTranslatedText('caatDescription', lang, translations)}
      </Text>
    </View>
  );

  const wrap = navOptions.length > 5;

  const nextSteps = (
    <View wrap={false}>
      <Text style={[styles.title, { color: titleColor }]}>
        How to create change using this report
      </Text>
      <Text style={styles.p}>
        Changing culture is a process, not an event. Therefore, this report
        alone is not a sufficient culture change strategy. The effectiveness of
        this report, like all data, ultimately rests on your willingness to
        reflect, engage, and commit to action. Here are some keys to keep in
        mind as you work to turn the data into transformation.
      </Text>
      <View style={styles.steps}>
        <Text style={styles.numbered}>1.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Establish a sense of urgency.</Text>
          &nbsp;&nbsp;Examine the findings of this report with a factual lens.
          Productive change begins when you have the discipline to confront the
          facts of your current reality, whatever they might be.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>2.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Set your priorities.</Text>
          &nbsp;&nbsp;As Brene Brown suggests in her book Dare to Lead, “if you
          have more than three priorities, you have no priorities”. Resist the
          urge to boil the ocean, and rather, hone in on a few key areas you
          have the capacity to impact over the coming months.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>3.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Form a guiding coalition.</Text>
          &nbsp;&nbsp;Assemble a diverse, representative, and influential group
          to lead the change effort. Bring this team together to drive ideation
          in an open and honest way based on the priorities from the report, and
          ensure they are empowered to act on the groups vision.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>4.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Create short term wins.</Text>
          &nbsp;&nbsp;Begin to implement initiatives that are visible and
          obvious based on the feedback provided in the report. These don't have
          to be monumental, but rather little changes that send a signal that
          things are in motion and the feedback provided is making a difference
          right away.
        </Text>
      </View>
      <View style={styles.steps}>
        <Text style={styles.numbered}>5.</Text>
        <Text style={styles.p2}>
          <Text style={{ fontWeight: 500 }}>Strategize long term success.</Text>
          &nbsp;&nbsp;In the midst of celebrating short term culture wins,
          continue to plan bigger initiatives that will be instilled more
          gradually.
        </Text>
      </View>
    </View>
  );

  return (
    <Page style={styles.page}>
      <Side />
      <Footer isFrench={isFrench} />
      <View>
        {/*<Text style={styles.T2}>Report Methods</Text>*/}
        {/*<View style={styles.line} />*/}
        {introduction}
        {sportIndex}
        {methodology}
      </View>
    </Page>
  );
};

Font.register({
  family: "Barlow",
  fonts: [{ src: Barlow }],
});

Methodology.propTypes = {
  companyLogo: PropTypes.string,
  introTemplate: PropTypes.number,
  coverImage: PropTypes.string,
  coverHeading: PropTypes.string,
  coverSubtitle: PropTypes.string,
  metadata: PropTypes.object,
};

export default Methodology;
