import { put, call, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
    NEW_EVENT_NOTIFICATION,
    SG_NEW_EVENT_NOTIFICATION,
    ALL_ERRORS,
} from "constants/actions";


function* getFunction(action) {

  try {
    const json = yield call(api.EVENT_NOTIFICATION, action.payload);
    yield put({ type: NEW_EVENT_NOTIFICATION, payload: json.data });

  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* loadGetFunction() {
  yield takeLatest(SG_NEW_EVENT_NOTIFICATION, getFunction);
}



export default function* index() {
  yield all([loadGetFunction(),
  ]);
}