import styled from "styled-components";
import React, { useEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { Progress, Label, Dropdown, Dimmer, Loader } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Filter from "./Filter";
import Table from "./Table";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useSearchParams } from "react-router-dom";
import { Data_Generator } from "./calculations";
import { SURVEY_INFO } from "constants/surveys";
import useGetSurveyData from "hooks/useGetSurveyData";

import {
  GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_STRUCTURES,
} from "constants/actions";

const Risk = ({ name }) => {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  let testing = searchParams.get("testing");

  const [selected, setSelected] = useState(null);
  const [tab, setTab] = useState(0);
  const [options, setOptions] = useState();
  const [sub, setSub] = useState();
  const [data, setData] = useState(data3.series);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [factor, setFactor] = useState();
  const [recentResponses, setRecentResponses] = useState();
  const [previousResponses, setPreviousResponses] = useState();
  const [responseHistory, setResponseHistory] = useState();
  const [tabId, setTabId] = useState();
  const [EDIQues, setEDIQues] = useState(0);
  const [categories, setCategories] = useState();
  const [questions, setQuestions] = useState();

  const chart = useRef(data3.options);

  const {
    get_survey_questions,
    errors,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    organizationId,
  } = useGetSurveyData({ surveyType: 7, auditTypeId: 4, dontRefetch: true });

  useEffect(() => {
    if (
      !recentResponses &&
      get_culture_audit_reports.response &&
      get_culture_audit_reports.response.length > 0
    ) {
      let responseList = [];

      const dataSet = get_culture_audit_reports.response.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.date) - new Date(a.date);
      });

      dataSet.forEach((responseSet) => {
        if (responseSet.responses.length > 0) {
          const exists = responseList.findIndex(
            (response) => response.name === responseSet.name
          );
          if (exists === -1) {
            responseList.push(responseSet);
          }
          if (responseList.length === 0) {
            responseList.push(responseSet);
          }
        }
      });
      setRecentResponses(responseList);
      setLoading(false);
    }
  }, [get_culture_audit_reports, recentResponses]);

  // TODO: add these to index of /edi folder so they are available throughout report.
  useEffect(() => {
    if (
      recentResponses &&
      !previousResponses &&
      get_culture_audit_reports.response &&
      get_culture_audit_reports.response.length > 0
    ) {
      let responseList = [];
      get_culture_audit_reports.response.forEach((responseSet) => {
        const exists = responseList.findIndex(
          (response) => response.name === responseSet.name
        );

        if (exists === -1) {
          const isDuplicate = recentResponses.find(
            (recentResponseSet) => recentResponseSet.id === responseSet.id
          );

          if (!isDuplicate && responseSet.responses.length > 0) {
            responseList.push(responseSet);
          }
        }
      });
      setPreviousResponses(responseList);
      setResponseHistory(get_culture_audit_reports.response);
    }
  }, [get_culture_audit_reports, recentResponses, previousResponses]);

  useEffect(() => {
    //Determine which survey is being used in the org data
    if (get_organizations && organizationId) {
      const organization = get_organizations[organizationId];
      const ediQues =
        organization?.services_enabled &&
        organization?.services_enabled.filter((sc) => {
          return sc?.name === SURVEY_INFO.edi?.name;
        })?.[0]?.question_sort_order;
      if (ediQues) {
        setEDIQues(ediQues);
      }
    }
  }, [get_organizations, organizationId]);

  useEffect(() => {
    if (EDIQues > 0 && get_survey_structure[0] && get_survey_questions[0]) {
      const SurveyQues = get_survey_questions.find((sq) => {
        return sq.sort_order === EDIQues;
      });

      setQuestions(SurveyQues.questions);
      setCategories(get_survey_structure[0]?.categories);
    } else if (get_survey_questions[0]) {
      setQuestions(get_survey_questions[0].questions);
    }
  }, [get_survey_structure, get_survey_questions, EDIQues]);

  useEffect(() => {
    if (
      questions &&
      questions.factors &&
      questions.factors.length > 0 &&
      name
    ) {
      setFactor(
        questions.factors.findIndex((f) => {
          return f.title === name;
        })
      );
    } else if (get_survey_questions[0]) {
      setFactor(
        get_survey_questions[0].questions.factors.findIndex((f) => {
          return f.title === name;
        })
      );
    }
  }, [questions, name]);

  const structureOptions = (structure) => {
    return structure[0].categories
      .filter((f) => f.priority.toLowerCase() === "tertiary")
      .map((item, i) => {
        return {
          key: i,
          text: item.name,
          value: item.id,
        };
      });
  };

  const getFilterOptions = (structure) => {
    let _options = [];
    _options.push({
      key: 0,
      text: "Overview",
      value: null,
    });

    structure[0].categories
      .find((f) => f.priority === "primary")
      .options.forEach((item, i) => {
        _options.push({
          key: i + 1,
          text: item.name,
          value: i,
        });
      });

    return _options;
  };

  useEffect(() => {
    if (get_survey_structure.length > 0 && !options) {
      setOptions(structureOptions(get_survey_structure));
    }
  }, [options, get_survey_structure]);

  useEffect(() => {
    if (options && !sub) {
      setSub(options[0].value);
    }
  }, [options, sub]);

  const whiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );

  const getTitle = (structure) => {
    if (structure) {
      if (selected === null) {
        return structure[0].categories.find((f) => f.priority === "primary")
          .options[tab]?.name;
      }

      return (
        structure[0].categories.find((f) => f.priority === "secondary").options[
          tab
        ]?.name +
        " in " +
        structure[0].categories.find((f) => f.priority === "primary").options[
          selected
        ].name
      );
    }
  };

  useEffect(() => {
    if (
      get_survey_structure.length > 0 &&
      get_survey_structure[0].categories &&
      sub
    ) {
      setLoading(true);
      chart.current.xaxis.categories = get_survey_structure[0].categories
        .find((f) => f.id === sub)
        .options.map((item, i) => item.name);
      let _data = [
        {
          name: "Scores",
          data: chart.current.xaxis.categories.map((item) => {
            return getAverageForGroup(item, true);
          }),
        },
      ];

      setData(_data);
      setTimeout(() => setLoading(false), 100);
      setTimeout(() => setInitialLoad(false), 100);
    }
  }, [sub, options, chart.current, selected]);

  const getGroupResponseId = (group) => {
    const tertiaryGroup = get_survey_structure[0].survey_questions.find(
      (questionGroup) => {
        return questionGroup.id === sub;
      }
    );

    const groupResponseId = tertiaryGroup.answers.find(
      (answer) => answer.name === group
    )?.id;
    return groupResponseId;
  };

  const filterByPrimary = () => {
    const primaryFilter =
      selected !== null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[selected].name
        : get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[tab]?.name;

    const responseGroup = get_culture_audit_reports.response.filter(
      (responseSet) => responseSet.name === primaryFilter
    );

    const dataSet = responseGroup.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.date) - new Date(a.date);
    });

    return dataSet[0];
  };

  const filterBySecondary = (responseGroup) => {
    let _responseGroup = Object.assign({}, responseGroup);
    const filteredResponses = _responseGroup?.responses?.filter(
      (responseSet) => {
        return (
          responseSet?.response?.categories.find(
            (f) => f.priority === "secondary"
          ).response === tabId
        );
      }
    );
    _responseGroup.responses = filteredResponses;

    return _responseGroup;
  };

  const reverseScoring = {
    0: 10,
    1: 9,
    2: 8,
    3: 7,
    4: 6,
    5: 5,
    6: 4,
    7: 3,
    8: 2,
    9: 1,
    10: 0,
  };

  const getResponsesForSet = (question, group = null) => {
    const primaryFilter =
      selected !== null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[selected]?.name
        : get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[tab]?.name;
    const secondaryFilter =
      selected !== null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "secondary"
          ).options[tab]?.name
        : null;

    let responseGroup = [];
    get_culture_audit_reports.response.forEach((recentResponses) => {
      if (recentResponses.name !== primaryFilter) return;
      if (recentResponses) {
        let currentScores = [];
        recentResponses.responses.forEach((responseSet) => {
          let isValid = true;

          if (secondaryFilter) {
            const validRespondant = responseSet.response.categories.find(
              (c) => c.priority === "secondary"
            );

            isValid = tabId === validRespondant.response;
          }

          if (group) {
            const groupResponseId = getGroupResponseId(group);
            const subValidator = responseSet.response.categories.find(
              (category) => category.id === sub
            );

            const isValidRespondant = subValidator.response === groupResponseId;
            if (
              isValidRespondant &&
              responseSet.response.questions[question.id - 1]?.response &&
              isValid
            ) {
              const score =
                responseSet.response.questions[question.id - 1]?.response;

              currentScores.push(score);
            }
          } else {
            if (
              responseSet.response.questions[question.id - 1]?.response &&
              isValid
            ) {
              const score =
                responseSet.response.questions[question.id - 1]?.response;

              currentScores.push(score);
            } else {
              responseSet.response.questions.forEach((questionResponse) => {
                if (questionResponse.factor === factor) {
                  const score =
                    responseSet.response.questions[question.id - 1]?.response;

                  currentScores.push(score);
                }
              });
            }
          }
        });

        if (currentScores.length > 0) {
          const sum = [...currentScores].reduce((a, b) => a + b, 0);
          const avg = sum / currentScores.length || 0;
          responseGroup.push({
            avg: avg.toFixed(1),
            date: recentResponses.date,
            distribution: currentScores,
          });
        }
      }
    });

    return responseGroup;
  };

  const getAverageForQuestion = (question, group = null) => {
    const primaryFilter =
      selected != null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[selected].name
        : get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[tab]?.name;
    const secondaryFilter =
      selected != null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "secondary"
          ).options[tab].name
        : null;

    const primaryDataSet = recentResponses.find((data) => {
      return data.name === primaryFilter;
    });

    if (primaryDataSet) {
      let currentScores = [];

      primaryDataSet.responses.forEach((responseSet) => {
        let isValid = true;

        if (secondaryFilter) {
          const validRespondant =
            responseSet.response.categories.find(
              (c) => c.priority === "secondary"
            ).response === tabId;
          isValid = validRespondant;
        }

        if (group) {
          const groupResponseId = getGroupResponseId(group);
          const subValidator = responseSet.response.categories.find(
            (category) => category.id === sub
          );

          const isValidRespondant = subValidator.response === groupResponseId;

          if (
            isValidRespondant &&
            responseSet.response.questions.find(
              (f) => f.ques_order == question.id
            ).response &&
            isValid
          ) {
            currentScores.push(
              responseSet.response.questions.find(
                (f) => f.ques_order == question.id
              ).response
            );
          }
        } else {
          if (
            responseSet.response.questions.length > 0 &&
            responseSet.response.questions.find((f) => {
              return f.ques_order == question.id;
            }).response &&
            isValid
          ) {
            currentScores.push(
              responseSet.response.questions.find(
                (f) => f.ques_order == question.id
              ).response
            );
          }
        }
      });

      if (currentScores.length > 0) {
        const sum = currentScores.reduce((a, b) => a + b, 0);
        const avg = sum / currentScores.length || 0;
        return {
          avg: avg.toFixed(1),
          distribution: currentScores,
        };
      }
    }
    return {
      avg: 0,
      distribution: [],
    };
  };

  const getAggregatedScore = (responseObj) => {
    const questionObj = questions?.factors[factor]?.questions.find(
      (question) => question.id === responseObj.ques_order
    );

    return questionObj?.reverse
      ? reverseScoring[responseObj.response]
      : responseObj.response;
  };

  const getAverageForGroup = (group, isRecent = false) => {
    const dataSet = isRecent ? recentResponses : previousResponses;
    const primaryFilter =
      selected !== null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[selected].name
        : get_survey_structure[0].categories.find(
            (f) => f.priority === "primary"
          ).options[tab]?.name;
    const secondaryFilter =
      selected !== null
        ? get_survey_structure[0].categories.find(
            (f) => f.priority === "secondary"
          ).options[tab].name
        : null;

    const groupResponseId = getGroupResponseId(group);
    if (dataSet) {
      const primaryDataSet = dataSet.find((data) => {
        return data.name === primaryFilter;
      });

      if (primaryDataSet) {
        let currentScores = [];
        primaryDataSet.responses.forEach((responseSet) => {
          let isValid = true;
          if (secondaryFilter) {
            const validRespondant =
              responseSet.response.categories.find(
                (c) => c.priority === "secondary"
              ).response === tabId;
            isValid = validRespondant;
          }

          if (isValid) {
            const subValidator = responseSet.response.categories.find(
              (category) => category.id === sub
            );
            const isValidRespondant = subValidator.response === groupResponseId;
            if (isValidRespondant) {
              responseSet.response.questions.forEach((questionResponse) => {
                if (questionResponse.factor === factor) {
                  const score = getAggregatedScore(questionResponse);
                  currentScores.push(score);
                }
              });
            }
          }
        });

        if (currentScores.length > 0) {
          const sum = currentScores.reduce((a, b) => a + b, 0);
          const avg = sum / currentScores.length || 0;
          return avg.toFixed(1);
        }
      }
    }
    return 0;
  };

  useEffect(() => {
    if (
      !loading &&
      whiteLabel.highlight &&
      options &&
      get_survey_structure &&
      recentResponses &&
      sub &&
      previousResponses
    ) {
      chart.current.colors = [whiteLabel.highlight, "#c9c9c9"];
      chart.current.xaxis.categories = get_survey_structure[0].categories
        .find((f) => f.id === sub)
        .options.map((item, i) => item.name);

      let _data = [
        {
          name: "Current",
          data: chart.current.xaxis.categories.map((item) => {
            return getAverageForGroup(item, true);
          }),
        },
        {
          name: "Previous",
          data: chart.current.xaxis.categories.map((item) => {
            return getAverageForGroup(item);
          }),
        },
      ];
      setData(_data);
      setTimeout(() => setInitialLoad(false), 100);
    }
  }, [
    whiteLabel,
    options,
    chart,
    loading,
    sub,
    tab,
    selected,
    recentResponses,
    get_survey_structure,
  ]);

  const getTabId = (tab, ignoreSelected = false) => {
    if (selected !== null || ignoreSelected) {
      const struct = get_survey_structure[0].categories.find(
        (cat) => cat.priority === "secondary"
      ).options[tab].id;
      return struct;
    }
    return 0;
  };

  useEffect(() => {
    setTab(0);
    setTabId(getTabId(0));
  }, [selected]);

  // currently this block prevents an error when setting categories.
  if (initialLoad) {
    return <LoadingSpinner />;
  }

  /**
   * Gets the avg for the specified tab.
   * This can be extended to get previous responses (for change)
   * @param {*} tab current tab to get avg.
   *
   * @returns the group average (for all questions) used on tab component.
   */
  const getGroupAverage = (tab) => {
    const dataSet = recentResponses;
    const currentTabId = getTabId(tab);

    const primaryIndex = selected !== null ? selected : tab;

    const primaryFilter = get_survey_structure[0].categories.find(
      (f) => f.priority === "primary"
    ).options[primaryIndex]?.name;

    if (dataSet) {
      const primaryDataSet = dataSet.find((data) => {
        return data.name === primaryFilter;
      });

      if (primaryDataSet) {
        let currentScores = [];
        primaryDataSet.responses.forEach((responseSet) => {
          let isValid = true;
          if (selected !== null) {
            const validRespondant =
              responseSet.response.categories.find(
                (c) => c.priority === "secondary"
              ).response === currentTabId;
            isValid = validRespondant;
          }
          if (isValid) {
            responseSet.response.questions.forEach((questionResponse) => {
              if (questionResponse.factor === factor) {
                const score = getAggregatedScore(questionResponse);
                currentScores.push(score);
              }
            });
          }
        });

        if (currentScores.length > 0) {
          const sum = currentScores.reduce((a, b) => a + b, 0);
          const avg = sum / currentScores.length || 0;
          return avg * 10;
        }
      }
    }
    return 0;
  };

  const generate_test_data_set = () => {
    setLoading(true);
    const DataGenerated = Data_Generator(
      1000,
      5,
      get_survey_structure[0],
      questions
    );
    setRecentResponses(null);

    dispatch({
      type: GET_CULTURE_AUDIT_REPORTS,
      payload: { response: DataGenerated },
    });

    setTimeout(() => setLoading(false), 100);
  };

  return (
    <Container>
      <Left>
        {testing ? (
          <div>
            <Test onClick={() => generate_test_data_set()} id={"test"}>
              Generate Data
            </Test>
          </div>
        ) : (
          ""
        )}
        <Title>{name} Analytics</Title>
        <Filter
          structure={get_survey_structure[0]}
          selected={selected}
          setSelected={(v) => {
            setSelected(v);
            setTab(0);
          }}
          options={getFilterOptions(get_survey_structure)}
        />

        {selected === null
          ? get_survey_structure[0].categories
              .find((f) => f.priority === "primary")
              .options.map((item, i) => {
                return (
                  <RiskElement
                    color={whiteLabel.highlight}
                    title={item.name}
                    risk={getGroupAverage(i)}
                    onClick={() => {
                      setTab(i);
                      setTabId(getTabId(i));
                    }}
                    selected={i === tab}
                    structure={get_survey_structure[0]}
                  />
                );
              })
          : get_survey_structure[0].categories
              .find((f) => f.priority === "secondary")
              .options.map((item, i) => {
                const factor = get_survey_structure[0].categories.find(
                  (f) => f.priority === "secondary"
                ).name;
                return (
                  <RiskElement
                    color={whiteLabel.highlight}
                    title={`${item.name} (${factor})`}
                    risk={getGroupAverage(i)}
                    onClick={() => {
                      setTab(i);
                      setTabId(getTabId(i));
                    }}
                    selected={i === tab}
                  />
                );
              })}
      </Left>
      <Right>
        <T2>{getTitle(get_survey_structure)}</T2>

        <T3>
          {name} Analysis by
          <Dropdown
            inline
            options={options}
            defaultValue={options[0].value}
            value={sub}
            onChange={(e, { value }) => setSub(value)}
            style={{ marginLeft: 5 }}
          />
        </T3>
        {loading || !recentResponses ? (
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        ) : (
          <>
            <Chart
              options={chart.current}
              series={data}
              type="bar"
              height={400}
            />

            <Table
              title={getTitle(get_survey_structure)}
              q={questions.factors[factor].questions}
              date={recentResponses[0].date}
              structure={get_survey_structure[0].categories.find(
                (f) => f.id === sub
              )}
              factor={factor}
              recentResponses={
                selected
                  ? filterBySecondary(filterByPrimary())
                  : filterByPrimary()
              }
              categories={get_survey_structure[0].categories}
              getDataset={(q, i) => getAverageForQuestion(q, i)}
              getGroupData={(q, i) => getResponsesForSet(q, i)}
            />
          </>
        )}
      </Right>
    </Container>
  );
};

export default Risk;

const Container = styled.div`
  display: flex;
`;

const Left = styled.div`
  width: 400px;
  height: calc(100vh - 80px);
  border-right: 1px solid lightgrey;
  padding: 30px 20px;
`;

const Title = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 20px;
  margin-left: 0px;
`;

const T2 = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
`;
const T3 = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
`;

const Right = styled.div`
  width: calc(100% - 400px);
  padding: 30px 20px;
`;

const RiskElement = ({ title, risk, selected, color, onClick }) => {
  return (
    <RiskContainer selected={selected} onClick={() => onClick()}>
      <RiskTitle>{title}</RiskTitle>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ margin: 0, width: "80%" }}>
          <StyledProgressBar
            percent={risk}
            size="tiny"
            color={color}
            style={{ margin: 0 }}
          />
        </div>
        <Score>{Math.floor(risk * 10) / 10}%</Score>
      </div>
    </RiskContainer>
  );
};

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${(props) => props.color || "green"} !important;
  }
`;

const RiskContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px;
  width: 100%;
  height: auto;
  border-radius: 20px;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
  padding: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 10px;
  background-color: ${(props) => (props.selected ? "lightgrey" : "white")};

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 10px;
  }
`;

const RiskTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
`;

const LabelContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 20px;
`;

const Score = styled.div``;

const Test = styled.button`
  margin-top: 10px;
`;

const data3 = {
  series: [
    {
      name: "Scores",
      data: [9, 10, 8],
    },
  ],
  options: {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: ["#3260a8"],
    grid: {
      padding: {
        left: 40,
      },
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: [
        "Apples",
        "Oranges",
        "Strawberries",
        "Pineapples",
        "Mangoes",
        "Bananas",
        "Blackberries",
        "Pears",
        "Watermelons",
        "Cherries",
        "Pomegranates",
        "Tangerines",
        "Papayas",
      ],
      tickPlacement: "on",
    },
    yaxis: {
      min: 0,
      max: 10,
      floating: true,
      decimalsInFloat: 0,
      labels: {
        offsetX: 20,
      },
      title: {
        text: "Scores",
      },
    },
  },
};
