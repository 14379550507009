import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES,
  GET_DEBRIEF_PDF_REPORT,
  SG_GET_DEBRIEF_PDF_REPORT,
  FETCH_DEBRIEF_PDF_REPORT,
  SG_FETCH_DEBRIEF_PDF_REPORT,
  CREATE_DEBRIEF_PDF_REPORT,
  SG_CREATE_DEBRIEF_PDF_REPORT,
  DELETE_DEBRIEF_PDF_REPORT,
  SG_DELETE_DEBRIEF_PDF_REPORT,
  // UPDATE_DEBRIEF_PDF_REPORT,
  // SG_UPDATE_DEBRIEF_PDF_REPORT,
  GET_DEBRIEF_PDF_REPORT_URL,
  SG_GET_DEBRIEF_PDF_REPORT_URL,
  SG_DELETE_DEBRIEF_PDF_REPORT_ITEM,
  DELETE_DEBRIEF_PDF_REPORT_ITEM,
  ALL_ERRORS,
} from "constants/actions";

function* createMethodList(action) {
  try {
    const json = yield call(
      api.GET_DEBRIEF_PDF_REPORT_TEMPLATES,
      action.payload
    );
    yield put({ type: GET_DEBRIEF_PDF_REPORT_TEMPLATES, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodList() {
  yield takeLatest(SG_GET_DEBRIEF_PDF_REPORT_TEMPLATES, createMethodList);
}

function* getMethod() {
  try {
    const json = yield call(api.GET_DEBRIEF_PDF_REPORT);
    yield put({ type: GET_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodGet() {
  yield takeLatest(SG_GET_DEBRIEF_PDF_REPORT, getMethod);
}

function* fetchMethod(action) {
  try {
    const json = yield call(api.FETCH_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: FETCH_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_PDF_REPORT, fetchMethod);
}

// function* updateMethod(action) {
//   try {
//     const json = yield call(api.UPDATE_DEBRIEF_PDF_REPORT, action.payload);
//     yield put({ type: UPDATE_DEBRIEF_PDF_REPORT, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, payload: e.response });
//   }
// }

// export function* methodUpdate() {
//   yield takeLatest(SG_UPDATE_DEBRIEF_PDF_REPORT, updateMethod);
// }

// function* createMethod(action) {
//   try {
//     const json = yield call(api.UPDATE_PDF_SAVED_COMMENTS, action.payload);
//     yield put({ type: CREATE_DEBRIEF_PDF_REPORT, payload: json.data });
//   } catch (e) {
//     yield put({ type: ALL_ERRORS, payload: e.response });
//   }
// }

// export function* methodCreate() {
//   yield takeLatest(SG_CREATE_DEBRIEF_PDF_REPORT, createMethod);
// }

function* createMethod(action) {
  try {
    const json = yield call(api.CREATE_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: CREATE_DEBRIEF_PDF_REPORT, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodCreate() {
  yield takeLatest(SG_CREATE_DEBRIEF_PDF_REPORT, createMethod);
}

function* deleteMethod(action) {
  try {
    const json = yield call(api.DELETE_DEBRIEF_PDF_REPORT, action.payload);
    yield put({ type: DELETE_DEBRIEF_PDF_REPORT, payload: action.payload });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDelete() {
  yield takeLatest(SG_DELETE_DEBRIEF_PDF_REPORT, deleteMethod);
}

function* deleteItemMethod(action) {
  try {
    const json = yield call(api.DELETE_DEBRIEF_PDF_REPORT_ITEM, action.payload);
    yield put({
      type: DELETE_DEBRIEF_PDF_REPORT_ITEM,
      payload: action.payload,
    });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodDeleteItem() {
  yield takeLatest(SG_DELETE_DEBRIEF_PDF_REPORT_ITEM, deleteItemMethod);
}

function* getMethodUrl(action) {
  try {
    const json = yield call(api.GET_DEBRIEF_PDF_REPORT_URL, action.payload);
    yield put({ type: GET_DEBRIEF_PDF_REPORT_URL, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* methodGetUrl() {
  yield takeLatest(SG_GET_DEBRIEF_PDF_REPORT_URL, getMethodUrl);
}

export default function* index() {
  yield all([
    methodList(),
    methodGet(),
    methodFetch(),
    // methodUpdate(),
    methodCreate(),
    methodDelete(),
    methodGetUrl(),
    methodDeleteItem(),
  ]);
}
