import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/UI/people.svg'

const Summary = ({data}) =>{

    return (
        <Container>
            
            <T2>Here’s what your department is saying about {data.content.factor}:</T2>
            <ul>
                {data.content.text.map((item,i)=>{
                    return <LI key={i}>{item}</LI>
                    })}
            </ul>
           

          
        </Container>
    )

}

export default Summary

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
    align-items:flex-start;

`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const Description = styled.div`
    margin-bottom: 40px;
`
const D2 = styled.div`
    margin-bottom:20px;
`

const IMG = styled.img`
    height: 50px;
    margin-bottom:20px;
`

const LI = styled.li`
    margin-bottom:10px;
    line-height:1.6;
`