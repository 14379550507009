import React, { useEffect, useState, useRef } from "react";
import {generate_chart_standards_data,generate_chart_change_data,generate_chart_standards_data_factor,generate_chart_change_data_factor} from 'app/audit/calculations';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dropdown,Radio  } from "semantic-ui-react";
import Anchor from "../Anchor";

import FactorChart from './ScoreChart';
import factor_content from 'app/audit/simple/factor/factor_content.json';
import styled, { keyframes } from 'styled-components';

import { SET_DATA_ACNHOR } from "constants/actions";
import Loading from './Loading';


const dataOptions1 = [
  { key: 'raw', text: 'Raw', value: 'Raw' },
  { key: 'change', text: 'Change', value: 'change' },
  { key: 'benchmark', text: 'Benchmark', value: 'benchmark' },
 
]

const dataOptions2 = [
  { key: 'raw', text: 'Raw', value: 'Raw' },
  { key: 'change', text: 'Change', value: 'change' }
]

const Factor = ({ slideData, updateSlideData,loading,outcomeQ,outcomeStandards }) => {
    const dispatch = useDispatch();
  
    // Local state
    const [selectedFactor, setSelectedFactor] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [factorDropdown, setFactorDropdown] = useState([]);
    const [chartURI, setChartURI] = useState(() => slideData?.chartURI || null);
    const [selectedOption, setSelectedOption] = useState('Raw');


    // Ref to prevent cyclical updates
    const skipAnchorEffect = useRef(false);
  
  
    // Redux state
    const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
    const data = useSelector((state) => state.audit?.filtered_data, shallowEqual);
    const lastData = useSelector((state) => state.audit?.filtered_last_data, shallowEqual);
    const coreData = useSelector((state) => state.audit?.core_data, shallowEqual);
    const rule = 2;

    const dataOptions = Object.keys(coreData?.standards).length>0  ? dataOptions1 : dataOptions2;

    
  
     // Load data when a slide with existing data is selected
     useEffect(() => {
        if (slideData && slideData.selectedFactor) {
          if (slideData.selectedFactor?.title !== selectedFactor?.title) {
            setSelectedFactor(slideData.selectedFactor);
          }
        } else {
          setSelectedFactor(null);
        }

        if(slideData && slideData.selectedOption){
            setSelectedOption(slideData.selectedOption);
        }else{
            setSelectedOption('Raw');
        }

        if (slideData && slideData.anchor && slideData.anchor !== anchor) {
          skipAnchorEffect.current = true; // Set the flag
          dispatch({
            type: SET_DATA_ACNHOR, // Replace with your actual action type
            payload: slideData.anchor,
          });
        }
      }, [slideData]);
    
  
    // Update factor dropdown when coreData changes
    useEffect(() => {
      if (coreData) {
        let options = [{key:0, text:"All Factors", value:'All Factors'}] 
        coreData.questions.dimensions.flatMap((dimension, i) =>
        dimension.factors.map((factor) =>{
            options.push({
                key: factor.id+factor.title,
                text: factor.title,
                value: { ...factor, dimension: i, type: 'factor' }
            })
        })
      );

    //Add the outcome factors here
      outcomeQ.questions.map((q) => {
        options.push({
            key: q.id+q.title,
            text: q.name,
            value: { ...q, type: 'outcome' }
        })
        })
  
        setFactorDropdown(options);

        if(!slideData?.selectedFactor){
            setSelectedFactor(options[0].value);
          }
      }
    }, [coreData]);
  
    // Set default selectedFactor when factorDropdown changes
    useEffect(() => {
      if (factorDropdown.length > 0 && !selectedFactor) {
        setSelectedFactor(factorDropdown[0].value);
      }
    }, [factorDropdown, selectedFactor]);
  
    // Update chartData when selectedFactor or data changes
    useEffect(() => {
      if (selectedFactor && coreData) {
        const standards = coreData?.standards;
        const categories = coreData?.categories[0];
        const questions = coreData?.questions;

        const navigation = {
            category:{id:anchor},
            outcome:selectedFactor
        }

        let calculatedChartData = null;
        let changeChartData = null;

        let correctedData = data

        if(data[0][0]){
          correctedData = data[0]
        }

        if(selectedFactor === 'All Factors'){
            //calculation for the benchmark chart for Factors
            calculatedChartData = generate_chart_standards_data(
              correctedData,
            standards,
            navigation,
            categories,
            'Factor Overview',
            questions,
            rule
            );

            changeChartData = generate_chart_change_data(
              correctedData,
                lastData,
                navigation,
                categories,
                'Factor Overview',
                rule)
        }
        else{
            const data_type = selectedFactor.type === 'factor' ? 'Factor' : 'Outcome';

            calculatedChartData = generate_chart_standards_data_factor(
              correctedData,
            standards,
            navigation,
            categories,
            selectedFactor,
            data_type,
            outcomeStandards,
            rule
          );


          changeChartData = generate_chart_change_data_factor(
            correctedData,
            lastData,
            navigation,
            categories,
            selectedFactor,
            data_type,
            outcomeStandards,
            rule)


        }

        let _chartData = selectedOption === 'benchmark' || selectedOption === 'Raw' ? calculatedChartData : changeChartData;

        setChartData(_chartData);
      }
    }, [selectedFactor, data, lastData, anchor, coreData,selectedOption]);

    const build_slide_title = () => {
      const category_title = coreData?.categories[0].categories?.find((c) => c.id === anchor)?.name;
      const data_type_name = selectedOption === 'benchmark' ? 'Benchmarks' : selectedOption === 'Raw'? 'Scores' :'Score Changes';
      if(selectedFactor === 'All Factors'){
          return `Culture ${data_type_name} for Each ${category_title}`;
      }
      else if(selectedFactor?.title){
          return `${selectedFactor.title} ${data_type_name} for Each ${category_title}`;
      }
      return '';
  }

  
    // Update slideData when selectedFactor, anchor, chartData, or chartURI changes
    useEffect(() => {
      if (chartData && selectedFactor && anchor) {

        const content = factor_content[selectedFactor.title];
        const newSlideData = {
          ...slideData,
          title: build_slide_title(),
          content: content?.content?.p || '',
          chartURI,
          slide_template_id: 4,
          anchor,
          selectedFactor,
          selectedOption
        };
  
        // Only update if newSlideData is different from slideData
        if (JSON.stringify(newSlideData) !== JSON.stringify(slideData)) {
          updateSlideData(newSlideData);
         
        }

        
      }
      // Exclude slideData and updateSlideData from dependencies to prevent loops
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFactor, anchor, chartData, chartURI,selectedOption]);
  
     // Handle anchor changes from user input
     useEffect(() => {
        if (skipAnchorEffect.current) {
          skipAnchorEffect.current = false; // Reset the flag
          return; // Skip updating slideData
        }
        if (anchor && (!slideData || slideData.anchor !== anchor)) {
          const newSlideData = {
            ...slideData,
            anchor,
          };
          updateSlideData(newSlideData);
        }
      }, [anchor]);
  
    const getChange = () => {
      if (chartData?.lastOverallStats?.average) {
        const change = chartData.overallStats.average - chartData.lastOverallStats.average;
        return (change * 10).toFixed(1) + '%';
      }
      return '--';
    };
  
    const findContent = () => {
      if (selectedFactor) {
        const content = factor_content[selectedFactor.title];
        return content ? <p>{content.content.p}</p> : '';
      }
      return '';
    };
  
    const handleFactorChange = (e, { value }) => {
      setSelectedFactor(value);
    };
  
    const handleSetChartURI = (uri) => {
      setChartURI(uri);
    };

    const handleChange = (e, { value }) => setSelectedOption(value);


    if(loading){
        return <Loading/>
    }


    


  
    return (
      <Container>
        <T1>Score Analysis Slide</T1>
        <SubTitle>Slide Options</SubTitle>
        <OptionArea>
          <div style={{ marginRight: 20 }}>
            <OptionTitle>Select a Factor</OptionTitle>
            <Dropdown
              placeholder='Select Factor'
              selection
              options={factorDropdown}
              onChange={handleFactorChange}
              value={selectedFactor}
            />
          </div>
          <div style={{ marginRight: 20 }}>
            <OptionTitle>Category</OptionTitle>
            <Anchor />
          </div>

          <div>
            <OptionTitle>Chart Data</OptionTitle>
            <Dropdown
              placeholder='Select Data'
              selection
              options={dataOptions}
              onChange={handleChange}
              value={selectedOption}
            />
          </div>
        </OptionArea>
        <SlidePreview>
          <SlideTitle>{build_slide_title()}</SlideTitle>

          <FactorChart setChartURI={handleSetChartURI} data={chartData} selectedOption={selectedOption}/>
          <SlideFooter>innerlogic</SlideFooter>
        </SlidePreview>
      </Container>
    );
  };
  
  export default Factor;

  
const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;


const Container = styled.div`
 opacity:0;

    animation: ${fadeIn} 1s ease-out forwards;
`

const SlidePreview = styled.div`
    width:100%;
    height:550px;
    padding:20px;
    border:1px solid #e0e0e0;
    border-radius:10px;
    margin-top:20px;
    position:relative;
`

const Title = styled.div`
    margin-top:20px;
    font-size: 16px;
`

const SlideTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom:40px;
    font-family: 'Raleway';
    color:#333333;
`

const SlideFooter = styled.div`
    position:absolute;
    bottom:0;
    font-size: 12px;
    width:calc(100% - 40px);
    padding:10px 0px;
    border-top:1px solid #e0e0e0;
    display:flex;
`


const Content = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`

const ScoreArea = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-right:50px;
`

const ScoreTitle = styled.div`
    color:#666D79;
    font-size:12px;

`

const Score = styled.div`
color:#2A3039;
font-size:14px;
font-weight:600;
`

const ScoreImg = styled.img`
    width:20px;
`

const ScoreImg2 = styled.img`
    width:30px;
`

const GreenBlock = styled.div`
    background-color:#EAFBF7;
    height:40px;
    width:40px;
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
`

const BlueBlock = styled.div`
     background-color:#EBF1FD;
    height:40px;
    width:40px;
    border-radius:8px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;
`

const Description = styled.div`
    margin-top:20px;
`


const OptionArea = styled.div`
    margin-bottom:20px;
    display:flex;
    padding:20px;
    background-color:#f9fbfd;
    width:100%;
`

const OptionTitle = styled.div`
    font-size:12px;
    font-weight:500;
    margin-bottom:5px;
`
const SubTitle = styled.div`
    font-size: 14px;
    margin-top:20px;
    margin-bottom:5px;
    font-weight:600;
`

const T1 = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom:10px;
    color:#2A3039;
`