import React from 'react';
import { Card, Heading, Paragraph, Button } from './styles/Common';

const UserSyncComplete = ({ onManage }) => {
  return (
    <Card style={{textAlign: 'center'}}>
      <Heading>Synchronization Complete</Heading>
      <Paragraph>Your users have been successfully synchronized with Slack!</Paragraph>
      <Button primary onClick={onManage}>Manage Slack Integration</Button>
    </Card>
  );
};

export default UserSyncComplete;
