import styled, { keyframes } from "styled-components";
import React from "react";

const BubbleContainer = styled.div`
   display:${props=>props.hover?'flex':'none'};
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: -90px;
  left: ${(props) =>
    props.bar
      ? "calc(" + props.position + "% - 65px)"
      : "calc(" + props.position + "% - 58px)"};
  transition: left 500ms;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #545454;
  z-index:100;


`;

const Bubble = styled.div`
  background-color: black;
  height: 60px;
  border-radius: 20px;
  width:120px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DoubleBubble = styled.div`
  background-color: black;
  height: 60px;
  border-radius: 20px;
  width: 150px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Triangle = styled.div`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid black;
  z-index: 3;
`;

const getPercentile = (s) => {
  let mod = s % 10;
  if (mod == 0 || mod > 3 || s==12) {
    return s + "th";
  } else if (mod == 1) {
    return s + "st";
  } else if (mod == 2) {
    return s + "nd";
  } else if (mod == 3) {
    return s + "rd";
  }
};

const PercentileBubble = (props) => {



  if (props.style == "double") {
    return (
      <BubbleContainer
        position={props.position}
        bar={true}
        width={props.width}
        hover={props.hover}
      >
        <DoubleBubble>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: 75,
              borderRight: "1px solid white",
            }}
          >
            <div style={{ fontSize: 12 }}>
              <div style={{marginBottom:1}}>{getPercentile(props.p1 > props.p2 ? props.p2 : props.p1)}</div>
              percentile
            </div>
            <div style={{ fontSize: 7,marginTop:2 }}>
              {props.p1 > props.p2 ? props.t2 : props.t1}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: 75,
            }}
          >
            <div style={{ fontSize: 12 }}>
              <div style={{marginBottom:1}}>{getPercentile(props.p1 > props.p2 ? props.p1 : props.p2)}</div>
              percentile

            </div>
            <div style={{ fontSize: 7,marginTop:2 }}>
              {props.p2 >= props.p1 ? props.t2 : props.t1}
            </div>
          
          </div>
        </DoubleBubble>
        <Triangle />
      </BubbleContainer>
    );
  }
  return (
    <BubbleContainer
      position={props.position}
      bar={false}
      width={props.width}
      hover={props.hover}
    >
      <Bubble>
        <div style={{ fontSize: 14 }}>
           <div style={{marginBottom:1}}>{getPercentile(
            props.bar ? Math.floor(props.position[2]) : props.position
          )}</div>
           percentile
        </div>
        <div style={{ fontSize: 7,marginTop:2 }}>
             {props.text}
            </div>
      </Bubble>
      <Triangle />
    </BubbleContainer>
  );
};

export default PercentileBubble;
