import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Button, Header, Image, Modal } from 'semantic-ui-react'
import Create_Posting from "./create_posting";
import History from "./history";


const Recruiting = () => {


    return (
        <Container>
            <h1>Recruiting</h1>
            <div>
                <Create_Posting />
            </div>
            <History />
            
        
        </Container>
    );
    }

export default Recruiting;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-left:58px;
    margin-top:40px;
`;