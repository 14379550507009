import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Box from '../../DraftPlan/Dashboard/Box'

const UserPlans = () =>{
    const navigate = useNavigate();
        return (
            <Container>

                <Center>
                <Title>Data Stories</Title>
                <Box 
                    title={'Psychological Safety data story'}
                    icon={'book'}
                    onClick={()=>navigate('/simple/prototype/explain')}
                    description={'Build a culture action plan based on your culture data. Our ai action planner will help you every step of the way.'}/>

                <div style={{marginBottom:30}}/>
                <Title>Action Plans</Title>
                <Box 
                    title={'Draft action plans'}
                    icon={'pencil'}
                    onClick={()=>navigate('/simple/prototype/collab')}
                    description={'Build a culture action plan based on your culture data. Our ai action planner will help you every step of the way.'}/>

                </Center>

            </Container>
        )

}

export default UserPlans

const Container = styled.div`
  padding-left: 58px;
  padding-right: 40px;
  font-family: "Raleway";
  display:flex;
    flex-direction:column;
    align-items:center;
`;

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Center = styled.div`
    width:800px;
    margin-top:50px;
    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`
