import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import format from "date-fns/format";

export default function SubscriptionBox({subscription}) {
  // console.log(subscription)
  return (
    <Container>
      <Title>Subscription</Title>
      <TrialText>
        Your subscription is{" "}
        <span 
        style={{ color: "#08c251", fontWeight: "bold" }}>
          {(subscription?.status)}
        </span>.
      </TrialText>
      <Text>Subscription Summary:</Text>
       {/*  */}
      <Summary>You are subscribed to {(subscription?.quantity)} seat(s) on the Premium plan.</Summary>
      <Summary>Your last payment was 15.00$ on 
        {(subscription?.current_period_start)}
        {/* {format(
    new Date(subscription[0]?.current_period_start.toLocaleString()),
    "EEEE, MMM do h:mm a"
  )} */}
        </Summary>
      <Summary>Your next payment of 15.00$ will be due 
        {(subscription?.current_period_end)}
        {/* {format(
    new Date(subscription[0]?.current_period_end.toLocaleString()),
    "EEEE, MMM do h:mm a"
  )} */}
        </Summary>
      <Read>
        View Invoices <Icon name="chevron right" style={{ marginTop: 3 }} />
      </Read>
      <Cancel>
        Cancel Subscription{" "}
        <Icon name="chevron right" style={{ marginTop: 3 }} />
      </Cancel>
    </Container>
  );
}

function DateFormatDisplay(showDate) {
  if (!showDate) {
    return null
  }
  

}
const Container = styled.div`
  // width: 500px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  // margin-left: calc((100% - 500px) / 2);
  border-radius: 8px;
  box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
  padding: 36px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 36px 20px 36px 20px;
  }
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;
const Text = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 5px;
  font-weight: bold;
`;

const TrialText = styled.div`
  font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  color: ${(props) =>
    props.active ? "#606060" : props.selected ? "#5e00e3" : "#6a00ff"};
  margin-bottom: 20px;
`;
const Summary = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
`;
const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #6a00ff;
  margin-top: 15px;
  cursor: pointer;
`;
const Cancel = styled.div`
  font-family: "Red Hat Display", sans-serif;

  font-size: 14px;
  font-weight: bold;
  color: #ff2d55;
  margin-top: 15px;
  cursor: pointer;
`;
