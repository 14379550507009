import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components";
import Select from "react-select";
import {
  Button,
  Icon,
  Table,
  Segment,
  Dimmer,
  Confirm,
  Popup,
  Loader,
  Checkbox,
  Modal,
} from "semantic-ui-react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import {
  SG_FETCH_FILE_UPLOAD,
  SG_EDIT_FILE_UPLOAD,
  // LOAD_TEAMS,
} from "constants/actions";

import format from "date-fns/format";

import UpdateValues from "./Update";
import MapHeaders from "./MapHeaders";
import { validationReducer } from "./validationReducer";
import { ValidateCheckRow } from "./ValidatorRow";

const UpdateEmployeeRecordDetails = ({ showReportId }) => {
  const dispatch = useDispatch();

  const [tasks, valDispatch] = useReducer(validationReducer, []);

  const [fileUpload, setfileUpload] = useState([]);
  const [fileHeaders, setFileHeaders] = useState([]);
  const [validFieldList, setValidFieldList] = useState([]);
  const [validEmaiIds, setValidEmaiIds] = useState([]);

  const [jsonData, setJsonData] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);

  const [rowId, setRowId] = useState(false);
  const [openPortal, setopenPortal] = useState(false);

  const [deleteId, setDeleteId] = useState(false);

  const [invalidList, setInvalidList] = useState([]);
  const [invalidRows, setInvalidRows] = useState(0);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const [surveyHeaders, setSurveyHeaders] = useState(false);
  const [validFieldNames, setValidFieldName] = useState({});
  // const [validFields, setValidFields] = useState([])
  const [categoryLabel, setCategoryLabel] = useState([]);

  const [emailNotFound, setEmailNotFound] = useState(0);
  const [unMapped, setunMapped] = useState(false);

  const [createIfNotFound, setCreateIfNotFound] = useState(false);
  const [deleteIfNotUploaded, setDeleteIfNotUploaded] = useState(false);
  const [createUpdateRecord, setCreateUpdateRecord] = useState(1);
  const [previewChangedHeader, setPreviewChangedHeader] = useState([]);
  const [primaryCategory, setPrimaryCategory] = useState(false);
  const [selectedPrimaryCategory, setSelectedPrimaryCategory] = useState(false);

  const {
    get_file_uploads,
    get_employees,
    get_record_value,
    get_email_list,
    get_survey_structure,
    // get_selectedOrg
  } = useSelector(
    (state) => ({
      get_file_uploads: state.fileupload,
      get_employees: state.employees.employees,
      get_record_value: state.employee_record_value.employee_record_value,
      get_email_list: state.employees.allEmailList,
      get_survey_structure: state.surveystructure.survey_structure,
      // get_selectedOrg: state.selectedOrg
    }),
    shallowEqual
  );


  useEffect(() => {
    if (get_survey_structure?.[0]?.categories) {
      const _primaryCategory = [
        { id: null, name: "Delete Across All" }, // default value or first row
        ...get_survey_structure?.[0]?.categories
          .filter((cat) => cat.priority === "primary")?.[0]
          ?.options.map((op) => ({ id: op.id, name: `Delete all in ${op.name}` })),
      ];
      setPrimaryCategory(_primaryCategory);

      setSelectedPrimaryCategory(_primaryCategory?.[0]);
    }
  }, [get_survey_structure]);
  // console.log(primaryCategory);
  // useEffect(() => {
  //     const _validFields = get_record_value.filter(gr => gr.cat_field_name !== null
  //     ).map(gr => gr.cat_field_name)
  //     // _validFields.push("email")
  //     if (_validFields?.length > 0) {
  //         let _fileHeaders = fileHeaders
  //         _fileHeaders.push(..._validFields)
  //         _fileHeaders.filter(item => item !== '')

  //         setFileHeaders(_fileHeaders)
  //     }

  //     // setValidFields(_validFields)

  // }, [get_record_value, fileHeaders]);

  // useEffect(() => {
  //     setFileHeaders(Object.keys(_fileUpload?.review_data?.[0]))
  // })
  useEffect(() => {
    const myUniqueSet = new Set(fileHeaders.filter((item) => item !== ""));
    const myList = Array.from(myUniqueSet);
    setValidFieldList(myList);
  }, [fileHeaders]);

  useEffect(() => {
    setValidFieldName(get_survey_structure?.[0]?.field_map?.valid_field_name);
  }, [get_survey_structure]);

  useEffect(() => {
    if (get_record_value) {
      setCategoryLabel(
        get_record_value
          .filter((gr) => gr?.data_for === 1 && gr.cat_field_name !== null)
          .map((gr) => gr.cat_field_name)
      );
    }
  }, [get_record_value]);

  useEffect(() => {
    setValidEmaiIds(get_email_list?.response);
  }, [get_email_list]);

  useEffect(() => {
    if (showReportId) {
      dispatch({
        type: SG_FETCH_FILE_UPLOAD,
        payload: { id: showReportId },
      });
    }
  }, [dispatch, showReportId]);

  useEffect(() => {
    const _fileUpload = get_file_uploads?.[showReportId];
    setfileUpload(_fileUpload);
    const _UserList = [];
    let _total = 0;
    if (get_file_uploads?.[showReportId]?.review_data) {
      //
      setFileHeaders(Object.keys(_fileUpload?.review_data?.[0]));
      _fileUpload?.review_data.map((fl, i) => {
        const Found = validEmaiIds.includes(fl?.email);
        if (!Found) {
          _total += 1;
        }
        return _UserList.push({
          ...fl,
          valid: Found,
        });
      });
      if (_fileUpload?.status > 2) {
        _total = 0;
      }
      if (_fileUpload?.status > 3) {
        _total += 100;
      }
    }
    setEmailNotFound(_total);
    // console.log(_fileUpload)
    // console.log(_UserList)
    setJsonData(_UserList);

    if (get_file_uploads?.[showReportId]?.status === 9) {
      setPreviewChangedHeader(
        Object.keys(get_file_uploads?.[showReportId]?.review_data[0])
      );
      let ChangedData = get_file_uploads?.[showReportId]?.review_data.find(
        (ft) => ft?.status === "changed"
      );
      if (ChangedData) {
        setPreviewChangedHeader(Object.keys(ChangedData));
      } else {
        ChangedData = get_file_uploads?.[showReportId]?.review_data.find(
          (ft) => ft?.status === "to be deleted"
        );
        if (ChangedData) {
          setPreviewChangedHeader(Object.keys(ChangedData));
        }
      }
      setCreateUpdateRecord(2);
    }
    if (get_file_uploads?.[showReportId]?.status === 3) {
      setPreviewChangedHeader(
        Object.keys(get_file_uploads?.[showReportId]?.review_data[0]).filter(
          (fh) => fh !== "valid"
        )
      );
    }
  }, [showReportId, get_file_uploads, validEmaiIds, get_file_uploads.updated]);

  useEffect(() => {
    const _surveyHeads = ["id", "email", "first_name", "last_name"];
    _surveyHeads.push(
      ...Object.keys(get_survey_structure?.[0]?.field_map?.valid_field_name)
    );
    _surveyHeads.push(
      ...get_record_value
        .filter(
          (gr) =>
            gr?.data_for === 2 &&
            gr.cat_field_name !== null &&
            gr.cat_field_name !== "id"
        )
        .map((gr) => gr.cat_field_name)
    );
    setSurveyHeaders(_surveyHeads);
  }, [get_survey_structure, get_record_value]);

  useEffect(() => {
    let _total = 0;
    if (emailNotFound > 0) {
      _total += emailNotFound;
    }
    if (invalidList.length > 0) {
      _total += invalidList.length;
    }
    if (unMapped.length > 0) {
      _total += unMapped.length;
    }
    if (tasks?.length > 0) {
      _total += tasks.length;
    }
    if (createIfNotFound) {
      _total = 0;
    }
    if (createUpdateRecord === 2) {
      _total = 0;
    }
    setInvalidRows(_total);
  }, [
    emailNotFound,
    invalidList,
    unMapped,
    tasks,
    createIfNotFound,
    createUpdateRecord,
  ]);

  // console.log(
  //   emailNotFound,
  //   invalidList,
  //   unMapped,
  //   tasks,
  //   createIfNotFound,
  //   createUpdateRecord,
  //   invalidRows
  // );

  useEffect(() => {
    setLoadingPage(get_file_uploads?.pending);
  }, [get_file_uploads]);

  function GetDetails(item, id) {
    // setRowInfo(item)
    setopenPortal(true);
    setRowInfo(item);
    setRowId(id);
    // setRowData(Object.entries(item));
  }

  function DeleteRow(id) {
    // console.log(id)
    setDeleteId(id);
    setConfirmDelete(true);
  }
  function DeleteFileUpload() {
    dispatch({
      type: SG_EDIT_FILE_UPLOAD,
      payload: {
        id: showReportId,
        delete_row: jsonData[deleteId]?.id,
      },
    });
    const _jsonData = jsonData;
    _jsonData.splice(deleteId, 1);
    setJsonData(_jsonData);

    setConfirmDelete(false);
    setDeleteId(null);
  }

  function ImportToDb() {
    setLoadingPage(true);
    dispatch({
      type: SG_EDIT_FILE_UPLOAD,
      payload: {
        id: showReportId,
        import_to_db: 1,
        review_data: jsonData,
        delete_if_not_uploaded: deleteIfNotUploaded ? 1 : 0,
        primary_category: selectedPrimaryCategory?.id,
        create_update_record: createUpdateRecord,
      },
    });
  }

  function ShowPortal(e) {
    setLoadingPage(e);
    setopenPortal(e);
  }

  // console.log("selectedPrimaryCategory", selectedPrimaryCategory);
  console.log("jsonData", jsonData);

  useEffect(() => {
    let setTasks = 0;
    jsonData.map((item) =>
      fileHeaders.map((head) => {
        if (categoryLabel.includes(head)) {
          let _options = validFieldNames?.[head]?.options;
          const Foundval = _options?.find((op) => op.name === item[head])?.name;
          if (!Foundval) {
            setTasks += 1;
            // let setTasks = invalidList
            // setTasks.push(item?.id)
            // console.log(setTasks)
            valDispatch({
              type: "add",
              payload: item?.id,
            });
            // setInvalidList(setTasks)
          }
        }
        return null;
      })
    );
    setInvalidList(setTasks);
  }, [jsonData, validFieldNames, fileHeaders, categoryLabel]);

  useEffect(() => {
    let setTasks = 0;
    jsonData.map((item) =>
      fileHeaders.map((head) => {
        if (categoryLabel.includes(head)) {
          let _options = validFieldNames?.[head]?.options;
          const Foundval = _options?.find((op) => op.name === item[head])?.name;
          if (!Foundval) {
            setTasks += 1;
            // let setTasks = invalidList
            // setTasks.push(item?.id)
            // console.log(setTasks)
            valDispatch({
              type: "add",
              payload: item?.id,
            });
            // setInvalidList(setTasks)
          }
        }
        return null;
      })
    );
    setInvalidList(setTasks);
  }, [jsonData, validFieldNames, fileHeaders, categoryLabel]);

  return (
    <>
      {openPortal && (
        <>
          {/* to be shown when user clicks edit option */}
          <UpdateValues
            ShowPortal={ShowPortal}
            openPortal={openPortal}
            rowInfo={rowInfo}
            setRowInfo={setRowInfo}
            id={showReportId}
            rowId={rowId}
            setJsonData={setJsonData}
            jsonData={jsonData}
            validEmpList={get_employees}
            fileHeaders={validFieldList}
            validFieldNames={validFieldNames}
            categoryLabel={categoryLabel}
          />
        </>
      )}
      {fileUpload?.status < 3 && (
        <MapHeaders
          showReportId={showReportId}
          get_record_value={get_record_value}
          surveyHeaders={surveyHeaders}
          setFileHeaders={setFileHeaders}
          fileHeaders={validFieldList}
          setJsonData={setJsonData}
          jsonData={jsonData}
          setunMapped={setunMapped}
        />
      )}

      <Segment basic padded>
        <Dimmer active={loadingPage} />
        <Loader>Loading</Loader>
        <TopContainerRow>
          <TopContainerCol>
            <div>
              {fileUpload?.created_at && (
                <Information>
                  {fileUpload?.status_text} -{" "}
                  {format(
                    new Date(fileUpload?.created_at?.toLocaleString()),
                    "EEEE, MMM do"
                  )}
                </Information>
              )}
              <Information>
                {invalidRows > 0 &&
                  `Invalid Data or Empty Cells Found please fix them to complete the upload`}
              </Information>
            </div>
            {fileUpload?.status < 3 && (
              <StyledButton disabled={invalidRows > 0} onClick={ImportToDb}>
                Import Data Listed Below
              </StyledButton>
            )}
          </TopContainerCol>
          {fileUpload?.status < 3 && (
            <>
              <TopContainerCol>
                <SubHeaders>
                  <Popup
                    trigger={<Icon name="info circle" color="red" />}
                    content="Employees not in list will be added."
                    position="top left"
                  />
                  <Checkbox
                    toggle
                    checked={createIfNotFound}
                    onClick={() => setCreateIfNotFound(!createIfNotFound)}
                  />{" "}
                  Create New for Non-Existing
                </SubHeaders>

                <SubHeaders>
                  <Popup
                    trigger={<Icon name="info circle" color="red" />}
                    content="Employee not in list will be archived. Manual reinstatement may be required."
                    position="top left"
                  />
                  <Checkbox
                    toggle
                    checked={deleteIfNotUploaded}
                    onClick={() => setDeleteIfNotUploaded(!deleteIfNotUploaded)}
                  />{" "}
                  Delete Not found
                </SubHeaders>
              </TopContainerCol>
              <CSSTransition
                in={deleteIfNotUploaded}
                timeout={500}
                classNames={'fade'}
                unmountOnExit
              >
                {deleteIfNotUploaded ? (
                  <FullWidthBorderedContainer>
                    <AnimatedContainer>
                    <SubHeadersContent>
                      If not found in the CSV who should be deleted:
                    </SubHeadersContent>
                    <AnimationWrapper style={{width: "50%"}}>
                      <Select
                        placeholder="Select Primary Category"
                        onChange={setSelectedPrimaryCategory}
                        value={selectedPrimaryCategory}
                        options={primaryCategory}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                      />
                    </AnimationWrapper>
                    </AnimatedContainer>
                   
                  </FullWidthBorderedContainer>
                ) : (
                  <div> </div>
                )}
              </CSSTransition>
            </>
          )}
        </TopContainerRow>
        <ScrollContainer>
          {jsonData && jsonData.length === 0 && (
            <InfoCentered>Looks like an empty CSV file</InfoCentered>
          )}

          {fileUpload?.status === 9 && (
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  {previewChangedHeader &&
                    previewChangedHeader.map((head, i) => {
                      return (
                        <Table.HeaderCell key={i}>{head}</Table.HeaderCell>
                      );
                    })}

                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {jsonData &&
                  jsonData.map((item, ii) => {
                    return (
                      <Table.Row key={ii}>
                        {previewChangedHeader.map((pr, i) => {
                          return <Table.Cell key={i}>{item[pr]}</Table.Cell>;
                        })}
                        <Table.Cell>
                          <Button icon onClick={() => DeleteRow(ii)}>
                            <Icon name="trash alternate" />
                          </Button>
                          <Confirm
                            open={confirmDelete}
                            onCancel={() => setConfirmDelete(false)}
                            onConfirm={() => DeleteFileUpload(ii)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          )}

          {fileUpload?.status === 3 && (
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  {previewChangedHeader &&
                    previewChangedHeader.map((head, i) => {
                      return (
                        <Table.HeaderCell key={i}>{head}</Table.HeaderCell>
                      );
                    })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {jsonData &&
                  jsonData.map((item, ii) => {
                    return (
                      <Table.Row key={ii}>
                        {previewChangedHeader.map((pr, i) => {
                          if (pr === "category") {
                            return (
                              <Table.Cell key={i}>
                                <Modal
                                  trigger={<Button>Show Details</Button>}
                                  header="Employee Category Details!"
                                  content={
                                    <CategoryInfoTable jsonData={item[pr]} />
                                  }
                                  actions={[
                                    {
                                      key: "done",
                                      content: "Close",
                                      positive: true,
                                    },
                                  ]}
                                />
                              </Table.Cell>
                            );
                          }
                          return <Table.Cell key={i}>{item[pr]}</Table.Cell>;
                        })}
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          )}
          {/* regular table */}
          {fileUpload?.status < 3 && (
            <Table celled selectable>
              <Table.Header>
                <Table.Row>
                  {validFieldList &&
                    validFieldList.map((head, i) => {
                      const Result = validFieldList.includes(head);
                      if (head === "valid") {
                        return null;
                      }
                      if (head === "id") {
                        return null;
                      }

                      if (Result) {
                        return (
                          <Table.HeaderCell key={i}>{head}</Table.HeaderCell>
                        );
                      }

                      return (
                        <Table.HeaderCell key={i} error>
                          <Popup
                            content="Unable to import data in this column"
                            trigger={<Icon name="attention" />}
                          />
                          {head}
                        </Table.HeaderCell>
                      );
                    })}
                  {fileUpload?.status < 3 && (
                    <Table.HeaderCell></Table.HeaderCell>
                  )}
                  {fileUpload?.status < 3 && (
                    <Table.HeaderCell></Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {jsonData &&
                  jsonData.map((item, i) => {
                    return (
                      <Table.Row
                        key={i}
                        error={item.valid !== true && fileUpload?.status < 3}
                      >
                        {validFieldList &&
                          validFieldList.map((head, k) => {
                            // console.log(head)
                            // console.log(validFields)
                            const Result = validFieldList.includes(head);
                            if (head === "valid") {
                              return null;
                            }
                            if (head === "id") {
                              return null;
                            }
                            if (fileUpload?.status > 2) {
                              return (
                                <Table.Cell key={k}>{item[head]}</Table.Cell>
                              );
                            }
                            if (Result) {
                              return (
                                <ValidateCheckRow
                                  key={k}
                                  item={item}
                                  head={head}
                                  uploadStatus={fileUpload?.status}
                                  validEmaiIds={validEmaiIds}
                                  Foundval={true}
                                  categoryLabel={categoryLabel}
                                  fileHeaders={validFieldList} // fileHeaders are valid field names
                                  validFieldNames={validFieldNames}
                                  invalidList={invalidList}
                                  setInvalidList={setInvalidList}
                                  createIfNotFound={createIfNotFound}
                                />
                              );
                            }

                            return (
                              <Table.Cell key={k} error>
                                <Popup
                                  content="Unable to import this data, not supported field"
                                  trigger={<Icon name="attention" />}
                                />
                                {item[head]}
                              </Table.Cell>
                            );
                          })}

                        {fileUpload?.status < 3 && (
                          <Table.Cell>
                            <Button icon onClick={() => GetDetails(item, i)}>
                              <Icon name="edit" />
                            </Button>
                          </Table.Cell>
                        )}
                        {fileUpload?.status < 3 && (
                          <Table.Cell>
                            <Button icon onClick={() => DeleteRow(i)}>
                              <Icon name="trash alternate" />
                            </Button>
                            <Confirm
                              open={confirmDelete}
                              onCancel={() => setConfirmDelete(false)}
                              onConfirm={() => DeleteFileUpload(i)}
                            />
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          )}
        </ScrollContainer>

        {fileUpload?.status === 9 && (
          <SpacedDiv>
            <StyledButton disabled={invalidRows > 0} onClick={ImportToDb}>
              Complete Import
            </StyledButton>
          </SpacedDiv>
        )}
      </Segment>
    </>
  );
};

export default UpdateEmployeeRecordDetails;


function CategoryInfoTable({ jsonData }) {
  const [jsonRowData, setJsonRowData] = useState([]);
  const [dataHeader, setDataHeader] = useState([]);

  useEffect(() => {
    setJsonRowData(JSON.parse(jsonData));
  }, [jsonData]);

  useEffect(() => {
    if (jsonRowData?.length > 0) {
      setDataHeader(Object.keys(jsonRowData[0]));
    }
  }, [jsonRowData]);

  console.log(jsonRowData);

  return (
    <Table celled selectable>
      <Table.Header>
        <Table.Row>
          {dataHeader &&
            dataHeader.map((head, i) => {
              return <Table.HeaderCell key={i}>{head}</Table.HeaderCell>;
            })}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {jsonRowData?.length > 0 &&
          jsonRowData.map((item, ii) => {
            return (
              <Table.Row key={ii}>
                {dataHeader.map((pr, i) => {
                  return <Table.Cell key={i}>{item[pr]}</Table.Cell>;
                })}
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
}

const ScrollContainer = styled.div`
  overflow-x: auto;
  // display:flex;
  // width:100%;
  // align-items:center;
  // justify-content:space-between;
`;

const Headers = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 28px;
  margin-top: 40px;
  margin-bottom: 100px;
  padding-bottom: 50px;
`;

const CenterMessage = styled.div`
  text-align: center;
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  font-size: 28px;
  margin-top: 100px;
  margin-bottom: 20px;
`;

const WrappedText = styled.pre`
  flex-wrap: wrap;
`;
const SubHeaders = styled.div`
  font-family: "Barlow", sans-serif;
  // font-weight: bold;
  font-size: 25px;
  margin: 20px 0px;
  // margin-bottom: 20px;
`;

const SubHeadersContent = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  margin: 20px 0px;
`;
const InfoCentered = styled.div`
  text-align: center;
  font-family: "Barlow", sans-serif;
  font-size: 20px;
  margin-bottom: 25px;
  color: "#FFCCCB";
`;

const Org = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 25px;
`;
const Information = styled.div`
  font-family: "Barlow", sans-serif;
  font-size: 20px;
`;

const StyledTable = styled.div`
  margin-top: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
`;

const TR = styled.div`
  display: flex;
  padding: 15px 10px;
  width: 100%;
`;

const TH = styled.div`
  width: ${(props) => props.width + "%"};
  font-weight: bold;
  border-bottom: 1px solid #9b9b9b;
  padding-bottom: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  align-items: center;
  position: relative;
`;

const T1 = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const SmallImg = styled.img`
  width: 30px;
  margin-right: 10px;
`;
const RowView = styled.tr`
  cursor: pointer;
`;

const View = styled.div`
  padding: 5px 35px;
  background-color: #6a00ff;
  color: white;
  border-radius: 20px;
  font-weight: bold;
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const SpacedDiv = styled.div`
  padding: 30px 0px;
  margin-bottom: 10px;
`;

const Card = styled.div`
  max-width: 100%;
  padding: 30px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  //   position:relative;
  display: flex;
  justify-content: space-between;
  //   align-items:center;
`;

const TopContainerRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TopContainerCol = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const FullWidthBorderedContainer = styled.div`
  padding: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background-color: #f2f2f2;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;

  font-family: "Barlow Semi Condensed", sans-serif;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#606060" : "#2d50e2")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-right: 5px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const AnimationWrapper = styled.div`
  position: relative;
`;

const AnimatedContainer = styled.div`

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }

  &.fade-exit {
    opacity: 1;
  }

  &.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
`;
