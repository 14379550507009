import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Image from 'assets/images/nav/factor.svg'
import Chart from "react-apexcharts";

const MultipleScores = () =>{



    return (
        <Container>
            
            <T2>Your culture factor scores</T2>

            <Description>
                Here is a break down of your culture score into the individual factors that make up your total culture score. 
                While its important to understand what each of these scores mean and represent, we'll focus on diving into your lowest scores.
            </Description>


            <div style={{width:'100%',marginBottom:30}}>
            <Chart
						options={data3.options}
						series={data3.series}
						type="bar"
						height={450}
					/>
            </div>
         

          
        </Container>
    )

}

export default MultipleScores

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
    align-items:flex-start;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:70px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
    width:100%;
`

const Description = styled.div`
    margin-bottom: 20px;
`
const D2 = styled.div`
    margin-bottom:20px;
`

const IMG = styled.img`
    height: 30px;
    margin-bottom:20px;
`

const LI = styled.li`
    margin-bottom:10px;
`

const data3 = {
	series: [
		{
			data: [8.8,8.5,8.5,8.4,8.1,7.6,7.5,7.3,6.4,6.1],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#27CDA7", "#2D70E2"],
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 100],
              colorStops: [
                {
                  offset: 0,
                  color: "#27CDA7",
                  opacity: 0.5,
                },
                {
                  offset: 100,
                  color: "#2D70E2",
                  opacity: 0.5,
                }
              ],
            },
          },
		colors: ["#2D70E2"],
		xaxis: {
			categories: [
				"Psychological Safety",
                "Empathy",
                "Belonging",
                "Learning",
                "Values",
                "Clarity",
                "Resilience",
                "Accountability",
                "Impact",
                "Vision"

			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#27CDA7", "#C0C0C0"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 10,
			decimalsInFloat: 1,
			title: {
				text: "",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
