import styled,{keyframes} from "styled-components";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";

const Question = () =>{

    return (
        <Container>
                  <Title>Questions</Title>
                    <Description>Select a question from your custom survey to see all the responses.</Description>
                    <Dropdown placeholder='Select Question' fluid search selection options={[]} style={{marginBottom:30}}/>
                    


                    <Title>Responses</Title>

                    <Response>
                        <Name><InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>Michael Bawol</Name>
                        <Answer>Answer 1</Answer>

                        <Follow>Follow up question</Follow>
                        <Answer>Answer 1</Answer>
                    </Response>

                    <Response>
                        <Name><InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>Michael Bawol</Name>
                        <Answer>Answer 1</Answer>

                        <Follow>Follow up question</Follow>
                        <Answer>Answer 1</Answer>
                    </Response>

                    <Response>
                        <Name><InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>Michael Bawol</Name>
                        <Answer>Answer 1</Answer>

                        <Follow>Follow up question</Follow>
                        <Answer>Answer 1</Answer>
                    </Response>

                    <Response>
                        <Name><InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>Michael Bawol</Name>
                        <Answer>Answer 1</Answer>

                        <Follow>Follow up question</Follow>
                        <Answer>Answer 1</Answer>
                    </Response>

                    <Response>
                        <Name><InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>Michael Bawol</Name>
                        <Answer>Answer 1</Answer>

                        <Follow>Follow up question</Follow>
                        <Answer>Answer 1</Answer>
                    </Response>

                  
        </Container>
    )
}

export default Question


// Function to get initials from the name
const getInitials = (firstName, lastName) => {

    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  };
  const colors = [
    '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', 
    '#34495e', '#16a085', '#27ae60', '#2980b9', 
    '#8e44ad', '#2c3e50', '#f39c12', '#d35400', 
    '#c0392b', '#e74c3c', '#e67e22', '#f1c40f',
    '#e84393', '#00cec9', '#0984e3', '#6c5ce7'
  ];
  
  // Hash function to consistently map initials to a color
  const hashStringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return colors[hash % colors.length];
  };


const InitialsCircle = ({ firstName, lastName }) => {
    const initials = getInitials(firstName, lastName);
    const color = hashStringToColor(initials);
    
    return <Circle bgColor={color}>{initials}</Circle>;
  };

const Container = styled.div`
    width:100%;
    padding:20px;
    margin-top:20px;
    max-width:800px;
`

const Description = styled.div`

`



const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom:10px;
`



const Response = styled.div`
    background-color: #fff;
    border-radius: 8px;
    margin-top: 20px;
    padding-bottom:20px;
    border-bottom: 1px solid #e0e0e0;
`

const Name = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    display:flex;
    align-items: center;
`

const Answer = styled.div`
    margin-bottom: 10px;
`

const Follow = styled.div`
    font-weight:bold;
    font-style: italic;
    margin-bottom:10px;

`

// Styled component for the circle
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-right:10px;
`;