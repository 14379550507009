import styled from "styled-components";
import React, { useState, useEffect,useContext } from "react";
import { DomainContext } from 'layouts/LayoutContext';

import {
    Button,
    Header,
    Confirm,
    Icon,
    Modal,
    Table
  } from "semantic-ui-react";   

  import config from "_config";



  const PublicLink = ({open,setOpen,token}) => {

    const Domain = useContext(DomainContext);

    return (

        <Modal
        open={open}
        onClose={() => setOpen(false)}
        size="tiny"
        closeIcon
        >
        <Modal.Header>Your public survey link</Modal.Header>
        <Modal.Content>

        <Description>
            Anyone with this link can participate in this survey. They will be requested to provide and validate their email address prior to starting the survey.
        </Description>

        <ShareableUrl url={`${config.surveyUrl}/culture-audit/start/${token}`} />


        </Modal.Content>
        </Modal>

    )

  }

export default PublicLink;



const ShareableUrl = ({ url }) => {
    const [copied, setCopied] = useState(false);
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    };
  
    return (
      <ShareableUrlContainer>
        <UrlText>{url}</UrlText>
        <CopyButton icon onClick={copyToClipboard}>
          <Icon name={copied ? 'check' : 'copy'} />
        </CopyButton>
      </ShareableUrlContainer>
    );
  };

const Description = styled.p`
    font-size: 14px;
    font-family: 'Raleway', sans-serif;
    margin-bottom: 20px;
`


const ShareableUrlContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f9f9f9;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-family: 'Raleway', sans-serif;
`;

const UrlText = styled.span`
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyButton = styled(Button)`
  &&& {
    background-color: #2185d0;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

