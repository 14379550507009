import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Trust from "assets/images/Dependability.svg";
import Clarity from "assets/images/Clarity.svg";
import Communication from "assets/images/Communication.svg";
import Alignment from "assets/images/Direction.svg";
import Resilience from "assets/images/resilience2.svg";
import Impact from "assets/images/Impact.svg";

const Icons = {
	'Trust':Trust,
	'Clarity':Clarity,
	'Communication':Communication,
	Alignment:Alignment,
	Resilience:Resilience,
	Impact:Impact
}

const options = {
              chart: {
                height: 250,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '45%',
                  },
                   track:{
                	background:'#c3a7fd'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
               grid: {
                padding: {
                  bottom:-20
                }
            },
              fill:{
              	colors:['#6a00ff']
              }
            };

const CultureDials = ({data, ...props}) =>{
	const [chartData, setChartDate] = React.useState([0])

	React.useEffect(() => {
		if (data)
		setChartDate(data)
	}, [data])

	// console.log(data)
	// console.log(chartData[0])

	return (
			chartData[0]?  <Card selected={props.selected} onClick={()=>props.setSelected()}>
			
				<Top>
					<Line>
						<Title selected={props.selected}>{props.title}</Title>
					</Line>
				</Top>
				<Chart 
				options={options} 
				series={[chartData[0]]} 
				type="radialBar" 
				height={150} />
				<Values>
					<Change>
						{chartData.length > 1 &&
						(chartData[0] - chartData[1]+'%')}
					</Change>
					<Stat>{chartData[0]}%</Stat>
				</Values>
			</Card>
			: 
			''
		)
}


export default CultureDials


const Title = styled.div`
	font-family: "Red Hat Display", sans-serif;
	font-size:14px;
	font-weight:${props=>props.selected?'bold':400};
	color:#4a4a4a;


`

const Card = styled.div`
	padding:10px;
	box-shadow: ${props=>props.selected?'rgba(0, 0, 0, 0.16) 0px 1px 4px;':'none'};
	position:relative;
	width:125px;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	margin-bottom:20px;
	cursor:pointer;

	&:hover{
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		${Title} {
			font-weight:bold;
		}
	}


`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 15px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:20px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const Change = styled.div`
	font-size:14px;
	font-family: "Red Hat Display", sans-serif;
	margin-bottom:0px;
`

const Top = styled.div`
	width:100%;
	margin-bottom:-10px;
`



const Icon = styled.img`
	height:20px;
`

const View = styled.div`
	font-family: "Red Hat Display", sans-serif;
	cursor:pointer;
`

const Line = styled.div`
	display:flex;
	justify-content:space-between;
	align-items:center;
`

const Arrow = styled.span`
	width: 7px;
  height: 11px;
  margin-left:10px;
`