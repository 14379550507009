import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../../../transitions.css"; // Import your CSS for transitions
import WhatNext from "./WhatNext";
import ProgressTracker from "./ProgressTracker";
import YourPlan from "./YourPlan";
import CheckinFrequency from "./CheckinFrequency";

const NextStep = (props) => {
  const { step } = props;

  const renderContent = (currentStep) => {
    // Use a case statement to determine which component to render
    switch (currentStep) {
      case 0:
        return <WhatNext {...props} />;
      case 1:
        return <CheckinFrequency {...props} />;
      case 2:
        return <ProgressTracker {...props} />;
      case 3:
        return <YourPlan {...props} />;
      default:
        return <WhatNext {...props} />;
    }
  };

  return renderContent(step);
};

export default NextStep;
