import styled from "styled-components";
import React from "react";
import { useSelector, shallowEqual } from "react-redux";

// import { Button } from "semantic-ui-react";
import SettingsTable from "./SettingsTable";
import Personality from "./Personality";
import Leadership from "./Leadership";
import SurveyStatus from "surveyHistory/SurveyStatus";

import { Tab } from "semantic-ui-react";

// import {
//   SG_GET_GLOBAL_SCHEDULES,
//   GET_SURVEY_SETTING_LIST,
// } from "constants/actions";

const _SurveySettings = () => {
  // const dispatch = useDispatch();
  const [organizationalId, setOrganizationalId] = React.useState();
  const [organization, setOrganization] = React.useState();
  const [personality, setPersonality] = React.useState();

  const OrgId = useSelector(
    (state) => Number(state.auth?.organization_id),
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );
  const selectedOrg = useSelector((state) => state.selectedOrg, shallowEqual);
  const get_organizations = useSelector(
    (state) => state.organizations,
    shallowEqual
  );

  React.useEffect(() => {
    setOrganizationalId(
      selectedOrg?.organization?.id
        ? Number(selectedOrg?.organization?.id)
        : OrgId
    );
  }, [AmbassadorId, selectedOrg, OrgId]);

  React.useEffect(() => {
    setOrganization(get_organizations?.[organizationalId]);
    setPersonality(
      get_organizations?.[organizationalId]?.services_enabled.filter(
        (og) => og.id === 4
      )[0]?.enabled === true
    );
  }, [get_organizations, organizationalId]);

  // console.log("organizationalId", organizationalId, "#################")
  // console.log(organizationalId)
  // console.log(get_organizations)
  // console.log()
  // console.log(get_global_schedules)

  const panes = [
    {
      menuItem: "Team Intelligence",
      enabled: true,
      render: () => (
        <>
          <SettingsTable
            AmbassadorId={AmbassadorId}
            organizationalId={organizationalId}
          />
        </>
      ),
    },
    {
      menuItem: "Personality",
      enabled: personality,
      render: () => (
        <>
          <Personality organizationalId={organizationalId} />
        </>
      ),
    },
    {
      menuItem: "Leadership Pulse",
      enabled: false,
      render: () => (
        <>
          <Leadership organizationalId={organizationalId} />
        </>
      ),
    },
    {
      menuItem: "Survey Status",
      enabled: true,
      render: () => (
        <>
          <SurveyStatus organizationalId={organizationalId} />
        </>
      ),
    },
  ];

  // React.useEffect(() => {
  //   if (!get_global_schedules) {
  //     console.log(get_global_schedules)
  //     if (!once) {
  //       const todayDate = new Date()

  //       dispatch({
  //         type: SG_ADD_GLOBAL_SCHEDULE,
  //         payload: {
  //           active: 1,
  //           starting_from: todayDate,
  //           day: todayDate.getDay(),
  //           hour: todayDate.getHours(),
  //           minute: 0,
  //           organization: Number(get_auth.organization_id),
  //         },
  //       });
  //       setOnce(true)

  //     }

  //   }
  // }, [dispatch, once, get_global_schedules, get_auth])

  return (
    <div style={{ marginTop: 50 }}>
      {/* <Tabs /> */}
      <Tab panes={panes.filter((mn) => mn.enabled === true)} />
      {/* <div
        style={{
          display: "flex",
          padding: 10,
          // alignItems: "center",
          justifyContent: "space-between",
          // flexDirection: "column",
          // minHeight: 50,
        }}
      >
        <SubTitle>
          These settings will apply to the team intelligence survey.
        </SubTitle>
        <Button positive large onClick={() => setStartSettings(true)}>
          Start
        </Button>
      </div> */}

      {/* <SettingsTable /> */}
    </div>
  );
};

export default _SurveySettings;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 40px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 40px;
`;
