import React, { useState } from "react";
import styled from "styled-components";
import country_list from "data/countries.json";
import { Dropdown } from "semantic-ui-react";
import { useForm, Controller } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import CustomInput from "./Input";
import { } from 'semantic-ui-react'
import { Input, Button, Item, Header, Segment, Portal, Loader } from 'semantic-ui-react'
import Select from "react-select";


import { UPDATE_ORGANIZATION, SG_GET_PRICES, SG_GET_SUBSCRIPTIONS } from "constants/actions";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { StripeKey } from "_config";

import Price from "./Price";

import CustomDropdown from "./CustomDropdown";
import OrderBox from "./OrderBox";
import OrderBoxMobile from "./OrderBoxMobile";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function Checkout({ organizationId, organization }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, control, watch, errors, setValue } = useForm();
  const [country, setCountry] = useState({ text: "Canada", value: "CA" });
  const [province, setProvince] = useState(false);
  const [open, setOpen] = useState(false)
  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    if (organizationId) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: {
          id: organizationId,
          country: data.country.value,
          address: data.address,
          name: data.name,
          postalcode: data.postalcode,
          city: data.city,
          province: data.province?.value ? data.province.value : data.province,
        },
      });
      setOpen(false)
    }
  };

  const moreDetail = watch("country");

  React.useEffect(() => {
    if (organization) {
      setValue('name', organization?.name)
      setValue('city', organization?.city)
      setValue("address", organization?.address)
      setValue("country", country)
      setValue("postalcode", organization?.postalcode)
      setValue("province", province)
    }
  }, [organization, country, province, setValue])

  // console.log(organization)
  // console.log(province)

  React.useEffect(() => {
    setCountry(country_list.filter(p => p.value === organization?.country)[0])
    if (organization?.country === "CA") {
      setProvince(province_list.filter(p => p.value === organization?.province)[0])
    } else {
      setProvince(organization?.province)
    }
  }, [organization])

  function ChangedCountry(e) {
    setCountry(e)
  }

  React.useEffect(() => {
    setCountry(moreDetail)
  },[moreDetail])
  // console.log(country)
  // console.log(moreDetail)

  return (
    <>
      <Container>
      
      <Segment inverted={open} basic>
          <Loader active={open} inverted />
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap-reverse",
          }}>
            <Header as="h2">Company Address</Header>
            <Button
              content='Edit'
              disabled={open}
              positive
              onClick={() => setOpen(true)}
            />
          </div>
          <Item.Group>
            <Item>

              <Item.Content>
                <Item.Header as='a'>{organization?.name}</Item.Header>
                <Item.Meta> {organization?.address} </Item.Meta>
                <Item.Description>
                  {organization?.city} {organization?.postalcode}
                </Item.Description>
                <Item.Description>
                  {organization?.province}
                </Item.Description>
                <Item.Extra>{country?.text} ({country?.value})</Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      
      <Portal open={open}>
        <Segment
          style={{
            left: '10%',
            position: 'fixed',
            top: '20%',
            zIndex: 1000,
            overflow: "auto",
            // maxWidth: "40%",
          }}
        >
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap-reverse",
            }}>
              <Title>
                Edit Company Address
            </Title>
              <Button circular icon='close' onClick={() => setOpen(false)} />

            </div>

            <InputContainer>
              <Label>Company name</Label>
              <Controller
                as={StyledInput}
                name="name"
                placeholder="Company name"
                type="text"
                control={control}
                defaultValue={organization?.name}
                rules={{
                  required: "Please enter company name",
                }}
              />

              <Error>{errors.name && errors.name.message}</Error>
            </InputContainer>

            <InputContainer>
              <Label>Address</Label>
              <Controller
                as={StyledInput}
                name="address"
                placeholder="Address"
                type="text"
                control={control}
                defaultValue={organization?.address}
                rules={{
                  required: "Please enter company address",
                }}

              />

              <Error>{errors.address && errors.address.message}</Error>
            </InputContainer>

            <InputContainer>
              <Label>Country</Label>
              <Controller
                as={<Select />}
                name="country"
                onChange={ChangedCountry}
                options={country_list}
                getOptionLabel={(option) => `${option.text}`}
                getOptionValue={(option) => `${option.value}`}
                control={control}
                defaultValue={country}
                rules={{ required: "Please select Country" }}
              />{" "}
              <Error>{errors.country && errors.country.message}</Error>
            </InputContainer>

            {/* <InputContainer style={{ marginBottom: 10 }}>
          <Label>Currency</Label>
          <P>For your location, you'll be billed in US dollars.</P>
        </InputContainer> */}
            <InputContainer>
              <Label>City</Label>
              <Controller
                as={StyledInput}
                name="city"
                placeholder="City"
                type="text"
                control={control}
                defaultValue={organization?.city}
                rules={{
                  required: "Please enter a city",
                }}

              />
              <Error>{errors.city && errors.city.message}</Error>


            </InputContainer>
            <InputContainer>
              {moreDetail?.value === "CA" ? (
                <Controller
                  as={<Select />}
                  name="province"
                  options={province_list}
                  getOptionLabel={(option) => `${option.text}`}
                  getOptionValue={(option) => `${option.value}`}
                  control={control}
                  defaultValue={province}
                  rules={{ required: "Please select province" }}
                />

              ) : (
                <Controller
                  as={Input}
                  name="province"
                  placeholder="State / Province"
                  type="text"
                  control={control}
                  defaultValue={province}
                  rules={{
                    required: "Please choose a state/province",
                  }}
                />
              )}
              <Error>{errors.province && errors.province.message}</Error>
            </InputContainer>
            <InputContainer>
              <Controller
                as={Input}
                name="postalcode"
                placeholder={country?.value == "US" ? "Zip code" : "Postal code"}
                type="text"
                control={control}
                defaultValue={organization?.postalcode}
                rules={{
                  required: "Please enter a postal code",
                }}
                error={errors.postalcode}
              />
              <Error>{errors.postalcode && errors.postalcode.message}</Error>
            </InputContainer>

            <StyledButton>Save Address</StyledButton>
          </FormContainer>


        </Segment>
      </Portal>
    
    </Container>
    </>

  )

}

const province_list = [
  { text: "Alberta", value: "Alberta" },
  { text: "British Columbia", value: "British Columbia" },
  { text: "Manitoba", value: "Manitoba" },
  { text: "New Brunswick", value: "New Brunswick" },
  { text: "Newfoundland and Labrador", value: "Newfoundland and Labrador" },
  { text: "Nova Scotia", value: "Nova Scotia" },
  { text: "Nunavut", value: "Nunavut" },
  { text: "Northwest Territories", value: "Northwest Territories" },
  { text: "Ontario", value: "Ontario" },
  { text: "Prince Edward Island", value: "Prince Edward Island" },
  { text: "Quebec", value: "Quebec" },
  { text: "Saskatchewan", value: "Saskatchewan" },
  { text: "Yukon", value: "Yukon" },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 40px 0 rgba(179, 179, 179, 0.5);
  padding: 36px;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 36px 20px 36px 20px;
  }
`;
const Title = styled.div`
  font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 20px;
`;

const StyledInput = styled(Input)({
  borderRadius: "5px",
  height: "45px",
  outline: "none",
  border: ` ${props => props.error ? '1px solid #ff2d55' : '1px solid #9b9b9b'}`,
  marginTop: "3px",
});

const InputContainer = styled.div`
  font-size: 16px;
  font-weight:400;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  flex-direction:column;
  margin-bottom:15px;
  width:${props => props.half ? 'calc(50% - 15px)' : '100%'};

  @media(max-width:768px){
     margin-bottom:5px;
     width:${props => props.half ? 'calc(50% - 5px)' : '100%'};
   }

`

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  padding-bottom: 5px;
`;
const FormContainer = styled.form`
  width: 500px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;
const P = styled.div`
  font-size: 16px;
  font-family: "Red Hat Display", sans-serif;
`;

const Error = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #ff2d55;
`;

const InputContainerRow = styled.div`
  padding: 10px 0 10px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  margin: 20px 0 20px 0;
`;

const StyledButton = styled.button`
  width: 196px;
  height: 50px;
  border-radius: 10px;
  background-color: #6a00ff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  border: none;
  outline: none;
`;
