import styled from "styled-components";
import React from "react";
import Down from "assets/images/UI/down-small.svg";
import Up from "assets/images/UI/up-small.svg";
import { Popup } from "semantic-ui-react";

const MAX_TEXT_LENGTH = 24; // Define maximum text length for truncation

const truncateText = (text) => {
  return text.length > MAX_TEXT_LENGTH
    ? `${text.slice(0, MAX_TEXT_LENGTH)}...`
    : text;
};

const ScoreCard = ({ score, change, lowest, highest, icon, factor }) => {
  return (
    <Card>
      <IconWrapper>{icon}</IconWrapper>
      <LabelGroup>
        <BlueFactorLabel>{factor}</BlueFactorLabel>
        {lowest === highest ? (
          <></>
        ) : (
          <>
            <Label>
              Lowest:&nbsp;
              <Popup
                disabled={lowest.length < MAX_TEXT_LENGTH}
                content={lowest}
                trigger={<BoldedLabel>{truncateText(lowest)}</BoldedLabel>}
              />
            </Label>
            <Label>
              Highest:&nbsp;
              <Popup
                content={highest}
                disabled={highest.length < MAX_TEXT_LENGTH}
                trigger={<BoldedLabel>{truncateText(highest)}</BoldedLabel>}
              />
            </Label>
          </>
        )}
      </LabelGroup>
      <ScoreGroup>
        <Score>{score}%</Score>
        <Change
          color={
            change > 0
              ? "#1D9A7D"
              : change && !isNaN(change)
              ? "#F15062"
              : "#000"
          }
        >
          {(change && (
            <>
              <Pic src={change < 0 ? Down : Up} /> {change > 0 ? "+" : ""}
              {change} %
            </>
          )) || <></>}
        </Change>
      </ScoreGroup>
    </Card>
  );
};

export default ScoreCard;

// Styled Components
const Card = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  border-radius: 8px;
  padding: 10px;
  height: 75px;
  min-width: 250px;
  width: 100%;
  background-color: #f8faff;
`;

const IconWrapper = styled.div`
  padding: 6px;
  border-radius: 8px;
  background-color: #ebf1fd;
  height: 35px;
  width: 35px;
`;

const LabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Label = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const BoldedLabel = styled.div`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
`;

const Score = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
  color: #2a3039;
`;

const Change = styled.div`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  color: ${(props) => props.color};
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
`;

const Pic = styled.img`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-right: 3px;
`;

const ScoreGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 5px;
  margin-top: 5px;
`;

const BlueFactorLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2d70e2;
`;