import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Icon} from 'semantic-ui-react'
import TopProgress from './TopProgress'
import Light from 'assets/images/UI/lightbulb.svg'
import ContentScreen from './Content'
import template from './template_demo.json'
import Back from '../Back'

const Explain = ({back}) =>{
    const [step,setStep] = useState(0)

    return (
        <Container>
            <Side>
            <Back url={'/simple/prototype/explain/dashboard'} location={'data stories'}/>
              <Title>Your culture data explained</Title>
              <Description>
              We've analyzed your culture data and have created a walkthrough of the key elements of your culture. 
              </Description>
              <TopProgress step={step} totalSteps={template.length} steps={template} setStep={(s)=>setStep(s)}/>
          </Side>

          <Content>
            <ContentScreen step={step} setStep={setStep} data={template[step]} back={back} total_steps={template.length}/>
          </Content>
        </Container>
    )
}

export default Explain


const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
width:100%;
display:flex;
flex-direction:row;
max-width:1200px;
`

const Content = styled.div`
  width:calc(100% - 400px);
  font-family:'Raleway';
  padding:30px;
  padding-left:50px;
  padding-top:65px;

`



const Side = styled.div`
  width:450px;
  padding:30px;
  padding-left:40px;
  background-color:#F8FAFF;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-family: 'Raleway';
    margin-bottom: 10px;
    color:#2A3039;
`

const Description = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    font-family: 'Raleway';
    margin-bottom:30px;
`

const IMG = styled.img`
  height:40px;
  margin-bottom:10px;
`