import React, { useEffect, useState } from "react";

import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_DATA_STORIES,
  SG_EDIT_CHAT_RECOMMENDATION,
  SET_ACTIVE_RECOMMENDATION,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_GET_EMPLOYEE_ORG_PERMISSIONS,
} from "constants/actions";
import { format } from "date-fns";

import Box from "./components/Box";
import Back from "./components/Back";
import { Dimmer } from "semantic-ui-react";
import Loading from "./components/Loading";
import Empty from "./components/empty";

const StoryDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dataStories, setDataStories] = useState();
  const [filteredDataStories, setFilteredDataStories] = useState();
  const [employeeInfo, setEmployeeInfo] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [employeePermissions, setEmployeePermissions] = useState();
  const [groupTitle, setGroupTitle] = useState();
  const [loading, setLoading] = useState(true);
  const [employeeCategories, setEmployeeCategories] = useState();

  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    get_survey_structure,
    get_employee_category,
    get_employee_permission,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_category: state.employee_category.employee_org_category,
      get_employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    const employeePerms =
      get_employee_permission?.employee_org_permission?.find(
        (emp) => emp?.employee_id === employeeInfo?.id
      );

    if (employeePerms && employeePerms?.details?.length > 0) {
      // Filter the details so that all we have is the ones that work for this component.
      const filteredDetails = employeePerms?.details?.filter(
        (detail) => detail?.name_id && detail?.value_id
      );

      // If the filtered details are not empty, set the employee permissions to the filtered details.
      // This means the data stories will be filtered based on the permissions and not the selected group.
      if (filteredDetails?.length > 0) {
        setEmployeePermissions(filteredDetails);
      }
    }
  }, [get_employee_permission]);

  useEffect(() => {
    if (employeePermissions && dataStories) {
      const filteredDataStories = dataStories?.map((item) => {
        const dateObject = new Date(item.created_at);
        const groupTitle = `${format(dateObject, "MMMM d, yyyy")}`;
        return {
          id: item.id,
          title: groupTitle,
          data_story: item?.recommendation?.data_story?.filter((story) => {
            return employeePermissions.some((permission) => {
              return (
                permission.name_id === story.parent_id &&
                permission.value_id === story.category_option
              );
            });
          }),
        };
      });

      setFilteredDataStories(filteredDataStories);
    }
  }, [employeePermissions, dataStories]);

  useEffect(() => {
    setEmployeeInfo(get_auth_employee?.employee);
  }, [get_auth_employee]);

  // i just add dispatch to the array of dependencies not required but saves me from a warning
  useEffect(() => {
    dispatch({ type: SG_GET_DATA_STORIES });
  }, [dispatch]);

  useEffect(() => {
    if (employeeInfo) {
      dispatch({
        type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
        payload: `employee=${employeeInfo?.id}`,
      });

      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${employeeInfo?.organization_id}`,
      });

      dispatch({
        type: SG_GET_EMPLOYEE_ORG_PERMISSIONS,
        payload: `organization=${Number(employeeInfo?.organization_id)}`,
      });
    }
  }, [dispatch, employeeInfo]);

  useEffect(() => {
    if (get_chat_gpt_recommendations?.data_stories) {
      setDataStories(get_chat_gpt_recommendations?.data_stories?.results);
      setMessage(get_chat_gpt_recommendations?.data_stories?.message);
      setLoading(false);
    }
  }, [get_chat_gpt_recommendations]);

  useEffect(() => {
    if (selectedId && dataStories) {
      const selectedGroup = dataStories.find(
        (story) => story.id === selectedId
      );
      setSelectedGroup(selectedGroup);

      const dateObject = new Date(selectedGroup.created_at);
      const groupTitle = `Data stories generated ${format(
        dateObject,
        "MMMM d, yyyy"
      )}`;
      setGroupTitle(groupTitle);
    }
  }, [selectedId, dataStories]);

  useEffect(() => {
    if (selectedGroup) {
      const parentCategory = get_survey_structure?.[0]?.categories?.find(
        (cat) => cat.id === selectedGroup.category_id
      );

      setSelectedCategory(parentCategory);
    }
  }, [selectedGroup]);

  // i have not completed this function
  function updateReadStatus(id) {
    // need to fetch employee read status
    // from get_chat_gpt_recommendations?.data_stories?.data
    const ReadStory = {
      read: [id],
    };

    dispatch({
      type: SG_EDIT_CHAT_RECOMMENDATION,
      payload: {
        id: id,
        user_status: ReadStory,
      },
    });
  }

  // proposed user status object
  // const user_status = {
  //   <employee_id>: {
  //     read: [<story_id>, <story_id>]
  //   }
  // }

  // const user_status = {
  //   5: {
  //     read: [1]
  //   }
  // }

  const handleStoryBack = () => {
    setSelectedId(null);
    setSelectedGroup(null);
    setSelectedCategory(null);
  };

  const storyList = filteredDataStories ? (
    <>
      {filteredDataStories
        ?.filter((story) => story?.data_story?.length > 0)
        ?.map((story) => {
          return (
            <FilteredGroup>
              {/* <Title>{story.title}</Title> */}
              <FilteredStories>
                {story.data_story?.map((ds) => {
                  const parentCategory =
                    get_survey_structure?.[0]?.categories?.find(
                      (cat) => cat.id === ds.parent_id
                    );

                  const selectedOption = parentCategory?.options?.find(
                    (opt) => opt.id === ds.category_option
                  );

                  const title = `${parentCategory?.name} - ${selectedOption?.name} data story`;
                  const description = `This data story was generated from the ${parentCategory?.name} category and the ${selectedOption?.name} option, on ${story.title}`;
                  return (
                    <Box
                      onClick={() => {
                        dispatch({
                          type: SET_ACTIVE_RECOMMENDATION,
                          payload: ds,
                        });
                        navigate(`/simple/audit/datastory/1`);
                      }}
                      key={ds.id}
                      title={title}
                      icon={"book"}
                      // read={story.readStatus}
                      description={description}
                    />
                  );
                })}
              </FilteredStories>
            </FilteredGroup>
          );
        })}
    </>
  ) : (
    <>
      <>
        {(!selectedId &&
          dataStories &&
          dataStories
            ?.filter((sg) => sg?.recommendation?.data_story?.length > 0)
            ?.map((story) => {
              const dateObject = new Date(story.created_at);
              const groupTitle = `Data stories generated ${format(
                dateObject,
                "MMMM d, yyyy"
              )}`;

              return (
                <Box
                  onClick={() => {
                    // TODO: Add this to next part.
                    // updateReadStatus(story.id);
                    setSelectedId(story.id);
                  }}
                  key={story.id}
                  title={groupTitle}
                  icon={"book"}
                  // read={story.readStatus}
                  description={story.description}
                />
              );
            })) ||
          (!selectedId && dataStories && dataStories.length === 0 && <Empty />)}
      </>
      <>
        {selectedGroup &&
          selectedGroup?.recommendation?.data_story?.length > 0 &&
          selectedGroup?.recommendation?.data_story
            ?.filter((ds) => ds?.response?.length > 0)
            ?.map((story) => {
              const parentCategory =
                get_survey_structure?.[0]?.categories?.find(
                  (cat) => cat.id === story.parent_id
                );

              const selectedOption = parentCategory?.options?.find(
                (opt) => opt.id === story.category_option
              );

              const title = `${parentCategory?.name} - ${selectedOption?.name} data story`;
              const description = `This data story is generated from the ${parentCategory?.name} category and the ${selectedOption?.name} option.`;
              return (
                <Box
                  onClick={() => {
                    dispatch({
                      type: SET_ACTIVE_RECOMMENDATION,
                      payload: story,
                    });
                    navigate(`/simple/audit/datastory/1`);
                  }}
                  key={story.id}
                  title={title}
                  icon={"book"}
                  // read={story.readStatus}
                  description={description}
                />
              );
            })}
      </>
    </>
  );

  return (
    <Container>
      <Center>
        <div style={{ marginBottom: 30 }} />
        <Back
          onClick={selectedId ? handleStoryBack : null}
          url={"/simple/audit/simpledash"}
          location={"dashboard"}
        />
        {dataStories &&
          ((!filteredDataStories && (
            <Title>{!selectedId ? "Your data story groups" : groupTitle}</Title>
          )) || (
            <Title>{!selectedId ? "Your data stories" : groupTitle}</Title>
          ))}
        {!dataStories && (
          <LoadWrapper>
            <Loading />
            <LoadingText>Loading your data stories</LoadingText>
          </LoadWrapper>
        )}
        <>{storyList}</>
      </Center>
    </Container>
  );
};

export default StoryDashboard;

const Container = styled.div`
  padding-left: 58px;
  padding-right: 40px;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FilteredGroup = styled.div``;

const FilteredStories = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Center = styled.div`
  width: 800px;
  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state

  // 3. Adjust delay for each sample
  animation-delay: 0.3s;
`;

const LoadingText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  padding-top: 20px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const LoadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
