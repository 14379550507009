import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDown } from 'assets/images/UI/chevron-down.svg'; // Import your chevron-down.svg here


const CustomDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(props.defaultLabel);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <DropdownWrapper>
      <DropdownButton onClick={toggleDropdown}>
        {props.defaultLabel}
        <StyledChevron isOpen={isOpen} />
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {props.options.map((option) => (
          <DropdownItem
            href="#"
            onClick={(e) => {
              e.preventDefault();
              // props.onSelect(option.value);
              props.onDropdownSelect(option);
              handleOptionClick(option.text);
            }}
          >
            {option.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownWrapper>
  );
};

  export default CustomDropdown;


const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  color: #666D79;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
`;

const DropdownMenu = styled.div`
  position: absolute;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  min-width: 160px;
  padding: 8px 0;
  margin-top: 4px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
`;

const DropdownItem = styled.a`
  display: block;
  padding: 8px 16px;
  color: #666D79;
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const StyledChevron = styled(ChevronDown)`
  width: 12px;
  height: 12px;
  margin-left: 8px;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s;
`;
