import { erf } from "mathjs";
export function filterData(data, filters) {
  // Create an empty array to store the filtered results
  let filteredData = [];

  // Iterate over the data set
  data.forEach((item) => {
    // Define a flag to determine if the current item satisfies all filters
    let allFiltersSatisfied = true;
    // Group filters by UID
    const groupedFilters = filters.reduce((acc, filter) => {
      if (!acc[filter.uid]) {
        acc[filter.uid] = [];
      }
      acc[filter.uid].push(filter.optionUID);
      return acc;
    }, {});

    // Iterate over the grouped filters
    for (const uid in groupedFilters) {
      // Find the category with the same UID as the filter
      const category = item.categories.find((category) => category.id === uid);
      // If the category is not found or the options don't match any of the option UIDs in the group, set the flag to false
      if (!category || !groupedFilters[uid].includes(category.response)) {
        allFiltersSatisfied = false;
        break;
      }
    }

    // If the item satisfies all filters, add it to the filteredData array
    if (allFiltersSatisfied) {
      filteredData.push(item);
    }
  });

  // Return the filtered data
  return filteredData;
}

export const getLastFactorAverage = (
  reportData,
  filterArray,
  questions,
  dimension,
  factor
) => {
  const previousData = filterData(reportData[0]?.last, filterArray);

  let questionSum;
  if (dimension && factor) {
    //Filter questions based on the selected pillar first
    questionSum = previousData
      .map((item) =>
        item.questions.filter((f) => f.id === dimension && f.factor === factor)
      )
      .flat()
      .map((item) => check_reverse_score(item, questions));
  } else {
    questionSum = previousData
      .map((item) => item.questions)
      .flat()
      .map((item) => check_reverse_score(item, questions));
  }

  return average(questionSum);
};

const get_buckets = (data) => {
  let response = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data.map((i) => {
    response[Math.round(i)] += 1;
  });
  return response;
};

// Get summary average
export const summaryAverage = (data) => {
  let total = 0;
  let count = 0;
  data.forEach((item) => {
    total += item.average * item.n;
    count += item.n;
  });
  return total / count;
};

export const calculate_table_data = (
  data,
  filterArray,
  structure,
  anchor,
  rule,
  questions,
  extended = false
) => {
  //find the primary nav structure
  remove_NA_data(data);

  let anchor_priority = structure.categories.find(
    (f) => f.id === anchor
  ).priority;

  // TODO: Complete the routine for extended param.
  // It should return an array of the below data, this
  // array contains data for each primary as if they
  // were part of the filterArray
  if (extended) {
    const calculatedData = [];
    const primaryOptionsArr = getPrimaryOptions([structure]);
    primaryOptionsArr.forEach((op) => {
      let subfactor = [];
      let filters = [];
      let newFilterArray = [...filterArray, op];
      let filter_data = filterData(data[0]?.responses, newFilterArray);

      //Begin to summary the question data in to the proper tables
      let response = {};
      filter_data?.map((item, i) => {
        let secondary;
        let primary;
        let subfactor_parent = null;
        if (subfactor.length > 0 && anchor_priority === "primary") {
          secondary = item.categories.find((f) => f.id == anchor).subfactor;
          subfactor_parent = item.categories.find(
            (f) => f.id == anchor
          ).response;
        } else {
          secondary = item.categories.find((f) => f.id == anchor)?.response;
        }

        if (!secondary) {
          return;
        }

        item.questions.map((q, r) => {
          if (!(q.id in response)) {
            response[q.id] = {};
          }
          if (!(q.factor in response[q.id])) {
            response[q.id][q.factor] = {};
          }
          if (!(q.q in response[q.id][q.factor])) {
            response[q.id][q.factor][q.q] = {};
          }
          if (!(secondary in response[q.id][q.factor][q.q])) {
            response[q.id][q.factor][q.q][secondary] = {
              total: 0,
              n: 0,
              responses: [],
              adjusted_total: 0,
              adjusted_responses: [],
              subfactor_parent,
            };
          }
          let resp = check_reverse_score(q, questions);

          response[q.id][q.factor][q.q][secondary].adjusted_total += resp;
          response[q.id][q.factor][q.q][secondary].adjusted_responses.push(
            resp
          );
          response[q.id][q.factor][q.q][secondary].total += q.response;
          response[q.id][q.factor][q.q][secondary].n += 1;
          response[q.id][q.factor][q.q][secondary].responses.push(q.response);
        });
      });

      let processed_responses = [];
      Object.keys(response).map((item, i) => {
        let section = response[item];
        Object.keys(section).map((s) => {
          let question = section[s];
          Object.keys(question).map((q) => {
            let role = question[q];
            Object.keys(role).map((r) => {
              let role_data = role[r];
              let average = role_data.total / role_data.n;
              let stdev =
                Math.floor(getStandardDeviation(role_data.responses) * 10) / 10;
              let label;
              if (subfactor.length > 0 && anchor_priority == "primary") {
                label = label = structure.categories
                  .find((f) => f.id == anchor)
                  .options.find((f) => f.id == role_data.subfactor_parent)
                  .subfactors.find((f) => f.id == r);
              } else {
                label = structure.categories
                  .find((f) => f.id == anchor)
                  .options.find((f) => f.id == r);
              }

              let distribution = get_buckets(role_data.responses);
              let factors = { dimension: i, factor: s, x: 0, y: 0 };
              // let norm = calculate_norms(data,r,average,factors,q)
              processed_responses.push({
                average,
                label,
                distribution,
                role: r,
                question: q,
                section: s,
                dimension: i,
                stdev,
                n: role_data.n,
              });
            });
          });
        });
      });

      //summarize data in person and performance
      let summary = {};
      Object.keys(response).map((item, i) => {
        summary[item] = {};
        Object.keys(response[item]).map((f) => {
          summary[item][f] = {};
          Object.keys(response[item][f]).map((q) => {
            Object.keys(response[item][f][q]).map((s) => {
              if (!(s in summary[item][f])) {
                summary[item][f][s] = { total: 0, n: 0, responses: [] };
              }
              summary[item][f][s].total +=
                response[item][f][q][s].adjusted_total;
              summary[item][f][s].n += response[item][f][q][s].n;
              summary[item][f][s].responses.push(
                get_buckets(response[item][f][q][s].adjusted_responses)
              );
            });
          });
        });
      });

      let processed_summary = [];
      Object.keys(summary).map((item, i) => {
        let dimension = summary[item];
        Object.keys(dimension).map((f) => {
          let factor = dimension[f];
          Object.keys(factor).map((s) => {
            let secondary = factor[s];

            let average = secondary.total / secondary.n;

            let n =
              secondary.n /
              questions.dimensions[item]?.factors[f]?.questions.length;

            let distribution = sum_distribution(secondary.responses);
            processed_summary.push({
              dimension: item,
              factor: f,
              secondary: s,
              average,
              distribution,
              n: n,
            });
          });
        });
      });

      //summarize data in overall culture
      let overall = {};
      Object.keys(response).map((item, i) => {
        Object.keys(response[item]).map((f) => {
          Object.keys(response[item][f]).map((q) => {
            Object.keys(response[item][f][q]).map((s) => {
              if (!(s in overall)) {
                overall[s] = { total: 0, n: 0, responses: [], n1: 0 };
              }
              overall[s].total += response[item][f][q][s].adjusted_total;
              overall[s].n += response[item][f][q][s].n;
              overall[s].n1 = response[item][f][q][s].n;
              //need to reverse the appropriate responses
              overall[s].responses.push(
                average(response[item][f][q][s].adjusted_responses)
              );
            });
          });
        });
      });

      //filter based on the rule of 3
      processed_summary = processed_summary.filter((f) => f.n > rule);
      processed_responses = processed_responses.filter((f) => f.n > rule);
      const asArray = Object.entries(overall);
      const filtered = asArray.filter(
        ([key, value]) => value.n / value.responses.length > rule
      );
      const filtered_overall = Object.fromEntries(filtered);

      calculatedData.push({
        primary: op.optionUID,
        responses: processed_responses,
        summary: processed_summary,
        overall: filtered_overall,
      });
    });

    return calculatedData;
  } else {
    let filter_data = filterData(data[0]?.responses, filterArray);

    let subfactor = [];
    let filters = [];
    //Begin to summary the question data in to the proper tables
    let response = {};
    filter_data?.map((item, i) => {
      let secondary;
      let primary;
      let subfactor_parent = null;
      if (subfactor.length > 0 && anchor_priority === "primary") {
        secondary = item.categories.find((f) => f.id == anchor).subfactor;
        subfactor_parent = item.categories.find((f) => f.id == anchor).response;
      } else {
        secondary = item.categories.find((f) => f.id == anchor)?.response;
      }

      if (!secondary) {
        return;
      }

      item.questions.map((q, r) => {
        if (!(q.id in response)) {
          response[q.id] = {};
        }
        if (!(q.factor in response[q.id])) {
          response[q.id][q.factor] = {};
        }
        if (!(q.q in response[q.id][q.factor])) {
          response[q.id][q.factor][q.q] = {};
        }
        if (!(secondary in response[q.id][q.factor][q.q])) {
          response[q.id][q.factor][q.q][secondary] = {
            total: 0,
            n: 0,
            responses: [],
            adjusted_total: 0,
            adjusted_responses: [],
            subfactor_parent,
          };
        }
        let resp = check_reverse_score(q, questions);

        response[q.id][q.factor][q.q][secondary].adjusted_total += resp;
        response[q.id][q.factor][q.q][secondary].adjusted_responses.push(resp);
        response[q.id][q.factor][q.q][secondary].total += q.response;
        response[q.id][q.factor][q.q][secondary].n += 1;
        response[q.id][q.factor][q.q][secondary].responses.push(q.response);
      });
    });

    let processed_responses = [];
    Object.keys(response).map((item, i) => {
      let section = response[item];
      Object.keys(section).map((s) => {
        let question = section[s];
        Object.keys(question).map((q) => {
          let role = question[q];
          Object.keys(role).map((r) => {
            let role_data = role[r];
            let average = role_data.total / role_data.n;
            let stdev =
              Math.floor(getStandardDeviation(role_data.responses) * 10) / 10;
            let label;
            if (subfactor.length > 0 && anchor_priority == "primary") {
              label = label = structure.categories
                .find((f) => f.id == anchor)
                .options.find((f) => f.id == role_data.subfactor_parent)
                .subfactors.find((f) => f.id == r);
            } else {
              label = structure.categories
                .find((f) => f.id == anchor)
                .options.find((f) => f.id == r);
            }

            let distribution = get_buckets(role_data.responses);
            let factors = { dimension: i, factor: s, x: 0, y: 0 };
            // let norm = calculate_norms(data,r,average,factors,q)
            processed_responses.push({
              average,
              label,
              distribution,
              role: r,
              question: q,
              section: s,
              dimension: i,
              stdev,
              n: role_data.n,
            });
          });
        });
      });
    });

    //summarize data in person and performance
    let summary = {};
    Object.keys(response).map((item, i) => {
      summary[item] = {};
      Object.keys(response[item]).map((f) => {
        summary[item][f] = {};
        Object.keys(response[item][f]).map((q) => {
          Object.keys(response[item][f][q]).map((s) => {
            if (!(s in summary[item][f])) {
              summary[item][f][s] = { total: 0, n: 0, responses: [] };
            }
            summary[item][f][s].total += response[item][f][q][s].adjusted_total;
            summary[item][f][s].n += response[item][f][q][s].n;
            summary[item][f][s].responses.push(
              get_buckets(response[item][f][q][s].adjusted_responses)
            );
          });
        });
      });
    });

    let processed_summary = [];
    Object.keys(summary).map((item, i) => {
      let dimension = summary[item];
      Object.keys(dimension).map((f) => {
        let factor = dimension[f];
        Object.keys(factor).map((s) => {
          let secondary = factor[s];

          let average = secondary.total / secondary.n

          let n =
            secondary.n /
            questions.dimensions[item]?.factors[f]?.questions.length;

          let distribution = sum_distribution(secondary.responses);
          processed_summary.push({
            dimension: item,
            factor: f,
            secondary: s,
            average,
            distribution,
            n: n,
          });
        });
      });
    });

    //summarize data in overall culture
    let overall = {};
    Object.keys(response).map((item, i) => {
      Object.keys(response[item]).map((f) => {
        Object.keys(response[item][f]).map((q) => {
          Object.keys(response[item][f][q]).map((s) => {
            if (!(s in overall)) {
              overall[s] = { total: 0, n: 0, responses: [], n1: 0 };
            }
            overall[s].total += response[item][f][q][s].adjusted_total;
            overall[s].n += response[item][f][q][s].n;
            overall[s].n1 = response[item][f][q][s].n;
            //need to reverse the appropriate responses
            overall[s].responses.push(
              average(response[item][f][q][s].adjusted_responses)
            );
          });
        });
      });
    });

    //filter based on the rule of 3
    processed_summary = processed_summary.filter((f) => f.n > rule);
    processed_responses = processed_responses.filter((f) => f.n > rule);
    const asArray = Object.entries(overall);
    const filtered = asArray.filter(
      ([key, value]) => value.n / value.responses.length > rule
    );
    const filtered_overall = Object.fromEntries(filtered);

    return {
      responses: processed_responses,
      summary: processed_summary,
      overall: filtered_overall,
    };
  }
};

// Create a function that takes data?.responses and loops through the array.
// add each r.average to a new array
// return the new array
export const getCultureAverages = (data, labelName) => {
  console.log(data)
  if (!labelName) {
    return summaryAverage(data?.summary);
  }

  

  // using labelName, filter the responses which match the labelName
  // then map the responses to a new array of objects with average and n
  const filteredResponses = data?.responses;

  const averages = filteredResponses.map((r) => {
    return {
      average: r.average,
      n: r.distribution?.length,
    };
  });

  // Get the average of the averages using n to get the weight of each average
  const averageOfAverages =
    averages.reduce((acc, curr) => {
      return acc + curr.average * curr.n;
    }, 0) /
    averages.reduce((acc, curr) => {
      return acc + curr.n;
    }, 0);

  return averageOfAverages;
};

const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;
  return response;
};

const check_all_selected = (structure, item, filters) => {
  let filter_length = filters[item.id]?.length;
  let structure_length = structure.categories.find((f) => f.id == item.id)
    ?.options.length;
  return filter_length == structure_length;
};

const sum_distribution = (arr) => {
  let _distribution = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  arr.map((item) => {
    item.map((s, i) => (_distribution[i] += s));
  });
  return _distribution;
};

export const getCategoryFromOption = (id, structure) => {
  const categoryFound = structure.find((cat) => {
    return cat?.options.find((op) => {
      return op.id === id;
    });
  });
  if (categoryFound) {
    return {
      uid: categoryFound.id,
      optionUID: id,
    };
  } else {
    return null;
  }
};

const condensed = (r, questions) => {
  let summary = [];
  r.questions.map((item) => {
    let exists = summary.findIndex(
      (f) => f.id == item.id && f.factor == item.factor
    );

    let resp = check_reverse_score(item, questions);
    if (exists >= 0) {
      let data = summary[exists];

      data.response.push(resp);
      summary[exists] = data;
    } else {
      let data = {
        id: item.id,
        factor: item.factor,
        response: [resp],
      };

      summary.push(data);
    }
  });

  return summary;
};

const get_BIPOC = (filtered_data, structure, rule, questions) => {
  let _data = {};
  let ranked = {};

  let e = structure.categories.find(
    (f) => f.name === "Ethnicity" && f.priority != "primary"
  );
  let e_id = e.id;
  let selection = e.options.find((d) => d.name == "White").id;

  let _filtered_data = filtered_data
    .filter((f) => f.questions.length > 0)
    .filter(
      (f) => f.categories.find((c) => c.id === e_id).response === selection
    )
    .filter((f) => f.questions.length > 0);

  if (_filtered_data.length > rule) {
    _data["Non-BIPOC"] = {};
    ranked["Non-BIPOC"] = [];
    _filtered_data.map((r) => {
      let summary = condensed(r, questions);
      try {
        let secondary = r.categories.find(
          (f) => f.priority === "secondary"
        ).response;
        if (!(secondary in _data["Non-BIPOC"])) {
          _data["Non-BIPOC"][secondary] = [];
        }
        let x = [0, 0];
        let y = [0, 0];
        if (r.questions.length > 0) {
        }
        r.questions.map((d) => {
          let resp = check_reverse_score(d, questions);

          if (d.id === 0) {
            x[0] += resp;
            x[1] += 1;
          } else {
            y[0] += resp;
            y[1] += 1;
          }
        });
        ranked["Non-BIPOC"].push(summary);

        _data["Non-BIPOC"][secondary].push([x[0] / x[1], y[0] / y[1]]);
      } catch (err) {
        console.log(err);
      }
    });
  }

  _filtered_data = filtered_data
    .filter((f) => f.questions.length > 0)
    .filter(
      (f) => f.categories.find((c) => c.id === e_id).response !== selection
    )
    .filter((f) => f.questions.length > 0);

  if (_filtered_data.length > rule) {
    _data["BIPOC"] = {};
    ranked["BIPOC"] = [];
    _filtered_data.map((r) => {
      let summary = condensed(r, questions);
      try {
        let secondary = r.categories.find(
          (f) => f.priority === "secondary"
        ).response;
        if (!(secondary in _data["BIPOC"])) {
          _data["BIPOC"][secondary] = [];
        }
        let x = [0, 0];
        let y = [0, 0];
        if (r.questions.length > 0) {
        }
        r.questions.map((d) => {
          let resp = check_reverse_score(d, questions);

          if (d.id === 0) {
            x[0] += resp;
            x[1] += 1;
          } else {
            y[0] += resp;
            y[1] += 1;
          }
        });
        ranked["BIPOC"].push(summary);

        _data["BIPOC"][secondary].push([x[0] / x[1], y[0] / y[1]]);
      } catch (err) {
        console.log(err);
      }
    });
  }

  return [_data, ranked];
};

const filter_anchor = (data, anchor, item) => {
  return data
    ?.filter((f) => f.questions.length > 0)
    .filter(
      (f) => f.categories.find((c) => c.id === anchor)?.response === item.id
    )
    .filter((f) => f.questions.length > 0);
};

const get_anchor_data = (
  structure,
  anchor,
  filtered_data,
  rule,
  questions,
  filtered_last,
  showPrimary = false
) => {
  //get the list of the categories options and map through

  if (anchor === -1) {
    return get_BIPOC(filtered_data, structure, rule, questions);
  }
  let anchor_list = structure.categories.find((f) => f.id === anchor)?.options;
  let _data = {};
  let ranked = {};
  let _last_data = {};
  let last_ranked = {};
  let primaryDataMap = {};
  let lastPrimaryDataMap = {};

  if (anchor_list) {
    anchor_list.map((item, i) => {
      _data[item.id] = {};
      primaryDataMap[item.id] = [];
      lastPrimaryDataMap[item.id] = [];
      ranked[item.id] = [];

      _last_data[item.id] = {};

      last_ranked[item.id] = [];
      let _filtered_data = filter_anchor(filtered_data, anchor, item);
      let _filtered_last = filter_anchor(filtered_last, anchor, item);

      if (_filtered_data.length > rule) {
        //Loop through each response, and categorize them based on the secondary anchor
        _filtered_data.map((r, i) => {
          let summary = condensed(r, questions);
          try {
            let secondary = r.categories.find(
              (f) => f.priority === "secondary"
            ).response;

            let primary;
            if (!showPrimary) {
              primary = r.categories.find(
                (f) => f.priority === "primary"
              ).response;
            }

            if (!(secondary in _data[item.id])) {
              _data[item.id][secondary] = [];
            }
            let x = [0, 0];
            let y = [0, 0];
            if (r.questions.length > 0) {
            }
            r.questions.map((d) => {
              let resp = check_reverse_score(d, questions);

              if (d.id === 0) {
                x[0] += resp;
                x[1] += 1;
              } else {
                y[0] += resp;
                y[1] += 1;
              }
            });
            ranked[item.id].push(summary);

            if (!showPrimary) {
              primaryDataMap[item.id][i] = primary;
              _data[item.id][secondary].push([x[0] / x[1], y[0] / y[1]]);
            } else {
              _data[item.id][secondary].push([x[0] / x[1], y[0] / y[1]]);
            }
          } catch (err) {
            console.log(err);
          }
        });

        _filtered_last?.map((r) => {
          let summary = condensed(r, questions);
          try {
            let secondary = r.categories.find(
              (f) => f.priority === "secondary"
            ).response;

            let primary;
            if (showPrimary) {
              primary = r.categories.find(
                (f) => f.priority === "primary"
              ).response;
            }

            if (!(secondary in _last_data[item.id])) {
              _last_data[item.id][secondary] = [];
            }
            let x = [0, 0];
            let y = [0, 0];
            if (r.questions.length > 0) {
            }
            r.questions.map((d) => {
              let resp = check_reverse_score(d, questions);

              if (d.id === 0) {
                x[0] += resp;
                x[1] += 1;
              } else {
                y[0] += resp;
                y[1] += 1;
              }
            });
            last_ranked[item.id].push(summary);

            if (!showPrimary) {
              lastPrimaryDataMap[item.id][i] = primary;
              _last_data[item.id][secondary].push([x[0] / x[1], y[0] / y[1]]);
            } else {
              _last_data[item.id][secondary].push([x[0] / x[1], y[0] / y[1]]);
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    });
  }

  return [
    _data,
    ranked,
    _last_data,
    last_ranked,
    lastPrimaryDataMap,
    primaryDataMap,
  ];
};

const remove_NA_data = (data) => {
  return data.map((item) => {
    let _item = item;
    _item.responses = item.responses.map((resp) => {
      let _resp = resp;
      _resp.questions = resp.questions.filter((f) => f.response != "N/A");
      return _resp;
    });
    if (_item.last) {
      _item.last = item.last.map((resp) => {
        let _resp = resp;
        _resp.questions = resp.questions.filter((f) => f.response != "N/A");
        return _resp;
      });
    }

    return _item;
  });
};

const getTitle = (idx, structure, option, anchor) => {
  if (anchor === -1) {
    return { name: idx };
  }
  if (anchor) {
    let option_name = structure.categories
      .find((f) => f.id == anchor)
      .options.find((f) => f.id == idx);
    return option_name;
  }
  let structures = structure.categories.filter(
    (f) => f.priority == "primary"
  )[0];

  return structure.categories
    .find((f) => f.priority == "primary")
    .options.find((f) => f.id == option)
    .subfactors.find((f) => f.id == idx);
};

export const overall = (data) => {
  let average = [0, 0];
  let n = 0;
  Object.keys(data).map((k, i) => {
    data[k].map((item) => {
      average[0] += item[0];
      average[1] += item[1];
      n += 1;
    });
  });

  return [average[0] / n, average[1] / n];
};

const get_ranking = (r) => {
  let summary = [];
  r.map((item) => {
    item.map((cat) => {
      let exists = summary.findIndex(
        (f) => f.id == cat.id && f.factor == cat.factor
      );
      if (exists >= 0) {
        let data = summary[exists];

        data.response.push(average(cat.response));
        summary[exists] = data;
      } else {
        let data = {
          id: cat.id,
          factor: cat.factor,
          response: [average(cat.response)],
        };
        summary.push(data);
      }
    });
  });

  return summary.map((item) => {
    return { ...item, response: average(item.response) };
  });
};

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += item;
    n += 1;
  });
  return total / n;
};

const get_ranked_diff = (current, last) => {
  return current.map((item) => {
    item["diff"] =
      item.response -
      last.find((f) => f.id == item.id && f.factor == item.factor)?.response;
    return item;
  });
};

const structure_data = (
  data,
  ranked,
  structure,
  anchor,
  last,
  last_ranked,
  primaryDataMap
) => {
  let response = [];

  Object.keys(data).map((k, i) => {
    let title = getTitle(k, structure, null, anchor);

    let summary = overall(data[k]);
    let summary_last = overall(last[k]);
    let ranking = get_ranking(ranked[k]);
    let last_ranking = get_ranking(last_ranked[k]);
    let ranked_diff =
      last_ranking.length === 0
        ? ranking
        : get_ranked_diff(ranking, last_ranking);

    let summary_diff = [
      summary[0] - summary_last[0],
      summary[1] - summary_last[1],
    ];

    let breakdown = [];
    Object.keys(data[k]).map((sub) => {
      let subTitle = structure.categories[1].options[sub];
      let average = average_cat(data[k], sub);
      breakdown.push({ title: subTitle, average });
    });
    let raw = generate_raw(data[k], structure, anchor);
    let stdev = [
      getStandardDeviation(raw.map((i) => i.x)),
      getStandardDeviation(raw.map((i) => i.y)),
    ];

    let color = getColor(k, structure, anchor);
    let _id = anchor;
    let navigation = { option: k, id: _id, subfactor: null };
    let last_n = get_last_n(last[k]);

    response.push({
      title,
      summary,
      breakdown,
      raw,
      stdev,
      color,
      ranking: ranked_diff,
      navigation,
      summary_last,
      last_ranking,
      summary_diff,
      last_n,
    });
  });

  return response;
};

const getColor = (idx, structure, anchor) => {
  if (anchor === -1) {
    if (idx === "Non-BIPOC") {
      return "#D96176";
    }
    return "#615BF0";
  }
  return structure.categories
    .find((f) => f.id == anchor)
    .options.find((f) => f.id == idx)?.color;
};

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return (
    "" +
    parseInt(result[1], 16) +
    "," +
    parseInt(result[2], 16) +
    "," +
    parseInt(result[3], 16)
  );
}

const getRoleColor = (idx, structure, anchor) => {
  let color = structure?.categories
    ?.find((f) => f.priority === "secondary")
    ?.options?.find((f) => f.id === idx)?.color;

  if (!color) {
    color = structure?.categories
      ?.find((c) => c?.options?.find((o) => o?.id === idx))
      ?.options?.find((option) => option?.id === idx)?.color;
  }

  if (!color) {
    // Default to black if no color is found
    color = "#000000";
  }

  return hexToRgb(color);
};

const generate_raw = (data, structure, anchor) => {
  let raw = [];
  Object.keys(data).map((item, i) => {
    data[item].map((coord) => {
      let color = getRoleColor(item, structure, anchor);
      raw.push({
        x: coord[0],
        y: coord[1],
        color: "rgba(" + color + ",0.3)",
      });
    });
  });

  // if (raw.length > 150) {
  //   raw = raw.filter((f) => randomIntFromInterval(1, 20) < 2);
  // }

  return raw;
};

const get_last_n = (data) => {
  let total = 0;
  Object.keys(data).map((item) => {
    total += data[item].length;
  });

  return total;
};

function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}

export const average_cat = (data, cat) => {
  let average = [0, 0];
  let n = 0;

  data[cat].map((item) => {
    average[0] += item[0];
    average[1] += item[1];
    n += 1;
  });

  return [average[0] / n, average[1] / n];
};

export const calculate_data = (
  data,
  filterArray,
  structure,
  anchor,
  rule,
  questions,
  showPrimary = false
) => {
  let primary = [];
  let subfactor = [];
  let filters = {};

  remove_NA_data(data);
  //find the primary nav structure

  //Filter the responses from the most recent data based on the navigation filters

  // let filter_data = filter_data_nav(data[0]?.responses, filters, structure);
  let filter_data = filterData(data[0]?.responses, filterArray);
  //Do the same filtering with the historical data if any
  let filter_last_data = filterData(data[0]?.last, filterArray);

  let _data = {};

  let ranked = {};

  let _last = {};

  let last_ranked = {};
  let lastPrimaryDataMap = {};
  let primaryDataMap = {};

  // if a subfactor is present, then return the data for that or those subfactors
  // if (subfactor.length > 0 && filter_data.length > 0) {
  //   let filtered_data = filter_data.filter((f) => {
  //     return subfactor
  //       .map((i) => i.sub)
  //       .includes(f.categories.find((f) => f.priority == "primary").subfactor);
  //   });

  //   let filtered_last = filter_last_data.filter((f) => {
  //     return subfactor
  //       .map((i) => i.sub)
  //       .includes(f.categories.find((f) => f.priority == "primary").subfactor);
  //   });

  //   let anchor_priority = structure.categories.find(
  //     (f) => f.id == anchor
  //   ).priority;

  //   if (anchor && anchor_priority != "primary") {
  //     [_data, ranked, _last, last_ranked, lastPrimaryDataMap, primaryDataMap] =
  //       get_anchor_data(
  //         structure,
  //         anchor,
  //         filtered_data,
  //         rule,
  //         questions,
  //         filtered_last
  //       );

  //     return structure_data(
  //       _data,
  //       ranked,
  //       structure,
  //       anchor,
  //       _last,
  //       last_ranked,
  //       primaryDataMap
  //     );
  //   } else {
  //     subfactor.map((item, i) => {
  //       _data[i] = {
  //         sub: item.sub,
  //         primary: item.primary,
  //         data: {},
  //       };
  //       ranked[i] = [];

  //       _last[i] = {
  //         sub: item.sub,
  //         primary: item.primary,
  //         data: {},
  //       };
  //       last_ranked[i] = [];

  //       filtered_data
  //         .filter(
  //           (f) =>
  //             f.categories[0].subfactor == item.sub &&
  //             f.categories[0].response == item.primary
  //         )
  //         .map((r) => {
  //           let summary = condensed(r, questions);

  //           let secondary = r.categories[1].response;
  //           if (!(secondary in _data[i].data)) {
  //             _data[i].data[secondary] = [];
  //           }
  //           let x = [0, 0];
  //           let y = [0, 0];

  //           r.questions.map((d) => {
  //             let resp = check_reverse_score(d, questions);
  //             if (d.id == 0) {
  //               x[0] += resp;
  //               x[1] += 1;
  //             } else {
  //               y[0] += resp;
  //               y[1] += 1;
  //             }
  //           });

  //           ranked[i].push(summary);

  //           _data[i].data[secondary].push([x[0] / x[1], y[0] / y[1]]);
  //         });

  //       filtered_last
  //         .filter(
  //           (f) =>
  //             f.categories[0].subfactor == item.sub &&
  //             f.categories[0].response == item.primary
  //         )
  //         .map((r) => {
  //           let summary = condensed(r, questions);

  //           let secondary = r.categories[1].response;
  //           if (!(secondary in _last[i].data)) {
  //             _last[i].data[secondary] = [];
  //           }
  //           let x = [0, 0];
  //           let y = [0, 0];

  //           r.questions.map((d) => {
  //             let resp = check_reverse_score(d, questions);
  //             if (d.id == 0) {
  //               x[0] += resp;
  //               x[1] += 1;
  //             } else {
  //               y[0] += resp;
  //               y[1] += 1;
  //             }
  //           });

  //           last_ranked[i].push(summary);

  //           _last[i].data[secondary].push([x[0] / x[1], y[0] / y[1]]);
  //         });
  //     });
  //   }

  //   return structure_sub_data(
  //     _data,
  //     nav,
  //     ranked,
  //     structure,
  //     _last,
  //     last_ranked
  //   );
  // }

  // if no subfactor is present, then calculate the data for the primary factor
  //start by filtering the data to only the primary factors selected

  let filtered_data = filter_data;
  // filter_primary(filter_data, primary);
  let filtered_last_data = filter_last_data;
  // filter_primary(filter_last_data, primary);

  //bucket data by primary factor or by data anchor
  if (anchor) {
    [_data, ranked, _last, last_ranked] = get_anchor_data(
      structure,
      anchor,
      filtered_data,
      rule,
      questions,
      filtered_last_data,
      showPrimary
    );
  } else {
    primary.map((item, i) => {
      _data[item] = {};

      ranked[item] = [];
      filtered_data
        .filter((f) => f.categories[0].response == item)
        .map((r) => {
          let summary = condensed(r, questions);
          let secondary = r.categories[1].response;
          let sport = r.categories[0].subfactor;
          if (!(secondary in _data[item])) {
            _data[item][secondary] = [];
          }
          let x = [0, 0];
          let y = [0, 0];

          r.questions.map((d) => {
            if (d.id == 0) {
              x[0] += d.response;
              x[1] += 1;
            } else {
              y[0] += d.response;
              y[1] += 1;
            }
          });

          ranked[item].push(summary);

          _data[item][secondary].push([x[0] / x[1], y[0] / y[1]]);
        });
    });
  }

  return structure_data(_data, ranked, structure, anchor, _last, last_ranked);
};

const filter_primary = (data, primary) => {
  return data?.filter((f) => {
    return primary.includes(
      f.categories.find((d) => d.priority == "primary").response
    );
  });
};

export const retrievePillarData = (
  pillar,
  structure,
  data,
  filterId,
  secondaryData
) => {
  // Using the value of pillar, find the dimension id and factor id using the structure object
  let dimensionId = structure.dimensions.find((dimension) =>
    dimension.factors.find((f) => f.title === pillar)
  )?.id;
  if (!dimensionId) {
    dimensionId = structure.dimensions.find((dimension) =>
      dimension.factors?.find((f) => pillar.includes(f.title))
    )?.id;
  }

  let factorId = structure.dimensions
    .find((dimension) => dimension.factors.find((f) => f.title === pillar))
    ?.factors.find((f) => f.title === pillar)?.id;

  if (!factorId) {
    factorId = structure.dimensions
      .find((dimension) => {
        return dimension?.factors?.find((f) => pillar.includes(f.title));
      })
      ?.factors.find((f) => pillar.includes(f.title)).id;
  }

  // Find all the responses for the primaryId
  const filteredPrimary = data?.responses?.filter(
    (d) => d.label.id === filterId
  );

  const factorDataFiltered = filteredPrimary?.filter(
    (d) => Number(d.section) === factorId - 1 && d.dimension === dimensionId - 1
  );

  // Filter the data to only include the dimension and factor we are interested in
  // const filteredData = data.filter(
  //   (d) =>
  //     Number(d.dimension) === dimensionId - 1 &&
  //     Number(d.factor) === factorId - 1
  // );

  // const filteredSecondary = secondaryData?.summary.filter(
  //   (d) =>
  //     Number(d.dimension) === dimensionId - 1 &&
  //     Number(d.factor) === factorId - 1
  // );
  // Find the responses corresponding to the filterId
  // const secondaryDataFiltered = secondaryData?.find(
  //   (d) => d.primary === filterId
  // );
  // Filter those responses to only include the dimension and factor we are interested in
  if (secondaryData?.responses) {
      const secondaryResponsesFiltered = secondaryData.responses.filter(
    (d) => Number(d.section) === factorId - 1 && d.dimension === dimensionId - 1
  );
   // Get a list of unique roles from the secondaryResponsesFiltered
   const roles = secondaryResponsesFiltered.map((d) => d.label.id);
   // remove any duplicate id's from roles
   const uniqueRoles = [...new Set(roles)];
 
   // Find the average for each role
   const secondaryAverages = uniqueRoles.map((role) => {
     const roleResponses = secondaryResponsesFiltered.filter(
       (d) => d.label.id === role
     );
     const n = roleResponses.reduce((acc, curr) => acc + curr.n, 0);
     const average =
       roleResponses.reduce((acc, curr) => acc + curr.average * curr.n, 0) / n;
     return {
       role,
       average,
       n,
     };
   });
   let n = 0;
   const factorAvg =
     factorDataFiltered.reduce((acc, curr) => {
       n += curr.n;
       return acc + curr.average * curr.n;
     }, 0) / n;
 
   // Find the lowest average and role for it
   const lowestAverage = secondaryAverages.reduce((acc, curr) => {
     if (curr.average < acc.average) {
       return curr;
     }
     return acc;
   }, secondaryAverages[0]);
   
   return {
    lowestAverage,
    primaryAvg: factorAvg,
    factorLabel: pillar,
  };

  }

};

export const getPrimaryId = (name, structure) => {
  const primary = structure[0].categories
    ?.find((category) => category?.priority === "primary")
    .options.find((op) => op.name === name);
  return primary?.id;
};

// Get secondary id from structure[0].categories
export const getSecondaryId = (structure) => {
  return structure.categories.find(
    (category) => category.priority === "secondary"
  ).id;
};

// Create an array of options for the category location.
// this returns a "filterArray" containing all of the options.
export const getPrimaryOptions = (structure) => {
  const primary = structure[0].categories.find(
    (category) => category.priority === "primary"
  );
  return primary.options.map((option) => {
    return { uid: primary.id, optionUID: option.id };
  });
};

// retrieve the option name from structure[0].categories using the secondary id
export const getOptionName = (id, structure) => {
  const option = structure[0].categories
    .find((category) => category.options.find((option) => option.id === id))
    .options.find((option) => option.id === id);
  return option.name;
};

export const get_last_responses = (responses) => {
  // Initialize a tracker object to keep track of the two most recent dates for each name
  let tracker = {};

  // First, filter the responses to include only those from the last two occurrences for each name
  return (
    responses
      .filter((i) => {
        // If the name is not already in the tracker, add it with the current date
        if (!(i.name in tracker)) {
          tracker[i.name] = [i.date];
        }
        // If the name is in the tracker with only one date, add the current date
        else if (tracker[i.name].length < 2) {
          tracker[i.name].push(i.date);
        }
        // If the name is in the tracker with two dates and the current date is more recent than the first date,
        // update the tracker to hold the second date and the current date
        else if (i.date > tracker[i.name][0]) {
          tracker[i.name] = [tracker[i.name][1], i.date];
        }

        // Include this item in the output if its date is one of the two most recent for its name
        return tracker[i.name].includes(i.date);
      })
      // For each item in the filtered array, map it to its 'responses' array
      .map((i) => i.responses)
      // Flatten the resulting array of arrays into a single array
      .flat()
      // Map each item in the flattened array to its 'response' property
      .map((i) => i.response)
      // Filter the final array to remove any items that are arrays themselves
      .filter((f) => !Array.isArray(f))
  );
};

export const get_last_average = (last_data, structure, dimension, factor) => {
  //Filter questions based on the selected pillar first
  let questions;
  if (dimension && factor) {
    questions = last_data
      .map((item) =>
        item.questions.filter((f) => f.id === dimension && f.factor == factor)
      )
      .flat()
      .map((item) => check_reverse_score(item, structure));
  } else {
    questions = last_data
      .map((item) => item.questions)
      .flat()
      .map((item) => check_reverse_score(item, structure));
  }
  return average(questions);
};

export const getLastData = (data, filterArray, structure) => {
  let primary = [];
  let subfactor = [];
  let filters = {};

  let filter_data_last = data[0]?.last
    ?.filter((x) => x.questions.length > 0)
    .filter((x) => {
      let checked = true;
      if (x?.categories) {
        try {
          x.categories
            .filter((d) => d.priority != "primary")
            .map((item, i) => {
              let all_selected = check_all_selected(structure, item, filters);
              if (
                (item.response !== null && item.response !== "") ||
                !all_selected
              ) {
                checked = checked
                  ? filters[item.id].includes(item.response)
                  : false;
              }
            });
        } catch (err) {
          checked = false;
        }

        return checked;
      }
    });

  if (subfactor.length > 0) {
    filter_data_last = filter_data_last?.filter((f) => {
      return subfactor
        .map((i) => i.sub)
        .includes(f.categories.find((f) => f.priority == "primary").subfactor);
    });
  } else {
    filter_data_last = filter_data_last?.filter((f) => {
      return primary.includes(
        f.categories.find((f) => f.priority == "primary").response
      );
    });
  }

  return filter_data_last;
};

export const getSortOrders = (debriefObj, surveys) => {
  // Using debriefs.debrief_schedule.results create an array of unique brief_question
  const briefQuestions = debriefObj.debrief_schedule.results.map((debrief) => {
    return debrief.brief_question;
  });

  // Remove the duplicates from the array
  const uniqueBriefQuestions = [...new Set(briefQuestions)];

  // Map the unique brief_question ids to the survey ids, and return the sort_order
  const sortOrders = uniqueBriefQuestions.map((briefQuestion) => {
    return surveys.find((survey) => {
      return survey.id === briefQuestion;
    })?.sort_order;
  });

  return sortOrders.filter(f=>f);
};

export const restructure_Questions = (questions) => {
  const questionsCopy = JSON.parse(JSON.stringify(questions));
  let d1 = {
    id: 1,
    title: "DEI Factors",
    factors: questionsCopy?.questions?.factors,
  };
  questionsCopy.questions.dimensions = [d1];

  return questionsCopy;
};

export const checkPersonality = (org) => {
  return org.organization.services_enabled.find((f) => f.id === 21)?.enabled;
};

export const convert_personality_to_categories = (resp) => {
  resp.map((item) => {
    item.responses.map((r) => {
      if (Object.keys(r.response.survey_personality).length > 0) {
        let personality = calculate_personality_categories(
          r.response.survey_personality
        );
        r.response.categories.push(...personality);
      }

      return r;
    });
  });

  return resp;
};

export const calculate_personality_categories = (data) => {
  let pers = personality_norms.map((item, i) => calculate_personality(data, i));
  return pers.map((item, i) => {
    let response = item < 33 ? "0" : item < 67 ? "1" : "2";
    return { id: personality_names[i], priority: "personality", response };
  });
};

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const personality_names = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Neuroticism",
  "Openess",
];

const personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

export const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

export const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

export const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};

export const convert_DEI = (resp) => {
  resp.map((item) => {
    item.responses = item.responses.map((r) => {
      r.response.questions = r.response.questions.map((q) => {
        q.id = 0;
        return q;
      });
      return r;
    });
    return item;
  });
  return resp;
};

export const check_comments = (resp, get_employee) => {
  let comm = false;
  if (get_employee) {
    let user_info = get_employee?.userEmp;

    if (
      !user_info ||
      (!user_info.read_feedback && user_info.account_type_text != "Admin")
    ) {
      return false;
    }

    // if(questionStructure){
    //   if(!("comments" in questionStructure)){
    //     return false
    //   }
    //   else if(questionStructure.comments.length<1){
    //     return false
    //   }
    // b2979f0d-89d9-48e7-837b-d0ebff6f6d41
    // }

    for (var i = 0; i < resp.responses.length; i++) {
      if ("comments" in resp.responses[i] || "feedback" in resp.responses[i]) {
        comm = true;
        break;
      }
    }
  }

  return comm;
};
