import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { Modal, Grid, Header, Form } from "semantic-ui-react";


import Select from "react-select";

import { SG_EDIT_FILE_UPLOAD, SG_VALIDATE_EMAIL_LIST } from "constants/actions";

const Update = ({
    rowInfo,
    setRowInfo,
    rowId,
    id,
    ShowPortal,
    openPortal,
    jsonData,
    setJsonData,
    fieldValues,
    validFields,
    validFieldName,
}) => {
    const dispatch = useDispatch();

    // const [selectionList, setSelectionList] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    function UpdateRow() {
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: id,
                row_info: rowInfo,
                row_id: rowId,
            },
        });


        ShowPortal(false);
    }

    function ChangeRow(key, value) {
        setIsLoading(key)
        const _rowData = rowInfo;
        _rowData[key] = value;
        // console.log(e.target.value)
        // console.log(key)
        // console.log(value)

        if (key === 'email') {
            dispatch({
                type: SG_VALIDATE_EMAIL_LIST,
                payload: `email=${value}`
            });
        }

        const _full_data = jsonData;
        _full_data[rowId] = _rowData;
        setRowInfo(_rowData);

        // console.log(_rowData)
        // console.log(_full_data)
        dispatch({
            type: SG_EDIT_FILE_UPLOAD,
            payload: {
                id: id,
                row_info: rowInfo,
                row_id: rowId,
            },
        });

        setJsonData(_full_data);
    }

    function ChangeSelectedValue(key, e) {
        ChangeRow(key, e.name);
        // console.log("changed", key)
        // if (controlledBy[key])
        // {
        //     // console.log("Controlled Key Found", e.name, controlledBy[key])
        //     // console.log(rowInfo[controlledBy[key]])
        //     ChangeRow(controlledBy[key], null);
        // }
        // console.log(key, e.name)
        // console.log(rowInfo[key])
    }

    useEffect(() => {
        if (!isLoading === false) {
            const timer1 = setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            return () => clearTimeout(timer1);
        }

    }, [rowInfo, dispatch, isLoading]);

    function SelectedLists({ variable, optionList, value, isPrimary=false }) {
        let SelectionListValues = []
        const SetValue = optionList.filter(op => op.name === value)[0]
        if (isPrimary) {
            SelectionListValues = fieldValues[variable]
        } else {
            SelectionListValues =  optionList
        }
        

        return <Select
            placeholder={`Select ${variable}`}
            onChange={(e) => ChangeSelectedValue(variable, e)}
            value={SetValue}
            options={SelectionListValues}
            isLoading={isLoading === variable ? isLoading : false}
            getOptionLabel={(SelectionListValues) => SelectionListValues?.name}
            getOptionValue={(SelectionListValues) => SelectionListValues?.name}
        />

    }

    return (
        <Grid centered columns={2}>
            <Grid.Column>
                <Modal
                    open={openPortal}
                    onClose={() => ShowPortal(false)}
                >
                    <Modal.Header>Update Row</Modal.Header>
                    <Modal.Content>

                    
                    <Form>
                        {validFields && validFields.map((item, i) => {
                            // console.log(rowData.filter(rd => rd[0] === item))
                            const RowValue = rowInfo[item]
                            if (validFieldName[item]) {
                                if (validFieldName[item]?.priority === 'primary') {
                                    if (fieldValues?.[item]) {
                                        return (<Form.Field key={i}>
                                            <label>{item}</label>
                                            <SelectedLists
                                                variable={item}
                                                optionList={fieldValues?.[item]}
                                                value={RowValue}
                                                isPrimary={true}
                                            />
                                        </Form.Field>
                                        )
                                    }
                                } else  {
                                    if (validFieldName[item]?.visibility === 'public') {
                                        return (<Form.Field key={i}>
                                            <label>{item}</label>
                                            <SelectedLists
                                                variable={item}
                                                optionList={validFieldName[item]?.options}
                                                value={RowValue}
                                                isPrimary={false}
                                            />
                                        </Form.Field>
                                        )
                                    }
                                }
                            } 
                            // SelectedLists
                            return (
                                <Form.Field key={i}>
                                    <label>{item}</label>
                                    <input
                                        name={item}
                                        onChange={(e) =>
                                            ChangeRow(
                                                item,
                                                e.target.value
                                            )
                                        }
                                        defaultValue={RowValue}
                                    />
                                </Form.Field>
                            )
                        })}
                    </Form>

                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 20,
                        }}
                    >
                        <StyledButtonGrey
                            onClick={() => ShowPortal(false)}
                        >
                            Cancel
                        </StyledButtonGrey>
                        <StyledButton onClick={UpdateRow}>
                            Update
                        </StyledButton>
                    </div>
                    </Modal.Content>
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default Update;

const Label = styled.div`
    margin-bottom: 10px;
    font-weight: bold;
`;

const FormContainer = styled.div`
    max-width: 300px;
    margin-top: 20px;
`;

const FormContainerTop = styled.div`
    max-width: 300px;
    margin-top: 0px;
`;

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2d50e2;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-left: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;

const StyledButtonGrey = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #595959;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #595959;
        color: #595959;
    }
`;
