function getStandardDeviation(array) {
  const n = array.length;
  if (n == 0) {
    return 0;
  }
  const mean = array.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
}

const checkSubfactor = (anchor, categories) => {
  let primary = categories.categories.find((f) => f.priority == "primary");
  if ("subfactors" in primary.options[0]) {
    let subfactors = [];
    primary.options.map((item) => {
      item.subfactors.map((s) => subfactors.push(s.id));
    });
    console.log(subfactors, anchor, categories);
    return subfactors.includes(anchor);
  }

  return false;
};

export const effect_size_calculation = (
  categories,
  data,
  factor,
  question,
  role,
  anchor
) => {
  if (Object.keys(factor).length < 4) {
    return [];
  }

  //Determine tertiary category structure
  let tertiary = categories.categories.filter((f) => f.priority == "tertiary");
  let tertiary_ids = tertiary.map((item) => item.id);
  if (tertiary.length > 0) {
    let responses = data.filter_data[0]
      .filter((f) => {
        if (checkSubfactor(role, categories)) {
          return f.categories.find((f) => f.id == anchor)?.subfactor == role;
        }
        return f.categories.find((f) => f.id == anchor)?.response == role;
      })
      .map((i) => {
        return {
          response: i.questions.filter(
            (f) =>
              f.id == factor.dimension &&
              f.factor == factor.factor &&
              f.q == question
          )[0]?.response,
          categories: i.categories.filter((f) => tertiary_ids.includes(f.id)),
        };
      });

    let ES = tertiary.map((category) => {
      let idx = category.id;
      let standard_deviation = getStandardDeviation(
        responses.map((i) => i.response)
      );
      return {
        name: category.name,
        ES: category.options.map((option, i) => {
          let total1 = 0;
          let count1 = 0;

          responses
            .filter(
              (f) =>
                f.categories.filter((d) => d.id == idx)[0]?.response ==
                option.id
            )
            .map((item) => {
              total1 += item.response;
              count1 += 1;
            });

          let total2 = 0;
          let count2 = 0;
          responses
            .filter(
              (f) =>
                f.categories.filter((d) => d.id == idx)[0]?.response !=
                option.id
            )
            .map((item) => {
              total2 += item.response;
              count2 += 1;
            });

          if (count1 == 0 || count2 == 0 || standard_deviation == 0) {
            return 0;
          }

          return (total1 / count1 - total2 / count2) / standard_deviation;
        }),
      };
    });

    return ES;
  }
};
