import React, { useState } from 'react';
import styled from 'styled-components';

// ... same styled-components ...
const QuestionInput = styled.input`
    margin: 8px;
`;

function QuestionEditor({ question, onChange }) {
    return (
        <div>
            <label>
                Question:
                <QuestionInput
                    value={question.q}
                    onChange={e => onChange({ ...question, q: e.target.value })}
                />
            </label>
        </div>
    );
}

function FactorEditor({ factor, onChange }) {
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const handleQuestionChange = (index, updatedQuestion) => {
        const newQuestions = [...factor.questions];
        newQuestions[index] = updatedQuestion;
        onChange({ ...factor, questions: newQuestions });
    };

    const handleEditClick = (question) => {
        setSelectedQuestion(question);
    };

    const closeQuestionModal = () => {
        setSelectedQuestion(null);
    };

    const saveQuestionModal = (updatedQuestion) => {
        const index = factor.questions.findIndex(q => q.id === updatedQuestion.id);
        handleQuestionChange(index, updatedQuestion);
    };

    return (
        <div>
            <h3>{factor.title}</h3>
            <ul>
                {factor.questions.map((question, index) => (
                    <li key={question.id}>
                        {question.q}
                        <button onClick={() => handleEditClick(question)}>Edit</button>
                    </li>
                ))}
            </ul>
            
            {selectedQuestion && (
                <QuestionModal
                    question={selectedQuestion}
                    onSave={saveQuestionModal}
                    onClose={closeQuestionModal}
                />
            )}
        </div>
    );
}
function DimensionEditor({ dimension, onChange }) {
    const handleFactorChange = (index, updatedFactor) => {
        const newFactors = [...dimension.factors];
        newFactors[index] = updatedFactor;
        onChange({ ...dimension, factors: newFactors });
    };

    const addFactor = () => {
        const newFactor = { id: Date.now(), title: '', questions: [] }; // simplified id for demo
        onChange({ ...dimension, factors: [...dimension.factors, newFactor] });
    };

    return (
        <div>
            <label>
                Dimension Title:
                <input
                    value={dimension.title}
                    onChange={e => onChange({ ...dimension, title: e.target.value })}
                />
            </label>
            {dimension.factors.map((f, index) => (
                <FactorEditor key={f.id} factor={f} onChange={f => handleFactorChange(index, f)} />
            ))}
            <button onClick={addFactor}>Add Factor</button>
        </div>
    );
}

export default function JsonEditor() {
    const [jsonData, setJsonData] = useState(data);

    const handleDimensionChange = (index, updatedDimension) => {
        const newDimensions = [...jsonData.dimensions];
        newDimensions[index] = updatedDimension;
        setJsonData({ ...jsonData, dimensions: newDimensions });
    };

    const addDimension = () => {
        const newDimension = { id: Date.now(), title: '', factors: [] };  // simplified id for demo
        setJsonData({ ...jsonData, dimensions: [...jsonData.dimensions, newDimension] });
    };

    return (
        <div style={{marginLeft:60,marginTop:40}}>
            {jsonData.dimensions.map((d, index) => (
                <DimensionEditor key={d.id} dimension={d} onChange={d => handleDimensionChange(index, d)} />
            ))}
            <button onClick={addDimension}>Add Dimension</button>
        </div>
    );
}


const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContainer = styled.div`
    width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
`;

function QuestionModal({ question, onSave, onClose }) {
    const [localQuestion, setLocalQuestion] = useState(question);

    const handleInputChange = (key, value) => {
        setLocalQuestion({
            ...localQuestion,
            [key]: value,
        });
    };

    const handleSave = () => {
        onSave(localQuestion);
        onClose();
    };

    return (
        <ModalOverlay>
            <ModalContainer>
                {Object.keys(question).map(key => (
                    <div key={key}>
                        <label>
                            {key}:
                            <input
                                type="text"
                                value={localQuestion[key]}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                            />
                        </label>
                    </div>
                ))}
                <button onClick={handleSave}>Save</button>
                <button onClick={onClose}>Close</button>
            </ModalContainer>
        </ModalOverlay>
    );
}



const data ={
    type: 4,
    title: 'Georgia Tech Culture Index',
    comments: [],
    language: false,
    type_name: 'Innerlogic',
    dimensions: [
      {
        id: 1,
        title: 'People',
        factors: [
          {
            id: 1,
            title: 'Facilities & Services',
            questions: [
              {
                q: 'Emphasizes the importance of following GTAA, ACC, and NCAA rules.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 9,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Head Coach...'
              },
              {
                q: 'Supports my academic goals and efforts.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 10,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Head Coach...'
              },
              {
                q: 'Sensitive to the demands placed on my time as a student and athlete.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 11,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Head Coach...'
              },
              {
                q: 'Acts fairly with all members of the team, regardless of race, sex, gender identity, gender expression, sexual orientation or other cultural identities.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 12,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Head Coach...'
              },
              {
                q: 'Creates a supportive environment for mental and emotional health.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 13,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Head Coach...'
              },
              {
                q: 'Creates a supportive environment for mental and emotional health.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 14,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Assistant Coach...'
              },
              {
                q: 'Emphasizes the importance of following GTAA, ACC, and NCAA rules.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 15,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Assistant Coach...'
              },
              {
                q: 'Supports my academic goals and efforts.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 16,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Assistant Coach...'
              },
              {
                q: 'Sensitive to the demands placed on my time as a student and athlete.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 17,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Assistant Coach...'
              },
              {
                q: 'Acts fairly with all members of the team, regardless of race, sex, gender identity, gender expression, sexual orientation or other cultural identities..',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 18,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Assistant Coach...'
              },
              {
                q: 'Creates a supportive environment for mental and emotional health.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 19,
                role: null,
                reverse: false,
                qualifier: 'Please rate your level of agreement with the following statements about your Assistant Coach...'
              }
            ]
          },
          {
            id: 2,
            title: 'Academics',
            questions: [
              {
                q: 'I am respected by my peers in the classroom because I am a student-athlete.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 20,
                role: null,
                reverse: false,
                qualifier: 'Campus Academic Climate'
              },
              {
                q: 'Identifying myself to professors, as a student-athlete, I am respected.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 21,
                role: null,
                reverse: false,
                qualifier: 'Campus Academic Climate'
              },
              {
                q: 'Professors provide assistance with my absences due to athletic competition.',
                fr: 'Il n\'y a pas de danger à identifier les problèmes et à exprimer des préoccupations sincères. ',
                id: 22,
                role: null,
                reverse: false,
                qualifier: 'Campus Academic Climate'
              }
            ]
          }
        ]
      },
      {
        id: 2,
        title: 'Performance',
        factors: [
          {
            id: 1,
            title: 'Facilities & Services',
            questions: [
              {
                q: 'Competition Facilities.',
                fr: 'Les attentes en matière de performances sont clairement définies et comprises.',
                id: 1,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Practice Facilities.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 2,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Team Locker Room.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 3,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Athletic Training Room.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 4,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Weight Room.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 5,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Nutrition Room.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 6,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Champions Edge Dining.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 7,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              },
              {
                q: 'Study Hall/Tutoring Space.',
                fr: 'La meilleure façon d\'atteindre les objectifs est clairement définie.',
                id: 8,
                role: null,
                scale: [
                  'Very Dissatisfied',
                  'Very Satisfied'
                ],
                reverse: false,
                qualifier: 'Please rate your level of satisfaction with...'
              }
            ]
          }
        ]
      }
    ],
    question_titles: true
  }
