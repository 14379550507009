import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import Chart from "react-apexcharts";
import { Icon } from 'semantic-ui-react'


const SummarySquare = (props) =>{


	return (
		<Container>
			<Change color={props.change>0?'green':'red'}>
				<Icon name="arrow up" />
				{props.change}%
			</Change>

			<Chart options={data2.options} series={data2.series} type="line" height={100} />

			<Value>{props.value}</Value>
			<Sub>{props.title}</Sub>
		</Container>
		)
}


export default SummarySquare



const Container = styled.div`
	width:30%;
	font-family: 'Barlow', sans-serif;
	border:1px solid lightgrey;
	padding:20px;
`

const Change = styled.div`
	display:flex;
	color:${props=>props.color};
`

const Value = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:30px;
`
const Sub = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	color:grey;
	margin-top:10px;
	margin-bottom:20px;
`


const data2 = {
	series: [

              {
                name: "Revenue",
                data: [100,110,120,130,170,250,375]
              },
            ],
            options: {
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: true,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left'
              },
              colors:['#3260a8'],
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               stroke: {
          width: [5, 2],
          curve: 'smooth',
          dashArray: [0, 8]
        },  
              yaxis: {
              	show:false,
                title: {
                  text: 'Culture'
                },
                 decimalsInFloat: 0,
              }
              
            }
}
