import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Popup } from "semantic-ui-react";

import {
  caretDown,
  caretUp,
  viewIcon,
} from "../../../reports/components/Icons";
import Empty from "./Empty";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_GET_TASK_REPORTS, CLEAR_TASK_TEMPLATE } from "constants/actions";
import { useToasts } from "react-toast-notifications";
import ReportFilters from "./ReportFilters";
import { useNavigate } from "react-router-dom";

const statusCodes = [
  { code: 1, label: "New/Retry", container: "#FFFAF1", text: "#FEA711" },
  { code: 2, label: "In Progress", container: "#F3F7FF", text: "#2D70E2" },
  { code: 3, label: "Timed Out", container: "#FEF3F4", text: "#F15062" },
  { code: 4, label: "Error", container: "#FEF3F4", text: "#F15062" },
  { code: 5, label: "Partial Response", container: "#F3F7FF", text: "#2D70E2" },
  { code: 7, label: "Completed", container: "#F1FAF9", text: "#00B67A" },
  { code: 9, label: "Email Sent", container: "#F1FAF9", text: "#00B67A" },
  { code: 11, label: "Archived", container: "#F3F7FF", text: "#2D70E2" },
  { code: 99, label: "For Deletion", container: "#FEF3F4", text: "#F15062" },
];

const Status = ({ id }) => {
  const status = statusCodes?.find((status) => status?.code === id);

  return (
    <StatusContainer color={status?.container}>
      <StatusText color={status?.text}>{status?.label}</StatusText>
    </StatusContainer>
  );
};

const getReportsList = (data) => {
  return data
    .filter((item) => !Array.isArray(item?.report_data))
    .map((item, id) => {
      const reports = item.report_data?.response?.map((report) => {
        const tableLines = report.table.split("\n");
        const topicLineIndex =
          tableLines.findIndex((line) =>
            line.startsWith("|--------|-------|")
          ) + 1;

        const title = tableLines.length ==1? '' :tableLines[topicLineIndex].split("|")[2].trim();
        return {
          id: id,
          topic: title,
          data: {
            id: report.id,
            table: report.table,
          },
        };
      });

      return {
        title: `Reports generated: ${new Date(
          item.created_at
        ).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}`,
        status: item.status_text,
        reports: reports,
      };
    });
};

const ReportsTab = ({ id, responses, reports }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const { addToast } = useToasts();
  const [reportsList, setReportsList] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    if (reports) {
      const parseReportsList = getReportsList(reports);
      setReportsList(parseReportsList);
    }
  }, [reports]);

  const { reportCreated } = useSelector(
    (state) => ({
      reportCreated: state.task_templates?.reportCreated,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (reportCreated) {
      addToast("Report created successfully", {
        appearance: "success",
        autoDismiss: true,
      });

      // Clear the report created state
      dispatch({
        type: CLEAR_TASK_TEMPLATE,
      });
    }
  }, [reportCreated]);

  const toggleRow = (id) => {
    const isRowCurrentlyExpanded = expandedRows.includes(id);
    const newExpandedRows = isRowCurrentlyExpanded
      ? expandedRows.filter((rowId) => rowId !== id)
      : [...expandedRows, id];
    setExpandedRows(newExpandedRows);
  };

  return (
    <Container>
      <Header>
        <Title>Reports</Title>
        <ReportFilters id={id} responses={responses} />
      </Header>
      {(reportsList?.length > 0 && (
        <Table>
          <TableHeader>
            <TH width={75}>Title</TH>
            <TH width={15}>Status</TH>
            <TH width={10}>Actions</TH>
          </TableHeader>
          {reportsList?.map((report, index) => {
            const isExpanded = expandedRows.includes(report.id);
            const status = report?.reports ? 7 : 4;
            return (
              <>
                <TableRow
                  onMouseOver={() => {
                    setHovered(index);
                  }}
                  onMouseOut={() => setHovered(null)}
                  rowIsHovered={hovered === index}
                >
                  <TD width={75}>{report?.title}</TD>
                  <TD width={15}>
                    <Status id={status} />
                  </TD>
                  <TD width={10}>
                    {status === 7 && (
                      <ViewButton
                        onClick={() => {
                          // Navigate to the following: manage/task/report/:id/:reportGroupIndex/:reportIndex
                          navigate(
                            `/leader/audit/manage/task/report/${id}/${index}`
                          );
                        }}
                      >
                        {viewIcon()}
                      </ViewButton>
                    )}
                  </TD>
                </TableRow>
              </>
            );
          })}
        </Table>
      )) || <Empty />}
    </Container>
  );
};

export default ReportsTab;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Table = styled.div`
  width: 100%;
  border-collapse: collapse;
`;

const ViewButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TableRow = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.color};
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 5px 10px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 0 5px;
`;

const ExpandedTitle = styled.div`
  font-size: 12px;
  width: 82.5%;
  color: #2a3039;
  line-height: 1.5;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  text-overflow: ellipsis;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const ExpandedRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  width: 100%;
`;

const ExpandedRowActionButton = styled.div``;

const ExpandedRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f9f9f9;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: -10px;
`;


const StatusText = styled.div`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
`;
