import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import {Icon} from 'semantic-ui-react';
import ClickAwayListener from "react-click-away-listener";
import {
    SET_NEW_NAVIGATION
  } from "constants/actions";
import { set } from 'date-fns';
  


const Navigation = ({outcomes,core_data}) => {
    const dispatch = useDispatch();
    const navigation = useSelector((state) => state.audit?.new_navigation,shallowEqual)
    const [options1, setOptions1] = useState([])
    const [options2, setOptions2] = useState([])
    const [options3, setOptions3] = useState([])
    const [options4, setOptions4] = useState([])
    const [isOutcome, setIsOutcome] = useState(false)

    const { outcome, category, sub_category, factor,stage } = navigation;

    // Generate the dropdown options based on outcomes and categories
    const generate_outcome_options = () =>{
        let _options = [{name:'All Feedback',id:0},{name:'Culture',id:1}]
        outcomes?.questions.map((question)=>{
            _options.push({name:question.name,id:question.id,type:'outcome'})
        }
        )
        return _options
    }

    const generate_category_options = () =>{
        let _options = []
        core_data?.categories?.categories.map((category)=>{
            _options.push({name:category.name,id:category.id,type:'category'})
        })
        return _options
    }

    const generate_category_sub_options = () =>{
        let _options = [{name:'All Factors',id:null,type:'cat_option'}]
        core_data?.categories?.categories.find(f=>f.id === category.id)?.options
        .map((sub_category)=>{
            _options.push({name:sub_category.name,id:sub_category.id,type:'cat_option'})
        })
        return _options
    }

    const generate_factor_options = () =>{
        let _options = [{name:'All Factors',id:null,type:'factor'}]
        core_data?.questions?.dimensions.map((dimension,d)=>{
            dimension.factors.map((factor)=>{
                _options.push({name:factor.title,id:factor.id,type:'factor',dimension:d})
            })
        })
        return _options
    }

   

    useEffect(()=>{
        setOptions1(generate_outcome_options())
        setOptions4(generate_factor_options())
    },[outcomes])

    useEffect(()=>{
        setOptions2(generate_category_options())
    },[core_data])

    useEffect(()=>{
        if(category){
            setOptions3(generate_category_sub_options())
            updateSelection({ ...navigation, factor: null,sub_category:null })
        }
    },[category])


    useEffect(()=>{
        if(!factor && options4.length === 0){
            setOptions4(generate_factor_options())
        }
    },[factor])

    useEffect(()=>{
        if(outcome?.name !== 'Culture'){
            setIsOutcome(true)
            updateSelection({ ...navigation, factor: null,sub_category:null })
        }
        else{
            
            setIsOutcome(false)
        }
    },[outcome])

  


    // Push out the selected dropdown options to the redux store

    // Action creators (assuming these are properly defined elsewhere)
    const updateSelection = (selection) => dispatch({
        type: 'SET_NEW_NAVIGATION',
        payload: selection,
    });



   
    return (
        <Container>

            <Text>Feedback Type</Text>
            <Row>
                <Dropdown options={options1} selected={outcome} setSelected={option => updateSelection({ ...navigation, outcome: option })}/>
                {
                    options4.length > 0 &&
                    !isOutcome &&
                    <>
                        <Slash>/</Slash>
                        <Dropdown options={options4} selected={factor} setSelected={option => updateSelection({ ...navigation, factor: option })}/>
                    </>
                }
            </Row>
            
               
        </Container>
    )
}

export default Navigation


const Dropdown = ({options,selected,setSelected}) =>{

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);


    useEffect(()=>{
        if(!selected){
            setSelected(options[0])
        }
    },[options])

    const selectOption = (option) =>{
        setOpen(false)
        setSelected(option)
    }



    return (
        <ClickAwayListener onClickAway={()=>setOpen(false)}>
            <DropdownContainer onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
                <Nav onClick={()=>setOpen(!open)}>
                    <Selection>{selected?.name}</Selection>
                    <Icon name="triangle down" style={{marginTop:-3,color:'#B3B3B3'}}/>
                </Nav>
                {open && <DropdownContent>
                    {options.map((option,i)=><Option key={i} onClick={()=>selectOption(option)}>{option.name}</Option>)}
                </DropdownContent>}
            </DropdownContainer>
        </ClickAwayListener>
    )
}


const Container = styled.div`
    display:flex;
    font-family:'raleway';
    margin-bottom:20px;
    flex-direction:column;
`

const DropdownContainer = styled.div`
    position:relative;

`

const Nav = styled.div`
    display:flex;
    align-items:center;
    cursor:pointer;
`

const Selection = styled.div`
    font-size:14px;
    color:#2A3039;
    margin-right:5px;
`

const DropdownContent = styled.div`
    position:absolute;
    top:40px;
    left:0;
    background-color:white;
    box-shadow:0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius:5px;
    z-index:100;
    width:200px;
`

const Option = styled.div`
    padding:10px 20px;
    font-size:14px;
    color:#2A3039;
    cursor:pointer;

    &:hover{
        background-color:#F5F5F5;
    }
`

const Slash = styled.div`
    font-size:20px;
    color:#2A3039;
    margin-right:10px;
    color:#DFDFDF;
`

const Text = styled.div`
    font-size:12px;
    color:#2D70E2;
    font-weight:bold;
    margin-bottom:10px;
`

const Row = styled.div`
    display:flex;
`