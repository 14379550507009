import data from "data/live/navigation"


export const generate_chart_standards_data = (data,standards,navigation,categories,data_type,outcomeStandards) => {
    
    const parent_category = navigation?.category?.id
    const category_options = categories?.categories.find(f=>f.id === parent_category)?.options
    let resp = []

    console.log(standards)
    category_options?.map((option)=>{
            let filtered_data = data.filter(f=>f.categories.find(f=>f.id === parent_category)?.response==option.id)
            let overall_average = filtered_data.map(f=>f.questions.map(q=>q.response).flat()).flat()
            let overall_standard = standards.response.overall.average

            if(data_type.type === 'outcome'){
                let outcome = navigation?.outcome?.id
                overall_average = filtered_data.map(f=>f.employee_outcomes?.responses?.filter(f=>f.q===outcome).map(f=>f.response)).flat().filter(f=>f)
                overall_standard = outcomeStandards[data_type.id].overall_average
            }
           

            let name = option.name
            let option_id = option.id
            let average = average_array(overall_average)

            if(average){
                resp.push({
                    average:average,
                    diff:(average - overall_standard)*10,
                    name:name,
                    option_id:option_id
                })
            }

        })
    
    return resp

}


const average_array = (arr) =>{
    return arr.reduce((a,b)=>a+b,0)/arr.length
}

export const generate_chart_factor_standards = (data,standards,navigation,categories,data_type,questions) => {

    const parent_category = navigation?.category?.id
    const sub_category = navigation?.sub_category?.id

    let filtered_data = data.filter(f=>f.categories.find(f=>f.id === parent_category)?.response==sub_category)

    let resp = []
    questions.dimensions.map((dimension,d)=>{
        dimension.factors.map((factor,i)=>{
            let overall_average = filtered_data.map(f=>f.questions.filter(f=>f.id==d&&f.factor==i).map(q=>q.response).flat()).flat()
            let name = factor.title
            let average = average_array(overall_average)

            
            let standard = standards.response.pillars.find(f=>f.id==d && f.factor==i).average
            let diff = (average - standard)*10
            let option_id = factor.id

            if(average){
                resp.push({
                    average:average,
                    diff:diff,
                    name:name,
                    option_id:option_id,
                    dimension:d
                })
            }
        })
    })

    return resp

}

export const process_feedback_data = (data) => {
    let resp = []

    if(!data){
        return resp
    }

    data.map((item,idx)=>{
        item.feedback.map((question,idx)=>{
            let formatting_issue = ![0,1,2,3,4,5,6,7,8,9,10].includes(question.response)
            if(formatting_issue){
                resp.push({
                    question:question.question,
                    score:null,
                    feedback:question?.response,
                    follow_up_question:question?.prompt_question,
                    follow_up_response:question?.prompt_feedback,
                    dimension:question.dimension,
                    factor:question.factor,
                    id:idx,
                    type:'Culture',
                    survey_id:idx
                })
            }
            else{
                resp.push({
                    question:question.question,
                    score:question.response,
                    feedback:question.feedback,
                    follow_up_question:question?.prompt_question,
                    follow_up_response:question?.prompt_feedback,
                    dimension:question.dimension,
                    factor:question.factor,
                    id:idx,
                    type:'Culture',
                    survey_id:idx
                })
            }
            
        })
    })

    return resp.sort((a,b)=>a.score-b.score)

}


export const generate_question_standards = (data,standards,navigation,categories,questions) => {
   const factor = navigation.factor.id -1
   const dimension = navigation.factor.dimension

   const parent_category = navigation?.category?.id
    const sub_category = navigation?.sub_category?.id

    let filtered_data = data.filter(f=>f.categories.find(f=>f.id === parent_category)?.response==sub_category)


   // iterate on the questions object and filter for each question responses to get the average
 
   return questions.dimensions[dimension].factors[factor].questions.map((question,idx)=>{

       let overall_average = filtered_data.map(f=>f.questions.filter(f=>f.id==dimension&&f.factor==factor&&f.q==idx).map(q=>q.response).flat()).flat()
       let name = question.q
       let average = average_array(overall_average)
       let q_id = question?.old_id? question.old_id : idx


        let standard = standards.response.pillars.find(f=>f.factor==factor&&f.id==dimension).overall[q_id]
       
       return {
         average,
         standard,
         name
       }

   })





}