import Trust from 'assets/images/curiosity.svg'
import styled from "styled-components";
import React from 'react';


const IMG = styled.img`
	width:1000px;
`

const Images = () =>{

	return (
		<IMG src={Trust} />
		)
}

export default Images