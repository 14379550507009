import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress, Dropdown } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { build_default_State } from "./data_navigation/calculate_data";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
	SET_SELECTED_QUESTION,
	SET_SUB_NAV,
	SET_SELECTED_FILTERS,
	SET_NAVIGATION,
} from "constants/actions";

const configure = (structure) => {
	let ids = 0;
	let names = [{ title: "Overall Culture", level: 0, id: ids }];
	ids += 1;
	if (structure?.dimensions) {
		structure?.dimensions.map((item, i) => {
			names.push({ title: item.title, level: 1, id: ids, dimension: i });
			ids += 1;
			if (item.factors.length > 0) {
				item.factors.map((x, idx) => {
					names.push({
						title: x.title,
						level: 2,
						id: ids,
						dimension: i,
						factor: idx,
					});
					ids += 1;
				});
			}
		});
	}

	return names;
};

const DataRanking = (props) => {
  const { data, isFrench, questions: incQuestions } = props;
  const dispatch = useDispatch();

  const [filteredData1, setFilteredData1] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);
  const [structure, setStructure] = useState(null);

  const questions = useSelector(
    (state) => state.audit?.question_structure,
    shallowEqual
  );

  const questionSet = incQuestions || questions;

  const categories = useSelector(
    (state) => state.audit?.category_structure,
    shallowEqual
  );

  useEffect(() => {
    setFilteredData1(
      data.ranking
        .filter((f) => f.id === 0)
        .sort((a, b) => b.response - a.response)
    );
    setFilteredData2(
      data.ranking
        .filter((f) => f.id === 1)
        .sort((a, b) => b.response - a.response)
    );
  }, [data]);

  useEffect(() => {
    if (questionSet) {
      if (Object.keys(questionSet).length > 0) {
        // setQ(questions);
        setStructure(configure(questionSet));
      }
    }
  }, [questionSet]);

  const navigate = (select) => {
    if (data) {
      let selected = structure.find(
        (f) => f.dimension == select.id && f.factor == select.factor
      );

      dispatch({
        type: SET_SELECTED_QUESTION,
        payload: selected,
      });
      dispatch({
        type: SET_SUB_NAV,
        payload: 3,
      });

      onSelect({
        id: data.navigation.id,
        option: data.navigation.option,
        subfactor: data.navigation.subfactor,
        name: { name: data.title.name },
      });
    }
  };

  const selected = useSelector((state) => state.audit?.filters, shallowEqual);

  const onSelect = (s) => {
    let check = selected.filter(
      (f) =>
        f.id === s.id && f.subfactor === s.subfactor && f.option === s.option
    );
    let _selected;
    if (check.length > 0) {
      _selected = selected;
    } else {
      _selected = [...selected, s];
    }

    dispatch({
      type: SET_SELECTED_FILTERS,
      payload: _selected,
    });

    set_nav(_selected);
  };

  const set_nav = (new_selected) => {
    let _selected = build_default_State(categories);
    // console.log(_selected, new_selected);
    new_selected.map((item, i) => {
      if (_selected[item.id].selected) {
        _selected[item.id]["selected"] = false;
        Object.keys(_selected[item.id]).map((s) => {
          if (s != "selected" && s != "priority") {
            _selected[item.id][s].selected = false;
          }
        });
      }
      if (item.subfactor != null) {
        _selected[item.id][item.option].subfactors[
          item.subfactor
        ].selected = true;
        _selected[item.id][item.option].sub = true;
      } else {
        _selected[item.id][item.option].selected = true;
      }
      return null;
    });

    dispatch({
      type: SET_NAVIGATION,
      payload: _selected,
    });
  };

  return (
    <Container>
      <Column>
        <Title>
          {props.isFrench
            ? questionSet?.dimensions[0].fr
            : questionSet?.dimensions[0].title}
        </Title>
        {questionSet?.dimensions.length > 0 && filteredData1.length > 0
          ? filteredData1.map((item, i) => {
              return (
                <Row key={i}>
                  <Linked onClick={() => navigate(item)}>
                    {/*<Num>{i + 1 + ". "}</Num>*/}
                    {isFrench
                      ? questionSet?.dimensions[item?.id]?.factors[item?.factor]
                          ?.fr
                      : questionSet?.dimensions[item?.id]?.factors[item?.factor]
                          ?.title}
                  </Linked>
                  <StyledProgressBar
                    value={Math.floor(item?.response * 10) / 10}
                    total="10"
                    inverted={false}
                    color={props.color} // accepts only ["red","orange","yellow","olive","green","teal","blue","violet","purple","pink","brown","grey","black"].
                    size="tiny"
                    style={{
                      width: "calc(100% - 140px)",
                      margin: 0,
                    }}
                  />
                  <Score>{item?.response.toFixed(1)}</Score>
                </Row>
              );
            })
          : ""}
      </Column>
      {props.dimensions != 1 ? (
        <Column>
          <Title>
            {props.isFrench
              ? questionSet?.dimensions[1].fr
              : questionSet?.dimensions[1].title}
          </Title>

          {questionSet?.dimensions.length > 0 && filteredData2?.length > 0
            ? filteredData2.map((item, i) => {
                return (
                  <Row key={i}>
                    <Linked onClick={() => navigate(item)}>
                      {/*<Num>{i + 1 + ". "}</Num>*/}
                      {isFrench
                        ? questionSet?.dimensions[item?.id]?.factors[
                            item?.factor
                          ]?.fr
                        : questionSet.dimensions[item.id].factors[item.factor]
                            .title}
                    </Linked>
                    <StyledProgressBar
                      value={Math.floor(item.response * 10) / 10}
                      total="10"
                      inverted={false}
                      color={props.color} // acepts only ["red","orange","yellow","olive","green","teal","blue","violet","purple","pink","brown","grey","black"].
                      size="tiny"
                      style={{
                        width: "calc(100% - 140px)",
                        margin: 0,
                      }}
                    />
                    <Score>{item.response.toFixed(1)}</Score>
                  </Row>
                );
              })
            : ""}
        </Column>
      ) : (
        ""
      )}
    </Container>
  );
};

export default DataRanking;

const Container = styled.div`
  padding: 5px 5px 20px 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Column = styled.div`
  width: calc(50% - 10px);
`;

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: #66d686 !important;
    border-radius: 15px !important;
  }
  border-radius: 15px !important;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2.5px 0px 2.5px 2.5px;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const Linked = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: 300;
  width: 100px;
  cursor: pointer;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`;

const Num = styled.div`
  font-size: 16px;
  width: 30px;
  font-weight: bold;
  line-height: 1;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #c0c0c0;
  margin-bottom: 20px;
`;
const Score = styled.div`
  width: 10px;
  margin-left: 25px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;

  color: #7e7e7e;
`;
