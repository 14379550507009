
// This file contains the calculations for the Tag report


// This function returns the most recent responses for each primary factor
export const get_most_recent = (responses) => {
    let tracker = {};
    return {
      date: null,
      responses: responses
        .filter((i) => {
          if (!(i.name in tracker)) {
            tracker[i.name] = i.date;
          }
  
          return i.date === tracker[i.name];
        })
        .map((i) => i.responses)
        .flat()
        .map((i) => i.response)
        .filter((f) => !Array.isArray(f)),
    };
  };

  export const get_last_responses = (responses) => {
    let tracker = {};
  
    return responses
      .filter((i) => {
        if (!(i.name in tracker)) {
          tracker[i.name] = [i.date];
        } else if (tracker[i.name].length === 1) {
          tracker[i.name].push(i.date);
        }
  
        if (tracker[i.name].length === 2) {
          return i.date == tracker[i.name][1];
        }
        return false;
      })
      .map((i) => i.responses)
      .flat()
      .map((i) => i.response)
      .filter((f) => !Array.isArray(f));
  };



  export function filter_data(data, filters) {
    return data.reduce((filteredData, response) => {
      // Extract response IDs and subfactor IDs from categories
      const responseIds = response?.categories?.map((cat) => cat.response);
      const subfactorIds = response?.categories
        ?.filter((cat) => cat?.subfactor)
        ?.map((cat) => cat.subfactor);

      // Separate filters into categories
      const categoryFilters = filters.filter(
        (filter) => !filter.isSubfactor && filter.type_of !== "factor"
      );
      const subfactorFilters = filters.filter((filter) => filter.isSubfactor);
      const factorFilters = filters.filter(
        (filter) => filter.type_of === "factor"
      );

      // Group category filters by parent
      const groupedCategoryFilters = categoryFilters.reduce((acc, filter) => {
        if (!acc[filter.parent]) acc[filter.parent] = [];
        acc[filter.parent].push(filter);
        return acc;
      }, {});

      // Check category filters
      for (let parent in groupedCategoryFilters) {
        const requiredFilters = groupedCategoryFilters[parent];
        const hasMatchingFilter = requiredFilters.some((filter) => {
          return responseIds.includes(filter.id);
        });
        if (!hasMatchingFilter) {
          return filteredData; // Skip this response
        }
      }

      // Check subfactor filters
      if (subfactorFilters.length > 0) {
        const hasMatchingSubfactor = subfactorFilters.some((filter) => {
          return subfactorIds.includes(filter.id);
        });
        if (!hasMatchingSubfactor) {
          return filteredData; // Skip this response
        }
      }

      // Initialize filtered questions and feedback with original data
      let filteredQuestions = response.questions;
      let filteredFeedback = response.feedback;

      // Apply factor filters if any
      if (factorFilters.length > 0) {
        // Group factor filters by dimensionIndex
        const groupedFactorFilters = factorFilters.reduce((acc, filter) => {
          if (!acc[filter.dimensionIndex]) acc[filter.dimensionIndex] = [];
          acc[filter.dimensionIndex].push(filter);
          return acc;
        }, {});

        // Filter questions
        filteredQuestions = response.questions.filter((q) => {
          if (q.dimension === undefined) {
            return false; // Exclude if dimension is undefined
          }
          const requiredFilters = groupedFactorFilters[q.dimension];
          if (!requiredFilters) return false;

          return requiredFilters.some((filter) => q.factor === filter.id);
        });

        // Filter feedback
        filteredFeedback = response.feedback.filter((q) => {
          if (q.dimension === undefined) {
            return false; // Exclude if dimension is undefined
          }
          const requiredFilters = groupedFactorFilters[q.dimension];
          if (!requiredFilters) return false;

          return requiredFilters.some((filter) => q.factor === filter.id);
        });

        // Exclude response if no matching questions or feedback
        if (filteredQuestions.length === 0 && filteredFeedback.length === 0) {
          return filteredData; // Skip this response
        }
      }

      // Include the response with filtered questions and feedback
      filteredData.push({
        ...response,
        questions: filteredQuestions,
        feedback: filteredFeedback,
      });

      return filteredData;
    }, []);
  }

export const get_overall_average = (data) => {
  let totalSum = 0;
  let totalCount = 0;

  data.forEach((item) => {
    item.questions.forEach((question) => {
      if (typeof question.response === "number") {
        totalSum += question.response;
        totalCount++;
      }
    });
  });

  return totalCount ? totalSum / totalCount : 0; // Avoid division by zero
};

const sum_array = (arr) => {
    if(!arr){
        return 0
    }

    let sum = 0;
    arr.forEach(item => {
        sum += item;
    });
    return sum;
}

export const get_hr_flags = (data) => {
    let flags = 0;
    let feedback_quality = 0;
    let totalCount = 0;

    data.forEach(item => {
        item?.ml_feedback?.feedback?.forEach(question => {

                flags+= sum_array(question?.conversation_analysis?.hr_flag)

                question?.conversation_analysis?.quality.forEach(quality => {
                    feedback_quality+=quality
                    totalCount++
                })
        });
    });

    return {flags,feedback_quality:feedback_quality/totalCount}
}

const get_factor_average = (data, factor,dimension) => {
    
        let totalSum = 0;
        let totalCount = 0;
    
        data.forEach(item => {
            item.questions.forEach(question => {
                if (question.factor === factor && question.id === dimension) {
                    totalSum += question.response;
                    totalCount++;
                }
            });
        });
    
        return totalCount ? totalSum / totalCount : 0;  // Avoid division by zero
    
}

const get_item_scores = (data, index,dimension,factor) => {
    let scores = []
    factor.questions.forEach((item,question)=>{
        let totalSum = 0;
        let totalCount = 0;
        data.forEach(response=>{
            response.questions.forEach((q,i)=>{
                if(q.factor === index && q.id === dimension && q.q === question){
                    totalSum += q.response;
                    totalCount++;
                }
            })
        })
        scores.push({question:item.q,score:totalCount ? totalSum / totalCount : 0})
    })
    return scores
}

const get_pillar_standard = (standards,index,dimension) => {
   return standards?.response.pillars.find(f=>f.id==dimension && f.factor==index)
}

export const get_scores_per_pillar = (data,survey,org_data,last_data,standards) =>{
    let scores = []
    survey.questions.dimensions.map((item,dimension)=>{
        item.factors.map((factor,index)=>{
           scores.push({name:factor.title,
                        score:get_factor_average(data,index,dimension),
                        item_scores:get_item_scores(data,index,dimension,factor),
                        dimension,
                        factor:index,
                        last_score:get_factor_average(last_data,index,dimension),
                        pillar_standard:get_pillar_standard(standards,index,dimension),
                        org_score:get_factor_average(org_data,index,dimension)})

        })
    })
    return scores
}

export const getStructuredFeedback = (data) => {
    let feedback = []
    data?.map((item)=>{
        item.feedback.map((f)=>{
           feedback.push(f)
        })
    })

    return feedback
}