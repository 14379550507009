import React,{useEffect, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { Progress, Modal, Input } from 'semantic-ui-react';
import make_call from './Request'
import {sampleData} from './sample'
import {parseMarkdown} from './MarkdownParser'
import Icon from 'assets/images/UI/mdi_sparkles-outline.svg'

const DiagnosticReport = ({ method,onContinue }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [feedback, setFeedback] = useState(""); // Store feedback text
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility
    const [history, setHistory] = useState([]); // Chat history
    const [isInitialCall, setIsInitialCall] = useState(true); // Flag for first call



    useEffect(() => {
        make_gpt_call();
    }, []);

    const make_gpt_call = async (userFeedback = "") => {
        setLoading(true);
        setMessage("");

        // Prepare updated history, adding initial message on the first call
        const updatedHistory = isInitialCall
            ? [
                ...history,
                {
                    role: "user",
                    content: `
                    The following is the feedback data for the Communication culture factor, and the well-being outcome:
                    ${sampleData}

                    As a professional summarizer and culture diagnostic agent, create a concise and comprehensive summary of the provided survey data, while adhering to these guidelines:

                    1. Craft a summary that is detailed, thorough, in-depth, and complex, while maintaining clarity and conciseness.
                    2. Incorporate main ideas and essential information, eliminating extraneous language and focusing on critical aspects.
                    3. Rely strictly on the provided text, without including external information.
                    4. Format your summary using markdown syntax.
                    5. Include an overall summary, 3 key notions, and 3 key findings.
                    6. Explain how Communication is currently impacting well-being according to the data provided.
                    `
                }
            ]
            : [...history, { role: "user", content: userFeedback }];

        const assistantResponse = await make_call(
            updatedHistory,
            (chunk) => setMessage((prev) => prev + chunk)
        );

        setLoading(false);
        setHistory((prevHistory) => [
            ...updatedHistory,
            { role: "assistant", content: assistantResponse.trim() }
        ]);
        setIsInitialCall(false);
    };




    const handleRegenerateClick = () => {
        setModalOpen(true); // Open the modal
    };

    const handleRegenerateWithFeedback = () => {
        setModalOpen(false); // Close the modal
        make_gpt_call(feedback); // Call the function with feedback
        setFeedback(""); // Clear feedback after use
    };



 return (<Container>
    <Title>Diagnostic Report</Title>

    <T2>The highest ROI for Well-being is <Label>Communication</Label></T2>
    
    
    <T3>
        Communication Score
    </T3>
    <Description>
        Communication has scored 74% in the latest survey.
    </Description>
    <Progress percent={74} color="blue" progress/>

    <T3>
        Potential ROI
    </T3>
    <Description>
        Communication has the potential to increase Well-being by up to 23% based on our predictive model.
    </Description>


    <Summary>
    <Regenerate onClick={handleRegenerateClick}><IMG src={Icon}/>Regenerate Diagnostic</Regenerate>
        {parseMarkdown(message)}
    </Summary>


    {!loading && <Steps>
        <T4>Next Steps</T4>

        <Description>
            Now that we've generated a diagnosis of your culture data you have several options to consider for your next steps.
        </Description>

        <ButtonArea>
            <Button onClick={()=>onContinue()}>Save Diagnostic & Build Action Plan</Button>
            <Button>Save Diagnostic</Button>
        </ButtonArea>
    </Steps> }


     {/* Modal for feedback */}
     <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>Provide Feedback for Regeneration</Modal.Header>
                <Modal.Content>
                    <Input
                        fluid
                        placeholder="Enter your feedback here..."
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <ButtonArea>
                        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                        <Button primary onClick={handleRegenerateWithFeedback}>Regenerate</Button>
                    </ButtonArea>
                   
                </Modal.Actions>
            </Modal>




  </Container>
);
}

export default DiagnosticReport;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    width:100%;
    font-family: 'Raleway';
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`

const Title = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:15px;
    color:grey;
`

const Description = styled.div`

`

const T2 = styled.div`
    font-size:30px;
    font-weight:bold;
    margin-bottom:40px;
    line-height:1.2;
`

const Label = styled.span`
    color:#2D70E2;
    font-weight:bold;
`

const T3 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:5px;
    margin-top:20px;
`
const Summary = styled.div`
    margin-top:70px;
    padding-bottom:50px;
    position:relative;
`

const Steps = styled.div`
    margin-top:30px;
    width:100%;
    padding:20px;
    border-top:1px solid #ddd;
    margin-bottom:50px;
`
const ButtonArea = styled.div`
    display:flex;
    flex-direction:row;
    margin-top:20px;
    justify-content:flex-end;
`
const Button = styled.div`
    background-color:#2D70E2;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    margin-left:10px;
    cursor:pointer;
    font-weight:bold;
`

const T4 = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-bottom:20px;
    color:#2A3039;
`

const Regenerate = styled.div`
    font-size:14px;
    font-weight:bold;
    color:white;
    cursor:pointer;
    margin-bottom:20px;
    display:flex;
    align-items:center;
    background-color:#2D70E2;
    padding:8px 14px;
    border-radius:5px;

    position:absolute;
    right:0px;
    top:-40px;
`

const IMG = styled.img`
    width:20px;
    height:20px;
    margin-right:10px;
`