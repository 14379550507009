import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import SlackIntegrationManager from './SlackIntegrationManager';

const SlackApp = ()=>{

  return (<ThemeProvider theme={theme}>
    <div style={{ padding: '20px', minHeight: '90vh',marginTop:30 }}>
      <SlackIntegrationManager demoMode={true} />
    </div>
  </ThemeProvider>);


  }



export default SlackApp;