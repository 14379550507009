import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  LOGIN_PENDING,
  UPDATE_ACCOUNT_DEFAULT_PENDING,
  LOGIN_SUCCESS,
  LOGIN_UPDATE_ACCOUNT_DEFAULT,
  SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
  ALL_ERRORS,
  CLEAR_DEBRIEF_SCHEDULE,
  CLEAR_CULTURE_AUDIT_REPORT,
  CLEAR_EMPLOYEE_CATEGORY,
  CLEAR_EMPLOYEE_PERMISSION,
  CLEAR_SURVEY_QUESTION,
  CLEAR_SURVEY_STANDARD,
  CLEAR_SURVEY_STRUCTURE,
  CLEAR_EMPLOYEE_ORG_CATEGORY
} from "constants/actions";

import * as api from "constants/api";


// LOGIN USER
function* defaultAccountUpdate(action) {
  // Clear out old data from store if any,
  yield put({ type: CLEAR_DEBRIEF_SCHEDULE });
  yield put({ type: CLEAR_CULTURE_AUDIT_REPORT });
  yield put({ type: CLEAR_EMPLOYEE_CATEGORY });
  yield put({ type: CLEAR_EMPLOYEE_PERMISSION });
  yield put({ type: CLEAR_SURVEY_QUESTION });
  yield put({ type: CLEAR_SURVEY_STANDARD });
  yield put({ type: CLEAR_SURVEY_STRUCTURE });
  yield put({ type: CLEAR_EMPLOYEE_ORG_CATEGORY });

  // Regular login operations
  yield put({ type: LOGIN_PENDING });
  yield put({ type: UPDATE_ACCOUNT_DEFAULT_PENDING });
  try {
    const login = yield call(api.UPDATE_ACCOUNT_DEFAULT, action.payload);
    yield put({ type: LOGIN_UPDATE_ACCOUNT_DEFAULT, payload: login.data });
    yield put({ type: LOGIN_SUCCESS, payload: login.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* updateDefaultAccount() {
  // Clear out old data from store if any,
  yield put({ type: CLEAR_DEBRIEF_SCHEDULE });
  yield put({ type: CLEAR_CULTURE_AUDIT_REPORT });
  yield put({ type: CLEAR_EMPLOYEE_CATEGORY });
  yield put({ type: CLEAR_EMPLOYEE_PERMISSION });
  yield put({ type: CLEAR_SURVEY_QUESTION });
  yield put({ type: CLEAR_SURVEY_STANDARD });
  yield put({ type: CLEAR_SURVEY_STRUCTURE });
  yield put({ type: CLEAR_EMPLOYEE_ORG_CATEGORY });

  yield takeLatest(SG_LOGIN_UPDATE_ACCOUNT_DEFAULT, defaultAccountUpdate);
}

export default function* index() {
    yield all([
      updateDefaultAccount(),
    ]);
  }
  