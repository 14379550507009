import styled, { keyframes } from "styled-components";
import React from "react";
import MemberCard from './StatusCard'
import { Button } from 'semantic-ui-react'

const data = {
	'first_name':'Michael',
	'last_name':'Bawol',
	'email':'mike@innerlogic.com',
	email_status_text:'Delivered',
	'primary':'Athletics',
	'secondary':'Sport Scientist'

}

const Status = () =>{

	return (
		<Container>
			<ButtonContainer>
				<Button color="blue" >Remind All</Button>
          		<Button color="red" >Close Survey</Button>
          	</ButtonContainer>
			<Table>
	          <Headers>
	            <TH width={20}>Name</TH>
	            <TH width={20}>Email</TH>
	             <TH width={15}>Primary Factor</TH>
	             <TH width={15}>Secondary Factor</TH>
	            <TH width={10}>Status</TH>
	           {/*  <TH width={15}>Account type Role
	            <Popup
	              trigger={<Icon name='question circle' color='black' size='small' circular />}
	              content='Role helps you manage the team'
	              position='bottom right'
	        /> 
	            </TH>*/}
	            <TH width={20}>Action</TH>
	          </Headers>

	          <MemberCard data={data}/>
          </Table>
		</Container>
		)
}


export default Status


const Container = styled.div`
	margin-top:50px;
`

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;

  @media (max-width:768px){
    display:none;
  }
`;

const ButtonContainer = styled.div`
	width:100%;
	display:flex;
	justify-content:flex-end;
	padding:20px;
`