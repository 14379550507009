import React from "react";
import styled from "styled-components";
import { Input } from 'semantic-ui-react'

// const Custom = styled.input`
// 	border-radius:5px;
// 	height:45px;
// 	outline:none;
// 	border:${props=>props.error? '1px solid #ff2d55':'1px solid #9b9b9b'};
// 	margin-top:3px;
// 	padding-left:15px;
// `

const Custom =  styled(Input)({
  borderRadius:"5px",
	height:"45px",
	outline:"none",
	border:` ${props=>props.error? '1px solid #ff2d55':'1px solid #9b9b9b'}`,
	marginTop:"3px",
	paddingLeft:"15px",
});

const Container = styled.div`
  font-size: 16px;
  font-weight:400;
  font-family: 'Red Hat Display', sans-serif;
  display:flex;
  flex-direction:column;
  margin-bottom:15px;
  width:${props=>props.half?'calc(50% - 15px)':'100%'};

  @media(max-width:768px){
     margin-bottom:5px;
     width:${props=>props.half?'calc(50% - 5px)':'100%'};
   }

`

const Error = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #ff2d55;
`;

const Label = styled.div`
   font-weight:bold;
   @media(max-width:768px){
     font-size:14px;
   }
`

const StyledInput = React.forwardRef((props, ref) => {
  return (
    <Container half={props.half}>
      <Label>{props.placeholder}</Label>

      <Custom
        error={props.error}
        type={props.type}
        disabled={props.disabled}
        onChange={(e) => props.onChange(e.target.value)}
        defaultValue={props.defaultValue}
        forwardedRef={ref}
      />
      <Error>{props.error}</Error>
    </Container>
  );
});

export default StyledInput;
