import styled, { keyframes } from "styled-components";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import CultureDial from './Dial'

const Overview = ({ culOverview, moodOverview, pastDates }) => {

  const [ChartSeries, setChartSeries] = useState(series);
  const [ChartOptions, setChartOptions] = useState(options);

  useEffect(() => {
    setChartSeries([
      {
        name: 'Team Health',
        data: culOverview?culOverview.map(item=>item?item:0):[]
      }, {
        name: 'Emotional Health',
        data: moodOverview?moodOverview.map(item=>item?item:0):[]
      }]
    )
  }, [culOverview, moodOverview]);

  useEffect(() => {
    setChartOptions({
      ...options, xaxis: {
        type: 'date',
        categories: pastDates
      }
    })
  }, [pastDates]);

  // console.log(culOverview)
  // console.log(moodOverview)

  // useEffect(()=>{
  //   console.log(ChartSeries)
  // },[ChartSeries])

  return (
    <Container>
      <DialContainer>
        <CultureDial title={'Team Health'} data={culOverview} />
        <CultureDial title={'Mood'} data={moodOverview} />
      </DialContainer>
      <Title>Metric Trends</Title>
      <Chart options={ChartOptions} series={ChartSeries} type="area" width={'100%'} />
    </Container>
  )
}

export default Overview


const Container = styled.div`
	width:100%;
 	margin-top:20px;
  padding:10px;
`
const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	font-weight:500;
`

const DialContainer = styled.div`
	display:flex;
	justify-content:space-between;
	margin-bottom:20px;
  flex-wrap:wrap;
`

const series = [{
  name: 'Team Health',
  data: [31, 40, 28, 51, 42, 66, 95]
}, {
  name: 'Emotional Health',
  data: [11, 32, 45, 32, 34, 52, 41]
}]

const options = {
  chart: {
    height: 350,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  xaxis: {
    type: 'date',
    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
  },
  yaxis:{
    min:0,
    max:100
  },
  tooltip: {
    x: {
      format: 'dd/MM/yy'
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        chart: {
          height: 500
        }
      }
    }]
};
