import React,{useState} from 'react';
import styled from 'styled-components';
import IMG from 'assets/images/stock/ballon.jpg'
import IMG2 from 'assets/images/stock/desk.jpg'
import IMG3 from 'assets/images/stock/copilot.jpg'
import Box from './Box'
import { useNavigate } from 'react-router-dom';


const Dashboard = () =>{
    const navigate = useNavigate();

    const activateLoading = (route) =>{
        if(route){
            navigate(route)
        }
    }

    return (
        <Container>
            <Center>
                
                <Title>Change Agent Portal</Title>

                <Box 
                    onClick={()=>activateLoading('/simple/prototype/recon/collect')}
                    title={'Collect data'} 
                    IMG={IMG}
                    color={'#2254AA'}
                    buttonText={'Collect'}
                    description={'Deploy a change agent data collector in order to understand your data at a deeper level.'}/>
                <Box 
                onClick={()=>activateLoading('/simple/prototype/recon/active')}
                title={'Active agents'} 
                buttonText={'View'}
                IMG={IMG2}
                color={"#2D70E2"}
                description={'Generate reports and summaries on the data collected using change agents.'}/>
               
               <Box 
                onClick={()=>activateLoading('/simple/prototype/recon/reports')}
                title={'Agent reports'} 

                buttonText={'View'}
                IMG={IMG2}
                color={"#2D70E2"}
                description={'Generate reports and summaries on the data collected using change agents.'}/>
               
            </Center>
        </Container>
    )
}

export default Dashboard;


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
`

const Center = styled.div`
    width:800px;
    `
const Title = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:20px;
`