import React from 'react';
import styled from 'styled-components';

const ProgressContainer = styled.div`
  width: 100%;
  background-color: #e0e0de;
  border-radius: 10px;
  margin: 5px 0;

`;

const ProgressFiller = styled.div`
  height: 10px;
  border-radius: 10px;
  background-color: #2D70E2;
  width: ${props => props.percentage}%;
  transition: width 0.5s ease-in-out;
`;

const ProgressText = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
    justify-content: space-between;
  color: grey;
  font-weight: bold;
  font-size: 10px;
  font-family: 'Raleway';
`;

const ProgressBar = ({ step, totalSteps }) => {
  const percentage = (step / totalSteps) * 100;

  return (
    <div style={{ position: 'relative' }}>
      <ProgressContainer>
        <ProgressFiller percentage={percentage} />
      </ProgressContainer>
      <ProgressText>
        <div>
        {`STEP ${step} OUT OF ${totalSteps} `}
        </div> 
        <div>
            {` ${percentage.toFixed(0)}%`}
        </div>
        
        </ProgressText>
    </div>
  );
};

export default ProgressBar;