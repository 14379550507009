import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";



const Heatmap = () =>{

    return (
        <Container>
            <Row id={1}>
                <Item id={1}/>
                <Item id={2}/>
                <Item id={3}/>
                <Item id={4}/>
                <Item id={5}/>
            </Row>
            <Row id={2}>
                <Item id={1}/>
                <Item id={2}/>
                <Item id={3}/>
                <Item id={4}/>
                <Item id={5}/>
            </Row>
            <Row id={3}>
                <Item id={1}/>
                <Item id={2}/>
                <Item id={3}/>
                <Item id={4}/>
                <Item id={5}/>
            </Row>
            <Row id={4}>
                <Item id={1}/>
                <Item id={2}/>
                <Item id={3}/>
                <Item id={4}/>
                <Item id={5}/>
            </Row>
            <Row id={5}>
                <Item id={1}/>
                <Item id={2}/>
                <Item id={3}/>
                <Item id={4}/>
                <Item id={5}/>
            </Row>
        </Container>
    )
}

export default Heatmap

const fadeIn = keyframes`
    
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    `;

const Container = styled.div`

`

const Row = styled.div`
        display:flex;
        width:100%;
        margin-bottom:5px;
        opacity:0;
        animation: ${fadeIn} 0.5s ease forwards;
        animation-delay: ${props => props.id * 0.1}s;
        margin-bottom:1px;
`

const Item = styled.div`
        opacity:0;
        animation: ${fadeIn} 0.5s ease forwards;
        width:50px;
        border-radius:5px;
        margin-right:0px;
        background-color:blue;
        height:50px;
        margin-right:1px;

        animation-delay: ${props => props.id * 0.1}s;

`