import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { REGISTER_EMPLOYEE, SG_VALIDATE_EMPLOYEE } from "constants/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Dropdown from "./dropdown_role";
import { useForm, Controller } from "react-hook-form";
import { Message } from "semantic-ui-react";
import LeaderSetup from "./leaderSetup";
import { useToasts } from "react-toast-notifications";

import { roles } from "data/employeeAuth";

const email_validation = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const InviteMember = (props) => {
  const apiDispatch = useDispatch();
  const { addToast } = useToasts();
  const methods = useForm();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(9);
  const [open, setOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [emailId, setEmailId] = useState(false);
  const [inviteDisabled, setInviteDisabled] = useState(true);
  const [inviteText, setInviteText] = useState("Enter Email");

  const { register, handleSubmit, control, reset, errors } = methods;

  const { get_employees, get_error } = useSelector(
    (state) => ({
      get_employees: state.employees,
      get_error: state.errors,
    }),
    shallowEqual
  );

  const search = (e) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // if (re.test(e.target.value)) {
    //   setErrorMessage(false);
    //   apiDispatch({
    //     type: SG_VALIDATE_EMPLOYEE,
    //     payload: `email=${e.target.value}`,
    //   });
    //   setEmailId(e.target.value);
    // } else {
    //   // invalid email, maybe show an error to the user.
    //   setErrorMessage("Not valid email id");
    // }
    // console.log(e.target.value)
  };
  // useEffect(() => {
  //   if (get_employees?.search.length > 0) {
  //     setErrorMessage(
  //       `The email ${get_employees?.search[0].email} has been used`
  //     );
  //     setInviteText("Already Invited");
  //     setInviteDisabled(true);
  //   } else if (get_employees?.search.length === 0) {
  //     setInviteText("Invite");
  //     setInviteDisabled(false);
  //   }
  // }, [get_employees.search]);

  useEffect(() =>{
    if(get_employees?.validateEmail.code == 400) {
     setErrorMessage(`The email ${get_employees?.validateEmail?.email} has been used`)
     setInviteText("Already Invited")
   } else if (get_employees?.validateEmail.code == 200) {
     setInviteText("Invite")
     setInviteDisabled(false)
    }
   },[get_employees])

  useEffect(() => {
    setRole(props.role);
  }, [props.role]);


  const invite = (data) => {
    // console.log('invite triggered')
    if (data.role == 1 && data.setup === null) {
      return setOpen(true);
    }
    setOpen(false);
    apiDispatch({
      type: REGISTER_EMPLOYEE,
      payload: {
        email: emailId,
        organization: Number(props.organization),
        role: roles.find(({role}) => role === data.role).role,
        picture: null,
        team: props.team,
      },
    });
    addToast(emailId + " has been successfully invited to this team", {
      appearance: "success",
      autoDismiss: true,
    });
    reset()
    setEmail("");
    setEmailId(false);
    setRole(9);
    props.cancel();
  };

  const cancel = () => {
    setOpen(false);
    setEmail("");
    setEmailId(false);
    setRole(9);
    reset()
    props.cancel();
  };

  // console.log(role)
  // console.log(props)

  return (
    <MemberContainer visible={props.visible}>
      <DropdownContainer1>
        <InviteBox>
          {/* <Controller
					as={StyledInput}
				    name="email"
					onChange={search}
					placeholder="Add user email"
					control={control}
					rules={{
	                required: "Please enter email",
	                pattern:{
						value:email_validation,
						message:'Must be a valid email'}
	              }}
					/> */}
          <StyledInput
            actionPosition="left"
            onChange={search}
            size="huge"
            style={{ width: "90%" }}
            //   action={{
            //     // color: "standard",
            //     disabled: inviteDisabled,
            //     labelPosition: "left",
            //     icon: "paper plane",
            //     content: `${inviteText}`,
            //     onClick: () => save()
            //   }}
            placeholder="Add user email"
            type="email"
          />
        </InviteBox>

        {errorMessage && <Error>{errorMessage}</Error>}
      </DropdownContainer1>

      <DropdownContainer2>
        <Controller
          as={<Dropdown defaultValue={role} />}
          name="role"
          placeholder="Choose role"
          options={roles}
          setRole={(r) => setRole(r)}
          rules={{
            required: "Please choose an access level",
          }}
          control={control}
        />
        {errors.role && <Error>{errors.role.message}</Error>}
      </DropdownContainer2>

      <Controller
        as={LeaderSetup}
        name="setup"
        open={open}
        defaultValue={null}
        control={control}
        close={() => setOpen(false)}
        onSubmit={handleSubmit(invite)}
      />

      <div
        style={{
          width: "30%",
          display: "flex",
          height: "100%",
          alignItems: "center",
          padding: "0 0 0 15px",
        }}
      >
        <form onSubmit={handleSubmit(invite)}>
          <Button disabled={inviteDisabled} type="submit">
            Send Invite
          </Button>
        </form>
        <CancelButton onClick={() => cancel()}>Cancel</CancelButton>
      </div>
    </MemberContainer>
  );
};

export default InviteMember;

const MemberContainer = styled.div`
  width: 100%;
  padding: ${(props) => (props.visible ? "26px 0px 26px 0px" : 0)};
  border-bottom: ${(props) => (props.visible ? "1px solid #e9e9e9" : "none")};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Red Hat Display", sans-serif;
  color: #4a4a4a;
  max-height: ${(props) => (props.visible ? "100px" : 0)};
  overflow: ${(props) => (props.visible ? "visible" : "hidden")};
  transition: max-height 500ms, padding 500ms, overflow 500ms;

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: ${(props) => (props.visible ? "200px" : 0)};
    align-items: flex-start;
  }
`;
const Button = styled.button`
  width: 102px;
  height: 45px;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 64px;
  border: none;
  outline: none;
`;

const CancelButton = styled.div`
  width: 99px;
  min-width: 99px;
  height: 45px;
  border-radius: 8px;
  background-color: #e9e9e9;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
`;
const StyledInput = styled.input`
  width: 100%;
  padding: 5px;
  background-color: transparent;
  border: none;
  font-family: "Red Hat Display", sans-serif;
  &:focus {
    outline: none;
  }
`;

const InviteBox = styled.div`
  width: 100%;
  border-radius: 20px;
  background-color: #ebebeb;
  color: grey;
  transition: width 1s;
  font-family: "Red Hat Display", sans-serif;
  padding: 5px 15px 5px 15px;
  z-index: 1;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Error = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #ff2d55;
  margin-left: 20px;
  margin-top: 5px;
`;

const DropdownContainer1 = styled.div`
  width: 30%;
  display: flex;
  height: 100%;
  padding: 0 0 0 15px;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
const DropdownContainer2 = styled.div`
  width: 40%;
  display: flex;
  height: 100%;
  padding: 0 0 0 15px;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
