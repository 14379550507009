import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { Table, List } from "semantic-ui-react";


import Select from "react-select";

import { SG_EDIT_FILE_UPLOAD } from "constants/actions";



function HeaderList({ showReportId, surveyHeaders,
    setFileHeaders,
    fileHeaders, jsonData, setJsonData,
    setunMapped }) {

    const dispatch = useDispatch();

    const [csvListValues, setcsvListValues] = useState([])
    const [csvSelectedValue, setCsvSelectedValue] = useState(false)

    const [recordListValues, setRecordListValues] = useState([])
    const [recordSelectedValue, setRecordSelectedValue] = useState(false)
    const [notFound, setNotFound] = useState([])

    // console.log(surveyHeaders)

    useEffect(() => {
        const _notFound = []
        fileHeaders.map(fh => {
            if (fh !== "valid") {
                if (!surveyHeaders.includes(fh)) {
                    _notFound.push(fh)
                }
            }
            return null
        })
        setNotFound(_notFound)
    }, [fileHeaders, surveyHeaders, setNotFound]);

    useEffect(() => {
        setunMapped(notFound)
    }, [notFound, setunMapped]);

    useEffect(() => {
        const _List = []
        if (surveyHeaders) {
            surveyHeaders.filter(sh => sh !== 'id' && sh !== "valid").map(sh => _List.push({
                value: sh, label: sh
            }))
        }
        setRecordListValues(_List)
    }, [surveyHeaders]);

    useEffect(() => {
        const _List = []
        if (fileHeaders) {
            fileHeaders.filter(sh => sh !== 'id' && sh !== "valid").map(sh => _List.push({
                value: sh, label: sh
            }))
        }
        setcsvListValues(_List)
    }, [fileHeaders]);

    function UpdateRow() {
        const value = csvSelectedValue.value
        const replaceBy = recordSelectedValue.value
        const _newArray = []

        // console.log("Value", value)
        // console.log("replace by", replaceBy)

        if (value !== replaceBy) {
            jsonData.map(jd => {
                Object.defineProperty(jd, replaceBy,
                    Object.getOwnPropertyDescriptor(jd, value));
                delete jd[value];
                _newArray.push(jd)
                return null
            }
            )
            const _fileHeaders = fileHeaders
            var index = _fileHeaders.indexOf(value);
            if (index !== -1) {
                _fileHeaders[index] = replaceBy;
                setFileHeaders(_fileHeaders)
            }

            setJsonData(_newArray)

            dispatch({
                type: SG_EDIT_FILE_UPLOAD,
                payload: {
                    id: showReportId,
                    review_data: _newArray,
                },
            });
        }
    }
    // let SelectionListValues = []

    return (
        <FormContainer>
            {notFound?.length > 0 &&
                <>
                    <Label>
                        Found Fields that are not mapped properly
                        and could not be imported
                        please re-map them below
                    </Label>

                    <FlexContainer>
                        {notFound.map((nf, i) =>
                            <FlexItem key={i}>
                                {nf}
                            </FlexItem>
                        )}
                    </FlexContainer>

                </>

            }

            <Table celled selectable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Uploaded Header
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Map to Column
                        </Table.HeaderCell>
                        <Table.HeaderCell>

                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Select
                                placeholder={`CSV Header`}
                                onChange={setCsvSelectedValue}
                                value={csvSelectedValue}
                                options={csvListValues}
                            // isLoading={isLoading === variable ? isLoading : false}
                            // getOptionLabel={(selectionListValues) => `${selectionListValues?.data_for_text} - ${selectionListValues?.name}`}
                            // getOptionValue={(selectionListValues) => selectionListValues?.cat_field_name}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Select
                                placeholder={`Remap Header`}
                                onChange={setRecordSelectedValue}
                                value={recordSelectedValue}
                                options={recordListValues}
                            // isLoading={isLoading === variable ? isLoading : false}
                            // getOptionLabel={(selectionListValues) => `${selectionListValues?.data_for_text} - ${selectionListValues?.name}`}
                            // getOptionValue={(selectionListValues) => selectionListValues?.cat_field_name}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: 20,
                                }}
                            >
                                <StyledButton
                                    // disabled={selectedValue?.[nf]?.name === nf ? false : true}
                                    onClick={UpdateRow}
                                >
                                    Update
                                </StyledButton>
                            </div>
                        </Table.Cell>
                    </Table.Row>


                </Table.Body>
            </Table>
        </FormContainer>
    )
}




export default HeaderList;

const Label = styled.div`
    margin-bottom: 10px;
    font-weight: bold;
    color: red;
`;

const FormContainer = styled.div`
    max-width: 100%;
    margin-top: 20px;
`;

const FlexContainer = styled.div`
    padding: 5px;
    margin: 5px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
`;

const FlexItem = styled.div`
    background: tomato;
    padding: 5px;
    margin: 5px;
    text-align: center;
`

const StyledButton = styled.button`
    color: white;
    font-size: 14px;
    width: ${(props) => props.width + "%"};
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"}; 
    border-radius: 5px;
    background-color: ${(props) => props.disabled ? "#dcdcdc" : " #2d50e2"};  ;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;
    margin-left: 5px;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #2d50e2;
        color: #2d50e2;
    }
`;

const StyledButtonGrey = styled.button`
    color: white;
    font-size: 14px;
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: #595959;
    border: none;
    outline: none;
    padding: 0px 30px;
    border: 1px solid white;

    @media (max-width: 768px) {
        height: 30px;
        font-size: 12px;
        padding: 0 10px 0 10px;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #595959;
        color: #595959;
    }
`;
