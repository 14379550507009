import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress,Icon } from 'semantic-ui-react'


const Theme = (props) =>{



	return (
		<Container>
			<IconContainer>
				<Icon name={props.score==86?"flask":"handshake outline"} style={{color:'white',fontSize:30,margin:0}}/>
			</IconContainer>
			<ContentContainer>
			<Title>{props.score==86?'Feeling supported ':'Bouncing back'}</Title>
			<Impact>Theme Effect on Enjoyment</Impact>
			<Progress percent={props.score} progress size='small' style={{marginBottom:10,marginTop:2}} color='purple'/>
			<Description>Biltong andouille kevin landjaeger. Pork belly ham brisket swine filet mignon prosciutto ball tip sirloin. Ham hock hamburger pork chop tenderloin beef jerky porchetta buffalo pancetta capicola tri-tip burgdoggen</Description>
			</ContentContainer>
			
		</Container>


		)
}


export default Theme


const Container = styled.div`
	display:flex;
	margin-bottom:20px;
	margin-top:40px;
`

const IconContainer = styled.div`
	background-color:#6a00ff;
	border-radius:6px;
	display:flex;
	align-items:center;
	justify-content:center;
	height:50px;
	width:50px;
	padding-top:10px;
	margin-right:10px;
`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:bold;
`

const ContentContainer = styled.div`
	width:100%;
`

const Impact = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:2px;
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`