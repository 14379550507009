import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// import format from "date-fns/format";
// import Chart from "react-apexcharts";

import Individual from "./individualComment"

import SummaryData from './SummaryData';

// import Moment from 'moment';

import { Label, Dropdown, Icon, Dimmer, Loader } from 'semantic-ui-react'
import Empty from "./Empty"
import TopMenu from "./TopMenu"
import { CultureMenu, CultureQuestions } from "data/live/navigation"
import {
	SG_GET_MOODS,
	SG_GET_CULTURE_DATA,
	LOAD_EMPLOYEES,
	LOAD_TEAMS,
	SG_GET_EMPLOYEE_REPORTS,
	SG_GET_INDUSTRY_BENCHMARK,
} from "constants/actions";

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}

const getMax = (width) => {
	if (width > 1400) {
		return 6;
	} else if (width > 1300) {
		return 6;
	} else if (width > 1200) {
		return 6;
	} else if (width > 1000) {
		return 5;
	} else if (width > 930) {
		return 4;
	} else if (width > 680) {
		return 3;
	}
};

// const NavOptions = [{ text: 'Trust', value: 1 }, { text: 'Clarity', value: 2 }, { text: 'Communication', value: 3 }, { text: 'Alignment', value: 4 },
// { text: 'Resilience', value: 5 }, { text: 'Impact', value: 6 }, { text: 'Overview', value: 7 }]

const TeamHealth = ({selectedTeamId,selectedTeam}) => {
	const dispatch = useDispatch();
	const [selectedMenu, setSelectedMenu] = React.useState(1)
	const [cultureQuestion, setCultureQuestion] = React.useState(CultureQuestions[0].question);

	const [cultureData, setCultureData] = useState([]);
	const [scoreData, setScoreData] = useState([]);
	const [scoreLastData, setScoreLastData] = useState({
		1: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		4: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		5: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		6: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
	});
	// const [onDate, setOnDate] = useState(new Date(1995, 6, 2))

	const [selectedName, setSelectedName] = useState('')
	const [selectedComment, setSelectedComments] = useState([])
	const [selectedEmployee, setSelectedEmployee] = useState(0)
	const [employeeScore, setEmployeeScore] = useState(0)
	const [employeeLast, setEmployeeLast] = useState(0)
	const [employeeAvg, setEmployeeAvg] = useState(0)

	const [teamLast, setTeamLast] = useState(0)
	const [teamAvg, setTeamAvg] = useState(0)

	const [show, setShow] = React.useState(false)
	// const [position, setPosition] = useState(0);
	const [width, height] = useWindowSize();
	const [view, setView] = useState(false);
	const [loading, setLoading] = useState(true);
	const [number, setNumber] = useState(false);

	const [questions, setQuestions] = useState([]);

	const EmployeeId = useSelector((state) => state.auth?.employee_id, shallowEqual)
	const AmbassadorId = useSelector((state) => state.auth?.ambassador_id, shallowEqual)

	const { culture_data, employees_data, industry_benchmark_data,
		get_selectedOrg } = useSelector(
			(state) => ({
				// mood_data: state.moods.moods,
				culture_data: state.culture.culture,
				employees_data: state.employees.employees,
				industry_benchmark_data: state.industry_benchmark.industry_benchmark,
				get_selectedOrg: state.selectedOrg
			}),
			shallowEqual
		);

	// console.log(get_selectedOrg?.organization?.id)

	useEffect(() => {
		// Run! Like go get some data from an API.
		// dispatch({ type: SG_GET_MOODS });
		if (Number(AmbassadorId) > 0) {
			const OrgId = get_selectedOrg?.organization?.id
			if (OrgId) {
				dispatch({
					type: LOAD_EMPLOYEES,
					payload:  `organization=${OrgId}` 
				});
				dispatch({
					type: LOAD_TEAMS,
					payload:  `organization=${OrgId}` 
				});
				dispatch({
					type: SG_GET_CULTURE_DATA,
					payload:  `organization=${OrgId}` 
				});
				dispatch({
					type: SG_GET_EMPLOYEE_REPORTS,
					payload:  `organization=${OrgId}` 
				});
				dispatch({
					type: SG_GET_CULTURE_DATA,
					payload: `organization=${OrgId}${selectedTeamId ? `&team=${selectedTeamId}` : ''}`
				});
				
			}
		} else {
			dispatch({ type: LOAD_EMPLOYEES });
			dispatch({ type: LOAD_TEAMS });
			dispatch({
				type: SG_GET_CULTURE_DATA,
				payload: `${selectedTeamId ? `team=${selectedTeamId}` : ''}`
			});	
			dispatch({ type: SG_GET_EMPLOYEE_REPORTS });
		}

		dispatch({ type: SG_GET_INDUSTRY_BENCHMARK });

	}, [dispatch, AmbassadorId, get_selectedOrg, selectedTeamId]);


	// console.log(culture_data)
	// console.log(industry_benchmark_data)
	// console.log(culture_data[0]?.responses.filter(
	// 				p => p.employee_id === Number(EmployeeId)))

	useEffect(() => {
		if (culture_data) {
			setCultureData(culture_data[0]);
			// console.log(culture_data[0])
			if (culture_data[0]?.compliance) {
				setNumber(culture_data[0].compliance.length - getMax(width));
			}
			setQuestions(culture_data[0]?.questions.find(({ id }) => id === selectedMenu))
			if (culture_data?.length > 1) {
				setTeamLast(culture_data[1]?.questions.find(({ id }) => id === selectedMenu)?.score)
				setEmployeeLast(culture_data[1]?.responses.filter(
					p => p.employee_id === Number(EmployeeId))[0]?.responses?.filter(
						c => c.name === selectedName)[0]?.score)

				if (culture_data[1]?.scores) {
					setScoreLastData(culture_data[1]?.scores)
				}
			}

			const EmpScore = (culture_data[0]?.responses.filter(
				p => p.employee_id === Number(EmployeeId))[0]?.responses?.filter(
					c => c.name === selectedName)[0]?.score)


			setEmployeeScore(EmpScore ? EmpScore : 0)
			setScoreData(culture_data[0]?.scores)
			setSelectedName(CultureMenu.find(({ id }) => id === selectedMenu)?.name)
			setSelectedComments(
				culture_data[0]?.survey_comments.filter(
					f => f.id === selectedMenu))

			const TeamTotal = []
			const EmpTotal = []

			culture_data.map(cl => {
				TeamTotal.push(cl.questions.find(({ id }) => id === selectedMenu)?.score)
				if (cl.responses.filter(
					p => p.employee_id === Number(EmployeeId))[0]?.responses?.filter(
						c => c.name === selectedName)[0]?.score) {
					EmpTotal.push(cl.responses.filter(
						p => p.employee_id === Number(EmployeeId))[0]?.responses?.filter(
							c => c.name === selectedName)[0]?.score)
				}

			})



			// console.log(EmpTotal)
			setTeamAvg(
				Math.floor(TeamTotal.reduce((a, b) => a + b, 0) / TeamTotal.length)
			)
			setEmployeeAvg(
				Math.floor(EmpTotal.reduce((a, b) => a + b, 0) / EmpTotal.length)
			)

		}
	}, [culture_data, width, EmployeeId, selectedMenu, selectedName]);


	useEffect(() => {
		setTimeout(() => setLoading(false), 1000);
	});



	// const forDate = cultureData?.on_date ? cultureData?.on_date : new Date();
	// const d = new Date();
	// console.log(addDays(new Date(), 3))

	// console.log(CultureQuestions.filter(
	// 	  f => f.id === selectedMenu)[0]?.question)

	// console.log(selectedComment)
	// console.log()

	// // let date = new Date("2018-01-01T00:00:00");
	// console.log(
	// 	culture_data[0]?.responses.filter(
	// 	p => p.employee_id === Number(EmployeeId))[0]
	// 	)
	// console.log(culture_data[1]?.responses.filter(
	// 	p => p.employee_id === Number(EmployeeId))[0]?.responses?.filter(
	// 		c => c.name === selectedName)[0]?.score
	// 	)

	return (
		<Container>
			<Dimmer.Dimmable blurring dimmed={loading}>
				<Dimmer active={loading} inverted>
					<Loader>Loading</Loader>
				</Dimmer>
				{culture_data.length < 1 && !loading ?
					<Empty /> :
					<>
						<TopMenu selected={selectedMenu} setSelected={setSelectedMenu}
							CultureMenu={CultureMenu} />
						<Card>
							<MobileNav>
								<Dropdown
									inline
									options={CultureMenu}
									defaultValue={CultureMenu[0].id}
									onChange={(e, v) => {
										setSelectedMenu(v.value)
									}}
								/>
							</MobileNav>

							<SummaryData
								scoreData={scoreData?.[selectedMenu]}
								scoreLastData={scoreLastData?.[selectedMenu]}
								selectedName={selectedName}
								selected={selectedMenu}
								questions={questions}
								culture_data={cultureData}
								employeeScore={employeeScore}
								employeeLast={employeeLast}
								teamLast={teamLast}
								employeeAvg={employeeAvg}
								teamAvg={teamAvg}
								selectedEmployee={selectedEmployee}
								industryBenchmark={industry_benchmark_data}
								selectedTeam={selectedTeam}
							/>

							<Individual
								selectedComment={selectedComment}
								selectedName={selectedName}
								team_type={cultureData?.team_type}
								employeesData={employees_data}
								selectedTeam={selectedTeam}

							/>

						</Card>
					</>}

			</Dimmer.Dimmable>
		</Container>
	)
}

export default TeamHealth

const Card = styled.div`
	  width:100%;
	  display:flex;
	  flex-direction:column;
	  padding:0px 0px;
	  margin-bottom:10px;
`

const Container = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	align-items:center;
`

const Metrics = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:26px;
	font-weight:bold;
	color:#4a4a4a;
`

const MetricName = styled.div`
	 @media(max-width:1000px){
    display:none;
  }
`

const Line = styled.div`
	width:30px;
	height:5px;
	background-color:#6a00ff;
	margin-bottom:5px;
`

const StyledDate = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:15px;
	border-bottom:1px solid #e9e9e9;
	margin-bottom:30px;
`


const StyledDates = styled.div`
	display:flex;

`

const Time = styled.div`
	color:${props => props.selected ? 'white' : '#6a00ff'};
	padding:10px;
	font-family: 'Red Hat Display', sans-serif;
	border:1px solid #6a00ff;
	border-left:${props => props.first ? '1px solid #6a00ff' : 'none'};
	background-color:${props => props.selected ? '#6a00ff' : 'none'};
	cursor:pointer;
`

const MobileNav = styled.div`
  font-size:24px;
  padding-bottom:10px;
  padding-left:20px;

  @media(min-width:1000px){
    display:none;
  }
`

const DistributionTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:20px;
  margin-bottom:0px;
  font-weight:500;
  color:#4a4a4a;
  margin-left:10px;
`

const Hide = styled.div`
  max-height:${props => props.show ? '400px' : '0px'};
  overflow:hidden;
  transition:all 1s;
  padding:${props => props.show ? '0px 20px 20px 0' : '0px'};
`

const See = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  cursor:pointer;
  padding:20px;

  &:hover{
    text-decoration:underline;
  }
`


const data = {

	series: [{
		name: 'Trust',
		data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
			5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
			48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
		]
	}],
	options: {
		chart: {
			type: 'bar',
			height: 350,
			sparkline: {
				enabled: true
			},
			toolbar: { show: false }
		},
		grid: {
			padding: {
				right: 125
			}
		},
		plotOptions: {
			bar: {
				colors: {
					ranges: [{
						from: -1,
						to: 100,
						color: '#6a00ff'
					}, {
						from: -100,
						to: -1,
						color: '#ff2d55'
					}]
				},
				columnWidth: '80%',
			}
		},
		annotations: {
			yaxis: [
				{
					y: 0,
					borderColor: '#e9e9e9',
					offsetX: 125,
					strokeDashArray: 0,
					label: {
						borderColor: '#00E396',
						borderWidth: 0,
						offsetX: 125,
						style: {
							color: '#4a4a4a',
							fontFamily: 'Red Hat Display, sans-serif',
							fontSize: 14
						},
						text: 'Average Trust 73%'
					}
				}
			]
		},
		dataLabels: {
			enabled: false,
		},
		yaxis: {
			title: {
				text: 'Growth',
			},
			labels: {
				formatter: function (y) {
					return y.toFixed(0) + "%";
				}
			}
		},
		xaxis: {
			type: 'datetime',
			categories: [
				'2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
				'2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
				'2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
				'2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
				'2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
				'2013-07-01', '2013-08-01', '2013-09-01'
			],
			labels: {
				rotate: -90
			}
		}
	},


};