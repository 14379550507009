import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Checkbox, Message } from 'semantic-ui-react'
import DateTimePicker from 'react-datetime-picker';

import { SortedTimezone } from "data/timezone";
import Select from "react-select";

import {
	LOAD_TEAMS,
	PULL_ORGANIZATION,
	SG_ADD_DEBRIEF_SCHEDULE,
	SG_EDIT_DEBRIEF_SCHEDULE,
	CLEAR_ERRORS,
} from "constants/actions";


const HoursList = [
	{ value: 30, label: "Half Hour (30 mins)" },
	{ value: 60, label: "One Hour (60 mins)" },
	{ value: 90, label: "One and Half Hour (90 mins)" },
	{ value: 120, label: "Two Hours (120 mins)" },
	{ value: 180, label: "Three Hours (180 mins)" },
	{ value: 360, label: "Six Hours" },
	{ value: 720, label: "Twelve Hours" },
]

const AddSchedule = ({ debriefSchedule, briefId,
	CloseAll, setFetchAgain,
	setShowReportId }) => {
	const dispatch = useDispatch();
	const [dateValue, setDateValue] = useState(new Date());

	const [briefType, setBriefType] = useState(false)
	const [employee, setEmployee] = useState(false)

	const [teamList, setTeamList] = useState([])
	const [selectedTeam, setSelectedTeam] = useState([])
	const [selectedNoOfHours, setSelectedNoOfHours] = useState([])
	const [organizationId, setOrganizationId] = useState(false);
	const [organization, setOrganization] = useState(false);
	const [inviteLeaders, setInviteLeaders] = useState(false);
	const [inviteMembers, setInviteMembers] = useState(false);

	const [pristine, setPristine] = useState(true)
	const [formErrors, setFormErrors] = useState([]);
	// const [editDebrief, setEditDebrief] = useState({...debriefSchedule})

	const [timeZone, setTimeZone] = useState({
		value: "America/Halifax",
		label: "America/Halifax",
		text: "America/Halifax",
	});

	useEffect(() => {
		setBriefType(debriefSchedule?.type_of)
	}, [debriefSchedule]);

	const EmployeeId = useSelector((state) => Number(state.auth?.employee_id), shallowEqual)
	const AmbassadorId = useSelector((state) => Number(state.auth?.ambassador_id), shallowEqual)

	const {
		get_errors, get_employee,
		get_auth, get_teams, get_selectedOrg,
		get_organizations, get_debrief_schedules } = useSelector(
			(state) => ({
				get_errors: state.errors,
				get_auth: state.auth,
				get_teams: state.teams.teams,
				get_employee: state.employees,
				get_selectedOrg: state.selectedOrg,
				get_organizations: state.organizations,
				get_debrief_schedules: state.debrief_schedule
			}),
			shallowEqual
		);


	useEffect(() => {
		// Run! Like go get some data from an API.
		// dispatch({ type: SG_GET_MOODS });
		if (Number(AmbassadorId) > 0) {
			if (organizationId) {
				dispatch({
					type: LOAD_TEAMS,
					payload: `organization=${organizationId}`
				});
				dispatch({
					type: PULL_ORGANIZATION,
					payload: { id: organizationId }
				})
				return
			}
		}
		else {
			dispatch({ type: LOAD_TEAMS });
		}
	}, [dispatch, AmbassadorId,
		organizationId]);

	useEffect(() => {
		setOrganization(get_organizations?.[organizationId])
	}, [get_organizations, organizationId]);

	useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setOrganizationId(get_selectedOrg?.organization?.id)
		} else {
			setOrganizationId(Number(get_auth.organization_id))
		}

	}, [get_selectedOrg, AmbassadorId, get_auth]);

	useEffect(() => {
		setEmployee(get_employee[EmployeeId])
	}, [get_employee, EmployeeId]);

	useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setTeamList(get_teams)
			return
		}
		if (employee) {
			if (employee?.account_type === 5) {
				setTeamList(get_teams)
				return
			} else {
				const _TeamList = []
				_TeamList.push(
					get_teams.filter(
						tm => tm.id === employee?.team)[0]
				)
				setTeamList(_TeamList)
			return
			}
			
		}
	}, [get_teams, employee, AmbassadorId]);

	useEffect(() => {
		if (organization) {
			setTimeZone({
				value: organization?.time_zone,
				label: organization?.time_zone,
				text: organization?.time_zone,
			});
		}
	}, [organization]);


	function UpdateTimezone(e) {

		setTimeZone({
			value: e.value,
			label: e.value,
			text: e.value,
		});
	}
	function UpdateSelectedTeam(e) {
		setSelectedTeam(e)
	}
	function UpdateNofHours(e) {
		setSelectedNoOfHours(e)
	}

	useEffect(() => {
		if (debriefSchedule) {
			// HoursList

			setSelectedTeam(teamList.find((tm) => tm?.id === debriefSchedule.team))
			setDateValue(new Date(debriefSchedule?.scheduled_for))
			setInviteLeaders(debriefSchedule?.include_leaders)
			setInviteMembers(debriefSchedule?.include_members)
			setSelectedNoOfHours(HoursList.find((hl) => hl.value === debriefSchedule?.close_in_minutes))
			setTimeZone({
				value: debriefSchedule?.time_zone,
				label: debriefSchedule?.time_zone,
				text: debriefSchedule?.time_zone,
			});
		}
	}, [debriefSchedule, teamList]);

	const onSubmit = () => {
		if (formErrors.length > 0) {
			setPristine(false)
			return
		} 
		
		if (briefId) {
			dispatch({
				type: SG_EDIT_DEBRIEF_SCHEDULE,
				payload: {
					user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					id: briefId,
					team: selectedTeam.id,
					include_leaders: inviteLeaders,
					include_members: inviteMembers,
					scheduled_for: dateValue,
					time_zone: timeZone.value,
					close_in_minutes: selectedNoOfHours.value,

				},
			});
			setFetchAgain(briefId)
		} else {
			dispatch({
				type: SG_ADD_DEBRIEF_SCHEDULE,
				payload: {
					user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					type_of: briefType,
					team: selectedTeam.id,
					include_leaders: inviteLeaders,
					include_members: inviteMembers,
					scheduled_for: dateValue,
					time_zone: timeZone.value,
					close_in_minutes: selectedNoOfHours.value,
					organization: organizationId
				},
			});
			setFetchAgain(Math.random())
		}
		CloseAll()
		setPristine(true)
	}

	// console.log(briefType)

	useEffect(() => {
		const ErrorList = []
		if (!briefType) {
			ErrorList.push("Select a Brief Type")
		}
		if (!selectedTeam?.id) {
			ErrorList.push("Select a Team")
		}
		if (!inviteLeaders && !inviteMembers) {
			ErrorList.push("Select Member or Leaders")
		}
		if (!dateValue) {
			ErrorList.push("Select a Date")
		}
		if (!selectedNoOfHours.value) {
			ErrorList.push("Closing Hours")
		}
		setFormErrors(ErrorList)
	}, [briefType, selectedTeam, inviteLeaders,
		inviteMembers, dateValue, selectedNoOfHours]);

	// console.log(briefType)
	// console.log(debriefSchedule)
	// console.log(debriefResponses)
	// console.log(debriefSchedule)

	// console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

	return (
		
			<div>
				{formErrors.length > 0 && !pristine &&
					<Message negative
						//   onDismiss={clearErrors}
						header='We need these details!'
						list={formErrors}
					/>
				}

				<FormContainerTop>
					<Label>Debrief Type</Label>
					<Checkbox
			            radio
			            label='Performance'
			            name='performance'
			            value={1}
						disabled={briefId}
			            checked={briefType === 1}
			            onChange={()=>setBriefType(1)}
			            style={{marginRight:20}}
			          />

			          <Checkbox
			            radio
			            label='Training'
			            name='training'
			            value={2}
						disabled={briefId}
			            checked={briefType === 2}
			            onChange={()=>setBriefType(2)}
			          />
					{/*{TypesOfBriefs.map(tb => {
						return <DebriefType
							brief={tb} key={tb.id}
							briefType={briefType}
							setBriefType={setBriefType} />
					}
					)}*/}

				</FormContainerTop>

				<FormContainer>
					<Label>Team</Label>

					<Select
						placeholder="Select Team"
						onChange={UpdateSelectedTeam}
						value={selectedTeam}
						options={teamList}
						// isLoading={loading}
						getOptionLabel={(option) => option.name}
						getOptionValue={(option) => option.id}
					/>
				</FormContainer>


				<FormContainer>
					<Label>Included team members</Label>
					<Checkbox
						onClick={() => setInviteMembers(!inviteMembers)}
						label='Members' style={{ marginRight: 10 }}
						checked={inviteMembers} />
					<Checkbox
						onClick={() => setInviteLeaders(!inviteLeaders)}
						checked={inviteLeaders}
						label='Leaders' />
				</FormContainer>
				<FormContainer>
					<Label>Time Zone</Label>
					<Select
						placeholder="Select your timezone"
						onChange={(e) => UpdateTimezone(e)}
						value={timeZone}
						options={SortedTimezone()}
					/>
				</FormContainer>
				<FormContainer>

					<Label>Select a date and time</Label>
					<DateTimePicker
						onChange={setDateValue}
						value={dateValue}
					/>
				</FormContainer>

				<FormContainer>

					<Label>After how many hours should the survey close?</Label>
					{/* <Input placeholder='# of hours' /> */}

					<Select
						placeholder="Select No of Hours"
						onChange={(e) => UpdateNofHours(e)}
						value={selectedNoOfHours}
						options={HoursList}
					/>
				</FormContainer>

				<Submit
					disable={formErrors.length > 0 && !pristine}
					onClick={onSubmit}>{briefId? "update" : "Schedule"}</Submit>
			</div>
	)
}

export default AddSchedule


const Container = styled.div`
	// margin-top:30px;
	font-family: 'Red Hat Display', sans-serif;
	// padding:30px;
	// box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	width:400px;
	display:flex;
	align-items:center;
	flex-direction:column;
`

const Title = styled.div`
	font-size:26px;
	font-weight:bold;
	margin-bottom:30px;
`

const Label = styled.div`
	margin-bottom:10px;
	font-weight:bold;
`

const FormContainer = styled.div`
	max-width:300px;
	margin-top:20px;
`

const FormContainerTop = styled.div`
	max-width:300px;
	margin-top:0px;
`

const Submit = styled.button`
	border-radius: 5px;
  background-color:${props => props.disable ? '#dcdcdc' : '#6a00ff'};
  font-size: 14px;
  font-weight: bold;
  color:white;
  padding: 10px;
  display:flex;
  align-items:center;
  justify-content:center;
  width:200px;
  margin-top:30px;
  cursor:${props => props.disable ? 'not-allowed' : 'pointer'};
  border:none;

`

const Types = styled.div`
	width:100%;
	display:flex;
`

const TypeContainer = styled.div`
	width:400px;
	margin-right:30px;
	margin-top:20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	display:flex;
	padding:30px;
    cursor: pointer;
	background-color:${props => props.selected ? 'red' : 'gray'};
`
const TypeName = styled.div`
	font-size:22px;
	font-weight:bold;
	margin-bottom:15px;
`

const Description = styled.div`
	color:#757575;
`

const Image = styled.img`
	width:100px;
`

const See = styled.div`
	margin-top:10px;
	color:#6a00ff;
`
