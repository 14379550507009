import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const Summary = ({ data }) => {
  return (
    <Container>
      <T2 delay={0.3}>
        Here’s what your department is saying about {data.content.factor}:
      </T2>
      <ul delay={0.7}>
        {data.content.text.map((item, i) => {
          return <LI key={i}>{item}</LI>;
        })}
      </ul>
    </Container>
  );
};

export default Summary;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const T1 = styled.div`
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 70px;
  text-align: center;
  line-height: 1.4;
`;

const T2 = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  line-height: 1.4;

  animation: ${fadeIn} 1s ease-out forwards;
`;

const LI = styled.li`
  margin-bottom: 10px;
  line-height: 1.6;
`;
