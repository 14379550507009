import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Gauge from 'assets/images/UI/entypo_gauge.svg'
import { Icon } from "semantic-ui-react";
import 'intro.js/introjs.css';
import { Steps, Hints } from 'intro.js-react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
	COMPLETE_ANALYTICS,
	SET_SUB_NAV,
} from "constants/actions";

const Checklist = () =>{
	const dispatch = useDispatch();
	const [mini,setMini] = useState(false)
	const [steps,setSteps] = useState(false)
	const [currentStep,setCurrentStep] = useState(0)
	const [refresh,setRefresh] = useState(false)

	const { checklist } = useSelector(
    (state) => ({
      checklist: state.checklist.analytics
    }),
    shallowEqual
  );


	const setStep = (s) =>{
		setCurrentStep(s)
		setSteps(true)
	}

	const navigate_tabs = () =>{
		dispatch({
        type: SET_SUB_NAV,
        payload: 3,
      });
	}


	const changing = (nextStepIndex, nextElement) =>{
		if(currentStep==2 && nextStepIndex==1){
			navigate_tabs()
		}
		
	}

	const exit = () =>{
		if(currentStep==0){
			dispatch({
        type: COMPLETE_ANALYTICS,
        payload: 0,
      });
		}
	}


	return (
		<Container mini={mini}>
			<Top>
				<Icon name={mini?"expand":"window minimize outline"} style={{cursor:'pointer'}} onClick={()=>setMini(!mini)}/>
				<Icon name="close" style={{cursor:'pointer'}}/>
				
			</Top>
			<Title><Image src={Gauge}/>On the right track!</Title>
			{checklist?.steps.map((item,i)=>{
				return <Check done={checklist?.steps[i].completed} onClick={()=>setStep(i)}>{i+1}. {item.title}</Check>
			})}

			<Steps
	          enabled={steps}
	            steps={step[currentStep]}
	            initialStep={0}
	            onBeforeChange={changing}
	            onComplete={exit}
          />
		</Container>
		)
}


export default Checklist



const Container = styled.div`
	padding:${props=>props.mini?'10px':'20px'};
	position:fixed;
	bottom:20px;
	left:90px;
	background-color:#476DFA;
	font-family: 'Poppins';
	font-size: 16px;
	line-height: 24px;
	width:300px;
	color: white;
	border-radius:5px;
	display:flex;
	flex-direction:column;
	max-height:${props=>props.mini?'55px':'500px'};
	transition:all 0.5s;
`

const Title = styled.div`
	font-size:18px;
	font-weight:bold;
	display:flex;
	align-items:flex-end;
`

const Check = styled.div`
	font-size:12px;
	margin-top:10px;
	opacity:${props=>props.done?0.3:1};
	cursor:pointer;

`

const Image = styled.img`
		height:30px;
		margin-right:10px;
		margin-left:5px;
`

const Top = styled.div`
	position:absolute;
	top:5px;
	right:5px;
	font-size:12px;
`

const step = [[
	{
    element: '.selector1',
    title:'Welcome',
    intro: 'Welcome to the culture analytics dashboard',
    position: 'right',
    tooltipClass: 'myTooltipClass',
    highlightClass: 'myHighlightClass',
  },
  {
    element: '.tabs',
    title:"Tabs",
    intro: "At the top you'll find the tabs, each tab helps to display data in a different way. The tab you're on now is your main overview.",
  },
  {
    element: '.anchors',
    title:'Anchors',
    intro: 'If you want to visualize the data based on different anchors (categories), then select this dropdown and change the main data view',
  },
   {
    element: '.filters',
    title:'Filters',
    intro: "If you only want to see a particular category of data shown, then use the filters to isolate specific groups"
  },
  {
    element: '#culture_chart',
    title:'Culture map',
    intro: "All anchor factors are displayed on the culture map, showing the scores for the 2 dimensions that make up the culture index"
  },
  {
    element: '.legend-container',
    title:'Score breakdown',
    intro: "Each pillar of culture is broken down into its components. You can compare each score with the benchmarks from other organizations."
  }],[
  {
    element: '.tabs',
    title:'Explore Tabs',
    intro: "At the top you'll find the tabs, each tab helps to display data in a different way. The tab you're on now is your main overview.",
  }],[
  {
    element: '.anchors',
    title:'Try different anchors',
    intro: 'If you want to visualize the data based on different anchors (categories), then select this dropdown and change the main data view',
  }
  ],
  [
  {
    element: '.help',
    title:'Visit the tables tab',
    intro: 'The tables tab is where the individual questions are stored.',
  },
   {
    element: '.tabs2',
    title:'Visit the tables tab',
    intro: 'The tables tab is where the individual questions are stored.',
  }
  ]
];


