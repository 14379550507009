import React,{useEffect, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { Progress, Modal, Input } from 'semantic-ui-react';
import make_call from './Request'
import {sampleData} from './sample'
import {parseMarkdown} from './MarkdownParser'
import { useSelector, shallowEqual } from "react-redux";
import { Icon } from 'semantic-ui-react';

const DiagnosticReport = ({ context,action,selectedFactor,method,selectedOutcomeID,selectedOutcomeName,data,goBack,recommendationLevel}) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [feedback, setFeedback] = useState(""); // Store feedback text
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility
    const [history, setHistory] = useState([]); // Chat history
    const [isInitialCall, setIsInitialCall] = useState(true); // Flag for first call
    const [feedbackData, setFeedbackData] = useState(null);

    const core_data = useSelector(
        (state) => state.audit?.core_data,
        shallowEqual
      );
    
      useEffect(() => {

        if(data){
            let _data = [];
            let outcome_data = [];
            let _feedback = ""

            let {dimensionId, factorId} = selectedFactor;

            if(method == 'ROI'){
                dimensionId = selectedFactor.dimension;
                factorId = selectedFactor.factor;
            }

                data
                    ?.filter((f) => "feedback" in f)
                    ?.filter((f) => f?.feedback?.length > 0)
                    ?.map((item) => {
                        item?.feedback?.map((f) => {
                            if(f.id == dimensionId && f.factor == factorId){
                                _data.push({ feedback: f, categories: item.categories });
                            }
                        });
                    });

               if(selectedOutcomeName){
                    data
                    ?.filter((f) => "feedback_builder" in f)
                    ?.filter((f) => {
                        return f.feedback_builder?.responses?.length > 0;
                    })
                    ?.map((item) => {
                        item?.feedback_builder?.responses
                        ?.filter((f) => f?.response)
                        ?.map((f) => {
                            if(f.q == selectedOutcomeID){
                                outcome_data.push({
                                feedback: f?.responses || f,
                                categories: item?.categories,
                                type: "outcome",
                                });
                            }
                        });
                    });
                }

                    _data.map((item) => {
                        const isFollowUp = item.feedback?.prompt_feedback
                        if(isFollowUp){
                            _feedback+= `## Question:\n${item.feedback.prompt_question}\n`
                            _feedback+= `## Follow-up Feedback:\n${isFollowUp}\n\n`
                        }
                        if(item.feedback.feedback){
                            _feedback+= `## Question:\n${item.feedback.question}\n`
                            _feedback+= `## Feedback:\n${item.feedback.feedback}\n\n`
                        }
                    });

                if(method == 'ROI'){
                    _feedback+= `## Feedback for the outcome ${selectedOutcomeName}\n\n`
                    outcome_data.map((item) => {
                        _feedback+= `## Question:\n${item.feedback.question}\n`
                        _feedback+= `## Feedback:\n${item.feedback.response}\n\n`
                    });
                }


                    setFeedbackData(_feedback);
        }
        
    },[data])
    


    useEffect(() => {
        if(feedbackData){
            make_gpt_call();
        }
    }, [feedbackData]);

    const make_gpt_call = async (userFeedback = "") => {
        setLoading(true);
        setMessage("");


        let orgPrompt = `
        The following is the feedback data for the ${selectedFactor.name} culture factor ${selectedOutcomeName ? `and the ${selectedOutcomeName} outcome` : ""}:
        ${feedbackData}

        Using the selected scenario ${context.map((item,index)=>`- ${item} \n`)}, culture factor ${selectedFactor.name}, and the dominant theme from the feedback, act as a People and Culture Consultant/Organizational Psychologist to create a department or team-level action plan. This plan should focus on improving the chosen culture theme through intentional, manageable actions that departmental/team managers can seamlessly integrate into their regular interactions. Each action step should be practical and sequential, gradually increasing in complexity to encourage continuous growth. The plan should emphasize working on the culture factor within the selected context, ensuring that the actions are directly applicable and effective in that specific environment. The action plan should not rely on senior leadership or external resources but should be fully actionable within the individual manager’s sphere of influence.
Structure of the Action Plan:
Objective: Provide a concise overview (2-3 sentences) that summarizes the purpose of the action plan and its expected outcomes. It should motivate managers to engage with the plan and align with the culture theme. The objective should be engaging, emphasizing the value and encouraging the manager to act. Reference how these steps will enhance the culture factor and ensure it highlights the importance of each action and sets the stage for the steps to follow.
Action Steps: Create three sequential action steps that build upon one another, each including:
A clear, actionable description of the step that can be practiced within day-to-day interactions in the selected context focusing on improving the culture factor. 
Key activities that outline specific actions managers can take to implement the step effectively.
Practical tips for managers on how to approach and embody the culture factor during the action step.
A brief mention of potential challenges they might face and solutions to help overcome these challenges.
A follow-up strategy for maintaining momentum and reinforcing progress.
Suggested timelines for each action step, aligning with a phased approach (e.g., Weeks 1-2, Weeks 3-4, Weeks 5-6).
A measurable KPI (key performance indicator) for tracking progress, expressed in 1-2 sentences.
Qualitative success indicators to give a fuller picture of what success looks like.
Guidelines:
Use accessible, engaging language that avoids jargon while maintaining a professional tone.
The tone should be supportive and encouraging, guiding managers to incorporate the actions confidently.
Ensure that each action step is specifically applicable to the selected context to provide relevant, practical guidance.
Keep the actions practical, manageable, and relevant to the manager’s normal day-to-day activities.
Ensure that the action steps are easy to understand so managers know exactly how to proceed.
Include probing questions or examples within the action step descriptions to make them actionable.
Avoid any reliance on resources that require approval or involvement from senior leadership.
Ensure the plan is designed for immediate, realistic implementation within the manager’s control.
Action Plan Template:
# [Title: {Fluid Title Incorporating "Action Plan," {factor}, and {context}}]
## Objective
[Provide a concise overview of the action plan that aligns with the culture theme and motivates managers to engage in the suggested practices within the selected context.]
## [Action Step 1: Subtitle (Timeline: Weeks 1-2)]
[Detailed description of Action Step 1 that explains what the manager should do and why it matters within the context]
*Key Activities*: 
- [List of specific activities to carry out during the context]
*Tips for Managers*: 
- [Tip 1 for how managers can approach and embody the culture factor]
- [Tip 2 for how managers can approach and embody the culture factor]
*Potential Challenges and Solutions*: [Mention common challenges and practical solutions]
*Follow-Up Strategy*: [Suggest a simple follow-up action to maintain momentum, such as reflecting on the outcome or asking team members for feedback]
### KPI for Action Step 1
[Description of KPI for Action Step 1]  
*Qualitative Success Indicator*: [Description of qualitative success indicator]
## [Action Step 2: Subtitle (Timeline: Weeks 3-4)]
[Engaging and detailed description of Action Step 2 that builds on Action Step 1 and is relevant to the context]
*Key Activities*: 
- [List of specific activities to carry out during the context]
*Tips for Managers*: 
- [Tip 1 for how managers can approach and embody the culture factor]
- [Tip 2 for how managers can approach and embody the culture factor]
*Potential Challenges and Solutions*: [Mention common challenges and practical solutions]
*Follow-Up Strategy*: [Suggest a follow-up action to reinforce the positive impact, such as a recap or appreciation note]

### KPI for Action Step 2
[Description of KPI for Action Step 2]  
*Qualitative Success Indicator*: [Description of qualitative success indicator]
## [Action Step 3: Subtitle (Timeline: Weeks 5-6)]
[Clear and motivating description of Action Step 3 that builds on previous actions and remains context-specific]
*Key Activities*: 
- [List of specific activities to carry out during the context]
*Tips for Managers*: 
- [Tip 1 for how managers can approach and embody the culture factor]
- [Tip 2 for how managers can approach and embody the culture factor]
*Potential Challenges and Solutions*: [Mention common challenges and practical solutions]
*Follow-Up Strategy*: [Suggest a follow-up action, such as a reflection exercise or a team discussion to reinforce the step]
### KPI for Action Step 3
[Description of KPI for Action Step 3]  
*Qualitative Success Indicator*: [Description of qualitative success indicator]
        `


        // Prepare updated history, adding initial message on the first call
        const updatedHistory = isInitialCall
            ? [
                ...history,
                {
                    role: "user",
                    content: recommendationLevel == "Organizational wide"? orgPrompt : `
                    The following is the feedback data for the ${selectedFactor.name} culture factor ${selectedOutcomeName ? `and the ${selectedOutcomeName} outcome` : ""}:
                    ${feedbackData}

                    Based on the feedback from the survey, your role is to provide micro action plans to address the issues brought up
                    in the feedback. 

                    ${selectedOutcomeName? `Our ultimate goal is to improve ${selectedOutcomeName} via ${selectedFactor.name}` : ""}

                    The micro actions should be a gentle nudge, short tip, experiment, or habit that the leader or manager can intentionally practice in their day-to-day work. Each micro action is meant to be repetitive to allow for consistency so that it becomes a habit for the leader. Each micro action should only take the leader ten minutes to practice. The micro actions are also meant to be achieved within 12 weeks so take this into account when selecting and describing each action. As such, the micro actions must be specific, time-oriented when possible, and concrete to positively impact the work environment and employees. The micro action should be descriptive and highlight a specific scenario that is common for a departmental leader or manager like a one-on-one meeting, for example.  When applicable, please incorporate a suggested “what you could say” or sample probing question that could be asked within the associated micro action. A leader should be able to read the micro action and know exactly how to proceed. Therefore, the micro actions must be focused on things within the leader or managers sphere of influence and within their control. It should require little to no tangible resources or reliance on leaders above them. The actions should be in a bulleted list and be one sentence each. 

        The recommendation and micro actions should not include any suggestions for competitor platforms and/or surveys/questionnaires/assessments in the employee engagement, human resources, or people operations space. 

        The recommendations and micro actions should also use accessible, easy terms and avoid jargon while still sounding professional so that any leader/manager can easily understand it. Use a personal, everyday tone so the leader can resonate with the importance of acting. Use unbiased language and do not rely on stereotypes.

        Here’s an example to mimic when developing the why and micro actions:

        Be intentional about Inclusion and Belonging

        Unless people consciously try to be inclusive, exclusion can occur by accident. Even unintentional ambient cues can undermine people's sense of belonging. For example, Walton, Murphy and Ryan point out that 'Walls lined with photos of senior executives that exclude women and people of color may cause members of underrepresented groups to doubt their prospects. 

        Developing belonging should not only happen amongst peer groups.Those who have a trusting relationship with a mentor (or manager) are better able to take advantage of critical feedback and other opportunities to learn. The benefits of these trusting relationships are often greater amongst people from stigmatized groups.

        During team meetings this month, check in and ask yourself, "Am I making others feel they belong where we are?" Being mindful of our own actions can influence how others perceive their sense of belonging.
        If a team meeting is the best way to collaborate, set aside a few minutes for all participants to put their ideas on post-it notes and have each participant stand up and put their ideas on a board, grouping them as they go.
        Engage in conscious amplification at least two times in the next two weeks by repeating an idea of an employee to pull it, assign proper ownership, give it credibility and validate its worthiness of being discussed within your team.
        Create connections, not conflict, in tough conversations
        Words are powerful. Even with the best intentions, we can say or do things that shut down dialogue before it gets started. It's especially easy to do this when the topic is sensitive, controversial, or provocative. But we need to have these tough conversations to understand and bridge differences and successfully collaborate in teams. Create opportunities for connections by using words that invite different perspectives and help people feel heard and valued.
        In one-on-ones, demonstrate that it is safe to bring sensitive issues up with you. Say something like "To make real change, we have to understand each other. That takes talking and, importantly, listening to each other, to bridge our differences. Let's start there."
        During your next team meetings, try demonstrating humility by accepting that your views may be incomplete and showing your willingness to hear other points of view. Here’s something you could say “It sounds like you have a strong point of view. Help me understand how you see this issue.”
        Keep leading with intention. Use vour words and actions to purposefully create different opportunities for dialogue. Rethink the "I tried once, and mindset -  it takes time to build trust and connection. Continue to assume positive intent.

        ###END of example


        The format of the recommendation should be as follows:

        You must generate a plan for each of the context area below:
                    ${context.map((item,index)=>`- ${item} \n`)}

        Each plan should be structured as follows:

        # High level recommendation header including the factor name title. Do not include the word header. 1 sentence.
        ## Objective: header. Do not include the word header. 
        Objective paragraph one which incorporates a high level description of what the recommendation is. 1 sentence
        Objective paragraph two which describes why the recommendation is important using evidence from the data, and you must stress the main benefit or outcome for the manager by practicing the micro actions so that the leader feels that this is critical and urgent. When describing the benefit or usefulness to the leader, use second person (i.e., you). 2  sentences.
        ## Actions: header. Do not include the word header. 
        “-” **Action Title:** Action 
        “-” **Action Title:** Action
        “-” **Action Title:** Action
        A supplementary paragraph explaining why the recommendation is important using credible research, evidence, statistics, and or/best practice.
                    `
                }
            ]
            : [...history, { role: "user", content: userFeedback }];

        const assistantResponse = await make_call(
            updatedHistory,
            (chunk) => setMessage((prev) => prev + chunk)
        );

        setLoading(false);
        setHistory((prevHistory) => [
            ...updatedHistory,
            { role: "assistant", content: assistantResponse.trim() }
        ]);
        setIsInitialCall(false);
    };




    const handleRegenerateClick = () => {
        setModalOpen(true); // Open the modal
    };

    const handleRegenerateWithFeedback = () => {
        setModalOpen(false); // Close the modal
        make_gpt_call(feedback); // Call the function with feedback
        setFeedback(""); // Clear feedback after use
    };



 return (<Container>
     <BackButton onClick={goBack}><Icon name="chevron left"/> Back</BackButton>
    <Title>Action Plan</Title>

    <T2>This plan is being developed for <Label>{selectedFactor.name}</Label></T2>
    

    <Line/>

    <Summary>
    {/* <Regenerate onClick={handleRegenerateClick}><IMG src={Icon}/>Regenerate Diagnostic</Regenerate> */}
        <Page>
            {parseMarkdown(message)}
        </Page>
        
    </Summary>


    {!loading && <Steps>
        <T4>Next Steps</T4>

        <Description>
            Now that we've generated your action plan, here are your next steps.
        </Description>

        <ButtonArea>
            <Button>Save Plan & Generate PDF</Button>
        </ButtonArea>
    </Steps> }


     {/* Modal for feedback */}
     <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Header>Provide Feedback for Regeneration</Modal.Header>
                <Modal.Content>
                    <Input
                        fluid
                        placeholder="Enter your feedback here..."
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <ButtonArea>
                        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                        <Button primary onClick={handleRegenerateWithFeedback}>Regenerate</Button>
                    </ButtonArea>
                   
                </Modal.Actions>
            </Modal>




  </Container>
);
}

export default DiagnosticReport;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
    width:100%;
    font-family: 'Raleway';
    opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`

const Title = styled.div`
    font-size:14px;
    font-weight:bold;
    margin-bottom:5px;
    color:grey;
`

const Description = styled.div`

`

const Line = styled.div`
    width:100%;
    border-top:1px solid #ddd;

    margin-top:0px;
    margin-bottom:0px;
`

const T2 = styled.div`
    font-size:30px;
    font-weight:bold;
    margin-bottom:20px;
    line-height:1.2;
`

const Label = styled.span`
    color:#2D70E2;
    font-weight:bold;
`

const T3 = styled.div`
    font-size:16px;
    font-weight:bold;
    margin-bottom:5px;
    margin-top:20px;
`
const Summary = styled.div`
    margin-top:20px;
    padding-bottom:20px;
    position:relative;
`

const Steps = styled.div`
    margin-top:30px;
    width:100%;
    padding:20px;
    border-top:1px solid #ddd;
    margin-bottom:50px;

     opacity:0;
    animation: ${fadeIn} 1s ease-out forwards;
`
const ButtonArea = styled.div`
    display:flex;
    flex-direction:row;
    margin-top:20px;
    justify-content:flex-end;
`
const Button = styled.div`
    background-color:#2D70E2;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    margin-left:10px;
    cursor:pointer;
    font-weight:bold;
`

const T4 = styled.div`
    font-size:20px;
    font-weight:bold;
    margin-bottom:20px;
    color:#2A3039;
`

const Regenerate = styled.div`
    font-size:14px;
    font-weight:bold;
    color:white;
    cursor:pointer;
    margin-bottom:20px;
    display:flex;
    align-items:center;
    background-color:#2D70E2;
    padding:8px 14px;
    border-radius:5px;

    position:absolute;
    right:0px;
    top:-50px;
`

const IMG = styled.img`
    width:20px;
    height:20px;
    margin-right:10px;
`

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
    margin-bottom:10px;
`

const Page = styled.div`
    padding:50px 30px;
    border:1px solid #ddd;
    border-radius:5px;
    min-height:750px;
`