import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { Loader, Select } from "semantic-ui-react";
import { useDispatch } from "react-redux";
// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import {
  linearRegressionLine,
  linearRegression,
  min,
  max,
  rSquared,
} from "simple-statistics";
import LegendIcon from "./LegendIcon";
import { fetch_data } from "./fetch_data";
import ChartLoading from "reports/Audit/ChartLoading";
import CategorizedDropdown from "./CategorizedDropdown";
import noData from "assets/images/empty/empty.png";

const create_option_list = (categories, questions, type, records) => {
  let options = [];

  //Add the culture factors to the dropdown
  let dimensions = questions.dimensions.length;
  if (dimensions !== 1) {
    questions.dimensions[1].priority = "questions";
    options.push({
      text: questions.dimensions[1].title,
      value: { type: "culture", id: questions.dimensions[1], factor: null },
    });

    questions.dimensions[1].factors.map((item) => {
      options.push({
        text: item.title,
        value: {
          type: "culture",
          id: questions.dimensions[1],
          factor: item.id,
        },
      });
    });
  }
  questions.dimensions[0].priority = "questions";
  options.push({
    text: questions.dimensions[0].title,
    value: { type: "culture", id: questions.dimensions[0], factor: null },
  });

  questions.dimensions[0].factors.forEach((item) => {
    options.push({
      text: item.title,
      value: { type: "culture", id: questions.dimensions[0], factor: item.id },
    });
  });

  //Add the personality factors to the list
  let personality_factors = [
    "Extraversion",
    "Conscientiousness",
    "Agreeableness",
    "Neuroticism",
    "Openess",
  ];

  personality_factors.forEach((item, i) => {
    options.push({
      text: item,
      value: { type: "personality", id: i, text: item },
    });
  });

  //Add employee records
  records.forEach((item, i) => {
    options.push({
      text: item.name,
      value: { type: "records", id: item.id, text: item.name },
    });
  });

  return options;
};

const generate_regression_data = (data) => {
  let min_x = min(data.map((item) => item[0] && item[0]));
  let max_x = max(data.map((item) => item[0] && item[0]));
  let l = linearRegressionLine(linearRegression(data));

  let line = [
    { x: min_x, y: l(min_x) },
    { x: max_x, y: l(max_x) },
  ];
  let r = rSquared(data, l);

  return { line, r };
};

const DataScience = ({ data, records }) => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState();
  const [options, setOptions] = useState(data2.options);
  const [correl, setCorrel] = useState(0);
  const [loading, setLoading] = useState(false);

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    WhiteLabel,
    ambassadorId,
    get_employee,
    get_employee_categories,
  } = useGetSurveyData({ surveyType: 6, auditTypeId: 3 });

  useEffect(() => {
    console.log(data);
  }, [data]);

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  });

  const [horizontal, setHorizontal] = React.useState();
  const [vertical, setVertical] = React.useState();
  const [anchor_list, setAnchorList] = React.useState();
  const [anchor_list2, setAnchorList2] = React.useState();

  useEffect(() => {
    if (get_survey_structure[0] && questionStructure) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1,
        records
      );
      setAnchorList(anchor_list);
      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2,
        records
      );
      setAnchorList2(anchor_list2);
    }
  }, [get_survey_structure[0], questionStructure]);

  useEffect(() => {
    if (horizontal && vertical && data.length > 0) {
      setLoading(true);

      try {
        let new_data = fetch_data(
          data,
          horizontal,
          vertical,
          questionStructure
        );

        let { line, r } = generate_regression_data(new_data);
        setCorrel(r);

        let _series = [];
        _series.push({
          name: "Data",
          type: "scatter",
          data: new_data.map((item) => {
            return { x: item[0], y: item[1] };
          }),
        });
        _series.push({ name: "Trend", type: "line", data: line });
        setSeries(_series);

        console.log(horizontal, vertical);
        let horizontalText;
        let verticalText;
        if (horizontal?.factor) {
          horizontalText = horizontal?.id?.factors?.find(
            (f) => f.id === horizontal?.factor
          )?.title;
        }

        if (horizontal?.id && !horizontal?.factor) {
          horizontalText = horizontal?.id?.title;
        }

        if (vertical?.factor) {
          verticalText = vertical?.id?.factors?.find(
            (f) => f.id === vertical?.factor
          )?.title;
        }

        if (vertical?.id && !vertical?.factor) {
          console.log(horizontal);
          verticalText = vertical?.id?.title;
        }

        let _options = options;
        _options.xaxis.title.text = horizontalText || horizontal?.text;
        _options.yaxis.title.text = verticalText || vertical?.text;
        setOptions(_options);
      } catch (err) {
        console.log(err);
        setSeries(null);
        setLoading(false);
      }
    }
  }, [horizontal, vertical]);

  useEffect(() => {
    if (series) {
      setLoading(false);
    }
  }, [series]);

  if (!get_survey_structure[0]) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>Culture Data Analysis</Title>
      <OptionArea>
        <div style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
          <Label>Y Axis</Label>
          <CategorizedDropdown
            placeholder="Select Survey Type"
            filterOptions={anchor_list}
            value={vertical}
            id="anchors"
            onSelectLabel={(f) => setVertical(f.value)}
          />
        </div>

        <div style={{ marginLeft: 30, display: "flex", alignItems: "center" }}>
          <Label>X Axis</Label>
          <CategorizedDropdown
            placeholder="Select Survey Type"
            filterOptions={anchor_list2}
            value={horizontal}
            id="anchors"
            onSelectLabel={(f) => setHorizontal(f.value)}
          />
        </div>
      </OptionArea>

      <Row>
        <Card>
          {(!horizontal || !vertical || !series) && !loading ? (
            <NoDataContainer>
              <Image src={noData} />
              <Text>No data yet</Text>
            </NoDataContainer>
          ) : loading && horizontal && vertical ? (
            <ChartLoading />
          ) : (
            <Chart
              options={options}
              series={series}
              type="line"
              height={"100%"}
            />
          )}
        </Card>

        <Stats>
          <T2>
            <LegendIcon />
            Linear Regression Analysis
          </T2>
          <div style={{ width: "100%", paddingLeft: 30 }}>
            <SubTitle>R-squared</SubTitle>
            <R>{correl.toFixed(2)}</R>
            <AT>
              The results of the linear regression show that there is a strong
              relationship between Performance Outcomes and Cohesion.
            </AT>
          </div>
          <Correl>
            <T3>
              For every 5% increase in extraversion, your team cohesion
              increases by 10%.
            </T3>
          </Correl>
        </Stats>
      </Row>
    </Container>
  );
};

export default DataScience;

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;
  @media (max-width: 600px) {
    display: none;
  }
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  height: 200px;
  margin-top: 50px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #414141;
  margin-bottom: 30px;
`;

const Text = styled.div`
  font-size: 34px;
  font-weight: bold;
  font-family: "Poppins";
  margin-top: 20px;
`;

const Card = styled.div`
  width: calc(100% - 360px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
`;

const Stats = styled.div`
  width: 350px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 24px 10px;
  margin-left: 10px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #6d6d6d;
  margin-right: 50px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;
  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const R = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  font-family: "Poppins", sans-serif !important;
  line-height: 1;
`;

const T2 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* identical to box height */
  color: #414141;
`;

const T3 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: #414141;
  /* identical to box height */
`;

const Row = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  color: #7e7e7e;
  margin-top: 20px;
`;

const AT = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 30px;
  margin-bottom: 30px;
  color: #7e7e7e;
`;

const Correl = styled.div`
  border-top: 1px solid #d7dce6;
  border-bottom: ${(props) => (props.bottom ? "1px solid #D7DCE6" : "")};
  padding: 13px 24px;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #7e7e7e;
`;

const data2 = {
  series: [
    {
      name: "Points",
      type: "scatter",

      //2.14, 2.15, 3.61, 4.93, 2.4, 2.7, 4.2, 5.4, 6.1, 8.3
      data: [
        {
          x: 1,
          y: 2.14,
        },
        {
          x: 1.2,
          y: 2.19,
        },
        {
          x: 1.8,
          y: 2.43,
        },
        {
          x: 2.3,
          y: 3.8,
        },
        {
          x: 2.6,
          y: 4.14,
        },
        {
          x: 2.9,
          y: 5.4,
        },
        {
          x: 3.2,
          y: 5.8,
        },
        {
          x: 3.8,
          y: 6.04,
        },
        {
          x: 4.55,
          y: 6.77,
        },
        {
          x: 4.9,
          y: 8.1,
        },
        {
          x: 5.1,
          y: 9.4,
        },
        {
          x: 7.1,
          y: 7.14,
        },
        {
          x: 9.18,
          y: 8.4,
        },
      ],
    },
    {
      name: "Line",
      type: "line",
      data: [
        {
          x: 1,
          y: 2,
        },
        {
          x: 2,
          y: 3,
        },
        {
          x: 3,
          y: 4,
        },
        {
          x: 4,
          y: 5,
        },
        {
          x: 5,
          y: 6,
        },
        {
          x: 6,
          y: 7,
        },
        {
          x: 7,
          y: 8,
        },
        {
          x: 8,
          y: 9,
        },
        {
          x: 9,
          y: 10,
        },
        {
          x: 10,
          y: 11,
        },
      ],
    },
  ],
  options: {
    chart: {
      height: "100%",
      type: "line",
      fontFamily: "Poppins, sans-serif",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      borderColor: "#e7e7e7",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [5, 0],
      strokeWidth: 2,
      radius: 2,
      strokeColors: "#476DFA",
    },
    tooltip: {
      enabled: false,
    },
    colors: ["#476DFA", "#66D686", "#9C27B0"],
    xaxis: {
      type: "numeric",
      tickAmount: 10,
      decimalsInFloat: 0,
      title: {
        text: "Team Cohesion",
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#7E7E7E",
        },
      },
    },
    yaxis: {
      tickAmount: 10,
      decimalsInFloat: 0,
      title: {
        text: "Personality",
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: "#7E7E7E",
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  },
};

const test_data = [
  [1, 2.14],
  [1.2, 2.19],
  [1.8, 2.43],
  [2.6, 4.14],
  [2.9, 5.4],
  [3.2, 5.8],
  [3.8, 6.04],
  [4.55, 6.77],
  [4.9, 8.1],
  [5.1, 9.4],
  [7.1, 7.14],
  [9.18, 8.4],
];
