import styled, { keyframes } from 'styled-components';
import React from 'react';
import { Icon } from 'semantic-ui-react'
import { useParams } from "react-router-dom"
import PercentileBubble from '../Bubble'

const data = [3,0,0,10,2,6,5,6,2,0]
const pers = [30,61]
const scale = [0,1,2,3,4,5]
const height = 300

const Card = styled.div`
	padding:15px;
	position:relative;
	width:${props=>props.width+'%'};
	box-sizing:content-box;
	z-index:-1;

	@media (min-width:768px){
		display:none;
	}
`

const ChartBar = styled.div`
	box-shadow:0 2px 20px 0 rgba(141, 141, 141, 0.5);
	background-color:#6a00ff;
	border-radius:2px;
	width:${props=>props.width+'%'};
	height:${props=>props.height+'px'};
	position:absolute;
	top:${props=>props.y+'px'};
	left:${props=>props.x+'%'};

`


const Bubble = styled.div`
	background-color:black;
	height:35px;
	border-radius:20px;
	width:67px;
	color:white;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center
`

const DoubleBubble = styled.div`
	background-color:black;
	height:35px;
	border-radius:20px;
	width:85px;
	color:white;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center
`

const Triangle = styled.div`
	border-left: 5px solid transparent;
  	border-right: 5px solid transparent;
  	border-top: 10px solid black;
  	z-index:3
`

const Axis = styled.h1`
  font-size:16px;
  font-weight: 900;
  font-family: 'Red Hat Display', sans-serif;
`

const getYaxis = (i,multiple) =>{
	return scale.map(x => x * multiple)[i]
}

const getPercentile = (s) =>{
	let mod = s%10
  	if(mod === 0 || mod >3){
  		return s+'th'
  	}else if(mod===1){
  		return s+'st'
  	}else if(mod===2){
  		return s+'nd'
  	}else if(mod===3){
  		return s+'rd'
  	}
}


const BarChartMobile = (props) =>{
const {id1, id2, selectedTrait,average} = props
	//Calculate the bar chart data distribution
    let report_name = props.type
    let team_distribution = [0,0,0,0,0,0,0,0,0,0]
    Object.keys(props.data).map((id,i)=>{
    	team_distribution[Math.floor(props.data[id][props.selectedTrait.key]/10)] += 1
    	return 
    })


	
	const profile1 = props.data.find(({employee}) => employee === id1)
	const profile2 = id2? 
		props.data.find(({employee}) => employee === id2) : false
	
	const score1 = profile1[selectedTrait.key]
	const score2 = id2? profile2[selectedTrait.key] : average
	
	const [position, setPosition] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [max, setMax] = React.useState(Math.max(...team_distribution))
    const [multiple, setMultiple] = React.useState(Math.floor((Math.max(...team_distribution)>5?Math.max(...team_distribution):5 )/5))
    
    const getPosition = (val,wid,multiple) =>{
	let pos = Math.floor(val/10)
  	let barHeight = team_distribution[pos]/(5*multiple)
  	let y = (40+(1-barHeight)*(height-80))
  	let x= 9.09*(pos+1)

	  	return [x,y-45,val]
	}


		return(
		<Card width={props.width}>
			
		<div style={{position:'relative',height:height}}>
		  <svg width={'100%'} height={height} style={{position:"absolute",left:0,top:0}}>
			
	       	<line x1="20" y1={height-40-1*(height-80)/5} x2={'100%'} y2={height-40-1*(height-80)/5} stroke="lightgrey"  strokeWidth="1"/>
	       	<line x1="20" y1={height-40-2*(height-80)/5} x2={'100%'} y2={height-40-2*(height-80)/5} stroke="lightgrey"  strokeWidth="1"/>
	       	<line x1="20" y1={height-40-3*(height-80)/5} x2={'100%'} y2={height-40-3*(height-80)/5} stroke="lightgrey" strokeWidth="1"/>
	       	<line x1="20" y1={height-40-4*(height-80)/5} x2={'100%'} y2={height-40-4*(height-80)/5} stroke="lightgrey"  strokeWidth="1"/>
	       	<line x1="20" y1={height-40-5*(height-80)/5} x2={'100%'} y2={height-40-5*(height-80)/5} stroke="lightgrey"  strokeWidth="1"/>

	       	<text x="0" y={height-35} fill="black"></text>
	       	<text x="0" y={height-35-1*(height-80)/5} fill="black" fontWeight="600">{getYaxis(1,multiple)}</text>
	       	<text x="0" y={height-35-2*(height-80)/5} fill="black" fontWeight="600">{getYaxis(2,multiple)}</text>
	       	<text x="0" y={height-35-3*(height-80)/5} fill="black" fontWeight="600">{getYaxis(3,multiple)}</text>
	       	<text x="0" y={height-35-4*(height-80)/5} fill="black" fontWeight="600">{getYaxis(4,multiple)}</text>
	       	<text x="0" y={height-35-5*(height-80)/5} fill="black" fontWeight="600">{getYaxis(5,multiple)}</text>

	       	<text x={9.09+'%'} y={height-20}  fill="#4a4a4a" fontWeight="600" fontSize="10px" style={{transition:'fill .6s ease'}}>0</text>
	       	<text x={9.09*2+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">10</text>
	       	<text x={9.09*3+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">20</text>
	       	<text x={9.09*4+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">30</text>
	       	<text x={9.09*5+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">40</text>
	       	<text x={9.09*6+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">50</text>
	      	<text x={9.09*7+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">60</text>
	       	<text x={9.09*8+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">70</text>
	    	<text x={9.09*9+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">80</text>
	    	<text x={9.09*10+'%'} y={height-20} fill="#4a4a4a" fontWeight="600" fontSize="10px">90</text>


	    	
	      
	       	
	      </svg>

	      {[0,1,2,3,4,5,6,7,8,9].map((x,i)=>{
	    		let barHeight = team_distribution[i]/(5*multiple)
	    		let highlight = score1>=(x)*10 && score1<=(x+1)*10 || score2>=(x)*10 && score2<=(x+1)*10
	    		if(x===0){

	    			return <ChartBar key={i} highlight={highlight} width={5} height={barHeight*(height-80)-5} x={9.09} y={(40+(1-barHeight)*(height-80))} />
	    		}else{
	    			return <ChartBar key={i} highlight={highlight} x={(9.09*(x+1))} y={40+(1-barHeight)*(height-80)} width={5} height={barHeight*(height-80)-5}  />
	    		}
	    	})}
	      <Icon name='users' size={"large"} style={{position:'absolute',bottom:30,left:-8,color:'grey'}}/>


	     
	      

	     

	      </div>
	      <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
	      	<Axis>Percentiles</Axis>
	      </div>
	      	
	      </Card>

			)

}

export default BarChartMobile