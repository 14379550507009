import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Modal, Icon } from "semantic-ui-react";
import { avatarIcon, AiIcon } from "reports/AI/Icons";
import Chevron from "assets/images/UI/chevron down.svg";

const Query = ({ query, type, error, userLogo, references }) => {
  const inputRef = useRef(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackActions, setShowFeedbackActions] = useState(false);
  const [positiveFeedbackGiven, setPositiveFeedbackGiven] = useState(false);
  const [textCopied, setTextCopied] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const regex = /Question: (.*)/g;

  function formatElements(rawString) {
    if (!rawString) return null;
    // Splitting the rawString into separate lines
    let lines = rawString.split("\n");

    return lines.map((line, index) => {
        // Handling section headers with ###
        if (line.startsWith("###")) {
            if(line.startsWith("####")){
              return <T3 key={index}>{line.replace(/^####\s*/, '').replace(/\*\*/g, '').trim()}</T3>;
            }
            // Removing the '###' and '**' then wrapping the text with <T3>
            return <T3 key={index}>{line.replace(/^###\s*/, '').replace(/\*\*/g, '').trim()}</T3>;
        }
         // Handling list items
         else if (line.trim().match(/^(\d+\.\s|-)/)) {
          // Wrapping the text with '<li>'
          return <LI key={index}
          dangerouslySetInnerHTML={{
            __html:line.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1}</b>`).replace(/^\d+\.\s*\**|\**$/g, '').trim().replace(/^-/, '').trim()}}></LI>;
      }
        // Handling "title: description" format
        // else if (line.includes(":")) {
        //     let parts = line.split(":");
        //     return (
        //         <P key={index}>
        //             <b>{parts[0].replace(/\*\*/g, '').trim().replace(/^-/, '').trim()}</b>: {parts.slice(1).join(":").replace(/\*\*/g, '').trim()}
        //         </P>
        //     );
        // }

        else if (line.trim().match(/^\d+\.\s/)){
          return <T4 key={index}>{line.replace(/\*\*/g, '').trim()}</T4>;
        }
       
        // Handling bold text within lines
        else {
            return <P key={index}  dangerouslySetInnerHTML={{
              __html:line.replace(/\*\*(.*?)\*\*/g, (match, p1) => `<b>${p1}</b>`).trim()}}></P>;
        }
    });
}


  const feedbackModal = (
    <Modal
      onClose={() => setShowFeedbackModal(false)}
      onOpen={() => setShowFeedbackModal(true)}
      open={showFeedbackModal}
      size="small"
      closeOnDimmerClick
    >
      <Modal.Content>
        <Modal.Description>
          <Header>Provide additional feedback</Header>
        </Modal.Description>
        <FeedbackInput
          ref={inputRef}
          placeholder="Type your feedback here..."
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <ActionWrapper>
          <SubmitConfirm onClick={() => setShowFeedbackModal(false)}>
            Submit feedback
          </SubmitConfirm>
        </ActionWrapper>
      </Modal.Actions>
    </Modal>
  );

  const createUserMessage = (query) => {
    const text =
      !query?.id && query?.match(regex)?.[0]
        ? query?.match(regex)?.[0]?.replace("Question: ", "")
        : query?.id
        ? query?.message
        : query;

    return (
      <QuestionContainer>
        <MessageContainer>
          <Message>
            <Description>{text}</Description>
          </Message>
        </MessageContainer>
        <AvatarWrapper>{avatarIcon()}</AvatarWrapper>
      </QuestionContainer>
    );
  };

  function parseArrayString(input) {
    const results = [];
    let buffer = "";
    let insideString = false;
    let currentQuote = null;
    for (let i = 0; i < input.length; i++) {
      const char = input[i];

      if ((char === "'" || char === '"') && !insideString) {
        // Start of a new entry
        insideString = true;
        currentQuote = char;
        buffer = "";
      } else if (char === currentQuote && insideString) {
        // End of the current entry
        insideString = false;
        results.push(buffer);
        buffer = "";
      } else if (insideString) {
        // Inside an entry
        buffer += char;
      }
    }

    return results;
  }

  const handleReferences = (refs) => {
    try {
      return parseArrayString(refs);
    } catch {
      return [];
    }
  };
  const createResponseMessage = (query) => {
    return (
      <Container
        onMouseOver={() => query && setShowFeedbackActions(true)}
        onMouseLeave={() => {
          query && setShowFeedbackActions(false);
          setPositiveFeedbackGiven(false);
          setTextCopied(false);
        }}
      >
        <Circle>
          {AiIcon()}
          {error && <ErrorCircle>!</ErrorCircle>}
        </Circle>
        <TextContainer>
          <MessageContainer>
            <Message>
              <Description error={type === "response" && error}>
                {query !== ""
                  ? formatElements(query)
                  : "An error has occured, if the issue persists please contact support."}
              </Description>
              {references && references != "None" && (
                <ReferencesList references={handleReferences(references)} />
              )}
            </Message>
          </MessageContainer>
        </TextContainer>
      </Container>
    );
  };

  // console.log("type", type);
  return (
    <Container>
      {feedbackModal}
      {type === "response"
        ? createResponseMessage(query)
        : createUserMessage(query)}
    </Container>
  );
};

export default Query;

const ReferencesList = ({ references }) => {
  const [open, setOpen] = useState(false);
  // Function to convert plain text URLs to hyperlinks
  const hyperlinkURLs = (reference) => {
    // Regular expression to identify URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    // Split the reference by spaces and map through each word
    return reference.split(" ").map((word, idx) => {
      if (urlPattern.test(word)) {
        // If the word matches the URL pattern, return an anchor tag
        return (
          <span key={idx}>
            <a href={word} target="_blank" rel="noopener noreferrer">
              {word}
            </a>
            &nbsp;
          </span>
        );
      }
      return `${word} `;
    });
  };

  if (references.length == 0) {
    return "";
  }

  return (
    <ReferencesContainer open={open}>
      <RefTitle onClick={() => setOpen(!open)}>
        References <IMG src={Chevron} open={open} />
      </RefTitle>
      {references.map((reference, index) => (
        <RefText key={index} style={{ marginBottom: "15px" }}>
          {index + 1}. {hyperlinkURLs(reference)}
        </RefText>
      ))}
    </ReferencesContainer>
  );
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: 550px;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 20px;

  width: 100%;
`;

const FeedbackActions = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: flex-end;
  padding-right: 10px;
`;

const FeedbackInput = styled.textarea`
  margin-top: 25px;
  flex-grow: 1;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #999;
  border-radius: 8px;
  color: black;
  padding: 10px;
  outline: none;
  resize: none;
  overflow: auto;
  max-height: 300px; /* Height for 4 lines */
  line-height: 20px; /* Adjust the line-height to match the desired initial height */
  font-size: 14px; /* Adjust the font-size according to your design */
  width: 100%;
  font-family: "Raleway";
  min-height: 150px;

  &::placeholder {
    color: #999;
  }
`;

const IconWrapper = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const QuestionContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: 5px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const SubmitConfirm = styled.div`
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-right: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d70e2;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
`;

const Message = styled.div`
  align-self: center;
`;

const ErrorCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f15062;
  position: absolute;
  bottom: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
`;

const MessageContainer = styled.div`
  background: #f8faff;
  border-radius: 8px;
  padding: 16px;
  margin-right: 10px;
`;

const Description = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.error ? "#F15062" : "#2A3039")};
  white-space: pre-wrap;

  p{
    line-height:2;
  }
`;

const Image = styled.img`
  height: 25px;
`;

const Header = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  color: black;
  justify-content: center;
`;

const T2 = styled.div`
  font-weight: bold;
`;

const ReferencesContainer = styled.div`
  font-family: "Raleway";
  font-size: 12px;
  max-height: ${(props) => (props.open ? "100%" : "40px")};
  overflow: hidden;
`;

const RefTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 15px;
  cursor: pointer;
  color: #2d70e2;
  display: flex;
  align-items: center;
`;

const RefText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #2a3039;
  white-space: pre-wrap;
  padding-left: 20px;
  line-height: 1.3;
`;

const IMG = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0deg)")};
  transition: all 0.3s ease-in-out;
`;


const T3 = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
`
const P = styled.p`
  line-height:1.7;
  margin-bottom:20px;
`
const LI = styled.li`
  margin-bottom:15px;
  line-height:1.7;
`

const T4 = styled.div`
  font-weight: bold;
  font-size:14px;
  margin-bottom:15px;
`
