import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import CultureDial from './Dial'

const Overview = () =>{


	return (
		<Container>
			<DialContainer>
				<CultureDial title={'Team Health'}/>
				<CultureDial title={'Emotional Health'}/>
			</DialContainer>
			<Title>Metric Trends</Title>
			<Chart options={data.options} series={data.series} type="area" width={'100%'}/>
		</Container>
		)
}


export default Overview


const Container = styled.div`
	width:100%;
	box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
 	padding:30px 40px;
 	margin-top:20px;
`
const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	font-weight:500;
`

const DialContainer = styled.div`
	display:flex;
	justify-content:space-between;
	margin-bottom:20px;
`



const data = {
          
            series: [{
              name: 'Team Health',
              data: [31, 40, 28, 51, 42, 66, 95]
            }, {
              name: 'Emotional Health',
              data: [11, 32, 45, 32, 34, 52, 41]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                 toolbar:{
                	show:false
                }
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          };
