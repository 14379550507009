import React from "react";

export function ChevronIcon(fill) {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 13L7 7L1 1" stroke="#666D79" strokeLinecap="round" />
    </svg>
  );
}

export function Checkmark(fill) {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7601 0.48791C12.043 0.723619 12.0812 1.144 11.8455 1.42685L5.17881 9.42685C5.0589 9.57074 4.88399 9.65755 4.69688 9.66604C4.50976 9.67453 4.32771 9.60391 4.19526 9.47146L0.195262 5.47146C-0.0650874 5.21111 -0.0650874 4.789 0.195262 4.52865C0.455612 4.2683 0.877722 4.2683 1.13807 4.52865L4.62187 8.01245L10.8212 0.573268C11.0569 0.290417 11.4773 0.2522 11.7601 0.48791Z"
        fill="#2D70E2"
      />
    </svg>
  );
}

export function IdeasIcon(fill) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27.7834" height="28" rx="8" fill="#F3FDFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8773 14C16.8773 15 16.8773 17 16.8773 18C16.8773 19 15.8773 19 15.8773 19H11.8773C11.8773 19 10.8773 19 10.8773 18C10.8773 17 10.8773 15 9.87732 14C7.55128 11.674 7.29154 8.10051 9.63468 5.75736C11.9778 3.41421 15.7768 3.41421 18.12 5.75736C20.4631 8.10051 20.2842 11.5932 17.8773 14Z"
        stroke="#27CDA7"
        strokeLinecap="round"
      />
      <path d="M11.8916 23H15.8916" stroke="#27CDA7" strokeLinecap="round" />
    </svg>
  );
}

export function DownloadIcon(fill) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 3V16" stroke="#666D79" strokeLinecap="round" />
      <path d="M7 12L12 17L17 12" stroke="#666D79" strokeLinecap="round" />
      <path d="M20 21H4" stroke="#666D79" strokeLinecap="round" />
    </svg>
  );
}

export function CloseIcon(fill) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6569 17.6569L6.34319 6.34315"
        stroke="#666D79"
        strokeLinecap="round"
      />
      <path
        d="M17.6568 6.34315L6.34311 17.6569"
        stroke="#666D79"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function FilterIcon(fill) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99975 9.26137H7.49975C7.49975 9.14363 7.4582 9.02966 7.38241 8.93955L6.99975 9.26137ZM1.41042 2.61548L1.79308 2.29366L1.41042 2.61548ZM16.6457 2.61548L16.2631 2.29366L16.6457 2.61548ZM10.9998 9.32874L10.6171 9.00691C10.5413 9.09702 10.4998 9.21099 10.4998 9.32874H10.9998ZM10.9998 14L11.2998 14.4C11.4257 14.3056 11.4998 14.1574 11.4998 14H10.9998ZM6.99975 17H6.49975C6.49975 17.1894 6.60675 17.3625 6.77615 17.4472C6.94554 17.5319 7.14824 17.5136 7.29975 17.4L6.99975 17ZM7.38241 8.93955L1.79308 2.29366L1.02776 2.93731L6.61709 9.5832L7.38241 8.93955ZM1.79308 2.29366C1.51955 1.96841 1.75077 1.47183 2.17574 1.47183V0.471832C0.900811 0.471832 0.207153 1.96158 1.02776 2.93731L1.79308 2.29366ZM2.17574 1.47183H15.8804V0.471832H2.17574V1.47183ZM15.8804 1.47183C16.3054 1.47183 16.5366 1.96841 16.2631 2.29366L17.0284 2.93731C17.849 1.96158 17.1553 0.471832 15.8804 0.471832V1.47183ZM16.2631 2.29366L10.6171 9.00691L11.3824 9.65056L17.0284 2.93731L16.2631 2.29366ZM10.4998 9.32874V14H11.4998V9.32874H10.4998ZM10.6998 13.6L6.69975 16.6L7.29975 17.4L11.2998 14.4L10.6998 13.6ZM7.49975 17V9.26137H6.49975V17H7.49975Z"
        fill="#666D79"
      />
    </svg>
  );
}




export function FilterReactIcon({ filled }) {
  const fill = filled ? "#FFA500" : "#666D79";
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99975 9.26137H7.49975C7.49975 9.14363 7.4582 9.02966 7.38241 8.93955L6.99975 9.26137ZM1.41042 2.61548L1.79308 2.29366L1.41042 2.61548ZM16.6457 2.61548L16.2631 2.29366L16.6457 2.61548ZM10.9998 9.32874L10.6171 9.00691C10.5413 9.09702 10.4998 9.21099 10.4998 9.32874H10.9998ZM10.9998 14L11.2998 14.4C11.4257 14.3056 11.4998 14.1574 11.4998 14H10.9998ZM6.99975 17H6.49975C6.49975 17.1894 6.60675 17.3625 6.77615 17.4472C6.94554 17.5319 7.14824 17.5136 7.29975 17.4L6.99975 17ZM7.38241 8.93955L1.79308 2.29366L1.02776 2.93731L6.61709 9.5832L7.38241 8.93955ZM1.79308 2.29366C1.51955 1.96841 1.75077 1.47183 2.17574 1.47183V0.471832C0.900811 0.471832 0.207153 1.96158 1.02776 2.93731L1.79308 2.29366ZM2.17574 1.47183H15.8804V0.471832H2.17574V1.47183ZM15.8804 1.47183C16.3054 1.47183 16.5366 1.96841 16.2631 2.29366L17.0284 2.93731C17.849 1.96158 17.1553 0.471832 15.8804 0.471832V1.47183ZM16.2631 2.29366L10.6171 9.00691L11.3824 9.65056L17.0284 2.93731L16.2631 2.29366ZM10.4998 9.32874V14H11.4998V9.32874H10.4998ZM10.6998 13.6L6.69975 16.6L7.29975 17.4L11.2998 14.4L10.6998 13.6ZM7.49975 17V9.26137H6.49975V17H7.49975Z"
        fill={fill}
      />
    </svg>
  );
}
