import styled, { keyframes } from "styled-components";
import React from "react";
import { Dropdown,Icon,Progress } from 'semantic-ui-react'


const themes = ['Opportunity','Hardship','Learning','Uncertainty','Achievement','Strategy','Collaboration','Capacity','Relationships','Gratitude']


const Bar = (props) =>{

	return (
		<BarContainer value={props.value}>
			{Math.floor(props.value*100)+'%'}
		</BarContainer>
		)
}


const HistoryAnalysis = () =>{


	return (
		<Container>
			<H1>Historical Analysis for Trust</H1>
			<Description>Based on all historical comments from Michael, these are the average impact scores for each of the themes 
			in the feedback given. </Description>
				<Table>
					<Titles>
						{themes.map(item=><Title>{item}</Title>)}
					</Titles>
					<BarArea>	
						<Middle>0%</Middle>
						<Negative>
							{themes.map(item=><Bar value={Math.random() *2 -1}/>)}
						</Negative>
					</BarArea>
				</Table>
		<CardContainer>


			<Card>
				<H2>Keywords that reduce</H2>
				<KeywordDescription>A summary of the top 10 keywords that most reduced Trust scores for Michael</KeywordDescription>
				<Keyword>
					Change
					 <StyledProgressBar percent={100} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Relationships
					 <StyledProgressBar percent={86} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Workload
					 <StyledProgressBar percent={77} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Deliverables
					 <StyledProgressBar percent={73} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={72} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={68} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={66} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={55} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={45} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={33} inverted progress size={"small"} color="rgba(241, 45, 162,0.6)"/>
				</Keyword>
			</Card>

			<Card>
				<H2>Keywords for growth</H2>
				<KeywordDescription>A summary of the top 10 keywords that most improved Trust for Michael</KeywordDescription>
				<Keyword>
					Change
					 <StyledProgressBar percent={100} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Relationships
					 <StyledProgressBar percent={86} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Workload
					 <StyledProgressBar percent={77} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Deliverables
					 <StyledProgressBar percent={73} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={72} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={68} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={66} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={55} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={45} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>

				<Keyword>
					Change
					 <StyledProgressBar percent={33} inverted progress size={"small"} color="rgba(0, 176, 240,0.6)"/>
				</Keyword>
			</Card>

		</CardContainer>

		</Container>
		)
}

export default HistoryAnalysis


const Container = styled.div`
	width:100%;

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;
	height:50px;
	display:flex;
	align-items:center;
	font-weight:500;
`


const Titles = styled.div`
	width:200px;
	border-right:1px solid #e4e4e4;
`

const Table = styled.div`
	display:flex;
	margin-top:20px;
`

const BarArea = styled.div`
	width:calc(100% - 200px);
	display:flex;
	position:relative;
`

const Negative = styled.div`
	width:50%;
	border-right:1px solid #e4e4e4;
	display:flex;
	flex-direction:column;
`

const Positive = styled.div`
	width:50%;
`

const Middle = styled.div`
	position:absolute;
	top:-30px;
	width:50px;
	display:flex;
	justify-content:center;
	left:calc(50% - 25px);
	font-family: 'Red Hat Display', sans-serif;
`

const BarContainer = styled.div`
	height:20px;
	width:${props=>Math.abs(props.value)*100+'%'};
	background-color:${props=>props.value>0?'rgba(0, 176, 240,0.6)':'rgba(241, 45, 162,0.6)'};
	margin-top:15px;
	margin-bottom:15px;
	border-radius:${props=>props.value>0?'0 5px 5px 0':'5px 0 0 5px'};
	margin-left:${props=>props.value>0?'100%':'calc(100% - '+Math.abs(props.value)*100+'%)'};
	color:white;
	font-weight:bold;
	display:flex;
	justify-content:${props=>props.value>0?'flex-end':'flex-start'};
	padding:0 5px 0 5px;
	min-width:10%;
`

const H1 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:22px;
	font-weight:bold;
	margin-top:30px;
	margin-bottom:5px;
`

const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:22px;
	font-weight:bold;
	margin-bottom:5px;
`


const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:60px;

`

const KeywordDescription = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-bottom:20px;

`

const Card = styled.div`
	width:calc(50% - 10px);
	padding:30px;
	box-shadow: 0 2px 10px 0 #ececec;
	margin-top:40px;
`

const Keyword = styled.div`
	margin-top:-10px;
	font-family: 'Red Hat Display', sans-serif;
`

const CardContainer = styled.div`
	display:flex;
`

const StyledProgressBar = styled(Progress)`
  & > .bar {
    background-color: ${props => props.color || 'green'} !important;
  }
`;