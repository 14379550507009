// Install styled-components if you haven't already
// npm install styled-components
import React from 'react';
import styled, { keyframes } from 'styled-components';
import Chart from "react-apexcharts";
import { injectDownloadOptions } from "constants/chartOptions";


const TrendChart = () =>{

    return (
      <Container>
        <Content>
          <Title>Productivity Trends</Title>
          <Date>June 2023 - June 2024</Date>

          <Change>+13%</Change>
          <Text>Since last measurement</Text>
        </Content>

        <ChartContainer>
          <Chart
            options={injectDownloadOptions(data.options)}
            series={data.series}
            type="line"
            height={"100%"}
          />
        </ChartContainer>
      </Container>
    );
}


export default TrendChart;

const Container = styled.div`
    display:flex;
    width:100%;
    font-family:"Raleway";
    margin-bottom:40px;

`
const Content = styled.div`
    width:50%;
`

const Title = styled.div`
    color: #2d70e2;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    margin-top:20px;

`

const Date = styled.div`
    font-size:12px;
    color:#7E7E7E;
    margin-bottom:20px;
`

const Text = styled.div`
    font-size:10px;
`

const ChartContainer = styled.div`
    width:50%;
    height:175px;
    margin-top:20px;
`
const Change = styled.div`
    font-size:46px;
    font-weight:bold;
    color:#2D70E2;
    line-height:1;
    margin-bottom:5px;
    margin-top:30px;
`

const data = {
          
    series: [{
        name: "Desktops",
        data: [68,89]
    }],
    options: {
      chart: {
        height: 150,
        width:'100%',
        type: 'line',
        fontFamily: "Poppins, sans-serif",
        sparkline: {
          enabled: true
        },
      },
      colors: ['#2D70E2'],
      stroke: {
        curve: 'smooth'
      },
      markers:{
        size:6
      },
      tooltip:{
        enabled:false
      },
      yaxis:{
        min: 50,
        max: 100,
        show:false
      },
      xaxis: {
        type: 'category',
        labels: {
          show: true, // Ensure labels are shown
          style: {
            colors: '#000', // Color of the labels
            fontSize: '12px'
          }
        },
        axisTicks: {
          show: true, // Ensure ticks are shown
        },
        axisBorder: {
          show: true, // Ensure the axis border is shown
        }
      },
    },
  
  
  };