import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import {
  SG_EDIT_GLOBAL_SCHEDULE,
  SG_ADD_GLOBAL_SCHEDULE,
  SG_GET_SURVEY_COMPLIANCES,
} from "constants/actions";
import { SelectDate } from "../Settings";
import { Icon } from "semantic-ui-react";
import { SelectContainer } from "../styled_list";
import "../style.css";

const DayOfWeek = [
  { value: 0, text: "Monday" },
  { value: 1, text: "Tuesday" },
  { value: 2, text: "Wednesday" },
  { value: 3, text: "Thursday" },
  { value: 4, text: "Friday" },
  { value: 5, text: "Saturday" },
  { value: 6, text: "Sunday" },
];

export const SelectWeekDay = ({ 
  
  selectedDay, MoveUp, MoveDown }) => {
  // console.log('selectedDay', selectedDay, " -----", DayOfWeek[selectedDay])

  return (
    <SelectContainer margin={"10px"}>
      {DayOfWeek.filter((fl) => fl.value === selectedDay)[0].text}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 5,
          cursor: "pointer",
        }}
      >
        <Icon
          name="chevron up"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveUp}
        />
        <Icon
          name="chevron down"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveDown}
        />
      </div>
    </SelectContainer>
  );
};

const DateOfSurvey = ({
  organizationId,
  globalSchedule,
  setChangeDateOfWeek,
}) => {
  const dispatch = useDispatch();
  // const ref = React.createRef();
  const [startDate, setStartDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);
  const [selectedDay, setSelectedDay] = useState(0);

  // console.log(globalSchedule)
  React.useEffect(() => {
    if (globalSchedule?.starting_from) {
      setStartDate(new Date(globalSchedule.starting_from));
    }
    if (globalSchedule?.last_sent || globalSchedule?.starting_from) {
      setShowDate(false);
    } else {
      setShowDate(true);
    }

    if (globalSchedule?.day) {
      setSelectedDay(globalSchedule.day);
    }
  }, [globalSchedule]);

  function CreateUpdateSchedule(SelDate) {
    if (globalSchedule?.starting_from) {
      dispatch({
        type: SG_EDIT_GLOBAL_SCHEDULE,
        payload: {
          id: globalSchedule.id,
          day: SelDate,
          hour: globalSchedule.hour,
          organization: organizationId,
        },
      });
      setChangeDateOfWeek(SelDate)
    }
  }

  function MoveUp() {
    const SelDate = selectedDay <= 5 && selectedDay >= 0 ? selectedDay + 1 : 6
    setSelectedDay(SelDate);
    CreateUpdateSchedule(SelDate)
  }
  function MoveDown() {
    const SelDate = selectedDay <= 6 && selectedDay >= 1 ? selectedDay - 1 : 0
    setSelectedDay(SelDate);
    CreateUpdateSchedule(SelDate)
  }

    // console.log(globalSchedule)
  // console.log(organization)

  return (
    <>
      {showDate === false ? (
        <SelectWeekDay
          selectedDay={selectedDay}
          MoveUp={MoveUp}
          MoveDown={MoveDown}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default DateOfSurvey;
