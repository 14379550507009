import React from 'react';
import styled from 'styled-components';
import Tooltip from './tooltip';
// Define the styled Factor component
const Factor = styled.span`
  font-weight: bold;
  color: blue;  // Example styling, adjust as needed
  cursor: pointer;
`;

const P = styled.div`
    line-height:1.6;
`

// Factors list
const Factors = ["Psychological Safety","Empathy","Belonging","Learning","Values","Communication",
    "Clarity","Adaptability","Resilience","Accountability","Impact","Vision"];

// Function to wrap factors with <Factor> component
const wrapFactors = (text,setSelectedFactor) => {
  const regex = new RegExp(`\\b(${Factors.join('|')})\\b`, 'g');
  return text.split(regex).map((part, index) =>
    Factors.includes(part) ? <Tooltip 
                            text={<Factor key={index}>{part}</Factor>}
                            factor={part}
                            onClick={()=>setSelectedFactor(part)}/> 
                            : part
  );
};

// Function to bold percentages
const boldPercentages = (text) => {
    const percentageRegex = /\b(\d{1,3}\.\d{1,2}%)\b/g;
    return text.split(percentageRegex).map((part, index) =>
      percentageRegex.test(part) ? <strong key={index}>{part}</strong> : part
    );
  };


// Main function to parse the string
const parseString = (text,setSelectedFactor) => {
    // First wrap factors
    let parsedText = wrapFactors(text,setSelectedFactor);
    // Then bold percentages
    parsedText = parsedText.map((part, index) =>
      typeof part === 'string' ? boldPercentages(part) : part
    );
  
    // Flatten the array to remove nested arrays
    return [].concat(...parsedText);
  };

// Example usage in a React component
const ExampleComponent = ({ text,setSelectedFactor }) => {
  const parsedText = parseString(text,setSelectedFactor);

  return (
    <P>
      {parsedText}
    </P>
  );
};

export default ExampleComponent;