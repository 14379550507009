import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import {Icon} from 'semantic-ui-react'

const Activity = ({activity,description,icon,onClick,completed}) =>{


    return (
        <Container onClick={()=>onClick()} completed={completed}>
           
           <Icon name={icon} size={'large'} color={'#2A3039'}/>
           <div style={{marginLeft:20}}>
                <Title>{activity}</Title>
                <Description>{description}</Description>
           </div>

           {completed && <IconContainer>
           <Icon name="check"  />
            </IconContainer> }
           
        </Container>
    )
}

export default Activity



const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const Container = styled.div`
    padding:20px;
    display:flex;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    margin-bottom:20px;
    font-family:'Raleway';
    align-items:center;
    cursor:pointer;
    border:${props=>props.completed?'2px solid #27CDA7':''};
    background-color:${props=>props.completed?'#EAFBF7':''};

    opacity: 0; // Start with hidden state
    animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state
    
    
    // 3. Adjust delay for each sample
    animation-delay: 0.3s; 
`

const Title = styled.div`
    font-size:14px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:5px;
    display:flex;
    align-items:center;
`

const Description = styled.div`
    font-size:12px;
    color:#2A3039;
    margin-top:5px;
`

const IconContainer = styled.div`
    margin-left:20px;
    display:flex;
    align-items:center;
    color:#27CDA7;
    font-size:20px;
`