import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon } from "semantic-ui-react";
import Phase1 from "./Planning/Phase1";
import Plan from "./Plan/index";
import Phase3 from "./Planning/Phase3";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SG_GET_SURVEY_QUESTIONS,
} from "constants/actions";

const empty_selections = {
  metric: { factor: null, q: null },
  primary: { id: null },
  anchor: { id: null, selected: null },
  plan: { id: null },
  planData: ["", "", "", "", "", ""],
  member: [],
  status: null,
};

const Planning = ({ open, close, plans, setPlans }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const [data, setData] = useState(empty_selections);

	const { get_survey_structure, get_survey_questions } = useSelector(
		(state) => ({
			get_survey_structure: state.surveystructure.survey_structure,
			get_survey_questions: state.surveyquestions.survey_questions,
		}),
		shallowEqual
	);

  //Pull survey questions and categories from redux
  useEffect(() => {
    dispatch({ type: SG_GET_SURVEY_STRUCTURES });
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=6&status=2`,
    });
  }, [dispatch]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const next = () => {
    if (selected == 0) {
      let check = false;

      if (
        data.metric.factor &&
        data.metric.q != null &&
        data.primary.id &&
        data.plan.id != null
      ) {
        let anchor = data.anchor.id;
        if (anchor) {
          check = data.anchor.selected ? true : false;
        } else {
          check = true;
        }
      }

      if (check) {
        setSelected(selected + 1);
      }
    }
    if (selected == 1) {
      setSelected(selected + 1);
    }
    if (selected == 2) {
      setPlans([...plans, data]);
      setData(empty_selections);
      setSelected(0);
      close();
    }
  };

  return (
    <Container open={open}>
      <InnerContainer>
        <TopContainer>
          <Close onClick={() => close()}>
            <Icon name="close" style={{ margin: 0, marginTop: -3 }} />
          </Close>
        </TopContainer>

        <ScrollContainer>
          {get_survey_questions.length > 0 ? (
            selected === 0 ? (
              <Phase1
                categories={get_survey_structure[0]}
                questions={get_survey_questions[0]}
                data={data}
                setData={(d) => setData(d)}
              />
            ) : selected === 1 ? (
              <Plan
                data={data}
                setData={(d) => setData(d)}
                questions={get_survey_questions[0]}
              />
            ) : (
              <Phase3 data={data} setData={(d) => setData(d)} />
            )
          ) : (
            ""
          )}
        </ScrollContainer>
        <Actions>
          <Back onClick={() => setSelected(selected - 1)}>Back</Back>
          <StyledButton onClick={() => next()}>
            {selected == 2 ? "Save" : "Continue"}
          </StyledButton>
        </Actions>
      </InnerContainer>
    </Container>
  );
};

export default Planning;

const Container = styled.div`
  position: absolute;
  min-width: 300px;
  width: 60vw;
  right: ${(props) => (props.open ? "-25px" : "-70vw")};
  top: -20px;
  height: calc(100vh - 50px);
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  padding: 10px;
  overflow: hidden; ;
`;

const ScrollContainer = styled.div`
  max-height: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 5px;
  font-size: 16px;

  &:hover {
    background-color: rgba(9, 30, 66, 0.2);
  }
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Actions = styled.div`
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: lightgrey;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
`;
const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;

const Back = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  font-style: normal;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #7f7f7f;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;
