import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import Chart from "react-apexcharts";


const Retention = () =>{

    return (
        <Container>
            <Title>Turn Over Analytics</Title>
            <Description>Below you'll find an analysis of culture factors as it relates to the archived tags associated with individuals who have completed the survey prior to leaving the organization.</Description>


            <ChartArea>
                <Chart options={data3.options} series={data3.series} type="line" height={500} />
            </ChartArea>
           
        </Container>
    )

}


export default Retention


const Container = styled.div`
    width: 100%;
    max-width:1000px;
`
const Title = styled.div`
    font-family: Raleway;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    margin-bottom:24px;
`
const Description = styled.div`
font-family: Raleway;
font-size: 16px;
font-style: normal;
font-weight: 400;
max-width:800px;
line-height:1.5;
`
const ChartArea = styled.div`
    width:100%;
    margin-top:40px;
`

const data3 = {
    series: [{
        name: "Organization Average",
        data: [75,78,88,84]
      },
      {
        name: "Resignation",
        data: [78, 66, 50, 43]
      }
    ],
    options: {
      chart: {
        type: "bar",
        fontFamily: "Poppins, sans-serif",
        foreColor: "#7E7E7E",
        toolbar: {
          show: false,
        },
        width: 600,
        height: 400,
      },
      noData: {
        text: "Loading chart...",
        align: "center",
        verticalAlign: "middle",
      },
      stroke: {
        width: [5, 7, 5],
        curve: 'straight',
        dashArray: [0, 8, 5]
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          horizontal: true,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Apples",
          "Oranges",
          "Strawberries",
          "Pineapples",
          "Mangoes",
          "Bananas",
          "Blackberries",
          "Pears",
          "Watermelons",
          "Cherries",
          "Pomegranates",
          "Tangerines",
          "Papayas",
        ],
        tickPlacement: "on",
        labels: {
          style: {
            fontSize: "9px",
          },
        },
      },
      xaxis: {
        title: {
          text: "Survey Collection",
        },
      },
      yaxis: {
        min: 0,
        max: 100,
        decimalsInFloat: 0,
        tickAmount: 5,
        title: {
          text: "",
          style: {
            fontWeight: 400,
          },
        },
      },
    },
  };
  