import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Pic from 'assets/images/UI/empty1.jpg'

const Empty = () => {
    return (
        <Container>
            <IMG src={Pic} />
            <Text>No recommendations have been generated yet</Text>
            <P>
                Your AI generated recommendations will populate after your culture survey has been completed. 
                There may not be enough data to generate recommendations yet.
            </P>
        </Container>
    )
}

export default Empty

const Container = styled.div`
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    margin-top:100px;
`

const IMG = styled.img`
    width:300px;
`

const Text = styled.div`
    font-family: "Poppins";
    font-weight:bold;
    font-size:20px;
    margin-top:20px;
`

const P = styled.div`
    font-family: "Poppins";
    font-weight:400;
    font-size:14px;
    max-width:600px;
    margin-top:10px;
    line-height:1.5;
    text-align:center;
`