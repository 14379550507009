import React, { useState, useEffect } from "react";

// First step.
import CreateTemplatePage from "../actions/CreateTemplate";
// If users are navigating back, or editing a draft template.
import EditTemplatePage from "../actions/EditTemplate";
// If users are on final step of survey creation.
import ScheduleTemplatePage from "../actions/ScheduleTemplate";

// Include styles.
import styled from "styled-components";
// Include toasts for success and error messages.
import { useToasts } from "react-toast-notifications";
// Include the redux actions.
import { useSelector, useDispatch, shallowEqual } from "react-redux";
// Include the navigation
import { useNavigate } from "react-router-dom";
// Parse the params
import { useParams } from "react-router";

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <p>
        Preparing your survey, you will be redirected once this is complete.
      </p>
      <LoadingAnimation />
    </LoadingContainer>
  );
};

// Steps to create a survey:
// 1. Create a survey template. (created task).
// 2. Schedule template using id from created task.
// 3. Create the schedule object, and set the schedule id.
// 4. Assign users to the schedule.
// 5. Send the survey.
const CreateSurvey = ({ currentStep: navStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(navStep || "template");
  // The id will need to be stored as the first step succeeds.
  const [taskId, setTaskId] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);
  const { id, scheduleId: sId } = useParams();
  // Get the redux store items.
  const { createdTask, createdSurvey } = useSelector(
    (state) => ({
      createdTask: state.task_templates.created,
      createdSurvey: state.task_templates?.created_scheduled_task,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      setTaskId(id);
    }

    if (sId) {
      setScheduleId(sId);
    }
  }, [id, sId]);

  useEffect(() => {
    const checkDataAndProceed = async () => {
      setIsLoading(true);

      // Simulate a loading delay
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (createdTask?.id && currentStep === "edit") {
        setIsLoading(false);

        return;
      }

      if (createdTask?.id) {
        setTaskId(createdTask.id);
        // Append the id to the URL
        window.history.pushState(
          {},
          "",
          `/leader/audit/manage/tasks/schedule/${createdTask.id}`
        );

        setCurrentStep("schedule"); // Proceed to the next step after data is confirmed
      } else if (createdSurvey?.id) {
        setScheduleId(createdSurvey.id);
        // We can redirect to the survey landing page here.
      }

      setIsLoading(false);
    };

    checkDataAndProceed();
  }, [currentStep, createdTask, createdSurvey]);

  const renderActiveStep = () => {
    switch (currentStep) {
      case "template":
        return <CreateTemplatePage nextCb={() => setCurrentStep("schedule")} />;
      case "edit":
        return (
          <EditTemplatePage
            taskId={taskId}
            nextCb={() => setCurrentStep("schedule")}
          />
        );
      case "schedule":
        return (
          <ScheduleTemplatePage
            taskId={taskId}
            prevCb={() => {
              // update url to edit
              window.history.pushState(
                {},
                "",
                `/leader/audit/manage/tasks/edit/${taskId}`
              );
              setCurrentStep("edit");
            }}
          />
        );
      default:
        return <CreateTemplatePage nextCb={() => setCurrentStep("schedule")} />; // Default to the first component
    }
  };

  return <>{isLoading ? <LoadingComponent /> : renderActiveStep()}</>;
};

const LoadingContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const LoadingAnimation = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export default CreateSurvey;
