import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import IMG from 'assets/images/stock/ai_image.jpg'
import IMG2 from 'assets/images/stock/blogcard.jpg'
import ResearchModal from './ResearchModal'

const ResearchCard = ({id}) =>{

    return (
        <Card>
            <ImageSection>
                <Pic src={id==1?IMG:IMG2} />
            </ImageSection>
           
           <Title>
           Investigating the antecedents of work disengagement in the workplace
           </Title>

           <Text>
           This research paper investigates the causes of work disengagement in public sector organizations.
           </Text>

          <ResearchModal/>
        </Card>
    )
}



export default ResearchCard

const Card = styled.div`
    width:350px;
    margin-right:40px;

`

const Pic = styled.img`
    width:100%;
    margin-top:-100px;
`

const ImageSection = styled.div`
    width:100%;
    height:250px;
    overflow:hidden;
   
`

const Title = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #2A3039;
margin-top:16px;
line-height:26px;
`

const Text = styled.div`
font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height:20px;
color: #2A3039;
margin-top:8px;
`

