import React from "react";
import { View, Text, Image, StyleSheet, Page,Font } from "@react-pdf/renderer";
import Logo from "assets/images/pdf/smallLogo.png";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
// Create styles
const styles = StyleSheet.create({
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100vw",
    padding: 20,
    paddingHorizontal: 40,
    fontSize: 8,
    zIndex: -1,
    color:"#2A3039",
    fontFamily:'Raleway'
  },
  line: {
    height: 0.2,
    width: "100%",
    backgroundColor: "grey",
    marginBottom: 10,
  },
});

const Footer = () => {
  return (
    <View style={styles.footer} fixed>
      <View style={styles.line} />
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems:'center'
        }}
      >
        <View style={{flexDirection:'row'}}>
        <Image src={Logo} style={{width:15,marginRight:8,marginTop:-4}} />
          <Text style={{fontWeight:600}}>Innerlogic Culture Report and Action Plans</Text>
        </View>
        <Text
          render={({ pageNumber, totalPages }) =>
            `${pageNumber-1} / ${totalPages-1}`
          }
        />
      </View>
    </View>
  );
};

Font.register({
    family: "Raleway",
    fonts: [{ src: Raleway }],
  });
  

export default Footer;
