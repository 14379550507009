 
export const text = `[{
    "type": "t2",
    "text": "Action 1: Encourage Constructive Feedback and Dissent"
  }, {
    "type": "p",
    "text": "Objective: Foster an environment where employees are comfortable providing feedback and engaging in healthy dissent, both agreeing and disagreeing with your ideas."
  }, 
  {
    "type": "t3",
    "text": "Actions:"
  },
  {
    "type": "b",
    "text": "Ask for Feedback: Encourage employees to provide feedback on your leadership style and departmental processes. You can ask, 'What do you think we can do better as a team, and how can I support you more effectively?'"
  }, {
    "type": "b",
    "text": "Promote Disagreement: Make it clear that differing opinions are not only accepted but valued. Ask employees for their perspectives on specific issues and genuinely consider their input. For instance, you could say, 'I'd like to hear your thoughts on this project. Do you see any potential issues or improvements we should consider?'"
  }, {
    "type": "b",
    "text": "Normalize Disagreement: Share stories of successful outcomes that resulted from healthy disagreements within the team. Highlight that constructive dissent can lead to better decision-making and innovation."
  },
  {
    "type": "l",
    "text": "Suggested resource: ",
    "link_text":"How to Get Your Team to Disagree with You More",
    "url": "https://hbr.org/2019/05/how-to-get-your-team-to-disagree-with-you-more"
  },
  {
    "type": "t2",
    "text": "Action 2: Create a Safe Space for Mistakes and Growth"
  }, {
    "type": "p",
    "text": "Objective: Cultivate an environment where employees feel safe admitting mistakes and view them as opportunities for learning and improvement."
  }, {
    "type": "t3",
    "text": "Actions:"
  }, {
    "type": "b",
    "text": "Acknowledge Mistakes: Lead by example by admitting your own mistakes and discussing how they led to personal growth. Ask employees if there's anything they'd like to share about a mistake they've made recently and how they plan to address it."
  }, {
    "type": "b",
    "text": "Discuss Learning: Encourage employees to reflect on their experiences, even when things don't go as planned. Ask questions like, 'What did you learn from this situation?' or 'How can we turn this setback into an opportunity for improvement?'"
  }, {
    "type": "b",
    "text": "Celebrate Growth: Recognize and celebrate when employees take initiative to correct their mistakes or seek personal development. This can be as simple as a sincere 'Thank you for addressing that issue proactively; it shows your commitment to our team's success.'"
  }]`;