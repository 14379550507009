import styled from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";


const RecommendationCard = (props) => {
  const { title, tags, text, pending } = props;
  return (
    <Container>
      <Circle pending={pending} />

      <TextArea>
        <Title>{title}</Title>
        <TimeAgo>Created {text}</TimeAgo>
        {tags?.map((item, i) => {
          return <Tag>{item}</Tag>;
        })}
      </TextArea>
      {pending && (
        <PendingWrapper>
          <Tag pending={true}>Pending</Tag>
        </PendingWrapper>
      )}
    </Container>
  );
};

export default RecommendationCard;

const Container = styled.div`
  display: flex;
  padding-bottom: 40px;
  border-bottom: 1px solid #dfdfdf;
  margin-top: 10px;
  font-kerning: none;
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "ss08" on, "ss07" on, "ss05" on, "ss09" on, "ss02" on,
    "ss01" on, "salt" on, "liga" off;
`;

const Circle = styled.div`
  background: ${(props) => (props.pending ? "#FEA711" : "#27CDA7")};
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  margin-top: 5px;
`;

const TextArea = styled.div``;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #2a3039;
`;

const TimeAgo = styled.div`
  font-family: "Raleway";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #2a3039;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Tag = styled.div`
  background: ${(props) => (props.pending ? "#FEF5E7" : "#E6F7F3")};
  border-radius: 8px;
  padding: 4px 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => (props.pending ? "#FEA711" : "#27CDA7")};
  width: auto;
  display: inline-block;
  margin-right: 8px;
`;

const PendingWrapper = styled.div`
  display: flex;
  align-items: top;
  margin-left: auto;
  height: 100%;
`;