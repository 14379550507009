import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Select, Checkbox } from "semantic-ui-react";

const Progress = ({}) => {
	const [selected, setSelected] = useState(null);

	return (
		<Container>
			<T1>Progress report</T1>
			<Item>
				<Description>
					Do you want progress reports from the people assigned to
					this strategy? If so, how often would you like progress
					reports to be performed?
				</Description>
				<Select
					placeholder="Select timeframe"
					options={anchor_list}
					value={selected}
					onChange={(e, t) => setSelected(t.value)}
				/>
			</Item>
		</Container>
	);
};

export default Progress;

const Container = styled.div`
	width: 100%;
	margin-top: 20px;
	font-family: "Barlow", sans-serif;
`;

const Title = styled.div`
	font-family: "Barlow", sans-serif;
	font-weight: bold;
	font-size: 25px;
	margin-bottom: 50px;
`;

const Description = styled.div`
	font-weight: 300;
	max-width: 600px;
`;

const T1 = styled.div`
	font-family: "Barlow Semi Condensed", sans-serif;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 5px;
`;

const Item = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
`;

const anchor_list = [
	{ value: 0, text: "None" },
	{ value: 1, text: "1 week" },
	{ value: 2, text: "2 weeks" },
	{ value: 3, text: "1 month" },
	{ value: 4, text: "3 month" },
];
