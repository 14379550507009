import {
  ADD_SCHEDULE_TEMPLATE,
  DELETE_SCHEDULE_TEMPLATE,
  UPDATE_SCHEDULE_TEMPLATE,
  GET_PENDING_SHEDULE_REPORTS,
  CLEAR_SCHEDULE_TEMPLATE,
  CLEAR_ALL,
} from "constants/actions";

const initialState = {
  schedule_templates: [],
  pending_schedule_reports: [],
  schedule_templates_error: null,
  pending: false,
  created: false,
};

export default function ScheduleReports(state = initialState, action) {
  switch (action.type) {
    case ADD_SCHEDULE_TEMPLATE:
      const pendingScheduleTemplates = [
        ...state.pending_schedule_reports?.results,
      ];

      // Add the payload to the start of the array
      pendingScheduleTemplates?.unshift(action.payload);
      // ensure the id is only in there once.
      const uniquePendingScheduleTemplates = pendingScheduleTemplates.filter(
        (v, i, a) => a.findIndex((t) => t.id === v.id) === i
      );
      const updatedPending = {
        ...state.pending_schedule_reports,
        results: uniquePendingScheduleTemplates,
      };

      return {
        ...state,
        schedule_templates: action.payload,
        pending: false,
        created: true,
        pending_schedule_reports: updatedPending,
      };

    case CLEAR_SCHEDULE_TEMPLATE:
      return {
        ...state,
        created: false,
      };

    case DELETE_SCHEDULE_TEMPLATE:
      const filteredScheduleTemplates = state.schedule_templates.filter(
        (schedule_template) => schedule_template.id !== action.payload
      );

      // Do the same for the pending schedule reports
      const filteredPendingScheduleTemplates =
        state.pending_schedule_reports.results.filter(
          (schedule_template) => schedule_template.id !== action.payload
        );

      return {
        ...state,
        schedule_templates: filteredScheduleTemplates,
        pending_schedule_reports: {
          ...state.pending_schedule_reports,
          results: filteredPendingScheduleTemplates,
        },
        pending: false,
      };
    case UPDATE_SCHEDULE_TEMPLATE:
      return {
        ...state,
        schedule_templates: action.payload,
        pending: false,
      };
    case GET_PENDING_SHEDULE_REPORTS:
      return {
        ...state,
        pending_schedule_reports: action.payload,
        pending: false,
      };

    case CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
