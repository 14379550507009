import React, { useState } from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { format } from "date-fns";
import RankingTable from "./RankingTable";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  dataTableContainer: {},
  factorTable: {
    display: "flex",
    marginBottom: 20,
    marginTop: 20,
  },
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  factorLabel: {
    fontSize: 11,
    fontFamily: "Raleway",
    marginBottom: 5,
    fontWeight: 600,
  },
  factorDescription: {
    fontSize: 11,
    fontFamily: "Raleway",
    marginBottom: 5,
  },
  descriptionTwo: {
    fontSize: 11,
    fontFamily: "Raleway",
    marginBottom: 5,
  },
  tableWrapper: {
    width: "100%",
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    width: "100%",
    marginTop: 25,
    marginBottom: 10,
  },
  tableRow: {
    width: "100%",
    display: "flex",
    position: "relative",
    borderBottomWidth: 1,
    borderColor: "#DFDFDF",
    backgroundColor: "white",
    paddingBottom: 10,
    paddingTop: 10,
    flexDirection: "row",
  },
  tableColumn: {
    display: "flex",
    height: 45,
  },
  rowHeader: {
    fontWeight: "bold",
    fontSize: 9,
    display: "flex",
  },
  tableData: {
    fontSize: 9,
    flex: 1,
    maxWidth: "100%",
  },
  statusLabel: {
    fontSize: 9,
  },
  dateValue: {
    fontSize: 9,
  },
});

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const factorDescriptions = [
  {
    key: 1,
    text: "Equity",
    description:
      "An equitable organization allocates resources, programs and decision-making in ways that result in similar opportunities and benefits participants. An equity lens recognizes that decisions need to account for unequal barriers to sport and leadership, to ensure that everyone has access to the full range of opportunities to achieve the benefits that come from participating in sport.",
    second:
      "In the table below, indicate a Culture Pillar to target, and describe your team / organizations desired state of equity within your sport context. Please indicate the action and resources needed to reach each desired state. Multiple line items may be submitted.",
  },
  {
    key: 2,
    text: "Inclusion",
    description:
      "is where people have both the feeling and reality of belonging, where they can participate in ways that are meaningful to them, and where the activity contributes to them reaching their full potential.",
    second:
      "In the table below, use the dropdown to select a Culture Pillar to target, and describe your team / organizations desired state of inclusion within your sport context. Please indicate the action and resources needed to reach each desired state. Multiple line items may be submitted.",
  },
  {
    key: 3,
    text: "Accessibility",
    description:
      "in sport can refer to an environment outcome such as, physical space, information, or service that is flexible to accommodate a person’s preferences or needs without adaption.",
    second:
      "In the table below, indicate your target Culture Pillar and describe your team / organizations desired state of accessibility within your sport context. Please indicate the action and resources needed to reach your desired state. Multiple line items may be submitted.",
  },
];
const rankHeaders = ["AV", "A", "C", "SSM", "NSO", "O"];

const EIAComponent = (props) => {
  const { data, id, questions, primary, editLayout } = props;

  return (
    <View style={styles.container}>
      {data?.planData?.[3] && (
        <View style={styles.dataTableContainer}>
          <RankingTable
            title={"QUESTION"}
            headers={rankHeaders}
            data={data?.planData?.[3]}
            isFrench={false}
          />
        </View>
      )}
      {data?.planData[1] &&
        data?.planData[1]
          ?.filter((fa) => factorDescriptions?.find((ft) => ft.text === fa))
          .map((fa, f) => {
            return (
              <View style={styles.factorTable}>
                <View style={styles.descriptionContainer}>
                  <Text style={styles.factorLabel}>
                    {factorDescriptions?.find((ft) => ft.text === fa)?.text}
                    :&nbsp;
                  </Text>
                  <Text style={styles.factorDescription}>
                    {
                      factorDescriptions?.find((ft) => ft.text === fa)
                        ?.description
                    }
                  </Text>
                  <Text style={styles.descriptionTwo}>
                    {factorDescriptions?.find((ft) => ft.text === fa)?.second}
                  </Text>
                </View>
                <View style={styles.tableWrapper}>
                  <View style={styles.tableContainer}>
                    <View style={styles.tableRow}>
                      <View style={styles.tableColumn}>
                        <Text style={[styles.rowHeader, { width: 150 }]}>
                          NSO Desired State
                        </Text>
                      </View>
                      <View style={styles.tableColumn}>
                        <Text style={[styles.rowHeader, { width: 250 }]}>
                          Description of Action
                        </Text>
                      </View>
                      <View style={styles.tableColumn}>
                        <Text style={[styles.rowHeader, { width: 145 }]}>
                          Resource Needed
                        </Text>
                      </View>

                      <View style={[styles.tableColumn]}>
                        <Text
                          style={[
                            styles.rowHeader,
                            { width: 140, justifyContent: "flex-end" },
                          ]}
                        >
                          Lead Personnel
                        </Text>
                      </View>

                      <View style={[styles.tableColumn]}>
                        <Text
                          style={[
                            styles.rowHeader,
                            { width: 100, justifyContent: "flex-end" },
                          ]}
                        >
                          Timeline
                        </Text>
                      </View>

                      <View style={[styles.tableColumn]}>
                        <Text
                          style={[
                            styles.rowHeader,
                            { width: 80, justifyContent: "flex-end" },
                          ]}
                        >
                          Status
                        </Text>
                      </View>
                    </View>

                    {data?.planData[id]?.[f]?.map((r, i) => {
                      const altBackground = i % 2 === 0;
                      return (
                        <View
                          style={[
                            styles.tableRow,
                            {
                              backgroundColor: altBackground
                                ? "#F8FAFF"
                                : "white",
                            },
                          ]}
                        >
                          <View
                            style={[
                              styles.tableColumn,
                              {
                                width: 150,
                              },
                            ]}
                          >
                            <Text style={[styles.tableData, { width: 150 }]}>
                              {r?.[0] || ""}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.tableColumn,
                              {
                                width: 250,
                              },
                            ]}
                          >
                            <Text style={[styles.tableData, { width: 250 }]}>
                              {r?.[1] || ""}
                            </Text>
                          </View>

                          <View
                            style={[
                              styles.tableColumn,
                              {
                                width: 145,
                              },
                            ]}
                          >
                            <Text style={[styles.tableData, { width: 145 }]}>
                              {r?.[2] || ""}
                            </Text>
                          </View>
                          <View
                            style={[
                              styles.tableColumn,
                              {
                                width: 140,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                styles.tableData,
                                {
                                  width: 140,
                                  justifyContent: "flex-end",
                                },
                              ]}
                            >
                              {r?.[3] || ""}
                            </Text>
                          </View>
                          <View style={[styles.tableColumn, { width: 100 }]}>
                            {/* <CalendarIconSmall /> */}
                            <Text style={styles.dateValue}>
                              {format(
                                (r?.[6] && new Date(r?.[4])) || new Date(),
                                "yyyy/MM/dd"
                              )}
                            </Text>
                          </View>
                          <View style={[styles.tableColumn, { width: 80 }]}>
                            <Text
                              style={[styles.tableData, { color: "#2d70e2" }]}
                            >
                              {planStatus?.find((s) => s?.value === r?.[5])
                                ?.text || "Planned"}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            );
          })}
    </View>
  );
};

export default EIAComponent;
