import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { getSecondaryLabel } from "./pdfUtils";

const BarGraph = ({ title, average, data, categories, isFrench }) => {

  return (
    <Container>
      <div>{title}</div>

      <Top>
        <Spacer />
        <Num>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
            return <NumItem>{item}</NumItem>;
          })}
        </Num>
      </Top>

      <Chart>
        <Line left={58.69} />
        <Line left={62.68} />
        <Line left={66.77} />
        <Line left={70.86} />
        <Line left={74.95} />
        <Line left={79.04} />
        <Line left={83.13} />
        <Line left={87.22} />
        <Line left={91.31} />
        <Line left={95.4} />

        <Row>
          <TD width={35} color={"blue"}>
            {isFrench ? 'Moyenne' : 'Average'}
          </TD>
          <TD width={20}>{average}</TD>
          <Bar width={40.9 * ((average * 10) / 100)} color={"blue"} />
        </Row>

        {data.factorResponses.map((item) => {
          if (!item.average) {
            return null;
          }

          const label = getSecondaryLabel(item.id, categories);
          return (
            <Row>
              <TD width={35}>{isFrench ? label?.fr : label?.name}</TD>
              <TD width={20}>{item.average.toFixed(1)}</TD>
              <Bar
                width={40.9 * ((item.average * 10) / 100)}
                color={label?.color}
              />
            </Row>
          );
        })}
      </Chart>
    </Container>
  );
};

export default BarGraph;

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const Top = styled.div`
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #aecbff;
  display: flex;
`;
const Spacer = styled.div`
  width: 54%;
`;

const Num = styled.div`
  width: 45%;
  display: flex;
`;

const NumItem = styled.div`
  width: 9.09%;
  font-size: 10px;
`;

const Chart = styled.div`
  position: relative;
  width: 100%;
`;

const Line = styled.div`
  position: absolute;
  left: ${(props) => props.left + "%"};
  height: 100%;
  width: 1px;
  border-left: 1px dashed grey;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
`;

const TD = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  justify-content: ${(props) => (props.left ? "flex-start" : "center")};
  align-items: center;
  padding-left: ${(props) => (props.left ? "5px" : "center")};
  font-size: 10px;
  border-right: 1px solid #2c3644;
  color: ${(props) => (props.color ? props.color : "black")};
`;

const Bar = styled.div`
  height: 8px;
  opacity: 0.9;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  width: ${(props) => props.width + "%"};
  background-color: ${(props) => props.color};
`;
