import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import {
  Icon,
  Button,
  Modal,
} from "semantic-ui-react";

import Profile from "./Profile";
// import { useNavigate } from "react-router-dom";
import {
  SG_ADD_EMPLOYEE_CATEGORY,
  // SG_VALIDATE_EMPLOYEE,
  SG_REGISTER_EMPLOYEE_CULTURE,
} from "constants/actions";

const EmployeeProfile = (props) => {
  const { trigger, inviteNew, userEmployee,
    setChangesMade
  } = props
  const dispatch = useDispatch();
  // const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [valueUpdated, setValueUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState({
    first_name: '',
    last_name: '',
    email: '',
    enable_login: false,
    enmail_validated: 1,
    survey_status: 9,
    survey_schedule: 0,
    survey_add_users: 0,
    account_type: 9,
    role: 11,
  });

  // enable login and disable login set the rules for this
  // component
  // role info
  // (9, 'Team Member'),
  // # (10, 'Anonymous Access'),
  // (11, 'Profile Access'),
  // (21, 'Survey Access'),

  const [categoryUpload, setCategoryUpload] = useState([]);
  const [categoryUpdated, setCategoryUpdated] = useState(false);
  const [employeeCategory, setEmployeeCategory] = useState([]);
  const [runOnce, setRunOnce] = useState(false)

  const {
    get_employee_category,
    get_employee_created,
    get_org_id } = useSelector(
      (state) => ({
        get_employee_created: state.employees.created,
        get_employee_category: state.employee_category.employee_category,
        get_org_id: state.auth.organization_id,
      }),
      shallowEqual
    );

  // console.log(selectRow?.email)
  // console.log(selectRow?.email?.length)

  function SaveFormData() {
    if (valueUpdated && !errorMessage && selectRow?.email?.length > 3) {
      dispatch({
        type: SG_REGISTER_EMPLOYEE_CULTURE,
        payload: {
          organization: get_org_id,
          ...selectRow
        },
      });
      setRunOnce(true)
      setChangesMade({
        color: "green",
        message: "User was saved"
      })
    } else {
      setChangesMade({
        color: "red",
        message: "No changes were saved"
      })
    }
    setOpen(false)
  }

  useEffect(() => {
    // console.log(get_employee_created)
    if (get_employee_created?.id && runOnce) {
      if (!get_employee_category.filter(ec => ec.employee === get_employee_created?.id)?.[0]) {
        dispatch({
          type: SG_ADD_EMPLOYEE_CATEGORY,
          payload: {
            category: categoryUpload,
            employee: get_employee_created?.id,
          },
        });
      }
      // setOpen(false)
      setRunOnce(false)
      ;
    }
  }, [get_employee_created, get_employee_category, categoryUpload, runOnce, dispatch]);

  function closeModal() {
    setOpen(false)
    setValueUpdated(false)
  }

  return (
    <Modal
      onClose={closeModal}
      onOpen={() => setOpen(true)}
      open={open}
      size={"large"}
      closeIcon
      trigger={trigger}
    >
      <Modal.Header>Invite member</Modal.Header>
      <Modal.Content scrolling>
        <Profile
          // employee={fullEmpData}
          valueUpdated={valueUpdated}
          setValueUpdated={setValueUpdated}
          selectRow={selectRow}
          setSelectRow={setSelectRow}
          categoryUpload={categoryUpload}
          setCategoryUpload={setCategoryUpload}
          categoryUpdated={categoryUpdated}
          setCategoryUpdated={setCategoryUpdated}
          employeeCategory={employeeCategory}
          setEmployeeCategory={setEmployeeCategory}
          inviteNew={inviteNew}
          userEmployee={userEmployee}
          errorMessage={errorMessage} 
          setErrorMessage={setErrorMessage}
          
        />

      </Modal.Content>
      <Modal.Actions>
        <div style={{ marginBottom: "10px", paddingBottom: "20px" }}>
          <Button floated="left"
            onClick={closeModal}
            secondary>
            Cancel <Icon name="close" />
          </Button>
          <Button floated="right" 
          // disabled={!errorMessage}
          onClick={SaveFormData} primary>
            <Icon name="save" /> Save &amp; Close
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default EmployeeProfile;

const Container = styled.div`
  margin-left: 10px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-left: 0;
  }
`;

const Role = styled.div`
  font-size: 12px;
  color: ${(props) => (props.valid ? "#9b9b9b" : "#ff2d55")};
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-family: "Barlow", sans-serif;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Label = styled.div`
  font-size: 16px;
  font-family: "Barlow", sans-serif;
  margin-left: 30px;
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: #6a00ff;
  font-family: "Red Hat Display", sans-serif;
`;

const StyleButton = styled.button`
  height: 45px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) => (props.disabled ? "#606060" : "#6a00ff")};
  disabled: ${(props) => (props.disabled ? true : false)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px 7px 24px;
  border: none;
  outline: none;
  margin-top: 20px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Barlow", sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 0px 200px 0px;
    width: 100%;
  }
`;

const FormSection1 = styled.div`
  margin-right: 60px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const FormSection = styled.div`
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
