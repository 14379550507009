import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';

import SideMenu from './SideMenu'
import Stories from './Stories'

const Tasks = () => {
    const [selected,setSelected] = useState(0)
    

    const renderContent = () =>{
        if(selected == 1){
            return <Stories/>
        }
       

        return (
            <>
            <Title>
                    Tasks
                </Title>

                <Description>
                    We've organized your tasks so that you know exactly what you need to do to build a great culture.
                </Description>

                <T2>Pending Tasks</T2>

                <TaskCard 
                    title="Understand your departments biggest themes" 
                    description="Your data story is a summary of your team's feedback."
                    linkText="Click here to view your stories"
                    link="You have 1 unread story"
                    view={true}/>

                <TaskCard 
                    title="Build an action plan" 
                    description="Lets personalize a plan that is completely for you."
                    link="Start your change journey here"
                    linkText="Click here to begin"
                    url='/app/prototype/contextonboarding'
                    view={true}/>

                <TaskCard 
                    title="View your personalized plan" 
                    description="Start to action your plan and start to see culture growth."
                    link="Check out your personalized plan"
                    linkText="Click here to view"
                    url='/app/prototype/datajourney'
                    view={false}/>
                </>
        )
    }

    return (
        <Container>
            <Center>

                <SideMenu selected={selected} setSelected={setSelected}/>

                {renderContent()}
                

            </Center>
        </Container>
    )

}

export default Tasks;

const TaskCard = ({title,description,link,linkText,view,completed,url}) =>{
    const navigate = useNavigate()
    return (
        <CardContainer view={view}>
        <Card  link={link} completed={completed}>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>

        
        </Card>

        {link && <CardBottom>
            {link} - <Link onClick={()=>navigate(url)}>{linkText}</Link>
        </CardBottom>}

        </CardContainer>
    )
}


const Container = styled.div`
    display:flex;
    justify-content:center;
    font-family:'raleway';
    margin-top:30px;

`

const Center = styled.div`
   width:800px;
   position:relative;
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-bottom:10px;
`

const Description = styled.div`

`

const T2 = styled.div`
    font-size:18px;
    font-weight:bold;
    margin-top:30px;
    margin-bottom:20px;
`

const Card = styled.div`
    background-color:white;
    box-shadow:${props=>props.completed ? 'none' : '0px 0px 10px 0px rgba(0,0,0,0.1)'};
    border-radius:5px;
    padding:20px;
    position:relative;
    border:${props=>props.completed ? '2px solid #27CDA7' : 'none'};
    width:100%;

    
`

const CardTitle = styled.div`
    font-size:15px;
    font-weight:bold;
    margin-bottom:10px;
`
const CardDescription = styled.div`
    line-height:1.5;
    font-size:13px;
`

const CardContainer = styled.div`
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    align-items:center;

    opacity:${props=>!props.view ? 0.5 : 1};
`

const CardBottom = styled.div`
    height:40px;
    padding:10px;
    border-radius:0px 0px 5px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width:95%;
    background-color:#F8FAFF;
    font-size:12px;
    font-weight:bold;
`

const Link = styled.div`
    color:#2D70E2;
    cursor:pointer;
    font-weight:bold;
    font-size:12px;
    display:inline;
    margin-left:5px;
`