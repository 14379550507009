import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    width: "100%",
    marginTop: 10,
    marginBottom: 50,
    borderCollapse: "collapse",
  },
  tableRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 12,
    display: "flex",
    width: "50%",
    padding: 3,
  },
  tableCellText: {
    fontSize: 10,
    paddingBottom: 8,
  },
  middleCellText: {
    fontSize: 10,
    paddingRight: 5,
    paddingBottom: 8,
  },
  tableCell: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    fontSize: 10,
  },
  middleTableRow: {
    minHeight: 80,
    paddingLeft: 5,
    paddingTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  selectedOption: {
    fontSize: 10,
    color: "#2d70e2",
    fontWeight: 600,
  },
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f8faff",
    minHeight: 50,
    paddingBottom: 8,
  },
  commentText: {
    fontSize: 10,
    paddingBottom: 8,
  },
  firstCell: {
    fontSize: 10,
    paddingLeft: 5,
    paddingTop: 10,
  },
  commentPlaceholder: {
    fontSize: 10,
    paddingHorizontal: 5,
    color: "#000",
    fontWeight: 600,
  },
  section: {
    marginBottom: 10,
    borderTopWidth: 0.5,
    borderBottomWidth: 0.5,
    borderColor: "#000",
    borderStyle: "solid",
  },
});

const MultiDataTable = (props) => {
  const { data, rowsections: rowSections, headers, id } = props;
  const [rows, setRows] = React.useState([]);
  const dropdownOptions = ["Low", "Moderate", "High"];

  React.useEffect(() => {
    if (data) {
      setRows((data?.planData[id]?.length > 0 && data?.planData[id]) || [{}]);
    }
  }, [data]);

  return (
    <View style={styles.container}>
      <View style={styles.tableRow}>
        {headers.map((header) => (
          <Text style={styles.tableHeader}>{header}</Text>
        ))}
      </View>
      {rowSections.map((section, i) => {
        return (
          <View style={styles.section}>
            <View
              style={styles.tableRow}
              //   break={i > 0 && true}
            >
              <View style={styles.tableCell}>
                <Text style={styles.firstCell}>{section?.firstcolumn}</Text>
              </View>
              <View style={styles.tableCell}>
                {section?.middlecolumns?.map((m, idx) => {
                  const middleRowAltBackground = idx % 2 === 0;
                  return (
                    <View
                      style={[
                        styles.middleTableRow,
                        {
                          backgroundColor: middleRowAltBackground
                            ? "#F8FAFF"
                            : "#fff",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={[styles.middleCellText]}>{m}</Text>
                    </View>
                  );
                })}
              </View>
              <View style={styles.tableCell}>
                {section?.middlecolumns?.map((m, idx) => {
                  const middleRowAltBackground = idx % 2 === 0;

                  return (
                    <View
                      style={[
                        styles.middleTableRow,
                        {
                          backgroundColor: middleRowAltBackground
                            ? "#F8FAFF"
                            : "#fff",
                        },
                      ]}
                      wrap={false}
                    >
                      <Text style={styles.selectedOption}>
                        {rows[i]?.selections?.[idx] || dropdownOptions[0]}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
            <View style={styles.commentContainer} wrap={false}>
              <Text style={styles.commentPlaceholder}>Comments</Text>
              <Text style={styles.commentText}>{rows[i]?.input || ""}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default MultiDataTable;
