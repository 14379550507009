const endpoint = "https://api.openai.com/v1/chat/completions";  // Use the appropriate endpoint for your desired model.

export default async function make_call(prompt) {
    const response = await fetch(endpoint, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer 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`
        },
        body: JSON.stringify({
            messages: [
                { role: "system", content: "You are an assistant designed to build valid JSON responses only, that are focused on building a pulse survey for the user." },
                { role: "user", content: prompt }
            ],
            model: 'gpt-4o',
            stream: false // Disable streaming
        })
    });

    const result = await response.json();
    return result.choices[0].message.content.trim();
}
