import React from 'react';
import styled from "styled-components";
import { Icon, List, Button } from 'semantic-ui-react'

const PaymentMethods = ({ paymentData, defaultPayment,
    DeletePaymentMethod, FetchStripePayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod }) => {
    // console.log(selectedPaymentMethod)
    // SG_DELETE_PAYMENT_METHOD

    return (
        <>
            <List>
                {paymentData?.map((p, i) => {
                    return (

                        <Container key={i}>
                            <SubContainer>
                                {p.id === defaultPayment ?
                                    <Icon size='big' color='green' name='check circle' />
                                    :
                                    <Icon
                                        name="trash alternate outline"
                                        onClick={() => DeletePaymentMethod(p.id)}
                                        color="red"
                                        style={{ cursor: "pointer" }}
                                        size="large"
                                    />
                                }

                            </SubContainer>
                            <Icon size='big' name={p.card_brand === "visa" ? 'cc visa' : 'cc mastercard'} />


                            <SubContainer>
                                <BoxContainer>
                                    <div>
                                        {p.card_type === "debit" ? 'Debit' : 'Credit'} Card **** **** **** {p.last_4_digits}
                                    </div>
                                    <div>
                                        {p.exp_month} /
                                        {p.exp_year}
                                    </div>
                                </BoxContainer>

                            </SubContainer>
                            <SubContainer>
                                <div>
                                    <Button
                                    color={selectedPaymentMethod.id === p.id? "green" : "orange"}
                                        onClick={() => setSelectedPaymentMethod(p)}>
                                            Select
                                        </Button>
                                </div>
                            </SubContainer>
                            
                        </Container>

                    )
                })}

            </List>
        </>
    );
};

export default PaymentMethods;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1200px;
  justify-content: space-between;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(179, 179, 179, 0.5);
  padding: 36px;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 100px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
