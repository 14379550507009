import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Table } from 'semantic-ui-react'



const ChatTable = ({data}) => {

    if (!data || data.length === 0) return null;

    const headers = Object.keys(data[0]);

    const checkNum = (val) =>{
        if(typeof val === 'number'){
            return val.toFixed(2)
        }
        return val
    }

    return (
        <Container>
             <Title>Figure 2: Departmental Culture Scores.</Title>
            <Description>
                This table encapsulates the trust scores attributed to various departments and functional.
            </Description>

            <Table celled>
                <Table.Header>
                    <Table.Row>
                    {headers.map(header => (
                        <Table.HeaderCell key={header}>{header}</Table.HeaderCell>
                    ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((item, rowIndex) => (
                    <Table.Row key={rowIndex}>
                        {headers.map(header => (
                        <Table.Cell key={header}>{checkNum(item[header])}</Table.Cell>
                        ))}
                    </Table.Row>
                    ))}
                </Table.Body>
                </Table>
        </Container>
    )
}


export default ChatTable;

const Container = styled.div`
    margin-top: 80px;
    width:800px;
    font-family: Raleway;
    overflow-x: scroll;
    margin-bottom:60px;

`

const Title = styled.div`
color:#2A3039;
font-family: Raleway;
font-size: 20px;
font-weight:600;
margin-bottom:10px;
`

const Description = styled.div`
font-family: Raleway;
font-size: 14px;
color: #666D79;
`
