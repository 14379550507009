import React, { useEffect, useState, useLayoutEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import {
  grandientsAboveNorm,
  dataLabels,
  defaultColorGradient,
  orgColorGradient,
  legendOptions,
  barChartOptions,
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subsubtext,
  Subtext,
} from "reports/DataStories/common/styles";
import { useSelector } from "react-redux";

const options = {
  onDatasetHover: {
    highlightDataSeries: false,
  },
  chart: barChartOptions,
  legend: {
    position: "top",
    horizontalAlign: "left",
    show: false,
    markers: {
      fillColors: ["#468ADE", "#E6E6E6"],
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
    },
  },
  dataLabels: dataLabels,
  colors: ["#2D70E2", "#C7CFDB", "#E6E6E6"],
  fill: {
    type: ["gradient", "solid"],
    gradient: {
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: ["#40B7C2", "#468ADE"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
      colorStops: [grandientsAboveNorm, orgColorGradient, defaultColorGradient],
    },
  },
  xaxis: {
    categories: ["Your Team", "Your Organization"],
    tickPlacement: "on",
    labels: {
      style: {
        fontSize: "9px",
      },
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    forceNiceScale: true,
    decimalsInFloat: 0,
    title: {
      text: "Participation rate",
      style: {
        fontWeight: 400,
      },
    },
  },

  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
};

const series = [
  {
    name: "Scores",
    data: [
      33.33333333333333, 33.734939759036145, 46.15384615384615, 0,
      46.666666666666664,
    ],
  },
];

const SurveyParticipation = (props) => {
  const { calculatedData, orgName } = props;
  const { get_auth } = useSelector((state) => ({
    get_auth: state.auth,
  }));
  // Create a new array of objects containing the data for the chart and the associated labels at the same index of the array
  const mappedData = calculatedData.chart_data.data.map((value, index) => {
    return {
      label: calculatedData.chart_data.labels[index],
      value: value,
    };
  });

  // Create a new array of labels and data from the sorted array of objects
  const sortedValues = mappedData.map((item) => item.value);

  if (sortedValues.length < 3) {
    sortedValues.push(0);
  }

  if (sortedValues[sortedValues.length - 1] === 0) {
    options.dataLabels = {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff", "#fff", "#000921"],
      },
      formatter: function (val, opt) {
        if (val === 0) {
          return "N/A";
        }
        return val + "%";
      },
      offsetX: -10,
      offsetY: 15,
      dropShadow: {
        enabled: false,
      },
    };
  }
  options.xaxis.categories = [
    calculatedData.chart_data.labels[0],
    `Organization average`,
    `${calculatedData.chart_data.labels[0]} long term average`,
  ];
  series[0].data = sortedValues;
  const text = calculatedData.text;
  const boldedText = text.match(
    /(The survey)(.*). (\d+ out of \d+ contributed to these results.)/
  );

  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={1}>
        Participation Analysis
      </ScreenTitleExtended>
      <TextWrapper>
        <SubtextExtended delay={1.3}>
          {boldedText[1]}
          <BoldText>{boldedText[2]}</BoldText>.&nbsp;
          {boldedText[3]}
        </SubtextExtended>
      </TextWrapper>
      <TextWrapper>
        <SubsubtextExtended delay={1.4}>{}</SubsubtextExtended>
      </TextWrapper>
      <EngagementChartContainer delay={1.6}>
        <Chart
          width={"100%"}
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </EngagementChartContainer>
    </ScreenContainer>
  );
};

export default SurveyParticipation;

const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubsubtextExtended = styled(Subsubtext)`
animation: ${fadeIn} 1s ease-out forwards;
animation-delay: ${({ delay }) => delay}s;
`;


const BoldText = styled.span`
  font-weight: 600;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const EngagementChartContainer = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

