import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import {Icon} from 'semantic-ui-react'

const activities = [
    {
        icon:"eye",
        text:"Email reminder viewed",
        date:"Jan 16th, 2021 - 10:12am"
    },
    {
        icon:"mail outline",
        text:"Email reminder sent",
        date:"Jan 14th, 2021 - 10:12am"
    },
    {
        icon:"pin",
        text:"Set action plan milestones",
        date:"Jan 2nd, 2021 - 10:12am"
    },
    {
        icon:"eye",
        text:"Viewed action plan draft",
        date:"Jan 2nd, 2021 - 10:12am"
    },
    {
        icon:"flag checkered",
        text:"Completed data story",
        date:"Jan 2nd, 2021 - 10:12am"
    },
    {
        icon:"eye",
        text:"Viewed data story",
        date:"Jan 2nd, 2021 - 10:12am"
    },
    {
        icon:"check",
        text:"Created account.",
        date:"Jan 1, 2021 - 10:12am"
    }   
   
]

const Activity = () =>{

    return (
        <Container>

            {
                activities.map((activity, index)=>{
                    return (
                        <ActivityItem key={index}>
                            <Icon name={activity.icon} style={{fontSize:18,color:"#B3B3B3"}}/>
                            <Text>{activity.text}</Text>
                            <Date>{activity.date}</Date>
                            {index+1!=activities.length?<Line/>:""}
                        </ActivityItem>
                    )
                })
            }
        </Container> 
    )

}

export default Activity

const Container = styled.div`
    width:100%;
    margin-top:20px;
`

const ActivityItem = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:50px;
    font-size:20px;
    font-family:'Raleway';
    position:relative;
`

const Text = styled.div`
    margin-left:20px;
`

const Date = styled.div`
    margin-left:20px;
    color:#ccc;
    font-size:14px;
`

const Line = styled.div`
    height:40px;
    width:1px;
    background-color:#ccc;
    position:absolute;
    left:10px;
    bottom:-46px;
`

const Title = styled.div`
    font-size:18px;
    font-weight:600;
    font-family:'Raleway';
    margin-bottom:40px;
`