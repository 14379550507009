import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch,useSelector,shallowEqual } from "react-redux";
import {Icon,Radio} from 'semantic-ui-react';
import ClickAwayListener from "react-click-away-listener";
import {
    SET_NEW_NAVIGATION
  } from "constants/actions";
import Filters from "./Filters";
  

const Navigation = ({ outcomes, core_data }) => {
  const dispatch = useDispatch();
  const navigation = useSelector(
    (state) => state.audit?.new_navigation,
    shallowEqual
  );
  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);
  const [options4, setOptions4] = useState([]);
  const [isOutcome, setIsOutcome] = useState(false);
  const [factorView, setFactorView] = useState(false);

  const { outcome, category, sub_category, factor } = navigation;

  // Generate the dropdown options based on outcomes and categories
  const generate_outcome_options = () => {
    let _options = [{ name: "Culture", id: 0 }];
    outcomes?.questions.map((question) => {
      _options.push({
        name: question.name,
        id: question.id,
        type: "outcome",
      });
    });
    return _options;
  };

  const generate_category_options = () => {
    let _options = [];
    core_data?.categories?.[0]?.categories?.map((category) => {
      _options.push({
        name: category.name,
        id: category.id,
        type: "category",
        priority: category.priority,
      });
    });

    // Order the options so that the priority with the label primary is at the top
    _options = _options.sort((a, b) => {
      if (a.priority === "primary") {
        return -1;
      }
      if (b.priority === "primary") {
        return 1;
      }
      return 0;
    });

    return _options;
  };

  const generate_category_sub_options = () => {
    let _options = [
      { name: "Overview", id: null, type: "cat_option" },
      { name: "Factor overview", id: 1, type: "cat_option" },
    ];
    core_data?.categories?.[0]?.categories
      .find((f) => f.id === category.id)
      ?.options.map((sub_category) => {
        _options.push({
          name: sub_category.name,
          id: sub_category.id,
          type: "cat_option",
        });
      });
    return _options;
  };

  const generate_factor_options = () => {
    let _options = [{ name: "Overview", id: null, type: "factor" }];
    core_data?.questions?.dimensions.map((dimension, d) => {
      dimension.factors.map((factor) => {
        _options.push({
          name: factor.title,
          id: factor.id,
          type: "factor",
          dimension: d,
        });
      });
    });
    return _options;
  };

  useEffect(() => {
    setOptions1(generate_outcome_options());
  }, [outcomes]);

  useEffect(() => {
    setOptions2(generate_category_options());
  }, [core_data]);

  useEffect(() => {
    if (category) {
      setOptions3(generate_category_sub_options());
      updateSelection({ ...navigation, factor: null, sub_category: null });
    }
  }, [category]);

  useEffect(() => {
    if (sub_category?.name == "Overview") {
      setOptions4([]);
      updateSelection({ ...navigation, factor: null });
      if (factorView) {
        setFactorView(false);
      }
    } else {
      if (!factorView) {
        setFactorView(true);
      }
    }
  }, [sub_category]);

  useEffect(() => {
    if (factor && options4.length === 0) {
      setOptions4(generate_factor_options());
    }

    if (factor?.name == "Overview") {
      setOptions4([]);
    }
  }, [factor]);

  useEffect(() => {
    if (outcome?.name !== "Culture") {
      setIsOutcome(true);
      updateSelection({ ...navigation, factor: null, sub_category: null });
    } else {
      setIsOutcome(false);
    }
  }, [outcome]);

  const selectFactor = (value) => {
    setFactorView(value);

    if (!value) {
      updateSelection({
        ...navigation,
        factor: null,
        sub_category: { name: "Overview", id: null, type: "cat_option" },
      });
    } else {
      updateSelection({
        ...navigation,
        sub_category: { name: "Factor overview", id: 1, type: "cat_option" },
      });
    }
  };

  const goBack = () => {
    if (navigation?.outcome?.type === "outcome") {
      if (navigation?.category?.priority === "primary") {
        return updateSelection({
          selected1: null,
          selected2: null,
          selected3: null,
          factor: null,
          outcome: {
            name: "Culture",
            id: 0,
          },
        });
      }

      if (navigation?.category?.priority !== "primary") {
        // Get the primary category information
        let primary = core_data?.categories?.[0]?.categories.find(
          (f) => f.priority === "primary"
        );

        return updateSelection({
          ...navigation,
          category: {
            name: primary.name,
            id: primary.id,
            type: "category",
            priority: primary.priority,
          },
        });
      }
    } else if (options4.length > 0) {
      updateSelection({ ...navigation, factor: null });
      return setOptions4([]);
    }
    if (factorView) {
      selectFactor(false);
    } else if (sub_category?.name == "Factor overview") {
      updateSelection({
        ...navigation,
        sub_category: { name: "Overview", id: null, type: "cat_option" },
      });
    } else if (sub_category?.name == "Overview") {
      updateSelection({ ...navigation, category: null });
    }
  };
  // Push out the selected dropdown options to the redux store

  // Action creators (assuming these are properly defined elsewhere)
  const updateSelection = (selection) =>
    dispatch({
      type: SET_NEW_NAVIGATION,
      payload: selection,
    });

  return (
    <>
      <Row>
        <Container>
          <Dropdown
            options={options1}
            selected={outcome}
            label={"Data view"}
            setSelected={(option) =>
              updateSelection({ ...navigation, outcome: option })
            }
          />

          <Dropdown
            options={options2}
            selected={category}
            label={"Category view"}
            setSelected={(option) =>
              updateSelection({ ...navigation, category: option })
            }
          />

          {!isOutcome && (
            <>
              <Dropdown
                options={options3}
                selected={sub_category}
                label={"Selected category"}
                setSelected={(option) =>
                  updateSelection({ ...navigation, sub_category: option })
                }
              />
            </>
          )}
          {options4.length > 0 && (
            <>
              <Dropdown
                options={options4}
                selected={factor}
                label={"Selected Factor"}
                style={{fontSize:12}}
                setSelected={(option) =>
                  updateSelection({ ...navigation, factor: option })
                }
              />
            </>
          )}
        </Container>
        {!isOutcome && (
          <Radios>
            {/* <Radio
            label='Category Comparison'
            name='radioGroup'
            value={1}
            checked={factorView == false}
            onChange={()=>selectFactor(false)}
            style={{marginRight:10}}
          /> */}
            <Radio
              label="Factor Breakdown"
              name="radioGroup"
              value={2}
              toggle
              checked={factorView == true}
              onChange={() => selectFactor(!factorView)}
            />
          </Radios>
        )}
      </Row>
      <Row2 align={sub_category?.name != "Overview"}>
        {sub_category?.name != "Overview" && (
          <BackButton onClick={() => goBack()}>
            <Icon name="chevron left" style={{ marginTop: -7 }} />
            Back
          </BackButton>
        )}
        <Filters />
      </Row2>
    </>
  );
};

export default Navigation


const Dropdown = ({options,selected,setSelected,label}) =>{

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);


    useEffect(()=>{
        if(!selected){
            setSelected(options[0])
        }
    },[options])

    const selectOption = (option) =>{
        setOpen(false)
        setSelected(option)
    }



    return (
      <Box>
      <Label>{label}</Label>
      <ClickAwayListener onClickAway={()=>setOpen(false)}>
            <DropdownContainer onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
                <Nav onClick={()=>setOpen(!open)}>
                    <Selection>{selected?.name}</Selection>
                    <Icon name="triangle down" style={{marginTop:-4,color:'#B3B3B3'}}/>
                </Nav>
                {open && <DropdownContent>
                    {options.map((option,i)=><Option key={i} onClick={()=>selectOption(option)}>{option.name}</Option>)}
                </DropdownContent>}
            </DropdownContainer>
        </ClickAwayListener>
      </Box>
        
    )
}


const Container = styled.div`
    display:flex;
    font-family:'raleway';
    margin-bottom:20px;
`

const DropdownContainer = styled.div`
    position:relative;
    border:1px solid lightgrey;
    padding:5px;
`

const Nav = styled.div`
    display:flex;
    align-items:center;
    cursor:pointer;
`

const Selection = styled.div`
    font-size:10px;
    color:#2A3039;
    margin-right:5px;
`

const DropdownContent = styled.div`
    position:absolute;
    top:40px;
    left:0;
    background-color:white;
    box-shadow:0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius:5px;
    z-index:100;
    width:200px;
`

const Option = styled.div`
    padding:10px 20px;
    font-size:14px;
    color:#2A3039;
    cursor:pointer;

    &:hover{
        background-color:#F5F5F5;
    }
`

const Slash = styled.div`
    font-size:20px;
    color:#2A3039;
    margin-right:10px;
    color:#DFDFDF;
`

const Label = styled.div`
    font-size:12px;
    color:#2A3039;
    margin-right:10px;
`

const Box = styled.div`
    display:flex;
    flex-direction:column;
    margin-right:10px;
`

const Radios = styled.div`
    display:flex;
    margin-bottom:10px;

`
const Row = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:0px;
`

const BackButton = styled.div`
    font-size:14px;
    color:#B3B3B3;
    cursor:pointer;
    display:flex;
    align-items:center;
    font-size:12px;
`

const Row2 = styled.div`
    display:flex;
    justify-content:${props=>props.align?'space-between':'flex-end'};
    align-items:center;
`