import styled, { keyframes } from "styled-components";
import React from "react";

import IMG1 from "assets/images/stock/person1.jpg";
import IMG2 from "assets/images/stock/person2.jpg";
import IMG3 from "assets/images/stock/person3.jpg";
import LogoIMG from "assets/images/logo_full.png";

const GenericIntroduction = () => {
  return (
    <Container>
      <T1>Welcome to your data story</T1>
      <Text delay={0.5}>
        Data stories are designed to help you understand your people data and
        how it relates to your department's goals.
      </Text>

      <ImageArea>
        <Image src={IMG1} delay={1} />
        <Image src={IMG2} top={70} delay={1.2} />
        <Image src={IMG3} top={30} delay={1.4} />

        <Card delay={2}>
          <Stat>500 + stories</Stat>
          <T2>
            Hundreds of stories have been told to help leaders understand their
            people data.
          </T2>
        </Card>
      </ImageArea>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Logo delay={2.5}>
          Powered by{" "}
          <img src={LogoIMG} style={{ width: 100, marginLeft: 10 }} />
        </Logo>
      </div>
    </Container>
  );
};

export default GenericIntroduction;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  font-family: "Raleway";
`;

const Text = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 40px;
  margin-top: 10px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const T1 = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const ImageArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: center;
  position: relative;
`;

const Image = styled.img`
  width: 150px;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: ${(props) => props.top}px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #dfdfdf;
  padding: 10px;
  border-radius: 10px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Card = styled.div`
  position: absolute;
  bottom: -50px;
  width: 300px;
  background-color: #2a3039;
  color: white;
  padding: 20px;
  border-radius: 10px;

  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const Stat = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
`;

const T2 = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
