import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Loader, Select } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { median } from "simple-statistics";
import ChartLoading from "reports/Audit/ChartLoading";
import noData from "assets/images/empty/empty.png";
import CategorizedDropdown from "./CategorizedDropdown";

// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import LegendIcon from "./LegendIcon";

const create_option_list = (categories, questions, type) => {
  let options = [];
  categories.categories.forEach((item, i) => {
    if (
      type === 1 ||
      (type === 2 && item.priority !== "primary") ||
      type === 3
    ) {
      if (type === 3) {
        options.push({
          text: item.name,
          value: { text: item, type: "categories", id: i },
        });
      } else {
        options.push({ text: item.name, value: item, type: "categories" });
      }
    }
  });

  if (type === 2) {
    let dimensions = questions.dimensions.length;
    if (dimensions !== 1) {
      questions.dimensions[1].priority = "questions";
      options.push({
        text: questions.dimensions[1].title,
        value: questions.dimensions[1],
        type: "culture",
      });
    }

    questions.dimensions[0].priority = "questions";
    options.push({
      text: questions.dimensions[0].title,
      value: questions.dimensions[0],
      type: "culture",
    });
  }

  if (type !== 3) {
    options.push({
      text: "Personality",
      value: {
        type: "personality",
        id: options.length + 1,
        text: "Personality",
      },
    });
  }

  return options;
};

const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id]?.factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;
  return response;
};

const calculateCategoryAvg = (vertical, data, target, outcome, questions) => {
  let responsesByVertical;

  if (vertical?.type === "categories") {
    responsesByVertical = vertical.text.options.map((vo) => {
      const resp = data.filter((fg) => {
        return fg.categories.find((cr) => cr.response === vo.id);
      });
      return { group: vo.id, data: resp };
    });
  }

  const results = responsesByVertical.map((rv) => {
    let targetVal;
    if (target?.type === "personality") {
      let targetArr = [];
      rv.data.forEach((dr) => {
        console.log(dr, target);
        targetArr.push(
          calculate_personality(dr.survey_personality, target?.id)
        );
      });
      let sum = 0;
      targetArr.forEach((v) => {
        if (v) {
          sum += v;
        }
      });
      targetVal = sum / targetArr.length;
    } else {
      targetVal =
        (rv.data.filter((dr) => {
          return dr.categories.find((cr) => cr.response === target);
        }).length /
          rv.data.length) *
        100;
    }

    const outcomeArr = [];
    rv.data.forEach((dr) => {
      // Check which area we will be calculating for the outcome.
      if (outcome.type === "factors") {
        const outcomeResp = dr.questions.filter((q) => {
          return q.factor === outcome?.id - 1;
        });

        outcomeResp.forEach((o) => {
          const val = check_reverse_score(o, questions);
          outcomeArr.push(val);
        });
      } else if (outcome.type === "personality") {
        const outcomeResp = calculate_personality(
          dr.survey_personality,
          outcome?.id
        );
        outcomeArr.push(outcomeResp);
      } else if (outcome.type === "records") {
        const val = dr.employee_records.find((f) => f.id === outcome.id)?.value;
        if (val) {
          outcomeArr.push(val);
        }
      }
    });

    let sum = 0;
    outcomeArr.forEach((v) => {
      if (v) {
        sum += v;
      }
    });

    const outcomeAvg = (sum / outcomeArr.length) * 10;

    return {
      group: rv.group,
      target: targetVal,
      outcome: outcomeAvg,
    };
  });

  console.log(results);

  return results;
};

const chunkArray = (arr, n) => {
  let chunkLength = Math.max(arr.length / n, 1);
  let chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
};

const organizeData = (primaryResults, usePerc) => {
  const multiplier = usePerc ? 10 : 1;
  const textRes = primaryResults.map((r) => {
    return [r.target, (Math.round(r.outcome * 10) * multiplier) / 10];
  });

  const res = primaryResults.map((r) => {
    return (Math.round(r.outcome * 10) * multiplier) / 10;
  });

  const sorted = res.sort(function (a, b) {
    return a - b;
  });
  const arrays = chunkArray(sorted, 2);
  return { chartData: arrays, textData: textRes };
};

let reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return Math.round(((score1 + score2) / 2) * 100) / 100;
};

let personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const DataScience = ({ data, records }) => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState();

  const {
    get_organizations,
    get_survey_questions,
    get_survey_structure,
    get_culture_audit_reports,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  } = useGetSurveyData({ surveyType: 6, auditTypeId: 3 });

  const { questionStructure } = useGetSurveyUtils({
    get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    get_auth,
    get_selectedOrg,
    ambassadorId,
    get_employee,
  });

  const [horizontal, setHorizontal] = React.useState();
  const [vertical, setVertical] = React.useState();
  const [unitList, setUnitList] = React.useState();
  const [anchor_list, setAnchorList] = React.useState();
  const [anchor_list2, setAnchorList2] = React.useState();
  const [targetValue, setTargetValue] = React.useState();
  const [outcomeVariable, setOutcomeVariable] = React.useState();
  const [targetList, setTargetList] = React.useState();
  const [outcomeList, setOutcomeList] = React.useState();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (horizontal?.options) {
      const options = horizontal.options.map((op) => {
        return { text: op.name, value: op.id };
      });
      setTargetList(options);
    } else {
      let personality_factors = [
        "Extraversion",
        "Conscientiousness",
        "Agreeableness",
        "Neuroticism",
        "Openess",
      ];

      const options = personality_factors.map((f, i) => {
        return {
          text: f,
          value: { type: "personality", id: i, text: f },
        };
      });

      setTargetList(options);
    }
  }, [horizontal]);

  useEffect(() => {
    if (
      get_survey_structure[0] &&
      questionStructure &&
      !anchor_list &&
      !anchor_list2
    ) {
      const anchor_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        1,
        records
      );

      const unit_list = create_option_list(
        get_survey_structure[0],
        questionStructure,
        3,
        records
      );
      setUnitList(unit_list);

      setAnchorList(anchor_list);
      const anchor_list2 = create_option_list(
        get_survey_structure[0],
        questionStructure,
        2,
        records
      );

      const outcomes = anchor_list2
        .filter(
          (v) => v?.text === "People Factors" || v?.text === "Human Factors"
        )?.[0]
        ?.value?.factors?.map((f) => {
          return {
            text: f.title,
            value: { type: "factors", id: f.id, text: f.title },
          };
        });

      //Add the personality factors to the list
      let personality_factors = [
        "Extraversion",
        "Conscientiousness",
        "Agreeableness",
        "Neuroticism",
        "Openess",
      ];

      personality_factors.forEach((f, i) => {
        outcomes.push({
          text: f,
          value: { type: "personality", id: i, text: f },
        });
      });

      records.forEach((item, i) => {
        outcomes?.push({
          text: item.name,
          value: { type: "records", id: item.id, text: item.name },
        });
      });

      setOutcomeList(outcomes);

      setAnchorList2(anchor_list2.filter((v) => v?.text !== "People Factors"));
      if (anchor_list) {
        setHorizontal(
          anchor_list.find((f) => f.value.priority === "secondary").value
        );
        setVertical(
          anchor_list.find((f) => f.value.priority === "primary").value
        );
      }
    }
  }, [get_survey_structure[0], questionStructure]);

  const retrieveBoxPlotCalculations = (
    vertical,
    target,
    outcome,
    data,
    questions
  ) => {
    try {
      const primaryResults = calculateCategoryAvg(
        vertical,
        data,
        target,
        outcome,
        questions
      );

      const newObj = organizeData(primaryResults);

      const quartileOneMedian = median(newObj.chartData[0]);
      const quartileOneQ1 = median(
        newObj.chartData[0].slice(0, Math.ceil(newObj.chartData[0].length / 2))
      );
      const quartileOneQ2 = median(
        newObj.chartData[0].slice(
          Math.floor(newObj.chartData[0].length / 2),
          newObj.chartData[0].length
        )
      );

      const quartileOneLow = newObj.chartData[0][0];
      const quartileOneHigh =
        newObj.chartData[0][newObj.chartData[0].length - 1];
      const seriesOne = [
        quartileOneLow,
        quartileOneQ1,
        quartileOneMedian,
        quartileOneQ2,
        quartileOneHigh,
      ];

      const quartileTwoMedian = median(newObj.chartData[1]);
      const quartileTwoQ1 = median(
        newObj.chartData[1].slice(0, Math.ceil(newObj.chartData[1].length / 2))
      );
      const quartileTwoQ3 = median(
        newObj.chartData[1].slice(
          Math.floor(newObj.chartData[1].length / 2),
          newObj.chartData[1].length
        )
      );

      const quartileTwoLow = newObj.chartData[1][0];
      const quartileTwoHigh =
        newObj.chartData[1][newObj.chartData[1].length - 1];
      const seriesTwo = [
        quartileTwoLow,
        quartileTwoQ1,
        quartileTwoMedian,
        quartileTwoQ3,
        quartileTwoHigh,
      ];

      let min = 0;
      let max = 100;

      if (quartileTwoHigh > 100) {
        min = Math.floor(quartileOneLow / 2);
        max = Math.floor(quartileTwoHigh * 2);
      }

      console.log(max, quartileTwoHigh);
      const seriesData = {
        series: [
          {
            type: "boxPlot",
            data: [
              {
                x: "Quartile 1",
                y: seriesOne,
              },
              {
                x: "Quartile 2",
                y: seriesTwo,
              },
            ],
          },
        ],
        options: {
          chart: {
            type: "boxPlot",
            height: 350,
            fontFamily: "Poppins, sans-serif",
            toolbar: {
              show: false,
            },
          },
          title: {
            show: false,
          },
          yaxis: {
            min: min,
            max: max,
          },
          plotOptions: {
            boxPlot: {
              colors: {
                upper: "#476DFA",
                lower: "#66D686",
              },
            },
          },
        },
      };
      setLoading(false);
      setSeries(seriesData);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      horizontal &&
      vertical &&
      targetValue &&
      outcomeVariable &&
      questionStructure
    ) {
      setLoading(true);
      retrieveBoxPlotCalculations(
        vertical,
        targetValue,
        outcomeVariable,
        data,
        questionStructure
      );
    }
  }, [horizontal, vertical, targetValue, outcomeVariable, questionStructure]);

  // useEffect(() => {
  //   if (id) {
  //     dispatch({
  //       type: SG_GET_EMPLOYEE_RECORD,
  //       payload: `employee=${id}`,
  //     });

  //     dispatch({
  //       type: SG_GET_EMPLOYEE_LOG,
  //       payload: `employee=${id}`,
  //     });
  //   }
  //   //
  //   dispatch({ type: SG_GET_EMPLOYEE_RECORD_VALUE });
  // }, [dispatch, id, get_auth]);

  // useEffect(() => {
  //   dispatch({
  //     type: SG_GET_EMPLOYEE_RECORD_VALUE,
  //     payload: `data_for=2&sort_order=1`,
  //   });
  // }, [dispatch]);

  if (!get_survey_structure[0]) {
    return <Loader />;
  }

  return (
    <Container>
      <Title>Complex Data Analysis</Title>
      <OptionArea>
        <div
          style={{
            marginRight: 60,
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Label>Unit of Analysis</Label>
          <CategorizedDropdown
            placeholder="Select a Unit of Analysis"
            filterOptions={unitList}
            hideLabel
            hideFilter
            value={vertical}
            id="anchors"
            onSelectLabel={(f) => setVertical(f.value)}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            width: "50%",
          }}
        >
          <Label>Analysis Variable</Label>
          <CategorizedDropdown
            placeholder="Select an Analysis Variable"
            filterOptions={anchor_list}
            hideLabel
            hideFilter
            value={horizontal}
            id="anchors"
            onSelectLabel={(f) => {
              console.log(f);
              setHorizontal(f.value);
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 10,
            marginRight: 60,
          }}
        >
          <Label>Target</Label>

          <CategorizedDropdown
            placeholder={`Select Target Type`}
            filterOptions={targetList}
            value={targetValue}
            hideLabel
            hideFilter
            id="anchors"
            onSelectLabel={(f) => setTargetValue(f.value)}
          />
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Label>Outcome Variable</Label>
          <CategorizedDropdown
            placeholder="Select Outcome Type"
            filterOptions={outcomeList}
            value={outcomeVariable}
            id="anchors"
            onSelectLabel={(f) => setOutcomeVariable(f.value)}
          />
        </div>
      </OptionArea>

      <Row>
        <Card>
          {(!horizontal || !vertical || !series) && !loading ? (
            <NoDataContainer>
              <Image src={noData} />
              <Text>No data yet</Text>
            </NoDataContainer>
          ) : loading ? (
            <NoDataContainer>
              <ChartLoading />
            </NoDataContainer>
          ) : (
            <Chart
              options={series.options}
              series={series.series}
              type="line"
              height={"100%"}
            />
          )}
        </Card>

        <Stats>
          <T2>
            <LegendIcon />
            Linear Regression Analysis
          </T2>
          <div style={{ width: "100%", paddingLeft: 30 }}>
            <SubTitle>P-Value</SubTitle>
            <R>{0.03}</R>
            <AT>
              The results of the quartile analysis shows that there are
              meaningful differences between teams with higher percentages of
              extraverts and teams with lower percentages of extraverts.
            </AT>
          </div>
          <Correl>
            <T3>Quartile 1</T3>
            <Sub>Teams with average extraversion less than 3.2/5</Sub>
          </Correl>

          <Correl>
            <T3>Quartile 2</T3>
            <Sub>Teams with average extraversion less than 3.6/5</Sub>
          </Correl>

          <Correl bottom={true}>
            <T3>Quartile 3</T3>
            <Sub>Teams with average extraversion less than 4/5</Sub>
          </Correl>

          <Correl bottom={true}>
            <T3>Quartile 4</T3>
            <Sub>Teams with average extraversion less than 4.3/5</Sub>
          </Correl>
        </Stats>
      </Row>
    </Container>
  );
};

export default DataScience;

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Text = styled.div`
  font-size: 34px;
  font-weight: bold;
  font-family: "Poppins";
  margin-top: 20px;
`;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  height: 200px;
  margin-top: 50px;
`;

const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
  margin-bottom: 30px;
`;

const Card = styled.div`
  width: calc(100% - 360px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
`;

const Stats = styled.div`
  width: 350px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 24px 10px;
  margin-left: 10px;
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
`;

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  width: 150px;
  color: #6d6d6d;
  margin-right: 0px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const R = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  font-family: "Poppins", sans-serif !important;
  line-height: 1;
`;

const T2 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  /* identical to box height */

  color: #414141;
`;

const T3 = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #a5a5a5;
`;

const Row = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  color: #7e7e7e;
  margin-top: 20px;
`;

const AT = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 30px;
  margin-bottom: 30px;
  color: #7e7e7e;
`;

const Correl = styled.div`
  border-top: 1px solid #d7dce6;
  border-bottom: ${(props) => (props.bottom ? "1px solid #D7DCE6" : "")};
  padding: 13px 24px;
`;

const Sub = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;

const data2 = {
  series: [
    {
      type: "boxPlot",
      data: [
        {
          x: "Quartile 1",
          y: [54, 66, 69, 75, 88],
        },
        {
          x: "Quartile 2",
          y: [43, 65, 69, 76, 81],
        },
        {
          x: "Quartile 3",
          y: [31, 39, 45, 51, 59],
        },
        {
          x: "Quartile 4",
          y: [39, 46, 55, 65, 71],
        },
      ],
    },
  ],
  options: {
    chart: {
      type: "boxPlot",
      height: 350,
      fontFamily: "Poppins, sans-serif",
      toolbar: {
        show: false,
      },
    },
    title: {
      show: false,
    },
    yaxis: {
      min: 0,
      max: 100,
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: "#476DFA",
          lower: "#66D686",
        },
      },
    },
  },
};
