import styled,{keyframes} from "styled-components";
import React, { useEffect, useState } from "react";





const Summary = () =>{


    return (
        <Container>
            <Title>Custom survey summary</Title>
            <Description>View a summary of your custom survey, including the number of questions and responses.</Description>

            <Table>
                <Row>
                    <TH>
                        Member
                    </TH>
                    <TH>
                        Status
                    </TH>
                    <TH>
                        Sentiment
                    </TH>
                    <TH>
                        Action Orientation
                    </TH>
                    <TH>
                        Actions
                    </TH>
                </Row>

                <Row>
                    <TD>
                        <InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>
                        Michael Bawol
                    </TD>
                    <TD>
                        <Status status={'Completed'}/>
                    </TD>
                    <TD>
                        <Sentiment sentiment={'Positive'}/>
                    </TD>
                    <TD>
                        <ActionOrientation action={'50% Actionable'}/>
                    </TD>
                    <TD>
                      
                    </TD>
                </Row>

                <Row>
                    <TD>
                        <InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>
                        Michael Bawol
                    </TD>
                    <TD>
                        <Status status={'Completed'}/>
                    </TD>
                    <TD>
                        <Sentiment sentiment={'Positive'}/>
                    </TD>
                    <TD>
                        <ActionOrientation action={'100% Actionable'}/>
                    </TD>
                    <TD>
                      
                    </TD>
                </Row>

                <Row>
                    <TD>
                        <InitialsCircle firstName={'Michael'} lastName={'Bawol'}/>
                        Michael Bawol
                    </TD>
                    <TD>
                        <Status status={'Completed'}/>
                    </TD>
                    <TD>
                        <Sentiment sentiment={'Positive'}/>
                    </TD>
                    <TD>
                        <ActionOrientation action={'70% Actionable'}/>
                    </TD>
                    <TD>
                      
                    </TD>
                </Row>
            </Table>
        </Container>
    )

}

export default Summary


// Function to get initials from the name
const getInitials = (firstName, lastName) => {

    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  };
  const colors = [
    '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', 
    '#34495e', '#16a085', '#27ae60', '#2980b9', 
    '#8e44ad', '#2c3e50', '#f39c12', '#d35400', 
    '#c0392b', '#e74c3c', '#e67e22', '#f1c40f',
    '#e84393', '#00cec9', '#0984e3', '#6c5ce7'
  ];
  
  // Hash function to consistently map initials to a color
  const hashStringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return colors[hash % colors.length];
  };


const InitialsCircle = ({ firstName, lastName }) => {
    const initials = getInitials(firstName, lastName);
    const color = hashStringToColor(initials);
    
    return <Circle bgColor={color}>{initials}</Circle>;
  };


const Container = styled.div`
    width:100%;
    margin-top:30px;
`

const Title = styled.div`
    font-size:20px;
    font-weight:600;
    margin-bottom:10px;
`

const Table = styled.div`

`

const Row = styled.div`
    display:flex;
    margin-bottom:10px;
`

const TD = styled.div`
    display:flex;
    align-items:center;
    width:20%;
`
const TH = styled.div`
    width:20%;
    font-weight:bold;
    text-transform:uppercase;
    font-size:12px;
    

`
const Description = styled.div`
  margin-bottom:30px;
`

// Styled component for the circle
const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  font-weight: 600;
  margin-right:10px;
`;

const Status = ({status}) =>{
    return <Text>{status}</Text>
}

const Sentiment = ({sentiment}) =>{
    return <Text>{sentiment}</Text>
}

const ActionOrientation = ({action}) =>{
    return <Text>{action}</Text>
}

const Text = styled.div`
    
    `