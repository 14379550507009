import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";



const WhitePaper = () =>{


	return (
		<Container>
			<Top>
				<TopText>People Dimension</TopText>
				<Line/>
				<Title>Effects of Cohesion on Women's Soccer Performance Outcomes</Title>
				<Authors>Danielle Mercer PhD, Michael Bawol MSc, Bryce Tully MSc</Authors>
			</Top>

			<ContentArea>
				<Summary>
					<H1>Executive Summary</H1>
					<Text>
					Bacon ipsum dolor amet ribeye bacon cupim biltong. Turkey meatball short loin kevin, 
					cupim rump chuck alcatra doner. Capicola flank strip steak pastrami boudin. Pig alcatra 
					tenderloin, spare ribs sirloin kielbasa brisket prosciutto meatball pancetta beef. Boudin 
					ham chicken drumstick tail meatball. Buffalo leberkas tongue rump jowl jerky fatback corned 
					beef short loin.
					</Text>

					<Text>
					Shank biltong strip steak, fatback drumstick sirloin turducken chuck pork loin salami. Buffalo 
					chislic chicken turkey strip steak swine kielbasa. Chicken frankfurter drumstick, hamburger flank s
					wine brisket cow boudin pork loin bacon bresaola. Pork loin kevin beef ribs bacon, doner cow 
					capicola ham. Bacon frankfurter burgdoggen pastrami shank boudin corned beef pork belly. Beef 
					biltong tri-tip turkey boudin strip steak landjaeger alcatra salami sirloin spare ribs bacon 
					short ribs meatloaf.
					</Text>

					<Text>
					Landjaeger leberkas cow, pork loin cupim frankfurter venison pig burgdoggen prosciutto tenderloin
					 ball tip tail tri-tip. Corned beef tongue pork loin shank tail tenderloin shankle chuck. Brisket 
					 bacon salami, sausage ball tip flank buffalo shankle shoulder pork belly. Short ribs ground round 
					 pig tongue, strip steak meatball drumstick pork chop shank rump chislic sirloin. Picanha pig boudin 
					 ham shoulder tail meatloaf drumstick burgdoggen short loin sirloin. Pork loin swine kevin, rump cow 
					 ham hock landjaeger drumstick shoulder corned beef turducken chicken jerky porchetta.
					</Text>

					<Text>
					Porchetta ham hock flank ball tip boudin turducken chicken chuck shankle sirloin buffalo. 
					Hamburger pork chop pancetta, meatloaf tongue chicken turkey rump ribeye. Turducken capicola 
					meatball kevin chislic. Andouille pig pork chop chicken doner shoulder tenderloin chuck hamburger 
					kielbasa bacon meatball meatloaf drumstick tongue. Drumstick pig doner, short ribs tri-tip tail 
					capicola pork belly ribeye corned beef buffalo biltong andouille beef ribs. Kielbasa landjaeger 
					pig fatback rump cupim, buffalo frankfurter pork loin picanha. Ribeye swine short loin, beef salami 
					turducken sausage drumstick.
					</Text>

					<Text>
					Kielbasa chislic ground round, corned beef short ribs ham hock pork loin beef ribs prosciutto doner
					 tenderloin cupim meatball ham shankle. Buffalo kielbasa sirloin strip steak. Pastrami shank pancetta 
					 bacon picanha chuck shoulder strip steak bresaola meatball ham tongue porchetta cow pork. Short loin 
					 meatball ham hock leberkas jowl kielbasa tongue burgdoggen, picanha porchetta bresaola.
					</Text>
				</Summary>

				<Side>
				   <H2>Outcomes in high cohesion groups</H2>
					<div style={{position:'relative'}}>
						<Chart options={data.options} series={data.series} type="radialBar" height={150} />
						<Values>
						  <Stat>86%</Stat>
					    </Values>
					</div>
				
					<Description>Bacon ipsum dolor amet ribeye bacon cupim biltong. Turkey meatball short 
					loin kevin, cupim rump chuck alcatra doner.</Description>


				    <H2>Outcomes in low cohesion groups</H2>
					<div style={{position:'relative'}}>
						<Chart options={data.options} series={data2.series} type="radialBar" height={150} />
						<Values>
						  <Stat>36%</Stat>
					    </Values>
					</div>
				
					<Description>Bacon ipsum dolor amet ribeye bacon cupim biltong. Turkey meatball short 
					loin kevin, cupim rump chuck alcatra doner.</Description>
				</Side>


			</ContentArea>
		</Container>
		)
}


export default WhitePaper



const Container = styled.div`
	width:800px;
	font-family: "Barlow", sans-serif;
	padding:20px 60px;
	margin-top:60px;

`

const Top = styled.div`

`

const Title = styled.div`
	font-size:50px;
	font-weight:bold;
	line-height:1.3;
`

const Authors = styled.div`
	margin-top:20px;
	font-size:16px;
`

const TopText = styled.div`
	font-weight:bold;
	font-size:16px;
	color:grey;
`

const Line = styled.div`
	height:5px;
	width:75px;
	background-color:blue;
	margin-top:5px;
	margin-bottom:10px;
`

const H1 = styled.div`
	font-weight:bold;
	font-size:18px;
`

const Summary = styled.div`
	margin-top:50px;
	width:500px;
`

const Text = styled.div`
	font-size:13px;
	margin-top:10px;
`

const Side = styled.div`
	width:calc(100% - 520px);
	margin-left:20px;
	padding-top:40px;
`

const Outcome = styled.div`
	width:100%;
	height:150px;
	background-color:lightblue;
	margin-top:50px;
	padding:20px 10px;
`

const ContentArea = styled.div`
	display:flex;
`

const SideText = styled.div`
	font-size:40px;
	color:white;
	font-weight:bold;
`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 25px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:25px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const Description = styled.div`
	font-size:12px;
	line-height:1.2;
	margin-top:-10px;
`
const H2 = styled.div`
	font-weight:bold;
	margin-bottom:-10px;
	text-align:center;
	margin-top:40px;
`

const data = {
          
            series: [86],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
               grid:{
              	padding:{
              		right:0,
              		top:0,
              		left:0,
              		bottom:0
              	}
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '65%',
                  },
                   track:{
                	background:'white'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#ab710a']
              }
            },
          
          
          };


const data2 = {
          
            series: [36],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
               grid:{
              	padding:{
              		right:0,
              		top:0,
              		left:0,
              		bottom:0
              	}
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '65%',
                  },
                   track:{
                	background:'white'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#ab710a']
              }
            },
          
          
          };

