import React, { useState, useEffect } from "react";

import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_DATA_JOURNEYS,
  SET_ACTIVE_JOURNEY,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_GET_EMPLOYEE_ORG_PERMISSIONS,
} from "constants/actions";
import { format } from "date-fns";
import { useParams } from "react-router";
import Loading from "./components/Loading";

import Box from "./components/Box";
import Back from "./components/Back";

const JourneyDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [journeyGroups, setJourneys] = useState([]);
  const [filteredJourneys, setFilteredJourneys] = useState();
  const [selectedJourneyGroup, setSelectedJourneyGroup] = useState(null);
  const [selectedJourney, setSelectedJourney] = useState();
  const [employeeInfo, setEmployeeInfo] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [employeePermissions, setEmployeePermissions] = useState();
  const [groupTitle, setGroupTitle] = useState();
  const { id } = useParams();

  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    get_survey_structure,
    get_employee_permission,
  } = useSelector(
    (state) => ({
      get_auth_employee: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  useEffect(() => {
    setEmployeeInfo(get_auth_employee?.employee);
  }, [get_auth_employee]);

  useEffect(() => {
    const employeePerms =
      get_employee_permission?.employee_org_permission?.find(
        (emp) => emp?.employee_id === employeeInfo?.id
      );

    if (employeePerms && employeePerms?.details?.length > 0) {
      // Filter the details so that all we have is the ones that work for this component.
      const filteredDetails = employeePerms?.details?.filter(
        (detail) => detail?.name_id && detail?.value_id
      );

      // If the filtered details are not empty, set the employee permissions to the filtered details.
      // This means the data stories will be filtered based on the permissions and not the selected group.
      if (filteredDetails?.length > 0) {
        setEmployeePermissions(filteredDetails);
      }
    }
  }, [get_employee_permission]);

  useEffect(() => {
    if (employeeInfo) {
      dispatch({
        type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
        payload: `employee=${employeeInfo?.id}`,
      });

      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${employeeInfo?.organization_id}`,
      });

      dispatch({
        type: SG_GET_EMPLOYEE_ORG_PERMISSIONS,
        payload: `organization=${Number(employeeInfo?.organization_id)}`,
      });
    }
  }, [dispatch, employeeInfo]);

  useEffect(() => {
    if (employeePermissions && journeyGroups) {
      const filteredDataJourneys = journeyGroups?.map((item) => {
        const dateObject = new Date(item.created_at);
        const groupTitle = `${format(dateObject, "MMMM d, yyyy")}`;
        return {
          id: item.id,
          title: groupTitle,
          data_story: item?.recommendation?.data_story?.filter((story) => {
            return employeePermissions.some((permission) => {
              return (
                permission.name_id === story.parent_id &&
                permission.value_id === story.category_option
              );
            });
          }),
        };
      });

      setFilteredJourneys(
        filteredDataJourneys?.filter(
          (journey) => journey?.data_story?.length > 0
        )
      );
    }
  }, [employeePermissions, journeyGroups]);

  useEffect(() => {
    dispatch({ type: SG_GET_DATA_JOURNEYS });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_STRUCTURES,
      payload: `organization=${employeeInfo?.organization_id}`,
    });
  }, [dispatch, employeeInfo]);

  useEffect(() => {
    if (get_chat_gpt_recommendations?.data_journeys) {
      setJourneys(get_chat_gpt_recommendations?.data_journeys?.data);
    }
  }, [get_chat_gpt_recommendations]);

  useEffect(() => {
    if (id && journeyGroups) {
      const selectedGroup = journeyGroups.find(
        (story) => story.id === Number(id)
      );
      setSelectedJourneyGroup(selectedGroup);
    }
  }, [id, journeyGroups]);

  const defaultList = !selectedJourneyGroup
    ? journeyGroups
        // .filter((j) => !j.readStatus)
        ?.map((journey) => {
          const dateObject = new Date(journey.created_at);
          const groupTitle = `Data Journeys generated ${format(
            dateObject,
            "MMMM d, yyyy"
          )}`;
          return (
            <Box
              onClick={() => {
                // update read to true
                // const updatedJourneys = journeys.map((j) => {
                //   if (j.id === journey.id) {
                //     j.readStatus = true;
                //   }
                //   return j;
                // });
                // setJourneys(updatedJourneys);
                navigate(`/simple/audit/journeys/${journey.id}`);
              }}
              title={groupTitle}
              icon={"book"}
              // read={journey.readStatus}
              // description={journey.description}
            />
          );
        })
    : selectedJourneyGroup &&
      selectedJourneyGroup?.recommendation?.data_story?.map((journey) => {
        const parentCategory = get_survey_structure?.[0]?.categories?.find(
          (cat) => cat.id === journey.parent_id
        );

        const selectedOption = parentCategory?.options?.find(
          (opt) => opt.id === journey.category_option
        );

        const title = `${parentCategory?.name} - ${selectedOption?.name} data journey`;
        const description = `This data journey is generated from the ${parentCategory?.name} category and the ${selectedOption?.name} option.`;
        return (
          <Box
            onClick={() => {
              // update read to true
              // const updatedJourneys = journeys.map((j) => {
              //   if (j.id === journey.id) {
              //     j.readStatus = true;
              //   }
              //   return j;
              // });
              // setJourneys(updatedJourneys);
              // setSelectedJourney(journey.id);
              // Set active journey in redux, similar to stories.
              dispatch({
                type: SET_ACTIVE_JOURNEY,
                payload: {
                  activeJourney: journey,
                },
              });
              navigate(`/simple/audit/journey`);
            }}
            title={title}
            icon={"book"}
            // read={journey.readStatus}
            description={description}
          />
        );
      });

  const journeysList = filteredJourneys && (
    <>
      {filteredJourneys.map((journey) => {
        return (
          <FilteredGroup>
            <FilteredJourneys>
              {journey.data_story.map((journey) => {
                const parentCategory =
                  get_survey_structure?.[0]?.categories?.find(
                    (cat) => cat.id === journey.parent_id
                  );

                const selectedOption = parentCategory?.options?.find(
                  (opt) => opt.id === journey.category_option
                );

                const title = `${parentCategory?.name} - ${selectedOption?.name} data journey`;
                const description = `This data journey is generated from the ${parentCategory?.name} category and the ${selectedOption?.name} option.`;
                return (
                  <Box
                    onClick={() => {
                      // update read to true
                      // const updatedJourneys = journeys.map((j) => {
                      //   if (j.id === journey.id) {
                      //     j.readStatus = true;
                      //   }
                      //   return j;
                      // });
                      // setJourneys(updatedJourneys);
                      // setSelectedJourney(journey.id);
                      // Set active journey in redux, similar to stories.
                      dispatch({
                        type: SET_ACTIVE_JOURNEY,
                        payload: {
                          activeJourney: journey,
                        },
                      });
                      navigate(`/simple/audit/journey`);
                    }}
                    title={title}
                    icon={"book"}
                    // read={journey.readStatus}
                    description={description}
                  />
                );
              })}
            </FilteredJourneys>
          </FilteredGroup>
        );
      })}
    </>
  );

  return (
    <Container>
      <Center>
        <div style={{ marginBottom: 30 }} />
        <Back url={"/simple/audit/simpledash"} location={"dashboard"} />
        <Title>Your data journeys</Title>
        {journeysList || defaultList}
        {!journeysList && !defaultList && (
          <LoadWrapper>
            <Loading />
            <LoadingText>Loading your data stories</LoadingText>
          </LoadWrapper>
        )}
      </Center>
    </Container>
  );
};

export default JourneyDashboard;

const Container = styled.div`
  padding-left: 58px;
  padding-right: 40px;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #2a3039;
  padding-top: 20px;
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: ".";
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: ".";
    }
    33% {
      content: "..";
    }
    66% {
      content: "...";
    }
  }
`;

const LoadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const Title = styled.div`
    font-size:22px;
    font-weight:600;
    color#2A3039;
    margin-bottom:20px;
`;
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Center = styled.div`
  width: 800px;
  opacity: 0; // Start with hidden state
  animation: ${fadeIn} 0.5s forwards; // Animation name, duration and play-state

  // 3. Adjust delay for each sample
  animation-delay: 0.3s;
`;

const FilteredJourneys = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const FilteredGroup = styled.div``;
