// GenerateChart.js
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import styled, { keyframes } from "styled-components";
import { Modal, Input, Button, Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const GenerateButton = styled(Button)`
  background-color: #2d70e2 !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  margin-top: 20px !important;
  align-self: flex-end !important;

  &:hover {
    background-color: #1a4a8e !important;
  }
`;

const MasterContainer = styled.div`
  padding: 2em;
  animation: ${fadeIn} 0.5s ease-in;
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 30px;
`;

const StepDescription = styled.div`
  font-size: 16px;
  color: #666d79;
  margin-bottom: 20px;
`;

const SmallLabel = styled.div`
  font-size: 14px;
  color: #666d79;
`;

const Section = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const GenerateChartModal = ({
  open,
  onClose,
  generatePresentation,
  slides,
}) => {
  const [generatedReport, setGeneratedReport] = useState(false);
  const [presentationName, setPresentationName] =
    useState("Performance Review");

  const [callingAPI, setCallingAPI] = useState(false);
  const [presentationQueued, setPresentationQueued] = useState(false);

  const presentations = useSelector(
    (state) => state.powerpoint_presentations,
    shallowEqual
  );

  const employeeId = useSelector(
    (state) => state.auth.employee_id,
    shallowEqual
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (presentations && callingAPI) {
      setPresentationQueued(true);

      setCallingAPI(false);
      setGeneratedReport(true);
    }
  }, [presentations, callingAPI]);

  const generateReport = () => {
    setCallingAPI(true);
    const payload = {
      employee_id: employeeId,
      name: presentationName, // Replace with actual presentation name
      slides,
    };

    generatePresentation(payload);
  };

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Content>
        <MasterContainer>
          <Title>You're almost there!</Title>
          <StepDescription>
            Please give your presentation a title, then click "Generate"
          </StepDescription>
          <SmallLabel>Presentation Title</SmallLabel>
          <Section>
            <Input
              value={presentationName}
              onChange={(e) => setPresentationName(e.target.value)}
              style={{ marginBottom: "20px" }}
            />
            <>
              {!generatedReport && (
                <GenerateButton onClick={generateReport}>
                  Generate
                </GenerateButton>
              )}
            </>
            <>
              {presentationQueued && (
                <div>
                  <p>Your presentation has been queued successfully!</p>
                  <Button
                    onClick={() => navigate("/leader/audit/presentation/list")}
                  >
                    Go to Presentations List
                  </Button>
                </div>
              )}
              {callingAPI && (
                <div>
                  <Loader active inline="centered" size="large">
                    Requesting your presentation...
                  </Loader>
                  <p>Please wait while we process your request.</p>
                </div>
              )}
            </>
          </Section>
        </MasterContainer>
      </Modal.Content>
    </Modal>
  );
};

export default GenerateChartModal;