import styled from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
// import Back from "./Back";
import TopProgress from "./TopProgress";


const getComponentMapping = (componentName) => {
  switch (componentName) {
    case "HighestFactor":
      return "FactorScore";
    case "LowestFactor":
      return "FactorScore";
    case "LowestFactorFeedbackAI":
      return "AIFeedback";
    case "HighestFactorFeedbackAI":
      return "AIFeedback";
    case "LowestFactorExplanation":
      return "FactorExplain";
    case "HighestFactorExplanation":
      return "FactorExplain";
    default:
      return componentName;
  }
};

const Sidebar = ({ open, setOpen, setStage, stage, data }) => {
  const [section, setSection] = React.useState(2);

  const legendArr = data.map((s, i) => {
    // Depending on how this will map.
    if (getComponentMapping(s?.reactComponent) === "FactorExplain") {
      return {
        key: i,
        name: s?.dataRequirements?.title,

        title: s?.dataRequirements?.title,
      };
    } else if (s?.reactComponent === "HighestFactor") {
      return {
        key: i,
        name: "Biggest strength",
        title: "Biggest strength",
      };
    } else if (s?.reactComponent === "HighestFactorFeedbackAI") {
      return {
        key: i,
        name: "Strength analysis",
        title: "Strength analysis",
      };
    }else if ("LowestFactor" === s?.reactComponent) {
      return {
        key: i,
        name: "Biggest gap",
        title: "Biggest gap",
      };
    } else if ("LowestFactorFeedbackAI" === s?.reactComponent) {
      return {
        key: i,
        name: "Gap analysis",
        title: "Gap analysis",
      };
    } else {
      const legendData = s?.manualInputs?.find(
        (m) => m.name === "Legend Title"
      );
      return {
        key: i,
        name: legendData?.value,
        title: legendData?.value,
      };
    }
  });

  return (
    <Container open={open}>
      <IconContainer onClick={() => setOpen(!open)}>
        <Icon
          name={open ? "chevron left" : "chevron right"}
          style={{ margin: 0, marginTop: -3 }}
        />
      </IconContainer>

      {open && (
        <SideContent>
          <Title onClick={() => setSection(2)}>
            Introduction to data story
          </Title>

          <TopProgress
            step={stage}
            steps={legendArr}
            setStep={(s) => setStage(s)}
            open={section == 2}
          />
        </SideContent>
      )}
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  height: calc(100vh - 200px);
  position: fixed;
  left: 200px;
  top: 100px;
  border-radius: ${(props) =>
    props.open ? "0px 15px 15px 0px" : "0px 5px 5px 0px"};
  width: ${(props) => (props.open ? "300px" : "30px")};
  background-color: #ebf1fd;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;

const IconContainer = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 20px;
  color: #2a3039;
  cursor: pointer;
`;

const SideContent = styled.div`
  width: 300px;
  padding: 30px;
  padding-left: 40px;
  background-color: #ebf1fd;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
