import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { CalendarIcon } from "./icons";
import { format } from "date-fns";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "Raleway",
  },
  topDropdownSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  firstDropdown: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  firstDropdownLabel: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 5,
    color: "#666d79",
  },
  firstDropdownValue: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
    color: "#2d70e2",
  },
  tableDropdown: {
    display: "flex",
    flexDirection: "column",
  },
  tableDropdownLabel: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 5,
    color: "#666d79",
  },
  tableDropdownValue: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 10,
    color: "#2d70e2",
  },
  innerTableContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    width: "100%",
    marginTop: 10,
    borderCollapse: "collapse",
  },
  innerTableHeaderRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    paddingBottom: 5,
    paddingTop: 5,
    borderBottomWidth: 1,
  },
  innerTableRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: 5,
    paddingTop: 5,
  },
  innerTableHeader: {
    fontWeight: 600,
    fontSize: 10,
    display: "flex",
    alignItems: "center",
    width: "50%",
    marginRight: 10,
  },
  innerTableCol: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    fontSize: 10,
  },
  dateValue: {
    color: "#666d79",
    fontWeight: 500,
    fontSize: 12,
    marginTop: 3,
    marginRight: 15,
    marginLeft: 5,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
  },
  innerTableText: {},
  statusText: {
    color: "#2d70e2",
    fontWeight: 500,
    fontSize: 10,
  },
});

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
];

const DropdownTable = ({ data, id }) => {
  const statusOptions = planStatus.map((status) => {
    return status.text;
  });
  return (
    <View style={styles.tableContainer}>
      {(data?.planData[id]?.length > 0 && data?.planData[id])?.map((r, i) => {
        return (
          <View>
            <View style={styles.topDropdownSection}>
              <View style={styles.firstDropdown}>
                <Text style={styles.firstDropdownLabel}>Focus Area</Text>
                <Text style={styles.firstDropdownValue}>{r?.focusArea}</Text>
              </View>
              {r?.focusArea && (
                <View style={styles.tableDropdown}>
                  <Text style={styles.tableDropdownLabel}>Target</Text>
                  <Text style={styles.tableDropdownValue}>{r?.target}</Text>
                </View>
              )}
              {r?.focusArea && r?.target && (
                <View style={styles.innerTableContainer}>
                  <View style={styles.innerTableHeaderRow}>
                    <Text style={[styles.innerTableHeader, { width: 350 }]}>
                      Description of Action
                    </Text>
                    <Text style={styles.innerTableHeader}>Resource Needed</Text>
                    <Text style={styles.innerTableHeader}>Lead Personnel</Text>
                    <Text style={styles.innerTableHeader}>Timeline</Text>
                    <Text style={styles.innerTableHeader}>Status</Text>
                  </View>
                  <View style={styles.innerTableRow}>
                    <View style={[styles.innerTableCol, { width: 350 }]}>
                      <Text style={styles.innerTableText}>
                        {r?.inputData?.[0]}
                      </Text>
                    </View>
                    <View style={styles.innerTableCol}>
                      <Text style={styles.innerTableText}>
                        {r?.inputData?.[1]}
                      </Text>
                    </View>
                    <View style={styles.innerTableCol}>
                      <Text style={styles.innerTableText}>
                        {r?.inputData?.[2]}
                      </Text>
                    </View>
                    <View style={styles.innerTableCol}>
                      <View style={styles.dateContainer}>
                        <CalendarIcon />
                        <Text style={styles.dateValue}>
                          {format(
                            (r?.startDate && new Date(r?.startDate)) ||
                              new Date(),
                            "yyyy-MM-dd"
                          )}
                        </Text>
                      </View>
                      <View style={styles.dateContainer}>
                        <CalendarIcon />
                        <Text style={styles.dateValue}>
                          {format(
                            (r?.endDate && new Date(r?.endDate)) || new Date(),
                            "yyyy-MM-dd"
                          )}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.innerTableCol}>
                      <Text style={styles.statusText}>
                        {planStatus?.find((s) => s?.value === r?.status)
                          ?.text || statusOptions[0]}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default DropdownTable;
