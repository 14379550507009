import lightbulbImage from "assets/images/lightbulb.jpg";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import RalewayBold from "assets/fonts/Raleway-Bold.ttf";
import RalewaySemiBold from "assets/fonts/Raleway-SemiBold.ttf";

// Create styles
const styles = StyleSheet.create({
  actionItemsContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    marginBottom: 14,
  },
  actionItemsContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    borderRadius: 8,
  },
  textBlockContainer: {
    marginTop: 0,
    color: "#2A3039",
    paddingHorizontal: 16,
    paddingTop: 8,
  },
  titleContainer: {},
  T2: {
    fontSize: 12,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "128%",
    fontFamily: "Raleway",
    marginLeft: 12,
  },
  description: {
    fontSize: 9,
    marginTop: 12,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.7,
    fontFamily: "Raleway",
  },
  bulletTitle: {
    fontSize: 9,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: 1.7,
    fontFamily: "Raleway",
    marginBottom: 12,
  },
  bulletItemsContainer: {
    marginTop: 12,
    marginLeft: 40,
    marginRight: 16,
  },
  numberedItemsContainer: {
    marginTop: 12,
    marginLeft: 40,
    marginRight: 16,
  },
  boldedNumberedText: {
    fontSize: 9,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: 1.7,
  },
  numberedText: {
    fontSize: 9,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.7,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    marginTop: 12,
    borderRadius: 10,
  },
  bulletItems: {
    fontSize: 9,
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: 1.4,
  },
  bulletPoint: {
    display: "flex",
    flexDirection: "row",
  },
  bulletScore: {
    fontSize: 9,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: 1.4,
  },
});

Font.register({
  family: "Raleway",
  fonts: [
    { src: Raleway, fontWeight: 400 },
    { src: RalewayBold, fontWeight: 700 },
    { src: RalewaySemiBold, fontWeight: 600 },
  ],
  format: "truetype",
});

const createBulletPoints = (bulletTitle, bullets) => {
  return (
    <View style={styles.actionItemsContainer}>
      <View style={styles.actionItemsContent}>
        <Text style={styles.bulletTitle}>{bulletTitle}</Text>
        {bullets.map((item, i) => {
          const scoreRegex = /([\-\w\:\s]*)%/g;
          const score = scoreRegex.exec(item)[0];
          const text = item.replace(`(${score})`, "");
          return (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Text style={{ marginRight: 8, fontSize: 10, lineHeight: 1.4 }}>
                •
              </Text>
              <Text key={i + "-bullet"} style={styles.bulletItems}>
                {text}
                <Text key={i + "-score"} style={styles.bulletScore}>
                  ({score})
                </Text>
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const createNumberedItems = (numberedItems) => {
  return (
    <View style={styles.actionItemsContainer}>
      <View style={styles.actionItemsContent}>
        {numberedItems.map((item, i) => {
          const boldedRegex = /[\-\w*\s*]*\w:/g;
          const boldedText = boldedRegex.exec(item);
          const text = item.replace(boldedText, "");
          return (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: i === numberedItems.length - 1 ? 0 : 14,
                marginLeft: 40,
              }}
            >
              <Text style={styles.boldedNumberedText}>
                {i + 1}. {boldedText}
                <Text key={i + "-numberedText"} style={styles.numberedText}>
                  {text}
                </Text>
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

const TextBlock = (props) => {
  const {
    title,
    description,
    bullets,
    bulletTitle,
    icon,
    bgColor,
    numberedItems,
  } = props;
  return (
    <View
      style={[
        styles.textBlockContainer,
        {
          backgroundColor: bgColor,
        },
      ]}
    >
      <View style={styles.iconContainer}>
        {icon}
        <Text style={styles.T2}>{title}</Text>
      </View>
      {bullets && (
        <View style={styles.bulletItemsContainer}>
          <Text style={styles.description}>{description}</Text>
          {createBulletPoints(bulletTitle, bullets)}
        </View>
      )}
      {numberedItems && (
        <View styles={styles.numberedItemsContainer}>
          {createNumberedItems(numberedItems)}
        </View>
      )}
    </View>
  );
};

export default TextBlock;
