import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import SignupToken from "components/SignupToken";
import EmailDeliveryReport from "components/EmailDeliveryReport";
import {
  Dimmer,
  Loader,
  Header,
  Divider,
  Dropdown,
  Modal,
  Icon,
  Popup,
} from "semantic-ui-react";
import { useSearchParams } from "react-router-dom";
import { LockIcon, SaveIcon, XIcon, CategoryIcon, TrashIcon } from "./Icons";
import {
  SG_EDIT_EMPLOYEE_CATEGORY,
  SG_ADD_EMPLOYEE_CATEGORY,
  SG_GET_EMPLOYEE_ORG_CATEGORIES,
  SG_UPDATE_EMPLOYEE_AUDIT,
  SG_GET_SURVEY_STRUCTURES,
  UPDATE_EMPLOYEE,
  SG_ADD_EMPLOYEE_LOG,
  SG_GET_EMPLOYEE_RECORD_VALUE,
  SG_GET_EMPLOYEE_RECORD,
  SG_GET_EMPLOYEE_LOG,
  PULL_EMPLOYEE,
  SG_EDIT_EMPLOYEE_PERMISSION,
  SG_ADD_EMPLOYEE_PERMISSION,
  SG_GET_EMPLOYEE_PERMISSIONS,
  SG_GET_PROD_ORG_CONTRACT,
} from "constants/actions";
import { useParams } from "react-router";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router";
import format from "date-fns/format";
import { AddEmpRecRow, UpdateRecRow } from "./AddEdit/AddRow";
import CategoryPermission from "./Tabs/CategoryPermission";
import Tabs from "components/Tabs";
import { PERSONAS, PERSONA_OPTIONS } from "./Personas";
import { useLocation } from "react-router-dom";

const DETAILED_PERMISSIONS = [
  {
    permissionLabel: "Can Access Recommendations",
    permissionDescription:
      "Member will have access to the recommendation reports.",
    permissionValue: "access_recommendations",
  },
];

const ToggleComponent = (props) => {
  const handleChange = () => {
    props.handleCheck(!props.checked);
  };

  return (
    <ToggleLabel disabled={props.disabled}>
      <ToggleInput
        type="checkbox"
        onChange={() => !props.disabled && handleChange()}
        value={props.checked}
        checked={props.checked}
      />
      <ToggleSlider isChecked={props.checked} />
    </ToggleLabel>
  );
};

const Edit = (props) => {
  const {
    auditEnabled,
    teamToolEnabled,
    setOpenFullCard,
    setFullEmpData,
    setOpenTeamCard,
    data,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [employeeCategoryId, setEmployeeCategoryId] = useState();
  // const [errorMessage, setErrorMessage] = useState(false);
  // current logged in employee
  const [userEmployee, setUserEmployee] = useState(false);
  // current selected employee
  const [employee, setEmployee] = useState(false);

  const [cwfView, setCwfView] = useState(false);
  const [showCwfWarning, setShowCwfWarning] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [selectedPrimary, setSelectedPrimary] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [secondaryCategory, setSecondaryCategory] = useState([]);
  const [selectedSecondary, setSelectedSecondary] = useState({});
  const [disableEdit, setDisableEdit] = useState(false);
  const [isSelfEdit, setIsSelfEdit] = useState(true);
  const [notEnoughPriv, setNotEnoughPriv] = useState(false);
  const [canLockYouOut, setCanLockYouOut] = useState(false);
  const [canChangeAdmin, setCanChangeAdmin] = useState(false);
  const [showSelfAdminError, setShowSelfAdminError] = useState(false);
  const [otpPrimaryOptions, setOtpPrimaryOptions] = useState([]);
  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);
  // Permissions
  const [editUsers, setEditUsers] = useState(false);
  const [isAdminOption, setIsAdmin] = useState(false);
  const [participateSurvey, setParticipateSurvey] = useState(false);
  const [accessDatalytics, setAccessDatalytics] = useState(false);
  const [accessSubfactor, setAccessSubfactor] = useState(false);
  const [readFeedback, setReadFeedback] = useState(false);
  const [scheduleSurveys, setScheduleSurveys] = useState(false);
  const [hasLogin, setHasLogin] = useState(false);
  const [pdfEnabled, setPDFEnabled] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  // Fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [team, setTeam] = useState([]);
  const [email, setEmail] = useState();
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [orgCategory, setOrgCategory] = useState([]);
  const [teamOptions, setTeamOptions] = useState();
  const [primaryOptions, setPrimaryOptions] = useState();
  const [primaryApiOptions, setPrimaryApiOptions] = useState();
  const [categoryOptions, setCategoryOptions] = useState();
  const [categoryApiOptions, setCategoryApiOptions] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categoryTempLoad, setCategoryTempLoad] = useState([]);
  const [show, setShow] = useState(null);
  const [currentUser, setCurrentUser] = useState();
  const [nonSportOrg, setNonSportOrg] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [deactiveReason, setDeactiveReason] = useState();
  const [employeeRecordCreated, setEmployeeRecordCreated] = useState(false);
  const [employeeRecord, setEmployeeRecord] = useState([]);
  const [employeeLog, setEmployeeLog] = useState([]);
  const [employeeRecValue, setEmployeeRecValue] = useState([]);
  const [editEnabled, setEditEnabled] = useState(false);
  const [persona, setPersona] = useState();
  const [personaOptions, setPersonaOptions] = useState();
  const [employeeCategories, setEmployeeCategories] = useState();
  const [categoryChanged, setCategoryChanged] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let showTabs = searchParams.get("show_tabs");
  const [catDispatch, setCatDispatch] = useState(false);

  const [canEdit, setCanEdit] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState();
  const [isAdmin, setIsCurrentUserAdmin] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [canAccessData, setCanAccessData] = useState(false);
  const [userPersona, setUserPersona] = useState();
  // const [employeePermissions, setEmployeePermissions] = useState();
  // const [pdfFeatureEnabled, setPDFFeatureEnabled] = useState(false);
  const [employeePermissions, setEmployeePermissions] = useState();
  const [pdfFeatureEnabled, setPdfFeatureEnabled] = useState();
  const [selectedUserPdfFeatureEnabled, setSelectedUserPdfFeatureEnabled] =
    useState();
  const [hasPermissionsObject, setHasPermissionsObject] = useState(false);
  const [detailedPermissions, setDetailedPermissions] = useState([]);
  const [otpToggle, setOtpToggle] = useState(false);
  const [recommendationsEnabled, setRecommendationsEnabled] = useState(false);
  const [currentPermission, setCurrentPermission] = useState();

  const [summaryEmail, setSummaryEmail] = useState({});

  const settingsTabs =
    isOtp && !showTabs
      ? ["Profile", "Category Options"]
      : [
          "Profile",
          "Permissions",
          "Category Options",
          "Employee Records",
          "Detailed Permission",
        ];
  const [tabs, setTabs] = useState(settingsTabs);

  const employeeRecordTypes = [""];

  const {
    // get_teams,
    get_auth,
    get_employee_category,
    get_survey_structure,
    get_employee,
    get_error,
    get_organizations,
    get_employee_log,
    get_employee_records,
    get_employee_record_value,
    get_org_id,
    get_employee_permission,
    categoriesFetched,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_teams: state.teams.teams,
      get_employee_category: state.employee_category.employee_org_category,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee: state.employees,
      get_error: state.errors,
      get_org_id: state.auth.organization_id,
      get_organizations: state.organizations,
      get_employee_records: state.employee_record.employee_record,
      get_employee_log: state.employee_log.employee_log,
      get_employee_record_value:
        state.employee_record_value.employee_record_value,
      get_employee_permission: state.employee_permission,
      categoriesFetched: state.employee_category.fetched,
    }),
    shallowEqual
  );

  // Dispatch Calls
  useEffect(() => {
    if (id && !selectedEmployee && !get_employee?.[id]) {
      dispatch({
        type: PULL_EMPLOYEE,
        payload: {
          id: id,
        },
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_GET_EMPLOYEE_RECORD,
        payload: `employee=${id}`,
      });

      dispatch({
        type: SG_GET_EMPLOYEE_LOG,
        payload: `employee=${id}`,
      });
    }

    dispatch({ type: SG_GET_EMPLOYEE_RECORD_VALUE });
  }, [dispatch, id, get_auth, employeeRecordCreated]);

  useEffect(() => {
    dispatch({
      type: SG_GET_EMPLOYEE_RECORD_VALUE,
      payload: `data_for=2&sort_order=1`,
    });
  }, [dispatch]);

  useEffect(() => {
    if (selectedEmployee && !get_survey_structure?.length > 0) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${selectedEmployee?.organization}`,
      });
    }
  }, [dispatch, selectedEmployee]);

  useEffect(() => {
    const optionsArray = [];
    if (
      currentUser &&
      isOtp &&
      filteredCategories &&
      get_survey_structure?.[0] &&
      get_employee_permission
    ) {
      currentUser?.access_data_analytics === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_datalytics);
      currentUser?.access_subfactor_data === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_subfactor);
      currentUser?.survey_add_users === 1 &&
        optionsArray.push(PERSONA_OPTIONS.edit_users);
      currentUser?.account_type === 5 &&
        optionsArray.push(PERSONA_OPTIONS.is_admin);
      currentUser?.enable_login === true &&
        optionsArray.push(PERSONA_OPTIONS.enable_login);
      currentUser?.read_feedback === 1 &&
        optionsArray.push(PERSONA_OPTIONS.read_feedback);
      currentUser?.survey_schedule === 1 &&
        optionsArray.push(PERSONA_OPTIONS.schedule_survey);
      currentUser?.survey_status === 1 &&
        optionsArray.push(PERSONA_OPTIONS.participate_survey);

      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const newTeamArr = primary?.options.map((o) => o.id);

      if (
        newTeamArr?.length > 0 &&
        newTeamArr.every((v) => filteredCategories.includes(v))
      ) {
        optionsArray.push(PERSONA_OPTIONS.otp_view);
        if (filteredCategories.length > newTeamArr.length) {
          optionsArray.push(PERSONA_OPTIONS.is_cwf);
        }
      }

      if (
        get_employee_permission &&
        get_employee_permission?.employee_org_permission.length > 0
      ) {
        const currentPermissions =
          get_employee_permission?.employee_org_permission.find(
            (p) => p.employee_id === currentUser?.id
          );

        if (currentPermissions?.auth_details) {
          const exists = currentPermissions?.auth_details.find((p) => {
            if (p?.name === "pdf_enabled") {
              return true;
            }
            if (p.length > 0) {
              return p[0].name === "pdf_enabled";
            }
            return false;
          });

          if (exists) {
            setPdfFeatureEnabled(true);
            optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
          }
        } else {
          setPdfFeatureEnabled(false);
        }
      }

      const persona = PERSONAS.find((p) => {
        return p.options.sort().join(",") === optionsArray.sort().join(",");
      });

      if (persona) {
        setUserPersona(persona);
      }
    }
  }, [
    currentUser,
    isOtp,
    filteredCategories,
    get_survey_structure,
    get_employee_permission,
  ]);

  const detailedPermChange = (details, value) => {
    // Loop through details and look for the value property that matches value,
    // if it doesn't exist, create a new permissions object and add it to the details array.
    // If it does exist, toggle the response value.
    if (!details || details.length === 0) {
      return [{ value: value, response: true }];
    }

    if (details.length > 0) {
      const exists = details.find((d) => d.value === value);
      if (!exists) {
        return [
          ...details,
          {
            value: value,
            response: true,
          },
        ];
      }
    }
    details.forEach((detail) => {
      if (detail.value === value) {
        detail.response = !detail.response;
      }
    });

    return details;
  };

  useEffect(() => {
    const hasPermission = get_employee_permission?.employee_permission[0];
    if (hasPermission) {
      if (hasPermission?.updated) {
        setEmployeePermissions(hasPermission?.updated);
        return;
      }
      if (hasPermission?.created) {
        setEmployeePermissions(hasPermission?.created);
        return;
      }
      setEmployeePermissions(hasPermission);
    }
  }, [get_employee_permission, id]);

  useEffect(() => {
    if (isOtp) {
      if (selectedEmployee?.employee_employee_permission?.auth_details) {
        const exists =
          selectedEmployee?.employee_employee_permission?.auth_details.find(
            (p) => {
              if (p?.name === "pdf_enabled") {
                return true;
              }
              if (p.length > 0) {
                return p[0].name === "pdf_enabled";
              }
              return false;
            }
          );
        if (exists) {
          setSelectedUserPdfFeatureEnabled(true);
        }
      } else {
        setSelectedUserPdfFeatureEnabled(false);
      }
    } else {
      if (selectedEmployee?.employee_employee_permission) {
        setHasPermissionsObject(true);
        setDetailedPermissions(
          selectedEmployee?.employee_employee_permission?.auth_details
        );

        // check if recommendations is enabled
        const recommendations =
          selectedEmployee?.employee_employee_permission?.auth_details?.find(
            (d) => d.value === "access_recommendations"
          )?.response;

        setRecommendationsEnabled(recommendations);
      }
    }
  }, [selectedEmployee, isOtp]);

  useEffect(() => {
    if (get_employee_category?.length > 0) {
      // find employee category for current user id
      const employeeCategory = get_employee_category?.find((c) => {
        return c?.employee_id === Number(id);
      });

      if (employeeCategory) {
        setEmployeeCategories(employeeCategory);
      } else {
        setEmployeeCategories([]);
      }
    }
  }, [get_employee_category]);

  useEffect(() => {
    if (
      employeeCategories &&
      employeeCategories?.category &&
      get_survey_structure.length > 0 &&
      isOtp
    ) {
      let otpView = true;
      const primaryList = get_survey_structure?.[0]?.categories.filter(
        (c) => c.priority === "primary"
      )?.[0]?.options;

      const empCategoriesIds = employeeCategories?.category.map(
        (ci) => ci?.value_id
      );

      primaryList.forEach((li) => {
        if (!empCategoriesIds.includes(li.id)) {
          otpView = false;
        }
      });

      setOtpToggle(otpView);
    }
  }, [employeeCategories, get_survey_structure, isOtp]);

  useEffect(() => {
    if (!personaOptions && isOtp && isAdmin) {
      setPersonaOptions(PERSONAS);
    }

    if (!personaOptions && isOtp && userPersona && PERSONAS && !isAdmin) {
      const personaFilter = PERSONAS.filter((p) => p.id > userPersona.id);
      setPersonaOptions(personaFilter);
    }
  }, [personaOptions, userPersona, isOtp, isAdmin]);

  useEffect(() => {
    if (get_organizations?.[get_org_id]?.org_category) {
      const _sport = get_organizations?.[get_org_id]?.org_category?.sport;
      if (_sport === "yes") {
        setNonSportOrg(false);
        return;
      }
      if (_sport === "no") {
        setNonSportOrg(true);
        return;
      }
      if (_sport === false) {
        setNonSportOrg(true);
        return;
      }
      setNonSportOrg(true);
    }
  }, [get_organizations, get_org_id]);

  useEffect(() => {
    if (get_error?.errors?.length > 0) {
      addToast("Error while updating user", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [get_error]);

  useEffect(() => {
    setEmployeeRecordCreated(get_employee_records?.created);
  }, [get_employee_records]);

  useEffect(() => {
    if (id) {
      setEmployee(get_employee?.[id]);
      setSelectedEmployee(get_employee?.[id]);
    }

    setUserEmployee(get_employee?.userEmp);
    setCurrentUser(get_employee?.userEmp);

    if (get_employee.userEmp?.survey_add_users === 1) {
      setCanEdit(true);
    }

    if (get_employee?.userEmp?.account_type === 5) {
      setIsCurrentUserAdmin(true);
    }

    if (get_employee.userEmp?.access_data_analytics === 1) {
      setCanAccessData(true);
    }
  }, [id, get_employee]);

  useEffect(() => {
    if (get_organizations) {
      const isOTPType = get_organizations.organization.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;

      setIsOtp(isOTPType);
    }
  }, [get_organizations]);

  useEffect(() => {
    if (get_employee && id) {
      const currentEmployee = get_employee?.employees.find((emp) => {
        return emp.id === Number(id);
      });

      if (id && !catDispatch && get_auth?.organization_id) {
        setCatDispatch(true);

        const employeeCategory = get_employee_category?.find((c) => {
          return c?.employee_id === Number(id);
        });

        // Only call dispatch if permission id is not found
        if (!employeeCategory) {
          dispatch({
            type: SG_GET_EMPLOYEE_ORG_CATEGORIES,
            payload: `organization=${get_auth?.organization_id}`,
          });
        }
      }

      if (
        get_employee?.userEmp?.account_type > selectedEmployee?.account_type
      ) {
        setNotEnoughPriv(true);
      }

      if (currentEmployee) {
        if (
          get_employee?.userEmp?.account_type === 5 &&
          get_employee?.userEmp.id !== currentEmployee?.id
        ) {
          setCanChangeAdmin(true);
        }

        if (
          get_employee?.userEmp?.account_type === 5 &&
          get_employee?.userEmp.id === currentEmployee.id
        ) {
          setShowSelfAdminError(true);
        }
      }
    }
  }, [dispatch, get_employee, id, selectedEmployee]);

  useEffect(() => {
    setEmployeeRecValue(get_employee_record_value);
  }, [get_employee_record_value]);

  useEffect(() => {
    if (employeePermissions?.updated) {
      setCurrentPermission(employeePermissions?.updated);
      return;
    }
    if (employeePermissions?.created) {
      setCurrentPermission(employeePermissions?.created);
      return;
    }
    setCurrentPermission(employeePermissions?.employee_permission?.[0]);
  }, [employeePermissions]);

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);

        return;
      }
      if (currentUser?.employee_employee_category?.category?.length > 0) {
        const filterCat = currentUser?.employee_employee_category?.category
          ?.filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value_id);
        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [
    currentUser,
    employeeCategories,
    canEdit,
    canAccessData,
    get_survey_structure,
    filteredCategories,
  ]);

  useEffect(() => {
    if (get_survey_structure && get_survey_structure.length > 0) {
      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const filteredPrimaryOptions =
        (!isAdmin &&
          primary.options.filter((po) => {
            return filteredCategories?.includes(po.id);
          })) ||
        primary.options;

      if (primary) {
        let options = [];
        let primaryApiOptions = [];
        let otpOptions = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((cat) => {
            if (
              (!cat.subfactors && cat.name) ||
              (cat.subfactors.length === 0 && cat.name)
            ) {
              options.push({
                key: cat.name,
                text: cat.name,
                value: cat.id,
              });
              primaryApiOptions.push({
                name: primary.name,
                name_id: primary.id,
                value: cat.name,
                priority: "primary",
                value_id: cat.id,
                id: cat.id,
              });
            } else {
              otpOptions.push({
                name: primary.name,
                name_id: primary.id,
                value: cat.name,
                priority: "primary",
                value_id: cat.id,
                id: cat.id,
              });
            }
          });
        }

        let subfactors = [];
        let apiSubfactors = [];
        if (filteredPrimaryOptions.length > 0) {
          filteredPrimaryOptions.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
                apiSubfactors.push({
                  name: primary.name,
                  name_id: primary.id,
                  value: sf.name,
                  priority: "primary",
                  value_id: sf.id,
                  id: sf.id,
                  group: sf.name,
                  mainGroup: o.name,
                });
              });
            }
          });
        } else {
          primary.options.forEach((o) => {
            if (o?.subfactors?.length > 0) {
              o.subfactors.forEach((sf) => {
                subfactors.push({ key: sf.name, text: sf.name, value: sf.id });
                apiSubfactors.push({
                  name: primary.name,
                  name_id: primary.id,
                  value: sf.name,
                  priority: "primary",
                  value_id: sf.id,
                  id: sf.id,
                  group: sf.name,
                  mainGroup: o.name,
                });
              });
            }
          });

          subfactors = subfactors.filter((sf) => {
            return filteredCategories?.includes(sf.value);
          });

          apiSubfactors = apiSubfactors.filter((sf) => {
            return filteredCategories?.includes(sf.value_id);
          });
        }

        if (subfactors.length > 0) {
          options.push(...subfactors);
        }
        if (apiSubfactors.length > 0) {
          primaryApiOptions.push(...apiSubfactors);
        }

        setPrimaryApiOptions(primaryApiOptions);
        setOtpPrimaryOptions(otpOptions);
        setTeamOptions(options);
      }

      const otherCategories = get_survey_structure[0]?.categories?.filter(
        (c) => c.priority !== "primary" && c.visibility === "public"
      );


      if (otherCategories.length > 0) {
        const categoryOptions = otherCategories.map((c) =>
          c?.options.map((cat) => {
            return {
              key: cat.name,
              text: cat.name,
              value: cat.id,
              name_id: c.id,
            };
          })
        );


        const otherCategoriesApiOptions = otherCategories.map((c) =>
          c?.options.map((cat) => {
            return {
              name: c.name,
              name_id: c.id,
              value: cat.name,
              value_id: cat.id,
              priority: c.priority,
              id: cat.id,
            };
          })
        );
        if (!categoryChanged) {
          setCategoryOptions(categoryOptions);
          setCategoryApiOptions(otherCategoriesApiOptions);
        }
      } else {
        // Don't show category tab if categories are empty.
        const tempTabs = [...tabs];
        const spliceIndex = tempTabs.findIndex((v) => v === "Category Options");
        if (spliceIndex > -1) {
          tempTabs.splice(spliceIndex, 1);
        }

        setTabs(tempTabs);
      }
    }
  }, [get_survey_structure, filteredCategories, isAdmin]);

  useEffect(() => {
    // Remove tab if no values
    if (employeeRecValue.length === 0) {
      const tempTabs = [...tabs];
      const spliceIndex = tempTabs.findIndex((t) => t === "Employee Records");
      if (spliceIndex > -1) {
        tempTabs.splice(spliceIndex, 1);
      }
      setTabs(tempTabs);
    } else {
      const tempTabs = [...tabs];
      if (!tabs.includes("Employee Records")) {
        tempTabs.push("Employee Records");
      }
      setTabs(tempTabs);
    }
  }, [employeeRecValue]);

  // Sets specific permissions based on canEdit property (isAdmin enables all)
  useEffect(() => {
    if (isAdmin) {
      setEditEnabled(true);
    }

    if (userPersona) {
      if (persona) {
        // Get PERSONA based on persona value
        const personaObj = PERSONAS.find((p) => p.value === persona);
        // Check if user has access to PERSONA
        if (personaObj.id > userPersona.id) {
          if (selectedEmployee?.account_type === 5) {
            setEditEnabled(false);
          } else {
            setEditEnabled(true);
          }
        } else {
          setEditEnabled(false);
        }
      } else {
        if (selectedEmployee?.account_type === 5) {
          setEditEnabled(false);
        } else {
          setEditEnabled(true);
        }
      }
    }

    if (
      canEdit &&
      team &&
      filteredCategories?.length > 0 &&
      !isAdmin &&
      !userPersona
    ) {
      let canEditUser = true;
      team.forEach((t) => {
        if (canEditUser) {
          if (!filteredCategories || !filteredCategories?.includes(t)) {
            canEditUser = false;
          }
        }
      });
      setEditEnabled(canEditUser);

      if (selectedEmployee?.account_type === 5) {
        setEditEnabled(false);
      }
    }
  }, [
    isAdmin,
    canEdit,
    team,
    filteredCategories,
    selectedEmployee,
    userPersona,
    persona,
  ]);

  useEffect(() => {
    if (categoryApiOptions) {
      try {
        const selectedCategories = employeeCategories?.category
          .filter((c) => c?.priority !== "primary")
          .map((c) => {
            const apiOptions = categoryApiOptions.find((cApi) => {
              return cApi[0].name_id === c.name_id;
            });

            const apiValue =
              apiOptions?.find((ao) => ao.value === c.value) ||
              apiOptions?.find((ao) => ao.value_id === c.value_id);
            return {
              value: apiValue?.id,
              name: c.value,
              category: c.name,
              name_id: c.name_id,
            };
          });
        if (selectedCategories?.length > 0) {
          setSelectedCategories(selectedCategories);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [categoryApiOptions, employeeCategories]);

  useEffect(() => {
    setTeam([]);
    setEmployeeCategoryId(false);
    if (employeeCategories && employeeCategories?.category) {
      const _team = employeeCategories?.category
        .filter((c) => {
          return c?.priority === "primary";
        })
        ?.map((c) => c?.value_id);

      // const employeeCatId =

      setTeam(_team);
    }

    if (employeeCategories && employeeCategories?.id) {
      setEmployeeCategoryId(employeeCategories?.id);
    }
  }, [employeeCategories]);

  const resetChanges = () => {
    if (selectedEmployee) {
      selectedEmployee?.access_data_analytics === 1 &&
        setAccessDatalytics(true);
      selectedEmployee?.access_subfactor_data === 1 && setAccessSubfactor(true);
      selectedEmployee?.email_status_text === "Validated" &&
        setValidatedEmail(true);
      selectedEmployee?.survey_add_users === 1 && setEditUsers(true);
      selectedEmployee?.account_type === 5 && setIsAdmin(true);
      selectedEmployee?.enable_login === true && setHasLogin(true);
      selectedEmployee?.read_feedback === 1 && setReadFeedback(true);
      selectedEmployee?.survey_schedule === 1 && setScheduleSurveys(true);
      selectedEmployee?.survey_status === 1 && setParticipateSurvey(true);
      selectedEmployee?.first_name &&
        setFirstName(selectedEmployee?.first_name);
      selectedEmployee?.last_name && setLastName(selectedEmployee?.last_name);
      selectedEmployee?.email && setEmail(selectedEmployee?.email);
    }
  };

  useEffect(() => {
    if (selectedEmployee) {
      selectedEmployee?.access_data_analytics === 1 &&
        setAccessDatalytics(true);
      selectedEmployee?.access_subfactor_data === 1 && setAccessSubfactor(true);
      selectedEmployee?.email_status_text === "Validated" &&
        setValidatedEmail(true);
      selectedEmployee?.survey_add_users === 1 && setEditUsers(true);
      selectedEmployee?.account_type === 5 && setIsAdmin(true);
      selectedEmployee?.enable_login === true && setHasLogin(true);
      selectedEmployee?.read_feedback === 1 && setReadFeedback(true);
      selectedEmployee?.survey_schedule === 1 && setScheduleSurveys(true);
      selectedEmployee?.survey_status === 1 && setParticipateSurvey(true);

      selectedEmployee?.first_name &&
        setFirstName(selectedEmployee?.first_name);
      selectedEmployee?.last_name && setLastName(selectedEmployee?.last_name);
      selectedEmployee?.email && setEmail(selectedEmployee?.email);
      // only applies to OTP, PDF Permission.
      // selectedEmployee?.enable_pdf === 1 && setPDFEnabled(true)
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (
      selectedEmployee &&
      (employeeCategories ||
        (catDispatch &&
          categoriesFetched &&
          !get_employee_category.length > 0)) &&
      get_survey_structure?.[0]
    ) {
      setLoading(false);
    }
  }, [
    selectedEmployee,
    get_employee_category?.[0],
    get_survey_structure?.[0],
    employeeCategories,
  ]);

  useEffect(() => {
    setEmployeeLog(get_employee_log);
  }, [get_employee_log]);

  useEffect(() => {
    setEmployeeRecord(get_employee_records);
  }, [get_employee_records]);

  useEffect(() => {
    const optionsArray = [];
    if (
      selectedEmployee &&
      isOtp &&
      employeeCategories &&
      get_survey_structure?.[0] &&
      !persona
    ) {
      selectedEmployee?.access_data_analytics === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_datalytics);
      selectedEmployee?.access_subfactor_data === 1 &&
        optionsArray.push(PERSONA_OPTIONS.access_subfactor);
      selectedEmployee?.survey_add_users === 1 &&
        optionsArray.push(PERSONA_OPTIONS.edit_users);
      selectedEmployee?.account_type === 5 &&
        optionsArray.push(PERSONA_OPTIONS.is_admin);
      selectedEmployee?.enable_login === true &&
        optionsArray.push(PERSONA_OPTIONS.enable_login);
      selectedEmployee?.read_feedback === 1 &&
        optionsArray.push(PERSONA_OPTIONS.read_feedback);
      selectedEmployee?.survey_schedule === 1 &&
        optionsArray.push(PERSONA_OPTIONS.schedule_survey);
      selectedEmployee?.survey_status === 1 &&
        optionsArray.push(PERSONA_OPTIONS.participate_survey);

      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );

      const newTeamArr = primary?.options.map((o) => o.name);

      const _team = employeeCategories?.category
        ?.filter((c) => {
          return c?.priority === "primary";
        })
        ?.map((c) => c?.value);

      if (
        _team &&
        _team?.length > 0 &&
        newTeamArr?.length > 0 &&
        newTeamArr.every((v) => _team.includes(v))
      ) {
        optionsArray.push(PERSONA_OPTIONS.otp_view);
      }

      if (
        selectedEmployee?.employee_employee_permission?.extra_details ||
        selectedEmployee?.employee_employee_permission?.auth_details
      ) {
        const hasUpdated =
          selectedEmployee?.employee_employee_permission?.extra_details
            ?.persona;
        const p = !hasUpdated
          ? selectedEmployee?.employee_employee_permission?.auth_details.find(
              (p) => {
                if (p?.persona) {
                  return p;
                }
              }
            )?.persona
          : selectedEmployee?.employee_employee_permission?.extra_details
              ?.persona;

        if (p && p.toLowerCase() === "cwf") {
          optionsArray.push(PERSONA_OPTIONS.is_cwf);
          if (!optionsArray.includes(PERSONA_OPTIONS.pdf_enabled)) {
            optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
          }
        }
      }

      if (selectedUserPdfFeatureEnabled) {
        if (!optionsArray.includes(PERSONA_OPTIONS.pdf_enabled)) {
          optionsArray.push(PERSONA_OPTIONS.pdf_enabled);
        }
      }

      const persona = PERSONAS.find((p) => {
        return p.options.sort().join(",") === optionsArray.sort().join(",");
      });
      if (persona) {
        setPersona(persona?.value);
        handlePersonaSelection(persona?.value, true);
      }
    }
  }, [
    selectedEmployee,
    isOtp,
    employeeCategories,
    get_survey_structure,
    persona,
    employeePermissions,
    selectedUserPdfFeatureEnabled,
  ]);

  const handlePersonaSelection = (value, onLoad) => {
    setPersona(value);
    const options = PERSONAS.find((p) => p.value === value)?.options;
    if (options) {
      // Set options based on inclusion of option.
      const enableLogin = options.includes(PERSONA_OPTIONS.enable_login);
      const editUsers = options.includes(PERSONA_OPTIONS.edit_users);
      const scheduleSurveys = options.includes(PERSONA_OPTIONS.schedule_survey);
      const participateSurvey = options.includes(
        PERSONA_OPTIONS.participate_survey
      );
      const readFeedback = options.includes(PERSONA_OPTIONS.read_feedback);
      const accessSubfactor = options.includes(
        PERSONA_OPTIONS.access_subfactor
      );
      const accessDatalytics = options.includes(
        PERSONA_OPTIONS.access_datalytics
      );
      const isAdminOption = options.includes(PERSONA_OPTIONS.is_admin);
      const pdfGenerate = options.includes(PERSONA_OPTIONS.pdf_enabled);
      const otpView = options.includes(PERSONA_OPTIONS.otp_view);
      const isCWF = options.includes(PERSONA_OPTIONS.is_cwf);
      setHasLogin(enableLogin);
      setEditUsers(editUsers);
      setScheduleSurveys(scheduleSurveys);
      setParticipateSurvey(participateSurvey);
      setReadFeedback(readFeedback);
      setAccessSubfactor(accessSubfactor);
      setAccessDatalytics(accessDatalytics);
      setIsAdmin(isAdminOption);
      setPDFEnabled(pdfGenerate);
      if (!onLoad) {
        handleOtpView(otpView, isCWF);
      }
      setCwfView(isCWF);
      // setShowCwfWarning(isCWF);
    } else {
      setHasLogin(false);
      setEditUsers(false);
      setScheduleSurveys(false);
      setParticipateSurvey(false);
      setReadFeedback(false);
      setAccessSubfactor(false);
      setAccessDatalytics(false);
      setIsAdmin(false);
      setPDFEnabled(false);
      if (!onLoad) {
        handleOtpView(false, false);
      }
      setCwfView(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: SG_GET_PROD_ORG_CONTRACT,
    });
    if (id) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `employee=${id}`,
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (cwfView) {
      let showCwf = true;
      team.forEach((t) => {
        // if (showCwfWarning) {
        //   if (teamOptions.find((to) => to.text === t)) {
        //     showCwf = false;
        //   }
        // }
        if (teamOptions.find((to) => to.text === t)) {
          showCwf = false;
        }
      });
      // setShowCwfWarning(showCwf);
    }
  }, [cwfView, team, persona]);

  const getAuditObj = () => {
    return {
      enable_login: hasLogin,
      first_name: firstName,
      last_name: lastName,
      survey_add_users: editUsers ? 1 : 0,
      account_type: isAdminOption ? 5 : 9,
      survey_schedule: scheduleSurveys ? 1 : 0,
      survey_status: participateSurvey ? 1 : 2,
      read_feedback: readFeedback ? 1 : 0,
      access_subfactor_data: accessSubfactor ? 1 : 0,
      access_data_analytics: accessDatalytics ? 1 : 0,
      id: selectedEmployee.id,
    };
  };

  const getPrimaryCat = () => {
    const updateObj = team.map((t) => {
      const apiOptions = primaryApiOptions;
      const teamIndex = apiOptions.findIndex((o) => {
        return o.value_id === t;
      });
      if (teamIndex > -1) {
        if (apiOptions[teamIndex]?.group) {
          const apiObj = {
            name: apiOptions[teamIndex].name,
            name_id: apiOptions[teamIndex]?.name_id,
            index: teamIndex,
            value: teamOptions[teamIndex].text,
            value_id: apiOptions[teamIndex].id,
            priority: "primary",
            group: apiOptions[teamIndex]?.group,
            mainGroup: apiOptions[teamIndex]?.mainGroup,
          };
          return apiObj;
        } else {
          const apiObj = {
            name: apiOptions[teamIndex].name,
            name_id: apiOptions[teamIndex]?.name_id,
            index: teamIndex,
            value: apiOptions[teamIndex].value,
            value_id: apiOptions[teamIndex].id,
            priority: "primary",
          };
          return apiObj;
        }
      }

      if (otpToggle) {
        const otpIndex = otpPrimaryOptions.findIndex((o) => {
          return o.value === t;
        });

        if (otpIndex > -1) {
          if (otpPrimaryOptions[otpIndex]?.group) {
            const apiObj = {
              name: otpPrimaryOptions[otpIndex].name,
              name_id: otpPrimaryOptions[otpIndex]?.name_id,
              index: otpIndex,
              value: teamOptions[otpIndex].text,
              value_id: otpPrimaryOptions[otpIndex].id,
              priority: "primary",
              group: otpPrimaryOptions[otpIndex]?.group,
              mainGroup: otpPrimaryOptions[otpIndex]?.mainGroup,
            };
            return apiObj;
          } else {
            const apiObj = {
              name: otpPrimaryOptions[otpIndex].name,
              name_id: otpPrimaryOptions[otpIndex]?.name_id,
              index: otpIndex,
              value: t,
              value_id: otpPrimaryOptions[otpIndex].id,
              priority: "primary",
            };
            return apiObj;
          }
        }
      }
      return null;
    });

    return updateObj;
  };

  // Function to archive employees
  const deactivateEmployee = (employee) => {
    // Active status = 1
    if (nonSportOrg) {
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: {
          id: employee,
          status: 99,
        },
      });
      // SG_ADD_EMPLOYEE_LOG
      dispatch({
        type: SG_ADD_EMPLOYEE_LOG,
        payload: {
          employee: employee,
          type_of_log: 2,
          description: {
            Archived: deactiveReason,
          },
        },
      });
    }

    setShowArchiveModal(false);

    const basePath = location.pathname.split("/").slice(0, -2).join("/");

    navigate(`/leader/members/inactive`);
  };

  // Function to archive employees
  const activateEmployee = (employee) => {
    // Active status = 1
    if (deactiveReason && nonSportOrg)
      dispatch({
        type: UPDATE_EMPLOYEE,
        payload: {
          id: employee?.id,
          status: 1,
        },
      });
    // SG_ADD_EMPLOYEE_LOG
    dispatch({
      type: SG_ADD_EMPLOYEE_LOG,
      payload: {
        employee: employee?.id,
        type_of_log: 2,
        description: {
          Archived: "Re-activate employee",
        },
      },
    });
  };

  const getCategorySelections = () => {
    const selections = [];
    selectedCategories.forEach((selection) => {
      const sIndex = categoryApiOptions.findIndex(
        (o) => o[0].name === selection.category
      );

      if (sIndex > -1) {
        const iIndex = categoryApiOptions[sIndex].findIndex(
          (o) => o.id === selection.value
        );
        if (iIndex > -1) {
          const apiObj = {
            name: categoryApiOptions[sIndex][iIndex].name,
            index: sIndex,
            value: categoryOptions[sIndex][iIndex].text,
            priority: categoryApiOptions[sIndex][iIndex].priority,
            value_id: categoryApiOptions[sIndex][iIndex].value_id,
            name_id: categoryApiOptions[sIndex][iIndex].name_id,
          };
          selections.push(apiObj);
        }
      }
    });
    return selections;
  };

  const handleSave = () => {
    const primaryCatSelections = getPrimaryCat() || [];
    const categorySelections =
      (selectedCategories && getCategorySelections()) || [];

    if (isOtp) {
      const _emp_per = get_employee_permission?.employee_org_permission
        ?.find((p) => p.employee_id === Number(id))
        ?.auth_details.filter((ep) => ep.name !== "pdf_enabled");
      const permId = get_employee_permission?.employee_org_permission?.find(
        (p) => p.employee_id === Number(id)
      )?.id;

      if (permId) {
        const extraPerms =
          get_employee_permission?.employee_org_permission?.find(
            (p) => p.employee_id === Number(id)
          );
        let _ExtExtrDet = extraPerms?.extra_details
          ? Object.keys(extraPerms?.extra_details)
              //Filter Object with key contains "persona"
              .filter((key) => key !== "persona")
              .reduce((obj, key) => {
                return Object.assign(obj, {
                  [key]: extraPerms?.extra_details[key],
                });
              }, {})
          : {};

        _ExtExtrDet["persona"] = persona;
        if (pdfEnabled) {
          _emp_per.push({
            name_id: "pdf",
            name: "pdf_enabled",
            value_id: "pdf",
            value: "PDF - Enabled",
            text: "Enabled PDF",
          });
        }

        dispatch({
          type: SG_EDIT_EMPLOYEE_PERMISSION,
          payload: {
            body: {
              id: permId,
              auth_details: _emp_per,
              extra_details: _ExtExtrDet,
            },
            query: `organization=${get_auth?.organization_id}`,
          },
        });
      } else {
        let selectedPerm = [];
        if (pdfEnabled) {
          selectedPerm = [
            {
              name_id: "pdf",
              name: "pdf_enabled",
              value_id: "pdf",
              value: "PDF - Enabled",
              text: "Enabled PDF",
            },
          ];
        }

        let _ExtExtrDet = get_employee_permission?.employee_permission?.[0]
          ?.extra_details
          ? Object.keys(
              get_employee_permission?.employee_permission?.[0]?.extra_details
            )
              //Filter Object with key contains "persona"
              .filter((key) => key !== "persona")
              .reduce((obj, key) => {
                return Object.assign(obj, {
                  [key]:
                    get_employee_permission?.employee_permission?.[0]
                      ?.extra_details[key],
                });
              }, {})
          : {};

        _ExtExtrDet["persona"] = persona;

        dispatch({
          type: SG_ADD_EMPLOYEE_PERMISSION,
          payload: {
            body: {
              employee: Number(id),
              auth_details: selectedPerm,
              extra_details: _ExtExtrDet,
            },
            query: `organization=${get_auth?.organization_id}`,
          },
        });
      }
    }

    if (employeeCategoryId) {
      dispatch({
        type: SG_EDIT_EMPLOYEE_CATEGORY,
        payload: {
          category: [...primaryCatSelections, ...categorySelections],
          id: employeeCategoryId,
        },
      });
    } else {
      dispatch({
        type: SG_ADD_EMPLOYEE_CATEGORY,
        payload: {
          category: [...primaryCatSelections, ...categorySelections],
          employee: employee?.id,
        },
      });
    }

    if (!isOtp && detailedPermissions?.length > 0) {
      const permId = get_employee_permission?.employee_permission?.find(
        (p) => p.employee_id === Number(id)
      )?.id;

      if (hasPermissionsObject) {
        // Edit the permissions
        dispatch({
          type: SG_EDIT_EMPLOYEE_PERMISSION,
          payload: {
            body: {
              id: permId,
              auth_details: detailedPermissions,
            },
            query: `organization=${get_auth?.organization_id}`,
          },
        });
      } else {
        // Add the permissions
        dispatch({
          type: SG_ADD_EMPLOYEE_PERMISSION,
          payload: {
            body: {
              employee: Number(id),
              auth_details: detailedPermissions,
            },
          },
          query: `organization=${get_auth?.organization_id}`,
        });
      }
    }

    const auditObject = getAuditObj();
    dispatch({
      type: SG_UPDATE_EMPLOYEE_AUDIT,
      payload: {
        account_type: auditObject?.account_type,
        enable_login: auditObject.enable_login,
        first_name: auditObject.first_name,
        last_name: auditObject?.last_name,
        survey_add_users: auditObject?.survey_add_users,
        survey_schedule: auditObject?.survey_schedule,
        survey_status: auditObject?.survey_status,
        read_feedback: auditObject?.read_feedback,
        access_subfactor_data: auditObject?.access_subfactor_data,
        access_data_analytics: auditObject?.access_data_analytics,
        id: selectedEmployee.id,
      },
    });
    addToast("Updated user!", { appearance: "success", autoDismiss: true });
  };

  useEffect(() => {
    if (get_survey_structure.length > 0) {
      const _primaryList =
        get_survey_structure?.[0]?.field_map?.field_name.find(
          (f) => f.priority === "primary"
        );
      const _primarySelectList = [];
      get_survey_structure?.[0]?.field_map?.field_name
        .find((f) => f.priority === "primary")
        ?.answers?.map((lst, i) => {
          if (isAdmin) {
            _primarySelectList.push({
              value: lst.name,
              index: i,
              label: lst.name,
              name: _primaryList?.original,
              priority: "primary",
            });
          } else {
            if (userEmployeeCategoryList?.includes(lst.name)) {
              _primarySelectList.push({
                value: lst.name,
                index: i,
                label: lst.name,
                name: _primaryList?.original,
                priority: "primary",
              });
            }
          }
          return null;
        });

      setPrimaryCategory({
        original: _primaryList?.original,
        answers: _primarySelectList,
      });

      const _secondaryList =
        get_survey_structure?.[0]?.field_map?.field_name.find(
          (f) => f.priority === "secondary"
        );

      const _secondarySelectList =
        get_survey_structure?.[0]?.field_map?.field_name
          .find((f) => f.priority === "secondary")
          ?.answers?.map((lst, i) => {
            return {
              index: i,
              value: lst.name,
              label: lst.name,
              name: _secondaryList?.original,
              priority: "secondary",
            };
          });

      setSecondaryCategory({
        original: _secondaryList?.original,
        answers: _secondarySelectList,
      });

      get_survey_structure?.[0]?.field_map?.field_name.find(
        (f) => f.priority === "secondary"
      );

      const _orgCategory = [];
      get_survey_structure?.[0]?.categories
        .filter((f) => f.priority !== "primary" && f?.visibility)
        .map((og) => {
          if (og?.visibility === "public") {
            _orgCategory.push(og);
          }
          return null;
        });

      setOrgCategory(_orgCategory);
    }
  }, [get_survey_structure, isAdmin, userEmployeeCategoryList]);

  function ViewFullProfile() {
    setOpenFullCard(true);
    setFullEmpData(data);
  }

  function ViewTeamOptions() {
    setOpenTeamCard(true);
    setFullEmpData(data);
  }

  useEffect(() => {
    setSummaryEmail(
      get_employee_permission?.employee_permission?.[0]?.auth_details.filter(
        (ep) => ep.value === "summary_email"
      )?.[0]
    );
  }, [
    get_employee_permission?.pending,
    get_employee_permission?.employee_permission,
  ]);

  function ChangeSummaryEmail() {
    const EmpPerId = get_employee_permission?.employee_permission?.find(
      (p) => p.employee_id === Number(id)
    )?.id;

    if (EmpPerId) {
      let _emailSummary = summaryEmail;

      if (_emailSummary) {
        // change the value
        if (_emailSummary?.response) {
          _emailSummary.response = false;
        } else {
          _emailSummary.response = true;
        }
      } else {
        _emailSummary = {
          value: "summary_email",
          response: true,
        };
      }

      const _emp_per =
        get_employee_permission?.employee_permission
          ?.find((p) => p.employee_id === Number(id))
          ?.auth_details.filter((ep) => ep.value !== "summary_email") || [];
      _emp_per.push(_emailSummary);
      //  existing value of auth summary found
      // replace email summary with this value
      dispatch({
        type: SG_EDIT_EMPLOYEE_PERMISSION,
        payload: {
          body: {
            id: EmpPerId,
            auth_details: _emp_per,
          },
          query: `organization=${get_auth?.organization_id}`,
        },
      });
    } else {
      dispatch({
        type: SG_ADD_EMPLOYEE_PERMISSION,
        payload: {
          body: {
            employee: Number(id),
            auth_details: [
              {
                value: "summary_email",
                response: true,
              },
            ],
          },
        },
      });
    }
  }

  const handleTeamSelection = (value) => {
    let newTeamArr = value
      .filter((v) => !!v)
      .map((v) => {
        return teamOptions.find((t) => t.value === v).value;
      });

    const teamsTemp = (team && team.length > 0 && [...team]) || [];
    const tIndex = teamsTemp.findIndex((t) => t === newTeamArr);
    if (tIndex > -1) {
      teamsTemp.splice(tIndex, 1);
    } else {
      teamsTemp.push(...newTeamArr);
    }

    if (otpToggle) {
      const primary = get_survey_structure[0]?.categories?.find(
        (c) => c.priority === "primary"
      );
      const primaryTeams = primary?.options.map((o) => o.name);
      newTeamArr = [...newTeamArr, ...primaryTeams];
      // handleOtpView(true, true, newTeamArr);
    }

    setTeam(newTeamArr);
  };

  const addAllCategories = () => {
    let isCWF = persona === "CWF";
    const newTeamArr = teamOptions.map((v) => {
      return v.value;
    });

    const teamsTemp = [...team];
    const tIndex = teamsTemp.findIndex((t) => t === newTeamArr);
    if (tIndex > -1) {
      teamsTemp.splice(tIndex, 1);
    } else {
      teamsTemp.push(...newTeamArr);
    }
    if (isCWF) {
      handleOtpView(true, true, newTeamArr);
    } else {
      setTeam(newTeamArr);
    }
  };

  const removeAllCategories = () => {
    setTeam([]);
  };

  const handleCategorySelection = (value, index) => {
    const selectedTemp = [...selectedCategories];
    const updatedIndex = selectedTemp.findIndex(
      (s) => s.category === categoryApiOptions[index][0].name
    );
    if (updatedIndex > -1 && selectedTemp) {
      selectedTemp[updatedIndex] = {
        value: value,
        category: categoryApiOptions[index][0].name,
        value_id: categoryApiOptions[index][0].value_id,
        name_id: categoryApiOptions[index][0].name_id,
      };
    } else {
      selectedTemp.push({
        value: value,
        category: categoryApiOptions[index][0].name,
        value_id: categoryApiOptions[index][0].value_id,
        name_id: categoryApiOptions[index][0].name_id,
      });
    }
    setSelectedCategories(selectedTemp);
  };

  const handleOtpView = (s, cwf, teamAdd) => {
    const primary = get_survey_structure[0]?.categories?.find(
      (c) => c.priority === "primary"
    );

    const newTeamArr = primary?.options.map((o) => o.name);
    if (s) {
      if (cwf) {
        if (teamAdd) {
          const newTeam = [...newTeamArr, ...teamAdd];
          setTeam(newTeam);
        } else {
          const newTeam = [...team, ...newTeamArr];
          setTeam(newTeam);
        }
      } else {
        const newTeam = [...newTeamArr];
        setTeam(newTeam);
      }
    } else {
      const newTeam = [...team].filter((t) => {
        return newTeamArr.some((ts) => ts !== t);
      });
      setTeam(newTeam);
    }
    setOtpToggle(s);
    if (cwfView && s) {
      // setShowCwfWarning(true);
    }
  };

  const handleAdminChange = (value) => {
    if (canChangeAdmin) {
      setIsAdmin(value);
    }
  };

  return (
    <Dimmer.Dimmable blurring dimmed={loading}>
      {/* TOP SECTION */}
      <TopSection>
        <TopTitle>
          {selectedEmployee?.first_name} {selectedEmployee?.last_name}
        </TopTitle>
        <FixedButtons>
          <SaveControls>
            <CancelButton onClick={() => resetChanges()}>
              <CancelButtonText>Cancel</CancelButtonText>
              <CancelButtonIcon>{XIcon()}</CancelButtonIcon>
            </CancelButton>

            {(isAdmin || editEnabled) &&
              selectedEmployee?.id !== currentUser?.id &&
              ((selectedEmployee?.status === 1 && (
                <Modal
                  onClose={() => setShowArchiveModal(false)}
                  onOpen={() => setShowArchiveModal(true)}
                  size="tiny"
                  open={showArchiveModal}
                  trigger={
                    <ArchiveButton>
                      <SaveIconWrapper>{TrashIcon()}</SaveIconWrapper>
                      <SaveText>Archive</SaveText>
                    </ArchiveButton>
                  }
                >
                  <ModalContents>
                    <ModalHeader>
                      Are you sure you want to archive this employee?
                    </ModalHeader>
                    <ModalBody>
                      <InputDescription>
                        Please enter a reason for archiving this user:
                      </InputDescription>
                      <InputContainer>
                        <RowData
                          value={deactiveReason}
                          placeholder="Archive reason"
                          onChange={(s, t) => {
                            setDeactiveReason(s.target.value);
                          }}
                        />
                      </InputContainer>
                      <ModalButtons>
                        <ArchiveCancelButton
                          onClick={() => setShowArchiveModal(false)}
                        >
                          <CancelButtonText>Cancel</CancelButtonText>
                          <CancelButtonIcon>{XIcon()}</CancelButtonIcon>
                        </ArchiveCancelButton>
                        <ArchiveConfirmButton
                          onClick={() =>
                            deactivateEmployee(selectedEmployee?.id)
                          }
                        >
                          <SaveIconWrapper>{TrashIcon()}</SaveIconWrapper>
                          <SaveText>Archive</SaveText>
                        </ArchiveConfirmButton>
                      </ModalButtons>
                    </ModalBody>
                  </ModalContents>
                </Modal>
              )) || (
                <ArchiveConfirmButton
                  onClick={() => activateEmployee(selectedEmployee?.id)}
                >
                  <SaveIconWrapper>{TrashIcon()}</SaveIconWrapper>
                  <SaveText>Re-Activate</SaveText>
                </ArchiveConfirmButton>
              ))}

            <SaveButton
              // showCwfWarning={showCwfWarning}
              onClick={() => handleSave()}
            >
              <SaveIconWrapper>{SaveIcon()}</SaveIconWrapper>
              <SaveText>Save</SaveText>
            </SaveButton>
          </SaveControls>
        </FixedButtons>
      </TopSection>
      <Divider />
      {(isAdmin ||
        (editEnabled && selectedEmployee?.id !== currentUser?.id)) && (
        <>
          <TabsContainer>
            <Tabs
              options={tabs}
              onSelect={(item) => setSelectedTab(item)}
              selected={selectedTab}
              isOtp={isOtp}
              showTabs={showTabs}
            />
          </TabsContainer>
          <Divider />
        </>
      )}
      {tabs[selectedTab] === "Profile" && (
        <ProfileSection>
          <RowInfo>
            <RowLabel>First Name</RowLabel>
            <RowData
              value={firstName}
              onChange={(s) => setFirstName(s.target.value)}
            />
          </RowInfo>
          <RowInfo>
            <RowLabel>Last Name</RowLabel>
            <RowData
              value={lastName}
              onChange={(s) => setLastName(s.target.value)}
            />
          </RowInfo>
          <RowInfo>
            <RowLabel>Email</RowLabel>
            <RowData value={email} onChange={(s) => {}} />
          </RowInfo>
          {(isAdmin ||
            (editEnabled && selectedEmployee?.id !== currentUser?.id)) && (
            <>
              <RowInfo>
                <RowLabel>Delivery Information</RowLabel>
                <ValidatedSection>
                  <ValidText>
                    {selectedEmployee?.email_status_text === "Validated"
                      ? "Validated"
                      : "Pending"}
                  </ValidText>
                  {selectedEmployee?.email_status_text !== "Validated" && (
                    <SignupToken
                      organization_id={selectedEmployee?.organization}
                      employee_id={selectedEmployee?.id}
                    />
                  )}
                  {selectedEmployee?.email_status_text === "Validated" && (
                    <EmailDeliveryReport employee_id={selectedEmployee?.id} />
                  )}
                </ValidatedSection>
              </RowInfo>
              {(isAdmin || canEdit) && isOtp && personaOptions && (
                <>
                  {/* <CatRowInfo>
                    <RowLabel>OTP View</RowLabel>
                    <CatSelectionContainer>
                      <ToggleComponent
                        toggle
                        checked={otpToggle}
                        handleCheck={(s) => handleOtpView(s)}
                      />
                      <ControlDescription>
                        <DescriptionTitle>Enable OTP View</DescriptionTitle>
                        <DescriptionText>
                          Member will access all of the OTP Targets, rather than
                          sport specific.
                        </DescriptionText>
                      </ControlDescription>
                    </CatSelectionContainer>
                  </CatRowInfo> */}

                  <CatRowInfo>
                    <RowLabel>Permissions Profile</RowLabel>
                    <CatSelectionContainer>
                      <Dropdown
                        placeholder={`Select a Permission`}
                        clearable
                        fluid
                        selection
                        options={personaOptions}
                        value={persona}
                        style={{
                          borderRadius: 10,
                          fontFamily: "Poppins",
                        }}
                        onChange={(e, t) => {
                          handlePersonaSelection(t.value);
                        }}
                      />
                    </CatSelectionContainer>
                  </CatRowInfo>
                </>
              )}
              {(persona !== "OTP Admin" || showTabs) && (
                <>
                  <CatRowInfo>
                    <RowLabel>{primaryApiOptions?.[0]?.name}</RowLabel>
                    <CatSelectionContainer>
                      <Dropdown
                        placeholder={`Select a ${primaryApiOptions?.[0]?.name}`}
                        fluid
                        selection
                        multiple
                        options={teamOptions}
                        value={
                          (team?.length > 0 &&
                            team?.map((t) => {
                              return teamOptions?.find((op) => {
                                return op.value === t;
                              })?.value;
                            })) ||
                          []
                        }
                        style={{
                          borderRadius: 10,
                          fontFamily: "Poppins",
                        }}
                        onChange={(e, t) => {
                          handleTeamSelection(t.value);
                        }}
                      />
                    </CatSelectionContainer>
                    {/* {showCwfWarning && (
                      <ToolTipDesc>
                        <Popup
                          trigger={
                            <Icon circular color="red" name="warning sign" />
                          }
                          content="CWF Requires at least one team to be selected."
                          position="top center"
                          inverted
                        />
                      </ToolTipDesc>
                    )} */}
                  </CatRowInfo>
                  <AddAllControls>
                    <CancelButton onClick={() => removeAllCategories()}>
                      <CancelButtonText>Remove All</CancelButtonText>
                      <CancelButtonIcon>{XIcon()}</CancelButtonIcon>
                    </CancelButton>
                    <SaveButton onClick={() => addAllCategories()}>
                      <SaveText>Add All</SaveText>
                    </SaveButton>
                  </AddAllControls>
                </>
              )}
            </>
          )}
        </ProfileSection>
      )}

      {/* CATEGORY SELECTION SECTION */}
      {(isAdmin ||
        (editEnabled && selectedEmployee?.id !== currentUser?.id)) && (
        <>
          {(tabs[selectedTab] === "Category Options" ||
            (tabs[selectedTab] !== "Profile" && isOtp)) && (
            <CategorySection>
              <CategoryHeader>
                <IconWrapper>{CategoryIcon()}</IconWrapper>
                <CategoryLabel>Category Tags</CategoryLabel>
              </CategoryHeader>
              <CategoryRowInfo>
                <PermissionsControls>
                  {categoryApiOptions &&
                    categoryApiOptions.map((catOp, i) => {
                      return (
                        <ControlWrapper key={i}>
                          <CategoryTag>{catOp[0]?.name}</CategoryTag>
                          <TableContent>
                            <SelectionContainer>
                              <Dropdown
                                placeholder={`Select a ${catOp[0]?.name}`}
                                fluid
                                selection
                                clearable
                                options={categoryOptions[i]}
                                value={
                                  categoryOptions[i]?.find((op) => {
                                    const selectedValue =
                                      selectedCategories?.find((c) => {
                                        return c.name_id === catOp[0]?.name_id;
                                      })?.value;

                                    return op.value === selectedValue;
                                  })?.value
                                }
                                style={{
                                  borderRadius: 100,
                                  fontFamily: "Poppins",
                                }}
                                onChange={(e, t) => {
                                  setCategoryChanged(true);
                                  handleCategorySelection(t.value, i);
                                }}
                              />
                            </SelectionContainer>
                          </TableContent>
                        </ControlWrapper>
                      );
                    })}
                </PermissionsControls>
              </CategoryRowInfo>
            </CategorySection>
          )}
          {!loading &&
            (!isOtp || showTabs) &&
            tabs[selectedTab] === "Permissions" && (
              <PermissionsSection>
                <PermissionsHeader>
                  <IconWrapper>{LockIcon()}</IconWrapper>
                  <PermissionsLabel>Permission Controls</PermissionsLabel>
                </PermissionsHeader>
                <PermissionsControls>
                  <ControlWrapper>
                    {/* Create a list of detailed permissions as defined by the array at the top */}
                    {DETAILED_PERMISSIONS.map((permission, i) => {
                      return (
                        <ControlWrapper key={i}>
                          <ToggleComponent
                            toggle
                            checked={recommendationsEnabled}
                            handleCheck={(s) => {
                              setRecommendationsEnabled(
                                !recommendationsEnabled
                              );
                              setDetailedPermissions(
                                detailedPermChange(
                                  detailedPermissions,
                                  permission?.permissionValue
                                )
                              );
                            }}
                          />
                          <ControlDescription>
                            <DescriptionTitle>
                              {permission.permissionLabel}
                            </DescriptionTitle>
                            <DescriptionText>
                              {permission.permissionDescription}
                            </DescriptionText>
                          </ControlDescription>
                        </ControlWrapper>
                      );
                    })}
                    {/* Can Generate PDFs */}
                    {isOtp && (
                      <>
                        <ToggleComponent
                          toggle
                          checked={pdfEnabled}
                          handleCheck={(s) => setPDFEnabled(s)}
                        />
                        <ControlDescription>
                          <DescriptionTitle>
                            Can generated PDF's
                          </DescriptionTitle>
                          <DescriptionText>
                            Member will have access to generate PDF reports.
                          </DescriptionText>
                        </ControlDescription>
                      </>
                    )}
                  </ControlWrapper>
                  {/* Can receive Summary email */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={summaryEmail?.response}
                      handleCheck={ChangeSummaryEmail}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Summary email</DescriptionTitle>
                      <DescriptionText>
                        Member will recieve a summary email
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can participate in surveys */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={participateSurvey}
                      handleCheck={(s) => setParticipateSurvey(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Participate in Survey</DescriptionTitle>
                      <DescriptionText>
                        Member will participate in all audit survey's associated
                        with their given categories
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Access Subfactor Data */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={accessDatalytics}
                      handleCheck={(s) => setAccessDatalytics(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Access Data Analytics</DescriptionTitle>
                      <DescriptionText>
                        Member will get access to data analytics
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Access Data Analytics */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={accessSubfactor}
                      handleCheck={(s) => setAccessSubfactor(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Access Subfactor Data</DescriptionTitle>
                      <DescriptionText>
                        Member will get acces to subfactor data
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can Read Feedback */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={readFeedback}
                      handleCheck={(s) => setReadFeedback(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Can Read Feedback</DescriptionTitle>
                      <DescriptionText>
                        Member can read feedback
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can Schedule Surveys */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={scheduleSurveys}
                      handleCheck={(s) => setScheduleSurveys(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Can Schedule Surveys</DescriptionTitle>
                      <DescriptionText>
                        The ability to schedule and manage survey's for the
                        specified granted permissioned groups
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Can Edit Users */}
                  <ControlWrapper>
                    <ToggleComponent
                      checked={editUsers}
                      handleCheck={(s) => setEditUsers(s)}
                    />
                    <ControlDescription>
                      <DescriptionTitle>Can Edit Users</DescriptionTitle>
                      <DescriptionText>
                        The ability to manage users for the specificed
                        permissioned groups (invite,edit,remove)
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Has a Login Account */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={hasLogin}
                      handleCheck={(s) => setHasLogin(s)}
                    />
                    {/* lock */}
                    <ControlDescription>
                      <DescriptionTitle>
                        Has a Login Account{" "}
                        {hasLogin ? (
                          selectedEmployee?.account ? (
                            <ToolTipDesc>
                              <Popup
                                trigger={
                                  <Icon circular color="green" name="lock" />
                                }
                                content="The user has created a login"
                                position="top center"
                                inverted
                              />
                            </ToolTipDesc>
                          ) : (
                            <ToolTipDesc>
                              <Popup
                                trigger={
                                  <Icon
                                    circular
                                    color="red"
                                    name="warning sign"
                                  />
                                }
                                content="This user has not created a login, and can use forget password link to resend the invite email"
                                position="top center"
                                inverted
                              />
                            </ToolTipDesc>
                          )
                        ) : (
                          ""
                        )}
                      </DescriptionTitle>

                      <DescriptionText>
                        Login access grants this user access to the results and
                        analytics dashboards associated with the specific
                        permissions granted
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {/* Is an Account Administrator */}
                  <ControlWrapper>
                    <ToggleComponent
                      toggle
                      checked={isAdminOption}
                      disabled={!canChangeAdmin}
                      handleCheck={(s) => handleAdminChange(s)}
                    />
                    <ControlDescription disable={showSelfAdminError}>
                      <DescriptionTitle>
                        Is an Account Administrator
                      </DescriptionTitle>
                      <DescriptionText>
                        Has access to all reports, results, settings, user
                        management and team management for the entire
                        organization
                      </DescriptionText>
                    </ControlDescription>
                  </ControlWrapper>
                  {showSelfAdminError && (
                    <ErrorLabel>
                      You cannot remove your self as admin, doing so would
                      result in you being locked out of your account.
                    </ErrorLabel>
                  )}
                </PermissionsControls>
              </PermissionsSection>
            )}
          {selectedEmployee?.id && tabs[selectedTab] === "Employee Records" && (
            <CategorySection>
              <CategoryHeader>
                <CategoryLabel>Employee Records</CategoryLabel>
              </CategoryHeader>
              {(employeeRecValue &&
                employeeRecValue
                  .filter((rv) => {
                    return rv.data_action === 2;
                  })
                  .map((rval, i) => {
                    return (
                      <div key={i}>
                        <TableTitle>
                          <CategoryLabel>{rval?.name}</CategoryLabel>
                        </TableTitle>
                        <TableContainer>
                          <TopTableRow>
                            <TableHeading>Date</TableHeading>
                            <TableHeading>Value</TableHeading>
                          </TopTableRow>
                          {employeeRecord &&
                            employeeRecord
                              .filter((rv) => {
                                return rv.employee_record_value_id === rval?.id;
                              })
                              .map((rec, ii) => {
                                return (
                                  <TableRow key={ii}>
                                    <TableContentLabel>
                                      {format(
                                        new Date(
                                          rec?.created_at?.toLocaleString()
                                        ),
                                        "EEEE, MMM do yyyy h:mm a"
                                      )}
                                    </TableContentLabel>
                                    <TableContent>{rec?.value}</TableContent>
                                  </TableRow>
                                );
                              })}
                        </TableContainer>

                        {/*  */}
                        <AddEmpRecRow
                          employee={selectedEmployee?.id}
                          valueName={rval?.name}
                          employee_record_value={rval?.id}
                        />
                        {/*  */}
                      </div>
                    );
                  })) || (
                <EmptyLabel>No records available for this employee</EmptyLabel>
              )}
            </CategorySection>
          )}
          {/* Detailed Permission */}
          {selectedEmployee?.id &&
            tabs[selectedTab] === "Detailed Permission" && (
              <CategoryPermission
                selectedEmployee={selectedEmployee}
                employeeCategory={get_employee_category}
                currentUser={currentUser}
                primaryApiOptions={primaryApiOptions}
              />
            )}
          {selectedEmployee?.id && tabs[selectedTab] === "Employee Log" && (
            <CategorySection>
              <CategoryHeader>
                <CategoryLabel></CategoryLabel>
              </CategoryHeader>

              <TableContainer>
                {(employeeLog && employeeLog.length > 0 && (
                  <TopTableRow>
                    <TableHeading>Date</TableHeading>
                    <TableHeading>Log</TableHeading>
                  </TopTableRow>
                )) || (
                  <EmptyLabel>No logs available for this employee</EmptyLabel>
                )}
                {employeeLog &&
                  employeeLog.map((rec, i) => {
                    return (
                      <TableRow key={i}>
                        <TableContentLabel>{rec?.created_at}</TableContentLabel>
                        <TableContentLabel>
                          {JSON.stringify(rec?.description)}
                        </TableContentLabel>
                      </TableRow>
                    );
                  })}
              </TableContainer>
            </CategorySection>
          )}
        </>
      )}

      <Dimmer active={loading}>
        <Loader />
        <Header as="h2" icon inverted>
          Loading, Please wait.
        </Header>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default Edit;

const EmptyLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: "100%";
  align-self: center;
  /* identical to box height */
  color: #6d6d6d;
`;

const AddAllControls = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 300px;
  align-items: center;
  justify-content: space-between;
  width: 480px;
`;

const TableRow = styled.div`
  height: 82px;
  display: flex;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  /* Inside auto layout */
  margin-top: 5px;
  order: 0;
  flex-direction: row;
  flex-grow: 0;
  :hover {
    background-color: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.87);
  }
`;

const TableTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  align-self: center;
  /* identical to box height */
  color: #6d6d6d;
`;

const TableContentLabel = styled.div`
  width: 300px;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const CategoryTag = styled.div`
  width: 250px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const ModalContents = styled.div`
  background-color: white;
  padding-left: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 50px;
`;

const ModalHeader = styled.div`
  font-family: "Poppins";
  padding-bottom: 45px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;

  color: #414141;
`;

const ModalBody = styled.div`
  padding-top: 5px;
`;

const ToolTipDesc = styled.span`
  padding: 5px;
  cursor: pointer;
`;

const InputDescription = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const InputContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
`;

const TableContent = styled.div`
  width: 75%;
  padding-left: 80px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-right: 150px;
  color: #7e7e7e;
`;

const ArchiveButton = styled.div`
  padding: 3px 20px;
  cursor: pointer;

  background: #f3aa3d;
  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const ArchiveCancelButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 45px;
  cursor: pointer;
`;

const ArchiveConfirmButton = styled.div`
  width: 173px;
  height: 44px;
  cursor: pointer;

  background: #e5657a;
  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ErrorLabel = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height */
  align-self: center;
  color: red;
`;

const SelectionContainer = styled.div`
  padding-left: 25px;
  width: 100%;
`;

const CatSelectionContainer = styled.div`
  margin-left: -90px;
  min-width: 250px;
  max-width: calc(100% - 300px);
`;

const SaveIconWrapper = styled.div`
  padding-right: 8px;
  cursor: pointer;
`;

const SaveText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 15px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;

const CancelButton = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 15px;
  cursor: pointer;
`;

const CancelButtonIcon = styled.div`
  line-height: 15px;
  padding-top: 1px;
  padding-left: 5px;
  cursor: pointer;
`;

const CancelButtonText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #c0c0c0;
  cursor: pointer;
`;

const SaveButton = styled.div`
  padding: 3px 20px;
  cursor: pointer;

  background: #476dfa;
  border-radius: 100px;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.showCwfWarning ? 0.4 : 1)};
`;

const SaveControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PermissionsLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 10px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const TableHeading = styled.div`
  width: 25%;
  padding-left: 40px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #414141;
`;

const TopTableRow = styled.div`
  display: flex;
  background: #ffffff;
  height: 51px;
  padding-bottom: 5px;
  /* drop */
  flex-direction: row;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-top: 35px;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
`;

const TopTitle = styled.div`
  font-family: "Poppins";
  padding-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  padding-left: 83px;

  color: #414141;
`;

const ControlDescription = styled.div`
  padding-left: 20px;
  width: 409px;
  height: 38px;
  opacity: ${(props) => (props.disable ? 0.4 : 1)};
`;

const DescriptionTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #414141;
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
`;

const ToggleSlider = styled.div`
  position: relative;
  width: 40px;
  height: 16px;
  background: #e7e7e7;
  border-radius: 26.6667px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 15px;
    height: 16px;
    border-radius: 35px;
    top: 50%;
    left: 4px;
    background: ${(props) => (props.isChecked ? "#66d686" : "#c0c0c0")};
    transform: translate(0, -50%);
  }
`;

const ToggleInput = styled.input`
  display: none;

  &:checked + ${ToggleSlider} {
    background: #e7e7e7;

    &:before {
      transform: translate(20px, -50%);
    }
  }
`;

const DescriptionText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: #7e7e7e;
`;

const FixedButtons = styled.div`
  bottom: 10px;
  padding-left: 30px;
  align-items: flex-end;
`;

const PermissionsControls = styled.div``;

const ValidatedSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 30px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 30px;
  margin-bottom: -16px;
`;

const ValidText = styled.label`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #9e9fa1;
`;

const CategorySection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const CategoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

const CategoryLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 10px;
  /* identical to box height */
  align-self: center;
  color: #414141;
`;

const PermissionsSection = styled.div`
  padding-left: 83px;
  padding-top: 40px;
`;

const PermissionsHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconWrapper = styled.div``;

const ProfileSection = styled.div`
  padding-top: 20px;
`;

const CatRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  width: 100%;
  padding-left: 83px;
`;

const RowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  width: 566px;
  justify-content: space-between;
  padding-left: 83px;
`;

const CategoryRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  width: 566px;
  justify-content: space-between;
`;

const RowLabel = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  width: 300px;
  align-self: center;
  /* identical to box height */
  color: #6d6d6d;
`;

const RowData = styled.input`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 40px 12px 32px;
  gap: 10px;
  width: 376px;
  height: 48px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  align-items: center;

  color: #9e9fa1;

  border: 1px solid #dfdfdf;
  border-radius: 99px;
`;

const EditContainer = styled.div`
  flex: 1;
  padding-bottom: 50px;
`;
