import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";


const Description = () => {

    return (
        <Container>
            <h3>Job Description</h3>
            <P>{`Compensation: $55,000 / yr + additional stock options

Location: Nova Scotia / Remote

We are looking for a talented and enthusiastic Junior Front-End Software Engineer to join our team. As a member of our team, you will be responsible for developing and maintaining web applications that are both functional and visually appealing. You will work with a team of designers, developers, and project managers to create dynamic and engaging user interfaces.

Responsibilities:
●	Develop and maintain web applications using Python and React
●	Collaborate with designers, developers, and project managers to develop user interfaces that meet business and user needs
●	Write clean, efficient, and maintainable code
●	Conduct code reviews and provide constructive feedback to team members
●	Participate in team meetings, scrums, and other project-related activities
●	Keep up-to-date with the latest web development trends and technologies
●	Troubleshoot and debug issues with web applications
●	Continuously improve and optimize web applications for performance, scalability, and maintainability

Requirements:
●	Bachelor's degree in Computer Science, Software Engineering or related field
●	Strong proficiency in Python and React
●	Understanding of web development fundamentals such as HTML, CSS, and JavaScript
●	Knowledge of modern web development frameworks and libraries (e.g., React, Vue, Angular)
●	Experience with Git, Agile/Scrum development methodologies, and Jira
●	Good communication and collaboration skills
●	Attention to detail and a desire to produce high-quality work`}
</P>
        </Container>
    )
}

export default Description


const Container = styled.div`
    margin-top:30px;
`

const P = styled.p`
white-space: pre-line;
`