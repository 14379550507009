import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Dropdown, Button, Loader } from "semantic-ui-react";
import { SG_POST_CHAT_GPT } from "constants/actions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getTranslatedText } from "./pdfUtils";
import translations from "./translations.json";

// return any comments under the questions object, where the order is included
// in the passed array of orderIds.
const getSurveyCommentQuestions = (orderIds, surveyQuestions) => {
  const comments = [];
  surveyQuestions.questions.comments.forEach((question) => {
    if (orderIds.includes(question.order)) {
      comments.push(question);
    }
  });
  return comments;
};

// Loop through reportData, loop through the responses, find comments with order matching id of parameter
// and add to the response object.
const getCommentResponsesByOrder = (reportData, order, selectedSport) => {
  let responseComments = [];
  const filteredReport = reportData.responses?.filter((r) => {
    return (
      r?.categories?.find((c) => {
        return c?.priority === "primary";
      })?.subfactor === selectedSport
    );
  });
  filteredReport.forEach((response) => {
    response.comments.forEach((comment) => {
      if (comment.ques_order === order && comment.response.length > 0) {
        responseComments.push(comment);
      }
    });
  });

  return responseComments;
};

const getResponsesFormatted = (responses) => {
  let responseFormatted;

  responses.forEach((response, i) => {
    const { response: responseText } = response;
    if (responseFormatted?.length > 0) {
      responseFormatted += `; ${i + 1}. ${responseText}`;
    } else {
      responseFormatted = `${i + 1}. ${responseText}`;
    }
  });
  return responseFormatted;
};

const AIDropdown = ({
  dimension,
  surveyQuestions,
  reportData,
  setGptResponse,
  gptResponse,
  setPrompt,
  prompt,
  clearGptResponse,
  hasTranslation,
  questionsIndex,
  qIds,
  selectedSport,
}) => {
  const dispatch = useDispatch();
  const [commentResponses, setCommentResponses] = useState();
  const [loading, setLoading] = useState(false);
  const [questionIds, setQuestionIds] = useState();
  const [editableText, setEditableText] = useState("");
  const [showGPTError, setShowGPTError] = useState(false);
  const [needsTranslation, setNeedsTranslating] = useState(hasTranslation);
  const [currentQuestion, setCurrentQuestion] = useState();
  const lang = hasTranslation ? "fr" : "en";

  useEffect(() => {
    if (surveyQuestions && !qIds) {
      // retrieve all order ids from questions.comments, add to a list
      const orderIds = surveyQuestions.questions.comments.reduce(
        (acc, question) => {
          if (question.order) {
            acc.push(question.order);
          }
          return acc;
        },
        []
      );

      // grab the correct set of questions
      let splicedIds =
        questionsIndex === 0
          ? orderIds.splice(0, 5)
          : orderIds.splice(6, orderIds.length);
      setQuestionIds(splicedIds);
      setCurrentQuestion(0);
    }
    if (qIds) {
      setQuestionIds(qIds);
      setCurrentQuestion(0);
    }
  }, [surveyQuestions, questionsIndex]);

  const nextQuestion = () => {
    if (currentQuestion < commentQuestions.length - 1) {
      updateEditableText(editableText);
      setCurrentQuestion(currentQuestion + 1);
      setEditableText();
    }
  };

  const previousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setEditableText();
    }
  };

  const { get_chat_gpt_resp } = useSelector(
    (state) => ({
      get_chat_gpt_resp: state.chat_gpt.chat_gpt,
    }),
    shallowEqual
  );

  const commentQuestions =
    questionIds &&
    getSurveyCommentQuestions(questionIds, surveyQuestions).map((question) => {
      return {
        key: question.order,
        text: hasTranslation ? question?.fr : question.question,
        value: question.order,
      };
    });

  useEffect(() => {
    if (
      get_chat_gpt_resp &&
      get_chat_gpt_resp.response &&
      get_chat_gpt_resp.response?.ai_result !== "" &&
      (editableText?.length === 0 || !editableText) &&
      !isNaN(currentQuestion)
    ) {
      if (!needsTranslation) {
        setLoading(false);
        const gptResponseArr = gptResponse?.length > 0 ? [...gptResponse] : [];
        if (gptResponseArr[currentQuestion]) {
          gptResponseArr[currentQuestion] = {
            ...get_chat_gpt_resp,
            question: commentQuestions?.[currentQuestion]?.text,
          };
        } else {
          gptResponseArr.push({
            ...get_chat_gpt_resp,
            question: commentQuestions?.[currentQuestion]?.text,
          });
        }

        setGptResponse(gptResponseArr, currentQuestion);
        setEditableText(get_chat_gpt_resp.response.ai_result);
      } else {
        setNeedsTranslating(false);
        dispatch({
          type: SG_POST_CHAT_GPT,
          payload: {
            request: {
              gpt_text: get_chat_gpt_resp.response.ai_result,
              prompt: "Translate the following text to french",
            },
          },
        });
      }
    }

    if (
      get_chat_gpt_resp &&
      get_chat_gpt_resp.response &&
      get_chat_gpt_resp.response?.ai_result === ""
    ) {
      if (gptResponse?.[currentQuestion]) {
        setLoading(false);
        setShowGPTError(true);
      }
    }
  }, [get_chat_gpt_resp, gptResponse]);

  useEffect(() => {
    if (gptResponse && !isNaN(currentQuestion)) {
      setEditableText(
        gptResponse[currentQuestion]?.response?.ai_result?.replace(
          /\:\n\n/g,
          ""
        )
      );
    }
  }, [gptResponse, questionsIndex, currentQuestion]);

  // Update editable text
  const updateEditableText = (e) => {
    const gptResponseArr = gptResponse?.length > 0 ? [...gptResponse] : [];

    setEditableText(e);
    if (
      gptResponseArr[currentQuestion] ||
      gptResponseArr?.length > currentQuestion - 1
    ) {
      if (gptResponseArr?.[currentQuestion]?.response) {
        gptResponseArr[currentQuestion] = {
          ...gptResponseArr?.[currentQuestion],
          response: {
            ...gptResponseArr?.[currentQuestion]?.response,
            ai_result: e,
          },
          question: commentQuestions?.[currentQuestion]?.text,
        };
        setLoading(false);
        setGptResponse(gptResponseArr, currentQuestion);
      } else {
        gptResponseArr[currentQuestion] = {
          response: {
            ai_result: e,
          },
          question: commentQuestions?.[currentQuestion]?.text,
        };

        setLoading(false);
        setGptResponse(gptResponseArr, currentQuestion);
      }
    } else {
      if (e?.length > 0 || e === "") {
        gptResponseArr.push({
          response: {
            ai_result: e,
          },
          question: commentQuestions?.[currentQuestion]?.text,
        });
        setLoading(false);
        setGptResponse(gptResponseArr, currentQuestion);
      }
    }
  };

  useEffect(() => {
    if (!isNaN(currentQuestion)) {
      const commentResponses = getCommentResponsesByOrder(
        reportData,
        questionIds[currentQuestion],
        selectedSport
      );

      setPrompt(
        "Task: \n\n In four sentences, summarize the preceding responses \n\n Summary: \n"
      );
      if (commentResponses.length > 0) {
        let responseFormatted;
        commentResponses.forEach((response, i) => {
          const { response: responseText } = response;
          if (responseFormatted?.length > 0) {
            responseFormatted += `Response: \n\n ${responseText}\n\n`;
          } else {
            responseFormatted = `Response: \n\n ${responseText}\n\n`;
          }
        });
        if (responseFormatted?.length > 0) {
          setCommentResponses(`${responseFormatted}`);
        }
      } else {
        setCommentResponses();
      }
    }
  }, [currentQuestion]);

  //   Function to dispatch and generate AI summary.
  const generateSummary = (prompt, text) => {
    setLoading(true);
    setGptResponse();
    setNeedsTranslating(hasTranslation);
    setEditableText();
    if (text?.length > 0 && prompt?.length > 0) {
      const gptText = `Context: \nthese are survey responses the question \n ${text}`;
      dispatch({
        type: SG_POST_CHAT_GPT,
        payload: {
          request: {
            gpt_text: gptText,
            prompt: prompt,
          },
        },
      });
    }
  };

  //clearGptResponse
  const handleClear = () => {
    clearGptResponse(currentQuestion);
    setEditableText("");
  };

  return (
    <Container>
      <TopSection>
        <T1>{getTranslatedText("aiTitle", lang, translations)}</T1>
        {/* <CurrentQuestion>
          {currentQuestion + 1}/{commentQuestions?.length}
        </CurrentQuestion> */}
      </TopSection>
      {/* <Title>
        {getTranslatedText("aiDescription", lang, translations)} {dimension}
      </Title> */}
      <Label>
        {getTranslatedText("currentQuestion", lang, translations)}{" "}
        {currentQuestion + 1} {getTranslatedText("of", lang, translations)}{" "}
        {commentQuestions?.length}
      </Label>
      <QuestionText>{commentQuestions?.[currentQuestion]?.text}</QuestionText>
      <ButtonArea>
        <Button
          style={{ backgroundColor: "#2D70E2" }}
          primary
          onClick={() => previousQuestion()}
          size="mini"
          disabled={!(currentQuestion > 0)}
        >
          {getTranslatedText("previousQuestion", lang, translations)}
        </Button>
        <Button
          style={{ backgroundColor: "#2D70E2" }}
          primary
          onClick={() => nextQuestion()}
          size="mini"
          disabled={!(currentQuestion < commentQuestions?.length - 1)}
        >
          {getTranslatedText("nextQuestion", lang, translations)}
        </Button>
      </ButtonArea>
      <FeedbackTitle>
        {getTranslatedText("surveyFeedback", lang, translations)}
      </FeedbackTitle>
      {(commentResponses?.length > 0 && (
        <FeedbackText>
          {commentResponses
            ?.split(/Response:/)
            .filter((item) => item !== "")
            ?.map((item, i) => {
              return (
                <div key={i}>
                  <span>{i + 1}.</span> {item?.replace(/Response:/, "")}
                </div>
              );
            })}
        </FeedbackText>
      )) || <FeedbackText></FeedbackText>}
      <ButtonArea>
        {gptResponse?.[currentQuestion]?.response?.ai_result && (
          <Button
            disabled={!commentResponses?.length > 0}
            primary
            onClick={() => handleClear()}
          >
            {getTranslatedText("clear", lang, translations)}
          </Button>
        )}
        {/* {(loading && (
          <LoadWrapper>
            <Loader inline active size="small" />
          </LoadWrapper>
        )) || (
          <Button
            disabled={!commentResponses?.length > 0}
            primary
            onClick={() => generateSummary(prompt, commentResponses)}
          >
            {getTranslatedText("generateSummary", lang, translations)}
          </Button>
        )} */}
      </ButtonArea>

      {showGPTError && (
        <ErrorMessage>
          {getTranslatedText("gptError", lang, translations)}
        </ErrorMessage>
      )}

      <FeedbackTitle>
        {getTranslatedText("reportTitleLabel", lang, translations)}
      </FeedbackTitle>
      <Description>
        {getTranslatedText("addCommentsText", lang, translations)}
      </Description>
      <TextArea
        onChange={(e) => updateEditableText(e.target.value)}
        value={editableText || ""}
      />
    </Container>
  );
};

export default AIDropdown;

const QuestionText = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
`;

const FeedbackTitle = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 600;
color:#2A3039;
`;

const FeedbackText = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: #7c7c7c;
  max-height: 500px;
  min-height: 75px;
  border: 1px solid #dfdfdf;
  padding: 20px;
  overflow-y: scroll;
  & > div {
    margin-bottom: 10px;
    & > span {
      font-weight: 500;
    }
  }

  /* For Webkit browsers like Chrome, Safari */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

const LoadWrapper = styled.div`
  width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const T1 = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;
const ButtonArea = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 210px;
  border-radius: 5px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: center;

  color: #7e7e7e;

  padding: 15px;
  resize: none;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  margin-top: 10px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 10px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const CurrentQuestion = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
`;

const friendOptions = [
  {
    key: "Question #1",
    text: "Question #1",
    value: "Question #1",
  },
  {
    key: "Question #2",
    text: "Question #2",
    value: "Question #2",
  },
];

const Label = styled.div`
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  margin-top: 10px;
  font-family: Raleway;
`

const Description = styled.div`
font-family: Raleway;
font-size: 14px;
font-style: normal;
font-weight: 400;
color:#2A3039;
margin-bottom:10px;
margin-top:-5px;
`