
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the shimmer effect
const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

// Styled component for the loading bar with shimmer effect
const LoadingBar = styled.div`
  height: 20px;
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  animation: ${shimmer} 1.5s linear infinite;
  border-radius:10px;
`;

// Wrapper component for the loading bars
const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height:250px;
`;

// Main component that renders the loading state
const LoadingComponent = () => (
  <LoadingWrapper>
    <LoadingBar style={{ width: '30%', height: '15px' }} />
    <LoadingBar style={{ width: '100%', height: '20px' }} />
    <LoadingBar style={{ width: '100%', height: '100px' }} />
  </LoadingWrapper>
);

export default LoadingComponent;
