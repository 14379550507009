import React from "react";
import { View, Text, Image, StyleSheet,Page } from "@react-pdf/renderer";
import PropTypes from "prop-types";

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    marginBottom: 30,
    position: "relative",
  },
  bottom: {
    width: "85%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 2,
    paddingBottom: 2,
  },
  title: {
    width: "20%",
    fontSize: 10,
    color: "#2C3644",
  },
  bar: {
    height: 8,
    backgroundColor: "#000921",
    opacity: 0.9,
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  score: {
    fontSize: 10,
    fontWeight: 700,
    marginLeft: 4,
    position: "absolute",
    right: -20,
    top: -2,
    opacity: 1,
  },
  lines: {
    position: "absolute",
    left: "20%",
    height: "100%",
    width: "calc(80% + 20px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  line: {
    height: "100%",
    width: 1,
    borderLeft: "1px dashed grey",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  chart: {
    position: "relative",
  },
  text: {
    position: "absolute",
    bottom: -5,
    left: -5,
    fontSize: 8,
    color: "grey",
  },
});


const Chart = ({data}) =>{

	return (
		<View style={styles.page}>
			<View style={styles.chart}>
				{data.map((item=>{
					let score = Math.random()
					return (
							<View style={styles.row}>
								<Text style={styles.title}>{item.name}</Text>
								<View style={[styles.bar,{width:80*score+'%'}]}>
									<Text style={styles.score}>{Math.floor(score*100)+'%'}</Text>
								</View>
								
							</View>
						)
				}))}

				<View style={styles.lines}>
					<View style={styles.line}>
						<Text style={styles.text}>0%</Text>
					</View>
					<View style={styles.line}>
						<Text style={styles.text}>20%</Text>
					</View>
					<View style={styles.line}>
						<Text style={styles.text}>40%</Text>
					</View>
					<View style={styles.line}>
						<Text style={styles.text}>60%</Text>
					</View>
					<View style={styles.line}>
						<Text style={styles.text}>80%</Text>
					</View>
					<View style={styles.line}>	
						<Text style={styles.text}>100%</Text>
					</View>
				</View>

			</View>
			
		</View>
		)
}



export default Chart