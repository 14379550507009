import styled, { keyframes } from "styled-components";
import React from "react";
import {
  fadeIn,
  ScreenContainer,
  ScreenTitle,
  Subtext,
  Subsubtext,
} from "reports/DataJourney/components/common/styles";

const CTA = (props) => {
  const { onActionSelect, selectionHistory } = props;
  // Find the most recent selected item in the selectionHistory array which is of the type "action"
  const lastSelected = selectionHistory?.find((item) => item.type === "action");
  const behaviors = {
    title: lastSelected.action.split(":")[0],
    body: lastSelected.action.split(":")[1],
  };
  return (
    <ScreenContainer>
      <ScreenTitleExtended delay={0.3}>
        Behavior's to practice
      </ScreenTitleExtended>
      <SubtextExtended delay={0.5}>
        Based on your data, and the action you've chosen, here is a checklist of
        behaviors to practice.
      </SubtextExtended>

      <SubsubtextExtended delay={0.6}>Behaviors</SubsubtextExtended>
      <UL delay={0.8}>
        <LI>
          <strong>{behaviors.title}:</strong> {behaviors.body}
        </LI>
      </UL>
    </ScreenContainer>
  );
};

export default CTA;


const ScreenTitleExtended = styled(ScreenTitle)`
  animation: ${fadeIn} 1s ease-out forwards;
`;

const SubtextExtended = styled(Subtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const SubsubtextExtended = styled(Subsubtext)`
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s;
`;

const UL = styled.ul`
  margin-top: 20px;
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: ${({ delay }) => delay}s; // Use props to set delay
`;

const LI = styled.li`
  margin-bottom: 20px;
  line-height: 1.4;
`;
