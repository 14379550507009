import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";



const Distribution2 = ({width,start}) =>{

    return (

        <Container>
            <Range width={width} start={start}>
                <Circle d={1} location={0}/>
                <Circle d={1.3} location={1}/>
                <Circle d={1.8} location={2}/>
                <Circle d={1.2} location={3}/>
                <Circle d={0.5} location={4}/>
            </Range>
        </Container>
    )
}

export default Distribution2;

const Container = styled.div`
    width:100%;
    position:relative;
    height:40px;
    margin-bottom:20px;
`

const Range = styled.div`
    width:${props=>props.width*10}%;
    position:absolute;
    left:${props=>props.start*10}%;
    height:30px;
    background-color:#d9d9d9;
    display:flex;
    align-items:center;
    border-radius:20px;
    opacity:0.7;
`

const Circle = styled.div`
    height:${props=>props.d*20}px;
    width:${props=>props.d*20}px;
    border-radius:50%;
    background-color:rgba(46, 2, 235,0.4);
    border:1px solid black;
    position:absolute;
    left:${props=>props.location*25}%;

`