import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Dimmer, Loader, Modal } from "semantic-ui-react";
import { format } from "date-fns";

import {
  SG_GET_TASK_TEMPLATES,
  SG_GET_TASK_SCHEDULES,
  SG_GET_USER_TASK_RESPONSE,
} from "constants/actions";
import AssignedTasks from "./AssignedTasks";

// Create a function that takes the above objects and creates a new array of objects, first we need to find the assigned tasks for the employee.
const getAssignedTasks = (empObj, assignedTasks) => {
  // Find the assigned tasks for the employee
  const assignedTasksForEmployee = assignedTasks?.results?.filter((task) =>
    task.assigned_employees.includes(empObj.employee_id)
  );

  return assignedTasksForEmployee?.map((task) => {
    return {
      taskId: task.id,
      taskRefId: task.task_ref_id,
      taskName: task.name,
      task_template_user_tasks: task.task_template_user_tasks,
      taskStatus: task.status_text,
      updated_at: task.updated_at,
    };
  });
};

const OpenTasks = () => {
  const [incompleteTasks, setIncompleteTasks] = useState();
  const dispatch = useDispatch();

  const {
    get_chat_gpt_recommendations,
    get_auth_employee,
    core_data,
    task_templates,
    scheduled_tasks,
    get_employee_user_category,
    get_auth,
    story_logs,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_chat_gpt_recommendations: state.recommendations,
      get_survey_structure: state.surveystructure.survey_structure,
      core_data: state.audit?.core_data,
      get_employee_permission: state.employee_permission,
      pending_stories: state.schedule_reports?.pending_schedule_reports,
      task_templates: state.task_templates?.task_templates,
      scheduled_tasks: state.task_templates?.scheduled_tasks,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      story_logs: state.story_logging,
    }),
    shallowEqual
  );

  const userTaskStatus = (task, userId) => {
    if (
      task?.tracked_user_tasks?.task_completions?.some(
        (obj) => Number(obj.employee_id) === Number(userId)
      )
    )
      return "Completed";
    if (!task?.tracked_user_tasks?.task_responses?.[userId]) return "New";
    return "In Progress";
  };

  useEffect(() => {
    dispatch({
      type: SG_GET_TASK_TEMPLATES,
    });

    dispatch({
      type: SG_GET_TASK_SCHEDULES,
    });

    dispatch({
      type: SG_GET_USER_TASK_RESPONSE,
    });
  }, [dispatch]);

  useEffect(() => {
    if (scheduled_tasks && get_employee_user_category?.length > 0) {
      setIncompleteTasks(
        getAssignedTasks(get_employee_user_category[0], scheduled_tasks)
      );
    }
  }, [scheduled_tasks, get_employee_user_category]);

  return (
    <PageContainer>
      <AssignedTasks tasks={incompleteTasks} />
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export default OpenTasks;
