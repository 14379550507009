import styled, { keyframes } from "styled-components";

//****** Bar Chart options ******
export const barChartOptions = {
  type: "bar",
  fontFamily: "Poppins, sans-serif",
  foreColor: "#7E7E7E",
  toolbar: {
    show: false,
  },
  width: 800,
  height: 400,
};

// ****** Legend options ******
export const legendOptions = {
  position: "top",
  horizontalAlign: "left",
  show: true,
};

// ****** Data Labels ******
export const dataLabels = {
  enabled: true,
  textAnchor: "start",
  style: {
    colors: ["#fff"],
  },
  formatter: function (val, opt) {
    if (val === 0) {
      return "N/A";
    }
    return val + "%";
  },
  offsetX: -10,
  dropShadow: {
    enabled: false,
  },
};

// ****** Color Gradients ******
export const defaultColorGradient = [
  {
    offset: 0,
    color: "#E6E6E6",
    opacity: 1,
  },
  {
    offset: 100,
    color: "#E6E6E6",
    opacity: 1,
  },
];

export const orgColorGradient = [
  {
    offset: 0,
    color: "#C7CFDB",
    opacity: 1,
  },
  {
    offset: 100,
    color: "#C7CFDB",
    opacity: 1,
  },
];

export const grandientsAboveNorm = [
  {
    offset: 0,
    color: "#468ADE",
    opacity: 1,
  },
  {
    offset: 100,
    color: "#40B7C2",
    opacity: 1,
  },
];

export const grandientsBelowNorm = [
  {
    offset: 0,
    color: "#D482CE",
    opacity: 1,
  },
  {
    offset: 100,
    color: "#E172A2",
    opacity: 1,
  },
];

// ****** Screen Elements ******
export const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const ScreenContainer = styled.div`
  width: 100%;
  font-family: "Raleway";
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const ScreenTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
`;

export const Subtext = styled.div`
  color: #2a3039;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;

  line-height: 1.4;
  opacity: 0;
`;

export const Subsubtext = styled.div`
  color: #2a3039;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;

  line-height: 1.4;
  opacity: 0;
`;
