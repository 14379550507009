import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import {
  inactiveColor,
  fontSizes,
  primaryColor,
  tertiaryColor,
  barContainerBg,
  gradientPrimary,
  unreadColor,
  iconColor,
} from "./styleConfig";
import { ChevronIcon } from "./Icons";

const predictionsBar = (score) => {
  return (
    <BarGraphItemBar>
      <BarGraphPercent>{score}%</BarGraphPercent>
      <BarGraphFillContainer>
        <BarGraphItemBarInner width={score} />
      </BarGraphFillContainer>
    </BarGraphItemBar>
  );
};

const getChartOptions = (labels, predicted, optimized) => {
  // Get the next quarter and year based on the most recent label
  const lastLabel = labels[labels.length - 1];
  const lastQuarter = lastLabel.split(" ")[0];
  const lastYear = lastLabel.split(" ")[1];
  const nextQuarter =
    lastQuarter === "Q4" ? "Q1" : `Q${parseInt(lastQuarter.split("Q")[1]) + 1}`;
  const nextYear = lastQuarter === "Q4" ? parseInt(lastYear) + 1 : lastYear;
  const nextLabel = `${nextQuarter} ${nextYear}`;
  const labelSet = [...labels, nextLabel];
  return {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    annotations: {
      position: "front",
      points: [
        {
          x: nextLabel,
          y: predicted,
          offsetX: 0,
          offsetY: -10,

          style: {
            fontSize: fontSizes.small,
            fontWeight: 400,
          },
          marker: {
            size: 6,
            fillColor: "#3260a8",
            strokeColor: "#fff",
          },

          label: {
            text: "Predicted",
            offsetX: 0,
            offsetY: optimized < 100 ? -10 : 0,
            borderWidth: 7,
            borderColor: inactiveColor,
            style: {
              fontSize: fontSizes.xsmall,
              fontWeight: 400,
              opacity: 1,
              color: "#fff",
              background: inactiveColor,
            },
          },
        },
        {
          x: nextLabel,
          y: optimized,
          marker: {
            size: 6,
            fillColor: tertiaryColor,
            strokeColor: "#fff",
          },
          label: {
            borderWidth: 7,
            borderColor: inactiveColor,
            offsetX: 0,
            offsetY: -10,
            borderRadius: 2,
            style: {
              fontSize: fontSizes.xsmall,
              fontWeight: 400,
              opacity: 1,
              color: "#fff",
              background: inactiveColor,
            },
            text: "Optimized",
          },
        },
      ],
    },

    dataLabels: {
      enabled: false,
    },
    colors: [primaryColor, "#3260a8", tertiaryColor],
    stroke: {
      dashArray: [0, 0, 0],
      curve: "smooth",
      width: [2, 0, 2],
    },
    fill: {
      type: ["gradient", "gradient", "gradient"],
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        gradientToColors: [
          "rgba(63, 134, 254, 0.06)",
          "rgba(112, 51, 255, 0)",
          "rgba(63, 134, 254, 0.06)",
          "rgba(112, 51, 255, 0)",
        ], // optional, if not defined - uses the shades of same color in series
        opacityFrom: [0.4, 0.7, 0.5],
        opacityTo: 0,
        stops: [0, 100],
      },
      opacity: [1, 1, 0.2],
    },
    legend: {
      paddingTop: 100,
    },
    yaxis: {
      min: 0,
      max: 100,
      labels: {
        formatter: function (value) {
          return value;
        },
      },
    },
    grid: {
      padding: {
        top: 10,
        right: 50,
        bottom: 30,
      },
    },
    xaxis: {
      categories: labelSet,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
};

const Predictions = ({ data, selected, questions }) => {
  const [loading, setLoading] = useState(true);
  const [seriesData, setSeriesData] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    let overallAverages = [];
    // Loop through dataset and create array of labels.
    const labels = data.map(
      (item) => `${item.quarter.toUpperCase()} ${item.year}`
    );

    if (data.length > 0) {
      if (selected) {
        overallAverages = data.map((item) => {
          const anchorData = item.anchorData.find(
            (data) => data.option === selected
          );
          return Number(anchorData?.average) * 10;
        });
      } else {
        overallAverages = data.map((item) => Number(item.overallAverage) * 10);
      }

      const series = [];

      // Get most recent average from overallAverages.
      const mostRecentAverage = overallAverages[overallAverages.length - 1];

      // Create second series data arr
      const predictedData = overallAverages.map((item, i) =>
        i === overallAverages.length - 1 ? mostRecentAverage : null
      );
      const predictedAverage = Number((mostRecentAverage * 0.9).toFixed(1));
      predictedData.push(predictedAverage);

      // Create third series data arr
      const optimizedData = overallAverages.map((item, i) =>
        i === overallAverages.length - 1 ? mostRecentAverage : null
      );
      const optimizedPerc = Number((mostRecentAverage * 1.22).toFixed(1));
      optimizedData.push(optimizedPerc < 100 ? optimizedPerc : 100);

      // Add null to overall to allow extra space on plot for predicted and optimized.
      overallAverages.push(null);
      // Add outcomes to series.
      series.push({ name: "Outcomes", type: "area", data: overallAverages });
      series.push({
        name: "Predicted",
        type: "area",
        data: [...predictedData],
      });
      series.push({
        name: "Optimized",
        type: "area",
        data: [...optimizedData],
      });

      const options = getChartOptions(
        labels,
        predictedAverage,
        optimizedPerc < 100 ? optimizedPerc : 100
      );
      setOptions(options);
      setSeriesData(series);
      setLoading(false);
    }
  }, [data]);

  const getFactorInsights = (factorObj) => {
    return (
      <>
        <T3>Factor Insights</T3>
        <D1>Explanatory power of each factor</D1>
        {factorObj?.Factors.map((factor) => {
          return (
            <FactorContainer>
              <Factor>
                <FTitle>{factor?.Title}</FTitle>
                <FDescription>
                  Scores in this category have a significant effect on this
                  cohorts risk assessment
                </FDescription>
                {predictionsBar(factor.score * 100)}
              </Factor>
            </FactorContainer>
          );
        })}
      </>
    );
  };

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <Container>
      <T3>Predicted Outcomes</T3>

      <Chart options={options} series={seriesData} type="area" height={480} />
      {questions && questions?.Factors?.length > 0 && (
        <SquareContainer>
          <FullContainer>{getFactorInsights(questions)}</FullContainer>
        </SquareContainer>
      )}

      {/* <SquareContainer>
        <Square>
          <RightContainer>
            <>
              <T3>AI Recommendations</T3>
              <D1>Plans generated by AI</D1>
              <RecommendationContainer>
                <Recommendation>
                  <RecTitleSection>
                    <RecommendationTitle>Empathy</RecommendationTitle>
                    <RecommendationRisk>{getRiskLabel(10)}</RecommendationRisk>
                  </RecTitleSection>
                  <RecommendationDate>Created Jan 24 2022</RecommendationDate>
                  <RecommendationDescription>
                    A plan for improving empathy in the workplace.
                  </RecommendationDescription>
                  <DeployWrapper>
                    Deploy plan
                    <ChevronWrapper>{ChevronIcon(primaryColor)}</ChevronWrapper>
                  </DeployWrapper>
                </Recommendation>
              </RecommendationContainer>
            </>
            <>
              <RecommendationContainer>
                <Recommendation>
                  <RecTitleSection>
                    <RecommendationTitle>Well-being</RecommendationTitle>
                    <RecommendationRisk>{getRiskLabel(8)}</RecommendationRisk>
                  </RecTitleSection>
                  <RecommendationDate>Created Jan 24 2022</RecommendationDate>
                  <RecommendationDescription>
                    A 5 step plan for improving well-being in the workplace.
                  </RecommendationDescription>
                  <DeployWrapper>
                    Deploy plan
                    <ChevronWrapper>{ChevronIcon(primaryColor)}</ChevronWrapper>
                  </DeployWrapper>
                </Recommendation>
              </RecommendationContainer>
            </>
            <>
              <RecommendationContainer>
                <Recommendation>
                  <RecTitleSection>
                    <RecommendationTitle>Autonomy</RecommendationTitle>
                    <RecommendationRisk>{getRiskLabel(6)}</RecommendationRisk>
                  </RecTitleSection>
                  <RecommendationDate>Created Jan 24 2022</RecommendationDate>
                  <RecommendationDescription>
                    A plan for improving employee autonomy.
                  </RecommendationDescription>
                  <DeployWrapper>
                    Deploy plan
                    <ChevronWrapper>{ChevronIcon(primaryColor)}</ChevronWrapper>
                  </DeployWrapper>
                </Recommendation>
              </RecommendationContainer>
            </>
          </RightContainer>
        </Square>
      </SquareContainer> */}
    </Container>
  );
};

const getRiskLabel = (risk) => {
  let labelColor = iconColor.good;
  let backgroundColor = iconColor.goodBg;
  if (risk < 0) {
    labelColor = unreadColor;
  }

  return (
    <RiskLabel style={{ backgroundColor: backgroundColor }}>
      <RiskText style={{ color: labelColor }}>
        Predicted impact: {risk > 0 ? "+" + risk : "-" + risk}%
      </RiskText>
    </RiskLabel>
  );
};

export default Predictions;

const RiskText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.xsmall};
  line-height: 140%;
  padding: 6px;
`;

const RiskLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  border-radius: 8px;
  height: 20px;
`;

const RightContainer = styled.div`
  margin-left: 50px;
`;

const RecommendationDate = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.xsmall};
  line-height: 140%;
  margin-bottom: 16px;
`;

const RecommendationContainer = styled.div``;

const Recommendation = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;
  border-bottom: 1px solid lightgrey;
`;

const RecTitleSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const RecommendationTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.regular};
  line-height: 128%;
`;

const RecommendationRisk = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 20px;
`;

const RecommendationDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.small};
  line-height: 150%;
  margin-bottom: 16px;
`;

const DeployWrapper = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.small};
  line-height: 140%;
  cursor: pointer;
  color: ${primaryColor};
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
`;

const ChevronWrapper = styled.div`
  margin-left: 10px;
  margin-top: 2px;
`;

const FactorContainer = styled.div`
  display: flex;
  // padding-right: 50px;  // Uncomment when we have the AI recommendations.
`;

const Container = styled.div`
  margin-top: 30px;
`;

const T3 = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 128%;
  margin-bottom: 20px;
`;

const D1 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: ${inactiveColor};
`;

const SquareContainer = styled.div`
  display: flex;
  border-top: 1px solid lightgrey;
  margin-top: 20px;
`;

const FullContainer = styled.div`
  width: 100%;
  height: 600px;
  padding: 20px 10px;
`;

const Square = styled.div`
  border-right: ${(props) => (props.right ? "1px solid lightgrey" : "none")};
  width: 50%;
  height: 600px;
  padding: 20px 10px;
`;

const Score = styled.div`
  font-size: 50px;
  line-height: 1;
  font-family: "Barlow", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const Sub = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Barlow", sans-serif;
`;

const Factor = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;
  border-bottom: 1px solid lightgrey;
`;

const FTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: ${fontSizes.regular};
  line-height: 128%;
`;

const FDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.small};
  line-height: 150%;
`;

const BarGraphItemBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
`;

const BarGraphFillContainer = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: ${barContainerBg};
`;

const BarGraphItemBarInner = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  background: linear-gradient(
    270deg,
    ${gradientPrimary} 0%,
    rgba(63, 134, 254, 0.3) 100%
  );
  border-radius: 5px;
  margin-right: 3px;
`;

const BarGraphPercent = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: ${fontSizes.small};
  line-height: 140%;
  color: ${inactiveColor};
  padding-right: 16px;
`;
