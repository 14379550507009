import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Chart from "react-apexcharts";
import { Loader, Select } from "semantic-ui-react";
import {linearRegressionLine,linearRegression,min,max,rSquared} from 'simple-statistics'

const anchor_list = [{text:'Inclusivity',value:1}]
const anchor_list2 = [{text:'Representation',value:1},{text:'Representation curve',value:2}]

const generate_regression_data = (data) =>{


  let min_x = min(data.map((item=>item[0])))
  let max_x = max(data.map((item=>item[0])))
  let l = linearRegressionLine(linearRegression(data));

  let line = [{x:min_x,y:l(min_x)},{x:max_x,y:l(max_x)}]
  let r = rSquared(data,l)

  return {line,r}

}

const Correlation = () =>{
    const [series,setSeries] = useState(data3.series)

    useEffect(()=>{
    let _series = []
    let new_data = data3.series.map((item=>item.data[0]))
    let {line,r} = generate_regression_data(new_data)

    _series.push({name:'Data','type':'scatter',data:new_data.map(item=>{return {x:item[0],y:item[1]}})})
      _series.push({name:'Trend',type:'line',data:line})
      setSeries(_series)
      console.log(_series)

  },[])

	return  (

        <Container>
           <OptionArea>
            <div style={{ marginRight: 10, display: "flex", alignItems: "center" }}>
                <Label>Y Axis</Label>
                <StyledSelect
                  placeholder="Select Survey Type"
                  options={anchor_list}
                  value={anchor_list[0].value}
                  id="anchors"
                />
              </div>
              <div style={{ marginLeft: 30, display: "flex", alignItems: "center" }}>
                <Label>X Axis</Label>
                <StyledSelect
                  placeholder="Select Survey Type"
                  options={anchor_list2}
                    value={anchor_list[0].value}
                  id="anchors"
                />
              </div>
            </OptionArea>
            <Card>
                 <Chart
                    options={data3.options}
                    series={series}
                    type="line"
                    height={400}
                  />
                </Card>

        </Container>)
}



export default Correlation

const Card = styled.div`
  width: calc(100% - 360px);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
`;

const Container = styled.div`
  width:100%;
`

const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #6d6d6d;
  margin-right: 50px;
`;

const StyledSelect = styled(Select)`
  font-family: "Poppins", sans-serif !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  color: #9e9fa1 !important;
  font-weight: 500 !important;

  .item {
    font-size: 14px !important;
    color: #9e9fa1 !important;
    font-family: "Poppins", sans-serif !important;
  }
`;

const OptionArea = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const data3 = {
  series: [
    {
      name: "Windsor",
      data: [[14,80]],
    },
     {
      name: "Ottawa",
      data: [[11,73]],
    },
     {
      name: "Kingston",
      data: [[20,82]],
    },
     {
      name: "Halifax",
      data: [[9,66]],
    },
     {
      name: "Hamilton",
      data: [[17,85]],
    },
     {
      name: "Windsor",
      data: [[14,80]],
    },
     {
      name: "Vancouver",
      data: [[6,75]],
    },
     {
      name: "Kitchener",
      data: [[11,81]],
    },
     {
      name: "Waterloo",
      data: [[9,72]],
    },
    {
      name: "Markham",
      data: [[3,30]],
    },
    {
      name: "Niagra",
      data: [[6,37]],
    },
    {
      name: "Mississauga",
      data: [[10,55]],
    },
    {
      name: "Sudbury",
      data: [[5,67]],
    },
    {
      name: "Oakville",
      data: [[15,93]],
    }
  ],
  options: {
    chart: {
      height: '100%',
      type: "line",
      fontFamily: "Poppins, sans-serif",
      toolbar: {
        show: false,
      },
      animations:{
        enabled:true
      }
    },
    stroke: {
      curve: "straight",
    },
    grid: {
      borderColor: "#e7e7e7",
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      size: [5, 5],
    },
    tooltip:{
      enabled:true
    },
    xaxis: {
      type: "numeric",
      decimalsInFloat:0,
      title: {
        text: "Representation of Black athletes (%)",
        style:{
          fontSize:'12px',
          fontWeight:400,
          color:'#7E7E7E'
        }
      },
    },
    yaxis: {
      decimalsInFloat:0,
      title: {
        text: "Inclusivity metrics",
        style:{
          fontSize:'12px',
          fontWeight:400,
          color:'#7E7E7E'
        }
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
    },
  },
};


