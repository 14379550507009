import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress,Dropdown,Tab } from 'semantic-ui-react'




const data ={'color':'3, 135, 239',title:"Mental Health and well-being",'categories':[{'title':'Age','categories':['<30','30-40','40-50','50+']},
{'title':'Gender','categories':['Male','Female','Other']},{'title':'Experience','categories':['<1 year','1-3 years','3-6 years','6 years+']},
{'title':'Race','categories':['Black','Indigenous','White','Hispanice','Asian']}]}

const tagOptions = [
  {
    key: 'Important',
    text: 'Mental health and well-being',
    value: 'Important',
  },
  {
    key: 'Announcement',
    text: 'Phsyical health and well-being',
    value: 'Announcement',
  },
  {
    key: 'Psychological safety',
    text: 'Psychological safety',
    value: 'Cannot Fix',
  },
  {
    key: 'Phsyical safety and Safe sport',
    text: 'Phsyical safety and Safe sport',
    value: 'News',
  },
  {
    key: 'Self-determination',
    text: 'Self-determination',
    value: 'Enhancement',
  }
]

const tagOptions2 = [
  {
    key: 'Important',
    text: 'Leadership',
    value: 'Important',
  },
  {
    key: 'Announcement',
    text: 'Coaching',
    value: 'Announcement',
  },
  {
    key: 'Psychological safety',
    text: 'Daily training environment',
    value: 'Cannot Fix',
  },
  {
    key: 'Phsyical safety and Safe sport',
    text: 'Sport science and sport medicine',
    value: 'News',
  },
  {
    key: 'Self-determination',
    text: 'Pathways and performance',
    value: 'Enhancement',
  },
  {
    key: 'Self-determination',
    text: 'Athletes and international results',
    value: 'Enhancement',
  }
]

const Demographics = () =>{

	return (
		<Container>
			<First color={data.color}>
			<Dropdown text={data.title} multiple icon='angle down'>
			    <Dropdown.Menu>
			      <Dropdown.Divider />
			      <Dropdown.Header content='Person Dimension' />
			      <Dropdown.Menu scrolling>
			        {tagOptions.map((option) => (
			          <Dropdown.Item key={option.value} {...option} />
			        ))}
			      </Dropdown.Menu>
			        <Dropdown.Menu scrolling>
			      <Dropdown.Header  content='Performance Dimension' />
			      {tagOptions2.map((option) => (
			          <Dropdown.Item key={option.value} {...option} />
			        ))}
			      </Dropdown.Menu>
			    </Dropdown.Menu>
			  </Dropdown>
			  </First>
			  {data.categories.map(i=><Table color={data.color} info={i} title={i.title} />)}
			
		</Container>
		)
}

export default Demographics

const Container = styled.div`

`



const Table = (props)=>{

	return (
		<TableContainer>
			<TTitle>{props.title}</TTitle>
			
			<Scale color={props.color}>
				<TH width={47.73} />
				{[0,1,2,3,4,5,6,7,8,9,10].map(i=><Num>{i}</Num>)}
				<Num2/>
			</Scale>
			{props.info.categories.map((item,i)=>{
				return (<TR>
					<TD width={40} borderRight={true} bold={item=="Average"}>{item}</TD>
					<TD width={10} center={true} bold={item=="Average"}>8</TD>
					{[0,1,2,3,4,5,6,7,8,9,10].map(i=><TD width={4.54} dash={true} noBottom={i==10}></TD>)}
					
					<Bar width={8*4.54} color={item=="Average"?props.color:'140, 140, 140'}/>
					
				</TR>)
			})}
		</TableContainer>
		)
}


const TableContainer = styled.div`
	width:100%;
	margin-bottom:30px;
`

const Scale = styled.div`
	background-color:${props=>'rgba('+props.color+',0.4)'};
	display:flex;
`

const First = styled.div`
	background-color:${props=>'rgba('+props.color+',1)'};
	display:flex;
	text-transform:uppercase;
	font-size:14px;
	font-weight:bold;
	color:white;
	border-top:1px solid black;
	height:30px;
	align-items:center;
	padding-left:5px;
	margin-bottom:40px;
`

const TTitle = styled.div`
	text-transform:uppercase;
	font-size:14px;
	font-weight:bold;
	padding-left:5px;
`

const TR = styled.div`
	width:100%;
	display:flex;
	position:relative;
	
`

const Bar = styled.div`
	width:${props=>props.width+'%'};
	height:15px;
	position:absolute;
	left:calc(50% - 2px);
	top:calc(50% - 7.5px);
	background-color:${props=>'rgb('+props.color+')'};
`

const Num = styled.div`
	width:4.54%;
	border-top:1px solid black;
	border-bottom:1px solid black;
	position:relative;
	display:flex;
	justify-content:center;
`

const Num2 = styled.div`
	width:3.18%;
	border-top:1px solid black;
	border-bottom:1px solid black;
	position:relative;
	display:flex;
	justify-content:center;
`


const TH = styled.div`
	width:${props=>props.width+'%'};
	border-top:1px solid black;
	border-bottom:1px solid black;
`

const TD = styled.div`
	width:${props=>props.width+'%'};
	display:flex;
	align-items:center;
	justify-content:${props=>props.center?'center':'flex-start'};
	padding:${props=>props.dash?'0px ':'3px 6px'};
	border-right:${props=>props.borderRight?'1px solid black':'none'};
	font-weight:${props=>props.bold?'bold':400};
	border-left:${props=>props.dash?'1px dashed black ':'none'};
	border-bottom:${props=>!props.noBottom?'1px solid black':'none'};
	box-sizing:${props=>props.dash?'content-box ':'border-box'};
	
`