import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Loader, Checkbox, Modal } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Languages from "language/index";
import { useLanguageSetting } from "language/redux_store";
import PlusImg from "assets/images/nav/plus.svg";
import { SET_SELECTED_GROUP } from "constants/actions";

const QuestionDrop = ({
  pillarOnly = false,
  raw_feedback,
  outcomeQ,
  setDefaultListCb,
}) => {
  const language_hook = new Languages(useLanguageSetting());
  const isFrench = language_hook.is_french();
  const dispatch = useDispatch();
  const structure = useSelector((state) => state.audit?.question_structure, shallowEqual);
  const questionStructure = useSelector((state) => state.audit?.survey_version, shallowEqual);
  const selectedGroup = useSelector((state) => state.audit?.selectedGroup, shallowEqual);

  const [open, setOpen] = React.useState(false);
  const [labels, setLabels] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  // 1. Build a dataPointsMap once
  const dataPointsMap = React.useMemo(() => {
    if (!raw_feedback || !structure) return {};
    const map = {};
    for (const f of raw_feedback) {
      let key = "";
      if (structure?.dimensions?.length === 1) {
        key = `factor_${f.feedback.factor}`;
      } else if (f.type === "outcome") {
        key = `outcome_${f.feedback.q}`;
      } else if (f.type === "comments") {
        key = `comment_${f.id}`;
      } else {
        // dimension or factor feedback
        key = `factor_${f.feedback.factor}_dimension_${f.feedback.dimension || f.feedback.id}`;
      }
      map[key] = (map[key] || 0) + 1;
    }
    return map;
  }, [raw_feedback, structure]);

  // 2. Normalize the question structure into a flat list once
  const normalizedLabels = React.useMemo(() => {
    if (!questionStructure) return [];

    let idCounter = 0;
    const newLabels = [];
    
    // Overall Culture
    if (!pillarOnly) {
      newLabels.push({
        title: isFrench ? "Culture Générale" : "Overall Culture",
        level: 0,
        id: -1,
      });
    }

    // Dimensions & Factors
    questionStructure.questions.dimensions?.forEach((dim, dimensionIndex) => {
      if (!pillarOnly) {
        newLabels.push({
          title: isFrench ? (dim.fr || dim.title) : dim.title,
          level: 1,
          id: idCounter++,
          dimension: dimensionIndex,
        });
      }

      dim.factors?.forEach((factor, factorIndex) => {
        newLabels.push({
          title: isFrench ? (factor.fr || factor.title) : factor.title,
          level: 2,
          id: idCounter++,
          dimension: dimensionIndex,
          factor: factorIndex,
        });
      });
    });

    // Outcomes
    if (outcomeQ?.questions?.length > 0) {
      newLabels.push({
        title: "Outcomes",
        level: 1,
      });
      outcomeQ.questions.forEach((oq) => {
        newLabels.push({
          title: oq.name,
          id: oq.id,
          level: 3,
          type: "outcome",
        });
      });
    }

    // Custom Questions
    if (questionStructure?.questions?.comments?.length > 0) {
      newLabels.push({
        title: "Custom Questions",
        level: 1,
      });
      questionStructure.questions.comments.forEach((c, i) => {
        newLabels.push({
          title: c.question,
          id: i,
          level: 4,
          type: "comment",
        });
      });
    }

    return newLabels;
  }, [questionStructure, pillarOnly, isFrench, outcomeQ]);

  // Set labels and call callback when questionStructure changes
  useEffect(() => {
    if (normalizedLabels.length > 0) {
      if (setDefaultListCb) {
        setDefaultListCb(normalizedLabels);
      }
      setLabels(normalizedLabels);
    }
  }, [normalizedLabels, setDefaultListCb]);

  // When navigation changes, clear selection if needed
  useEffect(() => {
    if (selectedGroup?.length === 1 && selectedGroup[0]?.id === -1) {
      setSelected([]);
    }
  }, [selectedGroup]);

  // Preselect items that exist in selectedGroup
  useEffect(() => {
    if (labels.length > 0) {
      const preselected = labels.filter(label =>
        selectedGroup.some(sg => sg.id === label.id && sg.level === label.level)
      );
      if (preselected.length > 0) {
        setSelected(preselected);
      } else {
        setSelected([]);
      }
    }
  }, [labels, selectedGroup]);

  // 3. Group labels by parent title once
  const groupedLabels = React.useMemo(() => {
    // Instead of multiple finds, we assume we know the structure:
    // Level 1 items act as parents for level 2 items if dimension matches
    // "Outcomes" and "Custom Questions" known by title
    const groups = {};
    for (const label of labels) {
      if (label.level === 1 && label.title) {
        groups[label.title] = { ...label, factors: [] };
      } else if (label.level === 2 || label.level === 3 || label.level === 4) {
        // Find the parent title based on level
        // For level 2: parent is dimension-level item
        // For outcomes (3) and custom questions (4), the parent title is known
        let parentTitle = null;
        if (label.level === 2) {
          const parent = labels.find(
            (parentLabel) => parentLabel.level === 1 && parentLabel.dimension === label.dimension
          );
          parentTitle = parent?.title;
        } else if (label.level === 3) {
          parentTitle = "Outcomes";
        } else if (label.level === 4) {
          parentTitle = "Custom Questions";
        }

        if (parentTitle) {
          groups[parentTitle] = groups[parentTitle] || { title: parentTitle, factors: [] };
          groups[parentTitle].factors.push(label);
        }
      }
    }

    return groups;
  }, [labels]);

  const parentTitles = React.useMemo(() => Object.keys(groupedLabels || {}), [groupedLabels]);

  const select = (item) => {
    const alreadySelected = selectedGroup.some(
      (s) => s.id === item.id && s.level === item.level
    );

    // If selecting Overall Culture, reset everything else
    if (item.level === 0 && item.id === -1) {
      setSelected([item]);
      dispatch({
        type: SET_SELECTED_GROUP,
        payload: [item],
      });
      return;
    }

    let updatedSelection = [];
    if (alreadySelected) {
      // Deselect
      updatedSelection = selectedGroup.filter(
        (s) => !(s.id === item.id && s.level === item.level)
      );
    } else {
      // Add new selection
      updatedSelection = [...selectedGroup, item];
    }

    // If multiple selections, remove Overall Culture
    if (updatedSelection.length > 1) {
      updatedSelection = updatedSelection.filter(
        (s) => s.level !== 0 && s.id !== -1
      );
    }

    setSelected(updatedSelection);
    dispatch({
      type: SET_SELECTED_GROUP,
      payload: updatedSelection,
    });
  };

  const get_data_points = React.useCallback((factor) => {
    let key = "";
    if (structure?.dimensions?.length === 1 && factor.factor !== undefined) {
      key = `factor_${factor.factor}`;
    } else if (factor.type === "outcome") {
      key = `outcome_${factor.id}`;
    } else if (factor.type === "comment") {
      key = `comment_${factor.id}`;
    } else if (factor.factor !== undefined && factor.dimension !== undefined) {
      key = `factor_${factor.factor}_dimension_${factor.dimension}`;
    }
    return dataPointsMap[key] || 0;
  }, [dataPointsMap, structure]);

  return (
    <>
      <Factor>
        {labels.length > 0 && parentTitles.length > 0 ? (
          selectedGroup.length === 0 || selectedGroup[0].id === -1 ? (
            "Overall Culture"
          ) : (
            selectedGroup.map((item, idx) => (
              <span key={item.id || idx}>
                {item.title}
                {idx < selectedGroup.length - 1 && ", "}
              </span>
            ))
          )
        ) : (
          <Loader active inline="centered" />
        )}
      </Factor>

      {labels.length > 0 && parentTitles.length > 0 && (
        <Container onClick={() => setOpen(true)}>
          <IconImg2 src={PlusImg} /> Select factors
        </Container>
      )}

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        size="medium"
      >
        {open && parentTitles && groupedLabels && (
          <Content>
            <Title>Select a culture factor</Title>
            {parentTitles.map((parentTitle, i) => {
              const group = groupedLabels[parentTitle];
              if (!group.factors || group.factors.length === 0) return null;

              // Calculate total_data_points for this parent group
              const total_data_points = group.factors.reduce((acc, factor) => acc + get_data_points(factor), 0);
              if (total_data_points === 0) return null;

              // Skip "Outcomes" if no outcomes
              if (
                parentTitle === "Outcomes" &&
                raw_feedback.every((f) => f.type !== "outcome")
              ) {
                return null;
              }

              return (
                <React.Fragment key={parentTitle}>
                  <SectionTitle onClick={() => select(group)}>
                    {parentTitle}
                  </SectionTitle>
                  <AllItems>
                    {group.factors.map((factor, idx) => {
                      const isSelected = selectedGroup.some(
                        (s) => s.id === factor.id && s.level === factor.level
                      );
                      const dataPoints = get_data_points(factor);
                      return (
                        <ItemContainer
                          key={factor.id || `${parentTitle}-${idx}`}
                          onClick={() => select(factor)}
                          selected={isSelected}
                        >
                          <OptionTitle>
                            {factor.title || "Unknown Title"}{" "}
                            <Data>{dataPoints} data points</Data>
                          </OptionTitle>
                        </ItemContainer>
                      );
                    })}
                  </AllItems>
                </React.Fragment>
              );
            })}
          </Content>
        )}
        <Bottom>
          <Button onClick={() => setOpen(false)}>Select</Button>
        </Bottom>
      </Modal>
    </>
  );
};

export default QuestionDrop;

const Container = styled.div`
  color: #2d70e2;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  width: 130px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
`;

const AllItems = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color: #2a3039;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Title = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #2a3039;
  margin-bottom: 20px;
`;

const Button = styled.button`
  background: #2d70e2;
  border-radius: 8px;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  cursor: pointer;
`;

const First = styled.div`
  display: flex;
  text-transform: uppercase;
  height: 30px;
  padding-left: 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  color: #414141;
  margin-top: -10px;
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  border: ${(props) =>
    props.selected ? "1px solid #2D70E2" : "1px solid #E5E5E5"};
  background-color: ${(props) => (props.selected ? "#2D70E2" : "#fff")};
  color: ${(props) => (props.selected ? "#fff" : "#2A3039")};
  width: ${(props) => (props.fullWidth ? "100%" : "calc(50% - 20px)")};
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
`;

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${(props) => (props.selected ? "#2D70E2" : "#EBF1FD")};
  margin-right: 10px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OptionTitle = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: ${(props) => (props.question ? "400" : "600")};
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  line-height: 1.5;
  align-items: ${(props) => (props.question ? "flex-start" : "center")};
  flex-direction: ${(props) => (props.question ? "column" : "row")};
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

const FactorSection = styled.div`
  margin-left: 20px;
  padding-right: 10px;
  margin-right: 40px;
`;

const SelectItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
`;

const IconImg2 = styled.img`
  width: 20px;
  margin-right: 4px;
`;

const Content = styled.div`
  padding: 20px;
`;

const IMG = styled.img`
  width: 20px;
`;

const OptionDescription = styled.div`
  font-family: "Raleway";

  font-size: 12px;
  margin-bottom: 5px;
  line-height: 1.4;
`;

const Factor = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 20px;
`;

const Data = styled.span`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 1.4;
  color: #2d70e2;
  margin-left: 10px;
`;
