import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import Chart from "react-apexcharts";
import { Icon,Progress,Label,Tab,Dropdown } from 'semantic-ui-react'
import Predictions from './Predictions'

import SummarySquare from './SummarySquare'

const panes = [
  { menuItem: 'OKR', render: () => <OKR/> },
  { menuItem: 'Team Metrics', render: () => <Predictions/> }
]


const OKR = () =>{

	return (
		

		      <SquareContainer>
		      	<Top>
					<SummarySquare change={50} value={'$ 30,638,000'} title='Quarterly Revenue'/>
					<SummarySquare change={-5} value={'78%'} title='FTD'/>
					<SummarySquare change={25} value={'8.4'} title='Average Search Ranking'/>
				</Top>
		      	<Square width={100} top={false}>
		      		<T3>FTD and Revenue Trends</T3>
					<D1>Historical FTD and Revenue trends for this property</D1>
					<DropContainer>
						<Dropdown
						    placeholder='Chart Option'
						    fluid
						    selection
						    options={friendOptions}
						  />
					</DropContainer>
			      <Chart options={data2.options} series={data2.series} type="line" height={400} />
			      <Chart options={optionsLine} series={data2.series} type="area" height={150} />
		      	</Square>
		      	

		      

		      	
		      </SquareContainer>

		)
}

const Property = () =>{

	return (
		<Container>
			<T2>Crypto Vantage</T2>
			<Tab panes={panes} renderActiveOnly={true} />
		</Container>
		)
}


export default Property

const Container = styled.div`
	display:flex;
	flex-direction:column;
	margin-top:20px;
`

const DropContainer = styled.div`
	position:absolute;
	right:20px;
	top:40px;
	width:150px;
`

const T2 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:30px;
	font-weight:700;
	margin-bottom:40px;
`
const T3 = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:20px;
	font-weight:300;
	margin-bottom:10px;
`

const Line = styled.div`
	width:100%;
	height:1px;
	background-color:lightgrey;
	margin-top:20px;
	margin-bottom:20px;
`
const Top = styled.div`
	width:100%;
	display:flex;
	justify-content:space-between;
	margin-bottom:20px;
	margin-top:20px;
`


const ChartContainer = styled.div`

`


const Description = styled.div`

`

const D1 = styled.div`
	font-family: 'Barlow', sans-serif;
`

const SquareContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
`



const Square = styled.div`
	width:${props=>props.width+'%'};
	min-height:500px;
	padding:20px;
	position:relative;
`

const Score = styled.div`
	font-size:80px;
	line-height:1;
	font-family: 'Barlow', sans-serif;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	margin-top:20px;
`

const Sub = styled.div`
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	font-family: 'Barlow', sans-serif;
`

const Factor = styled.div`
	width:100%;
	margin-top:20px;
	padding-right:20px;

`

const FTitle = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:16px;
	font-weight:600;
	margin-bottom:5px;	
`

const FDescription = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:12px;
	margin-bottom:10px;	
`


const cats = ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001','13 Jan 2001', '14 Jan 2001', '15 Jan 2001', '16 Jan 2001', '17 Jan 2001', '18 Jan 2001', '19 Jan 2001', '20 Jan 2001', '21 Jan 2001', '22 Jan 2001']
const data = [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16,22, 22, 12, 16,12,13,20,30,40] .map((item,i)=>{
	return {x:cats[i],y:item}
})

const data2 = {
	series: [{
              name: 'Cummulative Revenue',
              type: 'line',
              data
            }],
            options: {
              chart: {
              		id:'chart2',
                type: 'line',
               toolbar: {
                  autoSelected: 'pan',
                  show: false
                },
                 sparkline: {
			        enabled: false,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left'
              },
              colors:['#3260a8'],
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               stroke: {
          width: [5],
          curve: 'smooth'
        },  xaxis: {
              	 type: 'datetime'
              },
              yaxis:{
                title: {
                  text: 'Revenue',
                },
              
              }
            }
}

const data3 ={
          
            series: [{
              name: 'Target',
              data: [10000,20000,30000,40000,50000,60000,80000]
            }, {
              name: 'Progress',
              data: [7000,13000,25000]
            }],
            options: {
              chart: {
                height: 350,
                type: 'area',
                toolbar: {
                  show: false
                },
              },
               annotations: {
				  xaxis: [
				    {
				      x:new Date("2018-09-19T02:30:00.000Z").getTime(),
				      borderColor: '#000',
				      label: {
				        borderColor: '#000',
				        orientation: 'horizontal',
				        text: '31%'
				      }
				    }
				  ]
				},
             
              dataLabels: {
                enabled: false
              },
              colors:['#b5b5b5','#b5b5b5'],
              stroke: {
                curve: 'smooth',
                width:[0,5]
              },fill:{
              	type:['gradient','solid'],
              	opacity:[0.3,1]
              },
              yaxis: {
			  labels: {
			    formatter: function (value) {
			      return value + "$";
			    }
			  },
			},
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            },
          
          
          }


const  optionsLine ={
              chart: {
                id: 'chart1',
                height: 130,
                type: 'area',
                brush:{
                  target: 'chart2',
                  enabled: true
                },
                selection: {
                  enabled: true,
                  xaxis: {
                    min: new Date('01 Jan 2001').getTime(),
                    max: new Date('22 Jan 2001').getTime()
                  }
                },
              },
              colors: ['#008FFB'],
              fill: {
                type: 'gradient',
                gradient: {
                  opacityFrom: 0.91,
                  opacityTo: 0.1,
                }
              },
              xaxis: {
                type: 'datetime',
                tooltip: {
                  enabled: false
                }
              },
              yaxis: {
                tickAmount: 2
              }
            }
          
const friendOptions = [
  {
    key: 'Revenue',
    text: 'Revenue',
    value: 'Revenue',
  },
  {
    key: 'FTD',
    text: 'FTD',
    value: 'FTD',
  },
  {
    key: 'Search Ranking',
    text: 'Search Ranking',
    value: 'Search Ranking',
  },
  {
    key: 'Rev/FTD',
    text: 'Rev/FTD',
    value: 'Rev/FTD',
  },
]