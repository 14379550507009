import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message, Table } from "semantic-ui-react";

import {
    SG_GET_SURVEY_STANDARD
} from "constants/actions";

const Standards = () => {
    const dispatch = useDispatch();
    const [standardList, setStandardList] = useState([])
    const [messageVisible, setMessageVisible] = useState(true)

    const {
        get_standards,
    } = useSelector(
        (state) => ({
            get_standards: state.survey_standard.survey_standards,
        }),
        shallowEqual
    );

    useEffect(() => {
        dispatch({
            type: SG_GET_SURVEY_STANDARD,
        });
    }, [dispatch]);

    useEffect(() => {
        setStandardList(get_standards)
    }, [get_standards]);

    console.log(standardList)

    return (
        <Container>
            {messageVisible &&
                <Message
                    onDismiss={() => setMessageVisible(false)}
                    header='Survey Standard Page!'
                    content='This just a page where you can see how survey standards.'
                />
            }


            {standardList && standardList.map((sl, i) => {
                return (
                    <Table celled key={i}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Name </Table.HeaderCell>
                                <Table.HeaderCell>Value</Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body >
                            <Table.Row >
                                <Table.Cell>
                                    Id
                                </Table.Cell>
                                <Table.Cell>{sl?.id}</Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>
                                    Name
                                </Table.Cell>
                                <Table.Cell>
                                    {sl?.name}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>
                                    Question Sort Order
                                </Table.Cell>
                                <Table.Cell>
                                    {sl?.question_sort_order}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>
                                    Name
                                </Table.Cell>
                                <Table.Cell>
                                    {sl?.name}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>
                                    Repeats
                                </Table.Cell>
                                <Table.Cell>
                                    {sl?.repeat_every} ({sl?.repeat_hrs} hrs)
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>
                                    Pillars
                                </Table.Cell>
                                <Table.Cell>
                                
                                    <pre>
                                    {JSON.stringify(sl?.response?.pillars)}
                                    </pre> 
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row >
                                <Table.Cell>
                                    Questions
                                </Table.Cell>
                                <Table.Cell>
                                    <pre>
                                    {JSON.stringify(sl?.response?.questions)}
                                    </pre> 
                                    
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                )
            })}


            <pre>
                {JSON.stringify(standardList)}
            </pre>

        </Container>
    );
};

export default Standards;

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  padding-left:58px;
  font-family: 'Poppins';


  @media (max-width: 600px) {
    display: none;
  }
`;


