import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Icon, Progress } from "semantic-ui-react";

const BasicLegend = (props) => {
  const legendStructure =
    props?.nonOTP && typeof props.isFrench === "undefined"
      ? props.structure.categories.filter((f) => f.priority === "secondary")[0]
      : props?.nonOTP
      ? props.structure.categories.find((c) => c.id === props.anchor)
      : props.structure.categories.filter((f) => f.priority === "secondary")[0];

  return (
    <Legend
      show={props.show}
      overviewReport={props.overviewReport}
      pdf={props.pdf}
      nonOtp={props.nonOTP}
    >
      {legendStructure?.options?.map((item, i) => {
        return (
          <Dot
            pdf={props.pdf}
            overviewReport={props.overviewReport}
            key={i}
            color={item.color}
            title={
              typeof props.isFrench !== "undefined" && props.isFrench()
                ? item.fr
                  ? item.fr
                  : item.name
                : item.name
            }
          />
        );
      })}
    </Legend>
  );
};

export default BasicLegend;

const Dot = (props) => {
  return (
    <DotWrapper>
      <Circle color={props.color} />
      <Text overviewReport={props.overviewReport}>{props.title}</Text>
    </DotWrapper>
  );
};

const Legend = styled.div`
  align-items: center;
  min-width: ${(props) =>
    props.pdf ? (props.nonOtp ? "650px" : "1000px") : "0"};
  width: ${(props) =>
    props.pdf ? (props.nonOtp ? "650px" : "650px") : "550px"};
  justify-content: ${(props) => (props.pdf ? "center" : "center")};
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-wrap: wrap;
  flex-direction: "row";
  margin-top: ${(props) => (props.pdf ? "650px" : "60px")};
  position: ${(props) => (props.pdf ? "absolute" : "relative")};
  /* bottom: ${(props) => (props.pdf ? "50px" : 0)}; */
  /* bottom: ${(props) => (props.pdf ? "50px" : 0)}; */
  padding-left: ${(props) => (props.pdf ? "60px" : "30px")};
`;

const DotWrapper = styled.div`
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  height: 27px;
  padding: 0px 5px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
  background-color: ${(props) => props.color};
`;

const Text = styled.div`
  margin-left: 10px;
  margin-top: ${(props) => (props.overviewReport ? "-8px" : 0)};
  white-space: nowrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  color: #7e7e7e;
`;
