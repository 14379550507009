import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";



const Area = (props) =>{

	return (
		<div>
			<ATitle>{props.title}
			<Percent>{props.percent}%</Percent></ATitle>
			<Progress percent={props.percent}  color='violet' size="tiny"/>
		</div>
		)
}

const Active = (props) =>{

	return (
		<AContainer>
			<H5>{props.title}</H5>
			<Dat>{props.date}</Dat>
		</AContainer>
		)
}






const SideMenu = () =>{


	return (
		<Container>
			 	<H2>Halifax, Nova Scotia</H2>

			 	<Card>
			 		<H3>Equity Score</H3>
			 		<div style={{position:'relative'}}>
						<Chart options={data.options} series={data.series} type="radialBar" height={150} />
						<Values>
								<Stat>86%</Stat>
							</Values>
					</div>
					<TextArea>
						<Change>Great</Change>
						<Text>This location's equity score has <strong>increased by +10%</strong> this quarter.</Text>
					</TextArea>
				</Card>

				<Content>
					<H4>Equity Score Breakdown</H4>
					<Area title={'Racial'} percent={94}/>
					<Area title={'Gender'} percent={88}/>
					<Area title={'Disability'} percent={78}/>
					<Area title={'Hierarchy'} percent={66}/>
				</Content>

				<Activity>
					<div style={{padding:'20px 20px 0px 20px'}}>
						<H4>Latest Activity</H4>
					</div>
					<Active title={"Implemented a workplace initiative, which saw a 15% increase in diversity scores"} date={"March 31st, 2020"}/>
					<Active title={"Implemented a workplace initiative, which saw a 15% increase in diversity scores"} date={"March 31st, 2020"}/>
					<Active title={"Implemented a workplace initiative, which saw a 15% increase in diversity scores"} date={"March 31st, 2020"}/>
				</Activity>
			 </Container>
		)
}


export default SideMenu



const Container = styled.div`
	width:400px;
	height:100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	margin-left:20px;
`

const H2 = styled.div`
	font-size:18px;
	padding:30px 20px;
	border-bottom:1px solid #e9e9e9;
	font-weight:500;
	display:flex;
	justify-content:space-between;
	align-items:center;
`

const H3 = styled.div`
	font-size:12px;
`

const Change = styled.div`
	font-size:16px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
	color:rgba(0,176,240,1);
	margin-bottom:5px;
`


const Card = styled.div`
	padding:30px 10px;
	position:relative;
	width:100%;
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
	background-color:white;
`

const Values = styled.div`
	position:absolute;
	width:50px;
	height:50px;
	left:calc(50% - 25px);
	top:calc(50% - 25px);
	display:flex;
	align-items:center;
	justify-content:center;
	flex-direction:column;
`

const Stat = styled.div`
	font-size:25px;
	font-family: "Red Hat Display", sans-serif;
	font-weight:bold;
`

const TextArea = styled.div`
	width:100%;
	padding:10px;
`


const ATitle = styled.div`
	font-size:14px;
	font-weight:500;
	width:100%;
	display:flex;
	justify-content:space-between;
`

const Percent = styled.div`
	
`

const Activity = styled.div`

`

const AContainer = styled.div`
	padding:20px;
	border-bottom:1px solid #e9e9e9;
	
`

const H5 = styled.div`
	font-weight:500;
	font-size:16px;
`

const Dat = styled.div`
	margin-top:10px;
	color:#4a4a4a;
	font-size:12px;
`

const Text = styled.div`
	
`

const Content = styled.div`
	padding:20px;
	border-bottom:1px solid #e9e9e9;
`

const H4 = styled.div`
	font-size:16px;
	color:#6a00ff;
	font-weight:bold;
	margin-bottom:20px;
`


const data = {
          
            series: [86],
            options: {
              chart: {
                height: 250,
                type: 'radialBar',
              },
               grid:{
              	padding:{
              		right:0,
              		top:0,
              		left:0,
              		bottom:0
              	}
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '65%',
                  },
                   track:{
                	background:'#c3a7fd'
                },
                dataLabels:{show:false,
                 	name:{show:false},
                 	value:{show:false}}
                },
                  
              },
              fill:{
              	colors:['#6a00ff']
              }
            },
          
          
          };