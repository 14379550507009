import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Dropdown from "./Dropdown";

const planStatus = [
  {
    value: 1,
    text: "Planned",
    key: "Planned",
  },
  {
    value: 2,
    text: "In Progress",
    key: "In Progress",
  },
  {
    value: 3,
    text: "Action Required",
    key: "Action Required",
  },
  {
    value: 5,
    text: "Completed",
    key: "Completed",
  },
  {
    value: 11,
    text: "Archived",
    key: "Archived",
  },
  {
    value: 99,
    text: "For Deletion",
    key: "For Deletion",
  },
];

const StatusDropdown = ({ data, setStatus, removePadding, removeLabels }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (data?.status) {
      const matchedStatus = planStatus.find(
        (status) => status.value === data.status
      );
      setSelected(matchedStatus.text);
    }
  }, [data]);

  const handleSelected = (t) => {
    const matchedStatus = planStatus.find((status) => status.text === t);
    setStatus(matchedStatus.value);
    setSelected(t);
  };

  const statusOptions = planStatus.map((status) => {
    return status.text;
  });

  return (
    <StatusContainer removePadding={removePadding}>
      {!removeLabels && <>
      <Title>Current plan status:</Title>
      <Subtitle>What is the status of this plan as of today?</Subtitle>
      </>}
      <Dropdown
        label={"Set a status"}
        handleOptionChange={(t) => handleSelected(t)}
        options={statusOptions}
        selectedOption={selected}
        inModal={true}
      />
    </StatusContainer>
  );
};

const Title = styled.div`
font-family: "Raleway";
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 21px;
/* identical to box height */

color: #6d6d6d;

margin-top: 20px;
`;

const Subtitle = styled.div`
font-family: "Raleway";
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height */

color: #6d6d6d;

margin-top: 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.removePadding ? "0px" : "150px")};
  margin-top: ${(props) => (props.removePadding ? "0px" : "20px")};
  flex-direction: column;
`;

export default StatusDropdown;
