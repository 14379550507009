import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import SimpleAnalytics from "app/audit/simple/index";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_STANDARD,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SET_FILTERED_DATA,
  SG_GET_OUTCOME_QUESTIONS,
  SET_FILTERED_LAST_DATA,
  SET_DATA_DATES,
  SET_RAW_DATA,
  SET_QUESTIONS,
  SET_CORE_DATA,
  SET_NAVIGATION,
} from "constants/actions";

import { SURVEY_INFO } from "constants/surveys";

const SURVEY_TYPE = 6;
const AUDIT_TYPE_ID = 3;

function ReportDash() {
  const dispatch = useDispatch();

  const [standards, setStandards] = useState(null);
  const [questionStructure, setQuestionStructure] = useState(null);
  const [cultureAuditQues, setCultureAuditQues] = useState();
  const [organizationId, setOrganizationId] = useState(null);
  // just make enough calls to get this information
  const {
    survey_version,
    get_auth,
    get_survey_structure,
    surveyQuestions,
    get_standards,
    get_survey_questions,
    get_employee_categories,
    employeeId,
    get_organizations,
    ambassadorId,
    get_culture_audit_reports,
    get_selectedOrg,
    nav_state,
  } = useSelector(
    (state) => ({
      get_selectedOrg: state.selectedOrg,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      ambassadorId: Number(state.auth?.ambassador_id),
      get_organizations: state.organizations,
      employeeId: Number(state.auth?.employee_id),
      get_employee_categories:
        state.employees.userEmp.employee_employee_category,
      get_auth: state.auth,
      surveyQuestions: state.surveyquestions.outcome_questions,
      get_employee_user_category:
        state.employee_category.employee_user_category,
      get_employee_org_category: state.employee_category?.employee_org_category,
      get_employees: state.employees,
      get_survey_structure: state.surveystructure.survey_structure,
      survey_version: state.audit.survey_version,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_standards: state.survey_standard.survey_standards,
      nav_state: state.audit.nav,
    }),
    shallowEqual
  );


  // Set the standards
  useEffect(() => {
    if (survey_version && get_standards) {
      setStandards(
        get_standards.find((f) =>
          f.question_sort_order.includes(survey_version.sort_order)
        )
      );
    }
  }, [survey_version, get_standards]);

  useEffect(() => {
    dispatch({
      type: SG_GET_CULTURE_AUDIT_REPORTS,
      payload: `audit-type-id=${AUDIT_TYPE_ID}`,
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_STANDARD,
      payload: `survey_type=${SURVEY_TYPE}`,
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      get_survey_structure?.[0] &&
      surveyQuestions &&
      get_standards &&
      questionStructure &&
      standards
    ) {
      const core_data = {
        categories: get_survey_structure[0],
        questions: questionStructure,
        standards,
        surveyQuestions,
        get_standards,
      };
      dispatch({
        type: SET_CORE_DATA,
        payload: core_data,
      });
    }
  }, [
    dispatch,
    get_survey_structure,
    questionStructure,
    surveyQuestions,
    get_standards,
    standards,
  ]);

  // Set the orgId
  useEffect(() => {
    if (Number(ambassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, ambassadorId, get_auth]);

  // Set the culture audit questions
  useEffect(() => {
    if (organizationId && get_organizations) {
      const organization = get_organizations?.[organizationId];
      let isOTP = organization?.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled;

      let survey_type = isOTP ? SURVEY_INFO.otp.name : SURVEY_INFO.audit.name;

      setCultureAuditQues(
        organization?.services_enabled &&
          organization?.services_enabled.filter(
            (sc) => sc?.name === survey_type
          )?.[0]?.question_sort_order
      );
    }
  }, [get_organizations, organizationId]);

  // Organize the survey questions
  useEffect(() => {
    if (cultureAuditQues > 0) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === cultureAuditQues
          )?.[0],
        };

        if (survey_version) {
          SurveyQues = {
            ...get_survey_questions.filter(
              (sq) => sq.sort_order === survey_version.sort_order
            )?.[0],
          };
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };

        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (survey_version) {
        SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === survey_version.sort_order
          )?.[0],
        };
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, cultureAuditQues, survey_version]);

  // Set the core data
  useEffect(() => {
    if (
      get_survey_structure?.[0] &&
      surveyQuestions &&
      get_standards &&
      questionStructure &&
      standards
    ) {
      const core_data = {
        categories: get_survey_structure[0],
        questions: questionStructure,
        standards,
        surveyQuestions,
        get_standards,
      };
      dispatch({
        type: SET_CORE_DATA,
        payload: core_data,
      });
    }
  }, [
    dispatch,
    get_survey_structure,
    questionStructure,
    standards,
    surveyQuestions,
    get_standards,
  ]);

  return (
    <>
      <Routes>
        <Route path="/" element={<SimpleAnalytics />} />
        <Route path={"simple"} element={<SimpleAnalytics />} />
      </Routes>
    </>
  );
}

export default ReportDash;
