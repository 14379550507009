import styled from "styled-components";
import React, { useEffect, useState } from "react";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Message } from "semantic-ui-react";

import AddMultipleSchedule from "components/CultureAudit/MultipleScheduler";
import AddSchedule from "components/CultureAudit/SingleScheduler";
import CalendarList from "components/CultureAudit/CalendarList";
import StatusList from "components/CultureAudit/StatusList";
import PendingList from "components/CultureAudit/PendingList_V2";
import HistoryList from "components/CultureAudit/HistoryList";

import {
  SG_GET_PDF_TEMPLATES,
  SG_CREATE_PDF_TEMPLATE,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_FETCH_USER_EMPLOYEE
} from "constants/actions";

import { SURVEY_TYPE, SURVEY_INFO, SURVEY_QUESTION_TYPE } from "constants/surveys";
import { BasicErrorMessage } from "utilities/ErrorMessages";

const AuditSurvey = () => {
  const dispatch = useDispatch();

  const [fetchAgain, setFetchAgain] = useState(0);
  const [organizationId, setOrganizationId] = useState(false);

  const [open, setOpen] = useState(false);
  const [briefId, setBriefId] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    no: 0,
    text: null,
  });

  const [subfactorFound, setSubfactorFound] = useState(false);

  const [userAdmin, setUserAdmin] = useState(false);
  const [userEmployee, setUserEmployee] = useState(false);
  const [userEmployeeCategoryList, setUserEmployeeCategoryList] = useState([]);

  const [showMenu, setShowMenu] = useState(true);
  const [pdfTemplate, setPdfTemplate] = useState(false);
  const [groupList, setGroupList] = useState([]);

  // const EmployeeId = useSelector(
  //   (state) => Number(state.auth?.employee_id),
  //   shallowEqual
  // );
  // const AmbassadorId = useSelector(
  //   (state) => Number(state.auth?.ambassador_id),
  //   shallowEqual
  // );

  const pdfConfig = useSelector(
    (state) => state.pdfGenerator.pdfConfig,
    shallowEqual
  );

  const [primaryCategory, setPrimaryCategory] = useState([]);
  const [secondaryCategory, setSecondaryCategory] = useState([]);
  const [tab, setTab] = useState(0);
  const [primarySecondaryName, setPrimarySecondaryName] = useState({
    primary: "Type",
    secondary: "Role",
  });



  const {
    get_auth,
    get_organizations,
    get_employee,
    get_survey_structure,
    get_employee_categories,
    get_survey_questions,
    get_selectedOrg,
    get_debrief_schedules,
  } = useSelector(
    (state) => ({
      get_errors: state.errors,
      get_auth: state.auth,
      get_organizations: state.organizations,
      get_employee: state.employees,
      get_selectedOrg: state.selectedOrg,
      get_survey_structure: state.surveystructure.survey_structure,
      get_employee_categories: state.employee_category.employee_category,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_debrief_schedules: state.debrief_schedule,
    }),
    shallowEqual
  );

  useEffect(() => {
    setUserAdmin(false);
    if (get_employee) {
      setUserEmployee(get_employee?.userEmp);
      // check for admin
      if (get_employee.userEmp?.account_type === 5) {
        setUserAdmin(true);
      }
    }
  }, [get_employee, get_auth]);

  const EmployeeId = useSelector(
    (state) => Number(state.auth?.employee_id),
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );


  useEffect(() => {
    const _Category = get_employee_categories.filter(
      (ec) => ec.employee === Number(get_auth?.employee_id)
    )?.[0]?.category;


    setUserEmployeeCategoryList(
      _Category?.filter((ct) => ct?.priority === "primary").map((ct) => ct.value)
    );
  }, [get_employee_categories, get_auth]);

  useEffect(() => {
    dispatch({
      type: SG_GET_PDF_TEMPLATES,
    });
  }, [dispatch]);

  useEffect(() => {
    if (EmployeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${EmployeeId}`,
      });
    }
  }, [dispatch, EmployeeId]);

  useEffect(() => {
    if (organizationId) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${SURVEY_QUESTION_TYPE.AUDIT}&status=2`,
      });
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });
      
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    dispatch({
      type: SG_FETCH_USER_EMPLOYEE,
      payload: {
        id: Number(get_auth?.employee_id),
      },
    });
  }, [dispatch, get_auth]);

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth]);

  // call the post api after state update false to []
  // if empty it would return [] or empty array
  useEffect(() => {
    setPdfTemplate(pdfConfig);
  }, [pdfConfig]);

  useEffect(() => {
    if (pdfTemplate.length === 0) {
      dispatch({
        type: SG_CREATE_PDF_TEMPLATE,
        payload: { name: "ver 1.0", survey_type: SURVEY_INFO.audit.type },
      });
    }
  }, [pdfTemplate, dispatch]);

  useEffect(() => {
    setPrimarySecondaryName({
      secondary: get_survey_structure?.[0]?.categories?.find(
        (f) => f.priority === "secondary"
      )?.name,
      primary: get_survey_structure?.[0]?.categories?.find(
        (f) => f.priority === "primary"
      )?.name,
    });
  }, [get_survey_structure]);

  // TODO: Determine whether this should live here or in its own hook
  // * Using a hook might make the most sense, since we are going to pull
  // * the pdfConfig value on every generation, we may want to move this to
  // * the pdf component itself, as a hook ex. const { pdfConfig } = useGetPDFConfig();

  useEffect(() => {
    const _primaryList = [];
    const _secondaryList = [];
    const _groupList = [];
    if (get_survey_structure?.[0]?.categories?.length > 0) {
      get_survey_structure?.[0]?.categories.map((st) => {
        if (st?.priority === "primary") {
          // no subfactors
          st.options.map((str) => {
            if (str?.subfactors?.length > 0) {
              // subfactors found
              setSubfactorFound(true);
              // console.log(str)
              _groupList.push(str?.name);
              str?.subfactors.map((opf) => {
                // check if user is not admin
                // for eligibility
                if (userAdmin) {
                  _primaryList.push({
                    value: opf.name,
                    name: opf?.name,
                    name_id: opf?.id,
                    label: opf?.name,
                    group: str?.name,
                    mainGroup: st?.name,
                    type_of: st?.priority,
                    subtitle: st?.subtitle,
                    priority: st?.priority,
                  });
                } else {
                  if (userEmployeeCategoryList?.includes(opf.name)) {
                    _primaryList.push({
                      value: opf.name,
                      name: opf?.name,
                      name_id: opf?.id,
                      label: opf?.name,
                      group: str?.name,
                      mainGroup: st?.name,
                      type_of: st?.priority,
                      subtitle: st?.subtitle,
                      priority: st?.priority,
                    });
                  }
                }
                return null;
              });
            } else {
              // check if user is not admin
              // for eligibility
              if (userAdmin) {
                _primaryList.push({
                  value: str?.name,
                  label: str?.name,
                  name: str?.name,
                  name_id: str?.id,
                  type_of: st?.priority,
                  subtitle: st?.subtitle,
                  priority: st?.priority,
                });
              } else {
                if (userEmployeeCategoryList?.includes(str.name)) {
                  _primaryList.push({
                    value: str?.name,
                    label: str?.name,
                    name: str?.name,
                    name_id: str?.id,
                    type_of: st?.priority,
                    subtitle: st?.subtitle,
                    priority: st?.priority,
                  });
                }
              }
            }
            return null;
          });
          return null;
        }

        if (st?.priority === "secondary") {
          st.options.map((str) => {
            if (userAdmin) {
              _secondaryList.push({
                value: str?.name,
                label: str?.name,
                name: str?.name,
                name_id: str?.id,
                type_of: st?.priority,
                subtitle: st?.subtitle,
                priority: st?.priority,
              });
            } else {
              if (userEmployeeCategoryList?.includes(str.name)) {
                _secondaryList.push({
                  value: str?.name,
                  label: str?.name,
                  name: str?.name,
                  name_id: str?.id,
                  type_of: st?.priority,
                  subtitle: st?.subtitle,
                  priority: st?.priority,
                });
              }
            }
            return null
          })
        }

      setPrimaryCategory(_primaryList);
      setSecondaryCategory(_secondaryList)
      setGroupList(_groupList)
      return null;
    })
  }

}, [get_survey_structure, userAdmin, userEmployeeCategoryList]);

useEffect(() => {
  if (alertMessage?.no !== 0) {
    setTab(1);
  }
}, [alertMessage]);


useEffect(() => {
    if (get_debrief_schedules?.updated_debrief) {
      if (get_debrief_schedules?.created) {
        setAlertMessage({ no: 1, text: "Successfully Added New Schedule" });
        return
      } else {
        setAlertMessage({ no: 1, text: "Successfully Updated Schedule" });
        return
      }
    }
}, [get_debrief_schedules, setAlertMessage]);


return (
  <>
    <Container>
      <Title>{get_organizations?.[organizationId]?.name} Culture Index</Title>
      {alertMessage?.text && (
        <Message
          onDismiss={() => setAlertMessage({ no: 0, text: null })}
          color={Number(alertMessage?.no === 2) ? "red" : "green"}
        >
          {alertMessage?.text}
        </Message>
      )}
      {showMenu && (
        <NavContainer>
          {userAdmin === true || userEmployee?.survey_schedule === 1 ?
            <div style={{ display: "flex" }}>
              {["Calendar", "Pending", "History"].map((item, i) => {
                return (
                  <Tab onClick={() => setTab(i)} selected={i === tab} key={i}>
                    {item}
                  </Tab>
                );
              })}
            </div>
            :
            <div style={{ display: "flex" }}>
              {["Calendar"].map((item, i) => {
                return (
                  <Tab onClick={() => setTab(i)} selected={i === tab} key={i}>
                    {item}
                  </Tab>
                );
              })}
            </div>
          }

          {/* {subfactorFound ? */}
          {userAdmin === true || userEmployee?.survey_schedule === 1 ? (
            briefId ? (
              <AddSchedule
                setFetchAgain={setFetchAgain} // changes on new addition or editing
                briefId={briefId}
                organizationId={organizationId}
                trigger={<StyledButton>Schedule</StyledButton>}
                open={open ? open : false}
                setOpen={setOpen}
                primaryCategory={primaryCategory}
                secondaryCategory={secondaryCategory}
                surveyQuestions={get_survey_questions}
                surveyStructure={get_survey_structure}
                primarySecondaryName={primarySecondaryName}
                surveyType={SURVEY_TYPE.AUDIT}
                surveyInfo={SURVEY_INFO.audit}
                setAlertMessage={setAlertMessage}
                setBriefId={setBriefId}
              />
            ) : (
              <AddMultipleSchedule
                setFetchAgain={setFetchAgain} // changes on new addition or editing
                // briefId={briefId? briefId : 0}
                organizationId={organizationId}
                trigger={<StyledButton>Schedule</StyledButton>}
                open={open ? open : false}
                setOpen={setOpen}
                surveyQuestions={get_survey_questions}
                surveyStructure={get_survey_structure}
                primaryCategory={primaryCategory}
                secondaryCategory={secondaryCategory}
                primarySecondaryName={primarySecondaryName}
                surveyType={SURVEY_TYPE.AUDIT}
                surveyInfo={SURVEY_INFO.audit}
                setAlertMessage={setAlertMessage}
                setBriefId={setBriefId}
              />
            )
          ) : (
            ""
          )}
        </NavContainer>
      )}

      <BasicErrorMessage />
      {tab === 0 &&
        <CalendarList
          survey_structure={get_survey_structure[0]}
          groupList={groupList}
          organizationId={organizationId}
          employee_category={get_employee_categories}
          primaryCategory={primaryCategory}
          subfactorFound={subfactorFound}
          surveyType={SURVEY_TYPE.AUDIT}
          userAdmin={userAdmin}
          userEmployeeCategoryList={userEmployeeCategoryList}
        />}
      {tab === 1 &&
        <PendingList
          organizationId={organizationId}
          fetchAgain={fetchAgain} // changes on new addition or editing
          setFetchAgain={setFetchAgain} // changes on new addition or editing
          setBriefId={setBriefId}
          showItems={'pending'}
          briefId={briefId}
          setOpen={setOpen}
          setAlertMessage={setAlertMessage}
          surveyType={SURVEY_TYPE.AUDIT}
          setShowMenu={setShowMenu}
          pdfConfig={pdfConfig}
          // select list for filtering
          primaryCategory={primaryCategory}
          primarySecondaryName={primarySecondaryName}
        />
      }
      {tab === 2 &&
        <HistoryList
          organizationId={organizationId}
          fetchAgain={fetchAgain} // changes on new addition or editing
          setFetchAgain={setFetchAgain} // changes on new addition or editing
          setBriefId={setBriefId}
          showItems={'history'}
          briefId={briefId}
          setOpen={setOpen}
          setAlertMessage={setAlertMessage}
          surveyType={SURVEY_TYPE.AUDIT}
          setShowMenu={setShowMenu}
          pdfConfig={pdfConfig}
          // select list for filtering
          primaryCategory={primaryCategory}
          primarySecondaryName={primarySecondaryName}
        />
      }
    </Container>
    <MobileContainer>
      Only available on displays wider than 600px
    </MobileContainer>
  </>
);
};

export default AuditSurvey;

const Container = styled.div`
  margin-top: 50px;
  margin-bottom: 100px;
  padding-left:58px;
  font-family: 'Poppins';


  @media (max-width: 600px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  margin-right: 20px;
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
/* identical to box height */

display: flex;
align-items: center;
text-transform: capitalize;

color: #414141;
`;

const StyledButton = styled.button`
  background-color: #e3b01f;
  color: black;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 19px;
  border: 1px solid #DFDFDF;
  outline: none;
  margin-top: 10px;
  margin-right: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  background-color:white;
  color: #7E7E7E;


  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }
`;

const Tab = styled.div`
  
  padding: 6px 19px 6px 19px;
  color: white;
  border: ${(props) =>
    props.selected ? "1px solid #476DFA" : "1px solid #414141"};
  background-color: ${(props) => (props.selected ? " #476DFA" : "#515151")};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 12px;
display: flex;
align-items: center;
text-align: center;

  &:hover {
    background-color: ${(props) => (props.selected ? "#2d50e2" : "#7f7f7f")};
    color: white;
  }
`;

const NavContainer = styled.div`
  display: flex;
  padding: 20px 0px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3dbdb;
`;
