import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';

const contexts = [
    "Slack or Messaging Platforms - Supporting effective communication in digital workspaces",
    "In-Person Team Meetings - Enhancing collaboration and engagement during face-to-face gatherings",
    "Virtual Team Meetings - Building rapport and maintaining engagement in virtual settings",
    "Email Communication - Conveying clear, thoughtful, and action-oriented messages via email",
    "One-on-One Meetings - Encouraging open dialogue and personalized feedback in private meetings",
    "Remote Work Dynamics - Fostering connection and productivity among remote team members",
    "Hybrid Team Settings - Navigating interactions and inclusivity in hybrid environments",
    "Onboarding Sessions - Setting up new hires for success and integrating them into the culture",
    "Cross-Functional Collaboration - Enhancing teamwork across departments and specialties",
    "Daily Stand-Up Meetings - Creating alignment and focus during quick daily check-ins"
];

const orgContext = [
  "Town halls or all hands meetings",
  "Executive/SLT led Q&A’s or ask me anything sessions",
  "Video updates to remote organizations or multi location organizations",
  "Company-wide update communication (i.e. weekly, biweekly or month email)",
  "Annual or biannual company retreats",
  "Onboarding and orientation",
  "Celebratory or milestone events",
  "Crisis communication"
]

const teamContext = [
  "Teambuilding",
  "Feedback and retrospective sessions",
  "Ongoing feedback loops",
  "One-on-one meetings or check ins",
  "Cross-departmental/cross-functional meetings",
  "Team-meetings or team stand ups or daily check ins",
  "Celebratory events or wins meetings",
  "Recognition and appreciation moments",
  "Goal-setting and planning sessions",
  "Performance management/evaluation/performance reviews/feedback sessions",
  "Brainstorm/ideation sessions",
  "Project status or progress meetings",
  "Crisis or high stress situations",
  "Cross-functional collaboration"
]



const ContextLibrary = ({ onSelectContext,selectedFactor,goBack,context }) => {
  const [selectedContexts, setSelectedContexts] = useState([]);
  const [contextList, setContextList] = useState([]);

  useEffect(() => {
    if(context == "Organizational wide"){
      setContextList(orgContext)
    }else{
      setContextList(teamContext)
    }
  }, [context])


  const handleContextClick = (context) => {
    
    if(selectedContexts.length == 3 && !selectedContexts.includes(context)){
        return 
    }

    setSelectedContexts((prevSelected) =>
      prevSelected.includes(context)
        ? prevSelected.filter((c) => c !== context)
        : [...prevSelected, context]
    );
  };

  const handleNextClick = () => {
    if (selectedContexts.length > 0) {
      onSelectContext(selectedContexts);
    }
  };

  return (
    <Container>
        <BackButton onClick={goBack}><Icon name="chevron left"/> Back</BackButton>
      <BlueTitle>Action Planning</BlueTitle>
      <Title>Select contexts for your <Label>{selectedFactor.name}</Label> action plan</Title>
      <Description>Choose the scenarios that best fit your current needs or goals. <strong>Choose up to 3:</strong></Description>
      
      <ContextContainer>
        {contextList.map((context, index) => (
          <ContextCard
            key={index}
            onClick={() => handleContextClick(context)}
            isSelected={selectedContexts.includes(context)}
          >
            {context}
          </ContextCard>
        ))}
      </ContextContainer>
      
      <NextButton onClick={handleNextClick} disabled={selectedContexts.length === 0}>
        Next
      </NextButton>
    </Container>
  );
};

export default ContextLibrary;


// Styled Components
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.8s ease-out forwards;
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: 'Raleway', sans-serif;
`;

const Label = styled.span`
    color:#2D70E2;
    font-weight:bold;
`

const BlueTitle = styled.div`
  color: #2D70E2;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #666;
  margin-bottom: 20px;
`;

const ContextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ContextCard = styled.div`
  border: 1px solid ${({ isSelected }) => (isSelected ? '#2D70E2' : '#ddd')};
  background-color: ${({ isSelected }) => (isSelected ? '#e6f0ff' : '#fff')};
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  box-shadow: ${({ isSelected }) => (isSelected ? '0 4px 12px rgba(0, 0, 0, 0.15)' : 'none')};
  transition: all 0.3s ease;
  
  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#d0e4ff' : '#f8f8f8')};
    border-color: #2D70E2;
  }
`;

const NextButton = styled.button`
  margin-top: 30px;
  padding: 12px 24px;
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#2D70E2')};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;
  
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#1a4ea1')};
  }
`;

const BackButton = styled.div`
    color: #2D70E2;
    font-size: 14px;
    cursor: pointer;
    margin-bottom:10px;
`