import styled from "styled-components";
import React, { useEffect, useState } from "react";
import Assignee from "../Assignee";
import { Input, Loader, TextArea } from "semantic-ui-react";
import useGetOrgData from "hooks/useGetOrgData";
import useGetActionPlan from "hooks/useGetActionPlan";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_ADD_ACTIVITY_ITEM,
  SG_GET_ACTION_PLAN_ACTIVITIES,
} from "constants/actions";
import ActivityHistory from "./ActivityHistory";

const Update = ({ planId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [currentActivityData, setCurrentActivityData] = useState("");

  const [currentEmployee, setCurrentEmployee] = useState();
  const { employeesListSafe } = useGetOrgData();
  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth,
    }),
    shallowEqual
  );

  const { planActivity } = useGetActionPlan({ planId: planId });

  const handleAddActivity = () => {
    if (currentActivityData) {
      const data = {
        action_plan: planId,
        description: currentActivityData,
      };

      dispatch({
        type: SG_ADD_ACTIVITY_ITEM,
        payload: data,
      });
      dispatch({
        type: SG_GET_ACTION_PLAN_ACTIVITIES,
      });
      setCurrentActivityData("");
    }
  };

  useEffect(() => {
    if (employeesListSafe && get_auth && !currentEmployee) {
      const found = employeesListSafe.find(
        (e) => e.id === Number(get_auth.employee_id)
      );

      if (found) {
        const empObject = {
          id: found.id,
          name: `${found.first_name} ${found.last_name}`,
          member: `${found.first_name} ${found.last_name}`,
        };
        setCurrentEmployee([empObject]);
        setLoading(false);
      }
    }
  }, [employeesListSafe, get_auth, currentEmployee]);

  if (loading) {
    return <Loader active inline="centered" />;
  }

  return (
    <Container>
      <Row>
        <Assignee assignees={currentEmployee} stacked={false} />

        <Input
          placeholder="Add comment"
          value={currentActivityData}
          onChange={(e, d) => setCurrentActivityData(d.value)}
          style={{ width: 400, marginLeft: 10 }}
          size="small"
          rows={2}
        />

        <StyledButton
          disabled={currentActivityData?.length === 0}
          onClick={() => handleAddActivity()}
        >
          Add Comment
        </StyledButton>
      </Row>
      {planActivity.map((activity) => {
        return (
          <Row>
            <ActivityHistory data={activity} employees={employeesListSafe} />
          </Row>
        );
      })}
    </Container>
  );
};

export default Update;

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledButton = styled.button`
  color: white;
  font-size: 14px;
  font-family: "Poppins";
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #2d50e2;
  border: none;
  outline: none;
  padding: 0px 30px;
  border: 1px solid white;
  margin-left: 20px;

  @media (max-width: 768px) {
    height: 30px;
    font-size: 12px;
    padding: 0 10px 0 10px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2d50e2;
    color: #2d50e2;
  }
`;
