import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import { Button, Icon, Input, Form, TextArea, Modal, Header } from 'semantic-ui-react'

import DateTimePicker from "react-datepicker";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import format from "date-fns/format";
import { useToasts } from "react-toast-notifications";
import { BasicErrorMessage } from "utilities/ErrorMessages";

import {
    SG_ADD_DEBRIEF_EMAIL,
    // SG_UPDATE_DEBRIEF_EMAIL, 
    SG_GET_DEBRIEF_EMAIL,
    SG_DELETE_DEBRIEF_EMAIL,
} from "constants/actions";

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function CustomEmailSend({ 
    briefId, openEmail, 
    setOpenEmail, 
    showHistory, 
    setShowHistory,
    RemindAll }) {
    const dispatch = useDispatch();
    const { addToast } = useToasts();


    const [userEmployee, setUserEmployee] = useState(false);
    const [emailSubject, setEmailSubject] = useState('')
    const [emailContent, setEmailContent] = useState('')
    const [scheduledFor, setScheduledFor] = useState(null)
    const [formErrors, setFormErrors] = useState([]);
    const [pristine, setPristine] = useState(true);

    const [endDate, setEndDate] = useState(new Date());

    const {
        get_employee,
        get_debrief_emails,
    } = useSelector(
        (state) => ({
            get_employee: state.employees,
            get_debrief_emails: state.debrief_email.debrief_email,
        }),
        shallowEqual
    );

    useEffect(() => {
        if (briefId) {
            dispatch({
                type: SG_GET_DEBRIEF_EMAIL,
                payload: `debrief_schedule=${briefId}`,
            });
        }
    }, [dispatch, briefId]);

    useEffect(() => {
        if (get_employee) {
            setUserEmployee(get_employee?.userEmp);
        }
    }, [get_employee]);


    useEffect(() => {
        const ErrorList = [];
        let totalErrors = 0
        if (emailContent?.length === 0) {
            ErrorList["emailContent"] = {
                error: true,
                message: "Please write reminder text"
            };
            totalErrors += 1
        }
        if (emailSubject?.length === 0) {
            ErrorList["emailSubject"] = {
                error: true,
                message: "Please enter email subject"
            };
            totalErrors += 1
        }
        ErrorList['errors'] = totalErrors
        setFormErrors(ErrorList);

    }, [emailContent, emailSubject, pristine]);

    function SendEmailAlert() {
        if (formErrors?.errors > 0) {
            setPristine(false);
            return;
        }
        setOpenEmail(false)
        // to schedule this event just pass one more param
        // scheduled_for
        // if there is not scheduled_for then it would be sent immediatly 
        dispatch({
            type: SG_ADD_DEBRIEF_EMAIL,
            payload: {
                status: 2,
                name: "custom email ",
                content: {
                    subject: emailSubject,
                    body: emailContent
                },

                debrief_schedule: briefId,
                sender: userEmployee,
                ...(scheduledFor && { scheduled_for: scheduledFor })
            }
        });

        setEmailContent('')
        addToast(
            "Emails set successfully, please check history after a couple of mins.",
            { appearance: "success" }
        );
    }

    function DeleteCustomEmail(id) {
        if (id) {
            dispatch({
                type: SG_DELETE_DEBRIEF_EMAIL,
                payload: {id: id}
            });
        }
        console.log(id)
    }

    return (
        <>
            {/* {!showHistory &&
                <>
                    <Button
                            onClick={() => setOpenEmail(true)} color="green">
                        <Icon name='users' />  Create custom reminder
                    </Button>

                    <Button onClick={() => setShowHistory(true)} color="orange">
                        <Icon name='users' />   Email Sent Status
                    </Button>
                </>

            } */}

            <Modal
                open={openEmail}
                onClose={() => setOpenEmail(false)}
                onOpen={() => setOpenEmail(true)}
            >
                <Modal.Header>Send a custom email reminder</Modal.Header>
                <Modal.Content scrolling>
                <BasicErrorMessage />
                    <Modal.Description>
                        <Form>
                            <EmailContainer>
                                To: All remaining survey participants
                            </EmailContainer>

                            <Input fluid={true}
                                defaultValue={emailSubject}
                                onChange={(e) => setEmailSubject(e.target.value)}
                                placeholder='Subject' />
                            {formErrors.emailSubject?.error && !pristine && (
                                <ErrorBanner>
                                    {formErrors.emailSubject?.message}
                                </ErrorBanner>
                            )}
                            <TextArea
                                // fluid="true"
                                rows={10}
                                defaultValue={emailContent}
                                onChange={(e) => setEmailContent(e.target.value)}
                                placeholder='write reminder text here...' />
                            {formErrors.emailContent?.error && !pristine && (
                                <ErrorBanner>
                                    {formErrors.emailContent?.message}
                                </ErrorBanner>
                            )}


                            <ButtonInLineContainer><Label>Select a date and time</Label>
                                <DateTimePicker
                                    selected={scheduledFor}
                                    onChange={(date) => setScheduledFor(date)}
                                    showTimeSelect
                                    minDate={endDate}
                                    maxDate={addDays(endDate, 90)}
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    popperPlacement="top-start"
                                />
                                {scheduledFor &&
                                    <Button onClick={() => setScheduledFor(null)} color="gray">
                                        Clear
                                    </Button>
                                }
                            </ButtonInLineContainer>


                        </Form>


                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <ButtonContainer>
                        <Button onClick={() => setOpenEmail(false)} color="red">
                            Cancel
                        </Button>

                        <Button onClick={SendEmailAlert} primary>
                            {scheduledFor ? "Send at Schedule" : "Send Now"}
                        </Button>
                    </ButtonContainer>

                </Modal.Actions>
            </Modal>

            <Modal
                open={showHistory}
                onClose={() => setShowHistory(false)}
                onOpen={() => setShowHistory(true)}
            >
                <Modal.Header>Send History</Modal.Header>
                <Modal.Content scrolling>
                <BasicErrorMessage />
                    <Modal.Description>
                    <Header size='medium'>Scheduled</Header>
                    <ScheduledEmailBox header="true">
                    <div>Header</div>
                    <div>On Date</div>
                    <div>Delete</div>
                    </ScheduledEmailBox>
                    {get_debrief_emails && get_debrief_emails.filter(db => db?.status < 3).map((bf, i) => {
                          
                                    return (
                                        <HistoryContainer key={i}>
                                            <ScheduledEmailBox>
                                                <div> {bf?.content?.subject}  </div>
                                               
                                                <div>
                                                    {bf?.scheduled_for? format(
                                                        new Date(bf?.scheduled_for?.toLocaleString()),
                                                        "EEEE, MMM do h:mm a"
                                                    ): format(
                                                        new Date(bf?.updated_at?.toLocaleString()),
                                                        "EEEE, MMM do h:mm a")}
                                                </div>
                                                <div> <Icon name='trash' onClick={() => DeleteCustomEmail(bf?.id)} /></div>
                                            </ScheduledEmailBox>
                                        </HistoryContainer>

                                    )
                                

                           
                        })}

                    <Header size='medium'>History</Header>
                        {get_debrief_emails && get_debrief_emails.map((bf, i) => {
                            return <div key={i}>
                                {bf?.response && bf?.response.map((emr, i2) => {
                                    return (
                                        <HistoryContainer key={i2}>
                                            <HistoryEmailBox>
                                                <div>{emr?.employee?.first_name} {emr?.employee?.last_name} - {emr?.employee?.email}</div>
                                                <div>Status: {emr?.status}</div>
                                                <div>Message: <app>
                                                    {JSON.stringify(emr?.message)}
                                                </app> </div>
                                                <div>Email Status: {emr?.employee?.email_status_text}</div>
                                                <div>On Date:
                                                    {format(
                                                        new Date(bf?.updated_at?.toLocaleString()),
                                                        "EEEE, MMM do h:mm a"
                                                    )}
                                                </div>
                                            </HistoryEmailBox>
                                        </HistoryContainer>

                                    )
                                })}

                            </div>
                        })}


                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <ButtonContainer>
                        <Button onClick={() => setShowHistory(false)} color="violet">
                            close
                        </Button>


                    </ButtonContainer>

                </Modal.Actions>
            </Modal>

        </>
    )
}

export default CustomEmailSend

const ErrorBanner = styled.div`
  color: red;
  font-weight: 400;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 5px;
`;


const ErrorBannerLink = styled.div`
  color: blue;
  font-weight: 600;
  font-family: "Red Hat Display", sans-serif;
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

const FormContainer = styled.div`
  max-width: 300px;
  margin-bottom: 20px;
`;

const Label = styled.div`
padding-top: 10px;
  margin-bottom: 10px;
  font-family: "Barlow", sans-serif;
  font-weight: bold;
  padding-right: 20px;
`;

const EmailContainer = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
`

const customButton = styled.button`

`

/* The alert message box */
const EmailBox = styled.div`
    padding: 10px;
    background-color: #f44336; /* Red */
    color: white;
    margin: 5px;
  `

/* The close button */
const CloseBtn = styled.div`
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        color: black;
      }
 `

const ButtonContainer = styled.div`
display: flex;
 justify-content:space-between;
 `

const ButtonInLineContainer = styled.div`
display: flex;
justify-content: flex-start;
margin-top: 10px;
 `

const HistoryContainer = styled.div`
 display: flex;
 flex-direction: column;
flex-wrap: wrap;
 `

 const ScheduledEmailBox = styled.div`
    padding: 10px;
    background-color: ${(props) => props.header? '#514EFA' : '' }; /* Blue */
    color:  ${(props) => props.header? 'white' : 'black' }; 
    font-weight:  ${(props) => props.header? '700' : '400' }; 
    margin: 5px;
    display: flex;
    justify-content: space-between; /* Spread items evenly */
    align-items: center; /* Align items vertically in the middle */
  `

const HistoryEmailBox = styled.div`
    padding: 10px;
    background-color: #f44336; /* Red */
    color: white;
    margin: 5px;
  `