import React from "react";
import styled from "styled-components";
import { Table, Checkbox, Button } from "semantic-ui-react";

const SelectionTableWrapper = styled.div`
  font-family: "Raleway";
`;

const SelectionTable = ({ data, onSelect, selectedData }) => {
    return (
      <SelectionTableWrapper>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Factor</Table.HeaderCell>
              <Table.HeaderCell>Data Points</Table.HeaderCell>
              <Table.HeaderCell>Select</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item) => (
              <Table.Row key={item.name}>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell>{item.dataPoints}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={selectedData.some((selected) => selected.name === item.name)}
                    onChange={() => onSelect(item)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </SelectionTableWrapper>
    );
  };

// Sample data
const data = [
    { name: "Communication", dataPoints: 327 },
    { name: "Adaptability", dataPoints: 299 },
    { name: "Belonging", dataPoints: 287 },
    { name: "Psychological Safety", dataPoints: 265 },
    { name: "Vision", dataPoints: 255 },
    { name: "Impact", dataPoints: 201 },
    { name: "Values", dataPoints: 205 },
    { name: "Accountability", dataPoints: 182 },
    { name: "Clarity", dataPoints: 175 },
    { name: "Empathy", dataPoints: 172 },
    { name: "Resilience", dataPoints: 156 },
    { name: "Learning", dataPoints: 97 },
  ];

const App = ({setSelectedData,selectedData}) => {

    // Function to handle the selection of a data source
    // If the item is in the selectedData array, remove it
    const handleSelect = (item) => {
        const index = selectedData.findIndex((data) => data.name === item.name);
        if (index >= 0) {
            setSelectedData(selectedData.filter((data) => data.name !== item.name));
        } else {
            setSelectedData([...selectedData, item]);
        }
    };

  return <SelectionTable data={data} onSelect={handleSelect} selectedData={selectedData}/>;
};

export default App;
