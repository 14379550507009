import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import Dial from '../Dial'
import Responses from '../responses'
import Individual from '../individual'
import { Progress } from 'semantic-ui-react'

const data = {
          
            series: [{
              name: 'Trust',
              data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
                5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
                48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
              ]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                sparkline: {
	            enabled: true
	          },
                toolbar:{show:false}
              },
              grid:{
              	padding:{
              		right:125
              	}
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [{
                      from: -1,
                      to: 100,
                      color: '#6a00ff'
                    },{
                      from: -100,
                      to: -1,
                      color: '#ff2d55'
                    }]
                  },
                  columnWidth: '80%',
                }
              },
              annotations: {
			  yaxis: [
			    {
			      y: 0,
			      borderColor: '#e9e9e9',
			      offsetX:125,
			       strokeDashArray: 0,
			      label: {
			        borderColor: '#00E396',
			        borderWidth:0,
			         offsetX:125,
			        style: {
			          color: '#4a4a4a',
			          fontFamily: 'Red Hat Display, sans-serif',
			          fontSize:14
			        },
			        text: 'Average Trust 73%'
			      }
			    }
			  ]
			},
              dataLabels: {
                enabled: false,
              },
              yaxis: {
                title: {
                  text: 'Growth',
                },
                labels: {
                  formatter: function (y) {
                    return y.toFixed(0) + "%";
                  }
                }
              },
              xaxis: {
                type: 'datetime',
                categories: [
                  '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
                  '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
                  '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
                  '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
                  '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
                  '2013-07-01', '2013-08-01', '2013-09-01'
                ],
                labels: {
                  rotate: -90
                }
              }
            },
          
          
          };


const SummaryData = () =>{

	return (
		
			<Dark>
				
				<Factor>
					<div>
						<FactorTitle>Trust Score</FactorTitle>
						<Description>Bacon ipsum dolor amet alcatra tail beef fatback picanha frankfurter pastrami tri-tip t-bone rump kielbasa short loin.</Description>
					</div>

					
						<ScoreContainer>
						<Sub>Team Avg</Sub>
						<Progress percent={73} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						<Sub>Your Score</Sub>
						<Progress percent={86} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						</ScoreContainer>
					
				</Factor>
				<Factor>
					<div>
						<FactorTitle>Score Last Week</FactorTitle>
						<Description>Bacon ipsum dolor amet alcatra tail beef fatback picanha frankfurter pastrami tri-tip t-bone rump kielbasa short loin.</Description>
					</div>
					<ScoreContainer>
						<Sub>Team Avg</Sub>
						<Progress percent={73} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						<Sub>Your Score</Sub>
						<Progress percent={86} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						</ScoreContainer>
				</Factor>
				<Factor>
					<div>
					<FactorTitle>Long Term Average</FactorTitle>
					<Description>Bacon ipsum dolor amet alcatra tail beef fatback picanha frankfurter pastrami tri-tip t-bone rump kielbasa short loin.</Description>
					</div>
					<ScoreContainer>
						<Sub>Team Avg</Sub>
						<Progress percent={73} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						<Sub>Your Score</Sub>
						<Progress percent={86} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						</ScoreContainer>
				</Factor>
				<Factor>
					<div>
					<FactorTitle>Industry Benchmark</FactorTitle>
					<Description>Bacon ipsum dolor amet alcatra tail beef fatback picanha frankfurter pastrami tri-tip t-bone rump kielbasa short loin.</Description>
					</div>
					<ScoreContainer>
						<Sub>Team Avg</Sub>
						<Progress percent={73} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						<Sub>Your Score</Sub>
						<Progress percent={86} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						</ScoreContainer>
				</Factor>
				<Factor>
					<div>
					<FactorTitle>Trust Engagement</FactorTitle>
					<Description>Bacon ipsum dolor amet alcatra tail beef fatback picanha frankfurter pastrami tri-tip t-bone rump kielbasa short loin.</Description>
					</div>
					<ScoreContainer>
						<Sub>Team Avg</Sub>
						<Progress percent={73} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						<Sub>Your Score</Sub>
						<Progress percent={86} size='small' style={{marginBottom:15,marginTop:0}} color='purple' progress inverted/>
						</ScoreContainer>
				</Factor>

							
			</Dark>

		)
}

export default SummaryData


const Card = styled.div`
	  box-shadow: 0 4px 20px 0 #ececec;
	  width:800px;
	  display:flex;
	  flex-direction:column;
	  padding:30px 40px;

`

const Title = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
`

const Dark = styled.div`
	margin-bottom:20px;
`

const Metrics = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:26px;
	font-weight:bold;
	color:#4a4a4a;
`

const Line = styled.div`
	width:30px;
	height:5px;
	background-color:#6a00ff;
	margin-bottom:5px;
`

const Date = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	margin-top:15px;
	border-bottom:1px solid #e9e9e9;
	margin-bottom:30px;
`

const Factor = styled.div`
	width:100%;
	display:flex;
	flex-direction:column;
	justify-content:space-between;
	margin-top:25px;
	align-items:center;
`

const FactorTitle = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:16px;
	font-weight:500;
`	

const Score = styled.div`
	font-size:25px;
	font-weight:bold;
	font-family: 'Red Hat Display', sans-serif;
`

const Description = styled.div`
	font-family: 'Red Hat Display', sans-serif;
`

const ChartContainer = styled.div`
	margin-top:30px;
`

const Sub = styled.div`
	font-size:10px;
	font-weight:300;
	font-family: 'Red Hat Display', sans-serif;
`

const ScoreContainer = styled.div`
	width:100%;
	margin-top:10px;
`

const TwoScores = styled.div`
	display:flex;
	min-width:125px;
	align-items:center;
`