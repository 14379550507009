import React,{useEffect,useState} from 'react';
import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";


const OverallScore = ({score}) =>{

    return (
        <Container>
            <T1>
                Your department's overall score is <strong>83%</strong>. That puts you in the <strong>80th percentile</strong> within your organization.
            </T1>

            
            <div style={{width:'100%',marginBottom:30}}>
            <Chart
						options={data3.options}
						series={data3.series}
						type="bar"
						height={450}
					/>
            </div>

            {/* <T2>What does that mean?</T2>
            <Description>
                Your overall culture score is a combination of all of the culture dimensions. 
                It is a measure of how your culture compares to the other companies in our database.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam tincidunt,
                enim lectus luctus elit, sit amet ullamcorper elit risus id nisl. Donec eget nunc eget libero
            </Description>

            <T2>How were the benchmark developed?</T2>
            <Description>
                Your overall culture score is a combination of all of the culture dimensions. 
                It is a measure of how your culture compares to the other companies in our database.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, diam quis aliquam tincidunt,
                enim lectus luctus elit, sit amet ullamcorper elit risus id nisl. Donec eget nunc eget libero
            </Description> */}
        </Container>
    )

}

export default OverallScore

const Container = styled.div`
    width:100%;
    font-family:'Raleway';
    display:flex;
    flex-direction:column;
`

const T1 = styled.div`
    font-size:26px;
    font-weight:400;
    margin-bottom:30px;
    text-align:center;
    line-height:1.4;
`

const T2 = styled.div`
    font-size:16px;
    font-weight:600;
    margin-bottom:10px;
    line-height:1.4;
`

const Description = styled.div`
    margin-bottom: 40px;
`


const data3 = {
	series: [
		{
			data: [9.07191496958851, 9.028902529765867, 8.637639297975701, 8.579321706766255, 8.46192786722845, 8.421206955557551, 8.27093006510129, 8.254794271867654, 8.221697977457534, 8.208445706294807, 8.201215585363835, 8.166001946655774, 8.065011081207938, 8.023233689572915, 8.020796247720615, 8.001270988671994, 7.999767639769582, 7.917709668981536, 7.9109999529785044, 7.8909709059581425, 7.829226685399363, 7.81673588380098, 7.805303441896007, 7.7592000183324386, 7.744351541473576, 7.736835483554641, 7.729060863820424, 7.728245678448761, 7.697514210574307, 7.694394839036695, 7.6755404546353265, 7.654175121993225, 7.650795132568388, 7.641555815705638, 7.588455930406092, 7.554771912973915, 7.528901072108942, 7.480826239859022, 7.430119479560203, 7.327993441023147, 7.15194359524052, 7.115125156082723, 7.083864717117156, 6.95506412723199, 6.942412377619459, 6.912098226322278, 6.819152796598606, 6.733064379653344, 6.567938878165246, 6.559467350012666],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              horizontal: false,
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
		colors: ["#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#2D70E2","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3", "#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3","#B3B3B3"],
		xaxis: {
			categories: [
				"Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department A",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
                "Department B",
                "Department C",
                "Department D",
                "Department E",
                "Department F",
			],
			tickPlacement: "on",
			labels:{
                show:false,
				style:{
					fontSize:'9px',
                    colors: ["#B3B3B3", "#B3B3B3", "#2D70E2","#B3B3B3","#B3B3B3","#B3B3B3"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 10,
			decimalsInFloat: 1,
			title: {
				text: "",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
