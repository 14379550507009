import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Tabs from "./Tabs";
import Plan from "./Plan";
import Planning from "./Planning";
import Edit from "./Edit";
import Actions from "./Actions";
import { Icon } from "semantic-ui-react";

const Overview = () => {
	const options = ["Active", "Archived", "My Plans"];
	const [selected, setSelected] = useState(options[0]);
	const [open, setOpen] = useState(false);
	const [edit, setEdit] = useState(false);

	const [plans, setPlans] = useState([
		{
			metric: {
				factor: {
					title: "Cohesion",
					d: 0,
					f: 2,
				},
				q: 1,
			},
			primary: {
				id: "dadefd2d-e286-4289-957c-d3b740ce85c0",
			},
			anchor: {
				id: "a4600b4e-286d-4e48-ab1e-c47c038ddfe1",
				selected: "a21617d2-3285-454b-b25b-534d9113d2ec",
			},
			plan: {
				id: 0,
			},
			planData: ["dsdsfsdf", "sdfdsfdsf", "sdfdsfds", "", "", ""],
			member: [
				{
					id: 0,
					name: "Michael Bawol",
				},
			],
			status: 1,
		},
	]);

	return (
		<Container>
			<Title>Action Plans</Title>
			<Tabs
				options={options}
				selected={selected}
				onSelect={(s) => setSelected(s)}
			/>

			{plans.map((item) => {
				return <Actions data={item} onClick={() => setEdit(item)} />;
			})}

			<Create onClick={() => setOpen(true)}>
				<Icon name="plus" /> Create plan
			</Create>

			<Planning
				open={open}
				close={() => setOpen(false)}
				plans={plans}
				setPlans={(p) => setPlans(p)}
			/>

			<Edit
				open={edit}
				close={() => setEdit(false)}
				plans={plans}
				data={edit}
			/>
		</Container>
	);
};

export default Overview;

const Container = styled.div`
	width: 100%;
	margin-top: 30px;
	position: relative;
`;

const Title = styled.div`
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 20px;
	font-family: "Barlow Semi Condensed", sans-serif;
`;

const Create = styled.div`
	wisth: 100%;
	padding: 10px;
	cursor: pointer;
	border-radius: 3px;
	margin-left: 20px;

	&:hover {
		background-color: rgba(9, 30, 66, 0.2);
	}
`;
