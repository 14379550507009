import styled from "styled-components";
import React, { useEffect, useState, useMemo } from "react";
import Tabs from "./Tabs";
import Planning from "./Planning";
import Edit from "./Edit";
import Actions from "./Actions";
import { Loader } from "semantic-ui-react";
import useGetActionPlan from "hooks/useGetActionPlan";
import useGetOrgData from "hooks/useGetOrgData";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { SG_CREATE_ACTION_PLAN } from "constants/actions";
import useGetSurveyData from "hooks/useGetSurveyData";
import { useToasts } from "react-toast-notifications";
import { SURVEY_INFO } from "constants/surveys";
import { useLocation } from "react-router";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { uuid4 } from "@sentry/utils";
import {
  SG_UPDATE_ACTION_PLAN,
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_ALL_ACTION_PLANS,
  SG_GET_SAFE_EMPLOYEE_LIST,
} from "constants/actions";
import { PlusIcon } from "./Icons";
import EditLayout from "./Planning/EditLayout";
import SurveyVersions from "../../Audit/VersionControl/index";

const itemsFromBE = [
  { id: uuid4(), content: "First Task" },
  { id: uuid4(), content: "Second Task" },
];

const columnStructure = {
  [uuid4()]: {
    name: "Planned",
    statusId: 1,
    items: itemsFromBE,
    color: "255, 139, 23",
  },
  [uuid4()]: {
    name: "In Progress",
    items: [],
    statusId: 2,
    color: "69, 158, 0",
  },
  [uuid4()]: {
    name: "Action Required",
    items: [],
    statusId: 3,
    color: "179, 2, 25",
  },
  [uuid4()]: {
    name: "Completed",
    items: [],
    statusId: 5,
    color: "2, 68, 209",
  },
  [uuid4()]: {
    name: "Archived",
    items: [],
    statusId: 11,
    color: "138, 138, 138",
  },
  [uuid4()]: {
    name: "For Deletion",
    items: [],
    statusId: 99,
    color: "138, 138, 138",
  },
};

const Overview = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(columnStructure);
  const options = ["Active", "Archived"];
  const [selected, setSelected] = useState(options[0]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const { addToast } = useToasts();
  const [childToastStatus, setChildToastStatus] = useState();
  const [surveyQuestions, setSurveyQuestions] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [immutablePlans, setImmutablePlans] = useState();
  const [questions, setQuestions] = useState();
  const [newPlanMembers, setNewPlanMembers] = useState();

  // Filtering categories
  const [employeeCategories, setEmployeeCategories] = useState();
  const [filteredCategories, setFilteredCategories] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canAccessData, setCanAccessData] = useState(false);

  const { get_auth, employeesListSafe } = useSelector(
    (state) => ({
      get_auth: state.auth,
      employeesListSafe: state.employees.employeesListSafe,
    }),
    shallowEqual
  );

  const organizationId = Number(get_auth?.organization_id);

  const { planActivities } = useGetActionPlan({
    orgId: organizationId,
  });

  const isOtp = location.pathname.includes("otp/action");
  const {
    get_survey_structure,
    get_survey_questions,
    plans: actionPlans,
    survey_version,
    get_employees,
    get_employee_categories,
  } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
      get_survey_questions: state.surveyquestions.survey_questions,
      plans: state.actionPlan.plans,
      survey_version: state.audit.survey_version,
      get_employees: state.employees,
      get_employee_categories: state.employee_category.employee_category,
    }),
    shallowEqual
  );

  useEffect(() => {
    if ((canEdit || canAccessData) && !filteredCategories) {
      if (isAdmin) {
        const filterCat = get_survey_structure?.[0]?.categories.filter(
          (c) => c.priority === "primary"
        )?.[0]?.options;
        setFilteredCategories(filterCat);
        return;
      } else if (employeeCategories?.category?.length > 0) {
        const filterCat = employeeCategories?.category
          .filter((c) => {
            return c?.priority === "primary";
          })
          .map((c) => c.value_id);

        setFilteredCategories(filterCat);

        return;
      }
    }
  }, [employeeCategories, canEdit, canAccessData, get_survey_structure]);

  useEffect(() => {
    setEmployeeCategories(get_employee_categories[0]);
  }, [get_employee_categories]);

  useEffect(() => {
    dispatch({
      type: SG_GET_EMPLOYEE_CATEGORIES,
      payload: `employee=${get_auth?.employee_id}`,
    });
  }, [organizationId]);

  useEffect(() => {
    setIsAdmin(false);
    setCanEdit(false);
    setCanAccessData(false);
    if (get_employees) {
      // check for admin
      if (get_employees.userEmp?.account_type === 5) {
        setIsAdmin(true);
      }

      if (get_employees.userEmp?.survey_add_users === 1) {
        setCanEdit(true);
      }

      if (get_employees.userEmp?.access_data_analytics === 1) {
        setCanAccessData(true);
      }
    }
  }, [get_employees]);

  const surveyInfo = useMemo(
    () =>
      location.pathname.includes("edi/action")
        ? {
            type: SURVEY_INFO.edi.question_type,
            auditType: SURVEY_INFO.edi.type,
          }
        : {
            type: location.pathname.includes("otp/action")
              ? 8
              : SURVEY_INFO.audit.question_type,
            auditType: location.pathname.includes("otp/action")
              ? 5
              : SURVEY_INFO.audit.type,
          },
    []
  );

  useEffect(() => {
    if (organizationId && !get_survey_questions?.[0]) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${organizationId}`,
      });

      dispatch({
        type: SG_GET_ALL_ACTION_PLANS,
        payload: `page_size=1000&organization=${organizationId}`,
      });
    }
  }, [dispatch, organizationId, surveyInfo]);

  useEffect(() => {
    if (!questions) {
      setQuestions(get_survey_questions?.[1] || get_survey_questions?.[0]);
    }
  }, [get_survey_questions]);

  useEffect(() => {
    dispatch({
      type: SG_GET_SAFE_EMPLOYEE_LIST,
    });
  }, [dispatch]);

  useEffect(() => {
    if (actionPlans && actionPlans.length > 0) {
      setImmutablePlans(actionPlans.slice());
    }
  }, [actionPlans]);

  const { get_organizations, orgSurveyQuestions } = useGetSurveyData({
    surveyType: surveyInfo.type,
    auditTypeId: surveyInfo.auditType,
    survey_version: survey_version,
  });

  const updateStatus = (status, id) => {
    const updateData = {
      status: status,
    };

    dispatch({
      type: SG_UPDATE_ACTION_PLAN,
      payload: { data: updateData, id: id },
    });
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceCol = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceCol.items];
      const destItems = [...destColumn.items];
      const statusId = columnStructure[destination.droppableId].statusId;
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, { ...removed, status: statusId });
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceCol,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      updateStatus(
        columnStructure[destination.droppableId].statusId,
        removed.id
      );
    } else {
      const column = columns[source.droppableId];
      const copiedCols = [...column.items];
      const [removed] = copiedCols.splice(source.index, 1);
      copiedCols.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedCols,
        },
      });
    }
  };

  useEffect(() => {
    if (plans) {
      const columnKeys = Object.keys(columnStructure);
      const structureCopy = { ...columnStructure };

      columnKeys.forEach((col) => {
        structureCopy[col] = {
          ...columnStructure[col],
          items: plans
            .filter((plan) => plan.status === columnStructure[col].statusId)
            .map((match) => {
              return {
                ...match,
                draggableId: uuid4(),
              };
            }),
        };
      });

      setColumns(structureCopy);
    }
  }, [plans]);

  const getFactorObj = (factor, options) => {
    if (options && factor) {
      const found = options.find(
        (option) => option.key.toLowerCase() === factor.toLowerCase()
      );
      return found;
    }

    return null;
  };

  const getAssignees = (memberArr, empList) => {
    return memberArr.map((assignee) => {
      const found = empList.find(
        (emp) => emp.id === assignee?.id || emp.id === assignee
      );

      if (found) {
        return {
          id: found.id,
          name: `${found.first_name} ${found.last_name}`,
          member: `${found.first_name} ${found.last_name}`,
        };
      }
    });
  };

  const getDefaultQuestionsSorted = (questions, sort_order) => {
    if (get_organizations && questions && get_auth.organization_id) {
      const surveyQues = questions.filter(
        (sq) => sq.sort_order === sort_order
      )?.[0];

      if (surveyQues) {
        return surveyQues?.questions?.dimensions
          .map((i, id) => {
            return i.factors.map((x, idx) => {
              return {
                key: x.title,
                text: x.title,
                value: { title: x.title, d: id, f: idx },
              };
            });
          })
          .flat();
      }
    }
  };

  const deserializeActionPlans = (data, questions, empList) => {
    if (data && questions && questions[0] && empList) {
      let questionsSorted;
      if (questions[0]?.survey_type === SURVEY_INFO.edi.question_type) {
        questionsSorted = questions[0].questions.factors
          .map((i, id) => {
            return {
              key: i.title,
              text: i.title,
              value: { title: i.title, f: id },
            };
          })
          .flat();
      }
      if (questions[0]?.questions?.dimensions) {
        questionsSorted = questions[0]?.questions?.dimensions
          .map((i, id) => {
            return i.factors.map((x, idx) => {
              return {
                key: x.title,
                text: x.title,
                value: { title: x.title, d: id, f: idx },
              };
            });
          })
          .flat();
      }

      if (survey_version?.questions) {
        questionsSorted = survey_version?.questions?.dimensions
          .map((i, id) => {
            return i.factors.map((x, idx) => {
              return {
                key: x.title,
                text: x.title,
                value: { title: x.title, d: id, f: idx },
              };
            });
          })
          .flat();
      }

      const deserialized = data
        ?.filter((plan) => {
          if (isAdmin) {
            return plan;
          } else {
            return filteredCategories?.includes(plan.primary);
          }
        })
        .map((plan) => {
          const questionsSet = questionsSorted;

          const metric = getFactorObj(plan.metric, questionsSet);
          const planDetails = plan.plan[0];
          if (planDetails.planData) {
            return {
              id: plan.id,
              metric:
                metric?.value ||
                plan?.metric?.charAt(0)?.toUpperCase() + plan?.metric?.slice(1),
              primary: plan.primary,
              plan: planDetails.planId,
              planData: planDetails.planData,
              member: getAssignees(plan.assignees, empList),
              status: plan.status,
              question: plan.question,
              startDate: plan.start_date,
              endDate: plan.end_date,
              createdBy: plan.created_by,
              createDate: plan.created_at,
              sort_order: plan?.sort_order || null,
            };
          }
          return null;
        })
        .filter((result) => result !== null);

      const firstFilter = deserialized.filter((userActivity) => {
        return userActivity.member.find((mem) => {
          return mem?.id === Number(get_auth?.employee_id);
        });
      });

      const secondFilter = deserialized
        .filter((userActivity) => {
          return userActivity.createdBy === Number(get_auth?.user_id);
        })
        .filter((item) => firstFilter.filter((first) => first.id === item.id));

      const filtered = [...secondFilter, ...firstFilter].filter(
        (value, index, self) => {
          return index === self.findIndex((t) => t.id === value.id);
        }
      );

      return filtered.sort(function (a, b) {
        return new Date(b.createDate) - new Date(a.createDate);
      });
    }
  };

  useEffect(() => {
    if (childToastStatus) {
      addToast(childToastStatus);
      setChildToastStatus();
    }
  }, [childToastStatus]);

  useEffect(() => {
    if (get_organizations && orgSurveyQuestions && get_auth.organization_id) {
      const org = get_organizations[get_auth.organization_id];
      const survey_type = org?.services_enabled.filter(
        (sc) => sc?.name === SURVEY_INFO.audit.name
      )?.[0]?.question_sort_order;
      const surveyQues = orgSurveyQuestions.filter(
        (sq) => sq.sort_order === survey_type
      )?.[0];

      if (surveyQues) {
        setSurveyQuestions(surveyQues);
        setSortOrder(survey_type);
      } else {
        setSortOrder(survey_type);
      }
    }
  }, [orgSurveyQuestions, get_organizations, get_auth.organization_id]);

  useEffect(() => {
    if (employeesListSafe?.length > 0 && orgSurveyQuestions) {
      // create a mutable copy of actionPlans
      const actionPlansCopy = actionPlans.slice();

      const parsedPlans = deserializeActionPlans(
        actionPlansCopy,
        orgSurveyQuestions,
        employeesListSafe
      );

      setLoading(false);
      setPlans(parsedPlans);
      setUpdateList(false);
    }
  }, [actionPlans, employeesListSafe, orgSurveyQuestions, updateList]);

  const handlePlanCreation = (actionPlan) => {
    const membersList = actionPlan.member.map((p) => {
      return p.id;
    });

    const data = {
      metric: actionPlan?.metric?.title?.toLowerCase(),
      question: actionPlan?.question > -1 ? actionPlan?.question : [],
      action_plan: actionPlan.plan,
      sort_order: sortOrder,
      plan: [
        {
          planId: actionPlan.plan,
          planData: actionPlan.planData,
        },
      ],
      status: actionPlan.status || 1,
      primary: actionPlan.primary,
      start_date: actionPlan.startDate,
      end_date: actionPlan.endDate,
      assignees: membersList,
      orgId: organizationId,
    };

    dispatch({
      type: SG_CREATE_ACTION_PLAN,
      payload: data,
    });
    setLoading(true);
    setUpdateList(true);
    addToast("Created action plan", { appearance: "success" });
  };

  const getCommentTotals = (id, list) => {
    const activityComments = list.filter(
      (comment) => comment.action_plan === id
    );

    return activityComments.length;
  };

  if (
    loading &&
    orgSurveyQuestions?.length > 0 &&
    surveyQuestions?.length > 0
  ) {
    return <Loader active inline="centered" />;
  }


  const handleSetEdit = (planId) => {
    const selectedPlan = actionPlans.find((plan) => plan.id === planId);
    const membersList = getAssignees(selectedPlan.assignees, employeesListSafe);
    if (membersList?.length > 0) {
      setNewPlanMembers(membersList);
    } else {
      setNewPlanMembers();
    }
    setEdit(selectedPlan);
  };

  return (
    <Container>
      <HiddenDiv>
        <SurveyVersions />
      </HiddenDiv>
      <>
        {(edit && isOtp && (
          <EditLayout
            open={edit}
            close={() => setEdit(false)}
            data={edit}
            updateStatus={(status, id) => updateStatus(status, id)}
            questions={questions}
            categories={get_survey_structure?.[0]}
            addToastStatus={(toastObj) => setChildToastStatus(toastObj)}
            updatePlans={(updateObj) => setPlans(updateObj)}
            planMembers={newPlanMembers}
            updatePlanMembers={(updateObj) => setNewPlanMembers(updateObj)}
          />
        )) ||
          (!open && (
            <>
              {options && (
                <Tabs
                  options={options}
                  selected={selected}
                  onSelect={(s) => setSelected(s)}
                />
              )}
              <ActionBar>
                {selected === options[0] && (
                  <Create onClick={() => setOpen(true)}>
                    <IconWrapper>{PlusIcon()}</IconWrapper>
                    <CreateText>Create plan</CreateText>
                  </Create>
                )}
              </ActionBar>
              <ContentArea>
                <Title>Action Plans</Title>
                <Description>
                  Create plans for change and manage the delivery and tracking
                  of those plans from one convenient spot
                </Description>
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {Object.entries(columns).map(([id, column]) => {
                      if (column.statusId === 99 || column.statusId === 4)
                        return;
                      if (
                        (selected === options[0] && column.statusId !== 11) ||
                        (selected !== options[0] && column.statusId === 11)
                      ) {
                        return (
                          <ColumnContainer>
                            <ColumnTitle>{column.name}</ColumnTitle>
                            <Droppable droppableId={id} key={id}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                      background: snapshot.isDraggingOver
                                        ? "lightblue"
                                        : "white",
                                      width: 260,
                                      minHeight: 650,
                                      borderRadius: 30,
                                      margin: 10,
                                      alignSelf: "center",
                                    }}
                                  >
                                    {column.items
                                      .filter((item) => {
                                        return (
                                          item?.metric?.title ||
                                          item?.metric ===
                                            "Wellness action plan" ||
                                          item?.metric?.toLowerCase() ===
                                            "equity, inclusion, accessibility action plan"
                                        );
                                      })
                                      .map((item, index) => {
                                        return (
                                          <Draggable
                                            key={item.draggableId}
                                            draggableId={item.draggableId}
                                            index={index}
                                          >
                                            {(provided, snapshot) => {
                                              const totalComments =
                                                getCommentTotals(
                                                  item.id,
                                                  planActivities
                                                );

                                              return (
                                                <div
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    userSelect: "none",
                                                    margin: "0 0 11px 0",
                                                    minHeight: "50px",
                                                    backgroundColor:
                                                      "transparent",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                >
                                                  <Actions
                                                    data={item}
                                                    commentTotals={
                                                      totalComments
                                                    }
                                                    onClick={() => {
                                                      handleSetEdit(item.id);
                                                    }}
                                                    categories={
                                                      orgSurveyQuestions[0]
                                                    }
                                                  />
                                                  {item.content}
                                                </div>
                                              );
                                            }}
                                          </Draggable>
                                        );
                                      })}
                                    {provided.placeholder}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </ColumnContainer>
                        );
                      }
                    })}
                  </div>
                </DragDropContext>
                {edit && !isOtp && (
                  <Edit
                    open={edit}
                    close={() => setEdit(false)}
                    plans={plans}
                    data={edit}
                    questions={orgSurveyQuestions}
                    addToastStatus={(toastObj) => setChildToastStatus(toastObj)}
                    updatePlans={(updateObj) => setPlans(updateObj)}
                  />
                )}
              </ContentArea>
            </>
          )) ||
          (open && (
            <Planning
              open={open}
              close={() => setOpen(false)}
              plans={plans}
              questions={questions}
              filteredCategories={filteredCategories}
              handlePlanCreation={(p) => handlePlanCreation(p)}
            />
          ))}
      </>
    </Container>
  );
};

export default Overview;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  position: relative;
  font-family:"Raleway"
`;

const ContentArea = styled.div`
  padding-left: 58px;
  width: 100%;
`;

const HiddenDiv = styled.div`
  display: none;
`;

const Title = styled.div`

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #414141;
  margin-top: 22px;
`;

const Description = styled.div`

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #7e7e7e;
  margin-bottom: 24px;
`;

const DroppableCol = styled.div`
  align-items: center;
`;

const IconWrapper = styled.div`
  line-height: 21.86px;
  margin-right: 5px;
`;

const Create = styled.div`
  display: flex;
  width: 99px;
  height: 21.86px;
  cursor: pointer;
  border-radius: 30px;
  align-self: center;
  justify-content: center;
  background-color: #476dfa;
`;

const CreateText = styled.label`
  font-style: normal;
  justify-content: center;
  font-weight: 300;
  font-size: 10px;
  line-height: 21.86px;
  cursor: pointer;

  color: white;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 7px;
  border-radius: 10px;
  background: #ffffff;
  /* drop */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
`;

const ColumnTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding-left: 20px;
  padding-top: 15px;
`;

const ActionBar = styled.div`
  height: 58px;
  width: "100%";
  display: flex;
  border-color: lightgrey;
  border-style: solid;
  border-left: 0px;
  vertical-align: center;
  border-width: 0.2px;
  border-right: 0px;
  margin-bottom: 15px;
  padding-left: 58px;
`;
