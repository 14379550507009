// Libraries
import React from "react";
import { View, StyleSheet, Text, Page, Font } from "@react-pdf/renderer";

import Side from "../components/Side";
import Footer from "../components/Footer";
import Table from "../components/Table";

// Components
import { getFeedback } from "../utils/cultureUtils";

// Fonts
import Poppins from "assets/fonts/Poppins-Light.ttf";
import PoppinsBold from "assets/fonts/Poppins-Bold.ttf";
import PoppinsMedium from "assets/fonts/Poppins-Medium.ttf";
import PoppinsRegular from "assets/fonts/Poppins-Regular.ttf";
import PoppinsItalic from "assets/fonts/Poppins-LightItalic.ttf";

Font.register({
  family: "Poppins",
  fonts: [
    { src: Poppins },
    { src: PoppinsItalic, fontWeight: 200 },
    { src: PoppinsBold, fontWeight: 700 },
    { src: PoppinsMedium, fontWeight: 500 },
    { src: PoppinsRegular, fontWeight: 400 },
  ],
  format: "truetype",
});

const FeedbackSection = (props) => {
  const getSentiment = (sent) => {
    if (sent > 2) {
      return "Positive";
    }
    if (sent < -2) {
      return "Negative";
    }
    return "Neutral";
  };

  const getRisk = (risk) => {
    if (risk > 70) {
      return `High`;
    }
    if (risk > 20) {
      return `Moderate`;
    }
    return `Low`;
  };

  const options = props.structure.comments
    .filter((item, i) => item.response.length > 0)
    .map((item, i) => {
      return {
        text: item.question,
        value: i,
      };
    });

  const tableTemplate = [
    { title: "Feedback", width: "57%" },
    { title: "Sport", width: "20%" },
    { title: "Risk", width: "10%" },
    { title: "Sentiment", width: "13%" },
  ];

  const optionsTest = new Array(5).fill({
    text: "How much wood would a woodchuck?",
  });

  const feedbackTables = options.map((option, i) => {
    const feedbackData = getFeedback(
      option.value,
      props.categories,
      props.filterData,
      props.structure
    );

    if (!feedbackData || feedbackData.length === 0) {
      return null;
    }

    // const testFeedback = new Array(50).fill({
    //   text: "Test analysis Test analysisTest analysisTest analysisTest analysisTest analysisTest analysisTest analysisTest analysis",
    //   primary: "Athlete",
    //   risk: 70,
    //   sentiment: 3,
    // });

    // const tableData = testFeedback.map((feedback) => {
    //   return [
    //     `${feedback.text}`,
    //     `${feedback.primary}`,
    //     `${getRisk(feedback.risk)}`,
    //     `${getSentiment(feedback.sentiment)}`,
    //   ];
    // });

    const tableData = feedbackData
      .filter((f) => f.text != "")
      .map((feedback) => {
        return [
          `${feedback.text}`,
          `${feedback.primary}`,
          `${getRisk(feedback.risk)}`,
          `${getSentiment(feedback.sentiment)}`,
        ];
      });

    const tableContent = {
      rows: tableTemplate,
      data: tableData,
    };

    return (
      <View>
        <View style={styles.section} wrap={false}>
          <Text style={styles.subTitle}>{i + 1}. Question</Text>
          <Text style={styles.question}>{option.text}</Text>
        </View>
        <View>
          <Table
            data={tableContent}
            headerColor="#2C3644"
            wrapRow={false}
            isSportLaw={props.isSportLaw}
          />
        </View>
      </View>
    );
  });

  return (
    <Page style={styles.page}>
      <Side {...props.config} isSportLaw={props.isSportLaw} />
      <Footer {...props.config} />
      <Text style={styles.t2}>Feedback</Text>
      <View style={styles.line} />
      {feedbackTables}
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    paddingTop: 30,
    paddingHorizontal: 50,
    marginBottom: 30,
    paddingBottom: 80,
    fontFamily: "Poppins",
    color: "#2C3644",
  },
  line: {
    width: 75,
    height: 4,
    backgroundColor: "#2C3644",
    marginTop: 5,
    marginBottom: 20,
  },
  pageWrapper: {
    flexDirection: "column",
    backgroundColor: "white",
    fontFamily: "Poppins",
  },
  scale: {
    height: "5px",
    borderRadius: "12px",
  },
  scaleBar: {
    width: "80px",
    backgroundColor: "rgb(232,232,232)",
    height: "5px",
    borderRadius: "12px",
  },
  feedbackWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  feedbackRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "10px",
    borderBottom: "1px",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  feedbackCol: {
    width: "40%",
  },
  commonCol: {
    width: "20%",
  },
  riskCol: {
    width: "30%",
    justifyContent: "center",
    left: "10px",
  },
  labelColWrapper: {
    justifyContent: "center",
    width: "20%",
  },
  labelCol: {
    textAlign: "center",
    justifyContent: "center",
    padding: "2px",
    borderRadius: "5px",
    backgroundColor: "rgb(232,232,232)",
    minHeight: 27,
    maxHeight: 27,
  },
  labelHeaderCol: {
    textAlign: "center",
    width: "20%",
    justifyContent: "center",
  },
  headerLabel: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "semibold",
    color: "#2C3644",
  },
  regText: {
    fontSize: "11px",
    color: "#2C3644",
  },
  labelText: {
    fontSize: "10px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#2C3644",
  },
  questionWrapper: {
    paddingBottom: "10px",
    paddingTop: "25px",
  },
  primaryCol: {
    width: "25%",
    textAlign: "center",
    justifyContent: "center",
  },
  t2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#2C3644",
  },
  description: {
    lineHeight: 1.7,
    fontSize: 11,
    color: "#2C3644",
  },
  sub: {
    fontSize: 16,
    fontWeight: 700,
    paddingBottom: 5,
    borderBottom: "0.5px solid black",
    width: "100%",
    color: "#2E34D0",
  },
  section: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: "#F0F5FE",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  subSection: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    fontSize: 16,
    height: "100%",
    alignItems: "center",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: 13,
    marginBottom: 5,
    color: "#2C3644",
  },
  question: {
    fontSize: 11,
    lineHeight: 1.7,
    color: "#2C3644",
  },
});

export default FeedbackSection;
