import React from "react";
import { useDispatch } from "react-redux";
import { Icon } from "semantic-ui-react";
import {
  P,
  P2,
  P3,
  Card,
  Avatar,
  Name,
  IconContainer,
  Highlight,
  ToggleContainer,
  Circle,
  Oval,
  SelectContainer,
  LeaderAvatarIcon,
  Toggled,
  Email,
  InviteContainer,
  MobileInvite,
  EmployeeSelect,
} from "./styled_list";

import {
  SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY,
  SG_GET_GLOBAL_SCHEDULES,
} from "constants/actions";

import ModalLeaderTeams from "./ModalLeaderTeams";

import PersonImage from "assets/images/person_image.png";

export const Selector = (props) => {
  return (
    <Oval selected={props.selected} onClick={() => props.onClick()}>
      <Icon
        name="check"
        size={"small"}
        style={{ margin: 0, color: props.selected ? "white" : "#d8d8d8" }}
      />
    </Oval>
  );
};

export const Toggle = (props) => {
  return (
    <Toggled>
      <ToggleContainer
        onClick={() => props.onClick()}
        color={props.on ? "#d6b9ff" : "lightgrey"}
      >
        <Circle
          position={props.on ? "16px" : "-5px"}
          color={props.on ? "#6a00ff" : "grey"}
        />
      </ToggleContainer>
    </Toggled>
  );
};

export const TeamSelection = ({ prop, selectedTeam, AddRemoveTeam }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "calc(50% - 45px)",
        borderBottom: "1px solid #e9e9e9",
        padding: "19px 0px 10px 0px",
      }}
    >
      <Selector
        selected={selectedTeam}
        onClick={() => AddRemoveTeam(selectedTeam, prop.id)}
      />
      <P3>{prop.name} Team</P3>
    </div>
  );
};

export const LeaderSelection = ({ prop, selectedLeader, AddRemoveLeader }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "calc(50% - 45px)",
        borderBottom: "1px solid #e9e9e9",
        padding: "19px 0px 10px 0px",
      }}
    >
      <Selector
        selected={selectedLeader}
        onClick={() => AddRemoveLeader(selectedLeader, prop.id)}
      />
      <div>
        <LeaderAvatarIcon src={prop.picture ? prop.picture : PersonImage} />
      </div>
      <div>
        <P3>
          {prop.first_name} {prop.last_name}
        </P3>
        <P3>{prop.email}</P3>
        <P3>{prop.designation}</P3>
      </div>
    </div>
  );
};

export const choice = [
  { id: 1, value: 1, text: "Weekly" },
  { id: 2, value: 2, text: "Bi-Weekly" },
  { id: 3, value: 4, text: "Monthly" },
  { id: 4, value: 12, text: "Quarterly" },
];

export const time = [
  { value: 8, text: "8:00am" },
  { value: 9, text: "9:00am" },
  { value: 10, text: "10:00am" },
  { value: 11, text: "11:00am" },
  { value: 12, text: "12:00pm" },
  { value: 13, text: "01:00pm" },
  { value: 14, text: "02:00pm" },
  { value: 15, text: "03:00pm" },
  { value: 16, text: "04:00pm" },
  { value: 17, text: "05:00pm" },
];

export const SelectFreq = ({ selected, setProcessing }) => {
  const dispatch = useDispatch();
  const [selectedFreq, setSelectedFreq] = React.useState(1);

  React.useEffect(() => {
    if (selected?.frequency) {
      setSelectedFreq(
        choice.filter((fl) => fl.value === selected.frequency)[0].id
      );
    }
  }, [selected]);

  function MoveUp() {
    // () => UpdateActiveStatus(selectedFreq + 1)
    let newFreq = selectedFreq + 1 > 4 ? 4 : selectedFreq + 1;
    setSelectedFreq(newFreq);
    // console.log(choice.filter((fl) => fl.id === newFreq)[0].value)
    UpdateActiveStatus(choice.filter((fl) => fl.id === newFreq)[0].value);
  }
  function MoveDown() {
    let newFreq = selectedFreq - 1 < 1 ? 1 : selectedFreq - 1;
    setSelectedFreq(newFreq);

    // console.log(choice.filter((fl) => fl.id === newFreq)[0].value)
    UpdateActiveStatus(choice.filter((fl) => fl.id === newFreq)[0].value);
  }

  function UpdateActiveStatus(frequency) {
    if (selected?.id) {
      setProcessing(true);
      dispatch({
        type: SG_EDIT_GLOBAL_SCHEDULE_FREQUENCY,
        payload: {
          id: selected.id,
          frequency: frequency,
        },
      });
    }
  }

  return (
    <SelectContainer>
      {selectedFreq
        ? choice.filter((fl) => fl.id === selectedFreq)[0].text
        : ""}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 5,
          cursor: "pointer",
        }}
      >
        <Icon
          name="chevron up"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveUp}
        />
        <Icon
          name="chevron down"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveDown}
        />
      </div>
    </SelectContainer>
  );
};

const DayOfWeek = [
  { value: 0, text: "Monday" },
  { value: 1, text: "Tuesday" },
  { value: 2, text: "Wednesday" },
  { value: 3, text: "Thursday" },
  { value: 4, text: "Friday" },
  { value: 5, text: "Saturday" },
  { value: 6, text: "Sunday" },
];

export const SelectWeekDay = ({ selectedDay, setSelectedDay }) => {
  function MoveUp() {
    // console.log("MoveUp", selectedDay)
    setSelectedDay(selectedDay <= 5 && selectedDay >= 0 ? selectedDay + 1 : 6);
  }
  function MoveDown() {
    // console.log("MoveDown", selectedDay)
    setSelectedDay(selectedDay <= 6 && selectedDay >= 1 ? selectedDay - 1 : 0);
  }

  return (
    <SelectContainer margin={"10px"}>
      {DayOfWeek.filter((fl) => fl.value === selectedDay)[0].text}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 5,
          cursor: "pointer",
        }}
      >
        <Icon
          name="chevron up"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveUp}
        />
        <Icon
          name="chevron down"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={MoveDown}
        />
      </div>
    </SelectContainer>
  );
};

export const SelectTime = ({ selectedTime, setSelectedTime }) => {
  return (
    <SelectContainer margin={"10px"}>
      {selectedTime
        ? time.filter((fl) => fl.value === selectedTime)[0]?.text
        : ""}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 5,
          cursor: "pointer",
        }}
      >
        <Icon
          name="chevron up"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={() =>
            setSelectedTime(
              selectedTime < 17 && selectedTime > 8 ? selectedTime + 1 : 17
            )
          }
        />
        <Icon
          name="chevron down"
          size={"small"}
          style={{ color: "#4a4a4a" }}
          onClick={() =>
            setSelectedTime(
              selectedTime < 18 && selectedTime > 8 ? selectedTime - 1 : 8
            )
          }
        />
      </div>
    </SelectContainer>
  );
};

export const SelectDate = React.forwardRef((props, ref) => (
  <SelectContainer
    ref={ref}
    onClick={props.onClick}
    style={{ cursor: "pointer", zIndex: 999 }}
  >
    <Icon
      name="calendar alternate"
      size={"large"}
      style={{ color: "#6a00ff", marginRight: 8 }}
    />{" "}
    {props.value}
  </SelectContainer>
));

export const LeaderCard = (props) => {
  const { employee } = props;
  const { selectedLeader, leaderParticipants } = props;
  const [status, setStatus] = React.useState(0);

  React.useEffect(() => {
    if (selectedLeader?.id) {
      setStatus(
        leaderParticipants.filter(
          (lp) => lp.leaders_in_global_schedule === selectedLeader?.id
        )
      );
    } else {
      setStatus([]);
    }
  }, [selectedLeader, leaderParticipants]);
  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "70%",
          wordBreak: "break-word",
        }}
      >
        <Avatar src={employee?.picture ? employee.picture : PersonImage} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Name>
            {employee?.first_name} {employee?.last_name}
          </Name>
          <Email>{employee?.email}</Email>
          <MobileInvite>
            <Email style={{ alignItems: "right" }}>
              {status.length} invited
            </Email>
          </MobileInvite>
        </div>
      </div>
      <InviteContainer>
        <Name style={{ alignItems: "right" }}>{status.length} invited</Name>
      </InviteContainer>

      {selectedLeader ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <ModalLeaderTeams
            employee={employee}
            selectedLeader={selectedLeader}
            schedule_id={props.schedule_id}
            organizationId={props.organizationId}
            leaderParticipants={status}
            team={employee?.team}
            trigger={
              <IconContainer color={"#6a00ff"}>
                <Icon name="pencil" size={"small"} style={{ margin: 0 }} />
              </IconContainer>
            }
          />

          <IconContainer color={"#ff2d55"}>
            <Icon
              name="trash alternate"
              size={"small"}
              style={{ margin: 0 }}
              onClick={() =>
                props.AddRemoveLeader(selectedLeader.id, employee.id)
              }
            />
          </IconContainer>
        </div>
      ) : (
        <Highlight onClick={() => props.AddRemoveLeader(null, employee.id)}>
          Setup 360
        </Highlight>
      )}
    </Card>
  );
};

export const TeamEmployeeSelection = (props) => {
  const { emp, participants } = props;
  return (
    <EmployeeSelect>
      <Selector
        selected={
          participants?.employee ? participants?.employee === emp?.id : false
        }
        onClick={() =>
          props.AddRemoveEmployee(
            participants?.employee ? participants?.id : null,
            emp.id
          )
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <P>
          {emp.first_name} {emp.last_name} {emp?.id}
        </P>
        <P2>{emp.email}</P2>
      </div>
    </EmployeeSelect>
  );
};
