import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import { DropdownMenu,
    DropdownItem,
    DropdownHeader,
    DropdownDivider,
    Dropdown} from 'semantic-ui-react'
import data from './data'

const Filters = ({selected,onSelect}) =>{



    return (
        <>
             <Dropdown
                fluid
                selection
                placeholder='Filter by tag'
                style={{width:150,marginRight:10}}
                value={selected}
                onChange={(e,{value})=>onSelect(value)}
                options={friendOptions}
                clearable
             />
        </>
       

    )

}

export default Filters

const Clear = styled.div`
    cursor:pointer;
`

const friendOptions = [
    {
      key: 'Location #1',
      text: 'Location #1',
      value: 'Location #1',
    },
    {
      key: 'Location #2',
      text: 'Location #2',
      value: 'Location #2',
    },
    {
      key: 'Development',
      text: 'Development',
      value: 'Development',
    },
    {
      key: 'Sales',
      text: 'Sales',
      value: 'Sales',
    },
    {
      key: 'Leadership',
      text: 'Leadership',
      value: 'Leadership',
    },
    {
      key: 'Operations',
      text: 'Operations',
      value: 'Operations',
    },
  ];