import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Textbox from "../PlanComponent/Textbox";
import SectionTitle from "../PlanComponent/SectionTitle";
import PillarLabel from "../PlanComponent/PillarLabel";
import GroupTitle from "../PlanComponent/GroupTitle";
import MultiDropdown from "../PlanComponent/MultiDropdown";
import TextInput from "../PlanComponent/TextInput";
import DateSelector from "../PlanComponent/DateSelector";
import MultiselectUser from "../PlanComponent/MultiselectUser";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_CULTURE_AUDIT_REPORTS,
  SET_QUESTIONS,
  SET_NAVIGATION,
  SET_RAW_DATA,
  SET_DATA_DATES,
  SG_GET_EMPLOYEE_CATEGORIES,
} from "constants/actions";
import Timelines from "../PlanComponent/Timelines";
import StatusDropdown from "../PlanComponent/StatusDropdown";
import ResourceLink from "../PlanComponent/ResourceLink";
import ReflectionTable from "../PlanComponent/ReflectionTable";
import MultiDataTable from "../PlanComponent/MultiDataTable";
import DropdownTable from "../PlanComponent/DropdownTable/DropdownTable";
import EIAComponent from "../PlanComponent/EIAComponent";
import ComponentDescription from "../PlanComponent/ComponentDescription";
import WellnessTable from "../PlanComponent/WellnessTable";
import MultiselectDropdown from "../PlanComponent/MultiselectDropdown";
import WellnessTop from "../PlanComponent/WellnessTop";
import BarGraph from "../PlanComponent/BarGraph";
import DateRange from "../PlanComponent/DateRange";

import {
  getColConfig,
  getNSOTableData,
  getTranslatedText,
} from "components/CWFPDF/pdfUtils";
import translations from "components/CWFPDF/translations.json";
import {
  build_default_State,
  get_dates,
  get_most_recent,
  get_last_responses,
  sort_historical_data,
  calculate_otp_table_data,
} from "reports/Audit/data_navigation/calculate_data";

const PlanLayout = (props) => {
  const dispatch = useDispatch();
  const {
    planTemplate,
    pillar,
    setData,
    data,
    close,
    showStartDateError,
    showEndDateError,
    questions,
  } = props;
  const [employeesList, setEmployeesList] = React.useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [employeesListSet, setEmployeesListSet] = React.useState(false);
  const [calculatedData, setCalculatedData] = useState([]);
  const [reportData, setReportData] = useState();
  const [questionStructure, setQuestionStructure] = useState();
  const [colConfig, setColConfig] = useState();
  const [tableData, setTableData] = useState();
  const [barData, setBarData] = useState();

  // ******** Selectors ********
  const {
    get_employees,
    get_employee_categories,
    survey_version,
    WhiteLabel,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    get_auth,
    get_survey_questions,
  } = useSelector(
    (state) => ({
      get_organizations: state.organizations,
      get_culture_audit_reports: state.debrief_schedule.culture_audit_reports,
      get_survey_structure: state.surveystructure.survey_structure,
      get_auth: state.auth,
      WhiteLabel: state.white_label?.white_label,
      survey_version: state.audit.survey_version,
      get_survey_questions: state.surveyquestions.survey_questions,
      get_employee_categories: state.employee_category.employee_category,
      get_employees: state.employees,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_QUESTIONS,
      payload: `survey_type=8&status=2`,
    });
  }, []);

  useEffect(() => {
    if (!props?.savedTemplate) {
      if (get_survey_structure && get_survey_structure[0]) {
        const DefState = build_default_State(get_survey_structure[0]);
        dispatch({
          type: SET_NAVIGATION,
          payload: DefState,
        });
        if (get_culture_audit_reports.response) {
          if (get_culture_audit_reports.response.length > 0) {
            let sorted_responses = get_culture_audit_reports.response.sort(
              (a, b) => b.id - a.id
            );

            if (survey_version) {
              sorted_responses = sorted_responses.filter(
                (f) => f.question.sort_order === survey_version.sort_order
              );
            }

            //   if (checkPersonality(get_organizations)) {
            //     //Modify the results to include personality as a categorical variable
            //     sorted_responses =
            //       convert_personality_to_categories(sorted_responses);
            //   }

            if (data?.primary) {
              sorted_responses = sorted_responses.filter((responseGroup) => {
                return responseGroup?.name_uid === data?.primary;
              });
            }

            let most_recent;
            most_recent = get_most_recent(sorted_responses);

            let total = {};
            most_recent.responses?.map((r) => {
              let n = r.categories.length.toString();
              if (!(n in total)) {
                total[n] = 1;
              } else {
                total[n] += 1;
              }
            });

            most_recent["last"] = get_last_responses(sorted_responses);

            if (sorted_responses.length > 0 && most_recent.responses) {
              let historical_data = sort_historical_data(
                sorted_responses.reverse(),
                0
              );

              setReportData([most_recent, ...historical_data]);

              // setComments(check_comments(most_recent, get_employees));

              dispatch({
                type: SET_RAW_DATA,
                payload: [most_recent, ...historical_data],
              });

              dispatch({
                type: SET_DATA_DATES,
                payload: get_dates(historical_data),
              });
            }
          }
        }
      }
    }
  }, [
    dispatch,
    get_survey_structure,
    get_culture_audit_reports,
    get_organizations,
    survey_version,
    data?.primary,
  ]);

  useEffect(() => {
    if (!props?.data?.planData[3]) {
      if (get_auth?.organization_id) {
        dispatch({
          type: SG_GET_CULTURE_AUDIT_REPORTS,
          payload: `audit-type-id=5&organization=${get_auth?.organization_id}`,
        });
      }
    }
  }, [get_auth?.organization_id]);

  /** useEffect Hooks
   * 1. Calculate data for table
   * 2. Get column config for table
   * 3. Get table data
   * 4. Get URI
   * 5. Get Spider options
   */
  useEffect(() => {
    if (!props?.savedTemplate) {
      if (reportData && get_survey_structure?.[0] && questionStructure) {
        const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : 2;
        const secondary = get_survey_structure[0]?.categories?.find(
          (category) => category.priority === "secondary"
        );

        setCalculatedData(
          calculate_otp_table_data(
            reportData,
            get_survey_structure[0],
            secondary?.id,
            orgRule,
            questionStructure,
            data?.primary,
            true
          )
        );
      }
    }
  }, [reportData, get_survey_structure[0], questionStructure, data?.primary]);

  // 2. Get column config for table
  useEffect(() => {
    if (get_survey_structure?.[0]) {
      setColConfig(getColConfig(get_survey_structure[0], "en"));
    }
  }, [get_survey_structure[0]]);

  useEffect(() => {
    if (!props?.savedTemplate) {
      if (
        calculatedData &&
        calculatedData?.responses?.length > 0 &&
        get_survey_structure?.[0] &&
        colConfig
      ) {
        const tableDataArr = getNSOTableData(
          calculatedData,
          get_survey_structure[0],
          questionStructure,
          colConfig,
          "en",
          translations
        );
        setTableData(tableDataArr);
      } else {
        setTableData();
      }
    }
  }, [
    calculatedData,
    colConfig,
    get_survey_structure[0],
    questionStructure,
    data?.primary,
  ]);

  useEffect(() => {
    if (survey_version) {
      if (get_survey_questions && get_survey_questions.length > 0) {
        let SurveyQues;

        if (survey_version) {
          SurveyQues = {
            ...get_survey_questions.filter(
              (sq) => sq.sort_order === survey_version.sort_order
            )?.[0],
          };
        }

        const QuesStruc = {
          title: SurveyQues?.questions?.title,
          dimensions: SurveyQues?.questions?.dimensions,
          type: SurveyQues?.questions?.type,
          type_name: SurveyQues?.questions?.type_name,
          comments: SurveyQues?.questions?.comments,
        };
        setQuestionStructure(QuesStruc);
        dispatch({
          type: SET_QUESTIONS,
          payload: QuesStruc,
        });
      }
    } else if (get_survey_questions?.[0]) {
      let SurveyQues = { ...get_survey_questions[0] };

      if (survey_version) {
        SurveyQues = {
          ...get_survey_questions.filter(
            (sq) => sq.sort_order === survey_version.sort_order
          )?.[0],
        };
      }

      const QuesStruc = {
        title: SurveyQues?.questions?.title,
        dimensions: SurveyQues?.questions?.dimensions,
        type: SurveyQues?.questions?.type,
        type_name: SurveyQues?.questions?.type_name,
        comments: SurveyQues?.questions?.comments,
      };

      setQuestionStructure(QuesStruc);
      dispatch({
        type: SET_QUESTIONS,
        payload: QuesStruc,
      });
    }
  }, [dispatch, get_survey_questions, survey_version]);

  useEffect(() => {
    setEmployeesListSet(false);
  }, [data?.primary]);

  useEffect(() => {
    if (get_auth && get_auth?.organization) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `organization=${get_auth?.organization?.id}`,
      });
    }
  }, [get_auth]);

  useEffect(() => {
    if (get_employees && get_employees?.employees.length > 0) {
      let employeeOptions = get_employees?.employees.map((employee) => {
        if (employee.id) {
          const displayText = employee.first_name
            ? `${employee.first_name} ${employee.last_name}`
            : employee.email;

          return {
            key: employee.id,
            text: displayText,
            value: {
              id: employee.id,
              name: displayText,
              member: displayText,
            },
          };
        }
      });
      if (employeeOptions) {
        if (get_employee_categories?.length > 1) {
          const filteredEmps = employeeOptions.filter((f) => {
            const empCats = get_employee_categories.find(
              (c) => c.employee === f.key
            );
            const validEmployee = empCats?.category?.find(
              (cat) =>
                cat?.name_id === data.primary || cat?.value_id === data.primary
            );
            return !!validEmployee;
          });

          setEmployeesList(filteredEmps);
        }
      } else {
        setEmployeesList(employeeOptions);
      }
      setEmployeesListSet(true);
    }
  }, [employeesListSet, get_employees, get_employee_categories, data?.primary]);

  // Handle date changes
  //   Plan start date.
  useEffect(() => {
    if (startDate) {
      const _data = Object.assign({}, data);
      _data.startDate = startDate;
      setData(_data);
    }
  }, [startDate]);

  //  Plan end date.
  useEffect(() => {
    if (endDate) {
      const _data = Object.assign({}, data);
      _data.endDate = endDate;
      setData(_data);
    }
  }, [endDate]);

  const onTextboxChange = (value, id) => {
    const _data = Object.assign({}, data);
    _data.planData[id] = value;
    setData(_data);
  };

  const onDropdownChange = (value, id, index) => {
    const _data = Object.assign({}, data);

    _data.planData[id][index].value = value;
    setData(_data);
  };

  const handleDateRangeSelected = (date, id, index) => {
    const _data = Object.assign({}, data);
    if (Array.isArray(_data.planData[id])) {
      if (_data.planData?.[id]?.[index]) {
        _data.planData[id][index] = date;
      } else {
        _data.planData[id].push(date);
      }
    } else {
      _data.planData[id] = [date];
    }
    setData(_data);
  };

  const onDropdownCommentChange = (value, id, index) => {
    const _data = Object.assign({}, data);
    _data.planData[id][index].comment = value;
    setData(_data);
  };

  const setMember = (d) => {
    const _data = Object.assign({}, data);
    _data.member = d;
    setData(_data);
  };

  const setStatus = (d) => {
    const _data = Object.assign({}, data);
    _data.status = d;
    setData(_data);
  };

  const handleDateStartChange = (d, id, index) => {
    const _data = Object.assign({}, data);
    _data.planData[id][index].startDate = d;
    setData(_data);
  };

  const handleDateEndChange = (d, id, index) => {
    const _data = Object.assign({}, data);
    _data.planData[id][index].endDate = d;
    setData(_data);
  };

  // For reflection/dynamic tables
  const handleTableChange = (d, id) => {
    const _data = Object.assign({}, data);
    _data.planData[id] = d;
    setData(_data);
  };

  const handleCheckinDatesSelected = (date, id, index) => {
    const _data = Object.assign({}, data);
    _data.planData[id] = date;
    setData(_data);
  };

  const handleTableData = (d) => {
    const _data = Object.assign({}, data);
    _data.planData[3] = d;
    setData(_data);
  };

  const handleEIAFactorSelect = (d) => {
    const _data = Object.assign({}, data);
    _data.planData[1] = d;
    setData(_data);
  };

  const handleEIAQuestionSelect = (d) => {
    const _data = Object.assign({}, data);
    _data.planData[2] = d;
    setData(_data);
  };

  const handleWellnessData = (d) => {
    const _data = Object.assign({}, data);
    _data.planData[3] = d;
    setData(_data);
  };

  const handleDimensionData = (d) => {
    const _data = Object.assign({}, data);
    _data.planData[4] = d;
    setData(_data);
  };

  const getComponent = (componentData, pillar, index) => {
    let component;
    switch (componentData.type) {
      case "textbox":
        component = (
          <Textbox
            key={index}
            onChange={(value, id) => onTextboxChange(value, id)}
            {...componentData}
            data={data}
            editEnabled={true}
          />
        );
        break;

      case "textinput":
        component = (
          <TextInput
            key={index}
            onChange={(value, id) => onTextboxChange(value, id)}
            {...componentData}
            data={data?.planData}
            editEnabled={true}
          />
        );
        break;

      case "sectiontitle":
        component = <SectionTitle key={index} {...componentData} />;
        break;

      case "culturepillar":
        component = <PillarLabel key={index} pillar={pillar} />;
        break;

      case "grouptitle":
        component = <GroupTitle key={index} {...componentData} />;
        break;

      case "multidropdown":
        component = (
          <MultiDropdown
            key={index}
            {...componentData}
            selected={data.planData}
            handleDropdownChange={(value, id, index) =>
              onDropdownChange(value, id, index)
            }
            handleTextChange={(value, id, index) =>
              onDropdownCommentChange(value, id, index)
            }
            editEnabled={true}
            comments={data.planData}
          />
        );
        break;

      case "dateselection":
        component = (
          <DateSelector
            key={index}
            {...componentData}
            data={data}
            editEnabled={true}
            handleDatesSelected={(date, id) => {
              handleCheckinDatesSelected(date, id);
            }}
          />
        );
        break;

      case "multiselectuser":
        component = (
          <MultiselectUser
            key={index}
            employeesList={employeesList}
            onSelect={setMember}
            editEnabled={true}
            {...componentData}
            member={data?.member}
          />
        );
        break;

      case "timeline":
        component = (
          <Timelines
            dateStart={startDate}
            dateEnd={endDate}
            editEnabled={true}
            setStartDate={(d, id, index) => {
              if (componentData?.planData) {
                handleDateStartChange(d, id, index);
              } else {
                setStartDate(d);
              }
            }}
            setEndDate={(d, id, index) => {
              if (componentData?.planData) {
                handleDateEndChange(d, id, index);
              } else {
                setEndDate(d);
              }
            }}
            {...componentData}
            data={data}
            showStartDateError={showStartDateError}
            showEndDateError={showEndDateError}
          />
        );
        break;

      case "planstatus":
        component = (
          <StatusDropdown
            key={index}
            {...componentData}
            editEnabled={true}
            setStatus={setStatus}
            data={data}
          />
        );

        break;

      case "componentdescription":
        component = <ComponentDescription key={index} {...componentData} />;
        break;

      case "reflectiontable":
        component = (
          <ReflectionTable
            key={index}
            {...componentData}
            editEnabled={true}
            onChange={(d, id) => handleTableChange(d, id)}
            data={data}
          />
        );
        break;

      case "dynamicinputtable":
        component = (
          <ReflectionTable
            key={index}
            {...componentData}
            dynamic={true}
            editEnabled={true}
            onChange={(d, id) => handleTableChange(d, id)}
            data={data}
          />
        );

        break;
      case "resourcelinks":
        component = <ResourceLink key={index} {...componentData} />;
        break;

      case "commenttable":
        component = (
          <MultiDataTable
            key={index}
            {...componentData}
            data={data}
            editEnabled={true}
            onChange={(d, id) => handleTableChange(d, id)}
          />
        );
        break;

      case "daterange":
        component = (
          <DateRange
            key={index}
            {...componentData}
            data={data}
            editEnabled={true}
            handleDatesSelected={(date, id, idx) => {
              handleDateRangeSelected(date, id, idx);
            }}
          />
        );
        break;

      case "dynamicdropdowntable":
        component = (
          <DropdownTable
            key={index}
            {...componentData}
            editEnabled={true}
            onChange={(d, id) => handleTableChange(d, id)}
            data={data}
          />
        );
        break;

      case "eiacomponent":
        component = (
          <EIAComponent
            key={index}
            {...componentData}
            editEnabled={true}
            onChange={(d, id) => handleTableChange(d, id)}
            data={data}
            questions={questions}
            primary={data?.primary}
            handleTableData={(d) => handleTableData(d)}
            handleEIAFactorSelect={(d) => handleEIAFactorSelect(d)}
            handleEIAQuestionSelect={(d) => handleEIAQuestionSelect(d)}
          />
        );
        break;

      case "wellnesstable":
        component = (
          <WellnessTable
            key={index}
            {...componentData}
            editEnabled={true}
            onChange={(d, id) => handleTableChange(d, id)}
            data={data}
            primary={data?.primary}
            handleWellnessData={(d) => handleWellnessData(d)}
          />
        );
        break;

      case "wellnesstop":
        component = <WellnessTop />;
        break;

      case "multiselectdropdown":
        component = (
          <MultiselectDropdown
            key={index}
            {...componentData}
            data={data}
            editEnabled={true}
            onSelect={(d, id) => handleTableChange(d, id)}
          />
        );
        break;

      case "bargraph":
        component = (
          <BarGraph
            average={
              tableData
                ? {
                    ...tableData?.[componentData?.dimension]?.data?.[
                      componentData?.factor
                    ],
                  }?.factorAverage?.toFixed(1)
                : 0
            }
            categories={get_survey_structure[0]}
            data={
              tableData
                ? {
                    ...tableData?.[componentData?.dimension]?.data?.[
                      componentData?.factor
                    ],
                  }
                : null
            }
            saveData={(d) => handleDimensionData(d)}
            isFrench={false}
            title={componentData?.title}
            key={index}
          />
        );
        break;

      default:
        component = null;
    }
    return component;
  };
  return (
    <LayoutContainer>
      {planTemplate?.screenComponents?.map((componentData, index) => {
        return getComponent(componentData, pillar, index);
      })}
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  padding-left: 10px;
  margin-top: 20px;
  padding-bottom:150px;
  width: 100%;
  height: 100%;
  max-width:900px;
`;



export default PlanLayout;
