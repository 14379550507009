import styled, { keyframes } from "styled-components";
import React from "react";
import { Icon, Label } from 'semantic-ui-react'
import AddTag from './AddTag'

const UserTag = ({categories}) =>{


	return (
		<Container>
			<Text>Tags</Text>
			<TagArea>
				<Label >
			      Pay bracket: $70,000-90,000
			      <Icon name='delete' />
			    </Label>

			    <Label >
			      Franchise: London
			      <Icon name='delete' />
			    </Label>

			    <Label >
			      Remote : 1 day
			      <Icon name='delete' />
			    </Label>

			    <Label >
			      Tag 4: ----
			      <Icon name='delete' />
			    </Label>
			   <AddTag categories={categories} missing={true}/>
			    <AddTag categories={categories}/>
			</TagArea>
		</Container>
		
		)
}


export default UserTag



const Container = styled.div`
	display:flex;
	margin-top:20px;
	align-items:center;
`

const Text = styled.div`
	 font-family: "Barlow", sans-serif;
	 width:100px;
`

const TagArea = styled.div`
	display:flex;
`
const Warning = styled.div`
	
`