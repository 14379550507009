import styled, { keyframes } from "styled-components";
import React, { useEffect, useLayoutEffect, useState,useRef } from "react";
import { Icon,Modal,Label,Loader,Dimmer,Progress } from 'semantic-ui-react'
import Chart from "react-apexcharts";




const IndividualModal = (props) =>{
	 const [open, setOpen] = React.useState(false)
	 const [series,setSeries] = useState([{'name':'data',data:[0,0,0,0,0,0,20,30,40,10,0]}])

	 const [loading,setLoading] = useState(true)
	 const [options1,setOptions1] = useState(null)
	 const [options2,setOptions2] = useState(null)

	

	


	useEffect(()=>{
		let check = false
		if(options1){
			check = options1.colors[0] != props.color
			console.log(props.color,options1.colors[0],options1.colors[0] == props.color)
		}
		if(check){
			console.log('not the same color')
			setLoading(true)
		}

		
		if(!options1 || check){
			 let _options = data3.options
	 		_options.colors = [props.color,'#b5b5b5']
			setOptions1(_options)

			 let _options2 = data2.options
	 		_options2.colors = [props.color]
	 		setOptions2(_options2)


	 		setTimeout(() => setLoading(false), 300);
		}

	},[open])



	return (
		<Container>
	      <Modal.Header style={{padding:30}}>
	      	<Role>{props.title}</Role>


	      	 <Question>{props.question}</Question>


	      </Modal.Header>
	      <SquareContainer>
	      		<Square width={75}>
					<Title>Long term trends</Title>
					<Description>Index scores from most recent survey. Aug 6th, 2020</Description>
					{loading?
								 <Dimmer active inverted>
						        <Loader inverted>Loading</Loader>
						      </Dimmer>:
						<Chart options={options1} series={data3.series} type="line" height={'100%'} />
					}
				</Square>

				<Square width={25}>
				<Title>Role Norms</Title>
				<Description>Index scores from most recent survey. Aug 6th, 2020</Description>
					<Chart options={data.options} series={data.series} type="radialBar" height={'100%'} style={{marginTop:-20}}/>
					<Change>Since last assessment <Label style={{marginLeft:10}} color={'green'}>+5%</Label></Change>
				</Square>

				<Square width={75}>
					<Title>Score distribution</Title>
					<Description>Index scores from most recent survey. Aug 6th, 2020</Description>
					{loading?
								 <Dimmer active inverted>
						        <Loader inverted>Loading</Loader>
						      </Dimmer>:
					<Chart options={options2} series={series} type="bar" height={'100%'} />}
					
				</Square>

				<Square width={25}>
					<Title>Effect size insights</Title>
					<Description>Critical factors that are most impacted and in need of attention</Description>
					<Labels>
						<LabelContainer>
							<Title>Experience</Title>
							<Progress progress='value' value={1.5} total={2} size="small"/>
						</LabelContainer>

						<LabelContainer>
							<Title>Age</Title>
							<Progress progress='value' value={0.5} total={2} size="small"/>
						</LabelContainer>

						<LabelContainer>
							<Title>Race</Title>
							<Progress progress='value' value={0.2} total={2} size="small"/>
						</LabelContainer>

						<LabelContainer>
							<Title>Gender</Title>
							<Progress progress='value' value={0.1} total={2} size="small"/>
						</LabelContainer>
					</Labels>

				</Square>

	      </SquareContainer>
	    </Container>
		)
}


export default IndividualModal


const Container = styled.div`
	height:100%;
	width:100%;
`


const Labels = styled.div`
	display:flex;
	flex-wrap:wrap;
	margin-top:30px;
	width:100%;
`

const LabelContainer = styled.div`
	width:100%;
`



const Square = styled.div`
	width:${props=>props.width+'%'};
	height:350px;
	padding:20px;
	border:1px solid lightgrey;
`

const SquareContainer = styled.div`
	display:flex;
	flex-wrap:wrap;
`
const Title = styled.div`
	font-size:10px;
	text-transform:uppercase;
	font-weight:bold;
	margin-bottom:3px;
`
const Description = styled.div`
	margin-top:0px;
	font-size:12px;
`

const Role = styled.div`
	font-family: 'Barlow', sans-serif;
	font-size:30px;
	margin-bottom:10px;
`

const Question = styled.div`
	font-family: 'Barlow', sans-serif;
	font-weight:300;
`
const Change = styled.div`
	width:100%;
	display:flex;
	justify-content:center;
	align-items:center;
	font-size:12px;
	margin-top:-20px;
`

const data3 = {
	series: [

              {
                name: "Average Score",
                data: [8.2, 8.5, 9,7.6]
              },
              {
                name: "Category Norms",
                data: [8.6, 8.0, 7.3,8.5]
              }
            ],
            options: {
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: false,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left'
              },
              colors:['#E3B01F','#b5b5b5'],
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               stroke: {
          width: [5, 2],
          curve: 'straight',
          dashArray: [0, 8]
        },  xaxis: {
                categories: ['2017', '2018', '2019', '2020', '2021', '2022', '2023'],
              },
              yaxis: {
                title: {
                  text: 'Score'
                },
                min: 0,
                max: 10,
                 decimalsInFloat: 0,
              },
            }
}

const data2 = {
	series: [

              {
                name: "Scores",
                data: [0,0,0,0,1,2,8,3,2,0,1]
              },
            ],
            options: {
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                 sparkline: {
			        enabled: false,
			    }
              },
              legend:{
              	position:'top',
              	horizontalAlign:'left',
              	show:false
              },
              colors:['#0387EF','#FFC600','#FF0000',"#02AE5B"],
              fill:{
              	opacity:0.3
              },
              grid:{
              	padding:{
              		left:10,
              		right:10,
              		bottom:20,
              		top:10
              	}
              },
               dataLabels: {
			  enabled: false
			},
			stroke: {
			  curve: "smooth"
			},
              xaxis: {
                categories: ['0','1','2','3','4','5','6','7','8','9','10'],
              },
              yaxis: {
                title: {
                  text: 'Score frequency'
                },
                 decimalsInFloat: 0,
              },
            }
}

const data = {
          
            series: [80],
            options: {
              chart: {
                height: 350,
                type: 'radialBar',
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '50%',
                  },
                  dataLabels: {
                    show: true,
                    name: {
                      offsetY: -10,
                      show: true,
                      color: '#888',
                      fontSize: '17px'
                    },
                    value: {
                      formatter: function(val) {
                        return parseInt(val);
                      },
                      color: 'black',
                      fontSize: '36px',
                      show: true,
                    }
                  },
                },
              },
              
              labels: ['Percentile'],
              fill: {
                colors:['#b5b5b5']
              },

            },
          
          
          }