import React, { useState,useEffect } from 'react';

import styled, { keyframes } from "styled-components";
import Chart from "react-apexcharts";
import {Table} from 'semantic-ui-react'

const Scatter = () =>{
    

    return (
        <Container>
          <Content>
            <Title>The impact of Organizational Culture on Absenteeism and Turnover - Q4 2024</Title> 

            <Description>
            Understanding the intricate relationship between organizational culture and employee absenteeism and turnover is crucial for businesses aiming to enhance their workforce stability. Research indicates that a positive organizational culture, characterized by strong internal communication, psychological safety, well-being, empathy, and belonging, directly impacts employee morale and retention. This, in turn, reduces absenteeism and turnover rates.
</Description>

<T2>Absenteeism leads turn over by 6 months</T2>
<Description>
In Q4 2024, we conducted a comprehensive study across multiple store locations to analyze how various aspects of organizational culture influence absenteeism and turnover. Our findings reveal that clear and transparent communication from leadership fosters trust and alignment among employees, leading to a more cohesive and committed workforce. Psychological safety, where employees feel valued and heard, enhances their engagement and reduces the likelihood of absenteeism and turnover. Furthermore, fostering well-being, empathy, and a sense of belonging ensures that employees remain resilient and satisfied, even in dynamic environments.
</Description>


<Chart
    options={data2.options}
    series={data2.series}
    type="bar"
    height={500}
  />

  <Description>
  Our analysis demonstrates a significant correlation between culture scores and absenteeism and turnover rates. Stores in the highest quartile of culture scores experience the lowest absenteeism and turnover rates, with absenteeism leading turnover by approximately six months. Specifically, stores with high scores in psychological safety, well-being, empathy, and belonging consistently achieve lower absenteeism and turnover rates. Investing in initiatives that enhance these culture factors can significantly reduce absenteeism and turnover, leading to a more stable and productive workforce.
  </Description>

<T2>How culture factors relate to absenteeism and turnover</T2>

<Description>
According to the most recent culture data collected, culture factors <strong>explain 67%</strong> of the variability in absenteeism and turnover rates at each store location. The top four culture factors that predict these rates are psychological safety, well-being, empathy, and belonging.
</Description>

<Chart
    options={data3.options}
    series={data3.series}
    type="bar"
    height={500}
/>

<Description>
Our analysis indicates that stores with high scores in these culture factors consistently achieve lower absenteeism and turnover rates, demonstrating the critical role of a positive workplace culture in enhancing employee retention. Investing in initiatives that enhance psychological safety, well-being, empathy, and belonging can significantly reduce absenteeism and turnover, leading to a more stable and productive workforce.
</Description>

<T2>Current culture factor drivers of absenteeism and turnover</T2>

<Table>
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell>Cause</Table.HeaderCell>
      <Table.HeaderCell>Effect</Table.HeaderCell>
    </Table.Row>
  </Table.Header>
  <Table.Body>
    <Table.Row>
      <Table.Cell>Leadership communication on company vision changes</Table.Cell>
      <Table.Cell>Positive impact on absenteeism and turnover as clear communication increases trust and alignment with company goals</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Introduction of regular feedback loops</Table.Cell>
      <Table.Cell>Increased psychological safety leading to lower absenteeism and turnover due to employees feeling heard and valued</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Implementation of cross-functional team projects</Table.Cell>
      <Table.Cell>Mixed reactions; boosts empathy and belonging for some, while others feel overwhelmed, slightly affecting absenteeism and turnover</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Transparent handling of layoffs</Table.Cell>
      <Table.Cell>Slight increase in absenteeism and turnover due to inherent stress, but transparency mitigates long-term negative impact</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Frequent shifts in strategic priorities</Table.Cell>
      <Table.Cell>Increased absenteeism and turnover due to perceived instability and lack of clear direction</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Inconsistent application of performance reviews</Table.Cell>
      <Table.Cell>Negative impact on absenteeism and turnover due to perceived unfairness and lack of clarity</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Sudden changes in management</Table.Cell>
      <Table.Cell>Negative effect on absenteeism and turnover due to uncertainty and disruption in established relationships</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Changes in internal communication platforms</Table.Cell>
      <Table.Cell>Mixed feedback; initial increase in absenteeism and turnover due to adjustment period, eventual decrease as efficiency improves</Table.Cell>
    </Table.Row>
    <Table.Row>
      <Table.Cell>Rapid scaling of the organization</Table.Cell>
      <Table.Cell>Challenges in adaptability lead to increased absenteeism and turnover due to growing pains and integration issues</Table.Cell>
    </Table.Row>
  </Table.Body>
</Table>

<T2>Actions to be taken</T2>
<Description>
To reduce absenteeism and turnover, organizations should focus on enhancing the identified culture factors. Firstly, fostering psychological safety can be achieved through regular feedback loops and open communication channels where employees feel valued and heard. Prioritizing well-being through mental health resources and work-life balance initiatives helps reduce stress and burnout. Encouraging empathy within teams through cross-functional projects and collaborative tasks can strengthen relationships and enhance a sense of belonging.
</Description>

<Description>
Specific actions include implementing regular feedback loops to gather employee insights, promoting cross-functional team projects to improve collaboration and empathy, and ensuring consistent application of performance reviews to maintain fairness and clarity. Transparent handling of organizational changes, such as layoffs or strategic shifts, is crucial to maintaining employee morale and trust. By addressing these areas, organizations can enhance their overall culture, leading to reduced absenteeism and turnover, and fostering a more stable and productive workforce.
</Description>

</Content>
        </Container>
    )
}

export default Scatter



const Container = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
flex-direction:row;
max-width:100%;
`

const Content = styled.div`
  width:700px;
  font-family:'Raleway';
  padding:30px;
  padding-left:50px;
  padding-top:65px;

`
const Title = styled.div`
    font-size:24px;
    font-weight:bold;
    margin-bottom:20px;
    line-height:1.2;
`

const T2 = styled.div`
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
    margin-top:20px;
`

const Description = styled.div`
  margin-bottom: 20px;
  line-height:1.8;
`

const Statement = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
`
const data2 = {
  series: [{
      name: 'Absenteeism',
      data: [15, 12, 10, 5]
  }, {
      name: 'Turnover',
      data: [20, 18, 15, 8]
  }],
  options: {
      chart: {
          height: "100%",
          type: "line",
          fontFamily: "Raleway",
          toolbar: {
              show: false,
          },
          animations: {
              enabled: false,
          },
      },
      stroke: {
          curve: "straight",
      },
      grid: {
          borderColor: "#e7e7e7",
          xaxis: {
              lines: {
                  show: true,
              },
          },
      },
      markers: {
          size: [5, 0],
          strokeWidth: 1,
          radius: 2,
          strokeColors: "#476DFA",
      },
      tooltip: {
          enabled: false,
      },
      xaxis: {
          categories: [
              "Lowest Quartile",
              "Middle Quartile",
              "Middle Quartile",
              "Highest Quartile"
          ],
          fill: {
              opacity: 0.7
          },
          title: {
              text: "Quartiles of Culture Scores",
              style: {
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#7E7E7E",
              },
          },
      },
      yaxis: {
          tickAmount: 10,
          decimalsInFloat: 0,
          title: {
              text: "Relative Outcomes",
              style: {
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#7E7E7E",
              },
          },
      },
      legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
      },
  },
};


  
const data3 = {
	series: [
		{
			data: [22,18,17,10],
		},
	],
	options: {
		chart: {
			type: "bar",
			fontFamily:"Poppins, sans-serif",
			foreColor: '#7E7E7E',
			toolbar: {
				show: false,
			},
			width: 800
		},
		legend: {
			position: "top",
			horizontalAlign: "left",
            show: false
		},
        plotOptions: {
            bar: {
              horizontal: false,
              distributed: true,
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              type: "vertical",
              shadeIntensity: 0.5,
              gradientToColors: ["#27CDA7", "#2D70E2"],
              inverseColors: true,
              opacityFrom: 0.5,
              opacityTo: 0.5,
              stops: [0, 100],
              colorStops: [
                {
                  offset: 0,
                  color: "#27CDA7",
                  opacity: 0.5,
                },
                {
                  offset: 100,
                  color: "#2D70E2",
                  opacity: 0.5,
                }
              ],
            },
          },
		colors: ["#2D70E2"],
		xaxis: {
			categories: [
                "Psychological Safety",
                "Well-being",
                "Empathy",
                "Belonging"
			],
			tickPlacement: "on",
			labels:{
				style:{
					fontSize:'9px',
                    colors: ["#2D70E2", "#2D70E2", "#2D70E2"],
				}
			}
		},
		yaxis: {
			min: 0,
			max: 30,
      forceNiceScale: true,
      tickAmount:8,
			decimalsInFloat: 0,
			title: {
				text: "% prediction of NPS",
				style:{
					fontWeight:400
				}
			},
		},
	},
};
