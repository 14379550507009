import React,{useState} from 'react';
import styled from 'styled-components';
import {Table,Checkbox} from 'semantic-ui-react'

const MemberSelection = ({setStep}) =>{

    return (
        <Container>
            <Title>Select the participating members</Title>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Member</Table.HeaderCell>
                        <Table.HeaderCell>Collect data</Table.HeaderCell>
                        <Table.HeaderCell>Recruit others</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>John Smith</Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                        <Table.Cell><Checkbox/></Table.Cell>
                    </Table.Row>
                    </Table.Body>

            </Table>

            <ButtonContainer>
                <Button onClick={()=>setStep(3)}>Review</Button>
            </ButtonContainer>
        </Container>

    )
}

export default MemberSelection;

const Container = styled.div`
    font-family:'Raleway';
`

const Title = styled.div`
    font-size:18px;
    font-weight:bold;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:30px;
`

const Button = styled.div`
    background:blue;
    color:white;
    padding:10px 20px;
    border-radius:5px;
    font-size:14px;
    font-weight:600;
    cursor:pointer;
    margin-left:20px;
`