import {
  GET_DEBRIEF_RESPONSES,
  DELETE_DEBRIEF_RESPONSE,
  FETCH_DEBRIEF_RESPONSE,
  EDIT_DEBRIEF_RESPONSE,
  ADD_DEBRIEF_RESPONSE,
  CLEAR_DEBRIEF_RESPONSE,
  CLEAR_ALL,

} from "constants/actions";

const initialState = {
  debrief_response: [],
  pending: false,
  response: null,
  created: [],
};

export default function DebriefResponse(state = initialState, action) {
  switch (action.type) {

    case GET_DEBRIEF_RESPONSES:
      return {
        ...state,
        debrief_response: action.payload,
        pending: false,
      };

    case FETCH_DEBRIEF_RESPONSE:
      return {
        ...state,
        [action.payload.id]: action.payload,
        pending: false
      };

    case EDIT_DEBRIEF_RESPONSE:
      // if (state.debrief_response.filter((an) => an.id === action.payload.id)[0]) {
      //   state = {
      //     ...state,
      //     debrief_response: state.debrief_response.filter(
      //       (rw) => rw.id !== action.payload.id
      //     ),
      //   };
      // }
      return {
        ...state,
        debrief_response: [...state.debrief_response, action.payload],
      };

    case DELETE_DEBRIEF_RESPONSE:

      return {
        ...state,
        debrief_response: state.debrief_response.filter(
          (row) => row.id !== action.payload.id
        ),
      };

    case ADD_DEBRIEF_RESPONSE:
      return {
        ...state,
        created: action.payload,
        debrief_response: [...state.debrief_response, action.payload],
        response: action.payload.response,
      };

    case CLEAR_DEBRIEF_RESPONSE:
      return {
        ...state,
        debrief_response: [],
      };

    case CLEAR_ALL:
      return {
        ...state,
        debrief_response: [],
      };

    default:
      return state;
  }
}
