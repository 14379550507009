import React, { useState } from "react";
import styled from "styled-components";

import { Confirm } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { SG_ADD_USER_TO_SURVEY } from "constants/actions";

import StatusBox from "./StatusBox";

const SURVEY_STATUS = [
  { value: 1, status: "Enabled" }, // would received the survey
  { value: 9, status: "Default settings" }, //not receive the survey
  { value: 2, status: "Not active" }, //would not receive the survey
];

const StatusCard = ({ data, status, debriefSchedule, nonHistory }) => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const { addToast } = useToasts();

  function AddEmpToSurvey() {
    if (debriefSchedule?.id) {
      dispatch({
        type: SG_ADD_USER_TO_SURVEY,
        payload: {
          debrief_schedule: debriefSchedule?.id,
          employee: data?.employee_id,
        },
      });

      addToast("Survey access successfully added", { appearance: "success" });
    }
    setShowConfirm(false);
  }

  if (!data) {
    return null;
  }

  console.log(data)

  return (
    <MemberContainer>
      <RowContainer width={30} mobile={false}>
        <Name> {data?.name ? data?.name : ""}</Name>
      </RowContainer>

      <RowContainer width={40} mobile={false}>
        <Text>{data ? data.email : ""}</Text>
      </RowContainer>
      {nonHistory && (
        <RowContainer width={10} mobile={false}>
          <Text>
            {data?.reminded}
            {/* {timeLeft} */}
          </Text>
        </RowContainer>
      )}
      <RowContainer width={30} mobile={false}>
        {data?.employee_status === 1 ? (
          data?.debrief_response ? (
            <StatusBox type={"completed"} />
          ) : nonHistory ? (
            <Text>
              {
                SURVEY_STATUS.filter(
                  (ss) => ss.value === data?.survey_status
                )?.[0]?.status
              }
            </Text>
          ) : (
            <StatusBox type={"No Response"} />
          )
        ) : (
          <Text>This account has been archived</Text>
        )}
      </RowContainer>
      {nonHistory && (
        <RowContainer width={20} mobile={false}>
          {/* to do show only if status is live status === 2 */}
          {status === 3
            ? //  data?.survey_status === 1 eligible for survey
              !data?.debrief_response &&
              data?.survey_status === 1 &&
              (data?.employee_status === 1 ? (
                <>
                  <StyledButton
                    color="#800080"
                    size="mini"
                    onClick={() => setShowConfirm(true)}
                  >
                    Add to Survey
                  </StyledButton>
                  <Confirm
                    open={showConfirm}
                    header={`Add ${data?.name} to Survey`}
                    content={`This would send a link to ${data?.email} within next 5 mins`}
                    onCancel={() => setShowConfirm(false)}
                    onConfirm={AddEmpToSurvey}
                  />
                </>
              ) : (
                ""
              ))
            : "Not Live"}
        </RowContainer>
      )}
    </MemberContainer>
  );
};

/******** Styles ****************************************************/

const MemberContainer = styled.div`
  width: 100%;
  padding: 6px 10px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
`;

const Name = styled.div`
  font-size: 12px;
  color:#2A3039;
  font-weight:600;
`;


const Text = styled.div`
  font-size: 12px;
`;

const WaitText = styled.div`
  font-size: 12px;
  width: 75px;
  color: red;
  font-family: "Red Hat Display", sans-serif;
`;

const RowContainer = styled.div`
  width: ${(props) => props.width + "%"};
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 0 0 0px;

  @media (max-width: 768px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
  }
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-family: "Barlow Semi Condensed", sans-serif;
  padding: 0px 20px;
  height: 30px;
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 20px;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  border: none;
  border: 1px solid white;
  outline: none;

  &:hover {
    background-color: white;
    color: ${(props) => props.color};
    border: ${(props) => "1px solid " + props.color};
  }
`;

export default StatusCard;
