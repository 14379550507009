// Libraries
import React, { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Loader } from "semantic-ui-react";
import * as html2Image from "html-to-image";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import { Canvg } from "canvg";

// Hooks
import useGetSurveyData from "hooks/useGetSurveyData";
import useGetSurveyUtils from "hooks/useGetSurveyUtils";
import useGetUserSettings from "hooks/useGetUserSettings";

// Components
import DownloadButton from "./components/DownloadButton";
import Overview from "reports/Audit/Overview";
import ButtonErrorWrapper from "error/components/ButtonErrorWrapper";
import OverviewOptions from "./components/OverviewOptions";

// Constants
import { SET_DATA_DATES } from "constants/actions";
import { SURVEY_INFO } from "constants/surveys";

// Calculations
import {
  calculate_table_data,
  calculate_data,
  filter_data,
  get_most_recent,
  sort_historical_data,
  get_dates,
  calculate_otp_table_data,
  filter_otp_data,
  // change_data,
} from "../../reports/Audit/data_navigation/calculate_data";

// Dummy Data
import { Data_GeneratorV2 } from "../../reports/Audit/Testing/generate_data";
import { useSearchParams } from "react-router-dom";
import PDFInstance from "./components/PDFInstance";
import dumData from "./assets/dummyData.json";
import dumCat from "./assets/dummyCategories.json";
import { Search } from "reports/Audit/Insights/difference_search";
// import dumSample from "./assets/sampleData.json";

const PDF = (props) => {
  /***********  Declarations  *************/
  const [URI, setURI] = useState(null);
  const [URI_OTP, setURIOtp] = useState(null);
  const [URIReady, setURIReady] = useState(false);
  const exportRef = useRef();
  const exportRefTwo = useRef();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [calculated, setCalculated] = useState();
  const [isCG, setIsCG] = useState();
  const [previous, setPrevious] = useState();
  const [filteredData, setFilteredData] = useState();
  const [sportName, setSportName] = useState();
  const [spiderOptions, setSpiderOptions] = useState();
  const [spiderTwoOptions, setSpiderTwoOptions] = useState();
  const [dummyData, setDummyData] = useState();
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [showControl, setShowControl] = useState(true);
  const { addToast, removeAllToasts } = useToasts();
  const [summarizedData, setSummarizedData] = useState();
  const anchor = useSelector((state) => state.audit?.anchor, shallowEqual);
  const [isOTP, setIsOTP] = useState(props?.isOTP || false);
  const [reportData, setReportData] = useState();

  // TODO: Update rule to default to 2
  const rule = 2;
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  let testing = searchParams.get("testing");
  const { data } = props.config?.[0];
  const { filteredCategories, isAdmin } = useGetUserSettings();

  /***********  Custom Hooks  *************/
  const {
    WhiteLabel,
    get_survey_structure,
    get_culture_audit_reports,
    nav_state: hook_nav,
    get_survey_questions,
    get_organizations,
    organizationData,
    selectedReport,
    get_auth,
    get_employee,
  } = useGetSurveyData({
    surveyType: SURVEY_INFO.audit.question_type,
    auditTypeId: SURVEY_INFO.audit.type,
    reportId: props.options?.reportId || null,
    dateStart: props.options?.startDate || null,
    dateEnd: props.options?.endDate || null,
    sports: props.options?.sports || null,
    filteredCategories,
    dontRefetch: props.options?.dontRefetch || props?.dontRefetch,
    isAdmin,
    auditReports: props.auditReports,
    survey_version: props.survey_version,
  });

  const nav_state = props?.nav_state || hook_nav;

  const orgRule = !!WhiteLabel?.rule ? Number(WhiteLabel?.rule) : rule;
  // const orgRule = 0;

  const { reportData: fetchedData } = useGetSurveyUtils({
    get_culture_audit_reports: props.auditReports || get_culture_audit_reports,
    get_survey_structure,
    get_organizations,
    get_survey_questions,
    reportId: props.options?.reportId,
    nav_state: nav_state,
    get_auth,
    dateStart: props.options?.startDate || null,
    dateEnd: props.options?.endDate || null,
    sportsFiltered: (props.selectedSport && props.options?.sports) || null,
    sports: props.options?.sports || null,
    get_employee,
    filteredCategories,
    isAdmin,
    survey_version: props.survey_version,
    sortOrder: props.sortOrder,
  });

  useEffect(() => {
    if (props?.reportData) {
      setReportData(props.reportData);
    }

    if (!props?.reportData && fetchedData) {
      setReportData(fetchedData);
    }
  }, [props?.reportData, fetchedData]);

  useEffect(() => {
    if (props?.isOTP) {
      setIsOTP(props.isOTP);
    }
  }, [props?.isOTP]);

  useEffect(() => {
    if (organizationData) {
      let otpType = false;
      otpType = organizationData?.services_enabled?.find(
        (f) => f.name === "OTP"
      )?.enabled
        ? true
        : false;

      setIsOTP(otpType);
    }
  }, [organizationData]);

  useEffect(() => {
    if (props?.calculated) {
      setCalculated(props.calculated);
    }
  }, [props?.calculated, calculated]);

  useEffect(() => {
    if (reportData && !sportName) {
      const category = reportData[0]?.responses?.[0]?.categories?.find(
        (cat) => {
          return cat.priority === "primary";
        }
      );

      let sportId;
      let categoryId;
      if (category) {
        if (category?.subfactor) {
          sportId = category?.subfactor;
          categoryId = category?.id;
        } else {
          sportId = category?.response;
          categoryId = category?.id;
        }
      }

      if (sportId && !props.sportName && props?.structure) {
        const sportName = getSportName(sportId, props?.structure, categoryId);
        setSportName(sportName);
      }

      if (props.sportName) {
        setSportName(props.sportName);
      }
    }
  }, [props?.structure, reportData]);

  const getSportName = (sportId, categories, categoryId) => {
    const sportCats = categories.categories.find(
      (c) => c.priority === "primary"
    );
    let sportName = sportCats.options.find((o) => o.id === sportId)?.name;
    if (!sportName) {
      const sportCat = sportCats?.options?.find((o) =>
        o?.subfactors?.find((s) => s.id === sportId)
      );
      sportName = sportCat?.subfactors?.find((s) => s.id === sportId)?.name;
    }

    if (!sportName) {
      const sportCat = sportCats?.options?.find((o) =>
        o?.subfactors?.find((s) => s.id === categoryId)
      );
      sportName = sportCat?.subfactors?.find((s) => s.id === categoryId)?.name;
    }
    return sportName;
  };

  /***********  useEffect Hooks  *************/
  useEffect(() => {
    if (!dummyData && reportData && nav_state) {
      if (Object.keys(nav_state).length > 0 && !props?.otpFormat) {
        setFilteredData(filter_data(reportData, nav_state, props?.structure));
      } else {
        setFilteredData(filter_otp_data(reportData, props.selectedSport));
      }
    } else {
      if (dummyData) {
        if (Object.keys(nav_state).length > 0) {
          setFilteredData(filter_data(reportData, nav_state, props?.structure));
        }
      }
    }
  }, [reportData, nav_state, dummyData]);

  useEffect(() => {
    if (dummyData && anchor) {
      const dummyCategories = props?.structure;
      const showAltData = dumCat.find((surveyCat) => surveyCat.id === anchor);

      if (showAltData) {
        dummyCategories.categories = dumCat;
      }

      setCalculated(
        calculate_table_data(
          dummyData,
          nav_state,
          dummyCategories,
          props?.anchor || anchor,
          orgRule,
          props.survey_version?.questions
        )
      );
      setPageLoading(false);
    }
  }, [dummyData, nav_state]);

  useEffect(() => {
    if (props.overview) {
      setCalculated(null);
      setShowControl(true);
      setURI(null);
    }
  }, [anchor, props.options]);

  useEffect(() => {
    if (!spiderOptions && calculated?.responses.length > 0) {
      setSpiderOptions(
        getOptions(
          0,
          calculated,
          _humanFactorsOptions,
          props.survey_version?.questions
        ).filter((item) => item !== null)
      );
    }

    if (
      spiderOptions &&
      calculated?.responses.length > 0 &&
      !spiderTwoOptions
    ) {
      setSpiderTwoOptions(
        getOptions(
          1,
          calculated,
          _systemFactorsOptions,
          props.survey_version?.questions
        )?.filter((item) => item !== null)
      );
    }
  }, [spiderOptions, calculated, spiderTwoOptions]);

  useEffect(() => {
    if (props.survey_version?.questions && calculated?.responses.length > 0) {
      setPageLoading(false);
    }
  }, [pageLoading, calculated, nav_state]);

  useEffect(() => {
    setSpiderOptions(null);
    setSpiderTwoOptions(null);
  }, [props.survey_version?.questions]);

  useEffect(() => {
    setURI(null);
    setPageLoading(true);
    setCalculated(null);
  }, [anchor, props.activePage]);

  useEffect(() => {
    if (
      !dummyData &&
      reportData &&
      reportData.length > 0 &&
      nav_state &&
      props?.structure &&
      props.survey_version?.questions &&
      !props?.calculated
    ) {
      if (!props.otpFormat) {
        const calcData = calculate_table_data(
          reportData,
          nav_state,
          props?.structure,
          props?.anchor || anchor,
          orgRule,
          props.survey_version?.questions
        );
        setCalculated(calcData);
      } else {
        if (props.selectedSport || props.otpVersion) {
          const calcData = calculate_otp_table_data(
            reportData,
            props?.structure,
            props?.anchor || anchor,
            orgRule,
            props.survey_version?.questions,
            props.selectedSport,
            props.otpVersion
          );
          setCalculated(calcData);
        }
      }
    }

    if (
      !calculated?.responses ||
      (reportData?.length === 0 && props.overview)
    ) {
      setPageLoading(false);
    }
  }, [props.showFeedback, props.survey_version, reportData]);

  useEffect(() => {
    setCalculated(null);
    setURI(null);
    setSpiderOptions(null);
    setSpiderTwoOptions(null);
    setPageLoading(true);
    setURIReady(false);
    setURIOtp(null);
  }, [props.reset]);

  useEffect(() => {
    if (
      !dummyData &&
      reportData &&
      reportData.length > 0 &&
      nav_state &&
      props?.structure &&
      props.survey_version?.questions &&
      (anchor || props?.anchor)
    ) {
      if (!props.otpFormat) {
        setCalculated(
          calculate_table_data(
            reportData,
            nav_state,
            props?.structure,
            props?.anchor || anchor,
            orgRule,
            props.survey_version?.questions
          )
        );
      } else {
        if (props.selectedSport || props.otpVersion) {
          setCalculated(
            calculate_otp_table_data(
              reportData,
              props?.structure,
              props?.anchor || anchor,
              orgRule,
              props.survey_version?.questions,
              props.selectedSport,
              props.otpVersion
            )
          );
        }
      }
    }

    if (
      !calculated?.responses ||
      (reportData?.length === 0 && props.overview)
    ) {
      setPageLoading(false);
    }
  }, [
    reportData,
    nav_state,
    orgRule,
    props?.structure,
    anchor,
    URI,
    props.overview,
    props.options,
    props.selectedSport,
  ]);

  /***********  Routines  *************/
  let comments = [];
  // Get questions from survey
  if (!dummyData && reportData && reportData[0]) {
    reportData[0].responses.forEach((responseGroup) => {
      if (responseGroup.comments?.length > 0) {
        responseGroup.comments.forEach((comment) => {
          comments.push(comment);
        });
      }
    });

    if (comments && comments.length > 0) {
      if (props.survey_version?.questions) {
        props.survey_version.questions.comments = comments;
      }

      // if (!props.survey_version?.questions && questionStructure) {
      //   questionStructure.comments = comments;
      // }
    }
  }

  if (dummyData && dummyData[0]) {
    comments = [];
    dummyData[0].responses.forEach((responseGroup) => {
      if (responseGroup.comments?.length > 0) {
        responseGroup.comments.forEach((comment) => {
          comments.push(comment);
        });
      }
    });

    // if (
    //   comments.length > 0 &&
    //   !props.survey_version?.questions &&
    //   questionStructure
    // ) {
    //   questionStructure.comments = comments;
    // } else {
    // }
    if (comments.length > 0 && props.survey_version?.questions) {
      props.survey_version.questions.comments = comments;
    }
  }

  /***********  Helper Functions  *************/
  const generate_test_data_set = () => {
    setPageLoading(true);
    setURI(null);
    setSpiderOptions(null);
    setSpiderTwoOptions(null);
    // change_data(dumSample, 50);
    const org = get_organizations[get_auth.organization_id];
    const survey_type = org?.services_enabled.filter(
      (sc) => sc?.name === SURVEY_INFO.audit.name
    )?.[0]?.question_sort_order;

    const showAltData = dumCat.find((surveyCat) => surveyCat.id === anchor);

    const SurveyQues = get_survey_questions.filter(
      (sq) => sq.sort_order === survey_type
    )?.[0];

    const DataGenerated = Data_GeneratorV2(
      10,
      1,
      props?.structure,
      SurveyQues.questions
    );

    let most_recent = showAltData
      ? get_most_recent(dumData)
      : get_most_recent(DataGenerated);
    let historical_data = sort_historical_data(dumData.reverse(), 0);
    setDummyData([most_recent, ...historical_data]);

    dispatch({
      type: SET_DATA_DATES,
      payload: get_dates(historical_data),
    });
  };

  /**
   *
   * @param {*} el node to be rendered as an image
   * @param {*} height height for rendered image
   * @param {*} width width for rendered image
   * @param {*} exclusionClasses
   */
  const exportAsImage = async (el, otp = false) => {
    setLoading(true);
    // addToast(
    //   "Generating your PDF now, please be patient as this may take a few moments.",
    //   { appearance: "info" }
    // );

    const filter = (el) => {
      const exclusionClasses = ["legend-container", "title-bar"];
      return !exclusionClasses.some((classname) =>
        el.classList?.value?.includes(classname)
      );
    };

    const fontEmbedCss = await html2Image.getFontEmbedCSS(el);
    const imageStyle = isOTP
      ? {
          filter: filter,
          fontEmbedCss,
          style: { display: "block", margin: "1px" },
          cacheBust: true,
          canvasWidth: 1130,
          canvasHeight: 1040,
          height: 1040,
          width: 1130,
        }
      : {
          filter: filter,
          fontEmbedCss,
          style: { display: "block", margin: "1px" },
          cacheBust: true,
          height: 900,
          width: 899,
        };
    if (otp) {
      const canvas = await html2Image.toCanvas(el, imageStyle);
      setURIOtp(canvas.toDataURL());
    } else {
      const canvas = await html2Image.toCanvas(el, imageStyle);
      setURI(canvas.toDataURL());
    }
  };

  const getAverage = (data) => {
    let total = 0;
    let n = 0;
    data.map((i) => {
      total += i;
      n += 1;
    });

    return total / n;
  };

  useEffect(() => {
    if (!isOTP) {
      setSportName(props.selectedSport);
    }
  }, [props.selectedSport]);

  // If template is OTP and OTP view, we need a second chart
  // This will abstract a new ready-state using 1 or both charts (depending on which is needed)
  useEffect(() => {
    if (props.otpVersion) {
      if (URI && URI_OTP) {
        setURIReady(true);
      }
    } else {
      if (URI) {
        setURIReady(true);
      }
    }
  }, [URI, props.otpVersion, URI_OTP]);

  const getOptions = (
    dimension = 0,
    calculatedData,
    baseOptions,
    structure
  ) => {
    let averages = calculatedData.summary?.filter(
      (factorSummary) => factorSummary.dimension === dimension.toString()
    );

    let factors = structure.dimensions[dimension]?.factors?.map((factor) => {
      return factor.title;
    });

    let __options = baseOptions;
    __options.xaxis.categories = factors;

    // TODO: When additional data is needed, update this map to return
    // other properties, such as Norm & Change from prev
    let factorOverview = factors?.map((item, i) => {
      if (i + 1 > averages.length) {
        return null;
      }
      const factorAverage = averages
        .filter((avg) => Number(avg.factor) === i)
        .map((avgItem) => avgItem.average);

      return {
        factor: item,
        average: getAverage(factorAverage),
        id: i,
      };
    });

    return factorOverview;
  };

  const canComponentRender = (testing = false, data) => {
    return (
      props?.survey_version?.questions &&
      props?.structure &&
      !pageLoading &&
      nav_state &&
      WhiteLabel &&
      data &&
      calculated?.responses.length > 0
    );
  };

  /***********  Render Functions  *************/
  const pdfInstance = useMemo(
    () =>
      !pageLoading &&
      calculated?.responses.length > 0 &&
      URIReady &&
      canComponentRender(testing, testing ? dummyData : reportData) &&
      spiderOptions &&
      spiderTwoOptions && (
        <PDFInstance
          data={data}
          // dialOne={dialOne}
          previous={previous}
          organizationData={organizationData}
          dummyData={dummyData}
          reportData={reportData}
          nav_state={nav_state}
          WhiteLabel={WhiteLabel}
          questionStructure={props?.survey_version?.questions}
          get_survey_structure={props?.structure}
          selectedReport={selectedReport}
          rule={orgRule}
          URI={URI}
          URI_OTP={URI_OTP}
          calculated={calculated}
          anchor={anchor}
          overview={props.overview}
          isOTP={isOTP}
          setPdfGenerating={(isGenerating) => setPdfGenerating(isGenerating)}
          spiderOptions={spiderOptions}
          spiderTwoOptions={spiderTwoOptions}
          filteredData={filteredData}
          summarizedData={summarizedData}
          reset={props.reset}
          {...props}
          otpView={props.otpView}
          sportName={sportName}
        />
      ),
    [
      pageLoading,
      calculated,
      URIReady,
      props?.structure,
      spiderOptions,
      spiderTwoOptions,
    ]
  );

  const loadingComponent = (
    <div
      style={{
        width: pdfGenerating && props.overview ? 0 : 110,
        position: "relative",
        marginLeft:
          pdfGenerating && props.overview
            ? props.downloadIcon
              ? "-198px"
              : "-180px"
            : "-65px",
        top:
          pdfGenerating || props.overview
            ? props.downloadIcon
              ? "5px"
              : "0px"
            : "7px",
        left: !pdfGenerating && props.overview ? "55px" : "0px",
      }}
    >
      <Loader inline active size="tiny" />
    </div>
  );

  if (pageLoading) {
    return loadingComponent;
  }

  const handleExport = async () => {
    await exportAsImage(exportRef.current);
    if (props.otpVersion) {
      await exportAsImage(exportRefTwo.current, true);
    }
  };

  const downloadButton = (
    <OverviewOptions
      btnAction={() => handleExport()}
      btnText={props.buttonLabel || "Generate PDF Report"}
      downloadIcon={props.downloadIcon}
      btnEnabled={props.buttonEnabled && calculated?.responses.length > 0}
      showControl={showControl}
      setShowControl={(show) => setShowControl(show)}
      optionsKey="sport"
      structure={props?.structure}
      otpReport={props.reportButton}
      setPdfGenerating={(isGenerating) => setPdfGenerating(isGenerating)}
      isFrench={props.isFrench}
      triggeredGenerate={props?.triggeredGenerate}
      pdfKey={props.pdfKey}
    />
  );

  // <DownloadButton
  //   btnAction={() => exportAsImage(exportRef.current)}
  //   loading={isCG ? loading && !previous : loading}
  //   showControl={calculated?.responses?.length > 0}
  //   setPdfGenerating={setPdfGenerating(props?.triggeredGenerate)}
  // />

  let otpChart;

  if (props.otpVersion) {
    otpChart = canComponentRender(
      testing,
      testing ? dummyData : reportData
    ) && (
      <div ref={exportRefTwo} key="overview2">
        <Overview
          data={dummyData || reportData}
          questions={props?.survey_version?.questions}
          structure={props?.structure}
          nav={nav_state}
          rule={orgRule}
          pdf={true}
          otpPdf={isOTP}
          otpView={props.otpView}
          WhiteLabel={WhiteLabel}
          selected={1}
          overview={props.overview}
          selectedSport={props.selectedSport}
          language={props.isFrench ? "fr" : "en"}
        />
      </div>
    );
  }

  const overviewChart = canComponentRender(
    testing,
    testing ? dummyData : reportData
  ) && (
    <div ref={exportRef}>
      <Overview
        data={dummyData || reportData}
        questions={props?.survey_version?.questions}
        structure={props?.structure}
        nav={nav_state}
        rule={orgRule}
        pdf={!isOTP}
        otpPdf={isOTP}
        WhiteLabel={WhiteLabel}
        selected={1}
        pdfAnchor={props.anchor}
        overview={props.overview}
        selectedSport={props.selectedSport}
        language={props.isFrench ? "fr" : "en"}
      />
    </div>
  );

  const testingControl = testing ? (
    <Test
      overview={props.overview}
      onClick={() => generate_test_data_set()}
      id={"test"}
    >
      Generate Data
    </Test>
  ) : null;

  return (
    <Container>
      {testingControl}
      <ButtonErrorWrapper
        retryFunction={() => exportAsImage(exportRef.current)}
      >
        {downloadButton}
        {pdfInstance}
        {pdfGenerating && loadingComponent}
        {/* Wrapper for charts */}
        <div
          style={{
            overflow: "hidden",
            position: "absolute",
            height: "0%",
            left: 0,
            top: 0,
            zIndex: -999,
          }}
        >
          {/* Certain Vertical bars won't render within react-pdf, we wrap these components in exportRef and use exportAsImage() */}
          {overviewChart}
        </div>
        {props.otpVersion && (
          <div
            style={{
              overflow: "hidden",
              position: "absolute",
              height: "0%",
              left: 0,
              top: 0,
              zIndex: -999,
            }}
          >
            {/* Certain Vertical bars won't render within react-pdf, we wrap these components in exportRef and use exportAsImage() */}
            {otpChart}
          </div>
        )}
      </ButtonErrorWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Test = styled.button`
  width: 80px;
  height: 35px;
  position: absolute;
  margin-top: ${(props) => (props.overview ? "-50px" : "-20px")};
  margin-left: ${(props) => (props.overview ? "15px" : "0px")};
`;

PDF.propTypes = {
  type: PropTypes.oneOf(["culture", "edi"]).isRequired,
  sport: PropTypes.string,
  downloadLinkText: PropTypes.string,
  overview: PropTypes.bool,
  companyLogo: PropTypes.string,
  introTemplate: PropTypes.number,
  coverImage: PropTypes.string,
  coverHeading: PropTypes.string.isRequired,
  coverSubtitle: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  buttonLabel: PropTypes.string,
};

// TODO: Right now, default props represents the only config we have ready, things like the company logo, and heading text will be configurable,
// TODO: but we will need to create different templates to handle the `introTemplate` and `type` properties.
PDF.defaultProps = {
  type: "culture",
  coverHeading: "Culture Index",
  coverSubtitle: "PDF summary report",
};

export default PDF;

const _humanFactorsOptions = {
  series: [
    {
      name: "Series 2",
      data: [20, 100, 40, 30, 50, 60],
    },
  ],
  chart: {
    width: 500,
    type: "radar",
    id: "chart",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  plotOptions: {
    radar: {
      size: 300,
      polygons: {
        strokeColors: "#e9e9e9",
        fill: {
          colors: ["#f8f8f8", "#fff"],
        },
      },
    },
  },
  colors: ["#2d50e2"],
  markers: {
    size: 4,
    colors: ["#fff"],
    strokeColor: "#2d50e2",
    strokeWidth: 2,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "20px",
        fontWeight: 400,
        fontFamily: "Barlow Semi Condensed, sans-serif",
        colors: ["black", "black", "black", "black", "black", "black"],
      },
    },
  },
  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 10,
    labels: {
      formatter: function (val, i) {
        if (i % 2 === 0) {
          return val;
        } else {
          return "";
        }
      },
    },
  },
};

const _systemFactorsOptions = {
  series: [
    {
      name: "Series 1",
      data: [20, 100, 40, 30, 50, 60],
    },
  ],
  chart: {
    width: 500,
    type: "radar",
    id: "chart2",
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  plotOptions: {
    radar: {
      size: 300,
      polygons: {
        strokeColors: "#e9e9e9",
        fill: {
          colors: ["#f8f8f8", "#fff"],
        },
      },
    },
  },
  colors: ["#FF8D66"],
  markers: {
    size: 4,
    colors: ["#fff"],
    strokeColor: "#FF8D66",
    strokeWidth: 2,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
  xaxis: {
    labels: {
      style: {
        fontSize: "20px",
        fontWeight: 400,
        fontFamily: "Barlow Semi Condensed, sans-serif",
        colors: ["black", "black", "black", "black", "black", "black"],
      },
    },
  },
  yaxis: {
    tickAmount: 5,
    min: 0,
    max: 10,
    labels: {
      formatter: function (val, i) {
        if (i % 2 === 0) {
          return val;
        } else {
          return "";
        }
      },
    },
  },
};
