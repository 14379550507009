
import styled, { keyframes } from "styled-components";
import React, {useState,useEffect} from "react";
import { Icon,Accordion,Pagination } from 'semantic-ui-react'
import data from "data/tools.json";
import AccountSetup from './AccountSetup'
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import EmployeeAvatar from "components/EmpAvatar";

import {
  SG_GET_MOODS,
  SG_GET_CULTURE_DATA,
  LOAD_EMPLOYEES,
  LOAD_TEAMS,
} from "constants/actions";




const Team = () =>{

	const dispatch = useDispatch();
  const [employee, setEmployee] = useState(false);
  const [teamList, setTeamList] = useState(false);
  const [activeIndex,setActive] = useState(-1)
  const [activePage,setPage] = useState(1)

   useEffect(() => {
    // Run! Like go get some data from an API.
    dispatch({ type: LOAD_EMPLOYEES });
    dispatch({ type: LOAD_TEAMS });
  }, [dispatch]);

  const employee_id = useSelector((state) => state.auth.employee_id);

  const { get_employee, get_auth, organization_id, get_organization } = useSelector(
    (state) => ({
      get_employee: state.employees,
      get_auth: state.auth,
      organization_id: state.auth.organization_id,
      get_organization: state.organizations,
    }),
    shallowEqual
  );

  React.useEffect(() => {
    if (get_employee) {
      setEmployee(get_employee[get_auth?.employee_id]);
    }
  }, [get_employee, get_auth]);

  React.useEffect(() => {
    if (employee?.team) {
      setTeamList(get_employee.employees.filter(em => em.team === employee.team));
    }
  }, [get_employee, employee]);

    const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActive(newIndex)
  }

  const handlePaginationChange = (e, { activePage }) => setPage(activePage)
// console.log(get_employee)
	return (
		<Container>
    <Mobile>
        <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Title><Icon name='dropdown' />
          Your Team
          </Title>
        </Accordion.Title>

        <Accordion.Content active={activeIndex === 0}>
          {teamList? (
            <div>
            {teamList.map((item, i) => {
                return (
                          <EmployeeAvatar
                            employee_id={item.id}
                            // showFace
                            // checked={item.completed}
                            // nonMemberHide={false}
                            // showEmotionalReport
                            overRide={true}
                            nameRight
                            showName
                            picSize={'small'}
                            key={i}
                          /> )
              })

          }
          </div>
            ):""}
         
        </Accordion.Content>
       </Accordion>
    </Mobile>

    <Desktop>
		<Title>Your Team</Title>
			{teamList? (
				<TeamContainer>
        <div style={{height:550}}>
				{teamList.slice((activePage-1)*10,activePage*10).map((item, i) => {
						return (
                    <div style={{marginBottom:5}} key={i}>
                      <EmployeeAvatar
                        employee_id={item.id}
                        // checked={item.completed}
                        showFace
                        nonMemberHide={false}
                        showEmotionalReport
                        overRide={true}
                        nameRight
                        picSize={'small'}
                      /></div> )
					})

			}
      </div>
      
      <div style={{width:'100%',display:'flex',justifyContent:'flex-end',paddingRight:10}}>
      {teamList.length>10?<Pagination
        boundaryRange={0}
        activePage={activePage}
        onPageChange={handlePaginationChange}
        defaultActivePage={1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
        size='mini'
        totalPages={Math.ceil(teamList.length/10)}
      />:""}</div>
			</TeamContainer>
				):""}
      </Desktop>
		</Container>
		)

}

export default Team

const Container = styled.div`
	width:250px;
	min-width:250px;

  @media(max-width:768px){
   width:100%;
  }
`

const Title = styled.div`
	font-size:14px;
	color:#545454;
	margin-bottom:20px;

  @media(max-width:768px){
    font-size:18px;
  }
`


const Mobile = styled.div`
  @media(min-width:768px){
    display:none;
  }
`

const Desktop = styled.div`
  @media(max-width:768px){
    display:none;
  }
`

const TeamContainer = styled.div`
  m
`