import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { Dimmer, Loader, Icon, Modal } from "semantic-ui-react";
import Select from "react-select";

import {
  LockIcon,
  SaveIcon,
  XIcon,
  CategoryIcon,
  TrashIcon,
} from "members/audit/Icons";
import {
  SG_GET_EMPLOYEE_PERMISSIONS,
  SG_EDIT_EMPLOYEE_PERMISSION,
  SG_ADD_EMPLOYEE_PERMISSION,
  SG_GET_PROD_ORG_CONTRACT,
} from "constants/actions";
import { useParams } from "react-router";
import ClickAwayListener from "react-click-away-listener";

const Dropdown = ({ options, selected, setSelected, label }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (!selected) {
      setSelected(options?.[0]);
    }
  }, [options]);

  const selectOption = (option) => {
    setOpen(false);
    setSelected(option);
  };

  return (
    <Box>
      <Label>{label}</Label>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <DropdownContainer
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Nav onClick={() => setOpen(!open)}>
            <Selection>{selected?.name || "Select access category"}</Selection>
            <Icon
              name="triangle down"
              style={{ marginTop: -4, color: "#B3B3B3" }}
            />
          </Nav>
          {open && (
            <DropdownContent>
              {options.map((option, i) => (
                <Option key={i} onClick={() => selectOption(option)}>
                  {option.name} - {option.text}
                </Option>
              ))}
            </DropdownContent>
          )}
        </DropdownContainer>
      </ClickAwayListener>
    </Box>
  );
};

const Label = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-right: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 20px;
`;

const DropdownContainer = styled.div`
  position: relative;
  border: 1px solid lightgrey;
  padding: 5px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Selection = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-right: 5px;
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  width: 250px;
  max-height: 150px; /* Set a fixed height for the dropdown content */
  overflow-y: auto; /* Enable vertical scrolling */
`;

const Option = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: #2a3039;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const CategoryPermission = ({ employeeCategory, currentUser }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [currentPermission, setCurrentPermission] = useState([]);
  const [currentEmpCategory, setCurrentEmpCategory] = useState([]);
  const [allSurveyOptions, setAllSurveyOptions] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const { id } = useParams();

  const { get_survey_structure, get_employee_permission, core_data } =
    useSelector(
      (state) => ({
        get_survey_structure: state.surveystructure.survey_structure,
        get_employee_permission: state.employee_permission,
        core_data: state.audit?.core_data,
      }),
      shallowEqual
    );

  useEffect(() => {
    dispatch({
      type: SG_GET_PROD_ORG_CONTRACT,
    });
    if (id) {
      dispatch({
        type: SG_GET_EMPLOYEE_PERMISSIONS,
        payload: `employee=${id}`,
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loading === true) {
        setLoading(false);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [loading]);

  useEffect(() => {
    setCurrentEmpCategory(
      employeeCategory?.category.filter((ec) => ec.priority === "primary")
    );
  }, [employeeCategory]);

  useEffect(() => {
    if (get_employee_permission) {
      setLoading(get_employee_permission?.pending);
    }
    if (get_employee_permission?.updated) {
      setCurrentPermission(get_employee_permission?.updated);
      return;
    }
    if (get_employee_permission?.created) {
      setCurrentPermission(get_employee_permission?.created);
      return;
    }
    setCurrentPermission(get_employee_permission?.employee_permission?.[0]);
  }, [get_employee_permission]);

  useEffect(() => {
    const _optionList = [];
    if (currentUser?.account_type === 5) {
      if (core_data?.categories?.[0]?.depth === 1) {
        core_data?.categories?.[0]?.categories
          .filter((ca) => ca.visibility === "public")
          .map((ss) => {
            ss?.options.map((op) =>
              _optionList.push({
                name_id: ss?.id,
                name: ss?.name,
                value_id: op?.id,
                value: `${ss.name} - ${op.name}`,
                text: op.name,
              })
            );
            return null;
          });
      }
      // subfactors where depth === 2
      if (core_data?.categories?.[0]?.depth === 2) {
        core_data?.categories?.[0]?.categories
          .filter((ca) => ca.visibility === "public")
          .subfactors?.map((ss) => {
            ss?.options.map((op) =>
              _optionList.push({
                name_id: ss?.id,
                name: ss?.name,
                value_id: op?.id,
                value: `${ss.name} - ${op.name}`,
                text: op.name,
              })
            );
            return null;
          });
      }
    } else {
      // non admin user
      const _validList = currentUser?.employee_employee_category?.category.map(
        (cc) => cc.value_id
      );
      if (core_data?.categories?.[0]?.depth === 1) {
        core_data?.categories?.[0]?.categories
          .filter((ca) => ca.visibility === "public")
          .map((ss) => {
            ss?.options.map((op) => {
              if (_validList.includes(op?.id)) {
                _optionList.push({
                  name_id: ss?.id,
                  name: ss?.name,
                  value_id: op?.id,
                  value: `${ss.name} - ${op.name}`,
                  text: op.name,
                });
              }
              return null;
            });
            return null;
          });
      }
      // subfactors where depth === 2
      if (core_data?.categories?.[0]?.depth === 2) {
        core_data?.categories?.[0]?.categories
          .filter((ca) => ca.visibility === "public")
          .subfactors?.map((ss) => {
            ss?.options.map((op) => {
              if (_validList.includes(op?.id)) {
                _optionList.push({
                  name_id: ss?.id,
                  name: ss?.name,
                  value_id: op?.id,
                  value: `${ss.name} - ${op.name}`,
                  text: op.name,
                });
              }
              return null;
            });
            return null;
          });
      }
    }

    setAllSurveyOptions(_optionList);
  }, [core_data?.categories, currentUser]);

  function handlePermissionChange(e) {
    setSelectedPermission(e);
  }

  function removePermission(rec) {
    const _curList = currentPermission?.details.filter(
      (cp) => cp?.value_id !== rec?.value_id
    );

    dispatch({
      type: SG_EDIT_EMPLOYEE_PERMISSION,
      payload: {
        body: {
          id: currentPermission?.id,
          details: _curList,
        },
        query: `organization=${currentUser?.organization}`,
      },
    });
  }

  function handleSavePermission() {
    if (currentPermission) {
      if (currentPermission?.id) {
        const _curList = currentPermission?.details;
        _curList.push(selectedPermission);

        dispatch({
          type: SG_EDIT_EMPLOYEE_PERMISSION,
          payload: {
            body: {
              id: currentPermission?.id,
              details: _curList,
            },
            query: `organization=${currentUser?.organization}`,
          },
        });
      }
    } else {
      dispatch({
        type: SG_ADD_EMPLOYEE_PERMISSION,
        payload: {
          body: {
            employee: id,
            details: [selectedPermission],
          },
          query: `organization=${currentUser?.organization}`,
        },
      });
    }
  }

  return (
    <Dimmer.Dimmable blurring dimmed={loading}>
      <EditContainer>
        {currentEmpCategory && (
          <>
            <CategoryRowInfo>
              <ControlWrapper>
                <div style={{ width: "100%", marginTop: "25px" }}>
                  <Dropdown
                    setSelected={(e) => handlePermissionChange(e)}
                    label="Data Access List"
                    options={allSurveyOptions}
                    selected={selectedPermission}
                  />
                </div>
                <TableContent>
                  <SaveButton onClick={handleSavePermission}>
                    <div>Add data access</div>
                    <SaveIconWrapper>{SaveIcon()}</SaveIconWrapper>
                  </SaveButton>
                </TableContent>
              </ControlWrapper>
            </CategoryRowInfo>
          </>
        )}
        {/* CATEGORY SELECTION SECTION */}
        <Header>
          <TH width={80}>Data Access</TH>
          <TH width={20}>Action</TH>
        </Header>

        <React.Fragment>
          {currentEmpCategory &&
            currentPermission?.details &&
            currentPermission?.details.map((rec, ii) => {
              return (
                <Row key={ii}>
                  <TD width={80}>{rec?.value}</TD>
                  <TD width={20}>
                    <Icon
                      onClick={() => removePermission(rec)}
                      link
                      name="trash"
                    />
                  </TD>
                </Row>
              );
            })}
        </React.Fragment>
      </EditContainer>
      <Dimmer active={loading}>
        <Loader />
        <Header as="h2" icon inverted>
          Loading, Please wait.
        </Header>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default CategoryPermission;

const TableContent = styled.div`
  width: 75%;
  padding-left: 10px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  // margin-right: 150px;
  color: #7e7e7e;
`;

const CategoryTag = styled.div`
  width: 250px;
  text-align: inherit;
  align-self: center;
  font-family: "Poppins";
  font-style: normal;
  flex-direction: row;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #7e7e7e;
`;

const SelectionContainer = styled.div`
  padding-left: 25px;
  width: 300px;
`;

const SaveIconWrapper = styled.div`
  padding-right: 8px;
  margin-top: 3px;
  cursor: pointer;
`;

const SaveText = styled.label`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  /* identical to box height */
  cursor: pointer;

  color: #ffffff;
`;

const SaveButton = styled.div`
  justify-content: space-between;
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
  width: 160px;
  right: 20px;
  bottom: 20px;
`;

const ControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CategoryRowInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  width: 566px;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const EditContainer = styled.div`
  width: 100%;
  margin-bottom: 50px;
  font-family: Raleway;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  font-family: "Raleway";
  margin-bottom: 0px;
  border-bottom: 1px solid #dfdfdf;
`;

const TH = styled.div`
  width: ${(props) => props.width}%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;

  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "salt" on, "ss02" on, "ss01" on, "ss09" on, "liga" off, "kern" off;

  color: #666d79;
`;

const Row = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.rowIsHovered ? "#F8FAFF" : "white")};
  border-radius: 5px;
`;

const TD = styled.div`
  width: ${(props) => props.width}%;
  font-size: 12px;
  color: #2a3039;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  padding-left: 10px;
`;
