import styled from "styled-components";
import React from "react";
import { Icon } from "semantic-ui-react";
// import Back from "./Back";
import TopProgress from "./TopProgress";
import NavSample from "./NavSample";

const Sidebar = ({
  open,
  setOpen,
  setStage,
  stage,
  introStage,
  introComplete,
  actionsComplete,
  setIntroComplete,
  setActionsComplete,
}) => {
  const [section, setSection] = React.useState(2);

  const legendArray = [
    {
      step: 0,
      name: "Journey Introduction",
      title: "Journey Introduction",
      screen: "Introduction",
      children: [
        {
          step: 1,
          title: "How data journeys work?",
        },
        {
          step: 2,
          title: "Impact on your culture",
        },
        {
          step: 3,
          title: "Where to focus first?",
        },
        {
          step: 4,
          title: "Are you ready?",
        },
        {
          step: 5,
          title: "Factor feedback",
        },
      ],
    },
    {
      step: 0,
      name: "Journey Path",
      title: "Journey Path",
      screen: "JourneyPath",
      children: [
        {
          step: 1,
          title: "First focus",
        },
        {
          step: 2,
          title: "Focus action",
          progressBlocked: true,
        },
        {
          step: 3,
          title: "Focus context",
          progressBlocked: true,
        },
        {
          step: 4,
          title: "Focus CTA",
          progressBlocked: true,
        },
      ],
    },
    {
      step: 5,
      name: "Next Steps",
      title: "Next Steps",
      progressBlocked: true,
      screen: "NextSteps",
      children: [
        {
          step: 6,
          title: "Check-in frequency",
          progressBlocked: true,
        },
        {
          step: 7,
          title: "Progress Tracker",
          progressBlocked: true,
        },
        {
          step: 8,
          title: "Action Plan",
          progressBlocked: true,
        },
      ],
    },
  ];

  return (
    <Container open={open}>
      <IconContainer onClick={() => setOpen(!open)}>
        <Icon
          name={open ? "chevron left" : "chevron right"}
          style={{ margin: 0, marginTop: -3 }}
        />
      </IconContainer>

      {open && (
        <SideContent>
          <Title onClick={() => setSection(2)}>
            Introduction to data journey
          </Title>
          <TopProgress
            step={stage}
            introStage={introStage}
            steps={legendArray}
            setStep={(s) => setStage(s)}
            open={section == 2}
            introComplete={introComplete}
            actionsComplete={actionsComplete}
            setIntroComplete={setIntroComplete}
            setActionsComplete={setActionsComplete}
          />
          {/* <NavSample /> */}
        </SideContent>
      )}
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  height: calc(100vh - 200px);
  position: fixed;
  left: 0px;
  top: 100px;
  border-radius: ${(props) =>
    props.open ? "0px 15px 15px 0px" : "0px 5px 5px 0px"};
  width: ${(props) => (props.open ? "300px" : "30px")};
  background-color: #ebf1fd;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
`;

const IconContainer = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: bold;
  font-family: "Raleway";
  margin-bottom: 20px;
  color: #2a3039;
  cursor: pointer;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  font-family: "Raleway";
  margin-bottom: 30px;
`;
const SideContent = styled.div`
  width: 300px;
  padding: 30px;
  padding-left: 40px;
  background-color: #ebf1fd;
`;
