import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Message } from 'semantic-ui-react'

import Select from "react-select";

import AddNew from "debriefs/AddNew"
import List from "debriefs/List"
import Details from "debriefs/Details"
import DebriefType from './Types'


import {
	LOAD_TEAMS,
	PULL_ORGANIZATION,
	SG_GET_TEAM_ACCESSS,
	CLEAR_ERRORS,
} from "constants/actions";

const Debriefs = () => {
	const dispatch = useDispatch();
	const [organizationId, setOrganizationId] = useState(false);
	const [teamList, setTeamList] = useState([])
	const [selectedTeam, setSelectedTeam] = useState([])
	const [employee, setEmployee] = React.useState(false)

	const [showReportId, setShowReportId] = useState(false);
	const [showErrors, setShowErrors] = React.useState(false);
	const [fetchAgain, setFetchAgain] = useState(0);
	const [deletedRecord, setDeletedRecord] = useState(0);
	const [createEdit, setCreateEdit] = useState(0)

	const EmployeeId = useSelector((state) => Number(state.auth?.employee_id), shallowEqual)
	const AmbassadorId = useSelector((state) => Number(state.auth?.ambassador_id), shallowEqual)

	const { get_auth, get_teams, get_selectedOrg,
		get_team_access, get_employee,
		get_errors } = useSelector(
			(state) => ({
				get_errors: state.errors,
				get_auth: state.auth,
				get_employee: state.employees,
				get_teams: state.teams.teams,
				get_selectedOrg: state.selectedOrg,
				get_team_access: state.team_access.team_access
			}),
			shallowEqual
		);

	useEffect(() => {
		// Run! Like go get some data from an API.
		// dispatch({ type: SG_GET_MOODS });
		if (Number(AmbassadorId) > 0) {
			if (organizationId) {
				dispatch({
					type: LOAD_TEAMS,
					payload: `organization=${organizationId}`
				});
				dispatch({
					type: PULL_ORGANIZATION,
					payload: { id: organizationId }
				})
				dispatch({
					type: SG_GET_TEAM_ACCESSS,
					payload: `organization=${organizationId}`
				});
				return
			}
		}
		else {
			dispatch({ type: LOAD_TEAMS });
			if (Number(EmployeeId) > 0) {
				dispatch({
					type: SG_GET_TEAM_ACCESSS,
					payload: `employee=${EmployeeId}`
				});
			}
			return
		}
	}, [dispatch, AmbassadorId, organizationId, EmployeeId]);

	useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setOrganizationId(get_selectedOrg?.organization?.id)
		} else {
			setOrganizationId(Number(get_auth.organization_id))
		}
	}, [get_selectedOrg, AmbassadorId, get_auth]);

	useEffect(() => {
		setEmployee(get_employee[EmployeeId])
		if (get_employee[EmployeeId]?.account_type === 5) {
			setCreateEdit(true)
			return
		}

		if (get_employee[EmployeeId]?.role < 7) {
			setCreateEdit(true)
			return
		}
		setCreateEdit(false)
	}, [get_employee, EmployeeId]);

	useEffect(() => {
		if (Number(AmbassadorId) > 0) {
			setTeamList(get_teams)
			return
		}
		if (employee) {
			if (employee?.account_type === 5) {
				setTeamList(get_teams)
				return
			}
			const _TeamList = []
			_TeamList.push(
				get_teams.filter(
					tm => tm.id === employee?.team)[0]

			)
			get_team_access.map(tm =>
				_TeamList.push(get_teams.filter(
					gt => gt.id === tm?.team)[0]
				)
			)
			setTeamList(_TeamList)
			return
		}
	}, [get_teams, employee, AmbassadorId, get_team_access]);

	function UpdateSelectedTeam(e) {
		setSelectedTeam(e)
	}
	useEffect(() => {
		if (get_errors?.status === 403) {
			setShowErrors(get_errors?.data?.detail)
		}
	}, [get_errors]);

	function clearErrors() {
		dispatch({
			type: CLEAR_ERRORS
		})
		setShowErrors(false)
	}

	return (
		<Container>
			{showErrors &&
				<Message negative
					onDismiss={clearErrors}
					header='There was an Error!'
					content={showErrors}
				/>
			}
			{showReportId ?
				<Details showReportId={showReportId}
					setShowReportId={setShowReportId}
					setFetchAgain={setFetchAgain}
					setDeletedRecord={setDeletedRecord} />
				:
				<>
					<Title>Debriefs</Title>
					<DropContainer>
						<WideSelectCont>
							<Select
								placeholder="Select Team"
								onChange={UpdateSelectedTeam}
								value={selectedTeam}
								options={teamList}
								isClearable
								// isLoading={loading}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</WideSelectCont>

						{/* Add New Modals */}
						{createEdit &&
							<AddNew
								setFetchAgain={setFetchAgain}
								setShowReportId={setShowReportId}
							/>
						}
						{/* Add new modals */}

					</DropContainer>

					{/* Select Debrief Type */}
					<DebriefType />
					{/* Select Debrief Type */}
					
					<List
						fetchAgain={fetchAgain}
						setFetchAgain={setFetchAgain}
						selectedTeam={selectedTeam}
						organizationId={organizationId}
						setShowReportId={setShowReportId}
						showErrors={showErrors}
						deletedRecord={deletedRecord}
						createEdit={createEdit}
						setDeletedRecord={setDeletedRecord}
					/>
				</>
			}


		</Container>
	)
}


export default Debriefs


const Container = styled.div`
	margin-top:50px;
	font-family: 'Red Hat Display', sans-serif;
`

const Title = styled.div`
		font-size:26px;
	font-weight:bold;
	margin-right:20px;
	
`
const WideSelectCont = styled.div`
width:200px;
`
const DropContainer = styled.div`
	margin-top:20px;
	display:flex;
  justify-content:space-between;
`
