import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import ReportType from './ReportType'

const ReportHome = () =>{

    const [active,setActive] = useState(1)

    return (
        <Container>
            <Center>
                <Title>PDF Report Home</Title>

                <Tabs>
                   <Tab onClick={()=>setActive(1)} active={active==1}>Reports</Tab>
                   <Tab onClick={()=>setActive(3)} active={active==3}>History</Tab>

                </Tabs> 

                <Options>
                    <ReportType
                    title={'Culture Factor Summary'}
                    description={'This report summarizes the information for each culture factor. The data for each culture factor is analyzed and summarized.'}
                    typeTitle={'Culture'}/>

                    <ReportType
                    title={'Lowest Culture Factor Report'}
                    description={'This report summarizes the information for each culture factor. The data for each culture factor is analyzed and summarized.'}
                    typeTitle={'Culture'}/>

                    <ReportType
                    title={'Retention Analysis'}
                    description={'This report summarizes the information for each culture factor. The data for each culture factor is analyzed and summarized.'}
                    typeTitle={'Retention'}/>

                    
                </Options>
              
            </Center>
        </Container>
    )

}

export default ReportHome


const Container = styled.div`
    display:flex;
    flex-direction:column;
    margin-top:30px;
    width:100%;
    justify-content:center;
    align-items:center;
    font-family:'Raleway';
`

const Center = styled.div`
    width:800px;
    `

const Title = styled.div`
    font-size:24px;
    font-weight:600;
    color:#2A3039;
    margin-bottom:20px;
    margin-top:20px;
    display:flex;
    align-items:center;
    justify-content:space-between;
`


const Tabs = styled.div`
    display:flex;
    width:100%;
    justify-content:flex-start;
    margin-bottom:20px;
    margin-top:30px;
`

const Tab = styled.div`
    padding:10px 20px;
    color:${props=>props.active ? 'blue' : '#2A3039'};
    border-bottom:${props=>props.active ?  '2px solid blue':'none'};
    margin-right:20px;
    cursor:pointer;
`
const Options = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    width:100%;
    margin-bottom:20px;
`