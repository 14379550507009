import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Button, Icon, Confirm } from 'semantic-ui-react'

import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    SG_GET_DEBRIEF_RESPONSES,
    SG_FETCH_DEBRIEF_SCHEDULE,
    SG_FETCH_SURVEY_QUESTION,
} from "constants/actions";

import format from "date-fns/format";

import PracticeReport from "debriefs/report/practice"
import PerformanceReport from "debriefs/report/performance"


const DebriefScheduleDetail = ({
    showReportId, setShowReportId }) => {
    const dispatch = useDispatch();

    const [debriefSchedule, setDebriefSchedule] = useState([]);
    const [debriefResponses, setDebriefResponses] = useState([]);
    const [debriefQuestionId, setDebriefQuestionId] = useState([]);
    // const [teamId, setTeamId] = useState([]);

    const {
        get_debrief_responses, get_debrief_schedules,
        get_survey_question, get_employees } = useSelector(
            (state) => ({
                get_debrief_responses: state.debrief_response,
                get_debrief_schedules: state.debrief_schedule,
                get_survey_question: state.surveyquestions,
                get_employees: state.employees.employees
            }),
            shallowEqual
        );

    useEffect(() => {
        if (showReportId) {
            dispatch({
                type: SG_FETCH_DEBRIEF_SCHEDULE,
                payload: { id: showReportId }
            })
            dispatch({
                type: SG_GET_DEBRIEF_RESPONSES,
                payload: `debrief_schedule=${showReportId}`
            })
        }
    }, [dispatch, showReportId]);

    useEffect(() => {
        if (debriefQuestionId > 0) {
            dispatch({
                type: SG_FETCH_SURVEY_QUESTION,
                payload: { id: debriefQuestionId }
            })
        }
    }, [dispatch, debriefQuestionId]);

    useEffect(() => {
        setDebriefSchedule(get_debrief_schedules?.[showReportId])
        setDebriefQuestionId(get_debrief_schedules?.[showReportId]?.brief_question)
    }, [showReportId, get_debrief_schedules]);

    useEffect(() => {
        setDebriefResponses(get_debrief_responses?.debrief_response)
    }, [get_debrief_responses]);

    // useEffect(() => {

    // }, [get_survey_question, debriefQuestionId]);

    // console.log(debriefResponses)

    return (
        <>
            

            <TopContainer>
                <div>
                    <Button floated='left' onClick={() => setShowReportId(false)}>
                        <Icon name='left chevron' /> Back</Button>
                  {/*  <Headers>Details of {debriefSchedule?.type_of_text} </Headers>
                    <Org>With Team {debriefSchedule?.team_name}</Org>*/}
                </div>
                <div>
                    {debriefSchedule?.scheduled_for &&
                        <Information>
                          Scheduled On:  {format(
                                new Date(debriefSchedule?.scheduled_for?.toLocaleString()),
                                "EEEE, MMM do"
                            )}
                        </Information>
                    }
                   {/* {debriefSchedule?.close_at &&
                        <Information>
                           Between:{" "}
                           {format(
                                new Date(debriefSchedule?.scheduled_for?.toLocaleString()),
                                "h:mm a"
                            )}
                            {` and `} {format(
                                new Date(debriefSchedule?.close_at?.toLocaleString()),
                                "h:mm a"
                            )}
                        </Information>
                    }
                    <Information>
                        {debriefSchedule?.status_text}
                    </Information>
                    <Org>Members Included {debriefSchedule?.include_members ? "Yes" : "No"}</Org>
                    <Org>Leaders Included {debriefSchedule?.include_leaders ? "Yes" : "No"}</Org>*/}
                </div>


            </TopContainer>
            
            {debriefSchedule?.status > 2 ?
                <>
                    {debriefSchedule?.type_of === 1 &&
                        <PerformanceReport
                            debriefResponses={debriefResponses}
                            questions={get_survey_question[debriefQuestionId]?.questions}
                            debriefSchedule={debriefSchedule}
                            get_employees={get_employees}
                            teamId={debriefSchedule?.team}
                        />}
                    {debriefSchedule?.type_of === 2 &&
                        <PracticeReport
                            debriefResponses={debriefResponses}
                            questions={get_survey_question[debriefQuestionId]?.questions}
                            debriefSchedule={debriefSchedule}
                            get_employees={get_employees}
                            teamId={debriefSchedule?.team}
                        />
                    }
                </> :
                <>
                    <CenterMessage>
                        The Survey has not yet gone out!
                    </CenterMessage>
                    <InfoCentered>
                        You will start seeing data once the survey becomes live.
                    </InfoCentered>
                </>
            }
        </>
    );
};

export default DebriefScheduleDetail;

const Headers = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
  font-size:28px;
  margin-top:40px;
  margin-bottom: 20px;
`;

const CenterMessage = styled.div`
  text-align: center;
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
  font-size:28px;
  margin-top:100px;
  margin-bottom: 20px;
`;
const InfoCentered = styled.div`
  text-align: center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:20px;
  margin-bottom: 25px;
`

const Org = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-size:22px;
  font-weight:bold;
  margin-bottom: 25px;
`
const Information = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-size:20px;
  margin-bottom: 25px;

`

const Table = styled.div`
	margin-top:30px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding:20px;
`

const TR = styled.div`
	display:flex;
	padding:15px 10px;
	width:100%;
`

const TH = styled.div`
	width:${props => props.width + '%'};
	font-weight:bold;
	border-bottom:1px solid #9b9b9b;
	padding-bottom:5px;
`

const TD = styled.div`
	width:${props => props.width + '%'};
	display:flex;
	align-items:center;
	position:relative;
`

const T1 = styled.div`
	font-weight:500;
	font-size:18px;
`

const SmallImg = styled.img`
	width:30px;
	margin-right:10px;
`



const View = styled.div`
	padding:5px 35px;
	background-color:#6a00ff;
	color:white;
	border-radius:20px;
	font-weight:bold;
	position:absolute;
	right:0;
	cursor:pointer;
`

const Card = styled.div`
  max-width:100%;
  padding:30px;
  margin-bottom:10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
//   position:relative;
  display:flex;
  justify-content:space-between;
//   align-items:center;

`

const TopContainer = styled.div`
    display:flex;
    width:100%;
    align-items:center;
    justify-content:space-between;
`