import Opportunity from "assets/images/theme/opportunity.svg";
import Uncertainty from "assets/images/theme/uncertainty.svg";
import Learning from "assets/images/theme/learning.svg";
import Achievement from "assets/images/theme/achievement.svg";
import Hardship from "assets/images/theme/hardship.svg";
import Strategy from "assets/images/theme/strategy.svg";
import Collaboration from "assets/images/theme/collaboration.svg";
import Capacity from "assets/images/theme/capacity.svg";
import Relationships from "assets/images/theme/relationships.svg";
import Gratitude from "assets/images/theme/gratitude.svg";



const Themes = {
	"Opportunity":{
		"description":"Opportunity is categorized by a focus on future events or tasks that people are optimistic about",
	"icon":Opportunity},
	"Uncertainty":{
		"description":"Uncertainty is categorized by a lack of clarity or confidence on how things will unfold or impact a person or the team",
	"icon":Uncertainty},
	"Learning":{
		"description":"Learning is categorized by a focus on growth, improvement, or development",
	"icon":Learning},
	"Achievement":{
		"description":"Achievement is categorized by a focus on pursuing goals, winning, succeeding, or achieving an outcome",
	"icon":Achievement},
	"Hardship":{
		"description":"Hardship is categorized by a focus on adverstiy, struggle, challenge, or coping within something difficult",
		"icon":Hardship},
	"Strategy":{
		"description":"Strategy is categorized by a focus on implementing new approaches, the way things get done, or the direction the team is heading",
	"icon":Strategy},
	"Collaboration":{
		"description":"Collaboration is categorized by a focus on working together, teamwork, or groupwork",
	"icon":Collaboration},
	"Capacity":{
		"description":"Capacity is categorized by a focus on having too much to do, going on, feeling tired, or unable to keep up",
	"icon":Capacity},
	"Relationships":{
		"description":"Relationships is categorized by a focus on belonging, feelings of support, community or just being together",
	"icon":Relationships},
	"Gratitude":{
		"description":"Gratitude is categorized by a focus on having a positive, appreciative, or helpful perspective on things and how they're going",
	"icon":Gratitude}

}

export default Themes


export const ThemesList = [

	{
		id: 1,
		details: "Opportunity is categorized by a focus on future events or tasks that people are optimistic about",
		icon: Opportunity,
		name: "Opportunity",
		text: "Opportunity",
		value: "Opportunity",
	},
	{
		id: 2,
		details: "Uncertainty is categorized by a lack of clarity or confidence on how things will unfold or impact a person or the team",
		icon: Uncertainty,
		name: "Uncertainty",
		text: "Uncertainty",
		value: "Uncertainty",
	},
	{
		id: 3,
		details: "Learning is categorized by a focus on growth, improvement, or development",
		icon: Learning,
		name: "Learning",
		text: "Learning",
		value: "Learning",
	},
	{
		id: 4,
		name: "Hardship",
		text: "Hardship",
		value: "Hardship",
		details: "Any focus on adverstiy, struggle, challenge, or coping within something difficult",
		icon: Hardship
	},
	{
		id: 5,
		name: "Strategy",
		text: "Strategy",
		value: "Strategy",
		details: "Any focus on implementing new approaches, the way things get done, or the direction the team is heading",
		icon: Strategy
	},
	{
		id: 6,
		name: "Collaboration",
		text: "Collaboration",
		value: "Collaboration",
		details: "Any focus on working together, teamwork, or groupwork",
		icon: Collaboration
	},
	{
		id: 7,
		name: "Capacity",
		text: "Capacity",
		value: "Capacity",
		details: "Any focus on having too much to do / going on, feeling tired, unable to keep up etc.",
		icon: Capacity
	},
	{
		id: 8,
		name: "Relationships",
		text: "Relationships",
		value: "Relationships",
		details: "Any focus on relationships, belonging, feelings of support, community or just being together",
		icon: Relationships
	},
	{
		id: 9,
		name: "Gratitude",
		text: "Gratitude",
		value: "Gratitude",
		details: "Any focus on having a positive or helpful perspective on things and how they're going",
		icon: Gratitude
	}

]