import styled, { keyframes } from "styled-components";
import React from "react";
import { Menu,Icon,Dropdown} from 'semantic-ui-react'
import ClickAwayListener from "react-click-away-listener";

const OptionsMenu = ({categories,edit,select,setOpen}) =>{


	const handleClickAway = () => {
		if(edit){
			 setOpen(false);
		}
   
  };

	return (
		 <ClickAwayListener onClickAway={handleClickAway}>
		<Container show={edit}>
      <Menu vertical>
        <Menu.Item>
          <Menu.Header>Edit  </Menu.Header>

          <Menu.Menu>
          	{categories?.categories.map(item=>{
          		return <Menu.Item
		              name={item.name}
		              onClick={()=>select(item)}
		            />
          	})}
            
            
          </Menu.Menu>
        </Menu.Item>

        <Menu.Item>
          <Menu.Header>Batch Selected Actions</Menu.Header>

          <Menu.Menu>
            <Menu.Item
              name='Archive'
            />

            {categories?.categories.map(item=>{
          		return <Dropdown item text={'Change '+ item.name}>
			          <Dropdown.Menu>
			          	{item.options.map(o=>{
			          		return <Dropdown.Item  text={o.name} />
			          	})}
			            
			          </Dropdown.Menu>
			        </Dropdown>
          	})}
          </Menu.Menu>
        </Menu.Item>

      
      </Menu>
      </Container>
      </ClickAwayListener>
		)
}


export default OptionsMenu

const Container = styled.div`
	position:absolute;
	left:0px;
	height:${props=>props.show?'auto':0};
	overflow:${props=>props.show?'':'hidden'};
	transition:height 0.5s;
	z-index:10;

`