import React, { useEffect, useState } from "react";
import NewEQ from "./Mood";
import NewSystems from "./NewSystems";
import { useNavigate } from "react-router-dom";

// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();

const Selection = ({ teamList, selectedTeam, selectedTeamId }) => {
  let navigate = useNavigate();
  const [mood, setMood] = React.useState(false);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const name = params.get("name") ? params.get("name") : false;

  const setMoodRoute = (m) => {
    if (!teamList) {
      navigate("/app/team/eq?name=" + m);
    }

    setMood(m);
  };
  //  console.log(mood)
  //  console.log(selectedTeamId)

  //  for team show this
  if (mood || name) {
    return (
      <NewSystems
        selectedMood={mood}
        back={() => setMood(false)}
        showBack={teamList}
        selectedTeam={selectedTeam}
        selectedTeamId={selectedTeamId}
      />
    );
  }

  return (
    <NewEQ
      setMood={(m) => setMoodRoute(m)}
      selectedTeam={selectedTeam}
      selectedTeamId={selectedTeamId}
    />
  );
};

export default Selection;
