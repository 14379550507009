import React from "react";
import DateSelector from "./DateSelector";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import { format } from "date-fns";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  dateRangeContainer: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  titleLabel: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 10,
  },
  control: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  selectionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    fontSize: 10,
    fontWeight: 600,
    marginRight: 10,
    marginTop: -15,
  },
});

const DateRange = (props) => {
  const fromDate = props.data?.planData?.[props.id]?.[0];
  const toDate = props.data?.planData?.[props.id]?.[1];

  let fromFormatted;
  let toFormatted;
  if (!fromDate) {
    fromFormatted = "N/A";
  } else {
    fromFormatted = format(new Date(fromDate), "M/dd/yyyy");
  }

  if (!toDate) {
    toFormatted = "N/A";
  } else {
    toFormatted = format(new Date(toDate), "M/dd/yyyy");
  }

  return (
    <View style={styles.dateRangeContainer}>
      <Text style={styles.titleLabel}>Timeline:</Text>
      <View style={styles.control}>
        <View style={styles.selectionWrapper}>
          <Text style={styles.label}>From:</Text>
          <DateSelector {...props} date={fromFormatted} />
        </View>
        <View style={styles.selectionWrapper}>
          <Text style={styles.label}>To:</Text>
          <DateSelector {...props} date={toFormatted} />
        </View>
      </View>
    </View>
  );
};

export default DateRange;
