import React, { useEffect,useRef } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import styled, { keyframes } from "styled-components";
import { Icon } from "semantic-ui-react";

import BarChart from "./barchart";
import BarChartMobile from "./barchartmobile";
import TraitCard from "../TraitCard";
import DropDown from "../dropdown";
import TeamBreakDown from "../teamBreakdown";
import TraitInfo from './TraitInfo'

const H1 = styled.h1`
  font-family: "Red Hat Display", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 60px;

  @media (max-width:768px){
    margin-bottom:20px;
    font-size:23px;
  }
`;

const H2 = styled.h2`
  font-size: 23px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;

  @media(max-width:768px){
    display:none;
  }
`;

const H3 = styled.h3`
  font-size: 16px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

const P1 = styled.p`
  font-family: "Red Hat Display", sans-serif;
  font-size: 15px;
`;

const TopBox = styled.div`
  border-radius: 17.5px;
  background-color: #ebebeb;
  padding: 30px 60px 20px 30px;
  width: 49%;

  @media (max-width:768px){
    width:100%;
    padding:20px;
    margin-bottom:10px;
  }
`;
const Card = styled.div`
  width: 100%;
  box-shadow: 0 4px 20px 0 rgba(178, 178, 178, 0.5);
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-bottom: 60px;
  position: relative;

  @media(max-width:768px){
    flex-direction:column;
  }
`;


const Compat = styled.div`
  border-radius: 2px;
  background-color: #f8f8f8;
  width: 40%;
  margin: 10px;
  box-sizing: content-box;

  @media (max-width:768px){
    width:100%;
    margin:0;
  }
`;


const CompatBox = styled.div`
  border-top-left-radius: 12px;
  background-color: #6a00ff;
  width: ${(props) => (props.width== 10 ? '100px' : props.width + "%")};
  max-width:calc(100% - 100px);
  min-width:100px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: width 0.5s;
`;
const BalanceBox = styled.div`
  border-top-right-radius: 12px;
  background-color: #1e2172;
  width: ${(props) => (props.width == 10 ? '100px' : props.width + "%")};
  max-width:calc(100% - 100px);
  min-width:100px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  transition: width 0.5s;
`;

const Percent = styled.div`
  font-size: 25px;
  font-weight: bold;
  font-family: "Red Hat Display", sans-serif;
`;

const PercentSub = styled.div`
  font-size: 12px;
  font-weight: 900;
  margin-top: 5px;
  font-family: "Red Hat Display", sans-serif;
`;

const Read = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 10px;
  font-weight: bold;
  color: #7600ff;
  padding-left: 5px;
  cursor: pointer;
  text-transform: uppercase;
  position: absolute;
  left: 47%;
  top: 36px;
  z-index:2;

  @media (max-width:768px){
    left:50%;
    top:10px;
  }
`;

const Container = styled.div`
  padding:50px;

  @media (max-width:768px){
    padding:0px;
    width:100%;
  }
`
const DropdownContainer = styled.div`
  display:flex;
  flex-direction:row;
  align-items:center;
  margin-bottom:40px;

`

const TopContainer = styled.div`
   display:flex;
   flex-direction:row;
   justify-content:space-between;
   margin-bottom:72px;

   @media (max-width:768px){
     display:none;
   }
`

const subTraits = {
  Extraversion: ["Assertiveness", "Enthusiasm"],
  Agreeableness: ["Politeness", "Compassion"],
  Conscientiousness: ["Orderliness", "Industriousness"],
  Emotionality: ["Volatility", "Withdrawl"],
  "Openess to Experience": ["Abstract Interest", "Creativity"],
};

const subTraitskey = {
  Extraversion: ["assertiveness", "enthusiasm"],
  Agreeableness: ["politeness", "compassion"],
  Conscientiousness: ["orderliness", "industriousness"],
  Emotionality: ["volatility", "withdrawl"],
  "Openess to Experience": ["ai", "creativity"],
};

const keywords = {
   Extraversion: "extraversion",
  Agreeableness: "agreeableness",
  Conscientiousness: "conscientiousness",
  Emotionality: "emotionality",
  "Openess to Experience": "openess",
}


const subTraitList = [
  {
    id: 1,
    name: "Extraversion",
    key: "extraversion",
    subTrait: [
      { id: 1, name: "Assertiveness", key: "assertiveness" },
      { id: 2, name: "Enthusiasm", key: "enthusiasm" }
    ]
  },
  {
    id: 2,
    name: "Conscientiousness",
    key: "conscientiousness",
    subTrait: [
      { id: 5, name: "Orderliness", key: "orderliness" },
      { id: 6, name: "Industriousness", key: "industriousness" },
    ]
  },
  {
    id: 3,
    name: "Agreeableness",
    key: "agreeableness",
    subTrait: [
      { id: 3, name: "Politeness", key: "politeness" },
      { id: 4, name: "Compassion", key: "compassion" },
    ]
  },
  {
    id: 4,
    name: "Emotionality",
    key: "er",
    subTrait: [
      { id: 7, name: "Volatility", key: "volatility" },
      { id: 8, name: "Withdrawl", key: "withdrawl" },
    ]
  },
  {
    id: 5,
    name: "Openess to Experience",
    key: "openess",
    subTrait: [
      { id: 9, name: "Abstract Interest", key: "ai" },
      { id: 10, name: "Creativity", key: "creativity" },
    ]
  },
];

const Compared = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin: 0 20px 0 20px;
  font-size: 20px;
  color: #969696;

  @media (max-width:768px){
    display:none;
  }
`;

const MobileCompared = styled.div`
  font-family: "Red Hat Display", sans-serif;
  margin: 0 20px 0 20px;
  font-size: 16px;
  color: #545454;
  font-weight:bold;

  @media (min-width:768px){
    display:none;
  }
`;

const MobileTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  width:100%;
  margin-bottom:15px;

  @media (min-width:768px){
    display:none;
  }
`


const TraitReport = (props) => {
  const {personalityData, traitData,average} = props
  let pageRef = useRef(null)
  // const [personalityData, setPersonalityData] = React.useState(false);
  // const [traitData, setTraitData] = React.useState(false);
  const {reportName, selectedTrait} = props //'Extraversion' // 
 
  const [userID, setUser] = React.useState("1");
  const [compareID,setCompareID] = React.useState(props.id1)
  const [compareID2,setCompareID2] = React.useState(props.id2)
  const [balance, setBalance] = React.useState(50);


 


  React.useEffect(()=>{
    setCompareID(props.id1)
    setCompareID2(props.id2)
  },[props.id1,props.id2])

  React.useEffect(()=>{
    let user2
    let user1 = personalityData.filter(f => f.employee === compareID)[0]
    if(compareID2){
      user2 =personalityData.filter(f => f.employee === compareID2)[0]
    }else{
      user2=average
    }

    let balance_data =
        Math.abs(
          user1[subTraitskey[reportName][0]] -
            user2[subTraitskey[reportName][0]]
        ) +
        Math.abs(
          user1[subTraitskey[reportName][1]] -
            user2[subTraitskey[reportName][1]]
        );
      setBalance(Math.floor(balance_data / 2));
  },[compareID,compareID2])


  const getMagnitude=()=>{
     if(balance>80 || balance<20){
       return 'significantly'
     }
     else if(balance || balance<35){
       return 'moderately'
     }
     return 'slightly'
   }

   const getEmployeeName =(id) =>{
     if(personalityData){
       return personalityData.filter(f => f.employee === id)[0].name
     }
     return ""
   }

   const getText=()=>{
     return (props.employee==compareID? compareID2? 'Your' : 'Your' : getEmployeeName(compareID))+' '+
            reportName.toLowerCase()+' traits are '+ getMagnitude()+ ' more '+ (balance>50?"varied":"consistent") +' with '+ (compareID2? getEmployeeName(compareID2) :"your team's averages")+
            ' than they are '+ (balance>50?"consistent":"varied")+'. Higher '+ (balance>50?"variability":"consistency")+ ' scores indicate more natural trait '+
             (balance>50?"differences":"similarities")+', suggesting '+(props.employee==compareID?'you are ':getEmployeeName(compareID)+' is ') + getMagnitude()+ ' more '+ (balance>50?"dissimilar ":"alike ")+ (compareID2? getEmployeeName(compareID2) :"your team members")+
            ' than '+  (balance>50?"alike":"different")+'.'
   }

 


  return (
    <Container ref={pageRef}>
      <H1>
          <Icon name="arrow left" size={"small"} style={{ color: "#4a4a4a" ,cursor:'pointer',marginRight:24}} onClick={()=>props.back()}/>
        {reportName} Report
      </H1>
      <TraitInfo data={traitData[reportName]} trait={reportName}/>
       <MobileTitle>Current comparison</MobileTitle>
       <DropdownContainer>
          <DropDown 
          data={personalityData} 
          default={compareID} type={1} 
          selectedEmployee={(id) => props.setID1(id)}
           />
            <Compared>relative to</Compared>
            <MobileCompared>&</MobileCompared>
          <DropDown 
          data={personalityData} type={2} 
          default={compareID2?compareID2:false} 
          selectedEmployee={(id) => props.setID2(id)} />
         </DropdownContainer>

          <H2 style={{marginBottom:24}}>What is {reportName}?</H2>
      <TopContainer>
        <TopBox>
          <H2>
            Low {reportName}{" "}
            <Icon
              name="arrow circle down"
              size={"large"}
              style={{ color: "#4a4a4a" }}
            />
          </H2>
          <P1>{traitData && traitData[reportName]["low"]}</P1>
        </TopBox>

        <TopBox>
          <H2>
            High {reportName}{" "}
            <Icon
              name="arrow circle up"
              size={"large"}
              style={{ color: "#4a4a4a" }}
            />
          </H2>
          <P1>{traitData && traitData[reportName].high}</P1>
        </TopBox>
      </TopContainer>

      <H2 style={{marginBottom:24}}>Team {reportName} Distribution</H2>

      <Card>
         {/* Add this soon */}
        <TeamBreakDown
          userID={userID}
          type={reportName}
          selectedTrait={selectedTrait}
          personalityData={personalityData}
          traitData={traitData}
          keyword={keywords[reportName]}
          trigger={<Read>See team breakdown <Icon name="chevron right"/></Read>}
        />
        {/* Add this soon */}
          <BarChartMobile
          width={90}
          data={personalityData}
          id1={compareID}
          id2={compareID2}
          type={reportName}
          selectedTrait={selectedTrait}
          profile={compareID ? personalityData[compareID] : null}
          average={average[keywords[reportName]]}
        />
        <BarChart
          width={60}
          data={personalityData}
          id1={compareID}
          id2={compareID2}
          type={reportName}
          selectedTrait={selectedTrait}
          profile={compareID ? personalityData[compareID] : null}
          average={average[keywords[reportName]]}
        />
       
        <Compat>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CompatBox width={100 - balance>90?90:100 - balance<10?10:100 - balance}>
              <Percent>
                {balance ? Math.floor(100 - balance) : '50'}%
              </Percent>
              <PercentSub>CONSISTENT</PercentSub>
            </CompatBox>
            <BalanceBox width={balance>90?90:balance<10?10:balance}>
              <Percent>
                {balance
                  ? balance === 0
                    ? Math.floor(balance)
                    : Math.floor(balance)
                  : 50}
                %
              </Percent>
              <PercentSub>VARIED</PercentSub>
            </BalanceBox>
          </div>
          <div style={{ padding: "37px 0 20px 20px" }}>
            <P1>{getText()}</P1>
          </div>
        </Compat>
      </Card>

      <H2>{reportName} Aspects</H2>

      <TraitCard
        link={true}
        type={reportName && subTraits[reportName][0]}
        id1={compareID}
        id2={compareID2}   
        personalityData={personalityData}     
        average={average[subTraitskey[reportName][0]]}
        profiles={personalityData}
         keyword={subTraitskey[reportName][0]}
      />

       <TraitCard
        link={true}
        type={reportName && subTraits[reportName][1]}
        id1={compareID}
        id2={compareID2}    
        personalityData={personalityData}  
        average={average[subTraitskey[reportName][1]]}
        profiles={personalityData}
        keyword={subTraitskey[reportName][1]}
      />

      {/*<TraitCard
        link={true}
        type={subTraits[reportName][0]}
        score1={personalityData[userID][subTraits[reportName][0]]}
        score2={
          compareID
            ? personalityData[compareID][subTraits[reportName][0]]
            : average(personalityData, subTraits[reportName][0])
        }
        profile={compareID ? personalityData[compareID] : null}
        userID={userID}
      />
      <TraitCard
        link={true}
        type={subTraits[reportName][1]}
        score1={personalityData[userID][subTraits[reportName][1]]}
        score2={
          compareID
            ? personalityData[compareID][subTraits[reportName][1]]
            : average(personalityData, subTraits[reportName][1])
        }
        profile={compareID ? personalityData[compareID] : null}
        userID={userID}
      />*/}
    </Container>
  );
};

export default TraitReport;
