import styled, { keyframes } from "styled-components";
import React from "react";
import { Input, Progress } from 'semantic-ui-react'


const Team = ({ name, hide, selected, SelTeam, team, ...props }) => {
	// console.log(selected)
	
	return (
		<Card
			selected={selected}
			hide={hide}
			onClick={() => SelTeam(team)}
		>
			<UserContainer>

				<Name>{hide?team.name[0]:team?.name}</Name>
				<Hidden hide={hide}>
					{/* <Impact>Overall</Impact>
						<Progress percent={86} progress size='small' style={{marginBottom:15,marginTop:0}} color='grey'/> */}
				</Hidden>
			</UserContainer>


		</Card>
	)
}


export default Team



const Card = styled.div`
  border-radius: 15px;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);
  background-color: ${props => props.selected ? '#6a00ff' : "white"};
  color: ${props => props.selected ? 'white' : "black"};
  padding:10px;
  margin-top:10px;
  cursor:pointer;
  width:${props => props.hide ? '30px' : '100%'};
  height:${props => props.hide ? '30px' : '40px'};
  transition:all 500ms;
  display:flex;
  align-items:${props => props.hide ? 'center' : 'flex-start'};
  justify-content:${props => props.hide ? 'center' : 'flex-start'};
`

const UserContainer = styled.div`
	display:flex;
	flex-direction:column;
	position:relative;
	width:100%;
`

const Avatar = styled.img`
	width:40px;
	height:40px;
	border-radius:20px;
	margin-right:10px;
`

const Name = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:14px;
	font-weight:500;
	font-weight:bold;
`

const H2 = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:12px;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  color:#4a4a4a;
  font-weight:bold;

`

const Impact = styled.div`
	font-size:9px;
	font-weight:900;
	text-transform:uppercase;
	margin-top:5px;
`

const Hidden = styled.div`
	display:${props => props.hide ? 'none' : 'auto'};
	transition:all 500ms;
	width:100%;
`