import { useState, useEffect, useContext} from "react";
import {  useDispatch } from "react-redux";


import {
    SG_LOGIN_GOOGLE_AUTH,
    SG_REGISTER_GOOGLE_AUTH,
    SG_REGISTER_GOOGLE_AUTH_TOKEN,

} from "constants/actions";

import { DomainContext } from 'layouts/LayoutContext';


function GoogleAuth({
    login = false,
    organizationName = false,
    signUpToken = false }) {
    const dispatch = useDispatch();
    const Domain = useContext(DomainContext);
    
    const [loadedScript, setLoadedScript] = useState(false)

    useEffect(() => {
        if (!loadedScript && window.google) {
            // need this to initialuze google for linter to ignore
            /* global google */
            google.accounts.id.initialize({
                client_id: Domain.googleClientId,
                callback: onSuccess
            })

            google.accounts.id.renderButton(
                document.getElementById("googleSignInDiv"),
                {
                    theme: "filled_blue",
                    size: "large",
                    // text: "signup_with",
                    // context:"signup",
                }
            )
            setLoadedScript(true)
        }
       
    },[]);

    const onSuccess = (res) => {
        // console.log('success:', res);
        const GToken = res?.credential
        googleAuthServerSignIn(GToken)

    };


    async function googleAuthServerSignIn(token) {
        // simple login
        if (login) {
            dispatch({
                type: SG_LOGIN_GOOGLE_AUTH,
                payload: {
                    token: token
                },
            });
        }
        // register new org
        if (organizationName) {
            dispatch({
                type: SG_REGISTER_GOOGLE_AUTH,
                payload: {
                    token: token,
                    company_name: organizationName
                },
            });
        }
        // emp registration
        if (signUpToken) {
            dispatch({
                type: SG_REGISTER_GOOGLE_AUTH_TOKEN,
                payload: {
                    token: token,
                    employee_token: signUpToken
                },
            });
        }

    };

    if (!Domain.showGoogleSso) {
        return <></>
    }

    return (<>
    <div id="googleSignInDiv"></div>
    {/* <div id="g_id_onload"
     data-client_id="444691808577-8ta54ebdhvfmds918qqf8nc0v06hb7ij.apps.googleusercontent.com"
     data-context="signup"
     data-ux_mode="popup"
     data-login_uri="localhost:3000"
     data-auto_prompt="false">
</div>

<div class="g_id_signin"
     data-type="standard"
     data-shape="rectangular"
     data-theme="filled_blue"
     data-text="signup_as"
     data-size="large"
     data-logo_alignment="left"
     data-width="500">
</div> */}
    </>) 
}

export default GoogleAuth;
