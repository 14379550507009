import React from "react";
import styled from "styled-components";

const ProgressBar = (props) => {
  const { completed, label, customLabel } = props;

  const getBgColor = (completed) => {
    if (completed >= 7) {
      return "#27CDA7";
    }
    if (completed >= 4) {
      return "#FEA711";
    }
    if (completed >= 0) {
      return "#F15062";
    }
  };

  const getRating = (completed) => {
    if (completed >= 7) {
      return "High";
    }
    if (completed >= 4) {
      return "Moderate";
    }
    if (completed >= 0) {
      return "Low";
    }
  };

  const background = getBgColor(completed);

  return (
    <Container>
      <ProgressContainer>
        <Progress bgColor={background} completed={completed*10} />
      </ProgressContainer>
      <InfoSection>
        <Rating color={background}>{customLabel || completed}</Rating>
        <InfoText>{getRating(completed)}&nbsp;{label}</InfoText>
      </InfoSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  margin-right: 20px;
  min-width: 160px;
  justify-content: flex-start;
  z-index: 999;
`;

const ProgressContainer = styled.div`
  margin-top: 20px;
  height: 7px;
  width: 50px;
  min-width: 50px;
  background-color: #e0e0de;
  border-radius: 50px;
  transform: rotate(-90deg);
`;

const Rating = styled.div`
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => `${props.color}`};
`;

const Progress = styled.div`
  height: 100%;
  max-width: 50px;
  width: ${(props) => `${props.completed}%`};
  background-color: ${(props) => `${props.bgColor}`};
  border-radius: inherit;
`;

const InfoText = styled.div`
  font-family: Raleway;
  color: #666d79;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const InfoSection = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: -20px;
  position: relative;
`;

export default ProgressBar;
