import styled, { keyframes } from "styled-components";
import React from "react";
import Chart from "react-apexcharts";
import { Progress,Icon } from 'semantic-ui-react'



const Individual = () =>{

  const [show,setShow] = React.useState(false)

	return (
		<Container>
      <User>
        <UserContainer>
          <Avatar src={"https://storage.googleapis.com/innerlogic_staging_storage_2020/uploads/128/employee/8aab2574-0a69-4e11-829c-918667719926.jpg"}/>
           <div>
           <Name>Michael Bawol</Name>
           <View>View Profile</View>
           </div>
         </UserContainer>
        <Score>
              85%
       <Impact>Importance</Impact>
        </Score>
      </User>
      <Comment>Tongue tenderloin pork belly andouille pork meatball doner pancetta pastrami buffalo meatloaf prosciutto picanha. Jerky pastrami beef strip steak venison buffalo shoulder landjaeger short loin picanha short ribs hamburger sirloin cupim.</Comment>
      <See onClick={()=>setShow(!show)}>Show metrics <Icon name={show?"chevron up":"chevron down"}/></See>
      <Hide show={show}>
       
        <DistributionTitle>Trust Score Distribution</DistributionTitle>
         <Chart options={data.options} series={data.series} type="bar" height={200}/>
      </Hide>

     
     </Container>
		)
}


export default Individual



const Container = styled.div`
	width:100%;
  box-shadow: 0 2px 40px 0 rgba(220, 220, 220, 0.5);

  margin-top:20px;

  
`

const Average = styled.div`
   font-family: 'Red Hat Display', sans-serif;
   font-size:12px;
   margin-left:10px;
`

const View = styled.div`
    font-family: 'Red Hat Display', sans-serif;
   font-size:12px;
   margin-left:10px;
   color:white;

`

const UserContainer = styled.div`
  display:flex;
`
const Title = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  margin-bottom:-10px;
  font-weight:500;
  
`
const DistributionTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:20px;
  margin-bottom:0px;
  font-weight:500;
  color:#4a4a4a;
  margin-left:10px;
`

const CommentTitle = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:10px;
  margin-bottom:0px;
  font-weight:500;
`


const Name = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  font-weight:bold;
  margin-left:10px;
  font-size:18px;
  color:white;
`

const Description = styled.div`
  font-family: 'Red Hat Display', sans-serif;
  margin-top:8px;
  margin-bottom:-15px;
`

const Question = styled.div`
	font-family: 'Red Hat Display', sans-serif;
	font-size:18px;

	margin-bottom:50px;
  line-height:1;
`

const Avatar = styled.img`
  height:50px;
  width:50px;
  border-radius:25px;
`

const User = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-bottom:10px;
  position:relative;
     background-image: linear-gradient(to bottom right, #6d00aa , #6a00ff);
    color:white;
    padding:15px 20px;
`

const Score = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  color:white;
  font-weight:bold;
  position:absolute;
  top:15px;
  right:20px;

`

const Impact = styled.div`
  font-size:9px;
  font-weight:900;
  text-transform:uppercase;
  margin-top:-3px;
`
const Comment = styled.div`
 font-family: 'Red Hat Display', sans-serif;
font-size:18px;
line-height:1.3;
font-weight:300;
padding:20px 20px 0 20px;
`

const Hide = styled.div`
  max-height:${props=>props.show?'400px':'0px'};
  overflow:hidden;
  transition:all 1s;
  padding:${props=>props.show?'0px 20px 20px 0':'0px'};
`

const See = styled.div`
  width:100%;
  display:flex;
  justify-content:flex-end;
  font-family: 'Red Hat Display', sans-serif;
  font-size:12px;
  cursor:pointer;
  padding:20px;

  &:hover{
    text-decoration:underline;
  }
`


const data = {
          
            series: [{
              name: 'Trust',
              data: [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
                5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
                48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
              ]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350,
                sparkline: {
              enabled: true
            },
                toolbar:{show:false}
              },
              grid:{
                padding:{
                  right:125
                }
              },
              plotOptions: {
                bar: {
                  colors: {
                    ranges: [{
                      from: -1,
                      to: 100,
                      color: '#6a00ff'
                    },{
                      from: -100,
                      to: -1,
                      color: '#ff2d55'
                    }]
                  },
                  columnWidth: '80%',
                }
              },
              annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: '#e9e9e9',
            offsetX:125,
             strokeDashArray: 0,
            label: {
              borderColor: '#00E396',
              borderWidth:0,
               offsetX:125,
              style: {
                color: '#4a4a4a',
                fontFamily: 'Red Hat Display, sans-serif',
                fontSize:14
              },
              text: 'Average Trust 73%'
            }
          }
        ]
      },
              dataLabels: {
                enabled: false,
              },
              yaxis: {
                title: {
                  text: 'Growth',
                },
                labels: {
                  formatter: function (y) {
                    return y.toFixed(0) + "%";
                  }
                }
              },
              xaxis: {
                type: 'datetime',
                categories: [
                  '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
                  '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
                  '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
                  '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
                  '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
                  '2013-07-01', '2013-08-01', '2013-09-01'
                ],
                labels: {
                  rotate: -90
                }
              }
            },
          
          
          };