import styled from "styled-components";
import React, { useEffect, useState } from "react";


const VideoCard = ({title,color,sub}) =>{

	return (
		<Container>
			<Card color={color}>

			</Card>

			<Bottom>
				<T2>{sub}</T2>
				<T1>{title}</T1>
				<Button>Watch</Button>
			</Bottom>
			
		</Container>
		)
}


export default VideoCard


const Container = styled.div`
	border-radius:10px;
	border:1px solid lightgrey;
	overflow:hidden;
	width:250px;
	height:300px;
	margin-right:20px;
`

const Card = styled.div`
	height:50%;
	background-color:${props=>props.color};

`

const Bottom = styled.div`
	height:50%;
	padding:15px;
	position:relative;
`

const T2 = styled.div`
font-family: 'Poppins';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 18px;
/* identical to box height */

text-transform: uppercase;

color: #7E7E7E;
margin-bottom:5px;
`

const T1 = styled.div`
	font-family: 'Poppins';
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
/* identical to box height */


color: #414141;
`

const Button = styled.div`
	border-radius:5px;
	padding:2px;
	color:white;
	background-color:#476DFA;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	width:75px;
	display:flex;
	align-items:center;
	justify-content:center;
	cursor:pointer;
	position:absolute;
	bottom:10px;
	right:10px;
`