import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";

// import { useToasts } from "react-toast-notifications";

import {
  Modal,
  Icon,
  Button,
} from "semantic-ui-react";
import MemberCardAudit from "./MemberCardAudit";
import MemberCard from "./memberCard";
import Profile from "./Profile";
import EditMember from "./EditMember"

import {
  SG_UPDATE_EMPLOYEE_AUDIT,
  SG_EDIT_EMPLOYEE_CATEGORY,
  SG_ADD_EMPLOYEE_CATEGORY,
} from "constants/actions";

const MemberTableAudit = (props) => {
  const {
    auditEnabled,
    employeeCategories,
    setTeamUpdate,
    members,
    // userAdmin,
    userEmployee,
    userEmployeeCategoryList,
    teamToolEnabled,
    openFullCard,
    setOpenFullCard,
    openTeamCard,
    setOpenTeamCard,
    fullEmpData,
    setFullEmpData,
    newInvite,
    setLoading,
    setChangesMade,
  } = props;

  const dispatch = useDispatch();
  // const { addToast } = useToasts();

  // const [openFullCard, setOpenFullCard] = useState(false);
  // const [openTeamCard, setOpenTeamCard] = useState(false);
  // const [fullEmpData, setFullEmpData] = useState({});

  const [valueUpdated, setValueUpdated] = useState(false);
  const [selectRow, setSelectRow] = useState({
    first_name: "",
    last_name: "",
    email: "",
    enable_login: false,
    survey_status: 9,
    survey_schedule: 0,
    survey_add_users: 0,
    account_type: 9,
    read_feedback: 0,
    access_subfactor_data: 0,
    access_data_analytics: 0,
    role: 21,
  });

  const [categoryUpload, setCategoryUpload] = useState([]);
  const [categoryUpdated, setCategoryUpdated] = useState(false);
  const [employeeCategory, setEmployeeCategory] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);

  const [primarySecondaryName, setPrimarySecondaryName] = useState({
    primary: "Type",
    secondary: "Role",
  });

  const { get_survey_structure } = useSelector(
    (state) => ({
      get_survey_structure: state.surveystructure.survey_structure,
    }),
    shallowEqual
  );

  // console.log(selectRow)

  function SaveFormData() {
    if (valueUpdated && !errorMessage) {
      dispatch({
        type: SG_UPDATE_EMPLOYEE_AUDIT,
        payload: {
          account_type: selectRow?.account_type,
          enable_login: selectRow?.enable_login ? true : false,
          first_name: selectRow?.first_name,
          last_name: selectRow?.last_name,
          survey_add_users: selectRow?.survey_add_users,
          survey_schedule: selectRow?.survey_schedule,
          survey_status: selectRow?.survey_status,
          read_feedback: selectRow?.read_feedback,
          access_subfactor_data: selectRow?.access_subfactor_data,
          access_data_analytics: selectRow?.access_data_analytics,
          id: fullEmpData.id,
        },
      });
      setValueUpdated(false);
      setChangesMade({
        color: "green",
        message: "User was updated"
      })
    } else {
      setChangesMade({
        color: "red",
        message: "No changes were saved"
      })
    }

    if (categoryUpdated) {
      if (employeeCategory?.id) {
        // console.log("updated Category ", categoryUpload)

        dispatch({
          type: SG_EDIT_EMPLOYEE_CATEGORY,
          payload: {
            category: categoryUpload,
            id: employeeCategory?.id,
          },
        });
      } else {
        dispatch({
          type: SG_ADD_EMPLOYEE_CATEGORY,
          payload: {
            category: categoryUpload,
            employee: fullEmpData?.id,
          },
        });
      }
      setCategoryUpdated(false);
      setTeamUpdate(Math.random());
    }
    setLoading(true)
    setOpenFullCard(false);
    // addToast("Profile Updated", { appearance: "success", autoDismiss: true });
  }


  function CloseEmpCard() {
    setOpenFullCard(false)
    setFullEmpData({})
  }

  useEffect(() => {
    if (get_survey_structure?.[0]?.categories) {
      setPrimarySecondaryName({
        secondary: get_survey_structure?.[0]?.categories?.find(
          (f) => f.priority === "secondary"
        )?.name,
        primary: get_survey_structure?.[0]?.categories?.find(
          (f) => f.priority === "primary"
        )?.name,
      });
    }
  }, [get_survey_structure]);

  function getTitle() {
    if (fullEmpData.first_name) {
      return fullEmpData.first_name + " " + fullEmpData.last_name;
    }

    return fullEmpData.email;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        {members && members.length > 0 && (
          <Table>
            {auditEnabled ? (
              <Headers>
                <TH width={25}>Name</TH>
                <TH width={25}>{primarySecondaryName?.primary}</TH>
                <TH width={25}>Updated</TH>
                <TH width={25}>Actions</TH>
              </Headers>
            ) : (
              <Headers>
                <TH width={25}>Name</TH>
                <TH width={20}>Role</TH>
                <TH width={15}>Team</TH>
                <TH width={30}>Email</TH>
                <TH width={10}>Actions</TH>
              </Headers>
            )}
            {members &&
              members.length > 0 &&
              members.map((item, i) => {
                if (item.email) {
                  if (auditEnabled) {
                    return (
                      <MemberCardAudit
                        data={item}
                        setOpenFullCard={setOpenFullCard}
                        setOpenTeamCard={setOpenTeamCard}
                        setFullEmpData={setFullEmpData}
                        key={i}
                        auditEnabled={auditEnabled}
                        teamToolEnabled={teamToolEnabled}
                        employeeCategory={
                          employeeCategories.filter(
                            (ec) => ec.employee === item.id
                          )?.[0]
                        }
                        setLoading={setLoading}
                      />
                    );
                  } else {
                    return (
                      // team tool employee data
                      // step 1
                      <MemberCard
                        data={item}
                        auditEnabled={auditEnabled}
                        teamToolEnabled={teamToolEnabled}
                        setOpenFullCard={setOpenFullCard}
                        setOpenTeamCard={setOpenTeamCard}
                        openTeamCard={openTeamCard}
                        fullEmpData={item}
                        setFullEmpData={setFullEmpData}
                        userEmployee={userEmployee}
                        setLoading={setLoading}
                        key={i}
                      />

                    );
                  }
                } else {
                  return null;
                }
              })}
          </Table>
        )}
      </div>

      <EditMember
        setOpenTeamCard={setOpenTeamCard}
        openTeamCard={openTeamCard}
        setFullEmpData={setFullEmpData}
        fullEmpData={fullEmpData}
        userEmployee={userEmployee}
        newInvite={newInvite}
        setLoading={setLoading}
      />

      <Modal
        open={openFullCard}
        closeIcon
        onClose={CloseEmpCard}
        onOpen={() => setOpenFullCard(true)}
      // trigger={<Button>Scrolling Content Modal</Button>}
      >
        <Modal.Header>{getTitle()}</Modal.Header>
        <Modal.Content scrolling>
          {/* edit information about employees on this page modal */}
          <Profile
            employee={fullEmpData}
            valueUpdated={valueUpdated}
            setValueUpdated={setValueUpdated}
            selectRow={selectRow}
            setSelectRow={setSelectRow}
            categoryUpload={categoryUpload}
            setCategoryUpload={setCategoryUpload}
            categoryUpdated={categoryUpdated}
            setCategoryUpdated={setCategoryUpdated}
            employeeCategory={employeeCategory}
            setEmployeeCategory={setEmployeeCategory}
            userEmployee={userEmployee}
            userEmployeeCategoryList={userEmployeeCategoryList}
            errorMessage={errorMessage} 
            setErrorMessage={setErrorMessage}
          />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ marginBottom: "10px", paddingBottom: "20px" }}>
            <Button
              floated="left"
              onClick={CloseEmpCard}
              secondary
            >
              Cancel <Icon name="close" />
            </Button>
            <Button floated="right"
            onClick={SaveFormData} primary>
              <Icon name="save" /> Save &amp; Close
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default MemberTableAudit;

const Table = styled.div`
  width: 100%;
`;
const Headers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
  padding-top: 10px;
  background-color: #fafafa;
`;

const TH = styled.div`
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  width: ${(props) => props.width + "%"};
  padding: 0 0 10px 15px;
  font-weight: bold;

  @media (max-width: 768px) {
    display: none;
  }
`;
const Linked = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
