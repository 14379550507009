import React, { useState,useEffect } from 'react';
import styled, { keyframes } from "styled-components";
import {Modal,Icon,Popup} from 'semantic-ui-react'


const FeedbackModal = ({handleStage,currentResponse,handleResponseChange,selectedFeedback,setSelectedFeedback}) =>{
    const [open, setOpen] = React.useState(false);

    const selectOption = (num) =>{
        if(selectedFeedback.includes(num)){
            setSelectedFeedback(selectedFeedback.filter(item=>item!==num))
        }else{
            setSelectedFeedback([...selectedFeedback,num])
        }
    }

    return (
        <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        size='medium'
        trigger={<Button2>Use copilot to refine my plan</Button2>}
        // trigger={<ThumbContainer>
        //     <Popup 
        //     content="This plan isn't for me" 
        //     position='top center'
        //     inverted
        //     trigger={<Icon name="thumbs down outline"/>} />
        //     </ThumbContainer>}
      >
         <QuestionContainer>
              <Qualifier>We'd love to build you a better plan.</Qualifier>

              <OptionTitle>
                Select one or more of the options that best describes your feedback for this plan.
              </OptionTitle>
               <Options>
                    <Option selected={selectedFeedback.includes('Not actionable')} onClick={()=>selectOption('Not actionable')}>Not actionable</Option>
                    <Option selected={selectedFeedback.includes('Lacking detail')} onClick={()=>selectOption('Lacking detail')}>Lacking detail</Option>
                    <Option selected={selectedFeedback.includes('Not applicable')} onClick={()=>selectOption('Not applicable')}>Not applicable</Option>
                </Options> 

              <QuestionText>
                If you'd like, you can provide more detail on how you'd like to improve this plan for you.
              </QuestionText>
              <TextArea
                value={currentResponse}
                onChange={handleResponseChange}
              />

              <ButtonContainer>
                <Button onClick={() => handleStage(1)}>Improve my plan with feedback</Button>
              </ButtonContainer>
            </QuestionContainer>
         
      </Modal>
    )
}

export default FeedbackModal



const TextArea = styled.textarea`
    width:100%;
    height:100px;
    border-radius:5px;
    border:1px solid #DFDFDF;
    padding:10px;
    margin-top:10px;
    resize:none;
    font-family:'Raleway';
    font-size:14px;
    font-weight:400;
    line-height:1.5;
    margin-bottom:20px;

`

const QuestionText = styled.div`
font-family:'Raleway';
font-size: 14px;
font-weight: 400;
line-height:1.4;
`

const QuestionContainer = styled.div`
padding:30px;
`

const Qualifier = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-bottom:30px;
    font-family:'Raleway';
`

const Button = styled.button`
    background-color: #2D70E2;
    color: white;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const Button2 = styled.button`
    background-color: #EBF1FD;
    color: #2D70E2;
    padding: 10px 20px;
    border-radius:8px;
    border:none;
    cursor:pointer;
    width:auto;
    margin-left:10px;
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:10px;
`

const Options = styled.div`
    display:flex;
    margin-bottom:30px;
`

const Option = styled.div`
    font-size:14px;
    font-family:"Raleway";
    font-weight:400;
    border:1px solid #666D79;
    color:${props=>props.selected? 'white':'#666D79'};
    padding:10px;
    border-radius:5px;
    margin-right:10px;
    cursor:pointer;
    transition:all 0.3s ease;
    background-color:${props=>props.selected? '#2D70E2':'white'};

    &:hover{
        background-color:#2D70E2;
        color:white;
    }
`

const OptionTitle = styled.div`
    font-size:14px;
    font-family:"Raleway";
    font-weight:400;
    margin-bottom:20px;
`

const ThumbContainer = styled.div`
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-left:10px;
    margin-right:10px;
    color:#666D79;
    transition:all 0.3s ease;
    font-size:18px;
    &:hover{
        color:#2D70E2;
    }
`