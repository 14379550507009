import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";

import {
  Form,
  Input,
  Button,
  Grid,
  Checkbox,
  Message,
  Image,
  Header,
  Icon,
} from "semantic-ui-react";



const ImageUpload = (props) => {
  const [image, setImage] = useState(null);





  const onClickSave = () => {
    
  };

  const handleDrop = (e) => {
    // var that = this;
    // console.log(URL.createObjectURL(e.target.files[0]))
      setImage(URL.createObjectURL(e[0]));
   
  };


  return (
    <>
  

      <Grid.Row>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Image
              src={image? image : "https://react.semantic-ui.com/images/wireframe/image.png"}
              size="small"
              wrapped
            />
          </Grid.Column>
          <Grid.Column>
          {image?
                    <Grid>
                        <Grid.Row>
                    <Button variant="outlined" onClick={() => setImage("")}>
                          Clear 
                        </Button>
                      </Grid.Row>
                      <Grid.Row>
                        <Button onClick={() => onClickSave()} color="grey">
                          Save
                        </Button>
                    </Grid.Row>
                    </Grid>
                  
                    :
            <Dropzone onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    
                    <Header as="h2">
                      <Icon name="cloud upload" />
                      <Header.Content>Upload Logo</Header.Content>
                    </Header> 
                  </div>
                </section>
              )}
            </Dropzone>}
          </Grid.Column>
        </Grid>
      </Grid.Row>
      
    </>
  );
};

export default ImageUpload;
