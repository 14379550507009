import React, { useRef, useEffect,useState,useMemo } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";


const FactorChart = ({ data, setChartURI,selectedOption }) => {
  const hiddenChartRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (data && hiddenChartRef.current && hiddenChartRef.current?.chart) {
      // Delay to ensure the chart has rendered
      timeoutId = setTimeout(() => {
        hiddenChartRef.current.chart
          .dataURI({ width: 2400, height: 1600, scale: 1 })
          .then(({ imgURI }) => setChartURI(imgURI))
          .catch((err) => console.error('Error generating data URI:', err));
      }, 500); // Adjust the delay as needed
    }

    // Clean up on component unmount
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [data, setChartURI]);

  // Process and sort the data
  // Dynamically compute scaledData
  const scaledData = useMemo(() => {
    if (!data) return [];
    return data
      .map((item) => ({
        category: item.name,
        value:
          selectedOption === "Raw"
            ? (item.average ? (item.average * 10).toFixed(0) : null)
            : (item.diff !== null && isNaN(item.diff) === false)
            ? Math.round(item.diff)
            : null,
      }))
      .filter((item) => item.value !== null)
      .sort((a, b) => b.value - a.value);
  }, [data, selectedOption]);

   const categories = useMemo(() => scaledData.map((item) => item.category), [
    scaledData,
  ]);
  const dataValues = useMemo(() => scaledData.map((item) => item.value), [
    scaledData,
  ]);
  


  const processedSeries = useMemo(() => {
    const yaxisTitle =
      selectedOption === "benchmark"
        ? "Difference to benchmark"
        : selectedOption === "change"
        ? "Score change"
        : "Average Score";
    return [{ name: yaxisTitle, data: dataValues }];
  }, [selectedOption, dataValues]);

  const options = useMemo(() => {
    return {
      ...JSON.parse(JSON.stringify(baseOptions)),
      chart: { ...baseOptions.chart, height: 400 },
      colors: scaledData.map((item) =>
        item.value >= 0 ? "#27CDA7" : "#F15062"
      ),
      xaxis: {
        categories,
        labels: { style: { fontFamily: "Raleway, sans-serif" } },
      },
      yaxis: {
        title: { text: processedSeries[0].name },
        labels: { style: { fontFamily: "Raleway, sans-serif" } },
      },
      tooltip: {
        ...baseOptions.tooltip,
        y: { formatter: (val) => val.toString() },
      },
    };
  }, [scaledData, categories, processedSeries]);



  const hiddenChartOptions = useMemo(() => {
    return {
      ...JSON.parse(JSON.stringify(baseOptions)),
      chart: {
        ...baseOptions.chart,
        id: "report-chart",
        width: 2400,
        height: 1600,
        animations: { enabled: false },
      },
      colors: scaledData.map((item) =>
        item.value >= 0 ? "#27CDA7" : "#F15062"
      ),
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          barHeight: "70%",
          distributed: true,
        },
      },
      grid: {
        ...baseOptions.grid,
        padding: { top: -1, right: 100, bottom: 300, left: 100 },
      },
      dataLabels: {
        ...baseOptions.dataLabels,
        style: {
          fontSize: 40,
        },
      },
      xaxis: {
        type: "category",
        categories,
        title: {
          text: "",
          offsetY: 200,
          style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
        },
        labels: {
          style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
          offsetY: 20,
          rotate: -45,
          maxHeight: 100,
          formatter: (val) =>
            val.length > 30 ? `${val.substring(0, 27)}...` : val,
        },
      },
      yaxis: {
        labels: {
          offsetX: 80,
          style: { fontFamily: "Raleway, sans-serif", fontSize: 40 },
          formatter: (value) => value.toFixed(0),
        },
        title: {
          text:
            selectedOption === "benchmark"
              ? "Difference to Benchmark"
              : selectedOption === "change"
              ? "Score Change"
              : "Average Score",
          offsetX: -30,
          style: { fontFamily: "Raleway, sans-serif", fontSize: 30 },
        },
        tickAmount: 5,
      },
      tooltip: {
        ...baseOptions.tooltip,
        y: { formatter: (value) => value.toFixed(1) },
      },
    };
  }, [baseOptions, scaledData, categories, selectedOption]);
  





  return (
    <Container>
      {dataValues.length > 0 && (
        <>
             {!loading && <Chart 
                        options={options} 
                        series={processedSeries} 
                        key={`chart-2`}
                        type="bar" height={400} /> }
          <HiddenChartContainer>
          {!loading && <Chart
              ref={hiddenChartRef}
              options={hiddenChartOptions}
              series={processedSeries}
              type="bar"
              key={`chart-1`}
              height={1600}
              width={2400}
            /> }
          </HiddenChartContainer>
        </>
      )}
    </Container>
  );
};

export default FactorChart;

const Container = styled.div`
    margin-top: 20px;
    position: relative;
`

const HiddenChartContainer = styled.div`
  position: absolute;
  top: -10000px; /* Position it off-screen */
  left: -10000px;
  width: 2400px; /* Desired high resolution width */
  height: 1600px; /* Desired high resolution height */
  overflow: hidden; /* Prevent scrollbars */
`;


const baseOptions = {
    chart: {
      type: "bar",
      fontFamily: "Raleway, sans-serif",
      foreColor: "#7E7E7E",
      zoom: { enabled: false },
      toolbar: { show: false },
      background: "transparent",
    },
    plotOptions: {
      bar: { horizontal: false, borderRadius: 5, barHeight: "70%", distributed: true, },
    },
    legend: { show: false },
    fill: { type: "solid" },
    grid: {
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`, // Display percentage without decimals
      style: {
        colors: ["#fff"], // White text
        fontSize: "14px",
        fontFamily: "Raleway, sans-serif",
      },
    },
    tooltip: {
      enabled: true,
      style: { fontFamily: "Raleway, sans-serif" },
    },
  };