import React from "react";
import { Font, View, Text, StyleSheet } from "@react-pdf/renderer";
import Raleway from "assets/fonts/Raleway-Regular.ttf";
import Textbox from "./Textbox";

Font.register({
  family: "Raleway",
  fonts: [{ src: Raleway }],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Raleway",
    width: "100%",
    paddingTop: 0,
    marginBottom: 20,
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
    marginRight: 20,
  },
  dropdownSelection: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  dropdownLabel: {
    color: "#666d79",
    fontWeight: 600,
    fontSize: 12,
    marginTop: 3,
    marginRight: 5,
  },
  selectedOption: {
    color: "#2d70e2",
    fontWeight: 600,
    fontSize: 12,
    marginTop: 3,
  },
});

const MultiDropdown = (props) => {
  const { dropdowns, id, selected, comments } = props;

  return (
    <View style={styles.container}>
      {dropdowns.map((dropdown, index) => (
        <View style={styles.wrapper}>
          <View style={styles.dropdownSelection}>
            <Text style={styles.dropdownLabel}>{dropdown.title}:</Text>
            <Text style={styles.selectedOption}>
              {selected?.[id]?.[index]?.value || dropdown.options[0]}
            </Text>
          </View>
          <Textbox placeholder="" value={comments?.[id]?.[index]?.comment} />
        </View>
      ))}
    </View>
  );
};

export default MultiDropdown;
