import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
	width:100%;
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
	margin-top:30px;
`

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
`

export const TitleCard = styled.div`
  height: 137px;
  width:calc(33% - 24px);
  padding: 31px 36.5px 30px 57.7px;
  border-radius: 8px;
  border: solid 1px #ebebeb;
  background-image: linear-gradient(291deg, #6d00aa,#6a00ff);
  color:white;
  display:flex;
  align-items:center;
  flex-direction:row;
  box-shadow: 0 2px 40px 0 #dcdcdc;

`
export const Iconed = styled.img`
	width:62px;
	margin-right:36px;
`

export const Percentage = styled.div`
  font-size: 35px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  margin-bottom:20px;
`

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Red Hat Display', sans-serif;
`
const TableHeaders = [['Name',40],['# of members',30],['',30]]

export const View = styled.span`
	font-size: 16px;
  font-weight: bold;
  font-family: 'Red Hat Display', sans-serif;
  color: #7500ff;
  width:${TableHeaders[2][1]+'%'};
  display:flex;
  justify-content:center;
  align-items:center;
`

export const ClickableView = styled.span`
	font-size: 12px;
  font-family: 'Red Hat Display', sans-serif;
  color: #7500ff;
  cursor:pointer;
  width:${TableHeaders[2][1]+'%'};
  display:flex;
  justify-content:center;
  align-items:center;
  &:hover{
  	color: #5307ad;
    font-weight: 800;
  }
`

export const Arrow = styled.span`
	font-size:25px;
	margin-left:15px;
`

export const Table = styled.div`
	width:100%;
`
export const Headers = styled.div`
	width:100%;
	display:flex;
	flex-direction:row;
`

export const TH = styled.div`
  font-size: 12px;
  font-weight: 900;
  font-family: 'Red Hat Display', sans-serif;
  color: #696969;
  width:${props=>props.width+'%'};
  text-transform:uppercase;
  padding:0 0 10px 15px;
`


export const TeamCard = styled.div`
   border-bottom: 1px solid #e9e9e9;
  background-color: #ffffff;
  padding: 5px 0px 5px 0px;
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  cursor:pointer;

  &:hover{
  	background-color:#d3dbdb;
  }

  @media (max-width:768px){

  }
`

export const TeamRowCard = styled.div`
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 #e0e0e0;
  background-color: #ffffff;
  height:75px;
  width:100%;
  display: flex;
  flex-direction:row;
  justify-content: space-evenly;
  margin-top:16px;
  cursor:pointer;

  &:hover{
  	box-shadow: 0 10px 20px 0 #e0e0e0;
  }
`

export const ColumnName = styled.div`
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
`

export const DepartmentCard = styled.div`
  border-radius: 10px;
  box-shadow: 0 10px 20px 0 #e0e0e0;
  background-color: #ffffff;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-top:16px;
`

export const NakedCard = styled.div`
  height:${props=>props.open? '75px' : 0};
  width:100%;
  display:flex;
  position:relative;
  flex-direction:row;
  align-items:center;
  opacity:${props=>props.open? 1 : 0};
  border-bottom:${props=>props.border&& props.open? 'solid 1px #e9e9e9' : ''};
  overflow:hidden;
  transition:height 0.5s ease, opacity 0.5s ;

    cursor:pointer;

  &:hover{
  	box-shadow: 0 10px 20px 0 #e0e0e0;
  }
`

export const Avatar = styled.img`
  height:35px;
  width:35px;
  border-radius:18px;
  border: solid 1px #e9e9e9;
  margin-left:45px;
  margin-right:10px;

  @media (max-width:768px){
   display:none;
  }
`
export const TeamName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #4a4a4a;
  font-family: 'Red Hat Display', sans-serif;

  @media (max-width:768px){
   font-size:14px;
  }
`
export const TeamLeader = styled.div`
   font-size: 12px;
  font-weight: 900;
  color: #696969;
  font-family: 'Red Hat Display', sans-serif;
  text-transform:uppercase;

  @media (max-width:768px){
   display:none;
  }
`


export const Members = styled.div`
	width:${TableHeaders[1][1]+'%'};
	display:flex;
	font-size: 14px;
  	font-weight: 500;
	align-items:center;
	font-family: 'Red Hat Display', sans-serif;
	padding:0 0 0 15px;
	color: #545454;

  @media (max-width:768px){
   display:none;
  }
`

export const MembersMobile = styled.div`
    display:flex;
  font-size: 13px;
    font-weight: 500;
  align-items:center;
  font-family: 'Red Hat Display', sans-serif;
  color: #545454;

  @media (min-width:768px){
    display:none;
  }
`

export const Name = styled.div`
	width:${TableHeaders[0][1]+'%'};
	display:flex;
  flex-direction:row;
  
  @media (max-width:768px){
    width:70%;
    padding-left:10px;
  }

`

export const IconContainer = styled.div`
	transform:${props=>'rotate('+props.deg+'deg)'};
	color:#1e2172;
	position:absolute;
	left:10px;
	font-size:20px;
	transition:transform 0.5s;
	z-index:1;
`

export const BlankAvatar = styled.div`
  background-color:#4a4a4a;
   height:35px;
  width:35px;
  border-radius:18px;
  border: solid 1px #e9e9e9;
  margin-left:45px;
  margin-right:10px;

  @media (max-width:768px){
   display:none;
  }
`