import styled, { keyframes } from 'styled-components';
import React, { useEffect, useState,useRef } from "react";
import Chart from "react-apexcharts";

import ItemTable from './ItemTable';
import PptxGenJS from 'pptxgenjs';
import StarImg from 'assets/images/UI/star.svg';  // Assuming this is the SVG data or path
import ArrowImg from 'assets/images/UI/arrow_blue.svg';  // Assuming this is the SVG data or path
import { injectDownloadOptions } from "constants/chartOptions";


const SummaryChart = ({selectedFactor,chartData,setSelected}) =>{
    const chartRef = useRef(null); 
    const [loading,setLoading] = useState(true)

    const [selectedQuestion, setSelectedQuestion] = useState('Overall Summary');
     // Combine "Overall Summary" with the list of questions
     const questionOptions = ['Overall Summary', ...selectedFactor.questions.map((q, index) => q.q)];



    const [selectedOption, setSelectedOption] = useState('Average Score');

    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        },500)
    },[])

    // Process the chart data to apply sorting and scaling
const processedChartData = React.useMemo(() => {
    if (!chartData) return { categories: [], series: [], xAxisMax: null, labelsFormatter: val => val, tooltipFormatter: val => val };

    // Determine if we're using overall summary or a specific question
    const isOverallSummary = selectedQuestion === 'Overall Summary';

    // Find the series data corresponding to the selected option
    let seriesData;
    if (isOverallSummary) {
        seriesData = chartData.series.find(s => s.name === selectedOption);
    } else {
        // Extract data for the specific question
        // Assume chartData.questionSeries contains data per question
        seriesData = chartData.questionSeries[selectedQuestion]?.[selectedOption];
    }

    if (!seriesData) return { categories: [], series: [], xAxisMax: null, labelsFormatter: val => val, tooltipFormatter: val => val };

    let scaledData;
    let xAxisMax;
    let labelsFormatter;
    let tooltipFormatter;

    if (selectedOption === 'Average Score') {
        // Scale data to be out of 100%
        scaledData = seriesData.data.map(value => (value !== null ? value * 10 : null));
        // Remove decimals
        scaledData = scaledData.map(value => (value !== null ? Math.round(value) : null));
        xAxisMax = 100;
        labelsFormatter = function (val) {
            return val.toString();
        };
        tooltipFormatter = function (val) {
            return val.toString();
        };
    } else if (selectedOption === 'Max Score' || selectedOption === 'Min Score') {
        // Use data as is, no scaling
        scaledData = seriesData.data.map(value => (value !== null ? Math.round(value) : null));
        xAxisMax = 10;
        labelsFormatter = function (val) {
            return val.toString();
        };
        tooltipFormatter = function (val) {
            return val.toString();
        };
    } else if (selectedOption === 'Standard Deviation') {
        // Use data as is, with two decimals
        scaledData = seriesData.data.map(value => (value !== null ? parseFloat(value.toFixed(2)) : null));
        xAxisMax = null; // Let ApexCharts auto-adjust
        labelsFormatter = function (val) {
            return val.toFixed(2);
        };
        tooltipFormatter = function (val) {
            return val.toFixed(2);
        };
    } else {
        // Default case
        scaledData = seriesData.data;
        xAxisMax = null;
        labelsFormatter = function (val) {
            return val.toString();
        };
        tooltipFormatter = function (val) {
            return val.toString();
        };
    }

    // Pair the categories with the scaled data and filter out null values
    const dataPairs = chartData.categories
        .map((category, index) => ({
            category,
            value: scaledData[index]
        }))
        .filter(pair => pair.value !== null);

    // Sort data from largest to smallest
    dataPairs.sort((a, b) => b.value - a.value);

    // Extract sorted categories and values
    const sortedCategories = dataPairs.map(pair => pair.category);
    const sortedValues = dataPairs.map(pair => pair.value);

    // Create the processed series
    const processedSeries = [
        {
            name: seriesData.name,
            data: sortedValues
        }
    ];

    return {
        categories: sortedCategories,
        series: processedSeries,
        xAxisMax,
        labelsFormatter,
        tooltipFormatter
    };
}, [chartData, selectedOption, selectedQuestion]);


    // Update x-axis title to reflect the selected option and question
    const xAxisTitle = selectedOption;

    // Update chart title
    const chartTitle = selectedQuestion === 'Overall Summary' ? 'Overall Summary' : selectedQuestion;


    const options = {
      chart: {
        height: 400,
        type: "bar",
        id: "my-chart",
        fontFamily: "Raleway, sans-serif",
        foreColor: "#7E7E7E",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#2D70E2"], // Updated bar color
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          barHeight: "70%",
        },
      },
      legend: {
        show: false,
      },
      fill: {
        type: "solid",
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
          min: 0,
          max: 100,
        },
      },
      xaxis: {
        categories: processedChartData.categories,
        title: {
          text: xAxisTitle, // Dynamic x-axis title
        },
        labels: {
          formatter: processedChartData.labelsFormatter,
          style: {
            fontFamily: "Raleway, sans-serif",
          },
        },
        max: processedChartData.xAxisMax,
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Raleway, sans-serif",
          },
        },

        min: 0,
        max: 100,
      },
      dataLabels: {
        enabled: true, // Remove bar number labels
        formatter: function (val) {
          return `${val}%`; // Add % symbol to the value
        },
        style: {
          fontSize: "14px",
          fontFamily: "Raleway, sans-serif",
          colors: ["#FFFFFF"], // White text to ensure readability on the bar
        },
        offsetY: 0, // Center the label vertically
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.3,
        },
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w, options }) {
          const category = w.globals.seriesNames?.[0]?.[dataPointIndex]; // Get the x-axis label

          if (category?.length > 20) {
            return `
                <div style="padding: 8px; background-color: white; border: 1px solid #e0e0e0; border-radius: 5px; font-family: Poppins, sans-serif;">
                  <div style="font-size: 14px; font-weight: 500; color: #333;">${category}</div>
                </div>
              `;
          }
          return `
              <div>
              </div>
            `;
        },
      },
      title: {
        text: chartTitle,
        align: "center",
        style: {
          fontFamily: "Raleway, sans-serif",
        },
      },
    };

    // Handle radio button change
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

    // Handle question dropdown change
    const handleQuestionChange = (event) => {
      setSelectedQuestion(event.target.value);
    };

    return (
      <Container>
        {/* Radio Buttons */}
        {/* <div style={{ marginBottom: '20px' }}>
                <label style={{marginRight:10}}>
                    <input
                        type="radio"
                        value="Average Score"
                        checked={selectedOption === 'Average Score'}
                        onChange={handleOptionChange}
                    />
                    Average Score
                </label>
                {' '}
                <label style={{marginRight:10}}>
                    <input
                        type="radio"
                        value="Max Score"
                        checked={selectedOption === 'Max Score'}
                        onChange={handleOptionChange}
                    />
                    Max Score
                </label>
                {' '}
                <label style={{marginRight:10}}>
                    <input
                        type="radio"
                        value="Min Score"
                        checked={selectedOption === 'Min Score'}
                        onChange={handleOptionChange}
                    />
                    Min Score
                </label>
                {' '}
                <label style={{marginRight:10}}>
                    <input
                        type="radio"
                        value="Standard Deviation"
                        checked={selectedOption === 'Standard Deviation'}
                        onChange={handleOptionChange}
                    />
                    Standard Deviation
                </label>
            </div> */}

        {/* <div style={{ marginBottom: '20px' }}>
                <select value={selectedQuestion} onChange={handleQuestionChange} style={{ width: '100%', padding: '8px', fontFamily: 'Raleway, sans-serif' }}>
                    {questionOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div> */}
        {processedChartData?.series[0]?.data?.length > 0 && !loading && (
          <Chart
            options={injectDownloadOptions(options, true)}
            series={processedChartData.series}
            type="bar"
            height={400}
          />
        )}

        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 400,
            }}
          />
        )}
        {/* <button onClick={exportToPowerPoint}>Export to PowerPoint</button> */}

        <ItemTable chartData={chartData} setSelected={setSelected} />
      </Container>
    );
}

export default SummaryChart;


const Container = styled.div`
    margin-top: 20px;
`



